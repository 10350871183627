import React from "react";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps } from "react-router";
import { bindActionCreators, Dispatch } from "redux";
import { Trans } from "react-i18next";
import { State } from "@store/types";
import {
  SurveyResourceRecord,
  SurveyInfoResourceRecord,
  MargeTagStateProps
} from "@screens/Insights/Surveys/store/types";
import * as NotificationFactory from "@src/store/notifications/notification-factory";
import { getRightsMatcher } from "@screens/Insights/store/selectors";
import {
  getSurveyChannels,
  fetchSurveyInfo,
  fetchMergeTags as getMergeTags,
  getChannels
} from "@screens/Insights/Surveys/store/selectors";

import { actionCreators as surveysActionCreators } from "@screens/Insights/Surveys/store/actions";
import notificationActionCreators from "@store/notifications/actions";

import { SendReminderModal } from "@src/screens/Insights/SurveyList/components/SendReminderModal";

import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { SurveyInfoResource } from "hyphen-lib/dist/domain/resource/SurveyInfoResource";
import { Rights } from "@hyphen-lib/business/auth/Rights";
import { NotificationChannels } from "hyphen-lib/dist/domain/NotificationChannels";
import { getOr } from "hyphen-lib/dist/lang/Objects";
import SurveyReminders from "../../components/SurveyReminders";
import { actionCreators } from "./store/actions";
import { reminders } from "./store/selectors";
import { SurveyRemindersStateProps } from "./store/types";

interface MatchParams {
  id: string;
}

interface StateProps {
  reminders: SurveyRemindersStateProps;
  surveyInfo: SurveyInfoResourceRecord;
  surveyChannels: SurveyResourceRecord["channels"];
  companyChannels: CompanyResource["channels"];
  readonly mergeTags: MargeTagStateProps;
  readonly hasSurveyAdminRight: boolean;
}

export type OwnProps = RouteComponentProps<MatchParams>;

type Props = {
  dispatch: Dispatch;
} & OwnProps & StateProps;

export class SurveyRemindersContainer extends React.Component<Props> {
  boundActionCreators: typeof actionCreators;
  getSurveyInfo: typeof surveysActionCreators.getSurveyInfo;
  fetchMergeTags: typeof surveysActionCreators.fetchMergeTags;
  displayNotification: typeof notificationActionCreators.displayNotification;

  constructor(props: Props) {
    super(props);

    const { dispatch } = props;

    this.boundActionCreators = bindActionCreators(actionCreators, dispatch);
    this.getSurveyInfo = bindActionCreators(surveysActionCreators, dispatch).getSurveyInfo;
    this.fetchMergeTags = bindActionCreators(surveysActionCreators, dispatch).fetchMergeTags;
    this.displayNotification = bindActionCreators(notificationActionCreators, dispatch).displayNotification;
  }

  componentDidMount() {
    const surveyId = this.props.match.params.id;
    this.fetchSurveyReminders();
    this.getSurveyInfo(surveyId);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.fetchSurveyReminders();
    }
  }

  fetchSurveyReminders = () => {
    const surveyId = this.props.match.params.id;
    this.boundActionCreators.fetchSurveyReminders(surveyId);
  };

  closeModal = () => this.boundActionCreators.toggleReminderModalOpen(false, {} as SurveyInfoResource);
  sendSurveyReminders = (payload: NotificationChannels) => {
    const { selectedSurveyInfoResource } = this.props.reminders;
    this.boundActionCreators.sendSurveyRemindersRequest(
      selectedSurveyInfoResource.get("_id"),
      payload,
      () => {
        this.fetchSurveyReminders();
        this.displayNotification(
          NotificationFactory.success(
            "Reminder sent successfully",
            "",
            4.5
          )
        );
      }
    );
  };

  render() {
    const {
      mergeTags,
      surveyInfo,
      hasSurveyAdminRight,
      companyChannels,
    } = this.props;
    const {
      reminderModalOpen,
      selectedSurveyInfoResource,
      selectedSurveyResource,
      isRequestingSendReminders,
    } = this.props.reminders;

    return (
      <>
        <SurveyReminders
          reminders={this.props.reminders}
          surveyInfo={surveyInfo}
          toggleReminderModalOpen={this.boundActionCreators.toggleReminderModalOpen}
          canSendReminders={hasSurveyAdminRight}
        />
        {reminderModalOpen &&
          <SendReminderModal
            title={<Trans>Send a manual reminder</Trans>}
            visible={reminderModalOpen}
            onCancel={this.closeModal}
            name={selectedSurveyInfoResource.get("name")}
            surveyType={selectedSurveyInfoResource.get("type")}
            participation={selectedSurveyInfoResource.get("participation")}
            onSendReminder={this.sendSurveyReminders}
            isRequestingSendReminders={isRequestingSendReminders}
            fetchMergeTags={this.fetchMergeTags}
            mergeTags={mergeTags}
            companyChannels={companyChannels}
            surveyChannels={getOr(selectedSurveyResource.toJS().channels, {})}
            nextReminderChannels={selectedSurveyResource.toJS().nextReminderChannels}
            isMagicSurvey={surveyInfo.get("isMagic")}
          />
        }
      </>
    );
  }
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (
  state: State
): StateProps => ({
  reminders: reminders(state),
  surveyInfo: fetchSurveyInfo(state),
  surveyChannels: getSurveyChannels(state),
  companyChannels: getChannels(state),
  mergeTags: getMergeTags(state),
  hasSurveyAdminRight: getRightsMatcher(state).hasRight(Rights.Survey.ADMIN_ALL),
});

export default connect(mapStateToProps)(SurveyRemindersContainer);
