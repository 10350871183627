import styled from "styled-components";
import { GlobalActionPlanTemplate } from "@hyphen-lib/domain/GlobalActionPlanTemplate";
import Palette from "@src/config/theme/palette";
import { TransWrapper } from "@src/components/core/withTrans";

interface DifficultyProps {
  level?: number;
  readonly translate?: string;
}

const numberToDifficulty: any = Object.freeze({
  1: [GlobalActionPlanTemplate.DifficultyType.EASY],
  2: [GlobalActionPlanTemplate.DifficultyType.INTERMEDIATE],
  3: [GlobalActionPlanTemplate.DifficultyType.HARD],
});

function Difficulty({ level = 2, ...rest}: DifficultyProps) {
  return (
    <Container >
      <Circles>
        {Array.from(Array(3).keys()).map(i => 
            <Circle key={i} filled={i < level}/>
        )}
      </Circles>
      <TransWrapper {...rest}>{numberToDifficulty[level]}</TransWrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  padding-left: 2px;
`;

const Circles = styled.div`
  margin-right: 5px;
  display: flex;
  flex: 0.7;
  justify-content: space-around;
  min-width: 42px;
  align-items: center;
`;

const Circle = styled.span<{filled: boolean}>`
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: 1px solid ${Palette.darkTurqoise};
  background-color: ${props => props.filled ? Palette.darkTurqoise : "white"};
`;

export default Difficulty;