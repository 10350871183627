import React from "react";
import styled from "styled-components";

import { HighlightedSegment } from "@hyphen-lib/domain/resource/report/common/Highlights";
import Palette from "@src/config/theme/palette";
import CreateActionPlanButton from "@src/screens/Insights/components/Reports/CreateActionPlanButton";
import CompareValue from "@components/core/CompareValue";
import { withRouter, RouteComponentProps } from "react-router";

import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { getSegmentLabel } from "hyphen-lib/dist/domain/common/Dimensions";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { extractComparison } from "hyphen-lib/dist/business/calculation/benchmark/Benchmarks";
import { getClassNameForScoreText } from "../Reports/Score";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { FocusArea, Module } from "hyphen-lib/dist/domain/FocusArea";
import { Trans } from "react-i18next";
import { sanitizeSegmentLabel } from "hyphen-lib/dist/domain/common/Dimensions";
import { trans } from "@src/utils/i18next";

interface Props extends RouteComponentProps<{ id: string }> {
  dimensionLabel: string;
  data: HighlightedSegment;
  subtitle?: Optional<string>;
  isComparisonVisible?: boolean;
  comparisonLabel: Optional<string>;
  comparisonKey: Optional<string>;
  readonly hasActionCreationRight: boolean;
  readonly isRatingQuestion?: boolean;
  readonly isNPSQuestion?: boolean;
  readonly focusAreas?: FocusAreaResource[];
  readonly onCreateFocusArea: (focusArea: Partial<FocusAreaResource>) => void;
  readonly segmentType:
  | FocusArea.Source.STRONGEST_SEGMENTS
  | FocusArea.Source.WEAKEST_SEGMENTS
  | FocusArea.Source.BIGGEST_IMPROVEMENTS
  | FocusArea.Source.BIGGEST_DECLINE;
  readonly focusAreaLabel: string;
}

export const SegmentComponent = (
  {
    dimensionLabel,
    data: { dimension, segment, score, compare },
    subtitle,
    isComparisonVisible = true,
    comparisonLabel,
    comparisonKey = "previous",
    hasActionCreationRight,
    focusAreas,
    onCreateFocusArea,
    segmentType,
    focusAreaLabel,
    ...props
  }: Props
) => {
  const segmentLabel = getSegmentLabel(segment);

  function onCreateActionPlan() {
    const params: Dictionary<any> = props.match.params;

    onCreateFocusArea({
      title: segmentLabel,
      source: segmentType,
      module: Module.PULSE_POLL,
      focusAreaType: FocusArea.FocusAreaType.SEGMENT,
      sourceUrl: {
        label: focusAreaLabel,
        url: window.location.href,
      },
      dimension,
      actionPlans: [],
      pollId: params.templateId
    });
  }

  const comparison = extractComparison(compare, comparisonKey);
  const scoreClassName = getClassNameForScoreText(
    score,
    props.isNPSQuestion ? "nps" : "favorability"
  );

  return (
    <Container subtitleAvailable={isStringAndNotEmpty(subtitle)}>
      {
        hasActionCreationRight && (
          <CreateActionPlanButtonContainer data-cy={`actionPlan_${segmentLabel}`}>
            <CreateActionPlanButton onClick={onCreateActionPlan} />
          </CreateActionPlanButtonContainer>
        )
      }
      <SegmentAndDimensionKeyContainer data-cy={`segment_${segmentLabel}`}>
        <div>{trans(sanitizeSegmentLabel(segmentLabel))}</div>
        <DimensionLabelText>{dimensionLabel}</DimensionLabelText>
        {
          isStringAndNotEmpty(subtitle) &&
          <SubtitleText>{subtitle}</SubtitleText>
        }
      </SegmentAndDimensionKeyContainer>
      <ScoreContainer data-cy={`score_${segmentLabel}`}>
        <Score className={scoreClassName}>{score}%</Score>
      </ScoreContainer>
      {
        isComparisonVisible &&
        isNotNullNorUndefined(comparison) &&
        isNotNullNorUndefined(comparisonLabel) &&
        <CompareContainer>
          <CompareValueContainer>
            <CompareValue compare={comparison} /> <Trans>vs {comparisonLabel}</Trans>
          </CompareValueContainer>
        </CompareContainer>
      }
    </Container>
  );
};

const CompareContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 140px;
  margin-left: 8px;
  align-items: flex-end;
  color: ${Palette.bluishGrey};
  font-size: 12px;
`;

const Score = styled.span`
  font-size: 16px;
  font-weight: bold;

  &.positive {
    color: ${Palette.primary};
  }

  &.neutral {
    color: ${Palette.bluishGrey};
  }

  &.negative {
    color: ${Palette.darkPink};
  }
`;

const CreateActionPlanButtonContainer = styled.div`
  display: none;
  position: absolute;
  top: 50%;
  left: 0;
`;

const ScoreContainer = styled.div`
  margin-left: 8px;
`;

const CompareValueContainer = styled.div`
  span {
    font-size: 14px;
  }
`;

const DimensionLabelText = styled.div`
  margin-top: 4px;
  color: ${Palette.bluishGrey};
`;

export const SubtitleText = styled.div`
  margin-top: 4px;
  color: ${Palette.bluishGrey};
  font-size: 12px;
  display: none;
`;

const Container = styled.div<{ subtitleAvailable: boolean }>`
  position: relative;
  margin-top: 16px;
  font-size: 16px;
  height: 64px;
  line-height: 16px;
  border-bottom: 1px solid ${Palette.lightPeriwinkle};
  background: ${Palette.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 32px;
  &:hover {
    cursor: default;
    border-color: ${Palette.darkModerateBlue};
  }

  &:hover ${CreateActionPlanButtonContainer} {
    display: block;
  }

  &:hover ${DimensionLabelText} {
    display: ${props => props.subtitleAvailable ? "none" : "block"};
  }

  &:hover ${SubtitleText} {
    display: ${props => props.subtitleAvailable ? "block" : "none"};
  }
`;

const SegmentAndDimensionKeyContainer = styled.div`
  font-size: 14px;
  flex-grow: 1;
`;

export default withRouter(SegmentComponent);
