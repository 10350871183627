import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import styled from "styled-components";

import { Dictionary } from "@hyphen-lib/domain/structure/Dictionary";
import { getOr } from "@hyphen-lib/lang/Objects";
import IndividualResultsSurveyTable
  from "@screens/Insights/Survey/components/IndividualResultsSurveyReport/components/IndividualResultsSurveyTable";
import SearchBarSection from "@screens/Insights/Survey/components/SearchBarSection";

import ViewOptions, { getViewOptionValuesFromLocation } from "@screens/Insights/components/ViewOptions";
import { CompareWithOption } from "@screens/Insights/components/ViewOptions/components/CompareWith";
import { areEquals } from "hyphen-lib/dist/lang/Objects";

import { NetworkEventSuccessAction } from "@src/store/network/actions";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { PropMapping } from "@src/utils/parameters";
import { getViewOptionDefinitions } from "@src/utils/ViewOptions";
import { areArraysEqual, isEmpty } from "hyphen-lib/dist/lang/Arrays";
import { Participation } from "hyphen-lib/dist/domain/common/Participation";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { IndividualResultResource } from "hyphen-lib/dist/domain/resource/survey/report/IndividualResultResource";
import SendPrivateMessageModal from "../../../components/Reports/SendPrivateMessage/index";
import SurveyReportHeader from "../SurveyReportHeader";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";

export interface IndividualResultsReportStateProps {
  readonly commentText: string;
  readonly errorText: string;
  readonly isNetworkRequesting: boolean;
  readonly isPrivateMessageModalVisible: boolean;
  readonly postId: string;
  readonly privateMessageText: string;
  readonly voteId: string;
  readonly canSendPrivateMessage: boolean;
  readonly postAndSurveySentiment?: boolean;
}

export interface IndividualResultsReportActionProps {
  readonly clearPrivateMessageModal: () => any;
  readonly sendPrivateMessage: (
    surveyId: string,
    postId: string,
    voteId: string,
    initialMessage: string,
    onSuccessRedirect?: (payload: NetworkEventSuccessAction["payload"]) => void
  ) => void;
  readonly togglePrivateMessageModal: (
    surveyId: string,
    postId: string,
    voteId: string,
    commentText: string
  ) => void;
  readonly updatePrivateMessageText: (privateMessageText: string) => any;
}

type Props = {
  readonly surveyId: string;
  readonly enabledFilters: string[];
  readonly enabledCustomFilters?: string[];
  readonly storeMappings?: PropMapping[];
  readonly participation: Participation;
  readonly enabledViewOptions: string[];
  readonly compareWithOptions: CompareWithOption[];
  readonly individualResults: Store.Page<IndividualResultResource>;
  readonly surveyName: string;
  readonly isMultiSelectQuestionEnabled: boolean;
  readonly onCreateFocusArea: (
    focusArea: Partial<FocusAreaResource>
  ) => void;
} &
RouteComponentProps &
IndividualResultsReportStateProps &
IndividualResultsReportActionProps;

interface IndividualResultsState {
  isPrivateMessageModalOpen: boolean;
}

interface IndividualResultsSurveyReportState {
  readonly viewOptions: {
    readonly questionCategory: boolean;
    readonly comments: boolean;
  };
}

export class IndividualResultsSurveyReport extends React.Component<
Props,
IndividualResultsSurveyReportState & IndividualResultsState
> {
  constructor(props: Props) {
    super(props);

    const viewOptions = getViewOptionValuesFromLocation(props.location);
    this.state = {
      ...this.mapViewOptionsToState(
        viewOptions,
        props.compareWithOptions,
        this.getDefaultViewOptionValues()
      ),
      isPrivateMessageModalOpen: false,
    };
  }

  componentDidUpdate(prevProps: Props): void {
    const prevViewOptions = parseQueryString(prevProps.location.search).viewOptions;
    const currentViewOptions = parseQueryString(this.props.location.search).viewOptions;
    if (not(areEquals(prevViewOptions, currentViewOptions)) ||
      not(areArraysEqual(prevProps.compareWithOptions, this.props.compareWithOptions)) ||
      not(areArraysEqual(prevProps.enabledViewOptions, this.props.enabledViewOptions))) {
      this.setState(
        this.mapViewOptionsToState(
          getViewOptionValuesFromLocation(this.props.location),
          this.props.compareWithOptions,
          this.getDefaultViewOptionValues()
        )
      );
    }
  }

  sendPrivateMessage = () => {
    this.props.sendPrivateMessage(
      this.props.surveyId,           // Survey Id
      this.props.postId,             // Question Id
      this.props.voteId,             // Comment Id
      this.props.privateMessageText  // Text Message
    );
  };

  getDefaultViewOptionValues = () => {
    return {
      questionCategory: true,
      comments: true,
    };
  };

  mapViewOptionsToState = (viewOptions: Dictionary<any>,
    compareWithOptions: CompareWithOption[],
    defaultValues: Dictionary<boolean>) => {

    return {
      viewOptions: {
        questionCategory: getOr(viewOptions.questionCategory, defaultValues.questionCategory),
        comments: getOr(viewOptions.comments, defaultValues.comments),
      },
    };
  };

  handleViewOptionsChange = (viewOptions: Dictionary<any>) => {
    this.setState(
      this.mapViewOptionsToState(
        viewOptions,
        this.props.compareWithOptions,
        this.getDefaultViewOptionValues()
      )
    );
  };

  getViewOptionsComponent = (enabledViewOptions: string[],
    compareWithOptions: CompareWithOption[]): React.ReactNode => {

    const viewOptions = getViewOptionDefinitions(enabledViewOptions, compareWithOptions);
    if (isEmpty(viewOptions)) {
      return null;
    }

    return (
      <ViewOptions
        viewOptions={viewOptions}
        defaultValues={this.state.viewOptions}
        onChange={this.handleViewOptionsChange}
      />
    );
  };

  render() {
    const {
      participation,
      individualResults,
      enabledFilters,
      enabledCustomFilters,
      storeMappings,
      enabledViewOptions,
      compareWithOptions,
      clearPrivateMessageModal,
      commentText,
      errorText,
      isNetworkRequesting,
      isPrivateMessageModalVisible,
      privateMessageText,
      togglePrivateMessageModal,
      updatePrivateMessageText,
      canSendPrivateMessage,
      surveyName,
      isMultiSelectQuestionEnabled,
      onCreateFocusArea,
      postAndSurveySentiment
    } = this.props;

    const {
      viewOptions,
    } = this.state;

    return (
      <Container>
        <SurveyReportHeader
          participation={participation}
          enabledFilters={enabledFilters}
          enabledCustomFilters={enabledCustomFilters}
          storeMappings={storeMappings}
          exportOption="individualResults"
        />
        <IndividualResultsContainer>
          <SearchBarSection
            placeholder="Search employees, questions or answers"
            viewOptionsComponent={this.getViewOptionsComponent(enabledViewOptions, compareWithOptions)}
          />
          {Store.Page.isLoading(individualResults) ? (
            <LoadingContainer>Loading...</LoadingContainer>
          ) : (
            <IndividualResultsSurveyTable
              individualResults={Store.Page.toList(individualResults)}
              areQuestionCategoriesVisible={viewOptions.questionCategory}
              areCommentsVisible={viewOptions.comments}
              isPrivateMessageModalOpen={isPrivateMessageModalVisible}
              privateMessageText={privateMessageText}
              sendPrivateMessage={this.sendPrivateMessage}
              togglePrivateMessageModal={togglePrivateMessageModal}
              updatePrivateMessageText={updatePrivateMessageText}
              canSendPrivateMessage={canSendPrivateMessage}
              surveyName={surveyName}
              isMultiSelectQuestionEnabled={isMultiSelectQuestionEnabled}
              onCreateFocusArea={onCreateFocusArea}
              postAndSurveySentiment={postAndSurveySentiment}
            />
          )}
        </IndividualResultsContainer>
        <SendPrivateMessageModal
          clearPrivateMessageModal={clearPrivateMessageModal}
          comment={commentText}
          errorText={errorText}
          isSending={isNetworkRequesting}
          onCancel={togglePrivateMessageModal}
          privateMessageText={privateMessageText}
          updatePrivateMessageText={updatePrivateMessageText}
          visible={isPrivateMessageModalVisible}
          onOk={this.sendPrivateMessage}
        />
      </Container>
    );
  }
}

const Container = styled.div`
  height: 100vh;
`;

const IndividualResultsContainer = styled.div`
  margin-top: 24px;
`;

const LoadingContainer = styled.div`
  text-align: center;
  background: white;
  padding: 24px;
`;

export default withRouter(IndividualResultsSurveyReport);
