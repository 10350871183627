import React from "react";
import styled from "styled-components";
import Modal from "@src/components/core/Modal";
import Input from "@src/components/core/Input";
import { InputProps } from "antd/lib/input";
import Select from "@src/components/core/Select";
import Button from "@src/components/core/Button";
import Palette from "@src/config/theme/palette";
import { Form } from "antd";
import { getOr } from "hyphen-lib/dist/lang/Objects";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { GlobalActionPlanTemplate } from "hyphen-lib/dist/domain/GlobalActionPlanTemplate";
import { PostCategoryResource } from "hyphen-lib/dist/domain/resource/post/PostCategoryResource";
import { ModalProps } from "antd/lib/modal";
import { SelectProps } from "antd/lib/select";
import { TextArea } from "@src/components/core/TextArea";
import { Trans } from "react-i18next";

interface StateProps {
  action?: string;
  category?: string;
  description?: string;
  length?: string;
  difficulty?: string;
  source?: string;
  resource?: string;
  actionFieldIsTouched?: boolean;
  descriptionFieldIsTouched?: boolean;
  resourceFieldIsTouched?: boolean;
}

interface ActionProps {
  visible: boolean;
  modalOpenInMode: string;
  action: string;
  category: string;
  description: string;
  length: string;
  difficulty: string;
  source: string;
  resource: string;
  templateId: string;
  onCancel: (e: React.MouseEvent<any>) => void;
  onSubmit: (payload: any) => void;
  onUpdate: (payload: any, templateId: string) => void;
  onChange: (payload: any) => void;
  categories: PostCategoryResource[];
}

class CustomActionModal extends React.Component<ActionProps, StateProps> {
  constructor(props: any) {
    super(props);

    this.state = {
      actionFieldIsTouched: false,
      descriptionFieldIsTouched: false,
      resourceFieldIsTouched: false,
    };
  }

  createActionPlanTemplate = (e: React.FormEvent) => {
    e.preventDefault();
    const {
      action,
      category,
      description,
      length,
      difficulty,
      source,
      resource,
      templateId,
      modalOpenInMode,
    } = this.props;

    const payload = {
      action,
      category,
      description,
      length,
      difficulty,
      source,
      resource,
    };

    if (this.props.action.length > 0  && this.props.description.length > 0 && this.isValidURL(this.props.resource)) {
      if (modalOpenInMode === "add") {
        this.props.onSubmit(payload);
      } else if (modalOpenInMode === "edit") {
        this.props.onUpdate(payload, templateId);
      }
    } else {
      this.setState({
        actionFieldIsTouched: true,
        descriptionFieldIsTouched: true,
        resourceFieldIsTouched: true,
      });
    }
  };

  handleActionNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { category, description, length, difficulty, source, resource } = this.props;
    const payload = {
      action: e.target.value,
      category,
      description,
      length,
      difficulty,
      source,
      resource,
    };

    this.setState ({actionFieldIsTouched: true});
    this.props.onChange(payload);
  };

  handleCategoryChange = (value: any) => {
    const {action, description, length, difficulty, source, resource } = this.props;
    const payload = {
      action,
      category: value,
      description,
      length,
      difficulty,
      source,
      resource,
    };
    this.props.onChange(payload);
  };

  handleLengthChange = (value: any) => {
    const {action, category, description, difficulty, source, resource } = this.props;
    const payload = {
      action,
      category,
      description,
      length: value,
      difficulty,
      source,
      resource,
    };
    this.props.onChange(payload);
  };

  handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const {action, category, length, difficulty, source, resource } = this.props;
    const payload = {
      action,
      category,
      description: e.target.value,
      length,
      difficulty,
      source,
      resource,
    };

    this.setState ({descriptionFieldIsTouched: true});
    this.props.onChange(payload);
  };

  handleDifficultyChange = (value: any) => {
    const {action, category, length, description, source, resource } = this.props;
    const payload = {
      action,
      category,
      description,
      length,
      difficulty: value,
      source,
      resource,
    };
    this.props.onChange(payload);
  };

  handleResourceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {action, category, description, length, difficulty, source} = this.props;
    const payload = {
      action,
      category,
      description,
      length,
      difficulty,
      source,
      resource: e.target.value,
    };

    this.setState ({resourceFieldIsTouched: true});
    this.props.onChange(payload);
  };

  handleCancel = (e: React.MouseEvent<any>) => {
    this.setState({
      actionFieldIsTouched: false,
      descriptionFieldIsTouched: false,
      resourceFieldIsTouched: false,
    });
    this.props.onCancel(e);
  };

  isValidURL = (str: string): boolean => {
    const pattern = new RegExp("^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$","i"); // fragment locator

    // Return true is str is blank or valid URL
    return (str.trim().length === 0 || !!pattern.test(str));
  };

  getModalTitle = (mode: string) => {
    const titleMapper: Dictionary<any> = {
      add: "Create a Custom Action Plan Template",
      edit: "Update Custom Action Plan Template",
      view: "View Custom Action Plan Template",
    };

    return getOr(titleMapper[mode], "Unknown");
  };

  render() {
    const { Option } = Select;
    const { categories } = this.props;
    const lengthOptions = [
      GlobalActionPlanTemplate.LengthType.SHORT,
      GlobalActionPlanTemplate.LengthType.MEDIUM,
      GlobalActionPlanTemplate.LengthType.LONG,
    ];
    const difficultyOptions = [
      GlobalActionPlanTemplate.DifficultyType.EASY,
      GlobalActionPlanTemplate.DifficultyType.INTERMEDIATE,
      GlobalActionPlanTemplate.DifficultyType.HARD,
    ];

    return (
      <StyledModal
        visible={this.props.visible}
        centered
        title={<Trans>{this.getModalTitle(this.props.modalOpenInMode)}</Trans>}
        onCancel={this.handleCancel}
        destroyOnClose={true}
        footer={null}
      >
        <Form onSubmit={this.createActionPlanTemplate}>
          <ModalContent>
            <Container>
              <ActionTextInput
                disabled={this.props.modalOpenInMode === "view" ? true : false}
                label={<Trans>Action Name</Trans>}
                onChange={this.handleActionNameChange}
                value={this.props.action}
              />
              {this.state.actionFieldIsTouched && this.props.action.length === 0 && (
                <Warning><Trans>Please enter a action plan template name</Trans></Warning>
              )}
            </Container>
            <Container>
              <StyledSelect
                disabled={this.props.modalOpenInMode === "view" ? true : false}
                label={<Trans>Select a category</Trans>}
                onChange={this.handleCategoryChange}
                value={this.props.category}
              >
                {categories.map(postCategory => (
                  <Option key={postCategory._id} value={postCategory.label}>
                    <Trans>{postCategory.label}</Trans></Option>
                ))}
              </StyledSelect>
            </Container>
            <Container>
              <StyledTextArea
                disabled={this.props.modalOpenInMode === "view" ? true : false}
                label={<Trans>Description</Trans>}
                onChange={this.handleDescriptionChange}
                rows={4}
                value={this.props.description}
              />
              {this.state.descriptionFieldIsTouched && this.props.description.length === 0 && (
                <Warning><Trans>Please enter a description</Trans></Warning>
              )}
            </Container>
            <Container>
              <StyledSelect
                disabled={this.props.modalOpenInMode === "view" ? true : false}
                label={<Trans>Select Length</Trans>}
                value={this.props.length}
                onChange={this.handleLengthChange}
              >
                {lengthOptions.map(length => (
                  <Option key={length} value={length}>
                    <Trans>{length}</Trans>
                  </Option>
                ))}
              </StyledSelect>
            </Container>
            <Container>
              <StyledSelect
                disabled={this.props.modalOpenInMode === "view" ? true : false}
                label={<Trans>Select Difficulty</Trans>}
                value={this.props.difficulty}
                onChange={this.handleDifficultyChange}
              >
                {difficultyOptions.map(difficulty => (
                  <Option key={difficulty} value={difficulty}>
                    <Trans>{difficulty}</Trans>
                  </Option>
                ))}
              </StyledSelect>
            </Container>
            <Container>
              <StyledTextInput
                disabled={this.props.modalOpenInMode === "view" ? true : false}
                label={<Trans>Resource URL</Trans>}
                onChange={this.handleResourceChange}
                value={this.props.resource}
              />
              {this.state.resourceFieldIsTouched && !this.isValidURL(this.props.resource) && (
                <Warning><Trans>Please enter a valid URL</Trans></Warning>
              )}
            </Container>
          </ModalContent>
          {this.props.modalOpenInMode !== "view" &&
            <SubmitButtonContainer>
              <Button htmlType="submit" color="blue" translate="yes">
                {this.props.modalOpenInMode === "edit" ? "Update Action Plan" : "Create Action Plan"}
              </Button>
            </SubmitButtonContainer>
          }
        </Form>
      </StyledModal>
    );
  }
}

const ModalContent = styled.div`
  margin-top: 10px;
  padding-left: 32px !important;
  padding-right: 32px !important;
  display: flex;
  flex-direction: column;
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
  padding-left: 32px !important;
  padding-right: 32px !important;
`;

const StyledSelect = styled(Select)<SelectProps & {children: React.ReactNode}>`
  width: 535px;
`;

const StyledModal = styled(Modal)<ModalProps & {children: React.ReactNode}>`
  width: 600px !important;
  height: auto !important;
`;

const ActionTextInput = styled(Input)<InputProps>`
  .ant-input {
    width: 535px;
  }
`;

const StyledTextInput = styled(Input)<InputProps>`
  margin-top: 5 !important;
  .ant-input {
    width: 535px;
  }
`;

const StyledTextArea = styled(TextArea)`
  width: 535px !important;
  margin-top: 0 !important;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
`;

const Warning = styled.p`
  color: ${Palette.darkPink};
`;

export default CustomActionModal;
