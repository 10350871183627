import styled from "styled-components";

export const ActionContainer = styled.div`
  display: flex;
  margin-top: 32px;
  justify-content: flex-end;
  align-content: flex-end;

  & > button {
    margin-right: 32px;
  }

  & > button:last-child {
    margin-right: 0;
  }
`;
