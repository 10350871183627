// @ts-nocheck
import React from "react";
export function ReturnSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="13"
      viewBox="0 0 8 13"
    >
      <defs>
        {/* eslint-disable max-len */}
        <path id="returnA" d="M6.143 12a.867.867 0 0 1-.606-.244L1.25 7.59a.816.816 0 0 1 0-1.178l4.286-4.167a.873.873 0 0 1 1.212 0 .816.816 0 0 1 0 1.179L3.069 7l3.68 3.578a.816.816 0 0 1 0 1.178.867.867 0 0 1-.606.244"/>
        {/* eslint-disable max-len */}
      </defs>
      <use fill="#AAB9CE" fillRule="evenodd" xlinkHref="#returnA"/>
    </svg>
  );
}
