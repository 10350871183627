import React from "react";
import { isEmpty } from "@hyphen-lib/lang/Arrays";
import { Set as ImmutableSet } from "immutable";
import { DynamicRowProps } from "@components/core/DynamicRowsTable/withDynamicRows";
import { Post } from "hyphen-lib/dist/domain/Post";
import { PulsePollInfoResource } from "hyphen-lib/dist/domain/resource/PulsePollInfoResource";
import { PulsePollListOverviewResource } from "hyphen-lib/dist/domain/resource/PulsePollListOverviewResource";
import { AccessList } from "hyphen-lib/dist/domain/access/AccessList";
import Actions, {
  Action,
  ActionDefinition
} from "@src/screens/Insights/components/Actions";
import { PulsePollActionKeyType } from "@src/screens/Insights/components/Actions";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";

type ListResourceType = PulsePollInfoResource | PulsePollListOverviewResource;
interface ActionCellProps extends DynamicRowProps<ListResourceType> {
  onClick: (key: keyof PulsePollActionKeyType) => void;
  hasCreationRight: boolean;
}

export function PulsePollActionCell({
  row,
  onClick,
  hasCreationRight,
}: ActionCellProps) {
  const actions: ActionDefinition<PulsePollActionKeyType> = mapStatusToActions(
    row.frequency,
    row.status,
    row.isLastInstanceLaunched,
    row.role,
    hasCreationRight,
    row.instances.length
  );

  if (isEmpty(actions.actions) && isEmpty(actions.more)) {
    return null;
  }

  return (
    <Actions
      actions={actions}
      onClick={onClick}
      justifyContent={"flex-start"}
    />
  );
}

const ALL_ACTIONS: (Action<PulsePollActionKeyType> & {
  type: "classic" | "more" | "edit" | "delete";
  statuses: ImmutableSet<string>;
  roles: ImmutableSet<AccessList.UserAccessRole>;
  allowedWithCreationRight?: true;
})[] = [
  {
    key: "seeReport",
    label: "See report",
    type: "classic",
    statuses: ImmutableSet.of(
      Post.Status.ACTIVE,
      Post.Status.DEACTIVATED,
      Post.Status.CLOSED
    ),
    roles: ImmutableSet.of(
      AccessList.UserAccessRole.ADMIN,
      AccessList.UserAccessRole.CONVERSATION,
      AccessList.UserAccessRole.READ
    ),
  },
  {
    key: "deactivate",
    label: "Pause recurring poll",
    type: "more",
    statuses: ImmutableSet.of(Post.Status.ACTIVE),
    roles: ImmutableSet.of(AccessList.UserAccessRole.ADMIN),
  },
  {
    key: "reactivate",
    label: "Restart recurring poll",
    type: "more",
    statuses: ImmutableSet.of(Post.Status.DEACTIVATED),
    roles: ImmutableSet.of(AccessList.UserAccessRole.ADMIN),
  },
  {
    key: "edit",
    label: "Edit poll",
    type: "more",
    statuses: ImmutableSet.of(
      Post.Status.ACTIVE,
      Post.Status.DEACTIVATED,
      Post.Status.CLOSED
    ),
    roles: ImmutableSet.of(AccessList.UserAccessRole.ADMIN),
  },
  {
    key: "edit",
    label: "Edit",
    type: "edit",
    statuses: ImmutableSet.of(Post.Status.DRAFT),
    roles: ImmutableSet.of(AccessList.UserAccessRole.ADMIN),
  },
  {
    key: "delete",
    label: "Delete",
    type: "delete",
    statuses: ImmutableSet.of(Post.Status.DRAFT),
    roles: ImmutableSet.of(AccessList.UserAccessRole.ADMIN),
  },
  {
    key: "delete",
    label: "Delete",
    type: "more",
    statuses: ImmutableSet.of(
      Post.Status.ACTIVE,
      Post.Status.DEACTIVATED,
      Post.Status.CLOSED
    ),
    roles: ImmutableSet.of(AccessList.UserAccessRole.ADMIN),
  },
  {
    key: "close",
    label: "Close active iteration",
    type: "more",
    statuses: ImmutableSet.of("launched", "active"),
    roles: ImmutableSet.of(AccessList.UserAccessRole.ADMIN),
  },
];

function mapStatusToActions(
  frequency: Post.PollFrequency,
  status: Post.Status,
  isLastInstanceLaunched: boolean,
  role: Optional<AccessList.UserAccessRole>,
  hasCreationRight: boolean,
  totalInstances: number
): ActionDefinition<PulsePollActionKeyType> {
  let actions: Action<PulsePollActionKeyType>[] = [];
  let more: Action<PulsePollActionKeyType>[] = [];

  for (const action of ALL_ACTIONS) {
    let relevantStatus: string = status;
    const options: Dictionary<any> = {};
    let label: string = action.label;
    let actionKey: keyof PulsePollActionKeyType = action.key;

    if (action.key === "close") {
      relevantStatus = isLastInstanceLaunched ? "active" : "";
    }

    if (action.key === "seeReport" && totalInstances === 0) {
      options.buttonProps = { disabled: true };
      options.tooltip = "No iterations for the current poll";
    }

    if ((action.key === "deactivate" || action.key === "reactivate") && frequency === Post.PollFrequency.ONCE) {
      continue;
    }

    if (frequency === "once" && action.key === "close") {
      actionKey = "closeAndDeactivateFrequencyOncePoll";
      label = "Close poll";
    }

    if (
      action.statuses.has(relevantStatus) &&
      role &&
      action.roles.has(role) &&
      (hasCreationRight || action.allowedWithCreationRight !== true)
    ) {
      const bucket =
        action.type === "classic" || relevantStatus === "draft"
          ? actions
          : more;
      bucket.push({
        key: actionKey,
        label,
        ...options,
      });
    }
  }

  if (more.length < 2) {
    actions = [...actions, ...more];
    more = [];
  }

  return {
    actions,
    more,
  };
}
