import React from "react";
import { renderToString } from "react-dom/server";
import { LifeCycleDashboardResource } from "hyphen-lib/dist/domain/resource/report/LifeCycleResource";
import { Heading } from "@components/core/Typography";
import { Trans } from "react-i18next";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import { fromJS } from "immutable";
import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
import { isNotEmptyObject, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import ReactResizeDetector from "react-resize-detector";
import { isEmpty } from "hyphen-lib/dist/lang/Arrays";
import SelectDimension from "../../Survey/components/SelectDimension";
import {
  options,
  buildPlotBandsAndSeries,
  computeChartVitalStats,
  extractPhaseSummaries,
  extractPhaseSummary,
  showNoDataInPreSeparationPhases,
  showNoDataInSeparationPhase,
  computeIfPlotLabelOpen
} from "./utils";
import Tooltip from "./Tooltip";
import PlotBandLabel from "./PlotBandLabel";
import NoDataString from "./NoData";
import { plotLineXValue } from "./utils/plotLine";
import Annotation from "./Annotation";
import { computeOverlapWidth, nudgeLabelTop, objectIsEmpty, setWidthOfLabel } from "./utils/chartStyleUtils";

interface Props {
  dashboard: LifeCycleDashboardResource;
  dimensions: Optional<Dimensions>;
  onDimensionSelect: (dimension: string) => void;
  segmentedBy: string;
}

interface State {
  chartOptions: Highcharts.Options;
  summaryElements: Dictionary<Optional<boolean>>;
  windowWidth: number;
  windowHeight: number;
}

class FavorabilityByPhase extends React.Component<Props, State> {
  state: State = {
    chartOptions: options,
    summaryElements: {},
    windowWidth: 200,
    windowHeight: 900,
  };

  chartRef: any = React.createRef();
  preSeparationPhaseWidth = 300;
  plotBandLabelTop = 20;
  isPlotBandLabelOpen = false;
  yAxisGridLineTop = 100;
  labelGridLineOverlap = 0;
  gridLineWidth = 3;

  componentDidMount() {
    this.setState({
      summaryElements: this.initializeSummaryElements(),
    });
    window.addEventListener("resize", this.handleResize);
    this.configureHighChart();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      (prevProps.segmentedBy !== this.props.segmentedBy) ||
        (prevProps.dashboard._id !== this.props.dashboard._id)  ||
        (JSON.stringify(prevState.summaryElements) !== JSON.stringify(this.state.summaryElements)) ||
        (prevState.windowWidth !== this.state.windowWidth) ||
        (prevState.windowHeight !== this.state.windowHeight)
    ) {
      this.configureHighChart();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  };

  initializeSummaryElements = () => {
    const {dashboard: { favorabilityByPhase }} = this.props;
    const { totalNumberOfPhases } = computeChartVitalStats(extractPhaseSummaries(favorabilityByPhase));
    const summaryElements = {} as Dictionary<Optional<boolean>>;
    for (let i = 0; i < totalNumberOfPhases; i++) {
      summaryElements[i.toString()] = false;
    }
    return summaryElements;
  };

  onSummaryClick = (index: string) => () => this.setState(
    (prevState) => {
      return {
        ...prevState,
        summaryElements: {
          ...prevState.summaryElements,
          [index]: !prevState.summaryElements[index]},
      };
    });

  handleDimensionSelect = (dimension: string) => {
    this.props.onDimensionSelect(dimension);
  };

  configureHighChart = () => {
    const { dashboard: { favorabilityByPhase, votesPerSegments }, segmentedBy } = this.props;
    const { chartOptions, summaryElements } = this.state;
    if (!this.chartRef.current) {
      return ;
    }

    const optionsMap = fromJS(chartOptions);
    const mergingTooltip = fromJS({
      formatter: this.tooltipFormater(),
    });

    const chartOptionsWithTooltip = optionsMap.set(
      "tooltip",
      optionsMap.get("tooltip").merge(mergingTooltip)
    );
    const { plotLeft, plotWidth } = this.chartRef.current.chart;

    const optionWithInitialPlotBandsAndSeries = buildPlotBandsAndSeries(
      {
        favorabilityByPhase,
        initialOptions: chartOptionsWithTooltip,
        votesPerSegments,
        segmentedBy,
        plotLeft,
        plotWidth,
        labelStatuses: summaryElements }
    );
    this.setState({ chartOptions: optionWithInitialPlotBandsAndSeries }, () => {
      this.chartRef.current.chart.reflow();
      this.forceUpdate();
    });
  };

  tooltipFormater = () => {
    return function (this: Highcharts.TooltipFormatterContextObject): string {
      if (!this.points) {
        return "";
      }

      return renderToString(<Tooltip points={this.points}  />);
    };
  };

  extractDimensionKeys = () => {
    const dimensionKeys: string[] = ["None"];
    const { dimensions } = this.props;
    if (isNotNullNorUndefined(dimensions)) {
      dimensionKeys.push(...Object.keys(dimensions));
    }
    return dimensionKeys;
  };

  computeYAxisGridLineTop = () => {
    const chart = this.chartRef.current;
    if (chart && chart.chart && !isEmpty(chart.chart.axes)) {
      const yAxisGridGroupElement = chart.chart.axes[1].gridGroup.element;
      const { top } = yAxisGridGroupElement.getBoundingClientRect();
      this.yAxisGridLineTop = top;
    }
  };

  renderPlotBandLabels = () => {
    const chart = this.chartRef.current;
    const plotBandLabels: any = [];
    const { summaryElements } = this.state;
    const { dashboard: { favorabilityByPhase } } = this.props;
    const { totalNumberOfPhases } = computeChartVitalStats(extractPhaseSummaries(favorabilityByPhase));

    if (chart && chart.chart && chart.chart.xAxis) {
      let firstPlotBandLeft = 0;
      let index = 0;
      this.isPlotBandLabelOpen = computeIfPlotLabelOpen(summaryElements);
      Highcharts.objectEach(
        chart.chart.xAxis[0].ticks,
        (tick) => {
          if (isNotEmptyObject(tick) && tick.isFirst === undefined) {
            tick.mark.element.style.display = "none";
          }
        }
      );
      Highcharts.objectEach(
        chart.chart.xAxis[0].plotLinesAndBands,
        (plotLineOrBand, key, plotLineAndBands) => {
          if (index === 0) {
            const { left: plotBandLeft } = plotLineOrBand.svgElem.element.getBoundingClientRect();
            firstPlotBandLeft = plotBandLeft;
          }
          if (
            index === totalNumberOfPhases - 1 &&
            plotLineOrBand &&
            plotLineOrBand.label
          ) { // separation phase plot band
            const { left: plotLineXPosition } = plotLineOrBand.svgElem.element.getBoundingClientRect();
            this.preSeparationPhaseWidth = plotLineXPosition - firstPlotBandLeft;
            const { element: separationLabel } = plotLineOrBand.label;
            const { top, height } = separationLabel.getBoundingClientRect();
            this.plotBandLabelTop = top;
            this.computeYAxisGridLineTop();
            this.labelGridLineOverlap = computeOverlapWidth(top + height, this.yAxisGridLineTop);
            // fixme: these styles could not be set through css. No idea why. If that can be figured out
            // then these lines are not needed.
            // the 2px margin left is required so that the separationLabel does not sit over the plot line
            // without the 2px margin left the plot line will get covered
            separationLabel.style.marginLeft = "2px";
            separationLabel.style.width = "100%";
            if (this.isPlotBandLabelOpen) {
              nudgeLabelTop(separationLabel, this.labelGridLineOverlap, this.gridLineWidth);
            }
          }
          if (plotLineOrBand && plotLineOrBand.eventsAdded && plotLineOrBand.label && favorabilityByPhase[index]) {
            const {name, summary } = extractPhaseSummary(favorabilityByPhase[index]);
            if (
              index < totalNumberOfPhases - 1 &&
              plotLineOrBand &&
              plotLineAndBands[index + 1]
            ) { // pre-Separation phases
              // the styles have to be set dynamically because it cant be done through css.
              // if the widths of the plot band labels are not set then the label bg colors will
              // not stretch till the next plot band label
              const { element: thisLabel } = plotLineOrBand.label;
              const { bottom } = thisLabel.getBoundingClientRect();
              this.labelGridLineOverlap = computeOverlapWidth(bottom, this.yAxisGridLineTop);
              const { element: nextLabel } = plotLineAndBands[index + 1].label;
              const shouldAdjustForPlotLine = index === totalNumberOfPhases - 2;
              if (this.isPlotBandLabelOpen) {
                nudgeLabelTop(thisLabel, this.labelGridLineOverlap, this.gridLineWidth);
              }
              setWidthOfLabel(thisLabel, nextLabel, shouldAdjustForPlotLine);
            }
            plotBandLabels.push(
              <PlotBandLabel
                labelAnchor={plotLineOrBand.label.element}
                key={index.toString()}
                isLabelOpen={summaryElements[index.toString()]}
                name={name}
                summary={summary}
                onSummaryClick={this.onSummaryClick(index.toString())}
              />
            );
          }
          index = index + 1;
        }
      );
    }

    /* this call to render no data is required because the preSeparationPhaseWidth gets calculated
    only in this method. The call to renderPlotLine is also required here because plotBandLabelTop
    gets computed only in this method */
    this.renderNoData();
    this.renderPlotLine();
    return plotBandLabels;
  };

  renderNoData = () => {
    const chart = this.chartRef.current;
    const { dashboard: { favorabilityByPhase }, segmentedBy } = this.props;
    if (chart && chart.chart) {
      const {
        plotTop,
        plotHeight,
        plotWidth,
        renderer,
        noDataPreSeparation,
        noDataSeparation,
      } = chart.chart;

      const displayNoDataInPreSeparation = showNoDataInPreSeparationPhases(favorabilityByPhase, segmentedBy);
      const displayNoDataInSeparation = showNoDataInSeparationPhase(favorabilityByPhase, segmentedBy);
      const separationPhaseWidth = plotWidth - this.preSeparationPhaseWidth;
      const midChartY = (plotTop + plotHeight + 132) / 2;
      // ref: https://www.highcharts.com/forum/viewtopic.php?t=38132
      if (noDataPreSeparation && !objectIsEmpty(noDataPreSeparation)) {
        noDataPreSeparation.destroy();
      }

      if (noDataSeparation && !objectIsEmpty(noDataSeparation)) {
        noDataSeparation.destroy();
      }
      chart.chart.noDataPreSeparation = renderer.text(
        NoDataString("NoData1"),
        this.preSeparationPhaseWidth / 2,
        midChartY, true
      )

        .css({ display: displayNoDataInPreSeparation ? "block" : "none"})
        .add();

      chart.chart.noDataSeparation = renderer.text(
        NoDataString("NoData2"), // content
        this.preSeparationPhaseWidth + separationPhaseWidth / 2.5, // x
        midChartY, // y
        true // isHTML
      )
        .css({ display: displayNoDataInSeparation ? "block" : "none"})
        .add();
    }
  };

  renderPlotLine = () => {
    if (!this.chartRef.current) {
      return;
    }
    const { chart } = this.chartRef.current;

    if (!chart ) {
      return;
    }

    const containerTop = chart.container.getBoundingClientRect().top;
    const adjustForOpenLabels = this.isPlotBandLabelOpen ? 15 : 0;
    const totalLabelTopNudge = this.isPlotBandLabelOpen ? this.gridLineWidth + this.labelGridLineOverlap : 0;
    const lineToYValue = this.plotBandLabelTop - containerTop - adjustForOpenLabels - totalLabelTopNudge;
    const xAxis = chart.xAxis[0];
    const plotLineXValuePixels = xAxis.toPixels(plotLineXValue);
    const moveToY = chart.plotBox.height + chart.plotBox.y;
    const { plotLineSVG, renderer } = chart;
    if (plotLineSVG) {
      plotLineSVG.destroy();
    }

    chart.plotLineSVG = renderer.path([
      "M", plotLineXValuePixels, moveToY, "L", plotLineXValuePixels, lineToYValue ])
      .attr({
        "stroke-width": 2,
        stroke: Palette.bluePurple,
        zIndex: 126,
      })
      .add();
  };

  setChartDimensions = () => {
    if (this.chartRef.current && this.chartRef.current.chart) {
      this.chartRef.current.chart.reflow();
      this.forceUpdate();
    }
  };

  render() {
    const { dimensions, segmentedBy } = this.props;
    const { chartOptions } = this.state;
    const data = this.extractDimensionKeys();
    return (
      <Container>
        <Heading size="large">
          <HeadingLayout>
            <Trans>Favorability by Phase</Trans>
            <SelectDimension
              data={data}
              selected={segmentedBy || "None"}
              dimensions={dimensions}
              onChangeDimension={this.handleDimensionSelect}
              labelStyles={{ fontSize: "14"}}
            />
          </HeadingLayout>
        </Heading>
        <ChartContainer id="favorability-by-phase">
          <ReactResizeDetector handleHeight  handleWidth onResize={this.setChartDimensions} />
          <HighchartsReact
            highcharts={NoDataToDisplay(Highcharts)}
            options={chartOptions}
            ref={this.chartRef}
          />
          {this.renderPlotBandLabels()}
          <Annotation />
        </ChartContainer>
      </Container>
    );
  }
}

const Container = styled.div`
  padding: 32px;
  background: ${Palette.white};
  font-family: Lato, sans-serif;
  color: ${Palette.veryDarkBlueGrey};
  margin-top: 24px;
  @media print {
    padding-top: 70px;
  }
`;

const ChartContainer = styled.div`
  margin: 16px 24px 24px 24px;
`;

const HeadingLayout = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default FavorabilityByPhase;
