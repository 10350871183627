import React from "react";
import { Action } from "@hyphen-lib/domain/Action";
import Palette from "@src/config/theme/palette";
import { Tag } from "@components/core/Tag";

interface Props {
  status: Action.Status;
}

export function ActionPlanStatusTag({ status }: Props) {
  const { text, color, textColor } = mapStatusToTextAndColor(status);
  return <Tag color={color} textColor={textColor} translate="yes">{text}</Tag>;
};

function mapStatusToTextAndColor(status: Action.Status)
  : { text: string; color: string; textColor: string} {
  switch (status) {
    case Action.Status.TODO:
      return {
        text: "To do",
        color: Palette.darkPink,
        textColor: Palette.white,
      };

    case Action.Status.IN_PROGRESS:
      return {
        text: "In Progress",
        color: Palette.aquaBlue_50,
        textColor: Palette.veryDarkBlueGrey,
      };

    case Action.Status.COMPLETED:
      return {
        text: "Complete",
        color: Palette.darkModerateBlue,
        textColor: Palette.white,
      };

    case Action.Status.DISMISSED:
      return {
        text: "Dismissed",
        color: Palette.lightPeriwinkle,
        textColor: Palette.veryDarkBlueGrey,
      };
  }
}