import React from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter, RouteComponentProps } from "react-router-dom";

import { State } from "@store/types";
import { getAllDimensions, getCurrentUser } from "@screens/Insights/store/selectors";

import CreationLayout from "@components/layouts/CreationLayout";

import { Dimensions, shouldExcludeDirectManager } from "@hyphen-lib/domain/common/Dimensions";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";
import { Optional } from "@hyphen-lib/lang/Optionals";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";


import * as notificationFactory from "@src/store/notifications/notification-factory";
import displayNotification, { ShowNotificationAction } from "@src/store/notifications/actions";
import AreYouSureModal from "@src/components/core/AreYouSureModal";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { getUsersAndDimensionsState } from "./store/selectors";
import { UsersAndDimensionsStateProps } from "./store/reducers";
import { UpdateDimensionsRequestAction } from "./store/actions";
import AddUsersSuccess from "./containers/AddUsers/AddUsersSuccess";
import AddUsersProgress from "./containers/AddUsers/AddUsersProgress";
import AddUsers from "./containers/AddUsers";
import { AddEditDimensions } from "./containers/AddEditDimensions";
import { usersAndDimensionsActionCreators } from "./store/actions";

interface AddUsersAndDimensionsProps {
  dimensions: Optional<Dimensions>;
  currentUser: Optional<CurrentUserResource>;
  isChangedAfterLastUpdate: boolean;
  emails: string[];
  emailsToBeAdded: UsersAndDimensionsStateProps["emailsToBeAdded"];
}

interface AddUsersAndDimensionsActionProps {
  readonly updateCompanyDimensions: (companyName: string, dimensions: UpdateDimensionsRequestAction["payload"]) => void;
  readonly changedAfterLastUpdate: (payload: boolean) => void;
  readonly displayNotification: (payload: ShowNotificationAction["payload"]) => ShowNotificationAction;
}

export type OwnProps = RouteComponentProps;

type Props = OwnProps & AddUsersAndDimensionsProps & AddUsersAndDimensionsActionProps;

interface ComponentState {
  isCancelAddUsersModalOpen: boolean;
  isCancelEditDimensionsModalOpen: boolean;
}

export class AddUsersAndDimensions extends React.Component<Props, ComponentState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isCancelAddUsersModalOpen: false,
      isCancelEditDimensionsModalOpen: false,
    };
  }

  showSuccessNotification = (title: string, description: string, duration: number) => {
    const notification = notificationFactory.success(
      title,
      description,
      duration
    );
    this.props.displayNotification(notification);
  };

  showErrorNotification = (title: string, description: string, duration: number) => {
    const notification = notificationFactory.error(
      title,
      description,
      duration
    );
    this.props.displayNotification(notification);
  };

  onSave = () => {
    this.props.history.push("/users");
  };

  renderAddEditDimensions = (mode: "add" | "edit") => (props: any) => {
    const {
      dimensions,
      currentUser,
      updateCompanyDimensions,
      changedAfterLastUpdate,
      // history
    } = this.props;

    const dimensionsDict : Dictionary<string> = {};
    let companyName  = "";

    if (isNotNullNorUndefined(dimensions)) {
      Object.keys(dimensions).forEach(key => {
        const isAllowedDimension =  shouldExcludeDirectManager(key);
        if (key !== "manager" && isAllowedDimension) {
          dimensionsDict[key] = dimensions[key].label;
        }
      });
    }

    if (isNotNullNorUndefined(currentUser)) {
      if (isNotNullNorUndefined(currentUser.company)) {
        companyName = currentUser.company.name;
      }
    }

    return (
      <AddEditDimensions
        mode={mode}
        dimensions={dimensionsDict}
        companyName={companyName}
        updateCompanyDimensions={updateCompanyDimensions}
        changedAfterLastUpdate={changedAfterLastUpdate}
        showSuccessNotification={this.showSuccessNotification}
        showErrorNotification={this.showErrorNotification}
        onSave={this.onSave}
      />
    );
  };

  goBackToUsersPage = () => {
    this.props.changedAfterLastUpdate(false);
    this.props.history.push("/users");
  };

  closeCancelAddUsersModal = () => this.setState({ isCancelAddUsersModalOpen: false});
  closeCancelEditDimensionsModal = () => this.setState({ isCancelEditDimensionsModalOpen: false });

  shouldShowConfirmationForExit = (): boolean => {
    const { emails, emailsToBeAdded, location } = this.props;
    const { pathname, search } = location;
    const queryParams = parseQueryString(search);
    if (
      pathname === "/users/add" &&
      (isNotNullNorUndefined(queryParams.mode) && queryParams.mode === "manual") &&
      (isNotEmptyArray(emails) || isNotEmptyArray(emailsToBeAdded.toArray()))
    ) {
      return true;
    }
    return false;
  };

  showUsersPage = () => {
    if (this.shouldShowConfirmationForExit()) {
      this.setState({
        isCancelAddUsersModalOpen: true,
      });
    } else if (this.props.history.location.pathname.includes("/dimensions")) {
      if (this.props.isChangedAfterLastUpdate) {
        this.setState({
          isCancelEditDimensionsModalOpen: true,
        });
      } else {
        this.goBackToUsersPage();
      }
    } else {
      this.goBackToUsersPage();
    }
  };

  renderTitle = () => {
    if (this.props.location.pathname.includes("edit/dimensions")) {
      return "Edit dimensions";
    }
    return "Add users";
  };

  render() {
    const { isCancelAddUsersModalOpen, isCancelEditDimensionsModalOpen} = this.state;
    return (
      <>
        <CreationLayout
          title={this.renderTitle()}
          onCloseIconClick={this.showUsersPage}
        >
          <Switch>
            <Route path="/users/add/dimensions" render={this.renderAddEditDimensions("add")} />

            <Route path="/users/edit/dimensions" render={this.renderAddEditDimensions("edit")} />

            <Route path="/users/add" exact component={AddUsers} />
            <Route path="/users/add/progress" exact component={AddUsersProgress} />
            <Route path="/users/add/success" exact component={AddUsersSuccess} />
          </Switch>
        </CreationLayout>
        {
          isCancelAddUsersModalOpen &&
          <AreYouSureModal
            visible={isCancelAddUsersModalOpen}
            title="Are you sure?"
            description="If you leave now, your changes will not be saved. Any users you have added will be removed."
            onOk={this.goBackToUsersPage}
            onCancel={this.closeCancelAddUsersModal}
            okLabel="Discard changes"
            cancelLabel="Continue changes"
          />
        }

        {
          isCancelEditDimensionsModalOpen &&
          <AreYouSureModal
            visible={isCancelEditDimensionsModalOpen}
            title="Are you sure?"
            description=
              "If you leave now, your changes will not be saved. Any dimensions that you have added will be removed."
            onOk={this.goBackToUsersPage}
            onCancel={this.closeCancelEditDimensionsModal}
            okLabel="Discard changes"
            cancelLabel="Continue changes"
          />
        }
      </>
    );
  }
}

function mapStateToProps(state: State, routeComponentProps: RouteComponentProps): AddUsersAndDimensionsProps {
  const { emails, emailsToBeAdded, changedAfterLastUpdate } = getUsersAndDimensionsState(state);
  return {
    dimensions: getAllDimensions(state),
    currentUser: getCurrentUser(state),
    isChangedAfterLastUpdate: changedAfterLastUpdate,
    emails,
    emailsToBeAdded,
  };
}

const mapDispatchToProps = {
  updateCompanyDimensions: usersAndDimensionsActionCreators.updateCompanyDimensions,
  changedAfterLastUpdate: usersAndDimensionsActionCreators.changedAfterLastUpdate,
  displayNotification: displayNotification.displayNotification,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddUsersAndDimensions)
);
