import React from "react";
import styled from "styled-components";

import Palette from "@src/config/theme/palette";

import { PaginationConfig } from "antd/lib/pagination";
import { SorterResult, TableProps } from "antd/lib/table";
import { Icon } from "antd";
import { Heading } from "@components/core/Typography";
import Tooltip from "@components/core/Tooltip";
import Table from "@src/components/core/Table";
import { Table as AntdTable } from "antd";
import Checkbox from "@src/components/core/Checkbox";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { FilterParameter, SortParameter } from "@src/utils/networks";
import { PermissionsPageParameters } from "@screens/Insights/UserManagement/containers/UserPermissions/store/actions";
import { Trans, WithTranslation, withTranslation } from "react-i18next"; 
import { not } from "hyphen-lib/dist/lang/Booleans";
import { UserResource } from "hyphen-lib/dist/domain/resource/user/UserResource";
import {
  getOr,
  isNotEmptyObject,
  isNotNullNorUndefined,
  keys,
} from "hyphen-lib/dist/lang/Objects";
import { Dimensions, shouldExcludeDirectManager } from "hyphen-lib/dist/domain/common/Dimensions";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { UserPermissionResource } from "hyphen-lib/dist/domain/resource/user/UserPermissionResource";
import {
  DropDownButton,
  DropDownButtonProps,
} from "@components/core/DropDownButton";
import { Seq } from "immutable";
import { ScopeCell } from "@screens/Insights/UserManagement/cells/ScopeCell";
import AreYouSureModal from "@src/components/core/AreYouSureModal";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import Button from "@components/core/Button";
import { Optional } from "@hyphen-lib/lang/Optionals";
import {
  USER_PERMISSION_MODAL_MODES,
  permissionsModalContentMapper,
  ARE_YOU_SURE_MODAL_MODES,
} from "../../utils/constants";
import { BulkEditPermissionsModal } from "../BulkEditPermissionsModal";
import { EmailCell } from "../../cells/EmailCell";
import { checkIfHyphenAdmin } from "hyphen-lib/dist/business/user/Users";
import { downloadUsersInPermissionScope } from "@src/utils/exports";
import { SearchBar } from "@src/components/core/SearchBar";
import {
  AudienceEmailsModalComponent,
  SearchBarContainer,
} from "@src/screens/Insights/Surveys/components/AudienceEmailsModal";
import { ExportButton } from "@src/screens/Insights/PulsePoll/components/ReportHeader/ExportDropdown";
import { ExportSvg } from "@src/components/core/svg/ExportSvg";
import { User } from "hyphen-lib/dist/domain/User";
import { Paragraph } from "@components/core/Typography";
import NoResult from "@src/components/core/NoResult";
import { translate } from "@src/utils/i18next";

const ColumnStyledHeader = styled(Heading)<{ minWidth?: number }>`
  color: ${Palette.darkBlueGrey};
  min-width: ${(props) => `${props.minWidth}px`};
`;

export interface UserPermissionTableProps extends WithTranslation{
  readonly users: UserPermissionResource[];
  readonly onModifyList: (parameters: PermissionsPageParameters) => any;
  readonly page: PageFilter;
  readonly sort?: SortParameter;
  readonly filter?: FilterParameter;
  readonly total: number;
  readonly selectedUsersCount: number;
  readonly loading: boolean;
  readonly onSelectChange: (
    record: any,
    selected: any,
    selectedUsers: any
  ) => void;
  readonly onSelectAll: (
    selected: boolean,
    selectedRows?: any[],
    changeRows?: any[]
  ) => void;
  readonly onUpdateUserPermissions: (
    mode: USER_PERMISSION_MODAL_MODES,
    value: any
  ) => void;
  readonly dimensions: Dimensions;
  readonly isAreYouSureModalVisible: boolean;
  readonly toggleAreYouSureModalVisibility: (
    isAreYouSureModalVisible: boolean
  ) => any;
  readonly onUpdateUserPermission: (
    userId: string,
    userPermission: UserPermissionResource
  ) => any;
  readonly isModalVisible: boolean;
  readonly toggleModalVisibility: (isModalVisible: boolean) => void;
  readonly roles: string[];
  readonly selectedRowKeys: string[];
  readonly isBulkUpdateRequesting: boolean;
  readonly fetchUserScopeList: (rootUser: string) => void;
  readonly onRootUserSelect: (user: string) => void;
  readonly scopedUserList: User[];
}

interface UserTableStateProps {
  modalMode: USER_PERMISSION_MODAL_MODES;
  areYouSureModalMode: ARE_YOU_SURE_MODAL_MODES;
  clearDimensionForUser: Optional<UserPermissionResource>;
  dimensionToRemove: Optional<string>;
  dimensionsAdded: Dictionary<string[]>;
  downloadUserModalVisible: boolean;
  rootUser: string;
  searchText: string;
  scopeList: User[];
}

export interface DynamicHeaderProps {
  readonly record: any;
  readonly onActionClick: (mode: USER_PERMISSION_MODAL_MODES) => void;
}

export class PermissionsTable extends React.Component<
  UserPermissionTableProps,
  UserTableStateProps
> {
  constructor(props: UserPermissionTableProps) {
    super(props);

    this.state = {
      modalMode: "role",
      areYouSureModalMode: "customize",
      clearDimensionForUser: Optional.empty(),
      dimensionToRemove: Optional.empty(),
      dimensionsAdded: {},
      downloadUserModalVisible: false,
      rootUser: "",
      searchText: "",
      scopeList: [],
    };
  }

  componentDidUpdate(prevProps: UserPermissionTableProps) {
    if (prevProps.scopedUserList !== this.props.scopedUserList) {
      this.setState({
        scopeList: this.props.scopedUserList,
      });
    }
  }

  onHeaderRow = (record: any, rowIndex: number) => {
    return {
      record: {
        ...record,
        selectedCount: this.props.selectedUsersCount,
        onActionClick: (modalMode: USER_PERMISSION_MODAL_MODES) => {
          this.setState({ modalMode });
          this.props.toggleModalVisibility(true);
        },
      },
    };
  };

  onActionClick = (modalMode: USER_PERMISSION_MODAL_MODES) => {
    this.setState({ modalMode });
    this.props.toggleModalVisibility(true);
  };

  onChange = (
    pagination: PaginationConfig,
    __: any,
    sorter: SorterResult<UserPermissionResource>
  ) => {
    const { onModifyList, page, filter } = this.props;

    const pageParam = {
      size: getOr(pagination.pageSize, page.size),
      number: getOr(pagination.current, 1),
    };
    const sortParam: SortParameter = {};
    if (isNotEmptyObject(sorter) && isNotNullNorUndefined(sorter.order)) {
      sortParam[sorter.columnKey] = sorter.order === "ascend" ? 1 : -1;
    }
    onModifyList({
      filter,
      page: pageParam,
      sort: sortParam,
    });
  };

  onCustomizeClick =
    (record: UserPermissionResource) => (e: CheckboxChangeEvent) => {
      e.preventDefault();
      const { checked } = e.target;
      const { onUpdateUserPermission, toggleAreYouSureModalVisibility } =
        this.props;
      if (checked) {
        onUpdateUserPermission(record._id, {
          ...record,
          reportingDimensions: {},
        });
      } else {
        this.setState({
          areYouSureModalMode: "customize",
          clearDimensionForUser: record,
        });
        toggleAreYouSureModalVisibility(true);
      }
    };

  onDimensionSelect = (record: UserPermissionResource) => (optionKey: any) => {
    const { dimensionsAdded } = this.state;
    const userDimensions = getOr(dimensionsAdded[record._id], []);
    userDimensions.push(optionKey);
    dimensionsAdded[record._id] = userDimensions;
    this.setState({ dimensionsAdded });
  };

  removeDimensionInState = (
    user: UserPermissionResource,
    dimension: string
  ) => {
    const { dimensionsAdded } = this.state;
    let userDimensions = getOr(dimensionsAdded[user._id], []);
    userDimensions = userDimensions.filter((dim) => dim !== dimension);
    dimensionsAdded[user._id] = userDimensions;
    this.setState({ dimensionsAdded });
  };

  getAreYouSureModalContent = (): {
    description: string;
  } => {
    const { areYouSureModalMode } = this.state;
    switch (areYouSureModalMode) {
      case "customize":
        return {
          description: "All custom dimension will be removed.",
        };
      case "clearDimension":
        return {
          description: "Dimension will be removed.",
        };
      default:
        return {
          description: "",
        };
    }
  };

  onDimensionClear = (
    record: UserPermissionResource,
    dimensionToRemove: string
  ) => {
    this.setState({
      areYouSureModalMode: "clearDimension",
      clearDimensionForUser: record,
      dimensionToRemove,
    });
    this.props.toggleAreYouSureModalVisibility(true);
  };

  onAreYouSureOkClick = () => {
    const { onUpdateUserPermission, toggleAreYouSureModalVisibility } =
      this.props;
    const { areYouSureModalMode } = this.state;
    if (areYouSureModalMode === "customize") {
      const { clearDimensionForUser } = this.state;
      if (isNotNullNorUndefined(clearDimensionForUser)) {
        const updatedUser = { ...clearDimensionForUser };
        delete (updatedUser as any).reportingDimensions;
        onUpdateUserPermission(updatedUser._id, updatedUser);
      }
    } else if (areYouSureModalMode === "clearDimension") {
      const { clearDimensionForUser, dimensionToRemove } = this.state;
      if (
        isNotNullNorUndefined(clearDimensionForUser) &&
        isNotNullNorUndefined(dimensionToRemove)
      ) {
        let userToUpdate = { ...clearDimensionForUser };
        const { reportingDimensions } = userToUpdate;
        if (isNotNullNorUndefined(reportingDimensions)) {
          const { [dimensionToRemove]: removedDimension, ...rest } =
            reportingDimensions;
          userToUpdate = { ...userToUpdate, reportingDimensions: rest };
        }
        this.removeDimensionInState(userToUpdate, dimensionToRemove);
        onUpdateUserPermission(userToUpdate._id, userToUpdate);
      }
    }
    this.setState({ clearDimensionForUser: Optional.empty() });
    toggleAreYouSureModalVisibility(false);
  };

  onAreYouSureCancelClick = () => {
    const { toggleAreYouSureModalVisibility } = this.props;
    this.setState({
      clearDimensionForUser: Optional.empty(),
      dimensionToRemove: Optional.empty(),
    });
    toggleAreYouSureModalVisibility(false);
  };

  onSubmit = (value: any) => {
    this.props.onUpdateUserPermissions(this.state.modalMode, value);
  };

  onSelectAllSegments =
    (record: UserPermissionResource) => (dimension: string) => {
      const { onUpdateUserPermission } = this.props;
      this.onDimensionSelect(record)(dimension);
      let userToUpdate = { ...record };
      const { reportingDimensions } = userToUpdate;
      if (isNotNullNorUndefined(reportingDimensions)) {
        const { [dimension]: removedDimension, ...rest } = reportingDimensions;
        userToUpdate = { ...userToUpdate, reportingDimensions: rest };
      }
      onUpdateUserPermission(userToUpdate._id, userToUpdate);
    };

  onSegmentsChange =
    (record: UserPermissionResource) => (dimensions: Dictionary<string[]>) => {
      const { onUpdateUserPermission } = this.props;
      keys(dimensions).forEach((val) => {
        this.removeDimensionInState(record, val);
      });
      // @ts-ignore
      onUpdateUserPermission(record._id, {
        ...record,
        reportingDimensions: dimensions,
      });
    };

  renderScopeCell = (user: UserPermissionResource) => {
    const { dimensions } = this.props;
    const { dimensionsAdded } = this.state;
    if (isNotNullNorUndefined(user.reportingDimensions)) {
      return (
        <ScopeCell
          allDimensions={dimensions}
          userDimensions={user.reportingDimensions}
          dimensionsAddedInState={getOr(dimensionsAdded[user._id], [])}
          onSegmentsChange={this.onSegmentsChange(user)}
          onSelectAllSegments={this.onSelectAllSegments(user)}
          onCloseIconClick={this.onDimensionClear.bind(null, user)}
        />
      );
    } else {
      return null;
    }
  };

  openUserDownloadModal(rootUser: string) {
    this.props.onRootUserSelect(rootUser);
    this.props.fetchUserScopeList(rootUser);
    this.setState({
      downloadUserModalVisible: true,
      rootUser,
    });
  }

  onSearchChange = (e: any) => {
    this.setState({ searchText: e.target.value.trim() });
  };

  getFilteredUsers() {
    return this.state.scopeList.filter(
      (user) => user.email.indexOf(this.state.searchText.toLowerCase()) > -1
    );
  }

  scopeLabel(record: any) {
    const { t } = this.props;
    const totalUserVisible = getOr(record.scope, 0);
    const seeingLabel = `${translate(t, "Seeing")} ${totalUserVisible} ${translate(t, "employees")}`;
    return seeingLabel;
    // const totalUserVisible = getOr(record.scope, 0);
    // const seeingLabel = "Seeing " + totalUserVisible + " employees";
    // return seeingLabel;
  }

  makeInfoAsHyperLink(record: any) {
    const totalUserVisible = getOr(record.scope, 0);
    if (totalUserVisible === 0) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    const {
      users,
      page,
      total,
      loading,
      onSelectChange,
      selectedUsersCount,
      dimensions,
      isModalVisible,
      selectedRowKeys,
      roles,
      onSelectAll,
      isBulkUpdateRequesting,
      isAreYouSureModalVisible,
    } = this.props;
    const { modalMode } = this.state;

    const rowSelection = {
      onSelect: onSelectChange,
      selectedRowKeys,
      fixed: true,
      onSelectAll,
      getCheckboxProps: (record: UserResource) => {
        if (checkIfHyphenAdmin(record.email)) {
          return {
            disabled: true,
            checked: false,
          };
        } else {
          return {};
        }
      },
    };
    const availableOptions = permissionsModalContentMapper(
      modalMode
    ).getSelectOptions({
      roles,
    });

    return (
      <RelativeDiv>
        {selectedUsersCount > 0 && (
          <DynamicHeaderRow>
            <StyledTh>
              {selectedUsersCount} <Trans>selected</Trans>
              <Actions>
                <Button onClick={this.onActionClick.bind(null, "role")} translate="yes">
                  Edit roles
                </Button>
                <Button onClick={this.onActionClick.bind(null, "access")} translate="yes">
                  Edit access
                </Button>
              </Actions>
            </StyledTh>
          </DynamicHeaderRow>
        )}
        <StyledTable
          scroll={{ x: "max-content" }}
          userCount={users.length}
          columns={[
            {
              title: (
                <ColumnStyledHeader size="small" translate="yes" minWidth={300}>Email/ID</ColumnStyledHeader>
              ),
              dataIndex: "email",
              key: "email",
              fixed: "left",
              sorter: true,
              sortDirections: ["ascend", "descend"],
              render: (__: any, record) => (
                <EmailCell
                  email={record.email}
                  info={this.scopeLabel(record)}
                  makeInfoAsHyperLink={this.makeInfoAsHyperLink(record)}
                  onClickHyperLinkAction={() =>
                    this.openUserDownloadModal(record.email)
                  }
                />
              ),
            },
            {
              title: (
                <ColumnStyledHeader minWidth={150} size="small" translate="yes">
                  <Trans>Role</Trans>
                  <Tooltip title={<Trans>The user's assigned role</Trans>}>
                    <HelpIcon type="info-circle" />
                  </Tooltip>
                </ColumnStyledHeader>
              ),
              dataIndex: "role",
              key: "role",
              width: 10,
              sorter: true,
              sortDirections: ["ascend", "descend"],
              render: (__: any, record) => <RoleCell>{record.role}</RoleCell>,
            },
            {
              title: (
                <ColumnStyledHeader minWidth={200} size="small" translate="yes">
                  {<Trans>Engage Insights Access</Trans>}
                  <Tooltip title={<Trans>Whether the user has access to Engage Insights</Trans>}>
                    <HelpIcon type="info-circle" />
                  </Tooltip>
                </ColumnStyledHeader>
              ),
              dataIndex: "hasInsightsAccess",
              key: "hasInsightsAccess",
              width: 20,
              sorter: true,
              sortDirections: ["ascend", "descend"],
              render: (hasInsightsAccess) =>
                not(hasInsightsAccess) ? "No" : "Yes",
            },
            {
              title: (
                <ColumnStyledHeader minWidth={200} size="small" translate="yes">
                  <Trans>Custom scope permission</Trans>
                  <Tooltip title={<Trans>
                    Checking the box will allow the customization of the available dimensions
                    </Trans>}>
                    <HelpIcon type="info-circle" />
                  </Tooltip>
                </ColumnStyledHeader>
              ),
              dataIndex: "isCustomPermission",
              key: "isCustomPermission",
              width: 300,
              render: (__: any, record) => (
                <Checkbox
                  checked={isNotNullNorUndefined(record.reportingDimensions)}
                  onChange={this.onCustomizeClick(record)}
                  disabled={checkIfHyphenAdmin(record.email)}
                >
                  <Trans>Customise</Trans>
                </Checkbox>
              ),
            },
            {
              title: "",
              dataIndex: "addDimension",
              key: "addDimension",
              width: 150,
              render: (__: any, record) => {
                if (!checkIfHyphenAdmin(record.email)) {
                  const availableDimensions = keys(
                    getOr(record.reportingDimensions, {})
                  );
                  const { dimensionsAdded } = this.state;
                  const dimensionsInLocalState = getOr(
                    dimensionsAdded[record._id],
                    []
                  );
                  const options = Seq(keys(dimensions).toSet())
                    .filter(
                      (key) =>
                        !availableDimensions.includes(key) &&
                        dimensionsInLocalState.indexOf(key) === -1 &&
                        !key.includes("Separation") &&
                        shouldExcludeDirectManager(key)
                    ) // scope will seldom be set based on dynamicSeparation dimension
                    .map((key) => {
                      return { key, label: dimensions[key].label };
                    });
                  return isNotNullNorUndefined(record.reportingDimensions) ? (
                    <StyledDropDownButton
                      options={options.toArray()}
                      onClick={this.onDimensionSelect(record)}
                      noValues="All dimensions added"
                    >
                      <Trans>Add a dimension</Trans>
                    </StyledDropDownButton>
                  ) : null;
                }
              },
            },
            {
              title: "",
              dataIndex: "reportingDimensions",
              key: "reportingDimensions",
              render: (__: any, record) => this.renderScopeCell(record),
            },
          ]}
          dataSource={users}
          rowSelection={rowSelection}
          rowKey={"_id"}
          pagination={{
            total,
            pageSize: page.size,
            current: page.number,
            showTotal: (totalDocuments: number, range: number[]) =>
              <Trans i18nKey="userTableCount"
              values={{start: range[0], end: range[1], total: totalDocuments}} 
              defaults={`Showing ${range[0]} to ${range[1]} of ${totalDocuments} users`}/>,
          }}
          onChange={this.onChange}
          loading={loading}
        />
        <AreYouSureModal
          visible={isAreYouSureModalVisible}
          title="Are you sure?"
          description={this.getAreYouSureModalContent().description}
          okLabel="Ok"
          cancelLabel="Cancel"
          onCancel={this.onAreYouSureCancelClick}
          onOk={this.onAreYouSureOkClick}
        />
        {isModalVisible && (
          <BulkEditPermissionsModal
            visible={isModalVisible}
            selectedUsersCount={selectedUsersCount}
            mode={modalMode}
            onCancel={this.props.toggleModalVisibility.bind(null, false)}
            availableOptions={availableOptions}
            onSubmit={this.onSubmit}
            isLoading={isBulkUpdateRequesting}
          />
        )}

        <AudienceEmailsModalComponent
          footer={null}
          visible={this.state.downloadUserModalVisible}
          title={
            <div className="d-flex flex-row">
              <div className="mr-auto"><Trans>List of employees being seen</Trans></div>
              <div style={{ marginRight: "2em" }}>
                <ExportButton
                  color="gradation"
                  disabled={this.state.scopeList.length === 0}
                  onClick={() =>
                    downloadUsersInPermissionScope(
                      this.state.rootUser,
                      this.props.filter
                    )
                  }
                >
                  <Icon component={ExportSvg} />
                  <span><Trans>Export CSV</Trans></span>
                </ExportButton>
              </div>
            </div>
          }
          centered={true}
          onCancel={() => this.setState({ downloadUserModalVisible: false })}
        >
          <Description>
            <Trans>The following employees are being seen by</Trans> {this.state.rootUser}, 
            <Trans>as per the current user data uploaded in the system</Trans>
          </Description>
          <SearchBarContainer>
            <SearchBar placeholder="Search" onChange={this.onSearchChange} />
          </SearchBarContainer>
          <AudienceEmailsModalContent scopeList={this.getFilteredUsers()} />
        </AudienceEmailsModalComponent>
      </RelativeDiv>
    );
  }
}

interface UserPermissionProps {
  scopeList: User[];
}

const AudienceEmailsModalContent = (props: UserPermissionProps) => {
  const { scopeList } = props;
  const columns = [
    {
      title: (<Trans>Email</Trans>),
      dataIndex: "email",
      key: "email",
      width: 320,
    },
    {
      title: (<Trans>Name</Trans>),
      dataIndex: "name",
      render: (text: any, record: any) => {
        return `${getOr(record.firstName, "")} ${getOr(record.lastName, "")}`;
      },
      width: 250,
    },
  ];

  return (
    <AudienceEmailsTable
      pagination={false}
      columns={columns}
      dataSource={scopeList}
      rowKey="_id"
      scroll={{ y: 420 }}
      locale={{
        emptyText: (<NoResult type="minimal" description="No Data" />),
      }}
    />
  );
};

export default withTranslation()(PermissionsTable);

const Description = styled(Paragraph)`
  padding: 0px 32px 10px 32px;
  color: ${Palette.bluishGrey};
`;
const AudienceEmailsTable = styled(AntdTable)<TableProps<any>>`
  th,
  td {
    padding: 20px 32px !important;
  }
  .ant-table-header {
    overflow: hidden !important;
  }
`;

const RelativeDiv = styled.div`
  position: relative;
`;

const RoleCell = styled.div`
  padding-left: 8px;
`;

const DynamicHeaderRow = styled.div`
  position: absolute;
  left: 64px;
  height: 49px;
  color: ${Palette.bluishGrey} !important;
  font-weight: normal !important;
  padding-top: 6px !important;
  padding-left: 20px;
  z-index: 100;
  width: 100%;
  background-color: white;
`;

const StyledTh = styled.div`
  text-align: left !important;
  min-width: 346px;
  opacity: 1 !important;
  display: flex;
  align-items: center;
`;

const HelpIcon = styled(Icon)`
  margin-left: 8px;
  cursor: pointer !important;
  svg {
    fill: ${Palette.bluishGrey};
  }
`;

const StyledDropDownButton = styled(DropDownButton)<DropDownButtonProps>`
  text-align: left;
  width: 170px;
  height: 36px;
  border-radius: 3px;
  border: solid 1px ${Palette.lightGreyBlue};
  background-image: linear-gradient(to bottom, #ffffff, ${Palette.athensGrey});
`;

const Actions = styled.div`
  margin-left: 32px !important;

  button {
    height: 36px !important;
    margin-left: 32px !important;
  }
`;

const StyledTable = styled(Table)<TableProps<any> & { userCount: number }>`
  .ant-table-fixed-left table thead tr th:last-child {
    padding-right: 4px;
  }

  ${(props) =>
    props.userCount <= 3
      ? `table {
        padding-bottom: 300px !important;
      }`
      : ""};
`;
