import { isNullOrUndefined } from "util";
import { AccessResource } from "hyphen-lib/dist/domain/access/AccessResource";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { PulsePollResource } from "hyphen-lib/dist/domain/resource/PulsePollResource";
import { MergeTagResource } from "hyphen-lib/dist/domain/resource/MergeTagResource";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import Logger from "hyphen-lib/dist/util/Logger";
import { State } from "@src/store/types";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";
import { goTo } from "@src/utils/locations";
import { isNotNullNorUndefined, mapOr, getOr } from "hyphen-lib/dist/lang/Objects";
import { List as ImmutableList } from "immutable";
import React from "react";
import { getPulsePollResourceErrors } from "@src/utils/formValidations";
import LabelMapper from "@src/utils/constants/PulsePollCreation";
import { NetworkEventSuccessAction } from "@src/store/network/actions";
import { ShowNotificationAction } from "@src/store/notifications/actions";
import * as NotificationFactory from "@src/store/notifications/notification-factory";
import { AccessList } from "hyphen-lib/dist/domain/access/AccessList";
import CreationLayout from "@src/components/layouts/CreationLayout";
import ContainerCard from "@src/components/core/ContainerCard";
import styled from "styled-components";
import Modal from "@src/components/core/Modal";
import Stepper, { StepProps, STEP_STATUS } from "@src/components/core/Stepper";
import Button from "@src/components/core/Button";
import AreYouSureModal from "@src/components/core/AreYouSureModal";
import Spin from "@src/components/core/Spin";
import { Post } from "hyphen-lib/dist/domain/Post";
import { not } from "hyphen-lib/dist/lang/Booleans";
import moment from "moment";
import { Row, Col } from "antd";
import NavigationPrompt from "react-router-navigation-prompt";
import { Heading, Paragraph } from "@src/components/core/Typography";
import { Audience as AudienceType } from "hyphen-lib/dist/domain/common/Audience";
import {
  getStepIndex,
  getStepLabel,
  StepDefinition,
  getStepByKey,
  getStep
} from "@src/utils/wizard/Steps";
import { MapStateToProps, connect } from "react-redux";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { getExistingNotPaginatedPage, getResourceById, getUntypedElementById } from "@src/store/network/selectors";
import { PostCategoryResource } from "hyphen-lib/dist/domain/resource/post/PostCategoryResource";
import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { ParticipantResource } from "hyphen-lib/dist/domain/resource/participants/ParticipantResource";
import { ParticipantCountResource } from "hyphen-lib/dist/domain/resource/participants/ParticipantCountResource";
import { getManageesId, fetchManageesIfNeeded, getEligibleManagersCountId } from "@src/store/network/resource/Untypeds";
import { identity, noOp } from "hyphen-lib/dist/lang/Functions";
import { fetchPulsePollParticipantsIfNeeded } from "@src/store/network/resource/ParticipantResources";
import { QuestionConfig } from "hyphen-lib/dist/domain/common/QuestionType";
import Access from "../../components/Wizard/Access/Access";
import { getPulsePollMergeTags } from "../utils/helpers";
import { ActionContainer } from "../../components/Wizard/layout/ActionContainer";
import PulsePollSummary from "../../components/Wizard/Summary/PulsePollSummary";
import { getReportingDimMgmts } from "../../Surveys/store/surveyEditSelectors";
import Audience from "../../components/Wizard/Audience/container/Audience";
import { getImposedDimensions, getPostCategorys } from "../../Surveys/store/selectors";
import { pulsePollActionCreators } from "../store/pulsePollEditActions";
import { getCurrentUser, getCompany, getRightsMatcher } from "../../store/selectors";
import {
  getPulsePollEditState, getSpecificPulsePollAudienceEmails, getPulsePoll, getPulsePollAccesses
} from "../store/pulsePollEditSelectors";
import { getAllPulsePollStepDefinitions } from "../utils/StepDefinitions";
import {
  PulsePollDisabledFields,
  PulsePollStepValue
} from "../store/pulsePollEditTypes";
import { ContinueAlert } from "../../components/Wizard/layout/ContinueAlert";
import { NextButton } from "../../components/Wizard/layout/NextButton";
import { FetchError } from "../../errors/FetchError";
import { Question } from "./steps/Question";
import { Settings } from "./steps/Settings";
import { UserEmailInsightAccess } from "hyphen-lib/dist/domain/User";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { downloadPulsePollsRecipients } from "@src/utils/exports";
import { Trans, WithTranslation, withTranslation } from "react-i18next";
import { translate } from "@src/utils/i18next";
import { checkIfBusinessHours } from "hyphen-lib/dist/domain/Company";
import { hourOfDay } from "../../Settings/components/ActionPlanSettings";
import {
  UpdateUserPermissionsPayload,
  userPermissionsActionCreators
} from "../../UserManagement/containers/UserPermissions/store/actions";
import { SelectionFilter } from "hyphen-lib/dist/domain/parameter/SelectionFilter";
import { InsightsAccessAlter } from "../../components/Wizard/layout/InsightsAccessAlter";
import { Rights } from "hyphen-lib/dist/business/auth/Rights";
import displayNotification from "@src/store/notifications/actions";
import { getUserPermissionsStateProps } from "../../UserManagement/containers/UserPermissions/store/selectors";

const log = Logger.create("PulsePollEdit");

interface MatchParams {
  readonly id: string;
  readonly currentStepKey: string;
}

export type OwnProps = RouteComponentProps<MatchParams>;

interface PulsePollEditState {
  readonly isCancelPulsePollCreationModalOpen: boolean;
  readonly isPulsePollPreviewValidationModalOpen: boolean;
  readonly isContinueAlertModalOpen: boolean;
  readonly isPulsePollPreviewModalOpen: boolean;
  readonly tempAccessListHolder: AccessResource[];
  readonly missingFields: string[];
  readonly showBusinessHoursModal: boolean;
  readonly isInsightsAccessModalOpen: boolean;
  // fixme: we might want to store other things here?! or put everything in the redux store...
  // fixme: like the preview modal open, or other internal state to this component
}

interface PulsePollEditStateProps {
  readonly userId: string;
  readonly pulsePollId: string;
  readonly pulsePoll: Loadable<PulsePollResource>;
  readonly updatedAt: Date;
  readonly disabled: PulsePollDisabledFields;
  readonly allowedMergeTags: MergeTagResource[];
  readonly isPreviewModalOpen: boolean;
  readonly errors: WrongEntityException.Errors;
  readonly steps: ImmutableList<StepProps>;
  readonly dimensions: Dimensions;
  readonly reportingDimensions: Dictionary<string[]>;
  readonly participants: Loadable<ParticipantResource[]>;
  readonly participantsCount: Loadable<ParticipantCountResource>;
  readonly manageeEmails: UserEmailInsightAccess[];
  readonly currentStep: StepDefinition<PulsePollStepValue>;
  readonly allowedChannels: CompanyResource.Channels;
  readonly hasPendingUpdates: boolean;
  readonly categories: PostCategoryResource[];
  readonly currentUser: Optional<CurrentUserResource>;
  readonly isTouched: boolean;
  readonly specificAudienceEmails: Optional<UserEmailInsightAccess[]>;
  readonly questionConfig: QuestionConfig;
  readonly isMultiSelectQuestionEnabled: boolean;
  readonly canEditUser: boolean;
  readonly isBulkUpdateRequestSuccess: boolean;
  // accesses
  readonly accesses: AccessResource[];
  readonly usersWithoutInsightsAccess: AccessResource[];
  readonly eligibleManagersCount: Loadable<number>;
  readonly company: CompanyResource;
}

export interface PulsePollEditActionProps {
  readonly onFetchPulsePoll: (pulsePollId: string) => void;
  readonly onCreatePulsePoll: () => void;
  readonly onFetchManageeEmails: (userId: string) => void;
  readonly onUpdatePulsePoll: (
    pulsePollId: string,
    pulsePoll: PulsePollResource,
    questionConfig: QuestionConfig
  ) => void;
  readonly onSavePulsePoll: (
    pulsePollId: string,
    pulsePoll: PulsePollResource,
    questionConfig: QuestionConfig
  ) => void;
  readonly onActivatePulsePoll: (pulsePollId: string) => void;
  readonly onSendPulsePollPreview: (
    pulsePollId: string,
    previewEmails: string[],
    message: string,
    onSuccessRedirect?: (payload: NetworkEventSuccessAction["payload"]) => void
  ) => void;
  readonly displayNotification: (
    payload: ShowNotificationAction["payload"]
  ) => ShowNotificationAction;
  readonly onToggleManagersSynchronization: (
    pulsePollId: string,
    areManagersSynchronized: boolean
  ) => any;
  readonly onAddAccesses: (pulsePollId: string, emails: string[]) => any;
  readonly onUpdateAccess: (pulsePollId: string, accessId: string, role: AccessList.UserAccessRole) => any;
  readonly onRemoveAccess: (pulsePollId: string, accessId: string) => any;
  readonly onTouched: () => void;
  // audience
  readonly onFetchParticipants: (pulsePollId: string, pulsePoll: PulsePollResource) => void;

  readonly onExitPage: (pulsePollId: string) => void;
  readonly updateUsers: (payload: UpdateUserPermissionsPayload) => void;

}

export type PulsePollEditProps = OwnProps &
  PulsePollEditStateProps &
  PulsePollEditActionProps & WithTranslation;

export class PulsePollEditContainer extends React.Component<
  PulsePollEditProps,
  PulsePollEditState
> {
  defaultState: PulsePollEditState = {
    isCancelPulsePollCreationModalOpen: false,
    isPulsePollPreviewValidationModalOpen: false,
    isPulsePollPreviewModalOpen: false,
    isContinueAlertModalOpen: false,
    missingFields: [],
    tempAccessListHolder: [],
    showBusinessHoursModal: false,
    isInsightsAccessModalOpen: false,
  };
  private readonly contentWrapper: React.RefObject<HTMLDivElement>;
  private readonly AccessIntroduction: React.ReactNode = (
    <div>
      <div>
        <Trans>Decide what kind of access select employees will have to this survey.</Trans>
      </div>
      <div>
        <Trans>'View' can access the survey reports but cannot change settings or take
          action.</Trans>
      </div>
      <div>
        <Trans>'View + Messaging' can access the survey reports and initiate private
          messages with survey respondents.</Trans>
      </div>
      <div>
        <Trans>'All Access' can access survey reports and private messages but can also
          edit the survey settings.</Trans>
      </div>
      <br />
    </div>
  );

  constructor(props: PulsePollEditProps) {
    super(props);
    // create a ref, in order to be able to manage the scroll to top, when navigating through steps
    this.contentWrapper = React.createRef();
    this.state = this.defaultState;
  }

  componentDidMount() {
    const {
      userId,
      pulsePollId,
      onFetchPulsePoll,
      onCreatePulsePoll,
      onFetchManageeEmails,
    } = this.props;

    if (isNullOrUndefined(pulsePollId)) {
      onCreatePulsePoll();
    } else {
      onFetchPulsePoll(pulsePollId);
    }

    onFetchManageeEmails(userId);

  }

  componentDidUpdate(prevProps: PulsePollEditStateProps): void {
    const { onFetchPulsePoll,
      pulsePollId,
      isBulkUpdateRequestSuccess
    } = this.props;

    if (isBulkUpdateRequestSuccess && isBulkUpdateRequestSuccess !== prevProps.isBulkUpdateRequestSuccess) {
      onFetchPulsePoll(pulsePollId);
    }
  };

  componentWillUnmount() {
    const { pulsePollId, onExitPage } = this.props;

    onExitPage(pulsePollId);
  };

  moveToStep = (selectedStep: string) => {
    goTo(selectedStep);
  };

  onNextStep = () => {
    const {
      pulsePollId,
      pulsePoll: loadablePulsePoll,
      steps,
      currentStep,
      onUpdatePulsePoll,
      questionConfig,
    } = this.props;

    if (Loadable.isNotLoaded(loadablePulsePoll)) {
      return; // do nothing, poll is not loaded
    }

    const pulsePoll = loadablePulsePoll.value;

    const nextStep = currentStep.nextStep;
    if (isNotNullNorUndefined(nextStep)) {
      let nextStepIndex = getStepIndex(steps, nextStep);
      if (isNullOrUndefined(nextStepIndex)) {
        log.warn(`Unable to get the index for the next step: ${nextStep}`);
        nextStepIndex = 0;
      }

      if (isNotNullNorUndefined(pulsePoll.maxReachedStep) && pulsePoll.maxReachedStep < nextStepIndex + 1) {
        // Update the poll steps here
        onUpdatePulsePoll(pulsePollId, {
          ...pulsePoll,
          maxReachedStep: (nextStepIndex + 1) as 1 | 2 | 3 | 4 | 5,
        }, questionConfig);
      }

      goTo(nextStep);
      // Scroll to the top of the next page
      if (isNotNullNorUndefined(this.contentWrapper.current)) {
        this.contentWrapper.current.scrollTop = 0;
      }
    }
  };

  isPulsePollConsistent = () => {
    const { pulsePoll, currentStep, questionConfig } = this.props;

    if (Loadable.isNotLoaded(pulsePoll)) {
      return {
        isConsistent: null,
      };
    }

    const checkers = {};
    const allErrors = getPulsePollResourceErrors(this.props.t, pulsePoll.value, questionConfig, checkers);

    const errorKeys = allErrors
      .keySeq()
      .filter(key => LabelMapper.hasIn([currentStep, key]))
      .toArray();

    return {
      isConsistent: errorKeys.length > 0,
      errorKeys,
    };
  };

  checkPulsePollConsistencyBeforeNextStep = () => {
    const {
      currentStep,
      steps,
      usersWithoutInsightsAccess,
      pulsePoll: loadablePulsePoll,
      canEditUser
    } = this.props;

    let pulsePoll;
    if (Loadable.isLoaded(loadablePulsePoll)) {
      pulsePoll = loadablePulsePoll.value;
    }

    const step = getStep(steps, currentStep.key);
    if (isNullOrUndefined(step)) {
      log.warn(`Unable to find the current step ${currentStep.key}, so unable to check if there are some errors.`);
      this.onNextStep();
      return;
    }

    // Open are you sure modal if poll is current step is not consistent
    if (step.status === STEP_STATUS.inError) {
      const missingFields = step.fieldsInError.map(
        fieldKey => translate(this.props.t, getOr(LabelMapper.getIn([currentStep.key, fieldKey]), fieldKey))
      );
      this.setState({
        isContinueAlertModalOpen: true,
        missingFields,
      });
    } else if (step.key === "access" &&
      usersWithoutInsightsAccess.length > 0 &&
      (isNotNullNorUndefined(pulsePoll) &&
        pulsePoll.status === Post.Status.DRAFT) &&
      canEditUser) {
      this.setState({
        isInsightsAccessModalOpen: true,
      });
    } else {
      this.onNextStep();
    }
  };

  handleInsightsAccessModalSkipClick = () => {
    this.setState({
      isInsightsAccessModalOpen: false,
    });
    this.onNextStep();
  };

  updateInsightsAccess = () => {
    const { usersWithoutInsightsAccess } = this.props;
    const usersIds = usersWithoutInsightsAccess.map((user: any) => user._id);
    const payload: UpdateUserPermissionsPayload = {
      modifications: {
        hasInsightsAccess: true,
      },
      selection: {
        type: SelectionFilter.Type.MANUAL,
        ids: usersIds,
      },
    };
    const { updateUsers, displayNotification } = this.props;
    updateUsers(payload);
    displayNotification(
      NotificationFactory.success("Access to Engage Insights successfully updated", "", 4.5)
    );
  };

  handleInsightsAccessModalYesClick = () => {
    this.updateInsightsAccess();
    this.handleInsightsAccessModalSkipClick();
  };

  showInsightsAccessModal = (status: Post.Status) => {
    const { currentStep, usersWithoutInsightsAccess, canEditUser } = this.props;
    if (status !== Post.Status.DRAFT && currentStep.key === "access" &&
      usersWithoutInsightsAccess.length > 0 &&
      canEditUser) {
      this.setState({
        isInsightsAccessModalOpen: true
      });
    }
  };

  handleContinueModalCancelClick = () => {
    this.setState({
      isContinueAlertModalOpen: false,
      missingFields: [],
    });
  };

  handleContinueModalOkClick = () => {
    this.setState({
      isContinueAlertModalOpen: false,
      missingFields: [],
    });
    this.onNextStep();
  };

  handleGoToPulsePolls = () => {
    goTo("/pulsePolls");
  };

  handleChange = (pulsePoll: PulsePollResource) => {
    const { pulsePollId, onUpdatePulsePoll, questionConfig } = this.props;

    onUpdatePulsePoll(pulsePollId, pulsePoll, questionConfig);
  };

  handleSave = () => {
    const {
      pulsePollId,
      pulsePoll: loadablePulsePoll,
      onSavePulsePoll,
      questionConfig,
    } = this.props;

    if (Loadable.isNotLoaded(loadablePulsePoll)) {
      return false;
    }

    onSavePulsePoll(pulsePollId, loadablePulsePoll.value, questionConfig);
    this.showInsightsAccessModal(loadablePulsePoll.value.status);
    return true;
  };

  handleSaveAndExit = () => {
    // fixme: we should handle the case where we will not do the save because we do have some validation errors
    this.handleSave();
    this.handleGoToPulsePolls();
  };

  onPulsePollPreviewClick = () => {
    const { isConsistent, errorKeys } = this.isPulsePollConsistent();
    if (isNullOrUndefined(isConsistent)) {
      return null;
    }
    // Open are you sure modal if, poll not consistent on moving to next screen
    if (isConsistent && isNotNullNorUndefined(errorKeys)) {
      if (Array.isArray(errorKeys) && errorKeys.length > 0) {
        return this.setState({
          isPulsePollPreviewValidationModalOpen: true,
        });
      }
    }
    return this.setState({ isPulsePollPreviewModalOpen: true });
  };

  closePulsePollPreviewValidationModal = () => {
    this.setState({ isPulsePollPreviewValidationModalOpen: false });
  };

  closePulsePollPreviewModal = () => {
    this.setState({ isPulsePollPreviewValidationModalOpen: false });
  };

  openPulsePollPreviewModal = () => {
    this.setState({ isPulsePollPreviewValidationModalOpen: true });
  };

  handleClose = () => {
    const { pulsePoll: loadablePulsePoll, hasPendingUpdates } = this.props;

    if (Loadable.isNotLoaded(loadablePulsePoll)) {
      this.handleGoToPulsePolls();
      return;
    }

    const pulsePoll = loadablePulsePoll.value;
    if (pulsePoll.status !== Post.Status.DRAFT && not(hasPendingUpdates)) {
      this.handleGoToPulsePolls();
    } else {
      this.setState({
        isCancelPulsePollCreationModalOpen: true,
      });
    }
  };

  handleCloseCancelPulsePollCreationModal = () => {
    this.setState({
      isCancelPulsePollCreationModalOpen: false,
    });
  };

  showPreviewSentSuccessToast = (
    payload: NetworkEventSuccessAction["payload"]
  ) => {
    const notification = NotificationFactory.success(
      "Preview sent!",
      "Your recipients will receive the preview in their inbox shortly",
      4.5
    );
    this.props.displayNotification(notification);
  };

  sendPulsePollPreview = (emails: string[], message: string) => {
    const { pulsePoll, onSendPulsePollPreview } = this.props;
    if (Loadable.isNotLoaded(pulsePoll)) {
      return null;
    }

    onSendPulsePollPreview(
      pulsePoll.value._id,
      emails,
      message,
      this.showPreviewSentSuccessToast
    );
  };

  handleToggleManagersSynchronization = (areManagersSynchronized: boolean) => {
    this.props.onToggleManagersSynchronization(
      this.props.pulsePollId,
      areManagersSynchronized
    );
  };

  handleChangeAudience = (audience: AudienceType) => {
    const {
      pulsePollId,
      pulsePoll: loadablePulsePoll,
      onUpdatePulsePoll,
      questionConfig,
    } = this.props;

    if (Loadable.isNotLoaded(loadablePulsePoll)) {
      return false;
    }

    onUpdatePulsePoll(
      pulsePollId,
      {
        ...loadablePulsePoll.value,
        audience,
      },
      questionConfig
    );
  };

  handleFetchParticipants = () => {
    const {
      pulsePollId,
      pulsePoll: loadablePulsePoll,
      onFetchParticipants,
    } = this.props;

    if (Loadable.isNotLoaded(loadablePulsePoll)) {
      return;
    }

    onFetchParticipants(pulsePollId, loadablePulsePoll.value);
  };

  handleFetchParticipantsExport = () => {
    const { pulsePollId } = this.props;
    downloadPulsePollsRecipients(pulsePollId);
  };

  handleAddAccesses = (emails: string[]) => {
    const {
      pulsePollId,
      onAddAccesses,
    } = this.props;

    onAddAccesses(pulsePollId, emails);
  };

  handleUpdateAccess = (accessId: string, role: AccessList.UserAccessRole) => {
    const {
      pulsePollId,
      onUpdateAccess,
    } = this.props;

    onUpdateAccess(pulsePollId, accessId, role);
  };

  handleRemoveAccess = (accessId: string) => {
    const {
      pulsePollId,
      onRemoveAccess,
    } = this.props;

    onRemoveAccess(pulsePollId, accessId);
  };

  handleFinalActivate = () => {
    const { pulsePollId, onActivatePulsePoll } = this.props;
    onActivatePulsePoll(pulsePollId);
  };

  handleActivate = () => {
    const { company: { startTime, endTime, daysOfWeek } } = this.props;
    if (!checkIfBusinessHours(startTime, endTime, daysOfWeek)) {
      this.setState({
        showBusinessHoursModal: true
      });
    }
    else {
      this.handleFinalActivate();
    }
  };

  handleCloseBusinessHoursModal = () => {
    this.setState({
      showBusinessHoursModal: false,
    });
  };
  getStepComponent(
    stepDefinition: StepDefinition<PulsePollStepValue>
  ): React.ReactNode {
    const {
      pulsePoll: loadablePulsePoll,
      errors,
      allowedMergeTags,
      allowedChannels,
      disabled,
      dimensions,
      reportingDimensions,
      participants,
      questionConfig,
      isMultiSelectQuestionEnabled,
      participantsCount,
      categories,
      manageeEmails,
      specificAudienceEmails,
      accesses,
      eligibleManagersCount,
    } = this.props;
    const manageesEmailList = manageeEmails.map(user => user.email);
    let specificAudienceEmailsList: string[] = [];
    if (isNotNullNorUndefined(specificAudienceEmails)) {
      specificAudienceEmailsList = specificAudienceEmails.map(user => user.email);
    }
    if (Loadable.isNotLoaded(loadablePulsePoll)) {
      return null;
    }
    const pulsePoll = loadablePulsePoll.value;
    const cannotEditCriteriaAudience = pulsePoll.status === Post.Status.DEACTIVATED ||
      pulsePoll.status === Post.Status.CLOSED || (
        pulsePoll.status === Post.Status.LAUNCHED && (
          pulsePoll.audience.everyoneInTheCompany ||
          isNotNullNorUndefined(pulsePoll.audience.dynamicCriteria.dateCriteria)
        )
      );
    const canRemoveManualAudience = pulsePoll.status === Post.Status.DRAFT;
    const canAddManualAudience =
      pulsePoll.status !== Post.Status.CLOSED && pulsePoll.status !== Post.Status.DEACTIVATED;
    if(isNotEmptyArray(manageesEmailList) && isNotNullNorUndefined(dimensions.manager)) {
      dimensions.manager.segments = [ "N/A" , ...manageesEmailList ];
    }
    switch (stepDefinition.key) {
      case "settings":
        return (
          <Settings
            allowedChannels={allowedChannels}
            allowedMergeTags={allowedMergeTags}
            categories={categories}
            pulsePoll={pulsePoll}
            errors={errors.fields}
            disabled={disabled}
            onChange={this.handleChange}
          />
        );
      case "question":
        return (
          <Question
            pulsePoll={pulsePoll}
            onChange={this.handleChange}
            disabled={disabled}
            errors={errors}
            questionConfig={questionConfig}
            isMultiSelectQuestionEnabled={isMultiSelectQuestionEnabled}
          />
        );
      case "audience":
        return (
          <Audience
            audience={pulsePoll.audience}
            hasPendingAudienceUpdates={this.props.hasPendingUpdates}
            canRemoveManualAudience={canRemoveManualAudience}
            cannotEditCriteriaAudience={cannotEditCriteriaAudience}
            configuration={AudienceType.Configuration.allAudienceAllowed()}
            dimensions={dimensions}
            reportingDimensions={reportingDimensions}
            manualEmails={
              (isNotEmptyArray(specificAudienceEmailsList) ?
                specificAudienceEmailsList :
                manageesEmailList)
            }
            participants={participants}
            participantsCount={participantsCount}
            disabled={isNotNullNorUndefined(disabled.audience) && disabled.audience}
            // Note: plug the users' groups as soon as we will have groups in audience (i.e. when voice will be there)
            allowToUseGroups={false}
            groups={[]}
            onChange={this.handleChangeAudience}
            fetchParticipantsExport={this.handleFetchParticipantsExport}
            onFetchParticipants={this.handleFetchParticipants}
            // fixme: this is currently set to noOp and needs to be changed if required. 
            // It has been left as noOp because so far no issue
            // related to removal of manual audience has been reported for pulse polls.
            onErrorRemoveManualAudience={noOp}
            canAddManualAudience={canAddManualAudience}
          />
        );
      case "access":
        return (
          <Access
            AccessIntroduction={this.AccessIntroduction}
            title="Poll Access"
            accesses={accesses}
            areManagersSynchronized={getOr(pulsePoll.areManagersSynchronized, false)}
            onToggleManagersSynchronization={this.handleToggleManagersSynchronization}
            allowedEmails={manageesEmailList}
            eligibleManagersCount={eligibleManagersCount}
            onAddNewAccesses={this.handleAddAccesses}
            onUpdateAccess={this.handleUpdateAccess}
            onRemoveAccess={this.handleRemoveAccess}
            showInsightsAccessModal={() => this.showInsightsAccessModal(pulsePoll.status)}
          />
        );
      case "summary":
        return (
          <PulsePollSummary
            pulsePoll={pulsePoll}
            participantsCount={participantsCount}
            accesses={accesses}
            allowedChannels={allowedChannels}
          />
        );
    }
  }

  render() {
    const {
      pulsePoll: loadablePulsePoll,
      updatedAt,
      errors,
      steps,
      currentStep,
      hasPendingUpdates,
      participantsCount,
      usersWithoutInsightsAccess,
      company: {
        startTime,
        endTime
      }
    } = this.props;
    const {
      isCancelPulsePollCreationModalOpen,
      showBusinessHoursModal,
      isInsightsAccessModalOpen
    } = this.state;
    const isParticipantsCountError = participantsCount.status === "in-error";
    const textMessage = <Trans i18nKey="outOfBusinessHoursMessageForPoll"
      values={{ startTime: hourOfDay[startTime], endTime: hourOfDay[endTime] }}
      defaults={`It’s currently outside the company business hours
          (${hourOfDay[startTime]} to ${hourOfDay[endTime]} ),
          Poll notifications will be automatically sent to the 
          employees during business hours.
          Do you want to schedule this poll launch email ?`} />;
    if (Loadable.isNotLoaded(loadablePulsePoll)) {
      return (
        <CreationLayout
          title={""}
          navbarActions={[]}
          onCloseIconClick={this.handleClose}
          contentRef={this.contentWrapper}
        >
          {
            Loadable.isLoading(loadablePulsePoll) && <ContainerCard>
              <SpinContainer>
                <StyledSpinCol>
                  <Spin />
                </StyledSpinCol>
              </SpinContainer>
            </ContainerCard>
          }
          {
            Loadable.isInError(loadablePulsePoll) &&
            <FetchError {...loadablePulsePoll} resourceType={PulsePollResource.TYPE} />
          }
        </CreationLayout>
      );
    }

    const pulsePoll = loadablePulsePoll.value;

    const nextStep = currentStep.nextStep;
    const nextStepLabel = Optional.flatMap(nextStep, step =>
      getStepLabel(getAllPulsePollStepDefinitions(), step)
    );

    const returnHeader = () => {
      if (pulsePoll.status === Post.Status.DRAFT) {
        return "Create a new poll";
      } else if (pulsePoll.status === Post.Status.ACTIVE) {
        return "Edit in progress poll";
      } else {
        return "Edit closed poll";
      }
    };

    const updatedTime = moment(updatedAt).format("MMMM Do YYYY, h:mm:ss a");

    return (
      <CreationLayout
        title={returnHeader()}
        navbarActions={[
          <span key="time">
            {pulsePoll.status === Post.Status.DRAFT ?
              <Trans i18nKey="autoSaveDate" values={{ date: updatedTime }}
                defaults={`Saved automatically at ${updatedTime}`} />
              : <Trans i18nKey="saveDate" values={{ date: updatedTime }}
                defaults={`Saved at ${updatedTime}`} />}
          </span>,
        ]}
        onCloseIconClick={this.handleClose}
        contentRef={this.contentWrapper}
      >
        <Row type="flex" justify="center">
          <Col span={20}>
            <StyledStepper
              isParticipantError={isParticipantsCountError}
              steps={steps}
              currentStep={currentStep.key}
              onStepClick={this.moveToStep}
            />
          </Col>
        </Row>
        {this.getStepComponent(currentStep)}
        <ActionContainer>
          {
            pulsePoll.status !== Post.Status.DRAFT && (
              <Button
                disabled={WrongEntityException.hasErrors(errors) || not(hasPendingUpdates)}
                onClick={this.handleSave} translate="yes">
                Apply Changes
              </Button>
            )
          }
          {
            isNotNullNorUndefined(nextStepLabel) ?
              (
                <NextButton
                  nextStep={nextStepLabel}
                  onClick={this.checkPulsePollConsistencyBeforeNextStep}
                />
              )
              :
              pulsePoll.status === Post.Status.DRAFT && (
                <Button
                  color="blue"
                  disabled={WrongEntityException.hasErrors(errors) ||
                    hasPendingUpdates ||
                    isParticipantsCountError
                  }
                  onClick={this.handleActivate}
                  data-cy="pulsePollCreation_activate"
                  data-jest="pulsePoll_Activate"
                  translate="yes"
                >
                  Activate
                </Button>
              )
          }
        </ActionContainer>

        {showBusinessHoursModal && (
          <AreYouSureModal
            visible={showBusinessHoursModal}
            title="Are you sure?"
            cancelLabel={`Back to ${currentStep.label}`}
            /* eslint-disable-next-line max-len */
            description={textMessage}
            okLabel={"Activate Poll"}
            onCancel={this.handleCloseBusinessHoursModal}
            onOk={this.handleFinalActivate}
          />
        )}
        <ContinueAlert
          visible={this.state.isContinueAlertModalOpen}
          missingFields={this.state.missingFields}
          onCancelClick={this.handleContinueModalCancelClick}
          onOkClick={this.handleContinueModalOkClick}
          currentStep={currentStep.label}
        />
        <AreYouSureModal
          visible={this.state.isPulsePollPreviewValidationModalOpen}
          title="poll Incomplete"
          description="Before we can send a preview, your poll needs to have at-least one question"
          onOk={this.closePulsePollPreviewValidationModal}
          onCancel={this.closePulsePollPreviewValidationModal}
          data-cy="pulsePollCreation_preview_incompletePoll"
          okLabel="Add Questions"
          hideCancelButton={true}
        />
        <InsightsAccessAlter
          visible={isInsightsAccessModalOpen}
          onCancelClick={this.handleInsightsAccessModalSkipClick}
          onOkClick={this.handleInsightsAccessModalYesClick}
          usersWithoutInsightsAccess={usersWithoutInsightsAccess}
        />
        {pulsePoll.status === Post.Status.DRAFT &&
          isCancelPulsePollCreationModalOpen && (
            <AreYouSureModal
              visible={isCancelPulsePollCreationModalOpen}
              title="Are you sure?"
              /* eslint-disable-next-line max-len */
              description="If you want to stop editing this poll right now, a draft will be saved and you can access it from the poll list."
              onOk={this.handleGoToPulsePolls}
              onCancel={this.handleCloseCancelPulsePollCreationModal}
              okLabel="Yes"
              cancelLabel="Keep Editing"
            />
          )}
        <NavigationPrompt
          // @ts-ignore
          when={this.navCheck}
        >
          {({ isActive, onCancel, onConfirm }) => {
            if (isActive) {
              return (
                <AreYouSureModal
                  visible={true}
                  title="You have unsaved changes"
                  description="Click Save to save changes and exit or Cancel to exit without saving."
                  okLabel={"Save"}
                  onCancel={onConfirm}
                  // tslint:disable-next-line:jsx-no-lambda
                  onOk={() => {
                    this.handleSave();
                    onConfirm();
                  }}
                />
              );
            }
          }}
        </NavigationPrompt>
        {isCancelPulsePollCreationModalOpen && hasPendingUpdates && (
          // @ts-ignore
          <StyledModal
            visible={true}
            footer={null}
            onCancel={this.handleCloseCancelPulsePollCreationModal}
          >
            <ModalContent>
              <Heading translate="yes">You have unsaved changes.</Heading>
              <br />
              <Paragraph translate="yes">
                Please click one of the following Options to continue:
              </Paragraph>
              <Paragraph translate="yes">
                - "<Trans>Discard Changes & Exit</Trans>" - <Trans>To exit this page without saving
                  your changes click</Trans>
              </Paragraph>
              <Paragraph translate="yes">
                - "<Trans>Stay on Page</Trans>" - <Trans>To remain on this page & continue editing
                  click</Trans>
              </Paragraph>
              <Paragraph translate="yes">
                - "<Trans>Confirm Changes & Exit</Trans>" - <Trans>To exit this page and SAVE your
                  changes click</Trans>
              </Paragraph>
              <br />
            </ModalContent>
            <ActionContainerModal>
              <StyledButton onClick={this.handleGoToPulsePolls} translate="yes">
                Discard Changes & Exit
              </StyledButton>
              <StyledButton
                onClick={this.handleCloseCancelPulsePollCreationModal}
                translate="yes"
              >
                Stay on Page
              </StyledButton>
              <StyledButton color="blue" onClick={this.handleSaveAndExit} translate="yes">
                Confirm Changes & Exit
              </StyledButton>
            </ActionContainerModal>
          </StyledModal>
        )}
      </CreationLayout>
    );
  }
}

const mapStateToProps: MapStateToProps<PulsePollEditStateProps, OwnProps, State> = (
  state: State,
  ownProps: OwnProps
): PulsePollEditStateProps => {

  const pulsePollId = ownProps.match.params.id;
  const {
    steps,
    ...restPulsePollEditState
  } = getPulsePollEditState(state, pulsePollId);

  // Disabling Multi Select Option while creating pulse poll
  const isMultiSelectQuestionEnabled = false;

  const pulsePoll = getPulsePoll(state);
  const currentUser = getCurrentUser(state);
  const companyResource = getCompany(state);
  let userId = "";
  if (isNotNullNorUndefined(currentUser)) {
    const { userId: currentUserId } = mapOr(
      currentUser,
      (_currentUser: CurrentUserResource) => ({
        userId: _currentUser._id,
      }),
      {
        userId: "",
      });
    userId = currentUserId;
  }

  const manageeEmails = Store.Element.mapIfLoadedOr(
    getUntypedElementById<UserEmailInsightAccess[]>(state, getManageesId()),
    identity,
    []
  );

  const accesses = getPulsePollAccesses(state);
  const usersWithoutInsightsAccess = accesses.map((access) => {
    if (not(access.hasOwnProperty("isInsightUser"))) {
      const user = manageeEmails.find(manageeEmail => manageeEmail.email === access.email);
      if (isNotNullNorUndefined(user)) {
        access = {
          ...access,
          isInsightUser: user.isInsightUser ? true : false,
          _id: user._id,
        };
      };
    }
    return access;
  }).filter(access => not(access.isInsightUser) && access.role !== "blocked");
  const { isBulkUpdateRequestSuccess } = getUserPermissionsStateProps(state);

  const canEditUser = getRightsMatcher(state).hasRight(Rights.User.EDIT_USERS);
  return {
    ...restPulsePollEditState,
    accesses,
    isBulkUpdateRequestSuccess,
    usersWithoutInsightsAccess,
    userId,
    pulsePollId,
    pulsePoll,
    allowedMergeTags: getPulsePollMergeTags(),
    dimensions: getOr(getImposedDimensions(state), {}),
    reportingDimensions: getOr(getReportingDimMgmts(state), {}),
    participants: Loadable.flatMap(
      pulsePoll,
      loadedPulsePoll => Store.Page.toLoadable(
        getExistingNotPaginatedPage(
          state,
          ParticipantResource.TYPE,
          ParticipantResource.generateKey(pulsePollId, loadedPulsePoll.audience)
        )
      )
    ),
    participantsCount: Loadable.flatMap(
      pulsePoll,
      loadedPulsePoll => Store.Element.toLoadable(
        getResourceById(
          state,
          ParticipantCountResource.TYPE,
          ParticipantCountResource.generateKey(pulsePollId, loadedPulsePoll.audience)
        )
      )
    ),
    manageeEmails,
    currentUser: getCurrentUser(state),
    currentStep: getStepByKey(getAllPulsePollStepDefinitions(), ownProps.match.params.currentStepKey, "settings"),
    allowedChannels: mapOr(getCompany(state), company => company.channels, {}),
    questionConfig: getCompany(state)!.questionConfig,
    isTouched: false,
    categories: getPostCategorys(state).toArray().reverse(),
    specificAudienceEmails: getSpecificPulsePollAudienceEmails(state, pulsePoll),
    eligibleManagersCount: Loadable.flatMap(
      pulsePoll,
      loadedPulsePoll => Store.Element.toLoadable(
        getUntypedElementById<number>(state, getEligibleManagersCountId(pulsePollId, loadedPulsePoll.audience))
      )
    ),
    steps,
    isMultiSelectQuestionEnabled,
    company: getOr(companyResource, {} as CompanyResource),
    canEditUser,
  };
};

const mapDispatchToProps = {
  onFetchPulsePoll: pulsePollActionCreators.fetchPulsePoll,
  onCreatePulsePoll: pulsePollActionCreators.onCreatePulsePoll,
  onUpdatePulsePoll: pulsePollActionCreators.updatePulsePoll,
  onSavePulsePoll: pulsePollActionCreators.savePulsePoll,
  onActivatePulsePoll: pulsePollActionCreators.activatePulsePoll,
  onFetchParticipants: fetchPulsePollParticipantsIfNeeded,
  onFetchManageeEmails: fetchManageesIfNeeded,
  onAddAccesses: pulsePollActionCreators.addAccesses,
  onUpdateAccess: pulsePollActionCreators.updateAccess,
  onRemoveAccess: pulsePollActionCreators.removeAccess,
  onToggleManagersSynchronization: pulsePollActionCreators.toggleManagersSynchronization,
  onExitPage: pulsePollActionCreators.exitPage,
  updateUsers: userPermissionsActionCreators.updateUsers,
  displayNotification: displayNotification.displayNotification,
};

export const StyledStepper = styled(Stepper)`
  margin-bottom: 48px !important;
`;

const SpinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 250px);
`;

const StyledSpinCol = styled.div`
  text-align: center;
`;

const ModalContent = styled.div`
  margin-top: 10px;
  padding-left: 32px !important;
  padding-right: 32px !important;
`;

const StyledModal = styled(Modal)`
  width: 800px !important;
`;

const ActionContainerModal = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-around;
  padding-left: 32px !important;
  padding-right: 32px !important;
`;

const StyledButton = styled(Button)`
  // margin-left: 32px;
  min-width: 150px;
`;

export const PulsePollEdit = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PulsePollEditContainer)));
