// @ts-nocheck
import React from "react";
import { SvgProperties } from "csstype";
import Palette from "@src/config/theme/palette";
import GenericSvg from "./GenericSvg";

function InProgress(props: SvgProperties) {
  const { fill } = props;

  return (
    <GenericSvg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      fill={fill}
      viewBox="0 0 32 32">
      <defs>
        <circle id="b-progress" cx="16" cy="16" r="16" />
        <filter
          id="a-progress"
          width="187.5%"
          height="187.5%"
          x="-43.8%"
          y="-31.2%"
          filterUnits="objectBoundingBox">
          <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="4"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.776470588 0 0 0 0 0.839215686 0 0 0 0 0.874509804 0 0 0 0.15 0"
          />
        </filter>
        <circle id="d-progress" cx="16" cy="16" r="3" />
        <filter
          id="c-progress"
          width="566.7%"
          height="566.7%"
          x="-233.3%"
          y="-166.7%"
          filterUnits="objectBoundingBox">
          <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="4"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.776470588 0 0 0 0 0.839215686 0 0 0 0 0.874509804 0 0 0 0.15 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <use fill="#000" filter="url(#a-progress)" xlinkHref="#b-progress" />
        <use fill="#FFF" xlinkHref="#b-progress" />
        <g>
          <use fill="#000" filter="url(#c-progress)" xlinkHref="#d-progress" />
          <use fill={Palette.primary} xlinkHref="#d-progress" />
        </g>
      </g>
    </GenericSvg>
  );
}

export default InProgress;
