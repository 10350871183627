import React from "react";
import styled from "styled-components";
import { translate } from "@src/utils/i18next";
import { useTranslation } from "react-i18next";
import { Row, Col, Icon } from "antd";
import { ButtonProps } from "antd/lib/button";
import { CheckboxChangeEvent, CheckboxProps } from "antd/lib/checkbox";
import { List, Record } from "immutable";
import Select from "@components/core/Select";
import Input from "@components/core/Input";
import { Paragraph } from "@components/core/Typography";
import Button from "@components/core/Button";
import Checkbox from "@components/core/Checkbox";
import Palette from "@src/config/theme/palette";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { QuestionTypeLabel } from "hyphen-lib/dist/business/question/Questions";
import { SelectProps } from "antd/lib/select";
import { Question } from "../../containers/SurveyQuestions";
import { Trans } from "react-i18next";

const { Option } = Select;

interface CustomSurveyOptionsProps {
  value: string;
  disabled?: boolean;
  onChange?: (index: number, value: string) => void;
  index: number;
}

interface CustomCategoryProps {
  toggleField: boolean;
  onToggleField: (toggleField: boolean) => void;
  lockClose: () => void;
}

interface SettingToggle {
  checked?: boolean;
  disabled?: boolean;
}

interface SettingProps {
  toggle: {
    allowComment: SettingToggle;
    isNPSQuestion: SettingToggle;
    isRatingQuestion: SettingToggle;
    isOutcomeQuestion: SettingToggle;
  };
  onSettingsChange: (setting: any, checked: boolean) => void;
  onOpenTypeChange?: (e: CheckboxChangeEvent) => void;
  data?: {
    isSentimentAnalysisQuestion: Question["isSentimentAnalysisQuestion"];
    // @ts-ignore
    // FIXME: present in CommonMultipleChoiceFields interface of SurveyResource
    choices: Question["choices"];
    type: QuestionTypeState["type"];
  };
  surveyStatus?: string;
}

interface AddQuestionModalProps {
  onQuestionBankClick: () => void;
  onCustomClick: () => void;
}

interface QuestionTypeState {
  type: QuestionTypeLabel;
  allChoices: Record<{
    [key in QuestionTypeLabel]: List<string>
  }>;
  isConfirmationModalOpen?: boolean;
  confirmationEvent?: CheckboxChangeEvent | undefined;
}

export const modalContainerStyle = `
  padding: 0 32px 32px 32px !important;
`;

export class CustomSurveyOptions extends React.Component<CustomSurveyOptionsProps> {
  state = {
    hover: false,
  };

  onMouseEnter = () => this.setState({ hover: true });
  onMouseLeave = () => this.setState({ hover: false });

  onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const { onChange, index } = this.props;

    if (isNotNullNorUndefined(onChange)) {
      onChange(index, value);
    }
  };

  render() {
    const { disabled, value } = this.props;
    const { hover } = this.state;
    return (
      <div
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <Input
          value={value}
          onChange={this.onInputChange}
          disabled={disabled}
          suffix={(hover && !disabled) ? <Clear translate="yes">Clear</Clear> : null}
        />
      </div>
    );
  }
}

// tslint:disable-next-line:max-classes-per-file
export class SelectCategory extends React.Component {
  state = {
    toggleField: false,
    open: false,
  };
  lock = null as any;

  onToggleField = (toggleField: boolean) => this.setState({ toggleField });

  // source: https://codesandbox.io/s/32y80yk915
  lockClose = () => {
    clearTimeout(this.lock);
    this.lock = setTimeout(() => {
      this.lock = null;
    }, 100);
    return true;
  };

  onDropDownVisibleChange = (open: any) => {
    if (this.lock) {
      return;
    }

    this.setState({ open });
  };

  render() {
    const { toggleField, open } = this.state;
    return (
      <StyledSelect
        label={<Trans>Category</Trans>}
        helperAction={<Trans>Learn More</Trans>}
        defaultValue="1"
        open={open}
        // tslint:disable-next-line:jsx-no-lambda
        dropdownRender={(menu: any) => (
          <div>
            {menu}
            <CustomCategory
              toggleField={toggleField}
              onToggleField={this.onToggleField}
              lockClose={this.lockClose}
            />
          </div>
        )}
        onDropdownVisibleChange={this.onDropDownVisibleChange}
      >
        <Option value="1">
          <Trans>ActionList Item</Trans>
        </Option>
        <Option value="2">
          <Trans>ActionList Item</Trans>
        </Option>
        <Option value="3">
          <Trans>ActionList Item</Trans>
        </Option>
      </StyledSelect>
    );
  }
}

function CustomCategory(props: CustomCategoryProps) {
  const Container = styled.div`
    button {
      height: 32px !important;
    }
  `;
  const { toggleField, onToggleField, lockClose } = props;
  const { t } = useTranslation();
  return (
    <CustomContainer
      onMouseDown={lockClose}
    >
      {toggleField ? (
        <Container>
          <Row type="flex" align="middle" justify="space-between">
            <Col span={19}>
              <Input
                placeholder={translate(t, "Custom category name")}
              />
            </Col>
            <Col span={5}>
              <Button
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => onToggleField(false)}
                translate="yes"
              >
                Add
              </Button>
            </Col>
          </Row>
        </Container>
      ) : (
      // tslint:disable-next-line:jsx-no-lambda
        <div onClick={() => onToggleField(true)}>
          <Icon type="plus" /> <Trans>Add custom category</Trans>
        </div>
      )}
    </CustomContainer>
  );
}

export function QuestionSettings(props: SettingProps) {

  const { toggle, onSettingsChange,  data, onOpenTypeChange = () => undefined  } = props;
  const {
    allowComment,
    isOutcomeQuestion,
    isRatingQuestion,
  } = toggle;

  function onChange(value: keyof Question) {
    return (e: CheckboxChangeEvent) => {
      const { checked } = e.target;
      onSettingsChange(value, checked);
    };
  }

  return (
    <Checkboxes>
      <Checkbox
        info="Decide whether or not employees can submit comments with this question."
        checked={allowComment.checked}
        disabled={allowComment.disabled}
        // @ts-ignore
        // FIXME: allowComment key is present in CommonMultipleChoiceFields interface
        onChange={onChange("allowComment")}
        data-cy="surveyCreation_questions_allowComments"
      >
        <Trans>Allow comments</Trans>
      </Checkbox>
      {
        <VerbatimAnalysisCheckbox
          onChange={onOpenTypeChange}
          {...(isNotNullNorUndefined(data) ? { checked: data.isSentimentAnalysisQuestion } : {})}
          disabled={props.surveyStatus === "closed" || !allowComment.checked}
        />
      }
      <Checkbox
        info="All eNPS questions are included in eNPS calculations"
        disabled={true}
        checked={true}
        // @ts-ignore
        onChange={onChange("isNPSQuestion")}
        data-cy="surveyCreation_question_eNPS"
      >
        <Trans>Include in eNPS score calculation</Trans>
      </Checkbox>
      <Checkbox
        info="All Likert Scale questions are included in Favorability calculations"
        disabled={isRatingQuestion.disabled}
        checked={isRatingQuestion.checked}
        // @ts-ignore
        onChange={onChange("isRatingQuestion")}
        data-cy="surveyCreation_question_favorability"
      >
        <Trans>Include in favorability calculation</Trans>
      </Checkbox>
      <Checkbox
        info="Check if this is a question which implies the outcome or goal of the survey"
        disabled={isOutcomeQuestion.disabled}
        checked={isOutcomeQuestion.checked}
        // @ts-ignore
        onChange={onChange("isOutcomeQuestion")}
        data-cy="surveyCreation_questions_driverImpact"
      >
        <Trans>Set as Driver Impact Analysis outcome question</Trans>
      </Checkbox>
    </Checkboxes>
  );
}

export function VerbatimAnalysisCheckbox(props: CheckboxProps) {
  return (
    <Checkbox
      info="Check if the answers to this question need to be analyzed for sentiment"
      data-cy="surveyCreation_questions_sentimentAnalysis"
      {...props}
    >
      <Trans>Include Verbatim in Sentiment Analysis</Trans>
    </Checkbox>
  );
}

export function AddQuestionModal({ onCustomClick, onQuestionBankClick }: AddQuestionModalProps) {
  const Container = styled.div`
    ${modalContainerStyle}
  `;

  const Buttons = styled.div`
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;

    button:first-child {
      margin-right: 16px;
    }

    button {
      font-weight: bold;
    }
  `;
  return (
    <Container>
      <Paragraph>
          <Trans>You can either create your custom question or select one from our question bank.
          You will still be able to change settings for the bank questions.</Trans>
      </Paragraph>
      <Buttons>
        <StyledButton data-cy="surveyCreation_question_questionBank" onClick={onQuestionBankClick} 
        color="blue" translate="yes">
          Pick from our question bank
        </StyledButton>
        <Button data-cy="surveyCreation_question_custom" onClick={onCustomClick} color="blue" 
        translate="yes">
          Custom question
        </Button>
      </Buttons>
    </Container>
  );
}

export function SubmitButton({ title = "Save changes", ...rest }: { title?: string } & ButtonProps) {

  return (
    <SubmitButtonContainer>
      <Button
        // @ts-ignore
        color="blue"
        {...rest}
      >
        <Trans>{title}</Trans>
      </Button>
    </SubmitButtonContainer>
  );
}

export function Choices({ label }: { label: string }) {
  return (
    <ChoiceContainer>
      <Trans>{label}</Trans>
    </ChoiceContainer>
  );
}

const StyledButton = styled(Button)`
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    text-overflow: clip;
    white-space: normal;
    word-break: break-word;
    line-height: 15px;
  }
`;

const Clear = styled(Paragraph)`
  color: ${Palette.darkPink} !important;
  cursor: pointer;
`;

const CustomContainer = styled.div`
  padding: 20px 12px;
  color: ${Palette.darkModerateBlue};
  cursor: pointer;

  svg {
    fill: ${Palette.darkModerateBlue};
  }
`;

const Checkboxes = styled.div`
  margin-top: 16px;

  > div {
    margin-top: 16px;
  }
`;

const SubmitButtonContainer = styled.div`
  margin-top: 28px;
  display: flex;
  justify-content: flex-end;
`;

const ChoiceContainer = styled.div`
  margin-top: 8px;
  padding: 8px 13px;
  background-color: ${Palette.athensGrey};
  border: solid 1px ${Palette.lightPeriwinkle};
  border-radius: 3px;
`;

const StyledSelect = styled(Select)<SelectProps  & {children: React.ReactNode}>``;
