import React from "react";
import styled from "styled-components";

import Select from "@components/core/Select";
import Palette from "@src/config/theme/palette";

import { UserPermissionModalBaseProps } from "./BulkEditPermissionsModal";
import { Trans } from "react-i18next";

const { Option } = Select;

interface Props extends UserPermissionModalBaseProps {
  availableRoles: string[];
}

export function EditRoleModalContent(props: Props) {
  const { availableRoles, value, onChange } = props;
  return (
    // @ts-ignore
    <Select
      label={<SelectRoleTitle />}
      value={value}
      onChange={onChange}
    >
      {availableRoles.map(role => (
        <Option value={role} key={role}>{role}</Option>
      ))}
    </Select>
  );
}

function SelectRoleTitle() {
  return (
    <Title>
      <Trans>Change to</Trans>
    </Title>
  );
}

const Title = styled.span`
  i {
    margin-left: 4px;
    cursor: pointer;
    color: ${Palette.lightGreyBlue} !important;
  }
`;
