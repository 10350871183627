import React from "react";
import styled from "styled-components";

import { DynamicRowProps } from "@components/core/DynamicRowsTable/withDynamicRows";

import Palette from "@src/config/theme/palette";
import {
  CellContainer,
  CellInnerContainer
} from "@screens/Insights/SurveyList/components/cells/Cells";

interface TopicCellProps {
  totalComments: number;
}

export function CommentCountCell({ row, index }: DynamicRowProps<TopicCellProps>) {
  return (
    <CellInnerContainer padding={[20, 0]} width="100%">
      <CellContainer align="flex-start" justifyContent="flex-start">
        <Comment data-cy={`commentText_${index}`}>{row.totalComments}</Comment>
      </CellContainer>
    </CellInnerContainer>
  );
}


export const QuestionLabel = styled.div`
  font-family: Lato;
  font-size: 14px;
  line-height: 1.43;
  margin-top: 4px;
  color: ${Palette.veryDarkBlueGrey};
`;

const Comment = styled.span`
  padding-right: 32px;
  padding-left: 32px;
  font-family: Lato;
  font-size: 14px;
  margin-top: 10px;
  color: ${Palette.veryDarkBlueGrey};
`;


// const Dot = styled.span`
//   color: ${Palette.bluishGrey};
//   font-family: Lato;
//   font-size: 14px;
//   padding-right: 10px;
//   padding-left: 10px;
// `;
export const BelowPartLabel = styled.span`
  font-family: Lato;
  font-size: 14px;
  color: ${Palette.bluishGrey};
`;