import { State } from "@store/types";
import { LoadingState, FiltersStateProps } from "./types";

export const getSurveyFilters = (
  state: State
): FiltersStateProps => {
  return state.get("insights_filters").get("filters");
};

export const getLoadingState = (state: State): LoadingState => {
  return state.get("insights_filters").get("loadingState");
};
