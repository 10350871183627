import { State } from "@store/types";
import { getCurrentUser, getRightsMatcher } from "@screens/Insights/store/selectors";
import { isNullOrUndefined, mapOr, isNotNullNorUndefined } from "@hyphen-lib/lang/Objects";
import {
  SidebarDefinition,
  SidebarItemDefinition,
  SidebarSectionDefinition
} from "@components/Sidebar/SidebarDefinitions";
import { Location } from "history";
import {
  ExtendedSidebarItemDefinition,
  getDefaultSidebarDefinition
} from "@screens/Insights/MainNavigation/DefaultSidebarDefinition";
import { RightsMatcher } from "@hyphen-lib/business/auth/Auth";
import { Seq } from "immutable";
import { identity } from "@hyphen-lib/lang/Functions";
import { Breadcrumb, goTo } from "@src/utils/locations";
import { CompanyResource } from "@hyphen-lib/domain/resource/CompanyResource";
import { Optional } from "@hyphen-lib/lang/Optionals";
import { isLCAEnabled, isPulsePollEnabled, isVoiceEnabled } from "@hyphen-lib/business/company/Companies";
import { not } from "@hyphen-lib/lang/Booleans";
import { isEmpty } from "@hyphen-lib/lang/Arrays";
import { Notification } from "@hyphen-lib/domain/trait/WithNotifications";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";

export function getSidebarDefinition(state: State): SidebarDefinition<any> {
  const currentUser = getCurrentUser(state);
  const rightsMatcher = getRightsMatcher(state);

  if (isNullOrUndefined(currentUser)) {
    return {
      mainSection: { items: [] },
      adminSection: { items: [] },
    };
  }

  const company = currentUser.company;
  const settingsRegex = /insights\/settings/;
  const showSettingsNotificationIcon =
    isNotNullNorUndefined(currentUser.notifications) &&
    not(isEmpty(currentUser.notifications)) &&
    not(isEmpty(currentUser.notifications.filter(
      (notification: Notification) =>
        settingsRegex.test(notification.location) && isStringAndNotEmpty(notification.message)
    )));

  const { mainSection, adminSection } = getDefaultSidebarDefinition();

  return {
    mainSection: mapSection(
      mainSection,
      rightsMatcher,
      company
    ),
    adminSection: mapSection(
      adminSection,
      rightsMatcher,
      company,
      showSettingsNotificationIcon
    ),
  };
}

export function getActiveItemId(state: State, location: Location<any>): string | undefined {
  const { mainSection, adminSection } = getDefaultSidebarDefinition();
  return mapOr(
    Seq([mainSection.items, adminSection.items])
      .flatMap(identity)
      .find(item => item.locationMatcher(location.pathname)),
    item => item.id,
    undefined
  );
}

function isPulsePollModuleEnabled (company: Optional<CompanyResource>): boolean {
  if (isNotNullNorUndefined(company)) {

    return isPulsePollEnabled(company);
  }
  return false;
}

function isVoiceModuleEnabled (company: Optional<CompanyResource>): boolean {
  if (isNotNullNorUndefined(company)) {

    return isVoiceEnabled(company);
  }
  return false;
}

export function mapSection(
  section: SidebarSectionDefinition<ExtendedSidebarItemDefinition>,
  rightsMatcher: RightsMatcher,
  company: Optional<CompanyResource>,
  showSettingsNotificationIcon?: Optional<boolean>
): SidebarSectionDefinition<SidebarItemDefinition> {
  return {
    label: section.label,
    items:
      Seq(section.items)
        .filter(item => {
          // fixme - At some point, we probably want to add modules on the items, just like we have
          // the required rights for an item. Like that we could be generic.
          if (item.id === "main/pulses") {
            return (isPulsePollModuleEnabled(company)
                    && mapOr(item.requiredRights, rights => rightsMatcher.hasEveryOf(...rights), false) );
          }

          if (item.id === "main/voices") {
            return (isVoiceModuleEnabled(company)
                    && mapOr(item.requiredRights, rights => rightsMatcher.hasEveryOf(...rights), false) );
          }

          if (item.id === "main/lifecycle" && isNotNullNorUndefined(company)) {
            return ((isLCAEnabled(company)
                      && mapOr(item.requiredRights, rights => rightsMatcher.hasEveryOf(...rights), false)));
          }

          if (item.id === "admin/settings") {
            item.showNotificationIcon = showSettingsNotificationIcon!;
          }

          return mapOr(item.requiredRights, rights => rightsMatcher.hasEveryOf(...rights), false);
        })
        .map(item => ({
          ...item,
          onClick: () => goTo(item.onClick(), Breadcrumb.newStack()),
        }))
        .toArray(),
  };
}
