import Palette from "@src/config/theme/palette";
import styled from "styled-components";
import { Icon } from "antd";
import { goTo } from "@src/utils/locations";

interface Props {
  name: string;
  id: string;
  actionPlanIndex: number;
}

export default function CreatedActionPlan(props: Props) {
  const navigateTo = () => goTo(`/actioncenter/actions/view/${props.id}`);
  const isFirst = props.actionPlanIndex === 0;
  return (
    <Container onClick={navigateTo} isFirst={isFirst}>
      <Action onClick={navigateTo}>{props.name}</Action>
      <StyledIcon type="right" />
    </Container>
  );
}

const Container = styled.div<{isFirst: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  box-shadow: ${
    props => props.isFirst ? "inset 0 1px 0 0 #D1DBE8, inset 0 -1px 0 0 #D1DBE8" : "inset 0 -1px 0 0 #D1DBE8"
  };
  cursor: pointer;
`;
const Action = styled.button`
  cursor: pointer;
  border: none;
  background-color: #fff;
  color: ${Palette.veryDarkBlueGrey};
  font-weight: bold;
`;

const StyledIcon = styled(Icon)`
  vertical-align: middle;
  color: ${Palette.purple};
`;