import React from "react";
import styled from "styled-components";
import { Icon } from "antd";

import { Left, Right } from "@components/core/svg";
import { Word, Topic } from "@hyphen-lib/business/calculation/sentiment/Words";
import { isNotNullNorUndefined } from "@hyphen-lib/lang/Objects";
import { getOr } from "@hyphen-lib/lang/Objects";
import CommonWord from "./components/CommonWord";
import {
  sortWords
} from "hyphen-lib/dist/business//calculation/words/Words";
interface Props {
  words: ( Word | Topic )[];
  onWordClick?: (word: string, sentiment: string) => void;
}

interface State {
  scrolledToLeft: boolean;
  scrolledToRight: boolean;
  componentWidth: number;
}

class CommonWords extends React.Component<Props, State> {
  wordListContainer = React.createRef<HTMLDivElement>();
  wrapperRef: HTMLDivElement | null = null;
  state = {
    scrolledToLeft: true,
    scrolledToRight: true,
    componentWidth: 300,
  };

  componentDidMount() {
    setTimeout(() => this.setContainerWidth(), 0);
    window.addEventListener("resize", this.setContainerWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setContainerWidth);
  }

  componentDidUpdate() {
    this.updateScrollVisibility();
  }

  setContainerWidth = () => {
    if (this.wrapperRef) {
      this.setState({ componentWidth: this.wrapperRef.offsetWidth });
    }
  };

  updateScrollVisibility = () => {
    if (this.wordListContainer && this.wordListContainer.current) {
      const scrolledToLeft = this.wordListContainer.current.scrollLeft === 0;
      const scrolledToRight =
        this.wordListContainer.current.offsetWidth +
          Math.ceil(this.wordListContainer.current.scrollLeft) ===
        this.wordListContainer.current.scrollWidth;

      if (
        this.state.scrolledToLeft !== scrolledToLeft ||
        this.state.scrolledToRight !== scrolledToRight
      ) {
        this.setState({ scrolledToLeft, scrolledToRight });
      }
    }
  };

  scrollList = (direction: "left" | "right") => () => {
    if (this.wordListContainer && this.wordListContainer.current) {
      const scrollLeftBy = direction === "right" ? 400 : -400;
      this.wordListContainer.current.scrollBy({
        top: 0,
        left: scrollLeftBy,
        behavior: "smooth",
      });
    }
  };

  getWordOpacity = (value: number, max: number): number => {
    // remove applying opacity based on occurrances
    if (max === 0 || true) {
      return 1;
    }
    return Math.max(value / max, 0.4);
  };

  render() {
    const { words, onWordClick } = this.props;
    const sortedWord = sortWords(words, {occurrences: -1});
    const { componentWidth } = this.state;
    const maxOccurrences = sortedWord[0] ? getOr(sortedWord[0].occurrences, 0) : 0;
    return (
      <ScalingWrapper ref={element => (this.wrapperRef = element)}>
        <SentimentChartContainer parentWidth={componentWidth}>
          <SentimentChart
            onScroll={this.updateScrollVisibility}
            ref={this.wordListContainer}>
            {sortedWord.map((word, index) => (
              <CommonWord
                key={`${word}_${index}`}
                word={word}
                opacity={this.getWordOpacity(word.occurrences, maxOccurrences)}
                onWordClick={onWordClick}
              />
            ))}
          </SentimentChart>
          {!this.state.scrolledToLeft && (
            <LeftScrollButton onClick={this.scrollList("left")}>
              <Icon component={Left} />
            </LeftScrollButton>
          )}
          {!this.state.scrolledToRight && (
            <RightScrollButton onClick={this.scrollList("right")}>
              <Icon component={Right} />
            </RightScrollButton>
          )}
        </SentimentChartContainer>
      </ScalingWrapper>
    );
  }
}

const ScalingWrapper = styled.div`
  height: 50px;
`;

const SentimentChartContainer = styled.div<{ parentWidth?: number }>`
  position: relative;
  overflow: visible;
  width: ${props =>
    isNotNullNorUndefined(props.parentWidth)
      ? `${props.parentWidth}px`
      : "300px"};
  height: 50px;
`;

export const SentimentChart = styled.div`
  display: -webkit-box;
  // diplay: flex;
  overflow-x: scroll;
  overflow-y: visible;
  -ms-overflow-style: none; /* IE 10+ */
  overflow: -moz-scrollbars-none; /* Firefox */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const scrollButtonStyle = `
  position: absolute;
  top: 0;
  width: 128px;
  height: 28px;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const LeftScrollButton = styled.div`
  ${scrollButtonStyle};
  left: 0;
  justify-content: flex-start;
  background-image: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0));
`;

const RightScrollButton = styled.div`
  ${scrollButtonStyle};
  right: 0;
  justify-content: flex-end;
  background-image: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0));
`;

export default CommonWords;
