import React from "react";
import { AnonymityFilterExplanation } from "hyphen-lib/dist/domain/common/AnonymityFilterExplanation";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Tooltip } from "antd";
import { generateSubtitle } from "../AnonymityFiltered/index";
import incognito from "./incognito.png";

interface Props {
  explanation: AnonymityFilterExplanation;
  anonymityThreshold: number;
  size?: InlineAnonymityFilteredSize;
}

export type InlineAnonymityFilteredSize = "small" | "normal" | "big";

export function InlineAnonymityFiltered({ explanation, anonymityThreshold, size = "small" }: Props) {
  const { t } = useTranslation();
  return (
    <Tooltip title={generateSubtitle(t, explanation, anonymityThreshold)}>
      <StyledImage
        data-cy="anonimity-icon"
        src={incognito}
        alt={"Filtered for anonymity"}
        size={size}
      />
    </Tooltip>
  );
}

function convertSizeToPixel(size: InlineAnonymityFilteredSize) {
  switch (size) {
    case "small":
      return 16;
    case "normal":
      return 32;
    case "big":
      return 48;
  }
}

const StyledImage = styled.img<{size: InlineAnonymityFilteredSize}>`
  height: ${props => convertSizeToPixel(props.size)}px;
  opacity: 0.2;
`;
