import { SurveyResource } from "hyphen-lib/dist/domain/resource/SurveyResource";
import { Survey } from "hyphen-lib/dist/domain/Survey";
import { FSA } from "flux-standard-action";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import actionCreators, {
  NetworkEventErrorPayload,
  NetworkEventSuccessPayload,
  NetworkRequestAction,
} from "@store/network/actions";
import { createRequest } from "@src/utils/networks";
import { AccessResource } from "hyphen-lib/dist/domain/access/AccessResource";
import { MODALS as SURVEY_QUESTIONS_MODALS } from "@screens/Insights/Surveys/containers/SurveyQuestions";
import { SurveyBankQuestionPayload } from "@screens/Insights/Surveys/components/SurveyQuestions/QuestionBankModal";
import { SurveyQuestionResource } from "hyphen-lib/dist/domain/resource/SurveyQuestionResource";
import { AccessList } from "hyphen-lib/dist/domain/access/AccessList";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import notificationsActionCreators from "@store/notifications/actions";
import * as NotificationFactory from "@src/store/notifications/notification-factory";

export enum SurveyEditActionTypes {
  FETCH_SURVEY_REQUEST = "surveyEdit/FETCH_SURVEY_REQUEST",
  FETCH_SURVEY_SUCCESS = "surveyEdit/FETCH_SURVEY_SUCCESS",
  FETCH_SURVEY_ERROR = "surveyEdit/FETCH_SURVEY_ERROR",

  UPDATE_SURVEY = "surveyEdit/UPDATE_SURVEY",

  SAVE_SURVEY = "surveyEdit/SAVE_SURVEY",

  LAUNCH_SURVEY = "surveyEdit/LAUNCH_SURVEY",

  FETCH_PARTICIPANTS = "surveyEdit/FETCH_PARTICIPANTS",

  UPDATE_OPTIMISTIC_SURVEY_REQUEST = "surveyEdit/UPDATE_OPTIMISTIC_SURVEY_REQUEST",
  UPDATE_OPTIMISTIC_SURVEY_SUCCESS = "surveyEdit/UPDATE_OPTIMISTIC_SURVEY_SUCCESS",
  UPDATE_OPTIMISTIC_SURVEY_ERROR = "surveyEdit/UPDATE_OPTIMISTIC_SURVEY_ERROR",

  UPDATE_PESSIMISTIC_SURVEY_REQUEST = "surveyEdit/UPDATE_PESSIMISTIC_SURVEY_REQUEST",
  UPDATE_PESSIMISTIC_SURVEY_SUCCESS = "surveyEdit/UPDATE_PESSIMISTIC_SURVEY_SUCCESS",
  UPDATE_PESSIMISTIC_SURVEY_ERROR = "surveyEdit/UPDATE_PESSIMISTIC_SURVEY_ERROR",

  LAUNCH_SURVEY_REQUEST = "surveyEdit/LAUNCH_SURVEY_REQUEST",
  LAUNCH_SURVEY_SUCCESS = "surveyEdit/LAUNCH_SURVEY_SUCCESS",
  LAUNCH_SURVEY_ERROR = "surveyEdit/LAUNCH_SURVEY_ERROR",

  FETCH_MERGE_TAGS_REQUEST = "surveyEdit/FETCH_MERGE_TAGS_REQUEST",
  FETCH_MERGE_TAGS_SUCCESS = "surveyEdit/FETCH_MERGE_TAGS_SUCCESS",
  FETCH_MERGE_TAGS_ERROR = "surveyEdit/FETCH_MERGE_TAGS_ERROR",

  FETCH_SURVEY_TYPES_REQUEST = "surveyEdit/FETCH_SURVEY_TYPES_REQUEST",
  FETCH_SURVEY_TYPES_SUCCESS = "surveyEdit/FETCH_SURVEY_TYPES_SUCCESS",
  FETCH_SURVEY_TYPES_ERROR = "surveyEdit/FETCH_SURVEY_TYPES_ERROR",

  UPDATE_VALIDATION_ERRORS = "surveyEdit/UPDATE_VALIDATION_ERRORS",

  UPLOAD_SURVEY_LOGO_REQUEST = "surveyEdit/UPLOAD_SURVEY_LOGO_REQUEST",
  UPLOAD_SURVEY_LOGO_SUCCESS = "surveyEdit/UPLOAD_SURVEY_LOGO_SUCCESS",
  UPLOAD_SURVEY_LOGO_ERROR = "surveyEdit/UPLOAD_SURVEY_LOGO_ERROR",

  CHANGE_QUESTION_MODAL_STATE = "surveyEdit/CHANGE_QUESTION_MODAL_STATE",

  ADD_QUESTION_REQUEST = "surveyEdit/ADD_QUESTION_REQUEST",
  ADD_QUESTION_SUCCESS = "surveyEdit/ADD_QUESTION_SUCCESS",
  ADD_QUESTION_ERROR = "surveyEdit/ADD_QUESTION_ERROR",
  ADD_QUESTION_NON_NETWORK_ERROR = "surveyEdit/ADD_QUESTION_NON_NETWORK_ERROR",
  
  UPDATE_QUESTION_REQUEST = "surveyEdit/UPDATE_QUESTION_REQUEST",
  UPDATE_QUESTION_SUCCESS = "surveyEdit/UPDATE_QUESTION_SUCCESS",
  UPDATE_QUESTION_ERROR = "surveyEdit/UPDATE_QUESTION_ERROR",

  REMOVE_QUESTION_REQUEST = "surveyEdit/REMOVE_QUESTION_REQUEST",
  REMOVE_QUESTION_SUCCESS = "surveyEdit/REMOVE_QUESTION_SUCCESS",
  REMOVE_QUESTION_ERROR = "surveyEdit/REMOVE_QUESTION_ERROR",

  ADD_ACCESSES = "surveyEdit/ADD_ACCESSES",
  ADD_ACCESSES_REQUEST = "surveyEdit/ADD_ACCESSES_REQUEST",
  ADD_ACCESSES_SUCCESS = "surveyEdit/ADD_ACCESSES_SUCCESS",
  ADD_ACCESSES_ERROR = "surveyEdit/ADD_ACCESSES_ERROR",

  UPDATE_ACCESS = "surveyEdit/UPDATE_ACCESS",
  UPDATE_ACCESS_REQUEST = "surveyEdit/UPDATE_ACCESS_REQUEST",
  UPDATE_ACCESS_SUCCESS = "surveyEdit/UPDATE_ACCESS_SUCCESS",
  UPDATE_ACCESS_ERROR = "surveyEdit/UPDATE_ACCESS_ERROR",

  REMOVE_ACCESS = "surveyEdit/REMOVE_ACCESS",
  REMOVE_ACCESS_REQUEST = "surveyEdit/REMOVE_ACCESS_REQUEST",
  REMOVE_ACCESS_SUCCESS = "surveyEdit/REMOVE_ACCESS_SUCCESS",
  REMOVE_ACCESS_ERROR = "surveyEdit/REMOVE_ACCESS_ERROR",

  TOGGLE_MANAGERS_SYNCHRONIZATION_REQUEST = "TOGGLE_MANAGERS_SYNCHRONIZATION_REQUEST",
  TOGGLE_MANAGERS_SYNCHRONIZATION_SUCCESS = "TOGGLE_MANAGERS_SYNCHRONIZATION_SUCCESS",
  TOGGLE_MANAGERS_SYNCHRONIZATION_ERROR = "TOGGLE_MANAGERS_SYNCHRONIZATION_ERROR",

  UPDATE_ACCESS_LIST = "surveyEdit/UPDATE_ACCESS_LIST",

  UPDATE_DIFFERENTIAL_AUDIENCE_REQUEST = "surveyEdit/UPDATE_DIFFERENTIAL_AUDIENCE",
  UPDATE_DIFFERENTIAL_AUDIENCE_SUCCESS = "surveyEdit/UPDATE_DIFFERENTIAL_AUDIENCE_SUCCESS",
  UPDATE_DIFFERENTIAL_AUDIENCE_ERROR = "surveyEdit/UPDATE_DIFFERENTIAL_AUDIENCE_ERROR",

  TOGGLE_PREVIEW_MODAL_OPEN = "surveyEdit/TOGGLE_PREVIEW_MODAL_OPEN",
  TOGGLE_DIFFERENTIAL_AUDIENCE_MODAL = "surveyEdit/TOGGLE_DIFFERENTIAL_AUDIENCE_MODAL_OPEN",

  SEND_SURVEY_PREVIEW_REQUEST = "surveyEdit/SEND_SURVEY_PREVIEW_REQUEST",
  SEND_SURVEY_PREVIEW_SUCCESS = "surveyEdit/SEND_SURVEY_PREVIEW_SUCCESS",
  SEND_SURVEY_PREVIEW_ERROR = "surveyEdit/SEND_SURVEY_PREVIEW_ERROR",

  EXIT_PAGE = "surveyEdit/EXIT_PAGE",
}

interface Payloads {
  [SurveyEditActionTypes.FETCH_SURVEY_REQUEST]: { surveyId: string };
  [SurveyEditActionTypes.FETCH_SURVEY_SUCCESS]: NetworkEventSuccessPayload<SurveyResource>;

  [SurveyEditActionTypes.FETCH_PARTICIPANTS]: {
    surveyId: string;
    survey: SurveyResource;
  };

  [SurveyEditActionTypes.UPDATE_SURVEY]: {
    surveyId: string;
    survey: SurveyResource;
  };
  [SurveyEditActionTypes.SAVE_SURVEY]: {
    surveyId: string;
    survey: SurveyResource;
  };
  [SurveyEditActionTypes.UPDATE_OPTIMISTIC_SURVEY_REQUEST]: {
    surveyId: string;
    survey: SurveyResource;
  };
  [SurveyEditActionTypes.UPDATE_OPTIMISTIC_SURVEY_SUCCESS]: NetworkEventSuccessPayload<SurveyResource>;
  [SurveyEditActionTypes.UPDATE_PESSIMISTIC_SURVEY_REQUEST]: {
    surveyId: string;
    survey: SurveyResource;
  };
  [SurveyEditActionTypes.UPDATE_PESSIMISTIC_SURVEY_SUCCESS]: NetworkEventSuccessPayload<SurveyResource>;

  [SurveyEditActionTypes.LAUNCH_SURVEY]: { surveyId: string };
  [SurveyEditActionTypes.LAUNCH_SURVEY_SUCCESS]: NetworkEventSuccessPayload<SurveyResource>;
  [SurveyEditActionTypes.LAUNCH_SURVEY_ERROR]: NetworkEventErrorPayload;

  [SurveyEditActionTypes.FETCH_MERGE_TAGS_REQUEST]: { surveyType: Survey.Type };
  [SurveyEditActionTypes.FETCH_SURVEY_TYPES_REQUEST]: void;
  [SurveyEditActionTypes.UPDATE_VALIDATION_ERRORS]: {
    errors: WrongEntityException.Errors;
  };

  [SurveyEditActionTypes.ADD_ACCESSES]: { surveyId: string; emails: string[] };
  [SurveyEditActionTypes.UPDATE_ACCESS]: {
    surveyId: string;
    accessId: string;
    role: AccessList.UserAccessRole;
  };
  [SurveyEditActionTypes.REMOVE_ACCESS]: { surveyId: string; accessId: string };

  [SurveyEditActionTypes.TOGGLE_PREVIEW_MODAL_OPEN]: { open: boolean };
  [SurveyEditActionTypes.TOGGLE_DIFFERENTIAL_AUDIENCE_MODAL]: { open: boolean };
  [SurveyEditActionTypes.SEND_SURVEY_PREVIEW_REQUEST]: {
    surveyId: string;
    previewEmails: string[];
    message: string;
  };
  [SurveyEditActionTypes.SEND_SURVEY_PREVIEW_SUCCESS]: NetworkEventSuccessPayload<void>;
  [SurveyEditActionTypes.SEND_SURVEY_PREVIEW_ERROR]: NetworkEventErrorPayload;

  [SurveyEditActionTypes.EXIT_PAGE]: { surveyId: string };
  [SurveyEditActionTypes.ADD_QUESTION_ERROR]: { error: WrongEntityException[] };
  [SurveyEditActionTypes.ADD_QUESTION_NON_NETWORK_ERROR]: { error: WrongEntityException[] };
}

export interface FetchSurveySuccessAction
  extends FSA<
    SurveyEditActionTypes.FETCH_SURVEY_SUCCESS,
    Payloads[SurveyEditActionTypes.FETCH_SURVEY_SUCCESS]
  > {
  type: SurveyEditActionTypes.FETCH_SURVEY_SUCCESS;
  payload: Payloads[SurveyEditActionTypes.FETCH_SURVEY_SUCCESS];
}

export interface FetchSurveyParticipantsAction
  extends FSA<
    SurveyEditActionTypes.FETCH_PARTICIPANTS,
    Payloads[SurveyEditActionTypes.FETCH_PARTICIPANTS]
  > {
  type: SurveyEditActionTypes.FETCH_PARTICIPANTS;
  payload: Payloads[SurveyEditActionTypes.FETCH_PARTICIPANTS];
}

export interface UpdateSurveyAction
  extends FSA<
    SurveyEditActionTypes.UPDATE_SURVEY,
    Payloads[SurveyEditActionTypes.UPDATE_SURVEY]
  > {
  type: SurveyEditActionTypes.UPDATE_SURVEY;
  payload: Payloads[SurveyEditActionTypes.UPDATE_SURVEY];
}

export interface UpdateOptimisticSurveySuccessAction
  extends FSA<
    SurveyEditActionTypes.UPDATE_OPTIMISTIC_SURVEY_SUCCESS,
    Payloads[SurveyEditActionTypes.UPDATE_OPTIMISTIC_SURVEY_SUCCESS]
  > {
  type: SurveyEditActionTypes.UPDATE_OPTIMISTIC_SURVEY_SUCCESS;
  payload: Payloads[SurveyEditActionTypes.UPDATE_OPTIMISTIC_SURVEY_SUCCESS];
}

export interface UpdatePessimisticSurveySuccessAction
  extends FSA<
    SurveyEditActionTypes.UPDATE_PESSIMISTIC_SURVEY_SUCCESS,
    Payloads[SurveyEditActionTypes.UPDATE_PESSIMISTIC_SURVEY_SUCCESS]
  > {
  type: SurveyEditActionTypes.UPDATE_PESSIMISTIC_SURVEY_SUCCESS;
  payload: Payloads[SurveyEditActionTypes.UPDATE_PESSIMISTIC_SURVEY_SUCCESS];
}

export interface SaveSurveyAction
  extends FSA<
    SurveyEditActionTypes.SAVE_SURVEY,
    Payloads[SurveyEditActionTypes.SAVE_SURVEY]
  > {
  type: SurveyEditActionTypes.SAVE_SURVEY;
  payload: Payloads[SurveyEditActionTypes.SAVE_SURVEY];
}

export interface LaunchSurveyAction
  extends FSA<
    SurveyEditActionTypes.LAUNCH_SURVEY,
    Payloads[SurveyEditActionTypes.LAUNCH_SURVEY]
  > {
  type: SurveyEditActionTypes.LAUNCH_SURVEY;
  payload: Payloads[SurveyEditActionTypes.LAUNCH_SURVEY];
}

// noinspection JSUnusedGlobalSymbols
export interface LaunchSurveySuccessAction
  extends FSA<
    SurveyEditActionTypes.LAUNCH_SURVEY_SUCCESS,
    Payloads[SurveyEditActionTypes.LAUNCH_SURVEY_SUCCESS]
  > {
  type: SurveyEditActionTypes.LAUNCH_SURVEY_SUCCESS;
  payload: Payloads[SurveyEditActionTypes.LAUNCH_SURVEY_SUCCESS];
}

export interface LaunchSurveyErrorAction
  extends FSA<
    SurveyEditActionTypes.LAUNCH_SURVEY_ERROR,
    Payloads[SurveyEditActionTypes.LAUNCH_SURVEY_ERROR]
  > {
  type: SurveyEditActionTypes.LAUNCH_SURVEY_ERROR;
  payload: Payloads[SurveyEditActionTypes.LAUNCH_SURVEY_ERROR];
}

// eslint-disable-next-line max-len
export interface FetchMergeTagsRequestAction
  extends FSA<
    SurveyEditActionTypes.FETCH_MERGE_TAGS_REQUEST,
    Payloads[SurveyEditActionTypes.FETCH_MERGE_TAGS_REQUEST]
  > {
  type: SurveyEditActionTypes.FETCH_MERGE_TAGS_REQUEST;
  payload: Payloads[SurveyEditActionTypes.FETCH_MERGE_TAGS_REQUEST];
}

// eslint-disable-next-line max-len
export interface FetchSurveyTypesRequestAction
  extends FSA<
    SurveyEditActionTypes.FETCH_SURVEY_TYPES_REQUEST,
    Payloads[SurveyEditActionTypes.FETCH_SURVEY_TYPES_REQUEST]
  > {
  type: SurveyEditActionTypes.FETCH_SURVEY_TYPES_REQUEST;
  payload: Payloads[SurveyEditActionTypes.FETCH_SURVEY_TYPES_REQUEST];
}

// eslint-disable-next-line max-len
export interface UpdateValidationErrorsAction
  extends FSA<
    SurveyEditActionTypes.UPDATE_VALIDATION_ERRORS,
    Payloads[SurveyEditActionTypes.UPDATE_VALIDATION_ERRORS]
  > {
  type: SurveyEditActionTypes.UPDATE_VALIDATION_ERRORS;
  payload: Payloads[SurveyEditActionTypes.UPDATE_VALIDATION_ERRORS];
}

export interface AddAccessesAction
  extends FSA<
    SurveyEditActionTypes.ADD_ACCESSES,
    Payloads[SurveyEditActionTypes.ADD_ACCESSES]
  > {
  type: SurveyEditActionTypes.ADD_ACCESSES;
  payload: Payloads[SurveyEditActionTypes.ADD_ACCESSES];
}

export interface UpdateAccessAction
  extends FSA<
    SurveyEditActionTypes.UPDATE_ACCESS,
    Payloads[SurveyEditActionTypes.UPDATE_ACCESS]
  > {
  type: SurveyEditActionTypes.UPDATE_ACCESS;
  payload: Payloads[SurveyEditActionTypes.UPDATE_ACCESS];
}

export interface RemoveAccessAction
  extends FSA<
    SurveyEditActionTypes.REMOVE_ACCESS,
    Payloads[SurveyEditActionTypes.REMOVE_ACCESS]
  > {
  type: SurveyEditActionTypes.REMOVE_ACCESS;
  payload: Payloads[SurveyEditActionTypes.REMOVE_ACCESS];
}

export interface TogglePreviewModalOpenAction
  // eslint-disable-next-line max-len
  extends FSA<
    SurveyEditActionTypes.TOGGLE_PREVIEW_MODAL_OPEN,
    Payloads[SurveyEditActionTypes.TOGGLE_PREVIEW_MODAL_OPEN]
  > {
  type: SurveyEditActionTypes.TOGGLE_PREVIEW_MODAL_OPEN;
  payload: Payloads[SurveyEditActionTypes.TOGGLE_PREVIEW_MODAL_OPEN];
}

export interface ToggleDifferentialAudienceModalAction
  // eslint-disable-next-line max-len
  extends FSA<
    SurveyEditActionTypes.TOGGLE_DIFFERENTIAL_AUDIENCE_MODAL,
    Payloads[SurveyEditActionTypes.TOGGLE_DIFFERENTIAL_AUDIENCE_MODAL]
  > {
  type: SurveyEditActionTypes.TOGGLE_DIFFERENTIAL_AUDIENCE_MODAL;
  payload: Payloads[SurveyEditActionTypes.TOGGLE_DIFFERENTIAL_AUDIENCE_MODAL];
}

export interface SendSurveyPreviewSuccessAction
  extends FSA<
    SurveyEditActionTypes.SEND_SURVEY_PREVIEW_SUCCESS,
    Payloads[SurveyEditActionTypes.SEND_SURVEY_PREVIEW_SUCCESS]
  > {
  type: SurveyEditActionTypes.SEND_SURVEY_PREVIEW_SUCCESS;
  payload: Payloads[SurveyEditActionTypes.SEND_SURVEY_PREVIEW_SUCCESS];
}

export interface SendSurveyPreviewErrorAction
  extends FSA<
    SurveyEditActionTypes.SEND_SURVEY_PREVIEW_ERROR,
    Payloads[SurveyEditActionTypes.SEND_SURVEY_PREVIEW_ERROR]
  > {
  type: SurveyEditActionTypes.SEND_SURVEY_PREVIEW_ERROR;
  payload: Payloads[SurveyEditActionTypes.SEND_SURVEY_PREVIEW_ERROR];
}

export interface ExitPageAction
  extends FSA<
    SurveyEditActionTypes.EXIT_PAGE,
    Payloads[SurveyEditActionTypes.EXIT_PAGE]
  > {
  type: SurveyEditActionTypes.EXIT_PAGE;
  payload: Payloads[SurveyEditActionTypes.EXIT_PAGE];
}

function getSurvey(surveyTemplateId: string) {
  return createRequest(
    `/surveys/${surveyTemplateId}?include[]=questions&include[]=accesses`
  );
}

function updateSurvey(
  surveyId: string,
  survey: SurveyResource,
  accesses?: Optional<AccessResource[]>
) {
  let payload: any = survey;
  if (isNotNullNorUndefined(survey)) {
    payload = {
      ...payload,
      relationships: {
        accesses,
      },
    };
  }

  return createRequest(`/surveys/${surveyId}`, {
    method: "PUT",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export const createUploadSurveyLogoRequest = (surveyId: string, image: any) => {
  const formData = new FormData();
  formData.append("logo", image);
  return createRequest("/surveys/" + surveyId + "/logo", {
    method: "POST",
    body: formData,
  });
};

function addSurveyQuestion(
  surveyTemplateId: string,
  question: SurveyQuestionResource | SurveyBankQuestionPayload,
  queryParamObject?: Record<string, string>
) {
  let urlPath = `/surveys/${surveyTemplateId}/questions`;
  if(isNotNullNorUndefined(queryParamObject)) {
    urlPath += `?${new URLSearchParams(queryParamObject).toString()}`;
  }
  return createRequest(urlPath, {
    method: "POST",
    body: JSON.stringify(question),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function updateSurveyQuestion(
  surveyTemplateId: string,
  questionId: string,
  question: SurveyQuestionResource,
  queryParamObject?: Record<string, string>
) {
  let urlPath = `/surveys/${surveyTemplateId}/questions/${questionId}`;
  if(isNotNullNorUndefined(queryParamObject)) {
    urlPath += `?${new URLSearchParams(queryParamObject).toString()}`;
  }
  return createRequest(urlPath, {
    method: "PUT",
    body: JSON.stringify(question),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function removeSurveyQuestion(surveyTemplateId: string, questionId: string) {
  return createRequest(`/surveys/${surveyTemplateId}/questions/${questionId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

const addAccesses = (surveyId: string, surveyAccesses: AccessResource[]) => {
  return createRequest(`/surveys/${surveyId}/accesses/`, {
    method: "POST",
    body: JSON.stringify(surveyAccesses),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const updateAccess = (
  surveyId: string,
  accessId: string,
  surveyAccess: AccessResource
) => {
  return createRequest(`/surveys/${surveyId}/accesses/${accessId}`, {
    method: "PUT",
    body: JSON.stringify(surveyAccess),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const removeAccess = (surveyId: string, accessId: string) => {
  return createRequest(`/surveys/${surveyId}/accesses/${accessId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const toggleManagersSynchronization = (
  surveyId: string,
  areManagersSynchronized: boolean
) => {
  const data = {
    areManagersSynchronized,
  };
  return createRequest(`/surveys/${surveyId}/accesses/toggleSynchronize`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const launchSurvey = (surveyId: string) =>
  createRequest(`/surveys/${surveyId}/launch`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });

const updateAudienceForSurvey = (surveyId: string) =>
  createRequest("/surveys/audience/updateAudience", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ surveyId }),
  });

const sendSurveyPreview = (
  surveyId: string,
  previewEmails: string[],
  message: string
) => {
  const data = {
    previewEmails,
    message,
  };

  return createRequest(`/surveys/${surveyId}/previews`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

export const surveyEditActionCreators = {
  errorRemoveManualAudience: () =>
    notificationsActionCreators.displayNotification(
      NotificationFactory.error(
        "Cannot remove manual audience from launched or closed survey",
        "",
        4.5
      )
    ),

  fetchSurvey: (surveyTemplateId: string): NetworkRequestAction =>
    actionCreators.networkRequestWithActions({
      request: getSurvey(surveyTemplateId),
      requestAction: SurveyEditActionTypes.FETCH_SURVEY_REQUEST,
      successAction: SurveyEditActionTypes.FETCH_SURVEY_SUCCESS,
      errorAction: SurveyEditActionTypes.FETCH_SURVEY_ERROR,
      options: {
        noCache: true,
      },
    }),

  fetchParticipants: (
    surveyId: string,
    survey: SurveyResource
  ): FetchSurveyParticipantsAction => ({
    type: SurveyEditActionTypes.FETCH_PARTICIPANTS,
    payload: { surveyId, survey },
  }),

  updateSurvey: (
    surveyId: string,
    survey: SurveyResource
  ): UpdateSurveyAction => ({
    type: SurveyEditActionTypes.UPDATE_SURVEY,
    payload: {
      surveyId,
      survey,
    },
  }),

  saveSurvey: (surveyId: string, survey: SurveyResource): SaveSurveyAction => ({
    type: SurveyEditActionTypes.SAVE_SURVEY,
    payload: {
      surveyId,
      survey,
    },
  }),

  updateValidationErrors: (
    errors: WrongEntityException.Errors
  ): UpdateValidationErrorsAction => ({
    type: SurveyEditActionTypes.UPDATE_VALIDATION_ERRORS,
    payload: {
      errors,
    },
  }),

  updateOptimisticSurveyRequest: (
    surveyId: string,
    survey: SurveyResource
  ): NetworkRequestAction =>
    actionCreators.networkRequestWithActions({
      request: updateSurvey(surveyId, survey),
      requestAction: SurveyEditActionTypes.UPDATE_OPTIMISTIC_SURVEY_REQUEST,
      successAction: SurveyEditActionTypes.UPDATE_OPTIMISTIC_SURVEY_SUCCESS,
      errorAction: SurveyEditActionTypes.UPDATE_OPTIMISTIC_SURVEY_ERROR,
    }),

  updateSurveyNewAudience: (surveyId: string): NetworkRequestAction =>
    actionCreators.networkRequestWithActions({
      request: updateAudienceForSurvey(surveyId),
      requestAction: SurveyEditActionTypes.UPDATE_DIFFERENTIAL_AUDIENCE_REQUEST,
      successAction: SurveyEditActionTypes.UPDATE_DIFFERENTIAL_AUDIENCE_SUCCESS,
      errorAction: SurveyEditActionTypes.UPDATE_DIFFERENTIAL_AUDIENCE_ERROR,
    }),

  updatePessimisticSurveyRequest: (
    surveyId: string,
    survey: SurveyResource,
    accesses?: Optional<AccessResource[]>
  ): NetworkRequestAction =>
    actionCreators.networkRequestWithActions({
      request: updateSurvey(surveyId, survey, accesses),
      requestAction: SurveyEditActionTypes.UPDATE_PESSIMISTIC_SURVEY_REQUEST,
      successAction: SurveyEditActionTypes.UPDATE_PESSIMISTIC_SURVEY_SUCCESS,
      errorAction: SurveyEditActionTypes.UPDATE_PESSIMISTIC_SURVEY_ERROR,
    }),

  uploadSurveyLogo: (surveyId: string, image: any): NetworkRequestAction =>
    actionCreators.networkRequestWithActions({
      request: createUploadSurveyLogoRequest(surveyId, image),
      requestAction: SurveyEditActionTypes.UPLOAD_SURVEY_LOGO_REQUEST,
      successAction: SurveyEditActionTypes.UPLOAD_SURVEY_LOGO_SUCCESS,
      errorAction: SurveyEditActionTypes.UPLOAD_SURVEY_LOGO_ERROR,
    }),

  changeQuestionModalState: (
    isModalOpen: boolean,
    modalPurpose?: SURVEY_QUESTIONS_MODALS
  ) => ({
    type: SurveyEditActionTypes.CHANGE_QUESTION_MODAL_STATE,
    payload: {
      isModalOpen,
      modalPurpose,
    },
  }),

  addSurveyQuestion: (
    surveyId: string,
    question: SurveyQuestionResource | SurveyBankQuestionPayload,
    queryParamObject?: Record<string, string>,
    keepModalOpen?: boolean
  ): NetworkRequestAction =>
    actionCreators.networkRequestWithActions({
      request: addSurveyQuestion(surveyId, question, queryParamObject),
      requestAction: SurveyEditActionTypes.ADD_QUESTION_REQUEST,
      successAction: SurveyEditActionTypes.ADD_QUESTION_SUCCESS,
      errorAction: SurveyEditActionTypes.ADD_QUESTION_ERROR,
      meta: { surveyId, keepModalOpen },
    }),

  addQuestionErrors: (
    payload: Payloads[SurveyEditActionTypes.ADD_QUESTION_NON_NETWORK_ERROR]
  ) => ({ type: SurveyEditActionTypes.ADD_QUESTION_NON_NETWORK_ERROR, payload }),
  updateSurveyQuestion: (
    surveyId: string,
    questionId: string,
    question: SurveyQuestionResource,
    queryParams?: Record<string, string>,
    keepModalOpen?: boolean
  ): NetworkRequestAction =>
    actionCreators.networkRequestWithActions({
      request: updateSurveyQuestion(surveyId, questionId, question, queryParams),
      requestAction: SurveyEditActionTypes.UPDATE_QUESTION_REQUEST,
      successAction: SurveyEditActionTypes.UPDATE_QUESTION_SUCCESS,
      errorAction: SurveyEditActionTypes.UPDATE_QUESTION_ERROR,
      meta: { surveyId, keepModalOpen },
    }),

  removeSurveyQuestion: (
    surveyId: string,
    questionId: string
  ): NetworkRequestAction =>
    actionCreators.networkRequestWithActions({
      request: removeSurveyQuestion(surveyId, questionId),
      requestAction: SurveyEditActionTypes.REMOVE_QUESTION_REQUEST,
      successAction: SurveyEditActionTypes.REMOVE_QUESTION_SUCCESS,
      errorAction: SurveyEditActionTypes.REMOVE_QUESTION_ERROR,
      meta: { surveyId, questionId },
    }),

  addAccesses: (surveyId: string, emails: string[]): AddAccessesAction => ({
    type: SurveyEditActionTypes.ADD_ACCESSES,
    payload: { surveyId, emails },
  }),

  addAccessesRequest: (
    surveyId: string,
    accesses: AccessResource[]
  ): NetworkRequestAction =>
    actionCreators.networkRequestWithActions({
      request: addAccesses(surveyId, accesses),
      requestAction: SurveyEditActionTypes.ADD_ACCESSES_REQUEST,
      successAction: SurveyEditActionTypes.ADD_ACCESSES_SUCCESS,
      errorAction: SurveyEditActionTypes.ADD_ACCESSES_ERROR,
    }),

  updateAccess: (
    surveyId: string,
    accessId: string,
    role: AccessList.UserAccessRole
  ): UpdateAccessAction => ({
    type: SurveyEditActionTypes.UPDATE_ACCESS,
    payload: { surveyId, accessId, role },
  }),

  updateAccessRequest: (
    surveyId: string,
    accessId: string,
    accessResource: AccessResource
  ): NetworkRequestAction =>
    actionCreators.networkRequestWithActions({
      request: updateAccess(surveyId, accessId, accessResource),
      requestAction: SurveyEditActionTypes.UPDATE_ACCESS_REQUEST,
      successAction: SurveyEditActionTypes.UPDATE_ACCESS_SUCCESS,
      errorAction: SurveyEditActionTypes.UPDATE_ACCESS_ERROR,
    }),

  removeAccess: (surveyId: string, accessId: string): RemoveAccessAction => ({
    type: SurveyEditActionTypes.REMOVE_ACCESS,
    payload: { surveyId, accessId },
  }),

  removeAccessRequest: (
    surveyId: string,
    accessId: string
  ): NetworkRequestAction =>
    actionCreators.networkRequestWithActions({
      request: removeAccess(surveyId, accessId),
      requestAction: SurveyEditActionTypes.REMOVE_ACCESS_REQUEST,
      successAction: SurveyEditActionTypes.REMOVE_ACCESS_SUCCESS,
      errorAction: SurveyEditActionTypes.REMOVE_ACCESS_ERROR,
    }),

  updateAccessList: (accesses: AccessResource[]) => ({
    type: SurveyEditActionTypes.UPDATE_ACCESS_LIST,
    payload: { accesses },
  }),

  toggleManagersSynchronization: (
    surveyId: string,
    areManagersSynchronized: boolean
  ): NetworkRequestAction =>
    actionCreators.networkRequestWithActions({
      request: toggleManagersSynchronization(surveyId, areManagersSynchronized),
      requestAction:
        SurveyEditActionTypes.TOGGLE_MANAGERS_SYNCHRONIZATION_REQUEST,
      successAction:
        SurveyEditActionTypes.TOGGLE_MANAGERS_SYNCHRONIZATION_SUCCESS,
      errorAction: SurveyEditActionTypes.TOGGLE_MANAGERS_SYNCHRONIZATION_ERROR,
    }),

  launchSurvey: (surveyId: string): LaunchSurveyAction => ({
    type: SurveyEditActionTypes.LAUNCH_SURVEY,
    payload: {
      surveyId,
    },
  }),

  launchSurveyRequest: (surveyId: string): NetworkRequestAction =>
    actionCreators.networkRequestWithActions({
      request: launchSurvey(surveyId),
      requestAction: SurveyEditActionTypes.LAUNCH_SURVEY_REQUEST,
      successAction: SurveyEditActionTypes.LAUNCH_SURVEY_SUCCESS,
      errorAction: SurveyEditActionTypes.LAUNCH_SURVEY_ERROR,
    }),

  toggleSurveyPreviewModalOpen: (
    open: boolean
  ): TogglePreviewModalOpenAction => ({
    type: SurveyEditActionTypes.TOGGLE_PREVIEW_MODAL_OPEN,
    payload: { open },
  }),

  toggleDifferentialAudienceModal: (
    open: boolean
  ): ToggleDifferentialAudienceModalAction => ({
    type: SurveyEditActionTypes.TOGGLE_DIFFERENTIAL_AUDIENCE_MODAL,
    payload: { open },
  }),

  sendSurveyPreview: (
    surveyId: string,
    previewEmails: string[],
    message: string
  ): NetworkRequestAction =>
    actionCreators.networkRequestWithActions({
      request: sendSurveyPreview(surveyId, previewEmails, message),
      requestAction: SurveyEditActionTypes.SEND_SURVEY_PREVIEW_REQUEST,
      successAction: SurveyEditActionTypes.SEND_SURVEY_PREVIEW_SUCCESS,
      errorAction: SurveyEditActionTypes.SEND_SURVEY_PREVIEW_ERROR,
    }),

  exitPage: (surveyId: string): ExitPageAction => ({
    type: SurveyEditActionTypes.EXIT_PAGE,
    payload: { surveyId },
  }),
};
