import React from "react";
import styled from "styled-components";
import { SelectValue } from "antd/lib/select";

import { getOr } from "@hyphen-lib/lang/Objects";
import { Optional } from "@hyphen-lib/lang/Optionals";
import { Heading } from "@components/core/Typography";
import Palette from "@src/config/theme/palette";
import SelectDimension from "@screens/Insights/Survey/components/SelectDimension";

/* eslint-disable max-len */
import { MultipleChoiceQuestionReportResource } from "hyphen-lib/dist/domain/resource/report/MultipleChoiceQuestionReportResource";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { ParticipationReportResource } from "hyphen-lib/dist/domain/resource/survey/report/ParticipationReportResource";
import AnswersChart from "../AnswersChart";
import { Trans } from "react-i18next";
import {  Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
/* eslint-disable max-len */

interface Props {
  question: MultipleChoiceQuestionReportResource.NonFiltered;
  dimensions: MultipleChoiceQuestionReportResource.SegmentsSummary["dimensions"];
  dimensionsLabels: Dimensions;
  choiceLabels: Optional<string>[];
  selectedDimension: SelectValue;
  anonymityThreshold: number;
  isAnonymous?: boolean;
  title?: string;
  readonly participationBySegments?: Store.Element<ParticipationReportResource>;
  updateSegmentByInPath(selectedDimension: SelectValue): void;
}

class Answers extends React.Component<Props> {
  changeDimension = (selectedDimension: SelectValue) => {
    this.props.updateSegmentByInPath(selectedDimension);
  };

  render() {
    const {
      question,
      dimensions,
      choiceLabels,
      selectedDimension="",
      anonymityThreshold,
      title = "Answers",
      participationBySegments,
      isAnonymous = true,
      dimensionsLabels
    } = this.props;
    const dimension = getOr(dimensions[selectedDimension.toString()], {});
    const availableDimensions = Object.keys(getOr(dimensions, {}));

    return (
      <Container>
        <Heading size="large" data-cy="answers_heading">
          <Trans>{title}</Trans>
        </Heading>
        <SelectDimensionContainer>
          <SelectDimension
            data={availableDimensions}
            selected={selectedDimension}
            dimensions={dimensionsLabels as any /* fixme: this is ugly, we should get available dimensions from company! */}
            onChangeDimension={this.changeDimension}
          />
        </SelectDimensionContainer>
        <ChartContainer>
          <AnswersChart
            question={question}
            dimension={dimension}
            selectedDimension={selectedDimension}
            choiceLabels={choiceLabels}
            anonymityThreshold={anonymityThreshold}
            participationBySegments={participationBySegments}
            isAnonymous={isAnonymous}
          />
        </ChartContainer>
      </Container>
    );
  }
}

const Container = styled.div`
  padding: 32px;
  background: ${Palette.white};
  font-family: Lato,sans-serif;
  color: ${Palette.veryDarkBlueGrey};
  box-shadow: 0 4px 8px 0 rgba(198, 214, 223, 0.15);
  border-radius: 3px;
`;

const SelectDimensionContainer = styled.div`
  margin-top: 16px;
`;

const ChartContainer = styled.div`
  padding-top: 24px;
`;

export default Answers;
