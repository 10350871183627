import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Trans } from "react-i18next";
import {
  Body,
  BodySideNav,
  BodyWrapper
} from "@src/components/layouts/ReportContainer";
import CardMenu from "@src/components/core/CardMenu";
import { goTo } from "@src/utils/locations";
import { PropMapping } from "@src/utils/parameters";

import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { RightsMatcher } from "hyphen-lib/dist/business/auth/Auth";
import { Rights } from "hyphen-lib/dist/business/auth/Rights";
import { VoiceReportsHeader } from "./Containers/VoiceReportsHeader";
import { VoiceReportsRoutes } from "./VoiceReportsRoutes";

const voiceReportsPath = "/voice/reports/";
export const VOICE_POSTS_FILTER_MAPPINGS: PropMapping[] = [
  { localKey: "filter", storeKey: "voicePosts" },
  { localKey: "filter.sentiments", storeKey: "voicePosts.sentiments" },
  { localKey: "filter.createdAtDate", storeKey: "voicePosts.createdAtDate" },
  { localKey: "filter.freeText", storeKey: "voicePosts.freeText" },
  { localKey: "filter.groups", storeKey: "voicePosts.groups" },
];

export const VOICE_COMMENTS_FILTER_MAPPINGS: PropMapping[] = [
  { localKey: "filter", storeKey: "voiceComments" },
  { localKey: "filter.sentiments", storeKey: "voiceComments.sentiments" },
  { localKey: "filter.createdAtDate", storeKey: "voiceComments.createdAtDate" },
  { localKey: "filter.freeText", storeKey: "voiceComments.freeText" },
  { localKey: "filter.groups", storeKey: "voiceComments.groups" },
];

interface CardMenuItemProps {
  readonly label: string;
  readonly redirect: string;
  readonly mappings: PropMapping[];
  readonly searchPlaceHolder: string;
  readonly right: string;
}

interface VoiceReportProps extends RouteComponentProps {
  rightsMatcher: RightsMatcher;
}

const cardMenuItems: CardMenuItemProps[] = [
  {
    label: "Posts",
    redirect: "posts",
    mappings: VOICE_POSTS_FILTER_MAPPINGS,
    searchPlaceHolder: "Search posts",
    right: Rights.Voice.POSTS,
  },
  {
    label: "Comments",
    redirect: "comments",
    mappings: VOICE_COMMENTS_FILTER_MAPPINGS,
    searchPlaceHolder: "Search comments",
    right: Rights.Voice.COMMENTS,
  },
  {
    label: "Flagged Posts",
    redirect: "flags/posts",
    mappings: VOICE_COMMENTS_FILTER_MAPPINGS,
    searchPlaceHolder: "Search flagged posts",
    right: Rights.Voice.FLAGGED_POSTS,
  },
  {
    label: "Flagged Comments",
    redirect: "flags/comments",
    mappings: VOICE_COMMENTS_FILTER_MAPPINGS,
    searchPlaceHolder: "Search flagged comments",
    right: Rights.Voice.FLAGGED_COMMENTS,
  },
];

function Reports({ location: { pathname }, rightsMatcher }: VoiceReportProps) {
  function renderSideMenu(item: CardMenuItemProps) {
    if (rightsMatcher.hasRight(item.right)) {
      return (
        <CardMenu.Item
          key={item.redirect}
          onClick={redirect.bind(null, item.redirect)}
        >
          <Trans>{item.label}</Trans>
        </CardMenu.Item>
      );
    }
  }

  function redirect(path: string) {
    const newPath = voiceReportsPath + path;
    if (pathname !== newPath) {
      goTo(voiceReportsPath + path);
    }
  }

  function getSelectedKey() {
    return [pathname.replace(voiceReportsPath, "")];
  }

  const [selectedKey] = getSelectedKey();

  function getActiveCardMenuItem() {
    return cardMenuItems.find(val => val.redirect === selectedKey);
  }

  function getActiveCardItemProp(
    prop: keyof CardMenuItemProps
  ): string | PropMapping[] | undefined {
    const selectedCardMenuItem = getActiveCardMenuItem();
    if (isNotNullNorUndefined(selectedCardMenuItem)) {
      return selectedCardMenuItem[prop];
    }
  }

  return (
    <Body>
      <BodySideNav>
        <CardMenu selectedKeys={[selectedKey]}>
          {cardMenuItems.map(renderSideMenu)}
        </CardMenu>
      </BodySideNav>
      <BodyWrapper>
        <VoiceReportsHeader
          selectedKey={selectedKey}
          mappings={(getActiveCardItemProp("mappings") as PropMapping[]) || []}
          searchPlaceholder={getActiveCardItemProp("searchPlaceHolder") as string}
        />
        <VoiceReportsRoutes />
      </BodyWrapper>
    </Body>
  );
}

export const VoiceReports = withRouter(Reports);
