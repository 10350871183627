import React from "react";
import moment, { Moment } from "moment";
import { SelectValue, SelectProps } from "antd/lib/select";
import { RangePickerValue } from "antd/lib/date-picker/interface";
import Select from "@components/core/Select";
import { RangePicker } from "@components/core/DatePicker";
import { parseNumber } from "hyphen-lib/dist/lang/Number";
import { DateFilter as DateFilterType } from "@hyphen-lib/domain/parameter/DateFilter";
import { BaseFilterProps } from "@screens/Insights/components/FiltersContent";
import { IllegalStateException } from "hyphen-lib/dist/lang/exception/IllegalStateException";
import styled from "styled-components";
import { Trans } from "react-i18next";

const { Option } = Select;

export class DateFilter extends React.Component<BaseFilterProps<DateFilterType>> {
  state = {
    isDateRangeSelectorOpen: false,
  };

  getDateOption = (date?: DateFilterType) => {
    const option: { value: string; dates?: [Moment, Moment] } = { value: "" };
    if (!date) {
      return option;
    }
    if (date.type === "range") {
      option.value = "custom";
      option.dates = [moment(Number(date.from)), moment(Number(date.to))];
    } else if (date.type === "lastXDays") {
      switch (parseNumber(date.numberOfDays.toString())) {
        case 30:
          option.value = "last30days";
          break;
        case 90:
          option.value = "last90days";
          break;
        case 180:
          option.value = "last6months";
          break;
        case 365:
          option.value = "lastYear";
          break;
      }
    }
    return option;
  };

  changeDate = (selectedValue: SelectValue) => {
    let date: DateFilterType;
    switch (selectedValue) {
      case "last30days":
        date = { type: DateFilterType.Types.LAST_X_DAYS, numberOfDays: 30 };
        break;
      case "last90days":
        date = { type: DateFilterType.Types.LAST_X_DAYS, numberOfDays: 90 };
        break;
      case "last6months":
        date = { type: DateFilterType.Types.LAST_X_DAYS, numberOfDays: 180 };
        break;
      case "lastYear":
        date = { type: DateFilterType.Types.LAST_X_DAYS, numberOfDays: 365 };
        break;
      case "custom":
        date = {
          type: DateFilterType.Types.RANGE,
          from: moment().startOf("month").valueOf(),
          to: moment().valueOf(),
        };
        break;
      default:
        throw new IllegalStateException(`Unable to parse date ${selectedValue}`);
    }
    this.props.onChange(date);
  };

  changeDateRange = (dates: RangePickerValue) => {
    const [fromDate, toDate] = dates;
    if (!fromDate || !toDate) {
      return;
    }
    this.props.onChange({
      type: DateFilterType.Types.RANGE,
      from: fromDate.valueOf(),
      to: toDate.valueOf(),
    });
  };

  toggleDateRangeSelector = (isOpen: boolean) => {
    this.setState({ isDateRangeSelectorOpen: isOpen });
  };

  render () {
    const { isDateRangeSelectorOpen } = this.state;

    const { value, label = "Date" } = this.props;

    const defaultDate = this.getDateOption(value);
    return (
      <div>
        <StyledSelect label={label} value={defaultDate.value} onChange={this.changeDate} data-cy="core_date_filter">
          <Option value="last30days"><Trans>Last 30 days</Trans></Option>
          <Option value="last90days"><Trans>Last 90 days</Trans></Option>
          <Option value="last6months"><Trans>Last 6 months</Trans></Option>
          <Option value="lastYear"><Trans>Last year</Trans></Option>
          <Option value="custom"><Trans>Custom</Trans></Option>
        </StyledSelect>
        {defaultDate.value === "custom" && (
          <StyledRangePicker
            open={isDateRangeSelectorOpen}
            onChange={this.changeDateRange}
            onOpenChange={this.toggleDateRangeSelector}
            defaultValue={defaultDate.dates}/>
        )}
      </div>

    );
  }
}

const StyledRangePicker = styled(RangePicker)`
  .ant-calendar-range-middle {
    left: 45%;
  }
`;

const StyledSelect = styled(Select)<SelectProps & {children: React.ReactNode}>``;
