import React from "react";
import { BatarangResult } from "hyphen-lib/dist/domain/resource/batarang/BatarangResult";
import { entries, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { isString } from "hyphen-lib/dist/lang/Strings";
import Palette from "@src/config/theme/palette";
import styled from "styled-components";
import { Trans } from "react-i18next";

export interface BatarangExecutionResultProps {
  readonly result: BatarangResult;
}

export class BatarangExecutionResult extends React.Component<BatarangExecutionResultProps> {
  displayResult(result: BatarangResult) {
    switch (result.type) {
      case BatarangResult.Type.ERROR:
        return this.displayError(result);
      case BatarangResult.Type.NO_OP:
        return this.displayNoOp();
      case BatarangResult.Type.RESULTS:
        return this.displayResults(result);
    }
  }

  displayError(result: BatarangResult.Error) {
    return <Error>{result.error}</Error>;
  }

  displayNoOp() {
    return <NoOp><Trans>Nothing to do.</Trans></NoOp>;
  }

  renderResultValue(value: string | { [key: string]: any }) {
    if (isString(value)) {
      return value;
    } else if (value.isLink === true && isNotNullNorUndefined(value.link)) {
      const link = value.link;
      return (
        <GenratedUrlWrapper>
          <GenratedUrl>{link}</GenratedUrl>

          <a
            style={{
              backgroundColor: Palette.bluishGrey,
              textDecoration: "none",
              color: Palette.darkModerateBlue,
              padding: "4px",
              borderRadius: "2px",
              fontWeight: "bold",
              border: "none"
            }}
            onClick={() => navigator.clipboard.writeText(link)}
          >
            <Trans>Copy Link</Trans>
          </a>
        </GenratedUrlWrapper>
      );
    } else {
      return JSON.stringify(value);
    }
  }
  displayResults(result: BatarangResult.Results) {
    return (
      <Results>
        {entries(result.results)
          .map((value, key) => (
            <ResultLine key={key}>
              <ResultLabel>{key}</ResultLabel>
              <ResultValue>{this.renderResultValue(value)}</ResultValue>
            </ResultLine>
          ))
          .valueSeq()}
      </Results>
    );
  }

  render() {
    const { result } = this.props;

    return (
      <>
        <SectionHeader><Trans>Logs</Trans></SectionHeader>
        <Logs logs={result.logs} />
        <SectionHeader>Results</SectionHeader>
        {this.displayResult(result)}
      </>
    );
  }
}

const GenratedUrlWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

const GenratedUrl = styled.div`
  color: ${Palette.veryDarkBlueGrey};
  font-weight: bold;
  margin-right: 24px;
`;


const Error = styled.span`
  color: red;
`;

const NoOp = styled.span``;

const Results = styled.div``;

const ResultLine = styled.div``;

const ResultLabel = styled.span`
  font-weight: bold;
  margin: 0 5px 0 0;
`;

const ResultValue = styled.span`
  overflow-wrap: break-word;
`;

const SectionHeader = styled.div`
  margin: 10px 0 5px -10px;
  font-weight: bold;
  font-size: 12pt;
`;

function Logs({ logs }: { logs: string[] }) {
  return (
    <LogsContainer>
      {logs.map((logsLine, lineIdx) => (
        <LogsLine key={"logsLine#" + lineIdx}>{logsLine}</LogsLine>
      ))}
    </LogsContainer>
  );
}

const LogsContainer = styled.div`
  background-color: #000;
  padding: 5px;
  box-shadow: 1px 1px 2px 1px #000;
  color: #00ff00;
  font-family: "Courier New", monospace;
  font-size: 8pt;
  white-space: pre-wrap;
`;

const LogsLine = styled.div``;
