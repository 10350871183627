import { freeze } from "hyphen-lib/dist/lang/Objects";
import { takeLatest, put } from "redux-saga/effects";
import notificationActionCreators, {
  ShowNotificationAction
} from "@store/notifications/actions";
import { SegmentReportActionTypes } from "./actions";
import * as NotificationFactory from "@src/store/notifications/notification-factory";

function* showSuccess() {
  yield put<ShowNotificationAction>(
    notificationActionCreators.displayNotification(
      NotificationFactory.success(
        "Private message sent successfully",
        "",
        4.5
      )
  ));
}

function* showError() {
  yield put<ShowNotificationAction>(
    notificationActionCreators.displayNotification(
      NotificationFactory.error(
        "Error occurred in sending Private Message",
        "",
        4.5
      )
  ));
}

export const segmentReportSagas = freeze([
  /* All sagas go here */
  takeLatest(SegmentReportActionTypes.SEND_PRIVATE_MESSAGE_SUCCESS, showSuccess),

  takeLatest(SegmentReportActionTypes.SEND_PRIVATE_MESSAGE_ERROR, showError),
]);
