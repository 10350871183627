import React from "react";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Dispatch, bindActionCreators } from "redux";

import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { ParticipantCountResource } from "@hyphen-lib/domain/resource/participants/ParticipantCountResource";
import { State } from "@store/types";
import SurveyDetails from "@screens/Insights/Survey/components/SurveyDetails";
import {
  SurveyResourceRecord
} from "@screens/Insights/Surveys/store/types";
import * as selectors from "@screens/Insights/Surveys/store/selectors";
import actionCreators from "@screens/Insights/Surveys/store/actions";
import ImmutableMapType from "@src/utils/CreateImmutableType";
import { QuestionConfig } from "hyphen-lib/dist/domain/common/QuestionType";
import { getCompany } from "@src/screens/Insights/store/selectors";

interface MatchParams {
  id: string;
}

interface StateProps {
  survey: SurveyResourceRecord;
  surveyParticipantsCount: ImmutableMapType<ParticipantCountResource>;
  channels: CompanyResource["channels"];
  questionConfig: QuestionConfig;
}

export type OwnProps = RouteComponentProps<MatchParams>;

type Props = {
  dispatch: Dispatch;
} & OwnProps & StateProps & typeof actionCreators;

export class SurveyDetailsContainer extends React.Component<Props> {
  boundActionCreators: typeof actionCreators;

  constructor(props: Props) {
    super(props);

    const { dispatch } = props;

    this.boundActionCreators = bindActionCreators(actionCreators, dispatch);
  }

  componentDidMount() {
    const { survey } = this.props;
    const surveyId = this.props.match.params.id;
    this.boundActionCreators.fetchSurvey(surveyId);
    this.boundActionCreators.getParticipantsCount(surveyId, survey);
  }

  render() {
    const { survey, channels, surveyParticipantsCount, questionConfig } = this.props;
    return (
      <SurveyDetails
        data={survey}
        channels={channels}
        surveyParticipantsCount={surveyParticipantsCount}
        questionConfig = {questionConfig}
      />
    );
  }
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (
  state: State
): StateProps => {
  return ({
    survey: selectors.fetchSurveyDetails(state),
    channels: selectors.getChannels(state),
    surveyParticipantsCount: selectors.fetchSurveyParticipantsCount(state),
    questionConfig: getCompany(state)!.questionConfig,
  });
};

export default connect(mapStateToProps)(SurveyDetailsContainer);
