import React from "react";
import Palette from "@src/config/theme/palette";
import { Tag } from "@components/core/Tag";
import { Survey } from "@hyphen-lib/domain/Survey";
import { Post } from "hyphen-lib/dist/domain/Post";

interface Props {
  status: Survey.Status | Post.Status;
  type?: "survey" | "poll";
  translate?: string;
}

interface StatusTagColorProps {
  text: string;
  color: string;
  textColor: string;
}

export function StatusTag({ status, type = "survey", ...rest }: Props) {
  const { text, color, textColor } =
    type === "survey"
      ? mapStatusToTextAndColor(status)
      : mapPulsePollStatusToTextAndColor(status);
  return (
    <Tag color={color} textColor={textColor} translate="yes">{text}</Tag>
  );
}

function mapStatusToTextAndColor(
  status: Survey.Status | Post.Status
): StatusTagColorProps {
  switch (status) {
    case Survey.Statuses.DRAFT:
      return {
        text: "Draft",
        color: Palette.lightPeriwinkle,
        textColor: Palette.veryDarkBlueGrey,
      };
    case Survey.Statuses.LAUNCHING_REQUESTED:
    case Survey.Statuses.LAUNCHING_IN_PROGRESS:
      return {
        text: "Launching",
        color: Palette.lightPeriwinkle,
        textColor: Palette.veryDarkBlueGrey,
      };
    case Survey.Statuses.CLOSED:
      return {
        text: "Closed",
        color: Palette.darkModerateBlue,
        textColor: Palette.white,
      };
    case Survey.Statuses.LAUNCHED:
      return {
        text: "In progress",
        color: Palette.aquaBlue_50,
        textColor: Palette.veryDarkBlueGrey,
      };
    case Post.Status.ACTIVE:
      return {
        text: "Active",
        color: Palette.aquaBlue_50,
        textColor: Palette.veryDarkBlueGrey,
      };
    case "launching_in_progress":
      return {
        text: "Launching",
        color: Palette.lightPeriwinkle,
        textColor: Palette.veryDarkBlueGrey,
      };
    default:
      return {
        text: `Unknown: ${status}`,
        color: Palette.paleGrey,
        textColor: Palette.veryDarkBlueGrey,
      };
  }
}

function mapPulsePollStatusToTextAndColor(
  status: Survey.Status | Post.Status
): StatusTagColorProps {
  switch (status) {
    case Post.Status.DRAFT:
      return {
        text: "Draft",
        color: Palette.lightPeriwinkle,
        textColor: Palette.veryDarkBlueGrey,
      };
    case Post.Status.LAUNCHING_REQUESTED:
      return {
        text: "Launching",
        color: Palette.lightPeriwinkle,
        textColor: Palette.veryDarkBlueGrey,
      };
    case Post.Status.LAUNCHED:
      return {
        text: "In progress",
        color: Palette.aquaBlue_50,
        textColor: Palette.veryDarkBlueGrey,
      };
    case Post.Status.ACTIVE:
      return {
        text: "Active",
        color: Palette.aquaBlue_50,
        textColor: Palette.veryDarkBlueGrey,
      };
    case Post.Status.DEACTIVATED:
      return {
        text: "Paused",
        color: Palette.darkModerateBlue,
        textColor: Palette.white,
      };
    case Post.Status.CLOSED:
      return {
        text: "Closed",
        color: Palette.darkModerateBlue,
        textColor: Palette.white,
      };
    default:
      return {
        text: `Unknown: ${status}`,
        color: Palette.paleGrey,
        textColor: Palette.veryDarkBlueGrey,
      };
  }
}
