import React from "react";
import styled from "styled-components";
import { Icon } from "antd";
// import { Trans } from "react-i18next";
import { Paragraph } from "@components/core/Typography";
import Tooltip from "@components/core/Tooltip";
import Palette from "@src/config/theme/palette";
import { SurveyTemplateResource } from "hyphen-lib/dist/domain/resource/SurveyTemplateResource";

import {
  Engagement
} from "@components/core/svg";
import { SvgProperties } from "csstype";
import { getOr, isNullOrUndefined } from "hyphen-lib/dist/lang/Objects";

import AlumniIcon from "@src/img/templates/alumni.png";
import CultureAssessmentIcon from "@src/img/templates/culture_assessment.png";
import EngagementExtendedIcon from "@src/img/templates/engagement_extended.png";
import EngagementMiniIcon from "@src/img/templates/engagement_mini.png";
import EngagementStandardIcon from "@src/img/templates/engagement_standard.png";
import ExitIcon from "@src/img/templates/exit.png";
import HealthAndWellnessIcon from "@src/img/templates/health_and_wellness.png";
import ManagerEffectivenessIcon from "@src/img/templates/manager_effectiveness.png";
import OnboardingIcon from "@src/img/templates/onboarding.png";
import GraphIcon from "@src/img/templates/graph.png";
import OrganizationalChange from "@src/img/templates/OrganizationalChange.png";
import RemoteWorkAssessment from "@src/img/templates/RemoteWorkAssessment.png";
import RemoteWorkPulse from "@src/img/templates/RemoteWorkPulse.png";
import ReturnToWork from "@src/img/templates/ReturnToWork.png";
import OKR_Baseline from "@src/img/templates/OKR_Baseline.png";
import OKR_Reassessment from "@src/img/templates/OKR_Reassessment.png";
import DEIB from "@src/img/templates/DEIB.png";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Trans } from "react-i18next";

const DEFAULT_ICON: (props: SvgProperties) => JSX.Element = Engagement;

const ContentIcons: any = {
  "alumni": AlumniIcon,
  "culture_assessment": CultureAssessmentIcon,
  "employee_health": HealthAndWellnessIcon,
  "engagement_extended": EngagementExtendedIcon,
  "engagement_mini": EngagementMiniIcon,
  "engagement_standard": EngagementStandardIcon,
  "exit": ExitIcon,
  "manager_effectiveness": ManagerEffectivenessIcon,
  "onboarding": OnboardingIcon,
  "organizational_change": OrganizationalChange,
  "remote_work_assessment": RemoteWorkAssessment,
  "remote_work_pulse": RemoteWorkPulse,
  "return_to_work": ReturnToWork,
  "bw_baseline": OKR_Baseline,
  "bw_reassessment": OKR_Reassessment,
  "bw_deib": DEIB
};

function getContentIcon(imageKey: Optional<string>) {
  if (isNullOrUndefined(imageKey)) {
    return DEFAULT_ICON;
  }
  return getOr(ContentIcons[imageKey], DEFAULT_ICON);
}

interface Props {
  selected?: boolean;
  imageKey?: Optional<string>;
  hasBenchmark: boolean;
  description?: Optional<string>;
  title: string;
  posts: SurveyTemplateResource["posts"];
  onTemplateClick?: () => void;
  onPreviewClick?: () => void;
}

const TemplateCard = (props: Props) => {

  const {
    selected = false,
    imageKey,
    description,
    hasBenchmark,
    title,
    onTemplateClick,
    onPreviewClick,
    posts,
  } = props;

  const CardAction = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 8px;
    svg, p {
      color: ${selected ? "white" : Palette.bluePurple};
    }
    flex-basis: 36px;
    background-color: ${selected ? Palette.primary : Palette.paleGrey};
  `;

  const Card = styled.div`
    max-width: 285px;
    height: 210px;
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    background-color: ${selected
    ? Palette.lightLightBlue
    : "#fff "} !important;
    border: 1px solid ${Palette.lightGreyBlue};
    box-shadow: 0 4px 8px 0 rgba(198, 214, 223, 0.15);
    overflow: hidden;
    cursor: pointer;
  `;

  const ContentIcon = getContentIcon(imageKey);
  let questionsText = "no questions";
  if (posts.length === 1) {
    questionsText = "1 question";
  } else if (posts.length > 0) {
    questionsText = `${posts.length} questions`;
  }
  return (
    <Card>
      <CardInfo onClick={onTemplateClick}>
        {
          hasBenchmark &&
          <Tooltip
            title={<Trans>Industry benchmarks available</Trans>} 
            wrapperClassName="benchmark-tooltip">
            <BenchmarkIcon src={GraphIcon}/>
          </Tooltip>
        }
        {/* <ContentIcon fill={selected ? "#fff" : Palette.veryDarkBlueGrey} /> */}
        <ContentImg src = {ContentIcon} />
        <Heading className="px-2">
          {title}</Heading>
        {
          description &&
          <HelperText className="px-2"><Trans>{description}</Trans></HelperText>
        }
        <Paragraph>{questionsText}</Paragraph>
      </CardInfo>
      <CardAction onClick={onPreviewClick}>
        <SearchIcon type="search" />
        <Paragraph weight="bold" translate="yes">Preview</Paragraph>
      </CardAction>
    </Card>
  );
};

  const CardInfo = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    svg {
      margin-bottom: 4px;
    }
    p {
      color: ${Palette.veryDarkBlueGrey};
    }
    justify-content: center;
    align-items: center;
  `;


  const SearchIcon = styled(Icon)`
    font-weight: bold;
    margin-right: 4px;
  `;

  const ContentImg = styled.img`
    width: 34%;
    object-fit: scale-down;
    max-height: 80px;
  `;

  const BenchmarkIcon = styled.img`
    margin-bottom: 6px;
    width: 40px;
  `;

  const HelperText = styled.div`
    font-size: 12px;
    color: ${Palette.veryDarkBlueGrey};
  `;

  const Heading = styled.div`
    font-weight: bold;
    margin: unset;
    font-size: 18px;
    color: ${Palette.veryDarkBlueGrey};
    text-align: center;
  `;

export default TemplateCard;