import React from "react";
import { Dispatch } from "redux";
import styled from "styled-components";

import { SurveyQuestionResource } from "hyphen-lib/dist/domain/resource/SurveyQuestionResource";
import ContainerCard from "@components/core/ContainerCard";
import Button from "@components/core/Button";
import Modal from "@components/core/Modal";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import {
  getOr,
  isNotNullNorUndefined,
  isNullOrUndefined,
} from "hyphen-lib/dist/lang/Objects";
import { Paragraph } from "@src/components/core/Typography";
import { SurveyBankQuestionResource } from "hyphen-lib/dist/domain/resource/survey/SurveyBankQuestionResource";
import { PostCategoryResource } from "hyphen-lib/dist/domain/resource/post/PostCategoryResource";
import { ModalProps } from "antd/lib/modal";
import { Spin } from "antd";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import { Survey } from "hyphen-lib/dist/domain/Survey";
import { QuestionConfig } from "hyphen-lib/dist/domain/common/QuestionType";
import { QuestionPageStateProps } from "../store/surveyEditTypes";
import { AddOrEditQuestionModal } from "../../components/Wizard/Questions/AddOrEditQuestionModal";
import {
  QuestionBankModal,
  SurveyBankQuestionPayload,
} from "../components/SurveyQuestions/QuestionBankModal";
import {
  AddQuestionModal,
  modalContainerStyle,
} from "../components/SurveyQuestions/modalComponents";
import QuestionsList from "../components/SurveyQuestions/QuestionsList";
import { IsMultiSelectContext } from "./SurveyEdit";
import { TranslationUploadCard } from "./TranslationUploadCard";
import { FileUploadCollectiveState } from "../../Xlsx/util";
import { LocaleCodes } from "hyphen-lib/dist/util/locale";
import { Trans } from "react-i18next";

interface OwnProps {
  readonly surveyId: string;
  readonly errors: WrongEntityException.Errors;
  readonly questions: SurveyQuestionResource[];
  readonly surveyBankQuestions: SurveyBankQuestionResource[];
  readonly questionsPage: QuestionPageStateProps;
  readonly postCategories: PostCategoryResource[];
  readonly surveyStatus: Survey.Status;
  readonly disabled?: boolean;
  readonly fileUploadState: FileUploadCollectiveState;
  readonly configuredSurveyLanguages: string[];
  readonly isMultiLanguageSurveyEnabled: boolean;
  readonly surveyTranslatedLanguages?: string[];
  readonly translationFileId?: string;
  readonly updatedQuestion?: SurveyQuestionResource;

  readonly onToggleQuestionsModalState: (
    isModalOpen: boolean,
    modalPurpose?: MODALS | undefined
  ) => void;
  readonly onReorderQuestions: (questionIds: string[]) => any;
  readonly onAddQuestion: (
    question: SurveyQuestionResource | SurveyBankQuestionPayload,
    queryParams?: Record<string, string>,
    keepModalOpen?: boolean
  ) => void;
  readonly onUpdateQuestion: (
    questionId: string,
    question: SurveyQuestionResource,
    queryParams?: Record<string, string>,
    keepModalOpen?: boolean
  ) => void;
  readonly onRemoveQuestion: (questionId: string) => void;
  readonly onAddCustomCategory: (category: any) => void;
  readonly addQuestionErrors: (errorPayload: {
    error: WrongEntityException[];
  }) => void;
  readonly onTranslationCSVUpload: (
    file: File,
    surveyId: string,
    language: LocaleCodes
  ) => void;
}

export interface StateProps {
  readonly questionConfig: QuestionConfig;
}

type Props = {
  dispatch?: Dispatch;
} & OwnProps &
  StateProps;

export enum MODALS {
  editQuestion = "Edit question",
  addQuestion = "Add a question",
  addBankQuestion = "Add a question from the bank",
  addCustomQuestion = "Add a custom question",
  removeConfirmation = "Are you sure?",
}

export enum QUESTION_ORIGIN {
  custom = "Custom",
  template = "Template",
  bankQuestion = "Bank Question",
}

export type Question =
  | SurveyQuestionResource.MultipleChoice
  | SurveyQuestionResource.OpenText;

class SurveyQuestions extends React.Component<Props> {

  state = {
    selectedQuestion: {} as SurveyQuestionResource,
    isTemplate: false,
    modalPurpose: MODALS.editQuestion,
    selectedIndex: 0,
  };


  componentDidUpdate(prevProps: Props) {
    const selQuestion = this.props.questions.filter(question => question._id === this.state.selectedQuestion._id)[0];
    if(isNotNullNorUndefined(selQuestion)) {
      if(this.state.selectedQuestion !== selQuestion) {
        this.setState({
          selectedQuestion: selQuestion
        });
      }
    }
  }

  openModal = (modalPurpose: MODALS) => {
    this.props.onToggleQuestionsModalState(true, modalPurpose);
  };

  onModalClose = () => {
    this.setState({ isTemplate: false, selectedQuestion: {} });
    this.props.onToggleQuestionsModalState(false);
  };

  onActionClick(
    isTemplate: boolean,
    selectedQuestion: Question,
    action: string
  ) {
    const index = this.getQuestionIndex(selectedQuestion);
    this.setState({ selectedQuestion, isTemplate, selectedIndex: index });
    if (action === "edit") {
      this.openModal(MODALS.editQuestion);
    } else {
      this.openModal(MODALS.removeConfirmation);
    }
  }

  onReorderQuestion(
    isTemplate: boolean,
    selectedQuestionId: string,
    fromIndex: number,
    moveToIndex: number
  ) {
    const { questions, onReorderQuestions } = this.props;
    let templateQuestionsIds: string[] = [];
    let customQuestionsIds: string[] = [];
    if (isNotEmptyArray(questions)) {
      questions.forEach((question) => {
        if (isNotNullNorUndefined(question.surveyTemplatePostId)) {
          templateQuestionsIds.push(question._id);
        } else {
          customQuestionsIds.push(question._id);
        }
      });
    }

    if (isTemplate) {
      templateQuestionsIds = this.fnSwapArray(
        templateQuestionsIds,
        selectedQuestionId,
        fromIndex,
        moveToIndex
      );
    } else {
      customQuestionsIds = this.fnSwapArray(
        customQuestionsIds,
        selectedQuestionId,
        fromIndex,
        moveToIndex
      );
    }
    const postTemplateIds = [...templateQuestionsIds, ...customQuestionsIds];

    // notify new order of questions
    onReorderQuestions(postTemplateIds);
  }

  fnSwapArray = (
    array: string[],
    questionId: string,
    from: number,
    to: number
  ): string[] => {
    const swapArray: string[] = [...array];
    swapArray.splice(from, 1);
    swapArray.splice(to, 0, questionId);
    return swapArray;
  };

  onDelete = () => {
    const { onRemoveQuestion } = this.props;
    const { selectedQuestion } = this.state;
    onRemoveQuestion(selectedQuestion._id);
  };

  getQuestionIndex = (question: SurveyQuestionResource) => {
    const { questions } = this.props;
    let questionIndex = 0;

    questions.forEach((item, index) => {
      if (item._id === question._id) {
        questionIndex = index;
      }
    });
    return questionIndex;
  };

  addUpdateQuestion(
    add: boolean,
    questionId: string,
    question: SurveyQuestionResource,
    queryParams?: Record<string, string>,
    keepModalOpen?: boolean
  ) {
    const { onUpdateQuestion, onAddQuestion } = this.props;

    if (add) {
      onAddQuestion(question, queryParams, keepModalOpen);
    } else {
      onUpdateQuestion(questionId, question, queryParams, keepModalOpen);
    }
  }

  getModalContent = (): React.ReactNode => {
    const { selectedQuestion, isTemplate } = this.state;
    const {
      surveyBankQuestions,
      questionsPage,
      postCategories,
      surveyId,
      errors,
      onAddCustomCategory,
      surveyStatus,
      addQuestionErrors,
      questionConfig,
    } = this.props;
    const { bankQuestionId, recurringQuestionId } = selectedQuestion;

    let questionOrigin = QUESTION_ORIGIN.custom;
    if (isTemplate) {
      questionOrigin = QUESTION_ORIGIN.template;
    } else if (
      isNotNullNorUndefined(bankQuestionId) ||
      isNotNullNorUndefined(recurringQuestionId)
    ) {
      questionOrigin = QUESTION_ORIGIN.bankQuestion;
    }
    const { modalPurpose, isRequestingUpdate } = questionsPage;


    switch (modalPurpose) {
      case MODALS.editQuestion:
        return (
          <AddOrEditQuestionModal
            surveyId={surveyId}
            errors={errors}
            postCategories={postCategories}
            questionSource={selectedQuestion}
            questionOrigin={questionOrigin}
            addUpdateQuestion={this.addUpdateQuestion.bind(this, false)}
            onlyUpdateQuestion={this.addUpdateQuestion.bind(this, false)}
            buttonDisabled={isRequestingUpdate}
            onAddCustomCategory={onAddCustomCategory}
            surveyStatus={surveyStatus}
            addQuestionErrors={addQuestionErrors}
            questionConfig={questionConfig}
            configuredSurveyLanguages={getOr(this.props.configuredSurveyLanguages,[])}
            translatedSurveyLanguages={getOr(this.props.surveyTranslatedLanguages, [])}
            isMultiLanguageSurveyEnabled={this.props.isMultiLanguageSurveyEnabled}
            isUpdatingQuestion={this.props.questionsPage.isRequestingUpdate}
          />
        );
      case MODALS.addQuestion:
        return (
          <AddQuestionModal
            onQuestionBankClick={this.openModal.bind(
              null,
              MODALS.addBankQuestion
            )}
            onCustomClick={this.openModal.bind(null, MODALS.addCustomQuestion)}
          />
        );
      case MODALS.addCustomQuestion:
        return (
          <AddOrEditQuestionModal
            surveyId={surveyId}
            errors={errors}
            postCategories={postCategories}
            questionOrigin={questionOrigin}
            addUpdateQuestion={this.addUpdateQuestion.bind(this, true)}
            onlyUpdateQuestion={this.addUpdateQuestion.bind(this, false)}
            onAddCustomCategory={onAddCustomCategory}
            buttonDisabled={isRequestingUpdate}
            surveyStatus={surveyStatus}
            addQuestionErrors={addQuestionErrors}
            questionConfig={questionConfig}
            configuredSurveyLanguages={getOr(this.props.configuredSurveyLanguages,[])}
            translatedSurveyLanguages={getOr(this.props.surveyTranslatedLanguages, [])}
            isMultiLanguageSurveyEnabled={this.props.isMultiLanguageSurveyEnabled}
            isUpdatingQuestion={this.props.questionsPage.isRequestingUpdate}
            updatedQuestion={this.props.updatedQuestion}
          />
        );
      case MODALS.addBankQuestion:
        return (
          <IsMultiSelectContext.Consumer>
            {(isMultiSelectQuestionEnabled) => (
              <QuestionBankModal
                postCategories={postCategories}
                surveyBankQuestions={surveyBankQuestions}
                onAddQuestion={this.props.onAddQuestion}
                buttonDisabled={isRequestingUpdate}
                isMultiSelectQuestionEnabled={isMultiSelectQuestionEnabled}
                questionConfig={questionConfig}
                configuredSurveyLanguages={getOr(this.props.configuredSurveyLanguages,[])}
                isMultiLanguageSurveyEnabled={this.props.isMultiLanguageSurveyEnabled}
                enableTranslation={true}
                isUpdatingQuestion={this.props.questionsPage.isRequestingUpdate}
                updatedQuestion={this.props.updatedQuestion}
                addUpdateQuestion={this.addUpdateQuestion.bind(this, false)}
              />
            )}
          </IsMultiSelectContext.Consumer>
        );
      case MODALS.removeConfirmation:
        return (
          <RemoveModalContainer>
            <Paragraph>
              <Trans>You are about to delete</Trans>{" "}
              <strong>{selectedQuestion.question}</strong>
            </Paragraph>
            <DeleteActionsContainer>
              <Button
                color="grey"
                disabled={isRequestingUpdate}
                onClick={this.onModalClose}
                translate="yes"
              >
                Cancel
              </Button>
              <Button
                color="red"
                onClick={this.onDelete}
                disabled={isRequestingUpdate}
                translate="yes"
              >
                {isRequestingUpdate ? "Deleting..." : "Delete"}
              </Button>
            </DeleteActionsContainer>
          </RemoveModalContainer>
        );
      default:
        return null;
    }
  };

  matchTemplateId(
    shouldTemplateIdExist: boolean,
    question: SurveyQuestionResource
  ) {
    return shouldTemplateIdExist
      ? isNotNullNorUndefined(question.surveyTemplatePostId)
      : isNullOrUndefined(question.surveyTemplatePostId);
  }

  render() {
    const { questions, questionsPage, disabled, questionConfig } = this.props;
    const { isModalOpen, modalPurpose } = questionsPage;
    const templateQuestions = questions.filter(
      this.matchTemplateId.bind(this, true)
    );
    const customQuestions = questions.filter(
      this.matchTemplateId.bind(this, false)
    );

    return (
      <>
        <Spin
          size="large"
          className="sticky-top"
          spinning={this.props.fileUploadState.isUploadingTemplate}
          tip="Uploading the translations"
        >
          {isNotEmptyArray(templateQuestions) && (
            <TemplateQuestions
              title="Template questions"
              description="These questions are part of the template and cannot be edited."
            >
              <QuestionsList
                data={templateQuestions}
                onReorderQuestion={this.onReorderQuestion.bind(this, true)}
                onActionClick={this.onActionClick.bind(this, true)}
                disabled={disabled}
                questionConfig={questionConfig}
                configuredSurveyLanguages={this.props.configuredSurveyLanguages}
                isMultiLanguageSurveyEnabled={this.props.isMultiLanguageSurveyEnabled}
              />
            </TemplateQuestions>
          )}
          <CustomQuestions
            title="Custom questions"
            description={
              isNotEmptyArray(templateQuestions)
                ? "These questions will be included in the survey after the template questions."
                : "You must add at least one question to launch a survey."
            }
          >
            <QuestionsList
              onReorderQuestion={this.onReorderQuestion.bind(this, false)}
              data={customQuestions}
              onActionClick={this.onActionClick.bind(this, false)}
              disabled={disabled}
              questionConfig={questionConfig}
              configuredSurveyLanguages={this.props.configuredSurveyLanguages}
              isMultiLanguageSurveyEnabled={this.props.isMultiLanguageSurveyEnabled}
            />
            <div>
              <Button
                onClick={this.openModal.bind(null, MODALS.addQuestion)}
                color="grey"
                disabled={disabled}
                data-cy="surveyCreation_questions_addQuestion"
                translate="yes"
              >
                Add a question
              </Button>
            </div>
          </CustomQuestions>

          {this.props.isMultiLanguageSurveyEnabled && (
            <TranslationUploadCard
              surveyId={this.props.surveyId}
              onTranslationCSVUpload={this.props.onTranslationCSVUpload}
              fileUploadState={this.props.fileUploadState}
              configuredSurveyLanguages={this.props.configuredSurveyLanguages}
              surveyTranslatedLanguages={this.props.surveyTranslatedLanguages}
              translationFileId={this.props.translationFileId}
            />
          )}
          {isModalOpen && (
            <QuestionModal
              visible={isModalOpen}
              footer={false}
              title={<Trans>{modalPurpose}</Trans>}
              onCancel={this.onModalClose}
              destroyOnClose={true}
            >
              {this.getModalContent()}
            </QuestionModal>
          )}
        </Spin>
      </>
    );
  }
}

const CustomQuestions = styled(ContainerCard)`
  margin-top: 32px;
  padding: 0;
  > div:first-child {
    padding: 32px 32px 8px 32px;
  }

  > div:last-child {
    padding: 32px;
  }
`;

const TemplateQuestions = styled(ContainerCard)`
  padding: 0;
  > div:first-child {
    padding: 32px 32px 8px 32px;
  }
`;

const QuestionModal = styled(Modal)<ModalProps & { children: React.ReactNode }>`
  .ant-modal-content {
    padding-bottom: 0 !important;
  }
`;

const DeleteActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;

  & > * {
    margin-left: 8px;
  }
`;

const RemoveModalContainer = styled.div`
  ${modalContainerStyle}
`;

export default SurveyQuestions;
