import React from "react";
import { FiltersContent } from "@screens/Insights/components/FiltersContent";
import { applyDefault } from "@src/utils/Currier";
import {
  HasInsightAccessFilter,
  RoleFilter,
  UserStatusFilter
} from "@screens/Insights/components/Filters/business/UserFilters";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
import { generateDefinitionsForDimensions } from "@screens/Insights/components/Filters/business/DimensionFilters";
import { State } from "@store/types";
import { getExistingCount } from "@store/network/selectors";
import { connect } from "react-redux";
{/* eslint-disable-next-line max-len */}
import { userListFiltersActionCreators } from "@screens/Insights/UserManagement/containers/UserListFilterContainer/store/actions";
import { UserResource } from "hyphen-lib/dist/domain/resource/user/UserResource";
import { getOr } from "hyphen-lib/dist/lang/Objects";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { fetchUsersCountIfNeeded } from "@store/network/resource/UserResources";
import { AddDimensionFilter } from "@src/screens/Insights/components/Filters/business/AddDimensionFilter";
import { getFilter } from "./store/selectors";

export interface UserListFilterContainerStateProps {
  readonly innerValues: Dictionary<any>;
  readonly count: number;
  readonly countLoading: boolean;
}

export interface UserListFilterContainerActionProps {
  readonly onFetchCountIfNeeded: (filters: any) => any;
  readonly onModifyFilter: (filters: any) => any;
  readonly onCleanFilter: () => any;
}

export interface UserListFilterContainerOwnProps {
  readonly roles: string[];
  readonly dimensions: Dimensions;
  readonly values?: Dictionary<any>;
  readonly onApply: (filters: any) => any;
}

export type UserListFilterContainerProps = UserListFilterContainerOwnProps &
UserListFilterContainerStateProps &
UserListFilterContainerActionProps;

export class UserListFilter extends React.Component<UserListFilterContainerProps> {
  componentDidMount() {
    /*
        Modify filters with initial values while mounting.
     */
    this.props.onModifyFilter(getOr(this.props.values, {}));
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.countLoading) {
      /*
        Count is not loaded, maybe we might want to fetch it.
      */
      this.props.onFetchCountIfNeeded(this.props.innerValues);
    }
  }

  componentWillUnmount(): void {
    this.props.onCleanFilter();
  }

  render() {
    const {
      roles,
      dimensions,
      values,
      count,
      countLoading,
      onApply,
      onModifyFilter,
    } = this.props;

    return (
      <FiltersContent
        filters={[
          {
            key: "roles",
            label: "Role",
            component: applyDefault(RoleFilter, { roles }),
          },
          {
            key: "statuses",
            label: "Status",
            component: UserStatusFilter,
          },
          {
            key: "hasInsightsAccess",
            label: "Engage Insights access",
            component: HasInsightAccessFilter,
          },
          ...generateDefinitionsForDimensions(dimensions),
        ]}
        customFilters={[{
          key: "addDimension",
          label: "Add a Dimension",
          component: applyDefault(AddDimensionFilter, {
            dimensions,
          }),
        }]}
        displayCount={true}
        count={count}
        countLoading={countLoading}
        onChange={onModifyFilter}
        onApply={onApply}
        values={values}
      />
    );
  }
}

function mapStateToProps(state: State, ownProps: UserListFilterContainerOwnProps): UserListFilterContainerStateProps {
  const innerFilterValues = getFilter(state);

  /*
      When loading the component for the first time, the inner values are empty,
      so active filters are the one from the props.
   */
  const activeValues = getOr(innerFilterValues, getOr(ownProps.values, {}));
  /*
      Try to get the count based on active filter
   */
  const key = UserResource.generateKey(activeValues);
  const count = getExistingCount(state, UserResource.TYPE, key);

  return {
    innerValues: getOr(innerFilterValues, {}),
    count: Store.Count.isLoaded(count) ? count.count : 0,
    countLoading: not(Store.Count.isLoaded(count)),
  };
}

const mapDispatchToProps = {
  onFetchCountIfNeeded: fetchUsersCountIfNeeded,
  onModifyFilter: userListFiltersActionCreators.modifyFilter,
  onCleanFilter: userListFiltersActionCreators.cleanFilter,
};

export const UserListFilterContainer = connect(mapStateToProps, mapDispatchToProps)(UserListFilter);
