import React, { ReactNode, useEffect, useState } from "react";
import Modal from "@src/components/core/Modal";
import { ModalProps } from "antd/lib/modal";
import styled from "styled-components";
import Button from "@components/core/Button";
import { Trans } from "react-i18next";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";

interface eNPSOrFavorabilityInfoModalProps {
  visible: boolean;
  title: string;
  description: string | ReactNode;
  des?:string | ReactNode;
  note?: string | ReactNode; 
  onClose: () => void;
}

export default function FavorabilityOreNPSInfoModal(
  props: eNPSOrFavorabilityInfoModalProps
) {
  const [visible, isVisible] = useState(false);
  useEffect(() => {
    isVisible(props.visible);
  }, [props.visible]);
  return (
    <div data-cy="enps-or-favorability-info-modal">
      <StyledModal
        visible={visible}
        title={
          <span data-cy="enps-or-favorability-info-header">
            <Trans>{props.title}</Trans>
          </span>
        }
        footer={null}
        onCancel={() => {
          isVisible(false);
          props.onClose();
        }}
      >
        <ModalContent>
          <ContentWrapper>
            <Trans> {props.description} </Trans>
          </ContentWrapper>
        </ModalContent>
         
         { isNotNullNorUndefined(props.note) &&
        <ModalContent>
    
          <strong><Trans>Note</Trans> : </strong> 

           <Trans>{props.note} </Trans>
        </ModalContent>
        }

        <ActionContainer>
          <StyledButton
            color="blue"
            key="close"
            data-cy="close"
            onClick={() => {
              isVisible(false);
              props.onClose();
            }}
            translate="yes"
          >
            Close
          </StyledButton>
        </ActionContainer>
      </StyledModal>
    </div>
  );
}

const StyledModal = styled(Modal)<ModalProps & { children: React.ReactNode }>`
  .ant-modal-body {
    max-height: none !important;
    overflow-y: unset !important;
  }
`;

const ModalContent = styled.div`
  margin-top: 10px;
  padding-left: 32px !important;
  padding-right: 32px !important;
`;

const StyledButton = styled(Button)`
  min-width: 150px;
`;

const ActionContainer = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-around;
  padding-left: 32px !important;
  padding-right: 32px !important;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;