import { default as networkActionCreators } from "@store/network/actions";
import { createRequest } from "@src/utils/networks";
import { appendQueryString, generateQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { CommentsResultResource } from "hyphen-lib/dist/domain/resource/report/CommentsResultResource";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";

export function fetchCommentResultsIfNeeded(
  surveyId: string,
  questionId: Optional<string>,
  queryString: Dictionary<any>) {             
  return networkActionCreators.fetchPageIfNeeded({
    id: CommentsResultResource.generateKey({ surveyId, questionId }, queryString.filter),
    type: CommentsResultResource.TYPE,
    page: PageFilter.noPagination(),
    rawPageSize: PageFilter.RAW_PAGE_SIZE_WITH_NO_PAGINATION,
    request: () => createRequest(
      appendQueryString(
        `/surveys/${surveyId}/reports/comments`,
        generateQueryString(queryString)
      )
    ),
  });
}
export function fetchPollCommentResultsIfNeeded(pollId: string,
  questionId: Optional<string>,
  queryParams: Dictionary<any>) {
  
  return networkActionCreators.fetchPageIfNeeded({
    id: CommentsResultResource.generateKey({ pollId, questionId }, queryParams.filter),
    type: CommentsResultResource.TYPE,
    page: PageFilter.noPagination(),
    rawPageSize: PageFilter.RAW_PAGE_SIZE_WITH_NO_PAGINATION,
    request: () => createRequest(
      appendQueryString(
        `/pulsepolls/${pollId}/reports/comments`,
        generateQueryString(queryParams)
      )
    ),
  });
}
