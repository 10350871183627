import React from "react";
import styled from "styled-components";
import moment from "moment";
import { 
  getOr, 
  isNotEmptyObject, 
  isEmptyObject, 
  isNotNullNorUndefined,
  getProperty, 
} from "hyphen-lib/dist/lang/Objects";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { ActionResource } from "hyphen-lib/dist/domain/resource/action/ActionResource";
import { isActionOverdue } from "hyphen-lib/dist/business/action/Actions";
import { Heading } from "@components/core/Typography";
import { FilterParameter, SortParameter } from "@src/utils/networks";
import { Trans } from "react-i18next";
import { PaginationConfig } from "antd/lib/pagination";
import { SorterResult, TableProps, ColumnProps } from "antd/lib/table";
import { Icon } from "antd";
import { useTranslation } from "react-i18next";
import Palette from "@src/config/theme/palette";
import Table from "@src/components/core/Table";
import Tooltip from "@components/core/Tooltip";
import { DynamicRowProps, withDynamicRows } from "@components/core/DynamicRowsTable/withDynamicRows";
import { ActionListPageParameters } from "@screens/Insights/Actions/store/actions";

import { StatusCell } from "@screens/Insights/Actions/components/ActionListTable/cells/StatusCell";
import { AssigneeCell } from "@screens/Insights/Actions/components/ActionListTable/cells/AssigneeCell";
import { ActionCell, ActionKeys } from "@screens/Insights/Actions/components/ActionListTable/cells/ActionCell";
import { RightsMatcher } from "@hyphen-lib/business/auth/Auth";
import { FocusAreaCell } from "./cells/FocusAreaCell";
import { SourceCell } from "./cells/SourceCell";
import { translate } from "@src/utils/i18next";

const ColumnStyledHeader = styled(Heading)<{ minWidth: number }>`
  color: ${Palette.darkBlueGrey};
  min-width: ${props => `${props.minWidth}px`};
`;

export interface ActionListTableProps extends TableProps<ActionResource> {
  readonly actions: ActionResource[];
  readonly onModifyList: (parameters: ActionListPageParameters) => any;
  readonly page: PageFilter;
  readonly sort?: SortParameter;
  readonly filter?: FilterParameter;
  readonly total: number;
  readonly loading: boolean;
  readonly currentUserEmail: string;
  readonly rightsMatcher: RightsMatcher;
}

interface OwnProps {
  readonly onActionClick: (key: ActionKeys, data: ActionResource) => void;
}

function getSortOrder(field: string, sort?: SortParameter) {
  if (isEmptyObject(sort)) {
    return undefined;
  }
  if (sort![field] === 1) {
    return "ascend";
  } else if (sort![field] === -1) {
    return "descend";
  }
  return undefined;
}

export function ActionListTable({
  actions,
  currentUserEmail,
  filter,
  onModifyList,
  page,
  sort,
  total,
  ...rest
}: ActionListTableProps) {
  const { t } = useTranslation();
  const COLUMNS: ColumnProps<ActionResource>[] = [
    {
      title: (
        <ColumnStyledHeader minWidth={300} size="small" translate="yes">
          Action
          <Tooltip
            title={<Trans>The action description</Trans>}>
            <HelpIcon type="info-circle" />
          </Tooltip>
        </ColumnStyledHeader>),
      dataIndex: "action",
      key: "action",
      width: 60,
      sorter: true,
      sortOrder: getSortOrder("action", sort),
      sortDirections: ["ascend","descend"],
    },
    { 
      title: (
        <ColumnStyledHeader minWidth={180} size="small" translate="yes">
          Source
        </ColumnStyledHeader>),
      dataIndex: "source",
      key: "source",
      width: 60,
      sorter: true,
      sortOrder: getSortOrder("source", sort),
      sortDirections: ["ascend","descend"],
      render: (__, record) => {
        if (isNotNullNorUndefined(getProperty(record, "focusArea", undefined))) {
          return <FocusAreaCell row={record} />;
        } else {
          return (
            <SourceCell source={record.source.label} info={record.source.type} />
          );
        }
      },
    },
    {
      title: (
        <ColumnStyledHeader minWidth={200} size="small" translate="yes">
          Assignee
        </ColumnStyledHeader>),
      dataIndex: "assignee",
      key: "assignee",
      width: 50,
      sorter: true,
      sortOrder: getSortOrder("assignee", sort),
      sortDirections: ["ascend", "descend"],
      render: (__, record) => <AssigneeCell assigneeEmail={record.assignee} currentUserEmail={currentUserEmail} />,
    },
    {
      title: (
        <ColumnStyledHeader minWidth={180} size="small" translate="yes">
          Status
        </ColumnStyledHeader>),
      dataIndex: "status",
      key: "status",
      width: 40,
      sorter: true,
      sortOrder: getSortOrder("status", sort),
      sortDirections: ["ascend", "descend"],
      render: (__, record: ActionResource, index: number) => {
        if (record.status !== "completed" && record.status !== "dismissed") {
          const actionResource = {
            ...record,
            dueDate: new Date(record.dueDate),
          };
          const overdueStatus = isActionOverdue(actionResource, new Date());
          const dueDate = moment(record.dueDate);
          return (
            <StatusCell
              row={record}
              info={`${translate(t, "due on")} ${dueDate.format("MM/DD/YYYY")}`}
              danger={overdueStatus}
              index={index}
            />
          );
        }
        return (
          <StatusCell row={record} index={index} />
        );
      },
    },
  ];

  /* tslint:disable:jsx-no-lambda */
  return (
    <Table
      tableLayout="auto"
      scroll={{ x: "scroll" }}
      columns={COLUMNS}
      data-cy="actionPlan_table"
      dataSource={actions}
      rowKey={"_id"}
      pagination={{
        total,
        pageSize: page.size,
        current: page.number,
        showTotal: (totalDocuments: number, range: number[]) =>
          <Trans i18nKey="actionListCount"
            values={{start: range[0], end: range[1], total: totalDocuments}} 
            defaults={`Showing ${range[0]} to ${range[1]} of ${totalDocuments} action plans`}/>,
      }}

      onChange={(pagination: PaginationConfig, __: any, sorter: SorterResult<ActionResource>) => {
        const pageParam = {
          size: getOr(pagination.pageSize, page.size),
          number: getOr(pagination.current, 1),
        };
        const sortParam: SortParameter = {};
        if (isNotEmptyObject(sorter) && isNotNullNorUndefined(sorter.order)) {
          sortParam[sorter.columnKey] = sorter.order === "ascend" ? 1 : -1;
        }
        onModifyList({
          filter,
          page: pageParam,
          sort: sortParam,
        });
      }
      }
      {...rest}
    />
  );
}

const HelpIcon = styled(Icon)`
  margin-left: 8px;
  cursor: pointer !important;
  svg {
    fill: ${Palette.bluishGrey};
  }
`;

const LastHoverCellContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledHoverCell = styled.td`
// to ensure the color stay, even when we are not on hover anymore, for example on "More" menu
  background-color: ${Palette.lightLightBlue};
`;

export function ActionPlansTable(props: ActionListTableProps & OwnProps) {

  function HoverRow({ row, index}: DynamicRowProps<ActionResource>) {
    return (
      <>
        <StyledHoverCell>
          {row.action}
        </StyledHoverCell>

        <StyledHoverCell>
          <FocusAreaCell row={row} />
        </StyledHoverCell>

        <StyledHoverCell colSpan={3}>
          <LastHoverCellContainer>
            <ActionCell
              onClick={(key) => props.onActionClick(key, row)}
              row={row}
              rightsMatcher={props.rightsMatcher}
              index={index}
            />
          </LastHoverCellContainer>
        </StyledHoverCell>
      </>
    );
  }

  const TailoredTable = withDynamicRows(ActionListTable, HoverRow);

  return <TailoredTable
    {...props}
  />;
}
