import React from "react";
import styled from "styled-components";
import { Layout as AntLayout } from "antd";

import { FetchError } from "@screens/Insights/errors/FetchError";
import CommentsSearchBar from "@screens/Insights/Survey/components/Comments/components/CommentsSearchBar";
import SendPrivateMessageModal from "@screens/Insights/components/Reports/SendPrivateMessage";
import CommentsTable from "@screens/Insights/components/Reports/ResultsTable";

import { Store } from "hyphen-lib/dist/util/store/Store";
import { CommentsResultResource } from "hyphen-lib/dist/domain/resource/report/CommentsResultResource";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";

interface CommentsProps {
  readonly comments: Store.Page<CommentsResultResource>;
  readonly canSendPrivateMessage: boolean;
  readonly isPrivateMessageModalVisible: boolean;
  readonly isNetworkRequesting: boolean;
  readonly commentText: string;
  readonly privateMessageText: string;
  readonly errorText: string;
  readonly hasActionCreationRight: boolean;
  readonly focusAreas?: FocusAreaResource[];
  readonly focusAreaLabel: string;
  readonly page: PageFilter;
}

interface CommentsActionProps {
  readonly clearPrivateMessageModal: () => any;
  readonly sendPrivateMessage: () => void;
  readonly togglePrivateMessageModal: (
    resourceId: string,
    postId: string,
    voteId: string,
    commentText: string,
    origin: string,
    source?: CommentsResultResource["source"]
  ) => void;
  readonly updatePrivateMessageText: (privateMessageText: string) => void;
  readonly onCreateFocusArea: (focusArea: Partial<FocusAreaResource>) => void;
}

export type Props = CommentsProps & CommentsActionProps;

export class Comments extends React.Component<Props> {
  render() {
    const {
      comments,
      hasActionCreationRight,

      isPrivateMessageModalVisible,
      canSendPrivateMessage,
      isNetworkRequesting,
      privateMessageText,
      errorText,
      commentText,
      togglePrivateMessageModal,
      updatePrivateMessageText,
      sendPrivateMessage,
      clearPrivateMessageModal,
      focusAreas,
      onCreateFocusArea,
      focusAreaLabel,
      page
    } = this.props;

    if (Store.Page.isInError(comments)) {
      return <FetchError {...comments} resourceType={CommentsResultResource.TYPE}/>;
    }

    return (
      <>
        <Layout>
          <CommentsSearchBar/>
          <CommentsTable
            comments={Store.Page.toList(comments)}
            loading={Store.Page.isLoading(comments)}
            isPrivateMessageModalOpen={isPrivateMessageModalVisible}
            privateMessageText={privateMessageText}
            sendPrivateMessage={sendPrivateMessage}
            togglePrivateMessageModal={togglePrivateMessageModal}
            updatePrivateMessageText={updatePrivateMessageText}
            canSendPrivateMessage={canSendPrivateMessage}
            hasActionCreationRight={hasActionCreationRight}
            focusAreas={focusAreas}
            onCreateFocusArea={onCreateFocusArea}
            focusAreaLabel={focusAreaLabel}
            page={page}
          />
          <SendPrivateMessageModal
            clearPrivateMessageModal={clearPrivateMessageModal}
            comment={commentText}
            errorText={errorText}
            isSending={isNetworkRequesting}
            onCancel={togglePrivateMessageModal}
            privateMessageText={privateMessageText}
            updatePrivateMessageText={updatePrivateMessageText}
            visible={isPrivateMessageModalVisible}
            onOk={sendPrivateMessage}
          />
        </Layout>
      </>
    );
  }
}

const Layout = styled(AntLayout)`
  background: white !important;
  margin-top: 24px;
  padding-bottom: 12px;
  font-family: "Lato";
`;
