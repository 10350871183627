import { applyDefault } from "@src/utils/Currier";
import { MonoSelectFilter } from "@screens/Insights/components/Filters/base/MonoSelectFilter";
import { MultiSelectFilter } from "@screens/Insights/components/Filters/base/MultiSelectFilter";
import { SurveyTypeResource } from "hyphen-lib/dist/domain/resource/SurveyTypeResource";
import { BaseFilterProps } from "@screens/Insights/components/FiltersContent";
import { QuestionType } from "@hyphen-lib/domain/common/QuestionType";
import { Sentiment } from "hyphen-lib/dist/domain/common/ComputationTypes";
import { getOr } from "hyphen-lib/dist/lang/Objects";

export const SurveyStatusFilter =
  applyDefault(
    MultiSelectFilter,
    {
      trans: true,
      availableValues: [
        { value: "draft", label: "Draft"},
        { value: "launched", label: "In Progress"},
        { value: "closed", label: "Closed"},
      ],
    }
  );

export interface SurveyTypeFilterProps extends BaseFilterProps<string[]> {
  types: SurveyTypeResource[];
}

export function SurveyTypeFilter({ label, types, ...rest }: SurveyTypeFilterProps) {
  return (
    <MultiSelectFilter
      label={label}
      availableValues={types.map(typeResource => ({
        value: typeResource._id,
        label: typeResource.label,
      }))}
      {...rest}
    />
  );
}

export const QuestionTypeFilter =
  applyDefault(
    MultiSelectFilter,
    {
      availableValues: [
        { value: QuestionType.LIKERT, label: "Likert" },
        { value: QuestionType.MULTIPLE_CHOICE, label: "Multiple Choice" },
        { value: QuestionType.OPEN_TEXT, label: "Open" },
      ],
    }
  );

export interface CategoryFilterProps extends BaseFilterProps<string[]> {
  categories: string[];
  loading: boolean;
}

export function CategoryFilter({ label, categories, ...rest }: CategoryFilterProps) {
  return (
    <MultiSelectFilter
      data-cy="survey-category-textbox"
      label={label}
      availableValues={categories.map(category => ({
        value: category,
        label: category,
      }))}
      {...rest}
    />
  );
}

export interface CategoryFilterMonoSelectProps extends BaseFilterProps<any> {
  categories: string[];
  loading: boolean;
}

export function CategoryFilterMonoSelect({ label, categories, ...rest }: CategoryFilterMonoSelectProps) {

  const filterAvailableCategories = (val: any) => {
    let defaultValue = null;
    if(val) {
      if(categories.indexOf(val) === -1){
        rest.onChange(defaultValue);
      } else {
        defaultValue = val;
      }
    } 
    return defaultValue;
  };

  return (<MonoSelectFilter
    label={label}
    availableValues={categories.map(category => ({
      value: category,
      label: category,
    }))}
    allowClear={true}
    showSearch={true}
    mapperOut={(val) => getOr(val, null)}
    mapperIn={(val) => filterAvailableCategories(val)}
    {...rest}
  />);
}

export interface TopicFilterProps extends BaseFilterProps<string[]> {
  topics: string[];
}

export function TopicFilter({ label, topics, ...rest }: TopicFilterProps) {
  return (
    <MultiSelectFilter
      data-cy="survey-topic-textbox"
      label={label}
      availableValues={topics.map(topic => ({
        value: topic,
        label: topic,
      }))}
      {...rest}
    />
  );
}

export const SentimentFilter =
  applyDefault(
    MultiSelectFilter,
    {
      trans: true,
      availableValues: [
        { value: Sentiment.POSITIVE, label: "Positive"},
        { value: Sentiment.NEUTRAL, label: "Neutral"},
        { value: Sentiment.NEGATIVE, label: "Negative"},
      ],
    }
  );
