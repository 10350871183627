import React from "react";
import { Trans } from "react-i18next";
import { Table } from "antd";
import { TableProps } from "antd/lib/table/interface";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import NoResult from "@src/components/core/NoResult";

interface Props {
  questions: {
    category: string;
    question: string;
  }[];
}

const PreviewModalContent = (props: Props) => {
  const { questions } = props;

  const columns = [{
    title: <Trans>Category</Trans>,
    dataIndex: "category",
    key: "category",
  }, {
    title: <Trans>Question</Trans>,
    dataIndex: "question",
    key: "question",
  }];
  
  return (
    // eslint-disable-next-line max-len
    <QuestionsTable data-jest="questions-table" pagination={false} columns={columns} dataSource={questions} rowKey="question" 
    locale={{
      emptyText: (<NoResult type="minimal" description="No Data" />),
    }}/>
  );
};

const QuestionsTable = styled(Table)<TableProps<any>>`
  td {
    padding: 20px 32px !important;
  }

  th {
    padding: 0px 32px 20px 32px !important;
    font-weight: bold !important;
    color: ${Palette.darkBlueGrey} !important;
    background-color: white !important;
  }

  tbody {
    max-height: 550px;
    overflow-y: auto;
    display: block;
  }

  tbody > tr > td:first-child {
    color: ${Palette.lightGreyBlue} !important;
  }

  table thead, table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;/* even columns width , fix width of table too*/
  }

  tr:hover > td {
    background: #fff !important;
  }
`;

export default PreviewModalContent;
