import React from "react";
import { Form } from "antd";
import { ModalProps } from "antd/lib/modal";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { FormProps } from "antd/lib/form";
import styled from "styled-components";

import Modal from "@components/core/Modal";
import Button from "@components/core/Button";
import Checkbox from "@components/core/Checkbox";
import { Paragraph } from "@components/core/Typography";
import { NetworkEventSuccessAction } from "@src/store/network/actions";

import { SurveyListContainerActionProps } from "../index";
import { ActionKeys } from "./cells/ActionCell";
import { Trans } from "react-i18next";

interface Props extends ModalProps {
  readonly duplicateSurvey: SurveyListContainerActionProps["duplicateSurvey"];
  readonly onDuplicateSurveySuccess: (payload: NetworkEventSuccessAction["payload"]) => void;
  readonly surveyId: string;
  readonly buttonsDisabled: boolean;
}

export class DuplicateSurveyModal extends React.Component<Props, { linkDuplicate: boolean }> {
  state = {
    linkDuplicate: false,
  };
  onLinkDuplicateChange = (e: CheckboxChangeEvent) => this.setState({ linkDuplicate: e.target.checked });

  onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const { duplicateSurvey, onDuplicateSurveySuccess, surveyId } = this.props;
    const { linkDuplicate } = this.state;
    duplicateSurvey(surveyId, onDuplicateSurveySuccess, linkDuplicate);
  };

  render() {
    const { linkDuplicate } = this.state;
    const { buttonsDisabled } = this.props;
    return (
      <StyledModal
        footer={[
          <Button
            color="blue"
            htmlType="submit"
            data-cy="duplicationButton"
            key={ActionKeys.duplicate}
            onClick={this.onSubmit}
            disabled={buttonsDisabled}
            translate="yes"
          >
            {buttonsDisabled ? "Duplicating..." : "Duplicate"}
          </Button>,
        ]}
        {...this.props}
      >
        <Container onSubmit={this.onSubmit} >
          <Info>
            <Trans>Selecting this option will link your duplicated survey to the existing 
            survey you are duplicating from. This will allow you to compare the data from 
            this survey to the previous iteration in Survey Reports.</Trans>
          </Info>
          <Checkbox
            checked={linkDuplicate}
            onChange={this.onLinkDuplicateChange}
          >
            <Trans>Link to this survey</Trans>
          </Checkbox>
        </Container>
      </StyledModal>
    );
  }
}

const Container = styled(Form)<FormProps>`
  padding: 0 32px 24px 32px !important;
`;

const Info = styled(Paragraph)`
  margin-bottom: 24px !important;
`;

const StyledModal = styled(Modal)<ModalProps & {children: React.ReactNode}>``;
