// @ts-nocheck
import React from "react";
export function PulsesSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15"
      viewBox="0 0 16 15"
    >
      <defs>
        {/* eslint-disable max-len */}
        <path id="pulseA" d="M13.7 5.033c-.315.063-.538.446-.468.796a6.59 6.59 0 0 1 .201 1.604c0 3.334-2.666 6-6 6-3.333 0-6-2.666-6-6 0-3.333 2.667-6 6-6 1.07 0 2.138.298 3.136.868a.714.714 0 0 0 .925-.193c.19-.314.1-.72-.183-.934C10.047.47 8.715.1 7.433.1A7.312 7.312 0 0 0 .1 7.433a7.312 7.312 0 0 0 7.333 7.334 7.312 7.312 0 0 0 7.334-7.334c0-.758-.086-1.391-.27-1.945-.06-.305-.444-.525-.797-.455zm-6.196 2.73h-.141l-2.13-2.13a.644.644 0 0 0-.933 0 .644.644 0 0 0 0 .934l2.667 2.666c.119.12.312.2.466.2a.72.72 0 0 0 .467-.2L15.233 1.9a.644.644 0 0 0 0-.933.644.644 0 0 0-.933 0L7.504 7.763z"/>
        {/* eslint-disable max-len */}
      </defs>
      <use fill="#D19191" fillRule="nonzero" xlinkHref="#pulseA"/>
    </svg>
  );
}
