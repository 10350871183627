
import { default as networkActionCreators } from "@store/network/actions";
import { createRequest } from "@src/utils/networks";
import {
  appendQueryString,
  generateQueryString
} from "hyphen-lib/dist/util/net/HttpClient";
import {
  PulsePollOverviewReportResource
} from "hyphen-lib/dist/domain/resource/pulsePoll/report/PulsePollOverviewReportResource";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";

export interface FetchPulsePollOverviewReportParameters {
  readonly templateId: string;
  readonly queryParams: Dictionary<any>;
  readonly instanceId?: string;
}

export function fetchPulsePollOverviewReportIfNeeded({
  templateId,
  queryParams,
  instanceId,
}: FetchPulsePollOverviewReportParameters) {
  const routePrefix = isNotNullNorUndefined(instanceId)
    ? "/pulsePolls/poll"
    : "/pulsePolls/template";
  const id = instanceId || templateId;
  return networkActionCreators.fetchElementIfNeeded({
    id: PulsePollOverviewReportResource.generateId(id, queryParams.filter),
    type: PulsePollOverviewReportResource.TYPE,
    request: createRequest(
      appendQueryString(
        `${routePrefix}/${id}/reports/overview`,
        generateQueryString(queryParams)
      )
    ),
  });
}
