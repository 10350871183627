import { default as networkActionCreators } from "@store/network/actions";
import { createRequest } from "@src/utils/networks";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { isNullOrUndefined } from "hyphen-lib/dist/lang/Objects";
import { md5Hash } from "hyphen-lib/dist/util/hash/Hashs";
import { Audience } from "hyphen-lib/dist/domain/common/Audience";

export function fetchManageesIfNeeded(userId: string, statusRestriction?: Optional<string>) {

  const id = getManageesId(statusRestriction);
  const queryParameters = isNullOrUndefined(statusRestriction) ? "" : "?status=" + statusRestriction;

  return networkActionCreators.fetchUntyped({
    id,
    request: createRequest(`/users/${userId}/managees${queryParameters}`),
  });
}

const ALL_MANAGEES = "managees";

export function getManageesId(statusRestriction?: Optional<string>) {
  return isNullOrUndefined(statusRestriction) ? ALL_MANAGEES : "managees#" + statusRestriction;
}

export function fetchEligibleManagersCountIfNeeded(surveyId: string, audience: Audience) {
  return networkActionCreators.fetchUntypedIfNeeded({
    id: getEligibleManagersCountId(surveyId, audience),
    request: createRequest(`/surveys/${surveyId}/accesses/eligibleManagers/count`),
  });
}

export function getEligibleManagersCountId(surveyId: string, audience: Audience) {
  return md5Hash(
    { surveyId, audience },
    { skipEmptyObjects: true }
  );
}

export function fetchPulsePollEligibleManagersCountIfNeeded(
  pulsePollId: string,
  audience: Audience
) {
  return networkActionCreators.fetchUntypedIfNeeded({
    id: getEligibleManagersCountId(pulsePollId, audience),
    request: createRequest(`/pulsePolls/${pulsePollId}/accesses/eligibleManagers/count`),
  });
}
