import React from "react";
import styled from "styled-components";
import { TableProps } from "antd/lib/table";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import moment from "moment-timezone";

import { CommentsResultResource } from "@hyphen-lib/domain/resource/report/CommentsResultResource";
import Palette from "@src/config/theme/palette";
import {
  DynamicRowProps,
  withDynamicRows,
} from "@components/core/DynamicRowsTable/withDynamicRows";
import { IconButton } from "@src/components/core/IconButton";
import { CreateActionPlanSvg } from "@src/components/core/svg";
import { MailIcon } from "@src/components/core/svg/MailIcon";

import { getOr, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { SentimentCell } from "./cells/SentimentCell";
import { CommentCell } from "./cells/CommentCell";
import { QuestionCell } from "./cells/QuestionCell";
import CommentsTableComponent from "./tables/Comments";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { FocusArea, Module } from "hyphen-lib/dist/domain/FocusArea";
import { TOOLTIP } from "@src/utils/FocusArea";
import { Trans } from "react-i18next";
import { TableTextCell } from "./cells/TableTextCell";
import { SortParameter } from "@src/utils/networks";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import useToggle from "@src/utils/hooks/useToggle";
export interface CommentsTableProps extends TableProps<CommentsResultResource> {
  readonly comments: CommentsResultResource[];
  readonly hover?: boolean;
  readonly canSendPrivateMessage: boolean;
  readonly hasActionCreationRight: boolean;
  readonly focusAreas?: FocusAreaResource[];
  readonly onCreateFocusArea: (focusArea: Partial<FocusAreaResource>) => void;
  readonly focusAreaLabel: string;
  readonly sort?: SortParameter;
  readonly topicAnalysEnabled?: boolean;
  readonly page: PageFilter;
  readonly onlySugestions?: boolean;
  readonly postAndSurveySentiment?: boolean;
  readonly enableDefaultTranslate?: boolean;
}
export interface SendPrivateMessageProps {
  readonly isPrivateMessageModalOpen: boolean;
  readonly privateMessageText: string;
  readonly sendPrivateMessage: () => void;
  readonly togglePrivateMessageModal: (
    resourceId: string,
    postId: string,
    voteId: string,
    commentText: string,
    origin: string,
    source?: CommentsResultResource["source"]
  ) => void;
  readonly updatePrivateMessageText: (privateMessageText: string) => void;
}

interface MatchParamsSurvey {
  id: string;
}

interface MatchParamsPoll {
  templateId: string;
}

type MatchParams = MatchParamsPoll | MatchParamsSurvey;

function CommentsTable(
  props: CommentsTableProps &
    SendPrivateMessageProps &
    RouteComponentProps<MatchParams>
) {
  const [translate, onToggleTranslate] = useToggle(false);
  const translateComments = {
    translate,
    onToggleTranslate
  };
  function HoverRow({
    row,
    ...rest
  }: DynamicRowProps<CommentsResultResource> &
    RouteComponentProps<MatchParams>) {
    function onCreateActionPlan() {
      const { params } = rest.match;
      // const context: Action.Source["context"] = {
      //   commentId: row._id,
      //   commentText: row.text,
      //   questionId: row.questionId,
      // };
      // FIXME: This component shouldn't be grabbing poll templateId / surveyId from request params
      if (isNotNullNorUndefined(row.source)) {
        if (
          row.source.type === CommentsResultResource.SourceType.SURVEY_COMMENT
        ) {
          const createFocusAreaObject: Partial<FocusAreaResource> = {
            title: row.text,
            source: FocusArea.Source.COMMENT,
            module: Module.SURVEY,
            focusAreaType: FocusArea.FocusAreaType.COMMENT,
            sourceUrl: {
              label: props.focusAreaLabel,
              url: window.location.href,
            },
            category: row.category,
            actionPlans: [],
            surveyId: row.source.context.id,
            questionId: row.questionId,
            commentId: row._id,
          };
          props.onCreateFocusArea(createFocusAreaObject);
        } else {
          const createFocusAreaObject: Partial<FocusAreaResource> = {
            title: row.text,
            source: FocusArea.Source.COMMENT,
            module: Module.PULSE_POLL,
            focusAreaType: FocusArea.FocusAreaType.COMMENT,
            sourceUrl: {
              label: row.question,
              url: window.location.href,
            },
            category: row.category,
            actionPlans: [],
            pollId: row.source.context.id,
            commentId: row._id,
          };
          props.onCreateFocusArea(createFocusAreaObject);
        }
      } else {
        if ("templateId" in params) {
          const createFocusAreaObject: Partial<FocusAreaResource> = {
            title: row.text,
            source: FocusArea.Source.COMMENT,
            module: Module.PULSE_POLL,
            focusAreaType: FocusArea.FocusAreaType.COMMENT,
            sourceUrl: {
              label: row.question,
              url: window.location.href,
            },
            actionPlans: [],
            category: row.category,
            pollId: params.templateId,
            commentId: row._id,
          };
          props.onCreateFocusArea(createFocusAreaObject);
        } else {
          // encodeURIComponent(row.category)
          const createFocusAreaObject: Partial<FocusAreaResource> = {
            title: row.text,
            source: FocusArea.Source.COMMENT,
            module: Module.SURVEY,
            focusAreaType: FocusArea.FocusAreaType.COMMENT,
            sourceUrl: {
              label: props.focusAreaLabel,
              url: window.location.href,
            },
            actionPlans: [],
            surveyId: params.id,
            category: row.category,
            questionId: row.questionId,
            commentId: row._id,
          };
          props.onCreateFocusArea(createFocusAreaObject);
        }
      }
    }

    function togglePrivateMessageModal() {
      const { params } = rest.match;
      const resourceId = isNotNullNorUndefined(row.source)
        ? row.source.context.id
        : "id" in params
          ? params.id
          : params.templateId;
      props.togglePrivateMessageModal(
        resourceId,
        row.questionId,
        row._id, // CommentId
        row.text,
        row.origin,
        row.source
      );
    }

    function getPrivateMessageTooltipText() {
      if (row.conversation) {
        return (
          <ConversationLabel>
            <Trans>Private message</Trans> <br />
            <Trans>sent on</Trans> {moment(row.conversation.createdAt).format("MM/DD/YYYY")}
          </ConversationLabel>
        );
      }

      return (<Trans>Send Private Message</Trans>);
    }

    return (
      <>
        <StyledHoverCell>
          <IconContainer>
            {props.hasActionCreationRight && (
              <IconButton
                svgIcon={CreateActionPlanSvg}
                onClick={onCreateActionPlan}
                tooltipProps={{
                  title: <Trans>{TOOLTIP.FOCUSAREAICON}</Trans>,
                }}
              />
            )}
            {props.canSendPrivateMessage && (
              <IconButton
                data-jest="sendPrivateMessage"
                svgIcon={MailIcon}
                onClick={togglePrivateMessageModal}
                tooltipProps={{
                  title: getPrivateMessageTooltipText(),
                }}
              />
            )}
          </IconContainer>
          <CommentCell row={row} index={rest.index} translate={props.enableDefaultTranslate 
            && translateComments?.translate}/>
        </StyledHoverCell>
        {!props.match.path.includes("question") && (
          <StyledHoverCell>
            <QuestionCell row={row} index={rest.index} />
          </StyledHoverCell>
        )}
        {rest.match.path.includes("topic") && (
          <StyledHoverCell>
            <TableTextCell row={row} index={rest.index} target="category"/>
          </StyledHoverCell>
        )}
        {props.topicAnalysEnabled && (
          <StyledHoverCell>
            <TableTextCell row={row} index={rest.index} target="topic"/>
          </StyledHoverCell>
        )}
        {getOr(props.postAndSurveySentiment, true) && (<StyledHoverCell>
          <SentimentCell row={row} index={rest.index} />
        </StyledHoverCell>
        )}
      </>
    );
  }

  const TailoredTable = withDynamicRows(
    CommentsTableComponent,
    withRouter(HoverRow)
  );

  return <TailoredTable {...props} translateComments={props.enableDefaultTranslate ? translateComments : null}/>;
}

export default withRouter(CommentsTable);

const StyledHoverCell = styled.td`
  background-color: ${Palette.lightLightBlue};
  position: relative;
`;

const ConversationLabel = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const IconContainer = styled.div`
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  div {
    position: relative;
    top: 0;
    transform: unset;
  }
`;
