// @ts-nocheck
import React from "react";
export default function ArrowDown() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="4" viewBox="0 0 8 4">
      <path
        fill="#AAB9CE"
        fillRule="nonzero"
        // eslint-disable-next-line max-len
        d="M2.857 3.586a1.609 1.609 0 0 1-.077-.07L.17.967A.54.54 0 0 1 .198.155.66.66 0 0 1 .628 0c.16 0 .48.215.651.308.833.448 1.36.66 2.721.66 1.36 0 1.888-.212 2.72-.66C6.894.215 7.214 0 7.373 0a.66.66 0 0 1 .43.155.54.54 0 0 1 .028.812l-2.61 2.55c-.025.023-.05.047-.077.07A1.753 1.753 0 0 1 4 4c-.41 0-.82-.137-1.143-.414z"
      />
    </svg>
  );
}
