import React from "react";
import { ChangeEvent } from "react";
import { Trans } from "react-i18next";
import { CustomizationPopover } from "@src/screens/Insights/components/CustomizationPopover";
import { SearchBar } from "@src/components/core/SearchBar";
import Radio from "@components/core/Radio";
import { FetchError } from "@src/screens/Insights/errors/FetchError";
import { RadioChangeEvent } from "antd/lib/radio";
import {
  PulsePollInfoResource
} from "hyphen-lib/dist/domain/resource/PulsePollInfoResource";
import { connect } from "react-redux";
import { State } from "@src/store/types";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import {
  getOr,
  mapOr,
  cleanObject,
  isNotNullNorUndefined,
  isNotEmptyObject,
  mapValues
} from "hyphen-lib/dist/lang/Objects";
import { parseNumber } from "hyphen-lib/dist/lang/Number";
import { formatSort } from "@src/utils/helper";
import {
  getExistingPage,
  extractDataAndTotalFromPage
} from "@src/store/network/selectors";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { SortParameter, FilterParameter } from "@src/utils/networks";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import { debounce } from "lodash";

import { PropMapping, applyExistingParametersIfNeeded } from "@src/utils/parameters";
import convertDictToFilters, { clearFilter, clearFilterOption } from "@src/components/core/FilterLabels/utils";
import FilterLabels from "@src/components/core/FilterLabels";
import { replaceLocation, goTo, Breadcrumb } from "@src/utils/locations";
import { Map } from "immutable";
import { PaginationConfig, SorterResult } from "antd/lib/table";
import { fetchPulsePollInfosIfNeeded } from "@src/store/network/resource/PulsePollInfoResources";
import { fetchPulsePollListOverviewIfNeeded } from "@src/store/network/resource/PulsePollListOverviewResources";
import { PulsePollListOverviewResource } from "hyphen-lib/dist/domain/resource/PulsePollListOverviewResource";
import AreYouSureModal from "@src/components/core/AreYouSureModal";
import Spin from "@components/core/Spin";
import styled from "styled-components";
import { ExpandButton } from "@src/components/core/ExpandButton";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import Palette from "@src/config/theme/palette";
import Button from "@src/components/core/Button";
import { Rights } from "hyphen-lib/dist/business/auth/Rights";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import moment from "moment-timezone";
import { getLast } from "hyphen-lib/dist/lang/Arrays";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { PulsePollActionKeyType } from "../../components/Actions";
import { getRightsMatcher, getCurrentUser } from "../../store/selectors";
import { ModalActionsKeyTypes } from "../store/reducers";
import { PulsePollOverviewTable } from "../components/PulsePollOverviewTable";
import { getParameters } from "../../parameters/store/selectors";
import { parametersActionCreators } from "../../parameters/store/actions";
import {
  pulsePollListActionCreators,
  PulsePollInfosPageParameters,
  ActionListModalParameters,
  ChangeStatusAction
} from "../store/actions";
import {
  getPulsePollListStateProps,
  getPulsePollStatusOnToggleModal
} from "../store/selectors";
import { PulsePollDetailsTable } from "../components/PulsePollDetailsTable";
import { FiltersBackdrop } from "../../components/FiltersBackdrop";
import {
  deactivationWarning,
  reactivationWarning,
  deleteWarning,
  closeIterationWarning,
  closeIterationForDeactivatedPollWarning,
  closeAndDeactivateFrequencyOncePollWarning,
  deactivateWithNoActiveIterationWarning
} from "../messages";
import { PulsePollListFiltersContainer } from "./PulsePollListFiltersContainer";

interface PulsePollListScreenProps {
  readonly infoData: PulsePollInfoResource[];
  readonly overviewData: PulsePollListOverviewResource[];
  readonly overviewTotal: number;
  readonly infoTotal: number;
  readonly modalPurpose: Optional<keyof ModalActionsKeyTypes>;
  readonly pollInstanceId: Optional<string>;
  readonly loadingDetails: boolean;
  readonly loadingOverview: boolean;
  readonly parameters: Map<string, any>;
  readonly page: PageFilter;
  readonly sort: SortParameter;
  readonly filter: FilterParameter;
  readonly isAreYouSureModalVisible: boolean;
  readonly pollStatus: Optional<string>;
  readonly hasCreationRight: boolean;
  readonly companyTimezone: string;
  readonly iterationClosingDate: Optional<Date>;
  readonly existingInfoResourcePage: Store.Page<PulsePollInfoResource>;
  readonly existingListOverviewResourcePage: Store.Page<PulsePollListOverviewResource>;
}
interface PulsePollListScreenStateProps {
  areFiltersVisible: boolean;
  tableViewMode: TableViewModes;
}

interface PulsePollListActionProps {
  readonly onFetchPulsePollInfosIfNeeded: (parameters: PulsePollInfosPageParameters) => any;
  readonly onModifyList: (parameters: PulsePollInfosPageParameters) => any;
  readonly onModifyParameters: (parameters: Dictionary<any>, mappings?: PropMapping[]) => any;
  readonly onFetchPulsePollListOverviewIfNeeded: (parameters: PulsePollInfosPageParameters) => any;
  readonly onToggleModal: (modalParameters: ActionListModalParameters) => void;
  readonly onPulsePollStatusChange: (options?: ChangeStatusAction["options"]) => void;
  readonly onCloseModal: () => void;
}

export const PULSE_POLLS_FILTER_MAPPINGS: PropMapping[] = [
  { localKey: "filter.lastInstanceDate", storeKey: "pulsePollFilter.lastInstanceDate" },
  { localKey: "filter.status", storeKey: "pulsePollFilter.status" },
  { localKey: "filter.frequency", storeKey: "pulsePollFilter.frequency" },
  { localKey: "filter.sentiment", storeKey: "pulsePollFilter.sentiment" },
  { localKey: "filter.categories", storeKey: "pulsePollFilter.categories" },
  { localKey: "mode", storeKey: "pulsePollFilter.mode"},
  { localKey: "sort", storeKey: "pulsePollSort" },
];

type Props = PulsePollListScreenProps &
PulsePollListActionProps &
RouteComponentProps;

export enum TableViewModes {
  DETAILS = "details",
  OVERVIEW = "overview",
}

type ListResourceType = PulsePollListOverviewResource | PulsePollInfoResource;
type FilterType = keyof ListResourceType;

interface WarnType {
  description: string;
  label: string;
}

type WarningTextTypes = {
  [K in keyof ModalActionsKeyTypes] : WarnType;
};

const warningTextLookUp: WarningTextTypes = {
  deactivate: {
    description: deactivationWarning,
    label: "Deactivate",
  },
  deactivateAndclose: {
    description: deactivationWarning,
    label: "Deactivate",
  },
  delete: {
    description: deleteWarning,
    label: "Delete",
  },
  close: {
    description: closeIterationWarning,
    label: "Close",
  },
  closeAndDeactivateFrequencyOncePoll: {
    description: closeAndDeactivateFrequencyOncePollWarning,
    label: "Close",
  },
  reactivate: {
    description: reactivationWarning,
    label: "Restart Poll",
  },
};

function returnWarningTextLookup(status: string, modalPurpose: string, pollInstanceId?: Optional<string>): string {
  const action: keyof ModalActionsKeyTypes = modalPurpose  as keyof ModalActionsKeyTypes;

  if (!action || !modalPurpose) {
    return "";
  }

  if (status === "deactivated" && action === "close") {
    return closeIterationForDeactivatedPollWarning;
  }

  if (action === "reactivate") {
    return reactivationWarning;
  }

  if (action === "delete") {
    return deleteWarning;
  }

  if (not(isNotNullNorUndefined(pollInstanceId))) {
    return deactivateWithNoActiveIterationWarning;
  }

  return mapOr( warningTextLookUp[action], map => map.description, "" );

}

export class PulsePollListScreen extends React.Component<
Props,
PulsePollListScreenStateProps
> {
  private onSearchChangeDebounced: (value: any) => void;
  constructor(props: Props) {
    super(props);

    this.state = {
      areFiltersVisible: false,
      tableViewMode: TableViewModes.DETAILS,
    };
    this.onSearchChangeDebounced = debounce(this.updateSearchFilter, 500);
  }

  componentDidMount() {
    let mergedParameters = this.getMergedParams();
    if (isNotNullNorUndefined(mergedParameters) && "mode" in mergedParameters) {
      this.setState({ tableViewMode: mergedParameters.mode });
    } else if ( this.props.history.location.search.includes("mode")) {
      this.setState({ tableViewMode: parseQueryString(this.props.history.location.search).mode });
    }
    else {
      mergedParameters = { mode: TableViewModes.DETAILS };
      this.props.onModifyParameters({
        ...mergedParameters,
        mode: this.state.tableViewMode,
      },
      PULSE_POLLS_FILTER_MAPPINGS);
    }

    if (isNotNullNorUndefined(mergedParameters) && isNotEmptyObject(mergedParameters)) {
      replaceLocation(mergedParameters);
      return this.fetchIfNeeded();
    }

    this.fetchIfNeeded();
  }

  componentDidUpdate(prevProps: Props, prevState : PulsePollListScreenStateProps) {
    const { loadingDetails, loadingOverview, existingInfoResourcePage, existingListOverviewResourcePage } = this.props;
    const loading = loadingDetails || loadingOverview;
    const isInError =
      mapOr(existingInfoResourcePage, page => Store.Page.isInError(page), false) ||
      mapOr(existingListOverviewResourcePage, page => Store.Page.isInError(page), false);
    const mergedParameters = this.getMergedParams();

    if (loading && not(isInError)) {
      return this.fetchIfNeeded();
    }

    if (isNotNullNorUndefined(mergedParameters) && isNotEmptyObject(mergedParameters) && not(isInError)) {
      replaceLocation(mergedParameters);
      return this.fetchIfNeeded();
    }
  }

  getMergedParams() {
    const {
      parameters,
      location: { search },
    } = this.props;

    const existing = parseQueryString(search);

    const mergedParameters =
      applyExistingParametersIfNeeded(
        parameters.toJS(),
        existing,
        ...PULSE_POLLS_FILTER_MAPPINGS
      );
    return mergedParameters;
  }

  fetchIfNeeded() {
    const {
      onFetchPulsePollInfosIfNeeded,
      onFetchPulsePollListOverviewIfNeeded,
      page,
      filter,
      sort,
    } = this.props;
    if (this.state.tableViewMode === TableViewModes.DETAILS) {
      return onFetchPulsePollInfosIfNeeded({ filter, sort, page });
    }
    onFetchPulsePollListOverviewIfNeeded({ filter, sort, page });
  }

  onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.onSearchChangeDebounced(event.target.value);
  };

  updateSearchFilter = (value: any) => {
    const {
      onModifyList,
      page,
      sort,
    } = this.props;
    const filter = getOr(this.props.filter, {});
    if (isStringAndNotEmpty(value)) {
      filter.freeText = value;
    } else {
      delete filter.freeText;
    }
    const resetPage = { ...page, number: 1 };
    onModifyList({
      filter,
      page: resetPage,
      sort,
    });
  };

  handleFiltersClick = () =>
    this.setState(state => ({
      areFiltersVisible: !state.areFiltersVisible,
    }));

  handleApplyFilters = (newFilter: any) => {
    const {
      page,
      sort,
      onModifyList,
      onModifyParameters,
    } = this.props;

    this.setState({ areFiltersVisible: false });

    const resetPage = { ...page, number: 1 };

    onModifyParameters({ filter: newFilter, sort }, PULSE_POLLS_FILTER_MAPPINGS);

    onModifyList({
      filter: cleanObject(newFilter),
      page: resetPage,
      sort,
    });
  };

  onClearFilter = (filterToRemove: string) => {
    const {
      page,
      sort,
      onModifyList,
      onModifyParameters,
    } = this.props;

    const filter = clearFilter(filterToRemove, this.props.filter);
    onModifyParameters({ filter }, PULSE_POLLS_FILTER_MAPPINGS);

    onModifyList({
      filter: cleanObject(filter),
      page,
      sort,
    });
  };

  onClearFilterOption = (filterKey: string, subFilter: string) => {
    const {
      page,
      sort,
      onModifyList,
      onModifyParameters,
    } = this.props;

    const filter = clearFilterOption(filterKey, subFilter, this.props.filter);

    onModifyParameters({ filter }, PULSE_POLLS_FILTER_MAPPINGS);

    onModifyList({
      filter: cleanObject(filter),
      page,
      sort,
    });
  };

  renderFilterLabels = () => {
    const { filter } = this.props;
    const filters = convertDictToFilters(
      filter,
      {} /* no dimensions filters */
    );

    if (filters.length > 0 ) {
      return (
        <FilterLabels
          filters={filters}
          onClearFilter={this.onClearFilter}
          onClearSubfilter={this.onClearFilterOption}
        />
      );
    } else {
      return null;
    }
  };

  handleModeChange = (e: RadioChangeEvent) => {
    const tableViewMode = e.target.value;
    this.setState({ tableViewMode }, () => {
      this.props.onModifyParameters({
        ...this.getMergedParams(),
        mode: this.state.tableViewMode,
      }, PULSE_POLLS_FILTER_MAPPINGS);
      replaceLocation({
        ...this.getMergedParams(),
        mode: tableViewMode,
      });
    });
  };

  onActionClick = (row: ListResourceType, action: keyof PulsePollActionKeyType) => {
    switch (action) {
      case "seeReport":
        return goTo(
          `/pulsePolls/${row._id}/instances/${getLast(row.instances).id}/reports`,
          Breadcrumb.stack("Polls")
        );
      case "edit":
        return goTo(`/pulsePolls/edit/${row._id}/settings`);
      case "deactivate":
      case "delete" :
      case "reactivate":
      case "closeAndDeactivateFrequencyOncePoll":
      case "close": {
        return this.props.onToggleModal({
          modalPurpose: action,
          pollTemplateId: row._id,
          pollInstanceId: row.launchedInstanceId,
          pollStatus: row.status,
          iterationClosingDate: row.iterationClosingDate,
        });
      }
      default:
        return;
    }
  };

  onTableChange = <T extends ListResourceType>(
    pagination: PaginationConfig,
    filters: Record<FilterType, string[]>,
    sorter: SorterResult<T>
  ): void => {
    const { page, onModifyList, filter, sort: previousSort, onModifyParameters } = this.props;
    const pageParam = {
      size: getOr(pagination.pageSize, page.size),
      number: getOr(pagination.current, 1),
    };
    const sortParam : SortParameter = {};

    if (isNotEmptyObject(sorter) && isNotNullNorUndefined(sorter.order)) {
      sortParam[sorter.columnKey] = sorter.order === "ascend" ? 1 : -1;
    }

    const newSort = {
      ...mapValues(previousSort, () => undefined), // explicitly remove old sort
      ...sortParam,
    };

    onModifyParameters({ sort: newSort }, PULSE_POLLS_FILTER_MAPPINGS);
    onModifyList({
      filter,
      page: pageParam,
      sort: sortParam,
    });

  };

  getAreYouSureFooter = () => {
    const { modalPurpose, onCloseModal, onPulsePollStatusChange, iterationClosingDate } = this.props;
    if (modalPurpose === "deactivate" as any || modalPurpose === "deactivate&close" as any) {
      const actionButtonsRow = [
        <Button key="cancel" data-cy="areYouSure_cancel" onClick={onCloseModal} 
        translate="yes">Cancel</Button>,
      ];

      let buttonText = "Pause";
      if (isNotNullNorUndefined(iterationClosingDate)) {
        buttonText = "Pause Only";
        actionButtonsRow.splice(1,0,<Button
          key="deactivate-close"
          data-cy="pauseAndCloseButton"
          onClick={onPulsePollStatusChange.bind(null, { deactivateAndClose: true })}
        >
          <Trans>Pause and Close</Trans>
        </Button>);
      }

      actionButtonsRow.push(
        <Button
          key="deactivate-only"
          data-cy="okButton"
          onClick={onPulsePollStatusChange.bind(null, { deactivateAndClose: false })}
          color="blue"
        >
          <Trans>{ buttonText }</Trans>
        </Button>
      );

      return actionButtonsRow;

    }
    return null;
  };

  render() {
    const { areFiltersVisible, tableViewMode } = this.state;
    const {
      filter,
      sort,
      infoTotal,
      overviewTotal,
      page,
      infoData,
      overviewData,
      isAreYouSureModalVisible,
      modalPurpose,
      pollInstanceId,
      onPulsePollStatusChange,
      onCloseModal,
      pollStatus,
      hasCreationRight,
      companyTimezone,
      existingInfoResourcePage,
      existingListOverviewResourcePage,
    } = this.props;
    const isSpinning =
      ( Store.Page.isLoading(existingInfoResourcePage) && tableViewMode === TableViewModes.DETAILS ) ||
      ( Store.Page.isLoading(existingListOverviewResourcePage) && tableViewMode === TableViewModes.OVERVIEW );
    const isInfoResourceInError = mapOr(existingInfoResourcePage, p => Store.Page.isInError(p), false);
    const isOverviewResourceInError = mapOr(existingListOverviewResourcePage, q => Store.Page.isInError(q), false);
    const isInError = isInfoResourceInError || isOverviewResourceInError;
    const action = getOr(modalPurpose, "delete" as any);

    return (
      <Container>
        <AreYouSureModal
          visible={isAreYouSureModalVisible}
          title="Are you sure?"
          description={
            returnWarningTextLookup(getOr(pollStatus, ""), modalPurpose as unknown as string, pollInstanceId)
          }
          okLabel={
            mapOr(
              warningTextLookUp[action as unknown as keyof ModalActionsKeyTypes],
              map => map.label,
              "Ok"
            )
          }
          cancelLabel="Cancel"
          onCancel={onCloseModal}
          onOk={onPulsePollStatusChange}
          footer={this.getAreYouSureFooter()}
        />
        <SearchAndFilterContainer>
          <SearchBar
            placeholder="Search polls"
            onChange={this.onSearchChange}
            defaultValue={mapOr(filter, f => f.freeText, "")}
          />
          <ButtonsContainer>
            <StyledExpandButton icon="filter" onClick={this.handleFiltersClick} translate="yes">
              Filters
            </StyledExpandButton>
            <Tabs
              onChange={this.handleModeChange}
              value={tableViewMode}
              style={{ marginBottom: 8 }}
            >
              <Radio.Button value={TableViewModes.DETAILS}>
                <Trans>Details</Trans>
              </Radio.Button>
              <Radio.Button value={TableViewModes.OVERVIEW}>
                <Trans>Overview</Trans>
              </Radio.Button>
            </Tabs>
          </ButtonsContainer>

          {areFiltersVisible && (
            <FiltersBackdrop onClick={this.handleFiltersClick} />
          )}
          <CustomizationPopover open={areFiltersVisible}>
            <PulsePollListFiltersContainer
              values={filter}
              onApply={this.handleApplyFilters}
            />
          </CustomizationPopover>
        </SearchAndFilterContainer>
        {this.renderFilterLabels()}
        <Spin size="large" spinning={isSpinning}>
          {isInfoResourceInError &&
          (<FetchError
            {...(existingInfoResourcePage as Store.Page.InError)}
            resourceType={PulsePollInfoResource.TYPE}/>
          )
          }
          {isOverviewResourceInError &&
          (<FetchError
            {...(existingListOverviewResourcePage as Store.Page.InError)}
            resourceType={PulsePollListOverviewResource.TYPE}/>
          )
          }
          {tableViewMode === TableViewModes.DETAILS && not(isInError) &&
          <PulsePollDetailsTable
            sort={sort}
            timezone={companyTimezone}
            dataSource={infoData}
            onActionClick={this.onActionClick}
            // @ts-ignore
            onChange={this.onTableChange}
            pagination={{
              total: infoTotal,
              pageSize: page.size,
              current: page.number,
              showTotal: (totalDocuments: number, range: number[]) =>
                <Trans i18nKey="pollsCount"
                values={{start: range[0], end: range[1], total: totalDocuments}} 
                defaults={`Showing ${range[0]} to ${range[1]} of ${totalDocuments} polls`}/>
            }}
          />
          }
          {tableViewMode === TableViewModes.OVERVIEW && not(isInError) &&
          <PulsePollOverviewTable
            sort={sort}
            onActionClick={this.onActionClick}
            dataSource={overviewData}
            // @ts-ignore
            onChange={this.onTableChange}
            pagination={{
              total: overviewTotal,
              pageSize: page.size,
              current: page.number,
              showTotal: (totalDocuments: number, range: number[]) =>
                <Trans i18nKey="pollsCount"
                values={{start: range[0], end: range[1], total: totalDocuments}} 
                defaults={`Showing ${range[0]} to ${range[1]} of ${totalDocuments} polls`}/>,
            }}
            hasCreationRight={hasCreationRight}
          />
          }
        </Spin>
      </Container>
    );
  }
}

const Container = styled.div`
  background-color: white;
  padding-bottom: 32px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  min-width: 400px;
  justify-content: space-around;
`;
const StyledExpandButton = styled(ExpandButton)`
  width: 144px;
  margin-left: 16px;
`;

const SearchAndFilterContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  position: relative;
`;

const Tabs = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    color: ${Palette.bluePurple} ;
    height: 36px;
    padding-top: 2px;
  }
  label.ant-radio-button-wrapper:first-child {
    border-radius: 3px 0 0 3px;
  }
`;

function mapStateToProps(state: State, { location }: RouteComponentProps): PulsePollListScreenProps {
  const queryParameters = parseQueryString(location.search);
  const filter = getOr(queryParameters.filter, {});

  const { pageSize,
    isAreYouSureModalVisible,
    modalPurpose,
    iterationClosingDate,
    pollInstanceId,
  } = getPulsePollListStateProps(state);

  const sort = getOr(formatSort(queryParameters.sort), {});
  const page = {
    size: pageSize,
    // the query string IS the source of truth, as it might be modified when using back/next buttons
    number: mapOr(queryParameters.page, parseNumber, 1),
  };

  const existingInfoResourcePage = getExistingPage(
    state,
    PulsePollInfoResource.TYPE,
    PulsePollInfoResource.generateKey(filter, sort),
    page
  );

  const existingListOverviewResourcePage = getExistingPage(
    state,
    PulsePollListOverviewResource.TYPE,
    PulsePollListOverviewResource.generateKey(filter, sort),
    page
  );
  const { data: infoData, total: infoTotal } = extractDataAndTotalFromPage(existingInfoResourcePage);
  const { data: overviewData, total: overviewTotal  } = extractDataAndTotalFromPage(existingListOverviewResourcePage);

  const pollStatus = getPulsePollStatusOnToggleModal(state);

  const companyTimezone = mapOr(
    getCurrentUser(state),
    user => mapOr(
      user.company,
      company => company.timeZone,
      moment.tz.guess()
    ),
    moment.tz.guess()
  );

  return {
    loadingDetails: Store.Page.isNotFound(existingInfoResourcePage),
    loadingOverview: Store.Page.isNotFound(existingListOverviewResourcePage),
    existingInfoResourcePage,
    existingListOverviewResourcePage,
    infoData,
    overviewData,
    infoTotal,
    overviewTotal,
    page,
    sort,
    filter,
    modalPurpose,
    pollInstanceId,
    parameters: getParameters(state),
    isAreYouSureModalVisible,
    pollStatus,
    companyTimezone,
    hasCreationRight: getRightsMatcher(state).hasRight(Rights.PulsePoll.CREATE),
    iterationClosingDate,
  };
}

const mapDispatchToProps = {
  onFetchPulsePollInfosIfNeeded: fetchPulsePollInfosIfNeeded,
  onFetchPulsePollListOverviewIfNeeded: fetchPulsePollListOverviewIfNeeded,
  onModifyList: pulsePollListActionCreators.modifyList,
  onModifyParameters: parametersActionCreators.modifyParameters,
  onToggleModal: pulsePollListActionCreators.toggleAreYouSureModal,
  onPulsePollStatusChange: pulsePollListActionCreators.onPulsePollStatusChange,
  onCloseModal: pulsePollListActionCreators.onCloseModal,
};

export const PulsePollListContainer = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PulsePollListScreen));
