import { State } from "@store/types";

import { getFocusAreaListStoreProps } from "@src/screens/Insights/Actions/store/selectors";
import {
  extractDataAndTotalFromPage,
  getExistingPage,
} from "@src/store/network/selectors";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { DrawerViews } from "@src/components/core/BottomDrawer";

export const TOOLTIP = {
  FOCUSAREAICON: "Add as focus area"
};

/**
 * The function returns all the focus areas that are stored locally in the redux store. It limits the total
 * entries as defined by the maxToReturn parameter.
 *
 * It must be noted that if the local store has total number of entries less than the maxToReturn, all entries
 * in the local store will be returned. Thus, maximim 
 * possible totalEntries = min(maxToReturn, totalEntriesInsideReduxStore)
 *
 * @param state
 * @param maxToReturn
 * @returns
 */
export function getAllFocusAreasFromReduxStore(
  state: State,
  maxToReturn = 30
): FocusAreaResource[] {
  const {
    pageSize,
  } = getFocusAreaListStoreProps(state);

  const focusAreaList: FocusAreaResource[] = [];
  let currentPageNumber = 1;

  /**
   * The reason we have the loop:
   *  - The focus area bottom drawer must show the total elements greater than
   * the default page size of 10
   *  - It is complex to change the default page size of 10
   *  - Going forward, infinite scroll can also be implemented
   */
  while (focusAreaList.length < maxToReturn) {
    const existingPage = getExistingPage(
      state,
      FocusAreaResource.TYPE,
      FocusAreaResource.generateKey({ withActionPlans: false }, {}),
      {
        size: pageSize,
        number: currentPageNumber++,
      }
    );
    const { data } = extractDataAndTotalFromPage(existingPage);
    if(data.length === 0) break;
    focusAreaList.push(...data);
  }

  return focusAreaList.slice(0, maxToReturn);
}


export function checkIfFocusAreaCreated(state: State): boolean {
  const isFocusAreaCreated = state.getIn(["insights_focusAreas", "created"]);
  return isFocusAreaCreated;
}

export function getCurrentBottomDrawerView(state: State): DrawerViews {
  return state.getIn(["insights_bottom_drawer", "bottomDrawerView"]);
}

export function isFARequestComplete(state: State): boolean {
  return state.getIn(["insights_focusAreas","completedFARequest"]);
}