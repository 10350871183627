// core React
import React from "react";
import { Trans } from "react-i18next";
// core styled components
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { Tooltip, Icon, Empty } from "antd";
// type defs
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { CompareWithOption } from "@screens/Insights/components/ViewOptions/components/CompareWith";

// app components
import { Heading } from "@components/core/Typography";

// app utils
import { isEmpty, isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { getOr, values } from "@hyphen-lib/lang/Objects";
import { HighlightedSegment } from "hyphen-lib/dist/domain/resource/report/common/Highlights";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
import SegmentList from "./SegmentList";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";

export interface SegmentLists {
  readonly strongestSegments: HighlightedSegment[];
  readonly weakestSegments: HighlightedSegment[];
  readonly biggestDecline: HighlightedSegment[];
  readonly biggestImprovements: HighlightedSegment[];
}

interface Props {
  readonly segmentLists: SegmentLists;
  readonly dimensions: Optional<Dimensions>;
  readonly areComparisonsVisible?: boolean;
  readonly compareWithOptions: CompareWithOption[];
  readonly comparisonKey: Optional<string>;
  readonly hasActionCreationRight: boolean;
  readonly isNPSQuestion?: boolean;
  readonly isRatingQuestion?: boolean;
  readonly focusAreas?: FocusAreaResource[];
  readonly onCreateFocusArea: (focusArea: Partial<FocusAreaResource>) => void;
  readonly focusAreaLabel: string;
}

export const defaultSegmentLists: SegmentLists = {
  strongestSegments: [
    {
      compare: {
        average: {
          filteredForAnonymity: false,
          value: 20,
        },
      },
      dimension: "location",
      score: 20,
      segment: "Chicago",
    },
  ],
  weakestSegments: [
    {
      dimension: "location",
      score: 20,
      segment: "Chicago",
    },
  ],
  biggestImprovements: [
    {
      dimension: "location",
      score: 20,
      segment: "Chicago",
    },
  ],
  biggestDecline: [
    {
      dimension: "location",
      score: 20,
      segment: "Chicago",
    },
  ],
};

function Segments(props: Props) {
  const {
    segmentLists = defaultSegmentLists,
    dimensions,
    areComparisonsVisible,
    compareWithOptions,
    comparisonKey = "previous",
    hasActionCreationRight = false,
    isRatingQuestion,
    isNPSQuestion,
    focusAreas,
    onCreateFocusArea,
    focusAreaLabel
  } = props;

  return (
    <Container data-cy="polls_segmentReports">
      <Heading size="large">
        <Trans>Segments</Trans>
        <Tooltip title={<Trans>Based on segment data</Trans>}>
          <StyledInfoIcon
            className="block--print-hide"
            type="info-circle"
            data-cy="pollsSegment_toolTip"
          />
        </Tooltip>
      </Heading>
      <Summary><Trans>See which segments require your attention.</Trans></Summary>

      {values(segmentLists).every(isEmpty) ? (
        <Empty description={<Trans>Not enough data</Trans>} />
      ) : (
        <SectionContainer>
          {Object.entries(segmentLists).map(
            ([segmentName, segmentData]) =>
              isNotNullNorUndefined(segmentData) &&
              isNotEmptyArray(segmentData) && (
                <SegmentList
                  key={segmentName}
                  title={segmentName}
                  segmentData={segmentData}
                  dimensions={dimensions}
                  areComparisonsVisible={areComparisonsVisible}
                  compareWithOptions={compareWithOptions}
                  comparisonKey={comparisonKey}
                  hasActionCreationRight={hasActionCreationRight}
                  isRatingQuestion={isRatingQuestion}
                  isNPSQuestion={isNPSQuestion}
                  onCreateFocusArea={onCreateFocusArea}
                  focusAreas={focusAreas}
                  focusAreaLabel={focusAreaLabel}
                />
              )
          )}
        </SectionContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  padding: 32px;
  background: ${Palette.white};
  font-family: Lato, sans-serif;
  color: ${Palette.veryDarkBlueGrey};
`;

const Summary = styled.div`
  margin-top: 8px;
  color: ${Palette.bluishGrey};
`;

const SectionContainer = styled.div<{ marginTop?: number }>`
  margin-top: ${(props) => getOr(props.marginTop, 24)}px;
  display: flex;
  flex-wrap: wrap;
`;

const StyledInfoIcon = styled(Icon)`
  font-size: 16px;
  color: ${Palette.lightGreyBlue};
  margin-left: 8px;
`;

export default Segments;
