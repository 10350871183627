import React from "react";
import { Menu as AntMenu } from "antd";
import styled from "styled-components";
import { MenuProps } from "antd/lib/menu";
import "antd/lib/menu/style/index.css";
import Palette from "@src/config/theme/palette";
import hoistNonReactStatics from "hoist-non-react-statics";

const arrowSvgData = `data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWl
naHQ9IjciIHZpZXdCb3g9IjAgMCA4IDciPiAgICA8cGF0aCBmaWxsPSIjNjIzOUJGIiBmaWxsLXJ1bGU9Im5vbnplcm8iIGQ9Ik01Ljg2MSAyLjg3N0w0Lj
AzOSAxLjA2NGEuNjIuNjIgMCAwIDEgMC0uODgxLjYyOC42MjggMCAwIDEgLjg4NiAwTDcuODE2IDMuMDZhLjYyLjYyIDAgMCAxIDAgLjg4TDQuOTI1IDYuO
DE4YS42MjUuNjI1IDAgMCAxLS44ODYgMCAuNjIuNjIgMCAwIDEgMC0uODhMNS44NiA0LjEyMkguNjI2QS42MjUuNjI1IDAgMCAxIDAgMy41YzAtLjM0NC4y
OC0uNjIzLjYyNi0uNjIzaDUuMjM1eiIvPjwvc3ZnPg==`;

const StyledCardMenu = styled(AntMenu)<MenuProps>`
  background: transparent !important;
  color: ${Palette.bluePurple} !important;
  border-right: 0 !important;

  .ant-menu-item {
    font-family: Lato;
    color: ${Palette.darkBlueGrey} !important;
    font-size: 14px;
    padding: 0 !important;
  }
  .ant-menu-item:active, .ant-menu-item-selected {
    color: ${Palette.bluePurple} !important;
    background-color: transparent !important;
  }
  .ant-menu-item-selected {
    padding-left: 16px !important;
    background: url(${arrowSvgData}) no-repeat 0 50%;
    transition: background 0s;
  }
}
`;

export default hoistNonReactStatics(StyledCardMenu as React.ComponentType<MenuProps>, AntMenu);
