import { WheelEvent } from "react";
import { List } from "immutable";
import { LifeCycleSettings, LifeCycleSettingsConfig } from "@hyphen-lib/domain/common/LifeCycleSettingsConfig";
import { isNullOrEmpty, isStringAndNotEmpty } from "@hyphen-lib/lang/Strings";
import { not } from "@hyphen-lib/lang/Booleans";
import { mapOr } from "hyphen-lib/dist/lang/Objects";

export const EMPTY_STRING_NOTATION = -2;
export const INFINITY_NOTATION = -1;

export type MutablePhase = {
  -readonly [P in keyof LifeCycleSettings.Phase]: LifeCycleSettings.Phase[P];
};

export type MutableSubPhase = {
  -readonly [P in keyof LifeCycleSettings.SubPhase]: LifeCycleSettings.SubPhase[P];
};

export type MutableSeparation = {
  -readonly [P in keyof LifeCycleSettings.Separation]: LifeCycleSettings.Separation[P];
};

export interface MutableSubPhaseWithErrorState extends MutableSubPhase {
  startTenureError: string | null;
  endTenureError: string | null;
}

export interface MutablePhaseWithErrorState extends Omit<MutablePhase, "subPhases"> {
  nameError: string | null;
  startTenureError: string | null;
  endTenureError: string | null;
  subPhases: List<MutableSubPhaseWithErrorState>;
}

export interface MutableSeparationWithErrorState extends Omit<MutableSeparation, "subPhases"> {
  nameError: string | null;
  tenureBeforeTerminationError: string | null;
  subPhases: List<MutableSubPhaseWithErrorState>;
}

export const getPhasesWithoutErrorAsArray = (phases: List<MutablePhaseWithErrorState>): LifeCycleSettings.Phase[] => {
  return phases.toArray().map((phase) => ({
    name: phase.name,
    startTenure: phase.startTenure,
    endTenure: phase.endTenure,
    canRemove: phase.canRemove,
    subPhases: getSubPhasesWithoutErrorAsArray(phase.subPhases),
  }));
};

export const getSubPhasesWithoutErrorAsArray = (
  subPhases: List<MutableSubPhaseWithErrorState>
): LifeCycleSettings.SubPhase[] => {
  return subPhases.toArray().map((subPhase) => ({
    startTenure: subPhase.startTenure,
    endTenure: subPhase.endTenure,
  }));
};

export const getSeparationWithoutErrorAsObject = (
  separation: MutableSeparationWithErrorState
): LifeCycleSettings.Separation => {
  return {
    name: separation.name,
    tenureBeforeTermination: separation.tenureBeforeTermination,
    subPhases: getSubPhasesWithoutErrorAsArray(separation.subPhases),
    config: separation.config,
  };
};

const isInvalidTenureValue = (tenureValue: string | number): boolean => {
  return Number.isNaN(Number(tenureValue)) || not(Number.isInteger(Number(tenureValue))) || Number(tenureValue) < 0;
};

const isInvalidStartTenureValue = (tenureValue: string | number): boolean => {
  return isInvalidTenureValue(tenureValue);
};

const isInvalidEndTenureValue = (tenureValue: string | number, isLastPhase: boolean): boolean => {
  if (isLastPhase && Number.isInteger(Number(tenureValue)) && Number(tenureValue) < 0) {
    return false;
  }
  return isInvalidTenureValue(tenureValue);
};

export const getPhasesWithErrors = (phases: List<MutablePhaseWithErrorState>): List<MutablePhaseWithErrorState> => {
  return phases.map((phase, idx) => {
    phase.nameError = null;
    phase.startTenureError = null;
    phase.endTenureError = null;

    if (phase.startTenure > phase.endTenure && phase.endTenure !== INFINITY_NOTATION) {
      phase.startTenureError = "Start tenure cannot be greater than end tenure";
      phase.endTenureError = "End tenure cannot be less than start tenure";
    }
    if (phase.endTenure <= phase.startTenure && phase.endTenure !== INFINITY_NOTATION) {
      phase.endTenureError = "End tenure cannot be less than or equal to start tenure";
    }
    if (idx > 0) {
      const previousPhase = phases.get(idx - 1);
      if (phase.startTenure !== previousPhase!.endTenure) {
        phase.startTenureError = "Start tenure must be equal to previous phase end tenure";
      }
    }
    if (idx < phases.size - 1) {
      const nextPhase = phases.get(idx + 1);
      if (phase.endTenure !== nextPhase!.startTenure) {
        phase.endTenureError = "End tenure must be equal to next phase start tenure";
      }
    }

    const isLastPhase = idx === phases.size - 1;
    if (isInvalidStartTenureValue(phase.startTenure)) {
      phase.startTenureError = "Please enter a valid start tenure value";
    }
    if (isInvalidEndTenureValue(phase.endTenure, isLastPhase)) {
      phase.endTenureError = "Please enter a valid end tenure value";
    }

    if (isNullOrEmpty(phase.name)) {
      phase.nameError = "Name cannot be empty";
    }
    if (phase.startTenure === EMPTY_STRING_NOTATION) {
      phase.startTenureError = "Tenure value cannot be empty";
    }
    if (phase.endTenure === EMPTY_STRING_NOTATION) {
      phase.endTenureError = "Tenure value cannot be empty";
    }

    phase.subPhases = getSubPhasesWithErrors(phase);

    return phase;
  });
};

export const getSubPhasesWithErrors = (phase: MutablePhaseWithErrorState): List<MutableSubPhaseWithErrorState> => {
  const { subPhases } = phase;

  return subPhases.map((subPhase, idx) => {
    subPhase.startTenureError = null;
    subPhase.endTenureError = null;

    const phaseHasErrors =
      isStringAndNotEmpty(phase.nameError) ||
      isStringAndNotEmpty(phase.startTenureError) ||
      isStringAndNotEmpty(phase.endTenureError);

    if (phaseHasErrors) {
      return subPhase;
    }
    if (subPhase.startTenure > subPhase.endTenure && subPhase.endTenure !== INFINITY_NOTATION) {
      subPhase.startTenureError = "Start tenure cannot be greater than end tenure";
      subPhase.endTenureError = "End tenure cannot be less than start tenure";
    }
    if (subPhase.endTenure <= subPhase.startTenure && subPhase.endTenure !== INFINITY_NOTATION) {
      subPhase.endTenureError = "End tenure cannot be less than or equal to start tenure";
    }
    // Fixme: Discuss refactoring idea
    if (idx > 0) {
      const previousSubPhase = subPhases.get(idx - 1);
      if (subPhase.startTenure !== previousSubPhase!.endTenure) {
        subPhase.startTenureError = "Start tenure must be equal to previous subPhase end tenure";
      }
    }
    if (idx < subPhases.size - 1) {
      const nextSubPhase = subPhases.get(idx + 1);
      if (subPhase.endTenure !== nextSubPhase!.startTenure) {
        subPhase.endTenureError = "End tenure must be equal to next subPhase start tenure";
      }
    }
    if (subPhase.startTenure === subPhase.endTenure) {
      subPhase.startTenureError = "Start and end tenure values cannot be equal";
      subPhase.endTenureError = "Start and end tenure values cannot be equal";
    }
    if (
      subPhase.startTenure < phase.startTenure ||
      (phase.endTenure !== INFINITY_NOTATION && subPhase.startTenure > phase.endTenure)
    ) {
      subPhase.startTenureError = "Tenure value must be in range of phase start tenure & end tenure";
    }
    if (
      (subPhase.endTenure !== INFINITY_NOTATION && subPhase.endTenure < phase.startTenure) ||
      (phase.endTenure !== INFINITY_NOTATION && subPhase.endTenure > phase.endTenure)
    ) {
      subPhase.endTenureError = "Tenure value must be in range of phase start tenure & end tenure";
    }
    if (subPhase.startTenure === EMPTY_STRING_NOTATION) {
      subPhase.startTenureError = "Tenure value cannot be empty";
    }
    if (subPhase.endTenure === EMPTY_STRING_NOTATION) {
      subPhase.endTenureError = "Tenure value cannot be empty";
    }

    return subPhase;
  });
};

export const getSeparationWithErrors = (
  separation: MutableSeparationWithErrorState
): MutableSeparationWithErrorState => {
  separation.nameError = null;
  separation.tenureBeforeTerminationError = null;

  if (isNullOrEmpty(separation.name)) {
    separation.nameError = "Name cannot be empty";
  }
  if (separation.tenureBeforeTermination === EMPTY_STRING_NOTATION) {
    separation.tenureBeforeTerminationError = "Tenure value cannot be empty";
  }

  if (isInvalidTenureValue(separation.tenureBeforeTermination)) {
    separation.tenureBeforeTerminationError = "Please enter a valid tenure range value";
  }

  separation.subPhases = getSeparationSubPhasesWithErrors(separation);

  return separation;
};

export const getSeparationSubPhasesWithErrors = (
  separation: MutableSeparationWithErrorState
): List<MutableSubPhaseWithErrorState> => {
  const { subPhases } = separation;

  return subPhases.map((subPhase, idx) => {
    subPhase.startTenureError = null;
    subPhase.endTenureError = null;

    const separationHasErrors =
      isStringAndNotEmpty(separation.nameError) || isStringAndNotEmpty(separation.tenureBeforeTerminationError);

    if (separationHasErrors) {
      return subPhase;
    }

    if (subPhase.startTenure < subPhase.endTenure && subPhase.endTenure !== INFINITY_NOTATION) {
      subPhase.startTenureError = "Start tenure cannot be less than end tenure";
      subPhase.endTenureError = "End tenure cannot be greater than start tenure";
    }
    if (idx > 0) {
      const previousSubPhase = subPhases.get(idx - 1);
      if (subPhase.startTenure !== previousSubPhase!.endTenure) {
        subPhase.startTenureError = "Start tenure must be equal to previous subPhase end tenure";
      }
    }
    if (idx < subPhases.size - 1) {
      const nextSubPhase = subPhases.get(idx + 1);
      if (subPhase.endTenure !== nextSubPhase!.startTenure) {
        subPhase.endTenureError = "End tenure must be equal to next subPhase start tenure";
      }
    }

    if (subPhase.startTenure > separation.tenureBeforeTermination) {
      subPhase.startTenureError = "Start tenure cannot be greater than tenure before termination";
    }
    if (subPhase.endTenure > separation.tenureBeforeTermination) {
      subPhase.endTenureError = "End tenure cannot be greater than tenure before termination";
    }
    if (subPhase.startTenure === subPhase.endTenure) {
      subPhase.startTenureError = "Start tenure cannot be equal to end tenure";
      subPhase.endTenureError = "End tenure cannot be equal to start tenure";
    }
    if (isInvalidTenureValue(subPhase.startTenure)) {
      subPhase.startTenureError = "Please enter a valid start tenure value";
    }
    if (isInvalidTenureValue(subPhase.endTenure)) {
      subPhase.endTenureError = "Please enter a valid end tenure value";
    }
    if (subPhase.startTenure === EMPTY_STRING_NOTATION) {
      subPhase.startTenureError = "Tenure value cannot be empty";
    }
    if (subPhase.endTenure === EMPTY_STRING_NOTATION) {
      subPhase.endTenureError = "Tenure value cannot be empty";
    }

    return subPhase;
  });
};

export const handleWheelScroll = (evt: WheelEvent<HTMLInputElement>) => {
  evt.currentTarget.blur();
};

export const hasSettingsChanged = (
  phases: LifeCycleSettings.Phase[],
  separation: LifeCycleSettings.Separation,
  lcaSettingsDB: LifeCycleSettingsConfig
) => {
  if (hasPhasesChanged(phases, lcaSettingsDB.phases)) {
    return true;
  }
  if (hasSeparationChanged(separation, lcaSettingsDB.separation)) {
    return true;
  }

  return false;
};

function hasPhasesChanged(phases: LifeCycleSettings.Phase[], dbPhases:  LifeCycleSettings.Phase[] ) {
  if (phases.length !== dbPhases.length) {
    return true;
  }

  if (hasPhaseNamesChanged(phases, dbPhases)) {
    return true;
  }

  if (hasPhaseTenuresChanged(phases, dbPhases)) {
    return true;
  }

  if (haveSubphasesChanged(phases, dbPhases)) {
    return true;
  }

  return false;
}

function hasSeparationChanged(separation: LifeCycleSettings.Separation, dbSeparation: LifeCycleSettings.Separation) {
  if (separation.name !== dbSeparation.name) {
    return true;
  }

  if (separation.tenureBeforeTermination !== dbSeparation.tenureBeforeTermination) {
    return true;
  }

  if (separation.subPhases.length !== dbSeparation.subPhases.length) {
    return true;
  }

  if (!areSubPhasesEqual(separation.subPhases, dbSeparation.subPhases)) {
    return true;
  }
  return false;
}

function hasPhaseNamesChanged(phases: LifeCycleSettings.Phase[], dbPhases:  LifeCycleSettings.Phase[]) {
  const phaseNames = extractPhaseNames(phases);
  const dbPhaseNames = extractPhaseNames(dbPhases);
  return phaseNames.filter(name => !dbPhaseNames.includes(name)).length > 0;
}

function hasPhaseTenuresChanged(phases: LifeCycleSettings.Phase[], dbPhases:  LifeCycleSettings.Phase[]) {
  const phaseTenures = extractTenures(phases);
  const dbPhaseTenures = extractTenures(dbPhases);
  return phaseTenures.filter(tenure => !dbPhaseTenures.includes(tenure)).length > 0;
}

function haveSubphasesChanged(phases: LifeCycleSettings.Phase[], dbPhases:  LifeCycleSettings.Phase[]) {
  return !phases.every(phase => {
    const dbSubphases = extractSubphases(dbPhases, phase.name);
    return areSubPhasesEqual(phase.subPhases, dbSubphases);
  });
}

function areSubPhasesEqual(subPhases: LifeCycleSettings.SubPhase[], dbSubphases: LifeCycleSettings.SubPhase[]) {
  if (subPhases.length !== dbSubphases.length) {
    return false;
  }
  const subPhaseTenures = extractTenures(subPhases);
  const dbSubphaseTenures = extractTenures(dbSubphases);

  return subPhaseTenures.filter(tenure => !dbSubphaseTenures.includes(tenure)).length === 0;
}

function extractPhaseNames(phases: LifeCycleSettings.Phase[]) {
  return phases.map(phase => phase.name).sort();
}

function extractTenures(phases: (LifeCycleSettings.Phase | LifeCycleSettings.SubPhase)[]) {
  return phases.reduce((tenureCollector, phase) => {
    tenureCollector.push(phase.startTenure);
    tenureCollector.push(phase.endTenure);
    return tenureCollector;
  },[] as number[]).sort((a: number, b: number) => a - b);
}

function extractSubphases(phases: LifeCycleSettings.Phase[], phaseName: string) {
  const defaultPhase = {name: phaseName, subPhases: [], startTenure: 0, endTenure: 10, canRemove: true};
  const phase = mapOr(phases.filter(p => p.name === phaseName), p => p[0], defaultPhase);
  return phase.subPhases;
}
