import Palette from "@src/config/theme/palette";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { Trans } from "react-i18next";
import styled from "styled-components";

interface SourceProps {
  sourceUrl: FocusAreaResource["sourceUrl"];
  source: FocusAreaResource["source"];
}
function Source({source, sourceUrl: {label, url}}: SourceProps) {
  return (
    <SourceContainer>
      <StyledLabel><Trans>{source}</Trans> in</StyledLabel>
      <StyledUrl href={url} target="_blank" no-referrer> <Trans>{label}</Trans></StyledUrl>
    </SourceContainer>
  );
}

const SourceContainer = styled.div``;
const StyledLabel = styled.div`
  color: ${Palette.bluishGrey};
`;
const StyledUrl = styled.a`
  color: ${Palette.bluePurple};
  cursor: pointer;
  display: table-cell;
  width: 200px;
  overflow: hidden;
`;

export default Source;