import React from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { useTranslation } from "react-i18next";
import {
  navigateToSegmentReport,
  PhaseDetails,
  PhaseSummaryForPlotBand,
  serializeSubphase,
  SubPhaseTenuresWithMeta
} from "./utils";
import { translate } from "@src/utils/i18next";
import { Tooltip } from "antd";

interface PlotBandLabelProps {
  summary: PhaseSummaryForPlotBand;
  name: string;
  isLabelOpen: Optional<boolean>;
  labelAnchor: Element;
  onSummaryClick: () => void;
}

export type PlotBandTextProps = PhaseDetails & {
  isLabelOpen: Optional<boolean>;
  onSummaryClick: () => void;

};

export function PlotBandLabel({ summary, name, isLabelOpen, labelAnchor, onSummaryClick }: PlotBandLabelProps) {
  const phaseSummary: PhaseDetails = { name,...summary};
  return createPortal(
    <PlotBandText {...phaseSummary} isLabelOpen={isLabelOpen} onSummaryClick={onSummaryClick}/>, labelAnchor
  );
}

export const PlotBandText: React.FC<PlotBandTextProps> = (props) => {
  // tslint:disable-next-line: no-shadowed-variable
  // eslint-disable-next-line max-len
  const handleSubphaseClick = (subPhaseWithMeta: SubPhaseTenuresWithMeta, phaseName: string, isSeparationPhase: boolean) => () => {
    const {startTenure: tenureStart, endTenure: tenureEnd, hasNoData } = subPhaseWithMeta;
    const subPhase = serializeSubphase(
      `${isSeparationPhase ? "Separation" : phaseName}: ${tenureStart}-${tenureEnd} months`)
    ;

    const mainSegment = {
      dimension: `${isSeparationPhase ? "dynamicSeparation" : "dynamicTenure"}`, segment: subPhase,
    };

    if (not(hasNoData)) {
      navigateToSegmentReport(mainSegment);
    }
  };

  // tslint:disable-next-line: no-shadowed-variable
  const renderSubphaseDetails = (
    subPhases: SubPhaseTenuresWithMeta[], phaseName: string, isSeparationPhase = false) => {
      const { t } = useTranslation();
    return subPhases.map((subPhaseTenure: SubPhaseTenuresWithMeta, index: number) => {
      // tslint:disable-next-line: no-shadowed-variable
      return (
        <SubPhaseTenure
          key={index}
          data-jest="subPhaseTenure"
          onClick={handleSubphaseClick(subPhaseTenure, phaseName, isSeparationPhase)}
          hasNoData={subPhaseTenure.hasNoData}
        >
          {subPhaseTenure.startTenure}
          {subPhaseTenure.endTenure === -1
            ? ` ${translate(t, "or more")}`
            : " - ".concat(subPhaseTenure.endTenure.toString())} {translate(t, "months")}
        </SubPhaseTenure>
      );
    });
  };

  const {
    name, startTenure, endTenure, numberOfSubphases, subPhaseTenures, isSeparation, onSummaryClick, isLabelOpen,
  } = props;
  const { t } = useTranslation();
  return (
    <LabelContainer>
      <Tooltip  title = {translate(t, name)}>
      <Name title={name} data-jest="phaseName">
        {translate(t, name)}
      </Name>
    </Tooltip>
      <SubText data-jest="phaseSubText">
        {
          isSeparation
            ? `${translate(t, "Final")} ${startTenure} ${translate(t, "months")}`
            : `${startTenure}${endTenure === -1 ? "+" : ` - ${endTenure}`} ${translate(t, "months")}`
        }
      </SubText>
      <br />
      <Details className="subphase_details" onClick={onSummaryClick} open={isLabelOpen || false}>
        <Summary data-jest="numberOfSubphases">
          {numberOfSubphases} {numberOfSubphases > 1 ? translate(t, "subphases") : translate(t, "subphase")}<br/>
        </Summary>
        {renderSubphaseDetails(subPhaseTenures, name, isSeparation)}
      </Details>
    </LabelContainer>
  );
};

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: -6px;
`;
const Name = styled.div`
  width: 130px;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-height: 18px;
`;

const SubText = styled.div`
  font-size: 13px;
`;

const Details = styled.details`
  font-size: 13px;
`;

const Summary = styled.summary`
  margin-top: 5px;
  margin-bottom: 8px;
  font-weight: 300;
  cursor: pointer;
`;

const SubPhaseTenure = styled.div<{hasNoData: boolean}>`
  font-weight: 600;
  font-size: 13px;
  padding-left: 14px;
  margin-bottom: 10px;
  cursor: ${props => props.hasNoData ? "default" : "pointer"};
`;

export default PlotBandLabel;
