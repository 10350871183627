import React from "react";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps } from "react-router";

import { State } from "@store/types";
// eslint-disable-next-line max-len
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { Map as ImmutableMap } from "immutable";
import { getParameters } from "@screens/Insights/parameters/store/selectors";
import { applyExistingParametersIfNeeded } from "@src/utils/parameters";
import {
  areEquals,
  isEmptyObject,
  isNotEmptyObject,
  isNotNullNorUndefined,
} from "hyphen-lib/dist/lang/Objects";
import { goTo, replaceLocation } from "@src/utils/locations";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { checkForConversationRight } from "@src/utils/rights";
import { Participation } from "hyphen-lib/dist/domain/common/Participation";
import { AccessList } from "hyphen-lib/dist/domain/access/AccessList";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { IndividualResultResource } from "hyphen-lib/dist/domain/resource/survey/report/IndividualResultResource";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { getExistingPage } from "@store/network/selectors";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { fetchIndividualResultsIfNeeded } from "@store/network/resource/IndividualResultResources";
import { isSurveyMultiSelectEnabled } from "hyphen-lib/dist/business/company/Companies";
import { getCompany, getRightsMatcher } from "@src/screens/Insights/store/selectors";
import { FetchError } from "@src/screens/Insights/errors/FetchError";
// eslint-disable-next-line max-len
import IndividualResultsSurveyReport, {
  IndividualResultsReportStateProps,
} from "../../components/IndividualResultsSurveyReport";
import { surveyReportActionCreators } from "../../store/actions";
import {
  focusAreaListActionCreators,
  FocusAreaListPageParameters,
} from "@src/screens/Insights/Actions/store/actions";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { fetchFocusAreasIfNeeded } from "@src/store/network/resource/ActionResources";
import {
  getAllFocusAreasFromReduxStore,
  getCurrentBottomDrawerView,
} from "@src/utils/FocusArea";
import BottomDrawer, { DrawerViews } from "@src/components/core/BottomDrawer";
import BottomDrawerStripedChild, {
  transformFocusAreasToStripStack,
} from "@src/components/core/BottomDrawer/StripedChild";
import { BottomDrawerMessages } from "@src/screens/Insights/BottomDrawer/constant";
import { hasFocusAreaRights } from "hyphen-lib/dist/business/focusArea/focusAreaResource";

interface MatchParams {
  id: string;
}

export interface OwnProps extends RouteComponentProps<MatchParams> {
  readonly surveyId: string;
  readonly participation: Participation;
  readonly surveyRole: AccessList.UserAccessRole;
  readonly surveyName: string;
  readonly postAndSurveySentiment?: boolean;
}

export interface IndividualResultsSurveyReportContainerStateProps {
  readonly parameters: ImmutableMap<string, any>;
  readonly canSendPrivateMessage: boolean;
  readonly individualResults: Store.Page<IndividualResultResource>;
  readonly loading: boolean;
  readonly isMultiSelectQuestionEnabled: boolean;
  readonly focusAreas?: FocusAreaResource[];
  readonly currentBottomDrawerView: DrawerViews;
  readonly hasFaRights: boolean;
}

export type IndividualResultsSurveyReportContainerActionProps =
  typeof surveyReportActionCreators & {
    readonly onFetchIndividualResults: (
      surveyId: string,
      queryString: Dictionary<any>
    ) => void;
    readonly onFetchFocusArea: (parameters: FocusAreaListPageParameters) => any;
    readonly onCreateFocusArea: (focusArea: Partial<FocusAreaResource>) => void;
  };

type Props = OwnProps &
  IndividualResultsSurveyReportContainerStateProps &
  IndividualResultsSurveyReportContainerActionProps &
  IndividualResultsReportStateProps &
  typeof surveyReportActionCreators;

interface IndividualResultsSurveyReportContainerState {
  totalFocusAreas: number;
}

export class IndividualResultsSurveyReportContainer extends React.Component
<Props, IndividualResultsSurveyReportContainerState> {

  constructor(props: Props) {
    super(props);
    this.state = {
      totalFocusAreas: 0
    };
  }
  
  // noinspection JSMethodCanBeStatic
  extractParametersFromQuery(props: Props) {
    return this.extractRelevantParametersForQuery(
      parseQueryString(props.location.search)
    );
  }

  // noinspection JSMethodCanBeStatic
  extractRelevantParametersForQuery(parameters: any) {
    const relevantParameters = { ...parameters };
    delete relevantParameters.viewOptions;
    return relevantParameters;
  }

  componentDidMount(): void {
    // we might need to apply persisted parameters
    const {
      parameters,
      location: { search },
    } = this.props;

    const existing = parseQueryString(search);
    const mergedParameters = applyExistingParametersIfNeeded(
      parameters.toJS(),
      existing,
      "filter.dimensions",
      { localKey: "filter.date", storeKey: "surveyReport.date" },
      "filter.questionTypes",
      "filter.categories",
      "filter.sentiments",
      "viewOptions.questionCategory",
      "viewOptions.comments"
    );

    if (
      isNotNullNorUndefined(mergedParameters) &&
      isNotEmptyObject(mergedParameters)
    ) {
      replaceLocation(mergedParameters);

      if (
        isEmptyObject(this.extractRelevantParametersForQuery(mergedParameters))
      ) {
        // do the fetch now, otherwise we will not do it later, as componentDidUpdate will not see
        // any relevant changes, and not do any fetch
        this.fetchSurveyIndividualResults();
      }
    } else {
      // fetch the individual results only if we will stay on this page,
      // otherwise it will be fetched anyway in componentDidUpdate
      this.fetchSurveyIndividualResults();
    }
    let totalFocusAreas = 0;
    if(isNotNullNorUndefined(this.props.focusAreas)) {
      totalFocusAreas = this.props.focusAreas.length;
    }

    this.setState({
      totalFocusAreas
    });
  }

  componentDidUpdate(prevProps: Props) {
    const oldParams = this.extractParametersFromQuery(prevProps);
    const newParams = this.extractParametersFromQuery(this.props);
    const { loading } = this.props;
    if (
      (this.props.surveyId !== prevProps.surveyId ||
        not(areEquals(oldParams, newParams)) ||
        loading) &&
      not(Store.Page.isInError(this.props.individualResults))
    ) {
      this.fetchSurveyIndividualResults();
    }

    if(this.props.hasFaRights) {
      this.props.onFetchFocusArea(this.fetchFocusAreaProps());
    }

    if(isNotNullNorUndefined(this.props.focusAreas) && isNotNullNorUndefined(prevProps.focusAreas)) {
      if(this.props.focusAreas.length > prevProps.focusAreas.length) {
        this.setState({
          totalFocusAreas: this.props.focusAreas.length
        });
      }
    }
  }

  fetchSurveyIndividualResults = () => {
    const queryParams = this.extractParametersFromQuery(this.props);
    this.props.onFetchIndividualResults(this.props.surveyId, queryParams);
  };

  fetchFocusAreaProps(): FocusAreaListPageParameters {
    return {
      page: {
        number: 1,
        size: 30,
      },
      filter: {
        withActionPlans: false,
      },
    };
  }

  render() {
    const {
      commentText,
      errorText,
      individualResults,
      isNetworkRequesting,
      isPrivateMessageModalVisible,
      participation,
      postId,
      privateMessageText,
      surveyId,
      voteId,
      canSendPrivateMessage,
      surveyName,
      isMultiSelectQuestionEnabled,
      focusAreas,
      onCreateFocusArea,
      hasFaRights,
      postAndSurveySentiment
    } = this.props;
    if (Store.Page.isInError(individualResults)) {
      return (
        <FetchError
          {...individualResults}
          resourceType={IndividualResultResource.TYPE}
        />
      );
    }

    return (
      <>
        {hasFaRights && <BottomDrawer
          key="segment-drawer"
          initialView={this.props.currentBottomDrawerView}
          title={`${this.state.totalFocusAreas === 0 ? "No" : this.state.totalFocusAreas} Focus Area Added`}
          footerButton={{
            display: true,
            text: "Go To Action Center",
            onClick: () => goTo("/actioncenter"),
          }}
        >
          <BottomDrawerStripedChild
            stripStack={transformFocusAreasToStripStack(focusAreas)}
            emptyStackMessage={BottomDrawerMessages.EMPTY_STACK_MESSAGE}
          />
        </BottomDrawer>}
        <IndividualResultsSurveyReport
          participation={participation}
          individualResults={individualResults}
          enabledFilters={[
            "date",
            "question",
            "category",
            "sentiment",
            "withCommentOnly",
            "dimension",
          ]}
          enabledCustomFilters={["addDimension"]}
          storeMappings={[
            { localKey: "filter.date", storeKey: "surveyReport.date" },
          ]}
          enabledViewOptions={["questionCategory", "comments"]}
          compareWithOptions={[]}
          commentText={commentText}
          errorText={errorText}
          isNetworkRequesting={isNetworkRequesting}
          isPrivateMessageModalVisible={isPrivateMessageModalVisible}
          postId={postId}
          privateMessageText={privateMessageText}
          surveyId={surveyId}
          voteId={voteId}
          canSendPrivateMessage={canSendPrivateMessage}
          sendPrivateMessage={this.props.sendPrivateMessage}
          updatePrivateMessageText={this.props.updatePrivateMessageText}
          clearPrivateMessageModal={this.props.clearPrivateMessageModal}
          togglePrivateMessageModal={this.props.togglePrivateMessageModal}
          surveyName={surveyName}
          isMultiSelectQuestionEnabled={isMultiSelectQuestionEnabled}
          onCreateFocusArea={onCreateFocusArea}
          postAndSurveySentiment={postAndSurveySentiment}
        />
      </>
    );
  }
}

const mapStateToProps: MapStateToProps<
  IndividualResultsSurveyReportContainerStateProps,
  OwnProps,
  State
> = (
  state: State,
  ownProps: OwnProps
): IndividualResultsSurveyReportContainerStateProps &
  IndividualResultsReportStateProps => {
  const {
    match: {
      params: { id: surveyId },
    },
  } = ownProps;
  const filter = parseQueryString(ownProps.location.search).filter;
  const individualResults = getExistingPage(
    state,
    IndividualResultResource.TYPE,
    IndividualResultResource.generateKey(surveyId, filter),
    PageFilter.noPagination()
  );
  const company = getCompany(state);
  let isMultiSelectQuestionEnabled = false;
  if (isNotNullNorUndefined(company)) {
    isMultiSelectQuestionEnabled = isSurveyMultiSelectEnabled(company);
  }
  const data = getAllFocusAreasFromReduxStore(state);
  const currentBottomDrawerView = getCurrentBottomDrawerView(state);
  const hasFaRights = hasFocusAreaRights(getRightsMatcher(state));
  const allowPrivateMessages = isNotNullNorUndefined(company) && company.allowPrivateMessages;

  return {
    individualResults,
    commentText: state.getIn(["insights_survey_report", "commentText"]),
    errorText: state.getIn(["insights_survey_report", "errorText"]),
    isNetworkRequesting: state.getIn([
      "insights_survey_report",
      "isSendMessageRequesting",
    ]),
    isPrivateMessageModalVisible: state.getIn([
      "insights_survey_report",
      "isPrivateMessageModalVisible",
    ]),
    postId: state.getIn(["insights_survey_report", "postId"]),
    privateMessageText: state.getIn([
      "insights_survey_report",
      "privateMessageText",
    ]),
    voteId: state.getIn(["insights_survey_report", "voteId"]),
    parameters: getParameters(state),
    loading: Store.Page.isNotFound(individualResults),
    isMultiSelectQuestionEnabled,
    canSendPrivateMessage: checkForConversationRight(ownProps.surveyRole, allowPrivateMessages),
    focusAreas: data,
    currentBottomDrawerView,
    hasFaRights
  };
};

const mapDispatchToProps: IndividualResultsSurveyReportContainerActionProps = {
  onFetchFocusArea: fetchFocusAreasIfNeeded,
  onCreateFocusArea: focusAreaListActionCreators.createFocusArea,
  onFetchIndividualResults: fetchIndividualResultsIfNeeded,
  ...surveyReportActionCreators,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndividualResultsSurveyReportContainer);
