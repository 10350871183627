import { Record, RecordOf } from "immutable";
import { FilterParameter } from "@src/utils/networks";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { PulsePollActionKeyType } from "../../components/Actions";
import {
  CleanFilterAction,
  ModifyFilterAction,
  ModalToggleAction,
  PulsePollListFiltersActionTypes,
  PulsePollListModalActionTypes,
  PulsePollListActionTypes
} from "./actions";

export interface PulsePollListStateProps {
  readonly filter: Optional<FilterParameter>;
  readonly pageSize: number;
  readonly isAreYouSureModalVisible: boolean;
  readonly modalPurpose: Optional<keyof ModalActionsKeyTypes>;
  readonly pollTemplateId: Optional<string>;
  readonly pollInstanceId: Optional<string>;
  readonly pollStatus: Optional<string>;
  readonly iterationClosingDate: Optional<Date>;
}
export type PulsePollListState = RecordOf<PulsePollListStateProps>;

const defaultValues: PulsePollListStateProps = {
  filter: Optional.empty(),
  pageSize: 10,
  isAreYouSureModalVisible: false,
  modalPurpose: null,
  pollTemplateId: null,
  pollInstanceId: null,
  pollStatus: null,
  iterationClosingDate: undefined,
};

export const pulsePollListStateFactory = Record<PulsePollListStateProps>(defaultValues);
const defaultState = pulsePollListStateFactory();

type Actions = ModifyFilterAction | CleanFilterAction | ModalToggleAction | any;
type ExcludedPulsePollActionKeyTypes = Omit<PulsePollActionKeyType, "seeReport" | "edit">;
export type ModalActionsKeyTypes = ExcludedPulsePollActionKeyTypes;

export function pulsePollListReducers(
  state: PulsePollListState = defaultState,
  action: Actions
): PulsePollListState {
  switch (action.type) {
    case PulsePollListFiltersActionTypes.MODIFY_FILTER:
      return state.set("filter", action.payload);
    case PulsePollListFiltersActionTypes.CLEAN_FILTER:
      return state.set("filter", Optional.empty());
    case PulsePollListModalActionTypes.TOGGLE_MODAL:
      const modalState = state.get("isAreYouSureModalVisible");
      return state.merge({
        isAreYouSureModalVisible: !modalState,
        ...action.payload,
      });
    case PulsePollListActionTypes.CHANGE_PULSE_POLL_STATUS:
      const { options } = action;
      if (isNotNullNorUndefined(options) && options.deactivateAndClose) {
        return state.merge({
          modalPurpose: "deactivateAndclose",
        });
      }
      return state;
    case PulsePollListModalActionTypes.CLOSE_MODAL:
      return state.merge({
        isAreYouSureModalVisible: false,
        modalPurpose: null,
        pollInstanceId: null,
        pollTemplateId: null,
      });
    default:
      return state;
  }
}
