import { default as networkActionCreators } from "@store/network/actions";
import { QuestionInfoResource } from "hyphen-lib/dist/domain/resource/QuestionInfoResource";
import { createRequest } from "@src/utils/networks";

export function fetchQuestionInfoIfNeeded(surveyId: string, questionId: string) {
  return networkActionCreators.fetchElementIfNeeded({
    id: questionId,
    type: QuestionInfoResource.TYPE,
    request: createRequest(`/surveys/${surveyId}/questionInfos/${questionId}`),
  });
}
