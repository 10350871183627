import { getRightsMatcher } from "@screens/Insights/store/selectors";
import { Rights } from "@hyphen-lib/business/auth/Rights";
import { State } from "@store/types";
import { AccessList } from "hyphen-lib/dist/domain/access/AccessList";
import { RightsMatcher } from "@hyphen-lib/business/auth/Auth";

export function checkForActionPlanCreationRight(state: State): boolean {
  return getRightsMatcher(state).hasRight(Rights.Action.CREATE);
}

export function hasAdminAccess(role: AccessList.UserAccessRole | null): boolean {
  return role === AccessList.UserAccessRole.ADMIN;
}

export function checkForConversationRight(role: AccessList.UserAccessRole, 
  appSettingsAllowPrivateMessages: boolean): boolean {
  return appSettingsAllowPrivateMessages && (role === AccessList.UserAccessRole.ADMIN
    || role === AccessList.UserAccessRole.CONVERSATION);
}

export function checkForSurveyReportRight(rightsMatcher: RightsMatcher, reportType: string): boolean {
  switch (reportType) {
    case "overview":
      return rightsMatcher.hasRight(Rights.Survey.OVERVIEW);
    case "heatmap":
      return rightsMatcher.hasRight(Rights.Survey.HEATMAP);
    case "categories":
      return rightsMatcher.hasRight(Rights.Survey.CATEGORY_REPORT);
    case "questions":
      return rightsMatcher.hasRight(Rights.Survey.QUESTIONS);
    case "topics":
      return rightsMatcher.hasRight(Rights.Survey.TOPICS);
    case "comments":
      return rightsMatcher.hasRight(Rights.Survey.COMMENTS);
    case "individualResults":
      return rightsMatcher.hasRight(Rights.Survey.INDIVIDUAL);
    case "participation":
      return rightsMatcher.hasRight(Rights.Survey.PARTICIPATION);
    default:
      return false;
  }
}
