// @ts-nocheck
import React from "react";
export default function ArrowDoubleDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      viewBox="0 0 8 8"
    >
      <path
        id="svg_1"
        // eslint-disable-next-line max-len
        d="m2.857,3.586a1.609,1.609 0 0 1 -0.077,-0.07l-2.61,-2.549a0.54,0.54 0 0 1 0.028,-0.812a0.66,0.66 0 0 1 0.43,-0.155c0.16,0 0.48,0.215 0.651,0.308c0.833,0.448 1.36,0.66 2.721,0.66c1.36,0 1.888,-0.212 2.72,-0.66c0.174,-0.093 0.494,-0.308 0.653,-0.308a0.66,0.66 0 0 1 0.43,0.155a0.54,0.54 0 0 1 0.028,0.812l-2.61,2.55c-0.025,0.023 -0.05,0.047 -0.077,0.07a1.753,1.753 0 0 1 -1.144,0.413c-0.41,0 -0.82,-0.137 -1.143,-0.414z"
        fillRule="nonzero"
        fill="#AAB9CE"
      />
      <path
        id="svg_3"
        // eslint-disable-next-line max-len
        d="m2.857,7.586019a1.609,1.609 0 0 1 -0.077,-0.07l-2.61,-2.549a0.54,0.54 0 0 1 0.028,-0.812a0.66,0.66 0 0 1 0.43,-0.155c0.16,0 0.48,0.215 0.651,0.308c0.833,0.448 1.36,0.66 2.721,0.66c1.36,0 1.888,-0.212 2.72,-0.66c0.174,-0.093 0.494,-0.308 0.653,-0.308a0.66,0.66 0 0 1 0.43,0.155a0.54,0.54 0 0 1 0.028,0.812l-2.61,2.55c-0.025,0.023 -0.05,0.047 -0.077,0.07a1.753,1.753 0 0 1 -1.144,0.413c-0.41,0 -0.82,-0.137 -1.143,-0.414z"
        fillRule="nonzero"
        fill="#AAB9CE"
      />
    </svg>
  );
}
