// @ts-nocheck
import React from "react";
export function DropDownSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <defs>
        <path
          id="dropDownA"
          // eslint-disable-next-line max-len
          d="M7.857 10.586a1.609 1.609 0 0 1-.077-.07L5.17 7.967a.54.54 0 0 1 .028-.812.66.66 0 0 1 .43-.155c.16 0 .48.215.651.308.833.448 1.36.66 2.721.66 1.36 0 1.888-.212 2.72-.66.173-.093.493-.308.652-.308a.66.66 0 0 1 .43.155.54.54 0 0 1 .028.812l-2.61 2.55c-.025.023-.05.047-.077.07A1.753 1.753 0 0 1 9 11c-.41 0-.82-.137-1.143-.414z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="dropDownB" fill="#fff">
          <use xlinkHref="#dropDownA" />
        </mask>
        <use fill="#AAB9CE" fillRule="nonzero" xlinkHref="#dropDownA" />
        <g fill="#AAB9CE" mask="url(#dropDownB)">
          <path d="M0 0h18v18H0z" />
        </g>
      </g>
    </svg>
  );
}
