import React from "react";
import styled from "styled-components";
// @ts-ignore
import hareNiemeyer from "hare-niemeyer";

import { Sentiment } from "@hyphen-lib/domain/common/ComputationTypes";
import { SentimentValues } from "@screens/Insights/Survey/components/Comments/types";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import SentimentBar from "./components/SentimentBar";
import { DynamicRowProps } from "@components/core/DynamicRowsTable/withDynamicRows";

interface Props {
  sentiment: SentimentValues;
}

export const countPercentages = (sentiment: SentimentValues) => {
  const oneHundredPercent = 100;
  const percentages = hareNiemeyer(sentiment, oneHundredPercent);
  const result: Dictionary<number> = {};

  Object.keys(percentages).forEach((key) => {
    result[key] = isNaN(percentages[key]) ? 0 : percentages[key];
  });

  return result;
};

const TopicsSentimentChart = ({ row,  index }  : DynamicRowProps<Props>) => {
  const { sentiment } = row;
  
  const commentsInTotal =
  sentiment[Sentiment.POSITIVE] +
    sentiment[Sentiment.NEUTRAL] +
    sentiment[Sentiment.NEGATIVE];
  const possibleSentiments = [
    Sentiment.POSITIVE,
    Sentiment.NEUTRAL,
    Sentiment.NEGATIVE,
  ];

  if (commentsInTotal === 0) {
    return null;
  }
  const percentages = countPercentages(sentiment);
  return (
    <SentimentChart data-jest = {`sentimentCart-${index}`}>
      {possibleSentiments.map((sentimentKey: Sentiment) =>
        (isNotNullNorUndefined(percentages[sentimentKey]) && percentages[sentimentKey] !== 0) && (
          <SentimentBar
            key={sentimentKey}
            sentiment={sentimentKey}
            barPercentRounded={percentages[sentimentKey]}
            barPercent={
              Math.round((sentiment[sentimentKey] * 1000) / commentsInTotal) / 10
            }
            barValue={sentiment[sentimentKey]}
          />
        ))
      }
    </SentimentChart>
  );
};

const SentimentChart = styled.div`
  display: flex;
  height: 35px;
  margin-top: 32px;
  margin-bottom: 32px;
`;

// const StyledHoverCell = styled.td`
//   background-color: ${Palette.lightLightBlue};
//   position: relative;
// `;

// const RightIcon = styled(Icon)`
//   font-size: 12px;
// `;
// const StyledLink = styled.span`
//   cursor: pointer;
// `;

// const SeeDetails = styled.div`
//   color: ${Palette.darkModerateBlue};
//   font-size: 14px;
//   text-align: right;
//   cursor: pointer;
//   line-height: 30px;
// `;

export default TopicsSentimentChart;
