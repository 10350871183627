import React from "react";
import styled from "styled-components";

import { CellContainer, CellInnerContainer } from "@screens/Insights/SurveyList/components/cells/Cells";
import { DynamicRowProps } from "@components/core/DynamicRowsTable/withDynamicRows";
import Palette from "@src/config/theme/palette";

interface CategoryCellProps {
  _id: string;
}

export function CategoryCell ({ row }: DynamicRowProps<CategoryCellProps>) {
  return (
    <CellContainer align="flex-start" justifyContent="flex-start">
      <CellInnerContainer padding={[24, 0]} width="100%">
        <CategoryLabel>{row._id}</CategoryLabel>
      </CellInnerContainer>
    </CellContainer>
  );
}

const CategoryLabel = styled.span`
  font-family: Lato;
  font-size: 14px;
  color: ${Palette.darkBlueGrey};
`;
