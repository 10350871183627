import React, { FC } from "react";
import { TrendResource } from "hyphen-lib/dist/domain/resource/survey/report/TrendResource";
import { Heading } from "@src/components/core/Typography";
import { TrendLineChart, TrendLineChartData } from "@src/components/core/TrendLineChart";
import { Summary } from "../../../OverviewReport/components/Overview/index";
import {
  generateTrendLineData,
  getMonthsToDisplay,
  labelFormatter
} from "../../../../trendLineUtils";
import toolTipOptions from "../../../ToolTip";
import { Trans } from "react-i18next";

interface SurveyTrendLineStateProps {
  trend: TrendResource.QuestionTrends[];
}

interface SurveyTrendLineProps {
  questionId: string;
}

type ComponentProps = SurveyTrendLineStateProps & SurveyTrendLineProps;

const QuestionTrendLines: FC<ComponentProps> = (props) => {
  const { trend, questionId } = props;
  const trendData: TrendLineChartData[] = generateTrendLineData(trend, questionId);
  const minMonths = getMonthsToDisplay(trendData.length);
  if (trendData.length < 2) {
    return null;
  }
  return (
    <>
      <Heading size="small" weight={"bold"} data-jest="linked-surveys-heading" translate="yes">
        Trend
      </Heading>
      <Summary data-cy="">
        <Trans>Favorability score across linked surveys</Trans>
      </Summary>
      <br />
      <TrendLineChart
        minTicks={minMonths}
        data={trendData}
        toolTipOptions={toolTipOptions}
        xAxisLabelsOptions={{rotation: -45}}
        yAxis={{labelFormatter}}
      />
      <Summary data-cy="" centered>
        <Trans>Data points with not enough data are not displayed 
          in the graph to respect anonymity rules.</Trans>
      </Summary>
    </>
  );
};

export default QuestionTrendLines;
