import React, { ChangeEvent } from "react";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { SearchBar } from "@components/core/SearchBar";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { debounce } from "lodash";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import { replaceLocation } from "@src/utils/locations";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { getProperty } from "hyphen-lib/dist/lang/Objects";

interface TopicSearchBarSectionProps {
  readonly placeholder?: string;
  readonly defaultValue: string;
}

type Props = TopicSearchBarSectionProps & RouteComponentProps;
class TopicsSearchBar extends React.Component<Props> {
  updateQueryParams = debounce((param: any) => {
    const { location } = this.props;
    const filter = getProperty(parseQueryString(location.search), "filter", {});
    if (isStringAndNotEmpty(param)) {
      filter.freeText = param;
    } else {
      delete filter.freeText;
    }
    replaceLocation({filter});
  }, 500);

  handleSearchBarChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.updateQueryParams(e.target.value);
  };

  render() {
    const { placeholder, defaultValue } = this.props;
    return (
      <Container>
        <SearchBar
          datacy="survey_topics_search_box"
          onChange={this.handleSearchBarChange}
          placeholder={placeholder}
          defaultValue={defaultValue}
        />
      </Container>);
  }
};

const Container = styled.div`
  padding: 24px 32px;
  background: ${Palette.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export default withRouter(TopicsSearchBar);