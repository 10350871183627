import { default as networkActionCreators } from "@store/network/actions";
import { createRequest } from "@src/utils/networks";
import { appendQueryString, generateQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { CommentsOverviewResource } from "hyphen-lib/dist/domain/resource/report/CommentsOverviewResource";
import { Optional } from "hyphen-lib/dist/lang/Optionals";

export function fetchCommentsOverviewIfNeeded(surveyId: string,
  questionId: Optional<string>,
  queryString: Dictionary<any>) {

  return networkActionCreators.fetchElementIfNeeded({
    id: CommentsOverviewResource.generateId(
      { surveyId, questionId: Optional.extract(questionId) },
      queryString.filter
    ),
    type: CommentsOverviewResource.TYPE,
    request: createRequest(
      appendQueryString(
        `/surveys/${surveyId}/reports/comments/overview`,
        generateQueryString(queryString)
      )
    ),
  });
}

export function fetchPollCommentsOverviewIfNeeded(pollId: string,
  questionId: Optional<string>,
  queryString: Dictionary<any>
) {
  return networkActionCreators.fetchElementIfNeeded({
    id: CommentsOverviewResource.generateId(
      { pollId, questionId: Optional.extract(questionId) },
      queryString.filter
    ),
    type: CommentsOverviewResource.TYPE,
    request: createRequest(
      appendQueryString(
        `/pulsepolls/${pollId}/reports/comments/overview`,
        generateQueryString(queryString)
      )
    ),
  });
}
