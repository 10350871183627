// core react
import React from "react";
import { Trans } from "react-i18next";
// styled components
import styled from "styled-components";

// themes
import Palette from "@src/config/theme/palette";

// types
import { Participation as ParticipationType} from "@hyphen-lib/domain/common/Participation";

// core components
import { Progress } from "@components/core/Progress";

// utils
import { calculatePercentage } from "@hyphen-lib/util/math/Math";

export default function Participation(props: ParticipationType) {
    
  const { total, completed } = props;

  const percentage = total ? calculatePercentage(
    completed,
    total
  ) : 0;
  if (!total || !completed) {
    return <></>;
  }
  return (
    <ParticipationContainer>
              <Trans>Participation</Trans>:
      <ParticipationProgress>
        <Label>
          {percentage}% ({completed}/ {total})
        </Label>
        <ProgressContainer>
          <Progress percent={percentage}/>
        </ProgressContainer>
      </ParticipationProgress>
    </ParticipationContainer>
          
  );
}

const ParticipationContainer = styled.div``;
const ParticipationProgress = styled.div`
  margin-top: 4px;
  width: 260px;
  display: flex;
  flex-direction: row;
`;

export const Label = styled.div`
  width: 130px !important;
  font-family: Lato, sans-serif;
  color: ${Palette.veryDarkBlueGrey};
`;

const ProgressContainer = styled.div`
  width: 130px !important;
`;