import Palette from "@src/config/theme/palette";
import { Col, Menu } from "antd";
import MenuItem from "antd/lib/menu/MenuItem";
import { addMonths, compareDates, utcNow } from "hyphen-lib/dist/lang/Dates";
import styled from "styled-components";
import Row, { RowProps } from "antd/lib/row";
import React from "react";
import Title from "./Title";
import Source from "./Source";
import DateAdded from "./DateAdded";
import Actions from "./Actions";
import KebabMenu from "@src/components/core/Menus/KebabMenu";
import { FocusAreaResource } from "@hyphen-lib/domain/resource/focus/FocusAreaResource";
import { ColProps } from "antd/lib/col";
import { MenuProps } from "antd/lib/menu";
import { ActionPlanTemplateResource } from "hyphen-lib/dist/domain/resource/action/ActionPlanTemplateResource";
import Recommendations from "./Recommendations";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import { Trans } from "react-i18next";


interface FocusAreaState {
  openKebabMenu: boolean;
  showMoreCount: number;
}

export interface FocusAreaProp { 
  focusArea: FocusAreaResource;
  onDefineActionPlanClick: () => void;
  onCreatedActionPlansClick: () => void;
  suggestedActionPlans: ActionPlanTemplateResource[];
  showRecommendedActionPlans: boolean;
  activeKebabMenuRow: { focusAreaId: string };
  onDeleteFocusArea: () => void;
  onSelectActionPlanTemplate: (action: ActionPlanTemplateResource, focusAreaId: string) => void;
  createActionPlan: () => void;
  closeKebabMenu: () => void;
  toggleKebabMenu: () => void;
};

class FocusArea extends React.Component<FocusAreaProp, FocusAreaState> {
  state= {
    openKebabMenu: false,
    showRecommendedActionPlans: false,
    showMoreCount: 0,
  };

  componentDidUpdate(prevProps: FocusAreaProp) {
    const { activeKebabMenuRow } = this.props;
    if (prevProps.activeKebabMenuRow !== activeKebabMenuRow) {
      if (
        isStringAndNotEmpty(activeKebabMenuRow.focusAreaId) && 
        activeKebabMenuRow.focusAreaId === this.props.focusArea._id
      ) {
        this.setState({ openKebabMenu: true });
      } else {
        this.setState({ openKebabMenu: false });
      }
    }
  }
  
  static readonly defaultProps = {
    suggestedActionPlans: new Array(7) as ActionPlanTemplateResource[],
  };

  onShowMore = (increment: number) => () => {
    this.setState(prev => ({...prev, showMoreCount: prev.showMoreCount + increment }));
  };

  onCloseRecommendation = () => {
    const { onDefineActionPlanClick } = this.props;
    this.setState({showMoreCount: 0}, onDefineActionPlanClick);
  };

  computeMoreToShow = () => {
    const { showMoreCount } = this.state;
    const maxShowMoreCount = Math.floor(this.props.suggestedActionPlans.length / 3) - 1;
    const lastRowCardCount = this.props.suggestedActionPlans.length % 3;
    const { showRecommendedActionPlans, suggestedActionPlans } = this.props;
    const hasMoreToShow = showMoreCount <  (maxShowMoreCount +  Math.min(lastRowCardCount, 1));
    
    if (!showRecommendedActionPlans || !hasMoreToShow) return 0;
    
    const totalToShow = suggestedActionPlans.length;
    const countOfShown = (showMoreCount + 1) * 3;
    const countToShow = totalToShow - countOfShown;
    
    return countToShow > 3 ? 3 : countToShow; 
  };

  render() {
    const {
      focusArea,
      onDefineActionPlanClick,
      showRecommendedActionPlans,
      onCreatedActionPlansClick,
      onDeleteFocusArea,
      onSelectActionPlanTemplate,
      toggleKebabMenu,
      closeKebabMenu,
    } = this.props;

    const {
      title,
      source,
      sourceUrl,
      focusAreaType,
      actionPlans,
      createdAt,
    } = focusArea;
    const maxShowMoreCount = Math.floor(this.props.suggestedActionPlans.length / 3) - 1;
    const lastRowCardCount = this.props.suggestedActionPlans.length % 3;
    const { showMoreCount, openKebabMenu } = this.state;
    const rowActiveThreshold = addMonths(utcNow(), -3);
    const hasActionPlanCreated = isNotEmptyArray(actionPlans);
    const hasExceededThresholdDate = compareDates(new Date(createdAt), rowActiveThreshold) >= 0;
    const active = not(hasExceededThresholdDate) || hasActionPlanCreated;
    const kebabMenu = (
      <StyledMenu $hasNoActionPlans={actionPlans.length > 0} data-cy="focusAreaOption">
          {
            actionPlans.length > 0 &&
            <CreateMenuItem
              data-cy="createActionPlanFromFocusArea"
              onClick={this.props.createActionPlan}
            >
              <Trans>Create another Action Plan</Trans>
            </CreateMenuItem>
          }
          <DeleteMenuItem onClick={onDeleteFocusArea}><Trans>Delete Focus Area</Trans></DeleteMenuItem>
      </StyledMenu>
    );
    
    const hasMoreToShow = showMoreCount <  (maxShowMoreCount +  Math.min(lastRowCardCount, 1));
    let focusAreaTypeString: string = focusAreaType;
    if (focusArea?.filters?.filter) {
      let finalString = JSON.stringify(focusArea?.filters?.filter).replaceAll("{" , " ( ");
      finalString = finalString.replaceAll("[" , " ");
      finalString = finalString.replaceAll("]" , " ");
      finalString = finalString.replaceAll("}" , " )");
      finalString = finalString.replaceAll(/['"]+/g, " ");
      focusAreaTypeString = `filtered  ${finalString} `;
    };
    return (
      <>
        <FocusAreaRow 
          data-cy="focusAreaTitle" 
          $active={Boolean(active)} 
          onClick={closeKebabMenu}
        >
            <Col span={8}>
              <Title title={title} focusAreaTypeText={focusAreaTypeString} />
            </Col>
          <Col span={6}><Source sourceUrl={sourceUrl} source={source}/></Col>
          <Col span={4}><DateAdded dateAdded={createdAt} active={hasExceededThresholdDate}/></Col>
          <ActionsCol span={6} $hasActionPlans={actionPlans.length > 0}>
            <Actions
              actionPlans={actionPlans.map(a => a ? "" : a!.action)}
              onDefineActionPlanClick={onDefineActionPlanClick}
              disable={showRecommendedActionPlans}
              onCreatedActionPlansClick={onCreatedActionPlansClick}
            />
            <KebabContainer data-cy="focusAreaKebabMenu">
              <KebabMenu onClick={toggleKebabMenu} dotSeparation={3}/>
            </KebabContainer>
            {openKebabMenu && kebabMenu}
          </ActionsCol>
        </FocusAreaRow>
        {showRecommendedActionPlans && (
          <Recommendations 
            focusAreaId={focusArea._id}
            onCloseRecommendation={this.onCloseRecommendation}
            showMoreCount={showMoreCount}
            maxShowMoreCount={maxShowMoreCount}
            lastRowCardCount={lastRowCardCount}
            hasMoreToShow={hasMoreToShow}
            onShowMore={this.onShowMore}
            onCreateActionPlan={this.props.createActionPlan}
            computeMoreToShow={this.computeMoreToShow}
            suggestedActionPlans={this.props.suggestedActionPlans}
            onSelectActionPlanTemplate={onSelectActionPlanTemplate}
          />
        )}
      </>
    );
  }
}


const ActionsCol = styled(Col)<ColProps & {$hasActionPlans: boolean}>`
  margin-top: 6px;
  position: relative;
  padding-left: ${props => props.$hasActionPlans ? "50px" : "25px"};
`;

const KebabContainer = styled.span`
  vertical-align: middle;
  display: inline-block;
  padding-top: 5px;
`;
const CreateMenuItem = styled(MenuItem)`
  color: ${Palette.veryDarkBlueGrey};
`;
const DeleteMenuItem = styled(MenuItem)`
  color: ${Palette.darkPink};
  position: relative;
  top: -15px;
`;

const StyledMenu = styled(Menu)<MenuProps & {$hasNoActionPlans: boolean}>`
  position: absolute;
  zIndex: 2;
  top: -15px;
  height: ${props => props.$hasNoActionPlans ? "80px" : "40px"};
  width: 200px;
  background-color: #FFFFFF;
  box-shadow: 0 4px 8px 0 rgba(198,214,223,0.3);
`;

const FocusAreaRow = styled(Row)<RowProps & {$active: boolean}>`
  position: relative;
  margin-top: 16px;
  padding-top: 28px;
  padding-bottom: 28px;
  ${props => props.$active ? "" : `border: 1px solid ${Palette.pattensBlue}`};
  border-radius: 3px;
  background-color: ${props => props.$active ? Palette.paleGrey: "#fff"};
  box-shadow: 0 4px 8px 0 rgba(198,214,223,${props => props.$active ? "0.3" : "0.15" });
`;

export default FocusArea;