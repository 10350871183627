import Palette from "@src/config/theme/palette";
import { formatDate } from "hyphen-lib/dist/lang/Dates";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { translate } from "@src/utils/i18next";
export interface DateAddedProps {
  dateAdded: Date;
  active: boolean;
}

function DateAdded({dateAdded, active}: DateAddedProps) {
  const { t } = useTranslation();
  if (!active) {
    return (
    <DateAddedContainer>
      <StyledDate active={false}> {"> 3 months ago"} </StyledDate>
    </DateAddedContainer>
    );
  }
  return (
    <DateAddedContainer >
      <StyledDate active>{`${translate(t, "Added")} ${formatDate(new Date(dateAdded))}`}</StyledDate>
    </DateAddedContainer>
  );
}

const DateAddedContainer = styled.div`
  position: relative;
  top: 16px;
`;

const StyledDate = styled.div<{active?: boolean}>`
  color: ${props => props.active ? Palette.bluishGrey: Palette.darkPink};
  font-size: 12px;
`;


export default DateAdded;