import styled from "styled-components";
import { getOr } from "hyphen-lib/dist/lang/Objects";

export const CellContainer = styled.div<{
  align: "center" | "flex-start";
  justifyContent: "flex-start" | "flex-end";
  marginLeft?: number;
  marginRight?: number;
}>`
  display: flex;
  align-items: ${(props) => props.align};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-end"};
  width: 100%;
  ${(props) => (props.marginLeft ? `margin-left: ${props.marginLeft}px;` : "")};
  ${(props) =>
    props.marginRight ? `margin-right: ${props.marginRight}px;` : ""};
`;

export const CellInnerContainer = styled.div<{
  margin?: number;
  padding?: number[];
  width: string;
  textAlign?: string;
}>`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width};
  margin-top: ${(props) => getOr(props.margin, 0 as number) + "px"};
  padding: ${(props) => mapNumbersToPixels(getOr(props.padding, []))};
  position: relative;
  justify-content: space-evenly;
  text-align:${(props) => getOr(props.textAlign, "left")}

`;

function mapNumbersToPixels(values: number[]) {
  return values.map((value) => value + "px").join(" ");
}
