import React from "react";
import ContainerCard from "@components/core/ContainerCard";
import { Progress } from "antd";
import Palette from "@src/config/theme/palette";
import { withRouter, RouteComponentProps } from "react-router";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { State } from "@src/store/types";
import styled from "styled-components";
import { UsersAndDimensionsStateProps } from "../../store/reducers";
import { UsersAndDimensionsActionTypes } from "../../store/actions";

interface ReducerProps {
  readonly uploadStatus: UsersAndDimensionsStateProps["uploadStatus"];
  readonly fileName: UsersAndDimensionsStateProps["fileName"];
  readonly uploadProgress: UsersAndDimensionsStateProps["uploadProgress"];
}

class AddUsersProgress extends React.Component<ReducerProps & RouteComponentProps> {
  render() {
    const {fileName, uploadProgress, uploadStatus} = this.props;
    if (uploadStatus === UsersAndDimensionsActionTypes.UPLOAD_USERS_CSV_SUCCESS) {
      return <Redirect to="/users/add/success" />;
    }
    return (
      <>
        <CustomContainerCard>
          <FileNameContainer>
            <Importing>Importing</Importing>&nbsp;<FileName>{fileName}</FileName>
          </FileNameContainer>
          <Progress width={160} type="circle" percent={uploadProgress * 100} strokeColor={Palette.aquaBlue} />
        </CustomContainerCard>
      </>
    );
  }
}

function mapStateToProps(state: State): ReducerProps {
  return {
    uploadStatus: state.getIn(["insights_users_and_dimensions", "uploadStatus"]),
    fileName: state.getIn(["insights_users_and_dimensions", "fileName"]),
    uploadProgress: state.getIn(["insights_users_and_dimensions", "uploadProgress"]),
  };
}

export default withRouter(
  connect(
    mapStateToProps, {}
  )(AddUsersProgress)
);

const CustomContainerCard = styled(ContainerCard)`
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const FileNameContainer = styled.div`
  margin-bottom: 60px;
  font-size: 24px;
`;

const Importing = styled.span`
  color: ${Palette.lightGreyBlue};
`;

const FileName = styled.span`
  color: ${Palette.darkBlueGrey};
`;
