import { MultiSelectFilter } from "@screens/Insights/components/Filters/base/MultiSelectFilter";
import { BaseFilterProps } from "@screens/Insights/components/FiltersContent";
import { applyDefault } from "@src/utils/Currier";
import { User } from "hyphen-lib/dist/domain/User";
import { MonoSelectFilter } from "@screens/Insights/components/Filters/base/MonoSelectFilter";
import { parseBoolean } from "hyphen-lib/dist/lang/Booleans";
import { mapOr } from "hyphen-lib/dist/lang/Objects";

export interface RoleFilterProps extends BaseFilterProps<string[]> {
  roles: string[];
}

export function RoleFilter({ label, roles, ...rest }: RoleFilterProps) {
  return (
    <MultiSelectFilter
      label={label}
      availableValues={roles.map(role => ({
        value: role,
        label: role,
      }))}
      {...rest}
    />
  );
}

export const UserStatusFilter =
  applyDefault(
    MultiSelectFilter,
    {
      trans: true,
      availableValues: [
        { value: User.Statuses.CANDIDATE, label: "Candidate"},
        { value: User.Statuses.EMPLOYEE, label: "Employee"},
        { value: User.Statuses.TERMINATED, label: "Terminated"},
      ],
    }
  );

export const HasInsightAccessFilter =
  applyDefault(
    MonoSelectFilter,
    {
      trans: true,
      availableValues: [
        { value: "true", label: "Users with Engage Insights access only"},
        { value: "false", label: "Users without Engage Insights access only"},
      ],
      mapperOut: (val: any) => parseBoolean(val),
      mapperIn: (val?: boolean) => mapOr(val, b => b.toString(), undefined),
    }
  );

export const HasCustomScopeFilter =
  applyDefault(
    MonoSelectFilter,
    {
      trans: true,
      availableValues: [
        { value: "true", label: "Users with custom scope only"},
        { value: "false", label: "Users without custom scope only"},
      ],
      mapperOut: (val: any) => parseBoolean(val),
      mapperIn: (val?: boolean) => mapOr(val, b => b.toString(), undefined),
    }
  );
