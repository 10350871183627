import React from "react";
import styled from "styled-components";

import { CellContainer, CellInnerContainer } from "@screens/Insights/SurveyList/components/cells/Cells";
import { FavorabilityScore } from "@components/core/FavorabilityScore";
import { StackedBarGraph } from "@components/core/StackedBarGraph";
import Palette from "@src/config/theme/palette";
import { isNullOrUndefined, getOr, isNotNullNorUndefined } from "@hyphen-lib/lang/Objects";
import { DistributionResult } from "hyphen-lib/dist/domain/resource/report/common/DistributionResult";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { adjustElementsAndPercentages } from "@src/utils/Graphs";
import { ScoreType } from "hyphen-lib/dist/domain/resource/report/common/Score";

interface FavorabilityCellProps {
  row: {
    favorability: Optional<number>;
    distribution?: DistributionResult;
    numberOfVotes: number;
  };
  barGraphTheme:  {
    label: string;
    backgroundColor: string;
    fontColor?: Palette;
  }[];
}

export function FavorabilityCell({ row: 
    { favorability, distribution , numberOfVotes}, 
    barGraphTheme }: FavorabilityCellProps) {
  const renderChoicesGraph = (choicesData: DistributionResult) => {
    const { pieceToAdd } = adjustElementsAndPercentages(choicesData);

    const data = choicesData.map(({ total, percentage }, index) => {
      const { backgroundColor, fontColor, label } = barGraphTheme[index];
      return ({
        value: total,
        percentage: getOr(percentage, 0),
        percentageWidth: isNotNullNorUndefined(percentage) && percentage > 0 ?
          percentage + pieceToAdd :
          0,
        backgroundColor,
        fontColor,
        label,
      });
    });

    return <StackedBarGraph data={data} hideSingleDigitLabel={true} numberOfVotes ={numberOfVotes}/>;
  };

  return (
    <CellContainer align="center" justifyContent="flex-start">
      <CellInnerContainer padding={[24, 0]} width="100%">
        <FavorabilityContainer>
          <FavorabilityScore favorability={favorability} scoreType={ScoreType.FAVORABILITY}/>
          {!isNullOrUndefined(distribution) && renderChoicesGraph(distribution)}
        </FavorabilityContainer>
      </CellInnerContainer>
    </CellContainer>
  );
}

const FavorabilityContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
