import React from "react";
import styled from "styled-components";

import { ActionHighlight } from "@hyphen-lib/domain/resource/report/common/Highlights";
import { compareDates, utcNow, addDays } from "@hyphen-lib/lang/Dates";
import { isString } from "@hyphen-lib/lang/Strings";
import Palette from "@src/config/theme/palette";
import Attention from "@components/core/svg/Attention";
import ClockArcSmall from "@components/core/svg/ClockArcSmall";
import ClockArcBig from "@components/core/svg/ClockArcBig";
import ClockArcMedium from "@components/core/svg/ClockArcMedium";

import { Breadcrumb, goTo } from "@src/utils/locations";

interface Props {
  data: ActionHighlight;
}

const goToLink = (link: string) => {
  return () => goTo(link, Breadcrumb.stack("Dashboard"));
};

const ActionPlan = ({ data: { id, action, dueDate } }: Props) => {
  return (
    <LinkDiv onClick={goToLink(`/actioncenter/actions/edit/${id}`)}>
      <Container>
        <StyledParagraph>{action}</StyledParagraph>
        {mapActionToIconStyle(dueDate)}
      </Container>
    </LinkDiv>
  );
};

function mapActionToIconStyle(dueDate: Date) {
  const dueDateObject = isString(dueDate) ? new Date(dueDate) : dueDate;

  if (compareDates(utcNow(), dueDateObject) === 1) {
    return <Attention /> ;
  }

  if (compareDates(addDays(utcNow(), 1), dueDateObject) === 1) {
    return <ClockArcSmall stroke={Palette.darkPink} />;
  }

  if (compareDates(addDays(utcNow(), 7), dueDateObject) === 1) {
    return <ClockArcMedium stroke="#F1A300" />;
  }

  return <ClockArcBig stroke={Palette.greenBlue} />;

}

const Container = styled.div`
  background-image: linear-gradient(to bottom, #ffffff, #f9fafb) !important;
  color: ${Palette.veryDarkBlueGrey} !important;
  border: 1px solid ${Palette.lightGreyBlue};
  border-radius: 3px;
  font-family: Lato, sans-serif;
  font-size: 14px;
  line-height: 1.43;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
`;

const LinkDiv = styled.div`
  cursor: pointer;
`;

const StyledParagraph = styled.div`
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default ActionPlan;