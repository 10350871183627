import { Record, RecordOf, Map, fromJS } from "immutable";
import { NetworkEventSuccessAction } from "@src/store/network/actions";
import {
  ModifyListAction,
  SurveyListActionTypes,
  DuplicateSurveyAction
} from "@screens/Insights/SurveyList/store/actions";
import {SurveyResourceProps } from "@screens/Insights/Surveys/store/types";
import { defaultSurveyProps } from "@screens/Insights/Surveys/store/reducers";
import { SurveyInfoResource } from "hyphen-lib/dist/domain/resource/SurveyInfoResource";
import { SurveyResource } from "hyphen-lib/dist/domain/resource/SurveyResource";

export interface SelectedSurveyInfoResource extends Map<string, any> {
  toJS(): SurveyInfoResource;
  get<K extends keyof SurveyInfoResource>(key: K): SurveyInfoResource[K];
}

export interface SelectedSurveyResource extends Map<string, any> {
  toJS(): SurveyResource;
  get<K extends keyof SurveyResource>(key: K): SurveyResource[K];
}

export interface StateProps {
  readonly pageSize: number;
  readonly reminderModalOpen: boolean;
  readonly duplicateModalOpen: boolean;
  readonly deleteModalOpen: boolean;
  readonly closeModalOpen: boolean;
  readonly selectedSurveyInfoResource: SelectedSurveyInfoResource;
  readonly selectedSurveyResource: SurveyResourceProps;
  readonly isRequestingSendReminders: boolean;
  readonly isRequestingDelete: boolean;
  readonly isRequestingClose: boolean;
  readonly isAttemptingSurveyDuplication: boolean;
}
export type State = RecordOf<StateProps>;

const defaultValues: StateProps = {
  pageSize: 10,
  reminderModalOpen: false,
  duplicateModalOpen: false,
  deleteModalOpen: false,
  closeModalOpen: false,
  selectedSurveyInfoResource: Map({}) as SelectedSurveyInfoResource,
  selectedSurveyResource: defaultSurveyProps,
  isRequestingSendReminders: false,
  isRequestingDelete: false,
  isRequestingClose: false,
  isAttemptingSurveyDuplication: false,
};

export const stateFactory = Record<StateProps>(defaultValues);
const defaultState = stateFactory();

// fixme: we need to fix this this type was supposed to be used in the reducer instead of any :/
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type Actions = ModifyListAction |
DuplicateSurveyAction;

function receiveSurveySuccessReducer(
  state: State = defaultState,
  { payload }: NetworkEventSuccessAction
): State {
  return state.mergeIn(["selectedSurveyResource"], fromJS(payload.data) as SurveyResourceProps);
}

export function reducers(state: State = defaultState,
  action: any): State {
  switch (action.type) {
    case SurveyListActionTypes.FETCH_SURVEY_SUCCESS:
      return receiveSurveySuccessReducer(state, action);
    case SurveyListActionTypes.TOGGLE_REMINDER_MODAL_OPEN:
      return state.withMutations(map => {
        map
          .set("reminderModalOpen", action.reminderModalOpen)
          .set("selectedSurveyInfoResource", Map(action.selectedSurveyInfoResource) as SelectedSurveyInfoResource);
      });
    case SurveyListActionTypes.TOGGLE_DUPLICATE_MODAL_OPEN:
      return state.withMutations(map => {
        map
          .set("duplicateModalOpen", action.duplicateModalOpen)
          .set("selectedSurveyInfoResource", Map(action.selectedSurveyInfoResource) as SelectedSurveyInfoResource);
      });
    case SurveyListActionTypes.TOGGLE_DELETE_MODAL_OPEN:
      return state.withMutations(map => {
        map
          .set("deleteModalOpen", action.deleteModalOpen)
          .set("selectedSurveyInfoResource", Map(action.selectedSurveyInfoResource) as SelectedSurveyInfoResource);
      });
    case SurveyListActionTypes.TOGGLE_CLOSE_MODAL_OPEN:
      return state.withMutations(map => {
        map
          .set("closeModalOpen", action.closeModalOpen)
          .set("selectedSurveyInfoResource", Map(action.selectedSurveyInfoResource) as SelectedSurveyInfoResource);
      });
    case SurveyListActionTypes.SEND_SURVEY_REMINDERS_REQUEST:
      return state.set("isRequestingSendReminders", true);
    case SurveyListActionTypes.DELETE_SURVEY_REQUEST:
      return state.set("isRequestingDelete", true);
    case SurveyListActionTypes.CLOSE_SURVEY_REQUEST:
      return state.set("isRequestingClose", true);
    case SurveyListActionTypes.SEND_SURVEY_REMINDERS_SUCCESS:
      return state.withMutations(map => {
        map
          .set("isRequestingSendReminders", false)
          .set("reminderModalOpen", false)
          .set("selectedSurveyInfoResource", Map({}) as SelectedSurveyInfoResource);
      });
    case SurveyListActionTypes.DELETE_SURVEY_SUCCESS:
      return state.withMutations(map => {
        map
          .set("isRequestingDelete", false)
          .set("deleteModalOpen", false)
          .set("selectedSurveyInfoResource", Map({}) as SelectedSurveyInfoResource);
      });
    case SurveyListActionTypes.CLOSE_SURVEY_SUCCESS:
      return state.withMutations(map => {
        map
          .set("isRequestingClose", false)
          .set("closeModalOpen", false)
          .set("selectedSurveyInfoResource", Map({}) as SelectedSurveyInfoResource);
      });
    case SurveyListActionTypes.CLOSE_SURVEY_RESOURCE_SUCCESS:
      return state.withMutations(map => {
        map
          .set("isRequestingClose", false)
          .set("closeModalOpen", false)
          .set("selectedSurveyInfoResource", Map({}) as SelectedSurveyInfoResource);
      });
    case SurveyListActionTypes.DUPLICATE_SURVEY_REQUEST:
      return state
        .set("isAttemptingSurveyDuplication", true);
    case SurveyListActionTypes.DUPLICATE_SURVEY_SUCCESS:
      return state
        .set("isAttemptingSurveyDuplication", false);
    case SurveyListActionTypes.DUPLICATE_SURVEY_ERROR:
      return state
        .set("isAttemptingSurveyDuplication", false);
    // fixme: handle error
    default:
      return state;
  }
}
