import Button from "@src/components/core/Button";
import Palette from "@src/config/theme/palette";
import { goTo } from "@src/utils/locations";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { getOr } from "hyphen-lib/dist/lang/Objects";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Config } from "hyphen-lib/dist/util/Config";
import { Trans } from "react-i18next";
import styled from "styled-components";

interface Props {
    actionId: string;
    isEditScreen?: boolean;
    errorCode: number;
    betterworksBaseUrl?: Optional<string>;
}

function getErrorMessageByCode(errorCode?: number) {
  switch(errorCode) {
    case 10404:
      /* eslint-disable max-len */
      return "We could not automatically create your OKR in Betterworks, as we couldn't find the OKR assignee in the Betterworks app.";

    case 10403:
      /* eslint-disable max-len */
      return "We could not automatically create your OKR in Betterworks, as the Category for the OKR could not be created.";

    case 10422:
      /* eslint-disable max-len */
      return "We could not automatically create your OKR in Betterworks, as your organization is not found in Betterworks.";

    default:
      /* eslint-disable max-len */
      return "We could not automatically create your Betterworks OKR due to an Internal error in our system.";
  }
}

function OkrErrorSection(props: Props) {
    
    const onEditActionClick = () => {
        const { actionId } = props;
        goTo(`/actioncenter/actions/edit/${actionId}`);
    };
    const betterworksUrl = getOr(props.betterworksBaseUrl, Config.getInstance().get("BETTERWORKS_BASE_URL"));
    const errorMessage = getErrorMessageByCode(props.errorCode);
    
    return (
        <ErrorWrapper data-jest="okrErrorCard" data-cy="okrErrorCard">
            <Heading>
                <Trans>There has been a problem with creating your Betterworks OKR.</Trans>
            </Heading>
            <Paragraph data-jest="okrErrorMessageBody">
                <Trans>{errorMessage}</Trans>
                &nbsp; <Trans>If you still want to create it, you can either go to the Action Plan and edit it or go
                directly to the Betterworks app and create an OKR separately.</Trans>
            </Paragraph>
            <ButtonWrapper>
                {
                  not(props.isEditScreen) && 
                  <ErrorComponentButton onClick={onEditActionClick}>
                      <Trans>Edit Action Plan</Trans>
                  </ErrorComponentButton>
                }
                <NavigateToBetterworks 
                  data-cy="betterworksRedirectUrl" 
                  target="_blank" 
                  rel="noreferrer" 
                  href={betterworksUrl} 
                >
                    <Trans>Go to Betterworks</Trans>
                </NavigateToBetterworks>
            </ButtonWrapper>
        </ErrorWrapper>
    );
}

const ErrorWrapper = styled.div`
  box-sizing: border-box;
  height: 187px;
  width: 596px;
  border: 1px solid ${Palette.darkPink};
  border-radius: 4px;
  background-color: rgba(223,56,104,0.1);
  margin: auto;
  padding: 10px;
`;

const Heading = styled.h3`
  height: 20px;
  color: ${Palette.veryDarkBlueGrey};
  font-size: 14px;
  font-weight: bold;
  text-align: left;
`;

const Paragraph = styled.p`
  margin-top: 10px;
  margin-bottom: 10px;
  color: ${Palette.veryDarkBlueGrey};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: left;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 20px;
`;

const NavigateToBetterworks = styled.a`
  display: flex;
  align-items: center;
  border-radius: 2px;
  background: ${Palette.bluishGrey};
  margin-right: 10px;
  padding: 10px;
  color: ${Palette.white};
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    border-radius: 2px;
    background: ${Palette.bluishGrey};
  }

  &:visited {
    color: ${Palette.white};
  }
`;

const ErrorComponentButton = styled(Button)`
  border-radius: 2px;
  background: ${Palette.bluishGrey} !important;
  margin-right: 10px;

  &:hover {
    border-radius: 2px;
    background: ${Palette.bluishGrey} !important;
  }

  span {
    color: ${Palette.white};
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
    text-align: center;
  }
`;
export default OkrErrorSection;
