import { default as networkActionCreators } from "@store/network/actions";
import { createRequest } from "@src/utils/networks";
import { GroupsAndChannelsResource } from "hyphen-lib/dist/domain/resource/group/GroupsAndChannelsResource";

export function fetchGroupsAndChannelsIfNeeded() {
  return networkActionCreators.fetchElementIfNeeded({
    id: GroupsAndChannelsResource.generateKey(),
    type: GroupsAndChannelsResource.TYPE,
    request: createRequest("/groups/all"),
  });
}
