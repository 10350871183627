import { Component } from "react";

import { AccessResource } from "hyphen-lib/dist/domain/access/AccessResource";
import { CardSection } from "@components/core/InfoCard/CardSection";

interface AccessSectionProps {
  readonly accesses: AccessResource[];
}
export class AccessSection extends Component<AccessSectionProps> {
  getAccessListCount = () => {
    const { accesses } = this.props;
    let usersWithRead = 0;
    let usersWithConversation = 0;
    let usersWithAdmin = 0;

    for (const access of accesses) {
      switch (access.role) {
        case "read":
          usersWithRead += 1;
          break;
        case "conversation":
          usersWithConversation += 1;
          break;
        case "admin":
          usersWithAdmin += 1;
      }
    }

    return {
      usersWithRead,
      usersWithConversation,
      usersWithAdmin,
    };
  };

  render() {
    const { usersWithRead, usersWithConversation, usersWithAdmin } = this.getAccessListCount();
    const accessSummaryData = [
      {
        label: "All access",
        value: usersWithAdmin,
      },
      {
        label: "View + Messaging",
        value: usersWithConversation,
      },
      {
        label: "View",
        value: usersWithRead,
      },
    ];

    return <CardSection data={accessSummaryData} />;
  }
}
