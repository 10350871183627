import React from "react";
import styled from "styled-components";
// @ts-ignore
import hareNiemeyer from "hare-niemeyer";

import { Sentiment } from "@hyphen-lib/domain/common/ComputationTypes";
import { SentimentValues } from "@screens/Insights/Survey/components/Comments/types";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import SentimentBar from "./components/SentimentBar";

interface Props {
  sentiments: SentimentValues;
}

export const countPercentages = (sentiments: SentimentValues) => {
  const oneHundredPercent = 100;
  const percentages = hareNiemeyer(sentiments, oneHundredPercent);
  const result: Dictionary<number> = {};

  Object.keys(percentages).forEach((key) => {
    result[key] = isNaN(percentages[key]) ? 0 : percentages[key];
  });

  return result;
};

const CommentsSentimentChart = ({ sentiments }: Props) => {
  const commentsInTotal =
    sentiments[Sentiment.POSITIVE] +
    sentiments[Sentiment.NEUTRAL] +
    sentiments[Sentiment.NEGATIVE];
  const possibleSentiments = [
    Sentiment.POSITIVE,
    Sentiment.NEUTRAL,
    Sentiment.NEGATIVE,
  ];

  if (commentsInTotal === 0) {
    return null;
  }

  const percentages = countPercentages(sentiments);

  return (
    <SentimentChart>
      {possibleSentiments.map((sentimentKey: Sentiment) =>
        (isNotNullNorUndefined(percentages[sentimentKey]) && percentages[sentimentKey] !== 0) && (
          <SentimentBar
            key={sentimentKey}
            sentiment={sentimentKey}
            barPercentRounded={percentages[sentimentKey]}
            barPercent={
              Math.round((sentiments[sentimentKey] * 1000) / commentsInTotal) / 10
            }
            barValue={sentiments[sentimentKey]}
          />
        ))
      }
    </SentimentChart>
  );
};

const SentimentChart = styled.div`
  display: flex;
  height: 48px;
  margin-top: 32px;
  margin-bottom: 32px;
`;

export default CommentsSentimentChart;
