import React from "react";
import { Icon } from "antd";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { Trans } from "react-i18next";

interface MultiLanguageFooterStripProps {
  totalLanguages: number;
  languagesReady: number;
  onEditTranslations?: () => void;
  showEditTranslationButton?: boolean;
  allBold?: boolean;
}

export class MultiLanguageFooterStrip extends React.Component<MultiLanguageFooterStripProps> {

  renderLanguagesReadyMessage() {
    let allBold = true;
    if (this.props.allBold === false) {
      allBold = false;
    }

    if (this.props.totalLanguages === 0) {
      return (
        <>
          <LanguagesTextMessage bold={false} data-cy="no_languages_added">
            <Trans>No additional languages added for the survey</Trans>
          </LanguagesTextMessage>
        </>
      );
    } else if (this.props.languagesReady < this.props.totalLanguages) {
      return (
        <>
          <Icon
            type="warning"
            className="align-self-start"
            theme="filled"
            style={{ color: "#ffb300", marginRight: "5px" }}
          />
          <LanguagesTextMessage
            bold={allBold}
            data-cy="languages_out_of"
          >
            {this.props.languagesReady} <Trans>out of</Trans> {this.props.totalLanguages}{" "}
            <Trans>languages ready</Trans>
          </LanguagesTextMessage>
        </>
      );
    } else {
      return (
        <>
          <Icon
            type="check"
            className="align-self-start"
            style={{ marginRight: "5px", color: "#00D994" }}
          />
          <LanguagesTextMessage
            bold={allBold}
            data-cy="all_languages_ready"
          ><Trans>All languages ready</Trans></LanguagesTextMessage>
        </>
      );
    }
  }

  render() {
    let showEditBtn = true;
    if (
      isNotNullNorUndefined(this.props.showEditTranslationButton) &&
      this.props.showEditTranslationButton === false
    ) {
      showEditBtn = false;
    }

    return (
      <>
        <div className="d-flex">
          <div className="mr-auto">{this.renderLanguagesReadyMessage()}</div>
          {showEditBtn && this.props.totalLanguages > 0 && (
            <EditTranslations
              onClick={() => {
                if (isNotNullNorUndefined(this.props.onEditTranslations)) {
                  this.props.onEditTranslations();
                }
              }}
              data-cy="edit_translations"
            >
              <Trans>Edit translations</Trans>
            </EditTranslations>
          )}
        </div>
      </>
    );
  }
}

interface LanguagesTextMessageProps {
  bold: boolean;
}

const LanguagesTextMessage = styled.span<LanguagesTextMessageProps>`
  font-size: 12px;
  color: ${Palette.veryDarkBlueGrey};
  font-weight: ${(props) => props.bold === true ? "bold" : "inherit"}
`;

const EditTranslations = styled.div`
  color: ${Palette.bluePurple};
  cursor: pointer;
`;
