import { Component } from "react";
import ContainerCard from "@src/components/core/ContainerCard";

export default class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    const{ hasError} = this.state;
    if (hasError) {
      return (
        <ContainerCard
          title="Something is not right!"
          description="Try refreshing the page or if the issue persists, please contact your administrator."
        />
      );
    }

    return this.props.children;
  }
}
