import React from "react";
import styled from "styled-components";
import { Trans } from "react-i18next";
import { Col, Form, Row } from "antd";
import ContainerCard from "@components/core/ContainerCard";
import Checkbox from "@src/components/core/Checkbox";
import { DropDownButton, DropDownButtonProps } from "@components/core/DropDownButton";
import { InputProps } from "antd/lib/input";
import Palette from "@src/config/theme/palette";
import { RowProps } from "antd/lib/row";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Dimension, Dimensions, shouldExcludeDirectManager } from "@hyphen-lib/domain/common/Dimensions";
import {
  entries,
  isEmptyObject,
  isNotEmptyObject,
  isNotNullNorUndefined,
  isNullOrUndefined,
  mapOr
} from "hyphen-lib/dist/lang/Objects";
import { CurrentUserResource } from "@hyphen-lib/domain/resource/user/CurrentUserResource";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { NumberInput } from "@src/components/core/NumberInput";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { Audience } from "hyphen-lib/dist/domain/common/Audience";
import { DateCriteriaTexts, getDateCriteriaTexts } from "@src/utils/Audiences";
import { DimensionSelect } from "@screens/Insights/components/Wizard/Audience/components/DimensionSelect";
import { parseNumber } from "hyphen-lib/dist/lang/Number";
import { ManagerDimensionTooltip } from "../../../FiltersContent";
import { sanitizeDimensionKey } from "@src/utils/Dimensions";

interface CriteriaFormProps {
  readonly everyoneInTheCompany: boolean;
  readonly dynamicCriteria: Audience.DynamicCriteria;

  readonly dimensions: Dimensions;
  readonly reportingDimensions: Dictionary<string[]>;
  // till we have the voice and the groups, this flag should never be true
  readonly allowToUseGroups?: Optional<boolean>;
  readonly groups: CurrentUserResource.PrivateGroup[];
  // the count is not displayed if we are only having dynamic criteria
  readonly showCount: boolean;
  readonly count: Loadable<number>;

  readonly dateCriteriaConfiguration?: Optional<Audience.DateCriteriaConfiguration>;
  readonly dateCriteriaTexts?: Optional<Partial<DateCriteriaTexts>>;

  readonly disabled?: Optional<boolean>;

  readonly onToggleEveryoneInTheCompany: (everyoneInTheCompany: boolean) => any;
  readonly onChange: (dynamicCriteria: Audience.DynamicCriteria) => any;
  readonly canRemoveManualAudience?: boolean;
  readonly cannotEditAudience?: boolean;
  readonly hasPendingAudienceUpdates?: boolean;
  readonly statusOfParticipantsCount: string;
  readonly infoMessage: (status: string) => void;
  readonly isParticipantsCountForDimensionsError: boolean;
}

interface DynamicCriteriaOption {
  readonly key: string;
  readonly label: string;
}

export class CriteriaForm extends React.Component<CriteriaFormProps> {

  getAvailableDynamicOptions = (): DynamicCriteriaOption[] => {
    const {
      dynamicCriteria,
      dimensions,
      allowToUseGroups,
    } = this.props;

    const options = [];

    const notUsedDimensions = entries(dimensions)
      .filter(
        (dimLabel: Dimension, dimKey: string) =>
          (isNullOrUndefined(dynamicCriteria.dimensions) ||
          isNullOrUndefined(dynamicCriteria.dimensions[dimKey]))
          && shouldExcludeDirectManager(dimKey) 
      )
      .map((dimension: Dimension, dimKey: string) => ({ key: dimKey, label: dimension.label }))
      .valueSeq()
      .toArray();
    options.push(...notUsedDimensions);

    if (allowToUseGroups && isNullOrUndefined(dynamicCriteria.groups)) {
      options.push({ key: "groups", label: "Groups" });
    }

    return options;
  };

  handleToggleIsSendToEveryone = (e: any) => {
    this.props.onToggleEveryoneInTheCompany(e.target.checked);
  };

  handleCriteriaSelect = (key: string) => {
    const {
      dynamicCriteria,
      onChange,
      disabled,
    } = this.props;

    if (disabled) {
      return;
    }

    if (key === "groups") {
      onChange({
        ...dynamicCriteria,
        groups: [],
      });
    } else {
      onChange({
        ...dynamicCriteria,
        dimensions: {
          ...dynamicCriteria.dimensions,
          [key]: [],
        },
      });
    }
  };

  handleDateCriteriaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      dynamicCriteria,
      dateCriteriaConfiguration,
      onChange,
      disabled,
    } = this.props;

    if (disabled) {
      return;
    }

    if (isNotNullNorUndefined(dateCriteriaConfiguration)) {
      const value = parseNumber(e.target.value, 0);
      const dateCriteria = Audience.generateDateCriteria(dateCriteriaConfiguration, value);
      onChange({
        ...dynamicCriteria,
        dateCriteria: Optional.extract(dateCriteria),
      });
    }
  };

  returnDimensionData = (key: string) => {
    if (isNotNullNorUndefined(this.props.dimensions)
      && isNotNullNorUndefined(this.props.dimensions[key])) {
      return this.props.dimensions[key].segments;
    } else {
      return [];
    }
  };

  createChangeDimensionHandler = (dimKey: string) => (values: string[]) => {
    const {
      dynamicCriteria,
      onChange,
      disabled,
    } = this.props;

    if (disabled) {
      return;
    }

    onChange({
      ...dynamicCriteria,
      dimensions: {
        ...dynamicCriteria.dimensions,
        [dimKey]: values,
      },
    });
  };

  createRemoveDimensionHandler = (dimKey: string) => () => {
    const {
      dynamicCriteria,
      onChange,
      disabled,
    } = this.props;

    if (disabled) {
      return;
    }

    const newDimensions: any = { ...dynamicCriteria.dimensions };
    delete newDimensions[dimKey];

    const newDynamicCriteria = {
      ...dynamicCriteria,
      dimensions: newDimensions,
    };

    if (isEmptyObject(newDimensions)) {
      delete newDynamicCriteria.dimensions;
    }

    onChange(newDynamicCriteria);
  };

  renderDimensionsCriteria = () => {
    const {
      dynamicCriteria,
      dimensions,
      reportingDimensions,
      disabled,
      canRemoveManualAudience,
      cannotEditAudience,
      hasPendingAudienceUpdates,
    } = this.props;
    if (isNullOrUndefined(dynamicCriteria.dimensions)) {
      return null;
    }

    return entries(dynamicCriteria.dimensions)
      .map((segments: string[], dimKey: string) => {
        const dimensionConfiguration = dimensions[dimKey];
        const isDimensionConfiguration = isNullOrUndefined(dimensionConfiguration);
        const allowedValues = isDimensionConfiguration ? segments : dimensionConfiguration.segments;
        const restrictedValues = mapOr(reportingDimensions, reportingDims => reportingDims[dimKey], undefined);
        let label = <span>{sanitizeDimensionKey(dimKey)}</span>;
        if(dimKey.toLowerCase() === "manager") {
          label = ManagerDimensionTooltip(dimKey);
        }
        return (
          <div key={dimKey}>
            <DimensionSelect
              key={dimKey}
              dimKey={dimKey}
              label={label}
              values={segments}
              allowedValues={allowedValues}
              restrictedValues={restrictedValues}
              disabled={disabled}
              onChange={this.createChangeDimensionHandler(dimKey)}
              onRemove={this.createRemoveDimensionHandler(dimKey)}
              canRemoveManualAudience={canRemoveManualAudience}
              cannotEditAudience={cannotEditAudience}
              hasPendingAudienceUpdates={hasPendingAudienceUpdates}
            />
            {isDimensionConfiguration ? <DimensionError data-jest="dimensionError">
              {dimKey} <Trans>is not present in the defined dimension list</Trans>
            </DimensionError> : null
            }

          </div>
        );
      })
      .valueSeq();
  };

  renderGroupCriteria = () => {
    // fixme: handle groups when we will need them, as soon as voice will be there!
    return null;
  };

  renderDimensionSelect = () => {
    const options = this.getAvailableDynamicOptions();
    if (this.props.cannotEditAudience || this.props.everyoneInTheCompany) {
      return null;
    }
    return (
      <Col span={12}>
        <StyledDropDownButton
          options={options}
          onClick={this.handleCriteriaSelect}
          noValues="All criteria added"
          data-cy="audience_addCriteria"
        >
          <Trans>Add Criteria</Trans>
        </StyledDropDownButton>
      </Col>
    );
  };

  renderDateCriteria = () => {
    const {
      dynamicCriteria,
      dateCriteriaConfiguration,
      dateCriteriaTexts,
      disabled,
      cannotEditAudience,
    } = this.props;
    if (isNotNullNorUndefined(dateCriteriaConfiguration)) {

      const { text, hoverText } = getDateCriteriaTexts(dateCriteriaTexts, dateCriteriaConfiguration);

      const value = Math.abs(
        mapOr(
          dynamicCriteria.dateCriteria,
          dateCriteria => dateCriteria.minBound,
          0
        )
      );
      return (
        <StyledRow type="flex" justify="start">
          <NumberInput
            disabled={disabled === true || cannotEditAudience}
            label={text}
            infoText={hoverText}
            value={value}
            suffix={<Trans>days</Trans>}
            onChange={this.handleDateCriteriaChange}
          />
        </StyledRow>
      );
    }
  };

  render() {
    const {
      everyoneInTheCompany,
      reportingDimensions,
      cannotEditAudience,
      showCount,
      count,
      dynamicCriteria,
      statusOfParticipantsCount,
      isParticipantsCountForDimensionsError,
      infoMessage,
    } = this.props;
    const isRestricted = isNotEmptyObject(reportingDimensions) || cannotEditAudience;
    return (
      <StyledContainer>
        <ContainerCard
          title="Set eligibility criteria"
          /* eslint-disable max-len */
          description="Configure eligibility criteria to dynamically generate a list of recipients from your employee list."
        >
          <Row>
            <Col span={12}>
              <Form>
                <SendToEveryone
                  checked={everyoneInTheCompany}
                  disabled={isRestricted}
                  data-cy="audience_everyoneEligible"
                  info={"Send to all eligible employees in your scope. Uncheck to define criteria"}
                  onChange={this.handleToggleIsSendToEveryone}
                >
                  <Trans>Send to everyone eligible.</Trans>
                </SendToEveryone>
              </Form>
            </Col>
          </Row>
          {
            (!everyoneInTheCompany || isNotEmptyObject(dynamicCriteria.dateCriteria)) && (
              <>
                <Row>
                  {this.renderDimensionsCriteria()}
                  {this.renderGroupCriteria()}
                  {this.renderDimensionSelect()}
                </Row>
              </>
            )
          }
          {
            this.renderDateCriteria()
          }
        </ContainerCard>
        {
          showCount &&
          <RecipientsRow type="flex" align="middle" justify="space-between">
            <Row type="flex" align="middle">
              <RecipientsLabel>
                <Trans>Recipients defined by criteria:</Trans>
              </RecipientsLabel>
              <RecipientsCount data-jest="recipientsCount">
                {
                  isParticipantsCountForDimensionsError ? 
                    0 :
                    Loadable.mapOr(count, c => c.toString(), statusOfParticipantsCount)
                }
              </RecipientsCount>
              {infoMessage(statusOfParticipantsCount)}
            </Row>
          </RecipientsRow>
        }
      </StyledContainer>
    );
  }
}

export const StyledContainer = styled.div`
  margin-bottom: 24px;
`;

export const SendToEveryone = styled(Checkbox) <InputProps>`
  margin-top: 20px;
`;
const DimensionError = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  color: ${Palette.alizarin};
`;

export const StyledRow = styled(Row) <RowProps>`
  margin: 24px 0 0 0;
`;

export const RecipientsRow = styled(Row) <RowProps>`
  background-color: ${Palette.veryLightBlue};
  padding: 16px 32px;
`;

export const RecipientsLabel = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-right: 7px;
`;

export const RecipientsCount = styled.div`
  font-size: 24px;
  color: ${Palette.darkModerateBlue};
`;

export const StyledDropDownButton = styled(DropDownButton) <DropDownButtonProps>`
  margin: 24px 0 0 0;
  text-align: left;
  width: 285px;
  height: 36px;
  border-radius: 3px;
  border: solid 1px ${Palette.lightGreyBlue};
  background-image: linear-gradient(to bottom, #ffffff, ${Palette.athensGrey});
`;
