import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { ParticipantCountResource } from "@hyphen-lib/domain/resource/participants/ParticipantCountResource";

import { CardSection } from "@components/core/InfoCard/CardSection";
import { Optional } from "hyphen-lib/dist/lang/Optionals";

interface Props {
  participantsCount: Loadable<ParticipantCountResource>;
  isMagicSurveyFeatureEnabled?: Optional<boolean>;
  isMagicSurvey?: Optional<boolean>;
}

export function AudienceSection({ 
  participantsCount: loadableCount, 
  isMagicSurvey, 
  isMagicSurveyFeatureEnabled 
}: Props) {
  if (Loadable.isNotLoaded(loadableCount)) {
    return null;
  }

  const participantsCount = loadableCount.value;

  const audienceSummaryData = [
    {
      label: "Added by criteria",
      value: participantsCount.criteria || "N/A",
    },
    {
      label: "Added manually",
      value: participantsCount.manual || "N/A",
    },
    {
      label: "Total recipients",
      value: participantsCount.total || "N/A",
    },
  ];

  if (isMagicSurveyFeatureEnabled) {
    audienceSummaryData.push({
      label: "Shared via web link",
      value: isMagicSurvey ? "Enabled" : "Disabled",
    });
  }

  return (
    <>
      <CardSection data={audienceSummaryData}/>
    </>
  );
}
