import React from "react";
import { ColumnProps, TableProps } from "antd/lib/table";

import Table from "@src/components/core/Table";
import {
  DynamicRowProps,
  withDynamicRows
} from "@src/components/core/DynamicRowsTable/withDynamicRows";
import CreateActionPlanButton from "@src/screens/Insights/components/Reports/CreateActionPlanButton";
import styled from "styled-components";
import { VoiceCommentReportResource } from "hyphen-lib/dist/domain/resource/voice/VoiceCommentReportResource";
import { SentimentCell } from "../../VoicePosts/Components/cells/SentimentCell";
import { PostAndGroup } from "./cells/PostAndGroup";
import { Likes } from "./cells/Likes";
import { TextCell } from "./cells/TextCell";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { FocusArea, Module } from "hyphen-lib/dist/domain/FocusArea";
import { Trans } from "react-i18next";

interface VoiceCommentsTableProps
  extends TableProps<VoiceCommentReportResource> {
  readonly hasActionCreationRight: boolean;
  readonly focusAreas?: FocusAreaResource[];
  readonly onCreateFocusArea: (focusArea: Partial<FocusAreaResource>) => void;
}

export function VoiceCommentsTable({
  hasActionCreationRight,
  ...props
}: VoiceCommentsTableProps) {
  const COLUMNS: ColumnProps<VoiceCommentReportResource>[] = [
    {
      title: (<Trans>Comment</Trans>),
      dataIndex: "text",
      key: "text",
      width: "40%",
      render: (_, { text }) => <TextCell text={text} />,
    },
    {
      title: null,
      dataIndex: "likes",
      key: "likes",
      width: "10%",
      render: (_, { likes }) => <Likes totalLikes={likes} />,
    },

    {
      title: (<Trans>Posted in</Trans>),
      dataIndex: "downVotesCount",
      key: "downVotesCount",
      width: "40%",
      render: (_, { postText, group }) => (
        <PostAndGroup text={postText} group={group} />
      ),
    },
    {
      title: (<Trans>Sentiment</Trans>),
      dataIndex: "sentimentScore",
      key: "sentimentScore",
      width: "10%",
      render: (_, { sentimentScore }) => (
        <SentimentCell sentimentScore={sentimentScore} />
      ),
    },
  ];

  function HoverRow({ row }: DynamicRowProps<VoiceCommentReportResource>) {

    function onCreateActionPlan() {
      props.onCreateFocusArea({
        title: row.text,
        source: FocusArea.Source.COMMENT,
        module: Module.EMPLOYEE_VOICE,
        focusAreaType: FocusArea.FocusAreaType.COMMENT,
        sourceUrl: {
          label: row.text,
          url: window.location.href,
        },
        actionPlans: [],
        postId: row.postId,
        commentId: row._id
      });
    }

    return (
      <>
        <StyledHoverCell>
          {hasActionCreationRight && (
            <CreateActionPlanButton onClick={onCreateActionPlan} />
          )}
          <TextCell text={row.text} />
        </StyledHoverCell>
        <td>
          <Likes totalLikes={row.likes} />
        </td>
        <td>
          <PostAndGroup text={row.postText} group={row.group} />
        </td>
        <td>
          <SentimentCell sentimentScore={row.sentimentScore} />
        </td>
      </>
    );
  }

  const HoveringTable = withDynamicRows(Table, HoverRow);

  return <HoveringTable rowKey="_id" columns={COLUMNS} {...props} />;
}

const StyledHoverCell = styled.td`
  position: relative;
`;
