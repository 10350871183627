import React from "react";
import { ActionResource } from "@hyphen-lib/domain/resource/action/ActionResource";
import { isEmpty, isNotEmptyArray } from "@hyphen-lib/lang/Arrays";
import styled from "styled-components";
import ButtonGroup, { ButtonGroupProps } from "antd/lib/button/button-group";
import Button, { ButtonProps } from "@components/core/Button";
import { Action as ActionPlan } from "@hyphen-lib/domain/Action";
import { Set as ImmutableSet } from "immutable";
import { DropDownButton, DropDownButtonProps } from "@components/core/DropDownButton";
import { DynamicRowProps } from "@components/core/DynamicRowsTable/withDynamicRows";
import Palette from "@src/config/theme/palette";
import { getActionStatuses } from "@screens/Insights/ActionPlans/utils/helpers";
import { RightsMatcher } from "@hyphen-lib/business/auth/Auth";
import { Rights } from "@hyphen-lib/business/auth/Rights";
import { CellContainer, CellInnerContainer } from "./Cells";
import { Trans } from "react-i18next";

interface Action {
  readonly key: string;
  readonly label: string;
}

interface ActionDefinition {
  readonly actions: Action[];
  readonly setTo: Action[];
  readonly more: Action[];
}

export enum ActionKeys {
  edit = "edit",
  delete = "delete",
  todo = "todo",
  in_progress = "in_progress",
  completed = "completed",
  dismissed = "dismissed",
  view = "view",
}

interface ActionCellProps extends DynamicRowProps<ActionResource> {
  onClick: (key: ActionKeys) => any;
  readonly rightsMatcher: RightsMatcher;
}

export function ActionCell({ row, onClick, rightsMatcher }: ActionCellProps) {
  const actions: ActionDefinition = mapStatusToActions(row.status, rightsMatcher);
  if (isEmpty(actions.actions) && isEmpty(actions.setTo) && isEmpty(actions.more)) {
    return null;
  }

  return (
    <CellContainer align="center" justifyContent="flex-end">
      <CellInnerContainer margin={0} width="100%">
        <StyledButtonGroup>
          {
            /* tslint:disable:jsx-no-lambda */
            actions.actions.map((action, index) => (
              <StyledButton
                index={index}
                key={action.key}
                data-cy="actionButton_options"
                color="gradation"
                translate="yes"
                onClick={() => onClick(action.key as ActionKeys)}>
                {action.label}
              </StyledButton>
            ))
          }
          {
            isNotEmptyArray(actions.setTo) &&
            <SetToDropDown
              options={actions.setTo}
              onClick={onClick}
              data-cy="actionButton_setTo"
              translate="yes"
            >
              <Trans>Set to</Trans>
            </SetToDropDown>
          }
          {
            isNotEmptyArray(actions.more) &&
            <MoreOptionsDropDown
              options={actions.more}
              onClick={onClick}
              data-cy="actionButton_more"
              translate="yes"
            >
              <Trans>More</Trans>
            </MoreOptionsDropDown>
          }
        </StyledButtonGroup>
      </CellInnerContainer>
    </CellContainer>
  );
}

const ALL_ACTIONS:
(
  Action &
  {
    type: "classic" | "more";
    statuses: ImmutableSet<string>;
    right: string;
  }
)[] = [
  {
    key: ActionKeys.view,
    label: "View",
    type: "more",
    statuses: ImmutableSet.of("todo", "in_progress"),
    right: Rights.Action.VIEW_ALL,
  },
  {
    key: ActionKeys.edit,
    label: "Edit",
    type: "more",
    statuses: ImmutableSet.of("todo", "in_progress"),
    right: Rights.Action.CREATE,
  },
  {
    key: ActionKeys.view,
    label: "View",
    type: "classic",
    statuses: ImmutableSet.of("completed", "dismissed"),
    right: Rights.Action.VIEW_ALL,
  },
  {
    key: ActionKeys.delete,
    label: "Delete",
    type: "more",
    statuses: ImmutableSet.of("todo", "in_progress"),
    right: Rights.Action.CREATE,
  },
  {
    key: ActionKeys.delete,
    label: "Delete",
    type: "classic",
    statuses: ImmutableSet.of("completed", "dismissed"),
    right: Rights.Action.CREATE,
  },
];

function mapStatusToActions(currentStatus: ActionPlan.Status, rightsMatcher: RightsMatcher): ActionDefinition {

  const actions: Action[] = [];
  const setTo: Action[] = [];
  const more: Action[] = [];

  for (const action of ALL_ACTIONS) {
    if (
      action.statuses.has(currentStatus) &&
      rightsMatcher.hasRight(action.right)
    ) {
      if (action.type === "classic") {
        actions.push({
          key: action.key,
          label: action.label,
        });
      } else if (action.type === "more") {
        more.push({
          key: action.key,
          label: action.label,
        });
      }
    }
  }

  if (rightsMatcher.hasRight(Rights.Action.CREATE)) {
    const availableStatuses = getActionStatuses(currentStatus, false);
    availableStatuses.forEach(status => {
      setTo.push({
        key: status.value,
        label: status.label,
      });
    });
  }

  return {
    actions,
    setTo,
    more,
  };
}

const StyledButtonGroup = styled(ButtonGroup) <ButtonGroupProps & { children: React.ReactNode }>`
  display: flex !important;
  justify-content: flex-end;
`;

const StyledButton = styled(Button) <ButtonProps & { index: number }>`
  height: 36px !important;
  :focus, :hover {
    border-color: ${Palette.bluePurple} !important;
    outline: none !important;
  }
  border-top-left-radius: ${props => props.index === 0 ? 3 : 0}px !important;
  border-bottom-left-radius: ${props => props.index === 0 ? 3 : 0}px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
`;

const MoreOptionsDropDown = styled(DropDownButton) <DropDownButtonProps>`
  margin-left: -1px;
  width: 90px !important;
  height: 36px !important;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  color: ${Palette.veryDarkBlueGrey} !important;
  border-color: ${Palette.lightGreyBlue} !important;
  :focus, :hover {
    border-color: ${Palette.bluePurple} !important;
    outline: none !important;
  }
`;

const SetToDropDown = styled(DropDownButton) <DropDownButtonProps>`
  margin-left: -1px;
  min-width: 90px !important;
  height: 36px !important;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  color: ${Palette.veryDarkBlueGrey} !important;
  border-color: ${Palette.lightGreyBlue} !important;
  :focus, :hover {
    border-color: ${Palette.bluePurple} !important;
    outline: none !important;
  }
`;