import {
  ActionTypes as NetworkActionTypes,
  NetworkEventErrorAction,
  NetworkEventRequestAction,
  NetworkEventSuccessAction,
  NetworkRequestAction
} from "@store/network/actions";
import { FSA } from "flux-standard-action";
import { createRequest } from "@src/utils/networks";
import { SurveyResource } from "hyphen-lib/dist/domain/resource/SurveyResource";
import { Survey } from "hyphen-lib/dist/domain/Survey";
import { SurveyQuestionResource } from "hyphen-lib/dist/domain/resource/SurveyQuestionResource";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { goTo, Breadcrumb } from "@src/utils/locations";
import { PulsePollResource } from "hyphen-lib/dist/domain/resource/PulsePollResource";
import { AccessResource } from "hyphen-lib/dist/domain/access/AccessResource";
import { SurveyBankQuestionPayload } from "../components/SurveyQuestions/QuestionBankModal";
import { MODALS as SURVEY_QUESTIONS_MODALS } from "../containers/SurveyQuestions";
import { QuestionScoreFlags } from "../../SurveyList/components/SurveyListScreen";
import {
  AccessStateProps,
  AudiencePageProps,
  ContinueAlertProps,
  FormErrorType, SettingsPageStateProps,
  SurveyResourceProps,
  SurveyResourceRecord
} from "./types";

export enum ActionTypes {
  FETCH_TEMPLATES_REQUEST = "FETCH_TEMPLATES_REQUEST",
  FETCH_TEMPLATES_SUCCESS = "FETCH_TEMPLATES_SUCCESS",
  FETCH_TEMPLATES_ERROR = "FETCH_TEMPLATES_ERROR",

  FETCH_SURVEY_TYPE_REQUEST = "FETCH_SURVEY_TYPE_REQUEST",
  FETCH_SURVEY_TYPE_SUCCESS = "FETCH_SURVEY_TYPE_SUCCESS",
  FETCH_SURVEY_TYPE_ERROR = "FETCH_SURVEY_TYPE_ERROR",

  FETCH_POST_CATEGORY_REQUEST = "FETCH_POST_CATEGORY_REQUEST",
  FETCH_POST_CATEGORY_SUCCESS = "FETCH_POST_CATEGORY_SUCCESS",
  FETCH_POST_CATEGORY_ERROR = "FETCH_POST_CATEGORY_ERROR",

  FETCH_SURVEY_REQUEST = "FETCH_SURVEY_REQUEST",
  FETCH_SURVEY_SUCCESS = "FETCH_SURVEY_SUCCESS",
  FETCH_SURVEY_ERROR = "FETCH_SURVEY_ERROR",

  CREATE_SURVEY_REQUEST = "CREATE_SURVEY_REQUEST",
  CREATE_SURVEY_SUCCESS = "CREATE_SURVEY_SUCCESS",
  CREATE_SURVEY_ERROR = "CREATE_SURVEY_ERROR",

  GET_SURVEY_INFO_REQUEST = "GET_SURVEY_INFO_REQUEST",
  GET_SURVEY_INFO_SUCCESS = "GET_SURVEY_INFO_SUCCESS",
  GET_SURVEY_INFO_ERROR = "GET_SURVEY_INFO_ERROR",

  FETCH_SURVEY_INFOS_REQUEST = "FETCH_SURVEY_INFOS_REQUEST",
  FETCH_SURVEY_INFOS_SUCCESS = "FETCH_SURVEY_INFOS_SUCCESS",
  FETCH_SURVEY_INFOS_ERROR = "FETCH_SURVEY_INFOS_ERROR",

  UPLOAD_SURVEY_LOGO_REQUEST = "UPLOAD_SURVEY_LOGO_REQUEST",
  UPLOAD_SURVEY_LOGO_SUCCESS = "UPLOAD_SURVEY_LOGO_SUCCESS",
  UPLOAD_SURVEY_LOGO_ERROR = "UPLOAD_SURVEY_LOGO_ERROR",

  UPDATE_GET_STARTED_STATE = "UPDATE_GET_STARTED_STATE",
  UPDATE_SURVEY_DETAILS_PAGE = "UPDATE_SURVEY_DETAILS_PAGE",
  UPDATE_AUDIENCE_STATE = "UPDATE_AUDIENCE_STATE",

  UPDATE_SETTINGS_PAGE_STATE = "UPDATE_SETTINGS_PAGE_STATE",
  // Actions for Access Page begins
  FETCH_ACCESS_LIST_REQUEST = "FETCH_ACCESS_LIST_REQUEST",
  FETCH_ACCESS_LIST_SUCCESS = "FETCH_ACCESS_LIST_SUCCESS",
  FETCH_ACCESS_LIST_ERROR = "FETCH_ACCESS_LIST_ERROR",

  FETCH_ELIGIBLE_MANAGERS_COUNT_REQUEST = "FETCH_ELIGIBLE_MANAGERS_COUNT_REQUEST",
  FETCH_ELIGIBLE_MANAGERS_COUNT_SUCCESS = "FETCH_ELIGIBLE_MANAGERS_COUNT_SUCCESS",
  FETCH_ELIGIBLE_MANAGERS_COUNT_ERROR = "FETCH_ELIGIBLE_MANAGERS_COUNT_ERROR",

  FETCH_MANAGEES_EMAILS_REQUEST = "FETCH_MANAGEES_EMAILS_REQUEST",
  FETCH_MANAGEES_EMAILS_SUCCESS = "FETCH_MANAGEES_EMAILS_SUCCESS",
  FETCH_MANAGEES_EMAILS_ERROR = "FETCH_MANAGEES_EMAILS_ERROR",

  FETCH_POSSIBLE_ACTION_ASSIGNEES_REQUEST = "FETCH_POSSIBLE_ACTION_ASSIGNEES_REQUEST",
  FETCH_POSSIBLE_ACTION_ASSIGNEES_SUCCESS = "FETCH_POSSIBLE_ACTION_ASSIGNEES_SUCCESS",
  FETCH_POSSIBLE_ACTION_ASSIGNEES_ERROR = "FETCH_POSSIBLE_ACTION_ASSIGNEES_ERROR",

  TOGGLE_MANAGERS_SYNCHRONIZATION_REQUEST = "TOGGLE_MANAGERS_SYNCHRONIZATION_REQUEST",
  TOGGLE_MANAGERS_SYNCHRONIZATION_SUCCESS = "TOGGLE_MANAGERS_SYNCHRONIZATION_SUCCESS",
  TOGGLE_MANAGERS_SYNCHRONIZATION_ERROR = "TOGGLE_MANAGERS_SYNCHRONIZATION_ERROR",

  ADD_SURVEY_ACCESS_REQUEST = "ADD_SURVEY_ACCESS_REQUEST",
  ADD_SURVEY_ACCESS_SUCCESS = "ADD_SURVEY_ACCESS_SUCCESS",
  ADD_SURVEY_ACCESS_ERROR = "ADD_SURVEY_ACCESS_ERROR",

  ADD_LOCAL_SURVEY_ACCESS_REQUEST = "ADD_LOCAL_SURVEY_ACCESS_REQUEST",
  ADD_LOCAL_SURVEY_ACCESS_SUCCESS = "ADD_LOCAL_SURVEY_ACCESS_SUCCESS",
  ADD_LOCAL_SURVEY_ACCESS_ERROR = "ADD_LOCAL_SURVEY_ACCESS_ERROR",

  REMOVE_SURVEY_ACCESS_REQUEST = "REMOVE_SURVEY_ACCESS_REQUEST",
  REMOVE_SURVEY_ACCESS_SUCCESS = "REMOVE_SURVEY_ACCESS_SUCCESS",
  REMOVE_SURVEY_ACCESS_ERROR = "REMOVE_SURVEY_ACCESS_ERROR",

  MODIFY_ACCESS_REQUEST = "MODIFY_ACCESS_REQUEST",
  MODIFY_ACCESS_SUCCESS = "MODIFY_ACCESS_SUCCESS",
  MODIFY_ACCESS_ERROR = "MODIFY_ACCESS_ERROR",

  UPDATE_ACCESS_PAGE_STATE = "UPDATE_ACCESS_PAGE_STATE",
  // End of actions for Access Page

  UPDATE_SURVEY_REQUEST = "UPDATE_SURVEY_REQUEST",
  UPDATE_SURVEY_STATE = "UPDATE_SURVEY_STATE",
  UPDATE_SURVEY_SUCCESS = "UPDATE_SURVEY_SUCCESS",
  UPDATE_SURVEY_ERROR = "UPDATE_SURVEY_ERROR",

  UPDATE_SURVEY_AND_TRIGGER_FETCH_AUDIENCE_REQUEST = "UPDATE_SURVEY_AND_TRIGGER_FETCH_AUDIENCE_REQUEST",
  UPDATE_SURVEY_AND_TRIGGER_FETCH_AUDIENCE_SUCCESS = "UPDATE_SURVEY_AND_TRIGGER_FETCH_AUDIENCE_SUCCESS",
  UPDATE_SURVEY_AND_TRIGGER_FETCH_AUDIENCE_ERROR = "UPDATE_SURVEY_AND_TRIGGER_FETCH_AUDIENCE_ERROR",

  FETCH_PARTICIPANTS_COUNT_REQUEST = "FETCH_PARTICIPANTS_COUNT_REQUEST",
  FETCH_PARTICIPANTS_COUNT_SUCCESS = "FETCH_PARTICIPANTS_COUNT_SUCCESS",
  FETCH_PARTICIPANTS_COUNT_ERROR = "FETCH_PARTICIPANTS_COUNT_ERROR",

  FETCH_MERGE_TAG_REQUEST = "FETCH_MERGE_TAG_REQUEST",
  FETCH_MERGE_TAG_SUCCESS = "FETCH_MERGE_TAG_SUCCESS",
  FETCH_MERGE_TAG_ERROR = "FETCH_MERGE_TAG_ERROR",

  FETCH_SURVEY_BANK_QUESTIONS_REQUEST = "FETCH_SURVEY_BANK_QUESTIONS_REQUEST",
  FETCH_SURVEY_BANK_QUESTIONS_SUCCESS = "FETCH_SURVEY_BANK_QUESTIONS_SUCCESS",
  FETCH_SURVEY_BANK_QUESTIONS_ERROR = "FETCH_SURVEY_BANK_QUESTIONS_ERROR",

  UPDATE_SURVEY_FORM_ERRORS = "UPDATE_SURVEY_FORM_ERRORS",
  REMOVE_SURVEY_FORM_ERRORS = "REMOVE_SURVEY_FORM_ERRORS",

  OPEN_CONTINUE_ALERT_MODAL = "OPEN_CONTINUE_ALERT_MODAL",
  CLOSE_CONTINUE_ALERT_MODAL = "CLOSE_CONTINUE_ALERT_MODAL",
  FETCH_AUDIENCE_EMAIL_REQUEST = "FETCH_AUDIENCE_EMAIL_REQUEST",
  FETCH_AUDIENCE_EMAIL_SUCCESS = "FETCH_AUDIENCE_EMAIL_SUCCESS",
  FETCH_AUDIENCE_EMAIL_ERROR = "FETCH_AUDIENCE_EMAIL_ERROR",

  UPDATE_QUESTION_REQUEST = "UPDATE_QUESTION_REQUEST",
  UPDATE_QUESTION_SUCCESS = "UPDATE_QUESTION_SUCCESS",
  UPDATE_QUESTION_ERROR = "UPDATE_QUESTION_ERROR",

  ADD_QUESTION_REQUEST = "ADD_QUESTION_REQUEST",
  ADD_QUESTION_SUCCESS = "ADD_QUESTION_SUCCESS",
  ADD_QUESTION_ERROR = "ADD_QUESTION_ERROR",

  REMOVE_QUESTION_REQUEST = "REMOVE_QUESTION_REQUEST",
  REMOVE_QUESTION_SUCCESS = "REMOVE_QUESTION_SUCCESS",
  REMOVE_QUESTION_ERROR = "REMOVE_QUESTION_ERROR",

  LAUNCH_SURVEY_REQUEST = "LAUNCH_SURVEY_REQUEST",
  LAUNCH_SURVEY_SUCCESS = "LAUNCH_SURVEY_SUCCESS",
  LAUNCH_SURVEY_ERROR = "LAUNCH_SURVEY_ERROR",

  OPEN_SURVEY_PREVIEW_VALIDATION_MODAL = "OPEN_SURVEY_PREVIEW_VALIDATION_MODAL",
  CLOSE_SURVEY_PREVIEW_VALIDATION_MODAL = "CLOSE_SURVEY_PREVIEW_VALIDATION_MODAL",
  OPEN_SURVEY_PREVIEW_MODAL = "OPEN_SURVEY_PREVIEW_MODAL",
  CLOSE_SURVEY_PREVIEW_MODAL = "CLOSE_SURVEY_PREVIEW_MODAL",
  SEND_SURVEY_PREVIEW = "SEND_SURVEY_PREVIEW",
  SEND_SURVEY_PREVIEW_SUCCESS = "SEND_SURVEY_PREVIEW_SUCCESS",
  SEND_SURVEY_PREVIEW_ERROR = "SEND_SURVEY_PREVIEW_ERROR",

  OPEN_CANCEL_SURVEY_CREATION_MODAL = "OPEN_CANCEL_SURVEY_CREATION_MODAL",
  CLOSE_CANCEL_SURVEY_CREATION_MODAL = "CLOSE_CANCEL_SURVEY_CREATION_MODAL",

  TOGGLE_QUESTIONS_MODAL_STATE = "TOGGLE_QUESTIONS_MODAL_STATE",

  CLEAR_CREATE_SURVEY_STATE = "CLEAR_CREATE_SURVEY_STATE",

  SET_IS_TOUCHED = "SET_IS_TOUCHED",
}

interface Payloads {
  [ActionTypes.UPDATE_SETTINGS_PAGE_STATE]: Partial<SettingsPageStateProps>;
  [ActionTypes.UPDATE_ACCESS_PAGE_STATE]: Partial<AccessStateProps>;
  [ActionTypes.UPDATE_SURVEY_FORM_ERRORS]: FormErrorType;
  [ActionTypes.REMOVE_SURVEY_FORM_ERRORS]: keyof SurveyResourceProps;
  [ActionTypes.OPEN_CONTINUE_ALERT_MODAL]: Partial<ContinueAlertProps>;
  [ActionTypes.UPDATE_AUDIENCE_STATE]: AudiencePageProps;
}

export interface UpdateSettingsPageAction
  extends FSA<ActionTypes.UPDATE_SETTINGS_PAGE_STATE, Payloads[ActionTypes.UPDATE_SETTINGS_PAGE_STATE]> {
  type: ActionTypes.UPDATE_SETTINGS_PAGE_STATE;
  payload: Payloads[ActionTypes.UPDATE_SETTINGS_PAGE_STATE];
}

export interface UpdateAccessPageAction
  extends FSA<ActionTypes.UPDATE_ACCESS_PAGE_STATE, Payloads[ActionTypes.UPDATE_ACCESS_PAGE_STATE]> {
  type: ActionTypes.UPDATE_ACCESS_PAGE_STATE;
  payload: Payloads[ActionTypes.UPDATE_ACCESS_PAGE_STATE];
}

export interface UpdateSurveyFormErrorAction
  extends FSA<ActionTypes.UPDATE_SURVEY_FORM_ERRORS, Payloads[ActionTypes.UPDATE_SURVEY_FORM_ERRORS]> {
  type: ActionTypes.UPDATE_SURVEY_FORM_ERRORS;
  payload: Payloads[ActionTypes.UPDATE_SURVEY_FORM_ERRORS];
}

export interface RemoveSurveyFormErrorAction
  extends FSA<ActionTypes.REMOVE_SURVEY_FORM_ERRORS, Payloads[ActionTypes.REMOVE_SURVEY_FORM_ERRORS]> {
  type: ActionTypes.REMOVE_SURVEY_FORM_ERRORS;
  payload: Payloads[ActionTypes.REMOVE_SURVEY_FORM_ERRORS];
}

export interface OpenContinueAlertModalAction
  extends FSA<ActionTypes.OPEN_CONTINUE_ALERT_MODAL, Payloads[ActionTypes.OPEN_CONTINUE_ALERT_MODAL]> {
  type: ActionTypes.OPEN_CONTINUE_ALERT_MODAL;
  payload: Payloads[ActionTypes.OPEN_CONTINUE_ALERT_MODAL];
}

export interface CloseContinueAlertModalAction {
  type: ActionTypes.CLOSE_CONTINUE_ALERT_MODAL;
}

export interface FetchIsTouched {
  type: ActionTypes.SET_IS_TOUCHED;
}

export interface UpdateAudienceAction
  extends FSA<ActionTypes.UPDATE_AUDIENCE_STATE, Payloads[ActionTypes.UPDATE_AUDIENCE_STATE]> {
  type: ActionTypes.UPDATE_AUDIENCE_STATE;
  payload: Payloads[ActionTypes.UPDATE_AUDIENCE_STATE];
}

export const createSurveyTemplatesRequest = () => createRequest("/surveyTemplates");
export const createSurveyTypesRequest = () => createRequest("/surveyTypes");
export const createPostCategorysRequest = () => createRequest("/posts/categories");
export const createMergeTagsRequest =
  (surveyType: Survey.Type) => createRequest(`/surveyTypes/${encodeURIComponent(surveyType)}/mergeTags`);
function createSurveyBankQuestionsRequest(surveyTemplateId: string) {
  return createRequest(`/surveys/${surveyTemplateId}/bankQuestions`);
}

export const createSurveyRequest = (surveyTemplateId?: string, duplicateSurveyId?: string, linkDuplicate?: boolean) => {
  let body = null;
  if (surveyTemplateId) {
    body = new URLSearchParams(`surveyTemplateId=${surveyTemplateId}`);
  }
  if (duplicateSurveyId) {
    if (linkDuplicate) {
      body = new URLSearchParams(`surveyDuplicateId=${duplicateSurveyId}&linkDuplicate=true`);
    } else {
      body = new URLSearchParams(`surveyDuplicateId=${duplicateSurveyId}&linkDuplicate=false`);
    }

  }
  return createRequest("/surveys", {
    method: "POST",
    body,
  });
};

export const createGetSurveyInfoRequest =
  (surveyId: string) => createRequest(`/surveyInfos/${surveyId}`);

export function createGetSurveyInfosRequest(statuses?: string[], view?: string): Request {
  let queryString = "?";

  if (isNotEmptyArray(statuses)) {
    statuses.forEach( status => {
      queryString = queryString.concat("filter[status][]=" + status + "&");
    });
  }
  if (isStringAndNotEmpty(view)) {
    queryString = queryString.concat("view=" + view);
  }

  return createRequest("/surveyInfos" + queryString);
}

export const createLaunchSurveyInfoRequest =
  (surveyId: string) => createRequest(`/surveys/${surveyId}/launch`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });

export const createSendSurveyPreviewRequest =
  (surveyId: string, previewEmails: string[], message: string) => {
    const data = {
      previewEmails,
      message,
    };

    return createRequest(`/surveys/${surveyId}/previews`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  };

// network requests for access page UI
export const createGetAccessesListRequest =
  (surveyId: string) => createRequest(`/surveys/${surveyId}/accesses`);

export const createGetEligibleManagersCountRequest =
  (surveyId: string) => createRequest(`/surveys/${surveyId}/accesses/eligibleManagers/count`);

export const createGetManageesEmailsRequest =
  (userId: string, status?: string) =>
    isStringAndNotEmpty(status) ?
      createRequest(`/users/${userId}/managees?status=${status}`) :
      createRequest(`/users/${userId}/managees`);

export const createFetchPossibleActionAssigneesRequest =
  (actionId: string) => createRequest(`/actions/${actionId}/possibleAssignees`);

export const createToggleManagersSynchronizationRequest = (surveyId: string, areManagersSynchronized: boolean) => {
  const data = {
    areManagersSynchronized,
  };
  return createRequest(`/surveys/${surveyId}/accesses/toggleSynchronize`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const createAddNewAccessRequest =
  (surveyId: string, surveyAccesses: AccessResource[]) => {
    return createRequest(`/surveys/${surveyId}/accesses/`, {
      method: "POST",
      body: JSON.stringify(surveyAccesses),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

export const createModifyAccessRequest = (surveyId: string, accessId: string, surveyAccess: AccessResource) => {
  return createRequest(`/surveys/${surveyId}/accesses/${accessId}`, {
    method: "PUT",
    body: JSON.stringify(surveyAccess),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const createRemoveAccessRequest = (surveyId: string, accessId: string) => {
  return createRequest(`/surveys/${surveyId}/accesses/${accessId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// end of network requests for access UI

export const createUploadSurveyLogoRequest = (surveyId: string, image: any) => {
  const formData = new FormData();
  formData.append("logo", image);
  return createRequest("/surveys/" + surveyId + "/logo", {
    method: "POST",
    body: formData,
  });
};

export const createAudienceEmailsRequest = (entityId: string, entity: SurveyResourceRecord) => {
  return createRequest("/surveys/" + entityId + "/participants");
};

export const postAudienceEmailsRequest = (surveyId: string,survey: SurveyResource) =>
  createRequest("/surveys/" + surveyId + "/audience/participants" , {
    method: "PUT",
    body: JSON.stringify(survey),
    headers: {
      "Content-Type": "application/json",
    },
  });

export function getSurvey(surveyTemplateId: string) {
  return createRequest(`/surveys/${surveyTemplateId}?include[]=questions&include[]=accesses`);
}

export const updateSurveyRequest = (templateId: string, entity?: SurveyResource | PulsePollResource) => {

  // Update with poll API

  return createRequest(`/surveys/${templateId}`, {
    method: "PUT",
    body: JSON.stringify(entity),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

function updateQuestion(surveyTemplateId: string, questionId: string, question: SurveyQuestionResource) {
  return createRequest(`/surveys/${surveyTemplateId}/questions/${questionId}`, {
    method: "PUT",
    body: JSON.stringify(question),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function addQuestion(surveyTemplateId: string, question: SurveyQuestionResource | SurveyBankQuestionPayload) {
  return createRequest(`/surveys/${surveyTemplateId}/questions`, {
    method: "POST",
    body: JSON.stringify(question),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function removeQuestion(surveyTemplateId: string, questionId: string) {
  return createRequest(`/surveys/${surveyTemplateId}/questions/${questionId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function createGetParticipantsCountRequest(entityId: string,  entity: SurveyResourceRecord) {
  return createRequest(`/surveys/${entityId}/participantsCount`);
}

function createPostParticipantsCountRequest(surveyTemplateId: string, survey: SurveyResource) {
  return createRequest(`/surveys/${surveyTemplateId}/audience/participantsCount`, {
    method: "POST",
    body: JSON.stringify(survey),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function showReports(surveyId: string, {hasRatingQuestions, hasNPSQuestions}: QuestionScoreFlags) {
  if (hasRatingQuestions || hasNPSQuestions) {
    return goTo(
      `/surveys/view/${surveyId}/reports/overview`,
      Breadcrumb.stack("Surveys")
    );
  }
  goTo(
    `/surveys/view/${surveyId}/reports/questions`,
    Breadcrumb.stack("Surveys")
  );
}

export const actionCreators = {
  setIsTouched: (): any => ({
    type: ActionTypes.SET_IS_TOUCHED,
  }),
  fetchSurvey: (surveyTemplateId: string): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: getSurvey(surveyTemplateId),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => ({ type: ActionTypes.FETCH_SURVEY_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({ type: ActionTypes.FETCH_SURVEY_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({ type: ActionTypes.FETCH_SURVEY_ERROR, payload }),
      ],
    },
  }),
  fetchSurveyTemplates: (): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createSurveyTemplatesRequest(),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => ({ type: ActionTypes.FETCH_TEMPLATES_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({ type: ActionTypes.FETCH_TEMPLATES_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({ type: ActionTypes.FETCH_TEMPLATES_ERROR, payload }),
      ],
    },
  }),
  fetchSurveyTypes: (): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createSurveyTypesRequest(),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => ({ type: ActionTypes.FETCH_SURVEY_TYPE_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({ type: ActionTypes.FETCH_SURVEY_TYPE_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({ type: ActionTypes.FETCH_SURVEY_TYPE_ERROR, payload }),
      ],
    },
  }),
  fetchPostCategorys: (): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createPostCategorysRequest(),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => ({ type: ActionTypes.FETCH_POST_CATEGORY_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({ type: ActionTypes.FETCH_POST_CATEGORY_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({ type: ActionTypes.FETCH_POST_CATEGORY_ERROR, payload }),
      ],
    },
  }),
  fetchMergeTags: (surveyType: Survey.Type): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createMergeTagsRequest(surveyType),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => ({ type: ActionTypes.FETCH_MERGE_TAG_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: ActionTypes.FETCH_MERGE_TAG_SUCCESS, payload, surveyType }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({ type: ActionTypes.FETCH_MERGE_TAG_ERROR, payload }),
      ],
    },
  }),
  fetchSurveyInfos: (statuses?: string[], view?: string): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createGetSurveyInfosRequest(statuses, view),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: ActionTypes.FETCH_SURVEY_INFOS_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: ActionTypes.FETCH_SURVEY_INFOS_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({ type: ActionTypes.FETCH_SURVEY_INFOS_ERROR, payload }),
      ],
    },
  }),
  getSurveyInfo: (surveyId: string): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createGetSurveyInfoRequest(surveyId),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: ActionTypes.GET_SURVEY_INFO_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: ActionTypes.GET_SURVEY_INFO_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({ type: ActionTypes.GET_SURVEY_INFO_ERROR, payload }),
      ],
    },
  }),
  fetchAudienceEmails: (entityId: string, entity: SurveyResourceRecord): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createAudienceEmailsRequest(entityId, entity),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: ActionTypes.FETCH_AUDIENCE_EMAIL_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: ActionTypes.FETCH_AUDIENCE_EMAIL_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({ type: ActionTypes.FETCH_AUDIENCE_EMAIL_ERROR, payload }),
      ],
    },
  }),
  postAudienceEmails: (surveyId: string, survey: SurveyResource ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: postAudienceEmailsRequest(surveyId,survey),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: ActionTypes.FETCH_AUDIENCE_EMAIL_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: ActionTypes.FETCH_AUDIENCE_EMAIL_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({ type: ActionTypes.FETCH_AUDIENCE_EMAIL_ERROR, payload }),
      ],
    },
  }),
  fetchSurveyBankQuestions: (surveyTemplateId: string): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createSurveyBankQuestionsRequest(surveyTemplateId),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: ActionTypes.FETCH_SURVEY_BANK_QUESTIONS_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: ActionTypes.FETCH_SURVEY_BANK_QUESTIONS_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: ActionTypes.FETCH_SURVEY_BANK_QUESTIONS_ERROR, payload }),
      ],
    },
  }),
  createSurvey: (
    surveyTemplateId?: string,
    duplicateSurveyId?: string,
    linkDuplicate?: boolean,
    onSuccessRedirect?: (payload: NetworkEventSuccessAction["payload"]) => void): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createSurveyRequest(surveyTemplateId, duplicateSurveyId, linkDuplicate),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => ({ type: ActionTypes.CREATE_SURVEY_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({ type: ActionTypes.CREATE_SURVEY_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({ type: ActionTypes.CREATE_SURVEY_ERROR, payload }),
      ],
      onSuccessRedirect,
    },
  }),
  uploadSurveyLogo: (surveyId: string, image: any) => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createUploadSurveyLogoRequest(surveyId, image),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => ({ type: ActionTypes.UPLOAD_SURVEY_LOGO_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({ type: ActionTypes.UPLOAD_SURVEY_LOGO_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({ type: ActionTypes.UPLOAD_SURVEY_LOGO_ERROR, payload }),
      ],
    },
  }),

  updateSurvey: (surveyTemplateId: string, survey: SurveyResource): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: updateSurveyRequest(surveyTemplateId, survey),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => (
          { type: ActionTypes.UPDATE_SURVEY_REQUEST, payload, meta : {survey}}),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({ type: ActionTypes.UPDATE_SURVEY_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({ type: ActionTypes.UPDATE_SURVEY_ERROR, payload }),
      ],
    },
  }),
  // eslint-disable-next-line max-len
  updateSurveyAndTriggerFetchAudience: (surveyTemplateId: string, survey?: SurveyResource | PulsePollResource): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: updateSurveyRequest(surveyTemplateId, survey),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: ActionTypes.UPDATE_SURVEY_AND_TRIGGER_FETCH_AUDIENCE_REQUEST, payload, meta : {survey}}),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({
            type: ActionTypes.UPDATE_SURVEY_AND_TRIGGER_FETCH_AUDIENCE_SUCCESS,
            payload,
            meta: { surveyId: surveyTemplateId },
          }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: ActionTypes.UPDATE_SURVEY_AND_TRIGGER_FETCH_AUDIENCE_ERROR, payload }),
      ],
    },
  }),
  // fixme: add different ActionType for poll state management
  updateAudience: (payload: UpdateAudienceAction["payload"]): UpdateAudienceAction => ({
    type: ActionTypes.UPDATE_AUDIENCE_STATE,
    payload,
  }),
  fetchSurveyAccessList: (surveyId: string): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createGetAccessesListRequest(surveyId),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => ({ type: ActionTypes.FETCH_ACCESS_LIST_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({ type: ActionTypes.FETCH_ACCESS_LIST_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({ type: ActionTypes.FETCH_ACCESS_LIST_ERROR, payload }),
      ],
    },
  }),
  fetchEligibleManagersCount: (surveyId: string): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createGetEligibleManagersCountRequest(surveyId),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => ({
          type: ActionTypes.FETCH_ELIGIBLE_MANAGERS_COUNT_REQUEST, payload,
        }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({
          type: ActionTypes.FETCH_ELIGIBLE_MANAGERS_COUNT_SUCCESS, payload,
        }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({
          type: ActionTypes.FETCH_ELIGIBLE_MANAGERS_COUNT_ERROR, payload,
        }),
      ],
    },
  }),
  fetchManageesEmails: (userId: string, status?: string): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createGetManageesEmailsRequest(userId, status),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => ({
          type: ActionTypes.FETCH_MANAGEES_EMAILS_REQUEST, payload,
        }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({
          type: ActionTypes.FETCH_MANAGEES_EMAILS_SUCCESS, payload,
        }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({
          type: ActionTypes.FETCH_MANAGEES_EMAILS_ERROR, payload,
        }),
      ],
    },
  }),
  fetchPossibleActionAssignees: (actionId: string): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createFetchPossibleActionAssigneesRequest(actionId),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => ({
          type: ActionTypes.FETCH_POSSIBLE_ACTION_ASSIGNEES_REQUEST, payload,
        }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({
          type: ActionTypes.FETCH_POSSIBLE_ACTION_ASSIGNEES_SUCCESS, payload,
        }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({
          type: ActionTypes.FETCH_POSSIBLE_ACTION_ASSIGNEES_ERROR, payload,
        }),
      ],
    },
  }),
  toggleManagersSynchronization: (surveyId: string, areManagersSynchronized: boolean): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createToggleManagersSynchronizationRequest(surveyId, areManagersSynchronized),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => ({
          type: ActionTypes.TOGGLE_MANAGERS_SYNCHRONIZATION_REQUEST, payload,
        }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({
          type: ActionTypes.TOGGLE_MANAGERS_SYNCHRONIZATION_SUCCESS, payload,
        }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({
          type: ActionTypes.TOGGLE_MANAGERS_SYNCHRONIZATION_ERROR, payload,
        }),
      ],
    },
  }),

  addNewAccess: (surveyId: string, surveyAccesses: AccessResource[])
  : NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createAddNewAccessRequest(surveyId, surveyAccesses),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => (
          { type: ActionTypes.ADD_SURVEY_ACCESS_REQUEST, payload, meta: { surveyAccesses } }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({
          type: ActionTypes.ADD_SURVEY_ACCESS_SUCCESS, payload,
        }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({
          type: ActionTypes.ADD_SURVEY_ACCESS_ERROR, payload,
        }),
      ],
    },
  }),

  addLocalNewAccess: (surveyId: string, surveyAccesses: AccessResource[])
  : NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createAddNewAccessRequest(surveyId, surveyAccesses),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => (
          { type: ActionTypes.ADD_LOCAL_SURVEY_ACCESS_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({
          type: ActionTypes.ADD_LOCAL_SURVEY_ACCESS_SUCCESS, payload, meta: { surveyAccesses },
        }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({
          type: ActionTypes.ADD_LOCAL_SURVEY_ACCESS_ERROR, payload,
        }),
      ],
    },
  }),

  modifyAccess: (surveyId: string, accessId: string, surveyAccess: AccessResource): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createModifyAccessRequest(surveyId, accessId, surveyAccess),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => ({
          type: ActionTypes.MODIFY_ACCESS_REQUEST,
          payload,
          meta: { surveyAccess },
        }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({
          type: ActionTypes.MODIFY_ACCESS_SUCCESS,
          payload,
        }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({
          type: ActionTypes.MODIFY_ACCESS_ERROR, payload,
        }),
      ],
    },
  }),

  removeAccess: (surveyId: string, accessId: string): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createRemoveAccessRequest(surveyId, accessId),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => (
          { type: ActionTypes.REMOVE_SURVEY_ACCESS_REQUEST, payload, meta: { accessId } }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({
          type: ActionTypes.REMOVE_SURVEY_ACCESS_SUCCESS, payload,
        }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({
          type: ActionTypes.REMOVE_SURVEY_ACCESS_ERROR, payload,
        }),
      ],
    },
  }),

  getParticipantsCount: (entityId: string,  entity: SurveyResourceRecord): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createGetParticipantsCountRequest(entityId, entity),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => (
          { type: ActionTypes.FETCH_PARTICIPANTS_COUNT_REQUEST, payload}),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({
          type: ActionTypes.FETCH_PARTICIPANTS_COUNT_SUCCESS, payload,
        }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({
          type: ActionTypes.FETCH_PARTICIPANTS_COUNT_ERROR, payload,
        }),
      ],
    },
  }),

  postParticipantsCount: (surveyId: string, survey: SurveyResource): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createPostParticipantsCountRequest(surveyId, survey),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => (
          { type: ActionTypes.FETCH_PARTICIPANTS_COUNT_REQUEST, payload}),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({
          type: ActionTypes.FETCH_PARTICIPANTS_COUNT_SUCCESS, payload,
        }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({
          type: ActionTypes.FETCH_PARTICIPANTS_COUNT_ERROR, payload,
        }),
      ],
    },
  }),

  updateAccessPage: (payload: UpdateAccessPageAction["payload"]): UpdateAccessPageAction => ({
    type: ActionTypes.UPDATE_ACCESS_PAGE_STATE,
    payload,
  }),
  updateSurveyFormErrors: (payload: UpdateSurveyFormErrorAction["payload"]): UpdateSurveyFormErrorAction => ({
    type: ActionTypes.UPDATE_SURVEY_FORM_ERRORS,
    payload,
  }),
  removeSurveyFormErrors: (payload: RemoveSurveyFormErrorAction["payload"]): RemoveSurveyFormErrorAction => ({
    type: ActionTypes.REMOVE_SURVEY_FORM_ERRORS,
    payload,
  }),
  openContinueAlertModal: (payload: OpenContinueAlertModalAction["payload"]): OpenContinueAlertModalAction => ({
    type: ActionTypes.OPEN_CONTINUE_ALERT_MODAL,
    payload,
  }),
  closeContinueAlertModal: (): CloseContinueAlertModalAction => ({
    type: ActionTypes.CLOSE_CONTINUE_ALERT_MODAL,
  }),

  toggleQuestionsModalState: (isModalOpen: boolean, modalPurpose?: SURVEY_QUESTIONS_MODALS) => ({
    type: ActionTypes.TOGGLE_QUESTIONS_MODAL_STATE,
    isModalOpen,
    modalPurpose,
  }),

  openSurveyPreviewValidationModal: () => ({
    type: ActionTypes.OPEN_SURVEY_PREVIEW_VALIDATION_MODAL,
  }),
  closeSurveyPreviewValidationModal: () => ({
    type: ActionTypes.CLOSE_SURVEY_PREVIEW_VALIDATION_MODAL,
  }),

  openSurveyPreviewModal: () => ({
    type: ActionTypes.OPEN_SURVEY_PREVIEW_MODAL,
  }),
  closeSurveyPreviewModal: () => ({
    type: ActionTypes.CLOSE_SURVEY_PREVIEW_MODAL,
  }),

  openCancelSurveyCreationModal: () => ({
    type: ActionTypes.OPEN_CANCEL_SURVEY_CREATION_MODAL,
  }),

  closeCancelSurveyCreationModal: () => ({
    type: ActionTypes.CLOSE_CANCEL_SURVEY_CREATION_MODAL,
  }),

  clearCreateSurveyState: () => ({
    type: ActionTypes.CLEAR_CREATE_SURVEY_STATE,
  }),

  updateSurveyQuestion: (
    surveyTemplateId: string,
    questionId: string,
    question: SurveyQuestionResource,
    index: number
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: updateQuestion(surveyTemplateId, questionId, question),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: ActionTypes.UPDATE_QUESTION_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({
          type: ActionTypes.UPDATE_QUESTION_SUCCESS,
          payload, meta: { index },
        }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({ type: ActionTypes.UPDATE_QUESTION_ERROR, payload }),
      ],
    },
  }),
  addSurveyQuestion: (
    surveyTemplateId: string,
    question: SurveyQuestionResource | SurveyBankQuestionPayload
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: addQuestion(surveyTemplateId, question),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: ActionTypes.ADD_QUESTION_REQUEST, payload, meta: { question } }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({ type: ActionTypes.ADD_QUESTION_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({ type: ActionTypes.ADD_QUESTION_ERROR, payload }),
      ],
    },
  }),
  removeSurveyQuestion: (
    surveyTemplateId: string,
    questionId: string,
    index: number
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: removeQuestion(surveyTemplateId, questionId),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: ActionTypes.REMOVE_QUESTION_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({
          type: ActionTypes.REMOVE_QUESTION_SUCCESS,
          payload,
          meta: { index, questionId },
        }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({ type: ActionTypes.REMOVE_QUESTION_ERROR, payload }),
      ],
    },
  }),
  launchSurvey: (
    surveyTemplateId: string
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createLaunchSurveyInfoRequest(surveyTemplateId),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: ActionTypes.LAUNCH_SURVEY_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({ type: ActionTypes.LAUNCH_SURVEY_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({ type: ActionTypes.LAUNCH_SURVEY_ERROR, payload }),
      ],
    },
  }),

  sendSurveyPreview: (
    surveyTemplateId: string,
    previewEmails: string[],
    message: string,
    onSuccessRedirect?: (payload: NetworkEventSuccessAction["payload"]) => void): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createSendSurveyPreviewRequest(surveyTemplateId, previewEmails, message),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => ({ type: ActionTypes.SEND_SURVEY_PREVIEW, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({
          type: ActionTypes.SEND_SURVEY_PREVIEW_SUCCESS, payload,
        }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({ type: ActionTypes.SEND_SURVEY_PREVIEW_ERROR, payload }),
      ],
      onSuccessRedirect,
    },
  }),
};

export default actionCreators;
