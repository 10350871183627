import React from "react";
import { ModalProps } from "antd/lib/modal";
import styled from "styled-components";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";

import Modal from "@components/core/Modal";
import Button from "@components/core/Button";
import Paragraph from "@components/core/Typography/Paragraph";

import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { USER_PERMISSION_MODAL_MODES, permissionsModalContentMapper } from "../utils/constants";
import { EditAccessModalContent } from "./EditAccessModalContent";
import { EditRoleModalContent } from "./EditRoleModalContent";
import { Trans, WithTranslation, withTranslation } from "react-i18next";

export interface Props extends ModalProps, WithTranslation {
  selectedUsersCount: number;
  availableOptions?: string[];
  mode: USER_PERMISSION_MODAL_MODES;
  isLoading: boolean;
  onSubmit: (value: any) => void;
}

interface StateProps {
  value: string;
}

export interface UserPermissionModalBaseProps {
  value: any;
  onChange?: (value: any) => void;
}

export class BulkEditPermissions extends React.Component<Props, StateProps> {

  constructor(props: Props) {
    super(props);

    const { availableOptions, mode } = props;

    const value = isNotEmptyArray(availableOptions)
      ? availableOptions[0]
      : permissionsModalContentMapper(mode).getSelectOptions()[0];
    this.state = {
      value,
    };
  }

  onChange = (value: any) => this.setState({ value });

  renderContent = () => {
    const { value } = this.state;
    const { availableOptions, mode } = this.props;
    switch (mode) {
      case "access":
        return (
          <EditAccessModalContent
            value={value}
            onChange={this.onChange}
            availableOptions={
              isNotNullNorUndefined(availableOptions)
                ? availableOptions
                : permissionsModalContentMapper(mode).getSelectOptions()
            }
          />
        );
      case "role":
        return isNotEmptyArray(availableOptions) ? (
          <EditRoleModalContent
            value={value}
            onChange={this.onChange}
            availableRoles={availableOptions}
          />
        ) : <Paragraph translate="yes">No roles available</Paragraph>;
    }
  };

  render() {
    const { selectedUsersCount, mode, isLoading, onSubmit, t, ...rest } = this.props;
    const { value } = this.state;
    return (
      <StyledModal
        title={<Trans>{permissionsModalContentMapper(mode).getTitle()}</Trans>}
        footer={[
          <Button
            key="submit"
            color="blue"
            onClick={onSubmit.bind(null, value)}
            disabled={isLoading}
            translate="yes"
          >
            {isLoading ? "Saving..." : "Save changes"}
          </Button>,
        ]}
        {...rest}
      >
        <Container>
          <Paragraph translate="yes">
            {permissionsModalContentMapper(mode).getDescription(t, selectedUsersCount)}
          </Paragraph>
          <Content>
            {this.renderContent()}
          </Content>
        </Container>
      </StyledModal>
    );
  }
}

export const BulkEditPermissionsModal = withTranslation()(BulkEditPermissions);

const Container = styled.div`
  padding: 0 32px;
`;

const Content = styled.div`
  margin: 16px 0;
`;

const StyledModal = styled(Modal)<ModalProps & {children: React.ReactNode}>``;
