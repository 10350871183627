import { Spin as AntSpin } from "antd";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { SpinProps } from "antd/lib/spin";

export interface SpinnerProps extends SpinProps {
  color?: "grey" | "blue";
}

const blueSpinner = `
  .ant-spin-dot i {
    background-color: ${Palette.aquaBlue} !important;
    background: ${Palette.aquaBlue} !important;
  }
`;

const greySpinner = `
  .ant-spin-dot i {
    background-color: ${Palette.lightGreyBlue} !important;
    background: ${Palette.lightGreyBlue} !important;
  }
`;

function getSpinColor(props: SpinnerProps) {
  switch (props.color) {
    case "blue":
      return blueSpinner;
    case "grey":
      return greySpinner;
    default:
      return blueSpinner;
  }
}

const Spin = styled(AntSpin) <SpinnerProps>`
  ${props => getSpinColor(props)}
`;

export default Spin;