// @ts-nocheck
import React from "react";
export function PlusSignSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <defs>
        {/* eslint-disable max-len */}
        <path id="plusSignA" d="M7.5 0C3.364 0 0 3.365 0 7.5 0 11.636 3.365 15 7.5 15c4.136 0 7.5-3.364 7.5-7.5S11.636 0 7.5 0m0 13.5c-3.308 0-6-2.692-6-6s2.692-6 6-6 6 2.692 6 6-2.692 6-6 6m2.78-8.78a.75.75 0 0 0-1.06 0L7.5 6.44 5.78 4.72a.75.75 0 1 0-1.06 1.06L6.44 7.5 4.72 9.22a.75.75 0 1 0 1.06 1.06L7.5 8.56l1.72 1.72a.748.748 0 0 0 1.06 0 .75.75 0 0 0 0-1.06L8.56 7.5l1.72-1.72a.75.75 0 0 0 0-1.06"/>
        {/* eslint-disable max-len */}
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M8-2.728L18.607 7.88 8 18.485-2.607 7.88z"/>
        <g transform="rotate(45 7.293 8.293)">
          <mask id="plusSignB" fill="#fff">
            <use xlinkHref="#plusSignA"/>
          </mask>
          <use fill="#919EAB" xlinkHref="#plusSignA"/>
          <g fill="#AAB9CE" mask="url(#plusSignB)">
            <path d="M0 0h15v15H0z"/>
          </g>
        </g>
      </g>
    </svg>
  );
}
