import React from "react";
import ContainerCard from "@src/components/core/ContainerCard";

export function Error403() {
  return (
    <ContainerCard
      title="Access Required"
      data-cy="access_error_message"
      description="You do not have permission to access this page. Please contact your administrator."
    />
  );
}
