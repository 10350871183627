import React from "react";
import { useTranslation } from "react-i18next";

import { PulsePollResource } from "hyphen-lib/dist/domain/resource/PulsePollResource";
import ContainerCard from "@src/components/core/ContainerCard";
import styled from "styled-components";
import { getOr, isNotNullNorUndefined, isNullOrUndefined } from "hyphen-lib/dist/lang/Objects";
import { getQuestionTypeLabel, QuestionTypeLabel } from "hyphen-lib/dist/business/question/Questions";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import { toHumanReadableErrorMessage } from "@src/utils/formValidations";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { QuestionConfig } from "hyphen-lib/dist/domain/common/QuestionType";
import { PulsePollDisabledFields } from "../../store/pulsePollEditTypes";
import { AddOrEditQuestion, AddOrEditQuestionProps } from "../../../components/Wizard/Questions/AddOrEditQuestion";

interface QuestionProps {
  readonly pulsePoll: PulsePollResource;
  readonly disabled: PulsePollDisabledFields;
  readonly errors: WrongEntityException.Errors;
  readonly questionConfig: QuestionConfig;
  readonly isMultiSelectQuestionEnabled: boolean;
  readonly onChange: (pulsePoll: PulsePollResource) => void;
  readonly onAddCustomCategory?: (category: any) => void;
}

export function Question(props: QuestionProps) {
  const { t } = useTranslation();

  const {
    pulsePoll: {
      questionType,
      question,
      isSentimentAnalysisQuestion,
      isMultiSelect,
      maxNumberOfOptions,
    },
    questionConfig,
    isMultiSelectQuestionEnabled,
    disabled,
    errors,
    onAddCustomCategory,
  } = props;

  const handleChange = (modifications: Partial<PulsePollResource>) => {
    const {
      pulsePoll,
      onChange,
    } = props;

    onChange({
      ...pulsePoll as any,
      ...modifications,
    });
  };

  const handleAddCustomCategory = (category: any) => {
    if (isNotNullNorUndefined(onAddCustomCategory)) {
      onAddCustomCategory(category);
    }
  };

  const mappedErrors = errors.fields.map((val, fieldKey) =>
    toHumanReadableErrorMessage(t, val, fieldKey)!
  );

  let commonProps: AddOrEditQuestionProps = {
    title: "",
    questionType,
    question,
    hasCategory: false,
    disabled,
    questionOrigin: "",
    isSentimentAnalysisQuestion: getOr(isSentimentAnalysisQuestion, false),
    onChange: handleChange,
    initialErrors: mappedErrors,
    onAddCustomCategory: handleAddCustomCategory,
    isMultiSelectQuestionEnabled,
    isMultiSelect,
    questionConfig,
    maxNumberOfOptions: getOr(maxNumberOfOptions, 3),
    enableTranslation: false,
    isMultiLanguageSurveyEnabled: false,
    isUpdatingQuestion: false,
    t,
    isPulsePoll: true
  };


    const { isRatingQuestion, allowComment, choices, isNPSQuestion } = props.pulsePoll;
    const questionTypeLabel = getQuestionTypeLabel(
      commonProps.questionType!,
      questionConfig,
      choices,
      getOr(isRatingQuestion, true),
      getOr(isNPSQuestion, false),
      getOr(isMultiSelect, false)
    );
    const isCustomQuestionWithLessThanTwoChoices = (questionTypeLabel === "Multiple Choice" && choices.length < 2);
    const isLikertQuestionType = questionTypeLabel === QuestionTypeLabel.Likert;
    const isSentimentAnalysisFlagUndefined = isNullOrUndefined(isSentimentAnalysisQuestion);
    const isOpenQuestionType = questionTypeLabel === QuestionTypeLabel.Open;
    commonProps = Object.assign({}, commonProps, {
      isRatingQuestion,
      isNPSQuestion,
      allowComment,
      choices,
      allowedQuestionFlags: {
        allowComment: {
          checked: allowComment,
          disabled: props.pulsePoll.status !== "draft",
          label: "Allow voters to comment",
          info:
            "Decide whether or not employees can submit comments with this question.",
        },
        isSentimentAnalysisQuestion: {
          checked: isSentimentAnalysisFlagUndefined ? true : isSentimentAnalysisQuestion,
          disabled: props.pulsePoll.status !== "draft",
          label: "Include comments in sentiment analysis",
          info:
            "Check if the comments for this question need to be analyzed for sentiment",
        },
        isNPSQuestion: {
          info: "All eNPS questions are included in eNPS calculations",
          disabled: true,
          checked: true,
          label: "Include in eNPS score calculation",
        },
        isRatingQuestion: {
          checked: isLikertQuestionType || (not(isOpenQuestionType) && isRatingQuestion),
          disabled: disabled.isRatingQuestion || isCustomQuestionWithLessThanTwoChoices || isLikertQuestionType,
          info: "All Likert Scale questions are included in Favorability calculations",
          label: "Include in favorability calculation",
        },
      },
    });
  return (
    <ContainerCard title="Question settings">
      <FormContainer>
        <AddOrEditQuestion
          {...commonProps}
        />
      </FormContainer>
    </ContainerCard>
  );
}

const FormContainer = styled.div`
  margin: 0 270px;
`;
