import React from "react";
import styled from "styled-components";

import { Optional } from "@hyphen-lib/lang/Optionals";
import { isNotNullNorUndefined, getOr, isNullOrUndefined, mapOr } from "@hyphen-lib/lang/Objects";
import { isEmpty } from "@hyphen-lib/lang/Arrays";
import { isNumber } from "@hyphen-lib/lang/Number";
import Palette from "@src/config/theme/palette";
import Tooltip from "@components/core/Tooltip";
import ReactResizeDetector from "react-resize-detector";
import moment from "moment-timezone";
import { getMonthLabel, getBeginningOfMonth, addMonths, utcNow } from "hyphen-lib/dist/lang/Dates";
import { isEqual, Dictionary } from "lodash";
import { trans } from "@src/utils/i18next";

export type LineChartData = [string, Optional<number>, any?];

export interface CustomLineChartToolTipProps {
  graphKey?: string;
  value?: Optional<number>;
  uniqueValue?: Optional<number>;
}

export interface HighlightedDataPoint {
  value: LineChartData;
  color?: string;
}

const SHORT_MONTH: Dictionary<string> = {
  "January": "Jan",
  "February": "Feb",
  "March": "Mar",
  "April": "Apr",
  "May": "May",
  "June": "Jun",
  "July": "Jul",
  "August": "Aug",
  "September": "Sep",
  "October": "Oct",
  "November": "Nov",
  "December": "Dec",
};

interface Props {
  data: LineChartData[];
  toolTipText?: string;
  showCurrent?: boolean;
  onDataPointClick?: (data: LineChartData, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  customToolTipText?: string | React.ReactElement<CustomLineChartToolTipProps>;
  highlightDataPoint?: HighlightedDataPoint;
  shouldShowExtendedMonths?: boolean;
  minAxisY: number;
  maxAxisY: number;
  minMonths?: number;
  labelWithYear?: boolean;
}

interface Position {
  x: number;
  y: number;
}

interface DataPointProps {
  positionTop: number;
  positionLeft: number;
  highlightedDataPoint?: HighlightedDataPoint;
}

const defaultProps = Object.freeze({
  minAxisY: 0,
  maxAxisY: 100,
  labelWithYear: false,
});

const range = (start: number, stop: number, step = 1) =>
  Array(Math.ceil((stop - start) / step + 1)).fill(start).map((x, y) => x + y * step);

const getLineLength = (start: Position, end: Position) => {
  return Math.sqrt(Math.pow((end.y - start.y), 2) + Math.pow((end.x - start.x), 2));
};

const getLineRotation = (start: Position, end: Position) => {
  return Math.atan2(end.y - start.y, end.x - start.x) * 180 / Math.PI;
};

function isReactElement(arg: any): arg is React.ReactElement<CustomLineChartToolTipProps> {
  return arg.props !== undefined;
}

export class LineChart extends React.Component<Props> {
  static readonly defaultProps = defaultProps;
  chartRef: HTMLDivElement | null = null;
  chartHeight = 144;
  chartVerticalLabelsWidth = 48;
  chartHorizontalMargin = 24;

  state = {
    chartWidth: 300,
  };

  generateDataPoints = (data: LineChartData[], maxAxisY: number, axisYRange: number) => {
    const firstValidData = data.find(([, value]) => isNumber(value));
    const nullIndex = isNotNullNorUndefined(firstValidData) ?
      data.indexOf(firstValidData) : null;
    let lastValidNumber = firstValidData ? firstValidData[1] : 0;

    let lastValidIndex = data.length - 1;
    const generatedDataPoints = data.map(([key, value, uniqueValue], index) => {
      const previousValue = isNotNullNorUndefined(data[index - 1]) ? data[index - 1][1] : null;
      const nextValue = isNotNullNorUndefined(data[index + 1]) ? data[index + 1][1] : null;
      let newValue = value;
      if (isNotNullNorUndefined(newValue)) {
        lastValidNumber = newValue;
      } else {
        newValue = lastValidNumber;
      }

      if (isNotNullNorUndefined(value) && !nextValue) {
        lastValidIndex = index;
      }

      const pointValue = isNotNullNorUndefined(newValue)
        ? newValue
        : isNotNullNorUndefined(previousValue) ? previousValue : 0;
      const positionTop = isNotNullNorUndefined(nullIndex) && index < nullIndex ? 144
        : (maxAxisY - pointValue) / axisYRange * this.chartHeight;
      const positionLeft = index / (data.length - 1) * this.state.chartWidth;
      const tooltipText = this.getToolTipText(key, value, uniqueValue);
      const month = key;
      return {
        value,
        pointValue,
        positionTop,
        positionLeft,
        tooltipText,
        month,
        uniqueValue,
      };
    });

    generatedDataPoints.splice(lastValidIndex + 1);
    return generatedDataPoints;
  };

  getToolTipText = (key: string, value: any, uniqueValue?: any) => {
    const { customToolTipText } = this.props;
    if (isNullOrUndefined(value)) {
      return `${trans("Not enough data in")} - ${key}`;
    }
    if (isNotNullNorUndefined(customToolTipText)) {
      if (isReactElement(customToolTipText)) {
        return React.cloneElement<CustomLineChartToolTipProps>(
          customToolTipText,
          { graphKey: key, value, uniqueValue }
        );
      } else {
        return customToolTipText;
      }
    } else {
      return `${key}: ${value}%`;
    }
  };

  generateMonths = (data: LineChartData[]) => {
    return data.map(([key, value], index) => {
      const month = key;
      const positionLeft = index / (data.length -   1 ) * this.state.chartWidth;
      return {
        month,
        positionLeft,
      };
    });
  };

  setChartWidth = () => {
    if (this.chartRef) {
      const chartWidth = this.chartRef.offsetWidth - 2 * this.chartHorizontalMargin;
      this.setState({ chartWidth });
    }
  };

  componentDidMount() {
    this.setChartWidth();
    window.addEventListener("resize", this.setChartWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setChartWidth);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.data.length !== this.props.data.length) {
      this.setChartWidth();
    }
  }

  getLastPointIndex(dataPoints: any[]) {
    for (let idx = 0; idx < dataPoints.length; idx++) {
      const idxFromLast = dataPoints.length - 1 - idx;
      if (isNotNullNorUndefined(dataPoints[idxFromLast])) {
        return idxFromLast - 1;
      }
    }
    return -1;
  }

  render() {
    const {
      data,
      onDataPointClick = () => {
        return;
      },
      showCurrent = true,
      highlightDataPoint,
      shouldShowExtendedMonths = true,
      minMonths = 8,
      labelWithYear,
    } = this.props;
    if (isEmpty(data)) {
      return null;
    }

    const firstValidDataIndex = data.findIndex(([, value]) => isNumber(value));
    data.splice(0, firstValidDataIndex);

    const currentMonth = getBeginningOfMonth(utcNow());
    let endMonth = moment(addMonths(currentMonth, -2));
    const endMonthString = data[data.length - 1][0] ;
    if (endMonthString && moment().month(endMonthString)) {
      endMonth = moment().month(endMonthString);
    }

    const noOfMonthsToAdd = minMonths - data.length;
    for (let index = 0; index < noOfMonthsToAdd; index++) {
      let nextMonth = shouldShowExtendedMonths
        ? getMonthLabel(endMonth.add("M", 1).toDate())
        : null;

      nextMonth = labelWithYear && nextMonth ? SHORT_MONTH[nextMonth] + " " + endMonth.year() : nextMonth;

      data.push([nextMonth as string, null]);
    }

    const minAxisY = this.props.minAxisY;
    const maxAxisY = this.props.maxAxisY;
    const axisYRange = maxAxisY - minAxisY;

    const yAxes = range(maxAxisY, minAxisY, -10);
    const dataPoints = this.generateDataPoints(data, maxAxisY, axisYRange);
    const dataMonths = this.generateMonths(data);

    const lastPointIndex = this.getLastPointIndex(dataPoints);

    return (
      <Container height={this.chartHeight}>
        <ReactResizeDetector handleWidth onResize={this.setChartWidth}>
          <Chart ref={element => { this.chartRef = element; }} labelsWidth={this.chartVerticalLabelsWidth}>
            <HorizontalAxes>
              {yAxes.map((value, index) => {
                const positionTop = index / (yAxes.length - 1);
                const hasLabel = index % 2;
                return (
                  <AxisY key={index} positionTop={positionTop}>
                    {hasLabel ? <AxisYLabel labelsWidth={this.chartVerticalLabelsWidth}>{value}%</AxisYLabel> : null}
                  </AxisY>
                );
              })}
            </HorizontalAxes>
            <DataPoints showCurrent={showCurrent} horizontalMargin={this.chartHorizontalMargin}>
              {dataPoints.map(
                ({
                  value,
                  pointValue,
                  positionTop,
                  positionLeft,
                  tooltipText,
                  month,
                  uniqueValue,
                }, index) => {
                  const nextPoint = dataPoints[index + 1];
                  const highlightedDataPoint = mapOr(
                    highlightDataPoint,
                    map => map.value,
                    undefined
                  );
                  const isHighlighted = isEqual(highlightedDataPoint, [month, value, uniqueValue]);

                  const lineStartPosition = {
                    x: positionLeft,
                    y: positionTop,
                  };
                  const lineEndPosition = isNotNullNorUndefined(nextPoint) ? {
                    x: nextPoint.positionLeft,
                    y: nextPoint.positionTop,
                  } : null;
                  return (
                    <React.Fragment key={index}>
                      {
                        isNotNullNorUndefined(lineEndPosition) &&
                      <Line
                        start={lineStartPosition}
                        end={lineEndPosition}
                        last={showCurrent && index === lastPointIndex}
                      />
                      }
                      <Tooltip title={tooltipText}>
                        <DataPoint
                          onClick={
                            onDataPointClick.bind(null, [month, value, uniqueValue])
                          }
                          positionTop={positionTop}
                          positionLeft={positionLeft}
                          highlightedDataPoint={isHighlighted ? highlightDataPoint : undefined}
                        >
                          {isNotNullNorUndefined(value) && (
                            <DataPointLabel highlightedDataPoint={isHighlighted ? highlightDataPoint : undefined}
                              data-cy={`dataPointLabel_${index}`}
                            >
                              {value}%
                            </DataPointLabel>
                          )}
                        </DataPoint>
                      </Tooltip>
                    </React.Fragment>
                  );
                })}
            </DataPoints>
            <XAxis>
              {dataMonths.map(({ positionLeft , month }, index) => {
                return <XDataPoint
                  key={index}
                  data-cy={`xDataPoint_${index}`}
                  positionLeft={positionLeft}>{month}</XDataPoint>;
              })}
            </XAxis>
          </Chart>
        </ReactResizeDetector>
      </Container>
    );
  }
}

const Container = styled.div<{ height?: number }>`
  font-family: Lato, sans-serif;
  height: ${props => getOr(props.height, 144)}px;
  width: 100%;
`;

const AxisYLabel = styled.span<{ labelsWidth: number }>`
  position: absolute;
  left: -${props => getOr(props.labelsWidth, 0)}px;
  font-size: 10px;
  transform: translateY(-50%);
  color: ${Palette.bluishGrey};
`;

const AxisY = styled.div<{ positionTop: number }>`
  position: absolute;
  top: ${props => props.positionTop * 100}%;
  height: 2px;
  width: 100%;
  background: ${Palette.paleGrey};
`;

const HorizontalAxes = styled.div`
  div:last-of-type {
    background: ${Palette.lightPeriwinkle};
  }
`;

const Chart = styled.div<{ labelsWidth: number }>`
  position: relative;
  height: inherit;
  margin-left: ${props => getOr(props.labelsWidth, 0)}px;
`;

const Line = styled.div<{ start: Position; end: Position; last: boolean }>`
  position: absolute;
  top: ${props => props.start.y}px;
  left: ${props => props.start.x}px;
  width: ${props => getLineLength(props.start, props.end)}px;
  height: 3px;
  transform: translateY(-50%) rotate(${props => getLineRotation(props.start, props.end)}deg);
  transform-origin: 0 0;
  background: ${
  props => props.last ?
    `repeating-linear-gradient(
        to right,
        ${Palette.darkModerateBlue},
        ${Palette.darkModerateBlue} 14px,
        #fff 10px,
        #fff 18px
      );`
    :
    Palette.darkModerateBlue
};
  opacity: 0.6;
`;

const DataPoint = styled.div<DataPointProps>`
  position: absolute;
  top: ${props => props.positionTop}px;
  left: ${props => props.positionLeft}px;
  width: ${props => isNotNullNorUndefined(props.highlightedDataPoint) ? 13 : 9}px;
  height: ${props => isNotNullNorUndefined(props.highlightedDataPoint) ? 13 : 9}px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: ${
  props => isNotNullNorUndefined(props.highlightedDataPoint)
    ? getOr(props.highlightedDataPoint.color, Palette.darkModerateBlue)
    : Palette.darkModerateBlue
};
  border: solid 1px white;
`;

const XDataPoint = styled.div<{ positionLeft: number }>`
  position: absolute;
  left: ${props => props.positionLeft}px;
  top: 12px;
  min-width: 60px;
  width: 60px;
  height: 8px;
  color: ${Palette.bluishGrey};
  font-size: 11px;
  text-align: center;
  transform: rotate(-90deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
`;

const XAxis = styled.div`
  position: absolute;
  top: 105%;
  margin-top:3px;
`;

const DataPointLabel = styled.div<{highlightedDataPoint?: HighlightedDataPoint}>`
  position: relative;
  top: ${props => isNotNullNorUndefined(props.highlightedDataPoint) ? -32 : -24}px;
  transform: translateX(-100%);
  font-size: ${props => isNotNullNorUndefined(props.highlightedDataPoint) ? 20 : 14}px;
  font-weight: bold;
  color: ${
  props => isNotNullNorUndefined(props.highlightedDataPoint)
    ? getOr(props.highlightedDataPoint.color, Palette.darkModerateBlue)
    : Palette.darkModerateBlue
};
`;

const DataPoints = styled.div<{ horizontalMargin: number; showCurrent: boolean }>`
  position: relative;
  margin: 0 ${props => getOr(props.horizontalMargin, 0)}px;
  height: inherit;

  ${props => props.showCurrent ? `
    ${DataPoint}:last-of-type {
      width: 13px;
      height: 13px;

      ${DataPointLabel} {
        font-size: 20px;
        top: -32px;
        color: ${Palette.aquaBlue};
      }
    }
  ` : null}
`;
