import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";

export const SHORT_MONTH: Dictionary<string> = {
  "January": "Jan",
  "February": "Feb",
  "March": "Mar",
  "April": "Apr",
  "May": "May",
  "June": "Jun",
  "July": "Jul",
  "August": "Aug",
  "September": "Sep",
  "October": "Oct",
  "November": "Nov",
  "December": "Dec",
};

export const PRIMARY_SERIES : Highcharts.SeriesOptionsType = {
  id: "primary",
  type: "line",
  name: "mainSeries",
  zoneAxis: "x",
  zIndex: 2,
  connectNulls: true,
};
