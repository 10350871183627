import React from "react";
import { TransWrapper } from "@src/components/core/withTrans";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import {HTMLProps} from "react";

type ParagraphProps = Omit<HTMLProps<HTMLParagraphElement>, "size">;

interface HeadingProps extends ParagraphProps {
  children: React.ReactNode;
  size?: "small" | "medium" | "large" | "xlarge";
  weight?: "bold" | string;
}

const Heading = (props: HeadingProps) => {
  const { size = "large", children, weight = "normal", ...rest } = props;

  const getFontSize = () => {
    let fontSize;
    switch (size) {
      case "small":
        fontSize = 16;
        break;

      case "medium":
        fontSize = 18;
        break;

      case "xlarge":
        fontSize = 32;
        break;

      case "large":
      default:
        fontSize = 24;
        break;
    }

    return `${fontSize}px`;
  };

  // @ts-ignore
  return <StyledHeader weight={weight} size={getFontSize()} {...rest}>
     <TransWrapper {...props}/>
    </StyledHeader>;
};

interface StyledHeaderProps {
  weight?: "bold" | string;
  size?: string;

}

const StyledHeader = styled.div<StyledHeaderProps>`
    font-weight: ${props => props.weight};
    margin: unset;
    font-size: ${props => props.size};
    color: ${Palette.veryDarkBlueGrey};
  `;

export default Heading;
