import React, { ChangeEvent } from "react";
import styled from "styled-components";
import qs from "qs";
import { RouteComponentProps, withRouter } from "react-router";
import { debounce } from "lodash";
import { SearchBar } from "@components/core/SearchBar";
import Palette from "@src/config/theme/palette";
import { QueryParams } from "@src/screens/Insights/components/Reports/Filters";
import { replaceLocation } from "@src/utils/locations";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { getOr } from "hyphen-lib/dist/lang/Objects";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";

type Props = RouteComponentProps;

interface CommentsSearchBarState {
  readonly areFiltersVisible: boolean;
}

export class CommentsSearchBar extends React.Component<Props, CommentsSearchBarState> {
  state = {
    areFiltersVisible: false,
  };

  constructor(props: Props) {
    super(props);
    this.updateQueryParams = debounce(this.updateQueryParams, 500);
  }

  getQueryParams = (): QueryParams => {
    const { location } = this.props;
    return qs.parse(location.search, { ignoreQueryPrefix: true });
  };

  updateQueryParams = (value: any) => {
    const filter: Dictionary<any> = getOr(this.getQueryParams().filter, {});
    if (isStringAndNotEmpty(value)) {
      filter.freeText = value;
    } else {
      delete filter.freeText;
    }
    replaceLocation({ filter });
  };

  handleSearchBarChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.updateQueryParams(e.target.value);
  };

  handleFiltersClick = () => {
    this.setState(state => ({ areFiltersVisible: !state.areFiltersVisible }));
  };

  handleViewOptionsClick = () => {
    // tslint:disable-next-line:no-console
  };

  getFreeTextFilterValue = (): string | undefined => {
    const queryParams = this.getQueryParams();
    return queryParams.filter ? queryParams.filter.freeText : undefined;
  };

  render() {

    return (
      <Container>
        <SearchBar
          datacy="survey_comments_search_box"
          onChange={this.handleSearchBarChange}
          placeholder="Search comments or questions"
          defaultValue={this.getFreeTextFilterValue()}
        />
      </Container>
    );
  }
}

const Container = styled.div`
  padding: 24px 32px;
  background: ${Palette.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export default withRouter(CommentsSearchBar);
