import { Record, RecordOf } from "immutable";
import {
  ModifyPermissionsListAction,
  UserPermissionsActionTypes,
  ToggleModalVisibilityAction,
  AreYouSureModalActionAction,
  UpdateUserPermissionsAction,
  SelectUnselectAction
} from "@screens/Insights/UserManagement/containers/UserPermissions/store/actions";
import { SelectionFilter } from "hyphen-lib/dist/domain/parameter/SelectionFilter";

export interface UserPermissionsStateProps {
  readonly pageSize: number;
  readonly isAreYouSureModalVisible: boolean;
  readonly isModalVisible: boolean;
  readonly isBulkUpdateRequesting: boolean;
  readonly selectedRowKeys: string[];
  readonly tracker: SelectionFilter.Tracker;
  readonly selectedRootUser: string;
  readonly isBulkUpdateRequestSuccess: boolean;
}
export type UserPermissionsState = RecordOf<UserPermissionsStateProps>;

const defaultValues: UserPermissionsStateProps = {
  pageSize: 10,
  isAreYouSureModalVisible: false,
  isModalVisible: false,
  isBulkUpdateRequesting: false,
  selectedRowKeys: [],
  tracker: SelectionFilter.initializeNewTracker(),
  selectedRootUser: "",
  isBulkUpdateRequestSuccess: false,
};

export const userPermissionsStateFactory = Record<UserPermissionsStateProps>(defaultValues);

type Actions =
  | ModifyPermissionsListAction
  | ToggleModalVisibilityAction
  | SelectUnselectAction
  | AreYouSureModalActionAction
  | UpdateUserPermissionsAction;

export function userPermisssionsReducers(state: UserPermissionsState = userPermissionsStateFactory(),
  action: Actions | any): UserPermissionsState {
  switch (action.type) {
    case UserPermissionsActionTypes.TOGGLE_ARE_YOU_SURE_MODAL_VISIBILITY:
      return state.set("isAreYouSureModalVisible", action.isAreYouSureModalVisible);
    case UserPermissionsActionTypes.TOGGLE_MODAL_VISIBILITY:
      return state.set("isModalVisible", action.visible);
    case UserPermissionsActionTypes.UPDATE_USER_PERMISSIONS_REQUEST:
      return state.set("isBulkUpdateRequesting", true);
    case UserPermissionsActionTypes.UPDATE_USER_PERMISSIONS_ERROR:
    case UserPermissionsActionTypes.UPDATE_USER_PERMISSIONS_SUCCESS:
      return state.withMutations(map => {
        return map
          .set("isBulkUpdateRequesting", false)
          .set("isModalVisible", false)
          .set("isBulkUpdateRequestSuccess", true);
      });
    case UserPermissionsActionTypes.SELECT_UNSELECT_USERS:
      return state.merge(action.payload);
    case UserPermissionsActionTypes.SELECT_ROOT_USER:
      return state.set("selectedRootUser", action.email);
    default:
      return state;
  }
}
