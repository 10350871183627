import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Location } from "history";

import { appendQueryString, generateQueryString, parseQueryString } from "@hyphen-lib/util/net/HttpClient";
import { Dictionary } from "@hyphen-lib/domain/structure/Dictionary";
import { mapValues } from "@hyphen-lib/lang/Objects";
import { parseBoolean } from "@hyphen-lib/lang/Booleans";
import { ViewOptionParameter } from "@src/utils/networks";

import { replaceTo } from "@src/utils/locations";
import { connect } from "react-redux";
import { parametersActionCreators } from "@screens/Insights/parameters/store/actions";
import { ViewOptionDefinition, ViewOptionsContent } from "./components/ViewOptionsContent";

export interface ViewOptionsProps extends RouteComponentProps {
  viewOptions: ViewOptionDefinition[];
  defaultValues: Dictionary<any>;
  onChange: (viewOptions: ViewOptionParameter) => any;
  readonly onModifyParameters: (parameters: Dictionary<any>) => any;
}

export interface QueryParams {
  viewOptions?: any;
}

export const getViewOptionValuesFromLocation = (location: Location<any>) => {
  const { viewOptions = {} } = parseQueryString(location.search);

  return mapValues(
    viewOptions,
    (val: any, key: string) => {
      switch (key) {
        case "driverImpact":
        case "questionCategory":
        case "comments":
        case "comparison":
          return parseBoolean(val);
      }
      return val;
    }
  );
};

class ViewOptions extends React.Component<ViewOptionsProps> {
  handleChange = (viewOptions: ViewOptionParameter) => {
    const { location, onChange, onModifyParameters } = this.props;

    replaceTo(
      appendQueryString(
        location.pathname,
        generateQueryString({ ...parseQueryString(location.search), viewOptions })
      )
    );

    onModifyParameters({ viewOptions });
    onChange(viewOptions);
  };

  getViewOptionValues = () => {
    const { location } = this.props;
    return getViewOptionValuesFromLocation(location);
  };

  render() {
    const {
      viewOptions,
      defaultValues,
    } = this.props;

    const values = { ...defaultValues, ...this.getViewOptionValues() };

    return (
      <ViewOptionsContent
        viewOptions={viewOptions}
        onChange={this.handleChange}
        values={values}
      />
    );
  }
}

export default connect(
  undefined, {
    onModifyParameters: parametersActionCreators.modifyParameters,
  }
)(withRouter(ViewOptions));
