import React from "react";
import { List, Record, RecordOf } from "immutable";
import styled from "styled-components";
import { Form } from "antd";
import { connect } from "react-redux";
import { matchPath, RouteComponentProps, withRouter } from "react-router";
import { ActionResource } from "hyphen-lib/dist/domain/resource/action/ActionResource";
import {
  mapOr,
  isNotNullNorUndefined,
  isNullOrUndefined,
  getOr,
  isNotEmptyObject,
  isEmptyObject,
} from "hyphen-lib/dist/lang/Objects";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { SurveyInfoResource } from "hyphen-lib/dist/domain/resource/SurveyInfoResource";
import { Action } from "hyphen-lib/dist/domain/Action";
import { Optional } from "hyphen-lib/dist/lang/Optionals";

import { ScreenContainer } from "@screens/Insights/components/ScreenContainer";
import { actionCreators as SurveysActionCreator } from "@screens/Insights/Surveys/store/actions";
import Button from "@components/core/Button";
import ContainerCard from "@components/core/ContainerCard";
import { Paragraph } from "@components/core/Typography";
import Select from "@components/core/Select";
import { State } from "@store/types";
import {
  ActionModalProps,
  ActionModalType,
} from "@screens/Insights/Actions/store/reducers";
import { NetworkEventSuccessAction } from "@store/network/actions";
import AreYouSureModal from "@components/core/AreYouSureModal";
import ActionModal from "@screens/Insights/Actions/components/ActionModal";

import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { SelectProps } from "antd/lib/select";
import { PulsePollInfoResource } from "hyphen-lib/dist/domain/resource/PulsePollInfoResource";
import { AllErrors } from "@src/utils/formValidations";
import { NotificationSettings } from "../components/NotificationSettings";
import {
  ActionPlanSettings,
  ActionPlanSettingsErrors,
} from "../components/ActionPlanSettings";
import { ActionSourceDescription } from "../components/ActionSourceDescription";
import {
  getActionPlansState,
  getSurveyInfo,
  getPulsePollInfo,
} from "../store/selectors";
import {
  actionPlansActionCreators,
  UpdateActionSettingsAction,
} from "../store/actions";
import { getManageesEmails } from "../../Surveys/store/selectors";
import { getCurrentUser } from "../../store/selectors";
import {
  actionListActionCreators,
  ActionModalPayload,
} from "../../Actions/store/actions";
import { getErrorsForActionPlan } from "../utils/validations";
import { getActionListStateProps } from "../../Actions/store/selectors";
import { getActionStatuses } from "../utils/helpers";
import { NewActionResource } from "../store/reducers";
import ActionPlanOKR from "../components/ActionPlanOKRSection";
import OKRDetailCard from "../components/OKRDetailCard";
import { isOkrEnabled } from "hyphen-lib/dist/business/company/Companies";
import ActionPlanSuccessModal from "../components/ActionPlanSuccessModal";
import Spin from "@src/components/core/Spin";
import Palette from "@src/config/theme/palette";
import { getAppSettings } from "../../Settings/store/selectors";
import { AppSettingsResource } from "hyphen-lib/dist/domain/resource/AppSettingsResource";
import { getOkrSynonyms } from "hyphen-lib/dist/business/appSetting/AppSettings";
import { UserEmailInsightAccess } from "hyphen-lib/dist/domain/User";
import { Trans, WithTranslation, withTranslation } from "react-i18next";

const { Option } = Select;

interface EditActionPlanStateProps {
  readonly errors: RecordOf<ActionPlanSettingsErrors>;
  readonly actionName: string;
  readonly currentStatus: Action.Status;
  readonly okrSectionMode: string;
  readonly isActionPlanAlreadyLinked: boolean;
}

interface ReduxStateProps {
  readonly currentUser: CurrentUserResource;
  readonly mangeeEmails: List<UserEmailInsightAccess>;
  readonly actionId: string;
  readonly actionPlan: RecordOf<NewActionResource>;
  readonly surveyInfo: SurveyInfoResource;
  readonly pulsePollInfo: PulsePollInfoResource;
  readonly isActionUpdating: boolean;
  readonly visible: boolean;
  readonly action: ActionResource | null;
  readonly actionModalProps: ActionModalProps;
  readonly objectiveList: Optional<ActionResource.Objective[]>;
  readonly fetchingObjective: boolean;
  readonly enableSuccessOKRModal: boolean;
  readonly appSettings: AppSettingsResource;
}

interface ReduxActionProps {
  readonly fetchManageesEmails: (userId: string, status?: string) => void;
  readonly fetchPossibleActionAssignees: (actionPlanId: string) => void;
  readonly fetchActionPlanIfNeeded: (actionId: string) => void;
  readonly fetchSurveyIfNeeded: (surveyId: string) => void;
  readonly fetchPulsePollIfNeeded: (pulsePollId: string) => void;
  readonly updateActionPlanSettings: (
    payload: UpdateActionSettingsAction["payload"]
  ) => void;
  readonly onUpdateAction: (actionId: string) => void;
  readonly setDelete: (action: ActionResource) => any;
  readonly undoSet: () => any;
  readonly deleteResource: (redirectPath: string) => any;
  readonly onToggleActionModal: (payload: ActionModalPayload) => any;
  readonly onActionUpdate: (
    actionId: string,
    action: ActionResource,
    options?: {
      onSuccessRedirect?: (
        payload: NetworkEventSuccessAction["payload"]
      ) => void;
      meta?: Dictionary<any>;
    }
  ) => any;
  readonly fetchGoals: (searchQuery?: string) => void;
  readonly cleanActionPlan: () => void;
}
interface OwnProps {}

export interface EditActionPlanProps
  extends OwnProps,
    ReduxStateProps,
    WithTranslation,
    ReduxActionProps {}

function areCommentsOrRatingsRequired(consistencyErrors: AllErrors) {
  return consistencyErrors.has("comment") || consistencyErrors.has("rating");
}

enum OkrSection {
  CREATE = "OKR_CREATE_SECTION",
  VIEW = "OKR_VIEW_SECTION",
}
export class EditActionPlanContainer extends React.Component<
  EditActionPlanProps,
  EditActionPlanStateProps
> {
  constructor(props: EditActionPlanProps) {
    super(props);

    const dueDate = new Date();
    dueDate.setDate(dueDate.getDate() + 1);

    const defaultErrors: ActionPlanSettingsErrors = {
      action: "",
      assignee: "",
      category: "",
      dueDate: "",
      okr: {},
    };
    const errorStateFactory = Record<ActionPlanSettingsErrors>(defaultErrors);

    this.state = {
      errors: errorStateFactory(),
      actionName: "",
      currentStatus: Action.Status.TODO,
      okrSectionMode: "",
      isActionPlanAlreadyLinked: false,
    };
  }

  componentDidMount() {
    const {
      actionId,
      actionPlan,
      currentUser,
      fetchGoals,
      fetchActionPlanIfNeeded,
    } = this.props;
    const isOKREnabled = currentUser.company?.modules.betterworksOkr;
    const { okr } = actionPlan.toJS();
    fetchActionPlanIfNeeded(actionId);
    this.fetchSurveyInfoIfHasId();
    this.fetchPulsePollInfoIfHasPulsePollId();
    this.setOkrSection(okr);
    if (isOKREnabled) {
      fetchGoals();
    }
  }

  componentDidUpdate(prevProps: EditActionPlanProps) {
    const {
      actionPlan,
      currentUser,
      fetchPossibleActionAssignees,
      fetchManageesEmails,
      fetchGoals,
    } = this.props;
    const { okr } = actionPlan.toJS();
    const isOKREnabled = isOkrEnabled(currentUser.company!);
    if (not(prevProps.actionPlan.get("_id") === actionPlan.get("_id"))) {
      if (getOr(actionPlan.get("customizedAssigneeList"), false)) {
        fetchPossibleActionAssignees(actionPlan.get("_id"));
      } else {
        fetchManageesEmails(currentUser._id);
      }
      this.fetchSurveyInfoIfHasId();
      this.fetchPulsePollInfoIfHasPulsePollId();
      this.setState({
        actionName: actionPlan.get("action"),
        currentStatus: actionPlan.get("status"),
      });
    }
    if (
      isEmptyObject(prevProps.actionPlan.toJS()) &&
      isNotEmptyObject(actionPlan.toJS())
    ) {
      this.setOkrSection(okr);
      if (isOKREnabled) {
        fetchGoals();
      }
    }
  }

  componentWillUnmount() {
    this.props.cleanActionPlan();
  }

  fetchSurveyInfoIfHasId() {
    const { actionPlan, fetchSurveyIfNeeded } = this.props;
    const { source } = actionPlan.toJS();
    if (isNotNullNorUndefined(source)) {
      const { context } = source;
      if (isNotNullNorUndefined(context.surveyId)) {
        fetchSurveyIfNeeded(context.surveyId);
      }
    }
  }

  setOkrSection = (okr: ActionResource.OKR) => {
    if (isNotEmptyObject(okr) && ActionResource.isOkrCreateSuccessType(okr)) {
      this.setState({
        okrSectionMode: OkrSection.VIEW,
        isActionPlanAlreadyLinked: true,
      });
    } else {
      this.setState({ okrSectionMode: OkrSection.CREATE });
    }
  };

  fetchPulsePollInfoIfHasPulsePollId() {
    const { actionPlan, fetchPulsePollIfNeeded } = this.props;
    const { source } = actionPlan.toJS();
    if (isNotNullNorUndefined(source)) {
      const { context } = source;
      if (isNotNullNorUndefined(context.pulsePollId)) {
        fetchPulsePollIfNeeded(context.pulsePollId);
      }
    }
  }

  onCloseActionModal = () => {
    const { onToggleActionModal } = this.props;
    onToggleActionModal({
      isActionModalVisible: false,
      actionModalType: ActionModalType.VIEW,
      action: Optional.empty(),
    });
  };

  onSettingsChange = (fieldType: any, value: any) => {
    const { errors } = this.state;
    const { updateActionPlanSettings } = this.props;
    const statePayload = { errors: errors.set(fieldType, "") };
    const payload = { [fieldType]: value };
    updateActionPlanSettings(payload);
    this.setState(statePayload);
  };

  onSetToCompleteClick = () => {
    const { onToggleActionModal, actionPlan } = this.props;
    if (isNotNullNorUndefined(actionPlan)) {
      const actionType = actionPlan.get("status");
      onToggleActionModal({
        isActionModalVisible: true,
        action: actionPlan.toJS(),
        actionModalType:
          actionType === Action.Status.COMPLETED
            ? ActionModalType.SET_COMPLETE
            : ActionModalType.SET_DISMISSED,
      });
    }
  };

  onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const { errors } = this.state;
    const {
      actionId,
      onUpdateAction,
      updateActionPlanSettings,
      surveyInfo,
      pulsePollInfo,
    } = this.props;
    let { actionPlan } = this.props;

    // Save survey name if not there.
    if (
      actionPlan.hasIn(["source", "context", "surveyId"]) &&
      not(actionPlan.hasIn(["source", "context", "surveyName"]))
    ) {
      updateActionPlanSettings(
        actionPlan
          .setIn(["source", "context", "surveyName"], surveyInfo.name)
          .toJS()
      );
    } else if (
      actionPlan.hasIn(["source", "context", "pulsePollId"]) &&
      not(actionPlan.hasIn(["source", "context", "pulsePollName"]))
    ) {
      updateActionPlanSettings(
        actionPlan
          .setIn(["source", "context", "pulsePollName"], pulsePollInfo.question)
          .toJS()
      );
    }
    // Convert date to date object
    actionPlan = actionPlan.set("dueDate", new Date(actionPlan.get("dueDate")));
    const actionPlanPayload = actionPlan.toJS();
    const consistencyErrors = getErrorsForActionPlan(this.props.t, actionPlanPayload);

    if (areCommentsOrRatingsRequired(consistencyErrors)) {
      this.onSetToCompleteClick();
    } else if (consistencyErrors.size > 0) {
      const fieldErrorObj: ActionPlanSettingsErrors = errors.toObject();

      consistencyErrors.keySeq().forEach((key: any) => {
        if (errors.has(key)) {
          // @ts-ignore
          fieldErrorObj[key] = consistencyErrors.get(key);
        }
      });
      this.setState({ errors: errors.merge(fieldErrorObj) });
    } else {
      onUpdateAction(actionId);
    }
  };

  okDelete = () => this.props.deleteResource("/actioncenter/actions");

  deleteFallback = () => this.props.undoSet();

  renderAreYouSure = () => {
    const { visible, action } = this.props;
    return (
      visible &&
      isNotNullNorUndefined(action) && (
        <AreYouSureModal
          visible={visible}
          onOk={this.okDelete}
          onCancel={this.deleteFallback}
          title="Delete Action Plan"
          description="Are you sure you want to delete this?"
          okLabel="Yes"
        />
      )
    );
  };

  callSetDelete = () => {
    const { actionPlan, setDelete } = this.props;
    setDelete(actionPlan.toJS());
  };

  onCompleteOk = (
    actionId: string,
    action: ActionResource,
    callback: (payload: NetworkEventSuccessAction["payload"]) => void
  ) => {
    const { onActionUpdate, actionPlan } = this.props;
    const status = actionPlan.get("status");
    const statusAction =
      status === Action.Status.COMPLETED ? "completeAction" : "dismissAction";
    onActionUpdate(actionId, action, {
      onSuccessRedirect: callback,
      meta: {
        statusAction,
        redirectPath: "/actioncenter/actions",
      },
    });
  };

  isActionPlanEditable = () => {
    const { actionPlan } = this.props;
    const status = actionPlan.get("status");
    return (
      status !== Action.Status.DISMISSED && status !== Action.Status.COMPLETED
    );
  };

  updateNotificationSettings = (
    notifications: ActionResource.NotificationsConfiguration
  ) => {
    const { updateActionPlanSettings } = this.props;
    updateActionPlanSettings({
      notifications,
    });
  };

  showActionPlanToOKRSection = (isOKREnabled: boolean) => {
    const { errors } = this.state;
    const { actionPlan, appSettings } = this.props;

    const { assignee, dueDate, action, createdBy, description, resource, okr } =
      actionPlan.toJS();
    const values = {
      action,
      category: createdBy,
      dueDate,
      assignee,
      description,
      resource,
      okr,
    };
    const { okrSectionMode } = this.state;
    const okrError = errors.get("okr");
    const okrSynonyms = getOkrSynonyms(appSettings);
    return (
      <>
        {okrSectionMode === OkrSection.VIEW && (
          <StyledSection>
            <Paragraph>
              <strong>Betterworks OKR</strong>
            </Paragraph>
            <OKRCardSubHeading>
              <Trans>In order to edit or remove the objective click below to view it in</Trans>
              Betterworks OKR.
            </OKRCardSubHeading>
            <Paragraph data-cy="viewAction_okr">
              <OKRDetailCard data-jest="viewActionOkrCard" okr={okr} okrSynonyms={okrSynonyms} />
            </Paragraph>
          </StyledSection>
        )}

        {okrSectionMode === OkrSection.CREATE && (
          <>
            <OKRSection data-cy="createOkrSection">
              <ActionPlanOKR
                okrNamingConvention={okrSynonyms}
                fetchingObjective={this.props.fetchingObjective}
                currentUserEmail={this.props.currentUser.email}
                isOKREnabled={isOKREnabled}
                data-jest="actionPlanOkrSection"
                values={values}
                isDisabled={false}
                isEditScreen={true}
                onSettingsChange={this.onSettingsChange}
                objectiveList={this.props.objectiveList}
                fetchGoals={this.props.fetchGoals}
              />
            </OKRSection>
            {isNotEmptyObject(okrError) && (
              <OkrErrorContainer><Trans>{okrError}</Trans></OkrErrorContainer>
            )}
          </>
        )}
      </>
    );
  };

  render() {
    if (isEmptyObject(this.props.actionPlan.toJS())) {
      return <Spin />;
    }
    const {
      errors,
      actionName,
      currentStatus,
      isActionPlanAlreadyLinked,
    } = this.state;
    const {
      mangeeEmails,
      surveyInfo,
      pulsePollInfo,
      isActionUpdating,
      actionModalProps,
      currentUser,
      enableSuccessOKRModal,
      actionPlan,
      cleanActionPlan,
      appSettings
    } = this.props;
    const { source, okr, _id } = actionPlan.toJS();

    let title;
    if (
      isNotNullNorUndefined(source) &&
      (source.type === Action.SourceType.SURVEY_CATEGORY ||
        source.type === Action.SourceType.SURVEY_COMMENT ||
        source.type === Action.SourceType.SURVEY_QUESTION ||
        source.type === Action.SourceType.SURVEY_SEGMENT)
    ) {
      title = surveyInfo.name;
    } else if (
      isNotNullNorUndefined(source) &&
      (source.type === Action.SourceType.PULSE_POLL_SEGMENT ||
        source.type === Action.SourceType.PULSE_POLL_COMMENT)
    ) {
      title = pulsePollInfo.question;
    }

    let { notifications } = actionPlan.toJS();
    if (isNullOrUndefined(notifications)) {
      notifications = {};
    }

    const isActionPlanEditable = this.isActionPlanEditable();
    const isOKREnabled = isOkrEnabled(currentUser.company!);
    const betterworksBaseUrl = currentUser.company?.betterworksBaseUrl;
    const okrSynonyms = getOkrSynonyms(appSettings);

    if (enableSuccessOKRModal) {
      return (
        <ActionPlanSuccessModal
          actionId={_id}
          source={source}
          betterworksBaseUrl={betterworksBaseUrl}
          clearActionPlanResource={cleanActionPlan}
          isEditScreen={true}
          isActionPlanAlreadyLinked={isActionPlanAlreadyLinked}
          okrSynonymContainer={okrSynonyms}
          okr={okr as ActionResource.OKRCreateResponseType}
        />
      );
    } else {
      return (
        <ScreenContainer
          title={actionName}
          actions={[
            <Button
              key="adad"
              color="grey"
              data-cy="editScreenDeleteActionPlan"
              onClick={this.callSetDelete}
              translate="yes"
            >
              Delete Action Plan
            </Button>,
          ]}
        >
          {this.renderAreYouSure()}
          <Form onSubmit={this.onSubmit}>
            <ContainerCard>
              <Container>
                <MainDetails>
                  <ActionPlanSettings
                    maxWidth="600px"
                    onSettingsChange={this.onSettingsChange}
                    isDisabled={!isActionPlanEditable}
                    values={{
                      action: actionPlan.get("action"),
                      category: actionPlan.get("createdBy"),
                      dueDate: actionPlan.get("dueDate"),
                      assignee: actionPlan.get("assignee"),
                      description: actionPlan.get("description"),
                      resource: actionPlan.get("resource"),
                      okr: actionPlan.get("okr")!,
                    }}
                    availableAssignees={mangeeEmails}
                    errors={errors}
                    myself={currentUser.email}
                    currentUser={currentUser}
                  />
                  <ActionStatus
                    onChange={this.onSettingsChange.bind(null, "status")}
                    label={<Trans>Status</Trans>}
                    value={actionPlan.get("status")}
                    disabled={!isActionPlanEditable}
                  >
                    {getActionStatuses(currentStatus, true).map((status) => (
                      <Option key={status.value} value={status.value}>
                        <Trans>{status.label}</Trans>
                      </Option>
                    ))}
                  </ActionStatus>
                  <Paragraph>
                    <strong><Trans>Created by</Trans>:</strong> {actionPlan.get("createdBy")}
                  </Paragraph>
                </MainDetails>
                <SecondaryDetails>
                  <Details>
                    <Paragraph weight="light" translate="yes">Origin</Paragraph>
                    <ActionSourceDescription {...source} title={title} />
                  </Details>
                  {isOKREnabled &&
                    this.showActionPlanToOKRSection(isOKREnabled)}
                  <NotificationSettings
                    notifications={notifications}
                    updateNotificationSettings={this.updateNotificationSettings}
                    isDisabled={!isActionPlanEditable}
                  />
                </SecondaryDetails>
              </Container>
            </ContainerCard>
            <SubmitButtonContainer>
              <Button
                data-cy="updateActionPlan"
                disabled={isActionUpdating || !isActionPlanEditable}
                htmlType="submit"
                color="blue"
                translate="yes"
              >
                {`${
                  isActionUpdating
                    ? "Updating action plan..."
                    : "Update action plan"
                }`}
              </Button>
            </SubmitButtonContainer>
          </Form>
          {actionModalProps.action && (
            <ActionModal
              visible={actionModalProps.isActionModalVisible}
              type={actionModalProps.actionModalType}
              actionResource={actionModalProps.action}
              onCancel={this.onCloseActionModal}
              onUpdate={this.onCompleteOk}
              isActionUpdating={isActionUpdating}
            />
          )}
        </ScreenContainer>
      );
    }
  }
}

const SubmitButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const MainDetails = styled.div`
  width: 50%;
`;

const SecondaryDetails = styled.div`
  margin-left: 80px;
`;

const Details = styled.div`
  margin-top: 27px;
  display: flex: 1;
  > p {
    margin-right: 60px !important;
  }
`;

export const ActionStatus = styled(Select)<
  SelectProps & { children: React.ReactNode }
>`
  margin-bottom: 24px;
  max-width: 430px;
`;

const StyledSection = styled.div`
  margin-top: 27px;
  max-width: 500px;
  width: 70%;
`;

const OKRSection = styled.div`
  max-width: 100%;
  padding-bottom: 6px;
  height: fit-content;
  background: ${Palette.athensGrey};
`;

const OkrErrorContainer = styled.div`
  margin-top: 8px;
  color: ${Palette.alizarin};
`;

const OKRCardSubHeading = styled.div`
  color: ${Palette.veryDarkBlueGrey};
  font-size: 12px;
  margin-top: 10px;
  width: max-content;
  margin-bottom: 10px;
`;

function mapStateToProps(
  state: State,
  ownProps: OwnProps & RouteComponentProps
): ReduxStateProps {
  const realMatch = matchPath<{ actionId: string }>(
    ownProps.history.location.pathname,
    {
      path: "/actioncenter/actions/edit/:actionId",
      exact: false,
    }
  );
  const currentUser = mapOr(
    getCurrentUser(state),
    (user: CurrentUserResource) => user,
    {} as CurrentUserResource
  );

  const {
    isActionUpdating,
    actionModalProps,
    visible,
    action,
    enableSuccessOKRModal,
  } = getActionListStateProps(state);
  const { objectiveList, fetchingObjective, actionPlan } =
    getActionPlansState(state);
  const actionId = mapOr(realMatch, (map) => map.params.actionId, "");
  const appSettings = getOr(
    getAppSettings(state).get(0),
    {} as AppSettingsResource
  );

  return {
    currentUser,
    mangeeEmails: getManageesEmails(state),
    actionId,
    actionPlan,
    surveyInfo: getSurveyInfo(state),
    pulsePollInfo: getPulsePollInfo(state),
    isActionUpdating,
    visible,
    action,
    actionModalProps,
    objectiveList,
    fetchingObjective,
    enableSuccessOKRModal,
    appSettings,
  };
}

const mapDispatchToProps = {
  fetchManageesEmails: SurveysActionCreator.fetchManageesEmails,
  fetchPossibleActionAssignees:
    SurveysActionCreator.fetchPossibleActionAssignees,
  fetchActionPlanIfNeeded: actionPlansActionCreators.fetchActionPlanIfNeeded,
  updateActionPlanSettings: actionPlansActionCreators.updateActionPlanSettings,
  fetchSurveyIfNeeded: actionPlansActionCreators.fetchSurveyIfNeeded,
  fetchPulsePollIfNeeded: actionPlansActionCreators.fetchPulsePollIfNeeded,
  onUpdateAction: actionListActionCreators.updateAction,
  setDelete: actionListActionCreators.setDelete,
  undoSet: actionListActionCreators.undoSet,
  deleteResource: actionListActionCreators.deleteResource,
  onToggleActionModal: actionListActionCreators.onToggleActionModal,
  onActionUpdate: actionListActionCreators.updateActionRequest,
  fetchGoals: actionPlansActionCreators.fetchObjectiveList,
  cleanActionPlan: actionListActionCreators.cleanActionPlan,
};

export const EditActionPlan = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditActionPlanContainer))
);
