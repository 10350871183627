import React from "react";
import { RadioChangeEvent } from "antd/lib/radio";
import RadioGroup from "antd/lib/radio/group";
import styled from "styled-components";
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import queryString from "query-string";
import { connect } from "react-redux";
import { isNullOrUndefined, mapOr, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import Palette from "@src/config/theme/palette";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";
import pluralize from "pluralize";

import ContainerCard from "@components/core/ContainerCard";
import { Paragraph } from "@components/core/Typography";
import Radio from "@components/core/Radio";
import Button from "@components/core/Button";
import { State } from "@store/types";
import {
  UsersAndDimensionsStateProps as ReduxStatePropTypes
} from "@screens/Insights/AddUsersAndDimensions/store/reducers";
import { getCurrentUser, getAvailableRoles } from "@screens/Insights/store/selectors";

import { Rights } from "@hyphen-lib/business/auth/Rights";
import { getRightsMatcher } from "@screens/Insights/store/selectors";
import { ManualImport, ManualImportReduxStateProps, ManualImportActionProps } from "../../components/ManualImport";
import { AddUsersTable, AddUsersTableActionsProps, AddUsersTableReduxStateProps } from "../../components/AddUsersTable";
import {
  usersAndDimensionsActionCreators,
  AddUsersManuallyRequestAction,
  UserAndRole,
  UsersAndDimensionsActionTypes
} from "../../store/actions";
import { getUsersAndDimensionsState } from "../../store/selectors";
import AddUsersFromCSV from "../../components/AddUsersFromCSV";
import { Trans } from "react-i18next";
import { isNullOrEmpty } from "hyphen-lib/dist/lang/Strings";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import notificationsActionCreators, {
  ShowNotificationAction,
} from "@store/notifications/actions";
import * as notificationFactory from "@src/store/notifications/notification-factory";

type OwnProps = RouteComponentProps;

enum Modes {
  manual = "manual",
  csv = "csv",
}

interface ReduxStateProps extends AddUsersTableReduxStateProps, ManualImportReduxStateProps {
  emailsToBeAdded: ReduxStatePropTypes["emailsToBeAdded"];
  duplicateEmails: ReduxStatePropTypes["duplicateEmails"];
  usersAddedSuccessfully: ReduxStatePropTypes["usersAddedSuccessfully"];
  isNetworkRequesting: ReduxStatePropTypes["isNetworkRequesting"];
  companyName: string;
  uploadStatus: ReduxStatePropTypes["uploadStatus"];
  canAddUsersManually: boolean;
  canAddUsersByCSV: boolean;
}

interface ActionProps extends AddUsersTableActionsProps, ManualImportActionProps {
  onAddUsersManually: (payload: AddUsersManuallyRequestAction["payload"]) => void;
  cleanAddUserManuallyStore: () => void;
  uploadUsersCsv: (file: File) => void;
  displayNotification: (payload: ShowNotificationAction["payload"]) => ShowNotificationAction;
}

interface QueryParams {
  mode?: Modes;
}

type Props = {} & OwnProps & ActionProps & ReduxStateProps;

interface StateProps {
  radioValue: Modes;
}

class AddUsers extends React.Component<Props, StateProps> {
  csvInput: any;

  constructor(props: Props) {
    super(props);

    const { location, history } = props;
    const queryParams: QueryParams = queryString.parse(location.search);

    let mode = Modes.manual;
    if (isNullOrUndefined(queryParams.mode)) {
      queryParams.mode = mode;
      const queryParamsAsString = queryString.stringify(queryParams);
      history.replace({
        search: "?" + new URLSearchParams(queryParamsAsString),
      });
    } else {
      mode = queryParams.mode;
    }

    this.state = {
      radioValue: mode,
    };
  }

  componentWillUnmount() {
    this.props.cleanAddUserManuallyStore();
  }

  onRadioButtonChange = (e: RadioChangeEvent) => {
    const { value } = e.target;
    const { location, history } = this.props;
    const queryParams: QueryParams = queryString.parse(location.search);
    queryParams.mode = value;

    const queryParamsAsString = queryString.stringify(queryParams);
    history.replace({
      search: "?" + new URLSearchParams(queryParamsAsString),
    });
    this.setState({ radioValue: value });
  };

  onManualImportVerifyEmails = (emails: UserAndRole[]) => {
    this.props.onAddUsersManually({ emails, dryRun: true });
  };

  onAddUsers = () => {
    const { emailsToBeAdded, onAddUsersManually } = this.props;

    // Get a list of emails only
    const emails: string[] = [];

    emailsToBeAdded.forEach(user => {
      emails.push(user.get("email"));
    });

    onAddUsersManually({ emails: emailsToBeAdded.toArray(), dryRun: false });
  };

  emailsNotHaveEmpolyeeId = (text: any) => {
    const csvHeader = text.slice(0, text.indexOf("\n")).split(",");

    const indexOfEmpolyeeId = csvHeader.indexOf("employee_id");
    if(indexOfEmpolyeeId < 0) {
      return [];
    }

    const indexOfEmail = csvHeader.indexOf("email");

    const csvRows = text.slice(text.indexOf("\n") + 1).split("\n");

    const notEmpolyeeId: string[] = [];
    csvRows.map((i: string) => {
      if(!isNullOrEmpty(i)){
        const values = i.split(",");
        if(isNullOrEmpty(values[indexOfEmpolyeeId])){
          notEmpolyeeId.push(values[indexOfEmail]);
        }
      }
      return null;
    });
    return notEmpolyeeId;
  };

  errorNotification = () => {
    const {displayNotification} = this.props;
    const description = <p data-cy="fileNotUploaded">
                              <Trans>Some of the employees seem to be missing
                              their employee ids.</Trans><br /><Trans>You can either
                              add their employee ids or try uploading
                              without the employee id column</Trans>
                        </p>; 
    const notification = notificationFactory.error(
                            "Error during employee file upload",
                            description,
                            4.5
                      );
    displayNotification(notification);
  };

  uploadCsv =   (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNotNullNorUndefined(e) &&
      isNotNullNorUndefined(e.target) &&
      isNotNullNorUndefined(e.target.files) &&
      e.target.files.length > 0) {
        const file = e.target.files[0];
        if (file) {
          const fileReader = new FileReader();
          fileReader.onload = (event) => {
              const text = event.target?.result;
              const array = this.emailsNotHaveEmpolyeeId(text);  
              if(isNotEmptyArray(array)){
                this.errorNotification();
              }
              else {
                this.props.uploadUsersCsv(file);
              }
          };

          fileReader.readAsText(file);
        }    
    }
  };

  triggerUploadCsv = () => {
    if (isNotNullNorUndefined(this.csvInput)) {
      this.csvInput.click();
    }
  };

  render() {
    const { radioValue } = this.state;
    const {
      emailsToBeAdded,
      duplicateEmails,
      onUpdateUserRole,
      onRemoveUser,
      usersAddedSuccessfully,
      companyName,
      availableRoles,
      isNetworkRequesting,
      uploadStatus,
      emails,
      manualAddError,
      onSelectDeselectUsers,
      canAddUsersByCSV,
      canAddUsersManually,
    } = this.props;
    const totalDuplicateEmails = duplicateEmails.length;

    if (usersAddedSuccessfully) {
      return (
        <Redirect to="/users" />
      );
    }
    if (!canAddUsersByCSV && !canAddUsersManually) {
      return (
        <Redirect to="/errors/403" />
      );
    }

    if (uploadStatus === UsersAndDimensionsActionTypes.UPLOAD_USERS_CSV_REQUEST) {
      return <Redirect to="/users/add/progress" />;
    }

    return (
      <>
        <Container title="Add users manually or import from CSV">
          <Info>
            <Description>
              <Trans>You can either invite users manually by typing in their email address or
                importing a CSV following a format from our template. You will be able to edit
                user data after adding them.</Trans>
            </Description>

            <Options onChange={this.onRadioButtonChange} value={radioValue}>
              {
                canAddUsersManually && (
                  <Radio value={Modes.manual}>
                    <Trans>Add manually</Trans>
                  </Radio>
                )
              }
              {
                canAddUsersByCSV && (
                  <Radio value={Modes.csv}>
                    <Trans>Import from CSV</Trans>
                  </Radio>
                )
              }
            </Options>
          </Info>
          {(radioValue === Modes.manual && canAddUsersManually) && (
            <>
              <ManualImport
                onManualImportSubmit={this.onManualImportVerifyEmails}
                {...{
                  emails,
                  manualAddError,
                  onSelectDeselectUsers,
                }}
              />
              {emailsToBeAdded.size > 0 && (
                <AddUsersTable
                  dataSource={emailsToBeAdded.toJS()}
                  pagination={false}
                  onUpdateUserRole={onUpdateUserRole}
                  onRemoveUser={onRemoveUser}
                  availableRoles={availableRoles}
                  loading={isNetworkRequesting}
                />
              )}
              {totalDuplicateEmails > 0 && (
                <DuplicateEmailsMessage>
                  {totalDuplicateEmails} <Trans>of the users has already been invited to</Trans> {companyName}.
                </DuplicateEmailsMessage>
              )}
            </>
          )}
          {
            (radioValue === Modes.csv && canAddUsersByCSV) && (
              <AddUsersFromCSV />
            )
          }
        </Container>
        {emailsToBeAdded.size > 0 && (
          <ButtonContainer>
            <Button color="blue" onClick={this.onAddUsers}>
              <Trans>{isNetworkRequesting ? "Adding" : "Add"}</Trans>&nbsp;
              {emailsToBeAdded.size}&nbsp;
              <Trans>{pluralize("user", emailsToBeAdded.size)}</Trans>&nbsp;
              {`${isNetworkRequesting ? "..." : ""}`}
            </Button>
          </ButtonContainer>
        )}
        {(radioValue === Modes.csv && canAddUsersByCSV) && (
          <ButtonContainer>
            <UploadCsvButton onClick={this.triggerUploadCsv}>
              <Trans>Upload a CSV</Trans>
            </UploadCsvButton>
            <input
              ref={e => this.csvInput = e}
              style={{ display: "none" }}
              type="file"
              accept=".csv"
              data-cy="uploadCSV"
              onChange={this.uploadCsv} />
          </ButtonContainer>
        )}
      </>
    );
  }
}

const Container = styled(ContainerCard)`
  // padding: 32px 0 !important;
  > div:first-child {
    p {
      padding: 0 32px;
    }
  }
`;

const ButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
`;

const Info = styled.div`
  // padding: 0 32px;
`;

const UploadCsvButton = styled.button`
  width: 144px;
  height: 40px;
  font-weight: bold;
  color: white;
  border-radius: 2px;
  border: solid 1px #00b8cc;
  background-color: ${Palette.aquaBlue};
  cursor: pointer;
`;

const Description = styled(Paragraph)`
  margin-top: 9px !important;
  max-width: 758px;
`;

const Options = styled(RadioGroup)`
  margin: 28px 0 !important;
`;

const DuplicateEmailsMessage = styled.div`
  color: ${Palette.darkPink};
  padding: 32px;
`;

function mapStateToProps(state: State): ReduxStateProps {
  const {
    emailsToBeAdded,
    duplicateEmails,
    usersAddedSuccessfully,
    isNetworkRequesting,
    uploadStatus,
    emails,
    manualAddError,
  } = getUsersAndDimensionsState(state);

  const companyName = mapOr(
    getCurrentUser(state),
    (user: CurrentUserResource) =>
      mapOr(
        user.company,
        company => company.name,
        ""
      ),
    ""
  );
  const availableRoles = getAvailableRoles(state);
  return {
    emailsToBeAdded,
    duplicateEmails,
    usersAddedSuccessfully,
    companyName,
    availableRoles,
    isNetworkRequesting,
    uploadStatus,
    emails,
    manualAddError,
    canAddUsersManually: getRightsMatcher(state).hasRight(Rights.User.ADD_USERS_MANUALLY),
    canAddUsersByCSV: getRightsMatcher(state).hasRight(Rights.User.ADD_USERS_CSV),
  };
}

const mapDispatchToProps = {
  onAddUsersManually: usersAndDimensionsActionCreators.onAddUsersManually,
  onUpdateUserRole: usersAndDimensionsActionCreators.onUpdateUserRole,
  onRemoveUser: usersAndDimensionsActionCreators.onRemoveUser,
  cleanAddUserManuallyStore: usersAndDimensionsActionCreators.cleanAddUserManuallyStore,
  uploadUsersCsv: usersAndDimensionsActionCreators.uploadUsersCsv,
  onSelectDeselectUsers: usersAndDimensionsActionCreators.onSelectDeselectUsers,
  displayNotification: notificationsActionCreators.displayNotification,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddUsers));
