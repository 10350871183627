import React from "react";
import {
  NotificationChannel,
  NotificationChannels,
} from "hyphen-lib/dist/domain/NotificationChannels";
import { useTranslation } from "react-i18next";

import {
  entries,
  freeze,
  getOr,
  isNotNullNorUndefined,
  getProperty,
} from "hyphen-lib/dist/lang/Objects";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { Seq, Map as ImmutableMap } from "immutable";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import styled from "styled-components";
import { InputWithMergeTags } from "@components/core/InputWithMergeTags";
import { TextWithMergeTags } from "@components/core/TextWithMergeTags";
import Checkbox from "@components/core/Checkbox";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { MergeTagResource } from "hyphen-lib/dist/domain/resource/MergeTagResource";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import { toHumanReadableErrorMessage } from "@src/utils/formValidations";
import { capitalizeFirstLetter } from "@src/utils/helper";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import { newLineRegex } from "@src/utils/constants/SurveyCreation";
import { MultiLanguageFooterStrip } from "../../Surveys/components/MultiLanguageFooterStrip";
import {
  getLanguageInformationFromStringifiedTranslation,
  getParsed,
  getTranslatedSegmentFromStringifiedTranslation,
  LabelWithFlag,
} from "@src/utils/translation";
import {
  getLanguageStringFromCode,
  LocaleCodes,
} from "hyphen-lib/dist/util/locale";
import Button from "@src/components/core/Button";
import { Trans } from "react-i18next";
import { translate } from "@src/utils/i18next";

type ChannelsDictionary<T> = {
  [K in keyof NotificationChannels]: T;
};

type FieldType = "input" | "text";

const CHANNELS_CONFIGURATION: ChannelsDictionary<any> = freeze({
  email: {
    label: "Email",
    help: "If checked, an email will be sent to your employees",
    fields: {
      subject: "input",
      body: "text",
    },
    encodeLineBreak: true,
  },
  push: {
    label: "Push",
    help: "If checked, a push notification will be sent to your employees",
    fields: {
      body: "text",
    },
  },
  sms: {
    label: "SMS",
    help: "If checked, an SMS will be sent to your employees",
    fields: {
      body: "text",
    },
  },
  slack: {
    label: "Slack Message",
    help: "If checked, a slack message will be sent to your employees",
    fields: {
      body: "text",
    },
  },
  beekeeper: {
    label: "Beekeeper Message",
    help: "If checked, a beekeeper message will be sent to your employees",
    fields: {
      body: "text",
    },
  },
  workplace: {
    label: "Workplace Message",
    help: "If checked, a workplace message will be sent to your employees",
    fields: {
      body: "text",
    },
  },
  symphony: {
    label: "Symphony Message",
    help: "If checked, a symphony message will be sent to your employees",
    fields: {
      body: "text",
    },
  },
  speakap: {
    label: "Speakap Message",
    help: "If checked, a speakap message will be sent to your employees",
    fields: {
      body: "text",
    },
  },
});

const NOT_USED_CHANNEL: NotificationChannel.NotUsed = freeze({
  use: false,
});

interface NotificationChannelsFormProps {
  readonly channels: NotificationChannels;
  readonly allowedChannels: CompanyResource.Channels;
  readonly allowedMergeTags: MergeTagResource[];
  readonly errors?: Optional<
    ImmutableMap<string, WrongEntityException.WrongField>
  >;
  readonly disabled?: boolean;

  readonly onChange: (channels: NotificationChannels) => void;
  readonly configuredSurveyLanguages: string[];
  readonly enableTranslation: boolean;
  readonly isMultiLanguageSurveyEnabled: boolean;
}

interface NotificationChannelsFormState {
  /*
      Store the field values when one is deactivating a channel, in order to be able to restore them,
      if the channel is activated again.
   */
  readonly channelsCache: ChannelsDictionary<NotificationChannel<any>>;
}

export class NotificationChannelsForm extends React.Component<
  NotificationChannelsFormProps,
  NotificationChannelsFormState
> {
  state: NotificationChannelsFormState = {
    channelsCache: {},
  };

  createChannelChangeHandler =
    (channelKey: keyof NotificationChannels) =>
      (channel: NotificationChannel<any>) => {
        const { onChange, channels } = this.props;
        const { channelsCache } = this.state;

        if (not(channel.use)) {
          // if the channel has been deactivated, store the current channel's fields
          this.setState((state) => ({
            ...state,
            channelsCache: {
              ...state.channelsCache,
              [channelKey]: channels[channelKey]!,
            },
          }));
        } else if (
          channel.use !== getProperty(channels[channelKey], "use", false)
        ) {
          // if the channel has been activated, try to restore from the cache
          channel = getOr(channelsCache[channelKey], channel);
        }

        onChange({
          ...channels,
          [channelKey]: channel,
        });
      };

  render() {
    const { channels, allowedChannels, errors, allowedMergeTags, disabled } =
      this.props;

    return (
      <>
        {
          // doing a cast, as `entries` is by default returning a keyed seq with string as keys,
          // but here, we have a more precise subset for keys: keyof NotificationChannels
          (
            entries(CHANNELS_CONFIGURATION) as Seq.Keyed<
              keyof NotificationChannels,
              any
            >
          )
            .filter((channelConfig, channelKey) =>
              isNotNullNorUndefined((allowedChannels as any)[channelKey])
            )
            .map((channelConfig, channelKey) => (
              <>
                {(["sms", "email"].includes(channelKey) &&
                  not((allowedChannels as any)[channelKey].isAllowed))? null : 
                 (<NotificationChannelForm
                  key={channelKey}
                  label={channelConfig.label}
                  help={channelConfig.help}
                  fields={channelConfig.fields}
                  value={getOr(channels[channelKey], NOT_USED_CHANNEL)}
                  errors={Optional.map(errors, (errorsMap) =>
                    WrongEntityException.unPrefixErrorsMap(
                      errorsMap,
                      channelKey
                    )
                  )}
                  encodeLineBreak={Boolean(channelConfig.encodeLineBreak)}
                  allowedMergeTags={allowedMergeTags}
                  disabled={disabled}
                  onChange={this.createChannelChangeHandler(channelKey)}
                  configuredSurveyLanguages={
                    this.props.configuredSurveyLanguages
                  }
                  enableTranslation={this.props.enableTranslation}
                  isMultiLanguageSurveyEnabled={this.props.isMultiLanguageSurveyEnabled}
                />)}
              </>
            ))
            .valueSeq()
            .toArray()
        }
      </>
    );
  }
}

interface NotificationChannelFormProps {
  readonly label: string;
  readonly help?: string;
  readonly fields: Dictionary<FieldType>;
  readonly value: NotificationChannel<any>;
  readonly allowedMergeTags: MergeTagResource[];
  readonly encodeLineBreak: boolean;
  readonly errors?: Optional<
    ImmutableMap<string, WrongEntityException.WrongField>
  >;
  readonly disabled?: boolean;
  readonly onChange: (channel: NotificationChannel<any>) => void;
  readonly configuredSurveyLanguages: string[];
  readonly enableTranslation: boolean;
  readonly isMultiLanguageSurveyEnabled: boolean;
}

interface NotificationChannelFormState {
  translationEditing: {
    subject: boolean;
    body: boolean;
  };
  subjectTranslationMap: Record<string, string>;
  bodyTranslationMap: Record<string, string>;
}

// tslint:disable-next-line:max-classes-per-file
export class NotificationChannelForm extends React.Component<
  NotificationChannelFormProps,
  NotificationChannelFormState
> {
  constructor(props: NotificationChannelFormProps) {
    super(props);

    let bTransMap = {};
    let sTransMap = {};
    if (props.value.use) {
      if (isNotNullNorUndefined(props.value.body_t)) {
        bTransMap = getParsed(props.value.body_t);
      }

      if (isNotNullNorUndefined(props.value.subject_t)) {
        sTransMap = getParsed(props.value.subject_t);
      }
    }
    this.state = {
      translationEditing: {
        body: false,
        subject: false,
      },
      bodyTranslationMap: bTransMap,
      subjectTranslationMap: sTransMap,
    };
  }

  startTranslationEditing(
    fieldToEdit: keyof NotificationChannelFormState["translationEditing"]
  ) {
    const fieldsToTurnOff = Object.keys(this.state.translationEditing).filter(
      (key) => key !== fieldToEdit
    );
    const newEditingMap: Record<string, boolean> = {};
    fieldsToTurnOff.forEach((field) => {
      newEditingMap[field] = false;
    });
    this.setState({
      // @ts-ignore
      translationEditing: {
        ...newEditingMap,
        [fieldToEdit]: true,
      },
    });
  }

  isEditingTranslation(
    field: keyof NotificationChannelFormState["translationEditing"]
  ) {
    return getOr(this.state.translationEditing[field], false);
  }

  handleChannelUseChange = () => {
    const { value, onChange, disabled } = this.props;

    if (not(disabled)) {
      onChange({
        use: !value.use,
      });
    }
  };

  createChannelFieldChangeHandler = (field: string) => (fieldValue: string) => {
    const { value, onChange, disabled } = this.props;

    if (not(disabled)) {
      onChange({
        ...value,
        [field]: this.sanitizeValue(fieldValue, field),
      });
    }
  };

  sanitizeValue = (value: string, field: string) => {
    const { encodeLineBreak } = this.props;
    if (encodeLineBreak && field === "body") {
      return value.replace(newLineRegex, "<br>");
    }
    return value;
  };

  saveTranslations(
    field: keyof NotificationChannelFormState["translationEditing"],
    saveMergeTags?: boolean,
    translatedWithMergeTags?: Record<string, string>
  ) {
    const stateName = `${field}TranslationMap`;
    const { value, onChange } = this.props;
    if (saveMergeTags && isNotNullNorUndefined(translatedWithMergeTags)) {
      onChange({
        ...value,
        // @ts-ignore
        [`${field}_t`]: JSON.stringify(translatedWithMergeTags),
      });
    } else {
      onChange({
        ...value,
        // @ts-ignore
        [`${field}_t`]: JSON.stringify(this.state[stateName]),
      });
      this.setState({
        // @ts-ignore
        translationEditing: {
          [field]: false,
        },
      });
    }
  }

  render() {
    const { label, help, fields, value, allowedMergeTags, errors, disabled } =
      this.props;

    const isUsed = NotificationChannel.isUsed(value);
    const regex = /<br\s*[/]?>/gi;

    return (
      <>
        <CheckBoxDiv>
          <Checkbox
            checked={isUsed}
            info={help}
            disabled={disabled}
            onChange={this.handleChannelUseChange}
          >
            <Trans>{label}</Trans>
          </Checkbox>
          {isUsed &&
            entries(fields)
              .map((type: FieldType, fieldKey: string) => {
                const { totalLanguages, totalTranslated } =
                  getLanguageInformationFromStringifiedTranslation(
                    this.props.configuredSurveyLanguages,
                    value[`${fieldKey}_t`]
                  );
                const field =
                  fieldKey as keyof NotificationChannelFormState["translationEditing"];
                return (
                  <div
                    className={
                      this.isEditingTranslation(field) ? "channelT" : ""
                    }
                    key={fieldKey}
                    style={{ marginTop: "24px" }}
                  >
                    <NotificationChannelField
                      key={fieldKey + "-parent"}
                      label={label + " " + capitalizeFirstLetter(fieldKey)}
                      textOnlyLabel={
                        label + " " + capitalizeFirstLetter(fieldKey)
                      }
                      fieldName={
                        label + " " + capitalizeFirstLetter(fieldKey)
                      }
                      type={type}
                      value={
                        isStringAndNotEmpty(value[fieldKey])
                          ? value[fieldKey].replace(regex, "\n")
                          : ""
                      }
                      allowedMergeTags={allowedMergeTags}
                      error={Optional.map(errors, (errorsMap) =>
                        errorsMap.get(fieldKey)
                      )}
                      disabled={disabled}
                      onChange={this.createChannelFieldChangeHandler(fieldKey)}
                    />
                    {this.props.isMultiLanguageSurveyEnabled && this.props.enableTranslation &&
                      !this.isEditingTranslation(field) && (
                        <MultiLanguageFooterStrip
                          key={fieldKey}
                          languagesReady={totalTranslated}
                          totalLanguages={totalLanguages}
                          onEditTranslations={() => {
                            this.startTranslationEditing(field);
                          }}
                        />
                      )}
                    {this.isEditingTranslation(field) &&
                      this.props.configuredSurveyLanguages.map((code, index) => {
                        const translatedValue =
                          getTranslatedSegmentFromStringifiedTranslation(
                            code as LocaleCodes,
                            value[`${fieldKey}_t`]
                          );
                        return (
                          <>
                            <div className={index === 0 ? "mt-24p mb-24p" : "mb-24p"}>
                              <NotificationChannelField
                                key={fieldKey + "-" + code}
                                label={LabelWithFlag(code as LocaleCodes)}
                                textOnlyLabel={getLanguageStringFromCode(
                                  code as LocaleCodes
                                )}
                                fieldName={
                                  label + " " + capitalizeFirstLetter(fieldKey)
                                }
                                type={type}
                                value={translatedValue}
                                allowedMergeTags={allowedMergeTags}
                                error={Optional.map(errors, (errorsMap) =>
                                  errorsMap.get(fieldKey + "_t")
                                )}
                                disabled={disabled}
                                onChange={(value, cursorPosition, mergeTag) => {
                                  const stateFieldName = `${field}TranslationMap`;
                                  let updatedValue = value;
                                  if (
                                    isNotNullNorUndefined(cursorPosition) &&
                                    isNotNullNorUndefined(mergeTag)
                                  ) {
                                    updatedValue =
                                      TextWithMergeTags.insertString(
                                        // @ts-ignore
                                        getOr(this.state[stateFieldName][code], ""),
                                        mergeTag + " ",
                                        cursorPosition
                                      );
                                    this.saveTranslations(field, true, {
                                      // @ts-ignore
                                      ...this.state[stateFieldName],
                                      [code]: updatedValue,
                                    });
                                  }
                                  // @ts-ignore
                                  this.setState({
                                    [stateFieldName]: {
                                      // @ts-ignore
                                      ...this.state[stateFieldName],
                                      [code]: updatedValue,
                                    },
                                  });
                                }}
                              />
                            </div>
                          </>
                        );
                      })}

                    {this.isEditingTranslation(field) && (
                      <div className="d-flex justify-content-end">
                        <Button
                          color="blue"
                          style={{ height: "30px" }}
                          onClick={() => {
                            this.saveTranslations(field);
                          }}
                          data-cy="save_translations"
                          translate="yes"
                        >
                          Save translations
                        </Button>
                      </div>
                    )}
                  </div>
                );
              })
              .valueSeq()
              .toArray()}
        </CheckBoxDiv>
      </>
    );
  }
}

interface NotificationChannelFieldProps {
  readonly label: React.ReactNode;
  readonly textOnlyLabel: string;
  readonly type: FieldType;
  readonly value?: Optional<string>;
  readonly error?: Optional<WrongEntityException.WrongField>;
  readonly allowedMergeTags: MergeTagResource[];
  readonly disabled?: boolean;
  readonly onChange: (
    value: string,
    updatedCursorPosition?: number,
    mergeTag?: string
  ) => void;
  readonly fieldName: string;
}

function NotificationChannelField({
  label,
  textOnlyLabel,
  type,
  value,
  allowedMergeTags,
  error,
  disabled,
  fieldName,
  onChange,
}: NotificationChannelFieldProps) {
  function getFormComponent(fieldType: FieldType): {
    component: React.ElementType;
    additionalProps?: Dictionary<any>;
  } {
    switch (fieldType) {
      case "input":
        return {
          component: ChannelInput,
        };
      case "text":
        return {
          component: ChannelText,
          additionalProps: {
            rows: 4,
          },
        };
    }
  }
  const { t } = useTranslation();
  const { component: FormComponent, additionalProps } = getFormComponent(type);
  allowedMergeTags.forEach((tag: any) => {
    tag.description = translate(t, tag.description);
  });
  return (
    <FormComponent
      label={label}
      value={getOr(value, "")}
      onValueChange={onChange}
      error={toHumanReadableErrorMessage(t, error, fieldName)}
      data-cy="surveyCreation_settings_emailSubject"
      allowedMergeTags={allowedMergeTags}
      fieldName={fieldName}
      disabled={disabled}
      {...additionalProps}
    />
  );
}

/*
  Exported for unit test purpose
 */
export const ChannelInput = styled(InputWithMergeTags)``;

/*
  Exported for unit test purpose
 */
export const ChannelText = styled(TextWithMergeTags)``;

const CheckBoxDiv = styled.div`
  margin-top: 24px !important;
`;
