import React, { ChangeEvent } from "react";
import qs from "qs";
import { RouteComponentProps, withRouter } from "react-router";
import { debounce } from "lodash";
import { replaceLocation } from "@src/utils/locations";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { getOr } from "hyphen-lib/dist/lang/Objects";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import { SearchBar } from "@components/core/SearchBar";
import { QueryParams } from "@src/screens/Insights/components/Reports/Filters";

export type Props = RouteComponentProps;

export class OpenQuestionsSearchBar extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.updateQueryParams = debounce(this.updateQueryParams, 500);
  }

  getQueryParams = (): QueryParams => {
    const { location } = this.props;
    return qs.parse(location.search, { ignoreQueryPrefix: true });
  };

  updateQueryParams = (value: any) => {
    const filter: Dictionary<any> = getOr(this.getQueryParams().filter, {});
    if (isStringAndNotEmpty(value)) {
      filter.freeText = value;
    } else {
      delete filter.freeText;
    }
    replaceLocation({ filter });
  };

  handleSearchBarChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.updateQueryParams(e.target.value);
  };

  getFreeTextFilterValue = (): string | undefined => {
    const queryParams = this.getQueryParams();
    return queryParams.filter ? queryParams.filter.freeText : undefined;
  };

  render() {
    return (
      <SearchBar
        onChange={this.handleSearchBarChange}
        placeholder="Search responses"
        defaultValue={this.getFreeTextFilterValue()}
      />
    );
  }
}

export default withRouter(OpenQuestionsSearchBar);
