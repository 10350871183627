import { State } from "@store/types";
import { Survey } from "hyphen-lib/dist/domain/Survey";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import {
  getLanguageStringFromCode,
  LocaleCodes,
} from "hyphen-lib/dist/util/locale";

export interface FileUploadCollectiveState {
  isUploadingTemplate: boolean;
  isUploadSuccess: boolean;
  uploadResponse?: Survey.UploadSurveyTemplateResponse;
}

export function getFileUploadState(state: State): FileUploadCollectiveState {
  return {
    isUploadingTemplate: state.getIn(["insights_xlsx", "isUploadingTemplate"]),
    isUploadSuccess: state.getIn(["insights_xlsx", "isUploadSuccess"]),
    uploadResponse: state.getIn(["insights_xlsx", "uploadResponse"]),
  };
}

export function getLanguagesForSelectBox(languageCodes: LocaleCodes[]) {
  return languageCodes
  .filter((code) => /^en/i.test(code) === false)
  .map((c) => getLanguageStringFromCode(c));
}

export function displayUploadLanguageStatsText(
  configuredSurveyLanguages: string[],
  surveyTranslatedLanguages?: string[],
  uploadResponse?: Survey.UploadSurveyTemplateResponse
) {
  let languagesUploaded = "";
  const availableLanguagesInSurvey = getLanguagesForSelectBox(
    configuredSurveyLanguages as LocaleCodes[]
  ).join(",");

  if (isNotNullNorUndefined(uploadResponse)) {
    languagesUploaded = getLanguagesForSelectBox(
      uploadResponse.addedTranslations as LocaleCodes[]
    ).join(",");
  } else if (isNotNullNorUndefined(surveyTranslatedLanguages)) {
    languagesUploaded = getLanguagesForSelectBox(
      surveyTranslatedLanguages as LocaleCodes[]
    ).join(",");
  }

  // eslint-disable-next-line max-len
  return `Uploaded translation for "${languagesUploaded}" from the languages added in the survey "${availableLanguagesInSurvey}"`;
}
