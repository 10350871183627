import React from "react";
import { Trans } from "react-i18next";
// core antD components
import { Icon, Dropdown, Menu } from "antd";
import styled from "styled-components";

// app core components
import { ExportSvg } from "@components/core/svg/ExportSvg";
import { DropDownSvg } from "@components/core/svg/DropDownSvg";
import Button from "@components/core/Button";

interface ExportDropdownProps {
  exportOption?: string;
  handleExportsButtonClick: () => void;
  path: string;
  disabled?: boolean;
}

export default function ExportDropdown(props: ExportDropdownProps) {
  const { exportOption, handleExportsButtonClick, disabled = false } = props;

  const checkPdf = () => {
    const { path } = props;
    const regexPDFTypes = /heatmap|comments|individualResults/i;
    return !regexPDFTypes.test(path);
  };

  const menu = (
    <Menu>
      {exportOption && (
        <StyledMenuItem key="1" onClick={handleExportsButtonClick}>
          <Icon component={ExportSvg} />
          <Trans>Export as CSV</Trans>
        </StyledMenuItem>
      )}

      {/* tslint:disable-next-line: jsx-no-lambda */}
      {checkPdf() && (
        <StyledMenuItem key="2" onClick={window.print}>
          <Icon type="file-pdf" />
          <Trans>Export as PDF</Trans>
        </StyledMenuItem>
      )}
    </Menu>
  );

  return exportOption && checkPdf() ? (
    <Dropdown overlay={menu} disabled={disabled}>
      <ExportButton color="gradation" translate="yes">
        Export Report
        <ImageContainer orientation={"right"}>
          <DropDownSvg />
        </ImageContainer>
      </ExportButton>
    </Dropdown>
  ) : exportOption ? (
    <ExportButton
      disabled={disabled}
      color="gradation"
      onClick={handleExportsButtonClick}>
      <Icon component={ExportSvg} />
      <span><Trans>Export CSV</Trans></span>
    </ExportButton>
  ) : checkPdf() ? (
    // tslint:disable-next-line: jsx-no-lambda
    <ExportButton disabled={disabled} color="gradation" onClick={window.print}>
      <Icon component={ExportSvg} />
      <span><Trans>Export PDF</Trans></span>
    </ExportButton>
  ) : null;
}

const StyledMenuItem = styled(Menu.Item)`
  height: 36px !important;
`;

const ImageContainer = styled.span<{ orientation: "left" | "right" }>`
  position: absolute !important;
  top: 9px;
  ${props => props.orientation}: 9px;
  margin-left: 10px;
`;

export const ExportButton = styled(Button)`
  height: 36px !important;
  position: relative !important;
  padding-right: 36px !important;
`;
