import { Record, Map, fromJS } from "immutable";

import { NetworkEventSuccessAction } from "@src/store/network/actions";
import { SurveyResourceProps } from "@screens/Insights/Surveys/store/types";
import { SelectedSurveyInfoResource, SelectedSurveyResource } from "@src/screens/Insights/SurveyList/store/reducers";
import { StateProps, State } from "./types";
import { ActionTypes } from "./actions";


export const defaultValues: StateProps = {
  reminders: {
    isLoading: false,
    hasError: false,
    data: {
      _id: "",
      _type: "SurveyNotificationsInfo",
      nextScheduledReminderAt: undefined,
      launchedAt: new Date(),
      reminders: [],
    },
    reminderModalOpen: false,
    isRequestingSendReminders: false,
    selectedSurveyInfoResource: Map({}) as SelectedSurveyInfoResource,
    selectedSurveyResource: Map({}) as SelectedSurveyResource,
  },
};

export const stateFactory = Record<StateProps>(defaultValues);
const defaultState = stateFactory();

function receiveSurveySuccessReducer(
  state: State = defaultState,
  { payload }: NetworkEventSuccessAction
): State {
  return state.mergeIn(["reminders", "selectedSurveyResource"], fromJS(payload.data) as SurveyResourceProps);
}

export const reducers = (state: State = defaultState, action: any) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_REMINDER_MODAL_OPEN:
      return state.withMutations(map => {
        map
          .setIn(["reminders", "reminderModalOpen"], action.reminderModalOpen)
          .setIn(["reminders", "selectedSurveyInfoResource"],
            Map(action.selectedSurveyInfoResource) as SelectedSurveyInfoResource);
      });

    case ActionTypes.FETCH_SURVEY_SUCCESS:
      return receiveSurveySuccessReducer(state, action);

    case ActionTypes.SEND_SURVEY_REMINDERS_REQUEST:
      return state.setIn(["reminders", "isRequestingSendReminders"], true);
    case ActionTypes.SEND_SURVEY_REMINDERS_SUCCESS:
      return state.withMutations(map => {
        map
          .setIn(["reminders", "isRequestingSendReminders"], false)
          .setIn(["reminders", "reminderModalOpen"], false)
          .setIn(["reminders", "selectedSurveyInfoResource"], Map({}) as SelectedSurveyInfoResource);
      });
    case ActionTypes.FETCH_SURVEY_REMINDERS_REQUEST:
      return state
        .setIn(["reminders", "isLoading"], true)
        .setIn(["reminders", "hasError"], false);
    case ActionTypes.FETCH_SURVEY_REMINDERS_SUCCESS:
      return state
        .setIn(["reminders", "isLoading"], false)
        .mergeIn(["reminders"], action.payload);
    case ActionTypes.FETCH_SURVEY_REMINDERS_ERROR:
      return state
        .setIn(["reminders", "isLoading"], false)
        .setIn(["reminders", "hasError"], true);
    default:
      return state;
  }
};

export default reducers;
