import { Component } from "react";

import { CardSection } from "@components/core/InfoCard/CardSection";

import { QuestionType } from "hyphen-lib/dist/domain/common/QuestionType";
import { isNullOrUndefined } from "hyphen-lib/dist/lang/Objects";
import { Optional } from "hyphen-lib/dist/lang/Optionals";

interface Props {
  question: Optional<string>;
  questionType: Optional<QuestionType>;
}

export class PulsePollQuestionsSection extends Component<Props> {

  render() {
    const { question, questionType } = this.props;
    if (isNullOrUndefined(question) || isNullOrUndefined(questionType)) {
      return null;
    }

    const pulsePollQuestionsData = [
      {
        label: "Question",
        value: question,
      },
      {
        label: "Question Type",
        value: questionType,
      },
    ];

    return (
      <>
        <CardSection data={pulsePollQuestionsData} />
      </>
    );
  }
}
