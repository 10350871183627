import React from "react";
import { RouteComponentProps, withRouter } from "react-router";

import { QuestionType } from "@hyphen-lib/domain/common/QuestionType";
import { Sentiment } from "@hyphen-lib/domain/common/ComputationTypes";
import { DateFilter as DateFilterType } from "@hyphen-lib/domain/parameter/DateFilter";
import { parseBoolean } from "@hyphen-lib/lang/Booleans";
import { parseNumber } from "@hyphen-lib/lang/Number";
import { getOr, objectOmit } from "hyphen-lib/dist/lang/Objects";

import { FilterDefinition, FiltersContent, CustomFilterDefinition } from "@screens/Insights/components/FiltersContent";
import { FilterParameter } from "@src/utils/networks";
import { applyDefault } from "@src/utils/Currier";
import {
  CategoryFilter,
  CategoryFilterMonoSelect,
  QuestionTypeFilter,
  SentimentFilter,
  SurveyStatusFilter,
  SurveyTypeFilter,
  TopicFilter
} from "@screens/Insights/components/Filters/business/SurveyFilters";
import { DateFilter } from "@screens/Insights/components/Filters/business/DateFilter";
import { Seq, Map as ImmutableMap } from "immutable";
import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
import { appendQueryString, generateQueryString, parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { mapValues, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { FlagFilter } from "@src/screens/Insights/components/Filters/business/FlagFilter";
import { generateDefinitionsForDimensions } from "@screens/Insights/components/Filters/business/DimensionFilters";
import { replaceTo } from "@src/utils/locations";
import { SurveyTypes } from "@src/screens/Insights/Surveys/store/types";
import { Filter } from "@src/components/core/FilterLabels/types";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { Company } from "hyphen-lib/dist/domain/Company";
import { mapCompanyModulesToFiltersArray } from "@src/utils/helper";
import { isOnlySurveyModuleEnabled, hasOnlyAppliedFilter, isOnlyEmployeeVoiceModuleEnabled } from "@src/utils/modules";
import { ModuleFilter } from "../../Filters/business/ActionPlanFilters";
import { AddDimensionFilter } from "../../Filters/business/AddDimensionFilter";
import { sanitizeFilters } from "../../Filters/utils";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { SurveyDimensionsResource } from "hyphen-lib/dist/domain/resource/survey/report/SurveyDimensionsResource";
import { sanitizeDimensions } from "@src/utils/Dimensions";

const refreshCategoriesBasedOn = ["modules", "date", "surveyTypes"];
export interface FiltersData {
  readonly categories: string[];
  readonly topics: string[];
  readonly dimensions: Dimensions;
  readonly companyModules: Company.Modules;
  readonly surveyTypes: SurveyTypes;
}

export interface FiltersProps extends RouteComponentProps {
  enabledFilters: string[];
  appliedFilters: Filter[];
  enabledCustomFilters?: string[];
  loading: boolean;
  filtersData: FiltersData;
  from?: string;
  singleFieldFilter?: string;
  onApply: (filter: FilterParameter) => any;
  fetchCategories?: (queryParams: Dictionary<any>) => void;
  fetchSurveyDimensionsInfo: () => any;
  surveyDimensionsInfoElement: Store.Element<SurveyDimensionsResource>;
}

export interface FiltersState {
  isSurveyTypeFilterDisabled: boolean;
  isCategoryFilterDisabled: boolean;
  clearCategory: boolean;
}

export interface QueryParams {
  date?: DateFilterType;
  questionTypes?: QuestionType[];
  sentiments?: Sentiment[];
  categories?: string[];
  topics?: string[];
  category?: string;
  withCommentOnly?: boolean;
  dimensions?: string[];
  filter?: any;
}

export class Filters extends React.Component<FiltersProps, FiltersState> {
  customFiltersMap: ImmutableMap<string, CustomFilterDefinition> = ImmutableMap(
    {
      addDimension: {
        key: "addDimension",
        label: "Add a Dimension",
        component: applyDefault(AddDimensionFilter, {
          dimensions: this.props.filtersData.dimensions,
        }),
      },
    }
  );

  isDashboard = false;

  constructor(props: FiltersProps) {
    super(props);
    this.state = {
      isSurveyTypeFilterDisabled: true,
      isCategoryFilterDisabled: false,
      clearCategory: false
    };
    this.isDashboard = (this.props.from && this.props.from === "dashboard_view") ? true : false;
  }
  
  componentDidMount() {
    this.checkAndEnableDependentFilter();
  }

  handleApply = (filter: FilterParameter) => {
    const { location, onApply } = this.props;
    replaceTo(
      appendQueryString(
        location.pathname,
        generateQueryString({ ...parseQueryString(location.search), filter: sanitizeFilters(filter) })
      )
    );

    onApply(filter);
  };

  /*
      fixme: BEFORE LAUNCH!!!
      fixme: BEFORE LAUNCH!!!
      fixme: BEFORE LAUNCH!!!
      fixme: BEFORE LAUNCH!!!
      fixme: BEFORE LAUNCH!!!
      fixme: BEFORE LAUNCH!!!

      should not hardcode values, but use deserializer from filter definitions...
   */
  getFilterValues = () => {
    const { location } = this.props;
    const { filter = {} } = parseQueryString(location.search);

    return mapValues(
      filter,
      (val: any, key: string) => {
        switch (key) {
          case "withCommentOnly":
            return parseBoolean(val);
          case "sentiments":
            return val.map(parseNumber);
        }
        return val;
      }
    );
  };

  getFilterDefinitions(): FilterDefinition[] {
    const { enabledFilters, filtersData, singleFieldFilter, loading, surveyDimensionsInfoElement } = this.props;
    const filterList = isNotEmptyArray(enabledFilters) ? enabledFilters : [singleFieldFilter || ""];
    const dynamicDimensions =  Store.Element.isLoaded(surveyDimensionsInfoElement) ? 
      getOr(sanitizeDimensions(surveyDimensionsInfoElement.value.dimensions) , {}) : filtersData.dimensions;
    const filetrArray =  Seq( filterList)
      .flatMap(enabledFilter => this.mapToFilterDefinition(enabledFilter, filtersData, loading, dynamicDimensions))
      .toArray();
    return filetrArray;
  }

  handleModuleFilterChange = (filters: Dictionary<any>, key: any) => {
    // check whether survey-type filter should be enabled or disabled
    let { isSurveyTypeFilterDisabled, isCategoryFilterDisabled } = this.state;
    const { clearCategory } = this.state;
    if (isOnlySurveyModuleEnabled(this.props.filtersData.companyModules)) {
      isSurveyTypeFilterDisabled = false;
    } else if (isOnlyEmployeeVoiceModuleEnabled(this.props.filtersData.companyModules)) {
      isCategoryFilterDisabled = true;
    } else if (isNotNullNorUndefined(filters.modules)) {
      if (filters.modules.length === 1 && filters.modules.includes("surveys")) {
        isSurveyTypeFilterDisabled = false;
      } else if (filters.modules.length === 1 && filters.modules.includes("employeeVoice")) {
        isCategoryFilterDisabled = true;
      } else {
        isSurveyTypeFilterDisabled = true;
        isCategoryFilterDisabled = false;
      }
    } else {
      isSurveyTypeFilterDisabled = true;
      isCategoryFilterDisabled = false;
    }

    if(this.isDashboard){
      const { location, fetchCategories } = this.props;
      if(fetchCategories && key && refreshCategoriesBasedOn.indexOf(key) > -1){
        fetchCategories(parseQueryString(
          generateQueryString(
            { ...parseQueryString(location.search), filter: sanitizeFilters(objectOmit(filters, "category")) }
          )
        ));
      }
    }


    this.setState({
      isSurveyTypeFilterDisabled,
      isCategoryFilterDisabled,
      clearCategory
    });
  };

  checkAndEnableDependentFilter() {
    // check if appliedFilters contain only the survey-module
    const { appliedFilters, filtersData } = this.props;
    let isSurveyTypeFilterDisabled = true;
    let isCategoryFilterDisabled = false;
    if (isOnlySurveyModuleEnabled(filtersData.companyModules)) {
      isSurveyTypeFilterDisabled = false;
    } else {
      // if applied module filters contain only surveys
      isSurveyTypeFilterDisabled = !hasOnlyAppliedFilter(appliedFilters, "surveys");
    }

    if (isOnlyEmployeeVoiceModuleEnabled(filtersData.companyModules)) {
      isCategoryFilterDisabled = true;
    } else {
      // if applied module filters contain only employee voice
      isCategoryFilterDisabled = hasOnlyAppliedFilter(appliedFilters, "employeeVoice") ? true : false;
    }

    this.setState({
      isSurveyTypeFilterDisabled,
      isCategoryFilterDisabled
    });
  }
  

  get customFilterDefinitions(): CustomFilterDefinition[] {
    const { enabledCustomFilters = [] } = this.props;

    return Seq(enabledCustomFilters)
      .map(enabledCustomFilter => this.customFiltersMap.get(enabledCustomFilter))
      .filter(isNotNullNorUndefined)
      .toArray();
  }

  // noinspection JSMethodCanBeStatic
  mapToFilterDefinition(type: string,
    { categories, dimensions, companyModules, 
      topics, surveyTypes }: FiltersData, loading: boolean, dynamicDimensions: Dimensions): FilterDefinition[] {
    switch (type) {
      case "date":
        return [{
          key: type,
          label: "Date",
          component: DateFilter,
        }];
      case "question":
        return [{
          key: "questionTypes",
          label: "Question type",
          component: QuestionTypeFilter,
        }];
      case "category":
        const { isCategoryFilterDisabled } = this.state;
        return [{
          key: this.isDashboard ? "category" : "categories",
          label: "Category",
          component: applyDefault(
            (this.isDashboard 
              ? CategoryFilterMonoSelect : CategoryFilter),
            {
              categories,
              isDisabled: isCategoryFilterDisabled,
              loading
            }
          ),
        }];
      case "topic":
        return [{
          key: "topics",
          label: "Topic",
          component: applyDefault(TopicFilter, {
            topics
          }),
        }];
      case "sentiment":
        return [{
          key: "sentiments",
          label: "Sentiment",
          component: SentimentFilter,
        }];
      case "withCommentOnly":
        return [{
          key: type,
          label: "Only results with comments",
          component: FlagFilter,
          hidden: true,
        }];
      case "status":
        return [{
          key: type,
          label: "Status",
          component: SurveyStatusFilter,
          hidden: true,
        }];
      case "dimension":
        return generateDefinitionsForDimensions(dynamicDimensions);
      case "modules":
        const modules = mapCompanyModulesToFiltersArray(companyModules);
        return (modules.length > 1
          ? [
            {
              key: "modules",
              label: "Modules",
              component: applyDefault(ModuleFilter, {
                modules,
              }),
            },
          ]
          : []);
      case "surveyType":
        const { isSurveyTypeFilterDisabled } = this.state;
        return [{
          key: "surveyTypes",
          label: "Survey types",
          component: applyDefault(
            SurveyTypeFilter,
            {
              types: surveyTypes,
              isDisabled: isSurveyTypeFilterDisabled,
            }
          ),
        }];
    }

    return [];
  }

  render() {
    const values = this.getFilterValues();
    const { singleFieldFilter, surveyDimensionsInfoElement, filtersData } = this.props;
    const dynamicDimensions = Store.Element.isLoaded(surveyDimensionsInfoElement) ? 
      getOr(sanitizeDimensions(surveyDimensionsInfoElement.value.dimensions) , {}) : filtersData.dimensions;
    return (
      <FiltersContent
        filters={this.getFilterDefinitions()}
        customFilters={[{
          key: "addDimension",
          label: "Add a Dimension",
          component: applyDefault(AddDimensionFilter, {
            dimensions: dynamicDimensions,
            loading: Store.Element.isLoading(surveyDimensionsInfoElement),
            fetchDimensionData: this.props.fetchSurveyDimensionsInfo,
          }),
        }]}
        displayCount={false}
        onApply={this.handleApply}
        values={values}
        onChange={this.handleModuleFilterChange}
        singleFieldFilter={singleFieldFilter}
      />
    );
  }
}

export default withRouter(Filters);
