import { State } from "@store/types";

export function getActionPlansState(state: State) {
  return state.get("insights_action_plans");
}

export function getSurveyInfo(state: State) {
  return state.getIn(["insights_action_plans", "surveyInfo"]).toObject();
}

export function getPulsePollInfo(state: State) {
  return state.getIn(["insights_action_plans", "pulsePollInfo"]).toObject();
}
