import React from "react";
import styled from "styled-components";
import { Icon } from "antd";

import { CellContainer, CellInnerContainer } from "@screens/Insights/SurveyList/components/cells/Cells";
import Palette from "@src/config/theme/palette";
import { QuestionType } from "@hyphen-lib/domain/common/QuestionType";

import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { DriverImpact } from "./components/DriverImpact";
import { Trans } from "react-i18next";

interface DriverImpactCellProps {
  row: {
    type: QuestionType;
    driverImpact?: Optional<number>;
    isOutcomeQuestion?: boolean;
  };
}

export function DriverImpactCell({ row }: DriverImpactCellProps) {
  return (
    <CellContainer align="center" justifyContent="flex-start">
      <CellInnerContainer padding={[24, 0]} width="100%">
        {row.isOutcomeQuestion ?
          <OutcomeQuestion>
            <Icon type="star" style={{ fontSize: "26px", color: Palette.aquaBlue }}/>
            <Trans>Outcome Question</Trans>
          </OutcomeQuestion> :
          <DriverImpact driverImpact={row.driverImpact}/>
        }
      </CellInnerContainer>
    </CellContainer>
  );
}

export const OutcomeQuestion = styled.div`
  font-family: Lato;
  font-size: 12px;
  color: ${Palette.bluishGrey};
  display: flex;
  flex-direction: column;
  text-align: center;
`;
