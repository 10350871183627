export enum BottomDrawerActionTypes {
    MINIMIZE_BOTTOM_DRAWER = "actionList/MINIMIZE_BOTTOM_DRAWER",
    MAXIMIZE_BOTTOM_DRAWER = "actionList/MAXIMIZE_BOTTOM_DRAWER",
    HIDE_BOTTOM_DRAWER = "actionList/HIDE_BOTTOM_DRAWER",
}

export const bottomDrawerActions = {
    minimize: (): any => ({
      type: BottomDrawerActionTypes.MINIMIZE_BOTTOM_DRAWER,
    }),
    maximize: (): any => ({
      type: BottomDrawerActionTypes.MAXIMIZE_BOTTOM_DRAWER
    }),
    hide: (): any => ({
      type: BottomDrawerActionTypes.HIDE_BOTTOM_DRAWER
    }),
  };
  