import React from "react";
import styled from "styled-components";
import { Paragraph } from "@src/components/core/Typography";
import Palette from "@src/config/theme/palette";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { Trans } from "react-i18next";

interface SourceCellProps {
  readonly source: string;
  readonly info: string;
  readonly showAction?: boolean;
  readonly onViewInfo?: () => any;
}

export function SourceCell({ source, info }: SourceCellProps) {
  return (
    <Container>
      <Paragraph>
        {source}
      </Paragraph>
      {isNotNullNorUndefined(source) && (
        <Paragraph>
          <Trans>{info}</Trans>
        </Paragraph>
      )}
    </Container>
  );
}

const Container = styled.div`
  p:last-child {
    margin-top: 6px;
    color: ${Palette.bluishGrey};
  }
`;
