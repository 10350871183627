import { Optional } from "hyphen-lib/dist/lang/Optionals";

/**
 * Store the specified object in the storage.
 *
 * @param {string} key
 * @param {T} obj
 * @return {T}
 */
export function putInStorage<T>(key: string, obj: T): T {
  localStorage.setItem(key, JSON.stringify(obj));

  return obj;
}

/**
 * Read the specified value from storage.
 *
 * @param {string} key
 * @return {Optional<T>}
 */
export function findInStorage<T>(key: string): Optional<T> {
  return Optional.map(
    localStorage.getItem(key),
    JSON.parse
  );
}

/**
 * Clean the specified items from storage.
 *
 * @param {string} key
 */
export function removeInStorage(key: string) {
  localStorage.removeItem(key);
}
