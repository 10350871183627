import React from "react";
import { debounce } from "lodash";
import styled from "styled-components";
import FormItem from "antd/lib/form/FormItem";
import moment from "moment-timezone";
import {
  isNotNullNorUndefined,
  isNullOrUndefined
} from "hyphen-lib/dist/lang/Objects";
import { PostCategoryResource } from "hyphen-lib/dist/domain/resource/post/PostCategoryResource";
import Input from "@components/core/Input";
import Select from "@components/core/Select";
import SelectLarge from "@components/core/SelectLarge";
import { Paragraph } from "@components/core/Typography";
import DatePicker from "@components/core/DatePicker";
import { InputProps } from "antd/lib/input";
import { dateFormat } from "@src/utils/helper";
import { SelectProps } from "antd/lib/select";
import { TextArea } from "@src/components/core/TextArea";
import { Action } from "hyphen-lib/dist/domain/Action";
import { ActionResource } from "hyphen-lib/dist/domain/resource/action/ActionResource";
import { UserEmailInsightAccess } from "hyphen-lib/dist/domain/User";
import { List } from "immutable";
import Palette from "@src/config/theme/palette";
import { Icon } from "antd";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { Trans } from "react-i18next";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";

const { Option } = Select;

export interface ActionPlanSettingsErrors {
  action: string;
  category: string;
  description?: string;
  resource?: string;
  assignee: string;
  dueDate: string;
  notifications?: Action.NotificationsConfiguration;
  okr?: Partial<ActionResource.OKR>;
}

export interface ActionPlanValues {
  category: string;
  action: string;
  description?: string;
  resource?: string;
  assignee: string;
  dueDate: Date;
  notifications?: Action.NotificationsConfiguration;
  okr: Partial<ActionResource.OKR>;
}

export type ActionPlanSettingsFieldTypes = keyof ActionPlanValues;

export interface ActionPlanSettingsProps extends ContainerProps {
  errors?: ActionPlanSettingsErrors;
  categories?: PostCategoryResource[];
  values: ActionPlanValues;
  availableAssignees: List<UserEmailInsightAccess>;
  onUserSearch?: (value: any) => void;
  onSettingsChange: (fieldType: ActionPlanSettingsFieldTypes, value: any) => void;
  myself?: string;
  isDisabled: boolean;
  currentUser?: CurrentUserResource;
}

interface ContainerProps {
  maxWidth?: string;
}

export class ActionPlanSettings extends React.Component<ActionPlanSettingsProps> {
  constructor(props: ActionPlanSettingsProps) {
    super(props);
    this.onUserSearch = debounce(this.onUserSearch, 200);
  }

  onUserSearch = (value: any) => {
    const { onUserSearch } = this.props;
    if (isNotNullNorUndefined(onUserSearch)) {
      onUserSearch(value);
    }
  };

  onChange = (fieldType: ActionPlanSettingsFieldTypes, e: any) => {
    if (isNullOrUndefined(e)) {
      return null;
    }
    let value = e;
    if (
      fieldType === "action" ||
      fieldType === "description" ||
      fieldType === "resource"
    ) {
      value = e.target.value;
    }
    if (fieldType === "dueDate") {
      value = e.toDate();
    }
    this.props.onSettingsChange(fieldType, value);
  };

  render() {
    const {
      maxWidth, errors, values, categories, availableAssignees, myself, isDisabled, currentUser
    } = this.props;
    const { action, dueDate, assignee, category, description, resource } = values;
    const availableAssigneesEmail = availableAssignees.toJS().map(user => user.email);
    const allErrors = (isNotNullNorUndefined(errors) ? errors : {
      action: "",
      assignee: "",
      dueDate: "",
      category: "",
      notifications: {},
    });
    let insightsAccess = true;
    if (isNotEmptyArray(availableAssignees.toJS())){
      const availableAssigneesList = availableAssignees.toJS();
      if(isNotNullNorUndefined(currentUser)) {
        availableAssigneesList.push({
          _id: currentUser._id,
          email: currentUser.email,
          isInsightUser: true
        });
      }
      insightsAccess = availableAssigneesList.find(user => user.email === assignee)?.isInsightUser;
    }
    return (
      <Container maxWidth={maxWidth} data-cy="actionPlanScreen">
        <MainSection>
          {isNotNullNorUndefined(categories) && (
            <StyledSelect
              error={allErrors.category}
              label="Select a category"
              value={category}
              onChange={this.onChange.bind(this, "category")}
              data-cy="selectCategoryFromDropdown"
            >
              {categories.map(postCategory => (
                <Option key={postCategory._id} value={postCategory.label}>{postCategory.label}</Option>
              ))}
            </StyledSelect>
          )}
          <ActionName
            categories={categories}
            error={allErrors.action}
            label="Action Name"
            value={action}
            onChange={this.onChange.bind(this, "action")}
            disabled={isDisabled}
            data-cy="action_plan_name"
          />
          <ActionPlanDescription
            label="Description"
            onChange={this.onChange.bind(this, "description")}
            value={description}
            error={allErrors.description}
            rows={4}
            disabled={isDisabled}
            data-cy="enterDescriptionTextArea"
          />
          <Resource
            error={allErrors.resource}
            label="Resource URL"
            value={resource}
            onChange={this.onChange.bind(this, "resource")}
            disabled={isDisabled}
            data-cy="enterResourceUrl"
          />
          {/*
          // @ts-ignore */}
          <SelectAssignee
            showSearch
            label="Assignee"
            disabled={isDisabled}
            selectedValues={assignee}
            numberOfValuesToShow={100}
            error={allErrors.assignee}
            onChange={this.onChange.bind(this, "assignee")}
            data={availableAssigneesEmail}
            data-cy="selectAssignee"
            optionsToPrefix={[
              isNotNullNorUndefined(myself) &&
              (<Option key={myself} value={myself}><Trans>Myself</Trans> ({myself})</Option>),
            ]}
            description={ insightsAccess ? 
              "Note: Action Plan assignee will be notified by email." :
              <WarningText>
                <StyledIcon type="warning" twoToneColor={Palette.darkPink} />
                <Trans>This employee does not have access to Engage Insights and will not be 
                  able to visualize nor mark complete the Action Plan assigned to them.</Trans>
              </WarningText>}
          />
          <DatePickerLabel>
            <FormItem
              validateStatus={allErrors.dueDate && "error"}
              help={allErrors.dueDate}
            >
              <Label><Trans>Due Date</Trans></Label>
              <DueDatePicker
                disabled={isDisabled}
                format={dateFormat}
                value={moment(dueDate)}
                onChange={this.onChange.bind(this, "dueDate")}
                data-cy="selectDueDateFromDatePicker"
              />
            </FormItem>
          </DatePickerLabel>
        </MainSection>
      </Container>
    );
  }
}

const Container = styled.div`
  margin-top: 27px;
  max-width: ${(props: ContainerProps) => {
    if (isNullOrUndefined(props.maxWidth)) {
      return "900px";
    }
    return props.maxWidth;
  }};
  display: flex;
  flex-direction: row;
`;

const ActionName = styled(Input)`
  margin-top: ${(props: InputProps & { categories?: PostCategoryResource[] }) => {
    if (isNullOrUndefined(props.categories)) {
      return "initial";
    }
    return "24px";
  }};;
`;

const ActionPlanDescription = styled(TextArea)`
  margin-top: 24px;
`;

const Resource = styled(Input)`
  margin-top: 24px;
`;

const SelectAssignee = styled(SelectLarge)`
  margin-top: 24px;
`;

const DatePickerLabel = styled.div`
  margin-top: 24px;
`;

const DueDatePicker = styled(DatePicker)`
  width: 100% !important;
`;

const Label = styled(Paragraph)`
  margin-bottom: 6px;
`;

const MainSection = styled.div`
  width: 100%
`;

const WarningText = styled.div`
  margin-top: 6px;
  color: ${Palette.darkPink};
  width: 450px;
`;

const StyledIcon = styled(Icon)`
  color: ${Palette.darkPink};
  vertical-align: center;
  display: inline-block;
  margin-right: 3px;
  font-size: 15px;
`;
const StyledSelect = styled(Select) <SelectProps & { children: React.ReactNode }>``;
