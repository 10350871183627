import { default as networkActionCreators } from "@store/network/actions";
import { createRequest, FilterParameter } from "@src/utils/networks";
import { appendQueryString, generateQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import {
  SegmentReportResource
} from "hyphen-lib/dist/domain/resource/report/SegmentReportResource";
import { CommentsResultResource } from "hyphen-lib/dist/domain/resource/report/CommentsResultResource";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";

export interface FetchSegmentCommentsParameters {
  readonly filter?: FilterParameter;
}

export function fetchSegmentReportIfNeeded(dimension: string, segment: string, filter: FilterParameter) {
  return networkActionCreators.fetchElementIfNeeded({
    id: SegmentReportResource.generateId(dimension, segment, filter),
    type: SegmentReportResource.TYPE,
    request: createRequest(
      appendQueryString(
        `/segments/${dimension}/${segment}/reports/overview`,
        generateQueryString({
          filter,
        })
      )
    ),
  });
}

export function fetchSegmentCommentsIfNeeded(dimension: string,
  segment: string,
  { filter }: FetchSegmentCommentsParameters) {
  return networkActionCreators.fetchPageIfNeeded({
    id: CommentsResultResource.generateKey(
      { dimension, segment },
      filter
    ),
    type: CommentsResultResource.TYPE,
    page: PageFilter.noPagination(),
    rawPageSize: PageFilter.RAW_PAGE_SIZE_WITH_NO_PAGINATION,
    request: () => createRequest(
      appendQueryString(
        `/segments/${dimension}/${segment}/reports/comments`,
        generateQueryString({ filter })
      )
    ),
  });
}
