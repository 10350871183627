import { Radio as AntRadio } from "antd";
import { RadioProps } from "antd/lib/radio/interface";
import styled from "styled-components";
import hoistNonReactStatics from "hoist-non-react-statics";

import Palette from "@src/config/theme/palette";

interface Props extends RadioProps {
  color?: string;
}

const getRadioButtonColor = (color?: string) => {
  switch (color) {
    default:
      return Palette.bluePurple;
  }
};

const Radio = hoistNonReactStatics(styled(AntRadio) <Props> `
  span > span {
    border-color: ${props => getRadioButtonColor(props.color)} !important;
    ::after {
      background-color: ${props => getRadioButtonColor(props.color)} !important;
    }
  }
`, AntRadio);

export default Radio;
