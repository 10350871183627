import React from "react";
import { RouteComponentProps /* withRouter*/, withRouter } from "react-router";

// core styles
import styled from "styled-components";

// type defs
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { PropMapping } from "@src/utils/parameters";
import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";

// component imports
import { ExpandButton } from "@components/core/ExpandButton";
import FilterLabels from "@components/core/FilterLabels";
import { FiltersBackdrop } from "@screens/Insights/components/FiltersBackdrop";
import { CustomizationPopover } from "@screens/Insights/components/CustomizationPopover";

// utils
import {
  cleanObject,
  isNotNullNorUndefined
} from "@hyphen-lib/lang/Objects";
import { replaceTo } from "@src/utils/locations";
import convertDictToFilters, {
  clearFilter,
  clearFilterOption
} from "@components/core/FilterLabels/utils";

import {
  appendQueryString,
  generateQueryString,
  getFiltersDict,
  parseQueryString
} from "@hyphen-lib/util/net/HttpClient";
import { Post } from "hyphen-lib/dist/domain/Post";
import Palette from "@src/config/theme/palette";
import { Participation as ParticipationType} from "hyphen-lib/dist/domain/common/Participation";
import Participation from "@src/screens/Insights/components/Participation";
import { PulsePollReportsFilterContainer } from "../../containers/PulsePollReportsFilterContainer";
import IterationPicker from "./IterationPicker";
import ExportDropdown from "./ExportDropdown";

export const PULSE_POLL_OVERVIEW_REPORTS_FILTER_MAPPINGS: PropMapping[] = [
  { localKey: "filter.dimensions", storeKey: "pulsePollReportFilter.dimensions" },
  { localKey: "sort", storeKey: "pulsePollOverviewReportSort" },
  { localKey: "filter", storeKey: "pulsePollReportFilter" },
];

export const PULSE_POLL_RESULTS_REPORTS_FILTER_MAPPINGS: PropMapping[] = [
  { localKey: "filter.dimensions", storeKey: "pulsePollReportFilter.dimensions" },
  { localKey: "sort", storeKey: "pulsePollOverviewReportSort" },
  { localKey: "filter", storeKey: "pulsePollReportFilter" },
];

export const PULSE_POLL_COMMENTS_REPORTS_FILTER_MAPPINGS: PropMapping[] = [
  { localKey: "filter.dimensions", storeKey: "pulsePollReportFilter.dimensions" },
  { localKey: "filter.sentiments", storeKey: "pulsePollReportFilter.sentiments" },
  { localKey: "sort", storeKey: "pulsePollCommentsReportSort" },
  { localKey: "filter", storeKey: "pulsePollReportFilter" },
];

const PULSE_POLL_REPORTS_FILTER_MAPPINGS: PropMapping[] = [
  ...PULSE_POLL_OVERVIEW_REPORTS_FILTER_MAPPINGS,
  ...PULSE_POLL_COMMENTS_REPORTS_FILTER_MAPPINGS,
  ...PULSE_POLL_RESULTS_REPORTS_FILTER_MAPPINGS,
];

export interface ReportHeaderProps {
  readonly enabledFilters: string[];
  readonly enabledCustomFilters?: string[];
  readonly exportOption?: string;
  readonly explanation?: string;
  readonly storeMappings?: PropMapping[];
  readonly viewOptionsComponent?: React.ReactNode;
  readonly iterations: Post.PulsePoll.InstanceInfo[];
  readonly onIterationChange?: (value: any) => void;
  readonly isLoading: boolean;
  readonly selectedPollId: string;
  readonly participationData?: ParticipationType;
  readonly onModifyParameters: (parameters: Dictionary<any>, mappings?: PropMapping[]) => void;
}

interface StateProps {
  readonly dimensions: Dimensions;
}

interface ReportHeaderState {
  readonly areFiltersVisible: boolean;
  readonly areViewOptionsVisible: boolean;
}

type Props = RouteComponentProps &
StateProps &
ReportHeaderProps;

export class ReportHeader extends React.Component<
Props,
ReportHeaderState
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      areFiltersVisible: false,
      areViewOptionsVisible: false,
    };
  }

  onClearFilter = (filterToRemove: string) => {
    const filter = clearFilter(filterToRemove, this.getFiltersDictionary());
    this.onModifyList(filter);
  };

  onClearFilterOption = (filter: string, subFilter: string) => {
    const filterState = clearFilterOption(
      filter,
      subFilter,
      this.getFiltersDictionary()
    );
    this.onModifyList(filterState);
  };

  onModifyList = (filter: any) => {
    const { location, onModifyParameters, storeMappings = PULSE_POLL_REPORTS_FILTER_MAPPINGS } = this.props;

    onModifyParameters({ filter }, storeMappings);

    const queryStringParameters = {
      ...parseQueryString(location.search),
      filter: cleanObject(filter),
    };

    replaceTo(
      appendQueryString(
        location.pathname,
        generateQueryString(queryStringParameters)
      )
    );
  };

  getFiltersDictionary = () => {
    const { location } = this.props;
    return getFiltersDict(location.search);
  };

  handleViewOptionsClick = () => {
    this.setState(state => ({
      areViewOptionsVisible: !state.areViewOptionsVisible,
    }));
  };

  handleFiltersClick = () => {
    this.setState(state => ({ areFiltersVisible: !state.areFiltersVisible }));
  };

  handleApplyFilters = (filter: any) => {
    const { onModifyParameters, storeMappings = PULSE_POLL_REPORTS_FILTER_MAPPINGS } = this.props;

    onModifyParameters({ filter }, storeMappings);

    this.handleFiltersClick();
  };

  handleExportsButtonClick = () => {
    // FIXME: Write the handler functions if csv and excel data are required.
  };

  handleIterationSelect = (selection: any) => {
    const { location, onIterationChange } = this.props;
    const instanceIdRegex = /^(.*)\/instances\/([^/]+)\/(.*)$/;

    const url = location.pathname;
    const newUrl = url.replace(instanceIdRegex, (match, p1, p2, p3, offset, whole) => {
      return p1 + `/instances/${selection}/` + p3;
    });

    if (isNotNullNorUndefined(onIterationChange)) {
      onIterationChange(selection);
    }
    replaceTo(newUrl);
  };

  getFilters = () => {
    const { dimensions } = this.props;
    const filters = this.getFiltersDictionary();
    return convertDictToFilters(filters, dimensions);
  };

  render() {
    const {
      viewOptionsComponent,
      enabledFilters,
      enabledCustomFilters,
      exportOption,
      location,
      iterations,
      isLoading,
      selectedPollId,
      participationData,
    } = this.props;

    let total = 0;
    let completed = 0;

    if (isNotNullNorUndefined(participationData)) {
      total = participationData.total;
      completed = participationData.completed;
    }

    const { areFiltersVisible, areViewOptionsVisible } = this.state;
    const filters = this.getFilters();
    const selectedPoll =
      Array.isArray(iterations) &&
      iterations.filter(iteration => iteration.id === selectedPollId);
    const selectedValue =
      selectedPoll && selectedPoll.length > 0 ? selectedPollId : "";
    const isDisabled = isLoading || iterations.length === 0;
    return (
      <HeaderContainer className="block--print-show">
        <ReportHeaderLayout>
          <PickerParticipationContainer>
            <IterationPicker
              onIterationSelect={this.handleIterationSelect}
              iterationData={iterations}
              selectedValue={selectedValue}
              disabled={isDisabled}
            />
            <ParticipationHolder data-cy="participationCount">
              <Participation
                completed={completed}
                total={total}
              />
            </ParticipationHolder>
          </PickerParticipationContainer>
          <ActionButtons>
            <ExpandButton
              disabled={isDisabled}
              icon="filter"
              onClick={this.handleFiltersClick}
              translate="yes">
              Filters
            </ExpandButton>

            {viewOptionsComponent && (
              <ExpandButton
                disabled={isDisabled}
                icon="viewOptions"
                onClick={this.handleViewOptionsClick}
                translate="yes">
                View options
              </ExpandButton>
            )}
            {exportOption !== "comments" && (
              <ExportDropdown
                disabled={isDisabled}
                exportOption={exportOption}
                handleExportsButtonClick={this.handleExportsButtonClick}
                path={location.pathname}
              />
            )}
          </ActionButtons>
          {enabledFilters && areFiltersVisible && (
            <>
              <FiltersBackdrop onClick={this.handleFiltersClick} />
              <CustomizationPopover open={areFiltersVisible}>
                <PulsePollReportsFilterContainer
                  enabledCustomFilters={enabledCustomFilters}
                  enabledFilters={enabledFilters}
                  onApply={this.handleApplyFilters}
                />
              </CustomizationPopover>
            </>
          )}
          {viewOptionsComponent && areViewOptionsVisible && (
            <>
              <FiltersBackdrop onClick={this.handleViewOptionsClick} />
              <CustomizationPopover open={areViewOptionsVisible}>
                {viewOptionsComponent}
              </CustomizationPopover>
            </>
          )}
        </ReportHeaderLayout>
        {filters.length > 0 && (
          <FilterLabelsContainer>
            <FilterLabels
              filters={filters}
              onClearFilter={this.onClearFilter}
              onClearSubfilter={this.onClearFilterOption}
            />
          </FilterLabelsContainer>
        )}
      </HeaderContainer>
    );
  }
}

const PickerParticipationContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 520px;
  justify-content: space-between;
`;
const HeaderContainer = styled.div`
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(198, 214, 223, 0.15);
  border-radius: 3px;
`;

const ReportHeaderLayout = styled.div`
  padding: 20px 32px;
  background: ${Palette.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 -8px;
  > * {
    margin: 0 8px;
  }
  @media print {
    display: none;
    width: 0px !important;
    min-width: 0px !important;
    max-width: 0px !important;
    height: 0px !important;
    min-height: 0px !important;
    max-height: 0px !important;
  }
`;

const FilterLabelsContainer = styled.div`
  padding-left: 16px;
  padding-bottom: 8px;
`;

const ParticipationHolder = styled.div`
  vertical-align: middle;
  display: inline-block;
`;

export const ReportHeaderWithRouter = withRouter(ReportHeader);
