import styled from "styled-components";

import { DynamicRowProps } from "@components/core/DynamicRowsTable/withDynamicRows";
import ArtificialInteligence from "@src/components/core/svg/ArtificialInteligence";
import Palette from "@src/config/theme/palette";
import {
  CellContainer,
  CellInnerContainer
} from "@screens/Insights/SurveyList/components/cells/Cells";
import { Trans } from "react-i18next";
import { Tag } from "antdv4";
import Tooltip from "@src/components/core/Tooltip";
import { StyledInfoIcon } from "@src/screens/Insights/Survey/components/OverviewReport/components/Overview";
import { Optional } from "hyphen-lib/dist/lang/Optionals";

interface CommentCellProps {
  text: string;
  isReco?: boolean;
  translatedComment?: Optional<string>;
}

type ExternalProps = {
  translate?: boolean;
};

export function CommentCell({ row, index, translate }: DynamicRowProps<CommentCellProps> & ExternalProps) {
  return (
    <CellContainer align="flex-start" justifyContent="flex-start">
      <CellInnerContainer padding={[24, 0]} width="100%">
        { row.text !== "." && <Comment data-cy={`commentText_${index}`}>
            { translate && row.translatedComment ? row.translatedComment : <Trans>{row.text}</Trans> }
          </Comment> }
        { row.isReco && <SuggestionContainer>
            <AITag
            color={Palette.tequila}
            icon={<ArtificialInteligence />}
            data-jest="AI-based"/>
            <Suggestion><Trans>Suggestion</Trans></Suggestion>
            <Tooltip title={<Trans>We use Artificial Intelligence to identify for 
              you the comments that seem to contain a suggestion</Trans>}>
              <StyledInfoIcon className="block--print-hide" type="info-circle" data-cy="comment_sentimentTootltip"/>
            </Tooltip>
          </SuggestionContainer>
        }
      </CellInnerContainer>
    </CellContainer>
  );
}

const Comment = styled.span`
  padding-left: 32px;
  padding-right: 32px;
  font-family: Lato;
  font-size: 14px;
  color: ${Palette.veryDarkBlueGrey};
`;

const SuggestionContainer = styled.div`
  margin-left: 32px;
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

const Suggestion = styled.span`
  font-size: 16px;
  color: ${Palette.bluishGrey};
`;

const AITag = styled(Tag)`
  padding: 0;
  width: 26px;
  height: 24px;
  svg {
    height: 22px;
  }
`;
