/* eslint-disable react/self-closing-comp */
/* eslint-disable max-len */

export default function CalendarSvg() {
  return (
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Icons/Calendar</title>
      <g id="Icons/Calendar" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M13,2 C13.2454599,2 13.4496084,2.17687516 13.4919443,2.41012437 L13.5,2.5 L13.5,3.75 L14.0689655,3.75 C14.9520503,3.75 15.6706619,4.45663333 15.743859,5.34954569 L15.75,5.5 L15.75,14 C15.75,14.9626873 15.0011239,15.75 14.0689655,15.75 L14.0689655,15.75 L3.93103448,15.75 C2.99889169,15.75 2.25,14.9626735 2.25,14 L2.25,14 L2.25,5.5 C2.25,4.53734286 2.99890497,3.75 3.93103448,3.75 L3.93103448,3.75 L4.5,3.75 L4.5,2.5 C4.5,2.22385763 4.72385763,2 5,2 C5.24545989,2 5.44960837,2.17687516 5.49194433,2.41012437 L5.5,2.5 L5.5,3.75 L12.5,3.75 L12.5,2.5 C12.5,2.22385763 12.7238576,2 13,2 Z M4.5,4.75 L3.93103448,4.75 C3.56269413,4.75 3.25,5.07874329 3.25,5.5 L3.25,5.5 L3.25,14 C3.25,14.4212724 3.56268012,14.75 3.93103448,14.75 L3.93103448,14.75 L14.0689655,14.75 C14.4373347,14.75 14.75,14.4212869 14.75,14 L14.75,14 L14.75,5.5 C14.75,5.07872884 14.4373207,4.75 14.0689655,4.75 L14.0689655,4.75 L13.5,4.75 L13.5,6 C13.5,6.27614237 13.2761424,6.5 13,6.5 C12.7545401,6.5 12.5503916,6.32312484 12.5080557,6.08987563 L12.5,6 L12.5,4.75 L5.5,4.75 L5.5,6 C5.5,6.27614237 5.27614237,6.5 5,6.5 C4.75454011,6.5 4.55039163,6.32312484 4.50805567,6.08987563 L4.5,6 L4.5,4.75 Z M13,7.75 C13.4142136,7.75 13.75,8.08578644 13.75,8.5 C13.75,8.87969577 13.4678461,9.19349096 13.1017706,9.24315338 L13,9.25 L5,9.25 C4.58578644,9.25 4.25,8.91421356 4.25,8.5 C4.25,8.12030423 4.53215388,7.80650904 4.89822944,7.75684662 L5,7.75 L13,7.75 Z" id="Combined-Shape" fill="#AAB9CE"></path>
      </g>
  </svg>
  );
}