import React from "react";

export default class PulsePollsTrends extends React.Component {
  render() {
    return (
      <>
        Trends
      </>
    );
  }
}
