import { isNullOrUndefined } from "util";
import { parseNumber } from "hyphen-lib/dist/lang/Number";
import {isNotNullNorUndefined, entries, isNotEmptyObject, isEmptyObject} from "@hyphen-lib/lang/Objects";
import { Dictionary } from "@hyphen-lib/domain/structure/Dictionary";
import { Optional } from "@hyphen-lib/lang/Optionals";
import { AES, enc } from "crypto-js";
import { Company } from "hyphen-lib/dist/domain/Company";
import { SortParameter } from "./networks";

const ENCRYPTION_SECRET = "Hyphen_string_encryption";

export const moduleNameMapper: Dictionary<any> = {
  surveys: "Surveys",
  pulsePolls: "Polls",
  employeeVoice: "Employee voice",
};

/* Helper functions */

/**
 * Format the sort object.
 *
 * ```
 * {foo: "1", bar: "-1"}
 * ```
 * will be transformed into {foo: 1, bar: -1}
 *
 * @param {Optional<Dictionary<string>>)} sort
 * @return {Optional<Dictionary<1 | -1>>}
 */
export function formatSort(sort: Optional<Dictionary<string>>): Optional<Dictionary<1 | -1>> {
  if (isNotNullNorUndefined(sort) && isNotEmptyObject(sort)) {
    return entries(sort)
      .map(val => parseNumber(val, 1))
      .toObject() as Dictionary<1 | -1>;
  }
}

export function capitalizeFirstLetter(str: string | undefined) {
  if (isNullOrUndefined(str)) {
    return "";
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function encryptString(str: string): string {
  return AES.encrypt(str, ENCRYPTION_SECRET).toString();
}

export function decryptString(cipheredString: any): string {
  const bytes = AES.decrypt(cipheredString, ENCRYPTION_SECRET);
  return bytes.toString(enc.Utf8);
}

export function getSortOrder(field: string, sort?: SortParameter) {
  if (isEmptyObject(sort)) {
    return undefined;
  }
  if (sort![field] === 1) {
    return "ascend";
  } else if (sort![field] === -1) {
    return "descend";
  }
  return undefined;
}

export function mapCompanyModulesToFiltersArray(
  companyModules: Company.Modules
): { label: string; value: string }[] {
  const modules = Object.entries(companyModules)
    .filter(function filterEnabledModule([_, isEnabled]) {
      return isEnabled;
    })
    .map(function mapModuleToName([companyModule]) {
      return {
        label: moduleNameMapper[companyModule],
        value: companyModule,
      };
    });
  return modules;
}

export const dateFormat = "MM/DD/YYYY";

// Source: https://css-tricks.com/snippets/javascript/lighten-darken-color/
export function lightenDarkenColor(color: string, amount: number) {
  let usePound = false;

  if (color[0] === "#") {
    color = color.slice(1);
    usePound = true;
  }

  const num = parseInt(color, 16);

  // tslint:disable-next-line: no-bitwise
  let r = (num >> 16) + amount;

  if (r > 255) {
    r = 255;
  } else if (r < 0) {
    r = 0;
  }

  // tslint:disable-next-line: no-bitwise
  let b = ((num >> 8) & 0x00ff) + amount;

  if (b > 255) {
    b = 255;
  } else if (b < 0) {
    b = 0;
  }

  // tslint:disable-next-line: no-bitwise
  let g = (num & 0x0000ff) + amount;

  if (g > 255) {
    g = 255;
  } else if (g < 0) {
    g = 0;
  }

  // tslint:disable-next-line: no-bitwise
  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

export function multiplyBy(rhs: number): (lhs: number) => number {
  return function multiply(lhs: number) {
    return lhs * rhs;
  };
}
