import { Record } from "immutable";

import { State, StateProps } from "./types";
import { ActionTypes } from "./actions";

export const defaultValues: StateProps = {
  menu: "overview",
};

export const stateFactory = Record<StateProps>(defaultValues);
const defaultState = stateFactory();

export const reducers = (state: State = defaultState, action: any) => {
  // noinspection JSRedundantSwitchStatement
  switch (action.type) {
    case ActionTypes.SELECT_REPORTS_MENU:
      return state.setIn(["menu"], action.menu);
    default:
      return state;
  }
};

export default reducers;
