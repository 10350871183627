import React, { Component } from "react";
import { Trans } from "react-i18next";
import ContainerCard from "@components/core/ContainerCard";
import Palette from "@src/config/theme/palette";
import styled from "styled-components";
import withDebounce from "@src/components/core/withDebounce";
import { AppSettingsResource } from "hyphen-lib/dist/domain/resource/AppSettingsResource";
import { Paragraph } from "@src/components/core/Typography";
import { getOr } from "hyphen-lib/dist/lang/Objects";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { TextArea } from "@components/core/TextArea";
import { AnonymousBanner } from "../components/AnonymousBanner";
import { Divider } from "antd";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";
import { checkIfHyphenAdmin } from "hyphen-lib/dist/business/user/Users";

// @ts-ignore
const DebouncedTextArea = withDebounce(TextArea);

interface AppSettingsSurveyProps {
  appSettings: AppSettingsResource;
  onValueChange: (values: AppSettingsResource) => void;
  onAnonymousBannerChange: (values: AppSettingsResource) => void;
  readonly showAnonymousBanner: boolean;
  readonly email: CurrentUserResource["email"];
}

interface AppSettingsSurveyStates {
  errorInSurveyText: boolean;
  surveyTextAreaValue: string;
}

export default class Survey extends Component<AppSettingsSurveyProps, AppSettingsSurveyStates> {
  constructor(props: AppSettingsSurveyProps) {
    super(props);
    this.state = {
      errorInSurveyText: false,
      surveyTextAreaValue: "",
    };
  }

  updateSurveyText = (surveyText: string) => {
    if (surveyText.length < 100 || surveyText.length > 500) {
      this.setState({
        errorInSurveyText: true,
      });
    } else {
      this.setState({
        errorInSurveyText: false,
      });
      
      this.props.onValueChange({
        ...this.props.appSettings,
        surveyText,
      });
    }
  };

  setSurveyText() {
    if (
      isNotNullNorUndefined(this.props.appSettings) &&
      isNotNullNorUndefined(this.props.appSettings.surveyText) &&
      this.state.surveyTextAreaValue === ""
    ) {
      this.setState({
        surveyTextAreaValue: getOr(this.props.appSettings.surveyText, ""),
      });
    }
  }

  componentDidMount() {
    this.setSurveyText();
  }

  componentDidUpdate() {
    this.setSurveyText();
  }

  render() {
    const { email, onAnonymousBannerChange, showAnonymousBanner, appSettings } = this.props;
    return (
      <StyledContainerCard title="Surveys & Polls">
        <Holder>
          <Paragraph translate="yes">Default survey introduction page text</Paragraph>
          <TextHolder>
            <StyledTextArea
              // @ts-ignore
              // FIXME: autosize is present in TextAreaProps but lost after using withDebounce HOC
              autoSize={false}
              onValueChange={this.updateSurveyText}
              rows={4}
              waitTime={800}
              value={this.state.surveyTextAreaValue}
            />
            {this.state.errorInSurveyText && (
              <Warning><Trans>Length of survey introduction page text must be between 100 and 500</Trans></Warning>
            )}
          </TextHolder>
          <Paragraph weight="light" translate="yes">
            This will be displayed to the recipients on the first page of a survey. You can customize it per survey.
          </Paragraph>
        </Holder>
        { checkIfHyphenAdmin(email) && (<>
        <StyledDivider />
        <AnonymousBanner
            onAnonymousBannerChange={onAnonymousBannerChange}
            appSettings={appSettings}
            showAnonymousBanner={showAnonymousBanner}
        />
        </>)}
      </StyledContainerCard>
    );
  }
}

const StyledTextArea = styled(DebouncedTextArea)`
  margin-top: 24px !important;
  resize: none;
`;

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 28px;
`;

const StyledContainerCard = styled(ContainerCard)`
  margin-bottom: 39px;
  input:focus {
    outline: none;
  }
`;

const TextHolder = styled.div`
  max-width: 450px;
`;

const Warning = styled.p`
  color: ${Palette.darkPink};
`;

const StyledDivider = styled(Divider)`
  margin-top: 27px;
  margin-bottom: 20px;
`;
