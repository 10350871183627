import React from "react";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";
import { State } from "@store/types";
import qs from "qs";
import {
  areEquals,
  getOr,
  isNotEmptyObject,
  isNotNullNorUndefined,
  isNullOrUndefined,
  mapAtIndexOr,
  mapOr,
} from "@hyphen-lib/lang/Objects";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { fromJS, Map } from "immutable";

import Segment from "@src/screens/Insights/components/Segments";
import {
  generateCompareWithOptions,
  getAvailableComparisons,
  getMatchingOptionLabel,
} from "@src/utils/Comparisons";
import ViewOptions, {
  getViewOptionValuesFromLocation,
} from "@screens/Insights/components/ViewOptions";
import { getViewOptionDefinitions } from "@src/utils/ViewOptions";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { CompareWithOption } from "@src/screens/Insights/components/ViewOptions/components/CompareWith";
import { connect } from "react-redux";
import { isEmpty } from "hyphen-lib/dist/lang/Arrays";
import {
  appendQueryString,
  generateQueryString,
  parseQueryString,
} from "hyphen-lib/dist/util/net/HttpClient";
import { FilterParameter } from "@src/utils/networks";
import {
  fetchPulsePollOverviewReportIfNeeded,
  FetchPulsePollOverviewReportParameters,
} from "@store/network/resource/PulsePollOverviewReportResource";
import { LoadingContainer } from "@src/screens/Insights/Survey/components/Loading";
import { getResourceById } from "@src/store/network/selectors";
import { 
  PulsePollOverviewReportResource 
} from "hyphen-lib/dist/domain/resource/pulsePoll/report/PulsePollOverviewReportResource";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { getAnonymityThreshold } from "@src/screens/Insights/Settings/store/selectors";
import { goTo, replaceLocation, replaceTo } from "@src/utils/locations";
import { HighlightedSegment } from "hyphen-lib/dist/domain/resource/report/common/Highlights";
import { LineChartData } from "@src/components/core/LineChart";
import * as selectors from "@src/screens/Insights/store/selectors";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { getParameters } from "@src/screens/Insights/parameters/store/selectors";
import { applyExistingParametersIfNeeded } from "@src/utils/parameters";
import SentimentOverview from "@src/screens/Insights/components/Reports/SentimentOverview";
import ContainerCard from "@src/components/core/ContainerCard";
import NoResult from "@src/components/core/NoResult";
import { PulsePollInfoResource } from "hyphen-lib/dist/domain/resource/PulsePollInfoResource";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { withRouter } from "react-router-dom";
import { ResultWithAnonymity } from "hyphen-lib/dist/domain/common/ResultWithAnonymity";
import Palette from "@src/config/theme/palette";
import { AnonymityFilterExplanation } from "hyphen-lib/dist/domain/common/AnonymityFilterExplanation";
import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
import { getDimensions } from "@src/screens/Insights/store/selectors";
import { FetchError } from "@src/screens/Insights/errors/FetchError";
import { PulsePollReportHeader } from "../PulsePollReportHeaderContainer";
import { PulsePollsAnonymity } from "../../components/PulsePollsAnonymity";
import { PulsePollAnswerDistribution } from "../../components/PulsePollAnswerDistribution";
import { PULSE_POLL_OVERVIEW_REPORTS_FILTER_MAPPINGS } from "../../components/ReportHeader/index";
import { fetchFocusAreasIfNeeded } from "@src/store/network/resource/ActionResources";
import {
  focusAreaListActionCreators,
  FocusAreaListPageParameters,
} from "@src/screens/Insights/Actions/store/actions";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";
import {
  getAllFocusAreasFromReduxStore,
  getCurrentBottomDrawerView,
} from "@src/utils/FocusArea";
import BottomDrawer, { DrawerViews } from "@src/components/core/BottomDrawer";
import BottomDrawerStripedChild, {
  transformFocusAreasToStripStack,
} from "@src/components/core/BottomDrawer/StripedChild";
import { BottomDrawerMessages } from "@src/screens/Insights/BottomDrawer/constant";
import { hasFocusAreaRights } from "hyphen-lib/dist/business/focusArea/focusAreaResource";
import { PulsePollInstanceInfoResource } from "hyphen-lib/dist/domain/resource/PulsePollInstanceInfoResource";
import { Participation } from "hyphen-lib/dist/domain/common/Participation";
import { fetchPulsePollParticipationIfNeeded } from "@src/store/network/resource/ParticipationReportResources";
import { ParticipationReportResource } from "hyphen-lib/dist/domain/resource/survey/report/ParticipationReportResource";

interface MatchParams {
  templateId: string;
  instanceId: string;
}

interface PulsePollsOverviewReduxAction {
  onFetchIfNeeded: (payload: FetchPulsePollOverviewReportParameters) => void;
  readonly onFetchFocusArea: (parameters: FocusAreaListPageParameters) => any;
  readonly onCreateFocusArea: (focusArea: Partial<FocusAreaResource>) => void;
  readonly onFetchParticipation: (templateId: string, iterationId: string, queryString: Dictionary<any>) => void;
}

interface PulsePollsOverviewProps {
  compareWithOptions: CompareWithOption[];
  comparisonKey: string;
  enabledViewOptions: string[];
}

interface PulsePollsOverviewReduxStateProps {
  readonly filters: FilterParameter;
  readonly pulsePollOverviewElement: Store.Element<PulsePollOverviewReportResource>;
  readonly dimensions: Optional<Dimensions>;
  readonly anonymityThreshold: number;
  readonly instanceId: string;
  readonly company: CompanyResource;
  readonly parameters: Map<string, any>;
  readonly pulsePollInfo: Loadable<PulsePollInfoResource>;
  readonly focusAreas?: FocusAreaResource[];
  readonly currentUser: Optional<CurrentUserResource>;
  readonly currentBottomDrawerView: DrawerViews;
  readonly hasFaRights: boolean;
  readonly participationData: Participation;
  readonly filteredParticipantData: Participation;
}

type Props = PulsePollsOverviewProps &
  PulsePollsOverviewReduxAction &
  PulsePollsOverviewReduxStateProps &
  RouteComponentProps<MatchParams>;

interface OverviewReportState {
  readonly viewOptions: {
    readonly comparison: boolean;
    readonly compareWith: Optional<string>;
  };
  totalFocusAreas: number;
}

export class PulsePollsOverviewContainer extends React.Component<
  Props,
  OverviewReportState
> {
  template = false;
  constructor(props: Props) {
    super(props);
    const viewOptions = getViewOptionValuesFromLocation(props.location);

    this.state = {
      totalFocusAreas: 0,
      ...this.mapViewOptionsToState(
        viewOptions,
        props.compareWithOptions,
        this.getDefaultViewOptionValues()
      ),
    };
  }

  extractParametersFromQuery(props: Props) {
    return this.extractRelevantParametersForQuery(
      parseQueryString(props.location.search)
    );
  }

  // noinspection JSMethodCanBeStatic
  extractRelevantParametersForQuery(parameters: any) {
    const relevantParameters = { ...parameters };
    delete relevantParameters.viewOptions;
    delete relevantParameters.pollId;
    return relevantParameters;
  }

  componentDidMount() {
    const {
      parameters,
      location: { search },
    } = this.props;

    const existing = parseQueryString(search);
    const mergedParameters = applyExistingParametersIfNeeded(
      parameters.toJS(),
      existing,
      ...PULSE_POLL_OVERVIEW_REPORTS_FILTER_MAPPINGS
    );

    if (
      isNotNullNorUndefined(mergedParameters) &&
      isNotEmptyObject(mergedParameters)
    ) {
      replaceLocation(mergedParameters);
    } else {
      // fetch data only if we will stay on this page,
      // otherwise it will be fetched anyway in componentDidUpdate
      this.fetchPulsePollOverviewReport();
    }

    if (
      (isNullOrUndefined(this.props.focusAreas) ||
        isEmpty(this.props.focusAreas)) &&
      this.props.hasFaRights
    ) {
      this.props.onFetchFocusArea(this.fetchFocusAreaProps());
    }

    let totalFocusAreas = 0;
    if (isNotNullNorUndefined(this.props.focusAreas)) {
      totalFocusAreas = this.props.focusAreas.length;
    }

    this.setState({
      totalFocusAreas,
    });
  }

  fetchFocusAreaProps(): FocusAreaListPageParameters {
    return {
      page: {
        number: 1,
        size: 30,
      },
      filter: {
        withActionPlans: false,
      },
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { instanceId, pulsePollOverviewElement } = this.props;

    const oldParams = this.extractParametersFromQuery(prevProps);
    const newParams = this.extractParametersFromQuery(this.props);
    if (
      (instanceId !== prevProps.instanceId ||
        not(areEquals(oldParams, newParams))) &&
      not(Store.Element.isInError(pulsePollOverviewElement))
    ) {
      this.fetchPulsePollOverviewReport();
    }

    if (this.props.hasFaRights) {
      this.props.onFetchFocusArea(this.fetchFocusAreaProps());
    }

    if (
      isNotNullNorUndefined(this.props.focusAreas) &&
      isNotNullNorUndefined(prevProps.focusAreas)
    ) {
      if (this.props.focusAreas.length > prevProps.focusAreas.length) {
        this.setState({
          totalFocusAreas: this.props.focusAreas.length,
        });
      }
    }
  }

  fetchPulsePollOverviewReport = () => {
    const { onFetchIfNeeded,
        match, 
        instanceId,
        onFetchParticipation, 
    } = this.props;
    const {
      params: { templateId },
    } = match;

    const queryParams = this.extractParametersFromQuery(this.props);
    onFetchParticipation(templateId, instanceId, queryParams);
    onFetchIfNeeded({
      templateId,
      queryParams,
      instanceId,
    });
  };

  getDefaultViewOptionValues = () => {
    return {
      comparison: true,
    };
  };

  mapViewOptionsToState = (
    viewOptions: Dictionary<any>,
    compareWithOptions: CompareWithOption[],
    defaultValues: Dictionary<boolean>
  ) => {
    const compareWithMode: Optional<string> = getOr(
      viewOptions.compareWith,
      mapAtIndexOr(compareWithOptions, 0, (o) => o.key, "previous")
    );

    return {
      viewOptions: {
        comparison:
          isNotNullNorUndefined(compareWithMode) &&
          getOr(viewOptions.comparison, defaultValues.comparison),
        compareWith: compareWithMode,
      },
    };
  };

  getViewOptionsComponent = (
    enabledViewOptions: string[],
    compareWithOptions: CompareWithOption[]
  ): React.ReactNode => {
    const viewOptions = getViewOptionDefinitions(
      enabledViewOptions,
      compareWithOptions
    );
    if (isEmpty(viewOptions)) {
      return null;
    }
    return (
      <ViewOptions
        viewOptions={viewOptions}
        defaultValues={this.state.viewOptions}
        onChange={this.handleViewOptionsChange}
      />
    );
  };

  getCompareWithOptions = () => {
    const { pulsePollOverviewElement, company } = this.props;
    let compareWithOptions: CompareWithOption[] = [];

    const pulsePollOverviewReport = Store.Element.toLoadable(
      pulsePollOverviewElement
    );

    if (
      Loadable.isLoaded(pulsePollOverviewReport) &&
      !pulsePollOverviewReport.value.filteredForAnonymity &&
      pulsePollOverviewReport.value.type ===
        PulsePollOverviewReportResource.NonFilteredType.WITH_SCORE
    ) {
      compareWithOptions = generateCompareWithOptions(
        getOr(pulsePollOverviewReport.value.availableComparisons, {}),
        company,
        true
      );
    }
    return compareWithOptions;
  };

  handleViewOptionsChange = (viewOptions: Dictionary<any>) => {
    this.setState(
      this.mapViewOptionsToState(
        viewOptions,
        this.props.compareWithOptions,
        this.getDefaultViewOptionValues()
      )
    );
  };

  onFavorabilityDataPointClick = (data: LineChartData) => {
    const {
      match: {
        params: { templateId },
      },
    } = this.props;
    const pollId = data[2];

    if (isNotNullNorUndefined(pollId)) {
      replaceTo(
        `/pulsePolls/${templateId}/instances/${pollId}/reports/overview`
      );
    }
  };

  onWordClick = (word: string, sentiment: string) => {
    const param = {
      filter: {
        freeText: word,
        sentiments: [sentiment],
      },
    };
    const {
      location: { search, pathname },
    } = this.props;
    const tempQueryParams: any = qs.parse(search, { ignoreQueryPrefix: true });

    if (
      isNotNullNorUndefined(tempQueryParams.filter) &&
      isNotNullNorUndefined(tempQueryParams.filter.sentiments)
    ) {
      delete tempQueryParams.filter.sentiments;
    }
    const queryParams = fromJS(tempQueryParams).mergeDeep(param).toJS();
    const pathArr = pathname.split("/");
    pathArr.splice(pathArr.length - 1, 1, "comments");
    const newPath = pathArr.join("/");
    replaceTo(appendQueryString(newPath, generateQueryString(queryParams)));
  };

  renderAnswerDistribution = (
    reportValue: PulsePollOverviewReportResource.NonFiltered,
    infoValue: PulsePollInfoResource
  ) => {
    const {
      instanceId,
      comparisonKey = "previous",
      anonymityThreshold,
      company,
      participationData,
      filteredParticipantData
    } = this.props;
    const { viewOptions } = this.state;

    const compareWithOptions = this.getCompareWithOptions();
    const comparisonLabel = getMatchingOptionLabel(
      compareWithOptions,
      comparisonKey
    );
    const averageComparisonLabel = getMatchingOptionLabel(
      compareWithOptions,
      "average"
    );

    const { votesSummary } = reportValue;
    return (
      <PulsePollAnswerDistribution
        compareAverage={4}
        type={reportValue.type}
        comparePrevious={-4}
        onDataPointClick={this.onFavorabilityDataPointClick}
        comparisonLabel={comparisonLabel}
        averageComparisonLabel={averageComparisonLabel}
        showSecondComparison={viewOptions.compareWith !== "average"}
        areComparisonsVisible={viewOptions.comparison}
        anonymityThreshold={anonymityThreshold}
        summary={votesSummary}
        instanceId={instanceId}
        isNPSQuestion={infoValue.isNPSQuestion}
        isRatingQuestion={infoValue.isRatingQuestion}
        companyName={company.name}
        participationData={participationData}
        filteredParticipantData={filteredParticipantData}
      />
    );
  };

  renderSegments = (
    pulsePollOverviewReport: PulsePollOverviewReportResource.NonFiltered,
    dimensions: Optional<Dimensions>,
    infoValue: PulsePollInfoResource
  ) => {
    const { company, focusAreas, onCreateFocusArea } = this.props;

    if (
      pulsePollOverviewReport.type ===
      PulsePollOverviewReportResource.NonFilteredType.NO_SCORE
    ) {
      return null;
    }

    const compareWithOptions = generateCompareWithOptions(
      mapOr(pulsePollOverviewReport, getAvailableComparisons, {}),
      company,
      true
    );

    const { viewOptions } = this.state;
    let improvedSegments: HighlightedSegment[] = [];
    let declinedSegments: HighlightedSegment[] = [];

    const comparisonImprovements = "previous";

    if (
      isNotEmptyObject(pulsePollOverviewReport.improvements) &&
      isNotEmptyObject(
        pulsePollOverviewReport.improvements[comparisonImprovements]
      )
    ) {
      improvedSegments = getOr(
        pulsePollOverviewReport.improvements[comparisonImprovements]
          .improvedSegments,
        []
      );
      declinedSegments = getOr(
        pulsePollOverviewReport.improvements[comparisonImprovements]
          .declinedSegments,
        []
      );
    }

    return (
      <Section>
        <Segment
          segmentLists={{
            strongestSegments: pulsePollOverviewReport.strongestSegments,
            weakestSegments: pulsePollOverviewReport.weakestSegments,
            biggestDecline: declinedSegments,
            biggestImprovements: improvedSegments,
          }}
          dimensions={dimensions}
          areComparisonsVisible={viewOptions.comparison}
          compareWithOptions={compareWithOptions}
          comparisonKey={viewOptions.compareWith}
          hasActionCreationRight={true}
          isNPSQuestion={infoValue.isNPSQuestion}
          isRatingQuestion={infoValue.isRatingQuestion}
          focusAreas={focusAreas}
          onCreateFocusArea={onCreateFocusArea}
          focusAreaLabel={infoValue.question}
        />
      </Section>
    );
  };

  renderCommentsSummary = (
    commentsSummary: ResultWithAnonymity<PulsePollOverviewReportResource.CommentsSummary>,
    infoResource: PulsePollInfoResource
  ) => {
    const { anonymityThreshold } = this.props;

    if (commentsSummary.filteredForAnonymity) {
      return (
        <Section>
          <ContainerCard title="Comments overview">
            <PulsePollsAnonymity
              anonymityThreshold={anonymityThreshold}
              explanation={commentsSummary.explanation}
            />
          </ContainerCard>
        </Section>
      );
    }

    if (
      isNotNullNorUndefined(infoResource.isSentimentAnalysisQuestion) &&
      not(infoResource.isSentimentAnalysisQuestion)
    ) {
      return (
        <Section data-cy="poll_notSentimentAnalysed">
          <ContainerCard title="Comments overview">
            <CommentsSummary data-cy="comments_count">
              {commentsSummary.numberOfComments} comments
            </CommentsSummary>
            <NoResult
              type="minimal"
              description="Poll is not set for sentiment analysis"
            />
          </ContainerCard>
        </Section>
      );
    }

    return (
      <SentimentOverview
        filteredForAnonymity={commentsSummary.filteredForAnonymity}
        netSentimentScore={commentsSummary.netSentimentScore}
        sentiments={commentsSummary.sentiments}
        words={commentsSummary.words}
        numberOfComments={commentsSummary.numberOfComments}
        anonymityThreshold={this.props.anonymityThreshold}
        onWordClick={this.onWordClick}
      />
    );
  };

  renderReport = () => {
    const {
      pulsePollOverviewElement,
      dimensions,
      anonymityThreshold,
      pulsePollInfo,
    } = this.props;
    const pulsePollOverviewReport = Store.Element.toLoadable(
      pulsePollOverviewElement
    );

    if (Store.Element.isInError(pulsePollOverviewElement)) {
      return (
        <FetchError
          {...pulsePollOverviewElement}
          resourceType={PulsePollOverviewReportResource.TYPE}
        />
      );
    }

    if (Store.Element.isInError(pulsePollInfo)) {
      return (
        <FetchError
          {...pulsePollInfo}
          resourceType={PulsePollInfoResource.TYPE}
        />
      );
    }

    if (Store.Element.isNotFound(pulsePollOverviewElement)) {
      return (
        <Section>
          <ContainerCard>
            <NoResult type="minimal" description="No iterations found" />
          </ContainerCard>
        </Section>
      );
    }

    if (
      Loadable.isLoading(pulsePollOverviewReport) ||
      Loadable.isLoading(pulsePollInfo)
    ) {
      return <LoadingContainer>Loading...</LoadingContainer>;
    }
    if (Loadable.isLoaded(pulsePollOverviewReport)) {
      if (pulsePollOverviewReport.value.filteredForAnonymity) {
        const { numberOfVoters } = pulsePollOverviewReport.value
          .explanation as AnonymityFilterExplanation.NotEnoughVoters;

        if (numberOfVoters === 0) {
          return (
            <Section>
              <ContainerCard>
                <NoResult
                  type="minimal"
                  data-cy="pollOverview_noVoters"
                  description="No voters for this poll"
                />
              </ContainerCard>
            </Section>
          );
        }

        return (
          <PulsePollsAnonymity
            explanation={pulsePollOverviewReport.value.explanation}
            anonymityThreshold={anonymityThreshold}
          />
        );
      }

      const overviewReportValue = pulsePollOverviewReport.value;
      const infoResourceValue = pulsePollInfo.value;
      return (
        <>
          <Section>
            {this.renderAnswerDistribution(
              overviewReportValue,
              infoResourceValue
            )}
          </Section>
          {this.renderCommentsSummary(
            overviewReportValue.commentsSummary,
            infoResourceValue
          )}
          {this.renderSegments(
            overviewReportValue,
            dimensions,
            infoResourceValue
          )}
        </>
      );
    }
  };

  render() {
    const {
      enabledViewOptions = ["comparison", "compareWith"],
      focusAreas,
      hasFaRights,
    } = this.props;

    return (
      <>
        <PulsePollReportHeader
          enabledFilters={["dimension"]}
          enabledCustomFilters={["addDimension"]}
          viewOptionsComponent={this.getViewOptionsComponent(
            enabledViewOptions,
            this.getCompareWithOptions()
          )}
          storeMappings={PULSE_POLL_OVERVIEW_REPORTS_FILTER_MAPPINGS}
        />
        {this.renderReport()}
        {hasFaRights && (
          <BottomDrawer
            key="segment-drawer"
            initialView={this.props.currentBottomDrawerView}
            title={`${
              this.state.totalFocusAreas === 0
                ? "No"
                : this.state.totalFocusAreas
            } Focus Area Added`}
            footerButton={{
              display: true,
              text: "Go To Action Center",
              onClick: () => goTo("/actioncenter"),
            }}
          >
            <BottomDrawerStripedChild
              stripStack={transformFocusAreasToStripStack(focusAreas)}
              emptyStackMessage={BottomDrawerMessages.EMPTY_STACK_MESSAGE}
            />
          </BottomDrawer>
        )}
      </>
    );
  }
}

const Section = styled.div`
  margin-top: 24px;
`;

function mapStateToProps(
  state: State,
  { location, match }: RouteComponentProps<MatchParams>
): PulsePollsOverviewReduxStateProps {
  const currentUser = selectors.getCurrentUser(state);
  const queryParameters = parseQueryString(location.search);
  const filters = getOr(queryParameters.filter, {});
  const {
    params: { templateId, instanceId },
  } = match;

  const pulsePollOverviewElement = getResourceById(
    state,
    PulsePollOverviewReportResource.TYPE,
    PulsePollOverviewReportResource.generateId(instanceId, filters)
  );
  const pulsePollInfoElement = getResourceById(
    state,
    PulsePollInfoResource.TYPE,
    templateId
  );

  const pulsePollInstanceInfoElement = getResourceById(
    state,
    PulsePollInstanceInfoResource.TYPE,
    PulsePollInstanceInfoResource.generateId(instanceId)
  );
  const data = getAllFocusAreasFromReduxStore(state);
  const currentBottomDrawerView = getCurrentBottomDrawerView(state);
  const hasFaRights = hasFocusAreaRights(selectors.getRightsMatcher(state));

  const participationData = Store.Element.mapIfLoadedOr(
    pulsePollInstanceInfoElement,
    participation => participation.participationData,
    { total: 0, completed: 0 }
  );

  const participations = getResourceById(
    state,
    ParticipationReportResource.TYPE,
    ParticipationReportResource.generateId(
      match.params.instanceId,
      parseQueryString(location.search).filter
    )
  );
  const filteredParticipantData = Store.Element.mapIfLoadedOr(
    participations,
    participation => participation.company,
    { total: 0, completed: 0 }
  );
  return {
    instanceId,
    pulsePollOverviewElement,
    dimensions: getDimensions(state),
    filters,
    anonymityThreshold: getAnonymityThreshold(state),
    parameters: getParameters(state),
    company: selectors.getCompany(state)!,
    pulsePollInfo: Store.Element.toLoadable(pulsePollInfoElement),
    focusAreas: data,
    currentUser,
    currentBottomDrawerView,
    hasFaRights,
    participationData,
    filteredParticipantData
    // filteredParticipantData: getResourceById(
    //   state,
    //   ParticipationReportResource.TYPE,
    //   ParticipationReportResource.generateId(
    //     match.params.instanceId,
    //     parseQueryString(location.search).filter
    //   )
    // ),
  };
}

const mapDispatchToProps = {
  onFetchIfNeeded: fetchPulsePollOverviewReportIfNeeded,
  onFetchFocusArea: fetchFocusAreasIfNeeded,
  onCreateFocusArea: focusAreaListActionCreators.createFocusArea,
  onFetchParticipation: fetchPulsePollParticipationIfNeeded,
};

export const PulsePollsOverview = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PulsePollsOverviewContainer)
);

const CommentsSummary = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  color: ${Palette.bluishGrey};
`;
