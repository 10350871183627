import { Record, RecordOf } from "immutable";
import { FilterParameter } from "@src/utils/networks";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import {
  CleanFilterAction,
  ModifyFilterAction,
  ActionListFiltersActionTypes
} from "./actions";

export interface ActionListFilterContainerStateProps {
  readonly filter: Optional<FilterParameter>;
}

export type ActionListFilterContainerState = RecordOf<ActionListFilterContainerStateProps>;

const defaultValues: ActionListFilterContainerStateProps = {
  filter: Optional.empty(),
};

export const actionListFilterContainerStateFactory = Record<ActionListFilterContainerStateProps>(defaultValues);

type Actions =
  | ModifyFilterAction
  | CleanFilterAction;

export function actionListFilterContainerReducers(
  state: ActionListFilterContainerState = actionListFilterContainerStateFactory(),
  action: Actions): ActionListFilterContainerState {

  switch (action.type) {
    case ActionListFiltersActionTypes.MODIFY_FILTER:
      return state.set("filter", action.payload);
    case ActionListFiltersActionTypes.CLEAN_FILTER:
      return state.set("filter", Optional.empty());
    default:
      return state;
  }
}
