import { PulsePollListOverviewResource } from "hyphen-lib/dist/domain/resource/PulsePollListOverviewResource";
import { default as networkActionCreators } from "@store/network/actions";
import { createRequest } from "@src/utils/networks";
import { appendQueryString, generateQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { FetchPulsePollInfosParameters } from "./PulsePollInfoResources";

export function fetchPulsePollListOverviewIfNeeded({filter, sort, page}: FetchPulsePollInfosParameters) {
  return networkActionCreators.fetchPageIfNeeded({
    id: PulsePollListOverviewResource.generateKey(filter, sort),
    type: PulsePollListOverviewResource.TYPE,
    page,
    rawPageSize: 10,
    request: pageFilter => createRequest(
      appendQueryString(
        "/pulsePolls",
        generateQueryString({
          filter,
          sort,
          page: pageFilter,
          view: "overview",
        })
      )
    ),
  });
}
