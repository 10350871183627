import React from "react";
import styled, { css } from "styled-components";

import { HighlightedCategory } from "@hyphen-lib/domain/resource/report/common/Highlights";
import Palette from "@src/config/theme/palette";
import CreateActionPlanButton from "@src/screens/Insights/components/Reports/CreateActionPlanButton";
import CompareValue from "@components/core/CompareValue";
import { withRouter, RouteComponentProps } from "react-router";
import { extractComparison } from "hyphen-lib/dist/business/calculation/benchmark/Benchmarks";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { CompareWithOption } from "@screens/Insights/components/ViewOptions/components/CompareWith";
import { Icon } from "antd";
import { Breadcrumb, goTo } from "@src/utils/locations";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { FocusArea, Module } from "hyphen-lib/dist/domain/FocusArea";
import { Trans } from "react-i18next";

interface Props extends RouteComponentProps<{ id: string }> {
  readonly data: HighlightedCategory;
  readonly subtitle?: Optional<string>;
  readonly isComparisonVisible?: boolean;
  readonly compareWithOptions: CompareWithOption[];
  readonly comparisonKey: Optional<string>;
  readonly fromPage: string;
  readonly hasActionCreationRight: boolean;
  readonly showOnHover?: boolean;
  readonly focusAreas?: FocusAreaResource[];
  readonly onCreateFocusArea: (focusArea: Partial<FocusAreaResource>) => void;
  readonly categoryType:
  | FocusArea.Source.SATISFACTION_AREA
  | FocusArea.Source.OPPORTUNITY_AREA
  | FocusArea.Source.BEST_CATEGORIES
  | FocusArea.Source.WORST_CATEGORIES;
  readonly focusAreaLabel: string;
}

interface LocalProps {
  seeDetailsVisible?: boolean;
}

const Category = ({
  data: { category, favorability, compare },
  subtitle,
  isComparisonVisible = true,
  compareWithOptions,
  comparisonKey,
  seeDetailsVisible,
  hasActionCreationRight,
  focusAreaLabel,
  ...props
}: Props & LocalProps) => {
  const getScoreClassName = (score: number): string => {
    if (score < 45) {
      return "negative";
    }
    if (score <= 65) {
      return "neutral";
    }

    return "positive";
  };

  function onCreateActionPlan() {
    const { params } = props.match;
    props.onCreateFocusArea({
      category,
      title: category,
      source: props.categoryType,
      module: Module.DASHBOARD,
      focusAreaType: FocusArea.FocusAreaType.CATEGORY,
      sourceUrl: {
        label: focusAreaLabel,
        url: window.location.href,
      },
      actionPlans: [],
      surveyId: params.id
    });
  }

  const onSeeDetailsClick = () => {
    goTo(`categories/${encodeURI(category)}`, Breadcrumb.stack(props.fromPage));
  };

  const scoreClassName = getScoreClassName(favorability);
  const comparison = extractComparison(compare, comparisonKey);

  // noinspection PointlessBooleanExpressionJS
  return (
    <Container
      subtitleAvailable={isStringAndNotEmpty(subtitle)}
      seeDetailsVisible={seeDetailsVisible === true}
    >
      {hasActionCreationRight && (
        <CreateActionPlanButtonContainer showOnHover={props.showOnHover}>
          <CreateActionPlanButton
            onClick={onCreateActionPlan}
            showOnHover={props.showOnHover}
          />
        </CreateActionPlanButtonContainer>
      )}
      <TextContainer>
        <CategoryText data-cy={`categoryText_${category}`}>
          {category}
        </CategoryText>
        {isStringAndNotEmpty(subtitle) && (
          <SubtitleText>{subtitle}</SubtitleText>
        )}
      </TextContainer>
      <ScoreContainer>
        <Score className={scoreClassName} data-cy={`categoryScore_${category}`}>
          {favorability}%
        </Score>
      </ScoreContainer>
      <CompareAndSeeDetailsContainer>
        {isComparisonVisible && isNotNullNorUndefined(comparison) && (
          <CompareContainer>
            <CompareValueContainer>
              <CompareValue compare={comparison} />
            </CompareValueContainer>
          </CompareContainer>
        )}
        <StyledLink onClick={onSeeDetailsClick}>
          <SeeDetails>
            <Trans>See details</Trans>
            <RightIcon type="right" />
          </SeeDetails>
        </StyledLink>
      </CompareAndSeeDetailsContainer>
    </Container>
  );
};

const CompareAndSeeDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 100px;
  margin-left: 8px;
  align-items: flex-end;
`;

const SeeDetails = styled.span`
  display: none;
  color: ${Palette.darkModerateBlue};
  font-size: 14px;
  white-space: nowrap;
`;

const Score = styled.span`
  font-size: 16px;
  font-weight: bold;

  &.positive {
    color: ${Palette.primary};
  }

  &.neutral {
    color: ${Palette.bluishGrey};
  }

  &.negative {
    color: ${Palette.darkPink};
  }
`;

const ActionPlanIconNotHovered = css`
  display: none;
  position: absolute;
  top: 50%;
  left: 0;
`;

const ActionPlanIconHovered = css`
  padding-right: 24px;
  display: inline-block;
`;

const CreateActionPlanButtonContainer = styled.div<{ showOnHover?: boolean }>`
  ${(props) =>
    props.showOnHover ? ActionPlanIconNotHovered : ActionPlanIconHovered};
`;

const ScoreContainer = styled.div`
  margin-left: 8px;
`;

const CompareContainer = styled.div`
  display: inline-block;
  color: ${Palette.bluishGrey};
  font-size: 12px;
`;

const CompareValueContainer = styled.div`
  span {
    font-size: 14px;
    line-height: 1;
  }
`;

export const SubtitleText = styled.div`
  margin-top: 4px;
  color: ${Palette.bluishGrey};
  font-size: 12px;
  display: none;
`;

const TextContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div<{
  seeDetailsVisible: boolean;
  subtitleAvailable: boolean;
}>`
  position: relative;
  margin: 16px 16px 0 16px;
  padding: 0 16px 0 24px;
  height: 55px;
  font-size: 16px;
  line-height: 16px;
  border: 1px solid ${Palette.lightPeriwinkle};
  border-radius: 3px;
  background: ${Palette.white};
  box-shadow: 0 4px 8px 0 rgba(198, 214, 223, 0.15);
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    cursor: pointer;
    border-color: ${Palette.darkModerateBlue};
  }

  &:hover ${CreateActionPlanButtonContainer} {
    display: block;
  }

  &:hover ${CompareContainer} {
    display: ${(props) => (props.seeDetailsVisible ? "none" : "inline-block")};
  }

  &:hover ${SeeDetails} {
    display: ${(props) => (props.seeDetailsVisible ? "block" : "none")};
  }

  &:hover ${SubtitleText} {
    display: ${(props) => (props.subtitleAvailable ? "block" : "none")};
  }

  &:hover ${CompareAndSeeDetailsContainer} {
    width: 30%;
  }
`;

const RightIcon = styled(Icon)`
  margin-left: 4px;
  font-size: 12px;
`;

const CategoryText = styled.div`
  font-size: 14px;
  flex-grow: 1;
  line-height: 20px;
`;

const StyledLink = styled.span`
  cursor: pointer;
`;

export default withRouter(Category);
