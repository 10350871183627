import React from "react";
import { Trans } from "react-i18next";
import Modal from "@components/core/Modal";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import Button from "@src/components/core/Button";
import { ModalProps } from "antd/lib/modal";
import Tooltip from "@src/components/core/Tooltip";
import { trans } from "@src/utils/i18next";
import { AccessResource } from "hyphen-lib/dist/domain/access/AccessResource";

interface Props {
  readonly visible: boolean;
  readonly isSurvey?: boolean;
  readonly onOkClick: () => void;
  readonly onCancelClick: () => void;
  readonly usersWithoutInsightsAccess: AccessResource[];
}

export function InsightsAccessAlter(this: any, props: Props) {
  const { isSurvey, usersWithoutInsightsAccess } = props;
  const totalusersWithoutInsightsAccess = usersWithoutInsightsAccess.length;
  return (
    <StyledModal
      visible={props.visible}
      title={<Trans>Give access to Engage Insights too ?</Trans>}
      onOk={props.onOkClick}
      onCancel={props.onCancelClick}
      footer={[
        <ActionButtons
          data-cy="wizard_areYouSure_skip"
          color="grey"
          key="forward"
          onClick={props.onCancelClick}
          translate="yes"
        >
          Skip
        </ActionButtons>,
        <ActionButtons
          data-cy="wizard_areYouSure_yes"
          color="blue"
          key="back"
          onClick={props.onOkClick}
          translate="yes"
        >
          Yes
        </ActionButtons>,
      ]}
    >
      <Container translate="yes" >
        <Content>
          <Tooltip
            title={props.usersWithoutInsightsAccess.slice(0, 25)
              .map((user, index) => {
                if (index === 24 && (totalusersWithoutInsightsAccess - 25 > 0)) {
                  return <div key={index}>
                    {`${user.email} + ${totalusersWithoutInsightsAccess - 25} more`}
                  </div>;
                }
                return <div key={index}>{user.email}</div>;
              })}
          >
            {totalusersWithoutInsightsAccess} {trans("users")} </Tooltip>
          {trans("do not yet have access to Engage Insights")}.
        </Content>
        <Content>
          <Trans>Do you want to give them access to Engage Insights
            at the same time you are granting them visibility to this</Trans>
          &nbsp; {trans(isSurvey ? "survey" : "poll")} ? &nbsp;
          <Trans>(note: they will not receive an email)</Trans>
        </Content>
      </Container>

    </StyledModal>
  );
}

const Container = styled.div`
  padding: 16px 32px;
  color: ${Palette.bluishGrey};
`;

const ActionButtons = styled(Button)`
  width: 150px;
`;

const Content = styled.section`
  margin-top: 8px;
  color: ${Palette.darkBlueGrey};
`;

const StyledModal = styled(Modal) <ModalProps & { children: React.ReactNode }>``;
