import React from "react";
import CreationLayout from "@components/layouts/CreationLayout";
import { goTo } from "@src/utils/locations";
import ContainerCard from "@src/components/core/ContainerCard";
import styled from "styled-components";
import { actionCreators as SurveysActionCreator } from "@screens/Insights/Surveys/store/actions";
import Button from "@src/components/core/Button";
import CustomActionModal from "@src/screens/Insights/Settings/components/CustomActionModal";
import { connect } from "react-redux";
import { State } from "@src/store/types";
import { PostCategoryResource } from "hyphen-lib/dist/domain/resource/post/PostCategoryResource";
import {
  ManageTemplates,
  ManageTemplatesReduxActionProps,
  ManageTemplatesReduxStateProps
} from "../components/ManageTemplates";
import { settingsActionCreators } from "../store/actions";
import { getSettingsStateProps } from "../store/selectors";
import { CreateActionTemplatePayload, UpdateActionTemplatePayload } from "../store/actions";
import { getPostCategorys } from "../../Surveys/store/selectors";

interface ManageSuggestionsActionProps {
  fetchActionTemplates: () => void;
  addActionTemplate: (payload: CreateActionTemplatePayload) => void;
  updateActionTemplate: (payload: UpdateActionTemplatePayload, templateId: string) => void;
  fetchPostCategories: () => void;
}
interface ManageSuggestionsReduxStateProps extends ManageTemplatesReduxStateProps {
  readonly categories: PostCategoryResource[];
}

interface ManageSuggestionsProps extends
  ManageSuggestionsActionProps,
  ManageSuggestionsReduxStateProps,
  ManageTemplatesReduxActionProps {}
interface ManageSuggestionsStateProps {
  modalOpen: boolean;
  modalOpenInMode: string;
  action: string;
  category: string;
  description: string;
  length: string;
  difficulty: string;
  source: string;
  resource: string;
  templateId: string;
}

class ManageSuggestionsContainer extends React.Component<ManageSuggestionsProps, ManageSuggestionsStateProps> {
  constructor(props: ManageSuggestionsProps) {
    super(props);
    this.state = {
      modalOpen: false,
      modalOpenInMode: "add",
      action: "",
      category: "Benefits",
      description: "",
      length: "Short",
      difficulty: "Easy",
      source: "Custom",
      resource: "",
      templateId: "",
    };
  }

  componentDidMount() {
    const { fetchActionTemplates, fetchPostCategories, categories } = this.props;
    fetchActionTemplates();
    // make network request to fetch post categories only if not already fetched
    if (categories.length === 0 ) {
      fetchPostCategories();
    }
  }

  onCloseAction = () => {
    goTo("/settings/app");
  };

  openModal = () => {
    this.setState({ modalOpen: true});
  };

  closeModal = () => {
    this.setState({
      modalOpen: false,
      modalOpenInMode: "add",
      action: "",
      category: "Benefits",
      description: "",
      length: "Short",
      difficulty: "Easy",
      source: "Custom",
      resource: "",
      templateId: "",
    });
  };

  createActionTemplate = (payload: any) => {
    this.props.addActionTemplate(payload);
    this.setState({
      modalOpen: false,
      modalOpenInMode: "add",
      action: "",
      category: "Benefits",
      description: "",
      length: "Short",
      difficulty: "Easy",
      source: "Custom",
      resource: "",
      templateId: "",
    });
  };

  updateActionTemplate = (payload: any, templateId: string) => {
    this.props.updateActionTemplate(payload, templateId);
    this.setState({
      modalOpen: false,
      modalOpenInMode: "add",
      action: "",
      category: "Benefits",
      description: "",
      length: "Short",
      difficulty: "Easy",
      source: "Custom",
      resource: "",
      templateId: "",
    });
  };

  onChangeInActionTemplate = (payload: any) => {
    this.setState({
      action: payload.action,
      category: payload.category,
      description: payload.description,
      length: payload.length,
      difficulty: payload.difficulty,
      source: payload.source,
      resource: payload.resource,
    });
  };

  viewActionTemplate = (payload: any) => {
    this.setState({
      modalOpen: true,
      modalOpenInMode: "view",
      action: payload.action,
      category: payload.category,
      description: payload.description,
      length: payload.length,
      difficulty: payload.difficulty,
      source: payload.source,
      resource: payload.resource,
    });
  };

  editActionTemplate = (payload: any) => {
    this.setState({
      modalOpen: true,
      modalOpenInMode: "edit",
      action: payload.action,
      category: payload.category,
      description: payload.description,
      length: payload.length,
      difficulty: payload.difficulty,
      source: payload.source,
      resource: payload.resource,
      templateId: payload._id,
    });
  };

  render() {
    const {
      actionTemplates,
      categories,
      isNetworkRequesting,
      excludeActionTemplate,
      includeActionTemplate,
      removeActionTemplate,
    } = this.props;
    return (
      <CreationLayout title="Manage Action Plan Suggestions" onCloseIconClick={this.onCloseAction}>
        <Container>
          <ActionContainer>
            <Button onClick={this.openModal} color="blue" translate="yes">Create Custom Suggestion</Button>
            <CustomActionModal
              onSubmit={this.createActionTemplate}
              onUpdate={this.updateActionTemplate}
              visible={this.state.modalOpen}
              onCancel={this.closeModal}
              onChange={this.onChangeInActionTemplate}
              modalOpenInMode={this.state.modalOpenInMode}
              action={this.state.action}
              category={this.state.category}
              description={this.state.description}
              length={this.state.length}
              difficulty={this.state.difficulty}
              source={this.state.source}
              resource={this.state.resource}
              templateId={this.state.templateId}
              categories={categories}
            />
          </ActionContainer>
          <ManageTemplates
            onViewClick={this.viewActionTemplate}
            onEditClick={this.editActionTemplate}
            {
              ...{
                actionTemplates,
                isNetworkRequesting,
                excludeActionTemplate,
                includeActionTemplate,
                removeActionTemplate,
              }
            }
          />
        </Container>
      </CreationLayout>
    );
  }
}

const Container = styled(ContainerCard)`
  width: 100%;
  padding: 32px 0 !important;
  > div:first-child {
    p {
      padding: 0 32px;
    }
  }
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  margin-bottom: 20px;
`;

const mapStateToProps = (state: State): ManageSuggestionsReduxStateProps => {
  const { isNetworkRequesting, actionTemplates } = getSettingsStateProps(state);
  return {
    isNetworkRequesting,
    actionTemplates,
    categories: getPostCategorys(state).toArray(),
  };
};

const mapDispatchToProps = {
  addActionTemplate: settingsActionCreators.addActionTemplate,
  updateActionTemplate: settingsActionCreators.updateActionTemplate,
  fetchActionTemplates: settingsActionCreators.fetchActionTemplates,
  fetchPostCategories: SurveysActionCreator.fetchPostCategorys,
  excludeActionTemplate: settingsActionCreators.excludeActionTemplate,
  includeActionTemplate: settingsActionCreators.includeActionTemplate,
  removeActionTemplate: settingsActionCreators.removeActionTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageSuggestionsContainer);
