import React from "react";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps } from "react-router";
import { State } from "@store/types";

import Comments, {
  PollCommentsReportStateProps,
} from "@screens/Insights/PulsePoll/components/Comments";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { Map as ImmutableMap } from "immutable";
import { getParameters } from "@screens/Insights/parameters/store/selectors";
import { applyExistingParametersIfNeeded } from "@src/utils/parameters";
import { goTo, replaceLocation } from "@src/utils/locations";
import {
  getOr,
  isNotEmptyObject,
  isNotNullNorUndefined,
  isNullOrUndefined,
  mapOr,
} from "hyphen-lib/dist/lang/Objects";
import {
  checkForActionPlanCreationRight,
  checkForConversationRight,
} from "@src/utils/rights";
import { getAnonymityThreshold } from "@screens/Insights/Settings/store/selectors";
import { CommentsResultResource } from "hyphen-lib/dist/domain/resource/report/CommentsResultResource";
import { CommentsOverviewResource } from "hyphen-lib/dist/domain/resource/report/CommentsOverviewResource";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { getExistingPage, getResourceById } from "@store/network/selectors";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { fetchPollCommentsOverviewIfNeeded } from "@src/store/network/resource/CommentsOverviewResources";
import { fetchPollCommentResultsIfNeeded } from "@src/store/network/resource/CommentsResultResources";
import { PulsePollInfoResource } from "hyphen-lib/dist/domain/resource/PulsePollInfoResource";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { FetchError } from "@src/screens/Insights/errors/FetchError";
import { PULSE_POLL_COMMENTS_REPORTS_FILTER_MAPPINGS } from "../../components/ReportHeader";
import { pollReportActionCreators } from "../../store/actions";
import { getPulsePollCommentsReportState } from "./store/selectors";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";
import {
  getCurrentUser,
  getRightsMatcher,
} from "@src/screens/Insights/store/selectors";
import { fetchFocusAreasIfNeeded } from "@src/store/network/resource/ActionResources";
import {
  focusAreaListActionCreators,
  FocusAreaListPageParameters,
} from "@src/screens/Insights/Actions/store/actions";
import {
  getAllFocusAreasFromReduxStore,
  getCurrentBottomDrawerView,
  isFARequestComplete,
} from "@src/utils/FocusArea";
import { isEmpty } from "hyphen-lib/dist/lang/Arrays";
import BottomDrawer, { DrawerViews } from "@src/components/core/BottomDrawer";
import BottomDrawerStripedChild, {
  transformFocusAreasToStripStack,
} from "@src/components/core/BottomDrawer/StripedChild";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { BottomDrawerMessages } from "@src/screens/Insights/BottomDrawer/constant";
import { hasFocusAreaRights } from "hyphen-lib/dist/business/focusArea/focusAreaResource";
import Spin from "@src/components/core/Spin";
import { parseNumber } from "hyphen-lib/dist/lang/Number";

interface MatchParams {
  templateId: string;
  instanceId: string;
  selectedDimension: string;
}

export interface OwnProps extends RouteComponentProps<MatchParams> {}

export interface CommentsContainerStateProps {
  readonly instanceId: string;
  readonly parameters: ImmutableMap<string, any>;
  readonly canSendPrivateMessage: boolean;
  readonly anonymityThreshold: number;
  readonly comments: Store.Page<CommentsResultResource>;
  readonly loading: boolean;
  readonly commentsOverview: Store.Element<CommentsOverviewResource>;
  readonly focusAreas?: FocusAreaResource[];
  readonly currentUser: Optional<CurrentUserResource>;
  readonly currentBottomDrawerView: DrawerViews;
  readonly pulsePollInfo: Loadable<PulsePollInfoResource>;
  readonly hasFaRights: boolean;
  readonly completedFARequest: boolean;
  readonly page: PageFilter;
}

export type CommentsContainerActionProps = typeof pollReportActionCreators & {
  readonly onFetchPollComments: (
    pollId: string,
    questionId: Optional<string>,
    queryString: Dictionary<any>
  ) => void;
  readonly onFetchPollCommentsOverview: (
    pollId: string,
    questionId: Optional<string>,
    queryString: Dictionary<any>
  ) => void;
  readonly onFetchFocusArea: (parameters: FocusAreaListPageParameters) => any;
  readonly onCreateFocusArea: (focusArea: Partial<FocusAreaResource>) => void;
};

interface PollCommentsStateProps extends PollCommentsReportStateProps {
  origin: string;
}

type Props = OwnProps &
  CommentsContainerStateProps &
  CommentsContainerActionProps &
  PollCommentsStateProps; // fixme extract a common type if needed, but not the type from another component!

interface CommentsContainerState {
  totalFocusAreas: number;
}

class CommentsContainer extends React.Component<Props, CommentsContainerState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      totalFocusAreas: 0,
    };
  }

  componentDidMount(): void {
    // we might need to apply persisted parameters
    const {
      parameters,
      location: { search },
    } = this.props;

    const existing = parseQueryString(search);
    const mergedParameters = applyExistingParametersIfNeeded(
      parameters.toJS(),
      existing,
      ...PULSE_POLL_COMMENTS_REPORTS_FILTER_MAPPINGS
    );

    if (
      isNotNullNorUndefined(mergedParameters) &&
      isNotEmptyObject(mergedParameters)
    ) {
      replaceLocation(mergedParameters);
    } else {
      // fetch data only if we will stay on this page,
      // otherwise it will be fetched anyway in componentDidUpdate
      this.fetchDataFromBackend();
    }

    if (
      (isEmpty(getOr(this.props.focusAreas, [])) ||
        isNullOrUndefined(this.props.focusAreas)) &&
      this.props.hasFaRights
    ) {
      this.props.onFetchFocusArea(this.fetchFocusAreaProps());
    }

    let totalFocusAreas = 0;
    if (isNotNullNorUndefined(this.props.focusAreas)) {
      totalFocusAreas = this.props.focusAreas.length;
    }

    this.setState({
      totalFocusAreas,
    });
  }

  fetchFocusAreaProps(): FocusAreaListPageParameters {
    return {
      page: {
        number: 1,
        size: 30,
      },
      filter: {
        withActionPlans: false,
      },
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { instanceId, location, loading, comments, commentsOverview } =
      this.props;
    const isInError =
      mapOr(comments, (p) => Store.Page.isInError(p), false) ||
      mapOr(commentsOverview, (co) => Store.Element.isInError(co), false);
    const { search, pathname } = location;
    if (
      (instanceId !== prevProps.instanceId ||
        search !== prevProps.location.search ||
        pathname !== prevProps.location.pathname ||
        loading) &&
      not(isInError)
    ) {
      this.fetchDataFromBackend();
    }

    if (this.props.hasFaRights) {
      this.props.onFetchFocusArea(this.fetchFocusAreaProps());
    }

    if (
      isNotNullNorUndefined(this.props.focusAreas) &&
      isNotNullNorUndefined(prevProps.focusAreas)
    ) {
      if (this.props.focusAreas.length > prevProps.focusAreas.length) {
        this.setState({
          totalFocusAreas: this.props.focusAreas.length,
        });
      }
    }
  }

  fetchDataFromBackend = () => {
    const {
      location,
      onFetchPollComments,
      onFetchPollCommentsOverview,
      instanceId,
    } = this.props;
    const queryParams = parseQueryString(location.search);

    if (isNotNullNorUndefined(instanceId)) {
      onFetchPollComments(instanceId, Optional.empty(), queryParams);
      onFetchPollCommentsOverview(instanceId, Optional.empty(), queryParams);
    }
  };

  render() {
    const {
      comments,
      commentsOverview,
      commentText,
      errorText,
      isNetworkRequesting,
      isPrivateMessageModalVisible,
      privateMessageText,
      instanceId,
      voteId,
      canSendPrivateMessage,
      hasActionCreationRight,
      anonymityThreshold,
      clearPrivateMessageModal,
      sendPrivateMessage,
      togglePrivateMessageModal,
      updatePrivateMessageText,
      focusAreas,
      onCreateFocusArea,
      hasFaRights,
      completedFARequest,
      page
    } = this.props;

    if (Store.Page.isInError(comments)) {
      return (
        <FetchError {...comments} resourceType={CommentsResultResource.TYPE} />
      );
    }
    if (Store.Element.isInError(commentsOverview)) {
      return (
        <FetchError
          {...commentsOverview}
          resourceType={CommentsOverviewResource.TYPE}
        />
      );
    }

    return (
      <>
        <Spin
          size="large"
          className="sticky-top"
          spinning={!completedFARequest}
        >
          <Comments
            isNotFound={Store.Element.isNotFound(commentsOverview)}
            commentsOverview={Store.Element.toLoadable(commentsOverview)}
            comments={comments}
            origin={this.props.origin}
            clearPrivateMessageModal={clearPrivateMessageModal}
            sendPrivateMessage={sendPrivateMessage}
            togglePrivateMessageModal={togglePrivateMessageModal}
            updatePrivateMessageText={updatePrivateMessageText}
            commentText={commentText}
            errorText={errorText}
            isNetworkRequesting={isNetworkRequesting}
            isPrivateMessageModalVisible={isPrivateMessageModalVisible}
            instanceId={instanceId}
            privateMessageText={privateMessageText}
            voteId={voteId}
            canSendPrivateMessage={canSendPrivateMessage}
            hasActionCreationRight={hasActionCreationRight}
            anonymityThreshold={anonymityThreshold}
            fetchDataFromBackend={this.fetchDataFromBackend}
            onCreateFocusArea={onCreateFocusArea}
            focusAreas={focusAreas}
            pollName={""}
            page={page}
          />
          {hasFaRights && (
            <BottomDrawer
              key="segment-drawer"
              initialView={this.props.currentBottomDrawerView}
              title={`${
                this.state.totalFocusAreas === 0
                  ? "No"
                  : this.state.totalFocusAreas
              } Focus Area Added`}
              footerButton={{
                display: true,
                text: "Go To Action Center",
                onClick: () => goTo("/actioncenter"),
              }}
            >
              <BottomDrawerStripedChild
                stripStack={transformFocusAreasToStripStack(focusAreas)}
                emptyStackMessage={BottomDrawerMessages.EMPTY_STACK_MESSAGE}
              />
            </BottomDrawer>
          )}
        </Spin>
      </>
    );
  }
}

export const mapStateToProps: MapStateToProps<
  CommentsContainerStateProps,
  OwnProps,
  State
> = (
  state: State,
  ownProps: OwnProps
): CommentsContainerStateProps & PollCommentsStateProps => {
  const currentUser = getCurrentUser(state);
  const queryParameters = parseQueryString(ownProps.location.search);
  const page = {
    number: mapOr(queryParameters.page, parseNumber, 1),
    size: 30
  };
  const {
    match: {
      params: { instanceId, templateId },
    },
  } = ownProps;
  const pulsePollInfoElement = getResourceById(
    state,
    PulsePollInfoResource.TYPE,
    templateId
  );

  const filter = queryParameters.filter;
  const allowPrivateMessages = isNotNullNorUndefined(currentUser) &&
  isNotNullNorUndefined(currentUser?.company) &&
  currentUser.company.allowPrivateMessages;

  const canSendPrivateMessage = Store.Element.mapIfLoadedOr(
    getResourceById(state, PulsePollInfoResource.TYPE, templateId),
    (ppi) => checkForConversationRight(ppi.role!, allowPrivateMessages),
    false
  );

  const {
    isPrivateMessageModalVisible,
    isSendMessageRequesting,
    privateMessageText,
    voteId,
    origin,
    commentText,
    errorText,
  } = getPulsePollCommentsReportState(state);
  const comments = getExistingPage(
    state,
    CommentsResultResource.TYPE,
    CommentsResultResource.generateKey({ pollId: instanceId }, filter),
    PageFilter.noPagination()
  );

  const data = getAllFocusAreasFromReduxStore(state);
  const currentBottomDrawerView = getCurrentBottomDrawerView(state);
  const hasFaRights = hasFocusAreaRights(getRightsMatcher(state));

  return {
    comments,
    loading: Store.Page.isNotFound(comments),
    commentsOverview: getResourceById(
      state,
      CommentsOverviewResource.TYPE,
      CommentsOverviewResource.generateId({ pollId: instanceId }, filter)
    ),
    commentText,
    errorText,
    isNetworkRequesting: isSendMessageRequesting,
    isPrivateMessageModalVisible,
    privateMessageText,
    voteId,
    origin,
    parameters: getParameters(state),
    canSendPrivateMessage,
    hasActionCreationRight: checkForActionPlanCreationRight(state),
    anonymityThreshold: getAnonymityThreshold(state),
    instanceId,
    currentUser,
    focusAreas: data,
    currentBottomDrawerView,
    pulsePollInfo: Store.Element.toLoadable(pulsePollInfoElement),
    hasFaRights,
    completedFARequest: isFARequestComplete(state),
    page
  };
};

const mapDispatchToProps: CommentsContainerActionProps = {
  onFetchPollComments: fetchPollCommentResultsIfNeeded,
  onFetchPollCommentsOverview: fetchPollCommentsOverviewIfNeeded,
  onFetchFocusArea: fetchFocusAreasIfNeeded,
  onCreateFocusArea: focusAreaListActionCreators.createFocusArea,
  ...pollReportActionCreators,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentsContainer);
export { CommentsContainer as PureCommentsContainer };
