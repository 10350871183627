import React from "react";
import { Trans } from "react-i18next";
import {
  isNullOrUndefined,
  isNotNullNorUndefined
} from "@hyphen-lib/lang/Objects";
import { Optional } from "@hyphen-lib/lang/Optionals";
import { ArrowNext } from "@components/core/svg";
import Palette from "@src/config/theme/palette";
import styled from "styled-components";

type IconType = "arrow" | "signs";

interface Props {
  compare: Optional<number>;
  iconType?: IconType;
  compareWith?: string;
  percentage?: boolean;
}

const CompareValue = ({ compare, iconType = "arrow", compareWith, percentage = true }: Props) => {
  if (isNullOrUndefined(compare)) {
    return null;
  }

  let arrowIcon = null;
  let compareClassName = "";
  if (compare < 0) {
    compareClassName = "negative";
    arrowIcon =
      iconType === "arrow" ? (
        <ArrowNext fill={Palette.darkPink} rotationAngle="45deg" />
      ) : (
        <Sign className={compareClassName}>-</Sign>
      );
  } else if (compare > 0) {
    compareClassName = "positive";
    arrowIcon =
      iconType === "arrow" ? (
        <ArrowNext fill={Palette.aquaBlue} rotationAngle="-45deg" />
      ) : (
        <Sign className={compareClassName}>+</Sign>
      );
  }
  let compareValue: string | number = Math.abs(compare);

  compareValue = percentage ? compareValue + "%" : compareValue;

  return (
    <span>
      {arrowIcon}
      <CompareText className={compareClassName}>{compareValue}</CompareText>
      {isNotNullNorUndefined(compareWith) && (
        <CompareWith> <Trans>vs {compareWith}</Trans></CompareWith>
      )}
    </span>
  );
};

export const CompareText = styled.span`
  font-family: Lato, sans-serif;
  color: ${Palette.lightGreyBlue};

  &.positive {
    color: ${Palette.aquaBlue};
  }

  &.negative {
    color: ${Palette.darkPink};
  }
`;

export const Sign = styled.span`
  &.positive {
    color: ${Palette.aquaBlue};
  }

  &.negative {
    color: ${Palette.darkPink};
  }
`;

const CompareWith = styled.span`
  color: ${Palette.bluishGrey};
  font-size: 12px;
`;

export default CompareValue;
