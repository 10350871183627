import React from "react";
import { SurveyQuestionResource } from "hyphen-lib/dist/domain/resource/SurveyQuestionResource";
import styled from "styled-components";
import { Form } from "antd";
import { PostCategoryResource } from "hyphen-lib/dist/domain/resource/post/PostCategoryResource";
import { QUESTION_ORIGIN } from "@src/screens/Insights/Surveys/containers/SurveyQuestions";
import {
  getOr,
  cleanObject,
  isNotNullNorUndefined,
} from "hyphen-lib/dist/lang/Objects";
import {
  QuestionType,
  QuestionConfig,
} from "hyphen-lib/dist/domain/common/QuestionType";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import { GlobalErrors } from "@screens/Insights/components/Wizard/layout/GlobalErrors";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { toHumanReadableErrorMessage } from "@src/utils/formValidations";
import Button from "@src/components/core/Button";
import { IsMultiSelectContext } from "@src/screens/Insights/Surveys/containers/SurveyEdit";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { Survey } from "hyphen-lib/dist/domain/Survey";
import {
  checkSurveyQuestionResourceConsistency,
  CheckSurveyQuestionResourceConsistencyOptions,
} from "hyphen-lib/dist/business/survey/SurveyQuestionResources";
import {
  AddOrEditQuestion,
  QuestionOnChangePayload,
} from "./AddOrEditQuestion";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import { WithTranslation, withTranslation } from "react-i18next";

export interface AddOrEditQuestionModalProps extends WithTranslation{
  readonly buttonDisabled: boolean;
  readonly surveyId: string;
  readonly questionSource?: SurveyQuestionResource;
  readonly questionOrigin: QUESTION_ORIGIN;
  readonly addUpdateQuestion: (
    questionId: string,
    question: SurveyQuestionResource,
    queryParams?: Record<string, string>,
    keepModalOpen?: boolean
  ) => void;
  readonly onlyUpdateQuestion?: (
    questionId: string,
    question: SurveyQuestionResource,
    queryParams?: Record<string, string>,
    keepModalOpen?: boolean
  ) => void;
  readonly onAddCustomCategory: (category: any) => void;
  readonly addQuestionErrors: (errorPayload: {
    error: WrongEntityException[];
  }) => void;
  readonly postCategories: PostCategoryResource[];
  readonly errors: WrongEntityException.Errors;
  readonly surveyStatus: Survey.Status;
  readonly questionConfig: QuestionConfig;
  readonly configuredSurveyLanguages: string[];
  readonly translatedSurveyLanguages: string[];
  readonly isMultiLanguageSurveyEnabled: boolean;
  readonly isUpdatingQuestion: boolean;
  readonly updatedQuestion?: SurveyQuestionResource;
}

interface AddOrEditQuestionModalState {
  readonly question: SurveyQuestionResource;
}

class AddOrEditQuestionModalComponent extends React.Component<
  AddOrEditQuestionModalProps,
  AddOrEditQuestionModalState
> {
  defaultQuestion: SurveyQuestionResource = {
    _id: "",
    _type: "SurveyQuestion",
    question: "",
    question_t: "",
    isRatingQuestion: true,
    choices: QuestionConfig.getCompanyLikertOptions(
      this.props.questionConfig.likert
    ),
    category: this.props.postCategories.length
      ? this.props.postCategories[0].label
      : "",
    type: QuestionType.MULTIPLE_CHOICE,
    surveyId: this.props.surveyId,
    allowComment: false,
    isNPSQuestion: false,
    isSentimentAnalysisQuestion: false,
    isOutcomeQuestion: false,
  };
  state = {
    question: this.props.questionSource || this.defaultQuestion,
  };

  componentDidUpdate(prevProps: AddOrEditQuestionModalProps) {
    if (
      isNotNullNorUndefined(this.props.questionSource) &&
      prevProps.questionSource !== this.props.questionSource
    ) {
      this.setState({
        question: this.props.questionSource,
      });
    } else if (
      isNotNullNorUndefined(this.props.updatedQuestion) &&
      prevProps.updatedQuestion !== this.props.updatedQuestion
    ) {
      this.setState({
        question: this.props.updatedQuestion,
      });
    }
  }

  onChange = (payload: QuestionOnChangePayload) => {
    const { addQuestionErrors, questionConfig } = this.props;

    const newPayload = Object.assign({}, payload, {
      ...cleanObject({
        type: payload.questionType,
      }),
      questionType: undefined,
    });

    const question: SurveyQuestionResource = Object.assign(
      {},
      this.state.question,
      newPayload
    );

    let checkConsistencyOptions: Partial<CheckSurveyQuestionResourceConsistencyOptions> =
      { failFast: true };

    if (
      question.type === QuestionType.MULTIPLE_CHOICE &&
      question.isMultiSelect
    ) {
      checkConsistencyOptions = Object.assign({}, checkConsistencyOptions, {
        upperBoundMaxNumberOfOptions: question.choices.length,
      });
    }

    try {
      checkSurveyQuestionResourceConsistency(
        question,
        question.surveyId,
        questionConfig,
        checkConsistencyOptions
      );
      addQuestionErrors({
        error: [],
      });
    } catch (e) {
      addQuestionErrors({
        // @ts-ignore
        error: e.errors(),
      });
    }
    this.setState({
      question,
    });
  };

  saveQuestion = () => {
    const { question } = this.state;
    if (
      isStringAndNotEmpty(question._id) &&
      isNotNullNorUndefined(this.props.onlyUpdateQuestion)
    ) {
      /**
       * When the question has already been added and being operated in the save mode, 
       * the following statement only fires the save request.
       * 
       * This could happen when
       *  - a translation was saved for a new question and the user wants to continue editing 
       * the other fields and then clicks the "save changes" button of the modal
       */
      this.props.onlyUpdateQuestion(question._id, question);
    } else {
      this.props.addUpdateQuestion(question._id, question);
    }
  };

  getPropsBasedOnQuestionType = () => {
    if (
      this.state.question.type === QuestionType.LIKERT ||
      this.state.question.type === QuestionType.MULTIPLE_CHOICE
    ) {
      const {
        question: {
          isRatingQuestion,
          isOutcomeQuestion,
          choices,
          allowComment,
          isNPSQuestion,
        },
      } = this.state;
      const multiChoiceQuestionProps: Dictionary<any> = {
        isRatingQuestion,
        isOutcomeQuestion,
        choices,
        allowComment,
        isNPSQuestion,
      };
      if (this.state.question.isMultiSelect) {
        multiChoiceQuestionProps.isMultiSelect =
          this.state.question.isMultiSelect;
        multiChoiceQuestionProps.maxNumberOfOptions =
          this.state.question.maxNumberOfOptions;
      }
      return multiChoiceQuestionProps;
    }
  };

  render() {
    const {
      buttonDisabled,
      questionOrigin,
      postCategories,
      errors,
      onAddCustomCategory,
      surveyStatus,
      questionConfig,
      t
    } = this.props;
    const { question } = this.state;
    let title: string;
    if (questionOrigin === QUESTION_ORIGIN.bankQuestion) {
      title = "Bank questions cannot be changed";
    } else if (questionOrigin === QUESTION_ORIGIN.template) {
      title = "Questions cannot be changed from Survey Templates";
    }

    const isReadonly = questionOrigin !== QUESTION_ORIGIN.custom;
    const isLaunchedOrClosed =
      surveyStatus === Survey.Statuses.CLOSED ||
      surveyStatus === Survey.Statuses.LAUNCHED;
    const mappedErrors = errors.fields.map(
      (val, fieldKey) => toHumanReadableErrorMessage(this.props.t, val, fieldKey)!
    );
    // make categories to be case insensitive
    const allCategories = [...new Map(postCategories.map(item => [item._id.toLowerCase(), item])).values()];
    return (
      <FormContainer>
        {isNotEmptyArray(errors.global) && (
          <GlobalErrors errors={errors.global} />
        )}
        <IsMultiSelectContext.Consumer>
          {(isMultiSelectQuestionEnabled) => (
            <AddOrEditQuestion
              title={title}
              hasCategory={true}
              category={question.category}
              question={question.question}
              question_t={question.question_t}
              questionOrigin={questionOrigin}
              questionType={question.type}
              questionConfig={questionConfig}
              disabled={{
                question: isReadonly,
                category: isReadonly,
                questionType: isReadonly || isLaunchedOrClosed,
                isOutcomeQuestion: isReadonly,
                isRatingQuestion: isReadonly,
                isNPSQuestion: isReadonly,
                maxNumberOfOptions: isReadonly || isLaunchedOrClosed,
                allowUpdatingTranslation: !isLaunchedOrClosed,
              }}
              onChange={this.onChange}
              postCategories={allCategories}
              isSentimentAnalysisQuestion={getOr(
                question.isSentimentAnalysisQuestion,
                false
              )}
              initialErrors={mappedErrors}
              onAddCustomCategory={onAddCustomCategory}
              isMultiSelectQuestionEnabled={isMultiSelectQuestionEnabled}
              configuredSurveyLanguages={this.props.configuredSurveyLanguages}
              translatedSurveyLanguages={this.props.translatedSurveyLanguages}
              questionResource={this.state.question}
              addUpdateQuestion={this.props.addUpdateQuestion}
              onlyUpdateQuestion={this.props.onlyUpdateQuestion}
              enableTranslation={true}
              isMultiLanguageSurveyEnabled={
                this.props.isMultiLanguageSurveyEnabled
              }
              isUpdatingQuestion={this.props.isUpdatingQuestion}
              {...this.getPropsBasedOnQuestionType()}
              t={t}
            />
          )}
        </IsMultiSelectContext.Consumer>
        <Actions>
          <SaveButton
            color="blue"
            disabled={buttonDisabled}
            htmlType="submit"
            data-cy="edit_question_button"
            onClick={this.saveQuestion}
            translate="yes"
          >
            {buttonDisabled ? "Saving" : "Save changes"}
          </SaveButton>
        </Actions>
      </FormContainer>
    );
  }
}

export const AddOrEditQuestionModal = withTranslation()(AddOrEditQuestionModalComponent);

const FormContainer = styled(Form)`
  padding: 0 32px 32px 32px !important;
  display: flex;
  flex-direction: column;
`;

const SaveButton = styled(Button)`
  max-width: 300px;
  margin-top: 28px;
  flex-shrink: 1;
  justify-self: flex-end;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
