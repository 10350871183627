import { default as networkActionCreators } from "@store/network/actions";
import { SurveyInfoResource } from "hyphen-lib/dist/domain/resource/SurveyInfoResource";
import { createRequest, FilterParameter, SortParameter } from "@src/utils/networks";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { appendQueryString, generateQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { SurveyDimensionsResource } from "hyphen-lib/dist/domain/resource/survey/report/SurveyDimensionsResource";

export function fetchSurveyInfoIfNeeded(surveyId: string) {
  return networkActionCreators.fetchElementIfNeeded({
    id: surveyId,
    type: SurveyInfoResource.TYPE,
    request: createRequest("/surveyInfos/" + surveyId),
  });
}

export interface FetchSurveyInfosParameters {
  readonly filter?: FilterParameter;
  readonly sort?: SortParameter;
  readonly page: PageFilter;
}

export function fetchSurveyInfosIfNeeded({filter, sort, page}: FetchSurveyInfosParameters) {
  return networkActionCreators.fetchPageIfNeeded({
    id: SurveyInfoResource.generateKey(filter, sort),
    type: SurveyInfoResource.TYPE,
    page,
    rawPageSize: 10,
    request: pageFilter => createRequest(
      appendQueryString(
        "/surveyInfos",
        generateQueryString({
          filter,
          sort,
          page: pageFilter,
        })
      )
    ),
  });
}

export function fetchSurveyInfosCountIfNeeded(filter: FilterParameter) {
  return networkActionCreators.fetchCountIfNeeded({
    id: SurveyInfoResource.generateKey(filter),
    type: SurveyInfoResource.TYPE,
    request: createRequest(
      appendQueryString(
        "/surveyInfos/count",
        generateQueryString({ filter })
      )
    ),
  });
}

export function fetchSurveyDimensionsInfoIfNeeded(surveyId: string) {
  return networkActionCreators.fetchElementIfNeeded({
    id: SurveyDimensionsResource.generateId(surveyId),
    type: SurveyDimensionsResource.TYPE,
    request: createRequest("/surveyInfos/dimensions/" + surveyId),
  });
}