import { call, cancelled, put, select, takeEvery, takeLatest } from "@redux-saga/core/effects";
import { SagaIterator } from "redux-saga";


import { freeze, isNotNullNorUndefined, getOr } from "hyphen-lib/dist/lang/Objects";
import { loadSessionToken } from "@src/utils/sessionStores";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { DelayedResponse } from "hyphen-lib/dist/util/net/DelayedResponse";
import { getExistingCount, getExistingPage, getResourceById, getUntypedElementById } from "@store/network/selectors";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { getRawPageIndexes } from "hyphen-lib/dist/util/store/DefaultStore";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import notificationsActionCreators from "@store/notifications/actions";
import * as NotificationFactory from "@store/notifications/notification-factory";
import {
  actionCreators,
  ActionTypes,
  FetchCountIfNeededAction,
  FetchElementIfNeededAction,
  FetchPageIfNeededAction,
  FetchUntypedAction,
  FetchUntypedIfNeededAction,
  NetworkErrorAction, NetworkEventErrorAction,
  NetworkRequestAction,
  NetworkSuccessAction,
  RequestInfoType,
  UNTYPED_TYPE
} from "./actions";

export function* createNetworkRequest({ payload, meta }: NetworkRequestAction): SagaIterator {
  try {
    if (meta && meta.onRequestActions) {
      for (const action of meta.onRequestActions) {
        yield put(action(payload));
      }
    }

    const sessionToken = loadSessionToken();
    if (isNotNullNorUndefined(sessionToken)) {
      payload.headers.set("x-session-token", sessionToken);
    }
    // payload.headers.set("Content-Type", "application/json");
    const response: Response = yield call(fetch, payload);
    // FIXME: find a better way to do this
    let data;
    try {
      data = response.statusText !== "No Content" ? yield call([response, "json"]) : {};
    } catch (err) {
      // tslint:disable:no-console
      console.error("Failed to parse response json");
      data = {};
    }

    let extractedResponse: ExtractedResponse;
    if (DelayedResponse.isInstance(data)) {
      const delayedResponseData: any = DelayedResponse.isFulfilled(data) ? data.body : data.error;
      extractedResponse = {
        ok: DelayedResponse.isFulfilled(data), // can not extract the variable, otherwise typescript is failing :/
        status: data.statusCode,
        body: delayedResponseData,
      };
    } else {
      extractedResponse = {
        ok: response.ok,
        status: response.status,
        body: data,
      };
    }

    if (extractedResponse.ok) {
      yield put<NetworkSuccessAction>(actionCreators.networkSuccess(extractedResponse.body, meta));

      if (meta && meta.onSuccessActions) {
        for (const action of meta.onSuccessActions) {
          yield put(action({ data: extractedResponse.body, status: extractedResponse.status }));
        }
      }

      if (meta && meta.onSuccessRedirect) {
        yield call(meta.onSuccessRedirect, { data: extractedResponse.body, status: extractedResponse.status });
      }
    } else {
      yield put<NetworkErrorAction>(
        actionCreators.networkError({ error: extractedResponse.body, status: extractedResponse.status }, meta)
      );

      if (meta && meta.onErrorActions) {
        for (const action of meta.onErrorActions) {
          yield put(action({ error: extractedResponse.body, status: extractedResponse.status }));
        }
      }

      if (meta && meta.onErrorRedirect) {
        yield call(meta.onErrorRedirect, { error: extractedResponse.body, status: extractedResponse.status });
      }
    }
  } catch (error) {
    if (yield(cancelled())) {
      // tslint:disable:no-console
      console.error("cancelled");
    }

    yield put<NetworkErrorAction>({ type: ActionTypes.NETWORK_ERROR, payload: error, meta });

    if (meta && meta.onErrorActions) {
      for (const action of meta.onErrorActions) {
        yield put(action({ error, status: 0 }));
      }
    }
  }
}

export function* fetchElementIfNeeded({
  payload: {
    id,
    type,
    request,
    callbackActions,
  } }: FetchElementIfNeededAction): SagaIterator {
  // try to get element
  const element = yield select(getResourceById, type, id);

  // if not found execute the request
  if (Store.Element.isNotFound(element)) {

    yield put<NetworkRequestAction>(
      actionCreators.networkRequest(
        request,
        {
          info: {
            infoType: RequestInfoType.ELEMENT,
            key: id,
            type,
          },
          ...callbackActions,
        }
      )
    );
  }
}

export function* fetchPageIfNeeded(
  {payload: {id, type, page, rawPageSize, request }}: FetchPageIfNeededAction
): SagaIterator {
  // try to get page from the network store
  const existingPage = yield select(getExistingPage, type, id, page);

  // if not found => do a fetch request for the corresponding raw page
  if (Store.Page.isNotFound(existingPage)) {
    const { rawPageIndex } = getRawPageIndexes(rawPageSize, page);

    const rawPageFilter = { number: rawPageIndex, size: rawPageSize };
    yield put<NetworkRequestAction>(
      actionCreators.networkRequest(
        request(PageFilter.filterNoPagination(rawPageFilter)),
        {
          info: {
            infoType: RequestInfoType.PAGE,
            key: id,
            type,
            page: rawPageFilter,
          },
        }
      )
    );
  }
}


export function* fetchPage(
  {payload: {id, type, page, rawPageSize, request }}: FetchPageIfNeededAction
): SagaIterator {

    const { rawPageIndex } = getRawPageIndexes(rawPageSize, page);

    const rawPageFilter = { number: rawPageIndex, size: rawPageSize };
    
    yield put<NetworkRequestAction>(
      actionCreators.networkRequest(
        request(PageFilter.filterNoPagination(rawPageFilter)),
        {
          info: {
            infoType: RequestInfoType.PAGE,
            key: id,
            type,
            page: rawPageFilter,
          },
        }
      )
    );
}

export function* fetchCountIfNeeded({ payload: { id, type, request } }: FetchCountIfNeededAction) {
  // try to get count from the network store
  const existingCount = yield select(getExistingCount, type, id);

  // if not found => do a fetch request for the corresponding count
  if (Store.Count.isNotFound(existingCount)) {
    yield put<NetworkRequestAction>(
      actionCreators.networkRequest(
        request,
        {
          info: {
            infoType: RequestInfoType.COUNT,
            key: id,
            type,
          },
        }
      )
    );
  }
}

export function* fetchUntypedIfNeeded({
  payload: {
    id,
    request,
  } }: FetchUntypedIfNeededAction): SagaIterator {
  // try to get element
  const untypedElement = yield select(getUntypedElementById, id);

  // if not found execute the request
  if (Store.Element.isNotFound(untypedElement)) {
    yield put<NetworkRequestAction>(
      actionCreators.networkRequest(
        request,
        {
          info: {
            infoType: RequestInfoType.UNTYPED,
            key: id,
            type: UNTYPED_TYPE,
          },
        }
      )
    );
  }
}


export function* fetchUntyped({
  payload: {
    id,
    request,
  } }: FetchUntypedAction): SagaIterator {

    yield put<NetworkRequestAction>(
      actionCreators.networkRequest(
        request,
        {
          info: {
            infoType: RequestInfoType.UNTYPED,
            key: id,
            type: UNTYPED_TYPE,
          },
        }
      )
    );
}

export function* displayErrorNotification({ payload, meta }: NetworkEventErrorAction): SagaIterator {
  if (isNotNullNorUndefined(meta) &&
    isNotNullNorUndefined(meta.info)) {
    if (meta.info.infoType === RequestInfoType.PAGE) {
      let error = "Something went wrong, please try again";
      if (isNotNullNorUndefined(payload) && isNotNullNorUndefined(payload.error)) {
        if (typeof payload.error === "object") {
          error = JSON.stringify(payload.error, null, 4);
        }
        error = payload.error;
      }
      yield put(notificationsActionCreators.displayNotification(
        NotificationFactory.error(
          "Unable to fetch the data.",
          getOr(error, ""),
          0
        )
      ));
    }
  }
}

interface ExtractedResponse {
  readonly status: number;
  readonly body?: Optional<any>;
  readonly ok: boolean;
}

export const networkSagas = freeze([
  takeEvery(ActionTypes.NETWORK_REQUEST, createNetworkRequest),

  takeLatest(
    ActionTypes.FETCH_ELEMENT_IF_NEEDED,
    fetchElementIfNeeded
  ),

  takeLatest(
    ActionTypes.FETCH_PAGE_IF_NEEDED,
    fetchPageIfNeeded
  ),
  takeLatest(
    ActionTypes.FETCH_PAGE,
    fetchPage
  ),
  takeLatest(
    ActionTypes.FETCH_COUNT_IF_NEEDED,
    fetchCountIfNeeded
  ),

  takeLatest(
    ActionTypes.FETCH_UNTYPED_IF_NEEDED,
    fetchUntypedIfNeeded
  ),
  takeLatest(
    ActionTypes.FETCH_UNTYPED,
    fetchUntyped
  ),
  takeEvery(
    ActionTypes.NETWORK_ERROR,
    displayErrorNotification
  ),
]);
