import React from "react";
import { IndividualResultResource } from "hyphen-lib/dist/domain/resource/survey/report/IndividualResultResource";
import { Answer } from "./Answer";
import { QuestionType } from "hyphen-lib/dist/domain/common/QuestionType";
import styled from "styled-components";
import Popover from "antd/lib/popover";

import "antd/lib/popover/style/css";
import { List } from "antd";
import Palette from "@src/config/theme/palette";
import pluralize from "pluralize";

export interface AnswersProps {
  readonly choices: IndividualResultResource.Choice[];
  readonly type: QuestionType;
}

const defaultState = {
  visible: false,
};

type AnswersState = typeof defaultState;

export class Answers extends React.Component<
AnswersProps,
AnswersState
> {
  state = defaultState;

  hide = () => {
    this.setState({
      visible: false,
    });
  };

  handleVisibleChange = (visible: boolean) => {
    this.setState({ visible });
  };

  renderItem = (item: IndividualResultResource.Choice) => {
    const { type } = this.props;
    return (
      <List.Item>
        <Answer answer={item} questionType={type} />
      </List.Item>
    );
  };

  get answerList() {
    const { choices } = this.props;

    return <List bordered dataSource={choices} renderItem={this.renderItem} />;
  }

  render() {
    const { choices, type } = this.props;
    const minimumChoices =
      choices.length > 4 ? choices.slice(0, 3) : choices.slice(0, 4);
    const moreChoicesTotal = choices.length - minimumChoices.length;
    return (
      <>
        <AnswerContainer>
          {minimumChoices.map((choice, index) => (
            <div key={choice.text}>
              <Answer
                key={`${choice.text}_${index}`}
                answer={choice}
                questionType={type}
                data-jest="multi-select-answer"
                data-text={choice.text}
              />
            </div>
          ))}
        </AnswerContainer>
        {moreChoicesTotal > 0 && (
          <Popover
            content={this.answerList}
            trigger="click"
            visible={this.state.visible}
            placement="left"
            onVisibleChange={this.handleVisibleChange}
          >
            <SeeMoreButton>
              + {moreChoicesTotal} more{" "}
              {pluralize("response", moreChoicesTotal)}
            </SeeMoreButton>
          </Popover>
        )}
      </>
    );
  }
}

const AnswerContainer = styled.div`
  width: 168px;
  display: flex;
  flex-direction: column;

  > div:not(:last-of-type) {
    margin-right: 5px;
  }

  > div {
    max-width: 100%;
    position: relative;
    > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      z-index: 1;

      &:hover::before {
        content: attr(data-text);
        display: block;
        visibility: hidden;
        position: absolute;
        color: white;
        z-index: 2;
        white-space: normal;
        padding: 5px;
        left: 25%;
        top: 100%;
        font-size: 14px;
        background: ${Palette.darkBlueGrey};
        border-radius: 2px;
        animation: showContent 0.1s ease 2s infinite;
      }

      @keyframes showContent {
        to {
          visibility: visible;
        }
      }
    }
  }
`;

AnswerContainer.displayName = "AnswerContainer";

const SeeMoreButton = styled.div`
  cursor: pointer;
  color: ${Palette.bluePurple};
`;
