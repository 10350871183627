import { FSA } from "flux-standard-action";
import { FilterParameter } from "@src/utils/networks";

export enum SurveyListFiltersActionTypes {
  MODIFY_FILTER = "surveyList/MODIFY_FILTER",
  CLEAN_FILTER = "surveyList/CLEAN_FILTER",
}

interface Payloads {
  [SurveyListFiltersActionTypes.MODIFY_FILTER]: FilterParameter;
}

export interface ModifyFilterAction
  extends FSA<
  SurveyListFiltersActionTypes.MODIFY_FILTER,
  Payloads[SurveyListFiltersActionTypes.MODIFY_FILTER]
  > {
  type: SurveyListFiltersActionTypes.MODIFY_FILTER;
  payload: Payloads[SurveyListFiltersActionTypes.MODIFY_FILTER];
}

export interface CleanFilterAction extends FSA<SurveyListFiltersActionTypes.CLEAN_FILTER, void> {
  type: SurveyListFiltersActionTypes.CLEAN_FILTER;
}

export const surveyListFiltersActionCreators = {
  modifyFilter: (payload: ModifyFilterAction["payload"]): ModifyFilterAction => ({
    type: SurveyListFiltersActionTypes.MODIFY_FILTER,
    payload,
  }),
  cleanFilter: (): CleanFilterAction => ({
    type: SurveyListFiltersActionTypes.CLEAN_FILTER,
  }),
};
