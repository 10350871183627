import React from "react";
import { TFunction, useTranslation } from "react-i18next";
import { AnonymityFilterExplanation } from "hyphen-lib/dist/domain/common/AnonymityFilterExplanation";
import { TransWrapper } from "@src/components/core/withTrans";
import styled from "styled-components";
import { getSegmentLabel } from "hyphen-lib/dist/domain/common/Dimensions";
import { Seq } from "immutable";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import incognito from "./incognito.png";
import { translate } from "@src/utils/i18next";

interface Props {
  explanation: AnonymityFilterExplanation;
  anonymityThreshold: number;
  readonly isDashboard?: boolean;
  isFiltersAvailable?: boolean;
  readonly translate?: string;
}

export function AnonymityFiltered({ explanation, 
  anonymityThreshold, isFiltersAvailable , isDashboard, ...rest}: Props) {
  const { t } = useTranslation();
  return (
    <AnonymityFilteredContainer filters={isNotNullNorUndefined(isFiltersAvailable) && isFiltersAvailable } >
      <StyledImage data-cy={"anonymityIcon"} src={incognito} alt={"Filtered for anonymity"}/>
      <Subtitle data-cy={"anonymityFilterExplanation"}>
        <TransWrapper {...rest}>{generateSubtitle(t, explanation, anonymityThreshold, isDashboard)}</TransWrapper>
      </Subtitle>
    </AnonymityFilteredContainer>
  );
}

export function generateSubtitle(t:TFunction, explanation: 
  AnonymityFilterExplanation, anonymityThreshold: number, isDashboard?: boolean): string {
  /* eslint-disable max-len */
  switch (explanation.reason) {
    case AnonymityFilterExplanation.Reason.NOT_ENOUGH_VOTES:
      return `${translate(t, "The Anonymity threshold is equal to")} ${anonymityThreshold}. ${translate(t, "The current visualization is filtered as it does not contain enough votes.")}`;
    case AnonymityFilterExplanation.Reason.NOT_ENOUGH_COMMENTERS:
      return `${translate(t, "The Anonymity threshold is equal to")} ${anonymityThreshold}. ${translate(t, "The current visualization is filtered because it only contains")} ${explanation.numberOfVoters} ${translate(t, explanation.numberOfVoters > 1 ? "commenters" : "commenter")}.`;
    case AnonymityFilterExplanation.Reason.NOT_ENOUGH_VOTERS:
      const { numberOfVoters } = explanation;
      if( isDashboard ) {
        return `${translate(t, "This analysis cannot be displayed because the anonymity threshold for your organization is not met. The data for the defined timeframe contains")} ${numberOfVoters} ${translate(t, numberOfVoters === 1 ? "voter" : "voters")} ${translate(t, "to Likert scale questions, while the anonymity threshold is")} ${anonymityThreshold} ${translate(t, anonymityThreshold === 1 ? "voter" : "voters")}`;
      }
      return `${translate(t, "The Anonymity threshold is equal to")} ${anonymityThreshold}. ${translate(t, "The current visualization is filtered because it only contains")} ${numberOfVoters} ${translate(t, numberOfVoters === 1 ? "voter" : "voters")}.`; 
    case AnonymityFilterExplanation.Reason.AVOID_INFERENCE:
      return `${translate(t, "The Anonymity threshold is equal to")} ${anonymityThreshold}. ${translate(t, "The current visualization contains enough voters")} (${explanation.numberOfVoters}), ${translate(t, "but is filtered to prevent inference of")} ${serializeSegments(explanation.segments)}" ${translate(t, "segments")}`;
    default:
      return `${translate(t, "The Anonymity threshold is equal to")} ${anonymityThreshold}. ${translate(t, "The current visualization is filtered.")}`;
  }
  /* eslint-disable max-len */
}

export function serializeSegments(segments: string[]) {
  return Seq(segments)
    .map(segment => getSegmentLabel(segment))
    .join(", ");
}

const AnonymityFilteredContainer = styled.div<{ filters: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media print {
    margin-top: ${props => props.filters ? "100px" : ""}
  }

`;

const StyledImage = styled.img`
  height: 150px;
  opacity: 0.2;
`;

const Subtitle = styled.span`

`;
