// @ts-nocheck
import React from "react";
import Palette from "@src/config/theme/palette";
import styled from "styled-components";

const GenericSvg = styled.svg<{fill?: string}>`
  fill: ${props => props.fill || Palette.bluishGrey};
`;

export default GenericSvg;
