import React from "react";
import styled from "styled-components";

import { Form } from "antd";
import { FormProps } from "antd/lib/form";
import { ModalProps } from "antd/lib/modal";

import { Paragraph } from "@components/core/Typography";
import Modal from "@components/core/Modal";
import Button from "@components/core/Button";

import { Breadcrumb, goTo } from "@src/utils/locations";

import Palette from "@src/config/theme/palette";
import { checkChannels } from "@screens/Insights/SurveyList/utils/formValidations";

import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { SurveyInfoResource } from "hyphen-lib/dist/domain/resource/SurveyInfoResource";
import { getOr, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { NotificationChannels } from "hyphen-lib/dist/domain/notification/NotificationChannels";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import { NotificationChannelsForm } from "@screens/Insights/components/NotificationChannelsForm";
import { MargeTagStateProps } from "../../Surveys/store/types";
import { SurveyListContainerActionProps } from "../index";
import { ActionKeys } from "./cells/ActionCell";
import { Note } from "../../Surveys/components/MagicSurveySection";
import { Trans } from "react-i18next";

export interface SendReminderModalProps extends ModalProps {
  readonly participation: SurveyInfoResource["participation"];
  readonly name: SurveyInfoResource["name"];
  readonly surveyType: SurveyInfoResource["type"];
  readonly onSendReminder: (channels: NotificationChannels) => void;
  readonly isRequestingSendReminders: boolean;
  readonly fetchMergeTags: SurveyListContainerActionProps["fetchMergeTags"];
  readonly mergeTags: MargeTagStateProps;
  readonly viewReminderHistory?: {
    readonly surveyId: string;
  };
  readonly companyChannels: CompanyResource["channels"];
  readonly surveyChannels: NotificationChannels;
  readonly nextReminderChannels: Optional<NotificationChannels>;
  readonly isMagicSurvey?: Optional<boolean>;
}

interface SendReminderModalState {
  readonly channels: NotificationChannels;
  readonly errors?: Optional<WrongEntityException.Errors>;
}

export class SendReminderModal extends React.Component<SendReminderModalProps, SendReminderModalState> {

  constructor(props: SendReminderModalProps) {
    super(props);

    this.state = {
      channels: getOr(props.nextReminderChannels, props.surveyChannels),
    };
  }

  componentDidMount() {
    const { surveyType, fetchMergeTags } = this.props;
    fetchMergeTags(surveyType);
  }

  componentDidUpdate = (prevProps: SendReminderModalProps) => {
    if (
      prevProps.nextReminderChannels !== this.props.nextReminderChannels ||
      prevProps.surveyChannels !== this.props.surveyChannels
    ) {
      const {
        nextReminderChannels,
        surveyChannels,
      } = this.props;

      this.setState({
        channels: getOr(nextReminderChannels, surveyChannels),
      });
    }
  };

  updateChannels = (channels: NotificationChannels) => {
    this.setState({
      channels,
    });
  };

  onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const { onSendReminder } = this.props;
    const { channels } = this.state;

    const errors = checkChannels(channels);

    if (WrongEntityException.hasErrors(errors)) {
      this.setState({ errors });
    } else {
      onSendReminder(channels);
    }

  };

  goToLink = (link: string) => {
    return () => goTo(link, Breadcrumb.stack("Surveys"));
  };

  render() {

    const {
      name,
      participation,
      onOk,
      isRequestingSendReminders,
      mergeTags,
      surveyType,
      viewReminderHistory,
      companyChannels,
      surveyChannels,
      isMagicSurvey,
      ...rest
    } = this.props;

    const {
      channels,
      errors,
    } = this.state;

    const { total, completed } = participation;
    const remainingParticipants = total - completed;

    return (
      <StyledModal footer={null} {...rest}>
        <Container onSubmit={this.onSubmit}>
          <Paragraph data-jest={`remainingParticipantsCount_${remainingParticipants}`}>
            <Trans i18nKey="reminderKey" values={{name, count: remainingParticipants}} 
            defaults={`You’re about to send a reminder for the ‘${name}’ to ${remainingParticipants} recipients.`}/>
          </Paragraph>

          <span>
            <Trans>Manual reminders do not affect the cadence of automated reminders.</Trans>
          </span>

          {
            (isNotNullNorUndefined(viewReminderHistory)) && (
              <ReminderHistoryLink
                onClick={this.goToLink(`/surveys/view/${viewReminderHistory.surveyId}/reminders`)}
              >
                <Trans>See reminder history</Trans>
              </ReminderHistoryLink>
            )
          }

          <NotificationChannelsForm
            channels={channels}
            allowedChannels={companyChannels}
            allowedMergeTags={mergeTags.get(surveyType, [])}
            onChange={this.updateChannels}
            errors={Optional.map(errors, err => err.fields)}
            configuredSurveyLanguages={[]}
            enableTranslation={false}
            isMultiLanguageSurveyEnabled={false}
          />

          {
            isMagicSurvey && <Note data-jest="magicSurveyNote">
              <Trans>Reminders would need to be sent manually to participants using the web link,
                by resending the link. Warning: It might prompt those particpants to respond again</Trans>
            </Note> 
          }

          <SubmitButtonContainer>
            <Button
              color="blue"
              htmlType="submit"
              key={ActionKeys.sendReminder}
              disabled={isRequestingSendReminders}
              translate="yes"
            >
              {isRequestingSendReminders ? "Sending reminder..." : "Send the reminder"}
            </Button>
          </SubmitButtonContainer>
        </Container>
      </StyledModal>
    );
  }
}

const Container = styled(Form)<FormProps>`
  padding: 0 32px !important;
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 24px;
`;

const ReminderHistoryLink = styled.div`
  color: ${Palette.darkModerateBlue};
  margin-top: 8px;
  display: inline-block;
  cursor: pointer;
`;

const StyledModal = styled(Modal)<ModalProps & {children: React.ReactNode}>``;
