import React from "react";
import Highcharts, { ChartLoadCallbackFunction, Point, PointOptionsObject } from "highcharts";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import HighchartsReact from "highcharts-react-official";
import ReactResizeDetector from "react-resize-detector";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { Optional } from "@hyphen-lib/lang/Optionals";
import { applyDefault, getOr, isEmptyObject, isNotNullNorUndefined } from "@hyphen-lib/lang/Objects";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import NoDataString from "@src/screens/Insights/LifeCycleAnalysis/components/NoData";
import { generateLineSeriesAndLabels } from "./utils/chartHelpers";

const DEFAULT_TOOLTIP_OPTIONS: Highcharts.TooltipOptions = Object.freeze({
  backgroundColor: Palette.darkBlueGrey,
  borderColor: Palette.darkBlueGrey,
  shared: true,
  distance: 24,
  style: {
    color: Palette.white,
    fontSize: "12px",
    textAlign: "center",
  },
  useHTML: true,
  formatter(tp: Highcharts.Tooltip) {
    const pointsData = this.points![0];
    return `
      <div style='padding: 10px;'>
        ${pointsData.x}: ${pointsData.y}%
      </div>
    `;
  },
});

const DEFAULT_DATA_LABEL_OPTIONS: Highcharts.DataLabelsOptions = Object.freeze({
  enabled: true,
  formatter(this: Highcharts.PointLabelObject) {
    return `${this.point.y}%`;
  },
});

const DEFAULT_XAXIS_LABEL_OPTIONS: Highcharts.XAxisLabelsOptions = Object.freeze({
  formatter(this: Highcharts.AxisLabelsFormatterContextObject<number>): string {
    if (isStringAndNotEmpty(this.value)) {
      return `${this.value}`;
    }
    return "";
  },
});

export interface TrendLineChartDataOptions<T> extends PointOptionsObject {
  // Here you can add additional data for your own event callbacks and formatter callbacks.
  custom: Partial<T>;
}

export interface PointWithCustomData extends Point {
  custom?: Dictionary<any>;
}

export type TrendLineChartData<T = any> = [string, Optional<number>, Optional<TrendLineChartDataOptions<T>>?];

export interface TrendLineProps<T = any> {
  data: TrendLineChartData<T>[];
  toolTipOptions?: Highcharts.TooltipOptions;
  seriesDataLabelsOptions?: Highcharts.DataLabelsOptions;
  xAxisLabelsOptions?: Highcharts.XAxisLabelsOptions;
  xAxis?: Partial<{
    title: string;
  }>;
  yAxis?: Partial<{
    title: string;
    min: number;
    max: number;
    tickInterval: number;
    rotation: number;
    labelFormatter: Highcharts.AxisLabelsFormatterCallbackFunction;
  }>;
  line?: Partial<{
    valueSuffix: string;
  }>;
  styles?: Partial<{
    lineColor: string;
    pointColor: string;
    dataLabelHighlightColor: string;
  }>;
  showCurrent?: boolean;
  onDataPointClick?: (data: TrendLineChartData<T>, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  minTicks: number;
}

interface ChartWithNoDataAndAnonymityFiltered extends Highcharts.Chart {
  noData?: Optional<Highcharts.SVGElement>;
  anonymityFiltered?: Optional<Highcharts.SVGElement>;
}

const defaultProps : Readonly<TrendLineProps> = Object.freeze({
  data: [],
  xAxis: {
    title: "",
    rotation: 0,
  },
  yAxis: {
    title: "",
    min: 0,
    max: 100,
    tickInterval: 10,
    rotation: 0,
    showFirstLabel: true,
    showLastLabel: true,
  },
  line: {
    valueSuffix: "%",
  },
  styles: {
    lineColor: Palette.darkModerateBlue,
    pointColor: Palette.darkModerateBlue,
    dataLabelHighlightColor: Palette.aquaBlue,
  },
  minTicks: 6,
});

const chartHeight = 250;

const shouldShowNoData = (points: PointWithCustomData[]) => (
  points.every(
    point => (isNotNullNorUndefined(point.custom) && point.custom.noData) || isEmptyObject(point.custom)
  )
);
const shouldShowAnonymityFiltered = (points: PointWithCustomData[]) => (
  points.every(
    point => (isNotNullNorUndefined(point.custom) && point.custom.isFilteredForAnonymity) || isEmptyObject(point.custom)
  )
);

export const TrendLineChart: React.FC<TrendLineProps> = (props) => {
  const highlightDataPointIfRequired = (points: PointWithCustomData[], color: string) => {
    points.forEach(point => {
      if (isNotNullNorUndefined(point.y) && isNotNullNorUndefined(point.custom) && point.custom.isHighlighted) {
        point.update(
          {
            marker: {
              radius: 6,
              fillColor: color,
            },
            dataLabels: {
              color,
              style: {
                fontSize: "18px",
              },
            },
          }
        );
      }
    });
  };

  const renderNoDataOrAnonymityFilteredIconIfRequired = (chart: ChartWithNoDataAndAnonymityFiltered) => {
    const {
      chartWidth,
      renderer,
      noData,
    } = chart;

    const points: PointWithCustomData[] = chart.series[0].points;
    const showNoData = shouldShowNoData(points) || shouldShowAnonymityFiltered(points);

    // ref: https://www.highcharts.com/forum/viewtopic.php?t=38132
    if (noData && !isEmptyObject(noData)) {
      noData.destroy();
    }

    chart.noData = renderer.text(
      NoDataString("TrendLineChartNoData"),
      chartWidth / 2,
      chartHeight / 2,
      true
    )
      .css({ display: showNoData ? "block" : "none", transform: "translate(-50%, -50%)"})
      .add();
  };

  const chartDidRender = (): ChartLoadCallbackFunction => {
    const { styles } = props;
    return function () {
      highlightDataPointIfRequired(this.series[0].points, styles!.dataLabelHighlightColor!);
      renderNoDataOrAnonymityFilteredIconIfRequired(this);
    };
  };

  const generateChartOptions = (): Highcharts.Options => {
    const {
      data,
      xAxis,
      yAxis,
      styles,
      line,
      minTicks,
      toolTipOptions,
      seriesDataLabelsOptions,
      xAxisLabelsOptions,
    } = props;

    const { series, xAxis: labeledXAxis, yAxis: labeledYAxis } = generateLineSeriesAndLabels(
      data,
      styles,
      minTicks,
      {
        seriesDataLabelsOptions: applyDefault(seriesDataLabelsOptions, DEFAULT_DATA_LABEL_OPTIONS),
        xAxisLabelsOptions: applyDefault(xAxisLabelsOptions, DEFAULT_XAXIS_LABEL_OPTIONS),
      }
    );

    return {
      title: {
        text: "",
      },
      lang: {
        noData: "No data available",
      },
      chart: {
        height: chartHeight,
        events: {
          load: chartDidRender(),
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true,
            y: -10,
            nullFormat: true,
            color: styles!.lineColor,
            style: {
              fontSize: "14px",
            },
          },
        },
      },
      series,
      xAxis: {
        ...applyDefault(xAxis, defaultProps.xAxis),
        title: {
          text: xAxis!.title,
        },
        ...labeledXAxis,
      },
      yAxis: {
        ...applyDefault(yAxis, defaultProps.yAxis),
        title: {
          text: yAxis!.title,
        },
        labels: {
          format: `{value}${line!.valueSuffix}`,
          rotation: yAxis!.rotation,
          formatter: yAxis!.labelFormatter,
        },
        ...labeledYAxis,
      },
      tooltip: applyDefault(toolTipOptions, DEFAULT_TOOLTIP_OPTIONS),
      credits: { // disable highcharts watermark
        enabled: false,
      },
    } as Highcharts.Options;
  };

  const chartOptions = generateChartOptions();

  return (
    <Container height={chartHeight}>
      <ReactResizeDetector handleWidth>
        <HighchartsReact
          highcharts={NoDataToDisplay(Highcharts)}
          options={chartOptions}
        />
      </ReactResizeDetector>
    </Container>
  );
};

const Container = styled.div<{ height?: number }>`
  font-family: Lato, sans-serif;
  height: ${props => getOr(props.height, 144)}px;
  width: 100%;
`;

TrendLineChart.defaultProps = defaultProps;
