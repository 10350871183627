import { Filter, SubFilter } from "@src/components/core/FilterLabels/types";
import { Dictionary } from "@hyphen-lib/domain/structure/Dictionary";
import {
  cleanObject,
  getOr,
  isNotEmptyObject,
  isNotNullNorUndefined,
  isObject,
  mapOr,
  entries
} from "hyphen-lib/dist/lang/Objects";
import moment from "moment";
import { SurveyTypeResource } from "hyphen-lib/dist/domain/resource/SurveyTypeResource";
import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";

import startCase from "lodash/startCase";
import { FilterParameter } from "@src/utils/networks";
import { isString } from "@hyphen-lib/lang/Strings";
import { mapSentimentToTextAndColor } from "@components/core/SentimentTag";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Seq } from "immutable";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { moduleNameMapper } from "@src/utils/helper";

import { SurveyTypes } from "@screens/Insights/Surveys/store/types";
import { getFiltersDict } from "hyphen-lib/dist/util/net/HttpClient";
import { getDimensionLabel, getDimensionSegmentLabel } from "@src/utils/Dimensions";
/*
    fixme: BEFORE LAUNCH!!!
    fixme: BEFORE LAUNCH!!!
    fixme: BEFORE LAUNCH!!!
    fixme: BEFORE LAUNCH!!!
    fixme: BEFORE LAUNCH!!!
    fixme: BEFORE LAUNCH!!!

    We are mixing multiple unrelated things. What if tomorrow we have a closed status on action, and that we want to
    display "Closed actions".

    Let's have multiple constant for labels, and let's try to isolate them in separates files as we will need
    to find them quickly the day we will have to plug i18n.
 */
const status = {
  availableValues: [
    { key: "draft", label: "Draft" },
    { key: "launched", label: "In Progress" },
    { key: "closed", label: "Closed" },
    { key: "candidate", label: "Candidate" },
    { key: "employee", label: "Employee" },
    { key: "terminated", label: "Terminated" },
    { key: "active", label: "Active" },
    { key: "deactivated", label: "Paused/Closed" },

    /* For Action Plans */
    { key: "todo", label: "To do" },
    { key: "in_progress", label: "In progress" },
    { key: "completed", label: "Completed" },
    { key: "dismissed", label: "Dismissed" },
  ],
};

function basicSubfilterCreator(filterObjValue: any[], subFilters: SubFilter[], subStartsCase?: boolean): SubFilter[] {
  if (Array.isArray(filterObjValue)) {
    filterObjValue.forEach((subFilter: string) => {
      subFilters.push({
        key: subFilter,
        label: subStartsCase ? startCase(subFilter) : subFilter,
      } as SubFilter);
    });
  }
  return subFilters;
}

const DIMENSIONS_KEY = "dimensions";

export function clearFilter(filterToRemove: string, filterProp?: FilterParameter) {
  const filter = { ...getOr(filterProp, {}) };
  if (isNotNullNorUndefined(filter[filterToRemove])) {
    filter[filterToRemove] = undefined;
  } else if (isNotNullNorUndefined(filter[DIMENSIONS_KEY])) {
    const dimensionFilters = { ...filter[DIMENSIONS_KEY] };
    Object.keys(dimensionFilters).forEach(key => {
      if (key === filterToRemove) {
        dimensionFilters[key] = Optional.empty();
      }
    });
    filter[DIMENSIONS_KEY] = dimensionFilters;
  }
  return filter;
}

export function clearFilterOption(filterToRemove: string, subFilter: string, filterProp?: FilterParameter) {
  const filter = { ...getOr(filterProp, {}) };

  if (isNotNullNorUndefined(filter[filterToRemove])) {
    if (Array.isArray(filter[filterToRemove])) {
      const filterToRemoveFrom = [...filter[filterToRemove]];
      const indexToRemove = filterToRemoveFrom.indexOf(subFilter);
      if (indexToRemove > -1) {
        filterToRemoveFrom.splice(indexToRemove, 1);
        filter[filterToRemove] = filterToRemoveFrom;
      }
      if (filter[filterToRemove].length === 0) {
        filter[filterToRemove] = undefined;
      }
    } else {
      filter[filterToRemove] = undefined;
    }
  } else if (isNotNullNorUndefined(filter[DIMENSIONS_KEY])) {
    const dimensionFilters = { ...filter[DIMENSIONS_KEY] };
    const cleanedValuesForDimension =
      Seq(getOr(dimensionFilters[filterToRemove], []))
        .filter((segment: string) => segment !== subFilter)
        .toArray();

    if (isNotEmptyArray(cleanedValuesForDimension)) {
      dimensionFilters[filterToRemove] = cleanedValuesForDimension;
    } else {
      dimensionFilters[filterToRemove] = undefined;
    }

    const cleanDimensionFilters = cleanObject(dimensionFilters);
    if (isNotEmptyObject(cleanDimensionFilters)) {
      filter[DIMENSIONS_KEY] = cleanDimensionFilters;
    } else {
      filter[DIMENSIONS_KEY] = undefined;
    }
  }

  return filter;
}

/*
    fixme: BEFORE LAUNCH!!!
    fixme: BEFORE LAUNCH!!!
    fixme: BEFORE LAUNCH!!!
    fixme: BEFORE LAUNCH!!!
    fixme: BEFORE LAUNCH!!!
    fixme: BEFORE LAUNCH!!!

    Here again, we are doing a switch on filters keys, so each time a filter is added we need to modify X places.

    We should use filter definition it has been meant for that, and if we need a serialize/deserialize method
    in filter definition lets do that. Like that here, we will just have to loop over the filter definitions
    and deserialize the key using the definition.
 */
export default function convertDictToFilters(
  filterObject: Dictionary<any> | undefined,
  dimensions: Dimensions,
  surveyTypes?: SurveyTypeResource[]
): Filter[] {
  const filters: Filter[] = [];
  if (isNotNullNorUndefined(filterObject)) {
    for (const [filterObjKey, filterObjValue] of Object.entries(filterObject)) {
      let subFilters: SubFilter[] = [];
      let filterLabel = "";
      switch (filterObjKey) {
        case "name":
          break;
        case "date":
        case "launchedAt":
        case "createdAtDate":
          filterLabel = filterObjKey === "launchedAt" ? "Launched At" : "Date";
          if (filterObjValue.type === "range") {
            const fromMoment = moment(Number(filterObjValue.from)).format("MMM Do YY");
            const toMoment = moment(Number(filterObjValue.to)).format("MMM Do YY");
            subFilters.push({
              key: "range",
              label: fromMoment + "-" + toMoment,
            } as SubFilter);
          }
          else if (filterObjValue.type === "lastXDays") {
            subFilters.push({
              key: "lastXDays",
              label: "Last " + filterObjValue.numberOfDays + " days",
            } as SubFilter);
          }
          break;
        case "lastInstanceDate":
          filterLabel = filterObjKey === "lastInstanceDate" ? "Last Instance Date" : "Date";
          if (filterObjValue.type === "range") {
            const fromMoment = moment(Number(filterObjValue.from)).format("MMM Do YY");
            const toMoment = moment(Number(filterObjValue.to)).format("MMM Do YY");
            subFilters.push({
              key: "range",
              label: fromMoment + "-" + toMoment,
            } as SubFilter);
          }
          else if (filterObjValue.type === "lastXDays") {
            subFilters.push({
              key: "lastXDays",
              label: "Last " + filterObjValue.numberOfDays + " days",
            } as SubFilter);
          }
          break;
        case "type":
          filterLabel = "Type";
          if (Array.isArray(filterObjValue)) {
            filterObjValue.forEach((subFilter: string) => {
              if (isNotNullNorUndefined(surveyTypes)) {
                const surveyType = surveyTypes.find(x => x._id === subFilter);
                const subFilterLabel = (isNotNullNorUndefined(surveyType)) ? surveyType.label : "";

                subFilters.push({
                  key: subFilter,
                  label: subFilterLabel,
                } as SubFilter);
              }
            });
          }
          break;
        case "status":
        case "statuses":
          filterLabel = "Status";
          if (Array.isArray(filterObjValue)) {
            filterObjValue.forEach((subFilter: string) => {

              const statusType = status.availableValues.find(x => x.key === subFilter);
              const subFilterLabel = (isNotNullNorUndefined(statusType)) ? statusType.label : "";

              subFilters.push({
                key: subFilter,
                label: subFilterLabel,
              } as SubFilter);
            });
          }
          break;
        case "roles":
          filterLabel = "Role";
          subFilters = basicSubfilterCreator(filterObjValue, subFilters);
          break;
        case "questionTypes":
          filterLabel = startCase(filterObjKey);
          subFilters = basicSubfilterCreator(filterObjValue, subFilters, true);
          break;
        case "hasInsightsAccess":
          filterLabel = "Insights access";
          if (isString(filterObjValue)) {
            let label = "";
            switch (filterObjValue) {
              case "true":
                label = "Yes";
                break;
              case "false":
                label = "No";
                break;
            }
            subFilters.push({
              key: filterObjValue,
              label,
            } as SubFilter);
          }
          break;
        case "customScope":
          filterLabel = "Custom Scope";
          if (isString(filterObjValue)) {
            let label = "";
            switch (filterObjValue) {
              case "true":
                label = "Yes";
                break;
              case "false":
                label = "No";
                break;
            }
            subFilters.push({
              key: filterObjValue,
              label,
            } as SubFilter);
          }
          break;
        case "dimensions":
          if (isObject(filterObjValue)) {
            filters.push(...extractDimensionsFilters(filterObjValue as Dictionary<string[]>, dimensions));
          }
          break;
        case "sentiments":
          filterLabel = startCase(filterObjKey);
          filterObjValue.forEach((subFilter: string) => {
            const sentimentValue = Number.parseInt(subFilter, 10);
            const filterValueLabel = mapSentimentToTextAndColor(sentimentValue).text;
            subFilters.push({
              key: subFilter,
              label: filterValueLabel,
            } as SubFilter);
          });
          break;
        case "withCommentOnly":
          filterLabel = startCase(filterObjKey);
          subFilters.push({
            key: filterObjValue,
            label: filterObjValue === "true" ? "Yes" : "No",
          } as SubFilter);
          break;

          /* Filters for Action Plans begins */
        case "sources":
          filterLabel = startCase(filterObjKey);
          subFilters = basicSubfilterCreator(filterObjValue, subFilters);
          break;

        case "overdue":
          filterLabel = startCase(filterObjKey);
          subFilters.push({
            key: filterObjValue,
            label: filterObjValue === "true" ? "Yes" : "No",
          } as SubFilter);
          break;

        case "assignedToMe":
          filterLabel = startCase(filterObjKey);
          subFilters.push({
            key: filterObjValue,
            label: filterObjValue === "true" ? "Yes" : "No",
          } as SubFilter);
          break;

        case "createdByMe":
          filterLabel = startCase(filterObjKey);
          subFilters.push({
            key: filterObjValue,
            label: filterObjValue === "true" ? "Yes" : "No",
          } as SubFilter);
          break;

        case "dueDate":
          filterLabel = filterObjKey === "dueDate" ? "Due Date" : "Date";
          if (filterObjValue.type === "range") {
            const fromMoment = moment(Number(filterObjValue.from)).format("MMM Do YY");
            const toMoment = moment(Number(filterObjValue.to)).format("MMM Do YY");
            subFilters.push({
              key: "range",
              label: fromMoment + "-" + toMoment,
            } as SubFilter);
          }
          else if (filterObjValue.type === "lastXDays") {
            subFilters.push({
              key: "lastXDays",
              label: "Last " + filterObjValue.numberOfDays + " days",
            } as SubFilter);
          }
          break;

        case "categories":
        case "category":
          filterLabel = startCase(filterObjKey);
          subFilters = basicSubfilterCreator(Array.isArray(filterObjValue) ?
            filterObjValue : [filterObjValue], subFilters); 
          break;
        case "modules": {
          filterLabel = startCase(filterObjKey);
          filterObjValue.forEach((subFilter: string) => {
            subFilters.push({
              key: subFilter,
              label: moduleNameMapper[subFilter],
            } as SubFilter);
          });
          break;
        }
        default:
          /* dimensions and unknown filters will fallback here... */
          filterLabel = mapOr(dimensions[filterObjKey], d => d.label, startCase(filterObjKey));
          subFilters = basicSubfilterCreator(filterObjValue, subFilters);
        /* Filters for Action Plans end */
      }

      if (subFilters.length > 0) {
        filters.push({
          key: filterObjKey,
          label: filterLabel,
          filters: subFilters,
        });
      }
    }
  }

  return filters;
}

function extractDimensionsFilters(dimensionsFilter: Dictionary<string[]>,
  dimensions: Dimensions): Filter[] {

  return entries(dimensionsFilter)
    .map((segments: string[], dimension: string) => extractDimensionsFilter(dimension, segments, dimensions))
    .valueSeq()
    .toArray();
}

function extractDimensionsFilter(dimension: string,
  segments: string[],
  dimensions: Dimensions): Filter {
  const label = getDimensionLabel(dimension, dimensions);
  const filters: SubFilter[] = segments.map(segment => ({
    key: segment,
    label: getDimensionSegmentLabel(dimension, segment),
  }));
  return {
    key: dimension,
    label,
    filters,
  };
}

/**
 * This method parses the url and returns the list of all the applied filters.
 * You'll need to pass surveyTypes too for any screen that has survey-types filter
 */
export function getAppliedFilters (
  search: Location["search"],
  dimensions: Dimensions,
  surveyTypes?: SurveyTypes
) : Filter[] {
  const filters = getFiltersDict(search);
  return convertDictToFilters(filters, dimensions, surveyTypes);
}
