import { FilterDefinition } from "@screens/Insights/components/FiltersContent";
import { applyDefault } from "@src/utils/Currier";
import { MultiSelectFilter } from "@screens/Insights/components/Filters/base/MultiSelectFilter";
import { Dimensions, Dimension } from "hyphen-lib/dist/domain/common/Dimensions";
import { entries } from "hyphen-lib/dist/lang/Objects";
import { getDimensionSegmentLabel } from "@src/utils/Dimensions";

/**
 * Generates the filter definitions for the specified dimensions.
 *
 * @param {Dimensions} dimensions
 * @return {FilterDefinition[]}
 */
export function generateDefinitionsForDimensions(dimensions: Dimensions): FilterDefinition[] {
  return entries(dimensions)
    .map((dimension: Dimension, dimKey: string) => ({
      key: "dimensions." + dimKey,
      label: dimension.label,
      component: applyDefault(
        MultiSelectFilter,
        {
          availableValues: dimension.segments.map(
            segment => ({ value: segment, label: getDimensionSegmentLabel(dimKey, segment) })
          ),
        }
      ),
      hidden: true,
    }))
    .valueSeq()
    .toArray();
}
