import React from "react";
import styled from "styled-components";
import Button from "@src/components/core/Button";
import Input from "@src/components/core/Input";
import { List } from "immutable";
import Palette from "@src/config/theme/palette";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import { CustomAnswer } from "./CustomAnswer";
import { Trans } from "react-i18next";

export class AddCustomAnswer extends React.Component<{
  customAnswers: List<string>;
  minOptions: number;
  maxOptions: number;
  addCustomAnswer: (answer: string) => void;
  onChoiceChange: (index: number, value: string) => void;
  onAnswerRemove: (index: number) => void;
}> {
  state = {
    answer: "",
    isEditable: false,
    error: null,
    hoveredIndex: null,
  };
  onAnswerChange = (e: any) =>
    this.setState({ answer: e.target.value, error: null });
  onAddNew = (e: any) => this.setState({ isEditable: true });

  submitAnswer = () => {
    const { answer } = this.state;
    if (answer.length === 0) {
      return this.setState({ error: "Choice cannot be empty" });
    }
    this.props.addCustomAnswer(answer);
    this.setState({ answer: "", isEditable: false });
  };

  onEdit = (index: number, answer: string) =>
    this.props.onChoiceChange(index, answer);

  onRemove = (index: number) => this.props.onAnswerRemove(index);
  onMouseLeave = () => {
    if (this.state.hoveredIndex !== null) {
      this.setState({ hoveredIndex: null });
    }
  };
  onMouseEnter(index: number) {
    if (this.state.hoveredIndex !== index) {
      this.setState({ hoveredIndex: index });
    }
  }

  render() {
    const { customAnswers } = this.props;
    const { answer, isEditable, error, hoveredIndex } = this.state;

    const filteredAnswers = customAnswers.filter(isStringAndNotEmpty);
    return (
      <>
        {filteredAnswers.size > 0 && (
          <CustomAnswerContainer>
            {filteredAnswers.map((customAnswer, index) => {
              return (
                <CustomAnswer
                  key={index}
                  hover={hoveredIndex === index}
                  answer={customAnswer}
                  onEdit={this.onEdit.bind(null, index)}
                  onRemove={this.onRemove.bind(null, index)}
                  onMouseEnter={this.onMouseEnter.bind(this, index)}
                  onMouseLeave={this.onMouseLeave}
                />
              );
            })}
          </CustomAnswerContainer>
        )}
        {isEditable
          ? (
            <Input
              value={answer}
              onChange={this.onAnswerChange}
              onPressEnter={this.submitAnswer}
              suffix={<BlueLink onClick={this.submitAnswer}>
                <Trans>Save</Trans></BlueLink>}
              error={error && <Trans>{error}</Trans>}
              autoFocus
            />
          )
          : (
            filteredAnswers.size < this.props.maxOptions && (
              <Button color="blue" onClick={this.onAddNew} translate="yes">
                Add new answer
              </Button>
            )
          )
        }
      </>
    );
  }
}

const BlueLink = styled.div`
  color: ${Palette.darkModerateBlue};
  cursor: pointer;
`;

const CustomAnswerContainer = styled.div`
  margin-bottom: 8px;
`;
