import React from "react";
import styled from "styled-components";
import { SidebarIcon } from "@components/Sidebar/components/SidebarIcon";
import { SidebarMode } from "@components/Sidebar";
import { Tooltip, Icon as NotificationIcon } from "antd";
import { Trans } from "react-i18next";

interface SidebarItemProps {
  icon: React.ComponentType<any>;
  text: string;
  onClick: () => void;
  mode: SidebarMode;
  active: boolean;
  showNotificationIcon?: boolean;
}

export const SidebarItem = ({ icon: Icon, text, onClick, mode, active }: SidebarItemProps) => (
  <SidebarItemContainer mode={mode}>
    <SidebarItemInnerContainer onClick={onClick}>
      <Tooltip title={mode === SidebarMode.COLLAPSED ? <Trans>{text}</Trans> : ""} placement="right">
        <SidebarIcon active={active}>
          <Icon/>
        </SidebarIcon>
      </Tooltip>
      {
        mode === SidebarMode.EXPANDED &&
        <SidebarItemLabel>
          <Trans>{text}</Trans>
        </SidebarItemLabel>
      }
    </SidebarItemInnerContainer>
  </SidebarItemContainer>
);

const SidebarItemContainer = styled.div<{mode: SidebarMode}>`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: ${props => props.mode === SidebarMode.EXPANDED ? "flex-start" : "center"};
  padding-left: ${props => props.mode === SidebarMode.EXPANDED ? 33 : 0}px;
  width: 100%;
`;

export const StyledNotificationIcon = styled(NotificationIcon)`
    margin-left: 6px;
    font-size: 16px;
`;
const SidebarItemInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const SidebarItemLabel = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.07;
  letter-spacing: normal;
  color: #fff;
  margin-left: 9px;
`;
