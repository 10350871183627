import { FSA } from "flux-standard-action";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { PropMapping } from "@src/utils/parameters";

export enum ParametersActionTypes {
  MODIFY_PARAMETERS = "parameters/MODIFY",
}

interface Payloads {
  [ParametersActionTypes.MODIFY_PARAMETERS]: {
    parameters: Dictionary<any>;
    mappings: PropMapping[];
  };
}

export interface ModifyParametersAction
  extends FSA<ParametersActionTypes.MODIFY_PARAMETERS, Payloads[ParametersActionTypes.MODIFY_PARAMETERS]> {
  type: ParametersActionTypes.MODIFY_PARAMETERS;
  payload: Payloads[ParametersActionTypes.MODIFY_PARAMETERS];
}

export const parametersActionCreators = {
  modifyParameters: (parameters: Dictionary<any>, mappings: PropMapping[] = []): ModifyParametersAction => ({
    type: ParametersActionTypes.MODIFY_PARAMETERS,
    payload: {
      parameters,
      mappings,
    },
  }),
};
