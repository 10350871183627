// @ts-nocheck
import React from "react";
export function ExportSvg () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
    >
      {/* eslint-disable max-len */}
      <path fill="#AAB9CE" fillRule="evenodd" d="M5.568.804c0-.444.36-.804.804-.804h4.824c.444 0 .804.36.804.804v4.824a.804.804 0 1 1-1.608 0V2.745L6.705 6.432a.804.804 0 0 1-1.137-1.137l3.687-3.687H6.372a.804.804 0 0 1-.804-.804zM11.25 12H.75a.75.75 0 0 1-.75-.75V.75a.75.75 0 1 1 1.5 0v9.75h9.75a.75.75 0 1 1 0 1.5z"/>
      {/* eslint-disable max-len */}
    </svg>
  );
}
