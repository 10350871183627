import { VoiceCommentReportResource } from "hyphen-lib/dist/domain/resource/voice/VoiceCommentReportResource";
import { appendQueryString, generateQueryString } from "hyphen-lib/dist/util/net/HttpClient";

import { default as networkActionCreators } from "@store/network/actions";
import { createRequest } from "@src/utils/networks";
import { FetchDataListParameters } from "@src/screens/Insights/components/DataListContainer/types";

export function fetchVoiceCommentsIfNeeded({ filter, sort, page }: FetchDataListParameters) {
  return networkActionCreators.fetchPageIfNeeded({
    id: VoiceCommentReportResource.generateKey(filter),
    type: VoiceCommentReportResource.TYPE,
    page,
    rawPageSize: 30,
    request: pageFilter => createRequest(
      appendQueryString(
        "/voice/reports/comments",
        generateQueryString({
          filter,
          sort,
          page: pageFilter,
        })
      )
    ),
  });
}
