import React from "react";
import styled from "styled-components";

import Palette from "@src/config/theme/palette";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";

interface Props extends React.HTMLProps<HTMLSpanElement> {
  numberOfComments: number;
}

export function CommentsCounter({ numberOfComments, onClick }: Props) {
  return (
    <Container onClick={onClick}>{numberOfComments}</Container>
  );
}

const Container = styled.span<{ onClick: any }>`
  background: ${Palette.darkModerateBlue};
  color: ${Palette.white};
  font-size: 10px;
  padding: 3px 6px;
  text-align: center;
  border-radius: 3px;
  position: relative;
  display: block;
  float: left;
  clear: both;
  cursor: ${({onClick}) => isNotNullNorUndefined(onClick) ? "pointer" : "inherit"};

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 3px 0 3px 4px;
    border-color: transparent transparent transparent ${Palette.darkModerateBlue};
    bottom: -3px;
    left: 0;
  }
`;
