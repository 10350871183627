/* eslint-disable max-len */
export function NewBadgeSvg() {
    return (
        <svg width="18px" height="18px" viewBox="0 0 18 18" style={{marginRight: 6, verticalAlign: "sub"}} version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
            <title>8852E1BA-1C9A-4C93-968F-759E3619B4B4</title>
            <defs>
                <filter colorInterpolationFilters="auto" id="filter-1">
                    <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 0.000000 0 0 0 0 0.850980 0 0 0 0 0.580392 0 0 0 1.000000 0" />
                </filter>
            </defs>
            <g id="Action-planning-OKR-creation" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Create-AP-/-From-Scratch-/-Key-result-selected" transform="translate(-771.000000, -406.000000)">
                    <g id="Section-/-Create-AP-/-Add-to-OKR-vert" transform="translate(755.000000, 389.000000)">
                        <g id="Section-/-Create-AP-/-Integrations-base" transform="translate(16.000000, 16.000000)">
                            <g id="new-box" transform="translate(0.000000, 1.000000)" filter="url(#filter-1)">
                                <g transform="translate(0.000000, 2.000000)">
                                    <path d="M16.2,0 C17.199,0 18,0.801 18,1.8 L18,12.6 C18,13.599 17.199,14.4 16.2,14.4 L1.8,14.4 C0.801,14.4 0,13.599 0,12.6 L0,1.8 C0,0.801 0.801,0 1.8,0 L16.2,0 M5.85,9.9 L5.85,4.5 L4.725,4.5 L4.725,7.65 L2.475,4.5 L1.35,4.5 L1.35,9.9 L2.475,9.9 L2.475,6.75 L4.77,9.9 L5.85,9.9 M10.35,5.634 L10.35,4.5 L6.75,4.5 L6.75,9.9 L10.35,9.9 L10.35,8.775 L8.1,8.775 L8.1,7.776 L10.35,7.776 L10.35,6.642 L8.1,6.642 L8.1,5.634 L10.35,5.634 M16.65,9 L16.65,4.5 L15.525,4.5 L15.525,8.55 L14.517,8.55 L14.517,5.4 L13.392,5.4 L13.392,8.55 L12.375,8.55 L12.375,4.5 L11.25,4.5 L11.25,9 C11.25,9.49705627 11.6529437,9.9 12.15,9.9 L15.75,9.9 C16.2470563,9.9 16.65,9.49705627 16.65,9 Z" id="Shape" fill="#000000" fillRule="nonzero" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
  }
  /* eslint-disable max-len */
  