import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import { LifeCycleDashboardResource } from "hyphen-lib/dist/domain/resource/report/LifeCycleResource";
import {
  isNullOrUndefined,
  isNotNullNorUndefined,
  isNotEmptyObject,
  isEmptyObject
} from "hyphen-lib/dist/lang/Objects";
import { ResultWithAnonymity } from "hyphen-lib/dist/domain/common/ResultWithAnonymity";
import { fromJS } from "immutable";
import { DimensionsDictionary } from "hyphen-lib/dist/domain/common/Dimensions";
import { Series } from "highcharts";
import { isEmpty } from "hyphen-lib/dist/lang/Arrays";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { COLORS_TO_DROP, SERIES_COLORS } from "./seriesColors";
import {
  PhaseFavorability,
  PLOT_BAND_WIDTH,
  FavorabilityBySegment,
  NO_DATA_FILTERED_COLOR,
  FavorabilityStatus,
  SINGLE_SERIES_LINE_COLOR,
  MARKER_COLOR,
  ScoreAndStatus,
  MAX_SEGMENTS_TO_DISPLAY,
  showNoDataInPreSeparationPhases,
  showNoDataInSeparationPhase,
  SegmentVotes
} from ".";

const basicSeries = fromJS({
  name: "Not Specified",
  data: [],
  zones: [],
});

export function updateSeries(
  optionsArg: any,
  favorabilityByPhase: PhaseFavorability[],
  votesPerSegments: DimensionsDictionary<number>,
  dimension?: string
) {
  const xCoordinateGenerator = new XCoordinateFactory();
  const hasDimension = isStringAndNotEmpty(dimension) && dimension !== "None";
  const noDataInPreSeparationPhases = showNoDataInPreSeparationPhases(favorabilityByPhase, dimension);
  const noDataInSeparationPhase = showNoDataInSeparationPhase(favorabilityByPhase, dimension);
  const legend = {
    enabled: hasDimension && !(noDataInPreSeparationPhases && noDataInSeparationPhase),
    align: "right",
    verticalAlign: "bottom",
    x: 0,
    labelFormatter(this: Series) {
      if (this.name.includes("null")) {
        return "Not Specified";
      }
      const nameParts = this.name.split(":");
      return `${nameParts[1]}${nameParts[2] ? nameParts[2].replace("--1", "+") : ""}`;
    },
  };
  const top10Segments = computeTop10Segments(votesPerSegments, dimension);
  const favBySegment = initializeFavorabilityBySegments(favorabilityByPhase, dimension!, top10Segments);
  const crosshair = {
    color: "black",
    dashStyle: "ShortDash",
    width: 1,
  };

  let updatedOptions = optionsArg.withMutations(
    (map: any) =>
      map.setIn(
        ["plotOptions", "series", "dataLabels", "enabled"], false)
        .set("legend", legend)
  );

  updatedOptions = updatedOptions.setIn(
    ["xAxis"],
    updatedOptions.get("xAxis").map(
      (axis: any) => axis.set("crosshair", hasDimension ? crosshair : false)
    )
  );

  let series = favorabilityByPhase.reduce(
    (seriesAccumulator: any, phaseFavorability: PhaseFavorability, phaseCounter: number) => {
      const numberOfSubphases = phaseFavorability.subPhases.length;
      const widthForSubPhase = (PLOT_BAND_WIDTH - 0.1) / numberOfSubphases;
      const isSeparationPhase = !!phaseFavorability.isSeparation;

      phaseFavorability.subPhases.forEach((subPhase: LifeCycleDashboardResource.SubPhase, subPhaseCounter: number) => {
        xCoordinateGenerator.initialize(subPhaseCounter, widthForSubPhase, phaseCounter);
        const shouldAddNull = isSeparationPhase && subPhaseCounter === 0;

        if (hasDimension) {
          computeDimensionLevelSeries(
            dimension!,
            subPhase,
            favBySegment,
            xCoordinateGenerator,
            shouldAddNull,
            phaseFavorability.name,
            phaseFavorability.isSeparation,
            top10Segments
          );

        } else {
          if (seriesAccumulator.length === 0) {
            seriesAccumulator.push(basicSeries.toJS());
          }

          addPointsToSeries(
            shouldAddNull,
            xCoordinateGenerator,
            seriesAccumulator,
            phaseFavorability.name,
            phaseFavorability.isSeparation,
            subPhase,
            noDataInPreSeparationPhases,
            noDataInSeparationPhase,
            isSeparationPhase
          );
        }
      });

      return seriesAccumulator;
    }, []);

  if (dimension && dimension !== "None") { // if a segmentBy has been selected
    if (hasNoCoordinates(favBySegment)) { // but there is no data for the selected segment
      series.push({ data: [], name: `${dimension}`});
    } else { // there is data for the selected segment
      Object.entries(favBySegment).forEach(([segmentName, coordinatesArray]: any) => {
        series.push({data: coordinatesArray, name: `${dimension}:${segmentName}`});
      });
    }
  }

  const SERIES_COLORS_SUBSET = SERIES_COLORS.filter(
    (_, indexColor) => {
      return !COLORS_TO_DROP[series.length - 1].includes(indexColor);
    });
  series.forEach((aSeries: any, index: number) => {
    addMarkerAndOtherSeriesOptions(aSeries, index, series.length, SERIES_COLORS_SUBSET);
  });

  // the below code is required for the legend to have circular symbols. Also see
  // load function for additional code related to getting circular symbols
  // reference http://jsfiddle.net/d_paul/rpq4qkd9/5/
  series = series.reduce((seriesWithMockSeries: any[], aSeries: any, index: number) => {
    const mockSeries = {
      data: [],
      id: `Mock_Series_${index}`,
      type: "column",
      marker: {
        symbol: "circle",
      },
      color: aSeries.color,
      name: aSeries.name,
    };
    aSeries.linkedTo = mockSeries.id;
    seriesWithMockSeries.push(aSeries);
    seriesWithMockSeries.push(mockSeries);
    return seriesWithMockSeries;
  }, []);
  const maxXValue = computeMaxXValue(series);

  return updatedOptions.set("series", series).withMutations(
    (map: any) => {
      if (maxXValue > -Infinity) {
        map.setIn(["xAxis", 0, "max"], maxXValue);
      }
    }).toJS();
}

function computeMaxXValue(series: any) {
  const realSeries = series.filter((aSeries: any) => aSeries.type === "line");
  const flattenedXValues = realSeries.reduce((xCollector: [], aSeries: any) => {
    return xCollector.concat(aSeries.data.map((datum: any) => datum.x));
  }, []);

  return Math.max(...flattenedXValues);
}

function initializeFavorabilityBySegments(
  favorabilityByPhaseArray: PhaseFavorability[],
  dimension: string,
  top10Segments: string[]) {
  return favorabilityByPhaseArray.reduce((favBySegmentAcc, phaseFavorability) => {
    phaseFavorability.subPhases.forEach(subPhase => {
      if (isStringAndNotEmpty(dimension) && dimension !== "None") {
        if (isNotEmptyObject(subPhase.segments) && isNotEmptyObject(subPhase.segments[dimension])) {
          Object.keys(subPhase.segments[dimension]).forEach(segment => {
            if (top10Segments.includes(segment)) {
              favBySegmentAcc[segment] = [];
            }
          });
        }
      }
    });
    return favBySegmentAcc;
  }, {} as any);
}

function hasNoCoordinates(favBySegment: FavorabilityBySegment) {
  return Object.values(favBySegment).every(points => points.length === 0);
}

class XCoordinateFactory {
  xCoordinate = 0;
  xIncrement = 1;
  constructor(xIncrement?: number, initialVal?: number) {
    if (isNotNullNorUndefined(xIncrement)) {
      this.xIncrement = xIncrement;
    }

    if (isNotNullNorUndefined(initialVal)) {
      this.xCoordinate = initialVal;
    }
  }

  initialize(subPhaseCounter: number, width: number, phaseCounter: number) {
    // this if-else block handles centering the data-points in the middle of the plotband
    if (subPhaseCounter === 0) {
      this.setIncrement(width / 2);
      this.setInitialValue(phaseCounter);
    } else {
      this.setIncrement(width);
    }
  }

  tick() {
    this.xCoordinate = this.xCoordinate + this.xIncrement;
    return this.xCoordinate;
  }

  tickBack() {
    this.xCoordinate = this.xCoordinate - this.xIncrement;
    return this.xCoordinate;
  }

  setIncrement(xIncrement: number) {
    this.xIncrement = xIncrement;
  }

  setInitialValue(initialVal: number) {
    this.xCoordinate = initialVal;
  }

  getXCoordinate() {
    return this.xCoordinate;
  }
  reset() {
    this.xCoordinate = 0;
  }
}

function computeDimensionLevelSeries(
  dimension: string,
  subPhase: LifeCycleDashboardResource.SubPhase,
  favorabilityBySegment: FavorabilityBySegment,
  xCoordinateGenerator: any,
  shouldAddNull = false,
  phaseName: string,
  isSeparation = false,
  top10Segments: string[]
) {
  if (isEmptyObject(subPhase.segments) || isNullOrUndefined(subPhase.segments[dimension])) {
    return;
  }
  const segmentFavorabilities = Object.entries(subPhase.segments[dimension]);

  segmentFavorabilities.forEach(
    (
      [segmentName, segmentFavorability]: [string, ResultWithAnonymity<LifeCycleDashboardResource.Favorability>]
    ) => {
      if (!top10Segments.includes(segmentName)) {
        return;
      }
      const y = extractFavorability(segmentFavorability);

      const pointName = constructPointName(y, phaseName, isSeparation, subPhase);
      const yValue = y.status === FavorabilityStatus.SCORE ? y.score : null;

      if (shouldAddNull) {
        favorabilityBySegment[segmentName].push({
          y: null,
          x: Number(xCoordinateGenerator.getXCoordinate()) + 0.1,
          key: `${segmentName}_${subPhase.startTenure}-${subPhase.endTenure}_nullPoint`,
          name: pointName,
          isNull: true,
        });
      }
      favorabilityBySegment[segmentName].push({
        y: yValue,
        x: Number(xCoordinateGenerator.tick().toFixed(2)),
        key: `${segmentName}_${subPhase.startTenure}-${subPhase.endTenure}`,
        name: pointName,
      });

      const numberOfCoordinatesPerSegment =
        (new Set(Object.values(favorabilityBySegment).map(points => points.length))).size;
      if (numberOfCoordinatesPerSegment > 1) {
        xCoordinateGenerator.tickBack();
      }
    });
}

function addPointsToSeries(
  shouldAddNull: boolean,
  xCoordinateGenerator: any,
  seriesAccumulator: any,
  phaseName: string,
  isSeparation = false,
  subPhase: LifeCycleDashboardResource.SubPhase,
  noDataInPreSeparationPhases: boolean,
  noDataInSeparationPhase: boolean,
  isSeparationPhase: boolean
) {

  let pointName = constructInitialPointName(isSeparation, phaseName, subPhase);
  if (shouldAddNull) {
    const nullPointXCoordinate = xCoordinateGenerator.getXCoordinate() + 0.1;
    seriesAccumulator[0].data.push({
      x: nullPointXCoordinate,
      y: null,
      name: pointName,
      isNull: true,
    });
  }

  const x = Number(xCoordinateGenerator.tick().toFixed(2));

  if (
    (noDataInPreSeparationPhases && !isSeparationPhase) ||
    (noDataInSeparationPhase && isSeparationPhase) ) {
    const coordinates = {
      x,
      y: null,
      name: pointName,
    };
    return seriesAccumulator[0].data.push(coordinates);
  }

  if (!subPhase.favorability.filteredForAnonymity && isNotNullNorUndefined(subPhase.favorability.score)) {
    pointName = `${FavorabilityStatus.SCORE}#${pointName}`;
    const coordinates = {
      x,
      y: subPhase.favorability.score,
      name: pointName,
    };
    seriesAccumulator[0].data.push(coordinates);
  }

  if (subPhase.favorability.filteredForAnonymity) {
    pointName = `${FavorabilityStatus.FILTERED}#${pointName}`;
    const coordinates = {
      x,
      y: null,
      name: pointName,
      marker: {
        enabled: true,
        fillColor: NO_DATA_FILTERED_COLOR,
      },
    };
    seriesAccumulator[0].data.push(coordinates);
  }

  if (!subPhase.favorability.filteredForAnonymity && isNullOrUndefined(subPhase.favorability.score)) {
    pointName = `${FavorabilityStatus.NO_DATA}#${pointName}`;
    const coordinates = {
      x,
      y: null,
      name: pointName,
      marker: {
        enabled: true,
        fillColor: NO_DATA_FILTERED_COLOR,
      },
    };
    seriesAccumulator[0].data.push(coordinates);
  }
}

function addMarkerAndOtherSeriesOptions(
  aSeries: Highcharts.SeriesLineOptions,
  index: number,
  numberOfSeries: number,
  SERIES_COLORS_SUBSET: string[]
) {
  aSeries.id = `Series_${index}`;
  aSeries.showInLegend = false;
  aSeries.type = "line";
  aSeries.color = SERIES_COLORS_SUBSET[index];
  aSeries.dataLabels = [{enabled: false}];
  if (numberOfSeries > 1) {
    /* these events help in visualizing which series has been selected in a multi-series when hovered over */
    aSeries.events = {
      mouseOver(this: any) {
        this.chart.series.forEach((trendLine: any) => {
          if (this.name !== trendLine.name) {
            trendLine.setState("select");
          }
        });
      },
      mouseOut(this: any) {
        this.chart.series.forEach((trendLine: any) => {
          trendLine.setState("normal");
        });
      },
    };

    aSeries.states = {hover: {lineWidth: 5}, select: {lineWidth: 1}};
  }

  if (numberOfSeries === 1) {
    aSeries.color = SINGLE_SERIES_LINE_COLOR;
    aSeries.dataLabels = [{
      enabled: true,
      formatter(this: Highcharts.PointLabelObject) {
        if (
          this.point.name.includes(FavorabilityStatus.FILTERED) ||
          this.point.name.includes(FavorabilityStatus.NO_DATA)
        ) {
          return "";
        }
        return `${this.point.y}%`;
      },
      style: {
        fontFamily: "Lato",
        color: MARKER_COLOR,
        fontSize: "14px",
      },
    }];
  }

  addMarkerPropsToEachPoint(aSeries, numberOfSeries, numberOfSeries === 1 ? MARKER_COLOR : SERIES_COLORS_SUBSET[index]);
}

function addMarkerPropsToEachPoint(
  aSeries: Highcharts.SeriesLineOptions,
  numberOfSeries = 1,
  markerColor: string = MARKER_COLOR) {
  const markerProp = {
    enabled: true,
    symbol: "circle",
    radius: 4,
    fillColor: markerColor,
    states: {
      hover: {
        fillColor: markerColor,
        radiusPlus: 4,
      },
    },
  };
  if (numberOfSeries === 1) {
    const markerPropForSingleSeries = {
      ...markerProp,
      enabled: true,
    };

    aSeries.marker = markerPropForSingleSeries;
  }

  if (numberOfSeries > 1) {
    aSeries.marker = markerProp;
    if (isNotNullNorUndefined(aSeries.data) && not(isEmpty(aSeries.data))) {
      aSeries.data.forEach((datum: any, index: number) => {
        // the if condition below identifies a connected defined point
        if (datum && datum.y !== null) {
          if (
            (isNotNullNorUndefined(aSeries.data![index + 1]) &&
            (aSeries.data![index + 1]! as any).y !== null) ||
            (isNotNullNorUndefined(aSeries.data![index - 1]) &&
            (aSeries.data![index - 1]! as any).y !== null)
          ) {
            datum.marker = {
              ...markerProp,
              enabled: true,
              radius: 4,
            };
          }
        }
      });
    }
  }
}

function extractFavorability(
  segmentFavorability: ResultWithAnonymity<LifeCycleDashboardResource.Favorability>
): ScoreAndStatus {
  if (segmentFavorability.filteredForAnonymity) {
    return { score: Optional.empty(), status: FavorabilityStatus.FILTERED };
  }

  if (
    !segmentFavorability.filteredForAnonymity && isNullOrUndefined(segmentFavorability.score!)
  ) {
    return { score: Optional.empty(), status: FavorabilityStatus.NO_DATA };
  }
  return { score: segmentFavorability.score!, status: FavorabilityStatus.SCORE };
}

function computeTop10Segments(votesPerSegments: DimensionsDictionary<number>, dimension?: string) {
  if (
    dimension === "None" ||
    isNullOrUndefined(dimension) ||
    dimension === "" ||
    isEmptyObject(votesPerSegments) ||
    isEmptyObject(votesPerSegments[dimension])
  ) {
    return [];
  }
  const countOfVotesPerSegment: {[segment: string]: number} = votesPerSegments[dimension];

  const sortableSegments: SegmentVotes[] = [];
  for (const segment in countOfVotesPerSegment) {
    if (true) {
      sortableSegments.push([segment, countOfVotesPerSegment[segment]]);
    }
  }

  sortableSegments.sort((a: SegmentVotes, b: SegmentVotes) => (b[1] - a[1]));
  return sortableSegments
    .map(([segment, voteCount], index) => index < MAX_SEGMENTS_TO_DISPLAY ? segment : null)
    .filter(isNotNullNorUndefined);
}

function constructPointName(
  y: ScoreAndStatus, phaseName: string, isSeparation: boolean, subPhase: LifeCycleDashboardResource.SubPhase ) {
  return `${y.status}#${constructInitialPointName(isSeparation, phaseName, subPhase)}`;
}

function constructInitialPointName(
  isSeparation: boolean, phaseName: string, subPhase: LifeCycleDashboardResource.SubPhase ) {
  return `${isSeparation ? "Separation" : phaseName}#\
${subPhase.startTenure}${subPhase.endTenure === -1 ? "+" : `-${subPhase.endTenure}`} months`;
}
