import {  NetworkEventRequestAction,
          NetworkEventSuccessAction,
          NetworkEventErrorAction,
          default as networkActionCreators
} from "@src/store/network/actions";
import { FilterParameter, createRequest } from "@src/utils/networks";
import { FSA } from "flux-standard-action";
import { PulsePollsDimensionsResource } from "hyphen-lib/dist/domain/resource/PulsePollsDimensionsResource";

export enum PulsePollReportsFiltersActionTypes {
  MODIFY_FILTER = "pulsePollReports/MODIFY_FILTER",
  CLEAN_FILTER = "pulsePollReports/CLEAN_FILTER",

  FETCH_DIMENSIONS_REQUEST = "FETCH_DIMENSIONS_REQUEST",
  FETCH_DIMENSIONS_SUCCESS = "FETCH_DIMENSIONS_SUCCESS",
  FETCH_DIMENSIONS_ERROR = "FETCH_DIMENSIONS_ERROR",
}

interface Payloads {
  [PulsePollReportsFiltersActionTypes.MODIFY_FILTER]: FilterParameter;
}

export interface ModifyFilterAction
  extends FSA<
  PulsePollReportsFiltersActionTypes.MODIFY_FILTER,
  Payloads[PulsePollReportsFiltersActionTypes.MODIFY_FILTER]
  > {
  type: PulsePollReportsFiltersActionTypes.MODIFY_FILTER;
  payload: Payloads[PulsePollReportsFiltersActionTypes.MODIFY_FILTER];
}

export interface CleanFilterAction
  extends FSA<PulsePollReportsFiltersActionTypes.CLEAN_FILTER, void> {
  type: PulsePollReportsFiltersActionTypes.CLEAN_FILTER;
}


export function fetchPulsePollDimensionsInfoIfNeeded(templateId: string) {
  return networkActionCreators.fetchElementIfNeeded({
    id: PulsePollsDimensionsResource.generateId(templateId),
    type: PulsePollsDimensionsResource.TYPE,
    request: createRequest("/pulsepolls/reports/info/dimensions/" + templateId),
    callbackActions: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: PulsePollReportsFiltersActionTypes.FETCH_DIMENSIONS_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: PulsePollReportsFiltersActionTypes.FETCH_DIMENSIONS_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => 
          ({ type: PulsePollReportsFiltersActionTypes.FETCH_DIMENSIONS_ERROR, payload }),
      ],
  }
  });
}

export const pulsePollReportsFiltersActionCreators = {
  onModifyFilter: (
    payload: ModifyFilterAction["payload"]
  ): ModifyFilterAction => ({
    type: PulsePollReportsFiltersActionTypes.MODIFY_FILTER,
    payload,
  }),
  onCleanFilter: (): CleanFilterAction => ({
    type: PulsePollReportsFiltersActionTypes.CLEAN_FILTER,
  }),
  fetchPulsePollDimensionsInfo: (templateId: string) => {
      return fetchPulsePollDimensionsInfoIfNeeded(templateId);
    }
};
