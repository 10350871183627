import React from "react";
// eslint-disable-next-line max-len
import { BatarangScriptDefinitionsResource } from "hyphen-lib/dist/domain/resource/batarang/BatarangScriptDefinitionsResource";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { BatarangDryRunResultsResource } from "hyphen-lib/dist/domain/resource/batarang/BatarangDryRunResultsResource";
import { BatarangApplyResultsResource } from "hyphen-lib/dist/domain/resource/batarang/BatarangApplyResultsResource";
import { BatarangApplyToken } from "hyphen-lib/dist/domain/resource/batarang/BatarangApplyToken";
import { Loading } from "@screens/Insights/Survey/components/Loading";
import styled from "styled-components";
import Select from "@components/core/Select";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { BatarangScriptComponent } from "@screens/Insights/Settings/components/batarang/BatarangScriptComponent";
import ContainerCard from "@components/core/ContainerCard";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import { AssetUpload } from "../../containers/batarang/store/batarangScriptsReducers";
import { Trans } from "react-i18next";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";

const { Option } = Select;

export interface BatarangScriptsComponentProps {
  readonly definitions: Loadable<BatarangScriptDefinitionsResource>;
  readonly scriptName: Optional<string>;
  readonly values: Dictionary<any>;
  readonly dryRunExecutionResults: Optional<Loadable<BatarangDryRunResultsResource>>;
  readonly executionResults: Optional<Loadable<BatarangApplyResultsResource>>;
  readonly error?: Optional<string> | Optional<WrongEntityException.GlobalError>;
  readonly assetUpload?: Optional<AssetUpload>;
  readonly onScriptChange: (scriptName: string) => any;
  readonly onParametersChange: (parameters: Dictionary<any>) => any;
  readonly onAssetUpload: (file: File) => any;
  readonly onExecuteDryRun: (scriptName: string, parameters: Dictionary<any>) => any;
  readonly onExecute: (scriptName: string, parameters: Dictionary<any>, applyToken: BatarangApplyToken) => any;
  readonly onCancel: () => any;
  readonly onFinish: () => any;
  readonly isUserSyncEnabled?: boolean;
  readonly company?: Optional<CompanyResource>;
}

export class BatarangScriptsComponent extends React.Component<BatarangScriptsComponentProps> {

  handleExecuteDryRun = (parameters: Dictionary<any>) => {
    const {
      scriptName,
      onExecuteDryRun,
    } = this.props;

    if (isNotNullNorUndefined(scriptName)) {
      onExecuteDryRun(scriptName, parameters);
    }
  };

  handleExecute = (parameters: Dictionary<any>, applyToken: BatarangApplyToken) => {
    const {
      scriptName,
      onExecute,
    } = this.props;

    if (isNotNullNorUndefined(scriptName)) {
      onExecute(scriptName, parameters, applyToken);
    }
  };

  render() {
    const {
      definitions,
      scriptName,
      values,
      dryRunExecutionResults,
      executionResults,
      error,
      assetUpload,
      onScriptChange,
      onParametersChange,
      onCancel,
      onFinish,
      onAssetUpload,
      isUserSyncEnabled,
      company
    } = this.props;

    if (Loadable.isNotLoaded(definitions)) {
      return (
        <ContainerCard title="Batarang scripts">
          <BatarangIntroduction/>
          <Loading/>
        </ContainerCard>
      );
    }
    const selectedDefinition = Optional.map(
      scriptName,
      name => definitions.value.scripts.find(def => def.name === name)
    );

    return (
      <ContainerCard title="Batarang scripts">
        <BatarangIntroduction/>
        <Select
          placeholder={<Trans>Choose a script to execute</Trans>}
          onChange={onScriptChange as any}
          value={scriptName as string}
          showSearch
        >
          {
            definitions.value.scripts.map(definition =>
              <Option
                key={definition.name}
                value={definition.name}
                disabled={isUserSyncEnabled && definition.name === "modifyEmailsBatarangScript"}
              >
                {definition.name}: {definition.description}
              </Option>
            )
          }
        </Select>
        {
          isNotNullNorUndefined(selectedDefinition) &&
            <BatarangScriptComponent
              definition={selectedDefinition}
              values={values}
              context={definitions.value.context}
              dryRunExecutionResults={dryRunExecutionResults}
              executionResults={executionResults}
              error={error}
              assetUpload={assetUpload}
              onParametersChange={onParametersChange}
              onExecuteDryRun={this.handleExecuteDryRun}
              onExecute={this.handleExecute}
              onCancel={onCancel}
              onFinish={onFinish}
              onAssetUpload={onAssetUpload}
              company={company}
            />
        }
      </ContainerCard>
    );
  }
}

const Introduction = styled.div`
  font-style: italic;
  font-weight: lighter;
  margin: 20px 0;
`;

function BatarangIntroduction() {
  return (
    <Introduction>
      <Trans>I'm happy to lend you my batarang weapon.</Trans><br/>
      <Trans>Don't forget that with great power comes big responsibility.</Trans><br/>
      <Trans>If anything seems to not be accurate when running a function in dry run,
      please reach me on the #engage_batman channel on slack.</Trans>
      <br/>
      <br/>
      -- <Trans>your</Trans> 🦇 <Trans>with</Trans> ❤️
    </Introduction>
  );
}
