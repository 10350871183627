import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import {
  getOr,
} from "hyphen-lib/dist/lang/Objects";
import { Trans } from "react-i18next";
import { State } from "@store/types";
import { ScreenContainer } from "@screens/Insights/components/ScreenContainer";
import Button from "@components/core/Button";

import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { Breadcrumb, goTo } from "@src/utils/locations";
import { checkForActionPlanCreationRight } from "@src/utils/rights";
import Tabs from "@src/components/core/Tabs";
import ActionListContainer from "./containers/ActionListContainer/ActionListContainer";
import FocusAreasContainer from "./containers/FocusAreasContainer/FocusAreasContainer";
import Palette from "@src/config/theme/palette";
import FeatureIntro, { FeatureIntroChildren } from "../components/FeatureIntro/FeatureIntro";
import { Apps } from "hyphen-lib/dist/domain/apps/App";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import AreYouSureModal from "@src/components/core/AreYouSureModal";

const { TabPane } = Tabs;

enum ACTION_ROUTES {
  ACTION_CENTER = "/actioncenter",
  ACTIONS_LIST = "/actioncenter/actions",
  FOCUS_AREAS = "/actioncenter/focusareas",
}

const KEY_BY_PATH_NAME: Dictionary<string> = {
  "actioncenter": "action-center",
  "/actioncenter/actions": "actions-list",
  "/actioncenter/focusareas": "focus-areas",
};

interface ActionCenterProps {
  canCreateActionPlan: boolean;
}
export class ActionCenter extends React.Component<ActionCenterProps & RouteComponentProps> {

  // noinspection JSMethodCanBeStatic
  onCreateAction() {
    goTo("/actioncenter/actions/create", Breadcrumb.stack("ActionPlans"));
  }

  onTabClick = (key: string, event: any) => {
    const { history } = this.props;
    if (key === KEY_BY_PATH_NAME[ACTION_ROUTES.ACTIONS_LIST]) {
      history.push(ACTION_ROUTES.ACTIONS_LIST);
    } else if (key === KEY_BY_PATH_NAME[ACTION_ROUTES.FOCUS_AREAS]) {
      history.push(ACTION_ROUTES.FOCUS_AREAS);
    } else if (key === KEY_BY_PATH_NAME[ACTION_ROUTES.ACTION_CENTER]) {
      history.push(ACTION_ROUTES.ACTION_CENTER);
    }
  };

  getActiveKey = (path: string) => {
    return getOr(KEY_BY_PATH_NAME[path], KEY_BY_PATH_NAME[ACTION_ROUTES.FOCUS_AREAS]);
  };

  render() {
    const {
      canCreateActionPlan,
      location,
    } = this.props;
    const activeKey = this.getActiveKey(location.pathname);
    return (
      <ScreenContainer
        title="Action Center"
        actions={[
          canCreateActionPlan && (
            <Button
              key="create"
              color={Palette.bluishGrey}
              onClick={this.onCreateAction}
              data-cy="btn_create_action_plan"
              translate="yes"
            >
              Create Action Plan
            </Button>
          )
        ]}
      >
         <FeatureIntro data-cy="actionCenter" page={Apps.Pages.INSIGHTS_ACTION_CENTER}>
          {({ handleCloseNotification, notificationMessage, notificationTitle }: FeatureIntroChildren) => (
            <>
              {  isStringAndNotEmpty(notificationMessage) && isStringAndNotEmpty(notificationTitle) && (
                <AreYouSureModal 
                  hideCancelButton
                  okLabel="I understand"
                  description={notificationMessage}
                  width={650}
                  title={notificationTitle}
                  visible
                  onOk={handleCloseNotification}
                  onCancel={handleCloseNotification}
                />
              )}
              <Tabs
                onTabClick={this.onTabClick}
                defaultActiveKey={KEY_BY_PATH_NAME[ACTION_ROUTES.ACTION_CENTER]}
                activeKey={activeKey}
              >
                <TabPane tab={<Trans>Focus Areas</Trans>} key={KEY_BY_PATH_NAME[ACTION_ROUTES.FOCUS_AREAS]}>
                  <FocusAreasContainer />
                </TabPane>
                <TabPane tab={<Trans>Action Plans</Trans>} key={KEY_BY_PATH_NAME[ACTION_ROUTES.ACTIONS_LIST]}>
                  <ActionListContainer />
                </TabPane>
              </Tabs>
            </>)}
        </FeatureIntro>
      </ScreenContainer>
    );

     
  }
}

function mapStateToProps(state: State): ActionCenterProps {
  return {
    canCreateActionPlan: checkForActionPlanCreationRight(state),
  };
}

export default withRouter(
  connect(mapStateToProps)(ActionCenter)
);