import React from "react";
import styled from "styled-components";

import { Sentiment } from "@hyphen-lib/domain/common/ComputationTypes";
import { CommentsResultResource } from "@hyphen-lib/domain/resource/report/CommentsResultResource";

import {
  CellContainer,
  CellInnerContainer
} from "@screens/Insights/SurveyList/components/cells/Cells";
import { DynamicRowProps } from "@components/core/DynamicRowsTable/withDynamicRows";
import { SentimentTag } from "@components/core/SentimentTag";

interface SentimentCellProps {
  sentiment?: Sentiment;
}

export function SentimentCell(props: DynamicRowProps<CommentsResultResource> & DynamicRowProps<SentimentCellProps>) {
  return (
    <CellContainer align="flex-start" justifyContent="flex-start">
      <CellInnerContainer padding={[24, 0]} width="100%">
        {props.row.sentiment !== undefined && (
          <SentimentTagContainer>
            <SentimentTag sentiment={props.row.sentiment} />
          </SentimentTagContainer>
        )}
      </CellInnerContainer>
    </CellContainer>
  );
}

const SentimentTagContainer = styled.div`
  width: 88px;
  display: inline-block;
  margin-right: 16px;
`;
