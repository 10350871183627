import { call, put, take, takeEvery, takeLatest } from "redux-saga/effects";
import pluralize from "pluralize";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { freeze } from "hyphen-lib/dist/lang/Objects";
import {
  actionCreators as networkActionCreators,
  CleanResourceAction,
  NetworkEventSuccessAction,
  NetworkRequestAction
} from "@store/network/actions";
import notificationActionCreators, { ShowNotificationAction } from "@store/notifications/actions";
import * as NotificationFactory from "@src/store/notifications/notification-factory";
import { UserResource } from "hyphen-lib/dist/domain/resource/user/UserResource";
import { createUploadFileChannel } from "@src/utils/networks";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";
import {
  AddUsersManuallyRequestAction,
  UploadUsersCsvAction,
  usersAndDimensionsActionCreators,
  UsersAndDimensionsActionTypes
} from "./actions";
import i18next, { t } from "i18next";

export function* addUsersManually({ payload }: AddUsersManuallyRequestAction) {
  yield put<NetworkRequestAction>(
    usersAndDimensionsActionCreators.addUsersManuallyRequest(payload)
  );
}

function* onUsersAddedSuccessfully({ payload, meta }: NetworkEventSuccessAction) {
  const { dryRun } = meta;
  if (not(dryRun)) {
    const { data } = payload;
    const addedUserCount = Object.keys(data).length;
    const translateKey = `${pluralize("user", addedUserCount)} added`;
    yield put<ShowNotificationAction>(
      notificationActionCreators.displayNotification(
        NotificationFactory.success(
          `${addedUserCount} ${i18next.exists(translateKey) ? t(translateKey) : translateKey}`,
          "",
          4.5
        )
    ));
    yield put<CleanResourceAction>
    (networkActionCreators.cleanResource(UserResource.TYPE));
  }
}

function* onUsersAddError() {
  yield put<ShowNotificationAction>(
    notificationActionCreators.displayNotification(
      NotificationFactory.error(
        "Please check your internet connection and try again",
        "",
        4.5
      )
  ));
}

export function* uploadUsersCsvSaga({payload: {file}}: UploadUsersCsvAction) {
  yield put({
    type: UsersAndDimensionsActionTypes.UPLOAD_USERS_CSV_REQUEST,
    payload: {fileName: file.name},
  });
  const channel = yield call(createUploadFileChannel, "/users/uploads", file);
  while (true) {
    const { progress = 0, err, success } = yield take(channel);
    if (err) {
      yield put({
        type: UsersAndDimensionsActionTypes.UPLOAD_USERS_CSV_ERROR,
        payload: {fileName: file.name},
      });
      return;
    }
    if (success) {
      yield put({
        type: UsersAndDimensionsActionTypes.UPLOAD_USERS_CSV_SUCCESS,
        payload: {fileName: file.name},
      });
      return;
    }
    yield put({
      type: UsersAndDimensionsActionTypes.UPLOAD_USERS_CSV_PROGRESS,
      payload: {progress, fileName: file.name},
    });
  }
}

function* updateSuccessHandler({ meta }: NetworkEventSuccessAction) {
  yield put<CleanResourceAction>(networkActionCreators.cleanResource(CurrentUserResource.TYPE));
}

export const usersAndDimensionsSagas = freeze([
  /* All sagas go here */

  takeLatest(
    UsersAndDimensionsActionTypes.UPLOAD_USERS_CSV,
    uploadUsersCsvSaga
  ),

  takeLatest(
    UsersAndDimensionsActionTypes.ADD_USERS_MANUALLY,
    addUsersManually
  ),

  takeEvery(
    UsersAndDimensionsActionTypes.ADD_USERS_MANUALLY_SUCCESS,
    onUsersAddedSuccessfully
  ),
  takeEvery(
    UsersAndDimensionsActionTypes.ADD_USERS_MANUALLY_ERROR,
    onUsersAddError
  ),
  takeLatest(
    UsersAndDimensionsActionTypes.UPDATE_DIMENSIONS_SUCCESS,
    updateSuccessHandler
  ),
]);
