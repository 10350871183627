import React from "react";
import Icon, { IconProps } from "antd/lib/icon";
import styled from "styled-components";

import Palette from "@src/config/theme/palette";

import { TooltipProps } from "antd/lib/tooltip";
import Button, { ButtonProps } from "../Button";
import Tooltip from "../Tooltip";

export interface IconButtonProps extends ButtonProps {
  readonly tooltipProps: TooltipProps;
  readonly svgIcon: IconProps["component"];
}

export function IconButton({
  tooltipProps,
  svgIcon,
  ...rest
}: IconButtonProps) {
  return (
    <Container>
      <Tooltip arrowPointAtCenter={true} {...tooltipProps}>
        <Button data-cy="icon-button" data-jest="icon-button" color="blue" {...rest} translate="yes">
          <Icon component={svgIcon} />
        </Button>
      </Tooltip>
    </Container>
  );
}
const Container = styled.div`
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  left: -16px;
  transform: translateY(-50%);
  background: ${Palette.primary};
  color: ${Palette.white};
  line-height: 32px;
  text-align: center;
  border-radius: 4px;
  z-index: 4;

  :hover {
    cursor: pointer;
  }

  i {
    z-index: 0 !important;
  }

  button {
    height: 100% !important;
    width: 100%;
    padding: 0;
    border-radius: 4px !important;
  }
`;

Container.displayName = "IconButtonContainer";
