import React, { ChangeEvent } from "react";
import styled from "styled-components";
import qs from "qs";
import { RouteComponentProps, withRouter } from "react-router";
import { debounce } from "lodash";
import { fromJS } from "immutable";

import { addPropertyInDepth, getProperty } from "@hyphen-lib/lang/Objects";
import { ExpandButton } from "@components/core/ExpandButton";
import { SearchBar } from "@components/core/SearchBar";
import Palette from "@src/config/theme/palette";
import { QueryParams } from "@src/screens/Insights/components/Reports/Filters";
import { CustomizationPopover } from "@screens/Insights/components/CustomizationPopover";

export type OwnProps = RouteComponentProps;

interface SearchBarSectionProps {
  placeholder?: string;
  queryParamPath?: string;
  viewOptionsComponent?: React.ReactNode;
}

type Props = OwnProps & SearchBarSectionProps;

interface SearchBarSectionState {
  readonly areViewOptionsVisible: boolean;
}

export class SearchBarSection extends React.Component<Props, SearchBarSectionState> {
  static defaultProps = {
    placeholder: "Search",
    queryParamPath: "filter.freeText",
  };

  state = {
    areViewOptionsVisible: false,
  };

  updateQueryParams = debounce((param: any) => {
    const queryParams = fromJS(this.getQueryParams()).mergeDeep(param).toJS();

    const { history } = this.props;
    const queryString = qs.stringify(queryParams, { encode: false });

    history.replace({
      search: "?" + queryString,
    });
  }, 500);

  getQueryParams = (): QueryParams => {
    const { location } = this.props;
    return qs.parse(location.search, { ignoreQueryPrefix: true });
  };

  handleSearchBarChange = (e: ChangeEvent<HTMLInputElement>) => {
    const param = addPropertyInDepth(
      {},
      e.target.value,
      this.props.queryParamPath || SearchBarSection.defaultProps.queryParamPath
    );
    this.updateQueryParams(param);
  };

  handleViewOptionsClick = () => {
    this.setState(state => ({ areViewOptionsVisible: !state.areViewOptionsVisible }));
  };

  getFreeTextFilterValue = (): string | undefined => {
    const queryParams = this.getQueryParams();
    const paramPath = this.props.queryParamPath ? this.props.queryParamPath : "";
    return getProperty(queryParams, paramPath, "");
  };

  render() {
    const { viewOptionsComponent } = this.props;
    const { areViewOptionsVisible } = this.state;

    return (
      <Container>
        <SearchBar
          onChange={this.handleSearchBarChange}
          placeholder={this.props.placeholder}
          defaultValue={this.getFreeTextFilterValue()}
        />
        {
          viewOptionsComponent && areViewOptionsVisible &&
          <>
            <ActionButtons>
              <ExpandButton icon="viewOptions" onClick={this.handleViewOptionsClick} translate="yes">
                View options
              </ExpandButton>
            </ActionButtons>
            <Backdrop onClick={this.handleViewOptionsClick}/>
            <CustomizationPopover open={areViewOptionsVisible}>
              {viewOptionsComponent}
            </CustomizationPopover>
          </>
        }
      </Container>
    );
  }
}

const Container = styled.div`
  padding: 24px 32px;
  background: ${Palette.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;

  @media print {
    visibility: hidden;
    width: 0px !important;
    min-width: 0px !important;
    max-width: 0px !important;
    height: 0px !important;
    min-height: 0px !important;
    max-height: 0px !important;
  }
`;

const ActionButtons = styled.div`
  margin-left: 16px;
`;

const Backdrop = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
`;

export default withRouter(SearchBarSection);
