import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Dimensions, getSegmentLabel } from "hyphen-lib/dist/domain/common/Dimensions";
import { getOr, isNotNullNorUndefined, isNullOrUndefined } from "hyphen-lib/dist/lang/Objects";
import { Seq } from "immutable";
import { sanitizeSegmentLabel } from "hyphen-lib/dist/domain/common/Dimensions";

export const DYNAMIC_TENURE_DIMENSION = "dynamicTenure";
export const DYNAMIC_SEPARATION_DIMENSION = "dynamicSeparation";

function capitalizeFirstLetter(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function getDimensionLabel(dimension: string, dimensions: Optional<Dimensions>): string {
  const defaultDimensionLabel = capitalizeFirstLetter(sanitizeDimensionLabel(dimension));
  if (!dimensions || !dimensions.hasOwnProperty(dimension)) {
    return defaultDimensionLabel;
  }
  return getOr(sanitizeDimensionLabel(dimensions[dimension].label), defaultDimensionLabel);
}

export function sanitizeDimensionLabel(label: string) {
  switch (label) {
    case "dynamicTenure":
      return "Tenure (Calculated)";
    case "dynamicSeparation":
      return "Separation (Calculated)";
    default:
      return label;
  }
}

export function sanitizeDimensionKey(key: string) {
  switch (key) {
    case "dynamicTenure":
      return "tenure";
    case "dynamicSeparation":
      return "separation";
    default:
      return key;
  }
}

export function sanitizeDimensions(dimensions: Optional<Dimensions>) {
  if (isNullOrUndefined(dimensions)) {
    return dimensions;
  }
  // fixme: we should not change the label here, otherwise we can not change them in db anymore.
  sanitizeDynamicDimension(dimensions, "dynamicTenure", sanitizeDimensionLabel("dynamicTenure"));
  sanitizeDynamicDimension(dimensions, "dynamicSeparation", sanitizeDimensionLabel("dynamicSeparation"));
  return dimensions;
}

function sanitizeDynamicDimension(dimensions: Dimensions,
  dimensionKey: string,
  dimensionLabel: string): void {
  const dimension = dimensions[dimensionKey];
  if (isNotNullNorUndefined(dimension)) {
    dimension.label = dimensionLabel;
    const startDynamicDimensionRegex = /[0-9]+/;
    dimension.segments = Seq(dimension.segments)
      .map(segment => getSegmentLabel(segment))
      .sort((sA: string, sB: string) => {
        if ((startDynamicDimensionRegex.exec(sA)) && (startDynamicDimensionRegex.exec(sB))) {
          const segmentAStartTenure = parseInt(startDynamicDimensionRegex.exec(sA)![0], 10);
          const segmentBStartTenure = parseInt(startDynamicDimensionRegex.exec(sB)![0], 10);
          return segmentAStartTenure - segmentBStartTenure;
        }
        return 0;
      })
      .toArray();
  }
}

// fixme: we probably want to put this helper function in hyphen-lib?!
export function getDimensionSegmentLabel(dimension: string, segment: string): string {
  if (dimension === DYNAMIC_TENURE_DIMENSION || dimension === DYNAMIC_SEPARATION_DIMENSION) {
    return getSegmentLabel(sanitizeSegmentLabel(segment));
  }
  return getSegmentLabel(segment);
}


