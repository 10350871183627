import React from "react";
import styled from "styled-components";

import { DynamicRowProps } from "@components/core/DynamicRowsTable/withDynamicRows";

import Palette from "@src/config/theme/palette";
import {
  CellContainer,
  CellInnerContainer
} from "@screens/Insights/SurveyList/components/cells/Cells";
import { Trans } from "react-i18next";
import pluralize from "pluralize";

interface TopicCellProps {
  name: string;
  totalQuestionsLinked: number;
  totalCategoriesLinked: number;
}

export function TopicCell({ row, index }: DynamicRowProps<TopicCellProps>) {
  return (
    <CellInnerContainer padding={[20, 0]} width="100%">
      <CellContainer data-jest={`TopicText_${index}`} align="flex-start" justifyContent="flex-start">
          <Topic >{row.name}</Topic>
      </CellContainer>
      <div data-jest={`TopicQuestionsAndCategories_${index}`} >
        <BelowPartLabel>
          {row.totalQuestionsLinked} <Trans>{pluralize("question", row.totalQuestionsLinked)}</Trans>
        </BelowPartLabel> 
        <Dot>•</Dot>  
        <BelowPartLabel>
          {row.totalCategoriesLinked} <Trans>{pluralize("category", row.totalCategoriesLinked)}</Trans>
        </BelowPartLabel>
      </div>
    </CellInnerContainer>
  );
}


export const QuestionLabel = styled.div`
  font-family: Lato;
  font-size: 14px;
  line-height: 1.43;
  margin-top: 4px;
  color: ${Palette.veryDarkBlueGrey};
`;

const Topic = styled.span`
  padding-right: 32px;
  font-family: Lato;
  font-size: 14px;
  margin-top: 10px;
  color: ${Palette.veryDarkBlueGrey};
`;


const Dot = styled.span`
  color: ${Palette.bluishGrey};
  font-family: Lato;
  font-size: 14px;
  padding-right: 10px;
  padding-left: 10px;
`;
export const BelowPartLabel = styled.span`
  font-family: Lato;
  font-size: 14px;
  color: ${Palette.bluishGrey};
`;