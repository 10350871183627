import React from "react";
import styled from "styled-components";
import Button from "@components/core/Button";
import { Heading } from "@components/core/Typography";
import Palette from "@src/config/theme/palette";
import { StatusTag } from "@components/core/StatusTag";
import { Survey } from "@hyphen-lib/domain/Survey";
import { Link } from "react-router-dom";
import { isNotEmptyObject, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { capitalizeFirstLetter } from "@src/utils/helper";
import { Trans } from "react-i18next";
import { Tag, TagProps } from "antdv4";

interface Props {
  title: string;
  withActions?: boolean;
  status?: Survey.Status;
  surveyId?: string;
  style?: React.CSSProperties;
  toggleModal?: () => void;
  subHeader?: string;
  customTag?: TagProps;
}

const Navbar = ({ withActions = true, ...props }: Props) => {
  const { title, subHeader, toggleModal, style, surveyId, status, customTag } = props;
  return (<NavbarContainer style={style}>
    <TitleContainer>
      <Heading size="xlarge" data-text={title}>
        <StyledSpan>{ capitalizeFirstLetter(subHeader) } - </StyledSpan> {title}
      </Heading>
      {isNotNullNorUndefined(status) &&
      <StatusTagContainer>
        <StatusTag status={status} />
      </StatusTagContainer>}
      {isNotEmptyObject(customTag) &&
      <CustomTag color={customTag.color as string} icon={customTag.icon}>
        <Trans>{customTag.title}</Trans>
      </CustomTag>}
    </TitleContainer>
    {withActions && (
      <NavbarActions className="block--print-hide">
        <Link to={`/surveys/edit/${surveyId}/settings`}>
          <Button color="grey" style={{marginRight: "1em"}} data-cy="surveyReport_editButton" translate="yes">
            Edit Survey</Button>
        </Link>
        { (status !== "closed") &&

          <Button color="blue" onClick={toggleModal} data-cy="surveyReport_closeButton" translate="yes">
            Close Survey</Button>
        }
      </NavbarActions>
    )}
  </NavbarContainer>);
};

const StatusTagContainer = styled.div`
  width: 87px;
  margin-left: 24px;
  margin-top: 6px;
`;

const StyledSpan = styled.span`
  display: none;
  @media print {
    display: inline-block;
  }
`;

const NavbarContainer = styled.div`
  background: transparent;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${Palette.darkBlueGrey};
  justify-content: space-between;
  padding: 0;
  font-size: 16px;
  line-height: initial;
  margin-top: 7px;

  p {
    color: ${Palette.darkBlueGrey};
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  max-width: 100%;
  position: relative;

  > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;

    &:hover::before {
      content: attr(data-text);
      display: block;
      visibility: hidden;
      position: absolute;
      color: white;
      z-index: 2;
      white-space: normal;
      padding: 5px;
      top: 100%;
      font-size: 14px;
      background: ${Palette.darkBlueGrey};
      border-radius: 2px;
      animation: showContent 0.1s ease 2s infinite;
    }

    @keyframes showContent {
      to {
        visibility: visible;
      }
    }
  }
`;

const NavbarActions = styled.div`
  display: flex;
  align-items: center;
  > button {
    border-radius: 2px !important;
    font-weight: bold !important;
  }
`;

const CustomTag = styled(Tag)`
  padding: 5px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  span {
    font-size: 16px;
    padding-right: 5px;
    color: ${Palette.darkDarkDarkBlueGrey};
  }
`;

export default Navbar;
