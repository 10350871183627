import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { Rights } from "hyphen-lib/dist/business/auth/Rights";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { CompanyRoleResourceMap } from "../store/reducers";

export function getDependentRight(
  selectedRole: CompanyRoleResourceMap,
  options?: Dictionary<any>
): { right?: string; status: boolean } | undefined {
  if (isNotNullNorUndefined(options) && isNotNullNorUndefined(selectedRole.get("rights"))) {
    if (not(hasInsightsAccess(selectedRole, options))) {
      return {
        right: "General -> Engage Insights",
        status: false,
      };
    } if ("all" in options && isAllRightsEnabled(selectedRole, options)) {
      return {
        status: isAllRightsEnabled(selectedRole, options),
      };
    }
  }
  return;
}

function hasInsightsAccess(
  selectedRole: CompanyRoleResourceMap,
  options: Dictionary<any>
) {
  if (not("dependentRight" in options)) {
    return true;
  } else if (
    options.dependentRight === Rights.Insights.ACCESS
    && selectedRole.get("rights").includes(Rights.Insights.ACCESS)
  ) {
    return true;
  }
  return false;
}

export function isAllRightsEnabled(selectedRole: CompanyRoleResourceMap, options: Dictionary<string>) {
  let isAllEnabled = false;
  const { all } = options;
  if (Array.isArray(all)) {
    all.forEach(right => {
      if (selectedRole.get("rights").includes(right)) {
        isAllEnabled = true;
      }
    });
  } else {
    isAllEnabled = selectedRole.get("rights").includes(all);
  }
  return isAllEnabled;
}

export function isRightEnabled(
  right: string,
  selectedRole: CompanyRoleResourceMap,
  options?: Dictionary<any>
): boolean {
  if (isNotNullNorUndefined(selectedRole.get("rights"))) {
    if (isNotNullNorUndefined(options)) {
      if (not(hasInsightsAccess(selectedRole, options))) {
        return false;
      }
      if (isAllRightsEnabled(selectedRole, options)) {
        return true;
      }
    }
    if (selectedRole.get("rights").includes(right)) {
      return true;
    }
  }
  return false;
}
