import React from "react";
import styled from "styled-components";
import { Trans } from "react-i18next";
import { OverviewContainer } from "@src/screens/Insights/components/Reports/SentimentOverview";
import { SurveyTopicCommentResource } from "hyphen-lib/dist/domain/resource/survey/report/SurveyTopicCommentResource";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { Heading } from "@src/components/core/Typography";
import useToggle from "@src/utils/hooks/useToggle";
import { HelpText } from "@src/screens/Insights/components/Reports/SentimentDistribution";
import { LoadingContainer } from "../../../Loading";
import Spin from "@src/components/core/Spin";
import NoResult from "@src/components/core/NoResult";

export interface Props {
    readonly overviewReport: Loadable<SurveyTopicCommentResource>;
}

function TopicsOverview({ overviewReport }: Props) {
    const [isOverviewExpanded, toggleOverviewExpanded] = useToggle(false);
    return (
        <OverviewContainer>
            <Heading size="large" translate="yes">Topics overview</Heading>
            {
                Loadable.isNotLoaded(overviewReport) ? 
                    <LoadingContainer><Spin/></LoadingContainer> :
                <>
                    { overviewReport.value.totalAvailableComments > 0 ?
                    <>
                     <div className="py-1" data-jest = "totalComments">
                        {/* eslint-disable max-len */
                            `Results based on ${overviewReport.value.totalProcessedComments} out of ${overviewReport.value.totalAvailableComments} comments`
                        /* eslint-disable max-len */}
                    </div> 
                    <OverflowContent className={isOverviewExpanded ? "" : "elipsis"}>
                    <div><Trans>
                        Topics is a grouping algorithm that organizes the comments provided in response to survey
                        questions into sets that refer to similar subjects. This is intended to help you get a better
                        sense of what your employees are saying. There is a pre-defined set of Topics. Each comment
                        is analyzed by an AI engine, and is associated with at most one topic only. 
                    </Trans>
                    { isOverviewExpanded && <a className="px-1" 
                        onClick={() => toggleOverviewExpanded(false)}>See less</a> }
                    </div>
                    { !isOverviewExpanded &&  <a className="px-1" 
                        onClick={() => toggleOverviewExpanded(true)}>See more</a> }
                    </OverflowContent>
                  </> : 
                  <NoResult data-cy="noCommentsLabel" type="minimal" description="No comments" />
            }
                </>
            }
            
        </OverviewContainer> 
    );
}

export default TopicsOverview;

const OverflowContent = styled(HelpText)`
  &.elipsis {
    display: flex;
    >div {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: calc(100% - 65px);
      overflow-wrap: break-word;
    }
  }
`;