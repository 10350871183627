import React from "react";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { isNullOrUndefined, getOr } from "hyphen-lib/dist/lang/Objects";
import { Trans } from "react-i18next";

type ScoreSizeType = "large" | "small" | "xl";
type ScoreFormatType = "sentiment" | "favorability" | "nps";
type ScoreCssClassType = "positive" | "negative" | "neutral";

interface ScoreProps {
  score: Optional<number>;
  size?: ScoreSizeType;
  format: ScoreFormatType;
  percentage?: boolean;
}

export function Score({ score, size = "large", format, percentage = true }: ScoreProps) {
  if (isNullOrUndefined(score)) {
    return null;
  }

  return (
    <ScoreText
      size={size} className={getClassNameForScoreText(score, format)}
      data-cy="pollScore">
      <Trans>{getScoreFromFormat(score, format, percentage)}</Trans>
    </ScoreText>
  );
}

function getScoreFromFormat(
  score: number,
  format: ScoreProps["format"],
  percentage: boolean
): string {
  let sentiment = "";
  if (format === "sentiment") {
    sentiment = getSentimentTextFromScore(score);
  } else {
    sentiment = percentage ? `${score}%` :  `${score}`;
  }

  return sentiment;
}

function getNormalizedScore(score: number) {
  // normalize the netSentimentScore range from the [-100, 100] to [1, 5]
  return Math.min(Math.floor((score + 100) / 40) + 1, 5);
}

function getSentimentTextFromScore(score: number) {
  const sentimentScoreMap: any = {
    1: "Mostly Negative",
    2: "Negative",
    3: "Neutral",
    4: "Positive",
    5: "Mostly Positive",
  };

  const normalizedScore = getNormalizedScore(score);
  return sentimentScoreMap[normalizedScore] || `Unknown: ${normalizedScore}`;
}

export function getClassNameForScoreText(
  score: number,
  format: ScoreFormatType
): ScoreCssClassType {
  if (format === "sentiment") {
    const normalizedScore = getNormalizedScore(score);
    if (normalizedScore > 3 && normalizedScore < 6) {
      return "positive";
    }
    if (normalizedScore > 0 && normalizedScore < 3) {
      return "negative";
    }
    return "neutral";
  } else if (format === "favorability") {
    if (score < 40) {
      return "negative";
    }
    if (score < 60) {
      return "neutral";
    }

    return "positive";
  } else if (format === "nps") {
    if (score < 0) {
      return "negative";
    }
    if (score < 5) {
      return "neutral";
    }
    return "positive";
  }
  return "neutral";
}

const fontSize: any = {
  small: 16,
  xl: 44,
};

const ScoreText = styled.span<{ size: ScoreSizeType }>`
  font-family: Lato;
  font-size: ${props => getOr(fontSize[props.size], 32)}px;
  color: ${Palette.lightGreyBlue};
  font-weight: bold;

  &.positive {
    color: ${Palette.aquaBlue};
  }

  &.negative {
    color: ${Palette.darkPink};
  }
`;
