import React from "react";
import styled from "styled-components";
import { AnonymityFilterExplanation } from "hyphen-lib/dist/domain/common/AnonymityFilterExplanation";
import { AnonymityFiltered } from "@src/components/core/AnonymityFiltered";

interface Props {
  anonymityThreshold: number;
  explanation: AnonymityFilterExplanation | any;
}

export function PulsePollsAnonymity(props: Props) {
  return (
    <AnonymityContainer>
      <AnonymityFiltered
        explanation={props.explanation}
        anonymityThreshold={props.anonymityThreshold}
        translate="yes"
      />
    </AnonymityContainer>
  );
}

const AnonymityContainer = styled.div`
  margin: 24px 0;
  padding: 24px 0;
  background: white;
`;
