import Button from "@src/components/core/Button";
import Palette from "@src/config/theme/palette";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Trans } from "react-i18next";
import styled from "styled-components";

export interface ActionProps {
  readonly actionPlans: Optional<string>[];
  readonly onDefineActionPlanClick: () => void;
  readonly onCreatedActionPlansClick: () => void;
  readonly disable: boolean;
}

function Actions(props: ActionProps) {
  const {
    actionPlans,
    onDefineActionPlanClick,
    disable,
    onCreatedActionPlansClick,
  } = props;
  
  if (actionPlans.length === 0) {
    return (
      <ActionsContainer>
       <StyledButton color="blue" data-cy="defineActionPlan" onClick={onDefineActionPlanClick} 
       disabled={disable} translate="yes">
         Define Action Plan
       </StyledButton>
      </ActionsContainer>
    );
  }
  return (
      <>
        <ActionsContainer onClick={onCreatedActionPlansClick}>
          <StyledInfo data-cy="focusAreacreatedActionText">
            {actionPlans.length} <Trans>Action Plan{actionPlans.length > 1 ? "s" : ""} Created</Trans> 
          </StyledInfo>
        </ActionsContainer>
      </>
  );
}

const ActionsContainer = styled.div`
  display: inline-block;
  margin-right: 36px;
  max-width: 230px;
  position: relative;
  padding: 5px 0;
  cursor: pointer;
`;

const StyledInfo = styled.span`
  display: inline-block;
  vertical-align: middle;
  color: ${Palette.bluePurple};
`;
const StyledButton = styled(Button)`
  height: 30px !important;
  // width: 170px;
  border: 1px solid #00B8CC;
  border-radius: 2px;
  background-color: ${Palette.darkTurqoise};
`;

export default Actions;