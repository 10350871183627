import React from "react";
import styled from "styled-components";
import { Icon, Tooltip } from "antd";
import { RouteComponentProps, withRouter } from "react-router";
import { ColumnProps, TableProps } from "antd/lib/table";
import Table from "@components/core/Table";
import { DynamicRowProps, withDynamicRows } from "@components/core/DynamicRowsTable/withDynamicRows";
import Palette from "@src/config/theme/palette";
// eslint-disable-next-line max-len
import { QuestionCell } from "@screens/Insights/Survey/components/QuestionsReport/components/QuestionsTable/cells/QuestionCell";
// eslint-disable-next-line max-len
import { FavorabilitySentimentCell } from "@screens/Insights/Survey/components/QuestionsReport/components/QuestionsTable/cells/FavorabilitySentimentCell";
// eslint-disable-next-line max-len
import { CompareCell } from "@screens/Insights/Survey/components/QuestionsReport/components/QuestionsTable/cells/CompareCell";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { QuestionResult } from "hyphen-lib/dist/domain/resource/report/common/QuestionResult";
import { CompareWithOption } from "@screens/Insights/components/ViewOptions/components/CompareWith";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { Breadcrumb, goTo } from "@src/utils/locations";
import { getMatchingOptionLabel } from "@src/utils/Comparisons";
import { InlineAnonymityFiltered } from "@components/core/InlineAnonymityFiltered";
import CreateActionPlanButton from "@src/screens/Insights/components/Reports/CreateActionPlanButton";
import {
  CellContainer,
  CellInnerContainer
} from "@screens/Insights/SurveyList/components/cells/Cells";
import { CommentsCell } from "./cells/CommentsCell";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { FocusArea, Module } from "hyphen-lib/dist/domain/FocusArea";
import { QuestionType } from "hyphen-lib/dist/domain/common/QuestionType";
import { Trans } from "react-i18next";
/*
    fixme: BEFORE LAUNCH!!!
    fixme: BEFORE LAUNCH!!!
    fixme: BEFORE LAUNCH!!!
    fixme: BEFORE LAUNCH!!!
    fixme: BEFORE LAUNCH!!!
    fixme: BEFORE LAUNCH!!!

    This component should be mutualized with the QuestionTable used in QuestionsReport!

    fixme: BEFORE LAUNCH!!!
    fixme: BEFORE LAUNCH!!!
    fixme: BEFORE LAUNCH!!!
    fixme: BEFORE LAUNCH!!!
    fixme: BEFORE LAUNCH!!!
    fixme: BEFORE LAUNCH!!!
 */

const HeaderWithIcon = styled.div`
  white-space: pre;
`;

const ComparisonOption = styled.div`
  color: ${Palette.bluishGrey};
  font-size: 12px;
  font-weight: normal;
  margin-top: 4px;
  width:120px;
`;

const StyledInfoIcon = styled(Icon)`
  font-size: 16px;
  color: ${Palette.lightGreyBlue};
  margin-left: 8px;
`;

const getColumns = (
  tableName: string,
  areComparisonsVisible: boolean,
  compareWithOptions: CompareWithOption[],
  comparisonKey: Optional<string>,
  anonymityThreshold: number
): ColumnProps<QuestionResult>[] => {

  const columns: ColumnProps<QuestionResult>[] = [
    {
      title: (<Trans>Question</Trans>),
      dataIndex: "question",
      key: "question",
      width: 425,
      render: (__, record) => {
        return (
          <QuestionCell row={record} />
        );
      },
    },
    {
      title: (<Trans>Comments</Trans>),
      dataIndex: "comments",
      key: "comments",
      width: 198,
      render: (__, record) => {
        if (record.filteredForAnonymity) {
          return null;
        }
        return (
          <CommentsCell row={record} />
        );
      },
    },
    {
      title: (
        <HeaderWithIcon>
          <Trans>Favorability</Trans> / <Trans>Sentiment</Trans>
          <Tooltip
            title={<Trans>Favorability is the percentage of the favorable votes out of total votes. 
          Sentiment is based on percentage of positive comments 
          minus percentage of negative comments.</Trans>}>
            <StyledInfoIcon type="info-circle" />
          </Tooltip>
        </HeaderWithIcon>
      ),
      dataIndex: "favorabilitySentiment",
      key: "favorabilitySentiment",
      render: (__, record) => {
        if (record.filteredForAnonymity) {
          return (
            <InlineAnonymityFiltered
              explanation={record.explanation}
              anonymityThreshold={anonymityThreshold}
              size={"normal"}
            />
          );
        }
        return (
          <FavorabilitySentimentCell row={record} tableName={tableName} />
        );
      },
    },
  ];

  if (areComparisonsVisible) {
    const label = getMatchingOptionLabel(compareWithOptions, comparisonKey);
    if (isNotNullNorUndefined(label)) {
      columns.push({
        title: (
          <HeaderText>
            <Trans>Compare</Trans>
            <ComparisonOption><Trans>vs {label}</Trans></ComparisonOption>
          </HeaderText>
        ),
        dataIndex: "compare",
        key: "compare",
        width: 125,
        render: (__, record) => {
          return (
            <CompareCell 
              row={record as any} 
              percentage={record.type === QuestionType.ENPS ? false : true}
              comparisonKey={comparisonKey} 
              textAlign="center" 
            />
          );
        },
      });
    }
  } else {
    // Push empty spacer column
    columns.push({
      sorter: false,
      width: 125,
      render: () => {
        return (
          // Rendering empty placeholder
          <CellContainer align="flex-start" justifyContent="flex-start">
            <CellInnerContainer padding={[24, 0]} width="100%" />
          </CellContainer>
        );
      },
    });
  }

  return columns;
};

export interface CategoryQuestionsTableProps extends TableProps<QuestionResult> {
  readonly tableName: string;
  readonly questions: QuestionResult[];
  readonly areComparisonsVisible?: boolean;
  readonly compareWithOptions: CompareWithOption[];
  readonly comparisonKey: Optional<string>;
  readonly anonymityThreshold: number;
  readonly hasCommentsAccess: boolean;
  readonly hasActionCreationRight: boolean;
  readonly surveyName: string;
  readonly onCreateFocusArea: (focusArea: Partial<FocusAreaResource>) => void;
}

function CategoryQuestionsTableComponent({
  tableName,
  questions,
  areComparisonsVisible = true,
  compareWithOptions,
  comparisonKey,
  anonymityThreshold,
  ...rest
}: CategoryQuestionsTableProps) {

  return (
    <StyledTable
      columns={getColumns(tableName, areComparisonsVisible, compareWithOptions, comparisonKey, anonymityThreshold)}
      dataSource={questions}
      rowKey={"_id"}
      pagination={{
        pageSize: 10,
        // fixme: need to be activated again, and see if we can style it!
        showTotal: (total: number, range: number[]) => 
          <Trans i18nKey="resultsCount"
          values={{start: range[0], end: range[1], total}} 
          defaults={`Showing ${range[0]} to ${range[1]} of ${total} results`}/>,
      }}
      {...rest}
    />
  );
}

const StyledTable = styled(Table)`
  .ant-table-body > table tr > td {
    vertical-align: middle;
  }
  .ant-table-body > table > thead > tr > th {
    padding: 16px 0;
  }
`;

const StyledHoverCell = styled.td`
  background-color: ${Palette.lightLightBlue};
  position: relative;
`;

export default function CategoryQuestionsTable(props: CategoryQuestionsTableProps) {

  function HoverRow(
    { row, ...rest }: DynamicRowProps<QuestionResult> & RouteComponentProps<{ id: string }>
  ) {
    function onCreateActionPlan() {
      props.onCreateFocusArea({
        title: row.question,
        source: FocusArea.Source.CATEGORY,
        category: row.question,
        module: Module.SURVEY,
        focusAreaType: FocusArea.FocusAreaType.QUESTION,
        sourceUrl: {
          label: props.surveyName,
          url: window.location.href,
        },
        actionPlans: [],
        surveyId: rest.match.params.id,
        questionId: row._id
      });
    }

    function onShowDetail() {
      const { match, location } = rest;
      const { id } = match.params;

      goTo(`/surveys/view/${id}/reports/questions/${row._id}/${location.search}`,
        Breadcrumb.stack(props.tableName)
      );
    }

    function gotoComments() {
      const { match, location } = rest;
      const { id } = match.params;

      goTo(`/surveys/view/${id}/reports/questions/${row._id}/comments${location.search}`,
        Breadcrumb.stack(props.tableName)
      );
    }

    if (row.filteredForAnonymity) {
      return (
        <>
          <StyledHoverCell>
            <QuestionCell row={row} />
          </StyledHoverCell>
          <StyledHoverCell />
          <StyledHoverCell>
            <InlineAnonymityFiltered
              explanation={row.explanation}
              anonymityThreshold={props.anonymityThreshold}
              size={"normal"}
            />
          </StyledHoverCell>
          <StyledHoverCell />
        </>
      );
    }

    return (
      <>
        <StyledHoverCell>
          {props.hasActionCreationRight && (
            <CreateActionPlanButton onClick={onCreateActionPlan} />
          )}
          <QuestionCell row={row} hover marginLeft={20} />
        </StyledHoverCell>
        <StyledHoverCell>
          <CommentsCell onCommentsTagClick={props.hasCommentsAccess ? gotoComments : null} row={row} />
        </StyledHoverCell>
        <StyledHoverCell>
          <FavorabilitySentimentCell row={row} tableName={props.tableName} isFromCategory />
        </StyledHoverCell>
        <StyledHoverCell>
          <StyledLink onClick={onShowDetail}>
            <SeeDetails data-cy="see_details_question_drilldown">
              <Trans>See details</Trans> <RightIcon type="right" /></SeeDetails>
          </StyledLink>
        </StyledHoverCell>
      </>
    );
  }

  const TailoredTable = withDynamicRows(CategoryQuestionsTableComponent, withRouter(HoverRow));

  return <TailoredTable {...props} />;
}

const StyledLink = styled.span`
  cursor: pointer;
`;

const HeaderText = styled.div`
text-align: center;
`;

const SeeDetails = styled.div`
  color: ${Palette.darkModerateBlue};
  font-size: 14px;
  text-align: right;
  cursor: pointer;
  line-height: 30px;
`;

const RightIcon = styled(Icon)`
  font-size: 12px;
`;
