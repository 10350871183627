import { default as networkActionCreators } from "@store/network/actions";
import { createRequest } from "@src/utils/networks";
import { PulsePollInstanceInfoResource } from "hyphen-lib/dist/domain/resource/PulsePollInstanceInfoResource";

export function fetchParticipationCountIfNeeded(templateId: string, instanceId: string) {

  return networkActionCreators.fetchElementIfNeeded({
    id: PulsePollInstanceInfoResource.generateId(instanceId),
    type: PulsePollInstanceInfoResource.TYPE,
    request: createRequest(`/pulsePolls/${templateId}/instanceInfos/${instanceId}`),
  });
}
