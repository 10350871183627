import React from "react";
import { Map as ImmutableMap } from "immutable";
import { PaginationConfig } from "antd/lib/table";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { getOr, mapOr } from "hyphen-lib/dist/lang/Objects";
import {
  VoiceFlaggedCommentReportResource
} from "hyphen-lib/dist/domain/resource/voice/VoiceFlaggedCommentReportResource";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { parseNumber } from "hyphen-lib/dist/lang/Number";

import { DataListContainer } from "@src/screens/Insights/components/DataListContainer";
import { FetchDataListParameters } from "@src/screens/Insights/components/DataListContainer/types";
import { parametersActionCreators } from "@src/screens/Insights/parameters/store/actions";
import { State } from "@src/store/types";
import { getParameters } from "@src/screens/Insights/parameters/store/selectors";
import { PropMapping } from "@src/utils/parameters";
import { extractDataAndTotalFromPage, getExistingPage } from "@src/store/network/selectors";
import { fetchVoiceFlaggedCommentsIfNeeded } from "@src/store/network/resource/VoiceFlaggedCommentReportResource";

import { VoiceFlaggedPostReportResource } from "hyphen-lib/dist/domain/resource/voice/VoiceFlaggedPostReportResource";
import AreYouSureModal from "@src/components/core/AreYouSureModal";
import { RightsMatcher } from "hyphen-lib/dist/business/auth/Auth";
import { getRightsMatcher } from "@src/screens/Insights/store/selectors";
import { FetchError } from "@src/screens/Insights/errors/FetchError";
import { VoiceFlagsActionKeyType } from "../../store/types";
import { getEmployeeVoiceReportsState } from "../../store/selectors";
import { voiceReportsActionCreators, VoiceReportsAreYouSureModalPayload } from "../../store/actions";
import { VoiceFlaggedCommentsTable } from "../components/VoiceFlaggedCommentsTable";
import { Trans } from "react-i18next";

interface VoiceCommentsListProps
  extends VoiceFlaggedCommentsListReduxActionProps,
  RouteComponentProps,
  VoiceFlaggedCommentsListReduxStateProps {}

interface VoiceFlaggedCommentsListReduxActionProps {
  readonly onFetchVoiceFlaggedCommentsIfNeeded: (
    parameters: FetchDataListParameters
  ) => void;
  readonly onToggleAreYouSureModal: (payload: VoiceReportsAreYouSureModalPayload) => void;
  readonly onUnFlagComment: () => void;
  readonly archiveComment: () => void;
  readonly onModifyParameters: (
    parameters: Dictionary<any>,
    mappings?: PropMapping[]
  ) => void;
  readonly onModifyList: (parameters: FetchDataListParameters) => void;
  readonly onCloseAreYouSureModal: () => void;
}

interface VoiceFlaggedCommentsListReduxStateProps {
  readonly voiceFlaggedComments: VoiceFlaggedCommentReportResource[];
  readonly totalVoiceCommentsCount: number;
  readonly parameters: ImmutableMap<string, any>;
  readonly pageSize: number;
  readonly loading: boolean;
  readonly isNotFound: boolean;
  readonly isAreYouSureModalVisible: boolean;
  readonly isRequesting: boolean;
  readonly action: VoiceFlagsActionKeyType | null;
  readonly selectedFlaggedResource: VoiceFlaggedPostReportResource | VoiceFlaggedCommentReportResource | null;
  readonly rightsMatcher: RightsMatcher;
  readonly existingListVoiceComments: Store.Page<VoiceFlaggedCommentReportResource>;
}

export class VoiceFlaggedCommentsListContainer extends React.Component<
VoiceCommentsListProps
> {
  componentWillUnmount() {
    this.props.onCloseAreYouSureModal();
  }

  onTableChange = (
    pageParams: FetchDataListParameters,
    pagination: PaginationConfig
  ): void => {
    const { page, filter } = pageParams;
    const { onModifyList, onModifyParameters } = this.props;
    const pageParam = {
      size: getOr(pagination.pageSize, page.size),
      number: getOr(pagination.current, 1),
    };

    onModifyParameters({});
    onModifyList({
      filter,
      page: pageParam,
    });
  };

  onActionClick= (
    action:  VoiceFlagsActionKeyType,
    resource: VoiceFlaggedPostReportResource | VoiceFlaggedCommentReportResource
  ) => {
    this.props.onToggleAreYouSureModal({
      action,
      isAreYouSureModalVisible: true,
      selectedFlaggedResource: resource,
    });
  };

  handleModalOkClick = () => {
    const { action, onUnFlagComment, archiveComment } = this.props;
    if (action === VoiceFlagsActionKeyType.UNFLAG) {
      onUnFlagComment();
    } else if (action === VoiceFlagsActionKeyType.ARCHIVE) {
      archiveComment();
    }
  };

  getOkLabel = (): string => {
    const { action, isRequesting } = this.props;
    let label = isRequesting ? "Archiving..." : "Archive";
    if (action === VoiceFlagsActionKeyType.UNFLAG) {
      label = isRequesting ? "Unflagging..." : "Unflag";
    }

    return label;
  };

  render() {
    const {
      voiceFlaggedComments,
      pageSize,
      parameters,
      totalVoiceCommentsCount,
      loading,
      isNotFound,
      onFetchVoiceFlaggedCommentsIfNeeded,
      isAreYouSureModalVisible,
      action,
      onCloseAreYouSureModal,
      selectedFlaggedResource,
      isRequesting,
      rightsMatcher,
      existingListVoiceComments,
    } = this.props;

    if (Store.Page.isInError(existingListVoiceComments)) {
      return <FetchError { ...existingListVoiceComments } resourceType={VoiceFlaggedCommentReportResource.TYPE}/>;
    }

    return (
      <>
        <DataListContainer
          pageSize={pageSize}
          parameters={parameters}
          loading={loading}
          isNotFound={isNotFound}
          onFetchIfNeeded={onFetchVoiceFlaggedCommentsIfNeeded}
          isInError={Store.Page.isInError(existingListVoiceComments)}
        >
          {({ pageParams }) => (
            <VoiceFlaggedCommentsTable
              dataSource={voiceFlaggedComments}
              loading={loading}
              onActionClick={this.onActionClick}
              onChange={this.onTableChange.bind(this, pageParams)}
              pagination={{
                total: totalVoiceCommentsCount,
                pageSize: pageParams.page.size,
                current: pageParams.page.number,
                showTotal: (totalDocuments: number, range: number[]) =>
                  <Trans i18nKey="commentsCount"
                  values={{start: range[0], end: range[1], total: totalDocuments}} 
                  defaults={`Showing ${range[0]} to ${range[1]} of ${totalDocuments} comments`}/>,
              }}
              rightsMatcher={rightsMatcher}
            />
          )}
        </DataListContainer>

        {
          isAreYouSureModalVisible && selectedFlaggedResource &&
          <AreYouSureModal
            visible={isAreYouSureModalVisible}
            title="Are you sure?"
            description={`This action will ${
              action === VoiceFlagsActionKeyType.UNFLAG ? "unflag" : "archive"
            } the comment`}
            onOk={this.handleModalOkClick}
            okLabel={this.getOkLabel()}
            buttonsDisabled={isRequesting}
            onCancel={onCloseAreYouSureModal}
          />
        }
      </>
    );
  }
}

function mapStateToProps(
  state: State,
  { location }: RouteComponentProps
): VoiceFlaggedCommentsListReduxStateProps {
  const queryParameters = parseQueryString(location.search);
  const filter = getOr(queryParameters.filter, {});
  const {
    pageSize,
    isAreYouSureModalVisible,
    selectedFlaggedResource,
    action,
    isRequesting,
  } = getEmployeeVoiceReportsState(state);
  const page = {
    size: pageSize,
    number: mapOr(queryParameters.page, parseNumber, 1),
  };

  const existingListVoiceComments = getExistingPage(
    state,
    VoiceFlaggedCommentReportResource.TYPE,
    VoiceFlaggedCommentReportResource.generateKey(filter),
    page
  );

  const {
    data: voiceFlaggedComments,
    total: totalVoiceCommentsCount,
  } = extractDataAndTotalFromPage(existingListVoiceComments);

  return {
    isNotFound: Store.Page.isNotFound(existingListVoiceComments),
    loading: Store.Page.isLoading(existingListVoiceComments),
    existingListVoiceComments,
    voiceFlaggedComments,
    totalVoiceCommentsCount,
    parameters: getParameters(state),
    pageSize,
    isAreYouSureModalVisible,
    action,
    selectedFlaggedResource,
    isRequesting,
    rightsMatcher: getRightsMatcher(state),
  };
}

const mapDispatchToProps: VoiceFlaggedCommentsListReduxActionProps = {
  onFetchVoiceFlaggedCommentsIfNeeded: fetchVoiceFlaggedCommentsIfNeeded,
  onUnFlagComment: voiceReportsActionCreators.unflagComment,
  archiveComment: voiceReportsActionCreators.archiveComment,
  onToggleAreYouSureModal: voiceReportsActionCreators.toggleAreYouSureModal,
  onModifyList: voiceReportsActionCreators.modifyList,
  onModifyParameters: parametersActionCreators.modifyParameters,
  onCloseAreYouSureModal: voiceReportsActionCreators.closeAreYouSureModal,
};

export const VoiceFlaggedCommentsList = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(VoiceFlaggedCommentsListContainer)
);
