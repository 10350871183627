import React from "react";
import styled from "styled-components";
import { Layout as AntLayout } from "antd";

import { OverviewReportResource } from "@hyphen-lib/domain/resource/survey/report/OverviewReportResource";
import Palette from "@src/config/theme/palette";
import SurveyReportHeader from "@screens/Insights/Survey/components/SurveyReportHeader";
import Comments, { CommentsData } from "@screens/Insights/Survey/components/OverviewReport/components/Comments";
import Segments from "@screens/Insights/Survey/components/OverviewReport/components/Segments";
import Spin from "@components/core/Spin";

import { CompareWithOption } from "@screens/Insights/components/ViewOptions/components/CompareWith";
import { RightsMatcher } from "@hyphen-lib/business/auth/Auth";
import { Rights } from "@hyphen-lib/business/auth/Rights";
import { AnonymityFiltered } from "@components/core/AnonymityFiltered";
import { formatDate, utcNow } from "hyphen-lib/dist/lang/Dates";
import { getOr } from "hyphen-lib/dist/lang/Objects";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { DashboardResource } from "hyphen-lib/dist/domain/resource/report/DashboardResource";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Filter } from "@src/components/core/FilterLabels/types";
import { Company } from "hyphen-lib/dist/domain/Company";
import { isOnlyEmployeeVoiceModuleEnabled } from "@src/utils/modules";
import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
import { DASHBOARD_FILTER_MAPPING } from "../../containers/DashboardContainer";
import LatestActivity from "./components/LatestActivity";
import ActionPlans from "./components/ActionPlans";
import TotalFavorability from "./components/TotalFavorability";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";

interface Props {
  readonly enabledFilters: string[];
  readonly enabledCustomFilters?: string[];
  readonly dashboard: Loadable<DashboardResource>;
  readonly dimensions: Optional<Dimensions>;
  readonly rightsMatcher: RightsMatcher;
  readonly anonymityThreshold: number;
  readonly company?: Optional<CompanyResource>;
  readonly appliedFilters: Filter[];
  readonly companyModules: Company.Modules;
  readonly focusAreas?: FocusAreaResource[];
  readonly onCreateFocusArea: (
    focusArea: Partial<FocusAreaResource>
  ) => void;
  readonly currentUser: Optional<CurrentUserResource>;
}

class DashboardView extends React.Component<Props> {
  render() {
    const {
      dashboard,
      dimensions,
      enabledFilters,
      enabledCustomFilters,
      rightsMatcher,
      anonymityThreshold,
      company,
      appliedFilters,
      companyModules,
    } = this.props;

    const comparisonKey = "previous";
    const compareWithOptions: CompareWithOption[] = [
      {
        type: "single",
        key: "previous",
        label: "Previous",
        columnLabel: "previous",
      },
    ];

    const hasAccessToActionPlan = rightsMatcher.hasRight(Rights.Action.VIEW_ALL);
    const hasAccessToSurveys = rightsMatcher.hasRight(Rights.Survey.READ_ALL);
    const hasAccessToPulsePolls = rightsMatcher.hasRight(Rights.PulsePoll.READ_ALL);
    const hasAccessToPulsePollsList = rightsMatcher.hasRight(Rights.PulsePoll.LIST);
    const hasActionCreationRight = rightsMatcher.hasRight(Rights.Action.CREATE);

    const rangeExplanation = Loadable.mapOr(
      dashboard,
      // display first date in UTC, otherwise for PDT, it will display the last day of the month,
      // and that might be confusing. For to, we want localized date, as if the date is one day after current
      // that might also be confusing :D
      d => `Displaying data from ${formatDate(d.dateRange.from)} to ${formatDate(getOr(d.dateRange.to, utcNow()))}`,
      ""
    );

    return (
      <Layout>
        <Spin size="large" spinning={Loadable.isNotLoaded(dashboard)}>
          <SectionContainer data-cy="dashboard_dateRange">
            <SurveyReportHeader
              enabledFilters={enabledFilters}
              explanation={rangeExplanation}
              enabledCustomFilters={enabledCustomFilters}
              companyModules={companyModules}
              storeMappings={DASHBOARD_FILTER_MAPPING}
              from="dashboard_view"
            />
          </SectionContainer>
          {
            Loadable.isLoaded(dashboard) &&
            this.renderDashboard(
              dashboard.value,
              dimensions,
              compareWithOptions,
              comparisonKey,
              hasActionCreationRight,
              hasAccessToActionPlan,
              hasAccessToSurveys,
              hasAccessToPulsePolls,
              hasAccessToPulsePollsList,
              anonymityThreshold,
              company,
              appliedFilters,
              companyModules
            )
          }
        </Spin>
      </Layout>
    );
  }

  // noinspection JSMethodCanBeStatic
  private renderDashboard(dashboard: DashboardResource,
    dimensions: Optional<Dimensions>,
    compareWithOptions: CompareWithOption[],
    comparisonKey: string,
    hasActionCreationRight: boolean,
    hasAccessToActionPlan: boolean,
    hasAccessToSurveys: boolean,
    hasAccessToPulsePolls: boolean,
    hasAccessToPulsePollsList: boolean,
    anonymityThreshold: number,
    company: Optional<CompanyResource>,
    appliedFilters: Filter[],
    companyModules: Company.Modules) {

    if (dashboard.filteredForAnonymity) {
      return (
        <SectionContainer>
          <AnonymityContainer>
            <AnonymityFiltered
              explanation={dashboard.explanation}
              anonymityThreshold={anonymityThreshold}
              translate="yes"
              isDashboard={true}
            />
          </AnonymityContainer>
        </SectionContainer>
      );
    }
    const showOnlyCommentsSection = isOnlyEmployeeVoiceModuleEnabled(companyModules);

    return <>
      {
        !showOnlyCommentsSection &&
        <SectionContainer data-cy="dashboard_favourabilitySection">
          <TotalFavorability
            data={dashboard.favorability}
            compareWithOptions={compareWithOptions}
            comparisonKey={comparisonKey}
            hasActionCreationRight={hasActionCreationRight}
            company={company}
            appliedFilters={appliedFilters}
            onCreateFocusArea={this.props.onCreateFocusArea}
            focusAreas={this.props.focusAreas}
            focusAreaLabel={"Dashboard"}

          />
        </SectionContainer>
      }

      <SectionContainer data-cy="dashboard_commentSection">
        <Comments
          data={dashboard.sentiment as unknown as CommentsData}
          isDashboard={true}
          sectionName="Dashboard"
          hasCommentsAccess={false}
        />
      </SectionContainer>
      {
        !showOnlyCommentsSection &&
        <SectionContainer data-cy="dashboard_segmentSection">
          <Segments
            data={dashboard.segments as unknown as OverviewReportResource}
            dimensions={dimensions}
            compareWithOptions={compareWithOptions}
            comparisonKey={comparisonKey}
            hasActionCreationRight={hasActionCreationRight}
            focusAreas={this.props.focusAreas}
            onCreateFocusArea={this.props.onCreateFocusArea}
            focusAreaLabel={"Dashboard"}
          />
        </SectionContainer>
      }
      <DoubleSectionContainer>
        {
          hasAccessToActionPlan && (
            <ActionPlans data={dashboard.actionPlanning}/>
          )
        }
        {
          (hasAccessToSurveys || hasAccessToPulsePolls) && (
            <LatestActivity
              data={dashboard.latestActivity}
              company={company}
              hasAccessToPulsePollsList={hasAccessToPulsePollsList}
            />
          )
        }
      </DoubleSectionContainer>
    </>;
  }
}

const Layout = styled(AntLayout)`
   background: ${Palette.paleGrey} !important;
`;

const SectionContainer = styled.div`
  margin-top: 24px;

  &:first-of-type {
    margin-top: 0;
  }
`;
SectionContainer.displayName = "SectionContainer";

const AnonymityContainer = styled.div`
  margin: 24px 0;
  padding: 24px 0;
  background: white;
`;

const DoubleSectionContainer = styled.div`
  margin: 24px -12px 0;
  display: flex;

  > * {
    margin: 0 12px;
    flex: 1;
  }
`;

export default DashboardView;
