import { FSA } from "flux-standard-action";
import {
  createRequest,
  FilterParameter,
  SortParameter,
} from "@src/utils/networks";
import { ActionResource } from "hyphen-lib/dist/domain/resource/action/ActionResource";
import {
  ActionTypes as NetworkActionTypes,
  NetworkEventErrorAction,
  NetworkEventRequestAction,
  NetworkEventSuccessAction,
  NetworkRequestAction,
} from "@store/network/actions";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { ActionModalType, FocusAreaNotificationBar } from "@screens/Insights/Actions/store/reducers";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";

export enum ActionListActionTypes {
  MODIFY_LIST = "actionList/MODIFY_LIST",

  SELECT_UNSELECT_ACTIONS = "actionList/SELECT_UNSELECT_ACTIONS",

  TOGGLE_ACTION_MODAL_VISIBILITY = "actionList/TOGGLE_ACTION_MODAL_VISIBILITY",
  TOGGLE_ACTION_RATE_MODAL_VISIBILITY = "actionList/TOGGLE_ACTION_RATE_MODAL_VISIBILITY",

  UPDATE_ACTION = "actionList/UPDATE_ACTION",
  UPDATE_ACTION_REQUEST = "actionList/UPDATE_ACTION_REQUEST",
  UPDATE_ACTION_SUCCESS = "actionList/UPDATE_ACTION_SUCCESS",
  UPDATE_ACTION_ERROR = "actionList/UPDATE_ACTION_ERROR",

  DELETE_ACTION = "actionList/DELETE_ACTION",
  DELETE_ACTION_REQUEST = "actionList/DELETE_ACTION_REQUEST",
  DELETE_ACTION_SUCCESS = "actionList/DELETE_ACTION_SUCCESS",
  DELETE_ACTION_ERROR = "actionList/DELETE_ACTION_ERROR",

  SET_ACTION_FOR_DELETE = "actionList/DELETE_SET_ACTION",
  RESET_ACTION_FOR_DELETE = "actionList/RESET_ACTION_FOR_DELETE",

  CLEAN_ACTION_PLAN_RESOURCE = "actionList/CLEAN_ACTION_PLAN_RESOURCE",
}

export enum FocusAreaListActionTypes {
  MODIFY_LIST = "actionList/MODIFY_LIST",

  UPDATE_FOCUS_AREA = "actionList/UPDATE_FOCUS_AREA",
  UPDATE_FOCUS_AREA_REQUEST = "actionList/UPDATE_FOCUS_AREA_REQUEST",
  UPDATE_FOCUS_AREA_SUCCESS = "actionList/UPDATE_FOCUS_AREA_SUCCESS",
  UPDATE_FOCUS_AREA_ERROR = "actionList/UPDATE_FOCUS_AREA_ERROR",

  DELETE_FOCUS_AREA = "actionList/DELETE_FOCUS_AREA",
  DELETE_FOCUS_AREA_REQUEST = "actionList/DELETE_FOCUS_AREA_REQUEST",
  DELETE_FOCUS_AREA_SUCCESS = "actionList/DELETE_FOCUS_AREA_SUCCESS",
  DELETE_FOCUS_AREA_ERROR = "actionList/DELETE_FOCUS_AREA_ERROR",

  CLEAN_FOCUS_AREA_RESOURCE = "actionList/CLEAN_FOCUS_AREA_RESOURCE",

  SET_FOCUS_AREA_FOR_DELETE = "actionList/DELETE_SET_FOCUS_AREA",
  RESET_FOCUS_AREA_FOR_DELETE = "actionList/RESET_FOCUS_AREA_FOR_DELETE",

  CREATE_FOCUS_AREA_REQUEST = "actionList/CREATE_FOCUS_AREA_REQUEST",
  CREATE_FOCUS_AREA_REQUEST_DEBOUNCED = "actionList/CREATE_FOCUS_AREA_REQUEST_DEBOUNCED",
  CREATE_FOCUS_AREA_SUCCESS = "actionList/CREATE_FOCUS_AREA_SUCCESS",
  CREATE_FOCUS_AREA_ERROR = "actionList/CREATE_FOCUS_AREA_ERROR",
  FETCH_FOCUS_AREA_CATEGORY_TEMPLATE = "actionList/FETCH_FOCUS_AREA_CATEGORY_TEMPLATE",
  FETCH_FOCUS_AREA_CATEGORY_TEMPLATE_REQUEST = "actionList/FETCH_FOCUS_AREA_CATEGORY_TEMPLATE_REQUEST",
  FETCH_FOCUS_AREA_CATEGORY_TEMPLATE_SUCCESS = "actionList/FETCH_FOCUS_AREA_CATEGORY_TEMPLATE_SUCCESS",
  FETCH_FOCUS_AREA_CATEGORY_TEMPLATE_ERROR = "actionList/FETCH_FOCUS_AREA_CATEGORY_TEMPLATE_ERROR",
  SHOW_NOTIFICATION_BAR_FOCUS_AREA = "actionList/SHOW_NOTIFICATION_BAR_FOCUS_AREA",
}

export interface ActionModalPayload {
  readonly isActionModalVisible: boolean;
  readonly actionModalType: Optional<ActionModalType>;
  readonly action: Optional<ActionResource>;
}

interface Payloads {
  [ActionListActionTypes.MODIFY_LIST]: ActionListPageParameters;
  [ActionListActionTypes.SELECT_UNSELECT_ACTIONS]: string[];
  [ActionListActionTypes.TOGGLE_ACTION_MODAL_VISIBILITY]: ActionModalPayload;
  [ActionListActionTypes.UPDATE_ACTION_ERROR]: Error;
  [ActionListActionTypes.UPDATE_ACTION_REQUEST]: Request;
  [ActionListActionTypes.UPDATE_ACTION_SUCCESS]: Response;
  [ActionListActionTypes.DELETE_ACTION_REQUEST]: string;
  [ActionListActionTypes.SET_ACTION_FOR_DELETE]: ActionResource;
}

export interface ModifyListAction
  extends FSA<
    ActionListActionTypes.MODIFY_LIST,
    Payloads[ActionListActionTypes.MODIFY_LIST]
  > {
  type: ActionListActionTypes.MODIFY_LIST;
  payload: Payloads[ActionListActionTypes.MODIFY_LIST];
}

export interface ModifyFocusAreaListAction
  extends FSA<
    FocusAreaListActionTypes.MODIFY_LIST,
    Payloads[FocusAreaListActionTypes.MODIFY_LIST]
  > {
  type: FocusAreaListActionTypes.MODIFY_LIST;
  payload: Payloads[FocusAreaListActionTypes.MODIFY_LIST];
}

export interface SelectUnselectAction
  extends FSA<
    ActionListActionTypes.SELECT_UNSELECT_ACTIONS,
    Payloads[ActionListActionTypes.SELECT_UNSELECT_ACTIONS]
  > {
  type: ActionListActionTypes.SELECT_UNSELECT_ACTIONS;
  payload: Payloads[ActionListActionTypes.SELECT_UNSELECT_ACTIONS];
}

export interface ToggleActionModalVisibilityAction
  extends FSA<
    ActionListActionTypes.TOGGLE_ACTION_MODAL_VISIBILITY,
    Payloads[ActionListActionTypes.TOGGLE_ACTION_MODAL_VISIBILITY]
  > {
  type: ActionListActionTypes.TOGGLE_ACTION_MODAL_VISIBILITY;
  payload: Payloads[ActionListActionTypes.TOGGLE_ACTION_MODAL_VISIBILITY];
}

export interface UpdateActionRequestAction
  extends FSA<
    ActionListActionTypes.UPDATE_ACTION_REQUEST,
    Payloads[ActionListActionTypes.UPDATE_ACTION_REQUEST]
  > {
  type: ActionListActionTypes.UPDATE_ACTION_REQUEST;
  payload: Payloads[ActionListActionTypes.UPDATE_ACTION_REQUEST];
}

export interface UpdateActionSuccessAction
  extends FSA<
    ActionListActionTypes.UPDATE_ACTION_SUCCESS,
    Payloads[ActionListActionTypes.UPDATE_ACTION_SUCCESS]
  > {
  type: ActionListActionTypes.UPDATE_ACTION_SUCCESS;
  payload: Payloads[ActionListActionTypes.UPDATE_ACTION_SUCCESS];
}

export interface UpdateActionErrorAction
  extends FSA<
    ActionListActionTypes.UPDATE_ACTION_ERROR,
    Payloads[ActionListActionTypes.UPDATE_ACTION_ERROR]
  > {
  type: ActionListActionTypes.UPDATE_ACTION_ERROR;
  payload: Payloads[ActionListActionTypes.UPDATE_ACTION_ERROR];
}

export interface DeleteActionRequest
  extends FSA<
    ActionListActionTypes.DELETE_ACTION_REQUEST,
    Payloads[ActionListActionTypes.DELETE_ACTION_REQUEST]
  > {
  type: ActionListActionTypes.DELETE_ACTION_REQUEST;
  payload: Payloads[ActionListActionTypes.DELETE_ACTION_REQUEST];
}

export interface SetDeleteRequest
  extends FSA<
    ActionListActionTypes.SET_ACTION_FOR_DELETE,
    Payloads[ActionListActionTypes.SET_ACTION_FOR_DELETE]
  > {
  type: ActionListActionTypes.SET_ACTION_FOR_DELETE;
  payload: Payloads[ActionListActionTypes.SET_ACTION_FOR_DELETE];
}
export interface ActionListPageParameters {
  readonly filter?: FilterParameter;
  readonly sort?: SortParameter;
  readonly page: PageFilter;
}

export interface ActionListPageParameters {
  readonly filter?: FilterParameter;
  readonly sort?: SortParameter;
  readonly page: PageFilter;
}

export interface FocusAreaListPageParameters {
  readonly filter?: FilterParameter;
  readonly page: PageFilter;
  readonly sort?: SortParameter;
}

function createDeleteRequest(payload: DeleteActionRequest["payload"]) {
  return createRequest(`/actions/${payload}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function createFocusAreaDeleteRequest(payload: DeleteActionRequest["payload"]) {
  return createRequest(`/focusArea/${payload}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export const updateActionRequest = (
  actionId: string,
  action: ActionResource
) => {
  return createRequest(`/actions/${actionId}`, {
    method: "PUT",
    body: JSON.stringify(action),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateFocusAreaRequest = (
  focusAreaId: string,
  focusArea: FocusAreaResource
) => {
  return createRequest(`/focusAreas/${focusAreaId}`, {
    method: "PUT",
    body: JSON.stringify(focusArea),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const createFocusAreaRequest = (
  focusArea: Partial<FocusAreaResource>
) => {
  return createRequest("/focusArea", {
    method: "POST",
    body: JSON.stringify(focusArea),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getFocusAreaTemplatesByCategory = (category: string) => {
  return createRequest(`/focusArea/templates/${category}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const actionListActionCreators = {
  updateActionRequest: (
    actionId: string,
    action: ActionResource,
    options?: {
      meta?: Dictionary<any>;
      onSuccessRedirect?: (
        payload: NetworkEventSuccessAction["payload"]
      ) => void;
    }
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: updateActionRequest(actionId, action),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => ({
          type: ActionListActionTypes.UPDATE_ACTION_REQUEST,
          payload,
        }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({
          type: ActionListActionTypes.UPDATE_ACTION_SUCCESS,
          payload,
          meta: isNotNullNorUndefined(options) ? options.meta : undefined,
        }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({
          type: ActionListActionTypes.UPDATE_ACTION_ERROR,
          payload,
        }),
      ],
      onSuccessRedirect: isNotNullNorUndefined(options)
        ? options.onSuccessRedirect
        : undefined,
    },
  }),
  modifyList: (payload: ModifyListAction["payload"]): ModifyListAction => ({
    type: ActionListActionTypes.MODIFY_LIST,
    payload,
  }),
  onSelectActionKeys: (selectedRowKeys: SelectUnselectAction["payload"]) => ({
    type: ActionListActionTypes.SELECT_UNSELECT_ACTIONS,
    selectedRowKeys,
  }),
  onToggleActionModal: (payload: ActionModalPayload) => ({
    type: ActionListActionTypes.TOGGLE_ACTION_MODAL_VISIBILITY,
    payload,
  }),
  updateAction: (actionId: string) => ({
    type: ActionListActionTypes.UPDATE_ACTION,
    actionId,
  }),
  deleteAction: (
    actionId: string,
    onSuccessRedirect?: (payload: NetworkEventSuccessAction["payload"]) => void
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createDeleteRequest(actionId),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => ({
          type: ActionListActionTypes.DELETE_ACTION_REQUEST,
          payload,
        }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({
          type: ActionListActionTypes.DELETE_ACTION_SUCCESS,
          payload,
        }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({
          type: ActionListActionTypes.DELETE_ACTION_ERROR,
          payload,
        }),
      ],
      onSuccessRedirect,
    },
  }),
  setDelete: (action: ActionResource): SetDeleteRequest => ({
    type: ActionListActionTypes.SET_ACTION_FOR_DELETE,
    payload: action,
  }),
  undoSet: (): any => ({
    type: ActionListActionTypes.RESET_ACTION_FOR_DELETE,
  }),
  deleteResource: (redirectPath?: string): any => ({
    type: ActionListActionTypes.DELETE_ACTION,
    redirectPath,
  }),
  cleanActionPlan: (redirectPath?: string) => ({
    type: ActionListActionTypes.CLEAN_ACTION_PLAN_RESOURCE,
    redirectPath,
  }),
};

export const focusAreaListActionCreators = {
  updateFocusAreaRequest: (
    focusAreaId: string,
    focusArea: FocusAreaResource,
    options?: {
      meta?: Dictionary<any>;
      onSuccessRedirect?: (
        payload: NetworkEventSuccessAction["payload"]
      ) => void;
    }
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: updateFocusAreaRequest(focusAreaId, focusArea),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => ({
          type: FocusAreaListActionTypes.UPDATE_FOCUS_AREA_REQUEST,
          payload,
        }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({
          type: FocusAreaListActionTypes.UPDATE_FOCUS_AREA_SUCCESS,
          payload,
          meta: isNotNullNorUndefined(options) ? options.meta : undefined,
        }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({
          type: FocusAreaListActionTypes.UPDATE_FOCUS_AREA_ERROR,
          payload,
        }),
      ],
      onSuccessRedirect: isNotNullNorUndefined(options)
        ? options.onSuccessRedirect
        : undefined,
    },
  }),
  modifyList: (
    payload: ModifyListAction["payload"]
  ): ModifyFocusAreaListAction => ({
    type: FocusAreaListActionTypes.MODIFY_LIST,
    payload,
  }),

  deleteFocusArea: (
    focusAreaId: string,
    onSuccessRedirect?: (payload: NetworkEventSuccessAction["payload"]) => void
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createFocusAreaDeleteRequest(focusAreaId),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => ({
          type: FocusAreaListActionTypes.DELETE_FOCUS_AREA_REQUEST,
          payload,
        }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({
          type: FocusAreaListActionTypes.DELETE_FOCUS_AREA_SUCCESS,
          payload,
        }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({
          type: FocusAreaListActionTypes.DELETE_FOCUS_AREA_ERROR,
          payload,
        }),
      ],
      onSuccessRedirect,
    },
  }),
  createFocusArea: (focusArea: Partial<FocusAreaResource>) => ({
    type: FocusAreaListActionTypes.CREATE_FOCUS_AREA_REQUEST,
    payload: focusArea,
  }),
  showNotificationBar: (payload: FocusAreaNotificationBar) => ({
    type: FocusAreaListActionTypes.SHOW_NOTIFICATION_BAR_FOCUS_AREA,
    payload
  }),
  createFocusAreaDebounced: (
    focusArea: Partial<FocusAreaResource>
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createFocusAreaRequest(focusArea),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => ({
          type: FocusAreaListActionTypes.CREATE_FOCUS_AREA_REQUEST_DEBOUNCED,
          payload,
        }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({
          type: FocusAreaListActionTypes.CREATE_FOCUS_AREA_SUCCESS,
          payload,
        }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({
          type: FocusAreaListActionTypes.CREATE_FOCUS_AREA_ERROR,
          payload,
        }),
      ],
    },
  }),

  fetchFocusAreaTemplates: (
    category: string,
    options?: {
      meta?: Dictionary<any>;
      onSuccessRedirect?: (
        payload: NetworkEventSuccessAction["payload"]
      ) => void;
    }
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: getFocusAreaTemplatesByCategory(category),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => ({
          type: FocusAreaListActionTypes.FETCH_FOCUS_AREA_CATEGORY_TEMPLATE_REQUEST,
          payload,
        }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({
          type: FocusAreaListActionTypes.FETCH_FOCUS_AREA_CATEGORY_TEMPLATE_SUCCESS,
          payload,
          meta: isNotNullNorUndefined(options) ? options.meta : undefined,
        }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({
          type: FocusAreaListActionTypes.FETCH_FOCUS_AREA_CATEGORY_TEMPLATE_ERROR,
          payload,
        }),
      ],
      onSuccessRedirect: isNotNullNorUndefined(options)
        ? options.onSuccessRedirect
        : undefined,
    },
  }),

  cleanFocusArea: () => ({
    type: FocusAreaListActionTypes.CLEAN_FOCUS_AREA_RESOURCE,
  }),
};
