import React from "react";
import { SelectProps, SelectValue } from "antd/lib/select";
import Select from "@components/core/Select";
import { BaseFilterProps } from "@screens/Insights/components/FiltersContent";
import { Paragraph } from "@components/core/Typography";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { identity } from "hyphen-lib/dist/lang/Functions";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";
import { translate } from "@src/utils/i18next";

const { Option } = Select;

interface MonoSelectFilterProps<T extends string | number> extends SelectProps<T>, BaseFilterProps<T> {
  label: string;
  id?: string;
  trans?: boolean;
  value?: T;
  availableValues: { value: string | number; label: string }[];
  placeholder?: string;
  allowClear?: boolean;
  showSearch?: boolean;
  isDisabled?: boolean;
  onChange: (value: T) => void; // fixme if we are using a mapper, we are not having T but K, but how to express that?!
  mapperIn?: (value: any) => T | undefined;
  mapperOut?: (value: T) => any;
}

export function MonoSelectFilter<T extends string | number>(
  {
    label,
    value,
    availableValues,
    placeholder = "Select...",
    allowClear = false,
    showSearch = false,
    isDisabled = false,
    onChange,
    onClear,
    mapperIn = identity,
    mapperOut = identity,
    trans
  }: MonoSelectFilterProps<T>) {
  const { t } = useTranslation();
  const handleChange = (val: SelectValue) => {
    onChange(mapperOut(val as any));
  };

  const preventMouseDown = (e: any) => {
    e.preventDefault();
  };

  const renderDropDown = (menu: any) => {
    return (
      <>
        {menu}
        { isNotEmptyArray(availableValues) ?
          <Actions onMouseDown={preventMouseDown}>
            <Paragraph weight="bold" onClick={onClear} translate="yes">Clear</Paragraph>
          </Actions> 
        : null}
      </>
    );
  };

  return (
    <StyledSelect
      label={label}
      placeholder={<Trans>{placeholder}</Trans>}
      onChange={handleChange}
      mode="default"
      value={mapperIn(value as any)}
      dropdownRender={renderDropDown}
      allowClear={allowClear}
      showSearch={showSearch}
      disabled={isDisabled}
    >
      {
        availableValues.map((availableValue, idx) => (
          <Option
            key={availableValue.value.toString() + idx}
            value={availableValue.value}
            className={trans ? "translate" : ""}>
              {trans ? translate(t, availableValue.label) : availableValue.label}
          </Option>
        ))
      }
    </StyledSelect>
  );
}

const Actions = styled.div`
  box-shadow: 0 -1px 0 0 ${Palette.lightPeriwinkle};
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  align-items: center;

  > p {
    color: ${Palette.darkModerateBlue} !important;
    cursor: pointer;
  }
`;

// exported for unit tests
export const StyledSelect = styled(Select)<SelectProps & {children: React.ReactNode}>``;
