import { Switch } from "antd";
import styled from "styled-components";
import { AppSettingsResource } from "hyphen-lib/dist/domain/resource/AppSettingsResource";
import { Company } from "hyphen-lib/dist/domain/Company";
import { Heading, Paragraph } from "@components/core/Typography/index";
import useToggle from "@src/utils/hooks/useToggle";
interface MultiLanguageToggleProps {
  appSettings: AppSettingsResource;
  isMultiLanguageSurveyEnabled: boolean;
  onValueChange: (values: AppSettingsResource) => void;
}

export function MultiLanguageToggle({
  appSettings,
  onValueChange,
  isMultiLanguageSurveyEnabled }:
  MultiLanguageToggleProps) {
  const [multiLanguageEnabled, toggleMultiLanguage] = useToggle(isMultiLanguageSurveyEnabled);
  const onCompanyModulesChange = () => {
    onValueChange({
      ...appSettings,
      surveyLanguages: [],
      modules: {
        ...appSettings.modules,
        multiLanguageSurvey: !multiLanguageEnabled

      } as Company.Modules
    });
    toggleMultiLanguage(!multiLanguageEnabled);
  };
  return (
    <>
      <Heading translate="yes">Multi Language Surveys</Heading>
      <Holder data-jest="multiLanguageSurveys">
        <Description translate="yes">
          Enable launching surveys in multiple languages
        </Description>
        <Switch
          checked={multiLanguageEnabled}
          onChange={onCompanyModulesChange}
        />
      </Holder>
    </>
  );
}


const Holder = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
`;
const Description = styled(Paragraph)`
  padding-right:88px;
  margin-bottom: 1em;
`;