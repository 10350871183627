import React from "react";
import styled from "styled-components";
import { Switch } from "antd";
import { ColumnProps, TableProps } from "antd/lib/table";
import { Trans } from "react-i18next";
import { CommentsResultResource } from "@hyphen-lib/domain/resource/report/CommentsResultResource";
import Table from "@components/core/Table";
import Palette from "@src/config/theme/palette";
import Tooltip from "@components/core/Tooltip";
import { RouteComponentProps } from "react-router";
import { SentimentCell } from "../cells/SentimentCell";
import { CommentCell } from "../cells/CommentCell";
import { QuestionCell } from "../cells/QuestionCell";
import { TableTextCell } from "../cells/TableTextCell";
import {
  sortSurveyComments
} from "hyphen-lib/dist/business/calculation/comment/SurveyCommentsReport";
import { isNotNullNorUndefined, isEmptyObject, isNotEmptyObject, getOr } from "hyphen-lib/dist/lang/Objects";
import { SortParameter } from "@src/utils/networks";
import { Dictionary } from "@hyphen-lib/domain/structure/Dictionary";
import { formatSort } from "@src/utils/helper";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { StyledInfoIcon } from "@src/screens/Insights/Survey/components/OverviewReport/components/Overview";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { PaginationConfig, SorterResult } from "antd/lib/table";
import { replaceLocation } from "@src/utils/locations";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { TranslateSvg } from "@components/core/svg/Translate";

const headerStyle = `
  font-size: 15px;
  color: ${Palette.darkBlueGrey};
`;

export const HeaderWithIcon = styled.div`
  white-space: pre;
  ${headerStyle}
`;

function getSortOrder(field: string, sort?: Optional<SortParameter>) {
  if (isEmptyObject(sort)) {
    return undefined;
  }
  if (sort![field] === 1) {
    return "ascend";
  } else if (sort![field] === -1) {
    return "descend";
  }
  return undefined;
};

const getColumns = (
  sort: Dictionary<any>, 
  translateComments?: any,
  postAndSurveySentiment?: boolean 
  ): ColumnProps<CommentsResultResource>[] =>  {
  const sortParam = formatSort(sort);
    const columns: ColumnProps<CommentsResultResource>[] = [
      {
        title: (
          <CommentsHeader>
            <Trans>Comment</Trans>
            { translateComments && <div data-jest="translateComment">
              <Tooltip title={<span><Trans>Translate into</Trans> English</span>}>
                <span><TranslateSvg/></span>
              </Tooltip>
              &nbsp;
              <Switch
                checked={translateComments.translate}
                onChange={(ev) => translateComments.onToggleTranslate(ev)}
              />
            </div> }
          </CommentsHeader>
        ),
        dataIndex: "comment",
        width: 500,
        key: "comment",
        render: (__, record, index) => {
          return (
            <CommentCell
              row={record}
              index={index}
              translate={translateComments?.translate}
            />
          );
        },
      },
      {
        title: <Trans>Question</Trans>,
        dataIndex: "question",
        key: "question",
        width: 432,
        sorter: true,
        sortOrder: getSortOrder("question", sortParam),
        render: (__, record, index) => {
          return <QuestionCell row={record} index={index} />;
        },
      },
      {
        title: <Trans>Category</Trans>,
        dataIndex: "category",
        key: "category",
        sorter: true,
        sortOrder: getSortOrder("category", sortParam),
        render: (__, record, index) => {
          return <TableTextCell row={record} index={index} target="category" />;
        },
      },
      {
        title: <Trans>Topic</Trans>,
        dataIndex: "topic",
        key: "topic",
        sorter: true,
        sortOrder: getSortOrder("topic", sortParam),
        render: (__, record, index) => {
          return <TableTextCell row={record} index={index} target="topic" />;
        },
      },
    ];
    if (getOr(postAndSurveySentiment, true)) {
      columns.push({
        title: (
          <HeaderWithIcon>
            <Trans>Sentiment</Trans>
            <Tooltip title={<Trans>Based on percentage of positive comments
             minus percentage of negative comments</Trans>}>
              <StyledInfoIcon type="info-circle"/>
            </Tooltip>
          </HeaderWithIcon>
        ),
        dataIndex: "sentiment",
        key: "sentiment",
        sortOrder: getSortOrder("sentiment", sortParam),
        sorter: true,
        width: 200,
        render: (__, record, index) => {
          return <SentimentCell row={record} index={index} />;
        },
      });
    }
    return columns;
};

export interface CommentsTableProps
  extends TableProps<CommentsResultResource> {
  comments: CommentsResultResource[];
  sort?: SortParameter;
  readonly topicAnalysEnabled?: boolean;
  readonly page: PageFilter;
  readonly onlySugestions?: boolean;
  readonly postAndSurveySentiment?: boolean;
  readonly translateComments?: any;
}

export default function CommentsTableComponent({ 
  comments, onChange, sort, topicAnalysEnabled, page, onlySugestions, postAndSurveySentiment,
  translateComments, ...rest }: CommentsTableProps & RouteComponentProps) {
  let tableColumns = getColumns(sort as any, translateComments, postAndSurveySentiment);
  if (rest.match.path.includes("question")) {
    tableColumns = tableColumns.filter(col => col.key !== "question");
  }
  if (rest.match.path.includes("topic") || !topicAnalysEnabled) {
    tableColumns = tableColumns.filter(col => col.key !== "topic");
  }
  if (!rest.match.path.includes("topic")) {
    tableColumns = tableColumns.filter(col => col.key !== "category");
  }
  let sortedComments: CommentsResultResource[] = [];
  sortedComments = isNotNullNorUndefined(sort) ? sortSurveyComments(
    comments,
    sort
  ) : comments;
  if(onlySugestions) {
    sortedComments = sortedComments.filter(comment => comment.isReco);
  }
  const total = sortedComments.length;

  const onTableChange = (
    pagination: PaginationConfig,
    __: any,
    sorter: SorterResult<any>
  ) => {
    const { current } = pagination;
    const { location } = rest;
    const sortParam: SortParameter = {};
    if (isNotEmptyObject(sorter) && isNotNullNorUndefined(sorter.order)) {
      sortParam[sorter.columnKey] = sorter.order === "ascend" ? 1 : -1;
    }
    replaceLocation({
      ...parseQueryString(location.search),
      sort: sortParam,
      page: current
    });
  };
  return (
    <StyledTable
      columns={tableColumns}
      dataSource={sortedComments}
      rowKey={"_id"}
      pagination={{
        total,
        pageSize: page.size,
        current: page.number,
        // fixme: need to be activated again, and see if we can style it!
        showTotal: (total: number, range: number[]) =>
          <Trans i18nKey="resultsCount"
          values={{start: range[0], end: range[1], total}} 
          defaults={`Showing ${range[0]} to ${range[1]} of ${total} results`}/>,
      }}
      onChange={onTableChange}
      {...rest}
    />
  );
}
// ${props => props.filters ? "100px" : ""}

const CommentsHeader = styled.div`
  width: 450px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTable = styled(Table)`
  .ant-table-body > table tr > td {
    vertical-align: top;

    &:first-child {
      padding-left: 32px !important;
    }
  }

  .ant-table-body > table > thead > tr > th {
    padding: 16px 10px;
  }
`;
