import { FSA } from "flux-standard-action";
import { History } from "history";

export enum ActionTypes {
  LOGOUT = "LOGOUT",
}

interface Payloads {
  [ActionTypes.LOGOUT]: void;
}

export interface LogoutAction extends FSA<ActionTypes.LOGOUT, Payloads[ActionTypes.LOGOUT]> {
  type: ActionTypes.LOGOUT;
  payload: Payloads[ActionTypes.LOGOUT];
}

export function handleLogout() {
  // tslint:disable-next-line:no-console

  return {
    type: ActionTypes.LOGOUT,
  };
}

export function handleLogoClick(history: History) {
  history.push("/");
}
