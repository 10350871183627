import React from "react";
import Modal from "@src/components/core/Modal";
import Button from "@components/core/Button";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { TextArea } from "@src/components/core/TextArea";
import Paragraph from "@src/components/core/Typography/Paragraph";
import { ModalProps } from "antd/lib/modal";
import { Trans } from "react-i18next";

interface Props {
  isSending: boolean;
  visible: boolean;
  errorText?: string;
  comment?: string;
  privateMessageText: string;
  clearPrivateMessageModal: () => void;
  handleMessage?: () => void;
  onOk?: () => void;
  onCancel: ((
    pollId: string,
    postId: string,
    voteId: string,
    commentText: string,
    origin: string
  ) => void);
  updatePrivateMessageText: (privateMessageText: string) => void;
}

export default class SendPrivateMessageModal extends React.Component<Props> {
  render() {
    const {
      visible,
      onOk,
      comment,
    } = this.props;

    return (
      <StyledModal
        visible={visible}
        title={<Trans>Send private message</Trans>}
        centered
        destroyOnClose={true}
        onCancel={this.props.clearPrivateMessageModal}
        footer={null}
      >
        <Description>
          {comment && (
            <CommentContainer>
              <Trans>You're about to send a private email message to the author of:</Trans>
              <CommentText data-cy="comment">
                "{comment}"
              </CommentText>
            </CommentContainer>
          )}
          <Explanation>
            <Trans>Private Messages are an anonymous way of discussing some of the topics within Insights. 
            The recipient will receive an email with your message with the ability to respond directly to it.</Trans>
          </Explanation>
        </Description>
        <ModalContent>
          <TextArea
            data-cy="messageBox"
            // tslint:disable-next-line:jsx-no-lambda
            onChange={(e) => this.props.updatePrivateMessageText(e.target.value)}
            label="Message"
            rows={4}
            value={this.props.privateMessageText}
          />
          <ErrorParagraph>{this.props.errorText}</ErrorParagraph>
        </ModalContent>
        <ActionContainer>
          <StyledButton
            data-cy="send_button"
            color={(this.props.privateMessageText.trim() === "") ? "grey" : "blue"}
            disabled={(this.props.privateMessageText.trim() === "")}
            key="okLabel"
            loading={this.props.isSending}
            onClick={onOk}
            translate="yes"
          >
            {this.props.isSending ? "Sending..." : "Send"}
          </StyledButton>
        </ActionContainer>
      </StyledModal>
    );
  }
}

const ErrorParagraph = styled(Paragraph)`
  color:${Palette.darkPink};
`;

const StyledModal = styled(Modal)<ModalProps & {children: React.ReactNode}>`
  width: 510px !important;
  font-family: Lato;
`;

const Description = styled.div`
  padding: 16px 32px 24px;
  font-family: Lato;
  color: ${Palette.darkBlueGrey};
`;

const CommentText = styled.p`
  color: ${Palette.bluishGrey};
  margin-top: 8px;
`;

const CommentContainer = styled.div`
  color: ${Palette.darkBlueGrey};
  margin-bottom: 24px;
`;

const Explanation = styled.p`
  color: ${Palette.darkBlueGrey};
`;

const StyledButton = styled(Button)`
  margin-top: 8px;
  font-family: Lato;
`;

const ModalContent = styled.div`
  margin-top: 10px;
  padding-left: 32px !important;
  padding-right: 32px !important;
`;

const ActionContainer = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
  padding-left: 32px !important;
  padding-right: 32px !important;
`;
