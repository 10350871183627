import { DashboardSvg } from "@components/core/svg/DashboardSvg";
// import { CategoriesSvg } from "@components/core/svg/CategoriesSvg";
import { SurveysSvg } from "@components/core/svg/SurveysSvg";
import { PulsesSvg } from "@components/core/svg/PulsesSvg";
import { VoicesSvg } from "@components/core/svg/VoicesSvg";
import { ActionPlanSvg } from "@components/core/svg/ActionPlanSvg";
import { UsersSvg } from "@components/core/svg/UsersSvg";
// import { FlagSvg } from "@components/core/svg/FlagSvg";
import { SettingsSvg } from "@components/core/svg/SettingsSvg";
import { SidebarDefinition, SidebarItemDefinition } from "@components/Sidebar/SidebarDefinitions";
import { getRightsForRoute } from "@screens/Insights/MainNavigation/DefaultRoutesConfiguration";
import { Optional } from "@hyphen-lib/lang/Optionals";
import { LifecycleSvg } from "@src/components/core/svg/LifecycleSvg";

export interface ExtendedSidebarItemDefinition extends SidebarItemDefinition {
  readonly locationMatcher: (location: string) => boolean;
  readonly requiredRights: Optional<string[]>;
}

function navigateTo(location: string) {
  return location;
}

export function getDefaultSidebarDefinition() {
  return DEFAULT_SIDEBAR_DEFINITION;
}

// REMOVING /categories , /pulses & /flags for immediate use.
// Reason being they are not implemented.
// Make routes available as and when required.

const DEFAULT_SIDEBAR_DEFINITION: SidebarDefinition<ExtendedSidebarItemDefinition> = {
  mainSection: {
    items: [
      {
        id: "main/dashboard",
        icon: DashboardSvg,
        text: "Dashboard",
        onClick: () => navigateTo("/dashboard"),
        locationMatcher: location => /^\/dashboard/.test(location),
        requiredRights: getRightsForRoute("/dashboard"),
      },
      {
        id: "main/surveys",
        icon: SurveysSvg,
        text: "Surveys",
        onClick: () => navigateTo("/surveys"),
        locationMatcher: location => /^\/surveys/.test(location),
        requiredRights: getRightsForRoute("/surveys"),
      },
      {
        id: "main/pulses",
        icon: PulsesSvg,
        text: "Polls",
        onClick: () => navigateTo("/pulsePolls"),
        locationMatcher: location => /^\/pulsePolls/.test(location),
        requiredRights: getRightsForRoute("/pulsePolls"),
      },
      {
        id: "main/voices",
        icon: VoicesSvg,
        text: "Employee voice",
        onClick: () => navigateTo("/voice/reports/posts"),
        locationMatcher: location => /^\/voice/.test(location),
        requiredRights: getRightsForRoute("/voice"),
      },
      {
        id: "main/actioncenter",
        icon: ActionPlanSvg,
        text: "Action Center",
        onClick: () => navigateTo("/actioncenter"),
        locationMatcher: location => /^\/actioncenter/.test(location),
        requiredRights: getRightsForRoute("/actioncenter"),
      },
      {
        id: "main/lifecycle",
        icon: LifecycleSvg,
        text: "Lifecycle",
        onClick: () => navigateTo("/lifecycle"),
        locationMatcher: location => /^\/lifecycle/.test(location),
        requiredRights: getRightsForRoute("/lifecycle"),
      },
    ],
  },
  adminSection: {
    label: "ADMIN",
    items: [
      {
        id: "admin/users",
        icon: UsersSvg,
        text: "User Management",
        onClick: () => navigateTo("/users"),
        locationMatcher: location => /^\/users/.test(location),
        requiredRights: getRightsForRoute("/users"),
      },
      // {
      //   id: "admin/flags",
      //   icon: FlagSvg,
      //   text: "Flags",
      //   onClick: () => navigateTo("/flags"),
      //   locationMatcher: location => /^\/flags/.test(location),
      //   requiredRights: getRightsForRoute("/flags"),
      // },
      {
        id: "admin/settings",
        icon: SettingsSvg,
        text: "Settings",
        onClick: () => navigateTo("/settings"),
        locationMatcher: location => /^\/settings/.test(location),
        requiredRights: getRightsForRoute("/settings"),
        showNotificationIcon: false,
      },
    ],
  },
};
