import React from "react";
import { Trans, WithTranslation, withTranslation } from "react-i18next";
import { translate } from "@src/utils/i18next";
import Select from "@components/core/SelectLarge";
import styled from "styled-components";
import Button from "@components/core/Button";
import Palette from "@src/config/theme/palette";
import { TextArea } from "@src/components/core/TextArea";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import { Seq } from "immutable";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { isNotNullNorUndefined, isObject } from "hyphen-lib/dist/lang/Objects";

export interface SendSurveyPreviewModalContentProps extends WithTranslation{
  onClick: (emails: string[], message: string) => void;
  emails: string[];
  errors: WrongEntityException.Errors;
}

interface SendSurveyPreviewModalContentState {
  selectedEmails: string[];
  message: string;
  isButtonDisabled: boolean;
}

function returnErrorMessage(e: WrongEntityException.GlobalError |  WrongEntityException.WrongField){
  // @ts-ignore
  return isObject(e.reason) && isNotNullNorUndefined(e.reason.message) ? e.reason.message : e.reason;
}
class SendSurveyPreviewModalContentComponent
  extends React.Component<SendSurveyPreviewModalContentProps, SendSurveyPreviewModalContentState> {
  constructor(props: SendSurveyPreviewModalContentProps) {
    super(props);
    this.state = {
      selectedEmails: [],
      message: "",
      isButtonDisabled: true,
    };
  }
  onButtonClick = () => {
    const { selectedEmails, message } = this.state;
    const { onClick } = this.props;
    onClick(selectedEmails, message);
  };

  handleSelectedEmails = (selectedEmails: any) => {
    // Save the selected emails in state
    const isButtonDisabled = (selectedEmails.length <= 0);
    this.setState({
      selectedEmails,
      isButtonDisabled,
    });
  };

  saveMessage = (e: any) => {
    this.setState({
      message: e.target.value,
    });
  };

  transformErrors = (errors: WrongEntityException.Errors): Optional<string> => {
    if (WrongEntityException.hasNoErrors(errors)) {
      return Optional.empty();
    }

    let serialized = "<ul>";
    serialized += Seq(errors.global).map(e => `<li>${returnErrorMessage(e)}}</li>`).join();
    serialized += errors.fields.valueSeq().map(e => `<li>${returnErrorMessage(e)}}</li>`).join();
    serialized += "</ul>";

    return serialized;
  };

  render() {
    const { emails, errors, t} = this.props;
    const humanReadableErrors = this.transformErrors(errors);
    return (
      <SendSurveyPreviewBody>
        <StyledDiv>
          <Trans>Responses to preview surveys will not be captured as results.</Trans>
        </StyledDiv>
        <StyledLabel><Trans>Send preview to:</Trans></StyledLabel>
        <Select
          mode="multiple"
          size="large"
          placeholder={<Trans>Email address</Trans>}
          numberOfValuesToShow={100}
          allowSelectionByPaste={true}
          onChange={this.handleSelectedEmails}
          data={emails}
          selectedValues={this.state.selectedEmails}
        />
        <StyledLabel>
          <Trans>Message</Trans>
        </StyledLabel>
        <Message
          placeholder={translate(t, "Enter your message here")}
          value={this.state.message}
          onChange={this.saveMessage}
          rows={4}
        />
        <StyledDiv>
          <Trans>Optional</Trans>
        </StyledDiv>
        {
          isNotNullNorUndefined(humanReadableErrors) &&
            <WarningText dangerouslySetInnerHTML={{
            __html : humanReadableErrors
          }}/>
        }
        <ActionContainer>
          <SendPreview
            onClick={this.onButtonClick}
            disabled={this.state.isButtonDisabled}
            translate="yes">
            Send Preview
          </SendPreview>
        </ActionContainer>
      </SendSurveyPreviewBody>

    );
  }
}

export const SendSurveyPreviewModalContent = withTranslation()(
  SendSurveyPreviewModalContentComponent);

const SendSurveyPreviewBody = styled.div`
  padding: 20px 32px !important;
`;

const StyledDiv = styled.div`
  color: ${Palette.bluishGrey} !important;
`;

const StyledLabel = styled.div`
  margin-top: 20px;
`;

const ActionContainer = styled.div`
  display: flex;
  margin-top: 32px;
  justify-content: flex-end;
`;

const SendPreview = styled(Button)`
  background-color: ${Palette.aquaBlue} !important;
  color: white !important;
  transition: none;
`;

const Message = styled(TextArea)`
  height: 100px !important;
`;

const WarningText = styled.div`
  margin-top: 6px;
  color: ${Palette.darkPink};
`;
