import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";

import ContainerCard from "@components/core/ContainerCard";
import Button from "@components/core/Button";
import SlackLogo from "./assets/Slack_RGB.svg";

interface SlackActionProps {
  readonly updateCompany: (payload: CompanyResource["channels"]) => void;
  readonly removeIntegration: () => void;
  readonly integrateWithSlack: () => void;
}

interface SettingsStateProps {
  accessKey: string;
}

type Props = SettingsStateProps & SlackActionProps;

export default class SlackSettings extends React.Component<Props, SettingsStateProps> {
  constructor(props: Props) {
    super(props);

    this.state = {
      accessKey: props.accessKey,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { accessKey } = this.props;
    if (
      prevProps.accessKey !== this.props.accessKey
    ) {
      this.setState({
        accessKey,
      });
    }
  }

  updateSettings = () => {
    const { accessKey } = this.state;
    const payload = {
      slack: {
        accessKey,
      },
    };

    this.props.updateCompany(payload);
  };

  removeIntegration = () => {
    this.props.removeIntegration();
  };

  render() {
    const { accessKey } = this.state;
    return (
      <ContainerCardNew>
        <Holder>
          <Header>
            <ImageContainer src={SlackLogo} />
          </Header>

          {
            accessKey &&
            <Button
              color="gradation"
              onClick={this.removeIntegration}
              translate="yes"
            >
              Remove Integration
            </Button>
          }
        </Holder>
        <Description><Trans>
        The Slack integration uses the messaging power of Slack to fasten 
        response time and allow top content to be posted in real-time in 
        a Slack channel. Once our Slack bot Betty is installed, your employees 
        will receive polls and surveys from Betty on Slack.
        </Trans></Description>
        {
          !accessKey &&
          <SlackButton onClick={this.props.integrateWithSlack} translate="yes">
            <img
              alt="Add to Slack"
              height="40"
              width="139"
              src="https://platform.slack-edge.com/img/add_to_slack.png"
              srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x,
                https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
            />
          </SlackButton>
        }

      </ContainerCardNew>
    );
  }
}
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SlackButton = styled(Button as any)`
  border: none !important;
`;
const ImageContainer = styled.img`
  width: 150px;
  max-width: 100%;
  max-height: 207px;
  margin-right: 20px;
`;

const Holder = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    margin-bottom:10px
`;

const Description = styled.p`
  margin-top: 10px;
  padding-right:88px;
`;

const ContainerCardNew = styled(ContainerCard)`
	margin-bottom:30px;
`;
