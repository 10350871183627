import React from "react";
import { Layout, Row, Col } from "antd";

import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import Navbar from "./Navbar";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  navbarActions?: React.ReactNode[];
  onCloseIconClick?: () => void;
  contentRef?: React.RefObject<any>;
}

function CreationLayout(props: Props) {
  const { children, title, navbarActions, onCloseIconClick, contentRef, ...rest } = props;
  return (
    <>
      <Header>
        <Navbar
          title={title}
          onCancel={onCloseIconClick}
          actions={navbarActions}
        />
      </Header>
      <Content ref={contentRef} {...rest} >
        <Row type="flex" justify="center">
          <Col span={16}>
            {children}
          </Col>
        </Row>
      </Content>
    </>
  );
}

const Header = styled(Layout.Header)`
  padding: 0 !important;
  height: 60px !important;
`;

const Content = styled.div`
  padding: 48px 0;
  overflow-y: auto;
  height: calc(100vh - 60px);
  background-color: ${Palette.paleGrey};
`;

export default CreationLayout;
