import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";

import { HighlightedSegment } from "hyphen-lib/dist/domain/resource/report/common/Highlights";
import { Optional } from "hyphen-lib/dist/lang/Optionals";

import CreateActionPlanButton from "@src/screens/Insights/components/Reports/CreateActionPlanButton";
import CompareValue from "@components/core/CompareValue";
import { Score } from "@screens/Insights/components/Reports/Score";

import { extractComparison } from "hyphen-lib/dist/business/calculation/benchmark/Benchmarks";
import { getOr, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { getSegmentLabel } from "hyphen-lib/dist/domain/common/Dimensions";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";

import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { FocusArea, Module } from "hyphen-lib/dist/domain/FocusArea";
import { MatchParams } from "@src/screens/Insights/PulsePoll/containers/PulsePollsDetails";
import { sanitizeSegmentLabel } from "hyphen-lib/dist/domain/common/Dimensions";


export type OwnProps = RouteComponentProps;

interface Props extends RouteComponentProps<{ id: string }> {
  readonly dimensionLabel: string;
  readonly data: HighlightedSegment;
  readonly subtitle?: Optional<string>;
  readonly isComparisonVisible?: boolean;
  readonly comparisonLabel: Optional<string>;
  readonly comparisonKey: Optional<string>;
  readonly hasActionCreationRight: boolean;
  readonly segmentType:
  | FocusArea.Source.STRONGEST_SEGMENTS
  | FocusArea.Source.WEAKEST_SEGMENTS
  | FocusArea.Source.BIGGEST_DECLINE
  | FocusArea.Source.BIGGEST_IMPROVEMENTS;
  readonly focusAreaLabel: string;
}

interface SegmentActionProps {
}

export type SegmentProps = {
  readonly onCreateFocusArea: (focusArea: Partial<FocusAreaResource>) => void;
} & Props &
  OwnProps &
  SegmentActionProps &
  RouteComponentProps<MatchParams>;

export const SegmentComponent = ({
  dimensionLabel,
  data: { dimension, segment, score, compare },
  subtitle,
  isComparisonVisible = true,
  comparisonLabel,
  comparisonKey,
  hasActionCreationRight,
  focusAreaLabel,
  ...props
}: SegmentProps) => {

  const segmentLabel = getSegmentLabel(segment);
  function onCreateActionPlan() {
    if (props.location.pathname === "/dashboard") {
      props.onCreateFocusArea({
        title: segmentLabel,
        source: props.segmentType,
        module: Module.DASHBOARD,
        focusAreaType: FocusArea.FocusAreaType.SEGMENT,
        sourceUrl: {
          label: focusAreaLabel,
          url: window.location.href,
        },
        dimension,
        actionPlans: []
      });
    } else {
      props.onCreateFocusArea({
        title: segmentLabel,
        source: props.segmentType,
        module: Module.SURVEY,
        focusAreaType: FocusArea.FocusAreaType.SEGMENT,
        sourceUrl: {
          label: focusAreaLabel,
          url: window.location.href,
        },
        dimension,
        actionPlans: [],
        surveyId: props.match.params.id
      });
    }
  }

  const comparison = extractComparison(compare, comparisonKey);
  return (
    <>
      <Container subtitleAvailable={isStringAndNotEmpty(subtitle)}>
        {hasActionCreationRight && (
          <CreateActionPlanButtonContainer >
            <CreateActionPlanButton onClick={onCreateActionPlan} showOnHover />
          </CreateActionPlanButtonContainer>
        )}
        <SegmentAndDimensionKeyContainer data-cy={`segment_${segmentLabel}`}>
          <div>{sanitizeSegmentLabel(segmentLabel)}</div>
          <DimensionLabelText>{dimensionLabel}</DimensionLabelText>
          {isStringAndNotEmpty(subtitle) && (
            <SubtitleText>{subtitle}</SubtitleText>
          )}
        </SegmentAndDimensionKeyContainer>
        <ScoreContainer data-cy={`score_${segmentLabel}`}>
          <Score size="small" score={score} format="favorability" />
        </ScoreContainer>
        {isComparisonVisible &&
          isNotNullNorUndefined(comparison) &&
          isNotNullNorUndefined(comparisonLabel) && (
            <CompareContainer>
              <CompareValueContainer>
                <CompareValue
                  compare={comparison}
                  compareWith={getOr(comparisonLabel, "average")}
                />
              </CompareValueContainer>
            </CompareContainer>
          )}
      </Container>
    </>
  );
};

const CompareContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 160px;
  margin-left: 8px;
  align-items: flex-end;
  color: ${Palette.bluishGrey};
  font-size: 12px;
`;

const CreateActionPlanButtonContainer = styled.div`
  display: none;
  position: absolute;
  top: 50%;
  left: 0;
`;

const ScoreContainer = styled.div`
  margin-left: 8px;
`;

const CompareValueContainer = styled.div`
  span {
    font-size: 14px;
  }
`;

const DimensionLabelText = styled.div`
  margin-top: 4px;
  color: ${Palette.bluishGrey};
`;

export const SubtitleText = styled.div`
  margin-top: 4px;
  color: ${Palette.bluishGrey};
  font-size: 12px;
  display: none;
`;

const Container = styled.div<{ subtitleAvailable: boolean }>`
  position: relative;
  margin-top: 16px;
  font-size: 16px;
  height: 64px;
  line-height: 16px;
  border-bottom: 1px solid ${Palette.lightPeriwinkle};
  background: ${Palette.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 32px;

  &:hover {
    cursor: default;
    border-color: ${Palette.darkModerateBlue};
  }

  &:hover ${CreateActionPlanButtonContainer} {
    display: block;
  }

  &:hover ${DimensionLabelText} {
    display: ${(props) => (props.subtitleAvailable ? "none" : "block")};
  }

  &:hover ${SubtitleText} {
    display: ${(props) => (props.subtitleAvailable ? "block" : "none")};
  }
`;

const SegmentAndDimensionKeyContainer = styled.div`
  font-size: 14px;
  flex-grow: 1;
`;

export default withRouter(SegmentComponent);
