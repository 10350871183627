import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import ArtificialInteligence from "@src/components/core/svg/ArtificialInteligence";
import { Trans } from "react-i18next";
import { fontSizeConverter } from "@src/utils/Graphs";
import { TopicOverviewResource } from "hyphen-lib/dist/domain/resource/survey/report/TopicOverviewResource";
import WordCloud from "@src/screens/Insights/Survey/components/Topics/components/WordCloud";
import { CustomAITag, getTopicsChartData } from "@src/screens/Insights/Survey/components/Topics/SurveyTopicsChart";
interface OwnProps {
  readonly topics: TopicOverviewResource[];
  readonly width: number;
};

function WordCloudCell({ topics, width }: OwnProps) {

  const topicsCloud = getTopicsChartData(topics);
  const words = topicsCloud.data.slice(0, 5);
  words.forEach(word => {
    word.size = fontSizeConverter(word.value, topicsCloud.min, topicsCloud.max, 9, 14);
  });

  return <Container >
    <CustomAITag color={Palette.tequila} icon={<ArtificialInteligence />} data-jest="AI-based">
      <Trans>AI-based</Trans>
    </CustomAITag>
    <WordCloud topics={words} width={width} height={80} 
    noDescription={true}  minFontSize={12} maxFontSize={18} padding={5}/>
  </Container>;

};

const Container = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default WordCloudCell;
