import React from "react";
import Palette from "@src/config/theme/palette";
import { Tag } from "@components/core/Tag";
import { Sentiment } from "@hyphen-lib/domain/common/ComputationTypes";

interface Props {
  sentiment?: Sentiment;
  translate?: string;
}

export function SentimentTag({ sentiment }: Props) {
  const { text, color, textColor } = mapSentimentToTextAndColor(sentiment);
  return (
    <Tag color={color} textColor={textColor} translate="yes">{text}</Tag>
  );
}

export function mapSentimentToTextAndColor(sentiment?: Sentiment): { text: string; color: string; textColor?: string } {
  switch (sentiment) {
    case Sentiment.POSITIVE:
      return {
        text: "Positive",
        color: Palette.aquaBlue,
        textColor: Palette.white,
      };
    case Sentiment.NEUTRAL:
      return {
        text: "Neutral",
        color: Palette.lightPeriwinkle,
      };
    case Sentiment.NEGATIVE:
      return {
        text: "Negative",
        color: Palette.darkPink,
        textColor: Palette.white,
      };
    default:
      return {
        text: "Unknown",
        color: Palette.paleGrey,
      };
  }
}
