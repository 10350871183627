/* eslint-disable max-len */
import { Record } from "immutable";
import { stateFactory as networkStateFactory } from "@store/network/reducers";
import { stateFactory as insightsStateFactory } from "@screens/Insights/store/reducers";
import { stateFactory as insightsSurveysStateFactory } from "@screens/Insights/Surveys/store/reducers";
import { stateFactory as notificationsStateFactory } from "@store/notifications/reducers";
import { stateFactory as filtersStateFactory } from "@screens/Insights/Survey/containers/FiltersContainer/store/reducers";
import { stateFactory as participationStateFactory } from "@screens/Insights/Survey/containers/store/reducers";
import { stateFactory as surveyRemindersStateFactory } from "@screens/Insights/Survey/containers/SurveyRemindersContainer/store/reducers";
import { stateFactory as insightsSurveyListStateFactory } from "@screens/Insights/SurveyList/store/reducers";
import { stateFactory as insightsSurveyListFilterStateFactory } from "@screens/Insights/SurveyList/containers/SurveyListFilterContainer/store/reducers";
import { userListContainerStateFactory } from "@screens/Insights/UserManagement/containers/UserListContainer/store/reducers";
import { userListFilterContainerStateFactory } from "@screens/Insights/UserManagement/containers/UserListFilterContainer/store/reducers";
import { userPermissionsStateFactory } from "@screens/Insights/UserManagement/containers/UserPermissions/store/reducers";
import { userPermissionsFilterContainerStateFactory } from "@screens/Insights/UserManagement/containers/UserPermissionsFilterContainer/store/reducers";
import { usersAndDimensionsStateFactory } from "@src/screens/Insights/AddUsersAndDimensions/store/reducers";
import { focusAreaListContainerStateFactory, stateFactory as actionsStateFactory } from "@src/screens/Insights/Actions/store/reducers";
import { actionListFilterContainerStateFactory } from "@screens/Insights/Actions/containers/ActionListFilterContainer/store/reducers";
import { actionPlanStateFactory } from "@screens/Insights/ActionPlans/store/reducers";
import { userRolesStateFactory } from "@src/screens/Insights/UserManagement/containers/UserRoles/store/reducers";
import { surveyReportStateFactory } from "@screens/Insights/Survey/store/reducers";
import { parametersStateFactory } from "@screens/Insights/parameters/store/reducers";
import { settingsStateFactory } from "@screens/Insights/Settings/store/reducers";
import { pulsePollListStateFactory } from "@screens/Insights/PulsePollList/store/reducers";
import { pulsePollStateFactory } from "@screens/Insights/PulsePoll/store/reducers";
import { surveyEditStateFactory } from "@screens/Insights/Surveys/store/surveyEditReducers";
import { pulsePollEditStateFactory } from "@screens/Insights/PulsePolls/store/pulsePollEditReducers";
import {
  voiceReportsStateFactory
} from "@src/screens/Insights/EmployeeVoice/VoiceReports/store/reducers";
import { segmentReportCommentsStateFactory } from "@src/screens/Insights/Segments/SegmentReport/store/reducers";
import { batarangScriptsStateFactory } from "@screens/Insights/Settings/containers/batarang/store/batarangScriptsReducers";
import { StateProps } from "./types";
import { bottomDrawerStateFactory } from "@src/screens/Insights/BottomDrawer/reducers";
import { xlsxStateFactory } from "@src/screens/Insights/Xlsx/reducers";
/* eslint-disable max-len */

const defaultValues: StateProps = {
  network: networkStateFactory(),
  insights: insightsStateFactory(),
  insights_parameters: parametersStateFactory(),
  insights_batarang_scripts: batarangScriptsStateFactory(),
  insights_surveyList: insightsSurveyListStateFactory(),
  insights_surveyList_filter: insightsSurveyListFilterStateFactory(),
  insights_surveys: insightsSurveysStateFactory(),
  insights_surveyEdit: surveyEditStateFactory(),
  insights_pulsePollEdit: pulsePollEditStateFactory(),
  insights_filters: filtersStateFactory(),
  insights_participation: participationStateFactory(), // fixme: we are storing the menu there :/ At least rename that!
  insights_survey_reminders: surveyRemindersStateFactory(),
  insights_userList: userListContainerStateFactory(),
  insights_focusAreas: focusAreaListContainerStateFactory(),
  insights_userList_filter: userListFilterContainerStateFactory(),
  insights_userPermissions: userPermissionsStateFactory(),
  insights_userPermissions_filter: userPermissionsFilterContainerStateFactory(),
  insights_userRoles: userRolesStateFactory(),
  notification: notificationsStateFactory(),
  insights_users_and_dimensions: usersAndDimensionsStateFactory(),
  insights_action_plans: actionPlanStateFactory(),
  insights_actionList: actionsStateFactory(),
  insights_actionList_filter: actionListFilterContainerStateFactory(),
  insights_survey_report: surveyReportStateFactory(),
  insights_settings: settingsStateFactory(),
  insights_pulse_poll_list: pulsePollListStateFactory(),
  insights_voice_reports: voiceReportsStateFactory(),
  insights_segment_reports: segmentReportCommentsStateFactory(),
  insights_bottom_drawer: bottomDrawerStateFactory(),
  insights_xlsx: xlsxStateFactory(),
  pulse_poll: pulsePollStateFactory(),
};

export const stateFactory = () => {
  /*
      The network store is not immutable, so we need to create a fresh state,
      and not use the default value each time we are creating a fresh new state using default.
      As default has been mutated.
   */
  let state = Record<StateProps>(defaultValues)();
  state = state.set("network", networkStateFactory());
  return state;
};
