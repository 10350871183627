import React from "react";
import {
  BatarangScriptDefinitionsResource
} from "hyphen-lib/dist/domain/resource/batarang/BatarangScriptDefinitionsResource";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { BatarangDryRunResultsResource } from "hyphen-lib/dist/domain/resource/batarang/BatarangDryRunResultsResource";
import { BatarangApplyResultsResource } from "hyphen-lib/dist/domain/resource/batarang/BatarangApplyResultsResource";
import { isNotNullNorUndefined, isNullOrUndefined, isNotEmptyObject } from "hyphen-lib/dist/lang/Objects";
import { Icon } from "antd";
import styled from "styled-components";
import Button from "@src/components/core/Button";
import { ExportSvg } from "@components/core/svg/ExportSvg";
import { Loading } from "@src/screens/Insights/Survey/components/Loading";
import { BatarangParameters } from "@screens/Insights/Settings/components/batarang/BatarangParameters";
import { BatarangExecutionResult } from "@screens/Insights/Settings/components/batarang/BatarangExecutionResult";
import { BatarangApplyToken } from "hyphen-lib/dist/domain/resource/batarang/BatarangApplyToken";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { BatarangResult } from "hyphen-lib/dist/domain/resource/batarang/BatarangResult";
import { fetchAssetById } from "@src/utils/exports";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import { AssetUpload } from "../../containers/batarang/store/batarangScriptsReducers";
import { Trans } from "react-i18next";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";

export interface BatarangScriptComponentProps {
  readonly definition: BatarangScriptDefinitionsResource.Script;
  readonly values: Dictionary<any>;
  readonly context: Dictionary<any>;
  readonly dryRunExecutionResults: Optional<Loadable<BatarangDryRunResultsResource>>;
  readonly executionResults: Optional<Loadable<BatarangApplyResultsResource>>;
  readonly error?: Optional<string> | Optional<WrongEntityException.GlobalError>;
  readonly assetUpload?: Optional<AssetUpload>;
  readonly onParametersChange: (parameters: Dictionary<any>) => any;
  readonly onExecuteDryRun: (parameters: Dictionary<any>) => any;
  readonly onExecute: (parameters: Dictionary<any>, applyToken: BatarangApplyToken) => any;
  readonly onCancel: () => any;
  readonly onFinish: () => any;
  readonly onAssetUpload: (file: File) => any;
  readonly company?: Optional<CompanyResource>;
}

export class BatarangScriptComponent extends React.Component<BatarangScriptComponentProps> {

  handleExecuteDryRun = () => {
    const {
      values,
      onExecuteDryRun,
      assetUpload,
    } = this.props;
    const mergedParams = this.mergeAllParams(values, assetUpload);
    onExecuteDryRun(mergedParams);
  };

  handleExecute = () => {
    const {
      values,
      dryRunExecutionResults,
      onExecute,
      assetUpload,
    } = this.props;

    if (isNotNullNorUndefined(dryRunExecutionResults) &&
      Loadable.isLoaded(dryRunExecutionResults) &&
      dryRunExecutionResults.value.executable) {
      const mergedParams = this.mergeAllParams(values, assetUpload);
      onExecute(mergedParams, dryRunExecutionResults.value.applyToken);
    }
  };

  mergeAllParams = (values: Dictionary<any>, assetUpload: Optional<AssetUpload>) => {
    let params = { ...values };

    if (isNotEmptyObject(assetUpload)) {
      params = { ...params, ...assetUpload };
    }
    return params;
  };

  renderDownloadButton = () => {
    const { executionResults } = this.props;
    if (isNotNullNorUndefined(executionResults) && Loadable.isLoaded(executionResults)) {
      if (executionResults.value.result.type === BatarangResult.Type.RESULTS) {
        if (executionResults.value.result.results.type === "BatarangAsset") {
          const assetId = executionResults.value.result.results.id;
          return (
            <a onClick={() => fetchAssetById(assetId)} download={true}>
              <Button
                color="blue"
              >
                <Icon component={ExportSvg} />
                <span><Trans>Download</Trans></span>
              </Button>
            </a>

          );
        }
      }
    }
  };

  render() {
    const {
      definition: {
        description,
        parameters,
      },
      values,
      context,
      dryRunExecutionResults,
      executionResults,
      error,
      onParametersChange,
      onCancel,
      onAssetUpload,
      onFinish,
      company
    } = this.props;
    return (
      <ScriptContainer>
        {
          isNotNullNorUndefined(description) &&
          <>
            <SectionHeader><Trans>Description</Trans></SectionHeader>
            <Description>{description}</Description>
          </>
        }
        {
          isNotEmptyArray(parameters) &&
          <>
            <SectionHeader><Trans>Configuration</Trans></SectionHeader>
            <BatarangParameters
              parameters={parameters}
              values={values}
              context={context}
              onChange={onParametersChange}
              disabled={isNotNullNorUndefined(dryRunExecutionResults)}
              onAssetUpload={onAssetUpload}
              company={company}
            />
          </>
        }
        {
          (isNullOrUndefined(dryRunExecutionResults) || Loadable.isNotLoaded(dryRunExecutionResults)) &&
          <ActionBar>
            <Button
              color="blue"
              onClick={this.handleExecuteDryRun}
              disabled={isNotNullNorUndefined(dryRunExecutionResults)}
              translate="yes"
            >
              Dry Run
            </Button>
          </ActionBar>
        }
        {
          isNotNullNorUndefined(dryRunExecutionResults) &&
          <>
            <SectionHeader><Trans>Dry run results</Trans></SectionHeader>
            {
              Loadable.isNotLoaded(dryRunExecutionResults) ?
                <Loading />
                :
                <>
                  <BatarangExecutionResult
                    result={dryRunExecutionResults.value.result}
                  />
                  {
                    (isNullOrUndefined(executionResults) || Loadable.isNotLoaded(executionResults)) &&
                    dryRunExecutionResults.value.executable &&
                    <ActionBar>
                      <Button
                        color="grey"
                        onClick={onCancel}
                        disabled={isNotNullNorUndefined(executionResults)}
                        translate="yes"
                      >
                        Cancel
                      </Button>
                      <Button
                        color="red"
                        onClick={this.handleExecute}
                        disabled={isNotNullNorUndefined(executionResults)}
                        translate="yes"
                      >
                        Apply
                      </Button>
                    </ActionBar>
                  }
                  {
                    (isNullOrUndefined(executionResults) || Loadable.isNotLoaded(executionResults)) &&
                    !dryRunExecutionResults.value.executable &&
                    <ActionBar>
                      <Button
                        color="grey"
                        onClick={onCancel}
                        translate="yes"
                      >
                        Cancel
                      </Button>
                    </ActionBar>
                  }
                  {
                    isNotNullNorUndefined(executionResults) &&
                    <>
                      <SectionHeader>Execution results</SectionHeader>
                      {
                        Loadable.isNotLoaded(executionResults) ?
                          <Loading />
                          :
                          <>
                            <BatarangExecutionResult
                              result={executionResults.value.result}
                            />
                            <ActionBar>
                              {this.renderDownloadButton()}
                              <Button
                                color="grey"
                                onClick={onFinish}
                                translate="yes"
                              >
                                Finish
                              </Button>
                            </ActionBar>
                          </>
                      }
                    </>
                  }
                </>
            }
          </>
        }
        {
          isNotNullNorUndefined(error) &&
          <Error>
            {isNotEmptyArray(error) ? error.map(err => err.reason) : error}
          </Error>
        }
      </ScriptContainer>
    );
  }
}

const ScriptContainer = styled.div`
  margin: 0 0 0 30px;
`;

const SectionHeader = styled.div`
  margin: 30px 0 10px -30px;
  font-weight: bold;
  font-size: 14pt;
`;

const Description = styled.span`
  font-style: italic;
`;

const ActionBar = styled.div`
  display: flex;
  justify-content: center;
  margin: 15px 0;
  & > Button {
    margin: 0 15px 0 0
  }
  & > a {
    margin: 0 15px 0 0
  }
`;

const Error = styled.div`
  margin: 30px 0 0 0;
  color: red;
  font-weight: bolder;
`;
