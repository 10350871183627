import React from "react";
import { RadioChangeEvent } from "antd/lib/radio";
import styled from "styled-components";

import { BaseViewOptionProps } from "@screens/Insights/components/ViewOptions/components/ViewOptionsContent";
import Radio from "@components/core/Radio";
import Palette from "@src/config/theme/palette";
import Select from "@components/core/Select";
import { isNotNullNorUndefined, getOr } from "hyphen-lib/dist/lang/Objects";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { isEmpty } from "hyphen-lib/dist/lang/Arrays";
import { SelectProps } from "antd/lib/select";
import { extractViewOptionsKeyFromLabel } from "@src/utils/ViewOptions";
import { Trans } from "react-i18next";

const Option = Select.Option;

const RadioGroup = Radio.Group;

export type CompareWithOption = CompareWithSingleOption | CompareWithMultipleOption;

export interface CompareWithSingleOption {
  readonly type: "single";
  readonly key: string;
  readonly label: string;
  readonly columnLabel?: string;
}

export interface CompareWithMultipleOption {
  readonly type: "multiple";
  readonly key: string;
  readonly label: string;
  readonly defaultValue: string;
  readonly options: OptionForMultipleOption[];
}

export interface OptionForMultipleOption {
  readonly key: string;
  readonly label: string;
  readonly columnLabel?: string;
}

export interface CompareWithProps extends BaseViewOptionProps<string> {
  readonly options: CompareWithOption[];
}

export interface CompareWithState {
  readonly lastMultipleOptionValues: Dictionary<string>;
}

export class CompareWith extends React.Component<CompareWithProps, CompareWithState> {
  state: CompareWithState = {
    lastMultipleOptionValues: {},
  };

  handleChange = (e: RadioChangeEvent) => {
    const {
      options,
      onChange,
    } = this.props;

    const {
      lastMultipleOptionValues,
    } = this.state;

    const optionKey = e.target.value;
    const chosenOption = options.find(o => o.key === optionKey);
    if (isNotNullNorUndefined(chosenOption)) {
      if (chosenOption.type === "single") {
        // dispatch the change, only if the type of the option is single,
        // otherwise we want to dispatch the value of the drop down
        onChange(optionKey);
      } else {
        onChange(getOr(
          lastMultipleOptionValues[optionKey],
          extractViewOptionsKeyFromLabel(chosenOption.defaultValue, options)
        ));
      }
    }
  };

  handleMultipleOptionChange = (multipleOptionKey: string, optionKey: string) => {
    this.setState(state => ({
      lastMultipleOptionValues: {
        ...state.lastMultipleOptionValues,
        [optionKey]: multipleOptionKey,
      },
    }));
    this.props.onChange(multipleOptionKey);
  };

  render() {
    const {
      label,
      value,
      isDisabled = false,
      options,
    } = this.props;

    const {
      lastMultipleOptionValues,
    } = this.state;

    // search the value
    const radioOptionSelected = Optional.map(
      options.find(
        option =>
          (option.type === "single" && option.key === value) ||
          (option.type === "multiple" && option.options.some(o => o.key === value || o.label === value))
      ),
      option => option.key
    );

    if (isEmpty(options)) {
      return null;
    }

    return (
      <div>
        <span><Trans>{label}</Trans></span>
        <StyledRadioGroup onChange={this.handleChange} value={radioOptionSelected} disabled={isDisabled}>
          {
            options.map(option => this.renderOption(option, value, lastMultipleOptionValues[option.key]))
          }
        </StyledRadioGroup>
      </div>
    );
  }

  renderOption(option: CompareWithOption, value: Optional<string>, lastMultipleOptionValue: Optional<string>) {
    switch (option.type) {
      case "single":
        return this.renderSingleOption(option);
      case "multiple":
        return this.renderMultipleOption(option, value, lastMultipleOptionValue);
    }
  }

  // noinspection JSMethodCanBeStatic
  renderSingleOption(option: CompareWithSingleOption) {
    return (
      <Radio
        key={option.key}
        value={option.key}
      >
        <Trans>{option.label}</Trans>
      </Radio>
    );
  }

  renderMultipleOption(option: CompareWithMultipleOption,
    value: Optional<string>,
    lastMultipleOptionValue: Optional<string>) {

    const isSelected = option.options.some(o => o.key === value);
    const onChange = (val: any) => this.handleMultipleOptionChange(val, option.key);

    return (
      <Radio
        key={option.key}
        value={option.key}
      >
        <Trans>{option.label}</Trans>
        <SelectForMultipleOption
          defaultValue={isSelected ? value! : getOr(lastMultipleOptionValue, option.defaultValue)}
          onChange={onChange}
        >
          {
            option.options.map(o =>
              <Option
                key={o.key}
                value={o.key}
              >
                {o.label}
              </Option>
            )
          }
        </SelectForMultipleOption>
      </Radio>
    );
  }
}

export const StyledRadioGroup = styled(RadioGroup)`
  display: flex !important;
  flex-direction: column !important;

  .ant-radio-button-wrapper-checked {
    border-color: ${Palette.darkBlueGrey} !important;
    box-shadow: -1px 0 0 0 ${Palette.darkBlueGrey} !important;
    color: ${Palette.darkBlueGrey} !important;
  }
  label {
    margin-top: 8px;
    font-size: 15px;
  }

  label:hover {
    color: ${Palette.darkBlueGrey};
  }
`;

const SelectForMultipleOption = styled(Select)<SelectProps & {children: React.ReactNode}>`
  margin-left: 20px;
  display: inline-block;
  width: 350px;
`;
