import React from "react";
import styled from "styled-components";

import { adjustElementsAndPercentages } from "@src/utils/Graphs";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import BarSegment, { BarSegmentData } from "./components/BarSegment";

interface Props {
  data: BarSegmentData[];
  width?: string;
  height?: string;
  hideSingleDigitLabel?: boolean;
  skipWidthAdjustment?: boolean;
  numberOfVotes?: number;
}

export function StackedBarGraph({ data,
  width = "300px",
  height = "30px",
  hideSingleDigitLabel = false,
  skipWidthAdjustment = false,
  numberOfVotes}: Props) {

  const { elements, pieceToAdd } = adjustElementsAndPercentages(data);

  let consolidatedPieceToAdd = pieceToAdd;
  if (skipWidthAdjustment) {
    consolidatedPieceToAdd = 0;
  }

  return (
    <Container height={height}>
      {elements.map(
        ({ value, percentage, label, backgroundColor, fontColor }, index) => {
          let percentageWidth = 0;
          if (
            isNotNullNorUndefined(percentage) &&
            typeof percentage === "number" &&
            percentage > 0
          ) {
            percentageWidth = percentage + consolidatedPieceToAdd;
          }

          if (percentageWidth > 0) {
            return (
              <BarSegment
                key={index}
                value={value}
                percentage={percentage}
                percentageWidth={percentageWidth}
                label={label}
                backgroundColor={backgroundColor}
                fontColor={fontColor}
                height={height}
                hasSingleValue={data.length <= 1}
                hideSingleDigitLabel={hideSingleDigitLabel}
                numberOfVotes = {numberOfVotes}
              />
            );
          }

          return null;
        }
      )}
    </Container>
  );
}

const Container = styled.div<{ height: string }>`
  display: flex;
  flex: 1;
  height: ${props => props.height};

  div:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  div:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`;
