import { Question } from "@src/screens/Insights/Surveys/containers/SurveyQuestions";
import { QuestionType } from "hyphen-lib/dist/domain/common/QuestionType";
import { getOr } from "hyphen-lib/dist/lang/Objects";
import {
  getFlagCode,
  getLanguageStringFromCode,
  LocaleCodes,
} from "hyphen-lib/dist/util/locale";
import {
  NotificationChannel
} from "hyphen-lib/dist/domain/NotificationChannels";
import { SurveyResource } from "@hyphen-lib/domain/resource/SurveyResource";
import { SurveyQuestionResource } from "hyphen-lib/dist/domain/resource/SurveyQuestionResource";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";

export function getTranslatedSegmentFromStringifiedTranslation(
  code: LocaleCodes,
  translation?: string | object
) {
  if (typeof translation === "string") {
    const parsedIntroductionText = getParsed(getOr(translation, "{}"));
    return getOr(parsedIntroductionText[code], "");
  } else {
    const parsedText = getOr(translation, {});
    // @ts-ignore
    return getOr(parsedText[code], "");
  }
}

function getParsedTranslationText(translation?: string | object) {
  let parsedIntroductionText = {};
  if (typeof translation === "string") {
    parsedIntroductionText = getParsed(getOr(translation, "{}"));
  } else {
    parsedIntroductionText = getOr(translation, {});
  }

  return parsedIntroductionText;
}

export function filterEnglishCodes(languageCodes: string[]) {
  return languageCodes.filter((code) => /^en/i.test(code) === false);
}

export function getLanguageInformationFromStringifiedTranslation(
  configuredSurveyLanguages?: string[],
  translation?: string | object
) {
  const parsedIntroductionText = getParsedTranslationText(translation);

  const surveyLanguages = filterEnglishCodes(
    getOr(configuredSurveyLanguages, [])
  );

  let totalTranslated = 0;
  if (Object.keys(parsedIntroductionText).length > 0) {
    for (const lang of surveyLanguages) {
      // @ts-ignore
      if (parsedIntroductionText[lang] && parsedIntroductionText[lang].trim()) {
        totalTranslated++;
      }
    }
  }
  return {
    totalTranslated,
    totalLanguages: surveyLanguages.length,
  };
}

export function getOverallQuestionTranslationStats(
  question: Question,
  configuredSurveylanguages?: string[]
) {
  const parsedQuestionTranslation: Record<string, string> =
    getParsedTranslationText(question.question_t);
  const parsedCatgeoryTranslation: Record<string, string> =
    getParsedTranslationText(question.category_t);

  const finalStatMap: Record<string, number> = {};

  for (const code in parsedQuestionTranslation) {
    if (parsedQuestionTranslation[code] &&
       configuredSurveylanguages?.includes(code)) {
      finalStatMap[code] = 1;
    }
  }

  for (const code in parsedCatgeoryTranslation) {
    if (parsedCatgeoryTranslation[code] &&
        configuredSurveylanguages?.includes(code)) {
      if (finalStatMap[code]) {
        finalStatMap[code] += 1;
      } else {
        finalStatMap[code] = 1;
      }
    }
  }

  let totalCategoriesToCheck = 2;

  if (question.type !== QuestionType.OPEN_TEXT) {
    totalCategoriesToCheck = 3;
    const { multiChoiceStatMap } = getTranslationStatsForMultipleChoice(
      question.choices.length,
      // @ts-ignore
      getOr(question.choices_t, []),
      getOr(configuredSurveylanguages, [])
    );

    for (const code in multiChoiceStatMap) {
      if (multiChoiceStatMap[code] === question.choices.length) {
        if (finalStatMap[code]) {
          finalStatMap[code] += 1;
        } else {
          finalStatMap[code] = 1;
        }
      }
    }
  }

  const listOfLanguagesCompletelyTranslated: string[] = [];
  for (const code in finalStatMap) {
    if (finalStatMap[code] % totalCategoriesToCheck === 0) {
      /**
       * Since we are checking for 3(or2) translation categories
       *  - question
       *  - choices
       *  - category
       *
       * we check if the total number is divisible by 3. For translation
       * categories, we will need to check the divisibility by 4
       */
      listOfLanguagesCompletelyTranslated.push(code);
    }
  }

  return {
    totalLanguages: getOr(configuredSurveylanguages, []).length,
    totalTranslated: listOfLanguagesCompletelyTranslated.length,
  };
}

export function getParsed(translation: object | string) {
  if ("string" === typeof translation) {
    try {
      return JSON.parse(translation);
    } catch (err) {
      return {};
    }
  } else {
    return { ...translation };
  }
}

export function getTranslationStatsForMultipleChoice(
  maxChoices: number,
  choicesTranslation: string[],
  configuredSurveyLanguages: string[]
) {
  const statMap: { [key: string]: number } = {};

  /**
   * The following statement handles the case where the maxChoices < choicesTranslation.length
   * It is possible for the user to remove the option and keep editing offline.
   */
  Array(maxChoices).fill(null).forEach((_, index) => {
    const parsed = getParsed(choicesTranslation[index]);
    for (const code in parsed) {
      // Done to check for empty strings
      // Empty string mapped to a language code
      // will still be considered as an incomplete translation setup
      if (
        parsed[code] &&
        parsed[code].trim() &&
        configuredSurveyLanguages.includes(code)
      ) {
        if (statMap[code]) {
          statMap[code] += 1;
        } else {
          statMap[code] = 1;
        }
      }
    }
  });

  let totalTranslated = 0;
  for (const lCode in statMap) {
    if (statMap[lCode] === maxChoices) {
      totalTranslated++;
    }
  }
  return {
    totalLanguages: configuredSurveyLanguages.length,
    totalTranslated,
    multiChoiceStatMap: statMap,
  };
}

export function getFlagURL(code: string) {
  return `https://flagcdn.com/${getFlagCode(code)}.svg`;
}

export function LabelWithFlag(code: LocaleCodes) {
  const langString = getLanguageStringFromCode(code);
  return (
    <>
      <div className="d-flex flex-row" data-cy="question_languages">
        <div>
          <img
            style={{ borderRadius: "0.25em" }}
            width="22"
            height="16"
            src={getFlagURL(code)}
            title={code}
            alt={code}
          />
        </div>
        <div
          className="d-flex align-items-center"
          style={{ paddingLeft: "0.5em", fontSize: "14px" }}
        >
          {langString}
        </div>
      </div>
    </>
  );
}

export function getChannelTranslationStatus(
  channel: any,
  configuredSurveyLanguages: string[]
) {
  const bodyTranslation = getLanguageInformationFromStringifiedTranslation(
    configuredSurveyLanguages,
    (channel as NotificationChannel.SubjectAndBodyContext)
      .body_t as string
  );
  if(bodyTranslation.totalLanguages !== bodyTranslation.totalTranslated){
    return true;
  }
  if (Object.keys(channel).includes("subject_t")){
    const subjectTranslation = getLanguageInformationFromStringifiedTranslation(
      configuredSurveyLanguages,
      (channel as NotificationChannel.SubjectAndBodyContext)
        .subject_t as string
    );
    if(subjectTranslation.totalLanguages !== subjectTranslation.totalTranslated){
      return true;
    }
  }
  return false;
}

export function getOverallSurveyTranslationStatus(
  configuredSurveyLanguages: string[],
  survey: SurveyResource,
  questions: SurveyQuestionResource[]
) {
  const introTranslationStats = getLanguageInformationFromStringifiedTranslation(
    survey.surveyLanguages,
    survey.introductionText_t
  );
  if (introTranslationStats.totalLanguages !== introTranslationStats.totalTranslated){ 
    return true;
  }

  if (Object.values(survey.channels)
      .some((channel) => {
      if(isNotNullNorUndefined(survey.surveyLanguages) &&
      NotificationChannel.isUsed(channel)){
        return getChannelTranslationStatus(channel,
          survey.surveyLanguages);
      } else {
        return false;
      }
  })){
    return true;
  }
  
  if (questions
      .some((question) => {
      const questionStats = getOverallQuestionTranslationStats(
        question,
        survey.surveyLanguages
      );
      if (questionStats.totalLanguages !== questionStats.totalTranslated) {
        return true;
      } else {
        return false;
      }
    })) {
      return true;
  }
  return false;
}
