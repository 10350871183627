export const SERIES_COLORS =  [
  "#B66AE9",
  "#6239BE",
  "#1C1E56",
  "#4467AA",
  "#869BCE",
  "#5D9DD4",
  "#00C3D9",
  "#00D994",
  "#7BF49D",
  "#C0EF64",
];

export const COLORS_TO_DROP = [
  [1,2,3,4,5,6,7,8,9],
  [1,2,3,4,5,6,7,8],
  [1,2,3,5,6,7,9],
  [1,2,4,5,7,8],
  [1,3,5,7,9],
  [1,3,5,7],
  [1,3,5],
  [1, 3],
  [1],
  [],
];
