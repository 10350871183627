import React from "react";
import { Col, Layout as AntLayout, Row as AntRow } from "antd";
import styled from "styled-components";
import { Trans } from "react-i18next";
import CardMenu from "@components/core/CardMenu";
import Palette from "@src/config/theme/palette";
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from "react-router";
import ParticipationContainer from "@screens/Insights/Survey/containers/ParticipationContainer";
import QuestionsReportContainer from "@screens/Insights/Survey/containers/QuestionsReportContainer";
import CommentsContainer from "@screens/Insights/Survey/containers/CommentsContainer";
import CategoriesReportContainer from "@screens/Insights/Survey/containers/CategoriesReportContainer";
import HeatmapReportContainer from "@screens/Insights/Survey/containers/HeatmapReportContainer";
import OverviewReportContainer from "@screens/Insights/Survey/containers/OverviewReportContainer";
import TopicsReportContainer from "@screens/Insights/Survey/containers/TopicsReportContainer";
import { RightsMatcher } from "@hyphen-lib/business/auth/Auth";
import { Optional } from "@hyphen-lib/lang/Optionals";
import { checkForSurveyReportRight } from "@src/utils/rights";
import { SURVEY_REPORT_FALLBACK_ROUTES } from "@screens/Insights/MainNavigation/DefaultRoutesConfiguration";
import { not } from "@hyphen-lib/lang/Booleans";
import { getOr, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { Participation } from "hyphen-lib/dist/domain/common/Participation";
import { AccessList } from "hyphen-lib/dist/domain/access/AccessList";
import IndividualResultsSurveyReportContainer from "./IndividualResultsSurveyReportContainer";

interface MatchParams {
  id: string;
}

export interface SurveyReportProps {
  readonly surveyId: string;
  readonly surveyName: string;
  readonly surveyRole: AccessList.UserAccessRole;
  readonly participation: Participation;
  readonly isAnonymousSurvey: boolean;
  readonly hasRatingQuestions: boolean;
  readonly hasNPSQuestions: boolean;
  readonly topicAnalysEnabled?: boolean;
  readonly surveySuggestionsEnabled?: boolean;
  readonly rightsMatcher: RightsMatcher;
  readonly onSelectReportsMenu: (surveyId: string, menu: string) => void;
  readonly postAndSurveySentiment?: boolean;
  readonly surveyLanguages?: Optional<string[]>;
}

export type OwnProps = RouteComponentProps<MatchParams>;

class SurveyReports extends React.Component<OwnProps & SurveyReportProps> {
  onSelectReportsMenu(url: string, menu: string) {
    return () => this.props.onSelectReportsMenu(url, menu);
  }

  render() {
    const {
      surveyId,
      surveyName,
      surveyRole,
      participation,
      location,
      isAnonymousSurvey,
      hasRatingQuestions,
      hasNPSQuestions,
      rightsMatcher,
      topicAnalysEnabled,
      surveySuggestionsEnabled,
      postAndSurveySentiment,
      surveyLanguages
    } = this.props;

    const pathname = location.pathname;
    const splitPath = pathname.split("/");
    const selectedCard =
      splitPath.length >= 6 && splitPath[4] === "reports"
        ? splitPath[5]
        : "overview";
    const displayTopics = topicAnalysEnabled && checkForSurveyReportRight(rightsMatcher, "topics");
    if (not(checkForSurveyReportRight(rightsMatcher, selectedCard))) {
      const fallbackRoute = SURVEY_REPORT_FALLBACK_ROUTES.find((route) => {
        return checkForSurveyReportRight(rightsMatcher, route);
      });
      if (isNotNullNorUndefined(fallbackRoute)) {
        return <Redirect to={`/surveys/view/${surveyId}/reports/${fallbackRoute}`} />;
      }
      return <Redirect to={"/errors/403"} />;
    }

    // const reportsWithHiddenOverflow = ["heatmap"];
    // const hasHiddenOverflow = reportsWithHiddenOverflow.includes(selectedCard);

    /* tslint:disable:jsx-no-lambda */
    return (
      <Layout>
        <Sider className="block--print-hide">
          <CardMenu selectedKeys={[selectedCard]}>
            {
              (hasRatingQuestions || hasNPSQuestions) && checkForSurveyReportRight(rightsMatcher, "overview") && (
                <CardMenu.Item key="overview" onClick={this.onSelectReportsMenu(surveyId, "overview")}>
                  <StyledLink><Trans>Overview</Trans></StyledLink>
                </CardMenu.Item>
              )
            }
            {
              hasRatingQuestions && checkForSurveyReportRight(rightsMatcher, "heatmap") && (
                <CardMenu.Item key="heatmap" onClick={this.onSelectReportsMenu(surveyId, "heatmap")}>
                  <StyledLink><Trans>Heatmap</Trans></StyledLink>
                </CardMenu.Item>
              )
            }
            {
              (hasRatingQuestions && checkForSurveyReportRight(rightsMatcher, "categories")) && (
                <CardMenu.Item key="categories" onClick={this.onSelectReportsMenu(surveyId, "categories")}>
                  <StyledLink><Trans>Categories</Trans></StyledLink>
                </CardMenu.Item>
              )
            }
            {
              checkForSurveyReportRight(rightsMatcher, "questions") && (
                <CardMenu.Item key="questions" onClick={this.onSelectReportsMenu(surveyId, "questions")}>
                  <StyledLink><Trans>Questions</Trans></StyledLink>
                </CardMenu.Item>
              )
            }
            {displayTopics && getOr(postAndSurveySentiment, true) && (
                <CardMenu.Item key="topics" onClick={this.onSelectReportsMenu(surveyId, "topics")} data-jest="topics">
                  <StyledLink>Topics</StyledLink>
                </CardMenu.Item>
              )
            }
            {
              checkForSurveyReportRight(rightsMatcher, "comments") && (
                <CardMenu.Item key="comments" onClick={this.onSelectReportsMenu(surveyId, "comments")}>
                  <StyledLink><Trans>Comments</Trans></StyledLink>
                </CardMenu.Item>
              )
            }
            {
              (!isAnonymousSurvey && checkForSurveyReportRight(rightsMatcher, "individualResults")) &&
              <CardMenu.Item key="individualResults" onClick={this.onSelectReportsMenu(surveyId, "individualResults")}>
                <StyledLink><Trans>Individual results</Trans></StyledLink>
              </CardMenu.Item>
            }
            {
              checkForSurveyReportRight(rightsMatcher, "participation") && (
                <CardMenu.Item key="participation" onClick={this.onSelectReportsMenu(surveyId, "participation")}>
                  <StyledLink><Trans>Participation</Trans></StyledLink>
                </CardMenu.Item>
              )
            }
          </CardMenu>
        </Sider>
        <Content>
          <Row type="flex" justify="center">
            <Col span={24}>
              <Switch>
                {
                  !isAnonymousSurvey &&
                  <Route
                    path="/surveys/view/:id/reports/individualResults"
                    render={
                      // fixme: any because we are sharing too much types for the props
                      // fixme: and there are probably a lot of props that are not used, and TS is complaining here.
                      (props: any) =>
                        <IndividualResultsSurveyReportContainer
                          {...props}
                          surveyId={surveyId}
                          participation={participation}
                          surveyRole={surveyRole}
                          surveyName={surveyName}
                          postAndSurveySentiment={postAndSurveySentiment}
                        />
                    }
                  />
                }
                <Route
                  path="/surveys/view/:id/reports/participation"
                  component={ParticipationContainer}
                />
                <Route
                  path="/surveys/view/:id/reports/questions"
                  render={
                    (props) =>
                      <QuestionsReportContainer
                        {...props}
                        surveyId={surveyId}
                        surveyName={surveyName}
                        participation={participation}
                        postAndSurveySentiment={postAndSurveySentiment}
                      />
                  }
                />
                <Route
                  path="/surveys/view/:id/reports/topics"
                  render={
                    (props) =>
                      <TopicsReportContainer
                        {...props}
                        participation={participation}
                        topicAnalysEnabled={displayTopics}
                        surveyId={surveyId}
                        surveyName={surveyName} />
                  }
                />
                <Route
                  path="/surveys/view/:id/reports/comments"
                  render={
                    // fixme: any because we are sharing too much types for the comments props
                    // fixme: and there are probably a lot of props that are not used, and TS is complaining here.
                    (props: any) =>
                    (
                      <CommentsContainer
                        {...props}
                        surveyId={surveyId}
                        participation={participation}
                        surveyRole={surveyRole}
                        surveyName={surveyName}
                        topicAnalysEnabled={displayTopics}
                        surveySuggestionsEnabled={surveySuggestionsEnabled}
                        postAndSurveySentiment={postAndSurveySentiment}
                        surveyLanguages={surveyLanguages}
                      />
                    )
                  }
                />
                <Route
                  path="/surveys/view/:id/reports/categories"
                  render={
                    props =>
                      <CategoriesReportContainer
                        {...props}
                        surveyId={surveyId}
                        surveyName={surveyName}
                        participation={participation}
                        postAndSurveySentiment={postAndSurveySentiment}
                      />
                  }
                />
                <Route
                  path="/surveys/view/:id/reports/heatmap"
                  render={
                    props =>
                      <HeatmapReportContainer
                        {...props}
                        surveyId={surveyId}
                        surveyName={surveyName}
                        participation={participation}
                      />
                  }
                />
                <Route
                  path="/surveys/view/:id/reports/overview"
                  render={
                    props =>
                      <OverviewReportContainer
                        {...props}
                        surveyId={surveyId}
                        surveyName={surveyName}
                        participation={participation}
                        postAndSurveySentiment={postAndSurveySentiment}
                      />
                  }
                />
              </Switch>
            </Col>
          </Row>
        </Content>
      </Layout>
    );
    /* tslint:enable:jsx-no-lambda */
  }
}

const Layout = styled(AntLayout)`
  background: ${Palette.paleGrey} !important;

  .ant-layout-sider {
    background: transparent;
  }
`;

const StyledLink = styled.span`
  cursor: pointer;
`;

const Content = styled(AntLayout.Content)`
  overflow-x: auto !important;
`;

const Row = styled(AntRow)`
  overflow-x: initial;
  min-width: 920px;
`;

const Sider = styled(AntLayout.Sider)``;

export default withRouter(SurveyReports);
