import React from "react";
import { connect, MapStateToProps } from "react-redux";
import { Trans } from "react-i18next";
import { State } from "@store/types";
import styled from "styled-components";
import Button from "@components/core/Button";
import { getOr, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import ToggleOpenIconSVG from "@components/core/svg/ToggleOpenIconSVG";
import CloseIconSvg from "@components/core/svg/CloseIconSvg";
import Palette from "@src/config/theme/palette";
import { getCurrentBottomDrawerView } from "@src/utils/FocusArea";
import { bottomDrawerActions } from "@src/screens/Insights/BottomDrawer/actions";
import { Tooltip, Icon } from "antd";

export enum DrawerViews {
  MINIMIZED = "minimized",
  EXPANDED = "expanded",
  INVISIBLE = "invisible",
}
type DisplayMode = "flex" | "none";

interface OwnProps {
  children?: React.ReactNode;
  title: string;
  initialView?: DrawerViews;
  footerButton: {
    display: boolean;
    text: string;
    onClick: () => any;
  };
  onClose?: () => any;
}

interface BottomDrawerContainerStateProps {
  currentBottomDrawerView?: DrawerViews;
}

interface BottomDrawerContainerActionProps {
  readonly onMinimizeBottomDrawer: () => any;
  readonly onMaximizeBottomDrawer: () => any;
  readonly onHideBottomDrawer: () => any;
}

interface BrowserDrawerState {
  styles: {
    currentView: DrawerViews;
  };
}

type Props = OwnProps &
  BottomDrawerContainerStateProps &
  BottomDrawerContainerActionProps;

class BottomDrawer extends React.Component<Props, BrowserDrawerState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      styles: {
        currentView: getOr(this.props.initialView, DrawerViews.MINIMIZED),
      },
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.initialView !== prevProps.initialView) {
      this.setState({
        styles: {
          currentView: getOr(this.props.initialView, DrawerViews.MINIMIZED),
        },
      });
    }
  }

  deriveContainerHeight() {
    return this.state.styles.currentView === "expanded"
      ? this.getExpandedContainerHeight()
      : this.getMinimisedContainerHeight();
  }
  getExpandedContainerHeight() {
    return "262px";
  }
  getMinimisedContainerHeight() {
    return "40px";
  }

  getToggledView(): DrawerViews {
    let toggledView: DrawerViews;
    const { currentView } = this.state.styles;
    switch (currentView) {
      case "expanded":
        toggledView = DrawerViews.MINIMIZED;
        break;
      case "minimized":
        toggledView = DrawerViews.EXPANDED;
        break;
      default:
        toggledView = DrawerViews.MINIMIZED;
        break;
    }

    return toggledView;
  }

  getArrowRotation() {
    if (this.state.styles.currentView === "expanded") return 0;
    else return 180;
  }

  getCurrentDisplayMode(): DisplayMode {
    if (this.state.styles.currentView === "invisible") return "none";
    else return "flex";
  }

  setToggledView() {
    const toggledView = this.getToggledView();
    this.setState({
      styles: {
        currentView: toggledView,
      },
    });

    switch (toggledView) {
      case "expanded":
        this.props.onMaximizeBottomDrawer();
        break;
      case "minimized":
        this.props.onMinimizeBottomDrawer();
        break;
    }
  }

  setInvisibleView() {
    this.setState({
      styles: {
        currentView: DrawerViews.INVISIBLE,
      },
    });
    if (isNotNullNorUndefined(this.props.onClose)) {
      this.props.onClose();
    }
    this.props.onHideBottomDrawer();
  }

  render() {
    const { footerButton, title, children } = this.props;
    const [digits, word] = title.split(/(\d+)/).filter(Boolean);
    return (
      <StyledDrawerContainer
        data-cy="bottom_drawer_container"
        height={this.deriveContainerHeight()}
        display={this.getCurrentDisplayMode()}
      >
        <StyledDrawerTitle>
          <StyledDrawerTitleText data-cy="bottom_drawer_title">
            <Trans>{digits}</Trans> {word && <Trans>{word.trim()}</Trans>}
            <Tooltip
              title={<Trans>You can see up to 30 latest focus areas in this bottom drawer. 
                To see all focus areas go to Action Center</Trans>}
              placement="top"
              overlayClassName="bottom-drawer-tooltip"
            >
              <StyledInfoIcon type="info-circle" className="pointer ml-1" />
            </Tooltip>
          </StyledDrawerTitleText>
          <StyledDrawerTitleButton
            data-cy="bottom_drawer_expand_btn"
            onClick={() => this.setToggledView()}
          >
            <ToggleOpenIconSVG rotateBy={this.getArrowRotation()} />
          </StyledDrawerTitleButton>
          <StyledDrawerTitleButton
            data-cy="bottom_drawer_close_btn"
            onClick={() => this.setInvisibleView()}
          >
            <CloseIconSvg />
          </StyledDrawerTitleButton>
        </StyledDrawerTitle>
        <StyledDrawerBody data-cy="bottom_drawer_body">
          {children}
        </StyledDrawerBody>
        {isNotNullNorUndefined(footerButton) && (
          <StyledDrawerFooter
            display={footerButton.display === true}
            onClick={footerButton.onClick}
          >
            <StyledButton translate="yes">{footerButton.text}</StyledButton>
          </StyledDrawerFooter>
        )}
      </StyledDrawerContainer>
    );
  }
}

interface DrawerContainer {
  height?: string;
  display: DisplayMode;
}

const StyledDrawerContainer = styled.div<DrawerContainer>`
  position: fixed;
  background-color: ${Palette.white};
  width: 344px;
  height: ${(props) => props.height || "262px"};
  border-radius: 8px 8px 0px 0px;
  display: ${(props) => props.display};
  flex-direction: column;
  z-index: 9999;
  bottom: 0px;
  right: 34px;
  border-style: solid;
  border-width: 1px;
  border-color: ${Palette.pattensBlue};
  transition: height 400ms ease-in;
  @media print {
    display: none;
  }
`;

const StyledDrawerTitle = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  background-color: ${Palette.ceruleanBlue};
  border-radius: 8px 8px 0px 0px;
  color: ${Palette.white};
  font-family: Lato;
  font-weight: bold;
  font-size: 14px;
  padding: 15px;
`;

const StyledDrawerBody = styled.div`
  flex: 1;
  overflow-y: hidden;
`;

interface DrawerFooterInterface {
  display: boolean;
}

const StyledDrawerFooter = styled.div<DrawerFooterInterface>`
  height: 72px;
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-color: ${Palette.pattensBlue};
  display: ${(props) => (props.display ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

const StyledDrawerTitleText = styled.span`
  flex: 1;
`;

const StyledDrawerTitleButton = styled.span`
  width: 40px;
  text-align: center;
  box-sizing: border-box;
  padding-top: 2px;
  border-radius: 50%;
`;

const StyledButton = styled(Button)`
  background-color: ${Palette.darkTurqoise};
  color: #ffffff;
  font-family: Lato;
  font-weight: bold;
  height: 40px;
  max-width: 250px;
`;

const iconStyle = `
  position: relative;
  font-size: 14px;
`;

const StyledInfoIcon = styled(Icon)`
  ${iconStyle};
`;

const mapStateToProps: MapStateToProps<
  BottomDrawerContainerStateProps,
  OwnProps,
  State
> = (state: State, ownProps: OwnProps): BottomDrawerContainerStateProps => {
  return {
    currentBottomDrawerView: getCurrentBottomDrawerView(state),
  };
};

const mapDispatchToProps = {
  onMinimizeBottomDrawer: bottomDrawerActions.minimize,
  onMaximizeBottomDrawer: bottomDrawerActions.maximize,
  onHideBottomDrawer: bottomDrawerActions.hide,
};

export default connect(mapStateToProps, mapDispatchToProps)(BottomDrawer);
