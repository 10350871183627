import React from "react";
import {
  Route,
  Switch,
  withRouter,
  RouteComponentProps
} from "react-router-dom";
import { Error403 } from "../../errors/Error403";
import { Error404 } from "../../errors/Error404";
import { RedirectContainer } from "../../MainNavigation/RedirectContainer";
import PulsePollsDetails from "./PulsePollsDetails";
import { PulsePollsOverview } from "./PulsePollsOverview";
import PulsePollResults from "./PulsePollsResults";
import PulsePollsTrends from "./PulsePollsTrends";
import CommentsContainer from "./PulsePollsComments";

class PulsePollsRouter extends React.Component<RouteComponentProps> {

  render() {
    return (
      <Switch>
        <Route
          path="/pulsePolls/:templateId/reports/details"
          component={PulsePollsDetails}
        />
        <Route
          path="/pulsePolls/:templateId/instances/:instanceId/reports/overview"
          component={PulsePollsOverview}
        />
        <Route
          path="/pulsePolls/:templateId/instances/:instanceId/reports/results"
          component={PulsePollResults}
        />
        <Route
          path="/pulsePolls/:templateId/reports/comments"
          component={CommentsContainer}
        />
        <Route
          path="/pulsePolls/:templateId/instances/:instanceId/reports/comments"
          component={CommentsContainer}
        />
        <Route
          path="/pulsePolls/:templateId/instances/:instanceId/reports"
          component={PulsePollsOverview}
        />
        <Route
          path="/pulsePolls/view/:templateId/reports/trends"
          component={PulsePollsTrends}
        />
        <Route path="/errors/403" component={Error403} />
        <Route path="/errors/404" component={Error404} />
        <Route path="/" component={RedirectContainer} />
        <Route component={Error404} />
      </Switch>
    );
  }
}

export default withRouter(PulsePollsRouter);
