// @ts-nocheck
import React from "react";
export function FilterSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <defs>
        <path
          id="filterA"
          // eslint-disable-next-line max-len
          d="M2.608 2.324a.284.284 0 0 0-.284.284v3.784c0 .157.127.284.284.284h3.784a.284.284 0 0 0 .284-.284V2.608a.284.284 0 0 0-.284-.284H2.608zm0-1.324h3.784C7.28 1 8 1.72 8 2.608v3.784C8 7.28 7.28 8 6.392 8H2.608C1.72 8 1 7.28 1 6.392V2.608C1 1.72 1.72 1 2.608 1zm0 10.324a.284.284 0 0 0-.284.284v3.784c0 .157.127.284.284.284h3.784a.284.284 0 0 0 .284-.284v-3.784a.284.284 0 0 0-.284-.284H2.608zm0-1.324h3.784C7.28 10 8 10.72 8 11.608v3.784C8 16.28 7.28 17 6.392 17H2.608C1.72 17 1 16.28 1 15.392v-3.784C1 10.72 1.72 10 2.608 10zm9 1.324a.284.284 0 0 0-.284.284v3.784c0 .157.127.284.284.284h3.784a.284.284 0 0 0 .284-.284v-3.784a.284.284 0 0 0-.284-.284h-3.784zm0-1.324h3.784c.888 0 1.608.72 1.608 1.608v3.784C17 16.28 16.28 17 15.392 17h-3.784C10.72 17 10 16.28 10 15.392v-3.784c0-.888.72-1.608 1.608-1.608zm.891-4.639l3.322-4.115a.666.666 0 0 1 .93-.102c.286.226.332.64.104.922L13.07 6.754a.666.666 0 0 1-.985.054l-1.892-1.875a.652.652 0 0 1 0-.928.666.666 0 0 1 .936 0L12.5 5.36z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="filterB" fill="#fff">
          <use xlinkHref="#filterA" />
        </mask>
        <use fill="#111" fillRule="nonzero" xlinkHref="#filterA" />
        <g fill="#AAB9CE" mask="url(#filterB)">
          <path d="M0 0h18v18H0z" />
        </g>
      </g>
    </svg>
  );
}
