import { getOr } from "hyphen-lib/dist/lang/Objects";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Palette } from "./palette";

export interface BarOptions {
  backgroundColor: string;
  fontColor: string;
  label: string;
}

export default Palette;

export const barGraphTheme = {
  color: [
    { backgroundColor: Palette.aquaBlue, fontColor: Palette.white, label: "Positive" },
    { backgroundColor: Palette.lightGrey, fontColor: Palette.bluishGrey, label: "Neutral" },
    { backgroundColor: Palette.darkPink, fontColor: Palette.white, label: "Negative" },
  ],
};

export function getBarThemeForFavorability(scale: number, choiceLabels: Optional<string>[]) {
  return barGraphTheme.color.map((configuration, idx) => {
    return {
      ...configuration,
      label: getOr(choiceLabels[idx], configuration.label),
    };
  });
}

export function getBarConfigForRendering(items: any[]) {
  const choiceLabels = barGraphTheme.color.map((item) => item.label);
  const distribution = getOr(items, []);
  const barTheme: BarOptions[] = getBarThemeForFavorability(
    choiceLabels.length,
    choiceLabels
  );

  return {
    choiceLabels,
    distribution,
    barTheme,
  };
};