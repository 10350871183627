import React from "react";
import { ChangeEvent } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";

import styled from "styled-components";

import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { parseNumber } from "hyphen-lib/dist/lang/Number";
import {
  cleanObject,
  getOr,
  isNotEmptyObject,
  isNotNullNorUndefined,
  mapOr,
  mapValues
} from "hyphen-lib/dist/lang/Objects";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { SelectionFilter } from "hyphen-lib/dist/domain/parameter/SelectionFilter";
import { ActionResource } from "hyphen-lib/dist/domain/resource/action/ActionResource";
import { PostCategoryResource } from "hyphen-lib/dist/domain/resource/post/PostCategoryResource";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import Spin from "@components/core/Spin";
import { debounce } from "lodash";

import { FilterParameter, SortParameter } from "@src/utils/networks";
import { formatSort } from "@src/utils/helper";

import { State } from "@store/types";

import { getActionListStateProps } from "@screens/Insights/Actions/store/selectors";
import { getCurrentUser } from "@src/screens/Insights/store/selectors";
import { extractDataAndTotalFromPage, getExistingPage } from "@store/network/selectors";

import AreYouSureModal from "@components/core/AreYouSureModal";
import { ActionPlansTable } from "@screens/Insights/Actions/components/ActionListTable";
import { CustomizationPopover } from "@screens/Insights/components/CustomizationPopover";
import { ActionListFilterContainer } from "@screens/Insights/Actions/containers/ActionListFilterContainer";

import Button from "@components/core/Button";
import { ExpandButton } from "@components/core/ExpandButton";
import { SearchBar } from "@components/core/SearchBar";
import FilterLabels from "@src/components/core/FilterLabels";
import NoResult from "@src/components/core/NoResult";
import convertDictToFilters, { clearFilter, clearFilterOption } from "@src/components/core/FilterLabels/utils";


import { NetworkEventSuccessAction } from "@store/network/actions";
import {
  actionListActionCreators,
  ActionListPageParameters,
  ActionModalPayload
} from "@screens/Insights/Actions/store/actions";
import { actionCreators as SurveysActionCreator } from "@screens/Insights/Surveys/store/actions";

import { getPostCategorys } from "@screens/Insights/Surveys/store/selectors";

import { ActionModalProps, ActionModalType } from "@screens/Insights/Actions/store/reducers";
import ActionModal from "@screens/Insights/Actions/components/ActionModal";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { Action } from "hyphen-lib/dist/domain/Action";
import { Breadcrumb, goTo, replaceLocation } from "@src/utils/locations";
import { Map as ImmutableMap } from "immutable";
import { getParameters } from "@screens/Insights/parameters/store/selectors";
import { applyExistingParametersIfNeeded, PropMapping } from "@src/utils/parameters";
import { parametersActionCreators } from "@screens/Insights/parameters/store/actions";
import { FiltersBackdrop } from "@screens/Insights/components/FiltersBackdrop";
import { checkForActionPlanCreationRight } from "@src/utils/rights";
import { RightsMatcher } from "@hyphen-lib/business/auth/Auth";
import { getRightsMatcher } from "@screens/Insights/store/selectors";
import { fetchActionsIfNeeded } from "@store/network/resource/ActionResources";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { Icon } from "antd";
import { ExportSvg } from "@src/components/core/svg/ExportSvg";
import { createActionPlanReportRequest } from "@src/utils/exports";
import { Rights } from "hyphen-lib/dist/business/auth/Rights";
import { FetchError } from "../../../errors/FetchError";
import { ActionKeys } from "../../components/ActionListTable/cells/ActionCell";
import { Trans } from "react-i18next";

export const ACTIONS_FILTER_MAPPINGS: PropMapping[] = [
  { localKey: "filter", storeKey: "actionsFilter" },
  { localKey: "filter.sources", storeKey: "actionsFilter.sources" },
  { localKey: "filter.statuses", storeKey: "actionsFilter.statuses" },
  { localKey: "filter.overdue", storeKey: "actionsFilter.overdue" },
  { localKey: "filter.assignedToMe", storeKey: "actionsFilter.assignedToMe" },
  { localKey: "filter.createdByMe", storeKey: "actionsFilter.createdByMe" },
  { localKey: "filter.dueDate", storeKey: "actionsFilter.dueDate" },
  { localKey: "filter.categories", storeKey: "actionsFilter.categories" },
  { localKey: "sort", storeKey: "actionsSort" },
];

interface ActionPlansPageProps {
  readonly loading: boolean;
  readonly actions: ActionResource[];
  readonly categories: PostCategoryResource[];
  readonly total: number;
  readonly page: PageFilter;
  readonly existingPage: Store.Page<ActionResource>;
  readonly sort: SortParameter;
  readonly filter: FilterParameter;
  readonly selectedRowKeys: string[];
  readonly actionModalProps: ActionModalProps;
  readonly isActionUpdating: boolean;
  readonly visible: boolean;
  readonly action: ActionResource | null;
  readonly parameters: ImmutableMap<string, any>;
  readonly canCreateActionPlan: boolean;
  readonly rightsMatcher: RightsMatcher;
}

interface CurrentUserProps {
  currentUserEmail: string;
}

export interface ActionPlansPageActionProps {
  readonly onFetchIfNeeded: (parameters: ActionListPageParameters) => any;
  readonly onModifyList: (parameters: ActionListPageParameters) => any;
  readonly onSelectActionKeys: (selectedRowKeys: string[]) => any;
  readonly onToggleActionModal: (payload: ActionModalPayload) => any;
  readonly onActionUpdate: (
    actionId: string,
    action: ActionResource,
    options?: {
      onSuccessRedirect?: (payload: NetworkEventSuccessAction["payload"]) => void;
      meta?: Dictionary<any>;
    }
  ) => any;
  readonly setDelete: (action: ActionResource) => any;
  readonly undoSet: () => any;
  readonly deleteResource: () => any;
  readonly fetchPostCategories: () => void;
  readonly onModifyParameters: (parameters: Dictionary<any>, mappings?: PropMapping[]) => any;
}

export type Props = ActionPlansPageProps & ActionPlansPageActionProps & RouteComponentProps & CurrentUserProps;

export interface ActionPlansPageState {
  readonly areFiltersVisible: boolean;
  readonly selectedActions: any[];
  readonly tracker: SelectionFilter.Tracker;
}

export class ActionPlansList extends React.Component<Props, ActionPlansPageState> {
  private readonly onSearchChangeDebounced: (value: any) => void;
  private containerRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);

    this.state = {
      areFiltersVisible: false,
      selectedActions: [],
      tracker: SelectionFilter.initializeNewTracker().applyFilter(props.filter),
    };

    this.onSearchChangeDebounced = debounce(this.updateSearchFilter, 500);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    // we might need to apply persisted parameters
    const {
      fetchPostCategories,
      categories,
      parameters,
      location: { search },
    } = this.props;

    const existing = parseQueryString(search);
    const mergedParameters =
      applyExistingParametersIfNeeded(
        parameters.toJS(),
        existing,
        ...ACTIONS_FILTER_MAPPINGS
      );

    if (isNotNullNorUndefined(mergedParameters) && isNotEmptyObject(mergedParameters)) {
      replaceLocation(mergedParameters);
    } else {
      // fetch the action plan list only if we will stay on this page,
      // otherwise it will be fetched anyway in componentDidUpdate
      this.fetchIfNeeded();
    }

    // make network request to fetch post categories only if not already fetched
    if (categories.length === 0) {
      fetchPostCategories();
    }

    if (isNotNullNorUndefined(this.containerRef.current)) {
      this.containerRef.current.scrollTop = 0;
    }
  }

  componentDidUpdate(prevProps: ActionPlansPageProps) {
    if (this.props.loading && not(Store.Page.isInError(this.props.existingPage))) {
      this.fetchIfNeeded();
    }

    if (isNotNullNorUndefined(this.containerRef.current)) {
      this.containerRef.current.scrollTop = 0;
    }
  }

  fetchIfNeeded() {
    const {
      onFetchIfNeeded,
      page,
      filter,
      sort,
    } = this.props;

    onFetchIfNeeded({ filter, sort, page });
  }

  handleFiltersClick = () => {
    this.setState(
      state => (
        {
          areFiltersVisible: !state.areFiltersVisible,
        }
      )
    );
  };

  onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.onSearchChangeDebounced(event.target.value);
  };

  updateSearchFilter = (value: any) => {
    const {
      onModifyList,
      page,
      sort,
    } = this.props;
    const { tracker } = this.state;

    const filter = getOr(this.props.filter, {});
    if (isStringAndNotEmpty(value)) {
      filter.freeText = value;
    } else {
      delete filter.freeText;
    }

    const resetPage = { ...page, number: 1 };
    this.setState({ tracker: tracker.applyFilter(filter) });
    onModifyList({
      filter,
      page: resetPage,
      sort,
    });
  };

  handleApplyFilters = (newFilter: any) => {
    const {
      page,
      sort,
      onModifyList,
      onModifyParameters,
    } = this.props;
    const { tracker } = this.state;

    const cleanedFilters = cleanObject(newFilter);

    this.setState({ areFiltersVisible: false, tracker: tracker.applyFilter(cleanedFilters) });

    const resetPage = { ...page, number: 1 };

    onModifyParameters({ filter: newFilter, sort }, ACTIONS_FILTER_MAPPINGS);

    onModifyList({
      filter: cleanedFilters,
      page: resetPage,
      sort,
    });
  };

  renderNoData = () => {
    const { filter } = this.props;
    let type: "filter" | "data" | "default" | "search" = "default";
    let description = "";

    if (isNotNullNorUndefined(filter)) {
      // in-case of search
      if (isNotNullNorUndefined(filter.freeText)) {
        type = "search";
        description = "Sorry, we couldn't find any actions matching your search for the moment.";
      }
      // in-case any filters are applied
      else if (Object.keys(filter).length > 0) {
        type = "filter";
        description = "Sorry, we couldn't find any actions matching your filters for the moment.";
      }
      // no filters, meaning no action is created
      else {
        type = "data";
        description = "Looks like you haven't created any Action Plans yet.";
      }
    } else {
      type = "default";
      description = "";
    }

    return (
      <NoResult
        type={type}
        description={description}
      />
    );
  };

  onClearFilter = (filterToRemove: string) => {
    const {
      page,
      sort,
      onModifyList,
      onModifyParameters,
    } = this.props;
    const { tracker } = this.state;

    const filter = clearFilter(filterToRemove, this.props.filter);
    const cleanedFilters = cleanObject(filter);

    this.setState({ tracker: tracker.applyFilter(cleanedFilters) });

    onModifyParameters({ filter }, ACTIONS_FILTER_MAPPINGS);

    onModifyList({
      filter: cleanedFilters,
      page,
      sort,
    });
  };

  onClearFilterOption = (filterKey: string, subFilter: string) => {
    const {
      page,
      sort,
      onModifyList,
      onModifyParameters,
    } = this.props;
    const { tracker } = this.state;

    const filter = clearFilterOption(filterKey, subFilter, this.props.filter);
    const cleanedFilters = cleanObject(filter);

    this.setState({ tracker: tracker.applyFilter(cleanedFilters) });

    onModifyParameters({ filter }, ACTIONS_FILTER_MAPPINGS);

    onModifyList({
      filter: cleanedFilters,
      page,
      sort,
    });
  };

  onActionClick = (key: ActionKeys, data: ActionResource) => {

    switch (key) {
      case ActionKeys.delete:
        this.props.setDelete(data);
        return;
      case ActionKeys.completed:
        {
          const { onToggleActionModal } = this.props;
          onToggleActionModal({
            isActionModalVisible: true,
            action: data,
            actionModalType: ActionModalType.SET_COMPLETE,
          });
        }
        return;
      case ActionKeys.view: {
        return goTo(`/actioncenter/actions/view/${data._id}`, Breadcrumb.stack("Actions"));
      }
      case ActionKeys.edit:
        return goTo(`/actioncenter/actions/edit/${data._id}`, Breadcrumb.stack("Actions"));
      case ActionKeys.todo:
        const actionInTodo = { ...data, status: Action.Status.TODO };
        return this.props.onActionUpdate(data._id, actionInTodo);
      case ActionKeys.in_progress:
        const actionInProgress = { ...data, status: Action.Status.IN_PROGRESS };
        return this.props.onActionUpdate(data._id, actionInProgress, {
          meta: {
            statusAction: "in_progress",
          },
        });
      case ActionKeys.dismissed:
        {
          const { onToggleActionModal } = this.props;
          onToggleActionModal({
            isActionModalVisible: true,
            action: data,
            actionModalType: ActionModalType.SET_DISMISSED,
          });
        }
        return;
    }
  };

  onCloseActionModal = () => {
    const { onToggleActionModal } = this.props;
    onToggleActionModal({
      isActionModalVisible: false,
      actionModalType: ActionModalType.VIEW,
      action: Optional.empty(),
    });
  };

  renderFilterLabels = () => {
    const { filter } = this.props;
    const filters = convertDictToFilters(
      filter,
      {} /* no dimension filters */
    );

    if (filters.length > 0) {
      return (
        <FilterLabels
          filters={filters}
          onClearFilter={this.onClearFilter}
          onClearSubfilter={this.onClearFilterOption}
        />
      );
    } else {
      return null;
    }
  };

  okDelete = () => this.props.deleteResource();

  deleteFallback = () => this.props.undoSet();

  renderAreYouSure = () => {
    const { visible, action } = this.props;
    return visible && isNotNullNorUndefined(action) ?
      <AreYouSureModal
        visible={visible}
        onOk={this.okDelete}
        onCancel={this.deleteFallback}
        title="Delete Action Plan"
        description="Are you sure you want to delete this?"
        okLabel="Yes"
      /> : "";
  };

  onCompleteOk = (
    actionId: string,
    action: ActionResource,
    callback: (payload: NetworkEventSuccessAction["payload"]) => void
  ) => {
    const { onActionUpdate } = this.props;
    const { status } = action;
    const statusAction = status === Action.Status.COMPLETED ? "completeAction" : "dismissAction";
    onActionUpdate(actionId, action, {
      onSuccessRedirect: callback,
      meta: {
        statusAction,
      },
    });
  };

  /*
      proxy to store the sort
   */
  handleModifyTable = (parameters: ActionListPageParameters) => {
    const {
      sort: previousSort,
      onModifyList,
      onModifyParameters,
    } = this.props;

    const newSort = {
      ...mapValues(previousSort as any, () => undefined), // explicitly remove old sort
      ...parameters.sort,
    };

    onModifyParameters({ sort: newSort }, ACTIONS_FILTER_MAPPINGS);
    onModifyList(parameters);
  };

  // noinspection JSMethodCanBeStatic
  onCreateAction() {
    goTo("/actioncenter/actions/create", Breadcrumb.stack("ActionPlans"));
  }

  handleExportsButtonClick = () => {
    createActionPlanReportRequest("?dimensionFilters={}");
  };

  renderExportDropdown = () => {
    const checkCurrentUserCanDownloadCsv = isNotNullNorUndefined(this.props.currentUserEmail)
      && this.props.rightsMatcher.hasRight(Rights.Action.VIEW_ALL);
    return (
      checkCurrentUserCanDownloadCsv &&
      <ExportButton color="gradation" data-cy="actionReport_exportButton" onClick={this.handleExportsButtonClick} 
        ><Icon component={ExportSvg} />
        <span><Trans>Export as CSV</Trans></span>
      </ExportButton>
    );
  };

  render() {
    const {
      loading,
      total,
      actions,
      categories,
      page,
      sort,
      filter,
      actionModalProps,
      isActionUpdating,
      currentUserEmail,
      rightsMatcher,
      existingPage,
    } = this.props;

    const {
      areFiltersVisible,
    } = this.state;

    return (
        <>
        <Container ref={this.containerRef}>
          {this.renderAreYouSure()}
          <SearchAndFilterContainer>
            <SearchBar
              placeholder="Search action plans"
              onChange={this.onSearchChange}
              defaultValue={mapOr(filter, f => f.freeText, "")}
            />
            <StyledExpandButton
              className="block--print-hide"
              icon="filter"
              onClick={this.handleFiltersClick}
              translate="yes"
            >
              Filters
            </StyledExpandButton>
            {
              areFiltersVisible &&
              <FiltersBackdrop onClick={this.handleFiltersClick} />
            }
            {this.renderExportDropdown()}
            <CustomizationPopover open={areFiltersVisible}>
              <ActionListFilterContainer
                values={filter}
                onApply={this.handleApplyFilters}
                categories={categories}
              />
            </CustomizationPopover>
          </SearchAndFilterContainer>
          {this.renderFilterLabels()}
          { Store.Page.isInError(existingPage) && (
            <FetchError { ...existingPage } resourceType={ActionResource.TYPE} />
          )}
          <Spin size="large" spinning={Store.Page.isLoading(existingPage)} >
            {
              (Store.Page.isLoaded(existingPage)) && total === 0
                ? this.renderNoData()
                : (
                  <ActionPlansTable
                    loading={loading}
                    actions={actions}
                    total={total}
                    page={page}
                    filter={filter}
                    sort={sort}
                    onModifyList={this.handleModifyTable}
                    onActionClick={this.onActionClick}
                    currentUserEmail={currentUserEmail}
                    rightsMatcher={rightsMatcher}
                  />
                )
            }
          </Spin>
        </Container>
        {
          actionModalProps.action && (
            <ActionModal
              visible={actionModalProps.isActionModalVisible}
              type={actionModalProps.actionModalType}
              actionResource={actionModalProps.action}
              onCancel={this.onCloseActionModal}
              onUpdate={this.onCompleteOk}
              isActionUpdating={isActionUpdating}
            />
          )
        }
        </>
    );
  }
}

const SearchAndFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  position: relative;
`;

const StyledExpandButton = styled(ExpandButton)`
  width: 144px;
  margin-left: 16px;
`;

const Container = styled.div`
  background-color: white;
  padding-bottom: 32px;
`;

function mapStateToProps(state: State, { location }: RouteComponentProps): ActionPlansPageProps & CurrentUserProps {
  const queryParameters = parseQueryString(location.search);
  const filter = getOr(queryParameters.filter, {});
  const sort = getOr(formatSort(queryParameters.sort), {});

  const {
    pageSize,
    selectedRowKeys,
    actionModalProps,
    isActionUpdating,
  } = getActionListStateProps(state);
  const page = {
    size: pageSize,
    number: mapOr(queryParameters.page, parseNumber, 1),
  };

  const existingPage = getExistingPage(
    state,
    ActionResource.TYPE,
    ActionResource.generateKey(filter, sort),
    page
  );
  const { data, total } = extractDataAndTotalFromPage(existingPage);
  const currentUser = getCurrentUser(state);

  return {
    loading: Store.Page.isNotFound(existingPage), // mapOr(existingPage, p => p.status !== "loaded", true),
    actions: data,
    existingPage,
    total,
    sort,
    filter,
    page,
    selectedRowKeys,
    actionModalProps,
    isActionUpdating,
    visible: state.insights_actionList.visible,
    action: state.insights_actionList.action,
    categories: getPostCategorys(state).toArray().reverse(),
    parameters: getParameters(state),
    currentUserEmail: isNotNullNorUndefined(currentUser) ? currentUser.email : "",
    canCreateActionPlan: checkForActionPlanCreationRight(state),
    rightsMatcher: getRightsMatcher(state),
  };
}

const mapDispatchToProps = {
  onFetchIfNeeded: fetchActionsIfNeeded,
  onModifyList: actionListActionCreators.modifyList,
  onSelectActionKeys: actionListActionCreators.onSelectActionKeys,
  onToggleActionModal: actionListActionCreators.onToggleActionModal,
  onActionUpdate: actionListActionCreators.updateActionRequest,
  setDelete: actionListActionCreators.setDelete,
  undoSet: actionListActionCreators.undoSet,
  deleteResource: actionListActionCreators.deleteResource,
  fetchPostCategories: SurveysActionCreator.fetchPostCategorys,
  onModifyParameters: parametersActionCreators.modifyParameters,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ActionPlansList)
);

const ExportButton = styled(Button)`
  margin-left: 10px;
  height: 36px !important;
  position: relative !important;
  padding-right: 36px !important;
`;