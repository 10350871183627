import React from "react";
import { Table } from "antd";
import { TableProps } from "antd/lib/table/interface";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { Trans } from "react-i18next";
import NoResult from "@src/components/core/NoResult";

const SurveySettingsModalContent = (props: any) => {
  const columns = [{
    title: (<Trans>Label</Trans>),
    dataIndex: "label",
    key: "label",
  }, {
    title: (<Trans>Description</Trans>),
    dataIndex: "description",
    key: "description",
  }];

  return (
    <>
      <DataTable
        pagination={false}
        columns={columns}
        dataSource={props.dataSource}
        rowKey="label" 
        locale={{
          emptyText: (<NoResult type="minimal" description="No Data" />),
        }}
      />
    </>
  );
};

const DataTable = styled(Table)<TableProps<any>>`
  td {
    padding: 20px 32px !important;
  }

  th {
    padding: 0px 32px 20px 32px !important;
    font-weight: bold !important;
    color: ${Palette.darkBlueGrey} !important;
    background-color: white !important;
  }

  tbody > tr > td:first-child {
    color: ${Palette.lightGreyBlue} !important;
  }

  tr:hover > td {
    background: #fff !important;
  }
`;

export default SurveySettingsModalContent;
