import React from "react";
import styled from "styled-components";
import ImmutableMapType from "@src/utils/CreateImmutableType";
import {
  SurveyResourceRecord
} from "@screens/Insights/Surveys/store/types";

import { InfoCard } from "@src/components/core/InfoCard/InfoCard";

import SurveySummarySection from "@src/screens/Insights/components/Wizard/Summary/SurveySummarySection";
import ChannelSummarySection from "@src/screens/Insights/components/Wizard/Summary/ChannelSummarySection";
import { SurveyQuestionsSection } from "@src/screens/Insights/components/Wizard/Summary/SurveyQuestionsSection";
import { AccessSection } from "@src/screens/Insights/components/Wizard/Summary/AccessSection";
import { AudienceSection } from "@screens/Insights/Surveys/components/Summary/AudienceSection";

import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { ParticipantCountResource } from "@hyphen-lib/domain/resource/participants/ParticipantCountResource";
import { getOr, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { QuestionConfig } from "hyphen-lib/dist/domain/common/QuestionType";

interface Props {
  data: SurveyResourceRecord;
  channels: CompanyResource["channels"];
  surveyParticipantsCount: ImmutableMapType<ParticipantCountResource>;
  questionConfig: QuestionConfig;
}

function SurveyDetails({ data, channels, surveyParticipantsCount, questionConfig }: Props) {
  const survey = data.toJS();
  return (
    <SurveyDetailsSection>
      <InfoCard
        title="Settings"
        link={`/surveys/edit/${survey._id}/settings`}
        headerStyle="emphasize"
        size="large"
        translate="yes"
      >
        <SurveySummarySection
          survey={survey}
        />
        <ChannelSummarySection
          channels={survey.channels}
          allowedChannels={channels}
        />
      </InfoCard>

      <InfoCard
        title="Questions"
        link={`/surveys/edit/${survey._id}/questions`}
        headerStyle="emphasize"
        size="large"
        translate="yes"
      >
        <SurveyQuestionsSection
          questions={survey.relationships.questions}
          questionConfig={questionConfig}
        />
      </InfoCard>

      <InfoCard
        title="Audience"
        link={`/surveys/edit/${data._id}/audience`}
        headerStyle="emphasize"
        size="large"
        translate="yes"
      >
        <AudienceSection 
          survey={data} 
          surveyParticipantsCount={surveyParticipantsCount}
        />
      </InfoCard>

      <InfoCard
        title="Access"
        link={`/surveys/edit/${survey._id}/access`}
        headerStyle="emphasize"
        size="large"
        translate="yes"
      >
        <AccessSection
          accesses={isNotNullNorUndefined(survey.relationships) ? getOr(survey.relationships.accesses, []) : []}
        />
      </InfoCard>
    </SurveyDetailsSection>
  );
}

const SurveyDetailsSection = styled.div`
  div:first-child {
    margin-top: 0px;
  }
`;

export default SurveyDetails;
