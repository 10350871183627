import { Card, Tooltip } from "antd";
import { formatDate } from "hyphen-lib/dist/lang/Dates";
import styled from "styled-components";
import { ActionResource } from "hyphen-lib/dist/domain/resource/action/ActionResource";
import { OkrCardLogoSvg } from "@src/components/core/svg/OkrCardLogoSvg";
import Palette from "@src/config/theme/palette";
import { Company } from "hyphen-lib/dist/domain/Company";
import { Trans } from "react-i18next";
const { WithOKRType } = ActionResource;

export interface OKRDetailProps {
    okr: ActionResource.OKRCreateSuccessType | ActionResource.OKRCreateRequestType;
    okrSynonyms: Company.GoalSetting;
    width?: string;
}

function OKRDetailCard(props: OKRDetailProps) {
    const { okr } = props;
    const { type, assignee, dueDate } = okr;
    const text = (type === WithOKRType.OBJECTIVE) ? props.okrSynonyms.objective : props.okrSynonyms.keyResult;
    return (
        <OKRDescriptionCard width={props.width}>
            <OkrCardLogoSvg />
            <Tooltip title={okr.name}>
                <ActionPlanName data-cy="actionPlanOkrName">{ okr.name }</ActionPlanName>
            </Tooltip>
            <MetaDescription>
                <Tooltip title={assignee}>
                    <AssigneeEmail>{ assignee }</AssigneeEmail>
                </Tooltip>
                {
                    ActionResource.isOkrCreateSuccessType(okr) &&
                    <ViewNowButton target="_blank" rel="noreferrer" href={okr.url}>
                        <Trans>View</Trans> {text}
                    </ViewNowButton>
                }
            </MetaDescription>
            <DueDateParagraph><Trans>due on</Trans> {formatDate(dueDate)}</DueDateParagraph>
        </OKRDescriptionCard>
    );
}

const DueDateParagraph = styled.div`
    font-size: 12px;
    text-align: left;
    margin-left: 12px;
    line-height: 20px;
    margin-top: 10px;
    color: ${Palette.bluishGrey};
`;

const AssigneeEmail = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ActionPlanName = styled.div`
    font-size: 14px;
    text-align: left;
    line-height: 20px;
    margin-left: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ViewNowButton = styled.a`
    font-size: 15px; !important;
    color: ${Palette.darkBlueGrey} !important;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: ${Palette.darkBlueGrey} !important;
    }

    &:visited {
        color: ${Palette.darkBlueGrey} !important;
    }
`;

const MetaDescription = styled.div`
    font-size: 12px;
    line-height: 16px;
    color: ${Palette.bluishGrey};
    display: flex;
    margin-top: 10px;
    margin-left: 12px;
    justify-content: space-between;
`;

const OKRDescriptionCard = styled(Card)<{width?: string}>`
    width: ${props => props.width ? props.width : "547px"};
    font-family: Lato;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    height: 120px;
    margin-top: 5px;
    letter-spacing: 0;
`;

export default OKRDetailCard;
