import { RecordOf, Record } from "immutable";
import {
  ActionTemplateSuggestionResource
} from "hyphen-lib/dist/domain/resource/action/ActionTemplateSuggestionResource";
import { SettingsActionTypes } from "./actions";

interface StateProps {
  readonly isNetworkRequesting: boolean;
  readonly actionTemplates: ActionTemplateSuggestionResource[];
  readonly userNotificationRequestId: string;
}

export type SettingsState = RecordOf<StateProps>;

const defaultValues: StateProps = {
  isNetworkRequesting: false,
  actionTemplates: [],
  userNotificationRequestId: "",
};

export const settingsStateFactory = Record<StateProps>(defaultValues);
const defaultState = settingsStateFactory();

function extractUserNotificationId(url: string) {
  const tokens = url.split("/");
  return tokens[tokens.length - 1];
}

export function settingsReducer(
  state: SettingsState = defaultState,
  action: any
): SettingsState {
  switch (action.type) {
    case SettingsActionTypes.FETCH_ALL_ACTION_TEMPLATES_REQUEST:
      return state.set("isNetworkRequesting", true);
    case SettingsActionTypes.FETCH_ALL_ACTION_TEMPLATES_SUCCESS:
      return state.merge({
        isNetworkRequesting: false,
        actionTemplates: action.payload.data,
      });
    case SettingsActionTypes.FETCH_ALL_ACTION_TEMPLATES_ERROR:
      return state.set("isNetworkRequesting", false);
    case SettingsActionTypes.UPDATE_USER_NOTIFICATION_REQUEST:
      const userNotificationId = extractUserNotificationId(action.payload.url);
      return state.set("userNotificationRequestId", userNotificationId);
    case SettingsActionTypes.UPDATE_USER_NOTIFICATION_SUCCESS:
    case SettingsActionTypes.UPDATE_USER_NOTIFICATION_ERROR:
      return state.set("userNotificationRequestId", "");
    default:
      return state;
  }
}
