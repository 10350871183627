import { createRequest } from "@src/utils/networks";
import { PulsePollResource } from "hyphen-lib/dist/domain/resource/PulsePollResource";
import actionCreators, {
  NetworkRequestAction, NetworkEventSuccessPayload, NetworkEventErrorPayload
} from "@src/store/network/actions";
import { FSA } from "flux-standard-action";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import { AccessList } from "hyphen-lib/dist/domain/access/AccessList";
import { AccessResource } from "hyphen-lib/dist/domain/access/AccessResource";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { QuestionConfig } from "hyphen-lib/dist/domain/common/QuestionType";

export enum PulsePollEditActionTypes {
  UPDATE_PULSE_POLL = "pulsePollEdit/UPDATE_PULSE_POLL",
  UPDATE_PULSE_POLLS_REQUEST = "pulsePollEdit/UPDATE_PULSE_POLLS_REQUEST",
  UPDATE_PULSE_POLLS_SUCCESS = "pulsePollEdit/UPDATE_PULSE_POLLS_SUCCESS",

  CREATE_PULSE_POLLS = "pulsePollEdit/CREATE_PULSE_POLLS",
  CREATE_PULSE_POLLS_REQUEST = "pulsePollEdit/CREATE_PULSE_POLLS_REQUEST",
  CREATE_PULSE_POLLS_SUCCESS = "pulsePollEdit/CREATE_PULSE_POLLS_SUCCESS",

  ACTIVATE_PULSE_POLL_REQUEST = "pulsePollEdit/ACTIVATE_PULSE_POLL_REQUEST",
  ACTIVATE_PULSE_POLL_SUCCESS = "pulsePollEdit/ACTIVATE_PULSE_POLL_SUCCESS",
  ACTIVATE_PULSE_POLL_ERROR = "pulsePollEdit/ACTIVATE_PULSE_POLL_ERROR",

  UPDATE_PULSE_POLLS_ERROR = "pulsePollEdit/UPDATE_PULSE_POLLS_ERROR",
  UPDATE_VALIDATION_ERRORS = "pulsePollEdit/UPDATE_VALIDATION_ERRORS",

  FETCH_PULSE_POLLS_REQUEST = "pulsePollEdit/FETCH_PULSE_POLLS_REQUEST",
  FETCH_PULSE_POLLS_SUCCESS = "pulsePollEdit/FETCH_PULSE_POLLS_SUCCESS",
  FETCH_PULSE_POLLS_ERROR = "pulsePollEdit/FETCH_PULSE_POLLS_ERROR",

  FETCH_PULSE_POLLS_ACCESS_LIST_REQUEST = "pulsePollEdit/FETCH_PULSE_POLLS_ACCESS_LIST_REQUEST",
  FETCH_PULSE_POLLS_ACCESS_LIST_SUCCESS = "pulsePollEdit/FETCH_PULSE_POLLS_ACCESS_LIST_SUCCESS",
  FETCH_PULSE_POLLS_ACCESS_LIST_ERROR = "pulsePollEdit/FETCH_PULSE_POLLS_ACCESS_LIST_ERROR",

  ADD_ACCESSES = "pulsePollEdit/ADD_ACCESSES",
  ADD_ACCESSES_REQUEST = "pulsePollEdit/ADD_ACCESSES_REQUEST",
  ADD_ACCESSES_SUCCESS = "pulsePollEdit/ADD_ACCESSES_SUCCESS",
  ADD_ACCESSES_ERROR = "pulsePollEdit/ADD_ACCESSES_ERROR",

  UPDATE_ACCESS = "pulsePollEdit/UPDATE_ACCESS",
  UPDATE_ACCESS_REQUEST = "pulsePollEdit/UPDATE_ACCESS_REQUEST",
  UPDATE_ACCESS_SUCCESS = "pulsePollEdit/UPDATE_ACCESS_SUCCESS",
  UPDATE_ACCESS_ERROR = "pulsePollEdit/UPDATE_ACCESS_ERROR",

  REMOVE_ACCESS = "pulsePollEdit/REMOVE_ACCESS",
  REMOVE_ACCESS_REQUEST = "pulsePollEdit/REMOVE_ACCESS_REQUEST",
  REMOVE_ACCESS_SUCCESS = "pulsePollEdit/REMOVE_ACCESS_SUCCESS",
  REMOVE_ACCESS_ERROR = "pulsePollEdit/REMOVE_ACCESS_ERROR",

  TOGGLE_MANAGERS_SYNCHRONIZATION_REQUEST = "pulsePollEdit/TOGGLE_MANAGERS_SYNCHRONIZATION_REQUEST",
  TOGGLE_MANAGERS_SYNCHRONIZATION_SUCCESS = "pulsePollEdit/TOGGLE_MANAGERS_SYNCHRONIZATION_SUCCESS",
  TOGGLE_MANAGERS_SYNCHRONIZATION_ERROR = "pulsePollEdit/TOGGLE_MANAGERS_SYNCHRONIZATION_ERROR",

  FETCH_POST_CATEGORIES_REQUEST = "pulsePollEdit/FETCH_POST_CATEGORIES_REQUEST",
  FETCH_POST_CATEGORIES_SUCCESS = "pulsePollEdit/FETCH_POST_CATEGORIES_SUCCESS",
  FETCH_POST_CATEGORIES_ERROR = "pulsePollEdit/FETCH_POST_CATEGORIES_ERROR",

  SAVE_PULSE_POLLS = "pulsePollEdit/SAVE_PULSE_POLLS",
  UPDATE_ACCESS_LIST = "pulsePollEdit/UPDATE_ACCESS_LIST",

  ACTIVATE_PULSE_POLL = "pulsePollEdit/ACTIVATE_PULSE_POLL",

  TOGGLE_PREVIEW_MODAL_OPEN = "pulsePollEdit/TOGGLE_PREVIEW_MODAL_OPEN",

  EXIT_PAGE = "pulsePollEdit/EXIT_PAGE",
}

interface Payloads {
  [PulsePollEditActionTypes.UPDATE_PULSE_POLL]: {
    pulsePollId: string;
    pulsePoll: PulsePollResource;
    questionConfig: QuestionConfig;
  };
  [PulsePollEditActionTypes.UPDATE_VALIDATION_ERRORS]: { errors: WrongEntityException.Errors };
  [PulsePollEditActionTypes.UPDATE_PULSE_POLLS_SUCCESS]: NetworkEventSuccessPayload<PulsePollResource>;
  [PulsePollEditActionTypes.SAVE_PULSE_POLLS]: {
    pulsePollId: string;
    pulsePoll: PulsePollResource;
    questionConfig: QuestionConfig;
  };
  [PulsePollEditActionTypes.TOGGLE_PREVIEW_MODAL_OPEN]: { open: boolean };
  [PulsePollEditActionTypes.ACTIVATE_PULSE_POLL]: { pulsePollId: string };
  [PulsePollEditActionTypes.ACTIVATE_PULSE_POLL_ERROR]: NetworkEventErrorPayload;

  [PulsePollEditActionTypes.ADD_ACCESSES]: { pulsePollId: string; emails: string[] };
  [PulsePollEditActionTypes.UPDATE_ACCESS]: { pulsePollId: string; accessId: string; role: AccessList.UserAccessRole };
  [PulsePollEditActionTypes.REMOVE_ACCESS]: { pulsePollId: string; accessId: string };
  [PulsePollEditActionTypes.FETCH_PULSE_POLLS_ACCESS_LIST_SUCCESS]: NetworkEventSuccessPayload<AccessResource[]>;

  [PulsePollEditActionTypes.EXIT_PAGE]: { pulsePollId: string };
}

export interface UpdatePulsePollAction
  extends FSA<
  PulsePollEditActionTypes.UPDATE_PULSE_POLL,
  Payloads[PulsePollEditActionTypes.UPDATE_PULSE_POLL]
  > {
  type: PulsePollEditActionTypes.UPDATE_PULSE_POLL;
  payload: Payloads[PulsePollEditActionTypes.UPDATE_PULSE_POLL];
}

export interface TogglePreviewModalOpenAction
  // eslint-disable-next-line max-len
  extends FSA<PulsePollEditActionTypes.TOGGLE_PREVIEW_MODAL_OPEN, Payloads[PulsePollEditActionTypes.TOGGLE_PREVIEW_MODAL_OPEN]> {
  type: PulsePollEditActionTypes.TOGGLE_PREVIEW_MODAL_OPEN;
  payload: Payloads[PulsePollEditActionTypes.TOGGLE_PREVIEW_MODAL_OPEN];
}

export interface UpdatePulsePollSuccessAction
  extends FSA<PulsePollEditActionTypes.UPDATE_PULSE_POLLS_SUCCESS,
  Payloads[PulsePollEditActionTypes.UPDATE_PULSE_POLLS_SUCCESS]> {
  type: PulsePollEditActionTypes.UPDATE_PULSE_POLLS_SUCCESS;
  payload: Payloads[PulsePollEditActionTypes.UPDATE_PULSE_POLLS_SUCCESS];
}

export interface FetchPulsePollAccessListSuccessAction
  extends FSA<PulsePollEditActionTypes.FETCH_PULSE_POLLS_ACCESS_LIST_SUCCESS,
  Payloads[PulsePollEditActionTypes.FETCH_PULSE_POLLS_ACCESS_LIST_SUCCESS]> {
  type: PulsePollEditActionTypes.FETCH_PULSE_POLLS_ACCESS_LIST_SUCCESS;
  payload: Payloads[PulsePollEditActionTypes.FETCH_PULSE_POLLS_ACCESS_LIST_SUCCESS];
}

export interface UpdateValidationErrorsAction
  extends FSA<
  PulsePollEditActionTypes.UPDATE_VALIDATION_ERRORS,
  Payloads[PulsePollEditActionTypes.UPDATE_VALIDATION_ERRORS]
  > {
  type: PulsePollEditActionTypes.UPDATE_VALIDATION_ERRORS;
  payload: Payloads[PulsePollEditActionTypes.UPDATE_VALIDATION_ERRORS];
}

export interface AddAccessesAction
  extends FSA<PulsePollEditActionTypes.ADD_ACCESSES, Payloads[PulsePollEditActionTypes.ADD_ACCESSES]> {
  type: PulsePollEditActionTypes.ADD_ACCESSES;
  payload: Payloads[PulsePollEditActionTypes.ADD_ACCESSES];
}

export interface UpdateAccessAction
  extends FSA<PulsePollEditActionTypes.UPDATE_ACCESS, Payloads[PulsePollEditActionTypes.UPDATE_ACCESS]> {
  type: PulsePollEditActionTypes.UPDATE_ACCESS;
  payload: Payloads[PulsePollEditActionTypes.UPDATE_ACCESS];
}

export interface RemoveAccessAction
  extends FSA<PulsePollEditActionTypes.REMOVE_ACCESS, Payloads[PulsePollEditActionTypes.REMOVE_ACCESS]> {
  type: PulsePollEditActionTypes.REMOVE_ACCESS;
  payload: Payloads[PulsePollEditActionTypes.REMOVE_ACCESS];
}

export interface SavePulsePollAction
  extends FSA<PulsePollEditActionTypes.SAVE_PULSE_POLLS, Payloads[PulsePollEditActionTypes.SAVE_PULSE_POLLS]> {
  type: PulsePollEditActionTypes.SAVE_PULSE_POLLS;
  payload: Payloads[PulsePollEditActionTypes.SAVE_PULSE_POLLS];
}

export interface ActivatePulsePollAction
  extends FSA<PulsePollEditActionTypes.ACTIVATE_PULSE_POLL, Payloads[PulsePollEditActionTypes.ACTIVATE_PULSE_POLL]> {
  type: PulsePollEditActionTypes.ACTIVATE_PULSE_POLL;
  payload: Payloads[PulsePollEditActionTypes.ACTIVATE_PULSE_POLL];
}

export interface ActivatePulsePollErrorAction
  extends FSA<
  PulsePollEditActionTypes.ACTIVATE_PULSE_POLL_ERROR,
  Payloads[PulsePollEditActionTypes.ACTIVATE_PULSE_POLL_ERROR]
  > {
  type: PulsePollEditActionTypes.ACTIVATE_PULSE_POLL_ERROR;
  payload: Payloads[PulsePollEditActionTypes.ACTIVATE_PULSE_POLL_ERROR];
}

export interface ExitPageAction
  extends FSA<PulsePollEditActionTypes.EXIT_PAGE,
  Payloads[PulsePollEditActionTypes.EXIT_PAGE]> {
  type: PulsePollEditActionTypes.EXIT_PAGE;
  payload: Payloads[PulsePollEditActionTypes.EXIT_PAGE];
}

function updatePulsePoll(pulsePollId: string, pulsePoll: PulsePollResource, accesses: Optional<AccessResource[]>) {
  return createRequest(`/pulsePolls/${pulsePollId}`, {
    method: "PUT",
    body: JSON.stringify({
      ...pulsePoll,
      relationships: {
        accesses,
      },
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function createPulsePoll() {
  return createRequest("/pulsePolls", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function getPulsePoll(pulsePollId: string) {
  return createRequest(`/pulsePolls/${pulsePollId}`);
}

function activatePulsePoLL(pulsePollId: string) {
  return createRequest(`/pulsePolls/${pulsePollId}/activate`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

const addAccesses = (pulsePollId: string, pulsePollAccesses: AccessResource[]) => {
  return createRequest(`/pulsePolls/${pulsePollId}/accesses/`, {
    method: "POST",
    body: JSON.stringify(pulsePollAccesses),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const updateAccess = (
  pulsePollId: string,
  accessId: string,
  pulsePollAccess: AccessResource
) => {
  return createRequest(`/pulsePolls/${pulsePollId}/accesses/${accessId}`, {
    method: "PUT",
    body: JSON.stringify(pulsePollAccess),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const removeAccess = (pulsePollId: string, accessId: string) => {
  return createRequest(`/pulsePolls/${pulsePollId}/accesses/${accessId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const toggleManagersSynchronization = (pulsePollId: string, areManagersSynchronized: boolean) => {
  const data = {
    areManagersSynchronized,
  };
  return createRequest(`/pulsePolls/${pulsePollId}/accesses/toggleSynchronize`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

function getAccessList(pulsePollId: string) {
  return createRequest(`/pulsePolls/${pulsePollId}/accesses`);
}

export const pulsePollActionCreators = {
  updatePulsePollRequest: (
    pulsePollId: string,
    pulsePoll: PulsePollResource,
    questionConfig: QuestionConfig,
    accesses?: Optional<AccessResource[]>
  ): NetworkRequestAction =>
    actionCreators.networkRequestWithActions({
      request: updatePulsePoll(pulsePollId, pulsePoll, accesses),
      requestAction: PulsePollEditActionTypes.UPDATE_PULSE_POLLS_REQUEST,
      successAction: PulsePollEditActionTypes.UPDATE_PULSE_POLLS_SUCCESS,
      errorAction: PulsePollEditActionTypes.UPDATE_PULSE_POLLS_ERROR,
    }),

  fetchPulsePoll: (pulsePollId: string): NetworkRequestAction =>
    actionCreators.networkRequestWithActions({
      request: getPulsePoll(pulsePollId),
      requestAction: PulsePollEditActionTypes.FETCH_PULSE_POLLS_REQUEST,
      successAction: PulsePollEditActionTypes.FETCH_PULSE_POLLS_SUCCESS,
      errorAction: PulsePollEditActionTypes.FETCH_PULSE_POLLS_ERROR,
    }),

  updateValidationErrors: (
    errors: WrongEntityException.Errors
  ): UpdateValidationErrorsAction => ({
    type: PulsePollEditActionTypes.UPDATE_VALIDATION_ERRORS,
    payload: {
      errors,
    },
  }),

  onCreatePulsePoll: (): NetworkRequestAction =>
    actionCreators.networkRequestWithActions({
      request: createPulsePoll(),
      requestAction: PulsePollEditActionTypes.CREATE_PULSE_POLLS_REQUEST,
      successAction: PulsePollEditActionTypes.CREATE_PULSE_POLLS_SUCCESS,
      errorAction: PulsePollEditActionTypes.FETCH_PULSE_POLLS_ERROR,
    }),

  updatePulsePoll: (
    pulsePollId: string,
    pulsePoll: PulsePollResource,
    questionConfig: QuestionConfig
  ): UpdatePulsePollAction => ({
    type: PulsePollEditActionTypes.UPDATE_PULSE_POLL,
    payload: {
      pulsePollId,
      pulsePoll,
      questionConfig,
    },
  }),

  addAccesses: (pulsePollId: string, emails: string[]): AddAccessesAction => ({
    type: PulsePollEditActionTypes.ADD_ACCESSES,
    payload: { pulsePollId, emails },
  }),

  addAccessesRequest: (pulsePollId: string, accesses: AccessResource[]): NetworkRequestAction =>
    actionCreators.networkRequestWithActions({
      request: addAccesses(pulsePollId, accesses),
      requestAction: PulsePollEditActionTypes.ADD_ACCESSES_REQUEST,
      successAction: PulsePollEditActionTypes.ADD_ACCESSES_SUCCESS,
      errorAction: PulsePollEditActionTypes.ADD_ACCESSES_ERROR,
    }),

  updateAccess: (pulsePollId: string, accessId: string, role: AccessList.UserAccessRole): UpdateAccessAction => ({
    type: PulsePollEditActionTypes.UPDATE_ACCESS,
    payload: { pulsePollId, accessId, role },
  }),

  updateAccessRequest: (pulsePollId: string, accessId: string, accessResource: AccessResource): NetworkRequestAction =>
    actionCreators.networkRequestWithActions({
      request: updateAccess(pulsePollId, accessId, accessResource),
      requestAction: PulsePollEditActionTypes.UPDATE_ACCESS_REQUEST,
      successAction: PulsePollEditActionTypes.UPDATE_ACCESS_SUCCESS,
      errorAction: PulsePollEditActionTypes.UPDATE_ACCESS_ERROR,
    }),

  removeAccess: (pulsePollId: string, accessId: string): RemoveAccessAction => ({
    type: PulsePollEditActionTypes.REMOVE_ACCESS,
    payload: { pulsePollId, accessId },
  }),

  removeAccessRequest: (pulsePollId: string, accessId: string): NetworkRequestAction =>
    actionCreators.networkRequestWithActions({
      request: removeAccess(pulsePollId, accessId),
      requestAction: PulsePollEditActionTypes.REMOVE_ACCESS_REQUEST,
      successAction: PulsePollEditActionTypes.REMOVE_ACCESS_SUCCESS,
      errorAction: PulsePollEditActionTypes.REMOVE_ACCESS_ERROR,
    }),

  activatePulsePollRequest: (
    pulsePollId: string
  ): NetworkRequestAction =>
    actionCreators.networkRequestWithActions({
      request: activatePulsePoLL(pulsePollId),
      requestAction: PulsePollEditActionTypes.ACTIVATE_PULSE_POLL_REQUEST,
      successAction: PulsePollEditActionTypes.ACTIVATE_PULSE_POLL_SUCCESS,
      errorAction: PulsePollEditActionTypes.ACTIVATE_PULSE_POLL_ERROR,
    }),

  updateAccessList: (accesses: AccessResource[]) => ({
    type: PulsePollEditActionTypes.UPDATE_ACCESS_LIST,
    payload: { accesses },
  }),

  toggleManagersSynchronization: (surveyId: string, areManagersSynchronized: boolean): NetworkRequestAction =>
    actionCreators.networkRequestWithActions({
      request: toggleManagersSynchronization(surveyId, areManagersSynchronized),
      requestAction: PulsePollEditActionTypes.TOGGLE_MANAGERS_SYNCHRONIZATION_REQUEST,
      successAction: PulsePollEditActionTypes.TOGGLE_MANAGERS_SYNCHRONIZATION_SUCCESS,
      errorAction: PulsePollEditActionTypes.TOGGLE_MANAGERS_SYNCHRONIZATION_ERROR,
    }),

  fetchPulsePollAccessList: (pulsePollId: string): NetworkRequestAction => {
    return actionCreators.networkRequestWithActions({
      request: getAccessList(pulsePollId),
      requestAction: PulsePollEditActionTypes.FETCH_PULSE_POLLS_ACCESS_LIST_REQUEST,
      successAction: PulsePollEditActionTypes.FETCH_PULSE_POLLS_ACCESS_LIST_SUCCESS,
      errorAction: PulsePollEditActionTypes.FETCH_PULSE_POLLS_ACCESS_LIST_ERROR,
    });
  },

  savePulsePoll: (
    pulsePollId: string,
    pulsePoll: PulsePollResource,
    questionConfig: QuestionConfig
  ): SavePulsePollAction => ({
    type: PulsePollEditActionTypes.SAVE_PULSE_POLLS,
    payload: { pulsePollId, pulsePoll, questionConfig },
  }),

  toggleSurveyPreviewModalOpen: (open: boolean): TogglePreviewModalOpenAction => ({
    type: PulsePollEditActionTypes.TOGGLE_PREVIEW_MODAL_OPEN,
    payload: { open },
  }),

  activatePulsePoll: (pulsePollId: string): ActivatePulsePollAction => ({
    type: PulsePollEditActionTypes.ACTIVATE_PULSE_POLL,
    payload: { pulsePollId },
  }),

  exitPage: (pulsePollId: string): ExitPageAction => ({
    type: PulsePollEditActionTypes.EXIT_PAGE,
    payload: { pulsePollId },
  }),
};
