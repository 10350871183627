import React from "react";
import { Dropdown, Menu } from "antd";
import { ClickParam } from "antd/lib/menu";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { ExpandButton } from "@components/core/ExpandButton";
import { noOp } from "hyphen-lib/dist/lang/Functions";
import { TransWrapper } from "../withTrans";
import { getOr } from "hyphen-lib/dist/lang/Objects";

export interface DropDownButtonProps {
  options: { key: string | number | symbol; label: JSX.Element | string }[];
  fetchDimensionData?: () => any;
  loading?: boolean;
  onClick: (optionKey: any) => any;
  children: React.ReactNode;
  noValues?: string;
  className?: string;
  translate?: string;
}

export function DropDownButton({ options, onClick, children, noValues, 
  fetchDimensionData,
  loading,
  translate, ...rest }: DropDownButtonProps) {
  const menu = (
    // tslint:disable-next-line:jsx-no-lambda
    <Menu onClick={(event: ClickParam) => onClick(event.key)}>
      {
        options.map(option => (
          <Menu.Item data-cy={`value_${option.label}`} key={option.key as string}>
            <TransWrapper translate={translate}>{option.label}</TransWrapper>
          </Menu.Item>
        ))
      }
    </Menu>
  );

  const noMenu = (
    <Menu>
      <Menu.Item disabled>{noValues || "No data found"}</Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      data-cy="core_dropdown"
      trigger={["click"]}
      overlay={isNotEmptyArray(options) ? menu : noMenu}
      disabled={ getOr(loading, false) }
      // tslint:disable-next-line: jsx-no-lambda
      getPopupContainer={(node) => node}
      {...rest}
    >
      <ExpandButton
        onClick={ getOr(fetchDimensionData, noOp) }
        isExpandable={true}
        {...rest}
      >
        {children}
      </ExpandButton>
    </Dropdown>
  );
}
