import { BaseFilterProps } from "@screens/Insights/components/FiltersContent";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import Checkbox from "@components/core/Checkbox";
import React from "react";

export function FlagFilter({ label, value, onChange }: BaseFilterProps<boolean>) {
  const handleChange = (e: CheckboxChangeEvent) => {
    onChange(e.target.checked);
  };
  return (
    <Checkbox
      checked={value}
      onChange={handleChange}
    >
      {label}
    </Checkbox>
  );
}
