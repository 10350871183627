import { default as networkActionCreators } from "@store/network/actions";
import { createRequest, FilterParameter, SortParameter } from "@src/utils/networks";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { appendQueryString, generateQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { ActionPlanTemplateResource } from "hyphen-lib/dist/domain/resource/action/ActionPlanTemplateResource";

export interface FetchActionPlanTemplatesParameters {
  readonly filter?: FilterParameter;
  readonly sort?: SortParameter;
  readonly page: PageFilter;
}

export function fetchActionPlanTemplatesIfNeeded({filter, sort, page}: FetchActionPlanTemplatesParameters) {
  return networkActionCreators.fetchPageIfNeeded({
    id: ActionPlanTemplateResource.generateKey(filter, sort),
    type: ActionPlanTemplateResource.TYPE,
    page,
    rawPageSize: 30,
    request: pageFilter => createRequest(
      appendQueryString(
        "/action/templates",
        generateQueryString({
          filter,
          sort,
          page: pageFilter,
        })
      )
    ),
  });
}
