import React from "react";
import { ColumnProps, TableProps } from "antd/lib/table";
import styled from "styled-components";
import { VoicePostReportResource } from "hyphen-lib/dist/domain/resource/voice/VoicePostReportResource";

import Table from "@src/components/core/Table";
import { CommentsCounter } from "@src/components/core/CommentsCounter";
import {
  DynamicRowProps,
  withDynamicRows
} from "@src/components/core/DynamicRowsTable/withDynamicRows";
import CreateActionPlanButton from "@src/screens/Insights/components/Reports/CreateActionPlanButton";

import { TextCell } from "../../VoiceComments/components/cells/TextCell";
import { Votes } from "./cells/Votes";
import { PostedDestination } from "./cells/PostedDestination";
import { SentimentCell } from "./cells/SentimentCell";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { FocusArea, Module } from "hyphen-lib/dist/domain/FocusArea";
import { Trans } from "react-i18next";

interface VoicePostsTableProps extends TableProps<VoicePostReportResource> {
  readonly hasActionCreationRight: boolean;
  readonly focusAreas?: FocusAreaResource[];
  readonly onCreateFocusArea: (focusArea: Partial<FocusAreaResource>) => void;
}

export function VoicePostsTable({hasActionCreationRight, ...props}: VoicePostsTableProps) {
  const COLUMNS: ColumnProps<VoicePostReportResource>[] = [
    {
      title: (<Trans>Post</Trans>),
      dataIndex: "text",
      key: "text",
      width: "60%",
      render: (_, { text }) => <TextCell text={text} />,
    },
    {
      title: null,
      dataIndex: "upVotesCount",
      key: "upVotesCount",
      render: (_, { upVotesCount }) => <Votes count={upVotesCount} />,
    },

    {
      title: null,
      dataIndex: "downVotesCount",
      key: "downVotesCount",
      render: (_, { downVotesCount }) => <Votes count={downVotesCount} down />,
    },
    {
      title: null,
      dataIndex: "commentsCount",
      key: "commentsCount",
      width: "10%",
      render: (_, { commentsCount }) => (
        <CommentsCounter numberOfComments={commentsCount} />
      ),
    },
    {
      title: (<Trans>Posted In</Trans>),
      dataIndex: "postedIn",
      key: "postedIn",
      render: (_, { group }) => <PostedDestination group={group} />,
    },
    {
      title: (<Trans>Sentiment</Trans>),
      dataIndex: "sentimentScore",
      key: "sentimentScore",
      render: (_, { sentimentScore }) => (
        <SentimentCell sentimentScore={sentimentScore} />
      ),
    },
  ];

  function HoverRow({ row }: DynamicRowProps<VoicePostReportResource>) {
    function onCreateActionPlan() {
      props.onCreateFocusArea({
        title: row.text,
        source: FocusArea.Source.POST,
        module: Module.EMPLOYEE_VOICE,
        focusAreaType: FocusArea.FocusAreaType.POST,
        sourceUrl: {
          label: "Post",
          url: window.location.href,
        },
        actionPlans: [],
        postId: row._id
      });
    }
    return (
      <>
        <StyledHoverCell>
          {hasActionCreationRight && (
            <CreateActionPlanButton onClick={onCreateActionPlan} />
          )}
          <TextCell text={row.text} />
        </StyledHoverCell>
        <td>
          <Votes count={row.upVotesCount} />
        </td>
        <td>
          <Votes count={row.downVotesCount} down />
        </td>
        <td>
          <CommentsCounter numberOfComments={row.commentsCount} />
        </td>
        <td>
          <PostedDestination group={row.group} />
        </td>
        <td>
          <SentimentCell sentimentScore={row.sentimentScore} />
        </td>
      </>
    );
  }

  const HoveringTable = withDynamicRows(Table, HoverRow);

  return <HoveringTable columns={COLUMNS} {...props} />;
}

const StyledHoverCell = styled.td`
  position: relative;
`;
