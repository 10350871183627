import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import {
  appendQueryString,
  generateQueryString
} from "hyphen-lib/dist/util/net/HttpClient";
import { createBrowserHistory, Location } from "history";
import { Consumer } from "hyphen-lib/dist/lang/Functions";
import { getOr } from "hyphen-lib/dist/lang/Objects";
import Logger from "hyphen-lib/dist/util/Logger";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import qs from "qs";
import { QueryParams } from "@src/screens/Insights/components/Reports/Filters";

const log = Logger.create("Locations");

export const history = createBrowserHistory<BreadcrumbElement[]>();

/**
 * Navigate to the specified URL,
 * and stack the URL in the history,
 * also manage breadcrumb with the specified operation.
 *
 * @param {string} url
 * @param {BreadcrumbOperation} operation
 */
export function goTo(
  url: string,
  operation: BreadcrumbOperation = BREADCRUMB_OP_IDENTITY
) {
  const nextState = computeNextState(
    history.location.state,
    operation,
    history.location
  );

  history.push(url, nextState);
}

/**
 * Navigate back in the stack to an existing URL.
 *
 * @param {string} url
 * @return {BreadcrumbOperationNavigateBackTo}
 */
export function navigateBackTo(url: string) {
  goTo(url, {
    type: "navigateBackTo",
    url,
  });
}

export function navigateBack(fallbackUrl = "") {
  let dest: string = fallbackUrl;
  if (isNotEmptyArray(history.location.state)) {
    dest = (history.location.state )[0].url;
  }
  goTo(dest, { type: "navigateBack" });
}

/**
 * Replace the specified URL in the browser history.
 * Don't touch the breadcrumb.
 *
 * @param {string} url
 */
export function replaceTo(
  url: string,
  operation: BreadcrumbOperation = BREADCRUMB_OP_IDENTITY
) {
  const nextState = computeNextState(
    history.location.state,
    operation,
    history.location
  );

  history.replace(url, nextState);
}

/**
 * Add the specified parameters as query string of the current URL, and
 * push this new URL in the history stack.
 *
 * @param {Dictionary<any>} parameters
 */
export function pushLocation(parameters: Dictionary<any>) {
  modifyLocation(parameters, goTo);
}

/**
 * Add the specified parameters as query string of the current URL, and
 * replace this URL in the history stack.
 *
 * @param {Dictionary<any>} parameters
 */
export function replaceLocation(parameters: Dictionary<any>) {
  modifyLocation(parameters, replaceTo);
}

function modifyLocation(
  parameters: Dictionary<any>,
  modifier: Consumer<string>
) {
  const newLocation = generateUrl(parameters);
  if (getCurrentLocation() !== newLocation) {
    modifier.call(null, newLocation);
  }
}

function generateUrl(parameters: Dictionary<any>) {
  return appendQueryString(
    history.location.pathname,
    generateQueryString(parameters)
  );
}

export function getCurrentLocation(): string {
  return extractLocation(history.location);
}

function extractLocation(location: Location<any>): string {
  return location.pathname + location.search;
}

/*
    ---- Breadcrumb management ----
 */

export type BreadcrumbOperation =
  | BreadcrumbOperationStack
  | BreadcrumbOperationIdentity
  | BreadcrumbOperationNavigateBack
  | BreadcrumbOperationNavigateBackTo
  | BreadcrumbOperationNewStack;

const BREADCRUMB_OP_IDENTITY: BreadcrumbOperationIdentity = {
  type: "identity",
};

export interface BreadcrumbOperationIdentity {
  readonly type: "identity";
}

export interface BreadcrumbOperationStack {
  readonly type: "stack";
  readonly label: string;
  readonly customUrl?: string;
}

export interface BreadcrumbOperationNavigateBack {
  readonly type: "navigateBack";
}

export interface BreadcrumbOperationNavigateBackTo {
  readonly type: "navigateBackTo";
  readonly url: string;
}

export interface BreadcrumbElement {
  readonly label: string;
  readonly url: string;
}

export interface BreadcrumbOperationNewStack {
  readonly type: "newStack";
}

/**
 * Factory methods to create some breadcrumb operations.
 */
export const Breadcrumb = {
  stack: (label: string, customUrl?: string): BreadcrumbOperationStack => ({
    type: "stack",
    label,
    customUrl,
  }),
  newStack: (): BreadcrumbOperationNewStack => ({
    type: "newStack",
  }),
};

// reducer ...
// fixme: remove the switch, come on a bit of OOP, not that complicated... 😈

function computeNextState(
  state: BreadcrumbElement[] = [],
  operation: BreadcrumbOperation,
  location: Location<any>
): BreadcrumbElement[] {
  switch (operation.type) {
    case "identity":
      return state;
    case "stack":
      const history = state.map(history => history.label);
      if(history.includes(operation.label)) {
        return state;
      }
      return [
        ...state,
        {
          label: operation.label,
          url: getOr(operation.customUrl, extractLocation(location)),
        },
      ];
    case "navigateBack":
      if (isNotEmptyArray(state)) {
        return state.slice(1);
      }
      return state;
    case "navigateBackTo":
      return navigateInBackHistory(state, operation);
    case "newStack":
      return [];
    default:
      log.warn(
        `Unhandled breadcrumb operation: ${
          (operation as any).type
        }, so skip it.`
      );
      return state;
  }
}

function navigateInBackHistory(
  stack: BreadcrumbElement[],
  operation: BreadcrumbOperationNavigateBackTo
): BreadcrumbElement[] {
  // find the url in the stack
  let index = -1;
  for (let idx = 0; idx < stack.length; idx++) {
    if (stack[idx].url === operation.url) {
      index = idx;
      break;
    }
  }

  if (index === -1) {
    // the URL was not part of the stack :/ so just return an empty stack
    log.warn(
      `Unable to find URL '${operation.url}' in breadcrumb stack, so just start with a brand new stack.`
    );
    return [];
  }

  // return the stack till the URL
  return stack.slice(0, index);
}

export function getQueryParams(location: Location<any>): QueryParams {
  return qs.parse(location.search, { ignoreQueryPrefix: true });
}