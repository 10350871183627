import { default as networkActionCreators } from "@store/network/actions";
import { createRequest, FilterParameter, SortParameter } from "@src/utils/networks";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { appendQueryString, generateQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { ActionResource } from "hyphen-lib/dist/domain/resource/action/ActionResource";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { FocusAreaListPageParameters } from "@src/screens/Insights/Actions/store/actions";

export interface FetchActionsParameters {
  readonly filter?: FilterParameter;
  readonly sort?: SortParameter;
  readonly page: PageFilter;
}

export function fetchActionsIfNeeded({filter, sort, page}: FetchActionsParameters) {
  return networkActionCreators.fetchPageIfNeeded({
    id: ActionResource.generateKey(filter, sort),
    type: ActionResource.TYPE,
    page,
    rawPageSize: 30,
    request: pageFilter => createRequest(
      appendQueryString(
        "/actions",
        generateQueryString({
          filter,
          sort,
          page: pageFilter,
        })
      )
    ),
  });
}

export function fetchActionsCountIfNeeded(filter: FilterParameter) {
  return networkActionCreators.fetchCountIfNeeded({
    id: ActionResource.generateKey(filter),
    type: ActionResource.TYPE,
    request: createRequest(
      appendQueryString(
        "/users/count",
        generateQueryString({ filter })
      )
    ),
  });
}

export function fetchFocusAreasIfNeeded({filter, sort, page}: FocusAreaListPageParameters) {
  return networkActionCreators.fetchPageIfNeeded({
    id: FocusAreaResource.generateKey(filter, sort),
    type: FocusAreaResource.TYPE,
    page,
    rawPageSize: 30,
    request: pageFilter => createRequest(
      appendQueryString(
        "/focusAreas",
        generateQueryString({
          filter,
          sort,
          page: pageFilter,
        })
      )
    ),
  });
}