import React, {MouseEvent} from "react";
import styled from "styled-components";
import { Tooltip as AntTooltip } from "antd";
import { TooltipProps } from "antd/lib/tooltip";
import Palette from "@src/config/theme/palette";

interface CustomProps {
  className?: string;
  wrapperClassName?: string;
}

type Props = CustomProps & TooltipProps;

const stopPropogation = (e: MouseEvent) => {
  e.stopPropagation();
};

function Tooltip(props: Props) {
  const { className, overlayClassName, wrapperClassName, ...rest } = props;
  return (
    <TooltipWrapper onClick={stopPropogation} className={wrapperClassName}>
      <AntTooltip
        overlayClassName={`${className} ${overlayClassName}`}
        {...rest}
      />
    </TooltipWrapper>
  );
}

export default styled(Tooltip)<Props>`
  .ant-tooltip-inner {
    padding: 16px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
  }
`;

const TooltipWrapper = styled.a`
  text-decoration: none;
  cursor: pointer;
  &.benchmark-tooltip {
    position: absolute;
    top: 0;
    right: 0;
    background-color: ${Palette.bluePurple};
    border-radius: 0 3px;
  }
`;
