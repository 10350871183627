import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { SegmentSubtitle, CategorySubtitle } from "hyphen-lib/dist/domain/resource/report/common/Highlights";
import { isNullOrUndefined } from "hyphen-lib/dist/lang/Objects";
import { translate } from "./i18next";
import { TFunction } from "react-i18next";

export enum CategorySection {
  TOP = "top",
  WORST = "worst",
}

export enum SegmentSection {
  BEST = "best",
  WEAKEST = "weakest",
  IMPROVED = "improved",
  DECLINED = "declined",
}

export function generateSegmentSubtitle(t: TFunction | void, subtitle: Optional<SegmentSubtitle>,
  section: CategorySection): Optional<string> {
  if (isNullOrUndefined(subtitle)) {
    return Optional.empty();
  }

  // @ts-ignore
  // eslint-disable-next-line max-len
  return `${translate(t, "Segment")} ${subtitle.segment} ${translate(t, `especially ${section === CategorySection.TOP ? "high" : "low"}`)} ${translate(t, "with")} ${subtitle.score}% ${translate(t, "favorability")}!`;
}

export function generateCategorySubtitle(t: TFunction | void, subtitle: Optional<CategorySubtitle>,
  section: SegmentSection): Optional<string> {
  if (isNullOrUndefined(subtitle)) {
    return Optional.empty();
  }

  if (section === SegmentSection.BEST || section === SegmentSection.WEAKEST) {
    // @ts-ignore
    // eslint-disable-next-line max-len
    return `${translate(t, "Category")} ${subtitle.category} ${translate(t, `especially ${section === SegmentSection.BEST ? "high" : "low"}`)} ${translate(t, "with")} ${subtitle.score}% ${translate(t, "favorability")}!`;
  }

  // @ts-ignore
  // eslint-disable-next-line max-len
  return `${translate(t, "Category")} ${subtitle.category} ${translate(t, `especially ${section === SegmentSection.IMPROVED ? "improved" : "declined"}`)} ${translate(t, "of")} ${subtitle.score}%!`;
}
