import React from "react";
import { fromJS } from "immutable";
import styled from "styled-components";
import { RouteComponentProps, withRouter } from "react-router";

import { Sentiment } from "@hyphen-lib/domain/common/ComputationTypes";
import Palette from "@src/config/theme/palette";
import { replaceLocation, getQueryParams } from "@src/utils/locations";
import { getOr, getProperty, isNotEmptyObject, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { Word, Topic } from "@hyphen-lib/business/calculation/sentiment/Words";
export interface Props extends RouteComponentProps {
  word: ( Word | Topic );
  opacity?: number;
  onWordClick?: (word: string, sentiment: string) => void;
}

class CommonWord extends React.Component<Props> {

  updateQueryParams = (param: any) => {
    const { location } = this.props;
    const tempQueryParams = getQueryParams(location);

    if (
      Object.keys(tempQueryParams).includes("filter") &&
      Object.keys(tempQueryParams.filter).includes("sentiments")
    ) {
      delete tempQueryParams.filter.sentiments;
    }
    const queryParams = fromJS(tempQueryParams).mergeDeep(param).toJS();
    replaceLocation(queryParams);
  };

  handleWordClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const { word: { sentiment }, onWordClick } = this.props;
    const wordDiv = e.target as HTMLDivElement;
    const commonWord = (isNotEmptyObject(wordDiv.children) ? wordDiv.children[0] : e.target) as HTMLDivElement;
    const innerText = commonWord.innerText;

    if (isNotNullNorUndefined(onWordClick)) {
      return onWordClick(innerText, sentiment.toString());
    }

    this.updateQueryParams({
      filter: {
        freeText: innerText,
        sentiments: [sentiment.toString()],
      },
    });
  };

  render() {
    const { word, opacity } = this.props;
    let backgroundColor = Palette.black;
    let color = Palette.white;
    switch (word.sentiment) {
      case Sentiment.POSITIVE:
        backgroundColor = Palette.aquaBlue;
        break;

      case Sentiment.NEUTRAL:
        backgroundColor = Palette.lightGrey;
        color = Palette.black;
        break;

      case Sentiment.NEGATIVE:
        backgroundColor = Palette.darkPink;
        break;
    }

    return (
      <WordContainer
        opacity={opacity}
        backgroundColor={backgroundColor}
        color={color}
        onClick={this.handleWordClick}>
        <span id="common-word"> 
          { getOr(getProperty(word, "word"), getProperty(word, "name"))}
        </span> ({word.occurrences})
      </WordContainer>
    );
  }
}

const WordContainer = styled.div<{ backgroundColor: string; color: string; opacity?: number }>`
    cursor: pointer;
    padding: 0 8px;
    line-height: 28px;
    margin-right: 8px;
    opacity: ${props => props.opacity ? props.opacity : 0.8};
    background-color: ${props => props.backgroundColor};
    color: ${props => props.color};
    border-radius: 3px;
    min-width: 100px;
    text-align: center;
`;

export default withRouter(CommonWord);
