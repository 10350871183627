import React from "react";
import { DynamicRowProps } from "@components/core/DynamicRowsTable/withDynamicRows";
import { Trans, useTranslation } from "react-i18next";
import {
  CellContainer,
  CellInnerContainer
} from "@src/screens/Insights/SurveyList/components/cells/Cells";
import { PulsePollInfoResource } from "hyphen-lib/dist/domain/resource/PulsePollInfoResource";
import { NameLabel, TypeLabel } from "@src/screens/Insights/SurveyList/components/cells/NameCell";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { formatDate } from "hyphen-lib/dist/lang/Dates";
import { translate } from "@src/utils/i18next";

export function PulsePollIterationCell({
  row,
  index,
}: DynamicRowProps<PulsePollInfoResource>) {

  const { t } = useTranslation();

  function renderCurrentClosingDate() {

    const { iterationClosingDate, status } = row;

    if (isNotNullNorUndefined(iterationClosingDate)) {
      const closingDate = formatDate(iterationClosingDate , {
        hour: "numeric",
        minute: "numeric",
      });

      return <TypeLabel
        data-cy={`listView_activeIteration_${index}`}>
        1 <Trans>active iteration will close on</Trans> {closingDate}
      </TypeLabel>;
    } else {
      return status === "active" && <TypeLabel data-cy="pollList_noActiveIteration">
        <Trans>No active iteration</Trans></TypeLabel>;
    }
  }

  const renderIterationsText = (iterationsLength: number) => {
    return iterationsLength === 1 ? 
    `${iterationsLength} ${translate(t, "Iteration")}` : `${iterationsLength} ${translate(t, "Iterations")}`;
  };

  return (
    <CellContainer
      align="center"
      justifyContent="flex-start"
    >
      <CellInnerContainer margin={0} width="100%">
        <CellContainer align="flex-start" justifyContent="flex-start" >
          <CellInnerContainer width="100%" data-cy={`listView_iteration_${index}`}>
            <NameLabel>{renderIterationsText(row.instances.length)}</NameLabel>
            {renderCurrentClosingDate()}
          </CellInnerContainer>
        </CellContainer>
      </CellInnerContainer>
    </CellContainer>
  );
}
