import React from "react";
import { Map } from "immutable";
import {
  Engagement,
  Communication,
  Management,
  Benefits,
  CollaborationTeamwork,
  CompanyPerformance,
  Environment,
  FeedBack,
  Growth,
  Leadership,
  Meaningful,
  Purpose,
  Workload
} from "@components/core/svg/ActionListIcons";

export const categorySVGMapper = Map({
  Engagement: <Engagement />,
  Management: <Management />,
  Communication: <Communication />,
  "Collaboration & Teamwork": <CollaborationTeamwork />,
  Workload: <Workload />,
  Purpose: <Purpose />,
  "Meaningful Work": <Meaningful />,
  "Feedback & Recognition": <FeedBack />,
  Environment: <Environment />,
  Growth: <Growth />,
  Benefits: <Benefits />,
  Leadership: <Leadership />,
  All: <CompanyPerformance />,
  "Company Performance": <CompanyPerformance />,
});
