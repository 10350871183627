import { Action } from "hyphen-lib/dist/domain/Action";
import { PostCategoryResource } from "hyphen-lib/dist/domain/resource/post/PostCategoryResource";
import { useTranslation } from "react-i18next";
import { applyDefault } from "@src/utils/Currier";

import { MultiSelectFilter } from "@screens/Insights/components/Filters/base/MultiSelectFilter";
import { BaseFilterProps } from "@screens/Insights/components/FiltersContent";
import { GlobalActionPlanTemplate } from "hyphen-lib/dist/domain/GlobalActionPlanTemplate";

export const ActionStatusFilter = applyDefault(MultiSelectFilter, {
  trans: true,
  availableValues: [
    { value: Action.Status.TODO, label: "To Do"},
    { value: Action.Status.IN_PROGRESS, label: "In Progress"},
    { value: Action.Status.COMPLETED, label: "Completed"},
    { value: Action.Status.DISMISSED, label: "Dismissed"},
  ],
});

export const ActionSourceFilter = applyDefault(MultiSelectFilter, {
  trans: true,
  availableValues: [
    { value: "category", label: "Category"},
    { value: "comment", label: "Comment"},
    { value: "question", label: "Question"},
    { value: "segment", label: "Segment"},
  ],
});

export const ActionDifficultyFilter = applyDefault(MultiSelectFilter, {
  trans: true,
  availableValues: [
    { value: GlobalActionPlanTemplate.DifficultyType.EASY, label: GlobalActionPlanTemplate.DifficultyType.EASY },
    {
      value: GlobalActionPlanTemplate.DifficultyType.INTERMEDIATE,
      label: GlobalActionPlanTemplate.DifficultyType.INTERMEDIATE,
    },
    { value: GlobalActionPlanTemplate.DifficultyType.HARD, label: GlobalActionPlanTemplate.DifficultyType.HARD },
  ],
});

export const ActionLengthFilter = applyDefault(MultiSelectFilter, {
  trans: true,
  availableValues: [
    { value: GlobalActionPlanTemplate.LengthType.SHORT, label: GlobalActionPlanTemplate.LengthType.SHORT },
    { value: GlobalActionPlanTemplate.LengthType.MEDIUM, label: GlobalActionPlanTemplate.LengthType.MEDIUM },
    { value: GlobalActionPlanTemplate.LengthType.LONG, label: GlobalActionPlanTemplate.LengthType.LONG },
  ],
});

export interface CategoryFilterProps extends BaseFilterProps<string[]> {
  categories: PostCategoryResource[];
}

export interface ModuleFilterProps extends BaseFilterProps<string[]> {
  readonly modules: { label: string; value: string }[];
}

export function CategoryFilter({
  label,
  categories,
  ...rest
}: CategoryFilterProps) {
  return (
    <MultiSelectFilter
      label={label}
      availableValues={categories.map(category => ({
        value: category._id,
        label: category.label,
      }))}
      {...rest}
    />
  );
}

export function ModuleFilter({
  label,
  modules,
  ...rest
}: ModuleFilterProps) {
  const { t } = useTranslation();
  return (
    <MultiSelectFilter
      label={label}
      availableValues={modules.map(availableModule => ({
        value: availableModule.value,
        label: t(availableModule.label),
      }))}
      {...rest}
    />
  );
}
