import React from "react";
import styled from "styled-components";
import { ActionResource } from "@hyphen-lib/domain/resource/action/ActionResource";

import Palette from "@src/config/theme/palette";
import { DynamicRowProps } from "@components/core/DynamicRowsTable/withDynamicRows";
import { CellContainer, CellInnerContainer } from "./Cells";
import { getProperty, isEmptyObject } from "hyphen-lib/dist/lang/Objects";
import { Link } from "react-router-dom";

export function FocusAreaCell({ row }: DynamicRowProps<ActionResource>) {
  const focusArea = getProperty(row,"focusArea", null);
  if (isEmptyObject(focusArea)) {
      return null;
  }
  const focusAreaTitle = `${focusArea.focusAreaType} ${row?.source?.label} in`;
  return (
    <CellContainer align="center" justifyContent="flex-start">
      <CellInnerContainer margin={0} width="86px">
        <FocusAreaDetailWrapper>
            <Title>{focusAreaTitle}</Title>
            <StyledLink to={focusArea.sourceUrl.url}>{focusArea.sourceUrl.label}</StyledLink>
        </FocusAreaDetailWrapper>
      </CellInnerContainer>
    </CellContainer>
  );
}

const FocusAreaDetailWrapper = styled.div`
  display: flex;
  width: 230px;
  max-width: 230px;
  flex-direction: column;
`;

const Title = styled.div`
  color: ${Palette.veryDarkBlueGrey};
  font-size: 13px;
  line-height: 15px;
`;

const StyledLink = styled(Link)`
  font-size: 14px;
  color: ${Palette.bluePurple};
  line-height: 20px;

  &:hover {
    color: ${Palette.bluePurple};
  }

  &:visited {
    color: ${Palette.bluePurple};
  }
`;