import { State } from "@store/types";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { PulsePollResource } from "hyphen-lib/dist/domain/resource/PulsePollResource";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { getUntypedElementById } from "@src/store/network/selectors";
import { getManageesId } from "@src/store/network/resource/Untypeds";
import { AccessResource } from "hyphen-lib/dist/domain/access/AccessResource";
import { PulsePollEditStateProps } from "./pulsePollEditReducers";
import { UserEmailInsightAccess } from "hyphen-lib/dist/domain/User";

export function getPulsePollEditState(
  state: State,
  pulsePollId: string
): PulsePollEditStateProps {
  return state.get("insights_pulsePollEdit").toObject();
}

export function getPulsePoll(state: State) {
  return state.insights_pulsePollEdit.pulsePoll;
}

export const getPulsePollParticipants = (state: State) => Loadable.loaded({});

export const fetchPulsePollParticipantsCount = (state: State) => Loadable.loaded({ manual: 0, criteria: 0, total: 0 });

export function getSpecificPulsePollAudienceEmails(
  state: State,
  loadablePulsePoll: Loadable<PulsePollResource>
): Optional<UserEmailInsightAccess[]> {
  if (Loadable.isLoaded(loadablePulsePoll)) {
    return Store.Element.unboxIfLoadedOr(
      getUntypedElementById<UserEmailInsightAccess[]>(state, getManageesId()),
      []
    );
  }

  return Optional.empty();
}

export function hasPulsePollPendingAccessesUpdates(state: State) {
  return state.insights_pulsePollEdit.hasPendingAccessesUpdates;
}

export function getPulsePollAccesses(state: State): AccessResource[] {
  return state.insights_pulsePollEdit.accesses;
}
