import styled from "styled-components";
import { Trans } from "react-i18next";
import Palette from "@src/config/theme/palette";
import TopicsLegend from "./../TopicsLegend";
import ReactWordcloud from "@src/components/core/WordCloud";
import { fontSizeConverter } from "@src/utils/Graphs";
import { useCallback } from "react";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { TopicOverviewResource } from "hyphen-lib/dist/domain/resource/survey/report/TopicOverviewResource";
import { Sentiment } from "hyphen-lib/dist/domain/common/ComputationTypes";
import { getTopicsChartData, SentimentMap } from "@src/screens/Insights/Survey/components/Topics/SurveyTopicsChart";
interface Props {
  readonly width: number;
  readonly height: number;
  readonly topics: TopicOverviewResource[];
  readonly noDescription?: boolean;
  readonly minFontSize?: number;
  readonly maxFontSize?: number;
  readonly padding?: number;
  readonly animate?: boolean;
  readonly setDimension?: boolean;
}

function WordCloud({topics, width, height, noDescription = false,
  minFontSize = 20, maxFontSize = 50, padding = 15,
  animate = false, setDimension = true}: Props) {
  const topicsCloud = getTopicsChartData(topics);
  topicsCloud.data.forEach( word => {
    word.size = fontSizeConverter(word.value, topicsCloud.min, topicsCloud.max, minFontSize, maxFontSize);
  });
  const fontSize = useCallback((d) => d.size, []);
  const rotate = useCallback((word) => 0, []);
  const fill = useCallback((d, i) => d.sentimentText === SentimentMap[Sentiment.NEUTRAL] ? Palette.bluishGrey : 
    (d.sentimentText === SentimentMap[Sentiment.POSITIVE] ? Palette.aquaBlue : Palette.darkPink), []);
  return (
    <Container setDimension={setDimension}>
      
      { !noDescription && <>
        <Title>
          <Trans>Size of the word signifies the amount of comments on the topic.</Trans>
        </Title>
        <TopicsLegend/> </>
      }

      { isNotEmptyArray(topicsCloud.data) &&
        <ReactWordcloud
          data={topicsCloud.data}
          height={height}
          width={width}
          fontWeight="normal"
          fontSize={fontSize}
          spiral="rectangular"
          rotate={rotate}
          padding={padding}
          random={Math.random}
          fill={fill}
          animate={animate}
          setDimension={setDimension}
        /> }
    </Container>
  );
}

const Container = styled.div<{setDimension: boolean}>`
  background: ${props => props.setDimension ? "transparent" : Palette.white};
  font-family: Lato, sans-serif;
  color: ${Palette.veryDarkBlueGrey};
  height: 100%;
  width: 100%;
`;

const Title = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  color: ${Palette.bluishGrey};
`;

export default WordCloud;