import React from "react";
import Modal from "@src/components/core/Modal";
import { Form } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { ModalProps } from "antd/lib/modal";
import Button from "@components/core/Button";
import Input from "@components/core/Input";
import styled from "styled-components";
import { InputProps } from "antd/lib/input";
import { Trans } from "react-i18next";

export interface AddRoleModalProps {
  readonly visible: boolean;
  readonly isCreatingRole: boolean;
  readonly onSubmit: (value: string) => void;
  readonly onCancel: () => void;
  readonly doesRoleNameExist: (role: string) => boolean;
}

type Props = AddRoleModalProps & FormComponentProps;

class AddRoleModal extends React.Component<Props> {
  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const { form } = this.props;
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.onSubmit(values.roleName);
      }
    });
  };

  validateName = (rule: any, value: any, callback: any) => {
    const { doesRoleNameExist } = this.props;
    if (doesRoleNameExist(value)) {
      return callback("Role already exists!");
    }
    callback();
  };

  render() {
    const {
      visible,
      form,
      isCreatingRole,
    } = this.props;
    const { getFieldDecorator, isFieldsTouched } = form;

    const isSaveButtonDisabled = !isFieldsTouched();
    return (
      <StyledModal
        visible={visible}
        title={<Trans>Add role</Trans>}
        centered
        footer={null}
        destroyOnClose={true}
        onCancel={this.props.onCancel}
      >
        {/*
				//	@ts-ignore */}
        <StyledForm onSubmit={this.handleSubmit}>
          <ModalContent>
            <Form.Item>
              {getFieldDecorator("roleName", {
                rules: [{
                  required: true, message: (<Trans>Please input the Role Name!</Trans>),
                }, {
                  min: 3, message: (<Trans>Role Name must be more than 3 characters!</Trans>),
                }, {
                  max: 30, message: (<Trans>Role Name must be less than 30 characters!</Trans>),
                }, {
                  validator: this.validateName,
                }],
              })(
                <CustomInput
                  label={<Trans>Role Name</Trans>}
                  name="roleName"
                  description={<Trans>You can set permissions for 
                    this role after clicking Create</Trans>}
                />
              )}
            </Form.Item>
          </ModalContent>
          <ActionContainer>
            <StyledButton
              key="okLabel"
              color="blue"
              disabled={isSaveButtonDisabled || isCreatingRole}
              htmlType="submit"
              translate="yes"
            >
              {isCreatingRole ? "Creating" : "Create"}
            </StyledButton>
          </ActionContainer>
        </StyledForm>
      </StyledModal >
    );
  }
}

const ModalContent = styled.div`
  margin-top: 10px;
  padding-left: 32px !important;
  padding-right: 32px !important;
  max-height: calc(100vh - 300px);
  overflow-x: scroll;
`;

const StyledModal = styled(Modal)<ModalProps & {children: React.ReactNode}>`
  width: 510px !important;
`;

const ActionContainer = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-around;
  padding-left: 32px !important;
  padding-right: 32px !important;
`;

const StyledButton = styled(Button)`
  // margin-left: 32px;
  min-width: 150px;
`;

const StyledForm = styled(Form) <FormComponentProps>`
	.ant-form-item {
		margin-bottom: 16px;
	}
	.ant-form-item-label {
		line-height: 25px;
	}
	.ant-form-item-label label {
		color: #213848 !important;
	}
`;

const CustomInput = styled(Input)<InputProps>`
  .ant-form-explain:nth-child(2) {
    display: none;
  }
`;

// @ts-ignore
export default Form.create<Props>()(AddRoleModal);
