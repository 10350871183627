import React from "react";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { Trans } from "react-i18next";

interface CardProps {
  description: any;
  imageSrc: string;
  buttonLabel?: string;
  buttonActive?: boolean;
  leftest?: boolean;
  buttonLink?: string;
  isDownload?: boolean;
  buttonHandler?: () => any;
}

export default class Card extends React.Component<CardProps> {

  buttonAction = () => {
    const { buttonLink, buttonHandler } = this.props;
    if (isNotNullNorUndefined(buttonLink)) {
      window.open(buttonLink, "_blank");

    }
    else if (isNotNullNorUndefined(buttonHandler)) {
      return buttonHandler();
    }

  };
  render() {
    const { description, imageSrc, buttonLabel, buttonActive, leftest,
    } = this.props;
    const Button: any = buttonActive ? ActiveButton : DisabledButton;

    return (
      <CardContainer style={{ marginLeft: leftest ? 0 : 30 }}>
        <ImageDescription>
          <CardImage src={imageSrc} />
          {description}
        </ImageDescription>
        {buttonLabel ?
          <Button onClick={this.buttonAction} data-jest='download_template_button'>
            <Trans>{buttonLabel}</Trans>
          </Button>
          : null
        }
      </CardContainer>
    );
  }
}

const buttonStyle = `
  line-height: 30px;
  padding: 0 23px;
  border-radius: 2px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1300px) {
    font-size: 13px;
  }
`;

const ActiveButton = styled.div`
  background-color: ${Palette.aquaBlue};
  border: solid 1px #00b8cc;
  color: white;
  cursor: pointer;
  ${buttonStyle}
`;

const DisabledButton = styled.button`
  border: solid 1px #9dacbf;
  color: white;
  cursor: pointer;
  color: #92a0b3;
  ${buttonStyle}
`;

const CardContainer = styled.div`
  width: 285px;
  height: 273px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 37px 24px 34px;
  border-radius: 3px;
  border: solid 1px ${Palette.lightGreyBlue};
  box-sizing: border-box;

  @media only screen and (max-width: 1393px) {
    height: 297px;
  }
`;

const CardImage = styled.img`
  height: 56px;
  margin-bottom: 20px;
`;

const ImageDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
