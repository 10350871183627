import { not } from "hyphen-lib/dist/lang/Booleans";

/*
  This was the previous validatiin for dimension keys. This is being removed in light of this discussion.
  https://betterworks.slack.com/archives/GUK9JNY2K/p1597056311017500

  So, we are going to use the same validation rule on dim labels for dim keys as well.
*/
// function isLowerCaseAndValid(text: string) {
//   return (/^[A-Z\sa-z0-9_]+$/).test(text);
// }

const PROHIBITED_DIMENSION_LABELS = ["language"];

function isProhibitedDimLabel(text: string) {
  return PROHIBITED_DIMENSION_LABELS.includes(text.toLowerCase());
}
function minOneAlphabetAndNoSpecialChars(text: string) {
  // no number only
  // no special chars except space, -, _, ), (
  // min one alphabet
  return (/^[\w\s-)(]*[a-zA-Z]+[\w\s-)(]*$/).test(text);
}

function isWithinCharacterLimit(text: string) {
  return (/^(.{3,30})$/).test(text);
}

export function countDuplicates(original: string[]) {
  const uniqueItems = new Set();
  const duplicates = new Set();
  for (const value of original) {
    if (uniqueItems.has(value)) {
      duplicates.add(value);
    } else {
      uniqueItems.add(value);
    }
  }
  return duplicates.size;
}

export function isValidDimensionKey(text: string) {
  return minOneAlphabetAndNoSpecialChars(text) && isWithinCharacterLimit(text);
}

export function isValidDimensionLabel(text: string) {
  return minOneAlphabetAndNoSpecialChars(text) && not(isProhibitedDimLabel(text)) && isWithinCharacterLimit(text);
}

export function generateDimensionKey(text: string) {
  return text.replace(/[^A-Z0-9_]/ig, "").toLowerCase();
}
