import React from "react";
import { Paragraph } from "@src/components/core/Typography";

interface AssigneeCellProps {
  readonly assigneeEmail: string;
  readonly currentUserEmail: string;
}

export function AssigneeCell({ assigneeEmail, currentUserEmail }: AssigneeCellProps) {
  return <Paragraph translate="yes">{assigneeEmail === currentUserEmail ? "Me" : assigneeEmail}</Paragraph>;
}
