import {
  default as networkActionCreators, NetworkEventErrorAction,
  NetworkEventRequestAction,
  NetworkEventSuccessAction
} from "@store/network/actions";
import { createRequest, CURRENT } from "@src/utils/networks";
import { appendQueryString, generateQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";
import { ActionTypes } from "@screens/Insights/store/actions";

export function fetchCurrentUserIfNeeded() {
  return networkActionCreators.fetchElementIfNeeded({
    id: CURRENT,
    type: CurrentUserResource.TYPE,
    request: createRequest(
      appendQueryString(
        "/users/current",
        generateQueryString({ include: ["company"] })
      )
    ),
    callbackActions: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => ({ type: ActionTypes.FETCH_CURRENT_USER_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({ type: ActionTypes.FETCH_CURRENT_USER_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({ type: ActionTypes.FETCH_CURRENT_USER_ERROR, payload }),
      ],
    },
  });
}
