import React, { ChangeEvent } from "react";
import styled from "styled-components";
import { Trans } from "react-i18next";
import Palette from "@src/config/theme/palette";
import { Paragraph } from "@src/components/core/Typography";
import { isEmpty, isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import FocusArea from "../../components/FocusArea/FocusArea";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import AreYouSureModal from "@src/components/core/AreYouSureModal";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { 
  areEquals,
  getOr,
  isEmptyObject,
  isNotEmptyObject,
  isNotNullNorUndefined,
  isNullOrUndefined,
  mapOr 
} from "hyphen-lib/dist/lang/Objects";
import CreatedActionPlan from "../../components/FocusArea/CreatedActionPlan";
import { State } from "@src/store/types";
import { getFocusAreaListStoreProps } from "../../store/selectors";
import { extractDataAndTotalFromPage, getExistingPage } from "@store/network/selectors";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { FilterParameter, SortParameter } from "@src/utils/networks";
import { Store } from "@hyphen-lib/util/store/Store";
import { generateQueryString, parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { parseNumber } from "hyphen-lib/dist/lang/Number";
import { getParameters } from "@src/screens/Insights/parameters/store/selectors";
import { connect } from "react-redux";
import { ParametersState } from "@src/screens/Insights/parameters/store/reducers";
import { focusAreaListActionCreators, FocusAreaListPageParameters } from "../../store/actions";
import { NetworkEventSuccessAction, NetworkRequestAction } from "@src/store/network/actions";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { applyExistingParametersIfNeeded, PropMapping } from "@src/utils/parameters";
import { fetchFocusAreasIfNeeded } from "@src/store/network/resource/ActionResources";
import { parametersActionCreators } from "@src/screens/Insights/parameters/store/actions";
import { Breadcrumb, goTo, replaceLocation } from "@src/utils/locations";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { FetchError } from "@src/screens/Insights/errors/FetchError";
import Spin from "@src/components/core/Spin";
import NoResult, { NoResultProps } from "@src/components/core/NoResult";
import { SearchBar } from "@src/components/core/SearchBar";
import { DropDownButton, DropDownButtonProps } from "@src/components/core/DropDownButton";
import { fromJS } from "immutable";
import debounce from "lodash.debounce";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import { Pagination } from "antd";
import { ActionPlanTemplateResource } from "hyphen-lib/dist/domain/resource/action/ActionPlanTemplateResource";
import { CreationModes } from "@src/screens/Insights/ActionPlans/containers/CreateActionPlanContainer";
import { Action } from "hyphen-lib/dist/domain/Action";
import { prepareCreateActionPlanContext } from "../../utils";

// eslint-disable-next-line max-len
const focusAreaFelicitation = "Congratulations on selecting Focus Areas in your Engage reports! Your next step is to define what is going to be done to improve, i.e. to turn Focus Areas into Action plans.";
interface FocusAreasContainerState {
  showRecommendedActionPlans: { focusAreaId: string; show: boolean }[];
  showCreatedActions: boolean;
  actionsCreated: Optional<FocusAreaResource.ActionPlan>[];
  sortedBy: SortBy;
  activeKebabMenuRow: { focusAreaId: string };
  selectedFocusAreaId: string;
}

export interface FocusAreasContainerReduxProps {
  readonly focusAreas: FocusAreaResource[];
  readonly loading: boolean;
  readonly total: number;
  readonly page: PageFilter;
  readonly sort: SortParameter;
  readonly filter: FilterParameter;
  readonly isUpdatingFocusAreaResource: boolean;
  readonly isDeletingFocusAreas: boolean;
  readonly parameters: ParametersState;
  readonly isFetchingActionPlanTemplates: boolean;
  readonly existingPage: Store.Page<FocusAreaResource>;
  readonly focusAreaTemplates: ActionPlanTemplateResource[];
}

export interface FocusAreasContainerDispatchProps {
  readonly onFetchIfNeeded: (parameters: FocusAreaListPageParameters) => any;
  readonly onModifyList: (parameters: FocusAreaListPageParameters) => any;
  readonly onFocusAreaUpdate: (
    focusAreaId: string,
    focusArea: FocusAreaResource,
    options?: {
      onSuccessRedirect?: (payload: NetworkEventSuccessAction["payload"]) => void;
      meta?: Dictionary<any>;
    }
  ) => any;
  readonly onModifyParameters: (parameters: Dictionary<any>, mappings?: PropMapping[]) => any;
  readonly fetchActionPlanTemplates: (category: string) => void;
  readonly cleanFocusAreaResource: () => void;
  readonly onDeleteFocusArea: (
    focusAreaId: string,
    onSuccessRedirect?: (payload: NetworkEventSuccessAction["payload"]) => void) => NetworkRequestAction;
}

export type FocusAreaContainerProps = FocusAreasContainerDispatchProps
  & FocusAreasContainerReduxProps & RouteComponentProps;


export const FOCUSAREA_LIST_FILTER_MAPPINGS: PropMapping[] = [
  { localKey: "filter", storeKey: "FocusAreaListSearch" },
  { localKey: "filter.freeText", storeKey: "FocusAreaListSearch.freeText" },
  { localKey: "sort", storeKey: "focusAreasSort" },
];

enum SortBy {
  createdAt = "Date added",
  title = "Name"
};

const sortByOptions: DropDownButtonProps["options"] = Object.entries(SortBy).map(([key, label]) => ({key, label}));

export class FocusAreasContainer extends React.Component<FocusAreaContainerProps, FocusAreasContainerState> {
  private readonly onSearchChangeDebounced: (value: any) => void;

  constructor(props: FocusAreaContainerProps) {
    super(props);

    this.state = {
      showRecommendedActionPlans: [],
      showCreatedActions: false,
      actionsCreated: [],
      sortedBy: SortBy.createdAt,
      activeKebabMenuRow: {focusAreaId: ""},
      selectedFocusAreaId: "",
    } as FocusAreasContainerState;

    this.onSearchChangeDebounced = debounce(this.updateSearchFilter, 500);
  }

  componentDidMount() {
    // we might need to apply persisted parameters
    const {
      parameters,
      location: { search },
    } = this.props;

    const existing = parseQueryString(search);
    const mergedParameters =
      applyExistingParametersIfNeeded(
        parameters.toJS(),
        existing,
        ...FOCUSAREA_LIST_FILTER_MAPPINGS
      );
      const focusAreaSort = parameters.get("focusAreasSort");
      if (isNotEmptyObject(focusAreaSort)) {
        if (focusAreaSort.title === 1) {
          this.setState({sortedBy: SortBy.title});
        } else {
          this.setState({sortedBy: SortBy.createdAt});
        }
      }

    if (isNotNullNorUndefined(mergedParameters) && isNotEmptyObject(mergedParameters)) {
      replaceLocation(mergedParameters);
    } else {
      // fetch the action plan list only if we will stay on this page,
      // otherwise it will be fetched anyway in componentDidUpdate
      this.fetchIfNeeded();
    }
  }

  componentDidUpdate(prevProps: FocusAreaContainerProps) {
    if (this.props.loading && not(Store.Page.isInError(this.props.existingPage))
    ) {
      this.fetchIfNeeded();
    }
    const {sort: prevSort, filter: prevFilter} = parseQueryString(prevProps.location.search);
    const { sort, filter } = parseQueryString(this.props.location.search);
    
    if (
        not(areEquals(prevSort, getOr(sort, {}))) ||
        not(areEquals(prevFilter, getOr(filter, {})))
    ) {
      this.fetchIfNeeded();
    }

    // fetch focus area when clean focus area resource is called
    if (prevProps.focusAreas !== this.props.focusAreas) {
      this.fetchIfNeeded();
    }

    if (
      prevProps.isFetchingActionPlanTemplates === true &&
      this.props.isFetchingActionPlanTemplates === false && 
      prevProps.focusAreaTemplates !== this.props.focusAreaTemplates
    ) {
      const focusAreaId = this.state.selectedFocusAreaId;
      const focusArea = this.props.focusAreas.find(f => f._id === focusAreaId);
      if (isNotEmptyArray(this.props.focusAreaTemplates) && isNotEmptyObject(focusArea)) {
        this.setState((prev:FocusAreasContainerState)  => {
            if (isEmpty(prev.showRecommendedActionPlans.filter(f => f.focusAreaId === focusAreaId))) {
              prev.showRecommendedActionPlans.push({ focusAreaId, show: true });
              return {
                showRecommendedActionPlans: prev.showRecommendedActionPlans,
                showCreatedActions: prev.showCreatedActions
              };
            }
      
            return {
              showRecommendedActionPlans: prev.showRecommendedActionPlans.map(f => {
                if (f.focusAreaId === focusAreaId) {
                  return { focusAreaId, show: !f.show };
                }
                return f;
              }),
              showCreatedActions: prev.showCreatedActions,
            };
        }); 
      } else if (isNotEmptyObject(focusArea)) {
        this.onCreateActionPlan(focusAreaId);
      }
    }
  }

  componentWillUnmount() {
    this.props.cleanFocusAreaResource();
  }

  toggleKebabMenu = (focusAreaId: string) => {
    if (
      isStringAndNotEmpty(this.state.activeKebabMenuRow.focusAreaId) &&
      focusAreaId === this.state.activeKebabMenuRow.focusAreaId
    ) {
      this.setState({activeKebabMenuRow: { focusAreaId: "" }});
    } else {
      this.setState({activeKebabMenuRow: { focusAreaId }});
    }
  };

  closeKebabMenu = (focusAreaId: string) => {
    if (
      isStringAndNotEmpty(this.state.activeKebabMenuRow.focusAreaId)
    ) {
      this.setState({ activeKebabMenuRow: { focusAreaId: "" }});
    }
  };


  onSelectActionPlanTemplate = (action: ActionPlanTemplateResource, focusAreaId: string) => {
    const { category, description, resource } = action;
    const { location } = this.props;
    const { from } = parseQueryString(location.search);
    const source = {
      context: {
        category,
        focusAreaId,
        description,
        resource,
        action: action.action,
      },
      label: encodeURIComponent(category),
      type: Action.SourceType.CATEGORY,
      mode: CreationModes.scratch,
      templateId: action._id,
      from,
    };
    const queryParams = generateQueryString({...source, encode: true});
    goTo(`/actioncenter/actions/create?${queryParams}`, Breadcrumb.stack("ActionPlans"));
  };

  fetchIfNeeded() {
    const {
      onFetchIfNeeded,
      page,
      filter,
      sort,
    } = this.props;
    onFetchIfNeeded({ filter, sort, page });
  }

  updateSearchFilter = (value: any) => {
    const {
      onModifyList,
      onModifyParameters,
      page,
      sort,
    } = this.props;

    const filter = getOr(this.props.filter, {});
    if (isStringAndNotEmpty(value)) {
      filter.freeText = value;
    } else {
      delete filter.freeText;
    }

    const resetPage = { ...page, number: 1 };
    onModifyParameters({
      filter,
      page: resetPage,
      sort,
    }, FOCUSAREA_LIST_FILTER_MAPPINGS);

    onModifyList({
      filter,
      page: resetPage,
      sort,
    });
  };

  onDefineActionPlanClick = (focusAreaId: string) => () => {
    const focusArea = this.props.focusAreas.find(f => f._id === focusAreaId);
    if (isNotEmptyObject(focusArea) && isNotNullNorUndefined(focusArea.category)) {
      this.setState({selectedFocusAreaId: focusAreaId});
      this.props.fetchActionPlanTemplates(focusArea.category);
    } else if (isNotNullNorUndefined(focusArea)) {
      this.onCreateActionPlan(focusAreaId);
    }
  };

  onCreatedActionPlansClick = (actionPlans: FocusAreaResource["actionPlans"]) => () => {
    this.setState((prev: FocusAreasContainerState) => ({
      showCreatedActions: true,
      showRecommendedActionPlans: prev.showRecommendedActionPlans,
      actionsCreated: actionPlans,
    }));
  };

  extractShow = (id: string) => {
    const show = this.state.showRecommendedActionPlans.find(f => f.focusAreaId === id);
    return show ? show.show && not(this.props.isFetchingActionPlanTemplates) : false;
  };

  onCancelModal = () => {
    this.setState({
      showCreatedActions: false,
      actionsCreated: [],
    });
  };

  showCreatedAction = (actionPlan: Optional<FocusAreaResource.ActionPlan>, index: number) => {
    if (isNotNullNorUndefined(actionPlan)) {
      return (
        <CreatedActionPlan
          key={actionPlan.actionId}
          name={actionPlan.action}
          id={actionPlan.actionId}
          actionPlanIndex={index}
        />
      );
    }
    return null;
  };

  onDeleteFocusArea = (id: FocusAreaResource["_id"]) => () => {
    this.props.onDeleteFocusArea(id);
  };

  onPageChange = (selectedPage: number) => {
    const {
      onModifyList,
      page,
      sort,
      filter
    } = this.props;
    if (selectedPage !== page.number) {
      onModifyList({
        filter,
        page: {...page, number: selectedPage},
        sort,
      });
    }
  };

  extractNoResultProps = () => {
      let type: NoResultProps["type"];
      let description = "";
      const { filter } = this.props;
      if (isNotNullNorUndefined(filter)) {
        // in-case of search
        if (isNotNullNorUndefined(filter.freeText)) {
          type = "search";
          description = "Sorry, we couldn't find any focus areas matching your search for the moment.";
        }
        // in-case any filters are applied
        else if (Object.keys(filter).length > 0) {
          type = "filter";
          description = "Sorry, we couldn't find any actions matching your filters for the moment.";
        }
        // no filters, meaning no action is created
        else {
          type = "data";
          description = "Looks like you haven't created any Focus Areas yet.";
        }
      } else {
        type = "default";
        description = "";
      }
  
      return { type, description };
  };

  onCreateActionPlan = (focusAreaId: string) => {
    const focusArea = this.props.focusAreas.find(focusArea => focusArea._id === focusAreaId);
    if (isNotNullNorUndefined(focusArea)) {
      const { location } = this.props;
      const { from } = parseQueryString(location.search);
      const source = prepareCreateActionPlanContext(focusArea, from);
      const queryParams = generateQueryString({...source, encode: true});
      goTo(`/actioncenter/actions/create?${queryParams}`, Breadcrumb.stack("ActionPlans"));
    }
  };

  onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    this.onSearchChangeDebounced(event.target.value);
  };

  onSortSelect = (e: keyof typeof SortBy) => {
    const queryParameters = parseQueryString(this.props.location.search);
    if (
      Object.keys(queryParameters).includes("sort")
    ) {
      delete queryParameters.sort;
    }
    let newQueryParams = {};
    this.props.onModifyParameters({sort: {}}, FOCUSAREA_LIST_FILTER_MAPPINGS);
    if (e ===  "createdAt") {
      newQueryParams = fromJS(queryParameters).mergeDeep({sort: {[e]: -1}}).toJS();
    } else {
      newQueryParams = fromJS(queryParameters).mergeDeep({sort: {[e]: 1}}).toJS();
    }
    replaceLocation(newQueryParams);
    this.setState({
      sortedBy: SortBy[e]},
      () => this.props.onModifyParameters(newQueryParams, FOCUSAREA_LIST_FILTER_MAPPINGS)
    );
  };

  render() {
    const { showCreatedActions, actionsCreated, sortedBy, activeKebabMenuRow } = this.state;
    const { focusAreas, existingPage, total, filter, page, isDeletingFocusAreas } = this.props;
    return (
      <>
        <StyledParagraph>
          <Trans>{focusAreaFelicitation}</Trans>
        </StyledParagraph>
      {
        <>
          <SearchSortContainer>
            <SearchBarContainer>
              <SearchBar 
                onChange={this.onSearch} 
                placeholder="Search"
                data-cy="focusAreaSearch"
                defaultValue={mapOr(filter, f => f.freeText, "")}
              />
            </SearchBarContainer>
            <DropdownStyles>
              <DropDownButton 
                onClick={this.onSortSelect}
                options={sortByOptions}
                translate="yes"
              >
                <SpanText><Trans>Sort by</Trans> </SpanText>&nbsp;<Trans>{sortedBy}</Trans>
              </DropDownButton>
            </DropdownStyles>
          </SearchSortContainer>
          {
            Store.Page.isInError(existingPage) && (
              <FetchError { ...existingPage } resourceType={FocusAreaResource.TYPE} />
            )
          }
          <Spin size="large" spinning={Store.Page.isLoading(existingPage) || isDeletingFocusAreas} >
            {
              (Store.Page.isLoaded(existingPage)) && total === 0
                ? <NoResult {...this.extractNoResultProps()} />
                : (
                  <>
                    {
                      focusAreas.map(focusArea => (
                        <FocusArea
                          focusArea={focusArea}
                          key={focusArea._id}
                          onDefineActionPlanClick={this.onDefineActionPlanClick(focusArea._id)}
                          onSelectActionPlanTemplate={this.onSelectActionPlanTemplate}
                          showRecommendedActionPlans={this.extractShow(focusArea._id)}
                          suggestedActionPlans={this.props.focusAreaTemplates}
                          onCreatedActionPlansClick={this.onCreatedActionPlansClick(focusArea.actionPlans)}
                          onDeleteFocusArea={this.onDeleteFocusArea(focusArea._id)}
                          createActionPlan={() => this.onCreateActionPlan(focusArea._id)}
                          toggleKebabMenu={() => this.toggleKebabMenu(focusArea._id)}
                          closeKebabMenu={() => this.closeKebabMenu(focusArea._id)}
                          activeKebabMenuRow={activeKebabMenuRow}
                        />
                      ))
                    }
                    {
                      showCreatedActions && <AreYouSureModal
                        okLabel=""
                        visible={showCreatedActions}
                        title="Action Plans created"
                        description={actionsCreated.map(this.showCreatedAction)}
                        onOk={this.onCancelModal}
                        onCancel={this.onCancelModal}
                        hideCancelButton
                        hideOkButton
                      />
                    }
                    <StyledPagination current={page.number} onChange={this.onPageChange} total={total} />
                  </>
                )
            }
          </Spin>
          
        </>
      }
      </>
    );
  }
}

function mapStateToProps(state: State, { location }: RouteComponentProps): FocusAreasContainerReduxProps {
  const queryParameters = parseQueryString(location.search);
  let filter = getOr(queryParameters.filter, {});
  const sort = getOr(queryParameters.sort, {});

  const {
    pageSize,
    isUpdatingFocusAreaResource,
    isLoading,
    isDeletingFocusAreas,
    isFetchingActionPlanTemplates,
    focusAreaTemplates,
  } = getFocusAreaListStoreProps(state);

  const page = {
    size: pageSize,
    number: mapOr(queryParameters.page, parseNumber, 1),
  };
  const parameters = getParameters(state);
  const parametersJS = parameters.toJS();

  if (
    (isEmptyObject(filter) || isNullOrUndefined(filter.freeText)) &&
    (isNotNullNorUndefined(parametersJS) && isNotEmptyObject(parameters.get("FocusAreaListSearch")))
  ) {
    filter = parameters.get("FocusAreaListSearch");
  }

  const existingPage = getExistingPage(
    state,
    FocusAreaResource.TYPE,
    FocusAreaResource.generateKey(filter, sort),
    page
  );

  const { data, total } = extractDataAndTotalFromPage(existingPage);

  return {
    focusAreas: data,
    loading: isLoading,
    total,
    page,
    sort,
    filter,
    isUpdatingFocusAreaResource,
    isDeletingFocusAreas,
    parameters,
    existingPage,
    isFetchingActionPlanTemplates,
    focusAreaTemplates,
  };
};

const mapDispatchToProps = {
  onFetchIfNeeded: fetchFocusAreasIfNeeded,
  onModifyList: focusAreaListActionCreators.modifyList,
  onFocusAreaUpdate: focusAreaListActionCreators.updateFocusAreaRequest,
  onModifyParameters: parametersActionCreators.modifyParameters,  
  onDeleteFocusArea: focusAreaListActionCreators.deleteFocusArea,
  fetchActionPlanTemplates: focusAreaListActionCreators.fetchFocusAreaTemplates,
  cleanFocusAreaResource: focusAreaListActionCreators.cleanFocusArea,
};

const StyledPagination = styled(Pagination)`
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
`;
const StyledParagraph = styled(Paragraph)`
  // height: 48px;
  color: ${Palette.veryDarkBlueGrey};
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  border: 1px solid ${Palette.veryLightBlue};
  border-radius: 4px;
  background-color: ${Palette.athensGrey};
  padding-left: 17px;
  padding-top: 14px;
  padding-bottom: 14px;
`;


const SearchSortContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;

const SearchBarContainer = styled.div`
  height: 36px;
  width: 265px;
`;

const DropdownStyles = styled.div`
  margin-left: 10px;
  // width: 170px;
  // button {
  //   width: 100%;
  // }
`;

const SpanText = styled.span`
  color: ${Palette.bluishGrey};
`;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FocusAreasContainer)
);