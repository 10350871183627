import React from "react";
import styled from "styled-components";
import { Trans } from "react-i18next";
import Palette from "@src/config/theme/palette";
import { LogoutSvg } from "@components/core/svg/LogoutSvg";
import { SidebarMode } from "@components/Sidebar";
import { SidebarIcon } from "@components/Sidebar/components/SidebarIcon";
import { isNullOrEmpty } from "hyphen-lib/dist/lang/Strings";
import { Tooltip } from "antd";
import { WebIconSvg } from "@src/components/core/svg/WebIconSvg";

type SidebarType = "logout" | "language";

interface SidebarFooterProps {
  onAction: (param?: any) => void;
  email: string;
  mode: SidebarMode;
  type: SidebarType;
}

function getTooltip(type: SidebarType, mode: SidebarMode, onClick: () => void) {
  if (type === "logout") {
    return (
      <Tooltip
        title={(<Trans>{mode === SidebarMode.COLLAPSED ? "Logout" : ""}</Trans>)}
        placement="right"
        {...(mode !== SidebarMode.COLLAPSED ? {visible : false} : {})}
      >
        <SidebarIcon onClick={onClick}>
          <LogoutSvg />
        </SidebarIcon>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip
        title={<Trans>{mode === SidebarMode.COLLAPSED ? "Change Language" : null}</Trans>}
        placement="right"
        {...(mode !== SidebarMode.COLLAPSED ? {visible : false} : {})}
      >
        <div data-cy="change-language-icon">
        <SidebarIcon onClick={onClick}>
          <WebIconSvg />
        </SidebarIcon>
        </div>
      </Tooltip>
    );
  }
}

function getLabelContainer(type: SidebarType, mode: SidebarMode, onClick: () => void,
  text: string) {
  if (type === "logout" && mode === SidebarMode.EXPANDED) {
    return (
      <LabelContainer>
        <LabelMessage><Trans>Logged in as:</Trans></LabelMessage>
        <EmailMessage>
          <span
            dangerouslySetInnerHTML={{
              __html: makeEmailWrap(text),
            }}
          />
        </EmailMessage>
      </LabelContainer>
    );
  } else if (type === "language" && mode === SidebarMode.EXPANDED) {
    return (
      <LabelContainer className="pointer" onClick={onClick}>
        <LanguageMessage><Trans>Language</Trans></LanguageMessage>
      </LabelContainer>
    );
  }
}

export const SidebarFooter = ({
  onAction,
  email,
  mode,
  type,
}: SidebarFooterProps) => (
  <SidebarFooterContainer mode={mode}>
    <SidebarFooterInnerContainer>
      {getTooltip(type, mode, onAction)}
      {getLabelContainer(type, mode, onAction, email)}
    </SidebarFooterInnerContainer>
  </SidebarFooterContainer>
);

function makeEmailWrap(email: string): string {
  const [head, tail] = email.split("@");
  if (isNullOrEmpty(tail)) {
    return head;
  }
  return `${head}&#8203;@${tail}`;
}

const SidebarFooterContainer = styled.div<{ mode: SidebarMode }>`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.mode === SidebarMode.EXPANDED ? "flex-start" : "center"};
  padding-left: ${(props) => (props.mode === SidebarMode.EXPANDED ? 33 : 0)}px;
  background-color: ${Palette.darkDarkDarkBlueGrey};
`;

const SidebarFooterInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LabelContainer = styled.div`
  margin-left: 9px;
  display: flex;
  flex-direction: column;
`;

const LabelMessage = styled.div`
  font-size: 12px;
  color: ${Palette.lightGreyBlue};
`;

const EmailMessage = styled.div`
  font-size: 14px;
  color: #ffffff;
`;

const LanguageMessage = EmailMessage;

