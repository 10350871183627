import React from "react";
import { ChangeEvent } from "react";
import { translate } from "@src/utils/i18next";
import { SearchSvg } from "@components/core/svg/SearchSvg";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { WithTranslation, withTranslation } from "react-i18next";

interface Props extends WithTranslation{
  onChange: (event: ChangeEvent<HTMLInputElement>) => any;
  placeholder?: string;
  defaultValue?: string;
  datacy?: string;
}

interface State {
  value: string;
}

class SearchBarComponent extends React.Component<Props, State> {
  state = {
    value: this.props.defaultValue ? this.props.defaultValue : "",
  };

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (isNotNullNorUndefined(this.props.defaultValue) && this.props.defaultValue !== prevProps.defaultValue) {
      this.setState({ value: this.props.defaultValue });
    }
  }

  onChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(event);
    this.setState({ value: event.target.value });
  };

  render() {
    const { placeholder, datacy, t} = this.props;
    return (
      <Container className="block--print-hide" data-cy="listView_search" >
        <ImageContainer>
          <SearchSvg/>
        </ImageContainer>
        <Input
          data-cy={isNotNullNorUndefined(datacy) ? datacy : null}
          placeholder={translate(t, placeholder)}
          onChange={this.onChange}
          value={this.state.value}
        />
      </Container>
    );
  }
}

export const SearchBar = withTranslation()(SearchBarComponent);

const Container = styled.div`
  position: relative;
  height: 36px;
  display: inline-block;
  width: 100%;
`;

const ImageContainer = styled.span`
  position: absolute;
  left: 9px;
  top: 9px;
  z-index: 2;
`;

const Input = styled.input`
  position: relative;
  display: inline-block;
  height: 36px;
  padding: 2px 10px 2px 36px;
  border: 1px solid ${Palette.lightGreyBlue};
  width: 100%;
  border-radius: 3px;
  &::placeholder {
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #7d8b94;
  }
`;
