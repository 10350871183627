import React from "react";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps } from "react-router";

import { State } from "@store/types";

import Participation from "@screens/Insights/Survey/components/Participation";
import { getDimensions } from "@screens/Insights/store/selectors";
import { SelectValue } from "antd/lib/select";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { replaceLocation } from "@src/utils/locations";
import { not } from "hyphen-lib/dist/lang/Booleans";
import {
  areEquals,
  isEmptyObject,
  isNotEmptyObject,
  isNotNullNorUndefined,
  isNullOrUndefined,
  keys
} from "hyphen-lib/dist/lang/Objects";
import { Map as ImmutableMap } from "immutable";
import { getParameters } from "@screens/Insights/parameters/store/selectors";
import { applyExistingParametersIfNeeded, PropMapping } from "@src/utils/parameters";
import { parametersActionCreators } from "@screens/Insights/parameters/store/actions";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Dimensions } from "@hyphen-lib/domain/common/Dimensions";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { getResourceById } from "@store/network/selectors";
import { ParticipationReportResource } from "hyphen-lib/dist/domain/resource/survey/report/ParticipationReportResource";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { Loading } from "@screens/Insights/Survey/components/Loading";
import { fetchSurveyParticipationIfNeeded } from "@store/network/resource/ParticipationReportResources";
import { FetchError } from "@src/screens/Insights/errors/FetchError";

interface MatchParams {
  id: string;
  selectedDimension: string;
}

export type OwnProps = RouteComponentProps<MatchParams>;

export interface ParticipationContainerStateProps {
  readonly parameters: ImmutableMap<string, any>;
  readonly participation: Store.Element<ParticipationReportResource>;
  readonly dimensions: Optional<Dimensions>;
}

export interface ParticipationContainerActionProps {
  readonly onModifyParameters: (parameters: Dictionary<any>, mappings?: PropMapping[]) => void;
  readonly onFetchParticipation: (surveyId: string, queryString: Dictionary<any>) => void;
}

type Props =
  OwnProps &
  ParticipationContainerStateProps &
  ParticipationContainerActionProps;

class ParticipationContainer extends React.Component<Props> {

  // noinspection JSMethodCanBeStatic
  extractParametersFromQuery(props: Props) {
    return this.extractRelevantParametersForQuery(
      parseQueryString(props.location.search)
    );
  }

  // noinspection JSMethodCanBeStatic
  extractRelevantParametersForQuery(parameters: any) {
    const relevantParameters = { ...parameters };
    delete relevantParameters.segmentBy;
    return relevantParameters;
  }

  componentDidMount(): void {
    // we might need to apply persisted parameters
    const {
      parameters,
      location: { search },
    } = this.props;

    const existing = parseQueryString(search);
    const mergedParameters =
      applyExistingParametersIfNeeded(
        parameters.toJS(),
        existing,
        "filter.dimensions",
        { localKey: "filter.date", storeKey: "surveyReport.date" },
        "segmentBy"
      );

    if (isNotNullNorUndefined(mergedParameters) && isNotEmptyObject(mergedParameters)) {
      replaceLocation(mergedParameters);

      if (isEmptyObject(this.extractRelevantParametersForQuery(mergedParameters))) {
        // do the fetch now, otherwise we will not do it later, as componentDidUpdate will not see
        // any relevant changes, and not do any fetch
        this.fetchSurveyParticipation();
      }
    } else {
      // fetch the overview only if we will stay on this page,
      // otherwise it will be fetched anyway in componentDidUpdate
      this.fetchSurveyParticipation();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const oldParams = this.extractParametersFromQuery(prevProps);
    const newParams = this.extractParametersFromQuery(this.props);
    if (
      (this.props.match.params.id !== prevProps.match.params.id ||
      not(areEquals(oldParams, newParams))) &&
      not(Store.Element.isInError(this.props.participation))
    ) {
      this.fetchSurveyParticipation();
    }
  }

  fetchSurveyParticipation = () => {
    const surveyId = this.props.match.params.id;
    const queryParams = this.extractParametersFromQuery(this.props);
    this.props.onFetchParticipation(surveyId, queryParams);
  };

  updateSegmentByInPath = (selectedDimension: SelectValue): void => {
    const { location, onModifyParameters } = this.props;
    const parameters = parseQueryString(location.search);
    parameters.segmentBy = selectedDimension;

    onModifyParameters({
      segmentBy: selectedDimension,
    });

    replaceLocation(parameters); // apply the new query string
  };

  render() {
    const { location, participation } = this.props;

    const queryParams = this.extractParametersFromQuery(this.props);
    const isFiltersAvailable = not(keys(queryParams).includes("filter"));
    let { segmentBy: selectedDimension } = parseQueryString(location.search);

    if (Store.Element.isInError(participation)) {
      return <FetchError {...participation} resourceType={ParticipationReportResource.TYPE}/>;
    }

    if (Store.Element.isNotLoaded(participation)) {
      return <Loading/>;
    }

    if (isNullOrUndefined(selectedDimension)) {
      if (isNotEmptyObject(this.props.dimensions)) {
        selectedDimension = Object.keys(this.props.dimensions)[0];
      } else {
        selectedDimension =  Object.keys(participation.value.dimensions)[0];
      }
    }

    return (
      <Participation
        participation={participation.value}
        dimensions={this.props.dimensions}
        updateSegmentByInPath={this.updateSegmentByInPath}
        selectedDimension={selectedDimension}
        isFiltersAvailable={isFiltersAvailable}
        enabledFilters={[
          "date",
          "dimension",
        ]}
        enabledCustomFilters={["addDimension"]}
        storeMappings={[
          { localKey: "filter.date", storeKey: "surveyReport.date" },
        ]}
      />
    );
  }
}

export const mapStateToProps: MapStateToProps<ParticipationContainerStateProps, OwnProps, State> = (
  state: State,
  ownProps: OwnProps
): ParticipationContainerStateProps => ({
  participation: getResourceById(
    state,
    ParticipationReportResource.TYPE,
    ParticipationReportResource.generateId(
      ownProps.match.params.id,
      parseQueryString(ownProps.location.search).filter
    )
  ),
  dimensions: getDimensions(state),
  parameters: getParameters(state),
});

const mapDispatchToProps: ParticipationContainerActionProps = {
  onModifyParameters: parametersActionCreators.modifyParameters,
  onFetchParticipation: fetchSurveyParticipationIfNeeded,
};

export default connect(mapStateToProps, mapDispatchToProps)(ParticipationContainer);
export { ParticipationContainer as PureParticipationContainer };
