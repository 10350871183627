import Palette from "@src/config/theme/palette";
import { Col, Icon, Row } from "antd";
import { ActionPlanTemplateResource } from "hyphen-lib/dist/domain/resource/action/ActionPlanTemplateResource";
import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";
import ActionPlanCard from "./ActionPlanCard";

interface RecommendationProps {
  readonly onCloseRecommendation: () => void;
  readonly showMoreCount: number;
  readonly maxShowMoreCount: number;
  readonly focusAreaId: string;
  readonly lastRowCardCount: number;
  readonly hasMoreToShow: boolean;
  readonly onShowMore: (increment: number) => () => void;
  readonly computeMoreToShow: () => number;
  readonly onCreateActionPlan: () => void;
  readonly suggestedActionPlans: ActionPlanTemplateResource[];
  readonly onSelectActionPlanTemplate: (action: ActionPlanTemplateResource, focusAreaId: string) => void;
}

class Recommendations extends React.Component<RecommendationProps, any> {

  render() {
    const {
      onCloseRecommendation,
      showMoreCount,
      maxShowMoreCount,
      lastRowCardCount,
      hasMoreToShow,
      onShowMore,
      computeMoreToShow,
      suggestedActionPlans,
      onSelectActionPlanTemplate,
      onCreateActionPlan,
    } = this.props;
    const showLabel = hasMoreToShow ? `Show ${computeMoreToShow()} more` : "Show less";
    return (
      <RecommendationRow> 
          <RecommendationContainer>
              <TitleRow>
                <Col span={23}><Trans>We identified best practice Action Plans for you</Trans></Col>
                <CloseCol span={1} onClick={onCloseRecommendation}><Icon type="close" /></CloseCol>
              </TitleRow>

              {Array.from(Array(showMoreCount + 1).keys()).map((i: number) => {
                if (i <= maxShowMoreCount) {
                  return (
                    <CardRow data-cy="recommendationRow" type="flex" justify="space-around" $isFirst={i===0} key={i}>
                        <ActionPlanCard
                          data-cy="actionPlanRecommendationCard"
                          focusAreaId={this.props.focusAreaId}
                          suggestedActionPlan={suggestedActionPlans[i * showMoreCount]}
                          onSelectActionPlanTemplate={onSelectActionPlanTemplate}
                        />
                        <ActionPlanCard 
                          data-cy="actionPlanRecommendationCard"
                          focusAreaId={this.props.focusAreaId}
                          suggestedActionPlan={suggestedActionPlans[i * showMoreCount + 1]}
                          onSelectActionPlanTemplate={onSelectActionPlanTemplate}
                        />
                        <ActionPlanCard
                          data-cy="actionPlanRecommendationCard"
                          focusAreaId={this.props.focusAreaId}
                          suggestedActionPlan={suggestedActionPlans[i * showMoreCount + 2]}
                          onSelectActionPlanTemplate={onSelectActionPlanTemplate}
                        />
                    </CardRow>
                  );
                }
                return (
                  <CardRow type="flex" $isFirst={i===0} key={i} $isLast>
                    {Array.from(Array(lastRowCardCount).keys()).map((i: number) => (
                        <ActionPlanCard 
                          data-cy="actionPlanRecommendationCard"
                          focusAreaId={this.props.focusAreaId}
                          suggestedActionPlan={suggestedActionPlans[showMoreCount * 3 + i]}
                          onSelectActionPlanTemplate={onSelectActionPlanTemplate}
                          key={i}
                        />
                    ))}
                  </CardRow>
                );
                })}

              <ShowMoreRow>
                <Show3MoreCol span={12} onClick={onShowMore(hasMoreToShow ? 1 : -1)}>
                  {
                    <>
                      <span>{hasMoreToShow ? <Trans i18nKey="showMore" defaults={showLabel} 
                      values={{count: computeMoreToShow()}} /> : <Trans>{showLabel}</Trans>}</span>
                      <StyledIcon type={hasMoreToShow ? "down" : "up"}/>
                    </>
                  }
                </Show3MoreCol>
                <CreateOwnCol
                  span={12}
                  onClick={onCreateActionPlan}>
                    <Trans>I'd rather create an action plan from scratch</Trans>
                </CreateOwnCol>
              </ShowMoreRow>
          </RecommendationContainer>
        </RecommendationRow>  
    );
  }
}

const RecommendationRow = styled(Row)`
  background-color: #fff;
  padding: 0 24px 24px 24px;
`;

const RecommendationContainer = styled(Row)`
  background-color: ${Palette.darkDarkDarkBlueGrey};
`;

const TitleRow = styled(Row)`
  color: #fff;
  line-height: 20px;
  padding: 16px;
`;

const CardRow = styled(Row)<{$isFirst?: boolean; $isLast?: boolean}>`
  padding-left: 1%;
  padding-right: 1%;
  ${props => props.$isFirst ? null : "margin-top: 16px"};
`;

const ShowMoreRow = styled(Row)`
  color: #fff;
  line-height: 20px;
  padding: 16px;
`;

const CloseCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  font-size: 24px;
  cursor: pointer;
`;

const CreateOwnCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;

const Show3MoreCol = styled(Col)`
  cursor: pointer;
`;


const StyledIcon = styled(Icon)`
  margin-left: 5px;
  font-size: 12px;
`;
export default Recommendations;