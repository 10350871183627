import React from "react";
import Table from "@src/components/core/Table";
import { DynamicRowProps, withDynamicRows } from "@components/core/DynamicRowsTable/withDynamicRows";

import Button, { ButtonProps } from "@src/components/core/Button";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { TableProps } from "antd/lib/table";
import ButtonGroup, { ButtonGroupProps } from "antd/lib/button/button-group";
import { DropDownButton, DropDownButtonProps } from "@components/core/DropDownButton";
import { Tooltip } from "antd";
import { isNotNullNorUndefined, getOr } from "hyphen-lib/dist/lang/Objects";
import {
  ActionTemplateSuggestionResource
} from "hyphen-lib/dist/domain/resource/action/ActionTemplateSuggestionResource";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { formatDate } from "hyphen-lib/dist/lang/Dates";
import { Trans } from "react-i18next";

export interface ManageTemplatesReduxStateProps {
  actionTemplates: ActionTemplateSuggestionResource[];
  isNetworkRequesting: boolean;
}

export interface ManageTemplatesProps extends
  ManageTemplatesReduxActionProps,
  ManageTemplatesReduxStateProps {}

export interface ManageTemplatesReduxActionProps {
  excludeActionTemplate: (templateId: string) => void;
  includeActionTemplate: (templateId: string) => void;
  removeActionTemplate: (templateId: string) => void;
  onViewClick: (payload: any) => void;
  onEditClick: (payload: any) => void;
}

export interface TemplateTableProps extends TableProps<ActionTemplateSuggestionResource> {}

function ManageTemplatesComponent(props: ManageTemplatesProps & TemplateTableProps) {
  const { isNetworkRequesting, actionTemplates } = props;

  function isSelected(row: ActionTemplateSuggestionResource) {
    return ((getOr(!row.excluded, false))  || row.type === ActionTemplateSuggestionResource.TemplateType.CUSTOM);
  }

  return (
    <Table
      loading={isNetworkRequesting}
      columns={[
        {
          title: (<Trans>Action Plan</Trans>),
          dataIndex: "action",
          key: "action",
          sorter: (a, b) => {
            a = getOr(a.action, "");
            b = getOr(b.action, "");
            return a.localeCompare(b);
          },
          sortDirections: ["descend", "ascend"],
          render: (record, row) => {
            return <StyledParagraph selected={isSelected(row)} >{row.action}</StyledParagraph>;
          },
        },
        {
          title: (<Trans>Type</Trans>),
          dataIndex: "type",
          key: "type",
          sorter: (a, b) => {
            a = getOr(a.type, "");
            b = getOr(b.type, "");
            return a.localeCompare(b);
          },
          sortDirections: ["descend", "ascend"],
          render: (record, row) => {
            return <StyledParagraph selected={isSelected(row)} >{row.type}</StyledParagraph>;
          },
        },
        {
          title: (<Trans>Category</Trans>),
          dataIndex: "category",
          key: "category",
          sorter: (a, b) => {
            a = getOr(a.category, "");
            b = getOr(b.category, "");
            return a.localeCompare(b);
          },
          sortDirections: ["descend", "ascend"],
          render: (record, row) => {
            return <StyledParagraph selected={isSelected(row)} >{row.category}</StyledParagraph>;
          },
        },
        {
          title: (<Trans>Question</Trans>),
          dataIndex: "question",
          key: "question",
          sorter: (a, b) => {
            a = getOr(a.question, "");
            b = getOr(b.question, "");
            return a.localeCompare(b);
          },
          sortDirections: ["descend", "ascend"],
          render: (record, row) => {
            return (
              <StyledParagraph selected={isSelected(row)}>
                {isNotNullNorUndefined(record)
                  ? <Trans>View Question</Trans>
                  : <Trans>N/A</Trans>
                }
              </StyledParagraph>
            );
          },
        },
        {
          title: (<Trans>Industry</Trans>),
          dataIndex: "industry",
          key: "industry",
          sorter: (a, b) => {
            a = getOr(a.industry, "");
            b = getOr(b.industry, "");
            return a.localeCompare(b);
          },
          sortDirections: ["descend", "ascend"],
          render: (record, row) => {
            return (
              <StyledParagraph selected={isSelected(row)}>
                {isNotNullNorUndefined(record)
                  ? row.industry
                  : <Trans>All</Trans>
                }
              </StyledParagraph>
            );
          },
        },
        {
          title: (<Trans>Date Created</Trans>),
          dataIndex: "createdAt",
          key: "createdAt",
          sorter: (a, b) => {
            a = getOr(a.createdAt, "");
            b = getOr(b.createdAt, "");
            return a.localeCompare(b);
          },
          sortDirections: ["descend", "ascend"],
          render: (record, row) => {
            return (
              <StyledParagraph selected={isSelected(row)}>
                {formatDate(
                  row.createdAt, {
                    hour: "numeric",
                    minute: "numeric",
                  }
                )}
              </StyledParagraph>
            );
          },
        },
        {
          title: (<Trans>Created By</Trans>),
          dataIndex: "createdBy",
          key: "createdBy",
          sorter: (a, b) => {
            a = getOr(a.createdBy, "");
            b = getOr(b.createdBy, "");
            return a.localeCompare(b);
          },
          sortDirections: ["descend", "ascend"],
          render: (record, row) => {
            return <StyledParagraph selected={isSelected(row)}>{row.createdBy}</StyledParagraph>;
          },
        },
      ]}
      dataSource={actionTemplates}
      rowKey="_id"
      {...props}
    />
  );
}
const StyledHoverCell = styled.td`
  background-color: ${Palette.lightLightBlue};
`;

const hyphenAddActions = (viewAction: any, includeAction: any) => ({
  actions: [
    { key: "View", label: "View", onClick: viewAction },
    { key: "Include", label: "Include", onClick: includeAction }],
  more: [],
});

const hyphenRemoveActions = (viewAction: any, excludeAction: any) => ({
  actions: [
    { key: "View", label: "View", onClick: viewAction },
    { key: "Exclude", label: "Exclude", onClick: excludeAction },
  ],
  more: [],
});

const customActions = (viewAction: any) => ({
  actions: [{ key: "View", label: "View", onClick: viewAction }],
  more: [{ key: "Edit", label: "Edit" }, { key: "Remove", label: "Remove" }],
});

function getActions(
  sourceType: string,
  isSelected: boolean,
  actions: Dictionary<any>
) {
  const { viewAction, includeAction, excludeAction } = actions;
  if (sourceType === "Custom") {
    return customActions(viewAction);
  } else {
    if (isSelected === true) {
      return hyphenRemoveActions(viewAction, excludeAction);
    } else {
      return hyphenAddActions(viewAction, includeAction);
    }
  }
}

export function ManageTemplates(props: ManageTemplatesProps & TemplateTableProps) {
  const {
    excludeActionTemplate,
    includeActionTemplate,
    removeActionTemplate,
    onEditClick,
    onViewClick,
  } = props;

  function onViewButtonAction(row: ActionTemplateSuggestionResource) {
    onViewClick(row);
  }

  function onMoreButtonAction(key: string, row: any) {
    if (key === "Edit") {
      return onEditClick(row);
    }

    if (key === "Remove") {
      return removeActionTemplate(row._id);
    }
    return;
  }

  function HoverRow({ row }: DynamicRowProps<TemplateTableProps & ActionTemplateSuggestionResource>) {
    const selected = (getOr(!row.excluded, true) || row.type === ActionTemplateSuggestionResource.TemplateType.CUSTOM);
    const rowActions = getActions(
      row.source as string,
      selected,
      {
        viewAction: () => {
          onViewButtonAction(row);
        },
        includeAction: () => {
          includeActionTemplate(row._id);
        },
        excludeAction: () => {
          excludeActionTemplate(row._id);
        },
      }
    );

    /* tslint:disable:jsx-no-lambda no-console*/
    return (
      <>
        <StyledHoverCell>
          <StyledParagraph selected={selected}>
            {row.action}
          </StyledParagraph>
        </StyledHoverCell>
        <StyledHoverCell>
          <StyledParagraph selected={selected}>
            {row.type}
          </StyledParagraph>
        </StyledHoverCell>
        <StyledHoverCell>
          <StyledParagraph selected={selected}>
            {row.category}
          </StyledParagraph>
        </StyledHoverCell>
        <StyledHoverCell>
          <StyledParagraph selected={selected}>
            {isNotNullNorUndefined(row.question)
              ? <Tooltip title={row.question}>View Question</Tooltip>
              : "N/A"
            }
          </StyledParagraph>
        </StyledHoverCell>
        <StyledHoverCell>
          <StyledParagraph selected={selected}>
            {isNotNullNorUndefined(row.industry)
              ? row.industry
              : <Trans>All</Trans>
            }
          </StyledParagraph>
        </StyledHoverCell>
        <StyledHoverCell colSpan={4}>
          <StyledButtonGroup>
            {/* tslint:disable:jsx-no-lambda */
              rowActions.actions.map((action: any, index) => (
                <StyledButton
                  index={index}
                  key={action.key}
                  color="gradation"
                  onClick={action.onClick}
                >
                  <Trans>{action.label}</Trans>
                </StyledButton>
              ))}
            {rowActions.more.length > 0 && (
              <StyledDropDownButton
                options={rowActions.more}
                onClick={(key) => onMoreButtonAction(key, row)}
                translate="yes"
              >
                <Trans>More</Trans>
              </StyledDropDownButton>
            )}
          </StyledButtonGroup>
        </StyledHoverCell>
      </>
    );
  }

  const TailoredTable = withDynamicRows(ManageTemplatesComponent, HoverRow);
  return <TailoredTable rowKey="_id" {...props} />;
}

const StyledButton = styled(Button)<ButtonProps & { index: number }>`
  height: 36px !important;
  :focus,
  :hover {
    border-color: ${Palette.bluePurple} !important;
    outline: none !important;
  }
  border-top-left-radius: ${props => (props.index === 0 ? 3 : 0)}px !important;
  border-bottom-left-radius: ${props => (props.index === 0 ? 3 : 0)}px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
`;

const StyledButtonGroup = styled(ButtonGroup)<ButtonGroupProps & { children: React.ReactNode }>`
  display: flex !important;
  justify-content: flex-end;
`;
const StyledDropDownButton = styled(DropDownButton)<DropDownButtonProps>`
  margin-left: -1px;
  width: 83px !important;
  height: 36px !important;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  color: ${Palette.veryDarkBlueGrey} !important;
  border-color: ${Palette.lightGreyBlue} !important;
  :focus,
  :hover {
    border-color: ${Palette.bluePurple} !important;
    outline: none !important;
  }
`;

const StyledParagraph = styled.p<{ selected: boolean }>`
  color: ${props => props.selected ? Palette.darkDarkDarkBlueGrey : Palette.bluishGrey } !important;
  font-style: ${props => props.selected ? "normal" : "italic"} !important;
`;
