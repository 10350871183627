import React from "react";
import styled from "styled-components";
import { ColumnProps, TableProps } from "antd/lib/table";

import Table from "@components/core/Table";
import { TopicCell } from "@src/screens/Insights/components/Reports/ResultsTable/cells/TopicCell";
import { CommentCountCell } from "@src/screens/Insights/components/Reports/ResultsTable/cells/CommentCountCell";
import { SortParameter } from "@src/utils/networks";
import { Dictionary } from "@hyphen-lib/domain/structure/Dictionary";
import { formatSort } from "@src/utils/helper";
import { isEmptyObject, isNotEmptyObject } from "hyphen-lib/dist/lang/Objects";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { DynamicRowProps, withDynamicRows } from "@src/components/core/DynamicRowsTable/withDynamicRows";
import Palette from "@src/config/theme/palette";
import { Trans } from "react-i18next";
import { Icon, Tooltip } from "antd";
import { TopicOverviewResource } from "hyphen-lib/dist/domain/resource/survey/report/TopicOverviewResource";
import TopicsSentimentChart from "../TopicsSentimentChart";
import { Breadcrumb, goTo } from "@src/utils/locations";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { HeaderWithIcon } from "@src/screens/Insights/components/Reports/ResultsTable/tables/Comments";
import { StyledInfoIcon } from "../../../OverviewReport/components/Overview";
export interface TopicTableProps extends TableProps<TopicOverviewResource> {
  readonly topics: TopicOverviewResource[];
  readonly total: number;
  readonly sort: SortParameter;
  readonly page: PageFilter;
  readonly loading: boolean;
  readonly tableName: string;
}


function getSortOrder(field: string, sort?: Optional<SortParameter>) {
  if (isEmptyObject(sort)) {
    return undefined;
  }
  if (sort![field] === 1) {
    return "ascend";
  } else if (sort![field] === -1) {
    return "descend";
  }
  return undefined;
}


const getColumns = (
  sort: Dictionary<any>,
  ): ColumnProps<TopicOverviewResource>[] => {
    let columns: ColumnProps<TopicOverviewResource>[] = [];
  const sortParam = formatSort(sort);
  columns = [...columns, 
    {
    title: "Topic",
    dataIndex: "name",
    key: "name",
    sorter: true,
    width: 350,
    sortOrder: getSortOrder("name", sortParam),
    sortDirections: ["ascend", "descend"],
    render: (__, record, index) => {
        return <TopicCell row = {record} index={index}/>;
      },
    },
    {    
      title: "Comments",
      dataIndex: "totalComments",
      key: "totalComments",
      sorter: true,
      width: 150,
      sortOrder: getSortOrder("totalComments", sortParam),
      sortDirections: ["ascend", "descend"],
      render: (__, record, index) => {
        return <CommentCountCell row={record} index={index} />;
      },
    },
    {    
      title: (
        <HeaderWithIcon>
          <Trans>Sentiment</Trans>
          <Tooltip title={<Trans>Based on percentage of positive comments
           minus percentage of negative comments</Trans>}>
            <StyledInfoIcon type="info-circle"/>
          </Tooltip>
        </HeaderWithIcon>
      ),
      dataIndex: "sentiment",
      key: "sentiment",
      // sorter: true,
      width: 400,
      sortOrder: getSortOrder("sentiment", sortParam),
      sortDirections: ["ascend", "descend"],
      render: (__, record, index) => {
        return <TopicsSentimentChart row={record}  index={index}/>;
      },
    },
    {
      title: "",
      dataIndex: "detail",
      key: "detail",
    }
    ];
 
 
    return columns;
};

function TopicsTableComponent({ 
  topics, 
  total, 
  sort, 
  page,
  loading,
  onChange, ...rest }: TopicTableProps) {
  return (
    <StyledTable
      columns={getColumns(sort)}
      dataSource={topics}
      rowKey={"_id"}
      pagination={{
        total,
        pageSize: page.size,
        current: page.number,
        showTotal: (total: number, range: number[]) =>
          <Trans i18nKey="resultsCount"
          values={{start: range[0], end: range[1], total}} 
          defaults={`Showing ${range[0]} to ${range[1]} of ${total} results`}/>,
      }}
      {...rest}
      onChange = {onChange}
      loading={loading}
    />
  );
}
interface MatchParams {
  id: string;
}

export default function TopicsTable(props: TopicTableProps) {

  function HoverRow({ row, ...rowProps }: DynamicRowProps<TopicOverviewResource> 
    & RouteComponentProps<MatchParams> ) {
    function onShowDetail() {
      const { match } = rowProps;
      const { id } = match.params;
  
      goTo(`/surveys/view/${id}/reports/topics/${row._id}/comments`,
        Breadcrumb.stack(props.tableName)
      );
    }
    return (
      <>   
        <StyledHoverCell>
          <TopicCell row={row}/>
        </StyledHoverCell>
        <StyledHoverCell>
          <CommentCountCell row={row}/>
        </StyledHoverCell>
        {isNotEmptyObject(row.sentiment) && <StyledHoverCell>
          <TopicsSentimentChart row = {row} />
        </StyledHoverCell> } 
        <StyledHoverCell>
          <StyledLink onClick={onShowDetail}>
            <SeeDetails data-cy="see_details_topic_drilldown">
              <Trans>See details</Trans> <RightIcon type="right" /></SeeDetails>
          </StyledLink>
        </StyledHoverCell>
      </>
    );
  }

  const TailoredTable = withDynamicRows(TopicsTableComponent, withRouter(HoverRow));

  return <TailoredTable {...props} />;
}

const StyledLink = styled.span`
  cursor: pointer;
`;

const RightIcon = styled(Icon)`
  font-size: 12px;
`;


const SeeDetails = styled.div`
  color: ${Palette.darkModerateBlue};
  font-size: 14px;
  text-align: right;
  cursor: pointer;
  line-height: 30px;
`;


const StyledHoverCell = styled.td`
  background-color: ${Palette.lightLightBlue};
  position: relative;
`;

const StyledTable = styled(Table)`
  .ant-table-body > table tr > td {
    &:first-child {
      padding-left: 32px !important;
    }
  }
  
  .ant-table-body > table > thead > tr > th {
    padding: 16px 10px;
  }
`;

