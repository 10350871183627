import React from "react";
import CreationLayout from "@components/layouts/CreationLayout";
import ContainerCard from "@src/components/core/ContainerCard";
import styled from "styled-components";
import Button from "@src/components/core/Button";
import OKRDetailCard from "./OKRDetailCard";
import { Action } from "hyphen-lib/dist/domain/Action";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ActionResource } from "hyphen-lib/dist/domain/resource/action/ActionResource";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { isNotEmptyObject } from "hyphen-lib/dist/lang/Objects";
import { Config } from "hyphen-lib/dist/util/Config";
import { goTo } from "@src/utils/locations";
import Palette from "@src/config/theme/palette";
import OkrErrorSection from "./OkrErrorSection";
import { Company } from "hyphen-lib/dist/domain/Company";
import { Trans } from "react-i18next";

const { WithOKRType } = ActionResource;

interface SuccessOKRProps extends RouteComponentProps {
  actionId: string;
  source: Action.Source;
  okr: Optional<ActionResource.OKRCreateResponseType>;
  isEditScreen?: boolean;
  isActionPlanAlreadyLinked?: boolean;
  betterworksBaseUrl?: Optional<string>;
  clearActionPlanResource: () => void;
  okrSynonymContainer: Company.GoalSetting;
  showOkrErrorMessage?: boolean;
}

class ActionPlanSuccessModal extends React.Component<SuccessOKRProps> {
  redirectToActionPlan = () => {
    this.props.history.goBack();
  };

  componentWillUnmount() {
    this.props.clearActionPlanResource();
  }

  onEditActionClick = () => {
    const { actionId } = this.props;
    goTo(`/actioncenter/actions/edit/${actionId}`);
  };

  goToBetterworks = () => {
    const betterworksUrl = Config.getInstance().get("BETTERWORKS_BASE_URL");
    window.open(betterworksUrl);
  };

  showOkrErrorCard = () => {
    const {
      okr,
      isEditScreen,
      betterworksBaseUrl,
    } = this.props;
    // if (isEditScreen && not(showOkrErrorMessage)) {
    //   return;
    // }
    if (isNotEmptyObject(okr) && okr.type === WithOKRType.ERROR) {
      return <OkrErrorSection
        betterworksBaseUrl={betterworksBaseUrl}
        isEditScreen={isEditScreen}
        actionId={this.props.actionId}
        errorCode={okr.error.code}
      />;
    }
  };

  renderOkrSuccessSection = (okr: ActionResource.OKRCreateSuccessType) => {
    const { isEditScreen, isActionPlanAlreadyLinked } = this.props;
    if (isEditScreen && isActionPlanAlreadyLinked) {
      return;
    }
    const okrSynonyms = this.props.okrSynonymContainer;
    const text = (okr?.type === WithOKRType.OBJECTIVE) ? okrSynonyms.objective : okrSynonyms.keyResult;

    return (
      <Container>
        <SubParagraph data-jest="successMessage">
          <Trans>Your</Trans> {text} <Trans>has been created in Betterworks</Trans> {okrSynonyms.okrs}.
        </SubParagraph>
        <OKRDetailCard okr={okr} okrSynonyms={this.props.okrSynonymContainer} />
      </Container>);
  };

  showOkrDetailCard = () => {
      const { 
        okr,
      } = this.props;

      if (isNotEmptyObject(okr)) {
        return (
          okr.type === WithOKRType.ERROR ? 
            this.showOkrErrorCard():
            this.renderOkrSuccessSection(okr)
        );
    }
  };

  render() {
    const { source, isEditScreen } = this.props;
    const actionPlanContainerTitleText = (isEditScreen) ? "Updated" : "Created";
    const actionPlanLayoutTitleText = (isEditScreen) ? "updated" : "published";
    return (
      <CustomCreationLayout 
        title={`Your Action Plan is ${actionPlanLayoutTitleText} now`} 
        onCloseIconClick={this.redirectToActionPlan}>
          <CustomContainerCard title={`Action Plan ${actionPlanContainerTitleText}!`}>
              <SubParagraph>
                <Trans>Your action plan for</Trans> {source.type} {source.label}
                <Trans>is</Trans> {actionPlanLayoutTitleText} <Trans>now</Trans>!
              </SubParagraph>
              {this.showOkrDetailCard()}
              <RedirectActionPlanButton
                data-cy="redirectToActionPlan"
                color="blue"
                onClick={this.redirectToActionPlan}>
                <Trans>Continue</Trans>
              </RedirectActionPlanButton>
          </CustomContainerCard>
      </CustomCreationLayout>
    );
  }
}

const RedirectActionPlanButton = styled(Button)`
  margin-top: 20px;
`;

const SubParagraph = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  color: ${Palette.veryDarkBlueGrey};
  font-family: Lato;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
`;

const CustomCreationLayout = styled(CreationLayout)`
  text-align: center;
`;

const Container = styled.div`
  background: ${Palette.paleGrey};
  padding: 10px;
  height: 188px;
  width: 595px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
`;

const CustomContainerCard = styled(ContainerCard)`
  margin-bottom: 20px;
`;

export default withRouter(ActionPlanSuccessModal);
