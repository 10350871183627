import React from "react";
import { RouteComponentProps } from "react-router";
import { Map as ImmutableMap, RecordOf } from "immutable";
import { Action } from "hyphen-lib/dist/domain/Action";
import {
  isNotNullNorUndefined,
  isNullOrUndefined,
  getOr,
  mapOr,
  isNotEmptyObject,
  cleanObject
} from "hyphen-lib/dist/lang/Objects";

import CreationLayout from "@components/layouts/CreationLayout";
import { State } from "@store/types";
import { goTo, replaceTo, replaceLocation, BreadcrumbElement } from "@src/utils/locations";
import AreYouSureModal from "@src/components/core/AreYouSureModal";
import ContainerCard from "@src/components/core/ContainerCard";
import styled from "styled-components";
import Button from "@src/components/core/Button";
import { connect } from "react-redux";
import { RadioChangeEvent } from "antd/lib/radio";
import RadioGroup from "antd/lib/radio/group";
import Radio from "@src/components/core/Radio";
import { not } from "hyphen-lib/dist/lang/Booleans";

import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { formatSort } from "@src/utils/helper";
import { parseNumber } from "hyphen-lib/dist/lang/Number";
import { getExistingPage, extractDataAndTotalFromPage } from "@src/store/network/selectors";
import { ActionPlanTemplateResource } from "hyphen-lib/dist/domain/resource/action/ActionPlanTemplateResource";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { SortParameter, FilterParameter } from "@src/utils/networks";
import { PropMapping, applyExistingParametersIfNeeded } from "@src/utils/parameters";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import { actionCreators as SurveysActionCreator } from "@screens/Insights/Surveys/store/actions";
import { PostCategoryResource } from "hyphen-lib/dist/domain/resource/post/PostCategoryResource";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { debounce } from "lodash";
import { clearFilter, clearFilterOption } from "@src/components/core/FilterLabels/utils";
import { PaginationConfig, SorterResult } from "antd/lib/table";
import { fetchActionPlanTemplatesIfNeeded } from "@store/network/resource/ActionPlanTemplateResources";
import { Store } from "hyphen-lib/dist/util/store/Store";
import Spin from "@src/components/core/Spin";
import qs from "qs";
import { FetchError } from "../../errors/FetchError";
import { parametersActionCreators } from "../../parameters/store/actions";
import { getPostCategorys } from "../../Surveys/store/selectors";
import { getParameters } from "../../parameters/store/selectors";
import { actionPlansActionCreators, CompanyActionTemplatesPageParameters } from "../store/actions";
import {
  PreviewTemplateProps,
  PreviewTemplateModal
} from "../components/PreviewTemplateModal";
import {
  ActionPlanTemplates
} from "../components/ActionPlanTemplates";
import { getActionPlansState } from "../store/selectors";
import { ActionPlanFromScratch } from "./ActionPlanFromScratch";
import { getCurrentUser } from "../../store/selectors";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";
import ActionPlanSuccessModal from "../components/ActionPlanSuccessModal";
import { NewActionResource } from "../store/reducers";
import { ActionResource } from "hyphen-lib/dist/domain/resource/action/ActionResource";
import { isOkrEnabled } from "hyphen-lib/dist/business/company/Companies";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { getAppSettings } from "../../Settings/store/selectors";
import { AppSettingsResource } from "hyphen-lib/dist/domain/resource/AppSettingsResource";
import { getOkrSynonyms } from "hyphen-lib/dist/business/appSetting/AppSettings";
import { Trans } from "react-i18next";

interface ReduxStateProps {
  readonly isAddingAction: boolean;
  readonly isFetchingActionTemplates: boolean;
  readonly existingPage: Store.Page<ActionPlanTemplateResource>;
  readonly total: number;
  readonly page: PageFilter;
  readonly sort: SortParameter;
  readonly parameters: ImmutableMap<string, any>;
  readonly filter: FilterParameter;
  readonly categories: PostCategoryResource[];
  readonly actionPlanTemplates: ActionPlanTemplateResource[];
  readonly currentUser: CurrentUserResource;
  readonly enableSuccessOKRModal: boolean;
  readonly actionPlan: RecordOf<NewActionResource>;
  readonly objectiveList: Optional<ActionResource.Objective[]>;
  readonly appSettings: AppSettingsResource;
}

interface ReduxActionProps {
  readonly onFetchIfNeeded: (parameters: CompanyActionTemplatesPageParameters) => void;
  readonly onModifyParameters: (parameters: Dictionary<any>, mappings?: PropMapping[]) => any;
  readonly onModifyList: (parameters: CompanyActionTemplatesPageParameters) => any;
  readonly fetchPostCategories: () => void;
  readonly cleanActionPlan: () => void;
  readonly fetchObjectiveList: (email: string) => void; 
}

interface QueryParams extends Action.Source {
  mode: CreationModes;
  templateId?: string;
  from: string;
}

export const ACTION_TEMPLATES_FILTER_MAPPINGS: PropMapping[] = [
  { localKey: "filter.difficulty", storeKey: "actionTemplatesFilter.difficulty" },
  { localKey: "filter.category", storeKey: "actionTemplatesFilter.category" },
  { localKey: "filter.categories", storeKey: "actionTemplatesFilter.categories" },
  { localKey: "filter.length", storeKey: "actionTemplatesFilter.length" },
  { localKey: "sort", storeKey: "actionTemplatesSort" },
];

interface CreateActionPlanProps extends RouteComponentProps, ReduxStateProps, ReduxActionProps {}
export interface CreateActionPlanStateProps {
  isAreYouSureModalVisible: boolean;
  isPreviewModalVisible: boolean;
  creationMode: CreationModes;
  defaultValues: {
    action?: string;
    description?: string;
    resource?: string;
    notifications?: Action.NotificationsConfiguration;
  };
  actionForPreview: ActionPlanTemplateResource;
  areFiltersVisible: boolean;
  modeSetByUser: boolean;
}

export enum CreationModes {
  scratch = "scratch",
  template = "template",
}

class CreateActionPlanContainer extends React.Component<
CreateActionPlanProps,
CreateActionPlanStateProps
> {
  private readonly onSearchChangeDebounced: (value: any) => void;
  private submitAction: any;
  constructor(props: CreateActionPlanProps) {
    super(props);

    const queryParams: QueryParams = this.getQueryParams();
    let mode = CreationModes.template;

    if (isNullOrUndefined(queryParams.mode)) {
      queryParams.mode = mode;
    } else {
      mode = queryParams.mode;
    }

    this.onSearchChangeDebounced = debounce(this.updateSearchFilter, 500);

    this.state = {
      isAreYouSureModalVisible: false,
      isPreviewModalVisible: false,
      creationMode: mode,
      defaultValues: {},
      actionForPreview: {} as PreviewTemplateProps,
      areFiltersVisible: false,
      modeSetByUser: false,
    };
  }
  componentDidMount() {
    const queryParams = this.getQueryParams();
    const {
      parameters,
      location: { search },
      categories,
      fetchPostCategories,
    } = this.props;
    const existing = parseQueryString(search);
    const mergedParameters =
      applyExistingParametersIfNeeded(
        parameters.toJS(),
        existing,
        ...ACTION_TEMPLATES_FILTER_MAPPINGS
      );

    // make network request to fetch post categories only if not already fetched
    if (categories.length === 0 ) {
      fetchPostCategories();
    }

    if (this.state.creationMode === CreationModes.template) {
      if (
        isNotNullNorUndefined(mergedParameters) &&
        isNotEmptyObject(mergedParameters)
      ) {
        replaceLocation(mergedParameters);
      } else {
        // fetch the overview only if we will stay on this page,
        // otherwise it will be fetched anyway in componentDidUpdate
        this.fetchIfNeeded();
      }
    }

    if (
      isNotNullNorUndefined(queryParams.context) &&
      isNotNullNorUndefined(queryParams.context.category)
    ) {
      const category = queryParams.context.category;
      this.handleApplyFilters({
        categories: [category],
      });
    }
  }

  componentDidUpdate(prevProps: CreateActionPlanProps) {
    const { isFetchingActionTemplates, existingPage, total, filter } = this.props;
    const { creationMode, modeSetByUser } = this.state;
    const hasFilterFreeTextChanged = filter.freeText !==  prevProps.filter.freeText;
    // when entering component, after fetching and before touching the component
    // if there are no templates then go to scratch creation mode

    if (
      Store.Page.isLoaded(existingPage) &&
      total <= 0 &&
      creationMode !== CreationModes.scratch &&
      isNullOrUndefined(filter.freeText) &&
      not(modeSetByUser)
    ) {
      this.setState({ creationMode: CreationModes.scratch });
    }
    // when user sets filter, user expects to be in template mode
    if (hasFilterFreeTextChanged && isNotNullNorUndefined(filter.freeText)) {
      this.setState({creationMode: CreationModes.template});
    }
    if (isFetchingActionTemplates && not(Store.Page.isInError(existingPage))) {
      this.fetchIfNeeded();
    }
  }

  fetchIfNeeded() {
    const {
      onFetchIfNeeded,
      page,
      filter,
      sort,
    } = this.props;
    const { creationMode } = this.state;
    const queryParams: QueryParams = Object.assign({}, this.getQueryParams(), {
      mode: creationMode,
    });

    onFetchIfNeeded({ filter, sort, page, queryParams });
  }

  handleFiltersClick = () => {
    this.setState(state => ({
      areFiltersVisible: !state.areFiltersVisible,
    }));
  };

  onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.onSearchChangeDebounced(value);
  };

  updateSearchFilter = (value: any) => {
    const {
      onModifyList,
      page,
      sort,
    } = this.props;

    const filter = getOr(this.props.filter, {});
    if (isStringAndNotEmpty(value)) {
      filter.freeText = value;
    } else {
      delete filter.freeText;
    }
    const queryParams: QueryParams = this.getQueryParams();

    const resetPage = { ...page, number: 1 };
    onModifyList({
      filter,
      page: resetPage,
      sort,
      queryParams,
    });
  };

  getQueryParams = (): QueryParams => {
    const { location } = this.props;
    // FIXME
    // @ts-ignore
    return parseQueryString(location.search);
  };

  onRadioButtonChange = (e: RadioChangeEvent) => {
    const { value } = e.target as any;
    const { location, page, filter, sort } = this.props;
    const { type, context, label } = this.getQueryParams();
    let queryParams = {
      page: page.number,
      filter,
      sort,
      ...(this.isFromSource() ? {
        type,
        context,
        label,
      } : {}),
    } as any;
    if (value === CreationModes.template && not(this.isFromSource())) {
      queryParams = Object.assign({}, queryParams, {
        mode: value,
      });
    } else {
      queryParams = Object.assign({}, this.getQueryParams(), {
        mode: value,
      });
    }

    const queryParamsAsString = qs.stringify(queryParams, { encode: true });
    replaceTo(`${location.pathname}?${queryParamsAsString}`);
    this.setState({ creationMode: value, modeSetByUser: true, defaultValues: {} });
  };

  isFromSource = () => {
    const { type, context } = this.getQueryParams();
    if (
      isNotNullNorUndefined(type) &&
      isNotNullNorUndefined(context) &&
      Object.values(Action.SourceType).includes(type)
    ) {
      return true;
    }
    return false;
  };

  toggleAreYouSureModal(
    modalType: "isAreYouSureModalVisible" | "isPreviewModalVisible",
    isModalOpen: boolean
  ) {
    // @ts-ignore
    this.setState({ [modalType]: isModalOpen });
  }

  onCloseAction = () => {
    const lastPath: BreadcrumbElement = (this.props.location as any).state.pop();
    let redirectPath = "/actioncenter/actions";
    if (isNotNullNorUndefined(lastPath) && lastPath.label === "ActionPlans") {
      redirectPath = lastPath.url;
    }
    goTo(redirectPath);
  };

  onSetClick = (click: any) => {
    this.submitAction = click;
  };

  onSubmit = (e: React.FormEvent) => {
    this.submitAction(e);
  };

  onCreateAction = (action: ActionPlanTemplateResource) => {
    const { category } = action;
    const { from, context } = this.getQueryParams();
    const source = {
      context: {
        category,
        ...(
          isNotNullNorUndefined(context) && 
          isNotNullNorUndefined(context.focusAreaId) && 
          {
            focusAreaId: context.focusAreaId
          }
        ),
      },
      label: encodeURIComponent(category),
      type: Action.SourceType.CATEGORY,
      mode: CreationModes.scratch,
      templateId: action._id,
      from,
    };

    this.setState({
      creationMode: CreationModes.scratch,
      defaultValues: {
        action: action.action,
        description: action.description,
        resource: action.resource,
      },
    });
    const queryParams = qs.stringify(source, { encode: false });
    replaceTo(`/actioncenter/actions/create?${queryParams}`);
  };

  onClearFilter = (filterToRemove: string) => {
    const {
      page,
      sort,
      onModifyList,
      onModifyParameters,
    } = this.props;

    const filter = clearFilter(filterToRemove, this.props.filter);
    const cleanedFilters = cleanObject(filter);

    onModifyParameters({ filter }, ACTION_TEMPLATES_FILTER_MAPPINGS);

    onModifyList({
      queryParams: this.getQueryParams(),
      filter: cleanedFilters,
      page,
      sort,
    });
  };

  onClearFilterOption = (filterKey: string, subFilter: string) => {
    const {
      page,
      sort,
      onModifyList,
      onModifyParameters,
    } = this.props;

    const filter = clearFilterOption(filterKey, subFilter, this.props.filter);
    const cleanedFilters = cleanObject(filter);

    onModifyParameters({ filter }, ACTION_TEMPLATES_FILTER_MAPPINGS);

    onModifyList({
      queryParams: this.getQueryParams(),
      filter: cleanedFilters,
      page,
      sort,
    });
  };

  handleApplyFilters = (newFilter: any) => {
    const {
      page,
      sort,
      onModifyParameters,
      onModifyList,
    } = this.props;

    const cleanedFilters = cleanObject(newFilter);

    this.setState({ areFiltersVisible: false });

    const resetPage = { ...page, number: 1 };
    const queryParams: QueryParams = this.getQueryParams();

    onModifyParameters({ sort, filter: newFilter }, ACTION_TEMPLATES_FILTER_MAPPINGS);
    onModifyList({
      filter: cleanedFilters,
      page: resetPage,
      sort,
      queryParams,
    });
  };

  onPreviewAction = (action: ActionPlanTemplateResource) => {
    this.setState({
      actionForPreview: action,
      isPreviewModalVisible: true,
    });
  };

  onCreateFromPreview = () => {
    const { actionForPreview } = this.state;
    this.onCreateAction(actionForPreview);
    this.setState({ isPreviewModalVisible: false });
  };

  onCloseIconClick = () => {
    const { location } = this.props;
    // FIXME
    // @ts-ignore
    const queryParams: QueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (isNotNullNorUndefined(queryParams.mode) && queryParams.mode === CreationModes.scratch) {
      this.toggleAreYouSureModal("isAreYouSureModalVisible", true);
    } else {
      this.onCloseAction();
    }
  };

  onTableChange = (pagination: PaginationConfig, filters: any, sorter: SorterResult<any>) => {
    const { page, onModifyList, filter } = this.props;
    const pageParam = {
      size: getOr(pagination.pageSize, page.size),
      number: getOr(pagination.current, 1),
    };
    const sortParam : SortParameter = { };
    if (isNotEmptyObject(sorter) && isNotNullNorUndefined(sorter.order)) {
      sortParam[sorter.columnKey] = sorter.order === "ascend" ? 1 : -1;
    }
    const queryParams: QueryParams = this.getQueryParams();

    onModifyList({
      filter,
      page: pageParam,
      sort: sortParam,
      queryParams,
    });
  };

  showAreYouSureModal() {
    const { isAreYouSureModalVisible } = this.state;
    return isAreYouSureModalVisible && (
      <AreYouSureModal
        visible={isAreYouSureModalVisible}
        title="Are you sure?"
        description="Leaving this screen will delete the Action Plan you are creating."
        okLabel="Delete"
        cancelLabel="Cancel"
        onCancel={this.toggleAreYouSureModal.bind(
          this,
          "isAreYouSureModalVisible",
          false
        )}
        onOk={this.onCloseAction}
      />
    );
  }

  showPreviewModal() {
    const { isPreviewModalVisible, actionForPreview } = this.state;
    return isPreviewModalVisible && (
      <PreviewTemplateModal
        visible={isPreviewModalVisible}
        {...actionForPreview}
        onCancel={this.toggleAreYouSureModal.bind(
          this,
          "isPreviewModalVisible",
          false
        )}
        footer={[
          <Button
            onClick={this.toggleAreYouSureModal.bind(
              this,
              "isPreviewModalVisible",
              false
            )}
            key="cancel"
            color="grey">
            <Trans>Cancel</Trans>
          </Button>,
          <Button
            onClick={this.onCreateFromPreview}
            key="create"
            color="blue">
            <Trans>Create</Trans>
          </Button>,
        ]}
      />
    );
  }

  showActionPlanBasedOnMode() {
    const { creationMode, defaultValues } = this.state;
    const { categories, filter, page, actionPlanTemplates, total, currentUser } = this.props;
    const isOKREnabled = isOkrEnabled(currentUser.company!);
    const isCreateMode = creationMode === CreationModes.scratch;
    return (isCreateMode ? (
      <ActionPlanFromScratch
        isOKREnabled={isOKREnabled}
        setClick={this.onSetClick}
        defaultValues={defaultValues}
        isFromSource={this.isFromSource}
      />
    ) : (
      <ActionPlanTemplates
        categories={categories}
        filter={filter}
        onApplyFilter={this.handleApplyFilters}
        onCreateAction={this.onCreateAction}
        onPreviewAction={this.onPreviewAction}
        areFiltersVisible={this.state.areFiltersVisible}
        handleFiltersClick={this.handleFiltersClick}
        onSearchChange={this.onSearchChange}
        actionPlanTemplates={actionPlanTemplates}
        onClearFilter={this.onClearFilter}
        onClearFilterOption={this.onClearFilterOption}
        page={page}
        total={total}
        onTableChange={this.onTableChange}
      />
    ));
  }

  render() {
    const {
      creationMode,
    } = this.state;
    const {
      isFetchingActionTemplates,
      existingPage,
      enableSuccessOKRModal,
      actionPlan,
      currentUser,
      cleanActionPlan,
    } = this.props;

    const { 
      source,
      okr,
      _id,
     } = actionPlan.toJS();

    const isCreateMode = creationMode === CreationModes.scratch;
    const betterworksBaseUrl = currentUser.company?.betterworksBaseUrl;
    if (Store.Page.isInError(existingPage)) {
      return <FetchError { ...existingPage } resourceType={ActionPlanTemplateResource.TYPE} />;
    }
    if (isFetchingActionTemplates) {
      return <SpinContainer>
        <Spin size="large" />
      </SpinContainer>;
    }

    const okrSynonymContainer = getOkrSynonyms(this.props.appSettings);

    return (enableSuccessOKRModal ?
      <ActionPlanSuccessModal
        actionId={_id}
        source={source}
        clearActionPlanResource={cleanActionPlan}
        betterworksBaseUrl={betterworksBaseUrl}
        okr={okr as ActionResource.OKRCreateResponseType}
        okrSynonymContainer={okrSynonymContainer}
      /> :
      (<CreationLayout
        title="Create a new Action Plan"
        onCloseIconClick={this.onCloseIconClick}>
        <Container
          isCreateMode={isCreateMode}
          title="Select a template or create from scratch to get started">
          <Options onChange={this.onRadioButtonChange} value={creationMode}>
            <Radio value={CreationModes.scratch} data-cy="mode_scratch">
              <Trans>Create your own action plan from scratch</Trans>
            </Radio>
            <Radio value={CreationModes.template} data-cy="mode_template">
              <Trans>Select a template</Trans>
            </Radio>
          </Options>
          { this.showActionPlanBasedOnMode() }
          { this.showAreYouSureModal() }
          { this.showPreviewModal() }
        </Container>
      </CreationLayout>));
    }
 }

const Options = styled(RadioGroup)`
  margin: 28px 0 !important;
`;

const SpinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 250px);
`;

const Container = styled(ContainerCard)<{isCreateMode: boolean}>`
  ${props =>
    not(props.isCreateMode)
      ? `
    padding: 32px 0 !important;
    > div:first-child {
      p {
        padding: 0 32px;
      }
    }

    .ant-radio-group  {
      padding: 0 32px;
    }
  `
      : "margin-bottom: 60px"};
`;

function mapStateToProps(state: State, { location }: RouteComponentProps): ReduxStateProps {
  const queryParameters = parseQueryString(location.search);
  const filter = getOr(queryParameters.filter, {});
  const sort = getOr(formatSort(queryParameters.sort), {});
  const { isAddingAction, pageSize, enableSuccessOKRModal, actionPlan, objectiveList } = getActionPlansState(state);
  const page = {
    size: pageSize,
    number: mapOr(queryParameters.page, parseNumber, 1),
  };
  const existingPage = getExistingPage(
    state,
    ActionPlanTemplateResource.TYPE,
    ActionPlanTemplateResource.generateKey(filter, sort),
    page
  );
  const { data, total } = extractDataAndTotalFromPage(existingPage);

  const currentUser = mapOr(
    getCurrentUser(state),
    (user: CurrentUserResource) =>
      user,
    {} as CurrentUserResource
  );

  const appSettings = getOr(getAppSettings(state).get(0), {} as AppSettingsResource);

  return {
    isFetchingActionTemplates: Store.Page.isNotFound(existingPage),
    existingPage,
    actionPlanTemplates: data,
    isAddingAction,
    total,
    sort,
    filter,
    categories: getPostCategorys(state).toArray(),
    parameters: getParameters(state),
    page,
    currentUser,
    enableSuccessOKRModal,
    actionPlan,
    objectiveList,
    appSettings,
  };
}

const mapDispatchProps = {
  onFetchIfNeeded: fetchActionPlanTemplatesIfNeeded,
  onModifyList: actionPlansActionCreators.modifyList,
  fetchPostCategories: SurveysActionCreator.fetchPostCategorys,
  onModifyParameters: parametersActionCreators.modifyParameters,
  cleanActionPlan: actionPlansActionCreators.cleanActionPlan,
  fetchObjectiveList: actionPlansActionCreators.fetchObjectiveList,
};

export const CreateActionPlan = connect(mapStateToProps, mapDispatchProps)(
  CreateActionPlanContainer
);
