import React from "react";
import { ColumnProps, TableProps } from "antd/lib/table";
import { VoiceFlaggedPostReportResource } from "hyphen-lib/dist/domain/resource/voice/VoiceFlaggedPostReportResource";

import Table from "@src/components/core/Table";
import {
  DynamicRowProps,
  withDynamicRows
} from "@src/components/core/DynamicRowsTable/withDynamicRows";
import { CommentsCounter } from "@src/components/core/CommentsCounter";
import Actions from "@src/screens/Insights/components/Actions";

import { formatDate } from "hyphen-lib/dist/lang/Dates";
import { RightsMatcher } from "hyphen-lib/dist/business/auth/Auth";
import { Rights } from "hyphen-lib/dist/business/auth/Rights";
import { TextCell } from "../../VoiceComments/components/cells/TextCell";
import { Votes } from "../../VoicePosts/Components/cells/Votes";
import { PostedDestination } from "../../VoicePosts/Components/cells/PostedDestination";
import { SentimentCell } from "../../VoicePosts/Components/cells/SentimentCell";
import { VoiceFlagsActionKeyType } from "../../store/types";
import { Trans } from "react-i18next";

interface VoiceFlaggedPostsTableProps
  extends TableProps<VoiceFlaggedPostReportResource> {
  readonly onActionClick: (key: VoiceFlagsActionKeyType, post: VoiceFlaggedPostReportResource) => void;
  readonly dataSource: VoiceFlaggedPostReportResource[];
  readonly rightsMatcher: RightsMatcher;
}

export function VoiceFlaggedPostsTable(props: VoiceFlaggedPostsTableProps) {
  const COLUMNS: ColumnProps<VoiceFlaggedPostReportResource>[] = [
    {
      title: (<Trans>Post</Trans>),
      dataIndex: "text",
      key: "text",
      width: "40%",
      render: (_, { text }) => <TextCell text={text} />,
    },
    {
      title: null,
      dataIndex: "upVotesCount",
      key: "upVotesCount",
      render: (_, { upVotesCount }) => <Votes count={upVotesCount} />,
    },
    {
      title: null,
      dataIndex: "downVotesCount",
      key: "downVotesCount",
      render: (_, { downVotesCount }) => <Votes count={downVotesCount} down />,
    },
    {
      title: null,
      dataIndex: "commentsCount",
      key: "commentsCount",
      width: "5%",
      render: (_, { commentsCount }) => (
        <CommentsCounter numberOfComments={commentsCount} />
      ),
    },
    {
      title: (<Trans>Flag reason</Trans>),
      dataIndex: "reason",
      key: "reason",
      width: "10%",
      render: (_, { flagReason }) => <TextCell text={flagReason} />,
    },
    {
      title: (<Trans>Flag date</Trans>),
      dataIndex: "flagDate",
      key: "flagDate",
      width: "10%",
      render: (_, { flagDate }) => <TextCell text={formatDate(flagDate)} />,
    },
    {
      title: (<Trans>Posted In</Trans>),
      dataIndex: "postedIn",
      key: "postedIn",
      render: (_, { group }) => <PostedDestination group={group} />,
    },
    {
      title: (<Trans>Sentiment</Trans>),
      dataIndex: "sentimentScore",
      key: "sentimentScore",
      width: "15%",
      render: (_, { sentimentScore }) => (
        <SentimentCell sentimentScore={sentimentScore} />
      ),
    },
  ];

  function HoverRow({ row }: DynamicRowProps<VoiceFlaggedPostReportResource>) {
    function onActionClick(key: VoiceFlagsActionKeyType) {
      props.onActionClick(key, row);
    }
    return (
      <>
        <td>
          <TextCell text={row.text} />
        </td>
        <td>
          <Votes count={row.upVotesCount} />
        </td>
        <td>
          <Votes count={row.downVotesCount} down />
        </td>
        <td>
          <CommentsCounter numberOfComments={row.commentsCount} />
        </td>
        <td>
          <TextCell text={row.flagReason} />
        </td>
        <td>
          <TextCell text={formatDate(row.flagDate)} />
        </td>
        <td>
          <PostedDestination group={row.group} />
        </td>
        <td>
          { props.rightsMatcher.hasRight(Rights.Voice.FLAGGED_POSTS_ACTIONS)
            ? <Actions
              actions={{
                actions: [
                  {
                    key: VoiceFlagsActionKeyType.UNFLAG as never,
                    label: "Unflag",
                  },
                  {
                    key: VoiceFlagsActionKeyType.ARCHIVE as never,
                    label: "Archive",
                  },
                ],
                more: [],
              }}
              onClick={onActionClick as any}
              justifyContent="flex-start"
            />
            : <SentimentCell sentimentScore={row.sentimentScore} />
          }
        </td>
      </>
    );
  }

  const HoveringTable = withDynamicRows(Table, HoverRow);

  return <HoveringTable rowKey="_id" columns={COLUMNS} {...props} />;
}
