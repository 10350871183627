import { default as networkActionCreators } from "@store/network/actions";
import { createRequest } from "@src/utils/networks";
import { appendQueryString, generateQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
// eslint-disable-next-line max-len
import { MultipleChoiceQuestionReportResource } from "hyphen-lib/dist/domain/resource/report/MultipleChoiceQuestionReportResource";

export function fetchMultipleChoiceQuestionReportIfNeeded(surveyId: string,
  questionId: string,
  queryString: Dictionary<any>) {

  return networkActionCreators.fetchElementIfNeeded({
    id: MultipleChoiceQuestionReportResource.generateId({ surveyId, questionId }, queryString.filter),
    type: MultipleChoiceQuestionReportResource.TYPE,
    request: createRequest(
      appendQueryString(
        `/surveys/${surveyId}/reports/questions/${questionId}`,
        generateQueryString({ ...queryString })
      )
    ),
  });
}

export function fetchPollMultipleChoiceQuestionReportIfNeeded(
  templateId: string,
  instanceId: string,
  queryString: Dictionary<any>
) {
  return networkActionCreators.fetchElementIfNeeded({
    id: MultipleChoiceQuestionReportResource.generateId(
      { templateId, instanceId },
      queryString.filter
    ),
    type: MultipleChoiceQuestionReportResource.TYPE,
    request: createRequest(
      appendQueryString(
        `/pulsepolls/${templateId}/reports/questions/${instanceId}`,
        generateQueryString({ ...queryString })
      )
    ),
  });
}
