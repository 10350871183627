import { isEmpty } from "hyphen-lib/dist/lang/Arrays";
import { getOr /* isNotNullNorUndefined,  isNullOrUndefined*/ } from "hyphen-lib/dist/lang/Objects";
import { PointOptionsObject } from "highcharts";
import { TrendLineProps, TrendLineChartData } from "..";
import { PRIMARY_SERIES } from ".";

export interface LineSeriesOptions {
  seriesDataLabelsOptions: Highcharts.DataLabelsOptions;
  xAxisLabelsOptions: Highcharts.XAxisLabelsOptions;
}

export function generateLineSeriesAndLabels<T>(
  data: TrendLineChartData<T>[],
  styles: TrendLineProps["styles"],
  minTicks: TrendLineProps["minTicks"],
  options: LineSeriesOptions
): Partial<Highcharts.Options> {
  if (isEmpty(data)) {
    return {
      series: [
        PRIMARY_SERIES,
      ] as Highcharts.SeriesOptionsType[],
      xAxis: {
        categories: [],
      },
    };
  }

  const dataForDisplay: PointOptionsObject[] = [];
  const categories: string[] = [];

  let idx = 0;

  // Render all points
  for (idx = 0; idx < data.length; idx++) {
    const [x, y, pointOptions] = data[idx];
    categories.push(x);
    // zones[0].value = idx; // "solid" line series extends until idx

    dataForDisplay.push({
      ...getOr(pointOptions, {custom: {}}),
      x: idx,
      y,
    });

  }
  if (idx < minTicks) {
    const noOfMonthsToAdd = minTicks - idx - 1;
    for (let idx2 = 0; idx2 <= noOfMonthsToAdd; idx2++) {
      dataForDisplay.push({custom: {}, x: idx + idx2,  y: null});
      categories.push("");
    }
  }

  return {
    series: [
      {
        ...PRIMARY_SERIES,
        data: dataForDisplay,
        gapSize: 1,
        connectNulls: false,
        dataLabels: options.seriesDataLabelsOptions,
        // zones,
        color: styles!.lineColor,
      },
    ] as Highcharts.SeriesOptionsType[],
    xAxis: {
      categories,
      labels: options.xAxisLabelsOptions,
    },
  };
}
