import React from "react";
import { Table } from "antd";
import { TableProps } from "antd/lib/table/interface";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { MergeTagResource } from "hyphen-lib/dist/domain/resource/MergeTagResource";
import { Trans } from "react-i18next";
import NoResult from "@src/components/core/NoResult";
import { useTranslation } from "react-i18next";
import { translate } from "@src/utils/i18next";

interface Props {
  mergeTags: MergeTagResource[];
  fieldName: string;
  addAction: (mergeTag: string) => void;
}

const MergeTagsModalContent = (props: Props) => {
  const { mergeTags, addAction, fieldName } = props;
  const { t } = useTranslation();
  const columns = [{
    title: (<Trans>Key</Trans>),
    dataIndex: "key",
    render: (text: any) => `{{${text}}}`,
  }, {
    title: (<Trans>Description</Trans>),
    dataIndex: "description",
    key: "description",
  }, {
    title: (<Trans>Action</Trans>),
    dataIndex: "action",
    render: (text: any, record: any) => {
      return (
        <div
          className="add-merge-tag"
          onClick={addAction.bind(undefined, `{{${record.key}}}`)}>
          <Trans>Add to</Trans> "{translate(t, fieldName.replace(/'/g,""))}"
        </div>
      );
    },
  }];

  return (
    <>
      <MergeTagsTable pagination={false} columns={columns} dataSource={mergeTags} rowKey="_id" 
      locale={{
        emptyText: (<NoResult type="minimal" description="No Data" />),
      }}/>
    </>
  );
};

const MergeTagsTable = styled(Table)<TableProps<any>>`
  td {
    padding: 20px 32px !important;
  }

  thead {
    display: none;
  }

  tr > td:nth-child(2) {
    color: ${Palette.bluishGrey} !important;
  }

  tr > td:nth-child(3) {
    cursor: pointer;
    color: transparent;
    text-align: center;
    min-width: 180px;
  }

  tr:hover > td:nth-child(3) {
    color: ${Palette.bluePurple} !important;
  }

  tr:hover > td {
    background: ${Palette.lightBlue} !important;
  }
`;

export default MergeTagsModalContent;
