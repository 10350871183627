import { State } from "@store/types";

export function getPulsePollListStateProps(state: State) {
  return state.get("insights_pulse_poll_list");
}

export function getPulsePollInfoFilters(state: State) {
  return state.getIn(["insights_pulse_poll_list", "filter"]);
}

export function getPulsePollStatusOnToggleModal(state: State) {
  return state.getIn(["insights_pulse_poll_list", "pollStatus"]);
}