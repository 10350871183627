import React from "react";
import { Router } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { history } from "@src/utils/locations";
import i18n  from "@src/utils/i18next";
import { I18nextProvider, withTranslation } from "react-i18next";

import Insights from "@screens/Insights";
import Palette from "@src/config/theme/palette";

import Notifications from "@src/screens/Insights/Notifications";
import ScrollToTop from "@src/screens/Insights/components/ScrollToTop";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: "Lato", sans-serif !important;
  }
  .ant-tooltip-inner {
    background-color: ${Palette.darkBlueGrey} !important;
    border-radius: 2px !important;
  }
  .ant-tooltip-placement-top
  .ant-tooltip-arrow,
  .ant-tooltip-placement-topLeft
  .ant-tooltip-arrow,
  .ant-tooltip-placement-topRight
  .ant-tooltip-arrow {
    border-top-color: ${Palette.darkBlueGrey} !important;
  }

  .highcharts-container {
    overflow-y: visible !important;
  }
  .highcharts-plot-band-label {
    display: flex !important;
    max-height: 240px !important;
  }

  .highcharts-plot-band :hover {
    z-index: 20;
    background-color: ${Palette.lightLightBlue};
  }

`;

export class App extends React.Component {
  public render() {
    return (
      <I18nextProvider i18n={i18n}>
        <Notifications />
        <Router history={history}>
          <ScrollToTop>
            <Insights />
          </ScrollToTop>
        </Router>
        <GlobalStyle />
      </I18nextProvider>
    );
  }
}

export default withTranslation()(App);
