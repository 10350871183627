import {
  ActionTypes as NetworkActionTypes,
  NetworkEventErrorAction,
  NetworkEventRequestAction,
  NetworkEventSuccessAction,
  NetworkRequestAction
} from "@store/network/actions";

import { createRequest } from "@src/utils/networks";
import { FSA } from "flux-standard-action";
import { FiltersStateProps } from "./types";
import { appendQueryString, generateQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";

export enum ActionTypes {
  FETCH_FILTERS_REQUEST = "FETCH_FILTERS_REQUEST",
  FETCH_FILTERS_SUCCESS = "FETCH_FILTERS_SUCCESS",
  FETCH_FILTERS_ERROR = "FETCH_FILTERS_ERROR",

  FETCH_DASHBOARD_CATEGORY_REQUEST = "FETCH_DASHBOARD_CATEGORY_REQUEST",
  FETCH_DASHBOARD_CATEGORY_SUCCESS = "FETCH_DASHBOARD_CATEGORY_SUCCESS",
  FETCH_DASHBOARD_CATEGORY_ERROR = "FETCH_DASHBOARD_CATEGORY_ERROR",
}

export const createSurveyFiltersRequest = (surveyId: string) =>
  createRequest(`/surveys/${surveyId}/reports/filters`);
export const createDashboardCategorysRequest = (queryString: Dictionary<any>) => 
  createRequest(
    appendQueryString(
      "/dashboards/categories",
      generateQueryString(queryString)
    )
  );
interface Payloads {
  [ActionTypes.FETCH_FILTERS_SUCCESS]: Partial<FiltersStateProps>;
}

export interface UpdateFiltersAction
  extends FSA<ActionTypes.FETCH_FILTERS_SUCCESS, Payloads[ActionTypes.FETCH_FILTERS_SUCCESS]> {
  type: ActionTypes.FETCH_FILTERS_SUCCESS;
  payload: Payloads[ActionTypes.FETCH_FILTERS_SUCCESS];
}

export const actionCreators = {
  fetchSurveyFilters: (surveyId: string): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createSurveyFiltersRequest(surveyId),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => ({
          type: ActionTypes.FETCH_FILTERS_REQUEST,
          payload,
        }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({
          type: ActionTypes.FETCH_FILTERS_SUCCESS,
          payload,
        }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({
          type: ActionTypes.FETCH_FILTERS_ERROR,
          payload,
        }),
      ],
    },
  }),
  fetchDashboardCategories: (queryString: Dictionary<any>): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createDashboardCategorysRequest(queryString),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => ({ 
          type: ActionTypes.FETCH_DASHBOARD_CATEGORY_REQUEST, payload 
        }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({ 
          type: ActionTypes.FETCH_DASHBOARD_CATEGORY_SUCCESS, payload 
        }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({ 
          type: ActionTypes.FETCH_DASHBOARD_CATEGORY_ERROR, payload 
        }),
      ],
    },
  }),
};
