import React from "react";
import styled from "styled-components";

import Palette from "@src/config/theme/palette";
import { Form } from "antd";
import { Trans } from "react-i18next";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Paragraph } from "./Typography";

interface FieldLabelProps {
  label?: React.ReactNode | string;
  description?: string;
  helperAction?: string;
  onActionClick?: () => void;
  className?: string;
  error?: Optional<string>;
  forwardedRef?: React.Ref<any>;
}

export interface WithFieldLabelProps extends FieldLabelProps {
  description?: string;
}

/** From T make every property K optional */
type Partialize<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>> & Partial<Pick<T, K>>;

/** Remove props, that have been pre-filled by the HOC */
type OptionalPreFilled<T extends WithFieldLabelProps> = Partialize<T, keyof WithFieldLabelProps>;

export function FieldLabels(props: FieldLabelProps) {
  const { label, helperAction, onActionClick, className } = props;

  let position = "space-between";

  if (helperAction === undefined || helperAction === null) {
    position = "flex-start";
  } else if (label === undefined || label === null) {
    position = "flex-end";
  }
  return (
    <Container className={className} labelPosition={position} >
      {label && <Label><Trans>{label}</Trans></Label>}
      {helperAction && (
        <Action onClick={onActionClick}>{helperAction}</Action>
      )}
    </Container>
  );
}

export const withFieldLabels = <P extends WithFieldLabelProps>(Component: React.ComponentType<P>) => {
  class EnhancedComponent extends React.Component<OptionalPreFilled<P>> {

    render() {
      const { label, description, helperAction, onActionClick, className, error, forwardedRef, ...rest } = this.props;
      return (
        <div className={className}>
          {(label || helperAction) && (
            <Helpers {...{ label, helperAction, onActionClick }} />
          )}
          <FormItem
            validateStatus={error && "error"}
            help={error && <Trans>{error}</Trans>}
          >
            <Component {...rest as P} ref={forwardedRef} />
          </FormItem>
          {description && <Description weight="light"><Trans>{description}</Trans></Description>}
        </div>
      );
    }
  }

  return React.forwardRef((props: OptionalPreFilled<P>, ref: React.Ref<any>) => {
    // fixme: compilation
    // @ts-ignore
    return <EnhancedComponent {...props} forwardedRef={ref} />;
  });
};

interface ContainerProps {
  labelPosition?: string;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  width: 100%;
  justify-content: ${props => props.labelPosition};
  margin-bottom: 4px;
`;
const Helpers = styled(FieldLabels)`
  margin-bottom: 4px;
`;

const Label = styled.label`
  color: ${Palette.veryDarkBlueGrey};
  line-height: 1.43 !important;
`;

const Description = styled(Paragraph)`

`;

const Action = styled(Paragraph)`
  color: ${Palette.bluePurple} !important;
  cursor: pointer;
`;

const FormItem = styled(Form.Item)`
  margin: 0 !important;
`;
