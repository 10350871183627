import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import * as d3 from "d3-selection";
import { hierarchy, pack } from "d3-hierarchy";
import ReactDOMServer from "react-dom/server";
import { Sentiment } from "hyphen-lib/dist/domain/common/ComputationTypes";
import tippy from "tippy.js";
import { SentimentMap } from "@src/screens/Insights/Survey/components/Topics/SurveyTopicsChart";
import { Breadcrumb, goTo } from "./locations";

const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
export interface FilteredElementAndPercentages<E extends { percentage?: Optional<number> }> {
  readonly elements: E[];
  readonly pieceToAdd: number;
}

export const fontSizeConverter = (count: number, min: number, max: number, 
  minSize: number, maxSize: number) => {
  if (max - min === 0) {
    // handle devision by zero
    return Math.round((minSize + maxSize) / 2);
  }
  return Math.round(
    ((count - min) * (maxSize - minSize)) / (max - min) + minSize,
  );
};

export function adjustElementsAndPercentages<E extends { percentage?: Optional<number> }>(elements: E[]):
FilteredElementAndPercentages<E> {

  const filteredElements: E[] = [];
  let numberOfNon0Elements = 0;
  let sum = 0;
  for (const element of elements) {
    const { percentage } = element;
    if (isNotNullNorUndefined(percentage)) {
      filteredElements.push(element);
      if (percentage > 0) {
        numberOfNon0Elements++;
        sum += percentage;
      }
    }
  }

  let pieceToAdd = 0;
  if (sum < 100) {
    pieceToAdd = (100 - sum) / numberOfNon0Elements;
  }

  return {
    elements: filteredElements,
    pieceToAdd: isFinite(pieceToAdd) ? pieceToAdd : 0,
  };
}

export interface d3List {
  readonly children: d3Data[];
}
interface d3Data {
  readonly name: string;
  readonly value: number;
  readonly content?: any;
  readonly tooltip?: any;
  readonly sentiments?: Optional<Sentiment>;
  readonly key: string;
  readonly surveyId: string;
  readonly sectionName: string;
  readonly questionId?: string;
  readonly categoryId?: string;
}

export const initBubbblePack = (data: d3List, 
  el: HTMLElement | null, diameter: number)=> {
  
  const json:d3List = data;
  
  d3.select(el).selectAll("*").remove();

  const margin = {
    left: 0,
    right: 25,
    top: 0,
    bottom: 0
  };
  
  let paddingOffset = 10;

  const id = el?.getAttribute("id");
  if(id === SentimentMap[Sentiment.POSITIVE]) {
    paddingOffset = 25;
    margin.left = paddingOffset;
  } 

  const svg = d3.select(el).append("svg")
    .attr("viewBox",`-${paddingOffset} 0 ${diameter + margin.right} ${diameter}`)
    .attr("width", (diameter + margin.left + margin.right))
    .attr("class", "chart-svg");

  const root = hierarchy(json)
    .sum((d: any) => { return d.value; })
    .sort(function(a: any, b: any) { return b.value - a.value; });

  const bubble = pack()
    .size([diameter, diameter])
    .padding(-25);
  
  bubble(root);
  let tooltipInstance: any;
  const mouseenter = (ev: MouseEvent, d: any) => {
    if((!tooltipInstance || tooltipInstance.state.isDestroyed)){
      // @ts-ignore
      tooltipInstance = tippy(ev.target, {
        placement: "bottom",
        content: () => ReactDOMServer.renderToString(d.data.tooltip).replace("ant-tooltip",""),
        allowHTML: true,
        hideOnClick: false,
        onHidden: (instance: any) => {
          instance.destroy();
        }
      });
      tooltipInstance.show();
    }
  };

  const onClick = (ev: MouseEvent, d: any) => {
    const { surveyId, name, sectionName, questionId, categoryId } = d.data;
    if(isNotNullNorUndefined(questionId)) {
      goTo(`/surveys/view/${surveyId}/reports/topics/${name}/questions/${questionId}/comments`, 
        Breadcrumb.stack(sectionName)
      );
    } else if(isNotNullNorUndefined(categoryId)) {
      goTo(`/surveys/view/${surveyId}/reports/topics/${name}/categories/${categoryId}/comments`, 
        Breadcrumb.stack(sectionName)
      );
    } else {
      goTo(`/surveys/view/${surveyId}/reports/topics/${name}/comments`, 
        Breadcrumb.stack(sectionName)
      );
    }
    mouseleave(ev, d);
  };

  const mouseleave = (ev: MouseEvent, d: any) => {
    if (tooltipInstance && !tooltipInstance.state.isDestroyed) {
      tooltipInstance.hide();
      tooltipInstance.destroy();
      tooltipInstance = null;
    }
  };
  
  const node = svg.selectAll(".node")
    // @ts-ignore
    .data(root.children)
    .enter()
    .append("g").attr("class", "node")
    .attr("transform", (d: any) => { return "translate(" + d.x + " " + d.y + ")"; })
    .append("g").attr("class", isChrome ? "bubble-graph" : "");
  
  node.append("circle")
    .attr("r", (d: any) => { return d.value / 2; })
    .style("fill", "transparent");
  
  const sentimentDonut = node.append("foreignObject")
    .attr("width", (d: any) => { return (d.value); })
    .attr("height", (d: any) => { return (d.value); })
    .attr("x", (d: any) => { return -(d.value / 2); })
    .attr("y", (d: any) => { return -(d.value / 2); })
    .attr("id", (d: any) => { return d.data.key; })
    .style("text-anchor", "middle")
    .html((d:any) => {return ReactDOMServer.renderToString(d.data.content); });

  sentimentDonut.selectChild()
    .on("mouseenter", mouseenter)
    .on("mouseleave", mouseleave)
    .on("click", onClick);

};