import React from "react";
import styled from "styled-components";

import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { ActionResource } from "@hyphen-lib/domain/resource/action/ActionResource";

import Palette from "@src/config/theme/palette";
import { ActionPlanStatusTag } from "@components/core/ActionPlanStatusTag";
import { DynamicRowProps } from "@components/core/DynamicRowsTable/withDynamicRows";
import {Icon} from "antd";
import { CellContainer, CellInnerContainer } from "./Cells";

interface StatusCellProps {
  info?: string;
  danger?: boolean;
}

function renderLabel({ info, danger }: StatusCellProps) {
  if (isNotNullNorUndefined(info)) {
    if (isNotNullNorUndefined(danger)) {
      if (danger) {
        return (
          <WarningText>
            <StyledIcon type="warning" twoToneColor={Palette.darkPink} />
            {info}
          </WarningText>
        );
      } else {
        return (
          <BluishGreyText>
            {info}
          </BluishGreyText>
        );
      }

    } else {
      return (
        <BluishGreyText>
          {info}
        </BluishGreyText>
      );
    }
  } else {
    return null;
  }
}

export function StatusCell({ row, info, danger }: DynamicRowProps<ActionResource> & StatusCellProps) {
  return (
    <CellContainer align="center" justifyContent="flex-start">
      <CellInnerContainer margin={0} width="86px">

        <ActionPlanStatusTag status={row.status} />

        {renderLabel({info, danger})}

      </CellInnerContainer>
    </CellContainer>
  );
}

const WarningText = styled.div`
  margin-top: 6px;
  color: ${Palette.darkPink};
  width: 200px;
`;

const BluishGreyText = styled.div`
  margin-top: 6px;
  color: ${Palette.bluishGrey};
  width: 200px;
`;

const StyledIcon = styled(Icon)`
  color: ${Palette.darkPink};
  vertical-align: center;
  display: inline-block;
  margin-right: 3px;
  font-size: 15px;
`;
