import React from "react";
import { SentimentTag } from "@src/components/core/SentimentTag";
import styled from "styled-components";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";

export function SentimentCell({ sentimentScore }: { sentimentScore?: number }) {
  let sentiment = sentimentScore;
  if (isNotNullNorUndefined(sentimentScore)) {
    if (sentimentScore > 0) {
      sentiment = 1;
    } else if (sentimentScore < 0) {
      sentiment = -1;
    }
  }
  return (
    <Container>
      <SentimentTag sentiment={sentiment} />
    </Container>
  );
}

const Container = styled.div`
  > div {
    max-width: 80px;
  }
`;

Container.displayName = "SentimentCell";
