import styled from "styled-components";

import ContainerCard from "@src/components/core/ContainerCard";

const PhaseContainer = styled(ContainerCard)`
  position: relative;
  margin-bottom: 24px;

  .extra {
    position: absolute;
    top: 32px;
    right: 32px;
  }
`;

export default PhaseContainer;
