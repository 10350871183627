import { isNullOrUndefined } from "util";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";

import styled from "styled-components";

import moment from "moment";

import { State } from "@store/types";
import { getResourceById } from "@src/store/network/selectors";
import { getChannels } from "@screens/Insights/Surveys/store/selectors";
import { hasAdminAccess } from "@src/utils/rights";

import {
  fetchPulsePollResourceIfNeeded,
  fetchPulsePollParticipantCountIfNeeded,
  FetchPulsePollParticipantParameters
} from "@store/network/resource/PulsePollResource";

import { InfoCard } from "@components/core/InfoCard/InfoCard";
import { CardSection } from "@components/core/InfoCard/CardSection";
import Spin from "@components/core/Spin";

import { PulsePollQuestionsSection } from "@src/screens/Insights/components/Wizard/Summary/PulsePollQuestionsSection";
import ChannelSummarySection from "@src/screens/Insights/components/Wizard/Summary/ChannelSummarySection";
import { AudienceSection } from "@src/screens/Insights/components/Wizard/Summary/AudienceSection";
import { AccessSection } from "@src/screens/Insights/components/Wizard/Summary/AccessSection";

import { Store } from "hyphen-lib/dist/util/store/Store";
import { PulsePollResource } from "hyphen-lib/dist/domain/resource/PulsePollResource";
import { PulsePollInfoResource } from "hyphen-lib/dist/domain/resource/PulsePollInfoResource";
import { ParticipantCountResource } from "hyphen-lib/dist/domain/resource/participants/ParticipantCountResource";
import { AccessResource } from "hyphen-lib/dist/domain/access/AccessResource";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";

import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { isNotNullNorUndefined, getOr } from "hyphen-lib/dist/lang/Objects";
import { getPulsePollAccesses } from "../../store/selectors";
import { pollReportActionCreators } from "../../store/actions";

export interface MatchParams {
  templateId: string;
}
interface PulsePollsDetailsProps {
  templateId: string;
  pulsePollResource: Loadable<PulsePollResource>;
  participantCount: Loadable<ParticipantCountResource>;
  accesses: AccessResource[];
  channels: CompanyResource["channels"];
  hasPollAdminRight: boolean;
}

interface PulsePollsDetailsActionProps {
  fetchPulsePollResource: (templateId: string) => void;
  fetchPulsePollParticipantCountResource: (
    fetchPulsePollparticipantCountParams: FetchPulsePollParticipantParameters
  ) => void;
  fetchPulsePollAccesses: (templateId: string) => void;
}

type Props = PulsePollsDetailsProps & PulsePollsDetailsActionProps;
export class PulsePollsDetails extends React.Component<Props> {
  componentDidMount() {
    const { templateId } = this.props;
    this.props.fetchPulsePollResource(templateId);
    this.props.fetchPulsePollAccesses(templateId);
  }
  componentDidUpdate() {
    const { templateId, pulsePollResource } = this.props;
    if (Loadable.isLoaded(pulsePollResource)) {
      const audience = pulsePollResource.value.audience;
      this.props.fetchPulsePollParticipantCountResource({
        templateId, audience,
      });
    }
  }
  render() {
    const { pulsePollResource, participantCount, accesses, channels, templateId, hasPollAdminRight } = this.props;

    if (Loadable.isNotLoaded(pulsePollResource)) {
      return (
        <PulsePollDetailsSection>
          <Spin/>
        </PulsePollDetailsSection>
      );
    }
    const pulsePoll = pulsePollResource.value;

    const settingsData = [
      {
        label: "Category",
        value: pulsePoll.category,
      },
      {
        label: "Show results to voters",
        value: pulsePoll.displayResults ? "Yes" : "No",
      },
      {
        label: "Include in Favorability",
        value: pulsePoll.isRatingQuestion ? "Yes" : "No",
      },
      {
        label: "Include in eNPS calculation",
        value: pulsePoll.isNPSQuestion ? "Yes" : "No",
      },
      {
        label: "Publish next on",
        value: "" + isNotNullNorUndefined(pulsePoll.nextOccurrence) ?
          moment(getOr(pulsePoll.nextOccurrence, new Date())).format("DD/MM/YYYY h:mm") : "",
      },
    ];
    const isSentimentAnalysisFlagUndefined = isNullOrUndefined(pulsePoll.isSentimentAnalysisQuestion);
    return (
      <PulsePollDetailsSection>
        <InfoCard
          headerStyle="emphasize"
          title="Settings"
          link={hasPollAdminRight ? `/pulsePolls/edit/${templateId}/settings` : undefined}
          size="large"
          translate="yes"
        >
          <CardSection data={settingsData} />
          <ChannelSummarySection
            allowedChannels={channels}
            channels={pulsePoll.channels}
          />
        </InfoCard>

        <InfoCard
          headerStyle="emphasize"
          title="Question"
          link={hasPollAdminRight ? `/pulsePolls/edit/${templateId}/question` : undefined}
          size="large"
          translate="yes"
        >
          <PulsePollQuestionsSection
            question={pulsePoll.question}
            questionType={pulsePoll.questionType}
            data-cy="questions-section"
          />
          <CardSection data={
            [
              {
                label: "Allow to comment",
                value: pulsePoll.allowComment ? "Yes" : "No",
              },
              {
                label: "Include in sentiment",
                value: isSentimentAnalysisFlagUndefined ? "Yes" : pulsePoll.isSentimentAnalysisQuestion ? "Yes" : "No",
              },
            ]
          } />
        </InfoCard>

        <InfoCard
          headerStyle="emphasize"
          title="Audience"
          link={hasPollAdminRight ? `/pulsePolls/edit/${templateId}/audience` : undefined}
          size="large"
          translate="yes"
        >
          <AudienceSection
            participantsCount={participantCount}
            data-cy="audience-section"
          />
        </InfoCard>

        <InfoCard
          headerStyle="emphasize"
          title="Access"
          link={hasPollAdminRight ? `/pulsePolls/edit/${templateId}/access` : undefined}
          size="large"
          translate="yes"
        >
          <AccessSection
            accesses={accesses}
            data-cy="accesses-section"
          />
        </InfoCard>
      </PulsePollDetailsSection>

    );

  }
}

const PulsePollDetailsSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  div:first-child {
    margin-top: 0px;
  }
`;

function mapStateToProps(
  state: State,
  { location, match }: RouteComponentProps<MatchParams>
): PulsePollsDetailsProps {
  const {
    params: { templateId },
  } = match;

  const pulsePollResourceElement = getResourceById(
    state,
    PulsePollResource.TYPE,
    templateId
  );
  const pulsePollResource = Store.Element.toLoadable(pulsePollResourceElement);

  const pulsePollInfoElement = getResourceById(
    state,
    PulsePollInfoResource.TYPE,
    templateId
  );
  const hasPollAdminRight = Store.Element.isLoaded(pulsePollInfoElement) &&
    hasAdminAccess(pulsePollInfoElement.value.role);

  return {
    templateId,
    pulsePollResource,
    participantCount: Loadable.flatMap(
      pulsePollResource,
      loadedPulsePoll => Store.Element.toLoadable(
        getResourceById(
          state,
          ParticipantCountResource.TYPE,
          ParticipantCountResource.generateKey(templateId, loadedPulsePoll.audience)
        )
      )
    ),
    hasPollAdminRight,
    accesses: getPulsePollAccesses(state),
    channels: getChannels(state),
  };

}

const mapDispatchToProps = {
  fetchPulsePollResource: fetchPulsePollResourceIfNeeded,
  fetchPulsePollParticipantCountResource: fetchPulsePollParticipantCountIfNeeded,
  fetchPulsePollAccesses: pollReportActionCreators.fetchPulsePollAccesses,
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PulsePollsDetails));
