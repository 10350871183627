// @ts-nocheck
import { getOr } from "hyphen-lib/dist/lang/Objects";
import React from "react";
import styled from "styled-components";

interface OpenIconProps {
  rotateBy?: number;
}

/* eslint-disable max-len */
export default function ToggleOpenIconSVG(props: OpenIconProps) {
  return (
    <SvgContainer>
      <svg
        style={{
          transform: `rotate(${getOr(props.rotateBy,0)}deg)`
        }}
        width="16px"
        height="16px"
        viewBox="0 0 10 10"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <title>Icons / Chevron down</title>
        <defs>
          <path
            d="M7.14270409,10 C6.92329119,10 6.70387829,9.91833282 6.53674738,9.75583181 L2.25133919,5.5891391 C1.91622027,5.26330373 1.91622027,4.73663377 2.25133919,4.4107984 L6.53674738,0.244105694 C6.8718663,-0.0817296758 7.41354189,-0.0817296758 7.74866081,0.244105694 C8.08377973,0.569941064 8.08377973,1.09661102 7.74866081,1.42244639 L4.06920934,4.99996875 L7.74866081,8.57749111 C8.08377973,8.90332648 8.08377973,9.42999644 7.74866081,9.75583181 C7.58152989,9.91833282 7.36211699,10 7.14270409,10"
            id="path-1"
          />
        </defs>
        <g
          id="Icons-/-Chevron-down"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1" />
          </mask>
          <use
            id="Icons/return"
            fill="#FFFFFF"
            transform="translate(5.000000, 4.999865) rotate(-90.000000) translate(-5.000000, -4.999865) "
            xlinkHref="#path-1"
          />
        </g>
      </svg>
    </SvgContainer>
  );
}

const SvgContainer = styled.span`
  cursor: pointer;
  &:hover{
    border-radius:50%;
    background-color:rgba(255, 255, 255, 0.08);
    padding:0.5em;
  }
`;
/* eslint-disable max-len */
