import React, { FC } from "react";
import styled from "styled-components";
import { Icon } from "antd";
import { TooltipFormatterContextObject } from "highcharts";
import Palette from "@src/config/theme/palette";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { isEmpty } from "hyphen-lib/dist/lang/Arrays";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { TOOLTIP_BACKGROUND_COLOR, FavorabilityStatus, TOOLTIP_HIGHLIGHTED_BACKGROUND_COLOR } from "./utils";
import { Trans } from "react-i18next";

export interface TooltipProps {
  points: TooltipFormatterContextObject[];
}

const Tooltip: FC<TooltipProps> = ({ points }) => {
  const [{ point: { name: title }}] = points;
  const sanitizedTitle = title.split("#")[2];
  return (
    <TooltipContainer>
      <TooltipTitle><Trans data-jest="tooltip-title">{sanitizedTitle}</Trans></TooltipTitle>
      {
        points.map((point) => {
          const name = point.series.name === "Not Specified" ? "Favorability" : point.series.name;
          const isHovered = (point.series as any).state === "hover";
          const [, ...segment] = name.split(":");
          const { point: { name: nameWithPrefix }} = point;
          const pointStatus = nameWithPrefix.split("#")[0];

          return (
            <TooltipFavourability key={point.key} highlighted={isHovered && name !== "Favorability"}>
              { name !== "Favorability" &&
                <SegmentMarker
                  color={point.color as string} />
              }
              <SegmentName>
                <Trans data-jest="segment-name">{getSegmentName(name, segment)}</Trans>
              </SegmentName>
              <FavorabilityScore data-jest="score">
                {point.y ? `${point.y}%` : getIcon(pointStatus)}
              </FavorabilityScore>
            </TooltipFavourability>
          );
        })
      }
    </TooltipContainer>
  );
};

function getSegmentName(name: string, segment: Optional<string>[]) {
  if (name.includes("null")) {
    return "Not Specified";
  }
  if (not(isEmpty(segment))) {
    return segment.join(" ").replace("--1", "+");
  }
  return name;
}

const getIcon = (pointStatus: string) => {
  const IconLookup: Dictionary<JSX.Element | null> = {
    [FavorabilityStatus.FILTERED] : <Icon type="eye-invisible" data-cy="anonymityIcon" data-jest="anonymityIcon" />,
    [FavorabilityStatus.NO_DATA] : <NoData data-jest="no-data"><Trans>No Data</Trans></NoData>,
    [FavorabilityStatus.SCORE]: null,
  };

  return IconLookup[pointStatus] || null;
};

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px 0;
  background-color: ${TOOLTIP_BACKGROUND_COLOR};
`;

const SegmentMarker = styled.div<{color: string}>`
  display: inline;
  margin-right: 4px;
  width: 10px;
  height: 10px;
  border: thin solid #f0f0f0;
  border-radius: 50%;
  background-color: ${props => props.color};
`;
const TooltipTitle = styled.div`
  padding: 0 15px 10px 15px;
  color: #fff;
  font-size: 16px;
  border-bottom: 1px solid #44467A;
`;

const TooltipFavourability = styled.div<{highlighted: boolean}>`
  display: flex;
  padding: 5px 15px 5px 15px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #44467A;
  ${props => props.highlighted ? "background-color: " + TOOLTIP_HIGHLIGHTED_BACKGROUND_COLOR + ";" : ""};
`;

const SegmentName = styled.div`
  white-space: normal;
  overflow-wrap: break-word;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #FFF;
  width: 100px;
`;

const FavorabilityScore = styled.div`
  font-size: 16px;
  color: ${Palette.aquaBlue};
`;

const NoData = styled.div`
  font-size: 12px;
  color: ${Palette.aquaBlue};
`;

// const StyledImage = styled.img`
//   opacity: 1;
//   background-color: #fff;
//   height: 25px;
// `;

export default Tooltip;
