import React from "react";
import styled from "styled-components";
import { Trans } from "react-i18next";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { CommentsOverviewResource } from "hyphen-lib/dist/domain/resource/report/CommentsOverviewResource";
import { Word } from "hyphen-lib/dist/business/calculation/sentiment/Words";
import { Heading } from "@src/components/core/Typography";
import { Tooltip, Empty, Icon } from "antd";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import CommentsSentimentChart from "@src/screens/Insights/Survey/components/CommentsSentimentChart";
import CommonWords from "@src/screens/Insights/Survey/components/CommonWords";
import Palette from "@src/config/theme/palette";
import { Sentiment } from "hyphen-lib/dist/domain/common/ComputationTypes";

interface Props {
  readonly netSentimentScore: Optional<number>;
  readonly numberOfComments: number;
  readonly sentiments: CommentsOverviewResource.Sentiments;
  readonly words: Word[];
  readonly onWordClick?: (word: string, sentiment: string) => void;
}

function SentimentDistribution(props: Props) {
  const { numberOfComments, sentiments, words, onWordClick } = props;
  const commentsInTotal =
    sentiments[Sentiment.POSITIVE] +
    sentiments[Sentiment.NEUTRAL] +
    sentiments[Sentiment.NEGATIVE];


  return (
    commentsInTotal !== 0 ? (
      <SentimentOverviewContainer data-cy="polls_sentimentsText">
        <Heading size={"medium"}>
          <Trans>Sentiment</Trans>
          <Tooltip
            title={<Trans>
              Based on percentage of positive comments minus percentage of negative comments
            </Trans>}
          >
            <StyledInfoIcon
              className="block--print-hide"
              type="info-circle"
              data-cy="comment_sentimentTootltip"
            />
          </Tooltip>
        </Heading>
        <CommentsSentimentChart sentiments={sentiments} />
        {numberOfComments === 0 && (
          <Empty description={<Trans>There are no comments to calculate sentiments</Trans>} />
        )}
        {isNotEmptyArray(words) && (
          <>
            <Heading size="small" weight="bold" translate="yes">
              Commonly mentioned words
            </Heading>
            <HelpText><Trans>Click on the words to filter the list below.</Trans></HelpText>
            <CommonWordsContainer>
              <CommonWords words={words} onWordClick={onWordClick} />
            </CommonWordsContainer>
          </>
        )}
      </SentimentOverviewContainer>
    ) : <></>
  );
}

export default SentimentDistribution;

const SentimentOverviewContainer = styled.div`
  font-family: Lato;
`;

const iconStyle = `
  position: relative;
  font-size: 14px;
`;

const StyledInfoIcon = styled(Icon)`
  ${iconStyle};
  top: -2px;
  margin-left: 4px;
`;

const CommonWordsContainer = styled.div`
  margin-top: 16px;
`;

export const HelpText = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  color: ${Palette.bluishGrey};
`;
