import { call, takeLatest } from "@redux-saga/core/effects";
import { select, put } from "redux-saga/effects";
import { freeze, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";

import { replaceLocation } from "@src/utils/locations";
import {
  CleanResourceAction,
  actionCreators as networkActionCreators
} from "@src/store/network/actions";
import { VoiceFlaggedPostReportResource } from "hyphen-lib/dist/domain/resource/voice/VoiceFlaggedPostReportResource";
import {
  VoiceFlaggedCommentReportResource
} from "hyphen-lib/dist/domain/resource/voice/VoiceFlaggedCommentReportResource";
import notificationActionCreators, {
  ShowNotificationAction
} from "@store/notifications/actions";
import { VoiceReportsStateProps } from "./reducers";
import { getEmployeeVoiceReportsState } from "./selectors";
import {
  VoiceReportsActions,
  ModifyListAction,
  voiceReportsActionCreators
} from "./actions";
import * as NotificationFactory from "@src/store/notifications/notification-factory";

export const voiceReportsSagas = freeze([
  takeLatest(VoiceReportsActions.MODIFY_LIST, updateLocationUrl),
  takeLatest(VoiceReportsActions.UNFLAG_POST, unflagPost),
  takeLatest(
    VoiceReportsActions.UNFLAG_POST_SUCCESS,
    notifyAfterUnflaggingPostAndResetResources
  ),
  takeLatest(VoiceReportsActions.ARCHIVE_POST, archivePost),
  takeLatest(
    VoiceReportsActions.ARCHIVE_POST_SUCCESS,
    notifyAfterArchivingPostAndResetResources
  ),

  takeLatest(VoiceReportsActions.UNFLAG_COMMENT, unflagComment),
  takeLatest(
    VoiceReportsActions.UNFLAG_COMMENT_SUCCESS,
    notifyAfterUnflaggingCommentAndResetResources
  ),
  takeLatest(VoiceReportsActions.ARCHIVE_COMMENT, archiveComment),
  takeLatest(
    VoiceReportsActions.ARCHIVE_COMMENT_SUCCESS,
    notifyAfterArchivingCommentAndResetResources
  ),
  takeLatest(
    [
      VoiceReportsActions.UNFLAG_COMMENT_ERROR,
      VoiceReportsActions.UNFLAG_POST_ERROR,
      VoiceReportsActions.ARCHIVE_COMMENT_ERROR,
      VoiceReportsActions.ARCHIVE_POST_ERROR,
    ],
    handleError
  ),
]);

export function* updateLocationUrl({
  payload: { filter, sort, page },
}: ModifyListAction) {
  yield call(replaceLocation, {
    filter,
    sort,
    page: page.number,
  });
}

export function* notifyAfterUnflaggingPostAndResetResources() {
  yield put(voiceReportsActionCreators.closeAreYouSureModal());
  yield put<ShowNotificationAction>(
    notificationActionCreators.displayNotification(
      NotificationFactory.success(
        "Post unflagged successfully",
        "",
        4.5
      )
  ));
  yield put<CleanResourceAction>(
    networkActionCreators.cleanResource(VoiceFlaggedPostReportResource.TYPE)
  );
}

export function* notifyAfterArchivingPostAndResetResources() {
  yield put(voiceReportsActionCreators.closeAreYouSureModal());
  yield put<ShowNotificationAction>(
    notificationActionCreators.displayNotification(
      NotificationFactory.success(
        "Post archived successfully",
        "",
        4.5
      )
    )
  );
  yield put<CleanResourceAction>(
    networkActionCreators.cleanResource(VoiceFlaggedPostReportResource.TYPE)
  );
}

export function* unflagPost() {
  const { selectedFlaggedResource }: VoiceReportsStateProps = yield select(
    getEmployeeVoiceReportsState
  );
  if (isNotNullNorUndefined(selectedFlaggedResource)) {
    yield put(
      voiceReportsActionCreators.onUnflagPostRequest({
        id: selectedFlaggedResource._id,
      })
    );
  }
}

export function* archivePost() {
  const { selectedFlaggedResource }: VoiceReportsStateProps = yield select(
    getEmployeeVoiceReportsState
  );
  if (isNotNullNorUndefined(selectedFlaggedResource)) {
    yield put(
      voiceReportsActionCreators.onArchiveFlaggedPostRequest({
        id: selectedFlaggedResource._id,
      })
    );
  }
}

export function* notifyAfterUnflaggingCommentAndResetResources() {
  yield put(voiceReportsActionCreators.closeAreYouSureModal());
  yield put<ShowNotificationAction>(
    notificationActionCreators.displayNotification(
      NotificationFactory.success(
        "Comment unflagged successfully",
        "",
        4.5
      )
  ));
  yield put<CleanResourceAction>(
    networkActionCreators.cleanResource(VoiceFlaggedCommentReportResource.TYPE)
  );
}

export function* notifyAfterArchivingCommentAndResetResources() {
  yield put(voiceReportsActionCreators.closeAreYouSureModal());
  yield put<ShowNotificationAction>(
    notificationActionCreators.displayNotification(
      NotificationFactory.success(
        "Comment archived successfully",
        "",
        4.5
      )
  ));
  yield put<CleanResourceAction>(
    networkActionCreators.cleanResource(VoiceFlaggedCommentReportResource.TYPE)
  );
}

export function* unflagComment() {
  const { selectedFlaggedResource }: VoiceReportsStateProps = yield select(
    getEmployeeVoiceReportsState
  );
  if (isNotNullNorUndefined(selectedFlaggedResource)) {
    yield put(
      voiceReportsActionCreators.onUnflagCommentRequest({
        id: selectedFlaggedResource._id,
      })
    );
  }
}

export function* archiveComment() {
  const { selectedFlaggedResource }: VoiceReportsStateProps = yield select(
    getEmployeeVoiceReportsState
  );
  if (isNotNullNorUndefined(selectedFlaggedResource)) {
    yield put(
      voiceReportsActionCreators.onArchiveFlaggedCommentRequest({
        id: selectedFlaggedResource._id,
      })
    );
  }
}

export function* handleError() {
  yield put(voiceReportsActionCreators.closeAreYouSureModal());
  yield put<ShowNotificationAction>(
    notificationActionCreators.displayNotification(
      NotificationFactory.error(
        "Error",
        "Oops, Something went wrong. Please try again",
        4.5
      )
  ));
}
