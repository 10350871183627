// @ts-nocheck
import React from "react";
/* eslint-disable max-len */
export default function Attention() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="26.645556131374178"
      width="28.645556131374178"
    >
      <defs>
        <path
          id="attention-path-0"
          opacity="1"
          fillRule="evenodd"
          d="M6.198267569270494,23.82277806568709 C4.678677731370763,23.82277806568709 3.714193813686306,22.194975395427647 4.444075033587491,20.86214881995592 L12.568585530004086,6.026086174325618 C13.327491160105136,4.640258501967176 15.318064971269042,4.640258501967176 16.076970601370093,6.026086174325618 L24.201481097786687,20.86214881995592 C24.93136231768787,22.194975395427647 23.966878400003417,23.82277806568709 22.447288562103683,23.82277806568709 L6.198267569270494,23.82277806568709Z"
        />
        <path
          id="attention-path-1"
          opacity="1"
          fillRule="evenodd"
          d="M14.322778065687089,10.822778065687089 L14.322778065687089,16.82277806568709 "
        />
        <ellipse
          id="attention-ellipse-2"
          opacity="1"
          rx="0.5"
          ry="0.5"
          cx="14.322778065687089"
          cy="20.32277806568709"
        />
      </defs>
      <g opacity="1">
        <g opacity="1">
          <use
            xlinkHref="#attention-path-0"
            fillOpacity="0"
            stroke="rgb(223,56,104)"
            strokeOpacity="1"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g opacity="1">
          <use
            xlinkHref="#attention-path-1"
            fillOpacity="0"
            stroke="rgb(223,56,104)"
            strokeOpacity="1"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g opacity="1">
          <use
            xlinkHref="#attention-ellipse-2"
            fill="rgb(223,56,104)"
            fillOpacity="1"
          />
          <use
            xlinkHref="#attention-ellipse-2"
            fillOpacity="0"
            stroke="rgb(223,56,104)"
            strokeOpacity="1"
            strokeWidth="1.2"
            strokeLinecap="square"
            strokeLinejoin="miter"
          />
        </g>
      </g>
    </svg>
  );
}
/* eslint-disable max-len */