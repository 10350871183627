import { State } from "@store/types";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { BatarangDryRunResultsResource } from "hyphen-lib/dist/domain/resource/batarang/BatarangDryRunResultsResource";
import { BatarangApplyResultsResource } from "hyphen-lib/dist/domain/resource/batarang/BatarangApplyResultsResource";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import { AssetUpload } from "./batarangScriptsReducers";

export function getExecutionInDryRunModeResults(state: State): Optional<Loadable<BatarangDryRunResultsResource>> {
  return state.insights_batarang_scripts.dryRunExecutionResults;
}

export function getExecutionResults(state: State): Optional<Loadable<BatarangApplyResultsResource>> {
  return state.insights_batarang_scripts.executionResults;
}

export function getError(state: State): Optional<string> | Optional<WrongEntityException.GlobalError> {
  return state.insights_batarang_scripts.error;
}

export function getAssetUpload(state: State): Optional<AssetUpload> {
  return state.insights_batarang_scripts.assetUpload;
}
