import React from "react";
import styled from "styled-components";
import { Icon, Tooltip } from "antd";
import { Trans } from "react-i18next";
import Palette from "@src/config/theme/palette";
import Heading from "@components/core/Typography/Heading";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { ComputedHeatMap } from "@hyphen-lib/domain/aggregate/calculation/ComputedHeatMap";

interface Props {
  heatMap: ComputedHeatMap;
  readonly heatmapTheme?: Dictionary<any>;
}

export default function HeatmapHeader(props: Props) {
  return (
    <HeatmapHeaderContainer>
      <HeadingContainer>
        <Heading>
          <Trans>Favorability heatmap</Trans>
          <Tooltip title={<Trans>Percentage of the favorable votes out of all votes</Trans>}>
            <StyledInfo type="info-circle"/>
          </Tooltip>
        </Heading>
      </HeadingContainer>
      <StyledSurveySummary>
        {props.heatMap.numberOfQuestions} 
        <Trans>questions</Trans> • {props.heatMap.numberOfVotes} <Trans>answers</Trans>
      </StyledSurveySummary>
    </HeatmapHeaderContainer>
  );
}

const HeatmapHeaderContainer = styled.div`
  padding: 0 32px;
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledSurveySummary = styled.div`
  font-family: Lato;
  margin-top: 16px;
  color: ${Palette.bluishGrey};
`;

const StyledInfo = styled(Icon)`
  margin-left: 8px;
  top: -2px;
  position: relative;
  font-size: 14px;
`;
