import { put, all, takeEvery } from "redux-saga/effects";
import notificationActionCreators, { ShowNotificationAction } from "@store/notifications/actions";
import { freeze } from "hyphen-lib/dist/lang/Objects";
import { Dictionary } from "@hyphen-lib/domain/structure/Dictionary";
import {
  actionCreators as networkActionCreators,
  CleanResourceAction,
  NetworkEventSuccessAction,
  NetworkEventErrorAction,
} from "@store/network/actions";
import { ActionPlanTemplateResource } from "hyphen-lib/dist/domain/resource/action/ActionPlanTemplateResource";
import { RawHeatMapResource } from "hyphen-lib/dist/domain/resource/report/RawHeatMapResource";
import { settingsActionCreators, SettingsActionTypes } from "./actions";
import * as NotificationFactory from "@src/store/notifications/notification-factory";

function* onSettingsUpdatedSuccessfully() {
  yield put<ShowNotificationAction>(
    notificationActionCreators.displayNotification(
      NotificationFactory.success(
        "Settings updated successfully",
        "",
        4.5
      )
  ));
  
  yield* cleanResourcesAfterSettingsUpdate();
}

function* cleanResourcesAfterSettingsUpdate() {
  yield put<CleanResourceAction>
  (networkActionCreators.cleanResource(RawHeatMapResource.TYPE));
}

function* onSettingsUpdateError({ payload }: NetworkEventErrorAction) {
  yield all(payload.error.map((err: any) => (
    put<ShowNotificationAction>(
      notificationActionCreators.displayNotification(
        NotificationFactory.error(
          "Uh-oh!",
          err.reason || "Something went wrong in trying to update your settings. Please try again.",
          4.5
        )
    ))
  )));
}

function* fetchActionPlanTemplates({ meta }: NetworkEventSuccessAction) {
  yield put(settingsActionCreators.fetchActionTemplates());
  const { mode } = meta;
  const actionMapper: Dictionary<any> = {
    add : "added",
    edit : "saved",
    exclude : "excluded",
    include : "included",
    remove : "removed",
  };
  yield put<ShowNotificationAction>(
    notificationActionCreators.displayNotification(
      NotificationFactory.success(
        `Action template ${actionMapper[mode]} successfully`,
        "",
        4.5
      )
  ));

  yield put<CleanResourceAction>
  (networkActionCreators.cleanResource(ActionPlanTemplateResource.TYPE));
}

export const settingsSagas = freeze([
  takeEvery(
    [
      SettingsActionTypes.UPDATE_COMPANY_SUCCESS,
      SettingsActionTypes.UPDATE_APP_SETTINGS_SUCCESS,
    ],
    onSettingsUpdatedSuccessfully
  ),
  takeEvery(
    [SettingsActionTypes.UPDATE_USER_NOTIFICATION_SUCCESS],
    cleanResourcesAfterSettingsUpdate
  ),
  takeEvery(
    [SettingsActionTypes.UPDATE_COMPANY_ERROR, SettingsActionTypes.UPDATE_APP_SETTINGS_ERROR],
    onSettingsUpdateError
  ),
  takeEvery(
    [
      SettingsActionTypes.ADD_ACTION_TEMPLATE_SUCCESS,
      SettingsActionTypes.UPDATE_ACTION_TEMPLATE_SUCCESS,
      SettingsActionTypes.EXCLUDE_ACTION_TEMPLATE_SUCCESS,
      SettingsActionTypes.INCLUDE_ACTION_TEMPLATE_SUCCESS,
      SettingsActionTypes.REMOVE_ACTION_TEMPLATE_SUCCESS,
    ],
    fetchActionPlanTemplates
  ),
]);
