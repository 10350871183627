import CompareValue from "@components/core/CompareValue";
import React from "react";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { Trans } from "react-i18next";
interface ComparisonsProps {
  readonly areComparisonsVisible: boolean;
  readonly comparisonLabel: Optional<string>;
  readonly comparisonValue: Optional<number>;
  readonly averageComparisonLabel: Optional<string>;
  readonly averageComparisonValue: Optional<number>;
  readonly showSecondComparison: boolean;
  readonly "data-cy"?: string;
  readonly percentage?: boolean;
}

export function ComparisonsOverview({
  areComparisonsVisible,
  comparisonLabel,
  comparisonValue,
  averageComparisonLabel,
  averageComparisonValue,
  showSecondComparison,
  "data-cy": dataCy,
  percentage = true,
}: ComparisonsProps) {

  if (not(areComparisonsVisible)) {
    return null;
  }

  const isAverageComparisonAvailable =
    isNotNullNorUndefined(averageComparisonValue) &&
    isNotNullNorUndefined(averageComparisonLabel);
  const isSecondComparisonAvailable =
    showSecondComparison &&
    isNotNullNorUndefined(comparisonValue) &&
    isNotNullNorUndefined(comparisonLabel);

  if (not(isAverageComparisonAvailable) && not(isSecondComparisonAvailable)) {
    return null;
  }

  return (
    <CompareContainer>
      <CompareValueContainer data-cy={`vsAverage_${dataCy}`}>
        {
          isAverageComparisonAvailable &&
          <>
            <CompareValue
              compare={averageComparisonValue}
              percentage={percentage}
            /> <Trans>vs {averageComparisonLabel}</Trans>
          </>
        }
      </CompareValueContainer>
      <Divider />
      <CompareValueContainer data-cy={`vsPrevious_${dataCy}`}>
        {
          isSecondComparisonAvailable &&
          <>
            <CompareValue
              compare={comparisonValue}
              percentage={percentage}
            /> <Trans>vs {comparisonLabel}</Trans>
          </>
        }
      </CompareValueContainer>
    </CompareContainer>
  );
}

const CompareContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 52px;
  margin-left: 40px;
  margin-top: 15px;
  color: ${Palette.bluishGrey};
  font-size: 12px;
  width: 160px;
`;

const CompareValueContainer = styled.div`
  // height: 21px;
  span {
    font-size: 14px;
  }
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${Palette.veryLightBlue};
  margin: 4px 0;
`;
