import { Switch, Route } from "react-router-dom";

import { VoicePostsList } from "./VoicePosts/Containers/VoicePostsList";
import { VoiceCommentsList } from "./VoiceComments/containers/VoiceCommentsList";
import { VoiceFlaggedPostsList } from "./VoiceFlaggedPosts/containers/VoiceFlaggedPostsList";
import { VoiceFlaggedCommentsList } from "./VoiceFlaggedComments/containers/VoiceFlaggedCommentsList";

export function VoiceReportsRoutes() {
  return (
    <Switch>
      <Route path="/voice/reports/posts" component={VoicePostsList} />
      <Route path="/voice/reports/comments" component={VoiceCommentsList} />
      <Route path="/voice/reports/flags/posts" exact component={VoiceFlaggedPostsList} />
      <Route path="/voice/reports/flags/comments" exact component={VoiceFlaggedCommentsList} />
    </Switch>
  );
}
