import React from "react";
import Select from "@components/core/Select";
import { SelectProps } from "antd/lib/select";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { debounce } from "lodash";
import hoistNonReactStatics from "hoist-non-react-statics";
import { withFieldLabels } from "../withFieldLabels";
import { sanitizeSegmentLabel } from "hyphen-lib/dist/domain/common/Dimensions";

const { Option } = Select;

interface SelectLargeProps {
  numberOfValuesToShow: number;
  data: string[];
  onChange: (value: any) => void;
  selectedValues: string[] | string;
  isOptionDisabled?: (option: string) => boolean;
  allowSelectionByPaste?: boolean;
  optionsToPrefix?: React.ReactNode[];
  optionsToSuffix?: React.ReactNode[];
  dataCy?: string;
}

interface StateProps {
  dataToShow: string[];
}

type Props = SelectLargeProps & SelectProps;

class SelectLargeComponent extends React.Component<Props, StateProps> {

  constructor(props: Props) {
    super(props);
    const { data, numberOfValuesToShow } = props;
    const limit = this.getLimit(data, numberOfValuesToShow);
    this.state = {
      dataToShow: data.slice(0, limit),
    };
    this.onSearch = debounce(this.onSearch, 500);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.data.length !== this.props.data.length) {
      const { data, numberOfValuesToShow } = this.props;
      const limit = this.getLimit(data, numberOfValuesToShow);
      this.setState({
        dataToShow: data.slice(0, limit),
      });
    }
  }

  getLimit = (data: string[], numberOfValuesToShow: number) => {
    return data.length < numberOfValuesToShow ? data.length : numberOfValuesToShow;
  };

  onSearch = (value: string) => {

    const { data, numberOfValuesToShow, allowSelectionByPaste = false } = this.props;
    if (allowSelectionByPaste && (value.includes(",") || value.includes(" "))) {
      const emails = value.split(/(?:,| )+/).map(val => val.trim()).filter(val => val.length > 0);
      const emailsInData = emails.filter((email) => data.includes(email));
      const { onChange, selectedValues } = this.props;
      const newValues = [...selectedValues];
      emailsInData.forEach((email) => {
        if (!newValues.includes(email)) {
          newValues.push(email);
        }
      });
      onChange(newValues);
      return;
    }
    const dataToShow = data.filter((option) => option.toLowerCase().includes(value.toLowerCase()));
    const limit = this.getLimit(dataToShow, numberOfValuesToShow);
    this.setState({ dataToShow: dataToShow.slice(0, limit) });
  };

  onSelect = () => {
    const { data, numberOfValuesToShow } = this.props;
    const limit = this.getLimit(data, numberOfValuesToShow);
    this.setState({
      dataToShow: data.slice(0, limit),
    });
  };

  checkIfOptionDisabled = (option: string): boolean => {
    const { isOptionDisabled } = this.props;
    if (isNotNullNorUndefined(isOptionDisabled)) {
      return isOptionDisabled(option);
    }
    return false;
  };

  render() {
    const {
      numberOfValuesToShow,
      data,
      selectedValues,
      isOptionDisabled,
      optionsToPrefix,
      optionsToSuffix,
      dataCy,
      ...rest
    } = this.props;
    const { dataToShow } = this.state;
    return (
      //  @ts-ignore
      <Select
        data-cy={dataCy}
        filterOption={false}
        onSearch={this.onSearch}
        onSelect={this.onSelect}
        value={selectedValues}
        {...rest}
      >
        {
          isNotNullNorUndefined(optionsToPrefix) &&
          optionsToPrefix.map(option => option)
        }
        {
          dataToShow.map((value: string) => {
            const toDisplayValue = sanitizeSegmentLabel(value);
            return (
              <Option
                disabled={this.checkIfOptionDisabled(value)}
                key={value}
                value={value}>
                {toDisplayValue}
              </Option>
            );
          })
        }
        {
          isNotNullNorUndefined(optionsToSuffix) &&
          optionsToSuffix.map(option => option)
        }
      </Select>
    );
  }
}

export default hoistNonReactStatics(withFieldLabels(SelectLargeComponent), Select);
