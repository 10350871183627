/* tslint:disable:no-console jsx-no-lambda */
import { RouteComponentProps, withRouter } from "react-router";
import React from "react";
import { Trans } from "react-i18next";
import { ChangeEvent } from "react";
import styled from "styled-components";
import Spin from "@components/core/Spin";
import { SearchBar } from "@src/components/core/SearchBar";
import { ExpandButton } from "@components/core/ExpandButton";
import { SurveyTable, SurveyTableProps } from "@screens/Insights/SurveyList/components/SurveyTable";
import { CustomizationPopover } from "@src/screens/Insights/components/CustomizationPopover";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import { debounce } from "lodash";
import {
  getOr,
  mapOr,
  isNotNullNorUndefined,
  cleanObject,
  mapValues,
  entries,
  isNullOrUndefined
} from "hyphen-lib/dist/lang/Objects";
import { SurveyListFiltersContainer } from "@screens/Insights/SurveyList/containers/SurveyListFilterContainer";
import { NetworkEventSuccessAction } from "src/store/network/actions";
import FilterLabels from "@src/components/core/FilterLabels";
import convertDictToFilters, { clearFilter, clearFilterOption } from "@src/components/core/FilterLabels/utils";
import { Omit } from "hyphen-lib/dist/lang/Types";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { SurveyInfoResource } from "hyphen-lib/dist/domain/resource/SurveyInfoResource";
import AreYouSureModal from "@src/components/core/AreYouSureModal";
import NoResult from "@src/components/core/NoResult";
import { PropMapping } from "@src/utils/parameters";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { SurveyInfosPageParameters } from "@screens/Insights/SurveyList/store/actions";
import { FiltersBackdrop } from "@screens/Insights/components/FiltersBackdrop";
import { Map as ImmutableMap } from "immutable";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { NotificationChannels } from "hyphen-lib/dist/domain/notification/NotificationChannels";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { FetchError } from "../../errors/FetchError";
import { SurveyActionKeyType } from "../../components/Actions";
import { SurveyListContainerActionProps, SurveyListContainerProps, SURVEYS_FILTER_MAPPINGS } from "../";
import { SurveyTypeProps, MargeTagStateProps } from "../../Surveys/store/types";
import { DuplicateSurveyModal } from "./DuplicateSurveyModal";
import { SendReminderModal } from "./SendReminderModal";
import { ActionKeys } from "./cells/ActionCell";

export interface QuestionScoreFlags {
  readonly hasNPSQuestions: boolean;
  readonly hasRatingQuestions: boolean;
}
interface Props extends RouteComponentProps, Omit<SurveyTableProps, "onActionClick">, SurveyTypeProps {
  readonly toggleReminderModalOpen: SurveyListContainerActionProps["toggleReminderModalOpen"];
  readonly toggleDeleteModalOpen: SurveyListContainerActionProps["toggleDeleteModalOpen"];
  readonly toggleCloseModalOpen: SurveyListContainerActionProps["toggleCloseModalOpen"];
  readonly toggleDuplicateModalOpen: SurveyListContainerActionProps["toggleDuplicateModalOpen"];
  readonly sendSurveyReminders: SurveyListContainerActionProps["sendSurveyReminders"];
  readonly deleteSurvey: SurveyListContainerActionProps["deleteSurvey"];
  readonly closeSurvey: SurveyListContainerActionProps["closeSurvey"];
  readonly reminderModalOpen: SurveyListContainerProps["reminderModalOpen"];
  readonly deleteModalOpen: SurveyListContainerProps["deleteModalOpen"];
  readonly closeModalOpen: SurveyListContainerProps["closeModalOpen"];
  readonly duplicateModalOpen: SurveyListContainerProps["duplicateModalOpen"];
  readonly selectedSurveyInfoResource: SurveyListContainerProps["selectedSurveyInfoResource"];
  readonly selectedSurveyResource: SurveyListContainerProps["selectedSurveyResource"];
  readonly channels: CompanyResource["channels"];
  readonly isRequestingSendReminders: SurveyListContainerProps["isRequestingSendReminders"];
  readonly isRequestingDelete: SurveyListContainerProps["isRequestingDelete"];
  readonly isRequestingClose: SurveyListContainerProps["isRequestingClose"];
  readonly duplicateSurvey: SurveyListContainerActionProps["duplicateSurvey"];
  readonly onShowReports: (surveyId: string, flags: QuestionScoreFlags) => any;
  readonly isAttemptingSurveyDuplication: SurveyListContainerProps["isAttemptingSurveyDuplication"];
  readonly showSuccessNotification: (title: string, description: string, duration: number) => any;
  readonly fetchMergeTags: SurveyListContainerActionProps["fetchMergeTags"];
  readonly mergeTags: MargeTagStateProps;
  readonly onModifyParameters: (parameters: Dictionary<any>, mappings?: PropMapping[]) => any;
  readonly existingPage: Store.Page<SurveyInfoResource>;
}

class SurveyListScreenContainer extends React.Component<Props> {

  state = {
    areFiltersVisible: false,
  };

  private onSearchChangeDebounced: (value: any) => void;

  constructor(props: Props) {
    super(props);

    this.onSearchChangeDebounced = debounce(this.updateSearchFilter, 500);
  }

  componentWillUnmount() {
    this.closeModal();
    this.closeDuplicateModal();
    this.closeDeleteModal();
    this.closeCloseModal();
  }

  handleFiltersClick = () => this.setState({
    areFiltersVisible: !this.state.areFiltersVisible,
  });

  onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.onSearchChangeDebounced(event.target.value);
  };

  onDuplicateSurveySuccess = (payload: NetworkEventSuccessAction["payload"]) => {
    const { data } = payload;
    const { history, duplicateModalOpen } = this.props;
    if (duplicateModalOpen) {
      this.closeDuplicateModal();
    }
    if (data) {
      this.props.showSuccessNotification(
        "Survey duplicated successfully",
        "You can find this survey in the Survey List",
        4.5
      );
      history.push(`/surveys/edit/${data._id}/settings`);
    }
  };

  shouldShowLinkSurveyModal = (survey: SurveyInfoResource): boolean => {
    switch (survey.status) {
      case "closed":
      case "launched":
        return true;
      default:
        return false;
    }
  };

  onEdit = (survey: SurveyInfoResource): void => {
    const { history } = this.props;
    if (survey) {
      history.push(`/surveys/edit/${survey._id}/settings`);
    }
  };

  onActionClick = (key: keyof SurveyActionKeyType, data: SurveyInfoResource) => {
    switch (key) {
      case ActionKeys.sendReminder:
        return this.props.toggleReminderModalOpen(true, data);
      case ActionKeys.delete:
        return this.props.toggleDeleteModalOpen(true, data);
      case ActionKeys.close:
        return this.props.toggleCloseModalOpen(true, data);
      case ActionKeys.duplicate:
        if (this.shouldShowLinkSurveyModal(data)) {
          return this.props.toggleDuplicateModalOpen(true, data);
        }
        if (data.status === "draft") {
          return this.props.duplicateSurvey(data._id, this.onDuplicateSurveySuccess, false);
        }
        return this.props.duplicateSurvey(data._id, this.onDuplicateSurveySuccess);
      case ActionKeys.seeReport:
        const { hasNPSQuestions, hasRatingQuestions, _id } = data;
        return this.props.onShowReports(_id, {hasRatingQuestions, hasNPSQuestions});
      case ActionKeys.edit:
        return this.onEdit(data);
    }
  };
  closeModal = () => this.props.toggleReminderModalOpen(false, {} as SurveyInfoResource);
  closeDeleteModal = () => this.props.toggleDeleteModalOpen(false, {} as SurveyInfoResource);
  closeCloseModal = () => this.props.toggleCloseModalOpen(false, {} as SurveyInfoResource);
  closeDuplicateModal = () => this.props.toggleDuplicateModalOpen(false, {} as SurveyInfoResource);

  updateSearchFilter = (value: any) => {
    const {
      onModifyList,
      page,
      sort,
    } = this.props;
    const filter = getOr(this.props.filter, {});
    if (isStringAndNotEmpty(value)) {
      filter.name = value;
    } else {
      delete filter.name;
    }
    const resetPage = { ...page, number: 1 };
    onModifyList({
      filter,
      page: resetPage,
      sort,
    });
  };

  handleApplyFilters = (newFilter: any) => {
    const {
      page,
      sort,
      onModifyList,
      onModifyParameters,
    } = this.props;

    this.setState({ areFiltersVisible: false });

    const resetPage = { ...page, number: 1 };

    onModifyParameters({ filter: newFilter, sort }, SURVEYS_FILTER_MAPPINGS);

    onModifyList({
      filter: cleanObject(newFilter),
      page: resetPage,
      sort,
    });
  };

  renderNoData = () => {
    const { filter } = this.props;

    if (isNotNullNorUndefined(filter)) {
      // in-case of search
      if (isNotNullNorUndefined(filter.name)) {
        return (
          <NoResult
            type="search"
            description="Sorry, we couldn't find any surveys matching your search for the moment."
          />
        );
      }
      // in-case any filters are applied
      else if (Object.keys(filter).length > 0) {
        return (
          <NoResult
            type="filter"
            description="Sorry, we couldn't find any surveys matching your filters for the moment."
          />
        );
      }
      // no filters, meaning no survey is created
      else {
        return (
          <NoResult
            type="data"
            description="Looks like you haven't created a survey yet."
          />
        );
      }
    } else {
      return (
        <NoResult
          type="default"
          description=" "
        />
      );
    }
  };

  onClearFilter = (filterToRemove: string) => {
    const {
      page,
      sort,
      onModifyList,
      onModifyParameters,
    } = this.props;

    const filter = clearFilter(filterToRemove, this.props.filter);
    onModifyParameters({ filter }, SURVEYS_FILTER_MAPPINGS);

    onModifyList({
      filter: cleanObject(filter),
      page,
      sort,
    });
  };

  onClearFilterOption = (filterKey: string, subFilter: string) => {
    const {
      page,
      sort,
      onModifyList,
      onModifyParameters,
    } = this.props;

    const filter = clearFilterOption(filterKey, subFilter, this.props.filter);

    onModifyParameters({ filter }, SURVEYS_FILTER_MAPPINGS);

    onModifyList({
      filter: cleanObject(filter),
      page,
      sort,
    });
  };

  renderFilterLabels = () => {
    const { filter, surveyTypes } = this.props;
    const filters = convertDictToFilters(
      filter,
      {}, /* no dimensions filters */
      surveyTypes
    );

    if (filters.length > 0 ) {
      return (
        <FilterLabels
          filters={filters}
          onClearFilter={this.onClearFilter}
          onClearSubfilter={this.onClearFilterOption}
        />
      );
    } else {
      return null;
    }
  };

  /*
      proxy to store the sort
   */
  handleModifyTable = (parameters: SurveyInfosPageParameters) => {
    const {
      sort: previousSort,
      onModifyList,
      onModifyParameters,
    } = this.props;

    const newSort = {
      ...mapValues(previousSort as any, () => undefined), // explicitly remove old sort
      ...parameters.sort,
    };

    onModifyParameters({ sort: newSort }, SURVEYS_FILTER_MAPPINGS);
    onModifyList(parameters);
  };

  /* this method ensures that only company allowed channels are allowed within the survey. This method is requried
    because when a survey has been imported from i1 to i2, a bunch of survey-level channels have been allowed to remain
    in the survey regardless of the channels allowed by the company. */
  getAllowedChannels = (companyChannels: CompanyResource.Channels, channels?: NotificationChannels) => {
    if (isNullOrUndefined(channels)) {
      return Optional.empty() as NotificationChannels;
    }
    const channelsAllowedInCompany = entries(companyChannels)
      .filter((value, key) =>
        (value.hasOwnProperty("isAllowed") && value.isAllowed) ||
                (isNotNullNorUndefined(key) && !value.hasOwnProperty("isAllowed")))
      .keySeq()
      .toArray();
    return entries(channels)
      .filter((val, key) => isNotNullNorUndefined(key))
      .filter((val, key) => channelsAllowedInCompany.includes(key))
      .toJS() as NotificationChannels;

  };

  render() {
    const {
      total,
      surveyTypes,
      filter,
      reminderModalOpen,
      selectedSurveyInfoResource,
      selectedSurveyResource,
      channels,
      sendSurveyReminders,
      isRequestingSendReminders,
      deleteSurvey,
      deleteModalOpen,
      isRequestingDelete,
      closeSurvey,
      closeModalOpen,
      duplicateModalOpen,
      isRequestingClose,
      isAttemptingSurveyDuplication,
      duplicateSurvey,
      fetchMergeTags,
      mergeTags,
      existingPage,
    } = this.props;
    const { areFiltersVisible } = this.state;

    let nextReminderChannels;
    if (isNotNullNorUndefined(selectedSurveyResource.nextReminderChannels)) {
      nextReminderChannels = (selectedSurveyResource.nextReminderChannels as ImmutableMap<string, any>).toJS();
    }

    return (
      <Container>
        <SearchAndFilterContainer>
          <SearchBar
            placeholder="Search surveys"
            onChange={this.onSearchChange}
            defaultValue={mapOr(filter, f => f.name, "")}
            datacy="surveyList_search"
          />
          <StyledExpandButton
            icon="filter"
            onClick={this.handleFiltersClick}
            translate="yes"
          >
            Filters
          </StyledExpandButton>
          {
            areFiltersVisible &&
            <FiltersBackdrop onClick={this.handleFiltersClick} />
          }
          <CustomizationPopover open={areFiltersVisible} >
            <SurveyListFiltersContainer
              surveyTypes={surveyTypes}
              values={filter}
              onApply={this.handleApplyFilters}
            />
          </CustomizationPopover>
        </SearchAndFilterContainer>
        {this.renderFilterLabels()}
        {
          <Spin size="large" spinning={Store.Page.isLoading(existingPage)}>
            {Store.Page.isInError(existingPage) &&
              <FetchError {...existingPage} resourceType={SurveyInfoResource.TYPE}/>
            }
            {total === 0 ? this.renderNoData()
              :
              (
                <Spin size="large" spinning={isAttemptingSurveyDuplication}>
                  <SurveyTable
                    scroll={{x: "scroll"}}
                    onActionClick={this.onActionClick}
                    {...this.props}
                    onModifyList={this.handleModifyTable}
                  />
                </Spin>
              )}
          </Spin>
        }
        { reminderModalOpen &&
          <SendReminderModal
            title={<Trans>Send a manual reminder</Trans>}
            visible={reminderModalOpen}
            onCancel={this.closeModal}
            name={selectedSurveyInfoResource.get("name")}
            surveyType={selectedSurveyInfoResource.get("type")}
            participation={selectedSurveyInfoResource.get("participation")}
            companyChannels={channels}
            surveyChannels={this.getAllowedChannels(channels, selectedSurveyResource.channels.toJS())}
            nextReminderChannels={this.getAllowedChannels(channels, nextReminderChannels)}
            onSendReminder={sendSurveyReminders}
            isRequestingSendReminders={isRequestingSendReminders}
            fetchMergeTags={fetchMergeTags}
            mergeTags={mergeTags}
            viewReminderHistory={{
              surveyId: selectedSurveyInfoResource.get("_id"),
            }}
            isMagicSurvey={selectedSurveyInfoResource.get("isMagic")}
          />
        }
        { deleteModalOpen &&
          <AreYouSureModal
            visible={deleteModalOpen}
            title="Are you sure?"
            // eslint-disable-next-line max-len
            description="This survey along with the associated data will be removed from Betterworks Engage, and cannot be undone. If this survey is linked to another survey, deleting this will also remove comparison data between the two surveys."
            okLabel={isRequestingDelete ? "Deleting..." : "Delete"}
            cancelLabel="Cancel"
            onOk={deleteSurvey}
            onCancel={this.closeDeleteModal}
            buttonsDisabled={isRequestingDelete}
          />
        }
        { closeModalOpen &&
          <AreYouSureModal
            visible={closeModalOpen}
            title="Are you sure?"
            description="This survey will be closed."
            okLabel={isRequestingClose ? "Closing" : "Close"}
            cancelLabel="Cancel"
            onOk={closeSurvey}
            onCancel={this.closeCloseModal}
            buttonsDisabled={isRequestingClose}
          />
        }
        { duplicateModalOpen &&
          <DuplicateSurveyModal
            visible={duplicateModalOpen}
            title={<Trans>Link Survey?</Trans>}
            onOk={closeSurvey}
            onCancel={this.closeDuplicateModal}
            onDuplicateSurveySuccess={this.onDuplicateSurveySuccess}
            surveyId={selectedSurveyInfoResource.get("_id")}
            duplicateSurvey={duplicateSurvey}
            buttonsDisabled={isAttemptingSurveyDuplication}
          />
        }
      </Container>
    );
  }
}

const SearchAndFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  position: relative;
`;

const StyledExpandButton = styled(ExpandButton)`
  width: 144px;
  margin-left: 16px;
`;

const Container = styled.div`
  background-color: white;
  padding-bottom: 32px;
`;

export const SurveyListScreen = withRouter(SurveyListScreenContainer);
