import React from "react";
import styled from "styled-components";
import { Map } from "immutable";
import {
  QuestionType as QuestionTypeInterface,
  QuestionConfig,
} from "hyphen-lib/dist/domain/common/QuestionType";
import {
  getQuestionTypeLabel,
  QuestionTypeLabel,
  getQuestionTypeFromLabel,
} from "hyphen-lib/dist/business/question/Questions";
import { Paragraph } from "@components/core/Typography";
import { TextArea } from "@components/core/TextArea";
import { FieldLabels } from "@components/core/withFieldLabels";

import { PostCategoryResource } from "hyphen-lib/dist/domain/resource/post/PostCategoryResource";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { getOr, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import withDebounce from "@src/components/core/withDebounce";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { AllErrors } from "@src/utils/formValidations";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { SurveyCategories } from "./SurveyCategories";
import { QuestionType } from "./QuestionType/QuestionType";
import {
  QuestionSettings,
  SettingsFlag,
  QuestionSettingProps,
} from "./QuestionSettings";
import { MultiLanguageFooterStrip } from "@src/screens/Insights/Surveys/components/MultiLanguageFooterStrip";
import { LocaleCodes } from "hyphen-lib/dist/util/locale";
import Button from "@src/components/core/Button";
import {
  getLanguageInformationFromStringifiedTranslation,
  getParsed,
  getTranslatedSegmentFromStringifiedTranslation,
  LabelWithFlag,
} from "@src/utils/translation";
import { SurveyQuestionResource } from "hyphen-lib/dist/domain/resource/SurveyQuestionResource";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import { Trans, TFunction } from "react-i18next";
import { translate } from "@src/utils/i18next";

// @ts-ignore
const DebouncedTextArea = withDebounce(TextArea);

export type AddOrEditQuestionProps =
  | QuestionWithCategoryProps
  | QuestionWithoutCategoryProps;

export type QuestionOnChangePayload = Partial<
  {
    [T in keyof QuestionWithCategoryProps]: any;
  }
>;

type DisabledFields = Partial<
  {
    [T in keyof QuestionWithCategoryProps]: boolean;
  }
>;

export type AllowedQuestionFlags = Partial<
  {
    [T in keyof QuestionSettingFlags]: SettingsFlag;
  }
>;

export interface QuestionSettingFlags {
  isOutcomeQuestion?: boolean;
  isRatingQuestion?: boolean;
  isNPSQuestion?: boolean;
  allowComment?: boolean;
}

interface CommonProps extends QuestionSettingFlags {
  readonly questionOrigin: string;
  readonly question?: string;
  readonly question_t?: string;
  readonly hasCategory: boolean;
  readonly add?: boolean;
  readonly id?: string;
  readonly isSentimentAnalysisQuestion: boolean;
  readonly questionType?: QuestionTypeInterface;
  readonly questionConfig: QuestionConfig;
  readonly isOutcomeQuestion?: boolean;
  readonly isRatingQuestion?: boolean;
  readonly isNPSQuestion?: boolean;
  readonly allowComment?: boolean;
  readonly choices?: string[];
  readonly disabled: DisabledFields;
  readonly title?: string;
  readonly allowedQuestionFlags?: AllowedQuestionFlags;
  readonly initialErrors?: Optional<Map<string, string>>;
  readonly isMultiSelectQuestionEnabled: boolean;
  readonly isMultiSelect?: boolean;
  readonly maxNumberOfOptions?: number;
  readonly onChange: (payload: QuestionOnChangePayload) => void;
  readonly onAddCustomCategory: (category: any) => void;
  readonly configuredSurveyLanguages?: string[];
  readonly translatedSurveyLanguages?: string[];
  readonly addUpdateQuestion?: (
    questionId: string,
    question: SurveyQuestionResource,
    queryParams?: Record<string, string>,
    keepModalOpen?: boolean
  ) => void;
  readonly onlyUpdateQuestion?: (
    questionId: string,
    question: SurveyQuestionResource,
    queryParams?: Record<string, string>,
    keepModalOpen?: boolean
  ) => void;
  readonly questionResource?: SurveyQuestionResource;
  readonly enableTranslation: boolean;
  readonly isMultiLanguageSurveyEnabled: boolean;
  readonly isUpdatingQuestion: boolean;
  readonly allowUpdatingTranslation?: boolean;
  readonly t?: TFunction;
  readonly isPulsePoll?: boolean;
}

interface QuestionWithCategoryProps extends CommonProps {
  readonly hasCategory: true;
  readonly category: string;
  readonly postCategories: PostCategoryResource[];
}

interface QuestionWithoutCategoryProps extends CommonProps {
  readonly hasCategory: false;
}

interface ComponentState {
  errors: AllErrors;
  prevIsRatingState?: boolean;
  readonly questionTranslationMap?: Record<string, string>;
  readonly categoryTranslationMap?: Record<string, string>;
  translationEdit: {
    isEditingQuestionTranslation: boolean;
    isEditingCategoryTranslation: boolean;
    isEditingQuestionTypeTranslation: boolean;
  };
}

export class AddOrEditQuestion extends React.Component<
  AddOrEditQuestionProps,
  ComponentState
> {
  static displayName = "AddOrEditQuestion";
  constructor(props: AddOrEditQuestionProps) {
    super(props);

    let catTransMap = {};
    if (
      isNotNullNorUndefined(this.props.questionResource) &&
      isNotNullNorUndefined(this.props.questionResource.category_t)
    ) {
      catTransMap = getParsed(this.props.questionResource.category_t);
    }

    let quesTransMap = {};
    if (
      isNotNullNorUndefined(this.props.questionResource) &&
      isNotNullNorUndefined(this.props.questionResource.question_t)
    ) {
      quesTransMap = getParsed(this.props.questionResource.question_t);
    }

    this.state = {
      errors: getOr(props.initialErrors, Map<string, string>()),
      prevIsRatingState: false,
      questionTranslationMap: quesTransMap,
      categoryTranslationMap: catTransMap,
      translationEdit: {
        isEditingQuestionTranslation: false,
        isEditingCategoryTranslation: false,
        isEditingQuestionTypeTranslation: false,
      },
    };
  }

  componentDidUpdate(prevProps: Readonly<AddOrEditQuestionProps>): void {
    if (prevProps.initialErrors !== this.props.initialErrors) {
      this.setState((state) => ({
        // fixme: should be state.errors.merge(getOr(this.props.initialErrors, Map<string, string>()))
        // fixme: but currently we are never removing the error from the state, even if the error is fixed
        // fixme: so only trust props!
        errors: getOr(this.props.initialErrors, Map<string, string>()),
      }));
    }

    if (prevProps.isUpdatingQuestion === true && this.props.isUpdatingQuestion === false) {
      this.stopEditMode();
    }
  }

  get defaultAllowedQuestionFlags() {
    return getOr(this.props.allowedQuestionFlags, {
      allowComment: {
        checked: getOr(this.props.allowComment, false),
        disabled: this.props.disabled.allowComment,
        label: "Allow comments",
        info: "Decide whether or not employees can submit comments with this question.",
      },
      isSentimentAnalysisQuestion: {
        checked: getOr(this.props.isSentimentAnalysisQuestion, false),
        disabled:
          this.props.disabled.isSentimentAnalysisQuestion ||
          not(getOr(this.props.allowComment, false)),
        label: "Include Verbatim in Sentiment Analysis",
        info: "Check if the answers to this question need to be analyzed for sentiment",
      },
      isNPSQuestion: {
        checked: true,
        disabled: true,
        info: "All eNPS questions are included in eNPS calculations",
        label: "Include in eNPS score calculation",
      },
      isOutcomeQuestion: {
        checked: getOr(this.props.isOutcomeQuestion, false),
        disabled: this.props.disabled.isOutcomeQuestion,
        info: "Check if this is a question which implies the outcome or goal of the survey",
        label: "Set as Driver Impact Analysis outcome question",
      },
      isRatingQuestion: {
        checked: getOr(this.props.isRatingQuestion, false),
        disabled:
          this.props.disabled.isRatingQuestion ||
          getQuestionTypeLabel(
            getOr(this.props.questionType, QuestionTypeInterface.LIKERT),
            this.props.questionConfig,
            getOr(
              this.props.choices,
              QuestionConfig.getCompanyLikertOptions(
                this.props.questionConfig.likert
              )
            ),
            getOr(this.props.isRatingQuestion, false),
            getOr(this.props.isNPSQuestion, false),
            getOr(this.props.isMultiSelect, false) &&
            this.props.isMultiSelectQuestionEnabled
          ) === QuestionTypeLabel.Likert,
        info: "All Likert Scale questions are included in Favorability calculations",
        label: "Include in favorability calculation",
      },
    });
  }

  updateQuestionErrorState = (field: string) => {
    let errors = this.state.errors;
    errors = errors.delete(field);
    this.setState({ errors });
  };

  // fixme - QuestionTypeLabel type
  onTypeChange(
    isRatingQuestion: boolean,
    label: QuestionTypeLabel,
    choices: string[]
  ) {
    const { onChange } = this.props;
    const { prevIsRatingState } = this.state;
    const isRatingQuestionPayload = {
      isRatingQuestion,
    };
    const isMultiSelectPayload: Dictionary<any> = {
      isMultiSelect: undefined,
      maxNumberOfOptions: undefined,
    };
    const isENPSPayload: Dictionary<any> = {
      isNPSQuestion: false,
    };
    // fixme - QuestionTypeLabel enum
    if (label === QuestionTypeLabel.Likert) {
      // If the question is likert type question set the isRatingQuestion to true
      // And save the previous isRatingState

      isRatingQuestionPayload.isRatingQuestion = true;
      this.setState({ prevIsRatingState: isRatingQuestion });
    } else if (prevIsRatingState !== undefined) {
      // If coming from likert, there is a previous state, set the isRatingQuestion to prevState
      // and then clear the prevState
      isRatingQuestionPayload.isRatingQuestion = prevIsRatingState;
      this.setState({ prevIsRatingState: undefined });
    }

    if (label === QuestionTypeLabel.MultiSelect) {
      isMultiSelectPayload.isMultiSelect = true;
      isMultiSelectPayload.maxNumberOfOptions = 3;
    }

    if (label === QuestionTypeLabel.ENPS) {
      isENPSPayload.isNPSQuestion = true;
    }

    onChange({
      questionType: getQuestionTypeFromLabel(label) as any,
      choices,
      ...isRatingQuestionPayload,
      ...isMultiSelectPayload,
      ...isENPSPayload,
    });
  }

  renderCategory = () => {
    const { disabled, onAddCustomCategory, t } = this.props;
    if (this.props.hasCategory) {
      if (disabled.category) {
        return (
          <>
            <div
              className={
                this.showEditTranslationForCategory()
                  ? "categoryT mt-16p"
                  : "mt-16p"
              }
            >
              <div
                className={
                  this.showEditTranslationForCategory() ? "mb-24p" : ""
                }
              >
                <Category label={`${t ? translate(t,"Category") : "Category"}
                ${this.props.category ? ` : ${this.props.category}` : ""}`}/>
              </div>
              {this.categoryTranslationSegment()}
            </div>
          </>
        );
      }
      return (
        <>
          <div
            className={
              this.showEditTranslationForCategory()
                ? "categoryT mt-16p"
                : "mt-16p"
            }
          >
            <div
              className={this.showEditTranslationForCategory() ? "mb-24p" : ""}
            >
              <StyledSurveyCategories
                value={this.props.category}
                label="Category"
                onChange={this.onChange.bind(this, "category")}
                onAddCustomCategory={onAddCustomCategory}
                surveyTypes={this.props.postCategories}
                customCategory={true}
                disabled={disabled.category}
              />
            </div>

            {this.categoryTranslationSegment()}
          </div>
        </>
      );
    }
    return null;
  };

  categoryTranslationSegment() {
    const { totalLanguages, totalTranslated } =
      getLanguageInformationFromStringifiedTranslation(
        this.props.configuredSurveyLanguages,
        this.props.questionResource?.category_t
      );
    return (
      <>
        {this.props.isMultiLanguageSurveyEnabled &&
          this.props.enableTranslation &&
          !this.showEditTranslationForCategory() && (
            <MultiLanguageFooterStrip
              languagesReady={totalTranslated}
              totalLanguages={totalLanguages}
              onEditTranslations={() => {
                this.startEditMode("isEditingCategoryTranslation");
              }}
            />
          )}
        {this.showEditTranslationForCategory() &&
          this.props.configuredSurveyLanguages?.map((code, index) => (
            <div className="mb-24p" key={index}>
              <DebouncedTextArea
                data-cy="category_translation"
                key={code}
                value={getTranslatedSegmentFromStringifiedTranslation(
                  code as LocaleCodes,
                  this.props.questionResource?.category_t
                )}
                label={LabelWithFlag(code as LocaleCodes)}
                onValueChange={(value) => {
                  this.setState({
                    categoryTranslationMap: {
                      ...this.state.categoryTranslationMap,
                      [code]: value,
                    },
                  });
                }}
                disabled={!this.props.disabled.allowUpdatingTranslation}
              />
            </div>
          ))}

        {this.showEditTranslationForCategory() && (
          <div className="d-flex justify-content-end">
            <Button
              color="blue"
              style={{ height: "30px" }}
              onClick={() => this.saveTranslation("category")}
              data-cy="category_save_translations"
              translate="yes"
            >
              Save translations
            </Button>
          </div>
        )}
      </>
    );
  }


  /**
   * Tells whether to send an update request or an add request when 
   * updating the translations
   * 
   * @returns Boolean
   */

  isUpdate() {
    let id = "";
    if (isNotNullNorUndefined(this.props.questionResource)) {
      const { _id } = this.props.questionResource;
      id = _id;
    }

    let update = false;
    if (isStringAndNotEmpty(id)) {
      update = true;
    }

    return update;
  }

  saveTranslation(field: "question" | "category") {
    const updateQuestion = this.isUpdate();
    let fnToCall = this.props.addUpdateQuestion;
    if (updateQuestion) {
      // Only updates the existing question
      fnToCall = this.props.onlyUpdateQuestion;
    }
    const fieldToUpdate = `${field}_t`;
    // @ts-ignore
    const fieldValue = this.state[`${field}TranslationMap`];
    if (fnToCall && this.props.questionResource) {
      fnToCall(this.props.questionResource?._id, {
        ...this.props.questionResource,
        [fieldToUpdate]: JSON.stringify(fieldValue),
      }, {}, true);
    }
  }

  onChange(key: keyof QuestionOnChangePayload, eventValue: any) {
    const { onChange } = this.props;
    switch (key) {
      case "isSentimentAnalysisQuestion":
        return onChange({
          isSentimentAnalysisQuestion: eventValue.target.checked,
        });
      case "question":
        return onChange({
          question: eventValue,
        });
      case "category":
        return onChange({
          category: eventValue,
        });
      case "choices":
        return onChange({
          choices: eventValue,
        });
      case "question_t":
        return onChange({
          question_t: eventValue,
        });
    }
  }

  onSettingsChange = (setting: keyof AddOrEditQuestionProps, value: any) => {
    const onChangePayload = {
      [setting]: value,
    };
    if (setting === "allowComment" && not(value as boolean)) {
      // Disable sentiment analysis if comments are not allowed
      onChangePayload.isSentimentAnalysisQuestion = false;
    }
    this.props.onChange(onChangePayload);
  };

  get questionSettingsProps(): QuestionSettingProps {
    const {
      isMultiSelect = false,
      maxNumberOfOptions,
      disabled,
      isMultiSelectQuestionEnabled,
      isNPSQuestion = false,
      isRatingQuestion = false,
      questionType = QuestionTypeInterface.LIKERT,
      questionConfig,
    } = this.props;

    const choices = getOr(
      this.props.choices,
      QuestionConfig.getCompanyLikertOptions(questionConfig.likert)
    );

    const questionTypeLabel = getQuestionTypeLabel(
      questionType,
      questionConfig,
      choices,
      isRatingQuestion,
      isNPSQuestion,
      isMultiSelect && isMultiSelectQuestionEnabled
    );

    const { errors } = this.state;

    const defaultAllowedQuestionFlags = Object.assign(
      {},
      this.defaultAllowedQuestionFlags
    );

    switch (questionTypeLabel) {
      case QuestionTypeLabel.Likert:
        delete defaultAllowedQuestionFlags.isNPSQuestion;
        break;
      case QuestionTypeLabel.YesNo:
        delete defaultAllowedQuestionFlags.isRatingQuestion;
        delete defaultAllowedQuestionFlags.isNPSQuestion;
        delete defaultAllowedQuestionFlags.isOutcomeQuestion;
        break;
      case QuestionTypeLabel.ENPS:
        delete defaultAllowedQuestionFlags.isRatingQuestion;
        break;
      case QuestionTypeLabel.Custom:
      case QuestionTypeLabel.MultiSelect:
        delete defaultAllowedQuestionFlags.isOutcomeQuestion;
        delete defaultAllowedQuestionFlags.isNPSQuestion;
        delete defaultAllowedQuestionFlags.isRatingQuestion;
        break;
      case QuestionTypeLabel.Unknown:
      default:
        delete defaultAllowedQuestionFlags.isNPSQuestion;
        break;
    }

    const commonProps = {
      toggle: defaultAllowedQuestionFlags,
      isMultiSelect: isMultiSelect && isMultiSelectQuestionEnabled,
      onSettingsChange: this.onSettingsChange,
    };

    if (isMultiSelect) {
      return {
        ...commonProps,
        maxNumberOfOptions: getOr(maxNumberOfOptions, 3),
        error: errors.get("maxNumberOfOptions"),
        maxNumberOfOptionsDisabled: getOr(disabled.maxNumberOfOptions, false),
      };
    }

    return commonProps;
  }

  showEditTranslationAreaForQuestion() {
    return (
      this.state.translationEdit.isEditingQuestionTranslation &&
      getOr(this.props.configuredSurveyLanguages, []).length > 0
    );
  }

  showEditTranslationForCategory() {
    return (
      this.state.translationEdit.isEditingCategoryTranslation &&
      getOr(this.props.configuredSurveyLanguages, []).length > 0
    );
  }

  startEditMode(fieldName: keyof ComponentState["translationEdit"]) {
    const remainingKeys = Object.keys(this.state.translationEdit).filter(
      (field) => field !== fieldName
    );
    const toggledStates = {};
    for (const field of remainingKeys) {
      // @ts-ignore
      toggledStates[field] = false;
    }

    this.setState({
      // @ts-ignore
      translationEdit: {
        [fieldName]: true,
        ...toggledStates,
      },
    });
  }

  stopEditMode() {
    const keys = Object.keys(this.state.translationEdit);
    const toggledStates = {};
    for (const field of keys) {
      // @ts-ignore
      toggledStates[field] = false;
    }
    this.setState({
      // @ts-ignore
      translationEdit: toggledStates
    });
  }

  // fixme: use proper pattern for default props
  render() {
    const {
      question,
      question_t,
      title,
      isNPSQuestion = false,
      isRatingQuestion = false,
      isSentimentAnalysisQuestion,
      questionType = QuestionTypeInterface.LIKERT,
      questionConfig,
      disabled,
      isMultiSelectQuestionEnabled,
      isMultiSelect,
      t
    } = this.props;

    const choices = getOr(
      this.props.choices,
      QuestionConfig.getCompanyLikertOptions(questionConfig.likert)
    );

    const questionTypeLabel = getQuestionTypeLabel(
      questionType,
      questionConfig,
      choices,
      isRatingQuestion,
      isNPSQuestion,
      isMultiSelect && isMultiSelectQuestionEnabled
    );

    const { errors } = this.state;
    const showQuestionSettings = questionTypeLabel !== QuestionTypeLabel.Open;
    const translatedQuestionInfo =
      getLanguageInformationFromStringifiedTranslation(
        this.props.configuredSurveyLanguages,
        question_t
      );

    return (
      <>
        {not(disabled.title) && title && (
          <TemplateQuestionTitle><Trans>{title}</Trans></TemplateQuestionTitle>
        )}
        <div
          className={
            this.showEditTranslationAreaForQuestion() ? "questionT" : ""
          }

        >
          <div
            className={
              this.showEditTranslationAreaForQuestion() ? "mb-24p" : ""
            }
          >
            <DebouncedTextArea
              label="Question"
              disabled={disabled.question}
              value={question}
              onValueChange={this.onChange.bind(this, "question")}
              // @ts-ignore
              error={errors.get("question")}
              rows={5}
            />
          </div>
          {this.props.isMultiLanguageSurveyEnabled &&
            this.props.enableTranslation &&
            !this.showEditTranslationAreaForQuestion() && (
              <MultiLanguageFooterStrip
                languagesReady={translatedQuestionInfo.totalTranslated}
                totalLanguages={translatedQuestionInfo.totalLanguages}
                onEditTranslations={() => {
                  this.startEditMode("isEditingQuestionTranslation");
                }}
              />
            )}

          {this.showEditTranslationAreaForQuestion() &&
            this.props.configuredSurveyLanguages?.map((code, index) => (
              <div className="mb-24p" key={index}>
                <DebouncedTextArea
                  key={code}
                  label={LabelWithFlag(code as LocaleCodes)}
                  value={getTranslatedSegmentFromStringifiedTranslation(
                    code as LocaleCodes,
                    question_t
                  )}
                  onValueChange={(value) => {
                    this.setState({
                      questionTranslationMap: {
                        ...getOr(this.state.questionTranslationMap, {}),
                        [code]: value,
                      },
                    });
                  }}
                  data-cy="question_translation"
                  // @ts-ignore
                  error={errors.get("question_t")}
                  rows={5}
                  disabled={!this.props.disabled.allowUpdatingTranslation}
                />
              </div>
            ))}

          {this.showEditTranslationAreaForQuestion() && (
            <div className="d-flex justify-content-end">
              <Button
                color="blue"
                style={{ height: "30px" }}
                onClick={() => this.saveTranslation("question")}
                data-cy="question_save_translations"
                translate="yes"
              >
                Save translations
              </Button>
            </div>
          )}
        </div>

        {this.renderCategory()}
        <QuestionType
          title={`${t ? translate(t,"Question Type") : "Question Type"}
          ${disabled.questionType ? ` : ${questionTypeLabel}` : ""}`}
          disableSelection={disabled.questionType}
          onTypeChange={this.onTypeChange.bind(this, isRatingQuestion)}
          onOpenTypeChange={this.onChange.bind(
            this,
            "isSentimentAnalysisQuestion"
          )}
          data={{
            choices,
            isSentimentAnalysisQuestion,
            type: questionTypeLabel,
            questionConfig,
          }}
          onUpdateChoices={this.onChange.bind(this, "choices")}
          error={errors.get("choices")}
          isSentimentAnalysisDisabled={getOr(
            disabled.isSentimentAnalysisQuestion,
            false
          )}
          isMultiSelectQuestionEnabled={isMultiSelectQuestionEnabled}
          onEditQuestionTypeTranslation={() => {
            this.startEditMode("isEditingQuestionTypeTranslation");
          }}
          configuredSurveyLanguages={this.props.configuredSurveyLanguages}
          addUpdateQuestion={this.props.addUpdateQuestion}
          onlyUpdateQuestion={this.props.onlyUpdateQuestion}
          questionResource={this.props.questionResource}
          isMultiLanguageSurveyEnabled={this.props.isMultiLanguageSurveyEnabled}
          isUpdatingQuestion={this.props.isUpdatingQuestion}
          stopEditMode={() => {
            this.stopEditMode();
          }}
          allowUpdatingTranslation={disabled.allowUpdatingTranslation}
          isPulsePoll={this.props.isPulsePoll}
        />
        {showQuestionSettings && (
          <QuestionSettings {...this.questionSettingsProps} />
        )}
      </>
    );
  }
}

const TemplateQuestionTitle = styled(Paragraph)`
  margin-bottom: 24px !important;
`;

const Category = styled(FieldLabels)``;

const StyledSurveyCategories = styled(SurveyCategories)``;
