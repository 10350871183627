import React from "react";
import { Map as ImmutableMap } from "immutable";
import { PaginationConfig } from "antd/lib/table";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { getOr, mapOr } from "hyphen-lib/dist/lang/Objects";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { parseNumber } from "hyphen-lib/dist/lang/Number";
import { VoiceFlaggedPostReportResource } from "hyphen-lib/dist/domain/resource/voice/VoiceFlaggedPostReportResource";
import {
  VoiceFlaggedCommentReportResource
} from "hyphen-lib/dist/domain/resource/voice/VoiceFlaggedCommentReportResource";

import { DataListContainer } from "@src/screens/Insights/components/DataListContainer";
import { FetchDataListParameters } from "@src/screens/Insights/components/DataListContainer/types";
import { parametersActionCreators } from "@src/screens/Insights/parameters/store/actions";
import { State } from "@src/store/types";
import { getParameters } from "@src/screens/Insights/parameters/store/selectors";
import { PropMapping } from "@src/utils/parameters";
import { getExistingPage, extractDataAndTotalFromPage } from "@src/store/network/selectors";

import { fetchVoiceFlaggedPostsIfNeeded } from "@src/store/network/resource/VoiceFlaggedPostReportResource";
import { Store } from "hyphen-lib/dist/util/store/Store";
import AreYouSureModal from "@src/components/core/AreYouSureModal";
import { RightsMatcher } from "hyphen-lib/dist/business/auth/Auth";
import { getRightsMatcher } from "@src/screens/Insights/store/selectors";
import { FetchError } from "@src/screens/Insights/errors/FetchError";
import { VoiceFlagsActionKeyType } from "../../store/types";
import { getEmployeeVoiceReportsState } from "../../store/selectors";
import { voiceReportsActionCreators, VoiceReportsAreYouSureModalPayload } from "../../store/actions";
import { VoiceFlaggedPostsTable } from "../components/VoiceFlaggedPostsTable";
import { Trans } from "react-i18next";

interface VoicePostsListProps
  extends VoiceFlaggedPostsListReduxActionProps,
  RouteComponentProps,
  VoiceFlaggedPostsListReduxStateProps {}

interface VoiceFlaggedPostsListReduxActionProps {
  readonly onFetchVoiceFlaggedPostsIfNeeded: (
    parameters: FetchDataListParameters
  ) => void;
  readonly onToggleAreYouSureModal: (payload: VoiceReportsAreYouSureModalPayload) => void;
  readonly onUnFlagPost: () => void;
  readonly archivePost: () => void;
  readonly onModifyParameters: (
    parameters: Dictionary<any>,
    mappings?: PropMapping[]
  ) => void;
  readonly onModifyList: (parameters: FetchDataListParameters) => void;
  readonly onCloseAreYouSureModal: () => void;
}

interface VoiceFlaggedPostsListReduxStateProps {
  readonly voiceFlaggedPosts: any[];
  readonly totalVoicePostsCount: number;
  readonly parameters: ImmutableMap<string, any>;
  readonly pageSize: number;
  readonly loading: boolean;
  readonly isNotFound: boolean;
  readonly isRequesting: boolean;
  readonly isAreYouSureModalVisible: boolean;
  readonly action: VoiceFlagsActionKeyType | null;
  readonly selectedFlaggedResource: VoiceFlaggedPostReportResource | VoiceFlaggedCommentReportResource | null;
  readonly rightsMatcher: RightsMatcher;
  readonly existingListVoiceFlaggedPosts: Store.Page<VoiceFlaggedPostReportResource>;
}

export class VoiceFlaggedPostsListContainer extends React.Component<
VoicePostsListProps
> {
  componentWillUnmount() {
    this.props.onCloseAreYouSureModal();
  }

  onTableChange = (
    pageParams: FetchDataListParameters,
    pagination: PaginationConfig
  ): void => {
    const { page, filter } = pageParams;
    const { onModifyList, onModifyParameters } = this.props;
    const pageParam = {
      size: getOr(pagination.pageSize, page.size),
      number: getOr(pagination.current, 1),
    };

    onModifyParameters({});
    onModifyList({
      filter,
      page: pageParam,
    });
  };

  onActionClick= (
    action:  VoiceFlagsActionKeyType,
    resource: VoiceFlaggedPostReportResource | VoiceFlaggedCommentReportResource
  ) => {
    this.props.onToggleAreYouSureModal({
      action,
      isAreYouSureModalVisible: true,
      selectedFlaggedResource: resource,
    });
  };

  handleModalOkClick = () => {
    const { action, onUnFlagPost, archivePost } = this.props;
    if (action === VoiceFlagsActionKeyType.UNFLAG) {
      onUnFlagPost();
    } else if (action === VoiceFlagsActionKeyType.ARCHIVE) {
      archivePost();
    }
  };

  getOkLabel = (): string => {
    const { action, isRequesting } = this.props;
    let label = isRequesting ? "Archiving..." : "Archive";
    if (action === VoiceFlagsActionKeyType.UNFLAG) {
      label = isRequesting ? "Unflagging..." : "Unflag";
    }

    return label;
  };

  render() {
    const {
      voiceFlaggedPosts,
      pageSize,
      parameters,
      totalVoicePostsCount,
      loading,
      isNotFound,
      onFetchVoiceFlaggedPostsIfNeeded,
      isAreYouSureModalVisible,
      action,
      onCloseAreYouSureModal,
      selectedFlaggedResource,
      isRequesting,
      rightsMatcher,
      existingListVoiceFlaggedPosts,
    } = this.props;

    if (Store.Page.isInError(existingListVoiceFlaggedPosts)) {
      return <FetchError { ...existingListVoiceFlaggedPosts } resourceType={VoiceFlaggedPostReportResource.TYPE}/>;
    }
    return (
      <>
        <DataListContainer
          pageSize={pageSize}
          parameters={parameters}
          loading={loading}
          isNotFound={isNotFound}
          onFetchIfNeeded={onFetchVoiceFlaggedPostsIfNeeded}
          isInError={Store.Page.isInError(existingListVoiceFlaggedPosts)}
        >
          {({ pageParams }) => (
            <VoiceFlaggedPostsTable
              onActionClick={this.onActionClick}
              dataSource={voiceFlaggedPosts}
              loading={loading}
              onChange={this.onTableChange.bind(this, pageParams)}
              pagination={{
                total: totalVoicePostsCount,
                pageSize: pageParams.page.size,
                current: pageParams.page.number,
                showTotal: (totalDocuments: number, range: number[]) =>
                  <Trans i18nKey="postsCount"
                  values={{start: range[0], end: range[1], total: totalDocuments}} 
                  defaults={`Showing ${range[0]} to ${range[1]} of ${totalDocuments} posts`}/>,
              }}
              rightsMatcher={rightsMatcher}
            />
          )}
        </DataListContainer>

        {
          isAreYouSureModalVisible && selectedFlaggedResource &&
          <AreYouSureModal
            visible={isAreYouSureModalVisible}
            title="Are you sure?"
            description={`This action will ${
              action === VoiceFlagsActionKeyType.UNFLAG ? "unflag" : "archive"
            } the post`}
            onOk={this.handleModalOkClick}
            okLabel={this.getOkLabel()}
            onCancel={onCloseAreYouSureModal}
            buttonsDisabled={isRequesting}
          />
        }
      </>
    );
  }
}

function mapStateToProps(
  state: State,
  { location }: RouteComponentProps
): VoiceFlaggedPostsListReduxStateProps {
  const queryParameters = parseQueryString(location.search);
  const filter = getOr(queryParameters.filter, {});
  const {
    pageSize,
    isAreYouSureModalVisible,
    selectedFlaggedResource,
    action,
    isRequesting,
  } = getEmployeeVoiceReportsState(state);
  const page = {
    size: pageSize,
    number: mapOr(queryParameters.page, parseNumber, 1),
  };

  const existingListVoiceFlaggedPosts = getExistingPage(
    state,
    VoiceFlaggedPostReportResource.TYPE,
    VoiceFlaggedPostReportResource.generateKey(filter),
    page
  );

  const {
    data: voiceFlaggedPosts,
    total: totalVoicePostsCount,
  } = extractDataAndTotalFromPage(existingListVoiceFlaggedPosts);

  return {
    isNotFound: Store.Page.isNotFound(existingListVoiceFlaggedPosts),
    loading: Store.Page.isLoading(existingListVoiceFlaggedPosts),
    existingListVoiceFlaggedPosts,
    voiceFlaggedPosts,
    totalVoicePostsCount,
    parameters: getParameters(state),
    pageSize,
    isAreYouSureModalVisible,
    action,
    selectedFlaggedResource,
    isRequesting,
    rightsMatcher: getRightsMatcher(state),
  };
}

const mapDispatchToProps: VoiceFlaggedPostsListReduxActionProps = {
  onFetchVoiceFlaggedPostsIfNeeded: fetchVoiceFlaggedPostsIfNeeded,
  onUnFlagPost: voiceReportsActionCreators.unflagPost,
  archivePost: voiceReportsActionCreators.archivePost,
  onToggleAreYouSureModal: voiceReportsActionCreators.toggleAreYouSureModal,
  onCloseAreYouSureModal: voiceReportsActionCreators.closeAreYouSureModal,
  onModifyList: voiceReportsActionCreators.modifyList,
  onModifyParameters: parametersActionCreators.modifyParameters,
};

export const VoiceFlaggedPostsList = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VoiceFlaggedPostsListContainer)
);
