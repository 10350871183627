import { isStringAndNotEmpty } from "@hyphen-lib/lang/Strings";
import { FSA } from "flux-standard-action";
import { Omit } from "hyphen-lib/dist/lang/Types";
import { ActionResource } from "hyphen-lib/dist/domain/resource/action/ActionResource";

import { createRequest, FilterParameter, SortParameter } from "@src/utils/networks";
import {
  ActionTypes as NetworkActionTypes,
  NetworkEventErrorAction,
  NetworkEventRequestAction,
  NetworkEventSuccessAction,
  NetworkRequestAction
} from "@src/store/network/actions";
import { SurveyInfoResource } from "hyphen-lib/dist/domain/resource/SurveyInfoResource";
import { PulsePollInfoResource } from "hyphen-lib/dist/domain/resource/PulsePollInfoResource";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { ActionPlanTemplateResource } from "hyphen-lib/dist/domain/resource/action/ActionPlanTemplateResource";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { NewActionResource } from "./reducers";

export enum ActionPlansActionTypes {
  ADD_ACTION_PLAN = "actionPlans/ADD_ACTION_PLAN",
  ADD_ACTION_PLAN_REQUEST = "actionPlans/ADD_ACTION_PLAN_REQUEST",
  ADD_ACTION_PLAN_SUCCESS = "actionPlans/ADD_ACTION_PLAN_SUCCESS",
  ADD_ACTION_PLAN_ERROR = "actionPlans/ADD_ACTION_PLAN_ERROR",

  FETCH_SURVEY_INFO = "actionPlans/FETCH_SURVEY_INFO",
  SELECT_SURVEY_INFO = "actionPlans/SELECT_SURVEY_INFO",
  
  FETCH_SURVEY_INFO_REQUEST = "actionPlans/FETCH_SURVEY_INFO_REQUEST",
  FETCH_SURVEY_INFO_SUCCESS = "actionPlans/FETCH_SURVEY_INFO_SUCCESS",
  FETCH_SURVEY_INFO_ERROR = "actionPlans/FETCH_SURVEY_INFO_ERROR",
  
  FETCH_PULSE_POLL_INFO = "actionPlans/FETCH_PULSE_POLL_INFO",
  SELECT_PULSE_POLL_INFO = "actionPlans/SELECT_PULSE_POLL_INFO",
  FETCH_PULSE_POLL_INFO_REQUEST = "actionPlans/FETCH_PULSE_POLL_INFO_REQUEST",
  FETCH_PULSE_POLL_INFO_SUCCESS = "actionPlans/FETCH_PULSE_POLL_INFO_SUCCESS",
  FETCH_PULSE_POLL_INFO_ERROR = "actionPlans/FETCH_PULSE_POLL_INFO_ERROR",

  MODIFY_LIST = "actionPlans/MODIFY_LIST",

  MODIFY_FILTER = "actionPlans/MODIFY_FILTER",
  CLEAN_FILTER = "actionPlans/CLEAN_FILTER",

  SELECT_ACTION_PLAN = "actionPlans/SELECT_ACTION_PLAN",
  FETCH_ACTION_PLAN = "actionPlans/FETCH_ACTION_PLAN",
  UPDATE_ACTION_PLAN_SETTINGS = "actionPlans/UPDATE_ACTION_PLAN_SETTINGS",

  FETCH_ACTION_PLAN_REQUEST = "actionPlans/FETCH_ACTION_PLAN_REQUEST",
  FETCH_ACTION_PLAN_SUCCESS = "actionPlans/FETCH_ACTION_PLAN_SUCCESS",
  FETCH_ACTION_PLAN_ERROR = "actionPlans/FETCH_ACTION_PLAN_ERROR",

  FETCH_ACTION_PLAN_OBJECTIVE_REQUEST = "actionPlans/FETCH_ACTION_PLAN_OBJECTIVE_REQUEST",
  FETCH_ACTION_PLAN_OBJECTIVE_SUCCESS = "actionPlans/FETCH_ACTION_PLAN_OBJECTIVE_SUCCESS",
  FETCH_ACTION_PLAN_OBJECTIVE_ERROR = "actionPlans/FETCH_ACTION_PLAN_OBJECTIVE_ERROR",

  CLEAN_ACTION_PLAN_RESOURCE = "actionPlans/CLEAN_ACTION_PLAN_RESOURCE",
  SHOW_ACTION_PLAN_OKR_NOTIFICATION = "actionPlans/SHOW_ACTION_PLAN_OKR_NOTIFICATION",
}

export interface CreateActionPayload extends Omit<ActionResource, "_id" | "_type"> {}

interface Payloads {
  [ActionPlansActionTypes.ADD_ACTION_PLAN]: CreateActionPayload;
  [ActionPlansActionTypes.UPDATE_ACTION_PLAN_SETTINGS]: Partial<NewActionResource>;
  [ActionPlansActionTypes.FETCH_ACTION_PLAN_REQUEST]: CompanyActionTemplatesPageParameters;
  [ActionPlansActionTypes.FETCH_ACTION_PLAN_SUCCESS]: Store.Page.Loaded<ActionPlanTemplateResource>;
  [ActionPlansActionTypes.MODIFY_LIST]: CompanyActionTemplatesPageParameters;
}
export interface AddActionPlanAction
  extends FSA<ActionPlansActionTypes.ADD_ACTION_PLAN, Payloads[ActionPlansActionTypes.ADD_ACTION_PLAN]> {
  type: ActionPlansActionTypes.ADD_ACTION_PLAN;
  payload: Payloads[ActionPlansActionTypes.ADD_ACTION_PLAN];
  redirectPath?: string;
}
export interface ModifyListActionTemplates
  extends FSA<ActionPlansActionTypes.MODIFY_LIST, Payloads[ActionPlansActionTypes.MODIFY_LIST]> {
  type: ActionPlansActionTypes.MODIFY_LIST;
  payload: Payloads[ActionPlansActionTypes.MODIFY_LIST];
}

export interface UpdateActionSettingsAction
  extends FSA<
  ActionPlansActionTypes.UPDATE_ACTION_PLAN_SETTINGS,
  Payloads[ActionPlansActionTypes.UPDATE_ACTION_PLAN_SETTINGS]
  > {
  type: ActionPlansActionTypes.UPDATE_ACTION_PLAN_SETTINGS;
  payload: Payloads[ActionPlansActionTypes.UPDATE_ACTION_PLAN_SETTINGS];
}

export interface CompanyActionTemplatesPageParameters {
  readonly filter?: FilterParameter;
  readonly sort?: SortParameter;
  readonly page: PageFilter;
  readonly queryParams?: Dictionary<any>;
}

function createAddActionPlanRequest(payload: AddActionPlanAction["payload"]) {
  return createRequest("/actions", {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
}
  
function createGetSurveyRequest(surveyId: string) {
  return createRequest(`/surveyInfos/${surveyId}`);
}

function createGetPulsePollRequest(pulsePollId: string) {
  return createRequest(`/pulsePolls/${pulsePollId}/reports/info`);
}

function createGetActionPlan(actionId: string) {
  return createRequest(`/actions/${actionId}`);
}

function getOKRObjectiveList(searchQuery?: string) {
  let url = "/action/goals/";
  if (isStringAndNotEmpty(searchQuery)) {
    url += `?q=${searchQuery}`;
  }
  return createRequest(url);
}
export const actionPlansActionCreators = {
  addActionPlanRequest: (
    actionPlantPayload: AddActionPlanAction["payload"],
    redirectPath: AddActionPlanAction["redirectPath"]
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createAddActionPlanRequest(actionPlantPayload),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: ActionPlansActionTypes.ADD_ACTION_PLAN_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({
            type: ActionPlansActionTypes.ADD_ACTION_PLAN_SUCCESS,
            payload,
            meta: { redirectPath },
          }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: ActionPlansActionTypes.ADD_ACTION_PLAN_ERROR, payload }),
      ],
    },
  }),

  fetchSurveyInfo: (
    surveyId: string
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createGetSurveyRequest(surveyId),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: ActionPlansActionTypes.FETCH_SURVEY_INFO_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({
            type: ActionPlansActionTypes.FETCH_SURVEY_INFO_SUCCESS,
            payload,
          }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: ActionPlansActionTypes.FETCH_SURVEY_INFO_ERROR, payload }),
      ],
    },
  }),

  fetchPulsePollInfo: (
    pulsePollId: string
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createGetPulsePollRequest(pulsePollId),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: ActionPlansActionTypes.FETCH_PULSE_POLL_INFO_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({
            type: ActionPlansActionTypes.FETCH_PULSE_POLL_INFO_SUCCESS,
            payload,
          }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: ActionPlansActionTypes.FETCH_PULSE_POLL_INFO_ERROR, payload }),
      ],
    },
  }),

  fetchAction: (
    actionId: string
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createGetActionPlan(actionId),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: ActionPlansActionTypes.FETCH_ACTION_PLAN_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({
            type: ActionPlansActionTypes.FETCH_ACTION_PLAN_SUCCESS,
            payload,
          }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: ActionPlansActionTypes.FETCH_ACTION_PLAN_ERROR, payload }),
      ],
    },
  }),

  fetchObjectiveList: (
    searchQuery?: string
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: getOKRObjectiveList(searchQuery),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: ActionPlansActionTypes.FETCH_ACTION_PLAN_OBJECTIVE_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({
            type: ActionPlansActionTypes.FETCH_ACTION_PLAN_OBJECTIVE_SUCCESS,
            payload,
          }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: ActionPlansActionTypes.FETCH_ACTION_PLAN_OBJECTIVE_ERROR, payload }),
      ],
    },
  }),

  modifyList: (payload: ModifyListActionTemplates["payload"]): ModifyListActionTemplates => ({
    type: ActionPlansActionTypes.MODIFY_LIST,
    payload,
  }),

  addActionPlan: (payload: AddActionPlanAction["payload"], redirectPath: AddActionPlanAction["redirectPath"]) => ({
    type: ActionPlansActionTypes.ADD_ACTION_PLAN,
    payload,
    redirectPath,
  }),

  cleanActionPlan: (redirectPath?: string) => ({
    type: ActionPlansActionTypes.CLEAN_ACTION_PLAN_RESOURCE,
    redirectPath,
  }),

  fetchSurveyIfNeeded: (surveyId: string) => ({
    type: ActionPlansActionTypes.FETCH_SURVEY_INFO,
    surveyId,
  }),

  fetchPulsePollIfNeeded: (pulsePollId: string) => ({
    type: ActionPlansActionTypes.FETCH_PULSE_POLL_INFO,
    pulsePollId,
  }),

  fetchActionPlanIfNeeded: (actionId: string) => ({
    type: ActionPlansActionTypes.FETCH_ACTION_PLAN,
    actionId,
  }),

  selectSurveyInfo: (surveyInfo: SurveyInfoResource) => ({
    type: ActionPlansActionTypes.SELECT_SURVEY_INFO,
    surveyInfo,
  }),

  selectPulsePollInfo: (pulsePollInfo: PulsePollInfoResource) => ({
    type: ActionPlansActionTypes.SELECT_PULSE_POLL_INFO,
    pulsePollInfo,
  }),

  selectAction: (actionPlan: ActionResource) => ({
    type: ActionPlansActionTypes.SELECT_ACTION_PLAN,
    actionPlan,
  }),

  updateActionPlanSettings: (payload: UpdateActionSettingsAction["payload"]) => ({
    type: ActionPlansActionTypes.UPDATE_ACTION_PLAN_SETTINGS,
    payload,
  }),

  modifyFilter: (payload: FilterParameter) => ({
    type: ActionPlansActionTypes.MODIFY_FILTER,
    payload,
  }),

  cleanFilter: () => ({
    type: ActionPlansActionTypes.CLEAN_FILTER,
  }),

  showOkrFeatureNotification: () => ({
    type: ActionPlansActionTypes.SHOW_ACTION_PLAN_OKR_NOTIFICATION,
  })
};
