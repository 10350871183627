import React from "react";
import ContainerCard from "@src/components/core/ContainerCard";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { UsedResources } from "@src/store/network/ResourceStoresDefinitions";

type FetchErrorProps = Store.Element.InError & {resourceType: keyof UsedResources; isCountResource?: boolean};

export function FetchError(
  {status, error: {errorCode, errorMessage}, resourceType, isCountResource=false }: FetchErrorProps) {
  let description = "Oops...Something went wrong on the server";
  const preFix = isCountResource ? "count of" : "";
  if (errorCode >= 400 && errorCode < 500) {
    // eslint-disable-next-line max-len
    description = `You are not authorized to access the requested resource(${preFix} ${resourceType})`;
  }
  if (errorCode >= 500 && errorCode < 600) {
    description = `There was a server error in fetching the resource(${preFix} ${resourceType}). Please try again.`;
  }
  return (
    <ContainerCard
      title="Network Fetch Error"
      description={description}
    />
  );
}
