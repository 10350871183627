import { SidebarDefinition } from "@components/Sidebar/SidebarDefinitions";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { SidebarHeader } from "@components/Sidebar/components/SidebarHeader";
import { SidebarFooter } from "@components/Sidebar/components/SidebarFooter";
import { SidebarSection } from "@components/Sidebar/components/SidebarSection";
import { SidebarIcon } from "@components/Sidebar/components/SidebarIcon";
import { CollapseSvg } from "@components/core/svg/CollapseSvg";
import { ExpandSvg } from "@components/core/svg/ExpandSvg";
import { isDashboardMultilingual } from "hyphen-lib/dist/business/company/Companies";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";

export enum SidebarMode {
  COLLAPSED = "collapsed",
  EXPANDED = "expanded",
}

interface Props {
  mode: SidebarMode;
  readonly company?: Optional<CompanyResource>;
  activeItemId?: string;
  sidebarDefinition: SidebarDefinition<any>;
  email: string;
  onClickLogo?: () => void;
  onChangeMode: () => void;
  onLogout: () => void;
  onLanguageChange: (language: string) => void;
}

export function Sidebar({
  mode,
  activeItemId,
  sidebarDefinition,
  email,
  company,
  onClickLogo,
  onChangeMode,
  onLogout,
  onLanguageChange
}: Props) { 
  return (
    <SidebarContainer className="block--print-hide" mode={mode}>
      <SidebarChangeModeButton mode={mode} onChangeMode={onChangeMode}/>
      <SidebarHeader mode={mode} onClickLogo={onClickLogo}/>

      <SidebarInnerContainer>
        <SidebarSection mode={mode} activeItemId={activeItemId} section={sidebarDefinition.mainSection}/>
        <SidebarSection mode={mode} activeItemId={activeItemId} section={sidebarDefinition.adminSection}/>
      </SidebarInnerContainer>

      {
        company &&
        isDashboardMultilingual(company) &&
        <SidebarFooter mode={mode} email={email} onAction={onLanguageChange} type={"language"} />
      }
      <SidebarFooter mode={mode} email={email} onAction={onLogout} type={"logout"} />
    </SidebarContainer>
  );
}

function ChangeModeButton({ mode, onChangeMode, ...rest }: { mode: SidebarMode; onChangeMode: () => void }) {
  return (
    <SidebarIcon onClick={onChangeMode}
      active={true}
      {...rest}
    >
      {
        mode === SidebarMode.EXPANDED ?
          <CollapseSvg/> :
          <ExpandSvg/>
      }
    </SidebarIcon>
  );
}

const SidebarContainer = styled.div<{ mode: SidebarMode }>`
  width: ${props => props.mode === SidebarMode.EXPANDED ? 260 : 84}px;
  min-width: ${props => props.mode === SidebarMode.EXPANDED ? 260 : 84}px;
  height: 100%;
  background-color: ${Palette.veryDarkBlueGrey};
  display: flex;
  flex-direction: column;
  position: relative;
  transition: 0.2s;
`;

const SidebarInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

const SidebarChangeModeButton = styled(ChangeModeButton)`
  position: absolute;
  top: 68px;
  right: 0;
  margin-right: -16px;
  cursor: pointer;
`;
