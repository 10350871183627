import React, { Component } from "react";
import ContainerCard from "@components/core/ContainerCard";
import styled from "styled-components";
import { AppSettingsResource } from "hyphen-lib/dist/domain/resource/AppSettingsResource";
import Select from "@src/components/core/Select";
import { SelectProps } from "antd/lib/select";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { InputProps } from "antd/lib/input";
import withDebounce from "@src/components/core/withDebounce";
import Input from "@src/components/core/Input";
import { isNotNullNorUndefined, areNotEquals } from "hyphen-lib/dist/lang/Objects";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import { Paragraph } from "@src/components/core/Typography";
import { TFunction, useTranslation } from "react-i18next";
import { translate } from "@src/utils/i18next";

const NewInput = withDebounce(Input as any);

function RenderTextInput({
  returnLikertHelperText,
  index,
  defaultChoices,
  handleOptionTextChange
}: any){
  const { t } = useTranslation();
  return (
    <TextInput
      label={`${translate(t, "Default Option")} #${index + 1} ${returnLikertHelperText(t, index)}`}
      value={defaultChoices[index]}
      // tslint:disable-next-line:jsx-no-lambda
      onValueChange={(modifiedOption: string) => handleOptionTextChange(modifiedOption, index)}
      waitTime={800}
      />
  );
}

interface AppSettingsSurveyProps{
  appSettings: AppSettingsResource;
  onValueChange: (values: AppSettingsResource) => void;
}

interface AppSettingsSurveyStates {
  defaultOption: number;
  defaultChoices: string[];
}

const { Option } = Select;

export default class LikertScaleSettings extends Component<AppSettingsSurveyProps, AppSettingsSurveyStates> {
  constructor(props: AppSettingsSurveyProps) {
    super(props);
    this.state = {
      defaultOption: 5,
      defaultChoices: [],
    };
  }

  componentDidMount() {
    const { appSettings } = this.props;
    if (
      isNotNullNorUndefined(appSettings) &&
      isNotNullNorUndefined(appSettings.questionConfig?.likert)
    ) {
      const { defaultScale } = appSettings.questionConfig?.likert!;
      this.setState({
        defaultOption: defaultScale,
        defaultChoices: this.returnDefaultLikertOptions(defaultScale),
      });
    }
  }

  updateDefaultScale = (option: any) => {
    this.setState({ defaultOption: option , defaultChoices: this.returnDefaultLikertOptions(option)});
    const { appSettings } = this.props;
    if (isNotNullNorUndefined(appSettings.questionConfig)) {
      appSettings.questionConfig.likert.defaultScale = option;
      this.props.onValueChange(appSettings);
    }
  };

  handleOptionTextChange = (modifiedOption: string, index: number) => {

    const { defaultOption } = this.state;
    const { appSettings } = this.props;

    if (isStringAndNotEmpty(modifiedOption) && isNotNullNorUndefined(appSettings) ) {
      // @ts-ignore
      const optionsToModify = appSettings.questionConfig.likert[`labels${defaultOption}`];
      optionsToModify[index] = modifiedOption;
      // @ts-ignore
      appSettings.questionConfig.likert[`labels${defaultOption}`] = optionsToModify;
      this.props.onValueChange(appSettings);
    }
  };

  componentDidUpdate(prevProps: AppSettingsSurveyProps) {
    const { appSettings } = this.props;

    if (areNotEquals(prevProps, this.props) && isNotNullNorUndefined(appSettings.questionConfig?.likert))
    {
      const { defaultScale } = appSettings.questionConfig?.likert!;
      this.setState({
        defaultOption: defaultScale,
        defaultChoices: this.returnDefaultLikertOptions(defaultScale),
      });
    }
  }

  returnLikertHelperText = (t:TFunction, index: number): string => {
    const { defaultChoices } = this.state;
    if (index === 0) {
      return `( ${translate(t, "Most Favorable")} )`;
    } else if (index === defaultChoices.length - 1) {
      return `( ${translate(t, "Least Favorable")} )`;
    }

    return "";
  };

  returnDefaultLikertOptions = (optionRange: Optional<number>): string[] => {

    const { appSettings } = this.props;

    if (isNotNullNorUndefined(appSettings.questionConfig)) {
      // @ts-ignore
      return appSettings.questionConfig.likert[`labels${optionRange}`];
    }

    return [];
  };

  render() {
    return (
      <StyledContainerCard title="Likert Scale Settings">
        <Holder>
          <Paragraph translate="yes">These settings will not be applicable to Template questions</Paragraph>
          <StyledSelect
            disabled={false}
            label="Likert Scale Option Range"
            value={this.state.defaultOption}
            onChange={this.updateDefaultScale}
            data-jest="likertSelector"
          >
            {[2, 3, 4, 5, 6, 7].map((option, index) => (
              <Option key={index} value={option}>
                {option}
              </Option>
            ))}
          </StyledSelect>
        </Holder>
        <Holder>
          {this.state.defaultChoices.map((option, index) => (
            <RenderTextInput
              returnLikertHelperText={this.returnLikertHelperText}
              index={index}
              key={index}
              defaultChoices={this.state.defaultChoices}
              handleOptionTextChange={this.handleOptionTextChange}
            />
          ))}
        </Holder>
      </StyledContainerCard>
    );
  }
}

const StyledSelect = styled(Select) <SelectProps & { children: React.ReactNode }>`
  width: 266px;
  margin-top: 30px;
`;

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 28px;
`;

const StyledContainerCard = styled(ContainerCard)`
  margin-bottom: 39px;
  input:focus {
    outline: none;
  }
`;

// eslint-disable-next-line max-len
export const TextInput = styled(NewInput)<InputProps & { label: string; onValueChange: any; description?: string; waitTime?: number }>`
  margin-top: 24px !important;
  .ant-input {
    width: 365px;
  }
`;
