import { default as networkActionCreators } from "@store/network/actions";
import { createRequest } from "@src/utils/networks";
import { appendQueryString, generateQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
// eslint-disable-next-line max-len
import { CategoryReportResource } from "hyphen-lib/dist/domain/resource/report/CategoryReportResource";

export function fetchCategoryReportIfNeeded(surveyId: string,
  categoryId: string,
  queryString: Dictionary<any>) {

  return networkActionCreators.fetchElementIfNeeded({
    id: CategoryReportResource.generateId(categoryId, queryString.filter, surveyId),
    type: CategoryReportResource.TYPE,
    request: createRequest(
      appendQueryString(
        `/surveys/${surveyId}/reports/categories/${categoryId}`,
        generateQueryString(queryString)
      )
    ),
  });
}
