import React from "react";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps } from "react-router";

import { State } from "@store/types";
// eslint-disable-next-line max-len
import CategoryHighlightReport from "@screens/Insights/Survey/components/CategoryHighlightReport";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import {
  getCompany,
  getRightsMatcher,
} from "@screens/Insights/store/selectors";
import {
  generateCompareWithOptions,
  getAvailableComparisons,
} from "@src/utils/Comparisons";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { not } from "hyphen-lib/dist/lang/Booleans";
import {
  areEquals,
  getOr,
  getProperty,
  isEmptyObject,
  isNotEmptyObject,
  isNotNullNorUndefined,
  mapOr,
  objectOmit,
} from "hyphen-lib/dist/lang/Objects";
import { Map as ImmutableMap } from "immutable";
import { getParameters } from "@screens/Insights/parameters/store/selectors";
import { goTo, replaceLocation } from "@src/utils/locations";
import {
  applyExistingParametersIfNeeded,
  PropMapping,
} from "@src/utils/parameters";
import { getAnonymityThreshold } from "@screens/Insights/Settings/store/selectors";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { SurveyInfoResource } from "hyphen-lib/dist/domain/resource/SurveyInfoResource";
import { Loading } from "@screens/Insights/Survey/components/Loading";
import { getResourceById } from "@store/network/selectors";
import { fetchSurveyInfoIfNeeded } from "@store/network/resource/SurveyInfoResources";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { CategoryReportResource } from "hyphen-lib/dist/domain/resource/report/CategoryReportResource";
import { fetchCategoryReportIfNeeded } from "@src/store/network/resource/CategoryReportResources";
import {
  checkForActionPlanCreationRight,
  checkForSurveyReportRight,
} from "@src/utils/rights";
import { FetchError } from "@src/screens/Insights/errors/FetchError";
import Navbar from "../../components/Navbar/index";
import { fetchFocusAreasIfNeeded } from "@src/store/network/resource/ActionResources";
import {
  focusAreaListActionCreators,
  FocusAreaListPageParameters,
} from "@src/screens/Insights/Actions/store/actions";
import {
  fetchTopicsOverviewIfNeeded
} from "@src/store/network/resource/TopicsReportResources";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import {
  getAllFocusAreasFromReduxStore,
  getCurrentBottomDrawerView,
} from "@src/utils/FocusArea";
import BottomDrawer, { DrawerViews } from "@src/components/core/BottomDrawer";
import BottomDrawerStripedChild, {
  transformFocusAreasToStripStack,
} from "@src/components/core/BottomDrawer/StripedChild";
import { BottomDrawerMessages } from "@src/screens/Insights/BottomDrawer/constant";
import { hasFocusAreaRights } from "hyphen-lib/dist/business/focusArea/focusAreaResource";
import { SurveyTopicCommentResource } from "hyphen-lib/dist/domain/resource/survey/report/SurveyTopicCommentResource";

interface MatchParams {
  id: string;
  categoryId: string;
}

interface CategoryHighlightReportContainerStateProps {
  readonly surveyId: string;
  readonly surveyInfoElement: Store.Element<SurveyInfoResource>;
  readonly company: CompanyResource;
  readonly parameters: ImmutableMap<string, any>;
  readonly anonymityThreshold: number;
  readonly category: Store.Element<CategoryReportResource>;
  readonly hasCommentsAccess: boolean;
  readonly hasTopicsAccess: boolean;
  readonly hasActionCreationRight: boolean;
  readonly focusAreas?: FocusAreaResource[];
  readonly currentBottomDrawerView: DrawerViews;
  readonly hasFaRights: boolean;
  readonly topicsOverview: Store.Element<SurveyTopicCommentResource>;
}

export interface CategoryHighlightReportContainerActionProps {
  readonly onFetchSurveyInfo: (surveyId: string) => void;
  readonly onFetchCategoryReport: (
    surveyId: string,
    categoryId: string,
    queryString: Dictionary<any>
  ) => any;
  readonly onFetchFocusArea: (parameters: FocusAreaListPageParameters) => any;
  readonly onCreateFocusArea: (focusArea: Partial<FocusAreaResource>) => void;
  readonly onFetchTopicsOverview: (
    surveyId: string,
    queryString: Dictionary<any>
  ) => void;
}

const storeMappings: PropMapping[] = [
  {
    localKey: "filter.dimensions",
    storeKey: "surveyCategoryHighlightReport.dimensions",
  },
];

export type OwnProps = RouteComponentProps<MatchParams>;

type Props = OwnProps &
  CategoryHighlightReportContainerStateProps &
  CategoryHighlightReportContainerActionProps;

interface CategoryHighlightReportContainerState {
  totalFocusAreas: number;
}

export class CategoryHighlightReportContainer extends React.Component<
  Props,
  CategoryHighlightReportContainerState
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      totalFocusAreas: 0,
    };
  }
  // noinspection JSMethodCanBeStatic
  extractParametersFromQuery(props: Props) {
    return this.extractRelevantParametersForQuery(
      parseQueryString(props.location.search)
    );
  }

  // noinspection JSMethodCanBeStatic
  extractRelevantParametersForQuery(parameters: any) {
    const relevantParameters = { ...parameters };
    delete relevantParameters.viewOptions;
    return relevantParameters;
  }

  componentDidMount(): void {
    // we might need to apply persisted parameters
    const {
      parameters,
      location: { search },
      surveyInfoElement,
      surveyId,
    } = this.props;

    const existing = parseQueryString(search);
    const mergedParameters = applyExistingParametersIfNeeded(
      parameters.toJS(),
      existing,
      "filter.dimensions",
      "viewOptions.compareWith"
    );
    if (
      isNotNullNorUndefined(mergedParameters) &&
      isNotEmptyObject(mergedParameters)
    ) {
      replaceLocation(mergedParameters);

      if (
        isEmptyObject(this.extractRelevantParametersForQuery(mergedParameters))
      ) {
        // do the fetch now, otherwise we will not do it later, as componentDidUpdate will not see
        // any relevant changes, and not do any fetch
        this.fetchSurveyCategoryHighlight();
        this.fetchSurveyTopicsOverview();
      }
    } else {
      // fetch the category only if we will stay on this page,
      // otherwise it will be fetched anyway in componentDidUpdate
      this.fetchSurveyCategoryHighlight();
      this.fetchSurveyTopicsOverview();
    }

    if (Store.Element.isNotFound(surveyInfoElement)) {
      this.props.onFetchSurveyInfo(surveyId);
    }

    if (this.props.hasFaRights) {
      this.props.onFetchFocusArea(this.fetchFocusAreaProps());
    }

    let totalFocusAreas = 0;
    if (isNotNullNorUndefined(this.props.focusAreas)) {
      totalFocusAreas = this.props.focusAreas.length;
    }

    this.setState({
      totalFocusAreas,
    });
  }

  componentDidUpdate(prevProps: Props) {
    const oldParams = this.extractParametersFromQuery(prevProps);
    const newParams = this.extractParametersFromQuery(this.props);
    const { category, surveyInfoElement } = this.props;
    const isInError =
      mapOr(category, (cat: any) => Store.Element.isInError(cat), false) ||
      mapOr(
        surveyInfoElement,
        (sElem: any) => Store.Element.isInError(sElem),
        false
      );
    if (
      (this.props.surveyId !== prevProps.surveyId ||
        this.props.match.params.categoryId !==
          prevProps.match.params.categoryId ||
        not(areEquals(oldParams, newParams))) &&
      not(isInError)
    ) {
      this.fetchSurveyCategoryHighlight();
      this.fetchSurveyTopicsOverview();
    }

    if (this.props.hasFaRights) {
      this.props.onFetchFocusArea(this.fetchFocusAreaProps());
    }

    if (
      isNotNullNorUndefined(this.props.focusAreas) &&
      isNotNullNorUndefined(prevProps.focusAreas)
    ) {
      if (this.props.focusAreas.length > prevProps.focusAreas.length) {
        this.setState({
          totalFocusAreas: this.props.focusAreas.length,
        });
      }
    }
  }

  fetchFocusAreaProps(): FocusAreaListPageParameters {
    return {
      page: {
        number: 1,
        size: 30,
      },
      filter: {
        withActionPlans: false,
      },
    };
  }

  fetchSurveyCategoryHighlight = () => {
    const categoryId = this.props.match.params.categoryId;
    const queryParams = this.extractParametersFromQuery(this.props);
    const { onFetchCategoryReport, surveyId } = this.props;
    onFetchCategoryReport(
      surveyId,
      categoryId,
      queryParams
    );
  };

  fetchSurveyTopicsOverview = () => {
    const categoryId = this.props.match.params.categoryId;
    const queryParams = this.extractParametersFromQuery(this.props);
    const { onFetchTopicsOverview, surveyId } = this.props;
    const filter = getProperty(queryParams, "filter", {});
    filter.categories = [categoryId];
    if(isNotNullNorUndefined(filter.freeText)) {
      delete filter.freeText;
    }
    onFetchTopicsOverview(
      surveyId,
      { filter }
    );
  };

  render() {
    const {
      match,
      surveyInfoElement,
      category,
      company,
      anonymityThreshold,
      hasCommentsAccess,
      hasTopicsAccess,
      hasActionCreationRight,
      focusAreas,
      onCreateFocusArea,
      hasFaRights,
      topicsOverview,
      surveyId
    } = this.props;

    const categoryName = match.params.categoryId;

    const compareWithOptions = generateCompareWithOptions(
      Store.Element.mapIfLoadedOr(category, getAvailableComparisons, {}),
      company
    );
    if (Store.Element.isInError(category)) {
      return (
        <FetchError {...category} resourceType={CategoryReportResource.TYPE} />
      );
    }
    if (Store.Element.isInError(surveyInfoElement)) {
      return (
        <FetchError
          {...surveyInfoElement}
          resourceType={SurveyInfoResource.TYPE}
        />
      );
    }
    if (Store.Element.isNotLoaded(surveyInfoElement)) {
      return <Loading />;
    }

    const {
      value: { participation, name },
    } = surveyInfoElement;

    return (
      <>
        <Navbar
          style={{ marginBottom: 32, height: "auto" }}
          withActions={false}
          title={decodeURI(match.params.categoryId)}
        />
        <CategoryHighlightReport
          categoryName={categoryName}
          participation={participation}
          category={Store.Element.toLoadable(category)}
          enabledFilters={["dimension"]}
          enabledCustomFilters={["addDimension"]}
          enabledViewOptions={["compareWith"]}
          compareWithOptions={compareWithOptions}
          anonymityThreshold={anonymityThreshold}
          hasCommentsAccess={hasCommentsAccess}
          hasTopicsAccess={hasTopicsAccess}
          hasActionCreationRight={hasActionCreationRight}
          storeMappings={storeMappings}
          onCreateFocusArea={onCreateFocusArea}
          surveyName={name}
          surveyId={surveyId}
          topicsOverview={Store.Element.toLoadable(topicsOverview)}
          questionConfig={company.questionConfig}
        />
        {hasFaRights && (
          <BottomDrawer
            key="segment-drawer"
            initialView={this.props.currentBottomDrawerView}
            title={`${
              this.state.totalFocusAreas === 0
                ? "No"
                : this.state.totalFocusAreas
            } Focus Area Added`}
            footerButton={{
              display: true,
              text: "Go To Action Center",
              onClick: () => goTo("/actioncenter"),
            }}
          >
            <BottomDrawerStripedChild
              stripStack={transformFocusAreasToStripStack(focusAreas)}
              emptyStackMessage={BottomDrawerMessages.EMPTY_STACK_MESSAGE}
            />
          </BottomDrawer>
        )}
      </>
    );
  }
}

const mapStateToProps: MapStateToProps<
  CategoryHighlightReportContainerStateProps,
  OwnProps,
  State
> = (
  state: State,
  ownProps: OwnProps
): CategoryHighlightReportContainerStateProps => {
  const {
    match: {
      params: { id: surveyId, categoryId },
    },
  } = ownProps;
  const { filter } = parseQueryString(ownProps.location.search);

  const data = getAllFocusAreasFromReduxStore(state);
  const currentBottomDrawerView = getCurrentBottomDrawerView(state);
  const hasFaRights = hasFocusAreaRights(getRightsMatcher(state));
  const skipFreeText = objectOmit(getOr(filter, {}), "freeText");
  return {
    surveyId,
    surveyInfoElement: getResourceById(
      state,
      SurveyInfoResource.TYPE,
      surveyId
    ),
    category: getResourceById(
      state,
      CategoryReportResource.TYPE,
      CategoryReportResource.generateId(categoryId, filter, surveyId)
    ),
    topicsOverview: getResourceById(
      state,
      SurveyTopicCommentResource.TYPE,
      SurveyTopicCommentResource.generateKey(
        surveyId,
        { categories: [categoryId], ...skipFreeText }
      )
    ),
    company: getCompany(state)!,
    parameters: getParameters(state),
    anonymityThreshold: getAnonymityThreshold(state),
    hasCommentsAccess: checkForSurveyReportRight(
      getRightsMatcher(state),
      "comments"
    ),
    hasTopicsAccess: checkForSurveyReportRight(
      getRightsMatcher(state),
      "topics"
    ),
    hasActionCreationRight: checkForActionPlanCreationRight(state),
    focusAreas: data,
    currentBottomDrawerView,
    hasFaRights,
  };
};

const mapDispatchToProps = {
  onFetchSurveyInfo: fetchSurveyInfoIfNeeded,
  onFetchCategoryReport: fetchCategoryReportIfNeeded,
  onFetchFocusArea: fetchFocusAreasIfNeeded,
  onCreateFocusArea: focusAreaListActionCreators.createFocusArea,
  onFetchTopicsOverview: fetchTopicsOverviewIfNeeded
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryHighlightReportContainer);
