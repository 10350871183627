import React from "react";
import { ThumbsUp } from "@src/components/core/svg/ThumbsUp";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";

interface VotesProps {
  readonly count: number;
  readonly down?: boolean;
}

export function Votes({ count, down }: VotesProps) {
  return (
    <Container down={down}>
      <ThumbsUp /><span>{count}</span>
    </Container>
  );
}

const Container = styled.div<{ down?: boolean }>`
  display: flex;
  align-items: center;
  color: ${Palette.bluishGrey};

  svg {
    ${props => props.down ? "transform: rotateX(180deg);" : ""}
    color: currentColor;
    height: 12px;
    vertical-align: middle;
    margin-right: 5px;
  }
`;

Container.displayName = "Votes";
