import React from "react";
import ContainerCard from "@src/components/core/ContainerCard";
import withDebounce from "@src/components/core/withDebounce";
import Input from "@src/components/core/Input";
import ImageUpload from "@src/components/core/ImageUpload";
import { InputProps } from "antd/lib/input";
import styled from "styled-components";
import * as notificationFactory from "@src/store/notifications/notification-factory";
import { isNullOrUndefined } from "hyphen-lib/dist/lang/Objects";
import Palette from "@src/config/theme/palette";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { CompanySettingsProps } from "../containers/CompanySettings";
import { withTranslation } from "react-i18next";

const NewInput = withDebounce(Input as any);

interface CompanySettingsGeneralProps {
  updateCompany: CompanySettingsProps["updateCompany"];
  company: CompanySettingsProps["company"];
  uploadCompanyLogo: CompanySettingsProps["uploadCompanyLogo"];
  displayNotification: CompanySettingsProps["displayNotification"];
  uploadDarkLogo: CompanySettingsProps["uploadDarkLogo"];
}

type uploadActionType =
  CompanySettingsGeneralProps["uploadCompanyLogo"] |
  CompanySettingsGeneralProps["uploadDarkLogo"];

interface StateProps {
  nameLengthExceeded: boolean;
}

class CompanySettingsGeneral extends React.Component<CompanySettingsGeneralProps,StateProps> {

  constructor( props: CompanySettingsGeneralProps ) {
    super(props);
    this.state = { nameLengthExceeded: false };
  }

  doUploadLogo = (err: any, file: any, action: uploadActionType) => {
    const { company, displayNotification } = this.props;
    if (err.type) {
      const message = (err.type === "FileTypeError") ? "Sorry, that file isn't right" : "File too big";
      const notification = notificationFactory.error(message, err.description, 4.5);
      displayNotification(notification);
    } else {
      action(company.name, file);
    }
  };

  doUploadCompanyLogo = (err: any, file: any) => {
    this.doUploadLogo(err, file, this.props.uploadCompanyLogo);
  };

  doUploadDarkLogo = (err: any, file: any) => {
    this.doUploadLogo(err, file, this.props.uploadDarkLogo);
  };

  removeCompanyLogo = () =>
    this.props.updateCompany(this.props.company.name, {...this.props.company, logo: undefined});

  removeDarkLogo = () =>
    this.props.updateCompany(this.props.company.name, {...this.props.company, darkLogo: undefined});

  updateDisplayName = (displayName: string) => {
    if ( displayName.length < 50 ) {

      this.props.updateCompany(this.props.company.name, {...this.props.company, displayName});
      this.setState({nameLengthExceeded: false}) ;

    } else {
      this.setState({
        nameLengthExceeded: true,
      }) ;
    }
  } ;

  checkErrors = () => {
    return this.state.nameLengthExceeded ? <Warning>The max length should be: 50</Warning> : "";
  };

  updateCommunicationName = (communicationName: string) =>
    this.props.updateCompany(this.props.company.name, {...this.props.company, communicationName});

  companyNameValue = () => {
    if (
      Object.keys(this.props.company).includes("displayName") &&
      isNotNullNorUndefined(this.props.company.displayName)
    ) {
      return this.props.company.displayName;
    } else {
      const companyName = this.props.company.name
        .substr(0, this.props.company.name.lastIndexOf("."))
        .toUpperCase();
      return companyName;
    }
  };

  communicationNameValue = () => {
    if (
      Object.keys(this.props.company).includes("communicationName") &&
      isNotNullNorUndefined(this.props.company.communicationName)
    ) {
      return this.props.company.communicationName;
    } else {
      return "Betterworks Engage";
    }
  };

  render () {
    const {company} = this.props;
    return (
      <ContainerCard title="General">
        <TextInput
          label="Company name"
          value={this.companyNameValue()}
          onValueChange={this.updateDisplayName}
          maxLength={50}
          waitTime={800}
        />
        { this.checkErrors() }
        <TextInput
          label="'From' name in Betterworks Engage emails"
          value={this.communicationNameValue()}
          description=
            "If left empty, all emails from Betterworks Engage will be sent with a sender name ‘Betterworks Engage’."
          onValueChange={this.updateCommunicationName}
          waitTime={800}
        />
        <ImageUpload
          style={{marginBottom: 0}}
          descriptionStyle={{height: "auto"}}
          label="Company logo"
          description="Logo must be PNG/JPG/JPEG. If no logo is uploaded, the Betterworks Engage logo will be used."
          buttonText="Upload a logo"
          maxImageSize={1048576}
          previewText=""
          onSelect={this.doUploadCompanyLogo}
          imageId={isNullOrUndefined(company.logo) ? "" : company.logo.id}
          removeImage={this.removeCompanyLogo}
          translate="yes"
        />
        <ImageUpload
          style={{marginBottom: 0}}
          descriptionStyle={{height: "auto"}}
          label="Company logo for dark backgrounds"
          description="PNG with transparent background recommended."
          buttonText="Upload a logo"
          maxImageSize={1048576}
          previewText=""
          onSelect={this.doUploadDarkLogo}
          imageId={isNullOrUndefined(company.darkLogo) ? "" : company.darkLogo.id}
          removeImage={this.removeDarkLogo}
          translate="yes"
        />
      </ContainerCard>
    );
  }
}

export default withTranslation()(CompanySettingsGeneral);

// eslint-disable-next-line max-len
const TextInput = styled(NewInput)<InputProps & { label: string; onValueChange: any; description?: string; waitTime?: number }>`
  margin-top: 24px !important;

  .ant-input {
    width: 365px;
  }
`;

const Warning = styled.p`
  color: ${Palette.darkPink};
`;
