import React from "react";
import { connect } from "react-redux";

import { State } from "@store/types";
import { applyDefault } from "@src/utils/Currier";

import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { getOr } from "hyphen-lib/dist/lang/Objects";
import { ActionResource } from "hyphen-lib/dist/domain/resource/action/ActionResource";
import { PostCategoryResource } from "hyphen-lib/dist/domain/resource/post/PostCategoryResource";

import { getExistingCount } from "@store/network/selectors";

import { FiltersContent } from "@screens/Insights/components/FiltersContent";
import {
  ActionStatusFilter,
  ActionSourceFilter,
  CategoryFilter,
  ModuleFilter
} from "@screens/Insights/components/Filters/business/ActionPlanFilters";
import { DateFilter } from "@screens/Insights/components/Filters/business/DateFilter";
import { FlagFilter } from "@src/screens/Insights/components/Filters/business/FlagFilter";
import {
  actionListFiltersActionCreators
} from "@screens/Insights/Actions/containers/ActionListFilterContainer/store/actions";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { fetchActionsCountIfNeeded } from "@store/network/resource/ActionResources";
import { getCompany } from "@src/screens/Insights/store/selectors";
import { mapOr } from "hyphen-lib/dist/lang/Objects";
import { Company } from "hyphen-lib/dist/domain/Company";
import { mapCompanyModulesToFiltersArray } from "@src/utils/helper";
import { getFilter } from "./store/selectors";

export interface ActionListFilterContainerStateProps {
  readonly innerValues: Dictionary<any>;
  readonly count: number;
  readonly countLoading: boolean;
  readonly modules: { label: string; value: string }[];
}

export interface ActionListFilterContainerActionProps {
  readonly onFetchCountIfNeeded: (filters: any) => any;
  readonly onModifyFilter: (filters: any) => any;
  readonly onCleanFilter: () => any;
}

export interface ActionListFilterContainerOwnProps {
  readonly values?: Dictionary<any>;
  readonly onApply: (filters: any) => any;
  readonly categories: PostCategoryResource[];
}

export type ActionListFilterContainerProps = ActionListFilterContainerOwnProps &
ActionListFilterContainerStateProps &
ActionListFilterContainerActionProps;

export class ActionListFilter extends React.Component<ActionListFilterContainerProps> {
  componentDidMount() {
    /*
        Modify filters with initial values while mounting.
     */
    this.props.onModifyFilter(getOr(this.props.values, {}));
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.countLoading) {
      /*
        Count is not loaded, maybe we might want to fetch it.
      */
      this.props.onFetchCountIfNeeded(this.props.innerValues);
    }
  }

  componentWillUnmount(): void {
    this.props.onCleanFilter();
  }

  render() {
    const {
      values,
      categories,
      count,
      countLoading,
      onApply,
      onModifyFilter,
      modules,
    } = this.props;

    return (
      <FiltersContent
        filters={[
          {
            key: "sources",
            label: "Source type",
            component: ActionSourceFilter,
          },
          {
            key: "statuses",
            label: "Status",
            component: ActionStatusFilter,
          },
          {
            key: "dueDate",
            label: "Due Date",
            component: DateFilter,
          },
          {
            key: "categories",
            label: "Categories",
            component: applyDefault(CategoryFilter, {
              categories,
            }),
          },
          ...(modules.length > 1
            ? [
              {
                key: "modules",
                label: "Modules",
                component: applyDefault(ModuleFilter, {
                  modules,
                }),
              },
            ]
            : []),
          {
            key: "overdue",
            label: "Only overdue",
            component: FlagFilter,
            changeValueOnAdd: {
              defaultValue: true,
            },
          },
          {
            key: "assignedToMe",
            label: "Assigned To Me",
            component: FlagFilter,
            changeValueOnAdd: {
              defaultValue: true,
            },
          },
          {
            key: "createdByMe",
            label: "Created By Me",
            component: FlagFilter,
            changeValueOnAdd: {
              defaultValue: true,
            },
          },
        ]}
        displayCount={true}
        count={count}
        countLoading={countLoading}
        onChange={onModifyFilter}
        onApply={onApply}
        values={values}
      />
    );
  }
}

function mapStateToProps(
  state: State,
  ownProps: ActionListFilterContainerOwnProps
): ActionListFilterContainerStateProps {
  const innerFilterValues = getFilter(state);

  /*
  When loading the component for the first time, the inner values are empty,
  so active filters are the one from the props.
  */
  const activeValues = getOr(innerFilterValues, getOr(ownProps.values, {}));
  const companyModules: Company.Modules = mapOr(
    getCompany(state),
    map => map.modules,
    {
      surveys: false,
      pulsePolls: false,
      employeeVoice: false,
      employeeLifeCycle: false,
    }
  );

  const modules = mapCompanyModulesToFiltersArray(companyModules);

  /*
      Try to get the count based on active filter
   */
  const key = ActionResource.generateKey(activeValues);
  const count = getExistingCount(state, ActionResource.TYPE, key);

  return {
    innerValues: getOr(innerFilterValues, {}),
    count: Store.Count.isLoaded(count) ? count.count : 0,
    countLoading: not(Store.Count.isLoaded(count)),
    modules,
  };
}

const mapDispatchToProps = {
  onFetchCountIfNeeded: fetchActionsCountIfNeeded,
  onModifyFilter: actionListFiltersActionCreators.modifyFilter,
  onCleanFilter: actionListFiltersActionCreators.cleanFilter,
};

export const ActionListFilterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActionListFilter);
