import {Component} from "react";
import {withRouter,RouteComponentProps} from "react-router-dom";
import {Login} from "./containers";

type OwnProps = RouteComponentProps;

export type Props = OwnProps;

export class LoginView extends Component<Props>{

  render() {
    return(<Login />);
  }
}

export default withRouter(LoginView);