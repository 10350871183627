import styled from "styled-components";
import { Table as AntTable } from "antd";
import Palette from "@src/config/theme/palette";
import { TableProps } from "antd/lib/table";
import NoResult from "../NoResult";

function Table(props: TableProps<any>) {
  const locale = {
    emptyText: (<NoResult type="minimal" description="No Data" />),
  };
  return (
    <StyledTable
      locale={locale}
      {...props}
    />
  );
}

export default Table;

/* eslint-disable max-len */
const StyledTable = styled(AntTable)<TableProps<any>>`
  .ant-table-fixed-left table thead tr th {
    background-color: #fff;

    padding: 16px;

    font-family: Lato;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: ${Palette.veryDarkBlueGrey};
      padding-left: 32px;


    :last-child {
      padding-right: 32px;
    }

    :hover {
      background-color: ${Palette.lightLightBlue};
    }
  }

  .ant-table-fixed-left table tbody tr td {
    padding-left: 32px;
  }

  .ant-table-row-cell-break-word {
    word-break: unset;
  }

  .ant-table-fixed-right {
    box-shadow: none;
  }

  .ant-table-fixed-right tbody {
    box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.15);
  }

  .ant-table-fixed-right th {
    background: none !important;
  }

  .ant-table-body > table {
    background-color: #fff;
  }

  .ant-table-body > table > tbody > tr {
    height: 90px;
  }

  .ant-table-body > table > tbody > tr:hover > td {
    background-color: ${Palette.lightLightBlue} !important;
  }

  .ant-table-fixed-left > table > tbody > tr:hover > td {
    background-color: ${Palette.lightLightBlue};
  }

  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background-color: ${Palette.lightLightBlue};
  }

  .ant-table-row-hover > td {
    background-color: ${Palette.lightLightBlue} !important;
  }

  .ant-table-body > table tr > td, .ant-table-body > table tr > th {
    border-bottom-color: ${Palette.veryLightBlue};
    border-bottom-width: 1px;
  }

  .ant-table-body > table tr > td {
    padding: 0 10px;
    :first-child {
      padding-left: 32px;
    }

    :last-child {
      padding-right: 32px;
    }
  }

  .ant-table-body > table tr > th {
    padding-top: 0;
    padding-left: 0;
  }

  .ant-table-body > table > thead > tr > th {
    background-color: #fff;
    padding: 16px;
    font-family: Lato;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: ${Palette.veryDarkBlueGrey};
    border-bottom: 1px solid ${Palette.lightGreyBlue};

    :first-child {
      padding-left: 32px;
    }

    :last-child {
      padding-right: 32px;
    }
  }

  th.ant-table-selection-column, th.ant-table-column-has-actions {
    border-bottom: 1px solid ${Palette.lightGreyBlue};
  }

  .ant-table-selection-column {
    font-size: 15px !important;
    color: ${Palette.veryDarkBlueGrey} !important;
    .ant-checkbox-inner {
      border-color: ${Palette.bluePurple} !important;
      background-color: white !important;
    }
    .ant-checkbox-inner::after {
      border-color: ${Palette.bluePurple} !important;
    }

    .ant-checkbox-indeterminate {
      .ant-checkbox-inner::after {
        background-color: ${Palette.bluePurple} !important;
      }
    }
    .ant-checkbox-disabled .ant-checkbox-inner {
      border-color: ${Palette.lightPeriwinkle} !important;
      background-color: ${Palette.lightGrey};
    }
  }

  /* ~~~~~~~~~~~~~~~~~~~~~~~ */
  /* ~~~ pagination part ~~~ */
  /* ~~~~~~~~~~~~~~~~~~~~~~~ */

  ul.ant-pagination.ant-table-pagination {
    margin: 0;
    padding: 32px;
    background: ${Palette.white};
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }

  /* summary */

  ul.ant-pagination.ant-table-pagination > li.ant-pagination-total-text {
    position: absolute;
    left: 32px;
    border: 0;
    width: auto;

    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: ${Palette.bluishGrey};
  }

  /* page switchers */

  ul.ant-pagination.ant-table-pagination > li {
    margin: 0 0 0 -1px;
    border-radius: 0;
    z-index: 1;
    border: 1px solid ${Palette.lightPeriwinkle};
    background-color: #fdfdfd;
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
  }

  ul.ant-pagination.ant-table-pagination > li > a {
    margin: 0 auto;
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${Palette.lightGreyBlue};
  }

  ul.ant-pagination.ant-table-pagination > li.ant-pagination-item-active,
   ul.ant-pagination.ant-table-pagination > li:hover {
    z-index: 2;
    border-color: ${Palette.bluePurple};
  }

  ul.ant-pagination.ant-table-pagination > li.ant-pagination-item-active > a,
   ul.ant-pagination.ant-table-pagination > li:hover > a {
    color: ${Palette.bluePurple};
  }

  ul.ant-pagination.ant-table-pagination .ant-pagination-item-link {
    border-radius: 0;
  }

  /* arrows: left, right */

  ul.ant-pagination.ant-table-pagination > li.ant-pagination-prev,
   ul.ant-pagination.ant-table-pagination > li.ant-pagination-next {
    border: 0;
  }

  ul.ant-pagination.ant-table-pagination > li.ant-pagination-prev > .ant-pagination-item-link,
   ul.ant-pagination.ant-table-pagination > li.ant-pagination-next > .ant-pagination-item-link {
    border: 1px solid ${Palette.lightGreyBlue};
    color: #8498b3;
    background-image: linear-gradient(to bottom, #ffffff, #f9fafb) !important;
    height: 36px;
    width: 36px;
  }

  ul.ant-pagination.ant-table-pagination > li.ant-pagination-disabled > .ant-pagination-item-link {
    border: 1px solid ${Palette.lightPeriwinkle};
    color: ${Palette.paleGrey};
  }

  ul.ant-pagination.ant-table-pagination > li.ant-pagination-prev:hover:not(.ant-pagination-disabled) > .ant-pagination-item-link,
   ul.ant-pagination.ant-table-pagination > li.ant-pagination-next:hover:not(.ant-pagination-disabled) > .ant-pagination-item-link {
    border-color: ${Palette.bluePurple};
  }

  ul.ant-pagination.ant-table-pagination > li.ant-pagination-prev > .ant-pagination-item-link {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  ul.ant-pagination.ant-table-pagination > li.ant-pagination-next > .ant-pagination-item-link {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  ul.ant-pagination.ant-table-pagination > li > .ant-pagination-item-link > i {
    display: none;
  }

  ul.ant-pagination.ant-table-pagination > li.ant-pagination-prev > a,
   ul.ant-pagination.ant-table-pagination > li.ant-pagination-next > a{
    display: flex;
    align-items: center;
    text-align: center;
  }

  ul.ant-pagination.ant-table-pagination > li.ant-pagination-prev > .ant-pagination-item-link:after {
    content: "⬅"; // replace that by real svg as soon as we get it
    width: 100%;
  }

  ul.ant-pagination.ant-table-pagination > li.ant-pagination-next > .ant-pagination-item-link:after {
    content: "➡";
    width: 100%;
  }

  /* small dots... */

  ul.ant-pagination.ant-table-pagination > li.ant-pagination-jump-next,
   ul.ant-pagination.ant-table-pagination > li.ant-pagination-jump-prev {
    height: 36px;
    width: 36px;
  }

  ul.ant-pagination.ant-table-pagination > li.ant-pagination-jump-next > a.ant-pagination-item-link,
   ul.ant-pagination.ant-table-pagination > li.ant-pagination-jump-prev > a.ant-pagination-item-link{
    width: 100%;
    content: "...";
  }

  ul.ant-pagination.ant-table-pagination > li.ant-pagination-jump-next > a.ant-pagination-item-link:after,
   ul.ant-pagination.ant-table-pagination > li.ant-pagination-jump-prev > a.ant-pagination-item-link:after{
    content: "...";
  }

  ul.ant-pagination.ant-table-pagination > li.ant-pagination-jump-next > a.ant-pagination-item-link > .ant-pagination-item-container,
  ul.ant-pagination.ant-table-pagination > li.ant-pagination-jump-prev > a.ant-pagination-item-link > .ant-pagination-item-container {
  display: none; // hide classic dots from antd
}
`;
/* eslint-disable max-len */