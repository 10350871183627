import { call, put, takeLatest } from "@redux-saga/core/effects";
import { freeze } from "hyphen-lib/dist/lang/Objects";
import {
  actionCreators as networkActionCreators,
  CleanResourceAction,
  NetworkRequestAction
} from "@store/network/actions";
import { pushLocation, replaceLocation } from "@src/utils/locations";
import { UserPermissionResource } from "hyphen-lib/dist/domain/resource/user/UserPermissionResource";
import {
  ModifyPermissionsListAction,
  UpdateUserPermissionsAction,
  userPermissionsActionCreators,
  UserPermissionsActionTypes
} from "@screens/Insights/UserManagement/containers/UserPermissions/store/actions";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";

export function* updateLocationUrl(replace: boolean,
  { payload: { filter, sort, page } }: ModifyPermissionsListAction) {

  const locationUpdater = replace ? replaceLocation : pushLocation;
  yield call(
    locationUpdater, {
      filter,
      sort,
      page: page.number,
    });
}

export function* updatePermissions({ payload }: UpdateUserPermissionsAction) {

  yield put<NetworkRequestAction>(
    userPermissionsActionCreators.updateUserPermissionsRequest(payload)
  );
}

export function* resetResources() {
  yield put<CleanResourceAction>
  (networkActionCreators.cleanResource(UserPermissionResource.TYPE));
}

export function* reFetchResource() {
  yield put<CleanResourceAction>(networkActionCreators.cleanResource(CurrentUserResource.TYPE));
}

export const userPermissionsSagas = freeze([

  // ~~~~~~~~~~~~~ URL MANAGEMENT ~~~~~~~~~~~~~~~~

  /*
    Listen for modification of list, in order to push the new state in the URL.
   */
  takeLatest(
    UserPermissionsActionTypes.MODIFY_PERMISSIONS_LIST,
    updateLocationUrl.bind(null, false)
  ),

  takeLatest(
    UserPermissionsActionTypes.UPDATE_USER_PERMISSIONS,
    updatePermissions
  ),

  takeLatest(
    [UserPermissionsActionTypes.UPDATE_USER_PERMISSIONS_SUCCESS],
    resetResources
  ),

  takeLatest(
    UserPermissionsActionTypes.UPDATE_USER_PERMISSION_SUCCESS,
    reFetchResource
  ),
]);
