import { Record } from "immutable";

import { NetworkEventSuccessAction } from "@store/network/actions";

import { removeSessionToken, saveSessionToken } from "@src/utils/sessionStores";
import { getOr, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { InsightsStateProps, State } from "./types";
import { ActionTypes } from "./actions";

const defaultValues: InsightsStateProps = {
  sessionToken: null,
  currentUserId: null,
  isLoading: false,
  emailSuccess: false,
  emailFailure: false,
  emailFailureMessage: undefined,
  passwordFailure: false,
  passwordSuccess: false,
  passwordFailureMessage: undefined,
};

const setSessionToken = (sessionToken: any) : any => {
  saveSessionToken(sessionToken);
};

export const stateFactory = Record<InsightsStateProps>(defaultValues);
const defaultState = stateFactory();

export const sessionSuccessReducer =
  (state: State = defaultState, { payload }: NetworkEventSuccessAction): State => {
    if (payload.data) {
      return state
        .set("sessionToken", payload.data.sessionToken || null)
        .set("currentUserId", null);
    }

    return state;
  };

export const currentUserSuccessReducer =
  (state: State = defaultState, { payload }: NetworkEventSuccessAction): State => {
    if (payload.data) {
      return state
        .set("currentUserId", payload.data._id || null);
    }

    return state;
  };

export const currentUserErrorReducer =
  (state: State = defaultState): State => {

    removeSessionToken();

    return state
      .set("currentUserId", null);
  };

export const reducers = (state: State = defaultState, action: any) => {
  switch (action.type) {
    case ActionTypes.FETCH_SESSION_SUCCESS:
      return sessionSuccessReducer(state, action);

    case ActionTypes.FETCH_CURRENT_USER_SUCCESS:
      return currentUserSuccessReducer(state, action);

    case ActionTypes.FETCH_CURRENT_USER_ERROR:
      return currentUserErrorReducer(state);

    case ActionTypes.SEND_EMAIL_REQUEST:
      return state.set("isLoading",true);
    case ActionTypes.SEND_EMAIL_SUCCESS:
      return state
        .merge({
          isLoading: false,
          emailSuccess: true,
          passwordFailure: false,
          passwordFailureMessage: undefined,
        });
    case ActionTypes.SEND_EMAIL_ERROR:
      return state
        .merge({
          isLoading: false,
          emailFailureMessage: isNotNullNorUndefined(action.payload.error) ? action.payload.error.message : undefined,
          emailFailure: true,
        });
    case ActionTypes.SEND_CODE_REQUEST:
      return state.set("isLoading",true);
    case ActionTypes.SEND_CODE_SUCCESS:
      setSessionToken(action.payload.data.sessionToken);
      return state
        .merge({ isLoading: false, passwordSuccess: true,sessionToken: action.payload.data.sessionToken});
    case ActionTypes.SEND_CODE_ERROR:
      return state
        .merge({
          isLoading: false,
          passwordFailure: true,
          passwordFailureMessage: getOr(action.payload.error.message, undefined),
        });
    case ActionTypes.LOGIN_STATUS_REFRESH:
      return state
        .merge({
          isLoading: false,
          emailSuccess: false,
          emailFailure: false,
          passwordFailure: false,
          passwordSuccess: false });
    case ActionTypes.LOGOUT_USER:
      removeSessionToken();
      action.payload();
      return state
        .merge({
          isLoading: false,
          emailSuccess: false,
          emailFailure: false,
          passwordFailure: false,
          passwordSuccess: false,
          currentUserId: null,
          sessionToken: null,
        });
    default:
      return state;
  }
};

export default reducers;
