import { FSA } from "flux-standard-action";
import { Notification } from "./types";

export enum ActionTypes {
  DISPLAY_NOTIFICATION = "DISPLAY_NOTIFICATION",
  REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION",
}

export interface Payloads {
  [ActionTypes.DISPLAY_NOTIFICATION]: Notification;
  [ActionTypes.REMOVE_NOTIFICATION]: string;
}

export interface ShowNotificationAction
  extends FSA<ActionTypes.DISPLAY_NOTIFICATION, Payloads[ActionTypes.DISPLAY_NOTIFICATION]> {
  type: ActionTypes.DISPLAY_NOTIFICATION;
  payload: Payloads[ActionTypes.DISPLAY_NOTIFICATION];
}

export interface RemoveNotificationAction
  extends FSA<ActionTypes.REMOVE_NOTIFICATION, Payloads[ActionTypes.REMOVE_NOTIFICATION]> {
  type: ActionTypes.REMOVE_NOTIFICATION;
  payload: Payloads[ActionTypes.REMOVE_NOTIFICATION];
}

const actionCreators = {
  displayNotification: (payload: ShowNotificationAction["payload"]): ShowNotificationAction => ({
    type: ActionTypes.DISPLAY_NOTIFICATION,
    payload,
  }),
  removeNotification: (payload: RemoveNotificationAction["payload"]): RemoveNotificationAction => ({
    type: ActionTypes.REMOVE_NOTIFICATION,
    payload,
  }),
};

export default actionCreators;