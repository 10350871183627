import { Record, RecordOf } from "immutable";
import { PollReportActionTypes } from "../../../store/actions";

export interface PulsePollCommentsReportStateProps {
  readonly commentText: string;
  readonly errorText: string;
  readonly isPrivateMessageModalVisible: boolean;
  readonly isSendMessageRequesting: boolean;
  readonly postId: string;
  readonly privateMessageText: string;
  readonly templateId: string;
  readonly voteId: string;
  readonly origin: string;
}

export type PulsePollCommentsReportState = RecordOf<PulsePollCommentsReportStateProps>;

const defaultValues: PulsePollCommentsReportStateProps = {
  commentText: "",
  errorText: "",
  isPrivateMessageModalVisible: false,
  isSendMessageRequesting: false,
  postId: "",
  privateMessageText: "",
  templateId: "",
  voteId: "",
  origin: "",
};

export const pulsePollCommentsReportStateFactory = Record<PulsePollCommentsReportStateProps>(defaultValues);
const defaultState = pulsePollCommentsReportStateFactory();

export function pulsePollCommentsReportReducers(
  state: PulsePollCommentsReportState = defaultState,
  action: any
) {
  switch (action.type) {
    case PollReportActionTypes.SEND_PRIVATE_MESSAGE_REQUEST:
      return state.merge({isSendMessageRequesting: true, errorText: ""});
    case PollReportActionTypes.SEND_PRIVATE_MESSAGE_ERROR:
      return state.merge({
        isSendMessageRequesting: false,
        errorText: "Unexpected error occurred while sending messsage.",
      });
    case PollReportActionTypes.SEND_PRIVATE_MESSAGE_SUCCESS:
      return state.merge({...defaultValues});
    case PollReportActionTypes.CLEAR_PRIVATE_MESSAGE_MODAL:
      return state.merge({...defaultValues});
    case PollReportActionTypes.UPDATE_PRIVATE_MESSAGE_TEXT:
      return state.set("privateMessageText", action.payload);
    case PollReportActionTypes.TOGGLE_PRIVATE_MESSAGE_MODAL:
      return (!(state.get("isPrivateMessageModalVisible"))) 
        ? state.merge({...action.payload, privateMessageText: "", isPrivateMessageModalVisible: true })
        : state.merge({...defaultValues});
    default:
      return state;
  }
}