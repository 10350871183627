import { Record } from "immutable";

import {
  dump, isNotNullNorUndefined, isNullOrUndefined, mapValues, isNotEmptyObject, getProperty
} from "@hyphen-lib/lang/Objects";
import Logger from "@hyphen-lib/util/Logger";
import { BaseResource } from "@hyphen-lib/domain/trait/BaseResource";
import { isStringAndNotEmpty } from "@hyphen-lib/lang/Strings";
import { PaginatedResources } from "@hyphen-lib/domain/resource/PaginatedResources";
import { CountResources } from "@hyphen-lib/domain/resource/CountResources";
import { getResourcesStore } from "@store/network/Stores";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { ResourceStores, resourceStoresSuppliers, UsedResources } from "@store/network/ResourceStoresDefinitions";
import { Mapper } from "hyphen-lib/dist/lang/Functions";
import { State, StateProps, ValueOf } from "./types";
import {
  ActionTypes,
  CleanResourceAction,
  Meta,
  NetworkErrorAction,
  NetworkRequestAction,
  NetworkSuccessAction,
  RequestInfo,
  RequestInfoType,
  UNTYPED_TYPE
} from "./actions";

const log = Logger.create("store/network/reducers");

export const resourceStoresFactory = () => {
  const resourceStoresInstance = mapValues(
    resourceStoresSuppliers,
    supplier => supplier()
  ) as ResourceStores; // typescript does not manage to infer, mapValues is using dictionaries...
  return Record<ResourceStores>(resourceStoresInstance)();
};

export const stateFactory = () => {
  const resources = resourceStoresFactory();
  return Record<StateProps>({ resources })();
};
const defaultState = stateFactory();

function addResourceToState<R extends ValueOf<UsedResources>>(resource: R,
  state: State,
  meta?: Meta[ActionTypes.NETWORK_SUCCESS]) {
  const store: Store<R> =
    // fixme we can probably manage to not do cast, at least not the last one :(
    getResourcesStore(state, resource._type as keyof UsedResources) as Store<R>;
  if (isNullOrUndefined(store)) {
    log.warn(`drop received document ${resource._id}, unable to find store for type ${resource._type}`);
  } else {
    state = state.setIn(
      [
        "resources",
        resource._type,
      ],
      store.setLoadedElement(
        isNotNullNorUndefined(meta) && isNotNullNorUndefined(meta.info) ? meta.info.key : resource._id,
        resource
      )
    );
  }
  return state;
}

function addUntypedPayloadToState(payload: any,
  state: State,
  info: RequestInfo) {
  const store: Store<any> = getResourcesStore(state, UNTYPED_TYPE);
  if (isNullOrUndefined(store)) {
    log.warn(`drop received untyped document ${dump(payload)}, unable to find store`);
  } else {
    state = state.setIn(
      [
        "resources",
        UNTYPED_TYPE,
      ],
      store.setLoadedUnidentifiedElement(
        info.key,
        payload
      )
    );
  }
  return state;
}

const addResourcesToState = (mutableState: State, resources: any) => {
  for (const resource of resources) {
    if (isStringAndNotEmpty(resource._id) && isStringAndNotEmpty(resource._type)) {
      addResourceToState(resource as BaseResource, mutableState);
    } else {
      log.warn(
        `drop received document it does not contains an _id and a _type, is it really a resource? => ${dump(resource)}`
      );
    }
  }
};

export const cleanResourceReducer =
  (state: State = defaultState, { payload: resourceType }: CleanResourceAction): State => {
    return state.setIn(["resources", resourceType], resourceStoresSuppliers[resourceType!]());
  };

export const successReducer = (state: State = defaultState, { payload, meta }: NetworkSuccessAction): State => {
  if (isNotNullNorUndefined(meta) && isNotNullNorUndefined(meta.options) && meta.options.noCache === true) {
    return state; // just don't store anything, as we ask for no cache.
  }

  // We check if the payload is an array of Resource objects or an object of type Resource
  // If so, we store them in its designated collection, in turn updating any existing resource of the same _id
  // tslint:disable-next-line: no-console
  if (isNotNullNorUndefined(payload)) {
    if (isNotNullNorUndefined(meta) &&
      isNotNullNorUndefined(meta.info) &&
      meta.info.infoType === RequestInfoType.UNTYPED) {
      state = addUntypedPayloadToState(payload, state, meta.info);
    } else if (Array.isArray(payload)) {
      state = reduceArray(state, payload, meta);
    } else if (isStringAndNotEmpty(payload._id) && isStringAndNotEmpty(payload._type)) {
      state = addResourceToState(payload as BaseResource, state, meta);
    } else if (PaginatedResources.isPaginatedResource(payload)) {
      const store: Store<any> = getResourcesStore(state, payload.meta.type as keyof UsedResources);
      if (isNullOrUndefined(store)) {
        log.warn(`drop received paginated response, unable to find store for type ${payload.meta.type}`);
        // eslint-disable-next-line max-len
        console.warn("reducers.ts:line:136", `drop received paginated response, unable to find store for type ${payload.meta.type}`);
      } else {
        state = state.setIn(
          [
            "resources",
            payload.meta.type,
          ],
          store.setLoadedPage(
            isNotNullNorUndefined(meta) && isNotNullNorUndefined(meta.info) ? meta.info.key : payload.meta.key,
            {
              number: payload.meta.pageNumber,
              size: payload.meta.pageSize,
            },
            payload.data,
            payload.meta.total
          )
        );
      }
    } else if (CountResources.isCountResources(payload)) {
      const store: Store<any> = getResourcesStore(state, payload.type as keyof UsedResources);
      if (isNullOrUndefined(store)) {
        log.warn(`drop received count response, unable to find store for type ${payload.type}`);
      } else {
        state = state.setIn(
          [
            "resources",
            payload.type,
          ],
          store.setLoadedCount(
            isNotNullNorUndefined(meta) && isNotNullNorUndefined(meta.info) ? meta.info.key : payload.key,
            payload.count
          )
        );
      }
    }
  }

  return state;
};

function reduceArray(state: State, payload: any[], meta?: Meta[ActionTypes.NETWORK_SUCCESS]): State {
  if (isNotNullNorUndefined(meta) && isNotNullNorUndefined(meta.info)) {
    if (meta.info.infoType === RequestInfoType.PAGE) {
      /*
        A page info has been attached to the request, so store the array of data in the page.
       */
      const store: Store<any> = getResourcesStore(state, meta.info.type as keyof UsedResources);
      return state.setIn(
        [
          "resources",
          meta.info.type,
        ],
        store.setLoadedPage(
          meta.info.key,
          meta.info.page,
          payload,
          payload.length
        )
      );
    } else {
      // eslint-disable-next-line max-len
      log.warn(`We received a request with some info, the payload is an array, but the info does not contain any page information but ${dump(meta.info)}, so we will just ignore the info...`);
    }
  }

  // fallback, just add all the resources one by one
  return state.withMutations(mutableState => addResourcesToState(mutableState, payload));
}

function errorReducer<T extends keyof UsedResources>(
  state: State = defaultState,
  { payload, meta }: NetworkErrorAction
): State {
  if (isNotNullNorUndefined(meta) && isNotNullNorUndefined(meta.info)) {
    const resourceType = meta.info.type;
    const store: Store<UsedResources[T]> = getResourcesStore(state, meta.info.type) as Store<UsedResources[T]>;
    if (meta.info.infoType === RequestInfoType.PAGE) {
      if (isNotEmptyObject(payload)) {
        return state.setIn(
          [
            "resources",
            resourceType,
          ],
          store.setInErrorPage(
            meta.info.key,
            meta.info.page,
            {
              errorCode: getProperty(payload?.error[0], "errorCode", payload.status),
              errorMessage: getProperty(payload, "error.message", "") ||
                getProperty(payload?.error[0], "reason", ""),
            }
          )
        );
      }

    } else {

      if (isNotEmptyObject(payload)) {
        return state.setIn(
          [
            "resources",
            resourceType,
          ],
          store.setInErrorElement(
            meta.info.key,
            {
              errorCode: getProperty(payload?.error[0], "errorCode", payload.status), 
              errorMessage: getProperty(payload, "error.message", "") ||
                getProperty(payload?.error[0], "reason", ""),
            }
          )
        );
      }
    }
  }
  return state;
}

function getStoreTransformer<T extends keyof UsedResources>(info: RequestInfo):
  Mapper<Store<UsedResources[T]>, Store<UsedResources[T]>> {

  switch (info.infoType) {
    case RequestInfoType.PAGE:
      return store => store.setLoadingPage(info.key, info.page);
    case RequestInfoType.ELEMENT:
      return store => store.setLoadingElement(info.key);
    case RequestInfoType.COUNT:
      return store => store.setLoadingCount(info.key);
    case RequestInfoType.UNTYPED:
      return store => store.setLoadingElement(info.key);
  }
}

function requestReducer<T extends keyof UsedResources>(state: State,
  { meta }: NetworkRequestAction): State {

  /*
      Here we want to manage the requests providing a meta information regarding paginated/count request
   */
  if (isNotNullNorUndefined(meta)) {
    if (isNotNullNorUndefined(meta.options) && meta.options.noCache === true) {
      return state; // nothing to do in this store, we don't want to cache the response.
    }

    if (isNotNullNorUndefined(meta.info)) {
      // get the store for the resource type
      const store: Store<UsedResources[T]> = getResourcesStore(state, meta.info.type) as Store<UsedResources[T]>;
      if (isNullOrUndefined(store)) {
        // eslint-disable-next-line max-len
        log.warn(`a request has been done for type ${meta.info.type}, but unable to find the corresponding store... full info: ${dump(meta.info)}`);
        return state;
      }

      const transformer = getStoreTransformer(meta.info);
      state = state.setIn(
        [
          "resources",
          meta.info.type,
        ],
        transformer(store)
      );
    }
  }

  return state;
}

type Action = NetworkSuccessAction | NetworkRequestAction | CleanResourceAction | NetworkErrorAction;
export const reducers = (state: State = defaultState, action: Action) => {
  switch (action.type) {
    case ActionTypes.NETWORK_REQUEST:
      return requestReducer(state, action);
    case ActionTypes.NETWORK_ERROR:
      return errorReducer(state, action);
    case ActionTypes.NETWORK_SUCCESS:
      return successReducer(state, action);
    case ActionTypes.CLEAN_RESOURCE:
      return cleanResourceReducer(state, action);
    default:
      return state;
  }
};

export default reducers;
