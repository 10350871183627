import React from "react";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { Sentiment } from "@hyphen-lib/domain/common/ComputationTypes";
import { Trans } from "react-i18next";
import { withRouter, RouteComponentProps } from "react-router";
import { getQueryParams, replaceLocation } from "@src/utils/locations";
import { fromJS } from "immutable";

interface Props extends RouteComponentProps{
  sentiment: Sentiment;
  barPercentRounded: number;
  barPercent: number;
  barValue: number;
}

const SentimentBar = ({
  sentiment,
  barPercentRounded,
  barPercent,
  barValue,
  location
}: Props) => {
  let backgroundColor;
  let fontColor;
  let borderRadius;
  let sentimentLabel;
  switch (sentiment) {
    case Sentiment.POSITIVE:
      backgroundColor = Palette.aquaBlue;
      fontColor = "white";
      borderRadius = "3px 0 0 3px";
      sentimentLabel = "Positive";
      break;

    case Sentiment.NEUTRAL:
      backgroundColor = Palette.lightGrey;
      fontColor = Palette.bluishGrey;
      borderRadius = "0";
      sentimentLabel = "Neutral";
      break;

    case Sentiment.NEGATIVE:
      backgroundColor = Palette.darkPink;
      fontColor = "white";
      borderRadius = "0 3px 3px 0";
      sentimentLabel = "Negative";
      break;
  }

  const setSentimentFilter = ()=> {
    const tempQueryParams = getQueryParams(location);

    if (
      Object.keys(tempQueryParams).includes("filter") &&
      Object.keys(tempQueryParams.filter).includes("sentiments")
    ) {
      delete tempQueryParams.filter.sentiments;
    }
    const param= {
      filter: {
        sentiments: [sentiment.toString()],
      }
    };
    const queryParams = fromJS(tempQueryParams).mergeDeep(param).toJS();
    replaceLocation(queryParams);
  };

  return (
    <StyledBar onClick={setSentimentFilter} barPercent={barPercent} backgroundColor={backgroundColor} 
    borderRadius={borderRadius} fontColor={fontColor} barPercentRounded={barPercentRounded}>
      <LabelContainer>{barPercentRounded}%</LabelContainer>
      <Tooltip className="top">
        {`${barPercent}% (${barValue}) `}<Trans>{sentimentLabel}</Trans>
        <Linker />
      </Tooltip>
    </StyledBar>
  );
};


/* eslint-disable max-len */
const StyledBar = styled.div<{ barPercent: number; backgroundColor: string; borderRadius: string; fontColor: string; barPercentRounded: number }>`
  width: ${(props) => props.barPercent}%;
  background-color: ${(props) => props.backgroundColor};
  line-height: 48px;
  color: ${(props) => props.fontColor};
  font-size: 16px;
  text-align: center;
  border-radius: ${(props) => props.borderRadius};
  white-space: nowrap;
  text-shadow: ${(props) => props.barPercentRounded < 3 ? "0px 0px 3px #000000" : "none"};
  cursor: pointer;
  &:hover > div {
    display: flex;
  }
`;

const Tooltip = styled.div`
  bottom: 79px;
  white-space: nowrap;
  line-height: normal;
  font-size: 14px;
  color: ${Palette.darkBlueGrey};
  position: relative;
  z-index: 100;
  flex-direction: column;
  align-items: center;
  display: none;
  text-shadow: none;
`;

const LabelContainer = styled.span`
  margin-left: -100%;
  margin-right: -100%;
  text-align: center;
`;

const Linker = styled.div`
  margin-top: 4px;
  width: 1px;
  height: 10px;
  background-color: ${Palette.veryLightBlue};
`;

export default withRouter(SentimentBar);
