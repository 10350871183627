import React from "react";
import {SentimentScore, SentimentScoreTextSizeVariant} from "@components/core/SentimentScore";
import { CommentsCounter } from "@components/core/CommentsCounter";
import styled from "styled-components";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";

type SentimentWithCommentsProps = WithCommentsProps | WithOutCommentsProps;

interface CommonProps {
  readonly netSentimentScore: any;
  readonly sentimentScoreTextSize?: SentimentScoreTextSizeVariant;
  readonly hasComments: boolean;
}

interface WithCommentsProps extends CommonProps {
  readonly hasComments: true;
  readonly hasCommentsAccess: boolean;
  readonly numberOfComments: number;
  readonly onCommentsClick?: () => void;
}

interface WithOutCommentsProps extends CommonProps {
  readonly hasComments: false;
}

export function SentimentWithComments(props: SentimentWithCommentsProps) {
  function onClickHandler() {
    if (props.hasComments) {
      const { onCommentsClick } = props;
      if (isNotNullNorUndefined(onCommentsClick)) {
        onCommentsClick();
      }
    }
  }
  const { netSentimentScore, sentimentScoreTextSize } = props;
  return (
    <Container>
      <SentimentScore netSentimentScore={netSentimentScore} size={sentimentScoreTextSize} translate="yes"/>
      {props.hasComments && (
        <CommentsCounterContainer
          data-cy="votes_results_sentiment_score"
          onClick={onClickHandler}
          isClickable={props.hasCommentsAccess}
        >
          <CommentsCounter numberOfComments={props.numberOfComments}/>
        </CommentsCounterContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: center;
`;

const CommentsCounterContainer = styled.span<{ isClickable: boolean }>`
  margin-left: 8px !important;
  cursor: ${({ isClickable }) => isClickable ? "pointer" : "default"};
`;
