import React from "react";
import styled from "styled-components";
import { Icon } from "antd";
import { Heading } from "@components/core/Typography";
import Palette from "@src/config/theme/palette";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";

export interface OwnProps {
  title: string;
  onCancel?: () => void;
  actions?: React.ReactNode[];
}

export type Props = {
} & OwnProps;

function Navbar ({ actions, ...props }: Props) {

  function onCancel(e: any) {
    if (isNotNullNorUndefined(props.onCancel)) {
      props.onCancel();
    }
  }

  return (
    <StyledContainer>
      <StyledHeader>
        <Heading translate="yes">{props.title}</Heading>
      </StyledHeader>
      {(isNotNullNorUndefined(actions) && isNotEmptyArray(actions)) && (
        <NavbarActions>
          {actions}
        </NavbarActions>
      )}
      <CrossIcon type="close" onClick={onCancel}/>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  background: ${Palette.veryDarkBlueGrey} ;
  height: 100%;
  display: flex;
  align-items: center;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 0 32px;
  font-size: 16px;

  p {
    color: #ffffff;
  }
`;

const NavbarActions = styled.div`
  display: flex;
  align-items: center;
  > span {
    margin-right: 1em;
    font-size: calc(1em - 2px);
    color: ${Palette.lightPeriwinkle};
  }
`;

const CrossIcon = styled(Icon)`
  cursor: pointer;
`;

const StyledHeader = styled.div`
  flex: 1 !important;
`;

export default Navbar;
