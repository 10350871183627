import React from "react";
import styled from "styled-components";
import { getOr, keys } from "hyphen-lib/dist/lang/Objects";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
import { ScopeDimensionCell } from "@screens/Insights/UserManagement/cells/ScopeDimensionCell";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";

interface ScopeCellProps {
  readonly userDimensions: Dictionary<string[]>;
  readonly dimensionsAddedInState: string[];
  readonly allDimensions: Dimensions;
  readonly onSegmentsChange: (dimension: Dictionary<string[]>) => void;
  readonly onSelectAllSegments: (dimension: string) => void;
  readonly onCloseIconClick: (dimensionToRemove: string) => void;
}

export class ScopeCell extends React.Component<ScopeCellProps> {

  onSelectDimensions = (dimension: string) => (segments: string[], isSelectAll: boolean) => {
    if (isSelectAll) {
      const { onSelectAllSegments } = this.props;
      onSelectAllSegments(dimension);
    } else {
      const { onSegmentsChange, userDimensions } = this.props;
      onSegmentsChange({ ...userDimensions, [dimension]: segments });
    }
  };

  render() {
    const { userDimensions, allDimensions, onCloseIconClick, dimensionsAddedInState } = this.props;
    const availableDimensions = keys(getOr(userDimensions, {})).reverse();

    return (
      <CellContainer>
        {
          dimensionsAddedInState.map((dim) => {
            const currentDim = allDimensions[dim];
            return (
              <ScopeDimensionCell
                key={dim}
                userSegments={[]}
                dimension={currentDim}
                onSelectDimensions={this.onSelectDimensions(dim)}
                onCloseIconClick={onCloseIconClick.bind(null,dim)}
                isInLocalState={true}
              />
            );
          })
        }
        {
          availableDimensions.map((dim) => {
            const segments = userDimensions[dim];
            const currentDim = allDimensions[dim];
            return isNotNullNorUndefined(segments) && isNotNullNorUndefined(currentDim) && (
              <ScopeDimensionCell
                key={dim}
                userSegments={segments}
                dimension={currentDim}
                onSelectDimensions={this.onSelectDimensions(dim)}
                onCloseIconClick={onCloseIconClick.bind(null,dim)}
                isInLocalState={false}
              />
            );
          })
        }
      </CellContainer>
    );
  }
}

const CellContainer = styled.div`
  display: flex;
`;
