import { FSA } from "flux-standard-action";
import { FilterParameter } from "@src/utils/networks";

export enum UserListFiltersActionTypes {
  MODIFY_FILTER = "userList/MODIFY_FILTER",
  CLEAN_FILTER = "userList/CLEAN_FILTER",
}

interface Payloads {
  [UserListFiltersActionTypes.MODIFY_FILTER]: FilterParameter;
}

export interface ModifyFilterAction
  extends FSA<
  UserListFiltersActionTypes.MODIFY_FILTER,
  Payloads[UserListFiltersActionTypes.MODIFY_FILTER]
  > {
  type: UserListFiltersActionTypes.MODIFY_FILTER;
  payload: Payloads[UserListFiltersActionTypes.MODIFY_FILTER];
}

export interface CleanFilterAction extends FSA<UserListFiltersActionTypes.CLEAN_FILTER, void> {
  type: UserListFiltersActionTypes.CLEAN_FILTER;
}

export const userListFiltersActionCreators = {
  modifyFilter: (payload: ModifyFilterAction["payload"]): ModifyFilterAction => ({
    type: UserListFiltersActionTypes.MODIFY_FILTER,
    payload,
  }),
  cleanFilter: (): CleanFilterAction => ({
    type: UserListFiltersActionTypes.CLEAN_FILTER,
  }),
};
