import React from "react";
import styled from "styled-components";
import { Icon } from "antd";
import { Trans } from "react-i18next";
import Palette from "@src/config/theme/palette";
import { Heading } from "@components/core/Typography";
import WordCloud from "./components/WordCloud";
import BubbleChart from "./components/BubbleChart";
import ButtonGroup from "antd/lib/button/button-group";
import Button from "@components/core/Button";
import { Col, Row } from "antd";
import { Tag } from "antdv4";
import ArtificialInteligence from "@src/components/core/svg/ArtificialInteligence";
import "@src/css/d3.css";
import { TopicOverviewResource } from "hyphen-lib/dist/domain/resource/survey/report/TopicOverviewResource";
import { Sentiment } from "hyphen-lib/dist/domain/common/ComputationTypes";
import { isNotEmptyObject, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { capitalizeFirstLetter } from "@src/utils/helper";
import { goTo } from "@src/utils/locations";
import { Sentiments } from "hyphen-lib/dist/domain/resource/report/common/Highlights";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import Tooltip from "@src/components/core/Tooltip";
import { CategoryReportResource } from "hyphen-lib/dist/domain/resource/report/CategoryReportResource";
import { Summary } from "../OverviewReport/components/Overview";

export interface OwnProps {
  readonly topics: TopicOverviewResource[];
  readonly title: string;
  readonly surveyId: string;
  readonly infoMessage: string;
  readonly sectionName: string;
  readonly questionId?: string;
  readonly categoryId?: string;
  readonly onlyTopics?: boolean;
  readonly category?: CategoryReportResource;
  readonly numberOfComments?: number;
  readonly footer?: React.ReactNode;
}
interface SurveyTopicsSateProps {
  isBubbleChart: boolean;
}

export const SentimentMap = {
  [Sentiment.POSITIVE]: "positive",
  [Sentiment.NEUTRAL]: "neutral",
  [Sentiment.NEGATIVE]: "negative"
};

export type TopicChartType = TopicWordChart & TopicBubbleChart;
export interface TopicWordChart extends TopicOverviewResource {
  value: number;
  text: string;
  sentimentText: string;
  size?: number;
}
interface TopicBubbleChart extends TopicOverviewResource {
  category: string;
  sentimentText: string;
  sentiments: Sentiments;
  size?: number;
}

export interface TopicChartData {
  data: TopicChartType[];
  min: number;
  max: number;
}

export function getTopicsChartData(topics: TopicOverviewResource[]): TopicChartData {
  const counts: number[] = [];
  const data = topics
    .map((topic: any) => {
      const tag: TopicChartType = topic;
      tag.category = topic.name;
      tag.value = topic.totalComments;
      tag.text = capitalizeFirstLetter(topic.name);
      tag.sentiments = topic.sentiment;
      if (isNotEmptyObject(topic.sentiment)) {
        tag.sentimentText = SentimentMap[Sentiment.NEUTRAL];
        let temp = topic.sentiment[Sentiment.NEUTRAL];
        if (temp <= topic.sentiment[Sentiment.POSITIVE]) {
          tag.sentimentText = SentimentMap[Sentiment.POSITIVE];
          temp = topic.sentiment[Sentiment.POSITIVE];
        }
        if (temp <= topic.sentiment[Sentiment.NEGATIVE]) {
          tag.sentimentText = SentimentMap[Sentiment.NEGATIVE];
          temp = topic.sentiment[Sentiment.NEGATIVE];
        }
      }
      counts.push(tag.value);
      return tag;
    });
  const min = Math.min(...counts);
  const max = Math.max(...counts);
  return {
    data,
    min,
    max
  };
}

export class SurveyTopicsChart extends React.Component<OwnProps, SurveyTopicsSateProps> {
  ref = React.createRef<HTMLDivElement>();

  constructor(props: OwnProps) {
    super(props);
    this.state = {
      isBubbleChart: true
    };
  }

  onViewTopics(surveyId: string) {
    goTo(`/surveys/view/${surveyId}/reports/topics`);
  }

  render() {
    const { onlyTopics, topics, footer, surveyId, infoMessage, title, category, 
      numberOfComments, sectionName, questionId, categoryId} = this.props;
    const { isBubbleChart } = this.state;
    const loaded = isNotEmptyArray(topics);
    return (
      <ChartWrapper ref={this.ref} className="pagebreak">
        <Row>
          <Col span={14}>
            <TitleContainer>
              <Heading size="large" data-jest="topTopics">
                <Trans>{title}</Trans>
              </Heading>
              <CustomAITag
                color={Palette.tequila}
                icon={<ArtificialInteligence />}
                data-jest="AI-based"
              >
                <Trans>AI-based</Trans>
              </CustomAITag>
              <Tooltip
                title={<Trans>
                 {infoMessage}
                </Trans>}
              >
                <StyledInfoIcon
                  className="block--print-hide"
                  type="info-circle"
                />
              </Tooltip>
            </TitleContainer>
          </Col>
          {loaded && (
            <Col span={10}>
              {onlyTopics ? (
                <StyledButtonGroup>
                  <StyledButton
                    onClick={() => surveyId ? this.onViewTopics(surveyId) : null}
                    className="block--print-hide pull-right"
                    data-cy="surveyReport_viewTopicsReportButton"
                    color="grey"
                    translate="yes"
                  >
                    View Topics Report
                  </StyledButton>
                </StyledButtonGroup>
              ) : (
                <StyledButtonGroup>
                  <StyledButton
                    key="topics_bubble_chart"
                    color={isBubbleChart ? "blue" : "gradation"}
                    onClick={() => this.setState({ isBubbleChart: true })}
                    style={{
                      height: "36px",
                    }}
                    data-jest="bubbleChart"
                  >
                    <Trans>Bubble Chart</Trans>
                  </StyledButton>
                  <StyledButton
                    key="topics_word_cloud"
                    color={!isBubbleChart ? "blue" : "gradation"}
                    onClick={() => this.setState({ isBubbleChart: false })}
                    style={{
                      height: "36px",
                    }}
                    data-jest="wordCloud"
                  >
                    <Trans>Word Cloud</Trans>
                  </StyledButton>
                </StyledButtonGroup>
              )}
            </Col>
          )}
        </Row>
        { !isBubbleChart && 
          <Row>
            {
              category && category.numberOfQuestions &&
              <Summary>{category.numberOfQuestions}&nbsp;
                <Trans>questions</Trans> • {category.numberOfComments}&nbsp;
                <Trans>comments</Trans>
              </Summary>
            }
            {
              numberOfComments &&
              <Summary>
                {numberOfComments}&nbsp;
                <Trans>comments</Trans>
              </Summary>
            }
          </Row>
        }
        <ChartContainer className={loaded ? "" : "display-center"}>
          {loaded ? (
            !isBubbleChart ? (
              <WordCloud
                topics={topics}
                width={this.ref.current?.offsetWidth as number}
                height={400}
                animate={true}
                setDimension={false}
              />
            ) : (
              <BubbleChart 
                topics={topics} 
                surveyId={surveyId} 
                sectionName={sectionName} 
                questionId={questionId} 
                categoryId={categoryId}
              />
            )
          ) : (
            <NoDataContent>
              AI is still processing the comments to generate topics for you..
            </NoDataContent>
          )}
        </ChartContainer>
        { isNotNullNorUndefined(footer) && <Footer>{ footer }</Footer>}
      </ChartWrapper>
    );
  }
};

export default SurveyTopicsChart;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ChartWrapper = styled.div`
  padding: 32px;
  margin-top: 24px;
  background: ${Palette.white};
  font-family: Lato, sans-serif;
  color: ${Palette.veryDarkBlueGrey};
`;

const StyledButtonGroup = styled(ButtonGroup)`
  display: flex;
  justify-content: flex-end;
`;

const StyledButton = styled(Button)`
`;

const NoDataContent = styled.div`
  color: ${Palette.dimGray};
  font-size: 18px;
`;

export const CustomAITag = styled(Tag)`
  padding: 3px 5px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  span {
    font-size: 14px;
    padding-left: 5px;
    color: ${Palette.darkDarkDarkBlueGrey};
  }
`;

const ChartContainer = styled.div`
  min-height: 400px;
  margin-top: 10px;
  background-color: ${Palette.background};
`;

const Footer = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
`;

const StyledInfoIcon = styled(Icon)`
  font-size: 16px;
  color: ${Palette.darkDarkDarkBlueGrey};
`;
