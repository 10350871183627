import React from "react";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps } from "react-router";
import { PaginationConfig, SorterResult } from "antd/lib/table";
import {
  areEquals,
  getOr,
  isEmptyObject,
  isNotEmptyObject,
  isNotNullNorUndefined,
  isNullOrUndefined,
  keys,
  mapOr,
} from "hyphen-lib/dist/lang/Objects";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";

import { State } from "@store/types";
import CategoriesReport from "@screens/Insights/Survey/components/CategoriesReport";
import { SortParameter } from "@src/utils/networks";
import {
  generateCompareWithOptions,
  getAvailableComparisons,
} from "@src/utils/Comparisons";
import {
  getCompany,
  getCurrentUser,
  getRightsMatcher,
} from "@screens/Insights/store/selectors";
import { goTo, replaceLocation } from "@src/utils/locations";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { Map as ImmutableMap } from "immutable";
import { getParameters } from "@screens/Insights/parameters/store/selectors";
import { applyExistingParametersIfNeeded } from "@src/utils/parameters";
import { formatSort } from "@src/utils/helper";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { checkForActionPlanCreationRight, checkForSurveyReportRight } from "@src/utils/rights";
import { getAnonymityThreshold } from "@src/screens/Insights/Settings/store/selectors";
import { Participation } from "hyphen-lib/dist/domain/common/Participation";
import { getResourceById } from "@store/network/selectors";
import { CategoriesReportResource } from "hyphen-lib/dist/domain/resource/report/CategoriesReportResource";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { fetchCategoriesReportIfNeeded } from "@store/network/resource/CategoriesReportResources";
import { FetchError } from "@src/screens/Insights/errors/FetchError";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import {
  focusAreaListActionCreators,
  FocusAreaListPageParameters,
} from "@src/screens/Insights/Actions/store/actions";
import { fetchFocusAreasIfNeeded } from "@src/store/network/resource/ActionResources";
import { isEmpty } from "hyphen-lib/dist/lang/Arrays";
import BottomDrawer, { DrawerViews } from "@src/components/core/BottomDrawer";
import BottomDrawerStripedChild, {
  transformFocusAreasToStripStack,
} from "@src/components/core/BottomDrawer/StripedChild";
import {
  getAllFocusAreasFromReduxStore,
  getCurrentBottomDrawerView,
  isFARequestComplete,
} from "@src/utils/FocusArea";
import { BottomDrawerMessages } from "@src/screens/Insights/BottomDrawer/constant";
import { hasFocusAreaRights } from "hyphen-lib/dist/business/focusArea/focusAreaResource";
import Spin from "@src/components/core/Spin";
import { parseNumber } from "hyphen-lib/dist/lang/Number";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";

interface MatchParams {
  id: string;
}

export interface OwnProps extends RouteComponentProps<MatchParams> {
  readonly surveyId: string;
  readonly surveyName: string;
  readonly participation: Participation;
  readonly postAndSurveySentiment?: boolean;
}

interface CategoriesReportContainerStateProps {
  readonly sort: SortParameter;
  readonly freeTextFilter: string;
  readonly company: CompanyResource;
  readonly parameters: ImmutableMap<string, any>;
  readonly hasActionCreationRight: boolean;
  readonly hasTopicsAccess:  boolean;
  readonly anonymityThreshold: number;
  readonly categories: Store.Element<CategoriesReportResource>;
  readonly isFiltersAvailable?: boolean;
  readonly focusAreas?: FocusAreaResource[];
  readonly currentUser: Optional<CurrentUserResource>;
  readonly currentBottomDrawerView: DrawerViews;
  readonly hasFaRights: boolean;
  readonly completedFARequest: boolean;
  readonly page: PageFilter;
}

interface CategoriesReportContainerActionProps {
  readonly onFetchCategoriesReport: (
    surveyId: string,
    queryString: Dictionary<any>
  ) => void;
  readonly onFetchFocusArea: (parameters: FocusAreaListPageParameters) => any;
  readonly onCreateFocusArea: (focusArea: Partial<FocusAreaResource>) => void;
}

type Props = OwnProps &
  CategoriesReportContainerStateProps &
  CategoriesReportContainerActionProps;

interface CategoriesReportContainerState {
  totalFocusAreas: number;
}

export class CategoriesReportContainer extends React.Component<
  Props,
  CategoriesReportContainerState
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      totalFocusAreas: 0,
    };
  }

  // noinspection JSMethodCanBeStatic
  extractParametersFromQuery(props: Props) {
    return this.extractRelevantParametersForQuery(
      parseQueryString(props.location.search)
    );
  }

  // noinspection JSMethodCanBeStatic
  extractRelevantParametersForQuery(parameters: any) {
    const relevantParameters = { ...parameters };
    delete relevantParameters.freeText;
    delete relevantParameters.viewOptions;
    delete relevantParameters.sort;
    delete relevantParameters.comparisonKey;
    return relevantParameters;
  }

  componentDidMount(): void {
    // we might need to apply persisted parameters
    const {
      parameters,
      location: { search },
    } = this.props;

    const existing = parseQueryString(search);
    const mergedParameters = applyExistingParametersIfNeeded(
      parameters.toJS(),
      existing,
      "filter.dimensions",
      "viewOptions.comparison",
      "viewOptions.compareWith"
    );

    if (
      isNotNullNorUndefined(mergedParameters) &&
      isNotEmptyObject(mergedParameters)
    ) {
      replaceLocation(mergedParameters);

      if (
        isEmptyObject(this.extractRelevantParametersForQuery(mergedParameters))
      ) {
        // do the fetch now, otherwise we will not do it later, as componentDidUpdate will not see
        // any relevant changes, and not do any fetch
        this.fetchSurveyCategories();
      }
    } else {
      // fetch the categories only if we will stay on this page,
      // otherwise it will be fetched anyway in componentDidUpdate
      this.fetchSurveyCategories();
    }

    if (
      (isNullOrUndefined(this.props.focusAreas) ||
        isEmpty(this.props.focusAreas)) &&
      this.props.hasFaRights
    ) {
      this.props.onFetchFocusArea(this.fetchFocusAreaProps());
    }

    let totalFocusAreas = 0;
    if (isNotNullNorUndefined(this.props.focusAreas)) {
      totalFocusAreas = this.props.focusAreas.length;
    }

    this.setState({
      totalFocusAreas,
    });
  }

  componentDidUpdate(prevProps: Props) {
    const oldParams = this.extractParametersFromQuery(prevProps);
    const newParams = this.extractParametersFromQuery(this.props);
    if (
      (this.props.surveyId !== prevProps.surveyId ||
        not(areEquals(oldParams, newParams))) &&
      not(Store.Element.isInError(this.props.categories))
    ) {
      this.fetchSurveyCategories();
    }

    if (this.props.hasFaRights) {
      this.props.onFetchFocusArea(this.fetchFocusAreaProps());
    }

    if (
      isNotNullNorUndefined(this.props.focusAreas) &&
      isNotNullNorUndefined(prevProps.focusAreas)
    ) {
      if (this.props.focusAreas.length > prevProps.focusAreas.length) {
        this.setState({
          totalFocusAreas: this.props.focusAreas.length,
        });
      }
    }
  }

  fetchFocusAreaProps(): FocusAreaListPageParameters {
    return {
      page: {
        number: 1,
        size: 30,
      },
      filter: {
        withActionPlans: false,
      },
    };
  }

  fetchSurveyCategories = () => {
    const queryParams = this.extractParametersFromQuery(this.props);
    this.props.onFetchCategoriesReport(this.props.surveyId, queryParams);
  };

  onTableChange = (
    pagination: PaginationConfig,
    __: any,
    sorter: SorterResult<any>
  ) => {
    const { location } = this.props;
    const { current } = pagination;
    const sortParam: SortParameter = {};
    if (isNotEmptyObject(sorter) && isNotNullNorUndefined(sorter.order)) {
      sortParam[sorter.columnKey] = sorter.order === "ascend" ? 1 : -1;
    }

    replaceLocation({
      ...parseQueryString(location.search),
      sort: sortParam,
      page: current
    });
  };

  render() {
    const {
      surveyName,
      participation,
      categories,
      company,
      sort,
      freeTextFilter,
      hasActionCreationRight,
      hasTopicsAccess,
      anonymityThreshold,
      onCreateFocusArea,
      focusAreas,
      hasFaRights,
      completedFARequest,
      surveyId,
      page,
      postAndSurveySentiment
    } = this.props;

    const queryParams = this.extractParametersFromQuery(this.props);

    const compareWithOptions = generateCompareWithOptions(
      Store.Element.mapIfLoadedOr(categories, getAvailableComparisons, {}),
      company
    );

    const isFiltersAvailable = not(keys(queryParams).includes("filter"));
    if (Store.Element.isInError(categories)) {
      return (
        <FetchError
          {...categories}
          resourceType={CategoriesReportResource.TYPE}
        />
      );
    }

    return (
      <>
        <Spin
          size="large"
          className="sticky-top"
          spinning={!completedFARequest}
        >
          {hasFaRights && (
            <BottomDrawer
              key="segment-drawer"
              initialView={this.props.currentBottomDrawerView}
              title={`${
                this.state.totalFocusAreas === 0
                  ? "No"
                  : this.state.totalFocusAreas
              } Focus Area Added`}
              footerButton={{
                display: true,
                text: "Go To Action Center",
                onClick: () => goTo("/actioncenter"),
              }}
            >
              <BottomDrawerStripedChild
                stripStack={transformFocusAreasToStripStack(focusAreas)}
                emptyStackMessage={BottomDrawerMessages.EMPTY_STACK_MESSAGE}
              />
            </BottomDrawer>
          )}
          <CategoriesReport
            isFiltersAvailable={isFiltersAvailable}
            onTableChange={this.onTableChange}
            surveyName={surveyName}
            participation={participation}
            categories={Store.Element.toLoadable(categories)}
            enabledFilters={["dimension"]}
            enabledCustomFilters={["addDimension"]}
            enabledViewOptions={["comparison", "compareWith"]}
            compareWithOptions={compareWithOptions}
            sort={sort}
            freeTextFilter={freeTextFilter}
            hasActionCreationRight={hasActionCreationRight}
            hasTopicsAccess={hasTopicsAccess}
            anonymityThreshold={anonymityThreshold}
            onCreateFocusArea={onCreateFocusArea}
            surveyId={surveyId}
            page={page}
            postAndSurveySentiment={postAndSurveySentiment}
            questionConfig={company.questionConfig}
          />
        </Spin>
      </>
    );
  }
}

const mapStateToProps: MapStateToProps<
  CategoriesReportContainerStateProps,
  OwnProps & RouteComponentProps,
  State
> = (state: State, ownProps: OwnProps): CategoriesReportContainerStateProps => {
  const currentUser = getCurrentUser(state);
  const queryParameters = parseQueryString(ownProps.location.search);

  const freeTextFilter = queryParameters.freeText || "";
  const sort = getOr(formatSort(queryParameters.sort), {});
  const page = {
    number: mapOr(queryParameters.page, parseNumber, 1),
    size: 50
  };
  const data = getAllFocusAreasFromReduxStore(state);
  const currentBottomDrawerView = getCurrentBottomDrawerView(state);
  const hasFaRights = hasFocusAreaRights(getRightsMatcher(state));

  return {
    sort,
    freeTextFilter,
    categories: getResourceById(
      state,
      CategoriesReportResource.TYPE,
      CategoriesReportResource.generateId(
        queryParameters.filter,
        ownProps.surveyId
      ),
    ),
    company: getCompany(state)!,
    parameters: getParameters(state),
    hasActionCreationRight: checkForActionPlanCreationRight(state),
    hasTopicsAccess: checkForSurveyReportRight(
      getRightsMatcher(state),
      "topics"
    ),
    anonymityThreshold: getAnonymityThreshold(state),
    currentUser,
    focusAreas: data,
    currentBottomDrawerView,
    hasFaRights,
    completedFARequest: isFARequestComplete(state),
    page
  };
};

const mapDispatchToProps = {
  onFetchCategoriesReport: fetchCategoriesReportIfNeeded,
  onFetchFocusArea: fetchFocusAreasIfNeeded,
  onCreateFocusArea: focusAreaListActionCreators.createFocusArea,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoriesReportContainer);
