import React from "react";
import { Route, Switch } from "react-router-dom";
import Settings from "./";

export class SettingsRoutes extends React.Component {

  renderComponent = (activeKey: string) => (props: any) => {
    return (<Settings {...props} activeKey={activeKey} />);
  };

  render() {
    return (
      <Switch>
        <Route path="/settings/app" exact render={this.renderComponent("app")} />
        <Route path="/settings/company" exact render={this.renderComponent("company")}/>
        <Route path="/settings/integrations" exact render={this.renderComponent("integrations")} />
        <Route path="/settings/batarang/scripts" exact render={this.renderComponent("batarang/scripts")} />
        <Route path="/settings/batarang/scripts/:scriptName" exact render={this.renderComponent("batarang/scripts")} />
        <Route path="/settings/employee-lifecycle" render={this.renderComponent("employee-lifecycle")} />
        <Route path="/settings" render={this.renderComponent("company")} />
      </Switch>
    );
  }
}

export default SettingsRoutes;
