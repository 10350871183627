import { Participation } from "@hyphen-lib/domain/common/Participation";
import { getProperty } from "@hyphen-lib/lang/Objects";
import { not } from "@hyphen-lib/lang/Booleans";
import { compareDates, formatDate, parse } from "@hyphen-lib/lang/Dates";
import { TrendLineChartData } from "@src/components/core/TrendLineChart";
import { TrendResource } from "@hyphen-lib/domain/resource/survey/report/TrendResource";
import { AxisLabelsFormatterCallbackFunction } from "highcharts";

interface SurveyTrendCustomProperties {
  surveyId: string;
  surveyName: string;
  ratingQuestionsCount: number;
  participation: Participation;
  isHighlighted: boolean;
  noData: boolean;
  isFilteredForAnonymity: boolean;
}

export const generateTrendLineData = (
  trend: TrendResource.TrendType[],
  currentId: string
): TrendLineChartData<SurveyTrendCustomProperties>[] => {
  
  const trendLineData: TrendLineChartData<SurveyTrendCustomProperties>[] = trend.map((surveyInfo) => {
    const score = getProperty(surveyInfo.favorability, "score", false);
    return (
    [
      formatDate(surveyInfo.launchDate, { month: "short", year: "numeric", day: undefined }),
      getProperty(surveyInfo.favorability, "score", null),
      {
        custom: {
          surveyId: surveyInfo.surveyId,
          surveyName: surveyInfo.surveyName,
          ratingQuestionsCount: TrendResource.isSurveyTrend(surveyInfo) ? surveyInfo.ratingQuestionsCount : -1,
          participation: surveyInfo.participation,
          isHighlighted: (currentId === surveyInfo.surveyId) ||
              (TrendResource.isQuestionTrend(surveyInfo) && currentId === surveyInfo.questionId),
          noData: score === 0 ? false : not(score),
          isFilteredForAnonymity: getProperty(surveyInfo.favorability, "filteredForAnonymity", false),
        },
      },
    ]
  );}
  );
  return trendLineData.sort(
    (
      chartDataA: TrendLineChartData<SurveyTrendCustomProperties>,
      chartDataB: TrendLineChartData<SurveyTrendCustomProperties>
    ) => (
      compareDates(parse(chartDataA[0]), parse(chartDataB[0]))
    )
  );
};

export const getMonthsToDisplay = (numberOfSurveys: number): number => {
  // Always a multiple of 3. If there are 2 surveys show 3 months, if 4 show 6 months.
  // But if number of surveys is already a multiple of 3, just show those many months.
  if (numberOfSurveys % 3 === 0) {
    return numberOfSurveys;
  }
  return 3 + numberOfSurveys - (numberOfSurveys % 3);
};

export const labelFormatter: AxisLabelsFormatterCallbackFunction = function () {
  const positions = this.axis.tickPositions;
  const currentValue = this.value;
  const currentIndex = positions!.indexOf(currentValue);

  if (currentIndex !== -1 && currentIndex % 2 !== 0) {
    return `${currentValue}%`;
  }
  return "";
};