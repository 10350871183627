// @ts-nocheck
import React from "react";
import { SVGProps } from "react";
import styled from "styled-components";

import Palette from "@src/config/theme/palette";

interface StyleProperties {
  fill?: string;
  rotationAngle?: string;
}

const ArrowNext = (props: StyleProperties) => {
  const { fill = Palette.bluishGrey, rotationAngle = 0 } = props;

  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill={fill}
      rotate={rotationAngle}
    >
      <path
        fillRule="evenodd"
        // eslint-disable-next-line max-len
        d="M12.586 5L9.293 1.707A.999.999 0 1 1 10.707.293l5 5a.999.999 0 0 1 0 1.414l-5 5a.997.997 0 0 1-1.414 0 .999.999 0 0 1 0-1.414L12.586 7H1a1 1 0 1 1 0-2h11.586z"
      />
    </StyledSvg>
  );
};

const StyledSvg = styled.svg<SVGProps<SVGSVGElement>>`
    fill: ${props => props.fill};
    width: 9px;
    height: 8px;
    top: -1px;
    margin-right: 1px;
    position: relative;
    transform: rotate(${props => props.rotate});
  `;
export default ArrowNext;
