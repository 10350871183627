import { Icon, Tooltip } from "antd";
import { CreateActionPlanSvg } from "@components/core/svg";
import React from "react";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { TooltipProps } from "antd/lib/tooltip";
import { TOOLTIP } from "@src/utils/FocusArea";
import { Trans } from "react-i18next";

interface CreateActionPlanButtonProps {
  onClick?: (e: React.MouseEvent) => void;
  showOnHover?: boolean;
  buttonColor?: string;
}

export default function CreateActionPlanButton(props: CreateActionPlanButtonProps) {
  return (
    <Container showOnHover={props.showOnHover} data-cy="create_focus_area_btn">
      <StyledTooltip
        getPopupContainer={getPopupContainer}
        arrowPointAtCenter={true}
        title={<Trans>{TOOLTIP.FOCUSAREAICON}</Trans>}
      >
        <Button
          data-jest="createActionPlanButton"
          data-cy="icon-button" buttonColor={props.buttonColor}
          onClick={props.onClick}>
          <Icon component={CreateActionPlanSvg} />
        </Button>
      </StyledTooltip>
    </Container>
  );
}

const Container = styled.div<{ showOnHover?: boolean }>`
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  left: ${props => props.showOnHover ? "-16px" : "8px"};
  transform: translateY(-50%);
  background: ${Palette.primary};
  border-radius: 4px;
  color: ${Palette.white};
  line-height: 32px;
  text-align: center;
  z-index: 1;
  :hover {
    cursor: pointer;
  }

  i {
    z-index: 0 !important;
  }
`;

const Button = styled.div<{ buttonColor?: string }>`
  width: 32px;
  height: 32px;
  padding-top: 2px;
  ${props => props.buttonColor ? `
    background: ${props.buttonColor};
    border-radius: 4px;`
    : `
    background: ${Palette.bluePurple}; 
    border-radius: 4px;`
  }
`;

const StyledTooltip = styled<React.ComponentType<TooltipProps>>(Tooltip)`
  .ant-tooltip-content {
    position: relative;
    top: 8px;
  }

  .ant-tooltip-inner {
    font-family: Lato;
    background: ${Palette.darkBlueGrey};
    color: ${Palette.white};
    width: 120px;
    padding: 8px;
    font-size: 13px;
    text-align: center;
  }

  .ant-tooltip-arrow {
    top: 100%;
  }
`;

const getPopupContainer = (triggerNode?: HTMLElement): HTMLElement => triggerNode || document.body;
