import { takeLatest, call, select, put } from "@redux-saga/core/effects";
import { freeze, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { pushLocation, replaceLocation } from "@src/utils/locations";
import {
  CleanResourceAction, 
  actionCreators as networkActionCreators
} from "@src/store/network/actions";
import { PulsePollInfoResource } from "hyphen-lib/dist/domain/resource/PulsePollInfoResource";
import notificationActionCreators, { ShowNotificationAction } from "@src/store/notifications/actions";
import { PulsePollListOverviewResource } from "hyphen-lib/dist/domain/resource/PulsePollListOverviewResource";
import { PulsePollListStateProps, ModalActionsKeyTypes } from "./reducers";
import { getPulsePollListStateProps } from "./selectors";
import { ModifyListAction, pulsePollListActionCreators } from "./actions";
import { PulsePollListActionTypes, ChangeStatusAction } from "./actions";
import * as NotificationFactory from "@src/store/notifications/notification-factory";

export const RAW_PAGE_SIZE = 30;

export function* updateLocationUrl(replace: boolean,
  { payload: { filter, sort, page } }: ModifyListAction) {

  const locationUpdater = replace ? replaceLocation : pushLocation;
  yield call(
    locationUpdater, {
      filter,
      sort,
      page: page.number,
    }
  );
}

export function* changePulsePollStatus({ options }: ChangeStatusAction) {
  const {
    pollTemplateId,
    modalPurpose,
    pollInstanceId,
  }: PulsePollListStateProps = yield select(getPulsePollListStateProps);

  if (isNotNullNorUndefined(pollTemplateId)) {
    switch (modalPurpose) {
      case "reactivate":
        return yield put(
          pulsePollListActionCreators.onActivatePulsePoll(pollTemplateId)
        );
      case "deactivate":
      case "deactivateAndclose":
        let closeInstance = false;
        if (isNotNullNorUndefined(options) && options.deactivateAndClose) {
          closeInstance = true;
        }
        return yield put(
          pulsePollListActionCreators.onDeactivatePulsePoll(pollTemplateId, closeInstance)
        );
      case "delete":
        return yield put(
          pulsePollListActionCreators.onDeletePulsePoll(pollTemplateId)
        );
      case "closeAndDeactivateFrequencyOncePoll":
      case "close":
        if (isNotNullNorUndefined(pollInstanceId)) {
          return yield put(
            pulsePollListActionCreators.onClosePulsePoll(pollTemplateId, pollInstanceId)
          );
        }
        return yield put(pulsePollListActionCreators.onCloseModal());
      default:
        return yield put(pulsePollListActionCreators.onCloseModal());
    }
  }
}

const actionNotificationMapper: {
  [K in keyof ModalActionsKeyTypes]: string;
} = {
  delete: "This Poll has been deleted.",
  close: "This iteration of the Poll has been closed.",
  closeAndDeactivateFrequencyOncePoll: "The Poll has been closed.",
  reactivate: "This Poll has been restarted. A new Poll will be sent out at the next scheduled time.",
  deactivate: "This Poll has been paused. Future iterations of the poll will not be launched",
  deactivateAndclose: "This Poll has been Paused and all active poll iterations are also closed.",
};

function* resetPulsePollList() {
  const { modalPurpose }: PulsePollListStateProps = yield select(getPulsePollListStateProps);
  if (isNotNullNorUndefined(modalPurpose)) {
    yield put<ShowNotificationAction>(
      notificationActionCreators.displayNotification(
        NotificationFactory.success(
          actionNotificationMapper[modalPurpose],
          "",
          4.5
        )
    ));
  }
  yield put(pulsePollListActionCreators.onCloseModal());
  yield put<CleanResourceAction>
  (networkActionCreators.cleanResource(PulsePollInfoResource.TYPE));
  yield put<CleanResourceAction>
  (networkActionCreators.cleanResource(PulsePollListOverviewResource.TYPE));
}

export const pulsePollsSagas = freeze([

  // ~~~~~~~~~~~~~ NETWORK ~~~~~~~~~~~~~~~~

  takeLatest(
    PulsePollListActionTypes.MODIFY_LIST,
    updateLocationUrl.bind(null, false)
  ),
  /*
    Listen for fetch if needed action, like this we can update the current location, so this is
    used when mounting for the first time the component, we will append the `page=1`.
    When "fetch if needed" will be called in update after an explicit modification of the parameter
    with a modify list, the URL will not be replaced, as the same URL will be generated, so
    replacement will be skipped.
   */
  takeLatest(
    PulsePollListActionTypes.CHANGE_PULSE_POLL_STATUS,
    changePulsePollStatus
  ),

  takeLatest(
    [
      PulsePollListActionTypes.ACTIVATE_PULSE_POLL_SUCCESS,
      PulsePollListActionTypes.DEACTIVATE_PULSE_POLL_SUCCESS,
      PulsePollListActionTypes.DELETE_PULSE_POLL_SUCCESS,
      PulsePollListActionTypes.CLOSE_PULSE_POLL_SUCCESS,
    ],
    resetPulsePollList
  ),
]);
