import React from "react";
import styled from "styled-components";

import { isNotNullNorUndefined } from "@hyphen-lib/lang/Objects";
import Palette from "@src/config/theme/palette";
import { CellContainer, CellInnerContainer } from "@screens/Insights/SurveyList/components/cells/Cells";
import { ArrowNext } from "@components/core/svg";
import { Comparison } from "hyphen-lib/dist/domain/resource/report/common/Comparison";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { extractComparison } from "hyphen-lib/dist/business/calculation/benchmark/Benchmarks";

interface Props {
  row: {
    compare?: Comparison;
  };
  comparisonKey: Optional<string>;
}

/*
    fixme: we need to migrate the comparison cells, we already have another one!
 */
export class CompareCell extends React.Component<Props> {
  renderChange = (change: number) => {
    let arrowIcon = null;
    let changeClassName = "";
    if (change < 0) {
      arrowIcon = <ArrowNext fill={Palette.darkPink} rotationAngle="45deg" />;
      changeClassName = "negative";
    } else if (change > 0) {
      arrowIcon = <ArrowNext fill={Palette.aquaBlue} rotationAngle="-45deg" />;
      changeClassName = "positive";
    }
    const changeValue = Math.abs(change);
    return (
      <>
        {arrowIcon}<ChangeValue className={changeClassName}>{changeValue}%</ChangeValue>
      </>
    );
  };

  render() {
    const { row: { compare }, comparisonKey } = this.props;

    const comparison = extractComparison(compare, comparisonKey);

    return (
      <CellContainer align="flex-start" justifyContent="flex-start">
        <CellInnerContainer padding={[24, 0]} width="100%" textAlign="center">
          <div>
            {
              isNotNullNorUndefined(comparison) &&
              this.renderChange(comparison)
            }
          </div>
        </CellInnerContainer>
      </CellContainer>
    );
  }
}

const ChangeValue = styled.span`
  font-family: Lato;
  color: ${Palette.lightGreyBlue};

  &.positive {
    color: ${Palette.aquaBlue};
  }

  &.negative {
    color: ${Palette.darkPink};
  }
`;
