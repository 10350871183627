import { SurveyResource } from "hyphen-lib/dist/domain/resource/SurveyResource";
import { CardSection } from "@components/core/InfoCard/CardSection";
import { getOr } from "hyphen-lib/dist/lang/Objects";
import { isStringAndNotEmpty } from "@hyphen-lib/lang/Strings";

interface Props {
  survey: SurveyResource;
}
export default function SurveySummarySection ({ survey }: Props) {
  const { name, type, isAnonymous, reminderInterval, reminderLimit, introductionText, linkedSurveyName} = survey;
  const surveyName = getOr(linkedSurveyName, "");
  const surveySummaryData = [
    {
      label: "Survey name",
      value: name,
    },
    {
      label: "Type",
      value: type,
    },
    {
      label: "Anonymous",
      value: isAnonymous ? "Yes" : "No",
    },
    {
      label: "Reminders",
      value: `Every ${reminderInterval} days, max. ${reminderLimit} days after`,
    },
    {
      label: "Introduction page text",
      value: introductionText,
    },
  ];
  if( isStringAndNotEmpty(surveyName) ) {
    surveySummaryData.push(
      {
          label: "Linked to survey",
          value: surveyName,
      }
    );
  };
  return (
    <CardSection data={surveySummaryData} data-cy="survey-summary-section"/>
  );
}
