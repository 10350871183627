import React, { useEffect } from "react";
import styled from "styled-components";
import { Icon, Tooltip } from "antd";
import { ColumnProps, TableProps } from "antd/lib/table";
import { getOr, getProperty, isEmptyObject, 
         isNotNullNorUndefined, isNullOrUndefined } from "hyphen-lib/dist/lang/Objects";
import { Trans } from "react-i18next";
import { CategoriesReportResource } from "@hyphen-lib/domain/resource/report/CategoriesReportResource";
import Table from "@components/core/Table";
import { DynamicRowProps, withDynamicRows } from "@components/core/DynamicRowsTable/withDynamicRows";
import CreateActionPlanButton from "@src/screens/Insights/components/Reports/CreateActionPlanButton";
import Palette, { getBarThemeForFavorability } from "@src/config/theme/palette";
import isDeepEqual from "react-fast-compare";
import { RouteComponentProps, withRouter } from "react-router";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Breadcrumb, goTo } from "@src/utils/locations";
import { SortParameter } from "@src/utils/networks";
import { CompareWithOption } from "@screens/Insights/components/ViewOptions/components/CompareWith";
import { getMatchingOptionLabel } from "@src/utils/Comparisons";
import { InlineAnonymityFiltered } from "@components/core/InlineAnonymityFiltered";
import { CellContainer } from "@src/screens/Insights/Actions/components/ActionListTable/cells/Cells";
import { CellInnerContainer } from "@src/screens/Insights/SurveyList/components/cells/Cells";
import { SentimentCell } from "./cells/SentimentCell";
import { CompareCell } from "./cells/CompareCell";
import { FavorabilityCell } from "./cells/FavorabilityCell";
import { CategoryCell } from "./cells/CategoryCell";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { FocusArea, Module } from "hyphen-lib/dist/domain/FocusArea";
import ReactDOMServer from "react-dom/server";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { SurveyTopicCommentResource } from "hyphen-lib/dist/domain/resource/survey/report/SurveyTopicCommentResource";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { connect, MapStateToProps } from "react-redux";
import { fetchTopicsOverviewIfNeeded } from "@src/store/network/resource/TopicsReportResources";
import { State } from "@store/types";
import { getResourceById } from "@src/store/network/selectors";
import { Store } from "hyphen-lib/dist/util/store/Store";
import WordCloudCell from "@src/screens/Insights/components/Reports/ResultsTable/cells/WordCloudCell";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { QuestionConfig } from "hyphen-lib/dist/domain/common/QuestionType";

function hasToLoadTopics(type: string) {
  return type.toLocaleLowerCase().indexOf("open text") > -1;
}
interface TopicsCategoryStateProps {
  readonly topicsForCategories : Record<string,Loadable<SurveyTopicCommentResource>>;
}
interface TopicsCategoryActionProps {
  readonly onFetchTopicsOverview: (
    surveyId: string,
    queryString: Dictionary<any>
  ) => void;
}

const headerStyle = `
  font-size: 15px;
  color: ${Palette.darkBlueGrey};
`;

const Header = styled.div`
  ${headerStyle};
  text-align:center;
`;

const HeaderWithIcon = styled.div`
  white-space: pre;
  ${headerStyle}
`;

const ComparisonOption = styled.div`
  color: ${Palette.bluishGrey};
  font-size: 12px;
  font-weight: normal;
  margin-top: 4px;
  width:120px;
`;

const StyledInfoIcon = styled(Icon)`
  font-size: 16px;
  color: ${Palette.lightGreyBlue};
  margin-left: 8px;
`;

function getSortOrder(field: string, sort?: SortParameter) {
  if (isEmptyObject(sort)) {
    return undefined;
  }
  if (sort![field] === 1) {
    return "ascend";
  } else if (sort![field] === -1) {
    return "descend";
  }
  return undefined;
}

const wordCloudData: Record<string, any> = {};

const getColumns = (
  areComparisonsVisible: boolean,
  compareWithOptions: CompareWithOption[],
  comparisonKey: Optional<string>,
  sort: SortParameter,
  anonymityThreshold: number,
  surveyId: string,
  questionConfig: QuestionConfig,
  postAndSurveySentiment?: boolean
): ColumnProps<CategoriesReportResource.Category>[] => {
  const columns: ColumnProps<CategoriesReportResource.Category>[] = [
    {
      title: (
        <HeaderWithIcon>
          <Trans>Category</Trans>
          {/* <StyledInfoIcon type="info-circle"/> */}
        </HeaderWithIcon>
      ),
      dataIndex: "category",
      key: "category",
      width: 191,
      sorter: true,
      // @ts-ignore
      sortOrder: getSortOrder("category", sort),
      sortDirections: ["ascend", "descend"],
      render: (__, record, index) => {
        return (
          <CategoryCell row={record} index={index} />
        );
      },
    },
    {
      title: (
        <HeaderWithIcon>
          <Trans>Favorability</Trans>
          <Tooltip title={<Trans>Percentage of the favorable votes out of all votes</Trans>}>
            <StyledInfoIcon className="block--print-hide" type="info-circle" />
          </Tooltip>
        </HeaderWithIcon>
      ),
      dataIndex: "favorability",
      key: "favorability",
      sorter: true,
      // @ts-ignore
      sortOrder: getSortOrder("favorability", sort),
      sortDirections: ["ascend", "descend"],
      render: (__, record) => {
        if (record.filteredForAnonymity) {
          return (
            <InlineAnonymityFiltered
              explanation={record.explanation}
              anonymityThreshold={anonymityThreshold}
              size={"normal"}
            />
          );
        }
        else if ((hasToLoadTopics(record._id ) || record.hasOnlyOpenText) 
          && wordCloudData[record._id] && wordCloudData[record._id].__html) {
          return <div dangerouslySetInnerHTML={{__html: wordCloudData[record._id].__html}}/>;
        }
        const labelKey = `labels${record.distribution.length}` as keyof QuestionConfig.Likert;
        const barGraphTheme  = getBarThemeForFavorability(
          record.distribution.length, 
          getOr(questionConfig.likert[labelKey] as string[], []));
        return (
          <FavorabilityCell row={record} barGraphTheme={barGraphTheme} />
        );
      },
    },
  ];

  if (areComparisonsVisible) {
    const label = getMatchingOptionLabel(compareWithOptions, comparisonKey);
    if (isNotNullNorUndefined(label)) {

      columns.push(
        {
          title: (
            <Header>
              <Trans>Compare</Trans>
              <ComparisonOption><Trans>vs {label}</Trans></ComparisonOption>
            </Header>
          ),
          dataIndex: "compare",
          key: "compare",
          width: 162,
          sorter: true,
          // @ts-ignore
          sortOrder: getSortOrder("compare", sort),
          sortDirections: ["ascend", "descend"],
          render: (__, record) => {
            if (record.filteredForAnonymity || hasToLoadTopics(record._id) || record.hasOnlyOpenText) {
              return null;
            }
            return (
              <CompareCell row={record} comparisonKey={comparisonKey} />
            );
          },
        });
    } else {
      // Push empty spacer column
      columns.push({
        sorter: false,
        width: 125,
        render: () => {
          return (
            // Rendering empty placeholder
            <CellContainer align="flex-start" justifyContent="flex-start">
              <CellInnerContainer padding={[24, 0]} width="100%" />
            </CellContainer>
          );
        },
      });
    }
  }

  if (getOr(postAndSurveySentiment, true)) {
    columns.push({
      title: (
        <HeaderWithIcon>
          <Trans>Sentiment</Trans>
          <Tooltip title={<Trans>Based on percentage of positive comments
           minus percentage of negative comments</Trans>}>
            <StyledInfoIcon className="block--print-hide" type="info-circle" />
          </Tooltip>
        </HeaderWithIcon>
      ),
      dataIndex: "netSentimentScore",
      key: "netSentimentScore",
      sorter: true,
      // @ts-ignore
      sortOrder: getSortOrder("netSentimentScore", sort),
      sortDirections: ["ascend", "descend"],
      width: 169,
      render: (__, record) => {
        if (record.filteredForAnonymity) {
          return null;
        }
        return (
          <SentimentCell row={record} />
        );
      },
    });
  }

  return columns;
};

export interface CategoriesTableProps extends TableProps<CategoriesReportResource.Category>,
 RouteComponentProps {
  readonly tableName: string;
  readonly categories: CategoriesReportResource.Category[];
  readonly areComparisonsVisible?: boolean;
  readonly compareWithOptions: CompareWithOption[];
  readonly comparisonKey: Optional<string>;
  readonly sort: SortParameter;
  readonly hasActionCreationRight: boolean;
  readonly hasTopicsAccess: boolean;
  readonly postAndSurveySentiment?: boolean;
  readonly anonymityThreshold: number;
  readonly isFiltersVisible: boolean;
  readonly surveyId: string;
  readonly questionConfig: QuestionConfig;
  readonly onCreateFocusArea: (
    focusArea: Partial<FocusAreaResource>
  ) => void;
  readonly focusAreaLabel: string;
  readonly page: PageFilter;
}

function CategoriesTableComponent({
  categories,
  areComparisonsVisible = true,
  comparisonKey,
  compareWithOptions,
  sort,
  anonymityThreshold,
  isFiltersVisible,
  surveyId,
  topicsForCategories,
  page,
  hasTopicsAccess,
  postAndSurveySentiment,
  questionConfig,
  ...rest
}: CategoriesTableProps & TopicsCategoryStateProps) {
  if(hasTopicsAccess){
    categories.forEach(category => {
      if(wordCloudData[category._id]) {
        if(Loadable.isLoaded(topicsForCategories[category._id])) {
          const topics = getProperty(topicsForCategories[category._id], "value.topics", []);
          if(isNotEmptyArray(topics) && !isDeepEqual(topics, getProperty(wordCloudData[category._id], "topics", []))) {
            wordCloudData[category._id] = {
              __html: ReactDOMServer.renderToString(
                <WordCloudCell 
                topics={topics}
                width={600}/>
              ),
              topics
            };
          }
        } else if(Loadable.isInError(topicsForCategories[category._id])) {
          wordCloudData[category._id] = {
            __html: ""
          };
        }
      }
    });
  }
  const total = categories.length;

  return (
    <StyledTable
      isFiltersVisible={isFiltersVisible}
      columns={getColumns(
        areComparisonsVisible, 
        compareWithOptions, 
        comparisonKey, 
        sort, 
        anonymityThreshold, 
        surveyId,
        questionConfig,
        postAndSurveySentiment)}
      dataSource={categories}
      rowKey={"_id"}
      pagination={{
        total,
        pageSize: page.size,
        current: page.number,
        showTotal: (total: number, range: number[]) =>
          <Trans i18nKey="resultsCount"
          values={{start: range[0], end: range[1], total}} 
          defaults={`Showing ${range[0]} to ${range[1]} of ${total} results`}/>,
      }}
      {...rest}
    />
  );
}

const StyledTable = styled(Table) <{ isFiltersVisible: boolean }>`
  .ant-table-body > table tr > td {
    vertical-align: middle;

    &:first-child {
      padding-left: 48px;
    }
  }
  .ant-table-body > table > thead > tr > th {
    padding: 8px 16px;

    &:first-child {
      padding-left: 48px;
    }
  }

  .ant-table-body > table > tbody > tr {
    height: 78px;
  }

  @media print {
    margin-top: ${props => props.isFiltersVisible ? "" : "110px"};
    table {
      page-break-after:always;
    }
  }

`;

const StyledHoverCell = styled.td`
  background-color: ${Palette.lightLightBlue};
  position: relative;
`;

interface MatchParams {
  id: string;
}

function CategoriesTable(props: CategoriesTableProps & TopicsCategoryActionProps & TopicsCategoryStateProps) {

  function HoverRow(
    { row, ...rowProps }: DynamicRowProps<CategoriesReportResource.Category> & RouteComponentProps<MatchParams>
  ) {
    function onCreateActionPlan() {
      // encodeURIComponent(row._id)

      props.onCreateFocusArea({
        title: row._id,
        category: row._id,
        source: FocusArea.Source.CATEGORY,
        module: Module.SURVEY,
        focusAreaType: FocusArea.FocusAreaType.CATEGORY,
        sourceUrl: {
          label: props.focusAreaLabel,
          url: window.location.href,
        },
        actionPlans: [],
        surveyId: rowProps.match.params.id
      });

    }

    function onSeeDetails() {
      goTo(`categories/${encodeURI(row._id)}`, Breadcrumb.stack(props.tableName));
    }
    if (row.filteredForAnonymity) {
      return (
        <>
          <StyledHoverCell>
            <CategoryCell row={row} index={rowProps.index} />
          </StyledHoverCell>
          <StyledHoverCell>
            <InlineAnonymityFiltered
              explanation={row.explanation}
              anonymityThreshold={props.anonymityThreshold}
              size={"normal"}
            />
          </StyledHoverCell>
          <StyledHoverCell colSpan={props.areComparisonsVisible ? 2 : 1} />
        </>
      );
    }
  
    const labelKey = `labels${row.distribution.length}` as keyof QuestionConfig.Likert;
    const barGraphTheme = getBarThemeForFavorability(
      row.distribution.length, 
      getOr(props.questionConfig.likert[labelKey] as string[], []));
    return (
      <>
        <StyledHoverCell>
          {
            props.hasActionCreationRight && (
              <CreateActionPlanButton onClick={onCreateActionPlan} />
            )
          }
          <CategoryCell row={row} index={rowProps.index} />
        </StyledHoverCell>
        <StyledHoverCell>
        { 
          wordCloudData[row._id] && wordCloudData[row._id].__html?
            <div dangerouslySetInnerHTML={{__html: wordCloudData[row._id].__html}}/>
          : 
            <FavorabilityCell row={row} barGraphTheme={barGraphTheme} />
        }
        </StyledHoverCell>
        {
          props.areComparisonsVisible && getOr(props.postAndSurveySentiment, true) && (
            <StyledHoverCell>
              <CompareCell row={row} comparisonKey={props.comparisonKey} />
            </StyledHoverCell>
          ) 
        }
        <StyledHoverCell>
          <StyledLink onClick={onSeeDetails}>
            <SeeDetails data-cy="see_details_question_drilldown">
              <Trans>See details</Trans> <RightIcon type="right" /></SeeDetails>
          </StyledLink>
        </StyledHoverCell>
      </>
    );
  }

  const { surveyId, categories, onFetchTopicsOverview, location, hasTopicsAccess, postAndSurveySentiment } = props;
  useEffect(() => {
    if(hasTopicsAccess && getOr(postAndSurveySentiment, true)) {
      categories.forEach(category => {
        if(hasToLoadTopics(category._id) || category.hasOnlyOpenText) {
          const existing = parseQueryString(location.search);
          const filter = getProperty(existing, "filter", {});
          filter.categories = [category._id];
          if(isNotNullNorUndefined(filter.freeText)) {
            delete filter.freeText;
          }
          onFetchTopicsOverview(surveyId, { filter });
          wordCloudData[category._id] = {
            __html: ReactDOMServer.renderToString(
              <NoDataContent data-jest = "noDataOfWorldChart">
                AI is still processing the comments to generate Topics for you..
              </NoDataContent>
            )
          };
        }
      });
    }
  }, []);

  const TailoredTable = withDynamicRows(withRouter(CategoriesTableComponent), withRouter(HoverRow));

  return <TailoredTable {...props} />;
}

const mapDispatchToProps = {
  onFetchTopicsOverview: fetchTopicsOverviewIfNeeded
};

const mapStateToProps: MapStateToProps<TopicsCategoryStateProps,
  CategoriesTableProps,
  State
> = (state: State, ownProps: CategoriesTableProps): TopicsCategoryStateProps => {
  const overview: Record<string,Loadable<SurveyTopicCommentResource>> = {};
  if(ownProps.hasTopicsAccess) {
    ownProps.categories.forEach(category => {
      if((hasToLoadTopics(category._id) || category.hasOnlyOpenText) 
        && isNullOrUndefined(overview[category._id])) {
        const existing = parseQueryString(ownProps.location.search);
          const filter = getProperty(existing, "filter", {});
          filter.categories = [category._id];
          if(isNotNullNorUndefined(filter.freeText)) {
            delete filter.freeText;
          }
        overview[category._id] = Store.Element.toLoadable(getResourceById(
          state,
          SurveyTopicCommentResource.TYPE,
          SurveyTopicCommentResource.generateKey(
            ownProps.surveyId,
            { ...filter},
          )
        ));
      }
    });
  }
  return {
    topicsForCategories: overview
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CategoriesTable));

const SeeDetails = styled.div`
  color: ${Palette.darkModerateBlue};
  font-size: 14px;
  text-align: right;
  cursor: pointer;
  line-height: 30px;
`;

const RightIcon = styled(Icon)`
  font-size: 12px;
`;

const StyledLink = styled.span`
   cursor: pointer;
`;

const NoDataContent = styled.div`
font-family: Lato;
font-size: 14px;
text-align: center;
color: ${Palette.darkBlueGrey};
`;