import React from "react";
import { SelectProps, SelectValue } from "antd/lib/select";
import { Select as AntSelect } from "antd";
import styled, { css } from "styled-components";
import { Optional } from "@hyphen-lib/lang/Optionals";
import { Dimensions } from "@hyphen-lib/domain/common/Dimensions";
import Palette from "@src/config/theme/palette";
import { getDimensionLabel } from "@src/utils/Dimensions";
import { Trans } from "react-i18next";

const { Option } = AntSelect;

interface SelectDimensionProps {
  data: string[];
  selected: SelectValue;
  dimensions: Optional<Dimensions>;
  onChangeDimension: (value: any) => void;
  labelStyles?: Partial<CSSStyleDeclaration>;
}

export default class  SelectDimension extends React.Component<SelectDimensionProps> {
  render() {
    const { data, dimensions, selected, onChangeDimension, labelStyles } = this.props;
    return (
      <SegmentByContainer labelStyles={labelStyles}>
        <Trans>Segment by</Trans>
        <StyledSelect
          data-cy="selectDimension_select"
          value={getDimensionLabel(selected as string, dimensions)}
          onChange={onChangeDimension}
        >
          {
            data.map(dimension => (
              <Option
                key={dimension}
                value={dimension}
                data-cy={`selectDimension_select_option_${dimension}`}
              >
                {getDimensionLabel(dimension, dimensions)}
              </Option>
            )
            )
          }
        </StyledSelect>
      </SegmentByContainer>
    );
  }
}

const SegmentByContainer = styled.div<{labelStyles: Optional<Partial<CSSStyleDeclaration>>}>`
  color: ${Palette.darkBlueGrey};
  ${props => {
    return props.labelStyles && props.labelStyles.fontSize ?
      css`font-size : ${props.labelStyles.fontSize}px;` :  null; }
}
`;

const StyledSelect = styled(AntSelect)<SelectProps & {children: React.ReactNode}>`
  width: 192px;
  border-radius: 3px;
  .ant-select-selection {
    :focus,
    :hover {
      border-color: ${Palette.bluePurple};
    }
  }
  margin-left: 16px !important;
  color: ${Palette.veryDarkBlueGrey};
`;
