import { isEmpty } from "hyphen-lib/dist/lang/Arrays";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { isNullOrUndefined } from "hyphen-lib/dist/lang/Objects";

export function sanitizeFilters(filters: Dictionary<any>) {
  if (isNullOrUndefined(filters.dimensions)) {
    return filters;
  }
  const { dynamicSeparation, dynamicTenure } = filters.dimensions;
  if (Array.isArray(dynamicSeparation) && not(isEmpty(dynamicSeparation))) {
    dynamicSeparation.forEach((segment: string, index: number) => {
      dynamicSeparation[index] = segment.replace(" - ", "-");
    });
  }
  if (Array.isArray(dynamicTenure) && not(isEmpty(dynamicTenure))) {
    dynamicTenure.forEach((segment: string, index: number) => {
      dynamicTenure[index] = segment.replace(" - ", "-");
    });
  }
  return filters;
}
