import React from "react";
import { Trans } from "react-i18next";
import { SurveyInfoResource } from "@hyphen-lib/domain/resource/SurveyInfoResource";
import { formatDate } from "@hyphen-lib/lang/Dates";
import { DynamicRowProps } from "@components/core/DynamicRowsTable/withDynamicRows";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { CellContainer, CellInnerContainer } from "./Cells";

export function DateCell({ row }: DynamicRowProps<SurveyInfoResource>) {
  const dates = mapStatusToDatesToDisplay(row);
  return (
    <CellContainer align="center" justifyContent="flex-end">
      <CellInnerContainer margin={0} width="100%">
        {
          dates.map((dateToDisplay, index) => (
            <RowContainer key={dateToDisplay.label} addMargin={index === 1}>
              <Label><Trans>{dateToDisplay.label}</Trans>:</Label>
              <Date>{dateToDisplay.date}</Date>
            </RowContainer>
          ))
        }
      </CellInnerContainer>
    </CellContainer>
  );
}

function formatDateWithTime(date: Date) {
  return formatDate(date, {
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
}

const RowContainer = styled.div<{ addMargin?: boolean }>`
  display: flex;
  flex-direction: row;
  margin-top: ${props => props.addMargin ? "6px" : "0px"};
`;

const Label = styled.span`
  width: 104px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${Palette.bluishGrey};
`;

const Date = styled.span`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${Palette.veryDarkBlueGrey};
`;

interface DateToDisplay {
  readonly label: string;
  readonly date: string;
}

function mapStatusToDatesToDisplay(survey: SurveyInfoResource): DateToDisplay[] {
  switch (survey.status) {
    case "draft":
      return [
        {
          label: "Last edited",
          date: formatDateWithTime(survey.updatedAt),
        },
      ];
    case "launching_requested":
      return [
        {
          label: "Last edited",
          date: formatDateWithTime(survey.updatedAt),
        },
      ];
    case "launched":
      return [
        {
          label: "Launch date",
          date: formatDate(survey.launchedAt!),
        },
        {
          label: "Next reminder",
          date: formatDateWithTime(survey.nextReminderAt!),
        },
      ];
    case "closed":
      return [
        {
          label: "Launch date",
          date: formatDate(survey.launchedAt!),
        },
        {
          label: "Closed on",
          date: formatDate(survey.closedAt!),
        },
      ];
    default:
      return [];
  }
}
