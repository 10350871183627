import { QuestionPageStateProps, SurveyDisabledFields } from "@screens/Insights/Surveys/store/surveyEditTypes";
import { SurveyResource } from "hyphen-lib/dist/domain/resource/SurveyResource";
import { Survey } from "hyphen-lib/dist/domain/Survey";
import { Mutable } from "hyphen-lib/dist/lang/Types";
import { areNotEquals, getOr, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { SurveyEditActionTypes } from "@screens/Insights/Surveys/store/surveyEditActions";
import { List as ImmutableList } from "immutable";
import { StepProps } from "@components/core/Stepper";
import { utcNow } from "hyphen-lib/dist/lang/Dates";
import { reduceSteps } from "@src/utils/wizard/Steps";
import { getAllSurveyStepDefinitions, getSurveyMaxReachedStep } from "@screens/Insights/Surveys/utils/StepDefinitions";
import { SurveyQuestionResource } from "hyphen-lib/dist/domain/resource/SurveyQuestionResource";
import { AccessResource } from "hyphen-lib/dist/domain/access/AccessResource";
import { MODALS as SURVEY_QUESTIONS_MODALS } from "@screens/Insights/Surveys/containers/SurveyQuestions";
import { toErrors } from "@src/utils/formValidations";
import { isStringAndNotEmpty } from "@hyphen-lib/lang/Strings";

export interface SurveyEditStateProps {
  readonly survey: Loadable<SurveyResource>;
  readonly questions: SurveyQuestionResource[];
  readonly accesses: AccessResource[];
  readonly updatedAt: Date;
  readonly disabled: SurveyDisabledFields;
  readonly errors: WrongEntityException.Errors;
  readonly steps: ImmutableList<StepProps>;
  readonly hasPendingUpdates: boolean;
  readonly hasPendingAccessesUpdates: boolean;
  readonly hasPendingAudienceUpdates: boolean;

  readonly previewErrors: WrongEntityException.Errors;
  readonly isPreviewModalOpen: boolean;

  readonly isUpdateAudienceModalOpen: boolean;

  readonly questionPageState: QuestionPageStateProps;
  readonly updatedQuestion?: SurveyQuestionResource;
}

export function surveyEditStateFactory(): SurveyEditStateProps {
  return {
    survey: Loadable.loading(),
    questions: [],
    accesses: [],
    updatedAt: utcNow(),
    disabled: {},
    errors: WrongEntityException.noErrors(),
    steps: reduceSteps(getAllSurveyStepDefinitions(), 0, WrongEntityException.noErrors()),
    hasPendingUpdates: false,
    hasPendingAccessesUpdates: false,
    hasPendingAudienceUpdates: false,

    previewErrors: WrongEntityException.noErrors(),
    isPreviewModalOpen: false,

    questionPageState: {
      isRequestingUpdate: false,
      isModalOpen: false,
      modalPurpose: SURVEY_QUESTIONS_MODALS.editQuestion,
      errors: WrongEntityException.noErrors(),
    },

    isUpdateAudienceModalOpen: false,
    updatedQuestion: undefined
  };
}

const defaultState: SurveyEditStateProps = surveyEditStateFactory();

/*
    ------ disabled field reduction -------
 */

function getDisabledFields(survey: SurveyResource): SurveyDisabledFields {
  const disabled: Mutable<SurveyDisabledFields> = {};

  if (survey.status === Survey.Statuses.DRAFT) {
    if (isNotNullNorUndefined(survey.surveyTemplateId)) {
      disabled.type = true;
    }
  } else {
    addDisabledFieldsForNonDraftSurvey(disabled);
  }
  if (survey.status === Survey.Statuses.CLOSED) {
    addDisabledFieldsForClosedSurvey(disabled);
  }

  return disabled;
}

function addDisabledFieldsForNonDraftSurvey(disabled: Mutable<SurveyDisabledFields>): void {
  // settings
  disabled.type = true;
  disabled.isAnonymous = true;
  disabled.allowSkipQuestion = true;
  // questions
  disabled.postTemplateIds = true;
  // language
  disabled.surveyLanguages = true;
}

function addDisabledFieldsForClosedSurvey(disabled: Mutable<SurveyDisabledFields>): void {
  // settings
  disabled.hideResults = true;
  disabled.introductionText = true;
  disabled.channels = true;
  disabled.reminderLimit = true;
  disabled.reminderInterval = true;
  // audience
  disabled.audience = true;
  // language
  disabled.surveyLanguages = true;
}

/*
  ---- actions reducers ----
 */

function reduceHasPendingAudienceUpdates(hasPendingAudienceUpdates: boolean,
  previousSurvey: Loadable<SurveyResource>,
  newSurvey: SurveyResource): boolean {
  if (hasPendingAudienceUpdates) {
    // some updates were already there so we still have some...
    return true;
  }

  if (Loadable.isNotLoaded(previousSurvey)) {
    // survey must obviously be loaded... Anyway, just assume the audience is updated in that case
    return true;
  }

  return areNotEquals(previousSurvey.value.audience, newSurvey.audience);
}

function reduceFetchSurveySuccess(state: SurveyEditStateProps, survey: SurveyResource): SurveyEditStateProps {
  const { relationships, ...surveyWithoutRelationships } = survey;
  const partialState: Mutable<Partial<SurveyEditStateProps>> = {
    survey: Loadable.loaded(surveyWithoutRelationships),
    updatedAt: surveyWithoutRelationships.updatedAt,
    disabled: getDisabledFields(surveyWithoutRelationships),
    steps: reduceSteps(
      getAllSurveyStepDefinitions(),
      getSurveyMaxReachedStep(surveyWithoutRelationships) - 1,
      WrongEntityException.noErrors()
    ),
  };
  if (isNotNullNorUndefined(relationships)) {
    if (isNotNullNorUndefined(relationships.questions)) {
      partialState.questions = relationships.questions;
    }
  }
  if (isNotNullNorUndefined(relationships)) {
    if (isNotNullNorUndefined(relationships.accesses)) {
      partialState.accesses = relationships.accesses;
    }
  }
  return {
    ...state,
    ...partialState,
  };
}

function reduceUpdateSurvey(state: SurveyEditStateProps, survey: SurveyResource): SurveyEditStateProps {
  const { relationships, ...surveyWithoutRelationships } = survey;
  if (
    isNotNullNorUndefined(surveyWithoutRelationships.channels.email) &&
    surveyWithoutRelationships.channels.email.use &&
    isStringAndNotEmpty(surveyWithoutRelationships.channels.email.body)
  ) {
    const { email } = surveyWithoutRelationships.channels;
    surveyWithoutRelationships.channels.email = Object.assign(email, {
      body: email.body.replace(/(?:\r\n|\r|\n)/g, "<br>"),
    });
  }

  return {
    ...state,
    survey: Loadable.loaded(surveyWithoutRelationships),
    steps: reduceSteps(
      getAllSurveyStepDefinitions(),
      getSurveyMaxReachedStep(surveyWithoutRelationships) - 1,
      state.errors
    ),
    hasPendingUpdates: true,
    hasPendingAudienceUpdates: reduceHasPendingAudienceUpdates(
      state.hasPendingAudienceUpdates,
      state.survey,
      surveyWithoutRelationships
    ),
  };
}

function reduceUpdateOptimisticSurveySuccess(state: SurveyEditStateProps,
  survey: SurveyResource): SurveyEditStateProps {
  return {
    ...state,
    updatedAt: survey.updatedAt,
    hasPendingUpdates: false,
    hasPendingAudienceUpdates: false,
    survey: Loadable.loaded(survey),
  };
}

function reduceUpdatePessimisticSurveySuccess(state: SurveyEditStateProps,
  survey: SurveyResource): SurveyEditStateProps {
  return {
    ...state,
    updatedAt: survey.updatedAt,
    survey: Loadable.loaded(survey),
    hasPendingUpdates: false,
    hasPendingAudienceUpdates: false,
    hasPendingAccessesUpdates: false,
  };
}

function reduceUpdateValidationErrors(state: SurveyEditStateProps,
  errors: WrongEntityException.Errors): SurveyEditStateProps {
  return {
    ...state,
    errors,
    steps: reduceSteps(
      getAllSurveyStepDefinitions(),
      Loadable.mapOr(state.survey, s => getSurveyMaxReachedStep(s) - 1, 0),
      errors
    ),
  };
}

function reduceUploadSurveyLogoSuccess(state: SurveyEditStateProps,
  modifiedSurveyAfterUpload: SurveyResource): SurveyEditStateProps {
  return {
    ...state,
    survey: Loadable.loaded(modifiedSurveyAfterUpload),
    updatedAt: modifiedSurveyAfterUpload.updatedAt,
  };
}

function reduceChangeQuestionModalState(state: SurveyEditStateProps,
  isModalOpen: boolean,
  modalPurpose?: SURVEY_QUESTIONS_MODALS): SurveyEditStateProps {

  return {
    ...state,
    questionPageState: {
      ...state.questionPageState,
      isModalOpen,
      modalPurpose,
      errors: WrongEntityException.noErrors(),
    },
  };
}

function reduceQuestionRequest(state: SurveyEditStateProps): SurveyEditStateProps {
  return {
    ...state,
    questionPageState: {
      ...state.questionPageState,
      isRequestingUpdate: true,
    },
  };
}

function reduceQuestionError(state: SurveyEditStateProps, error: any): SurveyEditStateProps {
  const errors = toErrors(error);
  return {
    ...state,
    questionPageState: {
      ...state.questionPageState,
      isRequestingUpdate: false,
      errors,
    },
  };
}

function reduceAddQuestionSuccess(state: SurveyEditStateProps,
  question: SurveyQuestionResource, meta: any): SurveyEditStateProps {
  let modifiedSurvey = state.survey;
  if (Loadable.isLoaded(modifiedSurvey)) {
    modifiedSurvey = Loadable.loaded({
      ...modifiedSurvey.value,
      postTemplateIds: [...modifiedSurvey.value.postTemplateIds, question._id],
    });
  }

  return {
    ...state,
    questions: [...state.questions, question],
    updatedQuestion: question,
    survey: modifiedSurvey,
    questionPageState: {
      ...state.questionPageState,
      isRequestingUpdate: false,
      isModalOpen: meta.keepModalOpen === true,
    },
  };
}

function reduceUpdateQuestionSuccess(state: SurveyEditStateProps,
  question: SurveyQuestionResource, meta: any): SurveyEditStateProps {
  return {
    ...state,
    questions: state.questions.map(q => q._id === question._id ? question : q),
    updatedQuestion: question,
    questionPageState: {
      ...state.questionPageState,
      isRequestingUpdate: false,
      isModalOpen: meta.keepModalOpen === true, 
      // The modal will close by default, when the update happens
      errors: WrongEntityException.noErrors(),
    },
  };
}

function reduceRemoveQuestionSuccess(state: SurveyEditStateProps,
  questionId: string): SurveyEditStateProps {
  let modifiedSurvey = state.survey;
  if (Loadable.isLoaded(modifiedSurvey)) {
    modifiedSurvey = Loadable.loaded({
      ...modifiedSurvey.value,
      postTemplateIds: modifiedSurvey.value.postTemplateIds.filter(id => id !== questionId),
    });
  }

  return {
    ...state,
    questions: state.questions.filter(q => q._id !== questionId),
    survey: modifiedSurvey,
    questionPageState: {
      ...state.questionPageState,
      isRequestingUpdate: false,
      isModalOpen: false,
    },
  };
}

function reduceUpdateAccessList(state: SurveyEditStateProps,
  accesses: AccessResource[]): SurveyEditStateProps {
  return {
    ...state,
    accesses,
    hasPendingAccessesUpdates: true,
    hasPendingUpdates: true,
  };
}

function reduceToggleManagersSynchronizationSuccess(state: SurveyEditStateProps,
  survey: SurveyResource): SurveyEditStateProps {
  let modifiedSurvey = state.survey;
  if (Loadable.isLoaded(modifiedSurvey)) {
    modifiedSurvey = Loadable.loaded({
      ...modifiedSurvey.value,
      areManagersSynchronized: survey.areManagersSynchronized,
    });
  }

  const accesses = survey.relationships!.accesses!;
  return {
    ...state,
    survey: modifiedSurvey,
    accesses,
    hasPendingAccessesUpdates: false,
  };
}

function reduceTogglePreviewModalOpen(state: SurveyEditStateProps,
  open: boolean): SurveyEditStateProps {
  return {
    ...state,
    isPreviewModalOpen: open,
  };
}

function reduceDifferentialAudienceModal(state: SurveyEditStateProps,
  open: boolean): SurveyEditStateProps {
  return {
    ...state,
    isUpdateAudienceModalOpen: open,
  };
}

function reduceSendSurveyPreviewSuccess(state: SurveyEditStateProps): SurveyEditStateProps {
  return {
    ...state,
    isPreviewModalOpen: false,
    previewErrors: WrongEntityException.noErrors(),
  };
}

function reduceSendSurveyPreviewError(state: SurveyEditStateProps, error: any): SurveyEditStateProps {
  const previewErrors = toErrors(error);
  return {
    ...state,
    previewErrors,
  };
}

function reduceExitPage(): SurveyEditStateProps {
  return surveyEditStateFactory();
}

/*
  The global reducer
 */
export const surveyEditReducers = (state: SurveyEditStateProps = defaultState, action: any) => {
  switch (action.type) {
    case SurveyEditActionTypes.FETCH_SURVEY_SUCCESS:
      return reduceFetchSurveySuccess(state, action.payload.data);
    case SurveyEditActionTypes.FETCH_SURVEY_ERROR:
      return { ...state, survey: Loadable.inError({errorCode: action.payload.status, errorMessage: "" }) };
    case SurveyEditActionTypes.UPDATE_SURVEY:
      return reduceUpdateSurvey(state, action.payload.survey);
    case SurveyEditActionTypes.UPDATE_OPTIMISTIC_SURVEY_SUCCESS:
      return reduceUpdateOptimisticSurveySuccess(state, action.payload.data);
    case SurveyEditActionTypes.UPDATE_PESSIMISTIC_SURVEY_SUCCESS:
      return reduceUpdatePessimisticSurveySuccess(state, action.payload.data);
    case SurveyEditActionTypes.UPDATE_VALIDATION_ERRORS:
      return reduceUpdateValidationErrors(state, action.payload.errors);
    case SurveyEditActionTypes.UPLOAD_SURVEY_LOGO_SUCCESS:
      return reduceUploadSurveyLogoSuccess(state, action.payload.data);
    case SurveyEditActionTypes.CHANGE_QUESTION_MODAL_STATE:
      return reduceChangeQuestionModalState(state, action.payload.isModalOpen, action.payload.modalPurpose);
    case SurveyEditActionTypes.ADD_QUESTION_REQUEST:
    case SurveyEditActionTypes.UPDATE_QUESTION_REQUEST:
    case SurveyEditActionTypes.REMOVE_QUESTION_REQUEST:
      return reduceQuestionRequest(state);
    case SurveyEditActionTypes.UPDATE_QUESTION_ERROR:
    case SurveyEditActionTypes.ADD_QUESTION_ERROR:
    case SurveyEditActionTypes.ADD_QUESTION_NON_NETWORK_ERROR:
    case SurveyEditActionTypes.REMOVE_QUESTION_ERROR:
      return reduceQuestionError(state, action.payload.error);
    case SurveyEditActionTypes.ADD_QUESTION_SUCCESS:
      return reduceAddQuestionSuccess(state, action.payload.data, getOr(action.meta, {}));
    case SurveyEditActionTypes.UPDATE_QUESTION_SUCCESS:
      return reduceUpdateQuestionSuccess(state, action.payload.data, getOr(action.meta, {}));
    case SurveyEditActionTypes.REMOVE_QUESTION_SUCCESS:
      return reduceRemoveQuestionSuccess(state, action.meta.questionId);
    case SurveyEditActionTypes.UPDATE_ACCESS_LIST:
      return reduceUpdateAccessList(state, action.payload.accesses);
    case SurveyEditActionTypes.TOGGLE_MANAGERS_SYNCHRONIZATION_SUCCESS:
      return reduceToggleManagersSynchronizationSuccess(state, action.payload.data);
    case SurveyEditActionTypes.TOGGLE_PREVIEW_MODAL_OPEN:
      return reduceTogglePreviewModalOpen(state, action.payload.open);
    case SurveyEditActionTypes.TOGGLE_DIFFERENTIAL_AUDIENCE_MODAL:
      return reduceDifferentialAudienceModal(state, action.payload.open);
    case SurveyEditActionTypes.UPDATE_DIFFERENTIAL_AUDIENCE_SUCCESS:
      return reduceDifferentialAudienceModal(state, false);
    case SurveyEditActionTypes.SEND_SURVEY_PREVIEW_SUCCESS:
      return reduceSendSurveyPreviewSuccess(state);
    case SurveyEditActionTypes.SEND_SURVEY_PREVIEW_ERROR:
      return reduceSendSurveyPreviewError(state, action.payload.error);
    case SurveyEditActionTypes.EXIT_PAGE:
      return reduceExitPage();
  }

  return state;
};
