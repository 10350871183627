import React from "react";
import { TableProps } from "antd/lib/table";
import { Icon, Tooltip } from "antd";
import styled from "styled-components";
import { Trans } from "react-i18next";
import Table from "@components/core/Table";
import { Paragraph } from "@components/core/Typography";
import Select from "@components/core/Select";
import Palette from "@src/config/theme/palette";

import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { UpdateUserRoleAction, RemoveUserAction} from "../store/actions";

const { Option } = Select;

export interface AddUsersTableReduxStateProps {
  availableRoles: string[];
}

export interface AddUsersTableActionsProps {
  onUpdateUserRole: (payload: UpdateUserRoleAction["payload"]) => void;
  onRemoveUser: (payload: RemoveUserAction["payload"]) => void;
}

interface Props extends TableProps<any>, AddUsersTableActionsProps, AddUsersTableReduxStateProps {}

export class AddUsersTable extends React.Component<Props> {
  state = {
    hoverIndex: null,
  };

  onRow = (record: any, rowIndex: number) => {
    return {
      onMouseEnter: () => {
        this.setState({ hoverIndex: rowIndex });
      },
      onMouseLeave: () => {
        this.setState({ hoverIndex: null });
      },
    };
  };

  onChange(index: number, value: any) {
    const payload = {
      index,
      role: value,
    };
    this.props.onUpdateUserRole(payload);
  }

  onRemove(index: number) {
    this.props.onRemoveUser({ index });
  }

  render() {
    const { availableRoles, ...rest } = this.props;
    const { hoverIndex } = this.state;

    return (
      <StyledTable
        columns={[{
          title: <Paragraph weight="light">{rest.dataSource!.length}
          <Trans>employees to be added</Trans></Paragraph>,
          dataIndex: "email",
          width: "55%",
        }, {
          title: (
            <RoleTitle>
              <Paragraph translate="yes">Role</Paragraph>
              <Tooltip title={<Trans>roles</Trans>}>
                <Icon type="info-circle" />
              </Tooltip>
            </RoleTitle>
          ),
          dataIndex: "role",
          render: (defaultRole, __, index) => isNotEmptyArray(availableRoles) ? (
          // @ts-ignore
            <Select
              value={defaultRole}
              onChange={this.onChange.bind(this, index)}
            >
              {availableRoles.map(role => (
                <Option key={role} value={role}>{role}</Option>
              ))}
            </Select>
          ) : <Trans>No roles available</Trans>,
          width: "30%",
        }, {
          title: null,
          render: (__, ___, rowIndex) => hoverIndex === rowIndex ? (
            <RemoveButton onClick={this.onRemove.bind(this, rowIndex)}>
              <Trans>Remove</Trans>
            </RemoveButton>
          ) : null,
          width: "15%",
          align: "center",
        }]}
        rowKey="email"
        onRow={this.onRow}
        {...rest}
      />
    );
  }
}

const StyledTable = styled(Table)<TableProps<any>>`
  margin-top: 32px !important;
`;

const RoleTitle = styled.div`
  display: flex;
  align-items: center;
  i {
    cursor: pointer;
    color: ${Palette.lightGreyBlue} !important;
  }
  p {
    margin-right: 4px !important;
  }
`;

const RemoveButton = styled.span`
  color: ${Palette.darkPink};
  width: 100%;
  cursor: pointer;
`;
