import { ActionPlanTemplateResource } from "hyphen-lib/dist/domain/resource/action/ActionPlanTemplateResource";
import { Map, Record, RecordOf } from "immutable";
import {
  ActionListActionTypes,
  FocusAreaListActionTypes,
  ModifyListAction,
  ToggleActionModalVisibilityAction,
  UpdateActionRequestAction,
  UpdateActionSuccessAction,
} from "@screens/Insights/Actions/store/actions";
import { ActionResource } from "hyphen-lib/dist/domain/resource/action/ActionResource";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { Notification } from "@src/store/notifications/types";

export enum ActionModalType {
  VIEW,
  SET_COMPLETE,
  SET_DISMISSED,
}

export interface SelectedActionResource extends Map<string, any> {
  toJS(): ActionResource;
  get<K extends keyof ActionResource>(key: K): ActionResource[K];
}

export interface SelectedFocusAreaResource extends Map<string, any> {
  toJS(): FocusAreaResource;
  get<K extends keyof FocusAreaResource>(key: K): FocusAreaResource[K];
}

export interface ActionModalProps {
  readonly isActionModalVisible: boolean;
  readonly actionModalType: ActionModalType;
  readonly action: Optional<ActionResource>;
}

export interface StateProps {
  readonly pageSize: number;
  readonly selectedActionResource: SelectedActionResource;
  readonly selectedRowKeys: string[];
  readonly actionModalProps: ActionModalProps;
  readonly isActionUpdating: boolean;
  readonly action: ActionResource | null;
  readonly visible: boolean;
  readonly isLoading: boolean;
  readonly isError: boolean;
  readonly enableSuccessOKRModal: boolean;
}

export interface FocusAreaNotificationBar {
  show: boolean;
  message?: string;
  type?: Notification["type"];
}

export interface FocusAreaListProps {
  readonly pageSize: number;
  readonly selectedFocusAreaResource: SelectedFocusAreaResource;
  readonly isUpdatingFocusAreaResource: boolean;
  readonly isDeletingFocusAreas: boolean;
  readonly focusArea: FocusAreaResource | null;
  readonly isLoading: boolean;
  readonly isError: boolean;
  readonly created: boolean;
  readonly completedFARequest: boolean;
  readonly notificationBar: FocusAreaNotificationBar;
  readonly isFetchingActionPlanTemplates: boolean;
  readonly focusAreaTemplates: ActionPlanTemplateResource[];
}

export type State = RecordOf<StateProps>;
export type FocusAreaListState = RecordOf<FocusAreaListProps>;
const defaultActionPlanListStateValues: StateProps = {
  pageSize: 10,
  selectedActionResource: Map({}) as SelectedActionResource,
  selectedRowKeys: [],
  actionModalProps: {
    isActionModalVisible: false,
    actionModalType: ActionModalType.VIEW,
    action: Optional.empty(),
  },
  isActionUpdating: false,
  action: null,
  visible: false,
  isLoading: false,
  isError: false,
  enableSuccessOKRModal: false,
};

const defaultFocusAreaListStateValues: FocusAreaListProps = {
  pageSize: 10,
  selectedFocusAreaResource: Map({}) as SelectedFocusAreaResource,
  isUpdatingFocusAreaResource: false,
  focusArea: null,
  isLoading: false,
  isError: false,
  isDeletingFocusAreas: false,
  created: false,
  completedFARequest: true,
  notificationBar: {
    show: false,
  },
  focusAreaTemplates: [],
  isFetchingActionPlanTemplates: false,
};

export const stateFactory = Record<StateProps>(
  defaultActionPlanListStateValues
);
export const focusAreaListContainerStateFactory = Record<FocusAreaListProps>(
  defaultFocusAreaListStateValues
);
const defaultState = stateFactory();
const defaultFocusAreaState = focusAreaListContainerStateFactory();

type Actions =
  | ModifyListAction
  | UpdateActionRequestAction
  | UpdateActionSuccessAction
  | ToggleActionModalVisibilityAction;

export function reducers(
  state: State = defaultState,
  action: Actions | any
): State {
  switch (action.type) {
    case ActionListActionTypes.TOGGLE_ACTION_MODAL_VISIBILITY: {
      const { payload } = action;
      if (payload.isActionModalVisible) {
        const modalProps: ActionModalProps = {
          isActionModalVisible: payload.isActionModalVisible,
          actionModalType: payload.actionModalType!,
          action: payload.action,
        };
        return state.set("actionModalProps", modalProps);
      } else {
        const modalProps: ActionModalProps = {
          isActionModalVisible: payload.isActionModalVisible,
          actionModalType: ActionModalType.VIEW,
          action: Optional.empty(),
        };
        return state.set("actionModalProps", modalProps);
      }
    }
    case ActionListActionTypes.UPDATE_ACTION_REQUEST:
      return state.set("isActionUpdating", true);
    case ActionListActionTypes.UPDATE_ACTION_SUCCESS:
      return state.merge({
        isActionUpdating: false,
        enableSuccessOKRModal: true,
      });
    case ActionListActionTypes.UPDATE_ACTION_ERROR:
      return state.merge({
        isActionUpdating: false,
        enableSuccessOKRModal: false,
      });
    case ActionListActionTypes.SET_ACTION_FOR_DELETE:
      return state.merge({
        action: action.payload,
        visible: true,
      });
    case ActionListActionTypes.RESET_ACTION_FOR_DELETE:
      return state.merge({
        action: null,
        visible: false,
      });
    case ActionListActionTypes.DELETE_ACTION_REQUEST:
      return state.set("isLoading", true);
    case ActionListActionTypes.DELETE_ACTION_SUCCESS:
      return state.merge({
        isLoading: false,
        visible: false,
      });
    case ActionListActionTypes.DELETE_ACTION_ERROR:
      return state.merge({
        isLoading: false,
        visible: false,
      });
    case ActionListActionTypes.CLEAN_ACTION_PLAN_RESOURCE:
      return state.set("enableSuccessOKRModal", false);
    default:
      return state;
  }
}

export function focusAreaListReducers(
  state: FocusAreaListState = defaultFocusAreaState,
  action: Actions | any
): FocusAreaListState {
  switch (action.type) {
    case FocusAreaListActionTypes.CREATE_FOCUS_AREA_REQUEST:
      return state.set("completedFARequest", false).set("created", false);
    case FocusAreaListActionTypes.CREATE_FOCUS_AREA_REQUEST_DEBOUNCED:
      return state.set("completedFARequest", false).set("created", false);
    case FocusAreaListActionTypes.CREATE_FOCUS_AREA_SUCCESS:
      return state.set("completedFARequest", true);
    case FocusAreaListActionTypes.CREATE_FOCUS_AREA_ERROR:
      return state.set("completedFARequest", true).set("created", false);
    case FocusAreaListActionTypes.UPDATE_FOCUS_AREA_REQUEST:
      return state.set("isUpdatingFocusAreaResource", true);
    case FocusAreaListActionTypes.UPDATE_FOCUS_AREA_SUCCESS:
      return state.set("isUpdatingFocusAreaResource", false);
    case FocusAreaListActionTypes.UPDATE_FOCUS_AREA_ERROR:
      return state.set("isUpdatingFocusAreaResource", false);
    case FocusAreaListActionTypes.DELETE_FOCUS_AREA_REQUEST:
      return state.set("isDeletingFocusAreas", true);
    case FocusAreaListActionTypes.DELETE_FOCUS_AREA_SUCCESS:
    case FocusAreaListActionTypes.DELETE_FOCUS_AREA_ERROR:
      return state.set("isDeletingFocusAreas", false);
    case FocusAreaListActionTypes.FETCH_FOCUS_AREA_CATEGORY_TEMPLATE_REQUEST:
      return state.set("isFetchingActionPlanTemplates", true);
    case FocusAreaListActionTypes.FETCH_FOCUS_AREA_CATEGORY_TEMPLATE_ERROR:
      return state
        .set("focusAreaTemplates", [])
        .set("isFetchingActionPlanTemplates", false);
    case FocusAreaListActionTypes.FETCH_FOCUS_AREA_CATEGORY_TEMPLATE_SUCCESS:
      return state
        .set("focusAreaTemplates", action.payload.data)
        .set("isFetchingActionPlanTemplates", false);
    case FocusAreaListActionTypes.SHOW_NOTIFICATION_BAR_FOCUS_AREA:
      return state.set("notificationBar", action.payload);
    default:
      return state;
  }
}
