import React, { useState } from "react";
import ContainerCard from "@src/components/core/ContainerCard";
import styled from "styled-components";
import { Score } from "@screens/Insights/components/Reports/Score";
import CompareValue from "@src/components/core/CompareValue";
import { Divider as AntDivider } from "antd";
import { DistributionResult } from "hyphen-lib/dist/domain/resource/report/common/DistributionResult";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { adjustElementsAndPercentages } from "@src/utils/Graphs";
import { getOr, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import Palette, { getBarThemeForFavorability, getBarThemeForEnps, getBarThemeForMultipleChoice }
  from "@src/config/theme/palette";
import { StackedBarGraph } from "@src/components/core/StackedBarGraph";
import { Paragraph, Heading } from "@src/components/core/Typography";
import {
  LineChart,
  LineChartData,
  CustomLineChartToolTipProps
} from "@src/components/core/LineChart";
import Button from "@src/components/core/Button";
import {
  PulsePollOverviewReportResource
} from "hyphen-lib/dist/domain/resource/pulsePoll/report/PulsePollOverviewReportResource";
import { extractComparison } from "hyphen-lib/dist/business/calculation/benchmark/Benchmarks";
import { formatDate } from "hyphen-lib/dist/lang/Dates";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { QuestionType } from "hyphen-lib/dist/domain/common/QuestionType";
import { not } from "hyphen-lib/dist/lang/Booleans";
import CommentSentiment from "../../components/Reports/CommentSentiment";
import { 
  Summary, 
  HeadingSubTitleUnderline,
  HeadingSubTitle 
} from "../../Survey/components/OverviewReport/components/Overview";
import { Trans } from "react-i18next";
import FavorabilityOreNPSInfoModal from "@src/components/core/InfoModal";
import { calculateNpsDistribution, generateDistributionText } from "@src/utils/eNpsAndFavDistributionUtils";
import { HeaderContainer } from "../../Dashboard/components/DashboardView/components/TotalFavorability";
import { Participation } from "hyphen-lib/dist/domain/common/Participation";

type VotesSummary = PulsePollOverviewReportResource.WithScore.VotesSummary |
  PulsePollOverviewReportResource.NoScore.VotesSummary;
interface PulsePollAnswerDistributionProps {
  readonly compareAverage: number;
  readonly comparePrevious: number;
  readonly onDataPointClick?: (data: LineChartData) => void;
  readonly averageComparisonLabel: Optional<string>;
  readonly comparisonLabel: Optional<string>;
  readonly showSecondComparison: boolean;
  readonly areComparisonsVisible: boolean;
  readonly anonymityThreshold: number;
  readonly summary: VotesSummary;
  readonly instanceId: string;
  readonly isNPSQuestion?: boolean;
  readonly isRatingQuestion?: boolean;
  readonly companyName: string;
  readonly type: PulsePollOverviewReportResource.NonFilteredType;
  readonly participationData: Participation;
  readonly filteredParticipantData: Participation;
}

export function PulsePollAnswerDistribution(props: PulsePollAnswerDistributionProps) {
  const {
    compareAverage,
    comparePrevious,
    averageComparisonLabel,
    comparisonLabel,
    showSecondComparison,
    areComparisonsVisible,
    type,
    summary,
    isNPSQuestion,
    isRatingQuestion,
    instanceId,
    companyName
  } = props;

  const [visibleFavorabilityModal, isVisibleFavorabilityModal] = useState(false);
  const [visibleEnpsModal, isVisibleEnpsModal] = useState(false);
  
  const eNPSHeader = () => {
    return <HeaderContainer width="53px">
      <Heading size="small" weight={"bold"}><Trans>eNPS</Trans>  |</Heading>
      <HeadingSubTitle marginLeft="3px">
        <Trans i18nKey="enpsSubTitle">
          Employee net promoter score (eNPS) is a measure of 
          employee loyalty, calculated from responses to specific 
          eNPS questions.
        </Trans>
        <HeadingSubTitleUnderline data-cy='calFav' onClick={() => isVisibleEnpsModal(true)}> 
          <Trans>How is this calculated ?</Trans>
        </HeadingSubTitleUnderline>
      </HeadingSubTitle>
  </HeaderContainer>;
  };

  const showFavorabilityInfoModal = (likert: PulsePollOverviewReportResource.WithScore.VotesSummary) => {
      const {  
        numberOfVotes,
        choiceLabels,
        distribution,
        score
      } = likert;
      const { distributionsText, noteDistributionsText } = generateDistributionText(
        choiceLabels,
        distribution,
        numberOfVotes
      );
      const verbBasedOnVoteCount = numberOfVotes > 1 ? "are" : "is";
      const responseText = numberOfVotes > 1 ? "responses" : "response";
      const description = <Trans i18nKey="pulsePollFavorabilityInfo" 
        values={{  numberOfVotes, distributionsText, score }} 
        defaults = {`Among the ${numberOfVotes} ${responseText} to the Likert-scale question that 
          ${verbBasedOnVoteCount} eligible for favorability
          calculation, ${distributionsText} which leads to a favorability score of ${score}%.`} />;

      const note = <Trans i18nKey="overallFavorabilityNote" values = {{noteDistributionsText}} 
      defaults = {`The percentages indicating the votes for ${noteDistributionsText} are 
      rounded to the nearest whole number for clarity. However, our favorability calculation uses 
      precise decimal values to ensure accuracy. This may result in a difference of up to 1 percentage
      point between the percentage of votes and the calculated favorability score.
      `}
    />;

    return visibleFavorabilityModal && (
      <FavorabilityOreNPSInfoModal
        visible={visibleFavorabilityModal}
        title="Your favorability score"
        description={description}
        note={note}
        onClose={() => isVisibleFavorabilityModal(false)}
      />
      );
  };


  const showEnpsInfoModal = (eNps: PulsePollOverviewReportResource.WithScore.VotesSummary) => {
    const {promoters, detractors} = calculateNpsDistribution(eNps.distribution);
    const {completed} = props.filteredParticipantData;

    const  description = <p> 
      <Trans i18nKey="pollNpsCalInfo" values={{companyName}} defaults = {`Based on your employees' responses to 
        the eNPS question such as, "I would recommend ${companyName} as a great place 
        to work" (on a 1-10 scale), they are categorized into promoters (scores 9-10), passives
        (scores 7-8) and detractors (scores 0-6). eNPS is the difference between the percentage 
        of promoters and detractors.`} />
      <br/>
      <Trans i18nKey="pollNpsInfo" values={{ completed, 
        detractors, promoters }} defaults = {`Among employees who have taken this
         poll (${completed}), ${promoters}% are promoters and ${detractors}% are detractors, which results in an 
         eNPS score of ${eNps.score}.
        `}/>
      </p>;

    const note = <Trans i18nKey="pollNpsInfoNote" 
      defaults = {`The percentages indicating the promoters and detractors 
        in the histogram are rounded to the nearest whole number for clarity. 
        However, our eNPS calculation uses precise decimal values to ensure 
        accuracy. This may result in a difference of up to 1 point between 
        the percentage of promoters/ detractors and the calculated eNPS score.
      `} />; 

      return visibleEnpsModal && (
        <FavorabilityOreNPSInfoModal
          visible={visibleEnpsModal}
          title="Your eNPS score"
          description={description}
          note={note}
          onClose={() => isVisibleEnpsModal(false)}
        />
      );
  };
  
  const renderDistributionGraph = (
    questionType: QuestionType.ENPS | QuestionType.LIKERT,
    choiceLabels: Optional<string>[] = [],
    distribution: DistributionResult,
    numberOfVotes: number
  ) => {
    const { pieceToAdd } = adjustElementsAndPercentages(distribution);
    let barGraphTheme = getBarThemeForMultipleChoice(choiceLabels);
    let labels = choiceLabels;
    let choicesData = distribution;

    if (questionType === QuestionType.LIKERT) {
      barGraphTheme = getBarThemeForFavorability(getOr(choiceLabels.length, 7), choiceLabels);
    } else if (questionType === QuestionType.ENPS) {
      barGraphTheme = getBarThemeForEnps();
      labels = choiceLabels.slice().reverse();
      choicesData = distribution.slice().reverse();
    }

    const graphData = choicesData.map(({ total, percentage }, index) => {
      const { backgroundColor, fontColor, label } = barGraphTheme[index];
      const labelText = getOr(labels[index], label);
      return {
        value: total,
        percentage: getOr(percentage, 0),
        percentageWidth:
          isNotNullNorUndefined(percentage) && percentage > 0
            ? percentage + pieceToAdd
            : 0,
        backgroundColor,
        fontColor,
        label: labelText,
      };
    });

    return <StackedBarGraph
      data-cy="stackedBarGraph"
      data={graphData} width="100%"
      height="48px"
      data-jest="choice-distribution"
      numberOfVotes = {numberOfVotes}
    />;
  };

  const isAverageComparisonAvailable =
    isNotNullNorUndefined(compareAverage) &&
    isNotNullNorUndefined(averageComparisonLabel);

  const isSecondComparisonAvailable =
    showSecondComparison &&
    isNotNullNorUndefined(comparePrevious) &&
    isNotNullNorUndefined(comparisonLabel);

  const renderFavorability = () => {
    if (type === PulsePollOverviewReportResource.NonFilteredType.NO_SCORE) {
      if (summary.distribution) {
        return (
          <CommentSentiment
            title="Answer distribution"
            hasSentimentScore={false}
            choiceLabels={summary.choiceLabels}
            distribution={summary.distribution}
            totalVotes={summary.numberOfVotes}
          />
        );
      }
    }

    if (type === PulsePollOverviewReportResource.NonFilteredType.WITH_SCORE) {
      const summaryWithScore = summary as PulsePollOverviewReportResource.WithScore.VotesSummary;
      const previousComparison = extractComparison(summaryWithScore.compare, "previous");
      const averageComparison = extractComparison(summaryWithScore.compare, "average");
      const lineChartData: LineChartData[] =
        summaryWithScore.scoreTrend.map(
          val => {
            return [formatDate(val.iteration), val.score, val._id];
          }
        );
      const highlightedValue = lineChartData.filter(iteration => iteration[2] === instanceId);

      return (
        <>
          <SectionContainer>
            {isNPSQuestion && (
              <NPSSummary>
                {eNPSHeader()}
                {summary.numberOfVotes}{" "}
                <Trans>{summary.numberOfVotes === 1 ? "answer" : "answers"}</Trans>
              </NPSSummary>
            )}
          </SectionContainer>
          {
            isRatingQuestion && !isNPSQuestion && (
              <SectionContainer>
                <HeaderContainer width="100px">
                  <Heading size="small" weight={"bold"} translate="yes"> <Trans>Favorability</Trans> | </Heading>
                    <HeadingSubTitle marginLeft="3px">
                      <Trans>
                        Favorability is a measure of employee satisfaction across different 
                        workplace factors, based on the Likert-scale question in this poll.
                      </Trans>
                      <HeadingSubTitleUnderline onClick={() => isVisibleFavorabilityModal(true)} data-cy='calFav'> 
                        <Trans>How is this calculated ?</Trans>
                      </HeadingSubTitleUnderline>
                  </HeadingSubTitle>
                </HeaderContainer>
                <Summary data-cy="answersCount">
                  {summary.numberOfVotes}{" "}
                  <Trans>{summary.numberOfVotes === 1 ? "answer" : "answers"}</Trans>
                </Summary>
              </SectionContainer>
            )
          }
          {showEnpsInfoModal(summaryWithScore)}
          {showFavorabilityInfoModal(summaryWithScore)}
          <ScoreDistributionContainer>
            <ScoreAndComparison>
              <Score
                size="xl"
                score={summaryWithScore.score}
                format={isNPSQuestion ? "nps" : "favorability"}
                percentage={not(isNPSQuestion)}
                data-jest="score"
              />
              {areComparisonsVisible && (
                <Comparison>
                  {isAverageComparisonAvailable && (
                    <CompareValue
                      compare={averageComparison}
                      compareWith="company's average"
                      percentage={true}
                      data-jest="score_comparison_average"
                    />
                  )}
                  <Divider />
                  {isSecondComparisonAvailable && (
                    <CompareValue
                      compare={previousComparison}
                      compareWith="previous"
                      percentage={true}
                      data-jest="score_comparison_previous"
                    />
                  )}
                </Comparison>
              )}
            </ScoreAndComparison>
            <DistributionContainer>
              {renderDistributionGraph(
                isRatingQuestion ? QuestionType.LIKERT : QuestionType.ENPS,
                summary.choiceLabels,
                summary.distribution,
                summary.numberOfVotes
              )}
            </DistributionContainer>
          </ScoreDistributionContainer>
          {
            isNotEmptyArray(lineChartData) && (
              <>
                <ChartHeader>
                  <Paragraph weight="bold" translate="yes">Trend</Paragraph>
                  <ChartSubHeader>
                    <Paragraph>
                      <Trans>{isNPSQuestion ? "eNPS" : "Favorability score"}</Trans>
                      <Trans>across iterations</Trans>
                    </Paragraph>
                  </ChartSubHeader>
                </ChartHeader>
                <ChartContainer>
                  <LineChart
                    data={lineChartData.reverse()}
                    minAxisY={isNPSQuestion ? -100 : 0}
                    showCurrent={false}
                    highlightDataPoint={
                      highlightedValue.length > 0
                        ? {
                          value: highlightedValue[0],
                          color: Palette.aquaBlue,
                        } : undefined
                    }
                    onDataPointClick={props.onDataPointClick}
                    shouldShowExtendedMonths={false}
                    customToolTipText={
                      <TooltipComponent onDataPointClick={props.onDataPointClick} />
                    }
                  />
                </ChartContainer>
              </>
            )
          }
        </>
      );
    }
  };

  return (
    <ContainerCard title="Overview" data-cy="polls_overviewSection">{renderFavorability()}</ContainerCard>
  );
}

function TooltipComponent(
  props: CustomLineChartToolTipProps & {
    onDataPointClick?: PulsePollAnswerDistributionProps["onDataPointClick"];
  }
) {
  const { graphKey, value, uniqueValue } = props;
  function onDataPointClick() {
    if (
      isNotNullNorUndefined(props.onDataPointClick) &&
      isNotNullNorUndefined(graphKey)
    ) {
      props.onDataPointClick([graphKey, value, uniqueValue]);
    }
  }

  return (
    <TooltipContainer>
      <Paragraph>{graphKey}</Paragraph>
      <Paragraph>Favorability: {value}</Paragraph>
      <TooltipButton onClick={onDataPointClick} translate="yes">
        View this iteration
      </TooltipButton>
    </TooltipContainer>
  );
}

PulsePollAnswerDistribution.defaultProps = {
  isNPSQuestion: false,
};

export const NPSSummary = styled.div`
  align-self: flex-start;
  color: ${Palette.bluishGrey};
`;

const SectionContainer = styled.div<{ marginTop?: number }>`
  margin-top: ${props => getOr(props.marginTop, 32)}px;
`;

const ScoreDistributionContainer = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: center;
`;

const ScoreAndComparison = styled.div`
  display: flex;
  align-self: flex-start;
`;

const Comparison = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
`;

const DistributionContainer = styled.div`
  margin-left: 36px;
  flex: 1;
`;

const ChartHeader = styled.div`
  margin-top: 32px;
`;

const ChartSubHeader = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
`;

const TooltipContainer = styled.div`
  text-align: center;
  font-size: 12px;

  p {
    color: white;
    margin-bottom: 5px;
  }
`;

const TooltipButton = styled(Button)`
  height: 24px !important;
  background-color: inherit !important;
  color: #92a0b3 !important;
  font-size: 12px !important;
`;

const Divider = styled(AntDivider)`
  margin: 5px 0 !important;
`;

const ChartContainer = styled.div`
  margin: 32px 0;
`;
