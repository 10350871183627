import React from "react";
import styled from "styled-components";
import { Paragraph } from "@src/components/core/Typography";
import { VoiceCommentReportResource } from "hyphen-lib/dist/domain/resource/voice/VoiceCommentReportResource";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { getTypeLabel } from "../../../utils";
import { Trans } from "react-i18next";

interface PostAndGroupProps {
  readonly text: string;
  readonly group: VoiceCommentReportResource["group"];
}

export function PostAndGroup({ text, group }: PostAndGroupProps) {
  return (
    <Container>
      <Paragraph weight="light">{text}</Paragraph>
      <Paragraph weight="light">
        {isNotNullNorUndefined(group) && Object.keys(group).length > 0 ? (
          <>
            <span>{group.name}</span> • <span><Trans>{getTypeLabel(group.type)}</Trans></span>
          </>
        ) : (
          <Trans>{getTypeLabel("all")}</Trans>
        )}
      </Paragraph>
    </Container>
  );
}

const Container = styled.div`
  max-width: 350px;
`;

Container.displayName = "PostAndGroupContainer";
