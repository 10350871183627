import React from "react";
import { Layout, Collapse } from "antd";
import styled from "styled-components";
import { BasicProps } from "antd/lib/layout/layout";
import AreYouSureModal from "@src/components/core/AreYouSureModal";
import Input from "@src/components/core/Input";
import { MultiSelectFilter } from "@src/screens/Insights/components/Filters/base/MultiSelectFilter";
import Palette from "@src/config/theme/palette";
import { connect } from "react-redux";
import { State } from "@store/types";
import { isNotNullNorUndefined, mapOr, keys } from "hyphen-lib/dist/lang/Objects";
import _ from "lodash";
import { Trans, withTranslation } from "react-i18next";
import { not } from "hyphen-lib/dist/lang/Booleans";
import withDebounce from "@src/components/core/withDebounce";
import { fromJS, Map as ImmutableMap } from "immutable";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { isVoiceEnabled } from "hyphen-lib/dist/business/company/Companies";
import Button from "@src/components/core/Button";
import { Rights } from "hyphen-lib/dist/business/auth/Rights";
import { getCompany } from "@src/screens/Insights/store/selectors";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Dimensions, shouldExcludeDirectManager } from "hyphen-lib/dist/domain/common/Dimensions";
import NavigationPrompt from "react-router-navigation-prompt";
import { Location } from "history";
import { RouteComponentProps } from "react-router-dom";
import { PermissionsItem } from "../../components/PermissionsItem";
import arrowRight from "../../assets/arrow-right.png";
import AddRoleModal from "../../components/AddRoleModal";
import { getDependentRight, isRightEnabled } from "./utils/rightsDependencies";
import { rightsLabelMapper } from "./utils/rightsLabelMapper";
import { getRolesStateProps } from "./store/selectors";
import { UserRolesStateProps, CompanyRoleResourceMap } from "./store/reducers";
import { userRolesActionCreators } from "./store/actions";

// @ts-ignore
const DebouncedInput = withDebounce(Input);

const { Sider, Content, Header } = Layout;
const Panel = Collapse.Panel;

interface StateProps extends UserRolesStateProps {
  dimensions: Optional<Dimensions>;
  isVoiceModuleEnabled: Optional<boolean>;
  allowPrivateMessages: boolean;
}

export type UserRoleProps = typeof userRolesActionCreators & StateProps & Pick<RouteComponentProps, "location">;
interface UserRolesState {
  addRoleModalOpen: boolean;
  deleteRoleModalOpen: boolean;
  nextPath: string;
  dimensionsValue: any[];
  nameError: string;
  areYouSureModal : boolean;
  nextRole : CompanyRoleResourceMap;
}
export class UserRoles extends React.Component<UserRoleProps, UserRolesState> {
  state = {
    addRoleModalOpen: false,
    deleteRoleModalOpen: false,
    nextPath: "",
    dimensionsValue: [],
    nameError: "",
    areYouSureModal : false,
    nextRole : ImmutableMap({}) as CompanyRoleResourceMap,
  };

  componentDidMount() {
    this.props.getCompanyRoles();
  }

  componentDidUpdate(prevProps: UserRoleProps) {
    const { roles } = this.props;
    const { selectedRole } = prevProps;
    if (this.props.location !==  prevProps.location) {
      this.props.onRoleUpdated(false);
    }
    if (
      not(_.isEqual(prevProps.roles, roles)) &&
      isNotNullNorUndefined(roles.toJS()))
    {
      // set selected to first role if selected roles not there in the present roles
      if (roles.indexOf(selectedRole) === -1) {
        this.activateSide(roles.get(0)!);
      }
    }
  }

  activateSide = (role: CompanyRoleResourceMap) => {
    if (this.props.isRoleUpdated && !this.state.areYouSureModal) {
      this.setState({areYouSureModal: true, nextRole : role});
    } else {
      this.setState({ areYouSureModal: false}, () => {
        this.props.onRoleUpdated(false);
        this.props.selectRole(role);
      });
    }
  };
  toggleAddRoleModal = (addRoleModalOpen: boolean) => this.setState({addRoleModalOpen});
  toggleDeleteRoleModal = (deleteRoleModalOpen: boolean) => this.setState({deleteRoleModalOpen});

  doesRoleNameExist = (role: string): boolean => {
    const { roles } = this.props;
    let doesRoleNameExist = false;

    roles.forEach(existingRole => {
      if (existingRole.get("role") === role) {
        doesRoleNameExist = true;
      }
    });

    return doesRoleNameExist;
  };

  onNameChange = (value: string) => {
    if (value.length < 3) {
      return this.setState({ nameError: "Role Name must be more than 3 characters!" });
    }
    if (value.length > 30) {
      return this.setState({ nameError: "Role Name must be less than 30 characters!" });
    }
    if (this.doesRoleNameExist(value)) {
      return this.setState({ nameError: "Role already exists!" });
    }
    this.setState({ nameError: "" });
    this.updateSelectedRole("edit_name", value);
  };

  /**
   * This function will handle all the updating of roles
   *
   * @memberof UserRoles
   */
  updateSelectedRole = (
    key: "edit_right" | "edit_name" | "edit_roleDimFilters",
    value: any,
    checked?: boolean
  ) => {
    const { selectedRole, updateCompanySelectedRole } = this.props;
    switch (key) {
      // When trying to edit rights of a role.
      case "edit_right":
        return updateCompanySelectedRole({
          key: "rights",
          value: checked
          // When trying to add a right.
            ? selectedRole.get("rights").push(value)
          // When trying to remove a right
            : selectedRole.get("rights").filter(item => item !== value),
        });
      // When trying to update the role's name
      case "edit_name":
        return updateCompanySelectedRole({ key: "role", value });
      case "edit_roleDimFilters":
        return updateCompanySelectedRole({ key: "dimensionFilters", value: fromJS(value) });
      default:
        break;
    }
  };

  renderRights = (
    arr: { title: string; description?: string; right: string }[],
    options?: Dictionary<any>
  ) => arr.map(right => (
    <PermissionsItem
      key={right.right}
      title={right.title}
      dependentRight={getDependentRight(this.props.selectedRole, options)}
      description={right.description}
      isRightEnabled={isRightEnabled(right.right, this.props.selectedRole, options)}
      onRightsModify={this.updateSelectedRole.bind(null, "edit_right", right.right)}
    />
  ));

  onAddRole = (role: string) => {
    this.props.addOrRemoveRoles({
      role,
      action: "add",
    });
  };

  shouldShowNavigationPrompt = (crntLocation: Location<any>, nextLocation: Optional<Location<any>>) => {
    return (!nextLocation || !nextLocation.pathname.startsWith(crntLocation.pathname)) &&
    (crntLocation.pathname === "/users/roles") && (this.props.isRoleUpdated || this.state.areYouSureModal) ;
  };

  getAreYouSureModal = (isActive: boolean, onCancel: () => void, onConfirm: () => void) => {
    return (
      <AreYouSureModal
        visible={isActive}
        title="Are you sure?"
        /* eslint-disable-next-line max-len */
        description="You have unsaved changes. Click Yes to discard changes and continue navigation or Cancel to stay on this page"
        okLabel="Yes"
        cancelLabel="Cancel"
        onCancel={onCancel}
        onOk={onConfirm}
      />
    );
  };

  onCancel = () => {
    this.setState({areYouSureModal : false, addRoleModalOpen : false});
  };

  onOk = () => {
    if (this.props.isRoleUpdated && this.state.addRoleModalOpen) {
      this.props.onModalVisibilityToggle({
        modalType : "isAddModalVisible",
        isModalVisible : true,
      });
    }
    this.activateSide(this.state.nextRole);
  };

  onAddRoleClick = () => {
    if (this.props.isRoleUpdated) {
      this.setState({areYouSureModal : true},() => this.toggleAddRoleModal(true));
    } else {
      this.props.onModalVisibilityToggle({
        modalType : "isAddModalVisible",
        isModalVisible : true,
      });
    }
  };

  render () {
    const {
      selectedRole,
      roles,
      isAddModalVisible,
      isRemoveModalVisible,
      onModalVisibilityToggle,
      isNetworkRequesting,
      isRoleUpdated,
      dimensions,
      isVoiceModuleEnabled,
      allowPrivateMessages
    } = this.props;
    
    const companyDimensions =  isNotNullNorUndefined(dimensions)
      ? keys(dimensions).filter(dimension => shouldExcludeDirectManager(dimension)).map(dimension => ({
        value: dimension,
        label: dimensions[dimension].label,
      })).toArray()
      : []
    ;

    const { nameError } = this.state;
    const dimensionFilters = isNotNullNorUndefined(selectedRole.get("dimensionFilters"))
      // @ts-ignore
      ? selectedRole.get("dimensionFilters").toArray()
      : [];

    const activeSideItem = (role: CompanyRoleResourceMap) =>
      <ActiveSideItem
        key={role.get("role")}
        onClick={this.activateSide.bind(null, role)}
      >
        <RightArrow src={arrowRight} />
        <div>
          <ActiveSideTitle
            data-cy={role.get("role")}
            data-jest={role.get("role")}
            className="active">
            {role.get("role")}
          </ActiveSideTitle>
          <ActiveSideNumber>{role.get("userCount")} <Trans>people</Trans></ActiveSideNumber>
        </div>
      </ActiveSideItem>;

    const sideItem = (role: CompanyRoleResourceMap) =>
      <SideItem key={role.get("role")}  data-jest={role.get("role")} onClick={this.activateSide.bind(null, role)}>
        <SideTitle data-cy={role.get("role")}>{role.get("role")}</SideTitle>
        <div>{role.get("userCount")} <Trans>people</Trans></div>
      </SideItem>;

    return (
      <Layout>
        <CustomHeader>
          <SaveButton
            data-cy="permissionSaveButton"
            color={isRoleUpdated ? "gradation" : undefined}
            disabled={not(isRoleUpdated) || isNetworkRequesting}
            onClick={this.props.saveCompanyRole}
            translate="yes"
          >
            {isNetworkRequesting ? "Saving..." : "Save changes"}
          </SaveButton>
          <Button
            color="blue"
            data-cy="addRole"
            onClick={this.onAddRoleClick}
            translate="yes"
          >
            Add Role
          </Button>
        </CustomHeader>
        <NavigationPrompt
          when={this.shouldShowNavigationPrompt}>
          {({ isActive, onCancel, onConfirm }) => (
            this.getAreYouSureModal(isActive, onCancel, onConfirm)
          )}
        </NavigationPrompt>
        {this.getAreYouSureModal(this.state.areYouSureModal,this.onCancel,this.onOk)}
        <Layout>
          <CustomSider>
            {roles.map(role => {
              if (role.get("role") === selectedRole.get("role")) {
                return activeSideItem(role);
              }
              return sideItem(role);
            })}
          </CustomSider>
          <CustomContent>
            <Collapse>
              <CustomPanel header={<Trans>General</Trans>} key="1">
                <SubsectionTitleFirst><Trans>Basic Settings</Trans></SubsectionTitleFirst>
                <PermissionInputContainer>
                  <DebouncedInput
                    label="Role name"
                    value={selectedRole.get("role")}
                    onValueChange={this.onNameChange}
                    // @ts-ignore
                    error={nameError && <Trans>{nameError}</Trans>}
                    disabled={true}
                  />
                </PermissionInputContainer>
                <SubsectionTitle><Trans>Engage Insights</Trans></SubsectionTitle>
                {this.renderRights(rightsLabelMapper.general.insights.slice(0, 1))}
                <PermissionInputContainer>
                  <MultiSelectFilter
                    className="width100"
                    value={dimensionFilters}
                    label="Viewable dimensions"
                    availableValues={companyDimensions}
                    onChange={this.updateSelectedRole.bind(null, "edit_roleDimFilters")}
                    onClear={this.updateSelectedRole.bind(null, "edit_roleDimFilters", [])}
                  />
                </PermissionInputContainer>
                {this.renderRights(
                  rightsLabelMapper.general.insights.slice(1, rightsLabelMapper.general.insights.length),
                  {
                    dependentRight: Rights.Insights.ACCESS,
                  }
                )}
                <SubsectionTitle><Trans>Admin</Trans></SubsectionTitle>
                {this.renderRights([rightsLabelMapper.general.admin.all], {
                  dependentRight: Rights.Insights.ACCESS,
                })}
                <SubsectionTitle2><Trans>User management</Trans></SubsectionTitle2>
                <NestedPermissions level={1}>
                  {this.renderRights(
                    rightsLabelMapper.general.admin.userManagement,
                    {
                      all: rightsLabelMapper.general.admin.all.right,
                      dependentRight: Rights.Insights.ACCESS,
                    }
                  )}
                </NestedPermissions>
              </CustomPanel>
              <CustomPanel header={<Trans>Surveys</Trans>} key="2">
                <SubsectionTitle><Trans>Engage Insights</Trans></SubsectionTitle>
                <SubsectionTitle><Trans>Access to screen</Trans></SubsectionTitle>
                <NestedPermissions level={1}>
                  {this.renderRights(
                    rightsLabelMapper.surveys.insights.surveys,
                    {dependentRight: Rights.Insights.ACCESS}
                  )}
                </NestedPermissions>
                <NestedPermissions level={1}>
                  {this.renderRights(
                    [rightsLabelMapper.surveys.insights.surveyReports.all],
                    {
                      dependentRight: Rights.Insights.ACCESS,
                    }
                  )}
                  {this.renderRights(
                    rightsLabelMapper.surveys.insights.surveyReports.rights,
                    {
                      all: [
                        rightsLabelMapper.surveys.insights.surveyReports.all.right,
                      ],
                      dependentRight: Rights.Insights.ACCESS,
                    }
                  )}
                </NestedPermissions>

                <SubsectionTitle><Trans>Administer Surveys</Trans></SubsectionTitle>
                {this.renderRights(
                  rightsLabelMapper.surveys.admin.rights,
                  {
                    dependentRight: Rights.Insights.ACCESS,
                  }
                )}

                <SubsectionTitle><Trans>Global Accesses</Trans></SubsectionTitle>
                <NestedPermissions level={1}>
                  {this.renderRights([rightsLabelMapper.surveys.insights.all], {
                    dependentRight: Rights.Insights.ACCESS,
                  })}
                  {allowPrivateMessages && this.renderRights(rightsLabelMapper.surveys.insights.messages,
                    {
                      dependentRight: Rights.Insights.ACCESS,
                    }
                  )}
                  {this.renderRights([rightsLabelMapper.surveys.admin.all], {
                    dependentRight: Rights.Insights.ACCESS,
                  })}
                </NestedPermissions>
              </CustomPanel>
              <CustomPanel header={<Trans>Polls</Trans>} key="3">
                <SubsectionTitle><Trans>Employee apps (web and mobile)</Trans></SubsectionTitle>
                {this.renderRights(rightsLabelMapper.pulsePolls.employeeApps, { allowPrivateMessages })}
                <SubsectionTitle><Trans>Engage Insights</Trans></SubsectionTitle>
                <SubsectionTitle><Trans>Access to screen</Trans></SubsectionTitle>
                <NestedPermissions level={1}>
                  {this.renderRights(
                    rightsLabelMapper.pulsePolls.insights.pulsePolls,
                    {
                      dependentRight: Rights.Insights.ACCESS,
                    }
                  )}
                  {this.renderRights(
                    [rightsLabelMapper.pulsePolls.insights.pulsePollReports.all],
                    {
                      dependentRight: Rights.Insights.ACCESS,
                    }
                  )}
                  {this.renderRights(
                    rightsLabelMapper.pulsePolls.insights.pulsePollReports.rights,
                    {
                      all: [
                        rightsLabelMapper.pulsePolls.insights.pulsePollReports.all.right,
                      ],
                      dependentRight: Rights.Insights.ACCESS,
                    }
                  )}
                </NestedPermissions>

                <SubsectionTitle><Trans>Administer Polls</Trans></SubsectionTitle>

                {this.renderRights(
                  rightsLabelMapper.pulsePolls.admin.rights,
                  {
                    dependentRight: Rights.Insights.ACCESS,
                  }
                )}
                <SubsectionTitle><Trans>Global Accesses</Trans></SubsectionTitle>
                <NestedPermissions level={1}>
                  {this.renderRights([rightsLabelMapper.pulsePolls.insights.all], {
                    dependentRight: Rights.Insights.ACCESS,
                  })}
                  {allowPrivateMessages && this.renderRights(rightsLabelMapper.pulsePolls.insights.messages,
                    {
                      dependentRight: Rights.Insights.ACCESS,
                    }
                  )}
                  {this.renderRights([rightsLabelMapper.pulsePolls.admin.all], {
                    dependentRight: Rights.Insights.ACCESS,
                  })}
                </NestedPermissions>
              </CustomPanel>
              <CustomPanel header={<Trans>Action Plans</Trans>} key="4">
                <SubsectionTitle><Trans>Engage Insights</Trans></SubsectionTitle>
                {this.renderRights([rightsLabelMapper.actionPlans.insights.all], {
                  dependentRight: Rights.Insights.ACCESS,
                })}
                {this.renderRights(
                  rightsLabelMapper.actionPlans.insights.rights,
                  {
                    all: rightsLabelMapper.actionPlans.insights.all.right,
                    dependentRight: Rights.Insights.ACCESS,
                  }
                )}
              </CustomPanel>

              {isVoiceModuleEnabled ? <CustomPanel header={<Trans>Voice</Trans>} key="5">
                <SubsectionTitle>
                  <Trans>Employee apps (web and mobile)</Trans>
                </SubsectionTitle>

                <NestedPermissions level={1}>
                  {this.renderRights([rightsLabelMapper.voice.employeeApps.all])}
                </NestedPermissions>
                <NestedPermissions level={2}>
                  {this.renderRights(rightsLabelMapper.voice.employeeApps.posts.rights, {
                    all: rightsLabelMapper.voice.employeeApps.all.right,
                  })}
                </NestedPermissions>

                <NestedPermissions level={1}>
                  <SubsectionTitle>
                    <Trans>Groups</Trans>
                  </SubsectionTitle>
                </NestedPermissions>
                <NestedPermissions level={2}>
                  {this.renderRights(rightsLabelMapper.voice.employeeApps.groups.rights, {
                    all: rightsLabelMapper.voice.employeeApps.all.right,
                  })}
                </NestedPermissions>

                <NestedPermissions level={1}>
                  <SubsectionTitle>
                    <Trans>Channels</Trans>
                  </SubsectionTitle>
                </NestedPermissions>
                <NestedPermissions level={2}>
                  {this.renderRights(rightsLabelMapper.voice.employeeApps.channels.rights, {
                    all: rightsLabelMapper.voice.employeeApps.all.right,
                  })}
                </NestedPermissions>

                <SubsectionTitle>
                  <Trans>Engage Insights</Trans>
                </SubsectionTitle>
                <NestedPermissions level={1}>
                  {this.renderRights([rightsLabelMapper.voice.insights.voiceReports.all], {
                    dependentRight: Rights.Insights.ACCESS,
                  })}
                </NestedPermissions>
                <NestedPermissions level={2}>
                  {this.renderRights(rightsLabelMapper.voice.insights.voiceReports.rights, {
                    all: rightsLabelMapper.voice.insights.voiceReports.all.right,
                    dependentRight: Rights.Insights.ACCESS,
                  })}
                </NestedPermissions>
              </CustomPanel> : null }
              <CustomPanel header={<Trans>Lifecycle</Trans>} key="6">
                <SubsectionTitle>
                  <Trans>Engage Insights</Trans>
                </SubsectionTitle>
                <NestedPermissions level={1}>
                  {this.renderRights([rightsLabelMapper.lifecycle.insights.lifecycleReports.all], {
                    dependentRight: Rights.Insights.ACCESS,
                  })}
                </NestedPermissions>
              </CustomPanel>
            </Collapse>
            <DeleteRoleButtonContainer>
              <Button
                color="grey"
                onClick={onModalVisibilityToggle.bind(null, {
                  modalType: "isRemoveModalVisible",
                  isModalVisible: true,
                })}
                translate="yes"
              >
                Delete Role
              </Button>
            </DeleteRoleButtonContainer>
          </CustomContent>
        </Layout>
        {isAddModalVisible &&
          <AddRoleModal
            // @ts-ignore
            visible={isAddModalVisible}
            onSubmit={this.onAddRole}
            doesRoleNameExist={this.doesRoleNameExist}
            onCancel={onModalVisibilityToggle.bind(null, {
              modalType: "isAddModalVisible",
              isModalVisible: false,
            })}
            isCreatingRole={isNetworkRequesting}
          />
        }
        {isRemoveModalVisible &&
          <AreYouSureModal
            visible={isRemoveModalVisible}
            onCancel={onModalVisibilityToggle.bind(null, {
              modalType: "isRemoveModalVisible",
              isModalVisible: false,
            })}
            title="Are you sure?"
            /* eslint-disable-next-line max-len */
            description="Are you sure you want to delete this role? Deleting the role will permanently remove this role from any users it is currently assigned to. This cannot be undone."
            okLabel={isNetworkRequesting ? "Deleting..." : "Delete" }
            buttonsDisabled={isNetworkRequesting}
            onOk={this.props.addOrRemoveRoles.bind(null, {
              role: selectedRole.get("role"),
              action: "remove",
            })}
          />
        }
      </Layout>
    );
  }
}

function mapStateToProps (state: State): StateProps {
  const {
    roles,
    selectedRole,
    isAddModalVisible,
    isRemoveModalVisible,
    isNetworkRequesting,
    isRoleUpdated,
  } = getRolesStateProps(state);
  const company = getCompany(state);
  const dimensions = mapOr(
    company,
    company => company.dimensions,
    {}
  );
  const isVoiceModuleEnabled = isVoiceEnabled(company!);
  const allowPrivateMessages = isNotNullNorUndefined(company) && company.allowPrivateMessages;
  return {
    roles,
    selectedRole,
    isAddModalVisible,
    isRemoveModalVisible,
    isNetworkRequesting,
    isRoleUpdated,
    dimensions,
    isVoiceModuleEnabled,
    allowPrivateMessages
  };
}

export default connect(mapStateToProps, userRolesActionCreators)(withTranslation()(UserRoles));

const borderColor = "#E2E6EA";

const borderTopStyle = `
  border-top: ${borderColor} solid 1px;
`;

const flexVerticalCenter = `
  display: flex;
  align-items: center;
`;

const permissionPaddingRightStyle = `
  padding-right: 10px;
`;

const permissionContainerStyle = `
  height: 52px;
  ${permissionPaddingRightStyle}
`;

const subsectionTitleBasicStyle = `
  font-weight: bold;
  font-size: 15px;
  ${permissionContainerStyle}
  ${flexVerticalCenter}
`;

const subsectionTitleStyle = `
  ${subsectionTitleBasicStyle}
  padding-left: 15px;
`;

const subsectionTitle2Style = `
  ${subsectionTitleBasicStyle}
  padding-left: 25px;
`;

const NestedPermissions = styled.div<{ level: number }>`
  padding-left: ${({ level }) => `${10 * level}px`};
`;

const SideItem = styled.div`
  padding: 17px 0;
  cursor: pointer;
  width: 160px;
`;

const ActiveSideItem = styled.div`
  display: flex;
  padding: 17px 0;
  cursor: pointer;
  width: 160px;
`;

const SideTitle = styled.div`
  font-weight: bold;
`;

const ActiveSideTitle = styled.div`
  color: ${Palette.darkModerateBlue};
  font-weight: bold;
`;

const RightArrow = styled.img`
  align-self: center;
  margin-right: 8px;
`;

const ActiveSideNumber = styled.div`
  color: ${Palette.darkModerateBlue};
`;

const SaveButton = styled(Button)`
  margin-right: 16px;
`;

const CustomHeader = styled(Header)<BasicProps>`
  background-color: ${Palette.paleGrey} !important;
  ${flexVerticalCenter}
  justify-content: flex-end;
  padding-right: 20px !important;
`;

const CustomSider = styled(Sider)<BasicProps>`
  background-color: ${Palette.paleGrey} !important;
`;

const CustomContent = styled(Content)<BasicProps>`
  background-color: ${Palette.paleGrey};
  padding: 10px 20px;
`;

const CustomPanel = styled(Panel)<BasicProps>`
  .ant-collapse-header {
    padding: 20px 0 20px 45px !important;
    font-weight: bold;
    font-size: 16px;
  }
  .ant-collapse-content-box {
    padding: 0 !important;
  }
`;

const DeleteRoleButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 17px;
`;

const SubsectionTitle = styled.div`
  ${subsectionTitleStyle}
  ${borderTopStyle}
`;

const SubsectionTitleFirst = styled.div`
  ${subsectionTitleStyle}
`;

const SubsectionTitle2 = styled.div`
  ${subsectionTitle2Style}
  ${borderTopStyle}
`;

const PermissionInputContainer = styled.div`
  height: 77px;
  ${permissionPaddingRightStyle}
  ${borderTopStyle}
  padding-left: 25px;
  ${flexVerticalCenter}
  > div {
    width: 100% !important;
  }
`;
