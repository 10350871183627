import React from "react";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import FileUpload from "@src/components/core/FileUpload/FileUpload";
import Palette from "@src/config/theme/palette";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Trans } from "react-i18next";
// eslint-disable-next-line max-len
import { BatarangScriptDefinitionsResource } from "hyphen-lib/dist/domain/resource/batarang/BatarangScriptDefinitionsResource";
import {
  BatarangScriptsActionTypes
} from "@screens/Insights/Settings/containers/batarang/store/batarangScriptsActions";
import styled from "styled-components";
import { BatarangParameterProps } from "./BatarangParameters";
import Tooltip from "@src/components/core/Tooltip";
import { connect, MapStateToProps } from "react-redux";
import { State } from "@store/types";
import { AssetUpload } from "../../containers/batarang/store/batarangScriptsReducers";
import {
  getAssetUpload
} from "@screens/Insights/Settings/containers/batarang/store/batarangScriptsSelectors";
interface FileState {
  fileList: File[];
};
interface BatarangParameterStateProps extends BatarangParameterProps{
  assetUpload?: Optional<AssetUpload>;
}
class ParameterAssetSelect extends React.Component<BatarangParameterStateProps, FileState> {

  inputElementRef: React.RefObject<HTMLInputElement> = React.createRef();

  constructor(props:BatarangParameterStateProps) {
    super(props);
    this.state = {
      fileList : [] 
    };
  }

  componentDidUpdate(prevProps: BatarangParameterStateProps){
    if(this.props.assetUpload && prevProps.assetUpload) {
      const { assetUpload } = this.props;
      if(prevProps.assetUpload.uploadStatus !== assetUpload.uploadStatus &&
           assetUpload.fileName === "" && 
           assetUpload.uploadStatus === BatarangScriptsActionTypes.UPLOAD_ASSET_PROGRESS) {
        this.removeFile();
      }
    }
  }

  validateFile = (e: any) => {
    const files = e.target.files;
    const { onAssetUpload } = this.props;
    if (files.length > 0) {
      const file = files[0];
      this.setState({
        fileList: Array.from(files)
      });
      onAssetUpload(file);
    }
  };

  removeFile = (i?: number) => {
    this.setState({
      fileList: i ? this.state.fileList.filter((each, idx) => idx !== i ) : []
    }, () => {
      if (isNotNullNorUndefined(this.inputElementRef.current)) {
        this.inputElementRef.current.value = "";
      }
    });
  };


  render() {
    const { parameter } = this.props;
    const uploadButtonText = extractUploadButtonText(parameter);
    const { fileList } = this.state;
    const disabled = Boolean(this.props.disabled);
    return (
      <FileUploadContainer>
        <FileUpload
          disabled={disabled}
          validateFile={this.validateFile}
          ref={this.inputElementRef}
          accept={".csv"}
          uploadButtonText={uploadButtonText}
        />
        {fileList.length ? 
          fileList.map((each, i)=>
            <div key={i} className="font-weight-bold">{ each.name } 
              <Tooltip title={<Trans>Remove</Trans>}>
                <RemoveButton disabled={disabled} onClick={() => !disabled ?  
                    this.removeFile(i) : null}>x</RemoveButton>
              </Tooltip>
            </div>
          ) : null}
      </FileUploadContainer>
    );
  }
}

const mapStateToProps: MapStateToProps<
  BatarangParameterStateProps,
  BatarangParameterProps,
  State
> = (state: State, ownProps: BatarangParameterProps): BatarangParameterStateProps => {
  return {
    assetUpload: getAssetUpload(state),
    ...ownProps
  };
};

export default connect(mapStateToProps)(ParameterAssetSelect);

function extractUploadButtonText(parameter: BatarangScriptDefinitionsResource.Script.Parameter) {
  if (parameter.key === "assetPrompt") {
    return parameter.label;
  }
  return "Upload File";
}

const FileUploadContainer = styled.div`
  margin-top: 16px;
`;

const RemoveButton = styled.span<{disabled: boolean}>`
  color: ${Palette.darkPink};
  cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
  margin-left: 5px;
  font-size: 20px;
`;