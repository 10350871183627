import React, { Component } from "react";

import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { Trans } from "react-i18next";

interface InfoItemProps {
  readonly label: string;
}

export class InfoItem extends Component<InfoItemProps> {
  render() {
    return  (
      <Item>
        <InfoLabel data-cy={`infoItem_label-${this.props.label}`}>
          <Trans>{this.props.label}</Trans>
        </InfoLabel>
        <InfoValue data-cy={`infoItem_value-${this.props.label}`}>
          {this.props.children}
        </InfoValue>
      </Item>
    );
  }
}

const Item = styled.div`
  display: block;
  padding: 8px 0;
`;

const InfoLabel = styled.div`
  display: inline-block;
  width: 35%;
  color: ${Palette.bluishGrey};
  vertical-align: top;
`;

const InfoValue = styled.div`
  display: inline-block;
  width: 65%;
  color: ${Palette.veryDarkBlueGrey};
`;
