import React from "react";
import styled from "styled-components";
import { Layout as AntLayout } from "antd";
import { Alert } from "antdv4";
import { CommentsOverviewResource } from "@hyphen-lib/domain/resource/report/CommentsOverviewResource";
import { CommentsResultResource } from "@hyphen-lib/domain/resource/report/CommentsResultResource";

import CommentsTable from "@src/screens/Insights/components/Reports/ResultsTable";
import SurveyReportHeader from "@screens/Insights/Survey/components/SurveyReportHeader";
import qs from "qs";
import SentimentOverview from "@src/screens/Insights/components/Reports/SentimentOverview";
import { NetworkEventSuccessAction } from "@src/store/network/actions";
import { Participation } from "hyphen-lib/dist/domain/common/Participation";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { Loading, LoadingContainer } from "@screens/Insights/Survey/components/Loading";
import SendPrivateMessageModal from "../../../components/Reports/SendPrivateMessage/index";
import OpenQuestionSearchBar from "./components/OpenQuestionSearchBar/index";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { SurveyTopicCommentResource } from "hyphen-lib/dist/domain/resource/survey/report/SurveyTopicCommentResource";
import SurveyTopicsChart, { ChartWrapper } from "../Topics/SurveyTopicsChart";
import Spin from "@src/components/core/Spin";
import { getOr, isNotNullNorUndefined, isNullOrUndefined, keys } from "hyphen-lib/dist/lang/Objects";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { Trans } from "react-i18next";
import { AbsoluteSearchBarContainer, CommonWordsContainer, HelpTextContainer, 
  StyledTabs, SuggestionsTitle } from "../Comments";
import CommonWords from "../CommonWords";
import { fromJS } from "immutable";
import { replaceTo } from "@src/utils/locations";
import { appendQueryString, generateQueryString, parseQueryString, } from "hyphen-lib/dist/util/net/HttpClient";
import { RouteComponentProps, withRouter } from "react-router-dom";
import  TopicSegment  from "../../containers/TopicCommentsHighlightReportContainer/components/TopicSegment";
import { SelectValue } from "antd/lib/select";
import { replaceLocation } from "@src/utils/locations";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { FilterParameter, SortParameter } from "@src/utils/networks";
import {  SurveyTopicSegmentResource } from "hyphen-lib/dist/domain/resource/survey/report/SurveyTopicSegmentResource";
import Tabs from "@src/components/core/Tabs";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { filterEnglishCodes } from "@src/utils/translation";

interface OpenQuestionProps {
  readonly participation: Participation;
  readonly enabledFilters: string[];
  readonly topicsSegment?: Loadable<SurveyTopicSegmentResource>;
  readonly enabledCustomFilters?: string[];
  readonly commentsOverview: Loadable<CommentsOverviewResource>;
  readonly comments: Store.Page<CommentsResultResource>;
  readonly focusAreas?: FocusAreaResource[];
  readonly onCreateFocusArea: (focusArea: Partial<FocusAreaResource>) => void;
  readonly topicsOverview?: Loadable<SurveyTopicCommentResource>;
  readonly surveyName: string;
  readonly page: PageFilter;
  readonly sort?: SortParameter;
  readonly filter: FilterParameter;
  readonly topicAnalysEnabled?: boolean;
  readonly surveySuggestionsEnabled?: boolean;
  readonly isSentimentAnalysisQuestion?: boolean;
  readonly surveyLanguages?: Optional<string[]>;
}

export interface SurveyCommentsReportStateProps {
  readonly commentText: string;
  readonly errorText: string;
  readonly title?: string;
  readonly isNetworkRequesting: boolean;
  readonly isPrivateMessageModalVisible: boolean;
  readonly postId: string;
  readonly privateMessageText: string;
  readonly surveyId: string;
  readonly voteId: string;
  readonly canSendPrivateMessage: boolean;
  readonly hasActionCreationRight: boolean;
  readonly anonymityThreshold: number;
  readonly allowComments?: boolean;
  readonly singleFieldFilter?: string;
}

const { TabPane } = Tabs;
export interface SurveyCommentsReportActionProps {
  readonly clearPrivateMessageModal: () => any;
  readonly sendPrivateMessage: (
    surveyId: string,
    postId: string,
    voteId: string,
    initialMessage: string,
    onSuccessRedirect?: (payload: NetworkEventSuccessAction["payload"]) => void
  ) => void;
  readonly togglePrivateMessageModal: (
    surveyId: string,
    postId: string,
    voteId: string,
    commentText: string
  ) => void;
  readonly updatePrivateMessageText: (privateMessageText: string) => any;
}

export type Props = SurveyCommentsReportStateProps &
  OpenQuestionProps &
  SurveyCommentsReportActionProps &
  RouteComponentProps;
interface MessageModalState {
  isPrivateMessageModalOpen: boolean;
  selectedTab: string;
}

/*
    fixme: BEFORE LAUNCH!!!
    fixme: BEFORE LAUNCH!!!
    fixme: BEFORE LAUNCH!!!
    fixme: BEFORE LAUNCH!!!
    fixme: BEFORE LAUNCH!!!
    fixme: BEFORE LAUNCH!!!

    This is not an open question report, but some generic drilldown to display comments,
    this is used for open questions, category's comments and 'private' question's comment
 */
// const CommentsTableWithRouterProps = withRouter(CommentsTable);
class OpenQuestionHighlightReport extends React.Component<Props, MessageModalState> {
  state = {
    isPrivateMessageModalOpen: false,
    selectedTab: "comments"
  };

  UNSAFE_componentWillMount(): void {
    const {
      location: { pathname }
    } = this.props;
    if(pathname.indexOf("/suggestions") > -1) {
      this.setState({
        selectedTab: "comments/suggestions"
      });
    }   
  }

  sendPrivateMessage = () => {
    this.props.sendPrivateMessage(
      this.props.surveyId,           // Survey Id
      this.props.postId,             // Question Id
      this.props.voteId,             // Comment Id
      this.props.privateMessageText  // Text Message
    );
  };

  onTabChange = (activeKey: string) => {
    const {
      location: { search, pathname }
    } = this.props;
    const queryParams: any = qs.parse(search, { ignoreQueryPrefix: true });
    const pathArr = pathname.split("/");
    if(activeKey === "comments") {
      pathArr.pop();
    }
    pathArr.splice(pathArr.length - 1, 1, activeKey);
    const newPath = pathArr.join("/");
    replaceTo(appendQueryString(newPath, generateQueryString(queryParams)));
    this.setState({
      selectedTab: activeKey
    }, () => {
      setTimeout(()=>{
        document.getElementById("comments-tab")?.scrollIntoView();
      }, 0);
    });
  };

  onWordClick = (word: string, sentiment: string) => {
    const param = {
      filter: {
        topics: [word],
      },
    };
    const {
      location: { search, pathname }
    } = this.props;
    const tempQueryParams: any = qs.parse(search, { ignoreQueryPrefix: true });

    if (
      isNotNullNorUndefined(tempQueryParams.filter) &&
      isNotNullNorUndefined(tempQueryParams.filter.topics)
    ) {
      delete tempQueryParams.filter.topics;
    }
    const queryParams = fromJS(tempQueryParams).mergeDeep(param).toJS();
    const pathArr = pathname.split("/");
    pathArr.splice(pathArr.length - 1, 1, "comments");
    const newPath = pathArr.join("/");
    replaceTo(appendQueryString(newPath, generateQueryString(queryParams)));
  };

  updateSegmentByInPath = (selectedDimension: SelectValue): void => {
    const { location } = this.props;
    const parameters = parseQueryString(location.search);
    parameters.segmentBy = selectedDimension;

    replaceLocation(parameters); // apply the new query string
  };

  showSegment = (
    topic: SurveyTopicSegmentResource,
    commentSentiments: CommentsOverviewResource.Sentiments
  ) => {
    /* eslint-disable max-len */
    const commentsInTotal = Object.values(commentSentiments).reduce((total: number, commentSentiment: number) => total + commentSentiment, 0);

    if (commentsInTotal === 0) {
      return null;
    }
    const { location, title, anonymityThreshold, surveyId, filter } = this.props;


    const { segments } = topic;
    if (isNullOrUndefined(segments)) {
      return null;
    }

    let { segmentBy: selectedDimension } = parseQueryString(location.search);
    if (isNullOrUndefined(selectedDimension)) {
      selectedDimension = keys(segments).first("");
    }

    const availableDimensions = Object.keys(getOr(segments, {}));
    const dimension = segments[selectedDimension.toString()];
    return (
      <SegmentContainer>
        <TopicSegment
          title={title}
          availableDimensions={availableDimensions}
          dimensions={segments}
          selectedDimension={selectedDimension}
          updateSegmentByInPath={this.updateSegmentByInPath}
          dimension={dimension}
          anonymityThreshold={anonymityThreshold}
          filter={filter}
          surveyId={surveyId}
          />
      </SegmentContainer>);
  };

  render() {
    const {
      clearPrivateMessageModal,
      comments,
      commentText,
      commentsOverview,
      enabledFilters,
      enabledCustomFilters,
      errorText,
      isNetworkRequesting,
      isPrivateMessageModalVisible,
      participation,
      privateMessageText,
      togglePrivateMessageModal,
      updatePrivateMessageText,
      canSendPrivateMessage,
      hasActionCreationRight,
      anonymityThreshold,
      focusAreas,
      onCreateFocusArea,
      surveyName,
      topicsOverview,
      topicsSegment,
      match,
      page,
      sort,
      allowComments,
      topicAnalysEnabled,
      surveySuggestionsEnabled,
      singleFieldFilter,
      isSentimentAnalysisQuestion,
      surveyLanguages,
      surveyId,
      title
    } = this.props;

    const params = this.props.match.params as {questionId: string ; id: string; categoryId: string};

    const { selectedTab } = this.state;

    if (Loadable.isNotLoaded(commentsOverview)) {
      // fixme we need to simplify that, with a header and a content which is loading or loaded :/
      return (
        <>
          <SurveyReportHeader
            participation={participation}
            enabledFilters={enabledFilters}
            enabledCustomFilters={enabledCustomFilters}
            singleFieldFilter={singleFieldFilter}
            exportOption="comments"
          />
          <Loading />
        </>
      );
    }

    let enableDefaultTranslate = false;
    if(isNotEmptyArray(surveyLanguages) && filterEnglishCodes(surveyLanguages).length) {
      enableDefaultTranslate = true;
    }

    return (
      <>
        <SurveyReportHeader
          participation={participation}
          enabledFilters={enabledFilters}
          enabledCustomFilters={enabledCustomFilters}
          exportOption="comments"
          singleFieldFilter="category"
        />

        {getOr(isSentimentAnalysisQuestion, true) && <SentimentOverview
          netSentimentScore={commentsOverview.value.netSentimentScore}
          filteredForAnonymity={commentsOverview.value.filteredForAnonymity}
          filteredForAnonymityReason={
            commentsOverview.value.filteredForAnonymityReason
          }
          totalCategories={commentsOverview.value.totalCategories}
          totalPosts={commentsOverview.value.totalPosts}
          totalVotes={commentsOverview.value.totalVotes}
          sentiments={commentsOverview.value.sentiments}
          words={[]}
          numberOfComments={commentsOverview.value.numberOfComments}
          anonymityThreshold={anonymityThreshold}
        />}
        { topicAnalysEnabled ? 
          (isNotNullNorUndefined(topicsSegment) && (Loadable.isNotLoaded(topicsSegment) ?
            <Loading /> :
            this.showSegment(topicsSegment.value, commentsOverview.value.sentiments))) : null
        }

        { topicAnalysEnabled ? 
          (isNotNullNorUndefined(topicsOverview) &&
            (Loadable.isInError(topicsOverview) ||
              (isNotNullNorUndefined(allowComments) && !allowComments) ||
              commentsOverview.value.numberOfComments === 0
              ? null :
              (Loadable.isNotLoaded(topicsOverview) ?
                <ChartWrapper>
                  <LoadingContainer><Spin /></LoadingContainer>
                </ChartWrapper> :
                (getOr(isSentimentAnalysisQuestion, true) && <SurveyTopicsChart
                  numberOfComments={topicsOverview.value.totalAvailableComments}
                  title={"Top topics in this question"}
                  topics={topicsOverview.value.topics}
                  infoMessage={"These are the top topics identified for this question."}
                  surveyId={surveyId}
                  sectionName={getOr(title, "")}
                  questionId={params.questionId}
                  categoryId={params.categoryId}
                />)))
            ) : null
        }
        <Layout id="comments-tab">
          <StyledTabs defaultActiveKey={selectedTab} onChange={this.onTabChange}>
            <TabPane tab={<Trans>All comments</Trans>} key="comments">
                {topicAnalysEnabled && Loadable.isLoaded(commentsOverview) && isNotEmptyArray(commentsOverview.value.topics)
              && !match.path.includes("reports/topics/") && getOr(isSentimentAnalysisQuestion, true) && (
                <CommonWordsContainer>
                  <HelpTextContainer>
                    <Trans>Click on the topics to filter the list below.</Trans>
                  </HelpTextContainer>
                  <CommonWords words={commentsOverview.value.topics} onWordClick={this.onWordClick} />
                </CommonWordsContainer>
              )}
              <CommentsTable
                comments={Store.Page.toList(comments)}
                loading={Store.Page.isLoading(comments)}
                isPrivateMessageModalOpen={isPrivateMessageModalVisible}
                privateMessageText={privateMessageText}
                sendPrivateMessage={this.sendPrivateMessage}
                togglePrivateMessageModal={togglePrivateMessageModal}
                updatePrivateMessageText={updatePrivateMessageText}
                canSendPrivateMessage={canSendPrivateMessage}
                hasActionCreationRight={hasActionCreationRight}
                onCreateFocusArea={onCreateFocusArea}
                focusAreas={focusAreas}
                focusAreaLabel={surveyName}
                page={page}
                sort={sort}
                topicAnalysEnabled={topicAnalysEnabled}
                postAndSurveySentiment={isSentimentAnalysisQuestion}
                enableDefaultTranslate={enableDefaultTranslate}
              />
            </TabPane>
            {surveySuggestionsEnabled ? <TabPane tab={SuggestionsTitle} key="comments/suggestions">
              <Alert
                key="suggestionsInfo"
                message={<Trans>
                  We use Artificial Intelligence to identify for you the comments that seem to contain a 
                  suggestion from the respondent. As in all things AI, there will be some false positives, 
                  and we'll continuously try to improve the accuracy of our algorithm.
                </Trans>}
                type="warning"
                style={{
                  "margin": "8px 0 12px"
                }}
              />
              <CommentsTable
                comments={Store.Page.toList(comments)}
                loading={Store.Page.isLoading(comments)}
                isPrivateMessageModalOpen={isPrivateMessageModalVisible}
                privateMessageText={privateMessageText}
                sendPrivateMessage={this.sendPrivateMessage}
                togglePrivateMessageModal={togglePrivateMessageModal}
                updatePrivateMessageText={updatePrivateMessageText}
                canSendPrivateMessage={canSendPrivateMessage}
                hasActionCreationRight={hasActionCreationRight}
                onCreateFocusArea={onCreateFocusArea}
                focusAreas={focusAreas}
                focusAreaLabel={surveyName}
                page={page}
                sort={sort}
                topicAnalysEnabled={topicAnalysEnabled}
                onlySugestions={true}
                postAndSurveySentiment={isSentimentAnalysisQuestion}
                enableDefaultTranslate={enableDefaultTranslate}
              />
            </TabPane> : null }
          </StyledTabs>
          <AbsoluteSearchBarContainer right={40}>
            <OpenQuestionSearchBar />
          </AbsoluteSearchBarContainer>
        </Layout>
        <SendPrivateMessageModal
          clearPrivateMessageModal={clearPrivateMessageModal}
          comment={commentText}
          errorText={errorText}
          isSending={isNetworkRequesting}
          onCancel={togglePrivateMessageModal}
          privateMessageText={privateMessageText}
          updatePrivateMessageText={updatePrivateMessageText}
          visible={isPrivateMessageModalVisible}
          onOk={this.sendPrivateMessage}
        />
      </>
    );
  }
}

const Layout = styled(AntLayout)`
  position: relative;
  background: white !important;
  margin-top: 24px;
  padding-bottom: 12px;
  font-family: "Lato";
`;

const SegmentContainer = styled.div`
  margin-top: 24px;
`;

export default withRouter(OpenQuestionHighlightReport);
