// @ts-nocheck
import React from "react";
export function SearchSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <defs>
        {/* eslint-disable max-len */}
        <path id="searchA" d="M7 11a4 4 0 1 1 0-8 4 4 0 0 1 0 8m9.707 4.293l-4.82-4.82A5.968 5.968 0 0 0 13 7 6 6 0 0 0 1 7a6 6 0 0 0 6 6 5.968 5.968 0 0 0 3.473-1.113l4.82 4.82a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414"/>
        {/* eslint-disable max-len */}
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="searchB" fill="#fff">
          <use xlinkHref="#searchA" />
        </mask>
        <use fill="#31373D" xlinkHref="#searchA" />
        <g fill="#AAB9CE" mask="url(#searchB)">
          <path d="M0 0h18v18H0z" />
        </g>
      </g>
    </svg>
  );
}
