import React from "react";
import Table from "@src/components/core/Table";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { Paragraph } from "@src/components/core/Typography";
import { FilterParameter, SortParameter } from "@src/utils/networks";
import { Heading } from "@components/core/Typography";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { UserResource } from "hyphen-lib/dist/domain/resource/user/UserResource";
import { UserPageParameters } from "@screens/Insights/UserManagement/containers/UserListContainer/store/actions";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { getOr, isNotEmptyObject, isEmptyObject, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { PaginationConfig } from "antd/lib/pagination";
import { SorterResult } from "antd/lib/table";
import { TableProps } from "antd/lib/table";
import Button from "@src/components/core/Button";
import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
import moment from "moment";
import { EmailCell } from "../cells/EmailCell";
import { checkIfHyphenAdmin } from "hyphen-lib/dist/business/user/Users";
import { Trans } from "react-i18next";
import { getDimensionSegmentLabel } from "@src/utils/Dimensions";

const ColumnStyledHeader = styled(Heading)<{ minWidth: number }>`
  color: ${Palette.darkBlueGrey};
  min-width: ${props => `${props.minWidth}px`};
`;

export interface UserTableProps {
  readonly users: UserResource[];
  readonly onModifyList: (parameters: UserPageParameters) => any;
  readonly page: PageFilter;
  readonly sort?: SortParameter;
  readonly filter?: FilterParameter;
  readonly total: number;
  readonly selectedUsersCount: number;
  readonly loading: boolean;
  readonly onSelectChange: (record: any, selected: any, selectedUsers: any) => void;
  readonly onSelectAll: (selected: boolean, selectedRows?: any[], changeRows?: any[]) => void;
  readonly onOpenDeleteModal: (visible: boolean) => void;
  readonly allowSMSPermission: boolean;
  readonly dimensions: Dimensions;
  readonly onEditUser: (user: UserResource) => void;
  readonly selectedRowKeys: string[];
  readonly canEditUser: boolean;
  readonly canDeleteUser: boolean;
}

export interface UserTableState {
  hoveredIndex: Optional<number>;
}

export interface DynamicHeaderProps {
  readonly record: any;
}

export class UserTable extends React.Component<UserTableProps, UserTableState> {
  state = {
    hoveredIndex: null,
  };

  onRow = (record: UserResource, rowIndex: number) => {
    return {
      onClick: (event: any) => this.onRowClick(record),
    };
  };

  getSortOrder(field: string, sort?: SortParameter) {
    if (isEmptyObject(sort)) {
      return undefined;
    }
    if (sort![field] === 1) {
      return "ascend";
    } else if (sort![field] === -1) {
      return "descend";
    }
    return undefined;
  }

  onRowClick = (record: UserResource) => {
    const { onEditUser, canEditUser } = this.props;
    if (canEditUser) {
      onEditUser(record);
    }
  };

  onChange = (pagination: PaginationConfig, __: any, sorter: SorterResult<UserResource>) => {
    const {
      onModifyList,
      page,
      filter,
    } = this.props;

    const pageParam = {
      size: getOr(pagination.pageSize, page.size),
      number: getOr(pagination.current, 1),
    };
    const sortParam: SortParameter = {};
    if (isNotEmptyObject(sorter) && isNotNullNorUndefined(sorter.order)) {
      sortParam[sorter.columnKey] = sorter.order === "ascend" ? 1 : -1;
    }
    onModifyList({
      filter,
      page: pageParam,
      sort: sortParam,
    });
  };

  renderTableColumnsForDimensions = (justManager = false) => {
    const { dimensions, sort } = this.props;
    const dimensionHeaders: any = [];
    for (const [dimensionKey, dimensionObj] of Object.entries(dimensions)) {
      if (((justManager && dimensionKey === "manager") || (!justManager && dimensionKey !== "manager")) 
          && dimensionKey !== "dynamicSeparation") {
        dimensionHeaders.push({
          title: (<ColumnStyledHeader size="small" minWidth={200}>{dimensionObj.label}</ColumnStyledHeader>),
          dataIndex: "dimensions." + dimensionKey,
          key: "dimensions." + dimensionKey,
          width: 120,
          sorter: true,
          sortOrder: this.getSortOrder("dimensions." + dimensionKey, sort),
          sortDirections: ["ascend", "descend"],
          render: (__: any, record: any) => 
            getDimensionSegmentLabel(dimensionKey,  getOr(record?.dimensions[dimensionKey], ""))
        });
      }
    }
    return dimensionHeaders;
  };

  render() {
    const {
      users,
      page,
      total,
      loading,
      onSelectChange,
      selectedUsersCount,
      allowSMSPermission,
      sort,
      selectedRowKeys,
      onSelectAll,
      canEditUser,
      canDeleteUser,
    } = this.props;

    const rowSelection = {
      onSelect: onSelectChange,
      fixed: true,
      selectedRowKeys,
      onSelectAll,
      getCheckboxProps: (record: UserResource) => {
        if (checkIfHyphenAdmin(record.email)) {
          return {
            disabled: true,
            checked: false
          };
        } else {
          return {};
        }
      },
    };

    return (
      <RelativeDiv>
        {
          selectedUsersCount > 0 && (
            <DynamicHeaderRow>
              <StyledTh>
                <span data-cy="userTableSelection"> {selectedUsersCount} <Trans>selected</Trans></span>
                {
                  canDeleteUser && (
                    <DeleteButton color="gradation" onClick={this.props.onOpenDeleteModal.bind(null, true)} 
                     translate="yes">Delete</DeleteButton>
                  )
                }
              </StyledTh>
            </DynamicHeaderRow>
          )
        }
        <StyledTable
          tableLayout="auto"
          scroll={{ x: "scroll" }}
          editUser={canEditUser}
          data-cy="userList"
          columns={[
            {
              title: (
                <ColumnStyledHeader minWidth={300} size="small" translate="yes">
                  Email/ID
                </ColumnStyledHeader>),
              dataIndex: "email",
              key: "email",
              fixed: "left",
              sorter: true,
              sortOrder: this.getSortOrder("email", sort),
              sortDirections: ["ascend", "descend"],
              render: (__, record) => (
                <EmailCell email={record.email} info={record.employeeId} />
              ),
            },
            {
              title: (
                <ColumnStyledHeader minWidth={180} size="small" translate="yes">
                  First Name
                </ColumnStyledHeader>),
              dataIndex: "firstName",
              key: "firstName",
              width: 180,
              sorter: true,
              sortOrder: this.getSortOrder("firstName", sort),
              sortDirections: ["ascend", "descend"],
              render: (__, record) => (
                <FirstNameCell>{record.firstName}</FirstNameCell>
              ),
            },
            {
              title: (
                <ColumnStyledHeader minWidth={180} size="small" translate="yes">
                  Last Name
                </ColumnStyledHeader>),
              dataIndex: "lastName",
              key: "lastName",
              width: 180,
              sorter: true,
              sortOrder: this.getSortOrder("lastName", sort),
              sortDirections: ["ascend", "descend"],
            },
            {
              title: (
                <ColumnStyledHeader minWidth={180} size="small" translate="yes">
                  Role
                </ColumnStyledHeader>),
              dataIndex: "role",
              key: "role",
              width: 180,
              sorter: true,
              sortOrder: this.getSortOrder("role", sort),
              sortDirections: ["ascend", "descend"],
            },
            {
              title: (
                <ColumnStyledHeader minWidth={200} size="small" translate="yes">
                  Status
                </ColumnStyledHeader>),
              dataIndex: "status",
              key: "status",
              // width: 300,
              sorter: true,
              sortOrder: this.getSortOrder("status", sort),
              render: (text: any) => (
                <Trans>{text}</Trans>
              ),
              sortDirections: ["ascend", "descend"],
            },
            {
              title: (
                <ColumnStyledHeader minWidth={200} size="small" translate="yes">
                  Engage Insights Access
                </ColumnStyledHeader>),
              dataIndex: "hasInsightsAccess",
              key: "hasInsightsAccess",
              width: 120,
              sorter: true,
              sortOrder: this.getSortOrder("hasInsightsAccess", sort),
              sortDirections: ["ascend", "descend"],
              render: hasInsightsAccess => not(hasInsightsAccess) ? "No" : "Yes",
            },
            allowSMSPermission === true ? ({
              title: (
                <ColumnStyledHeader minWidth={200} size="small" translate="yes">
                  Mobile
                </ColumnStyledHeader>),
              dataIndex: "mobile",
              key: "mobile",
              width: 120,
              sorter: true,
              sortDirections: ["ascend", "descend"],
            }) : {},
            ...this.renderTableColumnsForDimensions(true),
            {
              title: (
                <ColumnStyledHeader minWidth={150} size="small" translate="yes">
                  Hire Date
                </ColumnStyledHeader>),
              dataIndex: "hireDate",
              key: "hireDate",
              sorter: true,
              sortOrder: this.getSortOrder("hireDate", sort),
              sortDirections: ["ascend", "descend"],
              render: (__, record) => (
                <div>{record.hireDate ? moment(record.hireDate).format("MM/DD/YYYY") : null}</div>
              ),
            },
            {
              title: (
                <ColumnStyledHeader minWidth={150} size="small" translate="yes">
                  Termination Date
                </ColumnStyledHeader>),
              dataIndex: "endEmploymentDate",
              key: "endEmploymentDate",
              sorter: true,
              sortOrder: this.getSortOrder("endEmploymentDate", sort),
              sortDirections: ["ascend", "descend"],
              render: (__, record) => (
                <div>{record.endEmploymentDate ? moment(record.endEmploymentDate).format("MM/DD/YYYY") : null}</div>
              ),
            },
            ...this.renderTableColumnsForDimensions(),
            {
              title: null,
              dataIndex: "",
              key: "",
              fixed: "right",
              render: (__, record, index) => (
                canEditUser && (
                  <EditButton translate="yes">
                    Edit
                  </EditButton>
                )
              ),
              width: canEditUser ? 70 : 0,
            },
          ]}
          dataSource={users}
          onRow={this.onRow}
          rowSelection={rowSelection}
          rowKey={"_id"}
          pagination={{
            total,
            pageSize: page.size,
            current: page.number,
            showTotal: (totalDocuments: number, range: number[]) =>
              <Trans i18nKey="userTableCount"
              values={{start: range[0], end: range[1], total: totalDocuments}} 
              defaults={`Showing ${range[0]} to ${range[1]} of ${totalDocuments} users`}/>,
          }}
          onChange={this.onChange}
          loading={loading}
        />

      </RelativeDiv>
    );
  }
}

const RelativeDiv = styled.div`
  position: relative;
`;

const FirstNameCell = styled.div`
  padding-left: 8px;
`;

const DynamicHeaderRow = styled.div`
  position: absolute;
  left: 64px;
  height: 49px;
  color: ${Palette.bluishGrey} !important;
  font-weight: normal !important;
  padding-top: 6px !important;
  padding-left: 20px;
  z-index: 100;
  width: 100%;
  background-color: white;
`;

const DeleteButton = styled(Button)`
  height: 36px !important;
  // width: 85px;
  margin-left: 32px !important;
`;

const StyledTh = styled.div`
  text-align: left !important;
  min-width: 346px;
  opacity: 1 !important;
`;

const StyledTable = styled(Table) <TableProps<any> & { editUser: boolean }>`
  .ant-table-body > table > tbody > tr:hover > td {
    cursor: ${props => props.editUser ? "pointer" : "default"};
  }

  .ant-table-fixed-left table thead tr th:last-child{
    padding-right : 4px;
  }

  .ant-table-fixed-right th{
    padding-top: 0px !important;
    border-bottom: 1px solid ${Palette.lightGreyBlue};
  }
`;

const EditButton = styled(Paragraph)`
  cursor: pointer;
  text-align: center;
  color: ${Palette.darkModerateBlue} !important;
`;
