import React from "react";

import styled from "styled-components";
import Palette from "@src/config/theme/palette";

import { Col, Row } from "antd";
import { ModalProps } from "antd/lib/modal";

import ContainerCard from "@components/core/ContainerCard";
import { InfoCard } from "@components/core/InfoCard/InfoCard";
import Button from "@components/core/Button";
import Modal from "@components/core/Modal";

import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { PulsePollResource } from "hyphen-lib/dist/domain/resource/PulsePollResource";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { ParticipantCountResource } from "hyphen-lib/dist/domain/resource/participants/ParticipantCountResource";
import { AccessResource } from "hyphen-lib/dist/domain/access/AccessResource";
import ChannelSummarySection from "./ChannelSummarySection";
import PulsePollSummarySection from "./PulsePollSummarySection";
import { PulsePollQuestionsSection } from "./PulsePollQuestionsSection";
import { AudienceSection } from "./AudienceSection";
import { AccessSection } from "./AccessSection";
import { Trans } from "react-i18next";


const SummaryCard = styled(ContainerCard)`
  margin-bottom: 16px;
`;

const ActionContainer = styled.div`
  display: flex;
  margin-top: 32px;
  justify-content: flex-end;
`;

export interface PulsePollSummaryProps {
  readonly pulsePoll: PulsePollResource;
  readonly participantsCount: Loadable<ParticipantCountResource>;
  readonly accesses: AccessResource[];
  readonly allowedChannels: CompanyResource["channels"];
}

class PulsePollSummary extends React.Component<PulsePollSummaryProps> {
  state = {
    incompletePages: [],
  };

  closeModal = () => this.setState({ incompletePages: [] });

  render() {
    const {
      participantsCount,
      accesses,
      allowedChannels,
      pulsePoll,
    } = this.props;
    const { incompletePages } = this.state;

    return (
      <>
        <SummaryCard
          title="Summary"
          description="Almost there! Please double check everything before launching."
        >
          <Row gutter={32}>
            <Col span={12}>
              <InfoCard
                title="Settings"
                link={`/pulsePolls/edit/${pulsePoll._id}/settings`}
                translate="yes"
              >
                <PulsePollSummarySection
                  pulsePoll={pulsePoll}
                  data-cy="pulsePoll_summary_section"
                />
                <ChannelSummarySection
                  channels={pulsePoll.channels}
                  allowedChannels={allowedChannels}
                  data-cy="channel_summary_section"
                />
              </InfoCard>
            </Col>

            <Col span={12}>
              <InfoCard
                title="Question"
                link={`/pulsePolls/edit/${pulsePoll._id}/question`}
                translate="yes"
              >
                <PulsePollQuestionsSection
                  question={pulsePoll.question}
                  questionType={pulsePoll.questionType}
                  data-cy="questions-section"
                />
              </InfoCard>

              <InfoCard
                title="Audience"
                link={`/pulsePolls/edit/${pulsePoll._id}/audience`}
                translate="yes"
              >
                <AudienceSection
                  participantsCount={participantsCount}
                  data-cy="audience-section"
                />
              </InfoCard>

              <InfoCard
                title="Users with access"
                link={`/pulsePolls/edit/${pulsePoll._id}/access`}
                translate="yes"
              >
                <AccessSection
                  accesses={accesses}
                  data-cy="access-section"
                />
              </InfoCard>
            </Col>
          </Row>
        </SummaryCard>
        <ActionContainer>

          <StyledModal
            visible={incompletePages.length > 0}
            title={<Trans>Polls Incomplete</Trans>}
            footer={[
              <OkButton color="blue" key="forward" onClick={this.closeModal}
              translate="yes">Ok</OkButton>,
            ]}
            onCancel={this.closeModal}
          >
            <Container>
              <Trans>We're missing some required information. Please revisit the pages below.</Trans>
              <Content>
                {incompletePages.join(", ")}.
              </Content>
            </Container>
          </StyledModal>
        </ActionContainer>
      </>
    );
  }
}

const Container = styled.div`
  padding: 16px 32px;
  color: ${Palette.bluishGrey};
`;

const Content = styled.section`
  margin-top: 8px;
  color: ${Palette.darkBlueGrey};
`;

const StyledModal = styled(Modal)<ModalProps & {children: React.ReactNode}>`
  .ant-modal-footer {
    padding-bottom: 0px !important;
  }
`;

const OkButton = styled(Button)`
  width: 100px;
`;

export default PulsePollSummary;
