import React from "react";
import styled from "styled-components";
import { Icon } from "antd";

import { CellContainer, CellInnerContainer } from "@screens/Insights/SurveyList/components/cells/Cells";
import { SentimentScore } from "@components/core/SentimentScore";
import Palette from "@src/config/theme/palette";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Trans } from "react-i18next";

interface SentimentCellProps {
  row: {
    netSentimentScore: Optional<number>;
  };
  hover?: boolean;
}

export function SentimentCell({ row: { netSentimentScore }, hover }: SentimentCellProps) {
  return (
    <CellContainer align="center" justifyContent="flex-start">
      <CellInnerContainer padding={[24, 0]} width="100%">
        {hover ? (
          <SeeDetails><Trans>See details</Trans> <RightIcon type="right"/></SeeDetails>
        ) :
          <SentimentScore netSentimentScore={netSentimentScore} translate="yes"/>
        }
      </CellInnerContainer>
    </CellContainer>
  );
}

const SeeDetails = styled.div`
  color: ${Palette.darkModerateBlue};
  font-size: 14px;
  text-align: right;
  cursor: pointer;
  line-height: 30px;
`;

const RightIcon = styled(Icon)`
  font-size: 12px;
`;
