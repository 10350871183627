import { default as networkActionCreators } from "@store/network/actions";
import { createRequest } from "@src/utils/networks";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { SurveyTopicSegmentResource } from "hyphen-lib/dist/domain/resource/survey/report/SurveyTopicSegmentResource";
import { appendQueryString, generateQueryString } from "hyphen-lib/dist/util/net/HttpClient";

export function fetchTopicSegmentReportIfNeeded(surveyId: string,
  topicId: string,
  queryString: Dictionary<any>) {
  return networkActionCreators.fetchElementIfNeeded({
    id: SurveyTopicSegmentResource.generateKey(surveyId, queryString.filter),
    type: SurveyTopicSegmentResource.TYPE,
    request: createRequest(
      appendQueryString(
        `/surveys/${surveyId}/reports/topics/${topicId}/overview`,
        generateQueryString(queryString)
      )
    ),
  });
}

