import React from "react";
import ContainerCard from "@components/core/ContainerCard";
import styled from "styled-components";
import { Paragraph } from "@src/components/core/Typography";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";

interface Props {
  hasEditRight: boolean;
}

export default function ActionPlanSettingsManageTemplates(props: Props) {
  if (props.hasEditRight) {
    return (
      <StyledContainerCard title="Action Plan">
        <Holder>
          <Paragraph translate="yes">Manage Suggestions</Paragraph>
          <Link to="/settings/manageSuggestions">
            <Trans>Click here to manage your custom templates and select which Betterworks Engage
            templates to use.</Trans>
          </Link>
        </Holder>
      </StyledContainerCard>
    );
  } else {
    return null;
  }
}

const StyledContainerCard = styled(ContainerCard)`
  margin-bottom: 24px;
  input:focus {
    outline: none;
  }
`;

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;
