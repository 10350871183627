import { default as networkActionCreators } from "@store/network/actions";
import { createRequest } from "@src/utils/networks";
import { appendQueryString, generateQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { IndividualResultResource } from "hyphen-lib/dist/domain/resource/survey/report/IndividualResultResource";

export function fetchIndividualResultsIfNeeded(surveyId: string,
  queryString: Dictionary<any>) {

  return networkActionCreators.fetchPageIfNeeded({
    id: IndividualResultResource.generateKey(surveyId, queryString.filter),
    type: IndividualResultResource.TYPE,
    page: PageFilter.noPagination(),
    rawPageSize: PageFilter.RAW_PAGE_SIZE_WITH_NO_PAGINATION,
    request: () => createRequest(
      appendQueryString(
        `/surveys/${surveyId}/reports/individualResults`,
        generateQueryString(queryString)
      )
    ),
  });
}
