import React from "react";
import styled from "styled-components";

import { IndividualResultResource } from "@hyphen-lib/domain/resource/survey/report/IndividualResultResource";
import {
  CellContainer,
  CellInnerContainer
} from "@screens/Insights/SurveyList/components/cells/Cells";
import Button from "@src/components/core/Button";

import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { Question } from "./components/Question";

import { Comment } from "./components/Comment";
import { Answers } from "./components/Answers";

type QuestionAnswerCellProps = Pick<
IndividualResultResource,
Exclude<keyof IndividualResultResource, "email">
>;

interface Props {
  row: QuestionAnswerCellProps;
  isMultiSelectQuestionEnabled: boolean;
  index?: number;
  isCategoryVisible?: boolean;
  isCommentVisible?: boolean;
  postAndSurveySentiment?: boolean;
}

export class QuestionAnswerCell extends React.Component<Props> {
  get renderAnswerComponents() {
    const {
      row: { choices, type },
      isMultiSelectQuestionEnabled,
    } = this.props;
    return (
      isNotNullNorUndefined(choices) && (
        <AnswerContainer>
          <Answers
            choices={
              isMultiSelectQuestionEnabled ? choices : choices.slice(0, 1)
            }
            type={type}
          />
        </AnswerContainer>
      )
    );
  }

  render() {
    const {
      row,
      isCategoryVisible = true,
      isCommentVisible = true,
      postAndSurveySentiment
    } = this.props;

    return (
      <CellContainer align="flex-start" justifyContent="flex-start">
        <CellInnerContainer padding={[24, 0]} width="100%">
          <QuestionAnswerContainer>
            <Question
              question={row.question}
              category={row.category}
              isCategoryVisible={isCategoryVisible}
            />
            <FakeAnswerCell>
              {this.renderAnswerComponents}
            </FakeAnswerCell>
          </QuestionAnswerContainer>
          {isCommentVisible && row.comment && 
          <Comment comment={row.comment} postAndSurveySentiment={postAndSurveySentiment}/>}
        </CellInnerContainer>
      </CellContainer>
    );
  }
}

const QuestionAnswerContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FakeAnswerCell = styled.div`
  width: 168px;
  // margin-left: 32px;
`;

const AnswerContainer = styled.div`
  width: 168px;
`;

export const SendPrivateMessageButton = styled(Button)`
  height: 32px !important;
  margin-top: 20%;
`;
