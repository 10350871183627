import React from "react";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { Seq } from "immutable";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import { Trans } from "react-i18next";

export interface LegendItem {
  backgroundColor: string;
  label: string;
}

interface Props {
  legendItems: LegendItem[];
}

const AnswerDistributionLegend = ({ legendItems }: Props) => (
  <Container>
    {
      Seq(legendItems)
        .filter(item => isStringAndNotEmpty(item.label))
        .map(item => (
          <div key={item.label}>
            <LegendColor backgroundColor={item.backgroundColor}/>
            <Trans>{item.label}</Trans>
          </div>
        ))
        .toArray()
    }
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  color: ${Palette.darkBlueGrey};
  flex-wrap: wrap;
  justify-content: flex-end;

  div {
    margin-right: 32px;
    flex-direction: row;
  }
  div:last-child {
    margin-right: 0;
  }
  div > span {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 8px;
  }
`;

const LegendColor = styled.span<{ backgroundColor: string }>`
  background-color: ${props => props.backgroundColor};
`;

export default AnswerDistributionLegend;
