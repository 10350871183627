import React from "react";
import { connect, MapStateToProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RouteComponentProps, withRouter } from "react-router";

import { State } from "@store/types";


import { getDimensions } from "@screens/Insights/store/selectors";
import { getOr, mapValues, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { FilterParameter } from "@src/utils/networks";
import {
  FiltersContent,
  FilterDefinition,
  CustomFilterDefinition
} from "@src/screens/Insights/components/FiltersContent";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { generateDefinitionsForDimensions } from "@src/screens/Insights/components/Filters/business/DimensionFilters";
import { replaceTo } from "@src/utils/locations";
import {
  appendQueryString,
  generateQueryString,
  parseQueryString
} from "hyphen-lib/dist/util/net/HttpClient";
import { DateFilter } from "@src/screens/Insights/components/Filters/business/DateFilter";
import {
  SentimentFilter,
  PulsePollStatusFilter
} from "@src/screens/Insights/components/Filters/business/PulsePollFilters";
import { FlagFilter } from "@src/screens/Insights/components/Filters/business/FlagFilter";
import { Seq, Map as ImmutableMap } from "immutable";
import { parseBoolean } from "hyphen-lib/dist/lang/Booleans";
import { parseNumber } from "hyphen-lib/dist/lang/Number";
import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
import { AddDimensionFilter } from "@src/screens/Insights/components/Filters/business/AddDimensionFilter";
import { applyDefault } from "@src/utils/Currier";
import { pulsePollReportsFiltersActionCreators } from "./store/actions";
import { getPulsePollReportsFiltersState } from "./store/selectors";
import { PulsePollsDimensionsResource } from "hyphen-lib/dist/domain/resource/PulsePollsDimensionsResource";
import { getResourceById } from "@src/store/network/selectors";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { sanitizeDimensions } from "@src/utils/Dimensions";

interface MatchParams {
  id: string;
  selectedDimension: string;
  templateId: string;
  instanceId: string;
}

export interface PulsePollReportsFilterContainerOwnProps
  extends RouteComponentProps<MatchParams> {
  readonly enabledFilters: string[];
  readonly enabledCustomFilters?: string[];
  readonly values?: Dictionary<any>;
  readonly onApply: (filter: FilterParameter) => any;
}

interface PulsePollReportFilterData {
  readonly dimensions: Dimensions;
}

export interface PulsePollReportsFilterContainerStateProps {
  readonly filters: Optional<FilterParameter>;
  readonly filtersData: PulsePollReportFilterData;
  readonly pulsePollsDimensionsInfoElement: Store.Element<PulsePollsDimensionsResource>;
}

type PulsePollReportsFilterContainerProps = {
  dispatch: Dispatch;
} & PulsePollReportsFilterContainerOwnProps &
PulsePollReportsFilterContainerStateProps;

class PulsePollReportsFilter extends React.Component<
PulsePollReportsFilterContainerProps
> {
  boundActionCreators: typeof pulsePollReportsFiltersActionCreators;

  constructor(props: PulsePollReportsFilterContainerProps) {
    super(props);

    const { dispatch } = props;

    this.boundActionCreators = bindActionCreators(
      pulsePollReportsFiltersActionCreators,
      dispatch
    );
  }

  get customFilterDefinitions(): CustomFilterDefinition[] {
    const { enabledCustomFilters = [] , filtersData, pulsePollsDimensionsInfoElement } = this.props;
    const dynamicDimensions =  Store.Element.isLoaded(pulsePollsDimensionsInfoElement) ? 
    getOr(sanitizeDimensions(pulsePollsDimensionsInfoElement.value.dimensions) , {}) : filtersData.dimensions;
    const customFiltersMap: ImmutableMap<string, CustomFilterDefinition> = ImmutableMap(
      {
        addDimension: {
          key: "addDimension",
          label: "Add a Dimension",
          component: applyDefault(AddDimensionFilter, {
            dimensions: dynamicDimensions,
            loading: Store.Element.isLoading(pulsePollsDimensionsInfoElement),
            fetchDimensionData: this.fetchPulsePollDimensions,
          }),
        },
      }
    );

    return Seq(enabledCustomFilters)
      .map(enabledCustomFilter => customFiltersMap.get(enabledCustomFilter))
      .filter(isNotNullNorUndefined)
      .toArray();
  }

  componentDidMount() {
    const { onModifyFilter } = this.boundActionCreators;
    const { values } = this.props;
    /*
        Modify filters with initial values while mounting.
     */
    onModifyFilter(getOr(values, {}));
  }

  fetchPulsePollDimensions = () => {
    const { fetchPulsePollDimensionsInfo } = this.boundActionCreators;
    const {  match } = this.props;
    const {
      params: { instanceId },
    } = match;

    fetchPulsePollDimensionsInfo(instanceId);
  };

  getFilterDefinitions(): FilterDefinition[] {
    const { enabledFilters, filtersData, pulsePollsDimensionsInfoElement } = this.props;
    const dynamicDimensions =  Store.Element.isLoaded(pulsePollsDimensionsInfoElement) ? 
    getOr(sanitizeDimensions(pulsePollsDimensionsInfoElement.value.dimensions) , {}) : filtersData.dimensions;
    return Seq(enabledFilters)
      .flatMap(enabledFilter =>
        this.mapToFilterDefinition(enabledFilter, filtersData, dynamicDimensions)
      )
      .toArray();
  }

  // noinspection JSMethodCanBeStatic
  mapToFilterDefinition(
    type: string,
    { dimensions }: PulsePollReportFilterData,
    dynamicDimensions: Dimensions
  ): FilterDefinition[] {
    switch (type) {
      case "date":
        return [
          {
            key: type,
            label: "Date",
            component: DateFilter,
          },
        ];
      case "sentiment":
        return [
          {
            key: "sentiments",
            label: "Sentiment",
            component: SentimentFilter,
          },
        ];
      case "withCommentOnly":
        return [
          {
            key: type,
            label: "Only results with comments",
            component: FlagFilter,
          },
        ];
      case "status":
        return [
          {
            key: type,
            label: "Status",
            component: PulsePollStatusFilter,
          },
        ];
      case "dimension":
        return generateDefinitionsForDimensions(dynamicDimensions);
    }

    return [];
  }

  getFilterValues = () => {
    const { location } = this.props;
    const { filter = {} } = parseQueryString(location.search);

    return mapValues(filter, (val: any, key: string) => {
      switch (key) {
        case "withCommentOnly":
          return parseBoolean(val);
        case "sentiments":
          return val.map(parseNumber);
      }
      return val;
    });
  };

  handleApply = (filter: FilterParameter) => {
    const { location, onApply } = this.props;

    replaceTo(
      appendQueryString(
        location.pathname,
        generateQueryString({ ...parseQueryString(location.search), filter })
      )
    );

    onApply(filter);
  };

  render() {
    const values = this.getFilterValues();
    const filters = this.getFilterDefinitions();
    return (
      <FiltersContent
        filters={filters}
        customFilters={this.customFilterDefinitions}
        displayCount={false}
        onApply={this.handleApply}
        values={values}
        onChange={this.boundActionCreators.onModifyFilter}
      />
    );
  }
}

const mapStateToProps: MapStateToProps<
PulsePollReportsFilterContainerStateProps,
PulsePollReportsFilterContainerOwnProps,
State
> = (state: State, ownProps: PulsePollReportsFilterContainerOwnProps): PulsePollReportsFilterContainerStateProps => {
  const filters = getPulsePollReportsFiltersState(state);
  const { match } = ownProps;
  const {
    params: { instanceId },
  } = match;
  
  const pulsePollsDimensionsInfoElement = getResourceById(state, PulsePollsDimensionsResource.TYPE,
    PulsePollsDimensionsResource.generateId(instanceId));

  return {
    filtersData: {
      dimensions: getOr(getDimensions(state), {}),
    },
    filters,
    pulsePollsDimensionsInfoElement,
  };
};

export const PulsePollReportsFilterContainer = withRouter(
  connect(mapStateToProps)(PulsePollReportsFilter)
);
