import { createRequest } from "@src/utils/networks";
import { loadSessionToken } from "@src/utils/sessionStores";
import {
  ActionTypes as NetworkActionTypes,
  NetworkEventErrorAction,
  NetworkEventRequestAction,
  NetworkEventSuccessAction,
  NetworkRequestAction,
} from "@store/network/actions";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { LocaleCodes } from "hyphen-lib/dist/util/locale";

export enum XLSXActionTypes {
  DOWNLOAD_XLSX_TEMPLATE = "xlsx/DOWNLOAD_TEMPLATE",
  DOWNLOAD_XLSX_TEMPLATE_FAILED = "xlsx/DOWNLOAD_TEMPLATE_FAILED",
  DOWNLOAD_XLSX_TEMPLATE_SUCCESS = "xlsx/DOWNLOAD_TEMPLATE_SUCCESS",

  UPLOAD_XLSX = "xlsx/UPLOAD",
  UPLOAD_XLSX_SUCCESS = "xlsx/UPLOAD_SUCCESS",
  UPLOAD_XLSX_FAILED = "xlsx/UPLOAD_FAILED",
}

const sendXLSXToServer = (
  file: File,
  surveyId: string,
  language: LocaleCodes
) => {
  const token = loadSessionToken();
  const formData = new FormData();
  formData.append("csv", file);
  return createRequest(
    `/surveys/${surveyId}/multilanguage/upload?language=${language}&dryRun=false`,
    {
      method: "POST",
      body: formData,
      headers: {
        "x-session-token": isNotNullNorUndefined(token) ? token : "",
      },
    }
  );
};

export const xlsxActionCreators = {
  uploadXLSX: (
    file: File,
    surveyId: string,
    language: LocaleCodes
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: sendXLSXToServer(file, surveyId, language),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => ({
          type: XLSXActionTypes.UPLOAD_XLSX,
          payload,
        }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({
          type: XLSXActionTypes.UPLOAD_XLSX_SUCCESS,
          payload,
          meta: {surveyId},
        }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => {
          return {
            type: XLSXActionTypes.UPLOAD_XLSX_FAILED,
            payload,
          };
        },
      ],
      onSuccessRedirect: undefined,
    },
  }),
};
