import Palette from "@src/config/theme/palette";
import { Avatar } from "antdv4";
import "antdv4/lib/avatar/style";
import { Tooltip } from "antd";
import styled from "styled-components";

interface Props {
    id: string;
    owner: string;
    goalName: string;
    profileUrl: string;
    key: string;
}

function OKRSearchListOption(props: Props) {
    return (
        <SearchListWrapper key={props.key} id={props.id} role="goal">
            <Tooltip title={props.goalName}>
                <OkrTitle>
                    {props.goalName}
                </OkrTitle>
            </Tooltip>
            <UserWrapper>
                <OkrUserAvatar src={props.profileUrl}/>
                <Tooltip title={props.owner}>
                    <OkrOwner>
                        {props.owner}
                    </OkrOwner>
                </Tooltip>
            </UserWrapper>
        </SearchListWrapper>
    );
}

const SearchListWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    pading-top: 12px;
    width: 100%;
`;

const OkrTitle = styled.p`
    color: ${Palette.veryDarkBlueGrey};
    letter-spacing: 0;
    line-height: 20px;
    margin-top: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        overflow: none;
    }
`;

const OkrUserAvatar = styled(Avatar)`
    img {
        border-radius: 50%;
        max-height: 28px;
        max-width: 28px;
        border: 2px solid #FFFFFF;
        margin-right: 12px;
        margin-top: 2px;
    }
`;

const OkrOwner = styled.p`
    color: ${Palette.bluishGrey};
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    max-width: 100px;
    margin-top: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const UserWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-right: 12px;
`;

export default OKRSearchListOption;
