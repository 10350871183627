import React from "react";
import Modal from "@src/components/core/Modal";
import Button from "@components/core/Button";
import styled from "styled-components";
import {
  isNotNullNorUndefined,
  isNullOrUndefined,
} from "hyphen-lib/dist/lang/Objects";
import { ModalProps } from "antd/lib/modal";
import { noOp } from "hyphen-lib/dist/lang/Functions";
import { ReactNode } from "react";
import { Trans }  from "react-i18next";
import { TransWrapper } from "../withTrans";

type FooterButtonPosition = "left" | "center" | "right" | "default";

interface Props extends ModalProps {
  visible: boolean;
  title: string;
  description: string | ReactNode;
  onOk: () => void;
  okLabel: string;
  onCancel?: () => void;
  cancelLabel?: string;
  hideCancelButton?: boolean;
  hideOkButton?: boolean;
  buttonsDisabled?: boolean;
  image?: string;
  footerButtonPosition?: FooterButtonPosition;
  okButtonStyles?: React.CSSProperties;
}

class AreYouSureModal extends React.Component<Props> {
  onCancel = () => {
    const { onCancel, buttonsDisabled } = this.props;
    if (isNotNullNorUndefined(onCancel) && !buttonsDisabled) {
      onCancel();
    }
  };

  renderOkButton() {
    if (this.props.footerButtonPosition === "right") {
      return (
        <Button
          key="okLabel"
          data-cy="okButton"
          onClick={this.props.onOk}
          color="blue"
          disabled={this.props.buttonsDisabled}
          style={this.props.okButtonStyles}
          translate="yes"
        >
          {this.props.okLabel}
        </Button>
      );
    } else {
      return (
        <StyledButton
          key="okLabel"
          data-cy="okButton"
          onClick={this.props.onOk}
          color="blue"
          disabled={this.props.buttonsDisabled}
          style={this.props.okButtonStyles}
          translate="yes"
        >
          {this.props.okLabel}
        </StyledButton>
      );
    }
  }

  renderFooterSection() {
    const hideCancelButton = this.props.hideCancelButton;
    let footerButtonPositionClass = "";
    if (this.props.footerButtonPosition === "right") {
      footerButtonPositionClass = "d-flex justify-content-end";
    }
    return (
      <ActionContainer
        hideCancelButton={Boolean(hideCancelButton)}
        className={footerButtonPositionClass}
      >
        {hideCancelButton ? null : (
          <StyledButton
            data-cy="areYouSure_cancel"
            key="cancelLabel"
            onClick={this.props.onCancel}
            disabled={this.props.buttonsDisabled}
            translate="yes"
          >
            {this.props.cancelLabel || "Cancel"}
          </StyledButton>
        )}
        {!this.props.hideOkButton && this.renderOkButton()}
      </ActionContainer>
    );
  }

  render() {
    const {
      visible,
      title,
      description,
      onOk,
      okLabel,
      onCancel = noOp,
      hideCancelButton,
      hideOkButton = false,
      cancelLabel,
      buttonsDisabled,
      footer = null,
      image = null,
      width,
      ...rest
    } = this.props;

    return (
      <StyledModal
        visible={visible}
        title={<Trans>{title}</Trans>}
        centered
        onCancel={this.onCancel}
        destroyOnClose={true}
        footer={footer}
        width={width}
        {...rest}
      >
        <ModalContent data-cy="modalContent">
          <TransWrapper translate="yes">{description}</TransWrapper>
          {image && <StyledImage src={image} />}
        </ModalContent>
        {isNullOrUndefined(footer) && (
          this.renderFooterSection()
        )}
      </StyledModal>
    );
  }
}

const ModalContent = styled.div`
  margin-top: 10px;
  padding-left: 32px !important;
  padding-right: 32px !important;
  white-space: normal;
`;

const StyledImage = styled.img`
  object-fit: scale-down;
`;

const StyledModal = styled(Modal)<ModalProps & { children: React.ReactNode }>`
  width: ${(props) => props.width || 500}px !important;
  .ant-modal-footer {
    margin-top: 20px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    button { 
      margin-bottom: 10px;
    }
  }
`;

const ActionContainer = styled.div<{ hideCancelButton: boolean }>`
  display: flex;
  margin-top: 20px;
  justify-content: ${(props) =>
    props.hideCancelButton ? "center" : "space-around"};
  padding-left: 32px !important;
  padding-right: 32px !important;
`;

const StyledButton = styled(Button)`
  // margin-right: 32px;
  // margin-left: 32px;
  min-width: 150px;
`;

export default AreYouSureModal;
