import React from "react";
import styled from "styled-components";

interface TextCellProps {
  readonly text: string;
}

export function TextCell({ text }: TextCellProps) {
  return <Container>{text}</Container>;
}

const Container = styled.div`
  max-width: 600px;
  hyphens: auto;
  overflow-wrap: break-word;
`;

Container.displayName = "TextCellContainer";
