import React from "react";

import { CellContainer, CellInnerContainer } from "@screens/Insights/SurveyList/components/cells/Cells";

import { Question, QuestionProps } from "./components/Question";

interface Props {
  row: QuestionProps;
  hover?: boolean;
  marginLeft? : number;
  marginRight?: number;
}

export class QuestionCell extends React.Component<Props> {
  render () {
    const { row, hover, marginLeft, marginRight } = this.props;
    return (
      <CellContainer
        align="flex-start"
        justifyContent="flex-start"
        marginLeft={marginLeft}
        marginRight={marginRight}
      >
        <CellInnerContainer padding={[24, 0]} width="100%">
          <Question {...row} hover={hover} />
        </CellInnerContainer>
      </CellContainer>
    );
  }
}
