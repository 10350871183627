import { Point, TooltipFormatterContextObject } from "highcharts";
import { renderToString } from "react-dom/server";
import styled from "styled-components";
import { ensureNoNan } from "@hyphen-lib/lang/Number";
import Palette from "@src/config/theme/palette";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { Trans } from "react-i18next";

interface PointWithCustom extends Point {
  custom?: any;
}

interface TooltipFormatterContextObjectWithCustomPoint extends TooltipFormatterContextObject {
  point: PointWithCustom;
}

const toolTipOptions: Highcharts.TooltipOptions = {
  formatter() {
    const [pointData]: TooltipFormatterContextObjectWithCustomPoint[] = this.points!;
    /* fixme: This component currently has to know the properties in the question trend and survey trend
     to render itself appropriately. Ideally it should be agnostic to type of trend. Find a way to do that!! */
    return renderToString(
      <TooltipContainer>
        <TooltipRow>
          <TooltipTitle data-cy="survey_name">{pointData.point.custom.surveyName}</TooltipTitle>
        </TooltipRow>
        {/* <TooltipRow>
          <TooltipLabel>Favorability:</TooltipLabel>
          <TooltipValue data-cy="favorability_score">{pointData.y}%</TooltipValue>
        </TooltipRow> */}
        {
          isNotNullNorUndefined(pointData.point.custom.ratingQuestionsCount) &&
          pointData.point.custom.ratingQuestionsCount >= 0 && (
            <TooltipRow>
              <TooltipLabel># <Trans>Rating Questions</Trans>:</TooltipLabel>
              <TooltipValue data-cy="rating_question_count">
                {pointData.point.custom.ratingQuestionsCount}
              </TooltipValue>
            </TooltipRow>
          )
        }
        <TooltipRow>
          <TooltipLabel><Trans>Participation</Trans>:</TooltipLabel>
          <TooltipValue data-cy="survey_participation">
            {ensureNoNan(Math.floor(
              pointData.point.custom.participation.completed / pointData.point.custom.participation.total * 100
            ), 0)}% &nbsp;
            <small>
              ({pointData.point.custom.participation.completed}/{pointData.point.custom.participation.total})
            </small>
          </TooltipValue>
        </TooltipRow>
      </TooltipContainer>
    );
  },
};

const TooltipContainer = styled.div`
  text-align: left;
`;

const TooltipRow = styled.div`
  display: flex;
  align-items: center;
  border-bottom: thin solid #44467A;
  padding: 8px;
  &:last-child {
    border: none;
  }
`;

const TooltipLabel = styled.div`
  flex-grow: 1;
  margin-right: 4px;
`;

const TooltipValue = styled.div`
  flex-grow: 1;
  text-align: right;
  color: ${Palette.aquaBlue};
  font-size: 14px;
`;

const TooltipTitle = styled.div`
  color: ${Palette.aquaBlue};
  font-size: 14px;
`;

export default toolTipOptions;
