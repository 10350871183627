// @ts-nocheck
import React from "react";
export function VoicesSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15">
      <g fill="none" fillRule="evenodd" stroke="#AAB9CE" strokeLinecap="round" strokeWidth="1.2">
        <g strokeLinejoin="round">
          {/* eslint-disable-next-line max-len */}
          <path d="M7.521 11.413l.342.613c.203.367.18.806-.063 1.152a1.281 1.281 0 0 1-1.11.526 1.258 1.258 0 0 1-1.049-.626L3 8.34M5.64 3.206H3.473C2.108 3.206 1 4.326 1 5.706s1.108 2.5 2.474 2.5H5.64l5.361 2.5v-10l-5.36 2.5z"/>
        </g>
        {/* eslint-disable-next-line max-len */}
        <path d="M13.001 4c.622.533.999 1.233.999 2 .001.736-.355 1.448-1 2M15 1c1.274 1.271 2.002 3.09 2 5 .002 1.909-.726 3.728-2 5"/>
      </g>
    </svg>
  );
}
