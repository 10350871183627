import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect, MapStateToProps } from "react-redux";

import { Map as ImmutableMap } from "immutable";

import { State } from "@store/types";
import {
  getExistingPage,
  getResourceById,
} from "@store/network/selectors";

import {
  getCompany,
  getCurrentUser,
  getDimensions,
  getRightsMatcher,
} from "@screens/Insights/store/selectors";

import {
  fetchSegmentCommentsIfNeeded,
  FetchSegmentCommentsParameters,
  fetchSegmentReportIfNeeded,
} from "@store/network/resource/SegmentReportResources";

import { FilterParameter } from "@src/utils/networks";
import {
  checkForActionPlanCreationRight,
  checkForConversationRight,
} from "@src/utils/rights";
import {
  applyExistingParametersIfNeeded,
  PropMapping,
} from "@src/utils/parameters";
import { replaceLocation } from "@src/utils/locations";

import { getAppliedFilters } from "@components/core/FilterLabels/utils";

import { ScreenContainer } from "@screens/Insights/components/ScreenContainer";
import { getParameters } from "@screens/Insights/parameters/store/selectors";
import { getAnonymityThreshold } from "@screens/Insights/Settings/store/selectors";

import { SegmentReportCommentsReduxProps } from "@screens/Insights/Segments/SegmentReport/store/types";
import { getSegmentReportState } from "@screens/Insights/Segments/SegmentReport/store/selectors";

import { Store } from "hyphen-lib/dist/util/store/Store";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";

import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
import { AccessList } from "hyphen-lib/dist/domain/access/AccessList";
import { Company } from "hyphen-lib/dist/domain/Company";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";

import { SegmentReportResource } from "hyphen-lib/dist/domain/resource/report/SegmentReportResource";
import { CommentsResultResource } from "hyphen-lib/dist/domain/resource/report/CommentsResultResource";

import { RightsMatcher } from "hyphen-lib/dist/business/auth/Auth";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import {
  getOr,
  isNotEmptyObject,
  isNotNullNorUndefined,
  isNullOrUndefined,
  mapOr,
} from "hyphen-lib/dist/lang/Objects";
import { SegmentReportView } from "@screens/Insights/Segments/SegmentReport/components/SegmentReportView";
import { segmentReportActionCreators } from "@screens/Insights/Segments/SegmentReport/store/actions";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import {
  getDimensionLabel,
  getDimensionSegmentLabel,
  sanitizeDimensions,
} from "@src/utils/Dimensions";
import { Loading } from "../../Survey/components/Loading";
import { FetchError } from "../../errors/FetchError";
import { Comments } from "./components/Comments/index";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import {
  focusAreaListActionCreators,
  FocusAreaListPageParameters,
} from "../../Actions/store/actions";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";
import { isEmpty } from "lodash";
import { fetchFocusAreasIfNeeded } from "@src/store/network/resource/ActionResources";
import { getAllFocusAreasFromReduxStore } from "@src/utils/FocusArea";
import { hasFocusAreaRights } from "hyphen-lib/dist/business/focusArea/focusAreaResource";
import { parseNumber } from "hyphen-lib/dist/lang/Number";

interface MatchParams {
  dimension: string;
  segment: string;
}
export interface OwnProps extends RouteComponentProps<MatchParams> {
  readonly surveyRole: AccessList.UserAccessRole;
  readonly focusAreaLabel: string;
}

interface SegmentReportContainerStateProps
  extends SegmentReportCommentsReduxProps {
  readonly dimension: string;
  readonly segment: string;
  readonly segmentReport: Store.Element<SegmentReportResource>;
  readonly commentsResult: Store.Page<CommentsResultResource>;
  readonly parameters: ImmutableMap<string, any>;
  readonly filter: FilterParameter;
  readonly rightsMatcher: RightsMatcher;
  readonly dimensions: Dimensions;
  readonly anonymityThreshold: number;
  readonly company?: Optional<CompanyResource>;
  readonly companyModules: Company.Modules;
  readonly hasActionCreationRight: boolean;

  readonly canSendPrivateMessage: boolean;
  readonly isPrivateMessageModalVisible: boolean;
  readonly source: CommentsResultResource["source"];
  readonly focusAreas?: FocusAreaResource[];
  readonly currentUser: Optional<CurrentUserResource>;
  readonly hasFaRights: boolean;
  readonly page: PageFilter;
}

type SegmentReportContainerActionProps = typeof segmentReportActionCreators & {
  readonly onFetchSegmentReport: (
    dimension: string,
    segment: string,
    filter: FilterParameter
  ) => void;
  readonly onFetchSegmentComments: (
    dimension: string,
    segment: string,
    parameters: FetchSegmentCommentsParameters
  ) => void;
  readonly onCreateFocusArea: (focusArea: Partial<FocusAreaResource>) => void;
  readonly onFetchFocusArea: (parameters: FocusAreaListPageParameters) => any;
};

type Props = OwnProps &
  SegmentReportContainerStateProps &
  SegmentReportCommentsReduxProps &
  SegmentReportContainerActionProps;

const STORE_MAPPINGS: PropMapping[] = [
  { localKey: "filter", storeKey: "segmentReportFilter" },
  // { localKey: "filter.dimensions", storeKey: "segmentReport.dimensions" },
];

export class SegmentReportContainer extends React.Component<Props> {
  componentDidMount() {
    // we might need to apply persisted parameters
    const {
      parameters,
      location: { search },
    } = this.props;

    const existing = parseQueryString(search);
    const mergedParameters = applyExistingParametersIfNeeded(
      parameters.toJS(),
      existing,
      "filter.dimensions",
      "filter.modules",
      "filter.surveyType"
    );

    if (
      isNotNullNorUndefined(mergedParameters) &&
      isNotEmptyObject(mergedParameters)
    ) {
      replaceLocation(mergedParameters);
    } else {
      // fetch the overview only if we will stay on this page,
      // otherwise it will be fetched anyway in componentDidUpdate
      this.fetchSegmentReportData();
    }

    if (
      (isNullOrUndefined(this.props.focusAreas) ||
      isEmpty(this.props.focusAreas)) && this.props.hasFaRights
    ) {
      this.props.onFetchFocusArea(this.fetchFocusAreaProps());
    }
  }

  fetchFocusAreaProps(): FocusAreaListPageParameters {
    return {
      page: {
        number: 1,
        size: 30,
      },
      filter: {
        withActionPlans: false,
      },
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.location.search !== prevProps.location.search) {
      this.fetchSegmentReportData();
    }

    if(this.props.hasFaRights) {
      this.props.onFetchFocusArea(this.fetchFocusAreaProps());
    }
  }

  fetchSegmentReportData = () => {
    const {
      dimension,
      segment,
      onFetchSegmentReport,
      onFetchSegmentComments,
      filter,
    } = this.props;

    onFetchSegmentReport(dimension, segment, filter);
    onFetchSegmentComments(dimension, segment, {
      filter,
    });
  };

  generateEnabledFilters = () => {
    const { companyModules } = this.props;
    const enabledFilters = ["dimension", "date"];
    let numberOfModules = 0;
    Object.values(companyModules).forEach((value) => {
      if (value === true) {
        numberOfModules += 1;
      }
    });

    if (numberOfModules > 1) {
      enabledFilters.push("modules");
    }

    if (companyModules.surveys) {
      enabledFilters.push("surveyType");
    }

    return enabledFilters;
  };

  sendPrivateMessage = () => {
    const {
      resourceId,
      postId,
      voteId,
      privateMessageText,
      source,
      origin,
      sendPrivateMessage,
    } = this.props;
    sendPrivateMessage(
      resourceId,
      postId,
      voteId,
      privateMessageText,
      origin,
      source
    );
  };

  render() {
    const {
      dimension,
      segment,
      segmentReport,
      commentsResult,
      hasActionCreationRight,
      rightsMatcher,
      anonymityThreshold,
      company,
      companyModules,
      location,
      dimensions,

      isPrivateMessageModalVisible,
      privateMessageText,
      errorText,
      commentText,
      isSendMessageRequesting,
      canSendPrivateMessage,
      togglePrivateMessageModal,
      clearPrivateMessageModal,
      updatePrivateMessageText,
      focusAreas,
      onCreateFocusArea,
      focusAreaLabel,
      page
    } = this.props;

    const appliedFilters = getAppliedFilters(location.search, dimensions);
    if (Store.Element.isInError(segmentReport)) {
      return (
        <FetchError
          {...segmentReport}
          resourceType={SegmentReportResource.TYPE}
        />
      );
    }

    if (Store.Element.isNotFound(segmentReport)) {
      return <Loading />;
    }

    return (
      <ScreenContainer
        title={getDimensionSegmentLabel(dimension, segment)}
        subTitle={getDimensionLabel(dimension, dimensions)}
      >
        <SegmentReportView
          segmentReport={Store.Element.toLoadable(segmentReport)}
          rightsMatcher={rightsMatcher}
          anonymityThreshold={anonymityThreshold}
          company={company}
          companyModules={companyModules}
          dimensions={dimensions}
          appliedFilters={appliedFilters}
          enabledFilters={this.generateEnabledFilters()}
          enabledCustomFilters={["addDimension"]}
          storeMappings={STORE_MAPPINGS}
          focusAreas={focusAreas}
          onCreateFocusArea={onCreateFocusArea}
        />
        <Comments
          comments={commentsResult}
          canSendPrivateMessage={canSendPrivateMessage}
          hasActionCreationRight={hasActionCreationRight}
          isPrivateMessageModalVisible={isPrivateMessageModalVisible}
          isNetworkRequesting={isSendMessageRequesting}
          commentText={commentText}
          privateMessageText={privateMessageText}
          errorText={errorText}
          clearPrivateMessageModal={clearPrivateMessageModal}
          sendPrivateMessage={this.sendPrivateMessage}
          togglePrivateMessageModal={togglePrivateMessageModal}
          updatePrivateMessageText={updatePrivateMessageText}
          focusAreas={focusAreas}
          onCreateFocusArea={onCreateFocusArea}
          focusAreaLabel={focusAreaLabel}
          page={page}
        />
      </ScreenContainer>
    );
  }
}

const mapStateToProps: MapStateToProps<
  SegmentReportContainerStateProps,
  OwnProps,
  State
> = (state: State, ownProps: OwnProps): SegmentReportContainerStateProps => {
  const queryParameters = parseQueryString(ownProps.location.search);
  const filter = getOr(queryParameters.filter, {});
  const { freeText, ...filtersWithoutFreeText } = filter;
  const {
    params: { dimension, segment },
  } = ownProps.match;
  let company = null;
  const currentUser = getCurrentUser(state);
  const page = {
    number: mapOr(queryParameters.page, parseNumber, 1),
    size: 30
  };
  if (
    isNotNullNorUndefined(currentUser) &&
    isNotNullNorUndefined(currentUser.company)
  ) {
    company = currentUser.company;
  }

  const companyModules: Company.Modules = mapOr(
    getCompany(state),
    (map) => map.modules,
    {
      surveys: false,
      pulsePolls: false,
      employeeVoice: false,
      employeeLifeCycle: false,
    }
  );

  const segmentReport = getResourceById(
    state,
    SegmentReportResource.TYPE,
    SegmentReportResource.generateId(dimension, segment, filtersWithoutFreeText)
  );

  const commentsResult = getExistingPage(
    state,
    CommentsResultResource.TYPE,
    CommentsResultResource.generateKey({ segment, dimension }, filter),
    PageFilter.noPagination()
  );

  // fixme: currently this part is useless, might be used later for private conversations?
  const {
    // title,
    resourceId,
    postId,
    voteId,
    commentText,
    origin,
    source,

    isPrivateMessageModalVisible,
    isSendMessageRequesting,
    privateMessageText,
    errorText,
  } = getSegmentReportState(state);

  const data = getAllFocusAreasFromReduxStore(state);
  const hasFaRights = hasFocusAreaRights(getRightsMatcher(state));
  const allowPrivateMessages = isNotNullNorUndefined(currentUser) &&
  isNotNullNorUndefined(currentUser?.company) &&
  currentUser.company.allowPrivateMessages;

  return {
    dimension,
    segment,
    segmentReport,
    filter,
    commentsResult,
    company,
    parameters: getParameters(state),
    rightsMatcher: getRightsMatcher(state),
    anonymityThreshold: getAnonymityThreshold(state),
    dimensions: getOr(sanitizeDimensions(getDimensions(state)), {}),
    companyModules,
    // fixme: comments are not coming from a single survey, so we would need the
    //  role of all surveys that have comments currently displayed
    canSendPrivateMessage: checkForConversationRight(ownProps.surveyRole, allowPrivateMessages),
    hasActionCreationRight: checkForActionPlanCreationRight(state),

    resourceId,
    postId,
    voteId,
    commentText,
    origin,
    source,

    isPrivateMessageModalVisible,
    isSendMessageRequesting,
    privateMessageText,
    errorText,
    currentUser,
    focusAreas: data,
    hasFaRights,
    page
  };
};

const mapDispatchToProps: SegmentReportContainerActionProps = {
  onFetchSegmentReport: fetchSegmentReportIfNeeded,
  onFetchSegmentComments: fetchSegmentCommentsIfNeeded,
  onFetchFocusArea: fetchFocusAreasIfNeeded,
  onCreateFocusArea: focusAreaListActionCreators.createFocusArea,
  ...segmentReportActionCreators,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SegmentReportContainer);
