import React from "react";
import { Map as ImmutableMap } from "immutable";
import { connect } from "react-redux";
import { PaginationConfig } from "antd/lib/table";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import {
  mapOr,
  getOr,
  isNullOrUndefined,
  isNotNullNorUndefined,
} from "hyphen-lib/dist/lang/Objects";
import { parseNumber } from "hyphen-lib/dist/lang/Number";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { Rights } from "hyphen-lib/dist/business/auth/Rights";
import { VoiceCommentReportResource } from "hyphen-lib/dist/domain/resource/voice/VoiceCommentReportResource";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { Store } from "hyphen-lib/dist/util/store/Store";

import { FilterParameter, SortParameter } from "@src/utils/networks";
import { State } from "@src/store/types";
import { DataListContainer } from "@src/screens/Insights/components/DataListContainer";
import { getParameters } from "@src/screens/Insights/parameters/store/selectors";
import { parametersActionCreators } from "@src/screens/Insights/parameters/store/actions";
import { fetchVoiceCommentsIfNeeded } from "@src/store/network/resource/VoiceCommentReportResource";
import { FetchDataListParameters } from "@src/screens/Insights/components/DataListContainer/types";
import {
  getCurrentUser,
  getRightsMatcher,
} from "@src/screens/Insights/store/selectors";
import { PropMapping } from "@src/utils/parameters";
import {
  getExistingPage,
  extractDataAndTotalFromPage,
} from "@src/store/network/selectors";

import { FetchError } from "@src/screens/Insights/errors/FetchError";
import { getEmployeeVoiceReportsState } from "../../store/selectors";
import { VoiceCommentsTable } from "../components/VoiceCommentsTable";
import { VOICE_COMMENTS_FILTER_MAPPINGS } from "../..";
import { voiceReportsActionCreators } from "../../store/actions";
import { fetchFocusAreasIfNeeded } from "@src/store/network/resource/ActionResources";
import {
  focusAreaListActionCreators,
  FocusAreaListPageParameters,
} from "@src/screens/Insights/Actions/store/actions";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { isEmpty } from "lodash";
import BottomDrawer, { DrawerViews } from "@src/components/core/BottomDrawer";
import { goTo } from "@src/utils/locations";
import BottomDrawerStripedChild, {
  transformFocusAreasToStripStack,
} from "@src/components/core/BottomDrawer/StripedChild";
import {
  getAllFocusAreasFromReduxStore,
  getCurrentBottomDrawerView,
  isFARequestComplete,
} from "@src/utils/FocusArea";
import { BottomDrawerMessages } from "@src/screens/Insights/BottomDrawer/constant";
import { hasFocusAreaRights } from "hyphen-lib/dist/business/focusArea/focusAreaResource";
import Spin from "@src/components/core/Spin";
import { Trans } from "react-i18next";

interface VoiceCommentsListProps
  extends VoiceCommentsListReduxStateProps,
    VoiceCommentsListReduxActionProps {}

interface VoiceCommentsListReduxStateProps {
  readonly voiceComments: VoiceCommentReportResource[];
  readonly pageSize: number;
  readonly totalVoiceCommentsCount: number;
  readonly parameters: ImmutableMap<string, any>;
  readonly loading: boolean;
  readonly isNotFound: boolean;
  readonly hasActionCreationRight: boolean;
  readonly existingListVoiceComments: Store.Page<VoiceCommentReportResource>;
  readonly focusAreas?: FocusAreaResource[];
  readonly currentUser: Optional<CurrentUserResource>;
  readonly currentBottomDrawerView: DrawerViews;
  readonly hasFaRights: boolean;
  readonly completedFARequest: boolean;
}

interface VoiceCommentsListReduxActionProps {
  readonly onFetchVoiceCommentsIfNeeded: (
    parameters: FetchDataListParameters
  ) => void;
  readonly onModifyParameters: (
    parameters: Dictionary<any>,
    mappings?: PropMapping[]
  ) => void;
  readonly onModifyList: (parameters: FetchDataListParameters) => void;
  readonly onFetchFocusArea: (parameters: FocusAreaListPageParameters) => any;
  readonly onCreateFocusArea: (focusArea: Partial<FocusAreaResource>) => void;
}

export interface FetchVoiceCommentsParameters {
  readonly filter?: FilterParameter;
  readonly sort?: SortParameter;
  readonly page: PageFilter;
}

interface VoiceCommentListState {
  totalFocusAreas: number;
}

export class VoiceCommentsListContainer extends React.Component<
  VoiceCommentsListProps,
  VoiceCommentListState
> {
  constructor(props: VoiceCommentsListProps) {
    super(props);
    this.state = {
      totalFocusAreas: 0,
    };
  }

  onTableChange = (
    pageParams: FetchDataListParameters,
    pagination: PaginationConfig
  ): void => {
    const { page, filter } = pageParams;
    const { onModifyList, onModifyParameters } = this.props;
    const pageParam = {
      size: getOr(pagination.pageSize, page.size),
      number: getOr(pagination.current, 1),
    };

    onModifyParameters(VOICE_COMMENTS_FILTER_MAPPINGS);
    onModifyList({
      filter,
      page: pageParam,
    });
  };

  componentDidMount() {
    if (
      (isNullOrUndefined(this.props.focusAreas) ||
        isEmpty(this.props.focusAreas)) &&
      this.props.hasFaRights
    ) {
      this.props.onFetchFocusArea(this.fetchFocusAreaProps());
    }

    let totalFocusAreas = 0;
    if (isNotNullNorUndefined(this.props.focusAreas)) {
      totalFocusAreas = this.props.focusAreas.length;
    }

    this.setState({
      totalFocusAreas,
    });
  }

  componentDidUpdate(prevProps: VoiceCommentsListProps) {
    if (this.props.hasFaRights) {
      this.props.onFetchFocusArea(this.fetchFocusAreaProps());
    }

    if (
      isNotNullNorUndefined(this.props.focusAreas) &&
      isNotNullNorUndefined(prevProps.focusAreas)
    ) {
      if (this.props.focusAreas.length > prevProps.focusAreas.length) {
        this.setState({
          totalFocusAreas: this.props.focusAreas.length,
        });
      }
    }
  }

  fetchFocusAreaProps(): FocusAreaListPageParameters {
    return {
      page: {
        number: 1,
        size: 30,
      },
      filter: {
        withActionPlans: false,
      },
    };
  }

  render() {
    const {
      pageSize,
      parameters,
      loading,
      hasActionCreationRight,
      onFetchVoiceCommentsIfNeeded,
      voiceComments,
      totalVoiceCommentsCount,
      isNotFound,
      existingListVoiceComments,
      focusAreas,
      onCreateFocusArea,
      hasFaRights,
      completedFARequest,
    } = this.props;
    if (Store.Page.isInError(existingListVoiceComments)) {
      return (
        <FetchError
          {...existingListVoiceComments}
          resourceType={VoiceCommentReportResource.TYPE}
        />
      );
    }

    return (
      <>
        <Spin
          size="large"
          className="sticky-top"
          spinning={!completedFARequest}
        >
          {hasFaRights && (
            <BottomDrawer
              key="segment-drawer"
              initialView={this.props.currentBottomDrawerView}
              title={`${
                this.state.totalFocusAreas === 0
                  ? "No"
                  : this.state.totalFocusAreas
              } Focus Area Added`}
              footerButton={{
                display: true,
                text: "Go To Action Center",
                onClick: () => goTo("/actioncenter"),
              }}
            >
              <BottomDrawerStripedChild
                stripStack={transformFocusAreasToStripStack(focusAreas)}
                emptyStackMessage={BottomDrawerMessages.EMPTY_STACK_MESSAGE}
              />
            </BottomDrawer>
          )}
          <DataListContainer
            pageSize={pageSize}
            parameters={parameters}
            loading={loading}
            isInError={Store.Page.isInError(existingListVoiceComments)}
            isNotFound={isNotFound}
            onFetchIfNeeded={onFetchVoiceCommentsIfNeeded}
            paramMappings={VOICE_COMMENTS_FILTER_MAPPINGS}
          >
            {({ pageParams }) => (
              <VoiceCommentsTable
                hasActionCreationRight={hasActionCreationRight}
                dataSource={voiceComments}
                loading={loading}
                onChange={this.onTableChange.bind(this, pageParams)}
                onCreateFocusArea={onCreateFocusArea}
                focusAreas={focusAreas}
                pagination={{
                  total: totalVoiceCommentsCount,
                  pageSize: pageParams.page.size,
                  current: pageParams.page.number,
                  showTotal: (totalDocuments: number, range: number[]) =>
                    <Trans i18nKey="commentsCount"
                    values={{start: range[0], end: range[1], total: totalDocuments}} 
                    defaults={`Showing ${range[0]} to ${range[1]} of ${totalDocuments} comments`}/>,
                }}
              />
            )}
          </DataListContainer>
        </Spin>
      </>
    );
  }
}

function mapStateToProps(
  state: State,
  { location }: RouteComponentProps
): VoiceCommentsListReduxStateProps {
  const currentUser = getCurrentUser(state);

  const queryParameters = parseQueryString(location.search);
  const filter = getOr(queryParameters.filter, {});
  const { pageSize } = getEmployeeVoiceReportsState(state);
  const page = {
    size: pageSize,
    number: mapOr(queryParameters.page, parseNumber, 1),
  };
  const existingListVoiceComments = getExistingPage(
    state,
    VoiceCommentReportResource.TYPE,
    VoiceCommentReportResource.generateKey(filter),
    page
  );

  const { data: voiceComments, total: totalVoiceCommentsCount } =
    extractDataAndTotalFromPage(existingListVoiceComments);

  const data = getAllFocusAreasFromReduxStore(state);
  const currentBottomDrawerView = getCurrentBottomDrawerView(state);
  const hasFaRights = hasFocusAreaRights(getRightsMatcher(state));

  return {
    pageSize,
    loading: Store.Page.isLoading(existingListVoiceComments),
    isNotFound: Store.Page.isNotFound(existingListVoiceComments),
    parameters: getParameters(state),
    totalVoiceCommentsCount,
    existingListVoiceComments,
    voiceComments,
    hasActionCreationRight: getRightsMatcher(state).hasRight(
      Rights.Action.CREATE
    ),
    currentUser,
    focusAreas: data,
    currentBottomDrawerView,
    hasFaRights,
    completedFARequest: isFARequestComplete(state),
  };
}
const mapDispatchToProps = {
  onFetchVoiceCommentsIfNeeded: fetchVoiceCommentsIfNeeded,
  onModifyList: voiceReportsActionCreators.modifyList,
  onModifyParameters: parametersActionCreators.modifyParameters,
  onFetchFocusArea: fetchFocusAreasIfNeeded,
  onCreateFocusArea: focusAreaListActionCreators.createFocusArea,
};

export const VoiceCommentsList = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VoiceCommentsListContainer)
);
