import { FSA } from "flux-standard-action";
import { FilterParameter } from "@src/utils/networks";

export enum ActionListFiltersActionTypes {
  MODIFY_FILTER = "actionList/MODIFY_FILTER",
  CLEAN_FILTER = "actionList/CLEAN_FILTER",
}

interface Payloads {
  [ActionListFiltersActionTypes.MODIFY_FILTER]: FilterParameter;
}

export interface ModifyFilterAction
  extends FSA<ActionListFiltersActionTypes.MODIFY_FILTER, Payloads[ActionListFiltersActionTypes.MODIFY_FILTER]> {
  type: ActionListFiltersActionTypes.MODIFY_FILTER;
  payload: Payloads[ActionListFiltersActionTypes.MODIFY_FILTER];
}

export interface CleanFilterAction extends FSA<ActionListFiltersActionTypes.CLEAN_FILTER, void> {
  type: ActionListFiltersActionTypes.CLEAN_FILTER;
}

export const actionListFiltersActionCreators = {
  modifyFilter: (payload: ModifyFilterAction["payload"]): ModifyFilterAction => ({
    type: ActionListFiltersActionTypes.MODIFY_FILTER,
    payload,
  }),
  cleanFilter: (): CleanFilterAction => ({
    type: ActionListFiltersActionTypes.CLEAN_FILTER,
  }),
};
