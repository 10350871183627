import React from "react";
import Modal from "@components/core/Modal";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { Paragraph } from "@src/components/core/Typography";
import { ModalProps } from "antd/lib/modal";
import { MergeTagResource } from "hyphen-lib/dist/domain/resource/MergeTagResource";
import MergeTagsModalContent from "./MergeTagsModalContent";
import { Trans } from "react-i18next";

interface Props {
  modalOpen: boolean;
  handleCancel: () => void;
  mergeTags: MergeTagResource[];
  onAddTag: (mergeTag: string) => void;
  fieldName: string;
}

const StyledMergeTagsModal = styled(Modal)<ModalProps & {children: React.ReactNode}>`
  width: 740px !important;
`;

const Description = styled(Paragraph)`
  padding: 16px 24px 24px 32px;
  color: ${Palette.bluishGrey};
`;

interface OwnStateProps {
  addedTags: string[];
}

export default class MergeTagsModal extends React.Component<Props, OwnStateProps> {
  onAddTagAction = (mergeTag: string) => {
    const { handleCancel } = this.props;
    this.props.onAddTag(mergeTag);
    handleCancel();
  };

  onCloseClicked = () => {
    const { handleCancel } = this.props;
    handleCancel();
  };

  render () {
    const { modalOpen, mergeTags, fieldName, handleCancel } = this.props;
    return (
      <StyledMergeTagsModal
        footer={null}
        visible={modalOpen}
        title={<Trans>Add Merge Tags</Trans>}
        centered={true}
        onCancel={handleCancel}>
        <Description translate="yes">
          You can use merge tags to add dynamic information about each employee.
          Paste a merge tag into a text field or select any of your available tags from the list below.
        </Description>
        <MergeTagsModalContent
          mergeTags={mergeTags}
          addAction={this.onAddTagAction}
          fieldName={fieldName}
        />
      </StyledMergeTagsModal>
    );
  }
}
