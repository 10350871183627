import React from "react";
import styled from "styled-components";
import ReactDOMServer from "react-dom/server";
import tippy from "tippy.js";
import { Icon, Dropdown, Menu } from "antd";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Trans } from "react-i18next";
import Palette from "@src/config/theme/palette";

import { State } from "@store/types";

import { PropMapping } from "@src/utils/parameters";
import { replaceTo } from "@src/utils/locations";

import { FiltersBackdrop } from "@screens/Insights/components/FiltersBackdrop";
import { CustomizationPopover } from "@screens/Insights/components/CustomizationPopover";
import * as selectors from "@screens/Insights/Surveys/store/selectors";
import { SurveyTypes } from "@screens/Insights/Surveys/store/types";
import FiltersContainer from "@screens/Insights/Survey/containers/FiltersContainer";

import { Progress } from "@components/core/Progress";
import { ExpandButton } from "@components/core/ExpandButton";
import Button from "@components/core/Button";
import { ExportSvg } from "@components/core/svg/ExportSvg";
import FilterLabels from "@components/core/FilterLabels";
import {
  clearFilter,
  clearFilterOption
} from "@components/core/FilterLabels/utils";
import { capitalizeFirstLetter } from "@src/utils/helper";
import { getOr, cleanObject, addPropertyInDepth } from "@hyphen-lib/lang/Objects";
import { calculatePercentage } from "@hyphen-lib/util/math/Math";
import { appendQueryString, generateQueryString, parseQueryString } from "@hyphen-lib/util/net/HttpClient";
import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { DropDownSvg } from "@components/core/svg/DropDownSvg";

import { parametersActionCreators } from "@screens/Insights/parameters/store/actions";
import {
  createExportSurveyIndividualResultsRequest,
  createExportSurveyCommentsRequest,
  createExportSurveyTopicsRequest,
  createExportSurveyParticipationRequest,
  createExportSurveyQuestionsRequest,
  createExportSurveyHeatmapRequest,
  createExportSurveyFullOverviewReportRequest,
  createExportSurveyTrendsOverviewRequest
}
  from "@src/utils/exports";
import { getDimensions, getCurrentUser, getRightsMatcher } from "@screens/Insights/store/selectors";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";
import { Rights } from "hyphen-lib/dist/business/auth/Rights";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { getAppliedFilters } from "@components/core/FilterLabels/utils";
import { Company } from "hyphen-lib/dist/domain/Company";
import { sanitizeDimensions } from "@src/utils/Dimensions";
import { RightsMatcher } from "hyphen-lib/dist/business/auth/Auth";
import { getAppSettings } from "@src/screens/Insights/Settings/store/selectors";
import { getFiltersDict } from "hyphen-lib/dist/util/net/HttpClient";

export interface SurveyReportHeaderProps {
  participation?: { completed: number; total: number };
  enabledFilters: string[];
  enabledCustomFilters?: string[];
  exportOption?: string;
  explanation?: string;
  storeMappings?: PropMapping[];
  viewOptionsComponent?: React.ReactNode;
  companyModules?: Company.Modules;
  isLinkedSurveyTrendsPresent?: boolean;
  from?: string;
  singleFieldFilter?: string;
}

export interface SurveyReportHeaderActionProps {
  readonly onModifyParameters: (parameters: Dictionary<any>, mappings?: PropMapping[]) => any;
}

interface StateProps {
  readonly surveyTypes: SurveyTypes;
  readonly dimensions: Dimensions;
  readonly currentUser: Optional<CurrentUserResource>;
  readonly rightsMatcher: RightsMatcher;
  readonly canExportSurveyTrends: boolean;
}

interface SurveyReportHeaderState {
  readonly areFiltersVisible: boolean;
  readonly areViewOptionsVisible: boolean;
  readonly isSingleFieldFilterVisible: boolean;
}

interface MatchParams {
  id: string;
  questionId?: string;
  categoryId?: string;
  topicId?: string;
  
}

type Props = SurveyReportHeaderActionProps &
SurveyReportHeaderProps &
StateProps &
RouteComponentProps<MatchParams>;

const HoverTooltip = () => (
  <CustomTooltip
    className="ant-tooltip ant-tooltip-placement-top"
  >
    <div className="ant-tooltip-content">
      <div className="ant-tooltip-arrow" />
      <div className="ant-tooltip-inner" role="tooltip">
        <div className="text-center"><Trans>Comments will be
          translated into</Trans> English</div>
      </div>
    </div>
  </CustomTooltip>
);

const checkIfCommentsTranslateEnabled = () => {
  const translateElement = document.querySelector("[data-jest='translateComment']");
  const translateButton = translateElement && translateElement.querySelector("button[role='switch']");
  return (translateButton && translateButton.getAttribute("aria-checked") === "true") ? true : false;
};

export class SurveyReportHeader extends React.Component<Props, SurveyReportHeaderState> {
  state = {
    areFiltersVisible: false,
    areViewOptionsVisible: false,
    isSingleFieldFilterVisible: false
  };

  tooltipInstance: any = null;

  handleFiltersClick = () => {
    this.setState(state => ({ areFiltersVisible: !state.areFiltersVisible }));
  };

  handleCategoriesClick = () => {
    this.setState(state => ({ isSingleFieldFilterVisible: !state.isSingleFieldFilterVisible }));
  };

  handleApplyFilters = (filter: any) => {
    const {
      onModifyParameters,
      storeMappings,
    } = this.props;

    const { areFiltersVisible, isSingleFieldFilterVisible } = this.state;

    onModifyParameters({ filter }, storeMappings);

    if(areFiltersVisible) {
      this.handleFiltersClick();
    }

    if(isSingleFieldFilterVisible) {
      this.handleCategoriesClick();
    }
  };

  handleViewOptionsClick = () => {
    this.setState(state => ({ areViewOptionsVisible: !state.areViewOptionsVisible }));
  };

  onModifyList = (filter: any) => {
    const {
      location,
      onModifyParameters,
      storeMappings,
    } = this.props;

    onModifyParameters({ filter }, storeMappings);
    const queryStringParameters = { ...parseQueryString(location.search), filter: cleanObject(filter) };
    replaceTo(
      appendQueryString(
        location.pathname,
        generateQueryString(queryStringParameters)
      )
    );
  };

  onClearFilter = (filterToRemove: string) => {
    const filter = clearFilter(filterToRemove, getFiltersDict(this.props.location.search));
    if (filterToRemove === "modules") {
      filter.surveyTypes = undefined;
    }
    this.onModifyList(filter);
  };

  onClearFilterOption = (filter: string, subFilter: string) => {
    const filterState = clearFilterOption(filter, subFilter, getFiltersDict(this.props.location.search));
    if (filter === "modules" && subFilter === "surveys") {
      filterState.surveyTypes = undefined;
    }
    this.onModifyList(filterState);
  };

  handleExportsButtonClick = () => {
    const { id, questionId , categoryId, topicId } = this.props.match.params;
    const { exportOption, location } = this.props;

    if (exportOption === "individualResults") {
      createExportSurveyIndividualResultsRequest(id, location.search);
    } else if (exportOption === "overview") {
      createExportSurveyFullOverviewReportRequest(id, "?dimensionFilters={}");
    } else if (exportOption === "comments") {
      let serachValue = location.search;
      if(isNotNullNorUndefined(topicId)) {
        const queryParams = addPropertyInDepth(
          parseQueryString(location.search),
          [topicId],
          "filter.topics"
        );

        if (isNotNullNorUndefined(categoryId)) {
          queryParams.filter.categories= [categoryId];
        }
        serachValue = `?${generateQueryString(queryParams)}`;
      }
      createExportSurveyCommentsRequest(id, questionId, serachValue, checkIfCommentsTranslateEnabled());
    } else if (exportOption === "topics") {
      createExportSurveyTopicsRequest(id, location.search);
    } else if (exportOption === "participation") {
      createExportSurveyParticipationRequest(id, location.search);
    } else if (exportOption === "questions") {
      createExportSurveyQuestionsRequest(id, location.search);
    } else if (exportOption === "heatmap") {
      createExportSurveyHeatmapRequest(id, location.search);
    }
  };

  onHoverExport = () => {
    const exportButton = document.querySelector("button[data-cy='exportButton_csv']");
    if(checkIfCommentsTranslateEnabled()) {
      if((!this.tooltipInstance || this.tooltipInstance.state.isDestroyed) && exportButton){
        // @ts-ignore
        this.tooltipInstance = tippy(exportButton, {
          placement: "top",
          content: () => ReactDOMServer.renderToString(<HoverTooltip/>).replace("ant-tooltip",""),
          allowHTML: true,
          hideOnClick: false,
          onHidden: (instance: any) => {
            instance.destroy();
          }
        });
        this.tooltipInstance.show();
      }
    }
  };

  onLeaveExport = () => {
    if (this.tooltipInstance && !this.tooltipInstance.state.isDestroyed) {
      this.tooltipInstance.hide();
      this.tooltipInstance.destroy();
      this.tooltipInstance = null;
    }
  };

  handleSurveyTrendsExportButtonClick = () => {
    const { match: {params: { id } }, location } = this.props;
    createExportSurveyTrendsOverviewRequest(id, location.search);
  };

  checkPdf = () => {
    const { location } = this.props;
    const locationString = location.pathname;
    if (locationString.includes("heatmap")
      || locationString.includes("comments")
      || locationString.includes("individualResults")
      || locationString.includes("topics")
    ) {
      return false;
    } else {
      return true;
    }
  };

  renderExportDropdown = () => {
    const {
      exportOption, currentUser, rightsMatcher, isLinkedSurveyTrendsPresent, canExportSurveyTrends,
    } = this.props;

    const checkCurrentUserCanDownloadCsv = () => {

      if (this.props.match.url.includes("overview")) {
        return isNotNullNorUndefined(currentUser)
          && rightsMatcher.hasRight(Rights.Survey.DOWNLOAD_CSV);
      }

      return true;
    };

    const menu = (
      <Menu>
        { checkCurrentUserCanDownloadCsv()
          && exportOption
          && <StyledMenuItem data-cy="export_as_csv" key="1" onClick={this.handleExportsButtonClick}>
            <Icon component={ExportSvg} />
            <Trans>Export Survey Overview as CSV</Trans>
          </StyledMenuItem>
        }

        {/* tslint:disable-next-line: jsx-no-lambda */}
        {this.checkPdf() &&  <StyledMenuItem data-cy="export_as_pdf" key="2" onClick={() => window.print()} >
          <Icon type="file-pdf"/>
          <Trans>Export Survey Overview as PDF</Trans>
        </StyledMenuItem>}
        {
          exportOption &&
          exportOption === "overview" &&
          isLinkedSurveyTrendsPresent &&
          canExportSurveyTrends && (
            <StyledMenuItem data-cy="export_as_xlsx" key="3" onClick={this.handleSurveyTrendsExportButtonClick}>
              <Icon component={ExportSvg} />
              <Trans>Export Survey Trends as XLSX</Trans>
            </StyledMenuItem>
          )
        }
      </Menu>
    );

    if (exportOption && this.checkPdf()) {
      return <Dropdown className="block--print-hide" overlay={menu}>
        <ExportButton color="gradation" data-cy="surveyReport_exportButton" translate="yes">
          Export Report
          <ImageContainer orientation={"right"}>
            <DropDownSvg />
          </ImageContainer>
        </ExportButton>
      </Dropdown>;
    } else if (checkCurrentUserCanDownloadCsv() && exportOption) {
      return <ExportButton color="gradation" onClick={this.handleExportsButtonClick} 
        data-cy="exportButton_csv" onMouseEnter={this.onHoverExport} onMouseLeave={this.onLeaveExport}>
        <Icon component={ExportSvg} />
        <span><Trans>Export CSV</Trans></span></ExportButton>;
    } else if (this.checkPdf()) {
      // tslint:disable-next-line: jsx-no-lambda
      return <ExportButton
        className="block--print-hide"
        color="gradation"
        // tslint:disable-next-line: jsx-no-lambda
        onClick={() => window.print()}
        data-cy="exportButton_PDF"
      >
        <Icon component={ExportSvg} />
        <span><Trans>Export PDF</Trans></span>
      </ExportButton>;
    }

    
  };

  render() {

    const {
      participation,
      enabledFilters,
      explanation,
      viewOptionsComponent,
      enabledCustomFilters,
      location,
      dimensions,
      surveyTypes,
      companyModules,
      from,
      singleFieldFilter
    } = this.props;

    const { areFiltersVisible, areViewOptionsVisible, isSingleFieldFilterVisible } = this.state;
    const appliedFilters = getAppliedFilters(location.search, dimensions, surveyTypes);
    const percentage = participation && participation.total ? calculatePercentage(
      participation.completed,
      participation.total
    ) : 0;
    const explanationSplit = isStringAndNotEmpty(explanation) ? explanation.split(" ") : [];
    return (
      <HeaderContainer className="block--print-show">
        <UpperContainer>
          {
            participation &&
            <ParticipationContainer>
              <Trans data-jest="participation_text">Participation</Trans>:
              <ParticipationProgress>
                <Label data-cy="participation_count">
                  {percentage}% ({participation.completed} / {participation.total})
                </Label>
                <ProgressContainer>
                  <Progress percent={percentage} strokeColor={Palette.bluePurple}/>
                </ProgressContainer>
              </ParticipationProgress>
            </ParticipationContainer>
          }
          {
            isStringAndNotEmpty(explanation) &&
            <ExplanationLabel >
              {explanationSplit[3].split("/").length === 3 ? 
              (<Trans i18nKey="dateRange" 
                values={{
                  from: explanationSplit[3],
                  to: explanationSplit[5]
                }}
                defaults={explanation}
              />):<Trans>{explanation.trim()}</Trans>} 
            </ExplanationLabel>
          }
          <ActionButtons>
            { 
              singleFieldFilter &&
              (<div className="relative-pos">
                <ExpandButton icon="filter" onClick={this.handleCategoriesClick} translate="yes">
                  {capitalizeFirstLetter(singleFieldFilter)}
                </ExpandButton>
                {
                isSingleFieldFilterVisible &&
                <>
                  <FiltersBackdrop onClick={this.handleCategoriesClick}/>
                  <CustomizationPopover open={isSingleFieldFilterVisible} noPadding={true}>
                    <FiltersContainer
                      enabledFilters={[]}
                      appliedFilters={appliedFilters}
                      onApply={this.handleApplyFilters}
                      from={from}
                      singleFieldFilter={singleFieldFilter}
                    />
                  </CustomizationPopover>
                </>
                }
              </div>) 
            }
            <ExpandButton icon="filter" onClick={this.handleFiltersClick} translate="yes">
              Filters
            </ExpandButton>
            {
              viewOptionsComponent &&
              <ExpandButton icon="viewOptions" onClick={this.handleViewOptionsClick} translate="yes">
                View options
              </ExpandButton>
            }
            {
              this.renderExportDropdown()
            }
          </ActionButtons>
          {
            enabledFilters && areFiltersVisible &&
            <>
              <FiltersBackdrop onClick={this.handleFiltersClick}/>
              <CustomizationPopover open={areFiltersVisible}>
                <FiltersContainer
                  enabledCustomFilters={enabledCustomFilters}
                  enabledFilters={enabledFilters}
                  appliedFilters={appliedFilters}
                  onApply={this.handleApplyFilters}
                  companyModules={companyModules}
                  from={from}
                />
              </CustomizationPopover>
            </>
          }
          {
            viewOptionsComponent && areViewOptionsVisible &&
            <>
              <FiltersBackdrop onClick={this.handleViewOptionsClick}/>
              <CustomizationPopover open={areViewOptionsVisible}>
                {viewOptionsComponent}
              </CustomizationPopover>
            </>
          }
        </UpperContainer>
        {
          appliedFilters.length > 0 &&
          <FilterLabelsContainer>
            <FilterLabels
              filters={appliedFilters}
              onClearFilter={this.onClearFilter}
              onClearSubfilter={this.onClearFilterOption}
            />
          </FilterLabelsContainer>
        }
      </HeaderContainer>
    );
  }
}

const mapStateToProps: MapStateToProps<StateProps, SurveyReportHeaderProps, State> = (state: State): StateProps => {
  const appSettings = getAppSettings(state).get(0);
  let canExportSurveyTrends = false;
  if (isNotNullNorUndefined(appSettings)) {
    canExportSurveyTrends = !!appSettings.canExportSurveyTrends;
  }
  return {
    surveyTypes: selectors.getSurveyTypes(state).toArray(),
    dimensions: getOr(sanitizeDimensions(getDimensions(state)), {}),
    currentUser: getCurrentUser(state),
    rightsMatcher: getRightsMatcher(state),
    canExportSurveyTrends,
  };
};

export const SurveyReportHeaderWithRouter = withRouter(SurveyReportHeader);
export default connect(
  mapStateToProps,
  {
    onModifyParameters: parametersActionCreators.modifyParameters,
  }
)(SurveyReportHeaderWithRouter);

const ExplanationLabel = styled.span`
  font-family: Lato, sans-serif;
  color: ${Palette.veryDarkBlueGrey};
  font-style: italic;
`;

const HeaderContainer = styled.div`
  background-color: white;
`;

const FilterLabelsContainer = styled.div`
  padding-left: 16px;
  padding-bottom: 8px;
`;

const UpperContainer = styled.div`
  padding: 20px 32px;
  background: ${Palette.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const ParticipationContainer = styled.div``;

const ParticipationProgress = styled.div`
  margin-top: 4px;
  width: 260px;
  display: flex;
  flex-direction: row;
`;

export const Label = styled.div`
  width: 130px !important;
  font-family: Lato, sans-serif;
  color: ${Palette.veryDarkBlueGrey};
`;

const ProgressContainer = styled.div`
  width: 130px !important;
`;

const ActionButtons = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  margin: 0 -8px;

  > * {
    margin: 0 8px;
  }

  @media print {
    display: none;
    width: 0px !important;
    min-width: 0px !important;
    max-width: 0px !important;
    height: 0px !important;
    min-height: 0px !important;
    max-height: 0px !important;
  }
`;

const ExportButton = styled(Button)`
  height: 36px !important;
  position: relative !important;
  padding-right: 36px !important;
`;

const StyledMenuItem = styled(Menu.Item)`
  height: 36px !important;
`;

const ImageContainer = styled.span<{ orientation: "left" | "right" }>`
  position: absolute !important;
  top: 9px;
  ${props => props.orientation}: 9px;
  margin-left: 10px;
`;

const CustomTooltip = styled.div`
  width: 154px;
`;
