/* tslint:disable:max-line-length */
import React from "react";
import { Omit } from "@hyphen-lib/lang/Types";
import hoistNonReactStatics from "hoist-non-react-statics";

/**
 * Curry the specified component with the specified properties.
 *
 * @param {React.ComponentType<P>} WrappedComponent
 * @param {C} curriedProps
 * @return {React.ComponentType<D>}
 */
export function curry<P extends C, C, D extends Omit<P, keyof C>>(WrappedComponent: React.ComponentType<P>,
  curriedProps: C): React.ComponentType<D> {

  class CurriedComponent extends React.Component<D> {
    render() {
      /* eslint-disable max-len */
      return (
      /*
          fixme: need to fix TS compilation issue:
          Type error: Type 'Readonly<{ children?: ReactNode; }> & Readonly<D> & C' is not assignable to type 'IntrinsicAttributes & P & { children?: ReactNode;
          }'.
            Type 'Readonly<{ children?: ReactNode; }> & Readonly<D> & C' is not assignable to type 'P'.  TS2322

               9 |     render() {
              10 |       return (
            > 11 |         <WrappedComponent
                 |          ^
              12 |           {...this.props}
              13 |           {...curriedProps}
              14 |         />
       */
      // @ts-ignore
        <WrappedComponent
          {...this.props}
          {...curriedProps}
        />
      );
      /* eslint-disable max-len */
    }
  }

  return hoistNonReactStatics(CurriedComponent, WrappedComponent);
}

/**
 * Create a new component with pre-filled properties.
 *
 * @param {React.ComponentType<I>} WrappedComponent
 * @param {P} defaultProps
 * @return {React.ComponentType<C>}
 */
export function applyDefault<I, P extends Partial<I>, C extends SubPartial<I, P>>(
  WrappedComponent: React.ComponentType<I>,
  defaultProps: P): React.ComponentType<C> {

  // tslint:disable-next-line:max-classes-per-file
  class CurriedComponent extends React.Component<C> {
    render() {
      return (
        <WrappedComponent
          {...defaultProps}
          {...this.props}
        />
      );
    }
  }

  return hoistNonReactStatics(CurriedComponent, WrappedComponent);
}

export type SubPartial<I, P extends Partial<I>> = RequiredPart<I, P> & OptionalPart<I, P>;

export type RequiredPart<I, P extends Partial<I>> = {
  [K in keyof Omit<I, keyof P>]: I[K];
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type OptionalPart<I, P extends Partial<I>> = {
  [K in keyof Partial<I>]: I[K];
};
