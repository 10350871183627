import { FSA } from "flux-standard-action";
import {
  FilterParameter,
  SortParameter,
  createRequest
} from "@src/utils/networks";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import {
  appendQueryString,
  generateQueryString
} from "hyphen-lib/dist/util/net/HttpClient";
import {
  NetworkRequestAction, 
  ActionTypes as NetworkActionTypes,
  NetworkEventRequestAction,
  NetworkEventSuccessAction,
  NetworkEventErrorAction
} from "@src/store/network/actions";
import { Optional } from "hyphen-lib/dist/lang/Optionals";

export enum PulsePollListFiltersActionTypes {
  MODIFY_FILTER = "pulsePollList/MODIFY_FILTER",
  CLEAN_FILTER = "pulsePollList/CLEAN_FILTER",
}

export enum PulsePollListModalActionTypes {
  TOGGLE_MODAL = "pulsePollList/TOGGLE_MODAL",
  CLOSE_MODAL = "pulsePollList/CLOSE_MODAL",
}

export enum PulsePollListActionTypes {
  ACTIVATE_PULSE_POLL_REQUEST = "pulsePollList/ACTIVATE_PULSE_POLL_REQUEST",
  ACTIVATE_PULSE_POLL_SUCCESS = "pulsePollList/ACTIVATE_PULSE_POLL_SUCCESS",
  ACTIVATE_PULSE_POLL_ERROR = "pulsePollList/ACTIVATE_PULSE_POLL_ERROR",

  DEACTIVATE_PULSE_POLL_REQUEST = "pulsePollList/DEACTIVATE_PULSE_POLL_REQUEST",
  DEACTIVATE_PULSE_POLL_SUCCESS = "pulsePollList/DEACTIVATE_PULSE_POLL_SUCCESS",
  DEACTIVATE_PULSE_POLL_ERROR = "pulsePollList/DEACTIVATE_PULSE_POLL_ERROR",

  DELETE_PULSE_POLL_REQUEST = "pulsePollList/DELETE_PULSE_POLL_REQUEST",
  DELETE_PULSE_POLL_SUCCESS = "pulsePollList/DELETE_PULSE_POLL_SUCCESS",
  DELETE_PULSE_POLL_ERROR = "pulsePollList/DELETE_PULSE_POLL_ERROR",

  CLOSE_PULSE_POLL_REQUEST = "pulsePollList/CLOSE_PULSE_POLL_REQUEST",
  CLOSE_PULSE_POLL_SUCCESS = "pulsePollList/CLOSE_PULSE_POLL_SUCCESS",
  CLOSE_PULSE_POLL_ERROR = "pulsePollList/CLOSE_PULSE_POLL_ERROR",
  
  CHANGE_PULSE_POLL_STATUS = "pulsePollList/CHANGE_PULSE_POLL_STATUS",
  MODIFY_LIST = "pulsePollList/MODIFY_LIST",
}

export interface ActionListModalParameters {
  readonly modalPurpose: Optional<string>;
  readonly pollTemplateId: Optional<string>;
  readonly pollInstanceId: Optional<string>;
  readonly pollStatus: Optional<string>;
  readonly iterationClosingDate: Optional<Date>;
}

export interface PulsePollStatusChangeOptionalParams {
  readonly deactivateAndClose?: boolean;
}

interface Payloads {
  [PulsePollListFiltersActionTypes.MODIFY_FILTER]: FilterParameter;
  [PulsePollListActionTypes.MODIFY_LIST]: PulsePollInfosPageParameters;
  [PulsePollListModalActionTypes.TOGGLE_MODAL]: ActionListModalParameters;
  [PulsePollListActionTypes.CHANGE_PULSE_POLL_STATUS]: PulsePollStatusChangeOptionalParams;
}

export interface ModifyFilterAction
  extends FSA<
  PulsePollListFiltersActionTypes.MODIFY_FILTER,
  Payloads[PulsePollListFiltersActionTypes.MODIFY_FILTER]
  > {
  type: PulsePollListFiltersActionTypes.MODIFY_FILTER;
  payload: Payloads[PulsePollListFiltersActionTypes.MODIFY_FILTER];
}

export interface CleanFilterAction
  extends FSA<PulsePollListFiltersActionTypes.CLEAN_FILTER, void> {
  type: PulsePollListFiltersActionTypes.CLEAN_FILTER;
}

export interface ModalToggleAction
  extends FSA<
  PulsePollListModalActionTypes.TOGGLE_MODAL,
  Payloads[PulsePollListModalActionTypes.TOGGLE_MODAL]
  > {
  type: PulsePollListModalActionTypes.TOGGLE_MODAL;
  payload: Payloads[PulsePollListModalActionTypes.TOGGLE_MODAL];
}

export interface ChangeStatusAction
  extends FSA<
  PulsePollListActionTypes.CHANGE_PULSE_POLL_STATUS,
  Payloads[PulsePollListActionTypes.CHANGE_PULSE_POLL_STATUS]
  > {
  type: PulsePollListActionTypes.CHANGE_PULSE_POLL_STATUS;
  options?: Payloads[PulsePollListActionTypes.CHANGE_PULSE_POLL_STATUS];
}

export interface PulsePollInfosPageParameters {
  readonly filter?: FilterParameter;
  readonly sort?: SortParameter;
  readonly page: PageFilter;
}

export interface ModifyListAction
  extends FSA<
  PulsePollListActionTypes.MODIFY_LIST,
  Payloads[PulsePollListActionTypes.MODIFY_LIST]
  > {
  type: PulsePollListActionTypes.MODIFY_LIST;
  payload: Payloads[PulsePollListActionTypes.MODIFY_LIST];
}

export interface ModifyListAction
  extends FSA<
  PulsePollListActionTypes.MODIFY_LIST,
  Payloads[PulsePollListActionTypes.MODIFY_LIST]
  > {
  type: PulsePollListActionTypes.MODIFY_LIST;
  payload: Payloads[PulsePollListActionTypes.MODIFY_LIST];
}

export function createPulsePollInfosPageRequest({
  filter,
  sort,
  page,
}: PulsePollInfosPageParameters): Request {
  return createRequest(
    appendQueryString(
      "/pulsePolls",
      generateQueryString({
        filter,
        sort,
        page,
      })
    )
  );
}

export function createPulsePollActivateRequest(templateId: string): Request {
  return createRequest(`/pulsePolls/${templateId}/activate`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function createPulsePollDeactivateRequest(templateId: string, closeInstance: boolean): Request {
  const uri = appendQueryString(
    `/pulsePolls/${templateId}/deactivate`,
    generateQueryString({
      closeInstance,
    })
  );
  return createRequest(uri, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function createPulsePollCloseRequest(templateId: string, instanceId: string): Request {
  return createRequest(`/pulsePolls/${templateId}/instances/${instanceId}/close`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function createPulsePollDeleteRequest(templateId: string): Request {
  return createRequest(`/pulsePolls/${templateId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export const pulsePollListActionCreators = {
  modifyList: (payload: ModifyListAction["payload"]): ModifyListAction => ({
    type: PulsePollListActionTypes.MODIFY_LIST,
    payload,
  }),
  modifyFilter: (
    payload: ModifyFilterAction["payload"]
  ): ModifyFilterAction => ({
    type: PulsePollListFiltersActionTypes.MODIFY_FILTER,
    payload,
  }),
  cleanFilter: (): CleanFilterAction => ({
    type: PulsePollListFiltersActionTypes.CLEAN_FILTER,
  }),
  toggleAreYouSureModal: (payload: ModalToggleAction["payload"]): ModalToggleAction => ({
    type: PulsePollListModalActionTypes.TOGGLE_MODAL,
    payload,
  }),
  onActivatePulsePoll: (
    templateId: string
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createPulsePollActivateRequest(templateId),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: PulsePollListActionTypes.ACTIVATE_PULSE_POLL_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: PulsePollListActionTypes.ACTIVATE_PULSE_POLL_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: PulsePollListActionTypes.ACTIVATE_PULSE_POLL_ERROR, payload }),
      ],
    },
  }),
  onDeactivatePulsePoll: (
    templateId: string,
    closeInstance: boolean
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createPulsePollDeactivateRequest(templateId, closeInstance),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: PulsePollListActionTypes.DEACTIVATE_PULSE_POLL_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: PulsePollListActionTypes.DEACTIVATE_PULSE_POLL_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: PulsePollListActionTypes.DEACTIVATE_PULSE_POLL_ERROR, payload }),
      ],
    },
  }),
  onDeletePulsePoll: (
    templateId: string
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createPulsePollDeleteRequest(templateId),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: PulsePollListActionTypes.DELETE_PULSE_POLL_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: PulsePollListActionTypes.DELETE_PULSE_POLL_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: PulsePollListActionTypes.DELETE_PULSE_POLL_ERROR, payload }),
      ],
    },
  }),
  onClosePulsePoll: (
    templateId: string,
    instanceId: string
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createPulsePollCloseRequest(templateId, instanceId),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: PulsePollListActionTypes.CLOSE_PULSE_POLL_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: PulsePollListActionTypes.CLOSE_PULSE_POLL_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: PulsePollListActionTypes.CLOSE_PULSE_POLL_ERROR, payload }),
      ],
    },
  }),
  onPulsePollStatusChange: (options?: ChangeStatusAction["options"]): ChangeStatusAction => ({
    type: PulsePollListActionTypes.CHANGE_PULSE_POLL_STATUS,
    options,
  }),
  onCloseModal: () => ({
    type: PulsePollListModalActionTypes.CLOSE_MODAL,
  }),
};
