import { call, put, takeLatest } from "@redux-saga/core/effects";
import { freeze } from "hyphen-lib/dist/lang/Objects";
import {
  actionCreators as networkActionCreators,
  CleanResourceAction,
  NetworkRequestAction
} from "@store/network/actions";
import { pushLocation, replaceLocation } from "@src/utils/locations";
import { UserResource } from "hyphen-lib/dist/domain/resource/user/UserResource";
import {
  DeleteUsersAction,
  ModifyListAction,
  userListActionCreators,
  UserListActionTypes
} from "@screens/Insights/UserManagement/containers/UserListContainer/store/actions";
import { UserPermissionResource } from "hyphen-lib/dist/domain/resource/user/UserPermissionResource";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";

export function* updateLocationUrl(replace: boolean,
  { payload: { filter, sort, page } }: ModifyListAction) {

  const locationUpdater = replace ? replaceLocation : pushLocation;
  yield call(
    locationUpdater, {
      filter,
      sort,
      page: page.number,
    });
}

export function* deleteUsers({ payload }: DeleteUsersAction) {

  yield put<NetworkRequestAction>(
    userListActionCreators.deleteUsersRequest(payload)
  );
}

export function* cleanUsersCache() {
  yield put<CleanResourceAction>(networkActionCreators.cleanResource(UserResource.TYPE));
  yield put<CleanResourceAction>(networkActionCreators.cleanResource(UserPermissionResource.TYPE));
}

export function* cleanAfterUpdatingUser() {
  yield put<CleanResourceAction>(networkActionCreators.cleanResource(CurrentUserResource.TYPE));
  yield cleanUsersCache();
}

export const userListSagas = freeze([

  // ~~~~~~~~~~~~~ URL MANAGEMENT ~~~~~~~~~~~~~~~~

  /*
    Listen for modification of list, in order to push the new state in the URL.
   */
  takeLatest(
    UserListActionTypes.MODIFY_LIST,
    updateLocationUrl.bind(null, false)
  ),

  takeLatest(
    UserListActionTypes.DELETE_USERS,
    deleteUsers
  ),

  takeLatest(
    [UserListActionTypes.DELETE_USERS_SUCCESS],
    cleanUsersCache
  ),

  takeLatest(
    UserListActionTypes.UPDATE_USER_SUCCESS,
    cleanAfterUpdatingUser
  ),
]);
