import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import {
  CompareWithMultipleOption,
  CompareWithOption,
  CompareWithSingleOption
} from "@screens/Insights/components/ViewOptions/components/CompareWith";
import {
  entries,
  getOr,
  isEmptyObject,
  isNotNullNorUndefined,
  isNullOrUndefined,
  mapOr
} from "hyphen-lib/dist/lang/Objects";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { isEmpty } from "hyphen-lib/dist/lang/Arrays";
import { HasComparisons } from "hyphen-lib/dist/domain/resource/report/common/Comparison";
import { Map as ImmutableMap, Seq, Set as ImmutableSet } from "immutable";
import { not } from "hyphen-lib/dist/lang/Booleans";

export function getAvailableComparisons(report: HasComparisons): Dictionary<boolean> {
  return getOr(report.availableComparisons, {});
}

export function generateCompareWithOptions(
  availableComparisons: Dictionary<boolean>,
  company: CompanyResource,
  isPulsePoll = false
): CompareWithOption[] {
  if (isEmptyObject(availableComparisons)) {
    return [];
  }

  return Seq(generateSingleComparisons(availableComparisons, isPulsePoll))
    .concat(generateMultipleComparisons(availableComparisons, company))
    .toArray();
}

function generateSingleComparisons(
  availableComparisons: Dictionary<boolean>,
  isPulsePoll = false
): CompareWithSingleOption[] {
  return entries(availableComparisons)
    .filter(comparison => comparison) // we want only true values
    .keySeq()
    .map(comparisonKey => {
      if (isPulsePoll && comparisonKey === "previous") {
        return SINGLE_COMPARISONS.get("previousPoll");
      }
      return SINGLE_COMPARISONS.get(comparisonKey);
    })
    .filter(isNotNullNorUndefined)
    .toArray();
}

function generateMultipleComparisons(
  availableComparisons: Dictionary<boolean>,
  company: CompanyResource
): CompareWithMultipleOption[] {
  /*
      Unlike generateSingleComparisons this method is not generic, only
      once comparison options will be generated, the benchmarks.

      So if one day, we have another multiple options comparison, we might want to change that.
      Maybe we could adapt the `availableComparisons` object to look like this:
      ```
      {
        ...
        benchmarks: {
          bench1: true,
          bench2: true,
        }
      }
      ```
      Let's wait and see...
   */

  const availableBenchmarks = entries(availableComparisons)
    .filter(comparison => comparison) // we want only true values
    .keySeq()
    .filter(comparisonKey => not(SINGLE_COMPARISONS.has(comparisonKey)))
    .toSet();

  if (availableBenchmarks.isEmpty()) {
    return [];
  }

  return mapOr(
    generateBenchmarkComparisonOption(company, availableBenchmarks),
    o => [o],
    []
  );
}

const SINGLE_COMPARISONS: ImmutableMap<string, CompareWithSingleOption> = ImmutableMap({
  average: {
    // fixme: replace by as const when migrating to TS 3.4
    // fixme: see https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-4.html (const assertion)
    type: "single" as const,
    key: "average",
    label: "Company's average",
    columnLabel: "company's average",
  },
  previous: {
    type: "single" as const,
    key: "previous",
    label: "Previous survey",
    columnLabel: "previous",
  },
  previousPoll: {
    type: "single" as const,
    key: "previous",
    label: "Previous iteration",
    columnLabel: "previous",
  },
});

function generateBenchmarkComparisonOption(
  company: CompanyResource,
  availableBenchmarks: ImmutableSet<string>): Optional<CompareWithMultipleOption> {

  if (isNullOrUndefined(company.benchmarkTargets)) {
    return Optional.empty();
  }

  const options = Seq(company.benchmarkTargets)
    .map(
      ({ key, label }) => ({ key, label, columnLabel: "benchmark" })
    )
    .filter(({ key }) => availableBenchmarks.has(key))
    .toArray();

  if (isEmpty(options)) {
    return Optional.empty();
  }

  const defaultValue =
    isNotNullNorUndefined(company.benchmarkDefault) ?
      company.benchmarkDefault :
      options[0].key;

  return {
    type: "multiple",
    key: "benchmark",
    label: "Benchmark",
    defaultValue,
    options,
  };
}

export function getMatchingOptionLabel(options: CompareWithOption[],
  comparisonKey: Optional<string>): Optional<string> {

  if (isNullOrUndefined(comparisonKey)) {
    return Optional.empty();
  }
  for (const option of options) {
    if (option.type === "single" && option.key === comparisonKey) {
      return getOr(option.columnLabel, option.label);
    } else if (option.type === "multiple") {
      for (const o of option.options) {
        if (o.key === comparisonKey) {
          return getOr(o.columnLabel, o.label);
        }
      }
    }
  }
  return Optional.empty();
}
