// @ts-nocheck
import React from "react";
export function UsersSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="16"
      viewBox="0 0 15 16"
    >
      <defs>
        {/* eslint-disable max-len */}
        <path id="usersA" d="M7.17 7.65c-1.507 0-2.73-1.294-2.73-2.89v-.68c0-1.596 1.223-2.89 2.73-2.89S9.9 2.484 9.9 4.08v.68c0 1.596-1.223 2.89-2.73 2.89zm0 1.04c2.083 0 3.77-1.76 3.77-3.93v-.68c0-2.17-1.687-3.93-3.77-3.93S3.4 1.91 3.4 4.08v.68c0 2.17 1.687 3.93 3.77 3.93zm7.02 4.554c0-1.463-.934-2.744-2.284-3.13-1.684-.481-3.28-.737-4.736-.737-1.455 0-3.053.256-4.736.738C1.084 10.5.15 11.782.15 13.244v1.426c0 .287.233.52.52.52h13a.52.52 0 0 0 .52-.52v-1.426zM13.15 14l-.15.15H1.34L1.19 14v-.756c0-.998.627-1.872 1.53-2.13 1.588-.454 3.096-.697 4.45-.697s2.862.243 4.45.697c.903.258 1.53 1.132 1.53 2.13V14z"/>
        {/* eslint-disable max-len */}
      </defs>
      <use fill="#AAB9CE" fillRule="nonzero" xlinkHref="#usersA"/>
    </svg>

  );
}
