import React from "react";
import styled from "styled-components";
import qs from "qs";
import { Trans } from "react-i18next";
import { getOr } from "hyphen-lib/dist/lang/Objects";
import { CommentHighlightedCategory } from "@hyphen-lib/domain/resource/report/common/Highlights";
import { Sentiment } from "@hyphen-lib/domain/common/ComputationTypes";
import { Optional } from "@hyphen-lib/lang/Optionals";
import Palette from "@src/config/theme/palette";
import Button from "@components/core/Button";
import { Heading } from "@components/core/Typography";
import { SentimentDonutChart, SentimentDonutChartData } from "@components/core/SentimentDonutChart";
import { countPercentages } from "@screens/Insights/Survey/components/CommentsSentimentChart";
import { Breadcrumb, goTo } from "@src/utils/locations";
import { Tooltip, Icon, Empty } from "antd";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import {SentimentWithComments} from "@screens/Insights/components/SentimentWithComments";


export interface CommentsData {
  numberOfComments: number;
  netSentimentScore: Optional<number>;
  score?: Optional<number>;
  commentCategories: CommentHighlightedCategory[];
}

interface Props {
  readonly sectionName?: string;
  readonly data: CommentsData;
  readonly isDashboard?: boolean;
  readonly surveyId?: Optional<string>;
  readonly hasCommentsAccess: boolean;
  readonly postAndSurveySentiment?: boolean;
}

export function getCategoryData (
  { category, sentiments }: CommentHighlightedCategory
): SentimentDonutChartData {
  const sentimentMap: { [key: string]: any } = {
    [Sentiment.POSITIVE]: { color: Palette.aquaBlue, text: "Positive" },
    [Sentiment.NEUTRAL]: { color: Palette.lightPeriwinkle, text: "Neutral" },
    [Sentiment.NEGATIVE]: { color: Palette.darkPink, text: "Negative" },
  };
  const sentimentsList = [];
  const percentages = countPercentages(sentiments);

  for (const sentimentKey in sentiments) {
    if (sentiments.hasOwnProperty(sentimentKey)) {
      const sentiment: number = sentiments[sentimentKey as unknown as Sentiment];
      sentimentsList.push({
        ...sentimentMap[sentimentKey],
        value: sentiment,
        percentage: percentages[sentimentKey],
      });
    }
  }

  return {
    name: category,
    sentiments: sentimentsList,
  };
}

function Comments({ 
  data, 
  isDashboard=false, 
  surveyId, 
  sectionName, 
  hasCommentsAccess, 
  postAndSurveySentiment 
}: Props) {
  function onViewComments() {
    if (isNotNullNorUndefined(sectionName)) {
      goTo(`/surveys/view/${surveyId}/reports/comments`, Breadcrumb.stack(sectionName));
    } else {
      goTo(`/surveys/view/${surveyId}/reports/comments`);
    }
  }

  function goToComments(category: string) {
    const filter = { categories: [encodeURIComponent(category)] };

    const queryParams = qs.stringify({ filter }, { encode: false });
    const path = `/surveys/view/${surveyId}/reports/comments?${queryParams}`;
    if (isNotNullNorUndefined(sectionName)) {
      goTo(path, Breadcrumb.stack(sectionName));
    } else {
      goTo(path);
    }
  }

  function renderCount() {
    return <Summary data-cy="overview_commentsCount">{data.numberOfComments} <Trans>comments</Trans></Summary>;
  }

  function renderCommentsSection() {
    return (
      <SectionContainer>
        { getOr(postAndSurveySentiment, true) && (<div>
          <Heading size="small" weight="bold" translate="yes">
            Sentiment
            <Tooltip title={<Trans>Based on percentage of positive 
              comments minus percentage of negative comments</Trans>}>
              <StyledInfoIcon className="block--print-hide" type="info-circle" data-cy="comment_sentimentTootltip"/>
            </Tooltip>
          </Heading>
          <SentimentWithComments
            hasComments={false}
            netSentimentScore={isDashboard ? data.score! : data.netSentimentScore}
            sentimentScoreTextSize="large"
          />
        </div>
        )}
        {
          isNotEmptyArray(data.commentCategories) && (
            <CategoriesContainer>
              <Heading size="small" weight="bold" translate="yes">
                Popular categories
              </Heading>
              <Categories>
                {data.commentCategories.map((category: any) => (
                  !isDashboard && hasCommentsAccess ? (
                    <SentimentDonutChart
                      data-cy={category.category}
                      onClick={goToComments.bind(null, category.category)}
                      key={category.category}
                      data={getCategoryData(category)}
                    />
                  ) : <StyledSentimentDonutChart key={category.category} data={getCategoryData(category)}/>
                ))}
              </Categories>
            </CategoriesContainer>
          )
        }
      </SectionContainer>
    );
  }

  return (
    <Container>
      {!isDashboard && hasCommentsAccess &&
        <StyledLink onClick={onViewComments}>
          <ViewCommentsReportButton
            className="block--print-hide"
            data-cy="surveyReport_viewCommentsReportButton"
            color="grey"
            translate="yes">View Comments Report</ViewCommentsReportButton>
        </StyledLink>
      }

      <Heading size="large">
        <Trans>Comments</Trans>
      </Heading>
      {
        data.numberOfComments > 0 &&
        renderCount()
      }
      {
        data.numberOfComments > 0 ?
          renderCommentsSection() :
          <EmptyContainer>
            <Empty description={<Trans>Not enough data</Trans>} data-cy="commentSection_noData"/>
          </EmptyContainer>
      }
    </Container>
  );
}

const StyledLink = styled.span`
`;

const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Container = styled.div`
  padding: 32px;
  background: ${Palette.white};
  font-family: Lato, sans-serif;
  color: ${Palette.veryDarkBlueGrey};
`;

const StyledInfoIcon = styled(Icon)`
  font-size: 16px;
  color: ${Palette.lightGreyBlue};
  margin-left: 8px;
`;

const Summary = styled.div`
  margin-top: 8px;
  color: ${Palette.bluishGrey};
`;

const ViewCommentsReportButton = styled(Button)`
  float: right;
`;

const SectionContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
`;

const CategoriesContainer = styled.div`
  margin-left: 24px;
`;

const Categories = styled.div`
  margin: 16px -12px 0;
  display: flex;
  justify-content: space-evenly;

  > * {
    margin: 0 12px;
  }
`;

const StyledSentimentDonutChart = styled(SentimentDonutChart)`
  :hover{
    cursor:default;
  }
`;

export default Comments;
