import React from "react";
import styled from "styled-components";
import { getOr, isNotNullNorUndefined } from "@hyphen-lib/lang/Objects";
import Palette from "../../../config/theme/palette";

export interface SidebarIconProps {
  children: React.ReactNode;
  active?: boolean;
  className?: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export function SidebarIcon({
  children,
  active,
  className,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: SidebarIconProps) {
  return (
    <StyledSidebarIcon className={className}
      active={getOr(active, false)}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-cy="sideBarExpandCollapseButton">
      {children}
    </StyledSidebarIcon>
  );
}

const StyledSidebarIcon = styled.div<SidebarIconProps>`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.active ? `background-color: ${Palette.aquaBlue};` : ""}
  use, svg > g > g {
    fill: ${props => props.active ? "#fff" : Palette.lightGreyBlue};
  }
  svg > g, svg > path {
    stroke: ${props => props.active ? "#fff" : Palette.lightGreyBlue};
  }
  cursor: ${props => isNotNullNorUndefined(props.onClick) ? "pointer" : "inherit"};
`;
