import React from "react";
import ContainerCard from "@components/core/ContainerCard";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { Progress } from "antd";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";

export default class AddUsersSuccess extends React.Component {
  render() {
    return (
      <>
        <CustomContainerCard>
          <FileUploadContainer>
            <CheckedMarkDiv>
              <Progress percent={100} strokeColor={Palette.greenBlue} width={40} type="circle" strokeWidth={10} />
            </CheckedMarkDiv>
            <FileUploadDiv data-cy="fileUploaded"><Trans>File uploaded</Trans></FileUploadDiv>
          </FileUploadContainer>
          <HasBeenImportedDiv>
            <Trans>The CSV has been imported successfully.
              We are currently loading the data into our system and will send you an email confirming
              the full import.</Trans>
          </HasBeenImportedDiv>
        </CustomContainerCard>
        <ButtonContainer>
          <Link to="/users"><Button><Trans>Finish</Trans></Button></Link>
        </ButtonContainer>
      </>
    );
  }
}

const CustomContainerCard = styled(ContainerCard)`
  height: 319px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const FileUploadContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 23px;
`;

const CheckedMarkDiv = styled.div`
  margin-right: 10px;
`;

const FileUploadDiv = styled.div`
  font-size: 24px;
  color: ${Palette.darkBlueGrey};
`;

const HasBeenImportedDiv = styled.div`
  width: 601px;
  text-align: center;
`;

const ButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.button`
  width: 132px;
  height: 40px;
  font-weight: bold;
  color: white;
  border-radius: 2px;
  border: solid 1px #00b8cc;
  background-color: ${Palette.aquaBlue};
  cursor: pointer;
`;