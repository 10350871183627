import { FSA } from "flux-standard-action";
import { createRequest } from "@src/utils/networks";
import {
  ActionTypes as NetworkActionTypes,
  NetworkRequestAction,
  NetworkEventRequestAction,
  NetworkEventSuccessAction,
  NetworkEventErrorAction
} from "@src/store/network/actions";
import { CompanyRoleResource } from "hyphen-lib/dist/domain/resource/CompanyRoleResource";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { CompanyRoleResourceMap, RoleModals } from "./reducers";

export enum UserRolesActionTypes {
  SELECT_ROLE = "userRoles/SELECT_ROLE",
  SAVE_ROLE = "userRoles/SAVE_ROLE",

  UPDATE_SELECTED_ROLE = "userRoles/UPDATE_SELECTED_ROLE",
  ADD_OR_REMOVE_ROLES = "userRoles/ADD_OR_REMOVE_ROLES",
  TOGGLE_MODAL_VISIBILITY = "userRoles/TOGGLE_MODAL_VISIBILITY",

  FETCH_COMPANY_ROLES = "userRoles/FETCH_COMPANY_ROLES",
  
  FETCH_COMPANY_ROLES_REQUEST = "userRoles/FETCH_COMPANY_ROLES_REQUEST",
  FETCH_COMPANY_ROLES_SUCCESS = "userRoles/FETCH_COMPANY_ROLES_SUCCESS",
  FETCH_COMPANY_ROLES_ERROR = "userRoles/FETCH_COMPANY_ROLES_ERROR",

  UPDATE_ROLES_REQUEST = "userRoles/UPDATE_ROLES_REQUEST",
  UPDATE_ROLES_SUCCESS = "userRoles/UPDATE_ROLES_SUCCESS",
  UPDATE_ROLES_ERROR = "userRoles/UPDATE_ROLES_ERROR",
  UPDATE_IS_ROLE_UPDATED = "userRoles/UPDATE_IS_ROLE_UPDATED",
}

interface UpdateRolePayload {
  key: keyof CompanyRoleResource;
  value: any;
}

interface AddOrRemoveRolePayload {
  role: string;
  action: "add" | "remove";
}

export interface ModalPayload {
  modalType: keyof RoleModals;
  isModalVisible: boolean;
}

interface Payloads {
  [UserRolesActionTypes.SELECT_ROLE]: CompanyRoleResourceMap;
  [UserRolesActionTypes.UPDATE_SELECTED_ROLE]: UpdateRolePayload;
  [UserRolesActionTypes.ADD_OR_REMOVE_ROLES]: AddOrRemoveRolePayload;
  [UserRolesActionTypes.TOGGLE_MODAL_VISIBILITY]: ModalPayload;
}

export interface SelectRoleAction 
  extends FSA<UserRolesActionTypes.SELECT_ROLE, Payloads[UserRolesActionTypes.SELECT_ROLE]> {
  type: UserRolesActionTypes.SELECT_ROLE;
  payload: Payloads[UserRolesActionTypes.SELECT_ROLE];
}

export interface UpdateSelectedRoleAction
  extends FSA<UserRolesActionTypes.UPDATE_SELECTED_ROLE, Payloads[UserRolesActionTypes.UPDATE_SELECTED_ROLE]> {
  type: UserRolesActionTypes.UPDATE_SELECTED_ROLE;
  payload: Payloads[UserRolesActionTypes.UPDATE_SELECTED_ROLE];
}

export interface AddOrRemoveRoleAction
  extends FSA<UserRolesActionTypes.ADD_OR_REMOVE_ROLES, Payloads[UserRolesActionTypes.ADD_OR_REMOVE_ROLES]> {
  type: UserRolesActionTypes.ADD_OR_REMOVE_ROLES;
  payload: Payloads[UserRolesActionTypes.ADD_OR_REMOVE_ROLES];
}

function createGetRolesRequest(companyName: string) {
  return createRequest(`/companies/${companyName}/roles`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function createUpdateRolesRequest(companyName: string, payload: CompanyRoleResource[]) {
  return createRequest(`/companies/${companyName}/roles`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
}

export const userRolesActionCreators = {
  selectRole: (role: SelectRoleAction["payload"]) => ({
    type: UserRolesActionTypes.SELECT_ROLE,
    role,
  }),

  fetchCompanyRoles: (companyName: string): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createGetRolesRequest(companyName),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: UserRolesActionTypes.FETCH_COMPANY_ROLES_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: UserRolesActionTypes.FETCH_COMPANY_ROLES_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: UserRolesActionTypes.FETCH_COMPANY_ROLES_ERROR, payload }),
      ],
    },
  }),

  updateCompanyRolesRequest: (
    companyName: string,
    rolesPayload: CompanyRoleResource[],
    options?: Dictionary<any>
  ) => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createUpdateRolesRequest(companyName, rolesPayload),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: UserRolesActionTypes.UPDATE_ROLES_REQUEST, payload, meta: options }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: UserRolesActionTypes.UPDATE_ROLES_SUCCESS, payload, meta: options }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: UserRolesActionTypes.UPDATE_ROLES_ERROR, payload }),
      ],
    },
  }),

  getCompanyRoles: () => ({
    type: UserRolesActionTypes.FETCH_COMPANY_ROLES,
  }),
  updateCompanySelectedRole: (payload: UpdateSelectedRoleAction["payload"]) => ({
    type: UserRolesActionTypes.UPDATE_SELECTED_ROLE,
    payload,
  }),
  saveCompanyRole: () => ({
    type: UserRolesActionTypes.SAVE_ROLE,
  }),
  addOrRemoveRoles: (payload: AddOrRemoveRoleAction["payload"]) => ({
    type: UserRolesActionTypes.ADD_OR_REMOVE_ROLES,
    payload,
  }),
  onModalVisibilityToggle: (payload: ModalPayload) => ({
    type: UserRolesActionTypes.TOGGLE_MODAL_VISIBILITY,
    payload,
  }),
  onRoleUpdated: (payload : boolean) => ({
    type: UserRolesActionTypes.UPDATE_IS_ROLE_UPDATED,
    payload,
  }),
};
