import { Rights } from "hyphen-lib/dist/business/auth/Rights";

export const rightsLabelMapper = {
  general: {
    insights: [{
      title: "Engage Insights access",
      // eslint-disable-next-line max-len
      description: "Allow user access to the Engage Insights platform. Specific access for the role can be configured in the following steps.",
      right: Rights.Insights.ACCESS,
    }, {
      title: "View the Dashboard",
      description: "Allow user to see the aggregated overview reports",
      right: Rights.Dashboard.ACCESS,
    }],
    admin: {
      all: {
        title: "All",
        right: Rights.ADMIN.ALL,
      },
      userManagement: [{
        title: "Access Users admin page",
        description: "Allow user view access to the list of all users",
        right: Rights.User.ADMINISTRATE,
      }, {
        title: "Add users to Betterworks Engage via CSV upload",
        description: "Allow user to upload new users via CSV",
        right: Rights.User.ADD_USERS_CSV,
      }, {
        title: "Add users to Betterworks Engage manually",
        description: "Allow user to add new users via email",
        right: Rights.User.ADD_USERS_MANUALLY,
      }, {
        title: "Edit users",
        description: "Allow user to edit other users",
        right: Rights.User.EDIT_USERS,
      }, {
        title: "Delete users",
        description: "Allow user to delete other users",
        right: Rights.User.DELETE_USERS,
      }, {
        title: "Manage roles",
        description: "Allow user to edit the Roles & Permissions",
        right: Rights.User.USERS_ROLES,
      }, {
        title: "Change company settings",
        description: "Allow user to edit company-wide settings",
        right: Rights.Company.ADMINISTRATE,
      }],
    },
  },
  surveys: {
    insights: {
      all: {
        title: "Read All Data",
        description: "Allow user access to read all surveys' data",
        right: Rights.Survey.READ_ALL,
      },
      surveys: [{
        title: "View surveys list",
        description: "",
        right: Rights.Survey.INSIGHTS_SURVEYS_LIST,
      }],
      surveyReports: {
        all: {
          title: "All Reports",
          right: Rights.Survey.INSIGHTS_REPORTS_ALL,
        },
        rights: [{
          title: "Overview",
          right: Rights.Survey.OVERVIEW,
        }, {
          title: "Heatmap",
          right: Rights.Survey.HEATMAP,
        }, {
          title: "Categories",
          right: Rights.Survey.CATEGORY_REPORT,
        }, {
          title: "Questions",
          right: Rights.Survey.QUESTIONS,
        }, {
          title: "Topics",
          right: Rights.Survey.TOPICS,
        }, {
          title: "Comments",
          right: Rights.Survey.COMMENTS,
        }, {
          title: "Individual Results",
          right: Rights.Survey.INDIVIDUAL,
        }, {
          title: "Participation",
          right: Rights.Survey.PARTICIPATION,
        }],
      },
      messages: [{
        title: "Send private messages",
        description: "Allow user to send anonymous private messages to respondents for all Surveys",
        right: Rights.Survey.CONVERSATION_ALL,
      }],
    },
    admin: {
      all: {
        title: "Admin Access",
        description: "Allow user to administer all Surveys",
        right: Rights.Survey.ADMIN_ALL,
      },
      rights: [{
        title: "Create Surveys",
        description: "Allow user to create and publish new Surveys",
        right: Rights.Survey.CREATE,
      }],
    },
  },
  pulsePolls: {
    employeeApps: [{
      title: "View and answer polls",
      description: "Allow user to answer polls in the web app and mobile app",
      right: Rights.PulsePoll.VOICE_VIEW,
    },
    {
      title: "Post anonymously",
      // eslint-disable max-len
      description: "Allow user to add a voice post anonymously in the web app and mobile app",
      right: Rights.PulsePoll.VOICE_IDENTITY_ANONYMOUS,
    },
    {
      title: "Post with your own name",
      description: "Allow user to add a voice post with their own name in the web app and mobile app",
      right: Rights.PulsePoll.VOICE_IDENTITY_NAMED,
    },
    {
      title: "Post with official tag",
      description: "Allow user to add a voice post as official in the web app and mobile app",
      right: Rights.PulsePoll.VOICE_IDENTITY_OFFICIAL,
    }],
    insights: {
      all: {
        title: "Read All Data",
        description: "Allow user access to read all polls' data",
        right: Rights.PulsePoll.INSIGHTS_PULSE_POLL_ALL,
      },
      pulsePolls: [{
        title: "View polls list",
        description: "",
        right: Rights.PulsePoll.LIST,
      }],
      pulsePollReports: {
        all: {
          title: "All Reports",
          right: Rights.PulsePoll.INSIGHTS_REPORTS_ALL,
        },
        rights: [{
          title: "Overview",
          right: Rights.PulsePoll.OVERVIEW,
        }, {
          title: "Comments",
          right: Rights.PulsePoll.COMMENTS,
        }],
      },
      messages: [{
        title: "Send private messages",
        description: "Allow user to send anonymous private messages to respondents for all Polls",
        right: Rights.PulsePoll.CONVERSATION_ALL,
      }],
    },
    admin: {
      all: {
        title: "Admin Access",
        description: "Allow user to administer all polls",
        right: Rights.PulsePoll.ADMIN_ALL,
      },
      rights: [{
        title: "Create Polls",
        description: "Allow user to create and publish new Polls",
        right: Rights.PulsePoll.CREATE,
      }],
    },
  },
  actionPlans: {
    insights: {
      all: {
        title: "All",
        right: Rights.Action.ALL,
      },
      rights: [{
        title: "View Action Plans",
        description: "Allow user to view Action Plans assigned to others within their scope.",
        right: Rights.Action.VIEW_ALL,
      }, {
        title: "Create, edit/delete Action Plans",
        // eslint-disable-next-line max-len
        description: "Allow user to create, edit, delete and assign Action Plans to themselves or others within their scope.",
        right: Rights.Action.CREATE,
      }, {
        title: "Allow user to add and edit Action Plan templates",
        description: "This permission will allow the user access to the ‘Manage Suggestions’",
        right: Rights.Action.EDIT_TEMPLATE,
      }, {
        title: "Allow user to edit Action Plan notification and email settings",
        // eslint-disable-next-line max-len
        description: "This permission will allow the user access to the ‘Notification Settings’ and ‘Digest Emails’",
        right: Rights.Action.EDIT_NOTIFICATION,
      }],
    },
  },
  voice: {
    employeeApps: {
      all: {
        title: "All: All voice capabilities & actions",
        description: "Allow user with all voice access in the web app and mobile app",
        right: Rights.Voice.VOICE_ALL,
      },
      posts: {
        rights: [
          {
            title: "View voice posts",
            description: "Allow user to view voice posts in the web app and mobile app",
            right: Rights.Voice.VOICE_READ_ALL,
          },
          {
            title: "Add a voice post",
            description: "Allow user to add voice posts in the web app and mobile app",
            right: Rights.Voice.VOICE_CREATE,
          },
          {
            title: "Add a voice post in #All",
            /* eslint-disable max-len */
            description: "Allow user to add a voice post to entire company audience (All users) in the web app and mobile app",
            right: Rights.Voice.VOICE_ALL_ADD,
          },
          {
            title: "Close a post",
            description: "Allow user to close a voice post in the web app and mobile app",
            right: Rights.Voice.VOICE_CLOSE,
          },
          {
            title: "Add a multiple-choice question",
            description: "Allow user to add a multiple-choice question as a post in the web app and mobile app",
            right: Rights.Voice.VOICE_CREATE_MCQ,
          },
          {
            title: "Add media to post or comment",
            description: "Allow user to add a voice post or comment with media (photos & videos) in the web app and mobile app",
            right: Rights.Voice.VOICE_ADD_MEDIA,
          },
          {
            title: "Post anonymously",
            /* eslint-disable max-len */
            description: "Allow user to add a voice post anonymously in the web app and mobile app",
            right: Rights.Voice.VOICE_IDENTITY_ANONYMOUS,
          },
          {
            title: "Post with your own name",
            description: "Allow user to add a voice post with their own name in the web app and mobile app",
            right: Rights.Voice.VOICE_IDENTITY_NAMED,
          },
          {
            title: "Post with official tag",
            description: "Allow user to add a voice post as official in the web app and mobile app",
            right: Rights.Voice.VOICE_IDENTITY_OFFICIAL,
          },
        ],
      },
      groups: {
        title: "Groups",
        rights: [
          {
            title: "Private group: Create",
            description: "Allow user to create a private group from the web app and mobile app",
            right: Rights.Groups.VOICE_GROUP_CREATE,
          },
          {
            title: "Private group: Post",
            description: "Allow user to add a voice post in a private group from the web app and mobile app",
            right: Rights.Groups.VOICE_PRIVATE_GROUP_ADD_POST,
          },
          {
            title: "Private group: Invite a user",
            description: "Allow user to invite other users to a private group from the web app and mobile app",
            right: Rights.Groups.VOICE_PRIVATE_GROUP_INVITE_OTHERS,
          },
        ],
      },
      channels: {
        title: "Channels",
        rights: [
          {
            title: "Public channel: Create",
            description: "Allow user to create a public channel from the web app and mobile app",
            right: Rights.Groups.VOICE_CHANNEL_CREATE,
          },
          {
            title: "Public channel: Post",
            description: "Allow user to add a voice post in a public channel from the web app and mobile app",
            right: Rights.Groups.VOICE_CHANNEL_ADD_POST,
          },
          {
            title: "Public channel: Invite a user",
            description: "Allow user to invite other users to a public channel from the web app and mobile app",
            right: Rights.Groups.VOICE_CHANNEL_INVITE_OTHERS,
          },
        ],
      },
    },
    insights: {
      voiceReports: {
        all: {
          title: "View All Voice Reports",
          description: "Allow user to view all voice related reports on engage insights portal",
          right: Rights.Voice.INSIGHTS_VOICE_REPORTS,
        },
        rights: [
          {
            title: "Posts Reports",
            description: "Allow user to view voice posts report on engage insights portal",
            right: Rights.Voice.POSTS,
          },
          {
            title: "Comments Reports",
            description: "Allow user to view voice comments report on engage insights portal",
            right: Rights.Voice.COMMENTS,
          },
          {
            title: "Flagged Posts Reports",
            description: "Allow user to view flagged voice posts report on engage insights portal",
            right: Rights.Voice.FLAGGED_POSTS,
          },
          {
            title: "Unflag and Archive Posts",
            description: "Allow users to unflag and archive posts in flagged post report",
            right: Rights.Voice.FLAGGED_POSTS_ACTIONS,
          },
          {
            title: "Flagged Comments Reports",
            description: "Allow user to view flagged voice comments report on engage insights portal",
            right: Rights.Voice.FLAGGED_COMMENTS,
          },
          {
            title: "Unflag and Archive Comments",
            description: "Allow users to unflag and archive comments in flagged comments report",
            right: Rights.Voice.FLAGGED_COMMENTS_ACTIONS,
          },
        ],
      },
    },
  },
  lifecycle: {
    insights: {
      lifecycleReports: {
        all: {
          title: "View All Lifecycle Reports",
          description: "Allow user to view all Lifecycle related reports on engage insights portal",
          right: Rights.LCADashboard.ACCESS,
        },
      },
    },
  },
};
