import React from "react";
import styled from "styled-components";
import { Icon, Row } from "antd";
import { RowProps } from "antd/lib/grid";
import Palette from "@src/config/theme/palette";
import { Trans } from "react-i18next";

export function CollapsedRecipientsForm({ onExpand }: { onExpand: () => any }) {
  return (
    <>
      <RecipientsCollapsed onClick={onExpand}>
        <Icon data-cy="collapsedRecipientsForm_addRecipientsByEmail" type="plus-circle"/> 
        <span>&emsp;<Trans>ADD RECIPIENTS BY EMAIL</Trans></span>
      </RecipientsCollapsed>
    </>
  );
}

const RecipientsCollapsed = styled(Row) <RowProps>`
  background-color: ${Palette.paleGrey};
  text-align: center;
  border-style: dashed;
  border-color: #92a0b3;
  padding: 16px 32px;
  font-size: 16px;
  color: #92a0b3;
  cursor: pointer;
  margin: 24px 0px;
`;
