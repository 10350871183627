import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { State } from "@store/types";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { isNotNullNorUndefined, isNullOrUndefined, isEmptyObject } from "hyphen-lib/dist/lang/Objects";
import { isNullOrEmpty } from "hyphen-lib/dist/lang/Strings";
import notificationActionCreators from "@src/store/notifications/actions";
import { getCurrentUser } from "../../store/selectors";
import { getCompany } from "../store/selectors";
import { settingsActionCreators } from "../store/actions";
import CompanySettingsBusiness from "../components/CompanySettingsBusiness";
import CompanySettingsGeneral from "../components/CompanySettingsGeneral";
import { withTranslation } from "react-i18next";

interface ReducerProps {
  company: CompanyResource;
  companyName: CompanyResource["name"];
}

export type CompanySettingsProps = typeof settingsActionCreators & typeof notificationActionCreators & ReducerProps;

export class CompanySettings extends React.Component<CompanySettingsProps> {
  componentWillMount () {
    const {company, companyName, fetchCompany} = this.props;
    if (isEmptyObject(company)) {
      fetchCompany(companyName);
    }
  }

  render () {
    const {updateCompany, company, uploadCompanyLogo, displayNotification, uploadDarkLogo} = this.props;

    if (isNullOrEmpty(company.name)) { return null; }

    return (
      <>
        <CompanySettingsGeneral
          displayNotification={displayNotification}
          uploadCompanyLogo={uploadCompanyLogo}
          uploadDarkLogo={uploadDarkLogo}
          company={company}
          updateCompany={updateCompany}
        />
        <Spacer />
        <CompanySettingsBusiness
          company={company}
          updateCompany={updateCompany}
        />
      </>
    );
  }
}

const Spacer = styled.div`
  height: 24px;
`;

const mapStateToProps = (state: State): ReducerProps => {
  let company = getCompany(state).get(0);
  if (isNullOrUndefined(company)) {
    company = {} as CompanyResource;
  }

  let companyName = "";
  const currentUser = getCurrentUser(state);
  if (isNotNullNorUndefined(currentUser) &&
    isNotNullNorUndefined(currentUser.company) &&
    isNotNullNorUndefined(currentUser.company.name)) {
    companyName = currentUser.company.name;
  }
  return {
    company,
    companyName,
  };
};

export default connect(mapStateToProps, {...settingsActionCreators, ...notificationActionCreators})
(withTranslation()(CompanySettings));
