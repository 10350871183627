import styled from "styled-components";
import { d3List, initBubbblePack } from "@src/utils/Graphs";
import { SentimentDonutChart, Chart } from "@components/core/SentimentDonutChart";
import { useEffect } from "react";
import { fontSizeConverter } from "@src/utils/Graphs";
import { getProperty } from "hyphen-lib/dist/lang/Objects";
import { SentimentDonutTooltip } from "@src/components/core/SentimentDonutChart/components/SentimentDonutTooltip";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import * as d3 from "d3-selection";
interface Props {
  readonly topics: any[];
  readonly id: string;
  readonly minMaxThreshold: Record<string,number>;
  readonly width: number;
  readonly surveyId: string;
  readonly sectionName: string;
  readonly questionId?: string;
  readonly categoryId?: string;
}

function renderGraph(props: Props) {
  const sentiments: d3List = {
    children:[]
  };
  const { topics, minMaxThreshold, id, width, surveyId, sectionName , questionId, categoryId} = props;
  topics.forEach((sentiment) => {
    sentiment.size = fontSizeConverter(sentiment.count, minMaxThreshold.min, minMaxThreshold.max, 
      minMaxThreshold.minSize, minMaxThreshold.maxSize);
    const sentimentChart = (
        <SentimentDonutChart
        data-cy={sentiment.name}
        // onClick={goToComments.bind(null, topic.topic)}
        key={sentiment.name}
        data={sentiment.data}
        size={sentiment.size}
        count={sentiment.count}
        hideTooltip={true}
        />
    );
    const sentimentChartTooltip = (
      <SentimentDonutTooltip data={sentiment.data}/>
    );
    sentiments.children.push({
      name: sentiment.name,
      value: sentiment.size,
      key: `random_${Math.floor(Math.random()*(999-100+1)+100)}`,
      content: sentimentChart,
      tooltip: sentimentChartTooltip,
      surveyId,
      sectionName,
      questionId,
      categoryId,
      sentiments: getProperty(sentiment, "data.sentiments")
    });
  });
  initBubbblePack(sentiments, document.getElementById(id), width);
}

function SentimentBubble(props: Props) {

  const { topics, id } = props;
  
  useEffect(() => {
    if(isNotEmptyArray(topics)) {
      renderGraph(props);
    } else {
      d3.select(document.getElementById(id)).selectAll("*").remove();
    }
  }, [topics]);


  return (
    <Topic id={props.id} className="chart" />
  );
}

const Topic = styled.div`
  width: calc(100% / 3);
  min-height: 500px;
  ${Chart} {
    @media print {
      display: block;
    }
  }
`;

export default SentimentBubble;

