import React from "react";
import { Trans } from "react-i18next";
import { isNotEmptyArray } from "@hyphen-lib/lang/Arrays";
import styled from "styled-components";
import { getOr, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import ButtonGroup, { ButtonGroupProps } from "antd/lib/button/button-group";
import Button, { ButtonProps } from "@components/core/Button";
import Palette from "@src/config/theme/palette";
import {
  DropDownButton,
  DropDownButtonProps
} from "@src/components/core/DropDownButton";
import Tooltip from "@src/components/core/Tooltip";
import { Optional } from "hyphen-lib/dist/lang/Optionals";

interface BaseActionKeyTypes {
  seeReport: "seeReport";
  edit: "edit";
  delete: "delete";
  close: "close";
}

export interface PulsePollActionKeyType extends BaseActionKeyTypes {
  deactivate: "deactivate";
  reactivate: "reactivate";
  deactivateAndclose: "deactivate&close";
  closeAndDeactivateFrequencyOncePoll: "closeAndDeactivateFrequencyOncePoll";
}

export interface SurveyActionKeyType extends BaseActionKeyTypes {
  sendReminder: "sendReminder";
  duplicate: "duplicate";
}

export type ActionKeyType<T> = keyof T;

export interface Action<T> {
  readonly key: ActionKeyType<T>;
  readonly label: string;
  readonly buttonProps?: ButtonProps;
  readonly tooltip?: Optional<string>;
}

export interface ActionDefinition<T> {
  readonly actions: Action<T>[];
  readonly more: Action<T>[];
}

interface ActionButtonProps<T> extends Action<T>, ButtonProps {
  readonly index: number;
  readonly actionKey: string;
}

interface ActionsProps<T> {
  actions: ActionDefinition<T>;
  onClick: (key: ActionKeyType<T>) => void;
  justifyContent: "flex-start" | "flex-end";
}

export default function Actions<T>({
  actions,
  onClick,
  justifyContent,
}: ActionsProps<T>) {
  function onActionClick(key: ActionKeyType<T>, e: React.MouseEvent) {
    if (isNotNullNorUndefined(onClick)) {
      onClick(key);
    }
  }

  return (
    <CellContainer align="center" justifyContent={justifyContent}>
      <CellInnerContainer margin={0} width="100%">
        <StyledButtonGroup>
          {actions.actions.map(
            ({ key, label, buttonProps = {}, tooltip }, index) => {
              if (isNotNullNorUndefined(tooltip)) {
                return (
                  <Tooltip key={key as string} title={<Trans>{tooltip}</Trans>}>
                    <ActionButton
                      key={key as string}
                      actionKey={key as string}
                      onClick={onActionClick.bind(null, key)}
                      {...{ label, buttonProps, index }}
                      translate="yes"
                    />
                  </Tooltip>
                );
              }
              return (
                <ActionButton
                  key={key as string}
                  actionKey={key as string}
                  onClick={onActionClick.bind(null, key)}
                  {...{ label, buttonProps, index }}
                />
              );
            }
          )}
          {isNotEmptyArray(actions.more) && (
            <StyledDropDownButton
              options={actions.more}
              data-cy="actionButton_more"
              onClick={onClick}
              translate="yes"
            >
              <Trans>More</Trans>
            </StyledDropDownButton>
          )}
        </StyledButtonGroup>
      </CellInnerContainer>
    </CellContainer>
  );
}

function ActionButton<T>({
  key,
  actionKey,
  label,
  buttonProps = {},
  index,
  ...rest
}: ActionButtonProps<T>) {
  return (
    <StyledButton
      data-cy="actionButton_seeReports"
      color="gradation"
      actionKey={actionKey}
      index={index}
      {...{
        ...rest,
        ...buttonProps,
      }}
      translate="yes"
    >{label}</StyledButton>
  );
}

export const CellContainer = styled.div<{ align: "center" | "flex-start"; justifyContent: "flex-start" | "flex-end"}>`
  display: flex;
  align-items: ${(props) => props.align};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-end"};
  width: 100%;
`;

export const CellInnerContainer = styled.div<{ margin?: number; padding?: number[]; width: string }>`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width};
  margin-top: ${(props) => getOr(props.margin, 0 as number) + "px"};
  padding: ${(props) => mapNumbersToPixels(getOr(props.padding, []))};
  position: relative;
  justify-content: space-evenly;
`;

function mapNumbersToPixels(values: number[]) {
  return values.map((value) => value + "px").join(" ");
}

const StyledButtonGroup = styled(ButtonGroup)<ButtonGroupProps & { children: React.ReactNode }>`
  display: flex !important;
  justify-content: flex-end;
`;

const StyledButton = styled(Button)<ButtonProps & { index: number; actionKey: string }>`
  height: 36px !important;
  :focus,
  :hover {
    border-color: ${Palette.bluePurple} !important;
    outline: none !important;
  }
  border-top-left-radius: ${(props) =>
    props.index === 0 ? 3 : 0}px !important;
  border-bottom-left-radius: ${(props) =>
    props.index === 0 ? 3 : 0}px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
`;

const StyledDropDownButton = styled(DropDownButton)<DropDownButtonProps>`
  margin-left: -1px;
  width: 83px !important;
  height: 36px !important;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  color: ${Palette.veryDarkBlueGrey} !important;
  border-color: ${Palette.lightGreyBlue} !important;
  :focus,
  :hover {
    border-color: ${Palette.bluePurple} !important;
    outline: none !important;
  }
`;
