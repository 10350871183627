import { List as ImmutableList } from "immutable";
import { Optional } from "@hyphen-lib/lang/Optionals";
import { mapOr } from "@hyphen-lib/lang/Objects";
import { Rights } from "@hyphen-lib/business/auth/Rights";

// REMOVING /categories , /pulses & /flags for immediate use.
// Reason being they are not implemented.
// Make routes available as and when required.

const DEFAULT_ROUTE_RIGHTS: ImmutableList<[RegExp, string[]]> = ImmutableList([
  [/^\/lifecycle/, [Rights.Insights.ACCESS, Rights.LCADashboard.ACCESS]],
  [/^\/dashboard/, [Rights.Insights.ACCESS, Rights.Dashboard.ACCESS]],
  [/^\/segments/, [Rights.Insights.ACCESS, Rights.LCADashboard.ACCESS]],
  [/^\/pulsePolls\/.*\/reports\/overview$/, [Rights.Insights.ACCESS, Rights.PulsePoll.OVERVIEW]],
  [/^\/pulsePolls\/.*\/reports\/comments$/, [Rights.Insights.ACCESS, Rights.PulsePoll.COMMENTS]],
  [/^\/pulsePolls\/view\/reports/, [Rights.Insights.ACCESS, Rights.Dashboard.ACCESS]],
  [/^\/pulsePolls\/view\/details/, [Rights.Insights.ACCESS, Rights.Dashboard.ACCESS]],
  // [/^\/categories/, ["insights_categories"]],
  [/^\/surveys/, [Rights.Insights.ACCESS ,Rights.Survey.INSIGHTS_SURVEYS_LIST]],
  [/^\/pulsePolls/, [Rights.Insights.ACCESS, Rights.PulsePoll.LIST]],
  [/^\/actioncenter\/actions\/create/, [Rights.Insights.ACCESS, Rights.Action.CREATE]],
  [/^\/actioncenter\/actions\/edit/, [Rights.Insights.ACCESS, Rights.Action.CREATE]],
  [/^\/actioncenter\/actions/, [Rights.Insights.ACCESS, Rights.Action.VIEW_ALL]],
  [/^\/actioncenter\/focusareas/, [Rights.Insights.ACCESS, Rights.Action.VIEW_ALL]],
  [/^\/actioncenter/, [Rights.Insights.ACCESS, Rights.Action.VIEW_ALL]],
  [/^\/users\/permissions/, [Rights.User.USERS_ROLES]],
  [/^\/users\/roles/, [Rights.User.USERS_ROLES]],
  [/^\/users/, [Rights.User.ADMINISTRATE]],
  // [/^\/flags/, ["insights_flags"]],
  [/^\/settings\/manageSuggestions/, [Rights.Action.EDIT_TEMPLATE]],
  [/^\/settings/, [Rights.Company.ADMINISTRATE]],
  [/^\/voice/, [Rights.Voice.POSTS]],
  [/^\/errors/, []],
  [/^\/login/, []],
  [/^\/$/, []],
] as [RegExp, string[]][]);

export const FALLBACK_ROUTES : string[] = [
  "/dashboard",
  "/lifecycle",
  "/surveys",
  "/actioncenter",
  "/users",
  "/settings",
  "/errors/403",
];

export const SURVEY_REPORT_FALLBACK_ROUTES : string[] = [
  "overview",
  "heatmap",
  "categories",
  "questions",
  "comments",
  "individualResults",
  "participation",
];

/**
 * Gets the rights associated to a location.
 *
 * @param {string} location
 * @return {Optional<string[]>}
 */
export function getRightsForRoute(location: string): Optional<string[]> {
  const entry = DEFAULT_ROUTE_RIGHTS.find(
    ([locationRegex]) => locationRegex.test(location)
  );
  return mapOr(entry, ([__, rights]) => rights, Optional.empty());
}
