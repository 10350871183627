import { default as networkActionCreators } from "@store/network/actions";
import { createRequest } from "@src/utils/networks";
// eslint-disable-next-line max-len
import { BatarangScriptDefinitionsResource } from "hyphen-lib/dist/domain/resource/batarang/BatarangScriptDefinitionsResource";

export function fetchBatarangScriptDefinitionsIfNeeded() {
  return networkActionCreators.fetchElementIfNeeded({
    id: BatarangScriptDefinitionsResource.DEFAULT_ALL_ID,
    type: BatarangScriptDefinitionsResource.TYPE,
    request: createRequest("/batarang/scripts/" + BatarangScriptDefinitionsResource.DEFAULT_ALL_ID),
  });
}
