import React from "react";
import styled from "styled-components";
import { Empty } from "antd";
import { Trans } from "react-i18next";
import { getOr } from "@hyphen-lib/lang/Objects";
import Palette from "@src/config/theme/palette";
import { Heading } from "@components/core/Typography";
import { useTranslation } from "react-i18next";

import { OverviewReportResource } from "hyphen-lib/dist/domain/resource/survey/report/OverviewReportResource";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { isEmpty } from "hyphen-lib/dist/lang/Arrays";
import { CompareWithOption } from "@screens/Insights/components/ViewOptions/components/CompareWith";
import { getMatchingOptionLabel } from "@src/utils/Comparisons";
import {
  isNotEmptyObject,
  isNotNullNorUndefined,
} from "hyphen-lib/dist/lang/Objects";
import { HighlightedSegment } from "hyphen-lib/dist/domain/resource/report/common/Highlights";
import { generateCategorySubtitle, SegmentSection } from "@src/utils/Subtitles";
import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
import { getDimensionLabel } from "@src/utils/Dimensions";
import Segment from "./components/Segment";
import { FocusArea } from "hyphen-lib/dist/domain/FocusArea";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";

interface Props {
  readonly data: OverviewReportResource;
  readonly dimensions: Optional<Dimensions>;
  readonly areComparisonsVisible?: boolean;
  readonly compareWithOptions: CompareWithOption[];
  readonly comparisonKey: Optional<string>;
  readonly hasActionCreationRight: boolean;
  readonly focusAreas?: FocusAreaResource[];
  readonly onCreateFocusArea: (focusArea: Partial<FocusAreaResource>) => void;
  readonly focusAreaLabel: string;
}

function getSegmentKey({
  dimension,
  segment,
}: {
  dimension: string;
  segment: string;
}): string {
  return `${dimension}_${segment}`;
}

function Segments({
  data,
  dimensions,
  areComparisonsVisible = true,
  compareWithOptions,
  comparisonKey,
  hasActionCreationRight,
  focusAreas,
  onCreateFocusArea,
  focusAreaLabel
}: Props) {
  const comparisonLabel = getMatchingOptionLabel(
    compareWithOptions,
    comparisonKey
  );

  let improvedSegments: HighlightedSegment[] = [];
  let declinedSegments: HighlightedSegment[] = [];

  // for improved segments and declined segments, we always want to see previous
  const comparisonImprovements = "previous";
  if (
    isNotNullNorUndefined(comparisonImprovements) &&
    isNotEmptyObject(data.improvements) &&
    isNotEmptyObject(data.improvements[comparisonImprovements])
  ) {
    improvedSegments = getOr(
      data.improvements[comparisonImprovements].improvedSegments,
      []
    );
    declinedSegments = getOr(
      data.improvements[comparisonImprovements].declinedSegments,
      []
    );
  }
  const strongestSegments = data.strongestSegments;
  const weakestSegments = data.weakestSegments;
  const { t } = useTranslation();

  return (
    <Container>
      <Heading size="large" translate="yes">Segments</Heading>
      <Summary><Trans>See which segments require your attention.</Trans></Summary>
      {isEmpty(strongestSegments) &&
      isEmpty(weakestSegments) &&
      isEmpty(improvedSegments) &&
      isEmpty(declinedSegments) ? (
        <Empty description={<Trans>Not enough data</Trans>} data-cy="segmentSection_noData" />
      ) : (
        <>
          <SectionContainer>
            <SegmentsContainer>
              <Heading size="small" weight={"bold"} data-cy="strongest_segments" translate="yes">
                Strongest Segments
              </Heading>
              {strongestSegments.map((segment: HighlightedSegment) => (
                <Segment
                  segmentType={FocusArea.Source.STRONGEST_SEGMENTS}
                  dimensionLabel={getDimensionLabel(
                    segment.dimension,
                    dimensions
                  )}
                  key={getSegmentKey(segment)}
                  data={segment}
                  isComparisonVisible={areComparisonsVisible}
                  comparisonLabel={comparisonLabel}
                  comparisonKey={comparisonKey}
                  hasActionCreationRight={hasActionCreationRight}
                  subtitle={generateCategorySubtitle(t,
                    segment.categorySubtitle,
                    SegmentSection.BEST
                  )}
                  onCreateFocusArea={onCreateFocusArea}
                  focusAreaLabel={focusAreaLabel}
                />
              ))}
            </SegmentsContainer>
            <SegmentsContainer>
              <Heading size="small" weight={"bold"} translate="yes">
                Weakest Segments
              </Heading>
              {weakestSegments.map((segment: HighlightedSegment) => (
                <Segment
                  segmentType={FocusArea.Source.WEAKEST_SEGMENTS}
                  dimensionLabel={getDimensionLabel(
                    segment.dimension,
                    dimensions
                  )}
                  key={getSegmentKey(segment)}
                  data={segment}
                  isComparisonVisible={areComparisonsVisible}
                  comparisonLabel={comparisonLabel}
                  hasActionCreationRight={hasActionCreationRight}
                  comparisonKey={comparisonKey}
                  subtitle={generateCategorySubtitle(t,
                    segment.categorySubtitle,
                    SegmentSection.WEAKEST
                  )}
                  onCreateFocusArea={onCreateFocusArea}
                  focusAreaLabel={focusAreaLabel}
                />
              ))}
            </SegmentsContainer>
          </SectionContainer>

          <SectionContainer marginTop={56}>
            <SegmentsContainer>
              {improvedSegments.length > 0 && (
                <>
                  <Heading size="small" weight={"bold"} translate="yes">
                    Biggest improvements
                  </Heading>
                  {improvedSegments.map((segment: HighlightedSegment) => (
                    <Segment
                      dimensionLabel={getDimensionLabel(
                        segment.dimension,
                        dimensions
                      )}
                      key={getSegmentKey(segment)}
                      data={segment}
                      isComparisonVisible={areComparisonsVisible}
                      comparisonLabel={comparisonLabel}
                      hasActionCreationRight={hasActionCreationRight}
                      comparisonKey={comparisonKey}
                      subtitle={generateCategorySubtitle(t,
                        segment.categorySubtitle,
                        SegmentSection.IMPROVED
                      )}
                      segmentType={FocusArea.Source.BIGGEST_IMPROVEMENTS}
                      onCreateFocusArea={onCreateFocusArea}
                      focusAreaLabel={focusAreaLabel}
                    />
                  ))}
                </>
              )}
            </SegmentsContainer>
            <SegmentsContainer>
              {declinedSegments.length > 0 && (
                <>
                  <Heading size="small" weight={"bold"} translate="yes">
                    Biggest decline
                  </Heading>
                  {declinedSegments.map((segment: HighlightedSegment) => (
                    <Segment
                      dimensionLabel={getDimensionLabel(
                        segment.dimension,
                        dimensions
                      )}
                      key={getSegmentKey(segment)}
                      data={segment}
                      isComparisonVisible={areComparisonsVisible}
                      comparisonLabel={comparisonLabel}
                      hasActionCreationRight={hasActionCreationRight}
                      comparisonKey={comparisonKey}
                      subtitle={generateCategorySubtitle(t,
                        segment.categorySubtitle,
                        SegmentSection.DECLINED
                      )}
                      segmentType={FocusArea.Source.BIGGEST_DECLINE}
                      onCreateFocusArea={onCreateFocusArea}
                      focusAreaLabel={focusAreaLabel}
                    />
                  ))}
                </>
              )}
            </SegmentsContainer>
          </SectionContainer>
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  padding: 32px;
  background: ${Palette.white};
  font-family: Lato, sans-serif;
  color: ${Palette.veryDarkBlueGrey};
`;

const Summary = styled.div`
  margin-top: 8px;
  color: ${Palette.bluishGrey};
`;

const SectionContainer = styled.div<{ marginTop?: number }>`
  margin-top: ${(props) => getOr(props.marginTop, 24)}px;
  display: flex;
`;

const SegmentsContainer = styled.div`
  flex: 50%;

  &:first-of-type {
    margin-right: 32px;
  }
`;

export default Segments;
