import React from "react";
import { Trans } from "react-i18next";

export const TransWrapper = (props: any) => {
    const {children, translate, id} = props;
    if (translate === "yes") {
        return React.Children.map(children, (child, index) => (
            typeof child === "string" ?
            <Trans i18nKey={id || ""}>{child}</Trans> : child
        ));
    }
    return children || null;
};