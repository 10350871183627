import React from "react";
import { Icon, Form } from "antd";
import styled from "styled-components";
import { FormProps } from "antd/lib/form";

import { not } from "hyphen-lib/dist/lang/Booleans";
import { isEmpty } from "hyphen-lib/dist/lang/Arrays";

import Select from "@components/core/Select";
import Button from "@components/core/Button";
import { isEmailFormatValid } from "hyphen-lib/dist/business/user/Users";
import { UserAndRole, SelectDeselectUsersAction } from "../store/actions";
import { Trans } from "react-i18next";

export interface ManualImportReduxStateProps {
  emails: string[];
  manualAddError: string;
}

export interface ManualImportActionProps {
  onSelectDeselectUsers: (payload: SelectDeselectUsersAction["payload"]) => void;
}

interface Props extends ManualImportReduxStateProps, ManualImportActionProps {
  onManualImportSubmit: (emails: UserAndRole[]) => void;
}

export class ManualImport extends React.Component<Props> {

  componentWillUnmount() {
    this.clearEmailsFromStore();
  }

  onSelect = (value: any) => {
    const { onSelectDeselectUsers } = this.props;
    if (not(isEmailFormatValid(value))) {
      return onSelectDeselectUsers({ select: { error: "Value should be a valid email address." } });
    }
    onSelectDeselectUsers({ select: {
      email: value,
      error: "",
    } });
  };

  onDeselect = (value: any) => this.setState((state) => {
    const { onSelectDeselectUsers } = this.props;

    onSelectDeselectUsers({
      deselect: { email: value },
    });
  });

  onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const { onManualImportSubmit } = this.props;
    const { emails } = this.props;

    if (isEmpty(emails)) {
      return this.setState({ error: "You don't have any emails to be added." });
    }

    const emailsWithRoles = emails.map(email => {
      return {
        email,
        role: "user",
      };
    });
    onManualImportSubmit(emailsWithRoles);
    this.clearEmailsFromStore();
  };

  clearEmailsFromStore = () => this.props.onSelectDeselectUsers({ deselectAll: true });

  render() {
    const { emails, manualAddError } = this.props;
    return (
      <FormContainer onSubmit={this.onSubmit} >
        <Select
          mode="tags"
          size="large"
          value={emails}
          tokenSeparators={[",", ", ", " ", " ,", " , "]}
          placeholder={<Trans>Email</Trans>}
          error={manualAddError && <Trans>{manualAddError}</Trans>}
          onSelect={this.onSelect}
          onDeselect={this.onDeselect}
          suffixIcon={<Icon type="arrow-right" />}
        />
        <Button htmlType="submit" translate="yes">
          Add
        </Button>
      </FormContainer>
    );
  }
}

const FormContainer = styled(Form)<FormProps>`
  display: flex;
  padding: 0 32px !important;

  > div {
    flex-grow: 1;
  }
`;
