import React from "react";
import Checkbox from "@src/components/core/Checkbox";
import { CheckboxProps } from "antd/lib/checkbox";
import { Trans } from "react-i18next";

export function VerbatimAnalysisCheckbox(props: CheckboxProps) {
  return (
    <Checkbox
      info="Check if the answers to this question need to be analyzed for sentiment"
      data-cy="surveyCreation_questions_sentimentAnalysis"
      {...props}
    >
        <Trans>Include Verbatim in Sentiment Analysis</Trans>
    </Checkbox>
  );
}
