import { NotificationChannels } from "hyphen-lib/dist/domain/NotificationChannels";
import { checkChannelsConsistency } from "hyphen-lib/dist/business/NotificationChannels";
import { isInstanceOfException } from "hyphen-lib/dist/lang/exception/Exceptions";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";

export function checkChannels(channels: NotificationChannels):
WrongEntityException.Errors {
  try {
    checkChannelsConsistency(
      channels,
      {
        failFast: false,
      }
    );
  } catch (e) {
    if (isInstanceOfException(e, WrongEntityException)) {
      return WrongEntityException.extractErrors((e as WrongEntityException).errors());
    }
    // else propagate exception
    throw e;
  }

  return WrongEntityException.noErrors();
}
