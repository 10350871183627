// @ts-nocheck
import React from "react";
import { SvgProperties } from "csstype";
import styled from "styled-components";

const Engagement = (props: SvgProperties) => {
  const { fill } = props;

  const StyledSvg = styled.svg`
    fill: ${fill};
  `;
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width="62"
      height="54"
      viewBox="0 0 62 54"
    >
      <path
        fillRule="nonzero"
        d={
          `M46.851 33.705a1 1 0 0 0 .62.805l11.387 4.556A5 5 0 0 1 62 43.708V54H46v-2h14v-8.292a3 3 0
          0 0-1.886-2.786l-11.386-4.556a3 3 0 0 1-1.861-2.413l-.561-4.486.686-.299A10 10 0 0 0 51
          20v-9.275a5 5 0 0 0-5-5h-.618l-.276-.553-1.542-3.085c-.037-.068-.117-.1-.168-.084L36
          4.643a6.6 6.6 0 0 0-1.947 1.125l-.768.64-1.28-1.536.768-.64a8.613 8.613 0 0 1
          2.545-1.469L42.746.111c1-.33 2.09.109 2.595 1.06l1.291 2.582A7 7 0 0 1 53 10.725V20a12 12 0
          0 1-6.527 10.679l.378 3.026zm-20 2a1 1 0 0 0 .62.805l11.387 4.556A5 5 0 0 1 42
          45.708V54H0v-8.292a5 5 0 0 1 3.143-4.642l11.39-4.556a1 1 0 0 0 .62-.805l.28-2.232a18.53
          18.53 0 0 1-9.748-.524l-1.194-.396.655-1.074A26.338 26.338 0 0 0 9 18v-1c0-6.627 5.373-12
          12-12s12 5.373 12 12v.991a26.345 26.345 0 0 0 3.854 13.488l.655 1.074-1.194.396a18.53 18.53
          0 0 1-9.743.524l.28 2.232zM40 45.708a3 3 0 0 0-1.886-2.786l-11.386-4.556a3 3 0 0
          1-1.861-2.413l-.653-5.22 1.473.446c2.87.87 5.914.941 8.808.217A28.356 28.356 0 0 1 31
          18v-1c0-5.523-4.477-10-10-10s-10 4.477-10 10v1.009a28.345 28.345 0 0 1-3.495 13.388 16.53
          16.53 0 0 0 8.813-.218l1.473-.447-.19 1.528-.463 3.693a3 3 0 0 1-1.862 2.413l-11.39 4.556A3
          3 0 0 0 2 45.708V52h38v-6.292z`
        }
      />
    </StyledSvg>
  );
};

export default Engagement;
