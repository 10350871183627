import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import styled from "styled-components";
import { ColumnProps, TableProps } from "antd/lib/table";
import { DynamicRowProps, withDynamicRows } from "@components/core/DynamicRowsTable/withDynamicRows";
import { IndividualResultResource } from "@hyphen-lib/domain/resource/survey/report/IndividualResultResource";
import Palette from "@src/config/theme/palette";
import Table from "@components/core/Table";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import moment from "moment-timezone";
import { IconButton } from "@src/components/core/IconButton";
import { CreateActionPlanSvg } from "@src/components/core/svg";
import { MailIcon } from "@src/components/core/svg/MailIcon";
import { QuestionAnswerCell } from "./cells/QuestionAnswerCell";
import { EmailCell } from "./cells/EmailCell";
import { TOOLTIP } from "@src/utils/FocusArea";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { FocusArea, Module } from "hyphen-lib/dist/domain/FocusArea";
import { Trans } from "react-i18next";

export interface SendPrivateMessageProps{
  isPrivateMessageModalOpen: boolean;
  privateMessageText: string;
  sendPrivateMessage: () => void;
  togglePrivateMessageModal: (
    surveyId: string,
    postId: string,
    voteId: string,
    commentText: string
  ) => void;
  updatePrivateMessageText: (privateMessageText: string) => void;
}

const getColumns = (
  areQuestionCategoriesVisible: boolean,
  areCommentsVisible: boolean,
  canSendPrivateMessage: boolean,
  isMultiSelectQuestionEnabled: boolean,
  postAndSurveySentiment?: boolean
): ColumnProps<IndividualResultResource>[] => [
  {
    title: (<Trans>Employee email</Trans>),
    dataIndex: "employeeEmail",
    key: "employeeEmail",
    width: 200,
    render: (__, record, index) => {
      return <EmailCell row={record} index={index} />;
    },
  },
  {
    title: (
      <QuestionAnswerHeader>
        <div><Trans>Question</Trans></div>
        <AnswerHeader><Trans>Answer</Trans></AnswerHeader>
      </QuestionAnswerHeader>
    ),
    dataIndex: "question",
    key: "question",
    render: (__, record, index) => {
      return (
        <QuestionAnswerCell
          row={record}
          index={index}
          isCategoryVisible={areQuestionCategoriesVisible}
          isCommentVisible={areCommentsVisible}
          isMultiSelectQuestionEnabled={isMultiSelectQuestionEnabled}
          postAndSurveySentiment={postAndSurveySentiment}
        />
      );
    },
  },
];

export interface IndividualResultsSurveyTableProps extends TableProps<IndividualResultResource> {
  individualResults: IndividualResultResource[];
  areQuestionCategoriesVisible?: boolean;
  areCommentsVisible?: boolean;
  hover?: boolean;
  canSendPrivateMessage: boolean;
  surveyName: string;
  isMultiSelectQuestionEnabled: boolean;
  readonly onCreateFocusArea: (
    focusArea: Partial<FocusAreaResource>
  ) => void;
  readonly postAndSurveySentiment?: boolean;
}

function IndividualResultsSurveyTableComponent({
  individualResults,
  areQuestionCategoriesVisible = true,
  areCommentsVisible = true,
  canSendPrivateMessage,
  isMultiSelectQuestionEnabled,
  postAndSurveySentiment,
  ...rest
}: IndividualResultsSurveyTableProps) {
  return (
    <StyledTable
      columns={getColumns(
        areQuestionCategoriesVisible,
        areCommentsVisible,
        canSendPrivateMessage,
        isMultiSelectQuestionEnabled,
        postAndSurveySentiment
      )}
      dataSource={individualResults}
      rowKey={"_id"}
      pagination={{
        pageSize: 10,
        // fixme: need to be activated again, and see if we can style it!
        showTotal: (total: number, range: number[]) =>
          <Trans i18nKey="resultsCount"
          values={{start: range[0], end: range[1], total}} 
          defaults={`Showing ${range[0]} to ${range[1]} of ${total} results`}/>,
      }}
      {...rest}
    />
  );
}

interface MatchParams {
  id: string;
}

export default function IndividualResultsSurveyTable
(props: IndividualResultsSurveyTableProps & SendPrivateMessageProps) {

  function HoverRow({ row , ...rest}: DynamicRowProps<IndividualResultResource> & RouteComponentProps<MatchParams>) {

    function onCreateActionPlan() {
      props.onCreateFocusArea({
        title: row.question,
        source: FocusArea.Source.INDIVIDUAL_RESULT,
        module: Module.SURVEY,
        focusAreaType: FocusArea.FocusAreaType.INDIVIDUAL_RESULT,
        sourceUrl: {
          label: props.surveyName,
          url: window.location.href,
        },
        actionPlans: [],
        surveyId: rest.match.params.id,
        questionId: row.questionId,
        category: row.category
      });
    }

    function togglePrivateMessageModal() {
      props.togglePrivateMessageModal(
        rest.match.params.id,                 // SurveyId,
        row.questionId,                       // QuestionId
        row._id,                              // CommentId,
        row.comment ? row.comment.text : ""   // Comment Text
      );
    }

    function getPrivateMessageTooltipText() {
      if (row.conversation) {
        return (
          <div>
            <Trans>Private message</Trans> <br />
            <Trans>sent on</Trans> {moment(row.conversation.createdAt).format("MM/DD/YYYY")}
          </div>
        );
      }

      return (<Trans>Send Private Message</Trans>);
    }

    const showSendPrivateMessageButton =
      props.canSendPrivateMessage &&
      isNotNullNorUndefined(row.comment) &&
      props.areCommentsVisible;

    return (
      <>
        <StyledHoverCell>
          <IconContainer>
            <IconButton
              svgIcon={CreateActionPlanSvg}
              onClick={onCreateActionPlan}
              tooltipProps={{
                title: <Trans>{TOOLTIP.FOCUSAREAICON}</Trans>,
              }}
            />
            {showSendPrivateMessageButton && (
              <IconButton
                svgIcon={MailIcon}
                onClick={togglePrivateMessageModal}
                tooltipProps={{
                  title: getPrivateMessageTooltipText(),
                }}
              />
            )}
          </IconContainer>
          <div className="px-1">
            <EmailCell row={row} index={rest.index} />
          </div>
        </StyledHoverCell>
        <StyledHoverCell>
          <QuestionAnswerCell
            row={row}
            isCategoryVisible={props.areQuestionCategoriesVisible}
            isCommentVisible={props.areCommentsVisible}
            isMultiSelectQuestionEnabled={props.isMultiSelectQuestionEnabled}
            postAndSurveySentiment={props.postAndSurveySentiment}
          />
        </StyledHoverCell>
      </>
    );
  }

  const TailoredTable = withDynamicRows(IndividualResultsSurveyTableComponent, withRouter(HoverRow));

  return <TailoredTable {...props}/>;
}

const QuestionAnswerHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AnswerHeader = styled.div`
  width: 168px;
  text-align: right;
  margin-right: 120px;
`;

const StyledTable = styled(Table)`
  .ant-table-body > table tr > td {
    vertical-align: top;
  }
  .ant-table-thead > tr > th:last-child .ant-table-header-column {
    width: 100%;
  }
`;

const StyledHoverCell = styled.td`
  background-color: ${Palette.lightLightBlue};
  position: relative;
`;

const IconContainer = styled.div`
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  left: 15px;

  div {
    position: relative;
    top: 0;
    transform: unset;
  }
`;
