import { default as networkActionCreators } from "@store/network/actions";
import { createRequest } from "@src/utils/networks";
import { SurveyBankQuestionResource } from "hyphen-lib/dist/domain/resource/survey/SurveyBankQuestionResource";

export function fetchSurveyBankQuestionsIfNeeded(surveyId: string) {
  return networkActionCreators.fetchAllPageIfNeeded({
    type: SurveyBankQuestionResource.TYPE,
    request: createRequest(`/surveys/${surveyId}/bankQuestions`),
  });
}
