import React from "react";
import { LifeCycleDashboardResource } from "hyphen-lib/dist/domain/resource/report/LifeCycleResource";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Filter } from "@src/components/core/FilterLabels/types";
import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
import { Layout } from "antd";
import Spin from "@src/components/core/Spin";
import styled from "styled-components";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import SurveyReportHeader from "../../Survey/components/SurveyReportHeader";
import FavorabilityByPhase from "./FavorabilityByPhase";

interface LCADashboardViewProps {
  readonly enabledFilters: string[];
  readonly enabledCustomFilters?: string[];
  readonly dashboard: Loadable<LifeCycleDashboardResource>;
  readonly dimensions: Optional<Dimensions>;
  readonly appliedFilters: Filter[];
  readonly onDimensionSelect: (dimension: string) => void;
  readonly segmentedBy: string;
}

/* eslint-disable max-len */
const introText = "Lifecycle Analysis helps understand employee engagement longitudinally, throughout all phases of employees' tenure in the company. Phases and subphases are defined by admin users in Settings";

class LCADashboardView extends React.Component<LCADashboardViewProps> {

  extractDimensionKeys = () => {
    const dimensionKeys: string[] = [];
    const { dimensions } = this.props;
    if (isNotNullNorUndefined(dimensions)) {
      dimensionKeys.push(...Object.keys(dimensions));
    }
    return dimensionKeys;
  };

  render() {
    const {
      dashboard,
      enabledFilters,
      enabledCustomFilters,
      segmentedBy,
      dimensions,
      onDimensionSelect,
    } = this.props;

    return (
      <Layout>
        <Spin size="large" spinning={Loadable.isNotLoaded(dashboard)}>
          <SectionContainer>
            <SurveyReportHeader
              enabledFilters={enabledFilters}
              enabledCustomFilters={enabledCustomFilters}
              explanation={Loadable.isLoaded(dashboard) ? introText : ""}
            />
          </SectionContainer>
          {
            Loadable.isLoaded(dashboard) &&
            <FavorabilityByPhase
              dashboard={dashboard.value}
              dimensions={dimensions}
              onDimensionSelect={onDimensionSelect}
              segmentedBy={segmentedBy}
            />
          }
        </Spin>
      </Layout>
    );
  }
}
const SectionContainer = styled.div`
  margin-top: 24px;

  &:first-of-type {
    margin-top: 0;
  }
`;
SectionContainer.displayName = "SectionContainer";
export default LCADashboardView;
