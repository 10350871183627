import React from "react";
import Modal from "@components/core/Modal";
import { Seq } from "immutable";
import styled from "styled-components";
import { SearchBar } from "@src/components/core/SearchBar";
import { ModalProps } from "antd/lib/modal";
import { ParticipantResource } from "hyphen-lib/dist/domain/resource/participants/ParticipantResource";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import AudienceEmailsModalContent from "./AudienceEmailsModalContent";
import { ExportButton } from "@src/screens/Insights/PulsePoll/components/ReportHeader/ExportDropdown";
import { ExportSvg } from "@src/components/core/svg/ExportSvg";
import { Icon } from "antd";
import { Trans } from "react-i18next";

interface Props {
  modalOpen: boolean;
  fetchParticipantsExport: () => void;
  handleCancel: () => void;
  participants: Loadable<ParticipantResource[]>;
}

const StyledMergeTagsModal = styled(Modal)<ModalProps & {children: React.ReactNode}>`
  width: 740px !important;
`;

export default class AudienceEmailsModal extends React.Component<Props> {

  state = {
    searchText: "",
  };

  getFilteredAudience = () : ParticipantResource[] => {
    const { participants } = this.props;
    const { searchText } = this.state;

    return Loadable.mapOr(
      participants,
      p => Seq(p)
        .filter(participant =>
          participant.email.toLocaleLowerCase().indexOf(searchText.toLowerCase()) > -1
        )
        .toArray(),
      []
    );
  };

  onSearchChange = (e: any) => {
    this.setState({ searchText: e.target.value });
  };

  render () {
    const {
      modalOpen,
      handleCancel,
      participants,
      fetchParticipantsExport,
    } = this.props;

    const audience = this.getFilteredAudience();
    return (
      <StyledMergeTagsModal
        footer={null}
        visible={modalOpen}
        title={
        <div className="d-flex flex-row">
        <div className="mr-auto"><Trans>List of All Recipients</Trans></div>
       <div style={{ marginRight: "2em" }}>
          <ExportButton
            color="gradation"
            data-jest='exportRecipients'
            disabled={audience.length === 0}
            onClick = {()=>{
              fetchParticipantsExport();
            }}
          >
            <Icon component={ExportSvg} />
            <span><Trans>Export Recipients</Trans></span>
          </ExportButton>
        </div>
      </div>}
        centered={true}
        onCancel={handleCancel}>
        <SearchBarContainer>
          <SearchBar placeholder="Search" onChange={this.onSearchChange}/>
        </SearchBarContainer>
        {
          Loadable.mapOr(
            participants,
            _ => <AudienceEmailsModalContent audience={audience}/>,
            <>Loading...</>
          )
        }
      </StyledMergeTagsModal>
    );
  }
}

const SearchBarContainer = styled.div`
  padding: 24px 32px 12px;
`;
