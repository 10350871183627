import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import {
  addPropertyInDepth,
  getProperty,
  isNotNullNorUndefined,
  isNullOrUndefined,
  mergeObjectInto,
  isNotEmptyObject
} from "hyphen-lib/dist/lang/Objects";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { isString } from "hyphen-lib/dist/lang/Strings";
import { parseBoolean } from "hyphen-lib/dist/lang/Booleans";

export interface PropMapping {
  readonly storeKey: string;
  readonly localKey: string;
}

export function applyExistingParametersIfNeeded(parameters: Dictionary<any>,
  current: Dictionary<any>,
  ...keys: (string | PropMapping)[]): Optional<Dictionary<any>> {
  // prepare a merged object
  let merged: Dictionary<any> = { ...current };

  // there is a special case, where force is activated.
  // in that case, we just want to return the current with no force
  if (parseBoolean(merged.force)) {
    delete merged.force;
    return merged;
  }

  let mergeInDepth = false;
  if (parseBoolean(merged.merge)) {
    mergeInDepth = true;
    delete merged.merge;
  }

  let hasMergedProperties = false;
  for (const key of keys) {
    const { storeKey, localKey } = extractPropMaping(key);

    const existingValue = getProperty(current, localKey);
    if (isNullOrUndefined(existingValue)) {
      const parametersValue = getProperty(parameters, storeKey);
      if (isNotNullNorUndefined(parametersValue) && isNotEmptyObject(parametersValue)) {
        merged = addPropertyInDepth(merged, parametersValue, localKey);
        hasMergedProperties = true;
      }
    } else if (mergeInDepth) {
      const parametersValue = getProperty(parameters, storeKey);
      if (isNotNullNorUndefined(parametersValue)) {
        merged = addPropertyInDepth(
          merged,
          mergeObjectInto(parametersValue, existingValue),
          localKey
        );
        hasMergedProperties = true;
      }
    }
  }

  // return the merged values, only if some properties have been merged
  return hasMergedProperties ? merged : Optional.empty();
}

function extractPropMaping(key: string | PropMapping): PropMapping {
  if (isString(key)) {
    return { storeKey: key, localKey: key };
  }
  return key;
}
