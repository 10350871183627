import React from "react";
import styled from "styled-components";
import { Optional } from "@hyphen-lib/lang/Optionals";
import { isNullOrUndefined } from "@hyphen-lib/lang/Objects";
import Palette from "@src/config/theme/palette";
import { getClassNameForScoreText } from "@src/screens/Insights/components/Reports/Score";
import { ScoreType } from "hyphen-lib/dist/domain/resource/report/common/Score";

interface Props extends React.HTMLProps<HTMLSpanElement> {
  favorability: Optional<number>;
  scoreType: ScoreType;
  percentage?: boolean;
}

export function FavorabilityScore({ favorability, scoreType, percentage = true, ...rest }: Props) {
  if (isNullOrUndefined(favorability)) {
    return null;
  }

  const score = percentage ? favorability + "%" : favorability;

  return (
    // @ts-ignore
    <ScoreText data-cy="pollScore" className={getClassNameForScoreText(favorability, scoreType)} {...rest}>
      { score }
    </ScoreText>
  );
}

export const ScoreText = styled.span<any>`
  font-family: Lato;
  font-size: 16px;
  color: ${Palette.lightGreyBlue};
  width: 40px;
  font-weight: bold;
  white-space: nowrap;

  &.positive {
    color: ${Palette.aquaBlue};
  }

  &.negative {
    color: ${Palette.darkPink};
  }
`;
