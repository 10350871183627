import React from "react";
import styled from "styled-components";
import { TransWrapper } from "@src/components/core/withTrans";
import { Optional } from "@hyphen-lib/lang/Optionals";
import { isNullOrUndefined } from "@hyphen-lib/lang/Objects";
import Palette from "@src/config/theme/palette";

export type SentimentScoreTextSizeVariant = "large" | "small";

interface Props {
  netSentimentScore: Optional<number>;
  size?: SentimentScoreTextSizeVariant;
  readonly translate?: string;
}

export function SentimentScore({ netSentimentScore, size = "small", ...rest }: Props) {
  if (isNullOrUndefined(netSentimentScore)) {
    return null;
  }

  const getScoreClassName = (score: number): string => {
    if (score > 3 && score < 6) {
      return "positive";
    }
    if (score > 0 && score < 3) {
      return "negative";
    }

    return "neutral";
  };

  const sentimentScoreMap: any = {
    1: "Mostly Negative",
    2: "Negative",
    3: "Neutral",
    4: "Positive",
    5: "Mostly Positive",
  };

  // normalize the netSentimentScore range from the [-100, 100] to [1, 5]
  const sentimentScore = Math.min(Math.floor((netSentimentScore + 100) / 40) + 1, 5);

  const sentimentScoreText = sentimentScoreMap[sentimentScore] || `Unknown: ${sentimentScore}`;

  return (
    <ScoreText data-cy="sentiment_score" size={size} className={getScoreClassName(sentimentScore)}>
      <TransWrapper {...rest}>{sentimentScoreText}</TransWrapper>
    </ScoreText>
  );
}

export const ScoreText = styled.span<{ size: SentimentScoreTextSizeVariant }>`
  font-family: Lato, sans-serif;
  font-size: ${props => props.size === "small" ? 16 : 32}px;
  font-weight: bold;
  text-align: center;
  color: ${Palette.bluishGrey};

  &.positive {
    color: ${Palette.aquaBlue};
  }

  &.negative {
    color: ${Palette.darkPink};
  }
`;
