import React from "react";
import styled from "styled-components";
import { Trans } from "react-i18next";
import Palette from "@src/config/theme/palette";
import { Optional } from "hyphen-lib/dist/lang/Optionals";

interface Props {
  readonly heatmapTheme?: Optional<any>;
}

export default function HeatmapLegend(props: Props) {
  const { heatmapTheme } = props;
  const labelComponents : any = [];
  Object.keys(heatmapTheme).forEach(labelKey => {
      const labelInfo = heatmapTheme[labelKey as any];
      labelComponents.push(
          <div>
              <LabelIcon value={labelInfo.backgroundColor} />
              <Trans>{labelInfo.label}</Trans>
          </div>
      );
  });

  return (
      <Container>
        {labelComponents}
      </Container>
  );
}

const Container = styled.div`
  display: grid;
  flex-direction: column;
  font-size: 12px;
  color: ${Palette.bluishGrey};
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-right: 20px;

  div {
    margin-left: 16px;
    flex-direction: row;
  }
  
  div > span {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 8px;
  }
`;

export const Label = styled.span`
  width: 130px !important;
  font-family: Lato;
  color: ${Palette.veryDarkBlueGrey};
`;
  
export const VeryFavorable = styled.span`
  background-color: ${Palette.aquaBlue};
`;

export const Favorable = styled.span`
  background-color: #7fe1ec;
`;

export const Neutral = styled.span`
  background-color: ${Palette.veryLightBlue};
`;

export const Unfavorable = styled.span`
  background-color: ${Palette.softPink};
`;

export const VeryUnfavorable = styled.span`
  background-color: ${Palette.darkPink};
`;

interface labelIconProps{
  value?: string;
}

export const LabelIcon = styled.span<labelIconProps>`
  background-color: ${props => props.value};
`;
