import React from "react";
import { Audience as AudienceType } from "hyphen-lib/dist/domain/common/Audience";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Dimensions } from "@hyphen-lib/domain/common/Dimensions";
import { Trans } from "react-i18next";
import { CurrentUserResource } from "@hyphen-lib/domain/resource/user/CurrentUserResource";
// eslint-disable-next-line max-len
import { getProperty, isNotNullNorUndefined, keys, isNotEmptyObject, isNullOrUndefined } from "hyphen-lib/dist/lang/Objects";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { ParticipantCountResource } from "hyphen-lib/dist/domain/resource/participants/ParticipantCountResource";
import { ParticipantResource } from "hyphen-lib/dist/domain/resource/participants/ParticipantResource";
import { DateCriteriaTexts } from "@src/utils/Audiences";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { RecipientsForm } from "@screens/Insights/components/Wizard/Audience/components/RecipientsForm";
// eslint-disable-next-line max-len
import { CollapsedRecipientsForm } from "@screens/Insights/components/Wizard/Audience/components/CollapsedRecipientsForm";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { CriteriaForm } from "../components/CriteriaForm";
import AudienceEmailsModal from "../components/AudienceEmailsModal";
import Tooltip from "@components/core/Tooltip";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import { Icon } from "antd";

export interface AudienceProps {
  readonly audience: AudienceType;
  readonly configuration: AudienceType.Configuration;
  readonly dimensions: Dimensions;
  readonly reportingDimensions: Dictionary<string[]>;
  readonly allowToUseGroups: boolean;
  readonly groups: CurrentUserResource.PrivateGroup[];
  readonly manualEmails: string[];
  readonly participants: Loadable<ParticipantResource[]>;
  readonly participantsCount: Loadable<ParticipantCountResource>;
  readonly dateCriteriaTexts?: Optional<Partial<DateCriteriaTexts>>;
  readonly disabled?: Optional<boolean>;
  readonly canRemoveManualAudience?: boolean;
  readonly canAddManualAudience?: boolean;
  readonly cannotEditCriteriaAudience?: boolean;
  readonly onChange: (audience: AudienceType) => void;
  readonly onFetchParticipants: () => void;
  readonly fetchParticipantsExport: () => void;
  readonly hasPendingAudienceUpdates?: boolean;
  readonly onErrorRemoveManualAudience: () => void;
}

interface AudienceState {
  readonly isManualCriteriaExpanded: boolean;
  readonly isParticipantsModalOpen: boolean;
}

const defaultProps = Object.freeze({
  canRemoveManualAudience: true,
  canAddManualAudience: true,
  cannotEditCriteriaAudience: true,
  hasPendingAudienceUpdates: false,
});

class Audience extends React.Component<AudienceProps, AudienceState> {

  static readonly defaultProps = defaultProps;

  constructor(props: AudienceProps) {
    super(props);

    const {
      audience,
      configuration,
    } = props;

    const isManualCriteriaExpanded =
      // we only have dynamic, so we will only have manual criteria
      not(configuration.dynamic) ||
      // we already have some emails set in the manual criteria
      (
        !audience.everyoneInTheCompany &&
        getProperty(audience, "manualCriteria.userIds.length", 0) > 0
      );

    this.state = {
      isManualCriteriaExpanded,
      isParticipantsModalOpen: false,
    };
  }

  handleExpandManualCriteria = () => {
    this.setState({
      isManualCriteriaExpanded: true,
    });
  };

  handleOpenParticipantsModal = () => {
    this.props.onFetchParticipants();
    this.setState({
      isParticipantsModalOpen: true,
    });
  };

  handleCloseParticipantsModal = () => {
    this.setState({
      isParticipantsModalOpen: false,
    });
  };

  handleToggleEveryoneInTheCompany = (everyoneInTheCompany: boolean) => {
    const {
      audience,
      onChange,
    } = this.props;
    let modifiedAudience = { ...audience };
    if (everyoneInTheCompany) {
      // we are checking everyone in the company

      // if we have a date criteria, we still want a complex audience, but with only the date criteria
      if (!audience.everyoneInTheCompany && isNotNullNorUndefined(audience.dynamicCriteria.dateCriteria)) {
        modifiedAudience = {
          ...AudienceType.generateEmptyComplexAudience(),
          dynamicCriteria: {
            dateCriteria: audience.dynamicCriteria.dateCriteria,
          },
        };
      } else {
        // let's just set a new audience with everyone in the company
        modifiedAudience = AudienceType.generateEveryoneInTheCompanyAudience();
      }
    } else {
      // we are unchecking everyone in the company
      if (audience.everyoneInTheCompany) {
        // the audience has to be transformed into a complex audience
        modifiedAudience = AudienceType.generateEmptyComplexAudience();
      } else if (!audience.everyoneInTheCompany && isNotEmptyObject(audience.dynamicCriteria.dateCriteria)) {
        // @ts-ignore
        modifiedAudience.dynamicCriteria.dimensions = {};
      } else {
        // the audience is already complex, probably having a date criteria, nothing need to be done here
      }
    }
    onChange(modifiedAudience);
  };

  handleDynamicCriteriaChange = (dynamicCriteria: AudienceType.DynamicCriteria) => {
    const {
      audience,
      onChange,
    } = this.props;

    if (!audience.everyoneInTheCompany && isNotNullNorUndefined(dynamicCriteria)) {
      onChange({
        ...audience,
        dynamicCriteria,
      });
    }
  };

  handleAddManualRecipients = (emails: string[]) => {
    const {
      audience,
      onChange,
    } = this.props;

    if (!audience.everyoneInTheCompany) {
      onChange({
        ...audience,
        manualCriteria: {
          userIds: emails,
        },
      });
    }
  };

  /*
    As the checkbox for everyone in the company is in the dynamic section in the UI,
    but not in the audience object, we have to derive the checkbox value and we can not
    directly use the `audience.everyoneInTheCompany`.
   */
  checkIfActualEveryone = () => {
    const { audience, configuration } = this.props;
    // everyone is really true
    if (audience.everyoneInTheCompany) {
      return true;
    }

    if (
      isNotEmptyObject(audience.dynamicCriteria.dateCriteria) &&
      isNotNullNorUndefined(audience.dynamicCriteria.dimensions)
    ) {
      return false;
    }

    if (
      isNotEmptyObject(audience.dynamicCriteria.dateCriteria) &&
      isNullOrUndefined(audience.dynamicCriteria.dimensions)
    ) {
      return true;
    }
    // if we have only manual criteria, we are not allowing everyone
    if (configuration.manual && !configuration.dynamic) {
      return false;
    }

    // we do have some manual criteria configured
    const { manualCriteria, dynamicCriteria } = audience;
    if (isNotNullNorUndefined(manualCriteria) && isNotEmptyArray(manualCriteria.userIds)) {
      return false;
    }

    // we don't have any dynamic criteria except date criteria
    return keys(dynamicCriteria).count() === 1 && isNotNullNorUndefined(dynamicCriteria.dateCriteria);
  };

  infoMessage = (status: string) => {
    if (status === "undefined") {
      return (<Tooltip
        title={<Trans>The eligibility criteria has some errors. 
          Please resolve them to see the recipients count.</Trans>}
      >
        <HelpIcon type="info-circle" />
      </Tooltip>
      );
    }
  };

  render() {
    const {
      audience,
      configuration,
      manualEmails,
      participants,
      participantsCount,
      dimensions,
      reportingDimensions,
      dateCriteriaTexts,
      disabled,
      canRemoveManualAudience,
      canAddManualAudience,
      hasPendingAudienceUpdates,
      cannotEditCriteriaAudience,
      fetchParticipantsExport,
      onErrorRemoveManualAudience,
    } = this.props;

    const {
      isManualCriteriaExpanded,
      isParticipantsModalOpen,
    } = this.state;

    const actualEveryone = this.checkIfActualEveryone();
    const audienceHasDateCriteria = !audience.everyoneInTheCompany &&
      isNotEmptyObject(audience.dynamicCriteria.dateCriteria);
    const emptyDimensionManualCriteria =  WrongEntityException.ErrorCodes.EMPTY_DIMENSION_MANUALCRITERIA;
    const statusOfParticipantsCount = participantsCount.status === "in-error" 
      && participantsCount.error.errorCode !== emptyDimensionManualCriteria ? "undefined" : "loading...";
    const isParticipantsCountForDimensionsError = participantsCount.status === "in-error" 
      && participantsCount.error.errorCode === emptyDimensionManualCriteria;
    const totalRecipients =  isParticipantsCountForDimensionsError ? 0 :
      Loadable.mapOr(
        participantsCount,
        count => count.total.toString(),
        statusOfParticipantsCount
      );
    return (
      <>
        {
          configuration.dynamic && (
            <CriteriaForm
              everyoneInTheCompany={actualEveryone}
              dynamicCriteria={audience.everyoneInTheCompany ? {} : audience.dynamicCriteria}
              dimensions={dimensions}
              reportingDimensions={reportingDimensions}
              showCount={configuration.dynamic}
              count={Loadable.map(participantsCount, count => count.criteria)}
              dateCriteriaConfiguration={configuration.dateCriteria}
              dateCriteriaTexts={dateCriteriaTexts}
              disabled={disabled}
              // activate when we will have voice, and groups in i2
              allowToUseGroups={false}
              groups={[]}
              canRemoveManualAudience={canRemoveManualAudience}
              onToggleEveryoneInTheCompany={this.handleToggleEveryoneInTheCompany}
              onChange={this.handleDynamicCriteriaChange}
              hasPendingAudienceUpdates={hasPendingAudienceUpdates}
              cannotEditAudience={cannotEditCriteriaAudience}
              statusOfParticipantsCount={statusOfParticipantsCount}
              infoMessage={this.infoMessage}
              isParticipantsCountForDimensionsError = {isParticipantsCountForDimensionsError}
            />
          )
        }
        {
          (((not(actualEveryone) && configuration.manual) || audienceHasDateCriteria) &&
            not(isManualCriteriaExpanded)) && (canRemoveManualAudience || canAddManualAudience) &&
          <CollapsedRecipientsForm onExpand={this.handleExpandManualCriteria} />
        }
        {
          (((not(actualEveryone) && configuration.manual) || audienceHasDateCriteria) &&
            isManualCriteriaExpanded) &&
          <RecipientsForm
            emails={audience.everyoneInTheCompany ? [] : audience.manualCriteria.userIds}
            allowedEmails={manualEmails}
            showCount={configuration.manual && isManualCriteriaExpanded}
            count={Loadable.map(participantsCount, count => count.manual)}
            disabled={disabled}
            canRemoveManualAudience={canRemoveManualAudience}
            canAddManualAudience={canAddManualAudience}
            onChange={this.handleAddManualRecipients}
            hasPendingAudienceUpdates={hasPendingAudienceUpdates}
            onErrorRemoveManualAudience={onErrorRemoveManualAudience}
            statusOfParticipantsCount={statusOfParticipantsCount}
            infoMessage={this.infoMessage}
            isParticipantsCountForDimensionsError = {isParticipantsCountForDimensionsError}
          />
        }
        <ActionContainer>
          <RecipientsContainer>
            <RecipientsLabel>
              <Trans>Total Recipients:</Trans>
            </RecipientsLabel>
            <RecipientsCount data-jest="totalRecipients">
              {
                totalRecipients
              }
            </RecipientsCount>
            {this.infoMessage(statusOfParticipantsCount)}
            {statusOfParticipantsCount === "loading..." && totalRecipients > 0  && <FullListButton
              data-cy="audience_fullList"
              data-jest="audience_fullList"
              onClick={this.handleOpenParticipantsModal}
              translate="yes"
            >
              <Trans>See full list</Trans>
            </FullListButton>}
          </RecipientsContainer>
        </ActionContainer>
        <AudienceEmailsModal
          modalOpen={isParticipantsModalOpen}
          handleCancel={this.handleCloseParticipantsModal}
          participants={participants}
          fetchParticipantsExport = {fetchParticipantsExport}
        />
      </>

    );
  }
}

export default Audience;

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RecipientsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  margin-left: 30px;
`;

const RecipientsLabel = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-right: 7px;
`;

const RecipientsCount = styled.div`
  font-size: 24px;
  color: ${Palette.darkModerateBlue};
`;

const FullListButton = styled.div`
  cursor: pointer;
  margin-left: 10px;
  color: ${Palette.darkModerateBlue};
`;
const HelpIcon = styled(Icon)`
  margin-left: 8px;
  cursor: pointer !important;
  svg {
    fill: ${Palette.bluishGrey};
  }
`;