import React from "react";
import { DropDownButton } from "@src/components/core/DropDownButton";
import { PlusSignSvg } from "@src/components/core/svg/PlusSignSvg";
import { BaseCustomFilterProps } from "@screens/Insights/components/FiltersContent";
import { getOr, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import styled from "styled-components";
import { Trans } from "react-i18next";
import Spin from "@src/components/core/Spin";

export interface AddFiltersProps extends BaseCustomFilterProps<string[]> {
  readonly options: { key: string | number | symbol; label: JSX.Element | string }[];
  fetchDimensionData?: () => any;
  loading?: boolean;
}

export function AddFilters({
  options,
  onClick,
  label,
  hiddenFilters,
  fetchDimensionData,
  loading
}: AddFiltersProps) {
  const filters = options.filter(function filterOutHiddenFilters(option) {
    const existingFilter = hiddenFilters.find(function findById(hiddenFilter) {
      return hiddenFilter.key === option.key;
    });
    return isNotNullNorUndefined(existingFilter);
  });
  return (
    <StyledDropDownButton 
      options={filters} 
      onClick={onClick} 
      fetchDimensionData= {fetchDimensionData} 
      loading={loading}>
      <LabelContainer>
        { 
          getOr(loading, false) ? 
            <SpinContainer>
              <Spin color="blue" data-jest = {"loader"} />
            </SpinContainer> : <PlusSignSvg /> 
        }
        <span>
          <Trans>{label}</Trans>
        </span>
      </LabelContainer>
    </StyledDropDownButton>
  );
}

const StyledDropDownButton = styled(DropDownButton)`
  height: 36px;
  width: 266px;
  border-radius: 3px;
  background-color: #314a5b !important;
  border: solid 1px #d9d9d9;
  height: 30px !important;

  span {
    top: 20%;
  }
`;

const SpinContainer = styled.div`
padding-right: 8px
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`;
