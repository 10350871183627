import React from "react";
import { connect, MapStateToProps } from "react-redux";
import { List as ImmutableList } from "immutable";
import { notification, Icon } from "antd";

import { notifications as getNotifications } from "@src/store/notifications/selectors";
import { State } from "@src/store/types";
import { Notification } from "@src/store/notifications/types";
import actionCreators from "@src/store/notifications/actions";
import Palette from "@src/config/theme/palette";
import Attention from "@components/core/svg/Attention";

export interface StateProps {
  notifications: ImmutableList<Notification>;
}

export type Props = StateProps & typeof actionCreators;
class Notifications extends React.Component<Props> {

  openNotificationWithIcon = (type: string, message: string, description: string, duration: number) => {
    let icon = null;

    if (type === "error") {
      icon = <Icon component={Attention} />;
    } else if (type === "success") {
      icon = <Icon type="check-circle" style={{ color: Palette.aquaBlue }} />;
    }

    // @ts-ignore
    notification.open({
      message,
      description,
      duration,
      style: {
        borderStyle: "solid",
        borderColor: Palette.paleGrey,
        borderWidth: "thin",
      },
      icon,
    });
  };

  showNotifications = (notifications: ImmutableList<Notification>) => {
    notifications.forEach((notificationProps: any) => {
      this.openNotificationWithIcon(
        notificationProps.type,
        notificationProps.message,
        notificationProps.description,
        notificationProps.duration
      );
      this.props.removeNotification(notificationProps._id);
    });
  };

  componentDidUpdate() {
    const { notifications } = this.props;
    if (notifications.size > 0) {
      this.showNotifications(notifications);
    }
  }

  componentDidMount() {
    const { notifications } = this.props;
    if (notifications.size > 0) {
      this.showNotifications(notifications);
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps: MapStateToProps<StateProps, {}, State> = (state: State): StateProps => ({
  notifications: getNotifications(state),
});

export default connect(mapStateToProps, actionCreators)(Notifications);
