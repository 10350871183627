import React from "react";
import { Row } from "antd";
import { Trans } from "react-i18next";
import Button from "@components/core/Button";
import { RowProps } from "antd/lib/row";
import Select from "@components/core/Select";
import Palette from "@src/config/theme/palette";
import styled from "styled-components";
import Tooltip from "@components/core/Tooltip";
import { isNotNullNorUndefined, getOr } from "hyphen-lib/dist/lang/Objects";
import { SelectProps } from "antd/lib/select";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { OrderedSet as OrderedImmutableSet, Seq } from "immutable";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { getDimensionSegmentLabel } from "@src/utils/Dimensions";

const { Option } = Select;

export interface DimensionSelectProps {
  label: JSX.Element;
  dimKey: string;
  values: string[];
  allowedValues: string[];
  restrictedValues?: Optional<string[]>;
  disabled?: Optional<boolean>;
  canRemoveManualAudience?: boolean;
  cannotEditAudience?: boolean;
  hasPendingAudienceUpdates?: boolean;
  onChange: (values: string[]) => any;
  onRemove: () => any;

}

export class DimensionSelect extends React.Component<DimensionSelectProps> {

  state = {
    originalDimensions: this.props.values ? this.props.values : [],
  };

  handleSelectedValueChange = (value: any) => {
    this.props.onChange(Array.isArray(value) ? value : [value]);
  };

  isOptionDisabled = (segment: any) => {
    const { originalDimensions } = this.state;
    const { canRemoveManualAudience } = this.props;

    if (not(canRemoveManualAudience)) {
      return originalDimensions.includes(segment);
    }
    return false;
  };

  componentDidUpdate(prevProps: DimensionSelectProps) {
    if (
      prevProps.hasPendingAudienceUpdates &&
      !this.props.hasPendingAudienceUpdates
    ) {
      this.setState({ originalEmails: this.props.values });
    }
  }

  render() {
    const {
      label,
      values,
      allowedValues,
      restrictedValues,
      disabled,
      cannotEditAudience,
      dimKey,
      onRemove,
    } = this.props;

    const isRestricted = isNotNullNorUndefined(restrictedValues);
    const restrictedValuesSet = OrderedImmutableSet(getOr(restrictedValues, []).sort());
    return (
      <StyledRow type="flex" justify="space-between" align="bottom">
        <SelectContainer>
          <StyledSelect
            mode="multiple"
            size="large"
            label={label}
            value={values}
            onChange={this.handleSelectedValueChange}
            disabled={disabled === true || cannotEditAudience}
          >
            {
              restrictedValuesSet.map(segment => (
                <Option
                  disabled={restrictedValuesSet.size === 1 || (values.length === 1 && values[0] === segment)}
                  key={segment}
                  value={segment}
                >
                  <Tooltip
                    title={<Trans>
                      This criterium has been set as default due to your permissions and it cannot be removed.
                    </Trans>}
                  >
                    <Trans>{getDimensionSegmentLabel(dimKey, segment)}</Trans>
                  </Tooltip>
                </Option>
              ))
            }
            {
              Seq(allowedValues)
                .filter(segment => not(restrictedValuesSet.has(segment)))
                .map(segment => (
                  <Option
                    key={segment}
                    value={segment}
                    disabled={this.isOptionDisabled(segment)}
                  >
                    {getDimensionSegmentLabel(dimKey, segment)}
                  </Option>
                ))
            }
          </StyledSelect>
        </SelectContainer>
        <StyledButton
          icon="close"
          size="large"
          onClick={onRemove}
          disabled={isRestricted || disabled === true}
        />
      </StyledRow>
    );
  }
}

const StyledRow = styled(Row) <RowProps>`
  margin: 24px 0 0 0;
`;

const StyledButton = styled(Button)`
  width: 38px;
  height: 38px;
  border-radius: 3px;
  border: solid 1px ${Palette.lightGreyBlue};
  background-color: ${Palette.athensGrey};
  .anticon {
    color: ${Palette.lightGreyBlue};
  }
 `;

const SelectContainer = styled.div`
  flex: 1;
  margin-right: 16px;
`;

const StyledSelect = styled(Select)<SelectProps & {children: React.ReactNode}>``;
