import React from "react";
import { SurveyInfoResource } from "@hyphen-lib/domain/resource/SurveyInfoResource";
import { calculatePercentage } from "@hyphen-lib/util/math/Math";
import styled from "styled-components";
import { Progress } from "@components/core/Progress";
import { getOr } from "@hyphen-lib/lang/Objects";
import Palette from "@src/config/theme/palette";
import { DynamicRowProps } from "@components/core/DynamicRowsTable/withDynamicRows";
import { CellContainer, CellInnerContainer } from "./Cells";
import { Trans } from "react-i18next";

export function ParticipationCell({ row: { status, participation } }: DynamicRowProps<SurveyInfoResource>) {
  if (status === "draft") {
    return (
      <CellContainer align="center" justifyContent="flex-start">
        <CellInnerContainer width="100%">
          <Label>{participation.total}</Label>
        </CellInnerContainer>
      </CellContainer>
    );
  }

  const percentage = calculatePercentage(
    getOr(participation.completed, 0 as number),
    participation.total
  );

  const renderPercentage = ( participationd: any,percentaged: any) => {

    if ( isNaN( percentaged )) {
      return <Label><Trans>Not enough data</Trans></ Label> ;
    }else {return <div>
      <Progress percent={percentaged}/>
      <Label>
        { percentaged }% ({ participationd.completed } of {participationd.total})
      </ Label></div>;}
  };

  return (
    <CellContainer align="flex-start" justifyContent="flex-start">
      <CellInnerContainer width="100%">
        {renderPercentage(participation,percentage)}
      </CellInnerContainer>
    </CellContainer>
  );
}

export const Label = styled.span`
  margin-top: 11px;
  height: 20px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${Palette.veryDarkBlueGrey};
`;
