import Palette from "@src/config/theme/palette";
import React from "react";
import styled from "styled-components";
import { Heading } from "@components/core/Typography";
import { Trans } from "react-i18next";
import SelectDimension from "../../../../components/SelectDimension";
import { Dimensions, Dimension } from "@hyphen-lib/domain/common/Dimensions";
import { SelectValue } from "antd/lib/select";
import SegmentChart from "../SegmentChart";
import { 
  getOr, 
  isNotEmptyObject } from "hyphen-lib/dist/lang/Objects";
import NoResult from "@src/components/core/NoResult";
import { ParticipationReportResource } from "hyphen-lib/dist/domain/resource/survey/report/ParticipationReportResource";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { connect, MapStateToProps } from "react-redux";
import { State } from "@store/types";
import { getResourceById } from "@src/store/network/selectors";
import { FilterParameter } from "@src/utils/networks";
import { getDimensions } from "@src/screens/Insights/store/selectors";

export interface OwnProps {
  readonly title?: string;
  readonly availableDimensions: string[];
  readonly surveyId: string;
  readonly dimension: Dimension;
  readonly dimensions: Dimensions;
  readonly selectedDimension: string;
  readonly anonymityThreshold: number;
  readonly filter?: FilterParameter;
  readonly updateSegmentByInPath:(selectedDimension: SelectValue)=>void;
}

export interface TopicSegmentStateProps {
  readonly participationBySegments: Store.Element<ParticipationReportResource>;
  readonly dimensionsLabels: Dimensions;
}

type Props = OwnProps & TopicSegmentStateProps;
export class TopicSegment extends React.Component<Props> {
  render() {
    const { title,
      dimensions,
      dimensionsLabels,
      availableDimensions,
      updateSegmentByInPath,
      selectedDimension,
      participationBySegments,
      anonymityThreshold } = this.props;
    const dimension = dimensions[selectedDimension.toString()];
    console.log("participationBySegments ----->",  participationBySegments);
    console.log("selectedDimension ----->", selectedDimension);
    console.log("dimension ----->", dimension);
    return <Container>
      <Heading size="large" data-cy="topic_heading">
        <Trans>{title} by segment</Trans>
      </Heading>
      {
        isNotEmptyObject(dimensions) ?
          <>
            <SelectDimensionContainer>
              <SelectDimension
                data={availableDimensions}
                selected={selectedDimension}
                dimensions={dimensionsLabels}
                onChangeDimension={updateSegmentByInPath}
              />
            </SelectDimensionContainer>
            <ChartContainer >
              <SegmentChart
                anonymityThreshold={anonymityThreshold}
                dimension={dimension as any}
                participationBySegments={participationBySegments}
                selectedDimension={selectedDimension}
              />
            </ChartContainer>
          </> :
          <NoResult data-cy="noSegmentLabel" type="minimal" description="No segments" />
      }
    </Container>;
  };
};

const mapStateToProps: MapStateToProps<
TopicSegmentStateProps, 
OwnProps, 
State
> = (
  state: State, 
  ownProps: OwnProps
  ): TopicSegmentStateProps => {

    const {  surveyId, filter } = ownProps;
    return {
      participationBySegments: getResourceById(
        state,
        ParticipationReportResource.TYPE,
        ParticipationReportResource.generateId(
          surveyId,
          filter
        )
      ),
      dimensionsLabels: getOr(getDimensions(state), {}),
    };
};

export default connect(mapStateToProps)(TopicSegment);

const Container = styled.div`
  padding: 32px;
  background: ${Palette.white};
  font-family: Lato,sans-serif;
  color: ${Palette.veryDarkBlueGrey};
  box-shadow: 0 4px 8px 0 rgba(198, 214, 223, 0.15);
  border-radius: 3px;
`;

const ChartContainer = styled.div`
  padding-top: 24px;
  max-height: 500px;
  overflow-y: auto;
`;

const SelectDimensionContainer = styled.div`
  margin-top: 16px;
`;