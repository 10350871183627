// @ts-nocheck
import React from "react";
export function ExpandSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="10"
      viewBox="0 0 14 10"
    >
      <defs>
        {/* eslint-disable max-len */}
        <path id="collapseA" d="M4.22 8.302h9.43c.36 0 .65.313.65.699 0 .386-.29.699-.65.699H4.22l1.89 2.033a.735.735 0 0 1 0 .988.625.625 0 0 1-.46.205.625.625 0 0 1-.46-.205l-3-3.226a.735.735 0 0 1 0-.988l3-3.227a.617.617 0 0 1 .92 0 .735.735 0 0 1 0 .989L4.22 8.302zM8.685 4h6.628c.379 0 .686.307.686.686v.025a.686.686 0 0 1-.686.686H8.686A.686.686 0 0 1 8 4.711v-.025C8 4.307 8.307 4 8.686 4zm0 8.603h6.628c.379 0 .686.307.686.686v.025a.686.686 0 0 1-.686.686H8.686A.686.686 0 0 1 8 13.314v-.025c0-.379.307-.686.686-.686z"/>
        {/* eslint-disable max-len */}
      </defs>
      <use fill="#AAB9CE" fillRule="nonzero" transform="translate(16, -4) scale(-1, 1)" xlinkHref="#collapseA"/>
    </svg>
  );
}
