import React from "react";
import { ScreenContainer } from "@screens/Insights/components/ScreenContainer";
import Button from "@components/core/Button";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { SurveyListScreen, QuestionScoreFlags } from "@screens/Insights/SurveyList/components/SurveyListScreen";
import { connect } from "react-redux";
import { State } from "@store/types";
import { getCurrentUser, getRightsMatcher } from "@screens/Insights/store/selectors";
import { Rights } from "@hyphen-lib/business/auth/Rights";
import {
  SurveyInfosPageParameters,
  surveyListActionCreators
} from "@screens/Insights/SurveyList/store/actions";
import notificationActionCreators, { ShowNotificationAction } from "@src/store/notifications/actions";
import * as notificationFactory from "@src/store/notifications/notification-factory";
import { actionCreators, showReports } from "@screens/Insights/Surveys/store/actions";
import { getSurveyListStateProps } from "@screens/Insights/SurveyList/store/selectors";
import { extractDataAndTotalFromPage, getExistingPage } from "@store/network/selectors";
import { SurveyInfoResource } from "hyphen-lib/dist/domain/resource/SurveyInfoResource";
import { mapOr, isNotNullNorUndefined, isNotEmptyObject, isEmptyObject } from "hyphen-lib/dist/lang/Objects";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { parseNumber } from "hyphen-lib/dist/lang/Number";
import { getOr } from "hyphen-lib/dist/lang/Objects";
import { FilterParameter, SortParameter } from "@src/utils/networks";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { getSurveyTypes, fetchMergeTags } from "@screens/Insights/Surveys/store/selectors";
import { SurveyResourceProps } from "@screens/Insights/Surveys/store/types";
import {
  NetworkEventSuccessAction
} from "@store/network/actions";
import { Survey } from "hyphen-lib/dist/domain/Survey";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { formatSort } from "@src/utils/helper";
import { applyExistingParametersIfNeeded, PropMapping } from "@src/utils/parameters";
import { Map as ImmutableMap } from "immutable";
import { getParameters } from "@screens/Insights/parameters/store/selectors";
import { getChannels } from "@screens/Insights/Surveys/store/selectors";
import { parametersActionCreators } from "@screens/Insights/parameters/store/actions";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { replaceLocation } from "@src/utils/locations";
import { fetchSurveyInfosIfNeeded } from "@src/store/network/resource/SurveyInfoResources";
import { NotificationChannels } from "hyphen-lib/dist/domain/NotificationChannels";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { SurveyTypeProps, MargeTagStateProps } from "../Surveys/store/types";
import { SelectedSurveyInfoResource } from "./store/reducers";
import { fetchFocusAreasIfNeeded } from "@src/store/network/resource/ActionResources";
import { FocusAreaListPageParameters } from "../Actions/store/actions";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { getFocusAreaListStoreProps } from "../Actions/store/selectors";
import { hasFocusAreaRights } from "hyphen-lib/dist/business/focusArea/focusAreaResource";
// import { not } from "hyphen-lib/dist/lang/Booleans";

export const SURVEYS_FILTER_MAPPINGS: PropMapping[] = [
  { localKey: "filter", storeKey: "surveysFilter" },
  { localKey: "filter.launchedAt", storeKey: "surveysFilter.launchedAt" },
  { localKey: "filter.status", storeKey: "surveysFilter.status" },
  { localKey: "filter.type", storeKey: "surveysFilter.type" },
  { localKey: "sort", storeKey: "surveysSort" },
];

export interface SurveyListContainerProps extends SurveyTypeProps {
  readonly hasCreationRight: boolean;
  readonly loading: boolean;
  readonly channels: CompanyResource["channels"];
  readonly surveys: SurveyInfoResource[];
  readonly parameters: ImmutableMap<string, any>;
  readonly total: number;
  readonly page: PageFilter;
  readonly sort: SortParameter;
  readonly filter: FilterParameter;
  readonly reminderModalOpen: boolean;
  readonly deleteModalOpen: boolean;
  readonly closeModalOpen: boolean;
  readonly duplicateModalOpen: boolean;
  readonly selectedSurveyInfoResource: SelectedSurveyInfoResource;
  readonly selectedSurveyResource: SurveyResourceProps;
  readonly isRequestingSendReminders: boolean;
  readonly isRequestingDelete: boolean;
  readonly isRequestingClose: boolean;
  readonly isAttemptingSurveyDuplication: boolean;
  readonly mergeTags: MargeTagStateProps;
  readonly onShowReports: (surveyId: string, flags: QuestionScoreFlags) => any;
  readonly existingPage: Store.Page<SurveyInfoResource>;
  readonly focusAreas?: FocusAreaResource[];
  readonly currentUser: Optional<CurrentUserResource>;
  readonly hasFaRights: boolean;
}

export interface SurveyListContainerActionProps {
  readonly onFetchIfNeeded: (parameters: SurveyInfosPageParameters) => any;
  readonly onModifyList: (parameters: SurveyInfosPageParameters) => any;
  readonly fetchSurveyTypes: () => void;
  readonly toggleReminderModalOpen: (modalOpen: boolean, selectedSurveyInfoResource: SurveyInfoResource) => void;
  readonly toggleDeleteModalOpen: (modalOpen: boolean, selectedSurveyInfoResource: SurveyInfoResource) => void;
  readonly toggleCloseModalOpen: (modalOpen: boolean, selectedSurveyInfoResource: SurveyInfoResource) => void;
  readonly toggleDuplicateModalOpen: (modalOpen: boolean, selectedSurveyInfoResource: SurveyInfoResource) => void;
  readonly sendSurveyReminders: (channels: NotificationChannels) => void;
  readonly deleteSurvey: () => void;
  readonly closeSurvey: () => void;
  readonly duplicateSurvey: (
    surveyId: string,
    onSuccessRedirect?: (payload: NetworkEventSuccessAction["payload"]) => void,
    linkDuplicate?: boolean
  ) => void;
  readonly displayNotification: (payload: ShowNotificationAction["payload"]) => ShowNotificationAction;
  readonly fetchMergeTags: (surveyType: Survey.Type) => void;
  readonly onModifyParameters: (parameters: Dictionary<any>, mappings?: PropMapping[]) => any;
  readonly onFetchFocusArea: (parameters: FocusAreaListPageParameters) => any;
}

export type Props = SurveyListContainerProps & SurveyListContainerActionProps & RouteComponentProps;

class SurveyListContainer extends React.Component<Props> {
  componentDidMount() {
    // we might need to apply persisted parameters
    const {
      parameters,
      surveyTypes,
      location: { search },
      sort,
    } = this.props;

    const existing = parseQueryString(search);
    let mergedParameters =
      applyExistingParametersIfNeeded(
        parameters.toJS(),
        existing,
        ...SURVEYS_FILTER_MAPPINGS
      );

    if (isEmptyObject(sort)) {
      mergedParameters = Object.assign({}, mergedParameters, {
        sort: { created: -1 },
      });
    }
    if (isNotNullNorUndefined(mergedParameters) && isNotEmptyObject(mergedParameters)) {
      replaceLocation(mergedParameters);
    } else {
      // fetch the overview only if we will stay on this page,
      // otherwise it will be fetched anyway in componentDidUpdate
      this.fetchIfNeeded();
      if(this.props.hasFaRights) {
        this.props.onFetchFocusArea(this.fetchFocusAreaProps());
      }
    }

    // make network request to fetch survey types only if not already fetched
    if (surveyTypes.length === 0 ) {
      this.props.fetchSurveyTypes();
    }
  }

  fetchFocusAreaProps(): FocusAreaListPageParameters {
    return {
      page: {
        number: 1,
        size: 30
      },
      filter: {
        withActionPlans: false
      }
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.loading && not(Store.Page.isInError(this.props.existingPage))) {
      this.fetchIfNeeded();
    }

    if(this.props.hasFaRights) {
      this.props.onFetchFocusArea(this.fetchFocusAreaProps());
    }
  }

  fetchIfNeeded() {
    const {
      onFetchIfNeeded,
      page,
      filter,
      sort,
    } = this.props;

    onFetchIfNeeded({ filter, sort, page });
  }

  showSuccessNotification = (title: string, description: string, duration: number) => {
    const notification = notificationFactory.success(
      title,
      description,
      duration
    );
    this.props.displayNotification(notification);
  };

  render() {
    const { hasCreationRight } = this.props;
    return (
      <ScreenContainer
        title="Surveys"
        actions={[
          hasCreationRight && (
            <Link to="/surveys/create" key="create">
              <Button color="blue" data-cy="surveyCreation_surveyList_newSurvey" 
              translate="yes">Create New Survey</Button>
            </Link>
          )
          ,
        ]}
      >
        <SurveyListScreen
          {...this.props}
          showSuccessNotification={this.showSuccessNotification}
        />
      </ScreenContainer>
    );
  }
}

function mapStateToProps(state: State, { location }: RouteComponentProps): SurveyListContainerProps {
  const queryParameters = parseQueryString(location.search);
  const filter = getOr(queryParameters.filter, {});
  const currentUser = getCurrentUser(state);
  const {
    pageSize,
    reminderModalOpen,
    duplicateModalOpen,
    selectedSurveyInfoResource,
    selectedSurveyResource,
    isRequestingSendReminders,
    deleteModalOpen,
    closeModalOpen,
    isAttemptingSurveyDuplication,
  } = getSurveyListStateProps(state);
  const sort = getOr(formatSort(queryParameters.sort), {});
  const page = {
    size: pageSize,
    // the query string IS the source of truth, as it might be modified when using back/next buttons
    number: mapOr(queryParameters.page, parseNumber, 1),
  };

  const existingPage = getExistingPage(
    state,
    SurveyInfoResource.TYPE,
    SurveyInfoResource.generateKey(filter, sort),
    page
  );

  const { data, total } = extractDataAndTotalFromPage(existingPage);


  const focusAreaListStoreProps = getFocusAreaListStoreProps(state);
  const existingFocusAreaPage = getExistingPage(
    state,
    FocusAreaResource.TYPE,
    FocusAreaResource.generateKey({ withActionPlans: false}, {}),
    {
      size: focusAreaListStoreProps.pageSize,
      number: 1,
    }
  );
  const hasFaRights = hasFocusAreaRights(getRightsMatcher(state));

  return {
    hasCreationRight: getRightsMatcher(state).hasRight(Rights.Survey.CREATE),
    loading: Store.Page.isNotFound(existingPage),
    existingPage,
    channels: getChannels(state),
    surveys: data,
    total,
    sort,
    filter,
    page,
    surveyTypes: getSurveyTypes(state).toArray().reverse(),
    reminderModalOpen,
    deleteModalOpen,
    closeModalOpen,
    duplicateModalOpen,
    selectedSurveyInfoResource,
    selectedSurveyResource,
    isRequestingSendReminders,
    isRequestingDelete: state.get("insights_surveyList").get("isRequestingDelete"),
    isRequestingClose: state.get("insights_surveyList").get("isRequestingClose"),
    isAttemptingSurveyDuplication,
    onShowReports: showReports,
    mergeTags: fetchMergeTags(state),
    parameters: getParameters(state),
    focusAreas: extractDataAndTotalFromPage(existingFocusAreaPage).data,
    currentUser,
    hasFaRights
  };
}

export const SurveyList = withRouter(
  connect(
    mapStateToProps,
    {
      onFetchIfNeeded: fetchSurveyInfosIfNeeded,
      onModifyList: surveyListActionCreators.modifyList,
      toggleReminderModalOpen: surveyListActionCreators.toggleReminderModalOpen,
      toggleDeleteModalOpen: surveyListActionCreators.toggleDeleteModalOpen,
      toggleDuplicateModalOpen: surveyListActionCreators.toggleDuplicateModalOpen,
      toggleCloseModalOpen: surveyListActionCreators.toggleCloseModalOpen,
      sendSurveyReminders: surveyListActionCreators.sendSurveyReminders,
      deleteSurvey: surveyListActionCreators.deleteSurvey,
      closeSurvey: surveyListActionCreators.closeSurvey,
      duplicateSurvey: surveyListActionCreators.duplicateSurvey,
      fetchSurveyTypes: actionCreators.fetchSurveyTypes,
      displayNotification: notificationActionCreators.displayNotification,
      fetchMergeTags: actionCreators.fetchMergeTags,
      onModifyParameters: parametersActionCreators.modifyParameters,
      onFetchFocusArea: fetchFocusAreasIfNeeded,
    }
  )(SurveyListContainer)
);
