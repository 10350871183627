import React from "react";
import {
  BatarangScriptDefinitionsResource
} from "hyphen-lib/dist/domain/resource/batarang/BatarangScriptDefinitionsResource";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import styled from "styled-components";
import { isNotNullNorUndefined, dump } from "hyphen-lib/dist/lang/Objects";
import Select from "@components/core/Select";
import  ParameterAssetSelect  from "./ParameterAssetSelect";
const { Option } = Select;
import { OptionProps } from "antd/lib/select";
import { Trans } from "react-i18next";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import  ParameterCheckBox  from "./ParameterCheckBox";
import  ParameterInputField  from "./ParameterInputField";

export interface BatarangParametersProps {
  readonly parameters: BatarangScriptDefinitionsResource.Script.Parameter[];
  readonly values: Dictionary<any>;
  readonly context: Dictionary<any>;
  readonly disabled?: Optional<boolean>;
  readonly onChange: (parameters: Dictionary<any>) => any;
  readonly onAssetUpload: (file: File) => any;
  readonly company?: Optional<CompanyResource>;
}

export class BatarangParameters extends React.Component<BatarangParametersProps> {
  createHandleChangeFor = (key: string) => (value: any) => {
    const {
      values,
      onChange,
    } = this.props;

    onChange({
      ...values,
      [key]: value,
    });
  };
  filterOption = (input: string, option: React.ReactElement<OptionProps>) => {
    const { props } = option;
    if (isNotNullNorUndefined(props)) {
      const { children } = props;
      return (children as string).toLowerCase().indexOf(input.toLowerCase()) > -1;
    }
  };


  render() {
    const {
      parameters,
      values,
      context,
      disabled,
      onAssetUpload,
      company
    } = this.props;
    return (
      <>
        {
          parameters.map(parameter =>
            <BatarangParameter
              key={parameter.key}
              parameter={parameter}
              value={values[parameter.key]}
              context={context}
              disabled={disabled}
              onChange={this.createHandleChangeFor(parameter.key)}
              onAssetUpload={onAssetUpload}
              filterOption={this.filterOption}
              company={company}
            />
          )
        }
      </>
    );
  }
}

export interface BatarangParameterProps {
  readonly parameter: BatarangScriptDefinitionsResource.Script.Parameter;
  readonly value: Optional<any>;
  readonly context: Dictionary<any>;
  readonly disabled?: Optional<boolean>;
  readonly onChange: (value: any) => any;
  readonly onAssetUpload: (file: File) => any;
  readonly filterOption: (input: string, option: React.ReactElement<OptionProps>) => boolean | undefined;
  readonly company?: Optional<CompanyResource>;
}

function BatarangParameter(props: BatarangParameterProps) {
  const { parameter } = props;

  return (
    <ParameterContainer>
      {parameter.type === "boolean" ?
        <ParameterCheckBoxContainer>
          <ParameterCheckBox {...props} />
          <CheckBoxLabel>{parameter.label}</CheckBoxLabel>
        </ParameterCheckBoxContainer>
        :
        <>
          <Label>{parameter.label}</Label>
          <ParameterInput {...props} />
        </>
      }
      {
        isNotNullNorUndefined(parameter.description) &&
        <Description>{parameter.description}</Description>
      }
    </ParameterContainer>
  );
}

function ParameterInput(props: BatarangParameterProps) {
  const {parameter} = props;

  if (parameter.type === "string") {
    if (isNotNullNorUndefined(parameter.allowedValues)) {
      return (
        <ParameterInputSelect
          {...props}
        />
      );
    }
  }

  if (parameter.type === "asset") {
    return <ParameterAssetSelect { ...props} />;
  }

  if (parameter.type === "inputField") {
    return <ParameterInputField { ...props} />;
  }

  if (parameter.type === "boolean") {
    return <ParameterCheckBox { ...props} />;
  }

  return (
    <NotImplemented>
      Unable to find an implementation for this kind of parameter {dump(parameter, true)}
    </NotImplemented>
  );
}

function ParameterInputSelect({
  parameter,
  value,
  context,
  disabled,
  onChange,
  filterOption
}: BatarangParameterProps) {
  return (
    <Select
      placeholder={<Trans>{parameter.label}</Trans>}
      onChange={onChange}
      mode={parameter.multiple ? "multiple" : "default"}
      value={value as string[]}
      disabled={disabled === true}
      showSearch
      // @ts-ignore
      filterOption={filterOption}
    >
      {
        extractAllowedValues(parameter.allowedValues!, context).map(({ key, label }) =>
          <Option
            key={key}
            value={key}
          >
            {label}
          </Option>
        )
      }
    </Select>
  );
}

function extractAllowedValues(allowedValues: BatarangScriptDefinitionsResource.Script.Parameter.AllowedValues,
  context: Dictionary<any>): { key: string; label: string }[] {

  if (allowedValues.source === BatarangScriptDefinitionsResource.Script.Parameter.AllowedValues.Source.FIXED) {
    return allowedValues.values;
  }

  return context[allowedValues.contextKey];
}

const ParameterContainer = styled.div`
  margin: 0 0 10px 0;
`;

const Label = styled.span`
  font-weight: 600;
`;

const Description = styled.div`
  font-style: italic;
`;

const NotImplemented = styled.span`
  color: darkred;
  font-weight: bolder;
`;

const ParameterCheckBoxContainer = styled.div`
  margin: 0 0 10px 0;
  display: flex;
`;

const CheckBoxLabel = styled.span`
  font-weight: 600;
  padding-left: 7px;
`;