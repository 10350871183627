import { default as networkActionCreators } from "@store/network/actions";
import { createRequest } from "@src/utils/networks";
import { appendQueryString, generateQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { RawHeatMapResource } from "hyphen-lib/dist/domain/resource/report/RawHeatMapResource";

export function fetchRawHeatMapIfNeeded(surveyId: string, queryString: Dictionary<any>) {
  return networkActionCreators.fetchElementIfNeeded({
    id: RawHeatMapResource.generateId(queryString.filter, surveyId),
    type: RawHeatMapResource.TYPE,
    request: createRequest(
      appendQueryString(
        `/surveys/${surveyId}/reports/heatMap`,
        generateQueryString(queryString)
      )
    ),
  });
}
