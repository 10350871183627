import { isNullOrUndefined } from "util";
import React from "react";
import { DynamicRowProps } from "@components/core/DynamicRowsTable/withDynamicRows";
import {
  CellContainer
} from "@src/screens/Insights/SurveyList/components/cells/Cells";
import { SentimentScore } from "@components/core/SentimentScore";
import { PulsePollListOverviewResource } from "hyphen-lib/dist/domain/resource/PulsePollListOverviewResource";
import incognito from "@src/components/core/AnonymityFiltered/incognito.png";
import styled from "styled-components";
import Tooltip from "@src/components/core/Tooltip";
import { capitalizeFirstLetter } from "@src/utils/helper";
import { Trans } from "react-i18next";

export function PulsePollSentimentCell({
  row,
  index,
}: DynamicRowProps<PulsePollListOverviewResource>) {
  const { sentimentSummary } = row;
  if (isNullOrUndefined(sentimentSummary) || (row.instances && row.instances.length === 0)) {
    return <div data-cy="pulsePollList_sentimentSummary"><Trans>No data</Trans></div>;
  }

  if (sentimentSummary.filteredForAnonymity ) {
    return (
      <Tooltip title={<Trans>{capitalizeFirstLetter(sentimentSummary.explanation.reason)}</Trans>}>
        <StyledImage src={incognito} alt={"Filtered for anonymity"}/>
      </Tooltip>
    );
  }

  return (
    <CellContainer
      align="center"
      justifyContent="flex-start"
      data-cy={`pulsePollList_sentimentSummary${index}`}
    >
      <SentimentScore netSentimentScore={sentimentSummary.netSentimentScore} translate="yes"/>
    </CellContainer>
  );
}

const StyledImage = styled.img`
  height: 25px;
  opacity: 0.2;
`;
