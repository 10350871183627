import React from "react";
import { DropDownSvg } from "@components/core/svg/DropDownSvg";
import { FilterSvg } from "@components/core/svg/FilterSvg";
import { ExportSvg } from "@components/core/svg/ExportSvg";
import { ViewOptionsSvg } from "@components/core/svg/ViewOptionsSvg";
import styled from "styled-components";
import Button, { ButtonProps } from "@components/core/Button";
import { isNotNullNorUndefined } from "@hyphen-lib/lang/Objects";

type Icon = "filter" | "export" | "viewOptions"; // to be completed...

export interface ExpandButtonProps extends ButtonProps {
  onClick: () => any;
  children: React.ReactNode;
  icon?: Icon;
  className?: string;
  isExpandable?: boolean;
}

export function ExpandButton({ onClick, children, icon, className, isExpandable = true, ...rest }: ExpandButtonProps) {
  return (
    <StyledButton color="gradation"
      addleftpadding={icon}
      onClick={onClick}
      className={className}
      data-cy={`core_button_${icon}`}
      {...rest}
    >
      {
        isNotNullNorUndefined(icon) &&
        (
          <ImageContainer orientation={"left"}>
            {getIconSvg(icon)}
          </ImageContainer>
        )
      }
      {children}
      {isExpandable && (
        <ImageContainer orientation={"right"}>
          <DropDownSvg />
        </ImageContainer>
      )}
    </StyledButton>
  );
}

function getIconSvg(icon: Icon) {
  switch (icon) {
    case "filter":
      return <FilterSvg/>;
    case "export":
      return <ExportSvg/>;
    case "viewOptions":
      return <ViewOptionsSvg/>;
    default:
      return <FilterSvg/>;
  }
}

const ImageContainer = styled.span<{ orientation: "left" | "right" }>`
  position: absolute !important;
  top: 9px;
  ${props => props.orientation}: 9px;
`;

const StyledButton = styled(Button)<ButtonProps & {addleftpadding?: string}>`
  position: relative !important;
  padding-right: 36px !important;
  height: 36px !important;
  ${props => isNotNullNorUndefined(props.addleftpadding) ? "padding-left: 36px !important;" : ""}
`;
