// import  { ReactNode } from "react";
import { v4 as uuidV4 } from "uuid";
import { Notification } from "./types";
import i18next, { t } from "i18next";

export class NotificationFactory {
  notification: Notification;

  constructor(
    id: string,
    message: string,
    description: any,
    type: Notification["type"],
    duration?: number
  ) {
    this.notification = {
      uuid: id,
      message,
      description,
      type,
      duration: duration ? duration : 0,
    };
  }
}

export function success(
  message: string,
  description: any,
  duration?: number
) {
  const uuid = uuidV4();
  const notificationFactory = new NotificationFactory(
    uuid,
    i18next.exists(message) ? t(message) : message,
    typeof(description) === "string" ? 
      (i18next.exists(description) ? t(description) : description)
      : description,
    "success",
    duration
  );
  return notificationFactory.notification;
}

export function error(message: string, description: any, duration?: number) {
  const uuid = uuidV4();
  const notificationFactory = new NotificationFactory(
    uuid,
    i18next.exists(message) ? t(message) : message,
    typeof(description) === "string" ? 
      (i18next.exists(description) ? t(description) : description)
      : description,
    "error",
    duration
  );
  return notificationFactory.notification;
}

export function warning(
  message: string,
  description: any,
  duration?: number
) {
  const uuid = uuidV4();
  const notificationFactory = new NotificationFactory(
    uuid,
    i18next.exists(message) ? t(message) : message,
    typeof(description) === "string" ? 
      (i18next.exists(description) ? t(description) : description)
      : description,
    "warning",
    duration
  );
  return notificationFactory.notification;
}
