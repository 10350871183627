// @ts-nocheck
import React from "react";
import { SvgProperties } from "csstype";
import Palette from "@src/config/theme/palette";
import GenericSvg from "./GenericSvg";

function StepComplete(props: SvgProperties) {
  const { fill } = props;

  return (
    <GenericSvg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      fill={fill}
      viewBox="0 0 32 32">
      <defs>
        <path
          id="a-step-complete"
          d={`M7.866.367L3.75 4.483 2.134 2.866A1.249 1.249 0 1 0 .367 4.634l2.5 2.5a1.246 1.246 0 0 0
          1.767 0l5-5A1.249 1.249 0 1 0 7.865.367`}
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle
          cx="16"
          cy="16"
          r="12"
          fill={Palette.primary}
          stroke={Palette.primary}
          strokeWidth="2"
        />
        <use fill="#FFF" transform="translate(11 12)" xlinkHref="#a-step-complete" />
      </g>
    </GenericSvg>
  );
}

export default StepComplete;
