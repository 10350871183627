import React from "react";
import { Col, Row } from "antd";
import { Trans } from "react-i18next";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { RowProps } from "antd/lib/row";
import Select from "@components/core/SelectLarge";
import ContainerCard from "@components/core/ContainerCard";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { checkIfHyphenAdmin } from "hyphen-lib/dist/business/user/Users";

interface RecipientsFormProps {
  readonly emails: string[];
  readonly allowedEmails: string[];
  readonly showCount: boolean;
  readonly count: Loadable<number>;
  readonly disabled?: Optional<boolean>;
  readonly canRemoveManualAudience?: boolean;
  readonly onChange: (emails: string[]) => void;
  readonly hasPendingAudienceUpdates?: boolean;
  readonly canAddManualAudience?: boolean;
  readonly onErrorRemoveManualAudience: () => void;
  readonly statusOfParticipantsCount: string;
  readonly infoMessage: (status: string) => void;
  readonly isParticipantsCountForDimensionsError: boolean;
}
export class RecipientsForm extends React.Component<RecipientsFormProps> {

  state = {
    originalEmails: this.props.emails ? this.props.emails : [],
  };

  componentDidUpdate(prevProps: RecipientsFormProps) {
    if (
      prevProps.hasPendingAudienceUpdates &&
      !this.props.hasPendingAudienceUpdates
    ) {
      this.setState({ originalEmails: this.props.emails });
    }
  }

  handleSelectedEmails = (selectedEmails: any) => {
    const { originalEmails } = this.state;
    const { canRemoveManualAudience, onErrorRemoveManualAudience } = this.props;
    if (originalEmails.length > selectedEmails.length && not(canRemoveManualAudience)) {
      return onErrorRemoveManualAudience();
    }
    const { onChange } = this.props;
    onChange(selectedEmails);
  };

  isOptionDisabled = (value: string) => {
    const { disabled, canRemoveManualAudience } = this.props;

    if (disabled) {
      return true;
    }
    if (not(canRemoveManualAudience)) {
      return this.state.originalEmails.includes(value);
    }
    return false;
  };

  render() {
    const {
      emails,
      allowedEmails,
      showCount,
      count,
      canAddManualAudience,
      canRemoveManualAudience,
      statusOfParticipantsCount,
      infoMessage,
      isParticipantsCountForDimensionsError
    } = this.props;

    /* 
      antD will not show a deselect x mark for those selected elements which satisfy 
      this.isOptionDisabled and falls within the 100 elements shown. As a result of this 
      feature of antD, for launched surveys, it is found that some selected manual emails 
      are deselectable while others are not since the others fall within the 100 options and 
      satisfy the criterial for being disabled. Hence we have to sort the allowedUnselectedEmails
      to have all the selected emails at the top of the list so that all the options will be disabled uniformaly
    */
    const allowedUnselectedEmails = allowedEmails.filter(email => {
      return !emails.includes(email) && !checkIfHyphenAdmin(email);
    });

    const allowedSortedEmails = emails.concat(allowedUnselectedEmails);

    return (
      <StyledContainer>
        <ContainerCard
          title="Add recipients by email"
          // eslint-disable-next-line max-len
          description="Select from the dropdown or paste a list of comma-separated emails to add to the recipient list."
        >
          <br />

          <Row type="flex">
            <Trans>Select from email list</Trans>
            <Col span={24}>
              <Select
                disabled={not(canRemoveManualAudience || canAddManualAudience)}
                mode="multiple"
                size="large"
                placeholder={<Trans>Start typing to search</Trans>}
                dataCy="recipientForms_recipientEmail"
                numberOfValuesToShow={100}
                allowSelectionByPaste={true}
                onChange={this.handleSelectedEmails}
                data={allowedSortedEmails}
                selectedValues={emails}
                isOptionDisabled={this.isOptionDisabled}
              />
            </Col>
          </Row>
        </ContainerCard>
        {
          showCount &&
          <RecipientsRow type="flex" justify="space-between" align="middle">
            <Row type="flex" align="middle">
              <StatisticsText><Trans>Recipients added by email:</Trans></StatisticsText>
              <StatisticsSpan data-jest = "manual_count">
                {
                  isParticipantsCountForDimensionsError ?
                    0 : 
                    Loadable.mapOr(count, c => c.toString(), statusOfParticipantsCount)
                }
              </StatisticsSpan>
              {infoMessage(statusOfParticipantsCount)}
            </Row>
          </RecipientsRow>
        }
      </StyledContainer>
    );
  }
}

const StyledContainer = styled.div`
  margin: 24px 0;
`;

const RecipientsRow = styled(Row) <RowProps>`
  padding: 16px 32px;
  background-color: ${Palette.veryLightBlue};
  box-shadow: inset 0 2px 0 0 ${Palette.veryLightBlue};
`;

const StatisticsText = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-right: 7px;
`;

const StatisticsSpan = styled.div`
  color: ${Palette.darkModerateBlue};
  height: 32px;
  font-size: 24px;
  margin-bottom: 5px;
`;
