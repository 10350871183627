import { loadCookie, removeCookie, saveCookie } from "@src/utils/cookies";
import { Config } from "hyphen-lib/dist/util/Config";
import { Optional } from "hyphen-lib/dist/lang/Optionals";

const cookieSessionName = Config.getInstance().get("COOKIE_SESSION_TOKEN_KEY");

export function saveSessionToken(sessionToken: string): string {
  saveCookie(cookieSessionName, sessionToken);

  return sessionToken;
}

export function loadSessionToken(): Optional<string> {
  return loadCookie(cookieSessionName);
}

export function removeSessionToken() {
  removeCookie(cookieSessionName);
}
