import React from "react";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { SidebarMode } from "@components/Sidebar";
import { isNotNullNorUndefined } from "@hyphen-lib/lang/Objects";
import bwEngageLogo from "./assets/bw-logo.png";
import bwEngageName from "./assets/bw-name-vertical.png";

export interface SidebarHeaderProps {
  mode: SidebarMode;
  onClickLogo?: () => void;
}

export const SidebarHeader = ({ mode, onClickLogo }: SidebarHeaderProps) => (
  <SidebarHeaderContainer isClickable={isNotNullNorUndefined(onClickLogo)}>
    <SidebarHeaderInnerContainer onClick={onClickLogo} isExpanded={mode === SidebarMode.EXPANDED}>
      {
        mode === SidebarMode.EXPANDED
          ? <img src={bwEngageName} alt="bw-engage-name"/>
          : <img src={bwEngageLogo} alt="bw-engage-logo"/>
      }
    </SidebarHeaderInnerContainer>
  </SidebarHeaderContainer>
);

const SidebarHeaderContainer = styled.div<{isClickable: boolean}>`
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Palette.darkDarkDarkBlueGrey};
  cursor: ${props => isNotNullNorUndefined(props.isClickable) ? "pointer" : "default"};
`;

const SidebarHeaderInnerContainer = styled.div<{ isExpanded: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  //height: 30px;
  img {
    height: 100%;
    ${props => props.isExpanded ? `
      height: 55px;
    ` : "height: 30px"}
  }
`;
