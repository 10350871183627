import React from "react";
import { Layout as AntLayout, Col, Row } from "antd";
import styled from "styled-components";
import { RouteComponentProps, withRouter, Route } from "react-router";

import Palette from "@src/config/theme/palette";
import SurveyDetailsContainer from "@screens/Insights/Survey/containers/SurveyDetailsContainer";

interface MatchParams {
  id: string;
}

export type OwnProps = RouteComponentProps<MatchParams>;

class SurveyDetails extends React.Component<OwnProps> {
  render() {
    return (
      <Layout>
        <Content>
          <Row type="flex" justify="center">
            <Col span={24}>
              <Route component={SurveyDetailsContainer}/>
            </Col>
          </Row>
        </Content>
      </Layout>
    );
  }
}

const Layout = styled(AntLayout)`
  background: ${Palette.paleGrey} !important;
`;

const Content = styled(AntLayout.Content)``;

export default withRouter(SurveyDetails);
