import { default as networkActionCreators, RequestInfoType } from "@store/network/actions";
import { createRequest } from "@src/utils/networks";
import { SurveyResource } from "hyphen-lib/dist/domain/resource/SurveyResource";
import { ParticipantCountResource } from "hyphen-lib/dist/domain/resource/participants/ParticipantCountResource";
import { PulsePollResource } from "hyphen-lib/dist/domain/resource/PulsePollResource";

type Resource = SurveyResource | PulsePollResource;

export const fetchSurveyParticipantCountIfNeeded = participantCountIfNeeded("/surveys");
export const fetchPulsePollParticipantCountIfNeeded = participantCountIfNeeded("/pulsePolls");

function participantCountIfNeeded(
  prefixUri: string
) {
  return function fetchParticipantCount(
    id: string,
    resource: Resource,
    pendingAudience = false
  ) {

    // we have two cases, we can get the audience from what is in database, or using the audience of the
    // specified resource (survey | pulse poll)

    const requestForPendingAudience = createRequest(
      `${prefixUri}/${id}/audience/participantsCount`,
      {
        method: "POST",
        body: JSON.stringify(resource),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const requestForAudienceInDatabase = createRequest(
      `${prefixUri}/${id}/participantsCount`
    );

    const request = pendingAudience
      ? requestForPendingAudience
      : requestForAudienceInDatabase;

      return networkActionCreators.networkRequest(
        request,
        {
          info: {
            infoType:  RequestInfoType.ELEMENT,
            key: ParticipantCountResource.generateKey(id, resource.audience),
            type: ParticipantCountResource.TYPE,
          },
        }
      );
  };
}
