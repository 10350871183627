import { freeze } from "hyphen-lib/dist/lang/Objects";
import { takeLatest, put } from "redux-saga/effects";
import {
  NetworkRequestAction
} from "@store/network/actions";
import {
  CleanResourceAction,
  actionCreators as networkActionCreators
} from "@store/network/actions";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { SurveyInfoResource } from "hyphen-lib/dist/domain/resource/SurveyInfoResource";
import { ActionTypes, actionCreators } from "./actions";

export function* updateSurveySuccess(action: NetworkRequestAction) {
  yield put<CleanResourceAction>
  (networkActionCreators.cleanResource(SurveyInfoResource.TYPE));

  if (isNotNullNorUndefined(action.meta)) {
    const { surveyId } = action.meta; //
    // fixme: find a better way of doing that! We definitely don't want to have surveyId in meta!
    if (isNotNullNorUndefined(surveyId)) {
      yield put<NetworkRequestAction>
      (actionCreators.fetchAudienceEmails(surveyId, {} as any)); // fixme: refactor: useless?!
      yield put<NetworkRequestAction>
      (actionCreators.getParticipantsCount(surveyId, {} as any)); // fixme: refactor: useless?!
    }
  }
}

export function* updateSurveyList(action: NetworkRequestAction) {
  yield put<CleanResourceAction>
  (networkActionCreators.cleanResource(SurveyInfoResource.TYPE));

}

export const surveysSagas = freeze([
  takeLatest(
    ActionTypes.UPDATE_SURVEY_AND_TRIGGER_FETCH_AUDIENCE_SUCCESS,
    updateSurveySuccess
  ),
  takeLatest(
    ActionTypes.UPDATE_SURVEY_SUCCESS,
    updateSurveyList
  ),
]);
