import React from "react";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { sanitizeSegmentLabel } from "hyphen-lib/dist/domain/common/Dimensions";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import { trans } from "@src/utils/i18next";

interface Props {
  // fixme: add a status at some point... or maybe the title might be of type: React.ReactNode | string;
  title: string;
  subTitle?: Optional<string>;
  actions?: React.ReactNode;
  children: React.ReactNode;
}

export function ScreenContainer({ title, subTitle, actions, children }: Props) {
  return (
    <Container>
      <Header>
        <MainLine>
          {
            isStringAndNotEmpty(title) &&
              (<Title>
                {trans(sanitizeSegmentLabel(title))}
              </Title> )
          }
          <Actions>
            {actions}
          </Actions>
        </MainLine>
        {isNotNullNorUndefined(subTitle) && <SubTitle>{subTitle}</SubTitle>}
      </Header>
      {children}
    </Container>
  );
}

const Header = styled.div`
  margin: 0 0 24px 0;
  @media print {
    margin-bottom: 50px;
  }
`;

const MainLine = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled.h1`
  font-family: Lato;
  font-size: 30px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: ${Palette.darkBlueGrey};
  margin: 8px 0 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SubTitle = styled.div`
  font-family: Lato;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: ${Palette.bluishGrey};
  margin: 8px 0 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 0 3px 0;
`;

const Actions = styled.div`
  button {
    margin-left: 16px;
  }

  @media print {
    display: none;
    width: 0px !important;
    min-width: 0px !important;
    max-width: 0px !important;
    height: 0px !important;
    min-height: 0px !important;
    max-height: 0px !important;
  }


`;

const Container = styled.div``;
