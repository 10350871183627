import React from "react";
import { connect } from "react-redux";

import { State } from "@store/types";
import { applyDefault } from "@src/utils/Currier";

import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { getOr } from "hyphen-lib/dist/lang/Objects";
import { PostCategoryResource } from "hyphen-lib/dist/domain/resource/post/PostCategoryResource";

import { FiltersContent } from "@screens/Insights/components/FiltersContent";
import {
  CategoryFilter,
  ActionDifficultyFilter,
  ActionLengthFilter
} from "@screens/Insights/components/Filters/business/ActionPlanFilters";
import { getFilter } from "../../Actions/containers/ActionListFilterContainer/store/selectors";
import { actionPlansActionCreators } from "../store/actions";

export interface ActionTemplateFilterContainerStateProps {
  readonly innerValues: Dictionary<any>;
}

export interface ActionTemplateFilterContainerActionProps {
  readonly onModifyFilter: (filters: any) => any;
  readonly onCleanFilter: () => any;
}

export interface ActionTemplateFilterContainerOwnProps {
  readonly values?: Dictionary<any>;
  readonly onApply: (filters: any) => any;
  readonly categories: PostCategoryResource[];
}

export type ActionTemplateFilterContainerProps = ActionTemplateFilterContainerOwnProps &
ActionTemplateFilterContainerStateProps &
ActionTemplateFilterContainerActionProps;

export class ActionTemplateFilter extends React.Component<ActionTemplateFilterContainerProps> {
  componentDidMount() {
    /*
        Modify filters with initial values while mounting.
     */
    this.props.onModifyFilter(getOr(this.props.values, {}));
  }

  componentWillUnmount(): void {
    this.props.onCleanFilter();
  }

  render() {
    const {
      values,
      categories,
      onApply,
      onModifyFilter,
    } = this.props;

    return (
      <FiltersContent
        filters={[
          {
            key: "difficulty",
            label: "Difficulty",
            component: ActionDifficultyFilter,
          },
          {
            key: "length",
            label: "Length",
            component: ActionLengthFilter,
          },
          {
            key: "categories",
            label: "Categories",
            component: applyDefault(
              CategoryFilter,
              {
                categories,
              }
            ),
          },
        ]}
        displayCount={false}
        onChange={onModifyFilter}
        onApply={onApply}
        values={values}
      />
    );
  }
}

function mapStateToProps(
  state: State
): ActionTemplateFilterContainerStateProps {
  const innerFilterValues = getFilter(state);

  return {
    innerValues: getOr(innerFilterValues, {}),
  };
}

const mapDispatchToProps = {
  onModifyFilter: actionPlansActionCreators.modifyFilter,
  onCleanFilter: actionPlansActionCreators.cleanFilter,
};

export const ActionTemplateFilterContainer = connect(mapStateToProps, mapDispatchToProps)(ActionTemplateFilter);
