import React from "react";
import styled from "styled-components";
import { DashboardResource } from "@hyphen-lib/domain/resource/report/DashboardResource";
import { SurveyHighlight, PulsePollHighlight } from "@hyphen-lib/domain/resource/report/common/Highlights";
import Palette from "@src/config/theme/palette";
import { Heading } from "@components/core/Typography";
import Button from "@components/core/Button";

import { goTo, Breadcrumb } from "@src/utils/locations";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { isPulsePollEnabled } from "hyphen-lib/dist/business/company/Companies";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import Activity from "./components/Activity";

interface Props {
  data: DashboardResource.LatestActivity;
  company?: Optional<CompanyResource>;
  hasAccessToPulsePollsList?: boolean;
}

function LatestActivity({ data, company, hasAccessToPulsePollsList }: Props) {
  const maxActivitiesVisible = 3;

  const goToSurvey = (surveyId: string) => {
    goTo(`/surveys/view/${surveyId}/reports/overview`, Breadcrumb.stack("Dashboard"));
  };

  const goToSurveys = () => {
    goTo("/surveys?force=true", Breadcrumb.stack("Dashboard"));
  };

  const goToPulsePoll = (activity: PulsePollHighlight) => {
    if (isNotNullNorUndefined(activity.lastInstance)) {
      goTo(
        `/pulsePolls/${activity.id}/instances/${activity.lastInstance.id}/reports/overview`,
        Breadcrumb.stack("Dashboard")
      );
    }
  };

  const goToPulsePolls = () => {
    goTo("/pulsePolls?force=true", Breadcrumb.stack("Dashboard"));
  };

  return (
    <Container data-cy="dashboard_latestActivitySection">
      <Heading size="large" data-cy="latestActivitySection" translate="yes">
        Latest Activity
      </Heading>

      <SectionContainer>
        <SectionHeader>
          <Heading size="small" weight="bold" translate="yes">
            Surveys
          </Heading>
        </SectionHeader>
        <ActionPlanList>
          {data.surveys.slice(0, maxActivitiesVisible).map((activity: SurveyHighlight) => (
            <StyledLink key={activity.id} onClick={goToSurvey.bind(null, activity.id)}
              data-cy={`latestActivitySurvey_${activity.id}`}
            >
              <Activity key={activity.id} data={activity} />
            </StyledLink>
          ))}
        </ActionPlanList>
        <ActionButtons>
          <StyledLink onClick={goToSurveys}>
            <Button className="block--print-hide" color="blue" data-cy="lt_viewAllSurveyBtn" translate="yes">
              View all Surveys</Button>
          </StyledLink>
        </ActionButtons>
      </SectionContainer>

      {
        company && isNotEmptyArray(data.pulsePolls) && isPulsePollEnabled(company) ? (
          <SectionContainer>
            <SectionHeader>
              <Heading size="small" weight="bold" translate="yes">
                Polls
              </Heading>
            </SectionHeader>
            <ActionPlanList>
              {data.pulsePolls.slice(0, maxActivitiesVisible).map((activity: PulsePollHighlight) => (
                <StyledLink
                  key={activity.id}
                  onClick={goToPulsePoll.bind(null, activity)}
                  data-cy={`latestActivityPoll_${activity.id}`}
                >
                  <Activity key={activity.id} data={activity} />
                </StyledLink>
              ))}
            </ActionPlanList>
            <ActionButtons>
              {
                hasAccessToPulsePollsList && (
                  <StyledLink onClick={goToPulsePolls}>
                    {/* eslint-disable max-len */}
                    <Button className="block--print-hide" color="blue" data-cy="lt_viewAllPollBtn" translate="yes">
                      View all Polls</Button>
                    {/* eslint-disable max-len */}
                  </StyledLink>
                )
              }
            </ActionButtons>
          </SectionContainer>
        ) : null
      }
    </Container>
  );
}

const Container = styled.div`
  padding: 32px;
  background: ${Palette.white};
  font-family: Lato, sans-serif;
  color: ${Palette.veryDarkBlueGrey};
`;

const SectionContainer = styled.div`
  margin-top: 24px;
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${Palette.bluishGrey};
`;

const ActionPlanList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;

  > * {
    margin: 8px 0;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin: 16px -8px;

  > * {
    margin: 0 8px;
  }
`;

const StyledLink = styled.span`
  cursor: pointer;
`;

export default LatestActivity;
