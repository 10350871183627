import React from "react";
import { connect } from "react-redux";

import { State } from "@store/types";
import notificationActionCreators from "@src/store/notifications/actions";

import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { isNotNullNorUndefined, isNullOrUndefined, isEmptyObject } from "hyphen-lib/dist/lang/Objects";

import SlackSettings from "@screens/Insights/Settings/components/SlackSettings";
import BeekeeperSettings from "@screens/Insights/Settings/components/BeekeeperSettings";
import Workplace from "@screens/Insights/Settings/components/WorkplaceSettings";
import AreYouSureModal from "@components/core/AreYouSureModal";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { Config } from "hyphen-lib/dist/util/Config";
import { getCurrentUser } from "../../../store/selectors";
import { getCompany } from "../../store/selectors";
import { settingsActionCreators } from "../../store/actions";
import * as NotificationFactory from "@src/store/notifications/notification-factory";
interface IntegrationContainerReduxStoreProps {
  company: CompanyResource;
  companyName: CompanyResource["name"];
}

interface IntegrationState {
  isRemoveIntegrationModalOpen: boolean;
  removeIntegration: "beekeeper" | "workplace" | "slack" | null;
}

export type IntegrationSettingsProps =
  typeof settingsActionCreators &
  typeof notificationActionCreators &
  IntegrationContainerReduxStoreProps &
  RouteComponentProps;

export class IntegrationContainer extends React.Component<IntegrationSettingsProps, IntegrationState>{
  constructor(props: IntegrationSettingsProps) {
    super(props);

    this.state = {
      isRemoveIntegrationModalOpen: false,
      removeIntegration: null,
    };
  }

  componentDidMount() {
    const { company, companyName, fetchCompany, location } = this.props;
    const { integrate } = parseQueryString(location.search);

    /* the query params integrate=slack gets added when the Add to Slack button
    in the slack app's landing page is clicked */
    if (
      isNotNullNorUndefined(integrate) &&
      integrate === "slack"
    ) {
      if (!this.isIntegratedWithSlack()) {
        return this.integrateWithSlack();
      }
      notificationActionCreators.displayNotification(
        NotificationFactory.success(
          "Slack is already integrated",
          "",
          4.5
        )
      );
    }
    if (isEmptyObject(company)) {
      fetchCompany(companyName);
    }
  }

  isIntegratedWithSlack = () => {
    const { company } = this.props;
    return company.channels.slack && company.channels.slack.accessKey;
  };

  integrateWithSlack = () => {
    const config = Config.getInstance();
    const backendURLPrefix = config.get("BACKEND_BASE_URL");
    return window.location.href = `${backendURLPrefix}/integrations/slack`;
  };

  toggleAreYouSureModal(
    isRemoveIntegrationModalOpen: boolean,
    removeIntegration: "beekeeper" | "workplace" | "slack" | null
  ) {
    this.setState({
      isRemoveIntegrationModalOpen,
      removeIntegration,
    });
  }

  updateChannelIntegrations = (payload: CompanyResource["channels"]) => {
    const { companyName, company } = this.props;
    this.props.updateCompany(
      companyName,
      {
        ...company,
        channels: {
          ...company.channels,
          ...payload,
        },
      }
    );
  };

  removeChannelIntegration = (payload: "beekeeper" | "workplace" | "slack") => {
    const { companyName, company } = this.props;
    this.props.updateCompany(
      companyName,
      {
        ...company,
        channels: {
          ...company.channels,
          [payload]: {},
        },
      }
    );
  };

  closeModalAndRemoveIntegration = () => {
    const { removeIntegration } = this.state;
    if (isNotNullNorUndefined(removeIntegration)) {
      this.setState({
        isRemoveIntegrationModalOpen: false,
        removeIntegration: null,
      });
      this.removeChannelIntegration(removeIntegration);
    }
  };

  render() {
    const { company } = this.props;

    const { isRemoveIntegrationModalOpen, removeIntegration } = this.state;

    const slackSettings = (isNotNullNorUndefined(company.channels) &&
      isNotNullNorUndefined(company.channels.slack))
      ? company.channels.slack
      : {
        subdomain: "",
        accessKey: "",
      };

    const beekeeperSettings = (isNotNullNorUndefined(company.channels) &&
    isNotNullNorUndefined(company.channels.beekeeper))
      ? company.channels.beekeeper
      : {
        subdomain: "",
        accessKey: "",
        importUsers: false,
      };

    const workplaceSettings = (isNotNullNorUndefined(company.channels) &&
      isNotNullNorUndefined(company.channels.workplace))
      ? company.channels.workplace
      : {
        accessKey: "",
      };

    return (
      <div>
        <SlackSettings
          accessKey={slackSettings.accessKey}
          updateCompany={this.updateChannelIntegrations}
          removeIntegration={this.toggleAreYouSureModal.bind(this, true, "slack")}
          integrateWithSlack={this.integrateWithSlack}
        />

        <BeekeeperSettings
          subdomain={beekeeperSettings.subdomain}
          accessKey={beekeeperSettings.accessKey}
          importUsers={beekeeperSettings.importUsers}
          updateCompany={this.updateChannelIntegrations}
          removeIntegration={this.toggleAreYouSureModal.bind(this, true, "beekeeper")}
        />

        <Workplace
          accessKey={workplaceSettings.accessKey}
          updateCompany={this.updateChannelIntegrations}
          removeIntegration={this.toggleAreYouSureModal.bind(this, true, "workplace")}
        />

        {isRemoveIntegrationModalOpen && (
          <AreYouSureModal
            visible={isRemoveIntegrationModalOpen}
            title="Are you sure you want to remove this integration?"
            description=""
            okLabel="Delete"
            cancelLabel="Cancel"
            onCancel={this.toggleAreYouSureModal.bind(this, false, removeIntegration)}
            onOk={this.closeModalAndRemoveIntegration}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: State): IntegrationContainerReduxStoreProps => {
  let company = getCompany(state).get(0);
  if (isNullOrUndefined(company)) {
    company = {} as CompanyResource;
  }

  let companyName = "";
  const currentUser = getCurrentUser(state);
  if (isNotNullNorUndefined(currentUser) &&
    isNotNullNorUndefined(currentUser.company) &&
    isNotNullNorUndefined(currentUser.company.name)) {
    companyName = currentUser.company.name;
  }
  return {
    company,
    companyName,
  };
};

export default connect(
  mapStateToProps,
  { ...settingsActionCreators, ...notificationActionCreators }
)(withRouter(IntegrationContainer));
