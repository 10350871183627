import React from "react";
import styled from "styled-components";
import { Trans } from "react-i18next";
import { Survey } from "@hyphen-lib/domain/Survey";
import { formatDate } from "@hyphen-lib/lang/Dates";
import { SurveyRemindersStateProps } from "@screens/Insights/Survey/containers/SurveyRemindersContainer/store/types";
import { SurveyInfoResourceRecord } from "@screens/Insights/Surveys/store/types";
import Palette from "@src/config/theme/palette";
import Button from "@components/core/Button";
import { SurveyInfoResource } from "hyphen-lib/dist/domain/resource/SurveyInfoResource";
import { Note } from "@src/screens/Insights/Surveys/components/MagicSurveySection";

interface Props {
  reminders: SurveyRemindersStateProps;
  surveyInfo: SurveyInfoResourceRecord;
  toggleReminderModalOpen: (reminderModalOpen: boolean, selectedSurveyInfoResource: SurveyInfoResource) => void;
  readonly canSendReminders: boolean;
}

function formatDateWithTime(date: Date) {
  return formatDate(date, {
    hour: "numeric",
    minute: "numeric",
  });
}

function SurveyReminders({ reminders: { data }, surveyInfo, toggleReminderModalOpen, canSendReminders }: Props) {
  const capitalizeFirstLetter = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

  const surveyStatus = surveyInfo.get("status");
  const isMagicSurvey = surveyInfo.get("isMagic");
  function toggleModal() {
    toggleReminderModalOpen(true, surveyInfo);
  }

  return (
    <>
      { 
        isMagicSurvey && <Note>
          <Trans>Reminders would need to be sent manually to participants using the web link,
            by resending the link. Warning: It might prompt those particpants to respond again</Trans>
        </Note>
      }
      <Container>
        {(surveyStatus !== Survey.Statuses.CLOSED && canSendReminders) && (
          <Button
            onClick={toggleModal}
            color="blue"
            translate="yes"
          >
            Send a manual reminder
          </Button>
        )}
        {data.nextScheduledReminderAt && (
          <Event>
            {formatDateWithTime(data.nextScheduledReminderAt)} - <Trans>Next planned reminder</Trans>
          </Event>
        )}
        {data.reminders.map(reminder => {
          const date = formatDateWithTime(reminder.sentAt);
          return (
            <Reminder key={date}>
              <ReminderDate>{date}</ReminderDate>
              <ReminderType><Trans>{capitalizeFirstLetter(reminder.type)}</Trans>&nbsp;
              <Trans>reminder</Trans></ReminderType>
              <div>{reminder.numberOfRecipients} <Trans>recipients</Trans></div>
            </Reminder>
          );
        })}
        <Event>
          {formatDate(data.launchedAt)} <Trans>Survey launched</Trans>
        </Event>
      </Container>
    </>
  );
}

const Container = styled.div`
  margin-top: 24px;
  font-family: Lato, sans-serif;
  position: relative;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 32px;
    width: 3px;
    transform: translateX(-50%);
    background: ${Palette.lightPeriwinkle};
  }
`;

const Event = styled.div`
  color: ${Palette.veryDarkBlueGrey};
  font-size: 16px;
  font-weight: bold;
  margin: 48px 0;
  padding-left: 56px;
  line-height: 1;
  position: relative;

  &:before {
    position: absolute;
    content: "";
    left: 32px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transform: translateX(-50%);
    background: ${Palette.white};
  }

  &:after {
    position: absolute;
    content: "";
    left: 32px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    transform: translate(-50%, 1px);
    background: ${Palette.aquaBlue};
  }
`;

const Reminder = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 24px 32px;
  margin-top: 24px;
  color: ${Palette.veryDarkBlueGrey};
  font-size: 14px;
  border-radius: 3px;
  background: ${Palette.white};
  box-shadow: 0 4px 8px 0 rgba(198, 214, 223, 0.15);
`;

const ReminderDate = styled.div`
  flex: 0 0 256px;
  color: ${Palette.bluishGrey};
`;

const ReminderType = styled.div`
  flex-grow: 1;
`;

export default SurveyReminders;
