import React from "react";
import styled from "styled-components";
import { Form } from "antd";
import { List, Record, RecordOf } from "immutable";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { PostCategoryResource } from "hyphen-lib/dist/domain/resource/post/PostCategoryResource";
import { Action } from "hyphen-lib/dist/domain/Action";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";
import { getOr, isNotEmptyObject, isNotNullNorUndefined, mapOr } from "hyphen-lib/dist/lang/Objects";
import { SurveyInfoResource } from "hyphen-lib/dist/domain/resource/SurveyInfoResource";
import { Paragraph } from "@components/core/Typography";
import { State } from "@store/types";
import { actionCreators as SurveysActionCreator } from "@screens/Insights/Surveys/store/actions";
import { getManageesEmails, getPostCategorys } from "@screens/Insights/Surveys/store/selectors";
import { getCurrentUser } from "@screens/Insights/store/selectors";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { PulsePollInfoResource } from "hyphen-lib/dist/domain/resource/PulsePollInfoResource";
import { ActionPlanSettings, ActionPlanSettingsErrors, ActionPlanValues } from "../components/ActionPlanSettings";
import { NotificationSettings } from "../components/NotificationSettings";
import { actionPlansActionCreators, CreateActionPayload } from "../store/actions";
import { getErrorsForActionPlan } from "../utils/validations";
import { ActionSourceDescription } from "../components/ActionSourceDescription";
import { getActionPlansState, getSurveyInfo, getPulsePollInfo } from "../store/selectors";
import { generateAction } from "../utils/helpers";
import { CreateActionPlanStateProps } from "./CreateActionPlanContainer";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import ActionPlanOKR from "../components/ActionPlanOKRSection";
import { ActionResource } from "hyphen-lib/dist/domain/resource/action/ActionResource";
import Button from "@src/components/core/Button";
import Palette from "@src/config/theme/palette";
import { AppSettingsResource } from "hyphen-lib/dist/domain/resource/AppSettingsResource";
import { getAppSettings } from "../../Settings/store/selectors";
import { getOkrSynonyms } from "hyphen-lib/dist/business/appSetting/AppSettings";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { UserEmailInsightAccess } from "hyphen-lib/dist/domain/User";
import { Trans, WithTranslation, withTranslation } from "react-i18next";
const { WithOKRType } = ActionResource;

interface ReduxStateProps {
  categories: PostCategoryResource[];
  currentUser: CurrentUserResource;
  mangeeEmails: List<UserEmailInsightAccess>;
  isNetworkRequesting: boolean;
  surveyInfo: SurveyInfoResource;
  pulsePollInfo: PulsePollInfoResource;
  isAddingAction: boolean;
  objectiveList: Optional<ActionResource.Objective[]>;
  fetchingObjective: boolean;
  appSettings: AppSettingsResource;
}
interface ReduxActionProps {
  fetchPostCategories: () => void;
  fetchManageesEmails: (userId: string, status?: string) => void;
  fetchSurveyIfNeeded: (surveyId: string) => void;
  fetchPulsePollInfo: (pulsePollId: string) => void;
  addActionPlan: (payload: CreateActionPayload, redirectPath?: string) => void;
  showOkrFeatureNotification: () => void;
  fetchGoals: (searchQuery?: string) => void;
}
interface OwnProps {
  isFromSource: () => boolean;
  isOKREnabled: Optional<boolean>;
}
interface QueryParams extends Action.Source {
  templateId?: string;
}

export interface ActionPlanFromScratchProps
  extends OwnProps,
  ReduxStateProps,
  ReduxActionProps,
  WithTranslation,
  RouteComponentProps {
  setClick: (click: any) => void;
  defaultValues: CreateActionPlanStateProps["defaultValues"];
}
interface ActionPlanFromScratchStateProps extends ActionPlanValues {
  errors: RecordOf<ActionPlanSettingsErrors>;
  isModalOpen: boolean;
}
export class ActionPlanFromScratchContainer extends React.Component<
  ActionPlanFromScratchProps,
  ActionPlanFromScratchStateProps
> {
  constructor(props: ActionPlanFromScratchProps) {
    super(props);

    const { defaultValues, currentUser } = props;
    const dueDate = new Date();
    dueDate.setDate(dueDate.getDate() + 30);

    const defaultErrors: ActionPlanSettingsErrors = {
      action: "",
      assignee: "",
      category: "",
      dueDate: "",
      description: "",
      okr: {},
    };
    const errorStateFactory = Record<ActionPlanSettingsErrors>(defaultErrors);
    const { context } = this.getQueryParams();
    const action = getOr(defaultValues.action, "");
    const description = getOr(defaultValues.description, "");
    const resource = getOr(defaultValues.resource, "");
    const notifications = getOr(defaultValues.notifications, {
      numberOfDaysBeforeDueDate: 7,
      overdueReminderInterval: 3,
    });

    const category = isNotNullNorUndefined(context) && isNotNullNorUndefined(context.category) ? context.category : "";
    this.state = {
      category,
      action,
      description,
      assignee: currentUser.email,
      resource,
      dueDate,
      notifications,
      errors: errorStateFactory(),
      isModalOpen: false,
      okr: { type: WithOKRType.OBJECTIVE }
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const {
      categories,
      fetchPostCategories,
      fetchManageesEmails,
      currentUser,
      isOKREnabled,
      fetchSurveyIfNeeded,
      fetchPulsePollInfo,
      setClick,
      isFromSource,
    } = this.props;
    const { context } = this.getQueryParams();

    // make network request to fetch post categories only if not already fetched
    if (categories.length === 0) {
      fetchPostCategories();
    }

    fetchManageesEmails(currentUser._id);

    if (isFromSource() && isNotNullNorUndefined(context) && isNotNullNorUndefined(context.surveyId)) {
      fetchSurveyIfNeeded(context.surveyId);
    }

    if (isFromSource() && isNotNullNorUndefined(context) && isNotNullNorUndefined(context.pulsePollId)) {
      fetchPulsePollInfo(context.pulsePollId);
    }
    if (isOKREnabled) {
      this.props.fetchGoals();
    }

    if (isFromSource() && isNotNullNorUndefined(context) && isNotNullNorUndefined(context.focusAreaId)) {
      this.setInitialActionPlanDetailsFromFocusArea(context);
    }

    setClick(this.onSubmit);
  }

  onSettingsChange = (fieldType: keyof ActionPlanValues, value: any) => {
    const { errors } = this.state;
    const payload = { [fieldType]: value, errors: errors.set(fieldType, "") };
    // @ts-ignore
    this.setState(payload);
  };

  setInitialActionPlanDetailsFromFocusArea = (context: Dictionary<string>) => {
    if (isNotNullNorUndefined(context.action)) {
      this.onSettingsChange("action", context.action);
    }

    if (isNotNullNorUndefined(context.resource)) {
      this.onSettingsChange("resource", context.resource);
    }

    if (isNotNullNorUndefined(context.description)) {
      this.onSettingsChange("description", context.description);
    }
  };

  getQueryParams = (): QueryParams => {
    const { location } = this.props;
    // FIXME
    // @ts-ignore
    return parseQueryString(location.search);
  };

  onSubmit(e: React.FormEvent) {
    e.preventDefault();
    const {
      action,
      assignee,
      dueDate,
      category,
      description,
      resource,
      notifications,
    } = this.state;
    const { okr } = this.state;
    const { errors } = this.state;
    const { context, label, type, templateId } = this.getQueryParams();
    const { currentUser, addActionPlan, surveyInfo, pulsePollInfo, isFromSource } = this.props;
    let source: Action.Source = {
      context: {
        category,
        ...(
          isNotNullNorUndefined(context) && 
          isNotNullNorUndefined(context.focusAreaId) && 
          {
            focusAreaId: context.focusAreaId
          }
        ),
      },
      label: category,
      type: Action.SourceType.CATEGORY,
    };
    // If action is from some source
    if (isFromSource()) {
      if (type === Action.SourceType.PULSE_POLL_SEGMENT || type === Action.SourceType.PULSE_POLL_COMMENT) {
        source = {
          context: {
            ...context,
            pulsePollName: pulsePollInfo.question,
          },
          label,
          type,
        };
      } else {
        source = {
          context: {
            ...context,
            surveyName: surveyInfo.name,
          },
          label: getOr(label, ""),
          type: getOr(type, Action.SourceType.CATEGORY),
        };
      }
    }
    let finalOkrData = { ...okr };
    if (isNotEmptyObject(okr)) {
      if (okr.type === WithOKRType.OBJECTIVE) {
        finalOkrData = { ...okr, name: action };
      }
    }
    const payload = generateAction(source, {
      action,
      dueDate,
      assignee,
      category,
      createdBy: currentUser.email,
      description,
      resource,
      actionPlanTemplateId: templateId,
      notifications,
      okr: finalOkrData,
    });
    const consistencyErrors = getErrorsForActionPlan(this.props.t, payload);
    if (consistencyErrors.size > 0) {
      const fieldErrorObj: ActionPlanSettingsErrors = errors.toObject();

      // Get an object of the updated
      consistencyErrors.keySeq().forEach((key: any) => {
        if (errors.has(key)) {
          // @ts-ignore
          fieldErrorObj[key] = consistencyErrors.get(key);
        }
      });
      this.setState({ errors: errors.merge(fieldErrorObj) });
    } else {
      addActionPlan(payload);
    }
  }

  updateNotificationSettings = (notifications: ActionResource.NotificationsConfiguration) => {
    this.onSettingsChange(
      "notifications", notifications
    );
  };

  showText = () => {
    const { okr } = this.state;
    const { isOKREnabled, appSettings } = this.props;
    const okrSynonyms = getOkrSynonyms(appSettings);
    if (isNotEmptyObject(okr) && isOKREnabled) {
      const text = (okr.type === WithOKRType.KEY_RESULT) ? okrSynonyms.keyResult : okrSynonyms.objective;
      return (
        <SmallText>
          <Trans i18nKey="actionPlanCreateNote" defaults={`Your ${text} will be published in betterworks 
          ${okrSynonyms.okrs} automatically`}
           values={{objective: text, okr: okrSynonyms.okrs}}/>
        </SmallText>
      );
    }
  };

  showSubmitButton() {
    const { isAddingAction } = this.props;
    return (
      <SubmitButtonContainer>
        { this.showText() }
        <Button
          data-jest="createActionPlanButton"
          data-cy="createActionPlan"
          color="blue"
          disabled={isAddingAction}
          onClick={this.onSubmit}
          translate="yes">
          { isAddingAction
             ? "Creating the action plan..."
             : "Create the action plan"
          }
        </Button>
      </SubmitButtonContainer>
    );
  }

  showActionPlanToOKRSection = () => {
    const {
      action,
      category,
      assignee,
      dueDate,
      description,
      resource,
      okr,
      errors,
    } = this.state;
    const values = {
      action,
      category,
      assignee,
      dueDate,
      description,
      resource,
      okr,
    };
    const { 
      isOKREnabled,
      showOkrFeatureNotification,
      appSettings 
    } = this.props; 
    const okrError = errors.get("okr");
    const okrSynonyms = getOkrSynonyms(appSettings);
    return (
      <OKRSection>
        <ActionPlanOKR
          okrNamingConvention={okrSynonyms}
          currentUserEmail={this.props.currentUser.email}
          fetchingObjective={this.props.fetchingObjective}
          isOKREnabled={isOKREnabled!}
          data-jest="actionPlanOkrSection"
          showOkrFeatureNotification={showOkrFeatureNotification}
          values={values}
          isDisabled={false}
          isEditScreen={false}
          onSettingsChange={this.onSettingsChange}
          objectiveList={this.props.objectiveList}
          fetchGoals={this.props.fetchGoals} />
        {
          isNotEmptyObject(okrError) && (<OkrErrorContainer data-cy="okrFormValidationError">
            { okrError }
          </OkrErrorContainer>)
        }
      </OKRSection>
    );
  };

  render() {
    const { type } = this.getQueryParams();
    const isSurveyQuestionType = isNotNullNorUndefined(type) && type === Action.SourceType.SURVEY_QUESTION;
    const {
      categories, 
      mangeeEmails, 
      surveyInfo, 
      pulsePollInfo, 
      currentUser, 
      isFromSource,
    } = this.props;
    const {
      action,
      category,
      assignee,
      dueDate,
      errors,
      description,
      resource,
      okr,
    } = this.state;
    const values = {
      action,
      category,
      assignee,
      dueDate,
      description,
      resource,
      okr,
    };
    const notifications = getOr(this.state.notifications, {});
    let title;

    if (
      type === Action.SourceType.INDIVIDUAL_RESULT ||
      type === Action.SourceType.SURVEY_CATEGORY ||
      type === Action.SourceType.SURVEY_COMMENT ||
      type === Action.SourceType.SURVEY_QUESTION ||
      type === Action.SourceType.SURVEY_SEGMENT
    ) {
      title = surveyInfo.name;
    } else if (
      type === Action.SourceType.PULSE_POLL_SEGMENT ||
      type === Action.SourceType.PULSE_POLL_COMMENT
    ) {
      title = pulsePollInfo.question;
    }
    return (
      <FormSection>
        <Form>
          <Title weight="bold"><Trans>Create a new action plan</Trans></Title>
          {isFromSource() && (
            <SourceContainer>
              <Label>
                <Paragraph translate="yes">You're creating an Action Plan for:</Paragraph>
              </Label>
              <ActionSourceDescription
                title={title}
                {...this.getQueryParams()}
              />
            </SourceContainer>
          )}
          <Container>
            <MainSection>
              <ActionPlanSettings
                categories={(not(isFromSource()) || isSurveyQuestionType) ? categories : undefined}
                onSettingsChange={this.onSettingsChange}
                values={values}
                availableAssignees={mangeeEmails}
                errors={errors}
                myself={currentUser.email}
                isDisabled={false}
                currentUser={currentUser}
              />
              <NotificationSettings
                notifications={notifications}
                updateNotificationSettings={this.updateNotificationSettings}
                isDisabled={false}
              />
            </MainSection>
            { this.showActionPlanToOKRSection() }
          </Container>
        </Form>
        { this.showSubmitButton() }
      </FormSection>
    );
  }
}

const SourceContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-start;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const MainSection = styled.div`
  width: 60%;
`;

const Label = styled.div`
  width: 212px;
  margin-right: 26px !important;
`;

const Title = styled(Paragraph)`
  margin-top: 9px !important;
`;

const OKRSection = styled.div`
  max-width: 100%;
  height: fit-content;
  margin-left: 80px;
  background: ${Palette.athensGrey};
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 107%;
  right: -12px;
  margin-right: -20px;
`;

const SmallText = styled.p`
  color: ${Palette.bluishGrey};
  font-family: Lato;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  margin-right: 24px;
  align-self: center;
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const OkrErrorContainer = styled.div`
  margin-top: 8px;
  color: ${Palette.alizarin};
  position: absolute;
`;

function mapStateToProps(state: State, ownProps: OwnProps): ReduxStateProps {
  const currentUser = mapOr(
    getCurrentUser(state),
    (user: CurrentUserResource) => user,
    {} as CurrentUserResource
  );
  const appSettings = getOr(getAppSettings(state).get(0), {} as AppSettingsResource);

  const { 
    isNetworkRequesting, 
    isAddingAction, 
    objectiveList, 
    fetchingObjective 
  } = getActionPlansState(state);
  return {
    categories: getPostCategorys(state).toArray(),
    currentUser,
    mangeeEmails: getManageesEmails(state),
    isNetworkRequesting,
    surveyInfo: getSurveyInfo(state),
    pulsePollInfo: getPulsePollInfo(state),
    isAddingAction,
    objectiveList,
    fetchingObjective,
    appSettings
  };
}

const mapDispatchToProps = {
  fetchPostCategories: SurveysActionCreator.fetchPostCategorys,
  fetchManageesEmails: SurveysActionCreator.fetchManageesEmails,
  fetchSurveyIfNeeded: actionPlansActionCreators.fetchSurveyIfNeeded,
  fetchPulsePollInfo: actionPlansActionCreators.fetchPulsePollInfo,
  addActionPlan: actionPlansActionCreators.addActionPlan,
  showOkrFeatureNotification: actionPlansActionCreators.showOkrFeatureNotification,
  fetchGoals: actionPlansActionCreators.fetchObjectiveList,
};

export const ActionPlanFromScratch = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ActionPlanFromScratchContainer)));
