import React from "react";
import { Map as ImmutableMap, Set as ImmutableSet } from "immutable";
import { SurveyResource } from "hyphen-lib/dist/domain/resource/SurveyResource";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { MergeTagResource } from "hyphen-lib/dist/domain/resource/MergeTagResource";
import { SurveyTypeResource } from "hyphen-lib/dist/domain/resource/SurveyTypeResource";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import { GeneralSettingsForm } from "@screens/Insights/Surveys/components/GeneralSettingsForm";
import { CommunicationForm } from "@screens/Insights/Surveys/components/CommunicationForm";
import LabelMapper from "@src/utils/constants/SurveyCreation";
import { entries } from "hyphen-lib/dist/lang/Objects";
import { SurveyDisabledFields } from "@screens/Insights/Surveys/store/surveyEditTypes";

export interface SettingsProps {
  survey: SurveyResource;
  allowedMergeTags: MergeTagResource[];
  allowedChannels: CompanyResource.Channels;
  allowedTypes: SurveyTypeResource[];
  errors: ImmutableMap<string, WrongEntityException.WrongField>;
  disabled?: SurveyDisabledFields;
  configuredCompanyLanguages: string[];
  configuredSurveylanguages: string[];
  isMultiLanguageSurveyEnabled: boolean;

  onChange: (survey: SurveyResource) => void;
  onUploadSurveyLogo: (err: any, file: any) => void;
}

const COMMUNICATION_FIELDS = ImmutableSet([
  "channels",
  "reminderLimit",
  "reminderInterval",
]);

export class Settings extends React.Component<SettingsProps> {

  handleChange = (modifications: Partial<SurveyResource>) => {
    const {
      survey,
      onChange,
    } = this.props;

    onChange({
      ...survey,
      ...modifications,
    });
  };

  render() {
    const {
      survey,
      allowedMergeTags,
      allowedChannels,
      allowedTypes,
      errors,
      disabled,

      onUploadSurveyLogo,
    } = this.props;


    const areCommunicationDisabled = entries(disabled as object)
      .filter((value, key) => COMMUNICATION_FIELDS.has(key))
      .some(value => value === true);

    return (
      <>
        <GeneralSettingsForm
          configuredCompanyLanguages={this.props.configuredCompanyLanguages}
          configuredSurveylanguages={this.props.configuredSurveylanguages}
          isMultiLanguageSurveyEnabled={this.props.isMultiLanguageSurveyEnabled}
          values={survey}
          allowedTypes={allowedTypes}
          allowedMergeTags={allowedMergeTags}
          errors={errors}
          disabled={disabled}
          onChange={this.handleChange}
          onUploadSurveyLogo={onUploadSurveyLogo}
        />
        <CommunicationForm
          labelMapper = {LabelMapper}
          values={survey}
          allowedMergeTags={allowedMergeTags}
          allowedChannels={allowedChannels}
          errors={errors}
          disabled={areCommunicationDisabled}
          onChange={this.handleChange}
          configuredSurveyLanguages={this.props.configuredSurveylanguages}
          enableTranslation={true}
          isMultiLanguageSurveyEnabled={this.props.isMultiLanguageSurveyEnabled}
        />
      </>
    );
  }
}
