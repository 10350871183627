import React from "react";
import styled from "styled-components";
import { Layout as AntLayout } from "antd";

import Palette from "@src/config/theme/palette";
import { isOnlyEmployeeVoiceModuleEnabled } from "@src/utils/modules";

import { CompareWithOption } from "@screens/Insights/components/ViewOptions/components/CompareWith";
import SurveyReportHeader from "@screens/Insights/Survey/components/SurveyReportHeader";
import Comments, { CommentsData } from "@screens/Insights/Survey/components/OverviewReport/components/Comments";
import Spin from "@components/core/Spin";
import { Filter } from "@components/core/FilterLabels/types";
import { AnonymityFiltered } from "@components/core/AnonymityFiltered";

import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { RightsMatcher } from "hyphen-lib/dist/business/auth/Auth";
import { Rights } from "hyphen-lib/dist/business/auth/Rights";

import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
import { Company } from "hyphen-lib/dist/domain/Company";
import { SegmentReportResource } from "hyphen-lib/dist/domain/resource/report/SegmentReportResource";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";

import { formatDate, utcNow } from "hyphen-lib/dist/lang/Dates";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { getOr } from "hyphen-lib/dist/lang/Objects";
import TotalFavorability from "@src/screens/Insights/Dashboard/components/DashboardView/components/TotalFavorability";
import { PropMapping } from "@src/utils/parameters";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";

interface Props {
  readonly enabledFilters: string[];
  readonly enabledCustomFilters?: string[];
  readonly segmentReport: Loadable<SegmentReportResource>;
  readonly dimensions: Optional<Dimensions>;
  readonly rightsMatcher: RightsMatcher;
  readonly anonymityThreshold: number;
  readonly company?: Optional<CompanyResource>;
  readonly appliedFilters: Filter[];
  readonly companyModules: Company.Modules;
  readonly storeMappings: PropMapping[];
  readonly focusAreas?: FocusAreaResource[];
  readonly onCreateFocusArea: (
    focusArea: Partial<FocusAreaResource>
  ) => void;
}

export class SegmentReportView extends React.Component<Props> {
  render() {
    const {
      segmentReport,
      dimensions,
      enabledFilters,
      enabledCustomFilters,
      rightsMatcher,
      anonymityThreshold,
      company,
      appliedFilters,
      companyModules,
      storeMappings,
    } = this.props;

    const comparisonKey = "previous";
    const compareWithOptions: CompareWithOption[] = [
      {
        type: "single",
        key: "previous",
        label: "Previous",
        columnLabel: "previous",
      },
    ];

    const hasAccessToActionPlan = rightsMatcher.hasRight(Rights.Action.VIEW_ALL);
    const hasActionCreationRight = rightsMatcher.hasRight(Rights.Action.CREATE);

    const rangeExplanation = Loadable.mapOr(
      segmentReport,
      // display first date in UTC, otherwise for PDT, it will display the last day of the month,
      // and that might be confusing. For to, we want localized date, as if the date is one day after current
      // that might also be confusing :D
      d => `Displaying data from ${formatDate(d.dateRange.from)} to ${formatDate(getOr(d.dateRange.to, utcNow()))}`,
      ""
    );

    return (
      <Layout>
        <Spin size="large" spinning={Loadable.isNotLoaded(segmentReport)}>
          <SectionContainer data-cy="dateRange" >
            <SurveyReportHeader
              enabledFilters={enabledFilters}
              explanation={rangeExplanation}
              enabledCustomFilters={enabledCustomFilters}
              companyModules={companyModules}
              storeMappings={storeMappings}
            />
          </SectionContainer>
          {
            Loadable.isLoaded(segmentReport) &&
            this.renderReport(
              segmentReport.value,
              dimensions,
              compareWithOptions,
              comparisonKey,
              hasActionCreationRight,
              hasAccessToActionPlan,
              anonymityThreshold,
              company,
              appliedFilters,
              companyModules
            )
          }
        </Spin>
      </Layout>
    );
  }

  // noinspection JSMethodCanBeStatic
  private renderReport(segmentReport: SegmentReportResource,
    dimensions: Optional<Dimensions>,
    compareWithOptions: CompareWithOption[],
    comparisonKey: string,
    hasActionCreationRight: boolean,
    hasAccessToActionPlan: boolean,
    anonymityThreshold: number,
    company: Optional<CompanyResource>,
    appliedFilters: Filter[],
    companyModules: Company.Modules) {

    if (segmentReport.filteredForAnonymity) {
      return (
        <SectionContainer>
          <AnonymityContainer>
            <AnonymityFiltered
              explanation={segmentReport.explanation}
              anonymityThreshold={anonymityThreshold}
              translate="yes"
            />
          </AnonymityContainer>
        </SectionContainer>
      );
    }
    const showOnlyCommentsSection = isOnlyEmployeeVoiceModuleEnabled(companyModules);

    return <>
      {
        !showOnlyCommentsSection &&
        <SectionContainer data-cy="segmentReport_favourabilitySection">
          <TotalFavorability
            data={segmentReport.favorability}
            compareWithOptions={compareWithOptions}
            comparisonKey={comparisonKey}
            hasActionCreationRight={hasActionCreationRight}
            company={company}
            appliedFilters={appliedFilters}
            focusAreas={this.props.focusAreas}
            onCreateFocusArea={this.props.onCreateFocusArea}
            focusAreaLabel={"Dashboard"}
          />
        </SectionContainer>
      }

      <SectionContainer data-cy="segmentReport_commentSection">
        <Comments
          data={segmentReport.sentiment as unknown as CommentsData}
          isDashboard={true}
          sectionName="Dashboard"
          hasCommentsAccess={false}
        />
      </SectionContainer>
    </>;
  }
}

const Layout = styled(AntLayout)`
   background: ${Palette.paleGrey} !important;
`;

const SectionContainer = styled.div`
  margin-top: 24px;

  &:first-of-type {
    margin-top: 0;
  }
`;
SectionContainer.displayName = "SectionContainer";

const AnonymityContainer = styled.div`
  margin: 24px 0;
  padding: 24px 0;
  background: white;
`;
