import { isNullOrUndefined } from "util";
import { RouteComponentProps, withRouter } from "react-router";
import React from "react";
import { ChangeEvent } from "react";
import styled from "styled-components";
import { debounce, isEqual } from "lodash";
import { connect } from "react-redux";
import { ExpandButton } from "@components/core/ExpandButton";
import { SearchBar } from "@components/core/SearchBar";
import { CustomizationPopover } from "@screens/Insights/components/CustomizationPopover";
import { State } from "@store/types";
import { Map } from "immutable";
import PermissionsTable from "@screens/Insights/UserManagement/components/PermissionsTable";
import { UserPermissionResource } from "hyphen-lib/dist/domain/resource/user/UserPermissionResource";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { objectPick } from "hyphen-lib/dist/lang/Objects";
import { FilterParameter, SortParameter } from "@src/utils/networks";
import {
  extractDataAndTotalFromPage,
  getExistingNotPaginatedPage,
  getExistingPage,
} from "@store/network/selectors";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { 
  getUserPermissionsStateProps 
} from "@screens/Insights/UserManagement/containers/UserPermissions/store/selectors";
import { parseNumber } from "hyphen-lib/dist/lang/Number";
import { 
  UserPermissionsFilterContainer 
} from "@screens/Insights/UserManagement/containers/UserPermissionsFilterContainer";
import {
  getOr,
  isNotNullNorUndefined,
  mapOr,
  cleanObject,
  isNotEmptyObject,
  isEmptyObject,
} from "hyphen-lib/dist/lang/Objects";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
import {
  getAvailableRoles,
  getAllDimensions,
} from "@screens/Insights/store/selectors";
import convertDictToFilters, {
  clearFilter,
  clearFilterOption,
} from "@src/components/core/FilterLabels/utils";
import FilterLabels from "@src/components/core/FilterLabels";
import NoResult from "@src/components/core/NoResult";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { SelectionFilter } from "hyphen-lib/dist/domain/parameter/SelectionFilter";
import { UserResource } from "hyphen-lib/dist/domain/resource/user/UserResource";
import { FiltersBackdrop } from "@screens/Insights/components/FiltersBackdrop";
import {
  fetchUserPermissionsIfNeeded,
  fetchUserScopeList,
} from "@src/store/network/resource/UserPermissionResources";
import { parametersActionCreators } from "@src/screens/Insights/parameters/store/actions";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import {
  PropMapping,
  applyExistingParametersIfNeeded,
} from "@src/utils/parameters";
import { getParameters } from "@src/screens/Insights/parameters/store/selectors";
import { replaceLocation } from "@src/utils/locations";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { FetchError } from "@src/screens/Insights/errors/FetchError";
import Spin from "@src/components/core/Spin";
import { USER_LIST_FILTER_MAPPINGS } from "../..";
import { USER_PERMISSION_MODAL_MODES } from "../../utils/constants";
import {
  updateTracker,
  getSelectRowKeys,
  getSelectedRowCount,
  getDynamicSelectRowKeys,
  clearExcludedIds,
} from "../../utils/tracker";
import {
  userPermissionsActionCreators,
  PermissionsPageParameters,
  UpdateUserPermissionsPayload,
  SelectUsersPayload,
} from "../UserPermissions/store/actions";
import { checkIfHyphenAdmin } from "hyphen-lib/dist/business/user/Users";
import { User } from "hyphen-lib/dist/domain/User";

interface UserPermissionsContainerProps {
  readonly loading: boolean;
  readonly users: UserPermissionResource[];
  readonly total: number;
  readonly page: PageFilter;
  readonly sort: SortParameter;
  readonly filter: FilterParameter;
  readonly roles: string[];
  readonly isAreYouSureModalVisible: boolean;
  readonly dimensions: Dimensions;
  readonly isModalVisible: boolean;
  readonly isBulkUpdateRequesting: boolean;
  readonly selectedRowKeys: string[];
  readonly tracker: SelectionFilter.Tracker;
  readonly parameters: Map<string, any>;
  readonly existingPage: Store.Page<UserPermissionResource>;
  readonly scopedUserList: User[];
}

export interface UserPermissionsContainerActionProps {
  readonly onFetchIfNeeded: (parameters: PermissionsPageParameters) => any;
  readonly toggleAreYouSureModalVisibility: (
    isAreYouSureModalVisible: boolean
  ) => any;
  readonly onModifyList: (parameters: PermissionsPageParameters) => any;
  readonly onUpdateUserPermission: (
    userId: string,
    userPermission: UserPermissionResource
  ) => any;
  readonly toggleModalVisibility: (isModalVisible: boolean) => void;
  readonly updateUsers: (payload: UpdateUserPermissionsPayload) => void;
  readonly onSelectUsers: (payload: SelectUsersPayload) => any;
  readonly onModifyParameters: (
    parameters: Dictionary<any>,
    mappings?: PropMapping[]
  ) => void;
  readonly fetchUserScopeList: (rootUser: string) => void;
  readonly onRootUserSelect: (rootUser: string) => void;
}

export type UserPermissionsProps = 
UserPermissionsContainerProps &
  RouteComponentProps &
  UserPermissionsContainerActionProps;

export interface UserPermissionsState {
  readonly areFiltersVisible: boolean;
  readonly selectedUsers: any[];
}

export class UserPermissions extends React.Component<
  UserPermissionsProps,
  UserPermissionsState
> {
  private readonly onSearchChangeDebounced: (value: any) => void;

  constructor(props: UserPermissionsProps) {
    super(props);

    this.state = {
      areFiltersVisible: false,
      selectedUsers: [],
    };

    this.onSearchChangeDebounced = debounce(this.updateSearchFilter, 500);
  }

  componentDidMount() {
    const mergedParameters = this.getMergedParams();
    if (
      isNotNullNorUndefined(mergedParameters) &&
      isNotEmptyObject(mergedParameters)
    ) {
      replaceLocation(mergedParameters);
    }
    this.fetchIfNeeded();
  }

  componentDidUpdate(prevProps: UserPermissionsProps) {
    const {
      users,
      selectedRowKeys,
      onSelectUsers,
      tracker,
      parameters,
      location: { search },
      filter,
      existingPage,
    } = this.props;

    if (
      this.props.loading &&
      !prevProps.loading &&
      not(Store.Page.isInError(existingPage))
    ) {
      this.fetchIfNeeded();
    }

    const queryOptions = parseQueryString(search);
    if (
      !isEqual(queryOptions.filter, filter) &&
      isNullOrUndefined(queryOptions.filter) &&
      isNotEmptyObject(filter)
    ) {
      return replaceLocation({ filter });
    }

    if (!isEqual(parameters.toJS(), prevProps.parameters.toJS())) {
      const mergedParameters = this.getMergedParams();

      if (isNotNullNorUndefined(mergedParameters)) {
        replaceLocation(mergedParameters);
      }
    }

    if (
      !isEqual(users, prevProps.users) &&
      tracker.toSelectionFilter().type === "dynamic"
    ) {
      const dynamicSelectedRowKeys = getDynamicSelectRowKeys(
        users,
        tracker.toSelectionFilter(),
        selectedRowKeys
      );
      onSelectUsers({
        selectedRowKeys: dynamicSelectedRowKeys,
      });
    }
  }

  getMergedParams() {
    const {
      parameters,
      location: { search },
    } = this.props;

    const existing = parseQueryString(search);

    const mergedParameters = applyExistingParametersIfNeeded(
      parameters.toJS(),
      existing,
      ...USER_LIST_FILTER_MAPPINGS
    );
    return mergedParameters;
  }

  onSelectChange = (record: UserResource, selected: boolean) => {
    const { _id } = record;
    const { selectedRowKeys, onSelectUsers } = this.props;
    const tracker = updateTracker(this.props.tracker, _id, selected);
    const newSelectedRowKeys = getSelectRowKeys(selectedRowKeys, selected, _id);
    onSelectUsers({
      selectedRowKeys: newSelectedRowKeys,
      tracker,
    });
  };

  onSelectAll = (selected: boolean) => {
    const { users, onSelectUsers } = this.props;
    let { tracker } = this.props;
    if (not(selected)) {
      tracker = tracker.deselectAll();
      onSelectUsers({
        selectedRowKeys: [],
        tracker,
      });
    } else {
      tracker = clearExcludedIds(tracker.selectAll());
      const selectedRowKeys: string[] = [];
      users.forEach((user) => {
        if (checkIfHyphenAdmin(user.email)) {
          tracker = updateTracker(tracker, user._id, false);
        } else {
          selectedRowKeys.push(user._id);
          tracker = updateTracker(tracker, user._id, true);
        }
      });
      onSelectUsers({
        selectedRowKeys,
        tracker,
      });
    }
  };

  fetchIfNeeded() {
    const { onFetchIfNeeded, page, filter, sort } = this.props;
    onFetchIfNeeded({ filter, sort, page });
  }

  handleFiltersClick = () => {
    this.setState((state) => ({
      areFiltersVisible: !state.areFiltersVisible,
    }));
  };

  exportToCSV = () => {
    // Export to CSV
  };

  onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.onSearchChangeDebounced(event.target.value);
  };

  updateSearchFilter = (value: any) => {
    const {
      onModifyParameters,
      onModifyList,
      page,
      sort,
      tracker,
      onSelectUsers,
    } = this.props;

    const filter = getOr(this.props.filter, {});
    if (isStringAndNotEmpty(value)) {
      filter.freeText = value;
    } else {
      delete filter.freeText;
    }

    const resetPage = { ...page, number: 1 };
    onSelectUsers({ tracker: tracker.applyFilter(filter) });
    onModifyParameters(
      { filter: objectPick(filter, "freeText") },
      USER_LIST_FILTER_MAPPINGS
    );
    onModifyList({
      filter: cleanObject(filter),
      page: resetPage,
      sort,
    });
  };

  handleApplyFilters = (newFilter: any) => {
    const { onModifyList, page, sort, tracker, onSelectUsers } = this.props;

    this.setState({ areFiltersVisible: false });
    onSelectUsers({ tracker: tracker.applyFilter(newFilter) });

    const resetPage = { ...page, number: 1 };
    onModifyList({
      filter: cleanObject(newFilter),
      page: resetPage,
      sort,
    });
  };

  onUpdateUserPermissions = (mode: USER_PERMISSION_MODAL_MODES, value: any) => {
    const { updateUsers, filter, tracker } = this.props;

    let payload: UpdateUserPermissionsPayload = {
      modifications: {
        hasInsightsAccess: false,
      },
      selection: {
        ...tracker.toSelectionFilter(),
        filter: {
          ...filter,
        },
      },
    };
    if (mode === "access") {
      if (value === "Yes") {
        payload = Object.assign({}, payload, {
          modifications: {
            hasInsightsAccess: true,
          },
        });
      }
    } else if (mode === "role") {
      payload = Object.assign({}, payload, {
        modifications: {
          role: value,
        },
      });
    }
    updateUsers(payload);
  };

  renderNoData = () => {
    const { filter } = this.props;
    let type: "filter" | "data" | "default" | "search" = "default";
    let description = "";

    if (isNotNullNorUndefined(filter)) {
      // in-case of search
      if (isNotNullNorUndefined(filter.freeText)) {
        type = "search";
        description =
          "Sorry, we couldn't find any users matching your search for the moment.";
      }
      // in-case any filters are applied
      else if (Object.keys(filter).length > 0) {
        type = "filter";
        description =
          "Sorry, we couldn't find any users matching your filters for the moment.";
      }
      // no filters, meaning no user is created
      else {
        type = "data";
        description = "Looks like you haven't added any users yet.";
      }
    } else {
      type = "default";
      description = "";
    }

    return <NoResult type={type} description={description} />;
  };

  onClearFilter = (filterToRemove: string) => {
    const { onModifyList, page, sort, tracker, onSelectUsers } = this.props;
    const filter = cleanObject(clearFilter(filterToRemove, this.props.filter));

    onSelectUsers({ tracker: tracker.applyFilter(filter) });
    onModifyList({
      filter,
      page,
      sort,
    });
  };

  onClearFilterOption = (filter: string, subFilter: string) => {
    const { onModifyList, page, sort, tracker, onSelectUsers } = this.props;

    const filterState = cleanObject(
      clearFilterOption(filter, subFilter, this.props.filter)
    );

    onSelectUsers({ tracker: tracker.applyFilter(filterState) });
    onModifyList({
      filter: filterState,
      page,
      sort,
    });
  };

  renderFilterLabels = () => {
    const { filter, dimensions } = this.props;
    const filters = convertDictToFilters(filter, dimensions);
    if (filters.length > 0) {
      return (
        <FilterLabels
          filters={filters}
          onClearFilter={this.onClearFilter}
          onClearSubfilter={this.onClearFilterOption}
        />
      );
    } else {
      return null;
    }
  };

  render() {
    const {
      loading,
      users,
      total,
      page,
      sort,
      filter,
      roles,
      dimensions,
      onModifyList,
      onUpdateUserPermission,
      isAreYouSureModalVisible,
      toggleAreYouSureModalVisibility,
      toggleModalVisibility,
      isModalVisible,
      isBulkUpdateRequesting,
      selectedRowKeys,
      tracker,
      existingPage,
    } = this.props;
    const { areFiltersVisible } = this.state;
    const selectedUsersCount = getSelectedRowCount(
      total,
      tracker.toSelectionFilter()
    );
    const searchBarValue = mapOr(filter, (f) => f.freeText, "");

    return (
      <Container>
        <SearchAndFilterContainer>
          <SearchBar
            placeholder="Search users"
            onChange={this.onSearchChange}
            defaultValue={searchBarValue}
          />
          <StyledExpandButton icon="filter" onClick={this.handleFiltersClick} translate="yes">
            Filters
          </StyledExpandButton>
          {areFiltersVisible && (
            <FiltersBackdrop onClick={this.handleFiltersClick} />
          )}
          <CustomizationPopover open={areFiltersVisible}>
            <UserPermissionsFilterContainer
              roles={roles}
              dimensions={dimensions}
              values={filter}
              onApply={this.handleApplyFilters}
            />
          </CustomizationPopover>
        </SearchAndFilterContainer>
        {this.renderFilterLabels()}
        {
          <Spin size="large" spinning={Store.Page.isLoading(existingPage)}>
            {Store.Page.isInError(existingPage) && (
              <FetchError
                {...existingPage}
                resourceType={UserPermissionResource.TYPE}
              />
            )}
            {total === 0 && Store.Page.isLoaded(existingPage) ? (
              this.renderNoData()
            ) : (
              <PermissionsTable
                loading={loading}
                users={users}
                total={total}
                page={page}
                filter={filter}
                sort={sort}
                onModifyList={onModifyList}
                selectedRowKeys={selectedRowKeys}
                onSelectChange={this.onSelectChange}
                selectedUsersCount={selectedUsersCount}
                dimensions={dimensions}
                onUpdateUserPermission={onUpdateUserPermission}
                toggleModalVisibility={toggleModalVisibility}
                isAreYouSureModalVisible={isAreYouSureModalVisible}
                toggleAreYouSureModalVisibility={
                  toggleAreYouSureModalVisibility
                }
                isModalVisible={isModalVisible}
                roles={roles}
                onSelectAll={this.onSelectAll}
                onUpdateUserPermissions={this.onUpdateUserPermissions}
                isBulkUpdateRequesting={isBulkUpdateRequesting}
                fetchUserScopeList={this.props.fetchUserScopeList}
                onRootUserSelect={this.props.onRootUserSelect}
                scopedUserList={this.props.scopedUserList}
              />
            )}
          </Spin>
        }
      </Container>
    );
  }
}

const SearchAndFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  position: relative;
`;

const StyledExpandButton = styled(ExpandButton)`
  width: 144px;
  margin-left: 16px;
`;

const Container = styled.div`
  background-color: white;
  padding-bottom: 32px;
`;

function mapStateToProps(
  state: State,
  { location }: RouteComponentProps
): UserPermissionsContainerProps {
  const queryParameters = parseQueryString(location.search);
  let filter = getOr(queryParameters.filter, {});
  const sort = getOr(queryParameters.sort, {});
  const parameters = getParameters(state);
  const parametersJS = parameters.toJS();

  if (
    (isEmptyObject(filter) || isNullOrUndefined(filter.freeText)) &&
    isNotNullNorUndefined(parametersJS) &&
    isNotEmptyObject(parameters.get("UserListSearch"))
  ) {
    filter = parameters.get("UserListSearch");
  }
  const {
    pageSize,
    isModalVisible,
    isBulkUpdateRequesting,
    selectedRowKeys,
    isAreYouSureModalVisible,
    tracker,
  } = getUserPermissionsStateProps(state);
  const page = {
    size: pageSize,
    number: mapOr(queryParameters.page, parseNumber, 1),
  };

  const existingPage = getExistingPage(
    state,
    UserPermissionResource.TYPE,
    UserPermissionResource.generateKey(filter, sort),
    page
  );

  const { data, total } = extractDataAndTotalFromPage(existingPage);

   let scopedUserList: User[] = [];
   const scopedUser = Store.Page.toLoadable(getExistingNotPaginatedPage(
    state,
    UserPermissionResource.USER_SCOPE_LIST_TYPE,
    state.get("insights_userPermissions").get("selectedRootUser")
  ));

  if(scopedUser.status === "loaded") {
    // @ts-ignore
    scopedUserList = scopedUser.value;
  }

  return {
    loading: Store.Page.isNotFound(existingPage),
    existingPage,
    users: data,
    total,
    sort,
    filter,
    page,
    roles: getAvailableRoles(state),
    dimensions: getOr(getAllDimensions(state), {}),
    isModalVisible,
    isAreYouSureModalVisible,
    isBulkUpdateRequesting,
    selectedRowKeys,
    tracker,
    parameters,
    scopedUserList
  };
}

const mapDispatchToProps = {
  onFetchIfNeeded: fetchUserPermissionsIfNeeded,
  onModifyList: userPermissionsActionCreators.modifyPermissionsList,
  onUpdateUserPermission: userPermissionsActionCreators.updateUserPermissions,
  toggleModalVisibility: userPermissionsActionCreators.toggleModalVisibility,
  updateUsers: userPermissionsActionCreators.updateUsers,
  onSelectUsers: userPermissionsActionCreators.onSelectUsers,
  toggleAreYouSureModalVisibility:
    userPermissionsActionCreators.toggleAreYouSureModalVisibility,
  onModifyParameters: parametersActionCreators.modifyParameters,
  fetchUserScopeList,
  onRootUserSelect: userPermissionsActionCreators.onRootUserSelect
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserPermissions)
);
