// @ts-nocheck
import React from "react";
export default function Right() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="10"
      viewBox="0 0 6 10"
    >
      <path
        fill="#AAB9CE"
        fillRule="evenodd"
        // eslint-disable-next-line max-len
        d="M.857 10a.867.867 0 0 1-.606-.244.816.816 0 0 1 0-1.178L3.931 5 .25 1.423a.816.816 0 0 1 0-1.179.873.873 0 0 1 1.212 0L5.75 4.411a.816.816 0 0 1 0 1.178L1.463 9.756A.867.867 0 0 1 .857 10z"
      />
    </svg>
  );
}
