import styled from "styled-components";
import React from "react";

export function Loading() {
  return <LoadingContainer>Loading...</LoadingContainer>;
}

export const LoadingContainer = styled.div`
  text-align: center;
  background: white;
  padding: 24px;
`;
