import React from "react";
import { connect } from "react-redux";

import { State } from "@store/types";
import { getRightsMatcher } from "@screens/Insights/store/selectors";

import Tabs from "@src/components/core/Tabs";
import { Trans } from "react-i18next";
import { RightsMatcher } from "hyphen-lib/dist/business/auth/Auth";
import { ScreenContainer } from "../components/ScreenContainer";
import { VoiceReports } from "./VoiceReports";

const { TabPane } = Tabs;

interface EmployeeVoiceStateProps{
  readonly rightsMatcher: RightsMatcher;
}

export function EmployeeVoice(props: EmployeeVoiceStateProps) {
  return (
    <ScreenContainer title="Employee voice">
      <Tabs defaultActiveKey="reports">
        <TabPane tab={<Trans>Reports</Trans>} key="reports">
          <VoiceReports rightsMatcher={props.rightsMatcher}/>
        </TabPane>
      </Tabs>
    </ScreenContainer>
  );
}

function mapStateToProps(state: State) : EmployeeVoiceStateProps {
  return {
    rightsMatcher: getRightsMatcher(state),
  };
}

export default connect(mapStateToProps, {})(EmployeeVoice);
