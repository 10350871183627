import React from "react";
import { getProperty } from "hyphen-lib/dist/lang/Objects";
import Checkbox from "@src/components/core/Checkbox";
import { BatarangParameterProps } from "./BatarangParameters";
interface CheckedBox {
  isChecked: boolean;
};
class ParameterCheckBox extends React.Component<BatarangParameterProps, CheckedBox> {
  constructor(props: BatarangParameterProps) {
    super(props);
    this.state = {
      isChecked: false
    };
  }

  componentDidMount() {
    const { company } = this.props;
    if (this.props.parameter.key === "betterworksOkr") {
      this.setState({
        isChecked: getProperty(company, "modules.betterworksOkr", false)
      });
    }
    else if (this.props.parameter.key === "syncUsers") {
      this.setState({
        isChecked: getProperty(company, "coreAppConfig.syncUsers", false)
      });
    }
  }

  handleCheckBoxChange = (event: any) => {
    this.props.onChange(event.target.checked);
    this.setState({
      isChecked: event.target.checked
    });
  };

  render() {
    return (
      <Checkbox
        checked={this.state.isChecked}
        onChange={(e: any) =>
          this.handleCheckBoxChange(e)}
        disabled={this.props.disabled === true}
      />
    );
  }
}
export default (ParameterCheckBox);