import React from "react";
import styled from "styled-components";

import Palette from "@src/config/theme/palette";
import { isNullOrUndefined } from "@hyphen-lib/lang/Objects";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Trans } from "react-i18next";

export interface DriverImpactProps {
  driverImpact?: Optional<number>;
}

export function DriverImpact({ driverImpact }: DriverImpactProps) {
  const renderScoreBars = (driverImpactScore: number) => {
    const numberOfScoreBars = 5;
    const score = Math.max(0, Math.min(driverImpactScore, numberOfScoreBars));
    return Array.from(Array(numberOfScoreBars)).map((__, index) => {
      const isActive = index < score;
      return <ScoreBar key={index} className={isActive ? "active" : ""}/>;
    });
  };

  return (
    <Container>
      {isNullOrUndefined(driverImpact) ?
        <NotIncluded><Trans>Not included</Trans></NotIncluded> :
        <Score>
          {renderScoreBars(driverImpact)}
        </Score>
      }
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const Score = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column-reverse;
`;

const ScoreBar = styled.div`
  width: 20px;
  height: 2px;
  background: ${Palette.lightPeriwinkle};
  margin-top: 3px;

  &.active {
    background: ${Palette.aquaBlue};
  }
`;

const NotIncluded = styled.div`
  font-family: Lato;
  font-size: 12px;
  color: ${Palette.bluishGrey};
`;
