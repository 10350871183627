import { isStringAndNotEmpty } from "@hyphen-lib/lang/Strings";
import { HTMLDOMElement } from "highcharts";

export function objectIsEmpty(obj: any) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function setWidthOfLabel(
  thisLabel: HTMLDOMElement, nextLabel: HTMLDOMElement, shouldAdjustForPlotLine: boolean
) {
  const { left: thisLabelLeft } = thisLabel.getBoundingClientRect();
  const { left: nextLabelLeft } = nextLabel.getBoundingClientRect();
  thisLabel.style.width = `${nextLabelLeft - thisLabelLeft - (shouldAdjustForPlotLine ? 4 : 0)}px`;
}

export function nudgeLabelTop(element: HTMLDOMElement, overlapWidth: number, gridLineWidth: number) {
  if (isStringAndNotEmpty(element.style.top)) {
    const top = extractTop(element);
    const newTop = top - gridLineWidth - Math.max(overlapWidth, 0);
    element.style.top = `${newTop}px`;
  }
}

export function extractTop(element: HTMLDOMElement): number {
  const topWithPixels = element.style.top;
  const topString = topWithPixels.substring(0, topWithPixels.length - 2);
  return parseInt(topString, 10);
}

export function computeOverlapWidth(labelBottom: number, gridLineTop: number) {
  return Math.max(0, labelBottom - gridLineTop);
}
