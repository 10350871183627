import React from "react";
import { ComputedHeatMap } from "@hyphen-lib/domain/aggregate/calculation/ComputedHeatMap";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { CompareWithOption } from "@screens/Insights/components/ViewOptions/components/CompareWith";
import styled from "styled-components";
import { AutoSizer, Grid, ScrollSync } from "react-virtualized";
import Tooltip from "@components/core/Tooltip";
import ArrowDoubleDown from "@components/core/svg/ArrowDoubleDown";
import { Icon } from "antd";
import { getOr, isNotNullNorUndefined, isNullOrUndefined } from "hyphen-lib/dist/lang/Objects";
import { getMatchingOptionLabel } from "@src/utils/Comparisons";
import Palette from "@src/config/theme/palette";
import { Set as ImmutableSet } from "immutable";
import { ResultWithAnonymity } from "hyphen-lib/dist/domain/common/ResultWithAnonymity";
import { InlineAnonymityFiltered } from "@src/components/core/InlineAnonymityFiltered";
import { ArrowDown, ArrowNext } from "@components/core/svg";
import { extractComparison } from "hyphen-lib/dist/business/calculation/benchmark/Benchmarks";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { AnonymityFilterExplanation } from "hyphen-lib/dist/domain/common/AnonymityFilterExplanation";
import { Breadcrumb, goTo } from "@src/utils/locations";
import { appendQueryString, generateQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { getDimensionSegmentLabel } from "@src/utils/Dimensions";
import { Participation } from "hyphen-lib/dist/domain/common/Participation";
import { ParticipationReportResource } from "hyphen-lib/dist/domain/resource/survey/report/ParticipationReportResource";
import { Trans } from "react-i18next";
import { TransWrapper } from "@src/components/core/withTrans";
import { getSegmentLabel } from "hyphen-lib/dist/domain/common/Dimensions";
import { trans } from "@src/utils/i18next";

const X_AXIS_HEIGHT = 150;
const CELL_WIDTH = 80;
const ROW_HEIGHT = 58;

// tslint:disable-next-line:no-empty
const devNullListener = () => { };

interface Props {
  readonly surveyId: string;
  readonly surveyName: string;
  readonly heatMap: ComputedHeatMap;
  readonly areComparisonsVisible?: boolean;
  readonly compareWithOptions: CompareWithOption[];
  readonly comparisonKey: Optional<string>;
  readonly anonymityThreshold: number;
  readonly scoreOrDelta: "score" | "delta";
  readonly participationResource: ParticipationReportResource;
  readonly selectedDimension: string;
  readonly heatmapTheme?: Dictionary<any>;
}

interface State {
  readonly areAllExpanded: boolean;
  readonly expandedCategories: ImmutableSet<string>;
  readonly overscanColumnCount: number;
  readonly overscanRowCount: number;
  readonly preComputedHeatMap: PreComputedHeatMap;
}

export default class HeatmapTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const expandedCategories = ImmutableSet();
    this.state = {
      areAllExpanded: false,
      expandedCategories,
      preComputedHeatMap: this.generatePreComputedHeatMap(expandedCategories, props),
      overscanColumnCount: 0,
      overscanRowCount: 0,
    };

    this.renderBodyCell = this.renderBodyCell.bind(this);
    this.renderHeaderCell = this.renderHeaderCell.bind(this);
    this.renderLeftSideCell = this.renderLeftSideCell.bind(this);
    this.renderLeftHeaderCell = this.renderLeftHeaderCell.bind(this);
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    if (
      prevProps.areComparisonsVisible !== this.props.areComparisonsVisible ||
      prevProps.comparisonKey !== this.props.comparisonKey ||
      prevProps.scoreOrDelta !== this.props.scoreOrDelta ||
      prevProps.heatMap.dimension !== this.props.heatMap.dimension ||
      prevProps.heatMap.segments !== this.props.heatMap.segments
    ) {
      this.setState({
        preComputedHeatMap: this.generatePreComputedHeatMap(prevState.expandedCategories, this.props),
      });
    }
  }

  expandAllAction = () => {
    const { heatMap } = this.props;

    this.setState(prevState => {
      const areAllExpanded = !prevState.areAllExpanded;
      const expandedCategories = areAllExpanded ?
        ImmutableSet(heatMap.categories.map(cat => cat._id)) :
        ImmutableSet();
      return ({
        areAllExpanded,
        expandedCategories,
        preComputedHeatMap: this.generatePreComputedHeatMap(expandedCategories, this.props),
      });
    });
  };

  expandCategory = (category: string) => {
    const { heatMap } = this.props;
    this.setState(prevState => {
      const expandedCategories = prevState.expandedCategories.add(category);
      return {
        areAllExpanded: expandedCategories.size === heatMap.categories.length,
        expandedCategories,
        preComputedHeatMap: this.generatePreComputedHeatMap(expandedCategories, this.props),
      };
    });
    document.getElementsByClassName("overall")[0].scrollIntoView();
  };

  collapseCategory = (category: string) => {
    this.setState(prevState => {
      const expandedCategories = prevState.expandedCategories.remove(category);
      return {
        areAllExpanded: false,
        expandedCategories,
        preComputedHeatMap: this.generatePreComputedHeatMap(expandedCategories, this.props),
      };
    });
  };

  // noinspection JSMethodCanBeStatic
  generatePreComputedHeatMap(expandedCategories: ImmutableSet<string>, props: Props) {
    return preComputeHeatMap(
      props.heatMap,
      expandedCategories,
      props.scoreOrDelta,
      props.comparisonKey,
      getOr(props.areComparisonsVisible, false),
      props.surveyId,
      props.surveyName,
      props.heatmapTheme,
    );
  }

  /*
      Compute the best height for the heatmap. We want a heatmap big enough
      to fit the entire screen when scrolling but not bigger than the screen as we still want to be able to
      see the x-axis and the dimension picker.

      So we will calculate what would be the size of the heatmap, and an ideal size.
      And we will keep the minimum, if full size is less than ideal, no need to take more space than needed.
      But if heatmap is too big, let's use this "ideal" width.
   */
  // noinspection JSMethodCanBeStatic
  calculateBestHeight(preComputedHeatMap: PreComputedHeatMap) {
    return preComputedHeatMap.yAxisCells.length * ROW_HEIGHT;
  }

  /*
    UNIT TEST ONLY

    This method is supposed to be mocked. If we do not do this hack the width of the
    AutoSizer is eventually set to 0, and we can not do any assertion on its content :/
   */
  // noinspection JSMethodCanBeStatic
  // tslint:disable-next-line
  static getDefaultWidthValueForUnitTest() {
    return 0;
  }

  render() {
    const {
      scoreOrDelta,
    } = this.props;

    const {
      overscanColumnCount,
      overscanRowCount,
      preComputedHeatMap,
    } = this.state;
    // extract number of columns and rows, note that the yAxis column has to be counted,
    // whereas the yAxis row does not need to be.
    const columnCount = preComputedHeatMap.xAxisCells.length + 2;
    const rowCount = preComputedHeatMap.yAxisCells.length;

    const height = this.calculateBestHeight(preComputedHeatMap);

    // value only used for test, see method documentation
    const defaultWidthValue = HeatmapTable.getDefaultWidthValueForUnitTest();
    return (
      <ContentBox scoreOrDelta={scoreOrDelta}>
        <ScrollSync>
          {({
            onScroll,
            scrollLeft,
            scrollTop,
          }) => {

            const yAxisColumnWidth = 320;
            const xAxisRowHeight = X_AXIS_HEIGHT;
            return (
              <GridRow>
                <LeftSideGridContainer
                  // style={{
                  //   position: "absolute",
                  //   left: 0,
                  //   top: 0,
                  // }}
                >
                  <Grid
                    style={{ width: "auto", overflow: "unset" }}
                    cellRenderer={this.renderLeftHeaderCell}
                    className="HeaderGrid"
                    width={yAxisColumnWidth + 100}  // Needs space so that comparison header can slant
                    height={xAxisRowHeight}
                    rowHeight={xAxisRowHeight}
                    columnWidth={yAxisColumnWidth}
                    rowCount={1}
                    columnCount={1}
                    // force refresh if any of this change
                    {...preComputedHeatMap.context}
                  />
                  <Grid
                    overscanColumnCount={overscanColumnCount}
                    overscanRowCount={overscanRowCount}
                    cellRenderer={this.renderLeftSideCell}
                    columnWidth={yAxisColumnWidth}
                    columnCount={1}
                    className="LeftSideGrid"
                    height={height}
                    rowHeight={ROW_HEIGHT}
                    rowCount={rowCount}
                    scrollTop={scrollTop}
                    width={yAxisColumnWidth}
                    // force refresh if any of this change
                    {...preComputedHeatMap.context}
                    {...preComputedHeatMap.yAxisCells}
                  />
                </LeftSideGridContainer>
                <GridColumn>
                  {/* default width is a minimum for unit tests*/}
                  <AutoSizer disableHeight defaultWidth={defaultWidthValue}>
                    {({ width }) => {
                      width = width || defaultWidthValue; // for unit test as width will be 0
                      return (
                        <div>
                          <div
                            style={{
                              height: xAxisRowHeight,
                              width,
                            }}>
                            <Grid
                              className="HeaderGrid"
                              columnWidth={this._getColumnWidth}
                              columnCount={columnCount}
                              height={xAxisRowHeight}
                              overscanColumnCount={overscanColumnCount}
                              cellRenderer={this.renderHeaderCell}
                              rowHeight={xAxisRowHeight}
                              rowCount={1}
                              scrollLeft={scrollLeft}
                              width={width}
                              style={{
                                overflow: "hidden"
                              }}
                              // force refresh if any of this change
                              {...preComputedHeatMap.context}
                            />
                          </div>
                          <div
                            style={{
                              height: height + 10,
                              width,
                            }}>
                            <Grid
                              className="BodyGrid"
                              columnWidth={this._getColumnWidth}
                              columnCount={columnCount}
                              height={height + 10}
                              onScroll={onScroll}
                              overscanColumnCount={overscanColumnCount}
                              overscanRowCount={overscanRowCount}
                              cellRenderer={this.renderBodyCell}
                              rowHeight={ROW_HEIGHT}
                              rowCount={rowCount}
                              width={width}
                              // force refresh if any of this change
                              {...preComputedHeatMap.context}
                            />
                          </div>
                        </div>
                      );
                    }}
                  </AutoSizer>
                </GridColumn>
              </GridRow>
            );
          }}
        </ScrollSync>
      </ContentBox>
    );
  }

  /*
      Render methods:
      - renderTopLeftCorner is responsible to render the expand/collapse all cell
      - renderYAxisCell is responsible to render the categories, questions and overall cells (label + fav + comparison)
      - renderXAxisCell is responsible to render the segments
      - renderHeatMapCell is responsible to render the main content of the heatmap
   */

  private renderTopLeftCorner({ key, style }: GridContext) {
    const {
      areComparisonsVisible,
      compareWithOptions,
      comparisonKey,
    } = this.props;

    const {
      areAllExpanded,
    } = this.state;

    const label = getMatchingOptionLabel(compareWithOptions, comparisonKey);

    return (
      <div className="headerCell" key={key} style={style}>
        <div className="fixed-column-1">
          <Tooltip title={<Trans>{(areAllExpanded ? "Collapse" : "Expand") + " all categories"}</Trans>}>
            <StyledArrowDoubleDown
              component={ArrowDoubleDown}
              onClick={this.expandAllAction}
              style={areAllExpanded ? { transform: "rotate(180deg)" } : undefined}
            />
          </Tooltip>
          <ColumnHeaderText><Trans>Category</Trans></ColumnHeaderText>
        </div>
        {(areComparisonsVisible && isNotNullNorUndefined(label))
          ? (
            <div className="fixed-column-2 comparison-header">
              <Tooltip title={<Trans>vs {label}</Trans>} >
                <ColumnHeaderTextElipsis>
                  <ColumnHeaderText ><Trans>vs {label}</Trans></ColumnHeaderText>
                </ColumnHeaderTextElipsis>
              </Tooltip>
            </div>
          )
          : (
            <div className="fixed-column-2">
              <ColumnHeaderText />
            </div>
          )
        }
      </div>
    );
  }

  private renderYAxisCell(extendedContext: { rowIndex: number } & GridContext) {
    const {
      preComputedHeatMap,
    } = this.state;

    // get the corresponding cell
    const yAxisCell: YAxisCell = preComputedHeatMap.yAxisCells[extendedContext.rowIndex];
    // then dispatch to the right render method
    switch (yAxisCell.type) {
      case "category":
        return this.renderYAxisCategoryCell(yAxisCell, extendedContext);
      case "question":
        return this.renderYAxisQuestionCell(yAxisCell, extendedContext);
      case "overall":
        return this.renderYAxisOverallCell(yAxisCell, extendedContext);
    }
  }

  private renderYAxisCategoryCell(category: YAxisCategoryCell, { key, style }: GridContext) {
    const {
      anonymityThreshold,
      areComparisonsVisible,
      comparisonKey,
    } = this.props;

    const {
      expandedCategories,
    } = this.state;

    const classNamePrefix = "fixed-column-";

    if (category.data.filteredForAnonymity) {
      return (
        <div className="yAxisCell" key={key} style={style}>
          <div className={classNamePrefix + "1"}>
            <StyledYAxisDiv>
              {category.data._id}
            </StyledYAxisDiv>
          </div>
          <div className={classNamePrefix + "2"}>
            <InlineAnonymityFiltered
              explanation={category.data.explanation}
              anonymityThreshold={anonymityThreshold}
            />
          </div>
        </div>
      );
    }

    const comparison = extractComparison(category.data.compare, comparisonKey);
    const isExpanded = expandedCategories.has(category.data._id);
    return (
      <div className="yAxisCell" key={key} style={style}>
        <div className={classNamePrefix + "1"}>
          <StyledArrowDown
            component={ArrowDown}
            style={isExpanded ? { transform: "rotate(180deg)" } : undefined}
            onClick={
              isExpanded ?
                this.collapseCategory.bind(this, category.data._id) :
                this.expandCategory.bind(this, category.data._id)
            }
          />
          <StyledYAxisDiv>
            {category.data._id}
          </StyledYAxisDiv>
        </div>
        <div className={classNamePrefix + "2"}>
          {this.renderPercentageOrNA(category.data.favorability)}
          {areComparisonsVisible && this.renderChangeBy(comparison)}
        </div>
      </div>
    );
  }

  private renderYAxisQuestionCell(question: YAxisQuestionCell, { key, style }: GridContext) {
    const {
      anonymityThreshold,
      areComparisonsVisible,
      comparisonKey,
    } = this.props;

    const classNamePrefix = "fixed-question-column-";

    let scoreAndComparison;
    if (question.data.filteredForAnonymity) {
      scoreAndComparison = (
        <InlineAnonymityFiltered
          explanation={question.data.explanation}
          anonymityThreshold={anonymityThreshold}
        />
      );
    } else {
      const comparison = extractComparison(question.data.compare, comparisonKey);
      scoreAndComparison = (
        <>
          {this.renderPercentageOrNA(question.data.favorability)}
          {areComparisonsVisible && this.renderChangeBy(comparison)}
        </>
      );
    }

    return (
      <div className="yAxisCell" key={key} style={style}>
        <div className={classNamePrefix + "1"}>
          <StyledYAxisDiv fontSize={12}>
            {question.data.question}
          </StyledYAxisDiv>
        </div>
        <div className={classNamePrefix + "2"}>
          {scoreAndComparison}
        </div>
      </div>
    );
  }

  private renderYAxisOverallCell(overall: YAxisOverallCell, { key, style }: GridContext) {
    const {
      areComparisonsVisible,
      comparisonKey,
    } = this.props;

    const classNamePrefix = "overall fixed-column-";

    const comparison = extractComparison(overall.data.compare, comparisonKey);
    return (
      <div className="yAxisCell" key={key} style={style}>
        <div className={classNamePrefix + "1"}>
          Overall
        </div>
        <div className={classNamePrefix + "2"}>
          {this.renderPercentageOrNA(overall.data.favorability)}
          {areComparisonsVisible && this.renderChangeBy(comparison)}
        </div>
      </div>
    );
  }

  private getParticipationCount(segmentName: string, dataForChart: any): Participation {
    if (segmentName === "Not specified") {
      return dataForChart["[[~null~]]"];
    }
    return dataForChart[segmentName];
  }

  private getTooltipTitle(segmentName: string, participation: Participation) {
    return (
      (isNotNullNorUndefined(participation)) ?
        <>
          <SegmentLabel>{trans(segmentName)}</SegmentLabel>
          <StyledDiv>
            <Icon type="user" /> = {participation.completed} / {participation.total}
          </StyledDiv>
        </>
        : <SegmentLabel>{trans(segmentName)}</SegmentLabel>
    );

  }

  private renderXAxisCell({ columnIndex, key, style }: { columnIndex: number } & GridContext) {
    const {
      participationResource,
      selectedDimension,
    } = this.props;
    const {
      preComputedHeatMap,
    } = this.state;

    if (columnIndex === preComputedHeatMap.xAxisCells.length) {
      return null;
    }
    const { dimension } = preComputedHeatMap.context;
    let segmentName = getDimensionSegmentLabel(dimension, preComputedHeatMap.xAxisCells[columnIndex].label);

    const dataForChart = getOr(participationResource.dimensions[selectedDimension], {});
    const participation = this.getParticipationCount(
      getSegmentLabel(preComputedHeatMap.xAxisCells[columnIndex].label), dataForChart);
    const tooltipTitle = this.getTooltipTitle(segmentName, participation);
    if(segmentName.indexOf("@") > -1) {
      segmentName = `${segmentName.split("@")[0]}@`;
    }
    return (
      <div
        key={key}
        style={style}
        className="xAxisCell"
      > 
        <StyledRotatedContainer 
            style={{
              height: CELL_WIDTH - 15,
              width: X_AXIS_HEIGHT
            }}>
            <Tooltip title={tooltipTitle}>
              <ColumnHeaderTextElipsis>
                <span><TransWrapper>{segmentName}</TransWrapper></span>
              </ColumnHeaderTextElipsis>
            </Tooltip>
        </StyledRotatedContainer>
      </div>
    );
  }

  private renderHeatMapCell({ columnIndex, rowIndex, key, style }:
    { columnIndex: number; rowIndex: number } & GridContext) {

    const {
      anonymityThreshold,
      areComparisonsVisible,
      scoreOrDelta,
    } = this.props;

    const {
      preComputedHeatMap,
    } = this.state;

    if (columnIndex === preComputedHeatMap.xAxisCells.length) {
      return null;
    }

    const cell = preComputedHeatMap.heatMapCells[rowIndex][columnIndex];

    if (cell.filteredForAnonymity) {
      return (
        <div className="cell" key={key} style={style}>
          <StyledDataCell scoreOrDelta={scoreOrDelta}>
            <InlineAnonymityFiltered
              explanation={cell.explanation}
              anonymityThreshold={anonymityThreshold}
            />
          </StyledDataCell>
        </div>
      );
    }

    const onClickListener = isNotNullNorUndefined(cell.onClick) ? cell.onClick : devNullListener;
    const clickable: boolean = isNotNullNorUndefined(cell.onClick);

    const displayDelta =
      scoreOrDelta === "delta" && areComparisonsVisible;

    let renderedCell = (
      // tslint:disable-next-line:jsx-no-lambda
      <StyledDataCell
        cellBackground={cell.className.backgroundColor}
        cellColor={cell.className.fontColor}
        onClick={onClickListener}
        clickable={clickable}
        scoreOrDelta={scoreOrDelta}
      >
        {
          displayDelta ?
            this.renderCompare(cell.score) :
            this.renderPercentageOrNA(
              cell.score,
              preComputedHeatMap.xAxisCells[columnIndex].label
            )
        }
      </StyledDataCell>
    );

    if (clickable) {
      renderedCell = (
        <Tooltip title={<Trans>Click to see details</Trans>}>
          {renderedCell}
        </Tooltip>
      );
    }

    let className = "cell";
    if (rowIndex === 0) {
      if (columnIndex === 0) {
        className = "first-column-cell first-row-cell";
      } else {
        className = "first-row-cell";
      }
    } else if (columnIndex === 0) {
      className = "first-column-cell";
    }
    return (
      <div className={className} key={key} style={style}>
        {renderedCell}
      </div>
    );
  }

  private renderCompare = (value: Optional<number>) => {
    return this.renderChangeBy(value);
  };

  private renderPercentageOrNA = (value?: Optional<number>, dimension?: string) => {
    return isNullOrUndefined(value) || Number.isNaN(value)
      ? dimension
        ? <Tooltip title={<><Trans>No data available for</Trans> {dimension} 
        <Trans>in this category</Trans></>}>N/A</Tooltip>
        : "N/A"
      : `${value}%`;
  };

  private renderChangeBy = (changeBy?: Optional<number>) => {
    if (isNullOrUndefined(changeBy)) {
      return (
        <StyledValueNoChange>
          N/A
        </StyledValueNoChange>
      );
    }

    if (changeBy === 0) {
      return (
        <StyledValueNoChange>
          <StyledArrowNext fill={Palette.bluishGrey} rotationAngle="0" />
          {changeBy}%
        </StyledValueNoChange>
      );
    }

    if (changeBy > 0) {
      return (
        <StyledValueChangeUp>
          <StyledArrowNext fill={Palette.aquaBlue} rotationAngle="-45deg" />
          {changeBy}%
        </StyledValueChangeUp>
      );
    }

    if (changeBy < 0) {
      return (
        <StyledValueChangeDown>
          <StyledArrowNext fill={Palette.darkPink} rotationAngle="45deg" />
          {Math.abs(changeBy)}%
        </StyledValueChangeDown>
      );
    }

    return (
      <StyledValueNoChange>
        <StyledArrowNext fill={Palette.bluishGrey} rotationAngle="0" />
        0%
      </StyledValueNoChange>
    );
  };

  /*
      Internal render methods, they are indexed as a grid, from (0, 0).
      So in order to fit more with out UI:
      - categories/questions/overall in Y axis
      - segments in X axis
      - expand/collapse all in top left corner
      - all remaining cells are content of the heatmap
      Those method will delegate to our render method, and columnIndex and rowIndex will be transformed.

      Like this:
      - categories/questions/overall will only receive a row index starting from 0
      - segments will only receive a columnIndex starting from 0
      - expand/collapse all in top left corner will not receive any index, we know where it is :)
      - all remaining cells will receive an index starting at (0, 0)

      So we will have a structure in state containing:
      - an array of categories/questions/overall
      - an array of segments
      - a matrix of cells
   */

  private renderBodyCell({ columnIndex, key, rowIndex, style }: any) {
    if (columnIndex < 1) {
      return;
    }

    return this.renderLeftSideCell({ columnIndex, key, rowIndex, style });
  }

  private renderHeaderCell({ columnIndex, key, rowIndex, style }: any) {
    if (columnIndex < 1) {
      return;
    }

    return this.renderLeftHeaderCell({ columnIndex, key, rowIndex, style });
  }

  // noinspection JSMethodCanBeStatic
  private renderLeftHeaderCell({ columnIndex, key, style }: any) {
    return columnIndex === 0 ?
      this.renderTopLeftCorner({ key, style }) :
      this.renderXAxisCell({ columnIndex: columnIndex - 1, key, style });
  }

  // noinspection JSMethodCanBeStatic
  private renderLeftSideCell({ columnIndex, key, rowIndex, style }: any) {
    return columnIndex === 0 ?
      this.renderYAxisCell({ key, rowIndex, style }) :
      this.renderHeatMapCell({ key, columnIndex: columnIndex - 1, rowIndex, style });
  }

  private _getColumnWidth({ index }: { index: number }) {
    return index > 0 ? CELL_WIDTH : 0;
  }
}

const fixedColumnStyle = `
  display: flex;
  align-items: center;
  height: 100%;
`;

const questionColumnStyle = `
  display: flex;
  align-items: center;
  height: 100%;
  background: ${Palette.paleGrey};
`;

function getBorderStyle({ scoreOrDelta }: { scoreOrDelta: "score" | "delta" }): string {
  const color = scoreOrDelta === "score" ? "white" : Palette.veryLightBlue;
  return `1px solid ${color}`;
}

const ContentBox = styled.div<any & { scoreOrDelta: "score" | "delta" }>`
  margin-top: 24px;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: auto;
  background-color: #FFF;
  background: white;

  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  color: #213848;

  &:first-of-type {
    padding-top: 1rem;
  }
  
  .LeftSideGrid {
    overflow: hidden !important;
  }

  .BodyGrid {
    width: 100%;
  }

  *:focus {outline:none}

  .border-cell {}

  .border-first-cell {
    border-left: 1px solid ${Palette.veryLightBlue};
  }

  .fixed-column-1 {
    ${fixedColumnStyle};
    width: 220px;
    padding-left: 32px;
    padding-right: 16px;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  .fixed-column-2 {
    ${fixedColumnStyle};
    justify-content: space-between;
    left: 220px;
    width: 100px;
    font-size: 18px;
    margin-left: auto;
    padding-right: 2px;
  }

  .fixed-question-column-1 {
    ${questionColumnStyle};
    left: 0;
    width: 220px;
    padding-left: 32px;
    padding-right: 16px;
    color: ${Palette.bluishGrey};
  }

  .fixed-question-column-2 {
    ${questionColumnStyle};
    justify-content: space-between;
    left: 220px;
    width: 100px;
    color: ${Palette.veryDarkBlueGrey};
    margin-left: auto;
    padding-right: 2px;
  }

  .overall {
    background: ${Palette.veryLightBlue};
    border-bottom: 1px solid ${props => props.scoreOrDelta === "score" ? "white" : Palette.veryLightBlue} !important;
  }

  // This is to allow the slanted Comparision header overflow onto the scraollable section
  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important ;
  }

  .comparison-header {
    overflow: visible;
    height: 65px;
    width: 150px;
    background-color: white;
    transform: translate(60px, 70px) rotate(307deg);
    span {
      border-top: 1px solid #ebeef3;
      margin-top: 8px;
      padding-top: 8px;
      padding-left: 40px;
      white-space: pre-wrap !important;
      text-overflow: ellipsis !important;
      overflow: hidden !important;
      width: 150px !important;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow-wrap: break-word;
      -webkit-box-orient: vertical;
    }
  }

  .veryFavorable {
    background: ${Palette.aquaBlue};
    color: white;
  }

  .favorable {
    background: #7fe1ec;
    color: ${Palette.darkBlueGrey};
  }

  .neutral {
    background: ${Palette.paleGrey};
    color: ${Palette.darkBlueGrey};
  }

  .unfavorable {
    background: ${Palette.softPink};
    color: ${Palette.darkBlueGrey};
  }

  .veryUnfavorable {
    background: ${Palette.darkPink};
    color: white;
  }

  .NA {
    background: white;
    color: ${Palette.darkBlueGrey};
  }

  .headerCell {
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: row;
  }

  .xAxisCell {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .yAxisCell {
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid ${Palette.veryLightBlue};
  }

  .yAxisCell:first-child {
    border-top: 1px solid ${Palette.lightGreyBlue};
  }

  .yAxisCell:last-child {
    border-bottom: 0px;
  }

  .cell, .first-row-cell, .first-column-cell {
    width: 100%;
    height: 100%;
    border-right: ${props => getBorderStyle(props)};
    border-bottom: ${props => getBorderStyle(props)};
    text-align: center;
  }

  .first-row-cell {
    border-top: ${props => getBorderStyle(props)};
  }

  .first-column-cell {
    border-left: ${props => getBorderStyle(props)};
  }
`;

interface GridContext {
  readonly key: string;
  readonly style: Dictionary<any>;
}

const GridRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`;

const LeftSideGridContainer = styled.div`
  flex: 0 0 75px;
  z-index: 10;
`;

const GridColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

const StyledArrowDoubleDown = styled(Icon)`
  margin-right: 8px;
  position: relative;
`;

const ColumnHeaderText = styled.span`
  font-size: 14px;
`;

const StyledRotatedContainer = styled.div`
  transform: translate(-5px, 35px) rotate(305deg);
  border-top: 1px solid ${Palette.lightGrey};
  display: flex;
  align-items: center;
  span {
    color: ${Palette.darkDarkDarkBlueGrey};
    white-space: pre-wrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: 40px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow-wrap: break-word;
    -webkit-box-orient: vertical;
  }
`;

const StyledYAxisDiv = styled.div<{ fontSize?: number }>`
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 3;
   line-height: 15px;
   max-height: 45px;
   font-size: ${props => props.fontSize || 14}px;
`;

const iconStyle = `
  margin-right: 8px;
  position: relative;
  font-size: 14px;
`;

const valueChangeStyle = `
  width: 48px;
  height: 18px;
  font-size: 14px;
`;

const StyledValueNoChange = styled.span`
  ${valueChangeStyle};
  color: ${Palette.darkDarkDarkBlueGrey};
`;

const StyledArrowDown = styled(Icon)`
  ${iconStyle};
`;

const StyledArrowNext = styled(ArrowNext)`
  ${iconStyle};
`;

const StyledValueChangeUp = styled.span`
  ${valueChangeStyle};
  color: ${Palette.aquaBlue};
`;

const StyledValueChangeDown = styled.span`
  ${valueChangeStyle};
  color: ${Palette.darkPink};
`;

const StyledDataCell = styled.div<any & { clickable: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: ${props => props.clickable ? "pointer" : "default"};
  background: ${props => props.cellBackground};
  color: ${props => props.cellColor};
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`;

const SegmentLabel = styled.div`
  text-align: center;
`;

const ColumnHeaderTextElipsis = styled.div`
  width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  color: ${Palette.darkBlueGrey};
  font-size: 14px;
`;

const getColorSchemeForDataCell = (
  value: Optional<number>, 
  scoreOrDelta: "score" | "delta", 
  heatmapTheme: Dictionary<any>|undefined) => {
  const defaultHeatmapTheme = { label:"NA", backgroundColor:"white", fontColor: "#1c1e56"};
  if (isNullOrUndefined(value) || isNaN(value)) {
    return defaultHeatmapTheme;
  }
  if (scoreOrDelta === "delta") {
    return defaultHeatmapTheme;
  }
  if (isNullOrUndefined(heatmapTheme)) {
    return defaultHeatmapTheme;
  }
  const scaleDownValue = 100/heatmapTheme.length;
  const categoryRange = Math.floor(value / scaleDownValue);
  if (heatmapTheme.length - categoryRange - 1 === 0 || categoryRange === 0) {
    return {...heatmapTheme[heatmapTheme.length - categoryRange - 1], fontColor: "white"};
  } else if (heatmapTheme.length === categoryRange) {
    return {...heatmapTheme[0], fontColor: "white"};
  } else {
    return {...heatmapTheme[heatmapTheme.length - categoryRange - 1], fontColor: "black"};
  }
};

/*
    Utility method and types to transform the ComputedHeatmap into something closer to Grid
 */

type YAxisCell = YAxisCategoryCell | YAxisQuestionCell | YAxisOverallCell;

interface YAxisCategoryCell {
  readonly type: "category";
  readonly data: ComputedHeatMap.Category;
}

interface YAxisQuestionCell {
  readonly type: "question";
  readonly data: ComputedHeatMap.Question;
}

interface YAxisOverallCell {
  readonly type: "overall";
  readonly data: ComputedHeatMap.RowResult;
}

interface XAxisCell {
  readonly label: string;
}

type HeatMapCell = ResultWithAnonymity<NonFilteredHeatMapCell>;

interface NonFilteredHeatMapCell {
  readonly onClick?: Optional<() => any>;
  readonly className: any;
  readonly score: Optional<number>;
}

interface PreComputedHeatMap {
  readonly context: {
    readonly dimension: string;
    readonly expandedCategories: ImmutableSet<string>;
    readonly scoreOrDelta: "score" | "delta";
    readonly comparisonKey: Optional<string>;
    readonly areComparisonsVisible: boolean;
  };
  readonly xAxisCells: XAxisCell[];
  readonly yAxisCells: YAxisCell[];
  readonly heatMapCells: HeatMapCell[][];
}

function preComputeHeatMap(heatMap: ComputedHeatMap,
  expandedCategories: ImmutableSet<string> = ImmutableSet(),
  scoreOrDelta: "score" | "delta",
  comparisonKey: Optional<string>,
  areComparisonsVisible: boolean,
  surveyId: string,
  surveyName: string,
  heatmapTheme: Dictionary<any>| undefined): PreComputedHeatMap {

  const xAxisCells: XAxisCell[] = heatMap.segments.map(segment => ({
    label: segment,
  }));

  const yAxisCells: YAxisCell[] = [];
  const heatMapCells: HeatMapCell[][] = [];
  for (const category of heatMap.categories) {
    yAxisCells.push({
      type: "category",
      data: category,
    });
    const categoryRow = category.filteredForAnonymity ?
      generateFilteredHeatMapCells(heatMap.segments.length, category.explanation) :
      generateHeatMapCells(
        category.cells,
        heatMap.dimension,
        heatMap.segments,
        scoreOrDelta,
        comparisonKey,
        "/categories/" + encodeURI(category._id),
        surveyId,
        surveyName,
        heatmapTheme
      );
    heatMapCells.push(categoryRow);

    if (expandedCategories.has(category._id)) {
      for (const question of category.questions) {
        yAxisCells.push({
          type: "question",
          data: question,
        });

        const questionRow = question.filteredForAnonymity ?
          generateFilteredHeatMapCells(heatMap.segments.length, question.explanation) :
          generateHeatMapCells(
            question.cells,
            heatMap.dimension,
            heatMap.segments,
            scoreOrDelta,
            comparisonKey,
            "/questions/" + question._id,
            surveyId,
            surveyName,
            heatmapTheme
          );
        heatMapCells.push(questionRow);
      }
    }
  }

  yAxisCells.push({
    type: "overall",
    data: heatMap.overall,
  });

  heatMapCells.push(
    generateHeatMapCells(
      heatMap.overall.cells,
      heatMap.dimension,
      heatMap.segments,
      scoreOrDelta,
      comparisonKey,
      "/categories",
      surveyId,
      surveyName,
      heatmapTheme
    )
  );

  return {
    context: {
      dimension: heatMap.dimension,
      expandedCategories,
      scoreOrDelta,
      comparisonKey,
      areComparisonsVisible,
    },
    xAxisCells,
    yAxisCells,
    heatMapCells,
  };
}

function generateFilteredHeatMapCells(n: number,
  explanation: AnonymityFilterExplanation): HeatMapCell[] {

  const res = new Array(n);
  for (let idx = 0; idx < n; idx++) {
    res[idx] = ResultWithAnonymity.filtered(explanation);
  }
  return res;
}

function generateHeatMapCells(cells: ResultWithAnonymity<ComputedHeatMap.Cell>[],
  dimension: string,
  segments: string[],
  scoreOrDelta: "score" | "delta",
  comparisonKey: Optional<string>,
  urlPrefix: string,
  surveyId: string,
  surveyName: string,
  heatmapTheme: Dictionary<any>|undefined): HeatMapCell[] {

  const res = new Array(cells.length);
  for (let idx = 0; idx < cells.length; idx++) {
    res[idx] = generateHeatMapCell(
      cells[idx],
      dimension,
      segments[idx],
      scoreOrDelta,
      comparisonKey,
      computeOnClickCell(
        urlPrefix,
        surveyId,
        surveyName,
        dimension,
        segments[idx]
      ),
      heatmapTheme
    );
  }
  return res;
}

function generateHeatMapCell(cell: ResultWithAnonymity<ComputedHeatMap.Cell>,
  dimension: string,
  segment: string,
  scoreOrDelta: "score" | "delta",
  comparisonKey: Optional<string>,
  onClick: () => any,
  heatmapTheme: Dictionary<any>|undefined): HeatMapCell {

  if (cell.filteredForAnonymity) {
    return ResultWithAnonymity.filtered(cell.explanation);
  }

  const score: Optional<number> = scoreOrDelta === "score" ?
    cell.favorability :
    Optional.map(
      comparisonKey,
      cKey => Optional.map(
        cell.compare,
        c => {
          const compareValue = c[cKey];
          if (isNotNullNorUndefined(compareValue) && compareValue.filteredForAnonymity === false) {
            return compareValue.value;
          }
          return Optional.empty();
        }
      )
    );

  const className = getColorSchemeForDataCell(score, scoreOrDelta, heatmapTheme);

  return ResultWithAnonymity.nonFiltered({
    onClick: (segment !== "[[~null~]]") ?
      ((isNotNullNorUndefined(score) ? onClick : Optional.empty()) as Optional<() => any>)
      : null,
    className,
    score,
  });
}

function computeOnClickCell(urlPrefix: string,
  surveyId: string,
  surveyName: string,
  dimension: string,
  segment: string): () => any {

  return () => {
    const queryString = computeSegmentSuffix(dimension, segment);
    goTo(
      "/surveys/view/" + surveyId + "/reports" + appendQueryString(urlPrefix, queryString),
      Breadcrumb.stack(`${surveyName} - Heatmap report`)
    );
  };
}

function computeSegmentSuffix(dimension: string, segment: string): string {
  return generateQueryString({
    filter: {
      dimensions: {
        [dimension]: [segment],
      },
    },
    merge: true,
  });
}
