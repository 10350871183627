import styled from "styled-components";
import { getOr } from "hyphen-lib/dist/lang/Objects";

export const CellContainer = styled.div<{ align: "center" | "flex-start"; justifyContent: "flex-start" | "flex-end" }>`
  display: flex;
  align-items: ${props => props.align};
  justify-content: ${props => props.justifyContent};
  width: 100%;
`;

export const CellInnerContainer = styled.div<{ margin?: number; padding?: number[]; width: string }>`
  display: flex;
  flex-direction: column;
  width: ${props => props.width};
  margin-top: ${props => getOr(props.margin, 0 as number) + "px"};
  padding: ${props => mapNumbersToPixels(getOr(props.padding, []))};
  position: relative;
  justify-content: space-evenly;
`;

function mapNumbersToPixels(values: number[]) {
  return values.map(value => value + "px").join(" ");
}