import { PulsePollResource } from "hyphen-lib/dist/domain/resource/PulsePollResource";
import { SurveyResource } from "hyphen-lib/dist/domain/resource/SurveyResource";
import { useTranslation } from "react-i18next";
import { TFunction } from "react-i18next";
import {
  checkSurveyResourceConsistency,
  CheckSurveyResourceConsistencyOptions
} from "hyphen-lib/dist/business/survey/SurveyResources";
import {
  checkPulsePollResourceConsistency,
  CheckPulsePollResourceConsistencyOptions
} from "hyphen-lib/dist/business/pulsePoll/PulsePollResources";
import { isEmpty } from "hyphen-lib/dist/lang/Arrays";
import { isNotNullNorUndefined, isNullOrUndefined, getOr } from "hyphen-lib/dist/lang/Objects";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import { isInstanceOfException } from "hyphen-lib/dist/lang/exception/Exceptions";
import { Map, Seq } from "immutable";

import { SettingsStateProps } from "@src/screens/Insights/Surveys/store/types";
import { SurveyQuestionResource } from "hyphen-lib/dist/domain/resource/SurveyQuestionResource";
import { checkSurveyQuestionResourceConsistency } from "hyphen-lib/dist/business/survey/SurveyQuestionResources";
import LabelMapper from "@src/utils/constants/SurveyCreation";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { QuestionConfig } from "hyphen-lib/dist/domain/common/QuestionType";
import { translate } from "@src/utils/i18next";

export type AllErrors = Map<string, string>;

/**
 *  Function to validate form fields while typing into them
 *
 * @export
 * @param {any} value
 * @param {function} callback
 * @param {string} fieldName
 * @param {string} requiredMessage
 * @param {SurveyResource} survey
 * @returns callback function with a string as the parameter which acts as an error message
 */
export function fieldValidator(
  value: any,
  callback: any,
  fieldName: keyof SettingsStateProps["communication"] | keyof SettingsStateProps["general"],
  requiredMessage: string,
  survey: SurveyResource
) {
  const { t } = useTranslation();

  // Set the latest value of the field since the survey resource will be from redux store
  const newSurveyResource = {
    ...survey,
    [fieldName]: value,
  };

  // Set the error message if field is empty
  if (isNotNullNorUndefined(value) && value.length <= 0) {
    return callback(requiredMessage);
  }

  // Get errors by checking the survey consistency
  const errors = getErrorsByFieldName(t, newSurveyResource, fieldName);

  // If no errors, don't show anything
  if (errors.length <= 0) {
    callback();
    return;
  }

  // return all errors.
  return callback(errors.join(", "));
}

/**
 * Check consistency of survey and return array of errors if required
 *
 * @export
 * @param {SurveyResource} survey
 * @param {CheckSurveyResourceConsistencyOptions} [consistencyOptions]
 * @returns {array} array of Errors
 */
export function getErrorsForSurvey(
  survey: SurveyResource,
  consistencyOptions?: Partial<CheckSurveyResourceConsistencyOptions>
) {
  let errors: WrongEntityException.WrongField[] = [];
  try {
    checkSurveyResourceConsistency(survey, { failFast: false, ...consistencyOptions  });
  } catch (e) {
    if (isInstanceOfException(e, WrongEntityException)) {
      errors = e.errors();
    }
  }
  return errors;
}

/**
 * Check consistency of surveyQuestion and return array of errors if required
 *
 * @export
 * @param {SurveyQuestionResource} survey
 * @returns {array} array of Errors
 */
export function getErrorsForSurveyQuestion(question: SurveyQuestionResource, questionConfig: QuestionConfig) {
  let errors: WrongEntityException.WrongField[] = [];
  try {
    checkSurveyQuestionResourceConsistency(question, question.surveyId, questionConfig, { failFast: false });
  } catch (e) {
    if (isInstanceOfException(e, WrongEntityException)) {
      errors = e.errors();
    }
  }
  return errors;
}

/**
 * Check consistency of polls and return array of errors if required
 *
 * @export
 * @param {PulsePollResource} survey
 * @param {CheckPulsePollResourceConsistencyOptions} [consistencyOptions]
 * @returns {array} array of Errors
 */
export function getErrorsForPulsePoll(
  pulsePoll: PulsePollResource,
  questionConfig: QuestionConfig,
  consistencyOptions?: Partial<CheckPulsePollResourceConsistencyOptions>
) {
  let errors: WrongEntityException.WrongField[] = [];
  try {
    checkPulsePollResourceConsistency(pulsePoll, questionConfig, { failFast: false, ...consistencyOptions });
  } catch (e) {
    if (isInstanceOfException(e, WrongEntityException)) {
      errors = e.errors();
    }
  }
  return errors;
}

/**
 * Function to get errors of settings page in a key value pair
 *
 * @export
 * @param {SurveyResource} survey
 * @param {CheckSurveyResourceConsistencyOptions} [consistencyOptions]
 * @returns an object with all the errors for general settings and communications form as Immutable Maps.
 */
export function getSurveyResourceErrors(
  t: TFunction,
  survey: SurveyResource,
  consistencyOptions?: Partial<CheckSurveyResourceConsistencyOptions>
): AllErrors {
  // Get all errors
  const errors = getErrorsForSurvey(survey, consistencyOptions);
  return getErrors(t, errors);
}

/**
 * Function to get errors of settings page in a key value pair
 *
 * @export
 * @param {SurveyResource} pulsePoll
 * @param {CheckSurveyResourceConsistencyOptions} [consistencyOptions]
 * @returns an object with all the errors for general settings and communications form as Immutable Maps.
 */
export function getPulsePollResourceErrors(
  t: TFunction,
  pulsePoll: PulsePollResource,
  questionConfig: QuestionConfig,
  consistencyOptions?: Partial<CheckPulsePollResourceConsistencyOptions>
): AllErrors {
  // Get all errors
  const errors = getErrorsForPulsePoll(pulsePoll, questionConfig, consistencyOptions);
  return getErrors(t, errors);
}

/**
 * Function to get errors while editing or creating a question
 *
 * @export
 * @param {SurveyQuestionResource} survey
 * @returns an object with all the errors for survey questions.
 */
// export function getErrorsForQuestions(question: SurveyQuestionResource, questionConfig: QuestionConfig): AllErrors {
//   // Get all errors
//   const errors = getErrorsForSurveyQuestion(question, questionConfig);
//   return getErrors(errors);
// }

/**
 * Returns a map with all field errors
 *
 * @param {WrongEntityException.WrongField[]} errors
 * @returns {AllErrors}
 */
export function getErrors(
  t: TFunction,
  errors: WrongEntityException.WrongField[],
  errorMessagesFunc?: (t: TFunction, error: WrongEntityException.WrongField) => { key: string; message: string }
): AllErrors {
  let errorObject: AllErrors = Map();

  // return empty object if no errors are present
  if (isEmpty(errors)) {
    return errorObject;
  }

  // push errors into the specific forms
  errors.forEach((error: WrongEntityException.WrongField) => {
    const errorMessage = isNotNullNorUndefined(errorMessagesFunc)
      ? errorMessagesFunc(t, error)
      : getErrorMessages(t, error);
    errorObject = errorObject.set(errorMessage.key, errorMessage.message);
  });
  return errorObject;
}

/**
 * Parse the survey through checkSurveyResourceConsistency(hyphen-lib) and extract errors related to
 * a specific field.
 *
 * @param {SurveyResource} survey
 * @param {string} key
 * @returns errors array.
 */
export function getErrorsByFieldName(
  t: TFunction,
  survey: SurveyResource,
  key: string
) {
  // Get all errors
  const errors = getErrorsForSurvey(survey);
  // Extract and return the errors related to just the specific field.
  return Seq(errors)
    .filter((error: WrongEntityException.WrongField) => error.key === key)
    .map((error: WrongEntityException.WrongField) => getErrorMessages(t, error).message)
    .toArray();
}

export function getErrorMessages(
  t: TFunction,
  error: WrongEntityException.WrongField
): { key: string; message: string } {
  const errorMessage = {
    key: error.key,
    message: error.reason,
  };

  if (isNotNullNorUndefined(error.context)) {
    switch (error.key) {
      case "name":
        if ("minLength" in error.context) {
          // eslint-disable-next-line max-len
          errorMessage.message = `${translate(t, LabelMapper.getIn(["settings", "name"]))} ${translate(t, "is required")}`;
        }
        break;
      case "introductionText":
        if ("minLength" in error.context) {
          const {minLength} = error.context;
          // eslint-disable-next-line max-len
          errorMessage.message = `${translate(t, LabelMapper.getIn(["settings", "introductionText"]))} ${translate(t, "should have a minimum of")} ${minLength} ${translate(t, "characters")}`;
        }
        if ("maxLength" in error.context) {
          const {maxLength} = error.context;
          // eslint-disable-next-line max-len
          errorMessage.message = `${translate(t, LabelMapper.getIn(["settings", "introductionText"]))} ${translate(t, "should not have more than")} ${maxLength} ${translate(t, "characters")}`;
        }
        break;
      case "channels.email.subject":
        if ("minLength" in error.context) {
          const {minLength} = error.context;
          // eslint-disable-next-line max-len
          errorMessage.message = `${translate(t, LabelMapper.getIn(["settings", "launchEmailSubject"]))} ${translate(t, "should have a minimum of")} ${minLength} ${translate(t, "characters")}`;
        }
        if ("maxLength" in error.context) {
          const {maxLength} = error.context;
          // eslint-disable-next-line max-len
          errorMessage.message = `${translate(t, LabelMapper.getIn(["settings", "launchEmailSubject"]))} ${translate(t, "should not have more than")} ${maxLength} ${translate(t, "characters")}`;
        }
        break;
      case "channels.email.body":
        if ("minLength" in error.context) {
          const {minLength} = error.context;
          // eslint-disable-next-line max-len
          errorMessage.message = `${translate(t, LabelMapper.getIn(["settings", "launchEmailBody"]))} ${translate(t, "should have a minimum of")} ${minLength} ${translate(t, "characters")}`;
        }
        if ("maxLength" in error.context) {
          const {maxLength} = error.context;
          // eslint-disable-next-line max-len
          errorMessage.message = `${translate(t, LabelMapper.getIn(["settings", "launchEmailBody"]))} ${translate(t, "should not have more than")} ${maxLength} ${translate(t, "characters")}`;
        }
        break;
      case "channels.slack.body":
        if ("minLength" in error.context) {
          const {minLength} = error.context;
          // eslint-disable-next-line max-len
          errorMessage.message = `${translate(t, LabelMapper.getIn(["settings", "channels.slack.body"]))} ${translate(t, "should have a minimum of")} ${minLength} ${translate(t, "characters")}`;
        }
        if ("maxLength" in error.context) {
          const {maxLength} = error.context;
          // eslint-disable-next-line max-len
          errorMessage.message = `${translate(t, LabelMapper.getIn(["settings", "channels.slack.body"]))} ${translate(t, "should not have more than")} ${maxLength} ${translate(t, "characters")}`;
        }
        break;
      case "channels.beekeeper.body":
        if ("minLength" in error.context) {
          const { minLength } = error.context;
          // eslint-disable-next-line max-len
          errorMessage.message = `${translate(t, LabelMapper.getIn(["settings", "channels.beekeeper.body"]))} ${translate(t, "should have a minimum of")} ${minLength} ${translate(t, "characters")}`;
        }
        if ("maxLength" in error.context) {
          const { maxLength } = error.context;
          // eslint-disable-next-line max-len
          errorMessage.message = `${translate(t, LabelMapper.getIn(["settings", "channels.beekeeper.body"]))} ${translate(t, "should not have more than")} ${maxLength} ${translate(t, "characters")}`;
        }
        break;
      case "channels.speakap.body":
        if ("minLength" in error.context) {
          const { minLength } = error.context;
          // eslint-disable-next-line max-len
          errorMessage.message = `${translate(t, LabelMapper.getIn(["settings", "channels.speakap.body"]))} ${translate(t, "should have a minimum of")} ${minLength} ${translate(t, "characters")}`;
        }
        if ("maxLength" in error.context) {
          const { maxLength } = error.context;
          // eslint-disable-next-line max-len
          errorMessage.message = `${translate(t, LabelMapper.getIn(["settings", "channels.speakap.body"]))} ${translate(t, "should not have more than")} ${maxLength} ${translate(t, "characters")}`;
        }
        break;
      case "channels.symphony.body":
        if ("minLength" in error.context) {
          const { minLength } = error.context;
          // eslint-disable-next-line max-len
          errorMessage.message = `${translate(t, LabelMapper.getIn(["settings", "channels.symphony.body"]))} ${translate(t, "should have a minimum of")} ${minLength} ${translate(t, "characters")}`;
        }
        if ("maxLength" in error.context) {
          const { maxLength } = error.context;
          // eslint-disable-next-line max-len
          errorMessage.message = `${translate(t, LabelMapper.getIn(["settings", "channels.symphony.body"]))} ${translate(t, "should not have more than")} ${maxLength} ${translate(t, "characters")}`;
        }
        break;
      case "channels.workplace.body":
        if ("minLength" in error.context) {
          const { minLength } = error.context;
          // eslint-disable-next-line max-len
          errorMessage.message = `${translate(t, LabelMapper.getIn(["settings", "channels.workplace.body"]))} ${translate(t, "should have a minimum of")} ${minLength} ${translate(t, "characters")}`;
        }
        if ("maxLength" in error.context) {
          const { maxLength } = error.context;
          // eslint-disable-next-line max-len
          errorMessage.message = `${translate(t, LabelMapper.getIn(["settings", "channels.workplace.body"]))} ${translate(t, "should not have more than")} ${maxLength} ${translate(t, "characters")}`;
        }
        break;
      case "channels.sms.body":
        if ("minLength" in error.context) {
          const { minLength } = error.context;
          // eslint-disable-next-line max-len
          errorMessage.message = `${translate(t, LabelMapper.getIn(["settings", "channels.sms.body"]))} ${translate(t, "should have a minimum of")} ${minLength} ${translate(t, "characters")}`;
        }
        if ("maxLength" in error.context) {
          const { maxLength } = error.context;
          // eslint-disable-next-line max-len
          errorMessage.message = `${translate(t, LabelMapper.getIn(["settings", "channels.sms.body"]))} ${translate(t, "should not have more than")} ${maxLength} ${translate(t, "characters")}`;
        }
        break;
      case "postTemplateIds":
        if ("minLength" in error.context) {
          const {minLength} = error.context;
          // eslint-disable-next-line max-len
          errorMessage.message = `${translate(t, "There should be at least")} ${minLength} ${translate(t, `survey question${minLength > 1 ? "s" : null}`)}`;
        }
        break;
      case "question":
        if ("minLength" in error.context) {
          const {minLength} = error.context;
          // eslint-disable-next-line max-len
          errorMessage.message = `${translate(t, "A question should have at least")} ${minLength} ${translate(t, "characters")}`;
        } else if ("maxLength" in error.context) {
          const { maxLength } = error.context;
          // eslint-disable-next-line max-len
          errorMessage.message = `${translate(t, "A question can not have more than")} ${maxLength} ${translate(t, "characters")}`;
        }
        break;
      case "choices":
        if ("minLength" in error.context) {
          const {minLength} = error.context;
          // eslint-disable-next-line max-len
          errorMessage.message = `${translate(t, "A question must have at least")} ${minLength} ${translate(t, "choices")}`;
        }
        break;
      default:
        break;
    }
  }
  else {
    if (error.key === "reminderInterval" && error.errorCode === WrongEntityException.ErrorCodes.NUMBER_LESS_THAN) {
      // eslint-disable-next-line max-len
      errorMessage.message = translate(t, "Reminder interval should be less than Reminder limit");
    }
  }
  return errorMessage;
}

export function toHumanReadableErrorMessage(
  t: TFunction,
  error: Optional<WrongEntityException.WrongField>,
  label: string): Optional<string> {
  if (isNullOrUndefined(error)) {
    return Optional.empty();
  }

  const safeContext = getOr(error.context, {});
  const fieldLabel = getOr(fieldLabelMapper.get(label), label);
  switch (error.errorCode) {
    case WrongEntityException.ErrorCodes.REQUIRED_FIELD:
      return `${fieldLabel} ${translate(t, "is required")}`;
    case WrongEntityException.ErrorCodes.STRING_TOO_SHORT:
      // eslint-disable-next-line max-len
      return `${fieldLabel} ${translate(t, "should have a minimum of")} ${safeContext.minLength} ${translate(t, "characters")}`;
    case WrongEntityException.ErrorCodes.STRING_TOO_LONG:
      // eslint-disable-next-line max-len
      return `${fieldLabel} ${translate(t, "should not have more than")} ${safeContext.maxLength} ${translate(t, "characters")}`;
    case WrongEntityException.ErrorCodes.ARRAY_TOO_SHORT:
      // eslint-disable-next-line max-len
      return `${fieldLabel} ${translate(t, "should have a minimum of")} ${safeContext.minLength} ${translate(t, "options")}`;
    case WrongEntityException.ErrorCodes.NUMBER_GREATER_THAN:
      // eslint-disable-next-line max-len
      return `${fieldLabel} ${translate(t, "should not be greater than")} ${safeContext.maxValue} ${translate(t, "options")}`;
    case WrongEntityException.ErrorCodes.NUMBER_LESS_THAN:
      // eslint-disable-next-line max-len
      return `${fieldLabel} ${translate(t, "should not be less than")} ${safeContext.minValue} ${translate(t, "options")}`;
    case WrongEntityException.ErrorCodes.ARRAY_EMPTY:
      return `${fieldLabel} should not be empty`;
    case WrongEntityException.ErrorCodes.WRONG_TYPE:
      return `${fieldLabel} should not be empty`;
  }

  // we didn't manage to map the error.
  return `${error.reason}`;
}

const fieldLabelMapper = Map({
  maxNumberOfOptions: "Maximum choices",
});

export function toErrors(errors: any): WrongEntityException.Errors {
  let errorsObject: WrongEntityException.Errors;
  if (isNullOrUndefined(errors)) {
    errorsObject = WrongEntityException.withGlobalError("Unexpected error.");
  } else {
    if (Array.isArray(errors)) {
      errorsObject = WrongEntityException.extractErrors(errors);
    } else {
      errorsObject = WrongEntityException.withGlobalError(errors);
    }
  }
  return errorsObject;
}
