import React from "react";
import { StatusTag } from "@components/core/StatusTag";
import { DynamicRowProps } from "@components/core/DynamicRowsTable/withDynamicRows";
import {
  CellContainer,
  CellInnerContainer
} from "@src/screens/Insights/SurveyList/components/cells/Cells";
import { PulsePollInfoResource } from "hyphen-lib/dist/domain/resource/PulsePollInfoResource";
import { Post } from "hyphen-lib/dist/domain/Post";

export function PulsePollStatusCell({
  row,
  index,
}: DynamicRowProps<PulsePollInfoResource>) {
  let { status } = row;
  if (row.status === Post.Status.DEACTIVATED &&
      row.frequency === "once" &&
      !row.isLastInstanceLaunched) {
    status = "closed" as Post.Status;
  }

  return (
    <CellContainer
      align="center"
      justifyContent="flex-start"
      data-cy={`listView_status_${index}`}
    >
      <CellInnerContainer margin={0} width="87px">
        <StatusTag status={status} type="poll" />
      </CellInnerContainer>
    </CellContainer>
  );
}
