import { Record, RecordOf, List, fromJS, Map } from "immutable";
import { CompanyRoleResource } from "hyphen-lib/dist/domain/resource/CompanyRoleResource";
import { UserRolesActionTypes, SelectRoleAction, ModalPayload } from "./actions";

export interface CompanyRoleResourceMap extends Map<string, any> {
  toJS(): CompanyRoleResource;
  get<K extends keyof CompanyRoleResource>(key: K): CompanyRoleResource[K];
}

export interface RoleModals {
  readonly isAddModalVisible: boolean;
  readonly isRemoveModalVisible: boolean;
}

export interface UserRolesStateProps extends RoleModals {
  readonly selectedRole: CompanyRoleResourceMap;
  readonly roles: List<CompanyRoleResourceMap>;
  readonly isNetworkRequesting: boolean;
  readonly isRoleUpdated: boolean;
}
export type UserRolesState = RecordOf<UserRolesStateProps>;

export const defaultValues: UserRolesStateProps = {
  selectedRole: Map({}) as CompanyRoleResourceMap,
  roles: List([]),
  isAddModalVisible: false,
  isRemoveModalVisible: false,
  isNetworkRequesting: false,
  isRoleUpdated: false,
};

export const userRolesStateFactory = Record<UserRolesStateProps>(defaultValues);

type Actions = SelectRoleAction;

export function userRolesReducers(state: UserRolesState = userRolesStateFactory(),
  action: Actions | any): UserRolesState {
  switch (action.type) {
    case UserRolesActionTypes.SELECT_ROLE:
      return state.set("selectedRole", action.role);
    case UserRolesActionTypes.FETCH_COMPANY_ROLES_REQUEST:
    case UserRolesActionTypes.UPDATE_ROLES_REQUEST:
      return state.set("isNetworkRequesting", true);
    case UserRolesActionTypes.FETCH_COMPANY_ROLES_SUCCESS:
    case UserRolesActionTypes.UPDATE_ROLES_SUCCESS:
      return state.merge({
        roles: fromJS(action.payload.data),
        isAddModalVisible: false,
        isRemoveModalVisible: false,
        isNetworkRequesting: false,
        isRoleUpdated: false,
      });
    case UserRolesActionTypes.UPDATE_ROLES_ERROR:
    case UserRolesActionTypes.FETCH_COMPANY_ROLES_ERROR:
      return state.set("isNetworkRequesting", false);
    case UserRolesActionTypes.UPDATE_SELECTED_ROLE:
      return state.withMutations(map => {
        return map
          .setIn(["selectedRole", action.payload.key], action.payload.value)
          .set("isRoleUpdated", true);
      });
    case UserRolesActionTypes.TOGGLE_MODAL_VISIBILITY: {
      const { modalType, isModalVisible } = action.payload as ModalPayload;
      return state.set(modalType, isModalVisible);
    }
    case UserRolesActionTypes.UPDATE_IS_ROLE_UPDATED:
      return state.set("isRoleUpdated", action.payload);
    default:
      return state;
  }
}
