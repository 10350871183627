import React from "react";
import { Tabs as AntTabs } from "antd";
import styled from "styled-components";
import { TabsProps } from "antd/lib/tabs";
import Palette from "@src/config/theme/palette";
import hoistNonReactStatics from "hoist-non-react-statics";

const Tabs = styled(AntTabs)<TabsProps>`
  .ant-tabs-tab, .ant-tabs-tab-active {
    font-family: Lato;
    color: ${Palette.darkBlueGrey} !important;
    font-size: 16px;
    padding: 18px 24px !important;
    margin-right: 24px !important;
  }
  .ant-tabs-tab-active {
    font-weight: bold !important;
  }
  .ant-tabs-ink-bar {
    height: 3px;
    background-color: ${Palette.bluePurple};
  }
`;

export default hoistNonReactStatics(Tabs as React.ComponentType<TabsProps>, AntTabs);
