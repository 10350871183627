import React from "react";

import { isEmptyObject, isNullOrUndefined } from "@hyphen-lib/lang/Objects";
import { DistributionResult } from "@hyphen-lib/domain/resource/report/common/DistributionResult";
import BarChart, { BarChartDimension, BarChartSegment } from "@src/components/core/BarChart";
import { getSegmentLabel } from "hyphen-lib/dist/domain/common/Dimensions";
import {
  MultipleChoiceQuestionReportResource
} from "hyphen-lib/dist/domain/resource/report/MultipleChoiceQuestionReportResource";
import { getOr, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { adjustElementsAndPercentages } from "@src/utils/Graphs";
import {
  AnonymityFilterExplanation
} from "hyphen-lib/dist/domain/common/AnonymityFilterExplanation";
import { sanitizeSegmentLabel } from "hyphen-lib/dist/domain/common/Dimensions";
import { compact } from "lodash";
import { getBarConfigForRendering } from "@src/config/theme/segmentPalette";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { ParticipationReportResource } from "hyphen-lib/dist/domain/resource/survey/report/ParticipationReportResource";
import { SelectValue } from "antd/lib/select";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { sortStringArrayAsc } from "hyphen-lib/dist/lang/Arrays";


interface Dimension {
  [segment: string]: MultipleChoiceQuestionReportResource.DistributionResultForSegment;
}

export interface GraphColor {
  backgroundColor: string;
  fontColor?: string;
}

interface Props {
  dimension: Dimension;
  participationBySegments?:Store.Element<ParticipationReportResource>;
  selectedDimension:SelectValue;
  anonymityThreshold: number;
}

export default class SegmentChart extends React.Component<Props> {
  labelWidth = "170px";

  getDataForChart = (
    input: Dimension
  ): BarChartDimension | undefined => {
    const data: BarChartDimension = {};
    const { participationBySegments, selectedDimension } = this.props;

    let participationBySegment: Dictionary<any> = {};
    if (isNotNullNorUndefined(participationBySegments) && Store.Element.isLoaded(participationBySegments)) {
      participationBySegment = getOr(participationBySegments.value.dimensions[selectedDimension.toString()], {});
    }
    if (isNullOrUndefined(participationBySegments)) {
      participationBySegment = getOr(input, {});
    }
    sortStringArrayAsc(compact(Object.keys(participationBySegment))).forEach((label) => {
      let segmentAudience = 0;
      if (isNotNullNorUndefined(participationBySegment[label])) {
        const { total } = participationBySegment[label];
        segmentAudience = total;
      }
      let distribution: any = input[label];
      if(isNotNullNorUndefined(distribution)){
        if (isEmptyObject(distribution)) {
          const { filteredForAnonymity } = distribution;
          if (filteredForAnonymity) {
            distribution = {
              filteredForAnonymity: true,
              explanation: {
                reason: AnonymityFilterExplanation.Reason.NOT_ENOUGH_VOTERS,
                numberOfVoters: 0,
              },
            };
          }
          else {
            distribution = {
              filteredForAnonymity: false,
              total: 0,
              items: [],
            };
  
          }
        };
        let segment: BarChartSegment;
        if (distribution.filteredForAnonymity === false) {
          const { pieceToAdd } = adjustElementsAndPercentages(distribution.items);
          const {
            choiceLabels,
            distribution: distributionResult, barTheme,
          } = getBarConfigForRendering(distribution.items);
          const series =
            distributionResult
              .map((distributionItem: DistributionResult.Item, index: number) => {
                const { total, percentage } = distributionItem;
  
                return {
                  ...barTheme[index],
                  value: total,
                  percentage,
                  percentageWidth: isNotNullNorUndefined(percentage) && percentage > 0 ?
                    percentage + pieceToAdd :
                    0,
                  label: getOr(choiceLabels[index], "unknown"),
                };
              });
          segment = { total: segmentAudience, series, filteredForAnonymity: false };
        } else {
          segment = {
            total: segmentAudience,
            series: [],
            filteredForAnonymity: true,
            explanation: distribution.explanation,
          };
        }
        data[sanitizeSegmentLabel(getSegmentLabel(label))] = segment;
      }
    });

    return data;
  };

  render() {
    const {
      dimension,
      anonymityThreshold,
    } = this.props;

    return <BarChart
      data={this.getDataForChart(dimension)}
      labelWidth={this.labelWidth}
      anonymityThreshold={anonymityThreshold}
      isTopicalSegment={true}
    />;
  }
}


