import React from "react";

import { BaseViewOptionProps } from "@screens/Insights/components/ViewOptions/components/ViewOptionsContent";
import { StyledRadioGroup } from "@screens/Insights/components/ViewOptions/components/CompareWith";
import { isEmpty } from "hyphen-lib/dist/lang/Arrays";
import Radio from "@components/core/Radio";
import { RadioChangeEvent } from "antd/lib/radio";
import { Trans } from "react-i18next";

export interface RadioViewOptionProps extends BaseViewOptionProps<string> {
  readonly options: { key: string; label: string }[];
}

export class RadioViewOption extends React.Component<RadioViewOptionProps> {

  handleChange = (e: RadioChangeEvent) => {
    const {
      onChange,
    } = this.props;

    const optionKey = e.target.value;
    onChange(optionKey);
  };

  render() {
    const {
      label,
      value,
      isDisabled = false,
      options,
    } = this.props;

    if (isEmpty(options)) {
      return null;
    }

    return (
      <div>
        <span><Trans>{label}</Trans></span>
        <StyledRadioGroup onChange={this.handleChange} value={value} disabled={isDisabled}>
          {
            options.map(option => (
              <Radio
                key={option.key}
                value={option.key}
              >
                <Trans>{option.label}</Trans>
              </Radio>
            ))
          }
        </StyledRadioGroup>
      </div>
    );
  }
}
