import React, { Component } from "react";
import styled from "styled-components";

import Palette from "@src/config/theme/palette";
import { InfoItem } from "@components/core/InfoCard/InfoItem";

import { SurveyQuestionResource } from "@hyphen-lib/domain/resource/SurveyQuestionResource";
import { QuestionConfig } from "@hyphen-lib/domain/common/QuestionType";

import { not } from "hyphen-lib/dist/lang/Booleans";
import { isEmpty } from "hyphen-lib/dist/lang/Arrays";
import { isNotNullNorUndefined } from "@hyphen-lib/lang/Objects";
import { getQuestionTypeLabel } from "hyphen-lib/dist/business/question/Questions";

interface Props {
  questions: SurveyQuestionResource[];
  isSummary?: boolean;
  questionConfig: QuestionConfig;
}

export class SurveyQuestionsSection extends Component<Props> {
  getQuestionsByType = () => {
    const { questions, questionConfig } = this.props;
    const templateQuestions: SurveyQuestionResource[] = [];
    const customQuestions: SurveyQuestionResource[] = [];

    if (Array.isArray(questions) && not(isEmpty(questions))) {
      questions.forEach((question: SurveyQuestionResource) => {
        const {
          choices,
          isRatingQuestion,
          isNPSQuestion,
          isMultiSelect,
          type,
        }: any = question;
        const questionType = getQuestionTypeLabel(
          type,
          questionConfig,
          choices,
          isRatingQuestion,
          isNPSQuestion,
          isMultiSelect
        );
        const updatedQuestion = {...question, type: questionType } as any;
        if (isNotNullNorUndefined(question.surveyTemplatePostId)) {
          templateQuestions.push(updatedQuestion);
        } else {
          customQuestions.push(updatedQuestion);
        }
      });
    }

    return {
      templateQuestions,
      customQuestions,
    };
  };

  render() {
    const { isSummary = false } = this.props;
    const { templateQuestions, customQuestions } = this.getQuestionsByType();

    return (
      <>
        <InfoItem label="Template questions" data-cy="template-questions">
          {isSummary
            ? templateQuestions.length
            : templateQuestions.map((question: SurveyQuestionResource) => {
                return (
                  <QuestionContainer key={question.question}>
                    <QuestionTextLabel>{question.question}</QuestionTextLabel>
                    <QuestionTypeLabel>{question.type}</QuestionTypeLabel>
                  </QuestionContainer>
                );
              })}
        </InfoItem>

        <InfoItem label="Custom questions" data-cy="custom-questions">
          {isSummary
            ? customQuestions.length
            : customQuestions.map((question: SurveyQuestionResource) => {
                return (
                  <QuestionContainer key={question.question}>
                    <QuestionTextLabel>{question.question}</QuestionTextLabel>
                    <QuestionTypeLabel>{question.type}</QuestionTypeLabel>
                  </QuestionContainer>
                );
              })}
        </InfoItem>
      </>
    );
  }
}

const QuestionContainer = styled.div`
  margin-top: 24px;

  &:first-of-type {
    margin-top: 0;
  }
`;

const QuestionTextLabel = styled.div``;

const QuestionTypeLabel = styled.div`
  color: ${Palette.bluishGrey};
`;
