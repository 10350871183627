import { put, select, takeLatest } from "@redux-saga/core/effects";
import { freeze, isNullOrUndefined } from "hyphen-lib/dist/lang/Objects";
import { ActionTypes } from "@screens/Insights/store/actions";
import { getCompany } from "@screens/Insights/store/selectors";
import { NetworkRequestAction } from "@store/network/actions";
import { getAppSettings } from "@screens/Insights/Settings/store/selectors";
import Logger from "hyphen-lib/dist/util/Logger";
import { settingsActionCreators } from "@screens/Insights/Settings/store/actions";

const log = Logger.create("Insights/store/saga");

export function* fetchAppSettingsIfNeeded() {
  const appSettings = yield select(getAppSettings);
  if (appSettings.isEmpty()) { // fixme: currently app settings is an array :/
    const company = yield select(getCompany);
    if (isNullOrUndefined(company)) {
      log.warn("Unable to dispatch the app settings fetch, the current company is not available");
    } else {
      yield put<NetworkRequestAction>(settingsActionCreators.fetchAppSettings(company.name));
    }
  }
}

export const mainSagas = freeze([

  // ~~~~~~~~~~~~~ NETWORK ~~~~~~~~~~~~~~~~

  /*
    Listen the latest "fetch current user success" action in order to dispatch the fetch of app settings
    if it not already available in the store.
   */
  takeLatest(
    ActionTypes.FETCH_CURRENT_USER_SUCCESS,
    fetchAppSettingsIfNeeded
  ),
]);
