import styled, { css } from "styled-components";
import Palette from "@src/config/theme/palette";
import { Icon } from "antd";
import CreateActionPlanButton from "@src/screens/Insights/components/Reports/CreateActionPlanButton";
import { withRouter, RouteComponentProps } from "react-router";
import { Breadcrumb, goTo } from "@src/utils/locations";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { CompareWithOption } from "@src/screens/Insights/components/ViewOptions/components/CompareWith";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { extractComparison } from "hyphen-lib/dist/business/calculation/benchmark/Benchmarks";
import CompareValue from "@src/components/core/CompareValue";
import { HighlightedQuestion } from "hyphen-lib/dist/domain/resource/report/common/Highlights";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { FocusArea, Module } from "hyphen-lib/dist/domain/FocusArea";
import { Trans } from "react-i18next";
interface MatchParams {
  id: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  question: HighlightedQuestion;
  tableName: string;
  readonly hasActionCreationRight: boolean;
  postionIconInsideSection?: boolean;
  showOnHover?: boolean;
  isComparisionVisible?: boolean;
  compareWithOptions: CompareWithOption[];
  comparisonKey: Optional<string>;
  readonly focusAreas?: FocusAreaResource[];
  readonly onCreateFocusArea: (focusArea: Partial<FocusAreaResource>) => void;
  readonly questionSource:
  | FocusArea.Source.QUESTION
  | FocusArea.Source.TOP_QUESTION
  | FocusArea.Source.BOTTOM_QUESTION;
  readonly surveyName: string;
}

const Question = ({
  question: highlightedQuestion,
  tableName,
  postionIconInsideSection,
  hasActionCreationRight,
  showOnHover,
  isComparisionVisible,
  compareWithOptions,
  comparisonKey,
  focusAreas,
  onCreateFocusArea,
  surveyName,
  questionSource,
  ...rest
}: Props) => {
  const { question, _id, favorability, compare, category } =
    highlightedQuestion;

  function onCreateActionPlan() {
    onCreateFocusArea({
      title: question,
      category,
      source: questionSource,
      module: Module.SURVEY,
      focusAreaType: FocusArea.FocusAreaType.QUESTION,
      sourceUrl: {
        label: surveyName,
        url: window.location.href,
      },
      actionPlans: [],
      surveyId: rest.match.params.id,
      questionId: _id,
    });
  }
  const getScoreClassName = (score: number): string => {
    if (score < 40) {
      return "negative";
    }
    if (score < 60) {
      return "neutral";
    }

    return "positive";
  };

  const goToQuestions = () => {
    goTo(`questions/${_id}`, Breadcrumb.stack(tableName));
  };
  const scoreClassName = getScoreClassName(favorability);
  const comparison = extractComparison(compare, comparisonKey);
  return (
    <Container>
      {hasActionCreationRight && (
        <CreateActionPlanButtonContainer
          showOnHover={showOnHover}
          data-cy="icon-button"
        >
          <CreateActionPlanButton
            onClick={onCreateActionPlan}
            showOnHover={showOnHover}
          />
        </CreateActionPlanButtonContainer>
      )}
      <QuestionText data-cy={`questionText_${question}`}>
        {question}
      </QuestionText>
      <ScoreAndSeeDetailsContainer>
        {isNotNullNorUndefined(favorability) && (
          <Score
            data-cy={`questionScore_${question}`}
            data-jest="score"
            className={scoreClassName}
          >
            {favorability}%
          </Score>
        )}
      </ScoreAndSeeDetailsContainer>
      <CompareAndSeeDetailsContainer>
        {isComparisionVisible && isNotNullNorUndefined(comparison) && (
          <CompareContainer>
            <CompareValueContainer>
              <CompareValue compare={comparison} />
            </CompareValueContainer>
          </CompareContainer>
        )}
        <StyledLink onClick={goToQuestions}>
          <SeeDetails>
            <Trans>See details</Trans> <RightIcon type="right" />
          </SeeDetails>
        </StyledLink>
      </CompareAndSeeDetailsContainer>
    </Container>
  );
};

const ScoreAndSeeDetailsContainer = styled.div`
  margin-left: 8px;
`;

const Score = styled.span`
  font-size: 16px;
  font-weight: bold;

  &.positive {
    color: ${Palette.primary};
  }

  &.neutral {
    color: ${Palette.bluishGrey};
  }

  &.negative {
    color: ${Palette.darkPink};
  }
`;

const SeeDetails = styled.span`
  display: none;
  color: ${Palette.darkModerateBlue};
  font-size: 14px;
  max-width: 100%;
`;

const ActionPlanIconNotHovered = css`
  display: none;
  position: absolute;
  top: 50%;
  left: 0;
`;

const ActionPlanIconHovered = css`
  padding-right: 24px;
  display: inline-block;
`;

const CreateActionPlanButtonContainer = styled.div<{ showOnHover?: boolean }>`
  ${(props) =>
    props.showOnHover ? ActionPlanIconNotHovered : ActionPlanIconHovered};
`;

const CompareContainer = styled.div`
  display: inline-block;
  color: ${Palette.bluishGrey};
  font-size: 12px;
`;

const CompareValueContainer = styled.div`
  span {
    font-size: 14px;
    line-height: 1;
  }
`;

const QuestionText = styled.div`
  font-size: 12px;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Lato;
  letter-spacing: 0;
  white-space: nowrap;
`;

const CompareAndSeeDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 100px;
  margin-left: 8px;
  align-items: flex-end;
`;

const Container = styled.div`
  position: relative;
  margin: 16px 16px 0 16px;
  padding: 15px;
  min-height: 55px;
  padding-left: 24px;
  font-size: 16px;
  line-height: 16px;
  border: 1px solid ${Palette.lightPeriwinkle};
  border-radius: 3px;
  background: ${Palette.white};
  box-shadow: 0 4px 8px 0 rgba(198, 214, 223, 0.15);
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    cursor: pointer;
    border-color: ${Palette.darkModerateBlue};
    max-width: 95% !important;
  }

  &:hover ${CreateActionPlanButtonContainer} {
    display: block;
  }

  &:hover ${CompareValueContainer} {
    display: none;
  }

  &:hover ${QuestionText} {
    white-space: normal !important;
  }

  &:hover ${SeeDetails} {
    display: block;
    flex-wrap: nowrap;
  }

  &:hover {
    cursor: pointer;
    border-color: ${Palette.darkModerateBlue};
  }

  &:hover ${CreateActionPlanButtonContainer} {
    display: block;
  }

  &:hover ${CompareAndSeeDetailsContainer} {
    width: 20%;
  }
`;

const RightIcon = styled(Icon)`
  margin-left: 4px;
  font-size: 12px;
`;

const StyledLink = styled.span`
  cursor: pointer;
`;
export default withRouter(Question);
