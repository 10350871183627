import React from "react";
import styled from "styled-components";
import { Action } from "hyphen-lib/dist/domain/Action";
import { Paragraph } from "@src/components/core/Typography";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { Trans } from "react-i18next";
import { sanitizeSegmentLabel } from "hyphen-lib/dist/domain/common/Dimensions";
import { trans } from "@src/utils/i18next";

export interface ActionSourceDescriptionProps extends Action.Source {
  title?: string;
}

export function ActionSourceDescription(props: ActionSourceDescriptionProps) {
  const { type, title, label, context } = props;

  function getTitle() {
    if (isNotNullNorUndefined(context)
      && isNotNullNorUndefined(context.surveyName)
      && (
        type === Action.SourceType.INDIVIDUAL_RESULT ||
        type === Action.SourceType.SURVEY_SEGMENT ||
        type === Action.SourceType.SURVEY_CATEGORY ||
        type === Action.SourceType.SURVEY_QUESTION ||
        type === Action.SourceType.SURVEY_COMMENT
      )
    ) {
      return context.surveyName;
    }

    if ( isNotNullNorUndefined(context)
      && isNotNullNorUndefined(context.pulsePollName)
      && ( type === Action.SourceType.PULSE_POLL_SEGMENT
        || type === Action.SourceType.PULSE_POLL_COMMENT)
    ) {
      return context.pulsePollName;
    }

    return title;
  }

  return (
    <Container data-cy="category">
      <Paragraph>{trans(sanitizeSegmentLabel(label))}</Paragraph>
      <SourceType weight="light">
        <Trans>{type}</Trans>
        {isNotNullNorUndefined(getTitle()) ? ` in ${getTitle()}` : ""}
      </SourceType>
    </Container>
  );
}

const Container = styled.div`
  flex: 1;
  min-width: 50%;
`;

const SourceType = styled(Paragraph)`
  margin-top: 10px !important;
`;
