import React from "react";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps } from "react-router";
import { SelectValue } from "antd/lib/select";

import { State } from "@store/types";
import { replaceLocation, goTo } from "@src/utils/locations";
import { generateCompareWithOptions, getAvailableComparisons } from "@src/utils/Comparisons";
import { getCompany, getDimensions, getRightsMatcher } from "@screens/Insights/store/selectors";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { not } from "hyphen-lib/dist/lang/Booleans";
import {
  areEquals,
  getOr,
  isEmptyObject,
  isNotEmptyObject,
  isNotNullNorUndefined,
  isNullOrUndefined,
  keys,
  mapOr,
  objectOmit
} from "hyphen-lib/dist/lang/Objects";
import { QuestionInfoResource } from "hyphen-lib/dist/domain/resource/QuestionInfoResource";
import { QuestionType } from "hyphen-lib/dist/domain/common/QuestionType";
import { Map as ImmutableMap } from "immutable";
import { getParameters } from "@screens/Insights/parameters/store/selectors";
import { applyExistingParametersIfNeeded } from "@src/utils/parameters";
import { getAnonymityThreshold } from "@screens/Insights/Settings/store/selectors";
import { fetchSurveyInfoIfNeeded } from "@store/network/resource/SurveyInfoResources";
import { Loading } from "@screens/Insights/Survey/components/Loading";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { SurveyInfoResource } from "hyphen-lib/dist/domain/resource/SurveyInfoResource";
import { getResourceById } from "@store/network/selectors";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { fetchQuestionInfoIfNeeded } from "@store/network/resource/QuestionInfoResources";
// eslint-disable-next-line max-len
import { MultipleChoiceQuestionReportResource } from "hyphen-lib/dist/domain/resource/report/MultipleChoiceQuestionReportResource";
// eslint-disable-next-line max-len
import { fetchMultipleChoiceQuestionReportIfNeeded } from "@store/network/resource/MultipleChoiceQuestionReportResources";
import { checkForSurveyReportRight } from "@src/utils/rights";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { ParticipationReportResource } from "hyphen-lib/dist/domain/resource/survey/report/ParticipationReportResource";
import { fetchSurveyParticipationIfNeeded } from "@src/store/network/resource/ParticipationReportResources";
import { FetchError } from "@src/screens/Insights/errors/FetchError";
import Navbar from "../../components/Navbar/index";
import QuestionHighlightReport from "../../components/QuestionHighlightReport";
import {
  fetchTopicsOverviewIfNeeded
} from "@src/store/network/resource/TopicsReportResources";
import { SurveyTopicCommentResource } from "hyphen-lib/dist/domain/resource/survey/report/SurveyTopicCommentResource";
import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
interface MatchParams {
  id: string;
  questionId: string;
  selectedDimension: string;
}

interface QuestionHighlightReportContainerProps {
  readonly surveyId: string;
  readonly surveyInfoElement: Store.Element<SurveyInfoResource>;
  readonly questionInfoElement: Store.Element<QuestionInfoResource>;
  readonly company: CompanyResource;
  readonly parameters: ImmutableMap<string, any>;
  readonly anonymityThreshold: number;
  readonly question: Store.Element<MultipleChoiceQuestionReportResource>;
  readonly hasCommentsAccess: boolean;
  readonly hasTopicsAccess: boolean;
  readonly participation: Store.Element<ParticipationReportResource>;
  readonly topicsOverview: Store.Element<SurveyTopicCommentResource>;
  readonly dimensionsLabels: Dimensions;
}

export interface CategoryHighlightReportContainerActionProps {
  readonly onFetchSurveyInfo: (surveyId: string) => void;
  readonly onFetchQuestionInfo: (surveyId: string, questionId: string) => void;
  readonly onFetchMultipleChoiceQuestionReport:
  (surveyId: string, questionId: string, queryString: Dictionary<any>) => any;
  readonly onFetchParticipation: (surveyId: string, queryString: Dictionary<any>) => void;
  readonly onFetchTopicsOverview: (
    surveyId: string,
    queryString: Dictionary<any>
  ) => void;
}

export type OwnProps = RouteComponentProps<MatchParams>;

type Props =
  OwnProps &
  QuestionHighlightReportContainerProps &
  CategoryHighlightReportContainerActionProps;

export class QuestionHighlightReportContainer extends React.Component<Props> {

  // noinspection JSMethodCanBeStatic
  extractParametersFromQuery(props: Props) {
    return this.extractRelevantParametersForQuery(
      parseQueryString(props.location.search)
    );
  }

  // noinspection JSMethodCanBeStatic
  extractRelevantParametersForQuery(parameters: any) {
    const relevantParameters = { ...parameters };
    delete relevantParameters.viewOptions;
    delete relevantParameters.segmentBy;
    return relevantParameters;
  }

  componentDidMount(): void {
    // we might need to apply persisted parameters
    const {
      parameters,
      location: { search },
      surveyInfoElement,
      surveyId,
    } = this.props;

    const existing = parseQueryString(search);
    const mergedParameters =
      applyExistingParametersIfNeeded(
        parameters.toJS(),
        existing,
        "filter.dimensions",
        "viewOptions.compareWith"
      );
    this.fetchSurveyParticipation();
    if (isNotNullNorUndefined(mergedParameters) && isNotEmptyObject(mergedParameters)) {
      replaceLocation(mergedParameters);

      if (isEmptyObject(this.extractRelevantParametersForQuery(mergedParameters))) {
        // do the fetch now, otherwise we will not do it later, as componentDidUpdate will not see
        // any relevant changes, and not do any fetch
        this.fetchSurveyQuestionInfoAndHighlight();
        this.fetchSurveyTopicsOverview();
      }
    } else {
      // fetch the question only if we will stay on this page,
      // otherwise it will be fetched anyway in componentDidUpdate
      this.fetchSurveyQuestionInfoAndHighlight();
      this.fetchSurveyTopicsOverview();
    }

    if (Store.Element.isNotFound(surveyInfoElement)) {
      this.props.onFetchSurveyInfo(surveyId);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const oldParams = this.extractParametersFromQuery(prevProps);
    const newParams = this.extractParametersFromQuery(this.props);
    const { surveyInfoElement, questionInfoElement, question, participation} = this.props;

    const isInError = mapOr(surveyInfoElement, sElem => Store.Element.isInError(surveyInfoElement), false) ||
                        mapOr(questionInfoElement, qElem => Store.Element.isInError(questionInfoElement), false) ||
                          mapOr(question, q => Store.Element.isInError(q), false) ||
                            mapOr(participation, p => Store.Element.isInError(p), false);
    if (
      (this.props.surveyId !== prevProps.surveyId ||
      this.props.match.params.questionId !== prevProps.match.params.questionId ||
      not(areEquals(oldParams, newParams))) &&
      not(isInError)
    ) {
      this.fetchSurveyQuestionInfoAndHighlight();
      this.fetchSurveyParticipation();
      this.fetchSurveyTopicsOverview();
    }
  }

  fetchSurveyParticipation = () => {
    if (isNotEmptyObject(this.props.match)) {
      const surveyId = this.props.match.params.id;
      const queryParams = this.extractParametersFromQuery(this.props);
      this.props.onFetchParticipation(surveyId, queryParams);
    }
  };

  fetchSurveyTopicsOverview = () => {
    const questionId = this.props.match.params.questionId;
    const filter = getOr(this.extractParametersFromQuery(this.props).filter, {});
    const { onFetchTopicsOverview, surveyId } = this.props;
    filter.questionId = [questionId];
    if(isNotNullNorUndefined(filter.freeText)) {
      delete filter.freeText;
    }
    onFetchTopicsOverview(
      surveyId, { filter } 
    );
  };

  fetchSurveyQuestionInfoAndHighlight = () => {
    if (isNotEmptyObject(this.props.match)) {
      const questionId = this.props.match.params.questionId;
      const queryParams = this.extractParametersFromQuery(this.props);

      this.props.onFetchMultipleChoiceQuestionReport(this.props.surveyId, questionId, queryParams);
      this.props.onFetchQuestionInfo(this.props.surveyId, questionId);
    }
  };

  updateSegmentByInPath = (selectedDimension: SelectValue): void => {
    const { location } = this.props;
    const parameters = parseQueryString(location.search);
    parameters.segmentBy = selectedDimension;

    replaceLocation(parameters); // apply the new query string
  };

  onCommentsCountClick = () => {
    const { surveyId } = this.props;
    goTo(`/surveys/view/${surveyId}/reports/comments`);
  };

  render() {
    const {
      surveyInfoElement,
      questionInfoElement,
      question,
      location,
      company,
      anonymityThreshold,
      hasCommentsAccess,
      hasTopicsAccess,
      participation: participationBySegments,
      topicsOverview,
      dimensionsLabels,
      surveyId
    } = this.props;
    if (Store.Element.isInError(surveyInfoElement)) {
      return <FetchError {...surveyInfoElement} resourceType={SurveyInfoResource.TYPE}/>;
    }
    if (Store.Element.isInError(questionInfoElement)) {
      return <FetchError {...questionInfoElement} resourceType={QuestionInfoResource.TYPE}/>;
    }
    if (Store.Element.isInError(question)) {
      return <FetchError {...question} resourceType={MultipleChoiceQuestionReportResource.TYPE}/>;
    }
    if (Store.Element.isInError(participationBySegments)) {
      return <FetchError {...participationBySegments}  resourceType={ParticipationReportResource.TYPE}/>;
    }
    if (Store.Element.isNotLoaded(surveyInfoElement) || Store.Element.isNotFound(participationBySegments)) {
      return <Loading/>;
    }

    const { value: { participation: participationSurvey, isAnonymous } } = surveyInfoElement;

    let { segmentBy: selectedDimension } = parseQueryString(location.search);
    if (isNullOrUndefined(selectedDimension)) {
      selectedDimension = Store.Element.mapIfLoadedOr(
        question,
        q => !q.filteredForAnonymity ? keys(q.segmentsSummary.dimensions).first("") : Optional.empty(),
        ""
      );
    }

    const compareWithOptions = generateCompareWithOptions(
      Store.Element.mapIfLoadedOr(
        question,
        q => !q.filteredForAnonymity ? getAvailableComparisons : {},
        {}
      ),
      company
    );

    const questionText = Store.Element.mapIfLoadedOr(
      questionInfoElement,
      info => info.question,
      ""
    );
    const questionType = Store.Element.mapIfLoadedOr(
      questionInfoElement,
      info => info.type,
      QuestionType.MULTIPLE_CHOICE
    );
    const allowComments = Store.Element.mapIfLoadedOr(
      questionInfoElement,
      (info) => info.allowComment,
      false
    );

    return (
      <div data-jest="QuestionHighlightReport">
        <Navbar
          style={{ marginBottom: 32, height: "auto" }}
          withActions={false}
          title={questionText}
        />
        <QuestionHighlightReport
          questionText={questionText}
          questionType={questionType}
          allowComments={allowComments}
          participation={participationSurvey}
          participationBySegments={participationBySegments}
          question={Store.Element.toLoadable(question)}
          enabledFilters={["dimension"]}
          enabledCustomFilters={["addDimension"]}
          selectedDimension={selectedDimension}
          updateSegmentByInPath={this.updateSegmentByInPath}
          enabledViewOptions={["compareWith"]}
          compareWithOptions={compareWithOptions}
          anonymityThreshold={anonymityThreshold}
          hasCommentsAccess={hasCommentsAccess}
          hasTopicsAccess={hasTopicsAccess}
          isAnonymous={isAnonymous}
          onCommentsCountClick={this.onCommentsCountClick}
          topicsOverview={Store.Element.toLoadable(topicsOverview)}
          dimensionsLabels={dimensionsLabels}
          surveyId={surveyId}
        />
      </div>
    );
  }
}

const mapStateToProps: MapStateToProps<QuestionHighlightReportContainerProps, OwnProps, State> = (
  state: State,
  ownProps: OwnProps
): QuestionHighlightReportContainerProps => {
  const { match: { params: { id: surveyId, questionId } } } = ownProps;
  const filter = parseQueryString(ownProps.location.search).filter;
  const skipFreeText = objectOmit(getOr(filter, {}), "freeText");
  const extendedFilter = { questionId: [questionId], ...skipFreeText };
  return ({
    surveyId,
    surveyInfoElement: getResourceById(state, SurveyInfoResource.TYPE, surveyId),
    questionInfoElement: getResourceById(state, QuestionInfoResource.TYPE, questionId),
    question: getResourceById(
      state,
      MultipleChoiceQuestionReportResource.TYPE,
      MultipleChoiceQuestionReportResource.generateId(
        { surveyId, questionId },
        filter
      )
    ),
    topicsOverview: getResourceById(
      state,
      SurveyTopicCommentResource.TYPE,
      SurveyTopicCommentResource.generateKey(
        surveyId,
        extendedFilter
      )
    ),
    company: getCompany(state)!,
    dimensionsLabels: getOr(getDimensions(state), {}),
    parameters: getParameters(state),
    anonymityThreshold: getAnonymityThreshold(state),
    hasCommentsAccess: checkForSurveyReportRight(getRightsMatcher(state), "comments"),
    hasTopicsAccess: checkForSurveyReportRight(
      getRightsMatcher(state),
      "topics"
    ),
    participation: getResourceById(
      state,
      ParticipationReportResource.TYPE,
      ParticipationReportResource.generateId(
        surveyId,
        filter
      )
    ),
  });
};

const mapDispatchToProps: CategoryHighlightReportContainerActionProps = {
  onFetchSurveyInfo: fetchSurveyInfoIfNeeded,
  onFetchQuestionInfo: fetchQuestionInfoIfNeeded,
  onFetchMultipleChoiceQuestionReport: fetchMultipleChoiceQuestionReportIfNeeded,
  onFetchParticipation: fetchSurveyParticipationIfNeeded,
  onFetchTopicsOverview: fetchTopicsOverviewIfNeeded
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionHighlightReportContainer);
