import React from "react";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { TransWrapper } from "@src/components/core/withTrans";

interface FileUploadProps {
  disabled: boolean;
  validateFile: (e: any) => void;
  accept: string;
  uploadButtonText: string;
  readonly translate?: string;
}

function FileUpload(props: FileUploadProps, ref: React.Ref<HTMLInputElement>) {
  const { disabled, validateFile, accept, uploadButtonText, ...rest} = props;
  return (
    <UploadFileComponent>
      <UploadButtonLabel
        disabled={Boolean(disabled)}
        htmlFor="batarangFileToUpload"
        className="mb-1"
      >
        <TransWrapper {...rest}>{uploadButtonText}</TransWrapper>
      </UploadButtonLabel>
      <HiddenInput
        ref={ref}
        type="file"
        name="batarangFileToUpload"
        id="batarangFileToUpload"
        accept={accept}
        disabled={Boolean(disabled)}
        onChange={validateFile}
      />
    </UploadFileComponent>

  );
}

export default React.forwardRef(FileUpload);

const UploadButtonLabel = styled.label<{ disabled: boolean }>`
  display: inline-block;
  justify-content: flex-end;
  width: 128px;
  height: 40px;
  border-radius: 2px;
  background-color: ${Palette.lightGreyBlue};
  color: white;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  padding-top: 7px;
  font-weight: bold;
  ${props => props.disabled ? "cursor: not-allowed;" : ""};
  ${props => props.disabled ? "pointer-events: all !important;" : ""};
`;

const HiddenInput = styled.input`
  display: none !important;
  visility: hidden !important;
  ${props => props.disabled ? "cursor: not-allowed;" : ""};
  ${props => props.disabled ? "pointer-events: all !important;" : ""};
`;

const UploadFileComponent = styled.div`
  box-sizing: border-box;
`;
