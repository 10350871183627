import React from "react";
import { RecordOf } from "immutable";
import styled from "styled-components";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter, matchPath } from "react-router";
import moment from "moment";
import linkifyHtml from "linkifyjs/html";

import { State } from "@store/types";

import { Rate, Icon, Spin } from "antd";
import Palette from "@src/config/theme/palette";

import { ScreenContainer } from "@screens/Insights/components/ScreenContainer";
import ContainerCard from "@components/core/ContainerCard";
import { Paragraph } from "@components/core/Typography";
import Button from "@components/core/Button";
import { Breadcrumb, goTo } from "@src/utils/locations";

import { mapOr, isNotNullNorUndefined, isNotEmptyObject, isEmptyObject, getOr } from "hyphen-lib/dist/lang/Objects";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";
import { Action } from "hyphen-lib/dist/domain/Action";
import { isActionOverdue } from "hyphen-lib/dist/business/action/Actions";
import { ActionSourceDescription } from "../components/ActionSourceDescription";
import { NewActionResource } from "../store/reducers";
import { getStatusLabel } from "../utils/helpers";
import { getCurrentUser } from "../../store/selectors";
import { actionPlansActionCreators } from "../store/actions";
import { getActionPlansState } from "../store/selectors";
import OKRDetailCard from "../components/OKRDetailCard";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { ActionResource } from "hyphen-lib/dist/domain/resource/action/ActionResource";
import { getAppSettings } from "../../Settings/store/selectors";
import { AppSettingsResource } from "hyphen-lib/dist/domain/resource/AppSettingsResource";
import { Company } from "hyphen-lib/dist/domain/Company";
import { getOkrSynonyms } from "hyphen-lib/dist/business/appSetting/AppSettings";
import { Trans } from "react-i18next";

interface ViewActionPlanStateProps {
  readonly actionName: string;
}

interface ReduxStateProps {
  readonly currentUser: CurrentUserResource;
  readonly actionId: string;
  readonly actionPlan: RecordOf<NewActionResource>;
  readonly appSettings: AppSettingsResource;
}
interface ReduxActionProps {
  readonly fetchActionPlanIfNeeded: (actionId: string) => void;
  readonly cleanActionPlan: () => void;
}
interface OwnProps { }

export interface ViewActionPlanProps extends OwnProps, ReduxStateProps, ReduxActionProps { }

export class ViewActionPlanContainer extends React.Component<ViewActionPlanProps, ViewActionPlanStateProps> {
  componentDidMount() {
    const { actionId, fetchActionPlanIfNeeded } = this.props;
    fetchActionPlanIfNeeded(actionId);
  }

  componentWillUnmount() {
    this.props.cleanActionPlan();
  }

  generateDescriptionWithLink(description: string) {
    const link = linkifyHtml(description, {
      defaultProtocol: "https",
    });
    return { __html: link };
  }

  goToEditAction = () => {
    const { actionPlan } = this.props;
    const status = actionPlan.get("status");
    if (status === Action.Status.TODO || status === Action.Status.IN_PROGRESS) {
      goTo(`/actioncenter/actions/edit/${actionPlan.get("_id")}`, Breadcrumb.stack("Actions"));
    }
  };

  renderCreateOKRButton = (okrSynonym: string) => {
    return (<OKRButtonWrapper>
             <div><Trans>Not Created</Trans></div>
             <CreateOKRButton 
                data-jest="createOkrButton"
                data-cy="createOkrButton"
                color="blue"
                onClick={this.goToEditAction}>
                  <Trans>Create</Trans> {okrSynonym}
             </CreateOKRButton>
           </OKRButtonWrapper>);
   };

   renderOkrDetailCard = (okrSynonym: Company.GoalSetting) => {
    const actionPlan = this.props.actionPlan.toJS();
     return <>
       <OKRCardSubHeading>
            <Trans>In order to edit or remove the objective click below to view it
              in Betterworks</Trans> {okrSynonym.okrs}.
       </OKRCardSubHeading>
       <OKRDetailCard
         data-jest="viewActionOkrCard"
         okrSynonyms={okrSynonym}
         okr={actionPlan.okr as ActionResource.OKRCreateSuccessType}
       />
     </>;
   };

  render() {
    const actionPlan = this.props.actionPlan.toJS();
    if (isEmptyObject(actionPlan)) {
      return <Spin />;
    }
    const betterworksOkrEnabled = this.props.currentUser.company?.modules?.betterworksOkr;
    const showBetterworksOkr = betterworksOkrEnabled &&
     not([Action.Status.COMPLETED, Action.Status.DISMISSED].includes(actionPlan.status));
    let description = actionPlan.description;
    if (isNotNullNorUndefined(description)) {
      description = this.generateDescriptionWithLink(description);
    }

    let resource = actionPlan.resource;
    if (isNotNullNorUndefined(resource)) {
      resource = this.generateDescriptionWithLink(resource);
    }

    const {appSettings} = this.props;
    const okrSynonymContainer = getOkrSynonyms(appSettings);
    
    const actions = [];
    if (actionPlan.status === Action.Status.TODO || actionPlan.status === Action.Status.IN_PROGRESS) {
      actions.push(
        <Button key="viewAction_edit" data-cy="viewAction_edit" color="grey" 
        onClick={this.goToEditAction} translate="yes">
          Edit Action Plan
        </Button>
      );
    }

    let dueDate;
    let overdueStatus = false;
    if (isNotNullNorUndefined(actionPlan.dueDate)) {
      dueDate = moment(actionPlan.dueDate).format("L");
      const action = {
        ...actionPlan,
        dueDate: new Date(actionPlan.dueDate),
      };

      overdueStatus = isActionOverdue(action, new Date());
    }

    return (
      <ScreenContainer
        title={actionPlan.action}
        actions={[actions]}
      >
        <ContainerCard>
          <Container>
            <div>
              <StyledSection>
                <Paragraph>
                  <strong><Trans>Action Name</Trans></strong>
                </Paragraph>
                <Paragraph data-cy="viewAction_name" translate="yes">
                  {actionPlan.action}
                </Paragraph>
              </StyledSection>

              <StyledSection>
                <Paragraph>
                  <strong><Trans>Description</Trans></strong>
                </Paragraph>
                <p data-cy="viewAction_description"
                  dangerouslySetInnerHTML={description}
                />
              </StyledSection>

              <StyledSection>
                <Paragraph>
                  <strong><Trans>Resource URL</Trans></strong>
                </Paragraph>
                <p data-cy="viewAction_resource"
                  dangerouslySetInnerHTML={resource}
                />
              </StyledSection>

              <StyledSection>
                <Paragraph>
                  <strong><Trans>Assignee</Trans></strong>
                </Paragraph>
                <Paragraph data-cy="viewAction_assignee" translate="yes">
                  {actionPlan.assignee}
                </Paragraph>
              </StyledSection>

              <StyledSection>
                <Paragraph>
                  <strong><Trans>Due Date</Trans></strong>
                </Paragraph>
                { overdueStatus ?
                  <WarningText>
                    <StyledIcon type="warning" twoToneColor={Palette.darkPink} />
                    <Trans>due on</Trans> {dueDate}
                  </WarningText> :
                  <Paragraph data-cy="viewAction_dueDate" translate="yes">
                    {dueDate}
                  </Paragraph>
                }

              </StyledSection>

              <StyledSection>
                <Paragraph>
                  <strong><Trans>Status</Trans>:</strong>
                </Paragraph>
                <Paragraph data-cy="viewAction_status" translate="yes">
                  {getStatusLabel(actionPlan.status)}
                </Paragraph>
              </StyledSection>

              <StyledSection>
                <Paragraph translate="yes">
                  <strong><Trans>Created by</Trans></strong>
                </Paragraph>
                <Paragraph data-cy="viewAction_createdBy" translate="yes">
                  {actionPlan.createdBy}
                </Paragraph>
              </StyledSection>
            </div>
            <div>
              <Details>
                <Paragraph weight="light" translate="yes">
                  Origin
                </Paragraph>
                <ActionSourceDescription
                  data-cy="viewAction_source"
                  {...actionPlan.source}
                />
              </Details>
              {actionPlan.comment &&
                <StyledSection>
                  <Paragraph>
                    <strong><Trans>Comment</Trans></strong>
                  </Paragraph>
                  <Paragraph data-cy="viewAction_comment" translate="yes">
                    {actionPlan.comment}
                  </Paragraph>
                </StyledSection>
              }
              {actionPlan.rating &&
                <StyledSection>
                  <Paragraph>
                    <strong><Trans>Rating</Trans></strong>
                  </Paragraph>
                  <Rate data-cy="viewAction_rating"
                    disabled value={actionPlan.rating || 0}
                  />
                </StyledSection>
              }
              {
                showBetterworksOkr ? 
                 <StyledSection>
                 <Paragraph>
                 {showBetterworksOkr && <strong><Trans>Betterworks</Trans> {okrSynonymContainer.okrs}</strong>}
                 </Paragraph>
                 <Paragraph translate="yes">
                      {(isNotEmptyObject(actionPlan.okr) &&
                        isEmptyObject(actionPlan.okr.error)) ?
                        this.renderOkrDetailCard(okrSynonymContainer) :
                        this.renderCreateOKRButton(okrSynonymContainer.okrs)
                      }
                 </Paragraph>
               </StyledSection> : <React.Fragment/>
              }
            </div>
          </Container>
        </ContainerCard>
      </ScreenContainer>
    );
  }
}

const Container = styled.div`
    display: flex;
    justify-content: space-between;

    > div:first-child {
        margin-right: 160px;
    }

    > div {
        flex-grow: 1;
    }
`;

const Details = styled.div`
    margin-top: 27px;
    display: flex: 1;
    > p {
        margin-right: 60px !important;
    }
`;

const StyledSection = styled.div`
    margin-top: 27px;
    max-width: 500px;
    width: 70%;
`;

const WarningText = styled.div`
  margin-top: 6px;
  color: ${Palette.darkPink};
  width: 200px;
`;

const StyledIcon = styled(Icon)`
  color: ${Palette.darkPink};
  vertical-align: center;
  display: inline-block;
  margin-right: 3px;
  font-size: 15px;
`;

const OKRButtonWrapper = styled.div`
    display: flex;
    margin-top: 20px;
`;

const CreateOKRButton = styled(Button)`
    width: 114px;
    height: 30px !important;
    margin-left: 20px; 
    margin-bottom: 6px;
`;

const OKRCardSubHeading = styled.div`
  color: ${Palette.veryDarkBlueGrey};
  font-size: 12px;
  margin-top: 10px;
  width: max-content;
  margin-bottom: 10px;
`;

function mapStateToProps(state: State, ownProps: OwnProps & RouteComponentProps): ReduxStateProps {
  const realMatch = matchPath<{ actionId: string }>(ownProps.history.location.pathname, {
    path: "/actioncenter/actions/view/:actionId",
    exact: false,
  });
  const currentUser = mapOr(
    getCurrentUser(state),
    (user: CurrentUserResource) =>
      user,
    {} as CurrentUserResource
  );

  const {
    actionPlan,
  } = getActionPlansState(state);

  const actionId = mapOr(
    realMatch,
    (map) => map.params.actionId,
    ""
  );

  const appSettings = getOr(getAppSettings(state).get(0), {} as AppSettingsResource);

  return {
    currentUser,
    actionId,
    actionPlan,
    appSettings
  };
}

const mapDispatchToProps = {
  fetchActionPlanIfNeeded: actionPlansActionCreators.fetchActionPlanIfNeeded,
  cleanActionPlan: actionPlansActionCreators.cleanActionPlan,
};

export const ViewActionPlan = withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewActionPlanContainer));
