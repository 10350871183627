import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
import { PulsePollInfoResource } from "hyphen-lib/dist/domain/resource/PulsePollInfoResource";
import { getOr, isNullOrUndefined, mapOr } from "hyphen-lib/dist/lang/Objects";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { Participation } from "hyphen-lib/dist/domain/common/Participation";
import { PulsePollInstanceInfoResource } from "hyphen-lib/dist/domain/resource/PulsePollInstanceInfoResource";
import { Post } from "hyphen-lib/dist/domain/Post";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";

import { State } from "@store/types";
import { getResourceById } from "@src/store/network/selectors";
import { PropMapping } from "@src/utils/parameters";

import { fetchParticipationCountIfNeeded } from "@src/store/network/resource/PulsePollParticipationResource";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { getDimensions } from "../../store/selectors";
import { ReportHeader } from "../components/ReportHeader";
import { parametersActionCreators } from "../../parameters/store/actions";
import { FetchError } from "../../errors/FetchError";

type PulsePollReportHeaderProps = PulsePollReportHeaderReduxStateProps & OwnProps & PulsePollReportHeaderReduxActions;

interface MatchParams {
  readonly templateId: string;
  readonly instanceId: string;
  readonly selectedDimension: string;
}

interface PulsePollReportHeaderReduxStateProps {
  readonly dimensions: Dimensions;
  readonly iterations: Post.PulsePoll.InstanceInfo[];
  readonly pollId: string;
  readonly isFetchingReportsInfo: boolean;
  readonly participationData?: Participation;
  readonly pulsePollInfoElement: Store.Element<PulsePollInfoResource>;
  readonly pulsePollInstanceInfoElement: Store.Element<PulsePollInstanceInfoResource>;
}

interface PulsePollReportHeaderReduxActions {
  readonly onModifyParameters: (parameters: Dictionary<any>, mappings?: PropMapping[]) => void;
  readonly onfetchParticipantCountIfNeeded: (templateId: string, pollId: string) => void;
}

interface OwnProps extends RouteComponentProps<MatchParams> {
  readonly enabledFilters: string[];
  readonly enabledCustomFilters?: string[];
  readonly exportOption?: string;
  readonly explanation?: string;
  readonly viewOptionsComponent?: React.ReactNode;
  readonly storeMappings?: PropMapping[];
}

class PulsePollReportHeaderContainer extends React.Component<PulsePollReportHeaderProps> {

  componentDidMount() {
    this.fetchParticipantCount();
  }

  componentDidUpdate(prevProps: PulsePollReportHeaderProps) {
    const { pulsePollInfoElement, pulsePollInstanceInfoElement } = this.props;
    const isInError = mapOr(pulsePollInfoElement, iElem => Store.Element.isInError(iElem), false) ||
                        mapOr(pulsePollInstanceInfoElement, iiElem => Store.Element.isInError(iiElem), false);
    if (prevProps.pollId !== this.props.pollId && not(isInError)) {
      this.fetchParticipantCount();
    }
  }

  fetchParticipantCount = () => {
    const { match, pollId, onfetchParticipantCountIfNeeded } = this.props;
    const {
      params: { templateId },
    } = match;

    onfetchParticipantCountIfNeeded(templateId, pollId);
  };

  render() {
    const {
      dimensions,
      pollId,
      participationData,
      iterations,
      isFetchingReportsInfo,
      onModifyParameters,
      enabledCustomFilters,
      pulsePollInfoElement,
      pulsePollInstanceInfoElement,
      ...rest
    } = this.props;

    if (Store.Element.isInError(pulsePollInfoElement)) {
      return <FetchError {...pulsePollInfoElement} resourceType={PulsePollInfoResource.TYPE} />;
    }

    if (Store.Element.isInError(pulsePollInstanceInfoElement)) {
      return <FetchError {...pulsePollInstanceInfoElement} resourceType={PulsePollInstanceInfoResource.TYPE} />;
    }

    return (
      <ReportHeader
        enabledCustomFilters={enabledCustomFilters}
        dimensions={dimensions}
        selectedPollId={pollId}
        iterations={iterations}
        isLoading={isFetchingReportsInfo}
        participationData={participationData}
        onModifyParameters={onModifyParameters}
        {...rest}
      />
    );
  }
}

function mapStateToProps(
  state: State,
  ownProps: RouteComponentProps<MatchParams>
): PulsePollReportHeaderReduxStateProps {
  const {
    match: {
      params: { instanceId, templateId },
    },
  } = ownProps;

  let pollId = instanceId;

  const pulsePollInfoElement = getResourceById(
    state,
    PulsePollInfoResource.TYPE,
    templateId
  );
  const iterations = Store.Element.mapIfLoadedOr(
    pulsePollInfoElement,
    pi => pi.instances,
    []
  );
  if (isNullOrUndefined(pollId) && iterations.length > 0) {
    pollId = iterations[0].id;
  }

  const pulsePollInstanceInfoElement = getResourceById(
    state,
    PulsePollInstanceInfoResource.TYPE,
    PulsePollInstanceInfoResource.generateId(pollId)
  );

  const participationData = Store.Element.mapIfLoadedOr(
    pulsePollInstanceInfoElement,
    participation => participation.participationData,
    { total: 0, completed: 0 }
  );

  return {
    dimensions: getOr(getDimensions(state), {}),
    isFetchingReportsInfo: Store.Element.isLoading(pulsePollInfoElement),
    pulsePollInfoElement,
    pulsePollInstanceInfoElement,
    pollId,
    participationData,
    iterations,
  };
}

const mapDispatchToProps = {
  onModifyParameters: parametersActionCreators.modifyParameters,
  onfetchParticipantCountIfNeeded: fetchParticipationCountIfNeeded,
};

export const PulsePollReportHeader = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PulsePollReportHeaderContainer));
