import React from "react";
import { QuestionTypeLabel } from "hyphen-lib/dist/business/question/Questions";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Trans } from "react-i18next";
import { Checkbox, Icon, Tooltip } from "antd";
import { List, Record as IRecord, fromJS } from "immutable";
import { isNotNullNorUndefined, getOr } from "hyphen-lib/dist/lang/Objects";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { Paragraph } from "@src/components/core/Typography";
import AreYouSureModal from "@src/components/core/AreYouSureModal";
import { mapOr } from "hyphen-lib/dist/lang/Objects";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import Select from "@src/components/core/Select";
import { QuestionConfig } from "hyphen-lib/dist/domain/common/QuestionType";
import { VerbatimAnalysisCheckbox } from "../VerbatimAnalysisCheckbox";
import { AddCustomAnswer } from "./AddCustomAnswer";
import { MultiLanguageFooterStrip } from "@src/screens/Insights/Surveys/components/MultiLanguageFooterStrip";
import { LocaleCodes } from "hyphen-lib/dist/util/locale";
import Input from "@src/components/core/Input";
import Button from "@src/components/core/Button";
import { SurveyQuestionResource } from "hyphen-lib/dist/domain/resource/SurveyQuestionResource";
import {
  getParsed,
  getTranslatedSegmentFromStringifiedTranslation,
  getTranslationStatsForMultipleChoice,
  LabelWithFlag,
} from "@src/utils/translation";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import { not } from "hyphen-lib/dist/lang/Booleans";

const { Option } = Select;

interface QuestionTypeState {
  type: QuestionTypeLabel;
  allChoices: IRecord<
    {
      [key in QuestionTypeLabel]: List<string>;
    }
  >;
  isConfirmationModalOpen?: boolean;
  confirmationEvent?: CheckboxChangeEvent | undefined;
  isEditingQuestionType: boolean;
  choiceTranslations: { [key: number]: { [key: string]: string } };
  applyTranslationsGlobally: boolean;
  languageSelectedOnSelectBox: string;
}

interface QuestionTypeProps {
  title: string;
  disableSelection?: boolean;
  onTypeChange?: (label: QuestionTypeLabel, choices: string[]) => void;
  onOpenTypeChange?: (e: CheckboxChangeEvent) => void;
  data: {
    isSentimentAnalysisQuestion: boolean;
    questionConfig: QuestionConfig;
    type: QuestionTypeState["type"];
    choices?: string[];
  };
  onUpdateChoices?: (choices: string[]) => void;
  error?: Optional<string>;
  isSentimentAnalysisDisabled: boolean;
  isMultiSelectQuestionEnabled: boolean;
  onEditQuestionTypeTranslation: () => void;
  stopEditMode: () => void;
  readonly configuredSurveyLanguages?: string[];
  readonly addUpdateQuestion?: (
    questionId: string,
    question: SurveyQuestionResource,
    queryParams?: Record<string, string>,
    keepModalOpen?: boolean
  ) => void;
  readonly onlyUpdateQuestion?: (
    questionId: string,
    question: SurveyQuestionResource,
    queryParams?: Record<string, string>,
    keepModalOpen?: boolean
  ) => void;
  readonly questionResource?: SurveyQuestionResource;
  readonly isMultiLanguageSurveyEnabled: boolean;
  readonly isUpdatingQuestion: boolean;
  readonly allowUpdatingTranslation?: boolean;
  readonly isPulsePoll?: boolean;
}

export class QuestionType extends React.Component<
  QuestionTypeProps,
  QuestionTypeState
> {
  constructor(props: QuestionTypeProps) {
    super(props);
    const { data } = props;
    const type = isNotNullNorUndefined(data)
      ? data.type
      : QuestionTypeLabel.Likert;
    const allChoices: { [key in QuestionTypeLabel]: List<string> } = {
      [QuestionTypeLabel.Likert]: List(
        QuestionConfig.getCompanyLikertOptions(data.questionConfig.likert)
      ),
      [QuestionTypeLabel.YesNo]: List(
        QuestionConfig.getCompanyYesOrNoOptions(data.questionConfig.yesOrNo)
      ),
      [QuestionTypeLabel.ENPS]: List(
        QuestionConfig.getCompanyENPSOptions(data.questionConfig.enps)
      ),
      [QuestionTypeLabel.Open]: List([]),
      [QuestionTypeLabel.Custom]: List([]),
      [QuestionTypeLabel.MultiSelect]: List([]),
      [QuestionTypeLabel.Unknown]: List([]),
    };
    const stateFactory = IRecord(allChoices);
    const allChoicesRecord = stateFactory();

    const chTransMap: { [key: number]: { [key: string]: string } } = {};
    const qR = this.props
      .questionResource as SurveyQuestionResource.MultipleChoice;
    if (isNotNullNorUndefined(qR) && isNotNullNorUndefined(qR.choices_t)) {
      qR.choices_t.forEach((choice, index) => {
        chTransMap[index] = getParsed(choice);
      });
    }

    let defaultLangSelected = "";
    if (isNotNullNorUndefined(this.props.configuredSurveyLanguages)) {
      defaultLangSelected = getOr(this.props.configuredSurveyLanguages[0], "");
    }
    this.state = {
      type,
      allChoices:
        isNotNullNorUndefined(data) && isNotNullNorUndefined(data.choices)
          ? allChoicesRecord.set(type, List(data.choices))
          : allChoicesRecord,
      isConfirmationModalOpen: false,
      isEditingQuestionType: false,
      choiceTranslations: chTransMap,
      applyTranslationsGlobally: false,
      languageSelectedOnSelectBox: defaultLangSelected,
    };
  }

  componentDidUpdate(prevProps: QuestionTypeProps) {
    if (
      prevProps.isUpdatingQuestion === true &&
      this.props.isUpdatingQuestion === false
    ) {
      this.props.stopEditMode();
      this.setState({
        isEditingQuestionType: false,
      });
    }
  }

  getChoices = (): List<string> => {
    const { allChoices, type } = this.state;
    return allChoices.get(type);
  };

  onChange = (value: any) => {
    const { onTypeChange } = this.props;
    const { allChoices } = this.state;

    this.setState({ type: value });
    const choices = isNotNullNorUndefined(allChoices.get(value))
      ? allChoices.get(value).toArray()
      : [];
    if (isNotNullNorUndefined(onTypeChange)) {
      onTypeChange(value, choices);
    }
  };

  addCustomAnswer = (answer: string) => {
    const choices = this.getChoices();
    const updatedChoices = choices.push(answer);
    this.updateChoices(updatedChoices);
  };

  onAnswerRemove = (index: number) => {

    const choices = this.getChoices();
    const updatedChoices = choices.remove(index);
    this.updateChoices(updatedChoices);
    const _choiceTranslations = this.state.choiceTranslations;
    if (this.state.choiceTranslations[index]) {
      if (index === Object.keys(_choiceTranslations).length - 1) {
        /**
         * Since this was the last key to be removed, this required a simple resetting
         * after the deletion
         */
        delete _choiceTranslations[index];
        this.setState({
          choiceTranslations: _choiceTranslations,
        });
      } else {
        delete _choiceTranslations[index];
        const __choiceTranslations: {
          [key: number]: {
            [key: string]: string;
          };
        } = {};

        Object.keys(_choiceTranslations).forEach((v, i) => {
          /**
           * Since the keys are the numbers, javascript always follows the ascending order
           * when iterating over the object
           */
          __choiceTranslations[i] = _choiceTranslations[parseInt(v, 10)];
        });

        this.setState({
          choiceTranslations: __choiceTranslations,
        });
      }
      /**
       * Since the choices were also updated, send a save request 
       * to save the translations mapped correctly to the updated 
       * choices
       */
      this.onClickSaveBtnForLikertTypeTranslationView(updatedChoices.toJS());
    }
  };

  onChoiceChange = (index: number, value: string) => {
    const choices = this.getChoices();
    const updatedChoices = choices.set(index, value);
    this.updateChoices(updatedChoices);
  };

  updateChoices = (updatedChoices: List<string>) => {
    const { allChoices, type } = this.state;
    const { onUpdateChoices } = this.props;
    this.setState({ allChoices: allChoices.set(type, updatedChoices) });

    if (isNotNullNorUndefined(onUpdateChoices)) {
      onUpdateChoices(updatedChoices.toJS());
    }
  };

  getConfirmation = (e: CheckboxChangeEvent) => {
    this.setState({ isConfirmationModalOpen: true, confirmationEvent: e });
    return;
  };

  onConfirmation = () => {
    const { onOpenTypeChange = () => undefined } = this.props;
    if (isNotNullNorUndefined(this.state.confirmationEvent)) {
      onOpenTypeChange(this.state.confirmationEvent);
    }
    this.setState({ isConfirmationModalOpen: false });
  };

  onConfirmationModalClose = () => {
    this.setState({ isConfirmationModalOpen: false });
  };

  showEditTranslationForQuestionType() {
    return (
      this.state.isEditingQuestionType &&
      getOr(this.props.configuredSurveyLanguages, []).length > 0
    );
  }

  onChangeLikertType =
    (choiceIndex: number, languageCode: LocaleCodes) => (event: any) => {
      const translatedValue: string = event.target.value;
      if (this.state.choiceTranslations[choiceIndex]) {
        const existingTranslationMap =
          this.state.choiceTranslations[choiceIndex];
        existingTranslationMap[languageCode] = translatedValue;
        this.setState({
          choiceTranslations: {
            ...this.state.choiceTranslations,
            [choiceIndex]: existingTranslationMap,
          },
        });
      } else {
        const existingChoiceTranslations = this.state.choiceTranslations;
        existingChoiceTranslations[choiceIndex] = {
          [languageCode]: translatedValue,
        };
        this.setState({
          choiceTranslations: {
            ...this.state.choiceTranslations,
            ...existingChoiceTranslations,
          },
        });
      }
    };

  renderLikertTypeTranslationView(choices: List<string>) {
    const confSurveyLangs = getOr(this.props.configuredSurveyLanguages, []);
    return (
      <>
        {this.showEditTranslationForQuestionType() &&
          confSurveyLangs.length > 0 && (
            <div className="d-flex flex-row" style={{ marginTop: "20px" }}>
              <div
                className="d-flex align-items-center"
                style={{ width: "30%" }}
              >
                English
              </div>
              <div style={{ width: "70%", marginLeft: "10px" }}>
                <Select
                  size={"large"}
                  defaultValue={this.state.languageSelectedOnSelectBox}
                  onChange={(value) =>
                    this.setState({
                      languageSelectedOnSelectBox: value.toString(),
                    })
                  }
                >
                  {this.props.configuredSurveyLanguages?.map((code, index) => {
                    return (
                      <Option value={code} key={index}>
                        {LabelWithFlag(code as LocaleCodes)}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          )}

        {this.showEditTranslationForQuestionType() &&
          choices.map((choice, key) => {
            let catTranslation = "";
            if (isNotNullNorUndefined(this.props.questionResource)) {
              const qR = this.props
                .questionResource as SurveyQuestionResource.MultipleChoice;
              if (isNotNullNorUndefined(qR.choices_t)) {
                const pChoices = getParsed(qR.choices_t[key]);
                catTranslation = getTranslatedSegmentFromStringifiedTranslation(
                  this.state.languageSelectedOnSelectBox as LocaleCodes,
                  pChoices
                );
              }
            }
            return (
              <div
                className="d-flex flex-row"
                key={key + this.state.languageSelectedOnSelectBox}
              >
                <div
                  className="d-flex align-items-center"
                  style={{
                    width: "30%",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      color: "#7A8891",
                      lineHeight: "16px",
                    }}
                  >
                    <Tooltip title={choice} placement={"topLeft"}>
                      {choice}
                    </Tooltip>
                  </span>
                </div>
                <div style={{ width: "70%", marginLeft: "10px" }}>
                  <Input
                    key={key}
                    onChange={this.onChangeLikertType(
                      key,
                      this.state.languageSelectedOnSelectBox as LocaleCodes
                    )}
                    defaultValue={catTranslation}
                    size={"large"}
                    disabled={!this.props.allowUpdatingTranslation}
                    data-cy="answers_translation"
                  />
                </div>
              </div>
            );
          })}

        {this.showEditTranslationForQuestionType() &&
          this.state.type !== QuestionTypeLabel.MultiSelect &&
          this.state.type !== QuestionTypeLabel.Custom && (
            <Checkbox
              style={{ marginTop: "20px", marginBottom: "16px" }}
              checked={this.state.applyTranslationsGlobally}
              onClick={() => {
                this.setState({
                  applyTranslationsGlobally:
                    !this.state.applyTranslationsGlobally,
                });
              }}
              disabled={!this.props.allowUpdatingTranslation}
            >
              <span>
                <Trans>Use these translations to all</Trans> {this.state.type} 
                <Trans>questions in this survey</Trans>
              </span>
              <span style={{ paddingLeft: "0.5em" }}>
                <Tooltip
                  /* eslint-disable max-len */
                  title={<>{this.state.type} - <Trans>If there are any other questions already added, their scale translations will be overwritten.</Trans></>}
                >
                  <Icon type="info-circle" />
                </Tooltip>
              </span>
            </Checkbox>
          )}
      </>
    );
  }

  renderSaveBtnForLikertTypeTranslationView() {
    return (
      this.showEditTranslationForQuestionType() && (
        <div className="d-flex justify-content-end">
          <Button
            color="blue"
            style={{ height: "30px" }}
            onClick={() => {
              this.onClickSaveBtnForLikertTypeTranslationView();
            }}
            data-cy="question_type_save_translation"
            translate="yes"
          >
            Save translations
          </Button>
        </div>
      )
    );
  }

  onClickSaveBtnForLikertTypeTranslationView(updatedChoices?: string[]) {
    if (
      isNotNullNorUndefined(this.props.addUpdateQuestion) &&
      isNotNullNorUndefined(this.props.questionResource)
    ) {
      const sortedKeys = Object.keys(this.state.choiceTranslations)
        .map((item) => parseInt(item, 10))
        .sort(function (a, b) {
          return a - b;
        });

      const finalChoiceList: string[] = [];

      sortedKeys.forEach((key) => {
        finalChoiceList.push(
          JSON.stringify(this.state.choiceTranslations[key])
        );
      });

      let fnToCall = this.props.addUpdateQuestion;
      if (
        isStringAndNotEmpty(this.props.questionResource._id) &&
        isNotNullNorUndefined(this.props.onlyUpdateQuestion)
      ) {
        fnToCall = this.props.onlyUpdateQuestion;
      }

      fnToCall(
        this.props.questionResource._id,
        {
          ...this.props.questionResource,
          choices_t: finalChoiceList,
          choices: isNotNullNorUndefined(updatedChoices)
            ? updatedChoices
            : this.getChoices()
        } as SurveyQuestionResource.MultipleChoice,
        {
          applyForAllChoices: this.state.applyTranslationsGlobally.toString(),
        },
        true
      );
    }
  }
  getChoicesForMultipleRenderType() {
    const { type, allChoices } = this.state;
    const choices = isNotNullNorUndefined(allChoices.get(type))
      ? allChoices.get(type)
      : List([]);

    return choices;
  }

  renderType = () => {
    const {
      onOpenTypeChange = () => undefined,
      data,
      isSentimentAnalysisDisabled,
    } = this.props;
    const { type } = this.state;
    const choices = this.getChoicesForMultipleRenderType();
    switch (type) {
      case QuestionTypeLabel.Likert:
      case QuestionTypeLabel.ENPS:
      case QuestionTypeLabel.YesNo:
        return (
          <div
            className={
              this.showEditTranslationForQuestionType() ? "questionTypeT" : ""
            }
          >
            <Options
              className={
                this.showEditTranslationForQuestionType() ? "d-none" : ""
              }
            >
              {
                // @ts-ignore
                choices.map((choice, key) => (
                  <Choices key={key} label={choice} />
                ))
              }
            </Options>
            {this.renderLikertTypeTranslationView(choices)}
            {this.renderSaveBtnForLikertTypeTranslationView()}
          </div>
        );
      case QuestionTypeLabel.Open:
        return (
          <VerbatimAnalysisCheckbox
            onChange={onOpenTypeChange}
            {...(isNotNullNorUndefined(data)
              ? { checked: data.isSentimentAnalysisQuestion }
              : {})}
            disabled={isSentimentAnalysisDisabled}
          />
        );
      case QuestionTypeLabel.MultiSelect:
        return (
          <div
            className={
              this.showEditTranslationForQuestionType() ? "questionTypeT" : ""
            }
          >
            <div
              className={
                this.showEditTranslationForQuestionType() ? "d-none" : ""
              }
            >
              <AddCustomAnswer
                minOptions={data.questionConfig.multiSelect.options.MIN}
                maxOptions={data.questionConfig.multiSelect.options.MAX}
                customAnswers={choices}
                addCustomAnswer={this.addCustomAnswer}
                onChoiceChange={this.onChoiceChange}
                onAnswerRemove={this.onAnswerRemove}
              />
            </div>
            {this.renderLikertTypeTranslationView(choices)}
            {this.renderSaveBtnForLikertTypeTranslationView()}
          </div>
        );
      case QuestionTypeLabel.Custom:
        return (
          <div
            className={
              this.showEditTranslationForQuestionType() ? "questionTypeT" : ""
            }
          >
            <div
              className={
                this.showEditTranslationForQuestionType() ? "d-none" : ""
              }
            >
              <AddCustomAnswer
                minOptions={data.questionConfig.multipleChoice.options.MIN}
                maxOptions={data.questionConfig.multipleChoice.options.MAX}
                customAnswers={choices}
                addCustomAnswer={this.addCustomAnswer}
                onChoiceChange={this.onChoiceChange}
                onAnswerRemove={this.onAnswerRemove}
              />
            </div>
            {this.renderLikertTypeTranslationView(choices)}
            {this.renderSaveBtnForLikertTypeTranslationView()}
          </div>
        );
      default:
        return null;
    }
  };

  getChoicesForRenderAnswers() {
    const { data } = this.props;
    const choices = mapOr(data, (map) => getOr(map.choices, []), []);
    return choices;
  }

  renderAnswers = () => {
    const { data, isSentimentAnalysisDisabled } = this.props;
    const { type } = this.state;
    const choices = this.getChoicesForRenderAnswers();

    if (isNotNullNorUndefined(data)) {
      switch (type) {
        case QuestionTypeLabel.Open:
          return (
            <VerbatimAnalysisCheckbox
              checked={data.isSentimentAnalysisQuestion}
              disabled={isSentimentAnalysisDisabled}
            />
          );
        default:
          return (
            <div
              className={
                this.showEditTranslationForQuestionType() ? "questionTypeT" : ""
              }
            >
              <div
                className={
                  this.showEditTranslationForQuestionType() ? "d-none" : ""
                }
              >
                {choices
                  .filter((element) => {
                    return element != null && element.trim() !== "";
                  })
                  .map((choice, index) => (
                    <Choices key={index} label={choice} />
                  ))}
              </div>

              {this.renderLikertTypeTranslationView(fromJS(choices))}
              {this.renderSaveBtnForLikertTypeTranslationView()}
            </div>
          );
      }
    } else {
      return null;
    }
  };

  getTranslationStatsForLikertTypeQuestions(disableSelection: boolean) {
    let translated = 0;
    let total = 0;
    let choices: List<string> = fromJS([]);
    if (disableSelection) {
      choices = fromJS(this.getChoicesForRenderAnswers());
    } else {
      choices = this.getChoicesForMultipleRenderType();
    }

    const qR = this.props
      .questionResource as SurveyQuestionResource.MultipleChoice;
    if (isNotNullNorUndefined(qR)) {
      const choiceTr = getOr(qR.choices_t, []);
      const { totalLanguages, totalTranslated } =
        getTranslationStatsForMultipleChoice(
          choices.size,
          choiceTr,
          getOr(this.props.configuredSurveyLanguages, [])
        );
      total = totalLanguages;
      translated = totalTranslated;
    }

    return { totalLanguagesMS: total, totalTranslatedMS: translated };
  }

  render() {
    const {
      title,
      disableSelection = false,
      data,
      error,
      isMultiSelectQuestionEnabled,
      isPulsePoll
    } = this.props;
    const { type, isConfirmationModalOpen } = this.state;

    const { totalLanguagesMS, totalTranslatedMS } =
      this.getTranslationStatsForLikertTypeQuestions(disableSelection);

    return (
      <QuestionTypeContainer>
        <Paragraph><Trans>{title}</Trans></Paragraph>
        {!disableSelection && (
          <Select
            data-cy="question_type"
            data-jest="question-type"
            onChange={this.onChange}
            value={type}
          >
            <Option
              value={QuestionTypeLabel.Likert}
              data-cy="question_type_likert"
            >
              {QuestionTypeLabel.Likert}
            </Option>
            <Option
              value={QuestionTypeLabel.Custom}
              data-cy="question_type_custom"
            >
              {QuestionTypeLabel.Custom}
            </Option>
            {isMultiSelectQuestionEnabled && (
              <Option
                value={QuestionTypeLabel.MultiSelect}
                data-cy="question_type_multiSelect"
              >
                {QuestionTypeLabel.MultiSelect}
              </Option>
            )}
            <Option value={QuestionTypeLabel.ENPS} data-cy="question_type_enps">
              {QuestionTypeLabel.ENPS}
            </Option>
            <Option
              value={QuestionTypeLabel.YesNo}
              data-cy="question_type_yesNo"
            >
              {QuestionTypeLabel.YesNo}
            </Option>
            {not(isPulsePoll) && (<Option value={QuestionTypeLabel.Open} data-cy="question_type_open">
              {QuestionTypeLabel.Open}
            </Option>)}
          </Select>
        )}

        {isNotNullNorUndefined(data) && (
          <Types>
            {disableSelection ? this.renderAnswers() : this.renderType()}
            {!isNotNullNorUndefined(error) &&
              this.props.isMultiLanguageSurveyEnabled &&
              !this.showEditTranslationForQuestionType() &&
              this.state.type !== QuestionTypeLabel.Open && (
                <MultiLanguageFooterStrip
                  languagesReady={totalTranslatedMS}
                  totalLanguages={totalLanguagesMS}
                  onEditTranslations={() => {
                    this.setState({
                      isEditingQuestionType: true,
                    });
                    this.props.onEditQuestionTypeTranslation();
                  }}
                />
              )}
          </Types>
        )}
        {isNotNullNorUndefined(error) && (
          <ErrorContainer
            data-cy="question-type-error"
            data-jest="question-type-error"
          >
            <Trans>{error}</Trans>
          </ErrorContainer>
        )}
        {isConfirmationModalOpen && (
          <AreYouSureModal
            visible={isConfirmationModalOpen}
            data-cy="areYouSureModal"
            title="Are you sure?"
            /* eslint-disable-next-line max-len */
            description="Updating the settings for this question can impact your reporting. Are you sure you want to continue?"
            onOk={this.onConfirmation}
            onCancel={this.onConfirmationModalClose}
            okLabel="Confirm"
            cancelLabel="Cancel"
          />
        )}
      </QuestionTypeContainer>
    );
  }
}

export function Choices({ label }: { label: string }) {
  return <ChoiceContainer>{label}</ChoiceContainer>;
}

const QuestionTypeContainer = styled.div`
  margin-top: 16px;
`;

const Options = styled.div`
  margin-top: 16px;
`;

const Types = styled.div`
  margin-top: 20px;
`;

const ChoiceContainer = styled.div`
  margin-top: 8px;
  padding: 8px 13px;
  background-color: ${Palette.athensGrey};
  border: solid 1px ${Palette.lightPeriwinkle};
  border-radius: 3px;
`;

const ErrorContainer = styled.div`
  margin-top: 8px;
  color: #f5222d;
`;
