import React from "react";
import styled from "styled-components";
import { Layout as AntLayout } from "antd";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { SurveyInfoResource } from "hyphen-lib/dist/domain/resource/SurveyInfoResource";
import { isNotNullNorUndefined, isNullOrUndefined } from "hyphen-lib/dist/lang/Objects";
import { isNullOrEmpty } from "hyphen-lib/dist/lang/Strings";
import { Trans } from "react-i18next";
import Palette from "@src/config/theme/palette";
import Tabs from "@components/core/Tabs";
import Spin from "@components/core/Spin";
import { actionCreators as surveyActionCreators } from "@screens/Insights/Survey/containers/store/actions";
import { State } from "@store/types";

import { goTo, replaceTo } from "@src/utils/locations";

import AreYouSureModal from "@src/components/core/AreYouSureModal";
import { hasAdminAccess } from "@src/utils/rights";
import { RightsMatcher } from "@hyphen-lib/business/auth/Auth";
import { getRightsMatcher } from "@screens/Insights/store/selectors";
import { fetchSurveyInfoIfNeeded } from "@store/network/resource/SurveyInfoResources";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { getResourceById } from "@store/network/selectors";
import { surveyListActionCreators } from "../SurveyList/store/actions";
import { getSurveyListStateProps } from "../SurveyList/store/selectors";
import { FetchError } from "../errors/FetchError";
import SurveyReminders from "./containers/SurveyReminders";
import SurveyDetails from "./containers/SurveyDetails";
import SurveyReports from "./containers/SurveyReports";
import Navbar from "./components/Navbar";

const { TabPane } = Tabs;

interface ReduxStateProps {
  readonly surveyInfoElement: Store.Element<SurveyInfoResource>;
  readonly selectedReportsMenu: string;
  readonly closeModalOpen: boolean;
  readonly isRequestingClose: boolean;
  readonly hasSurveyAdminRight: boolean;
  readonly rightsMatcher: RightsMatcher;
}
interface ReduxActionProps {
  readonly onFetchSurveyInfo: (surveyId: string) => void;
  readonly onSelectReportsMenu: (menu: string) => void;
  readonly toggleCloseModalOpen: (modalOpen: boolean, selectedSurveyInfoResource: SurveyInfoResource) => void;
  readonly closeSurvey: () => void;
}

interface MatchParams {
  id: string;
}

interface SurveyReportState {
  reportsPath: string;
}

export interface SurveyReportProps extends ReduxStateProps, ReduxActionProps, RouteComponentProps<MatchParams> {}

export class SurveyContainer extends React.Component<SurveyReportProps, SurveyReportState> {

  constructor(props: SurveyReportProps) {
    super(props);
    const { location } = props;
    const { pathname } = location;
    const selectedTab = pathname.split("/")[4];

    let reportsPath = "overview";
    if (selectedTab === "reports" && isNotNullNorUndefined(pathname.split("/")[5])) {
      reportsPath = pathname.split("/")[5];
    }
    this.state = {reportsPath};
  }

  componentDidMount() {
    const {
      surveyInfoElement,
      match: { params: { id: surveyId } },
      onFetchSurveyInfo,
    } = this.props;

    if (Store.Element.isNotFound(surveyInfoElement)) {
      onFetchSurveyInfo(surveyId);
    }

    const { location } = this.props;
    const { pathname } = location;
    const selectedTab = pathname.split("/")[4];

    if (selectedTab === "reports") {
      if (isNullOrUndefined(pathname.split("/")[5])) {
        replaceTo(`${pathname}/overview`);
      } else if (isNullOrEmpty(pathname.split("/")[5])) {
        replaceTo(`${pathname}overview`);
      }
    }
  }

  componentDidUpdate(prevProps: SurveyReportProps) {
    const {
      match: { params: { id: surveyId } },
      onFetchSurveyInfo,
    } = this.props;

    const {
      match: { params: { id: prevSurveyId } },
    } = prevProps;

    if (surveyId !== prevSurveyId) {
      onFetchSurveyInfo(surveyId);
    }

    const { location } = this.props;
    const { pathname } = location;
    const selectedTab = pathname.split("/")[4];
    if (selectedTab === "reports") {
      const prevPath = prevProps.location.pathname.split("/")[5];
      const currentPath = this.props.location.pathname.split("/")[5] || "overview";
      if (prevPath !== currentPath) {
        this.setState({ reportsPath: currentPath });
      }
    }
  }

  onChange = (activeKey: string) => {
    const surveyId = this.props.match.params.id;
    if (activeKey === "reports") {
      const { reportsPath } = this.state;
      goTo(`/surveys/view/${surveyId}/${activeKey}/${reportsPath}`);
    } else {
      goTo(`/surveys/view/${surveyId}/${activeKey}`);
    }
  };

  closeCloseModal = () => {
    const {
      surveyInfoElement,
      toggleCloseModalOpen,
    } = this.props;

    if (Store.Element.isLoaded(surveyInfoElement)) {
      toggleCloseModalOpen(false, surveyInfoElement.value);
    }
  };

  toggleModalOpen = () => {
    const {
      surveyInfoElement,
      toggleCloseModalOpen,
    } = this.props;

    if (Store.Element.isLoaded(surveyInfoElement)) {
      toggleCloseModalOpen(true, surveyInfoElement.value);
    }
  };

  onSelectReportsMenu = (surveyId: string, menu: string) => {
    const { onSelectReportsMenu, location: { pathname: currentPath } } = this.props;

    const newPath = `/surveys/view/${surveyId}/reports/${menu}`;
    if (newPath !== currentPath) {
      goTo(newPath);
      onSelectReportsMenu(menu);
    }
  };

  render() {
    const {
      location,
      surveyInfoElement,
      selectedReportsMenu,
      closeSurvey,
      closeModalOpen,
      isRequestingClose,
      hasSurveyAdminRight,
      rightsMatcher,
    } = this.props;

    const { pathname } = location;
    const pathnameArray = pathname.split("/");
    const selectedTab = pathnameArray[4];
    const subHeader =  isNotNullNorUndefined(pathnameArray[5]) ? pathnameArray[5] : "";

    if (Store.Element.isInError(surveyInfoElement)) {
      return <FetchError {...surveyInfoElement} resourceType={SurveyInfoResource.TYPE}/>;
    }

    if (Store.Element.isNotLoaded(surveyInfoElement)) {
      return (
        <Layout>
          <Container>
            <Spin/>
          </Container>
        </Layout>
      );
    }

    const surveyInfo = surveyInfoElement.value;

    return (
      <Layout>
        <>
          <Header>
            <Navbar
              subHeader={subHeader}
              title={surveyInfo.name}
              surveyId={surveyInfo._id}
              status={surveyInfo.status}
              toggleModal={this.toggleModalOpen}
              withActions={hasSurveyAdminRight}
            />
          </Header>
          {closeModalOpen &&
          <AreYouSureModal
            visible={closeModalOpen}
            title="Are you sure?"
            description="This survey will be closed."
            okLabel={isRequestingClose ? "Closing" : "Close"}
            cancelLabel="Cancel"
            onOk={closeSurvey}
            onCancel={this.closeCloseModal}
            buttonsDisabled={isRequestingClose}
          />
          }
          <StyledTabs defaultActiveKey={selectedTab} onChange={this.onChange}>
            <TabPane tab={<Trans>Reports</Trans>} key={`reports/${selectedReportsMenu}`}>
              <SurveyReports
                surveyId={surveyInfo._id}
                topicAnalysEnabled = {surveyInfo.topic_analysis_enabled}
                surveySuggestionsEnabled={surveyInfo.surveySuggestionsEnabled}
                surveyName={surveyInfo.name}
                participation={surveyInfo.participation}
                isAnonymousSurvey={surveyInfo.isAnonymous}
                hasRatingQuestions={surveyInfo.hasRatingQuestions}
                hasNPSQuestions={surveyInfo.hasNPSQuestions}
                surveyRole={surveyInfo.role}
                onSelectReportsMenu={this.onSelectReportsMenu}
                rightsMatcher={rightsMatcher}
                postAndSurveySentiment={surveyInfo.sentimentv2Enabled}
                surveyLanguages={surveyInfo.surveyLanguages}
              />
            </TabPane>
            {
              hasSurveyAdminRight && (
                <TabPane tab={<Trans>Details</Trans>} key="details"><SurveyDetails/></TabPane>
              )
            }
            <TabPane tab={<Trans>Reminders</Trans>} key="reminders"><SurveyReminders/></TabPane>
          </StyledTabs>
        </>
      </Layout>
    );
  }
}

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Layout = styled(AntLayout)`
   background: ${Palette.paleGrey} !important;
   flex: 1;
`;

const StyledTabs = styled(Tabs)`
  flex: 1;
`;

const Header = styled(AntLayout.Header)`
  background: transparent !important;
  padding: 0 !important;
  margin-bottom: 24px;
`;

function mapStateToProps(state: State, ownProps: RouteComponentProps<MatchParams>): ReduxStateProps {
  const { match: { params: { id: surveyId } } } = ownProps;

  let selectedReportsMenu = state.insights_participation.get("menu");
  if (isNullOrUndefined(selectedReportsMenu)) {
    selectedReportsMenu = "overview";
  }

  const {
    closeModalOpen,
  } = getSurveyListStateProps(state);

  const surveyInfoElement = getResourceById(state, SurveyInfoResource.TYPE, surveyId);
  const hasSurveyAdminRight =
    Store.Element.isLoaded(surveyInfoElement) &&
    hasAdminAccess(surveyInfoElement.value.role);

  return {
    surveyInfoElement,
    selectedReportsMenu,
    closeModalOpen,
    isRequestingClose: state.get("insights_surveyList").get("isRequestingClose"),
    hasSurveyAdminRight,
    rightsMatcher: getRightsMatcher(state),
  };
}

const mapDispatchToProps = {
  onFetchSurveyInfo: fetchSurveyInfoIfNeeded,
  onSelectReportsMenu: surveyActionCreators.selectReportsMenu,
  toggleCloseModalOpen: surveyListActionCreators.toggleCloseModalOpen,
  closeSurvey: surveyListActionCreators.closeIndividualSurvey,
};

const Survey = connect(mapStateToProps, mapDispatchToProps)(withRouter(SurveyContainer));
export default Survey;
