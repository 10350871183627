import { default as networkActionCreators } from "@store/network/actions";
import { createRequest } from "@src/utils/networks";
import { appendQueryString, generateQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { TrendResource } from "hyphen-lib/dist/domain/resource/survey/report/TrendResource";

export function fetchSurveyTrendReportIfNeeded(surveyId: string, queryString: Dictionary<any>) {
  return networkActionCreators.fetchElementIfNeeded({
    id: TrendResource.generateId(surveyId, queryString.filter),
    type: TrendResource.TrendTypeEnum.SURVEY_TREND,
    request: createRequest(
      appendQueryString(
        `/surveys/${surveyId}/reports/linkedSurveyTrend`,
        generateQueryString(queryString)
      )
    ),
  });
}
