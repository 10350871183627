import { put, select, takeLatest } from "redux-saga/effects";
import { freeze, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { CompanyRoleResource } from "hyphen-lib/dist/domain/resource/CompanyRoleResource";
import { fromJS, List } from "immutable";

import { getCompany } from "@src/screens/Insights/store/selectors";
import notificationActionCreators, { ShowNotificationAction } from "@store/notifications/actions";
import { Notification } from "@store/notifications/types";

import {
  actionCreators as networkActionCreators,
  CleanResourceAction,
  NetworkEventSuccessAction
} from "@src/store/network/actions";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";
import { AddOrRemoveRoleAction, userRolesActionCreators, UserRolesActionTypes } from "./actions";
import { getRolesStateProps } from "./selectors";
import { CompanyRoleResourceMap } from "./reducers";
import * as NotificationFactory from "@src/store/notifications/notification-factory";

function getNotification(
  action: "add" | "update" | "remove" | "update_error"
): Notification {
  const notification: Notification = NotificationFactory.success(
    "Role updated successfully",
    "",
    4.5
  );
  switch (action) {
    case "add":
      return NotificationFactory.success(
        "Role added successfully",
        "",
        4.5
      );
    case "remove":
      return NotificationFactory.success(
        "Role deleted successfully",
        "",
        4.5
      );
    case "update_error":
      return NotificationFactory.error(
        "Something went wrong, please try again.",
        "",
        4.5
      );
    default:
      return notification;
  }
}

function* getCompanyRoles() {
  const company = yield select(getCompany);
  yield put(
    userRolesActionCreators.fetchCompanyRoles(company.name)
  );
}

function* updateCompanySelectedRole() {
  let updatedRoleIndex = 0;
  const company = yield select(getCompany);
  const { selectedRole, roles } = yield select(getRolesStateProps);

  roles.forEach((role: CompanyRoleResourceMap, index: number) => {
    if (role.get("_id") === selectedRole.get("_id")) {
      updatedRoleIndex = index;
    }
  });

  const updateRoles = roles.set(updatedRoleIndex, selectedRole);

  yield put(
    userRolesActionCreators.updateCompanyRolesRequest(company.name, updateRoles.toJS(), {
      action: "update",
    })
  );
}

function* addRemoveRoles({ payload }: AddOrRemoveRoleAction) {
  const company = yield select(getCompany);
  const { role, action } = payload;
  const { roles } = yield select(getRolesStateProps);
  let updatedRoles: List<CompanyRoleResourceMap> = List([]);
  if (action === "add") {
    updatedRoles = roles.push(fromJS({
      role,
      rights: [],
      userCount: 0,
      _id: role,
      _type: CompanyRoleResource.TYPE,
    }));
  } else if (action === "remove") {
    updatedRoles = roles.filter((item: CompanyRoleResourceMap) => item.get("role") !== role);
  }

  yield put(
    userRolesActionCreators.updateCompanyRolesRequest(company.name, updatedRoles.toJS(), {
      action,
    })
  );
}

function* onSaveSuccess({ meta }: NetworkEventSuccessAction) {
  let action: any = "update";
  if (isNotNullNorUndefined(meta)) {
    action = meta.action;
  }
  yield put<CleanResourceAction>(networkActionCreators.cleanResource(CurrentUserResource.TYPE));
  yield put<ShowNotificationAction>(
    notificationActionCreators.displayNotification(
      getNotification(action)
    )
  );
}

function* onSaveError() {
  yield put<ShowNotificationAction>(
    notificationActionCreators.displayNotification(
      getNotification("update_error")
    )
  );
}

export const userRolesSagas = freeze([
  takeLatest(
    UserRolesActionTypes.FETCH_COMPANY_ROLES,
    getCompanyRoles
  ),

  takeLatest(
    UserRolesActionTypes.SAVE_ROLE,
    updateCompanySelectedRole
  ),

  takeLatest(
    UserRolesActionTypes.ADD_OR_REMOVE_ROLES,
    addRemoveRoles
  ),

  takeLatest(
    UserRolesActionTypes.UPDATE_ROLES_SUCCESS,
    onSaveSuccess
  ),

  takeLatest(
    UserRolesActionTypes.UPDATE_ROLES_ERROR,
    onSaveError
  ),
]);
