import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { Layout as AntLayout } from "antd";

import { CommentsOverviewResource } from "@hyphen-lib/domain/resource/report/CommentsOverviewResource";
import { CommentsResultResource } from "@hyphen-lib/domain/resource/report/CommentsResultResource";
import CommentsTable from "@src/screens/Insights/components/Reports/ResultsTable";
import { NetworkEventSuccessAction } from "@src/store/network/actions";
import SendPrivateMessageModal from "@src/screens/Insights/components/Reports/SendPrivateMessage";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { Loading } from "@screens/Insights/Survey/components/Loading";
import SentimentOverview from "@src/screens/Insights/components/Reports/SentimentOverview";

import { RouteComponentProps } from "react-router";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import ContainerCard from "@src/components/core/ContainerCard";
import NoResult from "@src/components/core/NoResult";
import { PULSE_POLL_COMMENTS_REPORTS_FILTER_MAPPINGS } from "../../components/ReportHeader/index";
import { PulsePollReportHeader } from "../../containers/PulsePollReportHeaderContainer";
import CommentsSearchBar from "./components/CommentsSearchBar";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";

export interface PollCommentsReportStateProps {
  readonly commentText: string;
  readonly errorText: string;
  readonly isNetworkRequesting: boolean;
  readonly isPrivateMessageModalVisible: boolean;
  readonly privateMessageText: string;
  readonly voteId: string;
  readonly hasActionCreationRight: boolean;
  readonly focusAreas?: FocusAreaResource[];
}

export interface PollCommentsReportActionProps {
  readonly clearPrivateMessageModal: () => any;
  readonly sendPrivateMessage: (
    postId: string,
    voteId: string,
    initialMessage: string,
    origin: string,
    onSuccessRedirect?: (payload: NetworkEventSuccessAction["payload"]) => void
  ) => void;
  readonly togglePrivateMessageModal: (
    pollId: string,
    postId: string,
    voteId: string,
    commentText: string,
    origin: string
  ) => void;
  readonly updatePrivateMessageText: (privateMessageText: string) => any;
  fetchDataFromBackend: () => void;
  readonly onCreateFocusArea: (focusArea: Partial<FocusAreaResource>) => void;
}

interface CommentsProps {
  readonly instanceId: string;
  readonly commentsOverview: Loadable<CommentsOverviewResource>;
  readonly comments: Store.Page<CommentsResultResource>;
  readonly canSendPrivateMessage: boolean;
  readonly anonymityThreshold: number;
  readonly isNotFound: boolean;
  readonly origin: string;
  readonly pollName: string;
  readonly page: PageFilter;
}

export type Props = PollCommentsReportStateProps &
  CommentsProps &
  PollCommentsReportActionProps &
  RouteComponentProps;

interface CommentsState {
  isPrivateMessageModalOpen: boolean;
}

// const CommentsTableWithRouterProps = withRouter(CommentsTable);
class Comments extends React.Component<Props, CommentsState> {
  state = {
    isPrivateMessageModalOpen: false,
  };

  sendPrivateMessage = () => {
    this.props.sendPrivateMessage(
      this.props.instanceId,
      this.props.voteId,
      this.props.privateMessageText,
      this.props.origin
    );
  };

  extractParametersFromQuery(props: Props) {
    return this.extractRelevantParametersForQuery(
      parseQueryString(props.location.search)
    );
  }

  extractRelevantParametersForQuery(parameters: any) {
    const relevantParameters = { ...parameters };
    delete relevantParameters.viewOptions;
    delete relevantParameters.pollId;
    return relevantParameters;
  }

  render() {
    const {
      clearPrivateMessageModal,
      comments,
      commentText,
      commentsOverview,
      errorText,
      isNetworkRequesting,
      isPrivateMessageModalVisible,
      privateMessageText,
      togglePrivateMessageModal,
      updatePrivateMessageText,
      canSendPrivateMessage,
      hasActionCreationRight,
      anonymityThreshold,
      isNotFound,
      focusAreas,
      onCreateFocusArea,
      pollName,
      page
    } = this.props;

    return (
      <>
        <PulsePollReportHeader
          enabledFilters={["dimension", "sentiment"]}
          enabledCustomFilters={["addDimension"]}
          exportOption="comments"
          storeMappings={PULSE_POLL_COMMENTS_REPORTS_FILTER_MAPPINGS}
        />
        {/* FIXME: Refactor the if-else to a render function */}
        {isNotFound ? (
          <NotFoundContainer>
            <NoResult type="minimal" description="No iterations found" />
          </NotFoundContainer>
        ) : Loadable.isNotLoaded(commentsOverview) ? (
          <Loading />
        ) : (
          <>
            <SentimentOverview
              netSentimentScore={commentsOverview.value.netSentimentScore}
              filteredForAnonymity={commentsOverview.value.filteredForAnonymity}
              filteredForAnonymityReason={
                commentsOverview.value.filteredForAnonymityReason
              }
              sentiments={commentsOverview.value.sentiments}
              words={commentsOverview.value.words}
              numberOfComments={commentsOverview.value.numberOfComments}
              anonymityThreshold={anonymityThreshold}
            />
            <Layout>
              <CommentsSearchBar />
              <CommentsTable
                comments={Store.Page.toList(comments)}
                loading={Store.Page.isLoading(comments)}
                isPrivateMessageModalOpen={isPrivateMessageModalVisible}
                privateMessageText={privateMessageText}
                sendPrivateMessage={this.sendPrivateMessage}
                togglePrivateMessageModal={togglePrivateMessageModal}
                updatePrivateMessageText={updatePrivateMessageText}
                canSendPrivateMessage={canSendPrivateMessage}
                hasActionCreationRight={hasActionCreationRight}
                onCreateFocusArea={onCreateFocusArea}
                focusAreas={focusAreas}
                focusAreaLabel={pollName}
                page={page}
              />
            </Layout>
            <SendPrivateMessageModal
              clearPrivateMessageModal={clearPrivateMessageModal}
              comment={commentText}
              errorText={errorText}
              isSending={isNetworkRequesting}
              onCancel={togglePrivateMessageModal}
              privateMessageText={privateMessageText}
              updatePrivateMessageText={updatePrivateMessageText}
              visible={isPrivateMessageModalVisible}
              onOk={this.sendPrivateMessage}
            />
          </>
        )}
      </>
    );
  }
}

const NotFoundContainer = styled(ContainerCard)`
  margin-top: 24px;
`;

const Layout = styled(AntLayout)`
  background: white !important;
  margin-top: 24px;
  padding-bottom: 12px;
  font-family: "Lato";
`;

export default withRouter(Comments);
