import React from "react";
import { SurveyInfoResource } from "@hyphen-lib/domain/resource/SurveyInfoResource";
import styled from "styled-components";
import { Trans } from "react-i18next";
import Palette from "@src/config/theme/palette";
import { DynamicRowProps } from "@components/core/DynamicRowsTable/withDynamicRows";
import {isStringAndNotEmpty} from "hyphen-lib/dist/lang/Strings";
import { PulsePollInfoResource } from "hyphen-lib/dist/domain/resource/PulsePollInfoResource";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { capitalizeFirstLetter } from "@src/utils/helper";
import { PulsePollListOverviewResource } from "hyphen-lib/dist/domain/resource/PulsePollListOverviewResource";
import { CellContainer, CellInnerContainer } from "./Cells";
import Tooltip from "@src/components/core/Tooltip";

type ListingResourceType =
  SurveyInfoResource |
  PulsePollInfoResource |
  PulsePollListOverviewResource;

export function NameCell({ row , index}: DynamicRowProps<ListingResourceType>) {
  const returnName = (resource: ListingResourceType ) => {

    if (
      "name" in resource &&
      isNotNullNorUndefined(resource.name) &&
      isStringAndNotEmpty(resource.name)
    ) {
      return resource.name;
    } else if (
      "question" in resource &&
      isNotNullNorUndefined(resource.question) &&
      isStringAndNotEmpty(resource.question)
    ) {
      return resource.question;
    } else {
      let label = "Draft";

      if (resource._type === "PulsePollInfo" || resource._type === "PulsePollListOverview") {
        label += " Poll";
      } else if (resource._type === "SurveyInfo") {
        label += " Survey";
      }

      return label;
    }
  };

  const returnType = (resource: any) => {
    if (
      isNotNullNorUndefined(resource._type) &&
       isNotEmptyArray(resource.QuestionCategory)
    ) {
      return <TypeLabel> {resource.QuestionCategory[0]} • <Trans>{ 
      capitalizeFirstLetter(resource.frequency)}</Trans> </TypeLabel>;
    } else if (
      ( resource._type === "PulsePollListOverview" ||
        resource._type === "PulsePollInfo" ) &&
        isNotNullNorUndefined(resource.category)) {
      return <TypeLabel> {resource.category} • <Trans>{  
      capitalizeFirstLetter(resource.frequency)}</Trans> </TypeLabel>;
    }
    else {
      return <TypeLabel>{resource.type}</TypeLabel>;
    }
  };
  const linkedSurveyName = (resource: any) => {
    if(resource._type === SurveyInfoResource.TYPE && isStringAndNotEmpty(resource.linkedSurveyName)) {
      return <Tooltip  title={ <Trans i18nKey="linkedSurey" values={{linkedSurveyName: resource.linkedSurveyName}} 
        defaults={`Linked to survey: ${resource.linkedSurveyName}`} />
        }>
          <StyledTag>
           <Trans>Linked</Trans> 
          </StyledTag>
    </Tooltip>;
    }
      
  };
  return (
    <CellContainer align="flex-start" justifyContent="flex-start" data-cy={`${row._type}_name_${index}`}>
      <CellInnerContainer width="100%">
       <NameLabel>
          <Trans>{returnName(row)}</Trans>&emsp;                                
          { linkedSurveyName(row) } 
        </NameLabel>
        <Trans>{returnType(row)}</Trans>
      </CellInnerContainer>
    </CellContainer>
  );
}

export const NameLabel = styled.span`
  display: block;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: normal;
  word-break: break-all;
  color: ${Palette.veryDarkBlueGrey};
`;

export const TypeLabel = styled.span`
  margin-top: 6px;
  display: block;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: normal;
  color: ${Palette.bluishGrey};
`;

const StyledTag = styled.span<{color?: string}>`
  display: inline-block;
  padding: 0 20px;
  font-size: 12px;
  border-radius: 100px;
  color: ${Palette.darkModerateBlue};
  border: 1px solid ${Palette.darkModerateBlue};
  &:hover {
    background: ${Palette.darkModerateBlue} !important;
    color: ${Palette.white} !important;
  }
`;