import React from "react";
import { SelectProps, SelectValue } from "antd/lib/select";
import Select from "@src/components/core/Select";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { Paragraph } from "@src/components/core/Typography";
import Button from "@src/components/core/Button";

import { BaseFilterProps } from "../../FiltersContent";
import { Trans } from "react-i18next";
const { Option, OptGroup } = Select;

export interface GroupsAndChannelsFilterProps<T extends string[] | number[]>
  extends SelectProps<T>,
  BaseFilterProps<T> {
  readonly value?: T;
  readonly id?: string;
  readonly label: string;
  readonly groups: GroupsOptionsType[];
  readonly channels: GroupsOptionsType[];
  readonly groupsLabel?: string;
  readonly channelsLabel?: string;
  readonly onChange: (values: T) => void;
}

export interface GroupsOptionsType {
  readonly value: string | number;
  readonly label: string;
}

export function GroupsAndChannelsFilter<T extends string[] | number[]>({
  label,
  value,
  onChange,
  onClear,
  groups,
  channels,
  placeholder = "Select...",
  groupsLabel = "Groups",
  channelsLabel = "Channels",
  mode = "multiple",
}: GroupsAndChannelsFilterProps<T>) {
  function onValueChange(values: SelectValue) {
    onChange(values as T);
  }
  function preventMouseDown(e: any) {
    e.preventDefault();
  }
  function renderDropDown(menu: any) {
    return (
      <>
        {menu}
        <Actions>
          <div onMouseDown={preventMouseDown}>
            <Paragraph weight="bold" onClick={onClear} translate="yes">
              Clear
            </Paragraph>
          </div>
          <Button color="blue" translate="yes">
            Select
          </Button>
        </Actions>
      </>
    );
  }
  return (
    <Select
      label={label}
      placeholder={<Trans>{placeholder}</Trans>}
      onChange={onValueChange}
      mode={mode}
      value={value as string[] | number[]}
      dropdownRender={renderDropDown}
    >
      <OptGroup label={groupsLabel}>
        {groups.map((availableValue, idx) => (
          <Option
            key={availableValue.value.toString() + idx}
            value={availableValue.value}
          >
            {availableValue.label}
          </Option>
        ))}
      </OptGroup>
      <OptGroup label={channelsLabel}>
        {channels.map((availableValue, idx) => (
          <Option
            key={availableValue.value.toString() + idx}
            value={availableValue.value}
          >
            {availableValue.label}
          </Option>
        ))}
      </OptGroup>
    </Select>
  );
}

const Actions = styled.div`
  box-shadow: 0 -1px 0 0 ${Palette.lightPeriwinkle};
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  align-items: center;

  > div > p {
    margin-right: 16px;
    color: ${Palette.darkModerateBlue} !important;
    cursor: pointer;
  }

  button {
    height: 30px !important;
  }
`;
