import { Optional } from "@hyphen-lib/lang/Optionals";
import { DistributionResult } from "@hyphen-lib/domain/resource/report/common/DistributionResult";
import BarChart, { BarChartDimension } from "@components/core/BarChart";
import { GraphColor } from "../AnswersChart";

interface Props {
  readonly choiceLabels: Optional<string>[];
  readonly distribution: DistributionResult;
  readonly graphColors?: GraphColor[];
  readonly totalVotes: number;
}

const defaultGraphColors: GraphColor[] = [
  { backgroundColor: "#3559ae" },
  { backgroundColor: "#5372ba" },
  { backgroundColor: "#718ac6" },
  { backgroundColor: "#90a4d3" },
  { backgroundColor: "#aebddf" },
];

const AnswerDistributionBarChart = ({
  choiceLabels,
  distribution,
  graphColors = defaultGraphColors,
  totalVotes,
}: Props) => {
  const data: BarChartDimension = {};

  // fixme: should do a reduce, not a map :/
  choiceLabels.forEach((label, index) => {
    if (!label) {
      return;
    }
    const { percentage, total } = distribution[index];
    data[label] = {
      total: totalVotes,
      series: [{ ...graphColors[index], value: total, percentage, label }],
      filteredForAnonymity: false,
    };
  });

  return <BarChart data={data} anonymityThreshold={0} />;
};

export default AnswerDistributionBarChart;
