import styled from "styled-components";
import { Empty } from "antd";
import { Trans } from "react-i18next";

export interface NoResultProps {
  type: "search" | "filter" | "data" | "default" | "minimal";
  description?: string;
}

export default function NoResult(props: NoResultProps) {
  const { description } = props;
  function renderNoResultsOnSearch() {
    return (
      <>
        <Empty description={<Trans>{description}</Trans>} />
        <Description>
          <p />
          <h3><Trans>If you want to carry on searching, here are a few tips</Trans></h3>
          <StyledUl>
            <li><Trans>Check your spelling and try again</Trans></li>
            <li><Trans>Try a different search</Trans></li>
            <li><Trans>Try using a filter</Trans></li>
          </StyledUl>
        </Description>
      </>
    );
  }

  function renderNoResultsOnFilter() {
    return (
      <>
        <Empty description={<Trans>{description}</Trans>} />
        <Description>
          <p />
          <h3><Trans>Try using a different filter</Trans></h3>
        </Description>
      </>
    );
  }

  function renderNothingCreated() {
    return (
      <>
        <Empty description={<Trans>There's nothing here!</Trans>}/>
        <Description>
          <h3><Trans>{description}</Trans></h3>
          <p>
            <Trans>Why don't you try creating one? We'll be with 
            you every step of the way.</Trans>
          </p>
        </Description>
      </>
    );
  }

  function renderDefault() {
    return (
      <>
        <Empty description={<Trans>{description}</Trans>} />
        <Description>
          <h3><Trans>There's nothing here!</Trans></h3>
          <p><Trans>Please try again</Trans></p>
        </Description>
      </>
    );
  }

  function renderMinimalNoResult() {
    return (
      <>
        <Empty description="" />
        <Description>
          <h3><Trans>{description}</Trans></h3>
        </Description>
      </>
    );
  }

  const { type } = props;
  switch (type) {
    case "search":
      return renderNoResultsOnSearch();
    case "filter":
      return renderNoResultsOnFilter();
    case "data":
      return renderNothingCreated();
    case "minimal":
      return renderMinimalNoResult();
    default:
      return renderDefault();
  }
}

const StyledUl = styled.ul`
  display: inline-block;
  text-align: left;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
