import React from "react";
import styled from "styled-components";
import startCase from "lodash/startCase";

import { ParticipantCountResource } from "@hyphen-lib/domain/resource/participants/ParticipantCountResource";
import ImmutableMapType from "@src/utils/CreateImmutableType";
import { SurveyResourceRecord } from "@screens/Insights/Surveys/store/types";

import { SectionItem, SectionItemLabel, SectionItemValue } from "./SectionItem";
import { Trans } from "react-i18next";
import { sanitizeSegmentLabel } from "hyphen-lib/dist/domain/common/Dimensions";

interface Props {
  survey: SurveyResourceRecord;
  surveyParticipantsCount: ImmutableMapType<ParticipantCountResource>;
}

export function AudienceSection({ survey, surveyParticipantsCount }: Props) {
  if (!survey) {
    return null;
  }
  const dimensions = survey.getIn(["audience", "dynamicCriteria", "dimensions"]);
  const criteria = dimensions ? dimensions.toJS() : null;

  const renderCriterionValues = (criterions: any) => Array.isArray(criterions) ?
    criterions.map((criterion) => sanitizeSegmentLabel(criterion)).join(", ") : null;
  return (
    <>
      {criteria && (
        <SectionItem>
          <SectionItemLabel><Trans>Criteria used</Trans></SectionItemLabel>
          <SectionItemValue>
            {Object.keys(criteria).map(criterionName => (
              <Criterion key={criterionName}>
                <strong>{startCase(criterionName)}:</strong> {renderCriterionValues(criteria[criterionName])}
              </Criterion>
            ))}
          </SectionItemValue>
        </SectionItem>
      )}
      <SectionItem>
        <SectionItemLabel><Trans>Added by criteria</Trans></SectionItemLabel>
        <SectionItemValue>{surveyParticipantsCount.get("criteria") || "N/A"}</SectionItemValue>
      </SectionItem>
      <SectionItem>
        <SectionItemLabel><Trans>Added manually</Trans></SectionItemLabel>
        <SectionItemValue>{surveyParticipantsCount.get("manual") || "N/A"}</SectionItemValue>
      </SectionItem>
      <SectionItem>
        <SectionItemLabel><Trans>Total recipients</Trans></SectionItemLabel>
        <SectionItemValue>{surveyParticipantsCount.get("total") || "N/A"}</SectionItemValue>
      </SectionItem>
    </>
  );
}

const Criterion = styled.div`
  margin-bottom: 8px;
`;
