import React from "react";
import { RouteComponentProps } from "react-router";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
// eslint-disable-next-line max-len
import { BatarangScriptDefinitionsResource } from "hyphen-lib/dist/domain/resource/batarang/BatarangScriptDefinitionsResource";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { BatarangDryRunResultsResource } from "hyphen-lib/dist/domain/resource/batarang/BatarangDryRunResultsResource";
import { BatarangApplyResultsResource } from "hyphen-lib/dist/domain/resource/batarang/BatarangApplyResultsResource";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { BatarangApplyToken } from "hyphen-lib/dist/domain/resource/batarang/BatarangApplyToken";
import { connect, MapStateToProps } from "react-redux";
import { State } from "@store/types";
import { getResourceById } from "@store/network/selectors";
import { fetchBatarangScriptDefinitionsIfNeeded } from "@src/store/network/resource/BatarangScriptDefinitionsResources";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { BatarangScriptsComponent } from "@screens/Insights/Settings/components/batarang/BatarangScriptsComponent";
import { withRouter } from "react-router-dom";
// eslint-disable-next-line max-len
import { batarangScriptsActionCreators } from "@screens/Insights/Settings/containers/batarang/store/batarangScriptsActions";
import {
  getError,
  getExecutionInDryRunModeResults,
  getExecutionResults,
  getAssetUpload
} from "@screens/Insights/Settings/containers/batarang/store/batarangScriptsSelectors";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import { AssetUpload } from "./store/batarangScriptsReducers";
import { getCurrentUser } from "@src/screens/Insights/store/selectors";
import { getOr } from "hyphen-lib/dist/lang/Objects";
import { isCoreAppUserSyncEnabled } from "hyphen-lib/dist/business/company/Companies";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";

interface MatchParams {
  readonly scriptName: string;
}

export interface BatarangScriptsContainerOwnProps extends RouteComponentProps<MatchParams> {}

export interface BatarangScriptsContainerStateProps {
  readonly definitions: Loadable<BatarangScriptDefinitionsResource>;
  readonly scriptName: Optional<string>;
  readonly values: Dictionary<any>;
  readonly error: Optional<string> | Optional<WrongEntityException.GlobalError>;
  readonly dryRunExecutionResults: Optional<Loadable<BatarangDryRunResultsResource>>;
  readonly executionResults: Optional<Loadable<BatarangApplyResultsResource>>;
  readonly assetUpload: Optional<AssetUpload>;
  readonly isUserSyncEnabled: boolean;
  readonly company?: Optional<CompanyResource>;
}

export interface BatarangScriptsContainerActionProps {
  readonly onFetchBatarangScriptDefinitionIfNeeded: () => any;
  readonly onScriptChange: (scriptName: string) => any;
  readonly onParametersChange: (parameters: Dictionary<any>) => any;
  readonly onExecuteDryRun: (scriptName: string, parameters: Dictionary<any>) => any;
  readonly onExecute: (scriptName: string, parameters: Dictionary<any>, applyToken: BatarangApplyToken) => any;
  readonly onCancel: () => any;
  readonly onFinish: () => any;
  readonly onAssetUpload: (file: File) => any;
}

export type BatarangScriptsContainerProps =
  BatarangScriptsContainerOwnProps &
  BatarangScriptsContainerStateProps &
  BatarangScriptsContainerActionProps;

class BatarangScriptsContainer extends React.Component<BatarangScriptsContainerProps> {

  componentDidMount() {
    const {
      onFetchBatarangScriptDefinitionIfNeeded,
    } = this.props;

    onFetchBatarangScriptDefinitionIfNeeded();
  }

  render() {
    return (
      <BatarangScriptsComponent {...this.props} />
    );
  }
}

const mapStateToProps: MapStateToProps<BatarangScriptsContainerStateProps, BatarangScriptsContainerOwnProps, State> = (
  state: State,
  ownProps: BatarangScriptsContainerOwnProps
): BatarangScriptsContainerStateProps => {
  const scriptName = ownProps.match.params.scriptName;
  const values = parseQueryString(ownProps.location.search);
  const currentUser = getCurrentUser(state);
  const isUserSyncEnabled = getOr(currentUser && currentUser.company && 
    isCoreAppUserSyncEnabled(currentUser.company), false);
  let company = null;
  if (isNotNullNorUndefined(currentUser)) {
    if (isNotNullNorUndefined(currentUser.company)) {
      company = currentUser.company;
    }
  }
  return ({
    definitions: Store.Element.toLoadable(
      getResourceById(
        state,
        BatarangScriptDefinitionsResource.TYPE,
        BatarangScriptDefinitionsResource.DEFAULT_ALL_ID
      )
    ),
    scriptName,
    values,
    dryRunExecutionResults: getExecutionInDryRunModeResults(state),
    executionResults: getExecutionResults(state),
    error: getError(state),
    assetUpload: getAssetUpload(state),
    isUserSyncEnabled,
    company
  });
};

const mapDispatchToProps = {
  onFetchBatarangScriptDefinitionIfNeeded: fetchBatarangScriptDefinitionsIfNeeded,
  onScriptChange: batarangScriptsActionCreators.updateScript,
  onParametersChange: batarangScriptsActionCreators.updateParameterValues,
  onExecuteDryRun: batarangScriptsActionCreators.executeInDryRunMode,
  onExecute: batarangScriptsActionCreators.execute,
  onCancel: batarangScriptsActionCreators.cancel,
  onFinish: batarangScriptsActionCreators.finish,
  onAssetUpload: batarangScriptsActionCreators.uploadAssetRequestAction,
};

export const BatarangScripts = withRouter(connect(mapStateToProps, mapDispatchToProps)(BatarangScriptsContainer));
