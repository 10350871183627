import { DrawerViews } from "@src/components/core/BottomDrawer";
import { FSA } from "flux-standard-action";
import { Record, RecordOf } from "immutable";
import { BottomDrawerActionTypes } from "./actions";

interface BottomDrawerStateProps {
  bottomDrawerView: DrawerViews;
}

const defaultBottomDrawerStateValues: BottomDrawerStateProps = {
  bottomDrawerView: DrawerViews.MINIMIZED,
};

export const bottomDrawerStateFactory = Record<BottomDrawerStateProps>(
  defaultBottomDrawerStateValues
);
const defaultBottomDrawerState = bottomDrawerStateFactory();

export type BottomDrawerState = RecordOf<BottomDrawerStateProps>;

interface Payloads {
  [BottomDrawerActionTypes.MINIMIZE_BOTTOM_DRAWER]: {};
  [BottomDrawerActionTypes.MAXIMIZE_BOTTOM_DRAWER]: {};
  [BottomDrawerActionTypes.HIDE_BOTTOM_DRAWER]: {};
}

export interface MinimizeAction
  extends FSA<
    BottomDrawerActionTypes.MINIMIZE_BOTTOM_DRAWER,
    Payloads[BottomDrawerActionTypes.MINIMIZE_BOTTOM_DRAWER]
  > {
  type: BottomDrawerActionTypes.MINIMIZE_BOTTOM_DRAWER;
}

export interface MaximizeAction
  extends FSA<
    BottomDrawerActionTypes.MAXIMIZE_BOTTOM_DRAWER,
    Payloads[BottomDrawerActionTypes.MAXIMIZE_BOTTOM_DRAWER]
  > {
  type: BottomDrawerActionTypes.MAXIMIZE_BOTTOM_DRAWER;
}

export interface HideAction
  extends FSA<
    BottomDrawerActionTypes.HIDE_BOTTOM_DRAWER,
    Payloads[BottomDrawerActionTypes.HIDE_BOTTOM_DRAWER]
  > {
  type: BottomDrawerActionTypes.HIDE_BOTTOM_DRAWER;
}

type Actions = MinimizeAction | MaximizeAction | HideAction;

export function bottomDrawerReducers(
  state: BottomDrawerState = defaultBottomDrawerState,
  action: Actions | any
): BottomDrawerState {
  switch (action.type) {
    case BottomDrawerActionTypes.MINIMIZE_BOTTOM_DRAWER:
      return state.set("bottomDrawerView", DrawerViews.MINIMIZED);
    case BottomDrawerActionTypes.MAXIMIZE_BOTTOM_DRAWER:
      return state.set("bottomDrawerView", DrawerViews.EXPANDED);
    case BottomDrawerActionTypes.HIDE_BOTTOM_DRAWER:
      return state.set("bottomDrawerView", DrawerViews.INVISIBLE);
    default:
      return state;
  }
}
