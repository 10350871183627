import React from "react";
import { SelectProps } from "antd/lib/select";
import styled from "styled-components";
import Select from "@components/core/Select";
import { WithFieldLabelProps } from "@components/core/withFieldLabels";
import Input from "@components/core/Input";
import Button from "@components/core/Button";

import { SurveyTypeResource } from "hyphen-lib/dist/domain/resource/SurveyTypeResource";
import { Row, Col, Icon } from "antd";
import Palette from "@src/config/theme/palette";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { PostCategoryResource } from "hyphen-lib/dist/domain/resource/post/PostCategoryResource";
import { Trans, WithTranslation, withTranslation } from "react-i18next";

const { Option } = Select;

interface Props extends SelectProps, WithFieldLabelProps {
  surveyTypes: Partial<SurveyTypeResource | PostCategoryResource>[];
  customCategory?: boolean;
  onAddCustomCategory?: (category: any) => void;
}

interface CustomCategoryProps extends WithTranslation{
  toggleField: boolean;
  onToggleField: (toggleField: boolean) => void;
  lockClose: () => void;
  onCustomCategoryAdd: (category: any) => void;
  closeDropDown: () => void;
}

class CustomCategoryComponent extends React.Component<CustomCategoryProps> {
  state = {
    value: "",
    error: null,
  };
  onChange = (e: any) => this.setState({ value: e.target.value });
  addNewCategory = () => {
    const { onToggleField, onCustomCategoryAdd, closeDropDown} = this.props;
    const { value } = this.state;
    if (value.length === 0) {
      return this.setState({ error: "Category can't be empty." });
    }
    const category = {
      _id: value,
      label: value,
    };

    this.setState({ value: "", error: "" });
    onCustomCategoryAdd(category);
    onToggleField(false);
    closeDropDown();
  };

  render() {
    const { toggleField, onToggleField, lockClose, t} = this.props;
    const { value, error } = this.state;
    return (
      <CustomContainer
        onMouseDown={lockClose}
      >
        {toggleField ? (
          <Container>
            <Row type="flex" align="top" justify="space-between">
              <Col span={19}>
                <Input
                  value={value}
                  placeholder={`${t("Custom category name")}`}
                  onChange={this.onChange}
                  error={error && <Trans>{error}</Trans>}
                  autoFocus
                  onPressEnter={this.addNewCategory}
                />
              </Col>
              <Col span={5}>
                <Button
                  onClick={this.addNewCategory}
                  translate="yes"
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Container>
        ) : (
        // tslint:disable-next-line:jsx-no-lambda
          <div onClick={() => onToggleField(true)}>
            <Icon type="plus" /> <Trans>Add custom category</Trans>
          </div>
        )}
      </CustomContainer>
    );
  }
}

const CustomCategory = withTranslation()(CustomCategoryComponent);

const Container = styled.div`
  button {
    height: 32px !important;
  }
  input {
    margin-top: unset !important;
  }
  .ant-form-item-control {
    line-height: unset !important;
  }
`;

// tslint:disable-next-line:max-classes-per-file
export class SurveyCategories extends React.Component<Props, {
  categories: Props["surveyTypes"];
  toggleField: boolean;
  open: boolean;
}> {
  lock = null as any;

  constructor(props: Props) {
    super(props);

    this.state = {
      toggleField: false,
      open: false,
      categories: props.surveyTypes,
    };
  }

  onToggleField = (toggleField: boolean) => this.setState({ toggleField });

  // source: https://codesandbox.io/s/32y80yk915
  lockClose = () => {
    clearTimeout(this.lock);
    this.lock = setTimeout(() => {
      this.lock = null;
    }, 100);
    return true;
  };

  closeDropDown = () => this.setState({ open: false });

  onDropDownVisibleChange = (open: any) => {
    if (this.lock) {
      return;
    }
    this.setState({ open, toggleField: !open ? open : this.state.toggleField });
  };

  renderDropDown = (menu: any) => {
    const { customCategory = false } = this.props;
    return customCategory ? (
      <div>
        {menu}
        <CustomCategory
          toggleField={this.state.toggleField}
          onToggleField={this.onToggleField}
          lockClose={this.lockClose}
          onCustomCategoryAdd={this.onCustomCategoryAdd}
          closeDropDown={this.closeDropDown}
        />
      </div>
    ) : menu;
  };

  onCustomCategoryAdd = (category: any) => {
    const { categories } = this.state;
    const { onChange, onAddCustomCategory } = this.props;
    categories.push(category);
    if (isNotNullNorUndefined(onAddCustomCategory)) {
      onAddCustomCategory(category);
    }

    this.setState({ categories });
    if (isNotNullNorUndefined(onChange)) {
      onChange(category._id, <div />);
    }
  };

  render() {
    const { open, categories } = this.state;
    const {
      surveyTypes,
      ...rest
    } = this.props;
    return (
      <StyledSelect
        open={open}
        dropdownRender={this.renderDropDown}
        onDropdownVisibleChange={this.onDropDownVisibleChange}
        {...rest}
      >
        {categories.map((surveyType: Partial<SurveyTypeResource | PostCategoryResource>) =>
          <Option key={surveyType._id} value={surveyType._id}>{surveyType.label}</Option>
        )}
      </StyledSelect>
    );
  }
}

const CustomContainer = styled.div`
  padding: 20px 12px;
  color: ${Palette.darkModerateBlue};
  cursor: pointer;

  svg {
    fill: ${Palette.darkModerateBlue};
  }
`;

const StyledSelect = styled(Select)<SelectProps & {children: React.ReactNode}>``;
