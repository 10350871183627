import React from "react";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import { isEmpty } from "hyphen-lib/dist/lang/Arrays";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";

interface GlobalErrorsProps {
  readonly errors: WrongEntityException.GlobalError[];
}

export function GlobalErrors({ errors }: GlobalErrorsProps) {
  if (isEmpty(errors)) {
    return null;
  }
  return (
    <GlobalErrorsList>
      {
        errors.map((error, idx) =>
          <GlobalError key={"global-error#" + idx} error={error}/>
        )
      }
    </GlobalErrorsList>
  );
}

function GlobalError({error}: {error: WrongEntityException.GlobalError}) {
  // Note: we probably want to do a translation of the error!
  return <li>{error.reason}</li>;
}

const GlobalErrorsList = styled.ul`
  color: ${Palette.darkPink};
`;
