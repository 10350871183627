import { PulsePollListOverviewResource } from "hyphen-lib/dist/domain/resource/PulsePollListOverviewResource";
import { CurrentUserResource } from "@hyphen-lib/domain/resource/user/CurrentUserResource";
import { UserResource } from "@hyphen-lib/domain/resource/user/UserResource";
import { UserPermissionResource } from "hyphen-lib/dist/domain/resource/user/UserPermissionResource";
import { AppSettingsResource } from "@hyphen-lib/domain/resource/AppSettingsResource";
import { CompanyResource } from "@hyphen-lib/domain/resource/CompanyResource";
import { SurveyResource } from "@hyphen-lib/domain/resource/SurveyResource";
import { ActionResource } from "@hyphen-lib/domain/resource/action/ActionResource";
import { ParticipationReportResource } from "@hyphen-lib/domain/resource/survey/report/ParticipationReportResource";
import { SurveyTemplateResource } from "@hyphen-lib/domain/resource/SurveyTemplateResource";
import { SurveyTypeResource } from "hyphen-lib/dist/domain/resource/SurveyTypeResource";
import { PostCategoryResource } from "hyphen-lib/dist/domain/resource/post/PostCategoryResource";
import { SurveyInfoResource } from "hyphen-lib/dist/domain/resource/SurveyInfoResource";
import { SurveyBankQuestionResource } from "hyphen-lib/dist/domain/resource/survey/SurveyBankQuestionResource";
import { UploadUsersResource } from "hyphen-lib/dist/domain/resource/user/UploadUsersResource";
import { QuestionInfoResource } from "hyphen-lib/dist/domain/resource/QuestionInfoResource";
import { ActionPlanTemplateResource } from "hyphen-lib/dist/domain/resource/action/ActionPlanTemplateResource";
import { Resource } from "hyphen-lib/dist/domain/resource/Resource";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { Supplier } from "hyphen-lib/dist/lang/Functions";
import { DefaultStore } from "hyphen-lib/dist/util/store/DefaultStore";
import { DashboardResource } from "hyphen-lib/dist/domain/resource/report/DashboardResource";
import {
  SegmentReportResource
} from "hyphen-lib/dist/domain/resource/report/SegmentReportResource";
import { OverviewReportResource } from "hyphen-lib/dist/domain/resource/survey/report/OverviewReportResource";
import { RawHeatMapResource } from "hyphen-lib/dist/domain/resource/report/RawHeatMapResource";
import { CategoriesReportResource } from "hyphen-lib/dist/domain/resource/report/CategoriesReportResource";
// eslint-disable-next-line max-len
import { SurveyQuestionsReportResource } from "hyphen-lib/dist/domain/resource/survey/report/SurveyQuestionsReportResource";
import { CommentsOverviewResource } from "hyphen-lib/dist/domain/resource/report/CommentsOverviewResource";
import { CommentsResultResource } from "hyphen-lib/dist/domain/resource/report/CommentsResultResource";
// eslint-disable-next-line max-len
import { MultipleChoiceQuestionReportResource } from "hyphen-lib/dist/domain/resource/report/MultipleChoiceQuestionReportResource";
import { CategoryReportResource } from "hyphen-lib/dist/domain/resource/report/CategoryReportResource";
import { IndividualResultResource } from "hyphen-lib/dist/domain/resource/survey/report/IndividualResultResource";
import { PulsePollResource } from "hyphen-lib/dist/domain/resource/PulsePollResource";
import { PulsePollInfoResource } from "hyphen-lib/dist/domain/resource/PulsePollInfoResource";
import {
  PulsePollOverviewReportResource
} from "hyphen-lib/dist/domain/resource/pulsePoll/report/PulsePollOverviewReportResource";
import { PulsePollInstanceInfoResource } from "hyphen-lib/dist/domain/resource/PulsePollInstanceInfoResource";
import { MergeTagResource } from "hyphen-lib/dist/domain/resource/MergeTagResource";
import { UNTYPED_TYPE } from "@store/network/actions";
import { ParticipantCountResource } from "hyphen-lib/dist/domain/resource/participants/ParticipantCountResource";
import { ParticipantResource } from "hyphen-lib/dist/domain/resource/participants/ParticipantResource";
import { VoicePostReportResource } from "hyphen-lib/dist/domain/resource/voice/VoicePostReportResource";
import { VoiceFlaggedPostReportResource } from "hyphen-lib/dist/domain/resource/voice/VoiceFlaggedPostReportResource";
import {
  VoiceFlaggedCommentReportResource
} from "hyphen-lib/dist/domain/resource/voice/VoiceFlaggedCommentReportResource";
import {
  DifferentialAudienceCountResource
} from "hyphen-lib/dist/domain/resource/DifferentialAudienceResource";
import { VoiceCommentReportResource } from "hyphen-lib/dist/domain/resource/voice/VoiceCommentReportResource";
import { GroupsAndChannelsResource } from "hyphen-lib/dist/domain/resource/group/GroupsAndChannelsResource";
import {
  BatarangScriptDefinitionsResource
} from "hyphen-lib/dist/domain/resource/batarang/BatarangScriptDefinitionsResource";
import { LifeCycleDashboardResource } from "hyphen-lib/dist/domain/resource/report/LifeCycleResource";
import { TrendResource } from "hyphen-lib/dist/domain/resource/survey/report/TrendResource";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { TopicOverviewResource } from "hyphen-lib/dist/domain/resource/survey/report/TopicOverviewResource";
import { SurveyTopicCommentResource } from "hyphen-lib/dist/domain/resource/survey/report/SurveyTopicCommentResource";
import {  SurveyTopicSegmentResource } from "hyphen-lib/dist/domain/resource/survey/report/SurveyTopicSegmentResource";
import { SurveyDimensionsResource } from "hyphen-lib/dist/domain/resource/survey/report/SurveyDimensionsResource";
import { PulsePollsDimensionsResource } from "hyphen-lib/dist/domain/resource/PulsePollsDimensionsResource";

/*
    In order to add a new resource, and be able to use in the store:
    - The resource need to be added in hyphen-lib/src/domain/resource/Resource
        - in Resource.Type
        - in Resource.ByTypes
    - In this file, the type need to be added on the subset of the types allowed in i2: `UsedResources`
    - In this file, the store supplier need to be configured in `resourceStoresSuppliers`
 */

export type UsedResources = Pick<Resource.ByTypes,
// this part must to be modified as soon as we want a new resource to be taken into account,
// maybe we export that in a file: UsedResources.ts in i2
| typeof ActionPlanTemplateResource.TYPE
| typeof ActionResource.TYPE
| typeof AppSettingsResource.TYPE
| typeof BatarangScriptDefinitionsResource.TYPE
| typeof CategoriesReportResource.TYPE
| typeof CategoryReportResource.TYPE
| typeof CommentsOverviewResource.TYPE
| typeof CommentsResultResource.TYPE
| typeof CompanyResource.TYPE
| typeof CurrentUserResource.TYPE
| typeof DashboardResource.TYPE
| typeof FocusAreaResource.TYPE
| typeof SegmentReportResource.TYPE
| typeof DifferentialAudienceCountResource.TYPE
| typeof GroupsAndChannelsResource.TYPE
| typeof IndividualResultResource.TYPE
| typeof LifeCycleDashboardResource.TYPE
| typeof MergeTagResource.TYPE
| typeof MultipleChoiceQuestionReportResource.TYPE
| typeof OverviewReportResource.TYPE
| typeof ParticipantCountResource.TYPE
| typeof ParticipantResource.TYPE
| typeof ParticipationReportResource.TYPE
| typeof PostCategoryResource.TYPE
| typeof PulsePollResource.TYPE
| typeof PulsePollInfoResource.TYPE
| typeof PulsePollInstanceInfoResource.TYPE
| typeof PulsePollOverviewReportResource.TYPE
| typeof PulsePollListOverviewResource.TYPE
| typeof QuestionInfoResource.TYPE
| typeof RawHeatMapResource.TYPE
| typeof SurveyBankQuestionResource.TYPE
| typeof SurveyQuestionsReportResource.TYPE
| typeof SurveyResource.TYPE
| typeof SurveyInfoResource.TYPE
| typeof SurveyTemplateResource.TYPE
| typeof TrendResource.TrendTypeEnum.SURVEY_TREND
| typeof SurveyTypeResource.TYPE
| typeof UploadUsersResource.TYPE
| typeof UserResource.TYPE
| typeof UserPermissionResource.TYPE
| typeof UserPermissionResource["USER_SCOPE_LIST_TYPE"]
| typeof VoicePostReportResource.TYPE
| typeof VoiceCommentReportResource.TYPE
| typeof VoiceFlaggedPostReportResource.TYPE
| typeof VoiceFlaggedCommentReportResource.TYPE
| typeof TopicOverviewResource.TYPE
| typeof SurveyTopicCommentResource.TYPE
| typeof SurveyTopicSegmentResource.TYPE
| typeof SurveyDimensionsResource.TYPE
| typeof PulsePollsDimensionsResource.TYPE
>;

export type ResourceStores = {
  [index in keyof UsedResources]: Store<UsedResources[index]>;
} & {
  [UNTYPED_TYPE]: Store<any>;
};

export const resourceStoresSuppliers: { [index in keyof ResourceStores]: Supplier<ResourceStores[index]> } = {
  [ActionPlanTemplateResource.TYPE]: () => DefaultStore.create({
    pages: DefaultStore.Configuration.leastRecentlyUsed(10),
    counts: DefaultStore.Configuration.leastRecentlyUsed(100),
  }),
  [ActionResource.TYPE]: () => DefaultStore.create({
    pages: DefaultStore.Configuration.leastRecentlyUsed(10),
    counts: DefaultStore.Configuration.leastRecentlyUsed(100),
  }),
  [AppSettingsResource.TYPE]: () => DefaultStore.create(),
  [BatarangScriptDefinitionsResource.TYPE]: () => DefaultStore.create(),
  [CategoriesReportResource.TYPE]: () => DefaultStore.create({
    elements: DefaultStore.Configuration.leastRecentlyUsed(20),
  }),
  [CategoryReportResource.TYPE]: () => DefaultStore.create({
    elements: DefaultStore.Configuration.leastRecentlyUsed(20),
  }),
  [CommentsOverviewResource.TYPE]: () => DefaultStore.create({
    elements: DefaultStore.Configuration.leastRecentlyUsed(20),
  }),
  [CommentsResultResource.TYPE]: () => DefaultStore.create({
    pages: DefaultStore.Configuration.leastRecentlyUsed(10),
    counts: DefaultStore.Configuration.leastRecentlyUsed(100),
  }),
  [CompanyResource.TYPE]: () => DefaultStore.create(),
  [CurrentUserResource.TYPE]: () => DefaultStore.create(),
  [DashboardResource.TYPE]: () => DefaultStore.create({
    elements: DefaultStore.Configuration.leastRecentlyUsed(20),
  }),
  [FocusAreaResource.TYPE]: () => DefaultStore.create({
    pages: DefaultStore.Configuration.leastRecentlyUsed(10),
    counts: DefaultStore.Configuration.leastRecentlyUsed(100),
  }),
  [SegmentReportResource.TYPE]: () => DefaultStore.create({
    elements: DefaultStore.Configuration.leastRecentlyUsed(20),
  }),
  [IndividualResultResource.TYPE]: () => DefaultStore.create({
    pages: DefaultStore.Configuration.leastRecentlyUsed(10),
    counts: DefaultStore.Configuration.leastRecentlyUsed(100),
  }),
  [LifeCycleDashboardResource.TYPE]: () => DefaultStore.create({
    elements: DefaultStore.Configuration.leastRecentlyUsed(20),
  }),
  [MergeTagResource.TYPE]: () => DefaultStore.create({
    pages: DefaultStore.Configuration.leastRecentlyUsed(100),
  }),
  [MultipleChoiceQuestionReportResource.TYPE]: () => DefaultStore.create({
    elements: DefaultStore.Configuration.leastRecentlyUsed(20),
  }),
  [OverviewReportResource.TYPE]: () => DefaultStore.create({
    elements: DefaultStore.Configuration.leastRecentlyUsed(20),
  }),
  [DifferentialAudienceCountResource.TYPE]: () => DefaultStore.create({
    elements: DefaultStore.Configuration.leastRecentlyUsed(50),
  }),
  [GroupsAndChannelsResource.TYPE]: () => DefaultStore.create({
    elements: DefaultStore.Configuration.leastRecentlyUsed(50),
  }),
  [ParticipantCountResource.TYPE]: () => DefaultStore.create({
    elements: DefaultStore.Configuration.leastRecentlyUsed(50),
  }),
  [ParticipantResource.TYPE]: () => DefaultStore.create({
    pages: DefaultStore.Configuration.leastRecentlyUsed(50),
  }),
  [ParticipationReportResource.TYPE]: () => DefaultStore.create({
    elements: DefaultStore.Configuration.leastRecentlyUsed(20),
  }),
  [PostCategoryResource.TYPE]: () => DefaultStore.create(),
  [PulsePollResource.TYPE]: () => DefaultStore.create(),
  [PulsePollInfoResource.TYPE]: () => DefaultStore.create({
    elements: DefaultStore.Configuration.leastRecentlyUsed(20),
    pages: DefaultStore.Configuration.leastRecentlyUsed(10),
    counts: DefaultStore.Configuration.leastRecentlyUsed(100),
  }),
  [PulsePollOverviewReportResource.TYPE]: () => DefaultStore.create(),
  [PulsePollListOverviewResource.TYPE]: () => DefaultStore.create(),
  [QuestionInfoResource.TYPE]: () => DefaultStore.create({
    elements: DefaultStore.Configuration.leastRecentlyUsed(20),
  }),
  [PulsePollInstanceInfoResource.TYPE]: () => DefaultStore.create(),

  [RawHeatMapResource.TYPE]: () => DefaultStore.create({
    elements: DefaultStore.Configuration.leastRecentlyUsed(20),
  }),
  [SurveyBankQuestionResource.TYPE]: () => DefaultStore.create(),
  [SurveyQuestionsReportResource.TYPE]: () => DefaultStore.create({
    elements: DefaultStore.Configuration.leastRecentlyUsed(20),
  }),
  [SurveyResource.TYPE]: () => DefaultStore.create(),
  [SurveyInfoResource.TYPE]: () => DefaultStore.create({
    elements: DefaultStore.Configuration.leastRecentlyUsed(20),
    pages: DefaultStore.Configuration.leastRecentlyUsed(10),
    counts: DefaultStore.Configuration.leastRecentlyUsed(100),
  }),
  [SurveyTemplateResource.TYPE]: () => DefaultStore.create(),
  [TrendResource.TrendTypeEnum.SURVEY_TREND]: () => DefaultStore.create({
    elements: DefaultStore.Configuration.leastRecentlyUsed(20),
  }),
  [SurveyTypeResource.TYPE]: () => DefaultStore.create(),
  [UploadUsersResource.TYPE]: () => DefaultStore.create(),
  [UserResource.TYPE]: () => DefaultStore.create({
    pages: DefaultStore.Configuration.leastRecentlyUsed(10),
    counts: DefaultStore.Configuration.leastRecentlyUsed(100),
  }),
  [UserPermissionResource.TYPE]: () => DefaultStore.create({
    pages: DefaultStore.Configuration.leastRecentlyUsed(10),
    counts: DefaultStore.Configuration.leastRecentlyUsed(100),
  }),
  [UserPermissionResource.USER_SCOPE_LIST_TYPE]: () => DefaultStore.create({
    pages: DefaultStore.Configuration.leastRecentlyUsed(10),
    counts: DefaultStore.Configuration.leastRecentlyUsed(100),
  }),
  [UNTYPED_TYPE]: () => DefaultStore.create({
    elements: DefaultStore.Configuration.leastRecentlyUsed(100),
  }),
  [VoicePostReportResource.TYPE]: () => DefaultStore.create({
    pages: DefaultStore.Configuration.leastRecentlyUsed(10),
  }),
  [VoiceCommentReportResource.TYPE]: () => DefaultStore.create({
    pages: DefaultStore.Configuration.leastRecentlyUsed(10),
  }),
  [VoiceFlaggedPostReportResource.TYPE]: () => DefaultStore.create({
    pages: DefaultStore.Configuration.leastRecentlyUsed(10),
  }),
  [VoiceFlaggedCommentReportResource.TYPE]: () => DefaultStore.create({
    pages: DefaultStore.Configuration.leastRecentlyUsed(10),
  }),
  [TopicOverviewResource.TYPE]: () => DefaultStore.create({
    elements: DefaultStore.Configuration.leastRecentlyUsed(20)
  }),
  [SurveyTopicCommentResource.TYPE]: () => DefaultStore.create({
    pages: DefaultStore.Configuration.leastRecentlyUsed(10),
  }),
  [SurveyTopicSegmentResource.TYPE]: () => DefaultStore.create({
    elements: DefaultStore.Configuration.leastRecentlyUsed(20)
  }),
  [SurveyDimensionsResource.TYPE]: () => DefaultStore.create({
    elements: DefaultStore.Configuration.leastRecentlyUsed(20)
  }),
  [PulsePollsDimensionsResource.TYPE]: () => DefaultStore.create({
    elements: DefaultStore.Configuration.leastRecentlyUsed(20)
  }),
};
