import React from "react";
import { Trans } from "react-i18next";
import ContainerCard from "@src/components/core/ContainerCard";
import Select from "@src/components/core/Select";
import styled from "styled-components";
import { InputProps } from "antd/lib/input";
import { Checkbox } from "antd";
import Palette from "@src/config/theme/palette";
import moment from "moment-timezone";
import { CompanySettingsProps } from "../containers/CompanySettings";

const { Option } = Select;
const CheckboxGroup = Checkbox.Group;

interface CompanySettingsBusinessProps {
  updateCompany: CompanySettingsProps["updateCompany"];
  company: CompanySettingsProps["company"];
}

export default class CompanySettingsBusiness extends React.Component<CompanySettingsBusinessProps> {
  updateTimezone = (value: any) =>
    this.props.updateCompany(this.props.company.name, {...this.props.company, timeZone: value});
  updateDaysOfWeek = (values: any) =>
    this.props.updateCompany(this.props.company.name, {...this.props.company, daysOfWeek: values as number[]});
  updateStartTime = (value: any) =>
    this.props.updateCompany(this.props.company.name, {...this.props.company, startTime: Number(value)});
  updateEndTime = (value: any) =>
    this.props.updateCompany(this.props.company.name, {...this.props.company, endTime: Number(value)});
  renderTimezone = (tz: string) => tz.replace("_"," ");

  render () {
    const {company} = this.props;
    return (
      <ContainerCard title="Business days and hours">
        <Title1><Trans>Company timezone</Trans></Title1>
        <TimezoneSelect id="timezone" value={company.timeZone}
          onChange={this.updateTimezone}>
          {/* eslint-disable max-len */}
          {moment.tz.names().map((tz, idx) => (<option value={tz} key={"tz-" + idx}>{this.renderTimezone(tz)}</option>))}
          {/* eslint-disable max-len */}
        </TimezoneSelect>

        <Title2><Trans>Business days</Trans></Title2>
        <CheckboxGroup
          options={[
            {label: "Monday", value: 0},
            {label: "Tuesday", value: 1},
            {label: "Wednesday", value: 2},
            {label: "Thursday", value: 3},
            {label: "Friday", value: 4},
            {label: "Saturday", value: 5},
            {label: "Sunday", value: 6},
          ]}
          defaultValue={company.daysOfWeek}
          onChange={this.updateDaysOfWeek}
        />
        <Description>
          <Trans>Surveys and Polls will only be sent on business days.</Trans>
        </Description>

        <Title2><Trans>Business hours</Trans></Title2>
        <FromContainer>
          <FromToDiv><Trans>From</Trans></FromToDiv>
          <FromToSelect id="from" value={company.startTime.toString()}
            onChange={this.updateStartTime}>
            <Option key="0" value="0">0 AM</Option>
            <Option key="1" value="1">1 AM</Option>
            <Option key="2" value="2">2 AM</Option>
            <Option key="3" value="3">3 AM</Option>
            <Option key="4" value="4">4 AM</Option>
            <Option key="5" value="5">5 AM</Option>
            <Option key="6" value="6">6 AM</Option>
            <Option key="7" value="7">7 AM</Option>
            <Option key="8" value="8">8 AM</Option>
            <Option key="9" value="9">9 AM</Option>
            <Option key="10" value="10">10 AM</Option>
            <Option key="11" value="11">11 AM</Option>
            <Option key="12" value="12">12 PM</Option>
            <Option key="13" value="13">1 PM</Option>
            <Option key="14" value="14">2 PM</Option>
            <Option key="15" value="15">3 PM</Option>
            <Option key="16" value="16">4 PM</Option>
            <Option key="17" value="17">5 PM</Option>
            <Option key="18" value="18">6 PM</Option>
            <Option key="19" value="19">7 PM</Option>
            <Option key="20" value="20">8 PM</Option>
            <Option key="21" value="21">9 PM</Option>
            <Option key="22" value="22">10 PM</Option>
            <Option key="23" value="23">11 PM</Option>
            <Option key="24" value="24">12 AM</Option>
          </FromToSelect>
        </FromContainer>
        <ToContainer>
          <FromToDiv><Trans>To</Trans></FromToDiv>
          <FromToSelect id="to" value={company.endTime.toString()}
            onChange={this.updateEndTime}>
            <Option key="0" value="0">0 AM</Option>
            <Option key="1" value="1">1 AM</Option>
            <Option key="2" value="2">2 AM</Option>
            <Option key="3" value="3">3 AM</Option>
            <Option key="4" value="4">4 AM</Option>
            <Option key="5" value="5">5 AM</Option>
            <Option key="6" value="6">6 AM</Option>
            <Option key="7" value="7">7 AM</Option>
            <Option key="8" value="8">8 AM</Option>
            <Option key="9" value="9">9 AM</Option>
            <Option key="10" value="10">10 AM</Option>
            <Option key="11" value="11">11 AM</Option>
            <Option key="12" value="12">12 PM</Option>
            <Option key="13" value="13">1 PM</Option>
            <Option key="14" value="14">2 PM</Option>
            <Option key="15" value="15">3 PM</Option>
            <Option key="16" value="16">4 PM</Option>
            <Option key="17" value="17">5 PM</Option>
            <Option key="18" value="18">6 PM</Option>
            <Option key="19" value="19">7 PM</Option>
            <Option key="20" value="20">8 PM</Option>
            <Option key="21" value="21">9 PM</Option>
            <Option key="22" value="22">10 PM</Option>
            <Option key="23" value="23">11 PM</Option>
            <Option key="24" value="24">12 AM</Option>
          </FromToSelect>
        </ToContainer>
        <Description>
          <Trans>Surveys and Polls will only be sent within business hours.</Trans>
        </Description>
      </ContainerCard>
    );
  }
}

const Title1 = styled.div`
  line-height: 20px;
  margin-bottom: 5px;
  margin-top: 24px;
`;

const TimezoneSelect = styled(Select)<InputProps>`
  width: 266px;
`;

const Title2 = styled.div`
  line-height: 20px;
  margin-bottom: 8px;
  margin-top: 24px;
`;

const Description = styled.div`
  margin-top: 8px;
  line-height: 20px;
  color: ${Palette.bluishGrey};
`;

const fromToStyle = `
  display: flex;
  align-items: center;
`;

const FromContainer = styled.div`
  ${fromToStyle}
  margin-bottom: 16px;
`;

const ToContainer = styled.div`
  ${fromToStyle}
`;

const FromToDiv = styled.div`
  width: 69px;
`;

const FromToSelect = styled(Select)<InputProps>`
  width: 98px;
`;
