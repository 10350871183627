import React, { useEffect, useState } from "react";
import Modal from "@src/components/core/Modal";
import { ModalProps } from "antd/lib/modal";
import styled from "styled-components";
import Button from "@components/core/Button";
import Select from "@components/core/Select";
import { SelectProps } from "antd/lib/select";
import Palette from "@src/config/theme/palette";
import { TickIconSvg } from "../svg/TickIconSvg";
import { Trans } from "react-i18next";
const { Option } = Select;

export type AVAILABLE_LANGUAGES = {
  en: string;
  fr: string;
};

const LanguageMap: { [key in keyof AVAILABLE_LANGUAGES]: string } = {
  en: "",
  fr: "française"
};

const LangCodeToName: { [key in keyof AVAILABLE_LANGUAGES]: string } = {
  fr: "French (Canada)",
  en: "English"
};

interface LanguageSelectorModalProps {
  visible: boolean;
  onClose: () => void;
  activeLanguage: keyof AVAILABLE_LANGUAGES;
  onLanguageSelect: (language: keyof AVAILABLE_LANGUAGES) => void;
}

function LanguageOption(languageName: string, translatedMeta: string) {
  return (
    <LanguageOptionRoot>
      <LanguageOptionWrapper>
        <LanguageName>
          {languageName}
        </LanguageName>
        <TranslatedMeta className="translated-meta">
          {translatedMeta}
        </TranslatedMeta>
      </LanguageOptionWrapper>

      <TickIconWrapper className="selected-language-tick">
        <TickIconSvg />
      </TickIconWrapper>
    </LanguageOptionRoot>
  );
}

function getAvailableLanguages(): {
  languageCode: keyof AVAILABLE_LANGUAGES;
  translatedMeta: string;
}[] {
  const keys = Object.keys(LanguageMap);
  // @ts-ignore
  return keys.map((item) => {
    // @ts-ignore
    return { languageCode: item, translatedMeta: LanguageMap[item] };
  });
}

export default function LanguageSelectorModal(
  props: LanguageSelectorModalProps
) {
  const [visible, isVisible] = useState(false);
  const [language, setLanguage] = useState(props.activeLanguage);

  useEffect(() => {
    setLanguage(props.activeLanguage);
  }, [props.activeLanguage]);

  useEffect(() => {
    isVisible(props.visible);
  }, [props.visible]);

  return (
    <div data-cy="language-selector-modal">
      <StyledModal
        visible={visible}
        title={
          <span data-cy="language-selector-header">
            <Trans>Choose a language</Trans>
          </span>
        }
        footer={null}
        onCancel={() => {
          isVisible(false);
          props.onClose();
        }}
        destroyOnClose={true}
      >
        <ModalContent>
          <ContentWrapper>
            <div>
              <StyledSelect
                label={<Trans>Language</Trans>}
                showSearch
                isEmpty={false}
                placeholder={<Trans>Search</Trans>}
                size="large"
                defaultValue={language}
                onChange={(value) =>
                  setLanguage(value as keyof AVAILABLE_LANGUAGES)
                }
                className={"language-selector"}
                // open={true}
              >
                <Option
                  key={"-1"}
                  value={"en"}
                  style={{ padding: "1rem" }}
                  className={"border-bottom"}
                >
                  {LanguageOption(LangCodeToName.en, "")}
                </Option>
                {
                /**
                 * Always keep english at the TOP
                 */
                 }
                {getAvailableLanguages().map((item, index) => {
                  return item.languageCode !== "en" ? (
                    <Option
                      key={index}
                      value={item.languageCode}
                      style={{ padding: "1rem" }}
                    >
                      {LanguageOption(
                        LangCodeToName[item.languageCode],
                        item.translatedMeta
                      )}
                    </Option>
                  ) : null;
                })}
              </StyledSelect>
            </div>
          </ContentWrapper>
        </ModalContent>

        <ActionContainer>
          <StyledButton
            color="blue"
            key="saveTranslation"
            data-cy="save-language"
            onClick={() => {
              props.onLanguageSelect(language);
              props.onClose();
              isVisible(false);
            }}
            translate="yes"
          >
            Save changes
          </StyledButton>
        </ActionContainer>
      </StyledModal>
    </div>
  );
}

const StyledModal = styled(Modal)<ModalProps & { children: React.ReactNode }>`
  .ant-modal-body {
    max-height: none !important;
    overflow-y: unset !important;
  }
`;

const ModalContent = styled.div`
  margin-top: 10px;
  padding-left: 32px !important;
  padding-right: 32px !important;
`;

const StyledButton = styled(Button)`
  // margin-left: 32px;
  min-width: 150px;
  margin-top: 3rem;
`;

const ActionContainer = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-around;
  padding-left: 32px !important;
  padding-right: 32px !important;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSelect = styled(Select)<SelectProps & { isEmpty: boolean }>`
  .ant-select-selection {
    border: 1px solid
      ${(props) => (props.isEmpty ? Palette.darkPink : "#d9d9d9")};
    background-color: ${(props) => (props.isEmpty ? "#fbebef" : "#fff")};
  }
  width: 60%;
`;

const LanguageOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto !important;
`;

const TranslatedMeta = styled.div`
  font-size: 12px;
  color: #7a8891;
  letter-spacing: 0;
  line-height: 16px;
`;

const LanguageName = styled.div`
  color: #213848;
  font-size: 14px;
  letter-spacing: 0;
`;

const LanguageOptionRoot = styled.div`
  display: flex;
`;

const TickIconWrapper = styled.div`
  visibility: hidden;
`;
