import React from "react";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import { BaseViewOptionProps } from "@screens/Insights/components/ViewOptions/components/ViewOptionsContent";
import Checkbox from "@components/core/Checkbox";
import { Trans } from "react-i18next";

export function Toggle({ label, value, onChange }: BaseViewOptionProps<boolean>) {
  const handleChange = (e: CheckboxChangeEvent) => {
    onChange(e.target.checked);
  };

  return (
    <Checkbox
      checked={value}
      onChange={handleChange}
    >
      <Trans>{label}</Trans>
    </Checkbox>
  );
}
