import React from "react";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { connect } from "react-redux";
import { SurveyInfoResource } from "hyphen-lib/dist/domain/resource/SurveyInfoResource";
import { SurveyTypes } from "@screens/Insights/Surveys/store/types";
import { FiltersContent } from "@screens/Insights/components/FiltersContent";
import { SurveyStatusFilter, SurveyTypeFilter } from "@src/screens/Insights/components/Filters/business/SurveyFilters";
import { applyDefault } from "@src/utils/Currier";
import { State } from "@store/types";
import { getExistingCount } from "@store/network/selectors";
// eslint-disable-next-line max-len
import { surveyListFiltersActionCreators } from "@screens/Insights/SurveyList/containers/SurveyListFilterContainer/store/actions";
import { getFilter } from "@screens/Insights/SurveyList/containers/SurveyListFilterContainer/store/selectors";
import { getOr } from "hyphen-lib/dist/lang/Objects";
import { DateFilter } from "@screens/Insights/components/Filters/business/DateFilter";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { fetchSurveyInfosCountIfNeeded } from "@store/network/resource/SurveyInfoResources";
import { FetchError } from "@src/screens/Insights/errors/FetchError";

export interface SurveyListFiltersContainerStateProps {
  readonly innerValues: Dictionary<any>;
  readonly count: number;
  readonly countLoading: boolean;
  readonly countResource: Store.Count;
}

export interface SurveyListContainerActionProps {
  readonly onFetchCountIfNeeded: (filters: any) => any;
  readonly onModifyFilter: (filters: any) => any;
  readonly onCleanFilter: () => any;
}

export interface SurveyListContainerOwnProps {
  readonly values?: Dictionary<any>;
  readonly surveyTypes: SurveyTypes;
  readonly onApply: (filters: any) => any;
  "data-cy"?: string;
}

export type SurveyListFiltersContainerProps = SurveyListContainerOwnProps &
SurveyListFiltersContainerStateProps &
SurveyListContainerActionProps;

/* exported for unit tests only */
export class SurveyListFilters extends React.Component<SurveyListFiltersContainerProps> {
  componentDidMount() {
    /*
        Modify filters with initial values while mounting.
     */
    this.props.onModifyFilter(getOr(this.props.values, {}));
  }

  componentDidUpdate(prevProps: any) {

    if (this.props.countLoading && not(Store.Count.isInError(this.props.countResource))) {
      /*
        Count is not loaded, maybe we might want to fetch it.
      */
      this.props.onFetchCountIfNeeded(this.props.innerValues);
    }
  }

  componentWillUnmount(): void {
    this.props.onCleanFilter();
  }

  render() {
    const {
      surveyTypes,
      values,
      count,
      countLoading,
      onApply,
      onModifyFilter,
      countResource,
    } = this.props;

    if (Store.Count.isInError(countResource)) {
      return <FetchError {...countResource} resourceType={SurveyInfoResource.TYPE} isCountResource/>;
    }
    return (
      <FiltersContent
        filters={[
          {
            key: "launchedAt",
            label: "Date range (by launch date)",
            component: DateFilter,
          },
          {
            key: "status",
            label: "Status",
            component: SurveyStatusFilter,
          },
          {
            key: "type",
            label: "Type",
            component: applyDefault(
              SurveyTypeFilter,
              {
                types: surveyTypes,
              }
            ),
          },
        ]}
        displayCount={true}
        count={count}
        countLoading={countLoading}
        onChange={onModifyFilter}
        onApply={onApply}
        values={values}
      />
    );
  }
}

function mapStateToProps(state: State, ownProps: SurveyListContainerOwnProps): SurveyListFiltersContainerStateProps {
  const innerFilterValues = getFilter(state);

  /*
      When loading the component for the first time, the inner values are empty,
      so active filters are the one from the props.
   */
  const activeValues = getOr(innerFilterValues, getOr(ownProps.values, {}));
  /*
      Try to get the count based on active filter
   */
  const key = SurveyInfoResource.generateKey(activeValues);
  const count = getExistingCount(state, SurveyInfoResource.TYPE, key);

  return {
    innerValues: getOr(innerFilterValues, {}),
    count: Store.Count.isLoaded(count) ? count.count : 0,
    countResource: count,
    countLoading: not(Store.Count.isLoaded(count)),
  };
}

const mapDispatchToProps = {
  onFetchCountIfNeeded: fetchSurveyInfosCountIfNeeded,
  onModifyFilter: surveyListFiltersActionCreators.modifyFilter,
  onCleanFilter: surveyListFiltersActionCreators.cleanFilter,
};

export const SurveyListFiltersContainer = connect(mapStateToProps, mapDispatchToProps)(SurveyListFilters);
