import React from "react";
import { StackedBarGraph } from "@components/core/StackedBarGraph";
import { BarSegmentData } from "@components/core/StackedBarGraph/components/BarSegment";
import { AnonymityFilterExplanation } from "hyphen-lib/dist/domain/common/AnonymityFilterExplanation";
import { InlineAnonymityFiltered } from "@components/core/InlineAnonymityFiltered";
import styled from "styled-components";
import { ChartContainer, BarContainer, BarLabel } from "./components/ChartComponents";

export interface BarChartDimension {
  [segment: string]: BarChartSegment;
}

export interface BarChartSegment {
  readonly series: BarSegmentData[];
  readonly total: number;
  readonly filteredForAnonymity: boolean;
  readonly explanation?: AnonymityFilterExplanation;
}

export interface BarChartProps {
  data?: BarChartDimension;
  labelWidth?: string;
  anonymityThreshold: number;
  isTopicalSegment?: boolean;
  numberOfVotes?: number;
}

export default class BarChart extends React.Component<BarChartProps> {
  getParticipants(segment: BarChartSegment): number {
    const { series, filteredForAnonymity, explanation } = segment;
    if (filteredForAnonymity && explanation) {
      if ("numberOfVoters" in explanation) {
        return explanation.numberOfVoters;
      }

      return 0;
    }

    return  series.reduce((acc, curr) => {
      acc += curr.value;
      return acc;
    } , 0);
  }
  render() {
    const { data, labelWidth = "170px", anonymityThreshold ,isTopicalSegment=false, numberOfVotes } = this.props;

    if (!data) {
      return null;
    }

    return (
      <ChartContainer labelWidth={labelWidth}>
        {Object.keys(data).map((label: string, index: number) => {
          const { series, total, filteredForAnonymity, explanation } = data[label];

          if (filteredForAnonymity) {
            return (
              <BarContainer key={index}>
                <BarLabel
                  value={total}
                  label={label}
                  width={labelWidth}
                  participants={this.getParticipants(data[label])}
                  isTopicalSegment={isTopicalSegment}
                />
                <InlineAnonymityContainer>
                  <InlineAnonymityFiltered
                    explanation={explanation!}
                    anonymityThreshold={anonymityThreshold}
                    size={"normal"}
                  />
                </InlineAnonymityContainer>
              </BarContainer>
            );
          }
          return (
            <BarContainer key={index}>
              <BarLabel
                value={total}
                label={label}
                width={labelWidth}
                participants={this.getParticipants(data[label])}
                isTopicalSegment={isTopicalSegment}
              />
              <StackedBarGraph
                data={series}
                width={`calc(100% - ${labelWidth})`}
                height={"48px"}
                skipWidthAdjustment={series.length === 1}
                numberOfVotes = {numberOfVotes}
              />
            </BarContainer>
          );
        })}
      </ChartContainer>
    );
  }
}

const InlineAnonymityContainer = styled.span`
  margin-left: 10px;
`;
