import React from "react";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { Dimension } from "hyphen-lib/dist/domain/common/Dimensions";
import { Paragraph } from "@components/core/Typography";
import SelectLarge from "@components/core/SelectLarge";
import { SelectValue, SelectProps } from "antd/lib/select";
import Button from "@components/core/Button";
import { Icon } from "antd";
import { mapOr, getOr } from "hyphen-lib/dist/lang/Objects";
import AreYouSureModal from "@src/components/core/AreYouSureModal";
import { isEmpty } from "hyphen-lib/dist/lang/Arrays";
import { Trans } from "react-i18next";
import { sanitizeSegmentLabel } from "hyphen-lib/dist/domain/common/Dimensions";

export interface ScopeDimCellProps {
  readonly dimension: Dimension;
  readonly userSegments: string[];
  readonly onSelectDimensions: (segments: string[], isSelectAll: boolean) => void;
  readonly onCloseIconClick: () => void;
  readonly isInLocalState: boolean;
}

interface ScopeDimCellState {
  readonly showSegmentSelect: boolean;
  readonly selectedSegments: string[];
  readonly isModalVisible: boolean;
}

export class ScopeDimensionCell extends React.Component<ScopeDimCellProps, ScopeDimCellState> {
  private dropdownRef = React.createRef<HTMLDivElement>();
  constructor(props: ScopeDimCellProps) {
    super(props);
    const { userSegments, isInLocalState, dimension } = props;

    const segments = getOr(dimension.segments, []);
    this.state = {
      showSegmentSelect: false,
      selectedSegments: isInLocalState ? segments : [...userSegments],
      isModalVisible: false,
    };
  }

  componentDidUpdate(prevProps: ScopeDimCellProps , prevState: ScopeDimCellState) {
    const dropdownNode = this.dropdownRef.current;
    if (prevState.showSegmentSelect === false && this.state.showSegmentSelect === true && dropdownNode) {
      if (typeof dropdownNode.scrollIntoView === "function") {
        dropdownNode.scrollIntoView({ behavior: "smooth", inline: "start", block: "nearest" });
      }
    }
  }
  onToggleModalVisibility = (isModalVisible: boolean) => this.setState({ isModalVisible });

  onSelectChange = (values: SelectValue) => {
    this.setState({ selectedSegments: values as string[] });
  };

  preventMouseDown = (e: any) => {
    e.preventDefault();
  };

  selectAllClicked = () => {
    const { dimension } = this.props;
    this.setState({ selectedSegments: [...dimension.segments] });
  };

  unSelectAllClicked = () => {
    this.setState({ selectedSegments: [] });
  };

  onApply = () => {
    const { selectedSegments } = this.state;
    if (isEmpty(selectedSegments)) {
      this.setState({ showSegmentSelect: false });
      return this.onToggleModalVisibility(true);
    }
    this.applySelection();
  };

  applySelection = () => {
    const { onSelectDimensions, dimension, isInLocalState } = this.props;
    const { selectedSegments } = this.state;
    const isSelectAll = selectedSegments.length === dimension.segments.length;
    if (!isInLocalState || (isInLocalState && !isSelectAll)) {
      onSelectDimensions(selectedSegments, isSelectAll);
    }
    this.setState({ showSegmentSelect: false });
  };

  cancelSelection = () => {
    if (document.hasFocus()) {
      const { userSegments, isInLocalState, dimension } = this.props;
      const segments = getOr(dimension.segments, []);
      this.setState({
        showSegmentSelect: false,
        selectedSegments: isInLocalState ? segments : [...userSegments],
      });
    }
  };

  renderDropDown = (menu: any) => {
    const { dimension } = this.props;
    const { selectedSegments } = this.state;
    const segments = getOr(dimension.segments, []);
    const isSelectedAll = segments.length === selectedSegments.length;
    return (
      <>
        {
          segments.length > 0 && (
            <ActionsTop onMouseDown={this.preventMouseDown}>
              {
                isSelectedAll ? (
                  <Paragraph weight="bold" onClick={this.unSelectAllClicked} translate="yes">
                    Unselect All</Paragraph>
                ) : (
                  <Paragraph weight="bold" onClick={this.selectAllClicked} translate="yes">
                    Select All</Paragraph>
                )
              }
            </ActionsTop>
          )
        }
        {menu}
        <Actions onMouseDown={this.preventMouseDown}>
          <Paragraph weight="bold" onClick={this.cancelSelection} translate="yes">
            Cancel</Paragraph>
          <Button color="blue" data-cy="segments_apply" onClick={this.onApply} translate="yes">
            Apply</Button>
        </Actions>
      </>
    );
  };

  toggleSegmentSelect = () => {
    this.setState((prevState) => {
      return {
        showSegmentSelect: !prevState.showSegmentSelect,
      };
    });
  };

  onOk = () => {
    this.applySelection();
    this.onToggleModalVisibility(false);
  };

  handleCancel = () => {
    const { isInLocalState, userSegments, dimension } = this.props;
    this.setState({
      selectedSegments: isInLocalState ? dimension.segments : [...userSegments],
    });
    this.onToggleModalVisibility(false);
  };

  render() {
    const { dimension, onCloseIconClick, isInLocalState } = this.props;
    const { showSegmentSelect, selectedSegments, isModalVisible } = this.state;
    const availableValues = mapOr(
      dimension.segments,
      segments => segments.map(
        segment => ({ value: segment, label: segment })
      ),
      []
    );

    let toDisplay;
    if (selectedSegments.length > 0) {
      if (selectedSegments.length === 1) {
        toDisplay = sanitizeSegmentLabel(selectedSegments[0]);
      } else {
        toDisplay = sanitizeSegmentLabel(`${selectedSegments[0]} and ${selectedSegments.length - 1} more`);
      }
    } else {
      toDisplay = "None";
    }

    if (availableValues.length > 0 &&  (isInLocalState || selectedSegments.length === availableValues.length)) {
      toDisplay = "All";
    }

    return (
      <DimensionCell ref={this.dropdownRef}>
        {
          showSegmentSelect ? (

            <StyledSelect
              label={dimension.label}
              placeholder={<Trans>Select</Trans>}
              onChange={this.onSelectChange}
              mode="multiple"
              autoFocus={true}
              open={showSegmentSelect}
              onBlur={this.cancelSelection}
              selectedValues={selectedSegments}
              dropdownRender={this.renderDropDown}
              data={availableValues.map(val => val.label)}
              maxTagCount={10}
              numberOfValuesToShow={50}
            />
          ) : (
            <>
              <DimensionNameCell>
                <DimensionNameLabelCell>
                  {dimension.label}
                </DimensionNameLabelCell>
                <CloseIcon type="close" onClick={onCloseIconClick} />
              </DimensionNameCell>
              <DimensionValuesCell onClick={this.toggleSegmentSelect}>
                {toDisplay}
              </DimensionValuesCell>
            </>
          )
        }
        {isModalVisible && (
          <AreYouSureModal
            visible={isModalVisible}
            title="Are you sure?"
            /* eslint-disable-next-line max-len */
            description="If you remove all segments for a dimension, then the user will not be able to see any other users. Are you sure you want to do this?"
            onCancel={this.handleCancel}
            onOk={this.onOk}
            okLabel="Ok"
          />
        )}
      </DimensionCell>
    );
  }
}

const StyledSelect = styled(SelectLarge)<SelectProps>`
  width: 500px;

  .ant-select-open.ant-select-enabled {
    z-index: 1;
  }
  .ant-select-open .ant-select-selection {
    max-height: 150px;
    overflow: scroll;
  }
  .ant-form-item-control { height: 150px;}

`;

const DimensionCell = styled.div`
  flex-direction: column;
  padding-right: 24px;
  min-width: 220px;
  max-width: 220px;
  position: relative;
`;

const CloseIcon = styled(Icon)`
  cursor: pointer;
  align-self: center;
  font-size: 12px;
`;

const DimensionNameCell = styled.div`
  display: flex;
  color: ${Palette.bluishGrey};
  font-size: 14px;
  line-height: 20px;
`;

const DimensionNameLabelCell = styled.div`
  flex: 1;
`;

const DimensionValuesCell = styled.div`
  color: ${Palette.veryDarkBlueGrey};
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  margin-top: 6px;
  :hover {
    text-decoration: underline;
  }
`;

const Actions = styled.div`
  box-shadow: 0 -1px 0 0 ${Palette.lightPeriwinkle};
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  align-items: center;
  > p {
    margin-right: 16px;
    color: ${Palette.darkModerateBlue} !important;
    cursor: pointer;
  }

  button {
    height: 30px !important;
  }
`;

const ActionsTop = styled.div`
  box-shadow: 0 0 1px 0 ${Palette.lightPeriwinkle};
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  align-items: center;

  > p {
    margin-right: 16px;
    color: ${Palette.darkModerateBlue} !important;
    cursor: pointer;
  }
`;

