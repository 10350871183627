import React from "react";
import styled from "styled-components";
import { Map as ImmutableMap } from "immutable";

import { Dictionary } from "@hyphen-lib/domain/structure/Dictionary";
import {
  addPropertyInDepth,
  getOr,
  getProperty,
  isNotNullNorUndefined,
  subObject
} from "@hyphen-lib/lang/Objects";
import { not } from "@hyphen-lib/lang/Booleans";
import { isNotEmptyArray } from "@hyphen-lib/lang/Arrays";
import { collectInMap } from "@hyphen-lib/lang/Maps";

export interface ViewOptionDefinition {
  readonly key: string;
  readonly label: string;
  readonly component: React.ComponentType<BaseViewOptionProps<any>>;
}

export interface BaseViewOptionProps<V> {
  readonly label: string;
  readonly value?: V;
  readonly onChange: (value: V) => void;
  readonly isDisabled?: boolean;
}

export type ViewOptionsContentProps<F extends Dictionary<any>> =
  ViewOptionsContentCommonPropsProps<F>;

export interface ViewOptionsContentCommonPropsProps<F extends Dictionary<any>> {
  readonly viewOptions: ViewOptionDefinition[];
  readonly values?: Partial<F>;
  readonly onChange?: (viewOptions: Partial<F>) => any;
}

export interface ViewOptionsContentState<F extends Dictionary<any>> {
  readonly values: Partial<F>;
  readonly viewOptionsMap: ImmutableMap<string, ViewOptionDefinition>;
}

export class ViewOptionsContent<F extends Dictionary<any>>
  extends React.Component<ViewOptionsContentProps<F>, ViewOptionsContentState<F>> {

  constructor(props: ViewOptionsContentProps<F>) {
    super(props);

    this.state = {
      values: getOr(props.values, {}),
      viewOptionsMap: collectInMap("key", props.viewOptions),
    };
  }

  cleanViewOptions(viewOptions: Partial<F>): Partial<F> {
    // remove undefined and null in viewOptions, and also skip empty arrays
    return subObject(
      viewOptions,
      (val: any) => isNotNullNorUndefined(val) &&
        (not(Array.isArray(val)) || isNotEmptyArray(val))
    ) as Partial<F>;
  }

  notifyOnChange() {
    const { onChange } = this.props;
    const { values } = this.state;

    if (isNotNullNorUndefined(onChange)) {
      onChange(this.cleanViewOptions(values));
    }
  }

  handleChangeViewOptionValue(key: keyof F) {
    return (value: any) => {
      this.setState(oldState =>
        ({
          values: addPropertyInDepth(
            {
              ...oldState.values,
            },
            value,
            key
          ) as Partial<F>,
        }),
      this.notifyOnChange
      );
    };
  }

  render() {
    const { viewOptions } = this.props;
    const { values, viewOptionsMap } = this.state;

    return (
      <div>
        {
          viewOptions.length > 0 &&
          <ViewOptionComponentsContainer>
            {
              viewOptions
                .map((viewOption: ViewOptionDefinition) => viewOptionsMap.get(viewOption.key))
                .filter(isNotNullNorUndefined)
                .map((viewOption: ViewOptionDefinition) => (
                  <viewOption.component
                    key={viewOption.key}
                    label={viewOption.label}
                    onChange={this.handleChangeViewOptionValue(viewOption.key)}
                    value={getProperty(values, viewOption.key)}
                  />
                ))
            }
          </ViewOptionComponentsContainer>
        }
      </div>
    );
  }
}

const ViewOptionComponentsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 22px;
  align-items: flex-start;

  & > div {
    min-width: 200px;
    margin-right: 16px;
    margin-bottom: 16px;
  }
`;
