import React from "react";
import styled from "styled-components";
import { Trans } from "react-i18next";
import { SurveyHighlight, PulsePollHighlight } from "@hyphen-lib/domain/resource/report/common/Highlights";
import { isNotNullNorUndefined, getOr } from "@hyphen-lib/lang/Objects";
import { formatDate } from "@hyphen-lib/lang/Dates";
import { calculatePercentage } from "@hyphen-lib/util/math/Math";
import Palette from "@src/config/theme/palette";
import { Progress } from "@components/core/Progress";

interface Props {
  data: SurveyHighlight | PulsePollHighlight;
}

const Activity = ({ data: { name, launchedAt, participation, status , closedAt } }: Props) => {
  const percentage = calculatePercentage(
    getOr(participation.completed, 0 as number),
    participation.total
  );

  const renderDate = (statusSurvey: string,launchedAtSurvey?: Date, closedAtSurvey?: Date | null): any => {
    if (isNotNullNorUndefined(closedAtSurvey)) {
      return <InfoLabel>
        <Trans i18nKey="closedOn" values={{date:formatDate(closedAtSurvey)}}
          defaults={`Closed on ${formatDate(closedAtSurvey)}`}/>
        </InfoLabel>;
    } else if (isNotNullNorUndefined(launchedAtSurvey)) {
      return <InfoLabel>
        <Trans i18nKey="launchedOn" values={{date:formatDate(launchedAtSurvey)}}
          defaults={`Launched on ${formatDate(launchedAtSurvey)}`}/>
        </InfoLabel>;
    }
  };

  return (
    <Container>
      <div>
        <Text>{name}</Text>
        {renderDate(status,launchedAt,closedAt)}
      </div>
      <ParticipationProgress>
        <Progress percent={percentage}/>
        <ParticipationLabel>
          {percentage}% ({participation.completed} / {participation.total})
        </ParticipationLabel>
      </ParticipationProgress>
    </Container>
  );
};

const Text = styled.div`
  max-width: 450px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Container = styled.div`
  color: ${Palette.veryDarkBlueGrey} !important;
  border: 1px solid ${Palette.lightPeriwinkle};
  border-radius: 3px;
  font-family: Lato, sans-serif;
  font-size: 14px;
  line-height: 1.43;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  min-height: 55px;
    &:hover ${Text}{
      cursor: pointer;
      border-color: ${Palette.darkModerateBlue};
      white-space: normal !important;
}
`;

const InfoLabel = styled.div`
  color: ${Palette.bluishGrey};
`;

const ParticipationProgress = styled.div`
  width: 130px;
  display: flex;
  flex-direction: column;
`;

const ParticipationLabel = styled.div`
  margin-top: 4px;
  font-family: Lato, sans-serif;
  color: ${Palette.veryDarkBlueGrey};
  text-align: center;
`;

export default Activity;
