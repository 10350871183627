import { isNotNullNorUndefined, getOr } from "hyphen-lib/dist/lang/Objects";
import { isStringAndNotEmpty } from "@hyphen-lib/lang/Strings";
import { Config } from "hyphen-lib/dist/util/Config";
import { loadSessionToken } from "@src/utils/sessionStores";
import { saveAs } from "file-saver";
import { LocaleCodes } from "hyphen-lib/dist/util/locale";

import { FilterParameter, getAssetUrl } from "@src/utils/networks";
import { generateQueryString } from "hyphen-lib/dist/util/net/HttpClient";

const config = Config.getInstance();
const backendURLPrefix = config.get("BACKEND_BASE_URL");

const extractFileName = (disposition: string | null) => {
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(disposition as string);
  let filename = disposition;
  if (matches != null && matches[1]) {
    filename = matches[1].replace(/['"]/g, "");
  }
  return filename;
};

const createExportRequest = (
  url: string,
  appliedFilters: string,
  exportName: string,
  method = "GET",
  skipDownload = false
) => {
  const token = loadSessionToken();
  let fileName = "";
  return new Promise((resolve, reject) => {
    fetch(url + appliedFilters, {
      method,
      headers: {
        "x-session-token": isNotNullNorUndefined(token) ? token : "",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        fileName = getOr(
          extractFileName(response.headers.get("content-disposition")),
          exportName
        );
        return response.blob();
      })
      .then((blob) => {
        if (!skipDownload) {
          saveAs(blob, fileName);
        }
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        // resolve(URL.createObjectURL(blob));
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createExportSurveyIndividualResultsRequest = (
  surveyId: string,
  filters: string
) => {
  const url = `${backendURLPrefix}/surveys/${surveyId}/reports/individualResults/export`;

  createExportRequest(url, filters, `individualResults-${new Date()}.xlsx`);
};

export const createExportSurveyFullOverviewReportRequest = (
  surveyId: string,
  filters: string
) => {
  const url = `${backendURLPrefix}/insights/surveys/${surveyId}/export`;
  // tslint:disable-next-line: no-console
  createExportRequest(url, filters, `surveyReport-${new Date()}.csv`, "POST");
};

export const createExportSurveyCommentsRequest = (
  surveyId: string,
  questionId: string | undefined,
  filters: string,
  translate = false
) => {
  const url = `${backendURLPrefix}/surveys/${surveyId}/reports/comments/export`;
  let parameters = `${isStringAndNotEmpty(filters) ? filters + "&" : "?"}format=excel&translate=${translate}`;
  if (isNotNullNorUndefined(questionId)) {
    parameters += `&questionId=${questionId}`;
  }
  createExportRequest(
    url,
    parameters,
    `surveyComments-${new Date()}.xlsx`
  );
};

export const createExportSurveyTopicsRequest = (
  surveyId: string,
  filters: string
) => {
  const url = `${backendURLPrefix}/surveys/${surveyId}/reports/comments/export`;

  createExportRequest(
    url,
    `${isStringAndNotEmpty(filters) ? filters + "&" : "?"}format=excel`,
    `surveyComments-${new Date()}.xlsx`
  );
};

export const createExportSurveyParticipationRequest = (
  surveyId: string,
  filters: string
) => {
  const url = `${backendURLPrefix}/surveys/${surveyId}/reports/participation/export`;

  createExportRequest(
    url,
    `${isStringAndNotEmpty(filters) ? filters + "&" : "?"}format=excel`,
    `surveyParticipation-${new Date()}.xlsx`
  );
};

export const createExportSurveyQuestionsRequest = (
  surveyId: string,
  filters: string
) => {
  const url = `${backendURLPrefix}/surveys/${surveyId}/reports/export/questions`;

  createExportRequest(
    url,
    `${isStringAndNotEmpty(filters) ? filters + "&" : "?"}format=excel`,
    `surveyQuestions-${new Date()}.xlsx`
  );
};

export const createExportSurveyHeatmapRequest = (
  surveyId: string,
  filters: string
) => {
  const url = `${backendURLPrefix}/surveys/${surveyId}/reports/heatmap/export`;

  createExportRequest(
    url,
    `${isStringAndNotEmpty(filters) ? filters + "&" : "?"}format=excel`,
    `surveyHeatmap-${new Date()}.xlsx`
  );
};

export const createExportUserListRequest = (filters: string) => {
  const url = `${backendURLPrefix}/users/export`;

  createExportRequest(
    url,
    `${isStringAndNotEmpty(filters) ? filters + "&" : "?"}format=csv`,
    `users-${new Date()}.csv`
  );
};

export const createExportVoicePostsRequest = (filters: string) => {
  const url = `${backendURLPrefix}/voice/reports/posts/export`;
  return createExportRequest(
    url,
    `${isStringAndNotEmpty(filters) ? filters + "&" : "?"}format=excel`,
    `voice-reports-posts-${new Date()}.xlsx`
  );
};

export const createExportVoiceFlaggedPostsRequest = (filters: string) => {
  const url = `${backendURLPrefix}/voice/reports/posts/flagged/export`;
  return createExportRequest(
    url,
    `${isStringAndNotEmpty(filters) ? filters + "&" : "?"}format=excel`,
    `voice-reports-flagged-posts-${new Date()}.xlsx`
  );
};

export const createExportVoiceCommentsRequest = (filters: string) => {
  const url = `${backendURLPrefix}/voice/reports/comments/export`;
  return createExportRequest(
    url,
    `${isStringAndNotEmpty(filters) ? filters + "&" : "?"}format=excel`,
    `voice-reports-comments-${new Date()}.xlsx`
  );
};

export const createExportVoiceFlaggedCommentsRequest = (filters: string) => {
  const url = `${backendURLPrefix}/voice/reports/comments/flagged/export`;
  return createExportRequest(
    url,
    `${isStringAndNotEmpty(filters) ? filters + "&" : "?"}format=excel`,
    `voice-reports-flagged-comments-${new Date()}.xlsx`
  );
};

export const createExportSurveyTrendsOverviewRequest = (
  surveyId: string,
  filters: string
) => {
  const url = `${backendURLPrefix}/surveys/${surveyId}/reports/linkedSurveyTrend/export`;

  return createExportRequest(
    url,
    `${isStringAndNotEmpty(filters) ? filters + "&" : "?"}format=excel`,
    `survey-trends-overview-${new Date()}.xlsx`
  );
};

export const createActionPlanReportRequest = (filters: string) => {
  const url = `${backendURLPrefix}/action/reports`;
  // tslint:disable-next-line: no-console
  createExportRequest(
    url,
    filters,
    `actionPlanExport-${new Date()}.csv`,
    "GET"
  );
};

export const downloadSurveyLanguageTemplate = (
  surveyId: string,
  language: LocaleCodes
) => {
  const url = `${backendURLPrefix}/surveys/${surveyId}/multilanguage/template`;
  createExportRequest(
    url,
    "?format=csv",
    `${language}_${surveyId}_survey-template.csv`,
    "GET"
  );
};

export const downloadSurveyUploadErrorCSV = (
  surveyId: string,
  language: LocaleCodes,
  assetId: string
) => {
  const url = `${backendURLPrefix}/surveys/${surveyId}/multilanguage/${assetId}/getErrorFile`;
  createExportRequest(
    url,
    "?format=csv",
    `${language}_${surveyId}_error.csv`,
    "GET"
  );
};

export const downloadUsersInPermissionScope = (
  rootUserEmail: string,
  filters?: FilterParameter
) => {
  const url = `${backendURLPrefix}/permissions/scope/${rootUserEmail}`;
  let queryFilter: { format: string; filter?: FilterParameter } = {
    format: "csv",
  };

  if (isNotNullNorUndefined(filters)) {
    queryFilter = {
      ...queryFilter,
      filter: filters,
    };
  }

  createExportRequest(
    url,
    `?${generateQueryString(queryFilter)}`,
    `${rootUserEmail}_permissions.csv`,
    "GET"
  );
};

export const fetchAssetById = (
  assetId: string,
  filter = "",
  fileName = "",
  skipDownload = false
) => {
  const url = getAssetUrl(assetId);
  return createExportRequest(url, filter, fileName, "GET", skipDownload);
};

export const downloadUsersTemplate = () => {
  const url = `${backendURLPrefix}/users/export/template`;

  createExportRequest(
    url,
    "?format=csv",
    "template.csv"
  );
};

export const downloadSurveyRecipients = (surveyId: string) => {
  const url = `${backendURLPrefix}/surveys/${surveyId}/participants/export`;
  createExportRequest(
    url,
    "?format=csv",
    "recipients.csv"
  );
};


export const downloadPulsePollsRecipients = (pulsePollId: string) => {
  const url = `${backendURLPrefix}/pulsePolls/${pulsePollId}/participants/export`;
  createExportRequest(
    url,
    "?format=csv",
    "recipients.csv"
  );
};