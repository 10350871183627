import React from "react";
import Modal from "@components/core/Modal";
import { Seq } from "immutable";
import styled from "styled-components";
import { SearchBar } from "@src/components/core/SearchBar";
import { ParticipantResource } from "hyphen-lib/dist/domain/resource/participants/ParticipantResource";
import { ModalProps } from "antd/lib/modal";
import AudienceEmailsModalContent from "./AudienceEmailsModalContent";
import { Trans } from "react-i18next";

interface Props {
  modalOpen: boolean;
  handleCancel: () => void;
  surveyParticipants: ParticipantResource[];
  isAudienceLoading: boolean;
}

export const AudienceEmailsModalComponent = styled(Modal)<ModalProps & {children: React.ReactNode}>`
  width: 740px !important;
`;

export default class AudienceEmailsModal extends React.Component<Props> {

  state = {
    searchText: "",
  };

  getFilteredAudience = () : ParticipantResource[] => {
    const { surveyParticipants } = this.props;
    const { searchText } = this.state;
    return Seq(surveyParticipants)
      .filter(participant =>
        participant.email.toLocaleLowerCase().indexOf(searchText.toLowerCase()) > -1
      )
      .toArray();
  };

  onSearchChange = (e: any) => {
    this.setState({ searchText: e.target.value });
  };

  render () {
    const { modalOpen, handleCancel, isAudienceLoading } = this.props;
    return (
      <AudienceEmailsModalComponent
        footer={null}
        visible={modalOpen}
        title={<Trans>List of All Recipients</Trans>}
        centered={true}
        onCancel={handleCancel}>
        <SearchBarContainer>
          <SearchBar placeholder="Search" onChange={this.onSearchChange}/>
        </SearchBarContainer>
        {isAudienceLoading ? <Trans>Loading...</Trans> : 
        <AudienceEmailsModalContent audience={this.getFilteredAudience ()} />}
      </AudienceEmailsModalComponent>
    );
  }
}

export const SearchBarContainer = styled.div`
  padding: 24px 32px 12px;
`;
