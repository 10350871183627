import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import styled from "styled-components";

import { Dictionary } from "@hyphen-lib/domain/structure/Dictionary";
import { areEquals, isNotNullNorUndefined } from "@hyphen-lib/lang/Objects";
import SearchBarSection from "@screens/Insights/Survey/components/SearchBarSection";
import SurveyReportHeader from "@screens/Insights/Survey/components/SurveyReportHeader";
import {
  filterCategories,
  sortCategories
} from "hyphen-lib/dist/business/calculation/categories/CategoriesReportResources";
import ViewOptions, { getViewOptionValuesFromLocation } from "@screens/Insights/components/ViewOptions";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { CompareWithOption } from "@screens/Insights/components/ViewOptions/components/CompareWith";
import { PaginationConfig, SorterResult } from "antd/lib/table";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { SortParameter } from "@src/utils/networks";
import { CategoriesReportResource } from "hyphen-lib/dist/domain/resource/report/CategoriesReportResource";
import { AnonymityFiltered } from "@components/core/AnonymityFiltered";
import { getViewOptionDefinitions, mapViewOptionsToState } from "@src/utils/ViewOptions";
import { areArraysEqual, isEmpty } from "hyphen-lib/dist/lang/Arrays";
import { Participation } from "hyphen-lib/dist/domain/common/Participation";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import CategoriesTable from "./components/CategoriesTable";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { QuestionConfig } from "hyphen-lib/dist/domain/common/QuestionType";

interface OwnProps {
  readonly participation: Participation;
  readonly sort: SortParameter;
  readonly freeTextFilter: string;
  readonly surveyName: string;
  readonly enabledFilters: string[];
  readonly enabledCustomFilters?: string[];
  readonly enabledViewOptions: string[];
  readonly categories: Loadable<CategoriesReportResource>;
  readonly hasActionCreationRight: boolean;
  readonly hasTopicsAccess: boolean;
  readonly postAndSurveySentiment?: boolean;
  readonly anonymityThreshold: number;
  readonly isFiltersAvailable?: boolean;
  readonly compareWithOptions: CompareWithOption[];
  readonly surveyId: string;
  readonly questionConfig: QuestionConfig;
  readonly onTableChange?: (___: PaginationConfig, __: any, sorter: SorterResult<any>) => void;
  readonly onCreateFocusArea: (
    focusArea: Partial<FocusAreaResource>
  ) => void;
  readonly page: PageFilter;
}

type Props = OwnProps & RouteComponentProps;

interface CategoriesReportState {
  readonly viewOptions: {
    readonly comparison: boolean;
    readonly compareWith: Optional<string>;
  };
}

export class CategoriesReport extends React.Component<Props, CategoriesReportState> {
  constructor(props: Props) {
    super(props);

    const viewOptions = getViewOptionValuesFromLocation(props.location);

    this.state = mapViewOptionsToState(
      viewOptions,
      props.compareWithOptions,
      this.getDefaultViewOptionValues()
    );
  }

  componentDidUpdate(prevProps: Props): void {
    const prevViewOptions = parseQueryString(prevProps.location.search).viewOptions;
    const currentViewOptions = parseQueryString(this.props.location.search).viewOptions;
    if (not(areEquals(prevViewOptions, currentViewOptions)) ||
      not(areArraysEqual(prevProps.compareWithOptions, this.props.compareWithOptions)) ||
      not(areArraysEqual(prevProps.enabledViewOptions, this.props.enabledViewOptions))) {
      this.setState(
        mapViewOptionsToState(
          getViewOptionValuesFromLocation(this.props.location),
          this.props.compareWithOptions,
          this.getDefaultViewOptionValues()
        )
      );
    }
  }

  getDefaultViewOptionValues = () => {
    return {
      comparison: true,
    };
  };

  handleViewOptionsChange = (viewOptions: Dictionary<any>) => {
    this.setState(
      mapViewOptionsToState(
        viewOptions,
        this.props.compareWithOptions,
        this.getDefaultViewOptionValues()
      )
    );
  };

  getViewOptionsComponent = (enabledViewOptions: string[],
    compareWithOptions: CompareWithOption[]): React.ReactNode => {

    const viewOptions = getViewOptionDefinitions(enabledViewOptions, compareWithOptions);
    if (isEmpty(viewOptions)) {
      return null;
    }

    return (
      <ViewOptions
        viewOptions={viewOptions}
        defaultValues={this.state.viewOptions}
        onChange={this.handleViewOptionsChange}
      />
    );
  };

  onChange = (pagination: PaginationConfig, data: any, sorter: SorterResult<any>) => {
    const { onTableChange } = this.props;

    if (isNotNullNorUndefined(onTableChange)) {
      onTableChange(pagination, data, sorter);
    }
  };

  render() {
    const {
      surveyName,
      participation,
      categories,
      enabledFilters,
      enabledCustomFilters,
      enabledViewOptions,
      compareWithOptions,
      sort,
      freeTextFilter,
      hasActionCreationRight,
      hasTopicsAccess,
      anonymityThreshold,
      surveyId,
      page,
      location,
      match,
      history,
      postAndSurveySentiment
    } = this.props;

    let { isFiltersAvailable } = this.props;
    const routeProps = { location, match, history };

    isFiltersAvailable = isNotNullNorUndefined(isFiltersAvailable) ? isFiltersAvailable : false;

    const {
      viewOptions,
    } = this.state;

    let sortedCategories: CategoriesReportResource.Category[] = [];
    if (Loadable.isLoaded(categories)) {
      const filteredCategories = filterCategories(freeTextFilter, categories.value.categories);
      sortedCategories = sortCategories(
        filteredCategories,
        sort,
        viewOptions.compareWith
      );
    }

    return (
      <div>
        <SurveyReportHeader
          participation={participation}
          enabledFilters={enabledFilters}
          enabledCustomFilters={enabledCustomFilters}
          viewOptionsComponent={this.getViewOptionsComponent(enabledViewOptions, compareWithOptions)}
        />
        {
          this.renderCategoriesSection(
            categories,
            surveyName,
            sortedCategories,
            viewOptions,
            compareWithOptions,
            sort,
            hasActionCreationRight,
            hasTopicsAccess,
            isFiltersAvailable,
            anonymityThreshold, 
            surveyId,
            page,
            routeProps,
            postAndSurveySentiment
          )
        }
      </div>
    );
  }

  renderCategoriesSection(categories: Loadable<CategoriesReportResource>,
    surveyName: string,
    sortedCategories: CategoriesReportResource.Category[],
    viewOptions: CategoriesReportState["viewOptions"],
    compareWithOptions: CompareWithOption[],
    sort: SortParameter,
    hasActionCreationRight: boolean,
    hasTopicsAccess: boolean,
    isFiltersAvailable: boolean,
    anonymityThreshold: number,
    surveyId: string,
    page: PageFilter,
    routeProps: RouteComponentProps,
    postAndSurveySentiment?: boolean
    ) {

    if (Loadable.isLoaded(categories) && categories.value.filteredForAnonymity) {
      return (
        <CategoriesContainer>
          <AnonymityContainer>
            <AnonymityFiltered
              explanation={categories.value.filteredForAnonymityReason!}
              anonymityThreshold={anonymityThreshold}
              translate="yes"
            />
          </AnonymityContainer>
        </CategoriesContainer>
      );
    }

    return (
      <CategoriesContainer>
        <SearchBarSection
          placeholder="Search categories"
          queryParamPath="freeText"
        />
        {
          Loadable.isNotLoaded(categories) ?
            <LoadingContainer>Loading...</LoadingContainer> :
            <CategoriesTable
              isFiltersVisible={isFiltersAvailable}
              onChange={this.onChange}
              tableName={`${surveyName} - Categories report`}
              categories={sortedCategories}
              areComparisonsVisible={viewOptions.comparison}
              compareWithOptions={compareWithOptions}
              comparisonKey={viewOptions.compareWith}
              sort={sort}
              hasActionCreationRight={hasActionCreationRight}
              hasTopicsAccess={hasTopicsAccess}
              anonymityThreshold={anonymityThreshold}
              onCreateFocusArea={this.props.onCreateFocusArea}
              focusAreaLabel={surveyName}
              surveyId={surveyId}
              page={page}
              postAndSurveySentiment={postAndSurveySentiment}
              questionConfig={this.props.questionConfig}
              {...routeProps}
            />
        }
      </CategoriesContainer>
    );
  }
}

const CategoriesContainer = styled.div`
  margin-top: 24px;
`;

const LoadingContainer = styled.div`
  text-align: center;
  background: white;
  padding: 24px;
`;

const AnonymityContainer = styled.div`
  margin: 24px 0;
  padding: 24px 0;
  background: white;
`;

export default withRouter(CategoriesReport);
