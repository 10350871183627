import React from "react";
import styled from "styled-components";
import { Trans } from "react-i18next";
import { RadioChangeEvent } from "antd/lib/radio";
import Radio from "@components/core/Radio";
import { Paragraph } from "@components/core/Typography";
import ContainerCard from "@components/core/ContainerCard";
import { Row, Col as AntCol, Spin } from "antd";
import { ColProps } from "antd/lib/col";
import { SurveyTemplateResource } from "@hyphen-lib/domain/resource/SurveyTemplateResource";
import Select from "@components/core/Select";
import {SurveyInfoResource} from "hyphen-lib/dist/domain/resource/SurveyInfoResource";
import { isNullOrEmpty } from "hyphen-lib/dist/lang/Strings";
import Checkbox from "@components/core/Checkbox";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { SelectProps } from "antd/lib/select";
import { Seq } from "immutable";
import TemplateCard from "./TemplateCard";

const RadioGroup = Radio.Group;

const { Option } = Select;

const Container = styled(ContainerCard)`
  > p {
    margin-top: 8px;
    max-width: 680px;
  }

  > div {
    margin-top: 28px;
  }
`;

const Col = styled(AntCol)<ColProps>`
  margin-top: 15px;
`;

export type Modes = "scratch" | "templates" | "duplicate";

interface Props {
  mode: Modes;
  selectedTemplateId?: string;
  isTemplatesLoading: boolean;
  templates: SurveyTemplateResource[];
  onRadioChange: (e: RadioChangeEvent) => void;
  onLinkDuplicateChange: (e: CheckboxChangeEvent) => void;
  onSelectTemplate: (selectedTemplateId: string) => void;
  onSelectDuplicateChange: (duplicateTemplateId: string) => void;
  duplicateSurveyId?: string;
  onShowModal: (selectedTemplateId: string) => void;
  allSurveys: Partial<SurveyInfoResource>[];
  loadingAllSurveys: boolean;
  linkDuplicate: boolean;
}

const SurveyTypeSelection = (props: Props) => {
  const {
    templates,
    onRadioChange,
    mode,
    selectedTemplateId,
    onSelectTemplate,
    onShowModal,
    isTemplatesLoading,
    allSurveys,
    duplicateSurveyId,
    onSelectDuplicateChange,
    loadingAllSurveys,
    linkDuplicate,
    onLinkDuplicateChange,
  } = props;

  const onDuplicateSelectChange = (value: any) => {
    onSelectDuplicateChange(value as string);
  };

  return (
    <Container
      title="Select a template or create from scratch to get started">
      <Paragraph id="surveyTemplateDescription">
        <Trans>Use our expert created survey templates to launch employee surveys quickly and unlock
        access to industry benchmark data. You can still create surveys from scratch
        but those survey results will not include industry benchmark insights.</Trans>
      </Paragraph>
      <RadioGroup onChange={onRadioChange} value={mode}>
        <Radio data-cy="surveyCreation_gettingStarted_scratch" value="scratch">
          <Trans>Create your own survey from scratch</Trans>
        </Radio>
        <Radio data-cy="surveyCreation_gettingStarted_duplicate" value="duplicate">
          <Trans>Duplicate an existing survey</Trans>
        </Radio>
        <Radio data-cy="surveyCreation_gettingStarted_template" value="templates">
          <Trans>Select a template</Trans>
        </Radio>
      </RadioGroup>
      {mode === "templates" && (
        <Row type="flex" justify="start" gutter={18} style={{ marginTop: 9 }}>
          {isTemplatesLoading ?
            (
              <Row type="flex" justify="center" style={{ width: "100%", marginTop: 15 }}><Spin /></Row>
            )
            :
            Seq(templates)
              .reverse()
              .map((template, index) => (
                <Col
                data-cy={`surveyTemplate_${index}`}
                  key={`template${index}`}
                  lg={{ span: 8 }} xl={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }} style={{ marginTop: "15px" }}>
                  <TemplateCard
                    // tslint:disable-next-line:jsx-no-lambda
                    onTemplateClick={() => onSelectTemplate(template._id)}
                    selected={template._id === selectedTemplateId}
                    imageKey={template.imageKey}
                    hasBenchmark={template.hasBenchmark}
                    description={template.description}
                    title={template.name}
                    posts={template.posts}
                    key={template._id}
                    // tslint:disable-next-line:jsx-no-lambda
                    onPreviewClick={() => onShowModal(template._id)}
                  />
                </Col>
              ))
          }
        </Row>
      )}
      {
        mode === "duplicate" && (
          <Row type="flex" gutter={18} style={{ marginTop: 9, marginLeft: 24 }}>
            {
              loadingAllSurveys ? (
                <Row type="flex" justify="center" style={{ width: "100%", marginTop: 15 }}><Spin /></Row>
              ) :
                (
                  <>
                    <Row type="flex" justify="start" style={{ width: "100%" }}>
                      <Col span={12}>
                        <StyledSelect
                          label={<Trans>Select the survey to duplicate</Trans>}
                          value={duplicateSurveyId}
                          onChange={onDuplicateSelectChange}
                          placeholder={<Trans>Select</Trans>}
                          data-cy="surveyCreation_gettingStarted_duplicate"
                        >
                          {
                            allSurveys.map((survey) =>
                              (
                                <Option
                                  key={survey._id}
                                  value={survey._id}
                                >
                                  {isNullOrEmpty(survey.name) ? <Trans>No name</Trans> : survey.name}
                                </Option>
                              )
                            )
                          }
                        </StyledSelect>
                      </Col>
                    </Row>
                    <Row type="flex" justify="start" style={{ width: "100%", marginTop: 9, marginLeft: 8 }}>
                      <Checkbox
                        // eslint-disable-next-line max-len
                        info="Selecting this option will link your current survey to the existing survey you are copying. This will allow you to compare the data from this survey to the previous iteration in Survey Reports."
                        checked={linkDuplicate}
                        onChange={onLinkDuplicateChange}
                        data-cy="surveyCreation_duplicate_linkToPrevious"
                      >
                        <Trans>Link to this survey</Trans>
                      </Checkbox>
                    </Row>
                  </>
                )
            }

          </Row>
        )
      }
    </Container>
  );
};

const StyledSelect = styled(Select)<SelectProps  & {children: React.ReactNode}>``;

export default SurveyTypeSelection;
