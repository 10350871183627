import React from "react";
import styled from "styled-components";
import { Trans } from "react-i18next";
import ContainerCard from "@components/core/ContainerCard";
import Button from "@components/core/Button";
import Palette from "@src/config/theme/palette";
import AreYouSureModal from "@src/components/core/AreYouSureModal";
import { Icon, Select, Upload, Tooltip } from "antd";
import { RcFile, UploadProps } from "antd/lib/upload";
import {
  downloadSurveyLanguageTemplate,
  downloadSurveyUploadErrorCSV,
} from "@src/utils/exports";
import {
  FileUploadCollectiveState,
  getLanguagesForSelectBox,
} from "../../Xlsx/util";
import {
  getLanguageCodeFromString,
  getLanguageStringFromCode,
  LocaleCodes,
} from "hyphen-lib/dist/util/locale";
import { SelectValue } from "antd/lib/select";
import { Survey } from "hyphen-lib/dist/domain/Survey";
import { getOr, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { isNullOrEmpty } from "hyphen-lib/dist/lang/Strings";
import { not } from "hyphen-lib/dist/lang/Booleans";

const { Option } = Select;

interface TranslationUploadCardProps {
  readonly surveyId: string;
  readonly fileUploadState: FileUploadCollectiveState;
  readonly configuredSurveyLanguages: string[];
  readonly surveyTranslatedLanguages?: string[];
  readonly translationFileId?: string;

  readonly onTranslationCSVUpload: (
    file: File,
    surveyId: string,
    language: LocaleCodes
  ) => void;
}

interface CardState {
  downloadModalVisible: boolean;
  uploadSuccessModalVisible: boolean;
  uploadErrorModalVisible: boolean;
  file: FormData;
  selectedLanguageForDownload: LocaleCodes;
  selectedLanguageForUpload: LocaleCodes;
  uploadResponse?: Survey.UploadSurveyTemplateResponse;
}

export class TranslationUploadCard extends React.Component<
  TranslationUploadCardProps,
  CardState
> {
  constructor(props: TranslationUploadCardProps) {
    super(props);

    this.state = {
      downloadModalVisible: false,
      uploadSuccessModalVisible: false,
      uploadErrorModalVisible: false,
      selectedLanguageForDownload: this.props
        .configuredSurveyLanguages[0] as LocaleCodes,
      selectedLanguageForUpload: this.props
        .configuredSurveyLanguages[0] as LocaleCodes,
      file: new FormData(),
    };
  }

  // test comment
  downloadTextBody() {
    return (
      <ModalTextBody className="d-flex flex-column">
        <div>
          <Trans>Download the templates for each language , fill the translations and
          upload each of the language files.</Trans>
        </div>

        <DownloadTextBodyWarningMessage className="font-weight-bold d-flex">
          <div style={{ marginRight: "8.66px" }}>
            <Icon type="warning" className="align-self-start" theme="filled" />
          </div>
          <div>
            <Trans>Do not change the file name while uploading and keep the format of
            the template intact to avoid error while uploading translations</Trans>
          </div>
        </DownloadTextBodyWarningMessage>

        <div style={{ marginTop: "30px" }}>
          <span><Trans>Select language to download template</Trans></span>
          <Select
            showArrow
            style={{ width: "200px", marginLeft: "10px" }}
            onChange={(v: SelectValue) => {
              this.setState({
                selectedLanguageForDownload: getLanguageCodeFromString(
                  v as string
                ) as LocaleCodes,
              });
            }}
            value={[
              getLanguageStringFromCode(this.state.selectedLanguageForDownload),
            ]}
          >
            {getLanguagesForSelectBox(
              this.props.configuredSurveyLanguages as LocaleCodes[]
            ).map((langString) => (
              <Option key={langString} value={langString}>
                {langString}
              </Option>
            ))}
          </Select>
        </div>
      </ModalTextBody>
    );
  }

  uploadSuccessfulCard() {
    return (
      <ModalTextBody className="d-flex flex-column">
        <div className="mb-1 d-flex">
          <div>
            <Icon
              type="check"
              className="align-self-start"
              style={{ color: Palette.greenBlue, marginRight: "8.8px" }}
            />
          </div>
          <div>
            <div>
              <Trans>Congrats! Your translation file has been successfully uploaded.</Trans>
            </div>
            <div><Trans>All questions and answers are now translated.</Trans></div>
          </div>
        </div>
        <UploadErrorTipMessage className="well d-flex align-items-center">
          <Trans>Tip: You can double check all the translations by sending a Survey
          Preview</Trans>
        </UploadErrorTipMessage>
      </ModalTextBody>
    );
  }

  uploadErrorCard() {
    return (
      <ModalTextBody className="d-flex flex-column">
        <div className="mb-1 d-flex">
          <div>
            <Icon
              type="warning"
              className="align-self-start"
              style={{ color: Palette.sunglow, marginRight: "8.8px" }}
            />
          </div>
          <div style={{ lineHeight: "1.6em" }}>
            <div>
              <Trans>We observed some errors with the language translation file that
              was uploaded. As a result, the translations were not added</Trans>
            </div>
            <div style={{ marginTop: "10px" }}>
              <Trans>Download the detailed error file and fix the issues. Once done,
              you can upload the file again.</Trans>
            </div>
          </div>
        </div>
        <SuccessTipMessage className="well d-flex align-items-center">
          <Trans>Tip: You can download and check all the errors reported as .csv file</Trans>
        </SuccessTipMessage>
      </ModalTextBody>
    );
  }

  renderIUnderstandModal() {
    let description = null;
    let title = "";
    let visible = false;
    let onOkay: () => void = () => {};
    let onCancel: () => void = () => {};
    let okButtonStyles: React.CSSProperties = {};
    let okLabel = "Download Template";
    if (this.state.downloadModalVisible) {
      description = this.downloadTextBody();
      title = "Using the translation template";
      visible = this.state.downloadModalVisible;
      onOkay = () => {
        this.setState({ downloadModalVisible: false });
        downloadSurveyLanguageTemplate(
          this.props.surveyId,
          this.state.selectedLanguageForDownload
        );
      };
      onCancel = () => this.setState({ downloadModalVisible: false });
    } else if (this.state.uploadSuccessModalVisible) {
      description = this.uploadSuccessfulCard();
      title = "Bulk translation uploaded";
      visible = this.state.uploadSuccessModalVisible;
      onOkay = () => this.setState({ uploadSuccessModalVisible: false });
      onCancel = () => this.setState({ uploadSuccessModalVisible: false });
      okButtonStyles = {
        marginRight: "-16px",
      };
      okLabel = "I understand";
    } else if (this.state.uploadErrorModalVisible) {
      description = this.uploadErrorCard();
      title = "Error during translation upload";
      visible = this.state.uploadErrorModalVisible;
      onOkay = () => this.downloadErrorFile();
      onCancel = () => this.setState({ uploadErrorModalVisible: false });
      okLabel = "Download Error File";
    }

    return (
      <AreYouSureModal
        hideCancelButton
        okLabel={okLabel}
        description={description}
        width={515}
        title={title}
        visible={visible}
        onOk={onOkay}
        onCancel={onCancel}
        footerButtonPosition={"center"}
        okButtonStyles={okButtonStyles}
      />
    );
  }

  downloadErrorFile() {
    if (isNotNullNorUndefined(this.props.fileUploadState.uploadResponse)) {
      downloadSurveyUploadErrorCSV(
        this.props.surveyId,
        this.state.selectedLanguageForUpload,
        this.props.fileUploadState.uploadResponse?.errorFileId
      );
    }
  }

  showCSVDownloadModal() {
    this.setState({
      downloadModalVisible: true,
    });
  }

  beginUpload() {}

  extractLanguageFromFileName(file: RcFile): LocaleCodes {
    const filename = file.name;
    const filenameSplits = filename.split("_");
    const languageCode = filenameSplits[0] as LocaleCodes;
    return languageCode;
  }

  getUploadProps(): UploadProps {
    return {
      showUploadList: false,
      accept: ".csv",
      beforeUpload: (file: RcFile) => {
        const language = this.extractLanguageFromFileName(file);
        this.setState({ selectedLanguageForUpload: language });
        this.props.onTranslationCSVUpload(file, this.props.surveyId, language);
        return false;
      },
      onChange: () => {},
      disabled: !this.isEligibleToDownloadTemplate(),
    };
  }

  componentDidUpdate(prevProps: TranslationUploadCardProps) {
    if (
      isNotNullNorUndefined(prevProps.fileUploadState.uploadResponse) &&
      isNotNullNorUndefined(this.props.fileUploadState.uploadResponse) &&
      prevProps.fileUploadState.uploadResponse !==
        this.props.fileUploadState.uploadResponse
    ) {
      if (this.props.fileUploadState.uploadResponse.totalError > 0) {
        this.setState({
          uploadErrorModalVisible: true,
        });
      } else if (this.props.fileUploadState.uploadResponse.totalError === 0) {
        this.setState({
          uploadSuccessModalVisible: true,
        });
      }

      this.setState({
        uploadResponse: this.props.fileUploadState.uploadResponse,
      });
    }
  }

  canShowUploadedLanguages() {
    if (
      isNotNullNorUndefined(this.state.uploadResponse) &&
      this.state.uploadResponse.totalError === 0
    ) {
      return true;
    } else if (
      isNotNullNorUndefined(this.props.surveyTranslatedLanguages) &&
      this.props.surveyTranslatedLanguages.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  isEligibleToDownloadTemplate() {
    if (
      getLanguagesForSelectBox(
        this.props.configuredSurveyLanguages as LocaleCodes[]
      ).length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    return (
      <>
        {this.renderIUnderstandModal()}
        <Card title={<Trans>Translations</Trans>}>
          <Body>
            <BodyTitle>
              <Trans>There are two ways you can translate your survey questions:</Trans>
            </BodyTitle>
            <div className="d-flex">
              <LeftBox className="d-flex flex-column">
                <LeftBoxTitle className="font-weight-bold">
                  <Trans>Bulk translation</Trans>
                </LeftBoxTitle>
                <LeftBoxContent>
                  <Trans>Download the bulk translations template for each language,
                  fill it with the translated content and reupload here. The
                  translated content will be added automatically if there are no
                  errors in the uploaded translation file. You can check the
                  translations by sending a survey preview</Trans>
                </LeftBoxContent>
                <LeftButtonContainer className="d-flex">
                  <div className="flex-fill">
                    <div className="d-flex flex-row">
                      <Circle className="align-self-start">1</Circle>
                      <LeftBoxButtonTextContainer>
                        <LeftBoxButtonText className="font-weight-bold">
                          <Trans>Get your translation template</Trans>
                        </LeftBoxButtonText>
                        <Tooltip
                          title={<Trans>{
                            /* eslint-disable max-len */
                            "There are no languages added for this survey. Please add languages in the Survey Settings page"
                          }</Trans>}
                          {...(this.isEligibleToDownloadTemplate() ? { visible: false } : {})}
                        ><ToolTipWrapper>
                            <Button data-cy="download_template_button"
                              color={Palette.bluishGrey}
                              onClick={() => this.showCSVDownloadModal()}
                              disabled={not(this.isEligibleToDownloadTemplate())}
                              translate="yes"
                            >
                              Download Template
                            </Button></ToolTipWrapper>
                        </Tooltip>
                      </LeftBoxButtonTextContainer>
                    </div>
                  </div>
                  <div className="flex-fill">
                    <div className="d-flex flex-row">
                      <Circle className="align-self-start">2</Circle>
                      <LeftBoxButtonTextContainer>
                        <LeftBoxButtonText className="font-weight-bold">
                          <Trans>Upload completed translations</Trans>
                        </LeftBoxButtonText>
                        <Upload {...this.getUploadProps()}>
                          <Tooltip
                            title={<Trans>{
                              /* eslint-disable max-len */
                              "There are no languages added for this survey. Please add languages in the Survey Settings page"
                            }</Trans>}
                            {...(this.isEligibleToDownloadTemplate() ? { visible: false } : {})}
                          ><ToolTipWrapper>
                              <Button data-cy="upload_translation_button"
                                color="blue"
                                disabled={not(this.isEligibleToDownloadTemplate())}
                                translate="yes"
                              >
                                {"Upload Translations"}
                              </Button></ToolTipWrapper>
                          </Tooltip>
                        </Upload>
                      </LeftBoxButtonTextContainer>
                    </div>
                  </div>
                </LeftButtonContainer>
                {
                isNullOrEmpty(this.props.translationFileId) && 
                  <LeftBoxNoSurveyMessage
                    color={Palette.athensGrey}>
                    <Trans>You have not yet uploaded a translations file for this survey</Trans>
                  </LeftBoxNoSurveyMessage>
                }
              </LeftBox>
              <RightBox className="align-self-start">
                <RightBoxTitle className="font-weight-bold">
                  <Trans>In-line translation</Trans>
                </RightBoxTitle>
                <RightBoxContent>
                  <Trans>You can also manually input text for questions and predefined
                  answers for each survey question for all languages that you
                  selected in the first step.</Trans>
                </RightBoxContent>
              </RightBox>
            </div>
          </Body>
        </Card>
      </>
    );
  }
}

const Card = styled(ContainerCard)`
  margin-top: 32px;
  padding: 0;
  > div:first-child {
    padding: 32px 32px 0px 32px;
  }

  > div:last-child {
    padding: 16px 32px;
  }
`;

const LeftBox = styled.div`
  max-width: 600px;
  border: 1px solid #e4ecf0;
  padding: 16px;
  border-radius: 3px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
`;

const LeftBoxTitle = styled.div`
  font-size: 16px;
  margin-bottom: 12px;
`;

const LeftBoxContent = styled.div`
  font-size: 12px;
`;

const LeftButtonContainer = styled.div`
  margin-top: 24px;
  > div:nth-child(2) {
    margin-left: 66px;
  }
`;

const LeftBoxButtonTextContainer = styled.div``;
const LeftBoxButtonText = styled.div`
  font-size: 15px;
  margin-bottom: 13px;
`;

const Circle = styled.div`
  border-radius: 50% !important;
  display: inline-block;
  border: 1px solid #e4ecf0;
  padding: 3px 8.5px 5px 8.5px;
  font-size: 12px;
  margin-right: 8px;
  color: #7a8891;
  text-align: center;
`;

interface LefBoxNoSurveyMessageProps {
  color: Palette;
  border?: string;
}

const LeftBoxNoSurveyMessage = styled.div<LefBoxNoSurveyMessageProps>`
  margin-top: 32px;
  padding: 10px 2px 2px 14px;
  width: 568px;
  height: 48px;
  border: ${(props) => getOr(props.border, "1px solid #e4ecf0")};
  border-radius: 4px;
  background-color: ${(props) => props.color};
  box-sizing: border-box;
  font-size: 14px;
  overflow-y: auto;
`;
const RightBox = styled.div`
  height: 136px;
  width: 286px;
  border-radius: 3px;
  border: 1px solid #e4ecf0;
  margin-left: 30px;
  padding: 16px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
`;

const RightBoxTitle = styled.div`
  font-size: 16px;
  margin-bottom: 5px;
`;

const RightBoxContent = styled.div`
  font-size: 12px;
`;

const Body = styled.div``;

const BodyTitle = styled.div`
  font-size: 14px;
  margin-bottom: 20px;
`;

const ModalTextBody = styled.div`
  > div:first-child: {
    font-size: 14px;
    color: #213848;
    line-height: 20px;
  }
`;

const DownloadTextBodyWarningMessage = styled.div`
  margin-top: 15px;
`;

const SuccessTipMessage = styled.div`
  margin-top: 20px;
  width: 464px;
  height: 48px;
  margin-left: -16px;
`;

const UploadErrorTipMessage = styled.div`
  margin-top: 5px;
  width: 464px;
  height: 48px;
  margin-left: -16px;
`;

const ToolTipWrapper = styled.div`
  width: 200px;
  height: 50px;
`;
