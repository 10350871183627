import { State } from "@store/types";
import { getResources } from "@src/store/network/selectors";
import { getSegmentSizeForAnonymity } from "hyphen-lib/dist/business/appSetting/AppSettings";
import { getOr } from "hyphen-lib/dist/lang/Objects";
import { AppSettingsResource } from "hyphen-lib/dist/domain/resource/AppSettingsResource";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
/*
  fixme: we should use selector by ids and not list all possible values and take the first one :/
 */
export const getAppSettings = (state: State) => getResources(state, AppSettingsResource.TYPE).toList();
export const getCompany = (state: State) => getResources(state, CompanyResource.TYPE).toList();

export function getAnonymityThreshold(state: State) {
  // fixme: AppSettings should not contains optional values, so when we are getting the app settings
  // fixme: default values should be resolved in the backend!
  return getSegmentSizeForAnonymity(getOr(getAppSettings(state).get(0), {} as any) as any);
}

export function getSettingsStateProps(state: State) {
  return state.get("insights_settings");
}

export function getUserNotificationId(state: State) {
  return state.get("insights_settings").get("userNotificationRequestId");
}