import React from "react";

import styled from "styled-components";

interface NavbarItemsProps {
  children: React.ReactNode;
}

const NavbarItems = (props: NavbarItemsProps) => {
  return (
    <NavbarItemsContainer className="block--print-hide">
      {props.children}
    </NavbarItemsContainer>
  );
};

const NavbarItemsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > button {
    border-radius: 2px !important;
    font-weight: bold !important;
  }

  *:not(:last-child) {
    display: block;
    margin-right: 10px;
  }
`;

export default NavbarItems;
