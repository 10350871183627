import { Record, List, fromJS, Map as ImmutableMap } from "immutable";

import {
  NetworkEventSuccessAction,
  NetworkEventErrorAction
} from "@src/store/network/actions";

import { StepProps } from "@src/components/core/Stepper";
import { Survey } from "hyphen-lib/dist/domain/Survey";
import { v4 as uuidV4 } from "uuid";
import { SurveyQuestionResource } from "hyphen-lib/dist/domain/resource/SurveyQuestionResource";
import ImmutableMapType from "@src/utils/CreateImmutableType";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { Post } from "hyphen-lib/dist/domain/Post";
import { QuestionType } from "hyphen-lib/dist/domain/common/QuestionType";
import { utcNow } from "hyphen-lib/dist/lang/Dates";
import { AccessResource } from "hyphen-lib/dist/domain/access/AccessResource";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { MODALS as SURVEY_QUESTIONS_MODALS } from "../containers/SurveyQuestions";
import {
  SurveyResourceProps,
  SurveyProps,
  State,
  ContinueAlertProps,
  PulsePollResourceProps
} from "./types";
import {
  ActionTypes,
  UpdateAccessPageAction,
  OpenContinueAlertModalAction
} from "./actions";

// fixme: remove this, and probably the whole file as well
export const defaultSurveyProps: SurveyResourceProps = {
  postTemplateIds: List([]),
  hideResults: false,
  status: "draft",
  relationships: fromJS({
    questions: List([]),
  }),
  audience: fromJS({
    everyoneInTheCompany: true,
  }),
  _type: "Survey",
  _id: "",
  name: "",
  maxReachedStep: 1,
  surveyTemplateId: undefined,
  type: Survey.Types.ENGAGEMENT,
  logo: undefined,
  /* eslint-disable max-len */
  introductionText:
    "This is a new survey waiting for you! Please set aside some time to take it. We value opinion and can't wait to hear from you",
  /* eslint-disable max-len */
  isAnonymous: true,
  allowSkipQuestion: true,
  reminderInterval: 2,
  reminderLimit: 30,
  isTouched: false,
  channels: fromJS({
    email: {
      use: true,
      body: "",
      subject: "",
    },
  }),
  updatedAt: utcNow(),
  linkedSurveyName: ""
  // accessList: [] as any,
};

export const defaultPulsePollProps: PulsePollResourceProps = {
  _id: "",
  hideResults: false,
  status: Post.Status.DRAFT,
  audience: fromJS({
    everyoneInTheCompany: false,
  }),
  _type: "PulsePoll",
  // eslint-disable-next-line max-len
  reminderInterval: 2,
  reminderLimit: 30,
  channels: fromJS({
    email: {
      use: true,
      body: "",
      subject: "",
    },
  }),
  numberOfDaysBeforeClosingInstance: 5,
  question: "",
  questionType: QuestionType.LIKERT,
};

export const surveyResourceFactory = Record<SurveyResourceProps>(
  defaultSurveyProps
);
const defaultSurveyValues = surveyResourceFactory();

const continueAlertValues: ContinueAlertProps = {
  isModalOpen: false,
  fromPage: "",
  missingFields: [],
};

export const continueAlertFactory = Record<ContinueAlertProps>(
  continueAlertValues
);
const defaultContinueAlertValues = continueAlertFactory();

export const defaultStateValues: SurveyProps = {
  survey: defaultSurveyValues,
  manageesEmails: List([]),
  surveyInfo: fromJS({}),
  access: fromJS({
    eligibleManagersCount: 0,
    areManagersSynchronized: true,
    emails: [],
    accessList: [],
  }),
  surveyParticipantsCount: fromJS({}),
  formErrors: ImmutableMap(),
  mergeTags: ImmutableMap(),
  fetchSurveyTemplates: {
    isLoading: false,
  },
  createSurvey: {
    isLoading: false,
    isNewSurvey: false,
  },
  continueAlert: defaultContinueAlertValues,
  steppers: {
    steps: List([
      {
        title: "Settings",
        key: "settings",
      },
      {
        title: "Questions",
        key: "questions",
      },
      {
        title: "Audience",
        key: "audience",
      },
      {
        title: "Access",
        key: "access",
      },
      {
        title: "Summary",
        key: "summary",
      },
    ] as StepProps[]),
    redirectToCreate: false,
  },
  audiencePage: {
    isAudienceEmailsLoading: false,
    fetchAudienceTrigger: "",
    surveyParticipants: [],
  },
  surveyLaunchState: {
    isLaunching: false,
    launched: false,
  },
  isSurveyPreviewValidationModalOpen: false,
  isPreviewModalOpen: false,
  sendSurveyPreviewError: false,
  surveyPreviewErrorText: "",
  isCancelSurveyCreationModalOpen: false,
  surveyInfos: {
    isLoading: false,
    surveyNameInfos: [],
  },
  questionsPage: {
    isRequestingUpdate: false,
    isModalOpen: false,
    modalPurpose: SURVEY_QUESTIONS_MODALS.editQuestion,
  },
};

export const stateFactory = Record<SurveyProps>(defaultStateValues);
const defaultState = stateFactory();

export const createSurveySuccessReducer = (
  state: State = defaultState,
  { payload }: NetworkEventSuccessAction
): State => {
  return state.withMutations((map) => {
    map
      .setIn(["createSurvey", "isLoading"], false)
      .setIn(["createSurvey", "isNewSurvey"], true)
      .setIn(["createSurvey", "hasError"], false);
  });
};

export const uploadSurveyLogoSuccessReducer = (
  state: State = defaultState,
  { payload }: NetworkEventSuccessAction
): State => {
  return state.mergeIn(["survey"], fromJS(payload.data) as SurveyResourceProps);
};

export const uploadSurveyLogoErrorReducer = (
  state: State = defaultState,
  { payload }: NetworkEventErrorAction
): State => {
  return state;
};
function updateSurveySuccessReducer(
  state: State = defaultState,
  { meta }: NetworkEventSuccessAction
): State {
  // eslint-disable-next-line max-len
  const questions = state.getIn([
    "survey",
    "relationships",
    "questions",
  ]) as List<ImmutableMapType<SurveyQuestionResource>>;
  const { postTemplateIds } = meta.survey;

  return state.withMutations((map) => {
    map
      .mergeIn(["survey"], fromJS(meta.survey) as SurveyResourceProps)
      .setIn(["surveyInfo", "updatedAt"], Date.now())
      .setIn(
        ["survey", "relationships", "questions"],
        fromJS(
          postTemplateIds.map((id: string) =>
            questions.find((question) => question.get("_id") === id)
          )
        )
      );
  });
}

// function updateAudienceSuccessReducer(
//   state: State = defaultState,
//   { meta }: NetworkEventSuccessAction
// ): State {
//   return state.mergeIn(["survey", "audience"], meta.survey.audience as AudienceResourceProps);
// }

function addAccessReducer(
  state: State = defaultState,
  { meta }: NetworkEventSuccessAction
): State {
  return state.withMutations((map) => {
    map
      .updateIn(["access", "accessList"], (list) =>
        list.concat(fromJS(meta.surveyAccesses))
      )
      .setIn(["surveyInfo", "updatedAt"], Date.now());
  });
}

function addLocalAccessReducer(
  state: State = defaultState,
  { meta }: NetworkEventSuccessAction
): State {
  return state.withMutations((map) => {
    map
      .updateIn(["access", "accessList"], (list) => {
        const listModified = list.toJS();
        // @ts-ignore
        meta.surveyAccesses.forEach((x) => {
          // @ts-ignore
          listModified.forEach((y, i) => {
            if (y._id === x._id) {
              listModified[i] = x;
            }
          });
        });

        return fromJS(listModified);
      })
      .setIn(["surveyInfo", "updatedAt"], Date.now())
      .setIn(["survey", "isTouched"], false);
  });
}

function modifyAndSaveAccessReducer(
  state: State = defaultState,
  access: AccessResource
): State {
  return state.withMutations((map) => {
    map
      .updateIn(["access", "accessList"], (list) => {
        const itemIndex = list.findIndex((item: Map<string, any>) => {
          return item.get("_id") === access._id;
        });
        return list.set(itemIndex, fromJS(access));
      })
      .setIn(["surveyInfo", "updatedAt"], Date.now());
  });
}

function removeAccessReducer(
  state: State = defaultState,
  { meta }: NetworkEventSuccessAction
): State {
  return state.withMutations((map) => {
    map
      .updateIn(["access", "accessList"], (list) => {
        const itemIndex = list.findIndex((item: Map<string, any>) => {
          return item.get("_id") === meta.accessId;
        });
        return list.delete(itemIndex);
      })
      .setIn(["surveyInfo", "updatedAt"], Date.now());
  });
}
function openContinueModalReducer(
  state: State = defaultState,
  { payload }: OpenContinueAlertModalAction
): State {
  return state.withMutations((map) => {
    map
      .mergeIn(["continueAlert"], payload)
      .setIn(["continueAlert", "isModalOpen"], true);
  });
}

function openSurveyPreviewValidationModalReducer(
  state: State = defaultState
): State {
  return state.set("isSurveyPreviewValidationModalOpen", true);
}

function closeSurveyPreviewValidationModalReducer(
  state: State = defaultState
): State {
  return state.set("isSurveyPreviewValidationModalOpen", false);
}

function openSurveyPreviewModalReducer(state: State = defaultState): State {
  return state.set("isPreviewModalOpen", true);
}

function closeSurveyPreviewModalReducer(state: State = defaultState): State {
  return state.withMutations((map) => {
    map
      .set("surveyPreviewErrorText", "")
      .set("sendSurveyPreviewError", false)
      .set("isPreviewModalOpen", false);
  });
}

function openCancelSurveyCreationModalReducer(
  state: State = defaultState
): State {
  return state.set("isCancelSurveyCreationModalOpen", true);
}

function closeCancelSurveyCreationModalReducer(
  state: State = defaultState
): State {
  return state.set("isCancelSurveyCreationModalOpen", false);
}

function removeContinueModalReducer(state: State = defaultState): State {
  return state.updateIn(["continueAlert"], (record) => {
    record.clear();
  });
}

function receiveSurveySuccessReducer(
  state: State = defaultState,
  { payload }: NetworkEventSuccessAction
): State {
  return state.mergeIn(["survey"], fromJS(payload.data) as SurveyResourceProps);
}

function getSurveyInfoSuccessReducer(
  state: State = defaultState,
  { payload }: NetworkEventSuccessAction
): State {
  return state.mergeIn(["surveyInfo"], payload.data);
}

function getSurveyParticipantsCount(
  state: State = defaultState,
  { payload }: NetworkEventSuccessAction
): State {
  return state.mergeIn(["surveyParticipantsCount"], payload.data);
}

export const updateAccessReducer = (
  state: State = defaultState,
  { payload }: UpdateAccessPageAction
): State => {
  return state.mergeIn(["access"], payload);
};

function updateQuestionsReducer(
  state: State = defaultState,
  { meta, payload }: NetworkEventSuccessAction
) {
  const { index } = meta;
  const questionsPath = ["survey", "relationships", "questions"];
  const { data } = payload;
  const questions = state.getIn(questionsPath);
  return state.withMutations((map) => {
    map
      .setIn(questionsPath, questions.set(index, fromJS(data)))
      .setIn(["questionsPage", "isRequestingUpdate"], false)
      .setIn(["questionsPage", "isModalOpen"], false);
  });
}

function addQuestionsSuccessReducer(
  state: State = defaultState,
  { payload }: NetworkEventSuccessAction
) {
  const { data } = payload;
  const postTemplateIdsPath = ["survey", "postTemplateIds"];
  const questionsPath = ["survey", "relationships", "questions"];
  const postTemplateIds = state.getIn(postTemplateIdsPath);
  const questions = state.getIn(questionsPath);
  return state.withMutations((map) => {
    return map
      .setIn(postTemplateIdsPath, postTemplateIds.push(data._id))
      .setIn(questionsPath, questions.push(fromJS(data)))
      .setIn(["questionsPage", "isRequestingUpdate"], false)
      .setIn(["questionsPage", "isModalOpen"], false);
  });
}

function removeQuestionReducer(
  state: State = defaultState,
  { meta }: NetworkEventSuccessAction
) {
  const { index, questionId } = meta;
  const questions = state.getIn(["survey", "relationships", "questions"]);
  const postTemplateIds = state.getIn(["survey", "postTemplateIds"]);

  return state.withMutations((map) => {
    map
      .setIn(["survey", "relationships", "questions"], questions.remove(index))
      .setIn(
        ["survey", "postTemplateIds"],
        postTemplateIds.filter((id: string) => id !== questionId)
      )
      .setIn(["questionsPage", "isRequestingUpdate"], false)
      .setIn(["questionsPage", "isModalOpen"], false);
  });
}

function getSurveyParticipantDetails(
  state: State = defaultState,
  { payload }: NetworkEventSuccessAction
) {
  return state.withMutations((map) => {
    map
      .setIn(["audiencePage", "surveyParticipants"], payload.data)
      .setIn(["audiencePage", "isAudienceEmailsLoading"], false);
  });
}

function getSurveyInfoNames(
  state: State = defaultState,
  { payload }: NetworkEventSuccessAction
) {
  return state.withMutations((map) => {
    map
      .setIn(["surveyInfos", "surveyNameInfos"], payload.data.data)
      .setIn(["surveyInfos", "isLoading"], false);
  });
}

export const reducers = (state: State = defaultState, action: any) => {
  switch (action.type) {
    case ActionTypes.FETCH_TEMPLATES_REQUEST:
      return state.setIn(["fetchSurveyTemplates", "isLoading"], true);
    case ActionTypes.FETCH_TEMPLATES_SUCCESS:
      if (state.getIn(["steppers", "redirectToCreate"]) === true) {
        return state.withMutations((map) => {
          map
            .setIn(["steppers", "redirectToCreate"], false)
            .setIn(["fetchSurveyTemplates", "isLoading"], false);
        });
      }
      return state.setIn(["fetchSurveyTemplates", "isLoading"], false);
    case ActionTypes.CREATE_SURVEY_REQUEST:
      return state
        .setIn(["steppers", "redirectToCreate"], false)
        .setIn(["createSurvey", "isLoading"], true)
        .setIn(["createSurvey", "hasError"], false);

    case ActionTypes.CREATE_SURVEY_SUCCESS:
      return createSurveySuccessReducer(state, action);

    case ActionTypes.CREATE_SURVEY_ERROR:
      return state
        .setIn(["createSurvey", "isLoading"], false)
        .setIn(["createSurvey", "hasError"], true);
    case ActionTypes.FETCH_MERGE_TAG_SUCCESS:
      return state.setIn(["mergeTags", action.surveyType], action.payload.data);
    case ActionTypes.FETCH_SURVEY_SUCCESS:
      return receiveSurveySuccessReducer(state, action);
    case ActionTypes.GET_SURVEY_INFO_SUCCESS:
      return getSurveyInfoSuccessReducer(state, action);
    case ActionTypes.FETCH_SURVEY_ERROR:
      return state.setIn(["steppers", "redirectToCreate"], true);
    case ActionTypes.UPLOAD_SURVEY_LOGO_SUCCESS:
      return uploadSurveyLogoSuccessReducer(state, action);
    case ActionTypes.UPLOAD_SURVEY_LOGO_ERROR:
      return uploadSurveyLogoErrorReducer(state, action);
    case ActionTypes.UPDATE_SURVEY_REQUEST:
      return updateSurveySuccessReducer(state, action);
    case ActionTypes.UPDATE_SURVEY_SUCCESS:
      return state.setIn(["survey", "isTouched"], false);
    case ActionTypes.ADD_QUESTION_REQUEST:
    case ActionTypes.UPDATE_QUESTION_REQUEST:
    case ActionTypes.REMOVE_QUESTION_REQUEST:
      return state.setIn(["questionsPage", "isRequestingUpdate"], true);
    case ActionTypes.UPDATE_QUESTION_ERROR:
    case ActionTypes.ADD_QUESTION_ERROR:
    case ActionTypes.REMOVE_QUESTION_ERROR:
      return state.setIn(["questionsPage", "isRequestingUpdate"], false);
    case ActionTypes.ADD_QUESTION_SUCCESS:
      return addQuestionsSuccessReducer(state, action);
    case ActionTypes.UPDATE_QUESTION_SUCCESS:
      return updateQuestionsReducer(state, action);
    case ActionTypes.REMOVE_QUESTION_SUCCESS:
      return removeQuestionReducer(state, action);
    case ActionTypes.LAUNCH_SURVEY_REQUEST:
      return state.setIn(["surveyLaunchState", "isLaunching"], true);
    case ActionTypes.LAUNCH_SURVEY_SUCCESS:
      return state.withMutations((map) => {
        map
          .setIn(["surveyLaunchState", "isLaunching"], false)
          .setIn(["surveyLaunchState", "launched"], true);
      });
    case ActionTypes.LAUNCH_SURVEY_ERROR:
      return state.setIn(["surveyLaunchState", "isLaunching"], false);
    case ActionTypes.FETCH_ACCESS_LIST_SUCCESS:
      const accessList = fromJS(action.payload.data);
      return state.setIn(["access", "accessList"], accessList);
    case ActionTypes.FETCH_ELIGIBLE_MANAGERS_COUNT_SUCCESS:
      return state.setIn(
        ["access", "eligibleManagersCount"],
        action.payload.data
      );
    case ActionTypes.FETCH_MANAGEES_EMAILS_SUCCESS:
      const data = fromJS(action.payload.data);
      return state
        .setIn(["access", "emails"], data)
        .setIn(["manageesEmails"], data);
    case ActionTypes.FETCH_POSSIBLE_ACTION_ASSIGNEES_SUCCESS:
      const emails = fromJS(action.payload.data);
      return state
        .setIn(["access", "emails"], emails)
        .setIn(["manageesEmails"], emails);
    case ActionTypes.TOGGLE_MANAGERS_SYNCHRONIZATION_SUCCESS:
      const accesses = fromJS(action.payload.data.relationships.accesses);
      return state.withMutations((map) => {
        map
          .setIn(["access", "accessList"], accesses)
          .setIn(
            ["access", "areManagersSynchronized"],
            action.payload.data.areManagersSynchronized
          )
          .setIn(["surveyInfo", "updatedAt"], Date.now());
      });
    case ActionTypes.ADD_SURVEY_ACCESS_REQUEST:
      return addAccessReducer(state, action);
    case ActionTypes.ADD_LOCAL_SURVEY_ACCESS_SUCCESS:
      return addLocalAccessReducer(state, action);
    case ActionTypes.MODIFY_ACCESS_REQUEST:
      return modifyAndSaveAccessReducer(state, action.meta.surveyAccess);
    case ActionTypes.MODIFY_ACCESS_SUCCESS:
      return modifyAndSaveAccessReducer(state, action.payload.data);
    case ActionTypes.REMOVE_SURVEY_ACCESS_REQUEST:
      return removeAccessReducer(state, action);
    case ActionTypes.REMOVE_SURVEY_ACCESS_SUCCESS:
      return removeAccessReducer(state, action);
    case ActionTypes.UPDATE_ACCESS_PAGE_STATE:
      return updateAccessReducer(state, action);
    case ActionTypes.UPDATE_SURVEY_FORM_ERRORS:
      return state.mergeIn(["formErrors"], action.payload);
    case ActionTypes.REMOVE_SURVEY_FORM_ERRORS:
      return state.deleteIn(["formErrors", action.payload]);
    case ActionTypes.OPEN_CONTINUE_ALERT_MODAL:
      return openContinueModalReducer(state, action);
    case ActionTypes.CLOSE_CONTINUE_ALERT_MODAL:
      return removeContinueModalReducer(state);
    case ActionTypes.TOGGLE_QUESTIONS_MODAL_STATE:
      return state.withMutations((map) => {
        map
          .setIn(["questionsPage", "isModalOpen"], action.isModalOpen)
          .setIn(["questionsPage", "modalPurpose"], action.modalPurpose);
      });
    case ActionTypes.OPEN_SURVEY_PREVIEW_VALIDATION_MODAL:
      return openSurveyPreviewValidationModalReducer(state);
    case ActionTypes.CLOSE_SURVEY_PREVIEW_VALIDATION_MODAL:
      return closeSurveyPreviewValidationModalReducer(state);
    case ActionTypes.OPEN_SURVEY_PREVIEW_MODAL:
      return openSurveyPreviewModalReducer(state);
    case ActionTypes.CLOSE_SURVEY_PREVIEW_MODAL:
      return closeSurveyPreviewModalReducer(state);
    case ActionTypes.SEND_SURVEY_PREVIEW_SUCCESS:
      return closeSurveyPreviewModalReducer(state);
    case ActionTypes.SEND_SURVEY_PREVIEW_ERROR:
      if (
        isNotNullNorUndefined(action.payload.error) &&
        action.payload.error[0].key === "name" &&
        action.payload.error[0].errorCode ===
          WrongEntityException.ErrorCodes.STRING_TOO_SHORT
      ) {
        return state.withMutations((map) => {
          map
            .set(
              "surveyPreviewErrorText",
              "Please enter a Survey Name in Step 1(Settings)"
            )
            .set("sendSurveyPreviewError", true);
        });
      }
      return state.withMutations((map) => {
        map
          .set("surveyPreviewErrorText", "Unexpected error occurred.")
          .set("sendSurveyPreviewError", true);
      });
    case ActionTypes.OPEN_CANCEL_SURVEY_CREATION_MODAL:
      return openCancelSurveyCreationModalReducer(state);
    case ActionTypes.CLOSE_CANCEL_SURVEY_CREATION_MODAL:
      return closeCancelSurveyCreationModalReducer(state);
    case ActionTypes.UPDATE_AUDIENCE_STATE:
      return state.setIn(["audiencePage"], action.payload);
    case ActionTypes.FETCH_AUDIENCE_EMAIL_REQUEST:
      return state.setIn(["audiencePage", "isAudienceEmailsLoading"], true);
    case ActionTypes.FETCH_AUDIENCE_EMAIL_SUCCESS:
      return getSurveyParticipantDetails(state, action);
    case ActionTypes.FETCH_SURVEY_INFOS_REQUEST:
      return state.setIn(["surveyInfos", "isLoading"], true);
    case ActionTypes.FETCH_SURVEY_INFOS_SUCCESS:
      return getSurveyInfoNames(state, action);
    case ActionTypes.FETCH_SURVEY_INFOS_ERROR:
      return state.setIn(["surveyInfos", "isLoading"], false);
    case ActionTypes.FETCH_AUDIENCE_EMAIL_ERROR:
      return state.setIn(["audiencePage", "isAudienceEmailsLoading"], false);
    case ActionTypes.UPDATE_SURVEY_AND_TRIGGER_FETCH_AUDIENCE_REQUEST:
      return updateSurveySuccessReducer(state, action);
    case ActionTypes.UPDATE_SURVEY_AND_TRIGGER_FETCH_AUDIENCE_SUCCESS:
      return state.withMutations((map) => {
        map
          .setIn(["audiencePage", "fetchAudienceTrigger"], uuidV4())
          .setIn(["survey", "isTouched"], false);
      });
    case ActionTypes.FETCH_SURVEY_BANK_QUESTIONS_SUCCESS:
    case ActionTypes.FETCH_PARTICIPANTS_COUNT_SUCCESS:
      return getSurveyParticipantsCount(state, action);
    case ActionTypes.CLEAR_CREATE_SURVEY_STATE:
      return defaultState;
    case ActionTypes.SET_IS_TOUCHED:
      const path = ["survey", "isTouched"];
      const isTouched = state.getIn(path);
      return state.setIn(path, !isTouched);
    case ActionTypes.GET_SURVEY_INFO_ERROR:
      // debugger
      return state.mergeIn(
        ["surveyInfo"],
        Loadable.inError({ errorCode: action.payload.status, errorMessage: "" })
      );
    default:
      return state;
  }
};

export default reducers;
