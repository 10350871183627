import { default as networkActionCreators } from "@store/network/actions";
import { createRequest } from "@src/utils/networks";
import { appendQueryString, generateQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { CategoriesReportResource } from "hyphen-lib/dist/domain/resource/report/CategoriesReportResource";

export function fetchCategoriesReportIfNeeded(surveyId: string, queryString: Dictionary<any>) {
  return networkActionCreators.fetchElementIfNeeded({
    id: CategoriesReportResource.generateId(queryString.filter, surveyId),
    type: CategoriesReportResource.TYPE,
    request: createRequest(
      appendQueryString(
        `/surveys/${surveyId}/reports/categories`,
        generateQueryString(queryString)
      )
    ),
  });
}
