import React from "react";
import {Route, Switch, RouteComponentProps, Redirect} from "react-router-dom";
import styled from "styled-components";
import { Layout as AntLayout } from "antd";

import Palette from "@src/config/theme/palette";
import { PulsePollEdit } from "./containers/PulsePollEdit";

export class PulsePolls extends React.Component<RouteComponentProps> {

  render() {
    return (
      <Layout>
        {/* All the routes inside /pulsePolls go here */}
        <Switch>
          <Route path="/pulsePolls/edit/:id/:currentStepKey" component={PulsePollEdit} />
          <Route path="/pulsePolls/edit/:id/" component={PulsePollEdit} />
          <Route path="/pulsePolls/edit" component={PulsePollEdit} />
          <Redirect to="/"/>
        </Switch>
      </Layout>
    );
  }
}

const Layout = styled(AntLayout)`
  background: ${Palette.paleGrey};
`;
