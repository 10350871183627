import { FSA } from "flux-standard-action";

import { FilterParameter, createRequest } from "@src/utils/networks";
import { FetchDataListParameters } from "@src/screens/Insights/components/DataListContainer/types";
import {
  ActionTypes as NetworkActionTypes,
  NetworkRequestAction,
  NetworkEventRequestAction,
  NetworkEventSuccessAction,
  NetworkEventErrorAction
} from "@src/store/network/actions";
import { VoiceFlaggedPostReportResource } from "hyphen-lib/dist/domain/resource/voice/VoiceFlaggedPostReportResource";
import {
  VoiceFlaggedCommentReportResource
} from "hyphen-lib/dist/domain/resource/voice/VoiceFlaggedCommentReportResource";
import { VoiceFlagsActionKeyType } from "./types";

export enum VoiceReportsActions {
  MODIFY_FILTER = "voiceReports/MODIFY_FILTER",
  CLEAN_FILTER = "voiceReports/CLEAN_FILTER",
  TOGGLE_ARE_YOU_SURE_MODAL = "voiceReports/TOGGLE_ARE_YOU_SURE_MODAL",

  UNFLAG_POST_REQUEST = "voiceReports/UNFLAG_POST_REQUEST",
  UNFLAG_POST_SUCCESS = "voiceReports/UNFLAG_POST_SUCCESS",
  UNFLAG_POST_ERROR = "voiceReports/UNFLAG_POST_ERROR",

  ARCHIVE_POST_REQUEST = "voiceReports/ARCHIVE_POST_REQUEST",
  ARCHIVE_POST_SUCCESS = "voiceReports/ARCHIVE_POST_SUCCESS",
  ARCHIVE_POST_ERROR = "voiceReports/ARCHIVE_POST_ERROR",

  MODIFY_LIST = "voiceReports/MODIFY_LIST",
  UNFLAG_POST = "voiceReports/UNFLAG_POST",
  ARCHIVE_POST = "voiceReports/ARCHIVE_POST",

  UNFLAG_COMMENT_REQUEST = "voiceReports/UNFLAG_COMMENT_REQUEST",
  UNFLAG_COMMENT_SUCCESS = "voiceReports/UNFLAG_COMMENT_SUCCESS",
  UNFLAG_COMMENT_ERROR = "voiceReports/UNFLAG_COMMENT_ERROR",

  ARCHIVE_COMMENT_REQUEST = "voiceReports/ARCHIVE_COMMENT_REQUEST",
  ARCHIVE_COMMENT_SUCCESS = "voiceReports/ARCHIVE_COMMENT_SUCCESS",
  ARCHIVE_COMMENT_ERROR = "voiceReports/ARCHIVE_COMMENT_ERROR",

  UNFLAG_COMMENT = "voiceReports/UNFLAG_COMMENT",
  ARCHIVE_COMMENT = "voiceReports/ARCHIVE_COMMENT",
}

interface Payloads {
  [VoiceReportsActions.MODIFY_FILTER]: FilterParameter;
  [VoiceReportsActions.MODIFY_LIST]: FetchDataListParameters;
  [VoiceReportsActions.UNFLAG_POST]: UnflagPayload;
  [VoiceReportsActions.ARCHIVE_POST]: ArchivePayload;
  [VoiceReportsActions.UNFLAG_COMMENT]: UnflagPayload;
  [VoiceReportsActions.ARCHIVE_COMMENT]: ArchivePayload;
  [VoiceReportsActions.TOGGLE_ARE_YOU_SURE_MODAL]: VoiceReportsAreYouSureModalPayload;
}
interface UnflagPayload {
  readonly id: string;
}

interface ArchivePayload {
  readonly id: string;
}

export interface VoiceReportsAreYouSureModalPayload {
  readonly isAreYouSureModalVisible: boolean;
  readonly action: VoiceFlagsActionKeyType | null;
  readonly selectedFlaggedResource:
  | VoiceFlaggedPostReportResource
  | VoiceFlaggedCommentReportResource
  | null;
}

export interface ModifyFilterAction
  extends FSA<
  VoiceReportsActions.MODIFY_FILTER,
  Payloads[VoiceReportsActions.MODIFY_FILTER]
  > {
  type: VoiceReportsActions.MODIFY_FILTER;
  payload: Payloads[VoiceReportsActions.MODIFY_FILTER];
}

export interface UnflagPostAction
  extends FSA<
  VoiceReportsActions.UNFLAG_POST,
  Payloads[VoiceReportsActions.UNFLAG_POST]
  > {
  type: VoiceReportsActions.UNFLAG_POST;
  payload: Payloads[VoiceReportsActions.UNFLAG_POST];
}

export interface ArchivePostAction
  extends FSA<
  VoiceReportsActions.ARCHIVE_POST,
  Payloads[VoiceReportsActions.ARCHIVE_POST]
  > {
  type: VoiceReportsActions.ARCHIVE_POST;
  payload: Payloads[VoiceReportsActions.ARCHIVE_POST];
}

export interface UnflagCommentAction
  extends FSA<
  VoiceReportsActions.UNFLAG_COMMENT,
  Payloads[VoiceReportsActions.UNFLAG_COMMENT]
  > {
  type: VoiceReportsActions.UNFLAG_COMMENT;
  payload: Payloads[VoiceReportsActions.UNFLAG_COMMENT];
}

export interface ArchiveCommentAction
  extends FSA<
  VoiceReportsActions.ARCHIVE_COMMENT,
  Payloads[VoiceReportsActions.ARCHIVE_COMMENT]
  > {
  type: VoiceReportsActions.ARCHIVE_COMMENT;
  payload: Payloads[VoiceReportsActions.ARCHIVE_COMMENT];
}

export interface ModifyListAction
  extends FSA<
  VoiceReportsActions.MODIFY_LIST,
  Payloads[VoiceReportsActions.MODIFY_LIST]
  > {
  type: VoiceReportsActions.MODIFY_LIST;
  payload: Payloads[VoiceReportsActions.MODIFY_LIST];
}

export interface CleanFilterAction
  extends FSA<VoiceReportsActions.CLEAN_FILTER, void> {
  type: VoiceReportsActions.CLEAN_FILTER;
}

function getUnflagRequest(resource: "posts" | "comments") {
  return function createUnflagRequest(resourceId: string) {
    return createRequest(`/${resource}/${resourceId}/unflag`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    });
  };
}

function getArchiveRequest(resource: "posts" | "comments") {
  return function createArchiveRequest(resourceId: string) {
    return createRequest(`/${resource}/${resourceId}/archive`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    });
  };
}

export const voiceReportsActionCreators = {
  modifyList: (payload: ModifyListAction["payload"]): ModifyListAction => ({
    type: VoiceReportsActions.MODIFY_LIST,
    payload,
  }),
  modifyFilter: (
    payload: ModifyFilterAction["payload"]
  ): ModifyFilterAction => ({
    type: VoiceReportsActions.MODIFY_FILTER,
    payload,
  }),
  cleanFilter: (): CleanFilterAction => ({
    type: VoiceReportsActions.CLEAN_FILTER,
  }),

  unflagPost: () => ({
    type: VoiceReportsActions.UNFLAG_POST,
  }),
  archivePost: () => ({
    type: VoiceReportsActions.ARCHIVE_POST,
  }),

  unflagComment: () => ({
    type: VoiceReportsActions.UNFLAG_COMMENT,
  }),
  archiveComment: () => ({
    type: VoiceReportsActions.ARCHIVE_COMMENT,
  }),
  toggleAreYouSureModal: (
    payload: Payloads[VoiceReportsActions.TOGGLE_ARE_YOU_SURE_MODAL]
  ) => ({
    type: VoiceReportsActions.TOGGLE_ARE_YOU_SURE_MODAL,
    payload,
  }),
  closeAreYouSureModal: () => ({
    type: VoiceReportsActions.TOGGLE_ARE_YOU_SURE_MODAL,
    payload: {
      isAreYouSureModalVisible: false,
      action: null,
      selectedFlaggedResource: null,
    },
  }),
  onUnflagPostRequest: (
    unFlagPayload: Payloads[VoiceReportsActions.UNFLAG_POST]
  ): NetworkRequestAction => {
    const createUnflagPostRequest = getUnflagRequest("posts");
    return {
      type: NetworkActionTypes.NETWORK_REQUEST,
      payload: createUnflagPostRequest(unFlagPayload.id),
      meta: {
        onRequestActions: [
          (payload: NetworkEventRequestAction["payload"]) => ({
            type: VoiceReportsActions.UNFLAG_POST_REQUEST,
            payload,
          }),
        ],
        onSuccessActions: [
          (payload: NetworkEventSuccessAction["payload"]) => ({
            type: VoiceReportsActions.UNFLAG_POST_SUCCESS,
            payload,
          }),
        ],
        onErrorActions: [
          (payload: NetworkEventErrorAction["payload"]) => ({
            type: VoiceReportsActions.UNFLAG_POST_ERROR,
            payload,
          }),
        ],
      },
    };
  },

  onArchiveFlaggedPostRequest: (
    archivePayload: Payloads[VoiceReportsActions.ARCHIVE_POST]
  ): NetworkRequestAction => {
    const createArchivePostRequest = getArchiveRequest("posts");
    return {
      type: NetworkActionTypes.NETWORK_REQUEST,
      payload: createArchivePostRequest(archivePayload.id),
      meta: {
        onRequestActions: [
          (payload: NetworkEventRequestAction["payload"]) => ({
            type: VoiceReportsActions.ARCHIVE_POST_REQUEST,
            payload,
          }),
        ],
        onSuccessActions: [
          (payload: NetworkEventSuccessAction["payload"]) => ({
            type: VoiceReportsActions.ARCHIVE_POST_SUCCESS,
            payload,
          }),
        ],
        onErrorActions: [
          (payload: NetworkEventErrorAction["payload"]) => ({
            type: VoiceReportsActions.ARCHIVE_POST_ERROR,
            payload,
          }),
        ],
      },
    };
  },

  onUnflagCommentRequest: (
    unFlagPayload: Payloads[VoiceReportsActions.UNFLAG_COMMENT]
  ): NetworkRequestAction => {
    const createUnflagCommentRequest = getUnflagRequest("comments");
    return {
      type: NetworkActionTypes.NETWORK_REQUEST,
      payload: createUnflagCommentRequest(unFlagPayload.id),
      meta: {
        onRequestActions: [
          (payload: NetworkEventRequestAction["payload"]) => ({
            type: VoiceReportsActions.UNFLAG_COMMENT_REQUEST,
            payload,
          }),
        ],
        onSuccessActions: [
          (payload: NetworkEventSuccessAction["payload"]) => ({
            type: VoiceReportsActions.UNFLAG_COMMENT_SUCCESS,
            payload,
          }),
        ],
        onErrorActions: [
          (payload: NetworkEventErrorAction["payload"]) => ({
            type: VoiceReportsActions.UNFLAG_COMMENT_ERROR,
            payload,
          }),
        ],
      },
    };
  },

  onArchiveFlaggedCommentRequest: (
    archivePayload: Payloads[VoiceReportsActions.ARCHIVE_COMMENT]
  ): NetworkRequestAction => {
    const createArchiveCommentRequest = getArchiveRequest("comments");
    return {
      type: NetworkActionTypes.NETWORK_REQUEST,
      payload: createArchiveCommentRequest(archivePayload.id),
      meta: {
        onRequestActions: [
          (payload: NetworkEventRequestAction["payload"]) => ({
            type: VoiceReportsActions.ARCHIVE_COMMENT_REQUEST,
            payload,
          }),
        ],
        onSuccessActions: [
          (payload: NetworkEventSuccessAction["payload"]) => ({
            type: VoiceReportsActions.ARCHIVE_COMMENT_SUCCESS,
            payload,
          }),
        ],
        onErrorActions: [
          (payload: NetworkEventErrorAction["payload"]) => ({
            type: VoiceReportsActions.ARCHIVE_COMMENT_ERROR,
            payload,
          }),
        ],
      },
    };
  },
};
