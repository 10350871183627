import { Action } from "hyphen-lib/dist/domain/Action";
import { FocusArea } from "hyphen-lib/dist/domain/FocusArea";
import { Module } from "hyphen-lib/dist/domain/FocusArea";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { CreationModes } from "../ActionPlans/containers/CreateActionPlanContainer";

export function prepareCreateActionPlanContext(focusArea: FocusAreaResource, from: string) {
    let source = {};
    switch (focusArea.module) {
        case Module.DASHBOARD:
            source = {
                type: mapFocusAreaSourceToActionPlanSource(focusArea),
                label: focusArea.title,
                context: {
                    ...(isNotNullNorUndefined(focusArea.category) && ({category: focusArea.category})),
                    focusAreaId: focusArea._id,
                    ...(isNotNullNorUndefined(focusArea.dimension) && {dimension: focusArea.dimension}),
                    ...(isNotNullNorUndefined(focusArea.dimension) && {segment: focusArea.title})
                },
                mode: CreationModes.scratch,
                from,
            };
            break;

        case Module.SURVEY:
            source = {
                type: mapFocusAreaSourceToActionPlanSource(focusArea),
                label: focusArea.title,
                context: {
                    ...(isNotNullNorUndefined(focusArea.category) && ({category: focusArea.category})),
                    surveyId: focusArea.surveyId,
                    questionId: focusArea.questionId,
                    focusAreaId: focusArea._id,
                    ...(isNotNullNorUndefined(focusArea.dimension) && {dimension: focusArea.dimension}),
                    ...(isNotNullNorUndefined(focusArea.dimension) && {segment: focusArea.title})
                },
                mode: CreationModes.scratch,
                from,
            };

            break;

        case Module.EMPLOYEE_VOICE:
            source = {
                type: mapFocusAreaSourceToActionPlanSource(focusArea),
                label: focusArea.title,
                context: {
                    ...(isNotNullNorUndefined(focusArea.category) && ({category: focusArea.category})),
                    voicePollId: focusArea.postId,
                    commentId: focusArea.commentId,
                    focusAreaId: focusArea._id,
                },
                mode: CreationModes.scratch,
                from,
            };
            break;

        case Module.PULSE_POLL:
            source = {
                type: mapFocusAreaSourceToActionPlanSource(focusArea),
                label: focusArea.title,
                context: {
                    ...(isNotNullNorUndefined(focusArea.category) && ({category: focusArea.category})),
                    pulsePollId: focusArea.pollId,
                    segment: focusArea.title,
                    dimension: focusArea.dimension,
                    commentId: focusArea.commentId,
                    focusAreaId: focusArea._id,
                },
                mode: CreationModes.scratch,
                from,
            };
            break;
    }
    return source;
}

export function mapFocusAreaSourceToActionPlanSource(
    focusArea: FocusAreaResource
  ): Action.SourceType | undefined {
    if (
      focusArea.focusAreaType === FocusArea.FocusAreaType.QUESTION &&
      focusArea.module === Module.SURVEY &&
      isNotNullNorUndefined(focusArea.surveyId) &&
      isNotNullNorUndefined(focusArea.questionId)
    ) {
      return Action.SourceType.SURVEY_QUESTION;
    }

    if (
      focusArea.focusAreaType === FocusArea.FocusAreaType.COMMENT &&
      focusArea.module === Module.SURVEY &&
      isNotNullNorUndefined(focusArea.surveyId) &&
      isNotNullNorUndefined(focusArea.commentId)
    ) {
      return Action.SourceType.SURVEY_COMMENT;
    }

    if (
      focusArea.focusAreaType === FocusArea.FocusAreaType.CATEGORY &&
      focusArea.module === Module.SURVEY &&
      isNotNullNorUndefined(focusArea.surveyId) &&
      isNotNullNorUndefined(focusArea.category)
    ) {
      return Action.SourceType.SURVEY_CATEGORY;
    }

    if (
      focusArea.focusAreaType === FocusArea.FocusAreaType.SEGMENT &&
      focusArea.module === Module.SURVEY &&
      isNotNullNorUndefined(focusArea.surveyId)
    ) {
      return Action.SourceType.SURVEY_SEGMENT;
    }

    if (
      focusArea.focusAreaType === FocusArea.FocusAreaType.INDIVIDUAL_RESULT &&
      focusArea.module === Module.SURVEY &&
      isNotNullNorUndefined(focusArea.surveyId)
    ) {
      return Action.SourceType.INDIVIDUAL_RESULT;
    }

    if (
      focusArea.focusAreaType === FocusArea.FocusAreaType.POST &&
      focusArea.module === Module.PULSE_POLL &&
      isNotNullNorUndefined(focusArea.pollId) &&
      isNotNullNorUndefined(focusArea.commentId)
    ) {
      return Action.SourceType.PULSE_POLL_COMMENT;
    }

    if (
      focusArea.focusAreaType === FocusArea.FocusAreaType.SEGMENT &&
      focusArea.module === Module.PULSE_POLL &&
      isNotNullNorUndefined(focusArea.pollId)
    ) {
      return Action.SourceType.PULSE_POLL_SEGMENT;
    }

    if (
      focusArea.focusAreaType === FocusArea.FocusAreaType.COMMENT &&
      focusArea.module === Module.EMPLOYEE_VOICE &&
      isNotNullNorUndefined(focusArea.commentId)
    ) {
      return Action.SourceType.EMPLOYEE_VOICE_COMMENT;
    }

    if (
      focusArea.focusAreaType === FocusArea.FocusAreaType.POST &&
      focusArea.module === Module.EMPLOYEE_VOICE &&
      isNotNullNorUndefined(focusArea.postId)
    ) {
      return Action.SourceType.EMPLOYEE_VOICE_POST;
    }
    
    if (
      focusArea.focusAreaType === FocusArea.FocusAreaType.CATEGORY &&
      focusArea.module === Module.DASHBOARD
    ) {
      return Action.SourceType.CATEGORY;
    }

    if (
      focusArea.focusAreaType === FocusArea.FocusAreaType.SEGMENT &&
      focusArea.module === Module.DASHBOARD
    ) {
      return Action.SourceType.SEGMENT;
    }
  }