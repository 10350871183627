import { Input as AntInput } from "antd";
import { InputProps } from "antd/lib/input";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { withFieldLabels } from "../withFieldLabels";

const StyledInput = styled(AntInput)<InputProps>`
  border-radius: 3px !important;
  margin: 4px 0 !important;
  :focus,
  :hover {
    border-color: ${Palette.bluePurple} !important;
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    padding-right: 3px !important;
  }
  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

const Input = withFieldLabels(StyledInput);

export default Input;
