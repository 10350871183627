import { default as networkActionCreators } from "@store/network/actions";
import { createRequest } from "@src/utils/networks";
import { PostCategoryResource } from "hyphen-lib/dist/domain/resource/post/PostCategoryResource";

export function fetchPostCategoriesIfNeeded() {
  return networkActionCreators.fetchAllPageIfNeeded({
    type: PostCategoryResource.TYPE,
    request: createRequest("/posts/categories"),
  });
}
