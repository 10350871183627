import React from "react";
import styled from "styled-components";
import { Form, Tooltip, Checkbox, Icon } from "antd";

import Select from "@src/components/core/Select";
import { SelectValue, SelectProps } from "antd/lib/select";
import { Map, Record as IRecord } from "immutable";
import { isNotNullNorUndefined, getOr, getProperty } from "hyphen-lib/dist/lang/Objects";
import { Paragraph } from "@src/components/core/Typography";
import { FormProps } from "antd/lib/form";
import {
  getQuestionTypeLabel,
  QuestionTypeLabel,
} from "hyphen-lib/dist/business/question/Questions";
import {
  QuestionType,
  QuestionConfig,
} from "hyphen-lib/dist/domain/common/QuestionType";
import { SurveyBankQuestionResource } from "hyphen-lib/dist/domain/resource/survey/SurveyBankQuestionResource";
import { PostCategoryResource } from "hyphen-lib/dist/domain/resource/post/PostCategoryResource";
import {
  QuestionSettings,
  QuestionSettingProps,
} from "@src/screens/Insights/components/Wizard/Questions/QuestionSettings";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { QuestionCategories } from "../QuestionCategories";
import {
  modalContainerStyle,
  SubmitButton,
  Choices,
  VerbatimAnalysisCheckbox,
} from "./modalComponents";
import {
  getLanguageInformationFromStringifiedTranslation,
  getParsed,
  getTranslatedSegmentFromStringifiedTranslation,
  getTranslationStatsForMultipleChoice,
  LabelWithFlag,
} from "@src/utils/translation";
import { MultiLanguageFooterStrip } from "../MultiLanguageFooterStrip";
import { TextArea } from "@components/core/TextArea";
import { LocaleCodes } from "hyphen-lib/dist/util/locale";
import Button from "@src/components/core/Button";
import withDebounce from "@src/components/core/withDebounce";
import Input from "@src/components/core/Input";
import { SurveyQuestionResource } from "hyphen-lib/dist/domain/resource/SurveyQuestionResource";
import { Trans } from "react-i18next";

// @ts-ignore
const DebouncedTextArea = withDebounce(TextArea);
const { Option } = Select;

export interface SurveyBankQuestionPayload {
  bankQuestionId: string;
  bankQuestionOrigin: string;
  allowComment: boolean;
  choices_t?: string[];
  question_t?: string;
  category_t?: string;
}

export interface QuestionBankModalProps {
  surveyBankQuestions: SurveyBankQuestionResource[];
  buttonDisabled: boolean;
  postCategories: PostCategoryResource[];
  questionConfig: QuestionConfig;

  onAddQuestion: (
    question: SurveyBankQuestionPayload,
    queryParamObject?: Record<string, string>,
    keepModalOpen?: boolean
  ) => void;
  readonly addUpdateQuestion: (
    questionId: string,
    question: SurveyQuestionResource,
    queryParams?: Record<string, string>,
    keepModalOpen?: boolean
  ) => void;
  isMultiSelectQuestionEnabled: boolean;
  readonly configuredSurveyLanguages: string[];
  readonly isMultiLanguageSurveyEnabled: boolean;
  readonly enableTranslation: boolean;
  readonly isUpdatingQuestion: boolean;
  readonly updatedQuestion?: SurveyQuestionResource;
}

interface QuestionBankModalState {
  category: string;
  allCategories: CategoryMap;
  selectedQuestion: IRecord<SurveyBankQuestionResource>;
  selectedSource: "All" | SurveyBankQuestionResource.SOURCE_TYPE;
  readonly questionTranslationMap?: Record<string, string>;
  readonly categoryTranslationMap?: Record<string, string>;
  translationEdit: {
    isEditingQuestionTranslation: boolean;
    isEditingCategoryTranslation: boolean;
    isEditingQuestionTypeTranslation: boolean;
  };
  languageSelectedOnSelectBox: string;
  choiceTranslations: { [key: number]: { [key: string]: string } };
  applyTranslationsGlobally: boolean;
  questionUnderEditing?: SurveyQuestionResource;
}

type Category = PostCategoryResource & {
  questions: QuestionBankModalProps["surveyBankQuestions"];
};

type CategoryMap = Map<string, Category>;

export class QuestionBankModal extends React.Component<
  QuestionBankModalProps,
  QuestionBankModalState
> {
  constructor(props: QuestionBankModalProps) {
    super(props);

    const { surveyBankQuestions, postCategories } = props;
    let allCategories = postCategories.reduce((acc: CategoryMap, item) => {
      return acc.set(item._id.toLowerCase(), {
        ...item,
        questions: [],
      });
    }, Map() as CategoryMap);
    surveyBankQuestions.forEach((question) => {
      if (!allCategories.has(question.category.toLowerCase())) {
        allCategories = allCategories.set(question.category.toLowerCase(), {
          _id: question.category,
          label: question.category,
          _type: PostCategoryResource.TYPE,
          questions: [question],
        });
      } else {
        const category = allCategories.get(question.category.toLowerCase()) as Category;
        category.questions.push(question);
        allCategories = allCategories.set(question.category.toLowerCase(), category);
      }
    });

    allCategories = allCategories.filter(
      (category) => category.questions.length > 0
    );
    const defaultSelectedCategory = allCategories
      .valueSeq()
      .first() as Partial<SurveyBankQuestionResource>;
    let defaultSelected;
    if(defaultSelectedCategory && defaultSelectedCategory._id) {
      defaultSelected = allCategories.get(
        defaultSelectedCategory._id.toLowerCase()
      );
    }
    const questions = getProperty(defaultSelected, "questions", []);

    let defaultLangSelected = "";
    if (isNotNullNorUndefined(this.props.configuredSurveyLanguages)) {
      defaultLangSelected = getOr(this.props.configuredSurveyLanguages[0], "");
    }

    this.state = {
      category: defaultSelectedCategory._id as string,
      allCategories,
      selectedQuestion: this.getQuestionFactory(questions[0]),
      selectedSource: "All",
      translationEdit: {
        isEditingQuestionTranslation: false,
        isEditingCategoryTranslation: false,
        isEditingQuestionTypeTranslation: false,
      },
      categoryTranslationMap: {},
      questionTranslationMap: {},
      languageSelectedOnSelectBox: defaultLangSelected,
      applyTranslationsGlobally: false,
      choiceTranslations: {},
    };
  }

  componentDidMount() {
    this.setState({
      questionUnderEditing: undefined,
    });
  }

  componentDidUpdate(prevProps: QuestionBankModalProps) {
    if (
      prevProps.isUpdatingQuestion === true &&
      this.props.isUpdatingQuestion === false
    ) {
      this.stopEditMode();
    }

    if (
      isNotNullNorUndefined(this.props.updatedQuestion) &&
      prevProps.updatedQuestion !== this.props.updatedQuestion
    ) {
      this.setState({
        questionUnderEditing: this.props.updatedQuestion,
      });
    }
  }

  showEditTranslationAreaForQuestion() {
    return (
      this.state.translationEdit.isEditingQuestionTranslation &&
      getOr(this.props.configuredSurveyLanguages, []).length > 0
    );
  }

  showEditTranslationForCategory() {
    return (
      this.state.translationEdit.isEditingCategoryTranslation &&
      getOr(this.props.configuredSurveyLanguages, []).length > 0
    );
  }

  showEditTranslationForQuestionType() {
    return (
      this.state.translationEdit.isEditingQuestionTypeTranslation &&
      getOr(this.props.configuredSurveyLanguages, []).length > 0
    );
  }

  getQuestionFactory = (question: SurveyBankQuestionResource) => {
    const selectedQuestion = IRecord({
      ...question,
    } as SurveyBankQuestionResource)();
    return selectedQuestion;
  };

  startEditMode(fieldName: keyof QuestionBankModalState["translationEdit"]) {
    const remainingKeys = Object.keys(this.state.translationEdit).filter(
      (field) => field !== fieldName
    );
    const toggledStates = {};
    for (const field of remainingKeys) {
      // @ts-ignore
      toggledStates[field] = false;
    }

    this.setState({
      // @ts-ignore
      translationEdit: {
        [fieldName]: true,
        ...toggledStates,
      },
    });
  }

  stopEditMode() {
    const keys = Object.keys(this.state.translationEdit);
    const toggledStates = {};
    for (const field of keys) {
      // @ts-ignore
      toggledStates[field] = false;
    }
    this.setState({
      // @ts-ignore
      translationEdit: toggledStates,
    });
  }

  getQuestionsByCategory = (categoryName: string) => {
    const { allCategories, selectedSource } = this.state;
    const category = allCategories.get(categoryName.toLowerCase()) as Category;

    if (selectedSource === "All") {
      return category.questions;
    } else {
      return category.questions.filter(
        (question) => question.sourceType === selectedSource
      );
    }
  };

  onCategoryChange = (value: SelectValue | any) => {
    const questions = this.getQuestionsByCategory(value);
    this.setState({
      category: value,
      selectedQuestion: this.getQuestionFactory(questions[0]),
    });
  };

  onSettingsChange = (
    setting: keyof SurveyBankQuestionResource,
    checked: boolean
  ) => {
    let { selectedQuestion } = this.state;
    selectedQuestion = selectedQuestion.set(setting, checked);
    this.setState({ selectedQuestion });
  };

  onQuestionChange = (value: any) => {
    const { category } = this.state;
    const questions = this.getQuestionsByCategory(category);
    let questionSource = {} as any;
    questions.forEach((question) => {
      if (question._id === value) {
        questionSource = question;
      }
    });

    this.setState({
      selectedQuestion: this.getQuestionFactory(questionSource),
    });
  };

  onSourceTypeChange = (value: any) => {
    this.setState({ selectedSource: value }, () => {
      const { category } = this.state;
      this.onCategoryChange(category);
    });
  };

  getAddQuestionPayload() {
    let { selectedQuestion } = this.state;

    if (selectedQuestion.get("type") === "likert") {
      selectedQuestion = selectedQuestion.set(
        "type",
        QuestionType.MULTIPLE_CHOICE as never
      );
    }

    const payload: SurveyBankQuestionPayload = {
      bankQuestionId: selectedQuestion.get("_id"),
      bankQuestionOrigin: selectedQuestion.get("origin"),
      allowComment: selectedQuestion.get("allowComment"),
    };

    return payload;
  }

  onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const { onAddQuestion } = this.props;
    const payload: SurveyBankQuestionPayload = this.getAddQuestionPayload();
    if (isNotNullNorUndefined(this.state.questionUnderEditing)) {
      this.props.addUpdateQuestion(this.state.questionUnderEditing._id, {
        ...this.state.questionUnderEditing,
        ...{ allowComment: payload.allowComment },
      });
    } else {
      onAddQuestion(payload);
    }
  };

  onSaveCategoryTranslation() {
    const payload: SurveyBankQuestionPayload = this.getAddQuestionPayload();
    payload.category_t = JSON.stringify(
      getOr(this.state.categoryTranslationMap, {})
    );

    if (isNotNullNorUndefined(this.state.questionUnderEditing)) {
      this.props.addUpdateQuestion(
        this.state.questionUnderEditing._id,
        {
          ...this.state.questionUnderEditing,
          // @ts-ignore
          category_t: payload.category_t,
        },
        {},
        true
      );
    } else {
      this.props.onAddQuestion(payload, {}, true);
    }
  }

  onSaveQuestionTranslation() {
    const payload: SurveyBankQuestionPayload = this.getAddQuestionPayload();
    payload.question_t = JSON.stringify(
      getOr(this.state.questionTranslationMap, {})
    );
    if (isNotNullNorUndefined(this.state.questionUnderEditing)) {
      this.props.addUpdateQuestion(
        this.state.questionUnderEditing._id,
        {
          ...this.state.questionUnderEditing,
          question_t: payload.question_t,
        },
        {},
        true
      );
    } else {
      this.props.onAddQuestion(payload, {}, true);
    }
  }

  onSaveLikertTypeTranslation() {
    const payload: SurveyBankQuestionPayload = this.getAddQuestionPayload();
    payload.choices_t = this.getLikertTypeTranslationPayload();
    if (isNotNullNorUndefined(this.state.questionUnderEditing)) {
      this.props.addUpdateQuestion(
        this.state.questionUnderEditing._id,
        {
          ...this.state.questionUnderEditing,
          choices_t: payload.choices_t,
        } as SurveyQuestionResource.MultipleChoice,
        {
          applyForAllChoices: this.state.applyTranslationsGlobally.toString(),
        },
        true
      );
    } else {
      this.props.onAddQuestion(
        payload,
        {
          applyForAllChoices: this.state.applyTranslationsGlobally.toString(),
        },
        true
      );
    }
  }

  getLikertTypeTranslationPayload() {
    const sortedKeys = Object.keys(this.state.choiceTranslations)
      .map((item) => parseInt(item, 10))
      .sort();
    const finalChoiceList: string[] = [];

    sortedKeys.forEach((key) => {
      finalChoiceList.push(JSON.stringify(this.state.choiceTranslations[key]));
    });

    return finalChoiceList;
  }

  get defaultAllowedQuestionFlags() {
    const { selectedQuestion } = this.state;
    return {
      allowComment: {
        checked: selectedQuestion.get("allowComment"),
        disabled: false,
        label: "Allow comments",
        info: "Decide whether or not employees can submit comments with this question.",
      },
      isSentimentAnalysisQuestion: {
        checked: selectedQuestion.get("isSentimentAnalysisQuestion"),
        disabled: not(selectedQuestion.get("allowComment")),
        label: "Include Verbatim in Sentiment Analysis",
        info: "Check if the answers to this question need to be analyzed for sentiment",
      },
      isNPSQuestion: {
        checked: true,
        disabled: true,
        info: "All eNPS questions are included in eNPS calculations",
        label: "Include in eNPS score calculation",
      },
      isOutcomeQuestion: {
        checked: selectedQuestion.get("isOutcomeQuestion"),
        disabled: isNotNullNorUndefined(selectedQuestion),
        info: "Check if this is a question which implies the outcome or goal of the survey",
        label: "Set as Driver Impact Analysis outcome question",
      },
      isRatingQuestion: {
        checked: selectedQuestion.get("isRatingQuestion"),
        disabled: true,
        info: "All Likert Scale questions are included in Favorability calculations",
        label: "Include in favorability calculation",
      },
    };
  }

  get questionSettingsProps(): QuestionSettingProps {
    const { selectedQuestion } = this.state;
    const { isMultiSelectQuestionEnabled, questionConfig } = this.props;

    const isMultiSelect =
      getOr(selectedQuestion.get("isMultiSelect"), false) &&
      isMultiSelectQuestionEnabled;
    const maxNumberOfOptions = selectedQuestion.get("maxNumberOfOptions");
    const choices = getOr(selectedQuestion.get("choices"), []);

    const questionTypeLabel = getQuestionTypeLabel(
      selectedQuestion.get("type"),
      questionConfig,
      choices,
      selectedQuestion.get("isRatingQuestion"),
      selectedQuestion.get("isNPSQuestion"),
      isMultiSelect
    );

    type DefaultAllowedQuestionFlags = Partial<
      QuestionBankModal["defaultAllowedQuestionFlags"]
    >;

    const defaultAllowedQuestionFlags: DefaultAllowedQuestionFlags =
      Object.assign({}, this.defaultAllowedQuestionFlags);

    switch (questionTypeLabel) {
      case QuestionTypeLabel.Likert:
        delete defaultAllowedQuestionFlags.isNPSQuestion;
        break;
      case QuestionTypeLabel.YesNo:
        delete defaultAllowedQuestionFlags.isRatingQuestion;
        delete defaultAllowedQuestionFlags.isNPSQuestion;
        delete defaultAllowedQuestionFlags.isOutcomeQuestion;
        break;
      case QuestionTypeLabel.ENPS:
        delete defaultAllowedQuestionFlags.isRatingQuestion;
        delete defaultAllowedQuestionFlags.isOutcomeQuestion;
        break;
      case QuestionTypeLabel.Custom:
      case QuestionTypeLabel.MultiSelect:
        delete defaultAllowedQuestionFlags.isOutcomeQuestion;
        delete defaultAllowedQuestionFlags.isNPSQuestion;
        delete defaultAllowedQuestionFlags.isRatingQuestion;
        break;
      case QuestionTypeLabel.Unknown:
      default:
        delete defaultAllowedQuestionFlags.isNPSQuestion;
        break;
    }

    const commonProps = {
      toggle: defaultAllowedQuestionFlags,
      isMultiSelect,
      maxNumberOfOptions,
      maxNumberOfOptionsDisabled: true,
      onSettingsChange: this.onSettingsChange,
    };

    return commonProps;
  }

  categoryTranslationSegment() {
    const { totalLanguages, totalTranslated } =
      getLanguageInformationFromStringifiedTranslation(
        this.props.configuredSurveyLanguages,
        this.state.questionUnderEditing?.category_t
      );
    return (
      <>
        {this.props.isMultiLanguageSurveyEnabled &&
          this.props.enableTranslation &&
          !this.showEditTranslationForCategory() && (
            <MultiLanguageFooterStrip
              languagesReady={totalTranslated}
              totalLanguages={totalLanguages}
              onEditTranslations={() => {
                this.startEditMode("isEditingCategoryTranslation");
              }}
            />
          )}
        {this.showEditTranslationForCategory() &&
          this.props.configuredSurveyLanguages?.map((code, index) => (
            <div className="mb-24p" key={index}>
              <DebouncedTextArea
                key={code}
                value={getTranslatedSegmentFromStringifiedTranslation(
                  code as LocaleCodes,
                  this.state.questionUnderEditing?.category_t
                )}
                label={LabelWithFlag(code as LocaleCodes)}
                onValueChange={(value: string) => {
                  this.setState({
                    categoryTranslationMap: {
                      ...this.state.categoryTranslationMap,
                      [code]: value,
                    },
                  });
                }}
              />
            </div>
          ))}

        {this.showEditTranslationForCategory() && (
          <div className="d-flex justify-content-end">
            <Button
              color="blue"
              style={{ height: "30px" }}
              onClick={() => {
                this.onSaveCategoryTranslation();
              }}
              translate="yes"
            >
              Save translations
            </Button>
          </div>
        )}
      </>
    );
  }

  onChangeLikertType =
    (choiceIndex: number, languageCode: LocaleCodes) => (event: any) => {
      const translatedValue: string = event.target.value;
      if (this.state.choiceTranslations[choiceIndex]) {
        const existingTranslationMap =
          this.state.choiceTranslations[choiceIndex];
        existingTranslationMap[languageCode] = translatedValue;
        this.setState({
          choiceTranslations: {
            ...this.state.choiceTranslations,
            [choiceIndex]: existingTranslationMap,
          },
        });
      } else {
        const existingChoiceTranslations = this.state.choiceTranslations;
        existingChoiceTranslations[choiceIndex] = {
          [languageCode]: translatedValue,
        };
        this.setState({
          choiceTranslations: {
            ...this.state.choiceTranslations,
            ...existingChoiceTranslations,
          },
        });
      }
    };

  renderLikertTypeTranslationView(choices: string[]) {
    const confSurveyLangs = getOr(this.props.configuredSurveyLanguages, []);
    const selectedQuestionType = this.state.selectedQuestion.get("type");

    return (
      <>
        {this.showEditTranslationForQuestionType() &&
          confSurveyLangs.length > 0 && (
            <div className="d-flex flex-row" style={{ marginTop: "20px" }}>
              <div
                className="d-flex align-items-center"
                style={{ width: "30%" }}
              >
                English
              </div>
              <div style={{ width: "70%", marginLeft: "10px" }}>
                <Select
                  size={"large"}
                  defaultValue={this.state.languageSelectedOnSelectBox}
                  onChange={(value) =>
                    this.setState({
                      languageSelectedOnSelectBox: value.toString(),
                    })
                  }
                >
                  {this.props.configuredSurveyLanguages?.map((code, index) => {
                    return (
                      <Option value={code} key={index}>
                        {LabelWithFlag(code as LocaleCodes)}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          )}

        {this.showEditTranslationForQuestionType() &&
          choices.map((choice, key) => {
            let catTranslation = "";
            if (isNotNullNorUndefined(this.state.questionUnderEditing)) {
              const qR = this.state
                .questionUnderEditing as SurveyQuestionResource.MultipleChoice;
              if (isNotNullNorUndefined(qR.choices_t)) {
                const pChoices = getParsed(qR.choices_t[key]);
                catTranslation = getTranslatedSegmentFromStringifiedTranslation(
                  this.state.languageSelectedOnSelectBox as LocaleCodes,
                  pChoices
                );
              }
            }
            return (
              <div
                className="d-flex flex-row"
                key={key + this.state.languageSelectedOnSelectBox}
              >
                <div
                  className="d-flex align-items-center"
                  style={{
                    width: "30%",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      color: "#7A8891",
                      lineHeight: "16px",
                    }}
                  >
                    <Tooltip title={choice} placement={"topLeft"}>
                      {choice}
                    </Tooltip>
                  </span>
                </div>
                <div style={{ width: "70%", marginLeft: "10px" }}>
                  <Input
                    key={key}
                    onChange={this.onChangeLikertType(
                      key,
                      this.state.languageSelectedOnSelectBox as LocaleCodes
                    )}
                    defaultValue={catTranslation}
                    size={"large"}
                  />
                </div>
              </div>
            );
          })}

        {this.showEditTranslationForQuestionType() &&
          selectedQuestionType !== QuestionType.MULTI_SELECT &&
          selectedQuestionType !== QuestionType.MULTIPLE_CHOICE && (
            <Checkbox
              style={{ marginTop: "20px", marginBottom: "16px" }}
              checked={this.state.applyTranslationsGlobally}
              onClick={() => {
                this.setState({
                  applyTranslationsGlobally:
                    !this.state.applyTranslationsGlobally,
                });
              }}
            >
              <span>
                <Trans>Use these translations to all</Trans> {selectedQuestionType} 
                <Trans>questions in this survey</Trans>
              </span>
              <span style={{ paddingLeft: "0.5em" }}>
                <Tooltip
                  /* eslint-disable max-len */
                  title={<>{selectedQuestionType} - <Trans>If there are any other questions already added, their scale translations will be overwritten.</Trans></>}
                >
                  <Icon type="info-circle" />
                </Tooltip>
              </span>
            </Checkbox>
          )}
      </>
    );
  }

  renderSaveBtnForLikertTypeTranslationView() {
    return (
      this.showEditTranslationForQuestionType() && (
        <div className="d-flex justify-content-end">
          <Button
            color="blue"
            style={{ height: "30px" }}
            onClick={() => {
              this.onSaveLikertTypeTranslation();
            }}
            translate="yes"
          >
            Save translations
          </Button>
        </div>
      )
    );
  }

  getTranslationStatsForLikertTypeQuestions(choices: string[]) {
    let translated = 0;
    // let total = 0;

    const qR = this.state
      .questionUnderEditing as SurveyQuestionResource.MultipleChoice;
    if (isNotNullNorUndefined(qR)) {
      const choiceTr = getOr(qR.choices_t, []);
      const { totalTranslated } = getTranslationStatsForMultipleChoice(
        choices.length,
        choiceTr,
        getOr(this.props.configuredSurveyLanguages, [])
      );
      // total = totalLanguages;
      translated = totalTranslated;
    }

    return {
      totalLanguagesMS: this.props.configuredSurveyLanguages.length,
      totalTranslatedMS: translated,
    };
  }
  render() {
    const { allCategories, category, selectedQuestion, selectedSource } =
      this.state;
    const { buttonDisabled, isMultiSelectQuestionEnabled, questionConfig } =
      this.props;
    const postCategories: PostCategoryResource[] = [];
    const questions = this.getQuestionsByCategory(category);
    allCategories.forEach((value) => postCategories.push(value));
    const choices = isNotNullNorUndefined(selectedQuestion.get("choices"))
      ? selectedQuestion.get("choices")!.filter((value) => value.length > 0)
      : [];
    const sourceTypes = [
      { label: "All", value: "All" },
      {
        label: "Engage Templates",
        value: SurveyBankQuestionResource.SOURCE_TYPE.TEMPLATE,
      },
      {
        label: "My Questions",
        value: SurveyBankQuestionResource.SOURCE_TYPE.CUSTOM,
      },
    ];
    const selectedQuestionResource = selectedQuestion.toJS();
    const isMultiSelect =
      getOr(selectedQuestion.get("isMultiSelect"), false) &&
      isMultiSelectQuestionEnabled;
    const questionType = getQuestionTypeLabel(
      selectedQuestionResource.type,
      questionConfig,
      selectedQuestionResource.choices,
      selectedQuestionResource.isRatingQuestion,
      selectedQuestionResource.isNPSQuestion,
      isMultiSelect
    );
    const showQuestionSettings = questionType !== QuestionTypeLabel.Open;
    const translatedQuestionInfo =
      getLanguageInformationFromStringifiedTranslation(
        this.props.configuredSurveyLanguages,
        this.state.questionUnderEditing?.question_t
      );

    const { totalLanguagesMS, totalTranslatedMS } =
      this.getTranslationStatsForLikertTypeQuestions(choices);

    return (
      <Container onSubmit={this.onSubmit}>
        <SourceSelect
          label="Select Source"
          value={selectedSource}
          defaultValue="All"
          onChange={this.onSourceTypeChange}
          data-jest="questionBank_sourceSelect"
        >
          {sourceTypes.map((source) => {
            return (
              <Option
                key={source.value}
                value={source.value}
                title={source.label}
              >
                <Trans>{source.label}</Trans>
              </Option>
            );
          })}
        </SourceSelect>

        <div
          className={
            this.showEditTranslationForCategory()
              ? "categoryT mt-16p"
              : "mt-16p"
          }
        >
          <div
            className={this.showEditTranslationForCategory() ? "mb-24p" : ""}
          >
            <QuestionCategories
              value={category}
              label="Category"
              onChange={this.onCategoryChange}
              categories={postCategories}
            />
          </div>
          {this.categoryTranslationSegment()}
        </div>
        <div
          className={
            this.showEditTranslationAreaForQuestion() ? "questionT" : ""
          }
        >
          <div
            className={
              this.showEditTranslationAreaForQuestion() ? "mb-24p" : ""
            }
          >
            <QuestionsSelect
              label="Question"
              value={
                isNotNullNorUndefined(selectedQuestion)
                  ? selectedQuestion.get("_id")
                  : undefined
              }
              onChange={this.onQuestionChange}
              data-jest="questionBank_questionSelect"
            >
              {questions.map((question: SurveyBankQuestionResource) => {
                const questionTypeLabel = getQuestionTypeLabel(
                  question.type,
                  questionConfig,
                  getOr(question.choices, []),
                  question.isRatingQuestion,
                  question.isNPSQuestion,
                  question.isMultiSelect && isMultiSelectQuestionEnabled
                );
                return (
                  <Option
                    key={question._id}
                    value={question._id}
                    title={question.question}
                  >
                    ({questionTypeLabel}) {question.question}
                  </Option>
                );
              })}
            </QuestionsSelect>
          </div>
          {this.props.isMultiLanguageSurveyEnabled &&
            this.props.enableTranslation &&
            !this.showEditTranslationAreaForQuestion() && (
              <MultiLanguageFooterStrip
                languagesReady={translatedQuestionInfo.totalTranslated}
                totalLanguages={translatedQuestionInfo.totalLanguages}
                onEditTranslations={() => {
                  this.startEditMode("isEditingQuestionTranslation");
                }}
              />
            )}
          {this.showEditTranslationAreaForQuestion() &&
            this.props.configuredSurveyLanguages?.map((code, index) => (
              <div className="mb-24p" key={index}>
                <DebouncedTextArea
                  key={code}
                  label={LabelWithFlag(code as LocaleCodes)}
                  value={getTranslatedSegmentFromStringifiedTranslation(
                    code as LocaleCodes,
                    this.state.questionUnderEditing?.question_t
                  )}
                  onValueChange={(value) => {
                    this.setState({
                      questionTranslationMap: {
                        ...getOr(this.state.questionTranslationMap, {}),
                        [code]: value,
                      },
                    });
                  }}
                  // @ts-ignore
                  rows={5}
                />
              </div>
            ))}

          {this.showEditTranslationAreaForQuestion() && (
            <div className="d-flex justify-content-end">
              <Button
                color="blue"
                style={{ height: "30px" }}
                onClick={() => {
                  this.onSaveQuestionTranslation();
                }}
                translate="yes"
              >
                Save translations
              </Button>
            </div>
          )}
        </div>
        {!showQuestionSettings && (
          <StyledVerbatimAnalysisCheckbox
            disabled={true}
            checked={selectedQuestion.get("isSentimentAnalysisQuestion")}
          />
        )}
        <div
          className={
            this.showEditTranslationForQuestionType()
              ? "questionTypeT mt-16p"
              : ""
          }
        >
          <div
            className={
              this.showEditTranslationForQuestionType() ? "d-none" : ""
            }
          >
            {choices.length > 0 && (
              <AnswersContainer>
                <Paragraph><Trans>Answers</Trans>:</Paragraph>
                {choices.map((choice, index) => (
                  <Choices label={choice} key={index} />
                ))}
              </AnswersContainer>
            )}
          </div>
          {this.renderLikertTypeTranslationView(choices)}
          {this.renderSaveBtnForLikertTypeTranslationView()}
          {choices.length > 0 &&
            this.props.isMultiLanguageSurveyEnabled &&
            !this.showEditTranslationForQuestionType() && (
              <MultiLanguageFooterStrip
                languagesReady={totalTranslatedMS}
                totalLanguages={totalLanguagesMS}
                onEditTranslations={() => {
                  this.startEditMode("isEditingQuestionTypeTranslation");
                }}
              />
            )}
        </div>
        {showQuestionSettings && (
          <QuestionSettings {...this.questionSettingsProps} />
        )}
        <SubmitButton
          disabled={buttonDisabled}
          htmlType="submit"
          title={buttonDisabled ? "Adding..." : "Add question"}
          translate="yes"
        />
      </Container>
    );
  }
}

const Container = styled(Form)<FormProps>`
  ${modalContainerStyle}
`;

const AnswersContainer = styled.div`
  margin-top: 16px;
`;

const QuestionsSelect = styled(Select)<
  SelectProps & { children: React.ReactNode }
>`
  margin-top: 16px;
`;

export const SourceSelect = styled(Select)<
  SelectProps & { children: React.ReactNode }
>`
  margin-bottom: 16px;
`;

const StyledVerbatimAnalysisCheckbox = styled(VerbatimAnalysisCheckbox)`
  margin-top: 16px;
`;
