import React from "react";
import { Table, Icon } from "antd";
import { Trans } from "react-i18next";
import {
  DndProvider,
  DragSource,
  DropTarget,
  DropTargetMonitor,
  ConnectDropTarget,
  ConnectDragSource,
} from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { newLineRegex } from "@src/utils/constants/SurveyCreation";
import styled from "styled-components";
import { TableProps } from "antd/lib/table";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";

import Palette from "@src/config/theme/palette";
import { Paragraph } from "@components/core/Typography";
import DragIndicator from "@components/core/svg/DragIndicator";

import { SurveyQuestionResource } from "hyphen-lib/dist/domain/resource/SurveyQuestionResource";
import { getQuestionTypeLabel } from "hyphen-lib/dist/business/question/Questions";
import Tooltip from "@components/core/Tooltip";
import { getProperty } from "hyphen-lib/dist/lang/Objects";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { QuestionConfig } from "hyphen-lib/dist/domain/common/QuestionType";
import { IsMultiSelectContext } from "../../containers/SurveyEdit";
import { Question } from "../../containers/SurveyQuestions";
import { getOverallQuestionTranslationStats } from "@src/utils/translation";
import { MultiLanguageFooterStrip } from "../MultiLanguageFooterStrip";
import NoResult from "@src/components/core/NoResult";

const { Column } = Table;

export interface QuestionListProps {
  data: SurveyQuestionResource[];
  questionConfig: QuestionConfig;
  disabled?: boolean;

  onActionClick: (question: any, action: string) => void;
  onReorderQuestion: (
    selectedQuestionId: string,
    fromIndex: number,
    moveToIndex: number
  ) => void;
  configuredSurveyLanguages: string[];
  isMultiLanguageSurveyEnabled: boolean;
}

interface QuestionActionsProps {
  isHovered: boolean;
  disabled?: boolean;

  onEdit: () => void;
  onRemove: () => void;
}

export interface QuestionSettingsProps {
  record: Question;
}

function QuestionActions(props: QuestionActionsProps) {
  const { isHovered, onEdit, onRemove, disabled } = props;
  if (disabled === true) {
    return isHovered ? (
      <ActionsContainer>
        <span data-cy="question_edit" onClick={onEdit}><Trans>Edit</Trans></span>
      </ActionsContainer>
    ) : null;
  }
  return isHovered ? (
    <ActionsContainer>
      <span data-cy="question_edit" onClick={onEdit}><Trans>Edit</Trans></span>
      <span data-cy="question_remove" onClick={onRemove}><Trans>Remove</Trans></span>
    </ActionsContainer>
  ) : null;
}

function QuestionSettings(props: QuestionSettingsProps) {
  const { record } = props;
  const settings = [] as any;

  if (getProperty(record, "isNPSQuestion", false)) {
    settings.push("NPS");
  }
  if (getProperty(record, "isOutcomeQuestion", false)) {
    settings.push("Outcome");
  }
  if (getProperty(record, "isRatingQuestion", false)) {
    settings.push("Rating");
  }
  if (getProperty(record, "allowComment", false)) {
    settings.push("Allow Comments");
  }
  return isNotEmptyArray(settings) ? (
    <QuestionSettingsContainer>
      {settings.map((setting, index) => (
        <Setting key={index}>
          <Icon type="check" /> <Trans>{setting}</Trans>
        </Setting>
      ))}
    </QuestionSettingsContainer>
  ) : null;
}

export interface QuestionLanguageInformationProps {
  record: Question;
  configuredSurveyLanguages: string[];
}

function LanguageInformation(props: QuestionLanguageInformationProps) {
  const { record, configuredSurveyLanguages } = props;
  const { totalLanguages, totalTranslated } =
    getOverallQuestionTranslationStats(record, configuredSurveyLanguages);
  return (
    <LanguageSettingsContainer>
      <MultiLanguageFooterStrip
        languagesReady={totalTranslated}
        totalLanguages={totalLanguages}
        showEditTranslationButton={false}
        allBold={false}
      />
    </LanguageSettingsContainer>
  );
}
export interface RowProps {
  id: any;
  text: string;
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
}

interface RowSourceCollectedProps {
  isOver: boolean;
  connectDragSource: ConnectDragSource;
}

interface RowTargetCollectedProps {
  connectDropTarget: ConnectDropTarget;
}

class BodyRow extends React.Component<
  RowProps & RowSourceCollectedProps & RowTargetCollectedProps
> {
  render() {
    const {
      connectDragSource,
      connectDropTarget,
      isOver,
      moveRow,
      ...restProps
    } = this.props;

    return connectDragSource(connectDropTarget(<tr {...restProps} />));
  }
}

const rowSource = {
  beginDrag(props: RowProps) {
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  drop(props: RowProps, monitor: DropTargetMonitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};

const DragableBodyRow = DropTarget("row", rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource("row", rowSource, (connect) => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow)
);

// tslint:disable-next-line: max-classes-per-file
export default class QuestionsList extends React.Component<QuestionListProps> {
  state = {
    hoverRowIndex: null,
  };

  moveRow = (dragIndex: number, hoverIndex: number) => {
    const { data, onReorderQuestion } = this.props;
    const dragRow = data[dragIndex];

    onReorderQuestion(dragRow._id, dragIndex, hoverIndex);
  };

  onRow = (record: any, rowIndex: number) => {
    const { disabled } = this.props;

    return {
      index: rowIndex,
      ...(not(disabled) && { moveRow: this.moveRow }),
      onMouseOver: () => this.setState({ hoverRowIndex: rowIndex }),
      onMouseLeave: () => this.setState({ hoverRowIndex: null }),
    };
  };

  render() {
    const { data, onActionClick, disabled, questionConfig } = this.props;
    const { hoverRowIndex } = this.state;
    const areActionsAllowed = not(disabled);

    const components = areActionsAllowed
      ? {
        body: {
          row: DragableBodyRow,
        },
      }
      : {};

    return (
      <IsMultiSelectContext.Consumer>
        {(isMultiSelectQuestionEnabled) => (
          <DndProvider backend={HTML5Backend}>
            <QuestionsTable
              // @ts-ignore
              dataSource={data}
              components={components}
              onRow={this.onRow}
              pagination={false}
              rowKey={"_id"}
              data-cy="question_table"
              locale={{
                emptyText: (<NoResult type="minimal" description="No Data" />),
              }}
            >
              <Column
                title={<Trans>Question</Trans>}
                dataIndex="question"
                width="40%"
                // tslint:disable-next-line:jsx-no-lambda
                render={(text, record: Question, index) => {
                  const questionTypeLabel = getQuestionTypeLabel(
                    record.type,
                    questionConfig,
                    // fixme: May require a recource change instead of getProperty
                    getProperty(record, "choices"),
                    getProperty(record, "isRatingQuestion", true),
                    getProperty(record, "isNPSQuestion", false),
                    getProperty(record, "isMultiSelect", false) &&
                    isMultiSelectQuestionEnabled
                  );
                  return (
                    <QuestionCell>
                      {areActionsAllowed && (
                        <DragIconDiv isHovering={index === hoverRowIndex}>
                          <DragIndicator />
                        </DragIconDiv>
                      )}
                      <QuestionText>
                        {text
                          .split(newLineRegex)
                          .map((para: string, i: number) => {
                            return (
                              <Paragraph
                                data-jest={`question_text_${i}`}
                                key={i + 1}
                              >
                                {para}
                              </Paragraph>
                            );
                          })}
                        <Paragraph weight="light">
                          {questionTypeLabel} • {record.category}
                        </Paragraph>
                      </QuestionText>
                    </QuestionCell>
                  );
                }}
              />
              <Column
                title={
                  <div>
                    <Trans>Question settings</Trans>
                    <Tooltip
                      title={
                        <TooltipContainer>
                          <div>
                            <Trans>Specifies different settings associated with each
                            question.</Trans>
                          </div>
                          <div>
                            {/* tslint:disable-next-line:max-line-length */}
                            <strong><Trans>Allow comments</Trans></strong> - <Trans>Specifies if an
                            optional comment is associated with a multiple
                            choice question</Trans>
                          </div>
                          <div>
                            {/* tslint:disable-next-line:max-line-length */}
                            <strong><Trans>Rating</Trans></strong> - <Trans>Specifies if favorability
                            is calculated on a question and is a Driver Impact
                            Analysis rating question</Trans>
                          </div>
                          <div>
                            <strong><Trans>Outcome</Trans></strong> - <Trans>Specifies if it is a
                            Driver Impact Analysis outcome question</Trans>
                          </div>
                        </TooltipContainer>
                      }
                    >
                      <HelpIcon type="info-circle" />
                    </Tooltip>
                  </div>
                }
                width="20%"
                dataIndex="settings"
                // tslint:disable-next-line:jsx-no-lambda
                render={(settings, record: QuestionSettingsProps["record"]) => (
                  <QuestionSettings record={record} />
                )}
              />
              {this.props.isMultiLanguageSurveyEnabled && (
                <Column
                  title={
                    <div>
                      <Trans>Languages</Trans>
                      <Tooltip
                        title={
                          <TooltipContainer>
                            <div><Trans>
                              Specifies the language translations that are added
                              to the survey. A language is shown to be ready if
                              all the question, category and answer option
                              translations are added for that particular
                              language
                            </Trans></div>
                          </TooltipContainer>
                        }
                      >
                        <HelpIcon type="info-circle" />
                      </Tooltip>
                    </div>
                  }
                  dataIndex="languages"
                  width="25%"
                  render={(
                    settings,
                    record: QuestionSettingsProps["record"]
                  ) => (
                    <LanguageInformation
                      record={record}
                      configuredSurveyLanguages={
                        this.props.configuredSurveyLanguages
                      }
                    />
                  )}
                />
              )}
              <Column
                title=""
                width="15%"
                dataIndex="actions"
                // tslint:disable-next-line:jsx-no-lambda
                render={(text, record: Question, index) => (
                  <QuestionActions
                    isHovered={index === hoverRowIndex}
                    onEdit={onActionClick.bind(null, record, "edit")}
                    onRemove={onActionClick.bind(null, record, "remove")}
                    disabled={disabled}
                  />
                )}
              />
            </QuestionsTable>
          </DndProvider>
        )}
      </IsMultiSelectContext.Consumer>
    );
  }
}

const QuestionsTable = styled(Table) <TableProps<QuestionListProps>>`
  th {
    background-color: white !important;
    color: ${Palette.darkBlueGrey} !important;
    font-weight: bold !important;
    padding-left: 32px !important;
    border-bottom: 1px solid ${Palette.lightGreyBlue} !important;
  }

  tr {
    td:first-child {
      padding-left: 32px !important;
    }
  }
  tbody > tr {
    cursor: pointer;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 120px;

  span {
    cursor: pointer;
  }

  span:first-child {
    color: ${Palette.darkModerateBlue};
  }

  span:last-child {
    color: ${Palette.darkPink};
  }
`;

const HelpIcon = styled(Icon)`
  margin-left: 8px;
  cursor: pointer !important;
  svg {
    fill: ${Palette.bluishGrey};
  }
`;

const Setting = styled.div`
  display: flex;
  align-items: center;
  i {
    margin-right: 8px;
    svg {
      height: 8px;
    }
  }
`;

const QuestionCell = styled.div`
  min-height: 89px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const DragIconDiv = styled.div<{ isHovering: boolean }>`
  display: ${(props) => (props.isHovering ? "block" : "none")};
  padding-right: 10px;
`;

const QuestionText = styled.div`
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  p {
    overflow-wrap: break-word;
    word-break: break-word;
  }
`;

const QuestionSettingsContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;

  div {
    flex-basis: 50%;
  }
`;

const LanguageSettingsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`;

const TooltipContainer = styled.div`
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: #ffffff;
`;
