import Button from "@src/components/core/Button";
import Difficulty from "@src/components/core/Difficulty/Difficulty";
import CalendarSvg from "@src/components/core/svg/CalendarSvg";
import Palette from "@src/config/theme/palette";
import { Rate } from "antd";
import { ActionPlanTemplateResource } from "@hyphen-lib/domain/resource/action/ActionPlanTemplateResource";
import { GlobalActionPlanTemplate } from "@hyphen-lib/domain/GlobalActionPlanTemplate";
import React from "react";
import styled from "styled-components";
import { PreviewTemplateModal } from "@src/screens/Insights/ActionPlans/components/PreviewTemplateModal";
import { Trans } from "react-i18next";

interface ActionPlanCardProps {
  suggestedActionPlan: ActionPlanTemplateResource;
  focusAreaId: string;
  onSelectActionPlanTemplate: (action: ActionPlanTemplateResource, focusAreaId: string) => void;
}

interface ActionPlanCardState {
  showPreviewModal: boolean;
}

const difficultyToNumber = Object.freeze({
  [GlobalActionPlanTemplate.DifficultyType.EASY]: 1,
  [GlobalActionPlanTemplate.DifficultyType.INTERMEDIATE]: 2,
  [GlobalActionPlanTemplate.DifficultyType.HARD]: 3,
});
class ActionPlanCard extends React.Component<ActionPlanCardProps, ActionPlanCardState> {

  state = {
    showPreviewModal: false,
  };

  onSelectActionPlanTemplate = () => {
    this.props.onSelectActionPlanTemplate(this.props.suggestedActionPlan, this.props.focusAreaId);
  };

  closePreviewModal = () => {
    this.setState({ showPreviewModal: false });
  };

  openPreviewModal = () => {
    this.setState({ showPreviewModal: true });
  };

  showPreviewModal = (actionForPreview: ActionPlanTemplateResource) => {
    return this.state.showPreviewModal && (<PreviewTemplateModal
      visible={this.state.showPreviewModal}
      {...actionForPreview}
      onCancel={this.closePreviewModal}
      footer={[
        <Button
          onClick={this.closePreviewModal}
          key="cancel"
          color="grey">
          <Trans>Cancel</Trans>
        </Button>,
        <Button
          onClick={this.onSelectActionPlanTemplate}
          key="create"
          color="blue"
          translate="yes">
          <Trans>Create</Trans>
        </Button>,
      ]}
    />
    );
  };
  
  render() {
    const { suggestedActionPlan: {
        action,
        rating,
        difficulty,
        length,
      },
    } = this.props;

    return (
      <Card >
        <ActionTitle>{action}</ActionTitle>
        <ActionMeta>
          <VerticalFlexContainer showBorder={false}>
            <SmallText>
              <IconStyle>
                <CalendarSvg />
              </IconStyle>
              {length} <Trans>term</Trans>
            </SmallText>
            <SmallText><Difficulty translate="yes" level={difficultyToNumber[difficulty]}/></SmallText>
          </VerticalFlexContainer>
          <VerticalFlexContainer showBorder>
            <Rating><Trans>User Rating</Trans></Rating>
            <Rate
              disabled
              value={rating || 0}
            />
          </VerticalFlexContainer>
        </ActionMeta>
        <UserActions>
          { this.showPreviewModal(this.props.suggestedActionPlan) }
          <PreviewButton color="blue" onClick={this.openPreviewModal} translate="yes">
            Preview
          </PreviewButton>
          <SelectButton data-cy="selectActionPlanCard" color="blue" 
          onClick={this.onSelectActionPlanTemplate} translate="yes">
            Select this
          </SelectButton>
        </UserActions>
      </Card>
    );
  }
}

const IconStyle = styled.span`
  display: inline-flex;
  width: 15px;
  margin-right: 3px;
  position: relative;
  top: 4px;
`;
const ActionTitle = styled.div`
  color: ${Palette.veryDarkBlueGrey};
  font-weight: bold;
  line-height: 20px;
  font-size: 15px;
`;

const ActionMeta = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
`;

const Card = styled.div`
  aspect-ratio: 1.683;
  width: 32%;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 4px 8px 0 rgba(198,214,223,0.3);
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 1.3%;
`;

const VerticalFlexContainer = styled.div<{showBorder?: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 0 4px 8px 0 rgba(198,214,223,0.15);
  ${props => props.showBorder ? `border: 1px solid ${Palette.pattensBlue}`: ""};
  border-radius: 6px;
  padding: 5px;
  background-color: #FFFFFF;
  li.ant-rate-star:not(:last-child) {
    margin-right: 4px;
  }
  li.ant-rate-star {
    font-size: 12px;
  }

  ul.ant-rate {
    font-size: unset;
  }
`;

const SmallText = styled.div`
  font-size: 12px;
  color: ${Palette.veryDarkBlueGrey};
  line-height: 16px;
`;

const Rating = styled(SmallText)`
  text-align: center;
`;

const UserActions = styled.div`
  font-size: 15px;
  display: flex;
  justify-content: flex-end;
`;

const SelectButton = styled(Button)`
  height: 30px !important;
  // width: 101px;
  border: 1px solid #00B8CC;
  border-radius: 2px;
  background-color: ${Palette.darkTurqoise};
  color: #FFFFFF;
  font-weight: bold;
  line-height: 12px;
  text-align: center;
`;


const PreviewButton = styled(Button)`
  height: 30px !important;
  width: 88px;
  color: ${Palette.darkBlueGrey} !important;
  border: none;
  font-weight: bold;
  line-height: 15px;
  text-align: center;
  background-color: #fff !important;
`;
export default ActionPlanCard;