import React from "react";
import { Steps as AntSteps } from "antd";
import { StepProps as AntStepProps, StepsProps } from "antd/lib/steps";
import styled from "styled-components";
import { List as ImmutableList } from "immutable";
import Palette from "@src/config/theme/palette";
import { InProgress, StepComplete } from "@components/core/svg";
import "antd/lib/steps/style/css";
import { getOr } from "hyphen-lib/dist/lang/Objects";
import { Trans } from "react-i18next";

export const { Step } = AntSteps;

export enum STEP_STATUS {
  incomplete,
  inError,
  finish,
}

export type StepProps = StepPropsNotInError | StepPropsInError;

export interface StepPropsNotInError {
  readonly key: string;
  readonly title: string;
  readonly status: STEP_STATUS.incomplete | STEP_STATUS.finish;
}

export interface StepPropsInError {
  readonly key: string;
  readonly title: string;
  readonly status: STEP_STATUS.inError;
  readonly fieldsInError: string[];
}

export interface Props {
  readonly steps: ImmutableList<StepProps>;
  readonly currentStep: string;
  readonly onStepClick: (stepKey: string) => void;
  // isParticipantError is an explicitly property
  readonly isParticipantError: boolean;
}

interface UnVisitedStepProps {
  step: number;
}

function IncompleteStep({ step }: UnVisitedStepProps) {
  return (
    <IncompleteContainer>
      {step}
    </IncompleteContainer>
  );
}

function InErrorStep({ step }: UnVisitedStepProps) {
  return (
    <InErrorContainer>
      {step}
    </InErrorContainer>
  );
}

function Stepper(props: Props & StepsProps) {
  const { steps, currentStep, onStepClick, isParticipantError, ...rest } = props;
  const current = getCurrentStepIndex(steps, currentStep);

  function getStatus(step: number, item: StepProps, currentStepKey: string): StepsProps["status"] {
    const showErrorForAudience = item.key === "audience" && isParticipantError;
    if (item.key === currentStepKey) {
      return "process";
    } else if (item.status === STEP_STATUS.incomplete) {
      return "wait";
    } else if (item.status === STEP_STATUS.inError || showErrorForAudience) {
      return "error";
    } else if (item.status === STEP_STATUS.finish) {
      return "finish";
    }
  }

  function getIcon(status: StepsProps["status"], step: number) {
    switch (status) {
      case "process":
        return <InProgress />;
      case "finish":
        return <StepComplete />;
      case "error":
        return <InErrorStep step={step} />;
      default:
        return <IncompleteStep step={step} />;
    }
  }

  return (
    <Steps labelPlacement="vertical" current={current} {...rest}>
      {
        steps.map((item: StepProps, index: number) => {
          const status = getStatus(index, item, currentStep);
          const Icon = getIcon(status, index + 1);
          const nextStepReachable =
            index + 1 < steps.size ?
              current === index + 1 || getOr(steps.get(index + 1), {} as any).status !== STEP_STATUS.incomplete :
              false;
          const clickable = current !== index && item.status !== STEP_STATUS.incomplete;

          return (
            <CustomizedStep
              key={item.title}
              icon={Icon}
              title={<Trans>{item.title}</Trans>}
              status={status}
              // tslint:disable-next-line:jsx-no-lambda
              onClick={() => {
                if (clickable) {
                  onStepClick(item.key);
                }
              }}
              nextstepreachable={toSerializedBoolean(nextStepReachable)}
              clickable={toSerializedBoolean(clickable)}
            />
          );
        }
        )}
    </Steps>
  );
}

function getCurrentStepIndex(steps: ImmutableList<StepProps>, currentStep: string): number {
  for (let idx = 0; idx < steps.size; idx++) {
    const step = steps.get(idx)!;
    if (step.key === currentStep) {
      return idx;
    }
  }

  return 0;
}

/* eslint-disable max-len */
/*
  We are doing this to fix react warnings:
  index.js:1437 Warning: Received `false` for a non-boolean attribute `nextstepreachable`.

  If you want to write it to the DOM, pass a string instead: nextstepreachable="false" or nextstepreachable={value.toString()}.

  If you used to conditionally omit it with nextstepreachable={condition && value}, pass nextstepreachable={condition ? value : undefined} instead.
 */
/* eslint-disable max-len */
type SerializedBoolean = "true" | "false";

function toSerializedBoolean(bool: boolean): SerializedBoolean {
  return bool ? "true" : "false";
}

// eslint-disable-next-line max-len
export const CustomizedStep = styled(Step) <AntStepProps & { nextstepreachable: SerializedBoolean; clickable: SerializedBoolean; onClick: () => any }>`
  .ant-steps-item-tail:after {
    background-color: ${props => props.nextstepreachable === "true" ? Palette.primary : "#e8e8e8"} !important;
  }

  .ant-steps-icon {
    cursor: ${props => props.clickable === "true" ? "pointer" : "default"};
  }
`;

const Steps = styled(AntSteps) <StepsProps>`
  .ant-steps-item-icon {
    height: 32px !important;
    .ant-steps-icon {
      // cursor: pointer;
    }
  }
  .ant-steps-item-process, .ant-steps-item-finish {
    .ant-steps-icon {
      top: -3px !important;
      left: -3.5px !important;
    }
  }

  .ant-steps-item-content {
    margin: unset !important;
  }

  .ant-steps-item-title {
    color: ${Palette.lightGreyBlue} !important;
    font-size: 14px !important;
  }
  .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title {
    color: ${Palette.darkBlueGrey} !important;
  }
  .ant-steps-item-finish > .ant-steps-item-tail:after {
    background-color: ${Palette.primary} !important;
  }
`;

const IncompleteContainer = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid ${Palette.lightPeriwinkle};
  font-size: 12px;
  color: ${Palette.lightPeriwinkle};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RED_COLOR = Palette.darkPink;
const InErrorContainer = styled(IncompleteContainer)`
  color: ${RED_COLOR};
  border-color: ${RED_COLOR};
`;

export default Stepper;
