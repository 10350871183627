import { Record, List as ImmutableList } from "immutable";
import { ActionTypes, ShowNotificationAction, RemoveNotificationAction } from "./actions";
import { State, StateProps, Notification } from "./types";

export const defaultNotificationProps: StateProps = {
  notifications: ImmutableList(),
};

export const stateFactory = Record<StateProps>(defaultNotificationProps);
const defaultState = stateFactory();

export const updateNotificationReducer =
  (state: State = defaultState, { payload }: ShowNotificationAction)
  : State => {
    return state.updateIn(["notifications"], list => {
      return list.push(payload);
    });
  };

export const removeNotificationReducer =
  (state: State = defaultState, { payload }: RemoveNotificationAction)
  : State => {
    return state
      .withMutations(map => {
        map
          .updateIn(["notifications"], list => {
            const itemIndex = list.findIndex((item: Notification) => {
              return item.uuid === payload;
            });
            return list.delete(itemIndex);
          });
      });
  };

export const reducers = (state: State = defaultState, action: any) => {
  switch (action.type) {
    case ActionTypes.DISPLAY_NOTIFICATION:
      return updateNotificationReducer(state, action);
    case ActionTypes.REMOVE_NOTIFICATION:
      return removeNotificationReducer(state, action);
    default:
      return state;
  }
};

export default reducers;