import React from "react";
import styled from "styled-components";
import { Action } from "hyphen-lib/dist/domain/Action";
import { Trans } from "react-i18next";
import { DashboardResource } from "@hyphen-lib/domain/resource/report/DashboardResource";
import Palette from "@src/config/theme/palette";
import { Heading } from "@components/core/Typography";
import { Statuses, ActionPlanTag } from "@components/core/ActionPlanTag";
import Button from "@components/core/Button";

import { getActionsLink } from "@src/screens/Insights/ActionPlans/utils/helpers";
import { Breadcrumb, goTo } from "@src/utils/locations";
import ActionPlan from "./components/ActionPlan";

interface Props {
  data: DashboardResource.ActionPlanning;
}

function ActionPlans({ data }: Props) {
  const maxActionPlansVisible = 3;

  const numberOfActions = data.numberOfTodoActions + data.numberOfInProgressActions;
  const numberOfExtraTodoActions = Math.max(0, data.numberOfTodoActions - maxActionPlansVisible);
  const numberOfExtraInProgressActions = Math.max(0, data.numberOfInProgressActions - maxActionPlansVisible);

  const goToLink = (link: string) => {
    return () => goTo(link, Breadcrumb.stack("Dashboard"));
  };

  const onLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    goTo("/actioncenter/actions", Breadcrumb.stack("Dashboard"));
  };

  return (
    <Container data-cy="dashboard_actionPlanSection">
      <Heading size="large" translate="yes">
        My Action Plans
      </Heading>
      {numberOfActions > 0 ? (
        <>
          <Summary>
            <Trans i18nKey="pendingActions"
                    values={{count:numberOfActions}}
                    defaults={`You have ${numberOfActions} active Action Plans`}/>
          </Summary>

          <SectionContainer>
            <SectionHeader>
              <ActionPlanTag status={Statuses.TODO}/>
              <Trans i18nKey="pendingPlans"
                    values={{count:data.numberOfTodoActions}}
                    defaults={`${data.numberOfTodoActions} plans`}/>
            </SectionHeader>
            <ActionPlanList>
              {data.todo.slice(0, maxActionPlansVisible).map((actionPlan: any) => (
                <ActionPlan key={actionPlan.id} data={actionPlan}/>
              ))}
            </ActionPlanList>
            {numberOfExtraTodoActions > 0 && (
              <SectionButtons>
                <Button
                  className="block--print-hide"
                  color="grey"
                  onClick={goToLink(getActionsLink(Action.Status.TODO))}
                >
                  <span>{`+ ${numberOfExtraTodoActions} `}</span>&nbsp;
                  <Trans>more</Trans>
                </Button>
              </SectionButtons>
            )}
          </SectionContainer>

          <SectionContainer>
            <SectionHeader>
              <ActionPlanTag status={Statuses.IN_PROGRESS}/>
              <Trans i18nKey="pendingPlans"
                    values={{count:data.numberOfInProgressActions}}
                    defaults={`${data.numberOfInProgressActions} plans`}/>
            </SectionHeader>
            <ActionPlanList>
              {data.inProgress.slice(0, maxActionPlansVisible).map((actionPlan: any) => (
                <ActionPlan key={actionPlan.id} data={actionPlan}/>
              ))}
            </ActionPlanList>
            {numberOfExtraInProgressActions > 0 && (
              <SectionButtons>
                <Button
                  color="grey"
                  onClick={goToLink(getActionsLink(Action.Status.IN_PROGRESS))}
                >
                  <span>{`+ ${numberOfExtraInProgressActions} `}</span>&nbsp;
                  <Trans>more</Trans>
                </Button>
              </SectionButtons>
            )}
          </SectionContainer>

          <ActionButtons>
            {/* eslint-disable max-len */}
            <Button
              className="block--print-hide"
              color="blue"
              onClick={
                goToLink(
                  "/actioncenter/actions?filter[statuses][]=todo&filter[statuses][]=in_progress&filter[assignedToMe]=true&page=1&force=true"
                  )
                }
            >
              <Trans>View Action Plans</Trans>
            </Button>
            {/* eslint-disable max-len */}
          </ActionButtons>
        </>
      ) : (
        <Summary data-cy="dashboard_noActionPlan">
          <p><Trans>You have no active action plans.</Trans></p>
          <p>
            <Trans>You can create Action Plans by clicking on the wrench icon found throughout reporting
            or by visiting the</Trans> <StyledLink onClick={onLinkClick} ><Trans>Action Plans</Trans></StyledLink>
            <Trans>page</Trans>.
          </p>
        </Summary>
      )}
    </Container>
  );
}

const Container = styled.div`
  padding: 32px;
  background: ${Palette.white};
  font-family: Lato, sans-serif;
  color: ${Palette.veryDarkBlueGrey};
`;

const Summary = styled.div`
  margin-top: 8px;
  color: ${Palette.veryDarkBlueGrey};
`;

const SectionContainer = styled.div`
  margin-top: 32px;
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${Palette.bluishGrey};
`;

const ActionPlanList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;

  > * {
    margin: 8px 0;
  }
`;

const SectionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 -8px;

  > * {
    margin: 0 8px;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 16px -8px;

  > * {
    margin: 0 8px;
  }
`;

const StyledLink = styled.span`
  color: ${Palette.hyperLinkBlue};
  cursor: pointer;
`;

export default ActionPlans;
