import React from "react";
import Table from "@src/components/core/Table";
import Button from "@src/components/core/Button";
import styled from "styled-components";
import { Paragraph } from "@src/components/core/Typography";
import { SearchBar } from "@src/components/core/SearchBar";
import { ExpandButton } from "@components/core/ExpandButton";
import { FiltersBackdrop } from "@screens/Insights/components/FiltersBackdrop";
import { CustomizationPopover } from "@src/screens/Insights/components/CustomizationPopover";
import { TableProps, PaginationConfig, SorterResult } from "antd/lib/table";
import { ActionPlanTemplateResource } from "hyphen-lib/dist/domain/resource/action/ActionPlanTemplateResource";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { PostCategoryResource } from "hyphen-lib/dist/domain/resource/post/PostCategoryResource";
import { mapOr } from "hyphen-lib/dist/lang/Objects";
import FilterLabels from "@src/components/core/FilterLabels";
import convertDictToFilters from "@src/components/core/FilterLabels/utils";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { categorySVGMapper } from "../utils/categorySVGMapper";
import { ActionTemplateFilterContainer } from "../containers/ActionTemplateFilterContainer";
import { Trans } from "react-i18next";

export interface ActionPlanTemplateProps extends TableProps<any>, RowActionProps, TemplateProps {}

interface RowActionProps {
  onCreateAction: (action: any) => void;
  onPreviewAction: (action: ActionPlanTemplateResource) => void;
  index?: number;
}

interface ActionNameProps {
  action: string;
  category: string;
  difficulty: string;
}

interface TemplateProps {
  areFiltersVisible: boolean;
  filter: Dictionary<any>;
  categories: PostCategoryResource[];
  total: number;
  page: PageFilter;
  handleFiltersClick: () => void;
  onClearFilter: (filterToRemove: string) => void;
  onClearFilterOption: (filterKey: string, subFilter: string) => void;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  actionPlanTemplates: ActionPlanTemplateResource[];
  onApplyFilter: (newFilter: any) => void;
  onTableChange: (pagination: PaginationConfig, filters: any, sorter: SorterResult<any>) => void;
}

export const ActionPlanTemplates = (props: ActionPlanTemplateProps) => {
  const {
    areFiltersVisible,
    filter,
    onApplyFilter,
    categories,
    onClearFilter,
    onClearFilterOption,
    total,
    page,
    onTableChange,
  } = props;

  function renderFilterLabels() {
    const filters = convertDictToFilters(
      filter,
      {} /* no dimension filters */
    );

    if (filters.length > 0) {
      return (
        <FilterLabels
          filters={filters}
          onClearFilter={onClearFilter}
          onClearSubfilter={onClearFilterOption}
        />
      );
    } else {
      return null;
    }
  }

  return (
    <div>
      <SearchAndFilterContainer>
        <SearchBar
          placeholder="Search action plans"
          onChange={props.onSearchChange}
          defaultValue={mapOr(filter, f => f.freeText, "")}
        />
        <StyledExpandButton icon="filter" onClick={props.handleFiltersClick} translate="yes">
          Filters
        </StyledExpandButton>
        {areFiltersVisible && (
          <FiltersBackdrop onClick={props.handleFiltersClick} />
        )}
        <CustomizationPopover open={areFiltersVisible}>
          <ActionTemplateFilterContainer
            values={filter}
            onApply={onApplyFilter}
            categories={categories}
          />
        </CustomizationPopover>
      </SearchAndFilterContainer>
      {renderFilterLabels()}

      <Table
        columns={[
          {
            title: "",
            dataIndex: "action",
            key: "category",
            render: (records, row) => categorySVGMapper.get(row.category),
          },
          {
            title: (<Trans>Name</Trans>),
            dataIndex: "action",
            key: "action",
            render: (record, row) => {
              return <ActionName {...row} />;
            },
          },
          {
            render: (__, row, index) => (
              <RowActions
                onCreateAction={props.onCreateAction.bind(null, row)}
                onPreviewAction={props.onPreviewAction.bind(null, row)}
                index={index}
              />
            ),
          },
        ]}
        dataSource={props.actionPlanTemplates}
        rowKey="_id"
        pagination={{
          total,
          pageSize: page.size,
          current: page.number,
          showTotal: (totalDocuments: number, range: number[]) =>
            <Trans i18nKey="surveyTableCount"
            values={{start: range[0], end: range[1], total: totalDocuments}} 
            defaults={`Showing ${range[0]} to ${range[1]} of ${totalDocuments} surveys`}/>,
        }}
        onChange={onTableChange}
      />
    </div>
  );
};

function ActionName({ action, category, difficulty }: ActionNameProps) {
  return (
    <div>
      <Paragraph>{action}</Paragraph>
      <Paragraph weight="light">
        {category} • <Trans>{difficulty}</Trans>
      </Paragraph>
    </div>
  );
}

function RowActions({ onCreateAction, onPreviewAction, index}: RowActionProps) {
  return (
    <ButtonContainer>
      {/*
      // @ts-ignore */}
      <StyledButton onClick={onPreviewAction} color="grey" data-cy={`actionPlanTemplate_previewBtn_${index}`}
      translate="yes">
        Preview
      </StyledButton>
      <StyledButton onClick={onCreateAction} color="blue" data-cy={`actionPlanTemplate_createBtn_${index}`}
      translate="yes">
        Create
      </StyledButton>
    </ButtonContainer>
  );
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const StyledButton = styled(Button)`
  height: 36px !important;
  // width: 85px;
`;

const SearchAndFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  position: relative;
`;

const StyledExpandButton = styled(ExpandButton)`
  width: 144px;
  margin-left: 16px;
`;
