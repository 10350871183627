import { Record, RecordOf } from "immutable";
import { FilterParameter } from "@src/utils/networks";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import {
  CleanFilterAction,
  ModifyFilterAction,
  SurveyListFiltersActionTypes
} from "./actions";

export interface StateProps {
  readonly filter: Optional<FilterParameter>;
}
export type State = RecordOf<StateProps>;

const defaultValues: StateProps = {
  filter: Optional.empty(),
};

export const stateFactory = Record<StateProps>(defaultValues);
const defaultState = stateFactory();

type Actions =
  | ModifyFilterAction
  | CleanFilterAction;

export function reducers(state: State = defaultState,
  action: Actions): State {
  switch (action.type) {
    case SurveyListFiltersActionTypes.MODIFY_FILTER:
      return state.set("filter", action.payload);
    case SurveyListFiltersActionTypes.CLEAN_FILTER:
      return state.set("filter", Optional.empty());
    default:
      return state;
  }
}
