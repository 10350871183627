import React from "react";
import styled from "styled-components";

import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { Mutable } from "hyphen-lib/dist/lang/Types";

import { SearchBar } from "@src/components/core/SearchBar";
import {
  ExpandButton,
  ExpandButtonProps
} from "@src/components/core/ExpandButton";

import {
  CustomizationPopover,
  CustomizationPopoverProps
} from "../CustomizationPopover";
import { FiltersBackdrop } from "../FiltersBackdrop";
import {
  SearchAndFilterProps,
  SearchAndFilterState,
  SearchAndFilterChildrenProps
} from "./types";

export class SearchAndFilter extends React.Component<
SearchAndFilterProps,
SearchAndFilterState
> {
  static SearchBar = SearchBar;
  static ExpandButton = ({ onClick, ...rest }: ExpandButtonProps) => (
    <StyledExpandButton onClick={onClick} {...rest}>
      {rest.children}
    </StyledExpandButton>
  );
  static PopOver = ({ open, children }: CustomizationPopoverProps) => (
    <CustomizationPopover open={open}>{children}</CustomizationPopover>
  );

  state = {
    isPopoverVisible: false,
  };

  onExpandButtonClick = () => {
    const { onExpandButtonClick } = this.props;
    const { isPopoverVisible } = this.getState();
    if (isNotNullNorUndefined(onExpandButtonClick)) {
      // If controlled change state from where ever this is called from.
      onExpandButtonClick(isPopoverVisible);
    } else {
      this.setState(state => ({
        isPopoverVisible: !state.isPopoverVisible,
      }));
    }
  };

  isControlled(prop: keyof SearchAndFilterProps): boolean {
    return isNotNullNorUndefined(this.props[prop]);
  }

  reduceToNewState = (
    newState: Mutable<SearchAndFilterState>,
    stateKey: keyof SearchAndFilterState
  ): SearchAndFilterState => {
    if (this.isControlled(stateKey)) {
      // ts-ignore here because the availability of prop is already checked in isControlled
      // @ts-ignore
      newState[stateKey] = this.props[stateKey];
    } else {
      newState[stateKey] = this.state[stateKey];
    }
    return newState;
  };

  getState(): SearchAndFilterState {
    return (Object.keys(this.state) as (keyof SearchAndFilterState)[])
      .reduce(this.reduceToNewState, {} as SearchAndFilterState);
  }

  callAll = (...fns: ((...args: any[]) => void)[]) => {
    return function callWithArgs(...args: any[]) {
      fns.forEach(function callFn(fn) {
        if (isNotNullNorUndefined(fn) && typeof fn === "function") {
          fn(...args);
        }
      });
    };
  };

  getExpandButtonProps = (
    { onClick, isExpandable, ...props }: Partial<ExpandButtonProps> = {}
  ): ExpandButtonProps => {
    return {
      onClick: () => {
        if (
          isNotNullNorUndefined(isExpandable) &&
          !isExpandable
        ) {
          if (isNotNullNorUndefined(onClick)) {
            return onClick();
          }
          return;
        }
        if (isNotNullNorUndefined(onClick)) {
          return this.callAll(onClick, this.onExpandButtonClick)();
        }
        this.onExpandButtonClick();
      },
      isExpandable,
      children: [],
      ...props,
    };
  };

  getPopOverProps = (
    props: Partial<CustomizationPopoverProps> = {}
  ): CustomizationPopoverProps => {
    return {
      open: this.getState().isPopoverVisible,
      ...props,
    };
  };

  getStateAndHelpers(): SearchAndFilterChildrenProps {
    return {
      isPopoverVisible: this.getState().isPopoverVisible,
      getButtonProps: this.getExpandButtonProps,
      getPopOverProps: this.getPopOverProps,
    };
  }

  onClosePopover = () => {
    const { onClosePopover = () => undefined } = this.props;
    this.callAll(onClosePopover, this.onExpandButtonClick)();
  };

  render() {
    const { children } = this.props;
    const { isPopoverVisible } = this.getState();
    return (
      <Container>
        {children(this.getStateAndHelpers())}
        {isPopoverVisible && (
          <FiltersBackdrop onClick={this.onClosePopover} />
        )}
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 32px;
  position: relative;
  background: #fff;
  > * {
    margin-left: 16px;
  }
`;

const StyledExpandButton = styled(ExpandButton)`
  width: 144px;
`;
