import { Checkbox as AntCheckbox, Icon } from "antd";
import styled from "styled-components";
import { Trans } from "react-i18next";
import { CheckboxProps } from "antd/lib/checkbox";
import Palette from "@src/config/theme/palette";
import Tooltip from "../Tooltip";

interface Props extends CheckboxProps {
  info?: string;
  className?: string;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  svg {
    height: 18px;
    width: 18px;
    color: #919EAB;
    cursor: pointer;
  }
`;

const CheckboxElement = styled(AntCheckbox)<CheckboxProps>`
  font-size: 15px !important;
  color: ${Palette.veryDarkBlueGrey} !important;
  .ant-checkbox-inner {
    border-color: ${Palette.bluePurple} !important;
    background-color: white !important;
  }
  .ant-checkbox-inner::after {
    border-color: ${Palette.bluePurple} !important;
  }
`;

export default function Checkbox(props: Props) {
  const { info, className, ...rest } = props;
  return (
    <Container className={className}>
      <CheckboxElement {...rest} />
      {(info && info.length > 0) && (
        <Tooltip title={<Trans>{info}</Trans>}>
          <Icon type="info-circle" />
        </Tooltip>
      )}
    </Container>
  );
}
