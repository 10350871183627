import React from "react";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { Icon } from "antd";
import { sanitizeSegmentLabel } from "hyphen-lib/dist/domain/common/Dimensions";
import { trans } from "@src/utils/i18next";


export const ChartBackground = ({ labelWidth = "160px" }: { labelWidth?: string }) => {
  return (
    <ChartBackgroundContainer labelWidth={labelWidth}>
      <BackgroundCol first/>
      <BackgroundCol />
      <BackgroundCol />
      <BackgroundCol />
      <BackgroundCol middle/>
      <BackgroundCol noBorder/>
      <BackgroundCol />
      <BackgroundCol />
      <BackgroundCol />
      <BackgroundCol last/>
      <ChartBackgroundLabelContainer>
        <ChartBackgroundLabel>0%</ChartBackgroundLabel>
        <ChartBackgroundLabel>50%</ChartBackgroundLabel>
        <ChartBackgroundLabel>100%</ChartBackgroundLabel>
      </ChartBackgroundLabelContainer>
    </ChartBackgroundContainer>
  );
};

const BackgroundCol = styled.div<{ first?: boolean; middle?: boolean; last?: boolean; noBorder?: boolean }>`
  display: block;
  float: left;
  width: 10%;
  height: 100%;
  border-left: 1px solid ${props => props.first ? Palette.lightPeriwinkle : Palette.paleGrey};
  border-right: ${props => props.last || props.middle ? `1px solid ${Palette.lightPeriwinkle}` : 0};
  ${props => props.noBorder ? "border: 0" : ""};
`;

const ChartBackgroundContainer = styled.div<{ labelWidth: string }>`
  position: absolute;
  margin-left: ${props => props.labelWidth};
  width: calc(100% - ${props => props.labelWidth});
  height: calc(100% + 32px);
  top: -16px;
`;

const ChartBackgroundLabelContainer = styled.div`
  width: 100%;
  height: 30px;
  color: ${Palette.bluishGrey};
  font-size: 12px;
  font-family: "Lato";

  div:first-child {
    text-align: left;
  }

  div:last-child {
    text-align: right;
  }
`;

const ChartBackgroundLabel = styled.div`
  text-align: center;
  display: block;
  float: left;
  width: 33.3%;
  height: 20px;
  padding-top: 10px;
`;

export interface GraphContainerData {
  labelWidth?: string;
}

interface StateProps {
  visible: boolean;
}

export class ChartContainer extends React.Component<GraphContainerData, StateProps> {

  constructor(props: GraphContainerData) {
    super(props);

    this.state = { visible: false };
  }

  onMouseEnter = () => this.setState({ visible: true });

  onMouseLeave = () => this.setState({ visible: false });

  render() {
    return (
      <ChartWrapper>
        <ChartBackground labelWidth={this.props.labelWidth} />
        {this.props.children}
      </ChartWrapper>
    );
  }
}

const ChartWrapper = styled.div`
  position: relative;
  margin-bottom: 45px;
  margin-top: 24px;
`;

export interface BarLabelData {
  value: number;
  participants: number;
  label: string;
  width: string;
  isTopicalSegment: boolean;

}

// tslint:disable-next-line:max-classes-per-file
export const BarLabel = ({ width, label, participants, value, isTopicalSegment }: BarLabelData) => {

  return (
    <LabelWrapper width={width}>
      <Label data-cy={`barLabel_label_${sanitizeSegmentLabel(label)}`}>
          {trans(sanitizeSegmentLabel(label))}
      </Label>
      {isTopicalSegment ? <SubLabel data-cy={`barLabel_subLabel_${sanitizeSegmentLabel(label)}`}>
        {`n= ${participants}`}
      </SubLabel> :
        <><StyledDiv>
          <StyledIcon type="user" />
        </StyledDiv>
          =
          <SubLabel data-cy={`barLabel_subLabel_${sanitizeSegmentLabel(label)}`}>
            {`${participants} / ${value}`}
          </SubLabel>
        </>
      }
    </LabelWrapper>
  );
};

const StyledIcon = styled(Icon)`
  color: ${Palette.darkDarkDarkBlueGrey};
  margin-top: 3px;
`;

const commonLabelStyle = `
  width: 130px;
  font-size: 14px;
  font-family: "Lato";
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: top;
`;

const StyledDiv = styled.div`
  display: inline-block;
  flex-direction: horizontal;
`;

const LabelWrapper = styled.div<{ width: string }>`
  float: left;
  width: ${props => props.width};
`;

export const Label = styled.div`
  ${commonLabelStyle};
  color: ${Palette.darkBlueGrey};
`;

export const SubLabel = styled.div`
  ${commonLabelStyle};
  color: ${Palette.bluishGrey};
  display: inline-block;
  padding-top: 1px;
`;

export const BarContainer = styled.div`
  margin-bottom: 34px;
`;
