import { FSA } from "flux-standard-action";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { UsedResources } from "@store/network/ResourceStoresDefinitions";
import { Mapper } from "hyphen-lib/dist/lang/Functions";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { ALL_PAGE_ID } from "@src/utils/networks";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";

export enum ActionTypes {
  NETWORK_REQUEST = "NETWORK_REQUEST",
  NETWORK_SUCCESS = "NETWORK_SUCCESS",
  NETWORK_ERROR = "NETWORK_ERROR",
  CLEAN_RESOURCE = "CLEAN_RESOURCE",

  FETCH_ELEMENT_IF_NEEDED = "network/FETCH_ELEMENT_IF_NEEDED",
  FETCH_PAGE_IF_NEEDED = "network/FETCH_PAGE_IF_NEEDED",
  FETCH_COUNT_IF_NEEDED = "network/FETCH_COUNT_IF_NEEDED",
  FETCH_UNTYPED_IF_NEEDED = "network/FETCH_UNTYPED_IF_NEEDED",
  FETCH_UNTYPED = "network/FETCH_UNTYPED",
  FETCH_PAGE = "network/FETCH_PAGE"
}

export interface Data {
  [key: string]: any;
}

export interface Payloads {
  [ActionTypes.NETWORK_REQUEST]: Request;
  [ActionTypes.NETWORK_SUCCESS]: Data | Data[];
  [ActionTypes.NETWORK_ERROR]: NetworkEventErrorPayload;
  [ActionTypes.CLEAN_RESOURCE]: keyof UsedResources;

  [ActionTypes.FETCH_ELEMENT_IF_NEEDED]: FetchElementIfNeededPayload;
  [ActionTypes.FETCH_PAGE_IF_NEEDED]: FetchPageIfNeededPayload;
  [ActionTypes.FETCH_COUNT_IF_NEEDED]: FetchCountIfNeededPayload;
  [ActionTypes.FETCH_UNTYPED_IF_NEEDED]: FetchUntypedIfNeededPayload;
  [ActionTypes.FETCH_UNTYPED]:FetchUntypedIfNeededPayload;
  [ActionTypes.FETCH_PAGE]:FetchPageIfNeededPayload;
}

// Action that can be dispatched after a network request has succeeded or failed
export type NetworkEventRequestPayload = Request;

export interface NetworkEventSuccessPayload<S = Data> {
  data: S;
  status: number;
}

export interface NetworkEventErrorPayload {
  status: number;
  error?: any;
}

interface FetchElementIfNeededPayload {
  readonly id: string;
  readonly type: keyof UsedResources;
  readonly request: Request;
  readonly callbackActions?: Optional<{
    onRequestActions?: NetworkEventRequestActionCreator[];
    onSuccessActions?: NetworkEventSuccessActionCreator[];
    onErrorActions?: NetworkEventErrorActionCreator[];
  }>;
}

interface FetchPageIfNeededPayload {
  readonly id: string;
  readonly type: keyof UsedResources;
  readonly page: PageFilter;
  readonly rawPageSize: number;
  readonly request: Mapper<Optional<PageFilter>, Request>;
}

interface FetchAllIfNeededPayload {
  readonly type: keyof UsedResources;
  readonly request: Request;
}

interface FetchCountIfNeededPayload {
  readonly id: string;
  readonly type: keyof UsedResources;
  readonly request: Request;
}

interface FetchUntypedIfNeededPayload {
  readonly id: string;
  readonly request: Request;
}

export interface NetworkEventRequestAction extends FSA<string, NetworkEventRequestPayload> {
  payload: NetworkEventRequestPayload;
  meta?: any;
}
export interface NetworkEventSuccessAction extends FSA<string, NetworkEventSuccessPayload> {
  payload: NetworkEventSuccessPayload;
  meta?: any;
}
export interface NetworkEventErrorAction extends FSA<string, NetworkEventErrorPayload> {
  payload: NetworkEventErrorPayload;
  meta?: any;
}

export type NetworkEventRequestActionCreator =
  (payload: NetworkEventRequestAction["payload"]) => NetworkEventRequestAction;
export type NetworkEventSuccessActionCreator =
  (payload: NetworkEventSuccessAction["payload"]) => NetworkEventSuccessAction;
export type NetworkEventErrorActionCreator =
  (payload: NetworkEventErrorAction["payload"]) => NetworkEventErrorAction;

export enum RequestInfoType {
  PAGE = "page",
  ELEMENT = "element",
  COUNT = "count",
  UNTYPED = "untyped",
}

export const UNTYPED_TYPE = "untyped";

export interface RequestInfoCommon {
  readonly infoType: RequestInfoType;
  readonly type: keyof UsedResources | typeof UNTYPED_TYPE;
  readonly key: string;
}

export interface RequestInfoPage extends RequestInfoCommon {
  readonly infoType: RequestInfoType.PAGE;
  readonly page: PageFilter;
}

export interface RequestInfoElement extends RequestInfoCommon {
  readonly infoType: RequestInfoType.ELEMENT;
}

export interface RequestInfoCount extends RequestInfoCommon {
  readonly infoType: RequestInfoType.COUNT;
}

export interface RequestInfoUntyped extends RequestInfoCommon {
  readonly infoType: RequestInfoType.UNTYPED;
  readonly type: typeof UNTYPED_TYPE;
}

export type RequestInfo =
  | RequestInfoPage
  | RequestInfoElement
  | RequestInfoCount
  | RequestInfoUntyped;

export interface RequestOptions {
  readonly noCache?: Optional<boolean>;
}

export interface Meta {
  [ActionTypes.NETWORK_REQUEST]: {
    info?: RequestInfo;
    surveyId?: string; // fixme we do want to remove this field. It has to be agnostic of the business
    onRequestActions?: NetworkEventRequestActionCreator[];
    onSuccessActions?: NetworkEventSuccessActionCreator[];
    onErrorActions?: NetworkEventErrorActionCreator[];
    onSuccessRedirect?: (payload: NetworkEventSuccessAction["payload"]) => void;
    onErrorRedirect?: (payload: NetworkEventErrorAction["payload"]) => void;
    options?: Optional<RequestOptions>;
  };
  [ActionTypes.NETWORK_SUCCESS]: {
    info?: RequestInfo;
    options?: Optional<RequestOptions>;
  };
  [ActionTypes.NETWORK_ERROR]: {
    info?: RequestInfo;
  };
}

export interface NetworkRequestAction
  extends FSA<ActionTypes.NETWORK_REQUEST, Payloads[ActionTypes.NETWORK_REQUEST], Meta[ActionTypes.NETWORK_REQUEST]> {
  type: ActionTypes.NETWORK_REQUEST;
  payload: Payloads[ActionTypes.NETWORK_REQUEST];
  meta?: Meta[ActionTypes.NETWORK_REQUEST];
}

export interface NetworkSuccessAction
  extends FSA<ActionTypes.NETWORK_SUCCESS, Payloads[ActionTypes.NETWORK_SUCCESS], Meta[ActionTypes.NETWORK_SUCCESS]> {
  type: ActionTypes.NETWORK_SUCCESS;
  meta?: Meta[ActionTypes.NETWORK_SUCCESS];
}

export interface NetworkErrorAction
  extends FSA<ActionTypes.NETWORK_ERROR, Payloads[ActionTypes.NETWORK_ERROR], Meta[ActionTypes.NETWORK_ERROR]> {
  type: ActionTypes.NETWORK_ERROR;
  meta?: Meta[ActionTypes.NETWORK_ERROR];
}

export interface CleanResourceAction extends FSA<ActionTypes.CLEAN_RESOURCE, Payloads[ActionTypes.CLEAN_RESOURCE]> {
  type: ActionTypes.CLEAN_RESOURCE;
}

export interface FetchElementIfNeededAction
  extends FSA<ActionTypes.FETCH_ELEMENT_IF_NEEDED, Payloads[ActionTypes.FETCH_ELEMENT_IF_NEEDED]> {
  readonly payload: Payloads[ActionTypes.FETCH_ELEMENT_IF_NEEDED];
}

export interface FetchPageIfNeededAction
  extends FSA<ActionTypes.FETCH_PAGE_IF_NEEDED, Payloads[ActionTypes.FETCH_PAGE_IF_NEEDED]> {
  readonly payload: Payloads[ActionTypes.FETCH_PAGE_IF_NEEDED];
}

export interface FetchPageAction
  extends FSA<ActionTypes.FETCH_PAGE, Payloads[ActionTypes.FETCH_PAGE]> {
  readonly payload: Payloads[ActionTypes.FETCH_PAGE];
}

export interface FetchCountIfNeededAction
  extends FSA<ActionTypes.FETCH_COUNT_IF_NEEDED, Payloads[ActionTypes.FETCH_COUNT_IF_NEEDED]> {
  readonly payload: Payloads[ActionTypes.FETCH_COUNT_IF_NEEDED];
}

export interface FetchUntypedIfNeededAction
  extends FSA<ActionTypes.FETCH_UNTYPED_IF_NEEDED, Payloads[ActionTypes.FETCH_UNTYPED_IF_NEEDED]> {
  readonly payload: Payloads[ActionTypes.FETCH_UNTYPED_IF_NEEDED];
}

export interface FetchUntypedAction
  extends FSA<ActionTypes.FETCH_UNTYPED, Payloads[ActionTypes.FETCH_UNTYPED]> {
  readonly payload: Payloads[ActionTypes.FETCH_UNTYPED];
}

export interface RequestPayload {
  readonly request: Request;
  readonly requestAction?: string;
  readonly successAction?: string;
  readonly errorAction?: string;
  readonly meta?: any;
  readonly options?: Optional<RequestOptions>;
}

export const actionCreators = {
  networkRequest:
  (payload: NetworkRequestAction["payload"], meta: NetworkRequestAction["meta"] = {}): NetworkRequestAction => ({
    type: ActionTypes.NETWORK_REQUEST,
    payload,
    meta,
  }),
  networkRequestWithActions:
    ({ request, requestAction, successAction, errorAction, meta, options }: RequestPayload): NetworkRequestAction => {
      const metaCallbacks: Meta[ActionTypes.NETWORK_REQUEST] = {
        onRequestActions: [],
        onSuccessActions: [],
        onErrorActions: [],
        options,
      };
      if (isNotNullNorUndefined(requestAction)) {
        metaCallbacks.onRequestActions!.push(payload => ({ type: requestAction, payload, meta }));
      }
      if (isNotNullNorUndefined(successAction)) {
        metaCallbacks.onSuccessActions!.push(payload => ({ type: successAction, payload, meta }));
      }
      if (isNotNullNorUndefined(errorAction)) {
        metaCallbacks.onErrorActions!.push(payload => ({ type: errorAction, payload, meta }));
      }
      return ({
        type: ActionTypes.NETWORK_REQUEST,
        payload: request,
        meta: metaCallbacks,
      });
    },
  networkSuccess:
  (payload: NetworkSuccessAction["payload"], meta: NetworkRequestAction["meta"] = {}): NetworkSuccessAction => ({
    type: ActionTypes.NETWORK_SUCCESS,
    payload,
    meta,
  }),
  networkError: (payload: NetworkErrorAction["payload"],
    meta: NetworkRequestAction["meta"] = {}): NetworkErrorAction => ({
    type: ActionTypes.NETWORK_ERROR,
    payload,
    meta,
  }),
  cleanResource: (payload: CleanResourceAction["payload"]): CleanResourceAction => ({
    type: ActionTypes.CLEAN_RESOURCE,
    payload,
  }),
  fetchElementIfNeeded: (payload: Payloads[ActionTypes.FETCH_ELEMENT_IF_NEEDED]): FetchElementIfNeededAction => ({
    type: ActionTypes.FETCH_ELEMENT_IF_NEEDED,
    payload,
  }),
  fetchPageIfNeeded: (payload: Payloads[ActionTypes.FETCH_PAGE_IF_NEEDED]): FetchPageIfNeededAction => ({
    type: ActionTypes.FETCH_PAGE_IF_NEEDED,
    payload,
  }),
  fetchPageWithPagination:
    (payload: Payloads[ActionTypes.FETCH_PAGE]): FetchPageAction => ({
      type: ActionTypes.FETCH_PAGE,
      payload,
    }),
  fetchParticipantPage:(payload: Omit<Payloads[ActionTypes.FETCH_PAGE], "page" | "rawPageSize">): FetchPageAction => ({
    type: ActionTypes.FETCH_PAGE,
    payload: {
      ...payload,
      page: PageFilter.noPagination(),
      rawPageSize: PageFilter.RAW_PAGE_SIZE_WITH_NO_PAGINATION,
    },
  }),
  fetchPageWithoutPaginationIfNeeded:
    (payload: Omit<Payloads[ActionTypes.FETCH_PAGE_IF_NEEDED], "page" | "rawPageSize">): FetchPageIfNeededAction => ({
      type: ActionTypes.FETCH_PAGE_IF_NEEDED,
      payload: {
        ...payload,
        page: PageFilter.noPagination(),
        rawPageSize: PageFilter.RAW_PAGE_SIZE_WITH_NO_PAGINATION,
      },
    }),
    fetchPageWithoutPagination:
    (payload: Omit<Payloads[ActionTypes.FETCH_PAGE], "page" | "rawPageSize">): FetchPageAction => ({
      type: ActionTypes.FETCH_PAGE,
      payload: {
        ...payload,
        page: PageFilter.noPagination(),
        rawPageSize: PageFilter.RAW_PAGE_SIZE_WITH_NO_PAGINATION,
      },
    }),
  fetchAllPageIfNeeded: ({ type, request }: FetchAllIfNeededPayload): FetchPageIfNeededAction => ({
    type: ActionTypes.FETCH_PAGE_IF_NEEDED,
    payload: {
      id: ALL_PAGE_ID,
      type,
      page: PageFilter.noPagination(),
      rawPageSize: PageFilter.RAW_PAGE_SIZE_WITH_NO_PAGINATION,
      request: () => request,
    },
  }),
  fetchCountIfNeeded: (payload: Payloads[ActionTypes.FETCH_COUNT_IF_NEEDED]): FetchCountIfNeededAction => ({
    type: ActionTypes.FETCH_COUNT_IF_NEEDED,
    payload,
  }),
  fetchUntypedIfNeeded: (payload: Payloads[ActionTypes.FETCH_UNTYPED_IF_NEEDED]): FetchUntypedIfNeededAction => ({
    type: ActionTypes.FETCH_UNTYPED_IF_NEEDED,
    payload,
  }),
  fetchUntyped: (payload: Payloads[ActionTypes.FETCH_UNTYPED]): FetchUntypedAction => ({
    type: ActionTypes.FETCH_UNTYPED,
    payload,
  }),
 
};

export default actionCreators;
