import React from "react";

import { Optional } from "@hyphen-lib/lang/Optionals";
import { isNullOrUndefined, isEmptyObject } from "@hyphen-lib/lang/Objects";
import { DistributionResult } from "@hyphen-lib/domain/resource/report/common/DistributionResult";
import BarChart, { BarChartDimension, BarChartSegment } from "@src/components/core/BarChart";
import { getSegmentLabel } from "hyphen-lib/dist/domain/common/Dimensions";
/* eslint-disable max-len */
import { MultipleChoiceQuestionReportResource } from "hyphen-lib/dist/domain/resource/report/MultipleChoiceQuestionReportResource";
import { getOr, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { adjustElementsAndPercentages } from "@src/utils/Graphs";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { ParticipationReportResource } from "hyphen-lib/dist/domain/resource/survey/report/ParticipationReportResource";
import { SelectValue } from "antd/lib/select";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { AnonymityFilterExplanation } from "hyphen-lib/dist/domain/common/AnonymityFilterExplanation";
import { compact } from "lodash";
import { getBarConfigForRendering } from "@src/screens/Insights/components/Reports/VotesSentiment";
import { sanitizeSegmentLabel } from "hyphen-lib/dist/domain/common/Dimensions";
import { sortStringArrayAsc } from "hyphen-lib/dist/lang/Arrays";
/* eslint-disable max-len */
interface Dimension {
  [segment: string]: MultipleChoiceQuestionReportResource.DistributionResultForSegment;
}

export interface GraphColor {
  backgroundColor: string;
  fontColor?: string;
}

interface Props {
  readonly question: MultipleChoiceQuestionReportResource.NonFiltered;
  dimension?: Dimension;
  choiceLabels: Optional<string>[];
  anonymityThreshold: number;
  isAnonymous: boolean;
  readonly participationBySegments?: Store.Element<ParticipationReportResource>;
  readonly selectedDimension?: SelectValue;
}

export default class AnswersChart extends React.Component<Props> {
  labelWidth = "170px";
  defaultColors: GraphColor[] = [
    { backgroundColor: "#3559ae"},
    { backgroundColor: "#5372ba"},
    { backgroundColor: "#718ac6"},
    { backgroundColor: "#90a4d3"},
    { backgroundColor: "#aebddf"},
  ];

  getDataForChart = (
    question: MultipleChoiceQuestionReportResource.NonFiltered,
    input: Dimension
  ): BarChartDimension | undefined => {
    if (!input) {
      return undefined;
    }
    const data: BarChartDimension = {};
    const { participationBySegments, selectedDimension } = this.props;

    let participationBySegment: Dictionary<any> = {};
    if (isNotNullNorUndefined(participationBySegments) && Store.Element.isLoaded(participationBySegments)) {
      participationBySegment = getOr(participationBySegments.value.dimensions[selectedDimension!.toString()], {});
    }
    if (isNullOrUndefined(participationBySegments)) {
      participationBySegment = getOr(input, {});
    }

    sortStringArrayAsc(compact(Object.keys(participationBySegment))).forEach((label) => {
      let segmentAudience = 0;
      if (isNotNullNorUndefined(participationBySegment[label])) {
        const { total } = participationBySegment[label];
        segmentAudience = total;
      }
      let distribution: MultipleChoiceQuestionReportResource.DistributionResultForSegment = input[label];

      let segment: BarChartSegment;
      if (isEmptyObject(distribution)) {
        if (this.props.isAnonymous) {
          distribution = {
            filteredForAnonymity: true,
            explanation: {
              reason: AnonymityFilterExplanation.Reason.NOT_ENOUGH_VOTERS,
              numberOfVoters: 0,
            },
          } as MultipleChoiceQuestionReportResource.DistributionResultForSegment.FilteredSegmentResults;
        } else {
          distribution = {
            filteredForAnonymity: false,
            total: 0,
            items: [],
          } as MultipleChoiceQuestionReportResource.DistributionResultForSegment.NonFilteredSegmentResults;
        }
      }
      if (distribution.filteredForAnonymity === false) {
        // eslint-disable max-len
        const { pieceToAdd } = adjustElementsAndPercentages(distribution.items);
        const {
          choiceLabels,
          distribution: distributionResult, barTheme,
        } = getBarConfigForRendering(question, distribution.items);
        const series =
          distributionResult
            .map((distributionItem: DistributionResult.Item, index: number) => {
              const { total, percentage } = distributionItem;
              return {
                ...barTheme[index],
                value: total,
                percentage,
                percentageWidth: isNotNullNorUndefined(percentage) && percentage > 0 ?
                  percentage + pieceToAdd :
                  0,
                label: getOr(choiceLabels[index], "unknown"),
              };
            });
        segment = { total: segmentAudience, series, filteredForAnonymity: false };
      } else {
        segment = {
          total: segmentAudience,
          series: [],
          filteredForAnonymity: true,
          explanation: distribution.explanation,
        };
      }

      data[sanitizeSegmentLabel(getSegmentLabel(label))] = segment;
    });

    return data;
  };

  render() {
    const {
      question,
      dimension,
      anonymityThreshold,
    } = this.props;

    if (isNullOrUndefined(dimension)) {
      return null;
    }

    return <BarChart
      data={this.getDataForChart(question, dimension)}
      labelWidth={this.labelWidth}
      anonymityThreshold={anonymityThreshold}
      numberOfVotes = {question.votesSentimentSummary.numberOfVotes}
    />;
  }
}
