import styled from "styled-components";

import Palette from "@src/config/theme/palette";
import { QuestionType } from "@hyphen-lib/domain/common/QuestionType";
import { isNotNullNorUndefined } from "@hyphen-lib/lang/Objects";
import Icon from "antd/lib/icon";
import { newLineRegex } from "@src/utils/constants/SurveyCreation";
export interface QuestionProps {
  category?: string;
  type: QuestionType;
  isMultiSelect?: boolean;
  question: string;
  numberOfVotes?: number;
  numberOfComments?: number;
  hover?: boolean;
}

export function Question({
  category,
  type,
  isMultiSelect,
  question,
  hover,
  numberOfComments,
  numberOfVotes,
}: QuestionProps) {
  const typeMap = {
    [QuestionType.LIKERT]: "Likert",
    [QuestionType.OPEN_TEXT]: "Open",
    [QuestionType.MULTIPLE_CHOICE]: "Multiple choice",
    [QuestionType.ENPS]: "eNPS",
    [QuestionType.YES_OR_NO]: "Yes or No",
    [QuestionType.MULTI_SELECT]: "Multi select",
  };
  const typeString = isNotNullNorUndefined(isMultiSelect) ? typeMap[QuestionType.MULTI_SELECT] : typeMap[type];

  let belowPart = null;
  if (hover) {
    let belowPartNumber = 0;
    if (type === "open-text" && isNotNullNorUndefined(numberOfComments)) {
      belowPartNumber = numberOfComments;
    } else if (isNotNullNorUndefined(numberOfVotes)) {
      belowPartNumber = numberOfVotes;
    }
    belowPart = <BelowPartLabel><StyledDiv>
      <StyledIcon type="user"/>
    </StyledDiv>  = {belowPartNumber}</BelowPartLabel>;
  } else {
    belowPart = <BelowPartLabel>{typeString}</BelowPartLabel>;
    if (isNotNullNorUndefined(category)) {
      belowPart = <><BelowPartLabel>{typeString}</BelowPartLabel> • <BelowPartLabel>{category}</BelowPartLabel></>;
    }
  }
  return (
    <div>
      {question.split(newLineRegex).map((para: string, i: number) => {
        return <QuestionLabel key={i + 1}>{para}</QuestionLabel>;
      })}
      {belowPart}
    </div>
  );
}

const StyledIcon = styled(Icon)`
  color: ${Palette.darkDarkDarkBlueGrey};
  margin-top: 3px;
`;

const StyledDiv = styled.div`
  display: inline-block;
  flex-direction: horizontal;
`;

export const QuestionLabel = styled.div`
  font-family: Lato;
  font-size: 14px;
  line-height: 1.43;
  margin-top: 4px;
  color: ${Palette.veryDarkBlueGrey};
`;

export const BelowPartLabel = styled.span`
  font-family: Lato;
  font-size: 14px;
  color: ${Palette.bluishGrey};
`;
