import React from "react";
import { ColumnProps, TableProps } from "antd/lib/table";
import Table from "@src/components/core/Table";
import { Heading } from "@components/core/Typography";
import Palette from "@src/config/theme/palette";
import styled from "styled-components";
import {
  DynamicRowProps,
  withDynamicRows
} from "@src/components/core/DynamicRowsTable/withDynamicRows";
import { SortParameter } from "@src/utils/networks";
import { getSortOrder } from "@src/utils/helper";
import { CommentsCounter } from "@src/components/core/CommentsCounter";
import { goTo, Breadcrumb } from "@src/utils/locations";
import { PulsePollListOverviewResource } from "hyphen-lib/dist/domain/resource/PulsePollListOverviewResource";
import { Post } from "hyphen-lib/dist/domain/Post";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { PulsePollActionKeyType } from "../../components/Actions";
import { NameCell } from "../../SurveyList/components/cells/NameCell";
import {PulsePollSentimentCell} from "./cells/PulsePollSentimentCell";
import { PulsePollFavorabilityCell } from "./cells/PulsePollFavorabilityCell";
import { PulsePollActionCell } from "./cells/ActionCell";
interface PulsePollOverviewTableProps extends TableProps<PulsePollListOverviewResource> {
  readonly sort?: SortParameter;
  readonly onActionClick: (row: PulsePollListOverviewResource, action: keyof PulsePollActionKeyType) => void;
  readonly hasCreationRight: boolean;
}

function extractLastInstanceId(instances: Post.PulsePoll.InstanceInfo[]) {
  if (isNotEmptyArray(instances)) {
    return instances[instances.length - 1].id;
  }
}

const goToComments = ( row: PulsePollListOverviewResource ) => () => {
  const instanceId = row.launchedInstanceId ? row.launchedInstanceId : extractLastInstanceId(row.instances);
  if (isNotNullNorUndefined(instanceId)) {
    goTo(`pulsePolls/${row._id}/instances/${instanceId}/reports/comments`, Breadcrumb.stack("Polls"));
  }
};

function PulsePollTableComponent({ ...rest }: PulsePollOverviewTableProps) {

  const COLUMNS: ColumnProps<any>[] = [
    {
      title: <ColumnStyledHeader size="small" translate="yes">Name</ColumnStyledHeader>,
      dataIndex: "question",
      key: "question",
      sorter: true,
      sortOrder: getSortOrder("question", rest.sort),
      sortDirections: ["ascend","descend"],
      width: 650,
      render: (__, record, index) => <NameCell row={record} index={index} />,
    },
    {
      title: <ColumnStyledHeader size="small" translate="yes">Favorability/eNPS</ColumnStyledHeader>,
      dataIndex: "favorability",
      key: "favorability",
      sorter: false,
      sortOrder: getSortOrder("favorability", rest.sort),
      sortDirections: ["ascend","descend"],
      width: 220,
      render: (__, record, index) => {
        return <PulsePollFavorabilityCell row={record} index={index} />;
      },
    },
    {
      title: <ColumnStyledHeader size="small" translate="yes">Sentiment</ColumnStyledHeader>,
      dataIndex: "sentiment",
      key: "sentiment",
      width: 150,
      sorter: false,
      sortOrder: getSortOrder("sentiment", rest.sort),
      sortDirections: ["ascend","descend"],
      render: (__, record, index) => {
        return <PulsePollSentimentCell row={record} index={index} />;
      },
    },
    {
      title: <ColumnStyledHeader size="small" translate="yes">Comments</ColumnStyledHeader>,
      dataIndex: "commentsCounter",
      key: "commentsCounter",
      width: 280,
      render: (__, record) => {
        if (record.sentimentSummary && record.sentimentSummary.numberOfComments) {
          return  (
            <CommentsCounterContainer>
              <CommentsCounter numberOfComments={record.sentimentSummary.numberOfComments}/>
            </CommentsCounterContainer>
          );
        }
        return "";
      },
    },
  ];

  return <Table columns={COLUMNS} {...rest} />;
}

export function PulsePollOverviewTable(props: PulsePollOverviewTableProps) {
  function HoverRow({ row, index }: DynamicRowProps<any>) {
    return (
      <>
        <StyledHoverCell>
          <NameCell row={row} index={index} />
        </StyledHoverCell>
        <StyledHoverCell>
          <PulsePollFavorabilityCell row={row} index={index} />
        </StyledHoverCell>
        <StyledHoverCell>
          <PulsePollSentimentCell row={row} index={index} />
        </StyledHoverCell>
        <StyledHoverCell>
          <ButtonCommentsLayout>
            {(row.sentimentSummary && row.sentimentSummary.numberOfComments) && (
              <CommentsCounterContainer  onClick={goToComments(row)}>
                <CommentsCounter numberOfComments={row.sentimentSummary.numberOfComments}/>
              </CommentsCounterContainer>
            )
            }
            <PulsePollActionCell
              onClick={props.onActionClick.bind(null, row)}
              row={row}
              hasCreationRight={props.hasCreationRight}
              index={index}
            />
          </ButtonCommentsLayout>
        </StyledHoverCell>
      </>
    );
  }

  const TailoredTable = withDynamicRows(PulsePollTableComponent, HoverRow);

  return <TailoredTable rowKey="_id" {...props} />;
}

const ColumnStyledHeader = styled(Heading)<{ minWidth?: number; maxWidth?: number }>`
  color: ${Palette.darkBlueGrey};
  min-width: ${props => `${props.minWidth}px`};
`;

const StyledHoverCell = styled.td`
  background-color: ${Palette.lightLightBlue};
`;

const CommentsCounterContainer = styled.span`
  margin-left: 8px !important;
  cursor: pointer;
  justify-content: flex-start;
  width: 40%
`;

const ButtonCommentsLayout = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
