import React from "react";
import styled from "styled-components";

import { Heading } from "@components/core/Typography";

import { CommentsOverviewResource } from "@hyphen-lib/domain/resource/report/CommentsOverviewResource";
import Palette from "@src/config/theme/palette";
import { AnonymityFiltered } from "@src/components/core/AnonymityFiltered";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Word } from "hyphen-lib/dist/business/calculation/sentiment/Words";
import { AnonymityFilterExplanation } from "hyphen-lib/dist/domain/common/AnonymityFilterExplanation";
import NoResult from "@src/components/core/NoResult";
import SentimentDistribution from "../SentimentDistribution";
import { Trans } from "react-i18next";
import { Summary } from "@src/screens/Insights/Survey/components/OverviewReport/components/Overview";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";

export interface SentimentOverviewProps {
  readonly anonymityThreshold: number;
  readonly numberOfComments: number;
  readonly netSentimentScore: Optional<number>;
  readonly sentiments: CommentsOverviewResource.Sentiments;
  readonly totalCategories?: number;
  readonly totalPosts?: number;
  readonly totalVotes?: number;
  readonly words: Word[];
  readonly filteredForAnonymity: boolean;
  readonly filteredForAnonymityReason?: AnonymityFilterExplanation;
  readonly onWordClick?: (word: string, sentiment: string) => void;
}

class SentimentOverview extends React.Component<SentimentOverviewProps> {
  renderSentimentReport = () => {
    const {
      sentiments,
      numberOfComments,
      netSentimentScore,
      words,
      onWordClick,
      totalCategories,
      totalPosts,
      totalVotes,
    } = this.props;

    if (numberOfComments <= 0) {
      return <NoResult data-cy="noCommentsLabel" type="minimal" description="No comments" />;
    }

    return (
      <>
        {isNotNullNorUndefined(totalCategories) ?
          <Summary>
            {totalPosts}&nbsp;
            <Trans>questions</Trans> • {totalCategories}&nbsp;
            <Trans>categories</Trans> •  {totalVotes}&nbsp;
            <Trans>answers</Trans>
          </Summary> :
          <CommentsSummary data-cy="overview_commentsCount">{numberOfComments} <Trans>comments</Trans></CommentsSummary>
        }
        <SentimentDistribution
          numberOfComments={numberOfComments}
          sentiments={sentiments}
          netSentimentScore={netSentimentScore}
          words={words}
          onWordClick={onWordClick}
        />
      </>
    );
  };

  render() {
    const {
      anonymityThreshold,
      filteredForAnonymity,
      filteredForAnonymityReason,
      totalCategories,
    } = this.props;

    return filteredForAnonymity ? (
      <OverviewContainer>
        <AnonymityContainer>
          <AnonymityFiltered
            explanation={filteredForAnonymityReason!}
            anonymityThreshold={anonymityThreshold}
            translate="yes"
          />
        </AnonymityContainer>
      </OverviewContainer>
    ) : (
      <OverviewContainer data-cy="polls_commentsOverivew">
        <Heading size="large" translate="yes">
          {isNotNullNorUndefined(totalCategories) ? "Overview" : "Comments overview"}
        </Heading>
        {this.renderSentimentReport()}
      </OverviewContainer>
    );
  }
}

const AnonymityContainer = styled.div`
  margin: 24px 0;
  padding: 24px 0;
  background: white;
`;

const CommentsSummary = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  color: ${Palette.bluishGrey};
`;

export const OverviewContainer = styled.div`
  background: white;
  margin-top: 24px;
  padding: 24px 32px;
  font-family: "Lato";
`;

export default SentimentOverview;
