import { VoiceFlagsActionKeyType } from "./store/types";

export function getTypeLabel(type: string): string {
  if (type === "private_group") {
    return "Private Group";
  } else if (type === "all") {
    return "General";
  }
  return "Public Channel";
}

export function getLabelForEmployeeVoiceAdminActions(type: VoiceFlagsActionKeyType): string {
  if (type === VoiceFlagsActionKeyType.UNFLAG) {
    return "Unflag";
  } else if (type === VoiceFlagsActionKeyType.ARCHIVE) {
    return "Archive";
  }

  return "Unflag";
}