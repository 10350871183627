import React, { ChangeEvent } from "react";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import Checkbox from "@components/core/Checkbox";
import styled from "styled-components";
import { entries } from "hyphen-lib/dist/lang/Objects";
import { NumberInput } from "@src/components/core/NumberInput";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { AllowedQuestionFlags } from "./AddOrEditQuestion";
import { Trans } from "react-i18next";

export interface SettingsFlag {
  checked: boolean;
  disabled?: boolean;
  label: string;
  info: string;
}

export type QuestionSettingProps = SingleSelectSettingsProps | MultiSelectSettingsProps;

interface CommonQuestionSettingProps {
  toggle: AllowedQuestionFlags;
  isMultiSelect: boolean;
  onSettingsChange: (setting: any, value: any) => void;
}

interface SingleSelectSettingsProps extends CommonQuestionSettingProps {
  isMultiSelect: false;
}

interface MultiSelectSettingsProps extends CommonQuestionSettingProps {
  isMultiSelect: true;
  maxNumberOfOptions?: number;
  error?: Optional<string>;
  maxNumberOfOptionsDisabled?: boolean;
}

export function QuestionSettings(props: QuestionSettingProps) {
  const { toggle, onSettingsChange } = props;

  function onChange(value: any) {
    return (e: CheckboxChangeEvent) => {
      const { checked } = e.target;
      onSettingsChange(value, checked);
    };
  }

  function onMaxOptionsChange(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    const maxOption = Number(value);
    if (not(isNaN(maxOption))) {
      onSettingsChange("maxNumberOfOptions", maxOption);
    }
  }

  const allAllowedFlags: [string, SettingsFlag][] = entries(toggle).toArray();
  return (
    <>
      {props.isMultiSelect && (
        <MinMaxChoiceContainer>
          <NumberInput
            onChange={onMaxOptionsChange}
            error={props.error}
            value={Number(props.maxNumberOfOptions)}
            disabled={props.maxNumberOfOptionsDisabled}
            label="Maximum choices"
            suffix={<Trans>choices</Trans>}
            data-jest="question-settings-max-choices"
          />
        </MinMaxChoiceContainer>
      )}
      <Checkboxes>
        {allAllowedFlags.length > 0 &&
          allAllowedFlags.map(([key, flag]) => (
            <Checkbox
              key={key}
              info={flag.info}
              checked={flag.checked}
              disabled={flag.disabled}
              onChange={onChange(key)}
              data-cy={`surveyCreation_questions_${key}`}
              data-jest={`question_settings_${key}`}
            >
              <Trans>{flag.label}</Trans>
            </Checkbox>
          ))}
      </Checkboxes>
    </>
  );
}

const Checkboxes = styled.div`
  margin-top: 16px;

  > div {
    margin-top: 16px;
  }
`;

const MinMaxChoiceContainer = styled.div`
  margin-top: 16px;
`;
