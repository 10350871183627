import { freeze } from "hyphen-lib/dist/lang/Objects";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { navigateBackTo, pushLocation, replaceLocation, replaceTo } from "@src/utils/locations";
import notificationActionCreators, { ShowNotificationAction } from "@store/notifications/actions";
import { getResourceById } from "@store/network/selectors";
import {
  actionCreators as networkActionCreators,
  CleanResourceAction,
  NetworkEventSuccessAction
} from "@store/network/actions";
import { SurveyInfoResource } from "hyphen-lib/dist/domain/resource/SurveyInfoResource";
import { PulsePollInfoResource } from "hyphen-lib/dist/domain/resource/PulsePollInfoResource";
import { ActionResource } from "hyphen-lib/dist/domain/resource/action/ActionResource";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { not } from "hyphen-lib/dist/lang/Booleans";
import {
  actionPlansActionCreators,
  ActionPlansActionTypes,
  AddActionPlanAction,
  ModifyListActionTemplates
} from "./actions";
import * as NotificationFactory from "@src/store/notifications/notification-factory";

function* addActionPlan({ payload, redirectPath }: AddActionPlanAction) {
  yield put(
    actionPlansActionCreators.addActionPlanRequest(payload, redirectPath)
  );
}

export function* updateLocationUrl(
  replace: boolean,
  { payload: { filter, sort, page, queryParams } }: ModifyListActionTemplates
) {
  const locationUpdater = replace ? replaceLocation : pushLocation;
  yield call(
    locationUpdater, {
      ...queryParams,
      filter,
      sort,
      page: page.number,
    }
  );
}

function* showOkrFeatureNotification() {
  yield put<ShowNotificationAction>(
    notificationActionCreators.displayNotification(
      NotificationFactory.success(
        "This feature is not active for your company. Contact your company admin to get it enabled.",
        "",
        4.5
      )
  ));
}


function* handleActionPlanSuccess({ meta: { redirectPath }, payload }: NetworkEventSuccessAction ) {
  if (redirectPath) {
    yield call(
      navigateBackTo,
      redirectPath
    );
  }
  yield put<ShowNotificationAction>(
    notificationActionCreators.displayNotification(
      NotificationFactory.success(
        "Action added successfully",
        "",
        4.5
      )
  ));
}

function* cleanActionPlan() {

  yield put<CleanResourceAction>
  (networkActionCreators.cleanResource(ActionResource.TYPE));
}

function* showError() {
  yield put<ShowNotificationAction>(
    notificationActionCreators.displayNotification(
      NotificationFactory.error(
        "Something went wrong, please try again",
        "",
        4.5
      )
  ));
}

function* handleActionFetchFailure() {
  yield call(
    replaceTo,
    "/actioncenter/actions"
  );
  yield put<ShowNotificationAction>(
    notificationActionCreators.displayNotification(
      NotificationFactory.error(
        "Action could not be fetched",
        "The action is either not valid or does not exist anymore.",
        4.5
      )
  ));
}

// fixme kill and use the generic fetch element if needed instead
function* getSurveyInfoIfNeeded({ surveyId }: any) {
  const surveyInfo = yield select(getResourceById, SurveyInfoResource.TYPE, surveyId);
  if (not(Store.Element.isLoaded(surveyInfo))) {
    yield put(
      actionPlansActionCreators.fetchSurveyInfo(surveyId)
    );
  } else {
    yield put(
      actionPlansActionCreators.selectSurveyInfo(
        surveyInfo.value
      )
    );
  }
}

function* getPulsePollInfoIfNeeded({ pulsePollId }: any) {
  const pulsePollInfo = yield select(getResourceById, PulsePollInfoResource.TYPE, pulsePollId);
  if (not(Store.Element.isLoaded(pulsePollInfo))) {
    yield put(
      actionPlansActionCreators.fetchPulsePollInfo(pulsePollId)
    );
  } else {
    yield put(
      actionPlansActionCreators.fetchPulsePollInfo(
        pulsePollInfo.value
      )
    );
  }
}

function* getActionPlanIfNeeded({ actionId }: any) {
  const actionPlan = yield select(getResourceById, ActionResource.TYPE, actionId);
  if (Store.Element.isNotFound(actionPlan)) {
    yield put(
      actionPlansActionCreators.fetchAction(actionId)
    );
  } else {
    yield put(
      actionPlansActionCreators.selectAction(
        actionPlan.value
      )
    );
  }
}

function* onSurveySelect({ payload }: NetworkEventSuccessAction) {
  const surveyInfo = payload.data as SurveyInfoResource;
  yield put(
    actionPlansActionCreators.selectSurveyInfo(
      surveyInfo
    )
  );
}

function* onPulsePollSelect({ payload }: NetworkEventSuccessAction) {
  const pulsePollInfo = payload.data as PulsePollInfoResource;
  yield put(
    actionPlansActionCreators.selectPulsePollInfo(
      pulsePollInfo
    )
  );
}

function* onActionSelect({ payload }: NetworkEventSuccessAction) {
  const actionPlan = payload.data as ActionResource;
  yield put(
    actionPlansActionCreators.selectAction(
      actionPlan
    )
  );
}

export const actionPlansSagas = freeze([
  /* All sagas go here */
  takeLatest(
    ActionPlansActionTypes.ADD_ACTION_PLAN,
    addActionPlan
  ),

  takeLatest(
    ActionPlansActionTypes.CLEAN_ACTION_PLAN_RESOURCE,
    cleanActionPlan
  ),

  // ~~~~~~~~~~~~~ URL MANAGEMENT ~~~~~~~~~~~~~~~~

  /*
    Listen for modification of list, in order to push the new state in the URL.
   */
  takeLatest(
    ActionPlansActionTypes.MODIFY_LIST,
    updateLocationUrl.bind(null, true)
  ),

  takeLatest(
    ActionPlansActionTypes.ADD_ACTION_PLAN_SUCCESS,
    handleActionPlanSuccess
  ),

  takeLatest(
    ActionPlansActionTypes.ADD_ACTION_PLAN_ERROR,
    showError
  ),

  takeLatest(
    ActionPlansActionTypes.FETCH_SURVEY_INFO,
    getSurveyInfoIfNeeded
  ),

  takeLatest(
    ActionPlansActionTypes.FETCH_PULSE_POLL_INFO,
    getPulsePollInfoIfNeeded
  ),

  takeLatest(
    ActionPlansActionTypes.FETCH_ACTION_PLAN,
    getActionPlanIfNeeded
  ),

  takeLatest(
    ActionPlansActionTypes.FETCH_SURVEY_INFO_SUCCESS,
    onSurveySelect
  ),

  takeLatest(
    ActionPlansActionTypes.FETCH_PULSE_POLL_INFO_SUCCESS,
    onPulsePollSelect
  ),

  takeLatest(
    ActionPlansActionTypes.FETCH_ACTION_PLAN_SUCCESS,
    onActionSelect
  ),

  takeLatest(
    ActionPlansActionTypes.FETCH_ACTION_PLAN_ERROR,
    handleActionFetchFailure
  ),

  takeLatest(
    ActionPlansActionTypes.SHOW_ACTION_PLAN_OKR_NOTIFICATION,
    showOkrFeatureNotification
  )
]);
