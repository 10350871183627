import React from "react";
import { isNotNullNorUndefined, mapOr, getOr, isNotEmptyObject } from "hyphen-lib/dist/lang/Objects";
import { Company } from "hyphen-lib/dist/domain/Company";
import { getResourceById } from "@src/store/network/selectors";
import { connect, MapStateToProps } from "react-redux";
import { LifeCycleDashboardResource } from "hyphen-lib/dist/domain/resource/report/LifeCycleResource";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
import { Map as ImmutableMap } from "immutable";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { RouteComponentProps, Redirect } from "react-router-dom";
import { State } from "@src/store/types";
import { fetchLCADashboardIfNeeded } from "@src/store/network/resource/LCADashboardResource";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { getAppliedFilters } from "@src/components/core/FilterLabels/utils";
import { replaceLocation } from "@src/utils/locations";
import { applyExistingParametersIfNeeded } from "@src/utils/parameters";
import LCADashboardView from "../components/LCADashboardView";
import { ScreenContainer } from "../../components/ScreenContainer";
import { getParameters } from "../../parameters/store/selectors";
import { getCurrentUser, getCompany, getDimensions } from "../../store/selectors";
import { FetchError } from "../../errors/FetchError";

interface LCADashboardReduxProps {
  readonly dimensions: Dimensions;
  readonly parameters: ImmutableMap<string, any>;
  readonly lcaDashboard: Store.Element<LifeCycleDashboardResource>;
  readonly employeeLifeCycle: Optional<boolean>;
  readonly company?: Optional<CompanyResource>;
}

interface LCADashboardActionProps {
  readonly onFetchLCADashboard: (queryString: Dictionary<any>) => void;
}

type LCADashboardProps = RouteComponentProps & LCADashboardReduxProps & LCADashboardActionProps;

class LCADashboard extends React.Component<LCADashboardProps> {
  componentDidMount() {
    const { location, parameters } = this.props;
    const existingQueryParams = parseQueryString(location.search);
    const mergedParameters =
      applyExistingParametersIfNeeded(
        parameters.toJS(),
        existingQueryParams,
        "filter.dimensions"
      );
    if (isNotNullNorUndefined(mergedParameters) && isNotEmptyObject(mergedParameters)) {
      replaceLocation(mergedParameters);
    } else {
      this.fetchLCADashboardData();
    }
  }

  componentDidUpdate(prevProps: LCADashboardProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.fetchLCADashboardData();
    }
  }

  fetchLCADashboardData = () => {
    const { location, onFetchLCADashboard } = this.props;
    const queryParams = parseQueryString(location.search);
    onFetchLCADashboard(queryParams);
  };

  handleDimensionSelect = (selectedDimension: string) => {
    const { location } = this.props;
    const parameters = parseQueryString(location.search);
    if (selectedDimension === "None") {
      delete parameters.segmentBy;
    } else {
      parameters.segmentBy = selectedDimension;
    }
    replaceLocation(parameters);
  };

  render() {

    const { lcaDashboard, dimensions, location, employeeLifeCycle } = this.props;
    const appliedFilters = getAppliedFilters(location.search, dimensions);
    const selectedDimension = getOr(parseQueryString(location.search).segmentBy, "");

    if (Store.Element.isInError(lcaDashboard)) {
      return <FetchError {...lcaDashboard} resourceType={LifeCycleDashboardResource.TYPE}/>;
    }
    return (
      <ScreenContainer
        title="Lifecycle Analysis"
        actions={[]}
      >
        {employeeLifeCycle && <LCADashboardView
          dashboard={Store.Element.toLoadable(lcaDashboard)}
          dimensions={dimensions}
          enabledCustomFilters={["addDimension"]}
          enabledFilters={["date", "dimension"]}
          appliedFilters={appliedFilters}
          onDimensionSelect={this.handleDimensionSelect}
          segmentedBy={selectedDimension}
        />}
        {!employeeLifeCycle && <Redirect to={"/errors/403"}/>}
      </ScreenContainer>
    );
  }
}

const mapStateToProps: MapStateToProps<LCADashboardReduxProps, RouteComponentProps, State> = (
  state: State,
  routeProps: RouteComponentProps
): LCADashboardReduxProps => {
  let company = null;
  const currentUser = getCurrentUser(state);
  if (
    isNotNullNorUndefined(currentUser) &&
    isNotNullNorUndefined(currentUser.company)
  ) {
    company = currentUser.company;
  }

  const companyModules: Company.Modules = mapOr(getCompany(state), map => map.modules, {
    surveys: false,
    pulsePolls: false,
    employeeVoice: false,
    employeeLifeCycle: false,
  });

  return {
    lcaDashboard: getResourceById(
      state,
      LifeCycleDashboardResource.TYPE,
      LifeCycleDashboardResource.generateId(
        parseQueryString(routeProps.location.search).filter
      )
    ),
    company,
    parameters: getParameters(state),
    dimensions: getOr(getDimensions(state), {}),
    employeeLifeCycle: companyModules.employeeLifeCycle,
  };
};

const mapDispatchToProps = {
  onFetchLCADashboard: fetchLCADashboardIfNeeded,
};

export default connect(mapStateToProps, mapDispatchToProps)(LCADashboard);
