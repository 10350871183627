import React from "react";

import { Col, Row } from "antd";
import styled from "styled-components";

import ContainerCard from "@components/core/ContainerCard";
import { SurveyResource } from "hyphen-lib/dist/domain/resource/SurveyResource";
import { SurveyQuestionResource } from "hyphen-lib/dist/domain/resource/SurveyQuestionResource";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { ParticipantCountResource } from "hyphen-lib/dist/domain/resource/participants/ParticipantCountResource";
import { AccessResource } from "hyphen-lib/dist/domain/access/AccessResource";
import { InfoCard } from "@components/core/InfoCard/InfoCard";
import ChannelSummarySection from "./ChannelSummarySection";
import SurveySummarySection from "./SurveySummarySection";
import { AccessSection } from "./AccessSection";
import { AudienceSection } from "./AudienceSection";
import { SurveyQuestionsSection } from "./SurveyQuestionsSection";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Note } from "@src/screens/Insights/Surveys/components/MagicSurveySection";
import { Trans } from "react-i18next";
import { QuestionConfig } from "hyphen-lib/dist/domain/common/QuestionType";

export interface SurveySummaryProps {
  readonly survey: SurveyResource;
  readonly questions: SurveyQuestionResource[];
  readonly participantsCount: Loadable<ParticipantCountResource>;
  readonly accesses: AccessResource[];
  readonly allowedChannels: CompanyResource["channels"];
  readonly isMagicSurveyEnabled: Optional<boolean>;
  readonly questionConfig: QuestionConfig;
}

export class SurveySummary extends React.Component<SurveySummaryProps> {
  render() {
    const {
      survey,
      questions,
      participantsCount,
      allowedChannels,
      accesses,
      isMagicSurveyEnabled,
      questionConfig
    } = this.props;
    const surveyId = survey._id;

    const isMagicSurvey = survey.isMagic;
    const showMagicSurveyNote = isMagicSurvey && isMagicSurveyEnabled;
    return (
      <>
        <SummaryCard
          title="Summary"
          description="Almost there! Please double check everything before launching."
        >
          <Row gutter={32} >
            <Col span={12}>
              <InfoCard
                title="Settings"
                link={`/surveys/edit/${surveyId}/settings`}
                translate="yes"
              >
                <SurveySummarySection
                  survey={survey}
                  data-cy="survey_summary_section"
                />
                <ChannelSummarySection
                  channels={survey.channels}
                  allowedChannels={allowedChannels}
                  data-cy="channel_summary_section"
                />
              </InfoCard>
            </Col>

            <Col span={12}>
              <InfoCard
                title="Questions"
                link={`/surveys/edit/${surveyId}/questions`}
                translate="yes"
              >
                <SurveyQuestionsSection
                  questions={questions}
                  isSummary={true}
                  data-cy="questions-section"
                  questionConfig={questionConfig}
                />
              </InfoCard>

              <InfoCard
                title="Audience"
                link={`/surveys/edit/${surveyId}/audience`}
                translate="yes"
              >
                <AudienceSection
                  participantsCount={participantsCount}
                  isMagicSurveyFeatureEnabled={isMagicSurveyEnabled}
                  isMagicSurvey={isMagicSurvey}
                  data-cy="audience-section"
                />
              </InfoCard>

              <InfoCard
                title="Users with access"
                link={`/surveys/edit/${surveyId}/access`}
                translate="yes"
              >
                <AccessSection
                  accesses={accesses}
                  data-cy="access-section"
                />
              </InfoCard>
            </Col>
          </Row>
          {
            showMagicSurveyNote && <Note><Trans>
              Distribution by web link is enabled for this survey.
              The link will be enabled only once the survey is launched.
              After the survey is closed, reach out to Betterworks support for
              getting dimension wise filters created out of select demographic
              questions from this survey.</Trans></Note>
          }
        </SummaryCard>
      </>
    );
  }
}

const SummaryCard = styled(ContainerCard)`
  margin-bottom: 16px;
`;
