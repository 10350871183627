import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import CardMenu from "@components/core/CardMenu";
import { goTo, replaceTo } from "@src/utils/locations";
import {
  BodySideNav,
  Body,
  BodyWrapper
} from "@components/layouts/ReportContainer";
import { State } from "@store/types";

import { connect } from "react-redux";
import qs from "qs";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import {
  appendQueryString,
  generateQueryString
} from "hyphen-lib/dist/util/net/HttpClient";
import { fetchPulsePollInfoIfNeeded } from "@src/store/network/resource/PulsePollInfoResources";
import { getResourceById } from "@src/store/network/selectors";
import { PulsePollInfoResource } from "hyphen-lib/dist/domain/resource/PulsePollInfoResource";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { QuestionType } from "hyphen-lib/dist/domain/common/QuestionType";
import Spin from "@src/components/core/Spin";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { FetchError } from "@src/screens/Insights/errors/FetchError";
import PulsePollsRouter from "./../";
import { Trans } from "react-i18next";

interface MatchParams {
  templateId: string;
}

interface PulsePollsReportsReduxActionProps {
  fetchPulsePollReportsInfo: (templateId: string) => void;
}

interface PulsePollsReportsReduxStateProps {
  readonly pulsePollInfoElement: Store.Element<PulsePollInfoResource>;
}

interface PulsePollsReportsInterface
  extends RouteComponentProps<MatchParams>,
  PulsePollsReportsReduxActionProps, PulsePollsReportsReduxStateProps {}

const cardMenuItems = [
  {
    label: "Overview",
    key: "overview",
    redirect: "overview",
  },
  {
    label: "Results",
    key: "results",
    redirect: "results",
  },
  {
    label: "Comments",
    key: "comments",
    redirect: "comments",
  },
];

class PulsePollsReports extends React.Component<PulsePollsReportsInterface> {
  componentDidMount() {
    const { match, fetchPulsePollReportsInfo, pulsePollInfoElement } = this.props;
    const pulsePollInfo = Store.Element.toLoadable(pulsePollInfoElement);
    const {
      params: { templateId },
    } = match;
    fetchPulsePollReportsInfo(templateId);
    if (Loadable.isLoaded(pulsePollInfo)) {
      this.redirectIfOpenPoll();
    }
  }

  componentDidUpdate() {
    const {
      pulsePollInfoElement,
    } = this.props;

    const pulsePollInfo = Store.Element.toLoadable(pulsePollInfoElement);
    if (Loadable.isLoaded(pulsePollInfo) && not(Store.Element.isInError(pulsePollInfoElement))) {
      this.redirectIfOpenPoll();
    }
  }

  redirectIfOpenPoll() {
    const {
      location: { pathname },
      pulsePollInfoElement,
    } = this.props;
    const pulsePollInfo = Store.Element.toLoadable(pulsePollInfoElement);
    const splitPathArr = pathname.split("/");
    const lastElementInPath = splitPathArr[splitPathArr.length - 1];
    if (
      lastElementInPath === "reports" &&
      Loadable.isLoaded(pulsePollInfo) &&
      pulsePollInfo.value.type === QuestionType.OPEN_TEXT
    ) {
      return replaceTo("reports/comments");
    }

    if (lastElementInPath === "reports") {
      return replaceTo("reports/overview");
    }
  }

  redirect = (path: string) => {
    const {
      location: { pathname, search },
    } = this.props;
    const splitPathArr = pathname.split("/");
    splitPathArr.splice(-1, 1, path);

    const queryParams = qs.parse(search, { ignoreQueryPrefix: true });
    const pollId = queryParams.pollId;
    const tempQueryParams = isNotNullNorUndefined(pollId)
      ? {
        pollId,
      }
      : {};
    const newPath = splitPathArr.join("/");
    goTo(appendQueryString(newPath, generateQueryString(tempQueryParams)));
  };

  renderSideMenu(questionType: QuestionType, item: any) {
    if (
      questionType === QuestionType.OPEN_TEXT &&
      (item.key === "results" || item.key === "overview")
    ) {
      return null;
    }

    return (
      <CardMenu.Item
        key={item.key}
        onClick={this.redirect.bind(this, item.redirect)}>
        <Trans>{item.label}</Trans>
      </CardMenu.Item>
    );
  }

  render() {
    const { location, pulsePollInfoElement } = this.props;
    const pulsePollInfo = Store.Element.toLoadable(pulsePollInfoElement);
    const pathname = location.pathname;
    const splitPath = pathname.split("/");
    const selectedKeys =
      splitPath.length > 0 ? [splitPath[splitPath.length - 1]] : [];

    if (Store.Element.isInError(pulsePollInfoElement)) {
      return <FetchError {...pulsePollInfoElement} resourceType={PulsePollInfoResource.TYPE}/>;
    }

    return (
      <Body>
        <BodySideNav>
          {Loadable.isLoaded(pulsePollInfo) && (
            <CardMenu selectedKeys={selectedKeys}>
              {cardMenuItems.map(this.renderSideMenu.bind(this, pulsePollInfo.value.type))}
            </CardMenu>
          )}
        </BodySideNav>
        <BodyWrapper>
          {
            Loadable.mapOr(
              pulsePollInfo,
              _ => <PulsePollsRouter />,
              <Spin size="large" />
            )
          }
        </BodyWrapper>
      </Body>
    );
  }
}

function mapStateToProps(
  state: State,
  ownProps: RouteComponentProps<MatchParams>
): PulsePollsReportsReduxStateProps {
  const { match: { params: { templateId } } } = ownProps;
  const pulsePollInfoElement = getResourceById(
    state,
    PulsePollInfoResource.TYPE,
    templateId
  );

  return {
    pulsePollInfoElement,
  };
}

const mapDispatchToProps = {
  fetchPulsePollReportsInfo: fetchPulsePollInfoIfNeeded,
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PulsePollsReports));
