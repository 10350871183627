import React, { FC } from "react";
import styled from "styled-components";
import { Row, Col, Button, Form } from "antd";
import { List } from "immutable";

import Input from "@components/core/Input";
import { Paragraph } from "@components/core/Typography";
import withDebounce from "@components/core/withDebounce";
import { isNullOrEmpty, isStringAndNotEmpty } from "@hyphen-lib/lang/Strings";
import {
  MutableSeparationWithErrorState,
  EMPTY_STRING_NOTATION,
  MutableSubPhaseWithErrorState,
  INFINITY_NOTATION,
  handleWheelScroll
} from "../../utils/LifeCycleSettings";
import PhaseContainer from "./PhaseContainer";
import LifeCycleSubPhases from "./LifeCycleSubPhases";
import { Trans } from "react-i18next";

const DebouncedInput = withDebounce(Input as any);

interface SeparationPhaseProps extends MutableSeparationWithErrorState {
  title: string;
  maxSubPhases: number;
  minSubPhases: number;
  handleSeparationChange: (separation: MutableSeparationWithErrorState) => void;
}

const SeparationPhase: FC<SeparationPhaseProps> = (props) => {
  const {
    name,
    nameError,
    tenureBeforeTermination,
    tenureBeforeTerminationError,
    title,
    subPhases,
    minSubPhases,
    maxSubPhases,
    config,
    handleSeparationChange,
  } = props;

  const adjustSubphasesStart = (inputName: string, inputValue: string) => {
    const subPhaseList = List(subPhases);
    const firstSubPhase: MutableSubPhaseWithErrorState = subPhaseList.first();
    return subPhaseList.set(0, {
      startTenure: isNullOrEmpty(inputValue) ? EMPTY_STRING_NOTATION : Number(inputValue),
      endTenure: firstSubPhase.endTenure,
      startTenureError: firstSubPhase.startTenureError,
      endTenureError: firstSubPhase.endTenureError,
    });
  };

  const handleInputChange = (inputName: string) => (inputValue: string) => {
    let updatedSubPhases = List(subPhases);
    let updatedValue: string | number = inputValue;

    if (inputName.startsWith("tenure") && updatedSubPhases.size > 0) {
      updatedSubPhases = adjustSubphasesStart(inputName, inputValue);
    }

    if (inputName.startsWith("tenure") && isNullOrEmpty(inputValue)) {
      updatedValue = EMPTY_STRING_NOTATION;
    }

    if (inputName.startsWith("tenure") && isStringAndNotEmpty(inputValue)) {
      updatedValue = Number(inputValue);
    }

    handleSeparationChange({
      name,
      nameError,
      tenureBeforeTermination,
      tenureBeforeTerminationError,
      subPhases: updatedSubPhases,
      [inputName]: updatedValue,
      config,
    });
  };

  const handleSubPhasesChange = (updatedSubPhases: List<MutableSubPhaseWithErrorState>) => {
    const updatedSeparation = {
      name,
      nameError,
      tenureBeforeTermination,
      tenureBeforeTerminationError,
      maxSubPhases,
      minSubPhases,
      subPhases: updatedSubPhases,
      title,
      config,
    };
    handleSeparationChange(updatedSeparation);
  };

  const handleSubPhaseChange = (idx: number, inputName: string) => (inputValue: string) => {
    const subPhasesList = List(subPhases);

    const currentSubPhase = subPhasesList.get(idx);
    const previousSubPhase = subPhasesList.get(idx - 1);
    const nextSubPhase = subPhasesList.get(idx + 1);

    const updatedSubPhases = subPhasesList
      .set(inputName === "startTenure" ? idx - 1 : idx, {
        startTenure: (inputName === "startTenure" ? previousSubPhase : currentSubPhase)!.startTenure,
        endTenure: inputValue === "" ? EMPTY_STRING_NOTATION : Number(inputValue),
        startTenureError:
          inputName === "startTenure" ? previousSubPhase!.startTenureError : currentSubPhase!.startTenureError,
        endTenureError:
          inputName === "startTenure" ? previousSubPhase!.endTenureError : currentSubPhase!.endTenureError,
      })
      .set(inputName === "startTenure" ? idx : idx + 1, {
        startTenure: inputValue === "" ? EMPTY_STRING_NOTATION : Number(inputValue),
        endTenure: (inputName === "startTenure" ? currentSubPhase : nextSubPhase)!.endTenure,
        startTenureError:
          inputName === "startTenure" ? currentSubPhase!.startTenureError : nextSubPhase!.startTenureError,
        endTenureError: inputName === "startTenure" ? currentSubPhase!.endTenureError : nextSubPhase!.endTenureError,
      });

    handleSubPhasesChange(updatedSubPhases);
  };

  const addSubPhase = () => {
    const subPhasesList = List(subPhases);
    const firstSubPhase: MutableSubPhaseWithErrorState = subPhasesList.first();
    const lastSubPhase: MutableSubPhaseWithErrorState = subPhasesList.last();
    const allButLastSubPhases = subPhasesList.butLast();
    let updatedSubPhases = allButLastSubPhases;

    if (subPhasesList.size > 0) {
      const secondLastSubPhase: MutableSubPhaseWithErrorState = allButLastSubPhases.last();
      updatedSubPhases = updatedSubPhases.push({
        startTenure: secondLastSubPhase ? secondLastSubPhase.endTenure : firstSubPhase.startTenure,
        endTenure: EMPTY_STRING_NOTATION,
        startTenureError: null,
        endTenureError: null,
      });
    }

    updatedSubPhases = updatedSubPhases.push({
      startTenure: EMPTY_STRING_NOTATION,
      endTenure: subPhasesList.size > 0 ? lastSubPhase.endTenure : INFINITY_NOTATION,
      startTenureError: null,
      endTenureError: null,
    });

    handleSubPhasesChange(updatedSubPhases);
  };

  const removeSubPhase = (idx: number) => () => {
    const subPhasesList = List(subPhases);

    const updatedSubPhases = subPhasesList.remove(idx);
    if (idx === 0) {
      return handleSubPhasesChange(
        updatedSubPhases.set(0, {
          ...updatedSubPhases.first(),
          startTenure: tenureBeforeTermination,
        })
      );
    }
    if (idx === updatedSubPhases.size) {
      return handleSubPhasesChange(
        updatedSubPhases.set(idx - 1, {
          ...updatedSubPhases.last(),
          endTenure: 0,
        })
      );
    }
    handleSubPhasesChange(
      updatedSubPhases.set(idx, {
        ...updatedSubPhases.get(idx),
        startTenure: updatedSubPhases.get(idx - 1)!.endTenure,
      } as MutableSubPhaseWithErrorState)
    );
  };

  const getTenureBeforeTerminationValue = (tenure: number): string | number => {
    if (tenure === EMPTY_STRING_NOTATION) {
      return "";
    }
    return tenure;
  };

  return (
    <>
      <PhaseContainer title={title} data-cy="separation_phase">
        <div className="extra">
          <Button icon="lock" type="link" data-cy="remove_phaseLock" disabled />
        </div>

        <Description weight="light">
          <Trans>The last Phase is unique - its range is calculated from the end 
            (e.g. last 12 months of the employees tenure at the company).</Trans>
        </Description>

        <FormRow gutter={32}>
          <Col md={10} lg={6}>
            <Form.Item help={nameError && <Trans>{nameError}</Trans>} validateStatus={nameError ? "error" : ""}>
              <DebouncedInput label="Name" name="name" value={name} onValueChange={handleInputChange("name")} />
            </Form.Item>
          </Col>

          <Col md={10} lg={6}>
            <Form.Item help={tenureBeforeTerminationError && <Trans>{tenureBeforeTerminationError}</Trans>} 
            validateStatus={tenureBeforeTerminationError ? "error" : ""}>
              <DebouncedInput
                type="number"
                label="Tenure range of the phase (months)"
                data-cy="tenure_beforeTermination"
                name="tenureBeforeTermination"
                value={getTenureBeforeTerminationValue(tenureBeforeTermination)}
                onValueChange={handleInputChange("tenureBeforeTermination")}
                onWheel={handleWheelScroll}
              />
            </Form.Item>
          </Col>
        </FormRow>
        <LifeCycleSubPhases
          subPhases={List(subPhases)}
          isLastPhase={false}
          minSubPhases={minSubPhases}
          maxSubPhases={maxSubPhases}
          handleSubPhaseChange={handleSubPhaseChange}
          addSubPhase={addSubPhase}
          removeSubPhase={removeSubPhase}
        />
      </PhaseContainer>
    </>
  );
};

const Description = styled(Paragraph)`
  margin-top: 20px;
`;

const FormRow = styled(Row)`
  padding-top: 32px;
`;

export default SeparationPhase;
