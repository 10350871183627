import React from "react";
import { Link } from "react-router-dom";
import Button from "@src/components/core/Button";
import { State } from "@store/types";
import { Rights } from "@hyphen-lib/business/auth/Rights";
import { connect } from "react-redux";
import { getRightsMatcher } from "../store/selectors";
import { ScreenContainer } from "../components/ScreenContainer/index";
import { PulsePollListContainer } from "./containers/PulsePollListContainer";

interface PulsePollListProps { hasCreationRight: boolean }

export class PulsePollList extends React.Component<PulsePollListProps> {

  render() {
    const { hasCreationRight } = this.props;
    return (
      <ScreenContainer
        title="Polls"
        actions={[
          hasCreationRight && <Link to="/pulsePolls/edit" key="create">
            <Button data-cy="pollList_createButton" color="blue" 
            translate="yes">Create New Poll</Button>
          </Link>,
        ]}>
        <PulsePollListContainer />
      </ScreenContainer>
    );
  }
}

function mapStateToProps(state: State): PulsePollListProps {
  return {
    hasCreationRight: getRightsMatcher(state).hasRight(Rights.PulsePoll.CREATE),
  };
}

export default connect(mapStateToProps)(PulsePollList);
