import { FSA } from "flux-standard-action";
import { createRequest } from "@src/utils/networks";
import {
  ActionTypes as NetworkActionTypes,
  NetworkRequestAction,
  NetworkEventRequestAction,
  NetworkEventSuccessAction,
  NetworkEventErrorAction
} from "@src/store/network/actions";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";

export enum UsersAndDimensionsActionTypes {
  /* All Action types got here */

  ADD_USERS_MANUALLY = "usersAndDimensions/ADD_USERS_MANUALLY",
  UPDATE_USER_ROLE = "usersAndDimensions/UPDATE_USER_ROLE",
  REMOVE_USER = "usersAndDimensions/REMOVE_USER",
  CLEAN_ADD_USERS_MANUAL_STORE = "usersAndDimensions/CLEAN_ADD_USERS_MANUAL_DATA",

  ADD_USERS_MANUALLY_REQUEST = "usersAndDimensions/ADD_USERS_MANUALLY_REQUEST",
  ADD_USERS_MANUALLY_SUCCESS = "usersAndDimensions/ADD_USERS_MANUALLY_SUCCESS",
  ADD_USERS_MANUALLY_ERROR = "usersAndDimensions/ADD_USERS_MANUALLY_ERROR",

  UPLOAD_USERS_CSV = "addUsers/UPLOAD_USERS_CSV",
  UPLOAD_USERS_CSV_REQUEST = "addUsers/UPLOAD_USERS_CSV_REQUEST",
  UPLOAD_USERS_CSV_PROGRESS = "addUsers/UPLOAD_USERS_CSV_PROGRESS",
  UPLOAD_USERS_CSV_SUCCESS = "addUsers/UPLOAD_USERS_CSV_SUCCESS",
  UPLOAD_USERS_CSV_ERROR = "addUsers/UPLOAD_USERS_CSV_ERROR",

  UPDATE_DIMENSIONS = "usersAndDimensions/UPDATE_DIMENSIONS",
  UPDATE_DIMENSIONS_REQUEST = "usersAndDimensions/UPDATE_DIMENSIONS_REQUEST",
  UPDATE_DIMENSIONS_SUCCESS = "usersAndDimensions/UPDATE_DIMENSIONS_SUCCESS",
  UPDATE_DIMENSIONS_ERROR = "usersAndDimensions/UPDATE_DIMENSIONS_ERROR",
  CHANGED_AFTER_LAST_UPDATE = "addDimensions/CHANGED_AFTER_LAST_UPDATE",

  SELECT_DESELECT_USERS = "usersAndDimensions/SELECT_DESELECT_USERS",
}

export interface UploadUsersCsvParameters {
  readonly file: File;
}

export interface UserAndRole {
  email: string;
  role: string;
}

export interface SelectDeselectUsers {
  select?: {
    email?: string;
    error?: string;
  };
  deselect?: {
    email: string;
  };
  deselectAll?: boolean;
}

interface Payloads {
  [UsersAndDimensionsActionTypes.ADD_USERS_MANUALLY]: { emails: UserAndRole[]; dryRun: boolean };
  [UsersAndDimensionsActionTypes.UPDATE_USER_ROLE]: { index: number; role: string };
  [UsersAndDimensionsActionTypes.REMOVE_USER]: { index: number };
  [UsersAndDimensionsActionTypes.UPLOAD_USERS_CSV]: UploadUsersCsvParameters;
  [UsersAndDimensionsActionTypes.UPDATE_DIMENSIONS]: Dictionary<any>;
  [UsersAndDimensionsActionTypes.CHANGED_AFTER_LAST_UPDATE]: boolean;
  [UsersAndDimensionsActionTypes.SELECT_DESELECT_USERS]: SelectDeselectUsers;
}

export interface AddUsersManuallyRequestAction 
  extends FSA<
  UsersAndDimensionsActionTypes.ADD_USERS_MANUALLY, 
  Payloads[UsersAndDimensionsActionTypes.ADD_USERS_MANUALLY]
  > {
  type: UsersAndDimensionsActionTypes.ADD_USERS_MANUALLY;
  payload: Payloads[UsersAndDimensionsActionTypes.ADD_USERS_MANUALLY];
}

export interface SelectDeselectUsersAction 
  extends FSA<
  UsersAndDimensionsActionTypes.SELECT_DESELECT_USERS,
  Payloads[UsersAndDimensionsActionTypes.SELECT_DESELECT_USERS]
  > {
  type: UsersAndDimensionsActionTypes.SELECT_DESELECT_USERS;
  payload: Payloads[UsersAndDimensionsActionTypes.SELECT_DESELECT_USERS];
}

export interface UpdateUserRoleAction 
  extends FSA<
  UsersAndDimensionsActionTypes.UPDATE_USER_ROLE, 
  Payloads[UsersAndDimensionsActionTypes.UPDATE_USER_ROLE]
  > {
  type: UsersAndDimensionsActionTypes.UPDATE_USER_ROLE;
  payload: Payloads[UsersAndDimensionsActionTypes.UPDATE_USER_ROLE];
}

export interface RemoveUserAction 
  extends FSA<UsersAndDimensionsActionTypes.REMOVE_USER, Payloads[UsersAndDimensionsActionTypes.REMOVE_USER]> {
  type: UsersAndDimensionsActionTypes.REMOVE_USER;
  payload: Payloads[UsersAndDimensionsActionTypes.REMOVE_USER];
}
export interface UploadUsersCsvAction 
  extends FSA<
  UsersAndDimensionsActionTypes.UPLOAD_USERS_CSV,
  Payloads[UsersAndDimensionsActionTypes.UPLOAD_USERS_CSV
  ]> {
  type: UsersAndDimensionsActionTypes.UPLOAD_USERS_CSV;
  payload: Payloads[UsersAndDimensionsActionTypes.UPLOAD_USERS_CSV];
}

function createAddUsersManuallyRequest(payload: AddUsersManuallyRequestAction["payload"]) {
  const { emails, dryRun } = payload;
  return createRequest(`/users/emails?dryRun=${dryRun}`, {
    method: "POST",
    body: JSON.stringify(emails),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export interface UpdateDimensionsRequestAction 
  extends FSA<
  UsersAndDimensionsActionTypes.UPDATE_DIMENSIONS,
  Payloads[UsersAndDimensionsActionTypes.UPDATE_DIMENSIONS]
  > {
  type: UsersAndDimensionsActionTypes.UPDATE_DIMENSIONS;
  payload: Payloads[UsersAndDimensionsActionTypes.UPDATE_DIMENSIONS];
}

function createUpdateDimensionsRequest(companyName: string, payload: UpdateDimensionsRequestAction["payload"]) {

  return createRequest(`/companies/${companyName}/dimensions`, {
    method: "PUT",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export interface ChangedAfterLastUpdateAction 
  extends FSA<
  UsersAndDimensionsActionTypes.CHANGED_AFTER_LAST_UPDATE, 
  Payloads[UsersAndDimensionsActionTypes.CHANGED_AFTER_LAST_UPDATE]
  > {
  type: UsersAndDimensionsActionTypes.CHANGED_AFTER_LAST_UPDATE;
  payload: Payloads[UsersAndDimensionsActionTypes.CHANGED_AFTER_LAST_UPDATE];
}

export const usersAndDimensionsActionCreators = {
  addUsersManuallyRequest: (
    manualImportPayload: AddUsersManuallyRequestAction["payload"]
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createAddUsersManuallyRequest(manualImportPayload),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: UsersAndDimensionsActionTypes.ADD_USERS_MANUALLY_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({
            type: UsersAndDimensionsActionTypes.ADD_USERS_MANUALLY_SUCCESS,
            payload,
            meta: { dryRun: manualImportPayload.dryRun },
          }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: UsersAndDimensionsActionTypes.ADD_USERS_MANUALLY_ERROR, payload }),
      ],
    },
  }),
  onAddUsersManually: (
    payload: AddUsersManuallyRequestAction["payload"]
  ): AddUsersManuallyRequestAction => ({
    type: UsersAndDimensionsActionTypes.ADD_USERS_MANUALLY,
    payload,
  }),
  onUpdateUserRole: (
    payload: UpdateUserRoleAction["payload"]
  ): UpdateUserRoleAction => ({
    type: UsersAndDimensionsActionTypes.UPDATE_USER_ROLE,
    payload,
  }),
  onRemoveUser: (
    payload: RemoveUserAction["payload"]
  ): RemoveUserAction => ({
    type: UsersAndDimensionsActionTypes.REMOVE_USER,
    payload,
  }),
  cleanAddUserManuallyStore: () => ({
    type: UsersAndDimensionsActionTypes.CLEAN_ADD_USERS_MANUAL_STORE,
  }),
  uploadUsersCsv: (file: File): UploadUsersCsvAction => ({
    type: UsersAndDimensionsActionTypes.UPLOAD_USERS_CSV,
    payload: {file},
  }),
  onSelectDeselectUsers: (payload: SelectDeselectUsersAction["payload"]) => ({
    type: UsersAndDimensionsActionTypes.SELECT_DESELECT_USERS,
    payload,
  }),
  updateCompanyDimensions: (
    companyName: string,
    dimensions: UpdateDimensionsRequestAction["payload"],
    onSuccessRedirect?: (payload: NetworkEventSuccessAction["payload"]) => void
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createUpdateDimensionsRequest(companyName, dimensions),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: UsersAndDimensionsActionTypes.UPDATE_DIMENSIONS_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: UsersAndDimensionsActionTypes.UPDATE_DIMENSIONS_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: UsersAndDimensionsActionTypes.UPDATE_DIMENSIONS_ERROR, payload }),
      ],
      onSuccessRedirect,
    },
  }),
  changedAfterLastUpdate: (
    payload: ChangedAfterLastUpdateAction["payload"]
  ): ChangedAfterLastUpdateAction => ({
    type: UsersAndDimensionsActionTypes.CHANGED_AFTER_LAST_UPDATE,
    payload,
  }),
};
