import { Record } from "immutable";

import { StateProps, State } from "./types";
import { ActionTypes, UpdateFiltersAction } from "./actions";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";

export const defaultValues: StateProps = {
  loadingState: { isLoading: true, hasError: false },
  filters: {
    data: {
      _type: "SurveyReportFilters",
      categories: {},
      topics: [],
      _id: "",
    },
  },
};

export const stateFactory = Record<StateProps>(defaultValues);
const defaultState = stateFactory();

export const updateFiltersReducer = (
  state: State = defaultState,
  { payload }: UpdateFiltersAction
): State => {
  return state
    .mergeIn(["filters"], payload)
    .setIn(["loadingState"], { isLoading: false, hasError: false });
};

export const reducers = (state: State = defaultState, action: any) => {
  switch (action.type) {
    case ActionTypes.FETCH_FILTERS_REQUEST:
      return state.setIn(["loadingState"], {
        isLoading: true,
        hasError: false,
      });
    case ActionTypes.FETCH_FILTERS_SUCCESS:
      return updateFiltersReducer(state, action);
    case ActionTypes.FETCH_DASHBOARD_CATEGORY_SUCCESS:
      const categories : Dictionary<any> = {};
      // fix me: api to be modified to get derived data if added more filters
      action.payload.data.forEach((val: string)=>{
        categories[val] = {
            name: val
          };
      });
      action.payload.data = { categories };
      return updateFiltersReducer(state, action);
    case ActionTypes.FETCH_FILTERS_ERROR:
      return state.setIn(["loadingState"], {
        isLoading: false,
        hasError: true,
      });
    default:
      return state;
  }
};

export default reducers;
