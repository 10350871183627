import { SelectionFilter } from "hyphen-lib/dist/domain/parameter/SelectionFilter";

export function updateTracker(
  tracker: SelectionFilter.Tracker,
  userId: string,
  selected: boolean
): SelectionFilter.Tracker {
  let updatedTracker = tracker;
  if (selected) {
    updatedTracker = tracker.select(userId);
  } else {
    updatedTracker = tracker.deselect(userId);
  }
  return updatedTracker;
}

export function getSelectRowKeys(
  selectedRowKeys: string[],
  selected: boolean,
  userId: string
): string[] {
  let updatedSelectedRowKeys = selectedRowKeys;
  if (selected) {
    updatedSelectedRowKeys = [ ...selectedRowKeys, userId ];
  } else {
    const index = selectedRowKeys.indexOf(userId);
    if (index > -1) {
      updatedSelectedRowKeys.splice(index, 1);
    }
  }
  return updatedSelectedRowKeys;
}

export function getSelectedRowCount(
  total: number,
  selectionFilter: SelectionFilter
): number {
  let totalRowCount = total;
  if (selectionFilter.type === "manual") {
    totalRowCount = selectionFilter.ids.length;
  } else if (selectionFilter.type === "dynamic") {
    totalRowCount = totalRowCount - selectionFilter.excludedIds.length;
  }
  return totalRowCount;
}

export function getDynamicSelectRowKeys(
  users: any[],
  selectionFilter: SelectionFilter,
  selectedRowKeys: string[]
): string[] {
  let updatedSelectedRowKeys = selectedRowKeys;

  if (selectionFilter.type === "dynamic") {
    const userKeys = users.map(user => user._id);
    updatedSelectedRowKeys = [
      ...selectedRowKeys,
      ...userKeys.filter(key => {
        if (selectionFilter.excludedIds.indexOf(key) > -1) {
          return false;
        }
        return true;
      }),
    ];
  }

  return updatedSelectedRowKeys;
}

export function clearExcludedIds(
  tracker: SelectionFilter.Tracker
){
  let updatedTracker = tracker;
  const selectionFilter: SelectionFilter = tracker.toSelectionFilter();
  // if filter type is dynamic, clear all exludedIds from Tracker. 
  // It helps in selecting all records present in tracker
  if(selectionFilter.type === "dynamic" && selectionFilter.excludedIds.length){
    for(const excludedId of selectionFilter.excludedIds){
        updatedTracker = updatedTracker.select(excludedId);
    } 
  }
  return updatedTracker;
}