import React from "react";
import Button from "@components/core/Button";

interface Props {
  nextStep: string;
  onClick?: () => void;
  disabled?: boolean;
}

export function NextButton(props: Props) {
  const { nextStep, onClick, disabled } = props;
  return (
    <Button
      data-cy="wizard_nextStep"
      color="blue"
      onClick={onClick}
      disabled={disabled}
      translate="yes"
    >
      {`Next step: ${nextStep}`}
    </Button>
  );
}
