import React from "react";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";

interface ReportContainerProps {
  children : React.ReactNode;
  style ?: React.CSSProperties;
}

// container
export default class ReportContainer extends React.Component<ReportContainerProps> {
  render() {
    return <ContainerDiv>{this.props.children}</ContainerDiv>;
  }
}

// title
export const Title = (props: ReportContainerProps) => <TitleDiv>{props.children}</TitleDiv>;

// header
export const Header = (props: ReportContainerProps) => <HeaderDiv>{props.children}</HeaderDiv>;

// body
export const Body = (props: ReportContainerProps) => {
  const children: any = {};

  React.Children.forEach(props.children, (child: any, k: any) => {
    if (child.type === BodyTopNav) {
      children.BodyTopNav = child;
    }
    if (child.type === BodySideNav) {
      children.BodySideNav = child;
    }
    if (child.type === BodyWrapper) {
      children.BodyWrapper = child;
    }
  });

  return (
    <BodyDiv>
      {/* top nav */}
      {isNotNullNorUndefined(children.BodyTopNav) && children.BodyTopNav}

      {/* body wrapper */}
      <BodyContainer>
        {children.BodySideNav}
        {children.BodyWrapper}
      </BodyContainer>
    </BodyDiv>
  );
};

// Body Top Nav
export const BodyTopNav = (props: ReportContainerProps) => (
  <BodyTopNavDiv>
    <BodyTopNavWrapper>{props.children}</BodyTopNavWrapper>
  </BodyTopNavDiv>
);

// Body Side Nav
export const BodySideNav = (props: ReportContainerProps) => (
  <BodySideNavDiv>
    <BodySideNavWrapper>{props.children}</BodySideNavWrapper>
  </BodySideNavDiv>
);

// Body Wrapper
export const BodyWrapper = (props: ReportContainerProps) => (
  <BodyWrapperDiv>{props.children}</BodyWrapperDiv>
);

// floating container
export const FloatingContainer = (props: ReportContainerProps) => {
  const style: React.CSSProperties = {
    display : "flex",
    flexDirection : "row",
    position : "relative",
    alignItems: "center",
    backgroundColor: "#ffffff",
    boxShadow: "0px 4px 8px rgba(198, 214, 223, 0.15)",
    borderRadius: 3,
    ...props.style,
  };
  return <div style={style}>{props.children}</div>;
};

// container
const ContainerDiv = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${Palette.paleGrey};
`;

// Title
const TitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
  width: 100%;
`;

// Header
const HeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  width: 100%;
`;

// Body
const BodyDiv = styled.div`
  width: 100%;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100%;
  width: 100%;
`;

// BodyTopNav
const BodyTopNavDiv = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
`;

const BodyTopNavWrapper = styled.div`
  flex: 1;
`;

// BodySideNav
const BodySideNavDiv = styled.div`
  display: flex;
  width: 200px;
  height: 100%;
  @media print {
    display: none;
  }
`;

const BodySideNavWrapper = styled.div`
  flex: 1;
`;

// BodyWrapper
const BodyWrapperDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`;
