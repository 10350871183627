import { fromJS } from "immutable";
import Palette from "@src/config/theme/palette";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import {
  PhaseSummariesForPlotBand,
  PhaseSummaryForPlotBand,
  PLOT_BAND_WIDTH,
  PhaseDetails,
  color,
  computeChartVitalStats,
  computeIfPlotLabelOpen
} from ".";

const defaultPlotBand = fromJS({
  label: {
    text: "",
    align: "center",
    useHTML: true,
    style: {
      fontSize: "15px",
    },
  },
  color: "#eee",
  zIndex: 1,
  events: {
    mouseover(this: any) {
      this.svgElem.attr("fill", Palette.lightLightBlue);
    },
    mouseout(this: any) {
      this.svgElem.attr("fill", this.options.color);
    },
  },
});

export function computePlotBands(
  phaseSummaries: PhaseSummariesForPlotBand,
  PLOT_BAND_WIDTH_PIXELS: number,
  labelStatuses: Dictionary<Optional<boolean>>) {
  const { maxSubphases } = computeChartVitalStats(phaseSummaries);

  return Object.entries(phaseSummaries).reduce(
    addPhaseSummaryToChartPlotBands(maxSubphases, PLOT_BAND_WIDTH_PIXELS, labelStatuses),
    []);
}

function addPhaseSummaryToChartPlotBands(
  maxSubphases: number,
  PLOT_BAND_WIDTH_PIXELS: number,
  labelStatuses: Dictionary<Optional<boolean>>) {
  let from = 0;
  let to = 0;
  return (
    bandAcc: any,
    [name, summary]: [string, PhaseSummaryForPlotBand],
    index: number
  ) => {
    const { startTenure, endTenure, numberOfSubphases, subPhaseTenures, isSeparation } = summary;
    to = from + PLOT_BAND_WIDTH; // + PLOT_BAND_WIDTH_EXTENSION;
    const sanitizedEndTenure = sanitizeEndTenure(endTenure, isSeparation);
    const isPlotBandLabelOpen = computeIfPlotLabelOpen(labelStatuses);
    const phaseDetails: PhaseDetails = {
      name,
      startTenure,
      endTenure: sanitizedEndTenure.endTenure,
      numberOfSubphases,
      subPhaseTenures,
      isSeparation,
    };
    const bgColor = color.toggleBandColor();
    const mergingBand = fromJS({
      label: {
        align: "left",
        y: isPlotBandLabelOpen ? -51 * maxSubphases : -103,
        useHTML: true,
        style: {
          width: `${isSeparation ? 500 : PLOT_BAND_WIDTH_PIXELS}px`,
          padding: "16px",
          height: !isPlotBandLabelOpen ? "auto" : `${60 * maxSubphases}px`,
          backgroundColor: bgColor,
        },
      },
      from,
      to: to + 1,
      color: bgColor,
      id: phaseDetails.name,
    });

    const updatedPlotBand = defaultPlotBand.mergeDeep(mergingBand);
    bandAcc.push(updatedPlotBand.toJS());
    from = to;
    return bandAcc;
  };
}

function sanitizeEndTenure(endTenure: number, isSeparation?: boolean) {
  if (endTenure === -1) {
    return {
      endTenure: "+",
    };
  }

  return {
    endTenure: `${isSeparation ? "" : "-"}${endTenure}`,
  };
}

export enum BANDCOLORS {
  BAND_1_COLOR = "#f3f6f9",
  BAND_2_COLOR = "#fff",
}

// tslint:disable-next-line: no-shadowed-variable
export class BandColors {
  private color: BANDCOLORS;

  // tslint:disable-next-line: no-shadowed-variable
  constructor(color: BANDCOLORS) {
    this.color = color;
  }

  public getBandColor() {
    return this.color;
  }

  public toggleBandColor() {
    if (this.color === BANDCOLORS.BAND_1_COLOR) {
      this.color = BANDCOLORS.BAND_2_COLOR;
    } else {
      this.color = BANDCOLORS.BAND_1_COLOR;
    }
    return this.color;
  }
}
