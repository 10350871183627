import React from "react";
import styled from "styled-components";
import { isEqual } from "lodash";
import Palette from "@src/config/theme/palette";
import Button from "@components/core/Button";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import {
  addPropertyInDepth,
  cleanObject,
  getOr,
  getProperty,
  isNotNullNorUndefined,
} from "hyphen-lib/dist/lang/Objects";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { Map as ImmutableMap, OrderedSet, Seq } from "immutable";
import { onlyField } from "hyphen-lib/dist/lang/Functions";
import { collectInMap } from "hyphen-lib/dist/lang/Maps";
import pluralize from "pluralize";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { Tooltip, Icon } from "antd";
import { Trans } from "react-i18next";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";

interface BaseFilterDefinition {
  readonly key: string;
  readonly label: string;
}

export interface FilterDefinition extends BaseFilterDefinition {
  readonly component: React.ComponentType<BaseFilterProps<any>>;
  readonly hidden?: boolean;
  readonly changeValueOnAdd?: {
    readonly defaultValue: any;
  };
}

export interface CustomFilterDefinition extends BaseFilterDefinition {
  readonly component: React.ComponentType<BaseCustomFilterProps<any>>;
}

export interface BaseFilterProps<V> {
  readonly id?: any;
  readonly label: any;
  readonly value?: V;
  readonly onChange: (value: V) => void;
  readonly onClear?: () => void;
  readonly midget?: boolean;
  readonly onConfirm?: () => void;
}

export interface BaseCustomFilterProps<V> {
  readonly label: string;
  readonly value?: V;
  readonly hiddenFilters: { key: string; label: string }[];
  readonly onClick: (optionKey: string) => void;
}

export type FiltersContentProps<F extends Dictionary<any>> =
  | FiltersContentWithCountProps<F>
  | FiltersContentWithoutCountProps<F>;

export interface FiltersContentCommonPropsProps<F extends Dictionary<any>> {
  readonly filters: FilterDefinition[];
  readonly customFilters?: CustomFilterDefinition[];
  readonly values?: Partial<F>;
  readonly singleFieldFilter?: string;
  readonly displayCount: boolean;
  readonly onChange?: (filters: Partial<F>, key?: any) => any;
  readonly onApply: (filters: Partial<F>) => any;
}

export interface FiltersContentWithCountProps<F extends Dictionary<any>>
  extends FiltersContentCommonPropsProps<F> {
  readonly displayCount: true;
  readonly count: number;
  readonly countLoading: boolean;
}

export interface FiltersContentWithoutCountProps<F extends Dictionary<any>>
  extends FiltersContentCommonPropsProps<F> {
  readonly displayCount: false; // required to use the discriminated union.
}

export interface FiltersContentState<F extends Dictionary<any>> {
  readonly values: Partial<F>;
  readonly filtersMap: ImmutableMap<string, FilterDefinition>;
  readonly keysToDisplay: OrderedSet<string>;
  readonly atLeastOneHiddenFilter: boolean;
}

export class FiltersContent<F extends Dictionary<any>> extends React.Component<
  FiltersContentProps<F>,
  FiltersContentState<F>
> {
  constructor(props: FiltersContentProps<F>) {
    super(props);

    const nonNullInitialValues = getOr(props.values, {});
    const keysToDisplay = Seq(props.filters)
      .filter(
        (filter) => (filter.hidden !== true ||
          isNotNullNorUndefined(getProperty(nonNullInitialValues, filter.key)))
      )
      .map(onlyField("key"))
      .toOrderedSet();

    this.state = {
      values: getOr(props.values, {}),
      filtersMap: collectInMap("key", props.filters),
      keysToDisplay,
      atLeastOneHiddenFilter: props.filters.length > keysToDisplay.size,
    };

    this.resetAllFilters = this.resetAllFilters.bind(this);
    this.handleApply = this.handleApply.bind(this);
    this.confirmSelection = this.confirmSelection.bind(this);
    this.displayFilter = this.displayFilter.bind(this);
  }

  componentDidUpdate(prevProps: FiltersContentProps<F>) {
    const { filters } = this.props;
    if (not(isEqual(prevProps.filters, filters))) {
      this.setState({ filtersMap: collectInMap("key", filters) });
    }
  }
  
  confirmSelection() {
    const { singleFieldFilter } = this.props;
    const midget = isStringAndNotEmpty(singleFieldFilter);
    if(midget) {
      this.handleApply();
    }
  }

  handleApply() {
    const { onApply } = this.props;
    const { values } = this.state;

    onApply(values);
  }

  computeApplyFiltersLabel() {
    if (this.props.displayCount === true) {
      const { count, countLoading } = this.props;
      return countLoading ? (<Trans>Show ... result</Trans>) :
      (<Trans i18nKey="showCountResult" values={{count}} defaults={
        `Show ${count} ${pluralize(
          "result",
          count
        )}`
      }/>);
    }
    return (<Trans>Show results</Trans>);
  }

  resetAllFilters() {
    const { filters } = this.props;

    this.setState((state) => {
      const newValues: Partial<F> = filters.reduce(
        (acc, cur) => addPropertyInDepth(acc, null, cur.key) as Partial<F>,
        { ...state.values }
      );

      return {
        values: cleanObject(newValues) as Partial<F>,
      };
    }, this.notifyOnChange);
  }

  notifyOnChange(key?: any) {
    const { onChange } = this.props;
    const { values } = this.state;

    if (isNotNullNorUndefined(onChange)) {
      onChange(values, key);
    }
  }

  handleChangeFilterValue(key: keyof F, value: any) {
    this.setState(
      (oldState) => ({
        values: addPropertyInDepth(
          {
            ...oldState.values,
          },
          value,
          key
        ) as Partial<F>,
      }),
      () => this.notifyOnChange(key)
    );
  }

  handleClearFilterValue(key: keyof F) {
    this.setState(
      (oldState) => ({
        values: addPropertyInDepth(
          {
            ...oldState.values,
          },
          null,
          key
        ) as Partial<F>,
      }),
      this.notifyOnChange
    );
  }

  clearFilterState(key: keyof F, callback: (...args: any[]) => void) {
    this.setState(
      (oldState) => ({
        values: addPropertyInDepth(
          {
            ...oldState.values,
          },
          null,
          key
        ) as Partial<F>,
      }),
      () => callback()
    );
  }

  handleModuleFilterChange(key: keyof F, value: any) {
    if (not(value.length === 1 && value[0] === "surveys")) {
      this.clearFilterState(
        "surveyTypes",
        this.handleChangeFilterValue.bind(this, key, value)
      );
    } else {
      this.handleChangeFilterValue(key, value);
    }
  }

  handleClearModuleFilter(key: keyof F) {
    this.clearFilterState(
      "surveyTypes",
      this.handleClearFilterValue.bind(this, key)
    );
  }

  displayFilter(key: string) {
    const filterToDisplay = this.state.filtersMap.get(key);
    if (
      isNotNullNorUndefined(filterToDisplay) &&
      isNotNullNorUndefined(filterToDisplay.changeValueOnAdd)
    ) {
      this.setState(
        (oldState) => ({
          values: addPropertyInDepth(
            {
              ...oldState.values,
            },
            filterToDisplay.changeValueOnAdd!.defaultValue,
            key
          ) as Partial<F>,
        }),
        this.notifyOnChange
      );
    }
    this.setState((state) => ({
      keysToDisplay: state.keysToDisplay.add(key),
    }));
  }

  render() {
    const { filters, customFilters = [], singleFieldFilter } = this.props;

    const midget = isStringAndNotEmpty(singleFieldFilter);

    const { values, filtersMap, keysToDisplay } = this.state;

    const hiddenFilters = Seq(filters)
      .filter((filter) => not(keysToDisplay.has(filter.key)))
      .map(({ key, label }) => ({ key, label }))
      .toArray();

    return (
      <FiltersContentContainer>
        {keysToDisplay.size > 0 && (
          <FilterComponentsContainer midget={midget}>
            {keysToDisplay
              .map((key) => filtersMap.get(key))
              .filter(isNotNullNorUndefined)
              .map((filter: FilterDefinition) => {
                if (filter.key === "modules") {
                  return (
                    <filter.component
                      id={filter.key}
                      key={filter.key}
                      label={<Trans>{filter.label}</Trans>}
                      onClear={this.handleClearModuleFilter.bind(
                        this,
                        filter.key
                      )}
                      onChange={this.handleModuleFilterChange.bind(
                        this,
                        filter.key
                      )}
                      value={getProperty(values, filter.key)}
                      midget={midget}
                      onConfirm={this.confirmSelection}
                    />
                  );
                } else {
                  if (filter.label.toLowerCase() === "manager") {
                    /**
                     * Specifically add a tooltip when the manager
                     * dimension is being applied
                     */
                    return (
                      <filter.component
                        id={filter.key}
                        key={filter.key}
                        label={ManagerDimensionTooltip(filter.label)}
                        onClear={this.handleClearFilterValue.bind(
                          this,
                          filter.key
                        )}
                        onChange={this.handleChangeFilterValue.bind(
                          this,
                          filter.key
                        )}
                        value={getProperty(values, filter.key)}
                        midget={midget}
                        onConfirm={this.confirmSelection}
                      />
                    );
                  } else {
                    return (
                      <filter.component
                        id={filter.key}
                        key={filter.key}
                        label={<Trans>{filter.label}</Trans>}
                        onClear={this.handleClearFilterValue.bind(
                          this,
                          filter.key
                        )}
                        onChange={this.handleChangeFilterValue.bind(
                          this,
                          filter.key
                        )}
                        value={getProperty(values, filter.key)}
                        midget={midget}
                        onConfirm={this.confirmSelection}
                      />
                    );
                  }
                }
              })
              .toArray()}
          </FilterComponentsContainer>
        )}
        {!midget && isNotEmptyArray(customFilters) && (
          <FilterComponentsContainer midget={midget}>
            {customFilters.map((customFilter) => (
              <customFilter.component
                key={customFilter.key}
                label={customFilter.label}
                onClick={this.displayFilter}
                hiddenFilters={hiddenFilters}
              />
            ))}
          </FilterComponentsContainer>
        )}
        {!midget && (
          <ActionsContainer>
            <AddAndResetContainer>
              <StyledResetAll data-cy="core_reset" onClick={this.resetAllFilters}>
                <Trans>Reset all</Trans>
              </StyledResetAll>
            </AddAndResetContainer>
            <Button
              color="blue"
              onClick={this.handleApply}
              data-cy="show_results"
            >{this.computeApplyFiltersLabel()}
            </Button>
          </ActionsContainer>
        )}
      </FiltersContentContainer>
    );
  }
}

export function ManagerDimensionTooltip(label: string) {
  return (
    <>
      <div className="d-flex flex-row">
        <div>{label}</div>
        <div>
          <Tooltip
            placement="right"
            title={<Trans>The ‘Manager’ field represents the full hierarchy 
              associated with the selected manager.</Trans>}
          >
            <StyledInfoIcon type="info-circle" className="pointer ml-1" />
          </Tooltip>
        </div>
      </div>
    </>
  );
}

const FiltersContentContainer = styled.div``;

const AddAndResetContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > button {
    margin-right: 23px;
  }
`;

const FilterComponentsContainer = styled.div<{ midget: boolean}>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(266px, 1fr));
  grid-gap: 24px;
  margin-bottom: ${props => props.midget ? 0 : 22}px;
`;

const StyledResetAll = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: ${Palette.bluePurple};
  cursor: pointer;
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > p {
    color: ${Palette.darkModerateBlue} !important;
    font-size: 15px !important;
    cursor: pointer;
  }

  button {
    height: 32px !important;
  }
`;

const iconStyle = `
  position: relative;
  font-size: 14px;
`;

const StyledInfoIcon = styled(Icon)`
  ${iconStyle};
`;
