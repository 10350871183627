import React from "react";
import { Progress as AntProgress } from "antd";
import Palette from "@src/config/theme/palette";
import styled from "styled-components";
import { ProgressProps } from "antd/lib/progress";

interface Props {
  percent: number;
  strokeColor?: string;
}

export function Progress({ percent, strokeColor }: Props) {
  const progressBarColor = (strokeColor) ? strokeColor : Palette.bluePurple;
  return (
    <StyledAntProgress
      strokeLinecap="square"
      percent={percent}
      status="normal"
      strokeColor={progressBarColor}
      showInfo={false}
    />
  );
}

const StyledAntProgress = styled(AntProgress)<ProgressProps>`
  height: 8px;
  line-height: 8px !important;

  .ant-progress-inner {;
    background-color: #ebeef3;
    border-radius: 3px !important;
  }

  .ant-progress-bg {
    border-radius: 3px !important;
    border-top-right-radius: ${props => props.percent === 100 ? 3 : 0}px !important;
    border-bottom-right-radius: ${props => props.percent === 100 ? 3 : 0}px !important;
  }
`;
