import React from "react";
import styled from "styled-components";

import { CellContainer, CellInnerContainer } from "@screens/Insights/SurveyList/components/cells/Cells";
import { DynamicRowProps } from "@components/core/DynamicRowsTable/withDynamicRows";
import Palette from "@src/config/theme/palette";

interface EmailCellProps {
  email: string;
}

export function EmailCell ({ row }: DynamicRowProps<EmailCellProps>) {
  return (
    <CellContainer align="flex-start" justifyContent="flex-start">
      <CellInnerContainer padding={[24, 0]} width="100%">
        <EmailLabel>{row.email}</EmailLabel>
      </CellInnerContainer>
    </CellContainer>
  );
}

const EmailLabel = styled.span`
  font-family: Lato;
  font-size: 14px;
  color: ${Palette.darkBlueGrey};
`;
