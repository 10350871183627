import { Record, RecordOf } from "immutable";
import { FilterParameter } from "@src/utils/networks";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { CleanFilterAction, ModifyFilterAction, UserPermissionsFiltersActionTypes } from "./actions";

export interface UserPermissionsFilterContainerStateProps {
  readonly filter: Optional<FilterParameter>;
}

export type UserPermissionsFilterContainerState = RecordOf<UserPermissionsFilterContainerStateProps>;

const defaultValues: UserPermissionsFilterContainerStateProps = {
  filter: Optional.empty(),
};

export const userPermissionsFilterContainerStateFactory =
  Record<UserPermissionsFilterContainerStateProps>(defaultValues);

type Actions =
  | ModifyFilterAction
  | CleanFilterAction;

export function userPermissionsFilterContainerReducers(
  state: UserPermissionsFilterContainerState = userPermissionsFilterContainerStateFactory(),
  action: Actions): UserPermissionsFilterContainerState {

  switch (action.type) {
    case UserPermissionsFiltersActionTypes.MODIFY_FILTER:
      return state.set("filter", action.payload);
    case UserPermissionsFiltersActionTypes.CLEAN_FILTER:
      return state.set("filter", Optional.empty());
    default:
      return state;
  }
}
