// @ts-nocheck
import React, { SVGProps } from "react";
import styled from "styled-components";

const CreateActionPlanSvg: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  const { fill = "#FFF" } = props;
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      viewBox="0 0 18 20"
    >
      <g fill="none" fillRule="evenodd" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2">
        <rect className="btn" x="0" y="0" width="10" height="10"/>
        {/* eslint-disable max-len */}
        <path d="M14.157 6.967l-2.21-.98-.821-2.091 2.597-2.633A6.656 6.656 0 0 0 11.82 1c-2.492.218-4.387 2.37-4.33 4.914-.005.481.083.958.26 1.404l-5.786 4.886a2.959 2.959 0 0 0-.961 2.069 2.968 2.968 0 0 0 .788 2.143c.55.585 1.322.901 2.117.868a2.756 2.756 0 0 0 2.04-1.044l3.917-4.744.915-1.108a5.587 5.587 0 0 0 2.425.172c1.741-.367 3.14-1.684 3.637-3.422a5.144 5.144 0 0 0-.087-2.804l-2.598 2.633z"/>
        <path fill="#D8D8D8" d="M14.895 16H9.842M12.368 13.734v4.837"/>
        {/* eslint-disable max-len */}
      </g>
    </StyledSvg>

  );
};
const StyledSvg = styled.svg<SVGProps<SVGSVGElement>>`
  fill: ${props => props.fill};

  rect.btn {
    stroke:#fff;
    fill:#fff;
    fill-opacity:0;
    stroke-opacity:0;
  }
`;
export default CreateActionPlanSvg;
