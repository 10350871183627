import React from "react";
import styled from "styled-components";
import { Col, Form, Row } from "antd";
import { RowProps } from "antd/lib/row";
import ContainerCard from "@components/core/ContainerCard";
import LabelMapper from "@src/utils/constants/SurveyCreation";
import { NotificationChannels } from "hyphen-lib/dist/domain/NotificationChannels";
import { MergeTagResource } from "hyphen-lib/dist/domain/resource/MergeTagResource";
import { NotificationChannelsForm } from "@screens/Insights/components/NotificationChannelsForm";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { NumberInput } from "@components/core/NumberInput";
import { Map as ImmutableMap } from "immutable";
import { toHumanReadableErrorMessage } from "@src/utils/formValidations";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import { PulsePollResource } from "hyphen-lib/dist/domain/resource/PulsePollResource";
import { SurveyResource } from "hyphen-lib/dist/domain/resource/SurveyResource";
import { getOr, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { Trans, WithTranslation, withTranslation } from "react-i18next";

interface CommunicationFormValues {
  readonly channels: NotificationChannels;
  readonly reminderInterval: number;
  readonly reminderLimit: number;
}

interface CommunicationFormProps extends WithTranslation{
  readonly type?: Optional<typeof PulsePollResource.TYPE | typeof SurveyResource.TYPE>;
  readonly labelMapper?: ImmutableMap<string, any>;
  readonly values: CommunicationFormValues;
  readonly disabled?: boolean;
  readonly allowedChannels: CompanyResource.Channels;
  readonly allowedMergeTags: MergeTagResource[];
  readonly errors: ImmutableMap<string, WrongEntityException.WrongField>;

  readonly onChange: (values: Partial<CommunicationFormValues>) => void;
  readonly configuredSurveyLanguages?: string[];
  readonly enableTranslation: boolean;
  readonly isMultiLanguageSurveyEnabled: boolean;
}

class CommunicationFormComponent extends React.Component<CommunicationFormProps> {
  handleReminderLimitChange = (e: any) => {
    const { value } = e.target;

    this.props.onChange({
      reminderLimit: parseInt(value || 0, 10),
    });
  };

  handleReminderIntervalChange = (e: any) => {
    const { value } = e.target;

    this.props.onChange({
      reminderInterval: parseInt(value || 0, 10),
    });
  };

  getErrorMessageForField = (fieldName: string): Optional<string> => {
    const { errors = ImmutableMap<string, WrongEntityException.WrongField>() } = this.props;
    return Optional.map(
      errors.get(fieldName),
      error => toHumanReadableErrorMessage(this.props.t, error, fieldName)
    );
  };

  getInfoText = () => {
    const { type, labelMapper } = this.props;

    if (type === PulsePollResource.TYPE && isNotNullNorUndefined(labelMapper)) {
      return labelMapper.getIn(["settings", "stopReminderInfo"]);
    }

    return undefined;
  };

  render() {
    const {
      values: {
        channels,
        reminderInterval,
        reminderLimit,
      },
      allowedMergeTags,
      allowedChannels,
      disabled,
      errors = ImmutableMap<string, WrongEntityException.WrongField>(),

      onChange,
    } = this.props;

    const labelMapper = this.props.labelMapper ? this.props.labelMapper : LabelMapper;

    return (
      <Container
        title="Communications"
        description="You can still make changes to these settings after the survey is launched."
      >
        <Row type="flex" justify="center">
          <Col span={12}>
            <Form>
              <NotificationChannelsForm
                channels={channels}
                allowedChannels={allowedChannels}
                allowedMergeTags={allowedMergeTags}
                errors={WrongEntityException.unPrefixErrorsMap(errors, "channels")}
                // tslint:disable-next-line:jsx-no-lambda
                onChange={newChannels => onChange({ channels: newChannels })}
                disabled={disabled}
                configuredSurveyLanguages={getOr(this.props.configuredSurveyLanguages, [])}
                enableTranslation={this.props.enableTranslation}
                isMultiLanguageSurveyEnabled={this.props.isMultiLanguageSurveyEnabled}
              />

              <FrequencyRow type="flex" justify="space-between" gutter={24}>
                <Col span={12}>
                  <NumberInput
                    label={labelMapper.getIn(["settings", "reminderInterval"])}
                    value={reminderInterval}
                    error={this.getErrorMessageForField("reminderInterval")}
                    data-cy="surveyCreation_settings_reminderInterval"
                    suffix={<Trans>days</Trans>}
                    onChange={this.handleReminderIntervalChange}
                    disabled={disabled}
                  />
                </Col>
                <Col span={12}>
                  <NumberInput
                    label={labelMapper.getIn(["settings", "stopReminder"])}
                    value={reminderLimit}
                    error={this.getErrorMessageForField("reminderLimit")}
                    data-cy="surveyCreation_settings_reminderLimit"
                    suffix={<Trans>days</Trans>}
                    onChange={this.handleReminderLimitChange}
                    disabled={disabled}
                    infoText = {this.getInfoText()}
                  />
                </Col>
              </FrequencyRow>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export const CommunicationForm = withTranslation()(CommunicationFormComponent);

const Container = styled(ContainerCard)`
  margin-top: 32px;
`;

const FrequencyRow = styled(Row) <RowProps>`
  margin-top: 16px;
`;
