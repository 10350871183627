import React from "react";
import { Trans } from "react-i18next";
import Modal from "@components/core/Modal";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import Button from "@src/components/core/Button";
import { ModalProps } from "antd/lib/modal";
import { getOr } from "hyphen-lib/dist/lang/Objects";

interface Props {
  missingFields: string[];
  visible: boolean;
  onOkClick: () => void;
  onCancelClick: () => void;
  currentStep: string;
  isParticipantsCountForDimensionsError?: boolean;
}

export function ContinueAlert(props: Props) {
  return (
    <StyledModal
      visible={props.visible}
      title={<Trans>Are you sure?</Trans>}
      onOk={props.onOkClick}
      onCancel={props.onCancelClick}
      footer={[
        <ActionButtons
          data-cy="wizard_areYouSure_keepGoing"
          color="grey"
          key="forward"
          onClick={props.onOkClick}
          translate="yes"
        >
          Keep Going
        </ActionButtons>,
        <ActionButtons
          data-cy="wizard_areYouSure_back"
          color="blue"
          key="back"
          onClick={props.onCancelClick}
          translate="yes"
        >
          {`Back to ${props.currentStep}`}
        </ActionButtons>,
      ]}
    >
      {
      getOr(props.isParticipantsCountForDimensionsError, false) ? 
        <Container>
          <Trans>It looks like there are no recipients defined for this survey.
            Please include some recipients before launching the survey.</Trans>   
        </Container> : <Container>
          <Trans>It looks like we’re missing some information. The following fields still need to be completed:</Trans>
          <Content>
            {props.missingFields.join(", ")}.
          </Content>
        </Container>
      }
    </StyledModal>
  );
}

const Container = styled.div`
  padding: 16px 32px;
  color: ${Palette.bluishGrey};
`;

const ActionButtons = styled(Button)`
  // width: 158px;
`;

const Content = styled.section`
  margin-top: 8px;
  color: ${Palette.darkBlueGrey};
`;

const StyledModal = styled(Modal)<ModalProps & {children: React.ReactNode}>``;
