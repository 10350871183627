import React from "react";
import styled from "styled-components";
import { SelectValue } from "antd/lib/select";

import { areEquals, getOr, isNotNullNorUndefined, mapAtIndexOr, isNotEmptyObject } from "@hyphen-lib/lang/Objects";

import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { CompareWithOption } from "@screens/Insights/components/ViewOptions/components/CompareWith";
import ViewOptions, { getViewOptionValuesFromLocation } from "@screens/Insights/components/ViewOptions";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { RouteComponentProps, withRouter } from "react-router";
import { QuestionType } from "hyphen-lib/dist/domain/common/QuestionType";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { AnonymityFiltered } from "@components/core/AnonymityFiltered";
import { getViewOptionDefinitions } from "@src/utils/ViewOptions";
import { areArraysEqual, isEmpty } from "hyphen-lib/dist/lang/Arrays";
import { Participation } from "hyphen-lib/dist/domain/common/Participation";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
// eslint-disable-next-line max-len
import { MultipleChoiceQuestionReportResource } from "hyphen-lib/dist/domain/resource/report/MultipleChoiceQuestionReportResource";
import { Loading, LoadingContainer } from "@screens/Insights/Survey/components/Loading";
import { AnonymityFilterExplanation } from "hyphen-lib/dist/domain/common/AnonymityFilterExplanation";
import ContainerCard from "@src/components/core/ContainerCard";
import NoResult from "@src/components/core/NoResult";
import { ParticipationReportResource } from "hyphen-lib/dist/domain/resource/survey/report/ParticipationReportResource";
import { Store } from "hyphen-lib/dist/util/store/Store";
import VotesSentiment, { getBarConfigForRendering } from "../../../components/Reports/VotesSentiment";
import CommentSentiment from "../../../components/Reports/CommentSentiment";
import SurveyReportHeader from "../SurveyReportHeader";
import Answers from "./components/Answers";
import QuestionTrendLines from "./components/QuestionTrendLines";
import { SurveyTopicCommentResource } from "hyphen-lib/dist/domain/resource/survey/report/SurveyTopicCommentResource";
import SurveyTopicsChart, { ChartWrapper } from "../Topics/SurveyTopicsChart";
import Spin from "@src/components/core/Spin";
import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";

interface Props extends RouteComponentProps {
  readonly participation: Participation;
  readonly enabledFilters: string[];
  readonly enabledCustomFilters?: string[];
  readonly selectedDimension: string;
  readonly enabledViewOptions: string[];
  readonly compareWithOptions: CompareWithOption[];
  readonly questionText: string;
  readonly questionType: QuestionType;
  readonly allowComments: boolean;
  readonly anonymityThreshold: number;
  readonly isAnonymous: boolean;
  readonly question: Loadable<MultipleChoiceQuestionReportResource>;
  readonly hasCommentsAccess: boolean;
  readonly hasTopicsAccess: boolean;
  readonly participationBySegments: Store.Element<ParticipationReportResource>;
  readonly topicsOverview: Loadable<SurveyTopicCommentResource>;
  readonly dimensionsLabels: Dimensions;
  readonly surveyId: string;
  updateSegmentByInPath(selectedDimension: SelectValue): void;
  onCommentsCountClick(): void;
}

interface QuestionHighlightReportState {
  readonly viewOptions: {
    readonly comparison: boolean;
    readonly compareWith: Optional<string>;
  };
}
export class QuestionHighlightReport extends React.Component<Props, QuestionHighlightReportState> {
  constructor(props: Props) {
    super(props);

    const viewOptions = getViewOptionValuesFromLocation(props.location);

    this.state = this.mapViewOptionsToState(
      viewOptions,
      props.compareWithOptions,
      this.getDefaultViewOptionValues()
    );
  }

  componentDidUpdate(prevProps: Props): void {
    const prevViewOptions = parseQueryString(prevProps.location.search).viewOptions;
    const currentViewOptions = parseQueryString(this.props.location.search).viewOptions;
    if (not(areEquals(prevViewOptions, currentViewOptions)) ||
      not(areArraysEqual(prevProps.compareWithOptions, this.props.compareWithOptions)) ||
      not(areArraysEqual(prevProps.enabledViewOptions, this.props.enabledViewOptions))) {
      this.setState(
        this.mapViewOptionsToState(
          getViewOptionValuesFromLocation(this.props.location),
          this.props.compareWithOptions,
          this.getDefaultViewOptionValues()
        )
      );
    }
  }

  getDefaultViewOptionValues = () => {
    return {
      comparison: true,
    };
  };

  mapViewOptionsToState = (viewOptions: Dictionary<any>,
    compareWithOptions: CompareWithOption[],
    defaultValues: Dictionary<boolean>) => {

    const compareWithMode: Optional<string> = getOr(
      viewOptions.compareWith,
      mapAtIndexOr(compareWithOptions, 0, o => o.key, undefined)
    );

    return {
      viewOptions: {
        comparison:
          isNotNullNorUndefined(compareWithMode) &&
          getOr(viewOptions.comparison, defaultValues.comparison),
        compareWith: compareWithMode,
      },
    };
  };

  handleViewOptionsChange = (viewOptions: Dictionary<any>) => {
    this.setState(
      this.mapViewOptionsToState(
        viewOptions,
        this.props.compareWithOptions,
        this.getDefaultViewOptionValues()
      )
    );
  };

  getViewOptionsComponent = (enabledViewOptions: string[],
    compareWithOptions: CompareWithOption[]): React.ReactNode => {

    const viewOptions = getViewOptionDefinitions(enabledViewOptions, compareWithOptions);
    if (isEmpty(viewOptions)) {
      return null;
    }

    return (
      <ViewOptions
        viewOptions={viewOptions}
        defaultValues={this.state.viewOptions}
        onChange={this.handleViewOptionsChange}
      />
    );
  };

  render() {
    const {
      participation,
      question,
      selectedDimension,
      updateSegmentByInPath,
      enabledFilters,
      enabledCustomFilters,
      enabledViewOptions,
      compareWithOptions,
      questionText,
      anonymityThreshold,
      hasCommentsAccess,
      hasTopicsAccess,
      onCommentsCountClick,
      topicsOverview,
      allowComments,
    } = this.props;

    const {
      viewOptions,
    } = this.state;

    return (
      <>
        <SurveyReportHeader
          participation={participation}
          enabledFilters={enabledFilters}
          enabledCustomFilters={enabledCustomFilters}
          viewOptionsComponent={this.getViewOptionsComponent(enabledViewOptions, compareWithOptions)}
        />
        {
          Loadable.isNotLoaded(question) ?
            <Loading /> :
            this.renderQuestionHighlight(
              questionText,
              question.value,
              viewOptions,
              compareWithOptions,
              selectedDimension,
              updateSegmentByInPath,
              anonymityThreshold,
              hasCommentsAccess,
              hasTopicsAccess,
              onCommentsCountClick,
              topicsOverview,
              allowComments
            )
        }
      </>
    );
  }

  // noinspection JSMethodCanBeStatic
  private renderQuestionHighlight(questionText: string,
    question: MultipleChoiceQuestionReportResource,
    viewOptions: QuestionHighlightReportState["viewOptions"],
    compareWithOptions: CompareWithOption[],
    selectedDimension: string,
    updateSegmentByInPath: (selectedDimension: SelectValue) => void,
    anonymityThreshold: number,
    hasCommentsAccess: boolean,
    hasTopicsAccess: boolean,
    onCommentsCountClick: () => void,
    topicsOverview: Loadable<SurveyTopicCommentResource>,
    allowComments:boolean) {

    if (question.filteredForAnonymity) {
      if ((question.explanation as AnonymityFilterExplanation.NotEnoughVoters).numberOfVoters === 0) {
        return (
          <ContainerCard title="Results">
            <NoResult type="minimal" description={AnonymityFilterExplanation.Reason.NOT_ENOUGH_VOTERS} />
          </ContainerCard>
        );
      }
      return (
        <CommonContainer>
          <AnonymityContainer>
            <AnonymityFiltered
              explanation={question.explanation}
              anonymityThreshold={anonymityThreshold}
              translate="yes"
            />
          </AnonymityContainer>
        </CommonContainer>
      );
    }

    const {
      votesSentimentSummary: {
        numberOfComments,
        netSentimentScore,
        numberOfVotes,
      },
      segmentsSummary: { dimensions }
    } = question;
    const params = this.props.match.params as {questionId: string ; id: string};
    const withScore = question.type === MultipleChoiceQuestionReportResource.NonFilteredType.WITH_SCORE;
    const { participationBySegments, isAnonymous, dimensionsLabels, surveyId } = this.props;
    const { choiceLabels, distribution, barTheme } = getBarConfigForRendering(question);
    return <>
      <CommonContainer>
        {
          withScore ?
            (<>
              <VotesSentiment
                overviewLabel={getOr(questionText, "")}
                question={question}
                areComparisonsVisible={viewOptions.comparison}
                compareWithOptions={compareWithOptions}
                comparisonKey={viewOptions.compareWith}
                hasCommentsAccess={hasCommentsAccess}
                /**
                 * - Need to show comments by question
                 * Ticket: EN-1351
                 */
                onCommentsClick={undefined}
                postAndSurveySentiment={question.postAndSurveySentiment}
              />
              {question.trend.length > 1 &&  <QuestionTrendsContainer>
                <QuestionTrendLines
                  questionId={params.questionId}
                  trend={question.trend}
                />
              </QuestionTrendsContainer>}
            </>
            ) : (
              <CommentSentiment
                questionText={questionText}
                hasSentimentScore
                graphColors={barTheme}
                numberOfComments={numberOfComments}
                netSentimentScore={netSentimentScore}
                choiceLabels={choiceLabels}
                distribution={distribution}
                totalVotes={numberOfVotes}
                /**
                 *  - Need to show comments by question
                 * Ticket: EN-1351
                 */
                onCommentsClick={undefined}
                postAndSurveySentiment={question.postAndSurveySentiment}
              />
            )
        }
      </CommonContainer>
      {isNotEmptyObject(dimensions) && (
        <AnswersContainer>
          <Answers
            question={question}
            dimensionsLabels={dimensionsLabels}
            choiceLabels={question.votesSentimentSummary.choiceLabels}
            selectedDimension={selectedDimension}
            updateSegmentByInPath={updateSegmentByInPath}
            anonymityThreshold={anonymityThreshold}
            participationBySegments={participationBySegments}
            isAnonymous={isAnonymous}
            dimensions={dimensions}
          />
        </AnswersContainer>
      )}
      { (Loadable.isInError(topicsOverview) || !allowComments || not(hasTopicsAccess)) ? null: 
        Loadable.isNotLoaded(topicsOverview) ?
          <ChartWrapper>
            <LoadingContainer><Spin/></LoadingContainer>
          </ChartWrapper> :
          getOr(question.postAndSurveySentiment, true) && <SurveyTopicsChart 
          title={"Top topics in this question"}
          numberOfComments={topicsOverview.value.totalAvailableComments}
          topics={topicsOverview.value.topics}
          infoMessage={"These are the top topics identified for this question."}
          surveyId={surveyId}
          questionId={params.questionId}
          sectionName={questionText}
          />
      }
    </>;
  }
}

const QuestionTrendsContainer = styled.div`
  padding: 24px 32px;
  background-color: white;
`;

const CommonContainer = styled.div`
  margin-top: 24px;
`;

const AnswersContainer = styled.div`
  margin-top: 24px;
`;

const AnonymityContainer = styled.div`
  margin: 24px 0;
  padding: 24px 0;
  background: white;
`;

export default withRouter(QuestionHighlightReport);
