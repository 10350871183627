import { isNullOrUndefined } from "util";
import styled from "styled-components";
import { Trans } from "react-i18next";
import { CategoryReportResource } from "@hyphen-lib/domain/resource/report/CategoryReportResource";
import { DistributionResult } from "@hyphen-lib/domain/resource/report/common/DistributionResult";
import { getOr, isNotNullNorUndefined } from "@hyphen-lib/lang/Objects";
import { Heading } from "@components/core/Typography";
import { FavorabilityScore } from "@components/core/FavorabilityScore";
import { StackedBarGraph } from "@components/core/StackedBarGraph";
import Palette, { getBarThemeForFavorability } from "@src/config/theme/palette";
import { extractComparison } from "hyphen-lib/dist/business/calculation/benchmark/Benchmarks";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { ComparisonsOverview } from "@screens/Insights/Survey/components/ComparisonsOverview";
import { CompareWithOption } from "@screens/Insights/components/ViewOptions/components/CompareWith";
import { getMatchingOptionLabel } from "@src/utils/Comparisons";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Breadcrumb, goTo } from "@src/utils/locations";
import { adjustElementsAndPercentages } from "@src/utils/Graphs";
import { ScoreType } from "hyphen-lib/dist/domain/resource/report/common/Score";
import { SentimentWithComments } from "@src/screens/Insights/components/SentimentWithComments";
import CategoryTrend from "./components/CategoryTrend";
import { QuestionConfig } from "hyphen-lib/dist/domain/common/QuestionType";

interface Props {
  // fixme: component will accept category OR question, not both, refactor into one unique props named "source"
  readonly areComparisonsVisible: boolean;
  readonly category?: CategoryReportResource;
  readonly compareWithOptions: CompareWithOption[];
  readonly comparisonKey: Optional<string>;
  readonly overviewLabel: string;
  readonly hasCommentsAccess: boolean;
  readonly questionConfig: QuestionConfig;
  readonly onCommentsClick?: () => void;
}

interface MatchParams {
  id: string;

  /*
      fixme: BEFORE LAUNCH!!!
      fixme: BEFORE LAUNCH!!!
      fixme: BEFORE LAUNCH!!!
      fixme: BEFORE LAUNCH!!!
      fixme: BEFORE LAUNCH!!!
      fixme: BEFORE LAUNCH!!!
      fixme: BEFORE LAUNCH!!!

      targetId if we really need it, but not two properties and one of them being null :/
   */
  categoryId: string;
  questionId: string;
  surveyId: string;
}

function CategoryOverview({
  areComparisonsVisible,
  category,
  compareWithOptions,
  comparisonKey,
  overviewLabel,
  history,
  hasCommentsAccess,
  match: { params: { id: surveyId } },
  questionConfig,
  ...rest
}: Props & RouteComponentProps<MatchParams>) {

  const renderAnswerDistributionGraph = (
    choicesData: DistributionResult, 
    tailoredGraphData: any[], 
    numberOfVotes: number) => {
    const { pieceToAdd } = adjustElementsAndPercentages(choicesData);

    const data = choicesData.map(({ total, percentage }, index) => {
      const { backgroundColor, fontColor, label } = tailoredGraphData[index];
      return ({
        value: total,
        percentage: getOr(percentage, 0),
        percentageWidth: isNotNullNorUndefined(percentage) && percentage > 0 ?
          percentage + pieceToAdd :
          0,
        backgroundColor,
        fontColor,
        label,
      });
    });

    return <StackedBarGraph data={data} numberOfVotes = {numberOfVotes} width="100%" height="48px"/>;
  };

  if (isNullOrUndefined(category)) {
    return null;
  }

  const comparisonLabel = getMatchingOptionLabel(compareWithOptions, comparisonKey);
  const averageComparisonLabel = getMatchingOptionLabel(compareWithOptions, "average");
  const comparison = extractComparison(category.compare, comparisonKey);
  const averageComparison = extractComparison(category.compare, "average");
  const labelKey = `labels${category.distribution.length}` as keyof QuestionConfig.Likert;
  const graphDataToUse = getBarThemeForFavorability(
    category.distribution.length,  
    getOr(questionConfig.likert[labelKey] as string[], []));

  function goToComments() {
    const { onCommentsClick, location } = rest;
    if (isNotNullNorUndefined(onCommentsClick)) {
      return onCommentsClick();
    }
    goTo(history.location.pathname + "/comments" + location.search, Breadcrumb.stack(overviewLabel));
  }

  const hasAnswerDistribution = category.distribution.some((distribution) =>
    isNotNullNorUndefined(distribution.percentage)
  );

  // fixme set comparison label!
  return (
    <Container>
      <Heading size="large">
        <Trans>Overview</Trans>
      </Heading>
      {
        category && category.numberOfQuestions &&
        <Summary>{category.numberOfQuestions}&nbsp;
          <Trans>questions</Trans> • {category.numberOfVotes}&nbsp; 
          <Trans>answers</Trans></Summary>
      }
      <FavorabilitySentimentContainer>
        {isNotNullNorUndefined(category.favorability) && (
          <FavorabilityContainer>
            <Heading size="small" weight={"bold"}>
              <Trans>Favorability</Trans>
            </Heading>

            <FavorabilityComparisonContainer>
              <FavorabilityScoreContainer>
                <FavorabilityScore favorability={category.favorability} scoreType={ScoreType.FAVORABILITY}/>
              </FavorabilityScoreContainer>
              <ComparisonsOverview
                areComparisonsVisible={areComparisonsVisible}
                comparisonLabel={comparisonLabel}
                comparisonValue={comparison}
                averageComparisonLabel={averageComparisonLabel}
                averageComparisonValue={averageComparison}
                showSecondComparison={comparisonKey !== "average"}
              />
            </FavorabilityComparisonContainer>
          </FavorabilityContainer>
        )}
        {hasAnswerDistribution && (
          <AnswerDistributionContainer>
            <Heading size="small" weight={"bold"}>
              <Trans>Answer distribution</Trans>
            </Heading>
            <AnswerDistributionGraphContainer>
              {renderAnswerDistributionGraph(category.distribution, graphDataToUse, category.numberOfVotes)}
            </AnswerDistributionGraphContainer>
          </AnswerDistributionContainer>
        )}
        {getOr(category.postAndSurveySentiment, true) && (<div>
          <Heading size="small" weight={"bold"}>
            <Trans>Sentiment</Trans>
          </Heading>
          <SentimentWithComments
            hasComments
            netSentimentScore={category.netSentimentScore}
            hasCommentsAccess={hasCommentsAccess}
            numberOfComments={category.numberOfComments}
            onCommentsClick={goToComments}
          />
        </div>)}
      </FavorabilitySentimentContainer>
      <CategoryTrend surveyId={surveyId} trend={getOr(category.trends, [])} />
    </Container>
  );
}

const Container = styled.div`
  padding: 32px;
  background: ${Palette.white};
  font-family: Lato,sans-serif;
  color: ${Palette.veryDarkBlueGrey};
`;

const Summary = styled.div`
  margin-top: 8px;
  color: ${Palette.bluishGrey};
`;

const FavorabilitySentimentContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FavorabilityContainer = styled.div``;

const FavorabilityScoreContainer = styled.div`
  display: inline-block;

  span {
    font-size: 56px !important;
    line-height: 1;
  }
`;

const AnswerDistributionContainer = styled.div`
  flex-grow: 1;
  margin: 0 64px;
`;

const AnswerDistributionGraphContainer = styled.div`
  margin-top: 32px;
`;

const FavorabilityComparisonContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 16px;
`;

export default withRouter(CategoryOverview);
export { CategoryOverview as PureCategoryOverview };
