import { OrderedMap as OrderedImmutableMap } from "immutable";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { StepDefinition } from "@src/utils/wizard/Steps";
import { PulsePollResource } from "hyphen-lib/dist/domain/resource/PulsePollResource";
import { Post } from "hyphen-lib/dist/domain/Post";
import { getOr } from "hyphen-lib/dist/lang/Objects";
import { PulsePollStepValue } from "../store/pulsePollEditTypes";

const STEP_DEFINITIONS: OrderedImmutableMap<
PulsePollStepValue,
StepDefinition<PulsePollStepValue>
> = OrderedImmutableMap({
  settings: {
    key: "settings",
    label: "Settings",
    nextStep: "question",
    fieldErrorMatchers: [
      /^name$/,
      /^type$/,
      /^logo$/,
      /^introductionText$/,
      /^isAnonymous$/,
      /^allowSkipQuestion$/,
      /^reminderInterval$/,
      /^reminderLimit$/,
      /^channels.*$/,
    ],
  },
  question: {
    key: "question",
    label: "Question",
    nextStep: "audience",
    fieldErrorMatchers: [
      /^question$/,
      /^choices$/,
    ],
  },
  audience: {
    key: "audience",
    label: "Audience",
    nextStep: "access",
    fieldErrorMatchers: [/^audience.*$/],
  },
  access: {
    key: "access",
    label: "Access",
    nextStep: "summary",
  },
  summary: {
    key: "summary",
    label: "Summary",
    nextStep: Optional.empty(),
  },
}) as OrderedImmutableMap<PulsePollStepValue, StepDefinition<PulsePollStepValue>>;

export function getAllPulsePollStepDefinitions(): OrderedImmutableMap<
PulsePollStepValue,
StepDefinition<PulsePollStepValue>
> {
  return STEP_DEFINITIONS;
}

export function getPulsePollMaxReachedStep(
  pulsePoll: PulsePollResource
): 1 | 2 | 3 | 4 | 5 {
  if (pulsePoll.status === Post.Status.DRAFT) {
    return getOr(pulsePoll.maxReachedStep, 1);
  }
  return 5;
}
