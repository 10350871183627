import { FSA } from "flux-standard-action";
import { PrivateMessageContext } from "@hyphen-lib/domain/PrivateMessageContext";
import { createRequest } from "@src/utils/networks";
import {
  ActionTypes as NetworkActionTypes,
  NetworkRequestAction,
  NetworkEventRequestAction,
  NetworkEventSuccessAction,
  NetworkEventErrorAction
} from "@store/network/actions";

export enum SurveyReportActionTypes {
  SEND_PRIVATE_MESSAGE_REQUEST = "surveyReports/SEND_PRIVATE_MESSAGE_REQUEST",
  SEND_PRIVATE_MESSAGE_SUCCESS = "surveyReports/SEND_PRIVATE_MESSAGE_SUCCESS",
  SEND_PRIVATE_MESSAGE_ERROR = "surveyReports/SEND_PRIVATE_MESSAGE_ERROR",
  UPDATE_PRIVATE_MESSAGE_TEXT = "surveyReports/UPDATE_PRIVATE_MESSAGE_TEXT",
  TOGGLE_PRIVATE_MESSAGE_MODAL = "surveyReports/TOGGLE_PRIVATE_MESSAGE_MODAL",
  CLEAR_PRIVATE_MESSAGE_MODAL = "surveyReports/CLEAR_PRIVATE_MESSAGE_MODAL",
}

interface Payloads {
  [SurveyReportActionTypes.SEND_PRIVATE_MESSAGE_REQUEST]: Request;
  [SurveyReportActionTypes.SEND_PRIVATE_MESSAGE_SUCCESS]: Response;
  [SurveyReportActionTypes.SEND_PRIVATE_MESSAGE_ERROR]: Error;
  [SurveyReportActionTypes.UPDATE_PRIVATE_MESSAGE_TEXT]: string;
}

export interface SendPrivateMessageRequestAction
  extends FSA<
  SurveyReportActionTypes.SEND_PRIVATE_MESSAGE_REQUEST,
  Payloads[SurveyReportActionTypes.SEND_PRIVATE_MESSAGE_REQUEST]
  > {
  type: SurveyReportActionTypes.SEND_PRIVATE_MESSAGE_REQUEST;
  payload: Payloads[SurveyReportActionTypes.SEND_PRIVATE_MESSAGE_REQUEST];
}

export interface SendPrivateMessageSuccessAction
  extends FSA<
  SurveyReportActionTypes.SEND_PRIVATE_MESSAGE_SUCCESS,
  Payloads[SurveyReportActionTypes.SEND_PRIVATE_MESSAGE_SUCCESS]
  > {
  type: SurveyReportActionTypes.SEND_PRIVATE_MESSAGE_SUCCESS;
  payload: Payloads[SurveyReportActionTypes.SEND_PRIVATE_MESSAGE_SUCCESS];
}

export interface SendPrivateMessageErrorAction
  extends FSA<
  SurveyReportActionTypes.SEND_PRIVATE_MESSAGE_ERROR,
  Payloads[SurveyReportActionTypes.SEND_PRIVATE_MESSAGE_ERROR]
  > {
  type: SurveyReportActionTypes.SEND_PRIVATE_MESSAGE_ERROR;
  payload: Payloads[SurveyReportActionTypes.SEND_PRIVATE_MESSAGE_ERROR];
}

export const sendPrivateMessageRequest = (
  surveyId: string,
  postId: string,
  voteId: string,
  initialMessage: string
) => {
  
  const context: PrivateMessageContext.Survey = {
    type: PrivateMessageContext.Type.SURVEY,
    surveyId,
    voteId,
  };

  return createRequest("/privateConversations/start", {
    method: "POST",
    body: JSON.stringify({ postId, context, initialMessage }),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const surveyReportActionCreators = {
  sendPrivateMessage: (
    surveyId: string,
    postId: string,
    voteId: string,
    initialMessage: string,
    onSuccessRedirect?: (payload: NetworkEventSuccessAction["payload"]) => void
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: sendPrivateMessageRequest(surveyId, postId, voteId, initialMessage),

    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: SurveyReportActionTypes.SEND_PRIVATE_MESSAGE_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: SurveyReportActionTypes.SEND_PRIVATE_MESSAGE_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: SurveyReportActionTypes.SEND_PRIVATE_MESSAGE_ERROR, payload }),
      ],
      onSuccessRedirect,
    },
  }),
  updatePrivateMessageText: (payload: string) => ({
    type: SurveyReportActionTypes.UPDATE_PRIVATE_MESSAGE_TEXT,
    payload,
  }),
  clearPrivateMessageModal: () => ({
    type: SurveyReportActionTypes.CLEAR_PRIVATE_MESSAGE_MODAL,
  }),
  togglePrivateMessageModal: (
    surveyId: string,
    postId: string,
    voteId: string,
    commentText: string
  ) => ({
    type: SurveyReportActionTypes.TOGGLE_PRIVATE_MESSAGE_MODAL,
    payload: { surveyId, postId, voteId, commentText },
  }),
};
