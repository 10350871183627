// @ts-nocheck
import React from "react";
import Palette from "@src/config/theme/palette";

export function WebIconSvg() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke="none"
        strokeWidth="0.2"
        fill="none"
        fillRule="evenodd"
      >
        <g id="ic_fluent_globe_24_regular" fill="#212121" fillRule="nonzero">
          <path
            d="M12.0000002,1.99896738 C17.523704,1.99896738 22.0015507,
            6.47681407 22.0015507,12.0005179 C22.0015507,17.5242217
            17.523704,22.0020684 12.0000002,22.0020684 C6.47629639,
            22.0020684 1.99844971,17.5242217 1.99844971,12.0005179 
            C1.99844971,6.47681407 6.47629639,1.99896738 12.0000002,
            1.99896738 Z M14.9389045,16.5002571 L9.06109593,16.5002571
            C9.71313678,18.9143799 10.8464883,20.5020684 12.0000002,20.5020684
            C13.1535121,20.5020684 14.2868636,18.9143799 14.9389045,16.5002571
            Z M7.50830976,16.5008106 L4.78582644,16.5006803 C5.744167,18.0337454
            7.17761035,19.2393418 8.87999355,19.9113065 C8.35771536,19.0914835 
            7.92672586,18.0651949 7.60972411,16.8958162 L7.50830976,16.5008106 
            L7.50830976,16.5008106 Z M19.214174,16.5006803 L16.4916907,16.5008106 
            C16.167923,17.8345819 15.700316,19.000392 15.1189501,19.9113639 C16.7159251,19.2813431 
            18.0755983,18.1823607 19.0289676,16.7842422 L19.214174,16.5006803 L19.214174,16.5006803 
            Z M7.09342418,9.9998686 L3.73581796,9.9998686 L3.73581796,9.9998686 L3.73106043,10.0170734 
            C3.57897914,10.6534279 3.49844971,11.3175685 3.49844971,12.0005179 C3.49844971,13.0566718 
            3.69103899,14.0678442 4.04301473,15.000832 L7.21617552,15.0004667 C7.07387589,14.0513637 
            6.99844971,13.0431468 6.99844971,12.0005179 C6.99844971,11.3165055 7.03091285,10.6473039 
            7.09342418,9.9998686 Z M15.3969276,9.99993255 L8.60307281,9.99993255 C8.53505119,10.640197 
            8.49844971,11.3099099 8.49844971,12.0005179 C8.49844971,13.0600224 8.5845969,14.070347 
            8.73818201,15.000607 L15.2618184,15.000607 C15.4154035,14.070347 15.5015507,13.0600224 
            15.5015507,12.0005179 C15.5015507,11.3099099 15.4649492,10.640197 15.3969276,9.99993255 
            Z M20.2646076,9.999033 L16.9065762,9.99988793 C16.9690876,10.6473039 17.0015507,11.3165055 
            17.0015507,12.0005179 C17.0015507,13.0431468 16.9261245,14.0513637 16.7838249,15.0004667 
            L19.9569857,15.000832 C20.3089614,14.0678442 20.5015507,13.0566718 20.5015507,12.0005179 
            C20.5015507,11.3111121 20.4194915,10.6408723 20.2646076,9.999033 Z M8.88105029,4.0896719 
            L8.85814489,4.09838857 C6.81083065,4.91309834 5.15481653,6.500408 4.25014894,8.50027116 
            L7.29826946,8.50063757 C7.61162388,6.74784533 8.15846945,5.22192073 8.88105029,4.0896719 
            Z M12.0000002,3.49896738 L11.8843481,3.50426666 C10.6189068,3.62002448 9.39642826,5.62198962 
            8.82871306,8.50021862 L15.1712874,8.50021862 C14.6051632,5.63005613 13.3879407,3.63125276 
            12.1262908,3.50528435 L12.0000002,3.49896738 Z M15.1200069,4.08972931 L15.2268438,4.26382329 
            C15.8960528,5.37628985 16.4041675,6.83617446 16.701731,8.50063757 L19.7498515,8.50027116 
            C18.8852005,6.58886886 17.3342021,5.05432991 15.4112068,4.21100469 L15.1200069,4.08972931 Z"
            id="🎨-Color"
          />
        </g>
      </g>
    </svg>
  );
}
