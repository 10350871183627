// @ts-nocheck
import React from "react";
export function LifecycleSvg() {
  return (
    <svg height="16px" data-cy="lifeCycleSvg" viewBox="0 0 394 394" width="16px" xmlns="http://www.w3.org/2000/svg">
      {/* eslint-disable-next-line max-len */}
      <path fill="#aab9ce" strokeWidth="8px" d="m195.742188 14c31.976562 0 63.992187 9.066406 92.585937 26.226562 20.054687 12.054688 37.832031 27.546876 52.515625 45.769532l-53.65625-1.769532-.460938 13.992188 67.835938 2.238281c3.863281.125 7.097656-2.902343 7.226562-6.765625 0-.15625 0-.308594 0-.460937l-2.238281-67.835938-13.992187.460938 1.507812 45.734375c-14.789062-17.082032-32.179687-31.71875-51.535156-43.367188-30.769531-18.464844-65.277344-28.222656-99.789062-28.222656-55.140626 0-105.972657 22.734375-143.128907 64.019531-32.945312 36.605469-52.613281 85.847657-52.613281 131.722657h14c0-42.511719 18.324219-88.25 49.019531-122.355469 34.464844-38.296875 81.601563-59.386719 132.722657-59.386719zm0 0"/>
      {/* eslint-disable-next-line max-len */}
      <path fill="#aab9ce" strokeWidth="8px" d="m330.980469 318.097656c-34.464844 38.300782-81.597657 59.386719-132.722657 59.386719-31.976562 0-63.992187-9.066406-92.585937-26.222656-20.054687-12.058594-37.832031-27.550781-52.515625-45.773438l53.65625 1.773438.460938-13.996094-67.835938-2.234375c-1.9375-.078125-3.816406.664062-5.179688 2.042969-1.371093 1.371093-2.109374 3.246093-2.046874 5.183593l2.238281 67.835938 13.992187-.460938-1.507812-45.734374c14.789062 17.078124 32.179687 31.714843 51.535156 43.367187 30.769531 18.460937 65.277344 28.21875 99.789062 28.21875 55.140626 0 105.972657-22.734375 143.128907-64.019531 32.945312-36.605469 52.613281-85.847656 52.613281-131.722656h-14c0 42.511718-18.324219 88.253906-49.019531 122.355468zm0 0"/>
    </svg>
  );
}
