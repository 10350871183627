import React from "react";
import Palette from "@src/config/theme/palette";
import { TransWrapper } from "@src/components/core/withTrans";
import styled from "styled-components";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import Button from "@components/core/Button";
import { fetchAssetById } from "@src/utils/exports";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Error } from "./types";
import { isNullOrEmpty } from "hyphen-lib/dist/lang/Strings";

export interface Props {
  maxImageSize: number;
  imageId?: Optional<string>;
  label: string;
  buttonText: string;
  description: string;
  previewText: string;
  onSelect?: (error: Error, file: any) => void;
  removeImage?: () => void;
  style?: object;
  descriptionStyle?: object;
  disabled?: boolean;
  readonly translate?: string;
}

interface UploadImageState {
  showPreview: boolean;
  error: Error;
  imageSrc: string;
  imageName: string;
}

export class ImageUpload extends React.Component<Props, UploadImageState> {
  private inputElement: React.RefObject<HTMLInputElement>;
  constructor(props: Props) {
    super(props);
    this.state = {
      showPreview: false,
      error: {
        type: null,
        description: "",
      },
      imageSrc: "",
      imageName: "",
    };
    this.inputElement = React.createRef();
  }

  validateFile = (files: any) => {
    if (files.length > 0) {
      const file = files[0];
      const acceptedFileTypes = ["image/jpeg", "image/jpg", "image/png"];
      const { maxImageSize } = this.props;
      let error = {
        type: null,
        description: "",
      } as Error;

      if (!acceptedFileTypes.includes(file.type)) {
        error = {
          type: "FileTypeError",
          description: "This file type is not supported. Please upload either a PNG or JPG.",
        };
      } else if (file.size > maxImageSize) {
        error = {
          type: "FileSizeError",
          description: "Your file cannot be larger than 2MB. Please upload a smaller file.",
        };
      } else {
        // Show preview of the image
        const reader = new FileReader();
        reader.onload = () => {
          this.setState({
            imageSrc: reader.result as string,
            imageName: file.name,
            showPreview: true,
            error,
          });
        };
        reader.readAsDataURL(file);
      }

      if (isNotNullNorUndefined(this.props.onSelect)) {
        this.props.onSelect(error, file);
      }

      // This resets the value in the image, else if you select the same image a second time
      // the onChange event does not get triggered
      if (isNotNullNorUndefined(this.inputElement.current)) {
        this.inputElement.current.value = "";
      }

      this.setState({
        error,
      });
    }
  };

  removeImage(e: any) {
    this.setState({
      imageSrc: "",
      imageName: "",
      showPreview: false,
    }, () => {
      if (isNotNullNorUndefined(this.props.removeImage)) {
        this.props.removeImage();
      }
    });
  }

  componentDidMount() {
    if (isNotNullNorUndefined(this.props.imageId)) {
      this.setState({
        showPreview: true,
      });
    }else {
      this.setState({
        showPreview: false,
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.imageId !== this.props.imageId) {
      this.setState({ showPreview: true });
    }
  }

  render() {
    const { imageId, descriptionStyle, style, ...rest} = this.props;
    const { imageSrc, imageName, showPreview } = this.state;

    const uploadImageStyle = {marginBottom: 32, ...style};

    if (showPreview && (imageId || imageSrc)) {
      if (isNotNullNorUndefined(imageId) && isNullOrEmpty(imageSrc)) {
        fetchAssetById(imageId, "", "", true).then((res)=>{
          this.setState({
            imageSrc: res as string
          });
        },(err)=>{
          console.log(err);
        }); 
      }

      return (
        <UploadImageComponent
          style={{...uploadImageStyle}}
        >
          <p> <TransWrapper {...rest}>{this.props.label}</TransWrapper> </p>
          <ImageContainer
            src={imageSrc}
          />

          <ImageAction>
            <ImageName>{imageName}</ImageName>
            { /* tslint:disable-next-line:jsx-no-lambda */}
            <RemoveButton onClick={(e: any) => this.removeImage(e)}
              disabled={this.props.disabled}
              translate="yes">Remove</RemoveButton>
          </ImageAction>

          <Description>
            <TransWrapper {...rest}>{this.props.previewText}</TransWrapper>
          </Description>
        </UploadImageComponent>
      );
    } else {
      return (
        <UploadImageComponent style={{...uploadImageStyle}}>
          <p> <TransWrapper {...rest}>{this.props.label}</TransWrapper> </p>
          <UploadButtonLabel
            disabled={(this.props.disabled ? true : false)}
            htmlFor="fileToUpload">
            <TransWrapper {...rest}>{this.props.buttonText}</TransWrapper>
          </UploadButtonLabel>
          <HiddenInput
            ref={this.inputElement}
            type="file"
            name="fileToUpload"
            id="fileToUpload"
            accept="image/*"
            data-cy="surveyCreation_settings_uploadLogo"
            disabled={this.props.disabled}
            // tslint:disable-next-line:jsx-no-lambda
            onChange={(e: any) => this.validateFile(e.target.files)} />
          <Description style={{height: 40, ...descriptionStyle}}>
            <TransWrapper {...rest}>{this.props.description}</TransWrapper>
          </Description>
        </UploadImageComponent>

      );
    }
  }
}

const UploadButtonLabel = styled.label<{ disabled: boolean }>`
  display: inline-block;
  justify-content: flex-end;
  width: 128px;
  height: 40px;
  border-radius: 2px;
  border: solid 1px #9dabbf;
  background-color: ${Palette.lightGreyBlue};
  color: white;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  padding-top: 7px;
  font-weight: bold;
  ${props => props.disabled ? "cursor: not-allowed;" : ""};
  ${props => props.disabled ? "pointer-events: all !important;" : ""};
`;

const ImageContainer = styled.img`
  width: 207px;
  max-width: 100%;
  max-height: 207px;
  margin-right: 20px;
`;

const ImageAction = styled.div`
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  display: inline-block
`;

const RemoveButton = styled(Button)`
  width: 100px;
  height: 30px !important;
  border-radius: 2px;
  border: solid 1px #d13461;
  background-color: ${Palette.darkPink} !important;
  color: white !important;
`;

const HiddenInput = styled.input`
  display: none !important;
  visility: hidden !important;
  ${props => props.disabled ? "cursor: not-allowed;" : ""};
  ${props => props.disabled ? "pointer-events: all !important;" : ""};
`;

const ImageName = styled.p`
  overflow-wrap: break-word;
  word-break: break-all;
`;

const Description = styled.p`
  margin-top: 8px;
  justify-content: flex-end;
  width: 90%;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${Palette.bluishGrey};
`;

const UploadImageComponent = styled.div`
  box-sizing: border-box;
  margin-top: 24px !important;
`;

export default ImageUpload;
