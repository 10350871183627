import { default as networkActionCreators } from "@store/network/actions";
import { createRequest } from "@src/utils/networks";
import { PulsePollResource } from "hyphen-lib/dist/domain/resource/PulsePollResource";
import { ParticipantResource } from "hyphen-lib/dist/domain/resource/participants/ParticipantResource";
import { ParticipantCountResource } from "hyphen-lib/dist/domain/resource/participants/ParticipantCountResource";

export interface FetchPulsePollParticipantParameters {
  readonly templateId: string;
  readonly audience: PulsePollResource["audience"];
}

export function fetchPulsePollResourceIfNeeded(templateId: string) {
  return networkActionCreators.fetchElementIfNeeded({
    id: PulsePollResource.generateKey(templateId),
    type: PulsePollResource.TYPE,
    request: createRequest(`/pulsePolls/${templateId}`),
  });
}

export function fetchPulsePollParticipantsIfNeeded(
  {templateId, audience}: FetchPulsePollParticipantParameters
) {
  return networkActionCreators.fetchElementIfNeeded({
    id: ParticipantResource.generateKey(templateId, audience),
    type: ParticipantResource.TYPE,
    request: createRequest(`/pulsePolls/${templateId}/participants`),
  });
}

export function fetchPulsePollParticipantCountIfNeeded(
  {templateId, audience}: FetchPulsePollParticipantParameters
) {
  return networkActionCreators.fetchElementIfNeeded({
    id: ParticipantCountResource.generateKey(templateId, audience),
    type: ParticipantCountResource.TYPE,
    request: createRequest(`/pulsePolls/${templateId}/participantsCount`),
  });
}
