/* tslint:disable:max-classes-per-file */
import React from "react";
import hoistNonReactStatics from "hoist-non-react-statics";
import { isEmpty } from "@hyphen-lib/lang/Arrays";
import { IllegalStateException } from "@hyphen-lib/lang/exception/IllegalStateException";
import { TableProps } from "antd/lib/table";
import { RowProps } from "antd/lib/row";
import { createRef } from "react";
import { isNotNullNorUndefined, mapOr, getOr } from "hyphen-lib/dist/lang/Objects";
import styled from "styled-components";

export interface DynamicRowProps<T> {
  readonly row: T;
  readonly index?: number | null;
}

interface CustomRowProps {
  hoverIndex: number | null;
  rowIndex: number | null;
}

interface RowState {
  readonly height: number;
}

/**
 * Customize the specified table to make it display the `HoverRow` component when
 * the pointer is over a row.
 *
 * @param {Table<T>} WrappedTable
 * @param {React.ComponentType<DynamicRowProps<T>>} HoverRow
 */
export function withDynamicRows<T, P extends TableProps<T>>(WrappedTable: React.ComponentType<P>,
  HoverRow: React.ComponentType<DynamicRowProps<T>>):
  React.ComponentClass<P> {

  class RowSwitcher extends React.Component<RowProps & CustomRowProps, RowState> {
    state = {
      height: mapOr(this.props.style, d => getOr(d.height, 90), 90) as number,
    };
    private rowRRef = createRef<HTMLTableRowElement>();
    componentDidMount() {
      const { current: rowElem } = this.rowRRef;
      if (isNotNullNorUndefined(rowElem) && rowElem.clientHeight !== 0) {
        this.setState({ height: rowElem.clientHeight });
      }
    }

    // noinspection JSMethodCanBeStatic
    extractRowRecord(children: any): T {
      if (isEmpty(children)) {
        throw new IllegalStateException("the table does not seems to have any cells :/");
      }

      return children[0].props.record;
    }

    render() {
      const {
        children,
        hoverIndex,
        rowIndex,
        ...rest
      } = this.props;
      const { height } = this.state;
      return (
        <StyledRow
          height={height}
          ref={this.rowRRef}
          {...rest}
        >
          {
            hoverIndex === rowIndex ?
              <HoverRow row={this.extractRowRecord(children)} index={rowIndex} /> :
              children
          }
        </StyledRow>
      );
    }
  }

  class DynamicRowsTable extends React.Component<P> {
    state = {
      hoverIndex: null,
    };
    onRow = (record: any, rowIndex: number) => {
      const { hoverIndex } = this.state;
      return {
        onMouseOver: () => {
          this.setState({ hoverIndex: rowIndex });
        },
        onMouseLeave: () => {
          this.setState({ hoverIndex: null });
        },
        hoverIndex,
        rowIndex,
      };
    };
    render() {
      return (
        <WrappedTable
          {...this.props}
          components={{
            body: {
              row: RowSwitcher,
            },
          }}
          onRow={this.onRow}
        />
      );
    }
  }

  return hoistNonReactStatics(DynamicRowsTable, WrappedTable);
}

const StyledRow = styled.tr<{ height: number }>`
  ${props => `
    height: ${props.height}px !important;
  `}
`;
