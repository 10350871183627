import { Optional } from "@hyphen-lib/lang/Optionals";
import { DistributionResult } from "hyphen-lib/dist/domain/resource/report/common/DistributionResult";
import { getOr } from "hyphen-lib/dist/lang/Objects";
import { trans } from "./i18next";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import { Dictionary } from "@hyphen-lib/domain/structure/Dictionary";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";


export const generateDistributionText = (choiceLabels: Optional<string>[] = [], 
  distribution: DistributionResult = [], numberOfVotes: number) => {

  const midpoint = Math.floor(distribution.length / 2);

  const relevantDistributions: any[] = extractNonZeroPercentageDistributions(
    choiceLabels, 
    distribution.slice(0, midpoint)
  );

  if(!isNotEmptyArray(relevantDistributions)) {
    return generateDistributionTextForNonePercentage(choiceLabels.slice(0, midpoint), numberOfVotes);
  }
  return generateDistributionTextForNonZeroPercentage(relevantDistributions);
};

const extractNonZeroPercentageDistributions = (choiceLabels: Optional<string>[], distributions: any[]) => {
  const relevantDistributions: any[] = [];
  distributions.forEach((dist: any, index: number) => {
      if (getOr(dist.percentage, 0) > 0) {
          dist.label = choiceLabels[index];
          relevantDistributions.push(dist);
      }
  });
  return relevantDistributions;
};

const generateDistributionTextForNonZeroPercentage = (relevantDistributions: Optional<string>[]) => {
  let distributionsText = "";
  let noteDistributionsText = "";
  relevantDistributions.forEach((item: any, index: number)=> {
    const percentage = getOr(item.percentage, 0);
    const choiceLabel = item.label;
    if(!isStringAndNotEmpty(distributionsText)) {
      distributionsText = distributionsText + ` ${percentage}% 
        ${trans("of your employees have voted")} "${choiceLabel}"`;
      noteDistributionsText = noteDistributionsText + ` "${choiceLabel}"`; 
    } else if(relevantDistributions.length - 1 === index) {
      distributionsText += ` ${trans("and")} ${percentage}% ${trans("have voted")} "${choiceLabel}"`;
      noteDistributionsText += ` ${trans("and")} "${choiceLabel}"`;
    } else {
      distributionsText += `, ${percentage}% ${trans("have voted")} "${choiceLabel}"`;
      noteDistributionsText +=  `, "${choiceLabel}"`;
    } 
  });
  return {
    distributionsText,
    noteDistributionsText
  };
};

const generateDistributionTextForNonePercentage = (choiceLabels: Optional<string>[],
   numberOfVotes: number) => {
  let distributionsText = "";
  let noteDistributionsText = "";
  choiceLabels.forEach((choiceLabel, index: number) => {
    if (!isStringAndNotEmpty(distributionsText)) {
      distributionsText = `${ numberOfVotes > 1 ? 
        trans("no employees have voted") : trans("no employee has voted")} "${choiceLabel}"`;
      noteDistributionsText = `"${choiceLabel}"`;
    } else if (index === choiceLabels.length - 1) {
      distributionsText += ` ${trans("or")} "${choiceLabel}"`;
      noteDistributionsText += ` ${trans("and")} "${choiceLabel}"`;
    } else {
      distributionsText += `, "${choiceLabel}"`;
      noteDistributionsText +=  `, "${choiceLabel}"`;
    }
  });
  return {
    distributionsText,
    noteDistributionsText
  };
};

export const calculateNpsDistribution = (distribution: DistributionResult) => {
  const enpsCategories: Dictionary<number> = {
    promoters: 0,
    detractors: 0,
    passives: 0,
  };

  distribution.forEach((category: any, index: number) => {
    if (index >= 0 && index <= 6) {
      enpsCategories.detractors += category.percentage;
    } else if (index >= 9 && index <= 10) {
      enpsCategories.promoters += category.percentage;
    } else {
      enpsCategories.passives += category.percentage;
    }
  });

  return enpsCategories;
};