import React from "react";
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";
import { connect, MapStateToProps } from "react-redux";
import { State } from "@src/store/types";


import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";
import AddUsersAndDimensions from "@screens/Insights/AddUsersAndDimensions";
import { CreateActionPlan } from "@screens/Insights/ActionPlans";
import Spin from "@components/core/Spin";
import styled from "styled-components";
import { isNotNullNorUndefined, isNullOrUndefined } from "hyphen-lib/dist/lang/Objects";
import { loadSessionToken, saveSessionToken } from "@src/utils/sessionStores";
import { RightsMatcher } from "@hyphen-lib/business/auth/Auth";
import { getRightsForRoute } from "@screens/Insights/MainNavigation/DefaultRoutesConfiguration";
import { not } from "@hyphen-lib/lang/Booleans";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { isPulsePollEnabled, isVoiceEnabled } from "hyphen-lib/dist/business/company/Companies";
import { fetchCurrentUserIfNeeded } from "@store/network/resource/CurrentUserResources";
import { getResourceById } from "@store/network/selectors";
import { CURRENT } from "@src/utils/networks";
import { PulsePolls } from "./PulsePolls";
import ManageSuggestions from "./Settings/containers/ManageSuggestions";
import Login from "./Login/index";
import { MainNavigationContainer } from "./MainNavigation";
import { InsightsStateProps } from "./store/types";
import Surveys from "./Surveys";
import * as selectors from "./store/selectors";
import { FetchError } from "./errors/FetchError";
import { Error403 } from "./errors/Error403";

export type OwnProps = RouteComponentProps;

export interface StateProps {
  sessionToken: InsightsStateProps["sessionToken"];
  currentUser: Optional<CurrentUserResource>;
  currentUserElement: Store.Element<CurrentUserResource>;
  fetchingUser: boolean;
  readonly rightsMatcher: RightsMatcher;
  company: Optional<CompanyResource>;
}

interface InsightsActionProps {
  readonly onFetchCurrentUser: () => void;
}

export type Props =
  OwnProps &
  StateProps &
  InsightsActionProps;

class Insights extends React.Component<Props> {

  componentDidUpdate() {
    const { sessionToken, currentUserElement, currentUser, onFetchCurrentUser } = this.props;
    if (sessionToken) {
      const localSessionToken = loadSessionToken();
      if (isNullOrUndefined(localSessionToken)) {
        saveSessionToken(sessionToken);
      }
    }

    if (
      sessionToken &&
      not(Store.Element.isInError(currentUserElement)) &&
      !currentUser
    ) {
      onFetchCurrentUser();
    }
  }

  componentDidMount() {
    const localSessionToken = loadSessionToken();
    if (isNotNullNorUndefined(localSessionToken)) {
      this.props.onFetchCurrentUser();
    }
  }

  render() {
    const {
      currentUser,
      currentUserElement,
      fetchingUser,
      location,
      rightsMatcher,
      company ,
    } = this.props;

    if (Store.Element.isInError(currentUserElement)) {
      return <FetchError { ...currentUserElement } resourceType={CurrentUserResource.TYPE}/>;
    }
    if (fetchingUser) {
      return <SpinContainer>
        <Spin size="large" />
      </SpinContainer>;
    }

    if (currentUser) {
      const pathname = location.pathname;

      const rights = getRightsForRoute(pathname);
      if (isNullOrUndefined(rights)) {
        return <Redirect to={"/errors/404"} />;
      }
      const isAllowed = rightsMatcher.hasEveryOf(...rights);
      if (not(isAllowed)) {
        return <Redirect to={"/errors/403"} />;
      }

      if (pathname.includes("pulsePolls")) {
        let pulsePollsAccess = false;
        if (isNotNullNorUndefined(company)) {
          pulsePollsAccess = isPulsePollEnabled(company);
          if (!pulsePollsAccess) {
            return <Redirect to={"/errors/403"} />;
          }
        }
      }

      if (pathname.includes("voice")) {
        if (isNotNullNorUndefined(company)) {
          if (!isVoiceEnabled(company)) {
            return <Redirect to={"/errors/403"} />;
          }
        }
      }

      return (
        <Switch>
          {/* full page components...*/}
          <Route path="/surveys/create" component={Surveys} />
          <Route path="/surveys/edit/*" component={Surveys} />
          <Route path="/pulsePolls/edit" component={PulsePolls} />
          <Route path="/pulsePolls/edit/*" component={PulsePolls} />
          <Route path="/users/add" component={AddUsersAndDimensions} />
          <Route path="/users/edit/dimensions" component={AddUsersAndDimensions} />
          <Route
            path="/actioncenter/actions/create"
            exact
            component={CreateActionPlan}
          />
          <Route path="/settings/manageSuggestions" exact component={ManageSuggestions} />

          {/* main layout with sub-pages...*/}
          <Route path="/login" component={Login} />
          <Route path="/*" component={MainNavigationContainer} />
        </Switch>
      );
    }

    return (
      <Switch>
        <Route path="/errors/403" component={Error403} />
        <Route path="/login/:userName/:passCode" component={Login} />
        <Route path="/" component={Login} />
      </Switch>
    );
  }
}

const SpinContainer = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State): StateProps => {
  const currentUserElement = getResourceById(state, CurrentUserResource.TYPE, CURRENT);
  return ({
    currentUserElement,
    currentUser: Store.Element.toOptional(currentUserElement),
    fetchingUser: Store.Element.isLoading(currentUserElement),
    sessionToken: selectors.getSessionToken(state),
    rightsMatcher: selectors.getRightsMatcher(state),
    company: selectors.getCompany(state),
  });
};

const mapDispatchToProps = {
  onFetchCurrentUser: fetchCurrentUserIfNeeded,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Insights));
