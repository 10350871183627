import styled from "styled-components";
import { Icon } from "antd";
import { ColumnProps, TableProps } from "antd/lib/table";

import { DynamicRowProps, withDynamicRows } from "@components/core/DynamicRowsTable/withDynamicRows";
import CreateActionPlanButton from "@src/screens/Insights/components/Reports/CreateActionPlanButton";
import Palette from "@src/config/theme/palette";
import Table from "@components/core/Table";
import { formatSort } from "@src/utils/helper";
import isDeepEqual from "react-fast-compare";
import { QuestionResult } from "hyphen-lib/dist/domain/resource/report/common/QuestionResult";
import { RouteComponentProps, withRouter } from "react-router";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { CompareWithOption } from "@screens/Insights/components/ViewOptions/components/CompareWith";
import { getOr, getProperty, isEmptyObject, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { SortParameter } from "@src/utils/networks";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import Tooltip from "@src/components/core/Tooltip";
import { Breadcrumb, goTo } from "@src/utils/locations";
import { getMatchingOptionLabel } from "@src/utils/Comparisons";
import { QuestionType } from "@hyphen-lib/domain/common/QuestionType";
import { CellContainer, CellInnerContainer } from "@screens/Insights/SurveyList/components/cells/Cells";
import { InlineAnonymityFiltered } from "@components/core/InlineAnonymityFiltered";
import { CompareCell } from "./cells/CompareCell";
import { FavorabilitySentimentCell } from "./cells/FavorabilitySentimentCell";
import { QuestionCell } from "./cells/QuestionCell";
import { DriverImpactCell } from "./cells/DriverImpactCell";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { FocusArea, Module } from "hyphen-lib/dist/domain/FocusArea";
import { Trans } from "react-i18next";
import { fetchTopicsOverviewIfNeeded } from "@src/store/network/resource/TopicsReportResources";
import { connect, MapStateToProps } from "react-redux";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { SurveyTopicCommentResource } from "hyphen-lib/dist/domain/resource/survey/report/SurveyTopicCommentResource";
import { State } from "@store/types";
import { getResourceById } from "@src/store/network/selectors";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import WordCloudCell from "@src/screens/Insights/components/Reports/ResultsTable/cells/WordCloudCell";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";

const categoriesToLoadTopics = [QuestionType.OPEN_TEXT];
interface TopicsQuestionStateProps {
  readonly topicsForQuestions : Record<string,Loadable<SurveyTopicCommentResource>>;
}
interface TopicsQuestionActionProps {
  readonly onFetchTopicsOverview: (
    surveyId: string,
    queryString: Dictionary<any>
  ) => void;
}

const headerStyle = `
  font-size: 15px;
  color: ${Palette.darkBlueGrey};
`;

const Header = styled.div`
  ${headerStyle}
  white-space: nowrap;
  text-align:center;
`;

const HeaderWithIcon = styled.div`
  white-space: pre;
  ${headerStyle}
`;

const ComparisonOption = styled.div`
  color: ${Palette.bluishGrey};
  font-size: 12px;
  font-weight: normal;
  margin-top: 4px;
`;

const StyledInfoIcon = styled(Icon)`
  font-size: 16px;
  color: ${Palette.lightGreyBlue};
  margin-left: 8px;
`;

function getSortOrder(field: string, sort?: Optional<SortParameter>) {
  if (isEmptyObject(sort)) {
    return undefined;
  }
  if (sort![field] === 1) {
    return "ascend";
  } else if (sort![field] === -1) {
    return "descend";
  }
  return undefined;
}

const wordCloudData: Record<string, any> = {};

const getColumns = (
  areDriverImpactsVisible: boolean,
  areComparisonsVisible: boolean,
  compareWithOptions: CompareWithOption[],
  comparisonKey: Optional<string>,
  tableName: string,
  sort: Dictionary<any>,
  questions: QuestionResult[],
  anonymityThreshold: number,
  surveyId: string,
): ColumnProps<QuestionResult>[] => {
  const columns: ColumnProps<QuestionResult>[] = [];
  const sortParam = formatSort(sort);
  if (  
    areDriverImpactsVisible &&
    questions.some(question => Object.keys(question).includes("driverImpact"))
  ) {
    columns.push({
      title: (
        <HeaderWithIcon>
          <Trans>Driver Impact</Trans>
          <Tooltip title={<Trans>Level of impact questions have on overall Favorability</Trans>}>
            <StyledInfoIcon className="block--print-hide" type="info-circle" />
          </Tooltip>
        </HeaderWithIcon>
      ),
      sorter: true,
      dataIndex: "driverImpact",
      sortOrder: getSortOrder("driverImpact", sortParam),
      sortDirections: ["ascend", "descend"],
      key: "driverImpact",
      render: (__, record) => {
        if (record.filteredForAnonymity) {
          return null;
        }
        return (
          <DriverImpactCell row={record} />
        );
      },
    });
  }

  columns.push({
    title: (<Trans>Question</Trans>),
    dataIndex: "question",
    key: "question",
    sortOrder: getSortOrder("question", sortParam),
    sortDirections: ["ascend", "descend"],
    sorter: true,
    render: (__, record) => {
      return (
        <QuestionCell row={record} />
      );
    },
  },
    {
      title: (
        <HeaderWithIcon>
          <Trans>Favorability</Trans> / <Trans>Responses</Trans>
          <Tooltip
            title={<Trans>Favorability is the percentage of the favorable votes out of all votes. 
              Sentiment is based on percentage of positive comments minus percentage of negative comments.</Trans>}
          >
            <StyledInfoIcon
              className="block--print-hide"
              type="info-circle"
            />
          </Tooltip>
        </HeaderWithIcon>
      ),
      sorter: true,
      sortOrder: getSortOrder("favorability", sortParam),
      sortDirections: ["ascend", "descend"],
      dataIndex: "favorability",
      key: "favorability",
      width: 400,
      render: (__, record) => {
        if (record.filteredForAnonymity) {
          return (
            <InlineAnonymityFiltered
              explanation={record.explanation}
              anonymityThreshold={anonymityThreshold}
              size={"normal"}
            />
          );
        }
        else if(categoriesToLoadTopics.includes(record.type) && 
          wordCloudData[record._id] && wordCloudData[record._id].__html) {
          return <div dangerouslySetInnerHTML={{__html: wordCloudData[record._id].__html}}/>;
        }
        return (
          <FavorabilitySentimentCell row={record} tableName={tableName} />
        );
      },
    }
  );

  if (areComparisonsVisible) {
    const label = getMatchingOptionLabel(compareWithOptions, comparisonKey);
    if (isNotNullNorUndefined(label)) {
      columns.push({
        title: (
          <Header>
            <Trans>Compare</Trans>
            <ComparisonOption><Trans>vs {label}</Trans></ComparisonOption>
          </Header>
        ),
        sorter: true,
        // @ts-ignore
        sortOrder: getSortOrder("compare", formatSort(sort)),
        sortDirections: ["ascend", "descend"],
        dataIndex: "compare",
        key: "compare",
        width: 125,
        render: (__, record) => {
          return (
            // fixme: any because otherwise it says that some question type does not have a compare field :/
            <CompareCell
              row={record as any}
              comparisonKey={comparisonKey}
              percentage={record.type === QuestionType.ENPS ? false : true}
              textAlign={"center"} />
          );
        },
      });
    }
  } else {
    // Push empty spacer column
    columns.push({
      sorter: false,
      width: 125,
      render: () => {
        return (
          // Rendering empty placeholder
          <CellContainer align="flex-start" justifyContent="flex-start">
            <CellInnerContainer padding={[24, 0]} width="100%" />
          </CellContainer>
        );
      },
    });
  }

  return columns;
};

export interface QuestionsTableProps extends TableProps<QuestionResult>, RouteComponentProps {
  tableName: string;
  questions: QuestionResult[];
  areDriverImpactsVisible?: boolean;
  areComparisonsVisible?: boolean;
  compareWithOptions: CompareWithOption[];
  comparisonKey: Optional<string>;
  sort: SortParameter;
  readonly hasActionCreationRight: boolean;
  readonly hasTopicsAccess: boolean;
  readonly anonymityThreshold: number;
  readonly surveyId: string;
  readonly onCreateFocusArea: (
    focusArea: Partial<FocusAreaResource>
  ) => void;
  readonly focusAreaLabel: string;
  readonly page: PageFilter;
  readonly postAndSurveySentiment?: boolean;
}

function QuestionsTableComponent(
  {
    questions,
    areDriverImpactsVisible = true,
    areComparisonsVisible = true,
    compareWithOptions,
    comparisonKey,
    tableName,
    sort,
    pagination = false,
    anonymityThreshold,
    surveyId,
    topicsForQuestions,
    page,
    onChange,
    hasTopicsAccess,
    ...rest
  }: QuestionsTableProps & TopicsQuestionStateProps
) {
  if(hasTopicsAccess) {
    questions.forEach(question => {
      if(wordCloudData[question._id]){
        if(Loadable.isLoaded(topicsForQuestions[question._id])) {
          const topics = getProperty(topicsForQuestions[question._id], "value.topics", []);
          if(isNotEmptyArray(topics) && !isDeepEqual(topics, getProperty(wordCloudData[question._id], "topics", []))) {
            wordCloudData[question._id] = {
              __html: ReactDOMServer.renderToString(
                <WordCloudCell 
                topics={topics}
                width={500}/>
              ),
              topics
            };
          }
        } else if(Loadable.isInError(topicsForQuestions[question._id])) {
          wordCloudData[question._id] = {
            __html: ""
          };
        }
      }
    });
  }
  const total = questions.length;
  return (
    <StyledTable
      columns={getColumns(
        areDriverImpactsVisible,
        areComparisonsVisible,
        compareWithOptions,
        comparisonKey,
        tableName,
        sort,
        questions,
        anonymityThreshold,
        surveyId
      )}
      dataSource={questions}
      rowKey={"_id"}
      onChange={onChange}
      pagination={{
        total,
        pageSize: page.size,
        current: page.number,
        showTotal: (total: number, range: number[]) =>
          <Trans i18nKey="resultsCount"
          values={{start: range[0], end: range[1], total}} 
          defaults={`Showing ${range[0]} to ${range[1]} of ${total} results`}/>,
      }}
      {...rest}
    />
  );
}

const StyledTable = styled(Table)`
  .ant-table-body > table tr > td {
    vertical-align: middle;
  }
  @media print {
    table {
      page-break-after:always;
    }
  }
`;

const StyledHoverCell = styled.td`
  background-color: ${Palette.lightLightBlue};
  position: relative;
`;

interface MatchParams {
  id: string;
}

function QuestionsTable(props: QuestionsTableProps & TopicsQuestionStateProps & TopicsQuestionActionProps) {

  function HoverRow({ row, ...rowProps }: DynamicRowProps<QuestionResult> & RouteComponentProps<MatchParams>) {
    function onCreateActionPlan() {
      // encodeURIComponent(row.category),
      props.onCreateFocusArea({
        title: row.question,
        source: FocusArea.Source.QUESTION,
        module: Module.SURVEY,
        focusAreaType: FocusArea.FocusAreaType.QUESTION,
        sourceUrl: {
          label: props.focusAreaLabel,
          url: window.location.href,
        },
        actionPlans: [],
        surveyId: rowProps.match.params.id,
        category: row.category,
        questionId: row._id
      });
    }

    function onShowDetail() {
      let url = `questions/${row._id}`;
      if (row.type === QuestionType.OPEN_TEXT) {
        url += "/comments";
      }

      goTo(url, Breadcrumb.stack(props.tableName));
    }

    function checkDriverImpact() {
      return props.questions.some(question => Object.keys(question).includes("driverImpact"));
    }

    if (row.filteredForAnonymity) {
      return (
        <>
          {props.areDriverImpactsVisible && checkDriverImpact() && (
            <StyledHoverCell />
          )}
          <StyledHoverCell>
            <QuestionCell row={row} hover />
          </StyledHoverCell>
          <StyledHoverCell>
            <InlineAnonymityFiltered
              explanation={row.explanation}
              anonymityThreshold={props.anonymityThreshold}
              size={"normal"}
            />
          </StyledHoverCell>
          <StyledHoverCell />
        </>
      );
    }

    return (
      <>
        {props.areDriverImpactsVisible && checkDriverImpact() && (
          <StyledHoverCell>
            {
              props.hasActionCreationRight && (
                <CreateActionPlanButton onClick={onCreateActionPlan} />
              )
            }
            <DriverImpactCell row={row} />
          </StyledHoverCell>
        )}
        <StyledHoverCell>
          {(
            (!props.areDriverImpactsVisible && checkDriverImpact())
            || (props.areDriverImpactsVisible && !checkDriverImpact())
            || (!props.areDriverImpactsVisible && !checkDriverImpact())
          )
            && props.hasActionCreationRight
            && <CreateActionPlanButton onClick={onCreateActionPlan} />}
          <QuestionCell row={row} hover />
        </StyledHoverCell>
        <StyledHoverCell>
          { 
            wordCloudData[row._id] && wordCloudData[row._id].__html ? 
              <div dangerouslySetInnerHTML={{__html: wordCloudData[row._id].__html}}/>
            :
              <FavorabilitySentimentCell row={row} tableName={props.tableName} />
          }
        </StyledHoverCell>
        <StyledHoverCell>
          <StyledLink onClick={onShowDetail}>
            <SeeDetails data-cy="see_details_question_drilldown">
              <Trans>See details</Trans> <RightIcon type="right" /></SeeDetails>
          </StyledLink>
        </StyledHoverCell>
      </>
    );
  }

  const { surveyId, questions, onFetchTopicsOverview, location, hasTopicsAccess, postAndSurveySentiment } = props;
  useEffect(() => {
    if(hasTopicsAccess && getOr(postAndSurveySentiment, true)) {
      questions.forEach(question => {
        if(categoriesToLoadTopics.includes(question.type)) {
          const existing = parseQueryString(location.search);
          const filter = getProperty(existing, "filter", {});
          filter.questionId = [question._id];
          if(isNotNullNorUndefined(filter.freeText)) {
            delete filter.freeText;
          }
          onFetchTopicsOverview(surveyId, { filter });
          wordCloudData[question._id] = { 
            __html:  ReactDOMServer.renderToString(
                <NoDataContent>AI is still processing the comments to generate Topics for you..</NoDataContent>
              )
          };
        }
      });
    }
  }, []);

  const TailoredTable = withDynamicRows(QuestionsTableComponent, withRouter(HoverRow));

  return <TailoredTable {...props} />;
}

const mapDispatchToProps = {
  onFetchTopicsOverview: fetchTopicsOverviewIfNeeded
};

const mapStateToProps: MapStateToProps<TopicsQuestionStateProps,
  QuestionsTableProps,
  State
> = (state: State, ownProps: QuestionsTableProps): TopicsQuestionStateProps => {
  const overview: Record<string,Loadable<SurveyTopicCommentResource>> = {};
  if(ownProps.hasTopicsAccess) {
    ownProps.questions.forEach(question => {
      if(categoriesToLoadTopics.includes(question.type)) {
        const existing = parseQueryString(ownProps.location.search);
          const filter = getProperty(existing, "filter", {});
          filter.questionId = [question._id];
          if(isNotNullNorUndefined(filter.freeText)) {
            delete filter.freeText;
          }
        overview[question._id] = Store.Element.toLoadable(getResourceById(
          state,
          SurveyTopicCommentResource.TYPE,
          SurveyTopicCommentResource.generateKey(
            ownProps.surveyId,
            { ...filter},
          )
        ));
      }
    });
  }
  return {
    topicsForQuestions: overview
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QuestionsTable));

const StyledLink = styled.span`
  cursor: pointer;
`;

const SeeDetails = styled.div`
  color: ${Palette.darkModerateBlue};
  font-size: 14px;
  text-align: right;
  cursor: pointer;
  line-height: 30px;
`;

const RightIcon = styled(Icon)`
  font-size: 12px;
`;

const NoDataContent = styled.div`
  font-family: Lato;
  font-size: 14px;
  text-align: center;
  color: ${Palette.darkBlueGrey};
`;