import React from "react";
import styled from "styled-components";
import { getOr } from "hyphen-lib/dist/lang/Objects";
import { SentimentTag } from "@components/core/SentimentTag";
import Palette from "@src/config/theme/palette";
import { IndividualResultResource } from "@hyphen-lib/domain/resource/survey/report/IndividualResultResource";
import { Trans } from "react-i18next";

interface CommentProps {
  comment: IndividualResultResource.Comment;
  readonly postAndSurveySentiment?: boolean;
}

export class Comment extends React.Component<CommentProps> {
  state = {
    isExtended: false,
  };

  showMore = () => {
    this.setState({ isExtended: true });
  };

  render () {
    const { comment: { sentiment, text }, postAndSurveySentiment } = this.props;
    const textLengthLimit = 80;
    const isTextLong = text.length > textLengthLimit;
    const truncatedText = isTextLong ? text.substring(0, textLengthLimit) : text;

    return (
      <CommentContainer>
        { getOr(postAndSurveySentiment, true) && <SentimentTagContainer>
          <SentimentTag sentiment={sentiment}/>
        </SentimentTagContainer>}
        <CommentLabel>
          {this.state.isExtended || !isTextLong ?
            text :
            (
              <>
                {truncatedText}<span>... <ShowMore onClick={this.showMore}>
                  <Trans>show more</Trans></ShowMore></span>
              </>
            )
          }
        </CommentLabel>
      </CommentContainer>
    );
  }
}

const CommentContainer = styled.div`
  margin-top: 22px;
`;

const SentimentTagContainer = styled.div`
  width: 88px;
  display: inline-block;
  margin-right: 16px;
`;

export const CommentLabel = styled.span`
  font-family: Lato;
  font-size: 14px;
  color: ${Palette.bluishGrey};
`;

const ShowMore = styled.span`
  color: ${Palette.bluePurple};
  cursor: pointer;
`;