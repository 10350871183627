import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";
import { Heading, Paragraph } from "../Typography/index";
import { TransWrapper } from "@src/components/core/withTrans";

interface Props {
  children?: React.ReactNode;
  className?: string;
  title?: string | React.ReactNode;
  description?: string;
}

export default function ContainerCard (props: Props) {
  const { children, title, description, ...rest } = props;
  return (
    <Container {...rest}>
      <div>
        <Heading><TransWrapper>{title}</TransWrapper></Heading>
        {(description !== null || description !== undefined) && 
        <Paragraph weight="light"><Trans>{description}</Trans></Paragraph>}
      </div>
      {children}
    </Container>
  );
}

const Container = styled.div`
  border-radius: 3px;
  box-shadow: 0 4px 8px 0 rgba(198, 214, 223, 0.15);
  background-color: #ffffff;
  padding: 32px;
`;
