import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import styled from "styled-components";

import { ComputedHeatMap } from "@hyphen-lib/domain/aggregate/calculation/ComputedHeatMap";
import { Dictionary } from "@hyphen-lib/domain/structure/Dictionary";
import { areEquals, getOr, isNotNullNorUndefined, mapAtIndexOr } from "@hyphen-lib/lang/Objects";

import ViewOptions, { getViewOptionValuesFromLocation } from "@screens/Insights/components/ViewOptions";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { CompareWithOption } from "@screens/Insights/components/ViewOptions/components/CompareWith";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { AnonymityFilterExplanation } from "hyphen-lib/dist/domain/common/AnonymityFilterExplanation";
import { getViewOptionDefinitions } from "@src/utils/ViewOptions";
import { areArraysEqual, isEmpty } from "hyphen-lib/dist/lang/Arrays";
import { Participation } from "hyphen-lib/dist/domain/common/Participation";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { ParticipationReportResource } from "hyphen-lib/dist/domain/resource/survey/report/ParticipationReportResource";
import SurveyReportHeader from "../SurveyReportHeader";
import FavorabilityHeatmap from "./components/FavorabilityHeatmap";

interface OwnProps {
  readonly surveyId: string;
  readonly surveyName: string;
  readonly participation: Participation;
  readonly enabledFilters: string[];
  readonly enabledCustomFilters?: string[];
  readonly selectComponent: React.ReactNode;
  readonly heatMap: Loadable<ComputedHeatMap>;
  readonly enabledViewOptions: string[];
  readonly compareWithOptions: CompareWithOption[];
  readonly filteredForAnonymity: boolean;
  readonly filteredForAnonymityReason?: Optional<AnonymityFilterExplanation>;
  readonly anonymityThreshold: number;
  readonly participationResource: ParticipationReportResource;
  readonly selectedDimension: string;
  readonly likertLabels?: Optional<string[]>;
}

type Props = OwnProps & RouteComponentProps;

interface HeatmapReportState {
  readonly viewOptions: {
    readonly comparison: boolean;
    readonly compareWith: Optional<string>;
    readonly scoreOrDelta: "score" | "delta";
  };
}

export class HeatmapReport extends React.Component<Props, HeatmapReportState> {
  constructor(props: Props) {
    super(props);

    const viewOptions = getViewOptionValuesFromLocation(props.location);

    this.state = this.mapViewOptionsToState(
      viewOptions,
      props.compareWithOptions,
      this.getDefaultViewOptionValues()
    );
  }

  componentDidUpdate(prevProps: Props): void {
    const prevViewOptions = parseQueryString(prevProps.location.search).viewOptions;
    const currentViewOptions = parseQueryString(this.props.location.search).viewOptions;
    if (not(areEquals(prevViewOptions, currentViewOptions)) ||
        not(areArraysEqual(prevProps.compareWithOptions, this.props.compareWithOptions)) ||
        not(areArraysEqual(prevProps.enabledViewOptions, this.props.enabledViewOptions))) {
      this.setState(
        this.mapViewOptionsToState(
          getViewOptionValuesFromLocation(this.props.location),
          this.props.compareWithOptions,
          this.getDefaultViewOptionValues()
        )
      );
    }
  }

  getDefaultViewOptionValues = (): HeatmapReportState["viewOptions"] => {
    return {
      comparison: true,
      scoreOrDelta: "score" as const,
    } as HeatmapReportState["viewOptions"];
  };

  mapViewOptionsToState = (viewOptions: Dictionary<any>,
    compareWithOptions: CompareWithOption[],
    defaultValues: HeatmapReportState["viewOptions"]) => {

    const compareWithMode: Optional<string> = getOr(
      viewOptions.compareWith,
      mapAtIndexOr(compareWithOptions, 0, o => o.key, undefined)
    );
    const scoreOrDelta: "score" | "delta" = getOr(
      viewOptions.scoreOrDelta,
      defaultValues.scoreOrDelta
    );

    return {
      viewOptions: {
        comparison:
          isNotNullNorUndefined(compareWithMode) &&
          getOr(viewOptions.comparison, defaultValues.comparison),
        compareWith: compareWithMode,
        scoreOrDelta,
      },
    };
  };

  handleViewOptionsChange = (viewOptions: Dictionary<any>) => {
    this.setState(
      this.mapViewOptionsToState(
        viewOptions,
        this.props.compareWithOptions,
        this.getDefaultViewOptionValues()
      )
    );
  };

  getViewOptionsComponent = (enabledViewOptions: string[],
    compareWithOptions: CompareWithOption[]): React.ReactNode => {

    const viewOptions = getViewOptionDefinitions(enabledViewOptions, compareWithOptions);
    if (isEmpty(viewOptions)) {
      return null;
    }

    return (
      <ViewOptions
        viewOptions={viewOptions}
        defaultValues={this.state.viewOptions}
        onChange={this.handleViewOptionsChange}
      />
    );
  };

  render() {
    const {
      surveyId,
      surveyName,
      participation,
      heatMap,
      enabledFilters,
      enabledCustomFilters,
      enabledViewOptions,
      compareWithOptions,
      filteredForAnonymity,
      filteredForAnonymityReason,
      anonymityThreshold,
      participationResource,
      likertLabels,
    } = this.props;

    const {
      viewOptions,
    } = this.state;

    return (
      <div>
        <SurveyReportHeader
          participation={participation}
          enabledFilters={enabledFilters}
          enabledCustomFilters={enabledCustomFilters}
          viewOptionsComponent={this.getViewOptionsComponent(enabledViewOptions, compareWithOptions)}
          exportOption="heatmap"
        />
        <HeatmapContainer>
          {Loadable.isNotLoaded(heatMap) ?
            <LoadingContainer>Loading...</LoadingContainer> :
            <FavorabilityHeatmap
              surveyId={surveyId}
              surveyName={surveyName}
              heatMap={heatMap.value}
              participationResource={participationResource}
              selectComponent={this.props.selectComponent}
              selectedDimension={this.props.selectedDimension}
              areComparisonsVisible={viewOptions.comparison}
              compareWithOptions={compareWithOptions}
              comparisonKey={viewOptions.compareWith}
              filteredForAnonymity={filteredForAnonymity}
              filteredForAnonymityReason={filteredForAnonymityReason}
              anonymityThreshold={anonymityThreshold}
              scoreOrDelta={viewOptions.scoreOrDelta}
              likertLabels={likertLabels}
            />
          }
        </HeatmapContainer>
      </div>
    );
  }
}

const HeatmapContainer = styled.div`
  margin-top: 24px;
`;

const LoadingContainer = styled.div`
  text-align: center;
  background: white;
  padding: 24px;
`;

export default withRouter(HeatmapReport);
