import React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import { Route, Switch } from "react-router-dom";
import { Trans } from "react-i18next";
import styled from "styled-components";

import { State } from "@store/types";
import { getResourceById } from "@src/store/network/selectors";
import { replaceTo } from "@src/utils/locations";
import { fetchPulsePollInfoIfNeeded } from "@src/store/network/resource/PulsePollInfoResources";

import Tabs from "@components/core/Tabs";
import { StatusTag } from "@components/core/StatusTag";
import { LinkedButton } from "@components/core/Button";
import Spin from "@components/core/Spin";

import {
  Header,
  Body,
  BodyWrapper
} from "@components/layouts/ReportContainer";

import NavbarItems from "@src/components/layouts/ReportContainer/NavbarItems";


import { PulsePollInfoResource } from "hyphen-lib/dist/domain/resource/PulsePollInfoResource";

import { Store } from "hyphen-lib/dist/util/store/Store";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { hasAdminAccess } from "@src/utils/rights";
import { Post } from "hyphen-lib/dist/domain/Post";
import { FetchError } from "../errors/FetchError";
import PulsePollsDetails from "./containers/PulsePollsDetails";
import PulsePollsReports from "./containers/PulsePollsReports";

const { TabPane } = Tabs;

interface MatchParams {
  templateId: string;
  instanceId: string;
}

interface PulsePollStateResource {
  readonly pulsePollInfo: Loadable<PulsePollInfoResource>;
  readonly hasPollAdminRight: boolean;
}

interface PulsePollsProps extends RouteComponentProps<MatchParams>, PulsePollStateResource {
  fetchPulsePollInfoResourceIfNeeded: (templateId: string) => void;
}

class PulsePolls extends React.Component<PulsePollsProps> {
  redirectPath: string;
  pulsePollReportDetailsPath: string;

  constructor(props: PulsePollsProps) {
    super(props);

    const { templateId, instanceId } = props.match.params;
    this.redirectPath = `/pulsePolls/${templateId}/instances/${instanceId}/reports/overview`;
    this.pulsePollReportDetailsPath = `/pulsePolls/${templateId}/reports/details`;
  }

  componentDidMount() {
    const { match, fetchPulsePollInfoResourceIfNeeded } = this.props;
    const {
      params: { templateId },
    } = match;

    fetchPulsePollInfoResourceIfNeeded(templateId);
  }

  onChange = (path: any) => {
    const { location } = this.props;
    const { pathname } = location;
    const pathnameArr = pathname.split("/");
    pathnameArr.splice(-1, 1, path);

    if (path === "details") {
      replaceTo(this.pulsePollReportDetailsPath);
    } else {
      replaceTo(this.redirectPath);
    }
  };

  getPath = () => {
    const { location } = this.props;
    const { pathname } = location;
    const pathnameArray = pathname.split("/");
    if (pathnameArray.includes("details")) {
      return "details";
    }
    else if (pathnameArray.includes("reports")) {
      return pathnameArray[3];
    }
  };

  renderPulsePollPageHeader = () => {
    const { pulsePollInfo, hasPollAdminRight } = this.props;
    const { templateId } = this.props.match.params;
    if (Loadable.isInError(pulsePollInfo)) {
      return <FetchError {...pulsePollInfo} resourceType={PulsePollInfoResource.TYPE} />;
    }
    return Loadable.isLoading(pulsePollInfo) ? (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    ) : (
      <Header>
        <TitleAndStatus>
          <Title
            data-cy="pollsReport_header"
            data-title={pulsePollInfo.value.question}>{pulsePollInfo.value.question}
          </Title>
          <StatusTagContainer data-cy="pollStatus">
            <StatusTag status={
              pulsePollInfo.value.status === Post.Status.DEACTIVATED && 
              pulsePollInfo.value.frequency === "once" && 
              !pulsePollInfo.value.isLastInstanceLaunched ? "closed" as Post.Status : pulsePollInfo.value.status
            } type="poll"/>
          </StatusTagContainer>
        </TitleAndStatus>

        { hasPollAdminRight &&
          <NavbarItems>
            <LinkedButton
              link={`/pulsePolls/edit/${templateId}/settings`}
              color="grey"
              translate="yes"
            >
              Edit Poll
            </LinkedButton>
          </NavbarItems>
        }
      </Header>
    );
  };

  render() {
    const selectedTab = this.getPath();
    return (
      <>
        {this.renderPulsePollPageHeader()}

        {/* body */}
        <Body>
          <Switch>
            <Route
              exact
              path="/pulsePolls/:templateId/instances/:instanceId/reports"
              component={PulsePollsReports}
            />
            <Route
              exact
              path="/pulsePolls/:templateId/reports/details"
              component={PulsePollsDetails}
            />
          </Switch>

          {/* body top nav */}
          <BodyWrapper>
            <Tabs defaultActiveKey={selectedTab} onChange={this.onChange}>
              <TabPane tab={<Trans>Reports</Trans>} key="reports">
                <PulsePollsReports />
              </TabPane>
              <TabPane tab={<Trans>Details</Trans>} key="details">
                <PulsePollsDetails />
              </TabPane>
            </Tabs>
          </BodyWrapper>
        </Body>
      </>
    );
  }
}

const TitleAndStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 90%;
  position: relative;
`;

const Title = styled.div`
  font-size: 32px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:hover::after {
    position: absolute;
    visibility: hidden;
    left: 25%;
    top: 100%;
    font-size: 14px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 5px;
    max-width: 90%;
    white-space: normal;
    animation: showContent 0.1s ease 2s infinite;
    content: attr(data-title);
  }
  @keyframes showContent {
    to {
      visibility: visible;
    }
  }
`;

const StatusTagContainer = styled.div`
  width: 87px;
  margin-left: 24px;
  margin-top: 6px;
`;

const SpinContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 100%;
`;

function mapStateToProps(state: State, ownProps: PulsePollsProps): PulsePollStateResource {
  const { match: { params: { templateId } } } = ownProps;
  const pulsePollInfoElement = getResourceById(
    state,
    PulsePollInfoResource.TYPE,
    templateId
  );
  const hasPollAdminRight = Store.Element.isLoaded(pulsePollInfoElement) &&
    hasAdminAccess(pulsePollInfoElement.value.role);

  const pulsePollInfo = Store.Element.toLoadable(pulsePollInfoElement);

  return {
    pulsePollInfo,
    hasPollAdminRight,
  };
}

const mapDispatchToProps = {
  fetchPulsePollInfoResourceIfNeeded: fetchPulsePollInfoIfNeeded,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PulsePolls));
