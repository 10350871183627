import { default as networkActionCreators } from "@store/network/actions";
import { createRequest } from "@src/utils/networks";
import { appendQueryString, generateQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { DashboardResource } from "hyphen-lib/dist/domain/resource/report/DashboardResource";

export function fetchDashboardIfNeeded(queryString: Dictionary<any>) {
  return networkActionCreators.fetchElementIfNeeded({
    id: DashboardResource.generateId(queryString.filter),
    type: DashboardResource.TYPE,
    request: createRequest(
      appendQueryString(
        "/dashboards/insights",
        generateQueryString(queryString)
      )
    ),
  });
}
