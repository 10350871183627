import { isNullOrUndefined } from "hyphen-lib/dist/lang/Objects";
import { fromJS } from "immutable";
import { PhaseSummariesForPlotBand, computeChartVitalStats, PLOT_BAND_WIDTH } from ".";

export const defaultPlotLines = fromJS([
  {
    color: "#fff",
    width: 2,
    value: 100,
    zIndex: 5,
  },
]);

export let plotLineXValue = 1;
export function computePlotLine(phaseSummaries: PhaseSummariesForPlotBand) {
  if (isNullOrUndefined(phaseSummaries.Separation)) {
    return defaultPlotLines;
  }

  const { totalNumberOfPhases } = computeChartVitalStats(phaseSummaries);
  plotLineXValue = PLOT_BAND_WIDTH  * (totalNumberOfPhases - 1);
}
