import { Apps } from "hyphen-lib/dist/domain/apps/App";
import { getOr, isNotNullNorUndefined, mapOr } from "hyphen-lib/dist/lang/Objects";
import React from "react";
import { connect, MapStateToProps } from "react-redux";
import { getCurrentUser, getCurrentUserNotification } from "../../store/selectors";
import { RenderPropType } from "../DataListContainer/types";
import { State } from "@store/types";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { settingsActionCreators } from "../../Settings/store/actions";
import { NetworkRequestAction } from "@src/store/network/actions";
import { Notification } from "@hyphen-lib/domain/trait/WithNotifications";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";

export interface FeatureIntroChildren {
  handleCloseNotification: () => void;
  notificationMessage: Notification["message"];
  notificationId: Notification["_id"];
  notificationTitle: Notification["title"];
}

export interface FeatureIntroOwnProps {
  page: Apps.Pages;
}

interface FeatureIntroReduxStoreProps {
  notificationMessage: Notification["message"];
  notificationId: Notification["_id"];
  userId: string;
  notificationTitle: Notification["title"];
}

interface FeatureIntroActionProps {
  readonly updateUserNotifications: (userId: string, notificationId: string) => NetworkRequestAction;
}

type Props =
  FeatureIntroOwnProps &
  FeatureIntroReduxStoreProps &
  FeatureIntroActionProps &
  { children: React.ReactNode };

export type RenderPropTypeForFeatureIntro = RenderPropType<FeatureIntroChildren>;

class FeatureIntro extends React.Component<Props> {
  handleCloseNotification = () => {
    const { updateUserNotifications, userId, notificationId } = this.props;
    if (isNotNullNorUndefined(notificationId)) {
      updateUserNotifications(userId, notificationId);
    }
  };

  render() {
    const { children, notificationMessage, notificationId, notificationTitle } = this.props;
    return typeof children === "function"
      ? (children as RenderPropTypeForFeatureIntro)({ 
        handleCloseNotification: this.handleCloseNotification,
        notificationMessage,
        notificationId,
        notificationTitle
      })
      : children;
  }
  
}

const mapStateToProps: MapStateToProps<FeatureIntroReduxStoreProps, FeatureIntroOwnProps, State> =
  (state: State, ownProps: FeatureIntroOwnProps): FeatureIntroReduxStoreProps => {
    let notification = {} as Notification;
    let userId = "";
    const currentUser = getCurrentUser(state);
    if (
      isNotNullNorUndefined(currentUser) &&
      isNotNullNorUndefined(currentUser.company) &&
      isNotNullNorUndefined(currentUser.company.name)
    ) {
      notification = getCurrentUserNotification(state, ownProps.page);
      const { userId: currentUserId } = mapOr(
        currentUser, 
        (_currentUser: CurrentUserResource) => ({
          userId: _currentUser._id,
        }),
        {
          userId: "", 
        });
      userId = currentUserId;
    }
    return {
      notificationMessage: notification.message,
      notificationId: getOr(notification._id, Optional.empty() as string),
      userId,
      notificationTitle: getOr(notification.title, Optional.empty() as string)
    };
};

export default connect(
  mapStateToProps,
  { updateUserNotifications: settingsActionCreators.updateUserNotifications}
)(FeatureIntro);