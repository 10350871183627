import React from "react";
import { Comparison } from "@hyphen-lib/domain/resource/report/common/Comparison";
import { CellContainer, CellInnerContainer } from "@screens/Insights/SurveyList/components/cells/Cells";
import CompareValue from "@components/core/CompareValue";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { extractComparison } from "hyphen-lib/dist/business/calculation/benchmark/Benchmarks";

interface Props {
  row: {
    compare?: Comparison;
  };
  comparisonKey: Optional<string>;
  percentage?: boolean;
  textAlign?: string;
}

export class CompareCell extends React.Component<Props> {
  render() {
    const { row: { compare }, comparisonKey, percentage, textAlign } = this.props;
    const comparison = extractComparison(compare, comparisonKey);
    return (
      <CellContainer align="flex-start" justifyContent="flex-start">
        <CellInnerContainer padding={[24, 0]} width="100%" textAlign={textAlign}>
          <div>
            <CompareValue
              compare={comparison}
              percentage={percentage}
            />
          </div>
        </CellInnerContainer>
      </CellContainer>
    );
  }
}
