import React, { Component } from "react";

import styled from "styled-components";
import Select from "@src/components/core/Select";
import { SelectValue } from "antd/lib/select";

import Palette from "@src/config/theme/palette";
import { Post } from "hyphen-lib/dist/domain/Post";
import { formatDate } from "hyphen-lib/dist/lang/Dates";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { Trans } from "react-i18next";

const { Option } = Select;

interface IterationPickerProps {
  readonly onIterationSelect: (iteration: SelectValue) => void;
  readonly selectedValue: Optional<string>;
  readonly iterationData: Post.PulsePoll.InstanceInfo[];
  readonly disabled?: boolean;
}

export default class IterationPicker extends Component<IterationPickerProps> {

  handleIterationSelect = (value: SelectValue) => {
    this.props.onIterationSelect(value);
  };

  render() {
    const { iterationData, selectedValue, disabled } = this.props;
    const data = Array.isArray(iterationData) && iterationData.slice(0, 50);
    const instance = iterationData.find(v => v.id === selectedValue);
    const selectedLaunchDate = isNotNullNorUndefined(instance) ? formatDate(instance.launchDate) : "Unknown";
    return (
      <IterationContainer>

        <IterationLabel><Trans>Iteration</Trans></IterationLabel>
        {/*
          // @ts-ignore */  }
        <Select
          disabled={disabled}
          value={selectedValue === null ? undefined : selectedValue}
          style={{ width: 150 }}
          onChange={this.handleIterationSelect}
          data-cy="iterationSelection"
          className="block--print-hide"
        >
          {data && data.map(iteration => (
            <Option key={iteration.id} value={iteration.id}>
              {formatDate(iteration.launchDate)}
            </Option>
          ))}
        </Select>
        <IterationPoint>{selectedLaunchDate}</IterationPoint>

      </IterationContainer>
    );
  }
}

const IterationLabel = styled.span`
  font-size: 14px;
  color: ${Palette.veryDarkBlueGrey};
  margin-right: 18px;
  display: flex;
  align-items: center;
`;

const IterationPoint = styled.div`
  display: none;
  @media print {
    display: block;
    font-size: 16px;
  }
`;
const IterationContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  @media print {
    height: 100%;
    justify-content: center;
  }
`;
