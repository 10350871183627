import React from "react";
import Modal from "@src/components/core/Modal";
import { ModalProps } from "antd/lib/modal";
import { Paragraph } from "@src/components/core/Typography";
import styled from "styled-components";
import { Rate } from "antd";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { ActionPlanTemplateResource } from "hyphen-lib/dist/domain/resource/action/ActionPlanTemplateResource";
import { RateProps } from "antd/lib/rate";
import { Trans } from "react-i18next";

export interface PreviewTemplateProps extends ModalProps, ActionPlanTemplateResource {}

interface PropertyProps {
  label: string;
  value: string;
}

interface RatingPropertyProps extends RateProps {
  label: string;
  value: number;
}

export function PreviewTemplateModal(props: PreviewTemplateProps) {
  const { action, description, difficulty, category, resource, rating, length } = props;
  return (
    <StyledModal title={<Trans>Action Plan Preview</Trans>} {...props}>
      <Container>
        <Property label="Name" value={action} />
        <Property label="Description" value={description} />
        <Property label="Difficulty" value={difficulty} />
        <Property label="Length" value={length} />
        <Property label="Category" value={category} />
        {isNotNullNorUndefined(resource) && (
          <LinkProperty label="Resource" value={resource} />
        )}
        <RatingProperty label="Rating" value={isNotNullNorUndefined(rating) ? rating : 0} />
      </Container>
    </StyledModal>
  );
}

function Property({ label, value }: PropertyProps) {
  return (
    <PropertyContainer>
      <Label><Trans>{label}</Trans>:</Label>
      {typeof value === "string" ? (
        <Value weight="light"><Trans>{value}</Trans></Value>
      ) : (
        <ComponentContainer>{value}</ComponentContainer>
      )}
    </PropertyContainer>
  );
}

function LinkProperty({ label, value }: PropertyProps) {
  return (
    <PropertyContainer>
      <Label><Trans>{label}</Trans>:</Label>
      <Value weight="light">
        <a target="_blank" rel="noopener noreferrer" href={value}>{value}</a>
      </Value>
    </PropertyContainer>
  );
}

function RatingProperty({ label, value }: RatingPropertyProps) {
  const roundedValue = Math.round(value * 2) / 2;
  return (
    <PropertyContainer>
      <Label><Trans>{label}</Trans>:</Label>
      <RatingContainer>
        <Rate allowHalf disabled defaultValue={roundedValue} />
      </RatingContainer>
    </PropertyContainer>
  );
}

const StyledModal = styled(Modal)<ModalProps & {children: React.ReactNode}>`
  min-width: 900px !important;
`;

const Container = styled.div`
  padding: 0 32px !important;
`;

const PropertyContainer = styled.div`
  display: flex;
  margin-top: 8px;
`;

const RatingContainer = styled.div`
  width: 70%;
`;

const Label = styled(Paragraph)`
  width: 30%;
`;

const Value = styled(Paragraph)`
  width: 70%;
`;

const ComponentContainer = styled.div`
  width: 70%;
`;
