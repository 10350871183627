import { Component } from "react";
import { Col, Row } from "antdv4";
import { Trans } from "react-i18next";
import styled, { css } from "styled-components";
import ContainerCard from "@src/components/core/ContainerCard";
import Button from "@src/components/core/Button";
import Palette from "@src/config/theme/palette";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import { isNullOrEmpty } from "hyphen-lib/dist/lang/Strings";

interface MagicSurveyProps {
  generateUrl: () => void;
  isMagicSurvey: Optional<boolean>;
  surveyUrl: Optional<string>;
  surveyValidationError: WrongEntityException.Errors;
}
export class MagicSurveySection extends Component<MagicSurveyProps> {

  copyToClipBoard = () => {
    const { surveyUrl } = this.props;
    if (isNotNullNorUndefined(surveyUrl)) {
      navigator.clipboard.writeText(surveyUrl);
    }
  };

  renderGeneratedUrlSection = () => {
    const { surveyUrl } = this.props;
    return (
      <>
        <Wrapper>
          <Text>
            <Trans>The web link is generated automatically</Trans>
          </Text>
          <GenratedUrlWrapper>
            <GenratedUrl data-cy="generatedMagicUrlText">
              {surveyUrl}
            </GenratedUrl>
            <StyledButton backgroundcolor={Palette.bluishGrey} onClick={this.copyToClipBoard} translate="yes">
              Copy Link
            </StyledButton>
          </GenratedUrlWrapper>
        </Wrapper>
        <Note><Trans>
          Please note that everyone with access to this link will be able to
          respond to the survey anonymously once it is launched.
          Those participants’ data won’t be available in automatic filtering and segmentation.
        </Trans></Note>
      </>
    );
  };

  render() {

    const {
      isMagicSurvey,
      surveyUrl,
      surveyValidationError,
      generateUrl,
    } = this.props;

    const showMagicSurveyErrorText = surveyValidationError.fields.size > 0 && isNullOrEmpty(surveyUrl);
    return (
      <StyledContainer>
        <ContainerCard
          title="Share the survey via a web link"
          /* eslint-disable max-len */
          description="Create a web link and distribute to your employees manually. While web link ensures complete anonymity, it can lead to subpar data quality, so try including a few demographic questions that can help slice and dice the data in Survey Report."
        >
          <Row>
            <Col>
              {
                isMagicSurvey 
                ? this.renderGeneratedUrlSection()
                : <StyledButton
                    data-cy="generateMagicUrlButton" 
                    backgroundcolor={Palette.rockBlue}
                    margin="24px"
                    onClick={generateUrl}
                    translate="yes"
                  >
                    Generate a web link
                  </StyledButton>
              }
              {
                showMagicSurveyErrorText && <MagicSurveyErrorSection>
                  <Trans>Can't generate web link as there are errors observed in some of the survey creation steps.
                  Please resolve the errors and try generating the web link again.</Trans>
                </MagicSurveyErrorSection>
              }
            </Col>
          </Row>
        </ContainerCard>
      </StyledContainer>
    );
  }
}

const ButtonStyle = css<{backgroundcolor: string; margin?: string}>`
  background-color: ${props => props.backgroundcolor};
  color: ${Palette.white};
  font-size: 15px;
  font-weight: bold;
  border: 1px solid ${Palette.rockBlue}; 
  border-radius: 2px;
  border: none;
  margin-top: ${props => props.margin || "0px"};
`;

const StyledContainer = styled.div`
  margin-top: 24px;
`;

const GenratedUrlWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  align-items: center;
`;

const Wrapper = styled.div`
  margin-top: 24px;
  padding: 12px;
  background-color: ${Palette.lightLightBlue};
  border-radius: 0 0 3px 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Text = styled.div`
  color: ${Palette.veryDarkBlueGrey};
  font-size: 12px;
`;

const GenratedUrl = styled.div`
  color: ${Palette.veryDarkBlueGrey};
  font-weight: bold;
  margin-right: 24px;
`;

const StyledButton = styled(Button)<{backgroundcolor: string; margin?: string}>`
 ${ButtonStyle}

 &:hover {
   ${ButtonStyle}
 }

 &:active {
  ${ButtonStyle}
 }

 &:focus {
  ${ButtonStyle}
 }

 &:after {
  ${ButtonStyle}
 }
`;

const MagicSurveyErrorSection = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  color: ${Palette.alizarin};
`;

export const Note = styled.div`
  margin-top: 24px;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid ${Palette.veryLightBlue};
  background-color: ${Palette.athensGrey};
  color: ${Palette.veryDarkBlueGrey};
`;

export default MagicSurveySection;
