import { applyMiddleware, compose, createStore } from "redux";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";

import reducers from "@store/reducers";

import { rootSaga } from "@store/sagas";
import { State } from "./types";

const sagaMiddleware = createSagaMiddleware();

let middleware: any[] = [sagaMiddleware];

if (process.env.NODE_ENV !== "production" && process.env.NODE_ENV !== "test") {
  const loggerMiddleware = createLogger({
    collapsed: true,
  });
  middleware = [...middleware, loggerMiddleware];
}

const composeEnhancers =
typeof window === "object" &&
// @ts-ignore
window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
// @ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
  }) : compose;

const enhancer = composeEnhancers(
  applyMiddleware(...middleware)
  // other store enhancers if any
);

const configureStore = (preloadedState?: State) => {
  const store = createStore(
    reducers,
    preloadedState,
    enhancer
  );

  sagaMiddleware.run(rootSaga);

  return store;
};

export default configureStore;
