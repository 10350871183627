import React from "react";
import { Icon } from "antd";
import styled from "styled-components";
import { Heading } from "@components/core/Typography";
import Palette from "@src/config/theme/palette";
import Table from "@src/components/core/Table";
import { Table as AntTable } from "antd";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import Tooltip from "@components/core/Tooltip";
import Input from "@components/core/Input";
import { Trans } from "react-i18next";
import { Dimension } from "../types";
import NoResult from "@src/components/core/NoResult";

const { Column } = AntTable;

export interface DimensionsTableProps {
  dataSource: Dimension[];
  editDimensionName: (value: string, index: number, record: any) => void;
  editDimensionKey: (value: string, index: number, record: any) => void;
  removeDimension: (index: number) => void;
  onDoneEditingDimensionName: (value: string) => void;
}

export interface DimensionsTableState {
  hoveredIndex: Optional<number>;
}

export class DimensionsTable extends React.Component<DimensionsTableProps, DimensionsTableState>{
  constructor(props: DimensionsTableProps) {
    super(props);
    this.state = {
      hoveredIndex: null,
    };
  }

  onRow = (__: any, rowIndex: number) => {
    return {
      onMouseEnter: () => this.setState({ hoveredIndex: rowIndex }),
      onMouseLeave: () => this.setState({ hoveredIndex: null }),
    };
  };

  onNameChange = (value: string, index: number, record: any) => {
    this.props.editDimensionName(value, index, record);
  };

  onKeyChange = (value: string, index: number, record: any) => {
    this.props.editDimensionKey(value, index, record);
  };

  removeDimension = (index: number) => {
    this.props.removeDimension(index);
  };

  onDoneEdit = (e: any) => {
    this.props.onDoneEditingDimensionName(e.target.value);
  };

  render() {
    /* tslint:disable:jsx-no-lambda */
    const { dataSource } = this.props;
    const { hoveredIndex } = this.state;
    return (
      <Table
        dataSource={dataSource}
        rowKey={"id"}
        pagination={false}
        onRow={this.onRow}
        locale={{
          emptyText: (<NoResult type="minimal" description="No Data" />),
        }}
      >
        <Column
          title={(
            <div>
              <ColumnStyledHeader minWidth={100} size="small" translate="yes">
                <Trans>Dimension name</Trans></ColumnStyledHeader>
              <ColumnDescription>
                <Trans>How it will be displayed in Engage Insights</Trans>
              </ColumnDescription>
            </div>

          )}
          dataIndex="name"
          key="name"
          render={(role: any, record: any, index: number) => (
            <DimensionInput
              size="large"
              value={record.name}
              onChange={(e: any) => this.onNameChange(e.target.value, index, record)}
              onBlur={this.onDoneEdit}
              minLength={3}
              maxLength={30}
              required title="3 to 30 characters"
              data-jest={`editDimName_${record.name}`}
            />
          )}
        />

        <Column
          title={(
            <div>
              <ColumnStyledHeader minWidth={200} size="small" translate="yes">
                <Trans>Dimension key</Trans>
                <Tooltip
                  title={<Trans>Once you set the key you cannot change it later</Trans>}>
                  <HelpIcon type="info-circle" />
                </Tooltip>
              </ColumnStyledHeader>
              <ColumnDescription>
                <Trans>How the corresponding column should be named in your CSV file</Trans>
              </ColumnDescription>
            </div>
          )}
          dataIndex="dimKey"
          key="dimKey"
          render={(role: any, record: any, index: number) => (
            <DimensionInput
              size="large"
              value={record.dimKey}
              disabled={!record.isEditable}
              onChange={(e: any) => this.onKeyChange(e.target.value, index, record)}
              minLength={3}
              maxLength={30}
              required title="Dimension keys once set can't be edited"
            />
          )}
        />

        <Column
          title=""
          dataIndex="remove"
          key="remove"
          render={(accessType: any, record: any, index: number) => (
            // @ts-ignore
            <Remove
              style={{ visibility: (hoveredIndex === index) ? "visible" : "hidden" }}
              key={index}
              onClick={() => this.removeDimension(index)}
            >
              <Trans>Remove</Trans>
            </Remove>
          )}
        />
      </Table>
    );
  }
}

const ColumnStyledHeader = styled(Heading)<{ minWidth: number }>`
  color: ${Palette.darkBlueGrey};
  min-width: ${props => `${props.minWidth}px`};
`;

const ColumnDescription = styled.div`
  color: ${Palette.bluishGrey};
  font-weight: normal !important;
  margin-top: 10px;
`;

const HelpIcon = styled(Icon)`
  margin-left: 8px;
  cursor: pointer !important;
  svg {
    fill: ${Palette.bluishGrey};
  }
`;

const DimensionInput = styled(Input)`
  max-width: 300px;
`;

const Remove = styled.a`
  color: #df3868;
`;
