import { Record } from "immutable";

import {
  SegmentReportCommentsReduxProps,
  SegmentReportCommentsReduxRecord
} from "@screens/Insights/Segments/SegmentReport/store/types";
import { SegmentReportActionTypes } from "@screens/Insights/Segments/SegmentReport/store/actions";

const defaultValues: SegmentReportCommentsReduxProps = {
  resourceId: "",
  postId: "",
  voteId: "",
  commentText: "",
  privateMessageText: "",
  origin: "",
  source: undefined,

  isPrivateMessageModalVisible: false,
  isSendMessageRequesting: false,
  errorText: "",
};

export const segmentReportCommentsStateFactory = Record<SegmentReportCommentsReduxProps>(defaultValues);
const defaultState = segmentReportCommentsStateFactory();

export function segmentReportCommentsReducers(
  state: SegmentReportCommentsReduxRecord = defaultState,
  action: any
) {
  switch (action.type) {
    case SegmentReportActionTypes.SEND_PRIVATE_MESSAGE_REQUEST:
      return state.merge({isSendMessageRequesting: true, errorText: ""});
    case SegmentReportActionTypes.SEND_PRIVATE_MESSAGE_ERROR:
      return state.merge({
        isSendMessageRequesting: false,
        errorText: "Unexpected error occurred while sending messsage.",
      });
    case SegmentReportActionTypes.SEND_PRIVATE_MESSAGE_SUCCESS:
      return state.merge({...defaultValues});
    case SegmentReportActionTypes.CLEAR_PRIVATE_MESSAGE_MODAL:
      return state.merge({...defaultValues});
    case SegmentReportActionTypes.UPDATE_PRIVATE_MESSAGE_TEXT:
      return state.set("privateMessageText", action.payload);
    case SegmentReportActionTypes.TOGGLE_PRIVATE_MESSAGE_MODAL:
      return (!(state.get("isPrivateMessageModalVisible")))
        ? state.merge({...action.payload, privateMessageText: "", isPrivateMessageModalVisible: true })
        : state.merge({...defaultValues});
    default:
      return state;
  }
}
