import React from "react";
import styled from "styled-components";
import Heading from "@components/core/Typography/Heading";
import { SelectValue } from "antd/lib/select";
import SurveyReportHeader from "@screens/Insights/Survey/components/SurveyReportHeader";
import { Optional } from "@hyphen-lib/lang/Optionals";
import { Dimensions } from "@hyphen-lib/domain/common/Dimensions";
import { getOr } from "@hyphen-lib/lang/Objects";
import SelectDimension from "@screens/Insights/Survey/components/SelectDimension";
import { PropMapping } from "@src/utils/parameters";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { ParticipationReportResource } from "hyphen-lib/dist/domain/resource/survey/report/ParticipationReportResource";
import ParticipationChart from "./components/ParticipationChart";

interface Props {
  selectedDimension: SelectValue;
  participation: ParticipationReportResource;
  dimensions: Optional<Dimensions>;
  enabledFilters: string[];
  enabledCustomFilters?: string[];
  storeMappings?: PropMapping[];
  isFiltersAvailable?: boolean;

  updateSegmentByInPath(selectedDimension: SelectValue): void;
}

class Participation extends React.Component<Props> {
  changeDimension = (selectedDimension: SelectValue) => {
    this.props.updateSegmentByInPath(selectedDimension);
  };

  render() {
    const {
      participation,
      dimensions,
      selectedDimension,
      enabledFilters,
      enabledCustomFilters,
      storeMappings,
      isFiltersAvailable,
    } = this.props;

    const dataForChart = getOr(participation.dimensions[selectedDimension.toString()], {});
    const participationDimensions = Object.keys(getOr(participation.dimensions, {}));
    const participationHeaderData = getOr(participation.company, {
      total: 0,
      completed: 0,
    });

    return (
      <>
        <SurveyReportHeader
          participation={participationHeaderData}
          enabledFilters={enabledFilters}
          enabledCustomFilters={enabledCustomFilters}
          storeMappings={storeMappings}
          exportOption="participation"
        />
        <Container isFiltersAvailable={isNotNullNorUndefined(isFiltersAvailable) && isFiltersAvailable}>
          <Header>
            <Heading translate="yes">Participation by segment</Heading>
          </Header>
          <SelectDimension
            data={participationDimensions}
            selected={selectedDimension}
            dimensions={dimensions}
            onChangeDimension={this.changeDimension}
          />
          <ChartContainer>
            <ParticipationChart
              dataForChart={dataForChart}
            />
          </ChartContainer>
        </Container>
      </>
    );
  }
}

const ChartContainer = styled.div`
  padding-top: 24px;
`;

const Container = styled.div<{ isFiltersAvailable: boolean }>`
  background: white !important;
  margin-top: 24px;
  padding: 32px 32px;
  font-family: "Lato";

  @media print {
    padding-top: ${props => props.isFiltersAvailable ? "" : "110px"};
  }

`;

const Header = styled.div`
  margin-bottom: 16px;
`;

export default Participation;
