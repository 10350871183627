// @ts-nocheck
import React from "react";
import Palette from "@src/config/theme/palette";

export function LogoutSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15">
      <defs>
        {/* eslint-disable max-len */}
        <path id="logoutA" d="M15.75 17H8.246a1.251 1.251 0 0 1-1.249-1.25v-3.125a.625.625 0 1 1 1.25 0v3.125h7.503V3.25H8.246v3.125a.625.625 0 1 1-1.249 0V3.25c0-.69.56-1.25 1.25-1.25h7.503c.689 0 1.249.56 1.249 1.25v12.5c0 .69-.56 1.25-1.25 1.25zM4.132 8.875h8.487a.625.625 0 1 1 0 1.25H4.132l1.432 1.433a.625.625 0 0 1-.883.884l-2.498-2.5A.633.633 0 0 1 2 9.505v-.008c0-.164.066-.322.183-.439L4.68 6.558a.623.623 0 0 1 .883 0 .625.625 0 0 1 0 .884L4.132 8.875z"/>
        {/* eslint-disable max-len */}
      </defs>
      <use fill={Palette.darkTurqoise} fillRule="evenodd" transform="translate(-2 -2)" xlinkHref="#logoutA"/>
    </svg>
  );
}
