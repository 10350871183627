// @ts-nocheck
import React from "react";
import { SvgProperties } from "csstype";

/* eslint-disable max-len */
export default function ClockArcMedium(props: SvgProperties) {
  const { stroke } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="25.70463010947833"
      width="25.720264654765288">
      <defs>
        <ellipse
          id="clockArcMedium-ellipse-0"
          opacity="1"
          rx="10.50781727264342"
          ry="10.5"
          cx="12.860132327382585"
          cy="12.852315054738938"
        />
        <path
          id="clockArcMedium-path-1"
          opacity="1"
          fillRule="evenodd"
          d="M19.9963048567268,20.55957245168013 C22.069866455247805,18.641588596530124 23.367949600025156,15.89843083082646 23.367949600025156,12.852315054738938 C23.367949600025156,7.0533251818387726 18.66343956074778,2.3523150547389378 12.860132327381734,2.3523150547389378 "
        />
        <path
          id="clockArcMedium-path-2"
          opacity="1"
          fillRule="evenodd"
          d="M12.768882391907255,9.352315054738938 L12.768882391907255,12.852315054738938 L16.268882391907255,12.852315054738938 "
        />
        <ellipse
          id="clockArcMedium-ellipse-3"
          opacity="1"
          rx="5.5"
          ry="5.5"
          cx="12.768882391907255"
          cy="12.852315054738938"
        />
      </defs>
      <g opacity="1">
        <g opacity="0.1000000014901161">
          <use
            xlinkHref="#clockArcMedium-ellipse-0"
            fillOpacity="0"
            stroke="rgb(170,185,206)"
            strokeOpacity="1"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="miter"
          />
        </g>
        <g opacity="1">
          <use
            xlinkHref="#clockArcMedium-path-1"
            fillOpacity="0"
            stroke={stroke}
            strokeOpacity="1"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="miter"
          />
        </g>
        <g opacity="1">
          <use
            xlinkHref="#clockArcMedium-path-2"
            fillOpacity="0"
            stroke="rgb(170,185,206)"
            strokeOpacity="1"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g opacity="1">
          <use
            xlinkHref="#clockArcMedium-ellipse-3"
            fillOpacity="0"
            stroke="rgb(170,185,206)"
            strokeOpacity="1"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
}
/* eslint-disable max-len */
