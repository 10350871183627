import React from "react";
import { SurveyInfoResource } from "@hyphen-lib/domain/resource/SurveyInfoResource";
import { ColumnProps, TableProps } from "antd/lib/table";
import { Heading } from "@components/core/Typography";
import { DynamicRowProps, withDynamicRows } from "@components/core/DynamicRowsTable/withDynamicRows";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { StatusCell } from "@screens/Insights/SurveyList/components/cells/StatusCell";
import { ParticipationCell } from "@screens/Insights/SurveyList/components/cells/ParticipationCell";
import { ActionCell } from "@screens/Insights/SurveyList/components/cells/ActionCell";
import { AudienceCell } from "@screens/Insights/SurveyList/components/cells/AudienceCell";
import { DateCell } from "@screens/Insights/SurveyList/components/cells/DateCell";
import { SurveyInfosPageParameters } from "@screens/Insights/SurveyList/store/actions";
import { getOr, isNotNullNorUndefined, isNotEmptyObject, isEmptyObject } from "hyphen-lib/dist/lang/Objects";
import {FilterParameter, SortParameter} from "@src/utils/networks";
import {PageFilter} from "hyphen-lib/dist/domain/parameter/PageFilter";
import Table from "@src/components/core/Table";
import { SurveyActionKeyType } from "../../components/Actions";
import { NameCell } from "./cells/NameCell";
import { Trans } from "react-i18next";
import { formatDate } from "hyphen-lib/dist/lang/Dates";

export interface SurveyTableProps extends TableProps<SurveyInfoResource> {
  readonly hasCreationRight: boolean;
  readonly surveys: SurveyInfoResource[];
  readonly onModifyList: (parameters: SurveyInfosPageParameters) => any;
  readonly sort?: SortParameter;
  readonly page: PageFilter;
  readonly filter?: FilterParameter;
  readonly total: number;
  readonly loading: boolean;
}

interface OwnProps {
  readonly onActionClick: (key: keyof SurveyActionKeyType, data: SurveyInfoResource) => void;
}

function getSortOrder(field: string, sort?: SortParameter) {
  if (isEmptyObject(sort)) {
    return undefined;
  }
  if (sort![field] === 1) {
    return "ascend";
  } else if (sort![field] === -1) {
    return "descend";
  }
  return undefined;
}

function SurveyTableComponent({surveys, page, sort, filter, total, onModifyList, ...rest}: SurveyTableProps) {
  const COLUMNS: ColumnProps<SurveyInfoResource>[] = [
    {
      title: (<ColumnStyledHeader minWidth={300} size="small" translate="yes">Name</ColumnStyledHeader>),
      dataIndex: "name",
      key: "name",
      sorter: true,
      sortOrder: getSortOrder("name", sort),
      sortDirections: ["ascend","descend"],
      render: (__, record, index) => {
        return (
          <NameCell row={record} index={index} />
        );
      },
    },
    {
      title: (<ColumnStyledHeader minWidth={150} size="small" translate="yes">Created</ColumnStyledHeader>),
      dataIndex: "created",
      key: "created",
      sorter: true,
      sortOrder: getSortOrder("created", sort),
      render: (__, record, index) => {
        return formatDate(record.created);
      },
    },
    {
      title: (<ColumnStyledHeader maxWidth={150} size="small" translate="yes">Status</ColumnStyledHeader>),
      dataIndex: "status",
      key: "status",
      width: 150,
      sorter: true,
      sortOrder: getSortOrder("status", sort),
      sortDirections: ["ascend","descend"],
      render: (__, record, index) => {
        return (
          <StatusCell row={record} index={index} />
        );
      },
    },
    {
      title: (<ColumnStyledHeader minWidth={350} size="small" translate="yes">Audience</ColumnStyledHeader>),
      dataIndex: "audience",
      key: "audience",
      width: 350,
      sorter: true,
      sortOrder: getSortOrder("audience", sort),
      sortDirections: ["ascend","descend"],
      render: (__, record, index) => {
        return (
          <AudienceCell row={record} index={index} />
        );
      },
    },
    {
      title: (<ColumnStyledHeader minWidth={200} size="small" translate="yes">Participation</ColumnStyledHeader>),
      dataIndex: "participation",
      key: "participation",
      width: 120,
      sorter: false,
      render: (__, record, index) => {
        return (
          <ParticipationCell row={record} index={index} />
        );
      },
    },
  ];

  /* tslint:disable:jsx-no-lambda */
  return (
    <Table
      tableLayout="auto"
      data-cy="test"
      columns={COLUMNS}
      dataSource={surveys.map(sanitizeSurveyInfoResource)}
      pagination={{
        total,
        pageSize: page.size,
        current: page.number,
        showTotal: (totalDocuments: number, range: number[]) =>
          <Trans i18nKey="surveyTableCount"
          values={{start: range[0], end: range[1], total: totalDocuments}} 
          defaults={`Showing ${range[0]} to ${range[1]} of ${totalDocuments} surveys`}/>,
      }}
      onChange={(pagination, filters, sorter) => {
        const pageParam = {
          size: getOr(pagination.pageSize, page.size),
          number: getOr(pagination.current, 1),
        };
        const sortParam : SortParameter = { };
        if (isNotEmptyObject(sorter) && isNotNullNorUndefined(sorter.order)) {
          sortParam[sorter.columnKey] = sorter.order === "ascend" ? 1 : -1;
        }
        onModifyList({
          filter,
          page: pageParam,
          sort: sortParam,
        });
      }
      }
      {...rest}
    />
  );
}

/*
    fixme: analyze if we need to migrate that in network, I think we need to do that!
    We should probably do that when receiving resources in the network router,
    otherwise we will do that on every render, or when we are going back to some
    already displayed surveys.
 */
function sanitizeSurveyInfoResource(survey: SurveyInfoResource): SurveyInfoResource {
  return {
    ...survey,
    updatedAt: sanitizeDate(survey.updatedAt)!,
    launchedAt: sanitizeDate(survey.launchedAt),
    nextReminderAt: sanitizeDate(survey.nextReminderAt),
    closedAt: sanitizeDate(survey.closedAt),
  };
}

function sanitizeDate(d: any): Date | undefined {
  return isNotNullNorUndefined(d) ? new Date(d) : undefined;
}

const ColumnStyledHeader = styled(Heading)<{ minWidth?: number; maxWidth?: number }>`
  color: ${Palette.darkBlueGrey};
  min-width: ${props => `${props.minWidth}px`};
`;

const LastHoverCellContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledHoverCell = styled.td`
// to ensure the color stay, even when we are not on hover anymore, for example on "More" menu
  background-color: ${Palette.lightLightBlue};
`;
/*  */

/**
 * The survey table component.
 *
 * It uses the HOC `withDynamicRows` to tune an antd Table and specify a specific
 * component when pointer is being over a row
 *
 * @param {SurveyTableProps} props
 * @return {React.ComponentClass<SurveyTableProps>}
 */
export function SurveyTable(props: SurveyTableProps & OwnProps) {

  function HoverRow({row, index}: DynamicRowProps<SurveyInfoResource>) {
    /* tslint:disable:jsx-no-lambda no-console*/
    return (
      <>
        <StyledHoverCell>
          <NameCell row={row} index={index} />
        </StyledHoverCell>
         
        <StyledHoverCell>
          {formatDate(row.created)}
        </StyledHoverCell>
        <StyledHoverCell colSpan={3}>
          <LastHoverCellContainer>
            <DateCell row={row} index={index} />
            <ActionCell
              hasCreationRight={props.hasCreationRight}
              onClick={(key) => props.onActionClick(key, row)}
              row={row}
              index={index}
            />
          </LastHoverCellContainer>
        </StyledHoverCell>
      </>
    );
    /* tslint:enable:jsx-no-lambda no-console*/
  }

  const TailoredTable = withDynamicRows(SurveyTableComponent, HoverRow);

  return <TailoredTable
    rowKey="_id"
    {...props}
  />;
}
