import { SurveyResource } from "hyphen-lib/dist/domain/resource/SurveyResource";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { State } from "@store/types";
import { QuestionPageStateProps, SurveyDisabledFields } from "@screens/Insights/Surveys/store/surveyEditTypes";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import { StepProps } from "@components/core/Stepper";
import { List as ImmutableList, Seq } from "immutable";
import { SurveyQuestionResource } from "hyphen-lib/dist/domain/resource/SurveyQuestionResource";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
import { getCurrentUser, getDimensions } from "@screens/Insights/store/selectors";
import {
  isEmptyObject,
  mapOr,
  keys,
  subObject,
  isNotEmptyObject,
  addProperty,
  isNotNullNorUndefined
} from "hyphen-lib/dist/lang/Objects";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { getUntypedElementById } from "@src/store/network/selectors";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { getConfigurationFor } from "hyphen-lib/dist/business/survey/SurveyTypes";
import { getManageesId } from "@store/network/resource/Untypeds";
import { identity } from "hyphen-lib/dist/lang/Functions";
import { AccessResource } from "hyphen-lib/dist/domain/access/AccessResource";
import { UserEmailInsightAccess } from "hyphen-lib/dist/domain/User";

export function getSurvey(state: State, surveyId: string): Loadable<SurveyResource> {
  return state.insights_surveyEdit.survey;
}

export function getSurveyQuestions(state: State): SurveyQuestionResource[] {
  return state.insights_surveyEdit.questions;
}

export function getSurveyAccesses(state: State): AccessResource[] {
  return state.insights_surveyEdit.accesses;
}

export function getDisabledSurveyFields(state: State): SurveyDisabledFields {
  return state.insights_surveyEdit.disabled;
}

export function getSurveyValidationErrors(state: State): WrongEntityException.Errors {
  return state.insights_surveyEdit.errors;
}

export function getSurveySteps(state: State): ImmutableList<StepProps> {
  return state.insights_surveyEdit.steps;
}

export function getSurveyUpdatedAt(state: State): Date {
  return state.insights_surveyEdit.updatedAt;
}

export function hasSurveyPendingUpdates(state: State): boolean {
  return state.insights_surveyEdit.hasPendingUpdates;
}

export function hasSurveyPendingAccessesUpdates(state: State): boolean {
  return state.insights_surveyEdit.hasPendingAccessesUpdates;
}

export function hasSurveyPendingAudienceUpdates(state: State): boolean {
  return state.insights_surveyEdit.hasPendingAudienceUpdates;
}

export function getSpecificAudienceEmails(state: State,
  loadableSurvey: Loadable<SurveyResource>): Optional<UserEmailInsightAccess[]> {
  if (Loadable.isLoaded(loadableSurvey)) {
    const configuration = getConfigurationFor(loadableSurvey.value.type);
    if (isNotNullNorUndefined(configuration.audienceConfiguration.statusRestriction)) {
      return Store.Element.mapIfLoadedOr(
        getUntypedElementById<UserEmailInsightAccess[]>(
          state, 
          getManageesId(configuration.audienceConfiguration.statusRestriction)
        ),
        identity,
        []
      );
    }
  }

  return Optional.empty();
}

export function getSurveyQuestionPageState(state: State): QuestionPageStateProps {
  return state.insights_surveyEdit.questionPageState;
}

export function getSurveyUpdatedQuestionState(state: State): SurveyQuestionResource | undefined {
  return state.insights_surveyEdit.updatedQuestion;
}
export function getSurveyPreviewModalState(state: State): boolean {
  return state.insights_surveyEdit.isPreviewModalOpen;
}

export function getUpdateAudienceModalOpen(state: State): boolean {
  return state.insights_surveyEdit.isUpdateAudienceModalOpen;
}

export function getSurveyPreviewErrors(state: State): WrongEntityException.Errors {
  return state.insights_surveyEdit.previewErrors;
}

/**
 * Gets the imposed dimensions & segments based on reportingDimMgmts.
 *
 * @param {State} state
 * @return {Optional<Dimensions>}
 */
export function getImposedDimensions(state: State): Optional<Dimensions> {
  const dimensions = getDimensions(state);
  const reportingDimMgmts = getReportingDimMgmts(state);
  if (isEmptyObject(reportingDimMgmts) || isEmptyObject(dimensions)) {
    return dimensions;
  } else {
    return imposeReportingDimMgmts(reportingDimMgmts!, dimensions!);
  }
}

/**
 * Gets reportingDimMgmts for the Current User
 *
 * @param {State} state
 * @return {Optional<Dictionary<string[]>>}
 */
export function getReportingDimMgmts(state: State): Optional<Dictionary<string[]>> {
  return mapOr(
    getCurrentUser(state),
    (user: CurrentUserResource) => user.reportingDimMgmts,
    undefined
  );
}

/**
 * Pure function to impose reportingDimMgmts on dimensions.
 *
 * @param {Dictionary<string[]>} reportingDimMgmts
 * @param {Dimensions} dimensions
 * @return {Optional<Dimensions>}
 */
function imposeReportingDimMgmts(
  reportingDimMgmts: Dictionary<string[]>,
  dimensions: Dimensions) :  Optional<Dimensions>
{
  const dimensionKeys = keys(dimensions).toSet();

  const imposedDimension = subObject(Seq(dimensionKeys)
    .filter((key) => isNotEmptyObject(dimensions[key]))
    .reduce((acc, dimensionKey: string) => {
      let segments;
      if (isEmptyObject(reportingDimMgmts[dimensionKey])) {
        segments = dimensions[dimensionKey].segments;
      } else {
        segments = reportingDimMgmts[dimensionKey];
      }
      return addProperty(acc, dimensionKey, {
        ...dimensions[dimensionKey],
        segments,
      });
    }, {}),
  (dimension) => isNotEmptyArray(dimension.segments));

  return imposedDimension;
}
