import { FSA } from "flux-standard-action";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { AppSettingsResource } from "hyphen-lib/dist/domain/resource/AppSettingsResource";
import { createRequest } from "@src/utils/networks";
import {
  ActionTypes as NetworkActionTypes,
  NetworkRequestAction,
  NetworkEventRequestAction,
  NetworkEventSuccessAction,
  NetworkEventErrorAction
} from "@src/store/network/actions";
import { ActionPlanTemplateResource } from "hyphen-lib/dist/domain/resource/action/ActionPlanTemplateResource";
import { Omit } from "hyphen-lib/dist/lang/Types";

export enum SettingsActionTypes {
  /* All Action types got here */
  ADD_ACTION_TEMPLATE = "settings/ADD_ACTION_TEMPLATE",
  ADD_ACTION_TEMPLATE_REQUEST = "settings/ADD_ACTION_TEMPLATE_REQUEST",
  ADD_ACTION_TEMPLATE_SUCCESS = "settings/ADD_ACTION_TEMPLATE_SUCCESS",
  ADD_ACTION_TEMPLATE_ERROR = "settings/ADD_ACTION_TEMPLATE_ERROR",

  UPDATE_ACTION_TEMPLATE = "settings/UPDATE_ACTION_TEMPLATE",
  UPDATE_ACTION_TEMPLATE_REQUEST = "settings/UPDATE_ACTION_TEMPLATE_REQUEST",
  UPDATE_ACTION_TEMPLATE_SUCCESS = "settings/UPDATE_ACTION_TEMPLATE_SUCCESS",
  UPDATE_ACTION_TEMPLATE_ERROR =  "settings/UPDATE_ACTION_TEMPLATE_ERROR",

  UPDATE_COMPANY_REQUEST = "settings/UPDATE_COMPANY_REQUEST",
  UPDATE_COMPANY_SUCCESS = "settings/UPDATE_COMPANY_SUCCESS",
  UPDATE_COMPANY_ERROR = "settings/UPDATE_COMPANY_ERROR",

  FETCH_COMPANY_REQUEST = "settings/FETCH_COMPANY_REQUEST",
  FETCH_COMPANY_SUCCESS = "settings/FETCH_COMPANY_SUCCESS",
  FETCH_COMPANY_ERROR = "settings/FETCH_COMPANY_ERROR",
  
  FETCH_ALL_ACTION_TEMPLATES_REQUEST = "settings/FETCH_ALL_ACTION_TEMPLATES_REQUEST",
  FETCH_ALL_ACTION_TEMPLATES_SUCCESS = "settings/FETCH_ALL_ACTION_TEMPLATES_SUCCESS",
  FETCH_ALL_ACTION_TEMPLATES_ERROR = "settings/FETCH_ALL_ACTION_TEMPLATES_ERROR",

  EXCLUDE_ACTION_TEMPLATE_REQUEST = "settings/EXCLUDE_ACTION_TEMPLATE_REQUEST",
  EXCLUDE_ACTION_TEMPLATE_SUCCESS = "settings/EXCLUDE_ACTION_TEMPLATE_SUCCESS",
  EXCLUDE_ACTION_TEMPLATE_ERROR =   "settings/EXCLUDE_ACTION_TEMPLATE_ERROR",

  INCLUDE_ACTION_TEMPLATE_REQUEST = "settings/INCLUDE_ACTION_TEMPLATE_REQUEST",
  INCLUDE_ACTION_TEMPLATE_SUCCESS = "settings/INCLUDE_ACTION_TEMPLATE_SUCCESS",
  INCLUDE_ACTION_TEMPLATE_ERROR =   "settings/INCLUDE_ACTION_TEMPLATE_ERROR",
  
  REMOVE_ACTION_TEMPLATE_REQUEST = "settings/REMOVE_ACTION_TEMPLATE_REQUEST",
  REMOVE_ACTION_TEMPLATE_SUCCESS = "settings/REMOVE_ACTION_TEMPLATE_SUCCESS",
  REMOVE_ACTION_TEMPLATE_ERROR =   "settings/REMOVE_ACTION_TEMPLATE_ERROR",

  FETCH_ALL_TEMPLATES_IF_NEEDED = "settings/FETCH_ALL_TEMPLATES_IF_NEEDED",

  UPDATE_APP_SETTINGS_REQUEST = "settings/UPDATE_APP_SETTINGS_REQUEST",
  UPDATE_APP_SETTINGS_SUCCESS = "settings/UPDATE_APP_SETTINGS_SUCCESS",
  UPDATE_APP_SETTINGS_ERROR = "settings/UPDATE_APP_SETTINGS_ERROR",

  UPDATE_USER_NOTIFICATION_REQUEST = "settings/UPDATE_USER_NOTIFICATION_REQUEST",
  UPDATE_USER_NOTIFICATION_SUCCESS = "settings/UPDATE_USER_NOTIFICATION_SUCCESS",
  UPDATE_USER_NOTIFICATION_ERROR = "settings/UPDATE_USER_NOTIFICATION_ERROR",

  FETCH_APP_SETTINGS_REQUEST = "settings/FETCH_APP_SETTINGS_REQUEST",
  FETCH_APP_SETTINGS_SUCCESS = "settings/FETCH_APP_SETTINGS_SUCCESS",
  FETCH_APP_SETTINGS_ERROR = "settings/FETCH_APP_SETTINGS_ERROR",
}

export interface CreateActionTemplatePayload extends  Omit<ActionPlanTemplateResource, "_id" | "_type"> {}
export interface UpdateActionTemplatePayload extends  Omit<ActionPlanTemplateResource, "_type"> {}

interface ActionTemplatesPageParameters {}

interface Payloads {
  [SettingsActionTypes.ADD_ACTION_TEMPLATE]: CreateActionTemplatePayload;
  [SettingsActionTypes.UPDATE_ACTION_TEMPLATE]: UpdateActionTemplatePayload;
  [SettingsActionTypes.UPDATE_COMPANY_REQUEST]: CompanyResource;
  [SettingsActionTypes.UPDATE_APP_SETTINGS_REQUEST]: AppSettingsResource;
  [SettingsActionTypes.FETCH_ALL_ACTION_TEMPLATES_REQUEST]: ActionTemplatesPageParameters;
}

export interface AddActionTemplateAction 
  extends FSA<SettingsActionTypes.ADD_ACTION_TEMPLATE, Payloads[SettingsActionTypes.ADD_ACTION_TEMPLATE]> {
  type: SettingsActionTypes.ADD_ACTION_TEMPLATE;
  payload: Payloads[SettingsActionTypes.ADD_ACTION_TEMPLATE];
}

export interface UpdateActionTemplateAction 
  extends FSA<SettingsActionTypes.UPDATE_ACTION_TEMPLATE, Payloads[SettingsActionTypes.UPDATE_ACTION_TEMPLATE]> {
  type: SettingsActionTypes.UPDATE_ACTION_TEMPLATE;
  payload: Payloads[SettingsActionTypes.UPDATE_ACTION_TEMPLATE];
}

export interface UpdateCompanyRequestAction 
  extends FSA<SettingsActionTypes.UPDATE_COMPANY_REQUEST, Payloads[SettingsActionTypes.UPDATE_COMPANY_REQUEST]> {
  type: SettingsActionTypes.UPDATE_COMPANY_REQUEST;
  payload: Payloads[SettingsActionTypes.UPDATE_COMPANY_REQUEST];
}

export interface UpdateAppSettingsRequestAction 
  extends FSA<
  SettingsActionTypes.UPDATE_APP_SETTINGS_REQUEST,
  Payloads[SettingsActionTypes.UPDATE_APP_SETTINGS_REQUEST]
  > {
  type: SettingsActionTypes.UPDATE_APP_SETTINGS_REQUEST;
  payload: Payloads[SettingsActionTypes.UPDATE_APP_SETTINGS_REQUEST];
}

function createAddActionTemplateRequest(payload: AddActionTemplateAction["payload"]) {
  return createRequest("/action/templates/create", {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function createUpdateActionTemplateRequest(payload: UpdateActionTemplateAction["payload"], templateId: string) {
  return createRequest(`/action/templates/update/${templateId}`, {
    method: "PUT",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function createActionTemplatesRequest() {
  return createRequest("/action/templates/all", {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function createIncludeActionTemplateRequest(templateId: string) {  
  return createRequest(`/action/templates/include/${templateId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function createExcludeActionTemplateRequest(templateId: string) {  
  return createRequest(`/action/templates/exclude/${templateId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function createRemoveActionTemplateRequest(templateId: string) {  
  return createRequest(`/action/templates/remove/${templateId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function createUpdateCompanyRequest(companyName: string, payload: UpdateCompanyRequestAction["payload"]) {
  return createRequest(`/companies/${companyName}`, {
    method: "PUT",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function createUpdateAppSettingsRequest(companyName: string, payload: UpdateAppSettingsRequestAction["payload"]) {
  return createRequest(`/companies/${companyName}/appSettings`, {
    method: "PUT",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export const createFetchCompanyRequest = (companyName: string) =>
  createRequest(`/companies/${companyName}`);

export const createFetchAppSettingsRequest = (companyName: string) =>
  createRequest(`/companies/${companyName}/appSettings`);

const createUploadCompanyLogoRequest = (companyName: string, image: any) => {
  const formData = new FormData();
  formData.append("logo", image);
  return createRequest("/companies/" + companyName + "/logo", {
    method: "POST",
    body: formData,
  });
};

const createUploadDarkLogoRequest = (companyName: string, image: any) => {
  const formData = new FormData();
  formData.append("darkLogo", image);
  return createRequest("/companies/" + companyName + "/darkLogo", {
    method: "POST",
    body: formData,
  });
};

const createUserNotificationRequest = (userId: string, notificationId: string) => {
  return createRequest(`/users/${userId}/notifications/${notificationId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const settingsActionCreators = {
  addActionTemplate: (
    actionTemplatePayload: AddActionTemplateAction["payload"]
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createAddActionTemplateRequest(actionTemplatePayload),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: SettingsActionTypes.ADD_ACTION_TEMPLATE_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ 
            type: SettingsActionTypes.ADD_ACTION_TEMPLATE_SUCCESS, 
            payload, 
            meta: {
              mode: "add",
            },
          }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: SettingsActionTypes.ADD_ACTION_TEMPLATE_ERROR, payload }),
      ],
    },
  }),
  updateActionTemplate: (
    actionTemplatePayload: UpdateActionTemplateAction["payload"],
    templateId: string
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createUpdateActionTemplateRequest(actionTemplatePayload, templateId),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: SettingsActionTypes.UPDATE_ACTION_TEMPLATE_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: SettingsActionTypes.UPDATE_ACTION_TEMPLATE_SUCCESS, payload, meta: {
            mode: "edit",
          } }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: SettingsActionTypes.UPDATE_ACTION_TEMPLATE_ERROR, payload }),
      ],
    },
  }),
  updateCompany: (
    companyName: string,
    updatePayload: UpdateCompanyRequestAction["payload"]
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createUpdateCompanyRequest(companyName, updatePayload),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: SettingsActionTypes.UPDATE_COMPANY_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({
            type: SettingsActionTypes.UPDATE_COMPANY_SUCCESS,
            payload,
          }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({
            type: SettingsActionTypes.UPDATE_COMPANY_ERROR,
            payload,
          }),
      ],
    },
  }),
  updateAppSettings: (
    companyName: string,
    updatePayload: UpdateAppSettingsRequestAction["payload"]
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createUpdateAppSettingsRequest(companyName, updatePayload),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: SettingsActionTypes.UPDATE_APP_SETTINGS_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({
            type: SettingsActionTypes.UPDATE_APP_SETTINGS_SUCCESS,
            payload,
          }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({
            type: SettingsActionTypes.UPDATE_APP_SETTINGS_ERROR,
            payload,
          }),
      ],
    },
  }),
  excludeActionTemplate: (
    templateId: string
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createExcludeActionTemplateRequest(templateId),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: SettingsActionTypes.EXCLUDE_ACTION_TEMPLATE_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({
            type: SettingsActionTypes.EXCLUDE_ACTION_TEMPLATE_SUCCESS,
            payload,
            meta: {
              mode: "exclude",
            },
          }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({
            type: SettingsActionTypes.EXCLUDE_ACTION_TEMPLATE_ERROR,
            payload,
          }),
      ],
    },
  }),
  includeActionTemplate: (
    templateId: string
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createIncludeActionTemplateRequest(templateId),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: SettingsActionTypes.INCLUDE_ACTION_TEMPLATE_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({
            type: SettingsActionTypes.INCLUDE_ACTION_TEMPLATE_SUCCESS,
            payload,
            meta: {
              mode: "include",
            },
          }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({
            type: SettingsActionTypes.INCLUDE_ACTION_TEMPLATE_ERROR,
            payload,
          }),
      ],
    },
  }),
  removeActionTemplate: (
    templateId: string
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createRemoveActionTemplateRequest(templateId),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: SettingsActionTypes.REMOVE_ACTION_TEMPLATE_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({
            type: SettingsActionTypes.EXCLUDE_ACTION_TEMPLATE_SUCCESS,
            payload,
            meta: {
              mode: "remove",
            },
          }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({
            type: SettingsActionTypes.EXCLUDE_ACTION_TEMPLATE_ERROR,
            payload,
          }),
      ],
    },
  }),
  fetchCompany: (companyName: string): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createFetchCompanyRequest(companyName),
  }),
  fetchAppSettings: (companyName: string): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createFetchAppSettingsRequest(companyName),
  }),
  uploadCompanyLogo: (companyName: string, image: any) => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createUploadCompanyLogoRequest(companyName, image),
  }),
  uploadDarkLogo: (companyName: string, image: any) => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createUploadDarkLogoRequest(companyName, image),
  }),
  fetchActionTemplates: (): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createActionTemplatesRequest(),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: SettingsActionTypes.FETCH_ALL_ACTION_TEMPLATES_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: SettingsActionTypes.FETCH_ALL_ACTION_TEMPLATES_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: SettingsActionTypes.FETCH_ALL_ACTION_TEMPLATES_ERROR, payload }),
      ],
    },
  }),
  updateUserNotifications: (userId: string, notificationId: string): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createUserNotificationRequest(userId, notificationId),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: SettingsActionTypes.UPDATE_USER_NOTIFICATION_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: SettingsActionTypes.UPDATE_USER_NOTIFICATION_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: SettingsActionTypes.UPDATE_USER_NOTIFICATION_ERROR, payload }),
      ],
    },
  }),
};
