import React from "react";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { Paragraph } from "@src/components/core/Typography";
import Modal from "@src/components/core/Modal";
import { SurveyTemplateResource } from "hyphen-lib/dist/domain/resource/SurveyTemplateResource";
import { ModalProps } from "antd/lib/modal";
import PreviewModalContent from "./PreviewModalContent";
import { Trans } from "react-i18next";

interface Props {
  isModalOpen: boolean;
  template: SurveyTemplateResource;
  onHideModal: () => void;
}

const PreviewModalComponent = styled(Modal)<ModalProps & {children: React.ReactNode}>`
  width: 740px !important;
`;

const Description = styled(Paragraph)`
  padding: 16px 24px 24px 32px;
  color: ${Palette.bluishGrey};
`;

export default function PreviewModal(props: Props) {
  const { isModalOpen, template, onHideModal } = props;
  return (
    <PreviewModalComponent
      footer={null}
      visible={isModalOpen}
      title={<><Trans>{template.name}</Trans> <Trans>survey template</Trans></>}
      centered={true}
      onCancel={onHideModal}>
      <Description>
        The <Trans>{template.name}</Trans> <Trans>survey template will include the following
        categories and questions:</Trans>
      </Description>
      <PreviewModalContent questions={template.posts} />
    </PreviewModalComponent>
  );
}
