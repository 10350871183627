import React from "react";
import styled from "styled-components";
import { ReturnSvg } from "@components/core/svg/ReturnSvg";
import Palette from "@src/config/theme/palette";
import { Trans } from "react-i18next";

export interface BreadcrumbItem {
  label: string;
  onClick: () => any;
}

export interface BreadcrumbProps {
  readonly items: BreadcrumbItem[];
}

export function Breadcrumb({ items }: BreadcrumbProps) {
  return (
    <BreadcrumbContainer>
      {
        items.map((item: BreadcrumbItem, idx: number) =>
          <BreadcrumbItemComponent key={"breadcrumb#" + idx} item={item}/>
        )
      }
    </BreadcrumbContainer>
  );
}

function BreadcrumbItemComponent({item, ...rest}: {item: BreadcrumbItem}) {
  return (
    <>
      <ReturnImgContainer>
        <ReturnSvg/>
      </ReturnImgContainer>
      <BreadcrumbLink onClick={item.onClick}>
        <Trans>{item.label}</Trans>
      </BreadcrumbLink>
    </>
  );
}

const BreadcrumbContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const ReturnImgContainer = styled.div`
  margin-right: 8px;
  height: 20px;
`;

const BreadcrumbLink = styled.div`
  margin-right: 8px;
  cursor: pointer;
  height: 20px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: ${Palette.bluishGrey}
`;
