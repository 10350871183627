import { NetworkEventErrorAction } from "@src/store/network/actions";
import { FSA } from "flux-standard-action";
import { Survey } from "hyphen-lib/dist/domain/Survey";
import { Record, RecordOf } from "immutable";
import { XLSXActionTypes } from "./actions";

interface XLSXStateProps {
  isDownloadingTemplate: boolean;
  isUploadingTemplate: boolean;
  isUploadSuccess: boolean;
  uploadResponse?: Survey.UploadSurveyTemplateResponse;
}

const defaultXLSXStateValues: XLSXStateProps = {
  isDownloadingTemplate: false,
  isUploadingTemplate: false,
  isUploadSuccess: false,
  uploadResponse: {
    totalError: -1,
    errorFileId: "",
    addedTranslations: [],
    totalSurveyLanguages: [],
    translationFileId:""
  },
};

export const xlsxStateFactory = Record<XLSXStateProps>(defaultXLSXStateValues);
const defaultXlsxState = xlsxStateFactory();

export type XLSXState = RecordOf<XLSXStateProps>;

interface Payloads {
  [XLSXActionTypes.DOWNLOAD_XLSX_TEMPLATE]: {};
  [XLSXActionTypes.DOWNLOAD_XLSX_TEMPLATE_FAILED]: {};
  [XLSXActionTypes.DOWNLOAD_XLSX_TEMPLATE_SUCCESS]: {};
  [XLSXActionTypes.UPLOAD_XLSX_FAILED]: {};
}

export interface DownloadTemplate
  extends FSA<
    XLSXActionTypes.DOWNLOAD_XLSX_TEMPLATE,
    Payloads[XLSXActionTypes.DOWNLOAD_XLSX_TEMPLATE]
  > {
  type: XLSXActionTypes.DOWNLOAD_XLSX_TEMPLATE;
  payload: NetworkEventErrorAction["payload"];
}

export interface DownloadTemplateFailed
  extends FSA<
    XLSXActionTypes.DOWNLOAD_XLSX_TEMPLATE_FAILED,
    Payloads[XLSXActionTypes.DOWNLOAD_XLSX_TEMPLATE_FAILED]
  > {
  type: XLSXActionTypes.DOWNLOAD_XLSX_TEMPLATE_FAILED;
  payload: NetworkEventErrorAction["payload"];
}

export interface DownloadTemplateSuccess
  extends FSA<
    XLSXActionTypes.DOWNLOAD_XLSX_TEMPLATE_SUCCESS,
    Payloads[XLSXActionTypes.DOWNLOAD_XLSX_TEMPLATE_SUCCESS]
  > {
  type: XLSXActionTypes.DOWNLOAD_XLSX_TEMPLATE_SUCCESS;
  payload: NetworkEventErrorAction["payload"];
}

export interface UploadLanguageFailure
  extends FSA<
    XLSXActionTypes.UPLOAD_XLSX_FAILED,
    Payloads[XLSXActionTypes.UPLOAD_XLSX_FAILED]
  > {
  type: XLSXActionTypes.UPLOAD_XLSX_FAILED;
  payload: NetworkEventErrorAction["payload"];
}

type Actions =
  | DownloadTemplate
  | DownloadTemplateFailed
  | DownloadTemplateSuccess
  | UploadLanguageFailure
  | any;

export function xlsxReducers(
  state: XLSXState = defaultXlsxState,
  action: Actions
): XLSXState {
  switch (action.type) {
    case XLSXActionTypes.DOWNLOAD_XLSX_TEMPLATE:
      return state.set("isDownloadingTemplate", true);
    case XLSXActionTypes.DOWNLOAD_XLSX_TEMPLATE_FAILED:
      return state.set("isDownloadingTemplate", false);
    case XLSXActionTypes.DOWNLOAD_XLSX_TEMPLATE_SUCCESS:
      return state.set("isDownloadingTemplate", false);
    case XLSXActionTypes.UPLOAD_XLSX:
      return state
      .set("isUploadingTemplate", true)
      .set("isUploadSuccess", false);
    case XLSXActionTypes.UPLOAD_XLSX_SUCCESS:
      return state
        .set("isUploadingTemplate", false)
        .set("isUploadSuccess", true)
        .set("uploadResponse", action.payload.data);
    case XLSXActionTypes.UPLOAD_XLSX_FAILED:
      return state
        .set("isUploadingTemplate", false)
        .set("isUploadSuccess", false);
    default:
      return state;
  }
}
