import { actionPlansActionCreators } from "./../../ActionPlans/store/actions";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import {
  actionListActionCreators,
  ActionListActionTypes,
  focusAreaListActionCreators,
  FocusAreaListActionTypes,
  ModifyListAction
} from "@screens/Insights/Actions/store/actions";
import { call, delay, put, select, takeLatest } from "@redux-saga/core/effects";
import { ActionResource } from "hyphen-lib/dist/domain/resource/action/ActionResource";
import notificationActionCreators, { ShowNotificationAction } from "@store/notifications/actions";
import { freeze, getOr, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import * as NotificationFactory from "@src/store/notifications/notification-factory";
import {
  actionCreators as networkActionCreators,
  CleanResourceAction,
  NetworkEventSuccessAction,
  NetworkRequestAction,
  NetworkEventErrorAction
} from "@store/network/actions";
import { goTo, pushLocation, replaceLocation } from "@src/utils/locations";
import { ActionPlanTemplateResource } from "hyphen-lib/dist/domain/resource/action/ActionPlanTemplateResource";
import { getActionPlansState } from "../../ActionPlans/store/selectors";
import { getActionListStateProps } from "./selectors";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { bottomDrawerActions } from "../../BottomDrawer/actions";
import { FocusAreaNotificationBar } from "./reducers";

export function* updateLocationUrl(replace: boolean,
  { payload: { filter, sort, page } }: ModifyListAction) {
  const locationUpdater = replace ? replaceLocation : pushLocation;
  yield call(
    locationUpdater, {
      filter,
      sort,
      page: page.number,
    });
}

export function* resetActionResources() {
  yield put<CleanResourceAction>
  (networkActionCreators.cleanResource(ActionResource.TYPE));
}

export function* resetFocusAreaResources() {
  yield put<CleanResourceAction>
  (networkActionCreators.cleanResource(FocusAreaResource.TYPE));
}

function* updateAction({ actionId }: any) {
  const { actionPlan } = yield select(getActionPlansState);
  
  function* callActionAndNotify () {
    yield put<ShowNotificationAction>(
      notificationActionCreators.displayNotification(
        NotificationFactory.success(
          "Action updated successfully",
          "",
          4.5
        )
    ));
  }

  yield put(
    actionListActionCreators.updateActionRequest(actionId, actionPlan, {
      onSuccessRedirect: callActionAndNotify,
    })
  );
}

function* cleanActionPlan() { 
  yield put<CleanResourceAction>
  (networkActionCreators.cleanResource(ActionResource.TYPE));
  yield put(actionPlansActionCreators.cleanActionPlan());
}

export function* deleteAction({ redirectPath }: any) {
  // Get the selectedSurveyResource:
  const { action } = yield select(getActionListStateProps);
  const actionId = action._id;
  const successCallback = function* () {
    if (isNotNullNorUndefined(redirectPath)) {
      yield call(
        goTo,
        redirectPath
      );
    }
    yield put<ShowNotificationAction>(
      notificationActionCreators.displayNotification(
        NotificationFactory.success(
          "Action deleted successfully",
          "",
          4.5
        )
    ));
  };
  yield put<NetworkRequestAction>(
    actionListActionCreators.deleteAction(actionId, successCallback)
  );
}

export function* errorAction() {

  yield put<ShowNotificationAction>(
    notificationActionCreators.displayNotification(
      NotificationFactory.error(
        "Error occurred while trying to delete the Action",
        "",
        4.5
      )
  ));
}

function* updateSuccessHandler({ meta, payload }: NetworkEventSuccessAction) {
  if (isNotNullNorUndefined(meta)) {
    const { statusAction, redirectPath } = meta;
    if (isNotNullNorUndefined(statusAction)) {
      let successMessage = "";

      if (statusAction === "completeAction") {
        successMessage = "Action marked as complete";
        yield put<CleanResourceAction>
        (networkActionCreators.cleanResource(ActionPlanTemplateResource.TYPE));
      } else if (statusAction === "in_progress") {
        successMessage = "Action set to in-progress";
      } else if (statusAction === "dismissAction") {
        successMessage = "Action set to dismissed";
      }

      yield put<ShowNotificationAction>(
        notificationActionCreators.displayNotification(
          NotificationFactory.success(
            successMessage,
            "",
            4.5
          )
      ));
    }

    if (isNotNullNorUndefined(redirectPath)) {
      yield call(
        goTo,
        redirectPath
      );
    }
  }
  yield put(
    actionPlansActionCreators.updateActionPlanSettings(payload.data)
  );
}

function* cleanFocusAreaResource(networkResponse: any) {
  const meta = networkResponse?.payload?.data?.meta;
  if(meta?.totalFocusAreas > 30) {
    yield put<ShowNotificationAction>(
      notificationActionCreators.displayNotification(
        NotificationFactory.success(
          "Focus area added, go to the Action Center to see it",
          "",
          4.5
        )
    ));
  }
  yield put<CleanResourceAction>
  (networkActionCreators.cleanResource(FocusAreaResource.TYPE));

  yield put(bottomDrawerActions.maximize());
}

function* onShowFocusAreaNotificationBar(actionResponse: any) {
  const {payload} = actionResponse as {payload: FocusAreaNotificationBar};
  if(payload.show) {
    yield put<ShowNotificationAction>(
      notificationActionCreators.displayNotification(
        NotificationFactory.success(
          getOr(payload.message, ""),
          "",
          4.5
        )
    ));
  }
}

export function* errorNotify({payload}: NetworkEventErrorAction) {
  let message = "Error occurred while trying to update the Action.";

  /* fixme: this is a temporary fix. It needs to be taken out when proper error handling based on 
    error codes is implemented
  */
  if (isNotNullNorUndefined(payload.error) && isNotEmptyArray(payload.error)) {
    const { errorCode } = payload.error[0];
    if (errorCode === 10520) {
      message = message + " Assignee value does not lie within the range of allowed values for the user";
    }
  }
  yield put<ShowNotificationAction>(
    notificationActionCreators.displayNotification(
      NotificationFactory.error(
        message,
        "",
        4.5
      )
  ));
}

function* debounceCreateFocusArea(data: any) {
  yield delay(500);
  yield put(focusAreaListActionCreators.createFocusAreaDebounced(data.payload));
}

export const actionListSagas = freeze([

  // ~~~~~~~~~~~~~ NETWORK ~~~~~~~~~~~~~~~~

  /*
    Listen the latest "fetch if needed" action in order to dispatch a raw page fetch if the raw page
    is not already loading.
   */
  takeLatest(
    ActionListActionTypes.DELETE_ACTION,
    deleteAction
  ),

  takeLatest(
    ActionListActionTypes.DELETE_ACTION_SUCCESS,
    resetActionResources
  ),

  takeLatest(
    ActionListActionTypes.DELETE_ACTION_ERROR,
    errorAction
  ),

  takeLatest(
    ActionListActionTypes.CLEAN_ACTION_PLAN_RESOURCE,
    cleanActionPlan
  ),

  // ~~~~~~~~~~~~~ URL MANAGEMENT ~~~~~~~~~~~~~~~~

  /*
    Listen for modification of list, in order to push the new state in the URL.
   */
  takeLatest(
    [ActionListActionTypes.MODIFY_LIST, FocusAreaListActionTypes.MODIFY_LIST],
    updateLocationUrl.bind(null, false)
  ),

  takeLatest(
    [ActionListActionTypes.UPDATE_ACTION],
    updateAction
  ),

  takeLatest(
    ActionListActionTypes.UPDATE_ACTION_SUCCESS,
    updateSuccessHandler
  ),

  takeLatest(
    ActionListActionTypes.UPDATE_ACTION_ERROR,
    errorNotify
  ),
]);

export const focusAreaListSagas = freeze([
  takeLatest(
    FocusAreaListActionTypes.DELETE_FOCUS_AREA_SUCCESS,
    resetFocusAreaResources
  ),
  takeLatest(
    [
      FocusAreaListActionTypes.CREATE_FOCUS_AREA_SUCCESS,
      FocusAreaListActionTypes.CLEAN_FOCUS_AREA_RESOURCE,
    ],
    cleanFocusAreaResource
  ),
  takeLatest(
    FocusAreaListActionTypes.CREATE_FOCUS_AREA_REQUEST,
    debounceCreateFocusArea
  ),
  takeLatest(
    FocusAreaListActionTypes.SHOW_NOTIFICATION_BAR_FOCUS_AREA,
    onShowFocusAreaNotificationBar
  )
]);


