import React from "react";
import { RadioChangeEvent } from "antd/lib/radio";
import { RouteComponentProps, withRouter } from "react-router";
import { bindActionCreators, Dispatch } from "redux";
import { connect, MapStateToProps } from "react-redux";
import queryString from "query-string";

import { SurveyTemplateResource } from "hyphen-lib/dist/domain/resource/SurveyTemplateResource";

import { State } from "@src/store/types";
import { NetworkEventSuccessAction } from "@src/store/network/actions";
import { isNullOrUndefined } from "hyphen-lib/dist/lang/Objects";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import CreationLayout from "@src/components/layouts/CreationLayout";
import { NextButton } from "@screens/Insights/components/Wizard/layout/NextButton";
import { ActionContainer } from "@screens/Insights/components/Wizard/layout/ActionContainer";
import { CreateSurveyStateProps, FetchSurveyTemplatesStateProps, SurveyInfosProps } from "../store/types";
import * as selectors from "../store/selectors";
import actionCreators from "../store/actions";
import PreviewModal from "../components/PreviewModal";
import { default as SurveyTypeSelection } from "../components/SurveyTypeSelection";

export type OwnProps = RouteComponentProps;
export interface StateProps {
  templates: SurveyTemplateResource[];
  fetchSurveyTemplatesState: FetchSurveyTemplatesStateProps;
  createSurvey: CreateSurveyStateProps;
  surveyInfosState: SurveyInfosProps;
}

export type Props = {
  dispatch: Dispatch;
} & OwnProps & StateProps;

interface QueryParams {
  mode?: "scratch" | "templates" | "duplicate";
  selectedTemplateId?: string;
  isPreviewModalVisible?: "true";
  duplicateSurveyId?: string;
  linkDuplicate?: boolean;
}

class GetStarted extends React.Component<Props> {
  boundActionCreators: typeof actionCreators;

  constructor(props: Props) {
    super(props);

    const { dispatch, history, location } = props;
    const queryParams: QueryParams = queryString.parse(location.search);
    if (isNullOrUndefined(queryParams.mode)) {
      queryParams.mode = "templates";
      const queryParamsAsString = queryString.stringify(queryParams);
      history.replace({
        search: "?" + new URLSearchParams(queryParamsAsString),
      });
    }

    this.boundActionCreators = bindActionCreators(actionCreators, dispatch);
  }

  componentDidMount() {
    this.boundActionCreators.fetchSurveyTemplates();
    this.boundActionCreators.fetchSurveyInfos(["launched", "closed"], "name");
  }

  onRadioChange = (e: RadioChangeEvent) => {
    const { history, location } = this.props;
    const mode: string = e.target.value;
    const queryParams: QueryParams = queryString.parse(location.search);

    if (mode === "templates" || mode === "scratch" || mode === "duplicate") {
      queryParams.mode = mode;
      if (mode === "scratch") {
        delete queryParams.selectedTemplateId;
        delete queryParams.isPreviewModalVisible;
        delete queryParams.duplicateSurveyId;
        delete queryParams.linkDuplicate;
      } else if (mode === "duplicate") {
        delete queryParams.selectedTemplateId;
        delete queryParams.isPreviewModalVisible;
      } else if (mode === "templates") {
        delete queryParams.duplicateSurveyId;
        delete queryParams.linkDuplicate;
      }

      const queryParamsAsString = queryString.stringify(queryParams);
      history.push({
        search: "?" + new URLSearchParams(queryParamsAsString),
      });
    }
  };

  onSelectTemplate = (selectedTemplateId?: string) => {
    const { history, location } = this.props;
    const queryParams: QueryParams = queryString.parse(location.search);

    if (selectedTemplateId) {
      queryParams.selectedTemplateId = selectedTemplateId;
      delete queryParams.isPreviewModalVisible;
    }

    const queryParamsAsString = queryString.stringify(queryParams);
    history.replace({
      search: "?" + new URLSearchParams(queryParamsAsString),
    });
  };

  onSelectDuplicateChange = (duplicateSurveyId?: string) => {
    const { history, location } = this.props;
    const queryParams: QueryParams = queryString.parse(location.search);

    if (duplicateSurveyId) {
      queryParams.duplicateSurveyId = duplicateSurveyId;
      delete queryParams.isPreviewModalVisible;
      delete queryParams.selectedTemplateId;
    }
    const queryParamsAsString = queryString.stringify(queryParams);
    history.replace({
      search: "?" + new URLSearchParams(queryParamsAsString),
    });
  };

  onLinkDuplicateChange = (e: CheckboxChangeEvent) => {
    const { history, location } = this.props;
    const queryParams: QueryParams = queryString.parse(location.search);

    queryParams.linkDuplicate = e.target.checked;
    const queryParamsAsString = queryString.stringify(queryParams);
    history.replace({
      search: "?" + new URLSearchParams(queryParamsAsString),
    });
  };

  onShowModal = (selectedTemplateId: string) => {
    const { history, location } = this.props;
    const queryParams: QueryParams = queryString.parse(location.search);

    queryParams.selectedTemplateId = selectedTemplateId;
    queryParams.isPreviewModalVisible = "true";

    const queryParamsAsString = queryString.stringify(queryParams);
    history.push({
      search: "?" + new URLSearchParams(queryParamsAsString),
    });
  };

  onHideModal = () => {
    const { history, location } = this.props;
    const queryParams: QueryParams = queryString.parse(location.search);

    delete queryParams.isPreviewModalVisible;

    const queryParamsAsString = queryString.stringify(queryParams);
    history.push({
      search: "?" + new URLSearchParams(queryParamsAsString),
    });
  };

  onNext = () => {
    const { location } = this.props;
    const queryParams: QueryParams = queryString.parse(location.search);

    this.boundActionCreators.createSurvey(queryParams.selectedTemplateId,
      queryParams.duplicateSurveyId, queryParams.linkDuplicate, this.onSurveyCreationRedirect);
  };

  onSurveyCreationRedirect = (payload: NetworkEventSuccessAction["payload"]) => {
    const { data } = payload;
    if (data) {
      this.props.history.push(`/surveys/edit/${data._id}/settings`);
    }
  };

  showSurveyLists = () => {
    this.props.history.push("/surveys");
  };

  render() {
    const {
      templates,
      location,
      fetchSurveyTemplatesState,
      surveyInfosState,
    } = this.props;

    const allSurveys = surveyInfosState.surveyNameInfos;
    const loadingSurveyInfos = allSurveys.length === 0 && surveyInfosState.isLoading;

    const queryParams: QueryParams = queryString.parse(location.search);
    const selectedTemplate = queryParams.selectedTemplateId ?
      templates.find(value => value._id === queryParams.selectedTemplateId) : null;

    let buttonDisabled = false;
    if (queryParams.mode === "templates" && !queryParams.selectedTemplateId) {
      buttonDisabled = true;
    } else if (queryParams.mode === "duplicate" && !queryParams.duplicateSurveyId) {
      buttonDisabled = true;
    }

    let isLinkDuplicate;
    // @ts-ignore
    if (queryParams.linkDuplicate === true || queryParams.linkDuplicate === "true") {
      isLinkDuplicate = true;
    }else {
      isLinkDuplicate = false;
    }
    return (
      <CreationLayout
        title="Create a new survey"
        onCloseIconClick={this.showSurveyLists}
      >
        <SurveyTypeSelection
          templates={templates}
          onRadioChange={this.onRadioChange}
          mode={queryParams.mode || "scratch"}
          selectedTemplateId={queryParams.selectedTemplateId}
          onSelectTemplate={this.onSelectTemplate}
          duplicateSurveyId={queryParams.duplicateSurveyId}
          linkDuplicate={isLinkDuplicate || false}
          onLinkDuplicateChange={this.onLinkDuplicateChange}
          onSelectDuplicateChange={this.onSelectDuplicateChange}
          onShowModal={this.onShowModal}
          isTemplatesLoading={fetchSurveyTemplatesState.isLoading}
          allSurveys={allSurveys}
          loadingAllSurveys={loadingSurveyInfos}
        />
        <ActionContainer>
          <NextButton
            disabled={buttonDisabled}
            nextStep="Settings"
            onClick={this.onNext} />
        </ActionContainer>
        {selectedTemplate &&
          <PreviewModal
            isModalOpen={queryParams.isPreviewModalVisible === "true"}
            onHideModal={this.onHideModal}
            template={selectedTemplate}
          />
        }
      </CreationLayout>
    );
  }
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State): StateProps => ({
  templates: selectors.getTemplates(state),
  fetchSurveyTemplatesState: selectors.fetchSurveyTemplates(state),
  createSurvey: selectors.createSurvey(state),
  surveyInfosState: selectors.surveyInfosState(state),
});

export default withRouter(connect(mapStateToProps)(GetStarted));
