import { Config } from "@hyphen-lib/util/Config";
import { Dictionary } from "@hyphen-lib/domain/structure/Dictionary";
import { Optional } from "@hyphen-lib/lang/Optionals";
import {isNotNullNorUndefined, entries, isNotEmptyObject} from "@hyphen-lib/lang/Objects";
import { loadSessionToken } from "@src/utils/sessionStores";
import { eventChannel, END, buffers } from "redux-saga";

/**
 * Creates a request, it will prefix the URL with the backend base URL.
 *
 * @param {string} input
 * @param {RequestInit} init
 * @return {Request}
 */
export function createRequest(input: string, init?: RequestInit): Request {
  const config = Config.getInstance();
  const backendURLPrefix = config.get("BACKEND_BASE_URL");
  return new Request(
    backendURLPrefix + input,
    init
  );
}

export type SortParameter = Dictionary<1 | -1>;

export type FilterParameter = Dictionary<any>;

export type ViewOptionParameter = Dictionary<any>;

/**
 * Serialize the sort object.
 *
 * ```
 * {foo: 1, bar: -1}
 * ```
 * will be transformed into "foo,-bar"
 *
 * @param {Optional<SortParameter>} sort
 * @return {Optional<string>}
 */
export function serializeSort(sort: Optional<SortParameter>): Optional<string> {
  if (isNotNullNorUndefined(sort) && isNotEmptyObject(sort)) {
    return entries(sort)
      .map((direction: 1 | -1, key: string) => (direction === -1 ? "-" : "") + key)
      .join(",");
  }
}

export function getAssetUrl(assetId: string) {
  const config = Config.getInstance();
  const backendURLPrefix = config.get("BACKEND_BASE_URL");

  return backendURLPrefix + "/assets/" + assetId;
}

export const ALL_PAGE_ID = "all";
export const CURRENT = "current";

export function createUploadFileChannel(endpoint: string, file: any) {
  return eventChannel(emitter => {
    const xhr = new XMLHttpRequest();
    const onProgress = (e: ProgressEvent) => {
      if (e.lengthComputable) {
        const progress = e.loaded / e.total;
        emitter({ progress });
      }
    };
    const onFailure = () => {
      emitter({ err: new Error("Upload failed") });
      emitter(END);
    };
    // const onSuccess = (e: SuccessEvent)
    xhr.upload.addEventListener("progress", onProgress);
    xhr.upload.addEventListener("error", onFailure);
    xhr.upload.addEventListener("abort", onFailure);
    xhr.onreadystatechange = () => {
      const { readyState, status, response } = xhr;
      if (readyState === 4) {
        if (status === 200 || status === 202) {
          emitter({ success: true, response: JSON.parse(response) });
          emitter(END);
        }
      }
    };

    const config = Config.getInstance();
    const backendURLPrefix = config.get("BACKEND_BASE_URL");

    xhr.open("POST", backendURLPrefix + endpoint, true);
    const token = loadSessionToken();
    if (isNotNullNorUndefined(token)) {
      xhr.setRequestHeader("x-session-token", token);
    }

    const formData = new FormData();
    formData.append("csv", file);
    xhr.send(formData);

    return () => {
      xhr.upload.removeEventListener("progress", onProgress);
      xhr.upload.removeEventListener("error", onFailure);
      xhr.upload.removeEventListener("abort", onFailure);
      xhr.onreadystatechange = null;
      xhr.abort();
    };
  }, buffers.sliding(2));
}
