import React, { Component } from "react";
import styled from "styled-components";

import { InfoItem } from "./InfoItem";

interface ItemData {
  label: string;
  value: string | number;
}
interface CardSectionProps {
  readonly data: ItemData[];
}

export class CardSection extends Component<CardSectionProps> {
  render() {
    const { data } = this.props;

    const infoItems = data.map((itemData: ItemData, index: number) => {
      return (
        <InfoItem key={index} data-cy={`cardSection_infoItem${itemData.label}`}
          label={itemData.label}
        >{itemData.value}</InfoItem>
      );
    });
    return (
      <StyledCardSection>
        {infoItems}
      </StyledCardSection>
    );
  }
}

export const StyledCardSection = styled.div`
  margin-top: 12px !important;
`; 