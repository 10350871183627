import { FSA } from "flux-standard-action";
import { createRequest, FilterParameter, SortParameter } from "@src/utils/networks";
import {
  ActionTypes as NetworkActionTypes,
  NetworkEventErrorAction,
  NetworkEventRequestAction,
  NetworkEventSuccessAction,
  NetworkRequestAction
} from "@store/network/actions";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { UserPermissionResource } from "hyphen-lib/dist/domain/resource/user/UserPermissionResource";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { SelectionFilter } from "hyphen-lib/dist/domain/parameter/SelectionFilter";

export enum UserPermissionsActionTypes {
  MODIFY_PERMISSIONS_LIST = "userPermissions/MODIFY_PERMISSIONS_LIST",

  UPDATE_USER_PERMISSION = "userPermissions/UPDATE_USER_PERMISSION",
  UPDATE_USER_PERMISSION_REQUEST = "userPermissions/UPDATE_USER_PERMISSION_REQUEST",
  UPDATE_USER_PERMISSION_SUCCESS = "userPermissions/UPDATE_USER_PERMISSION_SUCCESS",
  UPDATE_USER_PERMISSION_ERROR = "userPermissions/UPDATE_USER_PERMISSION_ERROR",
  TOGGLE_ARE_YOU_SURE_MODAL_VISIBILITY = "userPermissions/TOGGLE_ARE_YOU_SURE_MODAL_VISIBILITY",
  TOGGLE_MODAL_VISIBILITY = "userPermissions/TOGGLE_MODAL_VISIBILITY",

  UPDATE_USER_PERMISSIONS = "userPermissions/UPDATE_USER_PERMISSIONS",
  SELECT_UNSELECT_USERS = "userPermissions/SELECT_UNSELECT_USERS",
  SELECT_ROOT_USER = "userPermissions/SELECT_ROOT_USER",
  UPDATE_USER_PERMISSIONS_REQUEST = "userPermissions/UPDATE_USER_PERMISSIONS_REQUEST",
  UPDATE_USER_PERMISSIONS_SUCCESS = "userPermissions/UPDATE_USER_PERMISSIONS_SUCCESS",
  UPDATE_USER_PERMISSIONS_ERROR = "userPermissions/UPDATE_USER_PERMISSIONS_ERROR",
}

interface Payloads {
  [UserPermissionsActionTypes.MODIFY_PERMISSIONS_LIST]: PermissionsPageParameters;
  [UserPermissionsActionTypes.UPDATE_USER_PERMISSION_REQUEST]: Request;
  [UserPermissionsActionTypes.UPDATE_USER_PERMISSION_SUCCESS]: Response;
  [UserPermissionsActionTypes.UPDATE_USER_PERMISSION_ERROR]: Error;
  [UserPermissionsActionTypes.TOGGLE_ARE_YOU_SURE_MODAL_VISIBILITY]: boolean;
  [UserPermissionsActionTypes.TOGGLE_MODAL_VISIBILITY]: boolean;
  [UserPermissionsActionTypes.UPDATE_USER_PERMISSIONS]: UpdateUserPermissionsPayload;
  [UserPermissionsActionTypes.SELECT_UNSELECT_USERS]: SelectUsersPayload;
}

export interface ModifyPermissionsListAction
  extends FSA<
  UserPermissionsActionTypes.MODIFY_PERMISSIONS_LIST,
  Payloads[UserPermissionsActionTypes.MODIFY_PERMISSIONS_LIST]
  > {
  type: UserPermissionsActionTypes.MODIFY_PERMISSIONS_LIST;
  payload: Payloads[UserPermissionsActionTypes.MODIFY_PERMISSIONS_LIST];
}

export interface UpdateUserPermissionsRequestAction
  extends FSA<
  UserPermissionsActionTypes.UPDATE_USER_PERMISSION_REQUEST,
  Payloads[UserPermissionsActionTypes.UPDATE_USER_PERMISSION_REQUEST]
  > {
  type: UserPermissionsActionTypes.UPDATE_USER_PERMISSION_REQUEST;
  payload: Payloads[UserPermissionsActionTypes.UPDATE_USER_PERMISSION_REQUEST];
}

export interface UpdateUserPermissionsSuccessAction
  extends FSA<
  UserPermissionsActionTypes.UPDATE_USER_PERMISSION_SUCCESS,
  Payloads[UserPermissionsActionTypes.UPDATE_USER_PERMISSION_SUCCESS]
  > {
  type: UserPermissionsActionTypes.UPDATE_USER_PERMISSION_SUCCESS;
  payload: Payloads[UserPermissionsActionTypes.UPDATE_USER_PERMISSION_SUCCESS];
}

export interface UpdateUserPermissionsErrorAction
  extends FSA<
  UserPermissionsActionTypes.UPDATE_USER_PERMISSION_ERROR,
  Payloads[UserPermissionsActionTypes.UPDATE_USER_PERMISSION_ERROR]
  > {
  type: UserPermissionsActionTypes.UPDATE_USER_PERMISSION_ERROR;
  payload: Payloads[UserPermissionsActionTypes.UPDATE_USER_PERMISSION_ERROR];
}

export interface ToggleModalVisibilityAction
  extends FSA<
  UserPermissionsActionTypes.TOGGLE_MODAL_VISIBILITY,
  Payloads[UserPermissionsActionTypes.TOGGLE_MODAL_VISIBILITY]
  > {
  type: UserPermissionsActionTypes.TOGGLE_MODAL_VISIBILITY;
  visible: Payloads[UserPermissionsActionTypes.TOGGLE_MODAL_VISIBILITY];
}

export interface UpdateUserPermissionsAction
  extends FSA<
  UserPermissionsActionTypes.UPDATE_USER_PERMISSIONS,
  Payloads[UserPermissionsActionTypes.UPDATE_USER_PERMISSIONS]
  > {
  type: UserPermissionsActionTypes.UPDATE_USER_PERMISSIONS;
  payload: Payloads[UserPermissionsActionTypes.UPDATE_USER_PERMISSIONS];
}

export interface SelectUnselectAction
  extends FSA<
  UserPermissionsActionTypes.SELECT_UNSELECT_USERS,
  Payloads[UserPermissionsActionTypes.SELECT_UNSELECT_USERS]
  > {
  type: UserPermissionsActionTypes.SELECT_UNSELECT_USERS;
  payload: Payloads[UserPermissionsActionTypes.SELECT_UNSELECT_USERS];
}

export interface AreYouSureModalActionAction
  extends FSA<
  UserPermissionsActionTypes.TOGGLE_ARE_YOU_SURE_MODAL_VISIBILITY,
  Payloads[UserPermissionsActionTypes.TOGGLE_ARE_YOU_SURE_MODAL_VISIBILITY]
  > {
  type: UserPermissionsActionTypes.TOGGLE_ARE_YOU_SURE_MODAL_VISIBILITY;
  isAreYouSureModalVisible: Payloads[UserPermissionsActionTypes.TOGGLE_ARE_YOU_SURE_MODAL_VISIBILITY];
}

export interface PermissionsPageParameters {
  readonly filter?: FilterParameter;
  readonly sort?: SortParameter;
  readonly page: PageFilter;
}

export interface UpdateUserPermissionsPayload {
  readonly modifications: {
    readonly hasInsightsAccess?: boolean;
    readonly role?: string;
  };
  readonly selection: Dictionary<any>;
}

export interface SelectUsersPayload {
  selectedRowKeys?: string[];
  tracker?: SelectionFilter.Tracker;
}

export const updateUserPermissionRequest = (userId: string, user?: UserPermissionResource) => {
  return createRequest(`/permissions/${userId}`, {
    method: "PUT",
    body: JSON.stringify(user),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export function createBulkUpdateRequest(payload: UpdateUserPermissionsPayload): Request {
  return createRequest("/users/bulk", {
    method: "PUT",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export const userPermissionsActionCreators = {
  modifyPermissionsList: (payload: ModifyPermissionsListAction["payload"]): ModifyPermissionsListAction => ({
    type: UserPermissionsActionTypes.MODIFY_PERMISSIONS_LIST,
    payload,
  }),
  updateUserPermissionsRequest: (bulkPayload: UpdateUserPermissionsAction["payload"]): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createBulkUpdateRequest(bulkPayload),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: UserPermissionsActionTypes.UPDATE_USER_PERMISSIONS_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: UserPermissionsActionTypes.UPDATE_USER_PERMISSIONS_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: UserPermissionsActionTypes.UPDATE_USER_PERMISSIONS_ERROR, payload }),
      ],
    },
  }),
  updateUserPermissions: (
    userId: string,
    user: UserPermissionResource,
    onSuccessRedirect?: (payload: NetworkEventSuccessAction["payload"]) => void
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: updateUserPermissionRequest(userId, user),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: UserPermissionsActionTypes.UPDATE_USER_PERMISSION_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: UserPermissionsActionTypes.UPDATE_USER_PERMISSION_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: UserPermissionsActionTypes.UPDATE_USER_PERMISSION_ERROR, payload }),
      ],
      onSuccessRedirect,
    },
  }),
  toggleAreYouSureModalVisibility: (isAreYouSureModalVisible: boolean) => ({
    type: UserPermissionsActionTypes.TOGGLE_ARE_YOU_SURE_MODAL_VISIBILITY,
    isAreYouSureModalVisible,
  }),
  toggleModalVisibility: (visible: boolean): ToggleModalVisibilityAction => ({
    type: UserPermissionsActionTypes.TOGGLE_MODAL_VISIBILITY,
    visible,
  }),
  updateUsers: (payload: UpdateUserPermissionsAction["payload"]) => ({
    type: UserPermissionsActionTypes.UPDATE_USER_PERMISSIONS,
    payload,
  }),
  onSelectUsers: (payload: SelectUnselectAction["payload"]) => ({
    type: UserPermissionsActionTypes.SELECT_UNSELECT_USERS,
    payload,
  }),
  onRootUserSelect: (email: string) => ({
    type: UserPermissionsActionTypes.SELECT_ROOT_USER,
    email
  })
};
