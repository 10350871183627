import {
  ActionTypes as NetworkActionTypes,
  NetworkEventErrorAction,
  NetworkEventRequestAction,
  NetworkEventSuccessAction,
  NetworkRequestAction
} from "@store/network/actions";
import { createRequest } from "@src/utils/networks";
import { FSA } from "flux-standard-action";
import { Config } from "@hyphen-lib/util/Config";

export enum ActionTypes {
  FETCH_SESSION_REQUEST = "FETCH_SESSION_REQUEST",
  FETCH_SESSION_SUCCESS = "FETCH_SESSION_SUCCESS",
  FETCH_SESSION_ERROR = "FETCH_SESSION_ERROR",

  FETCH_CURRENT_USER_REQUEST = "FETCH_CURRENT_USER_REQUEST",
  FETCH_CURRENT_USER_SUCCESS = "FETCH_CURRENT_USER_SUCCESS",
  FETCH_CURRENT_USER_ERROR = "FETCH_CURRENT_USER_ERROR",

  LOGIN_USER = "login/LOGIN_USER",

  SEND_EMAIL_REQUEST = "login/LOGIN_SEND_EMAIL",
  SEND_EMAIL_SUCCESS = "login/LOGIN_SEND_SUCCESS",
  SEND_EMAIL_ERROR = "login/LOGIN_SEND_ERROR",

  SEND_CODE_SUCCESS = "login/LOGIN_CODE_SUCCESS",
  SEND_CODE_REQUEST = "login/LOGIN_CODE_REQUEST",
  SEND_CODE_ERROR = "login/LOGIN_CODE_ERROR",

  LOGIN_STATUS_REFRESH = "login/LOGIN_STATUS_REFRESH",

  LOGOUT_USER = "login/LOGOUT_USER",
}

interface Payloads {
  [ActionTypes.LOGIN_USER]: any;
  [ActionTypes.LOGOUT_USER]: () => any;
}

export interface LoginUserRequestAction extends FSA<ActionTypes.LOGIN_USER, Payloads[ActionTypes.LOGIN_USER]> {
  type: ActionTypes.LOGIN_USER;
  payload: Payloads[ActionTypes.LOGIN_USER];
}

export interface LogoutUserAction extends FSA<ActionTypes.LOGOUT_USER, Payloads[ActionTypes.LOGOUT_USER]> {
  type: ActionTypes.LOGOUT_USER;
  payload: Payloads[ActionTypes.LOGOUT_USER];
}

function createLoginUserRequest(email: string, payload: LoginUserRequestAction["payload"]) {
  const config = Config.getInstance();
  const backendURLPrefix = config.get("BACKEND_BASE_URL");
  return new Request(backendURLPrefix.replace("/api/v2","") + "/parse/functions/sendVerification", {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function createLoginRequest(email: string, payload: LoginUserRequestAction["payload"]) {
  return createRequest("/insights/login", {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export const createSessionRequest = (impersonate: string | null) => createRequest("/sessions", {
  method: "POST",
  body: impersonate ? new URLSearchParams(`impersonate=${impersonate}`) : null,
});

export const actionCreators = {
  fetchSession: (impersonate: string | null): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createSessionRequest(impersonate),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => ({ type: ActionTypes.FETCH_SESSION_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({ type: ActionTypes.FETCH_SESSION_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({ type: ActionTypes.FETCH_SESSION_ERROR, payload }),
      ],
    },
  }),

  loginUser: (
    email: string,
    loginPayload: LoginUserRequestAction["payload"]
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createLoginUserRequest(email, loginPayload),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: ActionTypes.SEND_EMAIL_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: ActionTypes.SEND_EMAIL_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: ActionTypes.SEND_EMAIL_ERROR, payload }),
      ],
    },
  }),
  loginRequest: (
    email: string,
    loginPayload: LoginUserRequestAction["payload"]
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createLoginRequest(email,loginPayload),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: ActionTypes.SEND_CODE_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: ActionTypes.SEND_CODE_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({ type: ActionTypes.SEND_CODE_ERROR, payload }),
      ],
    },
  }),
  loginRefresh: (): any => ({
    type: ActionTypes.LOGIN_STATUS_REFRESH,
  }),
  logout: (callback: LogoutUserAction["payload"]): LogoutUserAction => ({
    type: ActionTypes.LOGOUT_USER,
    payload: callback,
  }),
};

export default actionCreators;
