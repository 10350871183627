import React from "react";
import { ColumnProps, TableProps } from "antd/lib/table";
import {
  VoiceFlaggedCommentReportResource
} from "hyphen-lib/dist/domain/resource/voice/VoiceFlaggedCommentReportResource";

import Table from "@src/components/core/Table";
import {
  DynamicRowProps,
  withDynamicRows
} from "@src/components/core/DynamicRowsTable/withDynamicRows";
import Actions from "@src/screens/Insights/components/Actions";
import { formatDate } from "hyphen-lib/dist/lang/Dates";
import { RightsMatcher } from "hyphen-lib/dist/business/auth/Auth";
import { Rights } from "hyphen-lib/dist/business/auth/Rights";
import { VoiceFlagsActionKeyType } from "../../store/types";

import { TextCell } from "../../VoiceComments/components/cells/TextCell";
import { SentimentCell } from "../../VoicePosts/Components/cells/SentimentCell";
import { Likes } from "../../VoiceComments/components/cells/Likes";
import { PostAndGroup } from "../../VoiceComments/components/cells/PostAndGroup";
import { Trans } from "react-i18next";

interface VoiceFlaggedCommentsTableProps
  extends TableProps<VoiceFlaggedCommentReportResource> {
  readonly onActionClick: (key: VoiceFlagsActionKeyType, post: VoiceFlaggedCommentReportResource) => void;
  readonly dataSource: VoiceFlaggedCommentReportResource[];
  readonly rightsMatcher: RightsMatcher;
}

export function VoiceFlaggedCommentsTable(props: VoiceFlaggedCommentsTableProps) {
  const COLUMNS: ColumnProps<VoiceFlaggedCommentReportResource>[] = [
    {
      title: (<Trans>Comment</Trans>),
      dataIndex: "text",
      key: "text",
      width: "30%",
      render: (_, { text }) => <TextCell text={text} />,
    },
    {
      title: null,
      dataIndex: "likes",
      key: "likes",
      width: "10%",
      render: (_, { likes }) => <Likes totalLikes={likes} />,
    },
    {
      title: (<Trans>Flag reason</Trans>),
      dataIndex: "reason",
      key: "reason",
      width: "10%",
      render: (_, { flagReason }) => <TextCell text={flagReason} />,
    },
    {
      title: (<Trans>Flagged date</Trans>),
      dataIndex: "flagDate",
      key: "flagDate",
      width: "10%",
      render: (_, { flagDate }) => <TextCell text={formatDate(flagDate)} />,
    },
    {
      title: (<Trans>Posted in</Trans>),
      dataIndex: "downVotesCount",
      key: "downVotesCount",
      width: "20%",
      render: (_, { postText, group }) => (
        <PostAndGroup text={postText} group={group} />
      ),
    },
    {
      title: (<Trans>Sentiment</Trans>),
      dataIndex: "sentimentScore",
      key: "sentimentScore",
      width: "15%",
      render: (_, { sentimentScore }) => (
        <SentimentCell sentimentScore={sentimentScore} />
      ),
    },
  ];

  function HoverRow({
    row,
  }: DynamicRowProps<VoiceFlaggedCommentReportResource>) {
    function onActionClick(key: VoiceFlagsActionKeyType) {
      props.onActionClick(key, row);
    }
    return (
      <>
        <td>
          <TextCell text={row.text} />
        </td>
        <td>
          <Likes totalLikes={row.likes} />
        </td>
        <td>
          <TextCell text={row.flagReason} />
        </td>
        <td>
          <TextCell text={formatDate(row.flagDate)} />
        </td>
        <td>
          <PostAndGroup text={row.postText} group={row.group} />
        </td>
        <td>
          { props.rightsMatcher.hasRight(Rights.Voice.FLAGGED_COMMENTS_ACTIONS)
            ? <Actions
              actions={{
                actions: [
                  {
                    key: VoiceFlagsActionKeyType.UNFLAG as never,
                    label: "Unflag",
                  },
                  {
                    key: VoiceFlagsActionKeyType.ARCHIVE as never,
                    label: "Archive",
                  },
                ],
                more: [],
              }}
              onClick={onActionClick as any}
              justifyContent="flex-start"
            />
            : <SentimentCell sentimentScore={row.sentimentScore} />
          }
        </td>
      </>
    );
  }

  const HoveringTable = withDynamicRows(Table, HoverRow);

  return <HoveringTable rowKey="_id" columns={COLUMNS} {...props} />;
}
