import React, { ChangeEvent, Component } from "react";
import Checkbox from "@components/core/Checkbox";
import ContainerCard from "@components/core/ContainerCard";
import styled from "styled-components";
import Select from "@components/core/Select";
import { SelectProps, SelectValue } from "antd/lib/select";
import { AppSettingsResource } from "hyphen-lib/dist/domain/resource/AppSettingsResource";
import {
  areNotEquals,
  getOr,
  isNotNullNorUndefined,
} from "hyphen-lib/dist/lang/Objects";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import Palette from "@src/config/theme/palette";
import { Company } from "hyphen-lib/dist/domain/Company";
import Input from "antd/lib/input/Input";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import { getOkrSynonyms } from "hyphen-lib/dist/business/appSetting/AppSettings";
import { Trans } from "react-i18next";

const { Option } = Select;

interface AppSettingsActionPlanProps {
  appSettings: AppSettingsResource;
  onValueChange: (values: AppSettingsResource) => void;
  betterworksOkrEnabled: boolean;
}

interface AppSettingsActionPlanStates {
  fieldErrors: Partial<Record<keyof Company.GoalSetting, string>>;
  okrInputFieldValues: Partial<Record<keyof Company.GoalSetting, string>>;
}

const weekdays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const hourOfDay = [
  "0 AM",
  "1 AM",
  "2 AM",
  "3 AM",
  "4 AM",
  "5 AM",
  "6 AM",
  "7 AM",
  "8 AM",
  "9 AM",
  "10 AM",
  "11 AM",
  "12 PM",
  "1 PM",
  "2 PM",
  "3 PM",
  "4 PM",
  "5 PM",
  "6 PM",
  "7 PM",
  "8 PM",
  "9 PM",
  "10 PM",
  "11 PM",
  "12 AM",
];
export default class ActionPlanSettings extends Component<
  AppSettingsActionPlanProps,
  AppSettingsActionPlanStates
> {
  constructor(props: AppSettingsActionPlanProps) {
    super(props);
    this.state = {
      fieldErrors: {},
      okrInputFieldValues: getOkrSynonyms(this.props.appSettings),
    };
  }

  componentDidUpdate(prevProps: AppSettingsActionPlanProps) {
    if (areNotEquals(prevProps, this.props)) {
      this.setState({
        okrInputFieldValues: getOkrSynonyms(this.props.appSettings),
      });
    }
  }

  updateDayOfWeek = (value: SelectValue) => {
    this.props.onValueChange({
      ...this.props.appSettings,
      actions: {
        ...this.props.appSettings.actions,
        digestTime: {
          ...(isNotNullNorUndefined(this.props.appSettings.actions)
            ? this.props.appSettings.actions.digestTime
            : {}),
          dayOfWeek: Number(value),
        },
      } as AppSettingsResource.ActionsConfiguration,
    });
  };

  updateStartTime = (value: SelectValue) => {
    this.props.onValueChange({
      ...this.props.appSettings,
      actions: {
        ...this.props.appSettings.actions,
        digestTime: {
          ...(isNotNullNorUndefined(this.props.appSettings.actions)
            ? this.props.appSettings.actions.digestTime
            : {}),
          time: Number(value),
        },
      } as AppSettingsResource.ActionsConfiguration,
    });
  };

  sendEmailDigestChange = (e: CheckboxChangeEvent) => {
    this.props.onValueChange({
      ...this.props.appSettings,
      actions: {
        ...this.props.appSettings.actions,
        sendDigest: e.target.checked,
      },
    });
  };

  modifyOKRSynonyms = (e: ChangeEvent<HTMLInputElement>) => {
    const actionPlanConfig = getOr(this.props.appSettings.actions, {});
    const okrSettings = getOr(actionPlanConfig.okrNamingConvention, {
      keyResult: Company.OkrNamingConventionDefaultValues.keyResult,
      objective: Company.OkrNamingConventionDefaultValues.objective,
      okrs: Company.OkrNamingConventionDefaultValues.okrs,
    });

    const updatedInputFieldValue = e.currentTarget.value;
    if (updatedInputFieldValue) {
      /**
       * - Empty value cannot be updated
       * - No need to set the state here, as the page anyways gets reloaded
       * - No need to remove the field error state, as the page anyways gets reloaded
       */
      this.props.onValueChange({
        ...this.props.appSettings,
        actions: {
          ...actionPlanConfig,
          okrNamingConvention: {
            ...okrSettings,
            [e.currentTarget.name]: updatedInputFieldValue,
          },
        } as AppSettingsResource.ActionsConfiguration,
      });
    } else {
      this.setState({
        fieldErrors: {
          ...this.state.fieldErrors,
          [e.currentTarget
            .name as keyof Company.GoalSetting]: `${e.currentTarget.name} cannot be empty`,
        },
        okrInputFieldValues: {
          ...this.state.okrInputFieldValues,
          [e.currentTarget.name as keyof Company.GoalSetting]:
            updatedInputFieldValue,
        },
      });
    }
  };

  render() {
    const okrSynonymMap: Company.GoalSetting = {
      objective: "Objective",
      okrs: "OKR",
      keyResult: "Key Result",
    };

    const actionPlanConfig = getOr(this.props.appSettings.actions, {
      sendDigest: true,
      digestTime: {
        dayOfWeek: 0,
        time: 16,
      },
      okrNamingConvention: okrSynonymMap,
    });

    let digestWeekValue = 0;
    let digestTimeValue = 9;

    if (isNotNullNorUndefined(actionPlanConfig.digestTime)) {
      digestWeekValue = getOr(actionPlanConfig.digestTime.dayOfWeek, 0);
      digestTimeValue = getOr(actionPlanConfig.digestTime.time, 9);
    }
    return (
      <StyledContainerCard title="Action Plan settings">
        <Holder>
          <CheckHolder>
            <StyledDiv>
              <Checkbox
                info="Send weekly digest emails to users with active Action Plans"
                onChange={this.sendEmailDigestChange}
                checked={getOr(actionPlanConfig.sendDigest, true)}
              >
                <Trans>Send Action Plan digest emails</Trans>
              </Checkbox>
            </StyledDiv>
          </CheckHolder>
        </Holder>
        <Holder>
          <StyledSelect
            disabled={!getOr(actionPlanConfig.sendDigest, true)}
            label="Send Email digest on every"
            value={weekdays[digestWeekValue]}
            onChange={this.updateDayOfWeek}
          >
            {weekdays.map((day, index) => (
              <Option key={index} value={index}>
                {day}
              </Option>
            ))}
          </StyledSelect>
          <StyledSelect
            disabled={!getOr(actionPlanConfig.sendDigest, true)}
            label="Send Email digest at"
            value={hourOfDay[digestTimeValue]}
            onChange={this.updateStartTime}
          >
            {hourOfDay.map((hour, index) => (
              <Option key={index} value={index}>
                {hour}
              </Option>
            ))}
          </StyledSelect>
        </Holder>
        {this.props.betterworksOkrEnabled && (
          <Holder>
            <OKRHeading><Trans>Naming OKRs</Trans></OKRHeading>
            <OKRSubHeading>
              <Trans>Customize your naming convention to the one used in BW OKR module</Trans>
            </OKRSubHeading>
            {Object.keys(okrSynonymMap).map((item, index) => {
              return (
                <RowWrapper key={index}>
                  <Row key={index} gutter={[8, 8]}>
                    <Col span={2}>
                      <AlignCenter>
                        <Trans>{okrSynonymMap[item as keyof Company.GoalSetting]}</Trans>
                      </AlignCenter>
                    </Col>
                    <Col span={4}>
                      <Input
                        name={item}
                        disabled={!this.props.betterworksOkrEnabled}
                        value={
                          this.state.okrInputFieldValues[
                            item as keyof Company.GoalSetting
                          ]
                        }
                        onChange={this.modifyOKRSynonyms}
                      />
                      {this.state.fieldErrors[
                        item as keyof Company.GoalSetting
                      ] && (
                        <Warning>
                          {okrSynonymMap[item as keyof Company.GoalSetting]}{" "}
                          <Trans>cannot be left blank</Trans>
                        </Warning>
                      )}
                    </Col>
                  </Row>
                </RowWrapper>
              );
            })}
          </Holder>
        )}
      </StyledContainerCard>
    );
  }
}

const StyledContainerCard = styled(ContainerCard)`
  margin-bottom: 24px;
  input:focus {
    outline: none;
  }
`;

const StyledDiv = styled.div`
  color: ${Palette.lightGreyBlue};
  margin-right: 10px;
`;

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const CheckHolder = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  margin-right: 20px;
`;

const StyledSelect = styled(Select)<
  SelectProps & { children: React.ReactNode }
>`
  width: 266px;
`;

const AlignCenter = styled.div`
  display: flex !important;
  align-items: center !important;
`;

const OKRHeading = styled.div`
  display: flex;
  align-items: flex-start !important;
  font-size: 1.5em;
`;

const OKRSubHeading = styled.div`
  display: flex;
  align-items: flex-start !important;
  font-size: 1em;
  margin-bottom: 2em;
  color: ${Palette.raven}!important;
`;

const Warning = styled.p`
  color: ${Palette.darkPink};
  margin-bottom: 0;
`;

const RowWrapper = styled.div`
  margin-bottom: 0.1em;
`;
