import React from "react";
import { TransWrapper } from "@src/components/core/withTrans";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { getOr } from "hyphen-lib/dist/lang/Objects";

interface Props {
  children: React.ReactNode;
  color: string; // fixme: shouldn't we create a Palette.Color type?!
  textColor?: string;
  translate?: string;
}

export function Tag({ children, color, textColor, ...rest }: Props) {
  return (
    <StyledTag color={color}>
      <StyledLabel textColor={textColor}>
        <TransWrapper {...rest}>{children}</TransWrapper>
      </StyledLabel>
    </StyledTag>
  );
}

const StyledTag = styled.div<{color?: string}>`
  display: flex;
  height: 20px;
  border-radius: 100px;
  background-color: ${props => props.color};
`;

const StyledLabel = styled.span<{textColor?: string}>`
  margin: 2px auto 3px auto;
  height: 15px;
  font-family: Lato;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: ${props => getOr(props.textColor, Palette.veryDarkBlueGrey)};
`;
