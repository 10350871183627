import { isNullOrUndefined } from "util";
import React from "react";
import styled from "styled-components";
// eslint-disable-next-line max-len
import { MultipleChoiceQuestionReportResource } from "@hyphen-lib/domain/resource/report/MultipleChoiceQuestionReportResource";
import { isNotNullNorUndefined, mapOr, getOr } from "@hyphen-lib/lang/Objects";
import { Heading } from "@components/core/Typography";
import { FavorabilityScore } from "@components/core/FavorabilityScore";
import Palette, {
  getBarThemeForFavorability,
  getBarThemeForEnps,
  getBarThemeForMultipleChoice,
  BarOptions
} from "@src/config/theme/palette";
import AnswerDistributionLegend
  from "@screens/Insights/Survey/components/QuestionHighlightReport/components/AnswerDistributionLegend";
import { extractComparison } from "hyphen-lib/dist/business/calculation/benchmark/Benchmarks";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { ComparisonsOverview } from "@screens/Insights/Survey/components/ComparisonsOverview";
import { CompareWithOption } from "@screens/Insights/components/ViewOptions/components/CompareWith";
import { getMatchingOptionLabel } from "@src/utils/Comparisons";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Breadcrumb, goTo } from "@src/utils/locations";
import { ScoreType } from "hyphen-lib/dist/domain/resource/report/common/Score";
// eslint-disable-next-line max-len
import { NPSIcon } from "@src/screens/Insights/Survey/components/OverviewReport/components/Overview";
import {SentimentWithComments} from "@screens/Insights/components/SentimentWithComments";
import { DistributionResult } from "hyphen-lib/dist/domain/resource/report/common/DistributionResult";
// eslint-disable-next-line max-len
import AnswerDistributionGraph from "../../../Survey/components/QuestionHighlightReport/components/AnswerDistributionGraph";
import { Trans } from "react-i18next";

interface Props {
  readonly areComparisonsVisible: boolean;
  readonly question?: MultipleChoiceQuestionReportResource.NonFiltered;
  readonly compareWithOptions: CompareWithOption[];
  readonly comparisonKey: Optional<string>;
  readonly overviewLabel: string;
  readonly hasCommentsAccess: boolean;
  readonly onCommentsClick?: () => void;
  readonly postAndSurveySentiment?: boolean;
}

interface MatchParams {
  id: string;
  questionId: string;
}

export function getBarConfigForRendering (
  question: MultipleChoiceQuestionReportResource.NonFiltered,
  distributionResult?: DistributionResult
) {
  let choiceLabels = question.votesSentimentSummary.choiceLabels;
  let distribution = getOr(distributionResult, question.votesSentimentSummary.distribution);
  let barTheme: BarOptions[];

  if (question.type === MultipleChoiceQuestionReportResource.NonFilteredType.WITH_SCORE) {
    if (question.scoreType === ScoreType.NPS) {
      choiceLabels = choiceLabels.slice().reverse();
      distribution = distribution.slice().reverse();
      barTheme = getBarThemeForEnps();
    } else {
      barTheme = getBarThemeForFavorability(
        choiceLabels.length,
        choiceLabels
      );
    }
  } else {
    barTheme = getBarThemeForMultipleChoice(choiceLabels, question.isMultiSelect);
  }

  return {
    choiceLabels,
    distribution,
    barTheme,
  };
}

// tslint:disable-next-line:no-empty
const devNullListener = () => {};

/* fixme: instead of a separate component for working with MCQReportResource, try to write a component
that can work with both CategoryReportResource (used in CategoryOverview) and MCQReportResource
This new component should not take any resource as a prop, but should only take a set of resource-independent
props and render the section */

function VotesSentiment({
  areComparisonsVisible,
  question,
  compareWithOptions,
  comparisonKey,
  overviewLabel,
  history,
  hasCommentsAccess,
  postAndSurveySentiment,
  ...rest
}: Props & RouteComponentProps<MatchParams>) {

  if (isNullOrUndefined(question)) {
    return null;
  }

  const comparisonLabel = getMatchingOptionLabel(compareWithOptions, comparisonKey);
  const averageComparisonLabel = getMatchingOptionLabel(compareWithOptions, "average");
  const favorability = (question as MultipleChoiceQuestionReportResource.WithScore).votesSentimentSummary.score;
  const isQuestionWithScore = question.type === MultipleChoiceQuestionReportResource.NonFilteredType.WITH_SCORE ;
  const compare =  mapOr(
    question,
    (q: MultipleChoiceQuestionReportResource.NonFiltered) =>
      isQuestionWithScore ?
        (q as MultipleChoiceQuestionReportResource.WithScore).votesSentimentSummary.compare :
        {},

    {}
  );
  const comparison = extractComparison(compare, comparisonKey);
  const averageComparison = extractComparison(compare, "average");
  const hasAnswerDistribution = question.votesSentimentSummary.distribution.some(
    vote => isNotNullNorUndefined(vote.percentage)
  );

  const { choiceLabels, distribution, barTheme } = getBarConfigForRendering(question);

  function goToComments() {
    const { location, onCommentsClick } = rest;
    if (isNotNullNorUndefined(onCommentsClick)) {
      return onCommentsClick();
    }else {
      goTo(
        history.location.pathname.replace(/\/$/,"") + "/comments" + location.search, Breadcrumb.stack(overviewLabel)
        );
    }
  }

  const scoreType = (question as MultipleChoiceQuestionReportResource.WithScore).scoreType;

  // fixme set comparison label!
  return (
    <Container>
      <Heading size="large" data-cy="answers_heading_overview" translate="yes">
        Overview
      </Heading>
      <FavorabilitySentimentContainer>
        {isQuestionWithScore && isNotNullNorUndefined(favorability) && <FavorabilityContainer>
          <Heading size="small" weight={"bold"}>
            {scoreType === ScoreType.FAVORABILITY ?
              <Trans>Favorability</Trans> :
              NPSIcon
            }

          </Heading>
          <FavorabilityComparisonContainer>
            <FavorabilityScoreContainer>
              <FavorabilityScore
                data-cy="pollScore"
                favorability={favorability}
                scoreType={(question as MultipleChoiceQuestionReportResource.WithScore).scoreType}
                percentage={scoreType === ScoreType.FAVORABILITY }/>
            </FavorabilityScoreContainer>
            <ComparisonsOverview
              areComparisonsVisible={areComparisonsVisible}
              comparisonLabel={comparisonLabel}
              comparisonValue={comparison}
              averageComparisonLabel={averageComparisonLabel}
              averageComparisonValue={averageComparison}
              showSecondComparison={comparisonKey !== "average"}
              percentage={scoreType === ScoreType.FAVORABILITY }
            />
          </FavorabilityComparisonContainer>
        </FavorabilityContainer>}
        {hasAnswerDistribution && (
          <AnswerDistributionContainer>
            <Heading size="small" weight={"bold"} translate="yes">
            Answer distribution
            </Heading>
            <AnswerDistributionGraphContainer data-cy="satckedBarGraph">
              <AnswerDistributionGraph
                data={distribution}
                choiceLabels={choiceLabels}
                graphColors={barTheme}
                numberOfVotes={question.votesSentimentSummary.numberOfVotes}
              />
              <AnswerDistributionLegendContainer>
                <AnswerDistributionLegend legendItems={barTheme}/>
              </AnswerDistributionLegendContainer>
            </AnswerDistributionGraphContainer>
          </AnswerDistributionContainer>
        )}
        {getOr(postAndSurveySentiment, true) && (<SentimentContainer>
          <Heading size="small" weight={"bold"} translate="yes">
            Sentiment
          </Heading>
          <SentimentWithComments
            hasComments
            netSentimentScore={question.votesSentimentSummary.netSentimentScore}
            hasCommentsAccess={hasCommentsAccess}
            numberOfComments={question.votesSentimentSummary.numberOfComments}
            onCommentsClick={hasCommentsAccess ? goToComments : devNullListener}
          />
        </SentimentContainer>)}
      </FavorabilitySentimentContainer>
     
    </Container>
  );
}

const Container = styled.div`
  padding: 32px;
  background: ${Palette.white};
  font-family: Lato;
  color: ${Palette.veryDarkBlueGrey};
`;

const FavorabilitySentimentContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
`;

const FavorabilityContainer = styled.div``;

const FavorabilityScoreContainer = styled.div`
  display: inline-block;

  span {
    font-size: 56px !important;
    line-height: 1;
  }
`;

const SentimentContainer = styled.div`
`;

const AnswerDistributionContainer = styled.div`
  margin: 0 64px;
  flex-grow: 1;
`;

const AnswerDistributionGraphContainer = styled.div`
  margin-top: 32px;
`;

const AnswerDistributionLegendContainer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
`;

const FavorabilityComparisonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;
`;

export default withRouter(VotesSentiment);
export { VotesSentiment as PureVotesSentiment };
