import React, { FunctionComponent } from "react";
import { List } from "immutable";
import { useTranslation } from "react-i18next";
import { LifeCycleSettings } from "@hyphen-lib/domain/common/LifeCycleSettingsConfig";
import { isNotNullNorUndefined } from "@hyphen-lib/lang/Objects";
import {
  EMPTY_STRING_NOTATION,
  INFINITY_NOTATION,
  MutablePhaseWithErrorState,
  MutableSubPhaseWithErrorState
} from "../../utils/LifeCycleSettings";
import AddPhaseButton from "./AddPhaseButton";
import LifeCyclePhase from "./LifeCyclePhase";
import { translate } from "@src/utils/i18next";

export interface LifeCyclePhasesProps{
  phases: List<MutablePhaseWithErrorState>;
  config: LifeCycleSettings.Config;
  handlePhasesChange: (phases: List<MutablePhaseWithErrorState>) => void;
}

// fixme: Remove `!` wherever possible

const LifeCyclePhases: FunctionComponent<LifeCyclePhasesProps> = (props) => {
  const { phases, config } = props;
  const { t } = useTranslation();
  const handlePhaseChange = (idx: number) => (phase: MutablePhaseWithErrorState) => {
    let updatedPhases: List<MutablePhaseWithErrorState> = phases.set(idx, phase);
    const nextPhase = updatedPhases.get(idx + 1);

    if (isNotNullNorUndefined(nextPhase)) {
      nextPhase.startTenure = phase.endTenure;
      const nextPhaseFirstSubphase: MutableSubPhaseWithErrorState = nextPhase.subPhases.first();
      nextPhaseFirstSubphase.startTenure = phase.endTenure;
      nextPhase.subPhases = nextPhase.subPhases.set(0, nextPhaseFirstSubphase);
      updatedPhases = updatedPhases.set(idx + 1, nextPhase );
      return props.handlePhasesChange(updatedPhases);
    }
    props.handlePhasesChange(updatedPhases);
  };

  const handleRemovePhaseClick = (idx: number) => () => {
    let updatedPhases = phases.remove(idx);
    const lastPhase: MutablePhaseWithErrorState = updatedPhases.last();
    const lastSubPhase: MutableSubPhaseWithErrorState = lastPhase.subPhases.last();

    if (idx === phases.size - 1) {
      // This is the last one. Just change the previous phase endTenure to Infinity
      lastSubPhase.endTenure = INFINITY_NOTATION;
      lastPhase.subPhases = lastPhase.subPhases.set(lastPhase.subPhases.size - 1, {
        ...lastSubPhase,
        endTenure: INFINITY_NOTATION,
      });
      updatedPhases = updatedPhases.set(idx - 1, lastPhase);
      props.handlePhasesChange(updatedPhases);
      return;
    }

    props.handlePhasesChange(updatedPhases);
  };

  const handleAddNewPhase = () => {
    const lastPhase: MutablePhaseWithErrorState = phases.last();
    const otherPhases = phases.butLast();

    const lastPhaseSubPhases = List(lastPhase.subPhases);
    const lastSubPhaseInLastPhase: MutableSubPhaseWithErrorState = lastPhaseSubPhases.last();
    const otherSubphasesInLastPhase = lastPhaseSubPhases.butLast();

    lastPhase.endTenure = EMPTY_STRING_NOTATION;

    if (lastSubPhaseInLastPhase) {
      lastSubPhaseInLastPhase.endTenure = EMPTY_STRING_NOTATION;
      const updatedSubphases = otherSubphasesInLastPhase.concat(lastSubPhaseInLastPhase);
      lastPhase.subPhases = updatedSubphases;
    }

    const updatedPhases = otherPhases.concat(lastPhase, {
      name: "",
      startTenure: lastPhase.endTenure,
      endTenure: INFINITY_NOTATION,
      subPhases: List([
        {
          startTenure: lastPhase.endTenure,
          endTenure: INFINITY_NOTATION,
          startTenureError: null,
          endTenureError: null,
        },
      ]),
      nameError: null,
      startTenureError: null,
      endTenureError: null,
      canRemove: true,
    });

    props.handlePhasesChange(updatedPhases);
  };

  return (
    <>
      {phases &&
        phases.map((phase, idx) => (
          <LifeCyclePhase
            key={`phase_${idx}`}
            {...phase}
            subPhases={phase.subPhases}
            title={`${translate(t, "Phase")} ${idx + 1} - ${translate(t, phase.name)}`}
            isLast={phases.size === idx + 1}
            isFirst={idx === 0}
            minSubPhases={config.minSubPhases}
            maxSubPhases={config.maxSubPhases}
            handlePhaseChange={handlePhaseChange(idx)}
            handleRemovePhaseClick={handleRemovePhaseClick(idx)}
          />
        ))}

      {phases.size < config.maxPhases - 1 && (
      // -1 because separation is not within the phases array
        <AddPhaseButton addNewPhase={handleAddNewPhase} />
      )}
    </>
  );
};

export default LifeCyclePhases;
