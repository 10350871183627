import React from "react";
import styled from "styled-components";
import { Trans } from "react-i18next";
import { SidebarMode } from "@components/Sidebar";
import { SidebarSectionDefinition } from "@components/Sidebar/SidebarDefinitions";
import { isStringAndNotEmpty } from "@hyphen-lib/lang/Strings";
import { SidebarItem } from "@components/Sidebar/components/SidebarItem";
import { isNotEmptyArray } from "@hyphen-lib/lang/Arrays";

export interface SidebarSectionProps {
  mode: SidebarMode;
  activeItemId?: string;
  section: SidebarSectionDefinition<any>;
}

export const SidebarSection = ({ mode, activeItemId, section }: SidebarSectionProps) => (
  <SidebarSectionContainer>
    {
      isStringAndNotEmpty(section.label) &&
      mode === SidebarMode.EXPANDED &&
      isNotEmptyArray(section.items) &&
      <SidebarSectionLabel>
        <Trans>{section.label}</Trans>
      </SidebarSectionLabel>
    }
    {
      section.items.map(item => (
        <SidebarItem
          key={item.id}
          mode={mode}
          showNotificationIcon={item.showNotificationIcon}
          icon={item.icon}
          text={item.text}
          active={item.id === activeItemId}
          onClick={item.onClick}
        />
      ))
    }
  </SidebarSectionContainer>
);

const SidebarSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SidebarSectionLabel = styled.div`
  padding-left: 40px;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 16px;
`;
