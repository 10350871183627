import React from "react";
import { PulsePollInfoResource } from "hyphen-lib/dist/domain/resource/PulsePollInfoResource";
import {
  CellContainer,
  CellInnerContainer
} from "@src/screens/Insights/SurveyList/components/cells/Cells";
import { formatDate } from "hyphen-lib/dist/lang/Dates";
import { Paragraph } from "@src/components/core/Typography";
import { Row, Col } from "antd";
import styled from "styled-components";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import moment from "moment-timezone";
import { Post } from "hyphen-lib/dist/domain/Post";
import { Trans } from "react-i18next";

interface Props {
  readonly row: PulsePollInfoResource;
  readonly timezone?: string;
  readonly index?: number | null;
}

export function DateCell({ row, timezone = moment.tz.guess(), index }: Props) {
  const { nextOccurrence, lastOccurrence, status } = row;
  const nextDate = formatDate(nextOccurrence as Date, {
    hour: "numeric",
    minute: "numeric",
  });
  const lastOccurrenceDate = isNotNullNorUndefined(lastOccurrence)
    ? formatDate(lastOccurrence )
    : null;

  if (status !== Post.Status.DRAFT) {
    return (
      <CellContainer align="center" justifyContent="flex-end">
        <CellInnerContainer margin={0} width="100%">
          {row.status !== Post.Status.DEACTIVATED && (
            <Row data-cy={`listView_nextDate_${index}`}>
              <Col span={8}>
                <Paragraph weight="light">
                  <Trans>{
                    row.status === "active" && isNotNullNorUndefined(row.instances) &&
                      row.instances.length > 0 ? "Next on" : "Launch date"
                  }</Trans>{": "}
                </Paragraph>
              </Col>
              <Col span={16}>
                <Paragraph>
                  {nextOccurrence
                    ? `${nextDate} ${moment().tz(timezone).zoneAbbr()}`
                    : <Trans>Never</Trans>}
                </Paragraph>
              </Col>
            </Row>
          )}
          {lastOccurrence && (
            <StyledRow data-cy={`listView_lastSent_${index}`}>
              <Col span={8}>
                <Paragraph weight="light" translate="yes">Last sent:</Paragraph>
              </Col>
              <Col span={16}>
                <Paragraph weight="light">{lastOccurrenceDate}</Paragraph>
              </Col>
            </StyledRow>
          )}
        </CellInnerContainer>
      </CellContainer>
    );
  }

  return <></>;
}

const StyledRow = styled(Row)`
  margin-top: 5px;
`;
