import React from "react";
import { RouteComponentProps } from "react-router";
import { Trans, WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import { Col, Row, Spin } from "antd";
import { connect, MapStateToProps } from "react-redux";
import moment from "moment";
import { List as ImmutableList } from "immutable";
import Stepper, { STEP_STATUS, StepProps } from "@components/core/Stepper";
import { State } from "@src/store/types";
import Modal from "@src/components/core/Modal";
import Audience from "@screens/Insights/components/Wizard/Audience/container/Audience";
import ContainerCard from "@components/core/ContainerCard";
import {
  getOr,
  isNotNullNorUndefined,
  isNullOrUndefined,
  mapOr,
} from "hyphen-lib/dist/lang/Objects";
import { SurveyResource } from "hyphen-lib/dist/domain/resource/SurveyResource";
import AreYouSureModal from "@components/core/AreYouSureModal";
import CreationLayout from "@components/layouts/CreationLayout";
import { goTo } from "@src/utils/locations";
import { Survey } from "hyphen-lib/dist/domain/Survey";
import Logger from "hyphen-lib/dist/util/Logger";
import { ModalProps } from "antd/lib/modal";
import Button from "@components/core/Button";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import NavigationPrompt from "react-router-navigation-prompt";
import { Heading, Paragraph } from "@components/core/Typography";
import { Settings } from "@screens/Insights/Surveys/containers/Settings";
import {
  QuestionPageStateProps,
  SurveyDisabledFields,
  SurveyStepValue,
} from "@screens/Insights/Surveys/store/surveyEditTypes";
import { MergeTagResource } from "hyphen-lib/dist/domain/resource/MergeTagResource";
import { SurveyTypeResource } from "hyphen-lib/dist/domain/resource/SurveyTypeResource";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import {
  getDisabledSurveyFields,
  getSurvey,
  getSurveySteps,
  getSurveyUpdatedAt,
  getSurveyUpdatedQuestionState,
  getSurveyValidationErrors,
  hasSurveyPendingUpdates,
} from "@screens/Insights/Surveys/store/surveyEditSelectors";
import {
  getExistingAllPage,
  getExistingNotPaginatedPage,
  getResourceById,
  getUntypedElementById,
} from "@store/network/selectors";
import { Store } from "hyphen-lib/dist/util/store/Store";
import {
  getCompany,
  getCurrentUserId,
  getRightsMatcher,
} from "@screens/Insights/store/selectors";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { surveyEditActionCreators } from "@screens/Insights/Surveys/store/surveyEditActions";
import { fetchSurveyTypesIfNeeded } from "@store/network/resource/SurveyTypeResources";
import { not } from "hyphen-lib/dist/lang/Booleans";
import {
  getSurveyResourceErrors,
  toHumanReadableErrorMessage,
} from "@src/utils/formValidations";
import LabelMapper from "@src/utils/constants/SurveyCreation";
import * as NotificationFactory from "@src/store/notifications/notification-factory";
import displayNotification from "@src/store/notifications/actions";
import { AccessList } from "hyphen-lib/dist/domain/access/AccessList";
import { AccessResource } from "hyphen-lib/dist/domain/access/AccessResource";
import { SurveyQuestionResource } from "hyphen-lib/dist/domain/resource/SurveyQuestionResource";
import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { Audience as AudienceType } from "hyphen-lib/dist/domain/common/Audience";
import { ParticipantResource } from "hyphen-lib/dist/domain/resource/participants/ParticipantResource";
import { ParticipantCountResource } from "hyphen-lib/dist/domain/resource/participants/ParticipantCountResource";
import { NextButton } from "@screens/Insights/components/Wizard/layout/NextButton";
import { ContinueAlert } from "@screens/Insights/components/Wizard/layout/ContinueAlert";
import {
  extractMaxReachedStepIndex,
  getStep,
  getStepByKey,
  getStepIndex,
  getStepLabel,
  StepDefinition,
} from "@src/utils/wizard/Steps";
import { getAllSurveyStepDefinitions } from "@screens/Insights/Surveys/utils/StepDefinitions";
import { SurveySummary } from "@screens/Insights/components/Wizard/Summary/SurveySummary";
import { getConfigurationFor } from "hyphen-lib/dist/business/survey/SurveyTypes";
import { DateCriteriaTexts } from "@src/utils/Audiences";
import { identity } from "hyphen-lib/dist/lang/Functions";
import { Notification } from "@store/notifications/types";
import {
  isEmpty,
  isNotEmptyArray,
  reOrderById,
} from "hyphen-lib/dist/lang/Arrays";
import {
  fetchManageesIfNeeded,
  getEligibleManagersCountId,
  getManageesId,
} from "@store/network/resource/Untypeds";
import { SurveyBankQuestionResource } from "hyphen-lib/dist/domain/resource/survey/SurveyBankQuestionResource";
import { fetchSurveyBankQuestionsIfNeeded } from "@store/network/resource/SurveyBankQuestionResources";
import { SurveyBankQuestionPayload } from "@screens/Insights/Surveys/components/SurveyQuestions/QuestionBankModal";
import { fetchPostCategoriesIfNeeded } from "@store/network/resource/PostCategoryResources";
import { PostCategoryResource } from "hyphen-lib/dist/domain/resource/post/PostCategoryResource";
import { ActionContainer } from "@screens/Insights/components/Wizard/layout/ActionContainer";
import { GlobalErrors } from "@screens/Insights/components/Wizard/layout/GlobalErrors";
import UpdateAudienceModal from "@screens/Insights/Surveys/components/UpdateAudienceModal";
import { DifferentialAudienceCountResource } from "hyphen-lib/dist/domain/resource/DifferentialAudienceResource";
import {
  isMagicSurveyEnabled,
  isMultiLanguageSurveyEnabled,
  isSurveyMultiSelectEnabled,
} from "hyphen-lib/dist/business/company/Companies";
import { QuestionConfig } from "hyphen-lib/dist/domain/common/QuestionType";
import Access from "../../components/Wizard/Access/Access";
import {
  getImposedDimensions,
  getReportingDimMgmts,
  getSpecificAudienceEmails,
  getSurveyAccesses,
  getSurveyPreviewErrors,
  getSurveyPreviewModalState,
  getSurveyQuestionPageState,
  getSurveyQuestions,
  hasSurveyPendingAudienceUpdates,
  getUpdateAudienceModalOpen,
} from "../store/surveyEditSelectors";
import { SendSurveyPreviewModalContent } from "../components/SendSurveyPreviewModalContent";
import { FetchError } from "../../errors/FetchError";
import SurveyQuestions, {
  MODALS as SURVEY_QUESTIONS_MODALS,
} from "./SurveyQuestions";
import MagicSurveySection from "../components/MagicSurveySection";
import { xlsxActionCreators } from "../../Xlsx/actions";
import { FileUploadCollectiveState, getFileUploadState } from "../../Xlsx/util";
import { getAppSettings } from "../../Settings/store/selectors";
import { AppSettingsResource } from "hyphen-lib/dist/domain/resource/AppSettingsResource";
import { LocaleCodes } from "hyphen-lib/dist/util/locale";
import { filterEnglishCodes } from "@src/utils/translation";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import { UserEmailInsightAccess } from "hyphen-lib/dist/domain/User";
import { downloadSurveyRecipients } from "@src/utils/exports";
import { translate } from "@src/utils/i18next";
import { getOverallSurveyTranslationStatus } from "../../../../utils/translation";
import { MissingTranslationAlert } from "../../components/Wizard/layout/MissingTranslationWarning";
import { hourOfDay } from "../../Settings/components/ActionPlanSettings";
import { checkIfBusinessHours } from "hyphen-lib/dist/domain/Company";
import {
  UpdateUserPermissionsPayload,
  userPermissionsActionCreators
} from "../../UserManagement/containers/UserPermissions/store/actions";
import { SelectionFilter } from "hyphen-lib/dist/domain/parameter/SelectionFilter";
import { InsightsAccessAlter } from "../../components/Wizard/layout/InsightsAccessAlter";
import { Rights } from "hyphen-lib/dist/business/auth/Rights";
import { getUserPermissionsStateProps } from "../../UserManagement/containers/UserPermissions/store/selectors";
import Palette from "@src/config/theme/palette";

const log = Logger.create("SurveyEdit");
export const IsMultiSelectContext = React.createContext(false);

interface MatchParams {
  readonly id: string;
  readonly currentStepKey: string;
}

export type OwnProps = RouteComponentProps<MatchParams>;

interface SurveyEditStateProps {
  // common
  readonly userId: string;
  readonly surveyId: string;
  readonly survey: Loadable<SurveyResource>;
  readonly updatedAt: Date;
  readonly currentStep: StepDefinition<SurveyStepValue>;
  readonly steps: ImmutableList<StepProps>;
  readonly errors: WrongEntityException.Errors;
  readonly hasPendingUpdates: boolean;
  readonly hasPendingAudienceUpdates: boolean;
  readonly manageeEmails: UserEmailInsightAccess[];
  readonly disabled: SurveyDisabledFields;

  // settings
  readonly allowedMergeTags: MergeTagResource[];
  readonly allowedTypes: SurveyTypeResource[];
  readonly allowedChannels: CompanyResource.Channels;

  // audience
  readonly participantsCount: Loadable<ParticipantCountResource>;
  readonly participants: Loadable<ParticipantResource[]>;
  readonly usersWithoutInsightsAccess: AccessResource[];
  readonly dimensions: Dimensions;
  readonly reportingDimensions: Dictionary<string[]>;
  readonly newUsersCount: number;
  readonly showUpdateAudienceModal: boolean;
  /*
    For the manual audience, we will get the managee emails or this list if it is defined,
    if the survey type implies to get a specific type of audience for the manual emails, it will
    be set here. Otherwise we will just use the managee emails.
   */
  readonly specificAudienceEmails: Optional<UserEmailInsightAccess[]>;

  // questions
  readonly questions: SurveyQuestionResource[];
  readonly surveyBankQuestions: SurveyBankQuestionResource[];
  readonly postCategories: PostCategoryResource[];
  readonly questionPageState: QuestionPageStateProps;
  readonly updatedQuestion?: SurveyQuestionResource;
  readonly isMultiSelectQuestionEnabled: boolean;
  readonly questionConfig: QuestionConfig;

  // accesses
  readonly accesses: AccessResource[];
  readonly eligibleManagersCount: Loadable<number>;

  // preview
  readonly isPreviewModalOpen: boolean;
  readonly previewErrors: WrongEntityException.Errors;
  readonly magicSurveyEnabled: Optional<boolean>;

  readonly surveyValidationError: WrongEntityException.Errors;
  readonly fileUploadState: FileUploadCollectiveState;
  readonly surveyLanguageList: string[];
  readonly isMultiLangSurveyEnabled: boolean;
  readonly canEditUser: boolean;
  readonly company: CompanyResource;
  readonly isBulkUpdateRequestSuccess: boolean;
}

export interface SurveyEditActionProps {
  readonly onFetchSurvey: (surveyId: string) => any;
  readonly onFetchSurveyTypes: () => void;
  readonly onFetchPostCategories: () => void;
  readonly onFetchSurveyBankQuestions: (surveyId: string) => void;
  readonly onFetchManageeEmails: (userId: string) => void;
  readonly onUpdateSurvey: (surveyId: string, survey: SurveyResource) => void;
  readonly onSaveSurvey: (surveyId: string, survey: SurveyResource) => void;
  readonly onLaunchSurvey: (surveyId: string) => void;
  readonly onDisplayNotification: (payload: Notification) => void;

  // settings
  readonly onUploadSurveyLogo: (surveyId: string, file: any) => void;

  // questions
  readonly onChangeQuestionModalState: (
    isModalOpen: boolean,
    modalPurpose?: SURVEY_QUESTIONS_MODALS
  ) => void;
  readonly onAddQuestion: (
    surveyId: string,
    question: SurveyQuestionResource | SurveyBankQuestionPayload,
    queryParamObject?: Record<string, string>,
    keepModalOpen?: boolean
  ) => void;
  readonly onUpdateQuestion: (
    surveyId: string,
    questionId: string,
    question: SurveyQuestionResource,
    queryParamObject?: Record<string, string>,
    keepModalOpen?: boolean
  ) => void;
  readonly onRemoveQuestion: (surveyId: string, questionId: string) => void;
  readonly addQuestionErrors: (errorPayload: {
    error: WrongEntityException[];
  }) => void;

  // audience
  readonly onFetchParticipants: (
    surveyId: string,
    survey: SurveyResource
  ) => void;
  readonly onUpdateNewAudience: (surveyId: string) => void;
  readonly toggleDifferentialAudienceModal: (open: boolean) => void;

  // accesses
  readonly onAddAccesses: (surveyId: string, emails: string[]) => any;
  readonly onUpdateAccess: (
    surveyId: string,
    accessId: string,
    role: AccessList.UserAccessRole
  ) => any;
  readonly onRemoveAccess: (surveyId: string, accessId: string) => any;
  readonly onToggleManagersSynchronization: (
    surveyId: string,
    areManagersSynchronized: boolean
  ) => any;

  // preview
  readonly onTogglePreviewModalOpen: (open: boolean) => void;
  readonly onSendSurveyPreview: (
    surveyId: string,
    previewEmails: string[],
    message: string
  ) => void;

  readonly onExitPage: (surveyId: string) => void;
  readonly onErrorRemoveManualAudience: () => void;
  readonly onTranslationCSVUpload: (
    file: File,
    surveyId: string,
    language: LocaleCodes
  ) => void;
  readonly updateUsers: (payload: UpdateUserPermissionsPayload) => void;
}

export type SurveyEditProps = OwnProps &
  SurveyEditStateProps &
  SurveyEditActionProps & WithTranslation;

interface SurveyEditState {
  readonly isCancelSurveyCreationModalOpen: boolean;
  readonly isTranslationWarningModalOpen: boolean;
  readonly isContinueAlertModalOpen: boolean;
  readonly isAudienceModifiedModalOpen: boolean;
  readonly showBusinessHoursModal: boolean;
  readonly isInsightsAccessModalOpen: boolean;
  readonly missingFields: string[];
  readonly tempAccessListHolder: AccessResource[];
  readonly postCategories: PostCategoryResource[];
}

class SurveyEdit extends React.Component<
  SurveyEditProps,
  SurveyEditState
> {
  defaultState: SurveyEditState = {
    isCancelSurveyCreationModalOpen: false,
    isTranslationWarningModalOpen: false,
    isContinueAlertModalOpen: false,
    isAudienceModifiedModalOpen: false,
    showBusinessHoursModal: false,
    isInsightsAccessModalOpen: false,
    missingFields: [],
    tempAccessListHolder: [],
    postCategories: [],
  };
  private readonly contentWrapper: React.RefObject<HTMLDivElement>;
  private readonly AccessIntroduction: React.ReactNode = (
    <div>
      <div>
        <Trans>Decide what kind of access select employees will have to this survey.</Trans>
      </div>
      <div>
        <Trans>'View' can access the survey reports but cannot change settings or take
          action.</Trans>
      </div>
      <div>
        <Trans>'View + Messaging' can access the survey reports and initiate private
          messages with survey respondents.</Trans>
      </div>
      <div>
        <Trans>'All Access' can access survey reports and private messages but can also
          edit the survey settings.</Trans>
      </div>
      <br />
    </div>
  );

  constructor(props: SurveyEditProps) {
    super(props);
    // create a ref, in order to be able to manage the scroll to top, when navigating through steps
    this.contentWrapper = React.createRef();

    this.state = {
      ...this.defaultState,
      postCategories: props.postCategories,
    };
  }

  componentDidMount() {
    const {
      surveyId,
      onFetchSurvey,
      onFetchSurveyTypes,
      onFetchPostCategories,
      onFetchSurveyBankQuestions,
    } = this.props;

    onFetchSurvey(surveyId);
    onFetchSurveyTypes();
    onFetchPostCategories();
    onFetchSurveyBankQuestions(surveyId);
    this.redirectIfOverMaxStep();
  }

  componentDidUpdate(prevProps: SurveyEditProps): void {
    this.redirectIfOverMaxStep();
    const { 
      postCategories,
      isBulkUpdateRequestSuccess,
      onFetchSurvey,
      surveyId } = this.props;


    if (isBulkUpdateRequestSuccess && isBulkUpdateRequestSuccess !== prevProps.isBulkUpdateRequestSuccess) {
      onFetchSurvey(surveyId);
    }

    if (postCategories !== prevProps.postCategories) {
      if (postCategories.length > prevProps.postCategories.length) {
        this.setState({
          postCategories,
        });
      }
    }


    if (
      (this.props.fileUploadState.isUploadSuccess === true &&
        prevProps.fileUploadState.isUploadSuccess === false) ||
      (this.props.questionPageState.isRequestingUpdate === false &&
        prevProps.questionPageState.isRequestingUpdate === true)
    ) {
      /**
       * If the file upload state changed from "FALSE" to "TRUE",
       * fetch the survey details. This is required to show the correct
       * language count and update stats
       */
      this.props.onFetchSurvey(this.props.surveyId);
    }
  }

  redirectIfOverMaxStep = () => {
    const { survey: loadableSurvey, currentStep, steps } = this.props;

    if (Loadable.isNotLoaded(loadableSurvey)) {
      // the max reached step, has not been found, so let's not redirect yet...
      return;
    }
    const max = extractMaxReachedStepIndex(steps);
    const currentIndex = getStepIndex(steps, currentStep.key);
    if (isNotNullNorUndefined(currentIndex) && currentIndex > max) {
      this.goToStep(steps.get(max)!.key);
    }
  };

  componentWillUnmount() {
    const { surveyId, onExitPage } = this.props;

    this.setState(this.defaultState);
    onExitPage(surveyId);
  }

  goToStep = (stepKey: string) => {
    const { surveyId } = this.props;
    goTo(`/surveys/edit/${surveyId}/${stepKey}`);
  };

  moveToStep = (selectedStep: string) => {
    goTo(selectedStep);
  };

  toggleUpdateAudienceModal = () => {
    const { showUpdateAudienceModal, toggleDifferentialAudienceModal } =
      this.props;
    toggleDifferentialAudienceModal(!showUpdateAudienceModal);
  };

  onNextStep = () => {
    const {
      surveyId,
      survey: loadableSurvey,
      steps,
      currentStep,
      onUpdateSurvey,
    } = this.props;

    if (Loadable.isNotLoaded(loadableSurvey)) {
      return; // nothing to do, the survey is not loaded...
    }
    const survey = loadableSurvey.value;

    const nextStep = currentStep.nextStep;
    if (isNotNullNorUndefined(nextStep)) {
      let nextStepIndex = getStepIndex(steps, nextStep);
      if (isNullOrUndefined(nextStepIndex)) {
        log.warn(`Unable to get the index for the next step: ${nextStep}`);
        nextStepIndex = 0;
      }

      if (survey.maxReachedStep < nextStepIndex + 1) {
        onUpdateSurvey(surveyId, {
          ...survey,
          maxReachedStep: (nextStepIndex + 1) as 1 | 2 | 3 | 4 | 5,
        });
      }
      goTo(nextStep);
      // Scroll to the top of the next page
      if (isNotNullNorUndefined(this.contentWrapper.current)) {
        this.contentWrapper.current.scrollTop = 0;
      }
    }
  };

  isSurveyConsistent = () => {
    const { survey, currentStep } = this.props;

    if (Loadable.isNotLoaded(survey)) {
      return {
        isConsistent: null,
      };
    }

    const checkers =
      currentStep.label === "questions"
        ? { mandatoryPostIds: survey.value.postTemplateIds }
        : {};
    const allErrors = getSurveyResourceErrors(this.props.t, survey.value, checkers);

    const errorKeys = allErrors
      .keySeq()
      .filter((key) => LabelMapper.hasIn([currentStep, key]))
      .toArray();

    return {
      isConsistent: errorKeys.length > 0,
      errorKeys,
    };
  };

  checkSurveyConsistencyBeforeNextStep = () => {
    const {
      currentStep,
      steps,
      usersWithoutInsightsAccess,
      survey: loadableSurvey,
      canEditUser
    } = this.props;
    let survey;
    if (Loadable.isLoaded(loadableSurvey)) {
      survey = loadableSurvey.value;
    }

    const step = getStep(steps, currentStep.key);
    if (isNullOrUndefined(step)) {
      log.warn(
        `Unable to find the current step ${currentStep.key}, so unable to check if there are some errors.`
      );
      this.onNextStep();
      return;
    }

    // Open are you sure modal if survey is current step is not consistent
    if (step.status === STEP_STATUS.inError) {
      const missingFields = step.fieldsInError.map((fieldKey) =>
        translate(this.props.t, getOr(LabelMapper.getIn([currentStep.key, fieldKey]), fieldKey))
      );
      this.setState({
        isContinueAlertModalOpen: true,
        missingFields,
      });
    } else if (step.key === "access" &&
      usersWithoutInsightsAccess.length > 0 &&
      (isNotNullNorUndefined(survey) &&
        survey.status === Survey.Statuses.DRAFT) &&
      canEditUser) {
      this.setState({
        isInsightsAccessModalOpen: true,
      });
    } else {
      this.onNextStep();
    }
  };

  handleContinueModalCancelClick = () => {
    this.setState({
      isContinueAlertModalOpen: false,
      missingFields: [],
    });
  };

  handleContinueModalOkClick = () => {
    this.setState({
      isContinueAlertModalOpen: false,
      missingFields: [],
    });
    this.onNextStep();
  };

  handleInsightsAccessModalSkipClick = () => {
    this.setState({
      isInsightsAccessModalOpen: false,
    });
    this.onNextStep();
  };

  updateInsightsAccess = () => {
    const { usersWithoutInsightsAccess } = this.props;
    const usersIds = usersWithoutInsightsAccess.map((user: any) => user._id);
    const payload: UpdateUserPermissionsPayload = {
      modifications: {
        hasInsightsAccess: true,
      },
      selection: {
        type: SelectionFilter.Type.MANUAL,
        ids: usersIds,
      },
    };
    const { updateUsers, onDisplayNotification } = this.props;
    updateUsers(payload);
    onDisplayNotification(
      NotificationFactory.success("Access to Engage Insights successfully updated", "", 4.5)
    );
  };

  handleInsightsAccessModalYesClick = () => {
    this.updateInsightsAccess();
    this.handleInsightsAccessModalSkipClick();
  };

  handleGoToSurveys = () => {
    goTo("/surveys");
  };

  handleChangeSettings = (survey: SurveyResource) => {
    const {
      surveyId,
      survey: loadableSurvey,
      reportingDimensions,
      onUpdateSurvey,
    } = this.props;

    if (Loadable.isNotLoaded(loadableSurvey)) {
      return;
    }

    const previousSurvey = loadableSurvey.value;
    if (previousSurvey.type !== survey.type) {
      // we need to change the audience object to match the requirements of the new type!
      const newAudience = AudienceType.generate(
        getConfigurationFor(survey.type).audienceConfiguration,
        reportingDimensions,
        {
          configuration: getConfigurationFor(previousSurvey.type)
            .audienceConfiguration,
          audience: previousSurvey.audience,
        }
      );
      survey = {
        ...survey,
        audience: newAudience,
      };
    }
    onUpdateSurvey(surveyId, survey);
  };

  handleSave = () => {
    const { surveyId, survey: loadableSurvey, onSaveSurvey } = this.props;

    if (Loadable.isNotLoaded(loadableSurvey)) {
      return false;
    }
    onSaveSurvey(surveyId, loadableSurvey.value);
    this.showInsightsAccessModal(loadableSurvey.value.status);
    return true;
  };

  showInsightsAccessModal = (status: Survey.Status) => {
    const { currentStep, usersWithoutInsightsAccess, canEditUser } = this.props;

    if ( status !== Survey.Statuses.DRAFT && currentStep.key === "access" &&
      usersWithoutInsightsAccess.length > 0 &&
      canEditUser) {
      this.setState({
        isInsightsAccessModalOpen: true
      });
    }
  };

  handleSaveAndExit = () => {
    // fixme: we should handle the case where we will not do the save because we do have some validation errors
    this.handleSave();
    this.handleGoToSurveys();
  };

  handleChangeAudience = (audience: AudienceType) => {
    const { surveyId, survey: loadableSurvey, onUpdateSurvey } = this.props;

    if (Loadable.isNotLoaded(loadableSurvey)) {
      return false;
    }

    onUpdateSurvey(surveyId, {
      ...loadableSurvey.value,
      audience,
    });
  };

  generateMagicUrl = () => {
    const { surveyId, survey: loadableSurvey, onSaveSurvey } = this.props;

    if (Loadable.isNotLoaded(loadableSurvey)) {
      return;
    }

    if (loadableSurvey.value.isMagic) {
      return;
    }

    onSaveSurvey(surveyId, {
      ...loadableSurvey.value,
      isMagic: true,
    });
  };

  handleReorderQuestions = (questionIds: string[]) => {
    const { surveyId, survey: loadableSurvey, onUpdateSurvey } = this.props;

    if (Loadable.isNotLoaded(loadableSurvey)) {
      return false;
    }

    onUpdateSurvey(surveyId, {
      ...loadableSurvey.value,
      postTemplateIds: questionIds,
    });
  };

  handleClose = () => {
    const { survey: loadableSurvey, hasPendingUpdates } = this.props;

    if (Loadable.isNotLoaded(loadableSurvey)) {
      this.handleGoToSurveys();
      return;
    }

    const survey = loadableSurvey.value;
    if (survey.status !== Survey.Statuses.DRAFT && not(hasPendingUpdates)) {
      this.handleGoToSurveys();
    } else {
      this.setState({
        isCancelSurveyCreationModalOpen: true,
      });
    }
  };

  handleCloseCancelSurveyCreationModal = () => {
    this.setState({
      isCancelSurveyCreationModalOpen: false,
    });
  };

  handleSendSurveyPreview = (emails: string[], message: string) => {
    const { survey, onSendSurveyPreview } = this.props;
    if (Loadable.isNotLoaded(survey)) {
      return null;
    }

    onSendSurveyPreview(survey.value._id, emails, message);
  };

  handleOpenSendSurveyPreviewModal = () => {
    const { onTogglePreviewModalOpen } = this.props;

    onTogglePreviewModalOpen(true);
  };

  handleCloseSendSurveyPreviewModal = () => {
    const { onTogglePreviewModalOpen } = this.props;

    onTogglePreviewModalOpen(false);
  };

  handleCloseSendSurveyPreviewModalAndGoToQuestion = () => {
    this.handleCloseSendSurveyPreviewModal();

    this.goToStep("questions");
  };

  handleToggleManagersSynchronization = (areManagersSynchronized: boolean) => {
    this.props.onToggleManagersSynchronization(
      this.props.surveyId,
      areManagersSynchronized
    );
  };

  handleFetchParticipants = () => {
    const {
      surveyId,
      survey: loadableSurvey,
      onFetchParticipants,
    } = this.props;

    if (Loadable.isNotLoaded(loadableSurvey)) {
      return;
    }

    onFetchParticipants(surveyId, loadableSurvey.value);
  };

  handleFetchParticipantsExport = () => {
    const { surveyId } = this.props;
    downloadSurveyRecipients(surveyId);
  };

  handleAddQuestion = (
    question: SurveyQuestionResource | SurveyBankQuestionPayload,
    queryParamObject?: Record<string, string>,
    keepModalOpen?: boolean
  ) => {
    const { surveyId, onAddQuestion } = this.props;

    // if the question is a SurveyQuestionResource, we need to add the survey id.
    // fixme: there should be a more elegant way to do this.
    // fixme: Probably the caller (SurveyQuestion component) should set correctly the resource type
    if (isNotNullNorUndefined((question as any).question)) {
      question = {
        ...question,
        surveyId,
      };
    }

    onAddQuestion(
      surveyId,
      question,
      getOr(queryParamObject, {}),
      keepModalOpen
    );
  };

  handleUpdateQuestion = (
    questionId: string,
    question: SurveyQuestionResource,
    queryParams?: Record<string, string>,
    keepModalOpen?: boolean
  ) => {
    const { surveyId, onUpdateQuestion } = this.props;

    onUpdateQuestion(
      surveyId,
      questionId,
      question,
      queryParams,
      keepModalOpen
    );
  };

  handleRemoveQuestion = (questionId: string) => {
    const { surveyId, onRemoveQuestion } = this.props;

    onRemoveQuestion(surveyId, questionId);
  };

  handleAddCustomCategory = (category: any) => {
    const { postCategories } = this.state;
    this.setState({
      postCategories,
    });
  };

  handleAddAccesses = (emails: string[]) => {
    const { surveyId, onAddAccesses } = this.props;

    onAddAccesses(surveyId, emails);
  };

  handleUpdateAccess = (accessId: string, role: AccessList.UserAccessRole) => {
    const { surveyId, onUpdateAccess } = this.props;

    onUpdateAccess(surveyId, accessId, role);
  };

  handleRemoveAccess = (accessId: string) => {
    const { surveyId, onRemoveAccess } = this.props;

    onRemoveAccess(surveyId, accessId);
  };

  handleOpenAudienceModifiedModal = () => {
    this.setState({
      isAudienceModifiedModalOpen: true,
    });
  };

  handleCloseAudienceModifiedModal = () => {
    this.setState({
      isAudienceModifiedModalOpen: false,
    });
  };

  handleCloseBusinessHoursModal = () => {
    this.setState({
      showBusinessHoursModal: false,
    });
  };

  handleConfirmAudienceModifiedModal = () => {
    this.setState({
      isAudienceModifiedModalOpen: false,
    });
    this.handleSave();
  };

  getStepComponent(
    stepDefinition: StepDefinition<SurveyStepValue>
  ): React.ReactNode {
    const {
      survey: loadableSurvey,
      questions,
      surveyBankQuestions,
      questionPageState,
      dimensions,
      reportingDimensions,
      participants,
      participantsCount,
      allowedMergeTags,
      allowedChannels,
      allowedTypes,
      disabled,
      errors,
      accesses,
      eligibleManagersCount,
      manageeEmails,
      specificAudienceEmails,
      isMultiSelectQuestionEnabled,
      onChangeQuestionModalState,
      addQuestionErrors,
      questionConfig,
      onErrorRemoveManualAudience,
      magicSurveyEnabled,
      surveyValidationError,
      fileUploadState,
      currentStep
    } = this.props;
    const manageesEmailList = manageeEmails.map(user => user.email);
    let specificAudienceEmailsList: string[] = [];
    if (isNotNullNorUndefined(specificAudienceEmails)) {
      specificAudienceEmailsList = specificAudienceEmails.map(user => user.email);
    }
    const { postCategories } = this.state;

    if (Loadable.isNotLoaded(loadableSurvey)) {
      return null;
    }
    const survey = loadableSurvey.value;
    const translationFileId = isStringAndNotEmpty(
      fileUploadState?.uploadResponse?.translationFileId
    )
      ? fileUploadState?.uploadResponse?.translationFileId
      : survey.translationFileId;

    if(isNotEmptyArray(manageesEmailList) && isNotNullNorUndefined(dimensions.manager)) {
        dimensions.manager.segments = [ "N/A" , ...manageesEmailList ];
    }
    switch (stepDefinition.key) {
      case "settings":
        return (
          <Settings
            survey={survey}
            configuredCompanyLanguages={this.props.surveyLanguageList}
            isMultiLanguageSurveyEnabled={this.props.isMultiLangSurveyEnabled}
            configuredSurveylanguages={survey.surveyLanguages as string[]}
            allowedMergeTags={allowedMergeTags}
            allowedChannels={allowedChannels}
            allowedTypes={allowedTypes}
            disabled={disabled}
            errors={errors.fields}
            onChange={this.handleChangeSettings}
            onUploadSurveyLogo={this.handleUploadSurveyLogo}
          />
        );
      case "questions":
        return (
          <IsMultiSelectContext.Provider value={isMultiSelectQuestionEnabled}>
            <SurveyQuestions
              onTranslationCSVUpload={this.props.onTranslationCSVUpload}
              fileUploadState={this.props.fileUploadState}
              configuredSurveyLanguages={filterEnglishCodes(
                getOr(survey.surveyLanguages, [])
              )}
              isMultiLanguageSurveyEnabled={this.props.isMultiLangSurveyEnabled}
              surveyTranslatedLanguages={survey.translatedLanguages}
              surveyStatus={survey.status}
              surveyId={survey._id}
              errors={questionPageState.errors}
              surveyBankQuestions={surveyBankQuestions}
              questions={reOrderById(questions, survey.postTemplateIds)}
              questionsPage={questionPageState}
              postCategories={postCategories}
              onToggleQuestionsModalState={onChangeQuestionModalState}
              onReorderQuestions={this.handleReorderQuestions}
              onAddQuestion={this.handleAddQuestion}
              onUpdateQuestion={this.handleUpdateQuestion}
              onRemoveQuestion={this.handleRemoveQuestion}
              onAddCustomCategory={this.handleAddCustomCategory}
              disabled={
                isNotNullNorUndefined(disabled.postTemplateIds) &&
                disabled.postTemplateIds
              }
              addQuestionErrors={addQuestionErrors}
              questionConfig={questionConfig}
              updatedQuestion={this.props.updatedQuestion}
              translationFileId={translationFileId}
            />
          </IsMultiSelectContext.Provider>
        );
      case "audience":
        return (
          <AudienceWrapper>
            <Audience
              audience={survey.audience}
              configuration={
                getConfigurationFor(survey.type).audienceConfiguration
              }
              dimensions={dimensions}
              reportingDimensions={reportingDimensions}
              manualEmails={
                (isNotEmptyArray(specificAudienceEmailsList) ?
                  specificAudienceEmailsList :
                  manageesEmailList)
              }
              participants={participants}
              participantsCount={participantsCount}
              dateCriteriaTexts={this.generateDateCriteriaTexts(survey.type)}
              disabled={
                isNotNullNorUndefined(disabled.audience) && disabled.audience
              }
              // Note: plug the users' groups as soon as we will have groups in audience (i.e. when voice will be there)
              allowToUseGroups={false}
              groups={[]}
              canRemoveManualAudience={survey.status === Survey.Statuses.DRAFT}
              canAddManualAudience={survey.status !== Survey.Statuses.CLOSED}
              cannotEditCriteriaAudience={
                survey.status === Survey.Statuses.CLOSED ||
                (survey.status === Survey.Statuses.LAUNCHED &&
                  (survey.audience.everyoneInTheCompany ||
                    isNotNullNorUndefined(
                      survey.audience.dynamicCriteria.dateCriteria
                    )))
              }
              hasPendingAudienceUpdates={this.props.hasPendingAudienceUpdates}
              onChange={this.handleChangeAudience}
              onFetchParticipants={this.handleFetchParticipants}
              fetchParticipantsExport={this.handleFetchParticipantsExport}
              onErrorRemoveManualAudience={onErrorRemoveManualAudience}
            />
            {magicSurveyEnabled && survey.status !== Survey.Statuses.CLOSED && (
              <MagicSurveySection
                isMagicSurvey={survey.isMagic}
                generateUrl={this.generateMagicUrl}
                surveyValidationError={surveyValidationError}
                surveyUrl={survey.magicUrl}
              />
            )}
          </AudienceWrapper>
        );
      case "access":
        return (
          <Access
            AccessIntroduction={this.AccessIntroduction}
            title="Survey Access"
            accesses={accesses}
            areManagersSynchronized={getOr(
              survey.areManagersSynchronized,
              false
            )}
            onToggleManagersSynchronization={
              this.handleToggleManagersSynchronization
            }
            allowedEmails={manageesEmailList}
            eligibleManagersCount={eligibleManagersCount}
            onAddNewAccesses={this.handleAddAccesses}
            onUpdateAccess={this.handleUpdateAccess}
            onRemoveAccess={this.handleRemoveAccess}
            showInsightsAccessModal={() => this.showInsightsAccessModal(survey.status)}
          />
        );
      case "summary":
        return (
          <>
            <SurveySummary
              survey={survey}
              questions={questions}
              participantsCount={participantsCount}
              accesses={accesses}
              allowedChannels={allowedChannels}
              isMagicSurveyEnabled={this.props.magicSurveyEnabled}
              questionConfig={questionConfig}
            />
            <MissingTranslationAlert
              onCancelClick={this.handleMissingTranslationCancelClick}
              onOkClick={this.handleFinalLaunch}
              currentStep={currentStep.label}
              translationWarningStatus={this.state.isTranslationWarningModalOpen}
            />
          </>
        );
    }
  }

  generateDateCriteriaTexts = (
    type: Survey.Type
  ): Optional<Partial<DateCriteriaTexts>> => {
    /* eslint-disable max-len */
    switch (type) {
      case Survey.Types.ONBOARDING:
        return {
          text: "How many days after Hire date should the survey be sent?",
          hoverText:
            "Survey will be automatically sent to audience once they cross these number of days after their hire date",
        };
      case Survey.Types.OFFBOARDING:
        return {
          text: "How many days before termination date should the survey be sent?",
          hoverText:
            "Survey will be automatically sent to audience once they have these number of days remaining before their termination date",
        };
      case Survey.Types.ALUMNI:
        return {
          text: "How many days after termination date should the survey be sent?",
          hoverText:
            "Survey will be automatically sent to audience once they cross these number of days after their termination date",
        };
      case Survey.Types.PRE_HIRE:
        return {
          text: "How many days before hire date should the survey be sent?",
          hoverText:
            "Survey will be automatically sent to audience once they have these number of days remaining before their hire date",
        };
      case Survey.Types.HIRING_MANAGER:
        return {
          text: "How many days post employee's hire date should the survey be sent to their manager? ",
          hoverText:
            "Survey will be automatically sent to audience once they have these number of days remaining before their hire date",
        };
    }
    /* eslint-disable max-len */

    return Optional.empty();
  };

  handleUploadSurveyLogo = (err: any, file: any) => {
    const { surveyId, onDisplayNotification, onUploadSurveyLogo } = this.props;

    if (err.type) {
      const message =
        err.type === "FileTypeError"
          ? "Sorry, that file isn't right"
          : "File too big";
      onDisplayNotification(
        NotificationFactory.error(message, err.description, 4.5)
      );
    } else {
      onUploadSurveyLogo(surveyId, file);
    }
  };

  handleMissingTranslationCancelClick = () => {
    this.setState({
      isTranslationWarningModalOpen: false
    });
  };

  handleLaunch = () => {
    const { survey: loadableSurvey, questions,
      company: { startTime, endTime, daysOfWeek }
    } = this.props;
    if (Loadable.isNotLoaded(loadableSurvey)) {
      return null;
    }
    const survey = loadableSurvey.value;
    const isLanguageTranslationAdded = isNotEmptyArray(filterEnglishCodes(getOr(survey.surveyLanguages, []))) &&
      getOverallSurveyTranslationStatus(
        survey.surveyLanguages as string[],
        survey,
        questions
      );
    if (isLanguageTranslationAdded) {
      this.setState({
        isTranslationWarningModalOpen: true
      });
    } else if (!checkIfBusinessHours(startTime, endTime, daysOfWeek)) {
      this.setState({
        showBusinessHoursModal: true
      });
    } else {
      this.handleFinalLaunch();
    }
  };

  handleFinalLaunch = () => {
    const { surveyId, onLaunchSurvey } = this.props;

    onLaunchSurvey(surveyId);
  };

  updateNewAudience = () => {
    const { surveyId, onUpdateNewAudience } = this.props;

    onUpdateNewAudience(surveyId);
  };

  checkBlockNavigation = (currentLocation: any, nextLocation: any) => {
    const { hasPendingUpdates, surveyId } = this.props;
    const { isCancelSurveyCreationModalOpen } = this.state;
    const isNavigatingAway =
      !nextLocation ||
      !nextLocation.pathname.startsWith(`/surveys/edit/${surveyId}`);

    return (
      isNavigatingAway &&
      hasPendingUpdates &&
      not(isCancelSurveyCreationModalOpen)
    );
  };

  render() {
    const {
      survey: loadableSurvey,
      updatedAt,
      errors,
      steps,
      currentStep,
      hasPendingUpdates,
      hasPendingAudienceUpdates,
      manageeEmails,
      isPreviewModalOpen,
      previewErrors,
      newUsersCount,
      participantsCount,
      usersWithoutInsightsAccess,
      company: {
        startTime,
        endTime
      }
    } = this.props;
    const isParticipantsCountError = participantsCount.status === "in-error";
    const isParticipantsCountForDimensionsError = participantsCount.status === "in-error"
      && participantsCount.error.errorCode === WrongEntityException.ErrorCodes.EMPTY_DIMENSION_MANUALCRITERIA;
    const {
      isCancelSurveyCreationModalOpen,
      isContinueAlertModalOpen,
      isAudienceModifiedModalOpen,
      missingFields,
      showBusinessHoursModal,
      isInsightsAccessModalOpen
    } = this.state;
    const textMessage = <Trans i18nKey="outOfBusinessHoursMessageForSurvey"
      values={{ startTime: hourOfDay[startTime], endTime: hourOfDay[endTime] }}
      defaults={`It’s currently outside the company business hours
        (${hourOfDay[startTime]} to ${hourOfDay[endTime]} ),
        Survey notifications will be automatically sent to the 
        employees during business hours.
        Do you want to schedule this survey launch email ?`} />;
    const manageesEmail = manageeEmails.map(user => user.email);
    if (Loadable.isNotLoaded(loadableSurvey)) {
      return (
        <CreationLayout
          title={""}
          navbarActions={[]}
          onCloseIconClick={this.handleClose}
          contentRef={this.contentWrapper}
        >
          {Loadable.isLoading(loadableSurvey) && (
            <ContainerCard>
              <SpinContainer>
                <StyledSpinCol>
                  <Spin />
                </StyledSpinCol>
              </SpinContainer>
            </ContainerCard>
          )}
          {Loadable.isInError(loadableSurvey) && (
            <FetchError
              {...loadableSurvey}
              resourceType={SurveyResource.TYPE}
            />
          )}
        </CreationLayout>
      );
    }

    const survey = loadableSurvey.value;

    const nextStep = currentStep.nextStep;
    const nextStepLabel = Optional.flatMap(nextStep, (step) =>
      getStepLabel(getAllSurveyStepDefinitions(), step)
    );

    const maxReachedStepIndex = extractMaxReachedStepIndex(steps);

    const getErrorMessageForField = (errors: WrongEntityException.Errors) => {
      const errorMessages: Optional<string>[] = [];
      steps.map((step: any) => {
        return step.fieldsInError.map((fieldInError: string)  => {
          const errorFieldReason = errors.fields.get(fieldInError);
          const fieldKeyLabel = getOr(
            LabelMapper.getIn([step.key, fieldInError]),
            fieldInError
          );
          const value = toHumanReadableErrorMessage(
            this.props.t,
            errorFieldReason,
            fieldKeyLabel
          );
          return errorMessages.push(value);
        });
      });
     
      return errorMessages.map((errorMessage, index) => (
        <Content key={index}>{errorMessage}</Content>
      ));
    };
    const returnHeader = () => {
      if (survey.status === Survey.Statuses.DRAFT) {
        return "Create a new survey";
      } else if (survey.status === Survey.Statuses.LAUNCHED) {
        return "Edit in progress survey";
      } else {
        return "Edit closed survey";
      }
    };

    const shouldShowUpdateAudienceModal = () => {
      return (
        survey.status === Survey.Statuses.LAUNCHED &&
        survey.audience.everyoneInTheCompany &&
        newUsersCount > 0
      );
    };

    const updatedTime = moment(updatedAt).format("MMMM Do YYYY, h:mm:ss a");

    return (
      <CreationLayout
        title={returnHeader()}
        navbarActions={[
          <span key="time">
            {survey.status === Survey.Statuses.DRAFT ?
              <Trans i18nKey="autoSaveDate" values={{ date: updatedTime }}
                defaults={`Saved automatically at ${updatedTime}`} />
              : <Trans i18nKey="saveDate" values={{ date: updatedTime }}
                defaults={`Saved at ${updatedTime}`} />}
          </span>,
        ]}
        onCloseIconClick={this.handleClose}
        contentRef={this.contentWrapper}
      >
        <Row type="flex" justify="center">
          <Col span={20}>
            <StyledStepper
              isParticipantError={isParticipantsCountError}
              steps={steps}
              currentStep={currentStep.key}
              onStepClick={this.moveToStep}
            />
          </Col>
        </Row>
        {isNotEmptyArray(errors.global) && (
          <Row type="flex" justify="center">
            <GlobalErrors errors={errors.global} />
          </Row>
        )}
        {this.getStepComponent(currentStep)}
        <ActionContainer>
          {survey.status !== Survey.Statuses.DRAFT && (
            <Button
              disabled={
                WrongEntityException.hasErrors(errors) || not(hasPendingUpdates)
              }
              data-cy="surveyEdit_applyChanges"
              onClick={
                hasPendingAudienceUpdates
                  ? this.handleOpenAudienceModifiedModal
                  : this.handleSave
              }
              translate="yes"
            >
              Apply Changes
            </Button>
          )}
          {shouldShowUpdateAudienceModal() && (
            <>
              <UpdateAudienceModal
                isModalOpen={this.props.showUpdateAudienceModal}
                onHideModal={this.toggleUpdateAudienceModal}
                additionalUsersCount={newUsersCount}
                onConfirmModal={this.updateNewAudience}
              />
              <Button
                onClick={this.toggleUpdateAudienceModal}
                data-jest="surveyEdit_update_audience"
                translate="yes"
              >
                Update Audience
              </Button>
            </>
          )}
          {
            // weird way to check that the preview is allowed, maybe this should be part of the step definition
            // we should avoid to have to refer to step indexes
            maxReachedStepIndex > 1 && (
              <Button
                color="grey"
                onClick={this.handleOpenSendSurveyPreviewModal}
                data-cy="surveyCreation_audience_preview"
                translate="yes"
              >
                Send Survey Preview
              </Button>
            )
          }
          {isNotNullNorUndefined(nextStepLabel) ? (
            <NextButton
              nextStep={nextStepLabel}
              onClick={this.checkSurveyConsistencyBeforeNextStep}
            />
          ) : (
            survey.status === Survey.Statuses.DRAFT && (
              <Button
                color="blue"
                disabled={
                  WrongEntityException.hasErrors(errors) ||
                  hasPendingUpdates ||
                  isParticipantsCountError
                }
                onClick={this.handleLaunch}
                data-cy="surveyCreation_launch"
                data-jest="surveyLaunch"
                translate="yes"
              >
                Launch
              </Button>
            )
          )}

        </ActionContainer>
        <ContinueAlert
          visible={isContinueAlertModalOpen}
          missingFields={missingFields}
          onCancelClick={this.handleContinueModalCancelClick}
          onOkClick={this.handleContinueModalOkClick}
          currentStep={currentStep.label}
          isParticipantsCountForDimensionsError={isParticipantsCountForDimensionsError}
        />
        <InsightsAccessAlter
          visible={isInsightsAccessModalOpen}
          onCancelClick={this.handleInsightsAccessModalSkipClick}
          onOkClick={this.handleInsightsAccessModalYesClick}
          usersWithoutInsightsAccess={usersWithoutInsightsAccess}
          isSurvey={true}
        />
        {isPreviewModalOpen && isEmpty(survey.postTemplateIds) && (
          <AreYouSureModal
            visible={isPreviewModalOpen}
            title="Survey Incomplete"
            description={<><Trans>Before we can send a preview, you need to fix the bellow validation errors</Trans>
              <br />
                {getErrorMessageForField(errors)}
              </>}
            onOk={this.handleCloseSendSurveyPreviewModalAndGoToQuestion}
            onCancel={this.handleCloseSendSurveyPreviewModal}
            data-cy="surveyCreation_preview_incompleteSurvey"
            okLabel="Add Questions"
            hideCancelButton={true}
          />
        )}

        {isPreviewModalOpen &&
          isNotEmptyArray(survey.postTemplateIds) &&
          WrongEntityException.hasErrors(errors) && (
            <AreYouSureModal
              visible={isPreviewModalOpen}
              title="Survey Incomplete"
              description={<><Trans>Before we can send a preview, you need to fix the bellow validation errors</Trans>
                <br />
                {getErrorMessageForField(errors)}
                </>}
              onOk={this.handleCloseSendSurveyPreviewModal}
              onCancel={this.handleCloseSendSurveyPreviewModal}
              data-cy="surveyCreation_preview_incompleteSurvey"
              okLabel="Add Questions"
              hideCancelButton={true}
              hideOkButton={true}
            />
          )}
        {isPreviewModalOpen &&
          isNotEmptyArray(survey.postTemplateIds) &&
          WrongEntityException.hasNoErrors(errors) && (
            <SendPreviewModal
              visible={isPreviewModalOpen}
              title={<Trans>Send Survey Preview</Trans>}
              centered
              onCancel={this.handleCloseSendSurveyPreviewModal}
              destroyOnClose={true}
              footer={null}
            >
              <SendSurveyPreviewModalContent
                emails={manageesEmail}
                onClick={this.handleSendSurveyPreview}
                errors={previewErrors}
              />
            </SendPreviewModal>
          )}
        {survey.status === Survey.Statuses.DRAFT &&
          isCancelSurveyCreationModalOpen && (
            <AreYouSureModal
              visible={isCancelSurveyCreationModalOpen}
              title="Are you sure?"
              /* eslint-disable-next-line max-len */
              description="If you want to stop editing this survey right now, a draft will be saved and you can access it from the survey list."
              onOk={this.handleGoToSurveys}
              onCancel={this.handleCloseCancelSurveyCreationModal}
              okLabel="Yes"
              cancelLabel="Keep Editing"
            />
          )}
        {isAudienceModifiedModalOpen && (
          <AreYouSureModal
            visible={isAudienceModifiedModalOpen}
            title="Are you sure?"
            /* eslint-disable-next-line max-len */
            description="Updating the Audience for this survey will send the survey to the new recipients you have added. Are you sure you want to continue?"
            okLabel={"Save"}
            onCancel={this.handleCloseAudienceModifiedModal}
            onOk={this.handleConfirmAudienceModifiedModal}
          />
        )}
        {showBusinessHoursModal && (
          <AreYouSureModal
            visible={showBusinessHoursModal}
            title="Are you sure?"
            cancelLabel={`Back to ${currentStep.label}`}
            /* eslint-disable-next-line max-len */
            description={textMessage}
            okLabel={"Launch Survey"}
            onCancel={this.handleCloseBusinessHoursModal}
            onOk={this.handleFinalLaunch}
          />
        )}
        <NavigationPrompt
          // @ts-ignore
          when={this.checkBlockNavigation}
        >
          {({ isActive, onConfirm }) => {
            if (isActive) {
              return (
                <AreYouSureModal
                  visible={true}
                  title="You have unsaved changes"
                  description="Click Save to save changes and exit or Cancel to exit without saving."
                  okLabel={"Save"}
                  onCancel={onConfirm}
                  // tslint:disable-next-line:jsx-no-lambda
                  onOk={() => {
                    this.handleSave();
                    onConfirm();
                  }}
                />
              );
            }
          }}
        </NavigationPrompt>
        {isCancelSurveyCreationModalOpen && hasPendingUpdates && (
          // @ts-ignore
          <StyledModal
            visible={true}
            footer={null}
            onCancel={this.handleCloseCancelSurveyCreationModal}
          >
            <ModalContent>
              <Heading translate="yes">You have unsaved changes.</Heading>
              <br />
              <Paragraph translate="yes">
                Please click one of the following Options to continue:
              </Paragraph>
              <Paragraph>
                - "<Trans>Discard Changes & Exit</Trans>" - <Trans>To exit this page without saving
                  your changes click</Trans>
              </Paragraph>
              <Paragraph translate="yes">
                - "<Trans>Stay on Page</Trans>" - <Trans>To remain on this page & continue editing
                  click</Trans>
              </Paragraph>
              <Paragraph translate="yes">
                - "<Trans>Confirm Changes & Exit</Trans>" - <Trans>To exit this page and SAVE your
                  changes click</Trans>
              </Paragraph>
              <br />
            </ModalContent>
            <ActionContainerModal>
              <StyledButton onClick={this.handleGoToSurveys} translate="yes">
                Discard Changes & Exit
              </StyledButton>
              <StyledButton onClick={this.handleCloseCancelSurveyCreationModal} translate="yes">
                Stay on Page
              </StyledButton>
              <StyledButton color="blue" onClick={this.handleSaveAndExit} translate="yes">
                Confirm Changes & Exit
              </StyledButton>
            </ActionContainerModal>
          </StyledModal>
        )}
      </CreationLayout>
    );
  }
}

export const SurveyEditComponent = withTranslation()(SurveyEdit);

export const StyledStepper = styled(Stepper)`
  margin-bottom: 48px !important;
`;

const SendPreviewModal = styled(Modal) <
  ModalProps & { children: React.ReactNode }
>`
  width: 500px !important;
`;

const SpinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 250px);
`;

const StyledSpinCol = styled.div`
  text-align: center;
`;

const ModalContent = styled.div`
  margin-top: 10px;
  padding-left: 32px !important;
  padding-right: 32px !important;
`;

const StyledModal = styled(Modal)`
  width: 800px !important;
`;

const ActionContainerModal = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-around;
  // padding-left: 32px !important;
  padding-right: 32px !important;
`;

const StyledButton = styled(Button as any)`
  margin-left: 32px;
  min-width: 150px;
`;

const AudienceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Content = styled.section`
  color: ${Palette.alizarin};
`;

const mapStateToProps: MapStateToProps<SurveyEditStateProps, OwnProps, State> =
  (state: State, ownProps: OwnProps): SurveyEditStateProps => {
    const surveyId = ownProps.match.params.id;
    const survey = getSurvey(state, surveyId);
    const loadableNewUsersCount = Store.Element.toLoadable(
      getResourceById(
        state,
        DifferentialAudienceCountResource.TYPE,
        DifferentialAudienceCountResource.generateKey(surveyId)
      )
    );

    const newUsersCount = Loadable.mapOr(
      loadableNewUsersCount,
      (c) => c.newUserCount,
      0
    );
    const companyResource = getCompany(state);
    const isMultiSelectQuestionEnabled = mapOr(
      companyResource,
      isSurveyMultiSelectEnabled,
      false
    );
    const magicSurveyEnabled = isMagicSurveyEnabled(companyResource!);
    const surveyValidationError = getSurveyValidationErrors(state);
    const appSettings = getOr(
      getAppSettings(state).get(0),
      {} as AppSettingsResource
    );

    const manageeEmails = Store.Element.mapIfLoadedOr(
      getUntypedElementById<UserEmailInsightAccess[]>(
        state,
        getManageesId(
          Loadable.mapOr(
            survey,
            (s) =>
              getConfigurationFor(s.type).audienceConfiguration
                .statusRestriction,
            Optional.empty() as string
          )
        )
      ),
      identity,
      []
    );

    const accesses = getSurveyAccesses(state);
    const usersWithoutInsightsAccess = accesses.map((access) => {
      if (not(access.hasOwnProperty("isInsightUser"))) {
        const user = manageeEmails.find(manageeEmail => manageeEmail.email === access.email);
        if (isNotNullNorUndefined(user)) {
          access = {
            ...access,
            isInsightUser: user.isInsightUser ? true : false,
            _id: user._id,
          };
        };
      }
      return access;
    }).filter(access => not(access.isInsightUser) && access.role !== "blocked");

    const canEditUser = getRightsMatcher(state).hasRight(Rights.User.EDIT_USERS);
    const { isBulkUpdateRequestSuccess } = getUserPermissionsStateProps(state);
    return {
      userId: getCurrentUserId(state)!,
      surveyId,
      survey,
      canEditUser,
      updatedAt: getSurveyUpdatedAt(state),
      disabled: getDisabledSurveyFields(state),
      allowedTypes: Store.Page.toList(
        getExistingAllPage(state, SurveyTypeResource.TYPE)
      ),
      allowedMergeTags: Loadable.mapOr(
        survey,
        (loadedSurvey) =>
          Store.Page.toList(
            getExistingNotPaginatedPage(
              state,
              MergeTagResource.TYPE,
              loadedSurvey.type
            )
          ),
        []
      ),
      dimensions: getOr(getImposedDimensions(state), {}),
      reportingDimensions: getOr(getReportingDimMgmts(state), {}),
      errors: getSurveyValidationErrors(state),
      steps: getSurveySteps(state),
      currentStep: getStepByKey(
        getAllSurveyStepDefinitions(),
        ownProps.match.params.currentStepKey,
        "settings"
      ),
      allowedChannels: mapOr(
        companyResource,
        (company) => company.channels,
        {}
      ),
      questionConfig: getCompany(state)!.questionConfig,
      hasPendingUpdates: hasSurveyPendingUpdates(state),
      hasPendingAudienceUpdates: hasSurveyPendingAudienceUpdates(state),
      participantsCount: Loadable.flatMap(survey, (loadedSurvey) =>
        Store.Element.toLoadable(
          getResourceById(
            state,
            ParticipantCountResource.TYPE,
            ParticipantCountResource.generateKey(
              surveyId,
              loadedSurvey.audience
            )
          )
        )
      ),
      participants: Loadable.flatMap(survey, (loadedSurvey) =>
        Store.Page.toLoadable(
          getExistingNotPaginatedPage(
            state,
            ParticipantResource.TYPE,
            ParticipantResource.generateKey(surveyId, loadedSurvey.audience)
          )
        )),
      usersWithoutInsightsAccess,
      showUpdateAudienceModal: getUpdateAudienceModalOpen(state),
      manageeEmails,

      specificAudienceEmails: getSpecificAudienceEmails(state, survey),

      // questions
      questions: getSurveyQuestions(state),
      surveyBankQuestions: Store.Page.toList(
        getExistingAllPage(state, SurveyBankQuestionResource.TYPE)
      ),
      postCategories: Store.Page.toList(
        getExistingAllPage(state, PostCategoryResource.TYPE)
      ),
      questionPageState: getSurveyQuestionPageState(state),
      updatedQuestion: getSurveyUpdatedQuestionState(state),
      // accesses
      accesses,
      eligibleManagersCount: Loadable.flatMap(survey, (loadedSurvey) =>
        Store.Element.toLoadable(
          getUntypedElementById<number>(
            state,
            getEligibleManagersCountId(surveyId, loadedSurvey.audience)
          )
        )
      ),

      // preview
      isPreviewModalOpen: getSurveyPreviewModalState(state),
      previewErrors: getSurveyPreviewErrors(state),
      newUsersCount,
      isMultiSelectQuestionEnabled,
      magicSurveyEnabled,
      surveyValidationError,
      fileUploadState: getFileUploadState(state),
      surveyLanguageList: getOr(appSettings.surveyLanguages, []),
      isMultiLangSurveyEnabled:
        isNotNullNorUndefined(companyResource) &&
        isMultiLanguageSurveyEnabled(companyResource),
      company: getOr(companyResource, {} as CompanyResource),
      isBulkUpdateRequestSuccess
    };
  };

const mapDispatchToProps: SurveyEditActionProps = {
  onFetchSurvey: surveyEditActionCreators.fetchSurvey,
  onFetchSurveyTypes: fetchSurveyTypesIfNeeded,
  onFetchPostCategories: fetchPostCategoriesIfNeeded,
  onFetchSurveyBankQuestions: fetchSurveyBankQuestionsIfNeeded,
  onFetchManageeEmails: fetchManageesIfNeeded,
  onUpdateSurvey: surveyEditActionCreators.updateSurvey,
  onSaveSurvey: surveyEditActionCreators.saveSurvey,
  onUploadSurveyLogo: surveyEditActionCreators.uploadSurveyLogo,
  onDisplayNotification: displayNotification.displayNotification,
  onFetchParticipants: surveyEditActionCreators.fetchParticipants,
  onChangeQuestionModalState: surveyEditActionCreators.changeQuestionModalState,
  onAddQuestion: surveyEditActionCreators.addSurveyQuestion,
  onUpdateQuestion: surveyEditActionCreators.updateSurveyQuestion,
  onRemoveQuestion: surveyEditActionCreators.removeSurveyQuestion,
  onAddAccesses: surveyEditActionCreators.addAccesses,
  onUpdateAccess: surveyEditActionCreators.updateAccess,
  onRemoveAccess: surveyEditActionCreators.removeAccess,
  onToggleManagersSynchronization:
    surveyEditActionCreators.toggleManagersSynchronization,
  onLaunchSurvey: surveyEditActionCreators.launchSurvey,
  onTogglePreviewModalOpen:
    surveyEditActionCreators.toggleSurveyPreviewModalOpen,
  toggleDifferentialAudienceModal:
    surveyEditActionCreators.toggleDifferentialAudienceModal,
  onSendSurveyPreview: surveyEditActionCreators.sendSurveyPreview,
  onExitPage: surveyEditActionCreators.exitPage,
  onUpdateNewAudience: surveyEditActionCreators.updateSurveyNewAudience,
  addQuestionErrors: surveyEditActionCreators.addQuestionErrors,
  onErrorRemoveManualAudience:
    surveyEditActionCreators.errorRemoveManualAudience,
  onTranslationCSVUpload: xlsxActionCreators.uploadXLSX,
  updateUsers: userPermissionsActionCreators.updateUsers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyEditComponent);

