import { Switch } from "antd";
import styled from "styled-components";
import { AppSettingsResource } from "hyphen-lib/dist/domain/resource/AppSettingsResource";
import { Heading, Paragraph } from "@components/core/Typography/index";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { Trans } from "react-i18next";
import useToggle from "@src/utils/hooks/useToggle";
import { Company } from "hyphen-lib/dist/domain/Company";
interface AnonymousBannerProps {
  appSettings: AppSettingsResource;
  showAnonymousBanner : boolean;
  onAnonymousBannerChange: (values: AppSettingsResource) => void;
}

export function AnonymousBanner({
  appSettings,
  onAnonymousBannerChange,
  showAnonymousBanner }:
  AnonymousBannerProps) {
  const [anonymousBanner, toggleAnonymousBanner] = useToggle(showAnonymousBanner);
  const onBannerChange = () => {
    onAnonymousBannerChange({
      ...appSettings,
      modules: {
        ...appSettings.modules,
        anonymousBanner: not(anonymousBanner)
      } as Company.Modules
    });
    toggleAnonymousBanner(!anonymousBanner);
  };
  return (
    <>
      <Heading translate="yes">Banner messages</Heading>
      <Holder>
        <Description>
          <Trans>Show “Your answers are 100% anonymous” / “Your answers in this survey/poll are not anonymous”
          at the top of survey/poll screens.</Trans>
        </Description>
        <Switch
          checked={anonymousBanner}
          onChange={onBannerChange}
        />
      </Holder>
    </>
  );
}

const Holder = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
`;
const Description = styled(Paragraph)`
  padding-right:88px;
  margin-bottom: 1em;
`;