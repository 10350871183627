import Palette from "@src/config/theme/palette";
import { InputProps } from "antd/lib/input";
import React from "react";
import styled from "styled-components";


interface Props extends InputProps {
    info?: string;
    className?: string;
}

interface OwnState {
    disabled?: boolean;
    value? :string;
}
export class OnOffSwitch extends React.Component<Props, OwnState> {
  constructor(props: any) {
    super(props);
    this.state = {
      disabled: props.disabled,
      value: props.value,
    };
  }

  render() {
    return (
      <Label data-cy={"toggleButton"} onClick={this.props.onClick as any}>
        <Input type="checkbox" {...this.props as any} checked={this.props.checked}/>
        <Span />
      </Label>
    );
  }
}

const Span = styled.span`
  position: absolute;
  cursor: pointer;
  display: inline-block;
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 15px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 140px;
  height: 40px;
  background-color: ${Palette.lightPeriwinkle};
  -webkit-transition: 0.4s;
  border-radius: 8px;
  transition: 0.4s;
  :after {
    position: absolute;
    content: "\\2713\\000A0\\000A0On";
    display: flex;
    justify-content: center;
    color: ${Palette.dimGray};
    align-items: center;
    height: 32px;
    width: 63px;
    left: 4px;
    bottom: 4px;
    background-color: ${Palette.lightPeriwinkle};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    z-index: 1;
    border-radius: 8px;
  }
  :before {
    position: absolute;
    content: "\\2715\\000A0\\000A0Off";
    display: flex;
    align-items: center;
    justify-content: center;
    color: red;
    height: 32px;
    width: 63px;
    left: 70px;
    bottom: 4px;
    background-color: ${Palette.white};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    z-index: 2;
    border-radius: 4px;
  }
`;
const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  &:focus + span {
    box-shadow: 0 0 1px ${Palette.dodgerBlue};
  }
  :checked + span::before {
    -webkit-transform: translateX(-64px);
    -ms-transform: translateX(-64px);
    transform: translateX(-64px);
    content: "\\2713\\000A0\\000A0On";
    color: ${Palette.greenBlue};
  }
  :checked + span::after {
    -webkit-transform: translateX(59px);
    -ms-transform: translateX(59px);
    transform: translateX(59px);
    content: "\\2715\\000A0\\000A0Off";
    left: 14px;
    color: ${Palette.dimGray};
  }
`;
const Label = styled.label`
  display: block;
  position: relative;
  width: 140px;
  height: 40px;
  border-radius: 8px;
`;