import { default as networkActionCreators } from "@store/network/actions";
import { createRequest } from "@src/utils/networks";
import { SurveyResource } from "hyphen-lib/dist/domain/resource/SurveyResource";
import { ParticipantResource } from "hyphen-lib/dist/domain/resource/participants/ParticipantResource";
import { PulsePollResource } from "hyphen-lib/dist/domain/resource/PulsePollResource";
import { Post } from "hyphen-lib/dist/domain/Post";

export function fetchSurveyParticipantsIfNeeded(surveyId: string,
  survey: SurveyResource,
  pendingAudience = false) {

  // we have two cases, we can get the audience from what is in database, or using the audience of the
  // specified survey, it will depend on the survey status

  const requestForPendingAudience =
    createRequest(`/surveys/${surveyId}/audience/participants`, {
      method: "POST",
      body: JSON.stringify(survey),
      headers: {
        "Content-Type": "application/json",
      },
    });
  const requestForAudienceInDatabase = createRequest("/surveys/" + surveyId + "/participants");

  const request = pendingAudience ?
    requestForPendingAudience :
    requestForAudienceInDatabase;

  return networkActionCreators.fetchParticipantPage({
    id: ParticipantResource.generateKey(surveyId, survey.audience),
    type: ParticipantResource.TYPE,
    request: () => request,
  });
}

export function fetchPulsePollParticipantsIfNeeded(
  pulsePollId: string,
  pulsePoll: PulsePollResource
) {
  // we have two cases, we can get the audience from what is in database, or using the audience of the
  // specified poll, it will depend on the poll status

  const requestForPendingAudience = createRequest(
    `/pulsePolls/${pulsePollId}/audience/participants`,
    {
      method: "POST",
      body: JSON.stringify(pulsePoll),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const requestForAudienceInDatabase = createRequest(
    "/pulsePolls/" + pulsePollId + "/participants"
  );

  const request =
    pulsePoll.status === Post.Status.ACTIVE
      ? requestForPendingAudience
      : requestForAudienceInDatabase;

  return networkActionCreators.fetchParticipantPage({
    id: ParticipantResource.generateKey(pulsePollId, pulsePoll.audience),
    type: ParticipantResource.TYPE,
    request: () => request,
  });
}
