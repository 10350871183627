import React from "react";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { Trans } from "react-i18next";

const TopicsLegend = () => (
  <Container>
    <div>
      <Positive />
      <Trans>Positive sentiment</Trans>
    </div>
    <div>
      <Neutral />
      <Trans>Neutral sentiment</Trans>
    </div>
    <div>
      <Negative />
      <Trans>Negative sentiment</Trans>
    </div>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  color: ${Palette.bluishGrey};
  justify-content: flex-end;
  margin-bottom: 8px;
  div {
    margin-right: 16px;
    flex-direction: row;
  }

  div:last-child {
    margin-right: 0;
  }

  div > span {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 8px;
  }
`;

export const Label = styled.span`
  width: 130px !important;
  font-family: Lato;
  color: ${Palette.veryDarkBlueGrey};
`;

export const Positive = styled.span`
  background-color: ${Palette.aquaBlue};
`;

export const Neutral = styled.span`
  background-color: ${Palette.bluishGrey};
`;

export const Negative = styled.span`
  background-color: ${Palette.darkPink};
`;

export default TopicsLegend;
