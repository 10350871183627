import notificationActionCreators, {
  ShowNotificationAction,
} from "@src/store/notifications/actions";
import { freeze } from "hyphen-lib/dist/lang/Objects";
import * as NotificationFactory from "@src/store/notifications/notification-factory";
import { put, takeLatest } from "@redux-saga/core/effects";
import { XLSXActionTypes } from "./actions";

function* showErrorNotificationWhenUploadFails(networkResponse: any) {
  // const { payload } = networkResponse as {
  //   payload: { error: { message: string } };
  // };
  yield put<ShowNotificationAction>(
    notificationActionCreators.displayNotification(
      NotificationFactory.error(
        "Failed to upload",
        "",
        4.5
      )
  ));
}

export const surveyLanguageCSVSagas = freeze([
  takeLatest(
    XLSXActionTypes.UPLOAD_XLSX_FAILED,
    showErrorNotificationWhenUploadFails
  ),
]);
