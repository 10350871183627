import React from "react";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";

import Button from "@components/core/Button";
import { ScreenContainer } from "@screens/Insights/components/ScreenContainer";
import { parseQueryString } from "@hyphen-lib/util/net/HttpClient";
import { State } from "@store/types";
import DashboardView from "@screens/Insights/Dashboard/components/DashboardView";
import { Map as ImmutableMap } from "immutable";
import { getParameters } from "@screens/Insights/parameters/store/selectors";
import { goTo, replaceLocation } from "@src/utils/locations";
import { applyExistingParametersIfNeeded } from "@src/utils/parameters";
import {
  isNotEmptyObject,
  isNotNullNorUndefined,
  getOr,
  mapOr,
} from "hyphen-lib/dist/lang/Objects";
import {
  getRightsMatcher,
  getCurrentUser,
  getCompany,
} from "@screens/Insights/store/selectors";
import { RightsMatcher } from "@hyphen-lib/business/auth/Auth";
import { hasFocusAreaRights } from "hyphen-lib/dist/business/focusArea/focusAreaResource";
import { Rights } from "@hyphen-lib/business/auth/Rights";
import { getAnonymityThreshold } from "@screens/Insights/Settings/store/selectors";
import styled from "styled-components";
import { getResourceById } from "@store/network/selectors";
import { DashboardResource } from "hyphen-lib/dist/domain/resource/report/DashboardResource";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { fetchDashboardIfNeeded } from "@store/network/resource/DashboardResources";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { isPulsePollEnabled } from "hyphen-lib/dist/business/company/Companies";
import { getSurveyTypes } from "@src/screens/Insights/Surveys/store/selectors";
import { getDimensions } from "@screens/Insights/store/selectors";
import { SurveyTypes } from "@screens/Insights/Surveys/store/types";
import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
import { Company } from "hyphen-lib/dist/domain/Company";
import { getAppliedFilters } from "@components/core/FilterLabels/utils";
import FeatureIntro, {
  FeatureIntroChildren,
} from "@src/screens/Insights/components/FeatureIntro/FeatureIntro";
import { Apps } from "hyphen-lib/dist/domain/apps/App";
import AreYouSureModal from "@src/components/core/AreYouSureModal";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import AddToFocusArea from "@src/img/add_to_focus_area.png";
import {} from "hyphen-lib/dist/domain/FocusArea";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { fetchFocusAreasIfNeeded } from "@src/store/network/resource/ActionResources";
import {
  focusAreaListActionCreators,
  FocusAreaListPageParameters,
} from "@src/screens/Insights/Actions/store/actions";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";
import {
  getAllFocusAreasFromReduxStore,
  getCurrentBottomDrawerView,
  isFARequestComplete,
} from "@src/utils/FocusArea";
import BottomDrawer, { DrawerViews } from "@src/components/core/BottomDrawer";
import BottomDrawerStripedChild, {
  transformFocusAreasToStripStack,
} from "@src/components/core/BottomDrawer/StripedChild";
import { BottomDrawerMessages } from "@src/screens/Insights/BottomDrawer/constant";
import Spin from "@src/components/core/Spin";

export type OwnProps = RouteComponentProps;

interface DashboardContainerStateProps {
  readonly surveyTypes: SurveyTypes;
  readonly dimensions: Dimensions;
  readonly dashboard: Store.Element<DashboardResource>;
  readonly parameters: ImmutableMap<string, any>;
  readonly rightsMatcher: RightsMatcher;
  readonly hasSurveyCreationRight: boolean;
  readonly hasPulsePollCreationRight: boolean;
  readonly anonymityThreshold: number;
  readonly company?: Optional<CompanyResource>;
  readonly companyModules: Company.Modules;
  readonly focusAreas?: FocusAreaResource[];
  readonly currentUser: Optional<CurrentUserResource>;
  readonly currentBottomDrawerView: DrawerViews;
  readonly completedFARequest: boolean;
}

export const DASHBOARD_FILTER_MAPPING = [
  { localKey: "filter", storeKey: "dashboard" },
  { localKey: "filter.dimensions", storeKey: "dashboard.dimensions" },
  { localKey: "filter.modules", storeKey: "dashboard.modules" },
];

interface DashboardContainerActionProps {
  readonly onFetchDashboard: (queryString: Dictionary<any>) => void;
  readonly onFetchFocusArea: (parameters: FocusAreaListPageParameters) => any;
  readonly onCreateFocusArea: (focusArea: Partial<FocusAreaResource>) => void;
}

type Props = OwnProps &
  DashboardContainerStateProps &
  DashboardContainerActionProps;

interface DashboardContainerState {
  totalFocusAreas: number;
}

// interface Language {
//   readonly label: string;
// }

// const lngs:Record<string, Language>  = {
//   en: { label: "English" },
//   hi: { label: "Hindi" }
// };

// const langList: string[] = Object.keys(lngs);

export class DashboardContainer extends React.Component<
  Props,
  DashboardContainerState
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      totalFocusAreas: 0,
    };
  }

  componentDidMount() {
    // we might need to apply persisted parameters
    const {
      parameters,
      location: { search },
    } = this.props;

    let totalFocusAreas = 0;
    if (isNotNullNorUndefined(this.props.focusAreas)) {
      totalFocusAreas = this.props.focusAreas.length;
    }

    this.setState({
      totalFocusAreas,
    });

    const existing = parseQueryString(search);
    const mergedParameters = applyExistingParametersIfNeeded(
      parameters.toJS(),
      existing,
      ...DASHBOARD_FILTER_MAPPING
    );
    if (
      isNotNullNorUndefined(mergedParameters) &&
      isNotEmptyObject(mergedParameters)
    ) {
      replaceLocation(mergedParameters);
    } else {
      // fetch the overview only if we will stay on this page,
      // otherwise it will be fetched anyway in componentDidUpdate
      this.fetchDashboardData();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.location.search !== prevProps.location.search) {
      this.fetchDashboardData();
    }

    if (hasFocusAreaRights(this.props.rightsMatcher)) {
      this.props.onFetchFocusArea(this.fetchFocusAreaProps());
    }

    if (
      isNotNullNorUndefined(this.props.focusAreas) &&
      isNotNullNorUndefined(prevProps.focusAreas)
    ) {
      if (this.props.focusAreas.length > prevProps.focusAreas.length) {
        this.setState({
          totalFocusAreas: this.props.focusAreas.length,
        });
      }
    }
  }

  fetchFocusAreaProps(): FocusAreaListPageParameters {
    return {
      page: {
        number: 1,
        size: 30,
      },
      filter: {
        withActionPlans: false,
      },
    };
  }

  fetchDashboardData = () => {
    const { location, onFetchDashboard } = this.props;
    const queryParams = parseQueryString(location.search);
    onFetchDashboard(queryParams);
    if (hasFocusAreaRights(this.props.rightsMatcher)) {
      this.props.onFetchFocusArea(this.fetchFocusAreaProps());
    }
  };

  generateEnabledFilters = () => {
    const { companyModules } = this.props;
    const enabledFilters = ["dimension", "date"];
    let numberOfModules = 0;
    Object.values(companyModules).forEach((value) => {
      if (value === true) {
        numberOfModules += 1;
      }
    });

    if (numberOfModules > 1) {
      enabledFilters.push("modules");
    }

    if (companyModules.surveys) {
      enabledFilters.push("surveyType");
    }
    // show by default on dashboard;
    enabledFilters.push("category");
    return enabledFilters;
  };

  render() {
    const {
      dashboard,
      rightsMatcher,
      hasSurveyCreationRight,
      hasPulsePollCreationRight,
      anonymityThreshold,
      company,
      companyModules,
      location,
      dimensions,
      surveyTypes,
      focusAreas,
      onCreateFocusArea,
      currentUser,
      completedFARequest
    } = this.props;

    const appliedFilters = getAppliedFilters(
      location.search,
      dimensions,
      surveyTypes
    );

    const isPulsePollEnabledForUser = company
      ? isPulsePollEnabled(company)
      : false;

    const hasFaRights = hasFocusAreaRights(this.props.rightsMatcher);
    return (
      <>
        <Spin size="large" className="sticky-top" spinning={!completedFARequest}>
          <ScreenContainer
            title="Dashboard"
            data-cy="dashboard"
            actions={[
              isPulsePollEnabledForUser && hasPulsePollCreationRight && (
                <Link to="/pulsePolls/edit" key="createPulsePoll">
                  <StyledButton
                    color="blue"
                    data-cy="createPollButton_dashboard"
                    translate="yes"
                  >
                    Create New Poll
                  </StyledButton>
                </Link>
              ),
              hasSurveyCreationRight && (
                <Link to="/surveys/create" key="createSurvey">
                  <StyledButton
                    color="blue"
                    data-cy="createSurveyButton_dashboard"
                    translate="yes"
                  >
                    Create New Survey
                  </StyledButton>
                </Link>
              ),
              // (<div key="langChange">
              //   {langList.map((lng) => (
              //     <button key={lng} style={{ fontWeight: this.props.i18n.resolvedLanguage === lng 
              //       ? "bold" : "normal" }} type="submit" onClick={() => this.props.i18n.changeLanguage(lng)}>
              //       {lngs[lng].label}
              //     </button>
              //   ))}
              // </div>)
            ]}
          >
            <FeatureIntro page={Apps.Pages.INSIGHTS_DASHBOARD_FOCUS_AREA}>
              {({
                handleCloseNotification,
                notificationMessage,
                notificationTitle,
              }: FeatureIntroChildren) => (
                <>
                  {hasFaRights &&
                    isStringAndNotEmpty(notificationMessage) &&
                    isStringAndNotEmpty(notificationTitle) && (
                      <AreYouSureModal
                        hideCancelButton
                        okLabel="I understand"
                        description={notificationMessage}
                        image={AddToFocusArea}
                        width={650}
                        title={notificationTitle}
                        visible
                        onOk={handleCloseNotification}
                        onCancel={handleCloseNotification}
                      />
                    )}
                  <DashboardView
                    dashboard={Store.Element.toLoadable(dashboard)}
                    dimensions={dimensions}
                    enabledCustomFilters={["addDimension"]}
                    enabledFilters={this.generateEnabledFilters()}
                    appliedFilters={appliedFilters}
                    rightsMatcher={rightsMatcher}
                    anonymityThreshold={anonymityThreshold}
                    company={company}
                    companyModules={companyModules}
                    focusAreas={focusAreas}
                    onCreateFocusArea={onCreateFocusArea}
                    currentUser={currentUser}
                  />
                </>
              )}
            </FeatureIntro>
          </ScreenContainer>
        </Spin>

        {hasFaRights && (
          <BottomDrawer
            key="segment-drawer"
            initialView={this.props.currentBottomDrawerView}
            title={`${
              this.state.totalFocusAreas === 0
                ? "No"
                : this.state.totalFocusAreas
            } Focus Area Added`}
            footerButton={{
              display: true,
              text: "Go To Action Center",
              onClick: () => goTo("/actioncenter"),
            }}
          >
            <BottomDrawerStripedChild
              stripStack={transformFocusAreasToStripStack(focusAreas)}
              emptyStackMessage={BottomDrawerMessages.EMPTY_STACK_MESSAGE}
            />
          </BottomDrawer>
        )}
      </>
    );
  }
}

const StyledButton = styled(Button)`
  @media print {
    visibility: hidden;
    width: 0px !important;
    min-width: 0px !important;
    max-width: 0px !important;
    height: 0px !important;
    min-height: 0px !important;
    max-height: 0px !important;
  }
`;

const mapStateToProps: MapStateToProps<
  DashboardContainerStateProps,
  OwnProps,
  State
> = (state: State, ownProps: OwnProps): DashboardContainerStateProps => {
  let company = null;
  const currentUser = getCurrentUser(state);
  if (
    isNotNullNorUndefined(currentUser) &&
    isNotNullNorUndefined(currentUser.company)
  ) {
    company = currentUser.company;
  }

  const companyModules: Company.Modules = mapOr(
    getCompany(state),
    (map) => map.modules,
    {
      surveys: false,
      pulsePolls: false,
      employeeVoice: false,
      employeeLifeCycle: false,
    }
  );

  const data = getAllFocusAreasFromReduxStore(state);
  const currentBottomDrawerView = getCurrentBottomDrawerView(state);
  return {
    dashboard: getResourceById(
      state,
      DashboardResource.TYPE,
      DashboardResource.generateId(
        parseQueryString(ownProps.location.search).filter
      )
    ),
    company,
    parameters: getParameters(state),
    rightsMatcher: getRightsMatcher(state),
    hasSurveyCreationRight: getRightsMatcher(state).hasRight(
      Rights.Survey.CREATE
    ),
    hasPulsePollCreationRight: getRightsMatcher(state).hasRight(
      Rights.PulsePoll.CREATE
    ),
    anonymityThreshold: getAnonymityThreshold(state),
    surveyTypes: getSurveyTypes(state).toArray(),
    dimensions: getOr(getDimensions(state), {}),
    companyModules,
    focusAreas: data,
    currentUser,
    currentBottomDrawerView,
    completedFARequest: isFARequestComplete(state)
  };
};

const mapDispatchToProps = {
  onFetchDashboard: fetchDashboardIfNeeded,
  onFetchFocusArea: fetchFocusAreasIfNeeded,
  onCreateFocusArea: focusAreaListActionCreators.createFocusArea,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
