// core react
import React, { Component } from "react";

// styles
import styled from "styled-components";

// type defs
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { CompareWithOption } from "@screens/Insights/components/ViewOptions/components/CompareWith";
import { HighlightedSegment } from "hyphen-lib/dist/domain/resource/report/common/Highlights";

// app components
import { Heading } from "@components/core/Typography";

// utils
import { getMatchingOptionLabel } from "@src/utils/Comparisons";
import { generateCategorySubtitle, SegmentSection } from "@src/utils/Subtitles";
import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
import { getDimensionLabel } from "@src/utils/Dimensions";
import Segment from "./Segment";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { FocusArea } from "hyphen-lib/dist/domain/FocusArea";
import { WithTranslation, withTranslation } from "react-i18next";

export interface SegmentListProps extends WithTranslation{
  readonly title: string;
  readonly segmentData: HighlightedSegment[];
  readonly dimensions: Optional<Dimensions>;
  readonly areComparisonsVisible?: boolean;
  readonly compareWithOptions: CompareWithOption[];
  readonly comparisonKey: Optional<string>;
  readonly hasActionCreationRight: boolean;
  readonly isNPSQuestion?: boolean;
  readonly isRatingQuestion?: boolean;
  readonly focusAreas?: FocusAreaResource[];
  readonly onCreateFocusArea: (focusArea: Partial<FocusAreaResource>) => void;
  readonly focusAreaLabel: string;
}

function getSegmentKey({ dimension, segment }: { dimension: string; segment: string }): string {
  return `${dimension}_${segment}`;
}

enum DisplayTitle {
  strongestSegments =  "Strongest Segments",
  weakestSegments =  "Weakest Segments",
  biggestImprovements =  "Biggest Improvements",
  biggestDecline =  "Biggest Decline",
  none  = "No Title Provided",
}

class SegmentList extends Component<SegmentListProps> {

  segmentTypeMapper(displayTitle: string) {
    switch(displayTitle) {
      case "strongestSegments":
        return FocusArea.Source.STRONGEST_SEGMENTS;
      case "weakestSegments":
        return FocusArea.Source.WEAKEST_SEGMENTS;
      case "biggestImprovements":
        return FocusArea.Source.BIGGEST_IMPROVEMENTS;
      case "biggestDecline":
      default:
        return FocusArea.Source.BIGGEST_DECLINE;
    }
  }

  render() {
    const {
      title,
      segmentData,
      dimensions,
      areComparisonsVisible,
      compareWithOptions,
      comparisonKey="previous",
      hasActionCreationRight,
      isRatingQuestion,
      isNPSQuestion,
      focusAreas,
      onCreateFocusArea,
      focusAreaLabel,
      t
    } = this.props;

    const comparisonLabel = getMatchingOptionLabel(compareWithOptions, comparisonKey);
    return (
      <SegmentsContainer>
        <Heading size="small" weight={"bold"}>
          { DisplayTitle[`${title ? title : "none"}` as keyof typeof DisplayTitle] }
        </Heading>
        {
          segmentData.map((segment: any) => (
            <Segment
              key={getSegmentKey(segment)}
              dimensionLabel={getDimensionLabel(segment.dimension, dimensions)}
              data={segment}
              isComparisonVisible={areComparisonsVisible}
              comparisonLabel={comparisonLabel}
              hasActionCreationRight={hasActionCreationRight}
              comparisonKey={comparisonKey}
              subtitle={generateCategorySubtitle(t, segment.categorySubtitle, SegmentSection.WEAKEST)}
              isRatingQuestion={isRatingQuestion}
              isNPSQuestion={isNPSQuestion}
              focusAreas={focusAreas}
              onCreateFocusArea={onCreateFocusArea}
              segmentType={this.segmentTypeMapper(title as DisplayTitle)}
              focusAreaLabel={focusAreaLabel}
            />
          ))
        }
      </SegmentsContainer>
    );
  }
}

export default withTranslation()(SegmentList);

const SegmentsContainer = styled.div`
  flex: 50%;
  max-width: 45%;
  margin-right: 32px;
  margin-bottom: 32px;
`;
