import React from "react";
import ContainerCard from "@src/components/core/ContainerCard";

export function Error404() {
  return (
    <ContainerCard
      title="Page Does Not Exist"
      description="The page you are trying to view does not exist."
    />
  );
}
