import styled from "styled-components";
import Palette from "@src/config/theme/palette";

export const SectionItem = styled.div`
  display: block;
  padding: 8px 0;
`;
export const SectionItemLabel = styled.div`
  display: inline-block;
  width: 35%;
  color: ${Palette.bluishGrey};
  vertical-align: top;
`;
export const SectionItemValue = styled.div`
  display: inline-block;
  width: 65%;
  color: ${Palette.veryDarkBlueGrey};
`;