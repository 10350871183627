import { BatarangDryRunResultsResource } from "hyphen-lib/dist/domain/resource/batarang/BatarangDryRunResultsResource";
import { BatarangApplyResultsResource } from "hyphen-lib/dist/domain/resource/batarang/BatarangApplyResultsResource";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import {
  BatarangScriptsActionTypes, CancelAction,
  ExecuteErrorAction,
  ExecuteInDryRunModeErrorAction,
  ExecuteInDryRunModeRequestAction,
  ExecuteInDryRunModeSuccessAction,
  ExecuteRequestAction,
  ExecuteSuccessAction, FinishAction,
  UpdateScriptAction,
  UploadAssetRequestAction,
  UploadAssetRequestStartAction,
  UploadAssetSuccessAction,
  UploadAssetProgressAction,
  UploadAssetErrorAction
} from "@screens/Insights/Settings/containers/batarang/store/batarangScriptsActions";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";

export interface AssetUpload {
  uploadStatus: string;
  fileName: Optional<string>;
  error?: Optional<Dictionary<any>>;
  uploadProgress?: Optional<number>;
  assetId?: Optional<string>;
}

export interface BatarangScriptsStateProps {
  readonly dryRunExecutionResults?: Optional<Loadable<BatarangDryRunResultsResource>>;
  readonly executionResults?: Optional<Loadable<BatarangApplyResultsResource>>;
  readonly error?: Optional<string>;
  readonly assetUpload?: AssetUpload;
}

export function batarangScriptsStateFactory(): BatarangScriptsStateProps {
  return { assetUpload: {
    fileName: "",
    uploadStatus: BatarangScriptsActionTypes.UPLOAD_ASSET_PROGRESS,
  }};
}

type BatarangScriptsAction =
  UpdateScriptAction |
  UploadAssetRequestAction |
  UploadAssetRequestStartAction |
  UploadAssetSuccessAction |
  UploadAssetProgressAction |
  UploadAssetErrorAction |
  CancelAction |
  FinishAction |
  ExecuteInDryRunModeRequestAction |
  ExecuteInDryRunModeSuccessAction |
  ExecuteInDryRunModeErrorAction |
  ExecuteRequestAction |
  ExecuteSuccessAction |
  ExecuteErrorAction;

export const batarangScriptsReducers = (state: BatarangScriptsStateProps = batarangScriptsStateFactory(),
  action: BatarangScriptsAction) => {
  switch (action.type) {
    case BatarangScriptsActionTypes.UPDATE_SCRIPT:
    case BatarangScriptsActionTypes.CANCEL:
    case BatarangScriptsActionTypes.FINISH:
      return batarangScriptsStateFactory(); // reset the state on every script change
    case BatarangScriptsActionTypes.EXECUTE_IN_DRY_RUN_MODE_REQUEST:
      return {
        ...state,
        dryRunExecutionResults: Loadable.loading(),
      };
    case BatarangScriptsActionTypes.EXECUTE_IN_DRY_RUN_MODE_SUCCESS:
      return {
        ...state,
        dryRunExecutionResults: Loadable.loaded(action.payload.data),
        error: Optional.empty(),
      };
    case BatarangScriptsActionTypes.EXECUTE_IN_DRY_RUN_MODE_ERROR:
      return {
        ...state,
        dryRunExecutionResults: Optional.empty(),
        error: action.payload.error,
      };
    case BatarangScriptsActionTypes.EXECUTE_REQUEST:
      return {
        ...state,
        executionResults: Loadable.loading(),
      };
    case BatarangScriptsActionTypes.EXECUTE_SUCCESS:
      return {
        ...state,
        executionResults: Loadable.loaded(action.payload.data),
      };
    case BatarangScriptsActionTypes.EXECUTE_ERROR:
      return {
        ...state,
        executionResults: Optional.empty(),
        error: action.payload.error,
      };
    case BatarangScriptsActionTypes.UPLOAD_ASSET_REQUEST_START:
      return {
        ...state,
        assetUpload: {
          uploadStatus: action.type,
          fileName: action.payload.file.name,
        },
      };
    case BatarangScriptsActionTypes.UPLOAD_ASSET_SUCCESS:
      return {
        ...state,
        assetUpload: {
          uploadStatus: action.type,
          assetId: action.payload.assetId,
        },
      };
    case BatarangScriptsActionTypes.UPLOAD_ASSET_PROGRESS:
      return {
        ...state,
        assetUpload: {
          uploadStatus: action.type,
          uploadProgress: action.payload.progress,
          fileName: action.payload.fileName,
        },
      };
  }

  return state;
};
