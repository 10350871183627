import React from "react";
import { Table } from "antd";
import { TableProps } from "antd/lib/table/interface";
import styled from "styled-components";
import { isNullOrUndefined } from "hyphen-lib/dist/lang/Objects";
import { ParticipantResource } from "hyphen-lib/dist/domain/resource/participants/ParticipantResource";
import { Trans } from "react-i18next";
import NoResult from "@src/components/core/NoResult";

interface Props {
  audience: ParticipantResource[];
}

const convertToEmptyStringIfNull = (theString: string) => {
  return isNullOrUndefined(theString) ? "" : theString;
};

const AudienceEmailsModalContent = (props: Props) => {
  const { audience } = props;
  const columns = [{
    title: (<Trans>Email</Trans>),
    dataIndex: "email",
    key: "email",
    width: 320,
  }, {
    title: (<Trans>Name</Trans>),
    dataIndex: "name",
    render: (text: any, record: any) =>
      convertToEmptyStringIfNull(record.firstName) + " " + convertToEmptyStringIfNull(record.lastName),
    width: 250,
  }, {
    title: (<Trans>Added By</Trans>),
    dataIndex: "subscriptionType",
    key: "subscriptionType",
  }];

  return (
    <AudienceEmailsTable
      pagination={false}
      columns={columns}
      dataSource={audience}
      rowKey="_id"
      scroll={{y: 420}}
      locale={{
        emptyText: (<NoResult type="minimal" description="No Data" />),
      }}
    />
  );
};

const AudienceEmailsTable = styled(Table)<TableProps<any>>`
  th, td {
    padding: 20px 32px !important;
  }
  .ant-table-header {
    overflow: hidden !important;
  }
`;

export default AudienceEmailsModalContent;
