import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
  Redirect,
} from "react-router";
import { SidebarDefinition } from "@components/Sidebar/SidebarDefinitions";
import { Sidebar, SidebarMode } from "@components/Sidebar";
import React from "react";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { connect } from "react-redux";
import { State } from "@store/types";
import {
  getActiveItemId,
  getSidebarDefinition,
} from "@screens/Insights/MainNavigation/store/selectors";
import Surveys from "@screens/Insights/Surveys";
import Survey from "@screens/Insights/Survey";
import { SurveyList } from "@screens/Insights/SurveyList";
import UserList from "@screens/Insights/UserManagement";
import ActionCenter from "@screens/Insights/Actions/";
import {
  getCurrentUser,
  getRightsMatcher,
} from "@screens/Insights/store/selectors";
import { handleLogoClick } from "@store/actions";
import { actionCreators } from "@screens/Insights/store/actions";
import { RightsMatcher } from "@hyphen-lib/business/auth/Auth";
import { Error403 } from "@screens/Insights/errors/Error403";
import { Error404 } from "@screens/Insights/errors/Error404";
import { ViewActionPlan, EditActionPlan } from "@screens/Insights/ActionPlans";
import DashboardContainer from "@screens/Insights/Dashboard/containers/DashboardContainer/index";
import LCADashboard from "@src/screens/Insights/LifeCycleAnalysis/container/LCADashboard";
import { RedirectContainer } from "@screens/Insights/MainNavigation/RedirectContainer";
import { BreadcrumbContainer } from "@screens/Insights/MainNavigation/BreadcrumbContainer";
import { Breadcrumb, goTo } from "@src/utils/locations";
import QuestionHighlightReportContainer from "@screens/Insights/Survey/containers/QuestionHighlightReportContainer";
// eslint-disable-next-line max-len
import QuestionCommentsHighlightReportContainer from "@screens/Insights/Survey/containers/QuestionCommentsHighlightReportContainer";
import CategoryHighlightReportContainer from "@screens/Insights/Survey/containers/CategoryHighlightReportContainer";
import { checkForSurveyReportRight } from "@src/utils/rights";
import { Alert } from "antd";
import CategoryCommentsHighlightReportContainer 
  from "../Survey/containers/CategoryCommentsHighlightReportContainer/index";
import PulsePollList from "../PulsePollList";
import i18n from "@src/utils/i18next";
import PulsePolls from "../PulsePoll/index";
import EmployeeVoice from "../EmployeeVoice";
import SegmentReportContainer from "../Segments/SegmentReport";
import Settings from "../Settings/routes";
import LanguageSelectorModal from "@src/components/core/LanguageSelectorModal";
import { getProperty, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import TopicCommentsHighlightReportContainer 
  from "@src/screens/Insights/Survey/containers/TopicCommentsHighlightReportContainer/index";

interface MainNavigationOwnProps extends RouteComponentProps {}

interface MainNavigationStateProps{
  readonly company?: Optional<CompanyResource>;
  readonly activeItemId?: string;
  readonly sidebarDefinition: SidebarDefinition<any>;
  readonly email: string;
  readonly onClickLogo?: () => void;
  readonly rightsMatcher: RightsMatcher;
}

type MainNavigationProps = MainNavigationOwnProps &
  MainNavigationStateProps &
  typeof actionCreators;

export interface MainNavigationState {
  /*
    fixme: do we want to put that in the redux store, and do we want to persist it in the sidebar?!
    fixme: from my POV, I would say: probably and yes.
  */
  sidebarMode: SidebarMode;
  languageModalVisible: boolean;
}

class MainNavigation extends React.Component<
  MainNavigationProps,
  MainNavigationState
> {
  state = {
    sidebarMode: SidebarMode.COLLAPSED,
    languageModalVisible: false,
  };

  handleSidebarChangeMode = () => {
    this.setState((prevState) => ({
      sidebarMode:
        prevState.sidebarMode === SidebarMode.EXPANDED
          ? SidebarMode.COLLAPSED
          : SidebarMode.EXPANDED,
    }));
  };

  renderOnlyIfAccessToReport(
    rightsMatcher: RightsMatcher,
    report: string,
    props: RouteComponentProps<any>,
    render: (props: RouteComponentProps<any>) => React.ReactNode
  ) {
    if (checkForSurveyReportRight(rightsMatcher, report)) {
      return render(props);
    }

    return <Redirect to={"/errors/403"} />;
  }

  onLanguageChange(language: string) {
    this.setState({
      languageModalVisible: true,
    });
  }

  changeLang = (record: string) => {
    i18n.changeLanguage(record).then((t) => {
    });;
  };


  render() {
    /* tslint:disable:jsx-no-lambda */
    const {
      activeItemId,
      sidebarDefinition,
      email,
      onClickLogo,
      logout,
      rightsMatcher,
      company,
    } = this.props;

    const { sidebarMode } = this.state;

    return (
      <MainContainer>
        <LanguageSelectorModal
          visible={this.state.languageModalVisible}
          onClose={() => this.setState({ languageModalVisible: false })}
          activeLanguage={ getProperty(i18n,"resolvedLanguage", "en")}
          onLanguageSelect={this.changeLang}
        />
        <Sidebar
          company={company}
          mode={sidebarMode}
          activeItemId={activeItemId}
          sidebarDefinition={sidebarDefinition}
          email={email}
          onClickLogo={onClickLogo}
          onChangeMode={this.handleSidebarChangeMode}
          onLanguageChange={(language) => this.onLanguageChange(language)}
          onLogout={() => logout(() => goTo("/login", Breadcrumb.newStack()))}
        />

        <PageContainer id="navigationContainer" className="block--print-show">
          <BreadcrumbContainerContainer className="block--print-hide">
            <BreadcrumbContainer />
          </BreadcrumbContainerContainer>
          <Switch>
            <Route
              path="/surveys/view/:id/reports/categories/:categoryId+/comments"
              render={(props) =>
                this.renderOnlyIfAccessToReport(
                  rightsMatcher,
                  "comments",
                  props,
                  (p) => <CategoryCommentsHighlightReportContainer {...p} />
                )
              }
            />
            <Route
              path="/surveys/view/:id/reports/categories/:categoryId+"
              component={CategoryHighlightReportContainer}
            />
            <Route
              path="/surveys/view/:id/reports/questions/:questionId/comments"
              render={(props) =>
                this.renderOnlyIfAccessToReport(
                  rightsMatcher,
                  "comments",
                  props,
                  (p) => <QuestionCommentsHighlightReportContainer {...p} />
                )
              }
            />
            <Route
              path="/surveys/view/:id/reports/questions/:questionId"
              component={QuestionHighlightReportContainer}
            />
            <Route
              path="/surveys/view/:id/reports/topics/:topicId+/questions/:questionId/comments"
              render={(props) =>
                this.renderOnlyIfAccessToReport(
                  rightsMatcher,
                  "comments",
                  props,
                  (p) => <TopicCommentsHighlightReportContainer {...p} />
                )
              }
            />
            <Route
              path="/surveys/view/:id/reports/topics/:topicId+/categories/:categoryId/comments"
              render={(props) =>
                this.renderOnlyIfAccessToReport(
                  rightsMatcher,
                  "comments",
                  props,
                  (p) => <TopicCommentsHighlightReportContainer {...p} />
                )
              }
            />
            <Route
              path="/surveys/view/:id/reports/topics/:topicId+/comments"
              render={(props) =>
                this.renderOnlyIfAccessToReport(
                  rightsMatcher,
                  "comments",
                  props,
                  (p) => <TopicCommentsHighlightReportContainer {...p} />
                )
              }
            />
            <Route path="/surveys/view/:id" component={Survey} />
            <Route path="/surveys/*" component={Surveys} />
            <Route exact path="/surveys" component={SurveyList} />
            <Route exact path="/pulsePolls" component={PulsePollList} />
            <Route
              path="/pulsePolls/:templateId/instances/:instanceId"
              component={PulsePolls}
            />
            <Route path="/pulsePolls/:templateId" component={PulsePolls} />
            <Route path="/dashboard" component={DashboardContainer} />
            <Route path="/lifecycle" component={LCADashboard} />
            <Route
              path="/segments/:dimension/:segment"
              component={SegmentReportContainer}
            />
            <Route path="/voice/reports" component={EmployeeVoice} />
            <Route
              exact
              path="/actioncenter/focusareas"
              component={ActionCenter}
            />
            <Route
              exact
              path="/actioncenter/actions/edit/:actionId"
              component={EditActionPlan}
            />
            <Route
              exact
              path="/actioncenter/actions/view/:actionId"
              component={ViewActionPlan}
            />
            <Route
              exact
              path="/actioncenter/actions"
              component={ActionCenter}
            />
            <Route exact path="/actioncenter" component={ActionCenter} />
            <Route path="/users/permissions" exact component={UserList} />
            <Route path="/users/roles" component={UserList} />
            <Route path="/users" exact component={UserList} />
            <Route path="/settings" component={Settings} />
            <Route path="/errors/403" component={Error403} />
            <Route path="/errors/404" component={Error404} />
            <Route path="/" component={RedirectContainer} />
            <Route component={Error404} />
          </Switch>
        </PageContainer>
      </MainContainer>
    );
  }
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  @media print {
    display: inline-block;
    overflow: visible;
  }
`;

const PageContainer = styled.div`
  background-color: ${Palette.paleGrey};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 0 48px 32px 48px;
  @media print {
    width: auto;
  }

  > .ant-layout {
    min-height: unset;
  }
`;

export const GlobalNotification = styled(Alert)`
  margin-top: 24px;
`;

const BreadcrumbContainerContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 16px;
`;


function mapStateToProps(
  state: State,
  ownProps: MainNavigationOwnProps
): MainNavigationStateProps {
  let company = null;
  const currentUser = getCurrentUser(state);
  if (
    isNotNullNorUndefined(currentUser) &&
    isNotNullNorUndefined(currentUser.company)
  ) {
    company = currentUser.company;
  }
  return {
    company,
    email: getCurrentUser(state)!.email,
    rightsMatcher: getRightsMatcher(state),
    activeItemId: getActiveItemId(state, ownProps.location),
    sidebarDefinition: getSidebarDefinition(state),
    onClickLogo: () => handleLogoClick(ownProps.history),
  };
}

export const MainNavigationContainer = withRouter(
  connect(mapStateToProps, { ...actionCreators })(MainNavigation)
);
