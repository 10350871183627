import React from "react";
import { Select as AntSelect } from "antd";
import styled from "styled-components";
import { SelectProps } from "antd/lib/select";
import Palette from "@src/config/theme/palette";
import hoistNonReactStatics from "hoist-non-react-statics";
import { withFieldLabels } from "../withFieldLabels";
import NoResult from "../NoResult";

class SelectComponent extends React.Component<SelectProps & { children?: React.ReactNode }> {
  onGetPopupContainer(trigger: any) {
    return trigger.parentNode;
  }
  render() {
    return (
    // @ts-ignore
      <StyledSelect
        {...this.props}
        //  FIXME: AntD has this issue, must see how to fix this correctly
        // eslint-disable-next-line max-len
        //  FIXME: https://ant.design/docs/react/faq#Select-Dropdown-DatePicker-TimePicker-Popover-Popconfirm-scroll-with-the-page
        getPopupContainer={this.onGetPopupContainer}
        notFoundContent={<NoResult type="minimal" description="No data"/>}
      />
    );
  }
}

const StyledSelect = styled(AntSelect)<SelectProps  & {children: React.ReactNode}>`
  width: 100%;
  border-radius: 3px;
  .ant-select-selection {
    :focus, :hover {
      border-color: ${Palette.bluePurple};
    }
  }
  .ant-select-selection--multiple .ant-select-selection__choice {
    background-color: ${Palette.lightPeriwinkle};
    border-radius: 3px;
    border: none;
  }
`;

const Select = hoistNonReactStatics(withFieldLabels(SelectComponent), AntSelect);

export default Select;
