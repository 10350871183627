import { default as networkActionCreators } from "@store/network/actions";
import { createRequest } from "@src/utils/networks";
import { MergeTagResource } from "hyphen-lib/dist/domain/resource/MergeTagResource";

export function fetchMergeTagsIfNeeded(surveyType: string) {
  return networkActionCreators.fetchPageWithoutPaginationIfNeeded({
    id: surveyType,
    type: MergeTagResource.TYPE,
    request: () => createRequest(
      `/surveyTypes/${encodeURIComponent(surveyType)}/mergeTags`
    ),
  });
}
