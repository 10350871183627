import styled from "styled-components";

interface KebabProps {
  onClick: () => void;
  dotSeparation: number;
}

function KebabMenu({onClick, dotSeparation}: KebabProps) {
  return (
    <Container onClick={onClick}>
      <Kebab />
      <Kebab dotSeparation={dotSeparation}/>
      <Kebab dotSeparation={dotSeparation}/>
    </Container>
  );
}

const Kebab = styled.div<{dotSeparation?: number}>`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #AAB9CE;
  ${props => props.dotSeparation ? `margin-top: ${props.dotSeparation}px`: ""}
`;

const Container = styled.div<{height?: number}>`
  display: inline-block;
  cursor: pointer;
`;

export default KebabMenu;