import React from "react";
import styled from "styled-components";
import { Switch } from "antd";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { Trans } from "react-i18next";
export interface PermissionsItemProps extends DisableDivProps {
  title: string;
  description?: string;
  isRightEnabled: boolean;
  onRightsModify?: (checked: boolean) => void;
}

export function PermissionsItem(props: PermissionsItemProps) {
  const { title, description, dependentRight, isRightEnabled, onRightsModify = () => null } = props;
  return (
    <PermissionItemContainer>
      <div>
        <PermissionName dependentRight={dependentRight}><Trans>{title}</Trans></PermissionName>
        {
          isNotNullNorUndefined(description) && (
            <PermissionDescription dependentRight={dependentRight}>
              <Trans>{description}</Trans>
            </PermissionDescription>
          )
        }
      </div>
      {
        isNotNullNorUndefined(dependentRight) ? (
          <PermissionReasonAndSwitch>
            {isNotNullNorUndefined(dependentRight.right) && (
              <PermissionReason>
                <Trans>Requires</Trans> "{dependentRight.right}" <Trans>to
                  be {not(dependentRight.status) ? "enabled" : "disabled"}</Trans>
              </PermissionReason>
            )}
            <Switch checked={isRightEnabled} disabled />
          </PermissionReasonAndSwitch>
        ) : (
          <Switch checked={isRightEnabled} onChange={onRightsModify} />
        )
      }
    </PermissionItemContainer>
  );
}

const disabledColor = "#93A0A4";

interface DisableDivProps {
  dependentRight?: {
    right?: string;
    status: boolean;
  };
}

const PermissionItemContainer = styled.div`
  display: flex;
  align-items: center;
  border-top: #E2E6EA solid 1px;
  height: 52px;
  padding-right: 10px;
  justify-content: space-between;
  padding-left: 25px;
`;

const PermissionReason = styled.div`
  color: ${disabledColor};
  margin-right: 10px;
`;

const PermissionReasonAndSwitch = styled.div`
  display: flex;
  align-items: center;
`;

const PermissionName = styled.div<DisableDivProps>`
  font-weight: bold;
  ${props => isNotNullNorUndefined(props.dependentRight) ? (`
    color: ${disabledColor};
  `) : ""}
`;

const PermissionDescription = styled.div<DisableDivProps>`
  font-size: 13px;
  ${props => isNotNullNorUndefined(props.dependentRight) ? (`
    color: ${disabledColor};
  `) : ""}
`;
