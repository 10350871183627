import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Breadcrumb, BreadcrumbItem } from "@components/core/Breadcrumb";
import { BreadcrumbElement, navigateBackTo } from "@src/utils/locations";

type BreadcrumbContainerProps = RouteComponentProps<{}, any, BreadcrumbElement[]>;

class BreadcrumbContainerComponent extends React.Component<BreadcrumbContainerProps> {

  generateItems(stack: BreadcrumbElement[] = []): BreadcrumbItem[] {
    return stack.map(({ label, url }) => ({
      label,
      onClick: () => navigateBackTo(url), // fixme: use the method navigateInStack
    }));
  }

  render() {
    const items = this.generateItems(this.props.location.state);

    return (
      <Breadcrumb items={items}/>
    );
  }
}

export const BreadcrumbContainer = withRouter(BreadcrumbContainerComponent);
