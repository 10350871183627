import React from "react";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps } from "react-router";

import { State } from "@store/types";

import { SurveyCommentsReportStateProps } from "@screens/Insights/Survey/components/Comments";
import {
  getCurrentUser,
  getDimensions,
  getRightsMatcher,
} from "@screens/Insights/store/selectors";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import Navbar from "@screens/Insights/Survey/components/Navbar";
import OpenQuestion from "@screens/Insights/Survey/components/OpenQuestionHighlightReport";
import {
  addPropertyInDepth,
  getOr,
  getProperty,
  isNotEmptyObject,
  isNotNullNorUndefined,
  isNullOrUndefined,
  mapOr,
  objectOmit,
} from "hyphen-lib/dist/lang/Objects";
import { Map as ImmutableMap } from "immutable";
import { getParameters } from "@screens/Insights/parameters/store/selectors";
import { applyExistingParametersIfNeeded } from "@src/utils/parameters";
import { goTo, replaceLocation } from "@src/utils/locations";
import {
  checkForActionPlanCreationRight,
  checkForConversationRight,
  checkForSurveyReportRight,
} from "@src/utils/rights";
import { getAnonymityThreshold } from "@screens/Insights/Settings/store/selectors";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { SurveyInfoResource } from "hyphen-lib/dist/domain/resource/SurveyInfoResource";
import { fetchSurveyInfoIfNeeded } from "@store/network/resource/SurveyInfoResources";
import {
  getExistingPage,
  getResourceById,
} from "@store/network/selectors";
import { Loading } from "@screens/Insights/Survey/components/Loading";
import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { CommentsResultResource } from "hyphen-lib/dist/domain/resource/report/CommentsResultResource";
import { CommentsOverviewResource } from "hyphen-lib/dist/domain/resource/report/CommentsOverviewResource";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { fetchCommentResultsIfNeeded } from "@store/network/resource/CommentsResultResources";
import { fetchCommentsOverviewIfNeeded } from "@store/network/resource/CommentsOverviewResources";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { FetchError } from "@src/screens/Insights/errors/FetchError";
import { surveyReportActionCreators } from "../../store/actions";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";
import { fetchFocusAreasIfNeeded } from "@src/store/network/resource/ActionResources";
import {
  focusAreaListActionCreators,
  FocusAreaListPageParameters,
} from "@src/screens/Insights/Actions/store/actions";
import { isEmpty } from "lodash";
import { getAllFocusAreasFromReduxStore, getCurrentBottomDrawerView } from "@src/utils/FocusArea";
import { hasFocusAreaRights } from "hyphen-lib/dist/business/focusArea/focusAreaResource";
import {
  fetchTopicsOverviewIfNeeded
} from "@src/store/network/resource/TopicsReportResources";
import { SurveyTopicCommentResource } from "hyphen-lib/dist/domain/resource/survey/report/SurveyTopicCommentResource";
import BottomDrawer, { DrawerViews } from "@src/components/core/BottomDrawer";
import BottomDrawerStripedChild, {
  transformFocusAreasToStripStack,
} from "@src/components/core/BottomDrawer/StripedChild";
import { BottomDrawerMessages } from "@src/screens/Insights/BottomDrawer/constant";
import { parseNumber } from "hyphen-lib/dist/lang/Number";
import { formatSort } from "@src/utils/helper";
import { FilterParameter, SortParameter } from "@src/utils/networks";

interface MatchParams {
  id: string;
  categoryId: string;
}

export interface OwnProps extends RouteComponentProps<MatchParams> {}

export interface CategoryCommentsHighlightReportContainerStateProps
  extends SurveyCommentsReportStateProps {
  readonly surveyId: string;
  readonly surveyInfoElement: Store.Element<SurveyInfoResource>;
  readonly parameters: ImmutableMap<string, any>;
  readonly canSendPrivateMessage: boolean;
  readonly anonymityThreshold: number;
  readonly comments: Store.Page<CommentsResultResource>;
  readonly loading: boolean;
  readonly commentsOverview: Store.Element<CommentsOverviewResource>;
  readonly dimensions: Optional<Dimensions>;
  readonly focusAreas?: FocusAreaResource[];
  readonly currentUser: Optional<CurrentUserResource>;
  readonly hasFaRights: boolean;
  readonly hasTopicsAccess: boolean;
  readonly currentBottomDrawerView: DrawerViews;
  readonly topicsOverview: Store.Element<SurveyTopicCommentResource>;
  readonly page: PageFilter;
  readonly sort: SortParameter;
  readonly filter: FilterParameter;
}

type CategoryCommentsHighlightReportContainerActionProps =
  typeof surveyReportActionCreators & {
    readonly onFetchSurveyInfo: (surveyId: string) => void;
    readonly onFetchSurveyComments: (
      surveyId: string,
      questionId: Optional<string>,
      queryString: Dictionary<any>
    ) => void;
    readonly onFetchSurveyCommentsOverview: (
      surveyId: string,
      questionId: Optional<string>,
      queryString: Dictionary<any>
    ) => void;
    readonly onFetchFocusArea: (parameters: FocusAreaListPageParameters) => any;
    readonly onCreateFocusArea: (focusArea: Partial<FocusAreaResource>) => void;
    readonly onFetchTopicsOverview: (
      surveyId: string,
      queryString: Dictionary<any>
    ) => void;
  };

type Props = OwnProps &
  CategoryCommentsHighlightReportContainerStateProps &
  CategoryCommentsHighlightReportContainerActionProps;

  interface QuestionCommentsHighlightReportContainerState {
    totalFocusAreas: number;
  };
class CategoryCommentsHighlightReportContainer extends React.Component<Props, 
QuestionCommentsHighlightReportContainerState> {

  constructor(props: Props) {
    super(props);
    this.state = {
      totalFocusAreas: 0
    };
  }
  
  componentDidMount(): void {
    // we might need to apply persisted parameters
    const {
      parameters,
      location: { search },
    } = this.props;

    const existing = parseQueryString(search);
    const mergedParameters = applyExistingParametersIfNeeded(
      parameters.toJS(),
      existing,
      "filter.dimensions",
      "filter.categories",
      "filter.sentiments"
    );

    if (
      isNotNullNorUndefined(mergedParameters) &&
      isNotEmptyObject(mergedParameters)
    ) {
      replaceLocation(mergedParameters);
    } else {
      // fetch the overview only if we will stay on this page,
      // otherwise it will be fetched anyway in componentDidUpdate
      this.fetchDataFromBackend();
    }

    if (
      (isNullOrUndefined(this.props.focusAreas) ||
      isEmpty(this.props.focusAreas)) && this.props.hasFaRights
    ) {
      this.props.onFetchFocusArea(this.fetchFocusAreaProps());
    }

    let totalFocusAreas = 0;
    if(isNotNullNorUndefined(this.props.focusAreas)) {
      totalFocusAreas = this.props.focusAreas.length;
    }

    this.setState({
      totalFocusAreas
    });
  }

  componentDidUpdate(prevProps: Props) {
    const { surveyInfoElement, comments, commentsOverview, focusAreas, loading } = this.props;
    const isInError =
      mapOr(
        surveyInfoElement,
        (sElem) => Store.Element.isInError(sElem),
        false
      ) ||
      mapOr(comments, (p) => Store.Page.isInError(p), false) ||
      mapOr(commentsOverview, (cElem) => Store.Element.isInError(cElem), false);
    if (
      (this.props.surveyId !== prevProps.surveyId ||
        this.props.match.params.categoryId !==
          prevProps.match.params.categoryId ||
        this.props.location.search !== prevProps.location.search 
        || loading) &&
      not(isInError)
    ) {
      this.fetchDataFromBackend();
    }
    if(this.props.hasFaRights) {
      this.props.onFetchFocusArea(this.fetchFocusAreaProps());
    }
    if(isNotNullNorUndefined(focusAreas) && isNotNullNorUndefined(prevProps.focusAreas)) {
      if(focusAreas.length > prevProps.focusAreas.length) {
        this.setState({
          totalFocusAreas: focusAreas.length
        });
      }
    }
  }

  fetchFocusAreaProps(): FocusAreaListPageParameters {
    return {
      page: {
        number: 1,
        size: 30,
      },
      filter: {
        withActionPlans: false,
      },
    };
  }

  fetchDataFromBackend = () => {
    const { categoryId } = this.props.match.params;
    const {
      location,
      surveyId,
      postId,
      onFetchSurveyInfo,
      onFetchSurveyComments,
      onFetchSurveyCommentsOverview,
      onFetchTopicsOverview,
      hasTopicsAccess
    } = this.props;
    const queryParams = addPropertyInDepth(
      parseQueryString(location.search),
      [categoryId],
      "filter.categories"
    );
    onFetchSurveyComments(surveyId, postId, queryParams);
    onFetchSurveyCommentsOverview(surveyId, postId, queryParams);
    onFetchSurveyInfo(surveyId);
    const filter = getProperty(queryParams, "filter", {});
    filter.categories = [categoryId];
    if(isNotNullNorUndefined(filter.freeText)) {
      delete filter.freeText;
    }
    if(hasTopicsAccess) {
      onFetchTopicsOverview(
        surveyId,
        { filter }
      );
    }
  };

  render() {
    const {
      match: {
        params: { categoryId },
      },
      surveyInfoElement,
      commentsOverview,
      comments,
      commentText,
      errorText,
      isNetworkRequesting,
      isPrivateMessageModalVisible,
      postId,
      privateMessageText,
      surveyId,
      voteId,
      canSendPrivateMessage,
      hasActionCreationRight,
      anonymityThreshold,
      clearPrivateMessageModal,
      sendPrivateMessage,
      togglePrivateMessageModal,
      updatePrivateMessageText,
      focusAreas,
      onCreateFocusArea,
      topicsOverview,
      hasFaRights,
      hasTopicsAccess,
      page,
      sort,
      filter
    } = this.props;

    if (Store.Element.isInError(surveyInfoElement)) {
      return (
        <FetchError
          {...surveyInfoElement}
          resourceType={SurveyInfoResource.TYPE}
        />
      );
    }
    if (Store.Page.isInError(comments)) {
      return (
        <FetchError {...comments} resourceType={CommentsResultResource.TYPE} />
      );
    }

    if (Store.Element.isInError(commentsOverview)) {
      return (
        <FetchError
          {...commentsOverview}
          resourceType={CommentsOverviewResource.TYPE}
        />
      );
    }


    if (Store.Element.isNotLoaded(surveyInfoElement)) {
      return <Loading />;
    }

    const {
      value: { participation, name, topic_analysis_enabled, surveySuggestionsEnabled, surveyLanguages },
    } = surveyInfoElement;
    const displayTopics = topic_analysis_enabled && hasTopicsAccess;
    let enabledFilters = [ "dimension", "sentiment"];
    enabledFilters = displayTopics ? ["topic", ...enabledFilters] : enabledFilters;
    return (
      <>
        <Navbar
          style={{ marginBottom: 32, height: "auto" }}
          withActions={false}
          title={decodeURI(categoryId)}
        />
        {hasFaRights && <BottomDrawer
          key="segment-drawer"
          initialView={this.props.currentBottomDrawerView}
          title={`${this.state.totalFocusAreas === 0 ? "No" : this.state.totalFocusAreas} Focus Area Added`}
          footerButton={{
            display: true,
            text: "Go To Action Center",
            onClick: () => goTo("/actioncenter"),
          }}
        >
          <BottomDrawerStripedChild
            stripStack={transformFocusAreasToStripStack(focusAreas)}
            emptyStackMessage={BottomDrawerMessages.EMPTY_STACK_MESSAGE}
          />
        </BottomDrawer>}
        <OpenQuestion
          participation={participation}
          commentsOverview={Store.Element.toLoadable(commentsOverview)}
          comments={comments}
          enabledFilters={enabledFilters}
          enabledCustomFilters={["addDimension"]}
          clearPrivateMessageModal={clearPrivateMessageModal}
          sendPrivateMessage={sendPrivateMessage}
          togglePrivateMessageModal={togglePrivateMessageModal}
          updatePrivateMessageText={updatePrivateMessageText}
          commentText={commentText}
          errorText={errorText}
          isNetworkRequesting={isNetworkRequesting}
          isPrivateMessageModalVisible={isPrivateMessageModalVisible}
          postId={postId}
          privateMessageText={privateMessageText}
          surveyId={surveyId}
          voteId={voteId}
          canSendPrivateMessage={canSendPrivateMessage}
          hasActionCreationRight={hasActionCreationRight}
          anonymityThreshold={anonymityThreshold}
          focusAreas={focusAreas}
          onCreateFocusArea={onCreateFocusArea}
          surveyName={name}
          topicsOverview={Store.Element.toLoadable(topicsOverview)} 
          title={""}        
          page={page}
          sort={sort}
          filter={filter}
          topicAnalysEnabled={displayTopics}
          surveySuggestionsEnabled={surveySuggestionsEnabled}
          surveyLanguages={surveyLanguages}
          />
      </>
    );
  }
}

export const mapStateToProps: MapStateToProps<
  CategoryCommentsHighlightReportContainerStateProps,
  OwnProps,
  State
> = (
  state: State,
  ownProps: OwnProps
): CategoryCommentsHighlightReportContainerStateProps => {
  const currentUser = getCurrentUser(state);
  const { match, location } = ownProps;
  const surveyId = match.params.id;
  const { categoryId } = match.params;
  const surveyInfoElement = getResourceById(
    state,
    SurveyInfoResource.TYPE,
    surveyId
  );
  const allowPrivateMessages = isNotNullNorUndefined(currentUser) &&
  isNotNullNorUndefined(currentUser?.company) &&
  currentUser.company.allowPrivateMessages;
  
  const canSendPrivateMessage = Store.Element.isLoaded(surveyInfoElement)
    ? checkForConversationRight(surveyInfoElement.value.role, allowPrivateMessages)
    : false;
  const queryParams = addPropertyInDepth(
    parseQueryString(location.search),
    [categoryId],
    "filter.categories"
  );
  const { filter } = queryParams;

  const postId = state.getIn(["insights_survey_report", "postId"]);
  const data = getAllFocusAreasFromReduxStore(state);
  const hasFaRights = hasFocusAreaRights(getRightsMatcher(state));
  const currentBottomDrawerView = getCurrentBottomDrawerView(state);

  const skipFreeText = objectOmit(getOr(filter, {}), "freeText");
  const comments = getExistingPage(
    state,
    CommentsResultResource.TYPE,
    CommentsResultResource.generateKey(
      { surveyId, questionId: postId },
      filter
    ),
    PageFilter.noPagination() // fixme: handle pagination at some point!
  );
  const queryParameters = parseQueryString(ownProps.location.search);
  const page = {
    number: mapOr(queryParameters.page, parseNumber, 1),
    size: 10
  };
  const sort = getOr(formatSort(queryParameters.sort), {});
  return {
    surveyId,
    surveyInfoElement,
    comments,
    commentsOverview: getResourceById(
      state,
      CommentsOverviewResource.TYPE,
      CommentsOverviewResource.generateId(
        { surveyId, questionId: postId },
        filter
      )
    ),
    dimensions: getDimensions(state),
    commentText: state.getIn(["insights_survey_report", "commentText"]),
    errorText: state.getIn(["insights_survey_report", "errorText"]),
    isNetworkRequesting: state.getIn([
      "insights_survey_report",
      "isSendMessageRequesting",
    ]),
    isPrivateMessageModalVisible: state.getIn([
      "insights_survey_report",
      "isPrivateMessageModalVisible",
    ]),
    postId,
    privateMessageText: state.getIn([
      "insights_survey_report",
      "privateMessageText",
    ]),
    topicsOverview: getResourceById(
      state,
      SurveyTopicCommentResource.TYPE,
      SurveyTopicCommentResource.generateKey(
        surveyId,
        { categories: [categoryId], ...skipFreeText }
      )
    ),
    voteId: state.getIn(["insights_survey_report", "voteId"]),
    parameters: getParameters(state),
    canSendPrivateMessage,
    hasActionCreationRight: checkForActionPlanCreationRight(state),
    anonymityThreshold: getAnonymityThreshold(state),
    hasTopicsAccess: checkForSurveyReportRight(
      getRightsMatcher(state),
      "topics"
    ),
    currentUser,
    focusAreas: data,
    hasFaRights,
    currentBottomDrawerView,
    loading:  Store.Page.isNotFound(comments),
    page,
    sort,
    filter
  };
};

const mapDispatchToProps: CategoryCommentsHighlightReportContainerActionProps =
  {
    onFetchSurveyInfo: fetchSurveyInfoIfNeeded,
    onFetchSurveyComments: fetchCommentResultsIfNeeded,
    onFetchSurveyCommentsOverview: fetchCommentsOverviewIfNeeded,
    onFetchFocusArea: fetchFocusAreasIfNeeded,
    onCreateFocusArea: focusAreaListActionCreators.createFocusArea,
    onFetchTopicsOverview: fetchTopicsOverviewIfNeeded,
    ...surveyReportActionCreators,
  };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryCommentsHighlightReportContainer);
export { CategoryCommentsHighlightReportContainer as PureCategoryCommentsHighlightReportContainer };