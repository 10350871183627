import { default as networkActionCreators } from "@store/network/actions";
import { createRequest } from "@src/utils/networks";
import { SurveyTypeResource } from "hyphen-lib/dist/domain/resource/SurveyTypeResource";

export function fetchSurveyTypesIfNeeded() {
  return networkActionCreators.fetchAllPageIfNeeded({
    type: SurveyTypeResource.TYPE,
    request: createRequest("/surveyTypes"),
  });
}
