import React from "react";
import { Tag } from "antd";
import { Icon } from "antd";
import { Left, Right } from "@components/core/svg";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { isNotNullNorUndefined } from "@hyphen-lib/lang/Objects";
import { Filter, SubFilter } from "./types";
import { getDimensionSegmentLabel } from "@src/utils/Dimensions";
import { TransWrapper } from "../withTrans";

interface OwnProps {
  onClearFilter: (filter: string) => void;
  onClearSubfilter: (filter: string, subFilter: string) => void;
  filters: Filter[];
}

export type Props = {
} & OwnProps;

// eslint-disable-next-line max-len
const filtersAllowedTrans = ["date", "status", "statuses", "sources", "difficulty", "length", "sentiment", "frequency", "hasInsightsAccess", "customScope", "launchedAt",
"createdAtDate", "lastInstanceDate"];

export class FilterLabels extends React.Component<Props> {
  wordListContainer = React.createRef<HTMLDivElement>();
  wrapperRef: HTMLDivElement | null = null;
  state = {
    scrolledToLeft: false,
    scrolledToRight: false,
    componentWidth: 300,
  };

  componentDidMount() {
    setTimeout(() => this.setContainerWidth(), 0);
    window.addEventListener("resize", this.setContainerWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setContainerWidth);
  }

  componentDidUpdate() {
    this.updateScrollVisibility();
  }

  setContainerWidth = () => {
    if (this.wrapperRef) {
      this.setState({ componentWidth: this.wrapperRef.offsetWidth });
    }
  };

  updateScrollVisibility = () => {
    if (this.wordListContainer && this.wordListContainer.current) {
      const scrolledToLeft = this.wordListContainer.current.scrollLeft === 0;
      const scrolledToRight = this.wordListContainer.current.offsetWidth +
        this.wordListContainer.current.scrollLeft === this.wordListContainer.current.scrollWidth;

      if (this.state.scrolledToLeft !== scrolledToLeft || this.state.scrolledToRight !== scrolledToRight) {
        this.setState({ scrolledToLeft, scrolledToRight });
      }
    }
  };

  scrollList = (direction: "left" | "right") => () => {
    if (this.wordListContainer && this.wordListContainer.current) {
      const scrollLeftBy = direction === "right" ? 400 : -400;
      this.wordListContainer.current.scrollBy({
        top: 0,
        left: scrollLeftBy,
        behavior: "smooth",
      });
    }
  };

  render() {
    const { onClearFilter, onClearSubfilter, filters } = this.props;
    const filterTags: any = [];

    filters.forEach((filter: Filter) => {
      const subFilters = filter.filters.map((subFilter: SubFilter) => {
        return <SubfilterTag
          key={subFilter.key}
          data-cy={"subfilter_" + subFilter.key}
          closable={true}
          // tslint:disable-next-line:jsx-no-lambda
          onClose={(e: any) => { onClearSubfilter(filter.key, subFilter.key); }}
        >
          {
            <TransWrapper translate={filtersAllowedTrans.includes(filter.key) ? "yes" : "no"}>
              {getDimensionSegmentLabel(filter.key, subFilter.label)}</TransWrapper>
          }
        </SubfilterTag>;
      });
      filterTags.push(
        <LabelContainer
          key={filter.key}>
          <div>
            <FilterTag
              closable={true}
              // tslint:disable-next-line:jsx-no-lambda
              onClose={(e: any) => { onClearFilter(filter.key); }}
            ><TransWrapper translate={filtersAllowedTrans.includes(filter.key) ? "yes" : "no"}>
              {filter.label}</TransWrapper>
            </FilterTag>
          </div>
          {subFilters}
        </LabelContainer>
      );
    });

    const { componentWidth } = this.state;

    return (
      <ScalingWrapper ref={(element) => this.wrapperRef = element}>
        <Container parentWidth={componentWidth} >
          <FilterDiv
            onScroll={this.updateScrollVisibility}
            ref={this.wordListContainer}
          >
            {filterTags}

          </FilterDiv>

          {!this.state.scrolledToLeft && (
            <LeftScrollButton onClick={this.scrollList("left")}>
              <Icon component={Left} />
            </LeftScrollButton>
          )}
          {!this.state.scrolledToRight && (
            <RightScrollButton onClick={this.scrollList("right")}>
              <Icon component={Right} />
            </RightScrollButton>
          )}
        </Container>
      </ScalingWrapper >
    );
  }
}

const ScalingWrapper = styled.div`
  height: 50px;
  margin-bottom: 30px;
`;

const LabelContainer = styled.div`
  padding: 16px;
  display: inline-block;
  border-right: solid;
  padding-top: 0px;
  padding-bottom: 0px;
  border-color: #e6ebf1;
  border-width: thin;
`;

const SubfilterTag = styled(Tag)`
  background-color: ${Palette.lightPeriwinkle} !important;
  border-radius: 3px !important;
  border: none !important;
  height: 28px !important;
  vertical-align: middle !important;
  line-height: 28px !important;
  ::after {
    animation: 0s !important;
  }
`;

const FilterTag = styled(Tag)`
  border: none !important;
  color: ${Palette.bluishGrey} !important;
  background: #ffffff !important;
  padding-left: 0 !important;
  ::after {
    animation: 0s !important;
  }
`;

export const FilterDiv = styled.div`
  flex-flow:row;
  margin-top: 16px;
  margin-bottom: 24px;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: visible;
  -ms-overflow-style: none; /* IE 10+ */
  overflow: -moz-scrollbars-none; /* Firefox */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const Container = styled.div<{ parentWidth?: number }>`
  position: relative;
  overflow: visible;
  width: ${props => isNotNullNorUndefined(props.parentWidth) ? `${props.parentWidth}px` : "300px"}
  height: 50px;
`;

const scrollButtonStyle = `
  position: absolute;
  top: 24px;
  width: 128px;
  height: 30px;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const LeftScrollButton = styled.div`
  ${scrollButtonStyle}
  left: 0;
  padding-left: 20px;
  justify-content: flex-start;
  background-image: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0));
`;

const RightScrollButton = styled.div`
  ${scrollButtonStyle}
  right: 0px;
  padding-right:20px;
  justify-content: flex-end;
  background-image: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0));
`;

export default FilterLabels;
