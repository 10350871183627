import React from "react";
import { Button as AntButton } from "antd";
import { NativeButtonProps } from "antd/lib/button/button";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { TransWrapper } from "@src/components/core/withTrans";

export interface ButtonProps extends NativeButtonProps {
  color?: "grey" | "blue" | "gradation" | "red" | Palette.bluishGrey;
}

const greyButton = `
  background-color: ${Palette.lightGreyBlue} !important;
  color: #ffffff !important;
  border-color: ${Palette.lightGreyBlue} !important;
`;

const blueButton = `
  background-color: ${Palette.aquaBlue} !important;
  color: #ffffff !important;
  border-color: ${Palette.aquaBlue} !important;
`;

const redButton = `
  background-color: ${Palette.darkPink} !important;
  color: #ffffff !important;
  border-color: ${Palette.darkPink} !important;
`;

const bluishGreyButton = `
  background-color: ${Palette.bluishGrey} !important;
  color: #ffffff !important;
`;

const gradationButton = `
  background-image: linear-gradient(to bottom, #ffffff, #f9fafb) !important;
  color: ${Palette.veryDarkBlueGrey} !important;
  border-color: ${Palette.lightGreyBlue} !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
  text-align: center !important;
`;

function getButtonColor(props: ButtonProps) {
  switch (props.color) {
    case "grey":
      return greyButton;
    case "blue":
      return blueButton;
    case "gradation":
      return gradationButton;
    case "red":
      return redButton;
    case Palette.bluishGrey:
      return bluishGreyButton;
    default:
      return null;
  }
}

const Button = styled(AntButton)<ButtonProps>`
  border-radius: 2px !important;
  transition: none;
  height: ${props => props.style?.height ? props.style.height : "40px !important"};
  font-size: 15px !important;
  ${props => getButtonColor(props)}
  :after {
    animation: 0s !important;
    transition-delay: 0s !important;
  }
  :hover {
    border-color: unset !important;
  }
`;

interface LinkedButtonProps extends ButtonProps{
  link: string;
}

export const LinkedButton = (props: LinkedButtonProps) => (
  <Link to={props.link}>
    <Button color={props.color} style={props.style}>
      <TransWrapper {...props}/>
    </Button>
  </Link>
);

const DefaultButton = ({children, translate, ...rest}: ButtonProps) => (
  <Button {...rest}>
    <TransWrapper translate={translate}>{children}</TransWrapper>
  </Button>
);

export default DefaultButton;
