import { FSA } from "flux-standard-action";
import { createRequest } from "@src/utils/networks";
import {
  ActionTypes as NetworkActionTypes,
  NetworkRequestAction,
  NetworkEventRequestAction,
  NetworkEventSuccessAction,
  NetworkEventErrorAction
} from "@store/network/actions";

import { PrivateMessageContext } from "hyphen-lib/dist/domain/PrivateMessageContext";
import { CommentsResultResource } from "hyphen-lib/dist/domain/resource/report/CommentsResultResource";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";

export enum SegmentReportActionTypes {
  SEND_PRIVATE_MESSAGE_REQUEST = "segmentReport/SEND_PRIVATE_MESSAGE_REQUEST",
  SEND_PRIVATE_MESSAGE_SUCCESS = "segmentReport/SEND_PRIVATE_MESSAGE_SUCCESS",
  SEND_PRIVATE_MESSAGE_ERROR = "segmentReport/SEND_PRIVATE_MESSAGE_ERROR",
  UPDATE_PRIVATE_MESSAGE_TEXT = "segmentReport/UPDATE_PRIVATE_MESSAGE_TEXT",
  TOGGLE_PRIVATE_MESSAGE_MODAL = "segmentReport/TOGGLE_PRIVATE_MESSAGE_MODAL",
  CLEAR_PRIVATE_MESSAGE_MODAL = "segmentReport/CLEAR_PRIVATE_MESSAGE_MODAL",

}

interface Payloads {
  [SegmentReportActionTypes.SEND_PRIVATE_MESSAGE_REQUEST]: Request;
  [SegmentReportActionTypes.SEND_PRIVATE_MESSAGE_SUCCESS]: Response;
  [SegmentReportActionTypes.SEND_PRIVATE_MESSAGE_ERROR]: Error;
  [SegmentReportActionTypes.UPDATE_PRIVATE_MESSAGE_TEXT]: string;
}

export interface SendPrivateMessageRequestAction
  extends FSA<
  SegmentReportActionTypes.SEND_PRIVATE_MESSAGE_REQUEST,
  Payloads[SegmentReportActionTypes.SEND_PRIVATE_MESSAGE_REQUEST]
  > {
  type: SegmentReportActionTypes.SEND_PRIVATE_MESSAGE_REQUEST;
  payload: Payloads[SegmentReportActionTypes.SEND_PRIVATE_MESSAGE_REQUEST];
}

export interface SendPrivateMessageSuccessAction
  extends FSA<
  SegmentReportActionTypes.SEND_PRIVATE_MESSAGE_SUCCESS,
  Payloads[SegmentReportActionTypes.SEND_PRIVATE_MESSAGE_SUCCESS]
  > {
  type: SegmentReportActionTypes.SEND_PRIVATE_MESSAGE_SUCCESS;
  payload: Payloads[SegmentReportActionTypes.SEND_PRIVATE_MESSAGE_SUCCESS];
}

export interface SendPrivateMessageErrorAction
  extends FSA<
  SegmentReportActionTypes.SEND_PRIVATE_MESSAGE_ERROR,
  Payloads[SegmentReportActionTypes.SEND_PRIVATE_MESSAGE_ERROR]
  > {
  type: SegmentReportActionTypes.SEND_PRIVATE_MESSAGE_ERROR;
  payload: Payloads[SegmentReportActionTypes.SEND_PRIVATE_MESSAGE_ERROR];
}

export const sendPrivateMessageRequest = (
  resourceId: string,
  postId: string,
  voteId: string,
  initialMessage: string,
  origin: string,
  commentSource: CommentsResultResource["source"]
) => {
  let context
  : PrivateMessageContext.Survey
  | Partial<PrivateMessageContext.PulsePollVote>
  | Partial<PrivateMessageContext.PulsePollComment>;

  if (isNotNullNorUndefined(commentSource) && commentSource.type === CommentsResultResource.SourceType.SURVEY_COMMENT) {
    context = {
      type: PrivateMessageContext.Type.SURVEY,
      surveyId: resourceId,
      voteId,
    };
  } else {
    context = {
      type:
        origin === "Vote"
          ? PrivateMessageContext.Type.PULSE_POLL_VOTE
          : PrivateMessageContext.Type.PULSE_POLL_COMMENT,
      instanceId: resourceId,
    };

    if (context.type === PrivateMessageContext.Type.PULSE_POLL_VOTE) {
      context = {...context, voteId};
    } else if (context.type === PrivateMessageContext.Type.PULSE_POLL_COMMENT) {
      context = {...context, commentId: voteId};
    }
  }

  return createRequest("/privateConversations/start", {
    method: "POST",
    body: JSON.stringify({ context, postId, initialMessage }),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const segmentReportActionCreators = {
  sendPrivateMessage: (
    resourceId: string,
    postId: string,
    voteId: string,
    initialMessage: string,
    origin: string,
    source: CommentsResultResource["source"],
    onSuccessRedirect?: (payload: NetworkEventSuccessAction["payload"]) => void
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: sendPrivateMessageRequest(resourceId, postId, voteId, initialMessage, origin, source),

    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: SegmentReportActionTypes.SEND_PRIVATE_MESSAGE_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: SegmentReportActionTypes.SEND_PRIVATE_MESSAGE_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: SegmentReportActionTypes.SEND_PRIVATE_MESSAGE_ERROR, payload }),
      ],
      onSuccessRedirect,
    },
  }),
  updatePrivateMessageText: (payload: string) => ({
    type: SegmentReportActionTypes.UPDATE_PRIVATE_MESSAGE_TEXT,
    payload,
  }),
  clearPrivateMessageModal: () => ({
    type: SegmentReportActionTypes.CLEAR_PRIVATE_MESSAGE_MODAL,
  }),
  togglePrivateMessageModal: (
    resourceId: string,
    postId: string,
    voteId: string,
    commentText: string,
    origin: string,
    source: CommentsResultResource["source"]
  ) => ({
    type: SegmentReportActionTypes.TOGGLE_PRIVATE_MESSAGE_MODAL,
    payload: { resourceId, source, postId, voteId, commentText, origin },
  }),

};
