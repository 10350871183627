import { checkActionConsistency, CheckActionConsistencyOptions } from "hyphen-lib/dist/business/action/Actions";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import { isInstanceOfException } from "hyphen-lib/dist/lang/exception/Exceptions";

import { not } from "hyphen-lib/dist/lang/Booleans";
import { isEmailFormatValid } from "hyphen-lib/dist/business/user/Users";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";

import { getErrors } from "@src/utils/formValidations";
import { CreateActionPayload } from "../store/actions";
import { ActionResource } from "hyphen-lib/dist/domain/resource/action/ActionResource";
import { TFunction } from "i18next";
import { translate } from "@src/utils/i18next";

export function getErrorsForActionPlan(
  t: TFunction,
  action: CreateActionPayload,
  consistencyOptions?: Partial<CheckActionConsistencyOptions>
) {
  let errors: WrongEntityException.WrongField[] = [];
  try {
    checkActionConsistency(action, { failFast: false, ...consistencyOptions });
  } catch (e) {
    if (isInstanceOfException(e, WrongEntityException)) {
      errors = e.errors();
    }
  }
  let errorsMap = getErrors(t, errors, getErrorMessages);
  if (not(errorsMap.has("dueDate"))) {
    const minimumDueDate = new Date();

    if (action.dueDate < minimumDueDate) {
      errorsMap = errorsMap.set("dueDate", translate(t, "Due date must be at least 1 day in the future."));
    }
  }
  if (not(errorsMap.has("assignee"))) {
    if (not(isEmailFormatValid(action.assignee))) {
      errorsMap = errorsMap.set("assignee", translate(t, "Email format not valid."));
    }
  }
  if (errorsMap.has("okr")) {
    if (action.okr?.type === ActionResource.WithOKRType.KEY_RESULT) {
      errorsMap = errorsMap.set("okr", translate(t, "Please choose an objective to add action plan as key result"));
    }
  }
  return errorsMap;
}

export function getErrorsForUpdateActionPlan(
  t: TFunction,
  action: CreateActionPayload,
  consistencyOptions?: Partial<CheckActionConsistencyOptions>
) {
  let errors: WrongEntityException.WrongField[] = [];
  try {
    checkActionConsistency(action, { failFast: false, ...consistencyOptions });
  } catch (e) {
    if (isInstanceOfException(e, WrongEntityException)) {
      errors = e.errors();
    }
  }
  const errorsMap = getErrors(t, errors, getErrorMessages);
  return errorsMap;
}

function getErrorMessages(
  t: TFunction,
  error: WrongEntityException.WrongField
): { key: string; message: string } {
  const errorMessage = {
    key: error.key,
    message: error.reason,
  };
  if (isNotNullNorUndefined(error.context)) {
    switch (error.key) {
      case "action": {
        if ("minLength" in error.context) {
          const {minLength} = error.context;
          // eslint-disable-next-line max-len
          errorMessage.message = `${translate(t, "An action should have at least")} ${minLength} ${translate(t, "characters")}`;
        } else if ("maxLength" in error.context) {
          const { maxLength } = error.context;
          // eslint-disable-next-line max-len
          errorMessage.message = `${translate(t, "An action can not have more than")} ${maxLength} ${translate(t, "characters")}`;
        }
        break;
      }
      case "assignee": {
        if ("minLength" in error.context) {
          // eslint-disable-next-line max-len
          errorMessage.message = translate(t, "Assignee is required to create an action plan");
        }
        break;
      }
      case "source.context.category": {
        if ("minLength" in error.context) {
          errorMessage.key = "category";
          // eslint-disable-next-line max-len
          errorMessage.message = translate(t, "Category is required to create an action plan");
        }
        break;
      }
      case "comment": {
        if ("minLength" in error.context) {
          const {minLength} = error.context;
          // eslint-disable-next-line max-len
          errorMessage.message = `${translate(t, "Completion comment should have at least")} ${minLength} ${translate(t, "characters")}`;
        }
        break;
      }
      default:
        break;
    }
  }
  return errorMessage;
}
