import React from "react";
import styled from "styled-components";

import CheckBox from "@components/core/Checkbox";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import Input from "@components/core/Input";
import { ChangeEvent } from "react";

import { Paragraph } from "@components/core/Typography";
import { ActionResource } from "hyphen-lib/dist/domain/resource/action/ActionResource";

import { getOr, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { Trans } from "react-i18next";

export interface NotificationsConfigurationProps {
  notifications: ActionResource.NotificationsConfiguration;
  isDisabled: boolean;
  updateNotificationSettings?: (notifications: ActionResource.NotificationsConfiguration) => void;
}

const defaultNotificationValues = {
  numberOfDaysBeforeDueDate: 7,
  overdueReminderInterval: 3,
};

export const NotificationSettings = (props: NotificationsConfigurationProps) => {
  const { notifications, updateNotificationSettings, isDisabled } = props;

  const shouldSendDueDateReminder = getOr(isNotNullNorUndefined(notifications.numberOfDaysBeforeDueDate), true);

  const shouldSendOverdueReminder = getOr(isNotNullNorUndefined(notifications.overdueReminderInterval), true);

  const handleInputChange = (notificationField: keyof ActionResource.NotificationsConfiguration) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      if (isNotNullNorUndefined(updateNotificationSettings)) {
        updateNotificationSettings(
          {
            ...notifications,
            [notificationField]: Number(e.target.value),
          }
        );
      }
    };

  const handleCheckboxChange = (notificationField: keyof ActionResource.NotificationsConfiguration) =>
    (e: CheckboxChangeEvent) => {
      if (isNotNullNorUndefined(updateNotificationSettings)) {
        if (e.target.checked) {

          updateNotificationSettings(
            {
              ...notifications,
              [notificationField]: defaultNotificationValues[notificationField],
            }
          );
        } else {
          delete notifications[notificationField];
          updateNotificationSettings(notifications);
        }
      }
    };

  return (
    <NotificationsDiv>
      <SettingsLabel weight="bold"><Trans>Notification Settings</Trans></SettingsLabel>
      <TextHolder>
        <StyledCheckbox
          name="numberOfDaysBeforeDueDateCheckbox"
          info="Send an email reminder to the Assignee about an upcoming due date or an overdue Action Plan"
          checked={shouldSendDueDateReminder}
          onChange={handleCheckboxChange("numberOfDaysBeforeDueDate")}
          disabled={isDisabled}
          data-cy="numberOfDaysBeforeDueDateCheckbox"
        >
          <Trans>Send reminders</Trans>
        </StyledCheckbox>
        <StyledInput
          name="numberOfDaysBeforeDueDate"
          value={getOr(
            notifications.numberOfDaysBeforeDueDate,
            defaultNotificationValues.numberOfDaysBeforeDueDate
          )}
          type="number"
          suffix={<StyledSuffix><Trans>days before due-date</Trans></StyledSuffix>}
          min={0}
          onChange={handleInputChange("numberOfDaysBeforeDueDate")}
          disabled={isDisabled || !shouldSendDueDateReminder}
          data-cy="numberOfDaysBeforeDueDate"
        />
      </TextHolder>

      <TextHolder>
        <StyledCheckbox
          name="overdueReminderIntervalCheckbox"
          info="Send an email to the Owner and Assignee notifying them the Action Plan is overdue"
          checked={shouldSendOverdueReminder}
          onChange={handleCheckboxChange("overdueReminderInterval")}
          disabled={isDisabled}
          data-cy="overdueReminderIntervalCheckbox"
        >
          <Trans>Notify on overdue</Trans>
        </StyledCheckbox>

        <StyledInput
          name="overdueReminderInterval"
          value={getOr(
            notifications.overdueReminderInterval,
            defaultNotificationValues.overdueReminderInterval
          )}
          type="number"
          suffix={<StyledSuffix><Trans>days</Trans></StyledSuffix>}
          min={1}
          onChange={handleInputChange("overdueReminderInterval")}
          disabled={isDisabled || !shouldSendOverdueReminder}
          data-cy="overdueReminderInterval"
        />
      </TextHolder>
    </NotificationsDiv>
  );
};

const SettingsLabel = styled(Paragraph)`
  margin-top: 24px !important;
`;

export const NotificationsDiv = styled.div`
  margin-top: 27px !important;
`;

const StyledInput = styled(Input)`
  margin-top: 20px;
`;

const StyledCheckbox = styled(CheckBox)`
  margin-top: 20px;
`;

const StyledSuffix = styled.div`
  color: #aab9ce;
  margin-right: 10px;
`;

const TextHolder = styled.div`
  max-width: 266px;
  margin-top: 27px !important;
`;
