import React from "react";
import { ChangeEventHandler, FocusEventHandler } from "react";
import { Trans } from "react-i18next";
import { WithFieldLabelProps } from "@components/core/withFieldLabels";
import { InputProps } from "antd/lib/input";
import MergeTagsModal from "@screens/Insights/Surveys/components/MergeTagsModal";
import { MergeTagResource } from "hyphen-lib/dist/domain/resource/MergeTagResource";
import { getOr, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import withDebounce from "@components/core/withDebounce";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import Input from "@components/core/Input";

// fixme: make it compile correctly!
// @ts-ignore
const DebouncedInput = withDebounce(Input);

export interface InputWithMergeTagsProps extends WithFieldLabelProps, InputProps {
  waitTime?: number;
  onValueChange?: (value: string, updatedCursorPosition?: number, mergeTag?: string) => void;
  readonly fieldName?: string;
  readonly allowedMergeTags?: MergeTagResource[];
  label?: string;
}

interface InputWithMergeTagsState {
  readonly isMergeTagsModalOpen: boolean;
}

/**
 * The `InputWithMergeTags` is  a `Input` with debounce and a preconfigured helper action which will
 * display the merge tags modal. It will manage the merge tags insertion as well, with the
 * cursor position management.
 */
export class InputWithMergeTags extends React.Component<InputWithMergeTagsProps, InputWithMergeTagsState> {
  private textInputRef: Optional<HTMLInputElement> = Optional.empty();
  private mergeTagCursorPosition = 0;

  constructor(props: InputWithMergeTagsProps) {
    super(props);

    this.state = {
      isMergeTagsModalOpen: false,
    };
  }

  addTagToText = (mergeTag: string) => {
    if (isNotNullNorUndefined(this.textInputRef)) {
      const { value, onValueChange } = this.props;
      this.textInputRef.focus();

      const newText = this.insertString(
        getOr(value as string, ""),
        mergeTag + " ",
        this.mergeTagCursorPosition
      );

      if (isNotNullNorUndefined(onValueChange)) {
        onValueChange(newText, this.mergeTagCursorPosition, mergeTag);
      }
    }
  };

  insertString = (originalStr: string, strToInsert: string, position: number) => {
    return originalStr.substr(0, position) + strToInsert + originalStr.substr(position);
  };

  handleChangeModalOpenState = () => {
    this.setState(oldState => ({ isMergeTagsModalOpen: !oldState.isMergeTagsModalOpen }));
  };

  handleCloseModal = () => {
    this.setState({ isMergeTagsModalOpen: false });
  };

  createOnChangeHandler = (onChange?: ChangeEventHandler<HTMLInputElement>) => (e: any) => {
    this.mergeTagCursorPosition = e.target.selectionStart;
    if (isNotNullNorUndefined(onChange)) {
      onChange(e);
    }
  };

  createOnFocusHandler = (onFocus?: FocusEventHandler<HTMLInputElement>) => (e: any) => {
    this.mergeTagCursorPosition = e.target.selectionStart;
    if (isNotNullNorUndefined(onFocus)) {
      onFocus(e);
    }
  };

  storeRef = (domElement: HTMLInputElement) => {
    this.textInputRef = domElement;
  };

  render() {
    const { isMergeTagsModalOpen } = this.state;
    const {
      fieldName = "Current field",
      allowedMergeTags,
      onChange,
      onFocus,
      ...otherProps
    } = this.props;

    return (
      <>
        <DebouncedInput
          // tslint:disable-next-line:jsx-no-lambda
          inputRef={this.storeRef}
          onChange={this.createOnChangeHandler(onChange)}
          onFocus={this.createOnFocusHandler(onFocus)}
          helperAction={<Trans>{isNotEmptyArray(allowedMergeTags) && "+ Add merge tags"}</Trans>}
          onActionClick={not(otherProps.disabled) ? this.handleChangeModalOpenState : undefined}
          {...otherProps}
        />
        {
          isNotEmptyArray(allowedMergeTags) && isMergeTagsModalOpen && (
            <MergeTagsModal
              modalOpen={isMergeTagsModalOpen}
              handleCancel={this.handleCloseModal}
              mergeTags={allowedMergeTags}
              // tslint:disable-next-line:jsx-no-lambda
              onAddTag={mergeTag => this.addTagToText(mergeTag)}
              fieldName={`'${fieldName}'`}
            />
          )
        }
      </>
    );
  }
}
