import { PulsePollResource } from "hyphen-lib/dist/domain/resource/PulsePollResource";
import { CardSection } from "@components/core/InfoCard/CardSection";

interface Props {
  pulsePoll: PulsePollResource;
}

export default function PulsePollSummarySection ({ pulsePoll }: Props) {
  const { isAnonymous } = pulsePoll;
  const pulsePollSummaryData = [
    {
      label: "Anonymous",
      value: isAnonymous ? "Yes" : "No",
    },
  ];

  return (
    <>
      <CardSection data={pulsePollSummaryData} data-cy="pulsepoll-summary-section"/>
    </>
  );
}
