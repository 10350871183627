import React from "react";
import styled from "styled-components";
import { BatarangParameterProps } from "./BatarangParameters";
import withDebounce from "@src/components/core/withDebounce";
import { InputProps } from "antd/lib/input";
import { getOr } from "hyphen-lib/dist/lang/Objects";
import Input from "@src/components/core/Input";

const NewInput = withDebounce(Input as any);

class ParameterInputField extends React.Component<BatarangParameterProps> {

  render() {
    const { company, onChange, disabled, value} = this.props;
    return (
      <TextInput
            type="number"
            value={getOr(value, company?.coreAppConfig?.orgId)}
            onValueChange={onChange}
            disabled={disabled === true}
          />
    );
  }
}
export default (ParameterInputField);

const TextInput = styled(NewInput)<InputProps & { onValueChange: any}>`
  .ant-input {
    width: 350px;
  }`;