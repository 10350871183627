import React, { FC } from "react";
import styled from "styled-components";
import { Form, Table as AntTable, Button as AntButton } from "antd";
import { List } from "immutable";
import { Trans } from "react-i18next";
import Palette from "@src/config/theme/palette";
import Table from "@src/components/core/Table";
import Button from "@src/components/core/Button";
import Input from "@src/components/core/Input";
import withDebounce from "@components/core/withDebounce";
import { not } from "@hyphen-lib/lang/Booleans";
import {
  EMPTY_STRING_NOTATION,
  MutableSubPhaseWithErrorState,
  handleWheelScroll
} from "../../utils/LifeCycleSettings";

const { Column } = AntTable;

const DebouncedInput = withDebounce(Input as any);

interface ComponentProps {
  subPhases: List<MutableSubPhaseWithErrorState>;
  isLastPhase: boolean;
  minSubPhases: number;
  maxSubPhases: number;
  handleSubPhaseChange: (idx: number, inputName: string) => (inputValue: string) => void;
  addSubPhase: () => void;
  removeSubPhase: (idx: number) => () => void;
}

const LifeCycleSubPhases: FC<ComponentProps> = (props) => {
  const {
    subPhases,
    minSubPhases,
    maxSubPhases,
    isLastPhase,
    handleSubPhaseChange,
    addSubPhase,
    removeSubPhase,
  } = props;

  const getStartTenureValue = (startTenure: number): string | number => {
    if (startTenure === EMPTY_STRING_NOTATION) {
      return "";
    }
    return startTenure;
  };
  const getEndTenureValue = (endTenure: number, isLastIndex: boolean): string | number => {
    if (isLastPhase && isLastIndex) {
      return "∞";
    }
    if (endTenure === EMPTY_STRING_NOTATION) {
      return "";
    }
    return endTenure;
  };

  const getRowKey = (subPhase: MutableSubPhaseWithErrorState, idx: number) => `${idx}`;

  const renderStartTenure = (startTenure: number, record: MutableSubPhaseWithErrorState, idx: number) => (
    <Form.Item
      help={not(idx === 0) && record.startTenureError && <Trans>{record.startTenureError}</Trans>}
      validateStatus={not(idx === 0) && record.startTenureError ? "error" : ""}
    >
      <DebouncedInput
        type="number"
        name="startTenure"
        style={{ width: "40%" }}
        value={getStartTenureValue(startTenure)}
        data-cy={`lifeCycleSubphasesStartTenure_${idx}`}
        disabled={idx === 0}
        onValueChange={handleSubPhaseChange(idx, "startTenure")}
        onWheel={handleWheelScroll}
      />
    </Form.Item>
  );

  const renderEndTenure = (endTenure: number, record: MutableSubPhaseWithErrorState, idx: number) => (
    <Form.Item
      help={not(idx === subPhases.size - 1) && record.endTenureError && <Trans>{record.endTenureError}</Trans>}
      validateStatus={not(idx === subPhases.size - 1) && record.endTenureError ? "error" : ""}
    >
      <DebouncedInput
        type={isLastPhase && idx === subPhases.size - 1 ? "text" : "number"}
        name="endTenure"
        style={{ width: "40%" }}
        data-cy={`lifeCycleSubphasesEndTenure_${idx}`}
        value={getEndTenureValue(endTenure, idx === subPhases.size - 1)}
        disabled={idx === subPhases.size - 1}
        onValueChange={handleSubPhaseChange(idx, "endTenure")}
        onWheel={handleWheelScroll}
      />
    </Form.Item>
  );

  const renderAction = (action: unknown, record: MutableSubPhaseWithErrorState, idx: number) =>
    subPhases.size > minSubPhases && (
      <RemoveButton type="link" onClick={removeSubPhase(idx)} data-cy="remove_subPhase">
        <Trans>Remove</Trans>
      </RemoveButton>
    );

  return (
    <>
      {subPhases.size > 0 && (
        <SubPhasesTable
          dataSource={subPhases.toArray()}
          pagination={false}
          rowKey={getRowKey}
          data-cy="sub_phasesTable"
          data-jest="sub_phases_table"
        >
          <Column
            title={<Trans>Subphase start tenure (months)</Trans>}
            dataIndex="startTenure"
            key="startTenure"
            render={renderStartTenure}
          />
          <Column title={<Trans>Subphase end tenure (months)</Trans>} dataIndex="endTenure" 
            key="endTenure" render={renderEndTenure} />
          <Column title="" key="actions" align="right" width="50%" render={renderAction} />
        </SubPhasesTable>
      )}

      {subPhases.size < maxSubPhases && (
        <Button color="grey" onClick={addSubPhase} data-cy="add_subPhaseButton" translate="yes">
          Add a subphase
        </Button>
      )}
    </>
  );
};

const RemoveButton = styled(AntButton)`
  color: ${Palette.darkPink} !important;
`;

const SubPhasesTable = styled(Table)`
  margin-left: -32px;
  margin-right: -32px;
  margin-bottom: 32px;
`;

export default LifeCycleSubPhases;
