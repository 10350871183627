export enum ActionTypes {
  SELECT_REPORTS_MENU = "SELECT_REPORTS_MENU",
}

export const actionCreators = {
  selectReportsMenu: (menu: string) => {

    return ({
      type: ActionTypes.SELECT_REPORTS_MENU,
      menu,
    });
  },
};

// export default actionCreators;
