import { FSA } from "flux-standard-action";
import { createRequest, FilterParameter, SortParameter } from "@src/utils/networks";
import {
  ActionTypes as NetworkActionTypes,
  NetworkEventErrorAction,
  NetworkEventRequestAction,
  NetworkEventSuccessAction,
  NetworkRequestAction
} from "@store/network/actions";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { UserResource } from "hyphen-lib/dist/domain/resource/user/UserResource";
import { Dictionary } from "lodash";
import { SelectionFilter } from "hyphen-lib/dist/domain/parameter/SelectionFilter";

export enum UserListActionTypes {
  MODIFY_LIST = "userList/MODIFY_LIST",
  TOGGLE_MODAL_VISIBILITY = "userList/TOGGLE_MODAL_VISIBILITY",
  TOGGLE_EDIT_USER_MODAL_VISIBILITY = "userList/TOGGLE_EDIT_USER_MODAL_VISIBILITY",
  UPDATE_USER = "userList/UPDATE_USER",
  UPDATE_USER_REQUEST = "userList/UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS = "userList/UPDATE_USER_SUCCESS",
  UPDATE_USER_ERROR = "userList/UPDATE_USER_ERROR",
  DELETE_USERS = "userList/DELETE_USERS",
  SELECT_UNSELECT_USERS = "userList/SELECT_UNSELECT_USERS",

  DELETE_USERS_REQUEST = "userList/DELETE_USERS_REQUEST",
  DELETE_USERS_SUCCESS = "userList/DELETE_USERS_SUCCESS",
  DELETE_USERS_ERROR = "userList/DELETE_USERS_ERROR",
}

interface Payloads {
  [UserListActionTypes.MODIFY_LIST]: UserPageParameters;
  [UserListActionTypes.TOGGLE_MODAL_VISIBILITY]: boolean;
  [UserListActionTypes.TOGGLE_EDIT_USER_MODAL_VISIBILITY]: boolean;
  [UserListActionTypes.UPDATE_USER_REQUEST]: Request;
  [UserListActionTypes.UPDATE_USER_SUCCESS]: Response;
  [UserListActionTypes.UPDATE_USER_ERROR]: Error;
  [UserListActionTypes.DELETE_USERS]: DeleteUsersPayload;
  [UserListActionTypes.SELECT_UNSELECT_USERS]: SelectUsersPayload;
}

export interface ModifyListAction
  extends FSA<UserListActionTypes.MODIFY_LIST, Payloads[UserListActionTypes.MODIFY_LIST]> {
  type: UserListActionTypes.MODIFY_LIST;
  payload: Payloads[UserListActionTypes.MODIFY_LIST];
}

export interface ToggleModalVisibilityAction
  extends FSA<
  UserListActionTypes.TOGGLE_MODAL_VISIBILITY,
  Payloads[UserListActionTypes.TOGGLE_MODAL_VISIBILITY]
  > {
  type: UserListActionTypes.TOGGLE_MODAL_VISIBILITY;
  visible: Payloads[UserListActionTypes.TOGGLE_MODAL_VISIBILITY];
}

export interface ToggleEditUserModalVisibilityAction
  extends FSA<
  UserListActionTypes.TOGGLE_EDIT_USER_MODAL_VISIBILITY,
  Payloads[UserListActionTypes.TOGGLE_EDIT_USER_MODAL_VISIBILITY]
  > {
  type: UserListActionTypes.TOGGLE_EDIT_USER_MODAL_VISIBILITY;
  visible: Payloads[UserListActionTypes.TOGGLE_EDIT_USER_MODAL_VISIBILITY];
}

export interface UpdateUserRequestAction
  extends FSA<UserListActionTypes.UPDATE_USER_REQUEST, Payloads[UserListActionTypes.UPDATE_USER_REQUEST]> {
  type: UserListActionTypes.UPDATE_USER_REQUEST;
  payload: Payloads[UserListActionTypes.UPDATE_USER_REQUEST];
}

export interface UpdateUserSuccessAction
  extends FSA<UserListActionTypes.UPDATE_USER_SUCCESS, Payloads[UserListActionTypes.UPDATE_USER_SUCCESS]> {
  type: UserListActionTypes.UPDATE_USER_SUCCESS;
  payload: Payloads[UserListActionTypes.UPDATE_USER_SUCCESS];
}

export interface UpdateUserErrorAction
  extends FSA<UserListActionTypes.UPDATE_USER_ERROR, Payloads[UserListActionTypes.UPDATE_USER_ERROR]> {
  type: UserListActionTypes.UPDATE_USER_ERROR;
  payload: Payloads[UserListActionTypes.UPDATE_USER_ERROR];
}

export interface DeleteUsersAction
  extends FSA<UserListActionTypes.DELETE_USERS, Payloads[UserListActionTypes.DELETE_USERS]> {
  type: UserListActionTypes.DELETE_USERS;
  payload: Payloads[UserListActionTypes.DELETE_USERS];
}

export interface SelectUnselectAction
  extends FSA<UserListActionTypes.SELECT_UNSELECT_USERS, Payloads[UserListActionTypes.SELECT_UNSELECT_USERS]> {
  type: UserListActionTypes.SELECT_UNSELECT_USERS;
  payload: Payloads[UserListActionTypes.SELECT_UNSELECT_USERS];
}

export interface UserPageParameters {
  readonly filter?: FilterParameter;
  readonly sort?: SortParameter;
  readonly page: PageFilter;
}

export interface SelectUsersPayload {
  selectedRowKeys?: string[];
  tracker?: SelectionFilter.Tracker;
}

export interface DeleteUsersPayload {
  selection: Dictionary<any>;
}

export const updateUserRequest = (userId: string, user?: UserResource) => {
  return createRequest(`/users/${userId}`, {
    method: "PUT",
    body: JSON.stringify(user),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export function createDeleteUsersRequest(payload: DeleteUsersPayload): Request {
  return createRequest("/users/bulk", {
    method: "DELETE",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export const fetchUploadUsersRequest = () => createRequest("/users/uploads?status=pending");

export const userListActionCreators = {
  modifyList: (payload: ModifyListAction["payload"]): ModifyListAction => ({
    type: UserListActionTypes.MODIFY_LIST,
    payload,
  }),
  updateUser: (
    userId: string,
    user: UserResource,
    onSuccessRedirect?: (payload: NetworkEventSuccessAction["payload"]) => void
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: updateUserRequest(userId, user),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: UserListActionTypes.UPDATE_USER_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: UserListActionTypes.UPDATE_USER_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: UserListActionTypes.UPDATE_USER_ERROR, payload }),
      ],
      onSuccessRedirect,
    },
  }),
  deleteUsersRequest: (bulkPayload: DeleteUsersAction["payload"]): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createDeleteUsersRequest(bulkPayload),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: UserListActionTypes.DELETE_USERS_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: UserListActionTypes.DELETE_USERS_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: UserListActionTypes.DELETE_USERS_ERROR, payload }),
      ],
    },
  }),
  toggleModalVisibility: (visible: boolean): ToggleModalVisibilityAction => ({
    type: UserListActionTypes.TOGGLE_MODAL_VISIBILITY,
    visible,
  }),
  toggleEditUserModalVisibility: (visible: boolean): ToggleEditUserModalVisibilityAction => ({
    type: UserListActionTypes.TOGGLE_EDIT_USER_MODAL_VISIBILITY,
    visible,
  }),
  fetchUploadUsers: (): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: fetchUploadUsersRequest(),
  }),
  onDeleteUsers: (payload: DeleteUsersAction["payload"]) => ({
    type: UserListActionTypes.DELETE_USERS,
    payload,
  }),
  onSelectUsers: (payload: SelectUnselectAction["payload"]) => ({
    type: UserListActionTypes.SELECT_UNSELECT_USERS,
    payload,
  }),
};
