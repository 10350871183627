import { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Location } from "history";
import { List } from "immutable";
import { Alert } from "antd";
import { Trans, WithTranslation, withTranslation } from "react-i18next";
import { translate } from "@src/utils/i18next";
import {
  LifeCycleSettingsConfig,
  LifeCycleSettings as LifeCycleSettingsNamespace
} from "@hyphen-lib/domain/common/LifeCycleSettingsConfig";
import { AppSettingsResource } from "@hyphen-lib/domain/resource/AppSettingsResource";
import { CompanyResource } from "@hyphen-lib/domain/resource/CompanyResource";
import { not } from "@hyphen-lib/lang/Booleans";
import { isStringAndNotEmpty } from "@hyphen-lib/lang/Strings";
import { isNullOrUndefined, isNotNullNorUndefined, isEmptyObject } from "@hyphen-lib/lang/Objects";
import { Paragraph } from "@components/core/Typography";
import Spin from "@components/core/Spin";
import Button from "@src/components/core/Button";
import { State } from "@store/types";
import Palette from "@src/config/theme/palette";
import AreYouSureModal from "@src/components/core/AreYouSureModal";
import NavigationPrompt from "react-router-navigation-prompt";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import Footer from "@src/components/core/Footer/Footer";
import { getCurrentUser } from "../../store/selectors";
import { getAppSettings } from "../store/selectors";
import { settingsActionCreators } from "../store/actions";
import LifeCyclePhases from "../components/LifeCyclePhases/LifeCyclePhases";
import SeparationPhase from "../components/LifeCyclePhases/SeparationPhase";
import {
  MutablePhase,
  MutableSubPhase,
  MutablePhaseWithErrorState,
  MutableSeparationWithErrorState,
  MutableSubPhaseWithErrorState,
  getPhasesWithoutErrorAsArray,
  getSeparationWithoutErrorAsObject,
  getPhasesWithErrors,
  getSeparationWithErrors,
  hasSettingsChanged
} from "../utils/LifeCycleSettings";

interface ReduxStateProps {
  appSettings: AppSettingsResource;
  companyName: CompanyResource["name"];
}

export type LifeCycleSettingsProps = typeof settingsActionCreators & ReduxStateProps & WithTranslation;

interface LifeCycleSettingsState {
  phases: List<MutablePhaseWithErrorState>;
  separation: MutableSeparationWithErrorState;
  config: LifeCycleSettingsNamespace.Config;
  isFetchingAppSettings: boolean;
}

class LifeCycleSettingsComponent extends Component<LifeCycleSettingsProps, LifeCycleSettingsState> {
  constructor(props: LifeCycleSettingsProps) {
    super(props);
    this.state = {
      isFetchingAppSettings: false,
      phases: List([] as MutablePhaseWithErrorState[]),
      separation: {} as MutableSeparationWithErrorState,
      config: {} as LifeCycleSettingsNamespace.Config,
    };
  }

  componentDidMount() {
    const { appSettings, companyName, fetchAppSettings } = this.props;
    if (isEmptyObject(appSettings)) {
      this.setState({ isFetchingAppSettings: true });
      fetchAppSettings(companyName);
    } else {
      this.setInitialState(appSettings.lifeCycleSettingsConfig as LifeCycleSettingsConfig);
    }
  }

  componentDidUpdate(prevProps: LifeCycleSettingsProps, prevState: any) {
    const {
      appSettings: { lifeCycleSettingsConfig },
    } = this.props;

    if (
      (isEmptyObject(prevProps.appSettings) && not(isEmptyObject(this.props.appSettings)))
    ) {
      this.setInitialState(lifeCycleSettingsConfig as LifeCycleSettingsConfig);
    }

  }

  setInitialState = (lifeCycleSettingsConfig: LifeCycleSettingsConfig) => {
    const phasesWithErrorState: List<MutablePhaseWithErrorState> = List(
      lifeCycleSettingsConfig.phases.map((phase: MutablePhase) => {
        const subPhasesWithErrorState: List<MutableSubPhaseWithErrorState> = List(
          phase.subPhases.map((subPhase: MutableSubPhase) => ({
            ...subPhase,
            startTenureError: null,
            endTenureError: null,
          }))
        );
        return {
          ...phase,
          subPhases: subPhasesWithErrorState,
          nameError: null,
          startTenureError: null,
          endTenureError: null,
        };
      })
    );

    const separationWithErrorState: MutableSeparationWithErrorState = {
      ...lifeCycleSettingsConfig.separation,
      nameError: null,
      tenureBeforeTerminationError: null,
      subPhases: List(
        lifeCycleSettingsConfig.separation.subPhases.map((subPhase: MutableSubPhase) => ({
          ...subPhase,
          startTenureError: null,
          endTenureError: null,
        }))
      ),
    };

    this.setState({
      isFetchingAppSettings: false,
      phases: phasesWithErrorState,
      separation: separationWithErrorState,
      config: lifeCycleSettingsConfig.config,
    });
  };

  handlePhasesChange = (phases: List<MutablePhaseWithErrorState>) => {
    this.setState({ phases: getPhasesWithErrors(phases) });
  };

  handleSeparationChange = (separation: MutableSeparationWithErrorState) => {
    this.setState({ separation: getSeparationWithErrors(separation) });
  };

  handleSubmitSettings = () => {
    const { appSettings, companyName, updateAppSettings } = this.props;
    const { phases, separation } = this.state;

    updateAppSettings(companyName, {
      ...appSettings,
      lifeCycleSettingsConfig: {
        ...appSettings.lifeCycleSettingsConfig,
        phases: getPhasesWithoutErrorAsArray(phases),
        separation: getSeparationWithoutErrorAsObject(separation),
      } as LifeCycleSettingsConfig,
    });
  };

  shouldSubmitBeDisabled = () => {
    const { phases, separation } = this.state;
    const { appSettings: {lifeCycleSettingsConfig } } = this.props;
    const doesHaveErrorInPhases = phases.some(
      (phase) =>
        isStringAndNotEmpty(phase.nameError) ||
        isStringAndNotEmpty(phase.startTenureError) ||
        isStringAndNotEmpty(phase.endTenureError) ||
        phase.subPhases.some(
          (subphase) => isStringAndNotEmpty(subphase.startTenureError) || isStringAndNotEmpty(subphase.endTenureError)
        )
    );

    const doesHaveErrorInSeparation =
      isStringAndNotEmpty(separation.nameError) ||
      isStringAndNotEmpty(separation.tenureBeforeTerminationError) ||
      separation.subPhases.some(
        (subphase) => isStringAndNotEmpty(subphase.startTenureError) || isStringAndNotEmpty(subphase.endTenureError)
      );

    if (doesHaveErrorInSeparation || doesHaveErrorInPhases) {
      return true;
    }

    const phasesWithoutErrors = getPhasesWithoutErrorAsArray(phases);
    const separationWithoutErrors = getSeparationWithoutErrorAsObject(separation);

    return !hasSettingsChanged(phasesWithoutErrors, separationWithoutErrors, lifeCycleSettingsConfig!);
  };

  shouldShowNavigationPrompt = (crntLocation: Location<any>, nextLocation: Optional<Location<any>>) => {
    return (!nextLocation || !nextLocation.pathname.startsWith(crntLocation.pathname)) &&
          crntLocation.pathname.includes("lifecycle") &&
          !this.shouldSubmitBeDisabled();
  };

  render() {
    const { phases, separation, config, isFetchingAppSettings} = this.state;
    const { t } = this.props;
    return (
      <>
        <IntroText>
          <Paragraph translate="yes">
            Phases are the top-level unit for your Employee Lifecycle analysis. 
            You can have a minimum of 2 and a maximum of 6 Phases.
          </Paragraph>
          <Paragraph translate="yes">
            Each Phase can contain a minimum of 1 and a maximum of 4 subphases. 
            Please note that the tenure values for each subphase will adapt to each other
            , e.g. if subphase 1 ends at 24 months, the next one will start at 24 months as well.
          </Paragraph>
        </IntroText>

        {isFetchingAppSettings && phases.size === 0 && <Spin />}

        <NavigationPrompt
          when={this.shouldShowNavigationPrompt}>
          {({ isActive, onCancel, onConfirm }) => {
            if (isActive) {
              return (
                <AreYouSureModal
                  visible={true}
                  title="Are you sure?"
                  /* eslint-disable-next-line max-len */
                  description="You have unsaved changes. Click Yes to discard changes and continue navigation or Cancel to stay on this page"
                  okLabel="Yes"
                  cancelLabel="Cancel"
                  onCancel={onCancel}
                  onOk={onConfirm}
                />
              );
            }
          }}
        </NavigationPrompt>
        {not(isFetchingAppSettings) && phases.size > 0 && (
          <>
            <Footer isOnTopOfPage>
              <Alert
                message={<Trans>Changes to these settings will not be reflected in Real time in the Dashboard!</Trans>}
                type="warning"
                showIcon
              />
              <SaveButtonBottom color="blue"
                translate="yes"
                onClick={this.handleSubmitSettings} disabled={this.shouldSubmitBeDisabled()}>
                Save changes
              </SaveButtonBottom>
            </Footer>
            <LifeCyclePhases phases={List(phases)} config={config} handlePhasesChange={this.handlePhasesChange} />

            {separation && phases && (
              <SeparationPhase
                {...separation}
                minSubPhases={config.minSubPhases}
                maxSubPhases={config.maxSubPhases}
                title={`${translate(t,"Phase")} ${phases.size + 1} - ${translate(t,separation.name)}`}
                handleSeparationChange={this.handleSeparationChange}
              />
            )}

            <Footer>
              <Alert
                message={<Trans>Changes to these settings will not be reflected in Real time in the Dashboard!</Trans>}
                type="warning"
                showIcon
              />
              <SaveButtonBottom color="blue" data-cy="save_settings"
                translate="yes"
                onClick={this.handleSubmitSettings} disabled={this.shouldSubmitBeDisabled()}>
                Save changes
              </SaveButtonBottom>
            </Footer>
          </>
        )}
      </>
    );
  }
}

export const LifeCycleSettings = withTranslation()(LifeCycleSettingsComponent);

const IntroText = styled.div`
  margin-top: 32px;
  margin-bottom: 52px;

  p {
    margin-bottom: 8px;
    max-width: 758px;
  }
`;

const SaveButtonBottom = styled(Button as any)`
  margin-left: 48px;
  background-color: ${(props: HTMLButtonElement) =>
    props.disabled ? `${Palette.lightGreyBlue} !important` : "inherit"};
`;

const mapStateToProps = (state: State): ReduxStateProps => {
  let appSettings = getAppSettings(state).get(0);
  if (isNullOrUndefined(appSettings)) {
    appSettings = {} as AppSettingsResource;
  }

  let companyName = "";
  const currentUser = getCurrentUser(state);
  if (
    isNotNullNorUndefined(currentUser) &&
    isNotNullNorUndefined(currentUser.company) &&
    isNotNullNorUndefined(currentUser.company.name)
  ) {
    companyName = currentUser.company.name;
  }

  return {
    appSettings,
    companyName,
  };
};

export default connect(mapStateToProps, { ...settingsActionCreators })(LifeCycleSettings);
