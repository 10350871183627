import React from "react";
import {Route, Switch, RouteComponentProps, Redirect} from "react-router-dom";
import styled from "styled-components";
import { Layout as AntLayout } from "antd";

import Palette from "@src/config/theme/palette";

import GetStarted from "./containers/GetStarted";
import SurveyEdit from "./containers/SurveyEdit";

class Surveys extends React.Component<RouteComponentProps> {

  render() {
    return (
      <Layout>
        {/* All the routes inside /surveys go here */}
        <Switch>
          <Route path="/surveys/create" component={GetStarted} />
          <Route path="/surveys/edit/:id/:currentStepKey" component={SurveyEdit} />
          <Route path="/surveys/edit/:id/" component={SurveyEdit} />
          <Redirect to="/"/>
        </Switch>
      </Layout>
    );
  }
}

const Layout = styled(AntLayout)`
  background: ${Palette.paleGrey};
`;

export default Surveys;
