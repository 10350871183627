import React from "react";
import styled from "styled-components";
import employeeIdSvg from "../assets/Employee_ID.svg";
import dimensionsSvg from "../assets/Dimensions.svg";
import downloadTemplateSvg from "../assets/Download_template.svg";
import Card from "./Card";
import { downloadUsersTemplate } from "@src/utils/exports";
import { Trans } from "react-i18next";
export default class AddUsersFromCSV extends React.Component {
  render() {
    return (
      <Container>
        <div><Trans>A couple of important things to note before you import users:</Trans></div>
        <AllCardsContainer>
          <Card
            leftest
            imageSrc={employeeIdSvg}
            description={
              <CenterDiv>
                <TopTextInCard>
                  <Trans>Either employee email (email) or employee ID (employee_id) is required
                  so that we can identify the users.</Trans>
                </TopTextInCard>
                <Trans>Users already present in the system will be updated.</Trans>
              </CenterDiv>}
          />
          <Card
            imageSrc={dimensionsSvg}
            description={
              <CenterDiv>
                <TextInCard>
                  <Trans>Custom dimensions you have defined are</Trans> <b>department</b>,
                  <b>location</b> and <b>seniority</b>. <Trans>Your CSV should match them.</Trans>
                </TextInCard>
              </CenterDiv>}
            buttonLabel="Edit the dimensions"
            buttonLink="/users/add/dimensions"
          />
          <Card
            imageSrc={downloadTemplateSvg}
            description={
              <CenterDiv>
                <TextInCard>
                  <Trans>The easiest way to import users will be using our template,
                  which will provide you with the right structure to import back in the next step.</Trans>
                </TextInCard>
              </CenterDiv>}
            buttonLabel="Download the template"
            buttonActive
            isDownload
            buttonHandler={downloadUsersTemplate}
          />
        </AllCardsContainer>
        <a
          href={"https://support.betterworks.com/hc/en-us/articles/4519406052365"}
          target="_blank"
          rel="noopener noreferrer">
          <ReadMore><Trans>Read more about uploading a CSV with user data</Trans></ReadMore>
        </a>
      </Container>
    );
  }
}

const Container = styled.div`
  // padding: 0 32px;
`;

const ReadMore = styled.div`
  width: max-content;
  color: #3559ae;
  cursor: pointer;
`;

const AllCardsContainer = styled.div`
  margin: 24px 0;
  display: flex;
  justify-content: center;
`;

const CenterDiv = styled.div`
  text-align: center;
  line-height: 1.43;
`;

const TopTextInCard = styled.div`
  margin-bottom: 7px;
`;

const TextInCard = styled.div`
  margin-bottom: 16px;
`;
