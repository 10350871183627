import React from "react";
import { SurveyInfoResource } from "@hyphen-lib/domain/resource/SurveyInfoResource";
import { StatusTag } from "@components/core/StatusTag";
import { DynamicRowProps } from "@components/core/DynamicRowsTable/withDynamicRows";
import { CellContainer, CellInnerContainer } from "./Cells";

export function StatusCell({ row }: DynamicRowProps<SurveyInfoResource>) {
  return (
    <CellContainer align="center" justifyContent="flex-start" data-cy="surveyList_surveyStatus">
      <CellInnerContainer margin={0} width="87px">
        <StatusTag status={row.status} />
      </CellInnerContainer>
    </CellContainer>
  );
}
