import ContainerCard from "@src/components/core/ContainerCard";
import styled from "styled-components";
import Select from "@src/components/core/Select";
import Checkbox from "@src/components/core/Checkbox";
import { PostCategoryResource } from "hyphen-lib/dist/domain/resource/post/PostCategoryResource";
import { PulsePollResource } from "hyphen-lib/dist/domain/resource/PulsePollResource";
import { SelectValue } from "antd/lib/select";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { PulsePollDisabledFields } from "../store/pulsePollEditTypes";
import { Trans } from "react-i18next";

const { Option } = Select;

interface GeneralSettingsProps {
  readonly categories: PostCategoryResource[];
  readonly selectedCategory: string;
  readonly displayResults: boolean;
  readonly disabled: PulsePollDisabledFields;
  readonly onChange: (modifications: Partial<PulsePollResource>) => void;
  readonly openLearnMoreModal: () => void;
  readonly pulsePollStatus: string;
}

export function GeneralSettings(props: GeneralSettingsProps) {
  function handleChange(value: SelectValue) {
    props.onChange({ category: String(value) });
  }

  function handleCheckBoxChange(
    key: "displayResults" | "isRatingQuestion",
    e: CheckboxChangeEvent
  ) {
    const {
      target: { checked },
    } = e;
    props.onChange({ [key]: checked });
  }

  return (
    <ContainerCard title="General settings">
      <FormContainer>
        {/*
        // @ts-ignore */}
        <Select
          label={<Trans>Poll Category</Trans>}
          helperAction={<Trans>Learn More</Trans>}
          placeholder={<Trans>Select...</Trans>}
          disabled={props.disabled.category}
          value={props.selectedCategory}
          onChange={handleChange}
          onActionClick={props.openLearnMoreModal}
          data-cy="pollCategory"
        >
          {props.categories.map(category => (
            <Option key={category._id} value={category.label} data-cy="category">
              {category.label}
            </Option>
          ))}
        </Select>

        <Checkbox
          info="Users will be able to see the cumulative votes on the poll"
          checked={props.displayResults}
          disabled={props.pulsePollStatus !== "draft"}
          onChange={handleCheckBoxChange.bind(null, "displayResults")}
        >
          <Trans>Show results to voters</Trans>
        </Checkbox>
      </FormContainer>
    </ContainerCard>
  );
}

const FormContainer = styled.div`
  margin: 0 270px;

  > div {
    margin-top: 24px;
  }
`;
