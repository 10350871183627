import React from "react";
import Modal from "@src/components/core/Modal";
import Button from "@components/core/Button";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { ActionResource } from "hyphen-lib/dist/domain/resource/action/ActionResource";
import { ActionModalType } from "@screens/Insights/Actions/store/reducers";
import moment from "moment";
import { Action } from "hyphen-lib/dist/domain/Action";
import { NetworkEventSuccessAction } from "@store/network/actions";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { getErrorsForUpdateActionPlan } from "@screens/Insights/ActionPlans/utils/validations";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { ActionSourceDescription } from "@screens/Insights/ActionPlans/components/ActionSourceDescription";
import { Rate } from "antd";
import { ModalProps } from "antd/lib/modal";
import { TextArea } from "@src/components/core/TextArea";
import { Trans, WithTranslation, withTranslation } from "react-i18next";

interface Props extends WithTranslation{
  visible: boolean;
  actionResource: ActionResource;
  type: ActionModalType;
  onCancel: () => void;
  onUpdate: (
    actionId: string,
    action: ActionResource,
    onSuccessRedirect: (payload: NetworkEventSuccessAction["payload"]) => any
  ) => any;
  readonly isActionUpdating: boolean;
}

interface StateProps {
  comment: string;
  commentError: Optional<string>;
  rating: number;
  ratingError: Optional<string>;
}

class ActionModal extends React.Component<Props, StateProps> {
  constructor(props: Props) {
    super(props);
    this.state = {
      comment: "",
      commentError: Optional.empty(),
      rating: 0,
      ratingError: Optional.empty(),
    };
  }

  onCancel = () => {
    const {
      onCancel,
    } = this.props;
    onCancel();
  };

  onRatingChange = (value: number) => {
    this.setState({ rating: value, ratingError: Optional.empty() });
  };

  onCommentsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ comment: e.target.value, commentError: Optional.empty() });
  };

  onSuccessRedirect = (payload: NetworkEventSuccessAction["payload"]) => {
    this.onCancel();
  };

  onSetToStatusClick = () => {
    const {
      actionResource,
      onUpdate,
      type,
    } = this.props;

    const { comment, rating } = this.state;
    const updatedAction = {
      ...actionResource,
      status: type === ActionModalType.SET_COMPLETE ? Action.Status.COMPLETED :
        Action.Status.DISMISSED,
      comment,
      rating,
      dueDate: moment(actionResource.dueDate).toDate(),
    };

    const consistencyErrors = getErrorsForUpdateActionPlan(this.props.t, updatedAction);
    if (consistencyErrors.size > 0) {
      if (isNotNullNorUndefined(consistencyErrors.get("comment"))) {
        this.setState({ commentError: consistencyErrors.get("comment") });
      }
      if (isNotNullNorUndefined(consistencyErrors.get("rating"))) {
        this.setState({ ratingError: consistencyErrors.get("rating") });
      }
    } else {
      onUpdate(updatedAction._id, updatedAction, this.onSuccessRedirect);
    }
  };

  render() {
    const {
      visible,
      actionResource,
      type,
      isActionUpdating,
    } = this.props;

    const { comment, commentError, rating, ratingError } = this.state;

    let title;
    if (
      actionResource.source.type === Action.SourceType.SURVEY_CATEGORY  ||
      actionResource.source.type === Action.SourceType.SURVEY_COMMENT ||
      actionResource.source.type === Action.SourceType.SURVEY_QUESTION  ||
      actionResource.source.type === Action.SourceType.SURVEY_SEGMENT
    ) {
      title = actionResource.source.context.surveyName;
    } else if (
      actionResource.source.type === Action.SourceType.PULSE_POLL_SEGMENT ||
      actionResource.source.type === Action.SourceType.PULSE_POLL_COMMENT
    ) {
      title = actionResource.source.context.pulsePollName;
    }

    return (
      <StyledModal
        visible={visible}
        title={actionResource.action}
        centered
        onCancel={this.onCancel}
        destroyOnClose={true}
        footer={null}>
        <ModalContent>
          <ContentWrapper>
            <ContentDiv>
              <Section>
                <SectionLabel>
                  <Trans>Origin</Trans>
                </SectionLabel>
                <SectionContent>
                  <ActionSourceDescription
                    {...actionResource.source}
                    title={title}
                  />
                </SectionContent>
              </Section>
              <Section>
                <SectionLabel>
                  <Trans>Due Date</Trans>
                </SectionLabel>
                <SectionContent className="px-1">
                  {moment(actionResource.dueDate).format("MM/DD/YYYY")}
                </SectionContent>
              </Section>
              <Section>
                <SectionLabel>
                  <Trans>Assignee</Trans>
                </SectionLabel>
                <SectionContent>
                  {actionResource.assignee}
                </SectionContent>
              </Section>
            </ContentDiv>
            <ContentDiv>
              {
                type === ActionModalType.VIEW ? (
                  <>
                    <Section2>
                      <Section2Label>
                        <Trans>Completion Comment</Trans>
                      </Section2Label>
                      <SectionContent>
                        {actionResource.comment || "none"}
                      </SectionContent>
                    </Section2>
                    <Section>
                      <SectionLabel>
                        <Trans>Rating</Trans>
                      </SectionLabel>
                      <SectionContent>
                        <Rate disabled value={actionResource.rating || 0} />
                      </SectionContent>
                    </Section>
                  </>
                ) : (type === ActionModalType.SET_COMPLETE) ?
                  (
                    <>
                      <StyledTextArea
                        error={commentError && <Trans>{commentError}</Trans>}
                        value={comment}
                        onChange={this.onCommentsChange}
                        label={<Trans>Completion Comment</Trans>}
                        rows={3}
                        description={<Trans>Please include a note about how this Action Plan is being resolved.</Trans>}
                      />
                      <Section>
                        <SectionLabel>
                        <Trans>Rating</Trans>
                        </SectionLabel>
                        <SectionContent>
                          <Rate value={rating} onChange={this.onRatingChange} />
                          <Warning><Trans>{ratingError}</Trans></Warning>
                        </SectionContent>
                      </Section>
                    </>
                  ) : (type === ActionModalType.SET_DISMISSED) ?
                    (
                      <StyledTextArea
                        error={commentError && <Trans>{commentError}</Trans>}
                        value={comment}
                        onChange={this.onCommentsChange}
                        label={<Trans>Dismissal Comment</Trans>}
                        rows={3}
                        description={<Trans>Please include a note about why this
                          Action Plan is being dismissed.</Trans>}
                      />
                    ) : ""
              }
            </ContentDiv>
          </ContentWrapper>
        </ModalContent>
        <ActionContainer>
          {
            type === ActionModalType.VIEW ? (
              <StyledButton key="cancelLabel" onClick={this.onCancel}  translate="yes">
                Close
              </StyledButton>
            ) : (type === ActionModalType.SET_DISMISSED ) ?

              (
                <StyledButton
                  key="okLabel"
                  color="blue"
                  onClick={this.onSetToStatusClick}
                  disabled={isActionUpdating}
                  translate="yes"
                >
                  {isActionUpdating ? "Dismissing..." : "Set to Dismiss"}
                </StyledButton>
              ) :

              (
                <StyledButton
                  key="okLabel"
                  color="blue"
                  onClick={this.onSetToStatusClick}
                  disabled={isActionUpdating}
                  translate="yes"
                >
                  {isActionUpdating ? "Completing..." : "Set to Complete"}
                </StyledButton>
              )
          }
        </ActionContainer>

      </StyledModal>

    );
  }
}

const ModalContent = styled.div`
  margin-top: 10px;
  padding-left: 32px !important;
  padding-right: 32px !important;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContentDiv = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
`;

const Section = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px 0px;
`;

const SectionLabel = styled.div`
  color: ${Palette.bluishGrey};
  font-size: 14px;
  line-height: 20px;
  min-width: 82px;
`;

const SectionContent = styled.div`
  color: ${Palette.veryDarkBlueGrey};
  font-size: 14px;
  line-height: 20px;
`;

const Section2 = styled.div`
  display: flex;
  margin: 8px 0px;
  flex-direction: column;
`;

const Section2Label = styled.div`
  color: ${Palette.bluishGrey};
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
`;

const StyledModal = styled(Modal)<ModalProps & {children: React.ReactNode}>`
  width: 900px !important;
`;

const ActionContainer = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-around;
  padding-left: 32px !important;
  padding-right: 32px !important;
`;

const StyledButton = styled(Button)`
  // margin-left: 32px;
  min-width: 150px;
`;

const StyledTextArea = styled(TextArea)`
  margin-top: 0px !important;
  margin-bottom: 0px !important;
`;

const Warning = styled.p`
  color: #f5222d;
`;

export default withTranslation()(ActionModal);
