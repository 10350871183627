import { List as ImmutableList, OrderedMap as OrderedImmutableMap } from "immutable";
import { STEP_STATUS, StepProps } from "@components/core/Stepper";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import Logger from "hyphen-lib/dist/util/Logger";
import { isNullOrUndefined } from "hyphen-lib/dist/lang/Objects";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";

const log = Logger.create("Steps");

export interface StepDefinition<SV> {
  readonly key: SV;
  readonly label: string;
  readonly nextStep: Optional<SV>;
  readonly fieldErrorMatchers?: Optional<RegExp[]>;
}

export function reduceSteps<SV extends string>(stepDefinitions: OrderedImmutableMap<SV, StepDefinition<SV>>,
  maxReachedStepIndex: number,
  errors: WrongEntityException.Errors): ImmutableList<StepProps> {

  return stepDefinitions.valueSeq()
    .map((stepDefinition, stepIndex) => {
      const { status, fieldsInError } = getStepStatus(
        stepIndex, stepDefinition, maxReachedStepIndex, errors
      );
      return ({
        key: stepDefinition.key,
        title: stepDefinition.label,
        status,
        fieldsInError,
      });
    })
    .toList();
}

function getStepStatus<SV extends string>(
  stepIndex: number,
  stepDefinition: StepDefinition<SV>,
  maxReachedStepIndex: number,
  errors: WrongEntityException.Errors): { status: STEP_STATUS; fieldsInError: string[] } {

  if (stepIndex > maxReachedStepIndex) {
    return {
      status: STEP_STATUS.incomplete,
      fieldsInError: [],
    };
  }
  const fieldsInError = getStepErrors(stepDefinition, errors);

  return {
    status: isNotEmptyArray(fieldsInError) ? STEP_STATUS.inError : STEP_STATUS.finish,
    fieldsInError,
  };
}

function getStepErrors<SV extends string>(stepDefinition: StepDefinition<SV>,
  errors: WrongEntityException.Errors): string[] {

  if (errors.fields.isEmpty()) {
    return [];
  }

  if (isNullOrUndefined(stepDefinition.fieldErrorMatchers)) {
    return [];
  }

  return errors
    .fields
    .keySeq()
    .filter(fieldKey => stepDefinition.fieldErrorMatchers!.some(errorMatcher => errorMatcher.test(fieldKey)))
    .toArray();
}

export function getStepByKey<SV extends string>(stepDefinitions: OrderedImmutableMap<SV, StepDefinition<SV>>,
  stepToken: string,
  fallbackStep: SV): StepDefinition<SV> {

  const stepDefinition = stepDefinitions.get(stepToken as SV);
  if (isNullOrUndefined(stepDefinition)) {
    log.warn(
      `Unable to get the step value for ${stepToken}, so falling back to ${fallbackStep}`
    );
    return stepDefinitions.get(fallbackStep)!;
  }
  return stepDefinition;
}

export function getStep(steps: ImmutableList<StepProps>,
  stepKey: string): Optional<StepProps> {

  return steps.find(step => step.key === stepKey);
}

export function getStepIndex(steps: ImmutableList<StepProps>,
  stepKey: string): Optional<number> {

  for (let stepIndex = 0; stepIndex < steps.size; stepIndex++) {
    if (stepKey === steps.get(stepIndex)!.key) {
      return stepIndex;
    }
  }

  return Optional.empty();
}

export function getStepLabel<SV extends string>(stepDefinitions: OrderedImmutableMap<SV, StepDefinition<SV>>,
  stepKey: SV): Optional<string> {
  return Optional.map(
    stepDefinitions.get(stepKey),
    stepDefinition => stepDefinition.label
  );
}

export function extractMaxReachedStepIndex(steps: ImmutableList<StepProps>): number {
  const maxFinishedStep = steps.findIndex(
    step => step.status === STEP_STATUS.incomplete
  );
  return maxFinishedStep === -1 ? steps.size - 1 : maxFinishedStep;
}
