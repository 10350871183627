import React from "react";
import styled from "styled-components";
import Palette, { getBarThemeForFavorability } from "@src/config/theme/palette";
import HeatmapHeader
  from "@screens/Insights/Survey/components/HeatmapReport/components/FavorabilityHeatmap/components/HeatmapHeader";
import HeatmapTable
  from "@screens/Insights/Survey/components/HeatmapReport/components/FavorabilityHeatmap/components/HeatmapTable";
import { ComputedHeatMap } from "@hyphen-lib/domain/aggregate/calculation/ComputedHeatMap";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { CompareWithOption } from "@screens/Insights/components/ViewOptions/components/CompareWith";
import { Empty } from "antd";
import { AnonymityFiltered } from "@components/core/AnonymityFiltered";
import { AnonymityFilterExplanation } from "hyphen-lib/dist/domain/common/AnonymityFilterExplanation";
import { ParticipationReportResource } from "hyphen-lib/dist/domain/resource/survey/report/ParticipationReportResource";
import HeatmapLegend from
  "@screens/Insights/Survey/components/HeatmapReport/components/FavorabilityHeatmap/components/HeatmapLegend";
import { Trans } from "react-i18next";

interface Props {
  readonly surveyId: string;
  readonly surveyName: string;
  readonly heatMap: ComputedHeatMap;
  readonly selectComponent: React.ReactNode;
  readonly areComparisonsVisible?: boolean;
  readonly compareWithOptions: CompareWithOption[];
  readonly comparisonKey: Optional<string>;
  readonly filteredForAnonymity: boolean;
  readonly filteredForAnonymityReason?: Optional<AnonymityFilterExplanation>;
  readonly anonymityThreshold: number;
  readonly scoreOrDelta: "score" | "delta";
  readonly participationResource: ParticipationReportResource;
  readonly selectedDimension: string;
  readonly likertLabels?: Optional<string[]>;
}

export default class FavorabilityHeatmap extends React.Component<Props> {
  render() {
    const {
      surveyId,
      surveyName,
      heatMap,
      areComparisonsVisible = true,
      compareWithOptions,
      comparisonKey,
      filteredForAnonymity,
      filteredForAnonymityReason,
      anonymityThreshold,
      scoreOrDelta,
      participationResource,
      likertLabels
    } = this.props;
    const heatmapTheme = getBarThemeForFavorability(likertLabels?.length as number, likertLabels as string[]);

    if (filteredForAnonymity) {
      return (
        <Container>
          <AnonymityFiltered
            explanation={filteredForAnonymityReason!}
            anonymityThreshold={anonymityThreshold}
            translate="yes"
          />
        </Container>
      );
    }

    return (
      <Container>
        <HeaderLegends>
          <div>
            <HeatmapHeader 
              heatMap={heatMap} />
            {heatMap.numberOfQuestions > 0 && heatMap.numberOfVotes ?
            <SelectContainer>{this.props.selectComponent}</SelectContainer>: null}
          </div>
            <HeatmapLegend 
              heatmapTheme={heatmapTheme}
            />
        </HeaderLegends>
        {heatMap.numberOfQuestions > 0 && heatMap.numberOfVotes ?
            <HeatmapTable
              surveyId={surveyId}
              surveyName={surveyName}
              heatMap={heatMap}
              areComparisonsVisible={areComparisonsVisible}
              compareWithOptions={compareWithOptions}
              comparisonKey={comparisonKey}
              anonymityThreshold={anonymityThreshold}
              scoreOrDelta={scoreOrDelta}
              participationResource={participationResource}
              selectedDimension={this.props.selectedDimension}
              heatmapTheme={heatmapTheme}
            /> : <Empty description={<Trans>Not enough data</Trans>} />
        }
      </Container>
    );
  }
}

const HeaderLegends = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SelectContainer= styled.div`
  padding: 0;
  margin: 0;
  padding-top: 16px;
  padding-left: 32px;
`;

const Container = styled.div`
  font-family: Lato;
  padding: 20px 0;
  background: ${Palette.white};
`;
