import { getResourceById } from "@store/network/selectors";
import { State } from "@store/types";

import { CurrentUserResource } from "@hyphen-lib/domain/resource/user/CurrentUserResource";
import { RightsMatcher } from "@hyphen-lib/business/auth/Auth";
import { Dimensions } from "@hyphen-lib/domain/common/Dimensions";
import { getDimensionsHavingSegments } from "@hyphen-lib/business/user/Dimensions";
import { Optional } from "@hyphen-lib/lang/Optionals";
import { getOr, isNotNullNorUndefined, mapOr } from "@hyphen-lib/lang/Objects";
import { SurveyResource } from "@hyphen-lib/domain/resource/SurveyResource";
import { CompanyResource } from "@hyphen-lib/domain/resource/CompanyResource";
import { onlyField } from "@hyphen-lib/lang/Functions";
import { Store } from "@hyphen-lib/util/store/Store";
import { CURRENT } from "@src/utils/networks";
import { sanitizeDimensions } from "@src/utils/Dimensions";
import { Apps } from "@hyphen-lib/domain/apps/App";
import { compareDates, utcNow } from "@hyphen-lib/lang/Dates";
import { Notification } from "@hyphen-lib/domain/trait/WithNotifications";

export const getSessionToken = (state: State) => state.insights.sessionToken;
export const getCurrentUserId = (state: State) => state.insights.currentUserId;

export function getCurrentUser(state: State): Optional<CurrentUserResource> {
  return Store.Element.toOptional(getResourceById(state, CurrentUserResource.TYPE, CURRENT));
}

export function getCurrentUserNotification(state: State, page: Apps.Pages): Notification {
  const currentUser = getCurrentUser(state);
  // taking advantage of type coercion here. Else, we would have to include currentUser === undefined
  if ( currentUser == null ) {
    return {} as Notification;
  }
  if (currentUser.notifications && currentUser.notifications.length) {
    return getOr(currentUser.notifications.filter(notificationItem => 
      notificationItem.location === page &&
      compareDates(new Date(notificationItem.expirationDate), utcNow()) >= 0)[0], {} as Notification);
  }
  return {} as Notification;
}

/**
 * Gets allow SMS for the Current User
 *
 * @param {State} state
 * @return {boolean} allowSMS
 */
export function getAllowSMSPermission(state: State): boolean {
  return mapOr(
    getCurrentUser(state),
    (user: CurrentUserResource) =>
      mapOr(
        user.company,
        company => isNotNullNorUndefined(company.channels.sms) && company.channels.sms.isAllowed,
        false
      ),
    false
  );
}

export function getCurrentSurvey(state: State, surveyId: string): Optional<SurveyResource> {
  const survey = getResourceById(state, SurveyResource.TYPE, surveyId);
  if (Store.Element.isLoaded(survey)) {
    return survey.value;
  }
}

/**
 * Gets the current user's rightsMatcher instance.
 *
 * fixme: we need to find a way to map resources once returned, probably using FETCH_CURRENT_USER_SUCCESS
 * fixme: in the right reducer. But the question is where do we want to store data like that?
 *
 * @param {State} state
 * @return {Optional<RightsMatcher>}
 */
export function getRightsMatcher(state: State): RightsMatcher {
  return mapOr(
    getCurrentUser(state),
    (user: CurrentUserResource) => RightsMatcher.parse(user.serializedRightsMatcher),
    RightsMatcher.noRights()
  );
}

/**
 * Gets the dimensions that have segments defined.
 *
 * @param {State} state
 * @return {Optional<Dimensions>}
 */
export function getDimensions(state: State): Optional<Dimensions> {
  return sanitizeDimensions(
    mapOr(
      getCurrentUser(state),
      (user: CurrentUserResource) =>
        mapOr(
          user.company,
          company => getDimensionsHavingSegments(company.dimensions),
          undefined
        ),
      undefined
    )
  );
}

/**
 * Gets all dimensions, including the ones that don't have segments.
 *
 * @param {State} state
 * @return {Optional<Dimensions>}
 */
export function getAllDimensions(state: State): Optional<Dimensions> {
  return sanitizeDimensions(
    mapOr(
      getCurrentUser(state),
      (user: CurrentUserResource) =>
        mapOr(
          user.company,
          company => company.dimensions,
          undefined
        ),
      undefined
    )
  );
}

/**
 * Gets the current company resource.
 *
 * @param {State} state
 * @return {Optional<Dimensions>}
 */
export function getCompany(state: State): Optional<CompanyResource> {
  return Optional.map(
    getCurrentUser(state),
    onlyField("company")
  );
}

/**
 * Gets the available roles.
 *
 * @param {State} state
 * @return {Optional<Dimensions>}
 */
export function getAvailableRoles(state: State): string[] {
  return mapOr(
    getCurrentUser(state),
    (user: CurrentUserResource) =>
      mapOr(
        user.company,
        company => getOr(company.availableRoles, []),
        []
      ),
    []
  );
}
