import { OrderedMap as OrderedImmutableMap } from "immutable";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { SurveyStepValue } from "@screens/Insights/Surveys/store/surveyEditTypes";
import { StepDefinition } from "@src/utils/wizard/Steps";
import { SurveyResource } from "hyphen-lib/dist/domain/resource/SurveyResource";
import { getOr } from "hyphen-lib/dist/lang/Objects";
import { Survey } from "hyphen-lib/dist/domain/Survey";

const STEP_DEFINITIONS: OrderedImmutableMap<SurveyStepValue, StepDefinition<SurveyStepValue>> = OrderedImmutableMap({
  "settings": {
    key: "settings",
    label: "Settings",
    nextStep: "questions",
    fieldErrorMatchers: [
      /^name$/,
      /^type$/,
      /^logo$/,
      /^introductionText$/,
      /^isAnonymous$/,
      /^allowSkipQuestion$/,
      /^reminderInterval$/,
      /^reminderLimit$/,
      /^channels.*$/,
    ],
  },
  "questions": {
    key: "questions",
    label: "Questions",
    nextStep: "audience",
    fieldErrorMatchers: [
      /^postTemplateIds$/,
    ],
  },
  "audience": {
    key: "audience",
    label: "Audience",
    nextStep: "access",
    fieldErrorMatchers: [
      /^audience.*$/,
    ],
  },
  "access": {
    key: "access",
    label: "Access",
    nextStep: "summary",
  },
  "summary": {
    key: "summary",
    label: "Summary",
    nextStep: Optional.empty(),
  },
}) as OrderedImmutableMap<SurveyStepValue, StepDefinition<SurveyStepValue>>;

export function getAllSurveyStepDefinitions(): OrderedImmutableMap<SurveyStepValue, StepDefinition<SurveyStepValue>> {
  return STEP_DEFINITIONS;
}

export function getSurveyMaxReachedStep(survey: SurveyResource): SurveyResource["maxReachedStep"] {
  if (survey.status === Survey.Statuses.DRAFT) {
    return getOr(survey.maxReachedStep, 1);
  }
  return 5;
}
