import React from "react";
import styled from "styled-components";

import Palette from "@src/config/theme/palette";

import { SentimentDonutSlice, getSliceRotation } from "./components/SentimentDonutSlice";
import { SentimentDonutTooltip } from "./components/SentimentDonutTooltip";
export interface SentimentData {
  percentage: number;
  value: number;
  color: string;
  text: string;
}

export interface SentimentDonutChartData {
  name: string;
  sentiments: SentimentData[];
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  data: SentimentDonutChartData;
  size?: number;
  count?: number;
  hideTooltip?: boolean;
}

export const getTotalValue = (sentiments: SentimentData[]) =>
    sentiments.reduce((total: number, sentiment: SentimentData) => total + sentiment.value, 0);

export function SentimentDonutChart({ count, data, hideTooltip = false, size = 100, ...divProps }: Props) {
  let incrementalPercentageSum = 0;

  return (
    <Container hideTooltip={hideTooltip} size={size} {...divProps} data-cy={`sentimentDonutChart_${data.name}`}>
        { !hideTooltip && <SentimentDonutTooltip data={data} size={size}/> }
        <Chart size={size}>
          {data.sentiments.map((sentiment: SentimentData, sliceIndex: number) => {
            const rotation = getSliceRotation(incrementalPercentageSum);
            incrementalPercentageSum += sentiment.percentage;
            return (
              <SentimentDonutSlice
                key={sliceIndex}
                size={size}
                percentage={sentiment.percentage}
                rotation={rotation}
                color={sentiment.color}
              />
            );
          })}
          <NameLabel data-cy={data.name}>
            <SentimentLabel data-cy={`category_${data.name}_count`} width={size}>
              {data.name}
            </SentimentLabel>
            { count && <div className="sentiment-count">{count}</div> }
          </NameLabel>
        </Chart>
    </Container>
  );
}

const ChartDetailsContainer = styled.div`
  display: none;
  font-family: Lato, sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  @media print {
    display: block;
  }
`;

export const Chart = styled.div<{ size: number }>`
  position: relative;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 50%;
  overflow: hidden;
  @media print {
    display: none;
  }
`;

const NameLabel = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  width: 85%;
  height: 85%;
  background: #FFF;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  font-family: Lato, sans-serif;
  font-size: 12px;
  color: ${Palette.veryDarkBlueGrey};
  align-items: center;
  justify-content: center;
  z-index: 20;
  .sentiment-count {
    color: ${Palette.bluishGrey};
  }
`;

const SentimentLabel = styled.div<{ width: number }>`
  width: ${props => props.width - 25}px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow-wrap: break-word;
  -webkit-box-orient: vertical;
`;

const Container = styled.div<{ hideTooltip: boolean; size: number }>`
  position: ${props => props.hideTooltip ? "fixed" : "relative"};
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  cursor: pointer;
  // transition: transform .2s;
  display: inline-block;
  ${SentimentLabel}, .ant-tooltip-inner {
    text-align: center;
  }
  :hover ${ChartDetailsContainer} {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }

  :hover .ant-tooltip{
    visibility: visible;
  }

  .ant-tooltip {
    visibility: hidden;
  }
`;
