import React from "react";
import styled from "styled-components";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getOr, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";

import { Heading } from "@components/core/Typography";
import Palette, { getBarThemeForMultipleChoice } from "@src/config/theme/palette";
import { Breadcrumb, goTo } from "@src/utils/locations";

import AnswerDistributionGraph from
  "@screens/Insights/Survey/components/QuestionHighlightReport/components/AnswerDistributionGraph";
import AnswerDistributionLegend, { LegendItem } from
  "@screens/Insights/Survey/components/QuestionHighlightReport/components/AnswerDistributionLegend";
import AnswerDistributionBarChart from
  "@screens/Insights/Survey/components/QuestionHighlightReport/components/AnswerDistributionBarChart";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { DistributionResult } from "hyphen-lib/dist/domain/resource/report/common/DistributionResult";
import { GraphColor } from "@src/screens/Insights/Survey/components/QuestionHighlightReport/components/AnswersChart";
import {SentimentWithComments} from "@screens/Insights/components/SentimentWithComments";
import { Trans } from "react-i18next";

const defaultGraphColors: GraphColor[] = getBarThemeForMultipleChoice([]);

type Props = WithoutSentimentProps | WithSentimentProps;

interface CommonProps extends RouteComponentProps<{ id: string }> {
  readonly title?: string | React.ReactNode;
  readonly hasSentimentScore: boolean;
  readonly questionText?: string;
  readonly choiceLabels: Optional<string>[];
  readonly distribution: DistributionResult;
  readonly graphColors?: GraphColor[];
  readonly onCommentsClick?: () => void;
  readonly totalVotes?: number;
  readonly postAndSurveySentiment?: boolean;
}

interface WithoutSentimentProps extends CommonProps {
  readonly hasSentimentScore: false;
}

interface WithSentimentProps extends CommonProps {
  readonly hasSentimentScore: true;
  readonly numberOfComments: number;
  readonly netSentimentScore: Optional<number>;
}

/*
    fixme: BEFORE LAUNCH!!!
    fixme: BEFORE LAUNCH!!!
    fixme: BEFORE LAUNCH!!!
    fixme: BEFORE LAUNCH!!!
    fixme: BEFORE LAUNCH!!!
    fixme: BEFORE LAUNCH!!!

    Refactor the name of this component, this is not sentiment, but distribution, and where is it used?
    only in multiple-choice drill down?!
 */

export class CommentsReport extends React.Component<Props> {
  state = {
    isExpanded: false,
  };

  toggleExpand = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  gotoComments = () => {
    const { history, questionText, onCommentsClick } = this.props;
    if (isNotNullNorUndefined(questionText)) {
      if (isNotNullNorUndefined(onCommentsClick)) {
        return onCommentsClick();
      }else {
        goTo(
          history.location.pathname.replace(/\/$/,"") + "/comments",
          Breadcrumb.stack(questionText)
        );
      }
    }
  };

  renderSentimentScore = () => {
    if (this.props.hasSentimentScore) {
      const { netSentimentScore, numberOfComments } = this.props;
      return (
        <SentimentContainer>
          <Heading size="small" weight={"bold"} translate="yes">
            Sentiment
          </Heading>
          <SentimentWithComments
            netSentimentScore={netSentimentScore}
            hasComments
            hasCommentsAccess
            numberOfComments={numberOfComments}
            onCommentsClick={this.gotoComments}
          />
        </SentimentContainer>
      );
    }
  };

  render() {
    const {
      choiceLabels,
      distribution,
      title = "Overview",
      hasSentimentScore,
      graphColors = defaultGraphColors,
      totalVotes = 0,
      postAndSurveySentiment
    } = this.props;
    const { isExpanded } = this.state;
    const legendItems = choiceLabels.map((label, index) => ({
      ...graphColors[index],
      label,
    })) as LegendItem[];
    const answerDistributionTitle = "Answer distribution";

    return (
      <Container>
        {typeof title === "string" ? (
          <Heading size="large" translate="yes">{title}</Heading>
        ) : (
          title
        )}
        {hasSentimentScore && getOr(postAndSurveySentiment, true) && this.renderSentimentScore()}
        <AnswerDistributionContainer>
          <Heading size="small" weight={"bold"}>
            {typeof title === "string" &&
              title !== answerDistributionTitle &&
              <Trans>{answerDistributionTitle}</Trans>}
            <ExpandCollapseButton data-cy="expandButton" onClick={this.toggleExpand}>
              <Trans>{isExpanded ? "Collapse" : "Expand"}</Trans>
            </ExpandCollapseButton>
          </Heading>
          <AnswerDistributionGraphContainer data-cy="expandedViewOfVotes">
            {isExpanded ? (
              <AnswerDistributionBarChartContainer>
                <AnswerDistributionBarChart
                  choiceLabels={choiceLabels}
                  distribution={distribution}
                  graphColors={graphColors}
                  totalVotes={totalVotes}
                />
              </AnswerDistributionBarChartContainer>
            ) : (
              <AnswerDistributionGraph
                data={distribution}
                choiceLabels={choiceLabels}
                graphColors={graphColors}
              />
            )}
            <AnswerDistributionLegendContainer>
              <AnswerDistributionLegend legendItems={legendItems} />
            </AnswerDistributionLegendContainer>
          </AnswerDistributionGraphContainer>
        </AnswerDistributionContainer>
      </Container>
    );
  }
}

const Container = styled.div`
  padding: 32px;
  background: ${Palette.white};
  font-family: Lato;
  color: ${Palette.veryDarkBlueGrey};
  box-shadow: 0 4px 8px 0 rgba(198, 214, 223, 0.15);
  border-radius: 3px;
`;

const SentimentContainer = styled.div`
  margin-top: 24px;
`;

const ExpandCollapseButton = styled.span`
  color: ${Palette.bluePurple};
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  margin-left: 8px;
`;

const AnswerDistributionContainer = styled.div`
  margin-top: 40px;
`;

const AnswerDistributionGraphContainer = styled.div`
  margin-top: 32px;
`;

const AnswerDistributionLegendContainer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
`;

const AnswerDistributionBarChartContainer = styled.div`
  padding: 8px 0;
`;

const CommentSentiment = withRouter<Props, React.ComponentType<Props>>(CommentsReport);
export default CommentSentiment;
