import { State } from "@store/types";
import { getResources } from "@src/store/network/selectors";
import { UploadUsersResource } from "hyphen-lib/dist/domain/resource/user/UploadUsersResource";
import { UserListContainerStateProps } from "./reducers";

export function getPageSize(state: State): number {
  return state.insights_userList.pageSize;
}

export function getUserListStateProps(state: State): UserListContainerStateProps {
  return state.get("insights_userList");
}

export const getUploadUsersStatus = (state: State) => getResources(state, UploadUsersResource.TYPE);
