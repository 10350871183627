import React from "react";

import Select from "@components/core/Select";

import { UserPermissionModalBaseProps } from "./BulkEditPermissionsModal";

const { Option } = Select;

interface Props extends UserPermissionModalBaseProps {
  availableOptions: string[];
}

export function EditAccessModalContent(props: Props) {
  const { value, onChange, availableOptions } = props;
  return (
    // @ts-ignore
    <Select value={value} onChange={onChange} >
      {availableOptions.map(option => (
        <Option value={option} key={option}>{option}</Option>
      ))}
    </Select>
  );
}
