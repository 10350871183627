import { FSA } from "flux-standard-action";
import { createRequest, FilterParameter, SortParameter } from "@src/utils/networks";
import { SurveyInfoResource } from "hyphen-lib/dist/domain/resource/SurveyInfoResource";
import {
  ActionTypes as NetworkActionTypes,
  NetworkEventErrorAction,
  NetworkEventRequestAction,
  NetworkEventSuccessAction,
  NetworkRequestAction
} from "@store/network/actions";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { NotificationChannels } from "hyphen-lib/dist/domain/NotificationChannels";

export enum SurveyListActionTypes {
  FETCH_SURVEY_REQUEST = "surveyList/FETCH_SURVEY_REQUEST",
  FETCH_SURVEY_SUCCESS = "surveyList/FETCH_SURVEY_SUCCESS",
  FETCH_SURVEY_ERROR = "surveyList/FETCH_SURVEY_ERROR",

  MODIFY_LIST = "surveyList/MODIFY_LIST",
  SEND_SURVEY_REMINDER = "surveyList/SEND_SURVEY_REMINDER",
  DELETE_SURVEY = "surveyList/DELETE_SURVEY",
  CLOSE_SURVEY = "surveyList/CLOSE_SURVEY",

  TOGGLE_REMINDER_MODAL_OPEN = "surveyList/TOGGLE_REMINDER_MODAL_OPEN",
  TOGGLE_DUPLICATE_MODAL_OPEN = "surveyList/TOGGLE_DUPLICATE_MODAL_OPEN",
  TOGGLE_DELETE_MODAL_OPEN = "surveyList/TOGGLE_DELETE_MODAL_OPEN",
  TOGGLE_CLOSE_MODAL_OPEN = "surveyList/TOGGLE_CLOSE_MODAL_OPEN",

  SEND_SURVEY_REMINDERS_REQUEST = "surveyList/SEND_SURVEY_REMINDERS_REQUEST",
  SEND_SURVEY_REMINDERS_SUCCESS = "surveyList/SEND_SURVEY_REMINDERS_SUCCESS",
  SEND_SURVEY_REMINDERS_ERROR = "surveyList/SEND_SURVEY_REMINDERS_ERROR",

  DELETE_SURVEY_REQUEST = "surveyList/DELETE_SURVEY_REQUEST",
  DELETE_SURVEY_SUCCESS = "surveyList/DELETE_SURVEY_SUCCESS",
  DELETE_SURVEY_ERROR = "surveyList/DELETE_SURVEY_ERROR",

  CLOSE_SURVEY_REQUEST = "surveyList/CLOSE_SURVEY_REQUEST",
  CLOSE_SURVEY_SUCCESS = "surveyList/CLOSE_SURVEY_SUCCESS",
  CLOSE_SURVEY_ERROR = "surveyList/CLOSE_SURVEY_ERROR",
  DUPLICATE_SURVEY_REQUEST = "surveyList/DUPLICATE_SURVEY_REQUEST",
  DUPLICATE_SURVEY_SUCCESS = "surveyList/DUPLICATE_SURVEY_SUCCESS",
  DUPLICATE_SURVEY_ERROR = "surveyList/DUPLICATE_SURVEY_ERROR",

  CLOSE_SURVEY_RESOURCE_SUCCESS = "surveyList/CLOSE_SURVEY_RESOURCE_SUCCESS",
  CLOSE_INDIVIDUAL_SURVEY = "surveyList/CLOSE_INDIVIDUAL_SURVEY",

}

interface Payloads {
  [SurveyListActionTypes.MODIFY_LIST]: SurveyInfosPageParameters;
  [SurveyListActionTypes.SEND_SURVEY_REMINDER]: NotificationChannels;
  [SurveyListActionTypes.DELETE_SURVEY]: {};
  [SurveyListActionTypes.CLOSE_SURVEY]: {};
  [SurveyListActionTypes.DUPLICATE_SURVEY_REQUEST]: DuplicateSurveyParameters;
  [SurveyListActionTypes.CLOSE_INDIVIDUAL_SURVEY]: {};
}

export interface ModifyListAction
  extends FSA<SurveyListActionTypes.MODIFY_LIST, Payloads[SurveyListActionTypes.MODIFY_LIST]> {
  type: SurveyListActionTypes.MODIFY_LIST;
  payload: Payloads[SurveyListActionTypes.MODIFY_LIST];
}

export interface SendSurveyReminderAction
  extends FSA<SurveyListActionTypes.SEND_SURVEY_REMINDER, Payloads[SurveyListActionTypes.SEND_SURVEY_REMINDER]> {
  type: SurveyListActionTypes.SEND_SURVEY_REMINDER;
  payload: Payloads[SurveyListActionTypes.SEND_SURVEY_REMINDER];
}

export interface DeleteSurveyAction
  extends FSA<SurveyListActionTypes.DELETE_SURVEY, Payloads[SurveyListActionTypes.DELETE_SURVEY]> {
  type: SurveyListActionTypes.DELETE_SURVEY;
  payload: Payloads[SurveyListActionTypes.DELETE_SURVEY];
}

export interface CloseSurveyAction
  extends FSA<SurveyListActionTypes.CLOSE_SURVEY, Payloads[SurveyListActionTypes.CLOSE_SURVEY]> {
  type: SurveyListActionTypes.CLOSE_SURVEY;
  payload: Payloads[SurveyListActionTypes.CLOSE_SURVEY];
}

export interface CloseIndividualSurveyAction
  extends FSA<SurveyListActionTypes.CLOSE_INDIVIDUAL_SURVEY, Payloads[SurveyListActionTypes.CLOSE_INDIVIDUAL_SURVEY]> {
  type: SurveyListActionTypes.CLOSE_INDIVIDUAL_SURVEY;
  payload: Payloads[SurveyListActionTypes.CLOSE_INDIVIDUAL_SURVEY];
}

export interface SurveyInfosPageParameters {
  readonly filter?: FilterParameter;
  readonly sort?: SortParameter;
  readonly page: PageFilter;
}

export interface DuplicateSurveyParameters {
  surveyId: string;
  linkDuplicate?: boolean;
}

export interface DuplicateSurveyAction
  extends FSA<
  SurveyListActionTypes.DUPLICATE_SURVEY_REQUEST,
  Payloads[SurveyListActionTypes.DUPLICATE_SURVEY_REQUEST]
  > {
  type: SurveyListActionTypes.DUPLICATE_SURVEY_REQUEST;
  payload: Payloads[SurveyListActionTypes.DUPLICATE_SURVEY_REQUEST];
}

export function getSurveyResource(surveyId: string) {
  return createRequest(`/surveys/${surveyId}`);
}

export function createSendSurveyRemindersRequest(
  surveyId: string,
  payload: SendSurveyReminderAction["payload"]
): Request {
  return createRequest(`/surveys/${surveyId}/reminders`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export const createDuplicateSurveyRequest =
  ({ surveyId, linkDuplicate }: DuplicateSurveyParameters) => {
    const data = {
      surveyDuplicateId: surveyId,
      ...(isNotNullNorUndefined(linkDuplicate) ? {
        linkDuplicate,
      } : {}),
    };
    return createRequest("/surveys", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  };

function createDeleteSurveyRequest(surveyId: string): Request {
  return createRequest(`/surveys/${surveyId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function createCloseSurveyRequest(surveyId: string): Request {
  return createRequest(`/surveys/${surveyId}/close`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export const surveyListActionCreators = {
  sendSurveyRemindersRequest: (
    surveyId: string,
    surveyReminderPayload: SendSurveyReminderAction["payload"]
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createSendSurveyRemindersRequest(surveyId, surveyReminderPayload),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: SurveyListActionTypes.SEND_SURVEY_REMINDERS_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: SurveyListActionTypes.SEND_SURVEY_REMINDERS_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: SurveyListActionTypes.SEND_SURVEY_REMINDERS_ERROR, payload }),
      ],
    },
  }),
  deleteSurveyRequest: (surveyId: string): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createDeleteSurveyRequest(surveyId),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: SurveyListActionTypes.DELETE_SURVEY_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: SurveyListActionTypes.DELETE_SURVEY_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: SurveyListActionTypes.DELETE_SURVEY_ERROR, payload }),
      ],
    },
  }),
  closeSurveyRequest: (surveyId: string): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createCloseSurveyRequest(surveyId),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: SurveyListActionTypes.CLOSE_SURVEY_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: SurveyListActionTypes.CLOSE_SURVEY_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: SurveyListActionTypes.CLOSE_SURVEY_ERROR, payload }),
      ],
    },
  }),
  closeIndividualSurveyRequest: (surveyId: string): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createCloseSurveyRequest(surveyId),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: SurveyListActionTypes.CLOSE_SURVEY_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: SurveyListActionTypes.CLOSE_SURVEY_RESOURCE_SUCCESS, payload, meta: { surveyId } }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: SurveyListActionTypes.CLOSE_SURVEY_ERROR, payload }),
      ],
    },
  }),
  modifyList: (payload: ModifyListAction["payload"]): ModifyListAction => ({
    type: SurveyListActionTypes.MODIFY_LIST,
    payload,
  }),
  sendSurveyReminders: (payload: SendSurveyReminderAction["payload"]): SendSurveyReminderAction => ({
    type: SurveyListActionTypes.SEND_SURVEY_REMINDER,
    payload,
  }),
  deleteSurvey: (): DeleteSurveyAction => ({
    type: SurveyListActionTypes.DELETE_SURVEY,
    payload: {},
  }),
  closeSurvey: (): CloseSurveyAction => ({
    type: SurveyListActionTypes.CLOSE_SURVEY,
    payload: {},
  }),
  closeIndividualSurvey: (): CloseIndividualSurveyAction => ({
    type: SurveyListActionTypes.CLOSE_INDIVIDUAL_SURVEY,
    payload: {},
  }),
  fetchSurvey: (surveyId: string): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: getSurveyResource(surveyId),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => ({
          type: SurveyListActionTypes.FETCH_SURVEY_REQUEST,
          payload,
        }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({
          type: SurveyListActionTypes.FETCH_SURVEY_SUCCESS,
          payload,
        }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({
          type: SurveyListActionTypes.FETCH_SURVEY_ERROR,
          payload,
        }),
      ],
    },
  }),
  toggleReminderModalOpen: (reminderModalOpen: boolean, selectedSurveyInfoResource: SurveyInfoResource) => ({
    type: SurveyListActionTypes.TOGGLE_REMINDER_MODAL_OPEN,
    reminderModalOpen,
    selectedSurveyInfoResource,
  }),
  toggleDuplicateModalOpen: (duplicateModalOpen: boolean, selectedSurveyInfoResource: SurveyInfoResource) => ({
    type: SurveyListActionTypes.TOGGLE_DUPLICATE_MODAL_OPEN,
    duplicateModalOpen,
    selectedSurveyInfoResource,
  }),
  toggleDeleteModalOpen: (deleteModalOpen: boolean, selectedSurveyInfoResource: SurveyInfoResource) => ({
    type: SurveyListActionTypes.TOGGLE_DELETE_MODAL_OPEN,
    deleteModalOpen,
    selectedSurveyInfoResource,
  }),
  toggleCloseModalOpen: (closeModalOpen: boolean, selectedSurveyInfoResource: SurveyInfoResource) => ({
    type: SurveyListActionTypes.TOGGLE_CLOSE_MODAL_OPEN,
    closeModalOpen,
    selectedSurveyInfoResource,
  }),
  duplicateSurvey: (
    surveyId: string,
    onSuccessRedirect?: (payload: NetworkEventSuccessAction["payload"]) => void,
    linkDuplicate?: boolean
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createDuplicateSurveyRequest({ surveyId, linkDuplicate }),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => ({
          type: SurveyListActionTypes.DUPLICATE_SURVEY_REQUEST,
          payload,
        }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({
          type: SurveyListActionTypes.DUPLICATE_SURVEY_SUCCESS,
          payload,
        }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({
          type: SurveyListActionTypes.DUPLICATE_SURVEY_ERROR,
          payload,
        }),
      ],
      onSuccessRedirect,
    },
  }),
};
