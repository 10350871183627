import notificationActionCreators from "@store/notifications/actions";
// import * as notificationActionCreators from "@store/notifications/actions";
import { call, takeLatest } from "@redux-saga/core/effects";
import { freeze } from "hyphen-lib/dist/lang/Objects";
import { goTo, replaceLocation } from "@src/utils/locations";
import * as notificationFactory from "@src/store/notifications/notification-factory";
import {
  BatarangScriptsActionTypes, FinishAction,
  UpdateParameterValuesAction,
  UpdateScriptAction,
  UploadAssetRequestAction
} from "@screens/Insights/Settings/containers/batarang/store/batarangScriptsActions";
import { put, take } from "redux-saga/effects";
import { createUploadFileChannel } from "@src/utils/networks";
import { actionCreators, CleanResourceAction } from "@src/store/network/actions";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";

export function* updateScriptInURL({ payload: { scriptName } }: UpdateScriptAction) {
  yield call(
    goTo,
    `/settings/batarang/scripts/${scriptName}`
  );
}

export function* updateParameterValuesInURL({ payload: { values } }: UpdateParameterValuesAction) {
  yield call(
    replaceLocation,
    values
  );
}

export function* resetURL({payload}: FinishAction) {
  yield call(
    goTo,
    "/settings/batarang/scripts"
  );
}

export function* uploadAssetRequest({payload: {file}}: UploadAssetRequestAction) {
  const acceptedFileTypes = ["text/csv"];

  if (!acceptedFileTypes.includes(file.type)) {
    const error = {
      type: "FileTypeError",
      description: "This file type is not supported. Please upload csv type only",
    };
    const notification = notificationFactory.error(error.type, error.description, 4.5);
    return yield put(notificationActionCreators.displayNotification(notification));
  }

  yield put({
    type: BatarangScriptsActionTypes.UPLOAD_ASSET_REQUEST_START,
    payload: {fileName: file.name, file },
  });
  const channel = yield call(createUploadFileChannel, "/batarang/scripts/assets/upload", file);

  while (true) {
    const { progress = 0, err: error, success, response } = yield take(channel);
    if (error) {
      yield put({
        type: BatarangScriptsActionTypes.UPLOAD_ASSET_ERROR,
        payload: {fileName: file.name, error },
      });
      return;
    }
    if (success) {
      yield put({
        type: BatarangScriptsActionTypes.UPLOAD_ASSET_SUCCESS,
        payload: { assetId: response.body.assetId },
      });
      return;
    }
    yield put({
      type: BatarangScriptsActionTypes.UPLOAD_ASSET_PROGRESS,
      payload: {progress, fileName: file.name},
    });
  }
}

function* cleanResourceOnEngageBWScriptSuccess({ meta }: any) {
  if(meta === "EngageAndBetterworksIntegration"){
    yield put<CleanResourceAction>(actionCreators.cleanResource(CurrentUserResource.TYPE));
  }
}

export const batarangScriptsSagas = freeze([

  takeLatest(
    BatarangScriptsActionTypes.UPDATE_SCRIPT,
    updateScriptInURL
  ),

  takeLatest(
    BatarangScriptsActionTypes.UPDATE_PARAMETER_VALUES,
    updateParameterValuesInURL
  ),

  takeLatest(
    BatarangScriptsActionTypes.FINISH,
    resetURL
  ),

  takeLatest(
    BatarangScriptsActionTypes.UPLOAD_ASSET_REQUEST,
    uploadAssetRequest
  ),

  takeLatest(
    BatarangScriptsActionTypes.EXECUTE_SUCCESS,
    cleanResourceOnEngageBWScriptSuccess
  )
]);
