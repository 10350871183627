import React from "react";
import styled from "styled-components";

import {
  CellContainer,
  CellInnerContainer
} from "@screens/Insights/SurveyList/components/cells/Cells";
import { DynamicRowProps } from "@components/core/DynamicRowsTable/withDynamicRows";
import Palette from "@src/config/theme/palette";

interface QuestionCellProps {
  question: string;
  choices?: string[];
  category: string;
}

export function QuestionCell({ row }: DynamicRowProps<QuestionCellProps>) {
  return (
    <CellContainer align="flex-start" justifyContent="flex-start">
      <CellInnerContainer padding={[24, 0]} width="100%">
        <Question>{row.question}</Question>
        <Stand>
          {Array.isArray(row.choices) ? row.choices.join(", ") : ""}
        </Stand>
        <Divider />
        <Stand>{row.category}</Stand>
      </CellInnerContainer>
    </CellContainer>
  );
}

const Question = styled.span`
  font-family: Lato;
  font-size: 14px;
  color: ${Palette.veryDarkBlueGrey};
`;

const Stand = styled.span`
  font-family: Lato;
  font-size: 14px;
  color: ${Palette.bluishGrey};
`;

const Divider = styled.div`
  height: 1px;
  width: 42px;
  background-color: ${Palette.bluishGrey};
  margin-top: 8px;
  margin-bottom: 8px;
`;
