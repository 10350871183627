import { MergeTagResource } from "hyphen-lib/dist/domain/resource/MergeTagResource";

/**
 *  A higher order function which reduces the array into an object, with the value
 * of each key being a boolean value.
 *
 * @export
 * @template T
 * @param {T} disabled
 * @returns function
 */
export function getDisabledReducer<T>(disabled: T) {
  return function getDisabledFieldsOf(disabledFieldsArr: (keyof T)[]) {
    return disabledFieldsArr.reduce(
      function disabledReducer(disabledFields, field) {
        return {
          ...disabledFields,
          [field]: true,
        };
      }
      , disabled);
  };
}

export function getPulsePollMergeTags(): MergeTagResource[] {
  return [{
    _id: "1",
    _type: "MergeTag",
    key: "participant.firstName",
    description: "First name of the survey participant",
  }, {
    _id: "2",
    _type: "MergeTag",
    key: "participant.lastName",
    description: "Last name of the survey participant",
  }];
}
