import React, { useState } from "react";
import { SelectProps, SelectValue } from "antd/lib/select";
import Select from "@components/core/Select";
import { BaseFilterProps } from "@screens/Insights/components/FiltersContent";
import { Paragraph } from "@components/core/Typography";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import Button from "@components/core/Button";
import { isNullOrEmpty } from "@hyphen-lib/lang/Strings";
import { getProperty } from "hyphen-lib/dist/lang/Objects";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";
import { translate } from "@src/utils/i18next";
import Checkbox from "@src/components/core/Checkbox";
import Spin from "@src/components/core/Spin";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";

const { Option } = Select;
interface SelectOptionsAvailable {
  value: string | number;
  label: string;
}

interface MultiSelectFilterProps<T extends string[] | number[]> extends SelectProps<T>, BaseFilterProps<T> {
  id?: string;
  label: string;
  value?: T;
  isDisabled?: boolean;
  availableValues: SelectOptionsAvailable[];
  placeholder?: string;
  trans?: boolean;
  onChange: (values: T) => void;
  onConfirm?: () => void;
}

function preventMouseDown(e: any){
  e.preventDefault();
}

export function MultiSelectFilter<T extends string[] | number[]> (props: MultiSelectFilterProps<T>) {
  const { t } = useTranslation();
  const { onChange, label, value, placeholder = "Select...", isDisabled, trans, midget } = props;
  const [selectedValue] = useState<any>(value || []);
  const availableValues = props.availableValues.filter(each => !isNullOrEmpty(each.label));
  const isLoading = !isNotEmptyArray(availableValues);

  const translateLabel = (label: string) => trans ? translate(t, label) : label;

  const renderDropDown = (menu: any) => {
    const { onClear } = props;
    return (
      <>
        {menu}
        <Actions>
          <div onMouseDown={ midget ? preventMouseDown : () => {} }
           data-cy="surveyList_filter_clear">
            <Paragraph weight="bold" onClick={onClear} translate="yes">
              Clear
            </Paragraph>
          </div>
          <div onMouseDown={ midget ? preventMouseDown : () => {} }>
            <Button data-cy="surveyList_filter_select" color="blue" 
              onClick={props?.onConfirm} translate="yes">
              Select
            </Button>
          </div>
        </Actions>
      </>
    );
  };
  
  return (
    <StyledSelect
        disabled={isDisabled}
        label={!midget ? label : ""}
        placeholder={<Trans>{placeholder}</Trans>}
        onChange={(values: SelectValue) => onChange(values as T)}
        mode="multiple"
        value={(midget ? selectedValue : value) as string[] | number[]}
        dropdownRender={isLoading ? (menu) => <></> : renderDropDown}
        data-cy={"core_filters_dropdown" + 
          (typeof(label) === "string" ? label : getProperty(label, "props.children", label))}
        optionFilterProp={midget ? "value" : "children"}
        defaultOpen={midget}
        maxTagCount={midget ? 0 : 10}
        loading={isLoading}
        suffixIcon={isLoading && <Spin data-jest = {"loader"} />}
        maxTagPlaceholder={<>{selectedValue.length} <Trans>Selected</Trans> ...</>}
      >
        {
          availableValues.map((availableValue, idx) => (
            <Option
              data-cy={(typeof(label) === "string" ? label : getProperty(label, "props.children", label))
               + availableValue.value}
              key={availableValue.value.toString() + idx}
              value={availableValue.value}>
                { midget ? <Checkbox checked={selectedValue.indexOf(availableValue.value) >= 0}>
                  { translateLabel(availableValue.label) }
                </Checkbox> : translateLabel(availableValue.label) }
            </Option>
          ))
        }
    </StyledSelect>
  );
}

const Actions = styled.div`
  box-shadow: 0 -1px 0 0 ${Palette.lightPeriwinkle};
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  align-items: center;

  > div > p {
    margin-right: 16px;
    color: ${Palette.darkModerateBlue} !important;
    cursor: pointer;
  }

  button {
    height: 30px !important;
  }
`;

const StyledSelect = styled(Select)<SelectProps & {children: React.ReactNode}>``;
