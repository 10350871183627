import { ViewOptionDefinition } from "@screens/Insights/components/ViewOptions/components/ViewOptionsContent";
import { Seq } from "immutable";
import { CompareWith, CompareWithOption } from "@screens/Insights/components/ViewOptions/components/CompareWith";
import { Toggle } from "@screens/Insights/components/ViewOptions/components/Toggle";
import { applyDefault } from "@src/utils/Currier";
import { RadioViewOption } from "@screens/Insights/components/ViewOptions/components/RadioViewOption";
import { isEmpty } from "hyphen-lib/dist/lang/Arrays";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { getOr, isNotNullNorUndefined, mapAtIndexOr } from "hyphen-lib/dist/lang/Objects";

export function getViewOptionDefinitions(enabledViewOptions: string[],
  compareWithOptions: CompareWithOption[]): ViewOptionDefinition[] {

  return Seq(enabledViewOptions)
    .flatMap(enabledViewOption => mapToViewOptionDefinition(enabledViewOption, compareWithOptions))
    .toArray();
}

function mapToViewOptionDefinition(type: string,
  compareWithOptions: CompareWithOption[]): ViewOptionDefinition[] {
  switch (type) {
    case "driverImpact":
      return [{
        key: type,
        label: "Show Driver Impact",
        component: Toggle,
      }];
    case "questionCategory":
      return [{
        key: type,
        label: "Show question category",
        component: Toggle,
      }];
    case "comments":
      return [{
        key: type,
        label: "Show comments",
        component: Toggle,
      }];
    case "comparison":
      if (isEmpty(compareWithOptions)) {
        return [];
      }
      return [{
        key: type,
        label: "Show comparison",
        component: Toggle,
      }];
    case "scoreOrDelta":
      if (isEmpty(compareWithOptions)) {
        return [];
      }
      return [{
        key: type,
        label: "View data as",
        component: applyDefault(
          RadioViewOption,
          {
            options: [
              {
                key: "score",
                label: "Absolute value",
              },
              {
                key: "delta",
                label: "Delta vs comparison",
              },
            ],
          }
        ),
      }];
    case "compareWith":
      if (isEmpty(compareWithOptions)) {
        return [];
      }
      return [{
        key: type,
        label: "Compare with:",
        component: applyDefault(
          CompareWith,
          {
            options: compareWithOptions,
          }
        ),
      }];
  }

  return [];
}

export const mapViewOptionsToState = (viewOptions: Dictionary<any>,
  compareWithOptions: CompareWithOption[],
  defaultValues: Dictionary<boolean>) => {
  const compareWithMode = getOr(
    extractViewOptionsKeyFromLabel(viewOptions.compareWith, compareWithOptions),
    mapAtIndexOr(compareWithOptions, 0, o => o.key, null) as string
  );

  return {
    viewOptions: {
      comparison:
        isNotNullNorUndefined(compareWithMode) &&
        getOr(viewOptions.comparison, defaultValues.comparison),
      compareWith: compareWithMode,
    },
  };
};

export const extractViewOptionsKeyFromLabel = (compareWith: string, compareWithOptions: CompareWithOption[]) =>  {
  let viewOptionsKey = compareWith;
  compareWithOptions.forEach(compareOption => {
    if (compareOption.type === "multiple") {
      const optionsWithinCompareOption = compareOption.options.filter(o => o.label === compareWith);
      if (optionsWithinCompareOption.length > 0) {
        viewOptionsKey = optionsWithinCompareOption[0].key;
      } 
    }
  });
  return viewOptionsKey;
};
