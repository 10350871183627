import React from "react";
import { DatePicker as AntDatePicker } from "antd";
import styled from "styled-components";
import { DatePickerProps, RangePickerProps } from "antd/lib/date-picker/interface";
import "antd/lib/date-picker/style/index.css";
import Palette from "@src/config/theme/palette";
import hoistNonReactStatics from "hoist-non-react-statics";

const AntRangePicker = AntDatePicker.RangePicker;

const pickerStyles = `
  font-family: Lato !important;
  position: relative !important;

  .ant-calendar-picker-container,
  .ant-calendar-table {
    font-family: Lato !important;
  }

  .ant-calendar-picker-container {
    top: 0 !important;
  }

  .ant-calendar-header {
    font-size: 16px !important;
  }

  .ant-calendar-table th {
    font-size: 12px !important;
    color: ${Palette.bluishGrey};
  }

  .ant-calendar-table td {
    border: 1px solid ${Palette.lightPeriwinkle};
  }

  .ant-calendar-cell {
    padding: 0;
  }

  .ant-calendar-date {
    line-height: 30px;
    border-radius: 0;
    width: initial;
    height: initial;
  }

  .ant-calendar-date:hover,
  .ant-calendar-date:active {
    color: ${Palette.veryDarkBlueGrey};
    background: #C6D0E7;
  }

  .ant-calendar-selected-date .ant-calendar-date,
  .ant-calendar-selected-start-date .ant-calendar-date,
  .ant-calendar-selected-end-date .ant-calendar-date {
    background: ${Palette.bluePurple} !important;
    color: ${Palette.white} !important;
  }

  .ant-calendar-range .ant-calendar-in-range-cell:before {
    background: ${Palette.bluePurple_10};
    top: 0;
    bottom: 0;
  }

  .ant-calendar-today .ant-calendar-date {
    border-color: ${Palette.bluePurple};
    color: ${Palette.bluePurple};
  }

  .ant-calendar-header a {
    color: ${Palette.veryDarkBlueGrey};
  }

  .ant-calendar-header a:hover,
  .ant-calendar-footer a {
    color: ${Palette.bluePurple};
  }
}
`;

const StyledDatePicker = styled(AntDatePicker)<DatePickerProps>`${pickerStyles}`;
const StyledRangePicker = styled(AntRangePicker)<RangePickerProps>`${pickerStyles}`;

const getCalendarContainer = (trigger: Element): HTMLElement => trigger as HTMLElement;

const CustomDatePicker = (props: DatePickerProps) => {
  return <StyledDatePicker {...props} getCalendarContainer={getCalendarContainer}/>;
};

const CustomRangePicker = (props: RangePickerProps) => {
  return <StyledRangePicker {...props} getCalendarContainer={getCalendarContainer}/>;
};

const DatePicker = hoistNonReactStatics(CustomDatePicker as React.ComponentType<DatePickerProps>, AntDatePicker);
export const RangePicker = hoistNonReactStatics(
  CustomRangePicker as React.ComponentType<RangePickerProps>, AntDatePicker
);

export default DatePicker;