import React from "react";
import styled from "styled-components";

import { InputProps } from "antd/lib/input";
import { Row, Col } from "antd";

import Input from "@components/core/Input";
import Button from "@components/core/Button";
import ContainerCard from "@components/core/ContainerCard";
import AreYouSureModal from "@components/core/AreYouSureModal";

import { NetworkEventSuccessAction } from "src/store/network/actions";

import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { isNotNullNorUndefined, getOr } from "hyphen-lib/dist/lang/Objects";

import { v4 as uuidV4 } from "uuid";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Dimension } from "../../types";
import { UpdateDimensionsRequestAction } from "../../store/actions";
import { DimensionsTable } from "../../components/DimensionsTable";
import { isValidDimensionKey, isValidDimensionLabel, generateDimensionKey, countDuplicates } from "./utils";
import { Trans } from "react-i18next";

interface AddEditDimensionsProps {
  dimensions: Dictionary<string>;
  companyName: string;
  mode: "add" | "edit";
}

interface AddEditDimensionsActionProps {
  readonly updateCompanyDimensions: (
    companyName: string,
    dimensions: UpdateDimensionsRequestAction["payload"],
    onUpdateDimensionsSuccess: (payload: NetworkEventSuccessAction["payload"]) => void
  ) => void;
  readonly changedAfterLastUpdate: (payload: boolean) => void;
  readonly showSuccessNotification: (title: string, description: string, duration: number) => void;
  readonly showErrorNotification: (title: string, description: string, duration: number) => void;
  readonly onSave: () => void;
}

interface AddEditDimensionsState {
  isConfirmationModalOpen: boolean;
  dimensionInputValue: string;
  displayDimensionsTable: boolean;
  areDimensionsValid: boolean;
  dimensionsTableDataSource: Dimension[];
  selectedDimensionIndex: number | null;
}
type Props = AddEditDimensionsProps & AddEditDimensionsActionProps;
/* eslint-disable max-len */
export const InvalidDimensionName = "Valid dimension names must satisfy: 1) length between 3-30 alphabetical characters. 2) minimum one alphabetical character. 3) no special characters except spaces, underscores, parenthesis and hyphens. 4) should not be a keyword like 'language'";

export class AddEditDimensions extends React.Component<Props, AddEditDimensionsState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isConfirmationModalOpen: false,
      displayDimensionsTable: true,
      areDimensionsValid: true,
      dimensionsTableDataSource: [],
      dimensionInputValue: "",
      selectedDimensionIndex: null,
    };
  }
  componentDidMount() {
    this.formatDimensions();
  }

  formatDimensions = () => {
    const { dimensions } = this.props;
    const dimensionsTableDataSource: Dimension[] = [];

    Object.keys(dimensions).forEach(key =>
      dimensionsTableDataSource.push(
        {
          dimKey: key,
          name: dimensions[key],
          isEditable: false,
          id: uuidV4(),
        }
      )
    );

    this.setState({
      dimensionsTableDataSource,
    });
  };

  showDimensionsTable = () => {
    this.setState({
      displayDimensionsTable: true,
    });
  };

  closeConfirmationModal = () => {
    this.setState({
      isConfirmationModalOpen: false,
      selectedDimensionIndex: null,
    });
  };

  onInputChange = (e: any) => {
    this.setState({
      dimensionInputValue: e.target.value,
    });
  };

  editDimensionName = (value: string, index: number, record: any) => {
    const { dimensionsTableDataSource } = this.state;
    dimensionsTableDataSource[index].name = value;
    this.setState({
      dimensionsTableDataSource,
    });
  };

  onDoneEditingDimensionName = (value: string) => {
    if (isValidDimensionLabel(value)) {
      const { areDimensionsValid } = this.validateDimensions();
      return this.setState({ areDimensionsValid }, () => this.props.changedAfterLastUpdate(true));
    }
    this.showInvalidDimensionNameError();
  };

  editDimensionKey = (value: string, index: number, record: any) => {
    const { dimensionsTableDataSource } = this.state;
    if (dimensionsTableDataSource[index].isEditable) {
      dimensionsTableDataSource[index].dimKey = generateDimensionKey(value);
      this.setState({
        dimensionsTableDataSource,
      });
      this.props.changedAfterLastUpdate(true);
    } else {
      this.props.showErrorNotification(
        "Can't edit dimension key",
        "Keys that are already set cannot be edited again",
        4.5
      );
    }
  };

  onRemoveDimensionClick = (index: number) => {
    const { dimensionsTableDataSource } = this.state;
    if (!dimensionsTableDataSource[index].isEditable) {
      this.setState({
        isConfirmationModalOpen: true,
        selectedDimensionIndex: index,
      });
    }else {
      this.removeDimension(index);
    }
  };

  removeDimension = (index?: number) => {
    const { dimensionsTableDataSource, selectedDimensionIndex } = this.state;

    const dimensionIndex = isNotNullNorUndefined(selectedDimensionIndex) ? selectedDimensionIndex : index;

    if (isNotNullNorUndefined(dimensionIndex)) {
      dimensionsTableDataSource.splice(dimensionIndex, 1);
      this.setState({
        dimensionsTableDataSource,
        isConfirmationModalOpen: false,
        selectedDimensionIndex: null,
      });
      this.props.changedAfterLastUpdate(true);
    }
  };

  isDuplicateDimensionKey = (dimKey: string) => {
    const dimKeys = this.extractDimensionKeys();
    return dimKeys.includes(dimKey);
  };

  addDimension = () => {
    const { dimensionInputValue } = this.state;
    const dimensionKey = generateDimensionKey(dimensionInputValue);
    const { dimensionsTableDataSource } = this.state;
    if (not(isValidDimensionLabel(dimensionInputValue))) {
      return this.showInvalidDimensionNameError();
    }
    if (this.isDuplicateDimensionKey(dimensionKey)) {
      return this.showDuplicateDimensionKeyError(undefined);
    }

    dimensionsTableDataSource.push({
      dimKey: dimensionKey,
      name: dimensionInputValue,
      isEditable: true,
      id: uuidV4(),
    });
    this.setState({
      dimensionsTableDataSource,
      dimensionInputValue: "",
      displayDimensionsTable: true,
      areDimensionsValid: true,
    }, () => this.props.changedAfterLastUpdate(true));
  };

  showDuplicateDimensionKeyError = (message: Optional<string>, duration = 4.5) => {
    const defaultMessage = "The dimension label does not generate a unique key. Please enter a different label" ;
    const errorMessage = getOr(message, defaultMessage);
    return  this.setState({ areDimensionsValid: false }, () => this.props.showErrorNotification(
      "Dimension keys invalid",
      errorMessage,
      duration
    ));
  };

  showInvalidDimensionNameError = () => {
    this.setState({ areDimensionsValid: false }, () => this.props.showErrorNotification(
      "Dimension name not valid",
      InvalidDimensionName,
      4.5
    ));
  };

  areDimensionLabelsValid = () => {
    return this.state.dimensionsTableDataSource.every((dimension: Dimension) => {
      return isValidDimensionLabel(dimension.name);
    });
  };

  extractDimensionKeys = () => {
    const dimensionKeys: string[] = [];
    this.state.dimensionsTableDataSource.forEach((dimension: Dimension) => {
      dimensionKeys.push(dimension.dimKey);
    });
    return dimensionKeys;
  };

  validateDimensionKeys = () => {
    const dimensionKeys = this.extractDimensionKeys();
    const areAllDimensionKeysValid = dimensionKeys.every(isValidDimensionKey);
    return areAllDimensionKeysValid && not(countDuplicates(dimensionKeys) > 0);
  };

  validateDimensionLabels = () => {
    const { dimensionsTableDataSource } = this.state;
    let areDimensionsValid = false;
    if (dimensionsTableDataSource.length > 0) {
      areDimensionsValid = this.areDimensionLabelsValid();
    } else {
      areDimensionsValid = true;
    }
    return areDimensionsValid;
  };

  validateDimensions = () => {
    const areDimensionKeysValid = this.validateDimensionKeys();
    const areDimensionsValid = this.validateDimensionLabels();
    return { areDimensionsValid, areDimensionKeysValid };
  };

  followUpOnValidation = (
    areDimensionKeysValid: boolean,
    areDimensionNamesValid: boolean,
    shouldUpdateIfValid = true
  ) => {
    if (!areDimensionKeysValid) {
      /* eslint-disable max-len */
      const errorMessage = "Your dimension keys are not valid. Keys are generated from the values given. They must satisfy the following: 1) each of them must be unique. 2) minimum of 3 chars and max of 30 chars. 3) must have minimum one alphabet. 4) no special characters except space, hyphen and underscore. Please check the dimension values to see whether the keys generated by them are valid.";
      return this.showDuplicateDimensionKeyError(errorMessage, 30);
    }
    if (!areDimensionNamesValid) {
      this.showInvalidDimensionNameError();
    }
    if (areDimensionNamesValid && areDimensionKeysValid && shouldUpdateIfValid) {
      return this.setState({
        areDimensionsValid: true,
      }, () => {
        this.updateDimensions();
      });
    }
    if (areDimensionNamesValid && areDimensionKeysValid) {
      this.props.changedAfterLastUpdate(true);
    }
  };

  onUpdateDimensionsSuccess = (payload: NetworkEventSuccessAction["payload"]) => {
    const { onSave, showSuccessNotification } = this.props;
    const { dimensionsTableDataSource } = this.state;
    if (payload) {
      showSuccessNotification(
        "Dimensions updated successfully",
        "",
        4.5
      );
      dimensionsTableDataSource.forEach((dimension: Dimension) => {
        dimension.isEditable = false;
      });

      this.setState({
        dimensionsTableDataSource,
      });
    }
    onSave();
  };

  updateDimensions = () => {
    const { areDimensionsValid, dimensionsTableDataSource } = this.state;
    const { companyName } = this.props;

    if (areDimensionsValid) {
      const dimensions : Dictionary<string> = {};
      dimensionsTableDataSource.forEach((dimension: Dimension) => {
        dimensions[dimension.dimKey] = dimension.name;
      });
      this.props.updateCompanyDimensions(companyName, dimensions, this.onUpdateDimensionsSuccess);
    }
  };

  renderDescription = () => {
    const { mode } = this.props;

    if (mode === "add") {
      return (
        <p>
          <Trans>You have not yet defined employee dimensions.
          They are the fields that constitute the user's demographic information that you will
          use to define smart audiences and segment results.</Trans>
          <Trans>The most common custom dimensions are:</Trans>
          <i> location</i>,
          <i> department</i>,
          <i> gender</i>, and
          <i> tenure</i>.
          <Trans>Read more about dimensions here.</Trans>
        </p>
      );
    } else if (mode === "edit") {
      return (
        <p>
          <Trans>These dimensions are the fields that constitute the user's demographic information that you will
          use to define smart audiences and segment results.</Trans>
          <Trans>The most common custom dimensions are:</Trans>
          <i> location</i>,
          <i> department</i>,
          <i> gender</i>, and
          <i> tenure</i>.
          <Trans>Read more about dimensions here.</Trans>
        </p>
      );
    }
  };

  validateDimensionsAndFollowup = (e: any, shouldUpdateIfValid = true) => {
    const { areDimensionsValid, areDimensionKeysValid } = this.validateDimensions();
    this.followUpOnValidation(areDimensionKeysValid, areDimensionsValid, shouldUpdateIfValid);
  };

  render() {
    const {
      isConfirmationModalOpen,
      displayDimensionsTable,
      dimensionsTableDataSource,
      dimensionInputValue,
      selectedDimensionIndex,
      areDimensionsValid,
    } = this.state;
    const saveButtonColor = areDimensionsValid ? "blue" : "grey";
    const { mode } = this.props;

    return (
      <>
        <DescriptionCard>
          <h2>
            <Trans>Set employee dimensions</Trans>
          </h2>
          <Row type="flex">
            {this.renderDescription()}
            <p>
            <Trans>The following are already available by default:</Trans> Email/ID, First Name, Last Name,
            Role, Status, Engage Insights Access, Mobile Number, Locale, Manager, Manager Email, Manager (Direct reports only), Hire Date,
            Termination Date.
            </p>
            {!displayDimensionsTable &&
              <div>
                <Trans>Type your first dimension in the field below to add it to Insights</Trans>
              </div>
            }
          </Row>
        </DescriptionCard>

        {displayDimensionsTable &&
          <DimensionsTable
            dataSource={dimensionsTableDataSource}
            editDimensionName={this.editDimensionName}
            editDimensionKey={this.editDimensionKey}
            removeDimension={this.onRemoveDimensionClick}
            onDoneEditingDimensionName={this.onDoneEditingDimensionName}
          />
        }
        <AddDimensionCard>
          <Row type="flex">
            <Col span={24}>
              <DimensionInput
                size="large"
                onChange={this.onInputChange}
                value={dimensionInputValue}
                minLength={3}
                maxLength={30}
                data-jest="addDimensionInput"
              />
              <AddDimensionButton onClick={this.addDimension} data-jest="addButton" translate="yes">
                Add
              </AddDimensionButton>
            </Col>
          </Row>
        </AddDimensionCard>

        <ActionContainer>
          <Button
            color={saveButtonColor}
            onClick={this.validateDimensionsAndFollowup}
            disabled={!areDimensionsValid}
            data-jest="saveButton"
            translate="yes"
          >
            {mode === "add" ? "Save & proceed" : "Save"}
          </Button>
        </ActionContainer>

        {
          isConfirmationModalOpen && isNotNullNorUndefined(selectedDimensionIndex) &&
          <AreYouSureModal
            visible={isConfirmationModalOpen}
            title="Are you sure?"
            description="Are you sure you want to remove this dimension? This action cannot be undone"
            onOk={this.removeDimension}
            onCancel={this.closeConfirmationModal}
            okLabel="Delete"
          />
        }
      </>
    );
  }
}

const DimensionInput = styled(Input)<InputProps>`
  width: 250px;
  max-width: 300px;
  display: inline-block;
`;

const AddDimensionButton = styled(Button)`
  font-size: 16px !important;
`;

const DescriptionCard = styled(ContainerCard)`
  padding-bottom: 0px;
`;

const AddDimensionCard = styled(ContainerCard)`
  // padding-top: 0px;
`;

const ActionContainer = styled.div`
  display: flex;
  margin-top: 32px;
  justify-content: flex-end;
`;

export default AddEditDimensions;
