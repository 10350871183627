// @ts-nocheck
import React from "react";
export default function Left() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="10"
      viewBox="0 0 6 10"
    >
      <path
        fill="#AAB9CE"
        fillRule="evenodd"
        // eslint-disable-next-line max-len
        d="M5.143 10a.867.867 0 0 1-.606-.244L.25 5.589a.816.816 0 0 1 0-1.178L4.537.244a.873.873 0 0 1 1.212 0 .816.816 0 0 1 0 1.179L2.069 5l3.68 3.578a.816.816 0 0 1 0 1.178.867.867 0 0 1-.606.244"
      />
    </svg>
  );
}
