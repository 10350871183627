import React, { FC } from "react";
import { Trans } from "react-i18next";
import { Heading } from "@src/components/core/Typography";
import { TrendLineChart, TrendLineChartData } from "@src/components/core/TrendLineChart";
import { TrendResource } from "hyphen-lib/dist/domain/resource/survey/report/TrendResource";
import toolTipOptions from "@src/screens/Insights/Survey/components/ToolTip";
import { Summary } from "../..";
import { generateTrendLineData, getMonthsToDisplay,labelFormatter } from "../../../../../../trendLineUtils";

interface SurveyTrendLineStateProps {
  trend: TrendResource.SurveyTrends[];
}

interface SurveyTrendLineProps {
  currentSurveyId: string;
}

type ComponentProps = SurveyTrendLineStateProps & SurveyTrendLineProps;

const SurveyTrendlines: FC<ComponentProps> = (props) => {
  const { trend, currentSurveyId } = props;
  const trendData: TrendLineChartData[] = generateTrendLineData(trend, currentSurveyId);
  const minMonths = getMonthsToDisplay(trendData.length);
  if (trendData.length < 2) {
    return null;
  }
  return (
    <>
      <Heading size="small" weight={"bold"} data-jest="linked-surveys-heading" translate="yes">
        Linked Surveys Trend
      </Heading>
      <Summary data-cy="">
        <Trans>Favorability score across linked surveys</Trans>
      </Summary>
      <br />
      <TrendLineChart
        minTicks={minMonths}
        data={trendData}
        toolTipOptions={toolTipOptions}
        xAxisLabelsOptions={{rotation: -45}}
        yAxis={{labelFormatter}}
      />
      <Summary data-cy="" centered>
        <Trans>Data points with not enough data are not displayed in the graph to respect anonymity rules.</Trans>
      </Summary>
    </>
  );
};

export default SurveyTrendlines;
