import React from "react";
import { withRouter } from "react-router-dom";

import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import {
  getOr,
  mapOr,
  isNotNullNorUndefined,
  isNotEmptyObject
} from "hyphen-lib/dist/lang/Objects";
import { parseNumber } from "hyphen-lib/dist/lang/Number";

import { formatSort } from "@src/utils/helper";
import { applyExistingParametersIfNeeded } from "@src/utils/parameters";
import { replaceLocation } from "@src/utils/locations";

import { not } from "hyphen-lib/dist/lang/Booleans";
import { DataListProps, FetchDataListParameters, RenderPropsForDataList } from "./types";

export class DataList extends React.Component<DataListProps> {
  componentDidMount() {
    const {
      parameters,
      paramMappings = [],
      location: { search },
    } = this.props;
    // we might need to apply persisted parameters
    const existing = parseQueryString(search);
    const mergedParameters = applyExistingParametersIfNeeded(
      parameters.toJS(),
      existing,
      ...paramMappings
    );

    if (
      isNotNullNorUndefined(mergedParameters) &&
      isNotEmptyObject(mergedParameters)
    ) {
      replaceLocation(mergedParameters);
    } else {
      // fetch the data only if we will stay on this page,
      // otherwise it will be fetched anyway in componentDidUpdate
      this.fetchIfNeeded();
    }
  }

  componentDidUpdate() {
    if (this.props.isNotFound && not(this.props.isInError)) {
      this.fetchIfNeeded();
    }
  }

  getPageParams(): FetchDataListParameters {
    const {
      pageSize,
      location: { search },
    } = this.props;
    const queryParameters = parseQueryString(search);
    const filter = getOr(queryParameters.filter, {});
    const sort = getOr(formatSort(queryParameters.sort), {});
    const page = {
      size: pageSize,
      number: mapOr(queryParameters.page, parseNumber, 1),
    };
    return {
      filter,
      page,
      sort,
    };
  }

  fetchIfNeeded() {
    const { onFetchIfNeeded } = this.props;
    const pageParams = this.getPageParams();
    onFetchIfNeeded(pageParams);
  }

  render() {
    const { children } = this.props;

    // children as RenderPropType because a union type can't be a function or a different type
    // https://github.com/microsoft/TypeScript/issues/4612#issuecomment-387778600
    return typeof children === "function"
      ? (children as RenderPropsForDataList)({ pageParams: this.getPageParams() })
      : children;
  }
}

export const DataListContainer = withRouter(DataList);
