import { AccessResource } from "hyphen-lib/dist/domain/access/AccessResource";
import { RecordOf, Record } from "immutable";
import {
  PulsePollReportsFiltersStateProps,
  pulsePollReportsFilterReducers,
  PulsePollReportsFiltersStateFactory
} from "../containers/PulsePollReportsFilterContainer/store/reducers";
import { 
  PulsePollCommentsReportStateProps, 
  pulsePollCommentsReportStateFactory, 
  pulsePollCommentsReportReducers
} from "./../containers/PulsePollsComments/store/reducer";
import { PollReportActionTypes } from "./actions";

export interface PulsePollStateProps {
  readonly reportsFilters: RecordOf<PulsePollReportsFiltersStateProps>;
  readonly comments: RecordOf<PulsePollCommentsReportStateProps>;
  readonly pulsePollAccessesResource: RecordOf<AccessResource>;
}

export type PulsePollState = RecordOf<PulsePollStateProps>;

const pulsePollAccessesResourceFactory = Record<AccessResource>({} as AccessResource);

const pulsePollDefaultValues: PulsePollStateProps = {
  reportsFilters: PulsePollReportsFiltersStateFactory(),
  comments: pulsePollCommentsReportStateFactory(),
  pulsePollAccessesResource: pulsePollAccessesResourceFactory(),
};

export const pulsePollStateFactory = Record<PulsePollStateProps>(
  pulsePollDefaultValues
);

const defaultState = pulsePollStateFactory();
const reportsFilterFactory = PulsePollReportsFiltersStateFactory();

export function pulsePollAccessesResourceReducers(
  state: PulsePollState = defaultState,
  action: any
): PulsePollState {
  switch (action.type) {
    case PollReportActionTypes.FETCH_POLL_ACCESSES_SUCCESS:
      return state.setIn(["pulsePollAccessesResource"], action.payload.data);
    default:
      return state;
  }
}

export function pulsePollReducer(
  state: PulsePollState = defaultState,
  action: any
) {
  switch (action.type){
    case PollReportActionTypes.FETCH_POLL_ACCESSES_SUCCESS:
      return state.setIn(["pulsePollAccessesResource"], action.payload.data);
    default:
      return state.merge({
        reportsFilters: pulsePollReportsFilterReducers(
          reportsFilterFactory,
          action
        ),
        comments: pulsePollCommentsReportReducers(
          state.get("comments"),
          action
        ),
        pulsePollAccessesResource: state.pulsePollAccessesResource,
      });
  }
}
