import React from "react";
import styled from "styled-components";
import { Icon } from "antd";

import { CellContainer, CellInnerContainer } from "@screens/Insights/SurveyList/components/cells/Cells";
import { FavorabilityScore } from "@components/core/FavorabilityScore";
import { StackedBarGraph } from "@components/core/StackedBarGraph";
import Palette, {
  getBarThemeForFavorability,
  getBarThemeForMultipleChoice,
  getBarThemeForEnps,
  BarOptions
} from "@src/config/theme/palette";
import { QuestionType } from "@hyphen-lib/domain/common/QuestionType";
import { getOr, isNotNullNorUndefined, isNullOrUndefined } from "@hyphen-lib/lang/Objects";
import { DistributionResult } from "hyphen-lib/dist/domain/resource/report/common/DistributionResult";
import { Breadcrumb, goTo } from "@src/utils/locations";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { adjustElementsAndPercentages } from "@src/utils/Graphs";
import { ScoreType } from "hyphen-lib/dist/domain/resource/report/common/Score";
import {SentimentWithComments} from "@screens/Insights/components/SentimentWithComments";
import { Trans } from "react-i18next";

/*
  fixme:
    this is weird to manage the on hover here, this is not supposed to be like this.
    So this is leading us to give the tableName parameter.
    FavorabilityCell, should just be about displaying the distribution and the fav!
 */

interface FavorabilitySentimentCellProps {
  tableName: string;
  row: {
    type: QuestionType;
    favorability?: Optional<number>;
    sentimentScore?: Optional<number>;
    distribution?: DistributionResult;
    numberOfVotes?: number;
    readonly choiceLabels?: Optional<string>[];
    _id?: string;
    isMultiSelect?: boolean;
    eNPS?: Optional<number>;
  };
  hover?: boolean;
  isFromCategory?: boolean;
}

export function FavorabilitySentimentCell(
  {
    row: { type, favorability, sentimentScore, distribution, _id, choiceLabels, isMultiSelect, eNPS, numberOfVotes },
    hover,
    tableName,
    isFromCategory,
  }: FavorabilitySentimentCellProps
) {
  const renderChoicesGraph = (
    questionType: QuestionType,
    distributionResult: DistributionResult,
    availableLabels?: Optional<string>[]
  ) => {
    let colorMap: BarOptions[] = [];
    let choicesData = distributionResult;

    if (questionType === QuestionType.LIKERT) {
      colorMap = getBarThemeForFavorability(choicesData.length, getOr(availableLabels, []));
    } else if (questionType === QuestionType.ENPS) {
      choicesData = distributionResult.slice().reverse();
      colorMap = getBarThemeForEnps();
    } else {
      colorMap = getBarThemeForMultipleChoice(
        getOr(availableLabels, []),
        isMultiSelect
      );
    }

    const { pieceToAdd } = adjustElementsAndPercentages(choicesData);
    const data = choicesData
      .map(({ total, percentage }, index) => {
        const { backgroundColor, fontColor, label } = colorMap[index];
        return ({
          value: total,
          percentage: getOr(percentage, 0),
          percentageWidth: getOr(percentage, 0) > 0 ? percentage! + pieceToAdd : 0,
          backgroundColor,
          fontColor,
          label,
        });
      });

    return <StackedBarGraph hideSingleDigitLabel data={data} numberOfVotes={numberOfVotes}/>;
  };

  function onShowDetail() {
    goTo(`${isFromCategory ? "../" : ""}questions/${_id}`, Breadcrumb.stack(tableName));
  }

  function getScore() {
    if (isNotNullNorUndefined(eNPS)) {
      return { score: eNPS, type: ScoreType.NPS };
    }
    if (type === QuestionType.LIKERT && isNotNullNorUndefined(favorability)) {
      return { score: favorability, type: ScoreType.FAVORABILITY };
    }
  }

  const score = getScore();

  return (
    <CellContainer align="center" justifyContent="flex-start">
      <CellInnerContainer padding={[24, 0]} width="100%">
        <FavorabilitySentimentContainer>

          <FavorabilityScoreContainer>
            {isNotNullNorUndefined(score) && (
              <FavorabilityScore
                data-cy="pollScore"
                favorability={score.score}
                scoreType={score.type}
                percentage = {type === QuestionType.ENPS ? false : true}/>
            )}
          </FavorabilityScoreContainer>
          {
            type !== QuestionType.OPEN_TEXT &&
            !isNullOrUndefined(distribution) &&
            renderChoicesGraph(type, distribution, choiceLabels)
          }
          {
            type === QuestionType.OPEN_TEXT && !isNullOrUndefined(sentimentScore) && (
              <SentimentWithComments
                hasComments={false}
                netSentimentScore={sentimentScore}
              />
            )
          }
          {hover ?
            (
              <StyledLink onClick={onShowDetail}>
                <SeeDetails><Trans>See details</Trans> <RightIcon type="right" /></SeeDetails>
              </StyledLink>
            ) : null
          }
        </FavorabilitySentimentContainer>
      </CellInnerContainer>
    </CellContainer>
  );
}

const StyledLink = styled.span`
  cursor: pointer;
`;
const FavorabilitySentimentContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SeeDetails = styled.div`
  color: ${Palette.darkModerateBlue};
  font-size: 14px;
  text-align: right;
  cursor: pointer;
  line-height: 30px;
`;

const RightIcon = styled(Icon)`
  font-size: 12px;
`;

const FavorabilityScoreContainer = styled.div`
  margin-right: 16px;
  line-height: 30px;
  width: 30px;
`;
