import React from "react";
import Palette from "@src/config/theme/palette";
import styled from "styled-components";

export interface CustomizationPopoverProps extends React.HTMLProps<HTMLDivElement> {
  children?: React.ReactNode;
  open: boolean;
  noPadding?: boolean; 
}

export function CustomizationPopover({
  children,
  open,
  noPadding = false
}: CustomizationPopoverProps) {
  return open ? (
    <ExpandedHeaderContainer noPadding={noPadding}>
      <ExpandedHeaderContent noPadding={noPadding}>
        {children}
      </ExpandedHeaderContent>
    </ExpandedHeaderContainer>
  ) : null;
}

const ExpandedHeaderContainer = styled.div<{ noPadding: boolean }>`
  width: ${props => props.noPadding ? "fit-content" : "auto"};
  position: absolute;
  left: ${props => props.noPadding ? "auto" : "0"};
  right: 0;
  top: 100%;
  padding: ${props => props.noPadding ? "0" : "0 32"}px;
  background: ${Palette.white};
  border-radius: 0 0 3px 3px;
  z-index: 30;
  font-family: Lato,sans-serif;
  box-shadow: 0 10px 20px 0 rgba(7, 7, 7, 0.08);

  @media print {
    z-index: 0;
    margin-bottom: 30px;
  }
`;

const ExpandedHeaderContent = styled.div<{ noPadding: boolean }>`
  padding: ${props => props.noPadding ? "0" : "32px 0"}px;
  border-top: 1px solid ${Palette.paleGrey};
`;

ExpandedHeaderContainer.displayName = "ExpandedHeaderContent";
