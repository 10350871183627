import { Record, RecordOf, List, fromJS, merge } from "immutable";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { UsersAndDimensionsActionTypes, UserAndRole } from "./actions";

interface EmailResource extends Map<string, any>, UserAndRole {}

export interface UsersAndDimensionsStateProps {
  readonly uploadProgress: number;
  readonly uploadStatus: string;
  readonly fileName: string;
  readonly isNetworkRequesting: boolean;
  readonly emailsToBeAdded: List<EmailResource>;
  readonly emails: string[];
  readonly manualAddError: string;
  readonly duplicateEmails: string[];
  readonly usersAddedSuccessfully: boolean;
  readonly changedAfterLastUpdate: boolean;
}
export type UsersAndDimensionsState = RecordOf<UsersAndDimensionsStateProps>;

const defaultValues: UsersAndDimensionsStateProps = {
  uploadProgress: 0,
  uploadStatus: "INIT",
  fileName: "",
  isNetworkRequesting: false,
  emailsToBeAdded: List([]),
  duplicateEmails: [],
  emails: [],
  manualAddError: "",
  usersAddedSuccessfully: false,
  changedAfterLastUpdate: false,
};

export const usersAndDimensionsStateFactory = Record<UsersAndDimensionsStateProps>(defaultValues);
const defaultState = usersAndDimensionsStateFactory();

export function usersAndDimensionsReducers(state: UsersAndDimensionsState = defaultState,
  action: any): UsersAndDimensionsState {
  switch (action.type) {
    case UsersAndDimensionsActionTypes.CHANGED_AFTER_LAST_UPDATE:
      return state.set("changedAfterLastUpdate", action.payload);
    case UsersAndDimensionsActionTypes.UPDATE_DIMENSIONS_SUCCESS:
      return state.set("changedAfterLastUpdate", false);

    case UsersAndDimensionsActionTypes.UPLOAD_USERS_CSV_REQUEST:
    case UsersAndDimensionsActionTypes.UPLOAD_USERS_CSV_SUCCESS:
      return state.withMutations(map => {
        map
          .set("uploadStatus", action.type)
          .set("fileName", action.payload.fileName);
      });
    case UsersAndDimensionsActionTypes.UPLOAD_USERS_CSV_PROGRESS:
      return state.withMutations(map => {
        map
          .set("uploadStatus", action.type)
          .set("uploadProgress", action.payload.progress)
          .set("fileName", action.payload.fileName);
      });
    case UsersAndDimensionsActionTypes.ADD_USERS_MANUALLY_REQUEST:
      return state.set("isNetworkRequesting", true);
    case UsersAndDimensionsActionTypes.ADD_USERS_MANUALLY_ERROR:
      return state.set("isNetworkRequesting", false);
    case UsersAndDimensionsActionTypes.ADD_USERS_MANUALLY_SUCCESS: {
      const { data } = action.payload;
      const { dryRun } = action.meta;
      if (dryRun) {
        let emailsToBeAdded: List<EmailResource> = List([]);
        const duplicateEmails: string[] = [];
        Object.keys(data).forEach(email => {
          if (not(data[email])) {
            duplicateEmails.push(data[email]);
            return;
          }
          emailsToBeAdded = emailsToBeAdded.push(fromJS({
            email,
            role: "user",
          }));
        });
        emailsToBeAdded = merge(state.get("emailsToBeAdded"), emailsToBeAdded).toSet().toList();
        return state.withMutations(map => {
          return map
            .set("emailsToBeAdded", emailsToBeAdded)
            .set("duplicateEmails", duplicateEmails)
            .set("isNetworkRequesting", false);
        });
      } else {
        return state.withMutations(map => (
          map
            .set("usersAddedSuccessfully", true)
            .set("isNetworkRequesting", false)
        ));
      }
    }
    case UsersAndDimensionsActionTypes.SELECT_DESELECT_USERS: {
      const { select, deselect, deselectAll } = action.payload;
      const emails = [...state.get("emails")];
      if (isNotNullNorUndefined(select)) {
        const objToBeMerged = {} as { emails: string[]; manualAddError: string };
        if (isNotNullNorUndefined(select.email)) {
          emails.push(select.email);
          objToBeMerged.emails = emails;
        }
        if (isNotNullNorUndefined(select.error)) {
          objToBeMerged.manualAddError = select.error;
        }
        return state.merge(objToBeMerged);
      }
      if (isNotNullNorUndefined(deselect)) {
        const index = emails.indexOf(deselect.email);
        if (index > -1) {
          emails.splice(index, 1);
        }
        return state.set("emails", emails);
      }
      if (isNotNullNorUndefined(deselectAll)) {
        return state.merge({
          emails: [],
          manualAddError: "",
        });
      }
      return state;
    }
    case UsersAndDimensionsActionTypes.UPDATE_USER_ROLE: {
      const { index, role } = action.payload;
      return state.setIn(["emailsToBeAdded", index, "role"], role);
    }
    case UsersAndDimensionsActionTypes.REMOVE_USER: {
      const { index } = action.payload;
      return state.set("emailsToBeAdded", state.get("emailsToBeAdded").remove(index));
    }
    case UsersAndDimensionsActionTypes.CLEAN_ADD_USERS_MANUAL_STORE:
      return state.withMutations(map => {
        return map
          .set("usersAddedSuccessfully", false)
          .set("emailsToBeAdded", List([]))
          .set("duplicateEmails", []);
      });
    default:
      return state;
  }
}
