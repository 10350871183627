import { FSA } from "flux-standard-action";
import {
  ActionTypes as NetworkActionTypes,
  NetworkEventErrorAction,
  NetworkEventRequestAction,
  NetworkEventSuccessAction,
  NetworkRequestAction
} from "@store/network/actions";

import { createRequest } from "@src/utils/networks";
import { createSendSurveyRemindersRequest } from "@src/screens/Insights/SurveyList/store/actions";
import { SurveyInfoResource } from "hyphen-lib/dist/domain/resource/SurveyInfoResource";

import { NotificationChannels } from "hyphen-lib/dist/domain/NotificationChannels";
import { SurveyRemindersStateProps } from "./types";

export enum ActionTypes {
  FETCH_SURVEY_REQUEST = "reminders/FETCH_SURVEY_REQUEST",
  FETCH_SURVEY_SUCCESS = "reminders/FETCH_SURVEY_SUCCESS",
  FETCH_SURVEY_ERROR = "reminders/FETCH_SURVEY_ERROR",

  FETCH_SURVEY_REMINDERS_REQUEST = "reminders/FETCH_SURVEY_REMINDERS_REQUEST",
  FETCH_SURVEY_REMINDERS_SUCCESS = "reminders/FETCH_SURVEY_REMINDERS_SUCCESS",
  FETCH_SURVEY_REMINDERS_ERROR = "reminders/FETCH_SURVEY_REMINDERS_ERROR",

  TOGGLE_REMINDER_MODAL_OPEN = "reminders/TOGGLE_REMINDER_MODAL_OPEN",

  SEND_SURVEY_REMINDER = "reminders/SEND_SURVEY_REMINDER",
  SEND_SURVEY_REMINDERS_REQUEST = "reminders/SEND_SURVEY_REMINDERS_REQUEST",
  SEND_SURVEY_REMINDERS_SUCCESS = "reminders/SEND_SURVEY_REMINDERS_SUCCESS",
  SEND_SURVEY_REMINDERS_ERROR = "reminders/SEND_SURVEY_REMINDERS_ERROR",
}

export function getSurveyResource(surveyId: string) {
  return createRequest(`/surveys/${surveyId}`);
}

export const createSurveyRemindersRequest = (surveyId: string) =>
  createRequest(`/surveys/${surveyId}/notificationsInfo`);

interface Payloads {
  [ActionTypes.FETCH_SURVEY_REMINDERS_SUCCESS]: Partial<SurveyRemindersStateProps>;
  [ActionTypes.SEND_SURVEY_REMINDER]: NotificationChannels;
}

export interface UpdateQuestionsAction
  extends FSA<ActionTypes.FETCH_SURVEY_REMINDERS_SUCCESS, Payloads[ActionTypes.FETCH_SURVEY_REMINDERS_SUCCESS]> {
  type: ActionTypes.FETCH_SURVEY_REMINDERS_SUCCESS;
  payload: Payloads[ActionTypes.FETCH_SURVEY_REMINDERS_SUCCESS];
}
export interface SendSurveyReminderAction
  extends FSA<ActionTypes.SEND_SURVEY_REMINDER, Payloads[ActionTypes.SEND_SURVEY_REMINDER]> {
  type: ActionTypes.SEND_SURVEY_REMINDER;
  payload: Payloads[ActionTypes.SEND_SURVEY_REMINDER];
}

export const actionCreators = {
  fetchSurvey: (surveyId: string): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: getSurveyResource(surveyId),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => ({
          type: ActionTypes.FETCH_SURVEY_REQUEST,
          payload,
        }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({
          type: ActionTypes.FETCH_SURVEY_SUCCESS,
          payload,
        }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({
          type: ActionTypes.FETCH_SURVEY_ERROR,
          payload,
        }),
      ],
    },
  }),
  fetchSurveyReminders: (surveyId: string): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createSurveyRemindersRequest(surveyId),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) => ({
          type: ActionTypes.FETCH_SURVEY_REMINDERS_REQUEST,
          payload,
        }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) => ({
          type: ActionTypes.FETCH_SURVEY_REMINDERS_SUCCESS,
          payload,
        }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) => ({
          type: ActionTypes.FETCH_SURVEY_REMINDERS_ERROR,
          payload,
        }),
      ],
    },
  }),
  toggleReminderModalOpen: (reminderModalOpen: boolean, selectedSurveyInfoResource: SurveyInfoResource) => ({
    type: ActionTypes.TOGGLE_REMINDER_MODAL_OPEN,
    reminderModalOpen,
    selectedSurveyInfoResource,
  }),
  sendSurveyRemindersRequest: (
    surveyId: string,
    surveyReminderPayload: SendSurveyReminderAction["payload"],
    onSuccessRedirect?: (payload: NetworkEventSuccessAction["payload"]) => void
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createSendSurveyRemindersRequest(surveyId, surveyReminderPayload),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: ActionTypes.SEND_SURVEY_REMINDERS_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: ActionTypes.SEND_SURVEY_REMINDERS_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: ActionTypes.SEND_SURVEY_REMINDERS_ERROR, payload }),
      ],
      onSuccessRedirect,
    },
  }),
};
