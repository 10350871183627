import { DimensionsDictionary } from "hyphen-lib/dist/domain/common/Dimensions";
import { isStringAndNotEmpty } from "@hyphen-lib/lang/Strings";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import {
  LifeCycleDashboardResource
} from "hyphen-lib/dist/domain/resource/report/LifeCycleResource";
import { fromJS } from "immutable";
import { isEmptyObject, isNotNullNorUndefined, isNullOrUndefined } from "hyphen-lib/dist/lang/Objects";
import { LifeCycleSettings } from "hyphen-lib/dist/domain/common/LifeCycleSettingsConfig";
import Palette from "@src/config/theme/palette";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { Breadcrumb, goTo } from "@src/utils/locations";
import { appendQueryString, generateQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { isEmpty } from "hyphen-lib/dist/lang/Arrays";
import { updateSeries } from "./series";
import { computePlotBands, BandColors, BANDCOLORS } from "./plotBands";
import { computePlotLine } from "./plotLine";

export interface PhaseSummariesForPlotBand {
  [name: string]: PhaseSummaryForPlotBand;
}

export interface UnsanitizedSeries {
  [index: number]: Optional<number>[];
}

export interface Coordinates {
  y: Optional<number>;
  x: number;
  name: string;
  key: string;
  isNull?: boolean;
  marker?: { fillColor?: string; enabled: boolean };
}

export type FavorabilityBySegment = Dictionary<Coordinates[]>;
export type SubPhaseTenuresWithMeta = LifeCycleSettings.SubPhase & {readonly hasNoData: boolean};
export interface PhaseSummaryForPlotBand {
  startTenure: number;
  endTenure: number;
  numberOfSubphases: number;
  isSeparation?: boolean;
  subPhaseTenures: SubPhaseTenuresWithMeta[];
}

// fixme: derive from other two or consider using PhaseSummariesForPlotBand item by item
export interface PhaseDetails {
  name: string;
  startTenure: number;
  endTenure: number | string;
  isSeparation?: boolean;
  // tenureBeforeTermination?: Optional<number>;
  numberOfSubphases: number;
  subPhaseTenures: SubPhaseTenuresWithMeta[];
}

export interface ScoreAndStatus {
  score: Optional<number>;
  status: FavorabilityStatus;
}

export enum FavorabilityStatus {
  FILTERED = "filtered",
  NO_DATA = "no-data",
  SCORE = "score",
}

export type PhaseFavorability = LifeCycleDashboardResource.PhaseFavorability;
export type SegmentVotes = [string, number];
export const PLOT_BAND_WIDTH = 1;
export const NO_DATA_FILTERED_COLOR = "#D2691E";
export const YAXISOFFSET = -50;
export const SINGLE_SERIES_LINE_COLOR = "#738bc4";
export const MARKER_COLOR = "#3559ae";
export const TOOLTIP_BACKGROUND_COLOR = "#1c1e56";
export const TOOLTIP_HIGHLIGHTED_BACKGROUND_COLOR = "#6469d9";
export const MARKER_FILL_COLOR = Palette.aquaBlue;

export const color = new BandColors(BANDCOLORS.BAND_1_COLOR);
export const MAX_SEGMENTS_TO_DISPLAY = 10;
let seriesNamesString = "";

interface DimensionSegmentCouple {
  readonly dimension: string;
  readonly segment: string;
}

export function navigateToSegmentReport(mainSegment: DimensionSegmentCouple,
  implicitFilter?: Optional<DimensionSegmentCouple>): void {

  const baseUrl = `segments/${mainSegment.dimension}/${mainSegment.segment}/reports/overview`;
  let queryParameters: Dictionary<any> = { merge: "true" };
  if (isNotNullNorUndefined(implicitFilter)) {
    queryParameters = {
      ...queryParameters,
      filter: { dimensions: { [implicitFilter.dimension]: [implicitFilter.segment] } },
    };
  }
  const url = appendQueryString(baseUrl, generateQueryString(queryParameters));

  goTo(url, Breadcrumb.stack("Lifecycle Analysis"));
}

export function serializeSubphase(subPhaseName: string) {
  if (subPhaseName.includes("+")) {
    return subPhaseName.replace("+", "--1");
  }
  // Onboarding:%200-3%20months
  return subPhaseName;
}

function extractSubPhase(pointName: string): DimensionSegmentCouple {
  const subPhase = serializeSubphase(`${pointName.split("#")[1]}: ${pointName.split("#")[2]}`);
  return {
    dimension: pointName.includes("Separation") ? "dynamicSeparation" : "dynamicTenure",
    segment: subPhase,
  };
}

export const options: Highcharts.Options = {
  title: {
    text: undefined,
  },
  lang: {
    noData: "",
  },
  plotOptions: {
    series: {
      point: {
        events: {
          click(this: Highcharts.Point) {
            const subPhaseFilter = extractSubPhase(this.name);
            if (!this.series.name.includes("Not Specified")) {
              const [dimension, segment] = this.series.name.split(":");
              navigateToSegmentReport({ dimension, segment }, subPhaseFilter);
            } else {
              navigateToSegmentReport(subPhaseFilter);
            }
          },
        },
      },
    },
    line: {
      dataLabels: {
        enabled: true,
        y: -6,
        style: {
          fontSize: "14px",
        },
      },
    },
  },
  credits: {
    enabled: false,
  },
  chart: {
    type: "line",
    height: 650,
    marginTop: 220,
    spacingRight: 0,
    style: { fontFamily: "Lato" },
    events: {
      redraw(this: any) {
        let seriesNames = "";
        for (const series of this.series) {
          seriesNames = seriesNames.concat(series.getName());
        }

        const hasSegmentChanged = seriesNames !== seriesNamesString;

        if (hasSegmentChanged) {
          seriesNamesString = seriesNames;
          for (const series of this.series) {
            if (!series.visible) {
              series.setVisible(true);
            }
          }
        }
      },
      load(this: any) {
        // the below code is required for the legend to have circular symbols. Also see
        // updateSeries function for additional code related to getting circular symbols
        // reference: http://jsfiddle.net/d_paul/rpq4qkd9/5/
        for (const series of this.series) {
          if (series.type !== "column") {
            series.linkedTo = undefined;
            series.showInLegend = true;
          } else {
            series.showInLegend = false;
          }
        }
      },
    },
  },
  tooltip: {
    shared: true,
    useHTML: true,
    backgroundColor: TOOLTIP_BACKGROUND_COLOR,
    padding: 0,
    borderWidth: 0,
    borderRadius: 8,
    headerFormat: "", // Removes the botton value callout on hover
  },
  xAxis: [{
    type: "category",
    tickLength: 10,
    tickWidth: 2,
    tickPositioner(this: any) {
      const positions: number[] = [];
      if (this.hasData()) {
        const series = this.series[0];
        series.data.forEach((datum: any) => {
          if (!datum.isNull) {
            /* fixeme: the 0.5 is a weird hack, I know. But without that all the tick
              marks are off by the same amount from the x-coordinate of a point. */
            positions.push(datum.x - 0.5);
          }
        });
      }
      if (positions.length > 0) {
        this.update({ tickPositions: positions }, true);
      }
      return positions;
    },
    crosshair: {
      color: "black",
      dashStyle: "ShortDash",
      width: 1,
    },
    allowDecimals: false,
    min: 0,
    labels: {
      enabled: false,
    },
    plotBands: [],
  }],
  legend: {
    enabled: false,
    labelFormatter(this: Highcharts.Point | Highcharts.Series) {
      return this.name.replace("_", " ");
    },
  },
  yAxis: [{
    title: { text: undefined },
    offset: YAXISOFFSET,
    tickInterval: 10,
    labels: {
      formatter(this: Highcharts.AxisLabelsFormatterContextObject<number>) {
        if (this.value > 100) {
          return "";
        }
        return `${this.value}%`;
      },
      y: -5,
    },
    opposite: false,
    gridLineColor: "#E8EDF3",
    gridLineWidth: 2,
    gridZIndex: 2,
  }],
  series: [
    {
      type: "line",
      data: [1, 2, 1, 4, 3, 6],
      zIndex: 4,
      pointStart: 0,
      zoneAxis: "x",
      marker: {
        enabled: true,
        radius: 5,
        fillColor: MARKER_COLOR,
        states: {
          hover: {
            fillColor: MARKER_FILL_COLOR,
            radiusPlus: 6,
          },
        },
      },
    },
  ],
};

export interface SanitizedScores {
  [index: number]: (number | null)[];
}

export type SanitizedScoresForSegments = Dictionary<SanitizedScores>;
interface ChartOptionsBuildingElements {
  favorabilityByPhase: PhaseFavorability[];
  votesPerSegments: DimensionsDictionary<number>;
  initialOptions: Highcharts.Options;
  labelStatuses: Dictionary<Optional<boolean>>;
  plotWidth?: number;
  plotLeft?: number;
  segmentedBy?: string;

}

export function computeHalfMaxY(
  favorabilityByPhase: PhaseFavorability[],
  segmentedBy?: Optional<string>
): number {
  const isSegmented = isStringAndNotEmpty(segmentedBy) && segmentedBy !== "None";
  const maxY = favorabilityByPhase.reduce((maxYFinder: number, phaseFavorability: PhaseFavorability) => {
    phaseFavorability.subPhases.forEach((subPhaseFavorability: LifeCycleDashboardResource.SubPhase) => {
      if (
        not(isSegmented) &&
        !subPhaseFavorability.favorability.filteredForAnonymity &&
        isNotNullNorUndefined(subPhaseFavorability.favorability.score) &&
        subPhaseFavorability.favorability.score > maxYFinder
      ) {
        maxYFinder = subPhaseFavorability.favorability.score;
      }
      if (isSegmented) {
        const segments = subPhaseFavorability.segments[segmentedBy as string];
        if (isNullOrUndefined(segments)) {
          return 100;
        }
        Object.values(segments).forEach((segment) => {
          if (
            !segment.filteredForAnonymity &&
            isNotNullNorUndefined(segment.score) &&
            segment.score > maxYFinder
          ) {
            maxYFinder = segment.score;
          }
        });
      }
    });
    return maxYFinder;
  }, 0);

  return maxY / 2;
}
// tslint:disable-next-line: no-shadowed-variable
export function buildPlotBandsAndSeries(
  {
    favorabilityByPhase,
    initialOptions,
    plotWidth,
    plotLeft,
    segmentedBy,
    labelStatuses,
    votesPerSegments,
  }: ChartOptionsBuildingElements) {
  const immutableOptions = fromJS(initialOptions);
  const phaseSummaries = extractPhaseSummaries(favorabilityByPhase);
  const { totalNumberOfPhases } = computeChartVitalStats(phaseSummaries);
  const categories = new Array(totalNumberOfPhases).fill(0).map((_, index: number) => index.toString());
  let PLOT_BAND_WIDTH_PIXELS = 50;
  if (plotWidth && plotLeft) {
    PLOT_BAND_WIDTH_PIXELS = (plotWidth - plotLeft + YAXISOFFSET) / totalNumberOfPhases;
  }
  const updatedPlotBands = computePlotBands(phaseSummaries, PLOT_BAND_WIDTH_PIXELS, labelStatuses);
  computePlotLine(phaseSummaries);

  const mergingXAxis = fromJS({
    plotBands: updatedPlotBands,
    min: 0,
    max: totalNumberOfPhases * 1.1 - 1,
    categories,
    type: "category",
  });
  const maxFavorability = computeMaximumFavorability(favorabilityByPhase, segmentedBy);
  const mergingYAxis = fromJS({
    min: 0,
    max: lookUpYAxisMax(maxFavorability),
  });

  const updatedOptionsWithPlotbands = immutableOptions.setIn(
    ["xAxis", 0],
    immutableOptions.getIn(["xAxis", 0]).merge(mergingXAxis))
    .setIn(["yAxis", 0], immutableOptions.getIn(["yAxis", 0]).merge(mergingYAxis))
  ;
  return updateSeries(updatedOptionsWithPlotbands, favorabilityByPhase, votesPerSegments, segmentedBy);

}

export function computeChartVitalStats(phaseSummaries: PhaseSummariesForPlotBand) {
  const totalNumberOfPhases = Object.keys(phaseSummaries).length;
  let maxSubphases = 2;
  const totalNumberOfPoints = Object.values(phaseSummaries).reduce(
    (pointCountAcc: number, phaseDetails: PhaseSummaryForPlotBand) => {
      pointCountAcc = pointCountAcc + phaseDetails.numberOfSubphases;
      if (phaseDetails.numberOfSubphases > maxSubphases) {
        maxSubphases = phaseDetails.numberOfSubphases;
      }
      return pointCountAcc;
    }, 0);
  return { totalNumberOfPhases, totalNumberOfPoints, maxSubphases };
}

export function computeIfPlotLabelOpen(labelElements: Dictionary<Optional<boolean>>) {
  let isOpen = false;
  // tslint:disable-next-line: forin
  for (const elementIndex in labelElements) {
    if (labelElements[elementIndex] === true) {
      isOpen = true;
      break;
    }
  }
  return isOpen;
}

export function extractPhaseSummaries(
  favorabilityByPhase: PhaseFavorability[]): PhaseSummariesForPlotBand {
  return favorabilityByPhase.reduce(
    (phaseSummaryAcc: PhaseSummariesForPlotBand, phaseFavorability: PhaseFavorability) => {
      phaseSummaryAcc[phaseFavorability.name] = {
        startTenure: phaseFavorability.startTenure,
        endTenure: phaseFavorability.endTenure,
        numberOfSubphases: phaseFavorability.subPhases.length,
        subPhaseTenures: extractSubPhaseTenures(phaseFavorability.subPhases),
      };
      return phaseSummaryAcc;
    }, {});
}

export function extractPhaseSummary(phaseFavorability: PhaseFavorability) {
  const { name, subPhases, startTenure, endTenure, isSeparation } = phaseFavorability;

  const phaseSummary = {
    numberOfSubphases: subPhases.length,
    startTenure,
    endTenure,
    isSeparation,
    subPhaseTenures: subPhases.reduce((
      subPhaseSummaryAcc, subPhase
    ) => {
      subPhaseSummaryAcc.push({
        startTenure: subPhase.startTenure,
        endTenure: subPhase.endTenure,
        hasNoData: subPhase.favorability.filteredForAnonymity || !subPhase.favorability.score,
      });
      return subPhaseSummaryAcc;
    }, [] as {startTenure: number; endTenure: number; hasNoData: boolean}[]),
  };
  return { name, summary: phaseSummary };
}

function extractSubPhaseTenures(subPhases: LifeCycleDashboardResource.SubPhase[]) {
  return subPhases.map((subPhase: LifeCycleDashboardResource.SubPhase) => ({
    startTenure: subPhase.startTenure,
    endTenure: subPhase.endTenure,
    hasNoData: subPhase.favorability.filteredForAnonymity || isNullOrUndefined(subPhase.favorability.score),
  }));
}

export function computeMinimumFavorability(
  favorabilityByPhase: PhaseFavorability[],
  segmentedBy?: Optional<string>
) {
  const isSegmented = isStringAndNotEmpty(segmentedBy) && segmentedBy !== "None";
  const scores = favorabilityByPhase.reduce((relevantScores: number[], phase) => {
    phase.subPhases.forEach((subPhase) => {
      if (
        not(isSegmented) &&
        !subPhase.favorability.filteredForAnonymity &&
        isNotNullNorUndefined(subPhase.favorability.score)
      ) {
        relevantScores.push(subPhase.favorability.score);
      }

      if (isSegmented) {
        const segments = subPhase.segments[segmentedBy as string];
        if (isNullOrUndefined(segments)) {
          return relevantScores.push(0);
        }
        Object.values(segments).forEach((segment) => {
          if (!segment.filteredForAnonymity && isNotNullNorUndefined(segment.score)) {
            relevantScores.push(segment.score);
          }
        });
      }
    });
    return relevantScores;
  }, []);
  return isEmpty(scores) ? 0 : Math.min(...scores);
}

export function showNoDataInPreSeparationPhases(
  favorabilityByPhaseArray: PhaseFavorability[],
  segmentedBy?: string
) {
  const preSeparationfavorabilityByPhase = favorabilityByPhaseArray.filter(favorability => !favorability.isSeparation);
  return isEveryPhaseWithoutData(preSeparationfavorabilityByPhase, segmentedBy);
}

export function showNoDataInSeparationPhase(
  favorabilityByPhaseArray: PhaseFavorability[],
  segmentedBy?: string
) {
  const separationfavorabilityByPhase = favorabilityByPhaseArray.filter(favorability => favorability.isSeparation);
  return isEveryPhaseWithoutData(separationfavorabilityByPhase, segmentedBy);
}

function computeMaximumFavorability(
  favorabilityByPhase: PhaseFavorability[],
  segmentedBy: Optional<string>
) {
  return computeHalfMaxY(favorabilityByPhase, segmentedBy) * 2;
}

export function isEveryPhaseWithoutData(favorabilityByPhase: PhaseFavorability[], segmentedBy?: string) {
  const isSegmented = isStringAndNotEmpty(segmentedBy) && segmentedBy !== "None";
  if (isSegmented) {
    return favorabilityByPhase.every(phase => (
      phase.subPhases.every(subPhase => {
        if (isEmptyObject(subPhase.segments) || isEmptyObject(subPhase.segments[segmentedBy as string])) {
          return true;
        }
        return Object.values(subPhase.segments[segmentedBy as string]).every(segment => (
          segment.filteredForAnonymity || isNullOrUndefined(segment.score) || segment.numberOfVotes === 0
        ));
      })
    ));
  }
  return favorabilityByPhase.every(phase => (
    phase.subPhases.every(subPhase => (
      subPhase.favorability.filteredForAnonymity || subPhase.favorability.numberOfVotes === 0
    ))
  ));
}

export function computeNumberOfSegments(votesPerSegments: DimensionsDictionary<number>, segmentedBy?: string) {
  if (
    segmentedBy === "" ||
    (isStringAndNotEmpty(segmentedBy) && segmentedBy === "None")
  ) {
    return 1;
  }

  if (
    isEmptyObject(votesPerSegments) ||
    isNullOrUndefined(segmentedBy) ||
    isEmptyObject(votesPerSegments[segmentedBy])
  ) {
    return 0;
  }
  return Math.min(Object.keys(votesPerSegments[segmentedBy]).length, 10);
}

function lookUpYAxisMax(maxFavorability: number) {
  const yAxisMaxLookup: {[index: number]: number} = {
    0: 100,
    /* Why 110? because it is possible that fav hits 100. When that happens the marker gets half-overridden
     by the grid line at 100 or the plot band label */
    100: 110,
  };

  return yAxisMaxLookup[maxFavorability] || 100;
}
