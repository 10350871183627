import React from "react";
import styled from "styled-components";
import { getOr, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";

export class FiltersBackdrop extends React.PureComponent<any> {
  containerRef = React.createRef();
  mainNavigationContainer = document.querySelector("#navigationContainer");

  componentDidMount(): void {
    if (isNotNullNorUndefined(this.containerRef.current)) {

      // get the maximum height and apply it to the inner div, so from now on we have a scrollable backdrop
      (this.containerRef.current as any).style.height =
        this.extractHeight();
    }
  }

  generateScrollHandler: () => (event: any) => any = () => {
    if (isNotNullNorUndefined(this.mainNavigationContainer)) {
      return (event: any) => {
        // listen scroll and replicate the scroll on the main page container 😅
        this.mainNavigationContainer!.scrollTop = event.target.scrollTop;
      };
    } else {
      // we don't have access to main container, so don't do anything while scrolling
      // tslint:disable-next-line:no-empty
      return () => {};
    }
  };

  extractHeight() {
    if (isNotNullNorUndefined(this.mainNavigationContainer)) {
      return this.mainNavigationContainer.scrollHeight + "px";
    }

    return "100vh";
  }

  // noinspection JSMethodCanBeStatic
  getHeight(elem: any): number {
    return getOr(elem.offsetHeight, 0);
  }

  render() {
    return (
      <FiltersBackdropContainer
        onScroll={this.generateScrollHandler()}
      >
        <FiltersBackdropInnerContent
          ref={this.containerRef}
          {...this.props}
        />
      </FiltersBackdropContainer>
    );
  }
}

const FiltersBackdropContainer = styled.div<any>`
  position: fixed;
  left: 0;
  right: 0;
  height: 100vh;
  top: 0;
  z-index: 5;
  overflow-y: auto;

  /*
      We want to hide the scrollbar, and keep only the one from the main scrollable container.
      So use some CSS trick.

      (source: https://stackoverflow.com/a/38994837)
   */

  -ms-overflow-style: none;  // IE 10+
  scrollbar-width: none;  // Firefox
  &::-webkit-scrollbar {
    display: none;  // Safari and Chrome
  }
`;

const FiltersBackdropInnerContent = styled.div<any>`
  height: 100vh;
`;
