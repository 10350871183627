import { Record, RecordOf, fromJS } from "immutable";
import { SurveyInfoResource } from "hyphen-lib/dist/domain/resource/SurveyInfoResource";
import { PulsePollInfoResource } from "hyphen-lib/dist/domain/resource/PulsePollInfoResource";
import { ActionResource } from "hyphen-lib/dist/domain/resource/action/ActionResource";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { FilterParameter } from "@src/utils/networks";
import { ActionPlansActionTypes } from "./actions";

export interface NewActionResource extends ActionResource {
  description: string;
  resource: string;
}

export interface ActionPlanReduxProps {
  readonly pageSize: number;
  readonly isNetworkRequesting: boolean;
  readonly isAddingAction: boolean;
  readonly surveyInfo: RecordOf<SurveyInfoResource>;
  readonly pulsePollInfo: RecordOf<PulsePollInfoResource>;
  readonly actionPlan: RecordOf<NewActionResource>;
  readonly filter: Optional<FilterParameter>;
  readonly objectiveList: Optional<ActionResource.Objective[]>;
  readonly enableSuccessOKRModal: boolean;
  readonly fetchingObjective: boolean;
}

export type ActionPlanState = RecordOf<ActionPlanReduxProps>;

const surveyInfoFactory = Record<SurveyInfoResource>({} as SurveyInfoResource);
const pulsePollInfoFactory = Record<PulsePollInfoResource>({} as PulsePollInfoResource);
const selectedActionPlanFactory = Record<NewActionResource>({} as NewActionResource);

const defaultValues: ActionPlanReduxProps = {
  pageSize: 10,
  isNetworkRequesting: false,
  isAddingAction: false,
  surveyInfo: surveyInfoFactory(),
  pulsePollInfo: pulsePollInfoFactory(),
  actionPlan: selectedActionPlanFactory(),
  filter: Optional.empty(),
  objectiveList: null,
  enableSuccessOKRModal: false,
  fetchingObjective: true
};

export const actionPlanStateFactory = Record<ActionPlanReduxProps>(defaultValues);
const defaultState = actionPlanStateFactory();

export function actionPlansReducer(
  state: ActionPlanState = defaultState,
  action: any
) {
  switch (action.type) {
    case ActionPlansActionTypes.ADD_ACTION_PLAN_REQUEST:
      return state.set("isAddingAction", true);
    case ActionPlansActionTypes.ADD_ACTION_PLAN_ERROR:
      return state.set("isAddingAction", false).set("enableSuccessOKRModal", false);
    case ActionPlansActionTypes.ADD_ACTION_PLAN_SUCCESS:
      return state.merge({
        "isAddingAction": false, 
        "enableSuccessOKRModal": true, 
        "actionPlan": fromJS(action.payload.data),
      });
    case ActionPlansActionTypes.CLEAN_ACTION_PLAN_RESOURCE:
      return state.merge({
        "enableSuccessOKRModal": false,
        "actionPlan": Optional.empty() as RecordOf<NewActionResource>,
      });
    case ActionPlansActionTypes.FETCH_SURVEY_INFO_REQUEST:
    case ActionPlansActionTypes.FETCH_PULSE_POLL_INFO_REQUEST:
    case ActionPlansActionTypes.FETCH_ACTION_PLAN_REQUEST:
      return state.set("isNetworkRequesting", true);
    case ActionPlansActionTypes.FETCH_ACTION_PLAN_ERROR:
    case ActionPlansActionTypes.FETCH_SURVEY_INFO_ERROR:
    case ActionPlansActionTypes.FETCH_PULSE_POLL_INFO_ERROR:
    case ActionPlansActionTypes.FETCH_ACTION_PLAN_SUCCESS:
    case ActionPlansActionTypes.FETCH_SURVEY_INFO_SUCCESS:
    case ActionPlansActionTypes.FETCH_PULSE_POLL_INFO_SUCCESS:
      return state.set("isNetworkRequesting", false);
    case ActionPlansActionTypes.SELECT_SURVEY_INFO:
      return state.set("surveyInfo", fromJS(action.surveyInfo));
    case ActionPlansActionTypes.SELECT_PULSE_POLL_INFO:   
      return state.set("pulsePollInfo", fromJS(action.pulsePollInfo));
    case ActionPlansActionTypes.SELECT_ACTION_PLAN:
      return state.set("actionPlan", fromJS(action.actionPlan));
    case ActionPlansActionTypes.UPDATE_ACTION_PLAN_SETTINGS:
      return state.set("actionPlan", state.get("actionPlan").merge(action.payload));
    case ActionPlansActionTypes.MODIFY_FILTER:
      return state.set("filter", action.payload);
    case ActionPlansActionTypes.CLEAN_FILTER:
      return state.set("filter", Optional.empty());
    case ActionPlansActionTypes.FETCH_ACTION_PLAN_OBJECTIVE_REQUEST:
      return state.set("fetchingObjective", true);
    case ActionPlansActionTypes.FETCH_ACTION_PLAN_OBJECTIVE_SUCCESS:
      return state.merge({
        "objectiveList": action.payload.data, 
        "fetchingObjective": false
      });
    default:
      return state;
  }
}
