import { default as networkActionCreators } from "@store/network/actions";
import { createRequest, FilterParameter, SortParameter } from "@src/utils/networks";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { appendQueryString, generateQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { UserResource } from "hyphen-lib/dist/domain/resource/user/UserResource";

export interface FetchUsersParameters {
  readonly filter?: FilterParameter;
  readonly sort?: SortParameter;
  readonly page: PageFilter;
}

export function fetchUsersIfNeeded({filter, sort, page}: FetchUsersParameters) {
  return networkActionCreators.fetchPageIfNeeded({
    id: UserResource.generateKey(filter, sort),
    type: UserResource.TYPE,
    page,
    rawPageSize: 30,
    request: pageFilter => createRequest(
      appendQueryString(
        "/users",
        generateQueryString({
          filter,
          sort,
          page: pageFilter,
        })
      )
    ),
  });
}

export function fetchUsersCountIfNeeded(filter: FilterParameter) {
  return networkActionCreators.fetchCountIfNeeded({
    id: UserResource.generateKey(filter),
    type: UserResource.TYPE,
    request: createRequest(
      appendQueryString(
        "/users/count",
        generateQueryString({ filter })
      )
    ),
  });
}
