import React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { State } from "@store/types";
import { Trans } from "react-i18next";
import { Alert } from "antd";

import Button from "@components/core/Button";
import Tabs from "@components/core/Tabs";

import {
  getCurrentUser,
} from "@src/screens/Insights/store/selectors";
import {
  isCoreAppUserSyncEnabled
} from "hyphen-lib/dist/business/company/Companies";
import { ScreenContainer } from "@screens/Insights/components/ScreenContainer";
import { UserListContainer } from "@screens/Insights/UserManagement/containers/UserListContainer";
import UserPermissions from "@screens/Insights/UserManagement/containers/UserPermissions";
import { getAllDimensions } from "@screens/Insights/store/selectors";

import { Dimensions } from "@hyphen-lib/domain/common/Dimensions";
import { Optional } from "@hyphen-lib/lang/Optionals";
import { getOr, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { Rights } from "@hyphen-lib/business/auth/Rights";
import { getRightsMatcher } from "@screens/Insights/store/selectors";
import { PropMapping } from "@src/utils/parameters";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import UserRoles from "./containers/UserRoles/index";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { checkIfHyphenAdmin } from "hyphen-lib/dist/business/user/Users";

const { TabPane } = Tabs;
interface UserManagementProps {
  dimensions: Optional<Dimensions>;
  hasAccessToPermission: boolean;
  canAddUsers: boolean;
  isUserSyncEnabled: boolean;
}

type OwnProps = RouteComponentProps;
type Props = UserManagementProps & OwnProps;

export const USER_LIST_FILTER_MAPPINGS: PropMapping[] = [
  { localKey: "filter", storeKey: "UserListSearch" },
  { localKey: "filter.freeText", storeKey: "UserListSearch.freeText" },
];

export enum USER_ROUTES {
  USER_ROUTE = "/users",
  USER_ROLE_ROUTE = "/users/roles",
  USER_PERMISSION_ROUTE = "/users/permissions",
}

const KEY_BY_PATH_NAME: Dictionary<string> = {
  "/users": "user-list",
  "/users/roles": "roles",
  "/users/permissions": "user-permissions",
};
export class UserList extends React.Component<Props> {

  onAddUsersClick = () => {
    const { dimensions, history } = this.props;

    if (isNotNullNorUndefined(dimensions)) {
      const hasSomeDimensions = Object.keys(dimensions).some((dim: string) => dim !== "manager");
      if (hasSomeDimensions) {
        history.push("/users/add");
      } else {
        history.push("/users/add/dimensions");
      }
    } else {
      history.push("/users/add");
    }
  };

  onTabClick = (key: string, event: any) => {
    const { history } = this.props;
    if (key === KEY_BY_PATH_NAME[USER_ROUTES.USER_ROLE_ROUTE]) {
      history.push(USER_ROUTES.USER_ROLE_ROUTE);
    } else if (key === KEY_BY_PATH_NAME[USER_ROUTES.USER_PERMISSION_ROUTE]) {
      history.push(USER_ROUTES.USER_PERMISSION_ROUTE);
    } else if (key === KEY_BY_PATH_NAME[USER_ROUTES.USER_ROUTE]) {
      history.push(USER_ROUTES.USER_ROUTE);
    }
  };

  getActiveKey = (path: string) => {
    return getOr(KEY_BY_PATH_NAME[path], KEY_BY_PATH_NAME[USER_ROUTES.USER_ROUTE]);
  };

  render() {
    const { hasAccessToPermission, canAddUsers, location, isUserSyncEnabled } = this.props;
    const activeKey = this.getActiveKey(location.pathname);
    return (
      <ScreenContainer
        title="User Management"
        actions={[
          isUserSyncEnabled && <Alert
            key="userSync"
            showIcon
            message={<Trans i18nKey="userSyncBanner">
              <strong>NOTE</strong>: Your company has been configured to
              manage Users only from <strong>BetterWorks</strong> application
            </Trans>}
            type="warning"
          />,
          not(isUserSyncEnabled) && <Link key="edit" to="/users/edit/dimensions">
            <Button color="grey" translate="yes">Edit dimensions</Button>
          </Link>,
          not(isUserSyncEnabled) && canAddUsers &&
          <Button key="add" color="blue" 
          translate="yes" onClick={this.onAddUsersClick}>Add users</Button>,
        ]}
      >
        <Tabs
          onTabClick={this.onTabClick}
          defaultActiveKey={KEY_BY_PATH_NAME[USER_ROUTES.USER_ROUTE]}
          activeKey={activeKey}
        >
          <TabPane tab={<Trans>Users</Trans>}
            key={KEY_BY_PATH_NAME[USER_ROUTES.USER_ROUTE]}>
            <UserListContainer />
          </TabPane>
          {
            hasAccessToPermission && (
              <TabPane tab={<Trans>Permissions</Trans>}
                key={KEY_BY_PATH_NAME[USER_ROUTES.USER_PERMISSION_ROUTE]}>
                <UserPermissions />
              </TabPane>
            )
          }
          {
            hasAccessToPermission && (
              <TabPane tab={<Trans>Roles</Trans>}
                key={KEY_BY_PATH_NAME[USER_ROUTES.USER_ROLE_ROUTE]}>
                <UserRoles location = {this.props.location}/>
              </TabPane>
            )
          }
        </Tabs>
      </ScreenContainer>
    );
  }
}

function mapStateToProps(state: State, routeComponentProps: RouteComponentProps): UserManagementProps {
  const currentUser = getCurrentUser(state);
  const isUserSyncEnabled = getOr(currentUser && currentUser.company && 
    isCoreAppUserSyncEnabled(currentUser.company) && not(checkIfHyphenAdmin(currentUser.email)), false);
  return {
    dimensions: getAllDimensions(state),
    hasAccessToPermission: getRightsMatcher(state).hasRight(Rights.User.USERS_ROLES),
    canAddUsers: getRightsMatcher(state).hasAnyOf(...[Rights.User.ADD_USERS_CSV, Rights.User.ADD_USERS_MANUALLY]),
    isUserSyncEnabled
  };
}

export default withRouter(
  connect(
    mapStateToProps
  )(UserList)
);
