import { Record, RecordOf } from "immutable";
import {
  ModifyListAction,
  ToggleModalVisibilityAction,
  UpdateUserRequestAction,
  UpdateUserSuccessAction,
  ToggleEditUserModalVisibilityAction,
  UserListActionTypes,
  UpdateUserErrorAction,
  SelectUnselectAction
} from "@screens/Insights/UserManagement/containers/UserListContainer/store/actions";
import { SelectionFilter } from "hyphen-lib/dist/domain/parameter/SelectionFilter";

export interface UserListContainerStateProps {
  readonly pageSize: number;
  readonly modalVisible: boolean;
  readonly isEditUserModalVisible: boolean;
  readonly isUpdatingUser: boolean;
  readonly isDeletingUsers: boolean;
  readonly selectedRowKeys: string[];
  readonly tracker: SelectionFilter.Tracker;
}
export type UserListContainerState = RecordOf<UserListContainerStateProps>;

const defaultValues: UserListContainerStateProps = {
  pageSize: 10,
  modalVisible: false,
  isUpdatingUser: false,
  isEditUserModalVisible: false,
  isDeletingUsers: false,
  selectedRowKeys: [],
  tracker: SelectionFilter.initializeNewTracker(),
};

export const userListContainerStateFactory = Record<UserListContainerStateProps>(defaultValues);

type Actions = ModifyListAction |
ToggleModalVisibilityAction |
UpdateUserRequestAction |
UpdateUserSuccessAction |
ToggleEditUserModalVisibilityAction |
SelectUnselectAction |
UpdateUserErrorAction;

export function userListContainerReducers(state: UserListContainerState = userListContainerStateFactory(),
  action: Actions | any): UserListContainerState {
  switch (action.type) {
    case UserListActionTypes.TOGGLE_MODAL_VISIBILITY:
      return state.set("modalVisible", action.visible);
    case UserListActionTypes.TOGGLE_EDIT_USER_MODAL_VISIBILITY:
      return state.set("isEditUserModalVisible", action.visible);
    case UserListActionTypes.UPDATE_USER_REQUEST:
      return state.set("isUpdatingUser", true);
    case UserListActionTypes.UPDATE_USER_SUCCESS:
    case UserListActionTypes.UPDATE_USER_ERROR:
      return state.set("isUpdatingUser", false);
    case UserListActionTypes.DELETE_USERS_REQUEST:
      return state.set("isDeletingUsers", true);
    case UserListActionTypes.DELETE_USERS_ERROR:
      return state.merge({
        isDeletingUsers: false,
        modalVisible: false,
      });
    case UserListActionTypes.DELETE_USERS_SUCCESS:
      return state.merge({
        isDeletingUsers: false,
        selectedRowKeys: [],
        modalVisible: false,
        tracker: state.tracker.deselectAll(),
      });
    case UserListActionTypes.SELECT_UNSELECT_USERS:
      return state.merge(action.payload);
    default:
      return state;
  }
}
