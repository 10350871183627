import React, { MouseEvent } from "react";
import styled from "styled-components";
import { Button } from "antd";

import Palette from "@src/config/theme/palette";
import { Trans } from "react-i18next";

interface AddPhaseProps {
  addNewPhase: (evt: MouseEvent<HTMLDivElement>) => void;
}

const AddPhaseButton = (props: AddPhaseProps) => {
  return (
    <AddPhase onClick={props.addNewPhase}>
      <Button data-cy="add_phase" icon="plus-circle" type="link">
        <span>&emsp;<Trans>Add a Phase</Trans></span>
      </Button>
    </AddPhase>
  );
};

const AddPhase = styled.div`
  border: 2px dashed #d1dbe8;
  border-radius: 2px;

  text-align: center;
  padding-top: 22px;
  padding-bottom: 22px;
  margin-top: 48px;
  margin-bottom: 48px;

  cursor: pointer;

  .ant-btn {
    font-size: 16px;
    color: grey !important;
    text-transform: uppercase;

    span {
      color: ${Palette.darkBlueGrey};
    }
  }
`;

export default AddPhaseButton;
