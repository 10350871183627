import { Record, RecordOf } from "immutable";
import { SurveyReportActionTypes } from "./actions";

export interface SurveyReportStateProps {
  readonly commentText: string;
  readonly errorText: string;
  readonly isPrivateMessageModalVisible: boolean;
  readonly isSendMessageRequesting: boolean;
  readonly postId: string;
  readonly privateMessageText: string;
  readonly surveyId: string;
  readonly voteId: string;
}

export type SurveyReportState = RecordOf<SurveyReportStateProps>;

const defaultValues: SurveyReportStateProps = {
  commentText: "",
  errorText: "",
  isPrivateMessageModalVisible: false,
  isSendMessageRequesting: false,
  postId: "",
  privateMessageText: "",
  surveyId: "",
  voteId: "",
};

export const surveyReportStateFactory = Record<SurveyReportStateProps>(defaultValues);
const defaultState = surveyReportStateFactory();

export function surveyReportReducers(
  state: SurveyReportState = defaultState,
  action: any
) {
  switch (action.type) {
    case SurveyReportActionTypes.SEND_PRIVATE_MESSAGE_REQUEST:
      return state.merge({isSendMessageRequesting: true, errorText: ""});
    case SurveyReportActionTypes.SEND_PRIVATE_MESSAGE_ERROR:
      return state.merge({
        isSendMessageRequesting: false,
        errorText: "Unexpected error occurred while sending messsage.",
      });
    case SurveyReportActionTypes.SEND_PRIVATE_MESSAGE_SUCCESS:
      return state.merge({...defaultValues});
    case SurveyReportActionTypes.CLEAR_PRIVATE_MESSAGE_MODAL:
      return state.merge({...defaultValues});
    case SurveyReportActionTypes.UPDATE_PRIVATE_MESSAGE_TEXT:
      return state.set("privateMessageText", action.payload);
    case SurveyReportActionTypes.TOGGLE_PRIVATE_MESSAGE_MODAL:
      return (!(state.get("isPrivateMessageModalVisible"))) 
        ? state.merge({...action.payload, privateMessageText: "", isPrivateMessageModalVisible: true })
        : state.merge({...defaultValues});
    default:
      return state;
  }
}