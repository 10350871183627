import React from "react";
import { connect } from "react-redux";
import debounce from "lodash.debounce";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { cleanObject, getOr, mapOr, mapValues } from "hyphen-lib/dist/lang/Objects";
import { parseNumber } from "hyphen-lib/dist/lang/Number";
import { parseBoolean } from "hyphen-lib/dist/lang/Booleans";
import {
  GroupInfoResource,
  GroupsAndChannelsResource
} from "hyphen-lib/dist/domain/resource/group/GroupsAndChannelsResource";

import convertDictToFilters, { clearFilter, clearFilterOption } from "@src/components/core/FilterLabels/utils";
import { SearchAndFilter } from "@src/screens/Insights/components/SearchAndFilter";
import { parametersActionCreators } from "@src/screens/Insights/parameters/store/actions";
import { PropMapping } from "@src/utils/parameters";
import { State } from "@src/store/types";
import { formatSort } from "@src/utils/helper";
import { FilterParameter } from "@src/utils/networks";
import FilterLabels from "@src/components/core/FilterLabels";
import { FetchDataListParameters } from "@src/screens/Insights/components/DataListContainer/types";

import { fetchGroupsAndChannelsIfNeeded } from "@src/store/network/resource/GroupsAndChannelsResource";
import { getResourceById } from "@src/store/network/selectors";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { ExportButton } from "@src/screens/Insights/PulsePoll/components/ReportHeader/ExportDropdown";
import { Icon } from "antd";
import { ExportSvg } from "@components/core/svg/ExportSvg";
import {
  createExportVoicePostsRequest,
  createExportVoiceCommentsRequest,
  createExportVoiceFlaggedPostsRequest,
  createExportVoiceFlaggedCommentsRequest
} from "@src/utils/exports";
import { getEmployeeVoiceReportsState } from "../store/selectors";
import { VoiceReportsFilters } from "../Components/VoiceReportsFilters";
import { voiceReportsActionCreators } from "../store/actions";
import { Trans } from "react-i18next";

interface VoiceReportsHeaderProps
  extends VoiceReportsHeaderReduxActions,
  VoiceReportsHeaderReduxStateProps,
  RouteComponentProps {
  readonly mappings: PropMapping[];
  readonly searchPlaceholder: string;
  readonly selectedKey: string;
}

interface VoiceReportsHeaderReduxActions {
  readonly onModifyFilter: (filters: any) => void;
  readonly onCleanFilter: () => void;
  readonly onModifyList: (parameters: FetchDataListParameters) => void;
  readonly onFetchGroupsAndChannels: () => void;

  readonly onModifyParameters: (
    parameters: Dictionary<any>,
    mappings?: PropMapping[]
  ) => void;
}

interface VoiceReportsHeaderReduxStateProps {
  readonly pageParams: FetchDataListParameters;
  readonly groupsAndChannels: GroupInfoResource[];
}

interface VoiceReportsHeaderStateProps {
  readonly areFiltersVisible: boolean;
}

class VoiceReportsHeaderContainer extends React.Component<
VoiceReportsHeaderProps,
VoiceReportsHeaderStateProps
> {
  private onSearchChangeDebounced: (value: any) => void;
  constructor(props: VoiceReportsHeaderProps) {
    super(props);

    this.state = {
      areFiltersVisible: false,
    };
    this.onSearchChangeDebounced = debounce(this.updateSearchFilter, 500);
  }
  componentDidMount() {
    const {
      onModifyFilter,
      pageParams: { filter },
      onFetchGroupsAndChannels,
    } = this.props;

    onModifyFilter(filter);
    onFetchGroupsAndChannels();
  }
  componentWillUnmount() {
    this.props.onCleanFilter();
  }
  onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.onSearchChangeDebounced(event.target.value);
  };

  updateSearchFilter = (value: any) => {
    const {
      onModifyList,
      pageParams: { sort, page },
      mappings,
      onModifyParameters,
    } = this.props;
    const filter = getOr(this.props.pageParams.filter, {});
    if (isStringAndNotEmpty(value)) {
      filter.freeText = value;
    } else {
      filter.freeText = {};
    }
    const resetPage = { ...page, number: 1 };
    onModifyList({
      filter,
      page: resetPage,
      sort,
    });
    onModifyParameters({ filter, sort }, mappings);
  };

  onFiltersApply = (newFilter: FilterParameter) => {
    const {
      pageParams: { page, sort },
      onModifyList,
      onModifyParameters,
      mappings,
    } = this.props;

    const resetPage = { ...page, number: 1 };

    onModifyParameters({ filter: newFilter, sort }, mappings);

    onModifyList({
      filter: cleanObject(newFilter),
      page: resetPage,
      sort,
    });

    this.setState({ areFiltersVisible: false });
  };

  togglePopOver = () =>
    this.setState(state => ({ areFiltersVisible: !state.areFiltersVisible }));

  getFilterValues = () => {
    const { location } = this.props;
    const { filter = {} } = parseQueryString(location.search);

    return mapValues(filter, (val: any, key: string) => {
      switch (key) {
        case "withCommentOnly":
          return parseBoolean(val);
        case "sentiments":
          return val.map(parseNumber);
      }
      return val;
    });
  };

  onClearFilter = (filterToRemove: string) => {
    const {
      pageParams: { page, sort },
      onModifyList,
      onModifyParameters,
      mappings,
    } = this.props;

    const filter = clearFilter(filterToRemove, this.props.pageParams.filter);
    onModifyParameters({ filter }, mappings);

    onModifyList({
      filter: cleanObject(filter),
      page,
      sort,
    });
  };

  onClearFilterOption = (filterKey: string, subFilter: string) => {
    const {
      pageParams: { page, sort },
      onModifyList,
      onModifyParameters,
      mappings,
    } = this.props;

    const filter = clearFilterOption(
      filterKey,
      subFilter,
      this.props.pageParams.filter
    );

    onModifyParameters({ filter }, mappings);

    onModifyList({
      filter: cleanObject(filter),
      page,
      sort,
    });
  };

  renderFilterLabels = () => {
    const {
      pageParams: { filter },
    } = this.props;
      // FIXME: create a more generic reusable convertDictToFilters function
    const filters = convertDictToFilters(
      filter,
      {} /* no dimensions filters */
    );

    if (filters.length > 0) {
      return (
        <FilterLabels
          filters={filters}
          onClearFilter={this.onClearFilter}
          onClearSubfilter={this.onClearFilterOption}
        />
      );
    } else {
      return null;
    }
  };

  handleExportsButtonClick = () => {
    const { location, selectedKey } = this.props;
    switch (selectedKey) {
      case "posts":
        createExportVoicePostsRequest(location.search);
        return;
      case "flags/posts":
        createExportVoiceFlaggedPostsRequest(location.search);
        return;
      case "comments":
        createExportVoiceCommentsRequest(location.search);
        return;
      case "flags/comments":
        createExportVoiceFlaggedCommentsRequest(location.search);
        return;
    }
  };

  render() {
    const {
      onModifyFilter,
      pageParams: { filter },
      searchPlaceholder,
      groupsAndChannels,
    } = this.props;
    const { areFiltersVisible } = this.state;
    return (
      <Container>
        <SearchAndFilter
          isPopoverVisible={areFiltersVisible}
          onExpandButtonClick={this.togglePopOver}
        >
          {({ getPopOverProps, getButtonProps }) => (
            <>
              <SearchAndFilter.SearchBar
                defaultValue={mapOr(filter, map => getOr(map.freeText, ""), "")}
                onChange={this.onSearchChange}
                placeholder={searchPlaceholder}
              />
              <SearchAndFilter.ExpandButton
                {...getButtonProps({
                  icon: "filter",
                  // @ts-ignore
                  "data-jest": "voice-filter-button",
                })}
                translate="yes"
              >
                Filter
              </SearchAndFilter.ExpandButton>
              <ExportButton color="gradation" onClick={this.handleExportsButtonClick}>
                <Icon component={ExportSvg} />
                <span><Trans>Export CSV</Trans></span>
              </ExportButton>
              <SearchAndFilter.PopOver {...getPopOverProps()}>
                <VoiceReportsFilters
                  onChange={onModifyFilter}
                  onApply={this.onFiltersApply}
                  values={this.getFilterValues()}
                  groupsAndChannels={groupsAndChannels}
                />
              </SearchAndFilter.PopOver>
            </>
          )}
        </SearchAndFilter>
        {this.renderFilterLabels()}
      </Container>
    );
  }
}

function mapStateToProps(
  state: State,
  { location }: RouteComponentProps
): VoiceReportsHeaderReduxStateProps {
  const queryParameters = parseQueryString(location.search);
  const { pageSize } = getEmployeeVoiceReportsState(state);
  const filter = getOr(queryParameters.filter, {});
  const sort = getOr(formatSort(queryParameters.sort), {});
  const page = {
    size: pageSize,
    number: mapOr(queryParameters.page, parseNumber, 1),
  };

  const groupsAndChannels = Store.Element.mapIfLoadedOr(
    getResourceById(
      state,
      GroupsAndChannelsResource.TYPE,
      GroupsAndChannelsResource.generateKey()
    ),
    g => g.data,
    []
  );

  const pageParams: FetchDataListParameters = {
    filter,
    page,
    sort,
  };

  return {
    pageParams,
    groupsAndChannels,
  };
}

const mapDispatchToProps = {
  onModifyFilter: voiceReportsActionCreators.modifyFilter,
  onModifyList: voiceReportsActionCreators.modifyList,
  onCleanFilter: voiceReportsActionCreators.cleanFilter,
  onFetchGroupsAndChannels: fetchGroupsAndChannelsIfNeeded,
  onModifyParameters: parametersActionCreators.modifyParameters,
};

export const VoiceReportsHeader = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VoiceReportsHeaderContainer)
);

const Container = styled.div`
  background: white;
`;
