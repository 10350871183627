import { Map } from "immutable";

const LabelMapper : Map<string, any> = Map({
  settings: {
    name: "Poll name",
    type: "Poll type",
    logo: "Poll logo",
    introductionText: "Introduction page text",
    channels: {
      email: {
        label: "Send Email Notification",
        info: "If checked, a notification will be sent to your employees via email",
      },
      slack: {
        label: "Send Slack Notification",
        info: "If checked, a notification will be sent to your employees on Slack",
      },
      beekeeper: {
        label: "Send Beekeeper Notification",
        info: "If checked, a notification will be sent to your employees on Beekeeper",
      },
      workplace: {
        label: "Send Workplace Notification",
        info: "If checked, a notification will be sent to your employees on Facebook Workplace",
      },
      sms: {
        label: "Send SMS",
        info: "If checked, a notification will be sent to your employees via SMS",
      },
      speakap: {
        label: "Send Speakap Notification",
        info: "If checked, a notification will be sent to your employees on speakap",
      },
      symphony: {
        label: "Send Symphony Notification",
        info: "If checked, a notification will be sent to your employees on symphony",
      },
    },
    useChannels: {
      email: "Send Email Notification",
      slack: "Send Slack Notification",
      beekeeper: "Send Beekeeper Notification",
      speakap: "Send Speakap Notification",
      symphony: "Send Symphony Notification",
      workplace: "Send Workplace Notification",
      sms: "Send SMS",
    },

    launchEmailSubject: "Email Subject",
    launchEmailBody: "Email Body",
    "channels.email.subject": "Email Subject",
    "channels.email.body": "Email Body",

    "channels.slack.body": "Slack Notification Body",
    slackNotificationBody: "Slack Notification Body",

    "channels.beekeeper.body": "Beekeeper Notification Body",
    beekeeperNotificationBody: "Beekeeper Notification Body",

    "channels.workplace.body": "Workplace Notification Body",
    workplaceNotificationBody: "Workplace Notification Body",

    "channels.symphony.body": "Symphony Notification Body",
    symphonyNotificationBody: "Symphony Notification Body",

    "channels.speakap.body": "Speakap Notification Body",
    speakapNotificationBody: "Speakap Notification Body",

    "channels.sms.body": "SMS Body",
    smsBody: "SMS Body",

    stopReminder: "Stop the reminders after:",
    stopReminderInfo: "Reminders will be stopped if the poll is already closed",
    reminderInterval: "Send reminders every:",
    allowAnonymous: "Allow Anonymous",
    allowSkip: "Allow Skip",
  },
  questions: {
    postTemplateIds: "Poll questions",
  },
});

export const PageNameMapper: Map<string, any> = Map({
  settings: "Settings",
  questions: "Questions",
});

export default LabelMapper;
