import React from "react";
import Modal from "@components/core/Modal";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import Button from "@src/components/core/Button";
import { ModalProps } from "antd/lib/modal";
import { Trans } from "react-i18next";

interface Props {
  onOkClick: () => void;
  onCancelClick: () => void;
  currentStep: string;
  translationWarningStatus: boolean;
}

export function MissingTranslationAlert(props: Props) {
  return (
    <StyledModal
      visible={props.translationWarningStatus}
      title={<Trans>Are you sure?</Trans>}
      onOk={props.onOkClick}
      onCancel={props.onCancelClick}
      footer={[
        <ActionButtons
          data-cy="wizard_areYouSure_keepGoing"
          color="grey"
          key="forward"
          onClick={props.onOkClick}
          translate="yes"
        >Launch Survey
        </ActionButtons>,
        <ActionButtons
          data-cy="wizard_areYouSure_back"
          color="blue"
          key="back"
          onClick={props.onCancelClick}
        >
          <Trans>Back to</Trans> <Trans>{props.currentStep}</Trans>
        </ActionButtons>,
      ]}
    >
        <Container>
          <Trans>You have not added translations for all the languages added in the survey.</Trans><br/>
          <Trans>If you launch the survey now, employees will see questions/answer options in English,
            if they select those languages.</Trans>
        </Container>
    </StyledModal>
  );
}

const Container = styled.div`
  padding: 16px 32px;
  color: ${Palette.bluishGrey};
`;

const ActionButtons = styled(Button)`
  width: 158px;
`;

const StyledModal = styled(Modal)<ModalProps & {children: React.ReactNode}>``;
