import React from "react";
import styled from "styled-components";
import Input from "@src/components/core/Input";
import Palette from "@src/config/theme/palette";
import { Paragraph } from "@src/components/core/Typography";
import { Trans } from "react-i18next";

interface CustomAnswerProps {
  answer: string;
  onEdit: (answer: string) => void;
  onRemove: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  hover: boolean;
}

export class CustomAnswer extends React.Component<CustomAnswerProps, {
  disabled: boolean;
  answer: CustomAnswerProps["answer"];
}> {
  constructor(props: CustomAnswerProps) {
    super(props);
  
    this.state = {
      disabled: true,
      answer: props.answer,
    };
  }
  
  componentDidUpdate(prevProps: CustomAnswerProps) {
    if (this.props.answer !== prevProps.answer) {
      this.setState({ answer: this.props.answer });
    }
  }
  
  onEdit = () => {
    this.setState({ disabled: false });
  };
  onRemove = () => {
    this.props.onRemove();
  };
  onAdd = (e: any) => {
    e.preventDefault();
    const { answer } = this.state;
    const { onEdit } = this.props;
    onEdit(answer);
    this.setState({ disabled: true });
  };
  
  getSuffix = () => {
    const { disabled } = this.state;
    const { hover } = this.props;
    if (hover && disabled) {
      return (
        <CustomAnswerActions>
          <BlueLink onClick={this.onEdit}><Trans>Edit</Trans></BlueLink>
          <Remove onClick={this.onRemove}><Trans>Remove</Trans></Remove>
        </CustomAnswerActions>
      );
    } else if (!disabled) {
      return <BlueLink onClick={this.onAdd}><Trans>Save</Trans></BlueLink>;
    }
    return null;
  };
  
  onAnswerChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ answer: e.target.value });
  render() {
    const { disabled, answer } = this.state;
    const suffixComponent = this.getSuffix();
    return (
      <>
        <div
          onMouseEnter={this.props.onMouseEnter}
          onMouseLeave={this.props.onMouseLeave}
        >
          <Input
            disabled={disabled}
            suffix={suffixComponent}
            value={answer}
            onChange={this.onAnswerChange}
            onPressEnter={this.onAdd}
          />
        </div>
      </>
    );
  }
}

const CustomAnswerActions = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BlueLink = styled.div`
  color: ${Palette.darkModerateBlue};
  cursor: pointer;
`;

const Remove = styled(Paragraph)`
  color: ${Palette.darkPink} !important;
  cursor: pointer;
  margin-left: 16px !important;
`;
