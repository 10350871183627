import React from "react";
import styled from "styled-components";
import Palette, { barGraphTheme } from "@src/config/theme/palette";
import { QuestionType } from "hyphen-lib/dist/domain/common/QuestionType";
import { Trans } from "react-i18next";

interface AnswerProps extends React.HTMLProps<HTMLDivElement> {
  answer: {
    favorability: number;
    text: string;
  };
  questionType: string;
}

export function Answer ({ answer: { favorability, text }, questionType, ...rest }: AnswerProps) {

  const getFavorabilityClassName = (favorabilityValue: number) => {
    switch (favorabilityValue) {
      case 0:
        return "VeryFavorable";
      case 1:
        return "Favorable";
      case 2:
        return "Neutral";
      case 3:
        return "Unfavorable";
      case 4:
        return "VeryUnfavorable";
      default:
        return "";
    }
  };

  let answerClassName: string;

  if (questionType === QuestionType.LIKERT) {
    answerClassName = getFavorabilityClassName(favorability);
  } else if (questionType === QuestionType.ENPS) {
    answerClassName = `EnpsChoice${favorability}`;
  } else {
    answerClassName = `Choice${favorability}`;
  }

  return (
    // @ts-ignore
    <AnswerContainer data-text={text} {...rest}>
      <AnswerIndicator className={answerClassName}/>
      <AnswerLabel><Trans>{text}</Trans></AnswerLabel>
    </AnswerContainer>
  );
}

const LikertChoiceAnswerStyles = `
  .VeryFavorable {
    background: ${Palette.aquaBlue};
  }
  .Favorable {
    background: #7fe1ec;
  }
  .Neutral {
    background: ${Palette.veryLightBlue};
  }
  .Unfavorable {
    background: ${Palette.softPink};
  }
  .VeryUnfavorable {
    background: ${Palette.darkPink};
  }
`;

const MultipleChoiceAnswerStyles = `
  .Choice0 {
    background: ${barGraphTheme["multiple-choice"][0].backgroundColor}
  }

  .Choice1 {
    background: ${barGraphTheme["multiple-choice"][1].backgroundColor}
  }

  .Choice2 {
    background: ${barGraphTheme["multiple-choice"][2].backgroundColor}
  }

  .Choice3 {
    background: ${barGraphTheme["multiple-choice"][3].backgroundColor}
  }

  .Choice4 {
    background: ${barGraphTheme["multiple-choice"][4].backgroundColor}
  }

  .Choice5 {
    background: ${barGraphTheme["multiple-choice"][5].backgroundColor}
  }

  .Choice6 {
    background: ${barGraphTheme["multiple-choice"][6].backgroundColor}
  }

  .Choice7 {
    background: ${barGraphTheme["multiple-choice"][7].backgroundColor}
  }

  .Choice8 {
    background: ${barGraphTheme["multiple-choice"][8].backgroundColor}
  }

  .Choice9 {
    background: ${barGraphTheme["multiple-choice"][9].backgroundColor}
  }

  .Choice10 {
    background: ${barGraphTheme["multiple-choice"][10].backgroundColor}
  }

  .Choice11 {
    background: ${barGraphTheme["multiple-choice"][11].backgroundColor}
  }

  .Choice12 {
    background: ${barGraphTheme["multiple-choice"][12].backgroundColor}
  }

  .Choice13 {
    background: ${barGraphTheme["multiple-choice"][13].backgroundColor}
  }

  .Choice14 {
    background: ${barGraphTheme["multiple-choice"][14].backgroundColor}
  }
`;

const EnpsChoiceAnswerStyles = `
  .EnpsChoice0 {
    background: ${barGraphTheme.enps[10].backgroundColor}
  }

  .EnpsChoice1 {
    background: ${barGraphTheme.enps[9].backgroundColor}
  }

  .EnpsChoice2 {
    background: ${barGraphTheme.enps[8].backgroundColor}
  }

  .EnpsChoice3 {
    background: ${barGraphTheme.enps[7].backgroundColor}
  }

  .EnpsChoice4 {
    background: ${barGraphTheme.enps[6].backgroundColor}
  }

  .EnpsChoice5 {
    background: ${barGraphTheme.enps[5].backgroundColor}
  }

  .EnpsChoice6 {
    background: ${barGraphTheme.enps[4].backgroundColor}
  }

  .EnpsChoice7 {
    background: ${barGraphTheme.enps[3].backgroundColor}
  }

  .EnpsChoice8 {
    background: ${barGraphTheme.enps[2].backgroundColor}
  }

  .EnpsChoice9 {
    background: ${barGraphTheme.enps[1].backgroundColor}
  }

  .EnpsChoice10 {
    background: ${barGraphTheme.enps[0].backgroundColor}
  }
`;

const AnswerContainer = styled.div<AnswerProps>`
  ${LikertChoiceAnswerStyles}
  ${EnpsChoiceAnswerStyles}
  ${MultipleChoiceAnswerStyles}
`;

const AnswerIndicator = styled.div`
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 8px;
`;

export const AnswerLabel = styled.span`
  font-family: Lato;
  font-size: 14px;
  color: ${Palette.darkBlueGrey};
`;
