import { Action } from "hyphen-lib/dist/domain/Action";
import {
  getAllowedNextStatuses,
  generateActionForCategory,
  generateActionForSegment,
  generateActionForSurveyCategory,
  generateActionForSurveyComment,
  generateActionForPulsePollComment,
  generateActionForSurveyQuestion,
  generateActionForSurveySegment,
  generateActionForPulsePollSegment,
  generateActionForVoicePost,
  generateActionForVoiceComment,
  generateActionForIndividualResult
} from "hyphen-lib/dist/business/action/Actions";
import { uniqBy } from "lodash";
import qs from "qs";
import { Map } from "immutable";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";

import { CreateActionPayload } from "../store/actions";

export function getActionStatuses(
  currentStatus: Action.Status,
  includeCurrentStatus: boolean
): { value: Action.Status; label: string }[] {
  const allowedStatuses = getAllowedNextStatuses(currentStatus);
  let allStatuses = [
    { value: Action.Status.TODO, label: getStatusLabel(Action.Status.TODO) },
    { value: Action.Status.IN_PROGRESS, label: getStatusLabel(Action.Status.IN_PROGRESS) },
    { value: Action.Status.COMPLETED, label: getStatusLabel(Action.Status.COMPLETED) },
    { value: Action.Status.DISMISSED, label: getStatusLabel(Action.Status.DISMISSED) },
  ];

  allStatuses = allStatuses.filter(status => (status.value === currentStatus && includeCurrentStatus)
    || allowedStatuses.includes(status.value));

  return uniqBy(allStatuses, "value");
}

export function getStatusLabel(key: Action.Status): string {
  switch (key) {
    case Action.Status.TODO:
      return "To Do";
    case Action.Status.IN_PROGRESS:
      return "In Progress";
    case Action.Status.COMPLETED:
      return "Completed";
    case Action.Status.DISMISSED:
      return "Dismissed";
    default:
      return key;
  }
}

export function generateAction(
  source: Action.Source,
  payload: Dictionary<any>
): CreateActionPayload {
  const { context, label, type } = source;

  const {
    surveyId,
    surveyName,
    pulsePollId,
    pulsePollName,
    voicePollId,
    pollName,
    commentId,
    questionId,
    category: contextCategory,
    dimension,
    segment,
    focusAreaId,
  } = context;
  const {
    action,
    dueDate,
    assignee,
    category,
    createdBy,
    description,
    resource,
    actionPlanTemplateId,
    notifications,
    okr,
  } = payload;

  const actionMapper = Map({
    [Action.SourceType.CATEGORY]: generateActionForCategory(
      action,
      assignee,
      dueDate,
      createdBy,
      category,
      description,
      resource,
      actionPlanTemplateId,
      notifications,
      okr,
      focusAreaId,
    ),
    [Action.SourceType.SEGMENT]: generateActionForSegment(
      action,
      assignee,
      dueDate,
      createdBy,
      dimension,
      segment,
      description,
      resource,
      actionPlanTemplateId,
      notifications,
      okr,
      focusAreaId,
    ),
    [Action.SourceType.SURVEY_CATEGORY]: generateActionForSurveyCategory(
      action,
      assignee,
      dueDate,
      createdBy,
      surveyId,
      surveyName,
      contextCategory,
      description,
      resource,
      actionPlanTemplateId,
      notifications,
      okr,
      focusAreaId,
    ),
    [Action.SourceType.SURVEY_COMMENT]: generateActionForSurveyComment(
      action,
      assignee,
      dueDate,
      createdBy,
      label,
      commentId,
      questionId,
      surveyId,
      surveyName,
      contextCategory,
      description,
      resource,
      actionPlanTemplateId,
      notifications,
      okr,
      focusAreaId,
    ),    
    [Action.SourceType.PULSE_POLL_COMMENT]: generateActionForPulsePollComment(
      action,
      assignee,
      dueDate,
      createdBy,
      label,
      commentId,
      questionId,
      pulsePollId,
      pulsePollName,
      contextCategory,
      description,
      resource,
      actionPlanTemplateId,
      notifications,
      okr,
      focusAreaId,
    ),
    [Action.SourceType.SURVEY_QUESTION]: generateActionForSurveyQuestion(
      action,
      assignee,
      dueDate,
      createdBy,
      label,
      questionId,
      surveyId,
      surveyName,
      contextCategory,
      description,
      resource,
      actionPlanTemplateId,
      notifications,
      okr,
      focusAreaId,
    ),
    [Action.SourceType.SURVEY_SEGMENT]: generateActionForSurveySegment(
      action,
      assignee,
      dueDate,
      createdBy,
      segment,
      dimension,
      surveyId,
      surveyName,
      description,
      resource,
      actionPlanTemplateId,
      notifications,
      okr,
      focusAreaId,
    ),
    [Action.SourceType.PULSE_POLL_SEGMENT]: generateActionForPulsePollSegment(
      action,
      assignee,
      dueDate,
      createdBy,
      segment,
      dimension,
      pulsePollId,
      pulsePollName, 
      description,
      resource,
      actionPlanTemplateId,
      notifications,
      okr,
      focusAreaId,
    ),
    [Action.SourceType.EMPLOYEE_VOICE_COMMENT]: generateActionForVoiceComment(
      action,
      assignee,
      dueDate,
      createdBy,
      label,
      commentId,  
      voicePollId,
      pollName,
      contextCategory,
      description,
      resource,
      actionPlanTemplateId,
      notifications,
      okr,
      focusAreaId,
    ),
    [Action.SourceType.EMPLOYEE_VOICE_POST]: generateActionForVoicePost(
      action,
      assignee,
      dueDate,
      createdBy,
      voicePollId,
      pollName,
      contextCategory,
      description,
      resource,
      actionPlanTemplateId,
      notifications,
      okr,
      focusAreaId,
    ),
    [Action.SourceType.INDIVIDUAL_RESULT]: generateActionForIndividualResult(
      action,
      assignee,
      dueDate,
      createdBy,
      questionId,
      surveyId,
      surveyName,
      contextCategory,
      description,
      resource,
      actionPlanTemplateId,
      notifications,
      okr,
      focusAreaId,
    ),
  });

  return actionMapper.get(type) as CreateActionPayload;
}

export function getActionsLink(status: Action.Status) {
  function updateStatusesFilter(changedArray: any[]) {
    let filter = { statuses: [] };
    filter = Object.assign({}, filter, {
      statuses: changedArray,
      assignedToMe: true,
    });
    return { filter };
  }

  const filterMapper = {
    [Action.Status.TODO]: updateStatusesFilter([ Action.Status.TODO ]),
    [Action.Status.IN_PROGRESS]: updateStatusesFilter([ Action.Status.IN_PROGRESS ]),
    [Action.Status.COMPLETED]: updateStatusesFilter([ Action.Status.COMPLETED ]),
    [Action.Status.DISMISSED]: updateStatusesFilter([ Action.Status.DISMISSED ]),
  };

  const queryParams = qs.stringify(filterMapper[status], { encode: false });

  return `/actioncenter/actions?${queryParams}&force=true`;
}
