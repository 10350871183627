import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import styled from "styled-components";

import { Dictionary } from "@hyphen-lib/domain/structure/Dictionary";
import { areEquals, getOr, isNotNullNorUndefined, mapAtIndexOr } from "@hyphen-lib/lang/Objects";
import SearchBarSection from "@screens/Insights/Survey/components/SearchBarSection";
import { SortParameter } from "@src/utils/networks";

import ViewOptions, { getViewOptionValuesFromLocation } from "@screens/Insights/components/ViewOptions";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { CompareWithOption } from "@screens/Insights/components/ViewOptions/components/CompareWith";
import { TableProps } from "antd/lib/table";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { QuestionResult } from "hyphen-lib/dist/domain/resource/report/common/QuestionResult";
import { Participation } from "hyphen-lib/dist/domain/common/Participation";
import {
  filterQuestions,
  sortSurveyQuestions
} from "hyphen-lib/dist/business/calculation/question/SurveyQuestionsReports";
import { AnonymityFiltered } from "@components/core/AnonymityFiltered";
import { extractViewOptionsKeyFromLabel, getViewOptionDefinitions } from "@src/utils/ViewOptions";
import { areArraysEqual, isEmpty } from "hyphen-lib/dist/lang/Arrays";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
// eslint-disable-next-line max-len
import { SurveyQuestionsReportResource } from "hyphen-lib/dist/domain/resource/survey/report/SurveyQuestionsReportResource";
import { QuestionType } from "hyphen-lib/dist/domain/common/QuestionType";
import SurveyReportHeader from "../SurveyReportHeader";
import QuestionsTable from "./components/QuestionsTable";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";

type Props = RouteComponentProps & {
  readonly surveyName: string;
  readonly participation: Participation;
  readonly enabledFilters: string[];
  readonly enabledCustomFilters?: string[];
  readonly enabledViewOptions: string[];
  readonly compareWithOptions: CompareWithOption[];
  readonly onTableChange?: TableProps<any>["onChange"];
  readonly hasActionCreationRight: boolean;
  readonly hasTopicsAccess: boolean;
  readonly postAndSurveySentiment?: boolean;
  readonly anonymityThreshold: number;
  readonly sort: SortParameter;
  readonly freeTextFilter: string;
  readonly questions: Loadable<SurveyQuestionsReportResource>;
  readonly isFiltersAvailable: boolean;
  readonly isMultiSelectQuestionEnabled: boolean;
  readonly surveyId: string;
  readonly onCreateFocusArea: (
    focusArea: Partial<FocusAreaResource>
  ) => void;
  readonly page: PageFilter;
};

interface QuestionsReportState {
  readonly viewOptions: {
    readonly driverImpact: boolean;
    readonly comparison: boolean;
    readonly compareWith: Optional<string>;
  };
}

export class QuestionsReport extends React.Component<Props, QuestionsReportState> {
  constructor(props: Props) {
    super(props);

    const viewOptions = getViewOptionValuesFromLocation(props.location);

    this.state = this.mapViewOptionsToState(
      viewOptions,
      props.compareWithOptions,
      this.getDefaultViewOptionValues()
    );
  }

  componentDidUpdate(prevProps: Props): void {
    const prevViewOptions = parseQueryString(prevProps.location.search).viewOptions;
    const currentViewOptions = parseQueryString(this.props.location.search).viewOptions;
    if (not(areEquals(prevViewOptions, currentViewOptions)) ||
      not(areArraysEqual(prevProps.compareWithOptions, this.props.compareWithOptions)) ||
      not(areArraysEqual(prevProps.enabledViewOptions, this.props.enabledViewOptions))) {
      this.setState(
        this.mapViewOptionsToState(
          getViewOptionValuesFromLocation(this.props.location),
          this.props.compareWithOptions,
          this.getDefaultViewOptionValues()
        )
      );
    }
  }

  getDefaultViewOptionValues = () => {
    return {
      driverImpact: true,
      comparison: true,
    };
  };

  mapViewOptionsToState = (viewOptions: Dictionary<any>,
    compareWithOptions: CompareWithOption[],
    defaultValues: Dictionary<boolean>) => {
    const compareWithMode = getOr(
      extractViewOptionsKeyFromLabel(viewOptions.compareWith, compareWithOptions),
      mapAtIndexOr(compareWithOptions, 0, o => o.key, null) as string
    );

    return {
      viewOptions: {
        driverImpact: getOr(viewOptions.driverImpact, defaultValues.driverImpact),
        comparison:
          isNotNullNorUndefined(compareWithMode) &&
          getOr(viewOptions.comparison, defaultValues.comparison),
        compareWith: compareWithMode,
      },
    };
  };

  handleViewOptionsChange = (viewOptions: Dictionary<any>) => {
    this.setState(
      this.mapViewOptionsToState(
        viewOptions,
        this.props.compareWithOptions,
        this.getDefaultViewOptionValues()
      )
    );
  };

  getViewOptionsComponent = (enabledViewOptions: string[],
    compareWithOptions: CompareWithOption[]): React.ReactNode => {

    const viewOptions = getViewOptionDefinitions(enabledViewOptions, compareWithOptions);
    if (isEmpty(viewOptions)) {
      return null;
    }

    return (
      <ViewOptions
        viewOptions={viewOptions}
        defaultValues={this.state.viewOptions}
        onChange={this.handleViewOptionsChange}
      />
    );
  };

  render() {
    const {
      surveyName,
      participation,
      questions,
      enabledFilters,
      enabledCustomFilters,
      enabledViewOptions,
      compareWithOptions,
      onTableChange,
      sort,
      freeTextFilter,
      hasActionCreationRight,
      hasTopicsAccess,
      anonymityThreshold,
      isFiltersAvailable,
      isMultiSelectQuestionEnabled,
      surveyId,
      page,
      location,
      match,
      history,
      postAndSurveySentiment
    } = this.props;

    const routeProps = { location, match, history };

    const {
      viewOptions,
    } = this.state;

    let sortedQuestions: QuestionResult[] = [];
    let isSurveyOnlyMultiSelect = true;
    if (Loadable.isLoaded(questions)) {
      const filteredQuestions = filterQuestions(freeTextFilter, questions.value.questions);
      sortedQuestions = sortSurveyQuestions(
        filteredQuestions,
        sort,
        viewOptions.compareWith
      );
      for (const question of sortedQuestions) {
        if (question.type !== QuestionType.MULTIPLE_CHOICE) {
          isSurveyOnlyMultiSelect = false;
          break;
        }
        if (
          !question.filteredForAnonymity &&
          question.type === QuestionType.MULTIPLE_CHOICE
        ) {
          if (!question.isMultiSelect) {
            isSurveyOnlyMultiSelect = false;
          }
        }
      }
    }

    return (
      <div>
        <SurveyReportHeader
          participation={participation}
          enabledFilters={enabledFilters}
          enabledCustomFilters={enabledCustomFilters}
          viewOptionsComponent={this.getViewOptionsComponent(enabledViewOptions, compareWithOptions)}
          exportOption="questions"
        />
        {
          this.renderQuestions(
            surveyName,
            questions,
            hasActionCreationRight,
            hasTopicsAccess,
            onTableChange,
            sortedQuestions,
            viewOptions,
            compareWithOptions,
            sort,
            anonymityThreshold,
            isFiltersAvailable,
            isSurveyOnlyMultiSelect && isMultiSelectQuestionEnabled,
            surveyId,
            page,
            routeProps,
            postAndSurveySentiment
          )
        }
      </div>
    );
  }

  // noinspection JSMethodCanBeStatic
  renderQuestions(surveyName: string,
    questions: Loadable<SurveyQuestionsReportResource>,
    hasActionCreationRight: boolean,
    hasTopicsAccess: boolean,
    onTableChange: TableProps<any>["onChange"],
    sortedQuestions: QuestionResult[],
    viewOptions: QuestionsReportState["viewOptions"],
    compareWithOptions: CompareWithOption[],
    sort: SortParameter,
    anonymityThreshold: number,
    isFiltersAvailable: boolean,
    isSurveyOnlyMultiSelect: boolean,
    surveyId: string,
    page: PageFilter,
    routeProps:  RouteComponentProps,
    postAndSurveySentiment?: boolean
  ) {

    if (Loadable.isLoaded(questions) && questions.value.filteredForAnonymity) {
      return (
        <HighlightsContainer filters={isFiltersAvailable} >
          <AnonymityContainer>
            <AnonymityFiltered
              isFiltersAvailable={isFiltersAvailable}
              explanation={questions.value.filteredForAnonymityReason!}
              anonymityThreshold={anonymityThreshold}
              translate="yes"
            />
          </AnonymityContainer>
        </HighlightsContainer>
      );
    }

    return <>
      <QuestionsContainer>
        <SearchBarSection
          placeholder="Search questions"
          queryParamPath="freeText"
        />
        {Loadable.isNotLoaded(questions) ?
          <LoadingContainer>Loading...</LoadingContainer> :
          <QuestionsTable
            onChange={onTableChange}
            tableName={`${surveyName} - Questions report`}
            questions={sortedQuestions}
            areDriverImpactsVisible={viewOptions.driverImpact && not(isSurveyOnlyMultiSelect)}
            areComparisonsVisible={viewOptions.comparison}
            compareWithOptions={compareWithOptions}
            comparisonKey={viewOptions.compareWith}
            sort={sort}
            hasActionCreationRight={hasActionCreationRight}
            hasTopicsAccess={hasTopicsAccess}
            anonymityThreshold={anonymityThreshold}
            onCreateFocusArea={this.props.onCreateFocusArea}
            focusAreaLabel={surveyName}
            surveyId={surveyId}
            page={page}
            postAndSurveySentiment={postAndSurveySentiment}
            {...routeProps}
          />
        }
      </QuestionsContainer>
    </>;
  }
}

const HighlightsContainer = styled.div<{ filters: boolean }>`
  margin-top: 24px;
  @media print {
    padding-top: ${props => props.filters ? "" : "50px"}
  }
`;

const QuestionsContainer = styled.div`
  margin-top: 24px;
`;

const LoadingContainer = styled.div`
  text-align: center;
  background: white;
  padding: 24px;
`;

const AnonymityContainer = styled.div`
  margin: 24px 0;
  padding: 24px 0;
  background: white;
`;

export default withRouter(QuestionsReport);
