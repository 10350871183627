import React from "react";
import styled from "styled-components";
import { withRouter, Redirect } from "react-router-dom";
import { SelectValue } from "antd/lib/select";
import { connect } from "react-redux";

import { Store } from "hyphen-lib/dist/util/store/Store";
import {
  MultipleChoiceQuestionReportResource
} from "hyphen-lib/dist/domain/resource/report/MultipleChoiceQuestionReportResource";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import {
  getOr,
  mapAtIndexOr,
  isNotNullNorUndefined,
  isNullOrUndefined,
  keys,
  isNotEmptyObject,
  areEquals
} from "hyphen-lib/dist/lang/Objects";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { isEmpty } from "hyphen-lib/dist/lang/Arrays";
import { PulsePollInfoResource } from "hyphen-lib/dist/domain/resource/PulsePollInfoResource";
import { QuestionType } from "hyphen-lib/dist/domain/common/QuestionType";
import { not } from "hyphen-lib/dist/lang/Booleans";

import { Loading } from "@src/screens/Insights/Survey/components/Loading";
import { State } from "@src/store/types";
import { getResourceById } from "@src/store/network/selectors";
import Answers from "@src/screens/Insights/Survey/components/QuestionHighlightReport/components/Answers";
import ViewOptions, {
  getViewOptionValuesFromLocation
} from "@src/screens/Insights/components/ViewOptions";
import { CompareWithOption } from "@src/screens/Insights/components/ViewOptions/components/CompareWith";
import {
  generateCompareWithOptions
} from "@src/utils/Comparisons";
import {
  getCompany,
  getDimensions,
  getRightsMatcher
} from "@src/screens/Insights/store/selectors";
import { getViewOptionDefinitions } from "@src/utils/ViewOptions";
import { replaceLocation, goTo } from "@src/utils/locations";
import { getAnonymityThreshold } from "@src/screens/Insights/Settings/store/selectors";
import CommentSentiment from "@src/screens/Insights/components/Reports/CommentSentiment";
import { checkForSurveyReportRight } from "@src/utils/rights";
import {
  fetchPollMultipleChoiceQuestionReportIfNeeded
} from "@src/store/network/resource/MultipleChoiceQuestionReportResources";
import { getParameters } from "@src/screens/Insights/parameters/store/selectors";
import { applyExistingParametersIfNeeded } from "@src/utils/parameters";
import ContainerCard from "@src/components/core/ContainerCard";
import NoResult from "@src/components/core/NoResult";

import { AnonymityFilterExplanation } from "hyphen-lib/dist/domain/common/AnonymityFilterExplanation";
import VotesSentiment, { getBarConfigForRendering } from "@src/screens/Insights/components/Reports/VotesSentiment";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { FetchError } from "@src/screens/Insights/errors/FetchError";
import { ParticipationReportResource } from "hyphen-lib/dist/domain/resource/survey/report/ParticipationReportResource";
import { fetchPulsePollParticipationIfNeeded } from "@src/store/network/resource/ParticipationReportResources";
import { PULSE_POLL_RESULTS_REPORTS_FILTER_MAPPINGS } from "../../components/ReportHeader";
import { PulsePollsAnonymity } from "../../components/PulsePollsAnonymity";
import { PulsePollReportHeader } from "../PulsePollReportHeaderContainer";
import {
  PulsePollsResultsProps,
  PulsePollsResultsOwnProps,
  PulsePollsResultsReduxStateProps,
  PulsePollsResultsState
} from "./types";

export class PulsePollsResultsContainer extends React.Component<
PulsePollsResultsProps,
PulsePollsResultsState
> {
  constructor(props: PulsePollsResultsProps) {
    super(props);

    const viewOptions = getViewOptionValuesFromLocation(props.location);

    this.state = this.mapViewOptionsToState(
      viewOptions,
      props.compareWithOptions,
      this.getDefaultViewOptionValues()
    );
  }

  componentDidMount() {
    const {
      parameters,
      location: { search },
    } = this.props;
    const existing = parseQueryString(search);
    const mergedParameters =
      applyExistingParametersIfNeeded(
        parameters.toJS(),
        existing,
        ...PULSE_POLL_RESULTS_REPORTS_FILTER_MAPPINGS,
        "viewOptions.compareWith",
        "viewOptions.comparison"
      );

    if (
      isNotNullNorUndefined(mergedParameters) &&
      isNotEmptyObject(mergedParameters)
    ) {
      replaceLocation(mergedParameters);
    } else {
      // fetch data only if we will stay on this page,
      // otherwise it will be fetched anyway in componentDidUpdate
      this.fetchQuestionReport();
      this.fetchPulsePollParticipation();
    }
  }

  componentDidUpdate(prevProps: PulsePollsResultsProps) {
    const {
      match: { params: { instanceId } },
    } = this.props;

    const oldParams = this.extractParametersFromQuery(prevProps);
    const newParams = this.extractParametersFromQuery(this.props);
    if (prevProps.compareWithOptions.length !== this.props.compareWithOptions.length) {
      this.setState(this.mapViewOptionsToState(
        this.state.viewOptions,
        this.props.compareWithOptions,
        this.getDefaultViewOptionValues()
      ));
    }
    if (
      instanceId !== prevProps.match.params.instanceId ||
      not(areEquals(oldParams, newParams))
    ) {
      this.fetchQuestionReport();
      this.fetchPulsePollParticipation();
    }
  }

  fetchPulsePollParticipation = () => {
    if (isNotEmptyObject(this.props.match)) {
      const  {
        match: {
          params: { templateId, instanceId },
        },
        onFetchParticipation,
      } = this.props;
      const queryParams = this.extractParametersFromQuery(this.props);
      onFetchParticipation(templateId, instanceId, queryParams);
    }
  };

  extractParametersFromQuery(props: PulsePollsResultsProps) {
    return Object.entries(parseQueryString(props.location.search))
      .filter(function filterByRelevantParams([key]) {
        if (key !== "segmentBy") {
          return true;
        }
        return false;
      })
      .reduce(
        function extractRelevantParametersForQuery(relevantParameters, [key, value]) {
          relevantParameters[key] = value;
          return relevantParameters;
        }, {} as Dictionary<any>
      );
  }

  fetchQuestionReport() {
    const {
      match: {
        params: { templateId, instanceId },
      },
      onFetchPollMultipleChoiceQuestionReportIfNeeded,
    } = this.props;
    const queryParams = this.extractParametersFromQuery(this.props);
    onFetchPollMultipleChoiceQuestionReportIfNeeded(templateId, instanceId, queryParams);
  }

  getDefaultViewOptionValues = () => {
    return {
      comparison: true,
    };
  };

  getViewOptionsComponent = (
    enabledViewOptions: string[],
    compareWithOptions: CompareWithOption[]
  ): React.ReactNode => {
    const viewOptions = getViewOptionDefinitions(
      enabledViewOptions,
      compareWithOptions
    );
    if (isEmpty(viewOptions)) {
      return null;
    }

    return (
      <ViewOptions
        viewOptions={viewOptions}
        defaultValues={this.state.viewOptions}
        onChange={this.handleViewOptionsChange}
      />
    );
  };

  handleViewOptionsChange = (viewOptions: Dictionary<any>) => {
    this.setState(
      this.mapViewOptionsToState(
        viewOptions,
        this.props.compareWithOptions,
        this.getDefaultViewOptionValues()
      )
    );
  };

  mapViewOptionsToState = (
    viewOptions: Dictionary<any>,
    compareWithOptions: CompareWithOption[],
    defaultValues: Dictionary<boolean>
  ) => {
    const compareWithMode: Optional<string> = getOr(
      viewOptions.compareWith,
      mapAtIndexOr(compareWithOptions, 0, o => o.key, undefined)
    );

    return {
      viewOptions: {
        comparison:
          isNotNullNorUndefined(compareWithMode) &&
          getOr(viewOptions.comparison, defaultValues.comparison),
        compareWith: compareWithMode,
      },
    };
  };

  updateSegmentByInPath = (selectedDimension: SelectValue): void => {
    const { location } = this.props;
    const parameters = parseQueryString(location.search);
    parameters.segmentBy = selectedDimension;

    replaceLocation(parameters); // apply the new query string
  };

  goToComments = () => {
    goTo("comments");
  };

  renderResultsReport() {
    const {
      question,
      anonymityThreshold,
      selectedDimension,
      compareWithOptions,
      hasCommentsAccess,
      questionText,
      participation,
      dimensionsLabels
    } = this.props;
    const {
      viewOptions: { compareWith },
    } = this.state;
    if (Store.Element.isLoading(question) || Store.Element.isNotFound(question)) {
      return <Loading />;
    }

    if (Store.Element.isInError(question)) {
      return <FetchError {...question} resourceType={MultipleChoiceQuestionReportResource.TYPE}/>;
    }

    const questionValue = question.value;

    if (questionValue.filteredForAnonymity) {
      if ((questionValue.explanation as AnonymityFilterExplanation.NotEnoughVoters).numberOfVoters === 0) {
        return (
          <ContainerCard title="Results">
            <NoResult type="minimal" description={AnonymityFilterExplanation.Reason.NOT_ENOUGH_VOTERS} />
          </ContainerCard>
        );
      }
      return (
        <PulsePollsAnonymity
          anonymityThreshold={anonymityThreshold}
          explanation={questionValue.explanation}
        />
      );
    }
    const withScore = questionValue.type === MultipleChoiceQuestionReportResource.NonFilteredType.WITH_SCORE;
    let areComparisonsVisible = false;
    if (!questionValue.filteredForAnonymity) {
      if (
        withScore &&
        isNotEmptyObject((questionValue as MultipleChoiceQuestionReportResource.WithScore).availableComparisons)
      ) {
        areComparisonsVisible = true;
      }
    }

    const { choiceLabels, distribution } = getBarConfigForRendering(questionValue);

    return [
      withScore ? (
        <VotesSentiment
          key="distribution"
          overviewLabel={questionText}
          question={questionValue}
          areComparisonsVisible={areComparisonsVisible}
          compareWithOptions={compareWithOptions}
          comparisonKey={compareWith}
          hasCommentsAccess={hasCommentsAccess}
          onCommentsClick={this.goToComments}
        />
      ) : (
        <CommentSentiment
          key="distribution"
          questionText={questionText}
          hasSentimentScore
          netSentimentScore={questionValue.votesSentimentSummary.netSentimentScore}
          numberOfComments={questionValue.votesSentimentSummary.numberOfComments}
          choiceLabels={choiceLabels}
          distribution={distribution}
          totalVotes={questionValue.votesSentimentSummary.numberOfVotes}
          onCommentsClick={this.goToComments}
        />
      ),
      <Answers
        question={questionValue}
        key="segments"
        title="Segmentation"
        dimensionsLabels={dimensionsLabels}
        choiceLabels={questionValue.votesSentimentSummary.choiceLabels}
        selectedDimension={selectedDimension}
        updateSegmentByInPath={this.updateSegmentByInPath}
        anonymityThreshold={anonymityThreshold}
        participationBySegments={participation}
        dimensions={questionValue.segmentsSummary.dimensions}
      />,
    ];
  }

  render() {
    const {
      compareWithOptions,
      questionType,
      match: {
        params: { templateId, instanceId },
      },
    } = this.props;

    if (questionType === QuestionType.OPEN_TEXT) {
      return (
        <Redirect
          to={`/pulsePolls/${templateId}/instances/${instanceId}/reports/overview`}
        />
      );
    }
    return (
      <>
        <PulsePollReportHeader
          enabledFilters={["dimension"]}
          enabledCustomFilters={["addDimension"]}
          viewOptionsComponent={this.getViewOptionsComponent(
            ["comparison", "compareWith"],
            compareWithOptions
          )}
        />
        <Container>{this.renderResultsReport()}</Container>
      </>
    );
  }
}

function mapStateToProps(
  state: State,
  ownProps: PulsePollsResultsOwnProps
): PulsePollsResultsReduxStateProps {
  const {
    match: {
      params: { instanceId, templateId },
    },
    location,
  } = ownProps;
  const filter = parseQueryString(location.search).filter;
  const question = getResourceById(
    state,
    MultipleChoiceQuestionReportResource.TYPE,
    MultipleChoiceQuestionReportResource.generateId(
      { instanceId, templateId },
      filter
    )
  );
  const pulsePollInfoElement = getResourceById(
    state,
    PulsePollInfoResource.TYPE,
    templateId
  );
  const pulsePollInfo = Store.Element.toLoadable(pulsePollInfoElement);
  const questionType = Loadable.mapOr(
    pulsePollInfo,
    ppi => ppi.type,
    {} as QuestionType
  );
  const questionText = Loadable.mapOr(
    pulsePollInfo,
    ppi => ppi.question,
    ""
  );
  const company = getCompany(state)!;
  const compareWithOptions = generateCompareWithOptions(
    Store.Element.mapIfLoadedOr(
      question,
      q => q.filteredForAnonymity ?
        {} :
        q.type === MultipleChoiceQuestionReportResource.NonFilteredType.WITH_SCORE ?
          (q ).availableComparisons :
          {},
      {}
    ),
    company,
    true
  );
  let { segmentBy: selectedDimension } = parseQueryString(location.search);
  if (isNullOrUndefined(selectedDimension)) {
    selectedDimension = Store.Element.mapIfLoadedOr(
      question,
      q => q.filteredForAnonymity ? "" : keys(q.segmentsSummary.dimensions).first(""),
      ""
    );
  }

  return {
    question,
    anonymityThreshold: getAnonymityThreshold(state),
    compareWithOptions,
    questionType,
    selectedDimension,
    hasCommentsAccess: checkForSurveyReportRight(getRightsMatcher(state), "comments"),
    questionText,
    dimensionsLabels: getOr(getDimensions(state), {}),
    parameters: getParameters(state),
    participation: getResourceById(
      state,
      ParticipationReportResource.TYPE,
      ParticipationReportResource.generateId(
        ownProps.match.params.instanceId,
        parseQueryString(ownProps.location.search).filter
      )
    ),
  };
}

const mapDispatchToProps = {
  onFetchPollMultipleChoiceQuestionReportIfNeeded: fetchPollMultipleChoiceQuestionReportIfNeeded,
  onFetchParticipation: fetchPulsePollParticipationIfNeeded,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PulsePollsResultsContainer));

const Container = styled.div`
  margin: 24px 0;
  display: grid;
  grid-row-gap: 24px;

  /* for IE and old edge support */
  display: -ms-grid;
  -ms-grid-row-gap: 24px;
`;
