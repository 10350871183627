import { State as RootState } from "@store/types";
import { Seq } from "immutable";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { getResourcesStore } from "@store/network/Stores";
import { UsedResources } from "@store/network/ResourceStoresDefinitions";
import { ALL_PAGE_ID } from "@src/utils/networks";
import { UNTYPED_TYPE } from "@store/network/actions";

/**
 * Gets all the existing resources of a specified type.
 *
 * We don't care if the resources are not found, loading or loaded, we are just streaming what we have.
 *
 * @param {State} state
 * @param {R} type
 * @return {Seq.Indexed<UsedResources[R]>}
 */
export function getResources<R extends keyof UsedResources>(state: RootState,
  type: R): Seq.Indexed<UsedResources[R]> {

  // Note: cast is required since ts migration to 3.5.3, seems to be a problem of inference...
  return getResourcesStore(state.network, type).stream() as Seq.Indexed<UsedResources[R]>;
}

/**
 * Gets the specified resource.
 *
 * @param {State} state
 * @param {R} type
 * @param {string} id
 * @return {Store.Element<UsedResources[R]>}
 */
export function getResourceById<R extends keyof UsedResources>(state: RootState,
  type: R,
  id: string): Store.Element<UsedResources[R]> {
  // Note: cast is required since ts migration to 3.5.3, seems to be a problem of inference...
  return getResourcesStore(state.network, type).getElement(id) as Store.Element<UsedResources[R]>;
}

/**
 * Gets the specified untyped data.
 *
 * @param {State} state
 * @param {string} id
 * @return {Store.Element<UsedResources[R]>}
 */
export function getUntypedElementById<R>(state: RootState,
  id: string): Store.Element<R> {

  // Note: cast is required since ts migration to 3.5.3, seems to be a problem of inference...
  return getResourcesStore(state.network, UNTYPED_TYPE).getElement(id) as Store.Element<R>;
}

/**
 * Gets the specified page from the store for the specified resource.
 *
 * @param {State} state
 * @param {R} type
 * @param {string} key
 * @param {PageFilter} page
 * @return {Store.Page<UsedResources[R]>}
 */
export function getExistingPage<R extends keyof UsedResources>(state: RootState,
  type: R,
  key: string,
  page: PageFilter): Store.Page<UsedResources[R]> {

  // Note: cast is required since ts migration to 3.5.3, seems to be a problem of inference...
  return getResourcesStore(state.network, type).getPage(key, page) as Store.Page<UsedResources[R]>;
}

/**
 * Gets the unique page for the key, as we will not use pagination for this.
 *
 * @param {State} state
 * @param {R} type
 * @param {string} key
 * @return {Store.Page<UsedResources[R]>}
 */
export function getExistingNotPaginatedPage<R extends keyof UsedResources>(
  state: RootState,
  type: R,
  key: string): Store.Page<UsedResources[R]> {

  // Note: cast is required since ts migration to 3.5.3, seems to be a problem of inference...
  return getResourcesStore(state.network, type)
    .getPage(key, PageFilter.noPagination()) as Store.Page<UsedResources[R]>;
}

/**
 * Gets the unique page containing all resources for this type.
 *
 * @param {State} state
 * @param {R} type
 * @return {Store.Page<UsedResources[R]>}
 */
export function getExistingAllPage<R extends keyof UsedResources>(state: RootState,
  type: R): Store.Page<UsedResources[R]> {

  // Note: cast is required since ts migration to 3.5.3, seems to be a problem of inference...
  return getResourcesStore(state.network, type)
    .getPage(ALL_PAGE_ID, PageFilter.noPagination()) as Store.Page<UsedResources[R]>;
}

/**
 * Gets the specified count from the store for the specified resource.
 *
 * @param {State} state
 * @param {R} type
 * @param {string} key
 * @return {Store.Count}
 */
export function getExistingCount<R extends keyof UsedResources>(state: RootState,
  type: R,
  key: string): Store.Count {

  return getResourcesStore(state.network, type).getCount(key);
}

export function extractDataAndTotalFromPage<T>(page: Store.Page<T>): { data: T[]; total: number } {
  return {
    data: Store.Page.isLoaded(page) ? page.data : [],
    total: page.totalDocuments,
  };
}
