import React from "react";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps } from "react-router";
import { SelectValue } from "antd/lib/select";
import { Optional } from "@hyphen-lib/lang/Optionals";
import { Dimensions } from "@hyphen-lib/domain/common/Dimensions";
import { generateComputedHeatMapForDimension } from "@hyphen-lib/business/calculation/heatMap/ComputedHeatMaps";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { ParticipationReportResource } from "hyphen-lib/dist/domain/resource/survey/report/ParticipationReportResource";

import { State } from "@store/types";
import { getCompany, getDimensions } from "@screens/Insights/store/selectors";
import SelectDimension from "@screens/Insights/Survey/components/SelectDimension";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { generateCompareWithOptions, getAvailableComparisons } from "@src/utils/Comparisons";
import { replaceLocation } from "@src/utils/locations";
import { not } from "hyphen-lib/dist/lang/Booleans";
import {
  areEquals,
  getAtIndexOr,
  getOr,
  isEmptyObject,
  isNotEmptyObject,
  isNotNullNorUndefined
} from "hyphen-lib/dist/lang/Objects";
import { Map as ImmutableMap } from "immutable";
import { applyExistingParametersIfNeeded, PropMapping } from "@src/utils/parameters";
import { getParameters } from "@screens/Insights/parameters/store/selectors";
import { getAnonymityThreshold } from "@screens/Insights/Settings/store/selectors";
import { Participation } from "hyphen-lib/dist/domain/common/Participation";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { getResourceById } from "@store/network/selectors";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { RawHeatMapResource } from "hyphen-lib/dist/domain/resource/report/RawHeatMapResource";
import { ComputedHeatMap } from "hyphen-lib/dist/domain/aggregate/calculation/ComputedHeatMap";
import { parametersActionCreators } from "@src/screens/Insights/parameters/store/actions";
import { fetchRawHeatMapIfNeeded } from "@store/network/resource/RawHeatMapResources";
import { FetchError } from "@src/screens/Insights/errors/FetchError";
import { fetchSurveyParticipationIfNeeded } from "@store/network/resource/ParticipationReportResources";
import HeatmapReport from "../../components/HeatmapReport";
import { Loading } from "../../components/Loading";

interface MatchParams {
  id: string;
  dimension: string;
}

export interface OwnProps extends RouteComponentProps<MatchParams> {
  readonly surveyId: string;
  readonly surveyName: string;
  readonly participation: Participation;
}

interface HeatmapReportContainerStateProps {
  readonly dimensions: Optional<Dimensions>;
  readonly rawHeatMap: Store.Element<RawHeatMapResource>;
  readonly company: CompanyResource;
  readonly parameters: ImmutableMap<string, any>;
  readonly anonymityThreshold: number;
  readonly participationResource: Store.Element<ParticipationReportResource>;
}

interface HeatmapReportContainerActionProps {
  readonly onModifyParameters: (parameters: Dictionary<any>, mappings?: PropMapping[]) => void;
  readonly onFetchHeatMap: (surveyId: string, queryString: Dictionary<any>) => void;
  readonly onFetchParticipation: (surveyId: string, queryString: Dictionary<any>) => void;
}

type Props =
  OwnProps &
  HeatmapReportContainerStateProps &
  HeatmapReportContainerActionProps;

export class HeatmapReportContainer extends React.Component<Props> {

  // noinspection JSMethodCanBeStatic
  extractParametersFromQuery(props: Props) {
    return this.extractRelevantParametersForQuery(
      parseQueryString(props.location.search)
    );
  }

  // noinspection JSMethodCanBeStatic
  extractRelevantParametersForQuery(parameters: any) {
    const relevantParameters = { ...parameters };
    delete relevantParameters.viewOptions;
    delete relevantParameters.segmentBy;
    return relevantParameters;
  }

  componentDidMount(): void {
    // we might need to apply persisted parameters
    const {
      parameters,
      location: { search },
    } = this.props;

    const existing = parseQueryString(search);
    const mergedParameters =
      applyExistingParametersIfNeeded(
        parameters.toJS(),
        existing,
        "filter.dimensions",
        "viewOptions.comparison",
        "viewOptions.compareWith",
        "viewOptions.scoreOrDelta",
        "segmentBy"
      );

    if (isNotNullNorUndefined(mergedParameters) && isNotEmptyObject(mergedParameters)) {
      replaceLocation(mergedParameters);

      if (isEmptyObject(this.extractRelevantParametersForQuery(mergedParameters))) {
        // do the fetch now, otherwise we will not do it later, as componentDidUpdate will not see
        // any relevant changes, and not do any fetch
        this.fetchSurveyHeatmap();
        this.fetchSurveyParticipation();
      }
    } else {
      // fetch the heatmap only if we will stay on this page,
      // otherwise it will be fetched anyway in componentDidUpdate
      this.fetchSurveyHeatmap();
      this.fetchSurveyParticipation();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const oldParams = this.extractParametersFromQuery(prevProps);
    const newParams = this.extractParametersFromQuery(this.props);
    if (
      (this.props.match.params.id !== prevProps.match.params.id ||
      not(areEquals(oldParams, newParams))) &&
      not(Store.Element.isInError(this.props.rawHeatMap))
    ) {
      this.fetchSurveyHeatmap();
      this.fetchSurveyParticipation();
    }
  }

  fetchSurveyParticipation = () => {
    const surveyId = this.props.match.params.id;
    const queryParams = this.extractParametersFromQuery(this.props);
    this.props.onFetchParticipation(surveyId, queryParams);
  };

  fetchSurveyHeatmap = () => {
    const surveyId = this.props.match.params.id;
    const queryParams = this.extractParametersFromQuery(this.props);
    this.props.onFetchHeatMap(surveyId, queryParams);
  };

  updateSegmentByInPath = (selectedDimension: SelectValue): void => {
    const { location, onModifyParameters } = this.props;
    const parameters = parseQueryString(location.search);
    parameters.segmentBy = selectedDimension;

    onModifyParameters({ segmentBy: selectedDimension });

    replaceLocation(parameters); // apply the new query string
  };

  getSelectedDimension = () => {
    const { rawHeatMap } = this.props;
    const { segmentBy: selectedDimension } = parseQueryString(this.props.location.search);

    const firstAvailableDimension =
      Store.Element.mapIfLoadedOr(
        rawHeatMap,
        hm => getAtIndexOr(hm.dimensions, 0, "" as string),
        ""
      );

    return getOr(
      selectedDimension,
      firstAvailableDimension
    );
  };

  renderDimensionSelect = () => {
    const { rawHeatMap } = this.props;

    const dimensions = Store.Element.mapIfLoadedOr(rawHeatMap, hm => hm.dimensions, []);
    return (
      <SelectDimension
        data={dimensions}
        selected={this.getSelectedDimension()}
        dimensions={this.props.dimensions}
        onChangeDimension={this.updateSegmentByInPath}
      />
    );
  };

  render() {
    const {
      surveyId,
      surveyName,
      participation,
      rawHeatMap,
      company,
      anonymityThreshold,
      participationResource,
    } = this.props;

    const selectedDimension = this.getSelectedDimension();

    const loadableComputedHeatMap =
      Store.Element.mapIfLoadedOr(
        rawHeatMap,
        hm => Loadable.loaded(generateComputedHeatMapForDimension(hm, selectedDimension)),
        Loadable.loading() as Loadable<ComputedHeatMap>
      );

    const compareWithOptions = generateCompareWithOptions(
      Store.Element.mapIfLoadedOr(rawHeatMap, getAvailableComparisons, {}),
      company
    );
    if (Store.Element.isInError(rawHeatMap)) {
      return <FetchError {...rawHeatMap} resourceType={RawHeatMapResource.TYPE}/>;
    }
    if (Store.Element.isInError(participationResource)) {
      return <FetchError {...participationResource} resourceType={ParticipationReportResource.TYPE}/>;
    }

    if (Store.Element.isNotLoaded(participationResource)) {
      return <Loading/>;
    }

    const defaultScale : number = company.questionConfig.likert.defaultScale;
    const likertLabels = (company as any).questionConfig.likert[`labels${defaultScale}`];

    return (
      <HeatmapReport
        surveyId={surveyId}
        surveyName={surveyName}
        participation={participation}
        participationResource={participationResource.value}
        selectedDimension={selectedDimension}
        heatMap={loadableComputedHeatMap}
        enabledFilters={["dimension"]}
        enabledCustomFilters={["addDimension"]}
        enabledViewOptions={["comparison", "scoreOrDelta", "compareWith"]}
        compareWithOptions={compareWithOptions}
        selectComponent={this.renderDimensionSelect()}
        filteredForAnonymity={Store.Element.mapIfLoadedOr(rawHeatMap, hm => hm.filteredForAnonymity, false)}
        filteredForAnonymityReason={
          Store.Element.mapIfLoadedOr(rawHeatMap, hm => hm.filteredForAnonymityReason, Optional.empty())
        }
        anonymityThreshold={anonymityThreshold}
        likertLabels={likertLabels}
      />
    );
  }
}

const mapStateToProps: MapStateToProps<HeatmapReportContainerStateProps, OwnProps, State> = (
  state: State,
  ownProps: OwnProps
): HeatmapReportContainerStateProps => ({
  rawHeatMap: getResourceById(
    state,
    RawHeatMapResource.TYPE,
    RawHeatMapResource.generateId(
      parseQueryString(ownProps.location.search).filter,
      ownProps.surveyId
    )
  ),
  participationResource: getResourceById(
    state,
    ParticipationReportResource.TYPE,
    ParticipationReportResource.generateId(
      ownProps.match.params.id,
      parseQueryString(ownProps.location.search).filter
    )
  ),
  dimensions: getDimensions(state),
  company: getCompany(state)!,
  parameters: getParameters(state),
  anonymityThreshold: getAnonymityThreshold(state),
});

const mapDispatchToProps: HeatmapReportContainerActionProps = {
  onModifyParameters: parametersActionCreators.modifyParameters,
  onFetchHeatMap: fetchRawHeatMapIfNeeded,
  onFetchParticipation: fetchSurveyParticipationIfNeeded,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeatmapReportContainer);
