import React from "react";
import styled from "styled-components";

import { ExpandButton } from "@components/core/ExpandButton";
import Palette from "@src/config/theme/palette";
import { Heading } from "@components/core/Typography";
import { CustomizationPopover } from "@screens/Insights/components/CustomizationPopover";

interface Props {
  viewOptionsComponent?: React.ReactNode;
}

interface CategoryOptionsBarState {
  readonly areViewOptionsVisible: boolean;
}

class CategoryOptionsBar extends React.Component<Props, CategoryOptionsBarState> {
  state = {
    areViewOptionsVisible: false,
  };

  handleViewOptionsClick = () => {
    this.setState(state => ({ areViewOptionsVisible: !state.areViewOptionsVisible }));
  };

  render () {
    const { viewOptionsComponent } = this.props;
    const { areViewOptionsVisible } = this.state;

    return (
      <Container>
        <Heading size="large" translate="yes">
          Questions in this category
        </Heading>
        {
          viewOptionsComponent && areViewOptionsVisible &&
          <>
            <ActionButtons>
              <ExpandButton icon="viewOptions" onClick={this.handleViewOptionsClick} translate="yes">
                View options
              </ExpandButton>
            </ActionButtons>
            <Backdrop onClick={this.handleViewOptionsClick}/>
            <CustomizationPopover open={areViewOptionsVisible}>
              {viewOptionsComponent}
            </CustomizationPopover>
          </>
        }
      </Container>
    );
  }
}

const Container = styled.div`
  padding: 24px 32px;
  background: ${Palette.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const ActionButtons = styled.div`
  margin-left: 16px;
`;

const Backdrop = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
`;

export default CategoryOptionsBar;
