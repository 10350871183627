/* eslint-disable max-len */
export function OkrCardLogoSvg() {
    return (
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" style={{ position: "absolute" , left: 6 }} xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>0126670E-D254-4A9D-973B-ACA5E606D427</title>
            <defs>
                <circle id="path-1" cx="8" cy="8" r="8" />
                <filter x="-43.8%" y="-31.2%" width="187.5%" height="187.5%" filterUnits="objectBoundingBox" id="filter-2">
                    <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0" type="matrix" in="shadowBlurOuter1" />
                </filter>
            </defs>
            <g id="Action-planning-OKR-creation" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Create-AP-/-From-Scratch-/-Key-result-selected" transform="translate(-795.000000, -748.000000)">
                    <g id="Section-/-Create-AP-/-Add-to-OKR-vert" transform="translate(755.000000, 389.000000)">
                        <g id="Components-/-Kanban-card-/-Default-Copy-5" transform="translate(16.000000, 275.000000)">
                            <g id="Section-/-Chart-/-Person's-objectives-Copy" transform="translate(16.000000, 69.000000)">
                                <g id="Element-/-OKR-/-Card-type" transform="translate(12.000000, 17.000000)">
                                    <g id="Oval">
                                        <use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-1" />
                                        <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-1" />
                                    </g>
                                    <g id="Icon-/-Key-result-/-16px" transform="translate(3.500000, 3.500000)" fill="#3949AB">
                                        <path d="M7.6815,1.3185 L7.047,1.953 C7.695,2.6055 8.1,3.5055 8.1,4.5 C8.1,6.489 6.489,8.1 4.5,8.1 C2.511,8.1 0.9,6.489 0.9,4.5 C0.9,2.664 2.2725,1.152 4.05,0.9315 L4.05,1.8405 C2.772,2.0565 1.8,3.1635 1.8,4.5 C1.8,5.9895 3.0105,7.2 4.5,7.2 C5.9895,7.2 7.2,5.9895 7.2,4.5 C7.2,3.753 6.8985,3.078 6.408,2.592 L5.7735,3.2265 C6.0975,3.555 6.3,4.005 6.3,4.5 C6.3,5.4945 5.4945,6.3 4.5,6.3 C3.5055,6.3 2.7,5.4945 2.7,4.5 C2.7,3.663 3.276,2.9655 4.05,2.763 L4.05,3.726 C3.78,3.8835 3.6,4.167 3.6,4.5 C3.6,4.995 4.005,5.4 4.5,5.4 C4.995,5.4 5.4,4.995 5.4,4.5 C5.4,4.167 5.22,3.879 4.95,3.726 L4.95,0 L4.5,0 C2.016,0 0,2.016 0,4.5 C0,6.984 2.016,9 4.5,9 C6.984,9 9,6.984 9,4.5 C9,3.258 8.496,2.133 7.6815,1.3185 Z" id="Path" />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
  /* eslint-disable max-len */
