import { default as networkActionCreators } from "@store/network/actions";
import { createRequest, FilterParameter, SortParameter } from "@src/utils/networks";
import { appendQueryString, generateQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { TopicOverviewResource } from "hyphen-lib/dist/domain/resource/survey/report/TopicOverviewResource";
import { SurveyTopicCommentResource } from "hyphen-lib/dist/domain/resource/survey/report/SurveyTopicCommentResource";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";

export interface FetchTopicParameters {
  readonly filter?: FilterParameter;
  readonly sort?: SortParameter;
  readonly page: PageFilter;
}

export function fetchTopicsReportIfNeeded(surveyId: string,
  topicId: string, { filter, sort, page }: FetchTopicParameters) {
  return networkActionCreators.fetchPageWithPagination({
    id: TopicOverviewResource.generateKey(surveyId, topicId, filter, sort),
    type: TopicOverviewResource.TYPE,
    page,
    rawPageSize: 30,
    request: pageFilter => createRequest(
      appendQueryString(
        `/topics/overview?surveyId=${surveyId}`,
        generateQueryString({
          filter,
          sort,
          page: pageFilter,
        })
      )
    ),
  });
}

export function fetchTopicsOverviewIfNeeded(surveyId: string,
  queryString: Dictionary<any>) {
  return networkActionCreators.fetchElementIfNeeded({
    id: SurveyTopicCommentResource.generateKey(
      surveyId,
      queryString.filter
    ),
    type: SurveyTopicCommentResource.TYPE,
    request: createRequest(
      appendQueryString(
        `/surveys/${surveyId}/reports/topics/overview`,
        generateQueryString(queryString)
      )
    ),
  });
}