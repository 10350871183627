import { PulsePollResource } from "hyphen-lib/dist/domain/resource/PulsePollResource";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import { List as ImmutableList, Record } from "immutable";
import { StepProps } from "@src/components/core/Stepper";
import { utcNow } from "hyphen-lib/dist/lang/Dates";
import { reduceSteps } from "@src/utils/wizard/Steps";
import { isNotNullNorUndefined, areNotEquals } from "hyphen-lib/dist/lang/Objects";
import { Post } from "hyphen-lib/dist/domain/Post";
import { PostCategoryResource } from "hyphen-lib/dist/domain/resource/post/PostCategoryResource";
import { AccessResource } from "hyphen-lib/dist/domain/access/AccessResource";
import { getDisabledReducer } from "../utils/helpers";
import { getAllPulsePollStepDefinitions, getPulsePollMaxReachedStep } from "../utils/StepDefinitions";
import { PulsePollEditActionTypes } from "./pulsePollEditActions";
import { PulsePollDisabledFields } from "./pulsePollEditTypes";

export interface PulsePollEditStateProps {
  readonly pulsePoll: Loadable<PulsePollResource>;
  readonly updatedAt: Date;
  readonly disabled: PulsePollDisabledFields;
  readonly accesses: AccessResource[];
  readonly errors: WrongEntityException.Errors;
  readonly steps: ImmutableList<StepProps>;
  readonly hasPendingUpdates: boolean;
  readonly hasPendingAudienceUpdates: boolean;
  readonly hasPendingAccessesUpdates: boolean;
  readonly postCategories: PostCategoryResource[];

  readonly isPreviewModalOpen: boolean;
  readonly previewErrors: WrongEntityException.Errors;
}

export type PulsePollEditState = Record<PulsePollEditStateProps> & Readonly<PulsePollEditStateProps>;
export function pulsePollEditStateFactory(): PulsePollEditState {
  return Record<PulsePollEditStateProps>({
    pulsePoll: Loadable.loading(),
    updatedAt: utcNow(),
    disabled: {},
    accesses: [],
    errors: WrongEntityException.noErrors(),
    steps: reduceSteps(
      getAllPulsePollStepDefinitions(),
      0,
      WrongEntityException.noErrors()
    ),
    hasPendingUpdates: false,
    hasPendingAccessesUpdates: false,
    hasPendingAudienceUpdates: false,
    postCategories: [],
    previewErrors: WrongEntityException.noErrors(),
    isPreviewModalOpen: false,
  })();
}

const defaultState = pulsePollEditStateFactory();

/*
    ------ disabled field reduction -------
 */

function getDisabledFields(pulsePoll: PulsePollResource): PulsePollDisabledFields {
  let disabled: PulsePollDisabledFields = {};

  const getDisabledFieldsForDraftPulsePoll = getDisabledReducer<PulsePollDisabledFields>(disabled);
  const getDisabledFieldsForNonDraftPulsePoll = getDisabledReducer<PulsePollDisabledFields>(disabled);

  if (pulsePoll.status === Post.Status.DRAFT) {
    if (isNotNullNorUndefined(pulsePoll._id)) {
      disabled = getDisabledFieldsForDraftPulsePoll([
        // "questionType",
      ]);
    }
  } else {
    disabled = getDisabledFieldsForNonDraftPulsePoll([
      // settings
      "category",
      "questionType",
      "isAnonymous",

      // questions
      "question",
    ]);
    if (pulsePoll.status === Post.Status.DEACTIVATED) {
      const getDisabledFieldsForClosedPulsePoll = getDisabledReducer<PulsePollDisabledFields>(disabled);
      disabled = getDisabledFieldsForClosedPulsePoll([
        // settings
        "reminderLimit",
        "reminderInterval",
        "category",
        "numberOfDaysBeforeClosingInstance",

        // question
        "question",

        // audience
        "audience",
      ]);
    }
  }

  return disabled;
}

function reduceToggleManagersSynchronizationSuccess(
  pulsePoll: PulsePollResource,
  modifiedPulsePoll: Loadable<PulsePollResource>
): Partial<PulsePollEditState> {
  if (Loadable.isLoaded(modifiedPulsePoll)) {
    modifiedPulsePoll = Loadable.loaded({
      ...modifiedPulsePoll.value,
      areManagersSynchronized: pulsePoll.areManagersSynchronized,
    });
  }

  return  {
    pulsePoll: modifiedPulsePoll,
    hasPendingAccessesUpdates: false,
  };
}

function reduceFetchPulsePollSuccess(
  pulsePoll: PulsePollResource
): Partial<PulsePollEditState> {
  return {
    pulsePoll: Loadable.loaded(pulsePoll),
    updatedAt: pulsePoll.updatedAt,
    disabled: getDisabledFields(pulsePoll),
    steps: reduceSteps(
      getAllPulsePollStepDefinitions(),
      getPulsePollMaxReachedStep(pulsePoll) - 1,
      WrongEntityException.noErrors()
    ),
  };
}

function reduceHasPendingAudienceUpdates(
  hasPendingAudienceUpdates: boolean,
  previousPulsePoll: Loadable<PulsePollResource>,
  newPulsePoll: PulsePollResource
): boolean {
  if (hasPendingAudienceUpdates) {
    // some updates were already there so we still have some...
    return true;
  }

  if (Loadable.isNotLoaded(previousPulsePoll)) {
    // poll must obviously be loaded... Anyway, just assume the audience is updated in that case
    return true;
  }

  return areNotEquals(previousPulsePoll.value.audience, newPulsePoll.audience);
}

function reduceUpdateState(
  pulsePoll: PulsePollResource,
  state: PulsePollEditState
): Partial<PulsePollEditState> {
  return {
    pulsePoll: Loadable.loaded(pulsePoll),
    steps: reduceSteps(
      getAllPulsePollStepDefinitions(),
      getPulsePollMaxReachedStep(pulsePoll) - 1,
      state.get("errors")
    ),
    hasPendingUpdates: true,
    hasPendingAccessesUpdates: reduceHasPendingAudienceUpdates(
      state.get("hasPendingAccessesUpdates"),
      state.get("pulsePoll"),
      pulsePoll
    ),
  };
}

function reduceUpdateValidationErrors(
  errors: WrongEntityException.Errors,
  pulsePoll: Loadable<PulsePollResource>
): Partial<PulsePollEditState> {
  return {
    errors,
    steps: reduceSteps(
      getAllPulsePollStepDefinitions(),
      Loadable.mapOr(pulsePoll, pp => getPulsePollMaxReachedStep(pp) - 1, 0),
      errors
    ),
  };
}

function reduceUpdateOptimisticPulsePollSuccess(
  pulsePoll: PulsePollResource
): Partial<PulsePollEditState> {
  return {
    updatedAt: pulsePoll.updatedAt,
    pulsePoll: Loadable.loaded(pulsePoll),
    hasPendingUpdates: false,
    hasPendingAudienceUpdates: false,
  };
}

function reduceUpdatePessimisticPulsePollSuccess(
  pulsePoll: PulsePollResource
): Partial<PulsePollEditState> {
  return {
    updatedAt: pulsePoll.updatedAt,
    pulsePoll: Loadable.loaded(pulsePoll),
    hasPendingUpdates: false,
    hasPendingAudienceUpdates: false,
    hasPendingAccessesUpdates: false,
  };
}

function reduceState(state: PulsePollEditState) {
  return function returnModifiedState(
    func: (...args: any[]) => Partial<PulsePollEditState>,
    ...args: any[]
  ) {
    return state.merge(func(...args));
  };
}

/*
  ---- actions reducers ----
 */
export const pulsePollEditReducers = (
  state: PulsePollEditState = defaultState,
  action: any
): PulsePollEditState => {
  const applyReducer = reduceState(state);
  switch (action.type) {
    case PulsePollEditActionTypes.FETCH_PULSE_POLLS_SUCCESS:
    case PulsePollEditActionTypes.CREATE_PULSE_POLLS_SUCCESS:
      return applyReducer(
        reduceFetchPulsePollSuccess,
        action.payload.data
      );
    case PulsePollEditActionTypes.UPDATE_PULSE_POLL:
      return applyReducer(
        reduceUpdateState,
        action.payload.pulsePoll,
        state
      );
    case PulsePollEditActionTypes.UPDATE_PULSE_POLLS_SUCCESS: {
      const pulsePoll = action.payload.data as PulsePollResource;
      if (pulsePoll.status === Post.Status.DRAFT) {
        return applyReducer(reduceUpdateOptimisticPulsePollSuccess, pulsePoll);
      }
      return applyReducer(reduceUpdatePessimisticPulsePollSuccess, pulsePoll);
    }
    case PulsePollEditActionTypes.UPDATE_VALIDATION_ERRORS:
      return applyReducer(
        reduceUpdateValidationErrors,
        action.payload.errors as WrongEntityException.Errors,
        state.get("pulsePoll")
      );
    case PulsePollEditActionTypes.FETCH_POST_CATEGORIES_SUCCESS:
      return state.set("postCategories", action.payload.data);
    case PulsePollEditActionTypes.UPDATE_ACCESS_LIST:
      return state.merge({
        accesses: action.payload.accesses,
        hasPendingUpdates: true,
        hasPendingAccessesUpdates: true,
      });
    case PulsePollEditActionTypes.FETCH_PULSE_POLLS_ACCESS_LIST_SUCCESS:
      return state.merge({
        accesses: action.payload.data,
        hasPendingAccessesUpdates: false,
      });
    case PulsePollEditActionTypes.TOGGLE_MANAGERS_SYNCHRONIZATION_SUCCESS:
      return applyReducer(
        reduceToggleManagersSynchronizationSuccess,
        action.payload.data,
        state.get("pulsePoll")
      );
    case PulsePollEditActionTypes.TOGGLE_PREVIEW_MODAL_OPEN:
      return state.set("isPreviewModalOpen", action.payload.open);
    case PulsePollEditActionTypes.EXIT_PAGE:
      return pulsePollEditStateFactory();
    case PulsePollEditActionTypes.FETCH_PULSE_POLLS_ERROR:
      return state.setIn(["pulsePoll"], Loadable.inError({errorCode: action.payload.status, errorMessage: "" }));
    default:
      return state;
  }
};
