import { FSA } from "flux-standard-action";
import { createRequest } from "@src/utils/networks";
import {
  ActionTypes as NetworkActionTypes,
  NetworkRequestAction,
  NetworkEventRequestAction,
  NetworkEventSuccessAction,
  NetworkEventErrorAction
} from "@store/network/actions";
import { PrivateMessageContext } from "hyphen-lib/dist/domain/PrivateMessageContext";
import { PulsePollResource } from "hyphen-lib/dist/domain/resource/PulsePollResource";
import { Store } from "hyphen-lib/dist/util/store/Store";

export enum PollReportActionTypes {
  SEND_PRIVATE_MESSAGE_REQUEST = "pollReports/SEND_PRIVATE_MESSAGE_REQUEST",
  SEND_PRIVATE_MESSAGE_SUCCESS = "pollReports/SEND_PRIVATE_MESSAGE_SUCCESS",
  SEND_PRIVATE_MESSAGE_ERROR = "pollReports/SEND_PRIVATE_MESSAGE_ERROR",
  UPDATE_PRIVATE_MESSAGE_TEXT = "pollReports/UPDATE_PRIVATE_MESSAGE_TEXT",
  TOGGLE_PRIVATE_MESSAGE_MODAL = "pollReports/TOGGLE_PRIVATE_MESSAGE_MODAL",
  CLEAR_PRIVATE_MESSAGE_MODAL = "pollReports/CLEAR_PRIVATE_MESSAGE_MODAL",

  FETCH_POLL_ACCESSES_REQUEST = "pollReports/FETCH_POLL_ACCESSES_REQUEST",
  FETCH_POLL_ACCESSES_SUCCESS = "pollReports/FETCH_POLL_ACCESSES_SUCCESS",
  FETCH_POLL_ACCESSES_ERROR = "pollReports/FETCH_POLL_ACCESSES_ERROR",
}

interface Payloads {
  [PollReportActionTypes.SEND_PRIVATE_MESSAGE_REQUEST]: Request;
  [PollReportActionTypes.SEND_PRIVATE_MESSAGE_SUCCESS]: Response;
  [PollReportActionTypes.SEND_PRIVATE_MESSAGE_ERROR]: Error;
  [PollReportActionTypes.UPDATE_PRIVATE_MESSAGE_TEXT]: string;

  [PollReportActionTypes.FETCH_POLL_ACCESSES_REQUEST]: PollTemplateParameters;
  [PollReportActionTypes.FETCH_POLL_ACCESSES_SUCCESS]: Store.Page.Loaded<PulsePollResource>;
}

export interface SendPrivateMessageRequestAction
  extends FSA<
  PollReportActionTypes.SEND_PRIVATE_MESSAGE_REQUEST,
  Payloads[PollReportActionTypes.SEND_PRIVATE_MESSAGE_REQUEST]
  > {
  type: PollReportActionTypes.SEND_PRIVATE_MESSAGE_REQUEST;
  payload: Payloads[PollReportActionTypes.SEND_PRIVATE_MESSAGE_REQUEST];
}

export interface SendPrivateMessageSuccessAction
  extends FSA<
  PollReportActionTypes.SEND_PRIVATE_MESSAGE_SUCCESS,
  Payloads[PollReportActionTypes.SEND_PRIVATE_MESSAGE_SUCCESS]
  > {
  type: PollReportActionTypes.SEND_PRIVATE_MESSAGE_SUCCESS;
  payload: Payloads[PollReportActionTypes.SEND_PRIVATE_MESSAGE_SUCCESS];
}

export interface SendPrivateMessageErrorAction
  extends FSA<
  PollReportActionTypes.SEND_PRIVATE_MESSAGE_ERROR,
  Payloads[PollReportActionTypes.SEND_PRIVATE_MESSAGE_ERROR]
  > {
  type: PollReportActionTypes.SEND_PRIVATE_MESSAGE_ERROR;
  payload: Payloads[PollReportActionTypes.SEND_PRIVATE_MESSAGE_ERROR];
}

export interface PollTemplateParameters {
  readonly templateId: string;
}

export const sendPrivateMessageRequest = (
  instanceId: string,
  voteId: string,
  initialMessage: string,
  origin: string
) => {
  let context:
  | Partial<PrivateMessageContext.PulsePollVote>
  | Partial<PrivateMessageContext.PulsePollComment> = {
    type:
      origin === "Vote"
        ? PrivateMessageContext.Type.PULSE_POLL_VOTE
        : PrivateMessageContext.Type.PULSE_POLL_COMMENT,
    instanceId,
  };

  if (context.type === PrivateMessageContext.Type.PULSE_POLL_VOTE) {
    context = {...context, voteId};
  } else if (context.type === PrivateMessageContext.Type.PULSE_POLL_COMMENT) {
    context = {...context, commentId: voteId};
  }

  return createRequest("/privateConversations/start", {
    method: "POST",
    body: JSON.stringify({ context, postId: instanceId, initialMessage }),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

function createGetPulsePollAccessesRequest(pulsePollId: string) {
  return createRequest(`/pulsePolls/${pulsePollId}/accesses`);
}

export const pollReportActionCreators = {
  sendPrivateMessage: (
    instanceId: string,
    voteId: string,
    initialMessage: string,
    origin: string,
    onSuccessRedirect?: (payload: NetworkEventSuccessAction["payload"]) => void
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: sendPrivateMessageRequest(instanceId, voteId, initialMessage, origin),

    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: PollReportActionTypes.SEND_PRIVATE_MESSAGE_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({ type: PollReportActionTypes.SEND_PRIVATE_MESSAGE_SUCCESS, payload }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: PollReportActionTypes.SEND_PRIVATE_MESSAGE_ERROR, payload }),
      ],
      onSuccessRedirect,
    },
  }),
  updatePrivateMessageText: (payload: string) => ({
    type: PollReportActionTypes.UPDATE_PRIVATE_MESSAGE_TEXT,
    payload,
  }),
  clearPrivateMessageModal: () => ({
    type: PollReportActionTypes.CLEAR_PRIVATE_MESSAGE_MODAL,
  }),
  togglePrivateMessageModal: (
    templateId: string,
    postId: string,
    voteId: string,
    commentText: string,
    origin: string
  ) => ({
    type: PollReportActionTypes.TOGGLE_PRIVATE_MESSAGE_MODAL,
    payload: { templateId, postId, voteId, commentText, origin },
  }),

  fetchPulsePollAccesses: (
    templateId: string
  ): NetworkRequestAction => ({
    type: NetworkActionTypes.NETWORK_REQUEST,
    payload: createGetPulsePollAccessesRequest(templateId),
    meta: {
      onRequestActions: [
        (payload: NetworkEventRequestAction["payload"]) =>
          ({ type: PollReportActionTypes.FETCH_POLL_ACCESSES_REQUEST, payload }),
      ],
      onSuccessActions: [
        (payload: NetworkEventSuccessAction["payload"]) =>
          ({
            type: PollReportActionTypes.FETCH_POLL_ACCESSES_SUCCESS,
            payload,
          }),
      ],
      onErrorActions: [
        (payload: NetworkEventErrorAction["payload"]) =>
          ({ type: PollReportActionTypes.FETCH_POLL_ACCESSES_ERROR, payload }),
      ],
    },
  }),
};
