import {
  VoiceFlaggedCommentReportResource
} from "hyphen-lib/dist/domain/resource/voice/VoiceFlaggedCommentReportResource";
import {
  appendQueryString,
  generateQueryString
} from "hyphen-lib/dist/util/net/HttpClient";

import { default as networkActionCreators } from "@store/network/actions";
import { createRequest } from "@src/utils/networks";
import { FetchDataListParameters } from "@src/screens/Insights/components/DataListContainer/types";

export function fetchVoiceFlaggedCommentsIfNeeded({
  filter,
  sort,
  page,
}: FetchDataListParameters) {
  return networkActionCreators.fetchPageIfNeeded({
    id: VoiceFlaggedCommentReportResource.generateKey(filter),
    type: VoiceFlaggedCommentReportResource.TYPE,
    page,
    rawPageSize: 30,
    request: pageFilter =>
      createRequest(
        appendQueryString(
          "/voice/reports/comments/flagged",
          generateQueryString({
            filter,
            sort,
            page: pageFilter,
          })
        )
      ),
  });
}
