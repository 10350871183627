import { default as networkActionCreators } from "@store/network/actions";
import { VoicePostReportResource } from "hyphen-lib/dist/domain/resource/voice/VoicePostReportResource";
import { FilterParameter, SortParameter, createRequest } from "@src/utils/networks";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { appendQueryString, generateQueryString } from "hyphen-lib/dist/util/net/HttpClient";

export interface FetchVoicePostParameters {
  readonly filter?: FilterParameter;
  readonly sort?: SortParameter;
  readonly page: PageFilter;
}

export function fetchVoicePostsIfNeeded({ filter, sort, page }: FetchVoicePostParameters) {
  return networkActionCreators.fetchPageIfNeeded({
    id: VoicePostReportResource.generateKey(filter),
    type: VoicePostReportResource.TYPE,
    page,
    rawPageSize: 30,
    request: pageFilter => createRequest(
      appendQueryString(
        "/voice/reports/posts",
        generateQueryString({
          filter,
          sort,
          page: pageFilter,
        })
      )
    ),
  });
}
