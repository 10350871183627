import { freeze } from "hyphen-lib/dist/lang/Objects";
import { takeLatest, put, select } from "redux-saga/effects";
import notificationActionCreators, { ShowNotificationAction } from "@store/notifications/actions";
import {  SurveyListActionTypes, surveyListActionCreators } from "@screens/Insights/SurveyList/store/actions";
import {
  NetworkRequestAction,
  NetworkEventSuccessAction,
  CleanResourceAction,
  actionCreators as networkActionCreators
} from "@store/network/actions";
import { CommentsResultResource } from "hyphen-lib/dist/domain/resource/report/CommentsResultResource";
import { IndividualResultResource } from "hyphen-lib/dist/domain/resource/survey/report/IndividualResultResource";
import { actionPlansActionCreators } from "../../ActionPlans/store/actions";
import { getSurveyListStateProps } from "../../SurveyList/store/selectors";
import { SurveyReportActionTypes } from "./actions";
import * as NotificationFactory from "@src/store/notifications/notification-factory";

function* showSuccess() {
  yield put<ShowNotificationAction>(
    notificationActionCreators.displayNotification(
      NotificationFactory.success(
        "Private message sent successfully",
        "",
        4.5
      )
  ));

  yield put<CleanResourceAction>(
    networkActionCreators.cleanResource(CommentsResultResource.TYPE)
  );
  yield put<CleanResourceAction>(
    networkActionCreators.cleanResource(IndividualResultResource.TYPE)
  );
}

// Fixme: Add saga to refresh Survey Resource
export function* resetSurveyResource({ meta }: NetworkEventSuccessAction) {
  // Get the selectedSurveyResource:
  const { surveyId } = meta;
  yield put<NetworkRequestAction>(
    actionPlansActionCreators.fetchSurveyInfo(surveyId)
  );
}

export function* closeIndividualSurvey() {

  // Get the selectedSurveyResource:
  const { selectedSurveyInfoResource } = yield select(getSurveyListStateProps);
  const surveyId = selectedSurveyInfoResource.get("_id");

  yield put<NetworkRequestAction>(
    surveyListActionCreators.closeIndividualSurveyRequest(surveyId)
  );
}

export const surveyReportSagas = freeze([
  /* All sagas go here */
  takeLatest(
    SurveyReportActionTypes.SEND_PRIVATE_MESSAGE_SUCCESS,
    showSuccess
  ),

  takeLatest(
    SurveyListActionTypes.CLOSE_INDIVIDUAL_SURVEY,
    closeIndividualSurvey
  ),

  takeLatest(
    SurveyListActionTypes.CLOSE_SURVEY_RESOURCE_SUCCESS,
    resetSurveyResource
  ),
]);
