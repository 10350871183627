import React from "react";
import { VoicePostReportResource } from "hyphen-lib/dist/domain/resource/voice/VoicePostReportResource";
import { Paragraph } from "@src/components/core/Typography";
import styled from "styled-components";
import {
  isNotNullNorUndefined,
  isNotEmptyObject
} from "hyphen-lib/dist/lang/Objects";
import { getTypeLabel } from "../../../utils";

interface PostedDestinationProps {
  readonly group: VoicePostReportResource["group"];
}

export function PostedDestination({ group }: PostedDestinationProps) {
  return (
    <Container>
      {isNotNullNorUndefined(group) && isNotEmptyObject(group) ? (
        <>
          <Paragraph>{group.name}</Paragraph>
          <Paragraph weight="light" translate="yes">{getTypeLabel(group.type)}</Paragraph>
        </>
      ) : (
        <Paragraph weight="light" translate="yes">{getTypeLabel("all")}</Paragraph>
      )}
    </Container>
  );
}

const Container = styled.div`
  p:not(:first-of-type) {
    margin-top: 6px;
  }
`;

Container.displayName = "PostedDestination";
