import { Seq } from "immutable";
import { State } from "@store/types";
import { getResources, getResourceById } from "@store/network/selectors";
import { SurveyTemplateResource } from "hyphen-lib/dist/domain/resource/SurveyTemplateResource";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import {
  isEmptyObject,
  isNotNullNorUndefined,
  keys,
  subObject,
  addProperty,
  isNotEmptyObject,
  mapOr
} from "hyphen-lib/dist/lang/Objects";
import { Optional } from "@hyphen-lib/lang/Optionals";
import { Dimensions } from "@hyphen-lib/domain/common/Dimensions";
import { getCurrentUser, getDimensions } from "@screens/Insights/store/selectors";
import { CurrentUserResource } from "@hyphen-lib/domain/resource/user/CurrentUserResource";
import { SurveyTypeResource } from "hyphen-lib/dist/domain/resource/SurveyTypeResource";
import { SurveyResource } from "hyphen-lib/dist/domain/resource/SurveyResource";
import { Audience } from "hyphen-lib/dist/domain/common/Audience";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { PostCategoryResource } from "hyphen-lib/dist/domain/resource/post/PostCategoryResource";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { SurveyBankQuestionResource } from "hyphen-lib/dist/domain/resource/survey/SurveyBankQuestionResource";

export const getTemplates = (state: State): SurveyTemplateResource[] =>
  getResources(state, SurveyTemplateResource.TYPE).toArray();
export const getSurveyTypes = (state: State) => getResources(state, SurveyTypeResource.TYPE);
export const getPostCategorys = (state: State) => getResources(state, PostCategoryResource.TYPE);
export const getSurveyCreateStatus = (state: State) => state.getIn(["insights_surveys", "createSurvey", "isNewSurvey"]);

export const getSurveyTypeResourceForSurveyType =
(state: State, surveyId: SurveyResource["_id"]) : Optional<SurveyTypeResource> =>  {
  const survey = getResourceById(state, SurveyResource.TYPE, surveyId);
  if (Store.Element.isLoaded(survey)) {
    const type = getResourceById(state, SurveyTypeResource.TYPE, survey.value.type);
    if (Store.Element.isLoaded(type)) {
      return type.value;
    }
  }
};

export const getSurveyTypeStatusForSurveyType =
(state: State, surveyId: SurveyResource["_id"]) : Optional<string> =>  {
  return mapOr(
    getSurveyTypeResourceForSurveyType(state, surveyId),
    (surveyType: SurveyTypeResource) => surveyType.audienceConfiguration.statusRestriction,
    undefined
  );
};

export const getSurveyTypeAudienceConfig =
(state: State, surveyId: SurveyResource["_id"]) : Optional<Audience.Configuration> =>  {
  return mapOr(
    getSurveyTypeResourceForSurveyType(state, surveyId),
    (surveyType: SurveyTypeResource) => surveyType.audienceConfiguration,
    undefined
  );
};

export const getSurveyParticipants = (state: State) =>
  state.getIn(["insights_surveys", "audiencePage", "surveyParticipants"]);
export const getSurveyBankQuestions = (state: State) => getResources(state, SurveyBankQuestionResource.TYPE);

export const getManageesEmails = (state: State) => state.get("insights_surveys").get("manageesEmails");
export const access = (state: State) => state.get("insights_surveys").get("access");
export const isSurveyPreviewValidationModalOpen = (state: State) =>
  state.getIn(["insights_surveys", "isSurveyPreviewValidationModalOpen"]);
export const isPreviewModalOpen = (state: State) => state.getIn(["insights_surveys", "isPreviewModalOpen"]);
export const sendSurveyPreviewError = (state: State) => state.getIn(["insights_surveys", "sendSurveyPreviewError"]);
export const surveyPreviewErrorText = (state: State) => state.getIn(["insights_surveys", "surveyPreviewErrorText"]);
export const steppers = (state: State) => state.getIn(["insights_surveys", "steppers"]);
export const fetchSurveyDetails = (state: State) => state.getIn(["insights_surveys","survey"]);
export const getSurveyChannels = (state: State) => state.getIn(["insights_surveys", "survey", "channels"]);
export const getSurveyReminders = (state: State) => state.getIn(["insights_surveys", "survey", "nextReminderChannels"]);
export const getSurveyLaunchState = (state: State) => state.get("insights_surveys").get("surveyLaunchState");
export const fetchSurveyInfo = (state: State) => state.get("insights_surveys").get("surveyInfo");
export const fetchSurveyParticipantsCount =
  (state: State) => state.get("insights_surveys").get("surveyParticipantsCount");
export const fetchMergeTags = (state: State) => state.get("insights_surveys").get("mergeTags");
export const fetchFormErrors = (state: State) => state.get("insights_surveys").get("formErrors");
export const audience = (state: State) => state.get("insights_surveys").get("survey").get("audience");
export const audiencePage = (state: State) => state.get("insights_surveys").get("audiencePage");
export const surveyQuestionsPage = (state: State) => state.getIn(["insights_surveys", "questionsPage"]);

export const settingsPage = (state: State) => state.getIn(["insights_surveys_page", "settings"]);

export const fetchSurveyTemplates = (state: State) => state.get("insights_surveys").get("fetchSurveyTemplates");
export const surveyInfosState = (state: State) => state.getIn(["insights_surveys", "surveyInfos"]);

export const createSurvey = (state: State) => state.get("insights_surveys").get("createSurvey");

export const continueAlertModal = (state: State) => state.get("insights_surveys").get("continueAlert");
export const isCancelSurveyCreationModalOpen = (state: State) =>
  state.getIn(["insights_surveys", "isCancelSurveyCreationModalOpen"]);

/**
 * Gets allow SMS for the Current User
 *
 * @param {State} state
 * @return {boolean} allowSMS
 */
export function getAllowSMSPermission(state: State): boolean {
  return mapOr(
    getCurrentUser(state),
    (user: CurrentUserResource) =>
      mapOr(
        user.company,
        company => isNotNullNorUndefined(company.channels.sms) && company.channels.sms.isAllowed,
        false
      ),
    false
  );
}

/**
 * Gets all communication channels available for company
 *
 * @param {State} state
 * @return {boolean} allowSMS
 */
export function getChannels(state: State): CompanyResource["channels"] {
  return mapOr(
    getCurrentUser(state),
    (user: CurrentUserResource) =>
      mapOr(
        user.company,
        company => company.channels,
        {}
      ),
    { email: { isAllowed: true }}
  );
}

/**
 * Gets reportingDimMgmts for the Current User
 *
 * @param {State} state
 * @return {Optional<Dictionary<string[]>>}
 */
export function getReportingDimMgmts(state: State): Optional<Dictionary<string[]>> {
  return mapOr(
    getCurrentUser(state),
    (user: CurrentUserResource) => user.reportingDimMgmts,
    undefined
  );
}

/**
 * Gets Private Groups for the Current User
 *
 * @param {State} state
 * @return {Optional<Dictionary<string[]>>}
 */
export function getPrivateGroups(state: State): CurrentUserResource.PrivateGroup[] {
  return mapOr(
    getCurrentUser(state),
    (user: CurrentUserResource) => user.privateGroups,
    []
  );
}

/**
 * Gets the imposed dimensions & segments based on reportingDimMgmts.
 *
 * @param {State} state
 * @return {Optional<Dimensions>}
 */
export function getImposedDimensions(state: State): Optional<Dimensions> {
  const dimensions = getDimensions(state);
  const reportingDimMgmts = getReportingDimMgmts(state);
  if (isEmptyObject(reportingDimMgmts) || isEmptyObject(dimensions)) {
    return dimensions;
  } else {
    return imposeReportingDimMgmts(reportingDimMgmts!, dimensions!);
  }
}

/**
 * Pure function to impose reportingDimMgmts on dimensions.
 *
 * @param {Dictionary<string[]>} reportingDimMgmts
 * @param {Dimensions} dimensions
 * @return {Optional<Dimensions>}
 */

function imposeReportingDimMgmts(
  reportingDimMgmts: Dictionary<string[]>,
  dimensions: Dimensions) :  Optional<Dimensions>
{
  const dimensionKeys = keys(dimensions).toSet();

  const imposedDimension= subObject(Seq(dimensionKeys)
    .filter((key) => isNotEmptyObject(dimensions[key]))
    .reduce((acc, dimensionKey: string) => {
      let segments;
      if (isEmptyObject(reportingDimMgmts[dimensionKey])) {
        segments = dimensions[dimensionKey].segments;
      } else {
        segments = reportingDimMgmts[dimensionKey];
      }
      return addProperty(acc, dimensionKey, {
        ...dimensions[dimensionKey],
        segments,
      });
    },{}),
  (dimension) => isNotEmptyArray(dimension.segments));

  return imposedDimension;
}
