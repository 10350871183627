import { Record, RecordOf } from "immutable";
import { FilterParameter } from "@src/utils/networks";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import {
  CleanFilterAction,
  ModifyFilterAction,
  PulsePollReportsFiltersActionTypes
} from "./actions";

export interface PulsePollReportsFiltersStateProps {
  readonly filter: Optional<FilterParameter>;
}
export type PulsePollReportsFilterState = RecordOf<
PulsePollReportsFiltersStateProps
>;

export const pulsePollReportsFiltersDefaultValues: PulsePollReportsFiltersStateProps = {
  filter: Optional.empty(),
};

export const PulsePollReportsFiltersStateFactory = Record<
PulsePollReportsFiltersStateProps
>(pulsePollReportsFiltersDefaultValues);
const defaultState = PulsePollReportsFiltersStateFactory();

type Actions = ModifyFilterAction | CleanFilterAction;

export function pulsePollReportsFilterReducers(
  state: PulsePollReportsFilterState = defaultState,
  action: Actions
): PulsePollReportsFilterState {
  switch (action.type) {
    case PulsePollReportsFiltersActionTypes.MODIFY_FILTER:
      return state.set("filter", action.payload);
    case PulsePollReportsFiltersActionTypes.CLEAN_FILTER:
      return state.set("filter", Optional.empty());
    default:
      return state;
  }
}
