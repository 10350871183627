import { default as networkActionCreators } from "@store/network/actions";
import { createRequest } from "@src/utils/networks";
import { appendQueryString, generateQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { ParticipationReportResource } from "hyphen-lib/dist/domain/resource/survey/report/ParticipationReportResource";

export function fetchSurveyParticipationIfNeeded(surveyId: string, queryString: Dictionary<any>) {
  return networkActionCreators.fetchElementIfNeeded({
    id: ParticipationReportResource.generateId(surveyId, queryString.filter),
    type: ParticipationReportResource.TYPE,
    request: createRequest(
      appendQueryString(
        `/surveys/${surveyId}/reports/participation`,
        generateQueryString(queryString)
      )
    ),
  });
}

export function fetchPulsePollParticipationIfNeeded(
  templateId: string,
  iterationId: string,
  queryString: Dictionary<any>
) {
  return networkActionCreators.fetchElementIfNeeded({
    id: ParticipationReportResource.generateId(iterationId, queryString.filter),
    type: ParticipationReportResource.TYPE,
    request: createRequest(
      appendQueryString(
        `/pulsepolls/${templateId}/reports/questions/${iterationId}/participation`,
        generateQueryString(queryString)
      )
    ),
  });
}