import { getOr } from "hyphen-lib/dist/lang/Objects";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { QuestionType } from "hyphen-lib/dist/domain/common/QuestionType";

export enum Palette {
  aquaBlue = "#00c3d9",
  aquaBlue_50 = "rgba(0,195,217,0.5)",
  black = "#000000",
  white = "#ffffff",
  primary = "#01cade",
  darkPink = "#df3868",
  bluePurple = "#3559AE",
  bluePurple_10 = "rgba(53, 89, 174, 0.1)",
  bluePurple_40 = "rgba(53, 89, 174, 0.4)",
  darkBlueGrey = "#1c1e56",
  greenBlue = "#00d994",
  greenSuccess = "rgba(0,217,148,0.1)",
  greenBlue_40 = "rgba(0, 217, 148, 0.4)",
  lightAqua = "#9af0d5",
  softPink = "#f1a9be",
  paleGrey = "#f4f6f9",
  lightPeriwinkle = "#d1dbe8",
  lightPeriwinkle_80 = "rgba(209,219,232, 0.8)",
  veryLightBlue = "#e8edf3",
  lightBlue = "#d2edf0",
  lightGreyBlue = "#aab9ce",
  lightGrey = "#e6ebf1",
  veryDarkBlueGrey = "#213848",
  bluishGrey = "#7a8891",
  lightLightBlue = "#f2fcfd", // fixme: there is no name for this color in zeplin
  darkModerateBlue = "#3559ae",  // FIXME: there is no name for this color in zeplin
  darkDarkDarkBlueGrey = "#1e3342", // fixme: there is no name for this color in zeplin
  athensGrey = "#f9fafb",  // FIXME: there is no name for this color in zeplin
  hyperLinkBlue = "#1890ff",
  purple = "#6239BF",
  darkTurqoise = "#00c3d9",
  pattensBlue = "#E4ECF0",
  irisBlue = "#00B8CC",
  onahau = "#beebf1",
  alizarin = "#f5222d",
  dodgerBlue= "#2196f3",
  dimGray = "#666666",
  raven = "#6c757d",
  rockBlue = "#9dabbf",
  ceruleanBlue = "#3559AE",
  midnight = "#212B36",
  sunglow = "#FFC22F",
  background = "#f3f6f9",
  tequila = "#fce8ca",
  violet = "#365aad"
}

export interface BarOptions {
  backgroundColor: string;
  fontColor: string;
  label: string;
}

export default Palette;

export const barGraphTheme = {
  [QuestionType.LIKERT]: {
    scale2: [
      { backgroundColor: Palette.aquaBlue, fontColor: Palette.white, label: "Strongly agree" },
      { backgroundColor: Palette.darkPink, fontColor: Palette.white, label: "Strongly disagree" },
    ],
    scale3: [
      { backgroundColor: Palette.aquaBlue, fontColor: Palette.white, label: "Strongly agree" },
      { backgroundColor: "#E9ECF1", fontColor: Palette.bluishGrey, label: "Neither agree nor disagree" },
      { backgroundColor: Palette.darkPink, fontColor: Palette.white, label: "Strongly disagree" },
    ],
    scale4: [
      { backgroundColor: Palette.aquaBlue, fontColor: Palette.white, label: "Strongly agree" },
      { backgroundColor: "rgba(0, 195, 217, 0.7)", fontColor: Palette.white, label: "Agree" },
      { backgroundColor: Palette.softPink, fontColor: Palette.white, label: "Disagree" },
      { backgroundColor: Palette.darkPink, fontColor: Palette.white, label: "Strongly disagree" },
    ],
    scale5: [
      { backgroundColor: Palette.aquaBlue, fontColor: Palette.white, label: "Strongly agree" },
      { backgroundColor: "rgba(0, 195, 217, 0.7)", fontColor: Palette.white, label: "Agree" },
      { backgroundColor: "#E9ECF1", fontColor: Palette.bluishGrey, label: "Neither agree nor disagree" },
      { backgroundColor: Palette.softPink, fontColor: Palette.white, label: "Disagree" },
      { backgroundColor: Palette.darkPink, fontColor: Palette.white, label: "Strongly disagree" },
    ],
    scale6: [
      { backgroundColor: Palette.aquaBlue, fontColor: Palette.white, label: "Strongly agree" },
      { backgroundColor: "rgba(0, 195, 217, 0.7)", fontColor: Palette.white, label: "Agree" },
      { backgroundColor: "rgba(0, 195, 217, 0.5)", fontColor: Palette.bluishGrey, label: "Somewhat Agree" },
      { backgroundColor: "rgba(241, 169, 190, 0.5)", fontColor: Palette.bluishGrey, label: "Somewhat Disagree" },
      { backgroundColor: Palette.softPink, fontColor: Palette.white, label: "Disagree" },
      { backgroundColor: Palette.darkPink, fontColor: Palette.white, label: "Strongly disagree" },
    ],
    scale7: [
      { backgroundColor: Palette.aquaBlue, fontColor: Palette.white, label: "Strongly agree" },
      { backgroundColor: "rgba(0, 195, 217, 0.7)", fontColor: Palette.white, label: "Agree" },
      { backgroundColor: "rgba(0, 195, 217, 0.5)", fontColor: Palette.bluishGrey, label: "Somewhat Agree" },
      { backgroundColor: "#E9ECF1", fontColor: Palette.bluishGrey, label: "Neither agree nor disagree" },
      { backgroundColor: "rgba(241, 169, 190, 0.5)", fontColor: Palette.bluishGrey, label: "Somewhat Disagree" },
      { backgroundColor: Palette.softPink, fontColor: Palette.white, label: "Disagree" },
      { backgroundColor: Palette.darkPink, fontColor: Palette.white, label: "Strongly disagree" },
    ],
  },

  [QuestionType.ENPS]: [
    { backgroundColor: Palette.aquaBlue, fontColor: Palette.white, label: "10" },
    { backgroundColor: "rgba(0, 195, 217, 0.7)", fontColor: Palette.white, label: "9" },
    { backgroundColor: "#E9ECF1", fontColor: Palette.bluishGrey, label: "8" },
    { backgroundColor: "#c9d1d9", fontColor: Palette.white, label: "7" },
    { backgroundColor: "#fae5ec", fontColor: Palette.bluishGrey, label: "6" },
    { backgroundColor: "#f4bdd0", fontColor: Palette.white, label: "5" },
    { backgroundColor: "#ee93b2", fontColor: Palette.white, label: "4" },
    { backgroundColor: "#e86a94", fontColor: Palette.white, label: "3" },
    { backgroundColor: "#e34e7d", fontColor: Palette.white, label: "2" },
    { backgroundColor: Palette.darkPink, fontColor: Palette.white, label: "1" },
    { backgroundColor: "#ce3464", fontColor: Palette.white, label: "0" },
  ],

  [QuestionType.MULTIPLE_CHOICE]: [ 
    { backgroundColor: "#001640", fontColor: Palette.white },
    { backgroundColor: "#001C53", fontColor: Palette.white },
    { backgroundColor: "#002163", fontColor: Palette.white },
    { backgroundColor: "#002d79", fontColor: Palette.white },
    { backgroundColor: "#133e8d", fontColor: Palette.white },
    { backgroundColor: "#1d4899", fontColor: Palette.white },
    { backgroundColor: "#2852a5", fontColor: Palette.white },
    { backgroundColor: "#2f5aaf", fontColor: Palette.white },
    { backgroundColor: "#355fb3", fontColor: Palette.white },
    { backgroundColor: "#4070c5", fontColor: Palette.white },
    { backgroundColor: "#5372ba", fontColor: Palette.white },
    { backgroundColor: "#738cc6", fontColor: Palette.white },
    { backgroundColor: "#9bacd6", fontColor: Palette.white },
    { backgroundColor: "#c3cce7", fontColor: Palette.white },
    { backgroundColor: "#e7ebf5", fontColor: Palette.bluishGrey },
  ],

  [QuestionType.MULTI_SELECT]: [
    { backgroundColor: "#12013f", fontColor: Palette.white },
    { backgroundColor: "#1a0554", fontColor: Palette.white },
    { backgroundColor: "#22096d", fontColor: Palette.white },
    { backgroundColor: "#2d1787", fontColor: Palette.white },
    { backgroundColor: "#311b92", fontColor: Palette.white },
    { backgroundColor: "#4527a0", fontColor: Palette.white },
    { backgroundColor: "#512da8", fontColor: Palette.white },
    { backgroundColor: "#5e35b1", fontColor: Palette.white },
    { backgroundColor: "#673ab7", fontColor: Palette.white },
    { backgroundColor: "#7e57c2", fontColor: Palette.white },
    { backgroundColor: "#9575cd", fontColor: Palette.white },
    { backgroundColor: "#b39ddb", fontColor: Palette.white },
    { backgroundColor: "#d1c4e9", fontColor: Palette.white },
    { backgroundColor: "#ede7f6", fontColor: Palette.bluishGrey },
    { backgroundColor: "#fafafa", fontColor: Palette.bluishGrey },
  ],
};

export function getBarThemeForFavorability(scale: number, choiceLabels: Optional<string>[]) {
  switch (scale) {
    case 2:
      return barGraphTheme[QuestionType.LIKERT].scale2.map((configuration, idx) => {
        return {
          ...configuration,
          label: getOr(choiceLabels[idx], configuration.label),
        };
      });
    case 3:
      return barGraphTheme[QuestionType.LIKERT].scale3.map((configuration, idx) => {
        return {
          ...configuration,
          label: getOr(choiceLabels[idx], configuration.label),
        };
      });
    case 4:
      return barGraphTheme[QuestionType.LIKERT].scale4.map((configuration, idx) => {
        return {
          ...configuration,
          label: getOr(choiceLabels[idx], configuration.label),
        };
      });
    case 5:
      return barGraphTheme[QuestionType.LIKERT].scale5.map((configuration, idx) => {
        return {
          ...configuration,
          label: getOr(choiceLabels[idx], configuration.label),
        };
      });
    case 6:
      return barGraphTheme[QuestionType.LIKERT].scale6.map((configuration, idx) => {
        return {
          ...configuration,
          label: getOr(choiceLabels[idx], configuration.label),
        };
      });
    case 7:
      return barGraphTheme[QuestionType.LIKERT].scale7.map((configuration, idx) => {
        return {
          ...configuration,
          label: getOr(choiceLabels[idx], configuration.label),
        };
      });
    default:
      return barGraphTheme[QuestionType.LIKERT].scale5.map((configuration, idx) => {
        return {
          ...configuration,
          label: getOr(choiceLabels[idx], configuration.label),
        };
      });
  }
}

export function getBarThemeForEnps() {
  return barGraphTheme[QuestionType.ENPS];
}

export function getBarThemeForMultipleChoice(
  choiceLabels: Optional<string>[],
  isMultiSelect?: boolean
): BarOptions[] {
  let colors: any[] = [];

  const barTheme = isMultiSelect
    ? barGraphTheme[QuestionType.MULTI_SELECT]
    : barGraphTheme[QuestionType.MULTIPLE_CHOICE];

  switch (choiceLabels.length) {
    case 2:
      colors = [
        barTheme[0],
        barTheme[14],
      ];
      break;
    case 3:
      colors = [
        barTheme[0],
        barTheme[7],
        barTheme[14],
      ];
      break;
    case 4:
      colors = [
        barTheme[0],
        barTheme[5],
        barTheme[9],
        barTheme[14],
      ];
      break;
    case 5:
      colors = [
        barTheme[0],
        barTheme[3],
        barTheme[7],
        barTheme[11],
        barTheme[14],
      ];
      break;
    case 6:
      colors = [
        barTheme[0],
        barTheme[3],
        barTheme[6],
        barTheme[8],
        barTheme[11],
        barTheme[14],
      ];
      break;
    case 7:
      colors = [
        barTheme[0],
        barTheme[2],
        barTheme[4],
        barTheme[7],
        barTheme[10],
        barTheme[12],
        barTheme[14],
      ];
      break;
    case 8:
      colors = [
        barTheme[0],
        barTheme[2],
        barTheme[4],
        barTheme[6],
        barTheme[8],
        barTheme[10],
        barTheme[12],
        barTheme[14],
      ];
      break;
    case 9:
      colors = [
        barTheme[0],
        barTheme[2],
        barTheme[4],
        barTheme[6],
        barTheme[7],
        barTheme[8],
        barTheme[10],
        barTheme[12],
        barTheme[14],
      ];
      break;
    case 10:
      colors = [
        barTheme[0],
        barTheme[2],
        barTheme[4],
        barTheme[5],
        barTheme[6],
        barTheme[7],
        barTheme[8],
        barTheme[10],
        barTheme[12],
        barTheme[14],
      ];
      break;
    case 11:
      colors = [
        barTheme[0],
        barTheme[2],
        barTheme[4],
        barTheme[5],
        barTheme[6],
        barTheme[7],
        barTheme[8],
        barTheme[9],
        barTheme[10],
        barTheme[12],
        barTheme[14],
      ];
      break;
    case 12:
      colors = [
        barTheme[0],
        barTheme[2],
        barTheme[3],
        barTheme[4],
        barTheme[5],
        barTheme[6],
        barTheme[8],
        barTheme[9],
        barTheme[10],
        barTheme[11],
        barTheme[12],
        barTheme[14],
      ];
      break;
    case 13:
      colors = [
        barTheme[0],
        barTheme[2],
        barTheme[3],
        barTheme[4],
        barTheme[5],
        barTheme[6],
        barTheme[7],
        barTheme[8],
        barTheme[9],
        barTheme[10],
        barTheme[11],
        barTheme[12],
        barTheme[14],
      ];
      break;
    case 14:
      colors = [
        barTheme[0],
        barTheme[1],
        barTheme[2],
        barTheme[3],
        barTheme[4],
        barTheme[5],
        barTheme[6],
        barTheme[8],
        barTheme[9],
        barTheme[10],
        barTheme[11],
        barTheme[12],
        barTheme[13],
        barTheme[14],
      ];
      break;
    default: 
      colors = barTheme;
  }

  return colors.map((configuration, idx) => {
    return {
      ...configuration,
      label: getOr(choiceLabels[idx], ""),
    };
  });
}
