import { applyDefault } from "@src/utils/Currier";
import { MultiSelectFilter } from "@screens/Insights/components/Filters/base/MultiSelectFilter";
import { BaseFilterProps } from "@screens/Insights/components/FiltersContent";
import { PostCategoryResource } from "hyphen-lib/dist/domain/resource/post/PostCategoryResource";
import { Post } from "hyphen-lib/dist/domain/Post";
import { Sentiment } from "hyphen-lib/dist/domain/common/ComputationTypes";
import { QuestionType } from "hyphen-lib/dist/domain/common/QuestionType";

export const PulsePollStatusFilter =
  applyDefault(
    MultiSelectFilter,
    {
      trans: true,
      availableValues: [
        { value: Post.Status.DRAFT, label: "Draft"},
        { value: Post.Status.ACTIVE, label: "Active"},
        { value: Post.Status.DEACTIVATED, label: "Paused/Closed"},
      ],
    }
  );

export const SentimentFilter =
  applyDefault(
    MultiSelectFilter,
    {
      trans: true,
      availableValues: [
        { value: Sentiment.POSITIVE, label: "Positive"},
        { value: Sentiment.NEUTRAL, label: "Neutral"},
        { value: Sentiment.NEGATIVE, label: "Negative"},
      ],
    }
  );

export const QuestionTypeFilter =
  applyDefault(
    MultiSelectFilter,
    {
      availableValues: [
        { value: QuestionType.LIKERT, label: "Likert" },
        { value: QuestionType.MULTIPLE_CHOICE, label: "Multiple Choice" },
        { value: QuestionType.OPEN_TEXT, label: "Open" },
      ],
    }
  );

export const PulsePollFrequencyFilter =
  applyDefault(
    MultiSelectFilter,
    {
      trans: true,
      availableValues: [
        { value: Post.PollFrequency.EVERY_SIX_MONTHS, label: "Every 6 months"},
        { value: Post.PollFrequency.EVERY_TWO_WEEKS, label: "Every 2 weeks"},
        { value: Post.PollFrequency.MONTHLY, label: "Monthly"},
        { value: Post.PollFrequency.ONCE, label: "Once"},
        { value: Post.PollFrequency.QUARTERLY, label: "Quarterly"},
        { value: Post.PollFrequency.WEEKLY, label: "Weekly"},
      ],
    }
  );

export interface CategoryFilterProps extends BaseFilterProps<string[]> {
  categories: PostCategoryResource[];
}

export function CategoryFilter({
  label,
  categories,
  ...rest
}: CategoryFilterProps) {
  return (
    <MultiSelectFilter
      label={label}
      availableValues={categories.map(category => ({
        value: category._id,
        label: category.label,
      }))}
      {...rest}
    />
  );
}
