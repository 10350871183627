import React, { Component } from "react";
import { Link } from "react-router-dom";
import { TransWrapper } from "@src/components/core/withTrans";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";

import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";

interface InfoCardProps {
  readonly title: string;
  readonly description?: string;
  readonly link?: string;
  readonly size?: string;
  readonly headerStyle?: "emphasize";
  readonly translate?: string;
}

export class InfoCard extends Component<InfoCardProps> {
  render() {
    const { title, description, link, ...rest} = this.props;
    return (
      <InfoCardContainer size={this.props.size} data-cy="infoCard">
        <SectionHeader headerStyle={this.props.headerStyle} data-cy="infoCard_header">
          <SectionLabel data-cy="infoCard_title">
            <TransWrapper {...rest}>{title}</TransWrapper>
          </SectionLabel>
          {isNotNullNorUndefined(link) &&
            <SectionLink
              data-cy={`infoCard_link_edit${title}`}
              to={link}
            >
              <TransWrapper {...rest}>Edit</TransWrapper>
            </SectionLink>
          }
          
        </SectionHeader>
        { isNotNullNorUndefined(description) && 
          <Summary data-cy="infoCard_description">
            <TransWrapper {...rest}>{description}</TransWrapper>
          </Summary>
        }
        {this.props.children}
      </InfoCardContainer>
    );
  }
}

const largeContainer = `
  padding: 32px;
  margin-top: 32px;
`;

const smallContainer = `
  padding: 0px;
  margin-top: 32px;
`;

function getContainerStyle(size: string | undefined) {
  switch (size) {
    case "large":
      return largeContainer;
    default:
      return smallContainer;
  }
}

const InfoCardContainer = styled.div<{size: InfoCardProps["size"]}>`
  ${props => {
    return getContainerStyle(props.size);
  }}
  background: ${Palette.white};
`;

const defaultHeader = `
  font-size: 16px;
  border-bottom: 1px solid #EEE;
  width: 100%;
`;

const boldHeader = `
  font-weight: bold;
  font-size: 24px;
  color: ${Palette.veryDarkBlueGrey};
  display: flex;
  justify-content: space-between;
`;

function getHeaderStyle(headerStyle: string | undefined) {
  switch (headerStyle) {
    case "emphasize":
      return boldHeader;
    default:
      return defaultHeader;
  }
}

const SectionHeader = styled.div<{ headerStyle: InfoCardProps["headerStyle"] }>`
  ${props => {
    return getHeaderStyle(props.headerStyle);
  }}
`;

const SectionLabel = styled.div`
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  display: inline-block;
  vertical-align: top;
  color: ${Palette.veryDarkBlueGrey};
`;

const SectionLink = styled(Link)`
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  float: right;
  color: ${Palette.bluePurple};
  cursor: pointer;
`;

const Summary = styled.div`
  margin: 8px 0;
  color: ${Palette.bluishGrey};
`;
