import { freeze } from "hyphen-lib/dist/lang/Objects";
import { takeLatest, put } from "redux-saga/effects";
import notificationActionCreators, {
  ShowNotificationAction
} from "@store/notifications/actions";
import {
  CleanResourceAction,
  actionCreators as networkActionCreators
} from "@src/store/network/actions";
import { CommentsResultResource } from "hyphen-lib/dist/domain/resource/report/CommentsResultResource";
import { PollReportActionTypes } from "./actions";
import * as NotificationFactory from "@src/store/notifications/notification-factory";

function* showSuccess() {
  yield put<ShowNotificationAction>(
    notificationActionCreators.displayNotification(
      NotificationFactory.success(
        "Private message sent successfully",
        "",
        4.5
      )
  ));

  yield put<CleanResourceAction>(
    networkActionCreators.cleanResource(CommentsResultResource.TYPE)
  );
}

function* showError() {
  yield put<ShowNotificationAction>(
    notificationActionCreators.displayNotification(
      NotificationFactory.error(
        "Error occurred in sending Private Message",
        "",
        4.5
      )
  ));
}

export const pulsePollReportSagas = freeze([
  /* All sagas go here */
  takeLatest(PollReportActionTypes.SEND_PRIVATE_MESSAGE_SUCCESS, showSuccess),

  takeLatest(PollReportActionTypes.SEND_PRIVATE_MESSAGE_ERROR, showError),
]);
