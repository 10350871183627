import AreYouSureModal from "@components/core/AreYouSureModal";
import { translate } from "@src/utils/i18next";
import { useTranslation } from "react-i18next";
interface Props {
  isModalOpen: boolean;
  onHideModal: () => void;
  onConfirmModal: () => void;
  additionalUsersCount: number;
}

export default function UpdateAudienceModal(props: Props) {
  const { isModalOpen, onHideModal, additionalUsersCount, onConfirmModal } = props;
  const { t } = useTranslation();
  return (
    <AreYouSureModal
      visible={isModalOpen}
      title="Are you sure?"
      /* eslint-disable max-len */
      description={`${additionalUsersCount} ${translate(t, "newly added users will get added to the Survey. Please click Confirm to proceed or Cancel to go back")}`}
      okLabel={"Confirm"}
      onCancel={onHideModal}
      onOk={onConfirmModal}
    />
  );
}
