import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { PaginationConfig } from "antd/lib/table";
import { Map as ImmutableMap } from "immutable";

import { VoicePostReportResource } from "hyphen-lib/dist/domain/resource/voice/VoicePostReportResource";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import {
  getOr,
  isNullOrUndefined,
  mapOr,
  isNotNullNorUndefined,
} from "hyphen-lib/dist/lang/Objects";
import { parseNumber } from "hyphen-lib/dist/lang/Number";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { Rights } from "hyphen-lib/dist/business/auth/Rights";
import { Store } from "hyphen-lib/dist/util/store/Store";

import { fetchVoicePostsIfNeeded } from "@src/store/network/resource/VoicePostReportResource";
import { State } from "@src/store/types";
import {
  getExistingPage,
  extractDataAndTotalFromPage,
} from "@src/store/network/selectors";
import { parametersActionCreators } from "@src/screens/Insights/parameters/store/actions";
import { PropMapping } from "@src/utils/parameters";
import { getParameters } from "@src/screens/Insights/parameters/store/selectors";
import { getRightsMatcher } from "@src/screens/Insights/store/selectors";
import { DataListContainer } from "@src/screens/Insights/components/DataListContainer";
import { FetchDataListParameters } from "@src/screens/Insights/components/DataListContainer/types";

import { FetchError } from "@src/screens/Insights/errors/FetchError";
import { VoicePostsTable } from "../Components/VoicePostsTable";
import { getEmployeeVoiceReportsState } from "../../store/selectors";
import { voiceReportsActionCreators } from "../../store/actions";
import { VOICE_POSTS_FILTER_MAPPINGS } from "../..";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { fetchFocusAreasIfNeeded } from "@src/store/network/resource/ActionResources";
import {
  focusAreaListActionCreators,
  FocusAreaListPageParameters,
} from "@src/screens/Insights/Actions/store/actions";
import { isEmpty } from "lodash";
import BottomDrawer, { DrawerViews } from "@src/components/core/BottomDrawer";
import { goTo } from "@src/utils/locations";
import BottomDrawerStripedChild, {
  transformFocusAreasToStripStack,
} from "@src/components/core/BottomDrawer/StripedChild";
import {
  getAllFocusAreasFromReduxStore,
  getCurrentBottomDrawerView,
  isFARequestComplete,
} from "@src/utils/FocusArea";
import { BottomDrawerMessages } from "@src/screens/Insights/BottomDrawer/constant";
import { hasFocusAreaRights } from "hyphen-lib/dist/business/focusArea/focusAreaResource";
import Spin from "@src/components/core/Spin";
import { Trans } from "react-i18next";

interface VoicePostsListProps
  extends VoicePostsListReduxActionProps,
    RouteComponentProps,
    VoicePostsListReduxStateProps {}
interface VoicePostsListReduxActionProps {
  readonly onFetchVoicePostsIfNeeded: (
    parameters: FetchDataListParameters
  ) => void;
  readonly onModifyParameters: (
    parameters: Dictionary<any>,
    mappings?: PropMapping[]
  ) => void;
  readonly onModifyList: (parameters: FetchDataListParameters) => void;
  readonly onFetchFocusArea: (parameters: FocusAreaListPageParameters) => any;
  readonly onCreateFocusArea: (focusArea: Partial<FocusAreaResource>) => void;
}

interface VoicePostsListReduxStateProps {
  readonly voicePosts: VoicePostReportResource[];
  readonly totalVoicePostsCount: number;
  readonly parameters: ImmutableMap<string, any>;
  readonly pageSize: number;
  readonly loading: boolean;
  readonly isNotFound: boolean;
  readonly hasActionCreationRight: boolean;
  readonly existingListVoicePosts: Store.Page<VoicePostReportResource>;
  readonly focusAreas?: FocusAreaResource[];
  readonly currentBottomDrawerView: DrawerViews;
  readonly hasFaRights: boolean;
  readonly completedFARequest: boolean;
}

interface VoiceListPostContainerState {
  totalFocusAreas: number;
}

export class VoicePostsListContainer extends React.Component<
  VoicePostsListProps,
  VoiceListPostContainerState
> {
  constructor(props: VoicePostsListProps) {
    super(props);
    this.state = {
      totalFocusAreas: 0,
    };
  }

  onTableChange = (
    pageParams: FetchDataListParameters,
    pagination: PaginationConfig
  ): void => {
    const { page, filter } = pageParams;
    const { onModifyList, onModifyParameters } = this.props;
    const pageParam = {
      size: getOr(pagination.pageSize, page.size),
      number: getOr(pagination.current, 1),
    };

    onModifyParameters(VOICE_POSTS_FILTER_MAPPINGS);
    onModifyList({
      filter,
      page: pageParam,
    });
  };

  componentDidMount() {
    if (
      (isNullOrUndefined(this.props.focusAreas) ||
        isEmpty(this.props.focusAreas)) &&
      this.props.hasFaRights
    ) {
      this.props.onFetchFocusArea(this.fetchFocusAreaProps());
    }

    let totalFocusAreas = 0;
    if (isNotNullNorUndefined(this.props.focusAreas)) {
      totalFocusAreas = this.props.focusAreas.length;
    }

    this.setState({
      totalFocusAreas,
    });
  }

  componentDidUpdate(prevProps: VoicePostsListProps) {
    if (this.props.hasFaRights) {
      this.props.onFetchFocusArea(this.fetchFocusAreaProps());
    }

    if (
      isNotNullNorUndefined(this.props.focusAreas) &&
      isNotNullNorUndefined(prevProps.focusAreas)
    ) {
      if (this.props.focusAreas.length > prevProps.focusAreas.length) {
        this.setState({
          totalFocusAreas: this.props.focusAreas.length,
        });
      }
    }
  }

  fetchFocusAreaProps(): FocusAreaListPageParameters {
    return {
      page: {
        number: 1,
        size: 30,
      },
      filter: {
        withActionPlans: false,
      },
    };
  }

  render() {
    const {
      voicePosts,
      pageSize,
      parameters,
      totalVoicePostsCount,
      loading,
      isNotFound,
      hasActionCreationRight,
      onFetchVoicePostsIfNeeded,
      existingListVoicePosts,
      focusAreas,
      onCreateFocusArea,
      hasFaRights,
      completedFARequest
    } = this.props;

    if (Store.Page.isInError(existingListVoicePosts)) {
      return (
        <FetchError
          {...existingListVoicePosts}
          resourceType={VoicePostReportResource.TYPE}
        />
      );
    }

    return (
      <>
        <Spin
          size="large"
          className="sticky-top"
          spinning={!completedFARequest}
        >
          {hasFaRights && (
            <BottomDrawer
              key="segment-drawer"
              initialView={this.props.currentBottomDrawerView}
              title={`${
                this.state.totalFocusAreas === 0
                  ? "No"
                  : this.state.totalFocusAreas
              } Focus Area Added`}
              footerButton={{
                display: true,
                text: "Go To Action Center",
                onClick: () => goTo("/actioncenter"),
              }}
            >
              <BottomDrawerStripedChild
                stripStack={transformFocusAreasToStripStack(focusAreas)}
                emptyStackMessage={BottomDrawerMessages.EMPTY_STACK_MESSAGE}
              />
            </BottomDrawer>
          )}

          <DataListContainer
            pageSize={pageSize}
            parameters={parameters}
            loading={loading}
            isNotFound={isNotFound}
            onFetchIfNeeded={onFetchVoicePostsIfNeeded}
            paramMappings={VOICE_POSTS_FILTER_MAPPINGS}
            isInError={Store.Page.isInError(existingListVoicePosts)}
          >
            {({ pageParams }) => (
              <VoicePostsTable
                hasActionCreationRight={hasActionCreationRight}
                dataSource={voicePosts}
                rowKey="_id"
                loading={loading}
                onChange={this.onTableChange.bind(this, pageParams)}
                onCreateFocusArea={onCreateFocusArea}
                focusAreas={focusAreas}
                pagination={{
                  total: totalVoicePostsCount,
                  pageSize: pageParams.page.size,
                  current: pageParams.page.number,
                  showTotal: (totalDocuments: number, range: number[]) =>
                    <Trans i18nKey="postsCount"
                    values={{start: range[0], end: range[1], total: totalDocuments}} 
                    defaults={`Showing ${range[0]} to ${range[1]} of ${totalDocuments} posts`}/>,
                }}
              />
            )}
          </DataListContainer>
        </Spin>
      </>
    );
  }
}

function mapStateToProps(
  state: State,
  { location }: RouteComponentProps
): VoicePostsListReduxStateProps {
  const queryParameters = parseQueryString(location.search);
  const filter = getOr(queryParameters.filter, {});
  const { pageSize } = getEmployeeVoiceReportsState(state);
  const page = {
    size: pageSize,
    number: mapOr(queryParameters.page, parseNumber, 1),
  };
  const existingListVoicePosts = getExistingPage(
    state,
    VoicePostReportResource.TYPE,
    VoicePostReportResource.generateKey(filter),
    page
  );

  const { data: voicePosts, total: totalVoicePostsCount } =
    extractDataAndTotalFromPage(existingListVoicePosts);

  const data = getAllFocusAreasFromReduxStore(state);
  const currentBottomDrawerView = getCurrentBottomDrawerView(state);
  const hasFaRights = hasFocusAreaRights(getRightsMatcher(state));

  return {
    isNotFound: Store.Page.isNotFound(existingListVoicePosts),
    loading: Store.Page.isLoading(existingListVoicePosts),
    existingListVoicePosts,
    voicePosts,
    totalVoicePostsCount,
    parameters: getParameters(state),
    pageSize,
    hasActionCreationRight: getRightsMatcher(state).hasRight(
      Rights.Action.CREATE
    ),
    focusAreas: data,
    currentBottomDrawerView,
    hasFaRights,
    completedFARequest: isFARequestComplete(state),
  };
}

const mapDispatchToProps = {
  onFetchVoicePostsIfNeeded: fetchVoicePostsIfNeeded,
  onModifyList: voiceReportsActionCreators.modifyList,
  onModifyParameters: parametersActionCreators.modifyParameters,
  onFetchFocusArea: fetchFocusAreasIfNeeded,
  onCreateFocusArea: focusAreaListActionCreators.createFocusArea,
};

export const VoicePostsList = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VoicePostsListContainer)
);
