// eslint-disable-next-line max-len
export const deactivationWarning = "By continuing with 'Pause and Close' Poll, responses for the active poll iteration will no longer be accepted and launch of all future iterations of the poll will be paused.By continuing with 'Pause Only', The current active iteration will continue to accept votes, but the launch of all future iterations of the poll will be paused.";

// eslint-disable-next-line max-len
export const deactivateWithNoActiveIterationWarning = "By Pausing Poll, launch of all future iterations of this poll will be paused";

// eslint-disable-next-line max-len
export const deleteWarning = "If you delete the Poll, the poll and all of its data will be permanently deleted from the system.";

// eslint-disable-next-line max-len
export const reactivationWarning = "If you, restart the poll, new polls will be sent out according to your set schedule.";

// eslint-disable-next-line max-len
export const closeIterationWarning = "By closing the Poll iteration, no further reminders would be sent for the active poll iteration and responses for the active poll will no longer be accepted.";

// eslint-disable-next-line max-len
export const closeIterationForDeactivatedPollWarning = "If you continue, responses will no longer be accepted for this iteration of the Poll.";

// eslint-disable-next-line max-len
export const closeAndDeactivateFrequencyOncePollWarning = "By closing the Poll, no further reminders would be sent and responses for the active poll will no longer be accepted.";
