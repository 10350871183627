import Palette from "@src/config/theme/palette";
import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";
import { getTotalValue, SentimentData, SentimentDonutChartData } from "..";

interface Props {
    data: SentimentDonutChartData;
    size?: number;
}

export function SentimentDonutTooltip({ size = 100, data }: Props) {
  return (
    <CustomTooltip size={size} className="ant-tooltip ant-tooltip-placement-bottom">
        <div className="ant-tooltip-content">
        <div className="ant-tooltip-arrow"/>
        <div className="ant-tooltip-inner" role="tooltip">
            <div className="text-center">{`${data.name} (${getTotalValue(data.sentiments)})`}</div>
            <div className="my-1">
            {data.sentiments.map((sentiment: SentimentData) => (
            <SentimentText
                data-cy={`${data.name}_${sentiment.text}`}
                key={sentiment.text}
            >
                <div className="sentiment-type">
                <span className={sentiment.text.toLowerCase()}/>
                <Trans>{sentiment.text}</Trans> -
                </div>
                <div>{sentiment.percentage}%</div>
            </SentimentText>
            ))}
            </div>
        </div>
        </div>
    </CustomTooltip>
  );
}

const CustomTooltip = styled.div<{ size: number }>`
  width: 154px;
  top: 100%;
  left: ${props => (props.size - 150) / 2}px;
`;

const SentimentText = styled.div`
  font-family: Lato, sans-serif;
  display: flex;
  width: 115px;
  margin: 0 auto;
  justify-content: space-between;
  .sentiment-type > span {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 4px;
    background-color: ${Palette.bluishGrey};
    &.positive {
      background-color: ${Palette.aquaBlue};
    }
    &.negative {
      background-color: ${Palette.darkPink};
    }
  }
`;
