import React from "react";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";

import { State } from "@store/types";


import { actionCreators as surveyActionCreators } from "@screens/Insights/Surveys/store/actions";

import { getCompany, getDimensions } from "@screens/Insights/store/selectors";
import { getOr, getProperty, isNotNullNorUndefined, mapOr, values } from "hyphen-lib/dist/lang/Objects";
import { onlyField } from "hyphen-lib/dist/lang/Functions";
import { FilterParameter } from "@src/utils/networks";
import { Company } from "hyphen-lib/dist/domain/Company";
import { getSurveyTypes } from "@screens/Insights/Surveys/store/selectors";
import { Filter } from "@src/components/core/FilterLabels/types";
import { sanitizeDimensions } from "@src/utils/Dimensions";
import Filters, { FiltersData } from "../../../components/Reports/Filters";
import { LoadingState } from "./store/types";
import { actionCreators } from "./store/actions";
import { getLoadingState, getSurveyFilters } from "./store/selectors";
import { parseQueryString } from "@hyphen-lib/util/net/HttpClient";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { fetchSurveyDimensionsInfoIfNeeded } from "@src/store/network/resource/SurveyInfoResources";
import { getResourceById } from "@src/store/network/selectors";
import { SurveyDimensionsResource } from "hyphen-lib/dist/domain/resource/survey/report/SurveyDimensionsResource";
import { Store } from "hyphen-lib/dist/util/store/Store";

interface MatchParams {
  id: string;
  selectedDimension: string;
}

export interface FiltersContainerOwnProps extends RouteComponentProps<MatchParams> {
  enabledFilters: string[];
  appliedFilters: Filter[];
  companyModules?: Company.Modules;
  enabledCustomFilters?: string[];
  from?: string;
  onApply: (filter: FilterParameter) => any;
  singleFieldFilter?: string;
}

export interface FiltersContainerStateProps {
  filtersData: FiltersData;
  loadingState: LoadingState;
  surveyDimensionsInfoElement: Store.Element<SurveyDimensionsResource>;
}

export interface FiltersContainerActionProps {
  readonly fetchSurveyTypes: () => void;
  readonly fetchSurveyFilters: (surveyId: string) => void;
  readonly fetchDashboardCategories: (queryParams: Dictionary<any>) => void;
  readonly fetchSurveyDimensionsInfo: (surveyId: string) => any;
}

type FiltersContainerProps =
  FiltersContainerOwnProps &
  FiltersContainerStateProps &
  FiltersContainerActionProps;

class FiltersContainer extends React.Component<FiltersContainerProps> {

  componentDidMount(): void {
    this.fetchSurveyFilters();
  }

  componentDidUpdate(prevProps: FiltersContainerProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.fetchSurveyFilters();
    }
  }

  fetchSurveyFilters = () => {
    const { match, fetchSurveyFilters, fetchSurveyTypes , 
      fetchDashboardCategories, location, from} = this.props;
    const surveyId = match.params.id;
    if (isNotNullNorUndefined(surveyId)) {
      fetchSurveyFilters(surveyId);
    } else if(from === "dashboard_view"){
      fetchDashboardCategories(parseQueryString(location.search));
    }
    fetchSurveyTypes();
  };

  fetchSurveyDimensions = ()  => {
    const { match, fetchSurveyDimensionsInfo} = this.props;
    const surveyId = match.params.id;
    if (isNotNullNorUndefined(surveyId)) {
      fetchSurveyDimensionsInfo(surveyId);
    } 
  };

  render() {
    const { onApply, enabledCustomFilters, enabledFilters, appliedFilters, surveyDimensionsInfoElement,
      filtersData, from, fetchDashboardCategories, singleFieldFilter, loadingState: { isLoading }} = this.props;
    return (
      <Filters
        enabledFilters={enabledFilters}
        loading = {isLoading}
        appliedFilters={appliedFilters}
        filtersData={filtersData}
        enabledCustomFilters={enabledCustomFilters}
        onApply={onApply}
        from={from}
        fetchCategories={fetchDashboardCategories}
        singleFieldFilter={singleFieldFilter}
        fetchSurveyDimensionsInfo = {this.fetchSurveyDimensions}
        surveyDimensionsInfoElement={surveyDimensionsInfoElement}
      />
    );
  }
}

const mapStateToProps: MapStateToProps<FiltersContainerStateProps, FiltersContainerOwnProps, State> = (
  state: State,
  ownProps: FiltersContainerOwnProps
): FiltersContainerStateProps => {
  const filters = getSurveyFilters(state);
  let modules: Company.Modules = {
    surveys: false,
    pulsePolls: false,
    employeeVoice: false,
    employeeLifeCycle: false,
  };
  const { companyModules, match} = ownProps;
  const surveyId = match.params.id;

  if (isNotNullNorUndefined(companyModules)) {
    modules = companyModules;
  } else {
    modules = mapOr(getCompany(state), map => map.modules, {
      surveys: false,
      pulsePolls: false,
      employeeVoice: false,
      employeeLifeCycle: false,
    });
  }
  const surveyDimensionsInfoElement = getResourceById(state, SurveyDimensionsResource.TYPE,
     SurveyDimensionsResource.generateId(surveyId));

  return ({
    filtersData: {
      // fixme: what about filter for comments categories, we want only the category flagged
      categories: values(getProperty(filters, "data.categories", {}))
        .map(onlyField("name"))
        .toArray(),
      topics: values(getProperty(filters, "data.topics", {}))
        .map(onlyField("name"))
        .toArray(),
      dimensions:  getOr(sanitizeDimensions(getDimensions(state)), {}),
      companyModules: modules,
      surveyTypes: getSurveyTypes(state).toArray(),
    },
    loadingState: getLoadingState(state),
    surveyDimensionsInfoElement
  });
};

const mapDispatchToProps = {
  fetchSurveyTypes: surveyActionCreators.fetchSurveyTypes,
  fetchSurveyFilters: actionCreators.fetchSurveyFilters,
  fetchDashboardCategories: actionCreators.fetchDashboardCategories,
  fetchSurveyDimensionsInfo: fetchSurveyDimensionsInfoIfNeeded,
};

// Refer: https://github.com/microsoft/TypeScript/issues/38343#issuecomment-668228445
// @ts-ignore
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FiltersContainer));
export { FiltersContainer as PureFiltersContainer };
