/* tslint:disable:max-line-length */
import { all } from "@redux-saga/core/effects";
import { networkSagas } from "@store/network/sagas";
import { surveyListSagas } from "@screens/Insights/SurveyList/store/sagas";
import { usersAndDimensionsSagas } from "@screens/Insights/AddUsersAndDimensions/store/sagas";
import { userListSagas } from "@src/screens/Insights/UserManagement/containers/UserListContainer/store/sagas";

import { userPermissionsSagas } from "@src/screens/Insights/UserManagement/containers/UserPermissions/store/sagas";
import { actionPlansSagas } from "@src/screens/Insights/ActionPlans/store/sagas";
import { actionListSagas, focusAreaListSagas } from "@src/screens/Insights/Actions/store/sagas";

import { surveyReportSagas } from "@src/screens/Insights/Survey/store/sagas";
import { surveyReminderSagas } from "@src/screens/Insights/Survey/containers/SurveyRemindersContainer/store/sagas";
import { surveysSagas } from "@screens/Insights/Surveys/store/sagas";
import { userRolesSagas } from "@screens/Insights/UserManagement/containers/UserRoles/store/sagas";
import { settingsSagas } from "@screens/Insights/Settings/store/sagas";
import { mainSagas } from "@src/screens/Insights/store/sagas";
import { pulsePollsSagas } from "@src/screens/Insights/PulsePollList/store/sagas";
import { pulsePollReportSagas } from "@src/screens/Insights/PulsePoll/store/sagas";
import { surveyEditSagas } from "@screens/Insights/Surveys/store/surveyEditSagas";
import { pulsePollEditSagas } from "@screens/Insights/PulsePolls/store/pulsePollEditSagas";
import { voiceReportsSagas } from "@src/screens/Insights/EmployeeVoice/VoiceReports/store/sagas";
import { batarangScriptsSagas } from "@src/screens/Insights/Settings/containers/batarang/store/batarangScriptsSagas";
import { segmentReportSagas } from "@src/screens/Insights/Segments/SegmentReport/store/sagas";
import { surveyLanguageCSVSagas } from "@src/screens/Insights/Xlsx/sagas";

/*  */

export function* rootSaga() {
  yield all([
    ...networkSagas,

    ...mainSagas,

    ...batarangScriptsSagas,

    ...surveyEditSagas,

    ...pulsePollEditSagas,

    ...surveyListSagas,

    ...userListSagas,

    ...userPermissionsSagas,

    ...usersAndDimensionsSagas,
    ...actionPlansSagas,

    ...actionListSagas,
    ...focusAreaListSagas,
    ...surveyLanguageCSVSagas,
    ...surveyReportSagas,
    ...surveyReminderSagas,
    ...surveysSagas,
    ...userRolesSagas,

    ...settingsSagas,
    ...pulsePollsSagas,
    ...pulsePollReportSagas,
    ...voiceReportsSagas,
    ...segmentReportSagas,
  ]);
}
