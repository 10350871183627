import Tooltip from "@src/components/core/Tooltip";
import Palette from "@src/config/theme/palette";
import styled, { css } from "styled-components";
import { sanitizeSegmentLabel } from "hyphen-lib/dist/domain/common/Dimensions";
import { trans } from "@src/utils/i18next";

interface TitleProps {
  readonly title: string;
  readonly focusAreaTypeText: string;
}

function Title({title, focusAreaTypeText}: TitleProps) {
  const titleSanitize = sanitizeSegmentLabel(title);
  const focusAreaTypeTextSanitize = sanitizeSegmentLabel(focusAreaTypeText);
  return (
    <TitleContainer>
      <Tooltip title={trans(titleSanitize)}>
        <StyledTitle>{trans(titleSanitize)}</StyledTitle>
      </Tooltip>
      <Tooltip title={trans(focusAreaTypeTextSanitize)}>
        <StyledType>{trans(focusAreaTypeTextSanitize)}</StyledType>
      </Tooltip>
    </TitleContainer>
  );
}

const TitleContainer = styled.div`
  margin-left: 24px;
`;

const truncateTextAfterTwoLines = css`
  display: -webkit-box;
  max-width: 340px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const StyledTitle = styled.div`
  color: ${Palette.veryDarkBlueGrey};
  line-height: 19px;
  font-weight: bold;
  font-size: 16px;
  ${truncateTextAfterTwoLines}
`;

const StyledType = styled.div`
  margin-top: 8px;
  font-size: 14px;
  color: ${Palette.veryDarkBlueGrey};
  line-height: 20px;
  ${truncateTextAfterTwoLines}
`;

export default Title;
