import { Switch } from "antd";
import styled from "styled-components";
import { AppSettingsResource } from "hyphen-lib/dist/domain/resource/AppSettingsResource";
import { Heading } from "@components/core/Typography/index";
import { not } from "hyphen-lib/dist/lang/Booleans";
import Palette from "@src/config/theme/palette";
import { Trans } from "react-i18next";
import useToggle from "@src/utils/hooks/useToggle";
interface PrivateMessagesToggleProps {
  appSettings: AppSettingsResource;
  allowPrivateMessages : boolean;
  onValueChange: (values: AppSettingsResource) => void;
}

export function PrivateMessagesToggle({
  appSettings,
  onValueChange,
  allowPrivateMessages }:
  PrivateMessagesToggleProps) {
  const [allowPrivateMessagesEnabled, togglAllowPrivateMessages] = useToggle(allowPrivateMessages);

  const onAllowPrivateMessagesChange = () => {
    onValueChange({
      ...appSettings,
      allowPrivateMessages: not(allowPrivateMessagesEnabled),
    });
    togglAllowPrivateMessages(not(allowPrivateMessagesEnabled));
  };
  return (
    <Container data-jest="private-messages-toggle">
      <Heading translate="yes">Send private messages</Heading>
      <Holder>
        <Description>
          <Trans>
            Allow user to send anonymous private messages 
            (When disabled, the option to send private messages will be removed from all roles in Surveys and Polls)
          </Trans>
        </Description>
        <Switch
          checked={allowPrivateMessagesEnabled}
          onChange={onAllowPrivateMessagesChange}
        />
      </Holder>
    </Container>
  );
}


const Container = styled.div`
  border-radius: 3px;
  box-shadow: 0 4px 8px 0 rgba(198, 214, 223, 0.15);
  background-color: ${Palette.white};
  margin-bottom: 39px;
  padding: 32px;
`;
const Holder = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
`;
const Description = styled.p`
  padding-right:88px;
`; 