import {
  ModifyListAction,
  SendSurveyReminderAction,
  surveyListActionCreators,
  SurveyListActionTypes
} from "@screens/Insights/SurveyList/store/actions";
import { ActionTypes as SurveyCreateActions } from "@screens/Insights/Surveys/store/actions";
import { call, put, select, takeLatest } from "@redux-saga/core/effects";
import { SurveyInfoResource } from "hyphen-lib/dist/domain/resource/SurveyInfoResource";
import { freeze, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import {
  actionCreators as networkActionCreators,
  CleanResourceAction,
  NetworkRequestAction
} from "@store/network/actions";
import { pushLocation, replaceLocation } from "@src/utils/locations";
import { getSurveyListStateProps } from "./selectors";

export function* updateLocationUrl(replace: boolean,
  { payload: { filter, sort, page } }: ModifyListAction) {

  const locationUpdater = replace ? replaceLocation : pushLocation;
  yield call(
    locationUpdater, {
      filter,
      sort,
      page: page.number,
    });
}

export function* fetchSurvey() {
  // Get the selectedSurveyInfoResource:
  const { selectedSurveyInfoResource } = yield select(getSurveyListStateProps);
  const surveyId = selectedSurveyInfoResource.get("_id");
  if (isNotNullNorUndefined(surveyId)) {
    yield put<NetworkRequestAction>(
      surveyListActionCreators.fetchSurvey(surveyId)
    );
  }
}

export function* sendSurveyReminders({ payload }: SendSurveyReminderAction) {
  // Get the selectedSurveyInfoResource:
  const { selectedSurveyInfoResource } = yield select(getSurveyListStateProps);
  const surveyId = selectedSurveyInfoResource.get("_id");

  yield put<NetworkRequestAction>(
    surveyListActionCreators.sendSurveyRemindersRequest(surveyId, payload)
  );
}

export function* deleteSurvey() {
  // Get the selectedSurveyInfoResource:
  const { selectedSurveyInfoResource } = yield select(getSurveyListStateProps);
  const surveyId = selectedSurveyInfoResource.get("_id");

  yield put<NetworkRequestAction>(
    surveyListActionCreators.deleteSurveyRequest(surveyId)
  );
}

export function* resetResources() {
  yield put<CleanResourceAction>
  (networkActionCreators.cleanResource(SurveyInfoResource.TYPE));
}

export function* closeSurvey() {
  // Get the selectedSurveyInfoResource:
  const { selectedSurveyInfoResource } = yield select(getSurveyListStateProps);
  const surveyId = selectedSurveyInfoResource.get("_id");

  yield put<NetworkRequestAction>(
    surveyListActionCreators.closeSurveyRequest(surveyId)
  );
}

export const surveyListSagas = freeze([

  // ~~~~~~~~~~~~~ URL MANAGEMENT ~~~~~~~~~~~~~~~~

  /*
    Listen for modification of list, in order to push the new state in the URL.
   */
  takeLatest(
    SurveyListActionTypes.MODIFY_LIST,
    updateLocationUrl.bind(null, false)
  ),

  takeLatest(
    SurveyListActionTypes.TOGGLE_REMINDER_MODAL_OPEN,
    fetchSurvey
  ),

  takeLatest(
    SurveyListActionTypes.SEND_SURVEY_REMINDER,
    sendSurveyReminders
  ),

  takeLatest(
    SurveyListActionTypes.DELETE_SURVEY,
    deleteSurvey
  ),
  takeLatest(
    [
      SurveyListActionTypes.DELETE_SURVEY_SUCCESS,
      SurveyListActionTypes.CLOSE_SURVEY_SUCCESS,
      SurveyListActionTypes.SEND_SURVEY_REMINDERS_SUCCESS,
      SurveyCreateActions.CREATE_SURVEY_SUCCESS,
      SurveyCreateActions.LAUNCH_SURVEY_SUCCESS,
    ],
    resetResources
  ),

  takeLatest(
    SurveyListActionTypes.CLOSE_SURVEY,
    closeSurvey
  ),
]);
