import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import styled from "styled-components";

import { Dictionary } from "@hyphen-lib/domain/structure/Dictionary";
import { areEquals, getOr, isNotNullNorUndefined } from "@hyphen-lib/lang/Objects";
import SurveyReportHeader from "@screens/Insights/Survey/components/SurveyReportHeader";
import ViewOptions, { getViewOptionValuesFromLocation } from "@screens/Insights/components/ViewOptions";

import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { CompareWithOption } from "@screens/Insights/components/ViewOptions/components/CompareWith";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { AnonymityFiltered } from "@components/core/AnonymityFiltered";
import { getViewOptionDefinitions, mapViewOptionsToState } from "@src/utils/ViewOptions";
import { areArraysEqual, isEmpty } from "hyphen-lib/dist/lang/Arrays";
import { Participation } from "hyphen-lib/dist/domain/common/Participation";
import { OverviewReportResource } from "hyphen-lib/dist/domain/resource/survey/report/OverviewReportResource";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
import { TrendResource } from "hyphen-lib/dist/domain/resource/survey/report/TrendResource";
import Segments from "./components/Segments";
import Comments from "./components/Comments";
import Overview from "./components/Overview";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import SurveyTopicsChart from "../Topics/SurveyTopicsChart";

interface OwnProps {
  readonly surveyName: string;
  readonly participation: Participation;
  readonly enabledFilters: string[];
  readonly enabledCustomFilters?: string[];
  readonly enabledViewOptions: string[];
  readonly compareWithOptions: CompareWithOption[];
  readonly surveyId: string;
  readonly hasActionCreationRight: boolean;
  readonly anonymityThreshold: number;
  readonly isFiltersAvailable: boolean;
  readonly overview: Loadable<OverviewReportResource>;
  readonly dimensions: Optional<Dimensions>;
  readonly hasCommentsAccess: boolean;
  readonly hasTopicsAccess: boolean;
  readonly surveyTrend: Loadable<TrendResource>;
  readonly companyName: string;
  readonly filteredParticipantData: Participation;
  readonly onCreateFocusArea: (
    focusArea: Partial<FocusAreaResource>
  ) => void;
  readonly postAndSurveySentiment?: boolean;
}

type Props = OwnProps & RouteComponentProps;

interface OverviewReportState {
  readonly viewOptions: {
    readonly comparison: boolean;
    readonly compareWith: Optional<string>;
  };
}

export class OverviewReport extends React.Component<Props, OverviewReportState> {
  constructor(props: Props) {
    super(props);

    const viewOptions = getViewOptionValuesFromLocation(props.location);

    this.state = mapViewOptionsToState(
      viewOptions,
      props.compareWithOptions,
      this.getDefaultViewOptionValues()
    );
  }

  componentDidUpdate(prevProps: Props): void {
    const prevViewOptions = parseQueryString(prevProps.location.search).viewOptions;
    const currentViewOptions = parseQueryString(this.props.location.search).viewOptions;
    if (not(areEquals(prevViewOptions, currentViewOptions)) ||
      not(areArraysEqual(prevProps.compareWithOptions, this.props.compareWithOptions)) ||
      not(areArraysEqual(prevProps.enabledViewOptions, this.props.enabledViewOptions))) {
      this.setState(
        mapViewOptionsToState(
          getViewOptionValuesFromLocation(this.props.location),
          this.props.compareWithOptions,
          this.getDefaultViewOptionValues()
        )
      );
    }
  }

  getDefaultViewOptionValues = () => {
    return {
      comparison: true,
    };
  };

  handleViewOptionsChange = (viewOptions: Dictionary<any>) => {
    this.setState(
      mapViewOptionsToState(
        viewOptions,
        this.props.compareWithOptions,
        this.getDefaultViewOptionValues()
      )
    );
  };

  getViewOptionsComponent = (enabledViewOptions: string[],
    compareWithOptions: CompareWithOption[]): React.ReactNode => {

    const viewOptions = getViewOptionDefinitions(enabledViewOptions, compareWithOptions);
    if (isEmpty(viewOptions)) {
      return null;
    }

    return (
      <ViewOptions
        viewOptions={viewOptions}
        defaultValues={this.state.viewOptions}
        onChange={this.handleViewOptionsChange}
      />
    );
  };

  render() {
    const {
      surveyName,
      participation,
      overview,
      dimensions,
      enabledFilters,
      enabledCustomFilters,
      enabledViewOptions,
      compareWithOptions,
      surveyId,
      hasActionCreationRight,
      anonymityThreshold,
      isFiltersAvailable,
      hasCommentsAccess,
      hasTopicsAccess,
      surveyTrend,
      postAndSurveySentiment
    } = this.props;
    
    const {
      viewOptions,
    } = this.state;
    const isLinkedSurveyTrendsPresent = Loadable.isLoaded(surveyTrend) &&
      isNotNullNorUndefined(surveyTrend.value) &&
      surveyTrend.value.trends.length > 1;

    return (
      <div>
        <SurveyReportHeader
          participation={participation}
          enabledFilters={enabledFilters}
          enabledCustomFilters={enabledCustomFilters}
          viewOptionsComponent={this.getViewOptionsComponent(enabledViewOptions, compareWithOptions)}
          exportOption="overview"
          isLinkedSurveyTrendsPresent={isLinkedSurveyTrendsPresent}
        />
        {Loadable.isNotLoaded(overview) || Loadable.isNotLoaded(surveyTrend) ?
          <LoadingContainer>Loading...</LoadingContainer> :
          this.renderOverviewReport(
            surveyName,
            overview.value,
            dimensions,
            viewOptions,
            compareWithOptions,
            hasActionCreationRight,
            anonymityThreshold,
            isFiltersAvailable,
            surveyId,
            hasCommentsAccess,
            hasTopicsAccess,
            surveyTrend,
            postAndSurveySentiment
          )
        }
      </div>
    );
  }

  // noinspection JSMethodCanBeStatic
  renderOverviewReport(surveyName: string,
    overview: OverviewReportResource,
    dimensions: Optional<Dimensions>,
    viewOptions: OverviewReportState["viewOptions"],
    compareWithOptions: CompareWithOption[],
    hasActionCreationRight: boolean,
    anonymityThreshold: number,
    isFiltersAvailable: boolean,
    surveyId: Optional<string>,
    hasCommentsAccess: boolean,
    hasTopicsAccess: boolean,
    surveyTrend: Loadable<TrendResource>,
    postAndSurveySentiment?: boolean) {

    if (overview.filteredForAnonymity) {
      return (
        <AnonymityContainer filters={isFiltersAvailable} >
          <AnonymityFiltered
            explanation={overview.filteredForAnonymityReason!}
            anonymityThreshold={anonymityThreshold}
            translate="yes"
          />
        </AnonymityContainer>
      );
    }

    return <>
      <FirstSectionContainer filters={isFiltersAvailable} >
        <Overview
          data={overview}
          areComparisonsVisible={viewOptions.comparison}
          compareWithOptions={compareWithOptions}
          comparisonKey={viewOptions.compareWith}
          sectionName={`${surveyName} - Overview report`}
          hasActionCreationRight={hasActionCreationRight}
          anonymityThreshold={anonymityThreshold}
          surveyId={surveyId!}
          surveyTrend={surveyTrend}
          onCreateFocusArea={this.props.onCreateFocusArea}
          surveyName={this.props.surveyName}
          companyName={this.props.companyName} 
          participation={this.props.filteredParticipantData}
        />
      </FirstSectionContainer>
      <SectionContainer>
        {
          overview.isTopicAnalysisEnabled && hasTopicsAccess ? 
          getOr(postAndSurveySentiment, true) && <SurveyTopicsChart
            title={"Top Topics"}
            topics={overview.topics}
            surveyId={surveyId!}
            onlyTopics={true}
            infoMessage={"These are the top topics identified for this survey."}
            sectionName={`${surveyName} - Overview report`}
          /> : <Comments
            data={overview}
            surveyId={surveyId}
            hasCommentsAccess={hasCommentsAccess}
            postAndSurveySentiment={postAndSurveySentiment}
          />
        }
      </SectionContainer>
      <SectionContainer>
        <Segments
          data={overview}
          dimensions={dimensions}
          areComparisonsVisible={viewOptions.comparison}
          compareWithOptions={compareWithOptions}
          comparisonKey={viewOptions.compareWith}
          hasActionCreationRight={hasActionCreationRight}
          onCreateFocusArea={this.props.onCreateFocusArea}
          focusAreaLabel={surveyName}
        />
      </SectionContainer>
    </>;
  }
}

const FirstSectionContainer = styled.div<{ filters: boolean }>`
  margin-top: 24px;
  @media print {
    padding-top: ${props => props.filters ? "" : "60px"};
  }
`;

const SectionContainer = styled.div`
  margin-top: 24px;
`;

const AnonymityContainer = styled.div<{ filters: boolean }>`
  margin: 24px 0;
  padding: 24px 0;
  background: white;
  @media-print {
    padding-top: ${props => props.filters ? "" : "50px"};
  }

`;

const LoadingContainer = styled.div`
  text-align: center;
  background: white;
  padding: 24px;
`;

export default withRouter(OverviewReport);
