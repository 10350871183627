import {
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from "@redux-saga/core/effects";
import {
  AddAccessesAction,
  FetchSurveyParticipantsAction,
  FetchSurveySuccessAction,
  LaunchSurveyAction,
  LaunchSurveyErrorAction,
  RemoveAccessAction,
  SaveSurveyAction,
  surveyEditActionCreators,
  SurveyEditActionTypes,
  UpdateAccessAction,
  UpdateOptimisticSurveySuccessAction,
  UpdatePessimisticSurveySuccessAction,
  UpdateSurveyAction,
} from "@screens/Insights/Surveys/store/surveyEditActions";
import {
  areNotEquals,
  dump,
  freeze,
  getOr,
  isNotNullNorUndefined,
} from "hyphen-lib/dist/lang/Objects";
import { fetchMergeTagsIfNeeded } from "@src/store/network/resource/MergeTagResources";
import { SurveyResource } from "hyphen-lib/dist/domain/resource/SurveyResource";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import { checkSurveyResourceConsistency } from "hyphen-lib/dist/business/survey/SurveyResources";
import Logger from "@hyphen-lib/util/Logger";
import { isInstanceOfException } from "hyphen-lib/dist/lang/exception/Exceptions";
import { Survey } from "hyphen-lib/dist/domain/Survey";
import {
  getSurvey,
  getSurveyAccesses,
  getSurveyValidationErrors,
  hasSurveyPendingAccessesUpdates,
  hasSurveyPendingAudienceUpdates,
} from "@screens/Insights/Surveys/store/surveyEditSelectors";
import { fetchSurveyParticipantCountIfNeeded } from "@store/network/resource/ParticipantCountResources";
import { getConfigurationFor } from "hyphen-lib/dist/business/survey/SurveyTypes";
import {
  fetchEligibleManagersCountIfNeeded,
  fetchManageesIfNeeded,
} from "@store/network/resource/Untypeds";
import { getCurrentUserId } from "@screens/Insights/store/selectors";
import { Seq } from "immutable";
import { on, onlyField } from "hyphen-lib/dist/lang/Functions";
import {
  AccessResource,
  AccessSource,
} from "hyphen-lib/dist/domain/access/AccessResource";
import { AccessList } from "hyphen-lib/dist/domain/access/AccessList";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import notificationsActionCreators, {
  ShowNotificationAction,
} from "@store/notifications/actions";
import * as NotificationFactory from "@src/store/notifications/notification-factory";
import { goTo } from "@src/utils/locations";
import {
  actionCreators as networkActionCreators,
  CleanResourceAction,
} from "@store/network/actions";
import { SurveyInfoResource } from "hyphen-lib/dist/domain/resource/SurveyInfoResource";
import { fetchSurveyParticipantsIfNeeded } from "@store/network/resource/ParticipantResources";
import { fetchDifferentialAudienceCountIfNeeded } from "@src/store/network/resource/DifferentialAudienceCountResources";
import { DifferentialAudienceCountResource } from "hyphen-lib/dist/domain/resource/DifferentialAudienceResource";
import { OverviewReportResource } from "hyphen-lib/dist/domain/resource/survey/report/OverviewReportResource";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";

const log = Logger.create("surveyEditSagas");

/*
  Fetch the merge tags when receiving the survey
 */
export function* fetchSurveySuccess(action: FetchSurveySuccessAction) {
  const survey = action.payload.data;

  // fetch the merge tags
  yield put(fetchMergeTagsIfNeeded(survey.type));
  // also fetch the specific audience if needed
  yield fetchSpecificAudienceEmailsIfNeeded(survey.type);

  // let's validate the survey consistency
  const errors = validateSurvey(survey);
  yield put(surveyEditActionCreators.updateValidationErrors(errors));

  // fetch participant count
  yield put(fetchSurveyParticipantCountIfNeeded(survey._id, survey));

  // fetch newly added users
  yield put(fetchDifferentialAudienceCountIfNeeded(survey._id));

  // fetch eligible managers count
  yield put(fetchEligibleManagersCountIfNeeded(survey._id, survey.audience));
}

/*
  Fetch the participants, analyze if we need to use the pending audience or not.

  We will analyze if the survey is launched and if the audience has been modified.
 */
export function* fetchParticipants({
  payload: { surveyId, survey },
}: FetchSurveyParticipantsAction) {
  const hasPendingUpdates = yield select(hasSurveyPendingAudienceUpdates);
  yield put(
    fetchSurveyParticipantsIfNeeded(
      surveyId,
      survey,
      hasPendingUpdates && survey.status === Survey.Statuses.LAUNCHED
    )
  );
}

/*
  Here we will do:
  - check the survey consistency
    + yield the action SURVEY_VALIDATION_ERRORS if there are some errors
  - if survey status is DRAFT => we do optimistic management:
    + do the save: UPDATE_OPTIMISTIC_SURVEY_REQUEST (every time, we don't care if there are errors) =>
      for the response, we will not modify the store,
      just the date for the last save and display a notification if the save request is in error

  (for pessimistic there is nothing to do, this action will be reduced by the reducer,
  and the survey will be updated in the store)
 */
export function* updateSurvey({
  payload: { surveyId, survey },
}: UpdateSurveyAction) {
  const currentSurvey = yield select(getSurvey, surveyId);

  // fetch the merge tags as the type might have been changed
  yield put(fetchMergeTagsIfNeeded(survey.type));
  // also fetch the specific audience if needed
  yield fetchSpecificAudienceEmailsIfNeeded(survey.type);

  // let's validate the survey consistency
  const errors = validateSurvey(survey);
  yield put(surveyEditActionCreators.updateValidationErrors(errors));

  // then if the survey is a draft, trigger an optimistic update in any case
  if (survey.status === Survey.Statuses.DRAFT) {
    yield put(
      surveyEditActionCreators.updateOptimisticSurveyRequest(surveyId, survey)
    );
  } else if (survey.status === Survey.Statuses.LAUNCHED) {
    // if the audience has been modified we will need to fetch the participants count
    if (areNotEquals(currentSurvey.audience, survey.audience)) {
      yield put(fetchSurveyParticipantCountIfNeeded(survey._id, survey, true));
    }
  }
}

function* fetchSpecificAudienceEmailsIfNeeded(surveyType: Survey.Type) {
  const configuration = getConfigurationFor(surveyType);
  const userId = yield select(getCurrentUserId);
  yield put(
    fetchManageesIfNeeded(
      userId,
      configuration.audienceConfiguration.statusRestriction
    )
  );
}

/*
  The survey have been updated with optimistic update, so we might want to trigger some fetches...
 */
export function* updateOptimisticSurveySuccess({
  payload: { data: survey },
}: UpdateOptimisticSurveySuccessAction) {
  // fetch the participant count
  yield put(fetchSurveyParticipantCountIfNeeded(survey._id, survey));
  // fetch eligible managers count
  yield put(fetchEligibleManagersCountIfNeeded(survey._id, survey.audience));
}

/*
  The survey have been updated with optimistic update, so we might want to trigger some fetches...
 */
export function* updatePessimisticSurveySuccess({
  payload: { data: survey },
}: UpdatePessimisticSurveySuccessAction) {
  // fetch eligible managers count
  yield call(cleanCache);
  yield put(fetchEligibleManagersCountIfNeeded(survey._id, survey.audience));
  yield put(
    notificationsActionCreators.displayNotification(
      NotificationFactory.success("Survey updated", "", 4.5)
    )
  );
}

function validateSurvey(survey: SurveyResource): WrongEntityException.Errors {
  try {
    checkSurveyResourceConsistency(survey, {
      failFast: false,
      forcedStatus: survey.status as Survey.Statuses,
      channelStrictMode: true,
    });
  } catch (e) {
    if (isInstanceOfException(e, WrongEntityException)) {
      return WrongEntityException.extractErrors(e.errors());
    } else {
      log.error(`Unexpected error during survey validation ${e}`, e);
    }
  }

  return WrongEntityException.noErrors();
}

/*
  Note this method is called only for pessimistic.

  We will do:
  - if there are no validation error, and only if there are no errors:
    + do the save: UPDATE_PESSIMISTIC_SURVEY_REQUEST
 */
export function* saveSurvey({
  payload: { surveyId, survey },
}: SaveSurveyAction) {
  const errors = yield select(getSurveyValidationErrors);
  if (WrongEntityException.hasNoErrors(errors)) {
    let accesses: Optional<AccessResource[]> = Optional.empty();
    const hasAccessesUpdates = yield select(hasSurveyPendingAccessesUpdates);
    if (hasAccessesUpdates) {
      accesses = filterAccessesToRemovePermissions(
        yield select(getSurveyAccesses)
      );
    }
    yield put(
      surveyEditActionCreators.updatePessimisticSurveyRequest(
        surveyId,
        survey,
        accesses
      )
    );
  } else {
    // eslint-disable-next-line max-len
    log.warn(
      `Save survey called and there are validation errors, so skipping the save request... ${dump(
        errors,
        true
      )}`
    );
  }
}

/*
  We don't want to send the accesses that are from the permissions.

  So we will remove all accesses having a source being DERIVED, and where the role have not been modified.
 */
export function filterAccessesToRemovePermissions(
  accesses: AccessResource[]
): AccessResource[] {
  return Seq(accesses)
    .filter(
      (access) =>
        access.accessSource !== AccessSource.DERIVED ||
        access.globalAccess !== access.role
    )
    .map((access) => {
      if (access.accessSource === AccessSource.DERIVED) {
        return {
          ...access,
          accessSource: AccessSource.MANUAL,
          _id: access.email
        };
      }
      return {
        ...access,
        _id: access.email
      };
    })
    .toArray();
}

/*
  Modifications have been made in questions
 */
export function* modifyQuestions(action: any) {
  const surveyId = action.meta.surveyId;
  const survey: Loadable<SurveyResource> = yield select(getSurvey, surveyId);
  if (Loadable.isLoaded(survey)) {
    // let's validate the survey consistency
    const errors = validateSurvey(survey.value);
    yield put(surveyEditActionCreators.updateValidationErrors(errors));
  }
}

export function* addAccesses({
  payload: { surveyId, emails },
}: AddAccessesAction) {
  const existingAccesses = yield select(getSurveyAccesses);
  const existingAccessesEmails = Seq(existingAccesses)
    .map(onlyField("email"))
    .toSet();
 
  const accessesToAdd: AccessResource[] = Seq(emails)
    .filter((email) => not(existingAccessesEmails.has(email)))
    .map((email) => ({
      _id: email,
      _type: AccessResource.TYPE as typeof AccessResource.TYPE,
      email,
      role: AccessList.UserAccessRole.READ,
      accessSource: AccessSource.MANUAL,
    }))
    .toArray();

    const newAccessList: AccessResource[] = Seq(accessesToAdd)
    .concat(existingAccesses)
    .sort(on("email"))
    .toArray();

  yield put(surveyEditActionCreators.updateAccessList(newAccessList));

  const survey: Loadable<SurveyResource> = yield select(getSurvey, surveyId);
  if (
    Loadable.isLoaded(survey) &&
    survey.value.status === Survey.Statuses.DRAFT
  ) {
    // in case the status is draft we send the modification request every time
    yield put(
      surveyEditActionCreators.addAccessesRequest(surveyId, accessesToAdd)
    );
  }
}

export function* updateAccess({
  payload: { surveyId, accessId, role },
}: UpdateAccessAction) {
  const existingAccesses = yield select(getSurveyAccesses);

  const newAccessList: AccessResource[] = [];
  let modifiedAccess: Optional<AccessResource> = Optional.empty();
  for (const access of existingAccesses) {
    if (access.email === accessId) {
      modifiedAccess = {
        ...access,
        role,
      };
      newAccessList.push(modifiedAccess as AccessResource);
    } else {
      newAccessList.push(access);
    }
  }

  if (isNotNullNorUndefined(modifiedAccess)) {
    yield put(surveyEditActionCreators.updateAccessList(newAccessList));

    const survey: Loadable<SurveyResource> = yield select(getSurvey, surveyId);
    if (
      Loadable.isLoaded(survey) &&
      survey.value.status === Survey.Statuses.DRAFT
    ) {
      // in case the status is draft we send the modification request every time
      yield put(
        surveyEditActionCreators.updateAccessRequest(
          surveyId,
          accessId,
          modifiedAccess
        )
      );
    }
  }
}

export function* removeAccess({
  payload: { surveyId, accessId },
}: RemoveAccessAction) {
  const existingAccesses: AccessResource[] = yield select(getSurveyAccesses);

  const newAccessList: AccessResource[] = existingAccesses.filter(
    (access) => access.email !== accessId
  );

  if (newAccessList.length !== existingAccesses.length) {
    yield put(surveyEditActionCreators.updateAccessList(newAccessList));

    const survey: Loadable<SurveyResource> = yield select(getSurvey, surveyId);
    if (
      Loadable.isLoaded(survey) &&
      survey.value.status === Survey.Statuses.DRAFT
    ) {
      // in case the status is draft we send the modification request every time
      yield put(
        surveyEditActionCreators.removeAccessRequest(surveyId, accessId)
      );
    }
  }
}

/*
  Note this method will work a bit like the update, but adding a final step, the request
  for the survey to launch, only of the consistency is in success

  We will do only if there is no validation errors:
    + do request the survey launch LAUNCH_SURVEY_REQUEST
 */
export function* launchSurvey({ payload: { surveyId } }: LaunchSurveyAction) {
  const errors = yield select(getSurveyValidationErrors);
  if (WrongEntityException.hasNoErrors(errors)) {
    yield put(surveyEditActionCreators.launchSurveyRequest(surveyId));
  } else {
    // eslint-disable-next-line max-len
    log.warn(
      `Launch survey called and there are validation errors, so skipping the launch request... ${dump(
        errors,
        true
      )}`
    );
  }
}

/*
  Manage the launch success action, it will display a notification and redirect the user to the survey list
 */
export function* manageLaunchSuccess() {
  goTo("/surveys");
  yield put(
    notificationsActionCreators.displayNotification(
      NotificationFactory.success("Your Survey has launched", "", 4.5)
    )
  );
}

/*
  Display an error notification if the survey can not be launched.
 */
export function* manageLaunchError({
  payload: { error },
}: LaunchSurveyErrorAction) {
  yield put(
    notificationsActionCreators.displayNotification(
      NotificationFactory.error(
        "Unable to launch the survey.",
        getOr(error, ""),
        4.5
      )
    )
  );
}

/*
  Display an error notification if the survey can not be saved.
 */
export function* manageUpdateError({ payload: { error } }: any) {
  yield put(
    notificationsActionCreators.displayNotification(
      NotificationFactory.error(
        "Unable to save the survey.",
        getOr(Array.isArray(error) ? error[0].reason : error.reason, ""),
        4.5
      )
    )
  );
}

export function* manageSendSurveyPreviewSuccess() {
  yield put(
    notificationsActionCreators.displayNotification(
      NotificationFactory.success(
        "Preview sent!",
        "Your recipients will receive the preview in their inbox shortly",
        4.5
      )
    )
  );
}

export function* cleanCache() {
  yield put<CleanResourceAction>(
    networkActionCreators.cleanResource(SurveyResource.TYPE)
  );
  yield put<CleanResourceAction>(
    networkActionCreators.cleanResource(SurveyInfoResource.TYPE)
  );
  yield put<CleanResourceAction>(
    networkActionCreators.cleanResource(OverviewReportResource.TYPE)
  );
}

export function* manageUpdateDifferentialAudienceSuccess() {
  yield put(
    notificationsActionCreators.displayNotification(
      NotificationFactory.success(
        "Updated Audience",
        "Successfully added new users to Survey",
        4.5
      )
    )
  );

  yield put<CleanResourceAction>(
    networkActionCreators.cleanResource(DifferentialAudienceCountResource.TYPE)
  );
}

export function* manageUpdateDifferentialAudienceError() {
  yield put(
    notificationsActionCreators.displayNotification(
      NotificationFactory.error(
        "Error",
        "An error occurred while trying to add new Users to the Survey",
        4.5
      )
    )
  );
}

function* onQuestionUpdateError(actionResponse: any) {
  const { payload } = actionResponse;
  let message = "";
  if (isNotNullNorUndefined(payload.error)) {
    const { error } = payload;
    if (error.length > 0) {
      message = getOr(error[0].reason, message);
    }
  }

  if (isStringAndNotEmpty(message)) {
    yield put<ShowNotificationAction>(
      notificationsActionCreators.displayNotification({
        message,
        description: "",
        type: "error",
      })
    );
  }
}

export const surveyEditSagas = freeze([
  takeLatest(SurveyEditActionTypes.FETCH_SURVEY_SUCCESS, fetchSurveySuccess),

  takeLatest(SurveyEditActionTypes.FETCH_PARTICIPANTS, fetchParticipants),

  takeLatest(
    SurveyEditActionTypes.UPDATE_OPTIMISTIC_SURVEY_SUCCESS,
    updateOptimisticSurveySuccess
  ),

  takeLatest(
    SurveyEditActionTypes.UPDATE_PESSIMISTIC_SURVEY_SUCCESS,
    updatePessimisticSurveySuccess
  ),

  takeLatest(SurveyEditActionTypes.UPDATE_SURVEY, updateSurvey),

  takeLatest(SurveyEditActionTypes.SAVE_SURVEY, saveSurvey),

  takeLatest(
    [
      SurveyEditActionTypes.ADD_QUESTION_SUCCESS,
      SurveyEditActionTypes.UPDATE_QUESTION_SUCCESS,
      SurveyEditActionTypes.REMOVE_QUESTION_SUCCESS,
    ],
    modifyQuestions
  ),

  takeEvery(SurveyEditActionTypes.ADD_ACCESSES, addAccesses),

  takeEvery(SurveyEditActionTypes.UPDATE_ACCESS, updateAccess),

  takeEvery(SurveyEditActionTypes.REMOVE_ACCESS, removeAccess),

  takeLatest(SurveyEditActionTypes.LAUNCH_SURVEY, launchSurvey),

  takeLatest(SurveyEditActionTypes.LAUNCH_SURVEY_SUCCESS, manageLaunchSuccess),

  takeLatest(SurveyEditActionTypes.LAUNCH_SURVEY_ERROR, manageLaunchError),

  takeLatest(
    [
      SurveyEditActionTypes.UPDATE_OPTIMISTIC_SURVEY_ERROR,
      SurveyEditActionTypes.UPDATE_PESSIMISTIC_SURVEY_ERROR,
    ],
    manageUpdateError
  ),

  takeLatest(
    SurveyEditActionTypes.SEND_SURVEY_PREVIEW_SUCCESS,
    manageSendSurveyPreviewSuccess
  ),

  takeLatest(SurveyEditActionTypes.EXIT_PAGE, cleanCache),

  takeLatest(
    SurveyEditActionTypes.UPDATE_DIFFERENTIAL_AUDIENCE_SUCCESS,
    manageUpdateDifferentialAudienceSuccess
  ),

  takeLatest(
    SurveyEditActionTypes.UPDATE_DIFFERENTIAL_AUDIENCE_ERROR,
    manageUpdateDifferentialAudienceError
  ),
  takeLatest(
    [
      SurveyEditActionTypes.UPDATE_QUESTION_ERROR,
      SurveyEditActionTypes.ADD_QUESTION_ERROR,
    ],
    onQuestionUpdateError
  ),
]);
