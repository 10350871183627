import React from "react";
import styled from "styled-components";

interface Props {
  size: number;
  percentage: number;
  rotation: number;
  color: string;
}

export function getSliceRotation(percentage: number) {
  return percentage / 100 * 360;
}

function getSliceContainerRect(size: number, percentage: number) {
  const leftPosition = percentage <= 50 ? size / 2 : 0;
  return `rect(0, ${size}px, ${size}px, ${leftPosition}px)`;
}

function getSliceRect(size: number, percentage: number) {
  const rightPosition = percentage <= 50 ? size / 2 : size;
  return `rect(0, ${rightPosition}px, ${size}px, 0)`;
}

export function SentimentDonutSlice({ size, percentage, rotation, color }: Props) {
  return (
    <Container size={size} percentage={percentage} rotation={rotation}>
      <Slice
        size={size}
        percentage={percentage}
        rotation={rotation}
        color={color}
      />
    </Container>
  );
}

const Container = styled.div<{ size: number; percentage: number; rotation: number }>`
  position: absolute;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 50%;
  transform: rotate(${props => props.rotation}deg);
  clip: ${props => getSliceContainerRect(props.size, props.percentage)};
  z-index: ${props => props.percentage > 50 ? 5 : 10};
`;

const Slice = styled.div<{ size: number; percentage: number; rotation: number; color: string }>`
  position: absolute;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  clip: ${props => getSliceRect(props.size, props.percentage)};
  border-radius: 50%;
  transform: rotate(${props => getSliceRotation(props.percentage)}deg);
  background: ${props => props.color};
`;
