import styled from "styled-components";
import AntTextArea, { TextAreaProps } from "antd/lib/input/TextArea";
import Palette from "@src/config/theme/palette";
import { withFieldLabels } from "@components/core/withFieldLabels";

const StyledTextArea = styled(AntTextArea)<TextAreaProps>`
  border-radius: 3px !important;
  margin: 4px 0 !important;
  :focus, :hover {
    border-color: ${Palette.bluePurple} !important;
  }
`;

export const TextArea = withFieldLabels(StyledTextArea);
