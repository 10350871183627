import { Seq } from "immutable";

import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { GroupInfoResource } from "hyphen-lib/dist/domain/resource/group/GroupsAndChannelsResource";

import { FilterParameter } from "@src/utils/networks";
import { FiltersContent } from "@src/screens/Insights/components/FiltersContent";
import { SentimentFilter } from "@src/screens/Insights/components/Filters/business/SurveyFilters";
import { DateFilter } from "@src/screens/Insights/components/Filters/business/DateFilter";
import { GroupsAndChannelsFilter } from "@src/screens/Insights/components/Filters/business/GroupsAndChannelsFilter";
import { Group } from "hyphen-lib/dist/domain/Group";
import { applyDefault } from "@src/utils/Currier";

interface VoiceReportsFiltersProps {
  readonly values?: Dictionary<any>;
  readonly groupsAndChannels: GroupInfoResource[];
  readonly onApply: (filter: FilterParameter) => void;
  readonly onChange: (filter: FilterParameter) => void;
}

export function VoiceReportsFilters(props: VoiceReportsFiltersProps) {
  const { onApply, values, onChange, groupsAndChannels } = props;
  function getFilteredOptions(filterBy: Group.Type) {
    return Seq(groupsAndChannels)
      .filter(function filterByGroup(group) {
        return group.type === filterBy;
      })
      .map(function groupOptionsMapper(group) {
        return {
          value: group.name,
          label: group.name,
        };
      })
      .toArray();
  }
  const groups = getFilteredOptions(Group.Types.PRIVATE_GROUP);
  const channels = getFilteredOptions(Group.Types.CHANNEL);
  return (
    <FiltersContent
      filters={[
        {
          key: "createdAtDate",
          label: "Date range",
          component: DateFilter,
        },
        {
          key: "sentiments",
          label: "Sentiments",
          component: SentimentFilter,
          hidden: false,
        },
        {
          key: "groups",
          label: "Groups and Channels",
          component: applyDefault(
            GroupsAndChannelsFilter,
            {
              groups,
              channels,
            }
          ),
          hidden: false,
        },
      ]}
      onChange={onChange}
      displayCount={false}
      onApply={onApply}
      values={values}
    />
  );
}
