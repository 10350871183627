import Logger from "hyphen-lib/dist/util/Logger";
import { freeze, isNotNullNorUndefined, dump, getOr } from "hyphen-lib/dist/lang/Objects";
import { takeLatest, call, put, select, takeEvery } from "redux-saga/effects";
import { PulsePollResource } from "hyphen-lib/dist/domain/resource/PulsePollResource";
import { replaceTo, goTo } from "@src/utils/locations";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import { checkPulsePollResourceConsistency } from "hyphen-lib/dist/business/pulsePoll/PulsePollResources";
import { isInstanceOfException } from "hyphen-lib/dist/lang/exception/Exceptions";
import { fetchPulsePollParticipantCountIfNeeded } from "@src/store/network/resource/ParticipantCountResources";
import { actionCreators as SurveysActionCreator } from "@screens/Insights/Surveys/store/actions";
import { Post } from "hyphen-lib/dist/domain/Post";
import { Seq } from "immutable";
import {
  fetchManageesIfNeeded,
  fetchPulsePollEligibleManagersCountIfNeeded
} from "@src/store/network/resource/Untypeds";
import { Audience } from "hyphen-lib/dist/domain/common/Audience";
import { onlyField, on } from "hyphen-lib/dist/lang/Functions";
import { AccessResource, AccessSource } from "hyphen-lib/dist/domain/access/AccessResource";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { AccessList } from "hyphen-lib/dist/domain/access/AccessList";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import notificationsActionCreators from "@store/notifications/actions";
import * as NotificationFactory from "@src/store/notifications/notification-factory";
import { actionCreators as networkActionCreators, CleanResourceAction } from "@src/store/network/actions";
import { PulsePollInfoResource } from "hyphen-lib/dist/domain/resource/PulsePollInfoResource";
import {
  PulsePollOverviewReportResource
} from "hyphen-lib/dist/domain/resource/pulsePoll/report/PulsePollOverviewReportResource";
import { QuestionConfig } from "hyphen-lib/dist/domain/common/QuestionType";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { filterAccessesToRemovePermissions } from "../../Surveys/store/surveyEditSagas";
import { getCurrentUserId } from "../../store/selectors";
import {
  getPulsePoll,
  getPulsePollEditState,
  hasPulsePollPendingAccessesUpdates,
  getPulsePollAccesses
} from "./pulsePollEditSelectors";
import {
  PulsePollEditActionTypes,
  pulsePollActionCreators,
  UpdatePulsePollAction,
  UpdatePulsePollSuccessAction,
  AddAccessesAction,
  UpdateAccessAction,
  RemoveAccessAction,
  SavePulsePollAction,
  ActivatePulsePollAction,
  ActivatePulsePollErrorAction
} from "./pulsePollEditActions";

const log = Logger.create("pulsePollEditSagas");

export const pulsePollEditSagas = freeze([
  /*
    Listen for modification of list, in order to push the new state in the URL.
   */
  takeLatest(
    [
      PulsePollEditActionTypes.CREATE_PULSE_POLLS_SUCCESS,
      PulsePollEditActionTypes.FETCH_PULSE_POLLS_SUCCESS,
    ],
    fetchPulsePollSuccess
  ),

  takeLatest(
    PulsePollEditActionTypes.UPDATE_PULSE_POLL,
    updatePulsePoll
  ),

  takeLatest(
    PulsePollEditActionTypes.UPDATE_PULSE_POLLS_SUCCESS,
    updatePulsePollSuccess
  ),

  takeEvery(
    PulsePollEditActionTypes.ADD_ACCESSES,
    addAccesses
  ),

  takeEvery(
    PulsePollEditActionTypes.UPDATE_ACCESS,
    updateAccess
  ),

  takeEvery(
    PulsePollEditActionTypes.REMOVE_ACCESS,
    removeAccess
  ),

  takeEvery(
    PulsePollEditActionTypes.TOGGLE_MANAGERS_SYNCHRONIZATION_SUCCESS,
    updateAccessList
  ),

  takeLatest(
    PulsePollEditActionTypes.SAVE_PULSE_POLLS,
    savePulsePoll
  ),

  takeLatest(
    PulsePollEditActionTypes.ACTIVATE_PULSE_POLL,
    activatePoll
  ),

  takeLatest(
    PulsePollEditActionTypes.ACTIVATE_PULSE_POLL_SUCCESS,
    manageActivateSuccess
  ),

  takeLatest(
    PulsePollEditActionTypes.ACTIVATE_PULSE_POLL_ERROR,
    manageActivateError
  ),

  takeLatest(
    PulsePollEditActionTypes.UPDATE_PULSE_POLLS_ERROR,
    manageUpdateError
  ),

  takeLatest(
    PulsePollEditActionTypes.EXIT_PAGE,
    cleanCache
  ),
]);

function* fetchPulsePollSuccess(action: any) {
  const pulsePoll = action.payload.data as PulsePollResource;

  if (action.type === PulsePollEditActionTypes.CREATE_PULSE_POLLS_SUCCESS) {
    yield call(
      replaceTo,
      `/pulsePolls/edit/${pulsePoll._id}/settings`
    );
  }

  // also fetch the specific audience if needed
  yield fetchSpecificAudienceEmailsIfNeeded();

  // fetch participant count
  yield put(fetchPulsePollParticipantCountIfNeeded(pulsePoll._id, pulsePoll));

  // fetch poll access list
  yield put(pulsePollActionCreators.fetchPulsePollAccessList(pulsePoll._id));

  // Fetch the categories
  yield put(SurveysActionCreator.fetchPostCategorys());

  // fetch eligible managers count
  yield put(fetchPulsePollEligibleManagersCountIfNeeded(pulsePoll._id, pulsePoll.audience));
}

function validatePulsePoll(pulsePoll: PulsePollResource, questionConfig: QuestionConfig): WrongEntityException.Errors {
  try {
    checkPulsePollResourceConsistency(pulsePoll, questionConfig, {
      failFast: false,
      checkNextOccurrence: pulsePoll.status === Post.Status.DRAFT,
      forcedStatus: pulsePoll.status,
      channelStrictMode: true });
  } catch (e) {
    if (isInstanceOfException(e, WrongEntityException)) {
      return WrongEntityException.extractErrors(e.errors());
    } else {
      log.error(`Unexpected error during poll validation ${e}`, e);
    }
  }

  return WrongEntityException.noErrors();
}

function* updatePulsePoll({ payload: { pulsePollId, pulsePoll, questionConfig } }: UpdatePulsePollAction) {
  // fetch the audience EMAILS if needed.
  yield fetchSpecificAudienceEmailsIfNeeded();

  // lets validate the poll
  const errors = validatePulsePoll(pulsePoll, questionConfig);
  yield put(pulsePollActionCreators.updateValidationErrors(errors));

  // Trigger optimistic save if poll is draft
  if (pulsePoll.status === Post.Status.DRAFT) {
    yield put(pulsePollActionCreators.updatePulsePollRequest(pulsePollId, pulsePoll, questionConfig));
  }
  // Fetch audience optimistically only if the poll is active
  else if (pulsePoll.status === Post.Status.ACTIVE) {
    yield put(fetchPulsePollParticipantCountIfNeeded(pulsePollId, pulsePoll, true));
  }
}

/*
  update the pulsePoll and trigger some fetches, so we might want to trigger some fetches...
*/
export function* updatePulsePollSuccess({ payload: { data: pulsePoll } }: UpdatePulsePollSuccessAction) {

  // If the updating has been done optimistically
  if (pulsePoll.status === Post.Status.DRAFT) {
    yield call(updateOptimisticPulsePoll, pulsePoll);
  } else {
    yield call(cleanCache);
    yield put(notificationsActionCreators.displayNotification(
      NotificationFactory.success(
        "Your poll has been updated",
        "",
        4.5
      )
    ));
  }
  // fetch eligible managers count
  yield put((fetchPulsePollEligibleManagersCountIfNeeded(pulsePoll._id, pulsePoll.audience)));

}

function* updateOptimisticPulsePoll(pulsePoll: PulsePollResource) {
  // fetch the participant count
  yield put(fetchPulsePollParticipantCountIfNeeded(pulsePoll._id, pulsePoll));
}

function* fetchSpecificAudienceEmailsIfNeeded() {
  const configuration = Audience.Configuration.allAudienceAllowed();
  if (isNotNullNorUndefined(configuration.statusRestriction)) {
    const userId = yield select(getCurrentUserId);
    yield put(fetchManageesIfNeeded(userId, configuration.statusRestriction));
  }
}

export function* addAccesses({ payload: { pulsePollId, emails } }: AddAccessesAction) {
  const { accesses: existingAccesses } = yield select(getPulsePollEditState, pulsePollId);
  const existingAccessesEmails = Seq(existingAccesses).map(onlyField("email")).toSet();

  const accessesToAdd: AccessResource[] = Seq(emails)
    .filter(email => not(existingAccessesEmails.has(email)))
    .map(email => ({
      _id: email,
      _type: AccessResource.TYPE as typeof AccessResource.TYPE,
      email,
      role: AccessList.UserAccessRole.READ,
      accessSource: AccessSource.MANUAL,
    }))
    .toArray();

  const newAccessList: AccessResource[] = Seq(accessesToAdd)
    .concat(existingAccesses)
    .sort(on("email"))
    .toArray();

  yield put(pulsePollActionCreators.updateAccessList(newAccessList));

  const pulsePoll: Loadable<PulsePollResource> = yield select(getPulsePoll);
  if (Loadable.isLoaded(pulsePoll) && pulsePoll.value.status === Post.Status.DRAFT) {
    // in case the status is draft we send the modification request every time
    yield put(pulsePollActionCreators.addAccessesRequest(pulsePollId, accessesToAdd));
  }
}

export function* updateAccess({ payload: { pulsePollId, accessId, role } }: UpdateAccessAction) {
  const { accesses: existingAccesses } = yield select(getPulsePollEditState, pulsePollId);

  const newAccessList: AccessResource[] = [];
  let modifiedAccess: Optional<AccessResource> = Optional.empty();
  for (const access of existingAccesses) {
    if (access.email === accessId) {
      modifiedAccess = {
        ...access,
        role,
      };
      newAccessList.push(modifiedAccess as AccessResource);
    } else {
      newAccessList.push(access);
    }
  }

  if (isNotNullNorUndefined(modifiedAccess)) {
    yield put(pulsePollActionCreators.updateAccessList(newAccessList));

    const pulsePoll: Loadable<PulsePollResource> = yield select(getPulsePoll);
    if (Loadable.isLoaded(pulsePoll) && pulsePoll.value.status === Post.Status.DRAFT) {
      // in case the status is draft we send the modification request every time
      yield put(pulsePollActionCreators.updateAccessRequest(pulsePollId, accessId, modifiedAccess));
    }
  }
}

export function* removeAccess({ payload: { pulsePollId, accessId} }: RemoveAccessAction) {
  const { accesses: existingAccesses }: { accesses: AccessResource[] }
    = yield select(getPulsePollEditState, pulsePollId);

  const newAccessList: AccessResource[] = existingAccesses.filter(access => access.email !== accessId);

  if (newAccessList.length !== existingAccesses.length) {
    yield put(pulsePollActionCreators.updateAccessList(newAccessList));

    const pulsePoll: Loadable<PulsePollResource> = yield select(getPulsePoll);
    if (Loadable.isLoaded(pulsePoll) && pulsePoll.value.status === Post.Status.DRAFT) {
      // in case the status is draft we send the modification request every time
      yield put(pulsePollActionCreators.removeAccessRequest(pulsePollId, accessId));
    }
  }
}

export function* savePulsePoll({
  payload: { pulsePollId, pulsePoll, questionConfig },
}: SavePulsePollAction) {
  const { errors } = yield select(getPulsePollEditState, pulsePollId);
  if (WrongEntityException.hasNoErrors(errors)) {

    let accesses: Optional<AccessResource[]> = Optional.empty();
    const hasAccessesUpdates = yield select(hasPulsePollPendingAccessesUpdates);
    if (hasAccessesUpdates) {
      accesses = filterAccessesToRemovePermissions(yield select(getPulsePollAccesses));
    }
    yield put(pulsePollActionCreators.updatePulsePollRequest(pulsePollId, pulsePoll, questionConfig, accesses));
  } else {
    log.warn(`Save poll called and there are validation errors, so skipping the save request... ${dump(errors, true)}`);
  }
}

export function* updateAccessList({ payload: { data: pulsePoll } }: UpdatePulsePollSuccessAction) {
  // fetch poll access list
  yield put(pulsePollActionCreators.fetchPulsePollAccessList(pulsePoll._id));
}

function* activatePoll({ payload: { pulsePollId } }: ActivatePulsePollAction) {
  const { errors } = yield select(getPulsePollEditState, pulsePollId);
  if (WrongEntityException.hasNoErrors(errors)) {
    yield put(pulsePollActionCreators.activatePulsePollRequest(pulsePollId));
  } else {
    log.warn(`
      Activate poll called and there are validation errors, so skipping the launch request... ${dump(errors, true)}`
    );
  }
}

function* manageActivateSuccess() {
  yield call(goTo, "/pulsePolls");
  yield put(notificationsActionCreators.displayNotification(
    NotificationFactory.success(
      "Your poll has been activated",
      "",
      4.5
    )
  ));
}

// Display an error notification if the poll can't be activated.
function* manageActivateError({ payload: { error } }: ActivatePulsePollErrorAction) {
  yield put(notificationsActionCreators.displayNotification(
    NotificationFactory.error(
      "Unable to activate the poll.",
      getOr(dump(error), ""),
      4.5
    )
  ));
}
/*
  Display an error notification if the poll can not be saved.
*/
export function* manageUpdateError({ payload: { error } }: any) {
  yield put(notificationsActionCreators.displayNotification(
    NotificationFactory.error(
      "Unable to save the poll.",
      "",
      4.5
    )
  ));
}

export function* cleanCache() {
  yield put<CleanResourceAction>(networkActionCreators.cleanResource(PulsePollResource.TYPE));
  yield put<CleanResourceAction>(networkActionCreators.cleanResource(PulsePollInfoResource.TYPE));
  yield put<CleanResourceAction>(networkActionCreators.cleanResource(PulsePollOverviewReportResource.TYPE));
}
