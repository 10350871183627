import React from "react";
import TopicsTable from "../../components/Topics/components/TopicsTable";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { FilterParameter, SortParameter } from "@src/utils/networks";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { Map as ImmutableMap } from "immutable";
import { connect, MapStateToProps } from "react-redux";
import { State } from "@store/types";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { formatSort } from "@src/utils/helper";
import {
  getCompany,
} from "@screens/Insights/store/selectors";
import {
  areEquals,
  getOr,
  isNotEmptyObject,
  isNotNullNorUndefined,
  mapOr,
} from "hyphen-lib/dist/lang/Objects";
import { objectOmit } from "hyphen-lib/dist/lang/Objects";
import { Layout as AntLayout } from "antd";
import { getParameters } from "@src/screens/Insights/parameters/store/selectors";
import { applyExistingParametersIfNeeded } from "@src/utils/parameters";
import {
  isEmptyObject
} from "hyphen-lib/dist/lang/Objects";
import { replaceLocation } from "@src/utils/locations";
import { PaginationConfig, SorterResult } from "antd/lib/table";
import {
  fetchTopicsReportIfNeeded,
  fetchTopicsOverviewIfNeeded,
  FetchTopicParameters
} from "@src/store/network/resource/TopicsReportResources";
import { TopicOverviewResource } from "hyphen-lib/dist/domain/resource/survey/report/TopicOverviewResource";
import { SurveyTopicCommentResource } from "hyphen-lib/dist/domain/resource/survey/report/SurveyTopicCommentResource";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { FetchError } from "@src/screens/Insights/errors/FetchError";
import {
  sortSurveyTopics
} from "hyphen-lib/dist/business/calculation/topic/SurveyTopicsReport";
import { extractDataAndTotalFromPage, getExistingPage, getResourceById } from "@store/network/selectors";
import { parseNumber } from "hyphen-lib/dist/lang/Number";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { not } from "hyphen-lib/dist/lang/Booleans";
import TopicsSearchBar from "../../components/Topics/components/TopicsSearchBar";
import SurveyReportHeader from "@screens/Insights/Survey/components/SurveyReportHeader";
import { Participation } from "hyphen-lib/dist/domain/common/Participation";
import styled from "styled-components";
import SurveyTopicsChart, { ChartWrapper } from "../../components/Topics/SurveyTopicsChart";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import TopicsOverview from "../../components/Topics/components/TopicsOverview";
import { LoadingContainer } from "../../components/Loading";
import Spin from "@src/components/core/Spin";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import { getRawPageIndexes } from "hyphen-lib/dist/util/store/DefaultStore";
import { isEmpty } from "hyphen-lib/dist/lang/Arrays";
export interface OwnProps extends RouteComponentProps {
  readonly surveyId: string;
  readonly surveyName: string;
  readonly participation: Participation;
  readonly topicAnalysEnabled?: boolean;
}
export interface TopicsReportContainerActionProps {
  readonly onFetchTopicsReport: (
    surveyId: string,
    topicId: string,
    parameters: FetchTopicParameters
  ) => void;
  readonly onFetchTopicsOverview: (
    surveyId: string,
    queryString: Dictionary<any>
  ) => void;
}
export interface TopicsContainerStateProps {
  readonly sort: SortParameter;
  readonly page: PageFilter;
  readonly filter: FilterParameter;
  readonly freeTextFilter: string;
  readonly total: number;
  readonly company: CompanyResource;
  readonly parameters: ImmutableMap<string, any>;
  readonly topics: TopicOverviewResource[];
  readonly loading: boolean;
  readonly existingPage: Store.Page<TopicOverviewResource>;
  readonly topicsOverview: Store.Element<SurveyTopicCommentResource>;
}

export type Props = OwnProps &
  TopicsContainerStateProps & TopicsReportContainerActionProps;

export class TopicsReportContainer extends React.Component<Props> {
  componentDidMount(): void {
    const {
      parameters,
      location: { search },
      sort,
      surveyId,
      page,
      filter,
      onFetchTopicsReport,
    } = this.props;

    const existing = parseQueryString(search);
    let mergedParameters = applyExistingParametersIfNeeded(
      parameters.toJS(),
      existing,
      "filter.dimensions",
      "filter.categories"
    );
    if (isEmptyObject(sort)) {
      mergedParameters = Object.assign({}, mergedParameters, {
        sort: { totalComments: -1 },
      });
    }
    if (
      isNotNullNorUndefined(mergedParameters) &&
      isNotEmptyObject(mergedParameters)
    ) {
      replaceLocation(mergedParameters);
    } else {
      this.fetchDataFromBackend();
      onFetchTopicsReport(surveyId, "", { filter, sort, page });
    }
  };
  fetchTopicsIfNeeded = (id: string, pageNumber: number) => {
    const {
      filter,
      sort,
      surveyId,
      page,
      onFetchTopicsReport,
    } = this.props;
    const { rawPageIndex } = getRawPageIndexes(30, page);
    const isIdNotEqual = id !== TopicOverviewResource.generateKey(surveyId, "", filter, sort);
    if(isIdNotEqual || rawPageIndex !== pageNumber){
      onFetchTopicsReport(surveyId, "", { filter, sort, page });
    }
    if(isIdNotEqual){
      this.fetchDataFromBackend();
    }
  };

  fetchDataFromBackend = () => {
    const {
      location,
      surveyId,
      onFetchTopicsOverview
    } = this.props;
    const queryParams = parseQueryString(location.search);
    onFetchTopicsOverview(surveyId, 
      this.extractRelevantParametersForQuery(queryParams));
  };

  componentDidUpdate(prevProps: Props) {
    const {
      loading,
      existingPage,
      topicsOverview
    } = this.props;
    const isInError =
      mapOr(existingPage, (c) => Store.Page.isInError(c), false) ||
      mapOr(topicsOverview, (co) => Store.Element.isInError(co), false);
    const oldParams = parseQueryString(prevProps.location.search);
    const newParams = parseQueryString(this.props.location.search);
    const {surveyId, filter, sort, page} = prevProps;
    if (
      (this.props.surveyId !== surveyId ||
        not(areEquals(oldParams, newParams)) || loading) &&
        not(isInError)
    ) {
      const { rawPageIndex } = getRawPageIndexes(30, page);
      const previousId = TopicOverviewResource.generateKey(surveyId, "", filter, sort);
      this.fetchTopicsIfNeeded( previousId, rawPageIndex);
    }
  }

  extractRelevantParametersForQuery(parameters: any) {
    const relevantParameters = { ...parameters };
    if(isNotEmptyObject(relevantParameters.filter)) {
      delete relevantParameters.filter.freeText;
    }
    return relevantParameters;
  }

  onTableChange = (
    pagination: PaginationConfig,
    data: any,
    sorter: SorterResult<any>
  ) => {
    const { current } = pagination;
    const { location } = this.props;
    const sortParam: SortParameter = {};
    if (isNotEmptyObject(sorter) && isNotNullNorUndefined(sorter.order)) {
      sortParam[sorter.columnKey] = sorter.order === "ascend" ? 1 : -1;
    }
    replaceLocation({
      ...parseQueryString(location.search),
      sort: sortParam,
      page: current
    });
  };

  render() {
    const { sort, topics, existingPage, loading, total, page, filter,
      participation, topicsOverview, surveyName, topicAnalysEnabled, surveyId } = this.props;
    const searchBarValue = mapOr(filter, (f) => f.freeText, "");
    let sortedTopics: TopicOverviewResource[] = [];
    sortedTopics = sortSurveyTopics(
      topics,
      sort
    );

    if (Store.Page.isInError(existingPage)) {
      return (
        <FetchError
          {...existingPage}
          resourceType={TopicOverviewResource.TYPE} />
      );
    }

    if (Store.Element.isInError(topicsOverview)) {
      return (
        <FetchError
          {...topicsOverview}
          resourceType={SurveyTopicCommentResource.TYPE}
        />
      );
    }

    if(!topicAnalysEnabled){
      return null;
    }
    
    return (
      <>
        <SurveyReportHeader
          participation={participation}
          enabledFilters={["dimension"]}
          singleFieldFilter="category"
          enabledCustomFilters={["addDimension"]}
          exportOption="topics"
        />
        <TopicsOverview overviewReport={Store.Element.toLoadable(topicsOverview)}/>
        {
          Store.Element.isNotLoaded(topicsOverview) ? (<ChartWrapper>
            <LoadingContainer><Spin/></LoadingContainer>
          </ChartWrapper>) :
          (<SurveyTopicsChart 
            title={"Top Topics"}
            topics={topicsOverview.value.topics}
            infoMessage={`These are the top topics identified for this survey.
             You can see more topics in the list below the chart.`}
            surveyId={surveyId}
            sectionName={`${surveyName} - Topics report`}

          />)
        }
        <Layout>
          <TopicsSearchBar
            placeholder="Search topics"
            defaultValue={searchBarValue}/>
          <TopicsTable
            tableName={`${surveyName} - Topics report`}
            sort={sort}
            loading={loading}
            page={page}
            total={total}
            onChange={this.onTableChange}
            topics={sortedTopics}/>
        </Layout>
      </>
    );
  }
};


const mapStateToProps: MapStateToProps<
  TopicsContainerStateProps,
  OwnProps & RouteComponentProps,
  State
> = (state: State, ownProps: OwnProps): TopicsContainerStateProps => {
  const queryParameters = parseQueryString(ownProps.location.search);
  const filter = getOr(queryParameters.filter, {});
  const freeTextFilter = filter.freeText || "";
  const sort = getOr(formatSort(queryParameters.sort), {});
  const pageSize = 10;
  const page = {
    number: mapOr(isStringAndNotEmpty(freeTextFilter) ? 1 : queryParameters.page,
     parseNumber, 1),
    size: pageSize
  };
  const existingPage = getExistingPage(
    state,
    TopicOverviewResource.TYPE,
    TopicOverviewResource.generateKey(
      ownProps.surveyId,
      "",
      filter,
      sort
    ),
    page
  );
  const { data, total } = extractDataAndTotalFromPage(existingPage);
  if(Store.Page.isLoaded(existingPage) && total > 0 && isEmpty(data) && page.number > 1) {
    const sortParam: SortParameter = {
      totalComments: -1
    };
    replaceLocation({
      ...parseQueryString(ownProps.location.search),
      sort: sortParam,
      page: 1
    });
  }
  return {
    topicsOverview: getResourceById(
      state,
      SurveyTopicCommentResource.TYPE,
      SurveyTopicCommentResource.generateKey(
        ownProps.surveyId,
        objectOmit(filter, "freeText")
      )
    ),
    loading: Store.Page.isLoading(existingPage),
    topics: data,
    existingPage,
    filter,
    total,
    page,
    sort,
    freeTextFilter,
    company: getCompany(state)!,
    parameters: getParameters(state),
  };
};

const mapDispatchToProps = {
  onFetchTopicsReport: fetchTopicsReportIfNeeded,
  onFetchTopicsOverview: fetchTopicsOverviewIfNeeded
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TopicsReportContainer)
);

const Layout = styled(AntLayout)`
  margin-top: 24px;
`;