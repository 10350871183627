import { 
  DifferentialAudienceCountResource 
} from "hyphen-lib/dist/domain/resource/DifferentialAudienceResource";
import { default as networkActionCreators } from "../actions";
import { createRequest } from "../../../utils/networks";

export function fetchDifferentialAudienceCountIfNeeded(id: string) {

  const request = createRequest(`/surveys/${id}/newUsersCount`);

  return networkActionCreators.fetchElementIfNeeded({
    id: DifferentialAudienceCountResource.generateKey(id),
    type: DifferentialAudienceCountResource.TYPE,
    request,
  });
}
