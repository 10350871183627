import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import i18next, { TOptions, t }from  "i18next";
import { TFunction } from "react-i18next";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .use(Backend)
  .init({
    // debug: true,
    fallbackLng: "en",
    load: "languageOnly",
    cleanCode: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      transSupportBasicHtmlNodes: true,
      useSuspense: false
    }
  });

export default i18n;

export const translate = (t: TFunction, text = "", options?: TOptions) => {
  return i18next.exists(text) ? t(text, options) : text;
};

export const trans = (text: string, options?: TOptions) => {
  return i18next.exists(text) ? t(text, options) : text;
};