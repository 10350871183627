import React from "react";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";

interface QuestionProps {
  category: string;
  question: string;
  isCategoryVisible?: boolean;
}

export function Question ({ category, question, isCategoryVisible = true }: QuestionProps) {
  return (
    <div>
      {isCategoryVisible && <CategoryLabel>{category}</CategoryLabel>}
      <QuestionLabel>{question}</QuestionLabel>
    </div>
  );
}

export const CategoryLabel = styled.div`
  font-family: Lato;
  font-size: 14px;
  color: ${Palette.bluishGrey};
`;

export const QuestionLabel = styled.div`
  font-family: Lato;
  font-size: 14px;
  line-height: 1.43;
  margin-top: 4px;
  color: ${Palette.darkBlueGrey};
`;