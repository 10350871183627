// @ts-nocheck
import React from "react";
/* eslint-disable max-len */
export function ActionPlanSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
      <path fill="none"
        fillRule="evenodd"
        stroke="#AAB9CE"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M13.157 6.967l-2.21-.98-.821-2.091 2.597-2.633A6.656 6.656 0 0 0 10.82 1c-2.492.218-4.387 2.37-4.33 4.914-.005.481.083.958.26 1.404l-4.786 3.886a2.959 2.959 0 0 0-.961 2.069 2.968 2.968 0 0 0 .788 2.143c.55.585 1.322.901 2.117.868a2.756 2.756 0 0 0 2.04-1.044 96.661 96.661 0 0 1 2.917-3.744l.915-1.108a5.587 5.587 0 0 0 2.425.172c1.741-.367 3.14-1.684 3.637-3.422a5.144 5.144 0 0 0-.087-2.804l-2.598 2.633z"
      />
    </svg>
  );
}
/* eslint-disable max-len */
