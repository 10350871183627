import React from "react";
import { SelectProps } from "antdv4/lib/select";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { withFieldLabels } from "../withFieldLabels";
import hoistNonReactStatics from "hoist-non-react-statics";
import { Select } from "antdv4";
import "@src/App.css";
import "antdv4/lib/select/style/index.css";
interface SelectOwnProps {
  numberOfValuesToShow: number;
  data: any;
  groupLabels: { [key: string]: string };
  onChange: (value: any) => void;
  selectedValues: string[] | string;
  isOptionDisabled?: (option: string) => boolean;
  allowSelectionByPaste?: boolean;
  optionsToPrefix?: React.ReactNode[];
  optionsToSuffix?: React.ReactNode[];
  dataCy?: string;
  loading?: boolean;
}

type Props = SelectOwnProps & SelectProps<any>;
class SelectOptionGroupComponent extends React.Component<Props> {

  onGetPopupContainer(trigger: any) {
    return trigger.parentNode;
  }
  checkIfOptionDisabled = (option: string): boolean => {
    const { isOptionDisabled } = this.props;
    if (isNotNullNorUndefined(isOptionDisabled)) {
      return isOptionDisabled(option);
    }
    return false;
  };
  render() {

    return (
      <Select
        options={this.props.data}
        labelInValue
        showSearch
        getPopupContainer={this.onGetPopupContainer}
        {...this.props}
      />
    );
  }
}

export default hoistNonReactStatics(
  withFieldLabels(SelectOptionGroupComponent),
  Select
);
