import { Switch } from "antd";
import styled from "styled-components";
import { AppSettingsResource } from "hyphen-lib/dist/domain/resource/AppSettingsResource";
import { Company } from "hyphen-lib/dist/domain/Company";
import Palette from "@src/config/theme/palette";
import { Heading, Paragraph } from "@components/core/Typography/index";
import useToggle from "@src/utils/hooks/useToggle";
import { not } from "hyphen-lib/dist/lang/Booleans";
interface UserSyncToggleProps {
  appSettings: AppSettingsResource;
  isCoreAppUserSyncEnabled: boolean;
  onValueChange: (values: AppSettingsResource) => void;
}

export default function UserSyncToggle({
  appSettings,
  onValueChange,
  isCoreAppUserSyncEnabled }:
  UserSyncToggleProps) {
  const [userSyncEnabled, toggleUserSync] = useToggle(isCoreAppUserSyncEnabled);
  const onCoreAppConfigChange = () => {
    onValueChange({
      ...appSettings,
      coreAppConfig: {
        ...appSettings.coreAppConfig,
        syncUsers: not(userSyncEnabled)
      } as Company.CoreAppConfiguration
    });
    toggleUserSync(not(userSyncEnabled));
  };
  return (
    <Container>
      <Heading translate="yes">User Sync</Heading>
      <Holder>
        <Description translate="yes">
          Sync Users from BetterWorks application
        </Description>
        <Switch
          checked={userSyncEnabled}
          onChange={onCoreAppConfigChange}
        />
      </Holder>
    </Container>
  );
}

const Container = styled.div`
  border-radius: 3px;
  box-shadow: 0 4px 8px 0 rgba(198, 214, 223, 0.15);
  background-color: ${Palette.white};
  margin-bottom: 39px;
  padding: 32px;
`;

const Holder = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
`;
const Description = styled(Paragraph)`
  padding-right:88px;
  margin-bottom: 1em;
`;