import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import styled from "styled-components";

import { Dictionary } from "@hyphen-lib/domain/structure/Dictionary";
import { areEquals, getOr, isNotNullNorUndefined, mapAtIndexOr } from "@hyphen-lib/lang/Objects";
import ButtonGroup from "antd/lib/button/button-group";
import Button from "@components/core/Button";
import ViewOptions, { getViewOptionValuesFromLocation } from "@screens/Insights/components/ViewOptions";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { CompareWithOption } from "@screens/Insights/components/ViewOptions/components/CompareWith";
import { appendQueryString, generateQueryString, parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { AnonymityFiltered } from "@components/core/AnonymityFiltered";
import { getViewOptionDefinitions } from "@src/utils/ViewOptions";
import { areArraysEqual, isEmpty } from "hyphen-lib/dist/lang/Arrays";
import { Participation } from "hyphen-lib/dist/domain/common/Participation";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { CategoryReportResource } from "hyphen-lib/dist/domain/resource/report/CategoryReportResource";
import { PropMapping } from "@src/utils/parameters";
import { Loading, LoadingContainer } from "../Loading";
import CategoryOverview from "../CategoryOverview";
import SurveyReportHeader from "../SurveyReportHeader";
import CategoryQuestionsTable from "./components/CategoryQuestionsTable";
import CategoryOptionsBar from "./components/CategoryOptionsBar";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { SurveyTopicCommentResource } from "hyphen-lib/dist/domain/resource/survey/report/SurveyTopicCommentResource";
import SurveyTopicsChart, { ChartWrapper } from "../Topics/SurveyTopicsChart";
import Spin from "@src/components/core/Spin";
import { goTo } from "@src/utils/locations";
import { QuestionConfig } from "hyphen-lib/dist/domain/common/QuestionType";

interface MatchParams {
  categoryId: string;
}

interface OwnProps {
  readonly participation: Participation;
  readonly categoryName: string;
  readonly enabledFilters: string[];
  readonly enabledCustomFilters?: string[];
  readonly enabledViewOptions: string[];
  readonly compareWithOptions: CompareWithOption[];
  readonly anonymityThreshold: number;
  readonly category: Loadable<CategoryReportResource>;
  readonly hasCommentsAccess: boolean;
  readonly hasTopicsAccess: boolean;
  readonly hasActionCreationRight: boolean;
  readonly storeMappings: PropMapping[];
  readonly surveyName: string;
  readonly onCreateFocusArea: (focusArea: Partial<FocusAreaResource>) => void;
  readonly topicsOverview: Loadable<SurveyTopicCommentResource>;
  readonly surveyId: string;
  readonly questionConfig: QuestionConfig;
}

type Props = OwnProps & RouteComponentProps<MatchParams>;

interface CategoryHighlightReportState {
  readonly viewOptions: {
    readonly comparison: boolean;
    readonly compareWith: Optional<string>;
  };
}

export class CategoryHighlightReport extends React.Component<Props, CategoryHighlightReportState> {
  constructor(props: Props) {
    super(props);

    const viewOptions = getViewOptionValuesFromLocation(props.location);

    this.state = this.mapViewOptionsToState(
      viewOptions,
      props.compareWithOptions,
      this.getDefaultViewOptionValues()
    );
  }

  componentDidUpdate(prevProps: Props): void {
    const prevViewOptions = parseQueryString(prevProps.location.search).viewOptions;
    const currentViewOptions = parseQueryString(this.props.location.search).viewOptions;
    if (not(areEquals(prevViewOptions, currentViewOptions)) ||
      not(areArraysEqual(prevProps.compareWithOptions, this.props.compareWithOptions)) ||
      not(areArraysEqual(prevProps.enabledViewOptions, this.props.enabledViewOptions))) {
      this.setState(
        this.mapViewOptionsToState(
          getViewOptionValuesFromLocation(this.props.location),
          this.props.compareWithOptions,
          this.getDefaultViewOptionValues()
        )
      );
    }
  }

  getDefaultViewOptionValues = () => {
    return {
      comparison: true,
    };
  };

  mapViewOptionsToState = (viewOptions: Dictionary<any>,
    compareWithOptions: CompareWithOption[],
    defaultValues: Dictionary<boolean>) => {

    const compareWithMode: Optional<string> = getOr(
      viewOptions.compareWith,
      mapAtIndexOr(compareWithOptions, 0, o => o.key, undefined)
    );

    return {
      viewOptions: {
        comparison:
          isNotNullNorUndefined(compareWithMode) &&
          getOr(viewOptions.comparison, defaultValues.comparison),
        compareWith: compareWithMode,
      },
    };
  };

  handleViewOptionsChange = (viewOptions: Dictionary<any>) => {
    this.setState(
      this.mapViewOptionsToState(
        viewOptions,
        this.props.compareWithOptions,
        this.getDefaultViewOptionValues()
      )
    );
  };

  getViewOptionsComponent = (enabledViewOptions: string[],
    compareWithOptions: CompareWithOption[]): React.ReactNode => {

    const viewOptions = getViewOptionDefinitions(enabledViewOptions, compareWithOptions);
    if (isEmpty(viewOptions)) {
      return null;
    }

    return (
      <ViewOptions
        viewOptions={viewOptions}
        defaultValues={this.state.viewOptions}
        onChange={this.handleViewOptionsChange}
      />
    );
  };

  onViewTopics(surveyId: string) {
    const { match } = this.props;
    const baseUrl = `/surveys/view/${surveyId}/reports/topics`;
    let queryParameters: Dictionary<any> = {};
    queryParameters = {
      ...queryParameters,
      filter: { categories: [match.params.categoryId] },
      sort: { totalComments: -1 }
    };
    const url = appendQueryString(baseUrl, generateQueryString(queryParameters));
    goTo(url);
  }

  render() {
    const {
      categoryName,
      participation,
      category,
      enabledFilters,
      enabledCustomFilters,
      enabledViewOptions,
      compareWithOptions,
      match,
      anonymityThreshold,
      hasCommentsAccess,
      hasTopicsAccess,
      hasActionCreationRight,
      storeMappings,
      surveyId,
      topicsOverview,
      questionConfig,
    } = this.props;

    const {
      viewOptions,
    } = this.state;

    return (
      <div>
        <SurveyReportHeader
          participation={participation}
          enabledFilters={enabledFilters}
          enabledCustomFilters={enabledCustomFilters}
          viewOptionsComponent={this.getViewOptionsComponent(enabledViewOptions, compareWithOptions)}
          storeMappings={storeMappings}
        />
        {
          Loadable.isNotLoaded(category) ?
            <Loading /> :
            this.renderCategoryHighlight(
              match.params.categoryId,
              category.value,
              viewOptions,
              compareWithOptions,
              categoryName,
              anonymityThreshold,
              hasCommentsAccess,
              hasTopicsAccess,
              hasActionCreationRight,
              surveyId,
              topicsOverview,
              questionConfig
            )
        }
      </div>
    );
  }

  // noinspection JSMethodCanBeStatic
  private renderCategoryHighlight(categoryId: string,
    category: CategoryReportResource,
    viewOptions: CategoryHighlightReportState["viewOptions"],
    compareWithOptions: CompareWithOption[],
    categoryName: string,
    anonymityThreshold: number,
    hasCommentsAccess: boolean,
    hasTopicsAccess: boolean,
    hasActionCreationRight: boolean,
    surveyId: string,
    topicsOverview: Loadable<SurveyTopicCommentResource>,
    questionConfig: QuestionConfig) {

    if (category.filteredForAnonymity) {
      return (
        <CategoryOverviewContainer>
          <AnonymityContainer>
            <AnonymityFiltered
              explanation={category.filteredForAnonymityReason!}
              anonymityThreshold={anonymityThreshold}
              translate="yes"
            />
          </AnonymityContainer>
        </CategoryOverviewContainer>
      );
    }
    const numberOfComments = category.numberOfComments;

    return <>
      <CategoryOverviewContainer>
        <CategoryOverview
          overviewLabel={categoryId}
          category={category}
          areComparisonsVisible={viewOptions.comparison}
          compareWithOptions={compareWithOptions}
          comparisonKey={viewOptions.compareWith}
          hasCommentsAccess={hasCommentsAccess}
          questionConfig={questionConfig}
        />
      </CategoryOverviewContainer>
      {(Loadable.isInError(topicsOverview) || numberOfComments === 0 || not(hasTopicsAccess)) ?
        null :
        (Loadable.isNotLoaded(topicsOverview) ?
          <ChartWrapper>
            <LoadingContainer><Spin /></LoadingContainer>
          </ChartWrapper> :
          !!category.postAndSurveySentiment && <SurveyTopicsChart topics={topicsOverview.value.topics}
            title={`Top topics in category: ${categoryId}`}
            infoMessage={"These are the top topics identified for this category."}
            category={category}
            categoryId={categoryId}
            surveyId={surveyId}
            sectionName={categoryName}
            footer={<StyledButtonGroup>
              <StyledButton
                onClick={() => this.onViewTopics(surveyId)}
                className="block--print-hide pull-right"
                data-cy="surveyReport_viewTopicsReportButton"
                color="grey"
                translate="yes"
              >
                View all topics
              </StyledButton>
            </StyledButtonGroup>} />)
      }
      <QuestionsContainer>
        <CategoryOptionsBar />
        <CategoryQuestionsTable
          tableName={categoryName}
          questions={category.questions}
          areComparisonsVisible={viewOptions.comparison}
          compareWithOptions={compareWithOptions}
          comparisonKey={viewOptions.compareWith}
          anonymityThreshold={anonymityThreshold}
          hasCommentsAccess={hasCommentsAccess}
          hasActionCreationRight={hasActionCreationRight}
          onCreateFocusArea={this.props.onCreateFocusArea}
          surveyName={this.props.surveyName}
        />
      </QuestionsContainer>
    </>;
  }
}

const CategoryOverviewContainer = styled.div`
  margin-top: 24px;
`;

const QuestionsContainer = styled.div`
  margin-top: 24px;
`;

const AnonymityContainer = styled.div`
  margin: 24px 0;
  padding: 24px 0;
  background: white;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  float: right;
`;

const StyledButton = styled(Button)`
`;

export default withRouter(CategoryHighlightReport);
