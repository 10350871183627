import { Record, RecordOf } from "immutable";
import { FilterParameter } from "@src/utils/networks";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import {
  CleanFilterAction,
  ModifyFilterAction,
  UserListFiltersActionTypes
} from "./actions";

export interface UserListFilterContainerStateProps {
  readonly filter: Optional<FilterParameter>;
}

export type UserListFilterContainerState = RecordOf<UserListFilterContainerStateProps>;

const defaultValues: UserListFilterContainerStateProps = {
  filter: Optional.empty(),
};

export const userListFilterContainerStateFactory = Record<UserListFilterContainerStateProps>(defaultValues);

type Actions =
  | ModifyFilterAction
  | CleanFilterAction;

export function userListFilterContainerReducers(
  state: UserListFilterContainerState = userListFilterContainerStateFactory(),
  action: Actions): UserListFilterContainerState {

  switch (action.type) {
    case UserListFiltersActionTypes.MODIFY_FILTER:
      return state.set("filter", action.payload);
    case UserListFiltersActionTypes.CLEAN_FILTER:
      return state.set("filter", Optional.empty());
    default:
      return state;
  }
}
