import { RouteComponentProps, withRouter } from "react-router";
import React from "react";
import { ChangeEvent } from "react";
import styled from "styled-components";
import { debounce } from "lodash";
import { connect } from "react-redux";
import {
  getCurrentUser,
} from "@src/screens/Insights/store/selectors";
import {
  isCoreAppUserSyncEnabled
} from "hyphen-lib/dist/business/company/Companies";
import { ExpandButton } from "@components/core/ExpandButton";
import { SearchBar } from "@components/core/SearchBar";
import { CustomizationPopover } from "@screens/Insights/components/CustomizationPopover";
import { State } from "@store/types";
import { Map } from "immutable";
import { UserTable } from "@screens/Insights/UserManagement/components/UserTable";
import EditUserModal from "@screens/Insights/UserManagement/components/EditUserModal";
import { UserResource } from "hyphen-lib/dist/domain/resource/user/UserResource";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { User } from "hyphen-lib/dist/domain/User";
import { FilterParameter, SortParameter } from "@src/utils/networks";
import { extractDataAndTotalFromPage, getExistingPage } from "@store/network/selectors";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import {
  getUserListStateProps,
  getUploadUsersStatus
} from "@screens/Insights/UserManagement/containers/UserListContainer/store/selectors";
import { parseNumber } from "hyphen-lib/dist/lang/Number";
import { UserListFilterContainer } from "@screens/Insights/UserManagement/containers/UserListFilterContainer";
import {
  getOr,
  isNotNullNorUndefined,
  mapOr,
  cleanObject,
  isNotEmptyObject,
  isEmptyObject,
  isNullOrUndefined,
  objectOmit
} from "hyphen-lib/dist/lang/Objects";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import { objectPick } from "hyphen-lib/dist/lang/Objects";
import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
import {
  getAvailableRoles,
  getDimensions,
  getAllDimensions,
  getAllowSMSPermission
} from "@screens/Insights/store/selectors";
import AreYouSureModal from "@src/components/core/AreYouSureModal";
import convertDictToFilters, { clearFilter, clearFilterOption } from "@src/components/core/FilterLabels/utils";
import FilterLabels from "@src/components/core/FilterLabels";
import NoResult from "@src/components/core/NoResult";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { formatSort } from "@src/utils/helper";
import { NetworkEventSuccessAction } from "@store/network/actions";
import { UploadUsersResource } from "hyphen-lib/dist/domain/resource/user/UploadUsersResource";
import { SelectionFilter } from "hyphen-lib/dist/domain/parameter/SelectionFilter";
import { Seq } from "immutable";
import { isEqual } from "lodash";
import { FiltersBackdrop } from "@screens/Insights/components/FiltersBackdrop";
import { Rights } from "@hyphen-lib/business/auth/Rights";
import { Store } from "@hyphen-lib/util/store/Store";
import { getRightsMatcher } from "@screens/Insights/store/selectors";
import {
  createExportUserListRequest
}
  from "@src/utils/exports";
import { fetchUsersIfNeeded } from "@store/network/resource/UserResources";
import { parametersActionCreators } from "@src/screens/Insights/parameters/store/actions";
import { PropMapping, applyExistingParametersIfNeeded } from "@src/utils/parameters";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { replaceLocation } from "@src/utils/locations";
import { getParameters } from "@src/screens/Insights/parameters/store/selectors";
import Spin from "@src/components/core/Spin";
import { FetchError } from "@src/screens/Insights/errors/FetchError";
import { USER_LIST_FILTER_MAPPINGS } from "../..";
import { updateTracker, getSelectRowKeys, getSelectedRowCount, getDynamicSelectRowKeys ,
   clearExcludedIds} from "../../utils/tracker";
import { UserListContainerStateProps } from "./store/reducers";
import {
  userListActionCreators,
  UserPageParameters,
  DeleteUsersPayload,
  SelectUsersPayload
} from "./store/actions";
import { checkIfHyphenAdmin } from "hyphen-lib/dist/business/user/Users";
import { WithTranslation, withTranslation } from "react-i18next";

const STATUS = [
  { value: User.Statuses.CANDIDATE, label: "Candidate" },
  { value: User.Statuses.EMPLOYEE, label: "Employee" },
  { value: User.Statuses.TERMINATED, label: "Terminated" },
];

interface UserListContainerProps {
  readonly loading: boolean;
  readonly users: UserResource[];
  readonly total: number;
  readonly page: PageFilter;
  readonly sort: SortParameter;
  readonly filter: FilterParameter;
  readonly roles: string[];
  readonly allDimensions: Dimensions;
  readonly dimensionsWithSegments: Dimensions;
  readonly modalVisible: UserListContainerStateProps["modalVisible"];
  readonly allowSMSPermission: boolean;
  readonly isUpdatingUser: boolean;
  readonly isEditUserModalVisible: boolean;
  readonly uploadUsersStatus: Seq.Indexed<UploadUsersResource>;
  readonly selectedRowKeys: string[];
  readonly isDeletingUsers: boolean;
  readonly tracker: SelectionFilter.Tracker;
  readonly canEditUser: boolean;
  readonly canDeleteUser: boolean;
  readonly dimensions: Dimensions;
  readonly parameters: Map<string, any>;
  readonly existingPage: Store.Page<UserResource>;
  readonly isUserSyncEnabled: boolean;
}

export interface UserListContainerActionProps {
  readonly onFetchIfNeeded: (parameters: UserPageParameters) => any;
  readonly onModifyList: (parameters: UserPageParameters) => any;
  readonly onModifyParameters: (parameters: Dictionary<any>, mappings?: PropMapping[]) => void;
  readonly toggleModalVisibility: (visible: boolean) => any;
  readonly toggleEditUserModalVisibility: (visible: boolean) => any;
  readonly updateUser: (
    userId: string,
    user: UserResource,
    onSuccessRedirect: (payload: NetworkEventSuccessAction["payload"]) => any
  ) => any;
  readonly onFetchUploadUsers: () => any;
  readonly onDeleteUsers: (payload: DeleteUsersPayload) => any;
  readonly onSelectUsers: (payload: SelectUsersPayload) => any;
}

interface MatchParams {
  id: string;
}

export type UserListProps =
  UserListContainerProps &
  WithTranslation &
  UserListContainerActionProps &
  RouteComponentProps<MatchParams>;

export interface UserListState {
  readonly areFiltersVisible: boolean;
  readonly selectedUsers: any[];
  readonly userToEdit: UserResource | null;
}

class UserList extends React.Component<UserListProps, UserListState> {
  private readonly onSearchChangeDebounced: (value: any) => void;

  constructor(props: UserListProps) {
    super(props);

    this.state = {
      areFiltersVisible: false,
      selectedUsers: [],
      userToEdit: null,
    };

    this.onSearchChangeDebounced = debounce(this.updateSearchFilter, 500);
  }

  componentDidMount() {
    const { onSelectUsers, filter, tracker } = this.props;
    const mergedParameters = this.getMergedParams();
    if (isNotNullNorUndefined(mergedParameters) && isNotEmptyObject(mergedParameters)) {
      replaceLocation(mergedParameters);
    }
    this.fetchIfNeeded();
    this.props.onFetchUploadUsers();
    onSelectUsers({
      tracker: tracker.applyFilter(filter),
    });
  }

  componentDidUpdate(prevProps: UserListProps) {
    const {
      users,
      onSelectUsers,
      selectedRowKeys,
      tracker,
      parameters,
      existingPage,
    } = this.props;
    if (this.props.loading && not(Store.Page.isInError(existingPage))) {
      this.fetchIfNeeded();
    }
    if (!isEqual(parameters.toJS(), prevProps.parameters.toJS())) {
      const mergedParameters = this.getMergedParams();

      if (isNotNullNorUndefined(mergedParameters)) {
        replaceLocation(mergedParameters);
      }
    }

    if (!isEqual(users, prevProps.users) && tracker.toSelectionFilter().type === "dynamic") {
      const dynamicSelectedRowKeys = getDynamicSelectRowKeys(users, tracker.toSelectionFilter(), selectedRowKeys);
      onSelectUsers({
        selectedRowKeys: dynamicSelectedRowKeys,
      });
    }

  }

  getMergedParams() {
    const {
      parameters,
      location: { search },
    } = this.props;

    const existing = parseQueryString(search);

    const mergedParameters =
      applyExistingParametersIfNeeded(
        parameters.toJS(),
        existing,
        ...USER_LIST_FILTER_MAPPINGS
      );
    return mergedParameters;
  }

  onSelectChange = (record: UserResource, selected: boolean) => {
    const { _id } = record;
    const { selectedRowKeys, onSelectUsers } = this.props;
    const tracker = updateTracker(this.props.tracker, _id, selected);
    const newSelectedRowKeys = getSelectRowKeys(selectedRowKeys, selected, _id);
    onSelectUsers({
      selectedRowKeys: newSelectedRowKeys,
      tracker,
    });
  };

  onSelectAll = (selected: boolean) => {
    const { users, onSelectUsers} = this.props;
    let { tracker } = this.props;
    if (not(selected)) {
      tracker = tracker.deselectAll();
      onSelectUsers({
        selectedRowKeys: [],
        tracker,
      });
    } else {
      tracker = clearExcludedIds(tracker.selectAll());
      const selectedRowKeys: string[] = [];
      users.forEach(user => {
        if(checkIfHyphenAdmin(user.email)){
          tracker = updateTracker(tracker, user._id, false);
        } else {
          selectedRowKeys.push(user._id);
          tracker = updateTracker(tracker, user._id, true);
        }
      });
      onSelectUsers({
        selectedRowKeys,
        tracker,
      });
    }
  };

  fetchIfNeeded() {
    const {
      onFetchIfNeeded,
      page,
      filter,
      sort,
    } = this.props;

    onFetchIfNeeded({filter, sort, page});
  }

  handleFiltersClick = () =>
    this.setState(
      state => (
        {
          areFiltersVisible: !state.areFiltersVisible,
        }
      )
    );

  exportToCSV = () => {
    const { location } = this.props;
    createExportUserListRequest(location.search);
  };

  onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.onSearchChangeDebounced(event.target.value);
  };

  updateSearchFilter = (value: any) => {
    const {
      onModifyParameters,
      onModifyList,
      page,
      sort,
      tracker,
      onSelectUsers,
    } = this.props;

    const filter = getOr(this.props.filter, {});
    if (isStringAndNotEmpty(value)) {
      filter.freeText = value;
    } else {
      delete filter.freeText;
    }
    
    const resetPage = { ...page, number: 1 };
    onSelectUsers({ tracker: tracker.applyFilter(filter) });
    onModifyParameters({filter: objectPick(filter,"freeText")}, USER_LIST_FILTER_MAPPINGS );
    onModifyList({
      filter: cleanObject(filter),
      page: resetPage,
      sort,
    });
  };

  handleApplyFilters = (newFilter: any) => {
    const {
      onModifyList,
      page,
      sort,
      tracker,
      onSelectUsers,
    } = this.props;

    this.setState({ areFiltersVisible: false });
    onSelectUsers({ tracker: tracker.applyFilter(newFilter) });
    const resetPage = { ...page, number: 1 };
    onModifyList({
      filter: cleanObject(newFilter),
      page: resetPage,
      sort,
    });
  };

  renderNoData = () => {
    const { filter } = this.props;

    if (isNotNullNorUndefined(filter)) {
      // in-case of search
      if (isNotNullNorUndefined(filter.freeText)) {
        return (
          <NoResult
            type="search"
            description="Sorry, we couldn't find any users matching your search for the moment."
          />
        );
      }
      // in-case any filters are applied
      else if (Object.keys(filter).length > 0) {
        return (
          <NoResult
            type="filter"
            description="Sorry, we couldn't find any users matching your filters for the moment."
          />
        );
      }
      // no filters, meaning no survey is created
      else {
        return (
          <NoResult
            type="data"
            description="Looks like you haven't added any users yet."
          />
        );
      }
    } else {
      return (
        <NoResult
          type="default"
          description=" "
        />
      );
    }
  };

  onClearFilter = (filterToRemove: string) => {
    const {
      onModifyList,
      page,
      sort,
      tracker,
      onSelectUsers,
    } = this.props;

    const filter = cleanObject(clearFilter(filterToRemove, this.props.filter));

    onSelectUsers({ tracker: tracker.applyFilter(filter) });
    onModifyList({
      filter,
      page,
      sort,
    });
  };

  onClearFilterOption = (filter: string, subFilter: string) => {
    const {
      onModifyList,
      page,
      sort,
      tracker,
      onSelectUsers,
    } = this.props;

    const filterState = cleanObject(clearFilterOption(filter, subFilter, this.props.filter));

    onSelectUsers({ tracker: tracker.applyFilter(filterState) });
    onModifyList({
      filter: filterState,
      page,
      sort,
    });
  };

  openEditUserModal = (user: UserResource) => {
    const { toggleEditUserModalVisibility } = this.props;
    toggleEditUserModalVisibility(true);
    this.setState({ userToEdit: user});
  };

  closeEditModal = () => {
    const { toggleEditUserModalVisibility } = this.props;
    toggleEditUserModalVisibility(false);
    this.setState({ userToEdit: null });
  };

  renderFilterLabels = () => {
    const { filter, dimensions } = this.props;
    const filters = convertDictToFilters(filter, dimensions);
    if (filters.length > 0 ) {
      return (
        <FilterLabels
          filters={filters}
          onClearFilter={this.onClearFilter}
          onClearSubfilter={this.onClearFilterOption}
        />
      );
    } else {
      return null;
    }
  };

  onDeleteUsers = () => {
    const { onDeleteUsers, filter, tracker } = this.props;

    onDeleteUsers({ selection: {
      ...tracker.toSelectionFilter(),
      filter: {
        ...filter,
      },
    } });
  };

  render() {
    const {
      loading,
      users,
      total,
      page,
      sort,
      filter,
      roles,
      allDimensions,
      dimensionsWithSegments,
      onModifyList,
      toggleModalVisibility,
      modalVisible,
      allowSMSPermission,
      updateUser,
      isUpdatingUser,
      isEditUserModalVisible,
      uploadUsersStatus,
      selectedRowKeys,
      isDeletingUsers,
      tracker,
      canDeleteUser,
      canEditUser,
      existingPage,
      t,
      isUserSyncEnabled
    } = this.props;
    const { areFiltersVisible, userToEdit } = this.state;
    const selectedUsersCount =  getSelectedRowCount(total, tracker.toSelectionFilter());
    const searchBarValue = mapOr(filter, f => getOr(f.freeText, ""), "");
    return (
      <>
        {uploadUsersStatus.map(obj =>
          <StatusContainer key={obj._id}><b>{obj.fileName}</b> status is {obj.status}</StatusContainer>
        )}
        <Container>
          <SearchAndFilterContainer>
            <SearchBar
              placeholder="Search users"
              onChange={this.onSearchChange}
              defaultValue={searchBarValue }
            />
            <StyledExpandButton
              icon="filter"
              onClick={this.handleFiltersClick}
              translate="yes"
            >
              Filters
            </StyledExpandButton>
            <StyledExpandButton
              icon="export"
              isExpandable={false}
              onClick={this.exportToCSV}
              translate="yes"
            >
              Export
            </StyledExpandButton>
            {
              areFiltersVisible &&
              <FiltersBackdrop onClick={this.handleFiltersClick}/>
            }
            <CustomizationPopover open={areFiltersVisible}>
              <UserListFilterContainer
                roles={roles}
                dimensions={objectOmit(dimensionsWithSegments, "dynamicSeparation")}
                values={filter}
                onApply={this.handleApplyFilters}
              />
            </CustomizationPopover>
          </SearchAndFilterContainer>
          {this.renderFilterLabels()}
          {
            <Spin size="large" spinning={Store.Page.isLoading(existingPage)} >
              {Store.Page.isInError(existingPage) && <FetchError {...existingPage} resourceType={UserResource.TYPE}/>}
              {
                Store.Page.isLoaded(existingPage) && total === 0
                  ? this.renderNoData()
                  : (
                    <UserTable
                      loading={loading}
                      users={users}
                      total={total}
                      page={page}
                      filter={filter}
                      selectedRowKeys={selectedRowKeys}
                      sort={sort}
                      onModifyList={onModifyList}
                      onSelectAll={this.onSelectAll}
                      onSelectChange={this.onSelectChange}
                      selectedUsersCount={selectedUsersCount}
                      onOpenDeleteModal={toggleModalVisibility.bind(null, true)}
                      allowSMSPermission={allowSMSPermission}
                      dimensions={allDimensions}
                      onEditUser={this.openEditUserModal}
                      canEditUser={canEditUser}
                      canDeleteUser={canDeleteUser}
                    />
                  )
              }
            </Spin>
          }
          <AreYouSureModal
            visible={modalVisible}
            title="Are you sure?"
            /* eslint-disable max-len */
            description="Delete selected users? This action will permanently delete all selected users. It cannot be undone."
            okLabel={isDeletingUsers ? "Deleting..." : "Delete"}
            onCancel={toggleModalVisibility.bind(null, false)}
            onOk={this.onDeleteUsers}
            buttonsDisabled={isDeletingUsers}
          />
          {
            (isEditUserModalVisible && isNotNullNorUndefined(userToEdit)) && (
              <EditUserModal
                visible={isEditUserModalVisible}
                closeModal={this.closeEditModal}
                userToEdit={userToEdit}
                roles={roles}
                dimensions={allDimensions}
                statuses={STATUS}
                allowSMSPermission={allowSMSPermission}
                updateUser={updateUser}
                isUpdatingUser={isUpdatingUser}
                t={t}
                isUserSyncEnabled={isUserSyncEnabled}
              />
            )
          }
        </Container>
      </>
    );
  }
}

const SearchAndFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  position: relative;
`;

const StyledExpandButton = styled(ExpandButton)`
  width: 144px;
  margin-left: 16px;
`;

const Container = styled.div`
  background-color: white;
  padding-bottom: 32px;
`;

const StatusContainer = styled(Container)`
  margin-bottom: 24px;
  padding: 32px;
`;

function mapStateToProps(state: State, { location }: RouteComponentProps): UserListContainerProps  {
  const queryParameters = parseQueryString(location.search);
  let filter = getOr(queryParameters.filter, {});
  const sort = getOr(formatSort(queryParameters.sort), {});

  const {
    pageSize,
    modalVisible,
    isUpdatingUser,
    isEditUserModalVisible,
    selectedRowKeys,
    isDeletingUsers,
    tracker,
  } = getUserListStateProps(state);
  const page = {
    size: pageSize,
    number: mapOr(queryParameters.page, parseNumber, 1),
  };
  const parameters = getParameters(state);
  const parametersJS = parameters.toJS();

  if (
    (isEmptyObject(filter) || isNullOrUndefined(filter.freeText)) &&
    (isNotNullNorUndefined(parametersJS) && isNotEmptyObject(parameters.get("UserListSearch")))
  ) {
    filter = parameters.get("UserListSearch");
  }

  const existingPage = getExistingPage(
    state,
    UserResource.TYPE,
    UserResource.generateKey(filter, sort),
    page
  );
  const { data, total } = extractDataAndTotalFromPage(existingPage);
  const allDimensions = getOr(getAllDimensions(state), {});
  const currentUser = getCurrentUser(state);
  const isUserSyncEnabled = getOr(currentUser && currentUser.company && 
    isCoreAppUserSyncEnabled(currentUser.company) && not(checkIfHyphenAdmin(currentUser.email)), false);
  const canEditUser = getRightsMatcher(state).hasRight(Rights.User.EDIT_USERS);

  return {
    loading: Store.Page.isNotFound(existingPage),
    existingPage,
    users: data,
    total,
    sort,
    filter,
    page,
    modalVisible,
    roles: getAvailableRoles(state),
    allDimensions,
    dimensionsWithSegments: getOr(getDimensions(state), {}),
    allowSMSPermission: getAllowSMSPermission(state),
    isUpdatingUser,
    isEditUserModalVisible,
    uploadUsersStatus: getUploadUsersStatus(state),
    selectedRowKeys,
    isDeletingUsers,
    tracker,
    canEditUser,
    canDeleteUser: not(isUserSyncEnabled) && getRightsMatcher(state).hasRight(Rights.User.DELETE_USERS),
    dimensions: getOr(getDimensions(state), {}),
    parameters,
    isUserSyncEnabled
  };
}

const mapDispatchToProps = {
  onFetchIfNeeded: fetchUsersIfNeeded,
  onModifyList: userListActionCreators.modifyList,
  toggleModalVisibility: userListActionCreators.toggleModalVisibility,
  updateUser: userListActionCreators.updateUser,
  toggleEditUserModalVisibility: userListActionCreators.toggleEditUserModalVisibility,
  onFetchUploadUsers: userListActionCreators.fetchUploadUsers,
  onDeleteUsers: userListActionCreators.onDeleteUsers,
  onSelectUsers: userListActionCreators.onSelectUsers,
  onModifyParameters: parametersActionCreators.modifyParameters,
};

export const UserListContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserList))
);
