import React from "react";
import styled from "styled-components";
import { DynamicRowProps } from "@components/core/DynamicRowsTable/withDynamicRows";
import Palette from "@src/config/theme/palette";
import {
    CellContainer,
    CellInnerContainer
  } from "@screens/Insights/SurveyList/components/cells/Cells";
  import { Trans } from "react-i18next";

interface TableTextCellProps {
    target: string;
}

export function TableTextCell({ row, index, target }: DynamicRowProps<any> & TableTextCellProps) {
    return (
      <CellContainer align="flex-start" justifyContent="flex-start">
        <CellInnerContainer padding={[24, 0]} width="100%">
          <Title data-cy={`cellText_${index}`}>
            {row[target] ? <Trans>{row[target]}</Trans> : <Hyphen/> }
          </Title>
        </CellInnerContainer>
      </CellContainer>
    );
}

const Title = styled.span`
  padding-right: 32px;
  font-family: Lato;
  font-size: 14px;
  margin-top: 10px;
  color: ${Palette.veryDarkBlueGrey};
`;

const Hyphen = styled.div`
  height: 1px;
  width: 12px;
  background-color: #7a8891;
  margin: 0 10px;
`;