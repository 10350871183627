// This must be the first line in src/index.js
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import ErrorBoundary from "./screens/Insights/errors/ErrorBoundary";
import { I18nextProvider } from "react-i18next";
import i18n from "@src/utils/i18next";
import App from "./App";
import "./index.css";
import "./css/cursor.css"; // This needs to be convered to scss module
import "./css/spacing.css"; // This needs to be converted to scss module
import "./css/animation.css"; // This needs to be converted to scss module
import "./css/display.css"; // This needs to be converted to scss module
import "./css/text-util.css"; // This needs to be converted to scss module
import "./css/components.css"; // This needs to be converted to scss module
import "./css/position.css"; // This needs to be converted to scss module

import configureStore from "@store/configureStore";
import { stateFactory } from "@src/store";

const RootElement = document.getElementById("root");

if (!RootElement) {
  throw new Error("No root element");
}

const store = configureStore(stateFactory());

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </I18nextProvider>
  </Provider>,
  RootElement
);
