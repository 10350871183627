// import React from "react";

import Palette from "@src/config/theme/palette";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import styled from "styled-components";
import { TransWrapper } from "../withTrans";
import { sanitizeSegmentLabel } from "hyphen-lib/dist/domain/common/Dimensions";
import { Trans } from "react-i18next";

export interface BottomDrawerStripeRow {
  primaryContent: string;
  secondaryContent: string;
}
export interface BottomDrawerStripedChildProps {
  stripStack: BottomDrawerStripeRow[];
  emptyStackMessage?: string;
}

export function transformFocusAreasToStripStack(
  focusAreas?: FocusAreaResource[]
): BottomDrawerStripedChildProps["stripStack"] {
  if (!focusAreas) return [];
  return focusAreas.map((item) => {
    return {
      primaryContent: item.title,
      secondaryContent: item.focusAreaType,
    };
  });
}

function BottomDrawerStripedChild(props: BottomDrawerStripedChildProps) {
  return (

    <>
      <Container>
        <Table>
          <TableBody>
            {props.emptyStackMessage && props.stripStack.length === 0 && (
              <EmptyStackMessage data-cy="empty_stack_message">
                <Trans>{props.emptyStackMessage}</Trans>
              </EmptyStackMessage>
            )}
            {props.stripStack.map((item, index) => {
              return (
                <TableRow key={index}>
                  <PrimaryContent data-cy="bottom_drawer_primary_content">
                    <TransWrapper>{sanitizeSegmentLabel(item.primaryContent)}</TransWrapper>
                  </PrimaryContent>
                  <SecondaryContent data-cy="bottom_drawer_secondary_content">
                    <Trans>{item.secondaryContent}</Trans>
                  </SecondaryContent>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Container>
    </>
  );
}

const Container = styled.div``;

const Table = styled.div`
  width: 100%;
  border-collapse: collapse;
  height: 11em;
  overflow-y: auto;
`;

const TableBody = styled.div``;

const TableRow = styled.div`
  display: flex;
  padding: 0.9em;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  font-size: 1.1em;
`;

const PrimaryContent = styled.div`
  margin-right: auto;
`;

const SecondaryContent = styled.div`
  color: ${Palette.raven};
  font-size: 0.8em;
  letter-spacing: 0.03em;
`;

const EmptyStackMessage = styled.div`
  padding: 0.9em;
  visibility: hidden;
  animation: emptyStackMessageAnimation 0s 2s forwards;
`;

export default BottomDrawerStripedChild;
