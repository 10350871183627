import { Audience } from "hyphen-lib/dist/domain/common/Audience";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";

export interface DateCriteriaTexts {
  readonly text: string;
  readonly hoverText: string;
}

export function getDateCriteriaTexts(dateCriteriaTexts: Optional<Partial<DateCriteriaTexts>>,
  dateCriteria: Audience.DateCriteriaConfiguration): DateCriteriaTexts {

  const text = isNotNullNorUndefined(dateCriteriaTexts) && isNotNullNorUndefined(dateCriteriaTexts.text) ?
    // eslint-disable-next-line max-len
    dateCriteriaTexts.text : `How many days ${getDirectionLabel(dateCriteria.direction)} ${getDateFieldLabel(dateCriteria.field)}?`;
  const hoverText = isNotNullNorUndefined(dateCriteriaTexts) && isNotNullNorUndefined(dateCriteriaTexts.hoverText) ?
    // eslint-disable-next-line max-len
    dateCriteriaTexts.hoverText : `User will automatically be subscribed to audience once they cross these number of days ${getDirectionLabel(dateCriteria.direction)} their ${getDateFieldLabel(dateCriteria.field)}`;

  return {
    text,
    hoverText,
  };
}

function getDateFieldLabel(dateField: "hireDate" | "endEmploymentDate") {
  return dateField === "hireDate" ? "hire date" : "termination date";
}

function getDirectionLabel(direction: 1 | -1) {
  return direction === 1 ? "after" : "before";
}
