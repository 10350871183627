import React from "react";
import {renderToString} from "react-dom/server";
import NoResult from "@src/components/core/NoResult";
import { createPortal } from "react-dom";

export default function NoDataString(id: string) {
  return renderToString(
    <div id={id}>
      <NoResult type="minimal" description="No Data" />
    </div>
  );
}

export function NoData({ anchor }: {anchor: Element}) {
  return createPortal(
    <div style={{zIndex: 20}}>
      <NoResult type="minimal" description="No Data" />
    </div>, anchor
  );
}