import React from "react";
import { Modal as AntModal } from "antd";
import { ModalProps as AntModalProps } from "antd/lib/modal";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";

interface ModalProp extends AntModalProps {
  children?: React.ReactNode;
}

export default function Modal(props: ModalProp) {
  return (
    <StyledModal
      {...props}
      maskStyle={
        {
          backgroundColor: Palette.lightPeriwinkle_80,
        }
      }
    >
      {props.children}
    </StyledModal>
  );
}

const StyledModal = styled(AntModal)<AntModalProps>`
  .ant-modal-content {
    padding-bottom: 32px;
  }
  .ant-modal-header, .ant-modal-footer {
    border: none;
  }
  .ant-modal-header {
    > div {
      padding-left: 8px !important;
      font-size: 24px;
      color: ${Palette.darkBlueGrey};
      line-height: 1.33;
    }
  }
  .ant-modal-footer {
    padding: 10px 32px !important;
  }
  .ant-modal-body {
    padding: 0;
    max-height: 700px;
    height: 100%;
    overflow-y: auto;
  }
  .ant-modal-close-x {
   > .anticon-close {
    font-size: 20px;
    margin-right: 24px;
    margin-top: 22px;
    color: ${Palette.darkBlueGrey};
    }
  }
  .ant-modal-footer button + button {
    margin-left: 16px !important;
  }
`;
