import React from "react";
import { Map as ImmutableMap, Set as ImmutableSet } from "immutable";
import { PulsePollResource } from "hyphen-lib/dist/domain/resource/PulsePollResource";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import { PostCategoryResource } from "hyphen-lib/dist/domain/resource/post/PostCategoryResource";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { MergeTagResource } from "hyphen-lib/dist/domain/resource/MergeTagResource";
import { entries, getOr } from "hyphen-lib/dist/lang/Objects";
import LabelMapper from "@src/utils/constants/PulsePollCreation";
import { Post } from "hyphen-lib/dist/domain/Post";
import Modal from "@src/components/core/Modal";
import styled from "styled-components";
import { CommunicationForm } from "../../../Surveys/components/CommunicationForm";
import { PublishingForm } from "../../components/PublishingForm";
import { GeneralSettings } from "../../components/GeneralSettings";
import { PulsePollDisabledFields } from "../../store/pulsePollEditTypes";
import { Trans } from "react-i18next";

export interface SettingsProps {
  readonly pulsePoll: PulsePollResource;
  readonly allowedChannels: CompanyResource.Channels;
  readonly allowedMergeTags: MergeTagResource[];
  readonly errors: ImmutableMap<string, WrongEntityException.WrongField>;
  readonly disabled: PulsePollDisabledFields;
  readonly categories: PostCategoryResource[];
  readonly onChange: (pulsePoll: PulsePollResource) => void;
}

const COMMUNICATION_FIELDS = ImmutableSet([
  "channels",
  "reminderLimit",
  "reminderInterval",
]);

export class Settings extends React.Component<SettingsProps> {
  state = {
    isModalOpen: false,
  };

  toggleModelOpen = (isModalOpen: boolean) => this.setState({ isModalOpen });

  handleChange = (modifications: Partial<PulsePollResource>) => {
    const {
      pulsePoll,
      onChange,
    } = this.props;

    onChange({
      ...pulsePoll as any,
      ...modifications,
    });
  };

  render() {
    const {
      categories,
      allowedChannels,
      allowedMergeTags,
      errors,
      disabled,
      pulsePoll,
    } = this.props;

    const areCommunicationDisabled = entries(disabled as object)
      .filter((value, key) => COMMUNICATION_FIELDS.has(key))
      .some(value => value === true);
    return (
      <>
        <GeneralSettings
          disabled={disabled}
          selectedCategory={pulsePoll.category}
          categories={categories}
          onChange={this.handleChange}
          displayResults={getOr(pulsePoll.displayResults, false)}
          openLearnMoreModal={this.toggleModelOpen.bind(null, true)}
          pulsePollStatus={pulsePoll.status}
        />
        <PublishingForm
          disabled={disabled}
          status={pulsePoll.status}
          nextOccurrence={pulsePoll.nextOccurrence}
          lastPublishedOn={pulsePoll.lastPublishedOn}
          recurringFrequency={getOr(
            pulsePoll.recurringFrequency,
            Post.PollFrequency.ONCE
          )}
          errors={ImmutableMap(errors)}
          onChange={this.handleChange}
          numberOfDaysBeforeClosingInstance={
            getOr(pulsePoll.numberOfDaysBeforeClosingInstance, 7)
          }
        />
        <CommunicationForm
          labelMapper = {LabelMapper}
          type = {pulsePoll._type}
          allowedChannels={allowedChannels}
          allowedMergeTags={allowedMergeTags}
          errors={ImmutableMap(errors)}
          onChange={this.handleChange}
          disabled={areCommunicationDisabled}
          values={pulsePoll}
          enableTranslation={false}
          isMultiLanguageSurveyEnabled={false}
        />
        {/*
        // @ts-ignore */}
        <LearnMoreModal
          visible={this.state.isModalOpen}
          destroyOnClose
          onCancel={this.toggleModelOpen.bind(null, false)}
          footer={null}
          title={<Trans>Select a category which best suits the Poll theme</Trans>}
        />
      </>
    );
  }
}

const LearnMoreModal = styled(Modal)`
  min-width: 800px;
`;
