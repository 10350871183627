// @ts-nocheck
import React from "react";
export function SurveysSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
    >
      <defs>
        {/* eslint-disable max-len */}
        <path id="surveysA" d="M3.857 12.173L5.86 10.15a.5.5 0 0 1 .71.702L4.56 12.885l2.01 2.033a.5.5 0 1 1-.711.703l-2.002-2.025-2.001 2.025a.5.5 0 0 1-.712-.703l2.01-2.033-2.01-2.034a.5.5 0 0 1 .712-.702l2.001 2.024zM9.5 5.288a.5.5 0 0 1 0-1h6.429a.5.5 0 1 1 0 1H9.5zM2 3v3.77h3.714V3H2zm-.5-1h4.714a.5.5 0 0 1 .5.5v4.77a.5.5 0 0 1-.5.5H1.5a.5.5 0 0 1-.5-.5V2.5a.5.5 0 0 1 .5-.5zm8 11.288a.5.5 0 1 1 0-1h6.429a.5.5 0 0 1 0 1H9.5z"/>
        {/* eslint-disable max-len */}
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(-1 -2)">
        <path d="M0 0h18v18H0z"/>
        <mask id="surveysB" fill="#fff">
          <use xlinkHref="#surveysA"/>
        </mask>
        <use fill="#111" fillRule="nonzero" xlinkHref="#surveysA"/>
        <g fill="#AAB9CE" mask="url(#surveysB)">
          <path d="M0 0h18v18H0z"/>
        </g>
      </g>
    </svg>
  );
}
