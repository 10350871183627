import React from "react";
import { TransWrapper } from "@src/components/core/withTrans";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";

interface ParagraphProps {
  children: React.ReactNode;
  className?: string;
  onClick?: (event: React.FormEvent<HTMLParagraphElement>) => void;
  weight?: "light" | "bold";
  width?: number;
  readonly translate?: string;
  readonly id?: string;
}

const Paragraph = (props: ParagraphProps) => {
  const { className, onClick = () => undefined, weight = "normal", width } = props;
  return (
    <StyledP className={className} onClick={onClick} weight={weight} width={width}>
      <TransWrapper {...props}/>
    </StyledP>
  );
};

const StyledP = styled.p<{ weight: string; width?: number }>`
    font-weight: ${ p => p.weight === "light" ? "normal" : p.weight};
    ${p => p.width && "width: p.width"}
    margin: unset;
    color: ${p => p.weight === "light" ? Palette.bluishGrey : Palette.veryDarkBlueGrey};
    line-height: 1.43 !important;
  `;

export default Paragraph;
