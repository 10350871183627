import { Record, RecordOf } from "immutable";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { VoiceFlaggedPostReportResource } from "hyphen-lib/dist/domain/resource/voice/VoiceFlaggedPostReportResource";
import {
  VoiceFlaggedCommentReportResource
} from "hyphen-lib/dist/domain/resource/voice/VoiceFlaggedCommentReportResource";

import { FilterParameter } from "@src/utils/networks";
import { VoiceReportsActions } from "./actions";
import { VoiceFlagsActionKeyType } from "./types";

export interface VoiceReportsStateProps {
  readonly filter: Optional<FilterParameter>;
  readonly pageSize: number;
  readonly isAreYouSureModalVisible: boolean;
  readonly isRequesting: boolean;
  readonly action: VoiceFlagsActionKeyType | null;
  readonly selectedFlaggedResource:
  | VoiceFlaggedPostReportResource
  | VoiceFlaggedCommentReportResource
  | null;
}
export type VoiceReportsState = RecordOf<VoiceReportsStateProps>;

const defaultValues: VoiceReportsStateProps = {
  filter: Optional.empty(),
  pageSize: 10,
  isAreYouSureModalVisible: false,
  isRequesting: false,
  action: null,
  selectedFlaggedResource: null,
};

export const voiceReportsStateFactory = Record<VoiceReportsStateProps>(defaultValues);
const defaultState = voiceReportsStateFactory();

export function voiceReportsReducers(
  state: VoiceReportsState = defaultState,
  action: any
) {
  switch (action.type) {
    case VoiceReportsActions.MODIFY_FILTER:
      return state.set("filter", action.payload);
    case VoiceReportsActions.CLEAN_FILTER:
      return state.set("filter", Optional.empty());
    case VoiceReportsActions.TOGGLE_ARE_YOU_SURE_MODAL:
      return state.merge(action.payload);
    case VoiceReportsActions.UNFLAG_POST_REQUEST:
    case VoiceReportsActions.UNFLAG_COMMENT_REQUEST:
    case VoiceReportsActions.ARCHIVE_POST_REQUEST:
    case VoiceReportsActions.ARCHIVE_COMMENT_REQUEST:
      return state.set("isRequesting", true);
    case VoiceReportsActions.UNFLAG_POST_SUCCESS:
    case VoiceReportsActions.UNFLAG_COMMENT_SUCCESS:
    case VoiceReportsActions.ARCHIVE_POST_SUCCESS:
    case VoiceReportsActions.ARCHIVE_COMMENT_SUCCESS:
    case VoiceReportsActions.UNFLAG_POST_ERROR:
    case VoiceReportsActions.UNFLAG_COMMENT_ERROR:
    case VoiceReportsActions.ARCHIVE_POST_ERROR:
    case VoiceReportsActions.ARCHIVE_COMMENT_ERROR:
      return state.set("isRequesting", false);
    default:
      return state;
  }
}
