import { combineReducers } from "redux-immutable";
import { ActionTypes } from "@screens/Insights/store/actions";
import { default as network } from "@store/network/reducers";
import { default as insights } from "@screens/Insights/store/reducers";
import { default as insights_surveys } from "@screens/Insights/Surveys/store/reducers";
import { surveyEditReducers } from "@screens/Insights/Surveys/store/surveyEditReducers";
import { pulsePollEditReducers } from "@screens/Insights/PulsePolls/store/pulsePollEditReducers";
import { default as notification } from "@store/notifications/reducers";

import { default as insights_participation } from "@screens/Insights/Survey/containers/store/reducers";
import { default as insights_filters } from "@screens/Insights/Survey/containers/FiltersContainer/store/reducers";
import { reducers as insights_surveyList } from "@screens/Insights/SurveyList/store/reducers";
import {
  reducers as insights_surveyList_filter
} from "@screens/Insights/SurveyList/containers/SurveyListFilterContainer/store/reducers";
import {
  default as insights_survey_reminders
} from "@screens/Insights/Survey/containers/SurveyRemindersContainer/store/reducers";
import {
  userListContainerReducers
} from "@screens/Insights/UserManagement/containers/UserListContainer/store/reducers";
import {
  userListFilterContainerReducers
} from "@screens/Insights/UserManagement/containers/UserListFilterContainer/store/reducers";

import {
  userPermisssionsReducers
} from "@screens/Insights/UserManagement/containers/UserPermissions/store/reducers";
import {
  userRolesReducers
} from "@screens/Insights/UserManagement/containers/UserRoles/store/reducers";
import {
  userPermissionsFilterContainerReducers
} from "@screens/Insights/UserManagement/containers/UserPermissionsFilterContainer/store/reducers";

import { usersAndDimensionsReducers } from "@screens/Insights/AddUsersAndDimensions/store/reducers";
import { actionPlansReducer } from "@screens/Insights/ActionPlans/store/reducers";

import { focusAreaListReducers, reducers as actionListReducers } from "@screens/Insights/Actions/store/reducers";
import {bottomDrawerReducers} from "@screens/Insights/BottomDrawer/reducers";
import {xlsxReducers} from "@screens/Insights/Xlsx/reducers";
import {
  actionListFilterContainerReducers
} from "@screens/Insights/Actions/containers/ActionListFilterContainer/store/reducers";
import { surveyReportReducers } from "@screens/Insights/Survey/store/reducers";
import { parametersReducer } from "@screens/Insights/parameters/store/reducers";
import { settingsReducer } from "@screens/Insights/Settings/store/reducers";
import { pulsePollListReducers } from "@screens/Insights/PulsePollList/store/reducers";
import { pulsePollReducer } from "@screens/Insights/PulsePoll/store/reducers";
import { voiceReportsReducers } from "@src/screens/Insights/EmployeeVoice/VoiceReports/store/reducers";
import {
  segmentReportCommentsReducers
} from "@src/screens/Insights/Segments/SegmentReport/store/reducers";
import { stateFactory } from "@store/index";
import { batarangScriptsReducers } from "@screens/Insights/Settings/containers/batarang/store/batarangScriptsReducers";

const appReducer = combineReducers({
  network,
  insights,
  insights_parameters: parametersReducer,
  insights_batarang_scripts: batarangScriptsReducers as any /* fixme: need to understand what the problem is :/ */,
  insights_surveys,
  insights_surveyEdit: surveyEditReducers,
  insights_pulsePollEdit: pulsePollEditReducers,
  insights_surveyList,
  insights_surveyList_filter,
  insights_participation,
  insights_filters,
  insights_survey_reminders,
  insights_userList: userListContainerReducers,
  insights_userList_filter: userListFilterContainerReducers,
  insights_userPermissions: userPermisssionsReducers,
  insights_userPermissions_filter: userPermissionsFilterContainerReducers,
  insights_userRoles: userRolesReducers,
  insights_users_and_dimensions: usersAndDimensionsReducers,
  insights_action_plans: actionPlansReducer,
  insights_actionList: actionListReducers,
  insights_focusAreas: focusAreaListReducers,
  insights_bottom_drawer: bottomDrawerReducers,
  insights_xlsx: xlsxReducers,
  insights_actionList_filter: actionListFilterContainerReducers,
  insights_survey_report: surveyReportReducers,
  insights_settings: settingsReducer,
  insights_pulse_poll_list: pulsePollListReducers,
  pulse_poll: pulsePollReducer,
  insights_voice_reports: voiceReportsReducers,
  insights_segment_reports: segmentReportCommentsReducers,
  notification,
});

// @ts-ignore
const rootReducer = (state, action) => {
  if (action.type === ActionTypes.LOGOUT_USER ) {
    state = stateFactory();
  }
  return appReducer(state, action);
};

export default rootReducer;
