import React from "react";
import { SurveyInfoResource } from "@hyphen-lib/domain/resource/SurveyInfoResource";
import { isEmpty } from "@hyphen-lib/lang/Arrays";
import { Survey } from "@hyphen-lib/domain/Survey";
import { Set as ImmutableSet } from "immutable";
import { DynamicRowProps } from "@components/core/DynamicRowsTable/withDynamicRows";
import { AccessList } from "hyphen-lib/dist/domain/access/AccessList";
import Actions, { SurveyActionKeyType, Action, ActionDefinition } from "@src/screens/Insights/components/Actions";

export const ActionKeys: SurveyActionKeyType =  {
  edit: "edit",
  seeReport: "seeReport",
  sendReminder: "sendReminder",
  duplicate: "duplicate",
  delete: "delete",
  close: "close",
};

interface ActionCellProps extends DynamicRowProps<SurveyInfoResource> {
  hasCreationRight: boolean;
  onClick: (key: keyof SurveyActionKeyType) => void;
  "data-cy"?: string;
}

export function ActionCell({ row, hasCreationRight, onClick }: ActionCellProps) {
  const actions: ActionDefinition<SurveyActionKeyType> = mapStatusToActions(row.status, row.role, hasCreationRight);

  if (isEmpty(actions.actions) && isEmpty(actions.more)) {
    return null;
  }

  return (
    <Actions
      actions={actions}
      onClick={onClick}
      justifyContent={"flex-start"}
    />
  );
}

const ALL_ACTIONS:
(
  Action<SurveyActionKeyType> &
  {
    type: "classic" | "more";
    statuses: ImmutableSet<string>;
    roles: ImmutableSet<AccessList.UserAccessRole>;
    allowedWithCreationRight?: true;
  }
)[] = [
  {
    key: ActionKeys.edit,
    label: "Edit",
    type: "classic",
    statuses: ImmutableSet.of("draft"),
    roles: ImmutableSet.of(AccessList.UserAccessRole.ADMIN),
  },
  {
    key: ActionKeys.edit,
    label: "Edit",
    type: "more",
    statuses: ImmutableSet.of("launched", "closed"),
    roles: ImmutableSet.of(AccessList.UserAccessRole.ADMIN),
  },
  {
    key: ActionKeys.seeReport,
    label: "See report",
    type: "classic",
    statuses: ImmutableSet.of("launched", "closed"),
    roles: ImmutableSet.of(
      AccessList.UserAccessRole.ADMIN,
      AccessList.UserAccessRole.CONVERSATION,
      AccessList.UserAccessRole.READ
    ),
  },
  {
    key: ActionKeys.sendReminder,
    label: "Send reminder",
    type: "classic",
    statuses: ImmutableSet.of("launched"),
    roles: ImmutableSet.of(
      AccessList.UserAccessRole.ADMIN
    ),
  },
  {
    key: ActionKeys.duplicate,
    label: "Duplicate",
    type: "classic",
    statuses: ImmutableSet.of("closed", "draft"),
    roles: ImmutableSet.of(
      AccessList.UserAccessRole.ADMIN,
      AccessList.UserAccessRole.CONVERSATION,
      AccessList.UserAccessRole.READ
    ),
    allowedWithCreationRight: true,
  },
  {
    key: ActionKeys.duplicate,
    label: "Duplicate",
    type: "more",
    statuses: ImmutableSet.of("launched"),
    roles: ImmutableSet.of(
      AccessList.UserAccessRole.ADMIN,
      AccessList.UserAccessRole.CONVERSATION,
      AccessList.UserAccessRole.READ
    ),
    allowedWithCreationRight: true,
  },
  {
    key: ActionKeys.delete,
    label: "Delete",
    type: "classic",
    statuses: ImmutableSet.of("draft"),
    roles: ImmutableSet.of(AccessList.UserAccessRole.ADMIN),
  },
  {
    key: ActionKeys.delete,
    label: "Delete",
    type: "more",
    statuses: ImmutableSet.of("launched", "closed"),
    roles: ImmutableSet.of(AccessList.UserAccessRole.ADMIN),
  },
  {
    key: ActionKeys.close,
    label: "Close",
    type: "more",
    statuses: ImmutableSet.of("launched"),
    roles: ImmutableSet.of(AccessList.UserAccessRole.ADMIN),
  },
];

function mapStatusToActions(status: Survey.Status,
  role: AccessList.UserAccessRole,
  hasCreationRight: boolean): ActionDefinition<SurveyActionKeyType> {

  const actions: Action<SurveyActionKeyType>[] = [];
  const more: Action<SurveyActionKeyType>[] = [];

  for (const action of ALL_ACTIONS) {
    if (
      action.statuses.has(status) &&
      action.roles.has(role) &&
      (hasCreationRight || action.allowedWithCreationRight !== true)
    ) {
      const bucket = action.type === "classic" ? actions : more;
      bucket.push({
        key: action.key,
        label: action.label,
      });
    }
  }

  return {
    actions,
    more,
  };
}
