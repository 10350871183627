import { InputProps } from "antd/lib/input";
import React from "react";
import { Trans } from "react-i18next";
import Tooltip from "@components/core/Tooltip";
import { Icon } from "antd";
import Input from "@components/core/Input";
import { PermanentPlaceHolder } from "@components/core/PermanentPlaceHolder";
import { isNotNullNorUndefined, getOr } from "hyphen-lib/dist/lang/Objects";
import { Optional } from "hyphen-lib/dist/lang/Optionals";

interface NumberInputProps extends InputProps {
  label: string;
  infoText?: string;
  error?: Optional<string>;
  "data-cy"?: string;
}

interface NumberInputState {
  value: string | number | readonly string[] | undefined;
  disabled: boolean | undefined;
}

export class NumberInput extends React.Component<NumberInputProps, NumberInputState> {
  constructor(props: NumberInputProps) {
    super(props);
    this.state = {
      disabled: props.disabled,
      value: props.value,
    };
  }

  componentDidUpdate(prevProps: NumberInputProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  handleNumberChange = (e: any) => {
    const { onChange } = this.props;
    const value = parseInt(e.target.value || 0, 10);
    if (Number.isNaN(value)) {
      return;
    }
    if (onChange) {
      onChange(e);
    }
    this.setState({ value });
  };

  getLabel = (): (React.ReactNode | string) => {
    const { label, infoText } = this.props;
    if (isNotNullNorUndefined(infoText)) {
      return (
        <>
          <Trans>{label}</Trans>
          <Tooltip title={<Trans>{infoText}</Trans>}>
            <Icon type="info-circle" style={{ marginLeft: 5 }}/>
          </Tooltip>
        </>
      );
    }
    return label;
  };

  render() {
    const { error, suffix, label, infoText, ...rest } = this.props;

    return (
      <Input
        value={this.state.value}
        label={this.getLabel()}
        error={error && <Trans>{error}</Trans>}
        suffix={<PermanentPlaceHolder><Trans>{suffix}</Trans></PermanentPlaceHolder>}
        onChange={this.handleNumberChange}
        disabled={this.state.disabled}
        data-cy={getOr(this.props["data-cy"], "")}
        {...rest}
      />
    );
  }
}
