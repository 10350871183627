import React, { Component } from "react";
import ContainerCard from "@components/core/ContainerCard";
import styled from "styled-components";
import { Select } from "antd";
import { Heading, Paragraph } from "@src/components/core/Typography";
import { AppSettingsResource } from "hyphen-lib/dist/domain/resource/AppSettingsResource";
import {
  getLanguageCodeFromString,
  Locale,
  LocaleCodes,
} from "hyphen-lib/dist/util/locale";
import { SelectValue } from "antd/lib/select";
import { getOr } from "hyphen-lib/dist/lang/Objects";
import Palette from "@src/config/theme/palette";
import { getLanguagesForSelectBox } from "../../Xlsx/util";
import { Trans } from "react-i18next";
import { checkIfHyphenAdmin } from "hyphen-lib/dist/business/user/Users";
import { MultiLanguageToggle } from "./MultiLanguageToggle";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";

const { Option } = Select;

interface MultilanguageSettingsProps {
  appSettings: AppSettingsResource;
  onValueChange: (values: AppSettingsResource) => void;
  isMultiLanguageSurveyEnabled: boolean;
  handleModulesChange: (values: AppSettingsResource) => void;
  readonly email: CurrentUserResource["email"];
}

interface MultiLanguageSettingsState {
  defaultLanguageCodes: string[];
}

export default class MultilanguageSettings extends Component<
  MultilanguageSettingsProps,
  MultiLanguageSettingsState
> {
  constructor(props: MultilanguageSettingsProps) {
    super(props);
    this.state = {
      defaultLanguageCodes: [],
    };
  }

  componentDidUpdate(prevProps: MultilanguageSettingsProps) {
    if (
      prevProps.appSettings.surveyLanguages !==
      this.props.appSettings.surveyLanguages
    ) {
      this.setState({
        defaultLanguageCodes: getOr(this.props.appSettings.surveyLanguages, []),
      });
    }
  }

  componentDidMount() {
    this.setState({
      defaultLanguageCodes: getOr(this.props.appSettings.surveyLanguages, []),
    });
  }

  onLanguageChange(v: SelectValue) {
    const { appSettings } = this.props;
    appSettings.surveyLanguages = (v as string[]).map((i) =>
      getLanguageCodeFromString(i)
    ) as LocaleCodes[];
    this.props.onValueChange(appSettings);
  }

  render() {
    const { handleModulesChange, appSettings, isMultiLanguageSurveyEnabled, email } = this.props;
    if (checkIfHyphenAdmin(email) || isMultiLanguageSurveyEnabled) {
      return (
        <StyledContainerCard>
          {
            checkIfHyphenAdmin(email) &&
            (<MultiLanguageToggle
              onValueChange={handleModulesChange}
              appSettings={appSettings}
              isMultiLanguageSurveyEnabled={isMultiLanguageSurveyEnabled}
            />)
          }
          {
            isMultiLanguageSurveyEnabled &&
            <div data-jest="isMultiLanguageSurveyEnabled" style={{ paddingTop: "12px" }}>
              <Heading translate="yes">Languages</Heading><Holder>
                <Paragraph translate="yes">
                  These settings will be relevant to multi-language surveys. The
                  survey creators will only be able to select the languages set here.
                </Paragraph>
                <div style={{ color: Palette.bluishGrey, marginTop: "5px" }}>
                  <Trans>English is the default survey language. You can select additional
                    languages for this survey from the list of languages set in App
                    settings.</Trans>
                </div>
                <div className="d-flex flex-column" style={{ marginTop: "20px" }}>
                  <div style={{ marginBottom: "5px" }}><Trans>Additional Survey Languages</Trans></div>
                  <Select
                    mode="multiple"
                    showArrow
                    data-jest="multipleSelect"
                    data-cy="multipleSelect"
                    style={{ width: 530 }}
                    size="large"
                    onChange={(v: SelectValue) => this.onLanguageChange(v)}
                    value={getLanguagesForSelectBox(
                      this.state.defaultLanguageCodes as LocaleCodes[]
                    ).map((langString) => langString)}
                  >
                    {getLanguagesForSelectBox(
                      Object.keys(Locale) as LocaleCodes[]
                    ).map((langString) => (
                      <Option
                        key={langString}
                        value={langString}
                        data-cy="multipleOptions">
                        {langString}
                      </Option>
                    ))}
                  </Select>
                </div>
              </Holder>
            </div>
          }
        </StyledContainerCard>
      );
    }
    return null;
  }
}

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 28px;
`;

const StyledContainerCard = styled(ContainerCard)`
  margin-bottom: 39px;
  input:focus {
    outline: none;
  }
`;
