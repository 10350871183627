import React from "react";
import { connect } from "react-redux";
import { State } from "@store/types";
import { RightsMatcher } from "@hyphen-lib/business/auth/Auth";
import { getRightsMatcher } from "@screens/Insights/store/selectors";
import { RouteComponentProps, withRouter, Redirect } from "react-router";
import { getRightsForRoute, FALLBACK_ROUTES } from "@screens/Insights/MainNavigation/DefaultRoutesConfiguration";
import { isNotNullNorUndefined, getOr } from "hyphen-lib/dist/lang/Objects";

interface RedirectContainerRouteProps extends RouteComponentProps { }

interface RedirectContainerStateProps {
  readonly rightsMatcher: RightsMatcher;
}

type RedirectContainerProps = RedirectContainerRouteProps & RedirectContainerStateProps;

class RedirectContainerClass extends React.Component<RedirectContainerProps> {
  render() {
    const { rightsMatcher } = this.props;
    const fallbackRoute = FALLBACK_ROUTES.find((route) => {
      const rights = getOr(getRightsForRoute(route), []);
      const isAllowed = rightsMatcher.hasEveryOf(...rights);
      return isAllowed;
    });
    if (isNotNullNorUndefined(fallbackRoute)) {
      return <Redirect to={fallbackRoute} />;
    }
    return <Redirect to={"/errors/403"} />;
  }
}

function mapStateToProps(state: State, ownProps: RedirectContainerRouteProps): RedirectContainerStateProps {
  return {
    rightsMatcher: getRightsMatcher(state),
  };
}

export const RedirectContainer =
  withRouter(connect(mapStateToProps)(RedirectContainerClass));
