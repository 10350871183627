import styled from "styled-components";
import { NotificationChannel, NotificationChannels } from "hyphen-lib/dist/domain/notification/NotificationChannels";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { isNotNullNorUndefined, getOr } from "hyphen-lib/dist/lang/Objects";
import { CardSection } from "@components/core/InfoCard/CardSection";
import { useTranslation } from "react-i18next";
import { translate } from "@src/utils/i18next";

interface ChannelSummarySectionProps {
  allowedChannels: CompanyResource["channels"];
  channels: NotificationChannels;
}
export default function ChannelSummarySection({ allowedChannels, channels }: ChannelSummarySectionProps) {

  function renderChannelSummary(channelKey: keyof CompanyResource["channels"]) {
    const channel = channels[channelKey];
    const { t } = useTranslation();
    if (allowedChannels[channelKey]) {
      const channelsData = [{
        label: getChannelName(channelKey),
        value: NotificationChannel.isUsed(channels[channelKey]) ? "Yes" : "No",
      }];

      if (isNotNullNorUndefined(channel) && NotificationChannel.isUsed(channel)) {
        channelsData.push({
          label: `${getChannelName(channelKey)} ${translate(t, "body")}`,
          value: getOr(channel.body,  ""),
        });
      }
      return <CardSection data={channelsData} data-cy="channel-summary-section"/>;
    }
  }

  function renderEmailInfo() {
    const channelsData = [];
    if (allowedChannels.email) {
      channelsData.push({
        label: "Email",
        value: NotificationChannel.isUsed(channels.email) ? "Yes" : "No",
      });

      if (NotificationChannel.isUsed(channels.email)) {
        channelsData.push({
          label: "Email subject",
          value: channels.email.subject,
        }, {
          label: "Email body",
          value: channels.email.body,
        });
      }

      return <CardSection data={channelsData} />;
    }
  }

  return (
    <>
      {renderEmailInfo()}

      {renderChannelSummary("slack")}
      {renderChannelSummary("beekeeper")}
      {renderChannelSummary("speakap")}
      {renderChannelSummary("symphony")}
      {renderChannelSummary("workplace")}
      {renderChannelSummary("sms")}
    </>
  );
}

function getChannelName(channelKey: keyof CompanyResource["channels"]) {
  return channelKey.charAt(0).toUpperCase() + channelKey.slice(1);
}

export const ChannelSection = styled.div`
  margin-top: 24px !important;
`;
