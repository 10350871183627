import React from "react";
import { Participation } from "@hyphen-lib/domain/common/Participation";
import BarChart, { BarChartDimension } from "@src/components/core/BarChart";
import { calculatePercentage } from "hyphen-lib/dist/util/math/Math";
import { getSegmentLabel } from "hyphen-lib/dist/domain/common/Dimensions";
import { sanitizeSegmentLabel } from "hyphen-lib/dist/domain/common/Dimensions";
import { sortedParticipationSegment } from "@src/utils/participationSegment";

interface Dimension {
  [segment: string]: Participation;
}

interface Props {
  dataForChart?: Dimension;
}

export default class ParticipationChart extends React.Component<Props> {
  labelWidth = "170px";

  getDataForChart = (input?: Dimension): BarChartDimension | undefined => {
    if (!input) {
      return undefined;
    }
    const chartData: BarChartDimension = {};
    Object.keys(input).forEach((label: string) => {
      const { completed, total } = input[label];
      const percentage = total ? calculatePercentage(completed, total) : 0;

      const segmentLabel = sanitizeSegmentLabel(getSegmentLabel(label));

      chartData[segmentLabel] = {
        series: [{
          value: completed,
          percentage,
          label: segmentLabel,
        }],
        total,
        filteredForAnonymity: false,
      };
    });
    return sortedParticipationSegment(chartData);
  };

  render() {
    return (
      <BarChart
        data={this.getDataForChart(this.props.dataForChart)}
        labelWidth={"170px"}
        anonymityThreshold={0}
      />
    );
  }
}
