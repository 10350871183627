import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { isNotNullNorUndefined } from "@hyphen-lib/lang/Objects";

type Props = RouteComponentProps ;

class ScrollToTop extends Component<Props> {
  componentDidUpdate(prevProps: any) {
    const currentPathName = this.props.location.pathname;
    const prevPathName = prevProps.location.pathname;
    if (currentPathName !== prevPathName) {
      window.scrollTo(0, 0);
      const mainNavigationContainer = document.querySelector("#navigationContainer");
      if (isNotNullNorUndefined(mainNavigationContainer)) {
        mainNavigationContainer.scrollTop = 0;
      }
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);