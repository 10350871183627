import { Map as ImmutableMap } from "immutable";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import {
  cleanObject,
  entries,
  isNullOrUndefined,
  isObject,
  mapKeysInDepth,
  mergeObjectInto,
  getOr,
  isEmptyObject
} from "hyphen-lib/dist/lang/Objects";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { PropMapping } from "@src/utils/parameters";
import { mapInMap } from "hyphen-lib/dist/lang/Maps";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { onlyField } from "hyphen-lib/dist/lang/Functions";
import { findInStorage, putInStorage, removeInStorage } from "@src/utils/storages";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { ActionTypes, LogoutUserAction } from "@screens/Insights/store/actions";
import { ModifyParametersAction, ParametersActionTypes } from "./actions";

const PARAMETERS_STORAGE_KEY = "parameters";

export type ParametersState = ImmutableMap<string, any>;

export const EMPTY_STATE: ParametersState = ImmutableMap();

export const parametersDefaultValues: ParametersState =
  getOr(
    Optional.map(
      findInStorage(PARAMETERS_STORAGE_KEY),
      obj => ImmutableMap(obj as any)
    ),
    EMPTY_STATE
  );

export const parametersStateFactory = () => parametersDefaultValues;
const defaultState = parametersStateFactory();

type Actions = ModifyParametersAction | LogoutUserAction;

export const parametersReducer = (state: ParametersState = defaultState, action: Actions) => {
  // noinspection JSRedundantSwitchStatement
  switch (action.type) {
    case ParametersActionTypes.MODIFY_PARAMETERS:
      return putInStorage(
        PARAMETERS_STORAGE_KEY,
        mergeParameters(
          action.payload.parameters,
          action.payload.mappings,
          state
        )
      );
    case ActionTypes.LOGOUT_USER:
      removeInStorage(PARAMETERS_STORAGE_KEY);
      return EMPTY_STATE;
    default:
      return state;
  }
};

function mergeParameters(newParameters: Dictionary<any>,
  mappings: PropMapping[],
  existingState: ImmutableMap<string, any>): ImmutableMap<string, any> {

  if (isNotEmptyArray(mappings)) {
    // map the parameters to store
    const mappingsMap = mapInMap(mappings, onlyField("localKey"), onlyField("storeKey"));
    newParameters = mapKeysInDepth(
      newParameters,
      (key: string) => mappingsMap.get(key, key)
    );
  }

  return existingState.withMutations(
    mutable =>
      entries(newParameters)
        .forEach((value: any, key: string) => {
          if (isNullOrUndefined(value)) {
            // we need to remove the value in state
            mutable.remove(key);
          } else if (isObject(value)) {
            const existing = mutable.get(key);
            if (isEmptyObject(value)) {
              mutable.remove(key);
            } else if (isNullOrUndefined(existing) || not(isObject(existing))) {
              // easy case, just set the new object
              mutable.set(key, value);
            } else {
              // merge the new parameters into the existing object
              mutable.set(key, cleanObject(mergeObjectInto(value, existing)));
            }
          } else {
            // for all other cases, just overwrite the existing entry
            mutable.set(key, value);
          }
        })
  );
}
