import React from "react";
import { DynamicRowProps } from "@components/core/DynamicRowsTable/withDynamicRows";
import {
  CellContainer
} from "@src/screens/Insights/SurveyList/components/cells/Cells";
import CompareValue from "@src/components/core/CompareValue";
import { Score } from "@screens/Insights/components/Reports/Score";
import styled from "styled-components";
import { PulsePollListOverviewResource } from "hyphen-lib/dist/domain/resource/PulsePollListOverviewResource";
import incognito from "@src/components/core/AnonymityFiltered/incognito.png";
import Tooltip from "@src/components/core/Tooltip";
import { capitalizeFirstLetter } from "@src/utils/helper";
import { isNullOrUndefined } from "hyphen-lib/dist/lang/Objects";
import { Trans } from "react-i18next";

export function PulsePollFavorabilityCell({
  row,
  index,
}: DynamicRowProps<PulsePollListOverviewResource>) {

  const { scoreSummary } = row;

  function isComparisonAvailable() {
    if (scoreSummary && !scoreSummary.filteredForAnonymity) {
      if (scoreSummary.compare && scoreSummary.compare.previous) {
        const { previous }= scoreSummary.compare;
        if (!previous.filteredForAnonymity) {
          return { exists: Boolean(previous.value), value: previous.value };
        }
      }
    }
    return { exists: false };
  }

  if ((row.instances && row.instances.length === 0) || isNullOrUndefined(scoreSummary)) {
    return <div data-cy={`pollOverviewList_score_${index}`}><Trans>No data</Trans></div>;
  }
  
  if (scoreSummary.filteredForAnonymity) {
    return (
      <Tooltip title={<Trans>{capitalizeFirstLetter(scoreSummary.explanation.reason)}</Trans>}>
        <StyledImage data-cy={`pollOverviewList_score_${index}`} src={incognito} alt={"Filtered for anonymity"} />
      </Tooltip>
    ); 
  }

  return (
    <CellContainer
      align="center"
      justifyContent="flex-start"
      data-cy={`pollOverviewList_score_${index}`}
    >
      <Score size="small" score={scoreSummary.score} format={scoreSummary.scoreType!} />
      {isComparisonAvailable().exists && (
        <Comparison>
          <CompareValue
            compare={isComparisonAvailable().value}
            compareWith="previous"
          />
        </Comparison>
      )}
    </CellContainer>
  );
}

const Comparison = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
`;

const StyledImage = styled.img`
  height: 25px;
  opacity: 0.2;
`;
