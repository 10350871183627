import React from "react";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { connect } from "react-redux";
import { FiltersContent } from "@screens/Insights/components/FiltersContent";
import { State } from "@store/types";
import { getPulsePollInfoFilters } from "@screens/Insights/PulsePollList/store/selectors";
import { getOr } from "hyphen-lib/dist/lang/Objects";
import { DateFilter } from "@screens/Insights/components/Filters/business/DateFilter";
import { PostCategoryResource } from "hyphen-lib/dist/domain/resource/post/PostCategoryResource";
import { actionCreators as SurveysActionCreator } from "@screens/Insights/Surveys/store/actions";
import { applyDefault } from "@src/utils/Currier";
import {
  CategoryFilter,
  PulsePollStatusFilter,
  PulsePollFrequencyFilter
} from "@src/screens/Insights/components/Filters/business/PulsePollFilters";
import { pulsePollListActionCreators } from "../store/actions";
import { getPostCategorys } from "../../Surveys/store/selectors";

export interface PulsePollListFiltersContainerStateProps {
  readonly innerValues: Dictionary<any>;
  readonly categories: PostCategoryResource[];
}

export interface SurveyListContainerActionProps {
  readonly onModifyFilter: (filters: any) => any;
  readonly onCleanFilter: () => any;
  readonly fetchPostCategories: () => void;
}

export interface SurveyListContainerOwnProps {
  readonly values?: Dictionary<any>;
  readonly categories: PostCategoryResource[];
  readonly onApply: (filters: any) => any;
}

export type PulsePollListFiltersContainerProps = SurveyListContainerOwnProps &
PulsePollListFiltersContainerStateProps &
SurveyListContainerActionProps;

/* exported for unit tests only */
export class PulsePollListFilters extends React.Component<
PulsePollListFiltersContainerProps
> {
  componentDidMount() {
    const {
      onModifyFilter,
      categories,
      fetchPostCategories,
      values,
    } = this.props;
    /*
        Modify filters with initial values while mounting.
     */
    onModifyFilter(getOr(values, {}));
    if (categories.length === 0) {
      fetchPostCategories();
    }
  }

  componentWillUnmount() {
    this.props.onCleanFilter();
  }

  render() {
    const { values, onApply, onModifyFilter, categories } = this.props;

    return (
      <FiltersContent
        filters={[
          {
            key: "lastInstanceDate",
            label: "Date range (by most recent iteration)",
            component: DateFilter,
          },
          {
            key: "categories",
            label: "Categories",
            component: applyDefault(CategoryFilter, {
              categories,
            }),
          },
          {
            key: "status",
            label: "Status",
            component: PulsePollStatusFilter,
          },
          {
            key: "frequency",
            label: "Frequency",
            component: PulsePollFrequencyFilter,
          },
        ]}
        displayCount={false}
        onChange={onModifyFilter}
        onApply={onApply}
        values={values}
      />
    );
  }
}

function mapStateToProps(
  state: State
): PulsePollListFiltersContainerStateProps {
  const innerFilterValues = getPulsePollInfoFilters(state);

  return {
    innerValues: getOr(innerFilterValues, {}),
    categories: getPostCategorys(state).toArray().reverse(),
  };
}

const mapDispatchToProps = {
  onModifyFilter: pulsePollListActionCreators.modifyFilter,
  onCleanFilter: pulsePollListActionCreators.cleanFilter,
  fetchPostCategories: SurveysActionCreator.fetchPostCategorys,
};

export const PulsePollListFiltersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PulsePollListFilters);
