import React from "react";
import styled from "styled-components";
import { Paragraph } from "@components/core/Typography";
import Radio from "@src/components/core/Radio";
import { Card } from "antdv4";
import { translate } from "@src/utils/i18next";
import {
  isEmptyObject,
  isNotNullNorUndefined,
  isNullOrUndefined,
} from "hyphen-lib/dist/lang/Objects";
import {
  ActionPlanSettingsFieldTypes,
  ActionPlanValues,
} from "./ActionPlanSettings";
import { OnOffSwitch } from "@src/components/core/OnOffSwitch";
import SelectOptionGroup from "@src/components/core/SelectOptionGroup";
import { ActionResource } from "hyphen-lib/dist/domain/resource/action/ActionResource";
import { not } from "hyphen-lib/dist/lang/Booleans";
import Modal from "@src/components/core/Modal";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import { isEmpty, isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { debounce } from "lodash";
import OKRSearchListOption from "./OKRSearchListOption";
import Button from "@components/core/Button";
import OKRDetailCard from "./OKRDetailCard";
import { NewBadgeSvg } from "@src/components/core/svg/NewBadgeSvg";
import Palette from "@src/config/theme/palette";
import { differenceBy } from "lodash";
import { Company } from "hyphen-lib/dist/domain/Company";
import { Trans, WithTranslation, withTranslation } from "react-i18next";

const { WithOKRType } = ActionResource;

enum ObjectiveLabel {
  PUBLIC_GOAL = "Public",
  PERSONAL_GOAL = "Your",
}
export interface ActionPlanOKRProps extends WithTranslation{
  isDisabled?: boolean;
  values: ActionPlanValues;
  objectiveList?: Optional<ActionResource.Objective[]>;
  isEditScreen?: boolean;
  isOKREnabled?: boolean;
  currentUserEmail: string;
  fetchingObjective: boolean;
  onSettingsChange: (
    fieldType: ActionPlanSettingsFieldTypes,
    value: any
  ) => void;
  showOkrFeatureNotification?: () => void;
  fetchGoals: (searchQuery?: string) => void;
  okrNamingConvention: Company.GoalSetting;
}

interface ActionPlanOkrState {
  isKeyResultSelected: boolean;
  okr: ActionResource.OKRCreateRequestType;
  linkActionPlanToOkr: boolean;
  searchQuery: string;
  userObjectives: ActionResource.Objective[];
  publicObjectives: any[];
  filteredUserObjectives: any[];
  showSearchObjectiveModal: boolean;
  showOkrDetailCard: boolean;
}

export class ActionPlanOKR extends React.Component<
  ActionPlanOKRProps,
  ActionPlanOkrState
> {
  state = {
    isKeyResultSelected: false,
    okr: {
      type: WithOKRType.OBJECTIVE,
      name: "",
    } as ActionResource.OKRCreateRequestType,
    linkActionPlanToOkr: true,
    userObjectives: [] as ActionResource.Objective[],
    searchQuery: "",
    publicObjectives: [],
    filteredUserObjectives: [],
    showSearchObjectiveModal: false,
    showOkrDetailCard: false,
  };

  componentDidMount() {
    const { okr, action, assignee, dueDate} = this.props.values;
    const { isOKREnabled, isEditScreen, onSettingsChange } = this.props;
    if ((isEmptyObject(okr) || not(ActionResource.isOkrCreateSuccessType(okr))) && isEditScreen) {
      this.setState({
        okr: {
          type: ActionResource.WithOKRType.OBJECTIVE,
          name: action,
          assignee,
          dueDate
        },
        linkActionPlanToOkr: true
      }, () => {
        this.changeOkrSettings();
      });
    }
    if (!isOKREnabled) {
      onSettingsChange("okr", {});
      this.setState({
        okr: {} as ActionResource.OKRCreateRequestType,
        linkActionPlanToOkr: false,
      }, () => {
        this.changeOkrSettings();
      });
    }
  }

  componentDidUpdate(
    prevProps: ActionPlanOKRProps,
    prevState: ActionPlanOkrState
  ) {
    const { userObjectives, showOkrDetailCard, okr } = this.state;

    const { objectiveList, fetchingObjective, values } = this.props;

    if (prevProps.objectiveList !== objectiveList && not(fetchingObjective)) {
      this.updateSearchResult();
    }

    if (
      isNotNullNorUndefined(objectiveList) &&
      isEmpty(userObjectives) &&
      not(fetchingObjective) &&
      prevProps.objectiveList !== objectiveList
    ) {
      this.setState(
        { userObjectives: JSON.parse(JSON.stringify(objectiveList)) },
        () => {
          this.updateSearchResult();
        }
      );
    }

    if (
      okr?.type === WithOKRType.KEY_RESULT &&
      isStringAndNotEmpty(okr.name) &&
      isNotNullNorUndefined(okr.id) &&
      not(showOkrDetailCard)
    ) {
      this.setState({ showOkrDetailCard: true });
    }

    if (okr?.type === WithOKRType.OBJECTIVE && showOkrDetailCard) {
      this.setState({ showOkrDetailCard: false });
    }

    // updates assignee and dueDate in OKR card dynamically while creating KR
    if (prevProps.values.assignee !== values.assignee) {
      this.setState({
        okr: {
          ...JSON.parse(JSON.stringify(okr)),
          assignee: values.assignee,
        },
      });
    }
    if (prevProps.values.dueDate !== values.dueDate) {
      this.setState({
        okr: {
          ...JSON.parse(JSON.stringify(okr)),
          dueDate: values.dueDate,
        },
      });
    }
  }


  isGoalMatchedWithQuery = (
    wordsToBeSearched: string[],
    goal: string,
    currentUserName: string,
  ): boolean => {
    if (!wordsToBeSearched.length && isNullOrUndefined(goal)) {
      return true;
    }
    goal += ` ${currentUserName}`;
    return wordsToBeSearched.every(word => goal.toLowerCase().includes(word.toLowerCase()));
  };

  mapObjectivesToOkrOptionList = (objective: ActionResource.Objective) => {
    return {
      id: objective.id,
      value: objective.id,
      label: (<OKRSearchListOption
        goalName={objective.name}
        owner={objective.username}
        id={objective.id}
        profileUrl={objective.pictureUri}
        key={objective.id}
      />),
    };
  };

  updateSearchResult = () => {
    const { objectiveList, currentUserEmail, fetchingObjective } = this.props;
    const { userObjectives, searchQuery } = this.state;
    let wordsInSearchQuery: string[] = [];
    if (isNotNullNorUndefined(searchQuery)) {
      wordsInSearchQuery = searchQuery.toLowerCase().split(" ")
        .filter(words => isStringAndNotEmpty(words));
    }
    let currentUserObjective: ActionResource.Objective[] = [];

    // merge backend response for current user goal with frontend current user data
    if (isNotEmptyArray(objectiveList)) {
      const personalGoalFromBackend = objectiveList.filter(objective => objective.email === currentUserEmail);
      const personalGoalFromFrontEnd = userObjectives.filter(objective => objective.email === currentUserEmail);
      const differenceInGoals = differenceBy(personalGoalFromFrontEnd, personalGoalFromBackend, "id");
      currentUserObjective = [...personalGoalFromBackend, ...differenceInGoals];
    }

    // if there is no backend current user response then use only front end current user data
    if (not(isNotEmptyArray(currentUserObjective))) {
      currentUserObjective = [...JSON.parse(JSON.stringify(userObjectives))];
      currentUserObjective = currentUserObjective.filter(objective => objective.email === currentUserEmail);
    }
    if (isNotNullNorUndefined(objectiveList) && not(fetchingObjective)) {
      const filteredUserObjectives = currentUserObjective
        .filter((objective) => this.isGoalMatchedWithQuery(
          wordsInSearchQuery,
          objective.name,
          objective.username,
        ))
        .map(this.mapObjectivesToOkrOptionList);
      const publicObjectives = objectiveList
        .filter(objective => objective.email !== currentUserEmail)
        .map(this.mapObjectivesToOkrOptionList);
      this.setState({
        filteredUserObjectives,
        publicObjectives,
      });
    }
  };
  
  changeOkrSettings = () => {
    const { okr } = this.state;
    this.props.onSettingsChange("okr", okr);
  };

  onToggleSwitchClicked = () => {
    const { showOkrFeatureNotification, isOKREnabled, isEditScreen } =
      this.props;
    if (not(isOKREnabled) && not(isEditScreen)) {
      return showOkrFeatureNotification && showOkrFeatureNotification();
    }
  };

  onLinkActionPlanChange = () => {
    const { action, dueDate, assignee } = this.props.values;
    this.setState(
      (prevState) => ({ linkActionPlanToOkr: !prevState.linkActionPlanToOkr }),
      () => {
        const { linkActionPlanToOkr } = this.state;
        if (linkActionPlanToOkr) {
          this.setState(
            {
              okr: {
                type: ActionResource.WithOKRType.OBJECTIVE,
                name: action,
                assignee,
                dueDate,
              },
            },
            this.changeOkrSettings
          );
        } else {
          this.setState(
            {
              okr: {} as ActionResource.OKRCreateRequestType,
              isKeyResultSelected: false,
              showOkrDetailCard: false,
            },
            this.changeOkrSettings
          );
        }
      }
    );
  };

  onOkrChange = (e: any) => {
    const { dueDate, assignee } = this.props.values;
    const value = e.target.value;
    this.setState(
      {
        isKeyResultSelected: value === WithOKRType.KEY_RESULT,
        okr: {
          type: value,
          name: "",
          assignee,
          dueDate,
        },
      },
      this.changeOkrSettings
    );
  };

  onSelectKRObjectiveChange = (value: {
    label: any;
    key: string;
    value: string;
  }) => {
    const { goalName, id: goalId } = value.label.props;
    const { okr } = this.state;
    this.setState(
      {
        searchQuery: "",
        showSearchObjectiveModal: false,
        okr: {
          ...okr,
          name: goalName,
          id: goalId,
        } as ActionResource.CreateKeyResultRequest,
      },
      this.changeOkrSettings
    );
    this.props.fetchGoals();
  };

  onObjectiveSearch = (value: string) => {
    this.setState({ searchQuery: value }, () => {
      this.props.fetchGoals(value);
    });
  };

  showSearchObjectiveModal = () => {
    this.setState({
      showSearchObjectiveModal: true,
    });
  };

  closeModal = () => {
    this.setState(
      { showSearchObjectiveModal: false, showOkrDetailCard: false },
      () => {
        this.onOkrChange({ target: { value: WithOKRType.OBJECTIVE } });
      }
    );
  };

  showOKROptions = (okrSynonyms: Company.GoalSetting, isDisabled?: boolean) => {
    const {
      filteredUserObjectives,
      publicObjectives,
      showSearchObjectiveModal,
      isKeyResultSelected,
      showOkrDetailCard,
    } = this.state;
    const { okr } = this.props.values;
    const { fetchingObjective, t} = this.props;
    const objectiveOptGroupData = [];
    if (isNotEmptyArray(filteredUserObjectives)) {
      objectiveOptGroupData.push({
        label: `${ObjectiveLabel.PERSONAL_GOAL} ${okrSynonyms.objective}`,
        options: filteredUserObjectives,
      });
    }
    if (isNotEmptyArray(publicObjectives)) {
      objectiveOptGroupData.push({
        label: `${ObjectiveLabel.PUBLIC_GOAL} ${okrSynonyms.objective}`,
        options: publicObjectives,
      });
    }
    const groupLabels = {
      userObjectives: `${ObjectiveLabel.PERSONAL_GOAL} ${okrSynonyms.objective}`,
      publicObjectives: `${ObjectiveLabel.PUBLIC_GOAL} ${okrSynonyms.objective}`,
    };
    let objectiveButtonText = `Choose an ${okrSynonyms.objective}`;
    if (
      okr?.type === WithOKRType.KEY_RESULT &&
      isNotNullNorUndefined(okr.name) &&
      isNotNullNorUndefined(okr.id)
    ) {
      objectiveButtonText = `Change ${okrSynonyms.objective}`;
    }
    const okrType = ActionResource.isOkrCreateRequestType(okr) ? okr.type : WithOKRType.OBJECTIVE; 
    return (
      <RadioGroup
        disabled={isDisabled}
        value={okrType}
        onChange={this.onOkrChange}
      >
        <CustomCard>
          <CustomRadio
            data-cy={"createActionAsObjective"}
            disabled={isDisabled}
            value={WithOKRType.OBJECTIVE}
          >
            <Trans>Create a new {okrSynonyms.objective}</Trans>
          </CustomRadio>
          <SubParagraph>
            <Trans>Create a stand alone {okrSynonyms.objective}. You 
            will be able to align it directly in betterworks.</Trans>
          </SubParagraph>
        </CustomCard>
        <CustomCard>
          <CustomRadio
            disabled={isDisabled}
            data-jest="chooseKeyResult"
            data-cy="chooseKeyResult"
            value={WithOKRType.KEY_RESULT}
          >
            <Trans>Create a {okrSynonyms.keyResult} contributing to an existing {okrSynonyms.objective}</Trans>
          </CustomRadio>
          {showOkrDetailCard && (
            <OKRDetailCard width="100%" okr={this.state.okr} okrSynonyms={okrSynonyms} />
          )}
          <ChooseObjective
            data-jest="chooseOjectiveButton"
            data-cy="chooseOjectiveButton"
            disabled={not(isKeyResultSelected)}
            onClick={this.showSearchObjectiveModal}
            translate="yes"
          >
            {objectiveButtonText}
          </ChooseObjective>
          <SearchObjectiveModal
            data-jest="searchObjectiveModal"
            data-cy="searchObjectiveModal"
            visible={showSearchObjectiveModal}
            destroyOnClose
            footer={null}
            onCancel={this.closeModal}
            title={`${translate(t,"Select")} ${okrSynonyms.objective} ${translate(t,"to align to")}`}
          >
            <SelectObjective
              data-jest="searchobjectiveInput"
              data-cy="searchObjectiveInput"
              groupLabels={groupLabels}
              selectedValues={""}
              filterOption={false}
              autoFocus={true}
              showAction={["focus"]}
              loading={fetchingObjective}
              placeholder={`${translate(t,"Start typing to search for an")} ${okrSynonyms.objective}`}
              numberOfValuesToShow={100}
              onSearch={debounce(this.onObjectiveSearch, 200)}
              onChange={this.onSelectKRObjectiveChange}
              data={objectiveOptGroupData}
              notFoundContent={<Trans>No goals found</Trans>}
            />
          </SearchObjectiveModal>
        </CustomCard>
      </RadioGroup>
    );
  };

  render() {
    const { isDisabled, isOKREnabled, okrNamingConvention } = this.props;
    const { linkActionPlanToOkr } = this.state;
    const disabledOnOffSwitch = isOKREnabled ? isDisabled : true;
    const disableRadioOptions = isOKREnabled ? not(linkActionPlanToOkr) : true;
    const onOffSwitchChecked = isOKREnabled ? linkActionPlanToOkr : false;

    return (
      <OKRWrapper>
        <Label>
          <NewBadgeSvg />
          <Trans>Add to my {okrNamingConvention.okrs} in Betterworks</Trans>
        </Label>
        <OnOffSwitch
          onChange={this.onLinkActionPlanChange}
          onClick={this.onToggleSwitchClicked}
          disabled={disabledOnOffSwitch}
          checked={onOffSwitchChecked}
          info={`Add to my ${okrNamingConvention.okrs}`}
        />
        <SubParagraph><Trans>
          The name, assignee and due date will be set automatically. You will
          still be able to create an {okrNamingConvention.okrs} after creating the Action
          Plan.</Trans>
        </SubParagraph>
        {this.showOKROptions(
          okrNamingConvention,
          disableRadioOptions
        )}
      </OKRWrapper>
    );
  }
}

const OKRWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  max-width: 370px;
  max-height: 550px;
  margin-left: 20px;
  padding: 6px;
`;

const Label = styled(Paragraph)`
  font-weight: bold;
  font-family: Lato;
  font-size: 16px;
  margin-bottom: 12px;
  padding-top: 12px;
`;

const SubParagraph = styled.div`
  margin-top: 6px;
  margin-bottom: 10px;
  color: ${Palette.bluishGrey};
  font-family: Lato;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
`;

const SelectObjective = styled(SelectOptionGroup)`
  .ant-select {
    width: 540px;
  }
  .ant-select-dropdown {
    z-index: 1000;
    box-shadow: none;
    top: 40px !important;
    left: 0px !important;
  }
  .ant-select-dropdown-hidden {
    display: block !important;
  }
  .ant-select-item-group {
    color: ${Palette.darkBlueGrey};
    font-family: Lato;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
  }
  .ant-select-selector {
    height: 36px !important;
  }

  .rc-virtual-list-holder {
    max-height: 320px !important;
  }
  margin-left: 32px;
`;

const RadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
`;

const CustomCard = styled(Card)`
  margin-right: 20px;
  margin-top: 10px;
  width: 333px;
  border: 1px solid ${Palette.lightGreyBlue};
  border-radius: 3px;
  background: linear-gradient(
    180deg,
    ${Palette.white} 0%,
    ${Palette.athensGrey} 100%
  );
`;

const CustomRadio = styled(Radio)`
    display: inline-block;
    height: 20px;
    margin-bottom: 10px;
    white-space: pre-line;
    
    .ant-radio-disabled .ant-radio-inner::after {
        background-color: ${Palette.lightPeriwinkle} !important;
    }
`;

const SearchObjectiveModal = styled(Modal)`
  .ant-modal-content {
    width: 600px;
    height: 500px;
  }
  color: ${Palette.darkBlueGrey};
  font-family: Lato;
  font-size: 26px;
  letter-spacing: 0;
  line-height: 32px;
`;

const ChooseObjective = styled(Button)`
  margin-top: 12px;
  background: ${Palette.aquaBlue};
  border-radius: 2px !important;
  border: 1px solid ${Palette.irisBlue};
  height: 30px !important;

  &:hover {
    margin-top: 12px;
    background: ${Palette.aquaBlue};
    border-radius: 2px !important;
    border: 1px solid ${Palette.irisBlue};
    height: 30px !important;
  }

  span {
    color: white;
    font-family: Lato;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
    text-align: center;
  }

  &[disabled] {
    background: ${Palette.onahau} !important;
  }
`;

export default withTranslation()(ActionPlanOKR);
