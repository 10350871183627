import React from "react";
import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
import { entries } from "hyphen-lib/dist/lang/Objects";

import { AddFilters } from "../base/AddFilters";
import { BaseCustomFilterProps } from "../../FiltersContent";

export interface AddDimensionFilterProps
  extends BaseCustomFilterProps<string[]> {
  readonly dimensions: Dimensions;
}

function createLabel(label: string) {
  return label;
}


export function AddDimensionFilter({
  dimensions,
  ...rest
}: AddDimensionFilterProps) {
  const options = entries(dimensions)
    .map((dimension, dimKey) => ({
      key: `dimensions.${dimKey}`,
      label: createLabel(dimension.label),
    }))
    .valueSeq()
    .toArray();

  return <AddFilters options={options} {...rest} />;
}
