import React from "react";
import Modal from "@src/components/core/Modal";
import { Trans } from "react-i18next";
import { Form, Row, Col } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { ModalProps } from "antd/lib/modal";
import { SelectProps, OptionProps } from "antd/lib/select";
import { InputProps } from "antd/lib/input";
import Button from "@components/core/Button";
import Input from "@components/core/Input";
import Select from "@components/core/Select";
import styled from "styled-components";
import { isNullOrUndefined, isNotNullNorUndefined} from "hyphen-lib/dist/lang/Objects";
import { UserResource } from "hyphen-lib/dist/domain/resource/user/UserResource";
import { Dimensions, Direct_MANAGER, shouldExcludeDirectManager } from "hyphen-lib/dist/domain/common/Dimensions";
import Palette from "@src/config/theme/palette";
import Checkbox from "@components/core/Checkbox";
import DatePicker from "@components/core/DatePicker";
import { NetworkEventSuccessAction } from "@store/network/actions";
import AreYouSureModal from "@components/core/AreYouSureModal";
import { MOBILE_REGEX, checkIfHyphenAdmin, isEmailFormatValid } from "hyphen-lib/dist/business/user/Users";
import moment from "moment";
import { dateFormat } from "@src/utils/helper";
import Tooltip from "@components/core/Tooltip";
import { Icon } from "antd";
import {LANGUAGE_BY_LOCALE} from "../utils/constants";
import { getDimensionSegmentLabel } from "@src/utils/Dimensions";
import { TFunction } from "i18next";
import { translate } from "@src/utils/i18next";
import { isEmpty, isString } from "lodash";
const { Option } = Select;

interface EditUserProps {
  readonly visible: boolean;
  readonly closeModal: () => void;
  readonly userToEdit: UserResource;
  readonly allowSMSPermission: boolean;
  readonly roles: string[];
  readonly dimensions: Dimensions;
  readonly statuses: { value: string; label: string }[];
  readonly isUpdatingUser: boolean;
  readonly updateUser: (
    userId: string,
    user: UserResource,
    onSuccessRedirect: (payload: NetworkEventSuccessAction["payload"]) => any
  ) => any;
  readonly t: TFunction;
  readonly isUserSyncEnabled: boolean;
}

interface NewSegmentsInfo {
  dimension: string;
  segment: string;
}

type Props = EditUserProps & FormComponentProps;

export interface EditUserState {
  readonly userToEdit: UserResource;
  readonly isAreYouSureModalOpen: boolean;
  readonly allDimensions: Dimensions;
  readonly newSegmentsInfo: NewSegmentsInfo;
}

class EditUserModal extends React.Component<Props, EditUserState> {

  constructor(props: Props) {
    super(props);
    this.state = {
      userToEdit: props.userToEdit,
      isAreYouSureModalOpen: false,
      allDimensions: this.props.dimensions,
      newSegmentsInfo: {
        dimension: "",
        segment: "",
      },
    };
  }

  onCancelClicked = () => {
    const { form, closeModal } = this.props;
    if (form.isFieldsTouched()) {
      this.setState({ isAreYouSureModalOpen: true });
    } else {
      closeModal();
    }
  };

  validateMobileNumber = (rule: any, value: any, callback: any) => {
    if (value && !MOBILE_REGEX.test(value)) {
      callback("Please enter a valid mobile number.");
    } else {
      callback();
    }
  };

  renderInvalidTerminationDate = (rule: any, value: any, callback: any) => {
    const { hireDate } = this.state.userToEdit;
    if (isNotNullNorUndefined(hireDate) &&
       isNotNullNorUndefined(value) &&
       new Date(hireDate) > new Date( value )) {
      callback("Hiring date can not be greater than termination date.");
    }else {
      callback();
    }
  };

  renderOptions = (): any => {
    const keys: string[] = Object.keys(LANGUAGE_BY_LOCALE);
    return keys.map((x,i) => <Option key={x} value={x}>{LANGUAGE_BY_LOCALE[x]}</Option>);
  };

  onSuccessRedirect = (payload: NetworkEventSuccessAction["payload"]) => {
    const { closeModal } = this.props;
    closeModal();
  };

  closeModal = () => {
    const { closeModal } = this.props;
    this.closeAreYouSureModal();
    closeModal();
  };

  closeAreYouSureModal = () => {
    this.setState({ isAreYouSureModalOpen: false });
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const { form, updateUser } = this.props;
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { userToEdit } = this.state;
        if(isNotNullNorUndefined(values.dimensions.directmanager)) {
          delete values.dimensions.directmanager;
        }
        const updatedUser = { ...userToEdit, ...values, dimensions: values.dimensions };
        if(isString(updatedUser.employeeId) && isEmpty(updatedUser.employeeId)) {
          updatedUser.employeeId = null;
        }
        updateUser(updatedUser._id, updatedUser, this.onSuccessRedirect);
      }
    });
  };

  filterOption = (input: string, option: React.ReactElement<OptionProps>) => {
    const { props } = option;
    if (isNotNullNorUndefined(props)) {
      const { value } = props;
      return (value as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
  };

  onSearch = (label: string) => (value: string) => {
    const { newSegmentsInfo } = this.state;
    newSegmentsInfo.dimension = label;
    newSegmentsInfo.segment = value.trim();
    this.setState({
      newSegmentsInfo,
    });
  };

  renderDimensionsFormItem = () => {
    const { form, isUserSyncEnabled } = this.props;
    const { userToEdit, allDimensions, newSegmentsInfo } = this.state;
    const { getFieldDecorator } = form;
    const dimensionFormItem: any = [];
    for (const [dimensionKey, dimensionObj] of Object.entries(allDimensions)) {
      const notDisabledDimensions =  shouldExcludeDirectManager(dimensionKey);
      const fieldKey = `dimensions.${dimensionKey}`;
      const isEmpty = isNullOrUndefined(userToEdit.dimensions[dimensionKey]) &&
        isNullOrUndefined(form.getFieldValue(fieldKey));

      dimensionFormItem.push(
        <Form.Item key={dimensionKey}>
          {getFieldDecorator(fieldKey, {
            initialValue: userToEdit.dimensions[dimensionKey],
          })(
            <StyledSelect
              label={
              <>
                { dimensionObj.label}
                  {dimensionKey===Direct_MANAGER && 
                    <Tooltip title={<Trans>Auto-populated from the 'Manager' dimension.</Trans>}>
                      <StyledInfoIcon type="info-circle"/>
                    </Tooltip>
                }
              </>
            }
              showSearch
              isEmpty={isEmpty}
              placeholder={<Trans>Search</Trans>}
              optionFilterProp="value"
              optionLabelProp="title"
              // @ts-ignore
              filterOption={this.filterOption}
              onSearch={this.onSearch(dimensionObj.label)}
              disabled={ isUserSyncEnabled || !notDisabledDimensions }
              disabledDimension={notDisabledDimensions}
            >
              {
                dimensionObj.segments.map((segment) => (
                  <Option key={segment}
                    disabled={(userToEdit.email === segment) || 
                      (!isEmailFormatValid(segment) && (dimensionKey === "manager"))} 
                    value={segment} 
                    title={getDimensionSegmentLabel(dimensionKey ,segment)}>
                      {getDimensionSegmentLabel(dimensionKey ,segment)}
                  </Option>
                ))
              }
              {newSegmentsInfo.dimension === dimensionObj.label
              && !dimensionObj.segments.includes(newSegmentsInfo.segment) &&
              <Option key={newSegmentsInfo.segment}
                disabled={(userToEdit.email === newSegmentsInfo.segment) || 
                  (!isEmailFormatValid(newSegmentsInfo.segment) && 
                  (dimensionKey === "manager"))} 
                value={newSegmentsInfo.segment} 
                title={newSegmentsInfo.segment}>
                  Create segment "{newSegmentsInfo.segment}"
              </Option>
              }
            </StyledSelect>
          )}
        </Form.Item>
      );
    }
    return dimensionFormItem;
  };

  render() {
    const {
      visible,
      form,
      statuses,
      roles,
      allowSMSPermission,
      isUpdatingUser,
      t,
      isUserSyncEnabled
    } = this.props;
    const { getFieldDecorator, isFieldsTouched } = form;
    const { userToEdit, isAreYouSureModalOpen } = this.state;

    const { hireDate, endEmploymentDate } = userToEdit;

    const isHypenAdmin = checkIfHyphenAdmin(userToEdit.email);

    const displayHireDate = isNotNullNorUndefined(hireDate) ? moment(hireDate) : null;
    const displayEndEmploymentDate = isNotNullNorUndefined(endEmploymentDate) ? moment(endEmploymentDate) : null;

    const isSaveButtonDisabled = !isFieldsTouched();
    return (
      <StyledModal
        visible={visible}
        title={<Trans>Edit User</Trans>}
        centered
        footer={null}
        onCancel={this.onCancelClicked}
        destroyOnClose={true}
      >
        {/*
				//	@ts-ignore */}
        <StyledForm onSubmit={this.handleSubmit}>
          <ModalContent>
            <Form.Item>
              {getFieldDecorator("email", {
                rules: [{
                  type: "email", message: (<Trans>The input is not valid Email!</Trans>),
                }, {
                  required: true, message: (<Trans>Please input your Email!</Trans>),
                }],
                initialValue: userToEdit.email,
              })(
                <Input
                  label="Email"
                  name="email"
                  disabled
                />
              )}
            </Form.Item>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item>
                  {getFieldDecorator("firstName", {
                    initialValue: userToEdit.firstName,
                    rules: [
                      {
                        required: false, message: (<Trans>First name is required</Trans>),
                      },
                    ],
                  })(
                    <StyledInput
                      label={<Trans>First Name</Trans>}
                      name="firstName"
                      error={null}
                      isEmpty={isNotNullNorUndefined(userToEdit.firstName)
                               && userToEdit.firstName.length < 1}
                      disabled={isUserSyncEnabled}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  {getFieldDecorator("lastName", {
                    initialValue: userToEdit.lastName,
                    rules: [
                      {
                        required: false, message: (<Trans>Last name is required</Trans>),
                      },
                    ],
                  })(
                    <StyledInput
                      label={<Trans>Last Name</Trans>}
                      name="lastName"
                      error={null}
                      isEmpty={isNotNullNorUndefined(userToEdit.lastName)
                        && userToEdit.lastName.length < 1}
                      disabled={isUserSyncEnabled}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item>
                  {getFieldDecorator("status", {
                    rules: [{
                      required: true, message: (<Trans>Status cannot be empty!</Trans>),
                    }],
                    initialValue: userToEdit.status,
                  })(
                    <StyledSelectWithChildren label={(
                      <div>
                        <Trans>Status</Trans>
                      </div>
                    )} disabled={isHypenAdmin || isUserSyncEnabled}>
                      {
                        statuses.map((status) => (
                          <Option key={status.value} value={status.value}>
                            <Trans>{status.label}</Trans></Option>
                        ))
                      }
                    </StyledSelectWithChildren>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  {getFieldDecorator("role", {
                    rules: [{
                      required: true, message: (<Trans>Role cannot be empty!</Trans>),
                    }],
                    initialValue: userToEdit.role,
                  })(
                    <StyledSelectWithChildren label={(
                      <div>
                        <Trans>Role</Trans>
                      </div>
                    )} disabled={isHypenAdmin}>
                      {
                        roles.map((role) => (
                          <Option key={role} value={role}>{role}</Option>
                        ))
                      }
                    </StyledSelectWithChildren>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              {getFieldDecorator("hasInsightsAccess", {
                initialValue: userToEdit.hasInsightsAccess,
                valuePropName: "checked",
              })(
                <Checkbox>
                  <Trans>Has access to Engage Insights</Trans>
                  <Tooltip title={<Trans>Allow user to view data in Engage Insights</Trans>}>
                    <StyledInfoIcon type="info-circle"/>
                  </Tooltip>
                </Checkbox>
              )}
            </Form.Item>
            {
              allowSMSPermission && (
                <Form.Item>
                  {getFieldDecorator("mobile", {
                    rules: [{
                      validator: this.validateMobileNumber,
                    }],
                    initialValue: userToEdit.mobile,
                  })(
                    <Input
                      label={<Trans>Mobile number</Trans>}
                      name="mobile"
                      error={null}
                      disabled={isUserSyncEnabled}
                    />
                  )}
                </Form.Item>
              )
            }
            <Form.Item>
              {getFieldDecorator("locale", {
                initialValue: userToEdit.locale,
              })(
                <Select
                  showSearch
                  optionFilterProp="children"
                  label={<Trans>Locale</Trans>}
                  // @ts-ignore
                  filterOption={this.filterOption}
                >
                  {this.renderOptions()}
                </Select>
              )}
            </Form.Item>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label={<Trans>Hire Date</Trans>} colon={false}>
                  {getFieldDecorator("hireDate", {
                    initialValue: displayHireDate,
                  })(
                    <DatePicker format={dateFormat} placeholder={`${translate(t, "Select date")}`}
                    disabled={isUserSyncEnabled}/>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={<Trans>Termination Date</Trans>} colon={false}>
                  {getFieldDecorator("endEmploymentDate", {
                    initialValue: displayEndEmploymentDate,
                    rules: [{
                      validator: this.renderInvalidTerminationDate,
                    }],
                  })(
                    <DatePicker format={dateFormat} disabled={isHypenAdmin || isUserSyncEnabled}
                    placeholder={`${translate(t, "Select date")}`}/>
                  )}
                </Form.Item>
              </Col>
            </Row>
            {
              this.renderDimensionsFormItem()
            }
          </ModalContent>
          <ActionContainer>
            <StyledButton
              key="okLabel"
              color="blue"
              disabled={isSaveButtonDisabled || isUpdatingUser}
              htmlType="submit"
              translate="yes"
            >
              {isUpdatingUser ? "Saving" : "Save changes"}
            </StyledButton>
          </ActionContainer>
        </StyledForm>
        {
          isAreYouSureModalOpen && (
            <AreYouSureModal
              visible={isAreYouSureModalOpen}
              title="Are you sure?"
              description="If you close this window, your changes will not be saved."
              onOk={this.closeModal}
              onCancel={this.closeAreYouSureModal}
              okLabel="Discard changes"
              cancelLabel="Keep Editing"
            />
          )
        }
      </StyledModal >
    );
  }
}

const ModalContent = styled.div`
  margin-top: 10px;
  padding-left: 32px !important;
  padding-right: 32px !important;
  max-height: calc(100vh - 300px);
  overflow-x: scroll;
`;

const StyledModal = styled(Modal)<ModalProps & {children: React.ReactNode}>`
  width: 510px !important;
`;

const ActionContainer = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-around;
  padding-left: 32px !important;
  padding-right: 32px !important;
`;

const StyledButton = styled(Button)`
  // margin-left: 32px;
  min-width: 150px;
`;

const StyledSelect = styled(Select) <SelectProps & { isEmpty: boolean; disabledDimension: boolean } >`
  .ant-select-selection {
    ${props => props.disabledDimension ? 
        `border: 1px solid ${props.isEmpty ? Palette.darkPink : "#d9d9d9"};
        background-color: ${props.isEmpty ? "#fbebef" : "#fff"}` : 
        "color: rgba(0, 0, 0, 0.25)"
    };
  }
`;

const StyledSelectWithChildren = styled(Select)<SelectProps  & {children: React.ReactNode}>``;

const StyledForm = styled(Form) <FormComponentProps>`
	.ant-form-item {
		margin-bottom: 16px;
	}
	.ant-form-item-label {
		line-height: 25px;
	}
	.ant-form-item-label label {
		color: #213848 !important;
	}
`;

const StyledInput = styled(Input) <InputProps & { isEmpty: boolean } > `
  input {
		border: 1px solid ${props => props.isEmpty ? Palette.darkPink : "#d9d9d9"};
		background-color: ${props => props.isEmpty ? "#fbebef" : "#fff"};
  }
`;

const StyledInfoIcon = styled(Icon)`
  font-size: 16px;
  color: ${Palette.lightGreyBlue};
  margin-left: 8px;
  vertical-align: middle;
`;

// @ts-ignore
export default Form.create<EditUserProps>()(EditUserModal);
