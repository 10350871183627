import Paragraph from "@src/components/core/Typography/Paragraph";
import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";

export default function Annotation() {
  return (
    <AnnotationContainer>
      <Trans>Data points with not enough data are not displayed in the graph to respect anonymity rules.</Trans>
    </AnnotationContainer>
  );
}

const AnnotationContainer = styled(Paragraph)`
  margin-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #999;
`;
