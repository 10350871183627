import React, { useState } from "react";
import styled from "styled-components";
import { Trans } from "react-i18next";
import { Col, Empty, Icon, Row, Tooltip } from "antd";
import { OverviewReportResource } from "@hyphen-lib/domain/resource/survey/report/OverviewReportResource";
import { HighlightedCategory, HighlightedQuestion } from "@hyphen-lib/domain/resource/report/common/Highlights";
import { DistributionResult } from "@hyphen-lib/domain/resource/report/common/DistributionResult";
import { getOr, isNotNullNorUndefined } from "@hyphen-lib/lang/Objects";
import { Optional } from "@hyphen-lib/lang/Optionals";
import { Heading } from "@components/core/Typography";
import { FavorabilityScore } from "@components/core/FavorabilityScore";
import { StackedBarGraph } from "@components/core/StackedBarGraph";
import Palette, { getBarThemeForFavorability, getBarThemeForEnps } from "@src/config/theme/palette";

import { getMatchingOptionLabel } from "@src/utils/Comparisons";
import { CompareWithOption } from "@screens/Insights/components/ViewOptions/components/CompareWith";
import { extractComparison } from "hyphen-lib/dist/business/calculation/benchmark/Benchmarks";
import { ComparisonsOverview } from "@screens/Insights/Survey/components/ComparisonsOverview";
import { InlineAnonymityFiltered } from "@components/core/InlineAnonymityFiltered";
import { adjustElementsAndPercentages } from "@src/utils/Graphs";
import { ScoreType } from "hyphen-lib/dist/domain/resource/report/common/Score";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { QuestionType } from "hyphen-lib/dist/domain/common/QuestionType";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { TrendResource } from "hyphen-lib/dist/domain/resource/survey/report/TrendResource";
import SurveyTrendlines from "./components/SurveyTrendLines";
import Category from "./components/Category";
import Question from "./components/Question";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { FocusArea } from "hyphen-lib/dist/domain/FocusArea";
import { Participation } from "hyphen-lib/dist/domain/common/Participation";
import FavorabilityOreNPSInfoModal from "@src/components/core/InfoModal";
import { calculateNpsDistribution, generateDistributionText } from "@src/utils/eNpsAndFavDistributionUtils";
import { HeaderContainer } from "@src/screens/Insights/Dashboard/components/DashboardView/components/TotalFavorability";
import { trans } from "@src/utils/i18next";

interface Props {
  readonly data: OverviewReportResource;
  readonly areComparisonsVisible?: boolean;
  readonly compareWithOptions: CompareWithOption[];
  readonly comparisonKey: Optional<string>;
  readonly sectionName: string;
  readonly hasActionCreationRight: boolean;
  readonly anonymityThreshold: number;
  readonly surveyId: string;
  readonly surveyTrend: Loadable<TrendResource>;
  readonly focusAreas?: FocusAreaResource[];
  readonly companyName: string;
  readonly participation: Participation;
  readonly onCreateFocusArea: (
    focusArea: Partial<FocusAreaResource>
  ) => void;
  readonly surveyName: string;
}

function Overview({
  data,
  areComparisonsVisible = true,
  comparisonKey,
  compareWithOptions,
  sectionName,
  hasActionCreationRight,
  anonymityThreshold,
  surveyId,
  surveyTrend: loadableSurveyTrend,
  onCreateFocusArea,
  focusAreas,
  surveyName,
  companyName,
  participation
}: Props) {

  const [visibleEnpsModal, isVisibleEnpsModal] = useState(false);
  const [visibleFavorabilityModal, isVisibleFavorabilityModal] = useState(false);

  const renderDistributionGraph = (
    questionType: QuestionType.ENPS | QuestionType.LIKERT,
    choiceLabels: Optional<string>[] = [],
    distribution: DistributionResult,
    numberOfVotes:  number
  ) => {
    const { pieceToAdd } = adjustElementsAndPercentages(distribution);

    let barGraphTheme = getBarThemeForFavorability(choiceLabels.length, choiceLabels);
    let labels = choiceLabels;
    let choicesData = distribution;
    if (questionType === QuestionType.ENPS) {
      barGraphTheme = getBarThemeForEnps();
      labels = choiceLabels.slice().reverse();
      choicesData = distribution.slice().reverse();
    }
    const graphData = choicesData.map(({ total, percentage }, index) => {
      const { backgroundColor, fontColor, label } = barGraphTheme[index];
      const labelText = getOr(labels[index], label);

      return ({
        value: total,
        percentage: getOr(percentage, 0),
        percentageWidth: isNotNullNorUndefined(percentage) && percentage > 0 ?
          percentage + pieceToAdd :
          0,
        backgroundColor,
        fontColor,
        label: labelText,
      });
    });

    return <StackedBarGraph data={graphData} width="100%" height="48px" numberOfVotes={numberOfVotes}/>;
  };

  const comparisonLabel = getMatchingOptionLabel(compareWithOptions, comparisonKey);
  const averageComparisonLabel = getMatchingOptionLabel(compareWithOptions, "average");

  const NPSHeader = () => {
    const isFilteredForAnonymity = !data.eNps.filteredForAnonymity;
    return  <HeaderContainer width="53px">
      <Heading size="small" weight={"bold"}><Trans>eNPS</Trans>  |</Heading>
    <HeadingSubTitle marginLeft="3px">
      <Trans i18nKey="enpsSubTitle">
        Employee net promoter score (eNPS) is a measure of 
        employee loyalty, calculated from responses to specific 
        eNPS questions.
      </Trans>
      { isFilteredForAnonymity &&
        <HeadingSubTitleUnderline onClick={() => isVisibleEnpsModal(true)} data-cy='calFav'> 
          <Trans>How is this calculated ?</Trans>
        </HeadingSubTitleUnderline>
      }
    </HeadingSubTitle>
  </HeaderContainer>;
  };

  const favorabilityHeader = () => {
    const isFilteredForAnonymity = !data.likert.filteredForAnonymity;
    return <HeaderContainer width="100px">
      <Heading size="small" weight={"bold"} translate="yes">
        <Trans>Favorability</Trans> | </Heading>
        <HeadingSubTitle marginLeft="3px">
          <Trans>
          Favorability is a measure of employee satisfaction across different 
          workplace factors, based on all Likert-scale questions in this survey.
          </Trans>
          {
              isFilteredForAnonymity && 
              <HeadingSubTitleUnderline onClick={() => isVisibleFavorabilityModal(true)} data-jest='calFav'> 
                <Trans>How is this calculated ?</Trans>
              </HeadingSubTitleUnderline>
          }
      </HeadingSubTitle>
    </HeaderContainer>;
  };
 
  const showFavorabilityInfoModal = (likert: OverviewReportResource.Summary) => {
    if(!likert.filteredForAnonymity) {
      const {  
        numberOfVotes,
        numberOfQuestions,
        choiceLabels,
        distribution,
        score
      } = likert;
      const { distributionsText, noteDistributionsText } = generateDistributionText(
        choiceLabels,
        distribution,
        numberOfVotes,
      );
      const questionText = numberOfQuestions > 1 ? "questions": "question";
      const verbBasedOnVoteCount = numberOfVotes > 1 ? "are" : "is";
      const responseText = numberOfVotes > 1 ? "responses" : "response";
      const firstText = numberOfVotes > 1 ? trans("Among") : trans("For");
      const  description = 
        <Trans i18nKey="surveyeFavorabilityInfo" 
          values={{ firstText, questionText, numberOfQuestions, numberOfVotes, distributionsText, score }} 
          defaults = {`${firstText} the ${numberOfVotes} ${responseText} to the ${numberOfQuestions}
          Likert-scale ${questionText} that ${verbBasedOnVoteCount} eligible for favorability calculation, 
          ${distributionsText} which results in a favorability score of ${score}%.`} 
      />;
      const note = <Trans i18nKey="overallFavorabilityNote" values = {{noteDistributionsText}} 
      defaults = {`The percentages indicating the votes for ${noteDistributionsText} are 
      rounded to the nearest whole number for clarity. However, our favorability calculation uses 
      precise decimal values to ensure accuracy. This may result in a difference of up to 1 percentage
      point between the percentage of votes and the calculated favorability score.
      `}
    />;

      return visibleFavorabilityModal && (
        <FavorabilityOreNPSInfoModal
          visible={visibleFavorabilityModal}
          title="Your favorability score"
          description={description}
          note={note}
          onClose={() => isVisibleFavorabilityModal(false)}
        />
      );
    }
  };

  const showEnpsInfoModal = (eNps: OverviewReportResource.Summary) => {
    if(!eNps.filteredForAnonymity) {
      const { completed } = participation;
      const { promoters, detractors } = calculateNpsDistribution(eNps.distribution);
      const questionText = eNps.numberOfQuestions > 1 ? "questions": "question";
      const  description = <p> 
        <Trans i18nKey="surveyeNpsCalInfo" values={{questionText, companyName}} defaults = {`Based on your employees' 
          responses to the eNPS ${questionText} such as "I would recommend ${companyName} as a great place to work" 
          (on a 1-10 scale), they are categorized into promoters (scores 9-10),
          passives (scores 7-8) and detractors (scores 0-6). eNPS is the difference between the percentage of 
          promoters and detractors.`} 
        />
        <br/>
        {
          completed > 1 ? 
            <Trans i18nKey="surveyeNpsInfoQuestions" 
              values={{ numberOfVotes: completed, score:(data.eNps as any).score,detractors, promoters }} 
              defaults = {` Among total responses for all the eNPS questions in this survey 
              (${completed}), ${promoters}% are promoters and ${detractors}% are detractors, 
              which indicates an eNPS score of ${(data.eNps as any).score}.`}
            /> : 
            <Trans i18nKey="surveyeNpsInfo" 
              values={{ completed, score:(data.eNps as any).score, detractors, promoters }} 
              defaults = {`For employees who have taken this survey (${completed}),
              ${promoters}% are promoters and ${detractors}% are
               detractors, which results in an eNPS score of ${(data.eNps as any).score}.
              `}
            />
        }
      </p>;
      const note = <Trans i18nKey="surveyeNpsInfoNote" 
      defaults = {`The percentages indicating the promoters and detractors in the histogram 
        are rounded to the nearest whole number for clarity. However, our eNPS calculation uses 
        precise decimal values to ensure accuracy. This may result in a difference of up to 1 
        point between the percentage of promoters/ detractors and the calculated eNPS score.
      `}
    />; 

      return visibleEnpsModal && (
        <FavorabilityOreNPSInfoModal
          visible={visibleEnpsModal}
          title="Your eNPS score"
          description={description}
          note={note}
          onClose={() => isVisibleEnpsModal(false)}
        />
      );
    }
  };
  function renderENps(eNps: OverviewReportResource.Summary) {
    if (eNps.filteredForAnonymity) {
      return (
        <SectionContainer>
          {NPSHeader()}
          <Summary>{eNps.numberOfQuestions} 
            <Trans>{eNps.numberOfQuestions === 1 ? "question" : "questions"}</Trans>
          </Summary>
          <InlineAnonymityFiltered
            explanation={eNps.explanation}
            anonymityThreshold={anonymityThreshold}
            size={"big"}
          />
        </SectionContainer>
      );
    }

    const eNpsComparison = extractComparison(eNps.compare, comparisonKey);
    const eNpsAverageComparison = extractComparison(eNps.compare, "average");
    return (
      <>
        {eNps.numberOfVotes > 0 && (
          <SectionContainer>
            {NPSHeader()}
            {/* tslint:disable:max-line-length */}
            <Summary data-cy="surveyReport_numberOfQuestionsAndVoteForeNPS">
              {eNps.numberOfQuestions} {" "}
              <Trans>{eNps.numberOfQuestions === 1 ? "question" : "questions"}</Trans> •{" "}
              {eNps.numberOfVotes}{" "}
              <Trans>{eNps.numberOfVotes === 1 ? "answer" : "answers"}</Trans>
            </Summary>
            <FavorabilityContainer>
              <FavorabilityScoreContainer>        
                <FavorabilityScore
                  data-cy="surveyReport_enpsScore"
                  favorability={eNps.score}
                  scoreType={ScoreType.NPS}
                  percentage={false} />
              </FavorabilityScoreContainer>
              <ComparisonsOverview
                areComparisonsVisible={areComparisonsVisible}
                comparisonLabel={comparisonLabel}
                comparisonValue={eNpsComparison}
                averageComparisonLabel={averageComparisonLabel}
                averageComparisonValue={eNpsAverageComparison}
                showSecondComparison={comparisonKey !== "average"}
                data-cy={"compareFor_eNPS"}
                percentage={false}
              />
              <FavorabilityDistributionGraphContainer>
                {renderDistributionGraph(
                  QuestionType.ENPS,
                  eNps.choiceLabels,
                  eNps.distribution,
                  eNps.numberOfVotes
                )}
              </FavorabilityDistributionGraphContainer>
            </FavorabilityContainer>
            {showEnpsInfoModal(eNps)}
          </SectionContainer>
        )}
      </>
    );
  }

  function renderLikert(likert: OverviewReportResource.Summary) {
    if (likert.filteredForAnonymity) {
      return (
        <SectionContainer marginTop={40}>
          {favorabilityHeader()}
          <Summary>{likert.numberOfQuestions} 
          <Trans>{likert.numberOfQuestions === 1 ? "question" : "questions"}</Trans>
          </Summary>
          <InlineAnonymityFiltered
            explanation={likert.explanation}
            anonymityThreshold={anonymityThreshold}
            size={"big"}
          />
        </SectionContainer>
      );
    }

    if (likert.numberOfQuestions === 0) {
      return null;
    }

    const likertComparison = extractComparison(likert.compare, comparisonKey);
    const likertAverageComparison = extractComparison(likert.compare, "average");

    return (
      <SectionContainer marginTop={40}>
        {favorabilityHeader()}
        <Summary data-cy="surveyReport_numberOfQuestionsAndVoteForFavorability">
          {likert.numberOfQuestions}{" "}
          <Trans>{likert.numberOfQuestions === 1 ? "question" : "questions"}</Trans> •{" "}
          {likert.numberOfVotes}{" "}
          <Trans>{likert.numberOfVotes === 1 ? "answer" : "answers"}</Trans>{" "}
        </Summary>
        <FavorabilityContainer>
          <FavorabilityScoreContainer>
            {/* eslint-disable max-len */}
            <FavorabilityScore data-cy="surveyReports_favourabilityScore" favorability={likert.score} scoreType={ScoreType.FAVORABILITY} />
            {/* eslint-disable max-len */}
          </FavorabilityScoreContainer>
          <ComparisonsOverview
            areComparisonsVisible={areComparisonsVisible}
            comparisonLabel={comparisonLabel}
            comparisonValue={likertComparison}
            averageComparisonLabel={averageComparisonLabel}
            averageComparisonValue={likertAverageComparison}
            showSecondComparison={comparisonKey !== "average"}
            data-cy={"compareFor_favourability"}
          />
          <FavorabilityDistributionGraphContainer>
            {renderDistributionGraph(QuestionType.LIKERT, likert.choiceLabels, likert.distribution, likert.numberOfVotes)}
          </FavorabilityDistributionGraphContainer>
        </FavorabilityContainer>
        {showFavorabilityInfoModal(likert)}
      </SectionContainer>
    );
  }

  function renderSections(eNps: OverviewReportResource.Summary,
    likert: OverviewReportResource.Summary,
    bestThreeCategories: HighlightedCategory[],
    worstThreeCategories: HighlightedCategory[],
    bestThreeQuestions: HighlightedQuestion[],
    worstThreeQuestions: HighlightedQuestion[],
    sectionName: string) {
    const comparisonLabel = getMatchingOptionLabel(compareWithOptions, comparisonKey);
    const hasComparision = isNotNullNorUndefined(comparisonLabel) && areComparisonsVisible;
    const hasBest = isNotEmptyArray(bestThreeCategories) || isNotEmptyArray(bestThreeQuestions);
    const hasWorst = isNotEmptyArray(worstThreeCategories) || isNotEmptyArray(worstThreeQuestions);
    const hasBest3questions = isNotEmptyArray(bestThreeQuestions);
    const hasworst3questions = isNotEmptyArray(worstThreeQuestions);
    const hasBest3categories = isNotEmptyArray(bestThreeCategories);
    const hasWorst3categories = isNotEmptyArray(worstThreeCategories);
    return (
      <>
        {renderENps(eNps)}
        {renderLikert(likert)}

        {
          Loadable.isLoaded(loadableSurveyTrend) &&
          isNotNullNorUndefined(loadableSurveyTrend.value) &&
          (
            <SectionContainer marginTop={56}>
              <SurveyTrendlines
                currentSurveyId={surveyId}
                trend={loadableSurveyTrend.value.trends as TrendResource.SurveyTrends[]}
              />
            </SectionContainer>
          )
        }

        <SectionContainer marginTop={56}>
          <CategoriesContainer>
            <AreaSectionHeading>
              <Heading size="small" weight="bold" translate="yes">
                Satisfaction Areas
              </Heading>
            </AreaSectionHeading>
            {hasBest ? 
            
            <Row gutter={10}>
                <Col sm={12}>
                {
                  <>
                    <SubHeader>
                      <SubHeaderTitle>
                        <Trans>Top categories</Trans>
                        <Trans>{TopCategoryIconTooltip}</Trans>
                      </SubHeaderTitle>
                      {hasBest3categories && hasComparision && <ComparisionLabel>
                        <Trans>vs {comparisonLabel}</Trans></ComparisionLabel>}
                    </SubHeader>
                    {hasBest3categories ? (<>
                      {
                        bestThreeCategories.map((category: HighlightedCategory) => (
                          <Category
                            data-jest="bestThreeCategory"
                            showOnHover={true}
                            key={category.category}
                            data={category}
                            isComparisonVisible={areComparisonsVisible}
                            compareWithOptions={compareWithOptions}
                            comparisonKey={comparisonKey}
                            seeDetailsVisible={true}
                            fromPage={sectionName}
                            hasActionCreationRight={hasActionCreationRight}
                            focusAreas={focusAreas}
                            onCreateFocusArea={onCreateFocusArea}
                            categoryType={FocusArea.Source.BEST_CATEGORIES}
                            focusAreaLabel={surveyName}
                          />
                        ))
                      }
                    </>)
                      : <Empty description={<Trans>No data to show</Trans>} />}
                  </>
                }
                </Col>
                <Col sm={12}>
                {
                  <>
                    <SubHeader>
                      <SubHeaderTitle>
                        <Trans>Top questions</Trans>
                        <Trans>{TopQuestionIconTooltip}</Trans>
                      </SubHeaderTitle>
                      {hasBest3questions && hasComparision && <ComparisionLabel>
                        <Trans>vs {comparisonLabel}</Trans></ComparisionLabel>}
                    </SubHeader>
                    {hasBest3questions ? (<>
                      {bestThreeQuestions.map(question => (
                        <Question
                          isComparisionVisible={areComparisonsVisible}
                          compareWithOptions={compareWithOptions}
                          comparisonKey={comparisonKey}
                          data-jest="bestThreeQuestion"
                          showOnHover={true}
                          key={question._id}
                          question={question}
                          tableName={sectionName}
                          hasActionCreationRight={hasActionCreationRight}
                          focusAreas={focusAreas}
                          onCreateFocusArea={onCreateFocusArea}
                          questionSource={FocusArea.Source.TOP_QUESTION}
                          surveyName={surveyName}
                        />
                      ))
                      }
                    </>)
                      : <Empty description={<Trans>No data to show</Trans>} />
                    }
                  </>
                }
              </Col>
            </Row> : <Empty description={<Trans>No data to show</Trans>} />}
            <AreaSectionHeading>
              <Heading size="small" weight="bold">
                <Trans>Opportunity Areas</Trans>
              </Heading>
            </AreaSectionHeading>
            {hasWorst ? 
            <Row gutter={10}>
                <Col sm={12}>
                {
                  <>
                    <SubHeader>
                      <SubHeaderTitle>
                        <Trans>Bottom categories</Trans>
                        <Trans>{BottomCategoryIconTooltip}</Trans>
                      </SubHeaderTitle>
                      {hasWorst3categories && hasComparision && <ComparisionLabel>
                        <Trans>vs {comparisonLabel}</Trans></ComparisionLabel>}
                    </SubHeader>
                    {hasWorst3categories ? <>
                      {
                        worstThreeCategories.map((category: HighlightedCategory) => (
                          <Category
                            data-jest="worstThreeCategory"
                            key={category.category}
                            data={category}
                            isComparisonVisible={areComparisonsVisible}
                            compareWithOptions={compareWithOptions}
                            comparisonKey={comparisonKey}
                            seeDetailsVisible={true}
                            fromPage={sectionName}
                            hasActionCreationRight={hasActionCreationRight}
                            onCreateFocusArea={onCreateFocusArea}
                            focusAreas={focusAreas}
                            categoryType={FocusArea.Source.WORST_CATEGORIES}
                            focusAreaLabel={surveyName}
                          />
                        ))
                      }
                    </> : <Empty description={<Trans>No data to show</Trans>} />}
                  </>
                }
                </Col>
                <Col sm={12}>
              {
                <>
                  <SubHeader>
                    <SubHeaderTitle>
                      <Trans>Bottom questions</Trans>
                      <Trans>{BottomQuestionIconTooltip}</Trans>
                    </SubHeaderTitle>
                    {hasworst3questions && hasComparision && <ComparisionLabel>
                      <Trans>vs {comparisonLabel}</Trans></ComparisionLabel>}
                  </SubHeader>
                  {hasworst3questions ? <>
                    {
                      worstThreeQuestions.map(question => (
                        <Question
                          isComparisionVisible={areComparisonsVisible}
                          compareWithOptions={compareWithOptions}
                          comparisonKey={comparisonKey}
                          data-jest="worstThreeQuestion"
                          postionIconInsideSection={false}
                          key={question._id}
                          question={question}
                          tableName={sectionName}
                          hasActionCreationRight={hasActionCreationRight}
                          focusAreas={focusAreas}
                          onCreateFocusArea={onCreateFocusArea}
                          questionSource={FocusArea.Source.BOTTOM_QUESTION}
                          surveyName={surveyName}
                        />
                      ))
                    }
                  </>
                    : <Empty description={<Trans>No data to show</Trans>} />
                  }
                </>
              }
              </Col>
            </Row> : <Empty description={<Trans>No data to show</Trans>} />}
          </CategoriesContainer>
        </SectionContainer>
      </>
    );
  }

  const noVotes = data.eNps.filteredForAnonymity === false &&
    data.likert.filteredForAnonymity === false &&
    data.eNps.numberOfVotes === 0 &&
    data.likert.numberOfVotes === 0;

  return (
    <Container>
      {
        noVotes ?
          (
            <EmptyContainer>
              <Empty description={<Trans>Not enough data</Trans>} data-cy="overviewSection_noData" />
            </EmptyContainer>
          )
          :
          renderSections(
            data.eNps,
            data.likert,
            data.bestThreeCategories,
            data.worstThreeCategories,
            data.bestThreeQuestions,
            data.worstThreeQuestions,
            sectionName,
          )
      }

    </Container>
  );
}

const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const SubHeader = styled.div`
  display: flex;
  max-width: 95%;
  margin-left: 16px;
  margin: 16px 16px 0;
  justify-content: space-between;
  height: 16px;
`;

const SubHeaderTitle = styled.p`
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 16px;
`;

const ComparisionLabel = styled.p`
  height: 20px;
  white-space: nowrap;
  color: #7A8891;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
`;

const Container = styled.div`
  padding: 32px;
  background: ${Palette.white};
  font-family: Lato, sans-serif;
  color: ${Palette.veryDarkBlueGrey};
  @media print {
    padding-top: 100px;
  }
`;

export const StyledInfoIcon = styled(Icon)`
  font-size: 16px;
  color: ${Palette.lightGreyBlue};
  margin-left: 8px;

  @media print {
    display: none;
    visibility: hidden;
  }
`;

export const NPSIcon = (
  <Heading size="small" weight={"bold"}>
    <Trans>eNPS</Trans>
    <Tooltip
      title={<Trans>Calculated as the difference between Promoters and Detractors</Trans>}
    >
      <StyledInfoIcon className="block--print-hide" type="info-circle" data-cy="report_eNPSIconTooltip" />
    </Tooltip>
  </Heading>
);

export const HeadingSubTitle = styled.span<{marginLeft?: string}>`
  margin-top: ${props => getOr(props.marginLeft, "0px")};
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  color: ${Palette.black};
`;

export const HeadingSubTitleUnderline = styled.span`
  text-decoration: underline;  
  padding-left: 3px; 
  cursor: pointer;
`;

export const FavorabilityIconTooltip = (
  <Tooltip title={<Trans>Percentage of the favorable votes out of all votes</Trans>}

  >
    <StyledInfoIcon className="block--print-hide" type="info-circle" data-cy="toolTip_TF" />

  </Tooltip>
);

export const TopCategoryIconTooltip = (
  <Tooltip title={<Trans>Categories with the highest favorability scores</Trans>}

  >
    <StyledInfoIcon className="block--print-hide" type="info-circle" data-cy="toolTip_TC" />

  </Tooltip>
);

export const BottomCategoryIconTooltip = (
  <Tooltip title={<Trans>Categories with the lowest favorability scores</Trans>}

  >
    <StyledInfoIcon className="block--print-hide" type="info-circle" data-cy="toolTip_BC" />

  </Tooltip>
);

export const TopQuestionIconTooltip = (
  <Tooltip title={<Trans>Questions with the highest favorability scores</Trans>}

  >
    <StyledInfoIcon className="block--print-hide" type="info-circle" data-cy="toolTip_TQ" />

  </Tooltip>
);

export const BottomQuestionIconTooltip = (
  <Tooltip title={<Trans>Questions with the lowest favorability scores</Trans>}

  >
    <StyledInfoIcon className="block--print-hide" type="info-circle" data-cy="toolTip_BQ" />

  </Tooltip>
);


interface SummaryProps {
  centered?: boolean;
}
export const Summary = styled.div<SummaryProps>`
  margin-top: 8px;
  color: ${Palette.bluishGrey};
  ${props => props.centered && "text-align: center"}
`;

const SectionContainer = styled.div<{ marginTop?: number }>`
  margin-top: ${props => getOr(props.marginTop, 32)}px;
`;

const FavorabilityContainer = styled.div`
  margin-top: 24px;
  display: flex;
`;

const FavorabilityScoreContainer = styled.div`
  display: inline-block;
  width: 100px;
  span {
    font-size: 56px !important;
    line-height: 52px;
  }
`;

const FavorabilityDistributionGraphContainer = styled.div`
  margin-left: 48px;
  flex-grow: 1;
  align-self: center;
`;

const CategoriesContainer = styled.div`
  margin: 24px -16px 0;
  display: flex;
  flex-direction: column;
`;
const AreaSectionHeading = styled.div`
  margin: 0 16px;
  padding-top: 20px;
`;

export default Overview;