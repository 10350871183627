import { Company } from "hyphen-lib/dist/domain/Company";
import { Filter } from "@src/components/core/FilterLabels/types";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";

export function isOnlySurveyModuleEnabled(companyModules: Company.Modules) {
  // check if company has only surveys enabled
  return (companyModules.surveys && !companyModules.pulsePolls && !companyModules.employeeVoice);
}

export function isOnlyEmployeeVoiceModuleEnabled(companyModules: Company.Modules) {
  // check if company has only surveys enabled
  return (!companyModules.surveys && !companyModules.pulsePolls && companyModules.employeeVoice);
}

/**
 * Check if the applied module-filters contain only the specified filter
 *
 * @param appliedFilters 
 * @param filterKey 
 */
export function hasOnlyAppliedFilter(appliedFilters: Filter[], filterKey: string) {
  if (isNotEmptyArray(appliedFilters)) {
    for (const filterData of appliedFilters) {
      if (filterData.key === "modules") {
        const filters = filterData.filters;
        return filters.length === 1 && filters[0].key === filterKey;
      }
    }
  } else {
    return false;
  }
}

/**
 * Check whether to show a module or not based on the applied filter.
 *
 * @param appliedFilters 
 * @param filterKey 
 */
export function shouldShowModuleCount(appliedFilters: Filter[], filterKey: string) {
  if (isNotEmptyArray(appliedFilters)) {
    for (const filterData of appliedFilters) {
      if (filterData.key === "modules") {
        const filters = filterData.filters;
        for (const filter of filters) {
          if (filter.key === filterKey) {
            return true;
          }
        }
        return false;
      }
    }
    return true;
  } else {
    return true;
  }
}

/**
 * Check whether to show a section based on the applied filter.
 *
 * @param appliedFilters
 * @param filterKey 
 */
 export function shouldShowSectionBasedonFilter(appliedFilters: Filter[], filterKey: string) {
  if (isNotEmptyArray(appliedFilters)) {
    for (const filterData of appliedFilters) {
      if (filterData.key === filterKey) {
        return false;
      }
    }
    return true;
  } else {
    return true;
  }
}
