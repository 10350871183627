import React from "react";
import { AccessResource, AccessSource } from "hyphen-lib/dist/domain/access/AccessResource";
import ContainerCard from "@components/core/ContainerCard";
import Checkbox from "@components/core/Checkbox";
import Select from "@components/core/Select";
import SelectLarge from "@components/core/SelectLarge";
import Button from "@components/core/Button";
import styled from "styled-components";
import { Col, Icon, Row, Table } from "antd";
import Palette from "@src/config/theme/palette";
import { AccessList } from "hyphen-lib/dist/domain/access/AccessList";
import { Map as ImmutableMap, Seq } from "immutable";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { freeze, isNullOrUndefined, isNotNullNorUndefined, mapOr, values } from "hyphen-lib/dist/lang/Objects";
import Tooltip from "@components/core/Tooltip";
import { SelectProps, SelectValue } from "antd/lib/select";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { onlyField } from "hyphen-lib/dist/lang/Functions";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { checkIfHyphenAdmin } from "hyphen-lib/dist/business/user/Users";
import { Trans } from "react-i18next";

const { Option } = Select;
const { Column } = Table;

const AVAILABLE_ACCESS_CHANGES = ImmutableMap({
  [AccessList.UserAccessRole.ADMIN]: freeze([
    AccessList.UserAccessRole.ADMIN,
    AccessList.UserAccessRole.BLOCKED,
  ]),
  [AccessList.UserAccessRole.CONVERSATION]: freeze([
    AccessList.UserAccessRole.ADMIN,
    AccessList.UserAccessRole.CONVERSATION,
    AccessList.UserAccessRole.BLOCKED,
  ]),
  [AccessList.UserAccessRole.READ]: freeze([
    AccessList.UserAccessRole.ADMIN,
    AccessList.UserAccessRole.CONVERSATION,
    AccessList.UserAccessRole.READ,
    AccessList.UserAccessRole.BLOCKED,
  ]),
  [AccessList.UserAccessRole.BLOCKED]: freeze([
    AccessList.UserAccessRole.ADMIN,
    AccessList.UserAccessRole.CONVERSATION,
    AccessList.UserAccessRole.READ,
    AccessList.UserAccessRole.BLOCKED,
  ]),
});

const DEFAULT_ALL_ACCESSES = freeze(values(AccessList.UserAccessRole).toArray());

const ROLE_OPTIONS = freeze({
  admin: <Option key="admin" value="admin"><Trans>All Access</Trans></Option>,
  conversation: <Option key="conversation" value="conversation"><Trans>View + Messaging</Trans></Option>,
  read: <Option key="read" value="read"><Trans>View</Trans></Option>,
  blocked: <Option key="blocked" value="blocked"><Trans>No Access</Trans></Option>,
});

export interface AccessProps {
  readonly title: string;
  readonly AccessIntroduction: React.ReactNode;
  readonly areManagersSynchronized: boolean;
  readonly eligibleManagersCount: Loadable<number>;
  readonly allowedEmails: string[];
  readonly accesses: AccessResource[];
  readonly onToggleManagersSynchronization: (areManagersSynchronized: boolean) => void;
  readonly onAddNewAccesses: (emails: string[]) => void;
  readonly onUpdateAccess: (id: string, role: AccessList.UserAccessRole) => void;
  readonly showInsightsAccessModal: () => void;
  readonly onRemoveAccess: (id: string) => void;
}

interface AccessState {
  readonly selectedEmails: string[];
  readonly hoverIndex: Optional<number>;
}

class Access extends React.Component<AccessProps, AccessState> {
  constructor(props: AccessProps) {
    super(props);
    this.state = {
      selectedEmails: [],
      hoverIndex: Optional.empty(),
    };
  }

  componentDidUpdate(prevProps: AccessProps): void {
    const {showInsightsAccessModal,  areManagersSynchronized} = this.props;
    if(areManagersSynchronized !== prevProps.areManagersSynchronized && areManagersSynchronized) {
        showInsightsAccessModal();
    }
  };

  handleToggleManagersSynchronization = (evt: CheckboxChangeEvent) => {
    const { onToggleManagersSynchronization } = this.props;
    onToggleManagersSynchronization(evt.target.checked);
  };

  handleSelectedEmails = (selectedEmails: any) => {
    // Save the selected emails in state, before eventually the add button will be clicked
    this.setState({
      selectedEmails,
    });
  };

  handleAddAccesses = () => {
    const {
      onAddNewAccesses,
    } = this.props;
    const {
      selectedEmails,
    } = this.state;

    if (isNotEmptyArray(selectedEmails)) {
      onAddNewAccesses(selectedEmails);

      // clean the drop down
      this.setState({
        selectedEmails: [],
      });
    }
  };

  removeFromEmployeeAccessList = (e: any, record: any) => {
    if (isNotNullNorUndefined(record)) {
      this.props.onRemoveAccess(record._id);
    }
  };

  getAvailableAccessChanges = (globalAccess?: AccessList.UserAccessRole): AccessList.UserAccessRole[] => {
    return mapOr(
      globalAccess,
      ga => AVAILABLE_ACCESS_CHANGES.get(ga, DEFAULT_ALL_ACCESSES),
      DEFAULT_ALL_ACCESSES
    );
  };

  getAvailableRoleOptions = (accessRoles: AccessList.UserAccessRole[]) => {
    return accessRoles.map(access => ROLE_OPTIONS[access]);
  };

  handleOnRow = (__: any, rowIndex: number) => {
    return {
      onMouseEnter: () => {
        this.setState({ hoverIndex: rowIndex });
      },
      onMouseLeave: () => {
        this.setState({ hoverIndex: null });
      },
    };
  };

  createChangeAccessHandler = (record: AccessResource) => (value: SelectValue) => {
    const {
      onUpdateAccess,
    } = this.props;

    onUpdateAccess(record.email, value as AccessList.UserAccessRole);
  };

  createRemoveAccessHandler = (record: AccessResource) => () => {
    const {
      onRemoveAccess,
    } = this.props;

    onRemoveAccess(record.email);
  };

  renderAccessSource = (accessSource: AccessSource, record: AccessResource): string => {
    switch (accessSource) {
      case AccessSource.AUTO:
        return "Auto-defined";
      case AccessSource.MANUAL:
        return "Manual";
      default:
        return `Role (${record.userRole})`;
    }
  };

  isOptionDisabled = (value: any) => {
    const { accesses } = this.props;
    const existingAccessListEmails = Seq(accesses).map(onlyField("email")).toSet();
    return existingAccessListEmails.has(value);
  };

  getAccessTableRowClass = (record: unknown): string => {
    return (record as AccessResource).role === AccessList.UserAccessRole.BLOCKED ? "blocked" : "";
  };

  isSurveyHyphenAdmin = (email: string): boolean => {
    const { title } = this.props;
    return checkIfHyphenAdmin(email) && title.indexOf("Survey Access") > -1 ? true : false;
  };

  render() {
    const {
      title,
      AccessIntroduction,
      accesses,
      areManagersSynchronized,
      allowedEmails,
      eligibleManagersCount: loadableEligibleManagersCount,
    } = this.props;

    const {
      selectedEmails,
      hoverIndex,
    } = this.state;

    const countLabel = Loadable.mapOr(loadableEligibleManagersCount, c => c.toString(), "...");

    return (
      <>
        <ContainerCard title={title}>
          {AccessIntroduction}
          <Checkbox
            checked={areManagersSynchronized}
            info={"Check here to give access to all people managers to view reports for their team members"}
            onChange={this.handleToggleManagersSynchronization}
            data-cy="Creation_access_viewAccess"
          >
            <Trans>Give 'View' access automatically to all auto-defined managers as well?</Trans>
            ({countLabel} <Trans>people</Trans>)
          </Checkbox>
          <br />
          <Row type="flex">
            <Trans>Select from the dropdown or paste a list of comma-separated emails
            to add to the recipient list.</Trans>
            <Col span={22}>
              <SelectLarge
                mode="multiple"
                size="large"
                onChange={this.handleSelectedEmails}
                selectedValues={selectedEmails}
                data={allowedEmails}
                placeholder={<Trans>Start typing to search</Trans>}
                numberOfValuesToShow={100}
                allowSelectionByPaste={true}
                dataCy="Creation_access_recipientEmail"
                isOptionDisabled={this.isOptionDisabled}
              />
            </Col>
            <Col span={2}>
              <Button
                data-cy="Creation_access_add"
                onClick={this.handleAddAccesses}
                translate="yes"
              >
                Add
              </Button>
            </Col>

          </Row>
          <EmployeeAccessTable
            dataSource={accesses}
            rowKey={"email"}
            pagination={{ defaultPageSize: 10 }}
            onRow={this.handleOnRow}
            rowClassName={this.getAccessTableRowClass}
            data-jest="employee-access-table"
          >
            <Column
              title={<span>{accesses.length} <Trans>employees with access</Trans></span>}
              dataIndex="email"
              key="_id"
            />
            <Column
              title={<Trans>Source</Trans>}
              dataIndex="accessSource"
              key="accessSource"
              render={this.renderAccessSource}
            />
            <Column
              title={(
                <div>
                  <Trans>Type of Access</Trans>
                  <Tooltip
                    title={<Trans>Specifies whether the user is defined by data or by adding manually</Trans>}
                  >
                    <HelpIcon type="info-circle" />
                  </Tooltip>
                </div>
              )}
              dataIndex="role"
              key="role"
              // tslint:disable-next-line:jsx-no-lambda
              render={(role: any, record: AccessResource, index: number) => {
                const availableAccessChanges = this.getAvailableAccessChanges(record.globalAccess);
                const availableRoleOptions = this.getAvailableRoleOptions(availableAccessChanges);
                const { email } = record;
                return this.isSurveyHyphenAdmin(email) ?
                  (<div data-jest={email}>
                    <Trans>All Access</Trans>
                    <Tooltip
                      title={<><Trans>You cannot revoke survey access for</Trans> 
                      {email} <Trans>This is a system superuser</Trans></>}
                    >
                      <HelpIcon type="info-circle" />
                    </Tooltip>
                  </div>)
                  : (
                    <StyledSelect
                      data-cy={"Creation_access_typeOfAccess_" + index.toString()}
                      defaultValue={role}
                      key={index}
                      onChange={this.createChangeAccessHandler(record)}
                    >
                      {availableRoleOptions}
                    </StyledSelect>
                  );
              }}
            />
            <Column
              title=""
              dataIndex="remove"
              key="remove"
              // tslint:disable-next-line:jsx-no-lambda
              render={(accessType: any, record: AccessResource, index: number) => {
                const isVisible = hoverIndex === index && isNullOrUndefined(record.globalAccess);
                return (
                  <Remove
                    style={{
                      visibility: isVisible ? "visible" : "hidden",
                    }}
                    key={index}
                    onClick={this.createRemoveAccessHandler(record)}>
                    <Trans>Remove</Trans>
                  </Remove>
                );
              }}
            />
          </EmployeeAccessTable>
        </ContainerCard>
      </>
    );
  }
}

const StyledSelect = styled(Select) <SelectProps & { children: React.ReactNode }>``;

const HelpIcon = styled(Icon)`
  margin-left: 8px;
  cursor: pointer !important;
  svg {
    fill: ${Palette.bluishGrey};
  }
`;

const EmployeeAccessTable = styled(Table)`
  padding-left: 0px;
  div table>thead>tr>th {
    background-color: white;
  }
  tr.blocked {
    background-color: ${Palette.lightGrey};
  }
`;

const Remove = styled.a`
  color: #df3868;
`;

export default Access;
