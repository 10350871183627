import React from "react";
import { Trans } from "react-i18next";
import { ScreenContainer } from "@screens/Insights/components/ScreenContainer";
import Tabs from "@components/core/Tabs";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Integrations from "@screens/Insights/Settings/containers/Integrations";
import App from "@src/screens/Insights/Settings/containers/AppSettings";
import { RightsMatcher } from "hyphen-lib/dist/business/auth/Auth";
import { connect, MapStateToProps } from "react-redux";
import { State } from "@store/types";
import * as selectors from "@screens/Insights/store/selectors";
import { Rights } from "hyphen-lib/dist/business/auth/Rights";
import { BatarangScripts } from "@screens/Insights/Settings/containers/batarang/BatarangScriptsContainer";
import { isNotNullNorUndefined } from "@hyphen-lib/lang/Objects";
import { isLCAEnabled } from "@hyphen-lib/business/company/Companies";
import { getCurrentUser, getCurrentUserNotification } from "../store/selectors";
import LifeCycleSettings from "./containers/LifeCycleSettings";
import CompanySettings from "./containers/CompanySettings";
import { Apps } from "hyphen-lib/dist/domain/apps/App";

const { TabPane } = Tabs;

type OwnProps = RouteComponentProps;
interface Props {
  activeKey: string;
}

interface SettingsStateProps {
  readonly rightsMatcher: RightsMatcher;
  readonly showLCASettings: boolean;
  readonly showNotificationIcon: boolean;
}

type SettingsProps = Props & OwnProps & SettingsStateProps;

export class Settings extends React.Component<SettingsProps> {

  routeChange = (activeKey: string) => {
    const { history } = this.props;
    history.replace(`/settings/${activeKey}`);
  };

  render() {
    const {
      activeKey,
      rightsMatcher,
      showLCASettings,
    } = this.props;

    return (
      <ScreenContainer
        title="Settings"
      >
        <Tabs
          activeKey={activeKey}
          onChange={this.routeChange}
        >
          <TabPane tab={<Trans>Company settings</Trans>} key="company"><CompanySettings /></TabPane>
          <TabPane tab={
            <>
              {<Trans>App Settings</Trans>}
            </>
          } key="app"><App /></TabPane>
          <TabPane tab={<Trans>Integrations</Trans>} key="integrations"><Integrations /></TabPane>
          {
            showLCASettings && (
              <TabPane
                tab={<Trans>Employee lifecycle</Trans>}
                key="employee-lifecycle">
                {/* this component needs to get unmounted when tabbed away because unsaved state
                  within that component needs to be discarded. Hence the activeKey condition check before rendering*/}
                {activeKey.includes("lifecycle") && <LifeCycleSettings />}
              </TabPane>
            )
          }
          {
            rightsMatcher.hasAtLeastOneRightStartingWith(Rights.Batarang.SCRIPTS_PREFIX) &&
            <TabPane tab="🦇 scripts" key="batarang/scripts"><BatarangScripts /></TabPane>
          }
        </Tabs>
      </ScreenContainer>
    );
  }
}

const mapStateToProps: MapStateToProps<SettingsStateProps, OwnProps, State> = (state: State): SettingsStateProps => {
  let showLCASettings = false;

  const currentUser = getCurrentUser(state);
  const { message: notificationMessage } = getCurrentUserNotification(
    state,
    Apps.Pages.INSIGHTS_APP_SETTINGS_BENCHMARKS,
  );
  if (isNotNullNorUndefined(currentUser) &&
    isNotNullNorUndefined(currentUser.company) &&
    isNotNullNorUndefined(currentUser.company.modules)) {
    showLCASettings = isLCAEnabled(currentUser.company);
  }

  return {
    rightsMatcher: selectors.getRightsMatcher(state),
    showLCASettings,
    showNotificationIcon: isNotNullNorUndefined(notificationMessage),
  };
};

export default withRouter(connect(mapStateToProps)(Settings));
