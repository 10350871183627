import styled from "styled-components";
import { Trans } from "react-i18next";
import Palette from "@src/config/theme/palette";
import TopicsLegend from "./../TopicsLegend";
import { getTotalValue } from "@components/core/SentimentDonutChart";
import { getCategoryData } from "@src/screens/Insights/Survey/components/OverviewReport/components/Comments";
import SentimentBubble from "./SentimentBubble";
import { getTopicsChartData, SentimentMap, TopicChartData } 
  from "@src/screens/Insights/Survey/components/Topics/SurveyTopicsChart";
import { Sentiment } from "hyphen-lib/dist/domain/common/ComputationTypes";
import { TopicOverviewResource } from "hyphen-lib/dist/domain/resource/survey/report/TopicOverviewResource";
import { capitalizeFirstLetter } from "@src/utils/helper";
import { getProperty, isNotEmptyObject } from "hyphen-lib/dist/lang/Objects";
import { useEffect, useRef, useState } from "react";
import debounce from "lodash.debounce";
import ResizeObserver from "resize-observer-polyfill";
interface Props {
  readonly topics: TopicOverviewResource[];
  readonly surveyId: string;
  readonly sectionName: string;
  readonly questionId?: string;
  readonly categoryId?: string;
}

interface CategorisedTopic {
  topics : Record<string, TopicOverviewResource[]>;
  minMaxThreshold: Record<string, number>;
}

function groupBy<T, K extends keyof any>(arr: T[], key: (i: T) => K) {
  return arr.reduce((groups, item) => {
  (groups[key(item)] ||= []).push(item);
  return groups;
  }, {} as Record<K, T[]>);
}

function setBubbleSizeLimit(topics: Record<string, TopicOverviewResource[]>, maxLimit: number, width: number) {
  let minSize = 100;
  let maxSize = maxLimit > 49 ? (maxLimit > 99 ? 180 : 165) : 150;
  if(width < 400) {
    const modOffset = Math.floor((400 - width) / 10);
    maxSize -= modOffset * 2;
  }
  const minSizeOffset = 10;
  const maxSizeOffset = 20;
  (Object.values(topics)).forEach(val => {
    if(val.length > 4) {
      minSize -= minSizeOffset;
      maxSize -= maxSizeOffset;
    } else if(val.length > 7) {
      minSize -= minSizeOffset * 2;
      maxSize -= maxSizeOffset * 2;
    }
  });
  return  {
    minSize,
    maxSize
  };
}

function BubbleChart({topics, surveyId , sectionName, questionId, categoryId}: Props) {
  const chartTopicKeys = [SentimentMap[Sentiment.POSITIVE], 
                          SentimentMap[Sentiment.NEUTRAL], 
                          SentimentMap[Sentiment.NEGATIVE]];
  const [categorisedTopics, setCategorisedTopics] = useState<CategorisedTopic>({
    topics: {},
    minMaxThreshold: {}
  });

  const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;

  const [width, setwidth] = useState(0);

  useEffect(() => {
    if(ref.current) {
      const observer = new ResizeObserver(debounce(entries => {
        setwidth(entries[0].contentRect.width);
      }, 100));
      observer.observe(ref.current);
      return () => ref.current && observer.unobserve(ref.current);
    }
  }, []);

  useEffect(()=>{
    if(width > 0 && isNotEmptyObject(topics)) {
      const dataSource : TopicChartData = getTopicsChartData(topics);
      const { min, max } = dataSource;
      const chartData = dataSource.data
        .map( (bubble: any) => {
          bubble.data = getCategoryData(bubble);
          bubble.count = getTotalValue(bubble.data.sentiments);
          return bubble;
        });
      const groupedTopics = Object.assign({}, groupBy(chartData, topic => topic.sentimentText));
      const { minSize , maxSize } = setBubbleSizeLimit(groupedTopics, max, width / chartTopicKeys.length);
      setCategorisedTopics({
        topics: groupedTopics,
        minMaxThreshold: { min, max, minSize, maxSize }
      });
    }
    
  }, [width, topics]);    

  return (
    <Container>
      <Title>
        <Trans>
          The size of the circle is relative to the number 
          of comments where the topics were discovered.
        </Trans>
      </Title>
      <TopicsLegend/>
      <TagCloudContainer>
        <TopicsReportContainer id="sentiment-bubble" ref={ref}>
          { isNotEmptyObject(categorisedTopics.topics) && width > 0 && chartTopicKeys
            .map(key => ( <SentimentBubble key={key} id={key}
              topics={getProperty(categorisedTopics.topics, key, [])} 
              minMaxThreshold={categorisedTopics.minMaxThreshold}
              width={(width / chartTopicKeys.length)}
              surveyId={surveyId}
              sectionName={sectionName}
              questionId={questionId}
              categoryId={categoryId}/> )
            )
          }
        </TopicsReportContainer>
      </TagCloudContainer>
      <TopicsReportContainer>
        { chartTopicKeys
          .map(key => (<TopicFooter key={key} 
            className={key}>{capitalizeFirstLetter(key)}</TopicFooter>)
          )
        }
      </TopicsReportContainer>
    </Container>
  );
}

const Container = styled.div`
  background: ${Palette.white};
  font-family: Lato, sans-serif;
  color: ${Palette.veryDarkBlueGrey};
`;

const Title = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  color: ${Palette.bluishGrey};
`;

const TopicsReportContainer = styled.div`
  display: flex;
  width: 100%;
`;

const TopicFooter = styled.div`
  width: calc(100% / 3);
  font-size: 16px;
  font-weight: bold;
  margin-top: 5px;
  &.neutral { 
    text-align: center;
    color: ${Palette.bluishGrey};
  }
  &.positive { 
    text-align: left;
    color: ${Palette.aquaBlue};
  }
  &.negative { 
    text-align: right;
    color: ${Palette.darkPink};
  }
`;

const TagCloudContainer = styled.div`
  // padding: 1rem 5px;
  min-height: 500px;
  background: -webkit-repeating-linear-gradient(180deg,
   #0000 0 calc((100% - 5*2px)/9),
   #c9cfd2  0 calc((100% - 5*2px)/9 + 2px)),
   -webkit-linear-gradient(left, #e1f7fa , #fdfdfd, #fce8ed);
  background-size: cover;
  background-repeat: no-repeat;
  border-color: #c9cfd2;
  border-width: 0 2px;
  border-style: solid;
  border-radius: 3px;
`;

export default BubbleChart;
