import React from "react";
import styled from "styled-components";
import { Paragraph } from "@src/components/core/Typography";
import Palette from "@src/config/theme/palette";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";


interface EmailCellProps {
  readonly email: string;
  readonly info: string;
  readonly showAction?: boolean;
  readonly onViewInfo?: () => any;
  readonly makeInfoAsHyperLink?: boolean;
  readonly onClickHyperLinkAction?: () => any;
}

export function EmailCell({
  email,
  info,
  makeInfoAsHyperLink,
  onClickHyperLinkAction,
}: EmailCellProps) {
  const infoAsHyperLink = makeInfoAsHyperLink === true;

  const onInfoLinkClick = () => {
    if (isNotNullNorUndefined(onClickHyperLinkAction)) {
      onClickHyperLinkAction();
    }
  };

  return (
    <Container>
      <Paragraph>{email}</Paragraph>
      {isNotNullNorUndefined(email) && (
        <Paragraph>
          {infoAsHyperLink ? (
            <a onClick={onInfoLinkClick} style={{color: Palette.darkModerateBlue}}>{info}</a>
          ) : (
            <span>{info}</span>
          )}
        </Paragraph>
      )}
    </Container>
  );
}

const Container = styled.div`
  p:last-child {
    margin-top: 6px;
    color: ${Palette.bluishGrey};
  }
`;
