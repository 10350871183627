import React from "react";
import { SurveyInfoResource } from "@hyphen-lib/domain/resource/SurveyInfoResource";
import { DynamicRowProps } from "@components/core/DynamicRowsTable/withDynamicRows";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { capitalizeFirstLetter } from "@src/utils/helper";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { CellContainer, CellInnerContainer } from "./Cells";
import { Trans } from "react-i18next";
import { sanitizeSegmentLabel } from "hyphen-lib/dist/domain/common/Dimensions";

export function AudienceCell({ row: { audience } }: DynamicRowProps<SurveyInfoResource>) {
  if (!audience) {
    return <div>{""}</div>;
  }

  return (
    <CellContainer align="center" justifyContent="flex-start">
      <CellInnerContainer margin={0} width="100%">
        {
          audience.type.criteria &&
          <AudienceLine>
            <AudienceLabel>
              <Trans>Criteria</Trans>:
            </AudienceLabel>
            <AudienceValues>
              {sanitizeSegmentLabel(audience.description)}
            </AudienceValues>
          </AudienceLine>
        }
        {
          audience.type.manual &&
          <AudienceLine>
            <AudienceLabel>
              <Trans>Manual</Trans>:
            </AudienceLabel>
            <AudienceValues>
              {audience.manualCount}
            </AudienceValues>
          </AudienceLine>
        }
        {
          not(audience.type.manual) && not(audience.type.criteria) &&
          <AudienceLine>
            <AudienceLabel>
              <Trans>{capitalizeFirstLetter(audience.description)}</Trans>
            </AudienceLabel>
          </AudienceLine>
        }
      </CellInnerContainer>
    </CellContainer>
  );
}

const AudienceLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const AudienceLabel = styled.div<{ addMargin?: boolean }>`
  flex-direction: row;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: normal;
  margin-top: ${props => props.addMargin ? "6px" : "0px"};
  color: ${Palette.veryDarkBlueGrey};
  &:after {
    content: " ";
    white-space: pre;
  }
`;

const AudienceValues = styled.span`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Palette.bluishGrey};
`;
