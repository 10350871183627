import React from "react";

import { DistributionResult } from "@hyphen-lib/domain/resource/report/common/DistributionResult";
import { StackedBarGraph } from "@components/core/StackedBarGraph";
import { adjustElementsAndPercentages } from "@src/utils/Graphs";
import { isNotNullNorUndefined, getAtIndexOr } from "hyphen-lib/dist/lang/Objects";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { getBarThemeForMultipleChoice } from "@src/config/theme/palette";
import { GraphColor } from "../AnswersChart";

interface Props {
  readonly data: DistributionResult;
  readonly choiceLabels?: Optional<string>[];
  readonly graphColors?: GraphColor[];
  readonly numberOfVotes?: number;
}

const barTheme = getBarThemeForMultipleChoice([]);

const AnswerDistributionGraph = ({ data, choiceLabels, graphColors = barTheme, numberOfVotes}: Props) => {
  const { pieceToAdd } = adjustElementsAndPercentages(data);

  const graphData = data.map(({ total, percentage }, index) => {
    const { backgroundColor, fontColor } = graphColors[index];
    const labelToUse = getAtIndexOr(choiceLabels, index, "");
    return ({
      value: total,
      percentage,
      percentageWidth: isNotNullNorUndefined(percentage) && percentage > 0 ?
        percentage + pieceToAdd :
        0,
      backgroundColor,
      fontColor,
      label: labelToUse,
    });
  });

  return <StackedBarGraph data={graphData} width="100%" height="48px" numberOfVotes={numberOfVotes}/>;
};

export default AnswerDistributionGraph;
