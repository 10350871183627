import React from "react";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { Tag } from "@components/core/Tag";

export type Status = "todo" | "in_progress";

export enum Statuses {
  TODO = "todo",
  IN_PROGRESS = "in_progress",
}

interface Props {
  status: Status;
  readonly translate?: string;
}

export function ActionPlanTag({ status , ...rest}: Props) {
  const { text, color, textColor } = mapStatusToTextAndColor(status);
  return (
    <Container>
      <Tag color={color} textColor={textColor} translate="yes">{text}</Tag>
    </Container>
  );
}

function mapStatusToTextAndColor(status: Status): { text: string; color: string; textColor: string } {
  switch (status) {
    case Statuses.TODO:
      return {
        text: "To do",
        color: Palette.darkPink,
        textColor: Palette.white,
      };
    case Statuses.IN_PROGRESS:
      return {
        text: "In Progress",
        color: Palette.aquaBlue_50,
        textColor: Palette.veryDarkBlueGrey,
      };
    default:
      return {
        text: `Unknown: ${status}`,
        color: Palette.paleGrey,
        textColor: Palette.veryDarkBlueGrey,
      };
  }
}

const Container = styled.div`
  width: 88px;
`;
