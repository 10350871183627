import React from "react";
import { debounce } from "lodash";

import { InputProps } from "antd/lib/input";
import { getOr } from "hyphen-lib/dist/lang/Objects";

export interface WithDebounceProps extends InputProps {
  waitTime?: number;
  onValueChange: (value: string) => void;
  label: React.ReactNode;
  inputRef: any;
}

interface FormInputState {
  value: string | number | readonly string[] | undefined;
  isEditing: boolean;
}

/** From T make every property K optional */
type Partialize<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>> & Partial<Pick<T, K>>;

/** Remove props, that have been prefilled by the HOC */
type OptionalPrefilled<T extends WithDebounceProps> = Partialize<T, keyof WithDebounceProps>;

function withDebounce<P extends WithDebounceProps>
(WrappedComponent: React.ComponentType<P>) {
  return class WithDebounce extends React.Component<OptionalPrefilled<P>, FormInputState> {

    constructor(props: P) {
      super(props);
      this.state = {
        value: props.value,
        isEditing: false,
      };
      this.updateValueToState = debounce(this.updateValueToState, getOr(props.waitTime, 200));
    }

    componentDidUpdate(prevProps : P) {
      if (!this.state.isEditing) {
        if (prevProps.value !== this.props.value) {
          this.setState({ value: this.props.value });
        }
      }
    }

    handleChange = (e: any) => {
      const { onChange } = this.props;
      const { value } = e.target;
      if (onChange) {
        onChange(e);
      }
      this.updateValueToState(value);
      this.setState({ value, isEditing: true });
    };

    updateValueToState = (value: any) => {
      const { onValueChange } = this.props;
      if (onValueChange) {
        onValueChange(value);
      }
      this.setState({ isEditing: false });
    };

    render() {
      const { label, onValueChange, inputRef, ...rest } = this.props;
      const { isEditing,  ...restState } = this.state;
      return (
        <WrappedComponent
          {...rest as P}
          {...restState}
          label={label}
          onChange={this.handleChange}
          ref={inputRef}
        />

      );
    }
  };
}

export default withDebounce;
