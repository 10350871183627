// @ts-nocheck
import React from "react";
export function DashboardSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="15"
      viewBox="0 0 18 15"
    >
      {/* eslint-disable max-len */}
      <g fill="none" fillRule="evenodd" stroke="#AAB9CE"
        strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" transform="translate(1 1)">
        <path d="M8 0v1.912M16 8.412h-1.818M0 8.412h1.818M13.657 2.464L12.37 3.816M2.343 2.464L3.63 3.816M7.51 6.971L6.183 3.06M14.704 13A8.682 8.682 0 0 0 16 8.412C16 3.766 12.418 0 8 0S0 3.766 0 8.412A8.68 8.68 0 0 0 1.296 13h13.408z"/>
        <circle cx="8.045" cy="8.382" r="1.5"/>
      </g>
      {/* eslint-disable max-len */}
    </svg>
  );
}
