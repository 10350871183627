import React from "react";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { Sentiment } from "@hyphen-lib/domain/common/ComputationTypes";
import { Tooltip  } from "antd";

interface Props {
  sentiment: Sentiment;
  barPercentRounded: number;
  barPercent: number;
  barValue: number;
}

const SentimentBar = ({
  sentiment,
  barPercentRounded,
  barPercent,
  barValue,
}: Props) => {
  let backgroundColor;
  let fontColor;
  let borderRadius;
  let sentimentLabel;
  switch (sentiment) {
    case Sentiment.POSITIVE:
      backgroundColor = Palette.aquaBlue;
      fontColor = "white";
      borderRadius = "3px 0 0 3px";
      sentimentLabel = "Positive";
      break;

    case Sentiment.NEUTRAL:
      backgroundColor = Palette.lightGrey;
      fontColor = Palette.bluishGrey;
      borderRadius = "0";
      sentimentLabel = "Neutral";
      break;

    case Sentiment.NEGATIVE:
      backgroundColor = Palette.darkPink;
      fontColor = "white";
      borderRadius = "0 3px 3px 0";
      sentimentLabel = "Negative";
      break;
  }

  return (
    <Tooltip title={`${barPercentRounded}% (${barValue}) ${sentimentLabel}` }>
    <StyledBar barPercent={barPercent} backgroundColor={backgroundColor} 
    borderRadius={borderRadius} fontColor={fontColor}>
      <LabelContainer>{barPercentRounded}%</LabelContainer>
    </StyledBar>
    </Tooltip>
  );
};

const LabelContainer = styled.span`
  margin-left: -100%;
  margin-right: -100%;
  text-align: center;
`;

/* eslint-disable max-len */
const StyledBar = styled.div<{ barPercent: number; backgroundColor: string; borderRadius: string; fontColor: string }>`
    position: relative;
    width: ${(props) => props.barPercent}%;
    background-color: ${(props) => props.backgroundColor};
    line-height: 35px;
    border-radius: ${(props) => props.borderRadius};
    color: ${(props) => props.fontColor};
    font-size: 13px;
    text-align: center;
    white-space: nowrap;

    &:hover > div {
      display: block;
    }
  `;


export default SentimentBar;
