import React from "react";
import styled from "styled-components";
import { Layout as AntLayout } from "antd";
import qs from "qs";
import { CommentsOverviewResource } from "@hyphen-lib/domain/resource/report/CommentsOverviewResource";
import { CommentsResultResource } from "@hyphen-lib/domain/resource/report/CommentsResultResource";
import SurveyReportHeader from "@screens/Insights/Survey/components/SurveyReportHeader";
import CommentsTable from "@src/screens/Insights/components/Reports/ResultsTable";
import { NetworkEventSuccessAction } from "@src/store/network/actions";
import { Participation } from "hyphen-lib/dist/domain/common/Participation";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { LoadingContainer } from "@screens/Insights/Survey/components/Loading";
import SentimentOverview from "@src/screens/Insights/components/Reports/SentimentOverview";
import SendPrivateMessageModal from "../../../components/Reports/SendPrivateMessage/index";
import CommentsSearchBar from "./components/CommentsSearchBar";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { Trans } from "react-i18next";
import CommonWords from "../CommonWords";
import Palette from "@src/config/theme/palette";
import { getOr, isNotEmptyObject, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { fromJS } from "immutable";
import { appendQueryString, generateQueryString, parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { replaceLocation, replaceTo } from "@src/utils/locations";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Spin from "@src/components/core/Spin";
import { PaginationConfig } from "antd/lib/pagination";
import { SorterResult } from "antd/lib/table";
import { SortParameter } from "@src/utils/networks";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import Tabs from "@src/components/core/Tabs";
import ArtificialInteligence from "@src/components/core/svg/ArtificialInteligence";
import { Tag, Alert } from "antdv4";
export interface SurveyCommentsReportStateProps {
  readonly commentText: string;
  readonly errorText: string;
  readonly isNetworkRequesting: boolean;
  readonly isPrivateMessageModalVisible: boolean;
  readonly postId: string;
  readonly privateMessageText: string;
  readonly voteId: string;
  readonly hasActionCreationRight: boolean;
}

export interface SurveyCommentsReportActionProps {
  readonly clearPrivateMessageModal: () => any;
  readonly sendPrivateMessage: (
    surveyId: string,
    postId: string,
    voteId: string,
    initialMessage: string,
    onSuccessRedirect?: (payload: NetworkEventSuccessAction["payload"]) => void
  ) => void;
  readonly togglePrivateMessageModal: (
    surveyId: string,
    postId: string,
    voteId: string,
    commentText: string
  ) => void;
  readonly updatePrivateMessageText: (privateMessageText: string) => any;
}

interface CommentsProps extends RouteComponentProps{
  readonly surveyId: string;
  readonly surveyName: string;
  readonly participation: Participation;
  readonly commentsOverview: Loadable<CommentsOverviewResource>;
  readonly comments: Store.Page<CommentsResultResource>;
  readonly enabledFilters: string[];
  readonly enabledCustomFilters?: string[];
  readonly canSendPrivateMessage: boolean;
  readonly anonymityThreshold: number;
  readonly focusAreas?: FocusAreaResource[];
  readonly onCreateFocusArea: (focusArea: Partial<FocusAreaResource>) => void;
  readonly sort?: SortParameter;
  readonly topicAnalysEnabled?: boolean;
  readonly surveySuggestionsEnabled?: boolean;
  readonly page: PageFilter;
  readonly postAndSurveySentiment?: boolean;
  readonly enableDefaultTranslate?: boolean;
}

export type Props = SurveyCommentsReportStateProps & 
  CommentsProps & 
  SurveyCommentsReportActionProps;

interface CommentsState {
  isPrivateMessageModalOpen: boolean;
  selectedTab: string;
}

const { TabPane } = Tabs;
export class Comments extends React.Component<Props, CommentsState> {
  state = {
    isPrivateMessageModalOpen: false,
    selectedTab: "comments"
  };

  sendPrivateMessage = () => {
    this.props.sendPrivateMessage(
      this.props.surveyId,           // Survey Id
      this.props.postId,             // Question Id
      this.props.voteId,             // Comment Id
      this.props.privateMessageText  // Text Message
    );
  };

  UNSAFE_componentWillMount(): void {
    const {
      location: { pathname }
    } = this.props;
    if(pathname.indexOf("/suggestions") > -1) {
      this.setState({
        selectedTab: "comments/suggestions"
      });
    }   
  }

  onWordClick = (word: string, sentiment: string) => {
    const param = {
      filter: {
        topics: [word],
      },
    };
    const {
      location: { search, pathname }
    } = this.props;
    const tempQueryParams: any = qs.parse(search, { ignoreQueryPrefix: true });

    if (
      isNotNullNorUndefined(tempQueryParams.filter) &&
      isNotNullNorUndefined(tempQueryParams.filter.topics)
    ) {
      delete tempQueryParams.filter.topics;
    }
    const queryParams = fromJS(tempQueryParams).mergeDeep(param).toJS();
    const pathArr = pathname.split("/");
    pathArr.splice(pathArr.length - 1, 1, "comments");
    const newPath = pathArr.join("/");
    replaceTo(appendQueryString(newPath, generateQueryString(queryParams)));
  };

  onTableChange = (
    pagination: PaginationConfig,
    data: any,
    sorter: SorterResult<any>
  ) => {
    const { location } = this.props;
    const sortParam: SortParameter = {};
    if (isNotEmptyObject(sorter) && isNotNullNorUndefined(sorter.order)) {
      sortParam[sorter.columnKey] = sorter.order === "ascend" ? 1 : -1;
    }
    replaceLocation({
      ...parseQueryString(location.search),
      sort: sortParam
    });
  };

  onTabChange = (activeKey: string) => {
    const {
      location: { search, pathname }
    } = this.props;
    const queryParams: any = qs.parse(search, { ignoreQueryPrefix: true });
    const pathArr = pathname.split("/");
    if(activeKey === "comments") {
      pathArr.pop();
    }
    pathArr.splice(pathArr.length - 1, 1, activeKey);
    const newPath = pathArr.join("/");
    replaceTo(appendQueryString(newPath, generateQueryString(queryParams)));
    this.setState({
      selectedTab: activeKey
    }, () => {
      setTimeout(()=>{
        document.getElementById("comments-tab")?.scrollIntoView();
      }, 0);
    });
  };


  render() {
    const {
      clearPrivateMessageModal,
      comments,
      commentText,
      commentsOverview,
      enabledFilters,
      enabledCustomFilters,
      errorText,
      isNetworkRequesting,
      isPrivateMessageModalVisible,
      participation,
      privateMessageText,
      togglePrivateMessageModal,
      updatePrivateMessageText,
      canSendPrivateMessage,
      hasActionCreationRight,
      anonymityThreshold,
      onCreateFocusArea,
      focusAreas,
      surveyName,
      sort,
      topicAnalysEnabled,
      surveySuggestionsEnabled,
      page,
      postAndSurveySentiment,
      enableDefaultTranslate
    } = this.props;

    const { selectedTab } = this.state;
    return (
      <>
        <SurveyReportHeader
          participation={participation}
          enabledFilters={enabledFilters}
          enabledCustomFilters={enabledCustomFilters}
          singleFieldFilter="category"
          exportOption="comments"
        />

        { Loadable.isLoaded(commentsOverview) ? 
          getOr(postAndSurveySentiment, true) && <SentimentOverview
            netSentimentScore={commentsOverview.value.netSentimentScore}
            filteredForAnonymity={commentsOverview.value.filteredForAnonymity}
            filteredForAnonymityReason={
              commentsOverview.value.filteredForAnonymityReason
            }
            sentiments={commentsOverview.value.sentiments}
            words={[]}
            numberOfComments={commentsOverview.value.numberOfComments}    
            anonymityThreshold={anonymityThreshold}
          /> :
          <LoadingContainer><Spin/></LoadingContainer>
        }
        
        <Layout id="comments-tab">
          <StyledTabs defaultActiveKey={selectedTab} onChange={this.onTabChange}>
            <TabPane tab={<Trans>All comments</Trans>} key="comments">
              { topicAnalysEnabled && Loadable.isLoaded(commentsOverview)
               && isNotEmptyArray(commentsOverview.value.topics) 
               && getOr(postAndSurveySentiment, true) && (
                  <CommonWordsContainer>
                    <HelpTextContainer>
                      <Trans>Click on the topics to filter the list below.</Trans>
                    </HelpTextContainer>
                    <CommonWords words={commentsOverview.value.topics} onWordClick={this.onWordClick}/>
                  </CommonWordsContainer>
              )}
              <CommentsTable
                comments={Store.Page.toList(comments)}
                loading={Store.Page.isLoading(comments)}
                isPrivateMessageModalOpen={isPrivateMessageModalVisible}
                privateMessageText={privateMessageText}
                sendPrivateMessage={this.sendPrivateMessage}
                togglePrivateMessageModal={togglePrivateMessageModal}
                updatePrivateMessageText={updatePrivateMessageText}
                canSendPrivateMessage={canSendPrivateMessage}
                hasActionCreationRight={hasActionCreationRight}
                focusAreas={focusAreas}
                onCreateFocusArea={onCreateFocusArea}
                focusAreaLabel={surveyName}
                onChange={this.onTableChange}
                sort={sort}
                topicAnalysEnabled={topicAnalysEnabled}
                page={page}
                postAndSurveySentiment={postAndSurveySentiment}
                enableDefaultTranslate={enableDefaultTranslate}
              />
            </TabPane>
            { surveySuggestionsEnabled ? <TabPane tab={SuggestionsTitle} key="comments/suggestions">
              <Alert
                key="suggestionsInfo"
                message={<Trans>
                  We use Artificial Intelligence to identify for you the comments that seem to contain a 
                  suggestion from the respondent. As in all things AI, there will be some false positives, 
                  and we'll continuously try to improve the accuracy of our algorithm.
                </Trans>}
                type="warning"
                style={{
                  "margin": "8px 0 12px"
                }}
              />
              <CommentsTable
                comments={Store.Page.toList(comments)}
                loading={Store.Page.isLoading(comments)}
                isPrivateMessageModalOpen={isPrivateMessageModalVisible}
                privateMessageText={privateMessageText}
                sendPrivateMessage={this.sendPrivateMessage}
                togglePrivateMessageModal={togglePrivateMessageModal}
                updatePrivateMessageText={updatePrivateMessageText}
                canSendPrivateMessage={canSendPrivateMessage}
                hasActionCreationRight={hasActionCreationRight}
                focusAreas={focusAreas}
                onCreateFocusArea={onCreateFocusArea}
                focusAreaLabel={surveyName}
                onChange={this.onTableChange}
                sort={sort}
                topicAnalysEnabled={topicAnalysEnabled}
                page={page}
                onlySugestions={true}
                postAndSurveySentiment={postAndSurveySentiment}
                enableDefaultTranslate={enableDefaultTranslate}
              />
            </TabPane> : null }
          </StyledTabs>
          <AbsoluteSearchBarContainer right={15}>
            <CommentsSearchBar/>
          </AbsoluteSearchBarContainer>
        </Layout>
        <SendPrivateMessageModal
          clearPrivateMessageModal={clearPrivateMessageModal}
          comment={commentText}
          errorText={errorText}
          isSending={isNetworkRequesting}
          onCancel={togglePrivateMessageModal}
          privateMessageText={privateMessageText}
          updatePrivateMessageText={updatePrivateMessageText}
          visible={isPrivateMessageModalVisible}
          onOk={this.sendPrivateMessage}
        />
      </>
    );
  }
}

const AITag = styled(Tag)`
  padding: 0;
  height: 26px;
  margin-left: 5px;
`;

const SuggestionText = styled.div`
  min-width: 125px;
  > div {
    position: absolute;
    display: flex;
    align-items: center;
  }
`;

const SuggestionLabel = styled.span`
  font-family: Lato;
  font-weight: bold;
  font-size: 15px;
`;

export const SuggestionsTitle = <SuggestionText>
  <div><AITag
  color={Palette.tequila}
  icon={<ArtificialInteligence />}
  data-jest="AI-based"/>
  <SuggestionLabel>
    <Trans>Suggestions</Trans>
  </SuggestionLabel></div>
  </SuggestionText>;

const Layout = styled(AntLayout)`
  position: relative;
  background: white !important;
  margin-top: 24px;
  padding-bottom: 12px;
  font-family: "Lato";
`;

export const HelpTextContainer = styled.div`
  margin-bottom: 8px;
  color: ${Palette.bluishGrey};
`;

export const CommonWordsContainer = styled.div`
  background: ${Palette.white};
`;

export const AbsoluteSearchBarContainer = styled.div<{ right: number}>`
  position: absolute;
  width: 500px;
  ${props => `
    right: ${props.right}px;
  `}
  > div {
    padding: 0;
    margin: 10px;
  }
`;

export const StyledTabs = styled(Tabs)`
  padding: 0 32px;
  flex: 1;
`;

export default withRouter(Comments);
