import React from "react";
import { ColumnProps, TableProps } from "antd/lib/table";
import Table from "@src/components/core/Table";
import { Heading } from "@components/core/Typography";
import Palette from "@src/config/theme/palette";
import styled from "styled-components";
import {
  DynamicRowProps,
  withDynamicRows
} from "@src/components/core/DynamicRowsTable/withDynamicRows";
import { PulsePollInfoResource } from "hyphen-lib/dist/domain/resource/PulsePollInfoResource";
import { SortParameter } from "@src/utils/networks";
import { getSortOrder } from "@src/utils/helper";
import { AudienceCell } from "../../SurveyList/components/cells/AudienceCell";
import { NameCell } from "../../SurveyList/components/cells/NameCell";
import { PulsePollActionKeyType } from "../../components/Actions";
import { PulsePollStatusCell } from "./cells/PulsePollStatusCell";
import { PulsePollActionCell } from "./cells/ActionCell";
import { DateCell } from "./cells/DateCell";
import { PulsePollIterationCell } from "./cells/PulsePollIterationCell";

interface PulsePollDetailsTableProps extends TableProps<PulsePollInfoResource> {
  readonly sort?: SortParameter;
  readonly timezone: string;
  readonly onActionClick: (row: PulsePollInfoResource, action: keyof PulsePollActionKeyType) => void;
}

function PulsePollTableComponent({ ...rest }: PulsePollDetailsTableProps) {
  const COLUMNS: ColumnProps<any>[] = [
    {
      title: <ColumnStyledHeader size="small" translate="yes">Name</ColumnStyledHeader>,
      dataIndex: "question",
      key: "question",
      sorter: true,
      sortOrder: getSortOrder("question", rest.sort),
      sortDirections: ["ascend","descend"],
      width: 400,
      render: (__, record, index) => <NameCell row={record} index={index} />,
    },
    {
      title: <ColumnStyledHeader size="small" translate="yes">Status</ColumnStyledHeader>,
      dataIndex: "status",
      key: "status",
      sorter: true,
      sortOrder: getSortOrder("status", rest.sort),
      sortDirections: ["ascend","descend"],
      width: 150,
      render: (__, record, index) => {
        return <PulsePollStatusCell row={record} index={index} />;
      },
    },
    {
      title: <ColumnStyledHeader size="small" translate="yes">Iterations</ColumnStyledHeader>,
      dataIndex: "instances",
      key: "instances",
      width: 250,
      render: (__, record, index) => {
        return <PulsePollIterationCell row={record} index={index} />;
      },
    },
    {
      title: <ColumnStyledHeader size="small" translate="yes">Audience</ColumnStyledHeader>,
      dataIndex: "votingAudience",
      key: "votingAudience",
      width: 300,
      sorter: true,
      sortOrder: getSortOrder("votingAudience", rest.sort),
      sortDirections: ["ascend","descend"],
      render: (__, record, index) => {
        return <AudienceCell row={record} index={index} />;
      },
    },
    {
      title: <ColumnStyledHeader size="small" translate="yes">Key Dates</ColumnStyledHeader>,
      dataIndex: "",
      key: "",
      width: 350,
      render: (__, record, index) => {
        return <DateCell timezone={rest.timezone} row={record} index={index} />;
      },
    },
  ];

  return <Table columns={COLUMNS} {...rest} />;
}

export function PulsePollDetailsTable(props: PulsePollDetailsTableProps) {
  function HoverRow({ row, index }: DynamicRowProps<any>) {
    return (
      <>
        <StyledHoverCell>
          <NameCell row={row} index={index} />
        </StyledHoverCell>
        <StyledHoverCell>
          <PulsePollStatusCell row={row} index={index} />
        </StyledHoverCell>
        <StyledHoverCell>
          <PulsePollIterationCell row={row} index={index} />
        </StyledHoverCell>
        <StyledHoverCell>
          <AudienceCell row={row} index={index} />
        </StyledHoverCell>
        <StyledHoverCell colSpan={1}>
          <LastHoverCellContainer>
            <PulsePollActionCell
              onClick={props.onActionClick.bind(null, row)}
              row={row}
              index={index}
              hasCreationRight
            />
          </LastHoverCellContainer>
        </StyledHoverCell>
      </>
    );
  }

  const TailoredTable = withDynamicRows(PulsePollTableComponent, HoverRow);

  return <TailoredTable rowKey="_id" {...props} />;
}

const ColumnStyledHeader = styled(Heading)<{ minWidth?: number; maxWidth?: number }>`
  color: ${Palette.darkBlueGrey};
  min-width: ${props => `${props.minWidth}px`};
`;

const LastHoverCellContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledHoverCell = styled.td`
  background-color: ${Palette.lightLightBlue};
`;
