import React from "react";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import { getOr, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { Tooltip as Ttip } from "antd";
import { trans } from "@src/utils/i18next";

export interface BarSegmentData {
  value: number;
  percentage: number | null | undefined;
  percentageWidth?: number;
  label?: string;
  backgroundColor?: string;
  fontColor?: string;
  height?: string;
  hasSingleValue?: boolean;
  hideSingleDigitLabel?: boolean;
  numberOfVotes?: number;
}

const BarSegment = ({
  value,
  percentage,
  percentageWidth = getOr(percentage, 0),
  label,
  backgroundColor = Palette.bluePurple,
  fontColor = "white",
  height = "30px",
  hasSingleValue = false,
  hideSingleDigitLabel,
  numberOfVotes
}: BarSegmentData) => {
  const number = Number(label);
  const title =  isNotNullNorUndefined(numberOfVotes) ?
   `${trans("Answer")} "${label}": ${percentage}% (${value}/${numberOfVotes} ${Number.isNaN(number) ?
     trans("answers") : trans("employees")})`: 
   `${percentage}% (${value}) ${label}`;
  return (
    <Ttip title={title}>
      <StyledBar
        percentage={percentageWidth}
        backgroundColor={backgroundColor}
        fontColor={fontColor}
        height={height}
      >
        <LabelContainer colorOverride={percentage === 0} hasSingleValue={hasSingleValue} data-cy="barSegments">
          {(hideSingleDigitLabel && percentage && percentage < 10)
            ? ""
            : String(percentage) + "%"
          }
        </LabelContainer>
      </StyledBar>
    </Ttip >
  );
};

const StyledBar = styled
  .div<{percentage: number | null | undefined; backgroundColor: string; fontColor: string; height: string }>`
  position: relative;
  width: ${props => props.percentage}%;
  background-color: ${props => props.backgroundColor};
  line-height: ${props => props.height};
  color: ${props => props.fontColor};
  font-size: 13px;
  text-align: center;
  white-space: nowrap;

  &:hover > div {
    display: block;
  }
`;

const getAdditionalLabelContainerStyle = (colorOverride?: boolean, hasSingleValue?: boolean) => `
  padding-left: ${colorOverride && hasSingleValue ? "5px" : 0};
  ${colorOverride && hasSingleValue ? `color: ${Palette.darkModerateBlue}` : ""};
  ${!colorOverride && hasSingleValue ? `text-shadow: 0 0 1px ${Palette.darkBlueGrey}` : ""};
`;

const LabelContainer = styled.span<{ colorOverride?: boolean; hasSingleValue?: boolean }>`
  margin-left: -100%;
  margin-right: -100%;
  text-align: center;
  ${props => getAdditionalLabelContainerStyle(props.colorOverride, props.hasSingleValue)};
`;

export default BarSegment;
