import React, { FC } from "react";
import styled from "styled-components";
import { Row, Col, Button, Form } from "antd";
import { List } from "immutable";

import Palette from "@src/config/theme/palette";
import Input from "@src/components/core/Input";
import { not } from "@hyphen-lib/lang/Booleans";
import { isNullOrEmpty, isStringAndNotEmpty } from "@hyphen-lib/lang/Strings";
import withDebounce from "@components/core/withDebounce";
import {
  EMPTY_STRING_NOTATION,
  INFINITY_NOTATION,
  MutablePhaseWithErrorState,
  MutableSubPhaseWithErrorState,
  handleWheelScroll
} from "../../utils/LifeCycleSettings";
import LifeCycleSubPhases from "./LifeCycleSubPhases";
import PhaseContainer from "./PhaseContainer";
import { Trans } from "react-i18next";

const DebouncedInput = withDebounce(Input as any);

type PhaseTypeWithoutSubPhase = Omit<MutablePhaseWithErrorState, "subPhases">;

interface PhaseType extends PhaseTypeWithoutSubPhase {
  subPhases: List<MutableSubPhaseWithErrorState>;
}

interface LifeCyclePhaseProps extends PhaseType {
  title: string;
  isLast: boolean;
  isFirst: boolean;
  minSubPhases: number;
  maxSubPhases: number;
  handlePhaseChange: (phase: MutablePhaseWithErrorState) => void;
  handleRemovePhaseClick: () => void;
}

const LifeCyclePhase: FC<LifeCyclePhaseProps> = (props) => {
  const {
    name,
    startTenure,
    endTenure,
    subPhases,
    canRemove,
    title,
    isLast,
    minSubPhases,
    maxSubPhases,
    nameError,
    startTenureError,
    endTenureError,
    handlePhaseChange,
    handleRemovePhaseClick,
  } = props;

  const adjustSubphases = (inputName: string, inputValue: string) => {
    if (inputName === "startTenure") {
      const firstSubPhase: MutableSubPhaseWithErrorState = subPhases.first();
      return subPhases.set(0, {
        startTenure: isNullOrEmpty(inputValue) ? EMPTY_STRING_NOTATION : Number(inputValue),
        endTenure: firstSubPhase.endTenure,
        startTenureError: firstSubPhase.startTenureError,
        endTenureError: firstSubPhase.endTenureError,
      });
    }
    if (inputName === "endTenure") {
      const lastSubPhase: MutableSubPhaseWithErrorState = subPhases.last();
      return subPhases.set(subPhases.size - 1, {
        startTenure: lastSubPhase.startTenure,
        endTenure: isNullOrEmpty(inputValue) ? EMPTY_STRING_NOTATION : Number(inputValue),
        startTenureError: lastSubPhase.startTenureError,
        endTenureError: lastSubPhase.endTenureError,
      });
    }
    return subPhases;
  };

  const handleInputChange = (inputName: string) => (inputValue: string) => {
    let updatedSubPhases: List<MutableSubPhaseWithErrorState> = subPhases;
    let updatedValue: string | number = inputValue;

    if (inputName.endsWith("Tenure") && subPhases.size > 0) {
      updatedSubPhases = adjustSubphases(inputName, inputValue);
    }

    if (inputName.endsWith("Tenure") && isNullOrEmpty(inputValue)) {
      updatedValue = EMPTY_STRING_NOTATION;
    }

    if (inputName.endsWith("Tenure") && isStringAndNotEmpty(inputValue)) {
      updatedValue = Number(inputValue);
    }

    handlePhaseChange({
      name,
      startTenure,
      endTenure,
      canRemove,
      nameError,
      startTenureError,
      endTenureError,
      [inputName]: updatedValue,
      subPhases: updatedSubPhases,
    });
  };

  const handleSubPhasesChange = (updatedSubPhases: List<MutableSubPhaseWithErrorState>) => {
    handlePhaseChange({
      name,
      startTenure,
      endTenure,
      canRemove,
      nameError,
      startTenureError,
      endTenureError,
      subPhases: updatedSubPhases,
    });
  };

  const getStartTenureValue = () => {
    if (startTenure === EMPTY_STRING_NOTATION) {
      return "";
    }
    return startTenure;
  };

  const getEndTenureValue = () => {
    if (isLast) {
      return "∞";
    }
    if (endTenure === EMPTY_STRING_NOTATION) {
      return "";
    }
    return endTenure;
  };

  const addSubPhase = () => {
    const firstSubPhase: MutableSubPhaseWithErrorState = subPhases.first();
    const lastSubPhase: MutableSubPhaseWithErrorState = subPhases.last();
    const allButLastSubPhases = subPhases.butLast();
    let updatedSubPhases = allButLastSubPhases;

    if (subPhases.size > 0) {
      const secondLastSubPhase: MutableSubPhaseWithErrorState = allButLastSubPhases.last();
      updatedSubPhases = updatedSubPhases.push({
        startTenure: secondLastSubPhase ? secondLastSubPhase.endTenure : firstSubPhase.startTenure,
        endTenure: EMPTY_STRING_NOTATION,
        startTenureError: null,
        endTenureError: null,
      });
    }

    updatedSubPhases = updatedSubPhases.push({
      startTenure: EMPTY_STRING_NOTATION,
      endTenure: subPhases.size > 0 ? lastSubPhase.endTenure : INFINITY_NOTATION,
      startTenureError: null,
      endTenureError: null,
    });

    handleSubPhasesChange(updatedSubPhases);
  };

  const handleSubPhaseChange = (idx: number, inputName: string) => (inputValue: string) => {
    const currentSubPhase = subPhases.get(idx);
    const previousSubPhase = subPhases.get(idx - 1);
    const nextSubPhase = subPhases.get(idx + 1);

    const isStartTenure = inputName === "startTenure";

    const updatedSubPhases = subPhases
      .set(isStartTenure ? idx - 1 : idx, {
        startTenure: (isStartTenure ? previousSubPhase : currentSubPhase)!.startTenure,
        endTenure: inputValue === "" ? EMPTY_STRING_NOTATION : Number(inputValue),
        startTenureError: "",
        endTenureError: "",
      })
      .set(isStartTenure ? idx : idx + 1, {
        startTenure: inputValue === "" ? EMPTY_STRING_NOTATION : Number(inputValue),
        endTenure: (isStartTenure ? currentSubPhase : nextSubPhase)!.endTenure,
        startTenureError: "",
        endTenureError: "",
      });

    handleSubPhasesChange(updatedSubPhases);
  };

  const removeSubPhase = (idx: number) => () => {
    const currentSubPhase = subPhases.get(idx);
    const previousSubPhase = subPhases.get(idx - 1);
    const nextSubPhase = subPhases.get(idx + 1);
    let updatedSubPhases;

    if (subPhases.size > 1) {
      if (idx === 0) {
        updatedSubPhases = subPhases.remove(idx).set(0, {
          startTenure,
          endTenure: nextSubPhase!.endTenure,
          startTenureError: null,
          endTenureError: null,
        });
        return handleSubPhasesChange(updatedSubPhases);
      }

      if (idx === subPhases.size - 1) {
        updatedSubPhases = subPhases.remove(idx).set(idx - 1, {
          startTenure: previousSubPhase!.startTenure,
          endTenure: currentSubPhase!.endTenure,
          startTenureError: null,
          endTenureError: null,
        });

        return handleSubPhasesChange(updatedSubPhases);
      }

      updatedSubPhases = subPhases.remove(idx).set(idx, {
        startTenure: previousSubPhase!.endTenure,
        endTenure: nextSubPhase!.endTenure,
        startTenureError: null,
        endTenureError: null,
      });

      return handleSubPhasesChange(updatedSubPhases);
    }
  };

  return (
    <PhaseContainer title={title} data-cy="phase_container" data-jest="phase_container">
      <div className="extra">
        {canRemove ? (
          <RemoveButton type="link" onClick={handleRemovePhaseClick} data-jest="remove_phase" 
            data-cy="remove_phaseButton" >
              <Trans>Remove</Trans>
          </RemoveButton>
        ) : (
          <Button icon="lock" type="link" data-cy="remove_phaseLock" disabled />
        )}
      </div>

      <FormRow gutter={32}>
        <Col md={10} lg={6}>
          <Form.Item help={nameError && <Trans>{nameError}</Trans>} validateStatus={nameError ? "error" : ""}>
            <DebouncedInput label="Name" value={name} name="name" data-cy="phase_name" 
              onValueChange={handleInputChange("name")} />
          </Form.Item>
        </Col>

        <Col md={6} lg={4}>
          <Form.Item>
            <DebouncedInput
              type="number"
              label="Start tenure (months)"
              value={getStartTenureValue()}
              name="startTenure"
              data-cy="start_tenure"
              onValueChange={handleInputChange("startTenure")}
              onWheel={handleWheelScroll}
              disabled={true}
            />
          </Form.Item>
        </Col>

        <Col md={6} lg={4}>
          <Form.Item help={not(isLast) && endTenureError && <Trans>{endTenureError}</Trans>} 
          validateStatus={!isLast && endTenureError ? "error" : ""}>
            <DebouncedInput
              type={isLast ? "text" : "number"}
              label="End tenure (months)"
              value={getEndTenureValue()}
              data-cy="end_tenure"
              name="endTenure"
              onValueChange={handleInputChange("endTenure")}
              onWheel={handleWheelScroll}
              disabled={isLast}
            />
          </Form.Item>
        </Col>
      </FormRow>

      <LifeCycleSubPhases
        subPhases={subPhases}
        isLastPhase={isLast}
        minSubPhases={minSubPhases}
        maxSubPhases={maxSubPhases}
        handleSubPhaseChange={handleSubPhaseChange}
        addSubPhase={addSubPhase}
        removeSubPhase={removeSubPhase}
      />
    </PhaseContainer>
  );
};

const RemoveButton = styled(Button)`
  color: ${Palette.darkPink} !important;
`;

const FormRow = styled(Row)`
  padding-top: 32px;
  padding-bottom: 32px;
`;

export default LifeCyclePhase;
