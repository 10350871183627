import React from "react";
import styled from "styled-components";

import { HeartIcon } from "@src/components/core/svg/Heart";
import { Paragraph } from "@src/components/core/Typography";
import Palette from "@src/config/theme/palette";

interface LikesProps {
  readonly totalLikes: number;
}

export function Likes({ totalLikes }: LikesProps) {
  return (
    <Container>
      <div>
        <HeartIcon viewBox="0 0 25 33" />
      </div>
      <Paragraph weight="light">{totalLikes}</Paragraph>
    </Container>
  );
}

const Container = styled.div`
  color: ${Palette.bluishGrey};
  display: flex;
  align-items: center;

  > div {
    height: 12px;
    width: 12px;
    svg {
      fill: currentColor;
    }
  }
  p {
    margin-left: 5px;
  }
`;

Container.displayName = "LikesContainer";
