import actionCreators, {
  NetworkEventErrorPayload,
  NetworkEventSuccessPayload,
  NetworkRequestAction
} from "@store/network/actions";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { BatarangDryRunResultsResource } from "hyphen-lib/dist/domain/resource/batarang/BatarangDryRunResultsResource";
import { BatarangApplyToken } from "hyphen-lib/dist/domain/resource/batarang/BatarangApplyToken";
import { BatarangApplyResultsResource } from "hyphen-lib/dist/domain/resource/batarang/BatarangApplyResultsResource";
import { FSA } from "flux-standard-action";
import { createRequest } from "@src/utils/networks";

export enum BatarangScriptsActionTypes {
  UPDATE_SCRIPT = "batarangScripts/UPDATE_SCRIPT",
  UPDATE_PARAMETER_VALUES = "batarangScripts/UPDATE_PARAMETER_VALUES",
  
  UPLOAD_ASSET_REQUEST = "batarangScripts/UPLOAD_ASSET_REQUEST",
  UPLOAD_ASSET_REQUEST_START = "batarangScripts/UPLOAD_ASSET_REQUEST_START",
  UPLOAD_ASSET_SUCCESS = "batarangScripts/UPLOAD_ASSET_SUCCESS",
  UPLOAD_ASSET_ERROR = "batarangScripts/UPLOAD_ASSET_ERROR",
  UPLOAD_ASSET_PROGRESS = "batarangScripts/UPLOAD_ASSET_PROGRESS",

  CANCEL = "batarangScripts/CANCEL",
  FINISH = "batarangScripts/FINISH",

  EXECUTE_IN_DRY_RUN_MODE_REQUEST = "batarangScripts/EXECUTE_IN_DRY_RUN_MODE_REQUEST",
  EXECUTE_IN_DRY_RUN_MODE_SUCCESS = "batarangScripts/EXECUTE_IN_DRY_RUN_MODE_SUCCESS",
  EXECUTE_IN_DRY_RUN_MODE_ERROR = "batarangScripts/EXECUTE_IN_DRY_RUN_MODE_ERROR",

  EXECUTE_REQUEST = "batarangScripts/EXECUTE_REQUEST",
  EXECUTE_SUCCESS = "batarangScripts/EXECUTE_SUCCESS",
  EXECUTE_ERROR = "batarangScripts/EXECUTE_ERROR",
}

interface BatarangScriptsActionPayloads {
  [BatarangScriptsActionTypes.UPDATE_SCRIPT]: { scriptName: string };
  [BatarangScriptsActionTypes.UPDATE_PARAMETER_VALUES]: { values: Dictionary<any> };
  [BatarangScriptsActionTypes.UPLOAD_ASSET_REQUEST]: {file: File};
  [BatarangScriptsActionTypes.UPLOAD_ASSET_REQUEST_START]: {file: File};
  [BatarangScriptsActionTypes.UPLOAD_ASSET_SUCCESS]: {assetId: string};
  [BatarangScriptsActionTypes.UPLOAD_ASSET_ERROR]: {fileName: string; error: any};
  [BatarangScriptsActionTypes.UPLOAD_ASSET_PROGRESS]: {fileName: string; progress: any};
  [BatarangScriptsActionTypes.CANCEL]: {};
  [BatarangScriptsActionTypes.FINISH]: {};

  [BatarangScriptsActionTypes.EXECUTE_IN_DRY_RUN_MODE_REQUEST]: { scriptName: string; parameters: Dictionary<any> };
  [BatarangScriptsActionTypes.EXECUTE_IN_DRY_RUN_MODE_SUCCESS]:
  NetworkEventSuccessPayload<BatarangDryRunResultsResource>;
  [BatarangScriptsActionTypes.EXECUTE_IN_DRY_RUN_MODE_ERROR]: NetworkEventErrorPayload;

  [BatarangScriptsActionTypes.EXECUTE_REQUEST]:
  { scriptName: string; parameters: Dictionary<any>; applyToken: BatarangApplyToken };
  [BatarangScriptsActionTypes.EXECUTE_SUCCESS]: NetworkEventSuccessPayload<BatarangApplyResultsResource>;
  [BatarangScriptsActionTypes.EXECUTE_ERROR]: NetworkEventErrorPayload;
}

// eslint-disable-next-line max-len
export interface UpdateScriptAction extends FSA<BatarangScriptsActionTypes.UPDATE_SCRIPT, BatarangScriptsActionPayloads[BatarangScriptsActionTypes.UPDATE_SCRIPT]> {
  type: BatarangScriptsActionTypes.UPDATE_SCRIPT;
  payload: BatarangScriptsActionPayloads[BatarangScriptsActionTypes.UPDATE_SCRIPT];
}

// eslint-disable-next-line max-len
export interface UpdateParameterValuesAction extends FSA<BatarangScriptsActionTypes.UPDATE_PARAMETER_VALUES, BatarangScriptsActionPayloads[BatarangScriptsActionTypes.UPDATE_PARAMETER_VALUES]> {
  type: BatarangScriptsActionTypes.UPDATE_PARAMETER_VALUES;
  payload: BatarangScriptsActionPayloads[BatarangScriptsActionTypes.UPDATE_PARAMETER_VALUES];
}

// eslint-disable-next-line max-len
export interface CancelAction extends FSA<BatarangScriptsActionTypes.CANCEL, BatarangScriptsActionPayloads[BatarangScriptsActionTypes.CANCEL]> {
  type: BatarangScriptsActionTypes.CANCEL;
  payload: BatarangScriptsActionPayloads[BatarangScriptsActionTypes.CANCEL];
}

// eslint-disable-next-line max-len
export interface UploadAssetRequestAction extends FSA<BatarangScriptsActionTypes.UPLOAD_ASSET_REQUEST, BatarangScriptsActionPayloads[BatarangScriptsActionTypes.UPLOAD_ASSET_REQUEST]> {
  type: BatarangScriptsActionTypes.UPLOAD_ASSET_REQUEST;
  payload: BatarangScriptsActionPayloads[BatarangScriptsActionTypes.UPLOAD_ASSET_REQUEST];
}

// eslint-disable-next-line max-len
export interface UploadAssetRequestStartAction extends FSA<BatarangScriptsActionTypes.UPLOAD_ASSET_REQUEST_START, BatarangScriptsActionPayloads[BatarangScriptsActionTypes.UPLOAD_ASSET_REQUEST_START]> {
  type: BatarangScriptsActionTypes.UPLOAD_ASSET_REQUEST_START;
  payload: BatarangScriptsActionPayloads[BatarangScriptsActionTypes.UPLOAD_ASSET_REQUEST_START];
}

// eslint-disable-next-line max-len
export interface UploadAssetSuccessAction extends FSA<BatarangScriptsActionTypes.UPLOAD_ASSET_SUCCESS, BatarangScriptsActionPayloads[BatarangScriptsActionTypes.UPLOAD_ASSET_SUCCESS]> {
  type: BatarangScriptsActionTypes.UPLOAD_ASSET_SUCCESS;
  payload: BatarangScriptsActionPayloads[BatarangScriptsActionTypes.UPLOAD_ASSET_SUCCESS];
}

// eslint-disable-next-line max-len
export interface UploadAssetProgressAction extends FSA<BatarangScriptsActionTypes.UPLOAD_ASSET_PROGRESS, BatarangScriptsActionPayloads[BatarangScriptsActionTypes.UPLOAD_ASSET_PROGRESS]> {
  type: BatarangScriptsActionTypes.UPLOAD_ASSET_PROGRESS;
  payload: BatarangScriptsActionPayloads[BatarangScriptsActionTypes.UPLOAD_ASSET_PROGRESS];
}

// eslint-disable-next-line max-len
export interface UploadAssetErrorAction extends FSA<BatarangScriptsActionTypes.UPLOAD_ASSET_ERROR, BatarangScriptsActionPayloads[BatarangScriptsActionTypes.UPLOAD_ASSET_ERROR]> {
  type: BatarangScriptsActionTypes.UPLOAD_ASSET_ERROR;
}

// eslint-disable-next-line max-len
export interface FinishAction extends FSA<BatarangScriptsActionTypes.FINISH, BatarangScriptsActionPayloads[BatarangScriptsActionTypes.FINISH]> {
  type: BatarangScriptsActionTypes.FINISH;
  payload: BatarangScriptsActionPayloads[BatarangScriptsActionTypes.FINISH];
}

export interface ExecuteInDryRunModeRequestAction
  extends FSA<BatarangScriptsActionTypes.EXECUTE_IN_DRY_RUN_MODE_REQUEST,
  BatarangScriptsActionPayloads[BatarangScriptsActionTypes.EXECUTE_IN_DRY_RUN_MODE_REQUEST]> {
  type: BatarangScriptsActionTypes.EXECUTE_IN_DRY_RUN_MODE_REQUEST;
  payload: BatarangScriptsActionPayloads[BatarangScriptsActionTypes.EXECUTE_IN_DRY_RUN_MODE_REQUEST];
}

export interface ExecuteInDryRunModeSuccessAction
  extends FSA<BatarangScriptsActionTypes.EXECUTE_IN_DRY_RUN_MODE_SUCCESS,
  BatarangScriptsActionPayloads[BatarangScriptsActionTypes.EXECUTE_IN_DRY_RUN_MODE_SUCCESS]> {
  type: BatarangScriptsActionTypes.EXECUTE_IN_DRY_RUN_MODE_SUCCESS;
  payload: BatarangScriptsActionPayloads[BatarangScriptsActionTypes.EXECUTE_IN_DRY_RUN_MODE_SUCCESS];
}

export interface ExecuteInDryRunModeErrorAction
  extends FSA<BatarangScriptsActionTypes.EXECUTE_IN_DRY_RUN_MODE_ERROR,
  BatarangScriptsActionPayloads[BatarangScriptsActionTypes.EXECUTE_IN_DRY_RUN_MODE_ERROR]> {
  type: BatarangScriptsActionTypes.EXECUTE_IN_DRY_RUN_MODE_ERROR;
  payload: BatarangScriptsActionPayloads[BatarangScriptsActionTypes.EXECUTE_IN_DRY_RUN_MODE_ERROR];
}

export interface ExecuteRequestAction
  extends FSA<BatarangScriptsActionTypes.EXECUTE_REQUEST,
  BatarangScriptsActionPayloads[BatarangScriptsActionTypes.EXECUTE_REQUEST]> {
  type: BatarangScriptsActionTypes.EXECUTE_REQUEST;
  payload: BatarangScriptsActionPayloads[BatarangScriptsActionTypes.EXECUTE_REQUEST];
}

export interface ExecuteSuccessAction
  extends FSA<BatarangScriptsActionTypes.EXECUTE_SUCCESS,
  BatarangScriptsActionPayloads[BatarangScriptsActionTypes.EXECUTE_SUCCESS]> {
  type: BatarangScriptsActionTypes.EXECUTE_SUCCESS;
  payload: BatarangScriptsActionPayloads[BatarangScriptsActionTypes.EXECUTE_SUCCESS];
}

export interface ExecuteErrorAction
  extends FSA<BatarangScriptsActionTypes.EXECUTE_ERROR,
  BatarangScriptsActionPayloads[BatarangScriptsActionTypes.EXECUTE_ERROR]> {
  type: BatarangScriptsActionTypes.EXECUTE_ERROR;
  payload: BatarangScriptsActionPayloads[BatarangScriptsActionTypes.EXECUTE_ERROR];
}

function executeInDryRunMode(scriptName: string, parameters: Dictionary<any>) {
  return createRequest(
    `/batarang/scripts/${scriptName}/dryRun`,
    {
      method: "POST",
      body: JSON.stringify({
        parameters,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

function execute(scriptName: string, parameters: Dictionary<any>, applyToken: BatarangApplyToken) {
  return createRequest(
    `/batarang/scripts/${scriptName}/apply`,
    {
      method: "POST",
      body: JSON.stringify({
        parameters,
        applyToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

export const batarangScriptsActionCreators = {
  updateScript: (scriptName: string): UpdateScriptAction => ({
    type: BatarangScriptsActionTypes.UPDATE_SCRIPT,
    payload: {
      scriptName,
    },
  }),

  updateParameterValues: (values: Dictionary<any>): UpdateParameterValuesAction => ({
    type: BatarangScriptsActionTypes.UPDATE_PARAMETER_VALUES,
    payload: {
      values,
    },
  }),

  uploadAssetRequestAction: (file: File) => ({
    type: BatarangScriptsActionTypes.UPLOAD_ASSET_REQUEST,
    payload: {file},
  }),

  cancel: (): CancelAction => ({
    type: BatarangScriptsActionTypes.CANCEL,
    payload: {},
  }),

  finish: (): FinishAction => ({
    type: BatarangScriptsActionTypes.FINISH,
    payload: {},
  }),

  executeInDryRunMode: (scriptName: string, parameters: Dictionary<any>): NetworkRequestAction =>
    actionCreators.networkRequestWithActions({
      request: executeInDryRunMode(scriptName, parameters),
      requestAction: BatarangScriptsActionTypes.EXECUTE_IN_DRY_RUN_MODE_REQUEST,
      successAction: BatarangScriptsActionTypes.EXECUTE_IN_DRY_RUN_MODE_SUCCESS,
      errorAction: BatarangScriptsActionTypes.EXECUTE_IN_DRY_RUN_MODE_ERROR,
      options: {
        noCache: true,
      },
    }),

  execute: (scriptName: string, parameters: Dictionary<any>, applyToken: BatarangApplyToken): NetworkRequestAction =>
    actionCreators.networkRequestWithActions({
      request: execute(scriptName, parameters, applyToken),
      requestAction: BatarangScriptsActionTypes.EXECUTE_REQUEST,
      successAction: BatarangScriptsActionTypes.EXECUTE_SUCCESS,
      errorAction: BatarangScriptsActionTypes.EXECUTE_ERROR,
      options: {
        noCache: true,
      },
      meta: scriptName
    }),
};
