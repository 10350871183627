import React, { Component } from "react";
import Checkbox from "@components/core/Checkbox";
import { Trans, withTranslation } from "react-i18next";
import ContainerCard from "@components/core/ContainerCard";
import Input from "@src/components/core/Input";
import Select from "@components/core/Select";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";
import withDebounce from "@src/components/core/withDebounce";
import { AppSettingsResource } from "hyphen-lib/dist/domain/resource/AppSettingsResource";
import { Divider } from "antd";
import { InputProps } from "antd/lib/input";
import { Paragraph } from "@src/components/core/Typography";
import { isNotNullNorUndefined, mapOr } from "hyphen-lib/dist/lang/Objects";
import { isEqual, uniq, map } from "lodash";
import { SelectProps } from "antd/lib/select";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { not } from "hyphen-lib/dist/lang/Booleans";
import Button from "@src/components/core/Button";
import { GlobalNotification } from "../../MainNavigation";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";

// @ts-ignore
const NewInput = withDebounce(Input);
const { Option } = Select;

export interface AppSettingsInsightsProps {
  readonly appSettings: AppSettingsResource;
  readonly onValueChange: (values: AppSettingsResource) => void;
  readonly notificationMessage: string;
  readonly onCloseNotification: () => void;
}

interface AppSettingsInsightsStates {
  errorInSegmentSizeForAnonymity: boolean;
  defaultBenchmarkOptions: string[];
}

const MINIMUM_ANONYMITY = 3;

class HyphenInsights extends Component<AppSettingsInsightsProps, AppSettingsInsightsStates> {
  constructor(props: AppSettingsInsightsProps) {
    super(props);
    this.state = {
      errorInSegmentSizeForAnonymity: false,
      defaultBenchmarkOptions: [],
    };
  }

  selectedBenchmarksByYear: Dictionary<string[]> = {};

  updateEmployeeCountForAnonymity = (employeeCount: string): void => {
    const count = Number(employeeCount);
    // fixme: we should use the checker from hyphen-lib, this is pity to write/maintain same code twice!
    // noinspection UnnecessaryLocalVariableJS
    const minForAnonymity = MINIMUM_ANONYMITY;

    if (Number.isNaN(count) || !Number.isInteger(count) || count < minForAnonymity) {
      this.setState({
        errorInSegmentSizeForAnonymity: true,
      });
    } else {
      this.setState({
        errorInSegmentSizeForAnonymity: false,
      });

      this.props.onValueChange({
        ...this.props.appSettings,
        segmentSizeForAnonymity: count,
      });
    }
  };

  handleBenchmarkTargetsChange = (year: string) => (selectedBenchmarkTargetsForYear: any) => {
    this.selectedBenchmarksByYear[year] = selectedBenchmarkTargetsForYear;
    let selectedBenchmarkTargets: string[] = [];

    // merging all year benchmarks
    Object.values(this.selectedBenchmarksByYear).forEach(benchmarks => {
      selectedBenchmarkTargets = [...selectedBenchmarkTargets, ...benchmarks];
    });

    const { appSettings, onValueChange } = this.props;
    const newSelectedBenchmarkTargets = Array.isArray(selectedBenchmarkTargets)
      ? selectedBenchmarkTargets
      : [selectedBenchmarkTargets];

    const newSelectedBenchmarkTargetsObject = isNotNullNorUndefined(appSettings.allBenchmarkTargets)
      ? appSettings.allBenchmarkTargets.filter(benchmark => newSelectedBenchmarkTargets.indexOf(benchmark.label) >= 0)
      : [];

    const selectedIndustries = newSelectedBenchmarkTargetsObject.map(benchmark => benchmark.key);

    // Check if benchmark default should be updated
    let newBenchmarkDefault = "";

    if (isNotNullNorUndefined(appSettings.benchmarkDefault)) {
      if (selectedIndustries.indexOf(appSettings.benchmarkDefault) >= 0) {
        // The selected benchmark default is valid
        newBenchmarkDefault = appSettings.benchmarkDefault;
      } else {
        // Reset the benchmarkDefault
        if (newSelectedBenchmarkTargets.length > 0) {
          newBenchmarkDefault = mapOr(newSelectedBenchmarkTargetsObject, obj => obj[0].label, "");
        }
      }
    }

    onValueChange({
      ...this.props.appSettings,
      benchmarkTargets: newSelectedBenchmarkTargetsObject,
      benchmarkDefault: newBenchmarkDefault,
    });
  };

  handleBenchmarkTargetYearChange = (year: string) => (e: any) => {
    const { appSettings, onValueChange } = this.props;
    const appSettingsValueChange = {
      ...appSettings,
      benchmarkTargetYears: {...appSettings.benchmarkTargetYears, [year]: e.target.checked},
    };

    if (not(e.target.checked) && isNotNullNorUndefined(appSettings.benchmarkTargets)) {
      appSettingsValueChange.benchmarkTargets = 
        appSettings.benchmarkTargets?.filter(benchmark => benchmark.dimensions.year !== year);
    }

    onValueChange(appSettingsValueChange);
  };

  componentDidMount() {
    this.manageBenchmarks();
  }

  componentDidUpdate() {
    this.manageBenchmarks();
  }

  manageBenchmarks = () => {
    const { appSettings } = this.props;
    const newBenchmarkOptions = isNotNullNorUndefined(appSettings.benchmarkTargets)
      ? appSettings.benchmarkTargets
        .map(benchmark => benchmark.label)
        .filter((elem, pos, arr) => {
          return arr.indexOf(elem) === pos;
        })
        .sort()
      : [];

    if (!isEqual(newBenchmarkOptions, this.state.defaultBenchmarkOptions)) {
      this.setState({ defaultBenchmarkOptions: newBenchmarkOptions });
    }

    if (isNotNullNorUndefined(appSettings.benchmarkTargets)) {
      const years = uniq(map(appSettings.benchmarkTargets, "dimensions.year"));
  
      years.forEach(year => {
        this.selectedBenchmarksByYear[year] = appSettings.benchmarkTargets!
          .filter(benchmark => benchmark.dimensions.year === year)
          .map(value => value.label);
      });
    }
  };

  handleBenchmarkDefaultChange = (value: any) => {
    this.props.onValueChange({
      ...this.props.appSettings,
      benchmarkDefault: value,
    });
  };

  onCloseNotification = () => {
    this.props.onCloseNotification();
  };
  renderBenchmarksSetting = (year: string) => {
    const { appSettings } = this.props;
    return (
      <BenchmarkHolder key={year}>
        <BenchmarkCheckboxHolder>
          <CheckHolder>
            <StyledDiv>
              <Checkbox
                checked={
                  isNotNullNorUndefined(appSettings.benchmarkTargetYears)
                    ? appSettings.benchmarkTargetYears[year]
                    : true 
                }
                // tslint:disable-next-line:jsx-no-lambda
                onChange={this.handleBenchmarkTargetYearChange(year) }
              />
            </StyledDiv>
            <div>
              <Paragraph><Trans>Use</Trans> {year} <Trans>benchmarks</Trans></Paragraph>
            </div>
          </CheckHolder>
        </BenchmarkCheckboxHolder>

        <Holder>
          {
            isNotNullNorUndefined(appSettings.benchmarkTargetYears) 
            && not(appSettings.benchmarkTargetYears[year]) ? (
                <BenchmarkHintHolder>
                  <Paragraph weight="light">
                  <Trans>Select</Trans> <BenchmarkHintLabel> <Trans>Use</Trans>
                  {year} <Trans>benchmarks</Trans> </BenchmarkHintLabel>
                  <Trans>to add</Trans> {year} <Trans>benchmarks</Trans>
                  </Paragraph>
                </BenchmarkHintHolder>
              ) : (
                <div>
                  <Paragraph><Trans>Select</Trans> {year} <Trans>benchmark indices</Trans></Paragraph>
                  <StyledBenchmarkSelect
                    mode="multiple"
                    onChange={this.handleBenchmarkTargetsChange(year)}
                    value={
                      isNotNullNorUndefined(appSettings.benchmarkTargets)
                        ? appSettings.benchmarkTargets
                          .filter(obj => obj.dimensions.year === year)
                          .map(obj => obj.label)
                        : []
                    }
                  >
                    { 
                      isNotNullNorUndefined(appSettings.allBenchmarkTargets) 
                    && appSettings.allBenchmarkTargets
                      .filter(benchmark => benchmark.dimensions.year === year)
                      .map(obj => (
                        <Option key={obj.label} value={obj.label}>
                          {obj.label}
                        </Option>
                      ))
                    }
                  </StyledBenchmarkSelect>
                </div>
              )
          }
        </Holder>
      </BenchmarkHolder>
    );
  };

  getBenchmarkAvailableYears = () => {
    const { appSettings } = this.props;
    return uniq(map(appSettings.allBenchmarkTargets, "dimensions.year"))
      .sort((a, b) => { return b - a; });
  };
  
  render() {
    const { appSettings, onValueChange, notificationMessage } = this.props;
    const years = this.getBenchmarkAvailableYears();
    return (
      <StyledContainerCard title="Betterworks Engage Insights">
          {isStringAndNotEmpty(notificationMessage) && (
              <StyledNotificationWrapper>
                <GlobalNotification 
                  showIcon 
                  message={<Trans>{notificationMessage}</Trans>} 
                  type="warning" 
                  description={<Button onClick={this.onCloseNotification} color="grey" 
                                translate="yes" size="small">Ok, Got it</Button>}
                />
              </StyledNotificationWrapper>
        )}
        <Holder>
          <Paragraph translate="yes">Anonymise data in segments containing less than</Paragraph>
          <TextHolder>
            <TextInput
              // @ts-ignore
              // FIXME: onValueChange is present as a prop in withDebounce HOC
              onValueChange={this.updateEmployeeCountForAnonymity}
              suffix={<StyledDiv><Trans>respondents</Trans></StyledDiv>}
              value={appSettings.segmentSizeForAnonymity}
            />
            {this.state.errorInSegmentSizeForAnonymity &&
                <Warning><Trans>Please enter a value greater than</Trans> {MINIMUM_ANONYMITY}</Warning>
            }
          </TextHolder>
          <Paragraph weight="light">
              <Trans>If one of your segments contains less than entered respondents you won't be 
              able to see any data for it in segmented reports.</Trans>
          </Paragraph>
        </Holder>
        <CheckHolder>
          <StyledDiv>
            <Checkbox
              checked={appSettings.shouldDisplayCompanyAverages}
              // tslint:disable-next-line:jsx-no-lambda
              onChange={(e: any) =>
                onValueChange({
                  ...appSettings,
                  shouldDisplayCompanyAverages: e.target.checked,
                })
              }
            />
          </StyledDiv>
          <div>
            <Paragraph translate="yes">Display company averages</Paragraph>
            <Paragraph weight="light" translate="yes">
              Displaying company averages will give access to historical data across the entire company in Insights.
            </Paragraph>
          </div>
        </CheckHolder>
        <StyledDivider />
        <Paragraph translate="yes">Select benchmark years</Paragraph>
        {/* Select bench marks */}
        { 
          years.map(this.renderBenchmarksSetting)
        }
        <Holder>
          <Paragraph translate="yes">Select default benchmark</Paragraph>
          <StyledBenchmarkSelect
            disabled={this.state.defaultBenchmarkOptions.length < 2} // 1 or 0 options hence disabled
            onChange={this.handleBenchmarkDefaultChange}
            value={appSettings.benchmarkDefault}
          >
            {this.state.defaultBenchmarkOptions.map(benchmarkOption => (
              <Option key={benchmarkOption} value={benchmarkOption}>
                {benchmarkOption}
              </Option>
            ))}
          </StyledBenchmarkSelect>
        </Holder>
      </StyledContainerCard>
    );
  }
}

export default withTranslation()(HyphenInsights);

const TextInput = styled(NewInput)<InputProps>`
  margin-top: 24px !important;
`;
const Holder = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const BenchmarkHolder = styled.div`
  display: inline-block;
  width: 370px;
`;

const BenchmarkHintHolder = styled.div`
  text-align: center;
  padding-right:80px;
  box-sizing: border-box;
`;

const BenchmarkHintLabel = styled.span`
  color: ${Palette.veryDarkBlueGrey};
`;

const BenchmarkCheckboxHolder = styled.div`
  display: flex;
  flex-direction: column;
`;

const Warning = styled.p`
  color: ${Palette.darkPink};
`;

export const StyledSelect = styled(Select)<SelectProps & {children: React.ReactNode}>`
  width: 266px;
`;

export const StyledBenchmarkSelect = styled(Select)<SelectProps & {children: React.ReactNode}>`
  width: 360px;
`;

const CheckHolder = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-right: 20px;
`;

const StyledDivider = styled(Divider)`
  margin-top: 40px;
  margin-bottom: 40px;
`;

const StyledNotificationWrapper = styled.div`
  position: absolute;
  right: 0;
  top: -24px;
  width: 550px;
  .ant-alert-description {
    text-align: center
  }
`;

const StyledContainerCard = styled(ContainerCard)`
  margin-bottom: 24px;
  input:focus {
    outline: none;
  }
  position: relative;
`;

const TextHolder = styled.div`
  max-width: 266px;
`;

const StyledDiv = styled.div`
  color: #aab9ce;
  margin-right: 10px;
`;