import React from "react";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps } from "react-router";

import { parseQueryString } from "@hyphen-lib/util/net/HttpClient";
import { State } from "@store/types";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import {
  getCompany,
  getCurrentUser,
  getDimensions,
  getRightsMatcher,
} from "@screens/Insights/store/selectors";
import {
  generateCompareWithOptions,
  getAvailableComparisons,
} from "@src/utils/Comparisons";
import { not } from "hyphen-lib/dist/lang/Booleans";
import {
  areEquals,
  isEmptyObject,
  isNotEmptyObject,
  isNotNullNorUndefined,
  isNullOrUndefined,
  keys,
} from "hyphen-lib/dist/lang/Objects";
import { Map as ImmutableMap } from "immutable";
import { goTo, replaceLocation } from "@src/utils/locations";
import { applyExistingParametersIfNeeded } from "@src/utils/parameters";
import { getParameters } from "@screens/Insights/parameters/store/selectors";
import {
  checkForActionPlanCreationRight,
  checkForSurveyReportRight,
} from "@src/utils/rights";
import { getAnonymityThreshold } from "@screens/Insights/Settings/store/selectors";
import { Participation } from "hyphen-lib/dist/domain/common/Participation";
import { fetchOverviewReportIfNeeded } from "@store/network/resource/OverviewReportResources";
import { getResourceById } from "@store/network/selectors";
import { OverviewReportResource } from "hyphen-lib/dist/domain/resource/survey/report/OverviewReportResource";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { FetchError } from "@src/screens/Insights/errors/FetchError";
import { fetchSurveyTrendReportIfNeeded } from "@src/store/network/resource/SurveyTrendResource";
import { Loadable } from "hyphen-lib/dist/util/net/Loadable";
import { TrendResource } from "hyphen-lib/dist/domain/resource/survey/report/TrendResource";
import OverviewReport from "../../components/OverviewReport";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";
import {
  focusAreaListActionCreators,
  FocusAreaListPageParameters,
} from "@src/screens/Insights/Actions/store/actions";
import { fetchFocusAreasIfNeeded } from "@src/store/network/resource/ActionResources";
import { isEmpty } from "hyphen-lib/dist/lang/Arrays";
import {
  getAllFocusAreasFromReduxStore,
  getCurrentBottomDrawerView,
  isFARequestComplete,
} from "@src/utils/FocusArea";
import BottomDrawer, { DrawerViews } from "@src/components/core/BottomDrawer";
import BottomDrawerStripedChild, {
  transformFocusAreasToStripStack,
} from "@src/components/core/BottomDrawer/StripedChild";
import { BottomDrawerMessages } from "@src/screens/Insights/BottomDrawer/constant";
import { hasFocusAreaRights } from "hyphen-lib/dist/business/focusArea/focusAreaResource";
import Spin from "@src/components/core/Spin";
import { fetchSurveyParticipationIfNeeded } from "@store/network/resource/ParticipationReportResources";
import { ParticipationReportResource } from "hyphen-lib/dist/domain/resource/survey/report/ParticipationReportResource";


interface MatchParams {
  id: string;
}

export interface OwnProps extends RouteComponentProps<MatchParams> {
  readonly surveyId: string;
  readonly surveyName: string;
  readonly participation: Participation;
  readonly hasTopicsAccess?: boolean;
  readonly postAndSurveySentiment?: boolean;
}

export interface OverviewReportContainerStateProps {
  readonly overview: Store.Element<OverviewReportResource>;
  readonly dimensions: Optional<Dimensions>;
  readonly company: CompanyResource;
  readonly parameters: ImmutableMap<string, any>;
  readonly hasActionCreationRight: boolean;
  readonly anonymityThreshold: number;
  readonly hasCommentsAccess: boolean;
  readonly hasTopicsAccess: boolean;
  readonly surveyTrend: Loadable<TrendResource>;
  readonly focusAreas?: FocusAreaResource[];
  readonly currentUser: Optional<CurrentUserResource>;
  readonly currentBottomDrawerView: DrawerViews;
  readonly hasFaRights: boolean;
  readonly completedFARequest: boolean;
  readonly filteredParticipantData: Participation;
}

interface OverviewReportContainerActionProps {
  readonly onFetchOverviewReport: (
    surveyId: string,
    queryString: Dictionary<any>
  ) => void;
  readonly onFetchLinkedSurveyTrend: (
    surveyId: string,
    queryString: Dictionary<any>
  ) => void;
  readonly onFetchFocusArea: (parameters: FocusAreaListPageParameters) => any;
  readonly onCreateFocusArea: (focusArea: Partial<FocusAreaResource>) => void;
  readonly onFetchParticipation: (surveyId: string, queryString: Dictionary<any>) => void;
}

type Props = OwnProps &
  OverviewReportContainerStateProps &
  OverviewReportContainerActionProps;

interface OverviewReportContainerState {
  totalFocusAreas: number;
}

export class OverviewReportContainer extends React.Component<
  Props,
  OverviewReportContainerState
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      totalFocusAreas: 0,
    };
  }
  // noinspection JSMethodCanBeStatic
  extractParametersFromQuery(props: Props) {
    return this.extractRelevantParametersForQuery(
      parseQueryString(props.location.search)
    );
  }

  // noinspection JSMethodCanBeStatic
  extractRelevantParametersForQuery(parameters: any) {
    const relevantParameters = { ...parameters };
    delete relevantParameters.viewOptions;
    return relevantParameters;
  }

  componentDidMount(): void {
    // we might need to apply persisted parameters
    const {
      parameters,
      location: { search },
      focusAreas,
    } = this.props;

    const existing = parseQueryString(search);
    const mergedParameters = applyExistingParametersIfNeeded(
      parameters.toJS(),
      existing,
      "filter.dimensions",
      "viewOptions.comparison",
      "viewOptions.compareWith"
    );

    if (
      isNotNullNorUndefined(mergedParameters) &&
      isNotEmptyObject(mergedParameters)
    ) {
      replaceLocation(mergedParameters);

      if (
        isEmptyObject(this.extractRelevantParametersForQuery(mergedParameters))
      ) {
        // do the fetch now, otherwise we will not do it later, as componentDidUpdate will not see
        // any relevant changes, and not do any fetch
        this.fetchSurveyOverview();
      }
    } else {
      // fetch the overview only if we will stay on this page,
      // otherwise it will be fetched anyway in componentDidUpdate
      this.fetchSurveyOverview();
    }

    if (
      (isNullOrUndefined(focusAreas) || isEmpty(focusAreas)) &&
      this.props.hasFaRights
    ) {
      this.props.onFetchFocusArea(this.fetchFocusAreaProps());
    }

    let totalFocusAreas = 0;
    if (isNotNullNorUndefined(this.props.focusAreas)) {
      totalFocusAreas = this.props.focusAreas.length;
    }

    this.setState({
      totalFocusAreas,
    });
  }

  fetchFocusAreaProps(): FocusAreaListPageParameters {
    return {
      page: {
        number: 1,
        size: 30,
      },
      filter: {
        withActionPlans: false,
      },
    };
  }

  componentDidUpdate(prevProps: Props) {
    const oldParams = this.extractParametersFromQuery(prevProps);
    const newParams = this.extractParametersFromQuery(this.props);
    if (
      (this.props.surveyId !== prevProps.surveyId ||
        not(areEquals(oldParams, newParams))) &&
      not(Store.Element.isInError(this.props.overview))
    ) {
      this.fetchSurveyOverview();
    }

    if (this.props.hasFaRights) {
      this.props.onFetchFocusArea(this.fetchFocusAreaProps());
    }

    if (
      isNotNullNorUndefined(this.props.focusAreas) &&
      isNotNullNorUndefined(prevProps.focusAreas)
    ) {
      if (this.props.focusAreas.length > prevProps.focusAreas.length) {
        this.setState({
          totalFocusAreas: this.props.focusAreas.length,
        });
      }
    }
  }

  fetchSurveyOverview = () => {
    const { surveyId } = this.props;
    const queryParams = this.extractParametersFromQuery(this.props);
    this.props.onFetchParticipation(surveyId, queryParams);
    this.props.onFetchOverviewReport(surveyId, queryParams);
    this.props.onFetchLinkedSurveyTrend(surveyId, queryParams);
  };

  render() {
    const {
      surveyId,
      surveyName,
      participation,
      overview,
      dimensions,
      company,
      hasActionCreationRight,
      anonymityThreshold,
      hasCommentsAccess,
      hasTopicsAccess,
      surveyTrend,
      onCreateFocusArea,
      focusAreas,
      hasFaRights,
      completedFARequest,
      postAndSurveySentiment,
      filteredParticipantData,
    } = this.props;

    const queryParams = this.extractParametersFromQuery(this.props);

    const compareWithOptions = generateCompareWithOptions(
      Store.Element.mapIfLoadedOr(overview, getAvailableComparisons, {}),
      company
    );
    const isFiltersAvailable = not(keys(queryParams).includes("filter"));
    if (Store.Element.isInError(overview)) {
      return (
        <FetchError {...overview} resourceType={OverviewReportResource.TYPE} />
      );
    }

    return (
      <>
        <Spin
          size="large"
          className="sticky-top"
          spinning={!completedFARequest}
        >
          <OverviewReport
            surveyName={surveyName}
            participation={participation}
            isFiltersAvailable={isFiltersAvailable}
            overview={Store.Element.toLoadable(overview)}
            dimensions={dimensions}
            surveyId={surveyId}
            enabledFilters={["dimension"]}
            enabledCustomFilters={["addDimension"]}
            enabledViewOptions={["comparison", "compareWith"]}
            compareWithOptions={compareWithOptions}
            hasActionCreationRight={hasActionCreationRight}
            anonymityThreshold={anonymityThreshold}
            hasCommentsAccess={hasCommentsAccess}
            surveyTrend={surveyTrend}
            onCreateFocusArea={onCreateFocusArea}
            hasTopicsAccess={hasTopicsAccess}
            postAndSurveySentiment={postAndSurveySentiment}
            companyName={company.name}
            filteredParticipantData={filteredParticipantData}
          />
          {hasFaRights && (
            <BottomDrawer
              key="segment-drawer"
              initialView={this.props.currentBottomDrawerView}
              title={`${
                this.state.totalFocusAreas === 0
                  ? "No"
                  : this.state.totalFocusAreas
              } Focus Area Added`}
              footerButton={{
                display: true,
                text: "Go To Action Center",
                onClick: () => goTo("/actioncenter"),
              }}
            >
              <BottomDrawerStripedChild
                stripStack={transformFocusAreasToStripStack(focusAreas)}
                emptyStackMessage={BottomDrawerMessages.EMPTY_STACK_MESSAGE}
              />
            </BottomDrawer>
          )}
        </Spin>
      </>
    );
  }
}

const mapStateToProps: MapStateToProps<
  OverviewReportContainerStateProps,
  OwnProps,
  State
> = (state: State, ownProps: OwnProps): OverviewReportContainerStateProps => {
  const data = getAllFocusAreasFromReduxStore(state);
  const currentUser = getCurrentUser(state);
  const currentBottomDrawerView = getCurrentBottomDrawerView(state);
  const hasFaRights = hasFocusAreaRights(getRightsMatcher(state));
  const participations = getResourceById(
    state,
    ParticipationReportResource.TYPE,
    ParticipationReportResource.generateId(
      ownProps.match.params.id,
      parseQueryString(ownProps.location.search).filter
    )
  );
  const filteredParticipantData = Store.Element.mapIfLoadedOr(
    participations,
    participation => participation.company,
    { total: 0, completed: 0 }
  );
  return {
    focusAreas: data,
    currentBottomDrawerView,
    currentUser,
    overview: getResourceById(
      state,
      OverviewReportResource.TYPE,
      OverviewReportResource.generateId(
        ownProps.surveyId,
        parseQueryString(ownProps.location.search).filter
      )
    ),
    surveyTrend: Store.Element.toLoadable(
      getResourceById(
        state,
        TrendResource.TrendTypeEnum.SURVEY_TREND,
        TrendResource.generateId(
          ownProps.surveyId,
          parseQueryString(ownProps.location.search).filter
        )
      )
    ),
    dimensions: getDimensions(state),
    company: getCompany(state)!,
    parameters: getParameters(state),
    hasActionCreationRight: checkForActionPlanCreationRight(state),
    anonymityThreshold: getAnonymityThreshold(state),
    hasCommentsAccess: checkForSurveyReportRight(
      getRightsMatcher(state),
      "comments"
    ),
    hasTopicsAccess: checkForSurveyReportRight(
      getRightsMatcher(state),
      "topics"
    ),
    hasFaRights,
    completedFARequest: isFARequestComplete(state),
    filteredParticipantData,
  };
};

const mapDispatchToProps = {
  onFetchOverviewReport: fetchOverviewReportIfNeeded,
  onFetchLinkedSurveyTrend: fetchSurveyTrendReportIfNeeded,
  onFetchFocusArea: fetchFocusAreasIfNeeded,
  onCreateFocusArea: focusAreaListActionCreators.createFocusArea,
  onFetchParticipation: fetchSurveyParticipationIfNeeded,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OverviewReportContainer);
