import React from "react";
import { Col, Form, Row } from "antd";
import styled from "styled-components";
import { Trans, WithTranslation, withTranslation } from "react-i18next";
import { InputProps } from "antd/lib/input";
import { FormProps } from "antd/lib/form/Form";
import { SelectProps, SelectValue } from "antd/lib/select";
import ContainerCard from "@components/core/ContainerCard";
import Input from "@components/core/Input";
import withDebounce, { WithDebounceProps } from "@components/core/withDebounce";
import Checkbox from "@components/core/Checkbox";
import ImageUpload from "@components/core/ImageUpload";
import LabelMapper from "@src/utils/constants/SurveyCreation";
import { SurveyTypeResource } from "hyphen-lib/dist/domain/resource/SurveyTypeResource";
import { getOr, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { SurveyResource } from "hyphen-lib/dist/domain/resource/SurveyResource";
import { Survey } from "hyphen-lib/dist/domain/Survey";
import Select from "@components/core/Select";
import Asset from "hyphen-lib/dist/domain/Asset";
import { Map as ImmutableMap } from "immutable";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { MergeTagResource } from "hyphen-lib/dist/domain/resource/MergeTagResource";
import SurveySettingsModalContent from "@screens/Insights/Surveys/components/SurveySettingsModalContent";
import { Paragraph } from "@components/core/Typography";
import Palette from "@src/config/theme/palette";
import Modal from "@components/core/Modal";
import { ModalProps } from "antd/lib/modal";
import {
  TextWithMergeTags,
  TextWithMergeTagsProps,
} from "@components/core/TextWithMergeTags";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import { toHumanReadableErrorMessage } from "@src/utils/formValidations";
import {
  getLanguageCodeFromString,
  LocaleCodes,
} from "hyphen-lib/dist/util/locale";
import { getLanguagesForSelectBox } from "../../Xlsx/util";
import { MultiLanguageFooterStrip } from "./MultiLanguageFooterStrip";
import Button from "@components/core/Button";
import {
  getLanguageInformationFromStringifiedTranslation,
  getTranslatedSegmentFromStringifiedTranslation,
  LabelWithFlag,
} from "@src/utils/translation";

const { Option } = Select;

// @ts-ignore
const DebouncedInput = withDebounce(Input);

export interface GeneralSettingsFormValues {
  readonly name: string;
  readonly type: Survey.Type;
  readonly logo?: Optional<Asset>;
  readonly introductionText: string;
  readonly isAnonymous: boolean;
  readonly allowSkipQuestion: boolean;
  readonly introductionText_t?: string;
  readonly surveyLanguages?: string[];
  readonly translatedLanguages?: string[];
}

export type GeneralSettingsFormDisabledField = Partial<
  {
    [T in keyof GeneralSettingsFormValues]: boolean;
  }
>;

export interface GeneralSettingsFormProps extends WithTranslation{
  readonly values: GeneralSettingsFormValues;
  readonly allowedTypes: SurveyTypeResource[];
  readonly allowedMergeTags: MergeTagResource[];
  readonly errors: ImmutableMap<string, WrongEntityException.WrongField>;
  readonly disabled?: GeneralSettingsFormDisabledField;
  readonly configuredCompanyLanguages: string[];
  readonly configuredSurveylanguages: string[];
  readonly isMultiLanguageSurveyEnabled: boolean;

  readonly onChange: (values: Partial<SurveyResource>, sync?: boolean) => void;
  readonly onUploadSurveyLogo: (err: any, file: any) => void;
}

interface GeneralSettingsFormState {
  readonly isSurveyTypeModalOpen: boolean;
  readonly isSurveyLanguagesModalOpen: boolean;
  readonly editSurveyIntroduction: boolean;
  readonly translationMap: Record<string, string>;
}

class GeneralSettingsFormComponent extends React.Component<
  GeneralSettingsFormProps,
  GeneralSettingsFormState
> {
  constructor(props: GeneralSettingsFormProps) {
    super(props);

    const tMap: Record<string, string> = {};
    this.props.configuredSurveylanguages.forEach((code) => {
      tMap[code] = this.getTranslatedLanguageValueFromProps(
        code as LocaleCodes
      );
    });

    this.state = {
      isSurveyTypeModalOpen: false,
      isSurveyLanguagesModalOpen: false,
      editSurveyIntroduction: false,
      translationMap: tMap,
    };
  }

  removeImage = () => {
    this.props.onChange({
      logo: undefined,
    });
  };

  updateTextField =
    (fieldName: keyof GeneralSettingsFormValues) => (value: string) => {
      this.props.onChange({
        [fieldName]: value,
      });
    };

  createSurveyTypeModalStateHandler =
    (forcedState?: Optional<boolean>) => () => {
      this.setState((oldState) => ({
        isSurveyTypeModalOpen: isNotNullNorUndefined(forcedState)
          ? forcedState
          : !oldState.isSurveyTypeModalOpen,
      }));
    };

  getErrorMessageForField = (fieldName: string): Optional<string> => {
    const { errors = ImmutableMap<string, WrongEntityException.WrongField>() } =
      this.props;
    return Optional.map(errors.get(fieldName), (error) =>
      toHumanReadableErrorMessage(this.props.t, error, fieldName)
    );
  };

  onEditTranslations() {
    this.setState({
      editSurveyIntroduction: true,
    });
  }

  onTranslationUpdate() {
    this.props.onChange({
      introductionText_t: JSON.stringify(getOr(this.state.translationMap, {})),
    });
    this.setState({
      editSurveyIntroduction: false,
    });
  }

  isEditingSurveyIntroductionText() {
    return (
      this.state.editSurveyIntroduction &&
      this.props.configuredSurveylanguages.length > 0
    );
  }

  getTranslatedLanguageValueFromProps(code: LocaleCodes) {
    return getTranslatedSegmentFromStringifiedTranslation(
      code,
      this.props.values.introductionText_t
    );
  }

  getLanguageInformationForIntroductionText() {
    return getLanguageInformationFromStringifiedTranslation(
      this.props.values.surveyLanguages,
      this.props.values.introductionText_t
    );
  }

  render() {
    const {
      values: {
        name,
        type,
        logo,
        introductionText,
        isAnonymous,
        allowSkipQuestion,
      },
      disabled = {},
      allowedTypes,
      allowedMergeTags,
      onUploadSurveyLogo,
      onChange
    } = this.props;
    
    const { isSurveyTypeModalOpen, isSurveyLanguagesModalOpen } = this.state;
    const { totalLanguages, totalTranslated } =
      this.getLanguageInformationForIntroductionText();
    return (
      <ContainerCard title="General Settings">
        <Row type="flex" justify="center">
          <Col span={12}>
            <FormContainer layout="vertical">
              <SurveyName
                label={LabelMapper.getIn(["settings", "name"])}
                name="name"
                data-cy="surveyCreation_settings_name"
                value={name}
                // @ts-ignore
                error={this.getErrorMessageForField("name") && 
                <Trans>{this.getErrorMessageForField("name")}</Trans>}
                description={<Trans>The survey name is used for internal reference only.</Trans>}
                onValueChange={this.updateTextField("name")}
              />
              <SurveyTypesSelect
                value={type}
                label={LabelMapper.getIn(["settings", "type"])}
                data-cy="surveyCreation_settings_category"
                error={this.getErrorMessageForField("type")}
                disabled={disabled.type}
                // tslint:disable-next-line:jsx-no-lambda
                onChange={(value: SelectValue | Survey.Type) =>
                  onChange({
                    type: value as SurveyResource["type"],
                  })
                }
                description={<Trans>{
                  disabled.type
                    ? "You cannot change the type of a template-based survey"
                    : undefined
                }</Trans>}
                helperAction={<Trans>Learn More</Trans>}
                onActionClick={this.createSurveyTypeModalStateHandler()}
              >
                {allowedTypes.map((surveyType: SurveyTypeResource) => (
                  <Option key={surveyType._id} value={surveyType._id}>
                    {surveyType.label}
                  </Option>
                ))}
              </SurveyTypesSelect>
              {this.props.isMultiLanguageSurveyEnabled && (
                <SurveyLanguage>
                  <Select
                    label={<Trans>Additional Survey Languages</Trans>}
                    helperAction={<Trans>Learn More</Trans>}
                    data-cy="additional_survey_languages"
                    onActionClick={() =>
                      this.setState({ isSurveyLanguagesModalOpen: true })
                    }
                    showArrow
                    disabled={disabled.surveyLanguages}
                    mode={"multiple"}
                    onChange={(value: SelectValue) =>
                      onChange({
                        surveyLanguages: (value as string[]).map((i) =>
                          getLanguageCodeFromString(i)
                        ) as LocaleCodes[],
                      })
                    }
                    value={getLanguagesForSelectBox(
                      this.props.configuredSurveylanguages as LocaleCodes[]
                    )}
                  >
                    {getLanguagesForSelectBox(
                      this.props.configuredCompanyLanguages as LocaleCodes[]
                    ).map((langString) => (
                      <Option key={langString} value={langString}>
                        {langString}
                      </Option>
                    ))}
                  </Select>
                  <div style={{ color: Palette.bluishGrey }}><Trans>
                    English is the default survey language. You can select 
                    additional languages for this survey from the list of 
                    languages set in App settings.</Trans>
                  </div>
                </SurveyLanguage>
              )}
              <ImageUpload
                label={"Survey logo"}
                description={
                  "If no survey logo is loaded, we will use your company logo in survey communications."
                }
                buttonText={"Upload a logo"}
                previewText={
                  "Changing the logo file here will only affect this survey."
                }
                onSelect={onUploadSurveyLogo}
                removeImage={this.removeImage}
                imageId={Optional.map(logo, (l) => l.id)}
                maxImageSize={1048576}
                disabled={disabled.logo}
                translate="yes"
              />
              <div
                className={
                  this.isEditingSurveyIntroductionText() ? "introductionT" : ""
                }
              >
                <SurveyIntro
                  label={LabelMapper.getIn(["settings", "introductionText"])}
                  value={introductionText}
                  error={this.getErrorMessageForField("introductionText")}
                  data-cy="surveyCreation_settings_intro"
                  onValueChange={this.updateTextField("introductionText")}
                  rows={5}
                  description="This will be displayed to the recipients on the first page of the survey."
                  disabled={disabled.introductionText}
                  allowedMergeTags={allowedMergeTags}
                  fieldName={"Introduction text"}
                />

                {this.props.isMultiLanguageSurveyEnabled &&
                  !this.isEditingSurveyIntroductionText() && (
                    <MultiLanguageFooterStrip
                      languagesReady={totalTranslated}
                      totalLanguages={totalLanguages}
                      onEditTranslations={() =>
                        this.setState({ editSurveyIntroduction: true })
                      }
                    />
                  )}

                {this.isEditingSurveyIntroductionText() &&
                  this.props.configuredSurveylanguages.map((code, index) => (
                    <div
                      className={index === 0 ? "mb-24p mt-24p" : "mb-24p"}
                      key={index}
                    >
                      <SurveyIntro
                        key={code}
                        label={LabelWithFlag(code as LocaleCodes)}
                        value={this.state.translationMap[code]}
                        error={this.getErrorMessageForField("introductionText_t")}
                        data-cy="surveyCreation_settings_intro"
                        onValueChange={(value) => {
                          this.setState({
                            translationMap: {
                              ...getOr(this.state.translationMap, {}),
                              [code]: value,
                            },
                          });
                        }}
                        rows={5}
                        description=""
                        disabled={disabled.introductionText}
                        allowedMergeTags={allowedMergeTags}
                        fieldName={"Introduction text"}
                      />
                    </div>
                  ))}

                {this.isEditingSurveyIntroductionText() && (
                  <div className="d-flex justify-content-end">
                    <Button
                      color="blue"
                      data-cy="save_translations"
                      style={{ height: "30px" }}
                      onClick={() => this.onTranslationUpdate()}
                      translate="yes"
                    >
                      Save translations
                    </Button>
                  </div>
                )}
              </div>

              <AnonymousCheckbox
                /* eslint-disable max-len */
                info="Specify whether participants’ email addresses will appear in survey results. Users will be notified accordingly."
                checked={isAnonymous}
                data-cy="surveyCreation_settings_anonymousCheckbox"
                // tslint:disable-next-line:jsx-no-lambda
                onChange={(e: any) => {
                  onChange({
                    isAnonymous: e.target.checked,
                  });
                }}
                disabled={disabled.isAnonymous}
              >
                <Trans>Make responses anonymous</Trans>
              </AnonymousCheckbox>
              <SkipCheckbox
                checked={allowSkipQuestion}
                data-cy="surveyCreation_settings_skipCheckbox"
                info="If checked, employees will be able to submit the
              survey without completing all of the multiple
              choice questions. Open questions are skippable by default."
                // tslint:disable-next-line:jsx-no-lambda
                onChange={(e: any) => {
                  onChange({
                    allowSkipQuestion: e.target.checked,
                  });
                }}
                disabled={disabled.allowSkipQuestion}
              >
                <Trans>Allow participants to skip questions</Trans>
              </SkipCheckbox>
            </FormContainer>
          </Col>
        </Row>
        {isSurveyTypeModalOpen && (
          <StyledModal
            visible={isSurveyTypeModalOpen}
            title={<Trans>Survey Types</Trans>}
            centered
            // tslint:disable-next-line:jsx-no-lambda
            onCancel={this.createSurveyTypeModalStateHandler(false)}
            destroyOnClose={true}
            footer={null}
          >
            <Description>
              <Trans>Each survey has a type assigned, which will determine your
              settings for the next steps of the survey creation process. You
              can find a brief description of each type below.</Trans>
            </Description>
            <SurveySettingsModalContent dataSource={allowedTypes} />
          </StyledModal>
        )}
        {isSurveyLanguagesModalOpen && (
          <StyledModal
            visible={isSurveyLanguagesModalOpen}
            title={<Trans>Additional Survey Languages</Trans>}
            centered
            // tslint:disable-next-line:jsx-no-lambda
            onCancel={() =>
              this.setState({ isSurveyLanguagesModalOpen: false })
            }
            destroyOnClose={true}
            footer={null}
          >
            <Description>
              <Trans>You can create and launch surveys in multiple languages, in
              addition to English (the default language).</Trans>
            </Description>
            <ModalContent>
              <ModalSubHeading><Trans>Adding additional languages</Trans></ModalSubHeading>
              <div><Trans>
                You can select additional languages for this survey from the
                list of languages added under App Settings &#8594; Languages.
                Once the additional survey languages as added here, you can add
                translations for survey communications, questions, answer options
                from the Questions page of this survey
                </Trans></div>
            </ModalContent>
          </StyledModal>
        )}
      </ContainerCard>
    );
  }
}

export const GeneralSettingsForm = withTranslation()(GeneralSettingsFormComponent);

export const FormContainer = styled(Form)<FormProps>`
  margin-top: 32px !important;
`;

export const SurveyName = styled(DebouncedInput)<
  InputProps & WithDebounceProps
>`
  margin-bottom: 32px !important;
`;

export const SurveyIntro = styled(TextWithMergeTags)<TextWithMergeTagsProps>``;

export const AnonymousCheckbox = styled(Checkbox)`
  margin-top: 30px !important;
`;

export const SkipCheckbox = styled(Checkbox)`
  margin-top: 16px !important;
`;

export const SurveyTypesSelect = styled(Select)<
  SelectProps & { children: React.ReactNode }
>``;

const StyledModal = styled(Modal)<ModalProps & { children: React.ReactNode }>`
  width: 740px !important;
`;

const Description = styled(Paragraph)`
  padding: 16px 24px 24px 32px;
  color: ${Palette.bluishGrey};
`;

const ModalContent = styled(Paragraph)`
  padding-left: 32px;
  padding-right: 24px;
  color: ${Palette.bluishGrey};
`;

const ModalSubHeading = styled.div`
  color: ${Palette.darkBlueGrey};
  font-size: 1.1em;
  margin-bottom: 10px;
`;

const SurveyLanguage = styled.div`
  margin-top: 30px;
`;
