import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { addDays, utcNow } from "hyphen-lib/dist/lang/Dates";
import Cookies from "universal-cookie";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";

/**
 * Save the specified cookie.
 *
 * fixme: By default validity is 365 days, we probably need to change that, and set 10 days, and
 * fixme: to renew the cookie each time the user is accessing the app. Like that if the user
 * fixme: is using the app at least once every 10 days, it will always be logged in, but if during more
 * fixme: than 10 days he is not using the app, we will log him out.
 *
 * @param {string} name
 * @param {string} value
 * @param {number} validity
 */
export function saveCookie(name: string, value: string, validity = 365) {
  const opts: Dictionary<any> = {
    ...generateDefaultOptions(),
    expires: addDays(utcNow(), validity),
  };

  new Cookies().set(name, value, opts);
}

/**
 * Load the specified cookie.
 *
 * @param {string} name
 * @return {Optional<string>}
 */
export function loadCookie(name: string): Optional<string> {
  const val =  new Cookies().get(name);
  if (isStringAndNotEmpty(val)) {
    return val;
  }
  return Optional.empty();
}

/**
 * Remove the specified cookie.
 *
 * @param {string} name
 */
export function removeCookie(name: string) {
  new Cookies().remove(name, generateDefaultOptions());
}

function generateDefaultOptions() {
  const currentDomain = getCurrentDomain();
  const opts: Dictionary<any> = {
    path: "/",
  };
  if (currentDomain !== "localhost") {
    // set the domain, unless we are on dev mode
    opts.domain = currentDomain;
  }

  return opts;
}

function getCurrentDomain(): Optional<string> {
  return extractMasterDomain(document.domain);
}

export function extractMasterDomain(fullDomain: Optional<string>): Optional<string> {
  return Optional.map(
    fullDomain,
    domain => {
      const domainTokens = domain.split(".");
      if (domainTokens.length > 2) {
        return domainTokens[domainTokens.length - 2] + "." + domainTokens[domainTokens.length - 1];
      }
      return domain;
    }
  );
}
