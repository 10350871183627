import React from "react";
import { Map as ImmutableMap } from "immutable";
import ContainerCard from "@src/components/core/ContainerCard";
import styled from "styled-components";
import Radio from "@src/components/core/Radio";
import { Post } from "hyphen-lib/dist/domain/Post";
import { Paragraph } from "@src/components/core/Typography";
import DatePicker from "@src/components/core/DatePicker";
import { TimePicker } from "antd";
import { NumberInput } from "@src/components/core/NumberInput";
import { PulsePollResource } from "hyphen-lib/dist/domain/resource/PulsePollResource";
import { RadioChangeEvent } from "antd/lib/radio";
import withDebounce from "@src/components/core/withDebounce";
import moment from "moment";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { isNotNullNorUndefined, isNullOrUndefined } from "hyphen-lib/dist/lang/Objects";
import Palette from "@src/config/theme/palette";
import { WrongEntityException } from "hyphen-lib/dist/lang/exception/WrongEntityException";
import { toHumanReadableErrorMessage } from "@src/utils/formValidations";
import { Moment } from "moment-timezone";
import { PulsePollDisabledFields } from "../store/pulsePollEditTypes";
import { Trans, WithTranslation, withTranslation } from "react-i18next";

// @ts-ignore
const DebounceNumberInput = withDebounce(NumberInput);

interface Props extends WithTranslation{
  readonly status: Post.Status;
  readonly recurringFrequency: Post.PollFrequency;
  readonly numberOfDaysBeforeClosingInstance: number;
  readonly nextOccurrence: Optional<Date>;
  readonly lastPublishedOn: Optional<Date>;
  readonly disabled: PulsePollDisabledFields;
  readonly errors: ImmutableMap<string, WrongEntityException.WrongField>;
  readonly onChange: (modifications: Partial<PulsePollResource>) => void;
}

const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};

class PublishingFormComponent extends React.Component <Props> {

  handleFrequencyChange = (e: RadioChangeEvent) => {
    const { value } = e.target;
    this.props.onChange({ recurringFrequency: value });
  };

  handleInputChange(
    key: keyof PulsePollResource,
    value: any
  ) {
    this.props.onChange({ [key]: value });
  }

  handleTimeChange = (selectedTime: moment.Moment | null, dateString: string) => {
    if (selectedTime) {
      this.props.onChange({ nextOccurrence: selectedTime.toDate()});
    }
  };

  getErrorMessageForField = (fieldName: string): Optional<string> => {
    switch(fieldName){
      case "Publish Date":
        if (isNotNullNorUndefined(this.props.nextOccurrence) &&
            this.props.nextOccurrence < moment().toDate()){
          const errorField: WrongEntityException.WrongField = {
            scope: "field",
            key: "",
            reason: "",
            errorCode: WrongEntityException.ErrorCodes.NUMBER_LESS_THAN,
            context: {
              minValue: moment().format("YYYY-MM-DD HH:mm")
            }
          };
          return toHumanReadableErrorMessage(this.props.t, errorField, fieldName);
        }
        break;
      default:
        const { errors = ImmutableMap<string, WrongEntityException.WrongField>() } = this.props;
        return Optional.map(
          errors.get(fieldName),
          error => toHumanReadableErrorMessage(this.props.t, error, fieldName)
        );
    }  
  };

  getDefaultPublishDateFieldSettings = () => {
    const {
      status,
      nextOccurrence,
      disabled,
    } = this.props;

    if (status === Post.Status.DRAFT) {
      return {
        nextOccurrenceDateAndTime: isNotNullNorUndefined(nextOccurrence) ? moment(nextOccurrence) : moment(),
        disabled: disabled.nextOccurrence,
        label: "Publish the poll on:",
      };
    }
  
    if (isNullOrUndefined(nextOccurrence)) {
      return {
        nextOccurrenceDateAndTime: Optional.empty() as Moment,
        disabled: true,
        label: (
          <span>
            Publish the poll on <StyledSpan>(No future occurrences)</StyledSpan>:
          </span>
        ),
      };
    }

    return {
      nextOccurrenceDateAndTime: moment(nextOccurrence),
      disabled: disabled.nextOccurrence,
      label: "Publish the poll on:",
    };
  };

  render() {
    const {
      lastPublishedOn,
      recurringFrequency,
      numberOfDaysBeforeClosingInstance,
      disabled,
    } = this.props;

    const defaultPublishDateFieldSettings = this.getDefaultPublishDateFieldSettings();

    const lastPublishedOnDateAndTime = isNotNullNorUndefined(lastPublishedOn) ? moment(lastPublishedOn) : undefined;

    return (
      <PublishContainer title="Publishing">
        <FormContainer>
          <Radio.Group
            value={recurringFrequency}
            onChange={this.handleFrequencyChange}
            disabled={disabled.recurringFrequency}
          >
            <Paragraph translate="yes">The poll should be sent:</Paragraph>
            <Radio value={Post.PollFrequency.ONCE} style={radioStyle} data-cy="pollForOnce">
              <Trans>Once</Trans>
            </Radio>
            <Radio value={Post.PollFrequency.WEEKLY} style={radioStyle} data-cy="pollForWeekly">
              <Trans>Weekly</Trans>
            </Radio>
            <Radio value={Post.PollFrequency.EVERY_TWO_WEEKS} style={radioStyle} data-cy="pollFor2Weeks">
              <Trans>Every 2 weeks</Trans>
            </Radio>
            <Radio value={Post.PollFrequency.MONTHLY} style={radioStyle} data-cy="pollForMontly">
              <Trans>Monthly</Trans>
            </Radio>
            <Radio value={Post.PollFrequency.QUARTERLY} style={radioStyle} data-cy="pollForQuarterly">
              <Trans>Quarterly</Trans>
            </Radio>
            <Radio value={Post.PollFrequency.EVERY_SIX_MONTHS} style={radioStyle} data-cy="pollFor6Months">
              <Trans>Every 6 months</Trans>
            </Radio>
          </Radio.Group>
          <div>
            <Paragraph translate="yes">
              {defaultPublishDateFieldSettings.label}
            </Paragraph>
            <DateTimeContainer>
              <DatePicker
                data-jest="publishingForm_nextOccurrence_date"
                data-cy="publishingForm_nextOccurrence_date"
                disabled={defaultPublishDateFieldSettings.disabled}
                allowClear={false}
                value={defaultPublishDateFieldSettings.nextOccurrenceDateAndTime}
                onChange={this.handleTimeChange}
                style={{ width: 206 }}
              />
              <TimePicker
                data-jest="publishingForm_nextOccurrence_time"
                data-cy="publishingForm_nextOccurrence_time"
                disabled={defaultPublishDateFieldSettings.disabled}
                allowClear={false}
                value={defaultPublishDateFieldSettings.nextOccurrenceDateAndTime}
                format="HH:mm"
                style={{ width: 206 }}
                onChange={this.handleTimeChange}
              />
            </DateTimeContainer>
            <Error data-cy="publishingError">
              {this.getErrorMessageForField("Publish Date")}
            </Error>
          </div>
          { lastPublishedOnDateAndTime &&
            <StyledParagraph translate="yes">
              Last published on:
              <LastOccurrenceSpan
                data-jest="publishingForm_lastPublishedOn_date"
                data-cy="publishingForm_lastPublishedOn_date"
              >
                {lastPublishedOnDateAndTime.format("YYYY-MM-DD")}  {lastPublishedOnDateAndTime.format("HH:mm")}
              </LastOccurrenceSpan>
            </StyledParagraph>
          }

          <div>
            <DebounceNumberInput
              disabled={disabled.numberOfDaysBeforeClosingInstance}
              label="Stop accepting responses after:"
              data-cy="stopAcceptingAfter"
              value={numberOfDaysBeforeClosingInstance}
              onValueChange={(value)=> this.handleInputChange("numberOfDaysBeforeClosingInstance", 
              Number(value))}
              suffix={<Trans>days</Trans>}
            />
          </div>
        </FormContainer>
      </PublishContainer>
    );
  }

}

export const PublishingForm = withTranslation()(PublishingFormComponent);

const PublishContainer = styled(ContainerCard)`
  margin-top: 24px;
`;

const DateTimeContainer = styled.div`
  margin-top: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const FormContainer = styled.div`
  margin: 0 270px;

  > div {
    margin-top: 24px;
  }
`;

const Error = styled.div`
  margin-top: 5px;
  color: ${Palette.darkPink};
`;

const StyledSpan = styled.span`
  color: ${Palette.bluishGrey}
`;

const LastOccurrenceSpan = styled.span`
  margin-left: 10px;
`;

const StyledParagraph = styled(Paragraph)`
  svg {
    height: 18px;
    width: 18px;
    color: #919EAB;
    cursor: pointer;
  }
  margin-top: 20px;
  display: flex;
  flex: 1;
`;
