import { FC } from "react";
import styled from "styled-components";

import { TrendResource } from "hyphen-lib/dist/domain/resource/survey/report/TrendResource";
import { Heading } from "@src/components/core/Typography";
import { getOr } from "hyphen-lib/dist/lang/Objects";
import { TrendLineChart, TrendLineChartData } from "@src/components/core/TrendLineChart";
import { generateTrendLineData, getMonthsToDisplay, labelFormatter } from "@src/screens/Insights/Survey/trendLineUtils";
import toolTipOptions from "../../../ToolTip";
import { Summary } from "../../../OverviewReport/components/Overview/index";
import { Trans } from "react-i18next";

interface CategoryTrendLineProps {
  surveyId: string;
  trend: TrendResource.CategoryTrends[];
}

const CategoryTrend: FC<CategoryTrendLineProps> = (props) => {
  const { trend, surveyId } = props;
  const trendData: TrendLineChartData[] = generateTrendLineData(trend, surveyId);
  const minMonths = getMonthsToDisplay(trendData.length);

  if (trendData.length < 2) {
    return null;
  }

  return (
    <SectionContainer marginTop={42 }>
      <Heading size="small" weight={"bold"} data-jest="linked-surveys-heading" translate="yes">
        Category Trend
      </Heading>
      <Summary data-cy="">
        <Trans>Favorability score across linked surveys</Trans>
      </Summary>
      <br />
      <TrendLineChart
        minTicks={minMonths}
        data={trendData}
        toolTipOptions={toolTipOptions}
        xAxisLabelsOptions={{rotation: -45}}
        yAxis={{labelFormatter}}
      />
      <Summary data-cy="" centered>
        <Trans>Data points with not enough data are not displayed in the graph to respect anonymity rules.</Trans>
      </Summary>
    </SectionContainer>
  );
};

const SectionContainer = styled.div<{ marginTop?: number }>`
  margin-top: ${props => getOr(props.marginTop, 32)}px;
`;

export default CategoryTrend;