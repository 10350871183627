import React from "react";

import { CellContainer, CellInnerContainer } from "@screens/Insights/SurveyList/components/cells/Cells";
import { CommentsCounter } from "@components/core/CommentsCounter";
import { Optional } from "hyphen-lib/dist/lang/Optionals";

interface Props {
  row: {
    numberOfComments: number;
  };
  onCommentsTagClick?: Optional<(e: React.MouseEvent) => void>;
}

export class CommentsCell extends React.Component<Props> {
  render () {
    const { row: { numberOfComments }, onCommentsTagClick } = this.props;

    return (
      <CellContainer align="flex-start" justifyContent="flex-start">
        <CellInnerContainer padding={[24, 0]} width="100%">
          <div>
            <CommentsCounter onClick={Optional.extract(onCommentsTagClick)} numberOfComments={numberOfComments}/>
          </div>
        </CellInnerContainer>
      </CellContainer>
    );
  }
}
