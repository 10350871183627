import {
  ActionTypes,
  actionCreators
} from "@screens/Insights/Survey/containers/SurveyRemindersContainer/store/actions";

import { put, select, takeLatest } from "@redux-saga/core/effects";

import {
  NetworkRequestAction
} from "@store/network/actions";

import { freeze, isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { reminders } from "./selectors";

export function* fetchSurvey() {
  // Get the selectedSurveyInfoResource:
  const { data } = yield select(reminders);

  const surveyId = data._id;
  if (isNotNullNorUndefined(surveyId)) {
    yield put<NetworkRequestAction>(
      actionCreators.fetchSurvey(surveyId)
    );
  }
}

export const surveyReminderSagas = freeze([
  takeLatest(
    ActionTypes.TOGGLE_REMINDER_MODAL_OPEN,
    fetchSurvey
  ),
]);