import { LifeCycleDashboardResource } from "hyphen-lib/dist/domain/resource/report/LifeCycleResource";
import { default as networkActionCreators } from "@store/network/actions";
import { createRequest } from "@src/utils/networks";
import { appendQueryString, generateQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";

export function fetchLCADashboardIfNeeded(queryString: Dictionary<any>) {
  return networkActionCreators.fetchElementIfNeeded({
    id: LifeCycleDashboardResource.generateId(queryString.filter),
    type: LifeCycleDashboardResource.TYPE,
    request: createRequest(
      appendQueryString(
        "/lifecycle/dashboard",
        generateQueryString(queryString)
      )
    ),
  });
}
