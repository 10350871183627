import React from "react";
import { ChangeEvent } from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";
import Palette from "@src/config/theme/palette";

import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";

import ContainerCard from "@components/core/ContainerCard";
import Button from "@components/core/Button";
import Input from "@components/core/Input";
import workplaceLogo from "./assets/Workplace_Wordmark_Gray.png";

interface WorkplaceActionProps {
  readonly updateCompany: (payload: CompanyResource["channels"]) => void;
  readonly removeIntegration: () => void;
}

interface SettingsStateProps {
  accessKey: string;
}

type Props = SettingsStateProps & WorkplaceActionProps;
export default class WorkplaceSettings extends React.Component<Props, SettingsStateProps> {
  constructor(props: Props) {
    super(props);

    this.state = {
      accessKey: props.accessKey,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { accessKey } = this.props;
    if (prevProps.accessKey !== this.props.accessKey) {
      this.setState({
        accessKey,
      });
    }
  }

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    this.setState({
      [event.target.name]: event.target.value.trim(),
    });
  };

  updateSettings = () => {
    const { accessKey } = this.state;
    if (accessKey !== "") {
      const payload = {
        workplace: {
          accessKey,
        },
      };

      this.props.updateCompany(payload);
    }
  };

  removeIntegration = () => {
    this.props.removeIntegration();
  };

  render() {
    const { accessKey } = this.state;
    return (
      <ContainerCardNew>
        <Holder>
          <Header>
            <ImageContainer src={workplaceLogo}/>
          </Header>
          {
            this.props.accessKey &&
            <Button
              color="gradation"
              onClick={this.removeIntegration}
              translate="yes"
            >
              Remove Integration
            </Button>
          }
        </Holder>
        <Description><Trans>
          This integration allows companies using Facebook Workplace to send Surveys to employees
          as direct messages within the Facebook Workplace platform.
        </Trans></Description>
        <TextHolder>
          <Input
            value={accessKey}
            label="Access Token"
            name="accessKey"
            onChange={this.handleChange}
          />
        </TextHolder>

        <UpdateButton
          onClick={this.updateSettings}
          translate="yes"
        >
          Update Settings
        </UpdateButton>

      </ContainerCardNew>
    );
  }

}

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ImageContainer = styled.img`
  width: 150px;
  max-width: 100%;
  max-height: 207px;
  margin-right: 20px;
`;

const Holder = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    margin-bottom:10px
`;

const Description = styled.p`
  margin-top: 10px;
  padding-right:88px;
`;

const UpdateButton = styled(Button)`
  margin-top: 10px;
  background-color: ${Palette.lightGreyBlue} !important;
  border: 1px solid #9dabbf !important;
  color: white !important;

  :hover, :focus {
    background-color: #314a5b !important;
    border-color: #48687d !important;
  }
`;

const TextHolder = styled.div`
    max-width:30%;
    margin-top: 10px;
`;

const ContainerCardNew = styled(ContainerCard)`
	margin-bottom:30px;
`;
