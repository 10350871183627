import { default as networkActionCreators } from "@store/network/actions";
import { createRequest, FilterParameter, SortParameter } from "@src/utils/networks";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { appendQueryString, generateQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { UserPermissionResource } from "hyphen-lib/dist/domain/resource/user/UserPermissionResource";

export interface FetchUserPermissionsParameters {
  readonly filter?: FilterParameter;
  readonly sort?: SortParameter;
  readonly page: PageFilter;
}

export function fetchUserPermissionsIfNeeded({filter, sort, page}: FetchUserPermissionsParameters) {
  return networkActionCreators.fetchPageIfNeeded({
    id: UserPermissionResource.generateKey(filter, sort),
    type: UserPermissionResource.TYPE,
    page,
    rawPageSize: 30,
    request: pageFilter => createRequest(
      appendQueryString(
        "/permissions",
        generateQueryString({
          filter,
          sort,
          page: pageFilter,
        })
      )
    ),
  });
}

export function fetchUserScopeList(rootUser: string) {
  return networkActionCreators.fetchPageWithoutPagination({
    id: rootUser,
    type: UserPermissionResource.USER_SCOPE_LIST_TYPE,
    request: () => createRequest(`/permissions/scope/${rootUser}?format=json`),
  });
}


export function fetchUserPermissionsCountIfNeeded(filter: FilterParameter) {
  return networkActionCreators.fetchCountIfNeeded({
    id: UserPermissionResource.generateKey(filter),
    type: UserPermissionResource.TYPE,
    request: createRequest(
      appendQueryString(
        "/users/count",
        generateQueryString({ filter })
      )
    ),
  });
}
